import { DateUtils, formatoFechasApi } from "../../../../helpers/dateUtils";
import { RequestHelper } from "../../../../helpers/requestHelper";
import { SesionService } from "../../../../services/sesion.service";
import { parseLocalOptions } from "../helper/helper";
import { TKitsPartesOption } from "../store/type";

export const kitsPartesService = { getKitsPartes };


async function getKitsPartes(label: string): Promise<any> {
  try {
    const sesion = SesionService.getCurrentSesion()
    const data: any = {
      mensaje: label
    };
    const keysToCache: any = {
      ...data,
      dateKey: DateUtils.getCurrentDateAsString(formatoFechasApi),
      empresa: sesion?.empresa?.codigo,
    }
    const cacheKey = 'tiposKitsPartes' + JSON.stringify(keysToCache);
    const cached = sessionStorage.getItem(cacheKey);
    if (cached) {
      return { error: false, auto: JSON.parse(cached) };
    } else {
      const data: any = {
        mensaje: label
      };
      const res = await RequestHelper.getAll<any>('inventario/catalogos', 'fichaTecnica/kitspartes/cBoxLoad', "", data);
      let opt: Array<TKitsPartesOption> = [];

      if (res?.auto && res?.auto.length > 0) {
        opt = parseLocalOptions(res.auto);
      }
      sessionStorage.setItem(cacheKey, JSON.stringify(opt));
      res.auto = opt ?? []
      return res;
    }
  } catch (error) {
    return error;
  }
}