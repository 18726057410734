import { memo, useCallback } from 'react'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import { CCard, CCardHeader, CCollapse, CLink } from '@coreui/react-pro'
import { useDispatch, useSelector } from 'react-redux'
import CIcon from '@coreui/icons-react'
import { cilChevronBottom, cilChevronTop } from '@coreui/icons'
import { setCollpased, updateFilter } from '../../store/searchReducer'
import { RootState } from '../../../../../../store/store'
import CustomCol from '../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput'
import { TextBox } from 'devextreme-react'
import { ValueChangedEvent } from 'devextreme/ui/text_box'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import { buttonClick } from '../../store/tabsReducer'

const SearchFilter = () => {
  const dispatch = useDispatch()

  const searchFilter = useSelector(
    (state: RootState) => state.nomina.cargos.search.filter,
  )
  const collapsed = useSelector(
    (state: RootState) => state.nomina.cargos.search.collapsed,
  )
  const currentTab = useSelector((state: RootState) => {
    return state.nomina.cargos.tabs.current
  })
  const currentExecutingAction = useSelector((state: RootState) => {
    return state.nomina.cargos.tabs.tabs[currentTab].currentExecutingAction
  })

  const updateFormField = useCallback(
    (evt: ValueChangedEvent, valueToUpdate: string) => {
      if (evt.event)
        dispatch(
          updateFilter({
            ...searchFilter,
            [valueToUpdate]: evt.value,
          }),
        )
    },
    [dispatch, searchFilter],
  )

  const onHandleSearch = useCallback(() => {
    if (currentExecutingAction === undefined)
      dispatch(buttonClick({ tabKey: 'BUSQUEDA', button: ButtonTypes.find }))
  }, [currentExecutingAction, dispatch])

  return (
    <CCard>
      <CCardHeader
        onClick={() => dispatch(setCollpased(!collapsed))}
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <strong> {!collapsed ? 'Filtro de Búsqueda' : ''} </strong>
        <CLink className="card-header-action">
          <CIcon icon={collapsed ? cilChevronBottom : cilChevronTop} />
        </CLink>
      </CCardHeader>
      <CCollapse visible={collapsed}>
        <RowContainer className="m-2">
          <CustomCol xs="12" md="4">
            <Labeled label="Cargo:">
              <TextBox
                inputAttr={{ autocomplete: 'nope' }}
                value={searchFilter.cargo}
                onValueChanged={(evt: ValueChangedEvent) =>
                  updateFormField(evt, 'cargo')
                }
                showClearButton={true}
                onEnterKey={onHandleSearch}
              />
            </Labeled>
          </CustomCol>
        </RowContainer>
      </CCollapse>
    </CCard>
  )
}

export default memo(SearchFilter)
