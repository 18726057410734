import React from 'react'
import SelectBox from 'devextreme-react/select-box'
import { CondicionActivo } from './types/types'
import { condicionACtivoServices } from './services/condicionActivo.service'
import { utilidades } from '../../../helpers/utilidades'

interface CondicionActivoLookUpProps extends React.PropsWithChildren {
  selected: CondicionActivo | null
  // eslint-disable-next-line no-unused-vars
  onChanged: (newValue: CondicionActivo | null) => void
  // eslint-disable-next-line no-unused-vars
  onChangedOptions: (newValue: Array<CondicionActivo> | []) => void
  disabled?: boolean
}

const CondicionActivoLookUp: React.FC<CondicionActivoLookUpProps> = (props) => {
  const { selected, onChanged, disabled, onChangedOptions } = props
  const selectRef = React.useRef<any>()
  const [options, setOptions] = React.useState<Array<CondicionActivo> | []>([])

  const loadDatos = React.useCallback(async () => {
    const data = await condicionACtivoServices.getConditions()
    if (data.error === false) {
      let optionsLookup = []
      if (data.auto.length > 0) {
        optionsLookup = data.auto.map((option: any) => {
          return {
            codigo: option?.codigo ?? 0,
            descripcion: utilidades.capText(option?.descripcion_) ?? '',
            denominacion: option?.denominacion ?? '',
          }
        })
      }
      setOptions(optionsLookup)
      onChangedOptions(optionsLookup)
      const selectedIndex = optionsLookup.findIndex((option) => {
        return option.codigo === selected?.codigo
      })
      onChanged(optionsLookup[selectedIndex] ?? optionsLookup[0])
    }
  }, [onChanged, onChangedOptions, selected])

  const getItemDisplayExpr = React.useCallback((item: any) => {
    return item ? `${item.denominacion}` : ''
  }, [])

  const onLookUpValueChanged = React.useCallback(
    (evt: any) => {
      if (evt.event !== undefined) {
        onChanged(evt.value)
      }
    },
    [onChanged],
  )

  const onSelectBoxFocusIn = React.useCallback((e) => {
    e.element.querySelector('input.dx-texteditor-input').select()
  }, [])

  const refreshSelected = React.useCallback(() => {
    if (options?.length > 0) {
      const selectedIndex = options.findIndex((option: any) => {
        return option.codigo === selected?.codigo
      })
      onChanged(options[selectedIndex] ?? options[0])
    }
  }, [onChanged, options, selected])

  React.useEffect(() => {
    loadDatos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    refreshSelected()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  return (
    <SelectBox
      id="tiposImpuestosSelect"
      ref={selectRef}
      items={options ?? []}
      searchEnabled
      displayExpr={getItemDisplayExpr}
      searchExpr={['denominacion']}
      searchTimeout={500}
      value={selected}
      onValueChanged={onLookUpValueChanged}
      disabled={disabled}
      onFocusIn={onSelectBoxFocusIn}
    >
      {props.children}
    </SelectBox>
  )
}
export default React.memo(CondicionActivoLookUp)
