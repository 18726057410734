import { CCol, CRow } from '@coreui/react-pro';
import React from 'react';
import { NumberBox } from 'devextreme-react/number-box'
import { RootState } from '../../../../../../../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { CuadresService } from '../../../../../services/cuadres.service';
import { DateUtils, formatoFechasApi } from '../../../../../../../../../helpers/dateUtils';
import {
  update7VenInicialFinal,
  updateDpCierre7Only, updateDptotales7Only, updateDptotalesOnly,
  updateLoadDpCierre7Only,
  updateTotal7_tiOnly
} from '../../../store/editDataReducer';
import DataGrid, {
  Column, HeaderFilter, Paging, Pager, Export
} from 'devextreme-react/data-grid';
import RowContainer from '../../../../../../../../../views/componentes/rowContainer/rowContainer';
import CustomCol from '../../../../../../../../../views/componentes/colContainer';
import { utilidades } from '../../../../../../../../../helpers/utilidades';
import { useGridResultadosSize } from '../../../../../../../../../hooks/useGridResultadosSize';
import { getAllowedPageSizes } from '../../../../../../../../../helpers/Helper';
import { AnticiposCuadresCajaListado, ccDetalle, formasPagoCuadre } from '../../../../../types/types';
import TabFormasPago from '../componentes/tabFormasPago/tabFormasPago';
import { GlobalLabel } from '../../../../../../../../../views/componentes/globalLabel/globalLabel';
import ButtonsInfo from '../shared/ButtonsInfo';
import { exportFormats } from '../facturas/facturas';

interface IAnticiposProps extends React.PropsWithChildren {
  setToast: (texto, type) => void,
  playLoader: () => void,
  stopLoader: () => void,
  onErrorConfirm: (sms, title) => void,
  onConfirmarRegistro: (sms, method) => void
  tabId: string,
  statusLoaded: (load) => void
}



const Anticipos: React.FunctionComponent<IAnticiposProps> = (props) => {
  const {
    tabId, setToast, playLoader, stopLoader,
    onErrorConfirm, onConfirmarRegistro, statusLoaded
  } = props;
  const dataGrid = React.useRef<any>();


  const dispatch = useDispatch();
  const [heigth] = useGridResultadosSize();

  const local = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[tabId].local });
  const puntoVenta = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[tabId].puntoVenta });
  const fechaCierre = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].fechaCierre });
  const venInicial7 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venInicial7 });
  const venFinal7 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venFinal7 });
  const generarCuadre = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].generarCuadre });
  const loaddpcierre7 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].loaddpcierre7 });

  const total7_ti = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].total7_ti });
  const dptotales7 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dptotales7 });
  const dpfpagos = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dpfpagos });
  const dptotales = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dptotales });
  const dpcierre7 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dpcierre7 });

  const [displayMode,] = React.useState<string | 'full' | 'compact'>('full');
  const [showInfo,] = React.useState<boolean>(true);
  const [showNavButtons,] = React.useState<boolean>(true);
  const [showPageSizeSelector,] = React.useState<boolean>(true);

  const resultadoCierreCajaRet = React.useCallback(async (dpCierreFacturasVar: Array<AnticiposCuadresCajaListado> | []) => {
    console.log("resultadoCierreCajaRet")
    console.log("dpCierreFacturasVar", dpCierreFacturasVar)
    const detalle = {
      ccdCodigo: 0,
      ccaCodigo: "",
      subValor: "",
      ccdValor: 0,
      subCodigo: 0,
    };

    let valor: number = 0;
    const dptotales7Local: Array<formasPagoCuadre> = [];
    if (dpCierreFacturasVar.length === 0) {

      console.log("if dpfpagos", dpfpagos)

      for (const fpg of dpfpagos) {
        dptotales7Local.push({
          subCodigo: Number(fpg.codigo) ?? -1,
          subValor: String(fpg.descripcion) ?? "",
          ccdValor: valor,
        });
      }

      dispatch(updateDptotales7Only({
        dptotales7: dptotales7Local,
        key: tabId
      }));

    } else {
      console.log("else dpfpagos", dpfpagos)

      let i = 0;

      for (let j = 0; j <= dpfpagos.length - 1; j++) {
        valor = 0;
        for (i = 0; i < dpCierreFacturasVar.length; i++) {
          if (dpCierreFacturasVar[i].formaPagoDescripcion === dpfpagos[j].descripcion) {
            valor = valor + Number(Number(dpCierreFacturasVar[i].valor).toFixed(2));
          }
        }

        dptotales7Local.push({
          subCodigo: Number(dpfpagos[j].codigo),
          subValor: String(dpfpagos[j].descripcion),
          ccdValor: Number(Number(valor).toFixed(2)),
        });

      }

      const VEN_INICIAL7 = dpCierreFacturasVar[0].codigo;
      const VEN_FINAL7 = dpCierreFacturasVar[dpCierreFacturasVar.length - 1].codigo;

      dispatch(update7VenInicialFinal(
        {
          rango: {
            inicial: VEN_INICIAL7,
            final: VEN_FINAL7,
          },
          key: tabId
        }
      ));
      console.log("dptotales7Local", dptotales7Local)

      dispatch(updateDptotales7Only({
        dptotales7: dptotales7Local,
        key: tabId
      }));

      let k = 0;

      let total: number = 0;
      for (k = 0; k < dptotales7Local.length; k++) {
        total = total + dptotales7Local[k].ccdValor;
      }
      total = Number(Number(total).toFixed(2));
      dispatch(updateTotal7_tiOnly({
        total7_ti: total,
        key: tabId
      }));

      const dptotalesLocal: any = dptotales.slice() ?? [];
      if (dptotales7Local.length > 0) {
        for (i = 0; i < dpfpagos.length; i++) {
          let x: ccDetalle | null = null;
          try {
            x = dptotales[i];
          } catch (ex) {
            x = null;
          }
          if (x == null) {
            const addDetallTotales: ccDetalle = {
              ...detalle,
              ccdValor: Number(Number(dptotales7Local[i].ccdValor).toFixed(2)),
              subCodigo: dpfpagos[i].codigo,
              subValor: dpfpagos[i].descripcion,
            }
            dptotalesLocal.push({ ...addDetallTotales });
          } else {
            const y: number = Number(x?.ccdValor ?? 0) + Number(dptotales7Local[i]?.ccdValor ?? 0);

            dptotalesLocal[i] = {
              ...x, ccdValor: y ?? 0
            }
          }

        }

        dispatch(updateDptotalesOnly({
          dptotales: dptotalesLocal,
          key: tabId
        }));

      }
    }


  }, [dispatch, tabId, dpfpagos, dptotales]);

  const getCierreCuadreActicipos = React.useCallback(async () => {
    let dpCierreRet = [];

    if (dpcierre7) {
      dpCierreRet = dpcierre7;
    }

    await resultadoCierreCajaRet(dpCierreRet)

    dispatch(updateLoadDpCierre7Only({
      loaddpcierre7: false,
      key: tabId
    }));

  }, [
    dpcierre7, resultadoCierreCajaRet,
    dispatch, tabId
  ]);

  const onLoadClose = React.useCallback(async () => {
    statusLoaded(true)
  }, [statusLoaded]);

  React.useEffect(() => {
    if (generarCuadre === true) {
      onLoadClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generarCuadre])

  React.useEffect(() => {
    if (loaddpcierre7 === true) {
      getCierreCuadreActicipos();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaddpcierre7])


  return (
    <>
      <CRow className={'m-2'}>
        <CCol lg="12" xl="12" sm="12">
          <RowContainer >
            <CustomCol xs='12' md='12'   >
              <DataGrid
                selection={{ mode: 'single' }}
                focusedRowEnabled={true}
                keyExpr='codigo'
                dataSource={dpcierre7}
                showBorders={true}
                width={utilidades.getGridFullWidth()}
                allowColumnResizing
                columnResizingMode='widget'
                height={heigth}
                ref={dataGrid}
              >
                <Export enabled={true} formats={exportFormats} />
                <Paging defaultPageSize={20} />
                <Pager
                  visible={dpcierre7.length > 0 ? true : false}
                  allowedPageSizes={getAllowedPageSizes(dpcierre7)}
                  displayMode={displayMode}
                  showPageSizeSelector={showPageSizeSelector}
                  showInfo={showInfo}
                  showNavigationButtons={showNavButtons}
                />
                <HeaderFilter visible={true} />
                <Column dataField='numero' caption='Número' width='20%' allowEditing={false} />
                <Column dataField='registro' caption='Fecha' width='20%' allowEditing={false} />
                <Column dataField='asociadoIdentificacion' caption='Ced/Ruc' width='15%' allowEditing={false} />
                <Column dataField='asociadoDescripcion' caption='Cliente' allowEditing={false} width={'15%'} />
                <Column dataField='concepto' caption='Concepto' width='15%' allowEditing={false} />
                <Column dataField='documento' caption='Documento' width='15%' allowEditing={false} />
                <Column dataField='valor' caption='Valor' width='20%' allowEditing={false} />
              </DataGrid>
            </CustomCol>
          </RowContainer>
        </CCol>
      </CRow>
      <CRow className="m-2 row">
        <CCol lg="12" md="12" sm="12" className="d-flex justify-content-end  align-items-center">
          <CCol lg="3" md="3" sm="12" className="d-flex justify-content-end  align-items-center">
            <TabFormasPago
              tabId={tabId}
              dptotales={dptotales7}
            />
          </CCol>
        </CCol>
      </CRow>
      <CRow className="m-2 row">
        <CCol lg="3" md="3" sm="12" className="d-flex justify-content-start  align-items-center">
          <ButtonsInfo />
        </CCol>
        <CCol lg="9" md="9" sm="12" className="d-flex justify-content-end  align-items-center">
          <CCol lg='2' md="2" xs="12">
            <GlobalLabel htmlFor="total" className="col-form-label-md">
              <strong> {"Total"} </strong>
            </GlobalLabel>
            <CRow>
              <CCol md="12">
                <NumberBox
                  readOnly
                  width="100%"
                  value={total7_ti}
                  placeholder=""
                  format="#,##0.00"
                />
              </CCol>
            </CRow>
          </CCol>
        </CCol>
      </CRow>
    </>
  )
}
export default Anticipos;
