/* eslint-disable prefer-const */
import React, { useState } from 'react'
import {
  CCol,
  CRow,
  CButton
} from '@coreui/react-pro'
import { ingresarChequesService } from '../../service/modalIngresarCheques.services'
import { setRegistros } from "../../store/modalIngresoCheques"
import { useDispatch, useSelector } from 'react-redux'
import ModalCuentasBancos from '../../../../bancos/pages/ccahorros/componentes/modalCuentasBancos'
import ModalProveedores from '../../../../proveedores/pages/proveedores/components/modalProveedores'
// import ModalProveedores from '../../../../../../proveedores/pages/proveedores/components/modalProveedores'
import Modalform from '../../../../../views/componentes/modalform'
import Tabla from '../tabla'
import { RootState } from '../../../../../store/store'
import { ToastTypes } from '../../../../../store/types'
import { addToast } from '../../../../../store/toasterReducer'
import { changeLoader } from '../../../../../store/reducers'
import { Cheque } from '../../../../bancos/pages/cheques/store/types'

type BuscarProps = {
  // actionDelete: () => void
  // exportExcel: () => void
  onSelect: (data) => void
  // valor_ti: number
  codModulo: number
}

const Buscar = (props: BuscarProps) => {
  const { codModulo } = props;
  const dispatch = useDispatch()
  const registros = useSelector(
    (state: RootState) => state.bancos.cheques.registros
  )

  const [asi_numero, setAsi_numero] = useState<any>('')
  const [cuenta_ti, setCuenta_ti] = React.useState<any>('')
  const [codcuenta_ti, setCodcuenta_ti] = React.useState<any>('')

  const [showBancos, setShowBancos] = React.useState<boolean>(false)
  const [showModalProveedor, setShowModalProveedor] = React.useState<boolean>(
    false
  )
  const [identificacion_ti, setIdentificacion_ti] = React.useState<string>('')
  const [nombre_ti, setNombre_ti] = React.useState<string>('')
  const [messageConfirm, setMessageConfirm] = React.useState<string>(
    'Está seguro que desea realizar estos cambios?'
  )
  const [titleConfirm, setTitleConfirm] = React.useState<string>('Confirmar')
  const [showConfirm, setShowConfirm] = React.useState<boolean>(false)
  const [codAsociado_ti, setCodAsociado_ti] = React.useState<string>('')

  const setToast = React.useCallback((texto, type) => {
    let tipo: ToastTypes = ToastTypes.Info;
    if (type === 'warning') {
      tipo = ToastTypes.Warning;
    } else if (type === 'success') {
      tipo = ToastTypes.Success;
    } if (type === 'info') {
      tipo = ToastTypes.Info;
    } if (type === 'danger') {
      tipo = ToastTypes.Danger;
    }
    dispatch(addToast({
      id: '',
      autoHide: 5000,
      title: 'Acatha',
      content: texto,
      fade: true,
      type: tipo
    }));
  }, [dispatch]);

  const playLoader = React.useCallback(() => {
    dispatch(changeLoader({ show: true, mensaje: 'Cargando...' }))
  }, [dispatch]);

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoader({ show: false, mensaje: '' }))
  }, [dispatch]);

  const handleSubmit = React.useCallback(async (ev) => {

    console.log('handleSubmit boton click')
    ev.preventDefault()
    playLoader()

    try {
      const find = {
        asi_numero: asi_numero
      }
      const data = await ingresarChequesService.devuelveTotalTran(find)
      console.log('data', data)
      stopLoader()

      if (data.length > 0) {
        setToast('(' + data.length + ')' + ' Registros encontrados.', 'success')
        let item: Array<Cheque>
        item = data.map(function (item: Cheque) {
          return {
            codigo: Number(item['codigo']), //  CHE_CODIGO;
            numero: Number(item['numero'] ?? 0), //  CHE_NUMERO;
            registro: String(item['registro'] ?? ''), //  CHE_FREGISTRO;
            fechaCobro: String(item['fechaCobro'] ?? ''), // (double) CHE_FCOBRO;
            Transaccion: String(item['Transaccion'] ?? ''), //  CHE_TRANSACCION;
            aFavor: String(item['aFavor'] ?? ''), //  CHE_AFAVOR;
            formaPago: String(item['formaPago'] ?? ''), // (double) CHE_FPAGO;
            valor: Number(item['valor'] ?? 0), //  CHE_VALOR;
            estado: Number(item['estado'] ?? 0), //  CHE_ESTADO;
            estadoDescripcion: String(item['estadoDescripcion'] ?? ''), //  CHE_ESTADO_DES;
            descripcion: String(item['descripcion'] ?? ''), //  CHE_DESCRIPCION;
            comprobante: String(item['comprobante'] ?? ''), //  COMPROBANTE;
            pagos: Number(item['pagos'] ?? 0), //  CHE_PAGOS;
            cuentaBancoCodigo: Number(item['cuentaBancoCodigo'] ?? 0), //  CBA_CODIGO;
            cuentaBancoNumero: String(item['cuentaBancoNumero'] ?? ''), // (double) CBA_NUMERO;
            nombreBanco: String(item['nombreBanco'] ?? ''), //  BAN_NOMBRE;
            cuentaBancoPerteneceDes: Number(
              item['cuentaBancoPerteneceDes'] ?? 0
            ), //  CBA_PERTENECE_DES;
            formaPagValor: String(item['formaPagValor'] ?? ''), // (double) FPA_VALOR;
            fps_codigo: Number(item['fps_codigo'] ?? 0) //  FPS_CODIGO;
          }
        })
        dispatch(setRegistros(item))
      }

      return await data
    } catch (error) {
      console.log('error', error)
      stopLoader()
      setToast(JSON.stringify(error), 'danger')
    }
  }, [asi_numero, dispatch, playLoader, setToast, stopLoader]);

  const modalCuentaBancos = () => {
    return (
      <ModalCuentasBancos
        show={showBancos}
        onClose={() => setShowBancos(!showBancos)}
        cargarCuentas={data => {
          setShowBancos(!showBancos)
          setCuenta_ti(data['cba_numero'] + ' ' + data['ban_nombre'])
          setCodcuenta_ti(data['cba_codigo'])
        }}
        origen="cheques"
        codModulo={codModulo}
      />
    )
  }

  const modalProveedores = () => {
    return (
      <ModalProveedores
        show={showModalProveedor}
        onClose={() => setShowModalProveedor(!showModalProveedor)}
        cargarCuentas={data => {
          setShowModalProveedor(!showModalProveedor)
          setCodAsociado_ti(data['codigo'])
          setIdentificacion_ti(data['identificacion'])
          setNombre_ti(data['nombre'])
        }}
        origen='modal'
      />
    )
  }

  const renderBody = () => {
    return <>{messageConfirm}</>
  }

  const renderFooter = () => {
    return (
      <CButton
        color='primary'
        onClick={() => {
          setShowConfirm(false)
        }}
      >
        {'Aceptar'}
      </CButton>
    )
  }

  const confirmAction = () => {
    return (
      <Modalform
        name='confirmar'
        headerTitle={titleConfirm}
        childrenBody={renderBody()}
        childrenFooter={renderFooter()}
        closeOnBackdrop={false}
        show={showConfirm}
        onClose={() => setShowConfirm(!showConfirm)}
        centered={true}
        size='sm'
      />
    )
  }

  return (
    <>
      <CRow>
        <CCol>
          <Tabla
            name='tablaIngresoCheques'
            data={registros}
            actionDelete={() => console.log('')}
            exportExcel={() => console.log('')}
            origen='main'
            returnSeleccion={data => console.log('data')}
          // valor_ti={props.valor_ti}
          />
        </CCol>
      </CRow>
    </>
  )
}

export default Buscar
