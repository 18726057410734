import Lookup from 'devextreme-react/lookup';
import { DropDownOptions } from 'devextreme-react/select-box';
import React from 'react';
import { InventarioService } from '../../services/inventario.service';
import { UnidadInventario } from '../../store/types';

interface IBuscarUnidadLookUpProps extends React.PropsWithChildren {
  selected?: UnidadInventario | null,
  onChanged: (newValue: UnidadInventario) => void,
  allowEdit?: boolean,
  allowClear?: boolean,
  allowAdd?: boolean,
  provider?: Array<UnidadInventario> | []
}

const BuscarUnidadLookUp: React.FC<IBuscarUnidadLookUpProps> = (props) => {
  const { provider, selected, onChanged } = props;
  const [options, setOptions] = React.useState<Array<UnidadInventario> | []>([]);

  const loadDatos = React.useCallback(async () => {
    if (provider !== undefined && provider.length > 0) {
      setOptions(provider)
      const selectedIndex = provider.findIndex((select: UnidadInventario) => {
        return select.codigo === selected?.codigo
      })
      onChanged(provider[selectedIndex] ?? provider[0])
      if (selectedIndex > -1) {
        onChanged(provider[selectedIndex] ?? provider[0])
      }
    } else {
      const data = await InventarioService.getUnidades();

      let opt = [];
      if (data.length > 0) {
        opt = data.map((x) => {
          return x
        })
      }
      setOptions(opt);
      const selectedIndex = opt.findIndex((option) => {
        return option.codigo === selected?.codigo
      })
      if (selectedIndex > -1) {
        onChanged(opt[selectedIndex] ?? opt[0])
      }
    }
  }, [onChanged, selected, provider]);


  const getDisplayExpr = React.useCallback((unidad: UnidadInventario) => {
    return unidad ? unidad.nombre : '';
  }, []);

  const onLookUpValueChanged = React.useCallback((evt: any) => {
    if (evt.event !== undefined) {
      onChanged(evt.value);
    }
  }, [onChanged]);

  const refreshSelected = React.useCallback(async () => {
    if (options && options.length > 0) {
      const selectedIndex = await options.findIndex((option: UnidadInventario) => {
        return option.codigo === selected?.codigo
      })
      onChanged(options[selectedIndex] ?? options[0])
    }
  }, [onChanged, options, selected])

  React.useEffect(() => {
    loadDatos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (selected !== null) {
      refreshSelected()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  return (
    <Lookup
      id='buscarUnidadesSelect'
      items={options ?? []}
      showDataBeforeSearch
      defaultValue={selected !== null ? selected : null}
      value={selected !== null ? selected : null}
      minSearchLength={3}
      searchTimeout={500}
      displayExpr={getDisplayExpr}
      searchExpr={['nombre']}
      pageLoadMode='scrollBottom'
      showClearButton={true}
      onValueChanged={onLookUpValueChanged}
    >
      <DropDownOptions
        tabIndex={3000}
        closeOnOutsideClick={true}
        showTitle={false}
      />
      {props.children}
    </Lookup>
  )
};
export default React.memo(BuscarUnidadLookUp);
