import React from 'react'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput'
import {
  Button,
  CheckBox,
  DataGrid,
  DateBox,
  NumberBox,
  RadioGroup,
  TextArea,
  TextBox,
  ValidationGroup,
  ValidationSummary,
  Validator,
} from 'devextreme-react'
import { Detalle, ETypeMovTitle, ETypeMovimiento, ETypeMovimientoCode, GuardarMovimiento, IngresoEgresoDto, IngresosEgresosDatosEdicion, Items } from '../../types/types'
import { ButtonTypes } from '../../../../../../../views/componentes/globalMenu/types'
import { clearButtonClick, openTab, setButtons, setCurrentExecutingAction, setNameTabe } from '../../store/tabsReducer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import {
  setCurrentFunction,
} from '../../store/generalReducer'
import { changeLoader, initDatosEdicion, onChangeAsociado, onChangeDetall, onChangeDocumento, onChangeLocal, onChangeMovimientos, onChangeProyectos, onChangeSeries, onChangeTotales, setDatosEdicion } from '../../store/editDataReducer'
import Dialog from '../../../../../../../views/componentes/librerias/bootstrap-dialog'
import { v4 as uuidv4 } from 'uuid'
import { CBadge, CButton, CTooltip } from '@coreui/react-pro'
import { TabState } from '../../../../../../../store/genericTabTypes'
import TiposMovimientosLookUp from '../../../../../../componentes/tiposMovimientosLookUp'
import { setChanges as setChangesItemArray } from '../../../../../store/globalReducerInventarios'
import { Column, Editing, Pager, Paging, Button as DatagridButton, ColumnChooser } from 'devextreme-react/data-grid'
import { getAllowedPageSizes } from '../../../../../../../helpers/Helper'
import { addToast } from '../../../../../../../store/toasterReducer'
import { DocumentInfo, ETipoAsociado, ToastTypes } from '../../../../../../../store/types'
import { ProveedorBusqueda } from '../../../../../../proveedores/store/types'
import { InventarioService } from '../../../../../services/inventario.service'
import { getIndexProvider } from '../../../../../../shared/helpers/funciones'
import { AsyncRule, RequiredRule, StringLengthRule } from 'devextreme-react/validator'
import validations from '../../../../../../../config/validations'
import { DateUtils, formatoFechasApi } from '../../../../../../../helpers/dateUtils'
import { incomeExpensesServices } from '../../services/ingresosEgresos.services'
import { MessagesKeys, lh } from '../../../../../../../helpers/localizationHelper'
import { AdminModalLocales } from '../../../../../../componentes/modalAdminLocales/modalAdminLocales'
import Importar from '../../../../../../clientes/pages/docsCtasXCobrar/components/impportarExcel/importar'
import { AccountingEntry } from '../../../../../../clientes/pages/docsCtasXCobrar/types/types'
import { DocCuentasCobrar } from '../../../../../../clientes/pages/docsCtasXCobrar/services/docCuentasCobrar.service'
import { OrdenPedidoService } from '../../../../../../ventas/pages/ordenes/pages/ordenPedido/services/ordenPedido.services'
import { ProveedoresService } from '../../../../../../proveedores/services/proveedores.service'
import { ClientesService } from '../../../../../../ventas/services/clientes.service'
import { GeneralService } from '../../../../../../../services/general.service'
import ModalAsiento from '../../../../../../contabilidad/pages/mayorGeneral/componentes/modalAsiento'
import { ModalIngresoSeries } from '../../../../../../componentes/modalIngresoSerie'
import { ModalIngresoLotes } from '../../../../../../componentes/modalIngresoLotes/modalIngresoLotes'
import { BuscarProveedorLookUp } from '../../../../../../proveedores/components/busquedaProveedor/BuscarProveedorLookUp'
import BuscarClienteLookUp from '../../../../../../ventas/components/busquedaCliente/busquedaCliente'
import VisualizaError from '../../../../../../ventas/pages/shared/visualizaError/visualizaError'
import ListErrosValidationForm from '../../../../../../componentes/listadoErroresValidacionForm'
import { ModalModificarPrecioItem } from '../../../../../../componentes/modalModificarPrecioItems'
import { getTypesMovements } from '../../helper/helper'
import { ModuleButtons } from '../../index';
import BuscarItemPedidoMemoLookUp from '../../../../../../ventas/pages/ordenes/pages/ordenPedido/components/busquedaPedidos/BusquedaItemsSelectBox/BuscarItemPedidoMemoLookUp'
import { consoleService } from '../../../../../../../services/console.service'
import { StatesEdition } from '../../../../../../../store/enums'
import BlockUi from '../../../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../../../views/componentes/loadingindicator/loadingindicator'
import MainModalItems from '../../../../../../ventas/components/items/busquedaItemsModal/MainModalItems'
import { utilidades } from '../../../../../../../helpers/utilidades'
import { Movimiento } from '../../../conceptosMovimientos/types/types'
import { bancoService } from '../../../../../../bancos/service/bancos.service'
import SelectBox from '../../../../../../../views/componentes/selectBox/selectBox'
import { isMobile } from 'react-device-detect'
import { fetchAllItemsByLocal } from '../../store/serachReducer'
import { EModulosAcatha } from '../../../../../../../store/enumsAplication'
import { Series } from '../../../../../../componentes/modalIngresoSerie/types/types'
import { ModalVisualizarPorCrear } from '../../../../../../componentes/modalVisualizarPorCrear'
import { ReportWithLocalDataModal } from '../../../../../../../views/componentes/xtrareports/ReportWithLocalDataModal'
import { ItemPedido } from '../../../../../../ventas/pages/ordenes/pages/ordenPedido/type/types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import config from '../../../../../../../config/config'
import { ModalItemsConjuntos } from '../../../../../../componentes/modalItemsPorConjuntos'

export const optionsMov = [
  { value: 0, label: 'Ninguno' },
  { value: 1, label: 'Orden' },
  { value: 2, label: 'Movimiento' },
]

export const optionsPrioridad = [
  { value: false, label: 'Descripcion' },
  { value: true, label: 'Barras' },
]

interface INewProps extends React.PropsWithChildren {
  info: DocumentInfo<IngresosEgresosDatosEdicion>
  tab: TabState<IngresosEgresosDatosEdicion>
  tabId: string
}

let movimientoUpdate: Movimiento | null = null
const Nuevo: React.FunctionComponent<INewProps> = (props) => {
  const { tab, tabId, info } = props
  const dispatch = useDispatch()
  const inputPunto = React.useRef<any>();
  const inputDoc = React.useRef<any>();

  const loader = useSelector((state: RootState) => state.inventarios.movimientos.ingresosEgresos.editData[tabId].loader)
  const ingEgState = useSelector((state: RootState) => state.inventarios.movimientos.ingresosEgresos.editData[tabId])
  const loading = useSelector((state: RootState) => state.inventarios.movimientos.ingresosEgresos.editData[tabId].loading)

  const tabs = useSelector((state: RootState) => state.inventarios.movimientos.ingresosEgresos.tabs)
  const verRegistro = useSelector((state: RootState) => state.inventarios.movimientos.ingresosEgresos.search.verRegistro)
  const periodoActivo = useSelector(
    (state: RootState) => state.shared.providers.periodoContable,
  )
  const empresa = useSelector((state: RootState) => state.global.session.empresa)
  const localSession = useSelector((state: RootState) => state.global.session.local)
  const proyectos = useSelector((state: RootState) => state.shared.providers.proyectos)
  const modulo = useSelector((state: RootState) => state.inventarios.movimientos.ingresosEgresos.config.aplicacion)
  const [reporte, setReporte] = React.useState<null | "Viewer" | "Designer">(null);

  const [modalIngresoSeries, setModalIngresoSeries] = React.useState<boolean>(false)
  const [modalIngresoLotes, setModalIngresoLotes] = React.useState<boolean>(false)
  const [showErrorPopup, setShowErrorPopup] = React.useState<boolean>(false)
  const [confirmNew, setonfirmNew] = React.useState<boolean>(false)
  const [showModalPrecioItem, setShowModalPrecioItem] = React.useState<boolean>(false)
  const [showModalLocal, setShowModalLocal] = React.useState<boolean>(false)
  const [showImportButton, setShowImportButton] = React.useState<boolean>(false)
  const [showOrden, setShowOrden] = React.useState<boolean>(false)
  const [showModalAsiento, setShowModalAsiento] = React.useState<boolean>(false)
  const [showModalPorcrear, setShowModalPorcrear] = React.useState<boolean>(false)
  const [moreOptions, setMoreOptions] = React.useState<boolean>(false)
  const [modalItemsConjutos, setModalItemsConjutos] = React.useState<boolean>(false)

  const [arrayErrors, setArrayErrors] = React.useState<Array<string>>([])
  const [changes, setChanges] = React.useState<any>();
  const [editRowKey, setEditRowKey] = React.useState<any>();
  const [selectedDetalle, setSelectedDetalle] = React.useState(null);
  const [movimientos, setMovimientos] = React.useState([])
  const [newRow,] = React.useState<any>({
    codigo: 0,
    impuesto: 0,
    codigoUsuario: '',
    barras: '',
    unidadDescripcion: '',
    descripcion: '',
    cantidad: 0,
    lotes: 0,
    pvp: 0,
    pvd: 0,
    precio: 0,
    numeroSerie: 0,
    valorDescripcion: 0,
    valor: 0,
    oCostos: 0,
    oCostosIVA: 0,
    fracion: 0, // REVISAR
  })
  const sms = validations['es']
  const validationFormRef = React.useRef<any>()
  const dialogRef = React.useRef<any>(null)
  const dataGrid = React.useRef<any>()
  const genericErrorMensaje: string = lh.getMessage(MessagesKeys.GlobalErrorSave)
  // let counter = 0
  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(
      addToast({
        title: 'Inventario-Ingresos/Egresos',
        content: mensaje,
        type: tipo,
      }),
    )
  }, [dispatch])


  const getFilterString = React.useCallback((filter: IngresosEgresosDatosEdicion) => {
    const filterText = ''
    return filterText
  }, [])

  const playLoader = React.useCallback(
    (message = 'Cargando...') => {
      dispatch(changeLoader({
        key: tabId,
        loader: { show: true, mensaje: message }
      }))
    },
    [dispatch, tabId],
  )

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoader({
      key: tabId,
      loader: { show: false, mensaje: '' }
    }))
  }, [dispatch, tabId])


  const onChangesChange = React.useCallback((changes) => {
    setChanges(changes);
  }, [])

  const generarLocales = React.useCallback(async (obtenidos) => {

    const proyectos_object: any = []
    Object.keys(obtenidos).map((key, i) => {
      if (obtenidos[key]['locales'].length > 0) {
        proyectos_object.push(...obtenidos[key]['locales'])
      }
    })
    // buscar el local principal de la empresa y retornarlo en el lugar de general
    if (proyectos_object.length > 0) {
      const local_object: any = []
      proyectos_object.forEach(function (tipo_I_) {
        if (tipo_I_ !== 'false' && tipo_I_ !== false && tipo_I_ !== undefined) {
          const to_array_proy: any = {
            codigo: Number(tipo_I_['codigo']),
            nombre:
              tipo_I_['nombre'] === 'Elija una opción' ? 'General' : tipo_I_['nombre'],
            proyectoCodigo: Number(tipo_I_['proyectoCodigo']),
            proyectoNombre: String(tipo_I_['proyectoNombre'])

          }
          local_object.push(to_array_proy)
        }
      })
      return local_object
    }
  }, []);

  const generarOptionsLocales = React.useCallback((obtenidos) => {
    const objeto: any = []
    Object.keys(obtenidos).map(function (key, i) {
      objeto.push({
        codigo: Number(obtenidos[key]['localCodigo']),
        nombre: obtenidos[key]['localNombre'],
        proyectoCodigo: Number(obtenidos[key]['codigo']),
        proyectoNombre: obtenidos[key]['nombre']
      })
    })
    return objeto
  }, []);

  const generarOptionsProyectos = React.useCallback((obtenidos) => {
    const objeto: any = []
    Object.keys(obtenidos).map(function (key, i) {
      objeto.push({
        codigo: Number(obtenidos[key]['codigo']),
        nombre: obtenidos[key]['nombre'],
        locales: obtenidos[key]['locales']?.map(det => {
          if (det !== false) {
            const local: any = {
              proyectoCodigo: Number(obtenidos[key]['codigo']),
              proyectoNombre: obtenidos[key]['nombre'],
              ...det
            }
            return local;
          }

        })
      })
    })
    return objeto
  }, []);

  const onGetIVA = React.useCallback(async () => {
    try {
      const fechaIva = DateUtils.format(ingEgState?.fecha, 'dd/MM/yyyy', 'yyyy-MM-dd')
      const iva = await incomeExpensesServices.getIva(fechaIva, empresa?.codigoTipoNegocio ?? 0, empresa?.codigoPais ?? 0)
      if (iva !== null && iva !== undefined && iva['error'] === false) {
        return iva['auto']
      }
      return 0
    } catch (error) {
      return 0
    }
  }, [ingEgState, empresa])

  const modoNuevo = React.useCallback(async (template: number, limpiar: boolean = false) => {
    if (loading === false && !limpiar) {
      return
    }
    dispatch(setCurrentExecutingAction({
      tabKey: tabId,
      buttonType: ButtonTypes.new
    }))
    playLoader()
    const resMovimientos = await getTypesMovements()
    // const resLocal = await getLocales('Elija un almacén')
    const IVARes = await onGetIVA()

    const data = { ...defaultDataIncomeExpenses }
    data.concepto = resMovimientos.length > 0 ? resMovimientos[0] : null
    const localesApi = await bancoService.getLocales(empresa.codigo, 'Elije una opción');


    if (proyectos && proyectos.length > 0) {
      const proyectosData = generarOptionsProyectos(proyectos);
      data.proyectosData = proyectosData;

      if (proyectosData.length > 0) {
        const localesData = await generarLocales(proyectosData);
        if (localesData) {
          data.locales = localesData;

          const index = await getIndexProvider(localesData, 'codigo', localSession?.codigo ?? 0)
          if (index > -1) {
            data.local = localesData[index]
          } else {
            data.local = localesData[0]
          }

          const indexProyect = await getIndexProvider(proyectosData, 'codigo', data.local['proyectoCodigo'] ?? 0)
          if (indexProyect > -1) {
            data.proyectos = proyectosData[indexProyect]
          } else {
            data.proyectos = proyectosData[0]
          }
        }
      }

    }

    if (localesApi && localesApi.auto && localesApi.auto?.length > 0) {
      const localesData = generarOptionsLocales(localesApi.auto);
      data.locales = localesData;
      const index = await getIndexProvider(localesData, 'codigo', localSession?.codigo ?? 0)
      if (index > -1) {
        data.local = localesData[index]
      } else {
        data.local = localesData[0]
      }
    }


    data.tipoMovimiento = optionsMov[0]
    data.fecha = DateUtils.getCurrentDateAsString()
    data.dataGrid = [...data.dataGrid, { ...newRow }]
    data.desbloquear = false
    data.distinguir = empresa?.distinguir === 1 ? true : false
    data.documento = ''
    data.loading = false
    data.IVA = IVARes
    data.desdePlantilla = false;
    if (template > 0) {
      data.desdePlantilla = true;
    }
    consoleService.log('modoNuevo')
    dispatch(setDatosEdicion({
      key: tabId,
      data: data
    }))
    stopLoader()
    dispatch(setCurrentExecutingAction({
      tabKey: tabId,
      buttonType: undefined
    }))
  }, [dispatch, tabId, playLoader, stopLoader, onGetIVA, localSession?.codigo, empresa, proyectos, generarLocales, generarOptionsLocales, generarOptionsProyectos, loading, newRow])

  const onEditRowKeyChange = React.useCallback((editRowKey) => {
    setEditRowKey(editRowKey);
  }, [])



  const onExists = React.useCallback(async (data) => {
    const verify_data: any = {
      codigo: ingEgState?.codigo ?? 0,
      documento: `${ingEgState?.establecimeinto}-${ingEgState?.punto}-${data}`,
      procli: ingEgState.proveedor?.codigo ?? 0,
      tipo: "AJUSTES",
      movimiento: ingEgState.concepto?.codigo ?? 0,
      locCodigo: ingEgState.local?.codigo ?? -1,
    }
    try {
      const res = await incomeExpensesServices.existData(verify_data)
      if (res !== null && res !== undefined && res['error'] === false) {
        if (res['auto'] !== 0) {
          setToast('El documento que intenta ingresar ya existe para este Proveedor/Cliente', ToastTypes.Warning)
          return false
        } else {
          return true
        }
      } else {
        setToast(res['message'], ToastTypes.Danger)
        return false
      }
    } catch (error) {
      return false
      setToast(error.message, ToastTypes.Danger)
    }
  }, [ingEgState, setToast]);




  const onChangedLocal = React.useCallback(async (data) => {
    consoleService.log('onChangedLocal')

    dispatch(onChangeLocal({
      key: tabId,
      local: data
    }));
  }, [dispatch, tabId]);

  const onChangedProyecto = React.useCallback(async (data) => {
    consoleService.log('onChangedProyecto')

    dispatch(onChangeProyectos({
      key: tabId,
      proyecto: data
    }));
  }, [dispatch, tabId]);

  const onChangedAsociado = React.useCallback(async (data) => {
    consoleService.log('onChangedAsociado')
    let tipo: string = 'proveedor';
    if (ingEgState.concepto.asociado === ETypeMovimiento.ventas) {
      tipo = 'cliente';
    } else if (ingEgState.concepto.asociado === ETypeMovimiento.compras) {
      tipo = 'proveedor';
    }
    dispatch(onChangeAsociado({
      key: tabId,
      tipo: tipo,
      asociado: data
    }))
  }, [dispatch, tabId, ingEgState]);


  const onChangedDocumento = React.useCallback(async (data) => {
    consoleService.log('onChangedDocumento')
    const desbloquear = ingEgState?.desbloquear ? 50 : 9;
    let number = data
    if ((data !== null && data !== undefined) && data !== '' && data.length !== desbloquear) {
      const doc = parseInt(data)
      number = utilidades.zeroFill(doc, desbloquear, 'left')
    } else if (data === '') {
      number = ''
    }
    if (onExists(number)) {
      dispatch(onChangeDocumento({
        key: tabId,
        documento: number
      }))
    }
  }, [dispatch, tabId, ingEgState, onExists]);


  const ordenOTarjeta = React.useCallback(() => {
    if (ingEgState.tipoMovimiento?.value === 0) {
      setShowOrden(false)
    } else {
      setShowOrden(true)
    }
  }, [ingEgState.tipoMovimiento])

  const onTotales = React.useCallback((data: any[]) => {
    // const precision = empresa !== null ? empresa.precision : 2
    let subTotIva: number = 0
    let subTotl0: number = 0
    let iva: number = 0
    let tot: number = 0
    let numItems: number = 0
    let cantidadItems: number = 0
    data.map(item => {
      cantidadItems = item.cantidad
      if (item?.impuesto === 3) {
        subTotIva += Number(item?.valorDescripcion) ?? 0
      } else {
        subTotl0 += Number(item?.valorDescripcion) ?? 0
      }
      numItems += cantidadItems
    })
    iva = (subTotIva - ingEgState?.descuento12) * (ingEgState?.IVA / 100)
    tot = subTotIva + subTotl0 - ingEgState?.descuento12 - ingEgState?.descuento0 + iva

    consoleService.log('OnTotales')
    dispatch(onChangeTotales({
      key: tabId,
      subtotalIva: subTotIva,
      subtotalIva0: subTotl0,
      iva: iva,
      total: tot,
      art: numItems
    }))
  }, [ingEgState, dispatch, tabId])

  const onHandleBroom = React.useCallback(() => {
    const broomData = {
      ...ingEgState,
      codigo: 0,
      concepto: null,
      cliente: null,
      proveedor: null,
      documento: '',
      dataGrid: [...ingEgState.dataGrid, newRow],
      dataSeries: [],
      dataLotes: [],
      establecimeinto: '',
      punto: '',
      tipoMovimiento: optionsMov[0],
      desbloquear: false,
      ordenNumero: null,
      item: null,
      numFact: '',
      numArt: null,
      observaciones: '',
      fecha: DateUtils.getCurrentDateAsString()
    }
    consoleService.log('broomData', broomData)

    dispatch(setDatosEdicion({
      key: tabId,
      data: { ...broomData }
    }));
    validationFormRef.current.instance.reset();
  }, [dispatch, ingEgState, tabId, newRow])

  const onOpentabNew = React.useCallback(() => {
    const id = uuidv4()
    dispatch(
      initDatosEdicion({
        key: id,
        data: {
          ...defaultDataIncomeExpenses,
          loading: true,
        },
      }),
    )
    dispatch(openTab({ key: id }))
  }, [dispatch])

  const onConfirmNew = React.useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea crear un nuevo registro?`,
      actions: [
        Dialog.Action(
          <span>
            <u>{'P'}</u>{'estaña Actual'}
          </span>,
          (dialog) => {
            dialog.hide()
            onHandleBroom()
          },
          'btn-success',
          'p',
        ),
        Dialog.Action(
          <span>
            <u>{'N'}</u>{'ueva Pestaña'}
          </span>,
          (dialog) => {
            dialog.hide()
            onOpentabNew()
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>{'C'}</u>{'ancelar'}
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setonfirmNew(false)
    return
  }, [onHandleBroom, onOpentabNew])

  const onLoadAllLocal = React.useCallback(async () => {
    const dataState = { ...ingEgState }
    try {
      dispatch(setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: ButtonTypes.find
      }))
      playLoader('Cargando Locales...')
      const locCodigo = ingEgState.local?.codigo ?? 0
      const toAny: any = fetchAllItemsByLocal(locCodigo)
      const res = await dispatch(toAny)
      stopLoader()
      dispatch(setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: undefined
      }))
      consoleService.log(res)
      if (res !== null && res !== undefined && res['payload']['error'] === false) {
        // setDetalle(res['auto'])
        dataState.dataGrid = res['payload']['auto']

        if (res['payload']['auto'][0].ordenNumero === null || res['payload']['auto'][0].ordenNumero.toString() !== "") {
          dataState.ordenNumero = null
          setShowOrden(false)
          dataState.tipoMovimiento = optionsMov[0]
        } else {
          dataState.ordenNumero = res['payload']['auto'][0].numero
          setShowOrden(true)
          dataState.tipoMovimiento = optionsMov[1]
        }
        ordenOTarjeta()
        onTotales(res['payload']['auto'])
      } else {
        setToast(res['payload']['message'], ToastTypes.Danger)

      }
      consoleService.log('onLoadAddLocal')
      dispatch(setDatosEdicion({
        key: tabId,
        data: dataState
      }))
    } catch (error) {
      stopLoader()
      dispatch(setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: undefined
      }))
      setToast(error.message, ToastTypes.Danger)
    }
  }, [ordenOTarjeta, onTotales, setToast, dispatch, ingEgState, tabId, playLoader, stopLoader])

  const onConfirmLoadLocal = React.useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Esta seguro que desea cargar todos los items de este local?`,
      actions: [
        Dialog.Action(
          <span>
            <u>A</u>ceptar
          </span>,
          (dialog) => {
            dialog.hide()
            onLoadAllLocal()
          },
          'btn-success',
          'p',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setonfirmNew(false)
    return
  }, [onLoadAllLocal])


  const onUpdateMovimiento = React.useCallback(() => {
    consoleService.log('onUpdateMovimiento')
    consoleService.log('movimientoUpdate', movimientoUpdate)
    const updateForm = {
      ...ingEgState,
      concepto: movimientoUpdate,
      cliente: null,
      proveedor: null,
      documento: '',
      // proyectos: null,
      // local: null,
      dataGrid: [...ingEgState.dataGrid, newRow],
      dataSeries: [],
      dataLotes: [],
      establecimeinto: '',
      punto: '',
      tipoMovimiento: optionsMov[0],
      ordenNumero: null,
      item: null,
      numFact: '',
      numArt: null,
      observaciones: '',
      fecha: DateUtils.getCurrentDateAsString()
    }
    consoleService.log('updateForm', updateForm)

    dispatch(setDatosEdicion({
      key: tabId,
      data: { ...updateForm }
    }));
    consoleService.log('updated clean')
    movimientoUpdate = null;
    validationFormRef.current.instance.reset();
  }, [
    dispatch, tabId, ingEgState, newRow
  ]);

  const onConfirmChangeMovimiento = React.useCallback(() => {
    consoleService.log('onConfirmChangeMovimiento')
    consoleService.log('movimientoUpdate', movimientoUpdate)

    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Esta seguro de que desea cambiar de movimiento?\n Perderá los datos actualmente registrados si continua con la operación.`,
      actions: [
        Dialog.Action(
          <span>
            <u>A</u>ceptar
          </span>,
          (dialog) => {
            onUpdateMovimiento();
            dialog.hide();
          },
          'btn-success',
          'p',
        ),
        Dialog.Action(
          <span>
            <u>{'C'}</u>{'ancelar'}
          </span>,
          (dialog) => {
            dialog.hide()
            return false
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    return
  }, [onUpdateMovimiento])



  const validateState = React.useCallback((selectedState: any) => {
    consoleService.log(selectedState)
    if (selectedState.value.codigo > 0) {
      return Promise.resolve()
    } else {
      return Promise.reject(selectedState.rule.message)
    }
  }, [])

  const onGenerate = React.useCallback(async (codigoMca: number) => {
    if (ingEgState.concepto?.asiento === 1) {
      const fechaEntry = ingEgState?.fecha ? DateUtils.format(ingEgState?.fecha, 'dd/MM/yyyy', 'yyyy-MM-dd') : DateUtils.getCurrentDateAsString()
      const asiento_generate: AccountingEntry = {
        infoRegistro: {
          movimiento: 'INVENTARIO'
        },
        fecha: fechaEntry,
        descripcion: '',
        numeroDocumento: codigoMca,
        diarioNumero: periodoActivo.length > 0 ? periodoActivo[0].diario : 25,
        localCodigo: -1,
        formaPago: null
      }
      try {
        playLoader('Generando Asiento...')
        const res = await DocCuentasCobrar.generateAccountingEntry(asiento_generate)
        stopLoader()
        if (res !== null && res !== undefined && res['error'] === false) {
          setToast(res['message'], ToastTypes.Success)
        } else {
          setToast(res['message'], ToastTypes.Danger)

        }
      } catch (error) {
        stopLoader()
        setToast(error.message, ToastTypes.Danger)
      }
    }

  }, [ingEgState, playLoader, stopLoader, periodoActivo, setToast])


  const onChangedMovimiento = React.useCallback(async (data) => {
    consoleService.log('onChangedMovimiento', ingEgState)
    consoleService.log('data', data)
    if (ingEgState.dataGrid.length > 1 && (ingEgState?.concepto && ingEgState?.concepto?.codigo !== data.codigo)) {
      movimientoUpdate = data;
      await onConfirmChangeMovimiento();
      return false;
    }
    dispatch(onChangeMovimientos({
      key: tabId,
      concepto: data
    }));
  }, [
    dispatch, tabId, onConfirmChangeMovimiento, ingEgState
  ]);

  const onValidate = React.useCallback(() => {
    const error: Array<string> = []
    const provider = ingEgState?.dataGrid.slice()
    const dataArray = provider.filter(x => x.codigo > 0)
    dataArray.map((item, index) => {
      if (parseInt(item.lotes.toString()) == 1) {
        let totalesLotes: number = 0
        ingEgState?.dataLotes.map(itemLotes => {
          if (itemLotes?.codigoItem === item?.codigo) {
            if (ingEgState.concepto?.tipo === 10) {
              totalesLotes += itemLotes?.cantidad ?? 0
            } else {
              totalesLotes += itemLotes?.cantidadLote ?? 0
            }
          }
        })
        if (totalesLotes != ingEgState.dataGrid[index]?.cantidad) {
          error.push(`Lotes: El item ${item.barras}-${item.descripcion} no tiene registrado correctamente la cantidad en Lotes ${totalesLotes}. \n`)
        }
      }
      if (item.cantidad <= 0) {
        error.push(`Cantidad: El item ${item.barras}-${item.descripcion} tiene Cantidad de 0. \n`)
      }
      if (item.precio < 0 || isNaN(item.precio)) {
        error.push(`Costo Unitario: El item ${item.barras}-${item.descripcion} tiene Costo Unitario menor a 0. \n`)
      }
      if (item.valorDescripcion < 0 || isNaN(item.valorDescripcion)) {
        error.push(`Valor General: El item ${item.barras}-${item.descripcion} tiene Valor Original menor a 0. \n`)
      }
      if (item.valor < 0 || isNaN(item.valor)) {
        error.push(`Total: El item ${item.barras}${item.descripcion} tiene Total menor a 0. \n`)
      }

    })
    return error
  }, [ingEgState])

  const onHandleSave = React.useCallback(async () => {
    let error: Array<string> = []
    let clienteCodigo = 0
    let proveedorCodigo = 0
    let proCli = 0


    const result = validationFormRef.current.instance.validate()

    if (ingEgState?.dataGrid.length > 0) {
      error = onValidate()
    }
    if (ingEgState.concepto?.asociado !== ETypeMovimiento.ninguno) {
      if (ingEgState.concepto?.asociado === ETypeMovimiento.compras && (ingEgState.proveedor === null || ingEgState.proveedor === undefined)) {
        error.push(`Proveedor: Debe escoger una opción.`)
      } else if (ingEgState.concepto?.asociado === ETypeMovimiento.ventas && (ingEgState.cliente === null || ingEgState.cliente === undefined)) {
        error.push(`Cliente: Debe escoger una opción.`)
      }
    }

    if (ingEgState.local === null || ingEgState.local === undefined || ingEgState.local?.codigo <= 0) {
      error.push('Local: Debe elegir un local.')
    }

    if (ingEgState?.documento === null || ingEgState.documento === undefined || ingEgState.documento === '') {
      error.push('Documento: el documento esta incompleto')
    } else if (isNaN(parseInt(ingEgState?.documento))) {
      error.push(`Documento: El valor del documento debe ser un número.`)
    }

    if (ingEgState.concepto === null || ingEgState.concepto === undefined || ingEgState.concepto?.codigo <= 0) {
      error.push('Concepto: Debe elegir un movimiento.')
    }


    if (!result.isValid || error.length > 0) {
      setToast(genericErrorMensaje ?? 'Tiene errores, por favor verifiquelos', ToastTypes.Warning)
      setArrayErrors(error)
      setShowErrorPopup(true)
    } else {
      consoleService.log(ingEgState)
      consoleService.log(ingEgState?.dataSeries)
      consoleService.log(ingEgState?.dataLotes)

      const fechSave = ingEgState?.fecha ? DateUtils.format(ingEgState?.fecha, 'dd/MM/yyyy', 'yyyy-MM-dd') : DateUtils.getCurrentDateAsString()
      const documentoNom = `${ingEgState?.establecimeinto}-${ingEgState?.punto}-${ingEgState?.documento}`


      if (ingEgState.concepto !== null && ingEgState.concepto !== undefined && ingEgState.concepto?.asociado === ETypeMovimiento.ventas) {
        clienteCodigo = ingEgState.cliente?.codigo ?? 0
      } else if (ingEgState.concepto !== null && ingEgState.concepto !== undefined && ingEgState.concepto?.asociado === ETypeMovimiento.compras) {
        proveedorCodigo = ingEgState.proveedor?.codigo ?? 0
      }

      if (clienteCodigo !== 0) {
        proCli = clienteCodigo
      } else if (proveedorCodigo !== 0) {
        proCli = proveedorCodigo
      }

      const dataArraySave: Array<Detalle> = ingEgState.dataGrid.filter(x => x?.codigo > 0)

      const save_data: GuardarMovimiento = {
        infoRegistro: {
          codigo: ingEgState?.codigo ?? 0,
          movimiento: ingEgState.concepto?.codigo ?? 0,
          productoCliente: proCli,
          localCodigo: ingEgState.local?.codigo ?? 0,
          fecha: fechSave,
          documento: documentoNom,
          tipo: 'AJUSTES',
          observaciones: ingEgState?.observaciones ?? '',
          transito: 0,
          fechaCompra: fechSave,
          detalle: dataArraySave,
          series: ingEgState?.dataSeries,
          lotes: ingEgState?.dataLotes
        }
      }

      consoleService.log(save_data)

      dispatch(setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: ButtonTypes.save
      }))

      try {
        playLoader('Guardando...')
        const data = await incomeExpensesServices.setIncomeExpense(save_data)
        stopLoader()
        if (data !== null && data !== undefined && data['error'] === false) {
          consoleService.log('onHandleSvae')
          dispatch(setNameTabe({
            key: tabId,
            codigo: data['auto'],
            nombre: documentoNom,
          }))
          if (dataArraySave.length > 0) {
            dispatch(setChangesItemArray(dataArraySave))
          }
          dispatch(
            setButtons({
              tabKey: tabId,
              buttons: {
                ...ModuleButtons,
                Eliminar: false,
                Lotes: !verRegistro,
                Ver_asiento: !verRegistro,
                Importar: !verRegistro,
                Guardar: false,
                Limpiar: !verRegistro,
                Series: !verRegistro,
                Resumen: true,
                Imprimir: true,
              },
            }),
          )
          setToast(data['message'], ToastTypes.Success)
          onGenerate(data['auto'])
        } else {
          setToast(data['message'], ToastTypes.Danger)
        }
      } catch (error) {
        stopLoader()
        setToast(error.message, ToastTypes.Danger)
      }

      dispatch(setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: undefined
      }))
    }
  }, [setToast, ingEgState, playLoader, stopLoader, dispatch, tabId, onGenerate, onValidate, genericErrorMensaje, verRegistro])

  const onLoadSeries = React.useCallback((filaSerie: number, detalle: Array<any>) => {
    let cantidad: number = 0
    let existe: boolean = false
    consoleService.log(detalle)
    consoleService.log(filaSerie)
    ingEgState?.dataSeries.map(s => {
      if (s.codigo === detalle[filaSerie].codigo) {
        existe = true
        cantidad++
      }
    })
    consoleService.log(cantidad)
    consoleService.log(existe)
    if (existe === false || cantidad != detalle[filaSerie].cantidad) {
      const arraySeries: Array<Series> = []
      for (let index = 0; index < parseInt(detalle[filaSerie].cantidad); index++) {
        arraySeries.push({
          codigo: detalle[filaSerie]?.codigo ?? 0,
          barras: detalle[filaSerie]?.barras ?? '',
          descripcion: detalle[filaSerie]?.descripcion ?? '',
          serie: ingEgState?.dataSeries[index]?.serie ?? '',
          min: ingEgState?.dataSeries[index]?.min ?? ''
        })
      }
      consoleService.log('onLoadSeries')
      dispatch(onChangeSeries({
        key: tabId,
        series: arraySeries
      }))

    }
    setModalIngresoSeries(true)
  }, [ingEgState, dispatch, tabId])

  const onOpenSeries = React.useCallback(async (data: any) => {
    const provider = ingEgState?.dataGrid.slice(0)
    const index = await getIndexProvider(provider, 'codigo', data?.codigo ?? 0)
    if (index > -1) {
      onLoadSeries(index, provider)
    }
  }, [ingEgState?.dataGrid, onLoadSeries])

  const openModalLotes = React.useCallback((data: any) => {
    if (data !== null && data !== undefined && data?.codigo !== 0) {
      if (data?.lotes == 1) {
        setModalIngresoLotes(true)
      } else {
        setToast('El registro seleccionado no esta parametrizado para lotes', ToastTypes.Danger)
      }
    } else {
      setToast('Seleccione un item para ver los lotes', ToastTypes.Warning)
    }
  }, [setToast])

  const onConfirmMode = React.useCallback((type: null | "Viewer" | "Designer") => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Que impresion desea realizar?`,
      actions: [
        Dialog.Action(
          <span>
            <u>A</u>dmin
          </span>,
          (dialog) => {
            dialog.hide()
            dispatch(setDatosEdicion({
              key: tabId,
              data: {
                ...ingEgState,
                adm: true
              }
            }))
            setReporte(type)
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>B</u>odega
          </span>,
          (dialog) => {
            dialog.hide()
            dispatch(setDatosEdicion({
              key: tabId,
              data: {
                ...ingEgState,
                adm: false
              }
            }))
            setReporte(type)
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    return
  }, [ingEgState, dispatch, tabId])

  const handleButtonClick = React.useCallback(
    (buttonAction: ButtonTypes) => {
      switch (buttonAction) {
        case ButtonTypes.save:
          if (tabs.current === tabId) onHandleSave()
          break;
        case ButtonTypes.broom:
          if (tabs.current === tabId) onHandleBroom()
          break;
        case ButtonTypes.series:
          if (tabs.current === tabId) {
            if (selectedDetalle !== null && selectedDetalle !== undefined && selectedDetalle?.codigo !== 0) {
              if (selectedDetalle?.numeroSerie === 1) {
                onOpenSeries(selectedDetalle)
              } else {
                setToast('No hay numero de serie para el item seleccionado', ToastTypes.Warning)
              }
            } else {
              setToast('Seleccione un item para ver las series', ToastTypes.Warning)
            }
          }
          break;
        case ButtonTypes.lots:
          if (tabs.current === tabId) {
            openModalLotes(selectedDetalle)
          }
          break;
        case ButtonTypes.verAsiento:
          if (tabs.current === tabId) {
            if (ingEgState?.codigo !== 0 && ingEgState.concepto !== null && ingEgState.concepto !== undefined && ingEgState.concepto?.asiento === 1) {
              setShowModalAsiento(false)
            } else {
              setToast('No hay un concepto seleccionado o el concepto no tiene asiento', ToastTypes.Warning)
            }
          }
          break;
        case ButtonTypes.import:
          if (tabs.current === tabId) {
            if (ingEgState.local !== null && ingEgState.local !== undefined && ingEgState.local?.codigo > 0) {
              setShowImportButton(true)
            } else {
              setToast('Elija un local antes de subir los ítems', ToastTypes.Warning)
            }
          }
          break;
        case ButtonTypes.print:
          if (tabs.current === tabId) {
            onConfirmMode('Viewer')
          }
          break;
        case ButtonTypes.print_design:
          if (tabs.current === tabId) {
            onConfirmMode('Designer')
          }
          break;
        case ButtonTypes.edit:
          break;
        default:
          break
      }
      dispatch(setCurrentFunction(''))
      dispatch(clearButtonClick(tabId))
    },
    [dispatch, onHandleSave, onHandleBroom, tabId, tabs, ingEgState, setToast, selectedDetalle, onOpenSeries, openModalLotes, onConfirmMode])

  const onAddItem = React.useCallback(async (item: ItemPedido, isEnter: boolean = false) => {
    consoleService.log('onAddItem')
    consoleService.log(item, 'item')
    const errorsItem: Array<string> = []
    const cantidad = 1
    let dataArray = []
    let index: number = 0
    const data = { ...ingEgState }
    if (item === null || item === undefined) {
      errorsItem.push('Item: seleccione un Item para agregarlo.')
    }
    const precision = empresa !== null ? empresa.precision : 2;

    if (errorsItem.length === 0) {
      let data_grid = null
      const provider = ingEgState?.dataGrid.slice(0)
      dataArray = provider.filter(item => item.codigo > 0)
      index = await getIndexProvider(dataArray, 'codigo', item?.codigo ?? 0)
      if (index > - 1 && !data?.distinguir) {
        consoleService.log('existe')
        const existe: any = { ...dataArray[index] }
        existe.cantidad = parseInt(existe.cantidad) + cantidad
        existe.valor = existe.cantidad * existe.precio
        dataArray[index] = existe
      } else {
        consoleService.log('nuevo')
        const costo: any = Number(item?.costo).toFixed(precision) ?? 0;
        consoleService.log('costo', costo)
        data_grid = {
          codigo: item?.codigo ?? 0,
          impuesto: item?.impuesto ?? 0,
          codigoUsuario: item?.codigoInterno ?? '',
          barras: item?.codigoBarras ?? '',
          unidadDescripcion: item?.unidadDescripcion ?? '',
          descripcion: item?.descripcion ?? '',
          cantidad: cantidad ?? 0,
          lotes: parseInt(item?.lotes) ?? 0,
          pvp: item?.pvp ?? 0,
          pvd: item?.pvd ?? 0,
          precio: Number(costo),
          numeroSerie: item?.numeroSerie,
          valorDescripcion: (Number(costo) * cantidad) ?? 0,
          valor: (Number(costo) * cantidad) ?? 0,
          oCostos: 0,
          oCostosIVA: 0,
          fracion: 0, // REVISAR
        }
        dataArray.push(data_grid)
      }
      consoleService.log("dataArray", dataArray)


      dispatch(setDatosEdicion({
        key: tabId,
        data: {
          ...ingEgState,
          item: null,
          codigoItem: 0,
          cantidad: null
        }
      }))

      dispatch(onChangeDetall({
        key: tabId,
        detalle: [...dataArray, { ...newRow }],
      }))

      if (parseInt(data_grid?.lotes) == 1) {
        setSelectedDetalle(data_grid)
        openModalLotes(item)
      }

      if (data_grid?.numeroSerie == 1) {
        const provider = dataArray.slice(0)
        index = await getIndexProvider(provider, 'codigo', item?.codigo)
        if (index > -1) {
          onLoadSeries(index, dataArray)
        }
      }
      return false;


    } else {
      setToast('Tiene errores, por favor verifiquelos', ToastTypes.Warning)
      setArrayErrors(errorsItem)
      setShowErrorPopup(true)
    }
  }, [ingEgState, setToast, onLoadSeries, dispatch, tabId, openModalLotes, empresa, newRow])

  const onParseItem = React.useCallback((item: any) => {
    const itemBase: Detalle = {
      codigo: item?.itemCodigo ?? 0,
      impuesto: item?.itemImpuestoValor ?? 0,
      interno: '',
      codigoUsuario: item?.codigoInterno ?? '',
      barras: item?.itemBarras ?? '',
      unidadDescripcion: item?.itemUnidadCompraDescripcion ?? '',
      descripcion: item?.itemDescripcion ?? '',
      cantidad: 0,
      lotes: parseInt(item?.itemLotes) ?? 0,
      pvp: item?.itemPVP ?? 0,
      pvd: item?.itemPVD ?? 0,
      precio: Number(item?.itemPVP),
      numeroSerie: item?.itemNumeroSerieDescripcion === 'Si' ? 1 : 0,
      existencia: item?.itemStockMax ?? 0,
      costo: 0,
      valorDescripcion: 0,
      valor: 0,
      oCostos: 0,
      oCostosIVA: 0,
      fracion: 0,
      series: 0,
      stock: 0
    }
    return itemBase
  }, [])


  const adidedItem = React.useCallback((data: any[]) => {
    const newItem: any = {}
    data.map(item => {
      newItem.codigo = item?.itemCodigo ?? 0
      newItem.impuesto = item?.itemImpuestoValor ?? 0
      newItem.codigoInterno = item?.codigoInterno ?? ''
      newItem.codigoBarras = item?.itemBarras ?? ''
      newItem.unidadDescripcion = item?.itemUnidadCompraDescripcion ?? ''
      newItem.descripcion = item?.itemDescripcion ?? ''
      newItem.costo = 0
      newItem.lotes = parseInt(item?.itemLotes) ?? 0
      newItem.pvp = item?.itemPVP ?? 0
      newItem.pvd = item?.itemPVD ?? 0
      newItem.numeroSerie = item?.itemNumeroSerieDescripcion === 'Si' ? 1 : 0
      if (parseInt(item.itemStockMax) > 0) {
        onAddItem(newItem, true)
      }

    })
  }, [onAddItem])


  const onItemsValue = React.useCallback(async (data: ItemPedido) => {
    consoleService.log('onItemsValue ', data)
    if (data === null || data === undefined) {
      return
    }
    try {

      let get: boolean = false

      if (ingEgState.concepto !== null && ingEgState.concepto !== undefined && ingEgState.concepto?.tipo === 9) {
        get = true
      } else {

        if (data?.existencia <= 0 && parseInt(data?.jerarquia) != 1) {
          setToast('El ítem no tiene existencia disponible en este momento\nSeleccione otro ítem de la lista', ToastTypes.Danger);
          return false;
        } else {
          get = true
        }
      }
      if (parseInt(data?.jerarquia) != 1) {
        onAddItem(data, false)
        return
      }
      if (get) {
        playLoader('Buscando item base...')
        const fechaSearch = DateUtils.format(ingEgState?.fecha, 'dd/MM/yyyy', 'yyyy-MM-dd')
        const locCodigo = ingEgState?.local !== null ? ingEgState.local.codigo : -1
        const res: any = await InventarioService.getItemXCodigoBarras('', data?.codigo ?? 0, fechaSearch, true, locCodigo)
        stopLoader()
        consoleService.log(res, 'item base')
        if (res !== null && res !== undefined) {
          let arrayConjuntos: Array<Detalle> = []
          if (res['length'] === 1) {
            adidedItem(res)
          } else if (res['length'] > 1) {
            arrayConjuntos = res.map(item => {
              onParseItem(item)
            })
            dispatch(setDatosEdicion({
              key: tabId,
              data: {
                ...ingEgState,
                dataConjuntos: arrayConjuntos
              }
            }))
            setModalItemsConjutos(true)
          }
        } else if (res === null || res === undefined) {
          // onAddItem(data, false)
          setToast('El item con jerarquía AGRUPADOR no tiene items asociados.', ToastTypes.Info)
        }
      }
    } catch (error) {
      stopLoader()
      setToast(error.message, ToastTypes.Danger)
    }
  }, [ingEgState, playLoader, stopLoader, setToast, adidedItem, onAddItem, dispatch, tabId, onParseItem])

  const onLocalitem = React.useCallback((data) => {
    // onChangedValue(data, 'local')
    dispatch(setDatosEdicion({
      key: tabId,
      data: {
        ...ingEgState,
        local: data
      }
    }))
  }, [dispatch, ingEgState, tabId])

  const onChangeDate = React.useCallback((data) => {
    consoleService.log('onChangeDate')
    dispatch(setDatosEdicion({
      key: tabId,
      data: {
        ...ingEgState,
        fecha: data
      }
    }))
  }, [dispatch, ingEgState, tabId])

  const onChangeEst = React.useCallback((data) => {
    consoleService.log('onChangeEst')
    dispatch(setDatosEdicion({
      key: tabId,
      data: {
        ...ingEgState,
        establecimeinto: data
      }
    }))
  }, [dispatch, ingEgState, tabId])

  const onChangePunto = React.useCallback((data) => {
    consoleService.log('onChangePunto')
    dispatch(setDatosEdicion({
      key: tabId,
      data: {
        ...ingEgState,
        punto: data
      }
    }))
  }, [dispatch, ingEgState, tabId])


  const onChangeUnlock = React.useCallback((data) => {
    consoleService.log('onChangeUnlock')
    dispatch(setDatosEdicion({
      key: tabId,
      data: {
        ...ingEgState,
        desbloquear: data
      }
    }))
  }, [dispatch, ingEgState, tabId])

  const onChangeOptions = React.useCallback((data) => {
    consoleService.log('onChangeOptions')
    if (data.value === 0) {
      setShowOrden(false)
    } else {
      setShowOrden(true)
    }
    dispatch(setDatosEdicion({
      key: tabId,
      data: {
        ...ingEgState,
        tipoMovimiento: data
      }
    }))
  }, [dispatch, ingEgState, tabId])


  const onChangeObs = React.useCallback((data) => {
    consoleService.log('onChangeObs')
    dispatch(setDatosEdicion({
      key: tabId,
      data: {
        ...ingEgState,
        observaciones: data
      }
    }))
  }, [dispatch, ingEgState, tabId])

  const onChangeDesc12 = React.useCallback((data) => {
    consoleService.log('onChangeObs')
    dispatch(setDatosEdicion({
      key: tabId,
      data: {
        ...ingEgState,
        descuento12: data
      }
    }))
  }, [dispatch, ingEgState, tabId])

  const onChangeDesc0 = React.useCallback((data) => {
    consoleService.log('onChangeObs')
    dispatch(setDatosEdicion({
      key: tabId,
      data: {
        ...ingEgState,
        descuento0: data
      }
    }))
  }, [dispatch, ingEgState, tabId])

  const onChangeTot = React.useCallback((data) => {
    consoleService.log('onChangeObs')
    dispatch(setDatosEdicion({
      key: tabId,
      data: {
        ...ingEgState,
        total: data
      }
    }))
  }, [dispatch, ingEgState, tabId])

  const setCellCantidad = React.useCallback((rowData, value, currentRowData) => {
    let newCantidad: Array<any> = []
    if (currentRowData !== null && currentRowData !== undefined && value) {
      const detalle = ingEgState?.dataGrid ?? []
      newCantidad = detalle.map((det) => {
        if (currentRowData.codigo === det.codigo) {
          currentRowData.cantidad = value;
          currentRowData.valor = currentRowData.cantidad * currentRowData.precio
          currentRowData.valorDescripcion = currentRowData.valor
          return currentRowData
        }
        return det
      })
      dispatch(onChangeDetall({
        key: tabId,
        detalle: newCantidad,
      }))
      onTotales(newCantidad)
    }
  }, [onTotales, ingEgState, dispatch, tabId])

  const setCellCosto = React.useCallback((rowData, value, currentRowData) => {
    let newCantidad: Array<any> = []
    if (currentRowData !== null && currentRowData !== undefined && value) {
      const detalle = ingEgState?.dataGrid ?? []
      newCantidad = detalle.map((cost) => {
        if (currentRowData.codigo === cost.codigo) {
          currentRowData.precio = value;
          currentRowData.valor = currentRowData.cantidad * currentRowData.precio
          currentRowData.valorDescripcion = currentRowData.valor
          return currentRowData
        }
        return cost
      })
      dispatch(onChangeDetall({
        key: tabId,
        detalle: newCantidad,
      }))
      onTotales(newCantidad)
    }
  }, [onTotales, ingEgState, dispatch, tabId])

  const setCellVOriginal = React.useCallback((rowData, value, currentRowData) => {
    let newCantidad: Array<any> = []
    if (currentRowData !== null && currentRowData !== undefined && value) {
      const detalle = ingEgState?.dataGrid ?? []
      newCantidad = detalle.map((vorg) => {
        if (currentRowData.codigo === vorg.codigo) {
          return currentRowData
        }
        return vorg
      })
      dispatch(onChangeDetall({
        key: tabId,
        detalle: newCantidad,
      }))
      onTotales(newCantidad)
    }
  }, [onTotales, ingEgState, dispatch, tabId])

  const setCellValor = React.useCallback((rowData, value, currentRowData) => {
    let newCantidad: Array<any> = []
    if (currentRowData !== null && currentRowData !== undefined && value) {
      const detalle = ingEgState?.dataGrid ?? []
      newCantidad = detalle.map((val) => {
        if (currentRowData.codigo === val.codigo) {
          currentRowData.valor = value;
          currentRowData.precio = currentRowData.valor / currentRowData.cantidad;
          currentRowData.valorDescripcion = currentRowData.valor
          return currentRowData
        }
        return val
      })
      dispatch(onChangeDetall({
        key: tabId,
        detalle: newCantidad,
      }))
      onTotales(newCantidad)
    }
  }, [onTotales, ingEgState, dispatch, tabId])



  const onGetSeries = React.useCallback(async (codigo: number) => {
    try {
      let arraySeries: Array<any> = []
      const data_series = {
        tipo: 'MOVIMIENTOS',
        asociado: codigo,
      }
      const res = await incomeExpensesServices.getSeries(data_series)
      consoleService.log(res)
      if (res !== null && res !== undefined && res['error'] === false) {
        arraySeries = res['auto'].map(serie => {
          return {
            codigo: serie?.itemCodigo ?? 0,
            barras: serie?.itemBarras ?? '',
            descripcion: serie?.itemDescripcion ?? '',
            serie: serie?.itemSerieSerie ?? '',
            min: serie?.itemSerieMin ?? ''
          }
        })
        let cantidad: number = 0
        let existe: boolean = false

        ingEgState?.dataGrid.map((item) => {
          existe = false
          cantidad = 0
          if (item.numeroSerie === 1) {
            arraySeries.map(s => {
              if (s.codigo === item.codigo) {
                existe = true
                cantidad++
              }
            })
            if (existe === false || cantidad !== item.cantidad) {
              for (let index = 0; index < item.cantidad; index++) {
                arraySeries.push({
                  codigo: item?.codigo ?? 0,
                  barras: item?.barras ?? '',
                  descripcion: item?.descripcion ?? '',
                  serie: '',
                  min: ''
                })
              }
            }
          }
        });
      }
      return arraySeries
    } catch (error) {
      return []
    }
  }, [ingEgState])

  const onGetLotes = React.useCallback(async (codigo: number) => {
    try {
      let arrayLotes: Array<any> = []
      const data_series = {
        tipo: 'MOVIMIENTOS',
        codigo: codigo,
      }
      const res = await incomeExpensesServices.getLots(data_series)
      if (res !== null && res !== undefined && res['error'] === false) {
        arrayLotes = res['auto']
        consoleService.log(arrayLotes, 'getLotes')
      }
      return arrayLotes
    } catch (error) {
      return []
    }
  }, [])

  const onGetDeatilMove = React.useCallback(async (codigo: number) => {
    try {
      const res = await incomeExpensesServices.getIncomeExpensesDeatil(codigo)
      if (res !== null && res !== undefined && res['error'] === false) {
        return res['auto']
      }
      return []
    } catch (error) {
      return []
    }
  }, [])

  const onGetMovimientos = React.useCallback(async (codigo: number) => {
    try {
      const res = await getTypesMovements()
      if (res !== null && res !== undefined && res.length > 0) {
        const mov = res.find(item => item.codigo === codigo)
        return mov
      }
      return null
    } catch (error) {
      return null
    }
  }, [])


  const onGetProveedor = React.useCallback(async (codigo: number) => {
    try {
      const res = await ProveedoresService.getProveedorByCode(codigo)
      if (res !== null && res !== undefined && res?.codigo) {
        return res
      }
      return null
    } catch (error) {
      return null
    }
  }, [])

  const onGetCliente = React.useCallback(async (codigo: number) => {
    try {
      const cliente = await ClientesService.getCliente('', codigo);
      if (cliente !== null && cliente !== undefined && cliente?.codigo) {
        return cliente
      }
      return null
    } catch (error) {
      return null
    }
  }, [])




  const cargaMovimiento = React.useCallback(async (ingresosEgresosData) => {
    if (loading === false) {
      return
    }

    let proveedor = null
    let cliente = null
    const data = { ...defaultDataIncomeExpenses }
    consoleService.log(ingresosEgresosData)
    consoleService.log('cargaMovimiento')
    dispatch(
      setButtons({
        tabKey: tabId,
        buttons: {
          ...ModuleButtons,
          Eliminar: false,
          Lotes: !verRegistro,
          Ver_asiento: !verRegistro,
          Importar: !verRegistro,
          Guardar: !verRegistro,
          Limpiar: !verRegistro,
          Series: !verRegistro,
          Resumen: true,
          Imprimir: true,
        },
      }),
    )

    dispatch(setCurrentExecutingAction({
      tabKey: tabId,
      buttonType: ButtonTypes.edit
    }))


    try {

      playLoader('Cargando Registro...')

      const detalleMov = await onGetDeatilMove(ingresosEgresosData?.codigo)
      const movimiento = await onGetMovimientos(ingresosEgresosData?.movimiento)
      const movimientos = await getTypesMovements()
      const localesApi = await bancoService.getLocales(empresa.codigo, 'Elije una opción');
      if (movimientos.length > 0) {
        data.conceptosMovimientos = movimientos
      }
      const IVARes = await onGetIVA()
      const fechEdit = ingresosEgresosData?.fecha ? DateUtils.format(ingresosEgresosData?.fecha, 'yyyy-MM-dd', 'dd/MM/yyyy') : DateUtils.getCurrentDateAsString()
      if (movimiento !== null && movimiento !== undefined && movimiento?.asociado == ETypeMovimiento.compras) {
        proveedor = await onGetProveedor(ingresosEgresosData?.productoCliente)
      } else if (movimiento !== null && movimiento !== undefined && movimiento?.asociado == ETypeMovimiento.ventas) {
        cliente = await onGetCliente(ingresosEgresosData?.productoCliente)
      }
      const edDoc = ingresosEgresosData?.documento.split("-")
      const resSeries = await onGetSeries(ingresosEgresosData?.codigo ?? 0)
      if (resSeries.length > 0) {
        data.dataSeries = resSeries
      }
      const resLotes = await onGetLotes(ingresosEgresosData?.codigo ?? 0);
      if (resLotes.length > 0) {
        data.dataLotes = resLotes
      }

      if (edDoc.length >= 3) {
        data.establecimeinto = edDoc[0]
        data.punto = edDoc[1]
        data.documento = edDoc[2]
      } else {
        data.documento = ingresosEgresosData?.documento
      }

      if (detalleMov !== null && detalleMov !== undefined && detalleMov.length > 0) {
        data.dataGrid = detalleMov
      } else {
        setToast('El movimiento no posee detalle, si cree que es un error comuniquese de inmediato con el Administrador del sistema', ToastTypes.Info)
      }

      if (proyectos && proyectos.length > 0) {
        const proyectosData = generarOptionsProyectos(proyectos);
        data.proyectosData = proyectosData;
        if (proyectosData.length > 0) {
          const localesData = await generarLocales(proyectosData);
          consoleService.log(localesData)
          if (localesData) {
            data.locales = localesData;

            const index = await getIndexProvider(localesData, 'codigo', ingresosEgresosData?.localCodigo ?? 0)
            if (index > -1) {
              data.local = localesData[index]
            } else {
              data.local = localesData[0]
            }

            const indexProyecto = await getIndexProvider(proyectosData, 'codigo', data.local['proyectoCodigo'] ?? 0)
            consoleService.log(indexProyecto)
            if (indexProyecto > -1) {
              data.proyectos = proyectosData[indexProyecto]
            } else {
              data.proyectos = proyectosData[0]
            }
            consoleService.log(data.proyectos)
          }
        }

      }

      if (localesApi && localesApi.auto && localesApi.auto?.length > 0) {
        const localesData = generarOptionsLocales(localesApi.auto);
        data.locales = localesData;
        const index = await getIndexProvider(localesData, 'codigo', ingresosEgresosData?.localCodigo ?? 0)
        if (index > -1) {
          data.local = localesData[index]
        } else {
          data.local = localesData[0]
        }
      }



      data.codigo = ingresosEgresosData?.codigo
      data.IVA = IVARes
      data.concepto = movimiento
      data.distinguir = empresa?.distinguir === 1 ? true : false
      data.observaciones = ingresosEgresosData?.observaciones
      data.proveedor = proveedor
      data.cliente = cliente
      data.fecha = fechEdit
      data.dataGrid = [...detalleMov, { ...newRow }]
      data.tipoMovimiento = optionsMov[0]
      data.desdePlantilla = true
      data.loading = false

      stopLoader()

      dispatch(setDatosEdicion({
        key: tabId,
        data: data
      }))
      consoleService.log('cargaMovimientoe final')


    } catch (error) {
      stopLoader()
      setToast(error.message, ToastTypes.Danger)
    }

    dispatch(setCurrentExecutingAction({
      tabKey: tabId,
      buttonType: undefined
    }))
  }, [onGetSeries, playLoader, stopLoader, onGetLotes, setToast, dispatch, newRow,
    tabId, onGetDeatilMove, onGetCliente, onGetMovimientos, onGetProveedor, onGetIVA, proyectos,
    empresa, generarLocales, generarOptionsLocales, generarOptionsProyectos, loading, verRegistro])




  const onGetDatFile = React.useCallback((data: any) => {
    const dataState = { ...ingEgState }
    if (data !== null && data !== undefined && data['error'] === false) {
      let tempArray = []
      tempArray = data['auto'].map(item => {
        return item
      })
      consoleService.log(tempArray)
      dataState.dataGrid = [...tempArray[0], { ...newRow }]
      dataState.dataLotes = tempArray[1]
      dataState.dataSeries = tempArray[2]
      dataState.dataProCrear = tempArray[3]
      consoleService.log('onGetDatFile')
      dispatch(setDatosEdicion({
        key: tabId,
        data: dataState
      }))
      if (tempArray[3] && tempArray[3].length > 1) {
        // Popup visualizar pro crear
        setShowModalPorcrear(true)
      }
      onTotales(tempArray[0])
    } else {
      setToast(data['message'], ToastTypes.Danger)
    }
  }, [onTotales, setToast, dispatch, ingEgState, tabId, newRow])


  const onRemoveItem = React.useCallback(async (data: any) => {
    const dataState = { ...ingEgState }
    if (data !== null && data !== undefined && data?.codigo !== 0) {
      if (ingEgState?.dataGrid.length > 0) {
        let dataArray = []
        let dataLotes = []
        let dataSeries = []
        dataArray = ingEgState?.dataGrid.slice(0)
        const provider = dataArray.filter(item => item.codigo > 0)
        dataLotes = ingEgState?.dataLotes.filter(x => x.codigoItem !== data?.codigo)
        dataSeries = ingEgState?.dataSeries.filter(x => x.codigo !== data?.codigo)
        const index = await getIndexProvider(provider, 'codigo', data?.codigo)
        if (index > - 1) {
          provider.splice(index, 1)
          dataArray = provider
          onTotales(dataArray)
          dataState.dataGrid = [...dataArray, { ...newRow }]
          dataState.dataLotes = [...dataLotes]
          dataState.dataSeries = [...dataSeries]
          consoleService.log('onRemoveItem')
          dispatch(setDatosEdicion({
            key: tabId,
            data: dataState
          }))
        }
      }
    } else {
      setToast('Seleccione un item de la tabla para quitarlo', ToastTypes.Warning)
    }
  }, [setToast, onTotales, dispatch, ingEgState, tabId, newRow])

  const onSlectedItem = React.useCallback(({ selectedRowsData }) => {
    consoleService.log('detalle slected', selectedRowsData[0])
    setSelectedDetalle(selectedRowsData[0])
  }, [])

  const validateAsyncSeleccionTipoId = React.useCallback((datoSeleccion) => {
    return validateState(datoSeleccion)
  }, [validateState])



  const onValidateIdentificacion = React.useCallback(async (identificacion: string) => {
    try {
      playLoader()
      const validacionIdentificacion = await GeneralService.validarIndentificacion(identificacion ?? '')
      stopLoader()
      return validacionIdentificacion?.isValid
    } catch (error) {
      stopLoader()
      return false
    }
  }, [playLoader, stopLoader])

  const onGetPersonaIdentificacion = React.useCallback(async (identificacion: string) => {
    try {
      if (ingEgState.concepto?.asociado === ETypeMovimiento.compras) {
        playLoader()
        const provData = await ProveedoresService.getProveedor(identificacion)
        stopLoader()
        if (provData !== null && provData !== undefined && provData?.codigo) {
          // onChangedValue(provData, 'proveedor')
          dispatch(setDatosEdicion({
            key: tabId,
            data: {
              ...ingEgState,
              proveedor: provData
            }
          }))
        } else {
          setToast('No se encontro el proveedor, inténtelo nuevamente.', ToastTypes.Danger)
        }
      } else if (ingEgState.concepto?.asociado === ETypeMovimiento.ventas) {
        // CLIENTE
        playLoader()
        const clienteData = await ClientesService.getCliente(identificacion)
        stopLoader()
        if (clienteData !== null && clienteData !== undefined && clienteData?.codigo) {

          // onChangedValue(clienteData, 'cliente')
          dispatch(setDatosEdicion({
            key: tabId,
            data: {
              ...ingEgState,
              cliente: clienteData
            }
          }))

          if (clienteData?.tipoIdentificacion !== "06") {
            const validate = await onValidateIdentificacion(clienteData?.identificacion ?? '')
            if (validate) {
              setToast('Identificacion válida', ToastTypes.Success)
            }
          }
        } else {
          const validate = await onValidateIdentificacion(clienteData?.identificacion ?? '')
          if (!validate) {
            setToast('No se encontro el cliente', ToastTypes.Danger)
          }
        }
      }
    } catch (error) {
      stopLoader()
      setToast(error.message, ToastTypes.Danger)
    }
  }, [ingEgState, playLoader, stopLoader, setToast, onValidateIdentificacion, dispatch, tabId])

  const onDescuentos = React.useCallback(async (descuento: number, isEnter: boolean = false) => {
    consoleService.log('onDescuentos')
    consoleService.log('ingEgState', ingEgState)
    const { dataGrid } = ingEgState;
    if (dataGrid.length === 0) {
      return false;
    }
    let provider = []
    const precision = empresa !== null ? empresa.precision : 2;
    provider = dataGrid.slice() ?? [];
    provider = ingEgState?.dataGrid.map(item => {
      return { ...item }
    })
    consoleService.log('provider start', provider)

    provider.map(item => {
      if (item.valorDescripcion !== 0) {
        item.valor = Number(item?.valorDescripcion).toFixed(precision) ?? 0;
        item.precio = Number(item?.valorDescripcion / item?.cantidad).toFixed(precision) ?? 0;
      }
    })

    let subTotIva: number = 0
    let subTot0: number = 0
    provider.map(item => {
      if (item.impuesto === 3) {
        subTotIva += item?.valorDescripcion ?? 0
      } else {
        subTot0 += item?.valorDescripcion ?? 0
      }
    })
    let porDescIva: number = 0
    const desc = utilidades.stringToNumber(descuento.toString());
    porDescIva = (desc * 100) / subTotIva

    let porDesc0: number = 0
    porDesc0 = (desc * 100) / subTot0

    provider.map(item => {
      item.valorDescripcion = item.valor
      if (item.impuesto === 3) {
        item.valor = Number(item.valorDescripcion - (item.valorDescripcion * (porDescIva / 100))).toFixed(precision)
        item.precio = Number(item.valor / item.cantidad).toFixed(precision)
      } else {
        item.valor = Number(item.valorDescripcion - (item.valorDescripcion * (porDesc0 / 100))).toFixed(precision)
        item.precio = Number(item.valor / item.cantidad).toFixed(precision)
      }
    })

    consoleService.log('provider end', provider)

    dispatch(onChangeDetall({
      key: tabId,
      detalle: provider
    }))
  }, [ingEgState, empresa, tabId, dispatch])

  const onEnterDescountIva = React.useCallback((data) => {
    consoleService.log('onEnterDescountIva')
    consoleService.log('data', data)
    if (data?.component?._changedValue) {
      onDescuentos(data?.component?._changedValue, true);
    }
  }, [onDescuentos])

  const onEnterDescount = React.useCallback((data) => {
    consoleService.log('onEnterDescount')
    consoleService.log('data', data)
    if (data?.component?._changedValue) {
      onDescuentos(data?.component?._changedValue, true);
    }
  }, [onDescuentos])

  const onEnterOrden = React.useCallback(async () => {
    try {
      playLoader('Cargando Orden...')
      let res = null
      const dataState = { ...ingEgState }
      if (ingEgState.tipoMovimiento?.value === 1) {
        const mov = movimientos[1]
        // onChangedValue(mov, 'concepto')
        dispatch(setDatosEdicion({
          key: tabId,
          data: {
            ...ingEgState,
            concepto: mov
          }
        }))
        res = await OrdenPedidoService.getOrden(ingEgState?.ordenNumero)
        stopLoader()
        if (res !== null && res !== undefined && res?.codigo) {
          if (res?.detalle !== null && res?.detalle !== undefined && res?.detalle.length === 0) {
            if (ingEgState.local !== null && ingEgState.local !== undefined && ingEgState.local?.codigo > 0) {
              // onChangedValue(res.observaciones, 'observaciones')
              dispatch(setDatosEdicion({
                key: tabId,
                data: {
                  ...ingEgState,
                  observaciones: res.observaciones
                }
              }))
              if (res?.tipo === "ORD") {
                await onGetPersonaIdentificacion(res?.clienteIdentificacion)
                const detailOrder_data = {
                  codigo: res?.codigo ?? 0,
                  tipo: res?.tipo ?? '',
                }
                playLoader()
                const resOrderDetail = await incomeExpensesServices.getIncomeExpensesOrdersDetail(detailOrder_data)
                stopLoader()
                if (resOrderDetail !== null && resOrderDetail !== undefined && resOrderDetail['error'] === false) {
                  dataState.orden = resOrderDetail['auto']
                } else {
                  setToast(resOrderDetail['messsage'], ToastTypes.Danger)
                }
                onTotales(resOrderDetail['auto'])
              }
            } else {
              setToast('Seleccione un local para cargar detalle', ToastTypes.Danger)
            }
          } else {
            setToast('Ya existe un detalle precargado!!!', ToastTypes.Danger)
          }
        } else {
          setToast('Existen problemas al cargar el registro, posiblemente ya fue asigando a un movimiento o no existe el número ingresado', ToastTypes.Danger)
        }
      } else if (ingEgState.tipoMovimiento?.value === 2) {
        const codigo = ingEgState?.ordenNumero ?? 0
        res = await incomeExpensesServices.getIncomeExpensesDeatil(codigo)
        stopLoader()
        if (res !== null && res !== undefined && res['error'] === false) {
          dataState.dataGrid = res['auto']
          if (res['auto'][0].numero.toString() !== "") {
            // seOrden(null)
            dataState.orden = null
            setShowOrden(false)
            dataState.tipoMovimiento = optionsMov[0]
            // setOption(optionsMov[0])
          } else {
            dataState.orden = res['auto'][0].numero
            setShowOrden(true)
            dataState.tipoMovimiento = optionsMov[1]
            // setOption(optionsMov[1])
          }
          ordenOTarjeta()
          onTotales(res['auto'])
        } else {
          setToast(res['message'], ToastTypes.Danger)
        }
      }
      consoleService.log('onEnterOrden')
      dispatch(setDatosEdicion({
        key: tabId,
        data: dataState
      }))

    } catch (error) {
      stopLoader()
      setToast(error.message, ToastTypes.Danger)
    }
  }, [movimientos, setToast, playLoader, stopLoader, dispatch, tabId, ingEgState, onTotales, ordenOTarjeta, onGetPersonaIdentificacion])

  const extraerLocales = React.useCallback(async (obtenidos) => {
    const dataLocales = { ...ingEgState }
    if (obtenidos !== "") {
      if (obtenidos['codigo'] > 0) {
        dataLocales.locales = []
        const local_object: any = [];
        local_object.push({
          codigo: -1,
          nombre: "General"
        });
        obtenidos['locales'].forEach(function (tipo_I_) {
          if (tipo_I_ !== "false" && tipo_I_ !== false) {
            const to_array_proy: any = {
              codigo: Number(tipo_I_["codigo"]),
              nombre:
                tipo_I_["nombre"] === "Elija una opción"
                  ? "General"
                  : tipo_I_["nombre"],
              proyectoNombre: Number(tipo_I_?.proyectoNombre) ?? -1,
              proyectoCodigo: String(tipo_I_?.proyectoCodigo) ?? ""
            };
            local_object.push(to_array_proy);
          }
        });

        // dispatch(setLocales({ key: tabId, locales: local_object }))
        dataLocales.locales = local_object
        // dispatch(setLocal({ key: tabId, local: local_object[0] }))
        dataLocales.local = local_object[0]
        dispatch(setDatosEdicion({
          key: tabId,
          data: dataLocales
        }))
      }
    }
  }, [dispatch, tabId, ingEgState]);

  const onChangeProyecto = React.useCallback(async (ev) => {
    const dataChange = { ...ingEgState }
    if (ev) {
      if (ev.value !== -1) {
        extraerLocales(ev)
      } else {
        const localesData = await generarLocales(ingEgState?.proyectosData);
        if (localesData) {
          // dispatch(setLocales({ key: tabId, locales: localesData }))
          dataChange.locales = localesData
          // dispatch(setLocal({ key: tabId, local: localesData[0] }))
          dataChange.locales = localesData[0]
        }
      }
    } else {
      // dispatch(setLocales({ key: tabId, locales: localesAll }))
      let localSeleccionar = null
      const localSelect = ingEgState.locales.findIndex(x => Number(x.codigo) === Number(localSession.codigo))
      if (localSelect > -1) {
        localSeleccionar = ingEgState.locales[localSelect];
      } else {
        localSeleccionar = ingEgState.locales[0];
      }

      dataChange.local = localSeleccionar
      // dispatch(setLocal({ key: tabId, local: localSeleccionar }))

      dispatch(setDatosEdicion({
        key: tabId,
        data: dataChange
      }))

      // const localesData = await generarLocales(proyectos);
      // if (localesData) {
      //   dispatch(setLocales({ key: tabId, locales: localesData }))
      //   dispatch(setLocal({ key: tabId, local: localesData[0] }))
      // }
    }
  }, [
    extraerLocales, generarLocales, dispatch, tabId, localSession, ingEgState
  ]);

  React.useEffect(() => {
    if (confirmNew) {
      onConfirmNew()
    }
  }, [confirmNew, onConfirmNew])

  React.useEffect(() => {
    if (tab.editStatus === StatesEdition.new) {
      modoNuevo(tab?.info?.codigo)
    } else {
      cargaMovimiento(info.info)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab.globalButtonClick]);

  React.useEffect(() => {
    if (ingEgState.dataGrid) {
      onTotales(ingEgState.dataGrid)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ingEgState.dataGrid, ingEgState?.descuento12, ingEgState?.descuento0]);

  const modalAsiento = (
    <ModalAsiento
      show={showModalAsiento}
      onClose={() => setShowModalAsiento(false)}
      infoAsiento={selectedDetalle?.codigo}
      origenModulo={Number(modulo)}
    />
  )


  const seleccionarProveedor = () => {
    return (
      <Labeled label="Proveedor:">
        <BuscarProveedorLookUp
          selected={ingEgState?.proveedor as ProveedorBusqueda}
          disabled={verRegistro}
          allowAdd={!verRegistro}
          allowClear={!verRegistro}
          allowEdit={!verRegistro}
          onChanged={onChangedAsociado}
        />
      </Labeled>
    )
  }

  const seleccionarCliente = () => {
    return (
      <Labeled label="Cliente:">
        <BuscarClienteLookUp
          selected={ingEgState?.cliente}
          onChanged={onChangedAsociado}
          disabled={verRegistro}
          allowAdd={!verRegistro}
          allowClear={!verRegistro}
          allowEdit={!verRegistro} />
      </Labeled>
    )
  }

  const showDinamycLookUp = () => {
    if (ingEgState?.concepto?.asociado === ETypeMovimiento.ventas) {
      return seleccionarCliente()
    } else if (ingEgState?.concepto?.asociado === ETypeMovimiento.compras || ingEgState?.concepto?.asociado <= ETypeMovimiento.default) {
      return seleccionarProveedor()
    }
  }

  const renderSearchItems = React.useCallback(
    (data: any, rowIndex: number, columnIndex: number) => {
      if (
        (data.codigo || ingEgState.dataGrid[rowIndex]?.codigo) &&
        columnIndex === 2
      )
        return (
          <>
            {data.descripcion
              ? data.descripcion
              : ingEgState.dataGrid[rowIndex].descripcion}
          </>
        )
      else if (
        (data.codigo || ingEgState.dataGrid[rowIndex]?.codigo) &&
        columnIndex === 1
      )
        return (
          <>
            {data.barras ? data.barras : ingEgState.dataGrid[rowIndex].barras}
          </>
        )

      return (
        <>
          <BuscarItemPedidoMemoLookUp
            selected={null}
            onChanged={(item: ItemPedido) => {
              if (item) {
                if (parseInt(item.jerarquia) == 1) {
                  onItemsValue(item)
                } else {
                  onAddItem(item, false)
                }
              }
            }}
            cliente={
              ingEgState.cliente !== null
                ? ingEgState.cliente
                : null
            }
            fecha={DateUtils.getCurrentDateAsString(
              formatoFechasApi,
            )}
            autoComplete={true}
            prioridadBarras={columnIndex === 1 ? true : false}
            modulo={EModulosAcatha.MovimientosIngresosEgresos}
            tipoAsociado={ETipoAsociado.Clientes}
            movimiento={ingEgState?.concepto}
            local={ingEgState?.local !== null && ingEgState?.local !== undefined ? ingEgState?.local : undefined}
            codigoPrvAsociado={
              ingEgState?.proveedor?.codigo ?? -1
            }
          />
        </>
      )
    },
    [ingEgState, onItemsValue, onAddItem],
  )


  const onParseData = React.useCallback((ingresosEgresos: IngresosEgresosDatosEdicion) => {
    const registros: Array<IngresoEgresoDto> = [];
    let registro: IngresoEgresoDto = null
    let registrosItems: Array<Items> = [];
    let registroItem: Items = null

    let cliente = ''
    let proveedor = ''
    let asociado = ''
    let tipo = ''

    if (ingresosEgresos.concepto !== null && ingresosEgresos.concepto !== undefined && ingresosEgresos.concepto?.asociado === ETypeMovimiento.ventas) {
      cliente = ingresosEgresos.cliente?.nombres ?? ''
    } else if (ingresosEgresos.concepto !== null && ingresosEgresos.concepto !== undefined && ingresosEgresos.concepto?.asociado === ETypeMovimiento.compras) {
      proveedor = ingresosEgresos.proveedor?.nombre ?? ''
    }

    const dataArraySave: Array<any> = ingresosEgresos.dataGrid.filter(x => x?.codigo > 0)
    if (dataArraySave.length > 0) {
      registrosItems = dataArraySave.map(item => {
        registroItem = {
          Barcode: item?.barras ?? '',
          Unit: item?.unidadDescripcion ?? '',
          Description: item?.descripcion ?? '',
          Quantity: item?.cantidad ?? 0,
          UnitCost: item?.precio ?? 0,
          Original: item?.valorDescripcion ?? 0,
          Total: item?.valor ?? 0,
        }
        return registroItem
      })
    }

    if (cliente !== '') {
      asociado = cliente
    } else if (proveedor !== '') {
      asociado = proveedor
    }

    if (ingresosEgresos.concepto?.tipo === ETypeMovimientoCode.ingresos) {
      tipo = ETypeMovTitle.ingresos
    } else if (ingresosEgresos.concepto?.tipo === ETypeMovimientoCode.egresos) {
      tipo = ETypeMovTitle.egresos
    }

    registro = {
      Code: ingresosEgresos?.codigo ?? 0,
      MovType: tipo,
      Concep: ingresosEgresos.concepto?.descripcion ?? '',
      Associated: asociado,
      Date: ingresosEgresos?.fecha ?? '',
      Document: `${ingresosEgresos?.establecimeinto ?? ''}-${ingresosEgresos?.punto ?? ''}-${ingresosEgresos?.documento ?? ''}`,
      Boved: ingresosEgresos.local?.nombre ?? '',
      Items: registrosItems,
      SubTotalIva: ingresosEgresos?.subtotalIva ?? 0,
      SubTotal12: 0,
      SubTotal0: ingresosEgresos?.subtotalIva0 ?? 0,
      Discount12: ingresosEgresos?.descuento12 ?? 0,
      Discount0: ingresosEgresos?.descuento0 ?? 0,
      IVA: ingresosEgresos?.iva ?? 0,
      Total: ingresosEgresos?.total ?? 0,
    }
    registros.push(registro)
    consoleService.log(registros)
    return registros

  }, []);

  const onMoreOptions = React.useCallback(async () => {
    setMoreOptions(true)
  }, [])

  const onAddItemSet = React.useCallback((item: any) => {
    const data = { ...ingEgState }
    const tempItems = data.dataGrid.filter(x => x.codigo > 0)
    tempItems.push(item)
    data.dataGrid = [...tempItems, { ...newRow }]
    dispatch(setDatosEdicion({
      key: tabId,
      data: { ...data }
    }))
  }, [dispatch, ingEgState, newRow, tabId])


  return (
    <>
      <ReportWithLocalDataModal
        show={reporte !== null}
        onClose={() => setReporte(null)}
        data={onParseData(ingEgState)}
        fileName='StaticIngresoEgreso'
        mode={reporte ?? 'Viewer'}
        parameters={{ 'Reporte_Filtro': getFilterString(ingEgState), isAdmin: ingEgState?.adm }}
        template='StaticIngresoEgreso'
        key='reportDesigner'
      />
      {modalItemsConjutos && (<ModalItemsConjuntos
        onChanged={(data) => {
          setModalItemsConjutos(false)
          consoleService.log(data, 'item conjunto')
          onAddItemSet(data)
        }}
        onCancel={() => setModalItemsConjutos(false)}
        show={modalItemsConjutos}
        codModulo={1}
        dataItems={ingEgState?.dataConjuntos ?? []}
      />)}
      {moreOptions && (
        <MainModalItems
          transaccion="ventas"
          show={moreOptions}
          cliente={config.rucConsumidorFinal}
          onCancel={() => {
            setMoreOptions(false)
          }}
          returnItem={(e) => {
            setMoreOptions(false)
            let item = null
            item = { ...e }
            onItemsValue(item)
          }}
          modulo={EModulosAcatha.MovimientosIngresosEgresos}
          action={'none'}
          itemEditando={selectedDetalle}
          configFiltros={null}
          requestType={null}
          movimiento={ingEgState.concepto}
          codigoPrvAsociado={ingEgState?.proveedor?.codigo ?? -1}
          local={ingEgState?.local !== null && ingEgState?.local !== undefined ? ingEgState?.local : undefined}
        />
      )}
      {showModalPrecioItem && (
        <ModalModificarPrecioItem
          onChanged={(data) => consoleService.log(data)}
          onCancel={() => setShowModalPrecioItem(false)}
          show={showModalPrecioItem}
          codigoItem={ingEgState?.codigoItem}
        />
      )}
      {modalIngresoLotes && (
        <ModalIngresoLotes
          onChanged={(data) => {
            consoleService.log('onChange', data)
            dispatch(setDatosEdicion({
              key: tabId,
              data: {
                ...ingEgState,
                dataLotes: data
              }
            }))
            setModalIngresoLotes(false)
          }}
          onCancel={() => setModalIngresoLotes(false)}
          show={modalIngresoLotes}
          seleccionadoItems={{
            codigo: selectedDetalle?.codigo ?? 21,
            impuesto: selectedDetalle?.impuesto ?? 1,
            codigoUsuario: selectedDetalle?.codigoUsuario ?? 'test data',
            barras: selectedDetalle?.barras ?? 'test data',
            unidadDescripcion:
              selectedDetalle?.unidadDescripcion ?? 'test data',
            oCostos: selectedDetalle?.oCostos ?? 1,
            oCostosIVA: selectedDetalle?.oCostosIVA ?? 1,
            descripcion: selectedDetalle?.descripcion ?? 'test data',
            cantidad: selectedDetalle?.cantidad ?? 1,
            lotes: selectedDetalle?.lotes ?? 1,
            pvp: selectedDetalle?.pvp ?? 1,
            pvd: selectedDetalle?.pvd ?? 1,
            precio: selectedDetalle?.precio ?? 1,
            numeroSerie: selectedDetalle?.numeroSerie ?? 1,
            fracion: selectedDetalle?.fracion ?? 1,
            valorDescripcion: selectedDetalle?.valorDescripcion ?? 1,
            valor: selectedDetalle?.valor ?? 1,
            cantidadMovimiento: selectedDetalle?.cantidadMovimiento ?? 1,
          }}
          lotes={ingEgState?.dataLotes ?? []}
          detalleItems={ingEgState?.dataGrid ? ingEgState.dataGrid.filter(x => x.codigo > 0) : []}
          modulo={modulo}
          localCodigo={ingEgState?.local !== null ? ingEgState.local?.codigo : localSession.codigo}
          movimiento={ingEgState?.concepto}
        />
      )}
      {modalIngresoSeries && (
        <ModalIngresoSeries
          onChanged={(data) => {
            consoleService.log('series', data)
            dispatch(setDatosEdicion({
              key: tabId,
              data: {
                ...ingEgState,
                dataSeries: data
              }
            }))
            setModalIngresoSeries(false)
          }}
          onCancel={() => setModalIngresoSeries(false)}
          show={modalIngresoSeries}
          codModulo={modulo}
          dataSeries={ingEgState?.dataSeries}
          dataDetalle={ingEgState?.dataGrid ? ingEgState.dataGrid.filter(x => x.codigo > 0) : []}
          data={{
            local: ingEgState.local?.codigo ?? -1,
          }}
        />
      )}
      {showModalAsiento && modalAsiento}
      {showModalLocal && (
        <AdminModalLocales
          onChanged={(data) => {
            onLocalitem(data)
            setShowModalLocal(false)
          }}
          onCancel={() => setShowModalLocal(false)}
          show={showModalLocal}
          modulo={0}
        />
      )}
      {
        showModalPorcrear && <ModalVisualizarPorCrear
          onCancel={() => setShowModalPorcrear(false)}
          show={showModalPorcrear}
          porCrear={ingEgState?.dataProCrear ?? []}
        />
      }
      {showErrorPopup && (
        <VisualizaError
          titulo="Error en Ingreso/Egresos"
          mensaje={'Error al guardar'}
          content={
            <ListErrosValidationForm
              mainMessage={'Revise los siguientes errores:'}
              errorsList={arrayErrors}
              color={'danger'}
            />
          }
          onOk={() => setShowErrorPopup(false)}
        />
      )}
      <div style={{ overflowX: 'hidden' }}>
        <BlockUi
          tag="div"
          loader={LoadingIndicator}
          blocking={loader.show}
          message={loader.mensaje}
        >
          <fieldset disabled={verRegistro}>
            <RowContainer>
              <Dialog ref={dialogRef} />
              <ValidationGroup id={'formIngEg'} ref={validationFormRef}>
                <RowContainer>
                  {!isMobile && (
                    <CustomCol lg="6" md="8" sm="12">
                      <ValidationSummary id="summary"></ValidationSummary>
                    </CustomCol>
                  )}
                </RowContainer>
                <RowContainer>
                  <CustomCol xs="12" md={showImportButton ? '7' : '12'}>
                    <RowContainer>
                      <CustomCol xs="12" md="3">
                        <Labeled label="Concepto:">
                          <TiposMovimientosLookUp
                            provider={ingEgState?.conceptosMovimientos ?? []}
                            selected={ingEgState?.concepto ?? null}
                            onChanged={(e) => {
                              onChangedMovimiento(e)
                            }}
                            disabled={verRegistro}
                          >
                            <Validator>
                              <RequiredRule
                                message={'Concepto: Debe seleccionar una opción'}
                              />
                              <AsyncRule
                                message={'Concepto: Debe seleccionar una opción'}
                                validationCallback={validateAsyncSeleccionTipoId}
                              />
                            </Validator>
                          </TiposMovimientosLookUp>
                        </Labeled>
                      </CustomCol>
                    </RowContainer>
                    <RowContainer>
                      <CustomCol xs="12" md="7">
                        {ingEgState?.concepto !== null && showDinamycLookUp()}
                      </CustomCol>
                    </RowContainer>
                  </CustomCol>
                  {showImportButton && (
                    <CustomCol xs="12" md="5">
                      <Importar
                        tab={tab}
                        tabId={tabId}
                        openImport={(open) => setShowImportButton(open)}
                        data={{
                          local: ingEgState.local?.codigo ?? -1,
                        }}
                        moduleCode={modulo}
                        setData={(data) => onGetDatFile(data)}
                      />
                    </CustomCol>
                  )}
                </RowContainer>
                <RowContainer>
                  <CustomCol xs="12" md="2">
                    <Labeled label="Fecha:">
                      <DateBox
                        displayFormat="dd/MM/yyyy"
                        readOnly={verRegistro}
                        value={ingEgState?.fecha ?? ''}
                        onValueChange={(d) => {
                          onChangeDate(d)
                        }}
                      />
                    </Labeled>
                  </CustomCol>
                  <CustomCol xs="12" md="3">
                    <Labeled label="Documento:">
                      <RowContainer>
                        <CustomCol xs="3">
                          <TextBox
                            placeholder="001"
                            value={ingEgState?.establecimeinto ?? ''}
                            width={'100%'}
                            onValueChange={(d) => {
                              onChangeEst(d)
                            }}
                            maxLength={3}
                            valueChangeEvent="keyup"
                            name="establecimeinto"
                            hoverStateEnabled={true}
                            useMaskedValue={true}
                            onEnterKey={() => {
                              if (ingEgState?.establecimeinto.length === 3) {
                                inputPunto.current.instance.focus()
                              }
                            }}
                            onKeyDown={utilidades.filtraTeclasNoNumericas}
                            onKeyUp={(d: any) => {
                              if (
                                d?.component &&
                                d?.component['_changedValue'] &&
                                d?.component['_changedValue'].length === 3
                              ) {
                                inputPunto.current.instance.focus()
                              }
                            }}
                          />
                        </CustomCol>
                        <CustomCol xs="3">
                          <TextBox
                            ref={inputPunto}
                            placeholder="001"
                            value={ingEgState?.punto}
                            width={'100%'}
                            maxLength={3}
                            valueChangeEvent="keyup"
                            name="punto"
                            hoverStateEnabled={true}
                            useMaskedValue={true}
                            onValueChange={(d) => {
                              onChangePunto(d)
                            }}
                            onEnterKey={() => {
                              if (ingEgState?.punto.length === 3) {
                                inputDoc.current.instance.focus()
                              }
                            }}
                            onKeyDown={utilidades.filtraTeclasNoNumericas}
                            onKeyUp={(d: any) => {
                              if (
                                d?.component &&
                                d?.component['_changedValue'] &&
                                d?.component['_changedValue'].length === 3
                              ) {
                                inputDoc.current.instance.focus()
                              }
                            }}
                          />
                        </CustomCol>
                        <CustomCol>
                          <TextBox
                            ref={inputDoc}
                            placeholder="0000000000"
                            value={ingEgState?.documento ?? ''}
                            width={'100%'}
                            onValueChange={(d) => {
                              onChangedDocumento(d)
                            }}
                            maxLength={ingEgState?.desbloquear ? 50 : 9}
                            onKeyDown={utilidades.filtraTeclasNoNumericas}
                          >
                            <Validator>
                              <RequiredRule
                                message={'Documento: ' + sms['required']}
                              />
                              <StringLengthRule
                                message={
                                  'Documento: ' +
                                  sms['min'].replace(
                                    '%num_characters%',
                                    ingEgState?.desbloquear ? '50' : '9',
                                  )
                                }
                                min={ingEgState?.desbloquear ? 50 : 9}
                              />
                            </Validator>
                          </TextBox>
                        </CustomCol>
                      </RowContainer>
                    </Labeled>
                  </CustomCol>
                  <CustomCol xs="12" md="2">
                    <Labeled label="">
                      <CheckBox
                        text="Desbloquear"
                        value={ingEgState.desbloquear ?? null}
                        readOnly={verRegistro}
                        onValueChange={(d) => {
                          onChangeUnlock(d)
                        }}
                      />
                    </Labeled>
                  </CustomCol>
                </RowContainer>
                <RowContainer>
                  <CustomCol xs="12" md="5">
                    <Labeled label="Proyectos:">
                      <SelectBox
                        id="proyectos"
                        placeholder=""
                        disabled={verRegistro}
                        options={ingEgState?.proyectosData ?? []}
                        displayExpr="nombre"
                        keyExpr="codigo"
                        selected={ingEgState?.proyectos ?? null}
                        onChange={(selected) => {
                          onChangeProyecto(selected)
                          onChangedProyecto(selected)
                        }}
                        clearButton={true}
                      ></SelectBox>
                    </Labeled>
                  </CustomCol>
                  <CustomCol xs="12" md="5">
                    <Labeled label="Local:">
                      <div
                        style={{
                          display: 'flex',
                          gap: '1px',
                        }}
                      >
                        <SelectBox
                          id="locales"
                          placeholder=""
                          disabled={verRegistro}
                          options={ingEgState?.locales ?? []}
                          displayExpr="nombre"
                          keyExpr="codigo"
                          selected={ingEgState?.local ?? null}
                          onChange={onChangedLocal}
                          clearButton={true}
                        >
                          <Validator>
                            <RequiredRule
                              message={'Almacén: Debe seleccionar una opción'}
                            />
                            <AsyncRule
                              message={'Almacén: Debe seleccionar una opción'}
                              validationCallback={validateAsyncSeleccionTipoId}
                            />
                          </Validator>
                        </SelectBox>
                        {
                          ingEgState?.locales !== null && !verRegistro && (
                            <Button
                              id="btnAdd"
                              className="me-1"
                              icon="add"
                              stylingMode="contained"
                              type="default"
                              onClick={() => setShowModalLocal(true)}
                            />
                          )
                        }
                      </div>
                    </Labeled>
                  </CustomCol>
                  <CustomCol
                    xs="12"
                    md="2"
                    style={{ marginTop: 'auto', marginRight: 'auto' }}
                  >
                    {
                      !verRegistro && (
                        <CButton
                          id="btnConfirmar"
                          color="secondary"
                          className="m-1"
                          disabled={
                            ingEgState.local === null ||
                            ingEgState.local === undefined ||
                            ingEgState.local?.codigo === -1
                          }
                          size="sm"
                          onClick={() => onConfirmLoadLocal()}
                        >
                          {'Cargar Todo'}
                        </CButton>
                      )
                    }
                  </CustomCol>
                </RowContainer>
                {isMobile && (
                  <CustomCol lg="6" md="8" sm="12">
                    <ValidationSummary id="summary"></ValidationSummary>
                  </CustomCol>
                )}
              </ValidationGroup>
              <RowContainer>
                <CustomCol xs="12" md="2" className="text-right">
                  <Labeled label="">
                    <CheckBox
                      text="Acumular"
                      value={ingEgState.distinguir ?? null}
                      readOnly={verRegistro}
                      onValueChange={(d) => {
                        dispatch(setDatosEdicion({
                          key: tabId,
                          data: {
                            ...ingEgState,
                            distinguir: d
                          }
                        }))
                      }}
                    />
                  </Labeled>
                </CustomCol>
              </RowContainer>
              {
                (ingEgState?.concepto === null || ingEgState.concepto?.codigo <= 0 || ingEgState?.local === null || ingEgState.local?.codigo <= 0) ? (

                  <RowContainer className="infoButton">
                    <CustomCol md="1" xs="12" className="text-right">
                      <RowContainer>
                        <CustomCol lg="12">
                          <span>
                            <CTooltip placement={'top'} content={'Por favor selecciona el local y el concepto.'}>
                              <CButton id={'infoAsiento'} color="info" size="sm">
                                <FontAwesomeIcon size="lg" icon={['fal', 'info']} style={{ fontWeight: 1 }} />
                              </CButton>
                            </CTooltip>
                          </span>
                        </CustomCol>
                      </RowContainer>
                    </CustomCol>
                  </RowContainer>
                ) :
                  (
                    <RowContainer className="infoButton">
                      <CustomCol md="1" xs="12" className="text-right">
                        <RowContainer>
                          <CustomCol lg="12">
                            <CTooltip content="Más Opciónes">
                              <div>
                                <Button
                                  className="ms-1"
                                  stylingMode="contained"
                                  type="default"
                                  icon="more"
                                  onClick={() => {
                                    onMoreOptions()
                                  }}
                                ></Button>
                              </div>
                            </CTooltip>
                          </CustomCol>
                        </RowContainer>
                      </CustomCol>
                    </RowContainer>
                  )
              }
              <RowContainer>
                <div
                  className="mt-1 mb-1"
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: '2px',
                    width: '100%',
                  }}
                >
                  {ingEgState.dataSeries.length > 0 && (
                    <CBadge color="success">{`${ingEgState.dataSeries.length} SERIE(S) ENCONTRADO(S)`}</CBadge>
                  )}
                  {ingEgState.dataGrid.length > 0 && (
                    <CBadge color="info">{`${ingEgState.dataGrid.length} ITEM(S) ENCONTRADO(S)`}</CBadge>
                  )}
                </div>
                <DataGrid
                  style={{ marginTop: '10px' }}
                  ref={dataGrid}
                  id="codigo"
                  focusedRowKey="codigo"
                  selection={{ mode: 'single' }}
                  disabled={ingEgState.concepto?.codigo <= 0 || ingEgState.local?.codigo <= 0 || verRegistro}
                  dataSource={ingEgState.dataGrid}
                  showColumnLines={true}
                  showRowLines={true}
                  repaintChangesOnly
                  showBorders={true}
                  columnResizingMode="widget"
                  onSelectionChanged={onSlectedItem}
                  loadPanel={{
                    enabled: true,
                    height: 90,
                    indicatorSrc: '',
                    shading: false,
                    shadingColor: '',
                    showIndicator: true,
                    showPane: true,
                    text: 'Cargando...',
                    width: 200,
                  }}
                >
                  <Editing
                    mode="cell"
                    allowUpdating
                    changes={changes}
                    onChangesChange={onChangesChange}
                    editRowKey={editRowKey}
                    onEditRowKeyChange={onEditRowKeyChange}
                  />
                  <Paging defaultPageSize={10} />
                  <Pager
                    visible={ingEgState.dataGrid.length > 0 ? true : false}
                    allowedPageSizes={getAllowedPageSizes(ingEgState.dataGrid)}
                    displayMode={'full'}
                    showPageSizeSelector={true}
                    showInfo={true}
                    showNavigationButtons={true}
                  />
                  <ColumnChooser enabled={true} mode="select" />
                  <Column type="buttons" width='50px'>
                    <DatagridButton
                      icon="trash"
                      hint="Eliminar"
                      visible={(e) => {
                        return e.row?.data.codigo > 0
                      }}
                      disabled={(e) => {
                        consoleService.log('e', e.row?.data)
                        return e.row?.data.codigo <= 0
                      }}
                      onClick={async (e) => {
                        consoleService.log('e', e.row?.data)
                        onRemoveItem(e.row?.data)
                      }}
                    />
                  </Column>
                  <Column
                    allowEditing={false}
                    dataField="codigoUsuario"
                    caption="Interno"
                    visible={false}
                    width="50px"
                  />
                  <Column
                    allowEditing={false}
                    dataField="barras"
                    cellRender={({ data, rowIndex, columnIndex }) =>
                      renderSearchItems(data, rowIndex, columnIndex)}
                    caption="Barras"
                    width="250px"
                  />

                  <Column
                    allowEditing={false}
                    dataField="descripcion"
                    cellRender={({ data, rowIndex, columnIndex }) =>
                      renderSearchItems(data, rowIndex, columnIndex)}
                    caption="Item"
                    width="250px"
                  />
                  <Column
                    allowEditing={false}
                    dataField="unidadDescripcion"
                    caption="Unidad"
                    width="100px"
                  />
                  <Column
                    allowEditing={false}
                    dataField="pvp"
                    caption="PVP IVA"
                    width="11px"
                    visible={false}
                  />
                  <Column
                    allowEditing={true}
                    setCellValue={setCellCantidad}
                    format={{ precision: empresa?.precision ?? 2 }}
                    dataType='number'
                    dataField="cantidad"
                    caption="Cantidad"
                    width="100px"
                  />
                  <Column
                    allowEditing={true}
                    setCellValue={setCellCosto}
                    format={{ precision: empresa?.precision ?? 2 }}
                    dataType='number'
                    dataField="precio"
                    caption="Costo Unitario"
                    width="100px"
                  />
                  <Column
                    allowEditing={true}
                    setCellValue={setCellVOriginal}
                    format={{ precision: empresa?.precision ?? 2 }}
                    dataType='number'
                    dataField="valorDescripcion"
                    caption="Valor Original"
                    width="100px"
                  />
                  <Column
                    allowEditing={true}
                    setCellValue={setCellValor}
                    format={{ precision: empresa?.precision ?? 2 }}
                    dataType='number'
                    dataField="valor"
                    caption="Total"
                    width="100px"
                  />
                </DataGrid>
              </RowContainer>
              <RowContainer>
                <CustomCol xs="12" md="5">

                  <RowContainer>
                    <CustomCol md="12" className="d-flex justify-content-start">
                      <Labeled label=''>
                        <RadioGroup
                          name="iOptionsMov"
                          dataSource={optionsMov}
                          disabled={verRegistro}
                          value={ingEgState?.tipoMovimiento ?? optionsMov[0]}
                          layout="horizontal"
                          displayExpr="label"
                          onValueChange={(d) => {
                            onChangeOptions(d)
                          }}
                          width="100%"
                        />
                      </Labeled>
                    </CustomCol>
                  </RowContainer>
                  <RowContainer className="mt-2">
                    {showOrden && (
                      <CustomCol md="12" className="d-flex justify-content-start">
                        <Labeled label="Orden:" position="left">
                          <NumberBox
                            value={ingEgState?.ordenNumero ?? null}
                            onValueChange={(data) => {
                              dispatch(
                                setDatosEdicion({
                                  key: tabId,
                                  data: {
                                    ...ingEgState,
                                    ordenNumero: data,
                                  },
                                }),
                              )
                            }}
                            showClearButton={true}
                            width="100%"
                            inputAttr={{
                              autocomplete: 'nope',
                              class: 'textInputAlignRight',
                            }}
                            onEnterKey={onEnterOrden}
                          />
                        </Labeled>
                      </CustomCol>
                    )}
                  </RowContainer>
                </CustomCol>
                <CustomCol xs="12" md="4" className="m2-start m2-end">
                  <Labeled label="Observaciones:">
                    <TextArea
                      name="observaciones"
                      value={ingEgState?.observaciones ?? ''}
                      onValueChange={(d) => {
                        onChangeObs(d)
                      }}
                      inputAttr={{
                        autocomplete: 'nope',
                      }}
                    />
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="3">
                  <RowContainer className="mt-2">
                    <CustomCol md="12" className="d-flex justify-content-end">
                      <Labeled label="Subtotal IVA:" position="left">
                        <NumberBox
                          value={ingEgState?.subtotalIva ?? null}
                          format={{ precision: empresa?.precision ?? 2 }}
                          showClearButton={true}
                          width="115px"
                          inputAttr={{
                            autocomplete: 'nope',
                            class: 'textInputAlignRight',
                          }}
                          readOnly
                        />
                      </Labeled>
                    </CustomCol>
                  </RowContainer>
                  <RowContainer>
                    <CustomCol md="12" className="d-flex justify-content-end">
                      <Labeled label="Subtotal IVA 0%:" position="left">
                        <NumberBox
                          value={ingEgState?.subtotalIva0 ?? null}
                          format={{ precision: empresa?.precision ?? 2 }}
                          showClearButton={true}
                          width="115px"
                          inputAttr={{
                            autocomplete: 'nope',
                            class: 'textInputAlignRight',
                          }}
                          readOnly
                        />
                      </Labeled>
                    </CustomCol>
                  </RowContainer>
                  <RowContainer>
                    <CustomCol md="12" className="d-flex justify-content-end">
                      <Labeled
                        label={`Descuento ${ingEgState.IVA}:`}
                        position="left"
                      >
                        <NumberBox
                          value={ingEgState?.descuento12 ?? null}
                          showClearButton={true}
                          readOnly={verRegistro}
                          format={{ precision: empresa?.precision ?? 2 }}
                          width="115px"
                          inputAttr={{
                            autocomplete: 'nope',
                            class: 'textInputAlignRight',
                          }}
                          onValueChange={(d) => {
                            onChangeDesc12(d)
                          }}
                          onEnterKey={(d) => {
                            onEnterDescountIva(d)
                          }}
                        />
                      </Labeled>
                    </CustomCol>
                  </RowContainer>
                  <RowContainer>
                    <CustomCol md="12" className="d-flex justify-content-end">
                      <Labeled label="Descuento 0:" position="left">
                        <NumberBox
                          value={ingEgState?.descuento0 ?? null}
                          showClearButton={true}
                          format={{ precision: empresa?.precision ?? 2 }}
                          width="115px"
                          readOnly={verRegistro}
                          inputAttr={{
                            autocomplete: 'nope',
                            class: 'textInputAlignRight',
                          }}
                          onValueChange={(d) => {
                            onChangeDesc0(d)
                          }}
                          onEnterKey={(d) => onEnterDescount(d)}
                        />
                      </Labeled>
                    </CustomCol>
                  </RowContainer>
                  <RowContainer>
                    <CustomCol md="12" className="d-flex justify-content-end">
                      <Labeled label="IVA" position="left">
                        <NumberBox
                          value={ingEgState?.iva ?? null}
                          showClearButton={true}
                          format={{ precision: empresa?.precision ?? 2 }}
                          width="115px"
                          inputAttr={{
                            autocomplete: 'nope',
                            class: 'textInputAlignRight',
                          }}
                          readOnly
                        />
                      </Labeled>
                    </CustomCol>
                  </RowContainer>
                  <RowContainer>
                    <CustomCol md="12" className="d-flex justify-content-end">
                      <Labeled label="TOTAL:" position="left">
                        <NumberBox
                          value={ingEgState?.total ?? null}
                          format={{ precision: empresa?.precision ?? 2 }}
                          showClearButton={true}
                          readOnly={verRegistro}
                          width="115px"
                          inputAttr={{
                            autocomplete: 'nope',
                            class: 'textInputAlignRight',
                          }}
                          onValueChange={(d) => {
                            onChangeTot(d)
                          }}
                        />
                      </Labeled>
                    </CustomCol>
                  </RowContainer>
                </CustomCol>
              </RowContainer>
            </RowContainer>
          </fieldset>
        </BlockUi>
      </div>
    </>
  )
}

export const footerStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: '4px',
}

export default React.memo(Nuevo)


export const defaultDataIncomeExpenses: IngresosEgresosDatosEdicion = {
  codigo: 0,
  concepto: null,
  conceptosMovimientos: [],
  proveedor: null,
  cliente: null,
  fecha: DateUtils.getCurrentDateAsString(),
  documento: '',
  loading: false,
  desbloquear: false,
  proyectos: null,
  proyectosData: [],
  loader: {
    show: false,
    mensaje: ''
  },
  local: null,
  locales: [],
  IVA: null,
  dataGrid: [],
  dataConjuntos: [],
  dataSeries: [],
  dataLotes: [],
  dataProCrear: [],
  orden: null,
  ordenNumero: null,
  tipoMovimiento: null,
  item: null,
  codigoItem: 0,
  distinguir: true,
  barras: '',
  unidadDescripcion: '',
  interno: '',
  impuesto: null,
  existencia: null,
  descripcion: '',
  cantidad: null,
  lotes: null,
  numeroSerie: null,
  costo: null,
  pvp: null,
  pvd: null,
  stock: null,
  unidad: '',
  establecimeinto: '',
  punto: '',
  numFact: '',
  numArt: null,
  maxDoc: 0,
  subtotalIva: 0,
  subtotalIva0: 0,
  descuento12: 0,
  descuento0: 0,
  iva: 0,
  total: 0,
  observaciones: '',
  guardado: false,
  desdePlantilla: false,
  adm: false
}
