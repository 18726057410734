import { DataGrid } from 'devextreme-react'
import React, { FunctionComponent, PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react'
import CustomCol from '../../../../../../views/componentes/colContainer'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import { Column, ColumnChooser, Editing, Export, Lookup, Pager, Paging, Button as DatagridButton, MasterDetail } from 'devextreme-react/data-grid'
import ItemsCountIndicator from '../../../../../componentes/indicadorNroRegistros'
import DataSource from 'devextreme/data/data_source'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/store'
import { setCollapsed, setSeleccionarDato } from '../../store/searchReducer'
import { allowedPageSizes } from '../../../../../../helpers/Helper'
import { Workbook } from 'exceljs'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { saveAs } from 'file-saver';
import { consoleService } from '../../../../../../services/console.service'
import { NivelesEstudioOption } from '../../../../../componentes/nivelesEstudioLookUp/store/type'
import { estudiosService } from '../../../../../componentes/nivelesEstudioLookUp/service/estudios.service'
import { jornadaService } from '../../../../../componentes/jornadaLookUp/services/joranda.services'
import { TEstado } from '../../../../../../store/types'
import { isMobile } from 'react-device-detect'
import { ContratosList } from '../../types/types'
import { ActionSheetCommandsMobile } from './ActionSheetCommandsMobile'
import DetalleContrato from './detalleContrato'

interface IResultsProps extends PropsWithChildren {
  onDBClick: () => void
  // eslint-disable-next-line no-unused-vars
  handleOptionChange: (e: any) => void
  data: DataSource
  pageIndex: number
  pageSize: number
  onDelete: () => void
}

const ResultadoBusqueda: FunctionComponent<IResultsProps> = (props) => {

  const { onDBClick, data, handleOptionChange, pageIndex, pageSize, onDelete } =
    props
  const tablaRefPager = useRef<any>()
  const dispatch = useDispatch()
  const [isPagin] = useState<boolean>(true)
  const [changes, setChanges] = React.useState<any>()
  const [editRowKey, setEditRowKey] = React.useState<any>()

  const [optLevels, setOptLevel] = useState<NivelesEstudioOption[] | null>()
  const [optJor, setOptJor] = useState<TEstado[] | null>()
  const [optCalificated,] = useState<TEstado[] | null>(
    [
      { codigo: 0, descripcion: 'NO' },
      { codigo: 1, descripcion: 'SI' },
    ]
  )

  const onloadsLookups = React.useCallback(async () => {
    const tipoJornada = await jornadaService.getJornadaLabolar('Elija una opción')
    const niveles = await estudiosService.getEstados("Elija una opción");
    if (niveles['auto'] && niveles['error'] === false) {
      setOptLevel(niveles['auto'] ?? [])
    }
    if (tipoJornada['auto'] && tipoJornada['error'] === false) {
      setOptJor(tipoJornada['auto'] ?? [])
    }
  }, [])

  const totalCountContrats = useSelector((state: RootState) => {
    return state.nomina.contratos.search.nroRegistrosEncontrados
  })

  const onCollapsed = useCallback(async () => {
    if (totalCountContrats > 5) {
      dispatch(setCollapsed(false))
    }
  }, [dispatch, totalCountContrats])


  useEffect(() => {
    onCollapsed()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCountContrats])

  useEffect(() => {
    onloadsLookups()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onChangesChange = React.useCallback((changes) => {
    setChanges(changes)
  }, [])

  const onEditRowKeyChange = React.useCallback((editRowKey) => {
    setEditRowKey(editRowKey)
  }, [])

  const onSelectedChanged = useCallback(
    ({ selectedRowsData }) => {
      consoleService.log(selectedRowsData[0], 'seleccionado selectedRowsData[0]')
      if (selectedRowsData[0] && selectedRowsData[0]?.codigo) {
        dispatch(
          setSeleccionarDato({ ...selectedRowsData[0] }),
        )
      }
    },
    [dispatch],
  )

  const onExportExcell = useCallback((e) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('Main sheet')
    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'Contratos_Nomina.xlsx',
        )
      })
    })
  }, [])

  const actionSheetCommandsMobile = React.useCallback(
    (cellData) => {
      const data = cellData.data as any
      return (
        <ActionSheetCommandsMobile
          contract={data}
          key={data.codigo}
          onDelete={(data) => {
            dispatch(setSeleccionarDato({ ...data }))
            onDelete()
          }}
          onEdit={(data) => {
            dispatch(setSeleccionarDato({ ...data }))
            onDBClick()
          }}
        />
      )
    },
    [onDelete, dispatch, onDBClick],
  )


  return (
    <>
      <ItemsCountIndicator numberOfItems={data ? totalCountContrats : 0} />
      <RowContainer>
        <CustomCol xs="12" md="12">
          <DataGrid
            id="tablaSeguimientoRegistros"
            ref={tablaRefPager}
            dataSource={data ?? []}
            selection={{ mode: 'single' }}
            hoverStateEnabled={true}
            showBorders={true}
            showRowLines={true}
            onSelectionChanged={onSelectedChanged}
            onRowDblClick={onDBClick}
            repaintChangesOnly={true}
            highlightChanges={true}
            keyExpr="uuid"
            key="uuid"
            focusedRowKey="uuid"
            showColumnLines={true}
            onExporting={onExportExcell}
            className={'m-2 p-2'}
            onOptionChanged={handleOptionChange}
            remoteOperations={true}
            loadPanel={{
              enabled: true,
              height: 90,
              indicatorSrc: '',
              shading: false,
              shadingColor: '',
              showIndicator: true,
              showPane: true,
              text: 'Cargando...',
              width: 200,
            }}
          >

            <Editing
              mode="cell"
              refreshMode={'repaint'}
              allowUpdating
              changes={changes}
              onChangesChange={onChangesChange}
              editRowKey={editRowKey}
              onEditRowKeyChange={onEditRowKeyChange}
            />
            <Paging
              pageIndex={pageIndex}
              pageSize={pageSize}
              enabled={isPagin}
            />
            <Pager
              ref={tablaRefPager}
              showPageSizeSelector={true}
              allowedPageSizes={allowedPageSizes}
              showNavigationButtons={true}
              showInfo={true}
              infoText="Page #{0}. Total: {1} ({2} items)"
            />

            <Export enabled={true} allowExportSelectedData={true} />
            <ColumnChooser enabled={true} mode="select" />

            {
              !isMobile && (
                <Column allowEditing={false} type="buttons" width="50px">
                  <DatagridButton
                    icon="trash"
                    hint="Eliminar"
                    onClick={async (e) => {
                      consoleService.log('e', e)
                      const data: ContratosList = e.row.data
                      dispatch(setSeleccionarDato({ ...data }))
                      onDelete()
                    }}
                  />
                  <DatagridButton
                    icon="edit"
                    hint="Editar"
                    onClick={async (e) => {
                      consoleService.log('e', e)
                      const data: ContratosList = e.row.data
                      dispatch(setSeleccionarDato({ ...data }))
                      onDBClick()
                    }}
                  />
                </Column>
              )
            }

            {isMobile && (
              <Column
                allowEditing={false}
                caption="Tareas"
                dataField="codigo"
                cellRender={actionSheetCommandsMobile}
                width="50px"
                allowFiltering={false}
              ></Column>
            )}

            {!isMobile && (
              <MasterDetail
                enabled={true}
                render={(params) => (
                  <>
                    <DetalleContrato {...params} />{''}
                  </>
                )}
              />
            )}
            <Column dataField="codigo" caption="Nro." width="100px" />
            <Column allowEditing={false} dataField="usuarioCedula" caption="Identificación" width="100px" />
            <Column allowEditing={false} dataField="usuarioNombre" caption="Nombres" width="100px" />
            <Column allowEditing={false} dataField="rolDescripcion" caption="Cargo" width="100px" />
            <Column allowEditing={false} dataField="tituloDescripcion" caption="Titulo" width="100px" />
            <Column dataField="nivelEstudiosCodigo" caption="Estudios" width="100px" >
              <Lookup dataSource={optLevels} valueExpr='codigo' displayExpr='descripcion' />
            </Column>
            <Column dataField="calificado" caption="Calificado" width="50px" >
              <Lookup dataSource={optCalificated} valueExpr='codigo' displayExpr='descripcion' />
            </Column>
            <Column dataField="jornada" caption="Jor" width="100px" >
              <Lookup dataSource={optJor} valueExpr='codigo' displayExpr='descripcion' />
            </Column>
            <Column allowEditing={false} dataField="fechaIngreso" caption="Inicio" width="100px" />
            <Column dataField="fechaSalida" dataType="date" caption="Fin" width="100px" />
            <Column allowEditing={false} dataField="proceso" caption="Proceso" width="50px" />
            <Column dataField="observacionesSalida" caption="Obs. Salida" width="100px" />
            <Column dataField="nominal" dataType='number' caption="Sueldo" width="100px" />
          </DataGrid>
        </CustomCol>
      </RowContainer>
    </>
  )
}

export default React.memo(ResultadoBusqueda)