import { RequestHelper } from '../../../../../../helpers/requestHelper'
import { SaveTransfer, SearchFilters, SearchUsers } from '../types/types'

export const TransfersServices = {
  verifyStatus,
  getTransfersAll,
  getTransfersDetails,
  getUsers,
  getAllItemsStore,
  getAllItemsOriginDestination,
  getAllItemsPurchase,
  getAllItemsMovement,
  getLastNumber,
  saveTransfer,
  getTransferDetailAll,
  deleteTransfer,
  getSeriesItem,
  setArchivedTranfer,
}

async function verifyStatus(): Promise<any> {
  try {
    const res = await RequestHelper.getAll<any>(
      'inventario/movimientos',
      'transferencias/verifyStatus',
      '',
      {},
    )
    return res
  } catch (error) {
    return error
  }
}

async function getTransfersAll(searchFilter: SearchFilters): Promise<any> {
  try {
    const res = await RequestHelper.getAll<any>(
      'inventario/movimientos',
      'transferencias/getAll',
      '',
      searchFilter,
    )
    return res
  } catch (error) {
    return error
  }
}

async function getTransfersDetails(searchFilter: SearchFilters): Promise<any> {
  try {
    const res = await RequestHelper.getAll<any>(
      'inventario/movimientos',
      'transferencias/getAllDetail',
      '',
      searchFilter,
    )
    return res
  } catch (error) {
    return error
  }
}

async function getUsers(searchFilter: SearchUsers): Promise<any> {
  try {
    const res = await RequestHelper.getAll<any>(
      'nomina',
      'loadEmployes',
      '',
      searchFilter,
    )
    return res
  } catch (error) {
    return error
  }
}

async function getAllItemsStore(store: number, date: string): Promise<any> {
  try {
    const res = await RequestHelper.getAll<any>(
      'inventario/movimientos',
      'transferencias/',
      'items/searchItemsAllLocal',
      { local: store, fecha: date },
    )
    return res
  } catch (error) {
    return error
  }
}

async function getAllItemsOriginDestination(
  orignStore: number,
  destinationStore: number,
): Promise<any> {
  try {
    const res = await RequestHelper.getAll<any>(
      'inventario/movimientos',
      'transferencias/',
      'items/searchItemsTransferOriginDestiny',
      { origen: orignStore, destino: destinationStore },
    )
    return res
  } catch (error) {
    return error
  }
}

async function getAllItemsPurchase(
  purchase: number,
  orignStore: number,
  destinationStore: number,
): Promise<any> {
  try {
    const res = await RequestHelper.getAll<any>(
      'inventario/movimientos',
      'transferencias/',
      'purchases/searchItemsAllBuy',
      { compra: purchase, origen: orignStore, destino: destinationStore },
    )
    return res
  } catch (error) {
    return error
  }
}

async function getAllItemsMovement(codeMovement: number): Promise<any> {
  try {
    const res = await RequestHelper.getAll<any>(
      'inventario/movimientos',
      'transferencias/',
      'searchDetailsMovement',
      { codigo: codeMovement },
    )
    return res
  } catch (error) {
    return error
  }
}

async function getLastNumber(type: string): Promise<any> {
  try {
    const res = await RequestHelper.getAll<any>(
      'inventario/movimientos',
      'transferencias/',
      'getLastNumber',
      { tipo: type },
    )
    return res
  } catch (error) {
    return error
  }
}

async function saveTransfer(newTransfer: SaveTransfer): Promise<any> {
  try {
    const apiResult = await RequestHelper.postAll<any>(
      'inventario/movimientos',
      'transferencias/save',
      newTransfer,
    )
    return apiResult
  } catch (error) {
    return error
  }
}

async function getTransferDetailAll(codeTransfer: number): Promise<any> {
  try {
    const res = await RequestHelper.getAll<any>(
      'inventario/movimientos',
      'transferencias/',
      'detail/getAll',
      { codigo: codeTransfer },
    )
    return res
  } catch (error) {
    return error
  }
}

async function deleteTransfer(code: number, progress: number): Promise<any> {
  try {
    const apiResult = await RequestHelper.deleteRequestAll<any>(
      'inventario/movimientos',
      'transferencias/delete',
      '',
      { codigo: code, progreso: progress },
    )
    return apiResult
  } catch (error) {
    return error
  }
}
async function getSeriesItem(type: number, associate: string): Promise<any> {
  try {
    const res = await RequestHelper.getAll<any>(
      'inventario/items',
      'getSeries',
      '',
      { itemSerieAsociado: type, itemSerieTipo: associate },
    )
    return res
  } catch (error) {
    return error
  }
}

async function setArchivedTranfer(
  code: number,
  startHour: string,
  endHour: string,
): Promise<any> {
  try {
    const res = await RequestHelper.getAll<any>(
      'inventario/movimientos',
      'transferencias/archive',
      '',
      { codigo: code, horaInicio: startHour, horaFin: endHour },
    )
    return res
  } catch (error) {
    return error
  }
}
