import React, { useEffect } from 'react'
import { Draggable, SpeedDialAction } from 'devextreme-react'
import { FiltrosMarcasState, MarcasDatosEdicion, MarksReport } from '../../types/types'
import { ButtonTypes } from '../../../../../../../views/componentes/globalMenu/types'
import { clearButtonClick, openTab, setCurrentExecutingAction } from '../../store/tabsReducer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import {
  changeLoaderBrands,
  setCurrentFunction,
} from '../../store/generalReducer'
import { changeFilter, fetchMarcas, setCleanResult, setResetSeleccion } from '../../store/serachReducer'
import { MarcaInventario } from '../../../../../store/types'
import { TabState } from '../../../../../../../store/genericTabTypes'
import { InventarioService } from '../../../../../services/inventario.service'
import { FETCH_STATUS, ToastTypes } from '../../../../../../../store/types'
import { addToast } from '../../../../../../../store/toasterReducer'
import { v4 as uuidv4 } from 'uuid'
import Dialog from '../../../../../../../views/componentes/librerias/bootstrap-dialog'
import { initDatosEdicion } from '../../store/editDataReducer'
import { ReportWithLocalDataModal } from '../../../../../../../views/componentes/xtrareports/ReportWithLocalDataModal'
import ResultadoBusqueda from './ResultadoBusqueda'
import FiltrosBusqueda from './FiltrosBusqueda'
import BlockUi from '../../../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../../../views/componentes/loadingindicator/loadingindicator'
import TableLoader from '../../../../../../ventas/components/ventas/busquedaVentas/TableLoader'
import { isMobile } from 'react-device-detect'
import { CCard, CCardFooter } from '@coreui/react-pro'
import { defaultDataBrands } from '../nuevo'
import DataSource from 'devextreme/data/data_source'

interface ISearchProps extends React.PropsWithChildren {
  tab: TabState<MarcasDatosEdicion>
  tabId: string
}

enum VistasBusqueda {
  Filtros,
  ResultadosBusqueda,
  Resumen,
  Loading,
  Error
}

const draggingGroupName = 'appointmentsGroup';

const Search: React.FunctionComponent<ISearchProps> = (props) => {
  const { tabId, tab } = props

  const dispatch = useDispatch()
  const dialogRef = React.useRef<any>(null)
  const estadoBusqueda = useSelector((state: RootState) => state.inventarios.catalogos.marcas.search.status)
  const [vistaActual, setVistaActual] = React.useState<VistasBusqueda>(VistasBusqueda.Filtros)
  const [confirmDelete, setConfirmDelete] = React.useState<boolean>(false)
  const [pageIndex, setPageIndex] = React.useState<number>(0)
  const [pageSize, setPageSize] = React.useState<number>(10)
  const [dataMarcasPrint, setDataMarcasPrint] = React.useState<Array<MarcaInventario>>([])
  const [dataSourceMarks, setDataSourceMarks] = React.useState<DataSource>(null)

  const marcasLoader = useSelector((state: RootState) => {
    return state.inventarios.catalogos.marcas.general
  })
  const seleccionado = useSelector((state: RootState) => state.inventarios.catalogos.marcas.search.seleccionado)
  const searchFilter = useSelector((state: RootState) => state.inventarios.catalogos.marcas.search.filter)
  const currentTab = useSelector((state: RootState) => {
    return state.inventarios.catalogos.marcas.tabs.current
  })
  const selected = useSelector((state: RootState) => state.inventarios.catalogos.marcas.search.seleccionado)
  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(
      addToast({
        title: 'Inventario - Marcas',
        content: mensaje,
        type: tipo,
      }),
    )
  }, [dispatch])

  const getFilterString = React.useCallback((filter: FiltrosMarcasState) => {
    const filterText = ''
    return filterText
  }, [])
  const { getMarcasDatasource } = InventarioService.useGeMarcasDataSource()

  const DataSourceMarcas = React.useMemo(
    () => getMarcasDatasource(searchFilter),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchFilter],
  )
  const [reporte, setReporte] = React.useState<null | 'Viewer' | 'Designer'>(null)
  const [confirmEdit, setConfirmEdit] = React.useState<boolean>(false)

  const onExecuteButtonAction = React.useCallback((tipo: ButtonTypes | undefined) => {
    dispatch(
      setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: tipo,
      }),
    )
  }, [dispatch, tabId])

  const onInitSerachFilter = React.useCallback(() => {
    const data = { ...searchFilter }
    if (data.initial) {
      data.codigo = ''
      data.marcaWeb = ''
      data.initial = false

      dispatch(changeFilter({
        ...data
      }))
    }
  }, [dispatch, searchFilter])

  const playLoader = React.useCallback(
    (message = 'Cargando...') => {
      dispatch(changeLoaderBrands({ show: true, mensaje: message }))
    },
    [dispatch],
  )

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoaderBrands({ show: false, mensaje: '' }))
  }, [dispatch])


  const onHandleEdit = React.useCallback(async (marca: any) => {
    const id = uuidv4()
    dispatch(
      initDatosEdicion({
        key: id,
        data: {
          ...defaultDataBrands,
          loading: true
        }
      }),
    )
    dispatch(openTab({ key: id, marca: marca }))
  }, [dispatch])

  const onResetPag = React.useCallback(async () => {
    setPageIndex(0)
    setPageSize(10)
  }, [])

  const onBusqueda = React.useCallback(async () => {
    onExecuteButtonAction(ButtonTypes.find)
    playLoader('Buscando')
    try {
      if (isMobile) {
        setVistaActual(VistasBusqueda.Loading)
      }
      await DataSourceMarcas.reload()
      setDataSourceMarks(DataSourceMarcas)
      if (isMobile) {
        setVistaActual(VistasBusqueda.ResultadosBusqueda)
      }
    } catch (error) {
      if (isMobile) {
        setVistaActual(VistasBusqueda.Error)
      }
      setToast(error, ToastTypes.Danger)
    }
    stopLoader()
    onExecuteButtonAction(undefined)
  }, [playLoader, stopLoader, setToast, onExecuteButtonAction, DataSourceMarcas])

  const onHandleRemove = React.useCallback(async () => {
    try {
      const codigo = selected.row?.codigo ?? 0
      onExecuteButtonAction(ButtonTypes.delete)
      playLoader('Eliminando Registro...')
      if (isMobile) {
        setVistaActual(VistasBusqueda.Loading)
      }
      const data = await InventarioService.deleteMarca(codigo)
      if (isMobile) {
        setVistaActual(VistasBusqueda.ResultadosBusqueda)
      }
      stopLoader()
      if (data !== null && data !== undefined && data['error'] === false) {
        onBusqueda()
        setToast(data['message'], ToastTypes.Success)
      } else {
        setToast(data['message'], ToastTypes.Danger)
      }
      onExecuteButtonAction(undefined)
    } catch (error) {
      if (isMobile) {
        setVistaActual(VistasBusqueda.Error)
      }
      stopLoader()
      onExecuteButtonAction(undefined)
      setToast(error.message, ToastTypes.Danger)
    }
  }, [selected, setToast, playLoader, stopLoader, onExecuteButtonAction, onBusqueda])

  const onSearchPrint = React.useCallback(async (tipo: null | 'Viewer' | 'Designer') => {
    onExecuteButtonAction(ButtonTypes.print)
    playLoader('Generando Reporte...')
    try {
      const request_search: any = {
        marcaWeb: searchFilter.marcaWeb,
        nombre: searchFilter.codigo
      }
      const toAny: any = fetchMarcas(request_search)
      const res = await dispatch(toAny)
      if (res !== null && res !== undefined && res['payload'].length >= 0) {
        setDataMarcasPrint(res['payload'])
        setReporte(tipo)
      } else {
        setToast(`${res} registro(os) encontrados`, ToastTypes.Danger)
      }
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
    stopLoader()
    onExecuteButtonAction(undefined)
  }, [searchFilter, setToast, dispatch, playLoader, stopLoader, onExecuteButtonAction])

  const onUndoResultFilter = React.useCallback(() => {
    const data = { ...searchFilter }
    data.codigo = ''
    data.marcaWeb = ''
    dispatch(changeFilter({
      ...data
    }))
    dispatch(setCleanResult())
    dispatch(setResetSeleccion())
    setDataMarcasPrint([])
  }, [dispatch, searchFilter])

  const onBroomResult = React.useCallback(() => {
    setDataSourceMarks(null)
  }, [])


  const onConfirmDelete = React.useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea Eliminar el Registro: ${seleccionado.row?.nombre ?? '-'}?`,
      actions: [
        Dialog.Action(
          <span>
            <u>A</u>ceptar
          </span>,
          (dialog) => {
            dialog.hide()
            onHandleRemove()
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setConfirmDelete(false)
    return
  }, [onHandleRemove, seleccionado?.row])

  const onConfirmEdit = React.useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea Editar el registro: ${seleccionado.row?.nombre ?? '-'}?`,
      actions: [
        Dialog.Action(
          <span>
            <u>A</u>ceptar
          </span>,
          (dialog) => {
            dialog.hide()
            onHandleEdit(seleccionado.row)
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setConfirmEdit(false)
    return
  }, [onHandleEdit, seleccionado])

  const handleButtonClick = React.useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.delete:
          setConfirmDelete(true)
          break
        case ButtonTypes.find:
          onResetPag()
          onBusqueda()
          break
        case ButtonTypes.broom:
          onBroomResult()
          break
        case ButtonTypes.undo:
          onUndoResultFilter()
          break
        case ButtonTypes.edit:
          setConfirmEdit(true)
          break
        case ButtonTypes.print:
          if (dataMarcasPrint.length > 0) {
            setReporte('Viewer')
          } else {
            onSearchPrint('Viewer')
          }
          break
        case ButtonTypes.print_design:
          if (dataMarcasPrint.length > 0) {
            setReporte('Designer')
          } else {
            onSearchPrint('Designer')
          }
          break
        default:
          break
      }
      dispatch(setCurrentFunction(''))
      dispatch(clearButtonClick(tabId))
    },
    [dispatch, tabId, onBusqueda, dataMarcasPrint, onSearchPrint, onUndoResultFilter, onResetPag, onBroomResult],
  )

  const onParseData = React.useCallback((marcas: Array<MarcaInventario> = []) => {
    let registros: Array<MarksReport> = [];
    if (marcas.length > 0) {
      registros = marcas.map((x) => {
        return {
          Code: x?.codigo ?? 0,
          Description: x?.nombre ?? '',
          State: x?.estado ?? 0,
        }
      });
    }
    return registros;
  }, []);


  const handleOptionChange = React.useCallback(async (e) => {
    if (e.fullName === "paging.pageSize") {
      setPageSize(e.value)
    }
    if (e.fullName === "paging.pageIndex") {
      setPageIndex(e.value)
    }
  }, []);

  React.useEffect(() => {
    if (confirmDelete) {
      onConfirmDelete()
    }
  }, [confirmDelete, onConfirmDelete])

  React.useEffect(() => {
    if (confirmEdit) {
      onConfirmEdit()
    }
  }, [confirmEdit, onConfirmEdit])
  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab.globalButtonClick]);

  useEffect(() => {
    onInitSerachFilter()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    switch (estadoBusqueda) {
      case FETCH_STATUS.IDDLE:
        setVistaActual(VistasBusqueda.Filtros);
        break;
      case FETCH_STATUS.LOADING:
        setVistaActual(VistasBusqueda.Loading);
        break;
      case FETCH_STATUS.SUCCESS:
        setVistaActual(VistasBusqueda.ResultadosBusqueda);
        break;
      case FETCH_STATUS.FAILED:
        setVistaActual(VistasBusqueda.Error);
        break;
      default:
        break;
    }
  }, [estadoBusqueda])

  if (isMobile) {
    return (
      <>
        <ReportWithLocalDataModal
          show={reporte !== null}
          onClose={() => setReporte(null)}
          data={onParseData(dataMarcasPrint)}
          fileName="StaticMarcasListado"
          mode={reporte ?? 'Viewer'}
          parameters={{ Reporte_Filtro: getFilterString(searchFilter) }}
          template="StaticMarcasListado"
          key="reportDesigner"
        />
        <CCard>

          <Dialog ref={dialogRef} />
          {vistaActual === VistasBusqueda.Filtros &&

            (
              <>
                <FiltrosBusqueda />
              </>
            )
          }
          {vistaActual === VistasBusqueda.ResultadosBusqueda &&
            (<>
              <ResultadoBusqueda
                handleOptionChange={handleOptionChange}
                onDelete={() => setConfirmDelete(true)}
                onDBClick={onConfirmEdit}
                data={dataSourceMarks}
                pageIndex={pageIndex}
                pageSize={pageSize}
              />
            </>)
          }
          {vistaActual === VistasBusqueda.Error &&
            <></>
          }
          {vistaActual === VistasBusqueda.Loading &&
            <CCardFooter>
              <TableLoader />
            </CCardFooter>
          }
          {
            currentTab === tabId && (
              <Draggable
                id="list"
                data="dropArea"
                group={draggingGroupName}
              >
                <SpeedDialAction
                  icon="filter"
                  label='Filtros'
                  visible={true}
                  index={1}
                  onClick={() => {
                    setVistaActual(VistasBusqueda.Filtros);
                  }}
                />
                <SpeedDialAction
                  icon="search"
                  label='Busqueda'
                  visible={true}
                  index={2}
                  onClick={() => {
                    setVistaActual(VistasBusqueda.ResultadosBusqueda);
                  }}
                />
              </Draggable>
            )
          }
        </CCard>
      </>
    )
  }


  return (
    <>
      <div>
        <Dialog ref={dialogRef} />
      </div>
      <ReportWithLocalDataModal
        show={reporte !== null}
        onClose={() => setReporte(null)}
        data={onParseData(dataMarcasPrint)}
        fileName="StaticMarcasListado"
        mode={reporte ?? 'Viewer'}
        parameters={{ Reporte_Filtro: getFilterString(searchFilter) }}
        template="StaticMarcasListado"
        key="reportDesigner"
      />

      <div id='lineasContent'>
        <BlockUi
          tag="div"
          loader={LoadingIndicator}
          blocking={marcasLoader.loader.show}
          message={marcasLoader.loader.mensaje}
        >
          <CCard>
            <FiltrosBusqueda />
            <ResultadoBusqueda
              onDBClick={onConfirmEdit}
              handleOptionChange={handleOptionChange}
              data={dataSourceMarks}
              pageIndex={pageIndex}
              onDelete={() => setConfirmDelete(true)}
              pageSize={pageSize}
            />
          </CCard>
        </BlockUi>
      </div>
    </>
  )
}

export default React.memo(Search)

export const initMarcksFilter: FiltrosMarcasState = {
  codigo: '',
  marcaWeb: '',
  initial: true
} 
