import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CustomDictionary } from "../../../../../types/generics";
import { ICoordenadasUbicacion, ITotalesPedido, OrdenPedidoDatosEdicion, OrdenPedidoDatosEdicionPayload } from "../../../type/types";
import { ordenClearDatosEdicion } from "../../../store/reducers"
import { LoaderInfo } from "../../../../../../../store/types";
import { Cliente } from "../../../../../types/types";
import { Vendedor } from "../../../../../../shared/components/buscarVendedorLookMemoUp/type/types";
import { DetallePedidoRedux } from "../type/detallePedido";
import { IFileEncodeImage } from "../type/types";

const initialState: CustomDictionary<OrdenPedidoDatosEdicion> = {};

const datosEdicionCuadreCajaSlice = createSlice({
  name: "ordenPedidoDatosEdicion",
  initialState: initialState,
  reducers: {
    ordenClearDatosEdicion,
    setDatosEdicion(
      state,
      action: PayloadAction<{
        key: number | string;
        data: OrdenPedidoDatosEdicion;
      }>
    ) {
      state[action.payload.key] = action.payload.data;
    },
    initDatosEdicion(state, action: PayloadAction<OrdenPedidoDatosEdicionPayload<OrdenPedidoDatosEdicion>>) {
      if (!state[action.payload.key]) {
        state[action.payload.key] = action.payload.data;
      }
    },
    setEditLoader(
      state,
      action: PayloadAction<{ key: number | string; info: LoaderInfo }>
    ) {
      state[action.payload.key].loader = action.payload.info;
    },
    setMuestraError(state, action: PayloadAction<{ key: number | string, tieneError: boolean, mensajeError: string }>) {
      state[action.payload.key].tieneError = action.payload.tieneError;
      state[action.payload.key].mensajeError = action.payload.mensajeError;
    },
    updateCliente(state, action: PayloadAction<{ key: number | string, cliente: Cliente | null, detalles?: Array<any> }>) {
      state[action.payload.key].cliente = action.payload.cliente;
    },
    updateClienteOnly(state, action: PayloadAction<{ key: number | string, cliente: Cliente }>) {
      state[action.payload.key].cliente = action.payload.cliente;
    },
    updateVendedor(state, action: PayloadAction<{ key: number | string, vendedor: Vendedor }>) {
      state[action.payload.key].vendedor = action.payload.vendedor;
    },
    updateObservaciones(state, action: PayloadAction<{ key: number | string, observaciones: string }>) {
      state[action.payload.key].observaciones = action.payload.observaciones;
    },
    updatePorciones(state, action: PayloadAction<{ key: number | string, porciones: number }>) {
      state[action.payload.key].porciones = action.payload.porciones;
    },
    updateDetalles(state, action: PayloadAction<{ key: number | string, detalles: Array<DetallePedidoRedux> }>) {
      state[action.payload.key].detalles = action.payload.detalles;
    },
    addDetalle(state, action: PayloadAction<{ key: number | string, detalle: DetallePedidoRedux }>) {
      state[action.payload.key].detalles.push(action.payload.detalle);
    },
    updateFechaHora(state, action: PayloadAction<{ key: number | string, fechaEntrega: string }>) {
      state[action.payload.key].fechaEntrega = action.payload.fechaEntrega;
    },
    updateGarantia(state, action: PayloadAction<{ key: number | string, garantia: number }>) {
      state[action.payload.key].garantia = action.payload.garantia;
    },
    updateAbono(state, action: PayloadAction<{ key: number | string, abono: number }>) {
      state[action.payload.key].abono = action.payload.abono;
    },
    updateTotales(state, action: PayloadAction<{ key: number | string, totales: ITotalesPedido }>) {
      state[action.payload.key].totales = action.payload.totales;
    },
    updateImagenReferencia(state, action: PayloadAction<{ key: number | string, imgReferencia: Array<IFileEncodeImage> | null }>) {
      state[action.payload.key].imgReferencia = action.payload.imgReferencia;
    },
    updateImagenDecoracion(state, action: PayloadAction<{ key: number | string, imgDecoracion: Array<IFileEncodeImage> | null }>) {
      state[action.payload.key].imgDecoracion = action.payload.imgDecoracion;
    },
    updateCoordenates(state, action: PayloadAction<{ key: number | string, coordenadas: ICoordenadasUbicacion }>) {
      state[action.payload.key].coordenadas = action.payload.coordenadas;
    },
    setMostrarBusquedaItems(state, action: PayloadAction<{ key: string, mostrarBusquedaItems: boolean }>) {
      const { key, mostrarBusquedaItems } = action.payload;
      state[key].mostrarBusquedaItems = mostrarBusquedaItems;
    }
  },
});

export const {
  setDatosEdicion,
  setEditLoader,
  setMuestraError,
  initDatosEdicion,
  updateCliente,
  updateClienteOnly,
  updateVendedor,
  updateObservaciones,
  updatePorciones,
  updateDetalles,
  updateFechaHora,
  updateGarantia,
  updateAbono,
  updateTotales,
  updateImagenReferencia,
  updateImagenDecoracion,
  updateCoordenates,
  addDetalle,
  setMostrarBusquedaItems,
  ordenClearDatosEdicion: clearDatosEdicion,
} = datosEdicionCuadreCajaSlice.actions;
export const datosEdicionReducer = datosEdicionCuadreCajaSlice.reducer;
