import { DateUtils, formatoFechasApi } from "../../../../../helpers/dateUtils"
import { CompraModificar } from "../../../../compras/types/types"
import { InfoDocumentoVentaAfectar } from "../../../types/types"

export function onParseComprToVentaType(data: Array<CompraModificar>) {
  const convert: Array<InfoDocumentoVentaAfectar> = []
  if (data.length > 0) {
    data.forEach(c => {
      convert.push({
        codigo: c?.comprobante ?? 0,
        tipoComprobante: null,
        tipoComprobanteNombre: c?.descripcion ?? '',
        numero: c?.comprobanteNumero ?? '',
        clienteCodigo: c?.proveedorCodigo ?? 0,
        clienteIdentificacion: c?.proveedorIdentificacion ?? '',
        clienteNombre: c?.proveedorNombre ?? '',
        clienteDireccion: c?.proveedorDireccion ?? '',
        clienteTelefono: c?.proveedorTelefono ?? '',
        asociado: '',
        clienteEmail: c?.proveedorEmail ?? '',
        subtotal0: 0,
        subtotal12: 0,
        subtotalNoIva: 0,
        descuento: 0,
        descuentoGlobal: 0,
        iva: 0,
        total: 0,
        fecha: DateUtils.strDateToDate(c?.fecha, formatoFechasApi),
        formaPagoCodigo: c?.codigoFormaPago ?? 0,
        formaPagoDescripcion: c?.nombreFormaPago ?? '',
        puntoVenta: c?.puntoVenta ?? '',
      })
    })
  }
  return convert
}