import { CInputGroup, CFormFeedback, CFormInput } from '@coreui/react-pro'
import React from 'react'
import Labeled from '../labeledInput/labeledInput'

export interface IInpuTextProps extends React.PropsWithChildren {
  name: string
  feedBack?: string
  tieneError?: boolean
  value?: string | number
  placeholder?: string
  label?: string
  // eslint-disable-next-line no-unused-vars
  onChange: (event: React.FormEvent<any>) => void
  onBlur?: any
  readOnly?: boolean
  autoComplete: string
  maxLength?: number
}

export default function InpuText(props: IInpuTextProps) {
  return (
    <CInputGroup>
      <CFormInput
        autoComplete={props.autoComplete}
        name={props.name}
        type="text"
        placeholder={props.placeholder}
        onChange={props.onChange}
        onBlur={props.onBlur}
        value={props.value}
        invalid={props.tieneError}
        maxLength={props.maxLength}
        size="sm"
      />
      {props.tieneError && <CFormFeedback>{props.feedBack}</CFormFeedback>}
    </CInputGroup>
  )
}

export function InputNumber(props: IInpuTextProps) {
  return (
    <CInputGroup>
      <CFormInput
        name={props.name}
        type="number"
        placeholder={props.placeholder}
        onChange={props.onChange}
        onBlur={props.onBlur}
        value={props.value}
        invalid={props.tieneError}
        readOnly={props.readOnly}
        inputMode="decimal"
        size="sm"
      />
      {props.tieneError && <CFormFeedback>{props.feedBack}</CFormFeedback>}
    </CInputGroup>
  )
}

export function InpuTextWithLabel(props: IInpuTextProps) {
  return (
    <Labeled label={props.label ?? ''}>
      <InpuText {...props} />
    </Labeled>
  )
}

export function InputNumberWithLabel(props: IInpuTextProps) {
  return (
    <Labeled label={props.label ?? ''}>
      <InputNumber {...props} />
    </Labeled>
  )
}
