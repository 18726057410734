import { RequestHelper } from "../../../../../helpers/requestHelper"
import { CCAHORRO } from "../store/types";

export const ccahorrosService = {
  obtenerCCahorros, obtenerCombos, crearCuentaCAhorros, deleteCCAhorro
}

async function obtenerCCahorros(banco): Promise<any> {
  try {
    const obj = {
      ...banco
    }
    const data = await RequestHelper.getAll<Array<CCAHORRO>>('bancos/bancos', 'getAll', "", obj);
    return data;
  } catch (error) {
    return error;
  }
}

async function obtenerCombos(tipo, mensaje): Promise<any> {
  try {
    const obj = {
      tipoCodigo: tipo,
      mensaje: mensaje
    }
    const data = await RequestHelper.get<any>('subtypes', 'cboxLoad', "", obj);
    return data;
  } catch (error) {
    return error;
  }
}

async function crearCuentaCAhorros(objeto: any): Promise<any> {
  try {
    const obj = {
      infoRegistro: {
        ...objeto
      }
    }
    const data = await RequestHelper.postAll<any>('bancos/ccahorros', 'save', obj);
    return data;
  } catch (error) {
    return error;
  }
}

async function deleteCCAhorro(id: any): Promise<any> {
  try {
    const objRequest = {
      codigo: id
    }
    const data = await RequestHelper.deleteRequestAll<any>('bancos/ccahorros', 'inactive', "", objRequest);
    return data;
  } catch (error) {
    return error;
  }
}
