import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FiltrosGruposState, BusquedaState } from '../types/types'
import { FETCH_STATUS, Seleccionado } from '../../../../../../store/types'
import { GrupoInventario } from '../../../../store/types'
import { InventarioService } from '../../../../services/inventario.service';
const initialState: BusquedaState<GrupoInventario> = {
  filter: {
    descripcion: '',
    categoriaWeb: '',
    categoriaWebLookup: null,
    estado: null,
    initial: true,
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  seleccionado: {
    index: null,
    row: null,
    selectedRow: null,
  },
  resultados: [],
  tabs: ['Lista', 'Arbol'],
  currentTab: 'Lista',
  colapsado: true,
  nroItemsEncontrados: 0,
}

export const fetchGrupos = createAsyncThunk<
  Array<GrupoInventario>,
  FiltrosGruposState
>('inventarios/catalogos/grupos/listar', async (filtro) => {
  try {
    const groupsList = InventarioService.getGrupos(
      filtro.descripcion,
      filtro.categoriaWebLookup !== null
        ? filtro.categoriaWebLookup.codigo.toString()
        : '0',
      filtro.estado.value,
    )
    return groupsList
  } catch (error) {
    return Promise.reject(error)
  }
})

const searchSlice = createSlice({
  name: 'buscarGrupos',
  initialState: initialState,
  reducers: {
    setCleanResult(state) {
      state.resultados = []
    },
    changeFilter(state, action: PayloadAction<FiltrosGruposState>) {
      state.filter.descripcion = action.payload.descripcion
      state.filter.categoriaWeb = action.payload.categoriaWeb
      state.filter.categoriaWebLookup = action.payload.categoriaWebLookup
      state.filter.estado = action.payload.estado
      state.filter.initial = action.payload.initial
    },
    changeCurrentTab(state, action: PayloadAction<string>) {
      state.currentTab = action.payload
    },
    setCollapsed(state, action: PayloadAction<boolean>) {
      state.colapsado = action.payload
    },
    setResetSeleccion(state) {
      const toAdd = {
        index: null,
        row: null,
        selectedRow: null,
      }
      state.seleccionado = toAdd
    },
    setSeleccionarDato(state, action: PayloadAction<Seleccionado>) {
      const toAdd = {
        index: action.payload.index,
        row: action.payload.row,
        selectedRow: action.payload.selectedRow,
      }
      state.seleccionado = toAdd
    },
    setResultados(state, action: PayloadAction<Array<GrupoInventario>>) {
      state.resultados = action.payload
    },
    setNroGroupsEncontados(state, action: PayloadAction<number>) {
      state.nroItemsEncontrados = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGrupos.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchGrupos.fulfilled, (state, { payload }) => {
      state.resultados = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchGrupos.rejected, (state, { payload }) => {
      state.resultados = []
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
  },
})

export const {
  changeFilter,
  setCleanResult,
  changeCurrentTab,
  setResetSeleccion,
  setResultados,
  setSeleccionarDato,
  setCollapsed,
  setNroGroupsEncontados,
} = searchSlice.actions

export const searchReducer = searchSlice.reducer
