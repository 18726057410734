import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DateUtils } from "../../../../../helpers/dateUtils";
import { FETCH_STATUS, LoaderInfo, Seleccionado } from "../../../../../store/types";
import { Anticipo } from "../../../../shared/components/modales/modalAnticipos/store/types";
import { AnticiposState, FiltrosAnticiposState } from "../types/types";
import { anticiposCtasXCobrar } from "../components/anticipos/service/anticiposCtasXCobrar.service";
import { RootState } from "../../../../../store/store";
import { DocCuentasCobrar } from "../services/docCuentasCobrar.service";
import { cuentasService } from "../../../../contabilidad/pages/diarioGeneral/services/cuentas.service";


const initialState: AnticiposState<Anticipo> = {
  filter: {
    fecha: DateUtils.dateToString(new Date(), 'dd/MM/yyyy'),
    local: null,
    cliente: null,
    puntoVenta: null,
    numero: null,
    codigo: null,
    concepto: null,
    valor: null,
    documento: null,
    formasPago: null,
    codigoAnticipo: 0,
    codigoAsociado: 0,
  },
  seleccionado: {
    index: null,
    row: null,
    selectedRow: null,
  },
  loader: {
    show: false,
    mensaje: '',
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  resultados: [],
  resultadosAsiento: [],
  resultadosAsientoDetalle: [],
  tipoPagoAnt: [],
  currentExecute: null,
  currentActiontate: ''
}



export const fetchAnticpos = createAsyncThunk<
  Array<Anticipo>,
  FiltrosAnticiposState
>('contabilidad/diarioGeneral/advances/getAll', async (filtro) => {
  try {
    const anticiposApi = await cuentasService.getAnticipos(
      3,
      filtro.cliente !== null ? filtro.cliente.codigo : null,
      filtro.numero !== null ? filtro.numero : 0,
      filtro.codigo !== null ? filtro.codigo : 0,
      filtro.local !== null ? filtro.local.codigo : -1,
      filtro.fecha
    )
    return anticiposApi
  } catch (error) {
    return Promise.reject(error)
  }
})

export const fetchAAsiento = createAsyncThunk<
  Array<Anticipo>,
  { codigo: number, tipo: string }
>('contabilidad/entries/getJournalTran', async (requestBody) => {
  try {
    const asientoApi = await DocCuentasCobrar.loadEntry(requestBody)
    return asientoApi
  } catch (error) {
    return Promise.reject(error)
  }
})


export const fetchAAsientoDetalle = createAsyncThunk<
  Array<any>,
  number
>('contabilidad/entries/getDetail', async (codigoAsiento) => {
  try {
    const asientoApi = await DocCuentasCobrar.getEntryDetail(codigoAsiento)
    return asientoApi
  } catch (error) {
    return Promise.reject(error)
  }
})

export const saveAnticpos = createAsyncThunk<
  Array<Anticipo>,
  { filtro: FiltrosAnticiposState, array: Array<any> }
>('contabilidad/diarioGeneral/advances/save', async ({ filtro, array }) => {
  try {
    const anticiposApi = await cuentasService.saveAnticipo(
      filtro.codigoAnticipo,
      filtro.numero.toString(),
      3,
      filtro.cliente !== null ? filtro.cliente.codigo : 0,
      filtro.formasPago !== null ? filtro.formasPago.codigo : -1,
      filtro.concepto,
      filtro.fecha,
      filtro.documento,
      parseInt(filtro.valor),
      'INGRESO',
      filtro.puntoVenta !== null ? filtro.puntoVenta.codigo : -1,
      filtro.local !== null ? filtro.local.codigo : -1,
      array
    )
    return anticiposApi
  } catch (error) {
    return Promise.reject(error)
  }
})

export const fetchAnticposPagos = createAsyncThunk<
  Array<Anticipo>,
  FiltrosAnticiposState
>('contabilidad/diarioGeneral/advances/getAll', async (filtro) => {
  try {
    const anticiposApi = await cuentasService.getAnticipos(
      3,
      filtro.cliente !== null ? filtro.cliente.codigo : null,
      filtro.numero !== null ? filtro.numero : 0,
      filtro.codigo !== null ? filtro.codigo : 0,
      filtro.local !== null ? filtro.local.codigo : -1,
      filtro.fecha
    )
    return anticiposApi
  } catch (error) {
    return Promise.reject(error)
  }
})

export const fetchFormasPagoANL = createAsyncThunk<
  Array<Anticipo>,
  number
>('generales/formasPago/anl/getAll', async (asoCodigo) => {
  try {
    const formasPagoANLApi = await anticiposCtasXCobrar.getFormasPagoANL(
      asoCodigo,
    )
    return formasPagoANLApi
  } catch (error) {
    return Promise.reject(error)
  }
})

export const fetchAnticposCV = createAsyncThunk<
  Array<Anticipo>,
  FiltrosAnticiposState
>('contabilidad/diarioGeneral/advancescv/getAll', async (filtro) => {
  try {
    const anticiposcvApi = await anticiposCtasXCobrar.getAnticiposCV(
      filtro.codigoAnticipo
    )
    return anticiposcvApi
  } catch (error) {
    return Promise.reject(error)
  }
})

export const deleteAnticipos = createAsyncThunk<
  Array<Anticipo>,
  Seleccionado
>('contabilidad/diarioGeneral/advances/inactive', async (filtro) => {
  try {
    const anticiposApi = await cuentasService.deleteAnticipos(
      filtro.row.codigo,
      "ANTICIPO CLIENTE"
    )
    return anticiposApi
  } catch (error) {
    return Promise.reject(error)
  }
})


const anticiposSlice = createSlice({
  name: 'anticiposDocumentos',
  initialState: initialState,
  reducers: {
    setCleanResult(state) {
      state.resultados = []
    },
    setCleanPagoAnt(state) {
      state.tipoPagoAnt = []
    },
    setCleanFields(state) {
      state.filter.fecha = DateUtils.dateToString(new Date(), 'dd/MM/yyyy')
      state.filter.local = null
      state.filter.cliente = null
      state.filter.numero = null
      state.filter.codigo = null
      state.filter.concepto = ''
      state.filter.valor = ''
      state.filter.documento = ''
      state.filter.formasPago = null
      state.filter.codigoAnticipo = 0
      state.filter.codigoAsociado = 0
      state.tipoPagoAnt = []
    },
    initData(state) {
      state.filter.fecha = DateUtils.dateToString(new Date(), 'dd/MM/yyyy')
      state.filter.numero = null
      state.filter.codigo = null
      state.filter.concepto = ''
      state.filter.valor = ''
      state.filter.documento = ''
      state.filter.formasPago = null
      state.filter.codigoAnticipo = 0
      state.filter.codigoAsociado = 0
      state.tipoPagoAnt = []
    },
    changeFilter(state, action: PayloadAction<FiltrosAnticiposState>) {
      state.filter = action.payload
      state.filter.fecha = action.payload.fecha
      state.filter.local = action.payload.local
      state.filter.cliente = action.payload.cliente
      state.filter.numero = action.payload.numero
      state.filter.codigo = action.payload.codigo
      state.filter.concepto = action.payload.concepto
      state.filter.valor = action.payload.valor
      state.filter.documento = action.payload.documento
      state.filter.formasPago = action.payload.formasPago
      state.filter.codigoAnticipo = action.payload.codigoAnticipo
      state.filter.codigoAsociado = action.payload.codigoAsociado
    },
    setResetSeleccion(state) {
      const toAdd = {
        index: null,
        row: null,
        selectedRow: null,
      }
      state.seleccionado = toAdd
    },
    setSeleccionarDato(state, action: PayloadAction<Seleccionado>) {
      const toAdd = {
        index: action.payload.index,
        row: action.payload.row,
        selectedRow: action.payload.selectedRow,
      }
      state.seleccionado = toAdd
    },
    setCurrentAction(state, action: PayloadAction<string>) {
      state.currentActiontate = action.payload
    },
    changeLoaderAnticipos(state, action: PayloadAction<LoaderInfo>) {
      state.loader = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAnticpos.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchAnticpos.fulfilled, (state, { payload }) => {
      state.resultados = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchAnticpos.rejected, (state, { payload }) => {
      state.resultados = []
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
    builder.addCase(deleteAnticipos.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(deleteAnticipos.fulfilled, (state, { payload }) => {
      state.currentExecute = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(deleteAnticipos.rejected, (state, { payload }) => {
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
    builder.addCase(fetchAnticposCV.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchAnticposCV.fulfilled, (state, { payload }) => {
      state.currentExecute = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchAnticposCV.rejected, (state, { payload }) => {
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
    builder.addCase(saveAnticpos.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(saveAnticpos.fulfilled, (state, { payload }) => {
      state.currentExecute = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(saveAnticpos.rejected, (state, { payload }) => {
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
    builder.addCase(fetchFormasPagoANL.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchFormasPagoANL.fulfilled, (state, { payload }) => {
      state.tipoPagoAnt = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchFormasPagoANL.rejected, (state, { payload }) => {
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
    builder.addCase(fetchAAsiento.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchAAsiento.fulfilled, (state, { payload }) => {
      state.resultadosAsiento = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchAAsiento.rejected, (state, { payload }) => {
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
    builder.addCase(fetchAAsientoDetalle.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchAAsientoDetalle.fulfilled, (state, { payload }) => {
      state.resultadosAsientoDetalle = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchAAsientoDetalle.rejected, (state, { payload }) => {
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })

  },
})


export const getFiltroAnticipos = (state: RootState) => {
  return state.clientes.docCuentasXCobrar.anticipos.filter
}

export const getResultadosAnticipos = (state: RootState) => {
  return state.clientes.docCuentasXCobrar.anticipos.resultados
}

export const { changeFilter, setCleanResult, initData, setResetSeleccion, setSeleccionarDato, setCleanFields, changeLoaderAnticipos, setCurrentAction, setCleanPagoAnt } =
  anticiposSlice.actions

export const anticiposReducer = anticiposSlice.reducer