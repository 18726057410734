import React from 'react'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput'
import { DataGrid, DateBox, NumberBox } from 'devextreme-react'
import { Column, Scrolling, Sorting } from 'devextreme-react/data-grid'
import LocalesLookUp from '../../../../../../componentes/localesLookUp'
import { useDispatch, useSelector } from 'react-redux'
import { changeFilterCon } from '../../store/serachReducer'
import { RootState } from '../../../../../../../store/store'


interface IFilterHeadProps extends React.PropsWithChildren {
  onEnter: () => void
  onloadInventario: () => void
}

const FiltroBusqueda: React.FunctionComponent<IFilterHeadProps> = (props) => {
  const { onEnter, onloadInventario } = props
  const dispatch = useDispatch()
  const searchFilCon = useSelector((state: RootState) => state.inventarios.movimientos.inventarioInicial.search.filterConsolidar)
  const inventoryDatagrid = React.useRef<any>()
  const dataMov = useSelector((state: RootState) => state.inventarios.movimientos.inventarioInicial.search.filterConsolidar.dataMov)

  return (
    <>
      <RowContainer>
        <CustomCol xs="12" md="3">
          <Labeled label="Local:">
            <LocalesLookUp
              onChanged={(data) => {
                dispatch(changeFilterCon({
                  ...searchFilCon,
                  local: data
                }))
              }}
              onChangedOptions={() => { }}
              selected={searchFilCon?.local ?? null}
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="2">
          <Labeled label="Fecha de Inventario:">
            <DateBox
              displayFormat="dd/MM/yyyy"
              value={searchFilCon?.fechaInv ?? null}
              onValueChanged={(data) => {
                if (data?.event !== null && data?.event !== undefined) {
                  dispatch(changeFilterCon({
                    ...searchFilCon,
                    fechaInv: data?.value
                  }))
                }
              }}
              onEnterKey={onEnter}
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="1">
          <Labeled label="Año:">
            <NumberBox
              value={searchFilCon?.anio ?? null}
              onValueChanged={(data) => {
                if (data?.event !== null && data?.event !== undefined) {
                  dispatch(changeFilterCon({
                    ...searchFilCon,
                    anio: data?.value
                  }))
                }
              }}
              onEnterKey={onEnter}
              onFocusOut={onloadInventario}
              min={1000}
              max={new Date().getFullYear()}
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="2" />
        <CustomCol xs="12" md="4">
          <DataGrid
            style={{ marginTop: '10px' }}
            ref={inventoryDatagrid}
            height={140}
            keyExpr="codigo"
            id="tabNewInictialInventory"
            selection={{ mode: 'single' }}
            dataSource={dataMov['auto'] ?? []}
            showColumnLines={true}
            showRowLines={true}
            showBorders={true}
            columnResizingMode="widget"
          >
            <Sorting mode="none" />
            <Scrolling
              mode="virtual" />
            <Column dataField="fecha" caption="Fecha" width="50%" />
            <Column dataField="localCodigoDescripcion" caption="Local" width="50%" />
          </DataGrid>
        </CustomCol>
      </RowContainer>
    </>
  )
}

export default React.memo(FiltroBusqueda)