import { ItemCart } from '../../../../../modulos/cuenta/upgrate/type/type'
import { ETiposPagos } from '../../../../../store/types'
import { MotivoCaducidad } from '../contadorDocumentos'

export type PagoVencido = {
  autorizacion: string
  codTipoDoc: number
  codigo: number
  codigoCliente: number
  codigoLocal: number
  credito: number
  detalle: string
  dias: number
  direccionCliente: string
  emailCliente: string
  empresaCliente: string
  fecha: string
  identificacionCliente: string
  noVencido: number
  nombreCliente: string
  numero: string
  pagoInmediato: boolean
  rcomercialCliente: string
  saldo: number
  seleccionado: boolean
  telefonoCliente: string
  tipoComprobante: string
  total: number
  vencido: number
  vendedor: string
  venta8: number
  venta30: number
  venta60: number
  venta90: number
  venta120: number
  venta180: number
  ventaMas: number
}

export enum TipoPeriodo {
  ANUAL = 'ANUAL', // 5
  MENSUAL = 'MENSUAL', // 1
}

export type RecurrenteData = {
  codigo: number
  clienteCodigo: number
  nombreCliente: string
  rcomercialCliente: string
  identificacionCliente: string
  direccionCliente: string
  telefonoCliente: string
  emailCliente: string
  fechaInicio: string
  fechaFin: string
  planActivo: boolean
  comprobantesEmitidos: number
  tipoRecurrencia: number
  motivoCaduca: MotivoCaducidad
  verificado: number
  isFree: boolean
  mensajeMotivo: string
  codigoPlanVigente: number
  maxComprobantes: number
  periodo: TipoPeriodo
  numComprobantesContrato: number
  numUsuariosContrato: number
  numLocalesContrato?: number
}

export type PersonalizaRecurrenteData = {
  periodo: TipoPeriodo
  detallesPlan: any
  adicionales: Array<ItemCart> | []
  motivoDeposito: string
}
