import React from 'react'
import {
  DocumentosCtasCobrarDatosEdicion,
  DocumentosCtasCobrarListado,
} from '../../types/types'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import { StatesEdition } from '../../../../../ventas/types/enums'
import { CustomButtons, DocumentInfo } from '../../../../../../store/types'
import { TabState } from '../../../../../../store/genericTabTypes'

interface IDocumentoCtasPorCobrarProps {
  info: DocumentInfo<DocumentosCtasCobrarListado>
  tabId: string
  tab: TabState<DocumentosCtasCobrarListado>
  templateMode?: boolean
}

const DocumentoCtasPorCobrar: React.FunctionComponent<
  IDocumentoCtasPorCobrarProps
> = (props) => {
  const { tabId, info, tab } = props

  const modoNuevo = React.useCallback(
    async (template: number, limpiar: boolean = false) => {},
    [],
  )

  const modoEdicion = React.useCallback(() => {}, [])

  const guardar = React.useCallback(() => {}, [])

  const deshacer = React.useCallback(() => {}, [])

  const handlePrint = React.useCallback((design: boolean) => {
    // setVerFacturaPdf({ codigo: codigo, design: design });
  }, [])

  const handleButtonClick = React.useCallback(
    (button: ButtonTypes) => {
      switch (button) {
        case ButtonTypes.edit:
          modoEdicion()
          break
        case ButtonTypes.save: {
          guardar()
          break
        }
        case ButtonTypes.undo:
          deshacer()
          break
        case ButtonTypes.print:
          handlePrint(false)
          break
        case ButtonTypes.print_design:
          handlePrint(true)
          break
        case ButtonTypes.export:
          // exportarDetalle();
          break
        case ButtonTypes.sendMail:
          // setShowReenviarMail(true);
          break
        case ButtonTypes.broom:
          // modoNuevo(puntoVenta ?? "", 0, true);
          break
        default:
          break
      }
      // dispatch(clearButtonClick(tabId))
    },
    [deshacer, guardar, handlePrint, modoEdicion],
  )

  React.useEffect(() => {
    if (tab.editStatus === StatesEdition.new) {
      modoNuevo(tab.info?.codigo ?? 0)
    } else {
      // cargarVenta(info.numero, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
  }, [handleButtonClick, tab.globalButtonClick])

  return (
    <>
      {/* <Anticipos /> */}
      {/* <CorteComprobantes /> */}
      {/* <Abonos/> */}
      {/* <Saldos /> */}
    </>
  )
}

export const defaultDatosEdicionDocCtasCobrar: DocumentosCtasCobrarDatosEdicion =
  {
    imprimir: false,
    loader: {
      mensaje: 'Cargando...',
      show: false,
    },
    tieneError: false,
    mensajeError: '',
    loading: true,
    desdePlantilla: false,
    codigo: null,
  }

const buttonsProductoStyle = { marginLeft: '2px' }

export const ModuleButtons: CustomButtons = {
  Nuevo: true,
  Guardar: true,
  Buscar: true,
  Deshacer: true,
  Editar: true,
  Imprimir: true,
  Importar: true,
  Abonar: true,
  Saldos: true,
  Anticipos: true,
  Cartera: true,
  Cartera_vencida: true,
}

export default React.memo(DocumentoCtasPorCobrar)
