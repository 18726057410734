import { DateUtils, formatoFechasApi } from '../../../../helpers/dateUtils'
import { RequestHelper } from '../../../../helpers/requestHelper'
import { utilidades } from '../../../../helpers/utilidades'
import { ECountry } from '../../../../store/enum/countries'
import { PlanFacturacion } from '../types/types'

export const PlanesServices = { getPlanes }

async function getPlanes(): Promise<any> {
  try {
    const jquery: any = {}
    const response = await utilidades.isPathLocationSV()
    if (response) {
      jquery.pai_codigo = ECountry.ElSalvador
    } else {
      jquery.pai_codigo = ECountry.Ecuador
    }
    jquery.dateKey = DateUtils.getCurrentDateAsString(formatoFechasApi)
    const cacheKey = 'tipoPlanes' + JSON.stringify(jquery)
    const cached = sessionStorage.getItem(cacheKey)
    if (cached) {
      return { error: false, auto: JSON.parse(cached) }
    } else {
      const data = await RequestHelper.getAll<any>(
        'public',
        'getAllPlans',
        '',
        jquery,
      )
      const opt: Array<PlanFacturacion> = []
      if (data?.auto && data?.auto.length > 0) {
        for (const x of data.auto) {
          opt.push({
            id: x?.id ?? -1,
            codigoBase: x?.cod_base ?? -1,
            codigoItem: x?.cod_item ?? '',
            codigoPeriodo: x?.codPeriodo ?? -1,
            codigoCategoria: x?.categoriaCodigo ?? -1,
            cadena: x?.cadena ?? '',
            plan: utilidades.capText(x?.plan) ?? '',
          })
        }
      }
      data.auto = opt ?? []
      sessionStorage.setItem(cacheKey, JSON.stringify(opt))
      return data
    }
  } catch (error) {
    return error
  }
}
