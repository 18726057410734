import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoaderInfo, Seleccionado } from '../../../../../store/types';
import { TipoRecurrenciasState, ModuloTipoRecurrenciasState, TipoRecurrencias, formularioBusqueda } from './types';


const initialState: TipoRecurrenciasState = {
    modulo: 'tiporecurrencias',
    currentAction: "Buscar",
    currentFunction: "",
    currentExecute: null,
    registros: [],
    registrosBack: [],
    aplicacion: 'tiporecurrencias',
    seleccionado: {
        index: null,
        row: null,
        selectedRow: null,
    },
    loader: {
        show: false,
        mensaje: ''
    },
    acciones: [],
    refrescaTabla: false,
    formularioBusqueda: {
        nombre: null,
    },
}

const tipoRecurrenciasSlice = createSlice({
    name: "tiporecurrencias",
    initialState: initialState,
    reducers: {
        setFormularioBusqueda(state, action: PayloadAction<formularioBusqueda>) {
            const toAdd = {
                nombre: action.payload.nombre
            }
            state.formularioBusqueda = toAdd
        },
        setResetSeleccion(state) {
            const toAdd = {
                index: null,
                row: null,
                selectedRow: null,
            }
            state.seleccionado = toAdd
        },
        setSeleccionarDato(state, action: PayloadAction<Seleccionado>) {
            const toAdd = {
                index: action.payload.index,
                row: action.payload.row,
                selectedRow: action.payload.selectedRow,
            }
            state.seleccionado = toAdd
        },
        setData(state, action: PayloadAction<Array<TipoRecurrencias>>) {
            state.registros = action.payload
        },
        setDataBack(state, action: PayloadAction<Array<TipoRecurrencias>>) {
            state.registrosBack = action.payload
        },
        setCurrentFunction(state, action: PayloadAction<string>) {
            state.currentFunction = action.payload
        },
        setCurrentExecute(state, action: PayloadAction<any>) {
            state.currentExecute = action.payload
        },
        setCurrentAccion(state, action: PayloadAction<string>) {
            state.currentAction = action.payload
        },
        changeLoaderTipoRecurrencias(state, action: PayloadAction<LoaderInfo>) {
            state.loader = action.payload
        },
    },
});

export const { setData, setDataBack, setCurrentFunction, setCurrentExecute, setCurrentAccion, setResetSeleccion, changeLoaderTipoRecurrencias, setSeleccionarDato, setFormularioBusqueda } = tipoRecurrenciasSlice.actions;

export const tiporecurrenciasReducer = tipoRecurrenciasSlice.reducer;
