import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { HorarioDatosEdicion, configHorarios } from '../types/types'
import { EdicionPayload } from '../../../../store/types'

const initialState: configHorarios = {
  aplicacion: 0,
}

const configuracionHorarios = createSlice({
  name: 'configHorarios',
  initialState: initialState,
  reducers: {
    setAplicacionModulo(state, acion: PayloadAction<number>) {
      state.aplicacion = acion.payload
    },
    deleteEditDataBackup(state, action: PayloadAction<string>) {
      delete state[action.payload]
    },
    setDatosEdicionBackup(
      state,
      action: PayloadAction<EdicionPayload<HorarioDatosEdicion>>,
    ) {
      state[action.payload.key] = action.payload.data
    },
  },
})

export const configuracionesHorarios = configuracionHorarios.reducer
export const { setAplicacionModulo, deleteEditDataBackup, setDatosEdicionBackup } = configuracionHorarios.actions
