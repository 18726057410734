

export const MovimientosGridHelper = {
  customizeSummaryText,
  calculateCustomSummary,
  onParseLotes
};

function customizeSummaryText(data) {
  const number: number = data?.value ?? 0
  let numNuevo: string = "0"
  if (number !== 0) {
    numNuevo = number.toLocaleString(undefined, { maximumFractionDigits: 2 })
  }
  return numNuevo
}

function calculateCustomSummary(options) {
  if (options.summaryProcess === 'start') {
    options.totalValue = 0;
  } else if (options.summaryProcess === 'calculate') {
    options.totalValue += options.value[options.name]
  }
}

function onParseLotes(data = []) {
  return data.map(item => {
    return {
      codigoItem: parseInt(item.ITE_CODIGO ?? 0),
      codigo: parseInt(item.LOT_CODIGO ?? 0),
      cantidad: parseInt(item.LMO_CANTIDAD ?? 0),
      barras: parseInt(item.LOT_BARRAS ?? 0),
      caducidad: item.LOT_CADUCIDAD ?? '',
      fecha: item.LOT_FECHA ?? '',
      numero: parseInt(item.LOT_NUMERO ?? 0),
      cantidadLote: parseInt(item.LOT_CANTIDAD ?? 0),
      ubicado: item.LOT_UBICADO ?? '',
    }
  })
}