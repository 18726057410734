import React from 'react'
import { CCol } from '@coreui/react-pro'
import { Button, DataGrid } from 'devextreme-react'
import { Column, FilterRow, HeaderFilter, Pager, Paging, Button as DatagridButton } from 'devextreme-react/data-grid'
import RowContainer from '../../../../../../../../views/componentes/rowContainer/rowContainer'
import { LineaInventario } from '../../../../../../../inventario/store/types'
import { ModalAdminLineas } from '../../../../../../../inventario/components/buscarLinea/modalAdminLineas/modalAdminLineas'
import { DatosEdicionLineas } from '../../../../types/types'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../../store/store'
import { ToastTypes } from '../../../../../../../../store/types'
import Dialog from '../../../../../../../../views/componentes/librerias/bootstrap-dialog'
import { MessagesKeys, lh } from '../../../../../../../../helpers/localizationHelper'
import { setTabLineas } from '../../../../store/editDataReducer'

interface ILookUpLineasProps extends React.PropsWithChildren {
  tabId: string
  setToast: (sms: string, tipo: ToastTypes) => void
  refForm: any
}

const Lineas: React.FC<ILookUpLineasProps> = (props,) => {
  const { tabId, setToast } = props
  const dispatch = useDispatch()

  const lineasState = useSelector((state: RootState) => { return state.clientes.clientes.editData[tabId]?.tabs?.lineas })
  const [modalLineas, setModalLineas] = React.useState<boolean>(false)
  const [showModal, setShowModal] = React.useState<boolean>(false)
  const tablaRefPager = React.useRef<any>()
  const dialogRef = React.useRef<any>(null);



  const deleteItemTable = React.useCallback(() => {
    const filtrado = lineasState.lineas.findIndex((item: any) => {
      return Number(item.codigo) === Number(lineasState.linea["codigo"]);
    });
    const rowsBefore = lineasState.lineas.slice();
    if (filtrado > -1) {
      rowsBefore.splice(filtrado, 1);
      dispatch(setTabLineas({
        key: tabId,
        data: {
          lineas: rowsBefore,
          linea: null
        }
      }))
    }
  }, [lineasState, tabId, dispatch]);

  const onConfirmarUpdate = React.useCallback(() => {
    const sms = lh.getMessage(MessagesKeys.FormConfirmDeleteForm);
    dialogRef.current.show({
      title: <><strong>{"Ácatha"}</strong></>,
      body: <label style={{ fontSize: "14px", fontWeight: 600 }}>{sms}</label>,
      actions: [
        Dialog.Action(
          <span><u>{"A"}</u>{"ceptar"}</span>,
          (dialog) => {
            deleteItemTable()
            dialog.hide();
          },
          'btn-success',
          'n'
        ),
        Dialog.Action(
          <span><u>{"C"}</u>{"ancelar"}</span>,
          (dialog) => {
            dialog.hide();
          },
          'btn-danger',
          'c'
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide();
      }
    });
    return;
  }, [deleteItemTable]);

  const addLineas = React.useCallback((e: LineaInventario) => {
    if (e !== undefined) {
      const lineas = lineasState.lineas.slice();
      console.log(e)
      const existItem = lineas.find((obj: LineaInventario) => obj.codigo === e.codigo)
      if (existItem === undefined) {
        lineas.push(e)

        dispatch(setTabLineas({
          key: tabId,
          data: {
            ...lineasState,
            lineas: lineas
          }
        }))

        setShowModal(false)
      } else {
        setToast("No se puede volver a agregar esta linea", ToastTypes.Warning)
      }
    }
  }, [lineasState, setToast, dispatch, tabId]);




  const selectionChanged = React.useCallback((selectionChanged) => {
    if (selectionChanged['selectedRowsData'].length > 0) {
      dispatch(setTabLineas({
        key: tabId,
        data: {
          ...lineasState,
          linea: selectionChanged['selectedRowsData'][0]
        }
      }))
    }
  }, [tabId, lineasState, dispatch]);



  return (
    <>
      <div>
        <Dialog ref={dialogRef} />
      </div>
      <RowContainer>
        {showModal &&
          <ModalAdminLineas
            onChanged={(data) => {
              addLineas(data)
            }}
            onCancel={() => setShowModal(!showModal)}
            show={showModal}
          />
        }
        <CCol style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '4px' }}>
          <Button
            id="btnAdd"
            icon="add"
            stylingMode="contained"
            type="default"
            onClick={() => {
              setShowModal(true)
              setModalLineas(!modalLineas)
            }}
          />
          <Button
            style={{ marginLeft: '6px' }}
            id="btnRemove"
            icon="trash"
            stylingMode="contained"
            type="danger"
            onClick={() => {
              onConfirmarUpdate()
            }}
            disabled={lineasState.linea === null}
          />
        </CCol>
      </RowContainer>
      <RowContainer>
        <CCol className="d-flex justify-content-end">{lineasState?.lineas.length ?? 0}</CCol>
        <DataGrid
          selection={{ mode: 'single' }}
          hoverStateEnabled={true}
          dataSource={lineasState.lineas ?? []}
          showBorders={true}
          showRowLines={true}
          width={'100%'}
          onSelectionChanged={selectionChanged}
        >
          <FilterRow visible={true} />
          <HeaderFilter visible={true} />
          <Paging
            enabled={true}
            defaultPageSize={10}
          />
          <Pager
            ref={tablaRefPager}
            showPageSizeSelector={true}
            allowedPageSizes={[8, 12, 20]}
            showNavigationButtons={true}
            showInfo={true}
            infoText="Page #{0}. Total: {1} ({2} items)"
          />
          <Column type='buttons' width='7%' >
            <DatagridButton icon='arrowleft' visible={true} hint='Seleccionar'
              onClick={(e) => { console.log(e) }} />
          </Column>
          <Column dataType="string" dataField="descripcion" caption="Lineas" width="100%" />
        </DataGrid>
      </RowContainer>
    </>
  )
}

export default Lineas

export const defaultDatosEdicionLineas: DatosEdicionLineas = {
  lineas: [],
  linea: null
}