import React, { FunctionComponent, PropsWithChildren, useCallback, useRef, useState } from 'react'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import { DataGrid } from 'devextreme-react'
import { Column, ColumnChooser, Editing, Export, Pager, Paging } from 'devextreme-react/data-grid'
import { useDispatch, useSelector } from 'react-redux'
import { Workbook } from 'exceljs'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { saveAs } from 'file-saver';
import DataSource from 'devextreme/data/data_source'
import { allowedPageSizes } from '../../../../../../../helpers/Helper'
import ItemsCountIndicator from '../../../../../../componentes/indicadorNroRegistros'
import { RootState } from '../../../../../../../store/store'
import { setCollapsed } from '../../store/searchReducer'



interface IResultsProps extends PropsWithChildren {
  onDBClick: () => void
  // eslint-disable-next-line no-unused-vars
  handleOptionChange: (e: any) => void
  data: DataSource
  pageIndex: number
  pageSize: number
  onDelete: () => void
}



const ResHistorialBusqueda: FunctionComponent<IResultsProps> = (props) => {


  const { onDBClick, data, handleOptionChange, pageIndex, pageSize, onDelete } =
    props

  const tablaRefPager = useRef<any>()
  const dispatch = useDispatch()
  const [isPagin] = useState<boolean>(true)
  const totalCountRols = useSelector((state: RootState) => state.nomina.rol.rolesPago.search.nroRegistrosEncontrados)

  const [changes, setChanges] = React.useState<any>()
  const [editRowKey, setEditRowKey] = React.useState<any>()

  const onChangesChange = React.useCallback((changes) => {
    setChanges(changes)
  }, [])

  const onEditRowKeyChange = React.useCallback((editRowKey) => {
    setEditRowKey(editRowKey)
  }, [])

  const onExportExcell = useCallback((e) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('Main sheet')
    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'Roles_Historial_Nomina.xlsx',
        )
      })
    })
  }, [])



  return (
    <>
      <ItemsCountIndicator numberOfItems={data ? totalCountRols : 0} />
      <RowContainer>
        <CustomCol xs="12" md="12">
          <DataGrid
            id="tablaHistorial"
            ref={tablaRefPager}
            dataSource={data ?? []}
            selection={{ mode: 'single' }}
            hoverStateEnabled={true}
            showBorders={true}
            showRowLines={true}
            // onSelectionChanged={onSelectedChanged}
            // onRowDblClick={onDBClick}
            repaintChangesOnly={true}
            highlightChanges={true}
            keyExpr="uuid"
            key="uuid"
            focusedRowKey="uuid"
            showColumnLines={true}
            onExporting={onExportExcell}
            className={'m-2 p-2'}
            onOptionChanged={handleOptionChange}
            remoteOperations={true}
            loadPanel={{
              enabled: true,
              height: 90,
              indicatorSrc: '',
              shading: false,
              shadingColor: '',
              showIndicator: true,
              showPane: true,
              text: 'Cargando...',
              width: 200,
            }}
          >
            <Editing
              mode="cell"
              refreshMode={'repaint'}
              allowUpdating
              changes={changes}
              onChangesChange={onChangesChange}
              editRowKey={editRowKey}
              onEditRowKeyChange={onEditRowKeyChange}
            />
            <Paging
              pageIndex={pageIndex}
              pageSize={pageSize}
              enabled={isPagin}
            />
            <Pager
              ref={tablaRefPager}
              showPageSizeSelector={true}
              allowedPageSizes={allowedPageSizes}
              showNavigationButtons={true}
              showInfo={true}
              infoText="Page #{0}. Total: {1} ({2} items)"
            />
            <Export enabled={true} allowExportSelectedData={true} />
            <ColumnChooser enabled={true} mode="select" />

            <Column allowEditing={false} dataField='mes' caption="Mes" width="50px" />
            <Column allowEditing={false} dataField='anio' caption="Año" width="50px" />
            <Column allowEditing={false} dataField='cedula' caption="Identifcacion" width="100px" />
            <Column allowEditing={false} dataField='nombresCompletos' caption="Apellidos y Nombres" width="150px" />
            <Column allowEditing={false} dataField='nominal' caption="Nominal" width="90px" />
            <Column allowEditing={false} dataField='sueldo' caption="Sueldo" width="90px" />
            <Column allowEditing={false} dataField='valorJornada' caption="Noc" width="60px" />
            <Column allowEditing={false} dataField='valorHoraSuplementarias' caption="Sup" width="60px" />
            <Column allowEditing={false} dataField='valorHoraExtra' caption="Ext" width="60px" />
            <Column allowEditing={false} dataField='comisiones' caption="Com" width="60px" />
            <Column allowEditing={false} dataField='ajusteSueldo' caption="Ajustes" width="60px" />
            <Column allowEditing={false} dataField='faltas' caption="-Faltas" width="60px" />
            <Column allowEditing={false} dataField='bai' caption="BAI" width="60px" />
            <Column allowEditing={false} dataField='bonos' caption="Bono" width="60px" />
            <Column allowEditing={false} dataField='fondosReserva' caption="FReser" width="70px" />
            <Column allowEditing={false} dataField='bonificacion' caption="Boni" width="70px" />
            <Column allowEditing={false} dataField='totalIngresos' caption="Ingres" width="70px" />
            <Column allowEditing={false} dataField='iess' caption="-IESS Pe" width="70px" />
            <Column allowEditing={false} dataField='prestamos' caption="-Pres" width="70px" />
            <Column allowEditing={false} dataField='anticipos' caption="-Ant" width="70px" />
            <Column allowEditing={false} dataField='prestamosQuirografarios' caption="-Pres_Q" width="70px" />
            <Column allowEditing={false} dataField='prestamosHipotecarios' caption="-Pres_H" width="70px" />
            <Column allowEditing={false} dataField='descuentos' caption="-Des" width="70px" />
            <Column allowEditing={false} dataField='renta' caption="-Ren" width="70px" />
            <Column allowEditing={false} dataField='multas' caption="-Mul" width="70px" />
            <Column allowEditing={false} dataField='reemplazo' caption="-Otros" width="70px" />
            <Column allowEditing={false} dataField='totalEgresos' caption="Egres" width="70px" />
            <Column allowEditing={false} dataField='sueldoMes' caption="Total" width="70px" />
          </DataGrid>
        </CustomCol>
      </RowContainer>
    </>
  )
}

export default React.memo(ResHistorialBusqueda)