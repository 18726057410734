import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TipoAgente, TipoNegocio } from '../../../../../store/types'
import {
  defaultDatosDocumentos,
  defaultDatosEmpresa,
  defaultDatosFacturacionElectronica,
  defaultDatosGenerales,
  defaultDatosResumenComprobantes,
  EdicionDatosDocumentos,
  EdicionDatosFacturacionElectronica,
  EdicionDatosGenerales,
  EdicionDatosResumenComprobantes,
  EmpresaDatos,
  EmpresaState,
  TipoIdentificacion,
  TRimpe,
} from './types'

const initialState: EmpresaState = {
  modulo: 'empresa',
  currentAction: 'Editar',
  currentFunction: '',
  currentTab: 0, // 0,1,2
  currentSubTab: 0,
  dpTipoIdentificaciones: [],
  dpTipoAgente: [],
  dpTipoNegocio: [],
  dpRimpeClasificacion: [],
  datosEmpresa: defaultDatosEmpresa,
  datosGenerales: defaultDatosGenerales,
  datosDocumentos: defaultDatosDocumentos,
  datosFacturacionElectronica: {
    configuracion: defaultDatosFacturacionElectronica,
    recumenComprobantes: defaultDatosResumenComprobantes,
  },
}

const empresaSlice = createSlice({
  name: 'empresa',
  initialState: initialState,
  reducers: {
    setCurrentAccion(state, action: PayloadAction<string>) {
      state.currentAction = action.payload
    },
    setCurrentFunction(state, action: PayloadAction<string>) {
      state.currentFunction = action.payload
    },
    setCurrentTab(state, action: PayloadAction<number>) {
      state.currentTab = action.payload
    },
    setCurrentSubTab(state, action: PayloadAction<number>) {
      state.currentSubTab = action.payload
    },
    setDataEmpresa(state, action: PayloadAction<EmpresaDatos>) {
      state.datosEmpresa = action.payload
    },
    setDataGeneral(state, action: PayloadAction<EdicionDatosGenerales>) {
      state.datosGenerales = action.payload
    },
    setDpTipoIdentificacion(
      state,
      action: PayloadAction<Array<TipoIdentificacion>>,
    ) {
      state.dpTipoIdentificaciones = action.payload
    },
    setDpTipoAgente(state, action: PayloadAction<Array<TipoAgente>>) {
      state.dpTipoAgente = action.payload
    },
    setDpTipoNegocio(state, action: PayloadAction<Array<TipoNegocio>>) {
      state.dpTipoNegocio = action.payload
    },
    setDpRimpeClasificacion(state, action: PayloadAction<Array<TRimpe>>) {
      state.dpRimpeClasificacion = action.payload
    },
    setDataDocumentos(state, action: PayloadAction<EdicionDatosDocumentos>) {
      state.datosDocumentos = action.payload
    },
    setDataConfiguracionFacturacion(
      state,
      action: PayloadAction<EdicionDatosFacturacionElectronica>,
    ) {
      state.datosFacturacionElectronica.configuracion = action.payload
    },
    setDataResumenTransacciones(
      state,
      action: PayloadAction<EdicionDatosResumenComprobantes>,
    ) {
      state.datosFacturacionElectronica.recumenComprobantes = action.payload
    },
  },
})
export const {
  setCurrentAccion,
  setCurrentFunction,
  setDataEmpresa,
  setCurrentTab,
  setCurrentSubTab,
  setDataGeneral,
  setDpTipoIdentificacion,
  setDpTipoAgente,
  setDpTipoNegocio,
  setDataDocumentos,
  setDpRimpeClasificacion,
  setDataConfiguracionFacturacion,
  setDataResumenTransacciones,
} = empresaSlice.actions
export const EmpresaReducer = empresaSlice.reducer
