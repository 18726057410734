import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FETCH_STATUS, LoaderInfo } from "../../../../../store/types";
import { BusquedaState, FiltroBusquedaOrganico, OrganicoList } from "../types/types";
import { OrganicServices } from '../services/organico.services';


const initialState: BusquedaState<OrganicoList> = {
  filtro: {
    descripcion: '',
    initital: true
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  resultados: [],
  seleccionado: null,
  colapsado: true,
  searchOrganico: false,
  loader: {
    show: false,
    mensaje: '',
  },
  nroRegistrosEncontrados: 0
}


export const fetchOrganictList = createAsyncThunk<
  Array<OrganicoList>,
  FiltroBusquedaOrganico
>('nomina/organico/listar', async (filtro) => {
  try {
    const linesList = OrganicServices.getOrganic(filtro)
    return linesList
  } catch (error) {
    return Promise.reject(error)
  }
})


const searchSlice = createSlice({
  name: 'buscarOrganigrama',
  initialState: initialState,
  reducers: {
    setResetResults(state) {
      state.resultados = []
    },
    setResults(state, action: PayloadAction<OrganicoList[]>) {
      state.resultados = action.payload
    },
    changeFilter(state, action: PayloadAction<FiltroBusquedaOrganico>) {
      state.filtro.descripcion = action.payload.descripcion
      state.filtro.initital = action.payload.initital
    },
    setResetSeleccion(state) {
      state.seleccionado = null
    },
    setSeleccionarDato(state, action: PayloadAction<OrganicoList>) {
      state.seleccionado = action.payload
    },
    setCollapsed(state, action: PayloadAction<boolean>) {
      state.colapsado = action.payload
    },
    setNroRegistrosEncontradas(state, action: PayloadAction<number>) {
      state.nroRegistrosEncontrados = action.payload
    },
    setChangeLoader(state, action: PayloadAction<LoaderInfo>) {
      state.loader = action.payload
    },
    setSearch(state, action: PayloadAction<boolean>) {
      state.searchOrganico = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOrganictList.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchOrganictList.fulfilled, (state, { payload }) => {
      state.resultados = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchOrganictList.rejected, (state, { payload }) => {
      state.resultados = []
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
  },
})

export const {
  setResetResults,
  setResults,
  changeFilter,
  setResetSeleccion,
  setSeleccionarDato,
  setCollapsed,
  setNroRegistrosEncontradas,
  setChangeLoader,
  setSearch,
} = searchSlice.actions
export const searchReducer = searchSlice.reducer