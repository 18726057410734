import React from 'react'
import { v4 as uuidv4 } from 'uuid'

export interface ImageProps {
  show: boolean
  alt: string
  source: string
}

const ImageViewer = (props: ImageProps) => {
  const { show, alt, source } = props

  return (
    <>
      {show === true ? (
        <img
          key={uuidv4()}
          id={uuidv4()}
          src={source}
          className="avathar"
          style={{
            marginTop: '12px',
            maxWidth: '250px',
            maxHeight: '250px',
            objectFit: 'cover',
          }}
          alt={alt}
        />
      ) : (
        <></>
      )}
    </>
  )
}

export default React.memo(ImageViewer)
