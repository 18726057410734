import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CustomButtons, TabsState, TabState } from '../../../../types/generics'
import { TiposComprobantesSLV } from '../../../../../../store/types'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import { StatesEdition, TabTypes } from '../../../../types/enums'
import { VentaListado } from '../../../../types/types'
import { existeTab, ventasCloseTab } from '../../../../store/reducers'
import { DebitNoteButtons } from '../../../../components/notasDebito/notaDebito/notaDebito'

const initialState: TabsState<VentaListado> = {
  current: 'BUSQUEDA',
  tabs: {
    BUSQUEDA: {
      id: 0,
      canClose: false,
      editStatus: StatesEdition.doesNotApply,
      globalButtonClick: ButtonTypes.none,
      tittle: 'Búsqueda',
      type: TabTypes.busqueda,
      imprimir: false,
      tabKey: 'BUSQUEDA',
      buttons: {
        Buscar: true,
        Nuevo: true,
        Exportar: true,
      },
    },
  },
}

const tabsNotasCreditoSlice = createSlice({
  name: 'notasDebitoTabsSV',
  initialState: initialState,
  reducers: {
    ventasCloseTab,
    openTab(
      state,
      action: PayloadAction<{ key: string; venta?: VentaListado }>,
    ) {
      if (action.payload.venta === undefined) {
        const tab: TabState<VentaListado> = {
          id: -1,
          canClose: true,
          editStatus: StatesEdition.new,
          globalButtonClick: ButtonTypes.none,
          tittle: 'Nueva',
          type: TabTypes.documento,
          imprimir: false,
          tabKey: action.payload.key,
          info: {
            claveAcceso: '',
            numero: 0,
            tipoComprobante: TiposComprobantesSLV.NotaDebito,
          },
          buttons: DebitNoteButtons,
        }
        state.tabs[action.payload.key] = tab
        state.current = action.payload.key
      } else {
        const tabKey = existeTab(state.tabs, action.payload.venta.comprobante)
        if (tabKey) {
          state.current = tabKey
        } else {
          const tab: TabState<VentaListado> = {
            tabKey: action.payload.key,
            id: action.payload.venta.comprobante,
            tittle: `${action.payload.venta.establecimiento}-${action.payload.venta.puntoEmision}-${action.payload.venta.numero}`,
            canClose: true,
            editStatus: StatesEdition.save,
            type: TabTypes.documento,
            info: {
              claveAcceso: action.payload.venta.claveAcceso,
              numero: action.payload.venta.comprobante,
              tipoComprobante: TiposComprobantesSLV.NotaDebito,
              info: action.payload.venta,
            },
            globalButtonClick: ButtonTypes.none,
            imprimir: false,
            buttons: DebitNoteButtons,
          }
          state.tabs[action.payload.key] = tab
          state.current = action.payload.key
        }
      }
    },
    changeEditStatus(
      state,
      action: PayloadAction<{
        tabKey: string
        estado: StatesEdition
        info?: VentaListado
        buttons: CustomButtons
      }>,
    ) {
      const { estado, tabKey, info, buttons } = action.payload
      state.tabs[tabKey].editStatus = estado
      state.tabs[tabKey].currentExecutingAction = undefined
      state.tabs[tabKey].buttons = buttons
      if (info) {
        state.tabs[tabKey].info = {
          claveAcceso: info.claveAcceso,
          numero: info.comprobante,
          tipoComprobante: TiposComprobantesSLV.NotaDebito,
          info: info ?? null,
        }
        state.tabs[tabKey].id = info.comprobante
        state.tabs[
          action.payload.tabKey
        ].tittle = `${info.establecimiento}-${info.puntoEmision}-${info.numero}`
      }
    },
    buttonClick(
      state,
      action: PayloadAction<{ tabKey: string; button: ButtonTypes }>,
    ) {
      state.tabs[action.payload.tabKey].globalButtonClick =
        action.payload.button
    },
    clearButtonClick(state, action: PayloadAction<string>) {
      if (Object.prototype.hasOwnProperty.call(state.tabs, action.payload)) {
        state.tabs[action.payload].globalButtonClick = ButtonTypes.none
      }
    },
    changeCurrentTab(state, action: PayloadAction<string>) {
      if (Object.prototype.hasOwnProperty.call(state.tabs, action.payload)) {
        state.current = action.payload
      }

      //if (action.payload >= state.tabs.length) return;
    },
    setTabInfoAsInactive(state, action: PayloadAction<string>) {
      //if (action.payload >= state.tabs.length) return;
      const info = state.tabs[action.payload].info
      if (info !== undefined) {
        if (info.info) {
          info.info.estado = 'ANULADO'
          info.info.estadoElectronico = 'ANULADO'
        }
        state.tabs[action.payload].info = info
      }
    },
    setButtons(
      state,
      action: PayloadAction<{ tabKey: string; buttons: CustomButtons }>,
    ) {
      const { buttons, tabKey } = action.payload
      state.tabs[tabKey].buttons = buttons
      state.tabs[tabKey].currentExecutingAction = undefined
    },
    setCurrentExecutingAction(
      state,
      action: PayloadAction<{
        tabKey: string
        buttonType: ButtonTypes | undefined
      }>,
    ) {
      const { buttonType, tabKey } = action.payload
      state.tabs[tabKey].currentExecutingAction = buttonType
    },
  },
})

//export const selectMenuVentas = (state: RootState) => { return state.ventas.menuVentas };
export const {
  openTab,
  ventasCloseTab: closeTab,
  changeEditStatus,
  buttonClick,
  clearButtonClick,
  changeCurrentTab,
  setButtons,
  setCurrentExecutingAction,
  setTabInfoAsInactive,
} = tabsNotasCreditoSlice.actions
export const tabsReducer = tabsNotasCreditoSlice.reducer
