import { combineReducers } from '@reduxjs/toolkit'
import { tabsReducer } from './tabsReducer'
import { TConEmpresaState } from './types'
import { menuReducer } from './menuReducer'
import { dataEditionReducer } from './editDataReducer'
import { searchReducer } from './searchReducer'

export const ConfigEmpresaReducer = combineReducers<TConEmpresaState>({
  menu: menuReducer,
  tabs: tabsReducer,
  editData: dataEditionReducer,
  search: searchReducer,
})
