import React, { useEffect } from 'react'
import { GlobalMenu } from '../../../../../views/componentes/globalMenu/globalMenu'
import {
  AccionMenu,
  CustomButtons,
  FETCH_STATUS,
  ToastTypes,
} from '../../../../../store/types'
import CardContent from '../../../../bancos/components/cardContent'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../../../store/store'
import { FichaTecnicaDatosEdicion, GuardarFichaTecnica } from './types/types'
import {
  changeLoaderDataSheet,
  setCurrentFunction,
} from './store/generalReducer'
import { addToast } from '../../../../../store/toasterReducer'
import TabControl from './components/shared'
import { Aplicacion } from '../../../store/types'
import { utilidades } from '../../../../../helpers/utilidades'
import {
  changeCurrentTab,
  closeTabFicha,
  openTab,
  setButtons,
  setCurrentExecutingAction,
} from './store/tabsReducer'
import { ButtonTypes } from '../../../../../views/componentes/globalMenu/types'
import { v4 as uuidv4 } from 'uuid'
import { deleteEditData, initDatosEdicion } from './store/editDataReducer'
import { fetchFichaTecnica, fetchFichaTecnicaReporte, setCleanResult } from './store/serachReducer'
import Dialog from '../../../../../views/componentes/librerias/bootstrap-dialog'
import {
  useButtonDisabled,
  useMenuOptions,
} from '../../../../../hooks/useMenuButtonControls'
import { setCodigoModulo } from './store/configReducer'
import { EAplicationsAcatha } from '../../../../../store/enumsAplication'
import { DateUtils } from '../../../../../helpers/dateUtils'
import { dataSheetServices } from './services/unidades.services'

const pathVerificador = '/ficha_técnica'

interface FichaTecnicaProps extends React.PropsWithChildren {
  onSendData?: (data: any) => void
  modal?: boolean
}

const FichaTecnica: React.FunctionComponent<FichaTecnicaProps> = (props) => {

  const { onSendData, modal } = props

  const dispatch = useDispatch()
  const menuState = useSelector((state: RootState) => state.global.menu)
  const tabs = useSelector((state: RootState) => state.inventarios.catalogos.fechaTecnica.tabs)
  const loader = useSelector((state: RootState) => state.inventarios.catalogos.fechaTecnica.general.loader)
  const searchFilter = useSelector((state: RootState) => state.inventarios.catalogos.fechaTecnica.search.filter)
  const searchStatus = useSelector((state: RootState) => state.inventarios.catalogos.fechaTecnica.search.status)
  const searchResults = useSelector((state: RootState) => state.inventarios.catalogos.fechaTecnica.search.resultados)
  const selected = useSelector((state: RootState) => state.inventarios.catalogos.fechaTecnica.search.seleccionado)
  const [confirmDelete, setConfirmDelete] = React.useState<boolean>(false)
  const [confirmEdit, setConfirmEdit] = React.useState<boolean>(false)
  const [confirmClone, setConfirmClone] = React.useState<boolean>(false)
  const [confirmPrint, setConfirmPrint] = React.useState<boolean>(false)
  const [dataPrint, setDataPrint] = React.useState<Array<any>>([])
  const dialogRef = React.useRef<any>(null)
  const getButtonDisabled = useButtonDisabled(tabs)
  const { getMenuOptions, options } = useMenuOptions()

  const playLoader = React.useCallback((message = 'Cargando...') => {
    dispatch(changeLoaderDataSheet({ show: true, mensaje: message }))
  }, [dispatch])

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoaderDataSheet({ show: false, mensaje: '' }))
  }, [dispatch])

  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(
      addToast({
        title: 'Inventario - Ficha Tecnica',
        content: mensaje,
        type: tipo,
      }),
    )
  }, [dispatch])

  const onTabClosing = React.useCallback(
    async (index) => {
      const closedTab = tabs.tabs[index]
      dispatch(closeTabFicha(closedTab.tabKey ?? index))
      await utilidades.sleep(50)
      dispatch(deleteEditData(closedTab.tabKey ?? index))
    },
    [dispatch, tabs.tabs],
  )

  const onTabChanged = React.useCallback(
    (tabKey: string) => {
      dispatch(changeCurrentTab(tabKey))
    },
    [dispatch],
  )

  const onCleanResultFilter = React.useCallback(() => {
    dispatch(setCleanResult())
  }, [dispatch])

  const onConfirmEdit = React.useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea Editar el registro?`,
      actions: [
        Dialog.Action(
          <span>
            <u>A</u>ceptar
          </span>,
          (dialog) => {
            dialog.hide()
            dispatch(setCurrentFunction(ButtonTypes.edit))
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setConfirmEdit(false)
    return
  }, [dispatch])

  const onSearch = React.useCallback(async () => {
    try {
      const search_data = {
        base: searchFilter?.nroBase ?? '',
        grupoCodigo: searchFilter.tipoProducto?.codigo ?? -1,
      }
      const toAny: any = fetchFichaTecnica(search_data)
      const res = await dispatch(toAny)
      if (res !== null && res !== undefined && res['payload'].error === false) {
        setToast(res['payload'].message, ToastTypes.Success)
      } else {
        setToast(res['payload'].message, ToastTypes.Danger)
      }
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
  }, [dispatch, searchFilter, setToast])

  const onDuplicate = React.useCallback(async () => {
    try {
      const clone_data: GuardarFichaTecnica = {
        infoRegistro: {
          codigo: selected.row?.codigo ?? 0,
          serie: selected.row?.serie ?? '',
          base: selected.row?.base ?? '',
          itemCodigo: selected.row?.codigoItem ?? 0, //ITE_CODIGOP -> Cabecera
          estado: 1,
          fecha: selected.row?.fecha ?? DateUtils.getCurrentDateAsString('dd/MM/yyyy'),
          observaciones: selected.row?.observaciones ?? '',
          horma: 0, //ITE_CODIGOPH -> Horma
        }
      }
      playLoader()
      const data = await dataSheetServices.setDataSheetsClone(clone_data)
      stopLoader()
      if (data !== null && data !== undefined && data['error'] === false) {
        onSearch()
        setToast(data['message'], ToastTypes.Success)
      } else {
        setToast(data['message'], ToastTypes.Danger)
      }
    } catch (error) {
      stopLoader()
      setToast(error.message, ToastTypes.Danger)
    }
  }, [selected.row, playLoader, stopLoader, setToast, onSearch])

  const onGetDataPrint = React.useCallback(async () => {
    try {
      const data_print = {
        codigo: selected.row?.codigo ?? 0
      }
      const toAny: any = fetchFichaTecnicaReporte(data_print)
      const res = await dispatch(toAny)
      if (res !== null && res !== undefined && res['payload']?.error === false) {
        setDataPrint(res['payload']?.auto)
      } else {
        setToast(res['payload']?.message, ToastTypes.Danger)
      }
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
  }, [selected.row, dispatch, setToast])

  const onConfirmClone = React.useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea Duplicar el registro?`,
      actions: [
        Dialog.Action(
          <span>
            <u>A</u>ceptar
          </span>,
          (dialog) => {
            dialog.hide()
            onDuplicate()
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setConfirmClone(false)
    return
  }, [onDuplicate])


  const onConfirmPrint = React.useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Imprimir resumen Ficha?`,
      actions: [
        Dialog.Action(
          <span>
            <u>S</u>i
          </span>,
          (dialog) => {
            dialog.hide()
            onGetDataPrint()
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>N</u>o
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setConfirmPrint(false)
    return
  }, [onGetDataPrint])

  const onConfirmDelete = React.useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea Eliminar el Registro?`,
      actions: [
        Dialog.Action(
          <span>
            <u>A</u>ceptar
          </span>,
          (dialog) => {
            dialog.hide()
            dispatch(setCurrentFunction(ButtonTypes.delete))
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setConfirmDelete(false)
    return
  }, [dispatch])

  const onMenuButtonClick = React.useCallback(
    async (action: AccionMenu) => {
      const id = uuidv4()
      switch (action.actionType) {
        case ButtonTypes.find:
          if (tabs.current === 'BUSQUEDA') {
            await onSearch()
          } else dispatch(changeCurrentTab('BUSQUEDA'))
          break
        case ButtonTypes.new:
          if (tabs.current === 'BUSQUEDA') {
            dispatch(
              initDatosEdicion({
                key: id,
                data: defaultDataSheet,
              }),
            )
            dispatch(openTab({ key: id }))
          } else {
            dispatch(setCurrentFunction(ButtonTypes.new))
          }
          break
        case ButtonTypes.save:
          if (tabs.current !== 'BUSQUEDA')
            dispatch(setCurrentFunction(ButtonTypes.save))
          break
        case ButtonTypes.edit:
          if (tabs.current === 'BUSQUEDA') setConfirmEdit(true)
          break
        case ButtonTypes.clone:
          if (tabs.current === 'BUSQUEDA') setConfirmClone(true)
          break
        case ButtonTypes.print:
          if (tabs.current === 'BUSQUEDA') dispatch(setCurrentFunction(ButtonTypes.print))
          break
        case ButtonTypes.print_design:
          if (tabs.current === 'BUSQUEDA') dispatch(setCurrentFunction(ButtonTypes.print_design))
          break
        case ButtonTypes.broom:
          if (tabs.current === 'BUSQUEDA') onCleanResultFilter()
          else dispatch(setCurrentFunction(ButtonTypes.broom))
          break
        default:
          break
      }
    },
    [dispatch, onCleanResultFilter, tabs, onSearch],
  )


  const onGetPupUpData = React.useCallback(() => {
    if (selected.row !== null && selected.row !== undefined) {
      const data = selected.row
      onSendData(data)
    }
  }, [selected, onSendData])

  const onGetAppCode = React.useCallback(() => {
    dispatch(setCodigoModulo(parseInt(EAplicationsAcatha.FichaTecnica)))
  }, [dispatch])

  React.useEffect(() => {
    onGetAppCode()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (modal !== null && modal !== undefined && modal) {
      onGetPupUpData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected.row, modal])

  useEffect(() => {
    if (menuState.length > 0 && options.length === 0) {
      getMenuOptions(menuState, 'to', pathVerificador)
    }
  }, [menuState, getMenuOptions, options])

  React.useEffect(() => {
    if (confirmEdit) {
      onConfirmEdit()
    }
  }, [confirmEdit, onConfirmEdit])

  React.useEffect(() => {
    if (confirmPrint) {
      onConfirmPrint()
    }
  }, [confirmPrint, onConfirmPrint])

  React.useEffect(() => {
    if (confirmDelete) {
      onConfirmDelete()
    }
  }, [confirmDelete, onConfirmDelete])
  React.useEffect(() => {
    if (confirmClone) {
      onConfirmClone()
    }
  }, [confirmClone, onConfirmClone])

  React.useEffect(() => {
    if (searchStatus === FETCH_STATUS.FAILED) {
      dispatch(
        addToast({
          title: 'Buscar clientes',
          content: 'Error al realizar la búsqueda',
          type: ToastTypes.Danger,
        }),
      )
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'BUSQUEDA',
          buttonType: undefined,
        }),
      )
    }
    if (searchStatus === FETCH_STATUS.LOADING) {
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'BUSQUEDA',
          buttonType: ButtonTypes.find,
        }),
      )
    }
    if (searchStatus === FETCH_STATUS.SUCCESS) {
      dispatch(
        setButtons({
          tabKey: 'BUSQUEDA',
          buttons: {
            Nuevo: true,
            Guardar: false,
            Editar: selected.row !== null && selected.row !== undefined,
            Eliminar: selected.row !== null && selected.row !== undefined,
            Buscar: true,
            Imprimir: selected.row !== null && selected.row !== undefined,
            Duplicar: selected.row !== null && selected.row !== undefined,
            Limpiar: true,
          },
        }),
      )
    }
  }, [dispatch, searchStatus, playLoader, searchResults, stopLoader, selected.row])

  const optionsForm = () => {
    if (options.length > 0) {
      return (
        <>
          <GlobalMenu
            printButtonAsDropdown={true}
            showExportDesingOptions
            acciones={options}
            onClick={onMenuButtonClick}
            getButtonDisabled={getButtonDisabled}
            currentWorking={tabs.tabs[tabs.current].currentExecutingAction}
          />
        </>
      )
    } else {
      return <> </>
    }
  }

  const bodyForm = () => {
    return (
      <TabControl
        tabsState={tabs}
        onTabChanged={onTabChanged}
        ontabClosing={onTabClosing}
        tipoAplicacion={Aplicacion.catalogosFichaTecnica}
      />
    )
  }

  return (
    <>
      <div id={'catalogosFichaTecnica'}>
        <Dialog ref={dialogRef} />
        <CardContent
          childrenOptions={optionsForm()}
          childrenBody={bodyForm()}
          headerTitle={'Ficha Técnica'}
          aplicacion={'Ficha Técnica'}
          loader={loader}
        />
      </div>
    </>
  )
}

export default FichaTecnica

export const ModuleButtons: CustomButtons = {
  Nuevo: true,
  Guardar: true,
  Editar: false,
  Eliminar: false,
  Buscar: false,
  Imprimir: false,
  Limpiar: true,
  Duplicar: false
}

export const defaultDataSheet: FichaTecnicaDatosEdicion = {
  codigo: 0,
  fecha: DateUtils.getCurrentDateAsString(),
  itemReferencia: null,
  serie: '',
  nroBase: '',
  horma: null,
  grupo: '',
  propiedades: '',
  kitPartes: null,
  materiales: null,
  color: '',
  descripcionKit: '',
  descripcionMaterial: '',
  codigoUsuario: '',
  cantidad: '',
  unidad: '',
  desperdicio: '',
  costo: '',
  observaciones: '',
  imagen: '',
  datagrid: [],
  guardado: false,
}
