import React from 'react'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput'
import { DateBox, NumberBox, RadioGroup, TextBox, Validator } from 'devextreme-react'
import { consoleService } from '../../../../../../../services/console.service'
import TipoIdentificacionLookUp from '../../../../../../componentes/tipoIdentificacion/tipoIdentificacion'
import { TipoTransaccion } from '../../../../../../componentes/tipoIdentificacion/store/types'
import { DocumentInfo, ToastTypes } from '../../../../../../../store/types'
import { PersonalDatosEdicion } from '../../../types/types'
import { TabState } from '../../../../../../../store/genericTabTypes'
import EstadosCivilLookUp from '../../../../../../componentes/estadosCivilLookUp'
import TipoEstadoLookUp from '../../../../../../componentes/tipoEstado/'
import TipoSangreLookUp from '../../../../../../componentes/tipoSangre'
import LocalidadesLookUp from '../../../../../../componentes/localidadesLookUp/localidadesLookUp'
import TabContactos, { ETipoAsociado } from '../../../../../../proveedores/components/modalAdminProveedores/tabContactos/tabContactos'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import { setDatosEdicionPersonales } from '../../../store/editDataReducer'
import { AsyncRule, PatternRule, RequiredRule, StringLengthRule } from 'devextreme-react/data-grid'
import { MessagesKeys, lh } from '../../../../../../../helpers/localizationHelper'
import { PersonalServices } from '../../../services/personal.service'
import { addToast } from '../../../../../../../store/toasterReducer'
import validations from '../../../../../../../config/validations'

interface INewProps extends React.PropsWithChildren {
  info: DocumentInfo<PersonalDatosEdicion>
  tab: TabState<PersonalDatosEdicion>
  tabId: string
  refForm: any
}

export const generosGruposPersonal = [
  { value: 1, label: 'M' },
  { value: 0, label: 'F' },
  { value: -1, label: 'N/A' },
]

const Personales: React.FunctionComponent<INewProps> = (props) => {

  const { tabId } = props
  const dispatch = useDispatch()
  const personalState = useSelector((state: RootState) => state.nomina.personal.editData[tabId].personales)
  const [element] = React.useState('1')
  const tipoIdMessage: string = 'Tipo: elija un tipo'
  const estadoCivildMessage: string = 'Estado civil: elija un estado'
  const tipoSangredMessage: string = 'Tipo de Sangre: ingrese el tipo de sangre'
  const lugarResidenciaMessage: string = 'Lugar de Residencia: Elija un Lugar de Residencia'
  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(
      addToast({
        title: 'Nómina - Personal',
        content: mensaje,
        type: tipo,
      }),
    )
  }, [dispatch])
  const sms = validations['es']

  const onChangeValue = React.useCallback((data, key: string) => {
    dispatch(setDatosEdicionPersonales({
      key: tabId,
      data: {
        ...personalState,
        [key]: data
      }
    }))
  }, [dispatch, personalState, tabId])

  const validateId = React.useCallback(async (params) => {
    const data = await PersonalServices.getPersonalById(params?.value ?? '')
    if (data !== null && data !== undefined && data['error'] === false) {
      if (data['auto'] !== "NOT_FOUND_USER") {
        setToast(data['message'], ToastTypes.Warning)
        return Promise.reject(
          `La persona con cédula ${params.value ?? ''
          } ya se encuentra registrado, si desea modificar los datos debe dar clic en la opción de edición.`,
        )
      } else {
        return Promise.resolve()
      }
    } else {
      return Promise.reject('Error al validar identificación')
    }
  }, [setToast]);


  const inputIdentificacionCedula = React.useMemo(() => {
    return (
      <div key={'textCedula'}>
        <TextBox
          name="identificacionInput"
          placeholder="C.I. / Identificación"
          value={personalState?.cedula ?? ''}
          onValueChange={(e) => {
            onChangeValue(e, 'cedula')
          }}
          id={element}
        >
          <Validator>
            <RequiredRule trim message={lh.getMessage(MessagesKeys.ClienteNumeroIdentificacionRequerido)} />
            <StringLengthRule
              max="10"
              min="10"
              ignoreEmptyValue
              message={lh.getMessage(MessagesKeys.ClienteNumeroIdentificacionIncorrecto, '')}
            />
            <AsyncRule
              message={lh.getMessage(MessagesKeys.ClienteNumeroIdentificacionIncorrecto, '')}
              validationCallback={validateId}
            />
          </Validator>
        </TextBox>
      </div>
    )
  }, [element, validateId, onChangeValue, personalState]);

  const inputIdentificacionRuc = React.useMemo(() => {
    return (
      <div key={'textCedutextRucla'}>
        <TextBox
          name="identificacionInput"
          placeholder="R.U.C / Identificación"
          value={personalState?.cedula ?? ''}
          onValueChange={(e) => {
            onChangeValue(e, 'cedula')
          }}
          id={element}
        >
          <Validator>
            <PatternRule
              message={lh.getMessage(MessagesKeys.ClienteNumeroRucIncorrecto, '')}
              pattern="^[0-9]{5,}|^[0-9]{10}$|^[0-9]{13}$"
            />
            <AsyncRule
              message={lh.getMessage(MessagesKeys.ClienteNumeroRucIncorrecto, '')}
              validationCallback={validateId}
            />
            <StringLengthRule min="13" max="13" message={lh.getMessage(MessagesKeys.ClienteNumeroRucIncorrecto, '')} ignoreEmptyValue />
          </Validator>
        </TextBox>
      </div>
    )
  }, [element, validateId, onChangeValue, personalState])

  const inputIdentificacionPasaporte = React.useMemo(() => {
    return (
      <div key="textPasaporte">
        <TextBox
          name="ruc"
          placeholder="R.U.C / Identificación"
          value={personalState?.cedula ?? ''}
          onValueChange={(e) => {
            onChangeValue(e, 'cedula')
          }}
          id={element}
        >
          <Validator>
            <RequiredRule trim message={lh.getMessage(MessagesKeys.ClienteNumeroIdentificacionRequerido, '')} />
            <StringLengthRule
              max="15"
              message={lh.getMessage(MessagesKeys.ClienteNumeroIdentificacionMax, '').replace('@dinamic_var@', '15')}
              ignoreEmptyValue
            />
          </Validator>
        </TextBox>
      </div>
    )
  }, [element, onChangeValue, personalState])


  const validateIdCliente = React.useMemo(() => {
    if (personalState?.tipoId && personalState.tipoId?.codigo === '05') {
      return inputIdentificacionCedula
    } else if (personalState?.tipoId && personalState.tipoId?.codigo === '04') {
      return inputIdentificacionRuc
    } else {
      return inputIdentificacionPasaporte
    }
  }, [
    inputIdentificacionCedula, personalState,
    inputIdentificacionRuc, inputIdentificacionPasaporte
  ])

  const validateAsyncSelect = React.useCallback((datoSeleccion: any, message: string) => {
    if (datoSeleccion.value.codigo > -1) {
      return Promise.resolve()
    } else {
      return Promise.reject(message)
    }
  }, [])

  const validateAsyncSeleccionTipoId = React.useCallback((datoSeleccion) => {
    return validateAsyncSelect(datoSeleccion, tipoIdMessage)
  }, [validateAsyncSelect])

  const validateAsyncSeleccionEstadoCivil = React.useCallback((datoSeleccion) => {
    return validateAsyncSelect(datoSeleccion, estadoCivildMessage)
  }, [validateAsyncSelect])

  const validateAsyncSeleccionTipoSanre = React.useCallback((datoSeleccion) => {
    return validateAsyncSelect(datoSeleccion, tipoSangredMessage)
  }, [validateAsyncSelect])
  const validateAsyncSeleccionResidencia = React.useCallback((datoSeleccion) => {
    return validateAsyncSelect(datoSeleccion, lugarResidenciaMessage)
  }, [validateAsyncSelect])

  return (
    <>
      <RowContainer>
        <RowContainer>
          <CustomCol xs="12" md="3">
            <Labeled label="Tipo Id:">
              <TipoIdentificacionLookUp
                onChanged={(data) => {
                  if (data !== null && data !== undefined) {
                    consoleService.log(data)
                    onChangeValue(data, 'tipoId')
                  }
                }}
                transaccion={TipoTransaccion.compra}
                selected={personalState?.tipoId ?? null}
                provider={[]}
                id="lookUpTipoIdDGC"
              >
                <Validator>
                  <RequiredRule message={tipoIdMessage} />
                  <AsyncRule
                    message={tipoIdMessage}
                    validationCallback={validateAsyncSeleccionTipoId}
                  />
                </Validator>
              </TipoIdentificacionLookUp>
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="2">
            <Labeled label="Cédula:">
              {validateIdCliente}
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="2">
            <Labeled label="Codigo IESS:">
              <TextBox value={personalState?.codigoIESS ?? ''} onValueChange={(data) => onChangeValue(data, 'codigoIESS')} />
            </Labeled>
          </CustomCol>
        </RowContainer>
        <RowContainer>
          <CustomCol xs="12" md="2">
            <Labeled label="Nombres:">
              <TextBox value={personalState?.nombres ?? ''} onValueChange={(data) => onChangeValue(data, 'nombres')}>
                <Validator>
                  <RequiredRule message={'Nombres: ' + sms['required']} />
                </Validator>
              </TextBox>
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="2">
            <Labeled label="Apellidos:">
              <TextBox value={personalState?.apellidos ?? ''} onValueChange={(data) => onChangeValue(data, 'apellidos')} >
                <Validator>
                  <RequiredRule message={'Apellidos: ' + sms['required']} />
                </Validator>
              </TextBox>
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="2">
            <Labeled label="F. Nacimiento:">
              <DateBox value={personalState?.fechaNacimiento ?? ''} onValueChange={(data) => onChangeValue(data, 'fechaNacimiento')} >
                <Validator>
                  <RequiredRule message={'Fcha de Nacimiento: ' + sms['required']} />
                </Validator>
              </DateBox>
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="3">
            <Labeled label="Género">
              <RadioGroup
                name="generos"
                layout="horizontal"
                displayExpr="label"
                dataSource={generosGruposPersonal}
                value={personalState?.genero ?? null}
                onValueChange={(e) => {
                  console.log(e)
                  onChangeValue(e, 'genero')
                }}
              />
            </Labeled>
          </CustomCol>
        </RowContainer>
        <RowContainer>
          <CustomCol xs="12" md="3">
            <Labeled label="Estado Civil:">
              <EstadosCivilLookUp
                selected={personalState?.estadoCivil ?? null}
                onChanged={(data) => {
                  if (data !== null || data !== undefined) {
                    consoleService.log(data)
                    onChangeValue(data, 'estadoCivil')
                  }
                }}
                provider={[]}
                id={'lookUpEstadosCivilA'}
              >
                <Validator>
                  <RequiredRule message={estadoCivildMessage} />
                  <AsyncRule
                    message={estadoCivildMessage}
                    validationCallback={validateAsyncSeleccionEstadoCivil}
                  />
                </Validator>
              </EstadosCivilLookUp>
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="2">
            <Labeled label="Cargas Fam.:">
              <NumberBox value={personalState?.cargasFamiliares ?? null} onValueChange={(data) => onChangeValue(data, 'cargasFamiliares')} />
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="2">
            <Labeled label="Tipo de Sangre:">
              <TipoSangreLookUp
                onChanged={(data) => {
                  consoleService.log('data ', data)
                  onChangeValue(data, 'tipoSangre')
                }}
                selected={personalState?.tipoSangre ?? null}
              >
                <Validator>
                  <RequiredRule message={tipoSangredMessage} />
                  <AsyncRule
                    message={tipoSangredMessage}
                    validationCallback={validateAsyncSeleccionTipoSanre}
                  />
                </Validator>
              </TipoSangreLookUp>
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="2">
            <Labeled label="Estado:">
              <TipoEstadoLookUp
                onChanged={(e) => {
                  if (e !== null || e !== undefined) {
                    consoleService.log(e)
                    onChangeValue(e, 'estado')
                  }
                }}
                selected={personalState?.estado ?? null}
                provider={[]}
              />
            </Labeled>
          </CustomCol>
        </RowContainer>
        <RowContainer></RowContainer>
        <RowContainer>
          <CustomCol xs="12" md="6">
            <CustomCol xs="12" md="12">
              <Labeled label='Lugar Residencia:'>
                <LocalidadesLookUp
                  onChanged={(data) => {
                    consoleService.log('localidad ', data)
                    onChangeValue(data, 'ciudadReferencia')
                  }}
                  onChangedOptions={() => { }}
                  selected={personalState?.ciudadReferencia ?? null}
                  allowEdit
                  allowClear
                >
                  <Validator>
                    <RequiredRule message={lugarResidenciaMessage} />
                    <AsyncRule
                      message={lugarResidenciaMessage}
                      validationCallback={validateAsyncSeleccionResidencia}
                    />
                  </Validator>
                </LocalidadesLookUp>
              </Labeled>
            </CustomCol>
          </CustomCol>
          <CustomCol xs="12" md="6">
            <CustomCol xs="12" md="12">
              <Labeled label='Lugar Origen:'>
                <LocalidadesLookUp
                  onChanged={(data) => {
                    consoleService.log('localidad ', data)
                    onChangeValue(data, 'ciudadOrigen')
                  }}
                  onChangedOptions={() => { }}
                  selected={personalState?.ciudadOrigen ?? null}
                  allowEdit
                  allowClear
                />
              </Labeled>
            </CustomCol>
          </CustomCol>
        </RowContainer>
        <RowContainer>
          <CustomCol xs="12" md="7">
            <Labeled label='Dirección:'>
              <TextBox value={personalState?.direccion ?? ''} onValueChange={(data) => onChangeValue(data, 'direccion')} >
                <Validator>
                  <RequiredRule message={'Fcha de Dirección: ' + sms['required']} />
                </Validator>
              </TextBox>
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="5">
            <Labeled label='Número Casa:'>
              <TextBox value={personalState?.numero ?? ''} onValueChange={(data) => onChangeValue(data, 'numero')} />
            </Labeled>
          </CustomCol>
        </RowContainer>
        <RowContainer>
          <CustomCol xs="12" md="7">
            <Labeled label='Email:'>
              <TextBox value={personalState?.email ?? ''} onValueChange={(data) => onChangeValue(data, 'email')} />
            </Labeled>
          </CustomCol>
        </RowContainer>
        <RowContainer>
          <CustomCol xs='12' md='12'>
            <TabContactos
              tipoAsociado={ETipoAsociado.cliente}
              data={personalState?.telefonos ?? []}
              actionSelect={(data) => { consoleService.log(data) }}
              tiposOperadoras={[]}
              returnContactos={(contactos) => {
                if (contactos?.length > 0) {
                  consoleService.log(contactos)
                  onChangeValue(contactos, 'telefonos')
                }
              }}
            />
          </CustomCol>
        </RowContainer>
      </RowContainer>
    </>
  )
}

export default Personales