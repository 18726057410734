import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FETCH_STATUS } from '../../../../../store/types'
import { ZonaDetail, FiltrosDesemepnoState, SearchState } from '../types/types'
import { zonasServices } from '../services/zonas.services'

const initialState: SearchState<ZonaDetail> = {
  filter: {
    codigo: null,
    zona: '',
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  resultados: [],
}

export const fetchZones = createAsyncThunk<
  Array<ZonaDetail>,
  FiltrosDesemepnoState
>('clientes/zonas/listar', async (filtro) => {
  try {
    const performanceList = zonasServices.getZones(filtro)
    return performanceList
  } catch (error) {
    return Promise.reject(error)
  }
})

const searchSlice = createSlice({
  name: 'searchZonas',
  initialState: initialState,
  reducers: {
    setFilterZonas(state, action: PayloadAction<FiltrosDesemepnoState>) {
      state.filter.codigo = action.payload.codigo
      state.filter.zona = action.payload.zona
    },
    setCleanResult(state) {
      state.resultados = []
    },
    changeFilter(state, action: PayloadAction<FiltrosDesemepnoState>) {
      state.filter = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchZones.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchZones.fulfilled, (state, { payload }) => {
      state.resultados = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchZones.rejected, (state, { payload }) => {
      state.resultados = []
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
  },
})

export const { setFilterZonas, changeFilter, setCleanResult } =
  searchSlice.actions

export const searchReducer = searchSlice.reducer
