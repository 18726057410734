import React, { useCallback, useEffect, useMemo, useState } from 'react'
import FiltroBusqueda from './FiltroBusqueda'
import ResultadosBusqueda from './ResultadosBusqueda'
import { CCard } from '@coreui/react-pro'
import { DecimosDatosEdicion, SaveList, TenthList } from '../../types/types'
import { TabState } from '../../../../../../../store/genericTabTypes'
import { useDispatch, useSelector } from 'react-redux'
import { ButtonTypes } from '../../../../../../../views/componentes/globalMenu/types'
import { clearButtonClick, setButtons, setCurrentExecutingAction } from '../../store/tabsReducer'
import { ConfigPopupMsg, ToastTypes } from '../../../../../../../store/types'
import { addToast } from '../../../../../../../store/toasterReducer'
import { changeFilter, setChangeLoader, setImport, setResetSeleccion, setSearchC, setSearchT } from '../../store/searchReducers'
import { RootState } from '../../../../../../../store/store'
import BlockUi from '../../../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../../../views/componentes/loadingindicator/loadingindicator'
import { EAplicationsAcatha } from '../../../../../../../store/enumsAplication'
import { TheenthServices } from '../../services/theenth.services'
import DataSource from 'devextreme/data/data_source'
import { isMobile } from 'react-device-detect'
import ConfirmarAccionMsj from '../../../../../../componentes/confirmarAccionMsj'
import { defaultPopupMsgConfig } from '../../../../../store/types'
import { consoleService } from '../../../../../../../services/console.service'
import { MessagesKeys, lh } from '../../../../../../../helpers/localizationHelper'
import VisualizaError from '../../../../../../ventas/pages/shared/visualizaError/visualizaError'
import ListErrosValidationForm from '../../../../../../componentes/listadoErroresValidacionForm'
import { DateUtils } from '../../../../../../../helpers/dateUtils'


interface ISearchProps extends React.PropsWithChildren {
  tab: TabState<DecimosDatosEdicion>
  tabId: string
}


enum VistasBusqueda {
  Filtros,
  ResultadosBusqueda,
  Resumen,
  Loading,
  Error,
}

const draggingGroupName = 'appointmentsGroup'

const Search: React.FunctionComponent<ISearchProps> = (props) => {
  const { tabId, tab } = props

  const dispatch = useDispatch()
  const tabs = useSelector((state: RootState) => state.nomina.rol.decimos.tabs)
  const filter = useSelector((state: RootState) => state.nomina.rol.decimos.search.filter)
  const filterBackup = useSelector((state: RootState) => state.nomina.rol.decimos.search.filterBackup)
  const searchTercero = useSelector((state: RootState) => state.nomina.rol.decimos.search.searchDecimT)
  const searchCuarto = useSelector((state: RootState) => state.nomina.rol.decimos.search.searchDecimC)
  const currentApp = useSelector((state: RootState) => state.nomina.rol.decimos.config.currentApp)
  const seleccionado = useSelector((state: RootState) => state.nomina.rol.decimos.search.seleccionado)
  const loader = useSelector((state: RootState) => state.nomina.rol.decimos.search.loader)
  const { geTenthDatasource } = TheenthServices.useSetDataSources()
  const [arrayErrors, setArrayErrors] = React.useState<Array<string>>([])
  const [showErrorPopup, setShowErrorPopup] = React.useState<boolean>(false)
  const [popupMsgConfig, setPopupMsgConfig] = useState<ConfigPopupMsg>(
    defaultPopupMsgConfig,
  )
  const DataSourceTenth = useMemo(
    () => geTenthDatasource(filter, currentApp),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filter, currentApp],
  )

  const [dataSourceTenthT, setDataSourceTenthT] = useState<DataSource>(null)
  const [dataSourceTenthF, setDataSourceTenthF] = useState<DataSource>(null)
  const [vistaActual, setVistaActual] = useState<VistasBusqueda>(
    VistasBusqueda.Filtros,
  )
  const [pageIndex, setPageIndex] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(10)
  const onResetPag = useCallback(async () => {
    setPageIndex(0)
    setPageSize(10)
  }, [])

  const onSetButtonAction = useCallback(
    (tipo: ButtonTypes | undefined) => {
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'BUSQUEDA',
          buttonType: tipo,
        }),
      )
    },
    [dispatch],
  )

  const setToast = useCallback(
    (mensaje: string, tipo: ToastTypes) => {
      dispatch(
        addToast({
          title: `Nómina - Decimo ${currentApp === parseInt(EAplicationsAcatha.DecimoTercero) ? 'Tercero' : 'Cuarto'}`,
          content: mensaje,
          type: tipo,
        }),
      )
    },
    [dispatch, currentApp],
  )

  const onLoader = useCallback((showLoader: boolean, mensaje: string) => {
    dispatch(setChangeLoader({
      show: showLoader,
      mensaje: mensaje
    }))
  }, [dispatch])

  const handleOptionChange = useCallback(async (e) => {
    if (e.fullName === 'paging.pageSize') {
      setPageSize(e.value)
    }
    if (e.fullName === 'paging.pageIndex') {
      setPageIndex(e.value)
    }
  }, [])

  const onFind = useCallback(async () => {
    onSetButtonAction(ButtonTypes.find)
    if (!isMobile) {
      onLoader(true, 'Buscando...')
    }
    try {
      if (isMobile) {
        setVistaActual(VistasBusqueda.Loading)
      }
      await DataSourceTenth.reload()
      if (currentApp === parseInt(EAplicationsAcatha.DecimoTercero)) {
        setDataSourceTenthT(DataSourceTenth)
        dispatch(setSearchT(true))
      } else {
        setDataSourceTenthF(DataSourceTenth)
        dispatch(setSearchC(true))
      }
      if (isMobile) {
        setVistaActual(VistasBusqueda.ResultadosBusqueda)
      }
      setToast(`${DataSourceTenth.totalCount()} Registro(s) Encontrado(s)`, ToastTypes.Success)
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
      if (isMobile) {
        setVistaActual(VistasBusqueda.Error)
      }
    }
    if (!isMobile) {
      onLoader(false, '')
    }
    onSetButtonAction(undefined)
  }, [DataSourceTenth, dispatch, onLoader, onSetButtonAction, setToast, currentApp])

  const onSaveList = useCallback(async () => {
    let provider: Array<TenthList> = []
    if (currentApp === parseInt(EAplicationsAcatha.DecimoTercero)) {
      provider = dataSourceTenthT?.items()
    } else {
      provider = dataSourceTenthF?.items()
    }

    consoleService.log(provider, 'provider')
    onSetButtonAction(ButtonTypes.save)
    onLoader(true, 'Actualizando lista...')
    try {
      const lista_save: SaveList = {
        decimos: provider,
        fpago: filter?.formaPago?.codigo === -1 ? 0 : filter?.formaPago?.codigo
      }
      const data = await TheenthServices.saveListTenth(lista_save)
      consoleService.log(data, 'list save')
      if (data?.auto && data?.error === false) {
        setToast(data?.message, ToastTypes.Success)
        onFind()
      } else {
        setToast(data?.message, ToastTypes.Danger)
      }
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
    onLoader(false, '')
    onSetButtonAction(undefined)
  }, [currentApp, dataSourceTenthF, dataSourceTenthT, setToast, filter, onLoader, onSetButtonAction, onFind])

  const onPay = useCallback(async () => {
    let provider: Array<TenthList> = []
    if (currentApp === parseInt(EAplicationsAcatha.DecimoTercero)) {
      provider = dataSourceTenthT?.items().slice(0)
    } else {
      provider = dataSourceTenthF?.items().slice(0)
    }
    onSetButtonAction(ButtonTypes.salaries)
    onLoader(true, 'Generando Pago...')
    try {
      let fcobro = ''
      if (filter?.fechaCobro !== null && filter?.fechaCobro !== undefined && filter?.fechaCobro !== "") {
        fcobro = DateUtils.format(filter?.fechaCobro, 'dd/MM/yyyy', 'yyyy-MM-dd')
      }
      provider.map(x => {
        x.fechaCobro = fcobro
      })
      const data = await TheenthServices.payListTenth(provider)
      consoleService.log(data, 'data pay res')
      if (data?.auto && data?.error === false) {
        setToast(data?.message, ToastTypes.Success)
        onFind()
      } else {
        setToast(data?.message, ToastTypes.Danger)
      }
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
    onLoader(false, '')
    onSetButtonAction(undefined)
  }, [dataSourceTenthT, dataSourceTenthF, currentApp, filter, setToast, onFind, onLoader, onSetButtonAction])

  const onConfirmDelete = React.useCallback(() => {
    if (seleccionado !== null && seleccionado?.codigo) {
      setPopupMsgConfig({
        show: true,
        title: 'Ácatha',
        message: `¿Desea ELIMINAR el registro ${seleccionado?.nombre ?? '-'}?`,
        type: 'confirm',
        currentAction: ButtonTypes.delete,
      })
    }
  }, [seleccionado])


  const onValidatePay = useCallback(() => {
    const errors: Array<string> = []
    if (filter?.fechaCobro === null || filter?.fechaCobro === undefined || filter?.fechaCobro === '') {
      errors.push('Ingrese una fecha de cobro.')
    }
    return errors
  }, [filter])

  const onPayment = useCallback(() => {
    let errors: Array<string> = []
    errors = onValidatePay()

    if (errors.length === 0) {
      setPopupMsgConfig({
        show: true,
        title: 'Ácatha',
        message: `¿Está seguro que desea realizar el pago del décimo ${currentApp === parseInt(EAplicationsAcatha.DecimoTercero) ? 'tercer' : 'cuarto'} sueldo de Todos?`,
        type: 'confirm',
        currentAction: ButtonTypes.payments,
      })
    } else {
      setToast(lh.getMessage(MessagesKeys.GlobalErrorSave), ToastTypes.Warning)
      setArrayErrors(errors)
      setShowErrorPopup(true)
    }

  }, [onValidatePay, currentApp, setToast])

  const onUndo = useCallback(() => {
    dispatch(changeFilter({
      ...filterBackup
    }))
    dispatch(setResetSeleccion())
  }, [filterBackup, dispatch])

  const onBroom = useCallback(() => {
    if (currentApp === parseInt(EAplicationsAcatha.DecimoTercero)) {
      setDataSourceTenthT(null)
      dispatch(setSearchT(false))
    } else {
      setDataSourceTenthF(null)
      dispatch(setSearchC(false))
    }
    dispatch(setResetSeleccion())
  }, [dispatch, currentApp])

  const handleButtonClick = useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.rols:
        case ButtonTypes.find:
          if (tabs.current === tabId) {
            onResetPag()
            onFind()
          }
          break
        case ButtonTypes.delete:
          if (tabs.current === tabId) {
            if (seleccionado !== null && seleccionado?.codigo) {
              onConfirmDelete()
            } else {
              setToast('Debe elegir un Grupo para deshabilitarlo.', ToastTypes.Warning)
            }
          }
          break
        case ButtonTypes.import:
          if (tabs.current === tabId) dispatch(setImport(true))
          break
        case ButtonTypes.save:
          if (tabs.current === tabId) onSaveList()
          break
        case ButtonTypes.salaries:
          if (tabs.current === tabId) onPayment()
          break
        case ButtonTypes.undo:
          if (tabs.current === tabId) onUndo()
          break
        case ButtonTypes.broom:
          if (tabs.current === tabId) onBroom()
          break
        default:
          break
      }
      dispatch(clearButtonClick(tabId))
    }, [dispatch, onResetPag, tabId, tabs, onFind, onConfirmDelete, seleccionado, setToast, onSaveList, onPayment, onUndo, onBroom])



  useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab.globalButtonClick])

  useEffect(() => {
    if (searchTercero && currentApp === parseInt(EAplicationsAcatha.DecimoTercero)) setDataSourceTenthT(DataSourceTenth)
    if (searchCuarto && currentApp === parseInt(EAplicationsAcatha.DecimoCuarto)) setDataSourceTenthF(DataSourceTenth)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onHandleDelete = useCallback(async () => {
    onSetButtonAction(ButtonTypes.delete)
    onLoader(true, 'Eliminando...')
    try {
      const data = await TheenthServices.deletTenth(seleccionado?.codigo ?? 0)
      consoleService.log(data, 'data delete')
      if (data?.auto && data?.error === false) {
        setToast(data?.message, ToastTypes.Success)
        dispatch(setResetSeleccion())
        DataSourceTenth.reload()
        if (currentApp === parseInt(EAplicationsAcatha.DecimoTercero)) {
          setDataSourceTenthT(DataSourceTenth)
        } else {
          setDataSourceTenthF(DataSourceTenth)
        }
      }
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
    onLoader(false, '')
    onSetButtonAction(undefined)
  }, [setToast, onLoader, onSetButtonAction, seleccionado, currentApp, DataSourceTenth, dispatch])

  const onHandleConfirm = useCallback(() => {
    if (popupMsgConfig.type === 'confirm') {
      if (popupMsgConfig.currentAction === ButtonTypes.edit) consoleService.log(ButtonTypes.edit)
      else if (popupMsgConfig.currentAction === ButtonTypes.delete)
        onHandleDelete()
      else if (popupMsgConfig.currentAction === ButtonTypes.payments)
        onPay()
    }
    setPopupMsgConfig(defaultPopupMsgConfig)
  }, [onHandleDelete, popupMsgConfig, onPay])

  const onHandleCancel = useCallback(() => {
    setPopupMsgConfig(defaultPopupMsgConfig)
  }, [])

  React.useEffect(() => {
    dispatch(
      setButtons({
        tabKey: 'BUSQUEDA',
        buttons: {
          Nuevo: true,
          Guardar: currentApp === parseInt(EAplicationsAcatha.DecimoTercero) ? dataSourceTenthT !== null : dataSourceTenthF !== null,
          Editar: seleccionado !== null && seleccionado !== undefined,
          Eliminar: seleccionado !== null && seleccionado !== undefined,
          Buscar: true,
          Imprimir: currentApp === parseInt(EAplicationsAcatha.DecimoTercero) ? dataSourceTenthT !== null : dataSourceTenthF !== null,
          Cheque: false,
          Ver_asiento: false,
          Transferencias: true,
          Deshacer: true,
          Pagos: false,
          Sueldos: true,
          Todos: true,
          Roles: true,
          Limpiar: currentApp === parseInt(EAplicationsAcatha.DecimoTercero) ? dataSourceTenthT !== null : dataSourceTenthF !== null,
          Importar: true,
          Adjuntar: false,
        },
      }),
    )
  }, [dispatch, seleccionado, dataSourceTenthF, dataSourceTenthT, currentApp])


  return (
    <>
      <ConfirmarAccionMsj
        title={popupMsgConfig.title}
        isVisible={popupMsgConfig.show}
        handleConfirm={onHandleConfirm}
        handleCancel={onHandleCancel}
        message={popupMsgConfig.message}
        typeMessage={popupMsgConfig.type}
        typeInputInfo={null}
      />
      {showErrorPopup && (
        <VisualizaError
          titulo={`Error en Decimo ${currentApp === parseInt(EAplicationsAcatha.DecimoTercero) ? 'Tercero' : 'Cuarto'}`}
          mensaje={'Error al pagar'}
          content={
            <ListErrosValidationForm
              mainMessage={'Revise los siguientes errores:'}
              errorsList={arrayErrors}
              color={'danger'}
            />
          }
          onOk={() => setShowErrorPopup(false)}
        />
      )}
      <div id="DecimosContent">
        <BlockUi
          tag="div"
          loader={LoadingIndicator}
          blocking={loader.show}
          message={loader.mensaje}
        >
          <CCard>
            <FiltroBusqueda tab={tab} tabId={tabId} onSearchEnter={onFind} />
            <ResultadosBusqueda
              onDBClick={() => { }}
              data={currentApp === parseInt(EAplicationsAcatha.DecimoTercero) ? dataSourceTenthT : dataSourceTenthF}
              handleOptionChange={handleOptionChange}
              pageIndex={pageIndex}
              pageSize={pageSize}
              onDelete={onConfirmDelete}
            />
          </CCard>
        </BlockUi>
      </div>
    </>
  )
}

export default React.memo(Search)