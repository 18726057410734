import React, { useCallback, useEffect, useState } from 'react'
import Modalform from '../../../views/componentes/modalform'
import { CCard, CCardBody, CCardHeader } from '@coreui/react-pro'
import { LoadPanel, NumberBox, TextBox } from 'devextreme-react'
import { RootState } from '../../../store/store'
import { useDispatch, useSelector } from 'react-redux'
import { GlobalMenu } from '../../../views/componentes/globalMenu/globalMenu'
import { AccionMenu, ToastTypes } from '../../../store/types'
import { ButtonTypes } from '../../../views/componentes/globalMenu/types'
import RowContainer from '../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../views/componentes/colContainer'
import Labeled from '../../../views/componentes/labeledInput/labeledInput'
import DataGrid, { Column, ColumnChooser, Pager, Paging } from 'devextreme-react/data-grid'
import { getAllowedPageSizes } from '../../../helpers/Helper'
import ItemsCountIndicator from '../indicadorNroRegistros'
import { MarcationList, Marcations, MarcationsListDto } from '../../nomina/pages/registro/marcacion/types/types'
import { ModalDetalleRegistro } from '../modalDetallesRegistro'
import { consoleService } from '../../../services/console.service'
import { changeLoader } from '../../../store/reducers'
import { addToast } from '../../../store/toasterReducer'
import { MarcationsService } from '../../nomina/pages/registro/marcacion/services/marcations.service'
import { ReportWithLocalDataModal } from '../../../views/componentes/xtrareports/ReportWithLocalDataModal'

interface IModalVerMarcaciones extends React.PropsWithChildren {
  onCancel: () => void
  show: boolean
  usuario: number
  usuarioNombre: string
  rolparametroCodigo: number
  htrcodigo: number
  mes: string
  mesNombre: string
  anio: number
}


const menuDefecto = [

  {
    icon: 'fal fa-print',
    id: 'Impirmir',
    text: 'Impirmir',
    type: ButtonTypes.print,
  },
]

const ModalVerMarcaciones: React.FC<IModalVerMarcaciones> = (props) => {
  const { onCancel, show, usuario, rolparametroCodigo, htrcodigo, mes, mesNombre, anio, usuarioNombre } = props

  const dispatch = useDispatch()
  const loader = useSelector((state: RootState) => state.global.loader);
  const [resultados, setResultados] = useState<Array<MarcationList> | []>([])
  const [atrasos, setAtrasos] = useState<number>(0)
  const [salidaAnticipada, setSalidaAnticipada] = useState<number>(0)
  const [tAtrasos, setTAtrasos] = useState<number>(0)
  const [tSalAnticipo, setTSalAnticipo] = useState<number>(0)
  const [hExtra50, setHExtra50] = useState<string>('')
  const [hExtra100, setHExtra100] = useState<string>('')


  const [seleccionado, setSeleccionado] = useState<MarcationList>(null)
  const [showDetalleRegistro, setShowDetalleRegistro] = useState<boolean>(false)
  const [reporte, setReporte] = React.useState<null | "Viewer" | "Designer">(null);

  const botones = menuDefecto.map(acc => {
    const boton: AccionMenu = {
      nombre: acc.id,
      icon: acc.icon,//as any,
      actionType: acc.type,
      main: false,
      modal: false,
      shortcut: null
    }
    return boton;
  })

  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(addToast({
      content: mensaje,
      title: 'Marcaciones',
      type: tipo
    }));
  }, [dispatch])

  const playLoader = React.useCallback(() => {
    dispatch(changeLoader({ show: true, mensaje: 'Cargando...' }))
  }, [dispatch])

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoader({ show: false, mensaje: '' }))
  }, [dispatch])

  const loadArrears = useCallback((data: Array<MarcationList>) => {
    let atrasos: number = 0
    let salidas: number = 0
    let hsalidas: number = 0
    let hatrasos: number = 0
    let hextra50: number = 0
    let hextra100: number = 0
    data.forEach(x => {
      atrasos = atrasos + x?.atrasos ?? 0
      salidas = salidas + x?.antes ?? 0
      hsalidas = hsalidas + x?.minutosAntes ?? 0
      hatrasos = hatrasos + x?.minutosAtraso ?? 0
      hextra50 = hextra50 + x?.minutosExtra50 ?? 0
      hextra100 = hextra100 + x?.minutosExtra100 ?? 0
    })
    setAtrasos(atrasos)
    setSalidaAnticipada(salidas)
    setTAtrasos(hatrasos)
    setTSalAnticipo(hsalidas)
    setHExtra50(`${(hextra50 / 60)}:` + String((hextra50 % 60)).substring(0, 2) + ":00")
    setHExtra100(`${(hextra100 / 60)}:` + String((hextra100 % 60)).substring(0, 2) + ":00")
  }, [])


  const onFind = useCallback(async () => {
    playLoader()
    try {
      const data = await MarcationsService.loadMarctions(usuario, mes, anio, rolparametroCodigo, htrcodigo)
      consoleService.log(data, 'see marcations')
      if (data?.auto && data?.error === false) {
        setResultados(data?.auto)
        loadArrears(data?.auto)
      } else {
        setToast(data?.message, ToastTypes.Danger)
      }
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
    stopLoader()
  }, [setToast, usuario, mes, anio, rolparametroCodigo, htrcodigo, playLoader, stopLoader, loadArrears])


  const returnAction = (boton: AccionMenu) => {

    switch (boton.actionType) {
      case ButtonTypes.print:
        setReporte('Viewer')
        break;
      default:
        break;
    }
  }

  const getButtonDisabled = React.useCallback((button: AccionMenu) => {
    switch (button.actionType) {
      case ButtonTypes.print:
        return resultados.length <= 0
        break
      default:
        return false;
    }
  }, [resultados])

  const [acciones,] = React.useState<Array<AccionMenu>>(botones);

  useEffect(() => {
    onFind()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const botonesModal = () => {
    return (

      <GlobalMenu
        acciones={acciones}
        onClick={returnAction}
        getButtonDisabled={getButtonDisabled}
      />

    );
  }

  const onSelectedChanged = useCallback(
    ({ selectedRowsData }) => {
      consoleService.log(selectedRowsData[0], 'seleccionado selectedRowsData[0]')
      if (selectedRowsData[0] && selectedRowsData[0]?.fecha) {
        setSeleccionado({ ...selectedRowsData[0] })
      }
    },
    [],
  )

  const onDBClick = useCallback(() => {
    if (seleccionado !== null || seleccionado !== undefined) {
      setShowDetalleRegistro(true)
    }
  }, [seleccionado])

  const onParseData = useCallback(() => {
    const registros: Array<MarcationsListDto> = []
    const lista: Array<Marcations> = []
    if (resultados.length > 0) {
      resultados.forEach(x => {
        lista.push({
          Date: x?.fecha ?? '',
          Day: x?.diaDescripcion ?? '',
          MinutesWorked: x?.minutosTrabajados ?? 0,
          Arrears: x?.atrasos ?? 0,
          MinutesLate: x?.minutosAtraso ?? 0,
          Before: x?.antes ?? 0,
          MinutesBefore: x?.minutosAntes ?? 0,
          Xtra50: x?.minutosExtra50 ?? 0,
          Xtra100: x?.minutosExtra100 ?? 0,
          Errors: x?.error ?? '',
        })
      })
    }
    const registro: MarcationsListDto = {
      Employee: usuarioNombre ?? '',
      Month: mesNombre ?? '',
      Year: anio ?? 0,
      Marcations: lista
    }
    registros.push(registro)
    consoleService.log(registros)
    return registros
  }, [resultados, anio, mesNombre, usuarioNombre])


  const bodyModal = () => {

    return (
      <>
        <ReportWithLocalDataModal
          show={reporte !== null}
          onClose={() => setReporte(null)}
          data={onParseData()}
          fileName={'StaticMarcations'}
          mode={reporte ?? 'Viewer'}
          parameters={{ 'Reporte_Filtro': '' }}
          template={'StaticMarcations'}
          key='reportDesigner'
        />
        {showDetalleRegistro && <ModalDetalleRegistro
          onCancel={() => setShowDetalleRegistro(false)}
          show={showDetalleRegistro}
          usuarioCodigo={usuario}
          fecha={seleccionado?.fecha}
        />}
        <RowContainer>
          <CustomCol xs="12" md="3">
            <Labeled label='Atrasos:'>
              <NumberBox readOnly value={atrasos ?? null} />
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="3">
            <Labeled label='Tiempo Atrasos:'>
              <NumberBox readOnly value={tAtrasos ?? null} />
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="3">
            <Labeled label='Hora Extra 50%:'>
              <TextBox readOnly value={hExtra50 ?? null} />
            </Labeled>
          </CustomCol>
        </RowContainer>
        <RowContainer>
          <CustomCol xs="12" md="3">
            <Labeled label='Salida Anticipada:'>
              <NumberBox readOnly value={salidaAnticipada ?? null} />
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="3">
            <Labeled label='Tiempo Sal Anticipos:'>
              <NumberBox readOnly value={tSalAnticipo ?? null} />
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="3">
            <Labeled label='Hora Extra 100%:'>
              <TextBox readOnly value={hExtra100 ?? null} />
            </Labeled>
          </CustomCol>
        </RowContainer>
        <RowContainer className="mt-2">
          <CustomCol xs="12" md="12">
            <ItemsCountIndicator items={resultados} />
            <DataGrid
              keyExpr="fecha"
              focusedRowKey="fecha"
              id="tabCertificados"
              selection={{ mode: 'single' }}
              dataSource={resultados ?? []}
              showColumnLines={true}
              hoverStateEnabled={true}
              showRowLines={true}
              showBorders={true}
              columnResizingMode="widget"
              focusedRowEnabled={true}
              onSelectionChanged={onSelectedChanged}
              onRowDblClick={onDBClick}
              loadPanel={{
                enabled: true,
                height: 90,
                indicatorSrc: '',
                shading: false,
                shadingColor: '',
                showIndicator: true,
                showPane: true,
                text: 'Cargando...',
                width: 200,
              }}
            >
              <Paging defaultPageSize={10} />
              <Pager
                visible={resultados.length > 0 ? true : false}
                allowedPageSizes={getAllowedPageSizes(resultados)}
                displayMode={'full'}
                showPageSizeSelector={true}
                showInfo={true}
                showNavigationButtons={true}
              />
              <ColumnChooser enabled={true} mode="select" />

              <Column dataField='fecha' caption="Fecha" width="100px" />
              <Column dataField='diaDescripcion' caption="Día" width="100px" />
              <Column dataField='minutosTrabajados' caption="Min.Trabajo" width="100px" />
              <Column dataField='atrasos' caption="Atrasos" width="100px" />
              <Column dataField='minutosAtraso' caption="Min.Atra" width="100px" />
              <Column dataField='antes' caption="Salidas Antic" width="100px" />
              <Column dataField='minutosAntes' caption="MIn.Sal.Ant" width="100px" />
              <Column dataField='minutosExtra50' caption="Extras 50% (min)" width="100px" />
              <Column dataField='minutosExtra100' caption="Extras 100% (min)" width="100px" />
              <Column dataField='error' caption="Errores" width="100px" />
            </DataGrid>
          </CustomCol>
        </RowContainer>
      </>
    )
  }

  const renderContent = () => {
    return (
      <CCard>
        <CCardHeader>
          {botonesModal()}
        </CCardHeader>
        <CCardBody>
          <LoadPanel
            shadingColor="rgba(0,0,0,0.4)"
            position={'center'}
            visible={loader.show}
            showIndicator={true}
            shading={true}
            showPane={true}
            message={loader.mensaje}
            closeOnOutsideClick={false}
          />
          {bodyModal()}
        </CCardBody>
      </CCard>
    )
  }


  return (
    <>
      <Modalform
        name={'verMarcaciones'}
        key={'verMarcaciones'}
        headerTitle={"Marcaciones"}
        childrenBody={renderContent()}
        closeOnBackdrop={false}
        show={show}
        onClose={() => onCancel()}
        centered={true}
        size='lg'
      />
    </>
  )
}

export default ModalVerMarcaciones