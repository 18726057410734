import { RequestHelper } from "../../../../helpers/requestHelper";
import { VendedorBusqueda } from "../../../shared/components/buscarVendedorLookMemoUp/type/types";
import { TVendedorIngresar } from "../store/types";

export const VendedoresService = {
  getVendedores, ingresarVendedor
}

async function getVendedores(
  codigoLocal: number,
): Promise<Array<VendedorBusqueda>> {
  const query: any = {
    codigoLocal: codigoLocal,
  };

  const proveedoresApi = await RequestHelper.get<Array<VendedorBusqueda>>(
    "clientes/",
    "sellers/getAll",
    "",
    query
  );
  return proveedoresApi;
}


async function ingresarVendedor(seller: TVendedorIngresar): Promise<Promise<any>> {
  try {
    const infoRegistro = {
      infoRegistro: seller
    }
    const creada = await RequestHelper.postAll<any>("ventas/ventas", "saveSeller", infoRegistro);
    return creada;
  } catch (error) {
    return error;
  }
}