import React, { useEffect } from 'react'
import {
  AccionMenu,
  CustomButtons,
  FETCH_STATUS,
  ToastTypes,
} from '../../../../store/types'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../../store/store'
import { SeriesList, TitleTabSeries } from './types/types'
import {
  changeLoaderMultistore,
  setCurrentFunction,
} from './store/generalReducer'
import { addToast } from '../../../../store/toasterReducer'
import { Aplicacion } from '../../store/types'
import { utilidades } from '../../../../helpers/utilidades'
import {
  changeCurrentTab,
  seriesCloseTab,
  openTab,
  setButtons,
  setCurrentExecutingAction,
  buttonClick,
} from './store/tabsReducer'
import { ButtonTypes } from '../../../../views/componentes/globalMenu/types'
import { v4 as uuidv4 } from 'uuid'
import { deleteEditData, initDatosEdicion } from './store/editDataReduce'
import { changeFilter, fetchSeries, fetchSeriesCom, setCleanResult, setCollapsed, setCollapsedAvanzed } from './store/serachReducer'
import Dialog from '../../../../views/componentes/librerias/bootstrap-dialog'
import {
  useButtonDisabled,
  useMenuOptions,
} from '../../../../hooks/useMenuButtonControls'
import SeriesMain from './components/shared/main'
import { setMenuButtons } from './store/menuReducer'
import { consoleService } from '../../../../services/console.service'
import { EModulosAcatha } from '../../../../store/enumsAplication'
import { setModulo } from './store/configReducer'

const pathVerificador = '/series'

interface MultistoreProps extends React.PropsWithChildren { }

const Series: React.FunctionComponent<MultistoreProps> = () => {
  const dispatch = useDispatch()
  const menuState = useSelector((state: RootState) => state.global.menu)
  const tabs = useSelector((state: RootState) => {
    return state.inventarios.series.tabs
  })
  const searchResultsCom = useSelector((state: RootState) => {
    return state.inventarios.series.search.resultListCom
  })
  const searchFilter = useSelector((state: RootState) => {
    return state.inventarios.series.search.filter
  })
  const searchStatus = useSelector((state: RootState) => {
    return state.inventarios.series.search.status
  })
  const statusSearch = useSelector((state: RootState) => {
    return state.inventarios.series.search.status
  })
  const searchResults = useSelector((state: RootState) => {
    return state.inventarios.series.search.resultList
  })
  const currentTab = useSelector((state: RootState) => {
    return state.inventarios.series.search.currentTab
  })

  const [confirmarBorrar, setConfirmarBorrar] = React.useState<boolean>(false)
  const [confirmarEditar, setConfirmarEditar] = React.useState<boolean>(false)
  const dialogRef = React.useRef<any>(null)
  const getButtonDisabled = useButtonDisabled(tabs)
  const { getMenuOptions, options } = useMenuOptions()

  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(
      addToast({
        title: 'Inventario-Series',
        content: mensaje,
        type: tipo,
      }),
    )
  }, [dispatch])

  const playLoader = React.useCallback(
    (message = 'Cargando...') => {
      dispatch(changeLoaderMultistore({ show: true, mensaje: message }))
    },
    [dispatch],
  )

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoaderMultistore({ show: false, mensaje: '' }))
  }, [dispatch])

  const onTabClosing = React.useCallback(
    async (index) => {
      const closedTab = tabs.tabs[index]
      dispatch(seriesCloseTab(closedTab.tabKey ?? index))
      await utilidades.sleep(50)
      dispatch(deleteEditData(closedTab.tabKey ?? index))
    },
    [dispatch, tabs.tabs],
  )

  const onTabChanged = React.useCallback(
    (tabKey: string) => {
      dispatch(changeCurrentTab(tabKey))
    },
    [dispatch],
  )

  const onCleanResultFilter = React.useCallback(() => {
    dispatch(setCleanResult())
    dispatch(changeFilter({
      ...searchFilter,
      serie: ''
    }))
  }, [dispatch, searchFilter])

  const onConfirmarEditar = React.useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea Editar el registro?`,
      actions: [
        Dialog.Action(
          <span>
            <u>A</u>ceptar
          </span>,
          (dialog) => {
            dialog.hide()
            dispatch(setCurrentFunction(ButtonTypes.edit))
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setConfirmarEditar(false)
    return
  }, [dispatch])

  const onConfirmarBorrar = React.useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea Eliminar el Registro?`,
      actions: [
        Dialog.Action(
          <span>
            <u>A</u>ceptar
          </span>,
          (dialog) => {
            dialog.hide()
            dispatch(setCurrentFunction(ButtonTypes.delete))
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setConfirmarBorrar(false)
    return
  }, [dispatch])

  const onSearchFilter = React.useCallback(async () => {
    try {
      let res = null
      if (currentTab === TitleTabSeries.kardex) {
        const toAny: any = fetchSeries(searchFilter)
        res = await dispatch(toAny)
      } else if (currentTab === TitleTabSeries.series) {
        dispatch(
          setCurrentExecutingAction({
            tabKey: 'BUSQUEDA',
            buttonType: ButtonTypes.find,
          }),
        )
        playLoader('Buscando...')
        const toAny: any = fetchSeriesCom(searchFilter)
        res = await dispatch(toAny)
        stopLoader()
        dispatch(
          setCurrentExecutingAction({
            tabKey: 'BUSQUEDA',
            buttonType: undefined,
          }),
        )
      }
      consoleService.log(res)
      if (res === null || res === undefined) {
        setToast(`No se existe el tab: ${currentTab}`, ToastTypes.Danger)
        return
      }
      if (res !== null && res !== undefined && res['payload']['error'] === false) {
        if (res['payload']?.auto && res['payload'].auto.length > 5) {
          dispatch(setCollapsed(false))
          dispatch(setCollapsedAvanzed(false))
        }
        setToast(res['payload']['message'], ToastTypes.Success)
      } else {
        setToast(res['payload']['message'], ToastTypes.Danger)
      }
    } catch (error) {
      stopLoader()
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'BUSQUEDA',
          buttonType: undefined,
        }),
      )
      setToast(error.message, ToastTypes.Danger)
    }
  }, [setToast, dispatch, searchFilter, currentTab, playLoader, stopLoader])

  const onMenuButtonClick = React.useCallback(
    async (action: AccionMenu, tab: string = "") => {
      const id = uuidv4()
      switch (action.actionType) {
        case ButtonTypes.find:
          if (tabs.current === 'BUSQUEDA') {
            await onSearchFilter()
          } else {
            dispatch(changeCurrentTab('BUSQUEDA'))
          }
          break
        case ButtonTypes.new:
          if (tabs.current === 'BUSQUEDA') {
            dispatch(
              initDatosEdicion({
                key: id,
                data: { ...defaultDataStore },
              }),
            )
            dispatch(openTab({ key: id }))
          } else {
            dispatch(setCurrentFunction(ButtonTypes.new))
          }
          break
        case ButtonTypes.print:
          if (tabs.current === 'BUSQUEDA')
            dispatch(buttonClick({
              tabKey: tab === "" ? tabs.current : tab,
              button: action.actionType
            }))
          break
        case ButtonTypes.print_design:
          if (tabs.current === 'BUSQUEDA')
            dispatch(buttonClick({
              tabKey: tab === "" ? tabs.current : tab,
              button: action.actionType
            }))
          break
        case ButtonTypes.save:
          if (tabs.current !== 'BUSQUEDA')
            dispatch(setCurrentFunction(ButtonTypes.save))
          break
        case ButtonTypes.edit:
          if (tabs.current === 'BUSQUEDA') setConfirmarEditar(true)
          break
        case ButtonTypes.delete:
          if (tabs.current === 'BUSQUEDA') setConfirmarBorrar(true)
          break
        case ButtonTypes.broom:
          if (tabs.current === 'BUSQUEDA') onCleanResultFilter()
          break
        default:
          break
      }
    },
    [dispatch, onCleanResultFilter, tabs, onSearchFilter],
  )

  const onGetNumberModulo = React.useCallback(() => {
    dispatch(setModulo(parseInt(EModulosAcatha.Series)))
  }, [dispatch])

  useEffect(() => {
    onGetNumberModulo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    if (menuState.length > 0 && options.length === 0) {
      getMenuOptions(menuState, 'to', pathVerificador)
    }
  }, [menuState, getMenuOptions, options])

  React.useEffect(() => {
    if (statusSearch === FETCH_STATUS.FAILED) {
      stopLoader()
      dispatch(
        addToast({
          title: 'Buscar Series',
          content: 'Error al realizar la búsqueda',
          type: ToastTypes.Danger,
        }),
      )
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'BUSQUEDA',
          buttonType: undefined,
        }),
      )
    }
    if (statusSearch === FETCH_STATUS.LOADING) {
      playLoader('Buscando...')
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'BUSQUEDA',
          buttonType: ButtonTypes.find,
        }),
      )
    }
    if (statusSearch === FETCH_STATUS.SUCCESS) {
      stopLoader()
      dispatch(
        setButtons({
          tabKey: 'BUSQUEDA',
          buttons: {
            Nuevo: false,
            Guardar: false,
            Editar: false,
            Eliminar: false,
            Buscar: true,
            Limpiar: true,
            Imprimir: (searchResultsCom['auto'] && searchResultsCom['auto'].length > 0) || (searchResults['auto'] && searchResults['auto'].length > 0),
          },
        }),
      )
    }
  }, [dispatch, statusSearch, playLoader, stopLoader, searchResults, searchResultsCom])

  React.useEffect(() => {
    if (confirmarEditar) {
      onConfirmarEditar()
    }
  }, [confirmarEditar, onConfirmarEditar])

  React.useEffect(() => {
    if (confirmarBorrar) {
      onConfirmarBorrar()
    }
  }, [confirmarBorrar, onConfirmarBorrar])

  const onActionsButtonsLoaded = React.useCallback((actions: Array<AccionMenu>) => {
    dispatch(setMenuButtons(actions));
  }, [dispatch]);

  const onSearchExecutingAction = React.useCallback((tabKey: string, type?: ButtonTypes) => {
    dispatch(setCurrentExecutingAction({
      tabKey: tabKey,
      buttonType: type
    }))
  }, [dispatch]);


  return (
    <>
      <div id={'inventarioLotes'}>
        <Dialog ref={dialogRef} />
      </div>
      <SeriesMain
        menu={{ loading: false, accions: options }}
        tabs={tabs}
        onTabChanged={onTabChanged}
        codigoModulo={parseInt(EModulosAcatha.Series)}
        opciones={OpcionesModeulo}
        onActionsButtonsLoaded={onActionsButtonsLoaded}
        onMenuButtonClick={onMenuButtonClick}
        searching={searchStatus === FETCH_STATUS.LOADING}
        ontabClosing={onTabClosing}
        hayResultadosBusqueda={searchResults.length > 0}
        verificarPuntoVenta
        verificarAutorizacion
        onExecutingAction={onSearchExecutingAction}
        tipoAplicacion={Aplicacion.series}
      />
    </>
  )
}

export default React.memo(Series)

export const ModuleButtons: CustomButtons = {
  Nuevo: false,
  Buscar: true,
  Editar: false,
  Eliminar: false,
  Guardar: false,
  Exportar: false,
  Limpiar: true,
  Imprimir: false,
}

export const defaultDataStore: SeriesList = {
  codigo: 0,
  nombre: '',
  estado: null,
}

const OpcionesModeulo = [parseInt(EModulosAcatha.Series)];