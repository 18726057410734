import { DateUtils, formatoFechasApi } from '../../../../helpers/dateUtils'
import { RequestHelper } from '../../../../helpers/requestHelper'
import { utilidades } from '../../../../helpers/utilidades'
import { SesionService } from '../../../../services/sesion.service'
import { CapitalizeMode } from '../../../../store/types'
import { TiposIce } from '../types/types'

export const tiposIceServices = { getTipos }

async function getTipos(message: string): Promise<any> {
  try {
    const jquery: any = { mensaje: message }
    const sesion = SesionService.getCurrentSesion()
    const keysToCache: any = {
      ...jquery,
      dateKey: DateUtils.getCurrentDateAsString(formatoFechasApi),
      empresa: sesion?.empresa?.codigo,
    }
    const cacheKey = 'tipoIce' + JSON.stringify(keysToCache)
    const cached = sessionStorage.getItem(cacheKey)
    if (cached) {
      return { error: false, auto: JSON.parse(cached) }
    } else {
      const data = await RequestHelper.getAll<any>(
        'generales/taxes',
        'cBoxLoadICE',
        '',
        jquery
      );
      const opt: Array<TiposIce> = []
      if (data?.auto && data?.auto.length > 0) {
        for (const x of data.auto) {
          opt.push({
            codigo: x?.codigo ?? 0,
            nombre: utilidades.capText(x?.nombre, CapitalizeMode.upper) ?? '',
            descripcion: x?.descripcion ?? '',
            valor: x?.valor ?? 0,
            vigencia: x?.vigencia ?? '',
            hasta: x?.hasta ?? '',
            estado: x?.estado ?? 0,
            estadoDescripcion: x?.estadoDescripcion ?? '',
            tipo: x?.tipo ?? '',
            tipoValor: x?.tipoValor ?? '',
            adValorEm: x?.adValorEm ?? 0,
            exFabrica: x?.exFabrica ?? 0,
            exAduana: x?.exAduana ?? 0,
            exhibir: x?.exhibir ?? 0,
          })
        }
      }
      data.auto = opt ?? []
      sessionStorage.setItem(cacheKey, JSON.stringify(opt))
      return data
    }
  } catch (error) {
    return error
  }
}
