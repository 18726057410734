import React from 'react'
import { VendedoresService } from './service/vendedor.service'
import RowContainer from '../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../views/componentes/colContainer'
import SelectBox from 'devextreme-react/select-box'
// import { ModalAdminProveedores } from '../modalAdminProveedores/ModalAdminProveedores';
import Button from 'devextreme-react/button'
import { VendedorBusqueda, Vendedor } from './type/types'
import { AdminModalVendedores } from '../../../componentes/modalVendedores/modalVendedores'
interface IBuscarVendedorLookUpProps extends React.PropsWithChildren {
  selected: VendedorBusqueda | null
  // eslint-disable-next-line no-unused-vars
  onChanged: (newValue: VendedorBusqueda | null) => void
  allowAdd?: boolean
  allowClear?: boolean
  allowEdit?: boolean
  disabled?: boolean
  onEnterKey?: any
}

const vendedorSearchExpresion = ['completo', 'usuarioIdentificacion']

export const BuscarVendedorLookUp: React.FC<IBuscarVendedorLookUpProps> = (
  props,
) => {
  const {
    selected,
    onChanged,
    disabled,
    allowAdd,
    allowClear,
    allowEdit,
    onEnterKey,
  } = props
  const [mostrarEdicion, setMostrarEdicion] = React.useState(false)
  const [vendedorEditando, setVendedorEditando] =
    React.useState<VendedorBusqueda | null>(null)
  const selectRef = React.useRef<any>()
  const [vendedorLocal, setVendedorLocal] = React.useState<VendedorBusqueda>()
  const [data, setData] = React.useState<any>()

  const dataSource = React.useMemo(() => {
    try {
      const sellers = VendedoresService.getVendedoresDatasource()

      setData(sellers)

      sellers.load().then((data) => {
        if (data != null) {
          if (selected === null) {
            const fElem = data[0]
            if (selectRef?.current?.instance.repaint()) {
              onChanged(fElem)
            }
          }
        }
      })

      return sellers
    } catch (e) {
      console.error(e)
    }
  }, [onChanged, selected])

  const onModificarChanged = React.useCallback(
    async (identificacion) => {
      setMostrarEdicion(false)
      setVendedorEditando(null)
      if (selected) {
        await dataSource.reload()
        if (selectRef) {
          selectRef.current.instance.repaint()
        }
      } else {
        const nuevo = await VendedoresService.getVendedor(identificacion)
        onChanged(nuevo)
      }
      //onChanged(nuevo)
    },
    [selected, onChanged, dataSource],
  )

  const onModificarCancel = React.useCallback(() => {
    setVendedorEditando(null)
    setMostrarEdicion(false)
  }, [])

  const getVendedorDisplayExpr = React.useCallback((vendedor: Vendedor) => {
    return vendedor
      ? `${vendedor.usuarioIdentificacion} - ${vendedor.completo}`
      : ''
  }, [])

  const onLookUpValueChanged = React.useCallback(
    (e) => {
      if (e.event !== undefined) {
        if (
          typeof e.value == 'string' ||
          e.value?.completo === vendedorLocal?.completo
        )
          return
        setVendedorLocal(e.value)
        onChanged(e.value)
      }
    },
    [onChanged, vendedorLocal],
  )

  const onSelectBoxFocusIn = React.useCallback((e) => {
    e.element.querySelector('input.dx-texteditor-input').select()
  }, [])

  const onEditarClick = React.useCallback(() => {
    setVendedorEditando(selected)
    setMostrarEdicion(true)
  }, [selected])

  const onCrearClick = React.useCallback(() => {
    setVendedorEditando(null)
    setMostrarEdicion(true)
  }, [])

  const onQuitarClick = React.useCallback(() => {
    const sellers = VendedoresService.getVendedoresDatasource()
    sellers.load().then((data) => {
      const fElem = data[0]
      selectRef.current.instance.repaint()
      setVendedorLocal(fElem)
      onChanged(fElem)
    })
  }, [onChanged])

  const onChangeSeller = React.useCallback((opt) => {
    setVendedorLocal(opt ?? undefined)
  }, [])

  React.useEffect(() => {
    console.info('BuscarVendedorLookUp cambio onChanged')
  }, [onChanged])

  React.useEffect(() => {
    if (selected !== null) {
      onChangeSeller(selected ?? undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  React.useEffect(() => {
    if (selectRef?.current) {
      selectRef?.current?.instance.repaint()
    }
  }, [vendedorLocal, dataSource])

  React.useEffect(() => {
    dataSource.load()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (allowAdd || allowEdit || allowClear) {
    return (
      <>
        <AdminModalVendedores
          // codigoProveedor={proveedorEditando ? proveedorEditando.codigo : null}
          onChanged={(data) => {
            setVendedorLocal(data)
            setMostrarEdicion(false)
          }}
          onCancel={onModificarCancel}
          show={mostrarEdicion}
        />
        <RowContainer>
          <CustomCol xs="12" md="9" className="pe-0">
            <SelectBox
              ref={selectRef}
              dataSource={data}
              searchEnabled
              displayExpr={getVendedorDisplayExpr}
              searchExpr={vendedorSearchExpresion}
              searchTimeout={500}
              value={vendedorLocal}
              onValueChanged={onLookUpValueChanged}
              onFocusIn={onSelectBoxFocusIn}
              disabled={disabled}
              onEnterKey={onEnterKey}
              width={'100%'}
            >
              {props.children}
            </SelectBox>
          </CustomCol>
          <CustomCol md="3" xs="6" className="m-0 p-0">
            <div className="d-flex flex-row align-items-start  ">
              {allowEdit && selected && (
                <div style={{ marginLeft: '1px' }}>
                  <Button
                    stylingMode="contained"
                    type="default"
                    icon="edit"
                    onClick={onEditarClick}
                  />
                </div>
              )}
              {allowAdd && !disabled && (
                <div style={{ marginLeft: '1px' }}>
                  <Button
                    stylingMode="contained"
                    type="default"
                    icon="add"
                    onClick={onCrearClick}
                  />
                </div>
              )}
              {!disabled && allowClear && selected && (
                <div style={{ marginLeft: '1px' }}>
                  <Button
                    stylingMode="contained"
                    type="danger"
                    icon="undo"
                    onClick={onQuitarClick}
                  />
                </div>
              )}
            </div>
          </CustomCol>
        </RowContainer>
      </>
    )
  }

  return (
    <SelectBox
      ref={selectRef}
      dataSource={data}
      displayExpr={getVendedorDisplayExpr}
      searchExpr={vendedorSearchExpresion}
      searchTimeout={500}
      value={vendedorLocal}
      onValueChanged={onLookUpValueChanged}
      disabled={disabled}
      onFocusIn={onSelectBoxFocusIn}
      onEnterKey={onEnterKey}
      width={'100%'}
    >
      {props.children}
    </SelectBox>
  )
}
