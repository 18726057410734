import React from 'react'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput'
import { Button, CheckBox, DateBox, FileUploader, NumberBox, TextArea, TextBox, Validator } from 'devextreme-react'
import { consoleService } from '../../../../../../../services/console.service'
import RelationsLookUp from '../../../../../../componentes/relacionesLookUp'
import ModalCuentasBancos from '../../../../../../bancos/pages/ccahorros/componentes/modalCuentasBancos'
import { ModalDetalleCambios } from '../../../../../../componentes/modalDetalleCambios'
import { DocumentInfo, ToastTypes } from '../../../../../../../store/types'
import { ContratoDatosEdicion } from '../../../types/types'
import { TabState } from '../../../../../../../store/genericTabTypes'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import TipoDiscapacidadLookUp from '../../../../../../componentes/tipoDiscapacidadLookUp'
import { setChangeLoader, setDatosEdicionObservaciones } from '../../../store/editDataReducer'
import { RequiredRule } from 'devextreme-react/data-grid'
import validations from '../../../../../../../config/validations'
import { sidebarService } from '../../../../../../../services/sidebar.service'
import { addToast } from '../../../../../../../store/toasterReducer'
import { ImportExcellService } from '../../../../../../clientes/pages/docsCtasXCobrar/components/impportarExcel/service/importExcell.service'

interface IObservationsProps extends React.PropsWithChildren {
  info: DocumentInfo<ContratoDatosEdicion>
  tab: TabState<ContratoDatosEdicion>
  tabId: string
}


const Observaciones: React.FunctionComponent<IObservationsProps> = (props) => {

  const { tabId } = props
  const dispatch = useDispatch()



  const [showBancos, setShowBancos] = React.useState<boolean>(false)
  const [showDetalleCambio, setShowDetalleCambio] = React.useState<any>(false)
  const observationsFilter = useSelector((state: RootState) => state.nomina.contratos.editData[tabId].observaciones)
  const privations = useSelector((state: RootState) => state.nomina.contratos.editData[tabId].observaciones.privaciones)
  const options = useSelector((state: RootState) => state.nomina.contratos.editData[tabId].observaciones.opcionEmpres)
  const empresa = useSelector((state: RootState) => state.global.empresasUsuario)
  const empresaSesion = useSelector((state: RootState) => state.global.session.empresa)

  const sms = validations['es']
  const FILE_SIZE = 1000000;
  const allowExtention = ['.pdf'];
  const onLoader = React.useCallback((showLoader: boolean, text: string) => {
    dispatch(setChangeLoader({
      key: tabId,
      data: {
        show: showLoader,
        mensaje: text
      }
    }))
  }, [dispatch, tabId])

  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(
      addToast({
        title: 'Nómina - Contratos',
        content: mensaje,
        type: tipo,
      }),
    )
  }, [dispatch])

  const onValueChange = React.useCallback((data, key: string) => {
    dispatch(setDatosEdicionObservaciones({
      key: tabId,
      data: {
        ...observationsFilter,
        [key]: data
      }
    }))
  }, [observationsFilter, dispatch, tabId])

  const privacionesCheckBox = [
    {
      title: 'Nocturno',
      value: 'nocturno'
    },
    {
      title: 'Suplementarios',
      value: 'suplementarios'
    },
    {
      title: 'Extras',
      value: 'extras'
    },
    {
      title: 'Decimo Cuarto',
      value: 'decimoCuarto'
    },
    {
      title: 'Decimo Tercero',
      value: 'decimoTercero'
    },
  ]
  const opcionesEmpresaCheckBox = [
    {
      title: 'Pago Impuesto Renta',
      value: 'pagaimpuestorenta'
    },
    {
      title: 'Paga Seguro Social',
      value: 'pagaseguridadsocial'
    },
    {
      title: 'Pago Fondos desde un Inicio',
      value: 'pagofondosinicio'
    },
    {
      title: 'Sistema de Saldo Neto',
      value: 'sistemasalarioneto'
    },
    {
      title: 'Acumula Decimo Tercero',
      value: 'acumuladecimotercero'
    },
    {
      title: 'Acumula Fondo Reserva',
      value: 'acumulafondoreserva'
    },
    {
      title: 'Acumula Decimo Cuarto',
      value: 'acumuladecimocuarto'
    },
  ]

  const getExtension = (path: string) => {
    const basename: any = path.split(/[\\/]/).pop(),  // extract file name from full path ...
      // (supports `\\` and `/` separators)
      pos = basename.lastIndexOf(".");       // get last position of `.`

    if (basename === "" || pos < 1)            // if file name is empty or ...
      return "";                             //  `.` not found (-1) or comes first (0)

    return basename.slice(pos + 1);            // extract extension ignoring `.`
  }

  const validarFormatoPermitido = React.useCallback((file) => {
    let extention: string = "";
    if (file && file.length > 0) {
      extention = getExtension(file[0].name);
    }
    if (allowExtention.includes("." + extention)) {
      return true;
    } else {
      return false;
    }
  }, [allowExtention])

  const downloadData = React.useCallback(async () => {
    try {
      const response = await ImportExcellService.exportarDemo(observationsFilter?.archivo, "archivo");
      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement("a")
      a.href = url
      a.download = observationsFilter?.archivo
      a.click();
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
  }, [setToast, observationsFilter])

  const uploadFile = React.useCallback(async (file: any, type: string, fileName: string, typeValue: string) => {
    consoleService.log({ file, type, fileName })
    const fileUpload = {
      tipo: type,
      archivo: file,
      ruc: empresaSesion?.ruc,
      nombre: fileName,
      password: ""
    }
    onLoader(true, 'Subiendo archivo...')
    try {
      const data = await sidebarService.docEmpresaUplod(fileUpload);
      if (data['error'] === true) {
        setToast(JSON.stringify("Error al subir archivo " + data['message']), ToastTypes.Danger);
      } else if (data['valido'] && data['error'] === false) {
        setToast(data['message'], ToastTypes.Success);
      }
      consoleService.log(data, 'archivo res')
      onValueChange(fileName, typeValue)
    } catch (error) {
      consoleService.error(error);
      setToast(JSON.stringify(error), ToastTypes.Danger)
    }
    onLoader(false, '')
  }, [empresaSesion, setToast, onLoader, onValueChange]);

  const modalCuentaBancos = () => {
    return (
      <ModalCuentasBancos
        show={showBancos}
        onClose={() => setShowBancos(!showBancos)}
        cargarCuentas={(data) => {
          setShowBancos(!showBancos)
          consoleService.log(data, 'cuenta bancos')
          dispatch(setDatosEdicionObservaciones({
            key: tabId,
            data: {
              ...observationsFilter,
              codigoCtaBancaria: data?.cba_codigo ?? 0,
              ctaBancaria: `${data?.cba_numero ?? ''} - ${data?.ban_nombre ?? ''}`,
            }
          }))
        }}
        origen={"modal"}
        codModulo={0}
      />
    )
  }


  return (
    <>
      {showBancos && modalCuentaBancos()}
      {
        showDetalleCambio && <ModalDetalleCambios
          onCancel={() => setShowDetalleCambio(false)}
          show={showDetalleCambio}
          data={observationsFilter?.detalleLista ?? []}
        />
      }
      <RowContainer>
        <RowContainer>
          <CustomCol xs="12" md="3">
            <Labeled label='Fecha:'>
              <DateBox
                readOnly
                value={observationsFilter?.fecha ?? null}
                onValueChange={(data) => onValueChange(data, 'fecha')}
              >
                <Validator>
                  <RequiredRule message={'Fecha: ' + sms['required']} />
                </Validator>
              </DateBox>
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="3">
            <Labeled label='Dsicapacidad:'>
              <div
                style={{
                  display: 'flex',
                  gap: '2px',
                  alignItems: 'center',
                  width: '100%',
                }}
              >

                <TipoDiscapacidadLookUp
                  onChanged={(data) => {
                    onValueChange(data, 'discapacidad')
                  }}
                  selected={observationsFilter?.discapacidad ?? null}
                />
                <NumberBox
                  value={observationsFilter?.discapacidadPorcentaje ?? 0}
                  onValueChange={(data) => onValueChange(data, 'discapacidadPorcentaje')}
                />{'%'}
              </div>
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="5">
            <Button
              id="btnAdd"
              className="me-1"
              icon="plus"
              stylingMode="contained"
              type="default"
              onClick={() => setShowDetalleCambio(true)}
            >{'Ver Historial de Cambios'}</Button>
          </CustomCol>
        </RowContainer>
        <RowContainer>
          <CustomCol xs="12" md="7">
            <Labeled label="Agrupar por:">
              {
                privacionesCheckBox.map((grupo, i) => (
                  <CheckBox
                    key={i}
                    className='m-1'
                    text={grupo.title}
                    value={observationsFilter?.privaciones[grupo?.value] ?? null}
                    onValueChange={(data) => {
                      dispatch(setDatosEdicionObservaciones({
                        key: tabId,
                        data: {
                          ...observationsFilter,
                          privaciones: {
                            ...privations,
                            [grupo?.value]: data
                          }
                        }
                      }))
                    }}
                  />

                ))
              }
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="5">
            <Labeled label="Opciones de Empresa:">
              {
                opcionesEmpresaCheckBox.map((grupo, i) => (
                  <CheckBox
                    key={i}
                    className='m-1'
                    text={grupo.title}
                    value={observationsFilter?.opcionEmpres[grupo?.value] ?? null}
                    onValueChange={(data) => {
                      dispatch(setDatosEdicionObservaciones({
                        key: tabId,
                        data: {
                          ...observationsFilter,
                          opcionEmpres: {
                            ...options,
                            [grupo?.value]: data
                          }
                        }
                      }))
                    }}
                  />

                ))
              }
            </Labeled>
          </CustomCol>
        </RowContainer>
        <RowContainer>
          <CustomCol xs="12" md="2">
            <Labeled label='Cta. Bancaria:'>
              <div
                style={{
                  display: 'flex',
                  gap: '2px',
                  alignItems: 'center',
                  width: '100%',
                }}
              >

                <Button
                  id="btnAdd"
                  className="me-1"
                  icon="plus"
                  stylingMode="contained"
                  type="default"
                  onClick={() => setShowBancos(true)}
                />
                <TextBox value={observationsFilter?.ctaBancaria ?? ''} readOnly />
              </div>
            </Labeled>
          </CustomCol>
        </RowContainer>
        <br />
        <hr />
        <h5>Observaciones:</h5>
        <RowContainer>
          <CustomCol xs="12" md="6">
            <Labeled label='Fecha Ingreso:'>
              <TextArea
                value={observationsFilter?.observacionesIngreso ?? ''}
                onValueChange={(data) => onValueChange(data, 'observacionesIngreso')}
              />
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="6">
            <Labeled label='Fecha Salida:'>
              <TextArea
                value={observationsFilter?.observacionesSalida ?? ''}
                onValueChange={(data) => onValueChange(data, 'observacionesSalida')}
              />
            </Labeled>
          </CustomCol>
        </RowContainer>
        <RowContainer>
          <CustomCol xs="12" md="4">
            <Labeled label="Documento de Respaldo:">
              <TextBox readOnly value={observationsFilter?.archivo ?? ''} />
              <FileUploader
                // ref={imageLogo}
                selectButtonText="Examinar"
                labelText=""
                accept={'.pdf'}
                uploadMode="useForm"
                onValueChanged={(data) => {
                  consoleService.log(data, 'archivo')
                  if (data.value !== null && data.value !== undefined && data?.value.length > 0) {
                    const allow = validarFormatoPermitido(data.value);
                    if (allow === true) {
                      uploadFile(data?.value[0], 'archivo', data?.value[0]?.name, 'archivo')
                    } else {
                      onValueChange('', 'archivo')
                    }
                  }
                }}
                allowedFileExtensions={['.pdf']}
                maxFileSize={FILE_SIZE}
              />
              <Button
                id="btnAdd"
                className="me-1"
                icon="plus"
                disabled={observationsFilter?.archivo === ''}
                stylingMode="contained"
                type="default"
                onClick={() => downloadData()}
              >{'Descargar'}</Button>
            </Labeled>
          </CustomCol>
          {
            empresa[0]?.tipoAgente === 1 && (
              <CustomCol xs="12" md="6">
                <Labeled label='Relación de Trabajo:'>
                  <RelationsLookUp
                    onChanged={(data) => {
                      onValueChange(data, 'relacion')
                    }}
                    selected={observationsFilter?.relacion ?? null}
                  />
                </Labeled>
              </CustomCol>
            )
          }
        </RowContainer>
      </RowContainer>
    </>
  )
}

export default React.memo(Observaciones)