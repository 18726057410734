import { DateUtils, formatoFechasApi } from "../../../../../helpers/dateUtils";
import { RequestHelper } from "../../../../../helpers/requestHelper";
import { OpcionCliente } from "../store/types";

export const clientesOrigen = { getOptions };

async function getOptions(): Promise<any> {
  const data: any = {
  };
  data.dateKey = DateUtils.getCurrentDateAsString(formatoFechasApi);
  const cacheKey = 'tipoOrigen' + JSON.stringify(data);
  const cached = sessionStorage.getItem(cacheKey);
  if (cached) {
    return { error: false, auto: JSON.parse(cached) };
  } else {
    const clientes: any = await RequestHelper.getAll('clientes', 'getOptions', "", "");

    const opt: Array<OpcionCliente> = [];

    if (clientes?.auto && clientes?.auto.length > 0) {
      for (const x of clientes.auto) {
        opt.push({
          opcion: String(x.OPCION).toString() ?? '',
        })
      }
    }
    sessionStorage.setItem(cacheKey, JSON.stringify(opt));

    return { error: false, auto: opt };

  }
}

