import React, { useCallback, useEffect } from 'react'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput'
import { Button, DataGrid, NumberBox, TextBox } from 'devextreme-react'
import ModalCuentasBancos from '../../../../../../bancos/pages/ccahorros/componentes/modalCuentasBancos'
import { consoleService } from '../../../../../../../services/console.service'
import MesesLookUp, { MesesData } from '../../../../../../componentes/mesesLookUp'
import { Column, ColumnChooser, Export, Pager, Paging } from 'devextreme-react/data-grid'
import ItemsCountIndicator from '../../../../../../componentes/indicadorNroRegistros'
import { GetTransfers, RolesPadoDatosEdicion } from '../../types/types'
import { TabState } from '../../../../../../../store/genericTabTypes'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import { changeFilter, changeFilterBackup, setChangeLoader, setCollapsed, setResetResults, setResults } from '../../store/transferReducer'
import { clearButtonClick, setCurrentExecutingAction } from '../../store/tabsReducer'
import { ButtonTypes } from '../../../../../../../views/componentes/globalMenu/types'
import { ToastTypes } from '../../../../../../../store/types'
import { addToast } from '../../../../../../../store/toasterReducer'
import { RolsPaymentsServices } from '../../services/rolsPays.services'
import BlockUi from '../../../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../../../views/componentes/loadingindicator/loadingindicator'
import { Workbook } from 'exceljs'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { saveAs } from 'file-saver';
import { getAllowedPageSizes } from '../../../../../../../helpers/Helper'
import { CCard, CCardBody, CCardHeader, CCollapse, CLink, CTooltip } from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilChevronBottom, cilChevronTop } from '@coreui/icons'
import { ImportExcellService } from '../../../../../../clientes/pages/docsCtasXCobrar/components/impportarExcel/service/importExcell.service'
import { utilidades } from '../../../../../../../helpers/utilidades'


interface ITransferProps extends React.PropsWithChildren {
  tab: TabState<RolesPadoDatosEdicion>
  tabId: string
}


const Transferencia: React.FunctionComponent<ITransferProps> = (props) => {
  const { tab, tabId } = props

  const dispatch = useDispatch()
  const transferState = useSelector((state: RootState) => state.nomina.rol.rolesPago.transfer.filter)
  const transferStateBackup = useSelector((state: RootState) => state.nomina.rol.rolesPago.transfer.filterBackup)
  const resultados = useSelector((state: RootState) => state.nomina.rol.rolesPago.transfer.resultados)
  const collapsed = useSelector((state: RootState) => state.nomina.rol.rolesPago.transfer.colapsado)
  const loader = useSelector((state: RootState) => state.nomina.rol.rolesPago.transfer.loader)
  const tabs = useSelector((state: RootState) => state.nomina.rol.rolesPago.tabs)
  const [showBancos, setShowBancos] = React.useState<boolean>(false)

  const onSetButtonAction = useCallback(
    (tipo: ButtonTypes | undefined) => {
      dispatch(
        setCurrentExecutingAction({
          tabKey: tabId,
          buttonType: tipo,
        }),
      )
    },
    [dispatch, tabId],
  )

  const onLoader = useCallback((showLoader: boolean, mensaje: string) => {
    dispatch(setChangeLoader({
      show: showLoader,
      mensaje: mensaje
    }))
  }, [dispatch])

  const setToast = useCallback(
    (mensaje: string, tipo: ToastTypes) => {
      dispatch(
        addToast({
          title: 'Nómina - Roles de Pago',
          content: mensaje,
          type: tipo,
        }),
      )
    },
    [dispatch],
  )


  const onInitial = useCallback(() => {
    const temp = { ...transferState }
    if (temp?.initial) {
      temp.cuentaCodigo = 0
      temp.cuentaNombre = ''
      temp.mes = MesesData[0]
      temp.anio = new Date().getFullYear()
      temp.initial = false
      dispatch(changeFilter({
        ...temp
      }))
      dispatch(changeFilterBackup({
        ...temp
      }))
    }
  }, [dispatch, transferState])

  const onValueChange = useCallback((data, key: string) => {
    dispatch(changeFilter({
      ...transferState,
      [key]: data
    }))
  }, [dispatch, transferState])

  const onFind = useCallback(async () => {
    onSetButtonAction(ButtonTypes.find)
    onLoader(true, 'Buscando...')
    try {
      const data_trasnfer: GetTransfers = {
        cuentaBanco: transferState?.cuentaCodigo ?? 0,
        nombreMes: transferState?.mes?.label ?? '',
        mes: transferState?.mes?.value ?? '',
        anio: transferState?.anio ?? 0,
      }
      const data = await RolsPaymentsServices.getTransfers(data_trasnfer)
      consoleService.log(data, 'data trasnfer')
      if (data?.auto && data?.error === false) {
        setToast(data?.message, ToastTypes.Success)
        dispatch(setResults(data?.auto))
        if (data?.auto.length > 5) {
          dispatch(setCollapsed(false))
        }
      } else {
        setToast(data?.message, ToastTypes.Danger)
      }
    } catch (error) {
      setToast(error?.message, ToastTypes.Danger)
    }
    onLoader(false, '')
    onSetButtonAction(undefined)
  }, [transferState, setToast, onLoader, onSetButtonAction, dispatch])

  const onUndo = useCallback(() => {
    dispatch(changeFilter({
      ...transferStateBackup
    }))
  }, [dispatch, transferStateBackup])

  const onBroom = useCallback(() => {
    dispatch(setResetResults())
  }, [dispatch])

  const downloadText = React.useCallback(async () => {
    onSetButtonAction(ButtonTypes.attach)
    onLoader(true, 'Generando texto plano...')
    try {
      const response = await ImportExcellService.exportarDemo(`PAGOMULTICASH${transferState?.mes.value}${transferState?.anio}.txt`, "plano");
      if (response?.ok === true) {
        setToast(response?.statusText, ToastTypes.Success)
      } else {
        setToast(response?.statusText, ToastTypes.Danger)
      }
      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement("a")
      a.href = url
      a.download = `PAGOMULTICASH${transferState?.mes.value}${transferState?.anio}`
      a.click();
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
    onLoader(false, '')
    onSetButtonAction(undefined)
  }, [setToast, transferState, onLoader, onSetButtonAction])

  const handleButtonClick = useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.find:
          if (tabs.current === tabId) onFind()
          break
        case ButtonTypes.undo:
          if (tabs.current === tabId) onUndo()
          break
        case ButtonTypes.broom:
          if (tabs.current === tabId) onBroom()
          break
        case ButtonTypes.attach:
          if (tabs.current === tabId) downloadText()
          break
        default:
          break
      }
      dispatch(clearButtonClick(tabId))
    }, [dispatch, tabId, tabs, onFind, onUndo, onBroom, downloadText])


  useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab.globalButtonClick])

  useEffect(() => {
    onInitial()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onExportExcell = useCallback((e) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('Main sheet')
    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'Transferencia_RolPagos.xlsx',
        )
      })
    })
  }, [])


  return (
    <>
      {showBancos && <ModalCuentasBancos
        show={showBancos}
        onClose={() => setShowBancos(!showBancos)}
        cargarCuentas={(data) => {
          setShowBancos(!showBancos)
          consoleService.log(data, 'cuenta bancos')
          dispatch(changeFilter({
            ...transferState,
            cuentaNombre: `${data?.cba_numero ?? 0}-${data?.pla_descripcion ?? ''}`,
            cuentaCodigo: data?.cba_codigo ?? 0
          }))
        }}
        origen={"modal"}
        codModulo={0}
      />}
      <div id="PersonalContent">
        <BlockUi
          tag="div"
          loader={LoadingIndicator}
          blocking={loader.show}
          message={loader.mensaje}
        >
          <RowContainer>
            <CCard>
              <CCardHeader
                onClick={() => dispatch(setCollapsed(!collapsed))}
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <strong> {collapsed ? '' : 'Filtro de Búsqueda'} </strong>
                <CLink className="card-header-action">
                  <CIcon icon={collapsed ? cilChevronBottom : cilChevronTop} />
                </CLink>
              </CCardHeader>
              <CCollapse visible={collapsed}>
                <CCardBody>
                  <RowContainer>
                    <CustomCol xs="12" md="3">
                      <Labeled label='Cuenta:'>
                        <div
                          style={{
                            display: 'flex',
                            gap: '2px',
                            alignItems: 'center',
                            width: '100%',
                          }}
                        >

                          <TextBox value={transferState?.cuentaNombre ?? ''} onEnterKey={onFind} readOnly />
                          <Button
                            id="btnAdd"
                            className="me-1"
                            icon="plus"
                            stylingMode="contained"
                            type="default"
                            onClick={() => setShowBancos(true)}
                          />
                          {transferState?.cuentaCodigo > 0 && (
                            <>
                              <CTooltip content="Quitar cuenta seleccionado">
                                <div>
                                  <Button
                                    className="me-1"
                                    id="btnRemove"
                                    icon="undo"
                                    stylingMode="contained"
                                    type="danger"
                                    onClick={() => {
                                      dispatch(changeFilter({
                                        ...transferState,
                                        cuentaNombre: '',
                                        cuentaCodigo: 0
                                      }))
                                    }}
                                  />
                                </div>
                              </CTooltip>
                            </>
                          )}
                        </div>
                      </Labeled>
                    </CustomCol>
                    <CustomCol xs="12" md="3">
                      <Labeled label='Mes:'>
                        <MesesLookUp
                          onChanged={(data) => {
                            consoleService.log('mes ', data)
                            onValueChange(data, 'mes')
                          }}
                          selected={transferState?.mes ?? null}
                        />
                      </Labeled>
                    </CustomCol>
                    <CustomCol xs="12" md="3">
                      <Labeled label='Año:'>
                        <TextBox
                          width={115}
                          value={String(transferState?.anio) ?? null}
                          onValueChange={(data) => onValueChange(parseInt(data), 'anio')}
                          onEnterKey={onFind}
                          maxLength={4}
                          onKeyDown={utilidades.filtraTeclasNoNumericas}
                        />
                      </Labeled>
                    </CustomCol>
                  </RowContainer>
                </CCardBody>
              </CCollapse>
              <ItemsCountIndicator numberOfItems={resultados.length} />
              <RowContainer>
                <CustomCol xs="12" md="12">
                  <DataGrid
                    id="tablaTranferencias"
                    dataSource={resultados}
                    selection={{ mode: 'single' }}
                    hoverStateEnabled={true}
                    showBorders={true}
                    showRowLines={true}
                    // onSelectionChanged={onSelectedChanged}
                    repaintChangesOnly={true}
                    highlightChanges={true}
                    keyExpr="secuencial"
                    key="secuencial"
                    focusedRowKey="secuencial"
                    showColumnLines={true}
                    onExporting={onExportExcell}
                    className={'m-2 p-2'}
                    remoteOperations={true}
                    loadPanel={{
                      enabled: true,
                      height: 90,
                      indicatorSrc: '',
                      shading: false,
                      shadingColor: '',
                      showIndicator: true,
                      showPane: true,
                      text: 'Cargando...',
                      width: 200,
                    }}
                  >
                    <Export enabled={true} allowExportSelectedData={true} />
                    <ColumnChooser enabled={true} mode="select" />
                    <Paging defaultPageSize={20} />
                    <Pager
                      visible={resultados.length > 0 ? true : false}
                      allowedPageSizes={getAllowedPageSizes(resultados)}
                      displayMode={'full'}
                      showPageSizeSelector={true}
                      showInfo={true}
                      showNavigationButtons={true}
                    />

                    <Column dataField='pa' caption="COD. ORIENTACIONE" width="100px" />
                    <Column dataField='cuentaEmpresa' caption="CTA. EMPRESA" width="100px" />
                    <Column dataField='secuencial' caption="SECUENCIA PAGO" width="100px" />
                    <Column dataField='cuentaPago' caption="COM. PAGO" width="100px" />
                    <Column dataField='cuentaProveedor' caption="CTA. EMPLEADO" width="100px" />
                    <Column dataField='moneda' caption="MONEDA" width="100px" />
                    <Column dataField='valor' caption="VALOR" width="100px" />
                    <Column dataField='formaPago' caption="FORMA PAGO" width="100px" />
                    <Column dataField='cif' caption="CIF" width="100px" />
                    <Column dataField='tipoCuenta' caption="CTA" width="100px" />
                    <Column dataField='numeroCuenta' caption="NUM. CUENTA" width="100px" />
                    <Column dataField='tipoID' caption="TIPO ID" width="100px" />
                    <Column dataField='usuarioCedula' caption="ID" width="100px" />
                    <Column dataField='nombres' caption="NOMBRES Y APELLIDOS" width="100px" />
                    <Column dataField='usuarioDireccion' caption="DIRECCION" width="100px" />
                    <Column dataField='ciudad' caption="CIUDAD" width="100px" />
                    <Column dataField='flabor' caption="REFERENCIA" width="100px" />
                  </DataGrid>
                </CustomCol>
              </RowContainer>
            </CCard>
          </RowContainer>
        </BlockUi>
      </div>
    </>
  )
}

export default React.memo(Transferencia)