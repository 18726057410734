import React, { useCallback, useEffect, useRef } from 'react'
import { DocumentInfo, ToastTypes } from '../../../../../../../store/types';
import { TabState } from '../../../../../../../store/genericTabTypes'
import { GenerateEntryNomina, PaymentsRolListDto, RolesPadoDatosEdicion, SaveRolsPay, UpdatePay } from '../../types/types'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput'
import MesesLookUp, { MesesData } from '../../../../../../componentes/mesesLookUp'
import { consoleService } from '../../../../../../../services/console.service'
import { Button, DataGrid, DateBox, NumberBox, RadioGroup, SelectBox, TextArea, TextBox, ValidationGroup, ValidationSummary, Validator } from 'devextreme-react'
import { CButton } from '@coreui/react-pro'
import LocalesLookUp from '../../../../../../componentes/localesLookUp'
import { EAplicationsAcatha } from '../../../../../../../store/enumsAplication'
import ModalUsuarios from '../../../../../../componentes/modalUsuarios'
import { utilidades } from '../../../../../../../helpers/utilidades'
import { ETipoTransaccion, TFormaPagoOption } from '../../../../../../componentes/formasPagoLookUp/store/type'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import { setChangeLoader, setDatosEdicion } from '../../store/editDataReducer'
import { StatesEdition } from '../../../../../../../store/enums'
import { ButtonTypes } from '../../../../../../../views/componentes/globalMenu/types'
import { clearButtonClick, setButtons, setCurrentExecutingAction, setNameTab } from '../../store/tabsReducer'
import { addToast } from '../../../../../../../store/toasterReducer'
import { setDatosEdicionBackup } from '../../store/configReducer'
import { adminService } from '../../../../../../admin/cuenta/usuario/services/adminService'
import { formasPagoService } from '../../../../../../componentes/formasPagoLookUp/service/formasPago.service'
import BlockUi from '../../../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../../../views/componentes/loadingindicator/loadingindicator'
import { RolsPaymentsServices } from '../../services/rolsPays.services'
import { AsyncRule, Column, Editing, Pager, Paging, RequiredRule } from 'devextreme-react/data-grid';
import { getAllowedPageSizes } from '../../../../../../../helpers/Helper';
import { MarcationsService } from '../../../../registro/marcacion/services/marcations.service';
import { MarcationList } from '../../../../registro/marcacion/types/types';
import VisualizaError from '../../../../../../ventas/pages/shared/visualizaError/visualizaError';
import ListErrosValidationForm from '../../../../../../componentes/listadoErroresValidacionForm';
import validations from '../../../../../../../config/validations';
import { getIndexProvider } from '../../../../../../shared/helpers/funciones';
import { ModuleButtons } from '../../index';
import ModalVerMarcaciones from '../../../../../../componentes/modalVerMarcaciones';
import ModalAsiento from '../../../../../../contabilidad/pages/mayorGeneral/componentes/modalAsiento';
import Dialog from '../../../../../../../views/componentes/librerias/bootstrap-dialog';
import { DateUtils } from '../../../../../../../helpers/dateUtils';
import { DocCuentasCobrar } from '../../../../../../clientes/pages/docsCtasXCobrar/services/docCuentasCobrar.service';
import { AccountingEntry } from '../../../../../../clientes/pages/docsCtasXCobrar/types/types';
import { localesService } from '../../../../../../componentes/localesLookUp/service/locales.service';
import { isMobile } from 'react-device-detect';
import ModalIngresoCheques from '../../../../../../componentes/modalIngresoCheques';
import { ModalFormasPago } from '../../../../../../componentes/modalFormasPago';
import { ReportWithLocalDataModal } from '../../../../../../../views/componentes/xtrareports/ReportWithLocalDataModal';
import { DocumentsToPayService } from '../../../../../../proveedores/pages/docsCtasXPagar/services/docsCtasXPagar.services';
import { EgresoTipo } from '../../../../../../proveedores/store/types';
import { Abonar, Asientos, Cheques, Compra, Egreso, EgresosCajaDto } from '../../../../../../proveedores/pages/docsCtasXPagar/types/types';
import { EFormasPago } from '../../../../../../../store/enum/formasPago';


export const grupoVacaciones = [
  { value: 0, label: 'No' },
  { value: 1, label: 'Si' },
]

export const grupoJornada = [
  { value: 0, label: 'Diurna' },
  { value: 1, label: 'Nocturna' },
]

interface INewProps extends React.PropsWithChildren {
  info: DocumentInfo<RolesPadoDatosEdicion>
  tab: TabState<RolesPadoDatosEdicion>
  tabId: string
}


const Nuevo: React.FunctionComponent<INewProps> = (props) => {
  const { info, tab, tabId } = props

  const dispatch = useDispatch()
  const validationRoles = useRef<any>()
  const validationRolesPay = useRef<any>()
  const dialogRef = React.useRef<any>(null)

  const rolsState = useSelector((state: RootState) => state.nomina.rol.rolesPago.editData[tabId])
  const rolsStateBackup = useSelector((state: RootState) => state.nomina.rol.rolesPago.condfig[tabId])
  const tabs = useSelector((state: RootState) => state.nomina.rol.rolesPago.tabs)
  const empresa = useSelector((state: RootState) => state.global.empresasUsuario)
  const loader = useSelector((state: RootState) => state.nomina.rol.rolesPago.editData[tabId].loader)
  const loading = useSelector((state: RootState) => state.nomina.rol.rolesPago.editData[tabId].loading)
  const seleccionado = useSelector((state: RootState) => state.nomina.rol.rolesPago.search.seleccionado)
  const modulo = useSelector((state: RootState) => state.nomina.rol.rolesPago.condfig.aplicacion)
  const activePeriod = useSelector((state: RootState) => state.shared.providers.periodoContable)
  const sms = validations['es']
  const mesMessage: string = 'Mes: elija un mes.'
  const payFormdMessage: string = 'Forma de Pago: Elija una forma de pago'

  const [showUsers, setShowUsers] = React.useState<boolean>(false)
  const [showMarkations, setShowMarkations] = React.useState<boolean>(false)
  const [showModalsiento, setShowModalsiento] = React.useState<boolean>(false)
  const [showModalsientoLiq, setShowModalsientoLiq] = React.useState<boolean>(false)
  const [changes, setChanges] = React.useState<any>();
  const [editRowKey, setEditRowKey] = React.useState<any>();
  const [arrayErrorsMarks, setArrayErrorsMarks] = React.useState<Array<string>>([])
  const [showErrorPopupMarks, setShowErrorPopupMarks] = React.useState<boolean>(false)
  const [arrayErrors, setArrayErrors] = React.useState<Array<string>>([])
  const [showErrorPopup, setShowErrorPopup] = React.useState<boolean>(false)
  const [arrayErrorsPay, setArrayErrorsPay] = React.useState<Array<string>>([])
  const [showErrorPopupPay, setShowErrorPopupPay] = React.useState<boolean>(false)
  const [showBankChecks, setShowBankChecks] = React.useState<boolean>(false)
  const [showFormasPago, setShowFormasPago] = React.useState<any>(false)
  const [reporte, setReporte] = React.useState<null | "Viewer" | "Designer">(null);
  const [resultadoImprimir, setResultadoImprimir] = React.useState<any>({})
  const [templateName, setTemplateName] = React.useState<string>('StaticPaymentsRols')

  const onSetButtonAction = useCallback(
    (tipo: ButtonTypes | undefined) => {
      dispatch(
        setCurrentExecutingAction({
          tabKey: tabId,
          buttonType: tipo,
        }),
      )
    },
    [dispatch, tabId],
  )

  const onLoader = React.useCallback((showLoader: boolean, text: string) => {
    dispatch(setChangeLoader({
      key: tabId,
      data: {
        show: showLoader,
        mensaje: text
      }
    }))
  }, [dispatch, tabId])

  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(
      addToast({
        title: 'Nómina - Roles de Pago',
        content: mensaje,
        type: tipo,
      }),
    )
  }, [dispatch])

  const onConfirmEntry = React.useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Que asiento desea visualizar?`,
      actions: [
        Dialog.Action(
          <span>
            <u>R</u>ol de pagos
          </span>,
          (dialog) => {
            dialog.hide()
            if (rolsState?.codigo !== 0) {
              setShowModalsiento(true)
            } else {
              setToast('No existe asiento, es un registro nuevo!!!', ToastTypes.Warning)
            }
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>L</u>iquidación Rol de Pagos
          </span>,
          (dialog) => {
            dialog.hide()
            if (rolsState?.egresosCodigo !== 0) {
              setShowModalsientoLiq(true)
            } else {
              setToast('No existe asiento, no existe el pago!!!', ToastTypes.Warning)
            }
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    return
  }, [rolsState, setToast])



  const modoNuevo = React.useCallback(async (template: number, limpiar: boolean = false) => {
    if (loading === false && !limpiar) {
      return
    }
    onSetButtonAction(ButtonTypes.new)
    onLoader(true, 'Cargando...')
    try {
      const data = { ...defaultRolesPagoEdicion }
      const dataLocal = await adminService.obtenerLocalesUsuario("Elija un local", true)
      const dataFP = await formasPagoService.getFormasPago(ETipoTransaccion.ventas, "Elija una opción");
      const rolParams = await RolsPaymentsServices.loadRolParameters(0)
      data.tipo = empresa[0]?.numeroRoles ?? 0
      consoleService.log(rolParams, 'rolParams')
      if (rolParams?.auto && rolParams?.error === false) {
        data.rolparametros = rolParams?.auto
        data.sbu = data.rolparametros.sbu
      }
      if (dataFP?.auto && dataFP?.error === false) {
        const newFormPago = []
        dataFP?.auto.forEach(x => {
          if (x?.codigo === 7 || x?.codigo === -1 || x?.codigo === 6 || x?.codigo === 114 || x?.codigo === EFormasPago.otros) {
            newFormPago.push(x)
          }
        })
        data.listaFormasPagos = newFormPago
        data.formaPago = newFormPago[0]
      }
      if (dataLocal?.auto && dataLocal?.error === false) {
        data.lugar = dataLocal?.auto[0]
      }
      data.anio = new Date().getFullYear()
      data.dias = 30
      data.d_horas = '00'
      data.d_min = '00'
      data.horas = '00'
      data.min = '00'
      data.mes = MesesData[0]
      data.vacacionesRg = grupoVacaciones[0]
      data.jornada = grupoJornada[0]
      data.loading = false
      dispatch(setDatosEdicion({
        key: tabId,
        data: { ...data }
      }))
      dispatch(setDatosEdicionBackup({
        key: tabId,
        data: { ...data }
      }))

    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
    onLoader(false, '')
    onSetButtonAction(undefined)
  }, [loading, onLoader, onSetButtonAction, setToast, dispatch, tabId, empresa])


  const onValueChnage = useCallback((data, key: string) => {
    dispatch(setDatosEdicion({
      key: tabId,
      data: {
        ...rolsState,
        [key]: data
      }
    }))
  }, [dispatch, tabId, rolsState])

  const putTo0 = useCallback((data: RolesPadoDatosEdicion) => {
    const temp = { ...data }
    temp.d_horas = "00"
    temp.d_min = "00"
    return temp
  }, [])


  const calculateTotal = useCallback((data: RolesPadoDatosEdicion) => {
    const temp = { ...data }
    temp.totalPagar = temp?.totalIngreso - temp?.totalDescuentos + temp?.totalBeneficios
    return temp
  }, [])

  const calculateDiscount = useCallback((data: RolesPadoDatosEdicion) => {
    let temp = { ...data }
    if (temp?.contratoEmpleado?.pagarIESS === 0) {
      temp.totalDescuentos = temp?.iessPersonal + temp?.subsidioMaternidad + temp?.subsidioEnfermedad + temp?.prestamos + temp?.prestamosQuirografarios + temp?.prestamosHipotecarios + temp?.anticipos + temp?.descuentos + temp?.renta + temp?.remmplazoOtros + temp?.atrasos
    } else {
      temp.totalDescuentos = temp?.prestamos + temp?.subsidioMaternidad + temp?.subsidioEnfermedad + temp?.prestamosQuirografarios + temp?.prestamosHipotecarios + temp?.anticipos + temp?.descuentos + temp?.renta + temp?.remmplazoOtros + temp?.atrasos
    }
    temp = calculateTotal(temp)
    return temp
  }, [calculateTotal])

  const calculateBen = useCallback((data: RolesPadoDatosEdicion) => {
    const temp = { ...data }
    let totalben = 0
    let dtercero = 0
    let dcuarto = 0

    if (temp?.contratoEmpleado?.acumulaFondoReserva == 0) {
      totalben = totalben + temp?.fondosReserva
    }
    if (temp?.contratoEmpleado?.tipoPersonal !== 3) {
      totalben += temp?.vacaciones
      if (temp?.contratoEmpleado?.acumulaDecimoTercero === 0) {
        if (temp?.vacacionesRg?.value === 0) {
          dtercero = temp?.baseAporteIess / 12
        } else {
          dtercero = (temp?.baseAporteIess - temp?.sueldoPercibido + temp?.sueldoNominal) / 12
        }
        totalben = totalben + dtercero
      }
      if (temp?.contratoEmpleado?.acumulaDecimoCuarto === 0) {
        const diferencia = 30 - (temp?.dias + (parseInt(temp?.d_horas) / 24) + ((parseInt(temp?.d_min) * 1 / 24) / 60))
        const dias = 30 - diferencia
        if (temp?.contratoEmpleado?.tipo === 5) {
          dcuarto = (dias * temp?.sbu / 360) * temp?.horasMesParcial / 240
        } else if (temp?.contratoEmpleado?.tipo === 6 || temp?.contratoEmpleado?.tipo === 7) {
          dcuarto = (Math.round(dias) * temp?.sbu / 360) * temp?.horasMesParcial / 240
        } else {
          dcuarto = (dias * temp?.sbu) / 360
        }
        temp.decimoCurto = dcuarto
        totalben = totalben + temp?.decimoCurto
      }
    }
    temp.decimoTercero = dtercero
    temp.decimoCurto = dcuarto
    temp.totalBeneficios = Number(totalben.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0])
    return temp
  }, [])



  const calculateSECAP = useCallback((data: RolesPadoDatosEdicion) => {
    const temp = { ...data }
    if ((temp?.vacacionesRg?.value === 0 || temp?.contratoEmpleado?.tipo === 6) && temp?.contratoEmpleado?.tipo !== 7) {
      temp.secap = ((temp?.baseAporteIess * 0.005) * 100) / 100
    } else {
      temp.secap = (((temp?.baseAporteIess - temp?.sueldoPercibido + temp?.sueldoNominal) * 0.005) * 100) / 100
    }
    return temp
  }, [])

  const calculateIECE = useCallback((data: RolesPadoDatosEdicion) => {
    const temp = { ...data }
    if ((temp?.vacacionesRg?.value === 0 || temp?.contratoEmpleado?.tipo === 6) && temp?.contratoEmpleado?.tipo !== 7) {
      temp.iece = ((temp?.baseAporteIess * 0.005) * 100) / 100
    } else {
      temp.iece = (((temp?.baseAporteIess - temp?.sueldoPercibido + temp?.sueldoNominal) * 0.005) * 100) / 100
    }
    return temp
  }, [])

  const calculateIESS = useCallback((data: RolesPadoDatosEdicion) => {
    let temp = { ...data }
    if (temp?.codigo === 0) {
      if ((temp?.vacacionesRg?.value === 0 || temp?.contratoEmpleado?.tipo === 6) && temp?.contratoEmpleado?.tipo !== 7) {
        temp.iessPersonal = temp?.baseAporteIess * temp?.rolparametros?.aportePersonal
      } else {
        temp.iessPersonal = (temp?.baseAporteIess - temp?.sueldoPercibido + temp?.sueldoNominal) * temp?.rolparametros?.aportePersonal
      }
    }
    temp = calculateDiscount(temp)
    return temp
  }, [calculateDiscount])

  const calculateIESSP = useCallback((data: RolesPadoDatosEdicion) => {
    let temp = { ...data }
    if (temp?.codigo === 0) {
      if ((temp?.vacacionesRg?.value === 0 || temp?.contratoEmpleado?.tipo === 6) && temp?.contratoEmpleado?.tipo !== 7) {
        temp.iessPatronal = temp?.baseAporteIess * temp?.rolparametros?.aportePatronal
      } else {
        temp.iessPatronal = (temp?.baseAporteIess - temp?.sueldoPercibido + temp?.sueldoNominal) * temp?.rolparametros?.aportePatronal
      }
    }
    temp = calculateDiscount(temp)
    return temp
  }, [calculateDiscount])

  const calculateFR = useCallback((data: RolesPadoDatosEdicion) => {
    const temp = { ...data }
    if (temp?.codigo !== 0 && seleccionado?.fondosReserva > 0) {
      return temp
    }
    if (parseInt(temp?.contratoEmpleado?.anios) > 0 || temp?.contratoEmpleado?.pagaFondosReservaInicio == 1) {
      if (temp?.vacacionesRg?.value === 0) {
        temp.fondosReserva = temp?.baseAporteIess * 0.0833
      } else {
        temp.fondosReserva = (temp?.baseAporteIess - temp?.sueldoPercibido + temp?.sueldoNominal) * 0.0833
      }
      if (parseInt(temp.contratoEmpleado?.fechaProporcional) >= 1) {
        const fr = temp?.fondosReserva
        temp.fondosReserva = fr / 30 * parseInt(temp.contratoEmpleado?.fechaProporcional)
      }
    } else {
      temp.fondosReserva = 0
    }
    return temp
  }, [seleccionado])


  const calculateTI = useCallback((data: RolesPadoDatosEdicion) => {
    let temp = { ...data }
    temp.totalIngreso = temp?.baseAporteIess + temp?.bonos + temp?.bonificaciones
    temp = calculateFR(temp)
    temp = calculateBen(temp)
    temp = calculateIESS(temp)
    temp = calculateIESSP(temp)
    temp = calculateSECAP(temp)
    temp = calculateIECE(temp)
    temp = calculateTotal(temp)
    return temp
  }, [calculateFR, calculateBen, calculateIESS, calculateIESSP, calculateSECAP, calculateIECE, calculateTotal])

  const calculateBAI = useCallback((data: RolesPadoDatosEdicion) => {
    let temp = { ...data }
    temp.baseAporteIess = temp?.sueldoPercibido + temp?.horarioNocturno + temp?.horarioSuple + temp?.horarioExtra + temp?.comisiones + temp?.bonoAportable
    temp = calculateTI(temp)
    return temp
  }, [calculateTI])

  const calculateSalaryDay = useCallback((data: RolesPadoDatosEdicion) => {
    let temp = { ...data }
    if (temp?.dias >= 30) {
      temp.dias = 30
      temp.horas = '00'
      temp.min = '00'
    }

    let nominal = temp.sueldoNominal
    if (temp.contratoEmpleado?.tipo === 5 || temp.contratoEmpleado?.tipo === 6 || temp.contratoEmpleado?.tipo === 7) {
      nominal = ((temp?.sueldoNominal * temp.horasMesParcial) / 240)
      temp.diasEfecivos = parseFloat((30 * temp.horasMesParcial / 8).toFixed(2))
    } else {
      nominal = temp.sueldoNominal
      temp.diasEfecivos = null
    }
    const sxd = nominal / temp?.rolparametros?.dias
    const sxh = sxd / 8
    const sxm = sxh / 60
    temp.sueldoPercibido = Math.round((sxd * temp.dias + sxh * parseInt(temp.d_horas) + sxm * parseInt(temp.d_min)) * 100) / 100
    temp = calculateBAI(temp)
    temp = calculateFR(temp)
    temp = calculateIESS(temp)
    temp = calculateTI(temp)
    return temp
  }, [calculateBAI, calculateFR, calculateIESS, calculateTI])

  const onHoursToDaysAndHoursLab = useCallback((data, key: string) => {
    let temp = { ...rolsState }
    let horas = 0
    let minutos = 0

    if (key === "horas") {
      temp.horas = data
      horas = Number(temp?.horas)
      minutos = Number(temp?.min)
    } else {
      temp.min = data
      horas = Number(temp?.horas)
      minutos = Number(temp?.min)
    }
    const minTHoras = minutos / 60
    if (minTHoras + horas > 240) {
      setToast('Error, el numero total de horas no puede superar el maximo de horas laborables al mes (240) si tiene mas tiempo debe colocarlo en horas extras', ToastTypes.Warning)
      dispatch(setDatosEdicion({
        key: tabId,
        data: {
          ...temp
        }
      }))
      return
    }
    const horasPorDia: number = 8
    const diasCompletos = Math.floor(horas / horasPorDia)
    const horasRestantes = horas % horasPorDia
    temp.dias = diasCompletos
    temp.d_horas = horasRestantes.toString()
    temp.d_min = temp.min
    temp = calculateSalaryDay(temp)
    dispatch(setDatosEdicion({
      key: tabId,
      data: {
        ...temp
      }
    }))
  }, [dispatch, rolsState, setToast, tabId, calculateSalaryDay])

  const onActiveJournal = useCallback((data) => {
    const temp = { ...rolsState }
    temp.jornada = data
    if (data?.value === 1) {
      temp.horNH = true
      temp.horNM = true
    } else {
      temp.horNH = false
      temp.horNM = false
      temp.horarioNH = 0
      temp.horarioNM = 0
    }
    dispatch(setDatosEdicion({
      key: tabId,
      data: {
        ...temp
      }
    }))
  }, [dispatch, tabId, rolsState])


  const cleanAll = useCallback((data, key: string) => {
    const temp = { ...rolsState }
    temp[key] = data
    if (rolsState?.mes && rolsState?.mes.value !== data?.value) {
      temp.jornada = grupoJornada[0]
      temp.sueldoNominal = null
      temp.dias = temp.rolparametros?.dias
      temp.d_horas = "00"
      temp.d_min = "00"
      temp.sueldoPercibido = null
      temp.horarioEH = 0
      temp.horarioEM = 0
      temp.horarioExtra = null
      temp.horarioNH = 0
      temp.horarioNM = 0
      temp.horarioNocturno = null
      temp.horarioSH = 0
      temp.horarioSM = 0
      temp.horarioSuple = null
      temp.comisiones = null
      temp.secap = null
      temp.iessPersonal = null
      temp.iece = null
      temp.prestamos = null
      temp.anticipos = null
      temp.descuentos = null
      temp.renta = null
      temp.fondosReserva = null
      temp.multas = null
      temp.remmplazoOtros = null
      temp.bonificaciones = null
      temp.totalDescuentos = null
      temp.totalPagar = null
      temp.totalIngreso = null
    }
    dispatch(setDatosEdicion({
      key: tabId,
      data: {
        ...temp
      }
    }))
  }, [dispatch, rolsState, tabId])

  const calculateVnoct = useCallback((data: RolesPadoDatosEdicion) => {
    let temp = { ...data }
    const vhn = temp?.sueldoNominal / temp?.horasMes
    const vhnt = vhn * (temp?.rolparametros?.nocturna / 100)
    const vmnt = vhnt / 60
    const hnocturno = temp?.horarioNH
    const mnocturno = temp?.horarioNM
    temp.horarioNocturno = hnocturno * vhnt + mnocturno * vmnt
    temp = calculateBAI(temp)
    temp = calculateFR(temp)
    temp = calculateIESS(temp)
    return temp
  }, [calculateBAI, calculateFR, calculateIESS])

  const calculateVSuple = useCallback((data: RolesPadoDatosEdicion) => {
    let temp = { ...data }
    if (temp?.contratoEmpleado?.confianza === 0) {
      const vhn = temp?.sueldoNominal / temp?.horasMes
      const vhs = vhn * (1 + temp?.rolparametros?.porcentajeSuplementarias / 100)
      const vms = vhs / 60
      const hsuple = temp?.horarioSH
      const msuple = temp?.horarioSM
      const suple = (hsuple * vhs) + (msuple * vms)
      temp.horarioSuple = suple
      temp = calculateBAI(temp)
      temp = calculateFR(temp)
      temp = calculateIESS(temp)
    } else {
      temp.horarioSH = 0
      temp.horarioSM = 0
      setToast("No puede realizar esta acción, el empleado es de confianza", ToastTypes.Warning)
    }
    return temp
  }, [calculateBAI, calculateFR, calculateIESS, setToast])

  const calculateVextra = useCallback((data: RolesPadoDatosEdicion) => {
    let temp = { ...data }
    if (temp?.contratoEmpleado?.confianza === 0) {
      const vhn = temp?.sueldoNominal / temp?.horasMes
      const vhs = vhn * (1 + temp?.rolparametros?.porcentajeExtras / 100)
      const vms = vhs / 60
      const hextra = temp?.horarioEH
      const mextra = temp?.horarioEM
      const vextra = (hextra * vhs) + (mextra * vms)
      temp.horarioExtra = vextra
      temp = calculateBAI(temp)
      temp = calculateFR(temp)
      temp = calculateIESS(temp)
    } else {
      temp.horarioEH = 0
      temp.horarioEM = 0
      setToast("No puede realizar esta acción, el empleado es de confianza", ToastTypes.Warning)
    }
    return temp
  }, [calculateBAI, calculateFR, calculateIESS, setToast])

  const arrears = useCallback((data: RolesPadoDatosEdicion, marcations: Array<MarcationList>) => {
    let temp = { ...data }
    let horas = 0
    let diastrab = 0
    let mintrab = 0
    let num_atrasos = 0
    let num_antes = 0
    temp.multas = 0
    temp.observaciones = ''
    let hsalidas = 0
    let hatrasos = 0
    let hextra50 = 0
    let hextra100 = 0
    let hnocturno = 0
    const mensaje: Array<string> = []
    marcations?.forEach(x => {
      num_atrasos = num_atrasos + x?.atrasos
      num_antes = num_antes + x?.antes
      hsalidas = hsalidas + x?.minutosAntes
      hatrasos = hatrasos + x?.minutosAtraso
      hextra50 = hextra50 + x?.minutosExtra50
      hextra100 = hextra100 + x?.minutosExtra100
      horas = horas + x?.minutosTrabajados
      hnocturno = hnocturno + x?.minutosNocturno
      if (x?.minutosTrabajados !== 0) {
        diastrab = diastrab + 1
        if (temp?.contratoEmpleado?.tipo === 5 || temp?.contratoEmpleado?.tipo === 4) {
          mintrab = mintrab + (temp?.contratoEmpleado?.horasTiempoParcial * 60)
        } else {
          mintrab = mintrab + 480
        }
      }
      if (x?.error !== "") {
        mensaje.push(x?.error)
      }
      if (mensaje.length == 0) {
        let min = ""
        let hor = ""

        const min_atrasos = hatrasos.toString()
        const min_antes = hsalidas.toString()
        if (hextra50 <= temp?.rolparametros?.horasSuplementariasMaximo) {
          min = hextra50 % 60 < 10 ? "0" + (hextra50 % 60).toString() : (hextra50 % 60).toString()
          hor = hextra50 / 60 < 10 ? "0" + (hextra50 / 60).toString() : (hextra50 / 60).toString()
        } else if (hextra50 % 60 == 0) {
          min = hextra50 % 60 < 10 ? "0" + (hextra50 % 60).toString() : (hextra50 % 60).toString()
          hor = hextra50 / 60 < 10 ? "0" + (hextra50 / 60).toString() : (hextra50 / 60).toString()
        } else {
          hor = (temp?.rolparametros?.horasSuplementariasMaximo / 60 < 10 ? "0" + temp?.rolparametros?.horasSuplementariasMaximo / 60 : temp?.rolparametros?.horasSuplementariasMaximo / 60).toString()
          min = (temp?.rolparametros?.horasSuplementariasMaximo % 60 < 10 ? "0" + temp?.rolparametros?.horasSuplementariasMaximo % 60 : temp?.rolparametros?.horasSuplementariasMaximo % 60).toString()
        }
        temp.horarioSH = parseInt(hor.substring(0, 2))
        temp.horarioSM = parseInt(min.substring(0, 2))


        min = hextra100 % 60 < 10 ? `0${hextra100 % 60}` : (hextra100 % 60).toString()
        hor = hextra100 / 60 < 10 ? `00${hextra100 / 60}` : hextra100 / 60 < 100 ? `0${hextra100 / 60}` : (hextra100 / 60).toString()
        temp.horarioEH = parseInt(hor.substring(0, 3))
        temp.horarioEM = parseInt(min.substring(0, 2))

        min = hnocturno % 60 < 10 ? `0${hnocturno % 60}` : (hnocturno % 60).toString()
        hor = hnocturno / 60 < 10 ? `00${hnocturno / 60}` : hnocturno / 60 < 100 ? `0${hnocturno / 60}` : (hnocturno / 60).toString()
        temp.horarioNH = parseInt(hor.substring(0, 3))
        temp.horarioNM = parseInt(min.substring(0, 2))

        const horas_ausente = (mintrab / 60) - (horas / 60)
        temp.minTrabajados = horas
        const dias_trabajados = 30 - (horas_ausente / 8)

        temp.dias = dias_trabajados
        const horas_trabajadas = (dias_trabajados - temp?.dias) * 8
        const minutos_trabajados = (horas_trabajadas - horas_trabajadas) * 60
        hor = horas_trabajadas < 10 ? `0${horas_trabajadas}` : horas_trabajadas.toString()
        min = minutos_trabajados < 10 ? `0${minutos_trabajados}` : minutos_trabajados.toString()
        temp.d_horas = hor
        temp.d_min = min
        if (dias_trabajados > temp?.rolparametros?.dias) {
          temp.dias = temp?.rolparametros?.dias
          temp = putTo0(temp)
        }
        temp = calculateSalaryDay(temp)
        temp = calculateVSuple(temp)
        temp = calculateVextra(temp)
        temp = calculateDiscount(temp)
        if (num_atrasos > 0) {
          const matrasos = temp.rolparametros?.multa
          const multa_atraso = num_atrasos * matrasos
          temp.multas = temp?.multas * multa_atraso
          temp.observaciones = `${temp?.observaciones} - Tiene ${num_atrasos} atrasos que acumulan un total de ${min_atrasos} minutos. La multa por cada atraso es de $ ${matrasos}`
          temp = calculateDiscount(temp)
        }
        if (num_antes > 0) {
          const msalidas = temp.rolparametros?.multa
          const multa_salidas = num_antes * msalidas
          temp.multas = temp?.multas * multa_salidas
          temp.observaciones = `${temp?.observaciones} - Tiene ${num_antes} atrasos que acumulan un total de ${min_antes} minutos. La multa por cada atraso es de $ ${msalidas}`
          temp = calculateDiscount(temp)
        }
      } else {
        setArrayErrorsMarks(mensaje)
        setShowErrorPopupMarks(true)
      }
    })
    return temp
  }, [putTo0, calculateDiscount, calculateSalaryDay, calculateVSuple, calculateVextra])

  const onLoadContract = useCallback(async (codigo: number, usuario: string, cedula: string) => {
    onLoader(true, 'Cargando usuario...')
    let temp = { ...rolsState }
    try {
      consoleService.log(codigo, usuario)
      const fecha = `01/${rolsState?.mes?.value ?? ''}/${rolsState?.anio ?? ''}`
      const data = await RolsPaymentsServices.loadActive(codigo, fecha)
      consoleService.log(data, 'load data')
      if (data?.auto && data?.error === false) {
        temp.contratoEmpleado = data?.auto
        temp.sueldoNominal = data?.auto?.nominal
        temp.empleado = usuario
        temp.empleadoCodigo = codigo
        temp.empleadoCedula = cedula
        temp.horasMes = 240
        temp.cargo = data?.auto?.rolDescripcion
        if (data?.auto?.tipo === 5 || data?.auto?.tipo === 6 || data?.auto?.tipo === 7) {
          temp.horasDias = data?.auto?.horasTiempoParcial
          temp.horasMesParcial = 30 * temp.horasDias
        }
        temp = calculateSalaryDay(temp)
        temp.htrCodigo = temp?.contratoEmpleado?.horarioTrabajo
        if (temp?.contratoEmpleado?.confianza === 0) {
          const markdata = await MarcationsService.loadMarctions(codigo, rolsState?.mes?.value, rolsState?.anio, rolsState?.rolparametros?.codigo, temp.htrCodigo)
          consoleService.log(markdata, 'marcas load')
          if (markdata?.auto && markdata?.error === false) {
            temp = arrears(temp, markdata?.auto)
          } else {
            setToast(markdata?.message, ToastTypes.Danger)
          }
        }
        const discountsdata = await RolsPaymentsServices.loadUserDiscount(temp.contratoEmpleado?.usuarioCodigo, rolsState?.mes?.value, rolsState?.anio)
        consoleService.log(discountsdata, 'load discount')
        if (discountsdata?.auto && discountsdata?.error === false) {
          let total = 0
          let descuentos = []
          descuentos = discountsdata?.auto
          temp.prestamos = descuentos[0]?.valor
          temp.anticipos = descuentos[1]?.valor
          descuentos.forEach(x => {
            if (x?.valor > 0) {
              temp.observaciones = temp.observaciones + "-" + x?.descripcion + ":" + x?.valor
            }
          })
          for (let j = 2; j < descuentos.length; j++) {
            if (descuentos[j]?.valor > 0) {
              total = total + descuentos[j]?.valor;
            }

          }
          temp.descuentos = total
          temp = calculateDiscount(temp)
        }
        consoleService.log(temp, 'temp')
        dispatch(setDatosEdicion({
          key: tabId,
          data: {
            ...temp
          }
        }))
      }
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
    onLoader(false, '')
  }, [setToast, onLoader, rolsState, calculateSalaryDay, dispatch, tabId, calculateDiscount, arrears])

  const cargarRolPago = useCallback(async (loader: boolean, rolPagoData) => {
    if (loader === false) {
      return
    }
    dispatch(
      setButtons({
        tabKey: tabId,
        buttons: {
          ...ModuleButtons,
          Imprimir: true,
        }
      }),
    )

    onSetButtonAction(ButtonTypes.edit)
    onLoader(true, 'Cargando Registro...')
    try {
      const dataFP = await formasPagoService.getFormasPago(ETipoTransaccion.ventas, "Elija una opción");
      const dataLocal = await localesService.getLocales("Elija un local")
      let temp = { ...rolsState }
      temp.tipo = empresa[0]?.numeroRoles ?? 0
      if (dataLocal?.auto && dataLocal?.error === false) {
        const provider = dataLocal?.auto.slice(0)
        const index = await getIndexProvider(provider, 'codigo', rolPagoData?.local)
        if (index > -1) {
          temp.lugar = provider[index]
        } else {
          temp.lugar = provider[0]
        }
      }

      temp.tipoPago = []
      temp.jornada = grupoJornada[0]
      temp.modoEdicion = true
      temp.codigo = rolPagoData?.codigo
      temp.empleadoCodigo = rolPagoData?.usuario
      temp.egresosCodigo = Number(rolPagoData?.egreso)
      if (rolPagoData?.fechaRegistro !== "" && rolPagoData?.fechaRegistro !== null) {
        temp.fechaRegistro = DateUtils.format(rolPagoData?.fechaRegistro, 'yyyy-MM-dd', 'dd/MM/yyyy')
      }
      if (rolPagoData?.fechaLabor !== "" && rolPagoData?.fechaLabor !== null) {
        temp.fechaElaboracion = DateUtils.format(rolPagoData?.fechaLabor, 'yyyy-MM-dd', 'dd/MM/yyyy')
      }
      temp.fechaPago = rolPagoData?.fechaCobro
      if (dataFP?.auto && dataFP?.error === false) {
        const newFormPago = []
        dataFP?.auto.forEach(x => {
          if (x?.codigo === 7 || x?.codigo === -1 || x?.codigo === 6 || x?.codigo === 114 || x?.codigo === EFormasPago.otros) {
            newFormPago.push(x)
          }
        })
        temp.listaFormasPagos = newFormPago
        const index = await getIndexProvider(temp.listaFormasPagos, 'codigo', rolPagoData?.fPago)
        if (index > -1) {
          temp.formaPago = newFormPago[index]
        } else {
          temp.formaPago = newFormPago[0]
        }
      }
      temp.empleado = `${rolPagoData?.apellidos} ${rolPagoData?.nombres}`
      temp.cargo = rolPagoData?.descripcion
      const flabor: string = rolPagoData?.fechaLabor
      if (MesesData.length > 0) {
        const provider = MesesData.slice(0)
        const index = await getIndexProvider(provider, 'value', flabor.substring(3, 5))
        if (index > -1) {
          temp.mes = MesesData[index]
        } else {
          temp.mes = MesesData[0]
        }
      }
      temp.anio = Number(flabor.substring(6))
      temp.vacacionesRg = grupoVacaciones[rolPagoData?.calcularNominal]
      temp.sueldoNominal = Number(rolPagoData?.nominal)
      temp.sueldoPercibido = Number(rolPagoData?.sueldo)
      temp.dias = rolPagoData?.dias
      temp.d_horas = String(rolPagoData?.HL)
      temp.d_min = String(rolPagoData?.ML)
      temp.horarioNH = Number(rolPagoData?.HN)
      temp.horarioNocturno = rolPagoData?.valorJornada
      temp.horarioSH = Number(rolPagoData?.HS)
      temp.horarioSM = rolPagoData?.MS
      temp.horarioSuple = Number(rolPagoData?.valorHoraSuplementarias)
      temp.horarioEH = Number(rolPagoData?.HE)
      temp.horarioEM = rolPagoData?.me
      temp.horarioExtra = Number(rolPagoData?.valorHoraExtra)
      temp.comisiones = Number(rolPagoData?.comisiones)
      temp.bonoAportable = rolPagoData?.ajusteSueldo
      temp.iessPersonal = rolPagoData?.iess
      temp.iessPatronal = rolPagoData?.pagoIess
      temp.secap = rolPagoData?.secap
      temp.iece = rolPagoData?.iece
      temp.prestamos = rolPagoData?.prestamos
      temp.bonos = rolPagoData?.bonos
      temp.prestamosQuirografarios = rolPagoData?.prestamosQuirografarios
      temp.prestamosHipotecarios = rolPagoData?.prestamosHipotecarios
      temp.anticipos = rolPagoData?.anticipos
      temp.descuentos = rolPagoData?.descuentos
      temp.subsidioMaternidad = rolPagoData?.subsidioMaternidad
      temp.subsidioEnfermedad = rolPagoData?.subsidioEnfermedad
      temp.renta = rolPagoData?.renta
      temp.fondosReserva = rolPagoData?.fondosReserva
      temp.vacaciones = rolPagoData?.vacaciones
      temp.decimoTercero = rolPagoData?.decimoTercero
      let cde_adtercero = 1
      let cde_adcuarto = 1
      if (temp.decimoTercero > 0) {
        cde_adtercero = 0
      }
      temp.decimoCurto = rolPagoData?.decimoCuarto
      if (temp.decimoCurto > 0) {
        cde_adcuarto = 0
      }
      temp.contratoEmpleado = {
        codigo: 0,
        usuarioCodigo: 0,
        usuarioNombre: '',
        usuarioNombres: '',
        usuarioApellidos: '',
        usuarioNA: '',
        usuarioCedula: '',
        nivelEstudiosCodigo: 0,
        nivelEstudiosDescripcion: '',
        tituloDescripcion: '',
        tipo: rolPagoData?.contratoTipo ?? 0,
        tipoDescripcion: '',
        fecha: '',
        fechaIngreso: '',
        fechaSalida: '',
        archivo: '',
        observacionesIngreso: '',
        observacionesSalida: '',
        calificado: 0,
        tipoPersonal: 0,
        funciones: '',
        contrato: '',
        horasTiempoParcial: Number(rolPagoData?.horasTiempoParcial) ?? 0,
        calificadoDescripcion: '',
        estado: 0,
        estadoDescripcion: '',
        proceso: '',
        procesoDescripcion: '',
        anios: '',
        fechaProporcional: rolPagoData?.fechaProporcional ?? '',
        discapacidad: '',
        porcentajeDiscapacidad: 0,
        confianza: 0,
        nominal: 0,
        liquido: 0,
        jornada: 0,
        jornadaNombre: '',
        horarioTrabajo: 0,
        horarioTrabajoDescripcion: '',
        organicoRol: 0,
        organico: 0,
        rol: 0,
        rolDescripcion: '',
        acumulaFondoReserva: Number(rolPagoData?.acumularFondosReserva) ?? 0,
        privacionesNocturno: 0,
        privacionesSuplementarios: 0,
        privacionesExtras: 0,
        privacionesDecimoTercero: 0,
        privacionesDecimoCuarto: 0,
        pagaImpuestoRenta: 0,
        pagarIESS: rolPagoData?.pagarIess ?? 0,
        acumulaDecimoTercero: cde_adtercero,
        acumulaDecimoCuarto: cde_adcuarto,
        cuentaBanco: 0,
        cuentaBancoDescripcion: '',
        pagaFondosReservaInicio: 0,
      }
      temp.horasMes = 240
      if (temp?.contratoEmpleado?.tipo === 5 || temp?.contratoEmpleado?.tipo === 6 || temp?.contratoEmpleado?.tipo === 7) {
        temp.horasDias = temp?.contratoEmpleado?.horasTiempoParcial
        temp.horasMesParcial = 30 * temp?.horasDias
      }
      temp.multas = rolPagoData?.multas
      temp.bonificaciones = rolPagoData?.bonificacion
      temp.remmplazoOtros = Number(rolPagoData?.reemplazo)
      temp.observaciones = rolPagoData?.observaciones
      temp.atrasos = Number(rolPagoData?.atrasos)
      temp.sbu = rolPagoData?.unificado
      temp = calculateBAI(temp)
      temp = calculateTI(temp)
      temp = calculateDiscount(temp)
      const dataRolPay = await RolsPaymentsServices.getRolsPayments(rolPagoData?.codigo ?? 0)
      consoleService.log(dataRolPay, 'dataRolPay')
      if (dataRolPay?.auto && dataRolPay?.error === false) {
        if (dataRolPay?.auto?.length > 0) {
          temp.tipoPago.push(
            {
              codigo: parseInt(dataRolPay?.auto[0]?.subcodigo),
              valor: dataRolPay?.auto[0]?.valor,
            }
          )
        }
      }

      temp.loading = false
      consoleService.log(temp, 'edit data')
      dispatch(setDatosEdicion({
        key: tabId,
        data: { ...temp }
      }))
      dispatch(setDatosEdicionBackup({
        key: tabId,
        data: { ...temp }
      }))
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
    onLoader(false, '')
    onSetButtonAction(undefined)
  }, [dispatch, onLoader, onSetButtonAction, tabId, setToast, calculateBAI, calculateDiscount, calculateTI, rolsState, empresa])

  const onChangeAndCalcBAI = useCallback((data, key: string) => {
    let temp = { ...rolsState }
    temp[key] = data
    temp = calculateBAI(temp)
    dispatch(setDatosEdicion({
      key: tabId,
      data: {
        ...temp
      }
    }))
  }, [dispatch, rolsState, tabId, calculateBAI])

  const onChangeAndCalcTI = useCallback((data, key: string) => {
    let temp = { ...rolsState }
    temp[key] = data
    temp = calculateTI(temp)
    dispatch(setDatosEdicion({
      key: tabId,
      data: {
        ...temp
      }
    }))
  }, [dispatch, rolsState, tabId, calculateTI])

  const onChangeAndCalcDisc = useCallback((data, key: string) => {
    let temp = { ...rolsState }
    temp[key] = data
    temp = calculateDiscount(temp)
    dispatch(setDatosEdicion({
      key: tabId,
      data: {
        ...temp
      }
    }))
  }, [dispatch, rolsState, tabId, calculateDiscount])

  const onChangeAndCalcVnoct = useCallback((data, key: string) => {
    let temp = { ...rolsState }
    temp[key] = data
    temp = calculateVnoct(temp)
    dispatch(setDatosEdicion({
      key: tabId,
      data: {
        ...temp
      }
    }))
  }, [dispatch, rolsState, tabId, calculateVnoct])

  const onChangeAndCalcVsuple = useCallback((data, key: string) => {
    let temp = { ...rolsState }
    temp[key] = data
    temp = calculateVSuple(temp)
    dispatch(setDatosEdicion({
      key: tabId,
      data: {
        ...temp
      }
    }))
  }, [dispatch, rolsState, tabId, calculateVSuple])


  const onChangeAndCalcVextra = useCallback((data, key: string) => {
    let temp = { ...rolsState }
    temp[key] = data
    temp = calculateVextra(temp)
    dispatch(setDatosEdicion({
      key: tabId,
      data: {
        ...temp
      }
    }))
  }, [dispatch, rolsState, tabId, calculateVextra])


  const onChangeAndCalcSalaryDay = useCallback((data, key: string) => {
    let temp = { ...rolsState }
    temp[key] = data
    if (key === "dias") {
      temp = putTo0(temp)
    }
    temp = calculateSalaryDay(temp)
    dispatch(setDatosEdicion({
      key: tabId,
      data: {
        ...temp
      }
    }))
  }, [dispatch, rolsState, tabId, calculateSalaryDay, putTo0])

  const onValidate = useCallback(() => {
    const errors: Array<string> = []
    if (rolsState?.empleadoCodigo == 0) {
      errors.push('Empleado: elija al empleado que corresponde.')
    }
    if (rolsState?.sueldoNominal === null || rolsState?.sueldoPercibido === undefined) {
      errors.push('Sueldo Nominal: ingrese un valor.')
    }
    if (rolsState?.mes?.value === "-1") {
      errors.push('Mes: elija un mes.')
    }
    let total: number = 100
    const provider = rolsState?.centrosAsignados.slice(0)
    provider.forEach(x => {
      total -= x?.porcentaje
    })
    if (total <= 100 && total !== 0 && provider.length > 0) {
      errors.push('Porcentaje: el total prorrateado en centros de costos para este rol es mayor al 100%.')
    }
    return errors
  }, [rolsState])

  const onGenrateEntryNomina = useCallback(async (codigo: number) => {
    if (codigo !== 0) {
      try {
        const entry_data: GenerateEntryNomina = {
          descripcion: `ROL DE PAGOS EMPLEADO: ${rolsState?.empleado ?? ''} MES: ${rolsState?.mes?.value ?? ''}  PERIODO: ${rolsState?.anio ?? ''}`,
          nrdoc: codigo,
          diarioNumero: activePeriod?.length > 0 ? activePeriod[0]?.diario : 0,
          codigo: codigo,
          usuario: rolsState?.empleadoCodigo ?? 0,
          anio: rolsState?.anio ?? 0,
          mes: rolsState?.mes?.value ?? '',
        }

        const data = await RolsPaymentsServices.generateRolEntry(entry_data)
        consoleService.log(data, 'generate entry')
        if (data?.auto && data?.auto?.error > 1) {
          setToast(data?.auto?.mensaje, ToastTypes.Danger)
        } else if (data?.error === true) {
          setToast(data?.message, ToastTypes.Danger)
        }
      } catch (error) {
        return error
      }
    }
  }, [rolsState, activePeriod, setToast])


  const onGenrateEntryPay = useCallback(async (codigo: number, tipopago: Array<any>) => {
    if (codigo !== 0) {
      try {
        const entry: AccountingEntry = {
          infoRegistro: {
            movimiento: 'LIQUIDACION ROL DE PAGOS',
          },
          fecha: DateUtils.pickersDateToApiDate(rolsState?.fechaPago),
          descripcion: `LIQUIDACION ROL DE PAGOS EMPLEADO: ${rolsState?.empleado ?? ''} MES: ${rolsState?.mes?.value ?? ''}  PERIODO: ${rolsState?.anio ?? ''}`,
          numeroDocumento: codigo,
          diarioNumero: activePeriod?.length > 0 ? activePeriod[0]?.diario : 0,
          usuarioCodigo: rolsState?.empleadoCodigo ?? 0,
          localCodigo: 0,
          codigo: rolsState?.codigo ?? 0,
          formaPago: tipopago,
        }
        const generateEntry = await DocCuentasCobrar.generateAccountingEntry(
          entry,
        )

        consoleService.log(generateEntry, 'save entry pay')
        if (generateEntry?.auto && generateEntry?.auto?.error > 1) {
          setToast(generateEntry?.auto?.mensaje, ToastTypes.Danger)
        } else if (generateEntry?.error === true) {
          setToast(generateEntry?.message, ToastTypes.Danger)
        }

      } catch (error) {
        setToast(error.message, ToastTypes.Danger)
      }
    }
  }, [rolsState, activePeriod, setToast])

  const padString = useCallback((_str: string, _n: number, _pStr: string, _sideStr: string = 'LEFT'): string => {
    let _rtn: string = _str
    if ((_pStr === null) || (_pStr.length < 1)) {
      _pStr = ' '
    }
    if (_str.length < _n) {
      let _s: string = ''
      for (let i = 0; i < (_n - _str.length); i++) {
        _s += _pStr
      }
      if (_sideStr === "LEFT") {
        _rtn = _s + _str
      } else {
        _rtn = _str + _s
      }
    }
    return _rtn
  }, [])

  const onSave = useCallback(async () => {
    let errors: Array<string> = []
    errors = onValidate()
    const result = validationRoles.current.instance.validate()
    if (result.isValid && errors.length === 0) {
      onSetButtonAction(ButtonTypes.save)
      onLoader(true, 'Guardando...')
      consoleService.log(rolsState)
      try {
        let fechaCobro = ''
        if (rolsState?.tipoPago.length > 0) {
          fechaCobro = rolsState?.fechaPago
        }
        const save_data: SaveRolsPay = {
          infoRegistro: {
            codigo: rolsState?.codigo ?? 0,
            usuario: rolsState?.empleadoCodigo ?? 0,
            anio: rolsState?.anio ?? 0,
            mes: rolsState?.mes?.value ?? '',
            sueldoNominal: rolsState?.sueldoNominal ?? 0,
            sueldo: rolsState?.sueldoPercibido ?? 0,
            dias: `${padString(rolsState?.dias.toString(), 2, "0")}${padString(rolsState?.d_horas, 2, "0")}${padString(rolsState?.min, 2, "0")}`,
            jornada: rolsState?.jornada?.value ?? 0,
            porcentajeJornada: rolsState?.rolparametros?.nocturna ?? 0,
            valorJornada: rolsState?.horarioNocturno ?? 0,
            horasSuplementarias: rolsState?.horarioSH + (rolsState?.horarioSM / 0.60) / 100 ?? 0,
            porcentajeSuplementarias: rolsState?.rolparametros?.porcentajeSuplementarias ?? 0,
            valorHoraSuplementarias: rolsState?.horarioSuple ?? 0,
            horasExtras: rolsState?.horarioEH + (rolsState?.horarioEM / 0.60) / 100 ?? 0,
            porcentajeExtra: rolsState?.rolparametros?.porcentajeExtras ?? 0,
            valorHoraExtra: rolsState?.horarioExtra ?? 0,
            comisiones: rolsState?.comisiones ?? 0,
            porcentajeAportePatronal: rolsState?.rolparametros?.aportePatronal ?? 0,
            pagoIess: rolsState?.iessPatronal ?? 0,
            porcentajeAportePersonal: rolsState?.rolparametros?.aportePersonal ?? 0,
            iess: rolsState?.iessPersonal ?? 0,
            secapPorcentaje: 0.005,
            secap: rolsState?.secap ?? 0,
            iecePorcentaje: 0.005,
            iece: rolsState?.iece ?? 0,
            ajusteSueldo: rolsState?.bonoAportable ?? 0,
            prestamos: rolsState?.prestamos ?? 0,
            bonos: rolsState?.bonos ?? 0,
            prestamosQuirografarios: rolsState?.prestamosQuirografarios ?? 0,
            prestamosHipotecarios: rolsState?.prestamosQuirografarios ?? 0,
            anticipos: rolsState?.anticipos ?? 0,
            descuentos: rolsState?.descuentos ?? 0,
            renta: rolsState?.renta ?? 0,
            fondosReserva: rolsState?.fondosReserva ?? 0,
            subsidioMaternidad: rolsState?.subsidioMaternidad ?? 0,
            subsidioEnfermedad: rolsState?.subsidioEnfermedad ?? 0,
            multas: rolsState?.multas ?? 0,
            remplazo: rolsState?.remmplazoOtros ?? 0,
            atrasos: rolsState?.atrasos ?? 0,
            bonificacion: rolsState?.bonificaciones ?? 0,
            acumularFondosReserva: rolsState?.fondosReserva ?? 0,
            nominal: rolsState?.sueldoNominal ?? 0,
            unificado: rolsState?.sbu ?? 0,
            fechaCobro: fechaCobro,
            observaciones: rolsState?.observaciones ?? '',
            vacaciones: rolsState?.vacaciones ?? 0,
            calcularNominal: rolsState?.vacacionesRg?.value ?? 0,
            pagarIess: rolsState?.contratoEmpleado?.pagarIESS ?? 0,
            decimoTercero: rolsState?.decimoTercero ?? 0,
            decimoCuarto: rolsState?.decimoCurto ?? 0,
            local: rolsState?.lugar?.codigo ?? 0,
            fpago: rolsState?.formaPago?.codigo ?? 0,
            formasPago: rolsState?.tipoPago ?? [],
            centros: rolsState?.centrosAsignados ?? []
          }
        }

        consoleService.log(save_data, 'save to data')

        const data = await RolsPaymentsServices.saveRolsPay(save_data)
        consoleService.log(data, 'data save')
        if (data?.auto && data?.error === false) {
          dispatch(setNameTab({
            key: tabId,
            codigo: data?.auto,
            nombre: rolsState?.empleado
          }))
          dispatch(setDatosEdicion({
            key: tabId,
            data: {
              ...rolsState,
              codigo: data?.auto
            }
          }))
          dispatch(
            setButtons({
              tabKey: tabId,
              buttons: {
                ...ModuleButtons,
                Guardar: false,
                Imprimir: true,
              }
            }),
          )
          await onGenrateEntryNomina(data?.auto ?? 0)
          setToast(data?.message, ToastTypes.Success)
        } else {
          setToast(data?.message, ToastTypes.Danger)
        }
      } catch (error) {
        setToast(error?.message, ToastTypes.Danger)
      }
      onLoader(false, '')
      onSetButtonAction(undefined)
    } else {
      setArrayErrors(errors)
      setShowErrorPopup(true)
    }
  }, [onValidate, rolsState, setToast, onLoader, onSetButtonAction, dispatch, tabId, onGenrateEntryNomina, padString])

  const onValidatePay = useCallback(() => {
    const errors: Array<string> = []
    if (rolsState?.codigo === 0) {
      errors.push('Es un nuevo registro.')
    }
    if (rolsState?.formaPago?.codigo === -1) {
      errors.push('Elija una forma de pago.')
    }
    if (rolsState?.formaPago?.codigo === EFormasPago.otros) {
      let saldo = rolsState?.totalPagar
      const provider = rolsState.tipoPago.slice(0)
      provider.forEach(x => {
        saldo += x?.valor
      })

      if (saldo > 0) {
        setShowFormasPago(true)
        errors.push('Establezca los valores de las distintas formas de pago')
      }
    }

    if (rolsState?.fechaPago === "00/00/0000" || rolsState?.fechaPago === "") {
      errors.push('Ingrese una fecha de pago.')
    }
    return errors
  }, [rolsState])

  const verifyPayForm = useCallback((tipopago: Array<any>) => {
    let temList = tipopago.slice(0)
    if (rolsState?.formaPago?.codigo !== EFormasPago.otros) {
      temList = []
    }
    if (rolsState?.formaPago?.codigo !== EFormasPago.otros && rolsState?.totalPagar > 0) {
      temList.push({ codigo: rolsState?.formaPago?.codigo, valor: rolsState?.totalPagar })
    }
    return temList
  }, [rolsState])

  const onPay = useCallback(async () => {
    let errors: Array<string> = []
    errors = onValidatePay()
    const result = validationRolesPay.current.instance.validate()
    if (result.isValid && errors.length === 0) {
      onSetButtonAction(ButtonTypes.salaries)
      onLoader(true, 'Guardando pago...')
      try {
        let tipoPagoArray = []
        tipoPagoArray = verifyPayForm(rolsState?.tipoPago)
        let fPago = ''
        let fLabor = ''
        if (rolsState?.fechaPago !== "" && rolsState?.fechaPago !== null) {
          fPago = DateUtils.format(rolsState?.fechaPago, 'dd/MM/yyyy', 'yyyy-MM-dd')
        }
        if (rolsState?.fechaElaboracion !== "" && rolsState?.fechaElaboracion !== null) {
          fLabor = DateUtils.format(rolsState?.fechaElaboracion, 'dd/MM/yyyy', 'yyyy-MM-dd')
        }
        const data_pay: UpdatePay = {
          infoRegistro: {
            fechaCobro: fPago,
            fechaLabor: fLabor,
            codigo: rolsState?.codigo ?? 0,
            usuario: rolsState?.empleadoCodigo ?? 0,
            formaPago: rolsState?.formaPago?.codigo ?? 0,
            sueldoMes: seleccionado?.sueldoMes ?? 0,
            tipoPago: tipoPagoArray
          }
        }

        consoleService.log(data_pay, 'data pay')
        const dataPay = await RolsPaymentsServices.updatedPay(data_pay)
        consoleService.log(dataPay, 'save pay')
        if (dataPay?.auto && dataPay?.error === false) {
          dispatch(setDatosEdicion({
            key: tabId,
            data: {
              ...rolsState,
              egresosCodigo: parseInt(dataPay?.auto),
              tipoPago: tipoPagoArray
            }
          }))
          await onGenrateEntryPay(parseInt(dataPay?.auto), tipoPagoArray)
          setToast(dataPay?.message, ToastTypes.Success)
        } else {
          setToast(dataPay?.message, ToastTypes.Danger)
        }
      } catch (error) {
        setToast(error.message, ToastTypes.Danger)
      }
      onLoader(false, '')
      onSetButtonAction(undefined)
    } else {
      setArrayErrorsPay(errors)
      setShowErrorPopupPay(true)
    }
  }, [onValidatePay, rolsState, seleccionado, verifyPayForm, setToast, onLoader, onSetButtonAction, onGenrateEntryPay, dispatch, tabId])

  const onOpenModalCheck = useCallback(() => {
    rolsState?.tipoPago?.forEach(x => {
      if (parseInt(x?.codigo) == 7 || parseInt(x?.codigo) == 114) {
        if (rolsState?.codigo !== 0) {
          setShowBankChecks(true)
        }
      }
    })
  }, [rolsState])

  const onUndo = useCallback(() => {
    dispatch(setDatosEdicion({
      key: tabId,
      data: {
        ...rolsStateBackup,
      }
    }))
  }, [dispatch, tabId, rolsStateBackup])

  const getApiData = React.useCallback(async (mode: null | "Viewer" | "Designer") => {
    onSetButtonAction(ButtonTypes.print)
    onLoader(true, 'Genrando reporte...')
    try {
      const request_search = {
        tab: 'ROL DE PAGOS',
        codigoAsociado: rolsState?.codigo,
        egresoCodigo: rolsState?.egresosCodigo,
        tipo: "ROL DE PAGOS"
      }
      const data = await DocumentsToPayService.getDataPrintPurchase(request_search)
      consoleService.log(data, 'egreso data')
      if (data !== null && data !== undefined && data['error'] === false) {
        setResultadoImprimir(data['auto'])
        setReporte(mode)
      } else {
        setToast(data['message'], ToastTypes.Danger)
        setResultadoImprimir({})
      }
    } catch (error) {
      setResultadoImprimir({})
      setToast(error.message, ToastTypes.Danger)
    }
    onLoader(false, '')
    onSetButtonAction(undefined)
  }, [setToast, rolsState, onLoader, onSetButtonAction])

  const onConfirmPrint = React.useCallback((mode: null | "Viewer" | "Designer") => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Que desea imprimir?`,
      actions: [
        Dialog.Action(
          <span>
            <u>R</u>ol
          </span>,
          (dialog) => {
            dialog.hide()
            if (rolsState?.codigo !== 0) {
              setTemplateName('StaticPaymentsRols')
              setReporte(mode)
            } else {
              setToast('No existe asiento, es un registro nuevo!!!', ToastTypes.Warning)
            }
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>E</u>greso
          </span>,
          (dialog) => {
            dialog.hide()
            if (rolsState?.egresosCodigo !== 0) {
              setTemplateName('StaticEgresosCaja')
              getApiData(mode)
            } else {
              setToast('El rol no tiene un pago asociado', ToastTypes.Warning)
            }
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    return
  }, [rolsState, setToast, getApiData])


  const handleButtonClick = useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.save:
          if (tabs.current === tabId) onSave()
          break
        case ButtonTypes.verAsiento:
          if (tabs.current === tabId) onConfirmEntry()
          break
        case ButtonTypes.salaries:
          if (tabs.current === tabId) onPay()
          break
        case ButtonTypes.check:
          if (tabs.current === tabId) onOpenModalCheck()
          break
        case ButtonTypes.payments:
          if (tabs.current === tabId) setShowFormasPago(true)
          break
        case ButtonTypes.undo:
          if (tabs.current === tabId) onUndo()
          break
        case ButtonTypes.broom:
          if (tabs.current === tabId) modoNuevo(0, true)
          break
        case ButtonTypes.print:
          if (tabs.current === tabId) onConfirmPrint('Viewer')
          break
        case ButtonTypes.print_design:
          if (tabs.current === tabId) onConfirmPrint('Designer')
          break
        default:
          break
      }
      dispatch(clearButtonClick(tabId))
    }, [dispatch, tabId, onSave, tabs, onConfirmEntry, onPay, onOpenModalCheck, onUndo, modoNuevo, onConfirmPrint])

  useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab.globalButtonClick])

  useEffect(() => {
    if (tab.editStatus === StatesEdition.new) {
      modoNuevo(tab?.info?.codigo)
    } else {
      cargarRolPago(loading, info.info)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getItemDisplayExpr = React.useCallback((item: TFormaPagoOption) => {
    return item ? `${item.descripcion}` : '';
  }, []);

  const onChangesChange = React.useCallback((changes) => {
    setChanges(changes);
  }, [])


  const onEditRowKeyChange = React.useCallback((editRowKey) => {
    setEditRowKey(editRowKey);
  }, [])

  const validateAsyncSelect = React.useCallback((datoSeleccion: any, message: string) => {
    consoleService.log(datoSeleccion, 'val')
    if (datoSeleccion?.value?.value !== "-1") {
      return Promise.resolve()
    } else {
      return Promise.reject(message)
    }
  }, [])

  const validateAsyncSeleccionMes = React.useCallback((datoSeleccion) => {
    return validateAsyncSelect(datoSeleccion, mesMessage)
  }, [validateAsyncSelect])

  const onAddCC = useCallback(() => {
    const temp = { ...rolsState }
    let existe: boolean = false
    const provider = rolsState?.centrosAsignados.slice(0)
    provider.forEach(x => {
      if (rolsState?.lugar?.codigo === x?.codigo) {
        existe = true
      }
    })
    if (!existe) {
      provider.push({
        codigo: rolsState?.lugar?.codigo ?? 0,
        nombre: rolsState?.lugar?.nombre ?? '',
        porcentaje: 0
      })
      temp.centrosAsignados = provider
      dispatch(setDatosEdicion({
        key: tabId,
        data: {
          ...temp
        }
      }))
    }
  }, [rolsState, dispatch, tabId])


  const onDeleteCC = useCallback(async () => {
    const temp = { ...rolsState }
    const provider = rolsState?.centrosAsignados.slice(0)
    const index = await getIndexProvider(provider, 'codigo', temp?.centrosAsignadosSeleccionado?.codigo ?? 0)
    if (index > -1) {
      provider.splice(index, 1)
      temp.centrosAsignados = provider
      temp.centrosAsignadosSeleccionado = null
      dispatch(setDatosEdicion({
        key: tabId,
        data: {
          ...temp
        }
      }))
    }
  }, [dispatch, tabId, rolsState])

  const setCellPercent = useCallback((rowData, value, currentRowData) => {
    const temp = { ...rolsState }
    if (currentRowData !== null && currentRowData !== undefined && value) {
      const provider = rolsState?.centrosAsignados.slice(0)
      const centros = provider.map(x => {
        if (currentRowData.codigo === x?.codigo) {
          currentRowData.porcentaje = parseInt(value)
          return currentRowData
        } else {
          return x
        }
      })
      temp.centrosAsignados = centros
      dispatch(setDatosEdicion({
        key: tabId,
        data: {
          ...temp
        }
      }))
    }
  }, [dispatch, rolsState, tabId])

  const onSeeMarckations = useCallback(() => {
    if (rolsState?.mes?.value === "-1") {
      setToast('Seleccione un mes', ToastTypes.Warning)
      return
    }
    if (rolsState?.empleadoCodigo === 0) {
      setToast('Seleccione un usuario', ToastTypes.Warning)
      return
    }

    setShowMarkations(true)
  }, [rolsState, setToast])


  const onSelectedChanged = useCallback(
    ({ selectedRowsData }) => {
      consoleService.log(selectedRowsData[0], 'seleccionado selectedRowsData[0]')
      if (selectedRowsData[0] && selectedRowsData[0]?.codigo) {
        dispatch(setDatosEdicion({
          key: tabId,
          data: {
            ...rolsState,
            centrosAsignadosSeleccionado: { ...selectedRowsData[0] }
          }
        }))
      }
    },
    [dispatch, rolsState, tabId],
  )

  const validateAsyncSelectPay = React.useCallback((datoSeleccion: any, message: string) => {
    if (datoSeleccion.value.codigo > -1) {
      return Promise.resolve()
    } else {
      return Promise.reject(message)
    }
  }, [])

  const validateAsyncSeleccionCargo = React.useCallback((datoSeleccion) => {
    return validateAsyncSelectPay(datoSeleccion, payFormdMessage)
  }, [validateAsyncSelectPay])

  const onParseData = useCallback(() => {
    const registros: Array<PaymentsRolListDto> = []
    consoleService.log(rolsState)
    let tipoRol = ''
    switch (rolsState?.tipo) {
      case 1:
        tipoRol = 'MENSUAL'
        break;
      case 2:
        tipoRol = 'QUINCENA'
        break;
      case 4:
        tipoRol = 'SEMANA'
        break;

      default:
        break;
    }
    registros.push({
      Code: rolsState?.codigo ?? 0,
      Type: tipoRol,
      Month: rolsState?.mes?.label ?? '',
      Year: rolsState.anio ?? 0,
      Employee: `${rolsState?.empleado ?? ''} (${rolsState?.empleadoCedula ?? ''})`,
      Charge: rolsState?.cargo ?? '',
      NominaSalary: rolsState?.sueldoNominal ?? 0,
      WorkDaysD: String(rolsState?.dias) ?? '',
      WorkDaysH: rolsState?.d_horas ?? '',
      WorkDaysM: rolsState?.d_min ?? '',
      SalaryReceived: rolsState?.sueldoPercibido ?? 0,
      NightH2: rolsState?.horarioNH ?? 0,
      SupH3: rolsState?.horarioSH ?? 0,
      ExtraH4: rolsState?.horarioEH ?? 0,
      VJournal: rolsState?.horarioNocturno ?? 0,
      VSup: rolsState?.horarioSuple ?? 0,
      VExtra: rolsState?.horarioExtra ?? 0,
      Commissions: rolsState?.comisiones ?? 0,
      SalaryAdjustments: rolsState?.bonoAportable ?? 0,
      Base: rolsState?.baseAporteIess ?? 0,
      Bonuses: rolsState?.bonos ?? 0,
      Bonification: rolsState?.bonificaciones ?? 0,
      Total1: rolsState?.totalIngreso ?? 0,
      Booking: rolsState?.fondosReserva ?? 0,
      Vacations: rolsState?.vacaciones ?? 0,
      Thirtheen: rolsState?.decimoTercero ?? 0,
      Fourtheen: rolsState?.decimoCurto ?? 0,
      Total4: rolsState?.totalBeneficios ?? 0,
      Total5: (rolsState?.totalIngreso + rolsState?.totalBeneficios) ?? 0,
      IESS: rolsState?.iessPatronal ?? 0,
      Subsidy: rolsState?.subsidioEnfermedad ?? 0,
      Loans: rolsState?.prestamos ?? 0,
      Advances: rolsState?.anticipos ?? 0,
      UnsecuredLoans: rolsState?.prestamosQuirografarios ?? 0,
      MortgageLoans: rolsState?.prestamosHipotecarios ?? 0,
      Discounts: rolsState?.descuentos ?? 0,
      Income: rolsState?.renta ?? 0,
      Arrears: rolsState?.atrasos ?? 0,
      Fines: rolsState?.multas ?? 0,
      Others: rolsState?.remmplazoOtros ?? 0,
      Total2: rolsState?.totalDescuentos ?? 0,
      Total3: rolsState?.totalPagar ?? 0,
      Observations: rolsState?.observaciones ?? '',
    })
    consoleService.log(registros)
    return registros
  }, [rolsState])

  const getParseDataEgreso = React.useCallback((roles: any = {}) => {
    consoleService.log(roles)
    const { asientos, egreso, compra, cheques } = roles
    const registros: Array<EgresosCajaDto> = []

    const abonosArray: Array<Abonar> = []
    let asientosArray: Array<Asientos> = []
    let chequesArray: Array<Cheques> = []
    let egresoObj: Egreso
    let chequeObj: Cheques
    let compraObj: Compra

    if (egreso !== null && egreso !== undefined) {

      egresoObj = {
        codigo: Number(egreso?.egresoCodigo) ?? 0,
        numero: egreso?.egresoNumero ?? "",
        tipo: egreso?.egresoTipo ?? "",
        asociado: Number(egreso?.egresoAsociado) ?? 0,
        aceptado: "",
        valor: Number(egreso.egresoValor) ?? null,
        fecha: egreso?.egresoFecha ?? "",
        concepto: egreso.egresoConcepto ?? "",
        estadoCodigo: Number(egreso?.egresoEstado) ?? 0,
        estadoDescripcion: egreso?.egresoEstadoDes ?? "",
        asientoNumeroTipo: egreso?.asientoNroTipo ?? "0",
        proveedorCodigo: Number(egreso?.egresoProveedorCodigo) ?? 0,
        localNombre: egreso?.egresoLocalNombre ?? "",
        localCodigo: Number(egreso?.egresoLocalCodigo ?? 0),
        asientoNumero: egreso?.asientoNumero ?? "",
        puntoVentaCodigo: egreso?.egresoPuntoCodigo ?? "",
      }
    }

    if (cheques !== null && cheques?.length > 0) {
      chequesArray = cheques?.map(x => {
        chequeObj = {
          codigo: x?.codigo ?? 0,
          numero: x?.numero ?? '',
          fechaRegistro: x?.registro ?? '',
          fechaCobro: x?.fcobro ?? '',
          cuentaBancoCodigo: x?.cbaCodigo ?? 0,
          cuentaBancoNombre: '',
          cuentaBancoNumero: x?.cbaNumero ?? '',
          bancoNombre: x?.banNombre ?? '',
          perteneceCod: '',
          perteneceDescripcion: '',
          chequePago: '',
          chequeAfavor: x?.aFavor ?? '',
          chequeAValor: '',
          xomprobante: x?.comprobante ?? '',
          formaPagoValor: x?.comprobante ?? '',
          chequePagos: x?.pagos ?? '',
          chequeValor: x?.valor ?? 0,
          fpsCodigo: x?.fpsCodigo ?? '',
          chequeEstadoCodigo: x?.estado ?? '',
          chequeEstadoDescripcion: x?.estadoDes ?? '',
          chequeTransaccion: x?.transaccion ?? '',
          chequeDescripcion: x?.descripcion ?? '',
        }
        return chequeObj
      })
    }

    if (compra !== null && compra !== undefined) {
      compraObj = {
        establecimiento: egreso?.egresoTipo ?? "",
        nombreUsuario: compra?.nombreUsuario ?? "",
        proveedorCodigo: compra?.proveedorCodigo ?? "",
        proveedorNombre: compra?.proveedorNombre ?? "",
        proveedorIdentificacion: compra?.proveedorIdentificacion ?? "",
      }
    }

    if (asientos !== null && asientos !== undefined && asientos.length > 0) {
      asientosArray = asientos.map((x) => {
        const debe = x?.transaccionDebe === '' ? 0 : Number(x?.transaccionDebe)
        const haber = x?.transaccionHaber === '' ? 0 : Number(x?.transaccionHaber)

        const asientoObj: Asientos = {
          asiNmero: 0,
          planCtaCodigo: x?.transaccionCodigo ?? 0,
          planCtaNumero: x?.planNumero ?? "",
          planCtaDescripcion: x?.planDescripcion ?? "",
          planCtaDescripcionIdioma: "",
          localCodigo: 0,
          localNombre: "",
          transaccionReferencia: x?.transaccionReferencia ?? "",
          transaccionDebe: debe,
          transaccionHaber: haber,
          siglasLocal: "",
        }

        return asientoObj
      })
    }

    const debe = !isNaN(roles?.asientoTotalDebe ?? 0) ? Number(roles?.asientoTotalDebe) : 0
    const haber = !isNaN(roles?.asientoTotalHaber ?? 0) ? Number(roles?.asientoTotalHaber) : 0

    const egresoCaja: EgresosCajaDto = {
      EgresoTipo: EgresoTipo.rol,
      egreso: egresoObj,
      compra: compraObj,
      abonos: abonosArray.length > 0 ? abonosArray : null,
      cheques: chequesArray.length > 0 ? chequesArray : null,
      asientos: asientosArray,
      formaPago: roles?.formapago ?? '',
      usuario: compraObj?.nombreUsuario ?? '',
      fecha: egresoObj?.fecha ?? '',
      elaborado: roles?.elaborado ?? '',
      modificado: '',
      concepto: egresoObj?.concepto ?? '',
      descripcion: '',
      totalDebe: debe,
      totalHaber: haber,
    }

    registros.push(egresoCaja)
    consoleService.log(registros)
    return registros
  }, [])

  return (
    <>
      <ReportWithLocalDataModal
        show={reporte !== null}
        onClose={() => setReporte(null)}
        data={templateName === 'StaticPaymentsRols' ? onParseData() : getParseDataEgreso(resultadoImprimir)}
        fileName={`${templateName}`}
        mode={reporte ?? 'Viewer'}
        parameters={{ 'Reporte_Filtro': '' }}
        template={`${templateName}`}
        key='reportDesigner'
      />
      {showFormasPago && <ModalFormasPago
        onCancel={() => setShowFormasPago(false)}
        show={showFormasPago}
        codigoRolPago={rolsState?.codigo ?? 0}
        fpagooRolPago={rolsState?.formaPago?.codigo ?? 0}
        totalPagar={rolsState?.totalPagar ?? 0}
        onSendFormasPago={(data) => {
          dispatch(setDatosEdicion({
            key: tabId,
            data: {
              ...rolsState,
              tipoPago: data
            }
          }))
        }}
      />

      }

      {showBankChecks && (
        <ModalIngresoCheques
          show={showBankChecks}
          configModal={{
            open: showBankChecks,
            name: 'Ingresar Cheque Rol',
            parametros: {
              incomeCode: rolsState?.codigo,
              tipoDocumento: 'EGRESOS BANCOS NOMINA',
              fecha: DateUtils.format(rolsState?.fechaPago, 'dd/MM/yyyy', 'yyyy-MM-dd'),
              asientoNumero: rolsState?.egresosCodigo
            },
            codModulo: modulo,
          }}
          onClose={() => {
            setShowBankChecks(false)
            onGenrateEntryPay(rolsState?.egresosCodigo, rolsState?.tipoPago)
          }}
          parametros={(evt) => consoleService.log(evt)}
          pathVerificador={''}
          ejecutaAccion={() => consoleService.log('ejecutaAccion')}
          onSelect={(evt) => consoleService.log(evt)}
        />
      )}
      {
        showModalsiento && (

          <ModalAsiento
            show={showModalsiento}
            onClose={() => setShowModalsiento(false)}
            infoAsiento={rolsState?.codigo}
            tipo={'ROL DE PAGOS'}
            origenModulo={modulo}
            onGenerateEntry={() => onGenrateEntryNomina(rolsState?.codigo)}
          />

        )
      }
      {
        showModalsientoLiq && (

          <ModalAsiento
            show={showModalsientoLiq}
            onClose={() => setShowModalsientoLiq(false)}
            infoAsiento={rolsState?.egresosCodigo}
            tipo={'LIQUIDACION ROL DE PAGOS'}
            origenModulo={modulo}
            onGenerateEntry={() => onGenrateEntryPay(rolsState?.egresosCodigo, rolsState?.tipoPago)}
          />

        )
      }
      {
        showMarkations && <ModalVerMarcaciones
          onCancel={() => setShowMarkations(false)}
          show={showMarkations}
          usuario={rolsState?.empleadoCodigo ?? 0}
          usuarioNombre={rolsState?.empleado ?? ''}
          rolparametroCodigo={rolsState?.rolparametros?.codigo ?? 0}
          htrcodigo={rolsState?.htrCodigo ?? 0}
          mes={rolsState?.mes?.value ?? ''}
          mesNombre={rolsState?.mes?.label ?? ''}
          anio={rolsState?.anio ?? 0}
        />
      }
      {showUsers && (
        <ModalUsuarios
          show={showUsers}
          onClose={() => setShowUsers(false)}
          modulo={EAplicationsAcatha.Marcacion}
          onSelectUser={(user) => {
            consoleService.log(user)
            setShowUsers(false)
            onLoadContract(user?.codigo, user?.nombre, user?.cedula)
          }}
        />
      )}
      {showErrorPopup && (
        <VisualizaError
          titulo="Error en Roles de Pago"
          mensaje={'Error al guardar'}
          content={
            <ListErrosValidationForm
              mainMessage={'Revise los siguientes errores:'}
              errorsList={arrayErrors}
              color={'danger'}
            />
          }
          onOk={() => setShowErrorPopup(false)}
        />
      )}
      {showErrorPopupPay && (
        <VisualizaError
          titulo="Error en Pago"
          mensaje={'Error al guardar'}
          content={
            <ListErrosValidationForm
              mainMessage={'Revise los siguientes errores:'}
              errorsList={arrayErrorsPay}
              color={'danger'}
            />
          }
          onOk={() => setShowErrorPopupPay(false)}
        />
      )}
      {showErrorPopupMarks && (
        <VisualizaError
          titulo="Error en Roles de Pago"
          mensaje={'Error al generar marcaciones'}
          content={
            <ListErrosValidationForm
              mainMessage={'Las marcaciones generaron los siguientes errores. Por lo que no se completarán los datos del rol automáticamente.'}
              errorsList={arrayErrorsMarks}
              color={'danger'}
            />
          }
          onOk={() => setShowErrorPopupMarks(false)}
        />
      )}
      <div style={{ overflowX: 'hidden' }}>
        <BlockUi
          tag="div"
          loader={LoadingIndicator}
          blocking={loader.show}
          message={loader.mensaje}
        >
          <RowContainer>
            <Dialog ref={dialogRef} />
            <ValidationGroup id={'formRolPago'} ref={validationRoles}>
              <RowContainer>
                {!isMobile && (
                  <CustomCol lg="6" md="8" sm="12">
                    <ValidationSummary id="summary"></ValidationSummary>
                  </CustomCol>
                )}
              </RowContainer>
              <RowContainer>
                <CustomCol xs="12" md="3">
                  <Labeled label='Mes:'>
                    <MesesLookUp
                      onChanged={(data) => cleanAll(data, 'mes')}
                      selected={rolsState?.mes ?? null}
                      disabled={rolsState?.modoEdicion}
                    >
                      <Validator>
                        <RequiredRule message={mesMessage} />
                        <AsyncRule
                          message={mesMessage}
                          validationCallback={validateAsyncSeleccionMes}
                        />
                      </Validator>
                    </MesesLookUp>
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="2">
                  <Labeled label='Año:'>
                    <TextBox
                      value={String(rolsState?.anio) ?? null}
                      onValueChange={(data) => cleanAll(parseInt(data), 'anio')}
                      disabled={rolsState?.modoEdicion}
                      maxLength={4}
                      onKeyDown={utilidades.filtraTeclasNoNumericas}
                    />
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="3">
                  <CButton
                    id="btnConfirmar"
                    color="secondary"
                    className="m-1"
                    size="sm"
                    onClick={() => onSeeMarckations()}
                  >
                    {'Marcaciones'}
                  </CButton>
                </CustomCol>
              </RowContainer>
              <RowContainer>
                <CustomCol xs="12" md="3">
                  <Labeled label='Empleado:'>
                    <div
                      style={{
                        display: 'flex',
                        gap: '2px',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >

                      <TextBox
                        value={rolsState?.empleado ?? ''}
                        onValueChange={(data) => onValueChnage(data, 'empleado')}
                      >
                        <Validator>
                          <RequiredRule message={'Personal: ' + sms['required']} />
                        </Validator>
                      </TextBox>
                      <Button
                        id="btnAdd"
                        className="me-1"
                        stylingMode="contained"
                        type="default"
                        disabled={rolsState?.modoEdicion}
                        onClick={() => {
                          if (rolsState?.mes?.value !== "-1" && rolsState?.anio !== 0) {
                            setShowUsers(true)
                          } else {
                            setToast('Debe elegir una fecha antes de elegir el empleado', ToastTypes.Warning)
                          }
                        }}
                      >{'...'}</Button>
                    </div>
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="3">
                  <Labeled label='Calcular con nominal:'>
                    <RadioGroup
                      name="calculaNomina"
                      layout="horizontal"
                      displayExpr="label"
                      dataSource={grupoVacaciones}
                      value={rolsState?.vacacionesRg ?? null}
                      onValueChange={(data) => onValueChnage(data, 'vacacionesRg')}
                    />
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="3">
                  <Labeled label='Cargo:'>
                    <TextBox readOnly value={rolsState?.cargo ?? ''} />
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="3">
                  <Labeled label='Días efectivos:'>
                    <NumberBox readOnly value={rolsState?.diasEfecivos ?? null} />
                  </Labeled>
                </CustomCol>
              </RowContainer>
              <RowContainer>
                <CustomCol xs="12" md="2">
                  <Labeled label='Sueldo Nominal:'>
                    <NumberBox
                      readOnly
                      format="#,##0.00"
                      value={rolsState?.sueldoNominal ?? null}
                    >
                      <Validator>
                        <RequiredRule
                          message={'Sueldo Nominal: ' + sms['required']}
                        />

                      </Validator>
                    </NumberBox>
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="3">
                  {
                    rolsState?.cambioOp === false ?
                      (
                        <>
                          <Labeled label='Días:'>
                            <div
                              style={{
                                display: 'flex',
                                gap: '2px',
                                alignItems: 'center',
                                width: '100%',
                              }}
                            >
                              <NumberBox
                                value={rolsState?.dias ?? null}
                                onValueChange={(data) => onChangeAndCalcSalaryDay(data, 'dias')}
                                showSpinButtons={true}
                                showClearButton={true}
                                min={1}
                                max={30}
                                disabled={rolsState?.modoEdicion}
                              />
                              <TextBox
                                value={rolsState?.d_horas ?? ''}
                                onValueChange={(data) => onChangeAndCalcSalaryDay(data, 'd_horas')}
                                onKeyDown={utilidades.filtraTeclasNoNumericas}
                                maxLength={2}
                                disabled={rolsState?.modoEdicion}
                              />
                              {':'}
                              <TextBox
                                value={rolsState?.d_min ?? ''}
                                onValueChange={(data) => onChangeAndCalcSalaryDay(data, 'd_min')}
                                onKeyDown={utilidades.filtraTeclasNoNumericas}
                                maxLength={2}
                                disabled={rolsState?.modoEdicion}
                              />
                            </div>
                          </Labeled>
                        </>
                      )
                      :
                      (
                        <>
                          <Labeled label='Horas:'>
                            <div
                              style={{
                                display: 'flex',
                                gap: '2px',
                                alignItems: 'center',
                                width: '100%',
                              }}
                            >
                              <TextBox
                                value={rolsState?.horas ?? ''}
                                onValueChange={(data) => onHoursToDaysAndHoursLab(data, 'horas')}
                                onKeyDown={utilidades.filtraTeclasNoNumericas}
                                maxLength={3}
                              />
                              {':'}
                              <TextBox
                                value={rolsState?.min ?? ''}
                                onValueChange={(data) => onHoursToDaysAndHoursLab(data, 'min')}
                                onKeyDown={utilidades.filtraTeclasNoNumericas}
                                maxLength={2}
                              />
                            </div>
                          </Labeled>
                        </>
                      )
                  }

                </CustomCol>
                <CustomCol xs="12" md="3">
                  <Labeled label=''>
                    <Button
                      id="btnAdd"
                      className="me-1"
                      stylingMode="contained"
                      type="default"
                      onClick={() => onValueChnage(false, 'cambioOp')}
                    >{'D'}</Button>
                    <Button
                      id="btnAdd"
                      className="me-1"
                      stylingMode="contained"
                      type="default"
                      onClick={() => onValueChnage(true, 'cambioOp')}
                    >{'H'}</Button>
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="3">
                  <Labeled label='Jornada:'>
                    <RadioGroup
                      name="joranda"
                      layout="horizontal"
                      displayExpr="label"
                      dataSource={grupoJornada}
                      value={rolsState?.jornada ?? null}
                      onValueChange={(data) => onActiveJournal(data)}
                      disabled={rolsState?.modoEdicion}
                    />
                  </Labeled>
                </CustomCol>
              </RowContainer>
              <RowContainer>
                {isMobile && (
                  <CustomCol lg="6" md="8" sm="12">
                    <ValidationSummary id="summary"></ValidationSummary>
                  </CustomCol>
                )}
              </RowContainer>
            </ValidationGroup>
            <RowContainer>
              <CustomCol xs="12" md="7">
                <RowContainer>
                  <RowContainer>
                    <CustomCol xs="12" md="7">
                      <RowContainer>
                        <CustomCol md="12">
                          <Labeled label='Sueldo Percibido:'>
                            <NumberBox
                              readOnly
                              value={rolsState?.sueldoPercibido ?? null}
                              onValueChange={(data) => onChangeAndCalcBAI(data, 'sueldoPercibido')}
                              width='115px'
                              format="#,##0.00"
                              inputAttr={{
                                autocomplete: 'nope',
                                class: 'textInputAlignRight',
                              }}
                            />
                          </Labeled>
                        </CustomCol>
                      </RowContainer>
                      <RowContainer>
                        <CustomCol md="12">
                          <Labeled label='H.Nocturno:'>
                            <div
                              style={{
                                display: 'flex',
                                gap: '2px',
                                alignItems: 'end',
                                width: '100%',
                              }}
                            >
                              <NumberBox
                                value={rolsState?.horarioNH ?? null}
                                showSpinButtons={true}
                                showClearButton={true}
                                disabled={rolsState?.horNH || rolsState?.modoEdicion}
                                width='115px'
                                onValueChange={(data) => onChangeAndCalcVnoct(data, 'horarioNH')}
                              />
                              {':'}
                              <NumberBox
                                value={rolsState?.horarioNM ?? null}
                                showSpinButtons={true}
                                showClearButton={true}
                                disabled={rolsState?.horNM || rolsState?.modoEdicion}
                                width='115px'
                                onValueChange={(data) => onChangeAndCalcVnoct(data, 'horarioNM')}
                              />
                              <NumberBox
                                value={rolsState?.horarioNocturno ?? null}
                                width='115px'
                                format="#,##0.00"
                                readOnly
                                onValueChange={(data) => onChangeAndCalcBAI(data, 'horarioNocturno')}
                              />
                            </div>
                          </Labeled>
                        </CustomCol>
                      </RowContainer>
                      <RowContainer>
                        <CustomCol md="12">
                          <Labeled label='H.Suplementarias:'>
                            <div
                              style={{
                                display: 'flex',
                                gap: '2px',
                                alignItems: 'end',
                                width: '100%',
                              }}
                            >
                              <NumberBox
                                value={rolsState?.horarioSH ?? null}
                                showSpinButtons={true}
                                showClearButton={true}
                                disabled={rolsState?.modoEdicion}
                                width='115px'
                                onValueChange={(data) => onChangeAndCalcVsuple(data, 'horarioSH')}
                              />
                              {':'}
                              <NumberBox
                                value={rolsState?.horarioSM ?? null}
                                showSpinButtons={true}
                                showClearButton={true}
                                disabled={rolsState?.modoEdicion}
                                width='115px'
                                onValueChange={(data) => onChangeAndCalcVsuple(data, 'horarioSM')}
                              />
                              <NumberBox
                                value={rolsState?.horarioSuple ?? null}
                                width='115px'
                                format="#,##0.00"
                                readOnly
                                onValueChange={(data) => onChangeAndCalcBAI(data, 'horarioSuple')}
                              />
                            </div>
                          </Labeled>
                        </CustomCol>
                      </RowContainer>
                      <RowContainer>
                        <CustomCol md="12">
                          <Labeled label='H.Extraordinarias:'>
                            <div
                              style={{
                                display: 'flex',
                                gap: '2px',
                                alignItems: 'end',
                                width: '100%',
                              }}
                            >
                              <NumberBox
                                value={rolsState?.horarioEH ?? null}
                                onValueChange={(data) => onChangeAndCalcVextra(data, 'horarioEH')}
                                showSpinButtons={true}
                                showClearButton={true}
                                disabled={rolsState?.modoEdicion}
                                width='115px'
                              />
                              {':'}
                              <NumberBox
                                value={rolsState?.horarioEM ?? null}
                                onValueChange={(data) => onChangeAndCalcVextra(data, 'horarioEM')}
                                showSpinButtons={true}
                                showClearButton={true}
                                disabled={rolsState?.modoEdicion}
                                width='115px'
                              />
                              <NumberBox
                                value={rolsState?.horarioExtra ?? null}
                                width='115px'
                                format="#,##0.00"
                                onValueChange={(data) => onChangeAndCalcBAI(data, 'horarioExtra')}
                              />
                            </div>
                          </Labeled>
                        </CustomCol>
                      </RowContainer>
                      <RowContainer>
                        <CustomCol md="12">
                          <Labeled label='Comisiones:'>
                            <NumberBox
                              value={rolsState?.comisiones ?? null}
                              width='115px'
                              min={0}
                              format="#,##0.00"
                              onValueChange={(data) => onChangeAndCalcBAI(data, 'comisiones')}
                              disabled={rolsState?.modoEdicion}
                            />
                          </Labeled>
                        </CustomCol>
                      </RowContainer>
                      <RowContainer>
                        <CustomCol md="12">
                          <Labeled label='Bono aportable:'>
                            <NumberBox
                              value={rolsState?.bonoAportable ?? null}
                              width='115px'
                              format="#,##0.00"
                              min={0}
                              onValueChange={(data) => onChangeAndCalcBAI(data, 'bonoAportable')}
                            />
                          </Labeled>
                        </CustomCol>
                      </RowContainer>
                      <RowContainer>
                        <CustomCol md="12">
                          <Labeled label='Base Aporte IESS:'>
                            <NumberBox
                              value={rolsState?.baseAporteIess ?? null}
                              width='115px'
                              format="#,##0.00"
                              readOnly
                            />
                          </Labeled>
                        </CustomCol>
                      </RowContainer>
                      <RowContainer>
                        <CustomCol md="12">
                          <Labeled label='Bonos:' >
                            <NumberBox
                              value={rolsState?.bonos ?? null}
                              width='115px'
                              min={0}
                              onValueChange={(data) => onChangeAndCalcTI(data, 'bonos')}
                            />
                          </Labeled>
                        </CustomCol>
                      </RowContainer>
                      <RowContainer>
                        <CustomCol md="12">
                          <Labeled label='Bonificaciones:'>
                            <NumberBox
                              value={rolsState?.bonificaciones ?? null}
                              disabled={rolsState?.modoEdicion}
                              width='115px'
                              format="#,##0.00"
                              min={0}
                              onValueChange={(data) => onChangeAndCalcTI(data, 'bonificaciones')}
                            />
                          </Labeled>
                        </CustomCol>
                      </RowContainer>
                      <RowContainer>
                        <CustomCol md="12">
                          <Labeled label='Total Ingreso:'>
                            <NumberBox
                              value={rolsState?.totalIngreso ?? null}
                              width='115px'
                              readOnly
                              format="#,##0.00"
                            />
                          </Labeled>
                        </CustomCol>
                      </RowContainer>
                      <RowContainer>
                        <CustomCol md="12">
                          <Labeled label='Vacaciones:'>
                            <NumberBox
                              value={rolsState?.vacaciones ?? null}
                              width='115px'
                              format="#,##0.00"
                              min={0}
                              onValueChange={(data) => onChangeAndCalcTI(data, 'vacaciones')}
                            />
                          </Labeled>
                        </CustomCol>
                      </RowContainer>
                      <RowContainer>
                        <CustomCol md="12">
                          <Labeled label='Fondo de reserva:'>
                            <NumberBox
                              value={rolsState?.fondosReserva ?? null}
                              width='115px'
                              format="#,##0.00"
                              min={0}
                              onValueChange={(data) => onValueChnage(data, 'fondosReserva')}
                            />
                          </Labeled>
                        </CustomCol>
                      </RowContainer>
                      <RowContainer>
                        <CustomCol md="12">
                          <Labeled label='Decimo tercero:'>
                            <NumberBox
                              value={rolsState?.decimoTercero ?? null}
                              width='115px'
                              format="#,##0.00"
                              min={0}
                              onValueChange={(data) => onValueChnage(data, 'decimoTercero')}
                            />
                          </Labeled>
                        </CustomCol>
                      </RowContainer>
                      <RowContainer>
                        <CustomCol md="12">
                          <Labeled label='Decimo cuarto:'>
                            <NumberBox
                              value={rolsState?.decimoCurto ?? null}
                              width='115px'
                              format="#,##0.00"
                              min={0}
                              onValueChange={(data) => onValueChnage(data, 'decimoCurto')}
                            />
                          </Labeled>
                        </CustomCol>
                      </RowContainer>
                      <RowContainer>
                        <CustomCol md="12">
                          <Labeled label='Total Beneficions:'>
                            <NumberBox
                              value={rolsState?.totalBeneficios ?? null}
                              format="#,##0.00"
                              width='115px'
                              min={0}
                              onValueChange={(data) => onValueChnage(data, 'totalBeneficios')}
                            />
                          </Labeled>
                        </CustomCol>
                      </RowContainer>
                    </CustomCol>
                    <CustomCol xs="12" md="5">
                      <RowContainer>
                        <CustomCol md="12">
                          <Labeled label='IESS Patronal:'>
                            <NumberBox
                              value={rolsState?.iessPatronal ?? null}
                              format="#,##0.00"
                              width='115px'
                              min={0}
                              onValueChange={(data) => onValueChnage(data, 'iessPatronal')}
                            />
                          </Labeled>
                        </CustomCol>
                      </RowContainer>
                      <RowContainer>
                        <CustomCol md="12">
                          <Labeled label='SECAP:'>
                            <NumberBox
                              value={rolsState?.secap ?? null}
                              format="#,##0.00"
                              width='115px'
                              min={0}
                              onValueChange={(data) => onChangeAndCalcDisc(data, 'secap')}
                              disabled={rolsState?.modoEdicion}
                            />
                          </Labeled>
                        </CustomCol>
                      </RowContainer>
                      <RowContainer>
                        <CustomCol md="12">
                          <Labeled label='IECE:'>
                            <NumberBox
                              value={rolsState?.iece ?? null}
                              format="#,##0.00"
                              width='115px'
                              min={0}
                              onValueChange={(data) => onChangeAndCalcDisc(data, 'iece')}
                              disabled={rolsState?.modoEdicion}
                            />
                          </Labeled>
                        </CustomCol>
                      </RowContainer>
                      <hr />
                      <RowContainer>
                        <CustomCol md="12">
                          <Labeled label='IESS Personal:'>
                            <NumberBox
                              value={rolsState?.iessPersonal ?? null}
                              width='115px'
                              format="#,##0.00"
                              min={0}
                              onValueChange={(data) => onChangeAndCalcDisc(data, 'iessPersonal')}
                            />
                          </Labeled>
                        </CustomCol>
                      </RowContainer>
                      <RowContainer>
                        <CustomCol md="12">
                          <Labeled label='Subsidio por maternidad:'>
                            <NumberBox
                              value={rolsState?.subsidioMaternidad ?? null}
                              width='115px'
                              format="#,##0.00"
                              min={0}
                              onValueChange={(data) => onChangeAndCalcDisc(data, 'subsidioMaternidad')}
                              disabled={rolsState?.modoEdicion}
                            />
                          </Labeled>
                        </CustomCol>
                      </RowContainer>
                      <RowContainer>
                        <CustomCol md="12">
                          <Labeled label='Subsidio por enfermedad:'>
                            <NumberBox
                              value={rolsState?.subsidioEnfermedad ?? null}
                              width='115px'
                              format="#,##0.00"
                              min={0}
                              onValueChange={(data) => onChangeAndCalcDisc(data, 'subsidioEnfermedad')}
                              disabled={rolsState?.modoEdicion}
                            />
                          </Labeled>
                        </CustomCol>
                      </RowContainer>
                      <RowContainer>
                        <CustomCol md="12">
                          <Labeled label='Prestamos:'>
                            <NumberBox
                              value={rolsState?.prestamos ?? null}
                              width='115px'
                              format="#,##0.00"
                              min={0}
                              onValueChange={(data) => onChangeAndCalcDisc(data, 'prestamos')}
                              disabled={rolsState?.modoEdicion}
                            />
                          </Labeled>
                        </CustomCol>
                      </RowContainer>
                      <RowContainer>
                        <CustomCol md="12">
                          <Labeled label='Anticipos:'>
                            <NumberBox
                              value={rolsState?.anticipos ?? null}
                              width='115px'
                              format="#,##0.00"
                              min={0}
                              onValueChange={(data) => onChangeAndCalcDisc(data, 'anticipos')}
                              disabled={rolsState?.modoEdicion}
                            />
                          </Labeled>
                        </CustomCol>
                      </RowContainer>
                      <RowContainer>
                        <CustomCol md="12">
                          <Labeled label='Prestamos Quirografarios:'>
                            <NumberBox
                              value={rolsState?.prestamosQuirografarios ?? null}
                              width='115px'
                              format="#,##0.00"
                              min={0}
                              onValueChange={(data) => onChangeAndCalcDisc(data, 'prestamosQuirografarios')}
                              disabled={rolsState?.modoEdicion}
                            />
                          </Labeled>
                        </CustomCol>
                      </RowContainer>
                      <RowContainer>
                        <CustomCol md="12">
                          <Labeled label='Prestamos Hipotecarios:'>
                            <NumberBox
                              value={rolsState?.prestamosHipotecarios ?? null}
                              width='115px'
                              format="#,##0.00"
                              min={0}
                              onValueChange={(data) => onChangeAndCalcDisc(data, 'prestamosHipotecarios')}
                              disabled={rolsState?.modoEdicion}
                            />
                          </Labeled>
                        </CustomCol>
                      </RowContainer>
                      <RowContainer>
                        <CustomCol md="12">
                          <Labeled label='Descuentos:'>
                            <NumberBox
                              value={rolsState?.descuentos ?? null}
                              format="#,##0.00"
                              width='115px'
                              min={0}
                              onValueChange={(data) => onChangeAndCalcDisc(data, 'descuentos')}
                              disabled={rolsState?.modoEdicion}
                            />
                          </Labeled>
                        </CustomCol>
                      </RowContainer>
                      <RowContainer>
                        <CustomCol md="12">
                          <Labeled label='Renta:'>
                            <NumberBox
                              value={rolsState?.renta ?? null}
                              width='115px'
                              format="#,##0.00"
                              min={0}
                              onValueChange={(data) => onChangeAndCalcDisc(data, 'renta')}
                              disabled={rolsState?.modoEdicion}
                            />
                          </Labeled>
                        </CustomCol>
                      </RowContainer>
                      <RowContainer>
                        <CustomCol md="12">
                          <Labeled label='Multas:'>
                            <NumberBox
                              value={rolsState?.multas ?? null}
                              width='115px'
                              format="#,##0.00"
                              min={0}
                              onValueChange={(data) => onChangeAndCalcDisc(data, 'multas')}
                              disabled={rolsState?.modoEdicion}
                            />
                          </Labeled>
                        </CustomCol>
                      </RowContainer>
                      <RowContainer>
                        <CustomCol md="12">
                          <Labeled label='Atrasos:'>
                            <NumberBox
                              value={rolsState?.atrasos ?? null}
                              width='115px'
                              format="#,##0.00"
                              min={0}
                              onValueChange={(data) => onChangeAndCalcDisc(data, 'atrasos')}
                              disabled={rolsState?.modoEdicion}
                            />
                          </Labeled>
                        </CustomCol>
                      </RowContainer>
                      <RowContainer>
                        <CustomCol md="12">
                          <Labeled label='Reemplazo u Otros:'>
                            <NumberBox
                              value={rolsState?.remmplazoOtros ?? null}
                              width='115px'
                              format="#,##0.00"
                              min={0}
                              onValueChange={(data) => onChangeAndCalcDisc(data, 'remmplazoOtros')}
                              disabled={rolsState?.modoEdicion}
                            />
                          </Labeled>
                        </CustomCol>
                      </RowContainer>
                      <RowContainer>
                        <CustomCol md="12">
                          <Labeled label='Total Descuentos:'>
                            <NumberBox
                              value={rolsState?.totalDescuentos ?? null}
                              width='115px'
                              format="#,##0.00"
                              readOnly
                            />
                          </Labeled>
                        </CustomCol>
                      </RowContainer>
                      <RowContainer>
                        <CustomCol md="12">
                          <Labeled label='Total a Pagar:'>
                            <NumberBox
                              value={rolsState?.totalPagar ?? null}
                              width='115px'
                              readOnly
                              format="#,##0.00"
                              className='mb-2'
                            />
                          </Labeled>
                        </CustomCol>
                      </RowContainer>
                    </CustomCol>
                  </RowContainer>
                </RowContainer>
              </CustomCol>
              <CustomCol xs="12" md="5">
                <ValidationGroup id={'formRolPagoForm'} ref={validationRolesPay}>
                  {!isMobile && (
                    <CustomCol lg="6" md="8" sm="12">
                      <ValidationSummary id="summary"></ValidationSummary>
                    </CustomCol>
                  )}
                  <RowContainer>
                    <RowContainer>
                      <CustomCol md="12">
                        <Labeled label="Lugar o CC:">
                          <div
                            style={{
                              display: 'flex',
                              gap: '2px',
                              alignItems: 'center',
                              width: '100%',
                            }}
                          >
                            <LocalesLookUp
                              onChanged={(data) => onValueChnage(data, 'lugar')}
                              onChangedOptions={() => { }}
                              selected={rolsState?.lugar ?? null}
                            />
                            <Button
                              id="btnAdd"
                              className="me-1"
                              icon="add"
                              stylingMode="contained"
                              type="default"
                              onClick={() => onAddCC()}
                            />
                            <Button
                              id="btnRemove"
                              icon="trash"
                              stylingMode="contained"
                              type="danger"
                              disabled={rolsState?.centrosAsignadosSeleccionado === null}
                              onClick={() => onDeleteCC()}
                            />
                          </div>
                          <DataGrid
                            style={{ marginTop: '10px' }}
                            id="codigo"
                            focusedRowKey="codigo"
                            selection={{ mode: 'single' }}
                            dataSource={rolsState?.centrosAsignados}
                            showColumnLines={true}
                            showRowLines={true}
                            repaintChangesOnly
                            showBorders={true}
                            columnResizingMode="widget"
                            onSelectionChanged={onSelectedChanged}
                            loadPanel={{
                              enabled: true,
                              height: 90,
                              indicatorSrc: '',
                              shading: false,
                              shadingColor: '',
                              showIndicator: true,
                              showPane: true,
                              text: 'Cargando...',
                              width: 200,
                            }}
                          >
                            <Editing
                              mode="cell"
                              allowUpdating
                              changes={changes}
                              onChangesChange={onChangesChange}
                              editRowKey={editRowKey}
                              onEditRowKeyChange={onEditRowKeyChange}
                            />
                            <Paging defaultPageSize={10} />
                            <Pager
                              visible={rolsState?.centrosAsignados.length > 0 ? true : false}
                              allowedPageSizes={getAllowedPageSizes(rolsState?.centrosAsignados)}
                              displayMode={'full'}
                              showPageSizeSelector={true}
                              showInfo={true}
                              showNavigationButtons={true}
                            />
                            <Column
                              allowEditing={false}
                              dataType='string'
                              dataField="nombre"
                              caption="CC"
                              width="100%"
                            />
                            <Column
                              allowEditing={true}
                              dataType='number'
                              dataField="porcentaje"
                              setCellValue={setCellPercent}
                              caption="Porcentaje"
                              width="100%"
                            />
                          </DataGrid>
                        </Labeled>
                      </CustomCol>
                    </RowContainer>
                    <RowContainer>
                      <CustomCol md="12">
                        <Labeled label="Forma de Pago:">
                          <SelectBox
                            id='TFormaPagoOptionSelect'
                            items={rolsState?.listaFormasPagos ?? []}
                            searchEnabled
                            displayExpr={getItemDisplayExpr}
                            searchExpr={['descripcion']}
                            searchTimeout={500}
                            value={rolsState?.formaPago ?? null}
                            onValueChange={(data) => onValueChnage(data, 'formaPago')}
                          >
                            <Validator>
                              <RequiredRule message={payFormdMessage} />
                              <AsyncRule
                                message={payFormdMessage}
                                validationCallback={validateAsyncSeleccionCargo}
                              />
                            </Validator>
                          </SelectBox>
                        </Labeled>
                      </CustomCol>
                    </RowContainer>
                    <RowContainer>
                      <CustomCol md="12">
                        <Labeled label='Fecha de registro:'>
                          <DateBox
                            value={rolsState?.fechaRegistro ?? null}
                            readOnly
                            disabled={true}
                          />
                        </Labeled>
                      </CustomCol>
                    </RowContainer>
                    <RowContainer>
                      <CustomCol md="12">
                        <Labeled label='Fecha de elaboracion:'>
                          <DateBox
                            value={rolsState?.fechaElaboracion ?? null}
                            readOnly
                            disabled={true}
                          />
                        </Labeled>
                      </CustomCol>
                    </RowContainer>
                    <RowContainer>
                      <CustomCol md="12">
                        <Labeled label='Fecha de pago:'>
                          <DateBox
                            value={rolsState?.fechaPago ?? null}
                            onValueChange={(data) => onValueChnage(data, 'fechaPago')}
                          >
                            <Validator>
                              <RequiredRule message={'Fecha de Pago: ' + sms['required']} />
                            </Validator>
                          </DateBox>
                        </Labeled>
                      </CustomCol>
                    </RowContainer>
                    <RowContainer>
                      <CustomCol md="12">
                        <Labeled label='Observaciones:'>
                          <TextArea
                            height={200}
                            value={rolsState?.observaciones ?? ''}
                            onValueChange={(data) => onValueChnage(data, 'observaciones')}
                            maxLength={600}
                            showClearButton
                          />
                        </Labeled>
                      </CustomCol>
                    </RowContainer>
                  </RowContainer>
                  {isMobile && (
                    <CustomCol lg="6" md="8" sm="12">
                      <ValidationSummary id="summary"></ValidationSummary>
                    </CustomCol>
                  )}
                </ValidationGroup>
              </CustomCol>
            </RowContainer>
          </RowContainer>
        </BlockUi>
      </div>
    </>
  )
}

export default React.memo(Nuevo)


export const defaultRolesPagoEdicion: RolesPadoDatosEdicion = {
  codigo: 0,
  tipo: 0,
  htrCodigo: 0,
  egresosCodigo: 0,
  mes: null,
  anio: 0,
  lugar: null,
  contratoEmpleado: null,
  rolparametros: null,
  listaFormasPagos: [],
  centrosAsignados: [],
  tipoPago: [],
  centrosAsignadosSeleccionado: null,
  empleado: '',
  empleadoCodigo: 0,
  empleadoCedula: '',
  sbu: 0,
  minTrabajados: 0,
  cargo: '',
  vacacionesRg: null,
  sueldoNominal: null,
  dias: 0,
  diasEfecivos: null,
  cambioOp: false,
  d_horas: '',
  d_min: '',
  horas: '000',
  min: '00',
  jornada: null,
  nocturnoHora: null,
  nocturnoMin: null,
  supleHora: null,
  supleMin: null,
  extraHora: null,
  extraMin: null,
  sueldoPercibido: null,
  horarioNocturno: null,
  horarioNH: 0,
  horarioNM: 0,
  horarioSuple: null,
  horarioSH: 0,
  horarioSM: 0,
  horarioExtra: null,
  horarioEH: 0,
  horarioEM: 0,
  comisiones: null,
  bonoAportable: null,
  baseAporteIess: null,
  bonos: null,
  bonificaciones: null,
  totalIngreso: null,
  vacaciones: null,
  fondosReserva: null,
  decimoTercero: null,
  decimoCurto: null,
  totalBeneficios: null,
  iessPatronal: null,
  secap: null,
  iece: null,
  iessPersonal: null,
  subsidioMaternidad: null,
  subsidioEnfermedad: null,
  prestamos: null,
  anticipos: null,
  prestamosQuirografarios: null,
  prestamosHipotecarios: null,
  descuentos: null,
  horasMes: 240,
  horasDias: 0,
  horasMesParcial: 0,
  renta: null,
  multas: null,
  atrasos: null,
  remmplazoOtros: null,
  totalDescuentos: null,
  totalPagar: null,
  formaPago: null,
  modoEdicion: false,
  fechaRegistro: null,
  fechaPago: null,
  fechaElaboracion: null,
  observaciones: '',
  loading: false,
  horNH: true,
  horNM: true,
  loader: {
    show: false,
    mensaje: '',
  },
}