import React from 'react'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import { DataGrid } from 'devextreme-react'
import { utilidades } from '../../../../../../../helpers/utilidades'
import { Column, Pager, Paging } from 'devextreme-react/data-grid'
import { getAllowedPageSizes } from '../../../../../../../helpers/Helper'
import { consoleService } from '../../../../../../../services/console.service'
import { SmallLoadingIndicator } from '../../../../../../../views/componentes/loadingindicator/loadingindicator'
import { WorkingHour } from '../../../../../../componentes/modalHorariosTrabajo/types/type'
import { HorariosTrabajosServices } from '../../../../../../componentes/modalHorariosTrabajo/services/horariosTrabajo.services'

const DetalleHorarios: React.FunctionComponent<any> = (props) => {
  const { data } = props


  const [loading, setLoading] = React.useState(false)
  const [loaded, setLoaded] = React.useState(false)
  const [horas, setHoras] = React.useState<WorkingHour[] | []>([])



  const onLoadDetail = React.useCallback(async (hourData) => {
    setLoading(true)
    consoleService.log(hourData, 'detail hour')
    const dataHoras = await HorariosTrabajosServices.loadHour(hourData?.codigo ?? 0, false)
    if (dataHoras?.auto && dataHoras?.error === false) {
      setHoras(dataHoras?.auto)
    } else {
      setHoras([])
    }
    setLoading(false)
    setLoaded(true)
  }, [])

  React.useEffect(() => {
    onLoadDetail(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) {
    return <SmallLoadingIndicator mensaje="Cargando información adicional del registro..." />
  }

  if (!loaded) {
    return <></>
  }


  return (
    <RowContainer className="tabla-busqueda-modVentas-resumen">
      <DataGrid
        keyExpr={['codigo']}
        dataSource={horas ?? []}
        focusedRowEnabled
        hoverStateEnabled
        showBorders
        width={utilidades.getGridFullWidth()}
      >
        <Paging defaultPageSize={10} />
        <Pager
          visible={true}
          allowedPageSizes={getAllowedPageSizes([])}
          displayMode={'full'}
          showPageSizeSelector={true}
          showInfo={true}
          showNavigationButtons={true}
        />
        <Column dataField="descripcion" caption="Día" width="100%" />
        <Column dataField="horaEntrada" caption="Hora de Entrada" width="100%" />
        <Column dataField="horaSalida" caption="Hora de Salida" width="100%" />

      </DataGrid>
    </RowContainer>
  )
}

export default React.memo(DetalleHorarios)