import { DateUtils, formatoFechasApi } from '../../../../helpers/dateUtils'
import { RequestHelper } from '../../../../helpers/requestHelper'
import { consoleService } from '../../../../services/console.service'
import { SesionService } from '../../../../services/sesion.service'
import {
  TiposComprobantesSLV,
  TiposComprobantesSri,
} from '../../../../store/types'
import { VentasService } from '../../services/ventas.service'

export const VentasHelper = {
  comprobarAutorizacionYPuntoDeventa,
  getVoucherTypeFromAccesKey,
  comprobanteIva,
  getVoucherTypeFromAccesKeySV,
}

async function comprobarAutorizacionYPuntoDeventa(
  puntoVenta: string | null,
  tipoComprobante: TiposComprobantesSri,
  setConfigurarPv,
  setConfigurarAutorizacion,
  setMensajeAutorizacion,
) {
  if (puntoVenta == null) {
    setConfigurarPv(true)
    return false
  }
  try {
    const autorizacion = await VentasService.getAutorizacion(
      puntoVenta,
      tipoComprobante,
    )
  } catch (error) {
    setConfigurarAutorizacion(true)
    setMensajeAutorizacion(
      typeof error == 'string' ? error : JSON.stringify(error),
    )
    return false
  }

  return true
}

function getFormatDate(voucherType: TiposComprobantesSri, infoComprobante) {
  let date: any = ''
  let pos: any = ''
  let date1: any = ''
  switch (voucherType) {
    case TiposComprobantesSri.NotaCredito:
      consoleService.log(
        'NotaCredito',
        DateUtils.strDateToDate(infoComprobante.invoiceDate, formatoFechasApi),
      )
      pos = String(infoComprobante.invoiceDate).split('T')
      consoleService.log('pos ', pos)
      date1 = new Date(String(pos[0]))
      consoleService.log(date1)
      consoleService.log('1', DateUtils.strDateToDate(pos[0], formatoFechasApi))
      date = DateUtils.dateToString(date1, formatoFechasApi)
      break
    case TiposComprobantesSri.NotaDebito:
      consoleService.log(
        'NotaDebito',
        DateUtils.strDateToDate(infoComprobante.invoiceDate, formatoFechasApi),
      )
      pos = String(infoComprobante.invoiceDate).split('T')
      consoleService.log('pos ', pos)
      date1 = new Date(String(pos[0]))
      consoleService.log(date1)
      consoleService.log('1', DateUtils.strDateToDate(pos[0], formatoFechasApi))
      date = DateUtils.dateToString(date1, formatoFechasApi)
      break
    case TiposComprobantesSri.Factura:
      consoleService.log(
        'Factura',
        DateUtils.strDateToDate(infoComprobante.invoiceDate, formatoFechasApi),
      )
      pos = String(infoComprobante.invoiceDate).split('T')
      consoleService.log('pos ', pos)
      date1 = new Date(String(pos[0]))
      consoleService.log(date1)
      date = DateUtils.dateToString(date1, formatoFechasApi)
      break
    case TiposComprobantesSri.Retencion:
      consoleService.log(
        'Retencion',
        DateUtils.strDateToDate(infoComprobante.date, formatoFechasApi),
      )
      pos = String(infoComprobante.date).split('T')
      consoleService.log('pos ', pos)
      date1 = new Date(String(pos[0]))
      consoleService.log(date1)
      date = DateUtils.dateToString(date1, formatoFechasApi)
      break
    case TiposComprobantesSri.GuiaRemision:
      consoleService.log(
        'GuiaRemision',
        DateUtils.strDateToDate(infoComprobante.date, formatoFechasApi),
      )
      pos = String(infoComprobante.invoiceDate).split('T')
      consoleService.log('pos ', pos)
      date1 = new Date(String(pos[0]))
      consoleService.log(date1)
      date = DateUtils.dateToString(date1, formatoFechasApi)
      break
    case TiposComprobantesSri.LiquidacionCompra:
      consoleService.log(
        'LiquidacionCompra',
        DateUtils.strDateToDate(infoComprobante.invoiceDate, formatoFechasApi),
      )
      pos = String(infoComprobante.date).split('T')
      consoleService.log('pos ', pos)
      date1 = new Date(String(pos[0]))
      consoleService.log(date1)
      date = DateUtils.dateToString(date1, formatoFechasApi)
      break
    default:
      break
  }
  return date
}

async function comprobanteIva(
  invoiceData: any,
  voucherType: TiposComprobantesSri,
) {
  const dateVoucher = getFormatDate(voucherType, invoiceData)
  const sesion = SesionService.getCurrentSesion()
  try {
    const data: any = {
      fecha: dateVoucher,
      establecimiento: invoiceData.establishment,
      paisCodigo: sesion?.local?.ciudad?.paisCodigo ?? 1,
    }
    const dataComprobante = await RequestHelper.get<any>(
      'ventas',
      'ivaEstablecimiento',
      '',
      data,
    )
    return dataComprobante
  } catch (error) {
    return false
  }
}

function getVoucherTypeFromAccesKeySV(
  accesKey: string,
): TiposComprobantesSLV | undefined {
  if (accesKey && accesKey.length > 0) {
    const voucherType = accesKey.substr(4, 2) // DTE-01
    switch (voucherType) {
      case '01':
        return TiposComprobantesSLV.Factura
      case '03':
        return TiposComprobantesSLV.CreditoFiscal
      case '04':
        return TiposComprobantesSLV.NotaRemision
      case '05':
        return TiposComprobantesSLV.NotaCredito
      case '06':
        return TiposComprobantesSLV.NotaDebito
      case '07':
        return TiposComprobantesSLV.Retencion
      case '08':
        return TiposComprobantesSLV.Liquidacion
      case '09':
        return TiposComprobantesSLV.LiquidacionContable
      case '11':
        return TiposComprobantesSLV.FacturaExportacion
      case '14':
        return TiposComprobantesSLV.FacturaExcluidoSujeto
      default:
        break
    }
  }
  return undefined
}

function getVoucherTypeFromAccesKey(
  accesKey: string,
): TiposComprobantesSri | undefined {
  if (accesKey && accesKey.length === 49) {
    const voucherType = accesKey.substr(8, 2)
    switch (voucherType) {
      case '01':
        return TiposComprobantesSri.Factura
      case '03':
        return TiposComprobantesSri.LiquidacionCompra
      case '04':
        return TiposComprobantesSri.NotaCredito
      case '05':
        return TiposComprobantesSri.NotaDebito
      case '06':
        return TiposComprobantesSri.GuiaRemision
      case '07':
        return TiposComprobantesSri.Retencion
      default:
        break
    }
  }
  return undefined
}
