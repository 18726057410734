import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EdicionPayload } from '../../../../store/types'
import { CombinacionesDatosEdicion } from '../types/types'
import { CustomDictionary } from '../../../../../../store/types'

const initialState: CustomDictionary<CombinacionesDatosEdicion> = {}

const datosEditSlice = createSlice({
  name: 'combinacionesDatosEdicion',
  initialState: initialState,
  reducers: {
    deleteEditData(state, action: PayloadAction<string>) {
      delete state[action.payload]
    },
    setDatosEdicion(
      state,
      action: PayloadAction<{
        key: string
        inventario: CombinacionesDatosEdicion
      }>,
    ) {
      const { key, inventario } = action.payload
      state[key].codigo = inventario.codigo
      state[key].numero = inventario.numero
      state[key].nombre = inventario.nombre
      state[key].resultadoCodigo = inventario.resultadoCodigo
      state[key].resultadodescripcion = inventario.resultadodescripcion
      state[key].componentes = inventario.componentes
      state[key].item = inventario.item
      state[key].interno = inventario.interno
      state[key].stock = inventario.stock
      state[key].unidad = inventario.unidad
      state[key].guardado = inventario.guardado
    },
    initDatosEdicion(
      state,
      action: PayloadAction<EdicionPayload<CombinacionesDatosEdicion>>,
    ) {
      if (!state[action.payload.key]) {
        state[action.payload.key] = action.payload.data
      }
    },
  },
})

export const { setDatosEdicion, initDatosEdicion, deleteEditData } =
  datosEditSlice.actions
export const dataEditReducer = datosEditSlice.reducer
