import React from 'react'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import { CheckBox, DataGrid } from 'devextreme-react'
import { Column, ColumnChooser, Export, Pager, Paging } from 'devextreme-react/data-grid'
import CustomCol from '../../../../../../views/componentes/colContainer'
import ItemsCountIndicator from '../../../../../componentes/indicadorNroRegistros'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/store'
import { changeFilter } from '../../store/serachReducer'
import { Workbook } from 'exceljs'
import { exportDataGrid } from 'devextreme/excel_exporter';
import { saveAs } from 'file-saver';
import { getAllowedPageSizes } from '../../../../../../helpers/Helper'
import { consoleService } from '../../../../../../services/console.service'
import ModalProcedencia from '../../../kardex/components/modalProcedencia'
import { ReportWithLocalDataModal } from '../../../../../../views/componentes/xtrareports/ReportWithLocalDataModal'
import { KardexSerieDto } from '../../types/types'


interface ISearchProps extends React.PropsWithChildren {
  reporte: null | 'Viewer' | 'Designer'
  setReporte: (data: null | 'Viewer' | 'Designer') => void
}

const KardexSerie: React.FunctionComponent<ISearchProps> = (props) => {
  const { reporte, setReporte } = props
  const dataGridKardexSerie = React.useRef<any>()
  const dispatch = useDispatch()
  const [resultados, setResultados] = React.useState([])
  const [selected, setSelected] = React.useState(null)
  const [showProcedencia, setShowProcedencia] = React.useState<boolean>(false)

  const searchFilter = useSelector((state: RootState) => {
    return state.inventarios.series.search.filter
  })

  const searchResults = useSelector((state: RootState) => {
    return state.inventarios.series.search.resultList
  })

  const getFilterString = React.useCallback(() => {
    const filterText = ''
    return filterText
  }, [])

  const onGetData = React.useCallback(() => {
    if (searchResults !== null && searchResults !== null && searchResults['auto'] && searchResults['auto'].length > 0) {
      let newArray = []
      let cant: number = 0
      newArray = searchResults['auto'].map(x => {
        if (x?.tipo === 9) {
          cant += 1
          return {
            ...x,
            stock: cant,
            cantidadIngresos: 1,
          }
        } else if (x?.tipo === 10) {
          cant -= 1
          return {
            ...x,
            stock: cant,
            cantidadEgresos: 1,
          }
        } else if (x?.tipo === 11) {
          cant += 1
          return {
            ...x,
            stock: cant,
            cantidadIngresos: 1,
          }
        }
      })
      if (!searchFilter.verBodegaTransito) {
        newArray = newArray.filter(x => x?.local > 0)
      }
      setResultados(newArray)
    } else {
      setResultados([])
    }
  }, [searchResults, searchFilter?.verBodegaTransito])

  React.useEffect(() => {
    onGetData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResults, searchFilter?.verBodegaTransito])

  const onSelectedSeriesChanged = React.useCallback(({ selectedRowsData }) => {
    consoleService.log('selected ', selectedRowsData[0])
    setSelected(selectedRowsData[0])
  }, [])

  const onDBSelected = React.useCallback(() => {
    consoleService.log(selected)
    setShowProcedencia(true)
  }, [selected])

  const onExportExcell = React.useCallback((e) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('Main sheet')
    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Kardex_Serie.xlsx')
      });
    });
  }, [])

  const onParseData = React.useCallback(
    (kardexSeries: Array<any> = []) => {
      let registros: Array<KardexSerieDto> = []
      // console.log(kardexSeries)
      let registro: KardexSerieDto = null
      if (kardexSeries.length > 0) {
        registros = kardexSeries.map((x) => {
          registro = {
            Record: x?.registro ?? '',
            KardexDescription: x?.kardexDescripcion ?? '',
            Local: x?.localNombre ?? '',
            Line: x?.lineaDescripcion ?? '',
            Unit: x?.unidadNombre ?? '',
            Income: x?.cantidadIngresos ?? 0,
            egress: x?.cantidadEgresos ?? 0,
            Stock: x?.stock ?? 0,
          }
          return registro
        })
      }
      consoleService.log(registros)
      return registros
    },
    [],
  )

  return (
    <>
      <ReportWithLocalDataModal
        show={reporte !== null}
        onClose={() => setReporte(null)}
        data={onParseData(resultados)}
        fileName="StaticKardexSerie"
        mode={reporte ?? 'Viewer'}
        parameters={{ Reporte_Filtro: getFilterString() }}
        template="StaticKardexSerie"
        key="reportDesigner"
      />
      {
        showProcedencia && (<ModalProcedencia
          show={showProcedencia}
          onClose={() => setShowProcedencia(false)}
          codeKardex={selected?.codigo ?? 0}
        />)
      }
      <RowContainer>
        <CustomCol xs="12" md="12" className="mt-2 mb-2">
          <CheckBox
            text="Visualizar Bodega en Tránsito"
            value={searchFilter?.verBodegaTransito ?? null}
            onValueChanged={(data) => {
              if (data.event !== null && data.event !== undefined) {
                dispatch(changeFilter({
                  ...searchFilter,
                  verBodegaTransito: data?.value
                }))
              }
            }}
          />
        </CustomCol>
        <ItemsCountIndicator items={resultados} />
        <DataGrid
          ref={dataGridKardexSerie}
          selection={{ mode: 'single' }}
          hoverStateEnabled={true}
          dataSource={resultados}
          showBorders={true}
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          onSelectionChanged={onSelectedSeriesChanged}
          onRowDblClick={onDBSelected}
          onExporting={onExportExcell}
          keyExpr="codigo"
          key="gridListItems"
        >
          <Paging defaultPageSize={20} />
          <Pager
            visible={resultados.length > 0}
            allowedPageSizes={getAllowedPageSizes(resultados)}
            displayMode={'full'}
            showPageSizeSelector={true}
            showInfo={true}
            showNavigationButtons={true}
          />
          <ColumnChooser enabled={true} mode="select" />
          <Export enabled={true} allowExportSelectedData={true} />
          <Column visible={false} dataField="itemCodigo" width={0} />
          <Column
            allowEditing={false}
            dataField="registro"
            caption="Fecha"
            width="100px"
          />
          <Column
            allowEditing={false}
            dataField="kardexDescripcion"
            caption="Movimiento"
            width="300px"
          />
          <Column
            allowEditing={false}
            dataField="localNombre"
            caption="Local"
            width="100px"
          />
          <Column
            allowEditing={false}
            dataField="lineaDescripcion"
            caption="Línea"
            width="100px"
          />
          <Column
            allowEditing={false}
            dataField="unidadNombre"
            caption="Unidad"
            width="100px"
          />
          <Column
            allowEditing={false}
            dataField="cantidadIngresos"
            caption="Ingresos"
            width="100px"
          />
          <Column
            allowEditing={false}
            dataField="cantidadEgresos"
            caption="Egresos"
            width="100px"
          />
          <Column
            allowEditing={false}
            dataField="stock"
            caption="Stock"
            width="100px"
          />
        </DataGrid>
      </RowContainer>
    </>
  )
}

export default React.memo(KardexSerie)
