import React from 'react'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import { IngresosEgresosDatosEdicion, MovimientoTipo } from '../../types/types'
import { ButtonTypes } from '../../../../../../../views/componentes/globalMenu/types'
import { clearButtonClick, openTab, setCurrentExecutingAction } from '../../store/tabsReducer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import {
  changeLoaderGroups,
  setCurrentFunction,
} from '../../store/generalReducer'
import { changeCurrentTab, setResetSeleccion, setResultados, setVerRegistro } from '../../store/serachReducer'
import {
  CCard,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
} from '@coreui/react-pro'
import Cabecera from './cabecera'
import Detalle from './detalle'
import { TabState } from '../../../../../../../store/genericTabTypes'
import Base from './base'
import Consolidado from './consolidado'
import FiltroBusqueda from './filtroBusqueda'
import { Draggable, SpeedDialAction } from 'devextreme-react'
import { FETCH_STATUS, ToastTypes } from '../../../../../../../store/types'
import { DateUtils } from '../../../../../../../helpers/dateUtils'
import { addToast } from '../../../../../../../store/toasterReducer'
import { incomeExpensesServices } from '../../services/ingresosEgresos.services'
import { getIndexProvider } from '../../../../../../shared/helpers/funciones'
import { v4 as uuidv4 } from 'uuid'
import { initDatosEdicion } from '../../store/editDataReducer'
import { defaultDataIncomeExpenses } from "../nuevo/index"
import BlockUi from '../../../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../../../views/componentes/loadingindicator/loadingindicator'
import { isMobile } from 'react-device-detect'
import TableLoader from '../../../../../../ventas/components/ventas/busquedaVentas/TableLoader'
import Dialog from '../../../../../../../views/componentes/librerias/bootstrap-dialog'
import { MessagesKeys, lh } from '../../../../../../../helpers/localizationHelper'

enum VistasBusqueda {
  Filtros,
  ResultadosBusqueda,
  Resumen,
  Loading,
  Error
}
export const optionsActivos = [
  { value: 1, label: 'Activos' },
  { value: 2, label: 'Inactivos' },
]

interface ISearchProps extends React.PropsWithChildren {
  tab: TabState<IngresosEgresosDatosEdicion>
  tabId: string
}

const draggingGroupName = 'appointmentsGroup';
const Search: React.FunctionComponent<ISearchProps> = (props) => {
  const { tabId, tab } = props
  const dialogRef = React.useRef<any>(null)

  const dispatch = useDispatch()
  const estadoBusqueda = useSelector((state: RootState) => state.inventarios.movimientos.ingresosEgresos.search.status)
  const currentTab = useSelector((state: RootState) => state.inventarios.movimientos.ingresosEgresos.tabs.current)
  const movementsLoader = useSelector((state: RootState) => state.inventarios.movimientos.ingresosEgresos.general)
  const searchResults = useSelector((state: RootState) => state.inventarios.movimientos.ingresosEgresos.search.resultados)
  const selected = useSelector((state: RootState) => state.inventarios.movimientos.ingresosEgresos.search.seleccionado)
  const currentTabState = useSelector((state: RootState) => state.inventarios.movimientos.ingresosEgresos.search)
  const [vistaActual, setVistaActual] = React.useState<VistasBusqueda>(VistasBusqueda.Filtros)
  const [confirmDelete, setConfirmDelete] = React.useState<boolean>(false)
  const [confirmEdit, setConfirmEdit] = React.useState<boolean>(false)

  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(
      addToast({
        title: 'Inventario-Ingresos/Egresos',
        content: mensaje,
        type: tipo,
      }),
    )
  }, [dispatch])
  const playLoader = React.useCallback((message = 'Cargando...') => {
    dispatch(changeLoaderGroups({ show: true, mensaje: message }))
  }, [dispatch])

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoaderGroups({ show: false, mensaje: '' }))
  }, [dispatch])


  const deleteItemTabla = React.useCallback(async () => {
    if (selected !== null && selected !== undefined) {
      if (searchResults !== null && searchResults !== undefined && searchResults['auto'].length > 0) {
        const provider = searchResults['auto'].slice(0);
        const index = await getIndexProvider(provider, 'codigo', selected.codigo)
        if (index > -1) {
          provider.splice(index, 1)
          const payload: any = {
            error: false,
            auto: provider
          }
          dispatch(setResultados(payload))
          dispatch(setResetSeleccion())
        }
      }
    }
  }, [selected, dispatch, searchResults])

  const onHandleRemove = React.useCallback(async () => {
    try {
      dispatch(setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: ButtonTypes.delete
      }))
      const delete_data = {
        codigo: selected?.codigo ?? 0,
        fecha: selected?.fecha ?? DateUtils.getCurrentDateAsString('dd/MM/yyyy')
      }
      if (isMobile) {
        setVistaActual(VistasBusqueda.Loading)
      } else {
        playLoader('Eliminando Regsitro...')
      }
      const data = await incomeExpensesServices.deleteIncomeExpense(delete_data)
      if (isMobile) {
        setVistaActual(VistasBusqueda.ResultadosBusqueda)
      } else {
        stopLoader()
      }
      if (data !== null && data !== undefined && data['error'] === false) {
        deleteItemTabla()
        setToast(data['message'], ToastTypes.Success)
      } else {
        setToast(data['message'], ToastTypes.Danger)
      }
    } catch (error) {
      if (isMobile) {
        setVistaActual(VistasBusqueda.ResultadosBusqueda)
      } else {
        stopLoader()
      }
      setToast(error.message, ToastTypes.Danger)
    }

    dispatch(setCurrentExecutingAction({
      tabKey: tabId,
      buttonType: undefined
    }))
  }, [selected, setToast, deleteItemTabla, dispatch, tabId, playLoader, stopLoader])


  const onAbrir = React.useCallback((ingreso: MovimientoTipo | any) => {
    const id = uuidv4();
    dispatch(initDatosEdicion({
      key: id,
      data: {
        ...defaultDataIncomeExpenses,
        loading: true,
      }
    }));
    const d = { ...defaultDataIncomeExpenses }
    d.codigo = ingreso.codigo
    d.descripcion = ingreso.descripcion
    d.documento = ingreso.documento
    dispatch(openTab({ key: id, ingresoEgreso: ingreso }));
  }, [dispatch])

  const onConfirmDelete = React.useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea Eliminar el Registro: ${selected?.documento ?? '-'}?`,
      actions: [
        Dialog.Action(
          <span>
            <u>A</u>ceptar
          </span>,
          (dialog) => {
            dialog.hide()
            onHandleRemove()
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setConfirmDelete(false)
    return
  }, [onHandleRemove, selected])

  const onConfirmEdit = React.useCallback(() => {
    const sms = lh.getMessage(MessagesKeys.FormConfirmChangeForm);
    const sms_replace = sms.replace("@dinamic_var@", selected?.documento ?? '-');
    dialogRef.current.show({
      title: <><strong>{"Ácatha"}</strong></>,
      body: <label style={{ fontSize: "14px", fontWeight: 600 }}>{sms_replace}</label>,
      actions: [
        Dialog.Action(
          <span><u>{"A"}</u>{"ceptar"}</span>,
          (dialog) => {
            dispatch(setVerRegistro(false))
            onAbrir(selected)
            dialog.hide();
          },
          'btn-success',
          'n'
        ),
        Dialog.Action(
          <span><u>{"C"}</u>{"ancelar"}</span>,
          (dialog) => {
            dialog.hide();
          },
          'btn-danger',
          'c'
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide();
      }
    });
    setConfirmEdit(false)
    return;
  }, [selected, onAbrir, dispatch]);

  const handleButtonClick = React.useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.delete:
          setConfirmDelete(true)
          break
        case ButtonTypes.edit:
          setConfirmEdit(true)
          break
        default:
          break
      }
      dispatch(setCurrentFunction(''))
      dispatch(clearButtonClick(tabId))
    },
    [dispatch,
      tabId],
  )

  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab.globalButtonClick]);

  React.useEffect(() => {
    if (confirmDelete) {
      onConfirmDelete()
    }
  }, [confirmDelete, onConfirmDelete])

  React.useEffect(() => {
    if (confirmEdit) {
      onConfirmEdit()
    }
  }, [confirmEdit, onConfirmEdit])

  React.useEffect(() => {
    switch (estadoBusqueda) {
      case FETCH_STATUS.IDDLE:
        setVistaActual(VistasBusqueda.Filtros);
        break;
      case FETCH_STATUS.LOADING:
        setVistaActual(VistasBusqueda.Loading);
        break;
      case FETCH_STATUS.SUCCESS:
        setVistaActual(VistasBusqueda.ResultadosBusqueda);
        break;
      case FETCH_STATUS.FAILED:
        setVistaActual(VistasBusqueda.Error);
        break;
      default:
        break;
    }
    // if (estadoBusqueda === FETCH_STATUS.IDDLE || estadoBusqueda === FETCH_STATUS.FAILED) {
    //   setVistaActual(VistasBusqueda.Loading);
    // } else {
    //   setVistaActual(false);
    // }
  }, [estadoBusqueda])

  if (isMobile) {
    return (
      <>
        <CCard>

          <div>
            <Dialog ref={dialogRef} />
          </div>
          {vistaActual === VistasBusqueda.Filtros &&

            (
              <>
                < FiltroBusqueda key='filtroBusqueda' />
                <CNav variant="tabs" role="tablist" className="mt-4">
                  <CNav variant="tabs">
                    {currentTabState.tabs.map((tab, index) => (
                      <CNavItem key={index}>
                        <CNavLink
                          onClick={() =>
                            dispatch(changeCurrentTab(currentTabState.tabs[index]))
                          }
                          active={currentTabState.currentTab === tab}
                        >
                          {tab}
                        </CNavLink>
                      </CNavItem>
                    ))}
                  </CNav>
                </CNav>
              </>
            )
          }
          {vistaActual === VistasBusqueda.ResultadosBusqueda &&
            (<>
              <CTabContent
                style={{
                  overflowY: 'unset',
                  width: '100%',
                  marginBottom: '10px',
                }}
              >
                <CTabPane
                  role="tabpanel"
                  aria-labelledby="cabecera-tab"
                  visible={currentTabState.currentTab === currentTabState.tabs[0]}
                >
                  <Cabecera onDelete={() => setConfirmDelete(true)} onDBEdit={() => setConfirmEdit(true)} onSee={(data) => onAbrir(data)} />
                </CTabPane>
                <CTabPane
                  role="tabpanel"
                  aria-labelledby="detalle-tab"
                  visible={currentTabState.currentTab === currentTabState.tabs[1]}
                >
                  <Detalle />
                </CTabPane>
                <CTabPane
                  role="tabpanel"
                  aria-labelledby="base-tab"
                  visible={currentTabState.currentTab === currentTabState.tabs[2]}
                >
                  <Base />
                </CTabPane>
                <CTabPane
                  role="tabpanel"
                  aria-labelledby="consolidado-tab"
                  visible={currentTabState.currentTab === currentTabState.tabs[3]}
                >
                  <Consolidado />
                </CTabPane>
              </CTabContent>
            </>)
          }
          {vistaActual === VistasBusqueda.Error &&
            <></>
          }
          {vistaActual === VistasBusqueda.Loading &&
            <TableLoader />
          }
          {
            currentTab === tabId && (
              <Draggable
                id="list"
                data="dropArea"
                group={draggingGroupName}
              >
                <SpeedDialAction
                  icon="filter"
                  label='Filtros'
                  visible={true}
                  index={1}
                  onClick={() => {
                    setVistaActual(VistasBusqueda.Filtros);
                  }}
                />
                <SpeedDialAction
                  icon="search"
                  label='Busqueda'
                  visible={true}
                  index={2}
                  onClick={() => {
                    setVistaActual(VistasBusqueda.ResultadosBusqueda);
                  }}
                />
              </Draggable>
            )
          }
        </CCard>
      </>
    )
  }

  return (
    <>
      <div>
        <Dialog ref={dialogRef} />
      </div>
      <div id='ingEgresoContent'>
        <BlockUi
          tag="div"
          loader={LoadingIndicator}
          blocking={movementsLoader.loader.show}
          message={movementsLoader.loader.mensaje}
        >
          <CCard>
            <FiltroBusqueda />
            <RowContainer className='me-1 ms-1'>
              <CNav variant="tabs" role="tablist" className="mt-4">
                <CNav variant="tabs">
                  {currentTabState.tabs.map((tab, index) => (
                    <CNavItem key={index}>
                      <CNavLink
                        onClick={() =>
                          dispatch(changeCurrentTab(currentTabState.tabs[index]))
                        }
                        active={currentTabState.currentTab === tab}
                      >
                        {tab}
                      </CNavLink>
                    </CNavItem>
                  ))}
                </CNav>
                <CTabContent
                  style={{
                    overflowY: 'unset',
                    width: '100%',
                    marginBottom: '10px',
                  }}
                >
                  <CTabPane
                    role="tabpanel"
                    aria-labelledby="cabecera-tab"
                    visible={currentTabState.currentTab === currentTabState.tabs[0]}
                  >
                    <Cabecera onSee={(data) => onAbrir(data)} onDelete={() => setConfirmDelete(true)} onDBEdit={() => setConfirmEdit(true)} />
                  </CTabPane>
                  <CTabPane
                    role="tabpanel"
                    aria-labelledby="detalle-tab"
                    visible={currentTabState.currentTab === currentTabState.tabs[1]}
                  >
                    <Detalle />
                  </CTabPane>
                  <CTabPane
                    role="tabpanel"
                    aria-labelledby="base-tab"
                    visible={currentTabState.currentTab === currentTabState.tabs[2]}
                  >
                    <Base />
                  </CTabPane>
                  <CTabPane
                    role="tabpanel"
                    aria-labelledby="consolidado-tab"
                    visible={currentTabState.currentTab === currentTabState.tabs[3]}
                  >
                    <Consolidado />
                  </CTabPane>
                </CTabContent>
              </CNav>
            </RowContainer>
          </CCard>
        </BlockUi>
      </div>
    </>
  )
}

export default React.memo(Search)
