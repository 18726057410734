import React from 'react';
import SelectBox from 'devextreme-react/select-box';
import { empresasService } from './service/empresas.service';
import { TEmpresasOption } from './store/type';
import { utilidades } from '../../../helpers/utilidades';

interface EmpresasLookUpProps extends React.PropsWithChildren {
  selected: TEmpresasOption | null,
  onChanged: (newValue: TEmpresasOption | null) => void,
  onChangedOptions: (newValue: Array<TEmpresasOption> | []) => void,
  disabled?: boolean,
}

const EmpresasLookUp: React.FC<EmpresasLookUpProps> = (props) => {
  const { selected, onChanged, disabled, onChangedOptions } = props;
  const selectRef = React.useRef<any>();
  const [options, setOptions] = React.useState<Array<TEmpresasOption> | []>([]);

  const loadDatos = React.useCallback(async () => {
    const data = await empresasService.getEmpresas("Elija una opción");
    if (data.error === false) {
      let opt = [];
      if (data.auto.length > 0) {
        opt = data.auto.map(x => {
          const item: TEmpresasOption = {
            codigo: x?.valor ?? '',
            descripcion: utilidades.capText(x?.nombre) ?? '',
          };
          return item;
        });
      }
      setOptions(opt)
      onChangedOptions(opt)
      const selectedIndex = opt.findIndex((option) => {
        return option.codigo === selected?.codigo
      })
      onChanged(opt[selectedIndex] ?? opt[0])
    }

  }, [onChanged, onChangedOptions, selected]);

  const getItemDisplayExpr = React.useCallback((item: TEmpresasOption) => {
    return item ? `${item.descripcion}` : '';
  }, []);

  const onLookUpValueChanged = React.useCallback((evt: any) => {
    if (evt.event !== undefined) {
      onChanged(evt.value);
    }
  }, [onChanged]);

  const onSelectBoxFocusIn = React.useCallback((e) => {
    e.element.querySelector("input.dx-texteditor-input").select();
  }, [])

  React.useEffect(() => {
    loadDatos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <SelectBox
      id='empresasSelect'
      ref={selectRef}
      items={options ?? []}
      searchEnabled
      displayExpr={getItemDisplayExpr}
      searchExpr={['descripcion']}
      searchTimeout={500}
      onEnterKey={(e) => { console.log(e) }}
      value={selected}
      onValueChanged={onLookUpValueChanged}
      disabled={disabled}
      onFocusIn={onSelectBoxFocusIn}
    >
      {props.children}
    </SelectBox>
  )

}
export default React.memo(EmpresasLookUp);
