import React from 'react'
import { ClientesListado } from '../../../../types/types'
import {
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
} from '@coreui/react-pro'
import Datosgenerales from '../datosgenerales'
import { ToastTypes } from '../../../../../../../../store/types'
import Sucursales from '../sucursales'
import Ventas from '../ventas'
import Historicos from '../historicos'
import Adicionales from '../adicionales'
import Red from '../red'
import Lineas from '../lineas'
import Grupos from '../grupos'
import Marcas from '../marcas'
import Uafe from '../uafe'
import Main from '../main'
import { TabState } from '../../../../../../../../store/genericTabTypes'

interface ITabsEditionsProps {
  tabId: string
  tab: TabState<ClientesListado>
  setToast: (texto: string, type: ToastTypes) => void
  refForm: any
}

export enum TabsClientes {
  datosGenerales = 'Datos Generales',
  sucursales = 'Sucursales',
  ventas = 'Ventas',
  historico = 'Históricos',
  adicionales = 'Adicionales',
  red = 'Red',
  lineas = 'Lí­neas',
  grupos = 'Grupos',
  marcas = 'Marcas',
  uafe = 'UAFE',
}

const tabsNames: Array<string> = [
  TabsClientes.datosGenerales,
  TabsClientes.sucursales,
  TabsClientes.ventas,
  TabsClientes.historico,
  TabsClientes.adicionales,
  TabsClientes.red,
  TabsClientes.lineas,
  TabsClientes.grupos,
  TabsClientes.marcas,
  TabsClientes.uafe,
]

const TabsEdition: React.FunctionComponent<ITabsEditionsProps> = (props) => {
  const { refForm, tabId, setToast } = props

  const [showTab, setShowTab] = React.useState<string>(tabsNames[0])

  return (
    <>
      <Main tabId={tabId} />
      <CNav variant="tabs" role="tablist" className="mt-4">
        <CNav variant="tabs">
          {tabsNames.map((tab, index) => (
            <CNavItem key={index}>
              <CNavLink
                onClick={() => {
                  setShowTab(tab)
                }}
                active={showTab === tab}
              >
                {tab}
              </CNavLink>
            </CNavItem>
          ))}
        </CNav>
        <CTabContent
          style={{
            overflowY: 'unset',
            width: '100%',
            marginBottom: '10px',
          }}
        >
          <CTabPane
            role="tabpanel"
            aria-labelledby="generales-tab"
            visible={showTab === tabsNames[0]}
          >
            <Datosgenerales tabId={tabId} refForm={refForm} />
          </CTabPane>
          <CTabPane
            role="tabpanel"
            aria-labelledby="sucursales-tab"
            visible={showTab === tabsNames[1]}
          >
            <Sucursales tabId={tabId} setToast={setToast} refForm={refForm} />
          </CTabPane>
          <CTabPane
            role="tabpanel"
            aria-labelledby="ventas-tab"
            visible={showTab === tabsNames[2]}
          >
            <Ventas tabId={tabId} refForm={refForm} />
          </CTabPane>
          <CTabPane
            role="tabpanel"
            aria-labelledby="historicos-tab"
            visible={showTab === tabsNames[3]}
          >
            <Historicos tabId={tabId} refForm={refForm} />
          </CTabPane>
          <CTabPane
            role="tabpanel"
            aria-labelledby="adicionales-tab"
            visible={showTab === tabsNames[4]}
          >
            <Adicionales tabId={tabId} refForm={refForm} />
          </CTabPane>
          <CTabPane
            role="tabpanel"
            aria-labelledby="red-tab"
            visible={showTab === tabsNames[5]}
          >
            <Red tabId={tabId} refForm={refForm} />
          </CTabPane>
          <CTabPane
            role="tabpanel"
            aria-labelledby="lineas-tab"
            visible={showTab === tabsNames[6]}
          >
            <Lineas tabId={tabId} setToast={setToast} refForm={refForm} />
          </CTabPane>
          <CTabPane
            role="tabpanel"
            aria-labelledby="grupos-tab"
            visible={showTab === tabsNames[7]}
          >
            <Grupos tabId={tabId} setToast={setToast} refForm={refForm} />
          </CTabPane>
          <CTabPane
            role="tabpanel"
            aria-labelledby="marcas-tab"
            visible={showTab === tabsNames[8]}
          >
            <Marcas tabId={tabId} setToast={setToast} refForm={refForm} />
          </CTabPane>
          <CTabPane
            role="tabpanel"
            aria-labelledby="uafe-tab"
            visible={showTab === tabsNames[9]}
          >
            <Uafe tabId={tabId} refForm={refForm} />
          </CTabPane>
        </CTabContent>
      </CNav>
    </>
  )
}

export default React.memo(TabsEdition)
