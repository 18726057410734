import { DateUtils, formatoFechasApi } from "../../../../../../helpers/dateUtils";
import { ColInfo, ExportarExcelService as EES, FielTypes } from "../../../../../../services/exportarExcel.service";
import { SesionService } from "../../../../../../services/sesion.service";

export const ExportarExcelService = {
  exportarArchivo
}

const cols: Array<ColInfo> = [
  {
    "fieldName": "fechaEmision",
    "fieldTitle": "Fecha de Emisión",
    "order": 1,
    "datatype": FielTypes.String
  },
  {
    "fieldName": "numerofactura",
    "fieldTitle": "Número de Factura",
    "order": 2,
    "datatype": FielTypes.String
  },
  {
    "fieldName": "tipoComprobante",
    "fieldTitle": "Tipo de Comprobante",
    "order": 3,
    "datatype": FielTypes.String
  },
  {
    "fieldName": "clienteNombre",
    "fieldTitle": "Cliente",
    "order": 4,
    "datatype": FielTypes.String
  },
  {
    "fieldName": "baseImponible",
    "fieldTitle": "Base Imponible",
    "order": 5,
    "datatype": FielTypes.Decimal
  },
  {
    "fieldName": "tipoIva",
    "fieldTitle": "Tipo de IVA",
    "order": 6,
    "datatype": FielTypes.String
  },
  {
    "fieldName": "importeIva",
    "fieldTitle": "Importe del IVA",
    "order": 7,
    "datatype": FielTypes.Decimal
  },
  {
    "fieldName": "totalFacturado",
    "fieldTitle": "Total Facturado",
    "order": 8,
    "datatype": FielTypes.Decimal
  },
];

function exportarArchivo(exportarDatos) {
  const sesion = SesionService.getCurrentSesion();

  let response: any = '';

  try {
    response = EES.exportarExcel({
      cols: cols,
      data: exportarDatos,
      emptyRowsAfterTitles: 0,
      exportedBy: `${sesion.usuario.nombres} ${sesion.usuario.apellidos}`,
      sheetName: 'Bancos',
      titulos: [
        sesion.empresa.comercial,
        `FECHA:${DateUtils.getCurrentDateAsString(formatoFechasApi)}`,
        "EXPRESADO EN USD"
      ]
    })
  } catch (error) {
    console.error(error)
  }
  return response;

}
