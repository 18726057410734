import React from 'react'
import { SelectBox } from 'devextreme-react'
import { staticLookUpCliente } from '../lookupZonasGeograficas/store/types'

interface ProfesionesLookupProps extends React.PropsWithChildren {
  selected: staticLookUpCliente | null
  onChanged: (newValue: staticLookUpCliente | null) => void
  disabled?: boolean
  provider: Array<staticLookUpCliente> | []
}

const ProfesionesLookup: React.FC<ProfesionesLookupProps> = (props) => {
  const { provider, selected, onChanged, disabled } = props
  const selectRef = React.useRef<any>()
  const [options, setOptions] = React.useState<Array<staticLookUpCliente> | []>([]);

  const loadDatos = React.useCallback(async () => {
    if (provider !== undefined && provider.length > 0) {
      setOptions(provider)
      const selectedIndex = provider.findIndex((select: staticLookUpCliente) => {
        return select.codigo === selected?.codigo
      })
      onChanged(provider[selectedIndex] ?? provider[0])
      if (selectedIndex > -1) {
        onChanged(provider[selectedIndex] ?? provider[0])
      }
    } else {
      const opt = profesiones
      setOptions(opt)
      const selectedIndex = opt.findIndex((option) => {
        return option.codigo === selected?.codigo
      })
      if (selectedIndex > -1) {
        onChanged(opt[selectedIndex] ?? opt[0])
      }
    }
  }, [onChanged, provider, selected]);

  const onLookUpValueChanged = React.useCallback(
    (evt: any) => {
      if (evt.event !== undefined) {
        onChanged(evt.value)
      }
    },
    [onChanged])

  const onSelectBoxFocusIn = React.useCallback((e) => {
    e.element.querySelector('input.dx-texteditor-input').select()
  }, []);

  const refreshSelected = React.useCallback(async () => {
    if (options && options.length > 0) {
      const selectedIndex = await options.findIndex((option: staticLookUpCliente) => {
        return option.codigo === selected?.codigo
      })
      onChanged(options[selectedIndex] ?? options[0])
    }
  }, [onChanged, options, selected])

  React.useEffect(() => {
    loadDatos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (selected !== null) {
      refreshSelected()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])
  return (
    <SelectBox
      id="zonaGeograficaSelect"
      ref={selectRef}
      items={profesiones ?? []}
      searchEnabled
      displayExpr={'descripcion'}
      searchExpr={['descripcion']}
      searchTimeout={500}
      onEnterKey={(e) => {
        console.log(e)
      }}
      value={selected}
      onValueChanged={onLookUpValueChanged}
      disabled={disabled}
      onFocusIn={onSelectBoxFocusIn}
    >
      {props.children}
    </SelectBox>
  )
}

export default React.memo(ProfesionesLookup)

export const profesiones: Array<staticLookUpCliente> = [
  {
    codigo: 0,
    descripcion: 'Elija una opción',
    porcentaje: 0,
    natural: 10,
    juridica: 0,
  },
  {
    codigo: 1,
    descripcion: 'Abogado',
    porcentaje: 100,
    natural: 10,
    juridica: 0,
  },
  {
    codigo: 2,
    descripcion: 'Analista de Sistemas',
    porcentaje: 100,
    natural: 10,
    juridica: 0,
  },
  {
    codigo: 3,
    descripcion: 'Arquitecto',
    porcentaje: 30,
    natural: 10,
    juridica: 0,
  },
  {
    codigo: 4,
    descripcion: 'Artista',
    porcentaje: 40,
    natural: 10,
    juridica: 0,
  },
  {
    codigo: 5,
    descripcion: 'Asesor (Profesional)',
    porcentaje: 60,
    natural: 10,
    juridica: 0,
  },
  {
    codigo: 6,
    descripcion: 'Auditor',
    porcentaje: 60,
    natural: 100,
    juridica: 0,
  },
  {
    codigo: 7,
    descripcion: 'Azafata',
    porcentaje: 60,
    natural: 60,
    juridica: 0,
  },
  {
    codigo: 8,
    descripcion: 'Biologo Marino',
    porcentaje: 30,
    natural: 10,
    juridica: 0,
  },
  {
    codigo: 9,
    descripcion: 'Chofer',
    porcentaje: 30,
    natural: 10,
    juridica: 0,
  },
  {
    codigo: 10,
    descripcion: 'Cocinero',
    porcentaje: 25,
    natural: 10,
    juridica: 0,
  },
  {
    codigo: 11,
    descripcion: 'Comerciante',
    porcentaje: 50,
    natural: 10,
    juridica: 0,
  },
  {
    codigo: 12,
    descripcion: 'Comercio Internacional',
    porcentaje: 50,
    natural: 10,
    juridica: 0,
  },
  {
    codigo: 13,
    descripcion: 'Comunicador Social',
    porcentaje: 75,
    natural: 10,
    juridica: 0,
  },
  {
    codigo: 14,
    descripcion: 'Contador',
    porcentaje: 75,
    natural: 10,
    juridica: 0,
  },
  {
    codigo: 15,
    descripcion: 'Contratista',
    porcentaje: 40,
    natural: 10,
    juridica: 0,
  },
  {
    codigo: 16,
    descripcion: 'Cosmetologo',
    porcentaje: 25,
    natural: 10,
    juridica: 0,
  },
  {
    codigo: 17,
    descripcion: 'Decorador de Interiores',
    porcentaje: 25,
    natural: 10,
    juridica: 0,
  },
  {
    codigo: 18,
    descripcion: 'Dentista',
    porcentaje: 25,
    natural: 10,
    juridica: 0,
  },
  {
    codigo: 19,
    descripcion: 'Diseñador',
    porcentaje: 30,
    natural: 10,
    juridica: 0,
  },
  {
    codigo: 20,
    descripcion: 'Ebanista',
    porcentaje: 30,
    natural: 10,
    juridica: 0,
  },
  {
    codigo: 21,
    descripcion: 'Economista',
    porcentaje: 50,
    natural: 10,
    juridica: 0,
  },
  {
    codigo: 22,
    descripcion: 'Educador',
    porcentaje: 30,
    natural: 10,
    juridica: 0,
  },
  {
    codigo: 23,
    descripcion: 'Electricista',
    porcentaje: 50,
    natural: 10,
    juridica: 0,
  },
  {
    codigo: 24,
    descripcion: 'Empleado Bancario',
    porcentaje: 70,
    natural: 10,
    juridica: 0,
  },
  {
    codigo: 25,
    descripcion: 'Enfermera',
    porcentaje: 30,
    natural: 10,
    juridica: 0,
  },
  {
    codigo: 26,
    descripcion: 'Estilista',
    porcentaje: 25,
    natural: 10,
    juridica: 0,
  },
  {
    codigo: 27,
    descripcion: 'Estudiante',
    porcentaje: 30,
    natural: 10,
    juridica: 0,
  },
  {
    codigo: 28,
    descripcion: 'Fotografo',
    porcentaje: 30,
    natural: 10,
    juridica: 0,
  },
  {
    codigo: 29,
    descripcion: 'Geologo',
    porcentaje: 25,
    natural: 10,
    juridica: 0,
  },
  {
    codigo: 30,
    descripcion: 'Ingeniero',
    porcentaje: 50,
    natural: 10,
    juridica: 0,
  },
  {
    codigo: 31,
    descripcion: 'Jubilado',
    porcentaje: 30,
    natural: 10,
    juridica: 0,
  },
  {
    codigo: 32,
    descripcion: 'Mecanico',
    porcentaje: 40,
    natural: 10,
    juridica: 0,
  },
  {
    codigo: 33,
    descripcion: 'Mecanico Técnico',
    porcentaje: 40,
    natural: 10,
    juridica: 0,
  },
  {
    codigo: 34,
    descripcion: 'Medico',
    porcentaje: 30,
    natural: 10,
    juridica: 0,
  },
  {
    codigo: 35,
    descripcion: 'Nutricionista',
    porcentaje: 30,
    natural: 10,
    juridica: 0,
  },
  {
    codigo: 36,
    descripcion: 'Optómetra',
    porcentaje: 30,
    natural: 10,
    juridica: 0,
  },
  {
    codigo: 37,
    descripcion: 'Periodista',
    porcentaje: 60,
    natural: 10,
    juridica: 0,
  },
]
