import React from 'react';
import { TipoComprobante } from '../../../../store/types';
import SelectBox from '../../../../views/componentes/selectBox/selectBox';
import { TiposComprobantesService } from '../../services/TiposComprobantes.service';

interface ISelectBuscarTiposComporbantesProps extends React.PropsWithChildren {
  selected: TipoComprobante | null,
  onChanged: (newValue: TipoComprobante) => void,
  readOnly?: boolean
}

export const SelectBuscarTiposComprobantes: React.FC<ISelectBuscarTiposComporbantesProps> = (props) => {
  const { selected, onChanged, readOnly } = props;
  const [tiposComprobantes, setTiposComprobantes] = React.useState<Array<TipoComprobante>>([]);


  const cargarTiposComprobantes = React.useCallback(async () => {
    const tiposComprobantes = await TiposComprobantesService.getAll();
    setTiposComprobantes(tiposComprobantes);
  }, []);

  React.useEffect(() => {
    cargarTiposComprobantes();
  }, [cargarTiposComprobantes]);

  return (
    <SelectBox
      displayExpr='comprobante'
      id='busquedaTipoComprobantes'
      keyExpr='codigo'
      onChange={onChanged}
      placeholder='Tipo de comprobante'
      selected={selected}
      options={tiposComprobantes}
      minSearchLength={0}
      clearButton
      disabled={readOnly}
    >
      {props.children}
    </SelectBox>
  );
};
