import React, { memo } from 'react';
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer';
import CustomCol from '../../../../../../views/componentes/colContainer';
import { CCol, CRow, CTooltip, CLink, CBadge } from '@coreui/react-pro'
import CIcon from '@coreui/icons-react';
import {
  Column,
  DataGrid,
  FilterRow,
  HeaderFilter,
  Paging,
  LoadPanel,
  Pager
} from 'devextreme-react/data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../store/store';
import { v4 as uuidv4 } from 'uuid';
import { ProveedoresService } from '../../../../services/proveedores.service';
import { ProveedorSearchGrid } from '../../../../store/types';
import { changeLoader } from '../../../../../../store/reducers';
import { cilInfo } from '@coreui/icons';

type infoPaginationType = {
  desde: number,
  hasta: number,
  paginaActual: number,
  totalPaginas: number,
  totalRegistros: number
}

type findItems = {
  descripcion: string,
  codigo: string
}

const infoPaginationInit = {
  desde: 1,
  hasta: 10,
  paginaActual: 0,
  totalPaginas: 0,
  totalRegistros: 0
}

const infoFind = {
  descripcion: "",
  codigo: ""
}

interface ITablaProveedoresProps extends React.PropsWithChildren {
  name: string,
  capturaSeleccion: any,
  filaSeleccionada: any,
  actionDelete: any,
  funcion: string,
  returnItem: (item) => void,
  resetFunction: () => void,
  pagination: infoPaginationType,
  dataSearch: any,
  setDataItems: (data) => void,
  dataProveedores: any
}

const Tabla = (props: ITablaProveedoresProps) => {

  const dispatch = useDispatch();

  const tablaRef = React.useRef<any>();
  const tablaRefPager = React.useRef<any>();

  const { funcion, returnItem, pagination, dataSearch, resetFunction, setDataItems, dataProveedores } = props;
  const loader = useSelector((state: RootState) => state.global.loader);

  const [data, setData] = React.useState<any>([])
  const [infoPagination, setInfoPagination] = React.useState<infoPaginationType>(infoPaginationInit);
  const [pageSize, setPageSize] = React.useState<number>(10)
  const [pageIndex, setPageIndex] = React.useState<number>(10)
  // const [dataSeacrh, setDataSeacrh] = React.useState<findItems>(infoFind)

  // React.useEffect(() => {
  //   setDataSeacrh(dataSearch)
  // }, [dataSearch]);

  React.useEffect(() => {
    setInfoPagination(pagination)
  }, [pagination]);

  React.useEffect(() => {
    if (funcion === 'Deshacer') {
      setData([])
    } else if (funcion === 'Buscar') {
      onResetTab();
      resetFunction()
      setInfoPagination(infoPaginationInit)
      onSearch(dataSearch)
    }
  }, [funcion]);

  React.useEffect(() => {
    setData(dataProveedores);
  }, [dataProveedores])

  const selectionChanged = (selectionChanged) => {
    console.log('selectionChanged', selectionChanged)
    console.log('data', data._items)
    if (data && data._items && data._items.length > 0) {
      if (selectionChanged['selectedRowsData'].length > 0) {

        const filtroImpresora: number = data._items.findIndex((item: any) => {
          return Number(item.codigo) === Number(selectionChanged['selectedRowsData'][0]['codigo']);
        });

        props.capturaSeleccion(
          {
            index: filtroImpresora,
            row: selectionChanged['selectedRowsData'][0],
            selectedRow: filtroImpresora
          }
        )

      }
    }
  }

  const onRowDoubleClick = React.useCallback((e) => {
    const { data } = e;
    const item = data;
    returnItem(item)
  }, [dispatch]);

  const handleOptionChange = React.useCallback((e) => {

    if (e.fullName === "paging.pageSize") {
      setPageSize(e.value)
    }
    if (e.fullName === "paging.pageIndex") {
      setPageIndex(e.value)
    }
    if (e.fullName === "selectedRowKeys") {
      if (data._items && data._items.length > 0) {
        if (e['value'].length > 0) {
          const filtrado: number = data._items.findIndex((item: any) => {
            return String(item.codigoBarras) === String(e['value']);
          });
          if (filtrado > -1) {
            props.capturaSeleccion(
              {
                index: filtrado,
                row: data._items[filtrado],
                selectedRow: filtrado
              }
            )
          }
        }
      }
    }
  }, [data]);

  const playLoader = () => {
    dispatch(changeLoader({ show: true, mensaje: 'Cargando...' }))
  }

  const stopLoader = () => {
    dispatch(changeLoader({ show: false, mensaje: '' }))
  }

  const onSearch = React.useCallback((dataSearch) => {
    playLoader()
    console.log("onSearch", dataSearch)
    const find: ProveedorSearchGrid = {
      nombreComercial: dataSearch['nombreComercial'],
      razonSocial: dataSearch['razonSocial'],
      identificacion: dataSearch['ruc'],
      activos: dataSearch['activos'],
      transportista: dataSearch['transportista'],
    }
    const resFind = ProveedoresService.getProveedorGridDatasource(find);
    console.log("resFind", resFind)
    setData(resFind)
    setDataItems(resFind)
    stopLoader()
  }, [infoPagination]);

  const onResetTab = React.useCallback(() => {
    setPageIndex(0)
  }, []);

  return (
    <>
      <fieldset>


        <CRow>
          <CCol lg="6" className="d-flex justify-content-start">

            < CTooltip
              key={"tool-more"}
              placement="top"
              content={"Hacer doble click sobre el proveedor para copiar a la transacción."}
            >
              <div className="card-header-actions">
                <CLink className="card-header-action" >
                  <CIcon icon={cilInfo} />
                </CLink>
              </div>
            </CTooltip>

          </CCol>
          <CCol lg="6" className="d-flex justify-content-end">

            {/* <div>
              <CBadge color='info'>
                {`${data.length} REGISTROS ENCONTRADOS`}
              </CBadge>
            </div> */}

          </CCol>
        </CRow>

        <CRow>
          <CCol className='d-flex justify-content-center mt-2' md="12" lg="12" xs="12" >
            <DataGrid
              ref={tablaRef}
              dataSource={data ?? []}
              selection={{ mode: 'single' }}
              hoverStateEnabled={true}
              showBorders={true}
              showRowLines={true}
              onSelectionChanged={selectionChanged}
              onRowDblClick={onRowDoubleClick}
              allowColumnReordering={true}
              allowColumnResizing
              columnResizingMode='widget'
              columnAutoWidth={true}
              activeStateEnabled={true}
              onOptionChanged={handleOptionChange}
              remoteOperations={true}
            >
              <Paging
                pageIndex={pageIndex}
                enabled={true}
                defaultPageSize={10}
                pageSize={pageSize}
              />
              <Pager
                ref={tablaRefPager}
                showPageSizeSelector={true}
                allowedPageSizes={[8, 12, 20]}
                showNavigationButtons={true}
                showInfo={true}
                infoText="Page #{0}. Total: {1} ({2} items)"
              />
              <FilterRow visible={true} />
              <HeaderFilter visible={true} />
              <Column
                dataType='string'
                dataField='identificacion'
                caption='Identificación'
                width='15%'
              />
              <Column
                dataType='string'
                dataField='nombre'
                caption='Nombre'
                width='15%'
              />
              <Column
                dataType='string'
                dataField='razonComercial'
                caption='R. Comercial'
                minWidth={'60%'}
                width={'60%'}
              />
              <Column
                dataType='number'
                dataField='telefono'
                caption='Teléfono'
                width='10%'
              />

            </DataGrid>
          </CCol>
        </CRow>


      </fieldset>
    </>
  );
}
export default memo(Tabla);
