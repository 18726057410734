import React, { memo, useCallback, useEffect, useState } from 'react'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput'
import { Button, CheckBox, DateBox, NumberBox, TextBox } from 'devextreme-react'
import LocalesLookUp from '../../../../../../componentes/localesLookUp'
import {
  CCard,
  CCardHeader,
  CCol,
  CCollapse,
  CLink,
  CRow,
} from '@coreui/react-pro'
import EstadoProgresoLookUp from '../../../../../../componentes/progresoEstadoLookUp'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import {
  changeDestination,
  changeFilter,
  changeOrigin,
  changeProgress,
} from '../../store/searchReducer'
import { LocalOption } from '../../../../../../componentes/localesLookUp/store/type'
import { ProgressStatus } from '../../../../../../componentes/progresoEstadoLookUp/types/types'
import { DateUtils } from '../../../../../../../helpers/dateUtils'
import { AdminModalLocales } from '../../../../../../componentes/modalAdminLocales/modalAdminLocales'
import { buttonClick } from '../../store/tabsReducer'
import { ButtonTypes } from '../../../../../../../views/componentes/globalMenu/types'
import { isMobile } from 'react-device-detect'
import CIcon from '@coreui/icons-react'
import { cilChevronBottom, cilChevronTop } from '@coreui/icons'
import { setCollpased } from '../../store/generalReducer'

const FiltroBusqueda = () => {
  const dispatch = useDispatch()

  const searchFilter = useSelector((state: RootState) => {
    return state.inventarios.movimientos.transferencias.search.filter
  })
  const accountingPeriod = useSelector((state: RootState) => {
    return state.shared.providers.periodoContable
  })
  const tabs = useSelector((state: RootState) => {
    return state.inventarios.movimientos.transferencias.tabs
  })
  const collapsed = useSelector(
    (state: RootState) =>
      state.inventarios.movimientos.transferencias.general.collapse,
  )

  const [showModalStores, setShowModalStores] = useState<{
    show: boolean
    store: string
  }>({ show: false, store: '' })

  const onChangedOrigin = useCallback(
    (store: LocalOption) => {
      dispatch(changeOrigin(store))
    },
    [dispatch],
  )

  const onChangedDestination = useCallback(
    (store: LocalOption) => {
      dispatch(changeDestination(store))
    },
    [dispatch],
  )

  const onChangedProgress = useCallback(
    (progress: ProgressStatus) => {
      dispatch(changeProgress(progress))
    },
    [dispatch],
  )

  const onHandleChanged = useCallback(
    (event: any, valueToUpdate: string) => {
      if (event.event) {
        dispatch(
          changeFilter({
            ...searchFilter,
            [valueToUpdate]: event.value,
          }),
        )
      }
    },
    [dispatch, searchFilter],
  )

  const onHandleSearch = useCallback(() => {
    if (tabs.tabs[tabs.current].currentExecutingAction === undefined)
      dispatch(buttonClick({ tabKey: 'BUSQUEDA', button: ButtonTypes.find }))
  }, [dispatch, tabs])

  useEffect(() => {
    if (
      accountingPeriod?.length > 0 &&
      (searchFilter.fechaDesde === '' || searchFilter.fechaHasta === '')
    ) {
      dispatch(
        changeFilter({
          ...searchFilter,
          fechaDesde:
            DateUtils.apiDateToPickersDate(accountingPeriod[0]?.finicio) ?? '',
          fechaHasta:
            DateUtils.apiDateToPickersDate(accountingPeriod[0]?.fcierre) ?? '',
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountingPeriod])

  return (
    <>
      {showModalStores.show && (
        <AdminModalLocales
          onChanged={(store: LocalOption) => {
            if (showModalStores.store === 'origen')
              dispatch(changeOrigin(store))
            else dispatch(changeDestination(store))
            setShowModalStores({ show: false, store: '' })
          }}
          onCancel={() => setShowModalStores({ show: false, store: '' })}
          show={showModalStores.show}
          modulo={2}
        />
      )}
      <CCard>
        <CCardHeader
          onClick={() => dispatch(setCollpased(!collapsed))}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <strong> {!collapsed ? 'Filtro de Búsqueda' : ''} </strong>
          <CLink className="card-header-action">
            <CIcon icon={collapsed ? cilChevronBottom : cilChevronTop} />
          </CLink>
        </CCardHeader>
        <CCollapse visible={collapsed}>
          <RowContainer className="m-2">
            <RowContainer>
              <CustomCol xs="12" md="2">
                <Labeled label="Código">
                  <TextBox
                    value={searchFilter.codigo}
                    onValueChanged={(evt: unknown) =>
                      onHandleChanged(evt, 'codigo')
                    }
                    showClearButton={true}
                    onEnterKey={onHandleSearch}
                  />
                </Labeled>
              </CustomCol>
              <CustomCol xs="12" md="3">
                <Labeled label="Número:">
                  <NumberBox
                    value={searchFilter.numero}
                    onValueChanged={(evt: unknown) =>
                      onHandleChanged(evt, 'numero')
                    }
                    showClearButton={true}
                    onEnterKey={onHandleSearch}
                  />
                </Labeled>
              </CustomCol>
              <CustomCol xs="12" md="4">
                <Labeled label="Rango de Fechas:">
                  <div
                    style={{
                      display: 'flex',
                      gap: '2px',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <DateBox
                      displayFormat="dd/MM/yyyy"
                      value={searchFilter.fechaDesde}
                      onValueChanged={(evt) =>
                        onHandleChanged(evt, 'fechaDesde')
                      }
                    />
                    <DateBox
                      displayFormat="dd/MM/yyyy"
                      value={searchFilter.fechaHasta}
                      onValueChanged={(evt) =>
                        onHandleChanged(evt, 'fechaHasta')
                      }
                    />
                  </div>
                </Labeled>
              </CustomCol>
            </RowContainer>
            <RowContainer>
              <CustomCol xs="12" md="5">
                <Labeled label="Origen:">
                  <CRow>
                    <CCol xs="9" md="10">
                      <LocalesLookUp
                        selected={searchFilter.origen}
                        onChanged={(value: LocalOption) =>
                          onChangedOrigin(value)
                        }
                        message={'Elija un local o CC'}
                      />
                    </CCol>
                    <CCol xs="3" md="2" style={{ marginRight: 'auto' }}>
                      <Button
                        id="btnAdd"
                        className="me-1"
                        icon="add"
                        stylingMode="contained"
                        type="default"
                        onClick={() =>
                          setShowModalStores({ show: true, store: 'origen' })
                        }
                      />
                    </CCol>
                  </CRow>
                </Labeled>
              </CustomCol>
              <CustomCol xs="12" md="2">
                <Labeled label="Item:">
                  <TextBox
                    value={searchFilter.item}
                    onValueChanged={(evt: unknown) =>
                      onHandleChanged(evt, 'item')
                    }
                    showClearButton={true}
                  />
                </Labeled>
              </CustomCol>
              <CCol
                xs="4"
                md="1"
                style={{ marginTop: isMobile ? '4px' : 'auto' }}
              >
                <CheckBox
                  text="Activos"
                  value={searchFilter.activos}
                  onValueChanged={(evt: unknown) =>
                    onHandleChanged(evt, 'activos')
                  }
                />
              </CCol>
              <CCol
                xs="4"
                md="1"
                style={{ marginTop: isMobile ? '4px' : 'auto' }}
              >
                <CheckBox
                  text="Archivados"
                  value={searchFilter.archivados}
                  onValueChanged={(evt: unknown) =>
                    onHandleChanged(evt, 'archivados')
                  }
                />
              </CCol>
            </RowContainer>
            <RowContainer>
              <CustomCol xs="12" md="5">
                <Labeled label="Destino:">
                  <CRow>
                    <CCol xs="9" md="10">
                      <LocalesLookUp
                        selected={searchFilter.destino}
                        onChanged={(value: LocalOption) =>
                          onChangedDestination(value)
                        }
                        message={'Elija un local o CC'}
                      />
                    </CCol>
                    <CCol xs="3" md="2" style={{ marginRight: 'auto' }}>
                      <Button
                        id="btnAdd"
                        className="me-1"
                        icon="add"
                        stylingMode="contained"
                        type="default"
                        onClick={() =>
                          setShowModalStores({ show: true, store: 'destino' })
                        }
                      />
                    </CCol>
                  </CRow>
                </Labeled>
              </CustomCol>
              <CustomCol xs="12" md="2">
                <Labeled label="Movimiento:">
                  <TextBox
                    value={searchFilter.movimiento}
                    onValueChanged={(evt: unknown) =>
                      onHandleChanged(evt, 'movimiento')
                    }
                    showClearButton={true}
                  />
                </Labeled>
              </CustomCol>
              <CustomCol xs="12" md="2">
                <Labeled label="Progreso:">
                  <EstadoProgresoLookUp
                    selected={searchFilter.progreso}
                    onChanged={(value: ProgressStatus) =>
                      onChangedProgress(value)
                    }
                    onChangedOptions={() => {}}
                  />
                </Labeled>
              </CustomCol>
            </RowContainer>
            <CustomCol xs="12" md="5">
              <Labeled label="Observaciones:">
                <TextBox
                  value={searchFilter.observaciones}
                  onValueChanged={(evt: unknown) =>
                    onHandleChanged(evt, 'observaciones')
                  }
                  showClearButton={true}
                />
              </Labeled>
            </CustomCol>
          </RowContainer>
        </CCollapse>
      </CCard>
    </>
  )
}

export default memo(FiltroBusqueda)
