import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../../store/store";
import { TiposComprobantesSri } from "../../../../../store/types";
import { ButtonTypes } from "../../../../../views/componentes/globalMenu/types";
import { CompraInfo } from "../../../../compras/types/types";
import { StatesEdition, TabTypes } from "../../../types/enums";
import { CustomButtons, TabsState, TabState } from "../../../types/generics";
import { ventasCloseTab, existeTab } from "../../../store/reducers";
import { LiquidacionCompraButtons } from "../../../components/liquidaciones/liquidacion/liquidacion";
const initialState: TabsState<CompraInfo> = {
  current: "BUSQUEDA",
  tabs: {
    BUSQUEDA: {
      id: 0,
      canClose: false,
      editStatus: StatesEdition.doesNotApply,
      globalButtonClick: ButtonTypes.none,
      tittle: "Búsqueda",
      type: TabTypes.busqueda,
      imprimir: false,
      tabKey: "BUSQUEDA",
      buttons: {
        Buscar: true,
        Nuevo: true,
        Exportar: true,
      },
    },
  },
};

const tabsLiquidacionesSlice = createSlice({
  name: "ventasLiquidacionesTabs",
  initialState: initialState,
  reducers: {
    ventasCloseTab,
    openTab(
      state,
      action: PayloadAction<{ key: string; compra?: CompraInfo }>
    ) {
      const { key, compra } = action.payload;
      if (compra === undefined) {
        const tab: TabState<CompraInfo> = {
          id: -1,
          canClose: true,
          editStatus: StatesEdition.new,
          globalButtonClick: ButtonTypes.none,
          tittle: "Nueva",
          type: TabTypes.documento,
          imprimir: false,
          tabKey: action.payload.key,
          info: {
            claveAcceso: "",
            numero: 0,
            tipoComprobante: TiposComprobantesSri.LiquidacionCompra,
          },
          buttons: LiquidacionCompraButtons,
        };
        state.tabs[key] = tab;
        state.current = key;
      } else {
        const tabKey = existeTab(state.tabs as any, compra.codigo);
        if (tabKey) {
          state.current = tabKey;
        } else {
          const tab: TabState<CompraInfo> = {
            tabKey: action.payload.key,
            id: compra.codigo,
            tittle: `${compra.establecimiento}-${compra.puntoEmision}-${compra.numero}`,
            canClose: true,
            editStatus: StatesEdition.save,
            type: TabTypes.documento,
            info: {
              claveAcceso: "",
              numero: compra.codigo,
              tipoComprobante: TiposComprobantesSri.LiquidacionCompra,
              info: action.payload.compra,
            },
            globalButtonClick: ButtonTypes.none,
            imprimir: false,
            buttons: LiquidacionCompraButtons,
          };
          state.tabs[key] = tab;
          state.current = key;
        }
      }
    },
    changeEditStatus(
      state,
      action: PayloadAction<{
        tabKey: string;
        estado: StatesEdition;
        info?: CompraInfo;
        buttons: CustomButtons;
      }>
    ) {
      const { buttons, estado, tabKey, info } = action.payload;
      state.tabs[tabKey].editStatus = estado;
      state.tabs[tabKey].currentExecutingAction = undefined;
      state.tabs[tabKey].buttons = buttons;
      if (info) {
        state.tabs[tabKey].info = {
          claveAcceso: info.claveAcceso,
          numero: info.codigo,
          tipoComprobante: TiposComprobantesSri.Factura,
          info: info ?? null,
        };
        state.tabs[tabKey].id = info.codigo;
        state.tabs[
          tabKey
        ].tittle = `${info.establecimiento}-${info.puntoEmision}-${info.numero}`;
      }
    },
    buttonClick(
      state,
      action: PayloadAction<{ tabKey: string; button: ButtonTypes }>
    ) {
      state.tabs[action.payload.tabKey].globalButtonClick =
        action.payload.button;
    },
    clearButtonClick(state, action: PayloadAction<string>) {
      if (Object.prototype.hasOwnProperty.call(state.tabs, action.payload)) {
        state.tabs[action.payload].globalButtonClick = ButtonTypes.none;
      }
    },
    changeCurrentTab(state, action: PayloadAction<string>) {
      if (Object.prototype.hasOwnProperty.call(state.tabs, action.payload)) {
        state.current = action.payload;
      }
    },
    setTabInfoAsInactive(state, action: PayloadAction<string>) {
      //if (action.payload >= state.tabs.length) return;
      const info = state.tabs[action.payload].info;
      if (info !== undefined) {
        if (info.info) {
          info.info.estadoDescripcion = "ANULADO";
          info.info.estadoElectronico = "ANULADO";
        }
        state.tabs[action.payload].info = info;
      }
    },
    setButtons(
      state,
      action: PayloadAction<{ tabKey: string; buttons: CustomButtons }>
    ) {
      const { buttons, tabKey } = action.payload;
      state.tabs[tabKey].buttons = buttons;
      state.tabs[tabKey].currentExecutingAction = undefined;
    },
    setCurrentExecutingAction(
      state,
      action: PayloadAction<{
        tabKey: string;
        buttonType: ButtonTypes | undefined;
      }>
    ) {
      const { buttonType, tabKey } = action.payload;
      state.tabs[tabKey].currentExecutingAction = buttonType;
    },
  },
});

export const selectTabsventas = (state: RootState) => {
  return state.ventas.ventas.tabs;
};
export const {
  ventasCloseTab: closeTab,
  openTab,
  changeCurrentTab,
  changeEditStatus,
  clearButtonClick,
  buttonClick,
  setButtons,
  setCurrentExecutingAction,
  setTabInfoAsInactive,
} = tabsLiquidacionesSlice.actions;
export const tabsReducer = tabsLiquidacionesSlice.reducer;
