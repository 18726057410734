import React, { useCallback, useState } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CTooltip,
} from '@coreui/react-pro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch, useSelector } from 'react-redux'
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons'
import { ButtonTypes } from '../../../views/componentes/globalMenu/types'
import { RootState } from '../../../store/store'
import { changeLoader } from '../../../store/reducers'
import Modalform from '../../../views/componentes/modalform'
import CustomCol from '../../../views/componentes/colContainer'
import Labeled from '../../../views/componentes/labeledInput/labeledInput'
import { DataGrid, TextBox } from 'devextreme-react'
import { useSetToast } from '../../../hooks/useGlobalHooks'
import { Column, Pager, Paging } from 'devextreme-react/data-grid'
import { getAllowedPageSizes } from '../../../helpers/Helper'
import LocalesLookUp from '../localesLookUp'
import { LocalOption } from '../localesLookUp/store/type'
import { TransfersServices } from '../../inventario/pages/movimientos/transferencias/services/transferencias.services'
import { ToastTypes } from '../../../store/types'
import {
  SearchUsers,
  Usuario,
} from '../../inventario/pages/movimientos/transferencias/types/types'
import ItemsCountIndicator from '../indicadorNroRegistros'
import { Button as DatagridButton } from 'devextreme-react/data-grid'
import { EAplicationsAcatha, EModulosAcatha } from '../../../store/enumsAplication'
import { SearchUsesContract } from '../../nomina/pages/contratos/types/types'
import { ContratsServices } from '../../nomina/pages/contratos/services/contratos.services'
import { DateUtils } from '../../../helpers/dateUtils'
import { PersonalServices } from '../../nomina/pages/personal/services/personal.service'
import { FiltroBusquedaPersonal } from '../../nomina/pages/personal/types/types'
import { modalUsuariosServices } from '../../nomina/services/modalUsuariosServices'

const buttons = [
  {
    icon: 'fal fa-search',
    id: 'Buscar',
    text: 'Buscar',
    type: ButtonTypes.find,
  },
  {
    icon: 'fal fa-broom',
    id: 'Limpiar',
    text: 'Limpiar',
    type: ButtonTypes.broom,
  },
  {
    icon: 'fal fa-times',
    id: 'Salir',
    text: 'Salir',
    type: ButtonTypes.times,
  },
]

type IModalUsuariosProps = {
  show: boolean
  // eslint-disable-next-line no-unused-vars
  onSelectUser: (user: Usuario) => void
  onClose: () => void
  modulo: string
}

const ModalUsuarios = (props: IModalUsuariosProps) => {
  const dispatch = useDispatch()
  const setToastMessage = useSetToast()

  const codeCompany = useSelector(
    (state: RootState) => state.global.session.empresa.codigo,
  )
  const userSession = useSelector(
    (state: RootState) => state.global.session.usuario,
  )

  const [acciones] = useState<any>(buttons)
  const [cedula, setCedula] = React.useState<string>('')
  const [local, setLocal] = React.useState<LocalOption | { codigo: number }>({
    codigo: -1,
  })
  const [nombres, setNombres] = React.useState<string>('')
  const [apellidos, setApellidos] = React.useState<string>('')
  const [usuarios, setUsuarios] = React.useState<Usuario[]>([])
  const tablaRef = React.useRef<any>()
  const tablaRefPager = React.useRef<any>()

  const playLoader = useCallback(
    (message: string) => {
      dispatch(changeLoader({ show: true, mensaje: message }))
    },
    [dispatch],
  )

  const stopLoader = useCallback(() => {
    dispatch(changeLoader({ show: false, mensaje: '' }))
  }, [dispatch])

  const closeModal = useCallback(() => {
    props.onClose()
  }, [props])

  const onHandleChange = useCallback(
    (setState: any) => (event: any) => {
      if (event.event && event.value) setState(event.value)
    },
    [],
  )

  const onHandleSearch = useCallback(async () => {
    try {
      playLoader('Buscando usuarios . . .')
      const filter: SearchUsers = {
        cedula: cedula,
        nombres: nombres,
        apellidos: apellidos,
        empresaCodigo: codeCompany,
      }
      let users = null
      if (props.modulo === EModulosAcatha.Transferencias || props.modulo === EAplicationsAcatha.Marcacion)
        users = await TransfersServices.getUsers(filter)
      if (props.modulo === EAplicationsAcatha.Contratos) {
        const data_filter: SearchUsesContract = {
          nombres: nombres ?? '',
          apellidos: apellidos ?? '',
          fecha: DateUtils.getCurrentDateAsString('dd/MM/yyyy'),
          cedula: cedula ?? '',
          estado: 1,
          ciudad: -1,
          local: -1,
        }
        users = await ContratsServices.getDesEmployees(data_filter)
      }
      if (props.modulo === EAplicationsAcatha.RolesPago) {
        users = await modalUsuariosServices.obtenerEmpreados(filter)
      }
      if (props.modulo === EAplicationsAcatha.Calendarios) {
        const data_filter: FiltroBusquedaPersonal = {
          usuario: '',
          nombres: nombres ?? '',
          apellidos: apellidos ?? '',
          cedula: cedula ?? '',
          pais: null,
          locales: null,
          activos: true,
          initital: true,

        }
        users = await PersonalServices.getPersonal(data_filter, userSession?.rol)
      }
      stopLoader()
      if (!users.error && users.auto) {
        setUsuarios(users.auto)
        setToastMessage('Buscar Usuarios', users.message, ToastTypes.Success)
      } else
        setToastMessage(
          'Buscar Usuarios',
          users.message ?? 'Error al buscar usuarios',
          ToastTypes.Warning,
        )
    } catch (error) {
      stopLoader()
      setToastMessage(
        'Buscar Usuarios',
        error ?? 'Error al buscar usuarios',
        ToastTypes.Danger,
      )
    }
  }, [
    apellidos,
    cedula,
    nombres,
    playLoader,
    setToastMessage,
    stopLoader,
    codeCompany,
    props.modulo,
    userSession,
  ])

  const returnAction = useCallback(
    (action: string) => {
      switch (action) {
        case ButtonTypes.find:
          onHandleSearch()
          break
        case ButtonTypes.broom:
          setCedula('')
          setNombres('')
          setLocal({ codigo: -1 })
          setNombres('')
          setApellidos('')
          setUsuarios([])
          break
        case ButtonTypes.close:
          closeModal()
          break
        default:
          break
      }
    },
    [closeModal, onHandleSearch],
  )

  const bodyModalForm = () => {
    return (
      <>
        <CRow>
          <CustomCol xs="12" md="4">
            <Labeled label="Cédula:">
              <TextBox
                value={cedula}
                onValueChanged={onHandleChange(setCedula)}
              />
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="4">
            <Labeled label="Locales:">
              <LocalesLookUp
                selected={local}
                onChanged={(local: LocalOption) => setLocal(local)}
                onChangedOptions={() => { }}
                message="Todos"
              />
            </Labeled>
          </CustomCol>
        </CRow>
        <CRow>
          <CustomCol xs="12" md="4">
            <Labeled label="Apellidos:">
              <TextBox
                value={apellidos}
                onValueChanged={onHandleChange(setApellidos)}
              />
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="4">
            <Labeled label="Nombres:">
              <TextBox
                value={nombres}
                onValueChanged={onHandleChange(setNombres)}
              />
            </Labeled>
          </CustomCol>
        </CRow>
        <ItemsCountIndicator items={usuarios} />
        <DataGrid
          style={{ marginTop: '10px' }}
          ref={tablaRef}
          selection={{ mode: 'single' }}
          className="border"
          dataSource={usuarios}
          width={'100%'}
          keyExpr=""
          onRowDblClick={({ data }) => props.onSelectUser(data)}
        >
          <Paging enabled={true} defaultPageSize={10} />
          <Pager
            ref={tablaRefPager}
            showPageSizeSelector={true}
            allowedPageSizes={getAllowedPageSizes(usuarios)}
            showNavigationButtons={true}
            showInfo={true}
          />
          <Column dataField="codigo" caption="" width="" />
          <Column type="buttons" width="10%">
            <DatagridButton
              icon="arrowleft"
              visible={true}
              hint="Seleccionar"
              onClick={(e) => {
                props.onSelectUser(e.row.data)
              }}
            />
          </Column>
          <Column dataField="nombres" caption="Nombres" width="45%" />
          <Column dataField="apellidos" caption="Apellidos" width="45%" />
        </DataGrid>
      </>
    )
  }

  const renderBody = () => {
    return (
      <>
        <CCard key="cardCuentas">
          <CCardHeader>
            <CRow>
              <CCol className="ms-0 d-flex align-items-start justify-content-start">
                <div
                  key="menuItems"
                  className="btn-toolbar"
                  role="toolbar"
                  aria-label="Toolbar with button groups"
                  style={{ marginBottom: '5px', paddingTop: '-2rem' }}
                >
                  <div
                    key="menuItemsBotones"
                    className="btn-group-lg btn-group btn-sm me-2"
                    role="group"
                    aria-label="First group"
                  >
                    {acciones.map(function (action: any) {
                      const prefixButton = 'buttonsEditPrices_'
                      const nameButon = action.text
                      const disabled = false
                      return (
                        <CTooltip
                          key={'tool-' + prefixButton + nameButon}
                          placement="top"
                          content={nameButon}
                        >
                          <CButton
                            key={prefixButton + nameButon}
                            name={prefixButton + nameButon}
                            title={action.text}
                            id={prefixButton + nameButon}
                            value={action.text}
                            className="btn btn-secondary sm"
                            variant="ghost"
                            // eslint-disable-next-line no-unused-vars
                            onClick={(ev) => returnAction((ev = action.text))}
                            size="sm"
                            disabled={disabled}
                          >
                            <FontAwesomeIcon
                              size="sm"
                              icon={action.icon ?? faQuestionCircle}
                            />
                          </CButton>
                        </CTooltip>
                      )
                    })}
                  </div>
                </div>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>{bodyModalForm()}</CCardBody>
        </CCard>
      </>
    )
  }

  return (
    <Modalform
      name="modalUsers"
      key="modalUsers"
      headerTitle={'Usuarios'}
      childrenBody={renderBody()}
      childrenFooter={<></>}
      closeOnBackdrop={false}
      show={props.show}
      onClose={closeModal}
      size="lg"
    />
  )
}

export default React.memo(ModalUsuarios)
