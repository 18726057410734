import { AccionMenu, LoaderInfo } from "../../../../../store/types";
import { Asiento, AsientoApi } from "../../../store/types";

export enum TIPOS_BOTONES {
  BOTON_NUEVO = 'Nuevo',
  BOTON_GUARDAR = 'Guardar',
  BOTON_EDITAR = 'Editar',
  BOTON_DESHACER = 'Deshacer',
  BOTON_BUSCAR = 'Buscar',
  BOTON_ELIMINAR = 'Eliminar',
  BOTON_EXPORTAR = 'Exportar',
  BOTON_IMPORTAR = 'Importar',
  BOTON_ANTERIOR = 'Anterior',
  BOTON_SIGUIENTE = 'Siguiente',
  BOTON_RESTAURAR = 'Restaurar',
  BOTON_VERIFICAR = 'Verificar',
  BOTON_GENERAR_ASIENTOS = 'Generar asientos',
  BOTON_ASIGNAR_verificar = 'Asignar jerarquia',
  BOTON_ASIGNAR_NIVELES = 'Asignar niveles',
  BOTON_LIMPIAR = 'Limpiar',
  BOTON_ELIMINAR_ASIENTOS = 'Eliminar asientos',
  BOTON_ENUMERAR_ASIENTOS = 'Enumerar asientos',
  BOTON_DUPLICAR = 'Duplicar',
  BOTON_ANTICIPOS = 'Anticipos',
  BOTON_IMPRIMIR = 'Imprimir',
  BOTON_REGENERAR = 'Regenerar',
  BOTON_PRINT_DESIGN = "Imprimir_Diseño",
}

export type Registros = {
  PLA_CODIGO: number,
  PLA_NUMERO: string,
  PLA_DESCRIPCION: string,
  PLA_DESCIDIOMA: string,
  PLA_CATEGORIA: number,
  PLA_CATEGORIA_DES: string,
  PLA_SALDO_INICIAL: any,
  PLA_SALDO_FINAL: any,
  PLA_ESTADO: number,
  TIP_CODIGO: number,
  TIP_DESCRIPCION: string,
  PLA_PADRE: number,
  PLA_PADRENUM: any,
  PLA_ORDEN: any,
  PLA_OPERACION: number,
  PLA_SALDO: any,
  PLA_NIVEL: number,
  PLA_FISCAL: any,
  PLF_NUMERO: any,
  PLA_TRANSACCIONES: any,
}

export type Seleccionado = {
  index: any,
  row: any,
  selectedRow: any,
}

export type ItemDetalle = {
  FILA: number,
  ASI_NUMERO: number,
  PLA_CODIGO: string,
  PLA_NUMERO: string,
  PLA_DESCRIPCION: string,
  PLA_DESCIDIOMA: string,
  LOC_CODIGO: any,
  LOC_NOMBRE: any,
  TRA_CODIGO: string,
  TRA_REFERENCIA: string,
  TRA_DEBE: string,
  TRA_HABER: string,
}

export type formularioBusqueda = {
  ASI_DIARIONRO: number,
  ASI_FECHA_D: any,
  ASI_FECHA_H: any,
  porhoja: boolean,
  idioma: boolean,
  numDiario: number,
  descripcionAsiento: string,
  numeroAsiento: number,
  fechaInicio: string,
  fechaCierre: string,
}

export type CuentaContable = {
  codigo: number,
  numero: string,
  descripcion: string,
}

export type PageState = {
  modulo: string,
  currentAction: string,
  currentFunction: string,
  aplicacion: string,
  acciones: Array<AccionMenu>,
  registros: Array<Registros>,
  registrosApi: Array<any>,
  seleccionado: Seleccionado,
  refrescaTabla: boolean,
  detalleAsiento: Array<ItemDetalle>,
  navegacion: string, // permite saber si existe navegacion atras, siguiente.
  asiento: Asiento | null,
  asientoApi: AsientoApi | null,
  formularioBusqueda: formularioBusqueda,
  cargarCuentaContable: CuentaContable, // este type es para carputar la cuenta de la pantalla modal y pasarla a pantallas principales
  numeroAsientoActual: number,
  loader: LoaderInfo
}