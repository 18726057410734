import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoaderInfo, OptionGrupos } from "../../../../../store/types";
import { Seleccionado } from "../../../store/types";
import { CCAhorrosState, CCAHORRO, formularioBusqueda } from "./types";
const initialState: CCAhorrosState = {
  modulo: 'ccahorros',
  currentAction: "Buscar",
  currentFunction: "",
  currentExecute: null,
  registros: [],
  seleccionado: {
    index: null,
    row: null,
    selectedRow: null,
  },
  tipos: [],
  estados: [],
  loader: {
    show: false,
    mensaje: ''
  },
  formularioBusqueda: {
    pertenece: null,
    numero: null,
    asociado: null
  },
}

const ccahorrosSlice = createSlice({
  name: "ccahorros",
  initialState: initialState,
  reducers: {
    setCurrentAccion(state, action: PayloadAction<any>) {
      state.currentAction = action.payload
    },
    setCurrentFunction(state, action: PayloadAction<any>) {
      state.currentFunction = action.payload
    },
    setData(state, action: PayloadAction<Array<CCAHORRO>>) {
      state.registros = action.payload
    },
    setSeleccionarDato(state, action: PayloadAction<Seleccionado>) {
      const toAdd = {
        index: action.payload.index,
        row: action.payload.row,
        selectedRow: action.payload.selectedRow,
      }
      state.seleccionado = toAdd
    },
    setResetSeleccion(state) {
      const toAdd = {
        index: null,
        row: null,
        selectedRow: null,
      }
      state.seleccionado = toAdd
    },
    setTiposOptions(state, action: PayloadAction<Array<OptionGrupos>>) {
      state.tipos = action.payload
    },
    setOptionsEstados(state, action: PayloadAction<Array<OptionGrupos>>) {
      state.estados = action.payload
    },
    setFormularioBusqueda(state, action: PayloadAction<formularioBusqueda>) {
      const toAdd = {
        pertenece: action.payload.pertenece,
        numero: action.payload.numero,
        asociado: action.payload.asociado,
      }
      state.formularioBusqueda = toAdd
    },
    setCurrentExecute(state, action: PayloadAction<any>) {
      state.currentExecute = action.payload
    },
    changeLoaderCuentaAhorros(state, action: PayloadAction<LoaderInfo>) {
      state.loader = action.payload
    },
  }
})
export const {
  setOptionsEstados,
  setData,
  setCurrentAccion,
  setSeleccionarDato,
  setResetSeleccion,
  setTiposOptions,
  setCurrentFunction,
  setFormularioBusqueda,
  setCurrentExecute,
  changeLoaderCuentaAhorros
} = ccahorrosSlice.actions;
export const CCAhorrosReducer = ccahorrosSlice.reducer;

