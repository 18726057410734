import React, { memo } from 'react'
import ResultadoBusqueda from './resultadoBusqueda'
import { ToastTypes } from '../../../../../store/types'
import { anticiposService } from '../../service/anticiposVenta.service'
import { TListadoAnticipos } from '../../store/types'

interface IBuscarProps extends React.PropsWithChildren {
  onChanged: (data: TListadoAnticipos) => void
  funcion: string
  resetFunction: () => void
  ejecutarBusqueda: () => void
  setToast: (sms: string, type: ToastTypes) => void
  playLoader: () => void
  stopLoader: () => void
  codigoAnticipo: number
}

const Buscar = (props: IBuscarProps) => {
  const { codigoAnticipo, onChanged, funcion, setToast, resetFunction, playLoader, stopLoader } = props;

  const [items, setItems] = React.useState<Array<TListadoAnticipos> | []>([])
  const [seleccion, setSeleccion] = React.useState<any>({
    index: null,
    row: null,
    selectedRow: null,
  });

  const handleSubmit = React.useCallback(async () => {
    resetFunction()
    playLoader()

    try {
      const find = {
        codigo: codigoAnticipo
      }
      const data = await anticiposService.getListadoAnticipos(find)
      console.log('data', data)
      stopLoader()

      if (data.auto && data.auto.length > 0) {
        setToast('(' + data.auto.length + ')' + ' Registros encontrados.', ToastTypes.Success)
        let item: Array<TListadoAnticipos> = []
        item = data.auto.map(function (item: any) {
          return {
            acvCodigo: parseInt(item['acvCodigo'] ?? 0),
            antCodigo: parseInt(item['antCodigo'] ?? 0),
            tipo: String(item['tipo'] ?? ''),
            acvAsociado: parseInt(item['acvAsociado'] ?? 0),
            acvValor: item['acvValor'] ?? 0,
            numero: parseInt(item['numero'] ?? 0),
            documento: item['documento'] ?? '',
            concepto: item['concepto'] ?? '',
            antValor: item['antValor'] ?? '',
            asociado: item['asociado'] ?? '',
            origen: item['origen'] ?? ''
          }
        })
        setItems(item)
      }

      return await data
    } catch (error) {
      console.error(error)
      stopLoader()
      setToast(JSON.stringify(error), ToastTypes.Danger)
    }
  }, [resetFunction, playLoader, setToast, stopLoader, codigoAnticipo]);

  const onReset = React.useCallback(() => {
    resetFunction()
    setItems([])
  }, [resetFunction]);

  React.useEffect(() => {
    if (funcion === 'Buscar') {
      handleSubmit()
    } else if (funcion === 'Limpiar') {
      onReset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [funcion]);

  return (
    <ResultadoBusqueda
      funcion={''}
      data={items ?? []}
      setToast={setToast}
      capturaSeleccion={(data) => setSeleccion(data)}
      onChanged={onChanged}
    />
  )
}
export default memo(Buscar)
