import React from 'react';
import { CustomButtons } from '../../../../../../types/generics';
import BlockUi from '../../../../../../../../views/componentes/librerias/block-ui';
import LoadingIndicator from '../../../../../../../../views/componentes/loadingindicator/loadingindicator';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../../../store/store';
import { CuadresCajaListado, EgresosListado, IngresoCajaDatosEdicion, TIngresoCaja, TabStateCuadre } from '../../../../types/types';
import RowContainer from '../../../../../../../../views/componentes/rowContainer/rowContainer';
import CustomCol from '../../../../../../../../views/componentes/colContainer';
import Labeled from '../../../../../../../../views/componentes/labeledInput/labeledInput';
import { DateBox, NumberBox } from 'devextreme-react';
import { initICDatosEdicion, seleccionarRegistro, setICDatosEdicion, updateICEgresos, updateICFecha, updateICPuntoVenta, updateICPuntosVenta, updateICValor } from '../../store/editCashReducer';
import { PuntoVenta } from '../../../../../../../componentes/lookUpPuntosVenta/store/type';
import PuntosVentaLookUp from '../../../../../../../componentes/lookUpPuntosVenta';
import { StatesEdition } from '../../../../../../types/enums';
import { DateUtils, formatoFechasApi, formatoFechasDatePickers } from '../../../../../../../../helpers/dateUtils';
import { ButtonTypes } from '../../../../../../../../views/componentes/globalMenu/types';
import { clearButtonClick, setButtons, setCurrentExecutingAction } from '../../store/tabsReducer';
import { CuadresService } from '../../../../services/cuadres.service';
import { addToast } from '../../../../../../../../store/toasterReducer';
import { ToastTypes } from '../../../../../../../../store/types';
import DataGrid, {
  Column, Paging, Pager, Button as DatagridButton, Editing, HeaderFilter,
} from 'devextreme-react/data-grid';
import { utilidades } from '../../../../../../../../helpers/utilidades';
import { useGridResultadosSize } from '../../../../../../../../hooks/useGridResultadosSize';
import { getAllowedPageSizes } from '../../../../../../../../helpers/Helper';
import InputTotales from './inputTotales';

interface IIngresoCajaProps extends React.PropsWithChildren {

  tab: TabStateCuadre<CuadresCajaListado>,
  tabId: string,
  setToast: (texto, type) => void
  playLoader: () => void
  stopLoader: () => void,
  modulo: number
}

const IngresoCaja: React.FunctionComponent<IIngresoCajaProps> = (props) => {
  const { tab, tabId, setToast, playLoader, stopLoader, modulo } = props
  const dispatch = useDispatch()

  const datosEdicion = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.cashReceipts[props.tabId]
  })
  const loading = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.cashReceipts[props.tabId].loading
  })
  const puntoVenta = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.cashReceipts[props.tabId].puntoVenta
  })
  const fecha = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.cashReceipts[props.tabId].fecha
  })
  const valor = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.cashReceipts[props.tabId].valor
  })
  const puntoVentaGlobal = useSelector((state: RootState) => {
    return state.global.puntoVenta
  })
  const usuario = useSelector((state: RootState) => {
    return state.global.session?.usuario
  })
  const empresa = useSelector((state: RootState) => {
    return state.global.session?.empresa
  })
  const local = useSelector((state: RootState) => {
    return state.global.session?.local
  })
  const egresos = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.cashReceipts[props.tabId].egresos
  })
  const seleccionado = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.cashReceipts[props.tabId].seleccionado
  })

  const dataGrid = React.useRef<any>();
  const [heigth] = useGridResultadosSize();

  const [puntosVenta, setPuntosVenta] = React.useState<Array<PuntoVenta> | []>([])


  const onDataPuntoVenta = React.useCallback((puntosVenta) => {
    if (puntosVenta !== undefined) {
      dispatch(
        updateICPuntoVenta({
          puntoventa: puntosVenta[0],
          key: tabId,
        }),
      )
      dispatch(
        updateICPuntosVenta({
          puntosVenta: puntosVenta,
          key: tabId,
        }),
      )
    }
  }, [tabId, dispatch])

  const onChangeDate = React.useCallback((data) => {
    if (data?.event !== undefined) {
      dispatch(
        updateICFecha({
          fecha: data.value,
          key: tabId,
        }),
      )
    }
  }, [tabId, dispatch])


  const onChangePuntoVenta = React.useCallback((data) => {
    if (data !== undefined) {
      dispatch(
        updateICPuntoVenta({
          puntoventa: data,
          key: tabId,
        }),
      )
    }
  }, [tabId, dispatch])


  const onChangeValor = React.useCallback((data) => {
    if (data?.event !== undefined) {
      dispatch(
        updateICValor({
          valor: data?.value ?? null,
          key: tabId,
        }),
      )
    }
  }, [tabId, dispatch])

  const onResetForm = React.useCallback(
    async () => {
      dispatch(
        updateICValor({
          valor: 0,
          key: tabId,
        }),
      )
      dispatch(
        updateICPuntoVenta({
          puntoventa: puntosVenta[0],
          key: tabId,
        }),
      )
    },
    [
      dispatch, tabId, puntosVenta
    ]);


  const onResetFormIngresos = React.useCallback(
    async () => {
      dispatch(
        updateICValor({
          valor: 0,
          key: tabId,
        }),
      )
      dispatch(
        updateICPuntoVenta({
          puntoventa: puntosVenta[0],
          key: tabId,
        }),
      )
      dispatch(updateICEgresos({
        key: tabId,
        egresos: []
      }))
    }, [dispatch, tabId, puntosVenta]);

  const onLoadEspecial = React.useCallback(
    async () => {

      dispatch(updateICEgresos({
        key: tabId,
        egresos: []
      }))
      const fecha = DateUtils.getCurrentDateAsString(formatoFechasApi);

      const resultado = await CuadresService.getCargarEspecial(fecha, 98)
      if (resultado.auto.length > 0) {
        dispatch(updateICEgresos({
          key: tabId,
          egresos: resultado.auto
        }))
      }



    },
    [
      dispatch, tabId, datosEdicion,
    ]);

  const modoNuevo = React.useCallback(
    async (puntoVenta: string, template: number, limpiar: boolean = false) => {
      if (loading === false && !limpiar) {
        return
      }
      const data = { ...defaultDatosEdicionIngresoCaja }
      const fecha = DateUtils.getCurrentDateAsString(formatoFechasDatePickers);
      data.fecha = fecha

      dispatch(
        setICDatosEdicion({
          data: {
            ...data,
            loader: {
              show: false,
              mensaje: '',
            },
          },
          key: tabId,
        })
      )

      onLoadEspecial();
    },
    [
      dispatch, tabId, loading, onLoadEspecial
    ]);




  const guardar = React.useCallback(async () => {
    if (usuario == null || empresa == null || local == null) {
      return
    }
    try {

      dispatch(
        setICDatosEdicion({
          data: {
            ...datosEdicion,
            loader: {
              show: true,
              mensaje: 'Guardando Cuadre de Caja...',
            },
          },
          key: tabId,
        }),
      )

      dispatch(
        setCurrentExecutingAction({
          tabKey: tabId,
          buttonType: ButtonTypes.save,
        }),
      )

      const cuadreCaja: TIngresoCaja = {
        ventaCodigo: datosEdicion.codigo,
        ventaTipoDocumento: "98",
        ventaNumero: "",
        ventaAutorizacion: DateUtils.format(datosEdicion.fecha, formatoFechasApi),
        ventaEstablecimiento: "98",
        ventaPuntoEmision: puntoVenta?.codigoPuntoVenta.toString(),
        ventaFecha: DateUtils.format(datosEdicion.fecha, formatoFechasApi),
        ventaGuia: "",
        ventaSubtotal12: 0,
        ventaSubtotal0: 0,
        ventaDescuento: 0,
        ventaICE: 0,
        ventaSubtotalNoIVA: 0,
        ventaSubtotal: 0,
        ventaIVA: 0,
        ventaTotal: datosEdicion?.valor ?? 0,
        ventaFormaPago: 6,
        clienteCodigo: 0,
        localCodigo: local?.codigo ?? 0,
        ventaObservaciones: '',
        ventaImpreso: 1,
        ventaServicios: 0,
        sucursalCodigo: 0,
        valorDetalle: [],
        pagos: [
          {
            formaPagoCodigo: 6,
            formaPagoVentaValor: datosEdicion?.valor ?? 0
          },
        ],
        equipoCliente: {
          host: puntoVenta?.host.toString(),
          mac: puntoVenta?.mac.toString()
        }
      }

      const resultado = await CuadresService.ingresarCaja(cuadreCaja)
      if (resultado.error === false) {
        dispatch(addToast({
          id: '',
          autoHide: true,
          content: 'Registro Satisfactorio.',
          fade: true,
          title: 'Guardar',
          type: ToastTypes.Success,
        })
        )
        dispatch(setCurrentExecutingAction({
          tabKey: tabId,
          buttonType: undefined,
        }))

        onLoadEspecial()
        onResetForm()
      } else {

        dispatch(addToast({
          id: '',
          autoHide: true,
          content: resultado.message ?? "Error al registrar el ingreso de caja.",
          fade: true,
          title: 'Guardar',
          type: ToastTypes.Danger
        }))
      }

      dispatch(setICDatosEdicion({
        data: {
          ...datosEdicion,
          loader: {
            show: false,
            mensaje: ''
          },
        },
        key: tabId
      }));

    } catch (error) {
      console.error(error)
      dispatch(
        addToast({
          id: '',
          autoHide: true,
          content: 'Error al registrar el ingreso de caja ' + (typeof error === 'string' ? error : JSON.stringify(error)),
          fade: true,
          title: 'Guardar',
          type: ToastTypes.Danger,
        }),
      )
      dispatch(
        initICDatosEdicion({
          data: {
            ...datosEdicion,
            loader: {
              show: false,
              mensaje: '',
            },
          },
          key: tabId,
        }),
      )
      dispatch(
        setCurrentExecutingAction({
          tabKey: tabId,
          buttonType: undefined,
        }),
      )
      return
    }

  }, [
    usuario, empresa, datosEdicion,
    dispatch, tabId, puntoVenta, onLoadEspecial
    , local, onResetForm
  ]);

  const onSelectionDetalleChanged = React.useCallback(async (selectedRowsData) => {
    if (selectedRowsData['selectedRowsData'].length > 0) {
      const data: EgresosListado = selectedRowsData['selectedRowsData'][0]
      dispatch(seleccionarRegistro({
        tabKey: tabId,
        egreso: data
      }));
      dispatch(setButtons({
        tabKey: "INGRESO",
        buttons: {
          ...tab.buttons,
          Editar: true,
          Eliminar: true,
        }
      }))
    }

  }, [dispatch, tab, tabId]);

  const handleButtonClick = React.useCallback(
    (button: ButtonTypes) => {
      switch (button) {
        case ButtonTypes.save:
          guardar()
          break
        case ButtonTypes.cashReceipts:
          onLoadEspecial()
          break
        case ButtonTypes.print:
          break
        case ButtonTypes.broom:
          onResetFormIngresos()
          break
        case ButtonTypes.print_design:
          break
        default:
          break
      }
      dispatch(clearButtonClick(tabId))
    },
    [dispatch, guardar, onLoadEspecial, onResetFormIngresos, tabId],
  )


  React.useEffect(() => {
    if (tab.editStatus === StatesEdition.new) {
      modoNuevo(puntoVentaGlobal ?? '', tab.info?.codigo ?? 0)
    } else if (tab.editStatus === StatesEdition.save) {
      // cargarCuadre(info, true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
  }, [handleButtonClick, tab.globalButtonClick])


  React.useEffect(() => {
    if (tab.editStatus === StatesEdition.new && puntosVenta.length > 0) {
      onDataPuntoVenta(puntosVenta)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [puntosVenta])

  return (
    <div style={{ padding: '10px', overflowX: 'hidden' }} className='mb-4' >
      <BlockUi tag="div" loader={LoadingIndicator} blocking={datosEdicion.loader.show} message={datosEdicion.loader.mensaje}>
        <fieldset disabled={false}>
          <div>
            <RowContainer gutters={true}>

              <CustomCol xs="12" md="4">
                <Labeled label="Fecha ">
                  <DateBox
                    value={fecha}
                    onValueChanged={onChangeDate}
                    disabled={datosEdicion.fecha !== ""}
                  />
                </Labeled>
              </CustomCol>

              <CustomCol xs="12" md="3">
                <Labeled label="Punto de Venta ">

                  <PuntosVentaLookUp
                    onChanged={(data) => {
                      if (data !== null || data !== undefined) {
                        onChangePuntoVenta(data)
                      }
                    }}
                    onChangedOptions={(data) => { setPuntosVenta(data ?? []) }}
                    selected={puntoVenta}
                  />
                </Labeled>
              </CustomCol>

              <CustomCol xs="12" md="4">
                <Labeled label="Valor">
                  <NumberBox
                    // disabled={datosEdicion.ccaCodigo > 0}
                    min={0}
                    placeholder=""
                    value={valor}
                    onValueChanged={onChangeValor}
                    width="100%"
                  />
                </Labeled>
              </CustomCol>
            </RowContainer>
            <RowContainer>
              <CustomCol className='mt-4 mb-4'>
                <DataGrid
                  selection={{ mode: 'single' }}
                  focusedRowEnabled={true}
                  keyExpr='ventaCodigo'
                  dataSource={egresos ?? []}
                  showBorders={true}
                  width={utilidades.getGridFullWidth()}
                  allowColumnResizing
                  columnResizingMode='widget'
                  height={heigth}
                  onSelectionChanged={onSelectionDetalleChanged}
                  ref={dataGrid}
                >
                  <Paging defaultPageSize={20} />
                  <Pager
                    visible={egresos.length > 0 ? true : false}
                    allowedPageSizes={getAllowedPageSizes(egresos)}
                    displayMode={'full'}
                    showPageSizeSelector={true}
                    showInfo={true}
                    showNavigationButtons={true} />

                  <Column
                    caption='Tareas'
                    dataField='ventaCodigo'
                    width='50px'
                    allowFiltering={false}
                    visible={false}
                  />
                  <Column dataField='ventaFecha' dataType='date' caption='Fecha' width='30%' allowEditing={false} allowSearch={false} allowFiltering />
                  <Column dataField='puntoVentaHost' caption='Punto de Venta' allowEditing={false} width={'30%'} />
                  <Column dataField='ventaTotal' caption='Valor' width='15%' allowEditing={false} />

                </DataGrid>
              </CustomCol>
            </RowContainer>
            <RowContainer>
              <CustomCol>
                <InputTotales
                  egresos={egresos ?? []}
                />
              </CustomCol>

            </RowContainer>


          </div>
        </fieldset>
      </BlockUi>
    </div>
  )
}

export default React.memo(IngresoCaja)

export const ModuleButtonsCashReceipe: CustomButtons = {
  Nuevo: true,
  Guardar: true,
  Buscar: true,
  Deshacer: true,
  Imprimir: false,
  Importar: true,
  Limpiar: true,
  Eliminar: false,
  Ingresos_de_caja: true
}


export const defaultDatosEdicionIngresoCaja: IngresoCajaDatosEdicion = {
  codigo: 0,
  fecha: '',
  puntosVenta: [],
  puntoVenta: null,
  tieneError: false,
  mensajeError: '',
  loader: {
    mensaje: 'Cargando...',
    show: true,
  },
  loading: true,
  valor: 0,
  egresos: []
}
