import { RequestHelper } from "../../../../../helpers/requestHelper"

export const modalClientesService = {
  obtenerClientes,
}

async function obtenerClientes(ingresoFind): Promise<any> {
  try {
    const obj = {
      ...ingresoFind
    }
    const data = await RequestHelper.getAll<Array<any>>(
      'clientes',
      'getAllCort',
      '',
      obj
    )
    return data
  } catch (error) {
    return error
  }
}