import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DateUtils } from '../../../../../helpers/dateUtils'
import { RootState } from '../../../../../store/store'
import {
 DateRange,
 FETCH_STATUS,
 TiposComprobantesSri,
} from '../../../../../store/types'
import { CompraService } from '../../../../compras/services/compra.service'
import { CompraInfo } from '../../../../compras/types/types'
import { SearchStateCustomFilter } from '../../../types/generics'
import { FiltroBusquedaLiquidacionCompraState } from '../../../types/types'

const initialState: SearchStateCustomFilter<
 CompraInfo,
 FiltroBusquedaLiquidacionCompraState
> = {
 filter: {
  fechaInicio: DateUtils.getCurrentDateAsString(),
  fechaFin: DateUtils.getCurrentDateAsString(),
  puntoVenta: '',
  proveedor: null,
  activas: false,
  documento: '',
  formaPago: null,
  pendientesAbutorizar: false,
  establecimiento: null,
  estado: null,
  puntoEmision: null,
  tipoDoc: TiposComprobantesSri.LiquidacionCompra,
 },
 status: FETCH_STATUS.IDDLE,
 mensaje: '',
 resultados: [],
}

export const fetchVentasLiquidacionesCompra = createAsyncThunk<
 Array<CompraInfo>,
 FiltroBusquedaLiquidacionCompraState
>('ventas/liquidaciones/searchLiquidaciones', async (filtro) => {
 try {
  const range: DateRange = {
   inicio: DateUtils.pickersDateToApiDate(filtro.fechaInicio),
   fin: DateUtils.pickersDateToApiDate(filtro.fechaFin),
  }
  const compras = await CompraService.buscarCompras(
   range,
   filtro.documento ?? '',
   filtro.proveedor?.identificacion ?? '',
   filtro.tipoDoc,
   filtro.formaPago ? filtro.formaPago.codigo.toString() : '',
   filtro.claveAcceso ?? '',
  )
  return compras
 } catch (error) {
  return Promise.reject(error)
 }
})

const searchVentasLiquidacionesSlice = createSlice({
 name: 'searchVentasLiquidaciones',
 initialState: initialState,
 reducers: {
  resetState(state) {
   state = {
    ...initialState,
    filter: {
     ...initialState.filter,
     fechaInicio: DateUtils.getCurrentDateAsString(),
     fechaFin: DateUtils.getCurrentDateAsString(),
    },
   }
  },
  changeFilter(
   state,
   action: PayloadAction<FiltroBusquedaLiquidacionCompraState>,
  ) {
   state.filter = action.payload
  },
  setCleanResult(state) {
   state.resultados = []
  },
 },
 extraReducers: (builder) => {
  builder.addCase(fetchVentasLiquidacionesCompra.pending, (state) => {
   if (
    state.status === FETCH_STATUS.IDDLE ||
    state.status === FETCH_STATUS.SUCCESS
   ) {
    state.status = FETCH_STATUS.LOADING
    state.mensaje = ''
   }
  })
  builder.addCase(
   fetchVentasLiquidacionesCompra.fulfilled,
   (state, { payload }) => {
    state.resultados = payload
    state.status = FETCH_STATUS.SUCCESS
   },
  )
  builder.addCase(
   fetchVentasLiquidacionesCompra.rejected,
   (state, { payload }) => {
    state.resultados = []
    state.status = FETCH_STATUS.FAILED
    state.mensaje = payload as string
   },
  )
 },
})

export const selectFiltroBusqueda = (state: RootState) => {
 return state.ventas.liquidaciones.search.filter
}
export const selectResultadosBusqueda = (state: RootState) => {
 return state.ventas.liquidaciones.search.resultados
}
export const selectBusquedaEstado = (state: RootState) => {
 return state.ventas.liquidaciones.search.status
}
export const { changeFilter, resetState, setCleanResult } =
 searchVentasLiquidacionesSlice.actions
export const searchReducer = searchVentasLiquidacionesSlice.reducer
