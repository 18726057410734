import { RequestHelper } from "../../../../../helpers/requestHelper"
import { SesionService } from "../../../../../services/sesion.service";
import { Proveedor } from "../../../store/types";


export const proveedorService = {
  obtenerProveedores, obtenerCombos, crearProveedor, deleteProveedor, obtenerAsignacionesProveedores, guardarRetProCli
}

async function obtenerProveedores(buscarProveedores): Promise<any> {
  try {
    const obj = {
      ...buscarProveedores
    }
    const data = await RequestHelper.getAll<Array<Proveedor>>('proveedores', 'all', "", obj);
    return data;
  } catch (error) {
    return error;
  }
}

async function obtenerCombos(tipo, mensaje): Promise<any> {
  try {
    const obj = {
      tipoCodigo: tipo,
      mensaje: mensaje
    }
    const data = await RequestHelper.get<any>('subtypes', 'cboxLoad', "", obj);
    return data;
  } catch (error) {
    return error;
  }
}

async function crearProveedor(objeto: any): Promise<any> {
  const sesion = SesionService.getCurrentSesion();
  try {

    const obj = {
      infoEmpresa: {
        ruc: sesion.empresa.ruc,
      },
      infoUsuario: {
        usuario: sesion.usuario.codigo,
      },
      infoRegistro: {
        ...objeto
      }
    }
    const data = await RequestHelper.postAll<any>('proveedores', 'save', obj);
    return data;
  } catch (error) {
    return error;
  }
}

async function deleteProveedor(id: any): Promise<any> {
  const sesion = SesionService.getCurrentSesion();
  try {
    const objRequest = {
      ruc: sesion.empresa.ruc,
      usuario: sesion.usuario.codigo,
      codigo: id
    }
    const data = await RequestHelper.deleteRequest<any>('proveedores', 'unsubscribe', "", objRequest);
    return data;
  } catch (error) {
    return error;
  }
}


async function obtenerAsignacionesProveedores(codigoProveedor): Promise<any> {
  try {
    const obj = {
      proveedorCodigo: codigoProveedor
    }
    const data = await RequestHelper.getAll<any>(
      'proveedores/proveedores',
      'assignments/getSupplierAsignation',
      "",
      obj
    );
    return data;
  } catch (error) {
    return error;
  }
}

async function guardarRetProCli(objeto: any): Promise<any> {
  // const sesion = SesionService.getCurrentSesion();
  try {

    const obj = {
      // infoEmpresa: {
      //   ruc: sesion.empresa.ruc,
      // },
      // infoUsuario: {
      //   usuario: sesion.usuario.codigo,
      // },
      infoRegistro: {
        ...objeto
      }
    }
    const data = await RequestHelper.postAll<any>('proveedores/proveedores', 'retProCli/saveAll', obj);
    return data;
  } catch (error) {
    return error;
  }
}