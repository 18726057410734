import { RequestHelper } from '../../../../../../helpers/requestHelper'
import { GuardarTransformacion } from '../types/types'

export const conceptsServices = {
  getConcepts,
  setConcept,
  deleteCOncept,
}

async function getConcepts(data: any): Promise<any> {
  return Promise.resolve()
}
async function setConcept(requestBody: GuardarTransformacion): Promise<any> {
  return Promise.resolve()
}

async function deleteCOncept(performanceCode: number): Promise<any> {
  return Promise.resolve()
}
