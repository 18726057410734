import { GeneralState } from '../types/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LoaderInfo } from '../../../../../store/types'

const initialState: GeneralState = {
  modulo: 'inventario',
  currentAction: 'Buscar',
  currentFunction: '',
  aplicacion: 'kardex',
  loader: {
    show: false,
    mensaje: '',
  },
  collapse: true,
}

const desempenoSlice = createSlice({
  name: 'kardex',
  initialState: initialState,
  reducers: {
    setCurrentAccion(state, action: PayloadAction<string>) {
      state.currentAction = action.payload
    },
    setCurrentFunction(state, action: PayloadAction<string>) {
      state.currentFunction = action.payload
    },
    changeLoaderKardex(state, action: PayloadAction<LoaderInfo>) {
      state.loader = action.payload
    },
    setCollpased(state, action: PayloadAction<boolean>) {
      state.collapse = action.payload
    },
  },
})

export const {
  setCurrentAccion,
  setCurrentFunction,
  changeLoaderKardex,
  setCollpased,
} = desempenoSlice.actions

export const generalReducer = desempenoSlice.reducer
