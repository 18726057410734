import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import RolRolesPagosMain from './components/shared/main'
import { useDispatch, useSelector } from 'react-redux'
import { useMenuOptions } from '../../../../../hooks/useMenuButtonControls'
import { RootState } from '../../../../../store/store'
import { deleteEditData, initDatosEdicion } from './store/editDataReducer'
import { deleteEditDataBackup, setAplicacionModulo } from './store/configReducer'
import { buttonClick, changeCurrentTab, openTab, openTabGenrate, openTabTransfer, rolsCloseTab, setCurrentExecutingAction } from './store/tabsReducer'
import { utilidades } from '../../../../../helpers/utilidades'
import { ButtonTypes } from '../../../../../views/componentes/globalMenu/types'
import { Aplicacion } from '../../../store/types'
import { AccionMenu, CustomButtons, FETCH_STATUS } from '../../../../../store/types';
import { setMenuButtons } from './store/menuReducer'
import { EAplicationsAcatha } from '../../../../../store/enumsAplication'
import { TabTypes } from '../../../../../store/enums'
import { defaultRolesPagoEdicion } from './components/nuevo'
import Dialog from '../../../../../views/componentes/librerias/bootstrap-dialog'

const pathVerificador = '/roles_de_pago'
const RolesPago = () => {


  const dispatch = useDispatch()
  const { getMenuOptions, options } = useMenuOptions()
  const menuState = useSelector((state: RootState) => state.global.menu)
  const dialogRef = React.useRef<any>(null)

  const tabs = useSelector((state: RootState) => state.nomina.rol.rolesPago.tabs)
  const searchStatus = useSelector((state: RootState) => state.nomina.rol.rolesPago.search.status)
  const searchResults = useSelector((state: RootState) => state.nomina.rol.rolesPago.search.resultados)
  const onTabClosing = React.useCallback(
    async (index) => {
      const closedTab = tabs.tabs[index]
      dispatch(rolsCloseTab(closedTab.tabKey ?? index))
      await utilidades.sleep(50)
      dispatch(deleteEditData(closedTab.tabKey ?? index))
      dispatch(deleteEditDataBackup(closedTab.tabKey ?? index))
    },
    [dispatch, tabs.tabs],
  )

  const onSearchExecutingAction = React.useCallback((tabKey: string, type?: ButtonTypes) => {
    dispatch(setCurrentExecutingAction({
      tabKey: tabKey,
      buttonType: type
    }))
  }, [dispatch]);

  const onTabChanged = React.useCallback(
    (tabKey: string) => {
      dispatch(changeCurrentTab(tabKey))
    },
    [dispatch],
  )

  const onConfirmNew = React.useCallback((id) => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea registrar a todos los empleados?`,
      actions: [
        Dialog.Action(
          <span>
            <u>S</u>i
          </span>,
          (dialog) => {
            dialog.hide()
            dispatch(openTabGenrate({ key: 'GENERARROLES', type: TabTypes.generar }))
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>N</u>o
          </span>,
          (dialog) => {
            dialog.hide()
            dispatch(
              initDatosEdicion({
                key: id,
                data: {
                  ...defaultRolesPagoEdicion,
                  loading: true,
                },
              }),
            )
            dispatch(openTab({ key: id }))
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    return
  }, [dispatch])

  const onMenuButtonClick = React.useCallback(async (action: AccionMenu, tab: string = "") => {
    const id = uuidv4()
    switch (action.actionType) {
      case ButtonTypes.find:
        if (tabs.current === 'BUSQUEDA' || tabs.current === "TRANSFERENCIAS") {
          dispatch(buttonClick({
            tabKey: tab === "" ? tabs.current : tab,
            button: action.actionType
          }))
        } else dispatch(changeCurrentTab('BUSQUEDA'))
        break
      case ButtonTypes.new:
        if (tabs.current === 'BUSQUEDA') {
          onConfirmNew(id)
        } else {
          dispatch(
            initDatosEdicion({
              key: id,
              data: {
                ...defaultRolesPagoEdicion,
                loading: true,
              },
            }),
          )
          dispatch(openTab({ key: id }))
        }
        break
      case ButtonTypes.all:
        dispatch(openTabGenrate({ key: 'GENERARROLES', type: TabTypes.generar }))
        break
      case ButtonTypes.transfers:
        dispatch(openTabTransfer({ key: 'TRANSFERENCIAS', type: TabTypes.transferencia }))
        break
      case ButtonTypes.import:
        dispatch(buttonClick({
          tabKey: tab === "" ? tabs.current : tab,
          button: action.actionType
        }))
        break
      case ButtonTypes.delete:
        dispatch(buttonClick({
          tabKey: tab === "" ? tabs.current : tab,
          button: action.actionType
        }))
        break
      case ButtonTypes.edit:
        dispatch(buttonClick({
          tabKey: tab === "" ? tabs.current : tab,
          button: action.actionType
        }))
        break
      case ButtonTypes.save:
        dispatch(buttonClick({
          tabKey: tab === "" ? tabs.current : tab,
          button: action.actionType
        }))
        break
      case ButtonTypes.undo:
        dispatch(buttonClick({
          tabKey: tab === "" ? tabs.current : tab,
          button: action.actionType
        }))
        break
      case ButtonTypes.broom:
        dispatch(buttonClick({
          tabKey: tab === "" ? tabs.current : tab,
          button: action.actionType
        }))
        break
      case ButtonTypes.payments:
        dispatch(buttonClick({
          tabKey: tab === "" ? tabs.current : tab,
          button: action.actionType
        }))
        break
      case ButtonTypes.verAsiento:
        dispatch(buttonClick({
          tabKey: tab === "" ? tabs.current : tab,
          button: action.actionType
        }))
        break
      case ButtonTypes.check:
        dispatch(buttonClick({
          tabKey: tab === "" ? tabs.current : tab,
          button: action.actionType
        }))
        break
      case ButtonTypes.salaries:
        dispatch(buttonClick({
          tabKey: tab === "" ? tabs.current : tab,
          button: action.actionType
        }))
        break
      case ButtonTypes.print:
        dispatch(buttonClick({
          tabKey: tab === "" ? tabs.current : tab,
          button: action.actionType
        }))
        break
      case ButtonTypes.print_design:
        dispatch(buttonClick({
          tabKey: tab === "" ? tabs.current : tab,
          button: action.actionType
        }))
        break
      case ButtonTypes.attach:
        dispatch(buttonClick({
          tabKey: tab === "" ? tabs.current : tab,
          button: action.actionType
        }))
        break
      case ButtonTypes.rols:
        dispatch(changeCurrentTab('BUSQUEDA'))
        break
      default:
        break
    }
  }, [dispatch, tabs, onConfirmNew])

  const onActionsButtonsLoaded = React.useCallback((actions: Array<AccionMenu>) => {
    if (actions.length > 0) {
      dispatch(setMenuButtons(actions));
    }
  }, [dispatch]);

  const onGetAplicationCode = React.useCallback(() => {
    dispatch(setAplicacionModulo(parseInt(EAplicationsAcatha.RolesPago)))
  }, [dispatch])


  React.useEffect(() => {
    onGetAplicationCode()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (menuState.length > 0 && options.length === 0) {
      getMenuOptions(menuState, 'to', pathVerificador)
    }
  }, [menuState, getMenuOptions, options])

  return (
    <>
      <Dialog ref={dialogRef} />
      <RolRolesPagosMain
        menu={{ loading: false, accions: options }}
        tabs={tabs}
        onTabChanged={onTabChanged}
        codigoModulo={parseInt(EAplicationsAcatha.RolesPago)}
        opciones={OpcionesModeulo}
        onActionsButtonsLoaded={onActionsButtonsLoaded}
        onMenuButtonClick={onMenuButtonClick}
        searching={searchStatus === FETCH_STATUS.LOADING}
        ontabClosing={onTabClosing}
        hayResultadosBusqueda={searchResults.length > 0}
        verificarPuntoVenta
        verificarAutorizacion
        onExecutingAction={onSearchExecutingAction}
        tipoAplicacion={Aplicacion.rolesPago}
      />
    </>
  )
}

export default React.memo(RolesPago)

const OpcionesModeulo = [parseInt(EAplicationsAcatha.RolesPago)];


export const ModuleButtons: CustomButtons = {
  Nuevo: true,
  Buscar: false,
  Editar: false,
  Eliminar: false,
  Guardar: true,
  Imprimir: false,
  Cheque: true,
  Ver_asiento: true,
  Transferencias: true,
  Pagos: true,
  Sueldos: true,
  Roles: true,
  Todos: true,
  Importar: false,
  Deshacer: true,
  Limpiar: false,
  Adjuntar: false,
}

export const ModuleButtonsGen: CustomButtons = {
  Nuevo: true,
  Buscar: false,
  Editar: false,
  Eliminar: false,
  Guardar: true,
  Imprimir: false,
  Cheque: false,
  Ver_asiento: false,
  Transferencias: true,
  Pagos: false,
  Sueldos: false,
  Roles: true,
  Todos: false,
  Importar: false,
  Deshacer: true,
  Limpiar: true,
  Adjuntar: false,
}

export const ModuleButtonsTransfer: CustomButtons = {
  Nuevo: true,
  Buscar: true,
  Editar: false,
  Eliminar: false,
  Guardar: false,
  Imprimir: false,
  Cheque: false,
  Ver_asiento: false,
  Transferencias: false,
  Pagos: false,
  Sueldos: false,
  Roles: true,
  Todos: true,
  Importar: false,
  Deshacer: true,
  Limpiar: true,
  Adjuntar: true,
}
