import React, { memo } from 'react'
import ResultadoBusqueda from './resultadoBusqueda'
import { ToastTypes } from '../../../../../store/types'
import { modalListaRetencionesIVA } from '../../service/retencionesIVA.service'
import { RetencionesIVA } from '../../store/types'
import { TRetencionesOptionsIva } from '../types/type'

interface IBuscarProps extends React.PropsWithChildren {
  onChanged: (data: TRetencionesOptionsIva) => void
  onCancel?: () => void
  funcion: string
  resetFunction: () => void
  ejecutarBusqueda: () => void
  setToast: (sms: string, type: ToastTypes) => void
  playLoader: () => void
  stopLoader: () => void
}

const Buscar = (props: IBuscarProps) => {
  const { onChanged, funcion, setToast, resetFunction, playLoader, stopLoader } = props;

  const [items, setItems] = React.useState<Array<any> | []>([])
  const [seleccion, setSeleccion] = React.useState<any>({
    index: null,
    row: null,
    selectedRow: null,
  });

  const handleSubmit = React.useCallback(async () => {
    resetFunction()
    playLoader()

    try {
      const find = {
        tipoCodigo: 9,
        mensaje: ''
      }
      const data = await modalListaRetencionesIVA.getRetencionesIVA(find)
      console.log('data', data)
      stopLoader()

      if (data.auto && data.auto.length > 0) {
        setToast('(' + data.auto.length + ')' + ' Registros encontrados.', ToastTypes.Success)
        let item: Array<TRetencionesOptionsIva> = []
        item = data.auto.map(function (item: any) {
          return {
            codigo: parseInt(item['codigo'] ?? 0),
            valor: String(item['valor'] ?? ''),
            tipoCodigo: item['tipoCodigo'] ?? '',
            grupo: String(item['grupo'] ?? ''),
            orden: String(item['orden'] ?? ''),
            estado: parseInt(item['estado'] ?? 0),
          }
        })
        setItems(item)
      }

      return await data
    } catch (error) {
      console.error(error)
      stopLoader()
      setToast(JSON.stringify(error), ToastTypes.Danger)
    }
  }, [resetFunction, playLoader, setToast, stopLoader]);

  const onReset = React.useCallback(() => {
    resetFunction()
    setItems([])
  }, [resetFunction]);

  React.useEffect(() => {
    if (funcion === 'Buscar') {
      handleSubmit()
    } else if (funcion === 'Limpiar') {
      onReset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [funcion]);

  return (
    <ResultadoBusqueda
      funcion={''}
      data={items ?? []}
      setToast={setToast}
      capturaSeleccion={(data) => setSeleccion(data)}
      onChanged={onChanged}
    />
  )
}
export default memo(Buscar)
