import React from 'react';
import { EgresosListado } from '../../../../types/types';
import { CCol, CRow } from '@coreui/react-pro';
import { GlobalLabel } from '../../../../../../../../views/componentes/globalLabel/globalLabel';
import { TextBox } from 'devextreme-react';
interface IInputTotalesProps extends React.PropsWithChildren {
  egresos: Array<EgresosListado> | [],
}

const InputTotales: React.FunctionComponent<IInputTotalesProps> = (props) => {
  const { egresos } = props;

  const [totalEsaldosi, setTotalEsaldosi] = React.useState<string>('0.00');
  const [totalInicialb, setTotalInicialb] = React.useState<string>('0.00');
  const [totalInicial, setTotalInicial] = React.useState<string>('0.00');

  const onLoadData = React.useCallback(async (egresos: Array<EgresosListado>) => {

    let saldoinicial: number = 0;
    let saldoboveda: number = 0;
    let totalesaldosi: number | string = 0;

    for (const itemEgreso of egresos) {

      if (itemEgreso.puntoVentaEspecial === 'BOVEDA') {
        saldoboveda = Number(saldoboveda ?? 0) + Number(itemEgreso?.ventaTotal ?? 0);
      }
      else {
        saldoinicial = Number(saldoinicial ?? 0) + Number(itemEgreso?.ventaTotal ?? 0);
      }
    }

    if (saldoboveda > 0) {
      totalesaldosi = (Number(saldoboveda) - Number(saldoinicial)).toFixed(2);
    }
    else {
      totalesaldosi = Number(saldoinicial).toFixed(2);
    }

    setTotalInicial(saldoinicial.toFixed(2));
    setTotalInicialb(saldoboveda.toFixed(2));
    setTotalEsaldosi(totalesaldosi);
  }, []);

  React.useEffect(() => {
    if (egresos) {
      onLoadData(egresos);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [egresos])

  return (
    <CRow className="m-2 row">
      <CCol lg="12" md="12" sm="12" className="d-flex justify-content-end  align-items-center">
        <CCol lg='3' md="2" xs="12">
          <GlobalLabel htmlFor="boveda" className="col-form-label-md">
            <strong> {"BOVEDA"} </strong>
          </GlobalLabel>
          <CRow>
            <CCol md="12">
              <TextBox
                readOnly
                width="100%"
                value={totalInicialb ?? '0'}
                placeholder=""
              />
            </CCol>
          </CRow>
        </CCol>
        <CCol lg='1' md="1" xs="12" style={{ fontSize: '30px', textAlign: 'center', marginTop: '14px' }}>
          <strong>{"-"}</strong>
        </CCol>
        <CCol lg='3' md="2" xs="12">
          <GlobalLabel htmlFor="cajas" className="col-form-label-md">
            <strong> {"CAJAS"} </strong>
          </GlobalLabel>
          <CRow>
            <CCol md="12">
              <TextBox
                readOnly
                width="100%"
                value={totalInicial ?? '0'}
                placeholder=""
              />
            </CCol>
          </CRow>
        </CCol>
        <CCol lg='1' md="1" xs="12" style={{ fontSize: '30px', textAlign: 'center', marginTop: '14px' }}>
          <strong>{"="}</strong>
        </CCol>
        <CCol lg='3' md="2" xs="12">
          <GlobalLabel htmlFor="total" className="col-form-label-md">
            <strong> {"TOTAL"} </strong>
          </GlobalLabel>
          <CRow>
            <CCol md="12">
              <TextBox
                readOnly
                width="100%"
                value={totalEsaldosi ?? '0'}
                placeholder=""
              />
            </CCol>
          </CRow>
        </CCol>
      </CCol>
    </CRow>
  )
}
export default React.memo(InputTotales)
