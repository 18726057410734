import CustomStore from 'devextreme/data/custom_store'
import { RequestHelper } from '../../../../../../helpers/requestHelper'
import { PagedResult, PaginationInfo } from '../../../../../../store/types'
import { AtributosInventario, FiltrosAtributosState, GuardarAtributo } from '../types/types'
import DataSource from 'devextreme/data/data_source'
import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { setNrRegistrosAtributosLista } from '../store/serachReducer'

export const attributesServices = {
  getAttributes,
  useGeAtributosDataSource,
  setAttribute,
  deletetAttribute,
}

async function getAttributes(data: any): Promise<any> {
  try {
    const obj = {
      descripcion: data?.propiedad,
      estado: data?.activo ? 1 : 2,
    }

    const dataApi = await RequestHelper.getAll<any>('inventario/catalogos', 'atributos/getAll', '', obj)
    return dataApi
  } catch (error) {
    return error
  }
}

async function getAttributesPaged(propiedad: string, estado: number, pagination: PaginationInfo): Promise<any> {
  try {
    const obj = {
      descripcion: propiedad,
      estado: estado,
      isPaged: true,
      page: pagination.current,
      limit: pagination.size,
    }

    const apiDataPaged = await RequestHelper.get<any>('inventario/catalogos', 'atributos/getAll', '', obj)
    const apiData = apiDataPaged.auto as Array<any>
    const unidades = parseUnidadApiData(apiData)
    const pagedResult: PagedResult<AtributosInventario> = {
      currentPage: parseInt(apiDataPaged.paginaActual),
      from: parseInt(apiDataPaged.desde),
      to: parseInt(apiDataPaged.hasta),
      count: parseInt(apiDataPaged.totalRegistros),
      result: unidades,
    }
    return pagedResult
  } catch (error) {
    return error
  }
}


// function getDataAtributtesPaged(find: FiltrosAtributosState) {
function useGeAtributosDataSource() {
  const dispatch = useDispatch()

  const getDataAtributtesPaged = useCallback(
    (find: FiltrosAtributosState) => {
      const store = new CustomStore<void, AtributosInventario>({
        useDefaultSearch: true,
        key: 'codigo',
        byKey: async (key) => {
          console.log(key)
        },
        load: async (loadOptions) => {
          const paginInfo: PaginationInfo = {
            current: (loadOptions.skip ?? 0) / (loadOptions.take ?? 0) + 1,
            size: loadOptions.take ?? 10,
          }

          let pagedResult: PagedResult<AtributosInventario> = {
            currentPage: 0,
            from: 0,
            to: 0,
            count: 0,
            result: [],
          }

          pagedResult = await getAttributesPaged(
            find?.propiedad ?? '',
            find?.activo ? 1 : 2,
            paginInfo)

          return {
            data: pagedResult.result,
            totalCount: pagedResult.count,
          }
        },
      })
      const dataSource = new DataSource({
        totalCount: store.totalCount,
        paginate: true,
        pageSize: 10,
        store: store,
        key: 'codigo',
        requireTotalCount: true,
        onChanged: () => {
          dispatch(setNrRegistrosAtributosLista(dataSource.totalCount()))
        },
      })
      return dataSource
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return { getDataAtributtesPaged }
}

async function setAttribute(requestBody: GuardarAtributo): Promise<any> {
  try {
    const obj = {
      ...requestBody
    }
    const dataApi = await RequestHelper.postAll<any>('inventario/catalogos', 'atributos/save', obj)
    return dataApi
  } catch (error) {
    return error
  }
}

async function deletetAttribute(performanceCode: number): Promise<any> {
  try {
    const obj = {
      codigo: performanceCode
    }
    const dataApi = await RequestHelper.deleteRequestAll<any>('inventario/catalogos', 'atributos/delete', '', obj)
    return dataApi
  } catch (error) {
    return error
  }
}

function parseUnidadApiData(apiData: Array<any>): Array<AtributosInventario> {
  const atributos = apiData.map((u) => {
    const atributo: AtributosInventario = {
      codigo: u.codigo,
      descripcion: u.descripcion,
      opciones: u.opciones,
      estado: u.estado,
      orden: u.orden,
      idwoo: 0
    }
    return atributo
  })
  return atributos
}
