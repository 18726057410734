import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { OptionGrupos, Seleccionado } from '../../../../store/types'
import { RolesUsuariosState, Organico, RolesAsignar } from './types'

const initialState: RolesUsuariosState = {
  modulo: 'rolesUsuarios',
  currentAction: 'Buscar',
  currentFunction: '',
  organicos: [],
  rolesAsignar: [],
  seleccionado: {
    index: null,
    row: null,
    selectedRow: null,
  },
  tipos: [],
  estados: [],
}

const rolesUsuariosSlice = createSlice({
  name: 'rolesUsuarios',
  initialState: initialState,
  reducers: {
    setCurrentAccion(state, action: PayloadAction<string>) {
      state.currentAction = action.payload
    },
    setCurrentFunction(state, action: PayloadAction<string>) {
      state.currentFunction = action.payload
    },
    setData(state, action: PayloadAction<Array<Organico>>) {
      state.organicos = action.payload
    },
    setRolesAsignar(state, action: PayloadAction<Array<RolesAsignar>>) {
      state.rolesAsignar = action.payload
    },
    setSeleccionarDato(state, action: PayloadAction<Seleccionado>) {
      const toAdd = {
        index: action.payload.index,
        row: action.payload.row,
        selectedRow: action.payload.selectedRow,
      }
      state.seleccionado = toAdd
    },
    setResetSeleccion(state) {
      const toAdd = {
        index: null,
        row: null,
        selectedRow: null,
      }
      state.seleccionado = toAdd
    },
    setTiposOptions(state, action: PayloadAction<Array<OptionGrupos>>) {
      state.tipos = action.payload
    },
    setOptionsEstados(state, action: PayloadAction<Array<OptionGrupos>>) {
      state.estados = action.payload
    },
  },
})
export const {
  setOptionsEstados,
  setData,
  setCurrentAccion,
  setSeleccionarDato,
  setResetSeleccion,
  setTiposOptions,
  setCurrentFunction,
  setRolesAsignar,
} = rolesUsuariosSlice.actions
export const RolesUsuariosReducer = rolesUsuariosSlice.reducer
