import React from 'react'
import { DocumentInfo, ToastTypes } from '../../../../../../../store/types'
import { PersonalDatosEdicion } from '../../../types/types'
import { TabState } from '../../../../../../../store/genericTabTypes'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput'
import { Button, DateBox, TextBox, Validator } from 'devextreme-react'
import LocalesLookUp from '../../../../../../componentes/localesLookUp'
import { setChangeLoaderEdit, setDatosEdicionLogin } from '../../../store/editDataReducer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import TiposPersonasLookUp from '../../../../../../componentes/tiposPersonasLookUp'
import { consoleService } from '../../../../../../../services/console.service'
import { adminService } from '../../../../../../admin/cuenta/usuario/services/adminService'
import { addToast } from '../../../../../../../store/toasterReducer'
import ImageViewer from '../../../../../../componentes/imagen'
import { AsyncRule, RequiredRule } from 'devextreme-react/data-grid'
import validations from '../../../../../../../config/validations'
import Barcode from '../../../../../../../views/componentes/barcode/barcode'

interface INewProps extends React.PropsWithChildren {
  info: DocumentInfo<PersonalDatosEdicion>
  tab: TabState<PersonalDatosEdicion>
  tabId: string
  refForm: any
}


const Login: React.FunctionComponent<INewProps> = (props) => {

  const { tabId } = props
  const dispatch = useDispatch()
  const loginState = useSelector((state: RootState) => state.nomina.personal.editData[tabId].login)
  const sms = validations['es']
  const lugarMessage: string = 'Local/CC: debe eligir una opción'

  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(
      addToast({
        title: 'Nómina - Personal',
        content: mensaje,
        type: tipo,
      }),
    )
  }, [dispatch])

  const onLoader = React.useCallback((showLoader: boolean, text: string) => {
    dispatch(setChangeLoaderEdit({
      key: tabId,
      data: {
        show: showLoader,
        mensaje: text
      }
    }))
  }, [dispatch, tabId])


  const onChangeValue = React.useCallback((data, key: string) => {
    dispatch(setDatosEdicionLogin({
      key: tabId,
      data: {
        ...loginState,
        [key]: data
      }
    }))
  }, [dispatch, loginState, tabId])

  const generarNewPassword = React.useCallback(async () => {
    onLoader(true, 'Generando Password...')
    try {
      const data = await adminService.generarPassword()
      if (data['error'] === false) {
        if (data['auto'] && data['auto'] !== "") {
          dispatch(setDatosEdicionLogin({
            key: tabId,
            data: {
              ...loginState,
              password: data['auto'],
              confirmarPassword: data['auto'],
            }
          }))
        }
      }
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
    onLoader(false, '')
  }, [setToast, onLoader, dispatch, loginState, tabId])

  const validateAsyncSelect = React.useCallback((datoSeleccion: any, message: string) => {
    if (datoSeleccion.value.codigo > -1) {
      return Promise.resolve()
    } else {
      return Promise.reject(message)
    }
  }, [])


  const validateAsyncSeleccionLugar = React.useCallback((datoSeleccion) => {
    return validateAsyncSelect(datoSeleccion, lugarMessage)
  }, [validateAsyncSelect])


  return (
    <>
      <RowContainer>
        <CustomCol xs="12" md="4">
          <Labeled label='Creado:'>
            <DateBox
              value={loginState?.creado ?? ''}
              onValueChange={(data) => onChangeValue(data, 'creado')}
            />
          </Labeled>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="4">
          <Labeled label='Usuario:'>
            <TextBox
              value={loginState?.usuario ?? ''}
              onValueChange={(data) => onChangeValue(data, 'usuario')}
            >
              <Validator>
                <RequiredRule message={'Usuario: ' + sms['required']} />
              </Validator>
            </TextBox>
          </Labeled>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="4">
          <Labeled label='Password:'>
            <div
              style={{
                display: 'flex',
                gap: '2px',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <TextBox
                value={loginState?.password ?? ''}
                showClearButton={true}
                mode='password'
                width="50%"
                inputAttr={{ autocomplete: 'nope' }}
                onValueChange={(data) => onChangeValue(data, 'password')}
              >
                <Validator>
                  <RequiredRule message={'Password: ' + sms['required']} />
                </Validator>
              </TextBox>
              <Button
                id="btnGenrarPassword"
                className="me-1"
                stylingMode="contained"
                type="default"
                onClick={() => generarNewPassword()}
              >
                {'Generar Password'}
              </Button>
            </div>
          </Labeled>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="4">
          <Labeled label='Confirmar Password:'>
            <TextBox
              value={loginState?.confirmarPassword ?? ''}
              mode='password'
              inputAttr={{ autocomplete: 'nope' }}
              width="50%"
              onValueChange={(data) => onChangeValue(data, 'confirmarPassword')}
            >
              <Validator>
                <RequiredRule message={'Confirmar Password: ' + sms['required']} />
              </Validator>
            </TextBox>
          </Labeled>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="4">
          <Labeled label='Código de Pase:'>
            <TextBox
              mode='password'
              value={loginState?.codigoPase ?? ''}
              onValueChange={(data) => onChangeValue(data, 'codigoPase')}
            >
              <Validator>
                <RequiredRule message={'Codigo de Pase: ' + sms['required']} />
              </Validator>
            </TextBox>
            {loginState?.codigoPase !== "" && loginState?.codigoPase !== null && loginState?.codigoPase !== undefined && (
              <div id="barcode-to-print" style={{ cursor: 'pointer' }}>
                <Barcode text={loginState?.codigoPase} />
              </div>
            )}
          </Labeled>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="4">
          <Labeled label='Lugar o CC:'>
            <LocalesLookUp
              onChanged={(data) => onChangeValue(data, 'lugarCC')}
              onChangedOptions={() => { }}
              selected={loginState?.lugarCC ?? null}
            >
              <Validator>
                <RequiredRule message={lugarMessage} />
                <AsyncRule
                  message={lugarMessage}
                  validationCallback={validateAsyncSeleccionLugar}
                />
              </Validator>
            </LocalesLookUp>
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="4">
          <Labeled label='Tipo Usuario:'>
            <TiposPersonasLookUp
              provider={[]}
              onChanged={(data) => {
                consoleService.log('data ', data)
                onChangeValue(data, 'tipoUsuario')
              }}
              onChangedOptions={() => { }}
              selected={loginState?.tipoUsuario ?? null}

            />
          </Labeled>
        </CustomCol>
      </RowContainer>
    </>
  )
}

export default React.memo(Login)