import { combineReducers } from '@reduxjs/toolkit'
import { ModuloInventariosState } from './types'
import { MultistoreReducer } from '../pages/multialmacen/store/multistoreReducers'
import { ItemsReducer } from '../pages/items/store/itemsReducers'
import { KardexReducer } from '../pages/kardex/store/kardexReducers'
import { SeriesReducer } from '../pages/series/store/seriesReducers'
import { LotesReducer } from '../pages/lotes/store/lotesReducers'
import { ProjectsCCReducer } from '../pages/proyectos/store/projectsCCReducers'
import { VerificadorPreciosReducer } from '../pages/verificarPrecios/store/verificadorPreciosReducers'
import { CatalogosReducer } from '../pages/catalogos/store/CatalogosReducer'
import { MovimientosReducer } from '../pages/movimientos/store/movimientosReducers'
import { globalChangesReducer } from './globalReducerInventarios'

export const moduloInventarioReducer = combineReducers<ModuloInventariosState>({
  global: globalChangesReducer,
  multialmacen: MultistoreReducer,
  items: ItemsReducer,
  kardex: KardexReducer,
  series: SeriesReducer,
  lotes: LotesReducer,
  proyectos: ProjectsCCReducer,
  verificadorPrecios: VerificadorPreciosReducer,
  catalogos: CatalogosReducer,
  movimientos: MovimientosReducer,
})
