import React from 'react'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput'
import {
  Button,
  CheckBox,
  DataGrid,
  DateBox,
  NumberBox,
  TextArea,
  TextBox,
  ValidationGroup,
  ValidationSummary,
  Validator,
} from 'devextreme-react'
import { EInvIniTipo, FiltrosInventariosMovState, GuardarIventarioInicial, InventarioInicialDto, InventarioItem, InventarioMovDatosEdicion, TotalItem } from '../../types/types'
import { ButtonTypes } from '../../../../../../../views/componentes/globalMenu/types'
import { clearButtonClick, openTab, setButtons, setCurrentExecutingAction, setNameTabe } from '../../store/tabsReducer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import {
  setCurrentFunction,
} from '../../store/generalReducer'
import { changeLoaderImport, initDatosEdicion, onChangeDetall, onChangeTotales, setDatosEdicion } from '../../store/editDataReducer'
import Dialog from '../../../../../../../views/componentes/librerias/bootstrap-dialog'
import { v4 as uuidv4 } from 'uuid'
import { TabState } from '../../../../../../../store/genericTabTypes'
import { CLink, CTooltip } from '@coreui/react-pro'
import LocalesLookUp from '../../../../../../componentes/localesLookUp'
import { Column, ColumnChooser, RequiredRule, Button as DatagridButton, Editing, Paging, Pager, AsyncRule, PatternRule, Format } from 'devextreme-react/data-grid'
import { footerStyles } from '../../../ingresosEgresos/components/nuevo'
import BuscarItemPedidoMemoLookUp from '../../../../../../ventas/pages/ordenes/pages/ordenPedido/components/busquedaPedidos/BusquedaItemsSelectBox/BuscarItemPedidoMemoLookUp'
import { DateUtils, formatoFechasApi } from '../../../../../../../helpers/dateUtils'
import { EModulosAcatha } from '../../../../../../../store/enumsAplication'
import { DocumentInfo, ETipoAsociado, ToastTypes } from '../../../../../../../store/types'
import CIcon from '@coreui/icons-react'
import { cilInfo } from '@coreui/icons'
import validations from '../../../../../../../config/validations'
import { consoleService } from '../../../../../../../services/console.service'
import { StatesEdition } from '../../../../../../ventas/types/enums'
import { incomeExpensesServices } from '../../../ingresosEgresos/services/ingresosEgresos.services'
import { getLocales } from '../../../../../../componentes/localesLookUp/helper/helper'
import BlockUi from '../../../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../../../views/componentes/loadingindicator/loadingindicator'
import { getIndexProvider } from '../../../../../../shared/helpers/funciones'
import { addToast } from '../../../../../../../store/toasterReducer'
import VisualizaError from '../../../../../../ventas/pages/shared/visualizaError/visualizaError'
import ListErrosValidationForm from '../../../../../../componentes/listadoErroresValidacionForm'
import { isMobile } from 'react-device-detect'
import { getAllowedPageSizes } from '../../../../../../../helpers/Helper'
import { MessagesKeys, lh } from '../../../../../../../helpers/localizationHelper'
import { conceptsServices } from '../../services/conceptos.services'
import { utilidades } from '../../../../../../../helpers/utilidades'
import { ModuleButtons } from '../../index';
import { OrdenPedidoService } from '../../../../../../ventas/pages/ordenes/pages/ordenPedido/services/ordenPedido.services'
import { onparseItem, parseDetalleItems } from '../helpers/helpers'
import { ReportWithLocalDataModal } from '../../../../../../../views/componentes/xtrareports/ReportWithLocalDataModal'
import { AdminModalLocales } from '../../../../../../componentes/modalAdminLocales/modalAdminLocales'
import { ItemPedido } from '../../../../../../ventas/pages/ordenes/pages/ordenPedido/type/types'
import MainModalItems from '../../../../../../ventas/components/items/busquedaItemsModal/MainModalItems'
import config from '../../../../../../../config/config'

interface INewProps extends React.PropsWithChildren {
  info: DocumentInfo<InventarioMovDatosEdicion>
  tab: TabState<InventarioMovDatosEdicion>
  tabId: string
}

export const optionsPrioridad = [
  { value: false, label: 'Descripcion' },
  { value: true, label: 'Barras' },
]

const Nuevo: React.FunctionComponent<INewProps> = (props) => {
  const { tab, tabId, info } = props
  const dispatch = useDispatch()
  const invIniiState = useSelector((state: RootState) => state.inventarios.movimientos.inventarioInicial.editData[tabId])
  const tabs = useSelector((state: RootState) => state.inventarios.movimientos.inventarioInicial.tabs)
  const verRegistro = useSelector((state: RootState) => state.inventarios.movimientos.inventarioInicial.search.verRegistro)
  const searchFilter = useSelector((state: RootState) => state.inventarios.movimientos.inventarioInicial.search.filter)
  const loading = useSelector((state: RootState) => state.inventarios.movimientos.inventarioInicial.editData[tabId].loading)
  const loader = useSelector((state: RootState) => state.inventarios.movimientos.inventarioInicial.editData[tabId].loader)
  const modulo = useSelector((state: RootState) => state.inventarios.movimientos.inventarioInicial.config.aplicacion)
  const [moreOptions, setMoreOptions] = React.useState<boolean>(false)
  const [changes, setChanges] = React.useState<any>();
  const genericErrorMensaje: string = lh.getMessage(MessagesKeys.GlobalErrorSave)
  const [reporte, setReporte] = React.useState<null | 'Viewer' | 'Designer'>(null)
  const [showModalLocal, setShowModalLocal] = React.useState<boolean>(false)

  const empresa = useSelector((state: RootState) => state.global.session.empresa)
  const usuarioActivo = useSelector((state: RootState) => state.global.session.usuario)
  const localSession = useSelector((state: RootState) => state.global.session.local)
  const peridoActivo = useSelector(
    (state: RootState) => state.shared.providers.periodoContable,
  )
  const sms = validations['es']
  const validationFormRef = React.useRef<any>()
  const [arrayErrors, setArrayErrors] = React.useState<Array<string>>([])
  const [showErrorPopup, setShowErrorPopup] = React.useState<boolean>(false)
  const [selectedDetalle, setSelectedDetalle] = React.useState(null);
  const [editRowKey, setEditRowKey] = React.useState<any>();
  const [newRow,] = React.useState<any>({
    codigo: 0,
    usuarioCodigo: 0,
    impuesto: 0,
    codigoUsuario: '',
    barras: '',
    unidadDescripcion: '',
    porcentaje: 0,
    descuento: 0,
    descripcion: '',
    cantidad: 0,
    lotes: 0,
    precio: 0,
    pvd: 0,
    numeroSerie: 0,
    valorDescripcion: 0,
    valor: 0,
    fraccion: 0,
  })
  const [confirmNew, setonfirmNew] = React.useState<boolean>(false)
  const dialogRef = React.useRef<any>(null)
  const inventoryDatagrid = React.useRef<any>()
  const fechaFormato: string = 'dd/MM/yyyy'
  const fechaFormatoInput: string = 'yyyy-MM-dd'
  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(
      addToast({
        title: 'Inventario - Inventario Inicial',
        content: mensaje,
        type: tipo,
      }),
    )
  }, [dispatch])
  const getFilterString = React.useCallback((filter: FiltrosInventariosMovState) => {
    const filterText = ''
    return filterText
  }, [])

  const playLoader = React.useCallback(
    (message = 'Cargando...') => {
      dispatch(changeLoaderImport({
        key: tabId,
        loader: { show: true, mensaje: message }
      }))
    },
    [dispatch, tabId],
  )

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoaderImport({
      key: tabId,
      loader: { show: false, mensaje: '' }
    }))
  }, [dispatch, tabId])

  const setButtonExecuttion = React.useCallback((tipo: ButtonTypes | undefined) => {
    dispatch(
      setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: tipo,
      }),
    )
  }, [dispatch, tabId])


  const onChangesChange = React.useCallback((changes) => {
    setChanges(changes);
  }, [])
  const onEditRowKeyChange = React.useCallback((editRowKey) => {
    setEditRowKey(editRowKey);
  }, [])

  const onTotales = React.useCallback((data = []) => {
    consoleService.log('onTotalesCon')
    consoleService.log(data)
    const datCon = { ...invIniiState }
    let subtotal12: number = 0
    let subtotal0: number = 0
    let ivatotal: number = 0
    let descuentototal: number = 0
    let descuento: number = 0
    let precio: number = 0
    let cantidad: number = 0

    data.forEach(item => {
      cantidad = parseInt(item?.cantidad) ?? 0
      precio = item?.precio ?? 0
      descuento = item?.descuento ?? 0
      if (item?.porcentaje !== 0 && item?.impuesto !== null) {
        subtotal12 += item?.valor ?? 0
      } else {
        subtotal0 += item?.valor ?? 0
      }
      descuentototal += (precio * cantidad) * descuento / 100
    })
    datCon.subtotalIva = subtotal12
    datCon.subtotal0 = subtotal0
    datCon.subtotal = subtotal12 + subtotal0
    ivatotal = datCon.subtotalIva * datCon.iva / 100
    datCon.totalIva = ivatotal
    datCon.total = datCon.subtotalIva + subtotal0 + ivatotal
    dispatch(onChangeTotales({
      key: tabId,
      subtotalIva: subtotal12,
      subtotal0: subtotal0,
      subtotal: subtotal12,
      totalIva: ivatotal,
      total: datCon.subtotalIva + subtotal0 + ivatotal,
    }))
  }, [dispatch, invIniiState, tabId])


  const onGetIVA = React.useCallback(async (fecha: string) => {
    try {
      const fechaIva = DateUtils.format(fecha, 'dd/MM/yyyy', 'yyyy-MM-dd')
      const iva = await incomeExpensesServices.getIva(fechaIva, empresa?.codigoTipoNegocio ?? 0, empresa?.codigoPais ?? 0)
      if (iva !== null && iva !== undefined && iva['error'] === false) {
        return iva['auto']
      }
      return 0
    } catch (error) {
      return 0
    }
  }, [empresa])

  const modoNuevo = React.useCallback(async (template: number, limpiar: boolean = false) => {
    if (loading === false && !limpiar) {
      return
    }
    setButtonExecuttion(ButtonTypes.new)
    playLoader()
    const data = { ...defaultDataInventory }
    const fechaPeriodo = peridoActivo.length > 0 ? peridoActivo[0].fcierre : DateUtils.getCurrentDateAsString()

    const resLocal = await getLocales('Elija un almacén')
    const IVARes = await onGetIVA(fechaPeriodo)
    if (resLocal.length > 0) {
      const provider = resLocal.slice(0)
      const index = await getIndexProvider(provider, 'codigo', localSession?.codigo ?? -1)
      if (index > -1) {
        data.inventarioDe = resLocal[index]
      } else {
        data.inventarioDe = resLocal[0]
      }
    }
    const fecha = peridoActivo.length > 0 ? DateUtils.format(peridoActivo[0].fcierre, fechaFormatoInput, fechaFormato) : DateUtils.getCurrentDateAsString(fechaFormato)

    data.fechaKardex = fecha
    data.nroInventario = null
    data.dataGrid = [...data.dataGrid, { ...newRow }]
    data.iva = IVARes
    data.loading = false
    data.realizadoPor = `${usuarioActivo?.nombres ?? 'usuario'} ${usuarioActivo?.apellidos ?? 'usuario'}`
    consoleService.log('modoNuevo')
    dispatch(setDatosEdicion({
      key: tabId,
      inventario: data
    }))
    stopLoader()
    setButtonExecuttion(undefined)
  }, [dispatch, loading, playLoader, stopLoader, tabId, onGetIVA, peridoActivo, usuarioActivo, setButtonExecuttion, localSession, newRow])

  const cargarInventario = React.useCallback(async (loder: boolean, inventarioData) => {
    if (loder === false) {
      return
    }
    dispatch(
      setButtons({
        tabKey: tabId,
        buttons: {
          ...ModuleButtons,
          Imprimir: true,
          Guardar: !verRegistro,
          Deshacer: !verRegistro,
          Limpiar: !verRegistro,
        },
      }),
    )
    consoleService.log(inventarioData)
    const dataEdit = { ...defaultDataInventory }
    setButtonExecuttion(ButtonTypes.edit)
    try {
      playLoader('Cargando Registro...')
      const fechaPeriodo = inventarioData?.fecha ?? DateUtils.getCurrentDateAsString()
      const fechEdit = inventarioData?.fecha ? DateUtils.format(inventarioData?.fecha, 'yyyy-MM-dd', 'dd/MM/yyyy') : DateUtils.getCurrentDateAsString()
      const resLocal = await getLocales('Elija un almacén')
      const IVARes = await onGetIVA(fechaPeriodo)
      const detalle = await OrdenPedidoService.getOrdenDetalle(inventarioData?.codigo ?? 0)
      dataEdit.dataGrid = await parseDetalleItems(detalle)
      dataEdit.dataGrid.push({ ...newRow })
      if (resLocal.length > 0) {
        const provider = resLocal.slice(0)
        const index = await getIndexProvider(provider, 'codigo', parseInt(inventarioData?.cono) ?? -1)
        if (index > -1) {
          dataEdit.inventarioDe = provider[index]
        } else {
          dataEdit.inventarioDe = provider[0]
        }
      }
      consoleService.log(dataEdit.inventarioDe)
      dataEdit.fechaKardex = fechEdit
      dataEdit.nroInventario = inventarioData?.numero
      dataEdit.codigo = inventarioData?.codigo
      dataEdit.iva = IVARes
      dataEdit.cantidad = 1
      dataEdit.realizadoPor = inventarioData?.usuarioNombre
      dataEdit.observaciones = inventarioData?.observaciones
      dataEdit.loading = false
      dispatch(setDatosEdicion({
        key: tabId,
        inventario: dataEdit
      }))
      stopLoader()
    } catch (error) {
      stopLoader()
      setToast(error.message, ToastTypes.Danger)
    }
    setButtonExecuttion(undefined)
  }, [dispatch, tabId, stopLoader, playLoader, setToast, setButtonExecuttion, onGetIVA, verRegistro, newRow])


  const onConfirmNew = React.useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea crear un nuevo registro?`,
      actions: [
        Dialog.Action(
          <span>
            <u>P</u>estaña Actual
          </span>,
          (dialog) => {
            dialog.hide()
            modoNuevo(0, true)
          },
          'btn-success',
          'p',
        ),
        Dialog.Action(
          <span>
            <u>N</u>ueva Pestaña
          </span>,
          (dialog) => {
            dialog.hide()
            const id = uuidv4()
            dispatch(
              initDatosEdicion({
                key: id,
                data: {
                  ...defaultDataInventory,
                  loading: true,
                },
              }),
            )
            dispatch(openTab({ key: id }))
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setonfirmNew(false)
    return
  }, [dispatch, modoNuevo])

  const validateState = React.useCallback((selectedState: any) => {
    if (selectedState.value.codigo > -1) {
      return Promise.resolve()
    } else {
      return Promise.reject('Estado: Debe seleccionar una opción')
    }
  }, [])

  const onValidate = React.useCallback(() => {
    const erroresValidate: Array<string> = []
    if (invIniiState?.inventarioDe === null || invIniiState?.inventarioDe === undefined || invIniiState.inventarioDe?.codigo <= 0) {
      erroresValidate.push('Locales: debe elegir un local.')
    }

    if (invIniiState?.dataGrid === null || invIniiState?.dataGrid === undefined || invIniiState.dataGrid.length === 0) {
      erroresValidate.push('Detalle: el documento debe poseer detalle.')
    }

    return erroresValidate
  }, [invIniiState])

  const onHandleSave = React.useCallback(async () => {
    let errores: Array<string> = []
    errores = onValidate()
    const result = validationFormRef.current.instance.validate()
    if (!result.isValid || errores.length > 0) {
      setToast(genericErrorMensaje, ToastTypes.Warning)
      setArrayErrors(errores)
      setShowErrorPopup(true)
    } else {
      consoleService.log(invIniiState)
      let arrayItems: Array<any> = []
      const newArraySave = invIniiState.dataGrid.filter(x => x?.codigo > 0)
      if (newArraySave.length > 0) {
        0
        arrayItems = newArraySave.map(item => {
          return {
            itemCodigo: item?.codigo ?? 0,
            itemBarras: item?.barras ?? '',
            detalleCantidad: item?.cantidad ?? 0,
            itemDescripcion: item?.descripcion ?? '',
            itemUnidadCompraDescripcion: item?.unidadDescripcion ?? '',
            detalleImpuesto: item?.impuesto ?? 0,
            detallePorcentaje: item?.porcentaje ?? 0,
            detalleDescuento: item?.descuento ?? 0,
            detallePrecio: item?.precio ?? 0,
            detalleValor: item?.valor ?? 0,
          }
        })
      }

      const fechSave = invIniiState?.fechaKardex ? DateUtils.format(invIniiState?.fechaKardex, 'dd/MM/yyyy', 'yyyy-MM-dd') : DateUtils.getCurrentDateAsString()

      const data_save: GuardarIventarioInicial = {
        infoRegistro: {
          codigo: invIniiState?.codigo ?? 0,
          numero: invIniiState?.nroInventario ?? '',
          fecha: fechSave,
          vehiculoCodigo: 0,
          vehiculoKilometraje: 0,
          clienteCodigo: 0,
          mesero: usuarioActivo?.codigo ?? 0,
          detalleCono: invIniiState.inventarioDe?.codigo ?? -1,
          detalleTrabajo: '',
          detalleRecepcion: '',
          detalleObservaciones: invIniiState?.observaciones ?? '',
          detalleProforma: '',
          detalleTipo: EInvIniTipo?.tipo ?? '',
          valorDetalle: arrayItems,
        }
      }

      try {
        consoleService.log(data_save)
        setButtonExecuttion(ButtonTypes.save)
        playLoader('Guardando...')
        const data = await conceptsServices.saveInventario(data_save)
        stopLoader()
        setButtonExecuttion(undefined)
        if (data !== null && data !== undefined && data['error'] === false) {
          dispatch(setNameTabe({
            key: tabId,
            codigo: data['auto']?.codigo ?? 0,
            nrDocumento: data['auto']?.numero ?? '',
          }))

          dispatch(setDatosEdicion({
            key: tabId,
            inventario: {
              ...invIniiState,
              nroInventario: data['auto']?.numero ?? '',
            }
          }))
          dispatch(
            setButtons({
              tabKey: tabId,
              buttons: {
                ...ModuleButtons,
                Imprimir: true,
                Guardar: false
              },
            }),
          )
          setToast(data['message'], ToastTypes.Success)
        } else {
          setToast(data['message'], ToastTypes.Danger)
        }
      } catch (error) {
        stopLoader()
        setButtonExecuttion(undefined)
        setToast(error.message, ToastTypes.Danger)
      }
    }
  }, [genericErrorMensaje, onValidate, setToast, dispatch, tabId,
    invIniiState, playLoader, stopLoader, setButtonExecuttion, usuarioActivo])

  const handleButtonClick = React.useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.save:
          if (tabs.current === tabId) onHandleSave()
          break
        case ButtonTypes.new:
          if (tabs.current === tabId) setonfirmNew(true)
          break
        case ButtonTypes.broom:
          if (tabs.current === tabId) modoNuevo(0, true)
          break
        case ButtonTypes.undo:
          if (tabs.current === tabId) {
            if (info?.codigo !== 0) {
              cargarInventario(true, info.info)
            } else {
              modoNuevo(0, true)
            }
          }
          break
        case ButtonTypes.print:
          if (tabs.current === tabId) {
            if (invIniiState?.codigo !== null && invIniiState?.codigo !== undefined && invIniiState?.codigo > 0) {
              setReporte('Viewer')
            } else {
              setToast('Antes de Imprimir debe registrar los cambios', ToastTypes.Warning)
            }
          }
          break
        case ButtonTypes.print_design:
          if (tabs.current === tabId) {
            if (invIniiState?.codigo !== null && invIniiState?.codigo !== undefined && invIniiState?.codigo > 0) {
              setReporte('Designer')
            } else {
              setToast('Antes de Imprimir debe registrar los cambios', ToastTypes.Warning)
            }
          }
          break
        default:
          break
      }
      dispatch(setCurrentFunction(''))
      dispatch(clearButtonClick(tabId))
    },
    [dispatch, onHandleSave, modoNuevo, tabId, tabs, invIniiState?.codigo, setToast, cargarInventario, info],
  )
  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab.globalButtonClick]);

  React.useEffect(() => {
    if (tab.editStatus === StatesEdition.new) {
      modoNuevo(tab?.info?.codigo)
    } else {
      cargarInventario(loading, info.info)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (confirmNew) {
      onConfirmNew()
    }
  }, [confirmNew, onConfirmNew])

  const onSlectedItem = React.useCallback(({ selectedRowsData }) => {
    consoleService.log('detalle slected', selectedRowsData[0])
    setSelectedDetalle(selectedRowsData[0])
  }, [])

  const onRemoveItem = React.useCallback(async (data: any) => {
    const dataState = { ...invIniiState }
    if (data !== null && data !== undefined && data?.codigo !== 0) {
      if (invIniiState?.dataGrid.length > 0) {
        let dataArray = []
        dataArray = invIniiState?.dataGrid.slice(0)
        const provider = dataArray.filter(item => item.codigo > 0)
        const index = await getIndexProvider(provider, 'codigo', data?.codigo)
        if (index > - 1) {
          provider.splice(index, 1)
          dataArray = provider
          onTotales(dataArray)
          dataState.dataGrid = [...dataArray, { ...newRow }]
          consoleService.log('onRemoveItem')
          dispatch(setDatosEdicion({
            key: tabId,
            inventario: dataState
          }))
        }
      }
    } else {
      setToast('Seleccione un item de la tabla para quitarlo', ToastTypes.Warning)
    }
  }, [setToast, onTotales, dispatch, invIniiState, tabId, newRow])


  const setCellCantidad = React.useCallback((rowData, value, currentRowData) => {
    let newCantidad: Array<any> = []
    if (currentRowData !== null && currentRowData !== undefined && value) {
      const detalle = invIniiState?.dataGrid ?? []
      newCantidad = detalle.map((det) => {
        if (currentRowData.codigo === det.codigo) {
          currentRowData.cantidad = value;
          currentRowData.valor = currentRowData.cantidad * currentRowData.precio
          return currentRowData
        }
        return det
      })
      dispatch(onChangeDetall({
        key: tabId,
        detalle: newCantidad,
      }))
      // onTotales(newCantidad)
    }
  }, [invIniiState, dispatch, tabId])

  const setCellPrecio = React.useCallback((rowData, value, currentRowData) => {
    let newCantidad: Array<any> = []
    if (currentRowData !== null && currentRowData !== undefined && value) {
      const detalle = invIniiState?.dataGrid ?? []
      newCantidad = detalle.map((det) => {
        if (currentRowData.codigo === det.codigo) {
          currentRowData.precio = value;
          currentRowData.valor = currentRowData.cantidad * currentRowData.precio
          return currentRowData
        }
        return det
      })
      dispatch(onChangeDetall({
        key: tabId,
        detalle: newCantidad,
      }))
      // onTotales(newCantidad)
    }
  }, [invIniiState, dispatch, tabId])

  const onAddItem = React.useCallback(async (item: ItemPedido, isEnter: boolean = false) => {
    consoleService.log('onAddItem')
    const errorsItem: Array<string> = []
    const cantidad = 1
    if (item === null || item === undefined || item?.codigo === 0) {
      errorsItem.push('Item: seleccione un Item para agregarlo.')
    }
    if (item?.tipo === 5) {
      errorsItem.push('Item: No puede adicionar este tipo de ítem.')
    }
    const precision = empresa !== null ? empresa.precision : 2;

    if (errorsItem.length === 0) {
      let dataArray = []
      let data_grid = null
      const provider = invIniiState?.dataGrid.slice(0)
      dataArray = provider.filter(item => item.codigo > 0)
      const index = await getIndexProvider(dataArray, 'codigo', item?.codigo ?? 0)
      if (index > - 1) {
        consoleService.log('existe')
        const existe: any = { ...dataArray[index] }
        existe.cantidad = parseInt(existe.cantidad) + cantidad
        existe.valor = existe.cantidad * existe.precio
        dataArray[index] = existe
      } else {
        consoleService.log('nuevo')
        const costo: any = Number(item?.costo).toFixed(precision) ?? 0;
        consoleService.log('costo', costo)
        data_grid = {
          codigo: item?.codigo ?? 0,
          usuarioCodigo: usuarioActivo?.codigo ?? 0,
          impuesto: item?.impuesto ?? 0,
          codigoUsuario: item?.codigoInterno ?? '',
          barras: item?.codigoBarras ?? '',
          unidadDescripcion: item?.unidadDescripcion ?? '',
          porcentaje: item?.porcentajeIva ?? 0,
          descuento: 0,
          descripcion: item?.descripcion ?? '',
          cantidad: cantidad ?? 0,
          lotes: parseInt(item?.lotes) ?? 0,
          precio: item?.pvp ?? 0,
          pvd: item?.pvd ?? 0,
          numeroSerie: item?.numeroSerie,
          valorDescripcion: (Number(costo) * cantidad) ?? 0,
          valor: (Number(costo) * cantidad) ?? 0,
          fraccion: item?.fraccion ?? 0, // REVISAR
        }
        dataArray.push(data_grid)
      }
      consoleService.log("dataArray", dataArray)

      dispatch(onChangeDetall({
        key: tabId,
        detalle: [...dataArray, { ...newRow }],
      }))
      return false;

    } else {
      setToast('Tiene errores, por favor verifiquelos', ToastTypes.Warning)
      setArrayErrors(errorsItem)
      setShowErrorPopup(true)
    }
  }, [setToast, dispatch, tabId, empresa, invIniiState, usuarioActivo, newRow])

  const onDBClickItem = React.useCallback(async () => {
    consoleService.log(selectedDetalle)
    const i = await onparseItem(selectedDetalle)
    dispatch(setDatosEdicion({
      key: tabId,
      inventario: {
        ...invIniiState,
        item: i,
        cantidad: selectedDetalle?.cantidad ?? 0
      }
    }))
  }, [selectedDetalle, dispatch, invIniiState, tabId])

  const validateAsyncSeleccionTipoId = React.useCallback((datoSeleccion) => {
    return validateState(datoSeleccion)
  }, [validateState])

  React.useEffect(() => {
    if (invIniiState.dataGrid) {
      const dataArray = invIniiState?.dataGrid.slice(0)
      const provider = dataArray.filter(item => item.codigo > 0)
      onTotales(provider)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invIniiState.dataGrid, invIniiState.iva]);

  const renderSearchItems = React.useCallback(
    (data: any, rowIndex: number, columnIndex: number) => {
      if (
        (data.codigo || invIniiState.dataGrid[rowIndex]?.codigo) &&
        columnIndex === 2
      )
        return (
          <>
            {data.descripcion
              ? data.descripcion
              : invIniiState.dataGrid[rowIndex].descripcion}
          </>
        )
      else if (
        (data.codigo || invIniiState.dataGrid[rowIndex]?.codigo) &&
        columnIndex === 1
      )
        return (
          <>
            {data.barras ? data.barras : invIniiState.dataGrid[rowIndex].barras}
          </>
        )

      return (
        <>
          <BuscarItemPedidoMemoLookUp
            selected={null}
            onChanged={(item: ItemPedido) => {
              if (item) onAddItem(item, false)
            }}
            cliente={null}
            fecha={DateUtils.getCurrentDateAsString(
              formatoFechasApi,
            )}
            autoComplete={true}
            prioridadBarras={columnIndex === 1 ? true : false}
            modulo={EModulosAcatha.MovimientosInventarioInicial}
            tipoAsociado={ETipoAsociado.Clientes}
            movimiento={null}
            local={invIniiState?.inventarioDe !== null && invIniiState?.inventarioDe !== undefined ? invIniiState?.inventarioDe : undefined}
            codigoPrvAsociado={-1}
          />
        </>
      )
    },
    [invIniiState, onAddItem],
  )

  const onParseData = React.useCallback((invIntial: any = {}) => {
    const registros: Array<InventarioInicialDto> = [];
    let registrosItems: Array<InventarioItem> = [];
    let registro: InventarioInicialDto = null
    let registroItem: InventarioItem = null
    let registroTotal: TotalItem = null
    consoleService.log(invIntial)
    const newArraySave = invIniiState.dataGrid.filter(x => x?.codigo > 0)
    if (newArraySave.length > 0) {
      registrosItems = newArraySave.map((item) => {
        registroItem = {
          BarCode: item?.barras ?? '',
          Description: item?.descripcion ?? '',
          Cant: item?.cantidad ?? 0,
          Price: item?.precio ?? 0,
          Value: item?.valor ?? 0,
        }
        return registroItem
      })
    }

    registroTotal = {
      SubTotalIva: invIniiState?.subtotalIva ?? 0,
      SubTotalCero: invIniiState?.subtotal0 ?? 0,
      SubTotal: invIniiState?.subtotal ?? 0,
      Iva: invIniiState?.totalIva ?? 0,
      Totals: invIniiState?.total ?? 0,
    }

    registro = {
      Items: registrosItems,
      Totals: registroTotal,
      Numero: invIniiState?.nroInventario ?? '',
      RealizadoPor: invIniiState?.realizadoPor ?? '',
      Local: invIniiState.inventarioDe?.nombre ?? '',
      Fecha: invIniiState?.fechaKardex ?? '',
      Observaciones: invIniiState?.observaciones ?? '',
    }

    registros.push(registro)
    consoleService.log(registro)

    return registros

  }, [invIniiState]);

  const onMoreOptions = React.useCallback(async () => {
    setMoreOptions(true)
  }, [])

  return (
    <>
      <ReportWithLocalDataModal
        show={reporte !== null}
        onClose={() => setReporte(null)}
        data={onParseData(invIniiState)}
        fileName="StaticInventarioInicial"
        mode={reporte ?? 'Viewer'}
        parameters={{ Reporte_Filtro: getFilterString(searchFilter) }}
        template="StaticInventarioInicial"
        key="reportDesigner"
      />
      {moreOptions && (
        <MainModalItems
          transaccion="ventas"
          show={moreOptions}
          cliente={config.rucConsumidorFinal}
          onCancel={() => {
            setMoreOptions(false)
          }}
          returnItem={(e) => {
            let item = null
            item = { ...e }
            onAddItem(item)
          }}
          modulo={EModulosAcatha.MovimientosInventarioInicial}
          action={'none'}
          itemEditando={selectedDetalle}
          configFiltros={null}
          requestType={null}
          movimiento={null}
          codigoPrvAsociado={-1}
          local={invIniiState?.inventarioDe !== null && invIniiState?.inventarioDe !== undefined ? invIniiState?.inventarioDe : undefined}
        />
      )}
      {showErrorPopup && (
        <VisualizaError
          titulo="Error en Inventario Inicial"
          mensaje={'Error al guardar'}
          content={
            <ListErrosValidationForm
              mainMessage={'Revise los siguientes errores:'}
              errorsList={arrayErrors}
              color={'danger'}
            />
          }
          onOk={() => setShowErrorPopup(false)}
        />
      )}
      {showModalLocal && (
        <AdminModalLocales
          onChanged={(data) => {
            dispatch(setDatosEdicion({
              key: tabId,
              inventario: {
                ...invIniiState,
                inventarioDe: data
              }
            }))
            setShowModalLocal(false)
          }}
          onCancel={() => setShowModalLocal(false)}
          show={showModalLocal}
          modulo={modulo}
        />
      )}
      <div style={{ overflowX: 'hidden' }}>
        <BlockUi
          tag="div"
          loader={LoadingIndicator}
          blocking={loader.show}
          message={loader.mensaje}
        >
          <fieldset disabled={verRegistro}>
            <RowContainer>
              <Dialog ref={dialogRef} />
              <ValidationGroup id={'formInventarioInicial'} ref={validationFormRef}>
                <RowContainer>
                  {!isMobile && (
                    <CustomCol lg="6" md="8" sm="12">
                      <ValidationSummary id="summary"></ValidationSummary>
                    </CustomCol>
                  )}
                </RowContainer>
                <RowContainer>
                  <CustomCol xs="12" md="3">
                    <Labeled label="Inventario Nro:">
                      <div
                        style={{
                          display: 'flex',
                          gap: '2px',
                          alignItems: 'center',
                          width: '100%',
                        }}
                      >
                        <TextBox
                          value={invIniiState?.nroInventario ?? null}
                          showClearButton
                          maxLength={7}
                          onValueChanged={(data) => {
                            if (data?.event !== null && data?.event !== undefined) {
                              dispatch(setDatosEdicion({
                                key: tabId,
                                inventario: {
                                  ...invIniiState,
                                  nroInventario: data?.value
                                }
                              }))
                            }
                          }}
                          placeholder="0000000"
                          onKeyDown={utilidades.filtraTeclasNoNumericas}
                          readOnly={verRegistro}
                        />
                        <CTooltip
                          key={'tool-more-auth-side'}
                          placement="top"
                          content={
                            'Nro. de Proforma Tentativo. El real se mostrará en la impresión.'
                          }
                        >
                          <div className="card-header-actions">
                            <CLink className="card-header-action m-0">
                              <CIcon icon={cilInfo} className="ms-1 me-1" />
                            </CLink>
                          </div>
                        </CTooltip>
                      </div>
                    </Labeled>
                  </CustomCol>
                  <CustomCol xs="12" md="2">
                    <Labeled label="Realizado por:">
                      <TextBox value={invIniiState?.realizadoPor ?? ''} readOnly />
                    </Labeled>
                  </CustomCol>
                  <CustomCol xs="12" md="3">
                    <Labeled label="Inventario de:">
                      {!isMobile && (
                        <div
                          style={{
                            display: 'flex',
                            gap: '2px',
                            alignItems: 'center',
                            width: '100%',
                          }}
                        >
                          <LocalesLookUp
                            onChanged={(data) => {
                              dispatch(setDatosEdicion({
                                key: tabId,
                                inventario: {
                                  ...invIniiState,
                                  inventarioDe: data
                                }
                              }))
                            }}
                            disabled={verRegistro}
                            onChangedOptions={() => { }}
                            selected={invIniiState?.inventarioDe ?? null}
                          >
                            <Validator>
                              <RequiredRule
                                message={'Local: Debe seleccionar una opción'}
                              />
                              <AsyncRule
                                message={'Local: Debe seleccionar una opción'}
                                validationCallback={validateAsyncSeleccionTipoId}
                              />
                            </Validator>
                          </LocalesLookUp>
                          <CustomCol
                            md="3"
                            xs="12"
                            className={
                              isMobile
                                ? 'd-flex justify-content-end m-2 p-0'
                                : 'd-flex justify-content-start m-0 p-0'
                            }
                          >
                            {(invIniiState?.inventarioDe !== null && invIniiState.inventarioDe?.codigo > 0 && !verRegistro) && (
                              <>
                                <CTooltip content="Quitar local seleccionado">
                                  <div>
                                    <Button
                                      className="ms-1"
                                      stylingMode="contained"
                                      type="danger"
                                      icon="undo"
                                      onClick={() => {
                                        dispatch(setDatosEdicion({
                                          key: tabId,
                                          inventario: {
                                            ...invIniiState,
                                            inventarioDe: null
                                          }
                                        }))
                                      }}
                                    ></Button>
                                  </div>
                                </CTooltip>
                                <CTooltip content="Añadir un local">
                                  <div>
                                    <Button
                                      id="btnAdd"
                                      className="me-1"
                                      icon="add"
                                      stylingMode="contained"
                                      type="default"
                                      onClick={() => setShowModalLocal(true)}
                                    />
                                  </div>
                                </CTooltip>
                              </>
                            )}
                          </CustomCol>
                        </div>
                      )}
                      {
                        isMobile && (
                          <>
                            <LocalesLookUp
                              onChanged={(data) => {
                                dispatch(setDatosEdicion({
                                  key: tabId,
                                  inventario: {
                                    ...invIniiState,
                                    inventarioDe: data
                                  }
                                }))
                              }}
                              disabled={verRegistro}
                              onChangedOptions={() => { }}
                              selected={invIniiState?.inventarioDe ?? null}
                            >
                              <Validator>
                                <RequiredRule
                                  message={'Local: Debe seleccionar una opción'}
                                />
                                <AsyncRule
                                  message={'Local: Debe seleccionar una opción'}
                                  validationCallback={validateAsyncSeleccionTipoId}
                                />
                              </Validator>
                            </LocalesLookUp>
                            <CustomCol
                              md="3"
                              xs="12"
                              className={
                                isMobile
                                  ? 'd-flex justify-content-end m-2 p-0'
                                  : 'd-flex justify-content-start m-0 p-0'
                              }
                            >
                              {(invIniiState?.inventarioDe !== null && invIniiState.inventarioDe?.codigo > 0 && !verRegistro) && (
                                <>
                                  <CTooltip content="Quitar local seleccionado">
                                    <div>
                                      <Button
                                        className="ms-1"
                                        stylingMode="contained"
                                        type="danger"
                                        icon="undo"
                                        onClick={() => {
                                          dispatch(setDatosEdicion({
                                            key: tabId,
                                            inventario: {
                                              ...invIniiState,
                                              inventarioDe: null
                                            }
                                          }))
                                        }}
                                      ></Button>
                                    </div>
                                  </CTooltip>
                                  <CTooltip content="Añadir un local">
                                    <div>
                                      <Button
                                        id="btnAdd"
                                        className="me-1"
                                        icon="add"
                                        stylingMode="contained"
                                        type="default"
                                        onClick={() => setShowModalLocal(true)}
                                      />
                                    </div>
                                  </CTooltip>
                                </>
                              )}
                            </CustomCol>
                          </>
                        )
                      }
                    </Labeled>
                  </CustomCol>
                  <CustomCol xs="12" md="2">
                    <Labeled label="Fecha de Kardex:">
                      <DateBox
                        displayFormat="dd/MM/yyyy"
                        readOnly={verRegistro}
                        value={invIniiState?.fechaKardex ?? null}
                        onValueChanged={async (data) => {
                          if (data?.event !== null && data?.event !== undefined) {
                            const iva = await onGetIVA(data?.value)
                            dispatch(setDatosEdicion({
                              key: tabId,
                              inventario: {
                                ...invIniiState,
                                fechaKardex: data?.value,
                                iva: iva
                              }
                            }))

                          }
                        }}
                      />
                    </Labeled>
                  </CustomCol>
                </RowContainer>
                <RowContainer>
                  {isMobile && (
                    <CustomCol lg="6" md="8" sm="12">
                      <ValidationSummary id="summary"></ValidationSummary>
                    </CustomCol>
                  )}
                </RowContainer>
              </ValidationGroup>
              <RowContainer>
                <CustomCol xs="12" md="4">
                  <Labeled label="Observaciones:">
                    <TextArea
                      value={invIniiState?.observaciones ?? ''}
                      maxLength={500}
                      onValueChanged={(data) => {
                        if (data?.event !== null && data?.event !== undefined) {
                          dispatch(setDatosEdicion({
                            key: tabId,
                            inventario: {
                              ...invIniiState,
                              observaciones: data?.value
                            }
                          }))
                        }
                      }} />
                  </Labeled>
                </CustomCol>
              </RowContainer>
              <RowContainer className="infoButton">
                <CustomCol md="1" xs="12" className="text-right">
                  <RowContainer>
                    <CustomCol lg="12">
                      <CTooltip content="Más Opciónes">
                        <div>
                          <Button
                            className="ms-1"
                            stylingMode="contained"
                            type="default"
                            icon="more"
                            onClick={() => {
                              onMoreOptions()
                            }}
                          ></Button>
                        </div>
                      </CTooltip>
                    </CustomCol>
                  </RowContainer>
                </CustomCol>
              </RowContainer>
              <RowContainer>
                <DataGrid
                  style={{ marginTop: '10px' }}
                  ref={inventoryDatagrid}
                  keyExpr="codigo"
                  id="codigo"
                  focusedRowKey="codigo"
                  selection={{ mode: 'single' }}
                  disabled={verRegistro}
                  dataSource={invIniiState?.dataGrid ?? []}
                  showColumnLines={true}
                  showRowLines={true}
                  showBorders={true}
                  columnResizingMode="widget"
                  onSelectionChanged={onSlectedItem}
                  onRowDblClick={onDBClickItem}
                  loadPanel={{
                    enabled: true,
                    height: 90,
                    indicatorSrc: '',
                    shading: false,
                    shadingColor: '',
                    showIndicator: true,
                    showPane: true,
                    text: 'Cargando...',
                    width: 200,
                  }}
                >
                  <Editing
                    mode="cell"
                    allowUpdating
                    changes={changes}
                    onChangesChange={onChangesChange}
                    editRowKey={editRowKey}
                    onEditRowKeyChange={onEditRowKeyChange}
                  />
                  <Paging defaultPageSize={10} />
                  <Pager
                    visible={invIniiState.dataGrid.length > 0 ? true : false}
                    allowedPageSizes={getAllowedPageSizes(invIniiState.dataGrid)}
                    displayMode={'full'}
                    showPageSizeSelector={true}
                    showInfo={true}
                    showNavigationButtons={true}
                  />
                  <ColumnChooser enabled={true} mode="select" />
                  <Column type="buttons" width='50px'>
                    <DatagridButton
                      icon="trash"
                      hint="Eliminar"
                      visible={(e) => {
                        return e.row?.data.codigo > 0
                      }}
                      disabled={(e) => {
                        consoleService.log('e', e.row?.data)
                        return e.row?.data.codigo <= 0
                      }}
                      onClick={async (e) => {
                        consoleService.log('e', e.row?.data)
                        onRemoveItem(e.row?.data)
                      }}
                    />
                  </Column>
                  <Column
                    allowEditing={false}
                    dataField="barras"
                    cellRender={({ data, rowIndex, columnIndex }) =>
                      renderSearchItems(data, rowIndex, columnIndex)}
                    caption="Codigo"
                    width="100px" />
                  <Column
                    allowEditing={false}
                    dataType='string'
                    cellRender={({ data, rowIndex, columnIndex }) =>
                      renderSearchItems(data, rowIndex, columnIndex)}
                    dataField="descripcion"
                    caption="Descripción"
                    width="300px" />
                  <Column allowEditing={true} setCellValue={setCellCantidad} dataField="cantidad" dataType='number' caption="Cantidad" width="100px" >
                    <Format type='fixedPoint' precision={empresa?.precision ?? 2} />
                    <RequiredRule />
                    <PatternRule
                      message={'Cantidad debe ser un numero entero'}
                      pattern={/^-?\d+$/i}
                    />
                  </Column>
                  <Column allowEditing={true} setCellValue={setCellPrecio} dataField="precio" dataType='number' caption="Precio" width="100px">
                    <Format type='fixedPoint' precision={empresa?.precision ?? 2} />
                  </Column>
                  <Column allowEditing={false} dataField="valor" format={{ precision: empresa?.precision ?? 2 }} caption="Valor" width="100px" />
                </DataGrid>
              </RowContainer>
              <RowContainer>
                <CustomCol xs="12" md="5" className="d-flex justify-content-start" />
                <CustomCol xs="12" md="4" />
                <CustomCol xs="12" md="3">
                  <RowContainer className="mt-2">
                    <CustomCol md="12" className="d-flex justify-content-end">
                      <Labeled label="Subtotal 0:" position="left">
                        <NumberBox
                          value={invIniiState?.subtotal0 ?? null}
                          format={{ precision: empresa?.precision ?? 2 }}
                          showClearButton={true}
                          width="115px"
                          inputAttr={{
                            autocomplete: 'nope',
                            class: 'textInputAlignRight',
                          }}
                          readOnly
                        />
                      </Labeled>
                    </CustomCol>
                  </RowContainer>
                  <RowContainer>
                    <CustomCol md="12" className="d-flex justify-content-end">
                      <Labeled label="Subtotal IVA:" position="left">
                        <NumberBox
                          value={invIniiState?.subtotalIva ?? null}
                          format={{ precision: empresa?.precision ?? 2 }}
                          showClearButton={true}
                          width="115px"
                          inputAttr={{
                            autocomplete: 'nope',
                            class: 'textInputAlignRight',
                          }}
                          readOnly
                        />
                      </Labeled>
                    </CustomCol>
                  </RowContainer>
                  <RowContainer>
                    <CustomCol md="12" className="d-flex justify-content-end">
                      <Labeled
                        label={`Subtotal:`}
                        position="left"
                      >
                        <NumberBox
                          value={invIniiState?.subtotal ?? null}
                          showClearButton={true}
                          format={{ precision: empresa?.precision ?? 2 }}
                          width="115px"
                          inputAttr={{
                            autocomplete: 'nope',
                            class: 'textInputAlignRight',
                          }}
                        />
                      </Labeled>
                    </CustomCol>
                  </RowContainer>
                  <RowContainer>
                    <CustomCol md="12" className="d-flex justify-content-end">
                      <Labeled label="IVA" position="left">
                        <NumberBox
                          value={invIniiState?.totalIva ?? null}
                          showClearButton={true}
                          format={{ precision: empresa?.precision ?? 2 }}
                          width="115px"
                          inputAttr={{
                            autocomplete: 'nope',
                            class: 'textInputAlignRight',
                          }}
                          readOnly
                        />
                      </Labeled>
                    </CustomCol>
                  </RowContainer>
                  <RowContainer>
                    <CustomCol md="12" className="d-flex justify-content-end">
                      <Labeled label="TOTAL:" position="left">
                        <NumberBox
                          value={invIniiState?.total ?? null}
                          format={{ precision: empresa?.precision ?? 2 }}
                          showClearButton={true}
                          width="115px"
                          inputAttr={{
                            autocomplete: 'nope',
                            class: 'textInputAlignRight',
                          }}
                        />
                      </Labeled>
                    </CustomCol>
                  </RowContainer>
                  <RowContainer>
                    <CheckBox
                      style={footerStyles}
                      text="Mostrar IVA en impresión"
                      value={invIniiState?.mostratIva}
                      onValueChanged={(data) => {
                        if (data?.event !== null && data?.event !== undefined) {
                          dispatch(setDatosEdicion({
                            key: tabId,
                            inventario: {
                              ...invIniiState,
                              mostratIva: data?.value
                            }
                          }))
                        }
                      }}
                    />
                  </RowContainer>
                </CustomCol>
              </RowContainer>
            </RowContainer>
          </fieldset>
        </BlockUi>
      </div>
    </>
  )
}

export default React.memo(Nuevo)

export const defaultDataInventory: InventarioMovDatosEdicion = {
  codigo: 0,
  nroInventario: null,
  realizadoPor: '',
  inventarioDe: null,
  loading: false,
  fechaKardex: '',
  observaciones: '',
  dataGrid: [],
  item: null,
  loader: {
    show: false,
    mensaje: ''
  },
  descripcion: '',
  cantidad: 0,
  existencias: null,
  iva: 0,
  pvp: null,
  pvd: null,
  subtotal0: 0,
  subtotalIva: 0,
  subtotal: 0,
  totalIva: 0,
  total: 0,
  mostratIva: false,
  guardado: false,
}
