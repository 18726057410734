import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DateUtils } from '../../../../../../helpers/dateUtils'
import { RootState } from '../../../../../../store/store'
import {
  FETCH_STATUS,
  TiposComprobantesSLV,
} from '../../../../../../store/types'
import { VentasService } from '../../../../services/ventas.service'
import { SearchState } from '../../../../types/generics'
import {
  Cliente,
  EstablecimientoSri,
  EstadoComprobante,
  FiltroBusquedaState,
  FormaPagoModuloVentas,
  PuntoEmisionSri,
  VentaListado,
} from '../../../../types/types'
import { utilidades } from '../../../../../../helpers/utilidades'

const initialState: SearchState<VentaListado> = {
  filter: {
    fechaInicio: DateUtils.getCurrentDateAsString(),
    fechaFin: DateUtils.getCurrentDateAsString(),
    puntoVenta: '',
    cliente: null,
    activas: false,
    documento: '',
    formaPago: null,
    pendientesAbutorizar: false,
    establecimiento: null,
    estado: null,
    puntoEmision: null,
    tipoDoc: TiposComprobantesSLV.FacturaExportacion,
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  resultados: [],
  verJson: false,
  verJsonTab: '',
}

export const fetchVentas = createAsyncThunk<
  Array<VentaListado>,
  FiltroBusquedaState
>('ventas/searchVentasFacturaExportacion', async (filtro) => {
  try {
    const ventas = await VentasService.getVentas(
      0,
      DateUtils.pickersDateToApiDate(filtro.fechaInicio) ?? '',
      DateUtils.pickersDateToApiDate(filtro.fechaFin) ?? '',
      filtro.puntoVenta,
      filtro.documento ?? undefined,
      filtro.estado ? filtro.estado.codigo.toString() : undefined,
      filtro.cliente?.identificacion,
      filtro.pendientesAbutorizar === undefined
        ? undefined
        : !filtro.pendientesAbutorizar,
      filtro.claveAcceso,
      filtro.formaPago ? filtro.formaPago.codigo.toString() : undefined,
      filtro.establecimiento
        ? filtro.establecimiento.establecimiento
        : undefined,
      filtro.puntoEmision ? filtro.puntoEmision.puntoEmision : undefined,
      filtro.tipoDoc,
    )
    let data = ventas
    if (ventas.length > 0) {
      data = utilidades.sortJSON(ventas, 'comprobante', 'desc')
    }
    return data
  } catch (error) {
    return Promise.reject(error)
  }
})

const searchVentasSlice = createSlice({
  name: 'searchVentasFactExpo',
  initialState: initialState,
  reducers: {
    onResetState(state) {
      state.filter = {
        ...initialState.filter,
        fechaInicio: DateUtils.getCurrentDateAsString(),
        fechaFin: DateUtils.getCurrentDateAsString(),
      }
    },
    changeFilter(state, action: PayloadAction<FiltroBusquedaState>) {
      state.filter = action.payload
    },
    setCustomer(state, action: PayloadAction<Cliente | null>) {
      state.filter.cliente = action.payload
    },
    setSalesPoint(state, action: PayloadAction<string>) {
      state.filter.puntoVenta = action.payload
    },
    setDateStart(state, action: PayloadAction<string>) {
      state.filter.fechaInicio = action.payload
    },
    setDateEnd(state, action: PayloadAction<string>) {
      state.filter.fechaFin = action.payload
    },
    setInvoiceNumber(state, action: PayloadAction<string>) {
      state.filter.documento = action.payload
    },
    setInvoiceClaveAcceso(state, action: PayloadAction<string>) {
      state.filter.claveAcceso = action.payload
    },
    setPendientesAutorizar(state, action: PayloadAction<boolean>) {
      state.filter.pendientesAbutorizar = action.payload
    },
    setFormaPago(state, action: PayloadAction<FormaPagoModuloVentas | null>) {
      state.filter.formaPago = action.payload
    },
    setStatus(state, action: PayloadAction<EstadoComprobante | null>) {
      state.filter.estado = action.payload
    },
    setEstablecimiento(
      state,
      action: PayloadAction<EstablecimientoSri | null>,
    ) {
      state.filter.establecimiento = action.payload
    },
    setPuntoEmision(state, action: PayloadAction<PuntoEmisionSri | null>) {
      state.filter.puntoEmision = action.payload
    },
    setCleanResult(state) {
      state.resultados = []
    },
    setResultados(state, action: PayloadAction<Array<VentaListado> | []>) {
      state.resultados = action.payload
    },
    toggleVerJsonFExportacion(state, action: PayloadAction<boolean>) {
      state.verJson = action.payload
    },
    setVerJsonTabFExportacion(state, action: PayloadAction<string>) {
      state.verJsonTab = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchVentas.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchVentas.fulfilled, (state, { payload }) => {
      state.resultados = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchVentas.rejected, (state, { payload }) => {
      state.resultados = []
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
  },
})

export const selectFiltroBusquedaVentas = (state: RootState) => {
  return state.ventas.ventasSV.exportacion.search.filter
}
export const selectResultadosBusquedaVentas = (state: RootState) => {
  return state.ventas.ventasSV.exportacion.search.resultados
}
export const selectBusquedaVentasEstado = (state: RootState) => {
  return state.ventas.ventasSV.exportacion.search.status
}
export const selectBusquedaVerJsonFExportacion = (state: RootState) => {
  return state.ventas.ventasSV.exportacion.search.verJson
}
export const selectBusquedaVerJsonTabFExportacion = (state: RootState) => {
  return state.ventas.ventasSV.exportacion.search.verJsonTab
}
export const {
  setResultados,
  setCustomer,
  setSalesPoint,
  setDateStart,
  setDateEnd,
  setInvoiceNumber,
  setInvoiceClaveAcceso,
  setPendientesAutorizar,
  setFormaPago,
  setEstablecimiento,
  setPuntoEmision,
  setStatus,
  onResetState,
  setCleanResult,
  changeFilter,
  toggleVerJsonFExportacion,
  setVerJsonTabFExportacion,
} = searchVentasSlice.actions
export const searchVentasReducer = searchVentasSlice.reducer
