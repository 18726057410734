import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import OrganicoMain from './components/shared/main'
import { useMenuOptions } from '../../../../hooks/useMenuButtonControls'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'
import { buttonClick, changeCurrentTab, contratosCloseTab, openTab, setCurrentExecutingAction } from './store/tabsReducer'
import { EAplicationsAcatha } from '../../../../store/enumsAplication'
import { AccionMenu, CustomButtons, FETCH_STATUS } from '../../../../store/types'
import { setMenuButtons } from './store/menuReducer'
import { ButtonTypes } from '../../../../views/componentes/globalMenu/types'
import { utilidades } from '../../../../helpers/utilidades'
import { deleteEditData } from './store/editDataReducer'
import { deleteEditDataBackup, setAplicacionModulo } from './store/configReducer'
import { Aplicacion } from '../../store/types'
import { deleteEditDataNomina } from './store/nominaReducer'
import { initDatosEdicion as initDataOrganic } from './store/editDataReducer'
import { defaultOrganicEdition } from './components/nuevo'

const pathVerificador = '/organigrama'
const Organigrama = () => {

  const dispatch = useDispatch()

  const { getMenuOptions, options } = useMenuOptions()
  const tabs = useSelector((state: RootState) => state.nomina.organico.tabs)
  const searchStatus = useSelector((state: RootState) => state.nomina.organico.search.status)
  const searchResults = useSelector((state: RootState) => state.nomina.organico.search.resultados)
  const menuState = useSelector((state: RootState) => state.global.menu)


  const onTabChanged = React.useCallback(
    (tabKey: string) => {
      dispatch(changeCurrentTab(tabKey))
    },
    [dispatch],
  )

  const onSearchExecutingAction = React.useCallback((tabKey: string, type?: ButtonTypes) => {
    dispatch(setCurrentExecutingAction({
      tabKey: tabKey,
      buttonType: type
    }))
  }, [dispatch]);

  const onTabClosing = React.useCallback(
    async (index) => {
      const closedTab = tabs.tabs[index]
      dispatch(contratosCloseTab(closedTab.tabKey ?? index))
      await utilidades.sleep(50)
      dispatch(deleteEditData(closedTab.tabKey ?? index))
      dispatch(deleteEditDataBackup(closedTab.tabKey ?? index))
      dispatch(deleteEditDataNomina(closedTab.tabKey ?? index))
    },
    [dispatch, tabs.tabs],
  )

  const onMenuButtonClick = React.useCallback(async (action: AccionMenu, tab: string = "") => {
    const id = uuidv4()
    switch (action.actionType) {
      case ButtonTypes.find:
        if (tabs.current === 'BUSQUEDA') {
          dispatch(buttonClick({
            tabKey: tab === "" ? tabs.current : tab,
            button: action.actionType
          }))
        } else dispatch(changeCurrentTab('BUSQUEDA'))
        break
      case ButtonTypes.new:
        dispatch(
          initDataOrganic({
            key: id,
            data: {
              ...defaultOrganicEdition,
              loading: true,
            },
          }),
        )
        dispatch(openTab({ key: id }))
        break
      case ButtonTypes.delete:
        dispatch(buttonClick({
          tabKey: tab === "" ? tabs.current : tab,
          button: action.actionType
        }))
        break
      case ButtonTypes.edit:
        dispatch(buttonClick({
          tabKey: tab === "" ? tabs.current : tab,
          button: action.actionType
        }))
        break
      case ButtonTypes.undo:
        dispatch(buttonClick({
          tabKey: tab === "" ? tabs.current : tab,
          button: action.actionType
        }))
        break
      case ButtonTypes.broom:
        dispatch(buttonClick({
          tabKey: tab === "" ? tabs.current : tab,
          button: action.actionType
        }))
        break
      case ButtonTypes.save:
        dispatch(buttonClick({
          tabKey: tab === "" ? tabs.current : tab,
          button: action.actionType
        }))
        break
      case ButtonTypes.assign:
        dispatch(buttonClick({
          tabKey: tab === "" ? tabs.current : tab,
          button: action.actionType
        }))
        break
      case ButtonTypes.print:
        dispatch(buttonClick({
          tabKey: tab === "" ? tabs.current : tab,
          button: action.actionType
        }))
        break
      case ButtonTypes.print_design:
        dispatch(buttonClick({
          tabKey: tab === "" ? tabs.current : tab,
          button: action.actionType
        }))
        break
      default:
        break
    }
  }, [dispatch, tabs])

  const onActionsButtonsLoaded = React.useCallback((actions: Array<AccionMenu>) => {
    dispatch(setMenuButtons(actions));
  }, [dispatch]);


  React.useEffect(() => {
    if (menuState.length > 0 && options.length === 0) {
      getMenuOptions(menuState, 'to', pathVerificador)
    }
  }, [menuState, getMenuOptions, options])


  const onGetAplicationCode = React.useCallback(() => {
    dispatch(setAplicacionModulo(parseInt(EAplicationsAcatha.Organigrama)))
  }, [dispatch])


  React.useEffect(() => {
    onGetAplicationCode()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <OrganicoMain
        menu={{ loading: false, accions: options }}
        tabs={tabs}
        onTabChanged={onTabChanged}
        codigoModulo={parseInt(EAplicationsAcatha.Organigrama)}
        opciones={OpcionesModeulo}
        onActionsButtonsLoaded={onActionsButtonsLoaded}
        onMenuButtonClick={onMenuButtonClick}
        searching={searchStatus === FETCH_STATUS.LOADING}
        ontabClosing={onTabClosing}
        hayResultadosBusqueda={searchResults.length > 0}
        verificarPuntoVenta
        verificarAutorizacion
        onExecutingAction={onSearchExecutingAction}
        tipoAplicacion={Aplicacion.organigrama}
      />
    </>
  )
}

export default React.memo(Organigrama)

const OpcionesModeulo = [parseInt(EAplicationsAcatha.Organigrama)];

export const ModuleButtons: CustomButtons = {
  Nuevo: true,
  Buscar: false,
  Editar: false,
  Eliminar: false,
  Guardar: true,
  Exportar: false,
  Deshacer: true,
  Imprimir: false,
  Limpiar: false,
  Asignar: false,
}
export const ModuleButtonsAsignacion: CustomButtons = {
  Nuevo: true,
  Buscar: false,
  Editar: false,
  Eliminar: false,
  Guardar: true,
  Exportar: false,
  Deshacer: true,
  Imprimir: false,
  Limpiar: false,
  Asignar: false,
}
