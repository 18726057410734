import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MenuState } from '../../../../ventas/types/types'
import { AccionMenu } from '../../../../../store/types'

const initialState: MenuState = {
  loading: true,
  accions: [],
}

const menuOrganicoSlice = createSlice({
  name: 'nominaOrganigramaMenu',
  initialState: initialState,
  reducers: {
    setMenuButtons(state, action: PayloadAction<Array<AccionMenu>>) {
      state.accions = action.payload
      state.loading = false
    },
  },
})

export const { setMenuButtons } = menuOrganicoSlice.actions
export const menuReducer = menuOrganicoSlice.reducer
