import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box'
import React from 'react';
import { useDispatch } from 'react-redux';
import { lh } from '../../../../../helpers/localizationHelper';
import { useModalConvertSize } from '../../../../../hooks/useModalSize';
import { addToast } from '../../../../../store/toasterReducer';
import { TiposComprobantesSLV, TiposComprobantesSri, ToastTypes } from '../../../../../store/types';
import CustomCol from '../../../../../views/componentes/colContainer';
import Container from '../../../../../views/componentes/container';
import Labeled from '../../../../../views/componentes/labeledInput/labeledInput';
import CustomModalDevx from '../../../../../views/componentes/modal/Modal';
import RowContainer from '../../../../../views/componentes/rowContainer/rowContainer';
import { VentaListado } from '../../../types/types';
import { Switch } from 'devextreme-react/switch'
import "./anularDocumentoVenta.scss";
import { VentasService } from '../../../services/ventas.service';
import BuscarDocumentos from './buscarDocumentos/buscarDocumentos';
import BlockUi from '../../../../../views/componentes/librerias/block-ui';
import LoadingIndicator from '../../../../../views/componentes/loadingindicator/loadingindicator';
import { consoleService } from '../../../../../services/console.service';
interface IAnularDocumentoVentaProps extends React.PropsWithChildren {
  onOk: (info: VentaListado) => void,
  onCancel: () => void,
  info?: VentaListado,
  tipoComprobante?: TiposComprobantesSLV | TiposComprobantesSri
}

export type TDteRelated = {
  codigoDocumento: number,
  codigoGeneracion: string
}

export const AnularDocumentoVenta: React.FC<IAnularDocumentoVentaProps> = (props) => {
  const dispatch = useDispatch();
  const [modalSize] = useModalConvertSize("sm");
  const { onOk, onCancel, info, tipoComprobante } = props;
  const [isRelated, setIsRelated] = React.useState<boolean>(false)
  const [related, setRelated] = React.useState<TDteRelated | null>()
  const [dteNumber, setDteNumber] = React.useState<string>('')
  const [dtePopup, setDtePopup] = React.useState<boolean>(false)
  const [documentos, setDocumentos] = React.useState<Array<VentaListado>>([])
  const [loaderLocal, setLoaderLocal] = React.useState<{ show: boolean, message: string }>({ show: false, message: "" });

  const playLoader = React.useCallback(() => {
    setLoaderLocal({ show: true, message: 'Por favor espere...' })
  }, []);


  const stopLoader = React.useCallback(() => {
    setLoaderLocal({ show: false, message: '' })
  }, []);


  const searchDte = React.useCallback(async () => {
    playLoader()
    setRelated(null);
    if (dteNumber === '') {
      stopLoader()
      dispatch(addToast({
        id: '',
        autoHide: 7000,
        title: 'Acatha',
        content: "Debe ingresar el numero de documento relacionado.",
        fade: true,
        type: ToastTypes.Warning
      }));
      return false;
    }
    if ([TiposComprobantesSLV.Factura, TiposComprobantesSLV.NotaCredito, TiposComprobantesSLV.NotaDebito, TiposComprobantesSLV.CreditoFiscal, TiposComprobantesSLV.FacturaExportacion].includes(tipoComprobante as TiposComprobantesSLV)) {
      const facturas = await VentasService.getVentas(0, "", "", "", dteNumber, undefined, undefined, true, undefined, undefined, undefined, undefined, tipoComprobante);
      stopLoader()
      consoleService.log('facturas', facturas)
      if (facturas.length > 1) {
        setDocumentos(facturas)
        setDtePopup(true)
      } else if (facturas.length === 1) {
        if (facturas[0].autorizacionHacienda?.codigoGeneracion && facturas[0].autorizacionHacienda?.codigoGeneracion !== '') {
          if (facturas[0].estadoElectronico && facturas[0].estadoElectronico === 'ANULADO') {

            dispatch(addToast({
              id: '',
              autoHide: 7000,
              title: 'Acatha',
              content: `${`El documento`} ${dteNumber}  ${`ha sido anulado previamente y no puede ser utilizado para anular un documento.`}  `,
              fade: true,
              type: ToastTypes.Danger
            }));
            return false;
          } else {
            setRelated({
              codigoDocumento: facturas[0].comprobante ?? 0,
              codigoGeneracion: facturas[0].autorizacionHacienda?.codigoGeneracion ?? '',
            })
          }
        } else {
          dispatch(addToast({
            id: '',
            autoHide: 7000,
            title: 'Acatha',
            content: "El documento " + dteNumber + " no se encuentra autorizado ",
            fade: true,
            type: ToastTypes.Danger
          }));
        }
      } else if (facturas.length === 0) {
        dispatch(addToast({
          id: '',
          autoHide: 7000,
          title: 'Acatha',
          content: "No se encuentra un documento autorizado con este numero " + dteNumber,
          fade: true,
          type: ToastTypes.Danger
        }));
      }
      return false;
    } else {
      stopLoader()

      dispatch(addToast({
        id: '',
        autoHide: 7000,
        title: 'Acatha',
        content: "Servicio de busqueda no integrado.",
        fade: true,
        type: ToastTypes.Danger
      }));
      return false;
    }
  }, [tipoComprobante, dteNumber, dispatch, stopLoader, playLoader]);

  const copiarTexto = React.useCallback((idToCopy: string) => {
    const copyText = document.getElementById(idToCopy) as any;
    /* Select the text field */
    if (copyText) {
      copyText.select();
      copyText.setSelectionRange(0, 99999); /* For mobile devices */
      /* Copy the text inside the text field */
      document.execCommand("copy");
      dispatch(addToast({
        content: 'Copiado',
        type: ToastTypes.Success,
        autoHide: 1000
      }))
    } else {
      console.error("No se encuentra el elemento con id " + idToCopy + " para enlazar al TextBoxButtonCopy!!!")
    }


  }, [dispatch])

  const handleOnOk = React.useCallback(() => {
    if (isRelated === true) {
      if (related == null) {
        dispatch(addToast({
          id: '',
          autoHide: 7000,
          title: 'Acatha',
          content: "Debe ingresar el documento relacionado.",
          fade: true,
          type: ToastTypes.Warning
        }));
        return false;
      } else if (related?.codigoGeneracion === info?.autorizacionHacienda?.codigoGeneracion) {
        dispatch(addToast({
          id: '',
          autoHide: 7000,
          title: 'Acatha',
          content: "Debe ingresar el cod. de generaración de otro documento.",
          fade: true,
          type: ToastTypes.Warning
        }));
        return false;
      }
    }

    let dteAnular: VentaListado = info
    if (related) {
      dteAnular = {
        ...dteAnular,
        relacionado: related?.codigoGeneracion,
        relacionadoCodigoDocumento: related?.codigoDocumento
      }
    }
    if (dteAnular) onOk(dteAnular);
  }, [onOk, info, related, isRelated, dispatch]);

  const handleCheckboxChange = React.useCallback((e) => {
    setIsRelated(e)
  }, []);

  const onChangeNumDte = React.useCallback((e) => {
    setDteNumber(e)
  }, []);

  const onUpdateRelacionado = React.useCallback((e) => {
    setDteNumber(e.numero)
    setRelated({
      codigoDocumento: e?.comprobante ?? 0,
      codigoGeneracion: e?.autorizacionHacienda?.codigoGeneracion ?? '',
    })
    setDtePopup(false)
  }, []);
  const getLabel = () => {
    switch (tipoComprobante) {
      case '05':
        return 'Num. Nota de Crédito';
      case '06':
        return 'Num. Nota de Débito';
      case '01':
        return 'Num. Factura';
      case '07':
        return 'Num. Retención';
      case '11':
        return 'Num Factura Exp.';
      case '04':
        return 'Num. Nota Remisión';
      default:
        return 'Num. Documento';
    }
  };

  if (info === undefined) {
    console.error("La informacion de documento a a nular es null")
    return null;
  }


  return (
    <>
      {dtePopup && (
        <BuscarDocumentos
          onCancel={() => {
            setDtePopup(false)
          }}
          onOk={(documento) => {
            consoleService.log('documento', documento)
            onUpdateRelacionado(documento)
          }}
          tipoComprobante={tipoComprobante}
          documentos={documentos}
          documentoAnulando={info}
        />
      )}
      <CustomModalDevx
        size={modalSize}
        title='Anular Documento Venta'
        onClose={onCancel}
        toolbarItems={[
          {
            toolbar: 'bottom',
            widget: 'dxButton',
            location: 'after',
            options: {
              text: 'Anular',
              type: 'default',
              onClick: handleOnOk
            }
          },
          {
            toolbar: 'bottom',
            widget: 'dxButton',
            location: 'after',
            options: {
              text: 'Cancelar',
              type: 'danger',
              onClick: onCancel
            }
          }
        ]}
        show
      >
        <BlockUi tag="div" loader={LoadingIndicator} blocking={loaderLocal?.show ?? false} message={loaderLocal?.message ?? ""}  >
          <fieldset disabled={loaderLocal.show}>
            <Container>

              <RowContainer>
                <CustomCol
                  sm='12'
                  lg='12'
                >
                  <Labeled label='Reemplazar con nuevo Comprobante'            >
                    <Switch
                      value={isRelated}
                      onValueChange={(e) => { handleCheckboxChange(e) }}
                      switchedOffText="NO"
                      switchedOnText="SI"
                    />
                  </Labeled>

                  {isRelated && (
                    <fieldset className="fieldset-anular mt-2">
                      <legend>{'Documento Relacionado'}</legend>
                      <Labeled label={getLabel()}              >
                        <TextBox
                          value={dteNumber}
                          onValueChange={onChangeNumDte}
                          placeholder='0000000001'
                          maxLength={36}
                          onEnterKey={() => {
                            searchDte()
                          }}
                        >
                          <TextBoxButton
                            name="btnEye"
                            location="after"
                            options={{
                              icon: 'search',
                              onClick: () => {
                                searchDte()
                              },
                            }}
                          />
                          <TextBoxButton
                            name="btnMore"
                            location="after"
                            options={{
                              icon: 'more',
                              onClick: () => {
                                setRelated(null)
                                setDtePopup(true)
                              },
                            }}
                          />
                          <TextBoxButton
                            name="btnClean"
                            location="after"
                            options={{
                              icon: 'trash',
                              onClick: () => {
                                setDteNumber('')
                                setRelated(null)
                              },
                            }}
                          />
                        </TextBox>
                      </Labeled>
                      <Labeled label='Código Generación'              >
                        <TextBox
                          value={related?.codigoGeneracion ?? ''}
                          placeholder='Documento relacionado'
                          maxLength={36}
                          readOnly
                        />
                      </Labeled>
                    </fieldset>
                  )}
                </CustomCol>
              </RowContainer>
              <RowContainer>
                <CustomCol
                  sm='12'
                  lg='6'
                >
                  <Labeled
                    label='Tipo Documento'
                  >
                    <TextBox readOnly value={info.tipoDocumentoDescripcion} />
                  </Labeled>
                </CustomCol>
                <CustomCol
                  sm='12'
                  lg='6'
                >
                  <Labeled
                    label='Numero Documento'
                  >
                    <TextBox inputAttr={{ id: 'numeroDocumentoAnular' }} readOnly value={`${info.establecimiento}-${info.puntoEmision}-${info.numero}`} >
                      <TextBoxButton
                        name="copy"
                        location="after"
                        options={{
                          onClick: () => copiarTexto("numeroDocumentoAnular"),
                          stylingMode: 'contained',
                          type: 'default',
                          hint: lh.getMessage("dxDiagram-commandCopy",),
                          icon: 'copy',
                          disabled: false
                        }}
                      />
                    </TextBox>
                  </Labeled>
                </CustomCol>
              </RowContainer>
              <RowContainer>
                <CustomCol >
                  <Labeled m-1
                    label='Clave Acceso / Autorización'
                  >
                    <TextBox inputAttr={{ id: 'claveAccesoDocumentoAnular' }} readOnly value={info.claveAcceso} >
                      <TextBoxButton
                        name="copy"
                        location="after"
                        options={{
                          onClick: () => copiarTexto("claveAccesoDocumentoAnular"),
                          stylingMode: 'contained',
                          type: 'default',
                          hint: lh.getMessage("dxDiagram-commandCopy",),
                          icon: 'copy',
                          disabled: false
                        }}
                      />
                    </TextBox>
                  </Labeled>
                </CustomCol>
              </RowContainer>
              <RowContainer>
                <CustomCol

                >
                  <Labeled
                    label='Fecha Emisión'
                  >
                    <TextBox readOnly value={info.fecha ?? ""} />
                  </Labeled>
                </CustomCol>
                <CustomCol

                >
                  <Labeled
                    label='Fecha Autorización'
                  >
                    <TextBox readOnly value={info.fechaAutorizacion ?? ""} />
                  </Labeled>
                </CustomCol>

              </RowContainer>
              <RowContainer>
                <CustomCol
                  sm='12'
                  lg='8'
                >
                  <Labeled
                    label='Cliente'
                  >
                    <TextBox readOnly value={`${info.clienteIdentificacion}-${info.clienteNombre}`} />
                  </Labeled>
                </CustomCol>
                <CustomCol
                  sm='12'
                  lg='4'
                >
                  <Labeled
                    label='Email Cliente'
                  >
                    <TextBox inputAttr={{ id: 'emailClienteDocumentoAnular' }} readOnly value={info.clienteEmail} >
                      <TextBoxButton
                        name="copy"
                        location="after"
                        options={{
                          onClick: () => copiarTexto("emailClienteDocumentoAnular"),
                          stylingMode: 'contained',
                          type: 'default',
                          hint: lh.getMessage("dxDiagram-commandCopy",),
                          icon: 'copy',
                          disabled: false
                        }}
                      />
                    </TextBox>
                  </Labeled>
                </CustomCol>
              </RowContainer>
            </Container>

          </fieldset>
        </BlockUi >

      </CustomModalDevx>
    </>
  );
};
