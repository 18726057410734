import React from 'react';
import SelectBox from 'devextreme-react/select-box';
import { puntosVentaService } from './service/puntosVenta.service';
import { PuntoVenta } from './store/type';

interface PuntosVentaLookUpProps extends React.PropsWithChildren {
  selected: PuntoVenta | null,
  onChanged: (newValue: PuntoVenta | null) => void,
  onChangedOptions: (newValue: Array<PuntoVenta> | []) => void,
  disabled?: boolean,
}

const PuntosVentaLookUp: React.FC<PuntosVentaLookUpProps> = (props) => {
  const { selected, onChanged, disabled, onChangedOptions } = props;
  const selectRef = React.useRef<any>();
  const [options, setOptions] = React.useState<Array<PuntoVenta> | []>([]);

  const loadDatos = React.useCallback(async () => {
    const data = await puntosVentaService.getPuntosVenta("Todos");
    if (data.error === false) {
      let opt = [];
      if (data.auto.length > 0) {
        opt = data.auto.map(x => {
          const item: PuntoVenta = {
            codigo: parseInt(x.codigo) ?? 0,
            nombre: x.nombre ?? '',
            codigoPuntoVenta: parseInt(x.codigoPuntoVenta) ?? 0,
            host: x.host ?? '',
            codigoLocal: parseInt(x.codigoLocal) ?? 0,
            nombreLocal: x.nombreLocal ?? '',
            especial: x.especial ?? '',
            mac: x.mac ?? '',
            uso: x.uso ?? '',
            restaurante: parseInt(x.restaurante) ?? 0,
            toOrden: parseInt(x.toOrden) ?? 0,
            emula: parseInt(x.emula) ?? 0,
            estado: parseInt(x.estado) ?? 0,
            nombreDocumento: x.nombreDocumento ?? '',
          };
          return item;
        });
      }
      setOptions(opt)
      onChangedOptions(opt)
      if (selected === null)
        onChanged(opt[0])
    }

  }, [onChanged, onChangedOptions, selected]);

  const getItemDisplayExpr = React.useCallback((item: PuntoVenta) => {
    return item ? `${item.nombre}` : '';
  }, []);

  const onLookUpValueChanged = React.useCallback((evt: any) => {
    if (evt.event !== undefined) {
      onChanged(evt.value);
    }
  }, [onChanged]);

  const onSelectBoxFocusIn = React.useCallback((e) => {
    e.element.querySelector("input.dx-texteditor-input").select();
  }, []);

  const refreshSelected = React.useCallback(() => {
    if (options && options.length > 0) {
      const selectedIndex = options.findIndex((option: PuntoVenta) => {
        return option.codigo === selected?.codigo
      })
      onChanged(options[selectedIndex] ?? options[0])
    }
  }, [onChanged, options, selected])

  React.useEffect(() => {
    loadDatos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    refreshSelected();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  return (
    <SelectBox
      id='localSelect'
      ref={selectRef}
      items={options ?? []}
      searchEnabled
      displayExpr={getItemDisplayExpr}
      searchExpr={['nombre']}
      searchTimeout={500}
      onEnterKey={(e) => { console.log(e) }}
      value={selected}
      onValueChanged={onLookUpValueChanged}
      disabled={disabled}
      onFocusIn={onSelectBoxFocusIn}
    >
      {props.children}
    </SelectBox>
  )

}
export default React.memo(PuntosVentaLookUp);
