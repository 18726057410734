import React from 'react'
import { SelectBox } from 'devextreme-react'
import { staticLookUpCliente } from '../lookupZonasGeograficas/store/types'

interface ConcentracionAccionariaLookupProps extends React.PropsWithChildren {
  selected: staticLookUpCliente | null
  // eslint-disable-next-line no-unused-vars
  onChanged: (newValue: staticLookUpCliente | null) => void
  disabled?: boolean
  provider: Array<staticLookUpCliente> | []
}

const ConcentracionAccionariaLookup: React.FC<
  ConcentracionAccionariaLookupProps
> = (props) => {
  const { provider, selected, onChanged, disabled } = props
  const selectRef = React.useRef<any>()
  const [options, setOptions] = React.useState<Array<staticLookUpCliente> | []>(
    [],
  )

  const loadDatos = React.useCallback(async () => {
    if (provider !== undefined && provider.length > 0) {
      setOptions(provider)
      const selectedIndex = provider.findIndex(
        (select: staticLookUpCliente) => {
          return select.codigo === selected?.codigo
        },
      )
      onChanged(provider[selectedIndex] ?? provider[0])
      if (selectedIndex > -1) {
        onChanged(provider[selectedIndex] ?? provider[0])
      }
    } else {
      const opt = concentracionAccionaria
      setOptions(opt)
      const selectedIndex = opt.findIndex((option) => {
        return option.codigo === selected?.codigo
      })
      if (selectedIndex > -1) {
        onChanged(opt[selectedIndex] ?? opt[0])
      }
    }
  }, [onChanged, provider, selected])

  const onLookUpValueChanged = React.useCallback(
    (evt: any) => {
      if (evt.event !== undefined) {
        onChanged(evt.value)
      }
    },
    [onChanged],
  )

  const onSelectBoxFocusIn = React.useCallback((e) => {
    e.element.querySelector('input.dx-texteditor-input').select()
  }, [])

  const refreshSelected = React.useCallback(async () => {
    if (options && options.length > 0) {
      const selectedIndex = await options.findIndex(
        (option: staticLookUpCliente) => {
          return option.codigo === selected?.codigo
        },
      )
      onChanged(options[selectedIndex] ?? options[0])
    }
  }, [onChanged, options, selected])

  React.useEffect(() => {
    loadDatos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (selected !== null) {
      refreshSelected()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  return (
    <SelectBox
      id="conAccionesSelect"
      ref={selectRef}
      items={concentracionAccionaria ?? []}
      searchEnabled
      displayExpr={'descripcion'}
      searchExpr={['descripcion']}
      searchTimeout={500}
      onEnterKey={() => {}}
      value={selected}
      onValueChanged={onLookUpValueChanged}
      disabled={disabled}
      onFocusIn={onSelectBoxFocusIn}
    >
      {props.children}
    </SelectBox>
  )
}

export default React.memo(ConcentracionAccionariaLookup)

export const concentracionAccionaria: Array<staticLookUpCliente> = [
  {
    codigo: 0,
    descripcion: 'Elija una opción',
    porcentaje: 0,
    natural: 0,
    juridica: 15,
  },
  {
    codigo: 1,
    descripcion: 'Accionistas (Persona Jurídica) >= 25%',
    porcentaje: 100,
    natural: 0,
    juridica: 15,
  },
  {
    codigo: 2,
    descripcion: 'Accionistas (persona natural) >= 25%',
    porcentaje: 50,
    natural: 0,
    juridica: 15,
  },
  {
    codigo: 3,
    descripcion: 'Accionistas < 25%',
    porcentaje: 25,
    natural: 0,
    juridica: 15,
  },
]
