import { combineReducers } from '@reduxjs/toolkit'
import { menuReducer } from './menuReducer'
import { reducer } from './searchReducer'
import { tabsReducer } from './tabsReducer'
import { datosEdicionReducer } from './editDataReducer'
import { configuracionesReducer } from './configuracionesNCreditoReducer'
import { NotasCreditoState } from '../types/types'

export const notasCreditoReducer = combineReducers<NotasCreditoState>({
  menu: menuReducer,
  tabs: tabsReducer,
  search: reducer,
  editData: datosEdicionReducer,
  configuraciones: configuracionesReducer,
})
