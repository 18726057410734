import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DetalleRetencionRedux, TiposRetencion } from '../types/types'
import { CustomDictionary, LoaderInfo } from '../../../../../../store/types'
import {
  CompraInfo,
  FormaPagoModuloCompras,
  RetencionDatosEdicion,
  RetencionInfo,
} from '../../../../types/types'
import { ventasClearDatosEdicion } from '../../../../../ventas/store/reducers'
import { ProveedorBusqueda } from '../../../../../proveedores/store/types'

const initialState: CustomDictionary<RetencionDatosEdicion> = {}

const datosEdicionRetencionesSlice = createSlice({
  name: 'retencionesDatosEdicionSV',
  initialState: initialState,
  reducers: {
    ventasClearDatosEdicion,
    setFechaRetencion(
      state,
      action: PayloadAction<{ key: number | string; fecha: string }>,
    ) {
      state[action.payload.key].fechaEmision = action.payload.fecha
    },
    setNumeroRetencion(
      state,
      action: PayloadAction<{ key: number | string; numero: string }>,
    ) {
      state[action.payload.key].numero = action.payload.numero
      const splitData = action.payload.numero.split('-')
      if (splitData.length === 3) {
        state[action.payload.key].establecimiento = splitData[0]
        state[action.payload.key].puntoEmision = splitData[1]
      }
    },
    setFormaPago(
      state,
      action: PayloadAction<{
        key: number | string
        formaPago: FormaPagoModuloCompras
      }>,
    ) {
      state[action.payload.key].formaPago = action.payload.formaPago
    },
    setDatosEdicion(
      state,
      action: PayloadAction<{
        key: number | string
        data: RetencionDatosEdicion
      }>,
    ) {
      state[action.payload.key] = action.payload.data
    },
    setCompra(
      state,
      action: PayloadAction<{ key: number | string; compra: CompraInfo }>,
    ) {
      state[action.payload.key].compra = action.payload.compra
    },
    updateDetalles(
      state,
      action: PayloadAction<{
        key: number | string
        detalles: Array<DetalleRetencionRedux>
      }>,
    ) {
      state[action.payload.key].detalles = action.payload.detalles
    },
    updateDetalle(
      state,
      acion: PayloadAction<{
        key: string
        id: string
        updated: {
          baseImponible: number
          concepto: any
          conceptoCodigo: string
          porcentaje: number
          tipo: TiposRetencion
          valor: number
        }
      }>,
    ) {
      const { key, updated, id } = acion.payload
      for (let i = 0; i < state[key].detalles.length; i++) {
        const detalle = state[key].detalles[i]
        if (detalle.id === id) {
          state[key].detalles[i].baseImponible = updated.baseImponible
          state[key].detalles[i].concepto = updated.concepto
          state[key].detalles[i].conceptoCodigo = updated.conceptoCodigo
          state[key].detalles[i].porcentaje = updated.porcentaje
          state[key].detalles[i].tipo = updated.tipo
          state[key].detalles[i].valor = updated.valor
          break
        }
      }
    },
    updateProveedor(
      state,
      action: PayloadAction<{
        key: number | string
        proveedor: ProveedorBusqueda | null
        formasPago: Array<FormaPagoModuloCompras>
      }>,
    ) {
      state[action.payload.key].proveedor = action.payload.proveedor
      if (state[action.payload.key]) {
        const formaPago = action.payload.formasPago.find(
          (x) => x.codigo === state[action.payload.key].formaPago?.codigo,
        )
        state[action.payload.key].formaPago = formaPago
          ? formaPago
          : action.payload.formasPago.filter((x) => x.nombre === 'EFECTIVO')[0]
      } else {
        state[action.payload.key].formaPago = action.payload.formasPago.filter(
          (x) => x.nombre === 'EFECTIVO',
        )[0]
      }
    },
    setEditLoader(
      state,
      action: PayloadAction<{ key: number | string; info: LoaderInfo }>,
    ) {
      state[action.payload.key].loader = action.payload.info
    },
    setMuestraError(
      state,
      action: PayloadAction<{
        key: number | string
        tieneError: boolean
        mensajeError: string
      }>,
    ) {
      state[action.payload.key].tieneError = action.payload.tieneError
      state[action.payload.key].mensajeError = action.payload.mensajeError
    },
    updateResultadoGuardarRetencion(
      state,
      action: PayloadAction<{
        key: string | number
        retencion: RetencionInfo
        imprimir: boolean
      }>,
    ) {
      state[action.payload.key].autorizacion =
        action.payload.retencion.claveAcceso
      state[action.payload.key].codigo = action.payload.retencion.codigo
      state[action.payload.key].numero = action.payload.retencion.numero
      state[action.payload.key].fechaAutorizacion =
        action.payload.retencion.fechaAutorizacion
      state[action.payload.key].imprimir = action.payload.imprimir
    },
    setImprimir(
      state,
      action: PayloadAction<{ key: number | string; imprimir: boolean }>,
    ) {
      state[action.payload.key].imprimir = action.payload.imprimir
    },
  },
})

export const {
  setCompra,
  setFechaRetencion,
  setFormaPago,
  setNumeroRetencion,
  updateDetalles,
  updateProveedor,
  setDatosEdicion,
  setEditLoader,
  setMuestraError,
  ventasClearDatosEdicion: clearDatosEdicion,
  updateResultadoGuardarRetencion,
  setImprimir,
  updateDetalle,
} = datosEdicionRetencionesSlice.actions
export const datosEdicionReducer = datosEdicionRetencionesSlice.reducer
