import React from 'react';
import SelectBox from 'devextreme-react/select-box';
import { estadosService } from './service/estados.service';
import { EstadosOption } from './store/type';
import { utilidades } from '../../../helpers/utilidades';

interface EstadosLookUpProps extends React.PropsWithChildren {
  selected: EstadosOption | null,
  onChanged: (newValue: EstadosOption | null) => void,
  onChangedOptions: (newValue: Array<EstadosOption> | []) => void,
  disabled?: boolean,
}

const EstadosLookUp: React.FC<EstadosLookUpProps> = (props) => {
  const { selected, onChanged, disabled, onChangedOptions } = props;
  const selectRef = React.useRef<any>();
  const [options, setOptions] = React.useState<Array<EstadosOption> | []>([]);

  const loadDatos = React.useCallback(async () => {
    const data = await estadosService.getEstados("Elija una opción");
    if (data.error === false) {
      let opt = [];
      if (data.auto.length > 0) {
        opt = data.auto.map(x => {
          const item: EstadosOption = {
            codigo: parseInt(x.codigo) ?? 0,
            valor: utilidades.capText(x?.valor) ?? '',
            tipoCodigo: x.tipoCodigo ?? '',
            grupo: x.grupo ?? '',
            orden: x.orden ?? '',
            estado: x.estado ?? '',
          };
          return item;
        });
      }
      setOptions(opt)
      onChangedOptions(opt)
      if (selected === null)
        onChanged(opt[0])
    }

  }, [onChanged, onChangedOptions, selected]);

  const getItemDisplayExpr = React.useCallback((item: EstadosOption) => {
    return item ? `${item.valor}` : '';
  }, []);

  const onLookUpValueChanged = React.useCallback((evt: any) => {
    if (evt.event !== undefined) {
      onChanged(evt.value);
    }
  }, [onChanged]);

  const onSelectBoxFocusIn = React.useCallback((e) => {
    e.element.querySelector("input.dx-texteditor-input").select();
  }, [])

  React.useEffect(() => {
    loadDatos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <SelectBox
      id='estadoSelect'
      ref={selectRef}
      items={options ?? []}
      searchEnabled
      displayExpr={getItemDisplayExpr}
      searchExpr={['valor']}
      searchTimeout={500}
      onEnterKey={(e) => { console.log(e) }}
      value={selected}
      onValueChanged={onLookUpValueChanged}
      disabled={disabled}
      onFocusIn={onSelectBoxFocusIn}
    >
      {props.children}
    </SelectBox>
  )

}
export default React.memo(EstadosLookUp);
