import { combineReducers } from '@reduxjs/toolkit'
import { CandidatesState } from '../types/types'
import { dataEditCandidateReducer } from './editDataReducer'
import { searchCandidatesReducer } from './searchReducer'
import { tabsCandidatesReducer } from './tabsReducer'

export const candidatosReducer = combineReducers<CandidatesState>({
  tabs: tabsCandidatesReducer,
  editData: dataEditCandidateReducer,
  search: searchCandidatesReducer,
})
