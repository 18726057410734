import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  Candidate,
  CheckBoxArrayLeft,
  CheckBoxArrayRigth,
  NewCandidate,
} from '../../types/types'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput'
import CustomCol from '../../../../../../views/componentes/colContainer'
import {
  CheckBox,
  DateBox,
  TextBox,
  ValidationGroup,
  Validator,
} from 'devextreme-react'
import { CargoRol } from '../../../../../componentes/cargosLookup/types/types'
import CargosRolLookUp from '../../../../../componentes/cargosLookup'
import CargosDepartamentosLookUp from '../../../../../componentes/cargosDepartamentosLookup'
import { CargoDepartamento } from '../../../../../componentes/cargosDepartamentosLookup/types/types'
import LocalidadesLookUp from '../../../../../componentes/localidadesLookUp/localidadesLookUp'
import { CButton, CCol } from '@coreui/react-pro'
import FIleInput from '../../../../../componentes/fileInput'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/store'
import { ValueChangedEvent as EventTextBox } from 'devextreme/ui/text_box'
import { ValueChangedEvent as EventDateBox } from 'devextreme/ui/date_box'
import { ValueChangedEvent as EventCheckBox } from 'devextreme/ui/check_box'
import { ValueChangedEvent as EventFileUploader } from 'devextreme/ui/file_uploader'

import {
  setAllDataCandidate,
  setCity,
  setDataCandidate,
  setDepartament,
  setPayroll,
} from '../../store/editDataReducer'
import { OptionCiudad } from '../../../../../../containers/component/formLocal/types/types'
import {
  AsyncRule,
  EmailRule,
  PatternRule,
  RequiredRule,
} from 'devextreme-react/data-grid'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import {
  clearButtonClick,
  setButtons,
  setNameTab,
} from '../../store/tabsReducer'
import { useLoaderCandidates } from '../../customHooks'
import useTriggerEffect, {
  useSetToast,
} from '../../../../../../hooks/useGlobalHooks'
import { ToastTypes } from '../../../../../../store/types'
import VisualizaError from '../../../../../ventas/pages/shared/visualizaError/visualizaError'
import ListErrosValidationForm from '../../../../../componentes/listadoErroresValidacionForm'
import { CandidatesServices } from '../../services/candidates.services'
import { DateUtils } from '../../../../../../helpers/dateUtils'
import BlockUi from '../../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../../views/componentes/loadingindicator/loadingindicator'
import { defaultDataCandidate, parseCndidateToNewCandidateType } from '../..'
import { StatesEdition } from '../../../../../ventas/types/enums'
import { IGenericTabProps } from '../../../../store/types'

const NewCandidateComponent: FunctionComponent<IGenericTabProps<Candidate>> = (
  props,
) => {
  const { tabId, tab } = props
  const dispatch = useDispatch()
  const setToastMessage = useSetToast()
  const { showLoaderTabs } = useLoaderCandidates()

  const newCandidate = useSelector((state: RootState) => {
    return state.nomina.candidatos.editData[tabId]
  })
  const globalButtonClick = useSelector((state: RootState) => {
    return state.nomina.candidatos.tabs.tabs[tabId].globalButtonClick
  })
  const tabs = useSelector((state: RootState) => {
    return state.nomina.candidatos.tabs
  })
  const loader = useSelector((state: RootState) => {
    return state.nomina.candidatos.editData[tabId].loader
  })
  const ipWebCompany = useSelector(
    (state: RootState) => state.global.session?.empresa.ipWeb,
  )
  const idCompany = useSelector(
    (state: RootState) => state.global.session?.empresa.codigo,
  )

  const validationFormCandidate = useRef<any>()
  const [urlPdfCV, setUrlPdfCV] = useState<string>(
    `${ipWebCompany}/SolucionEmpresarial/SigNum/archivos/candidatos/${newCandidate.carpeta}`,
  )
  const [validationFormErrors, setValidationFormErrors] = useState<string[]>([])
  const [showPopupError, setShowPopupError] = useState<boolean>(false)
  const [resetFIleInput, setResetFIleInput] = useState<boolean>(false)
  const [pdfCV, setPdfCV] = useState<File>(null)

  const updateFormField = useCallback(
    (
      evt: EventTextBox | EventDateBox | EventCheckBox,
      valueToUpdate: string,
    ) => {
      if (evt.event)
        dispatch(
          setDataCandidate({
            key: tabId,
            candidate: {
              ...newCandidate,
              [valueToUpdate]: evt.value,
            },
          }),
        )
    },
    [dispatch, newCandidate, tabId],
  )

  const onCityChanged = useCallback(
    (city: OptionCiudad) => {
      dispatch(setCity({ key: tabId, city: city }))
    },
    [dispatch, tabId],
  )

  const onPayrollChanged = useCallback(
    (payroll: CargoRol) => {
      dispatch(setPayroll({ key: tabId, payroll: payroll }))
    },
    [dispatch, tabId],
  )

  const onDepartamentChanged = useCallback(
    (departament: CargoDepartamento) => {
      dispatch(setDepartament({ key: tabId, departament: departament }))
    },
    [dispatch, tabId],
  )

  const onPdfFileChanged = useCallback((pdfFile: EventFileUploader) => {
    setPdfCV(pdfFile?.value ? pdfFile.value[0] : null)
  }, [])

  const asyncValidation = useCallback((objValidation, field: string) => {
    if (objValidation?.value.codigo === -1 || !objValidation)
      return Promise.reject(
        `El campo ${field} no se encuentra seleccionado, por favor elija una opción.`,
      )
    else return Promise.resolve()
  }, [])

  useTriggerEffect(resetFIleInput, setResetFIleInput, () => {})

  const onHandleUndo = useCallback(() => {
    setResetFIleInput(true)
    setPdfCV(null)
    let canduateData: NewCandidate = null
    if (tab.editStatus == StatesEdition.edit)
      canduateData = parseCndidateToNewCandidateType(tab.info.info)
    else canduateData = defaultDataCandidate
    dispatch(
      setAllDataCandidate({
        key: tabId,
        data: canduateData,
      }),
    )
  }, [dispatch, tab, tabId])

  const onHandleSaveSucessfull = useCallback(
    (candidateCode: number) => {
      const pdfCVName = pdfCV ? `${idCompany}_${pdfCV.name}` : ''
      if (pdfCV)
        setUrlPdfCV(
          `${ipWebCompany}/SolucionEmpresarial/SigNum/archivos/candidatos/${pdfCVName}`,
        )
      dispatch(
        setDataCandidate({
          key: tabId,
          candidate: {
            ...newCandidate,
            codigo: candidateCode,
            carpeta: pdfCVName,
          },
        }),
      )
      dispatch(
        setNameTab({
          key: tabId,
          codigo: candidateCode,
          nombres: newCandidate.nombres,
          apellidos: newCandidate.apellidos,
        }),
      )
      dispatch(
        setButtons({
          tabKey: tabId,
          buttons: {
            Nuevo: true,
            Guardar: false,
            Editar: false,
            Eliminar: false,
            Buscar: true,
            Imprimir: true,
            Deshacer: false,
            Limpiar: false,
          },
        }),
      )
    },
    [dispatch, idCompany, ipWebCompany, newCandidate, pdfCV, tabId],
  )

  const onHandleSave = useCallback(async () => {
    showLoaderTabs(true, tabId, 'Guardando Candidato . . .', ButtonTypes.save)
    let validationFormResult =
      validationFormCandidate.current.instance.validate()
    if (validationFormResult.status === 'pending')
      validationFormResult = await validationFormResult.complete
    if (!validationFormResult.isValid) {
      const errorList: string[] = []
      validationFormResult.brokenRules.forEach((rule) => {
        errorList.push(rule.message)
      })
      setValidationFormErrors(errorList)
      setShowPopupError(true)
      setToastMessage(
        'Guardar Candidato',
        'Existen Errores en los datos del formulario, por favor verifiquelos.',
        ToastTypes.Warning,
      )
      showLoaderTabs(false, tabId)
    } else {
      try {
        const saveCandidate = await CandidatesServices.saveCandidate<number>({
          codigo: newCandidate.codigo,
          fecha: DateUtils.pickersDateToApiDate(newCandidate.fecha),
          aplica: newCandidate.aplica.codigo ?? -1,
          ciudad: newCandidate.ciudad.codigo ?? -1,
          apellidos: newCandidate.apellidos,
          nombres: newCandidate.nombres,
          identificacion: newCandidate.identificacion,
          email: newCandidate.email,
          telefono: newCandidate.telefono,
          liderazgo: newCandidate.liderazgo,
          actitud: newCandidate.actitud,
          comunicacion: newCandidate.comunicacion,
          flexibilidad: newCandidate.flexibilidad,
          colaboracion: newCandidate.colaboracion,
          dedicacion: newCandidate.dedicacion,
          confianza: newCandidate.confianza,
          integridad: newCandidate.integridad,
          iniciativa: newCandidate.iniciativa,
          aprendizaje: newCandidate.aprendizaje,
          curriculum: pdfCV,
        })
        if (!saveCandidate.error && saveCandidate.auto) {
          if (
            typeof saveCandidate.auto === 'number' &&
            !isNaN(saveCandidate.auto)
          )
            onHandleSaveSucessfull(saveCandidate.auto)
        }
        setToastMessage(
          'Guardar Candidato',
          saveCandidate.message,
          saveCandidate.error ? ToastTypes.Warning : ToastTypes.Success,
        )
        showLoaderTabs(false, tabId)
      } catch (error) {
        showLoaderTabs(false, tabId)
        setToastMessage('Guardar Candidato', error, ToastTypes.Danger)
      }
    }
  }, [
    showLoaderTabs,
    tabId,
    setToastMessage,
    newCandidate,
    pdfCV,
    onHandleSaveSucessfull,
  ])

  const onMenuButtonClick = useCallback(
    (actionType: string) => {
      switch (actionType) {
        case ButtonTypes.save:
          if (tabs.current === tabId) onHandleSave()
          break
        case ButtonTypes.undo:
          if (tabs.current === tabId) onHandleUndo()
          break
        default:
          break
      }
      dispatch(clearButtonClick(tabId))
    },
    [dispatch, onHandleSave, onHandleUndo, tabId, tabs],
  )

  useEffect(() => {
    if (globalButtonClick !== ButtonTypes.none)
      onMenuButtonClick(globalButtonClick)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalButtonClick])

  return (
    <>
      {showPopupError && (
        <VisualizaError
          titulo="Error en Candidatos"
          mensaje={'Error al guardar'}
          content={
            <ListErrosValidationForm
              mainMessage={
                'Antes de guardar la información revise lo siguiente:'
              }
              errorsList={validationFormErrors}
              color={'danger'}
            />
          }
          onOk={() => setShowPopupError(false)}
        />
      )}
      <BlockUi
        tag="div"
        loader={LoadingIndicator}
        blocking={loader.show && tabId === tabs.current}
        message={loader.mensaje}
      >
        <RowContainer className="m-2">
          <ValidationGroup
            key={'valitadationCandidate'}
            id={'valitadationCandidate'}
            ref={validationFormCandidate}
          >
            <RowContainer>
              <CustomCol xs="12" md="3">
                <Labeled label="Identificación:">
                  <TextBox
                    inputAttr={{ autocomplete: 'nope' }}
                    value={newCandidate.identificacion}
                    onValueChanged={(evt: EventTextBox) =>
                      updateFormField(evt, 'identificacion')
                    }
                    showClearButton={true}
                    maxLength={10}
                  >
                    <Validator>
                      <RequiredRule message="El campo Identificación es requerido." />
                      <PatternRule
                        pattern="^\d{10}$"
                        message="Ingrese una Identificación válida."
                      />
                    </Validator>
                  </TextBox>
                </Labeled>
              </CustomCol>
              <CustomCol xs="12" md="3">
                <Labeled label="Apellidos:">
                  <TextBox
                    inputAttr={{ autocomplete: 'nope' }}
                    value={newCandidate.apellidos}
                    onValueChanged={(evt: EventTextBox) =>
                      updateFormField(evt, 'apellidos')
                    }
                    showClearButton={true}
                    maxLength={250}
                  >
                    <Validator>
                      <RequiredRule message="El campo Apellidos es requerido." />
                      <PatternRule
                        pattern="^[a-zA-Z ]{4,}$"
                        message="El campo Apellidos debe tener al menos 4 letras."
                      />
                    </Validator>
                  </TextBox>
                </Labeled>
              </CustomCol>
              <CustomCol xs="12" md="3">
                <Labeled label="Nombres:">
                  <TextBox
                    inputAttr={{ autocomplete: 'nope' }}
                    value={newCandidate.nombres}
                    onValueChanged={(evt: EventTextBox) =>
                      updateFormField(evt, 'nombres')
                    }
                    showClearButton={true}
                    maxLength={250}
                  >
                    <Validator>
                      <RequiredRule message="El campo Nombres es requerido." />
                      <PatternRule
                        pattern="^[a-zA-Z ]{4,}$"
                        message="El campo Nombres debe tener al menos 4 letras."
                      />
                    </Validator>
                  </TextBox>
                </Labeled>
              </CustomCol>
              <CustomCol xs="12" md="3">
                <Labeled label="Registro:">
                  <DateBox
                    displayFormat="dd/MM/yyyy"
                    value={newCandidate.fecha}
                    onValueChanged={(evt: EventDateBox) =>
                      updateFormField(evt, 'fecha')
                    }
                  >
                    <Validator>
                      <RequiredRule message="El campo Registro es requerido." />
                    </Validator>
                  </DateBox>
                </Labeled>
              </CustomCol>
            </RowContainer>
            <RowContainer>
              <CustomCol xs="12" md="3">
                <Labeled label="Email:">
                  <TextBox
                    inputAttr={{ autocomplete: 'nope' }}
                    value={newCandidate.email}
                    onValueChanged={(evt: EventTextBox) =>
                      updateFormField(evt, 'email')
                    }
                    showClearButton={true}
                    maxLength={250}
                  >
                    <Validator>
                      <RequiredRule message="El campo Email es requerido." />
                      <EmailRule message="Ingrese un Email válido." />
                    </Validator>
                  </TextBox>
                </Labeled>
              </CustomCol>
              <CustomCol xs="12" md="3">
                <Labeled label="Teléfono:">
                  <TextBox
                    inputAttr={{ autocomplete: 'nope' }}
                    value={newCandidate.telefono}
                    onValueChanged={(evt: EventTextBox) =>
                      updateFormField(evt, 'telefono')
                    }
                    showClearButton={true}
                    maxLength={12}
                  >
                    <Validator>
                      <RequiredRule message="El campo Teléfono es requerido." />
                      <PatternRule
                        pattern="^\d{7,12}$"
                        message="Ingrese un Teléfono válido."
                      />
                    </Validator>
                  </TextBox>
                </Labeled>
              </CustomCol>
              <CustomCol xs="12" md="3">
                <Labeled label="Departamento:">
                  <CargosDepartamentosLookUp
                    selected={newCandidate.departamento}
                    onChanged={(departament: CargoDepartamento) =>
                      onDepartamentChanged(departament)
                    }
                  >
                    <Validator>
                      <AsyncRule
                        validationCallback={(paramsValidation) =>
                          asyncValidation(paramsValidation, 'Departamento')
                        }
                      />
                      <RequiredRule />
                    </Validator>
                  </CargosDepartamentosLookUp>
                </Labeled>
              </CustomCol>
              <CustomCol xs="12" md="3">
                <Labeled label="Aplica Para:">
                  <CargosRolLookUp
                    selected={newCandidate.aplica}
                    onChanged={(payRoll: CargoRol) => onPayrollChanged(payRoll)}
                  >
                    <Validator>
                      <AsyncRule
                        validationCallback={(selected) =>
                          asyncValidation(selected, 'Aplica Para')
                        }
                      />
                      <RequiredRule />
                    </Validator>
                  </CargosRolLookUp>
                </Labeled>
              </CustomCol>
            </RowContainer>
            <RowContainer className="mb-2">
              <CustomCol xs="12" md="3">
                <Labeled label="Ciudad:">
                  <LocalidadesLookUp
                    onChanged={(city: OptionCiudad) => onCityChanged(city)}
                    selected={newCandidate.ciudad}
                    allowEdit
                    allowClear
                  >
                    <Validator>
                      <AsyncRule
                        validationCallback={(paramsValidation) =>
                          asyncValidation(paramsValidation, 'Ciudad')
                        }
                      />
                      <RequiredRule />
                    </Validator>
                  </LocalidadesLookUp>
                </Labeled>
              </CustomCol>
              <CCol xs="12" md="3">
                <Labeled label="Cualidades:">
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '2px',
                    }}
                  >
                    {CheckBoxArrayLeft.map((checkBox) => {
                      return (
                        <CheckBox
                          key={checkBox.name}
                          text={checkBox.title}
                          value={newCandidate[checkBox.name]}
                          onValueChanged={(evt: EventCheckBox) =>
                            updateFormField(evt, checkBox.name)
                          }
                        />
                      )
                    })}
                  </div>
                </Labeled>
              </CCol>
              <CCol xs="12" md="3" style={{ marginTop: '12px' }}>
                <Labeled label="">
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '2px',
                    }}
                  >
                    {CheckBoxArrayRigth.map((checkBox) => {
                      return (
                        <CheckBox
                          key={checkBox.name}
                          text={checkBox.title}
                          value={newCandidate[checkBox.name]}
                          onValueChanged={(evt: EventCheckBox) =>
                            updateFormField(evt, checkBox.name)
                          }
                        />
                      )
                    })}
                  </div>
                </Labeled>
              </CCol>
              <CustomCol xs="12" md="3">
                <Labeled label="Carpeta:">
                  <span>Formato: .pdf, Tamaño max: 2 MB</span>
                  <FIleInput
                    accept={'.pdf'}
                    allowedFileExtensions={['.pdf']}
                    onChanged={(pdfFile: EventFileUploader) =>
                      onPdfFileChanged(pdfFile)
                    }
                    showDownloadDemo={false}
                    resetFile={resetFIleInput}
                  />
                </Labeled>
                {newCandidate.carpeta !== '' && newCandidate.codigo && (
                  <a href={urlPdfCV} target="_blank" rel="noreferrer">
                    <CButton
                      id="btnConfirmar"
                      color="secondary"
                      className="m-1"
                      size="sm"
                    >
                      {'Imprimir CV'}
                    </CButton>
                  </a>
                )}
              </CustomCol>
            </RowContainer>
          </ValidationGroup>
        </RowContainer>
      </BlockUi>
    </>
  )
}

export default NewCandidateComponent
