import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../../store/store'
import { AutorizacionDocumentoVenta } from '../../../types/types'
import { ConfiguracionesLiquidaciones } from '../types/types'

const initialState: ConfiguracionesLiquidaciones = {
 autorizacionDocumento: null,
}

const configuracionesLiquidacionesSlice = createSlice({
 name: 'configuracionesLiquidaciones',
 initialState: initialState,
 reducers: {
  initialize(state, acion: PayloadAction<AutorizacionDocumentoVenta>) {
   state.autorizacionDocumento = acion.payload
  },
  setAutorizacionModuloLiquidaciones(
   state,
   acion: PayloadAction<AutorizacionDocumentoVenta>,
  ) {
   state.autorizacionDocumento = acion.payload
  },
 },
})

export const configuracionesReducer = configuracionesLiquidacionesSlice.reducer
export const { setAutorizacionModuloLiquidaciones } =
 configuracionesLiquidacionesSlice.actions
