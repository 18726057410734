import React from 'react'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput'
import { TextBox, ValidationGroup, ValidationSummary, Validator } from 'devextreme-react'
import { MarcasDatosEdicion } from '../../types/types'
import { ButtonTypes } from '../../../../../../../views/componentes/globalMenu/types'
import { clearButtonClick, setButtons, setCurrentExecutingAction, setNameTabe } from '../../store/tabsReducer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import TipoEstadoLookUp from '../../../../../../componentes/tipoEstado'
import {
  changeLoaderBrands,
  setCurrentFunction,
} from '../../store/generalReducer'
import { setDatosEdicion } from '../../store/editDataReducer'
import Dialog from '../../../../../../../views/componentes/librerias/bootstrap-dialog'
import { CCol, CRow } from '@coreui/react-pro'
import MarcasWebLookUp from '../../../../../../componentes/marcasWebLookUp'
import { TabState } from '../../../../../../../store/genericTabTypes'
import { MarcaWeb } from '../../../../../../componentes/marcasWebLookUp/types/types'
import { AsyncRule, RequiredRule } from 'devextreme-react/validator'
import validations from '../../../../../../../config/validations'
import { DocumentInfo, ToastTypes } from '../../../../../../../store/types'
import { addToast } from '../../../../../../../store/toasterReducer'
import { MessagesKeys, lh } from '../../../../../../../helpers/localizationHelper'
import { InventarioService } from '../../../../../services/inventario.service'
import { StatesEdition } from '../../../../../../ventas/types/enums'
import { tipoEstadoService } from '../../../../../../componentes/tipoEstado/service/tipoEstado.service'
import { marcasWebServices } from '../../../../../../componentes/marcasWebLookUp/services/marcasWeb.services'
import VisualizaError from '../../../../../../ventas/pages/shared/visualizaError/visualizaError'
import ListErrosValidationForm from '../../../../../../componentes/listadoErroresValidacionForm'
import BlockUi from '../../../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../../../views/componentes/loadingindicator/loadingindicator'
import { ModuleButtons } from '../../index';
import { setDatosEdicionBackup } from '../../store/configReducer'

interface INewProps extends React.PropsWithChildren {
  info: DocumentInfo<MarcasDatosEdicion>
  tab: TabState<MarcasDatosEdicion>
  tabId: string
}

const Nuevo: React.FunctionComponent<INewProps> = (props) => {
  const { tab, tabId, info } = props
  const dispatch = useDispatch()
  const sms = validations['es']
  const marksState = useSelector((state: RootState) => {
    return state.inventarios.catalogos.marcas.editData[tabId]
  })
  const marksStateBackup = useSelector((state: RootState) => {
    return state.inventarios.catalogos.marcas.config[tabId]
  })
  const tabs = useSelector((state: RootState) => {
    return state.inventarios.catalogos.marcas.tabs
  })
  const loading = useSelector((state: RootState) => {
    return state.inventarios.catalogos.marcas.editData[tabId].loading
  })
  const loader = useSelector((state: RootState) => state.inventarios.catalogos.marcas.general.loader)

  const [arrayErrors, setArrayErrors] = React.useState<Array<string>>([])
  const [showErrorPopup, setShowErrorPopup] = React.useState<boolean>(false)
  const [marcasWeb, setMarcasWeb] = React.useState<Array<MarcaWeb>>([])
  const validationGroupRef = React.useRef<any>()
  const dialogRef = React.useRef<any>(null)

  const setButtonExecuttion = React.useCallback((tipo: ButtonTypes | undefined) => {
    dispatch(
      setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: tipo,
      }),
    )
  }, [dispatch, tabId])
  const playLoader = React.useCallback(
    (message = 'Cargando...') => {
      dispatch(changeLoaderBrands({ show: true, mensaje: message }))
    },
    [dispatch],
  )

  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(
      addToast({
        title: 'Inventario - Marcas',
        content: mensaje,
        type: tipo,
      }),
    )
  }, [dispatch])

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoaderBrands({ show: false, mensaje: '' }))
  }, [dispatch])

  const modoNuevo = React.useCallback(async (template: number, limpiar: boolean = false) => {
    if (loading === false && !limpiar) {
      return
    }
    setButtonExecuttion(ButtonTypes.new)
    playLoader('Iniciando...')
    try {
      const data = { ...defaultDataBrands }
      const estado = await tipoEstadoService.getTipoEstado('Elija un estado')
      const marcasWeb = await marcasWebServices.getWebBrands()
      if (marcasWeb['auto'] && marcasWeb['error'] === false) {
        if (marcasWeb['auto'].length > 0) {
          data.marcaWeb = marcasWeb['auto'][0]
        }
      }
      if (estado['auto'] && estado['error'] === false) {
        data.estado = estado['auto'][1]
      }
      data.nombre = ''
      data.marcaWebText = ''
      data.loading = false
      dispatch(setDatosEdicion({
        key: tabId,
        marca: {
          ...data
        }
      }))
      dispatch(setDatosEdicionBackup({
        key: tabId,
        marca: {
          ...data
        }
      }))
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
    stopLoader()
    setButtonExecuttion(undefined)
  }, [loading, playLoader, setButtonExecuttion, stopLoader, dispatch, tabId, setToast])

  const getEstado = React.useCallback(async (codigo: number) => {
    try {
      const data = await tipoEstadoService.getTipoEstado('Elija un estado')
      if (data !== null && data !== undefined && data['error'] === false) {
        const estado = data['auto'].find(item => item.codigo === codigo)
        return estado
      }
      return null
    } catch (error) {
      return null
    }
  }, [])


  const getMarcasWeb = React.useCallback(async (codigo: number) => {
    try {
      const data = await marcasWebServices.getWebBrands()
      if (data !== null && data !== undefined && data.length > 0) {
        const marcasWeb = data['auto'].find(item => item.codigo === codigo)
        return marcasWeb
      }
      return null
    } catch (error) {
      return null
    }
  }, [])

  const cargarMarcas = React.useCallback(async (loder: boolean, lineaData) => {
    if (loder === false) {
      return
    }
    setButtonExecuttion(ButtonTypes.edit)
    playLoader('Cargando Marca...')
    const data = { ...defaultDataBrands }
    try {
      const estado = await getEstado(lineaData?.estado)
      const marWeb = await getMarcasWeb(lineaData?.marcaWeb)
      data.marcaWeb = marWeb
      data.estado = estado
      data.codigo = lineaData?.codigo
      data.nombre = lineaData?.nombre
      data.marcaWebText = ''
      data.loading = false
      dispatch(setDatosEdicion({
        key: tabId,
        marca: data
      }))
      dispatch(setDatosEdicionBackup({
        key: tabId,
        marca: data
      }))
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
    stopLoader()
    setButtonExecuttion(undefined)
  }, [playLoader, stopLoader, setButtonExecuttion, getEstado, getMarcasWeb, setToast, dispatch, tabId])

  const onChangeData = React.useCallback((data, key: string) => {
    let value = null
    if (data?.event !== null && data?.event !== undefined) {
      value = data.value
    } else if (key === "estado") {
      value = data
    }
    if (value !== null && value !== undefined) {
      dispatch(setDatosEdicion({
        key: tabId,
        marca: {
          ...marksState,
          [key]: value
        }
      }))
    }
  }, [dispatch, marksState, tabId])


  const onValidateForm = React.useCallback(() => {
    const arrayErrores: Array<string> = []
    if (marksState?.nombre === null || marksState?.nombre === undefined || marksState?.nombre === '') {
      arrayErrores.push('Nombre: Ingrese el nombre de la marca.')
    }
    if (marksState?.estado === null || marksState?.estado === undefined || marksState.estado?.codigo <= 0) {
      arrayErrores.push('Estado: Seleccione un estado.')
    }
    return arrayErrores
  }, [marksState])

  const onHandleSave = React.useCallback(async () => {
    let errors: Array<string> = []
    const result = validationGroupRef.current.instance.validate()
    errors = onValidateForm()
    if (result.isValid && errors.length === 0) {
      let marcaWeb: any = {
        id: -1,
        descripcion: ""
      }
      if (marcasWeb.length > 0) {
        marcaWeb = {
          id: marksState.marcaWeb?.codigo ?? -1,
          descripcion: marksState.marcaWeb?.descripcion ?? "",
        }
      }
      const request_save = {
        codigo: marksState?.codigo ?? 0,
        nombre: marksState?.nombre ?? "",
        estado: marksState?.estado.codigo ?? 1,
        marcaWeb,
      }
      setButtonExecuttion(ButtonTypes.save)
      playLoader('Guardando...')
      try {
        const data = await InventarioService.createMarca(request_save)
        if (data !== null && data !== undefined && data['error'] === false) {
          dispatch(setNameTabe({
            key: tabId,
            codigo: parseInt(data['auto']),
            nombre: marksState?.nombre
          }))
          dispatch(
            setButtons({
              tabKey: tabId,
              buttons: {
                ...ModuleButtons,
                Guardar: false
              },
            }),
          )
          setToast(data['message'], ToastTypes.Success)
        } else {
          setToast(data['message'], ToastTypes.Danger)
        }
      } catch (error) {
        setToast(error.message, ToastTypes.Danger)
      }
      stopLoader()
      setButtonExecuttion(undefined)
    } else {
      setToast(lh.getMessage(MessagesKeys.GlobalErrorSave), ToastTypes.Warning)
      setShowErrorPopup(true)
      setArrayErrors(errors)
    }
  }, [marksState, setToast, marcasWeb, playLoader, stopLoader, dispatch, tabId, setButtonExecuttion, onValidateForm])




  const handleButtonClick = React.useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.save:
          if (tabs.current === tabId) onHandleSave()
          break
        case ButtonTypes.broom:
          if (tabs.current === tabId) {
            dispatch(setDatosEdicion({
              key: tabId,
              marca: {
                ...defaultDataBrands
              }
            }))
          }
          break
        case ButtonTypes.undo:
          if (tabs.current === tabId) {
            dispatch(setDatosEdicion({
              key: tabId,
              marca: {
                ...marksStateBackup
              }
            }))
          }
          break
        default:
          break
      }
      dispatch(setCurrentFunction(''))
      dispatch(clearButtonClick(tabId))
    },
    [dispatch, onHandleSave, tabId, tabs, marksStateBackup],
  )

  React.useEffect(() => {
    if (tab.editStatus === StatesEdition.new) {
      modoNuevo(tab?.info?.codigo)
    } else {
      cargarMarcas(loading, info.info)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onFilterMarcaWeb = React.useCallback((data) => {
    if (data.value !== null && data.value !== undefined) {
      const marcaWeb: string = data.value
      if (marcasWeb !== null && marcasWeb !== undefined && marcasWeb.length > 0) {
        let newArayy = []
        newArayy = marcasWeb.map(item => {
          if (item?.descripcion && item?.descripcion.indexOf(marcaWeb) > 0) {
            return item
          }
        })
        setMarcasWeb(newArayy)
      }
    }
  }, [marcasWeb])

  const validateAsyncSelect = React.useCallback((datoSeleccion: any, message: string) => {
    if (datoSeleccion.value.codigo > -1) {
      return Promise.resolve()
    } else {
      return Promise.reject(message)
    }
  }, [])

  const validateAsyncSeleccionTipoId = React.useCallback((datoSeleccion) => {
    return validateAsyncSelect(datoSeleccion, `Estado: Debe seleccionar una opción.`)
  }, [validateAsyncSelect])

  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab.globalButtonClick]);

  return (
    <>
      {showErrorPopup && (
        <VisualizaError
          titulo="Error en Marcas"
          mensaje={'Error al guardar'}
          content={
            <ListErrosValidationForm
              mainMessage={'Revise los siguientes errores:'}
              errorsList={arrayErrors}
              color={'danger'}
            />
          }
          onOk={() => setShowErrorPopup(false)}
        />
      )}
      <div style={{ overflowX: 'hidden' }}>
        <BlockUi
          tag="div"
          loader={LoadingIndicator}
          blocking={loader.show}
          message={loader.mensaje}
        >
          <RowContainer className="m-2">
            <Dialog ref={dialogRef} />
            <ValidationGroup id={'formLineas'} ref={validationGroupRef}>
              <RowContainer>
                <CustomCol xs="12" md="3">
                  <Labeled label="Nombre:">
                    <TextBox
                      value={marksState?.nombre ?? ""}
                      onValueChanged={(data) => onChangeData(data, 'nombre')}
                    >
                      <Validator>
                        <RequiredRule message={'Nombre: ' + sms['required']} />
                      </Validator>
                    </TextBox>
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="4">
                  <Labeled label="Marca Web:">
                    <TextBox
                      value={marksState?.marcaWebText}
                      onValueChanged={(data) => {
                        onChangeData(data, 'marcaWebText')
                        onFilterMarcaWeb(data)
                      }}
                    />
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="2">
                  <Labeled label="Estado:">
                    <TipoEstadoLookUp onChanged={(data) => onChangeData(data, 'estado')} selected={marksState?.estado}>
                      <Validator>
                        <RequiredRule message={'Estado: Debe seleccionar una opción'} />
                        <AsyncRule
                          message={'Estado: Debe seleccionar una opción'}
                          validationCallback={validateAsyncSeleccionTipoId}
                        />
                      </Validator>
                    </TipoEstadoLookUp>
                  </Labeled>
                </CustomCol>
              </RowContainer>
              <CRow>
                <CCol lg="6" md="8" sm="12">
                  <ValidationSummary id="summary"></ValidationSummary>
                </CCol>
              </CRow>
            </ValidationGroup>
            <RowContainer>
              <CustomCol xs="12" md="3"></CustomCol>
              <CCol xs="12" md="3" style={{ marginTop: '8px' }}>
                <MarcasWebLookUp
                  selected={marksState?.marcaWeb}
                  onChanged={(data) => onChangeData(data, 'marcaWeb')}
                  onChangedOptions={(data) => setMarcasWeb(data)}
                  provider={marcasWeb}
                />
              </CCol>
            </RowContainer>
          </RowContainer>
        </BlockUi>
      </div>
    </>
  )
}

export default React.memo(Nuevo)

export const defaultDataBrands: MarcasDatosEdicion = {
  codigo: 0,
  nombre: '',
  marcaWeb: null,
  marcaWebText: null,
  estado: null,
  guardado: false,
  loading: false,
}

