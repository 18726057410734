import React from 'react'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput'
import { TextBox } from 'devextreme-react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import { changeFilter, setCollapsed } from '../../store/serachReducer'
import { CCardBody, CCardHeader, CCollapse, CLink } from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilChevronBottom, cilChevronTop } from '@coreui/icons'

interface ISearchProps extends React.PropsWithChildren {
  onSearchEnter: () => void
}

const FiltroBusqueda: React.FunctionComponent<ISearchProps> = (props) => {
  const { onSearchEnter } = props
  const dispatch = useDispatch()

  const searchFilter = useSelector((state: RootState) => {
    return state.inventarios.catalogos.lineas.search.filter
  })
  const collapsed = useSelector((state: RootState) => {
    return state.inventarios.catalogos.lineas.search.colapsado
  })

  const onChangeData = React.useCallback((data, key: string) => {
    if (data?.event !== null && data?.event !== undefined) {
      dispatch(changeFilter({
        ...searchFilter,
        [key]: data.value
      }))
    }
  }, [dispatch, searchFilter])


  return (
    <>
      <CCardHeader
        onClick={() => dispatch(setCollapsed(!collapsed))}
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <strong> {collapsed ? '' : 'Filtro de Búsqueda'} </strong>
        <CLink className="card-header-action">
          <CIcon icon={collapsed ? cilChevronBottom : cilChevronTop} />
        </CLink>
      </CCardHeader>
      <CCollapse visible={collapsed}>
        <CCardBody>
          <RowContainer>
            <CustomCol xs="12" md="3">
              <Labeled label="Código:">
                <TextBox
                  value={searchFilter.particular}
                  onValueChanged={(data) => onChangeData(data, 'particular')}
                  onEnterKey={onSearchEnter}
                />
              </Labeled>
            </CustomCol>
            <CustomCol xs="12" md="3">
              <Labeled label="Descripción:">
                <TextBox
                  value={searchFilter.descripcion}
                  onValueChanged={(data) => onChangeData(data, 'descripcion')}
                  onEnterKey={onSearchEnter}
                />
              </Labeled>
            </CustomCol>
          </RowContainer>
        </CCardBody>
      </CCollapse>
    </>
  )
}

export default React.memo(FiltroBusqueda)