import React, { memo } from 'react';
import { ValidationGroup } from 'devextreme-react/validation-group';
import TextBox from 'devextreme-react/text-box';
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput';
import CheckBox from 'devextreme-react/check-box'
import { CRow, CCol } from '@coreui/react-pro';

const Buscar = (props: IBuscarProps) => {

  const { onChanged, ejecutarBusqueda, funcion } = props;

  const [razonSocial, setRazonSocial] = React.useState<string>("");
  const [nombreComercial, setNombreComercial] = React.useState<string>('');
  const [ruc, setRuc] = React.useState<string>('');
  const [transportista, setTransportista] = React.useState<boolean>(false);
  const [activos, setActivos] = React.useState<boolean>(true);
  const [datos, setDatos] = React.useState<DatosBuscarProveedor>(datosBuscarProveedorDefaultValue);

  React.useEffect(() => {
    onChanged(datos)
  }, [datos]);

  React.useEffect(() => {
    if (funcion === 'Deshacer') {
      setDatos(datosBuscarProveedorDefaultValue)
    }
  }, [funcion]);

  React.useEffect(() => {
    setDatos({ razonSocial, nombreComercial, ruc, transportista, activos })
  }, [razonSocial, nombreComercial, ruc, transportista, activos]);

  const capturarEnter = React.useCallback(() => {
    ejecutarBusqueda()
  }, []);

  return (
    <>
      <ValidationGroup>

        <CRow>
          <CCol md='12' lg='6' xs="12" >
            <Labeled
              label='Identificación'
            >
              <TextBox
                name='ruc'
                placeholder='R.U.C / Identificación'
                value={ruc}
                onValueChanged={({ value }: any) => setRuc(value)}
                elementAttr={{
                  autocomplete: "nope",
                  autoComplete: "nope",
                }}
                onEnterKey={() => capturarEnter()}
              >
              </TextBox>
            </Labeled>
          </CCol>
          <CCol md='12' lg='6' xs="12" >
            <Labeled
              label='Nombre Comercial'
            >
              <TextBox
                name='nombreComercial'
                placeholder='Nombre Comercial'
                value={nombreComercial ?? ""}
                onValueChanged={({ value }: any) => setNombreComercial(value)}
                onEnterKey={() => capturarEnter()}
              > </TextBox>
            </Labeled>
          </CCol>
          <CCol md='12' lg='6' xs="12" >
            <Labeled
              label='Razón Social'
            >
              <TextBox
                name='razonSocial'
                placeholder='Razón Social'
                value={razonSocial}
                onValueChanged={({ value }: any) => setRazonSocial(value)}
                elementAttr={{
                  autoFocus: true
                }}
                onEnterKey={() => capturarEnter()}
              >  </TextBox>
            </Labeled>
          </CCol>
          <CCol md='12' lg='2' xs="12" >
            <Labeled
              label='Transportista'
            >
              <CheckBox
                value={transportista}
                onValueChanged={() =>
                  setTransportista(!transportista)
                }
              />
            </Labeled>
          </CCol>
          <CCol md='12' lg='2' xs="12" >
            <Labeled
              label='Activos'
            >
              <CheckBox
                value={activos}
                onValueChanged={() =>
                  setActivos(!activos)
                }
              />
            </Labeled>
          </CCol>
        </CRow>

      </ValidationGroup>

    </>
  );
}

interface IBuscarProps extends React.PropsWithChildren {
  onChanged: (data) => void;
  onCancel?: () => void;
  funcion: string,
  resetFunction: () => void,
  ejecutarBusqueda: () => void
}

type DatosBuscarProveedor = {
  razonSocial: string,
  nombreComercial: string,
  ruc: string,
  transportista: boolean,
  activos: boolean,
}

const datosBuscarProveedorDefaultValue: DatosBuscarProveedor = {
  razonSocial: '',
  nombreComercial: '',
  ruc: '',
  transportista: false,
  activos: false,
};

export default memo(Buscar);
