import React, { useEffect } from 'react'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import { ProyectosCCList } from '../../types/types'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import { clearButtonClick } from '../../store/tabsReducer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/store'
import {
  changeLoaderProjectCC,
  setCurrentFunction,
} from '../../store/generalReducer'
import {
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
} from '@coreui/react-pro'
import { changeCurrentSearchTab } from '../../store/serachReducer'
import Cabecera from './cabecera'
import Detalle from './detalle'
import FiltroBusqueda from './filtrobusqueda'
import { TabState } from '../../../../../../store/genericTabTypes'

interface ISearchProps extends React.PropsWithChildren {
  tab: TabState<ProyectosCCList>
  tabId: string
}

const SearchSeries: React.FunctionComponent<ISearchProps> = (props) => {
  const { tabId } = props

  const dispatch = useDispatch()
  const currentAction = useSelector(
    (state: RootState) => state.inventarios.proyectos.general.currentFunction,
  )
  const tabsSearchseries = useSelector((state: RootState) => {
    return state.inventarios.proyectos.search
  })

  const playLoader = React.useCallback(
    (message = 'Cargando...') => {
      dispatch(changeLoaderProjectCC({ show: true, mensaje: message }))
    },
    [dispatch],
  )

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoaderProjectCC({ show: false, mensaje: '' }))
  }, [dispatch])

  const onHandleEdit = React.useCallback(() => {}, [])

  const onHandleRemove = React.useCallback(async () => {}, [])

  const handleButtonClick = React.useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.delete:
          onHandleRemove()
          break
        case ButtonTypes.edit:
          onHandleEdit()
          break
        default:
          break
      }
      dispatch(setCurrentFunction(''))
      dispatch(clearButtonClick(tabId))
    },
    [dispatch, onHandleRemove, onHandleEdit, tabId],
  )

  useEffect(() => {
    if (currentAction !== '') handleButtonClick(currentAction)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAction])

  return (
    <>
      <FiltroBusqueda />
      <RowContainer>
        <CNav variant="tabs" role="tablist" className="mt-4">
          <CNav variant="tabs">
            {tabsSearchseries.tabs.map((tab, index) => (
              <CNavItem key={index}>
                <CNavLink
                  onClick={() =>
                    dispatch(
                      changeCurrentSearchTab(tabsSearchseries.tabs[index]),
                    )
                  }
                  active={tabsSearchseries.currentTab === tab}
                >
                  {tab}
                </CNavLink>
              </CNavItem>
            ))}
          </CNav>
          <CTabContent
            style={{
              overflowY: 'unset',
              width: '100%',
              marginBottom: '10px',
            }}
          >
            <CTabPane
              role="tabpanel"
              aria-labelledby="pendientes-tab"
              visible={tabsSearchseries.currentTab === tabsSearchseries.tabs[0]}
            >
              <Cabecera />
            </CTabPane>
            <CTabPane
              role="tabpanel"
              aria-labelledby="pagados-tab"
              visible={tabsSearchseries.currentTab === tabsSearchseries.tabs[1]}
            >
              <Detalle />
            </CTabPane>
          </CTabContent>
        </CNav>
      </RowContainer>
    </>
  )
}

export default React.memo(SearchSeries)
