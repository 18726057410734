import { GeneralState, typeNotification } from '../types/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LoaderInfo } from '../../../../../store/types'

const initialState: GeneralState = {
  modulo: 'clientes',
  currentAction: 'Buscar',
  currentFunction: '',
  aplicacion: 'desempeno',
  loader: {
    show: false,
    mensaje: '',
  },
  typesNotification: null,
}

const desempenoSlice = createSlice({
  name: 'notificationsMsg',
  initialState: initialState,
  reducers: {
    setCurrentAccion(state, action: PayloadAction<string>) {
      state.currentAction = action.payload
    },
    setCurrentFunction(state, action: PayloadAction<string>) {
      state.currentFunction = action.payload
    },
    changeLoaderNotifications(state, action: PayloadAction<LoaderInfo>) {
      state.loader = action.payload
    },
    setTypeNotification(state, action: PayloadAction<Array<typeNotification>>) {
      state.typesNotification = action.payload
    },
  },
})

export const {
  setCurrentAccion,
  setCurrentFunction,
  changeLoaderNotifications,
  setTypeNotification,
} = desempenoSlice.actions

export const generalReducer = desempenoSlice.reducer
