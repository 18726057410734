import React from 'react'
import { isMobileOnly } from 'react-device-detect'
import Popup from 'devextreme-react/popup'
import ScrollView from 'devextreme-react/scroll-view'

interface IPopupContentProps extends React.PropsWithChildren {
  show: boolean
  title: string
  subTitle?: string
  onClose: () => void
  size?: 'sm' | 'md' | 'lg'
  portalElementId?: string
  canClose?: boolean
  width?: string | number
  height?: string | number
}

const defaultProps = {
  height: 'auto',
  title: 'Acatha'
}

export const PopupContent: React.FC<IPopupContentProps> = (props) => {
  PopupContent.defaultProps = defaultProps

  const { title, subTitle, show, onClose, canClose, size, width, height } =
    props

  const getWidth = React.useCallback(
    (
      size: 'sm' | 'md' | 'lg' | undefined,
      width: string | number | undefined,
    ): string | number | undefined => {
      if (width) {
        return width
      }
      if (size) {
        if (isMobileOnly) {
          return window.innerWidth / 1.1
        }
        switch (size) {
          case 'sm':
            return '30%'
          case 'md':
            return '60%'
          case 'lg':
            return '80%'
        }
      }
      return undefined
    },
    [],
  )

  const getHeight = React.useCallback(
    (
      size: 'sm' | 'md' | 'lg' | undefined,
      height: string | number | undefined,
    ): string | number | undefined => {
      if (height) {
        return height
      }
      // if (size) {

      // }
      return undefined
    },
    [],
  )

  return (
    <Popup
      title={title}
      width={getWidth(size, width) ?? 500}
      height={getHeight(size, height) ?? 300}
      visible={show}
      showTitle={title !== ''}
      closeOnOutsideClick={canClose || false}
      showCloseButton
      onHiding={onClose}
      shadingColor="rgba(0,0,0,0.4)"
      position={'center'}
      toolbarItems={[
        {
          options: {
            text: 'Aceptar',
            stylingMode: 'contained',
            type: 'default',
            icon: 'check',
            onClick: onClose,
          },
          widget: 'dxButton',
          toolbar: 'bottom',
          location: 'center',
        },
      ]}
    >
      <ScrollView width={'100%'} height={'100%'}>
        <React.Fragment key="popupContentAdvertencias">
          {subTitle !== '' && <label className='mb-2'>{subTitle}</label>}
          <div className='mt-2 mb-2'>
            {props.children}
          </div>
        </React.Fragment>
      </ScrollView>
    </Popup>
  )
}
