import React, { useEffect } from 'react';
import ReactDom from 'react-dom'
import {
  CCard, CCardBody, CCardHeader,
  CCol,
  CRow, CButton
} from '@coreui/react-pro'
import { cuentasService } from "../../../diarioGeneral/services/cuentas.service"
import { addToast } from '../../../../../../store/toasterReducer';
import { AccionMenu, ToastTypes } from '../../../../../../store/types';
import { useDispatch, useSelector } from 'react-redux'
import { changeLoader } from '../../../../../../store/reducers';
import { setCargarCuentaContable, setNavegacion, setCurrentAccion, setResetSeleccion, setData, setDetalleAsiento, setCurrentFunction } from "../../../diarioGeneral/store/diarioReducer"
import { RootState } from '../../../../../../store/store'
import { setModal as setModalCuentas } from "../../../planCuentas/store/cuentasReducer"
import Modalform from "../../../../../../views/componentes/modalform"
import { GlobalMenu } from '../../../../../../views/componentes/globalMenu/globalMenu';
import { TIPOS_BOTONES } from '../../../diarioGeneral/store/types'
import { setSeleccionarDato } from "../../store/mayorReducer"
import Buscar from "../../../diarioGeneral/components/buscar";
import Nuevo from "../../../diarioGeneral/components/nuevo";
import Editar from "../../../diarioGeneral/components/editar";
import ModalCuentas from "../../../planCuentas/modalCuentas";
import { lh, MessagesKeys } from '../../../../../../helpers/localizationHelper';
import { PdfVisualizer } from '../../../../../../views/componentes/pdfVisualizer/pdfVisualizer';
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types';
import { setModalAnticipos } from '../../../../../shared/components/modales/modalAnticipos/store/modalAnticiposReducer';
import ModalAnticipos from "../../../../../shared/components/modales/modalAnticipos/index";
import LoadingIndicator from '../../../../../../views/componentes/loadingindicator/loadingindicator';
import { DateUtils, formatoFechasApi } from '../../../../../../helpers/dateUtils';
import { ReportWithLocalDataModal } from '../../../../../../views/componentes/xtrareports/ReportWithLocalDataModal';
import BlockUi from '../../../../../../views/componentes/librerias/block-ui';
import { fetchAAsiento } from '../../../../../clientes/pages/docsCtasXCobrar/store/anticiposReducer';
import { EAplicationsAcatha, EModulosAcatha } from '../../../../../../store/enumsAplication';
import { consoleService } from '../../../../../../services/console.service';

type ModalAsientoProps = {
  show: boolean,
  onClose: () => void,
  onGenerateEntry?: (generate: boolean) => void,
  infoAsiento: any,
  origenModulo: number
  tipo?: string
}

const app = "mayor_general";
const pathVerificador = '/' + app;

const menuModalAsientos = [
  {
    nombre: "Nuevo",
    icon: "fal fa-file",
    shortcut: "",
    main: true,
    modal: true,
    actionType: ButtonTypes.new
  },
  {
    nombre: "Duplicar",
    icon: "fal fa-copy",
    shortcut: "",
    main: true,
    modal: true,
    actionType: ButtonTypes.clone
  },
  {
    nombre: "Guardar",
    icon: "fal fa-save",
    shortcut: "",
    main: true,
    modal: true,
    actionType: ButtonTypes.save
  },
  {
    nombre: "Anticipos",
    icon: "fal fa-hands-usd",
    shortcut: "",
    main: true,
    modal: true,
    actionType: ButtonTypes.advances
  },
  {
    nombre: "Imprimir",
    icon: "fal fa-print",
    shortcut: "",
    main: true,
    modal: true,
    actionType: ButtonTypes.print
  },
  {
    nombre: "Anterior",
    icon: "fal fa-chevron-left",
    shortcut: "",
    main: true,
    modal: true,
    actionType: ButtonTypes.prev
  },
  {
    nombre: "Siguiente",
    icon: "fal fa-chevron-right",
    shortcut: "",
    main: true,
    modal: true,
    actionType: ButtonTypes.next
  },
  {
    nombre: "Regenerar",
    icon: "fal fa-redo-alt",
    shortcut: "",
    main: true,
    modal: true,
    actionType: ButtonTypes.regenerate
  }
]

const ModalAsiento = (props: ModalAsientoProps) => {

  const dispatch = useDispatch()

  const { origenModulo, infoAsiento, onClose, onGenerateEntry, tipo } = props;

  const currentActionState = useSelector((state: RootState) => state.contabilidad.diarioGeneral.currentAction);
  const seleccionado = useSelector((state: RootState) => state.contabilidad.mayorGeneral.seleccionado);
  const menuState = useSelector((state: RootState) => state.global.menu);
  const modal = useSelector((state: RootState) => state.contabilidad.planCuentas.modal);
  const asiento = useSelector((state: RootState) => state.contabilidad.diarioGeneral.asiento);
  const asientoApi = useSelector((state: RootState) => state.contabilidad.diarioGeneral.asientoApi);
  const modalAnticipos = useSelector((state: RootState) => state.shared.modalAnticipos.modal);
  const loaderDiario = useSelector((state: RootState) => state.contabilidad.diarioGeneral.loader);


  const [messageConfirm, setMessageConfirm] = React.useState<string>("");
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [cleanDetall, setCleanDetall] = React.useState(false);
  const [options, setOptions] = React.useState<Array<AccionMenu>>([]);
  const [cloneConfirm, setCloneConfirm] = React.useState(false);
  const [displayDestino, setDisplayDestino] = React.useState<string>("");
  const [confirmChange, setConfirmChange] = React.useState<boolean>(false);
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const [confirmUpdate, setConfirmUpdate] = React.useState(false);
  const [blob, setBlob] = React.useState<Blob>();
  const [nameFile, setNameFile] = React.useState<string>("DiarioGeneral");
  const [reporte, setReporte] = React.useState<null | "Viewer" | "Designer">(null);
  const [datosExportar, setDatosExportar] = React.useState<any>({});
  const [asientoExportar, setAsientoExportar] = React.useState<any | null>(null);

  const loadMenu = () => {
    const botones = menuModalAsientos.map(acc => {
      const boton: AccionMenu = {
        nombre: acc.nombre as any,
        icon: acc.icon,
        shortcut: null,
        main: Number(acc.main) === 1 ? true : false,
        modal: Number(acc.modal) === 1 ? true : false,
        actionType: acc.actionType
      }
      return boton;
    });
    setOptions(botones)
  }

  const setToast = React.useCallback((texto, type: ToastTypes) => {
    dispatch(addToast({
      id: '',
      autoHide: 3500,
      title: 'Acatha',
      content: texto,
      fade: true,
      type: type
    }));
  }, [dispatch]);

  const playLoader = React.useCallback(() => {
    dispatch(changeLoader({ show: true, mensaje: 'Cargando...' }))
  }, [dispatch]);

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoader({ show: false, mensaje: '' }))
  }, [dispatch]);

  const renderBody = () => {
    return (
      <>
        {messageConfirm}
      </>
    )
  }

  const clonarAsiento = React.useCallback(() => {
    dispatch(setCurrentFunction(TIPOS_BOTONES.BOTON_DUPLICAR))
  }, [dispatch]);

  const renderFooter = () => {
    return (
      <>
        {((showConfirm === true && cloneConfirm === true) && (cleanDetall === false)) && (
          <>
            <CButton id="confirm" color="primary" onClick={() => {
              setCloneConfirm(false);
              setShowConfirm(false)
              clonarAsiento()
            }}>
              {"Aceptar"}
            </CButton>
            <CButton color="secondary" onClick={() => {
              setShowConfirm(!showConfirm)
            }} >{"Cancelar"}</CButton>
          </>
        )}
        {((showConfirm === true) && (cleanDetall !== true) && (cloneConfirm === false)) && (
          <>
            <CButton color="primary" onClick={() => {
              setShowConfirm(false)
            }}>
              {"Aceptar"}
            </CButton>
            <CButton color="secondary" onClick={() => {
              setShowConfirm(!showConfirm)
            }} >{"Cancelar"}</CButton>
          </>
        )}

        {((showConfirm === true) && (cleanDetall === true) && (cloneConfirm === false)) && (
          <>
            <CButton color="primary" onClick={() => {
              dispatch(setDetalleAsiento([]));
              setShowConfirm(false)
              setCleanDetall(false)
            }}>
              {"Aceptar"}
            </CButton>
            <CButton color="secondary" onClick={() => {
              setShowConfirm(!showConfirm)
            }} >{"Cancelar"}</CButton>
          </>
        )}


      </>
    )
  }

  const renderFooterConfirm = () => {
    document?.getElementById("confirm")?.focus();
    if (currentActionState === "Buscar") {
      return (
        <>
          {confirmDelete === true && (
            <CButton id="confirm" color="primary" onClick={() => {
              setShowConfirm(false)
              setConfirmDelete(false)
              handleDelete()
            }}>
              {"Aceptar"}
            </CButton>
          )}
          {confirmUpdate === true && (
            <CButton id="confirm" color="primary" onClick={() => {
              setConfirmUpdate(false);
              setShowConfirm(false)
              dispatch(setCurrentAccion("Editar"))
            }}>
              {"Aceptar"}
            </CButton>
          )}

          <CButton color="secondary" onClick={() => {
            setConfirmUpdate(false);
            setConfirmDelete(false)
            setShowConfirm(!showConfirm)
          }} >
            {"Cancelar"}
          </CButton>
        </>
      )
    } else if (currentActionState === "Nuevo" || currentActionState === "Editar") {

      return (
        <>

          {(showConfirm === true && cloneConfirm === true) && (
            <CButton id="confirm" color="primary" onClick={() => {
              setCloneConfirm(false);
              setShowConfirm(false)
              clonarAsiento()
            }}>
              {"Aceptar"}
            </CButton>
          )}

          {confirmChange === true && (
            <CButton color="primary" onClick={() => {
              setConfirmUpdate(false);
              setShowConfirm(false)
              setConfirmChange(false)
              setMessageConfirm("")
              dispatch(setCurrentAccion(displayDestino))
            }}>
              {"Aceptar"}
            </CButton>
          )}

          <CButton color="secondary" onClick={() => {
            setConfirmUpdate(false);
            setConfirmDelete(false)
            setShowConfirm(!showConfirm)
          }} >
            {"Cancelar"}
          </CButton>
        </>
      )
    }
  }

  const confirmAction = () => {
    return (
      <Modalform
        name='confirmar'
        headerTitle={'Confirmar'}
        childrenBody={renderBody()}
        childrenFooter={renderFooterConfirm()}
        closeOnBackdrop={false}
        show={showConfirm}
        onClose={() => setShowConfirm(!showConfirm)}
        centered={true}
        size="sm"
      />
    )
  }

  const closeModal = () => {
    props.onClose()
  }

  const getButtonDisabled = React.useCallback((button: AccionMenu) => {
    const { AS_TIPO } = asiento;
    const tipoAsiento = "MANUAL";
    if (origenModulo === parseInt(EModulosAcatha.Clientes) || origenModulo === parseInt(EModulosAcatha.Proveedores)) {
      switch (button.nombre) {
        case TIPOS_BOTONES.BOTON_IMPRIMIR:
        case TIPOS_BOTONES.BOTON_NUEVO:
        case TIPOS_BOTONES.BOTON_SIGUIENTE:
        case TIPOS_BOTONES.BOTON_ANTERIOR:
        case TIPOS_BOTONES.BOTON_REGENERAR: {
          return false;
        }
        case TIPOS_BOTONES.BOTON_ANTICIPOS: {
          return (AS_TIPO && AS_TIPO !== "" && AS_TIPO.toUpperCase() !== tipoAsiento);
        }
        case TIPOS_BOTONES.BOTON_GUARDAR: {
          return (AS_TIPO && AS_TIPO !== "" && AS_TIPO.toUpperCase() !== tipoAsiento);
        }
        case TIPOS_BOTONES.BOTON_DUPLICAR: {
          return (AS_TIPO && AS_TIPO !== "" && AS_TIPO.toUpperCase() !== tipoAsiento);
        }
        default:
          return true;
      }
    } else {

      switch (button.nombre) {
        case TIPOS_BOTONES.BOTON_IMPRIMIR:
        case TIPOS_BOTONES.BOTON_SIGUIENTE:
        case TIPOS_BOTONES.BOTON_ANTERIOR:
        case TIPOS_BOTONES.BOTON_REGENERAR: {
          return false;
        }
        case TIPOS_BOTONES.BOTON_ANTICIPOS: {
          return (AS_TIPO && AS_TIPO !== "" && AS_TIPO.toUpperCase() !== tipoAsiento);
        }
        case TIPOS_BOTONES.BOTON_GUARDAR: {
          return (AS_TIPO && AS_TIPO !== "" && AS_TIPO.toUpperCase() !== tipoAsiento);
        }
        case TIPOS_BOTONES.BOTON_DUPLICAR: {
          return (AS_TIPO && AS_TIPO !== "" && AS_TIPO.toUpperCase() !== tipoAsiento);
        }
        default:
          return true;
      }
    }
  }, [asiento, origenModulo]);

  const marcarAsiento = React.useCallback((secuencial) => {
    dispatch(setSeleccionarDato(
      {
        index: "0",
        row: {
          ASI_NUMERO: Number(secuencial)
        },
        selectedRow: {}
      }
    ))
  }, [dispatch]);

  const lastAsiento = React.useCallback(() => {
    if (seleccionado['index'] === null) {
      setToast("No se encuentra un registro seleccionado, Por favor seleccione un asiento.", ToastTypes.Info)
      return false;
    } else {
      const secuencial = seleccionado['row']['ASI_NUMERO'];
      marcarAsiento(Number(secuencial) - 1)
    }
  }, [marcarAsiento, setToast, seleccionado]);

  const nextAsiento = React.useCallback(() => {
    if (seleccionado['index'] === null) {
      setToast("No se encuentra un registro seleccionado, Por favor seleccione un asiento.", ToastTypes.Info)
      return false;
    } else {
      const secuencial = seleccionado['row']['ASI_NUMERO'];
      marcarAsiento(Number(secuencial) + 1)
    }
  }, [marcarAsiento, seleccionado, setToast]);

  const restaurarAsiento = React.useCallback(async () => {
    playLoader()
    try {
      const data = await cuentasService.asientosRestaurar(seleccionado['row']['ASI_NUMERO']);

      stopLoader()
      if (data['error'] === false) {
        setToast(data['message'], ToastTypes.Success);
      } else {
        setToast(data['message'], ToastTypes.Warning);
      }

      return await data;
    } catch (error) {
      consoleService.error(error);
      stopLoader()
      setToast(JSON.stringify(error), ToastTypes.Danger)
    }
  }, [stopLoader, playLoader, seleccionado, setToast]);


  const cargarCuentas = React.useCallback((evt: any) => {
    dispatch(setCargarCuentaContable(evt));
  }, [dispatch]);

  const onModalAnticipos = React.useCallback(() => {
    dispatch(setModalAnticipos({
      name: "modalAnticipos",
      open: false,
      codModulo: 0,
      parametros: []
    }))
  }, [dispatch]);

  const onParseInfoReporte = React.useCallback(async () => {
    consoleService.log("onParseInfoReporte", asientoApi)
    const infoAsientoContable: any = {
      ordenAsiento: 1,
      numeroAsiento: Number(asientoApi.numeroAsiento) ?? 0,
      numeroDiario: Number(asientoApi.numeroDiario) ?? 0,
      fecha: DateUtils.strDateToDate(asientoApi.fecha, formatoFechasApi) ?? "",
      fechaAuditoria: String(asientoApi.fechaAuditoria) ?? "",
      descripcionAsiento: asientoApi.descripcionAsiento ?? "-",
      numeroDocumento: Number(asientoApi.numeroDocumento) ?? 0,
      usuario: String(asientoApi.usuario) ?? "-",
      localCodigo: asientoApi.local.localCodigo,
      localNombre: asientoApi.local.localNombre,
      proyectoCodigo: asientoApi.proyecto.proyectoCodigo ?? 0,
      proyectoNombre: asientoApi.proyecto.proyectoNombre ?? "",
      tipo: String(asientoApi.tipo) ?? "",
      tipoNumero: Number(asientoApi.tipoNumero) ?? 0,
      debe: Number(asientoApi.debe) ?? 0,
      haber: Number(asientoApi.haber) ?? 0
    };

    const asientosDetalle: any = [];

    for (let indiceDetalle = 0; indiceDetalle < asientoApi['detalle'].length; indiceDetalle++) {
      asientosDetalle.push({
        cuentaCodigo: Number(asientoApi['detalle'][indiceDetalle]['planCuentasCodigo']) ?? 0,
        cuentaNombre: asientoApi['detalle'][indiceDetalle]['planCuentasDescripcion'] ?? "",
        cuentaNumero: asientoApi['detalle'][indiceDetalle]['planCuentasNumero'] ?? "",
        debe: Number(asientoApi['detalle'][indiceDetalle]['transaccionDebe']) ?? 0,
        haber: Number(asientoApi['detalle'][indiceDetalle]['transaccionHaber']) ?? 0,
        local: asientoApi['detalle'][indiceDetalle]['codigo'],
        localNombre: String(asientoApi['detalle'][indiceDetalle]['localNombre']) ?? "",
        siglasLocal: String(asientoApi['detalle'][indiceDetalle]['siglasLocal']) ?? "",
        referenciaTransaccion: String(asientoApi['detalle'][indiceDetalle]['transaccionReferencia']) ?? "",
      })
    }

    infoAsientoContable.detalle = asientosDetalle;

    setAsientoExportar([{ ...infoAsientoContable }]);

  }, [asientoApi]);

  const onButtonExportarClick = React.useCallback(async (filtro) => {
    await onParseInfoReporte()
    setReporte('Viewer');
  }, [onParseInfoReporte]);

  const onMenuButtonClick = React.useCallback((action: AccionMenu) => {

    const { infoAsiento } = props;
    switch (action['nombre']) {
      case TIPOS_BOTONES.BOTON_NUEVO:
        setDisplayDestino(TIPOS_BOTONES.BOTON_NUEVO)
        if (currentActionState === "Editar") {
          setConfirmChange(true)
          setConfirmUpdate(true)
          setMessageConfirm(lh.getMessage(MessagesKeys.FormConfirmChangeForm));
          setShowConfirm(true)
          return false;
        } else {
          dispatch(setResetSeleccion())
          dispatch(setCurrentAccion(TIPOS_BOTONES.BOTON_NUEVO))
          dispatch(setCurrentFunction(TIPOS_BOTONES.BOTON_NUEVO))
        }
        break;
      case TIPOS_BOTONES.BOTON_GUARDAR:
        if (currentActionState === "Nuevo") {
          dispatch(setCurrentFunction(TIPOS_BOTONES.BOTON_GUARDAR))
          return false;
        } else if (currentActionState === "Editar") {
          if (asiento.AS_TIPO !== "MANUAL") {
            setToast("No se puede editar el asiento tipo : " + asiento.AS_TIPO, ToastTypes.Info)
            return false;
          }
          dispatch(setCurrentFunction(TIPOS_BOTONES.BOTON_GUARDAR))
          return false;
        } else if (currentActionState === "Anterior") {
          dispatch(setCurrentFunction(TIPOS_BOTONES.BOTON_GUARDAR))
          return false;
        } else if (currentActionState === "Siguiente") {
          dispatch(setCurrentFunction(TIPOS_BOTONES.BOTON_GUARDAR))
          return false;
        } else if (currentActionState === "Importar") {
          dispatch(setCurrentFunction(TIPOS_BOTONES.BOTON_GUARDAR))
          return false;
        } else {
          setToast("No se encuentra cambios que realizar.", ToastTypes.Info)
        }
        break;
      case TIPOS_BOTONES.BOTON_EDITAR:
        setDisplayDestino(TIPOS_BOTONES.BOTON_EDITAR)

        if (currentActionState === "Editar") {
          setConfirmChange(true)
          setConfirmUpdate(true)
          setMessageConfirm(lh.getMessage(MessagesKeys.FormConfirmChangeForm));
          setShowConfirm(true)
          return false;
        } else {
          if (seleccionado['index'] === null) {
            setToast("No se encuentra un registro seleccionado, Por favor seleccione un Item para editarlo.", ToastTypes.Info)
            return false;
          } else {
            setConfirmUpdate(true)
            setMessageConfirm("Está seguro que desea realizar estos cambios?");
            setShowConfirm(true)
          }
        }
        break;
      case TIPOS_BOTONES.BOTON_DUPLICAR:
        if (seleccionado['row']['ASI_NUMERO'] !== 0) {
          setCloneConfirm(true)
          setMessageConfirm("Está seguro que desea duplicar este asiento?");
          setShowConfirm(true)
        } else {
          setToast("Debe elegir un registro para duplicarlo.", ToastTypes.Info)
        }
        break;
      case TIPOS_BOTONES.BOTON_ANTERIOR:
        lastAsiento()
        dispatch(setNavegacion(TIPOS_BOTONES.BOTON_ANTERIOR))
        dispatch(setCurrentAccion("Anterior"))
        break;
      case TIPOS_BOTONES.BOTON_SIGUIENTE:
        nextAsiento()
        dispatch(setNavegacion(TIPOS_BOTONES.BOTON_SIGUIENTE))
        dispatch(setCurrentAccion("Siguiente"))
        break;
      case TIPOS_BOTONES.BOTON_DESHACER:
        if (currentActionState === "Nuevo") {
          dispatch(setCurrentFunction(TIPOS_BOTONES.BOTON_DESHACER))
          return false;
        } else if (currentActionState === "Editar") {
          dispatch(setCurrentFunction(TIPOS_BOTONES.BOTON_DESHACER))
          return false;
        }
        else if (currentActionState === "Importar") {
          dispatch(setCurrentFunction(TIPOS_BOTONES.BOTON_DESHACER))
          return false;
        }
        else if (currentActionState === "Buscar") {
          dispatch(setCurrentFunction(TIPOS_BOTONES.BOTON_DESHACER))
          return false;
        }
        else {
          dispatch(setResetSeleccion())
          dispatch(setCurrentAccion("Buscar"))
        }
        break;
      case TIPOS_BOTONES.BOTON_REGENERAR:
        restaurarAsiento()
        break;
      case TIPOS_BOTONES.BOTON_ANTICIPOS:
        if (asiento.AS_TIPO !== "MANUAL") {
          setToast("No se puede realizar los anticipos al asiento tipo : " + asiento.AS_TIPO, ToastTypes.Info);
          return false;
        }
        dispatch(setModalAnticipos({
          name: "modalAnticipos",
          open: true,
          codModulo: origenModulo,
          parametros: {
            ...infoAsiento['row'],
            fecha: infoAsiento['row']['ASI_FECHA'],
            numeroAsiento: infoAsiento['row']['ASI_NUMERO'],
            haberTotal: infoAsiento['row']['TRA_HABER'],
          }
        }))
        break;
      case TIPOS_BOTONES.BOTON_IMPRIMIR:
        if (currentActionState === "Editar") {
          // imprimirAsiento()
          if (seleccionado['index'] !== null) {
            onButtonExportarClick('')
          } else {
            setToast("No se encuentra registros para exportar.", ToastTypes.Info)
          }
        } else if ((origenModulo === parseInt(EModulosAcatha.Clientes) || origenModulo === parseInt(EModulosAcatha.Proveedores)) && currentActionState === "Nuevo") {
          if (seleccionado['index'] !== null) {
            onButtonExportarClick('')
          } else {
            setToast("No se encuentra registros para exportar.", ToastTypes.Info)
          }
        }
        break;
      default:
        break;
    }
  }, [currentActionState, setDisplayDestino, setConfirmChange, setConfirmUpdate, setMessageConfirm,
    setShowConfirm, dispatch, setToast, lastAsiento, nextAsiento, setCloneConfirm, restaurarAsiento,
    props, seleccionado, origenModulo, asiento, onButtonExportarClick
  ]);


  const botonesApp = () => {
    return (
      <GlobalMenu
        printButtonAsDropdown={false}
        showExportDesingOptions
        acciones={options}
        onClick={onMenuButtonClick}
        getButtonDisabled={getButtonDisabled}
      />
    )
  }

  const clickDelete = React.useCallback(() => {
    setConfirmDelete(true);
    setShowConfirm(true)
    setMessageConfirm("Está seguro que desea eliminar este item?");
  }, []);

  const handleDelete = React.useCallback(async () => {
    playLoader()
    try {
      const data = await cuentasService.deleteRegistro(seleccionado['row']['ASI_NUMERO']);
      if (data['error'] === true) {
        setToast(data['message'], ToastTypes.Warning);
      } else {
        stopLoader()
        setToast(data['message'], ToastTypes.Success);
        dispatch(setResetSeleccion())
        dispatch(setCurrentAccion("Buscar"))
        quitarRegistroTabla(seleccionado['row'])
        dispatch(setData([]))
      }
      return await data;
    } catch (error) {
      consoleService.error(error);
      stopLoader()
      setToast(JSON.stringify(error), ToastTypes.Danger)
    }
  }, [dispatch, playLoader, seleccionado, setToast, stopLoader]);

  const quitarRegistroTabla = (idCuenta) => {
    // let filtrado = registros.findIndex((item: any) => {
    //     return item.ASI_NUMERO === idCuenta['ASI_NUMERO'];
    // });
    // const rowsBefore = registros.slice();
    // rowsBefore.splice(filtrado, 1);
    // dispatch(setData(rowsBefore));
  }

  const loadFormulario = () => {
    if (origenModulo === parseInt(EModulosAcatha.Clientes) || origenModulo === parseInt(EModulosAcatha.Proveedores)
      || origenModulo === parseInt(EModulosAcatha.MovimientosIngresosEgresos) || origenModulo === parseInt(EAplicationsAcatha.RolesPago) || origenModulo === parseInt(EModulosAcatha.MovimientosImportaciones)) {
      // if (currentActionState === "Nuevo") {
      //   return (
      //     <Nuevo origenModulo={origenModulo} />
      //   )
      // } else
      if (currentActionState === "Siguiente") {
        return (
          <Editar
            origenModulo={origenModulo}
            setToast={setToast}
            playLoader={playLoader}
            stopLoader={stopLoader}
          />
        )
      } else if (currentActionState === "Anterior") {
        return (
          <Editar
            origenModulo={origenModulo}
            setToast={setToast}
            playLoader={playLoader}
            stopLoader={stopLoader}
          />
        )
      } else {
        return (
          <Nuevo
            origenModulo={origenModulo}
            setToast={setToast}
            playLoader={playLoader}
            stopLoader={stopLoader}
          />
        )
      }
    } else {
      if (currentActionState === "Buscar") {
        return (
          <Buscar
            actionDelete={() => {
              clickDelete()
            }}
            exportExcel={() => {
              // onButtonExportarClick(registros)
            }}
            returnFormularioBusqueda={(data) => { }}
            returnAction={() => {
            }}
            setToast={setToast}
            playLoader={playLoader}
            stopLoader={stopLoader}
          />
        )
      } else if (currentActionState === "Nuevo") {
        return (
          <Nuevo
            setToast={setToast}
            playLoader={playLoader}
            stopLoader={stopLoader}
          />
        )
      } else if (currentActionState === "Editar") {
        return (
          <Editar
            currentAction={currentActionState}
            origenModulo={origenModulo}
            setToast={setToast}
            playLoader={playLoader}
            stopLoader={stopLoader}
          />
        )
      } else if (currentActionState === "Siguiente") {
        return (
          <Editar
            origenModulo={origenModulo}
            setToast={setToast}
            playLoader={playLoader}
            stopLoader={stopLoader}
          />
        )
      } else if (currentActionState === "Anterior") {
        return (
          <Editar
            origenModulo={origenModulo}
            setToast={setToast}
            playLoader={playLoader}
            stopLoader={stopLoader}
          />
        )
      } else {
        return (
          <>
          </>
        )
      }
    }
  }

  const bodyModal = () => {
    return (
      <>
        <ReportWithLocalDataModal
          show={reporte !== null}
          onClose={() => setReporte(null)}
          data={currentActionState === TIPOS_BOTONES.BOTON_BUSCAR ? datosExportar : asientoExportar}
          fileName={currentActionState === TIPOS_BOTONES.BOTON_BUSCAR ? 'StaticDiarioGeneralListado' : 'StaticAsientoContable'}
          mode={reporte ?? 'Viewer'}
          parameters={{ 'Reporte_Filtro': "" }}
          template={currentActionState === TIPOS_BOTONES.BOTON_BUSCAR ? 'StaticDiarioGeneralListado' : 'StaticAsientoContable'}
          key='reportDesigner'
        />
        <CCard>
          <CCardHeader >
            {botonesApp()}
          </CCardHeader>
          <CCardBody>

            <div>
              <BlockUi tag="div" loader={LoadingIndicator} blocking={loaderDiario.show ?? false} message={loaderDiario.mensaje ?? ""}  >
                <div>
                  {loadFormulario()}
                </div>
              </BlockUi >
            </div>

          </CCardBody>
        </CCard>
      </>
    )
  }

  const onModalClose = () => {
    dispatch(setModalCuentas({
      modalName: "modalPlan",
      open: false,
      codModulo: null
    }))
  }

  useEffect(() => {
    if (menuState.length > 0 && options.length === 0) {
      loadMenu()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuState, loadMenu]);

  const modalCuentas = (
    modal.modalName === "modalPlan" && (
      <ModalCuentas show={modal.open} onClose={() => onModalClose()} cargarCuentas={(evt) => {
        //cargarCuentas(evt)
        dispatch(setCargarCuentaContable(evt))
      }} />
    ))
  const blobUrl = blob ? window.URL.createObjectURL(blob) : '';

  const modalAnticiposComponente = (
    modalAnticipos.name === "modalAnticipos" && (
      <ModalAnticipos
        show={modalAnticipos.open}
        pathVerificador={pathVerificador}
        onClose={() => onModalAnticipos()}
        cargarCuentas={(evt) => cargarCuentas(evt)}
        modulo={origenModulo}
        comprobante={asiento['NRO']}
        fecha={asiento['ASI_C_FECHA_AS']}
      />
    ))

  const getInitAnticipos = React.useCallback(async (requestBody) => {
    const toAny: any = fetchAAsiento(requestBody)
    const res = await dispatch(toAny)
    if (res !== null && res !== undefined && res.payload['error'] === false) {
      setToast(res.payload['message'], ToastTypes.Info)
    } else {
      if (res !== null && res !== undefined && res.payload['error'] === true) {
        onGenerateEntry(true)
        setToast(res.payload['message'], ToastTypes.Danger)
      } else {
        consoleService.log(res)
        setToast('Error: No se pudo ejecutar esta acccion', ToastTypes.Danger)
      }
      onClose()
    }
  }, [dispatch, onClose, setToast, onGenerateEntry])

  useEffect(() => {
    const request_body: any = {}
    if (origenModulo === parseInt(EModulosAcatha.Clientes) && infoAsiento.row !== null && infoAsiento.row !== undefined) {
      request_body.codigo = infoAsiento.row.codigoAso
      request_body.tipo = 'ANTICIPO CLIENTE'
      getInitAnticipos(request_body)
    }
    if (origenModulo === parseInt(EModulosAcatha.Proveedores) && infoAsiento.row !== null && infoAsiento.row !== undefined) {
      request_body.codigo = infoAsiento.row.codigoAso
      request_body.tipo = 'ANTICIPO PROVEEDOR'
      getInitAnticipos(request_body)
    }
    if (origenModulo === parseInt(EModulosAcatha.Clientes) && !isNaN(infoAsiento)) {
      request_body.codigo = infoAsiento
      request_body.tipo = 'ABONO CLIENTE'
      getInitAnticipos(request_body)
    }
    if (origenModulo === parseInt(EModulosAcatha.Proveedores) && !isNaN(infoAsiento)) {
      request_body.codigo = infoAsiento
      request_body.tipo = 'ABONO PROVEEDOR'
      getInitAnticipos(request_body)
    }

    if (origenModulo === parseInt(EAplicationsAcatha.IngresosEgresos) && !isNaN(infoAsiento)) {
      request_body.codigo = infoAsiento
      request_body.tipo = 'INVENTARIO'
      getInitAnticipos(request_body)
    }

    if (origenModulo === parseInt(EAplicationsAcatha.MayorGeneral) && !isNaN(infoAsiento)) {
      request_body.codigo = infoAsiento
      request_body.tipo = 'INVENTARIO'
      getInitAnticipos(request_body)
    }
    if (origenModulo === parseInt(EAplicationsAcatha.RolesPago) && !isNaN(infoAsiento) && tipo !== null && tipo !== undefined) {
      request_body.codigo = infoAsiento
      request_body.tipo = tipo
      getInitAnticipos(request_body)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoAsiento, origenModulo])

  return (
    <>
      {modal.open === true && modalCuentas}
      {modalAnticipos.open === true && modalAnticipos.name === "modalAnticipos" && ReactDom.createPortal(modalAnticiposComponente, document.body)}
      {showConfirm === true && ReactDom.createPortal(confirmAction(), document.body)}

      {blobUrl && (
        <PdfVisualizer
          fileName={nameFile}
          urlPdf={blobUrl ?? ''}
          urlXml={''}
          urlPrint={''}
          urlBlobPdf={blobUrl ?? ''}
          onClose={() => setBlob(undefined)}
        />
      )}

      <CRow key="rowMain">
        <CCol key="colMain" >
          <Modalform
            name='apis'
            key='modalPlan'
            headerTitle={"Asiento Contable"}
            childrenBody={bodyModal()}
            childrenFooter={renderFooter()}
            closeOnBackdrop={false}
            show={props.show}
            onClose={closeModal}
            size="xl"
          />
        </CCol>
      </CRow>
    </>
  );
}

export default React.memo(ModalAsiento);