import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FiltrosMarcasState, BusquedaState } from '../types/types'
import { FETCH_STATUS, Seleccionado } from '../../../../../../store/types'
import { MarcaInventario } from '../../../../store/types'
import { InventarioService } from '../../../../services/inventario.service'

const initialState: BusquedaState<MarcaInventario> = {
  filter: {
    codigo: '',
    marcaWeb: '',
    initial: true
  },
  status: FETCH_STATUS.IDDLE,
  seleccionado: {
    index: null,
    row: null,
    selectedRow: null
  },
  mensaje: '',
  colapsado: true,
  resultados: [],
  nrItemsMarcasLista: 0,
}

export const fetchMarcas = createAsyncThunk<
  Array<MarcaInventario>,
  any
>('inventarios/catalogos/marcas/listar', async (filtro) => {
  try {
    const linesList = InventarioService.getMarcas(filtro)
    return linesList
  } catch (error) {
    return Promise.reject(error)
  }
})

const searchSlice = createSlice({
  name: 'buscarMarcas',
  initialState: initialState,
  reducers: {
    setCleanResult(state) {
      state.resultados = []
    },
    changeFilter(state, action: PayloadAction<FiltrosMarcasState>) {
      state.filter.codigo = action.payload.codigo
      state.filter.marcaWeb = action.payload.marcaWeb
      state.filter.initial = action.payload.initial
    },
    setCollapsed(state, action: PayloadAction<boolean>) {
      state.colapsado = action.payload
    },
    setResetSeleccion(state) {
      const toAdd = {
        index: null,
        row: null,
        selectedRow: null,
      }
      state.seleccionado = toAdd
    },
    setSeleccionarDato(state, action: PayloadAction<Seleccionado>) {
      const toAdd = {
        index: action.payload.index,
        row: action.payload.row,
        selectedRow: action.payload.selectedRow,
      }
      state.seleccionado = toAdd
    },
    setResultados(state, action: PayloadAction<Array<MarcaInventario>>) {
      state.resultados = action.payload
    },
    setnrRegistrosMarcasLista(state, action: PayloadAction<number>) {
      state.nrItemsMarcasLista = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMarcas.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchMarcas.fulfilled, (state, { payload }) => {
      state.resultados = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchMarcas.rejected, (state, { payload }) => {
      state.resultados = []
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
  },
})

export const { changeFilter, setCleanResult, setResetSeleccion, setSeleccionarDato, setResultados, setCollapsed, setnrRegistrosMarcasLista } = searchSlice.actions

export const searchReducer = searchSlice.reducer
