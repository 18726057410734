import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AutorizacionDocumentoVenta } from '../../../../../ventas/types/types'
import { ConfiguracionesLiquidaciones } from '../../../../../ventas/pages/liquidaciones/types/types'

const initialState: ConfiguracionesLiquidaciones = {
  autorizacionDocumento: null,
}

const configuracionesLiquidacionesSlice = createSlice({
  name: 'configuracionesLiquidacionesComprasSV',
  initialState: initialState,
  reducers: {
    initialize(state, acion: PayloadAction<AutorizacionDocumentoVenta>) {
      state.autorizacionDocumento = acion.payload
    },
    setAutorizacionModuloLiquidaciones(
      state,
      acion: PayloadAction<AutorizacionDocumentoVenta>,
    ) {
      state.autorizacionDocumento = acion.payload
    },
  },
})

export const configuracionesReducer = configuracionesLiquidacionesSlice.reducer
export const { setAutorizacionModuloLiquidaciones } =
  configuracionesLiquidacionesSlice.actions
