import React from 'react'
import { CBadge, CCard, CCardBody, CCardFooter, CCardHeader, CFormInput } from '@coreui/react-pro'
import DataGrid, { Column } from 'devextreme-react/data-grid'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import { consoleService } from '../../../../../../../services/console.service'
import { SmallLoadingIndicator } from '../../../../../../../views/componentes/loadingindicator/loadingindicator'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput'
import { utilidades } from '../../../../../../../helpers/utilidades'
import { OrdenPedidoService } from '../../../../../../ventas/pages/ordenes/pages/ordenPedido/services/ordenPedido.services'
import { parseDetalleItems } from '../helpers/helpers'
import { getLocales } from '../../../../../../componentes/localesLookUp/helper/helper'
import { getIndexProvider } from '../../../../../../shared/helpers/funciones'
import { LocalOption } from '../../../../../../componentes/localesLookUp/store/type'

const DetalleInventarioIinicial: React.FunctionComponent<any> = (props) => {
  const empresa = useSelector((state: RootState) => state.global.session.empresa)

  const [loading, setLoading] = React.useState(false)
  const [loaded, setLoaded] = React.useState(false)
  const [detalles, setDetalles] = React.useState<Array<any>>([])
  const [almacen, setAlmacen] = React.useState<string>('')

  const cargarInformacionDetallada = React.useCallback(
    async (inventarioInicial: any) => {

      setLoading(true)
      const resLocal = await getLocales('Elija un almacén')
      const detalle = await OrdenPedidoService.getOrdenDetalle(inventarioInicial?.codigo ?? 0)
      const resDetalle = await parseDetalleItems(detalle)
      if (resLocal.length > 0) {
        let almacenView: LocalOption = null
        const provider = resLocal.slice(0)
        const index = await getIndexProvider(provider, 'codigo', inventarioInicial?.cono ?? -1)
        if (index > -1) {
          almacenView = provider[index]
        } else {
          almacenView = provider[0]
        }
        setAlmacen(almacenView?.nombre ?? '')
      }
      setDetalles(resDetalle)
      setLoaded(true)
      setLoading(false)
      consoleService.log(inventarioInicial)
    },
    [],
  )

  React.useEffect(() => {
    cargarInformacionDetallada(props.data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) {
    return <SmallLoadingIndicator mensaje="Cargando información adicional del registro..." />
  }

  if (!loaded) {
    return <></>
  }
  const inventarioInicial = props.data as any
  return (
    <div>
      <RowContainer>
        <CustomCol xs="12">
          <CCard className="border-top-primary border-top-3" id="busqueda-modVentas-resumen">
            <CCardHeader>
              <span style={{ float: 'left' }}>
                <CBadge color="secondary" textColor="black">
                  <strong>
                    {`${inventarioInicial?.codigo ?? 0}-${inventarioInicial?.numero ?? ''}-${inventarioInicial?.estadoDescripcion ?? ''}`}
                  </strong>
                </CBadge>
              </span>
            </CCardHeader>
            <CCardBody>
              <div className="container">
                <RowContainer>
                  <CustomCol xs="12" md="3" xl="2">
                    <Labeled label="Inventario Nro:">
                      <CFormInput size="sm" readOnly type="string" value={`${inventarioInicial?.numero ?? ''}`} />
                    </Labeled>
                  </CustomCol>
                  <CustomCol xs="12" md="3" xl="2">
                    <Labeled label="Realizado por:">
                      <CFormInput size="sm" readOnly value={`${inventarioInicial?.usuarioNombre ?? ''}`} />
                    </Labeled>
                  </CustomCol>
                  <CustomCol xs="6" md="3" xl="2">
                    <Labeled label="Inventario de:">
                      <CFormInput size="sm" readOnly value={almacen} />
                    </Labeled>
                  </CustomCol>
                  {inventarioInicial?.fecha && (
                    <CustomCol xs="6" md="3" xl="2">
                      <Labeled label="Fecha Kardex:">
                        <CBadge color="info">{`${inventarioInicial?.fecha ?? ''}`}</CBadge>
                      </Labeled>
                    </CustomCol>
                  )}
                </RowContainer>
                <RowContainer>
                  <CustomCol xs="4" md="6" xl="2">
                    <Labeled label="Observaciones:">
                      <CFormInput value={`${inventarioInicial?.observaciones ?? ''}`} readOnly={true} size="sm" />
                    </Labeled>
                  </CustomCol>
                </RowContainer>
                <RowContainer className="tabla-busqueda-modVentas-resumen">
                  <DataGrid
                    keyExpr={['codigo', 'cantidad']}
                    dataSource={detalles}
                    focusedRowEnabled
                    hoverStateEnabled
                    showBorders
                    width={utilidades.getGridFullWidth()}
                  >
                    <Column dataField="barras" caption="Codigo" width="100px" />
                    <Column dataField="descripcion" caption="Descripción" width="300px" />
                    <Column dataField="cantidad" caption="Cantidad" width="100px" />
                    <Column dataField="precio" format={{ precision: empresa?.precision ?? 2 }} caption="Precio" width="100px" />
                    <Column dataField="valor" format={{ precision: empresa?.precision ?? 2 }} caption="Valor" width="100px" />
                  </DataGrid>
                </RowContainer>
              </div>
            </CCardBody>
            <CCardFooter></CCardFooter>
          </CCard>
        </CustomCol>
      </RowContainer>
    </div>
  )
}

export default React.memo(DetalleInventarioIinicial)
