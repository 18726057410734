import CustomStore from "devextreme/data/custom_store";
import { PagedResult, PaginationInfo } from "../../../../../store/types";
import { Vendedor, VendedorBusqueda } from "../type/types";
import DataSource from "devextreme/data/data_source";
import { SesionService } from "../../../../../services/sesion.service";
import { RequestHelper } from "../../../../../helpers/requestHelper";

export const VendedoresService = {
  getVendedoresDatasource, getVendedor, getVendedorByCode, getItemsGridDatasource, deleteSeller
}

async function getVendedorByCode(codigo: number): Promise<Vendedor> {
  const query: any = {
    codigo: codigo,
  };
  const proveedor = await RequestHelper.get<Vendedor>(
    "ventas/ventas",
    "sellers/getByIdOrCode",
    "",
    query
  );
  return proveedor;
}

function getVendedoresDatasource() {
  const regex = new RegExp("^[0-9]{3,13}$");
  const customCache: any = {};

  const store = new CustomStore({
    key: "vendedorCodigo",
    byKey: async (key) => {

      if (customCache[key]) {
        return customCache[key];
      } else {
        const vendedor = await getVendedorByCode(key);
        customCache[key] = vendedor;
        return vendedor;
      }

    },
    load: async (loadOptions) => {
      console.log("loadOptions", loadOptions);
      console.log("loadOptions.filter", loadOptions.filter);

      if (loadOptions.filter && loadOptions.filter.length === 3 && Array.isArray(loadOptions)) {
        //Si hay filtro devolvemos null y ya
        const filter = loadOptions.filter;
        if (filter[0] === "vendedorCodigo") {
          //Recuperar el vendedor por codigo
          if (filter[2] === 0) return {
            data: [],
            totalCount: 0,
          }; //no existe el vendedor con codigo 0
          const vendedor = await getVendedorByCode(filter[2])
          return {
            data: vendedor,
            totalCount: 1,
          };
        }
        return {
          data: [],
          totalCount: 0,
        };
      }

      const paginInfo: PaginationInfo = {
        current: (loadOptions.skip ?? 0) / (loadOptions.take ?? 0) + 1,
        size: loadOptions.take ?? 10,
      };
      let pagedResult: PagedResult<VendedorBusqueda>;
      if (regex.test(loadOptions.searchValue)) {
        pagedResult = await consultarVendedoresPaged(
          "",
          loadOptions.searchValue.toString(),
          paginInfo
        );
      } else {
        pagedResult = await consultarVendedoresPaged(
          loadOptions.searchValue,
          "",
          paginInfo
        );
      }
      console.log("current page ", pagedResult.currentPage);
      return {
        data: pagedResult.result,
        totalCount: pagedResult.count,
      };
    },
  });
  const source = new DataSource({
    paginate: true,
    pageSize: 10,
    store: store,
    key: "vendedorCodigo",
    loadMode: 'processed',
    requireTotalCount: true
  });
  return source;
}

async function getVendedor(
  identificacion: string,
  esTransportista: boolean | undefined = undefined
): Promise<VendedorBusqueda | null> {
  const proveedores = await consultarVendedores(
    "",
    identificacion
  );
  const proveedor = proveedores.find(
    (p) => p.usuarioIdentificacion === identificacion
  );
  return proveedor ?? null;
}

async function consultarVendedores(
  nombre: string,
  identificacion: string
): Promise<Array<VendedorBusqueda>> {
  const query: any = {
    estado: 1,
  };

  if (identificacion && identificacion.length > 0) {
    query.identificacion = identificacion;
  }
  if (nombre && nombre.length > 0) {
    query.nombre = nombre;
  }
  const proveedoresApi = await RequestHelper.get<Array<VendedorBusqueda>>(
    "proveedores",
    "all",
    "",
    query
  );
  return proveedoresApi;
}

async function consultarVendedoresPaged(
  nombre: string,
  identificacion: string,
  paginInfo: PaginationInfo
): Promise<PagedResult<VendedorBusqueda>> {

  return consultarVendedoresPagedApiPhp(
    nombre,
    identificacion,
    paginInfo
  );

}

async function consultarVendedoresPagedApiPhp(
  nombre: string,
  identificacion: string,
  pagination: PaginationInfo
): Promise<PagedResult<VendedorBusqueda>> {
  const sesion = SesionService.getCurrentSesion();
  const query: any = {
    page: pagination.current,
    limit: pagination.size,
    isPaged: true,
  };

  if (identificacion && identificacion.length > 0) {
    query.identificacion = identificacion;
  }
  if (nombre && nombre.length > 0) {
    query.nombre = nombre;
  }

  query.codigoLocal = sesion.local.codigo ?? -1;
  query.mensaje = "Elejir un vendedor";

  const pagedApiResult = await RequestHelper.get<any>(
    "ventas/ventas",
    "sellers/cboxLoad",
    "",
    query
  );
  const proveedoresApi = pagedApiResult.auto as Array<VendedorBusqueda>;

  const pagedResult: PagedResult<VendedorBusqueda> = {
    count: pagedApiResult.totalRegistros,
    currentPage: pagedApiResult.paginaActual,
    from: pagedApiResult.desde,
    result: proveedoresApi,
    to: pagedApiResult.hasta,
  };
  return pagedResult;
}

function getItemsGridDatasource(dataSeacrh) {
  console.log("getItemsGridDatasource", dataSeacrh)
  const store = new CustomStore({
    key: "vendedorCodigo",
    byKey: async (key) => {
      const item = await getVendedorByCode(key);
      console.log('item:', item);
      return item ? item : undefined;
    },
    load: async (loadOptions) => {
      console.log(loadOptions);
      const paginInfo: PaginationInfo = {
        current: (loadOptions.skip ?? 0) / (loadOptions.take ?? 0) + 1,
        size: loadOptions.take ?? 10,
      };
      let pagedResult: PagedResult<VendedorBusqueda>;
      console.log("loadOptions", loadOptions)
      // eslint-disable-next-line prefer-const
      pagedResult = await consultarVendedoresPaged(
        dataSeacrh['nombres'] ?? "", dataSeacrh["identificacion"] ?? "", paginInfo);
      console.log("current page ", pagedResult.currentPage);

      return {
        data: pagedResult.result,
        totalCount: pagedResult.count,
      };
    },
  });
  const source = new DataSource({
    totalCount: store.totalCount,
    paginate: true,
    pageSize: 10,
    store: store,
    key: "vendedorCodigo",
  });
  return source;
}

async function deleteSeller(codSeller: number): Promise<any> {
  try {
    const queryData = {
      codigo: codSeller
    }
    const deleteResult = await RequestHelper.deleteRequestAll<any>(
      'ventas',
      'ventas/inactiveSeller',
      '',
      queryData,
    )
    return deleteResult
  } catch (error) {
    return error
  }
}