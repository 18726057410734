import { CCard, CCardBody } from '@coreui/react-pro'
import React from 'react'

interface IRentaProps extends React.PropsWithChildren {
  tabId: string
}

export default function TRenta(props: IRentaProps) {
  const { tabId } = props

  return (
    <CCard style={{ border: 1 }} className="m-0">
      <CCardBody>
        <div style={{ padding: '10px', overflowX: 'hidden' }} className="mb-4">
          Renta
        </div>
      </CCardBody>
    </CCard>
  )
}
