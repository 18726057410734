import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  KardexListReserve,
  KardexListResult,
  optionsStock,
  SearchFilter,
  SearchState,
} from '../types/types'
import { LocalOption } from '../../../../componentes/localesLookUp/store/type'
import { GrupoInventario, MarcaInventario } from '../../../store/types'

const initialState: SearchState<KardexListResult | KardexListReserve> = {
  filter: {
    barras: '',
    codigo: 0,
    descripcion: '',
    local: null,
    fechaDesde: '',
    fechaHasta: '',
    visualizarBodega: false,
    stock: optionsStock[0],
    grupo: null,
    marca: null,
    locales: [],
    itemLookup: null,
  },
  resultItem: [],
  resultAll: [],
  resultReserve: [],
  tabs: ['Item', 'Todas', 'Reservas'],
  currentTab: 'Item',
  existenciaMin: null,
  existenciaMax: null,
  costo: null,
  saldoReservas: null,
  saldoFecha: null,
  codeSelectedKardex: 0,
}

const searchSlice = createSlice({
  name: 'searchKardex',
  initialState: initialState,
  reducers: {
    setFilter(state, action: PayloadAction<SearchFilter>) {
      state.filter.codigo = action.payload.codigo
      state.filter.barras = action.payload.barras
      state.filter.descripcion = action.payload.descripcion
      state.filter.fechaDesde = action.payload.fechaDesde
      state.filter.fechaHasta = action.payload.fechaHasta
      state.filter.visualizarBodega = action.payload.visualizarBodega
      state.filter.stock = action.payload.stock
      state.filter.locales = action.payload.locales
      state.filter.itemLookup = action.payload.itemLookup
    },
    setFooter(
      state,
      action: PayloadAction<{
        existenciaMin: number
        existenciaMax: number
        costo: number
        saldoReservas: number
        saldoFecha: number
      }>,
    ) {
      state.existenciaMin = action.payload.existenciaMin
      state.existenciaMax = action.payload.existenciaMax
      state.costo = action.payload.costo
      state.saldoReservas = action.payload.saldoReservas
      state.saldoFecha = action.payload.saldoFecha
    },
    setStore(state, action: PayloadAction<LocalOption>) {
      state.filter.local = action.payload
    },
    setBrand(state, action: PayloadAction<MarcaInventario>) {
      state.filter.marca = action.payload
    },
    setGroup(state, action: PayloadAction<GrupoInventario>) {
      state.filter.grupo = action.payload
    },
    setSearchResult(state, action: PayloadAction<Array<KardexListResult>>) {
      state.resultItem = action.payload
    },
    setSearchAll(state, action: PayloadAction<Array<KardexListResult>>) {
      state.resultAll = action.payload
    },
    setSearchReserve(state, action: PayloadAction<Array<KardexListReserve>>) {
      state.resultReserve = action.payload
    },
    setCleanResult(state) {
      state.filter.barras = ''
      state.filter.codigo = 0
      state.filter.descripcion = ''
      state.filter.local = null
      state.filter.visualizarBodega = false
      state.filter.stock = optionsStock[0]
      state.filter.grupo = null
      state.filter.marca = null
      state.filter.locales = []
      state.filter.itemLookup = null
      state.resultItem = []
      state.resultAll = []
      state.resultReserve = []
      state.existenciaMin = null
      state.existenciaMax = null
      state.costo = null
      state.saldoReservas = null
      state.saldoFecha = null
    },
    setCodeSelectedKardex(state, action: PayloadAction<number>) {
      state.codeSelectedKardex = action.payload
    },
    changeFilter(state, action: PayloadAction<SearchFilter>) {
      state.filter = action.payload
    },
    changeCurrentTab(state, action: PayloadAction<string>) {
      state.currentTab = action.payload
    },
  },
})

export const {
  setFilter,
  changeFilter,
  setCleanResult,
  changeCurrentTab,
  setStore,
  setBrand,
  setGroup,
  setSearchResult,
  setSearchAll,
  setSearchReserve,
  setFooter,
  setCodeSelectedKardex,
} = searchSlice.actions

export const searchReducer = searchSlice.reducer
