import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  FETCH_STATUS,
  LoaderInfo,
} from '../../../../../../../../../../store/types'
import {
  FiltroBusquedaLibroTransaccionalState,
  SearchStateLibroTransaccional,
  TiposLibrosSLV,
} from '../types/types'
import { DateUtils } from '../../../../../../../../../../helpers/dateUtils'
import { ComprobanteDte, DteType } from '../../../types/types'
import { anexoTransaccionalSVService } from '../../../../../services/anexoTransaccionalSV.services'
import { RootState } from '../../../../../../../../../../store/store'

const initialState: SearchStateLibroTransaccional = {
  filter: {
    fechaInicio: DateUtils.getCurrentDateAsString(),
    fechaFinal: DateUtils.getCurrentDateAsString(),
    dteOptions: [],
    dte: {
      '01': true,
    },
    loader: {
      show: false,
      mensaje: '',
    },
    tabActive: TiposLibrosSLV.ConsumidorFinal,
    tabs: [
      {
        visible: true,
        title: 'Consumidores Finales',
        code: TiposLibrosSLV.ConsumidorFinal,
      },
      {
        visible: true,
        title: 'Contribuyentes',
        code: TiposLibrosSLV.Contribuyentes,
      },
      {
        visible: true,
        title: 'Resumen',
        code: TiposLibrosSLV.Resumen,
      },
    ],
    consumidorFinal: [],
    contribuyentes: [],
    resumen: [],
    exportaciones: [],
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  resultados: [],
  finalizaBusqueda: false,
}

export const fetchLibrosTransaccional = createAsyncThunk<
  Array<ComprobanteDte> | [],
  FiltroBusquedaLibroTransaccionalState
>('integraciones/sv/getDataDte', async (filtro) => {
  try {
    const anexoData = await anexoTransaccionalSVService.getAnexoTransac(filtro)
    return anexoData
  } catch (error) {
    return Promise.reject(error)
  }
})

const searchAnexoSlice = createSlice({
  name: 'searchLibroSV',
  initialState: initialState,
  reducers: {
    resetState(state) {
      state = {
        ...initialState,
        filter: {
          ...initialState.filter,
        },
      }
    },
    changeFilter(
      state,
      action: PayloadAction<FiltroBusquedaLibroTransaccionalState>,
    ) {
      state.filter = action.payload
    },
    setDateStart(state, action: PayloadAction<string>) {
      state.filter.fechaInicio = action.payload
    },
    setDateEnd(state, action: PayloadAction<string>) {
      state.filter.fechaFinal = action.payload
    },
    setCleanResult(state) {
      state.resultados = []
      state.finalizaBusqueda = false
    },
    setResultados(state, action: PayloadAction<Array<ComprobanteDte> | []>) {
      state.resultados = action.payload
      state.finalizaBusqueda = false
    },
    setResetEndBusqueda(state) {
      state.finalizaBusqueda = false
    },
    setDte(state, action: PayloadAction<DteType>) {
      state.filter.dte = action.payload
    },
    setLoader(state, action: PayloadAction<LoaderInfo>) {
      state.filter.loader = action.payload
    },
    setTabActive(state, action: PayloadAction<TiposLibrosSLV>) {
      state.filter.tabActive = action.payload
    },
    setConsumidorFinalData(state, action: PayloadAction<any>) {
      state.filter.consumidorFinal = action.payload
    },
    setContribuyenteFinalData(state, action: PayloadAction<any>) {
      state.filter.contribuyentes = action.payload
    },
    setResumenData(state, action: PayloadAction<any>) {
      state.filter.resumen = action.payload
    },
    setExportacionesData(state, action: PayloadAction<any>) {
      state.filter.exportaciones = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLibrosTransaccional.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(
      fetchLibrosTransaccional.fulfilled,
      (state, { payload }) => {
        state.resultados = payload
        state.finalizaBusqueda = true
        state.status = FETCH_STATUS.SUCCESS
      },
    )
    builder.addCase(fetchLibrosTransaccional.rejected, (state, { payload }) => {
      state.resultados = []
      state.finalizaBusqueda = false
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
  },
})

export const selectFiltroBusquedaLibros = (state: RootState) => {
  return state.impuestosSV.libroTransaccional.search.filter
}
export const selectResultadosBusquedaAnexos = (state: RootState) => {
  return state.impuestosSV.libroTransaccional.search.resultados
}
export const selectBusquedaAnexosEstado = (state: RootState) => {
  return state.impuestosSV.libroTransaccional.search.status
}
export const selectBDataConsumidorFinal = (state: RootState) => {
  return state.impuestosSV.libroTransaccional.search.filter.consumidorFinal
}
export const selectBDataContribuyentes = (state: RootState) => {
  return state.impuestosSV.libroTransaccional.search.filter.contribuyentes
}
export const selectBDataResumen = (state: RootState) => {
  return state.impuestosSV.libroTransaccional.search.filter.resumen
}
export const selectBDataExportaciones = (state: RootState) => {
  return state.impuestosSV.libroTransaccional.search.filter.exportaciones
}
export const {
  setExportacionesData,
  setResumenData,
  setConsumidorFinalData,
  setContribuyenteFinalData,
  setTabActive,
  setLoader,
  setResetEndBusqueda,
  setResultados,
  resetState,
  setCleanResult,
  setDateStart,
  setDateEnd,
  changeFilter,
  setDte,
} = searchAnexoSlice.actions
export const searchAnexoReducer = searchAnexoSlice.reducer
