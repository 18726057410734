import React from 'react'
import SelectBox from 'devextreme-react/select-box'
import { TOptionGenericoString } from '../../clientes/pages/clientes/types/types'
import { TypesDepretiationService } from './services/tiposDepreciacio.services'

interface MetodoTipoDepreciacionLookUpProps extends React.PropsWithChildren {
  selected: TOptionGenericoString | null
  // eslint-disable-next-line no-unused-vars
  onChanged: (newValue: TOptionGenericoString | null) => void
  // eslint-disable-next-line no-unused-vars
  onChangedOptions?: (newValue: Array<TOptionGenericoString> | []) => void
  disabled?: boolean
}

const MetodosTiposDepreciacionesLookUp: React.FC<
  MetodoTipoDepreciacionLookUpProps
> = (props) => {
  const { selected, onChanged, disabled, onChangedOptions } = props
  const selectRef = React.useRef<any>()
  const [options, setOptions] = React.useState<
    Array<TOptionGenericoString> | []
  >([])

  const loadDatos = React.useCallback(async () => {
    const data = await TypesDepretiationService.getTypeDepretiation('Ninguno')
    if (data.error === false) {
      let opt = []
      if (data.auto.length > 0) {
        opt = data.auto.map((x) => {
          const item: TOptionGenericoString = {
            value: String(x?.codigo ?? 0),
            label: x?.descripcion ?? '',
          }
          return item
        })
      }
      setOptions(opt)
      onChangedOptions(opt)
      const selectedIndex = opt.findIndex((option: TOptionGenericoString) => {
        return option.value === selected?.value
      })
      if (selectedIndex > -1) {
        onChanged(opt[selectedIndex] ?? opt[0])
      }
    }
  }, [onChanged, selected, onChangedOptions])

  const getItemDisplayExpr = React.useCallback((item: any) => {
    return item ? `${item.label}` : ''
  }, [])

  const onLookUpValueChanged = React.useCallback(
    (evt: any) => {
      if (evt.event !== undefined) {
        onChanged(evt.value)
      }
    },
    [onChanged],
  )

  const onSelectBoxFocusIn = React.useCallback((e) => {
    e.element.querySelector('input.dx-texteditor-input').select()
  }, [])

  const refreshSelected = React.useCallback(() => {
    if (options && options.length > 0) {
      const selectedIndex = options.findIndex((option: any) => {
        return option.value === selected?.value
      })
      onChanged(options[selectedIndex] ?? options[0])
    }
  }, [onChanged, options, selected])

  React.useEffect(() => {
    loadDatos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    refreshSelected()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  return (
    <SelectBox
      id="tiposProcedenciaSelect"
      ref={selectRef}
      items={options ?? []}
      searchEnabled
      displayExpr={getItemDisplayExpr}
      searchExpr={['label']}
      searchTimeout={500}
      value={selected}
      onValueChanged={onLookUpValueChanged}
      disabled={disabled}
      onFocusIn={onSelectBoxFocusIn}
    >
      {props.children}
    </SelectBox>
  )
}

export default React.memo(MetodosTiposDepreciacionesLookUp)