import React from 'react';
import Checkbox from './Checkbox';
import EditableName from './EditableName';
import PropTypes from 'prop-types';
import './folderTreeCSS.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FolderComponent = ({ level, checked, handleCheck, filename, toggleFolder, open, setMyName, selectMe, selected }) => (
  <div className={"folder"} style={{ marginLeft: getInden(level) }}>
    <Checkbox status={checked} handleCheck={handleCheck} />

    <a onClick={toggleFolder}>
      <FontAwesomeIcon
        size="lg"
        icon={['fas', open ? "caret-down" : "caret-right"]} style={{ marginLeft: '10px' }}
      />
    </a>

    <span className={selected ? ["folderText", "selected"].join(' ') : "folderText"} onClick={selectMe}>
      {open ?
        <FontAwesomeIcon
          size="lg"
          icon={['fal', "folder-open"]} style={{ marginLeft: '10px' }}
        /> :
        <FontAwesomeIcon
          size="lg"
          icon={['fal', "folder"]} style={{ marginLeft: '10px' }}
        />
      }
      <EditableName filename={filename} setMyName={setMyName} selected={selected} />
    </span>

  </div>
);

FolderComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  path: PropTypes.array.isRequired,
  level: PropTypes.number.isRequired,
  checked: PropTypes.number.isRequired,
  filename: PropTypes.string.isRequired,
  selected: PropTypes.number.isRequired,

  selectMe: PropTypes.func.isRequired,
  setMyName: PropTypes.func.isRequired,
  handleCheck: PropTypes.func.isRequired,
  toggleFolder: PropTypes.func.isRequired,
}

function getInden(level) {
  return `${5 * level}px`;
}

export default FolderComponent;