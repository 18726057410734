import React, { useEffect } from 'react'
import { ImportacionesDatosEdicion } from '../../types/types'
import { ButtonTypes } from '../../../../../../../views/componentes/globalMenu/types'
import { clearButtonClick, openTab, setCurrentExecutingAction } from '../../store/tabsReducer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import {
  changeLoaderImports,
  setCurrentFunction,
} from '../../store/generalReducer'
import { changeCurrentTab, setResetSeleccion, setResultados } from '../../store/searchReducer'
import {
  CCard,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
} from '@coreui/react-pro'
import { v4 as uuidv4 } from 'uuid'

import Cabecera from './cabecera'
import Detalle from './detalle'
import { TabState } from '../../../../../../../store/genericTabTypes'
import FiltroBusqueda from './filtroBusqueda'
import Consolidado from './consolidado'
import { initDatosEdicion } from '../../store/editDataReducer'
import { FETCH_STATUS, ToastTypes } from '../../../../../../../store/types'
import TableLoader from '../../../../../../ventas/components/ventas/busquedaVentas/TableLoader'
import { Draggable, SpeedDialAction } from 'devextreme-react'
import { isMobile } from 'react-device-detect'
import BlockUi from '../../../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../../../views/componentes/loadingindicator/loadingindicator'
import { DateUtils } from '../../../../../../../helpers/dateUtils'
import { incomeExpensesServices } from '../../../ingresosEgresos/services/ingresosEgresos.services'
import { addToast } from '../../../../../../../store/toasterReducer'
import { getIndexProvider } from '../../../../../../shared/helpers/funciones'
import { defaultDataImports } from '../nuevo/index';



export const optionsActivos = [
  { value: 1, label: 'Activos' },
  { value: 2, label: 'Inactivos' },
]

enum VistasBusqueda {
  Filtros,
  ResultadosBusqueda,
  Resumen,
  Loading,
  Error
}

interface ISearchProps extends React.PropsWithChildren {
  tab: TabState<ImportacionesDatosEdicion>
  tabId: string
}

const draggingGroupName = 'appointmentsGroup';


const Search: React.FunctionComponent<ISearchProps> = (props) => {
  const { tabId } = props

  const dispatch = useDispatch()
  const currentAction = useSelector((state: RootState) => state.inventarios.movimientos.importaciones.general.currentFunction)
  const estadoBusqueda = useSelector((state: RootState) => state.inventarios.movimientos.importaciones.search.status)
  const currentTab = useSelector((state: RootState) => state.inventarios.movimientos.importaciones.tabs.current)
  const importacionesLoader = useSelector((state: RootState) => state.inventarios.movimientos.importaciones.general)
  const searchResults = useSelector((state: RootState) => state.inventarios.movimientos.importaciones.search.resultados)
  const selected = useSelector((state: RootState) => state.inventarios.movimientos.importaciones.search.seleccionado)
  const currentTabState = useSelector((state: RootState) => state.inventarios.movimientos.importaciones.search)
  const [vistaActual, setVistaActual] = React.useState<VistasBusqueda>(VistasBusqueda.Filtros)

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoaderImports({ show: false, mensaje: '' }))
  }, [dispatch])

  const playLoader = React.useCallback((message = 'Cargando...') => {
    dispatch(changeLoaderImports({ show: true, mensaje: message }))
  }, [dispatch])

  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(
      addToast({
        title: 'Inventario-Importaciones',
        content: mensaje,
        type: tipo,
      }),
    )
  }, [dispatch])


  const onAbrir = React.useCallback((importacion: any) => {
    const id = uuidv4();
    dispatch(initDatosEdicion({
      key: id,
      data: {
        ...defaultDataImports,
        loading: true,
      }
    }));
    const d = { ...defaultDataImports }
    d.codigo = importacion.codigo
    d.documento = importacion.documento
    dispatch(openTab({ key: id, importacion: importacion }));
  }, [dispatch])

  const deleteItemTabla = React.useCallback(async () => {
    if (selected !== null && selected !== undefined) {
      if (searchResults !== null && searchResults !== undefined && searchResults['auto'].length > 0) {
        const provider = searchResults['auto'].slice(0);
        const index = await getIndexProvider(provider, 'codigo', selected.codigo)
        if (index > -1) {
          provider.splice(index, 1)
          const payload: any = {
            error: false,
            auto: provider
          }
          dispatch(setResultados(payload))
          dispatch(setResetSeleccion())
        }
      }
    }
  }, [selected, dispatch, searchResults])


  const onHandleRemove = React.useCallback(async () => {
    try {
      dispatch(setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: ButtonTypes.delete
      }))
      const delete_data = {
        codigo: selected?.codigo ?? 0,
        fecha: selected?.fecha ?? DateUtils.getCurrentDateAsString('dd/MM/yyyy')
      }
      if (isMobile) {
        setVistaActual(VistasBusqueda.Loading)
      } else {
        playLoader('Anulando Regsitro...')
      }
      const data = await incomeExpensesServices.deleteIncomeExpense(delete_data)
      if (isMobile) {
        setVistaActual(VistasBusqueda.ResultadosBusqueda)
      } else {
        stopLoader()
      }
      if (data !== null && data !== undefined && data['error'] === false) {
        deleteItemTabla()
        setToast(data['message'], ToastTypes.Success)
      } else {
        setToast(data['message'], ToastTypes.Danger)
      }
    } catch (error) {
      if (isMobile) {
        setVistaActual(VistasBusqueda.ResultadosBusqueda)
      } else {
        stopLoader()
      }
      setToast(error.message, ToastTypes.Danger)
    }

    dispatch(setCurrentExecutingAction({
      tabKey: tabId,
      buttonType: undefined
    }))
  }, [deleteItemTabla, dispatch, playLoader, selected, setToast, stopLoader, tabId])
  const handleButtonClick = React.useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.disable:
          onHandleRemove()
          break
        case ButtonTypes.edit:
          // onHandleEdit()
          onAbrir(selected)
          break
        default:
          break
      }
      dispatch(setCurrentFunction(''))
      dispatch(clearButtonClick(tabId))
    },
    [dispatch, onHandleRemove,
      onAbrir,
      selected,
      tabId],
  )

  useEffect(() => {
    if (currentAction !== '') handleButtonClick(currentAction)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAction])


  React.useEffect(() => {
    switch (estadoBusqueda) {
      case FETCH_STATUS.IDDLE:
        setVistaActual(VistasBusqueda.Filtros);
        break;
      case FETCH_STATUS.LOADING:
        setVistaActual(VistasBusqueda.Loading);
        break;
      case FETCH_STATUS.SUCCESS:
        setVistaActual(VistasBusqueda.ResultadosBusqueda);
        break;
      case FETCH_STATUS.FAILED:
        setVistaActual(VistasBusqueda.Error);
        break;
      default:
        break;
    }
    // if (estadoBusqueda === FETCH_STATUS.IDDLE || estadoBusqueda === FETCH_STATUS.FAILED) {
    //   setVistaActual(VistasBusqueda.Loading);
    // } else {
    //   setVistaActual(false);
    // }
  }, [estadoBusqueda])


  if (isMobile) {
    return (
      <>
        <CCard>

          {vistaActual === VistasBusqueda.Filtros &&

            (
              <>
                < FiltroBusqueda key='filtroBusqueda' />
                <CNav variant="tabs" role="tablist" className="mt-4">
                  <CNav variant="tabs">
                    {currentTabState.tabs.map((tab, index) => (
                      <CNavItem key={index}>
                        <CNavLink
                          onClick={() =>
                            dispatch(changeCurrentTab(currentTabState.tabs[index]))
                          }
                          active={currentTabState.currentTab === tab}
                        >
                          {tab}
                        </CNavLink>
                      </CNavItem>
                    ))}
                  </CNav>
                </CNav>
              </>
            )
          }
          {vistaActual === VistasBusqueda.ResultadosBusqueda &&
            (<>
              <CTabContent
                style={{
                  overflowY: 'unset',
                  width: '100%',
                  marginBottom: '10px',
                }}
              >
                <CTabPane
                  role="tabpanel"
                  aria-labelledby="cabecera-tab"
                  visible={currentTabState.currentTab === currentTabState.tabs[0]}
                >
                  <Cabecera />
                </CTabPane>
                <CTabPane
                  role="tabpanel"
                  aria-labelledby="detalle-tab"
                  visible={currentTabState.currentTab === currentTabState.tabs[1]}
                >
                  <Detalle />
                </CTabPane>
                <CTabPane
                  role="tabpanel"
                  aria-labelledby="consolidado-tab"
                  visible={currentTabState.currentTab === currentTabState.tabs[2]}
                >
                  <Consolidado />
                </CTabPane>
              </CTabContent>
            </>)
          }
          {vistaActual === VistasBusqueda.Error &&
            <></>
          }
          {vistaActual === VistasBusqueda.Loading &&
            <TableLoader />
          }
          {
            currentTab === tabId && (
              <Draggable
                id="list"
                data="dropArea"
                group={draggingGroupName}
              >
                <SpeedDialAction
                  icon="filter"
                  label='Filtros'
                  visible={true}
                  index={1}
                  onClick={() => {
                    setVistaActual(VistasBusqueda.Filtros);
                  }}
                />
                <SpeedDialAction
                  icon="search"
                  label='Busqueda'
                  visible={true}
                  index={2}
                  onClick={() => {
                    setVistaActual(VistasBusqueda.ResultadosBusqueda);
                  }}
                />
              </Draggable>
            )
          }
        </CCard>
      </>
    )
  }


  return (
    <>
      <BlockUi
        tag="div"
        loader={LoadingIndicator}
        blocking={importacionesLoader.loader.show}
        message={importacionesLoader.loader.mensaje}
      >
        <CCard>
          <FiltroBusqueda />
          <CNav variant="tabs" role="tablist" className="mt-4">
            <CNav variant="tabs">
              {currentTabState.tabs.map((tab, index) => (
                <CNavItem key={index}>
                  <CNavLink
                    onClick={() =>
                      dispatch(changeCurrentTab(currentTabState.tabs[index]))
                    }
                    active={currentTabState.currentTab === tab}
                  >
                    {tab}
                  </CNavLink>
                </CNavItem>
              ))}
            </CNav>
            <CTabContent
              style={{
                overflowY: 'unset',
                width: '100%',
                marginBottom: '10px',
              }}
            >
              <CTabPane
                role="tabpanel"
                aria-labelledby="cabecera-tab"
                visible={currentTabState.currentTab === currentTabState.tabs[0]}
              >
                <Cabecera />
              </CTabPane>
              <CTabPane
                role="tabpanel"
                aria-labelledby="detalle-tab"
                visible={currentTabState.currentTab === currentTabState.tabs[1]}
              >
                <Detalle />
              </CTabPane>
              <CTabPane
                role="tabpanel"
                aria-labelledby="consolidado-tab"
                visible={currentTabState.currentTab === currentTabState.tabs[2]}
              >
                <Consolidado />
              </CTabPane>
            </CTabContent>
          </CNav>
        </CCard>
      </BlockUi>
    </>
  )
}

export default React.memo(Search)
