import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DateUtils } from "../../../../../helpers/dateUtils";
import { FETCH_STATUS } from "../../../../../store/types";
import { FilterSubscriptionssState, SeeSubcriptionsState } from "../types/types";
import { Seleccionado } from "../../../../bancos/store/types";
import { VerAbonosService } from "../components/verPagos/services/verAbonos.service";

const initialState: SeeSubcriptionsState<any> = {
  filter: {
    proveedor: null,
    imprimirTodos: false,
    anulados: false,
    comprobante: '',
    cheque: '',
    egreso: '',
    fechaDesde: DateUtils.dateToString(new Date(new Date().getFullYear(), 0, 1), 'dd/MM/yyyy'),
    fechaHasta: DateUtils.dateToString(new Date(new Date().getFullYear() + 1, 11, 31), 'dd/MM/yyyy'),
  },
  status: FETCH_STATUS.IDDLE,
  tabs: ['Abonos', 'Auditoria'],
  currentTab: 'Abonos',
  keyTab: 'Abonos',
  deleteItem: false,
  mensaje: '',
  resultados: [],
  seleccionado: {
    index: null,
    row: null,
    selectedRow: null,
  },
  currentExecute: null,
  currentActiontate: ''
}

export const fetchAbonosPagar = createAsyncThunk<
  Array<any>,
  FilterSubscriptionssState
>('generales/purchases/seePayment', async (filtro) => {
  try {
    const abonosApi = await VerAbonosService.seePayments(filtro)
    return abonosApi
  } catch (error) {
    return Promise.reject(error)
  }
})

const seeSubscriptionsSlice = createSlice({
  name: 'verAbonosPagar',
  initialState: initialState,
  reducers: {
    setCleanResultAbonos(state) {
      state.resultados = []
    },
    setDeleteItem(state, action: PayloadAction<boolean>) {
      state.deleteItem = action.payload
    },
    changeResultAbonos(state, action: PayloadAction<Array<any>>) {
      state.resultados = action.payload
    },
    setCurrentAction(state, action: PayloadAction<string>) {
      state.currentActiontate = action.payload
    },
    setCleanFieldsAbonos(state) {
      state.filter.proveedor = null
      state.filter.imprimirTodos = false
      state.filter.anulados = false
      state.deleteItem = false
      state.filter.comprobante = ''
      state.filter.cheque = ''
      state.filter.egreso = ''
    },
    initData(state) {
      state.filter.proveedor = null
      state.filter.imprimirTodos = false
      state.filter.anulados = false
      state.filter.comprobante = ''
      state.deleteItem = false,
        state.filter.cheque = ''
      state.filter.egreso = ''
    },
    changeFilter(state, action: PayloadAction<FilterSubscriptionssState>) {
      state.filter = action.payload
      state.filter.proveedor = action.payload.proveedor
      state.filter.imprimirTodos = action.payload.imprimirTodos
      state.filter.anulados = action.payload.anulados
      state.filter.comprobante = action.payload.comprobante
      state.filter.cheque = action.payload.cheque
      state.filter.egreso = action.payload.egreso
      state.filter.fechaDesde = action.payload.fechaDesde
      state.filter.fechaHasta = action.payload.fechaHasta
    },
    changeCurrentTab(state, action: PayloadAction<string>) {
      state.currentTab = action.payload
      state.keyTab = action.payload
    },
    setResetSeleccion(state) {
      const toAdd = {
        index: null,
        row: null,
        selectedRow: null,
      }
      state.seleccionado = toAdd
    },
    setSeleccionarDato(state, action: PayloadAction<Seleccionado>) {
      const toAdd = {
        index: action.payload.index,
        row: action.payload.row,
        selectedRow: action.payload.selectedRow,
      }
      state.seleccionado = toAdd
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAbonosPagar.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchAbonosPagar.fulfilled, (state, { payload }) => {
      state.resultados = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchAbonosPagar.rejected, (state, { payload }) => {
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
  }
})


export const { changeFilter, setCleanResultAbonos, initData, changeCurrentTab, setCleanFieldsAbonos, setSeleccionarDato, setCurrentAction, setResetSeleccion, setDeleteItem } =
  seeSubscriptionsSlice.actions

export const seeSubscriptionsReducer = seeSubscriptionsSlice.reducer