import React from 'react';
import { OpcionCliente } from './store/types';
import SelectBox from 'devextreme-react/select-box';
import { clientesOrigen } from './service/origenclientes.service';

interface OrigenClienteLookUpProps extends React.PropsWithChildren {
  selected: OpcionCliente | null,
  onChanged: (newValue: OpcionCliente | null) => void,
  provider?: Array<OpcionCliente> | []
}

const OrigenClienteLookUp: React.FC<OrigenClienteLookUpProps> = (props) => {
  const { provider, selected, onChanged } = props;
  const selectRef = React.useRef<any>();
  const [options, setOptions] = React.useState<Array<OpcionCliente> | []>([]);

  const loadDatos = React.useCallback(async () => {
    if (provider !== undefined && provider.length > 0) {
      setOptions(provider)
      const selectedIndex = provider.findIndex((select: OpcionCliente) => {
        return select.opcion === selected?.opcion
      })
      onChanged(provider[selectedIndex] || provider[0])
      if (selectedIndex > -1) {
        onChanged(provider[selectedIndex] || provider[0])
      }
    } else {
      const data = await clientesOrigen.getOptions();
      if (data.error === false) {
        let opt = [];
        if (data.auto.length > 0) {
          opt = data.auto.map(x => {
            const item: OpcionCliente = {
              opcion: String(x.opcion).toString() ?? '',
            };
            return item;
          });
        }
        setOptions(opt)
        const selectedIndex = opt.findIndex((option) => {
          return option.opcion === selected?.opcion
        })
        onChanged(opt[selectedIndex] || opt[0])
      }
    }
  }, [onChanged, selected, provider]);

  const getItemDisplayExpr = React.useCallback((item: OpcionCliente) => {
    return item ? `${item.opcion}` : '';
  }, []);

  const onLookUpValueChanged = React.useCallback(async (evt: any) => {
    if (evt.event !== undefined) {
      await onChanged(evt.value);
    }
  }, [onChanged]);

  const onSelectBoxFocusIn = React.useCallback((e) => {
    e.element.querySelector("input.dx-texteditor-input").select();
  }, [])

  const refreshSelected = React.useCallback(async () => {
    if (options && options.length > 0) {
      const selectedIndex = await options.findIndex((option: OpcionCliente) => {
        return option.opcion === selected?.opcion
      })
      onChanged(options[selectedIndex] ?? options[0])
    }
  }, [onChanged, options, selected])

  React.useEffect(() => {
    loadDatos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (selected !== null) {
      refreshSelected()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])
  return (
    <SelectBox
      id='origenClienteSelect'
      ref={selectRef}
      items={options ?? []}
      searchEnabled
      displayExpr={getItemDisplayExpr}
      searchExpr={['opcion']}
      searchTimeout={500}
      onEnterKey={(e) => { console.log(e) }}
      value={selected}
      onValueChanged={onLookUpValueChanged}
      onFocusIn={onSelectBoxFocusIn}
    >
      {props.children}
    </SelectBox>
  )

}
export default React.memo(OrigenClienteLookUp);
