import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FETCH_STATUS, LoaderInfo } from "../../../../../store/types";
import { MenuButtonList, OpcionesList, PermisosList, PermisosState } from "../types/types";



const initialState: PermisosState<PermisosList> = {
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  resultadosPermisos: [],
  resultadosOpciones: [],
  resultadosComandos: [],
  seleccionadoComando: null,
  seleccionadoPermiso: null,
  loader: {
    show: false,
    mensaje: '',
  },
  initial: true
}



const permisosSlice = createSlice({
  name: 'busquedaPermisos',
  initialState: initialState,
  reducers: {
    setResults(state, action: PayloadAction<PermisosList[]>) {
      state.resultadosPermisos = action.payload
    },
    setResultsOptions(state, action: PayloadAction<OpcionesList[]>) {
      state.resultadosOpciones = action.payload
    },
    setResultsCommands(state, action: PayloadAction<MenuButtonList[]>) {
      state.resultadosComandos = action.payload
    },
    setChangeLoader(state, action: PayloadAction<LoaderInfo>) {
      state.loader = action.payload
    },
    setResetSeleccion(state) {
      state.seleccionadoComando = null
    },
    setSeleccionarDato(state, action: PayloadAction<MenuButtonList>) {
      state.seleccionadoComando = action.payload
    },
    setResetSeleccionPermisos(state) {
      state.seleccionadoPermiso = null
    },
    setSeleccionarDatoPermisos(state, action: PayloadAction<PermisosList>) {
      state.seleccionadoPermiso = action.payload
    },
    setInitital(state, action: PayloadAction<boolean>) {
      state.initial = action.payload
    },
  }
})

export const {
  setResults,
  setChangeLoader,
  setResultsOptions,
  setResultsCommands,
  setResetSeleccion,
  setSeleccionarDato,
  setResetSeleccionPermisos,
  setSeleccionarDatoPermisos,
  setInitital
} = permisosSlice.actions

export const permisosReducer = permisosSlice.reducer
