import { combineReducers } from "redux";
import { ModuleTenthState } from "../types/types";
import { menuReducer } from "./menuReducer";
import { tabsReducer } from "./tabsReducer";
import { dataEditReducer } from "./editDataReducer";
import { transferReducer } from "./transferReducer";
import { todoReducer } from "./todosReducer";
import { searchReducer } from "./searchReducers";
import { configuracionesDecimos } from "./configReducer";




export const DecimosReducer = combineReducers<ModuleTenthState>({
  menu: menuReducer,
  tabs: tabsReducer,
  editData: dataEditReducer,
  transfer: transferReducer,
  search: searchReducer,
  todos: todoReducer,
  config: configuracionesDecimos
})