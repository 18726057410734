import React from 'react'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import { CCard, CCardBody, CCardHeader, CCollapse, CLink } from '@coreui/react-pro'
import { useDispatch, useSelector } from 'react-redux'
import { setCollapsed } from '../../store/transferReducer'
import { RootState } from '../../../../../../../store/store'
import CIcon from '@coreui/icons-react'
import { cilChevronBottom, cilChevronTop } from '@coreui/icons'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput'
import { Button, DataGrid, NumberBox, TextBox } from 'devextreme-react'
import { Column, ColumnChooser, Export, Pager, Paging } from 'devextreme-react/data-grid'
import { getAllowedPageSizes } from '../../../../../../../helpers/Helper'
import ItemsCountIndicator from '../../../../../../componentes/indicadorNroRegistros'

const Transferencia = () => {
  const dispatch = useDispatch()

  const collapsed = useSelector((state: RootState) => state.nomina.rol.decimos.transfer.colapsado)

  return (
    <>
      <RowContainer>
        <CCard>
          <CCardHeader
            onClick={() => dispatch(setCollapsed(!collapsed))}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <strong> {collapsed ? '' : 'Filtro de Búsqueda'} </strong>
            <CLink className="card-header-action">
              <CIcon icon={collapsed ? cilChevronBottom : cilChevronTop} />
            </CLink>
          </CCardHeader>
          <CCollapse visible={collapsed}>
            <CCardBody>
              <RowContainer>
                <CustomCol xs="12" md="3">
                  <Labeled label='Cuenta:'>
                    <div
                      style={{
                        display: 'flex',
                        gap: '2px',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >

                      <TextBox value={''} />
                      <Button
                        id="btnAdd"
                        className="me-1"
                        icon="plus"
                        stylingMode="contained"
                        type="default"
                        onClick={() => { }}
                      />
                    </div>
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="3">
                  <Labeled label='Año:'>
                    <NumberBox width={115} value={null} onValueChange={(data) => { }} />
                  </Labeled>
                </CustomCol>
              </RowContainer>
            </CCardBody>
          </CCollapse>
          <ItemsCountIndicator numberOfItems={0} />
          <RowContainer>
            <CustomCol xs="12" md="12">
              <DataGrid
                id="tablaTranferencias"
                dataSource={[]}
                selection={{ mode: 'single' }}
                hoverStateEnabled={true}
                showBorders={true}
                showRowLines={true}
                // onSelectionChanged={onSelectedChanged}
                repaintChangesOnly={true}
                highlightChanges={true}
                keyExpr="secuencial"
                key="secuencial"
                focusedRowKey="secuencial"
                showColumnLines={true}
                // onExporting={onExportExcell}
                className={'m-2 p-2'}
                remoteOperations={true}
                loadPanel={{
                  enabled: true,
                  height: 90,
                  indicatorSrc: '',
                  shading: false,
                  shadingColor: '',
                  showIndicator: true,
                  showPane: true,
                  text: 'Cargando...',
                  width: 200,
                }}
              >
                <Export enabled={true} allowExportSelectedData={true} />
                <ColumnChooser enabled={true} mode="select" />
                <Paging defaultPageSize={20} />
                <Pager
                  visible={false}
                  allowedPageSizes={getAllowedPageSizes([])}
                  displayMode={'full'}
                  showPageSizeSelector={true}
                  showInfo={true}
                  showNavigationButtons={true}
                />

                <Column dataField='pa' caption="COD. ORIENTACIONE" width="100px" />
                <Column dataField='cuentaEmpresa' caption="CTA. EMPRESA" width="100px" />
                <Column dataField='secuencial' caption="SECUENCIA PAGO" width="100px" />
                <Column dataField='cuentaPago' caption="COM. PAGO" width="100px" />
                <Column dataField='cuentaProveedor' caption="CTA. EMPLEADO" width="100px" />
                <Column dataField='moneda' caption="MONEDA" width="100px" />
                <Column dataField='valor' caption="VALOR" width="100px" />
                <Column dataField='formaPago' caption="FORMA PAGO" width="100px" />
                <Column dataField='cif' caption="CIF" width="100px" />
                <Column dataField='tipoCuenta' caption="CTA" width="100px" />
                <Column dataField='numeroCuenta' caption="NUM. CUENTA" width="100px" />
                <Column dataField='tipoID' caption="TIPO ID" width="100px" />
                <Column dataField='usuarioCedula' caption="ID" width="100px" />
                <Column dataField='nombres' caption="NOMBRES Y APELLIDOS" width="100px" />
                <Column dataField='usuarioDireccion' caption="DIRECCION" width="100px" />
                <Column dataField='ciudad' caption="CIUDAD" width="100px" />
                <Column dataField='flabor' caption="REFERENCIA" width="100px" />
              </DataGrid>
            </CustomCol>
          </RowContainer>
        </CCard>
      </RowContainer>
    </>
  )
}

export default React.memo(Transferencia)