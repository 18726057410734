import { CNav, CNavItem, CNavLink, CTabContent, CTabPane } from '@coreui/react-pro'
import React from 'react'
import { TabState } from '../../../../../../../store/genericTabTypes'
import { DocumentInfo } from '../../../../../../../store/types'
import { ContratoDatosEdicion, NuevoTabs } from '../../../types/types'
import Contrato from './contrato'
import Observaciones from './observaciones'

interface ITabsProps extends React.PropsWithChildren {
  info: DocumentInfo<ContratoDatosEdicion>
  tab: TabState<ContratoDatosEdicion>
  tabId: string
}

export const tabsNames = [NuevoTabs.con, NuevoTabs.obs]

const TabsEdition: React.FunctionComponent<ITabsProps> = (props) => {
  const { tabId, tab, info } = props

  const [currentTab, setCurrentTab] = React.useState<string>(NuevoTabs.con)

  return (
    <>
      <CNav variant="tabs" role="tablist" className="mt-4">
        <CNav variant="tabs">
          {tabsNames.map((tab, index) => (
            <CNavItem key={index}>
              <CNavLink
                onClick={() => {
                  setCurrentTab(tab)
                }}
                active={currentTab === tab}
              >
                {tab}
              </CNavLink>
            </CNavItem>
          ))}
        </CNav>
        <CTabContent
          style={{
            overflowY: 'unset',
            width: '100%',
            marginBottom: '10px',
          }}
        >
          <CTabPane
            role="tabpanel"
            aria-labelledby="generales-tab"
            visible={currentTab === tabsNames[0]}
          >
            <Contrato tabId={tabId} tab={tab} info={info} />
          </CTabPane>
          <CTabPane
            role="tabpanel"
            aria-labelledby="generales-tab"
            visible={currentTab === tabsNames[1]}
          >
            <Observaciones tabId={tabId} tab={tab} info={info} />
          </CTabPane>

        </CTabContent>
      </CNav>
    </>
  )
}

export default TabsEdition