import { DateUtils, formatoFechasApi } from '../../../../helpers/dateUtils';
import { RequestHelper } from '../../../../helpers/requestHelper'
import { utilidades } from '../../../../helpers/utilidades';
import { SesionService } from '../../../../services/sesion.service';
import { EspecialidadPrv } from '../store/types';

export const specialityServices = { getSpeciality }

async function getSpeciality(message: string): Promise<any> {
  try {
    const sesion = SesionService.getCurrentSesion()
    const data: any = {
      mensaje: message,
    }
    const keysToCache: any = {
      ...data,
      dateKey: DateUtils.getCurrentDateAsString(formatoFechasApi),
      empresa: sesion?.empresa?.codigo,
    }
    const cacheKey = 'tipoEspecialidadesOptions' + JSON.stringify(keysToCache);
    const cached = sessionStorage.getItem(cacheKey);
    if (cached) {
      return { error: false, auto: JSON.parse(cached) };
    } else {
      const res = await RequestHelper.getAll<any>('proveedores', 'especialidad/cboxLoad', '', data)
      const opt: Array<EspecialidadPrv> = [];
      if (res?.auto && res?.auto.length > 0) {
        for (const x of res.auto) {
          opt.push({
            codigo: parseInt(x?.codigo) ?? 0,
            estado: parseInt(x?.estado) ?? 0,
            descripcion: utilidades.capText(x?.nombre) ?? '',
          })
        }
        res.auto = opt
      }
      sessionStorage.setItem(cacheKey, JSON.stringify(opt));
      return res
    }
  } catch (error) {
    return error
  }
}
