import React, { useState, useEffect } from 'react'
import ReactDom from 'react-dom'
import {
  CCol,
  CRow, CButton
} from '@coreui/react-pro'
import Modalform from "../../../../../../views/componentes/modalform"
import { TIPOS_BOTONES } from './../../store/types';
import config from '../../../../../../config/config';
import { BotonMenu } from '../../../../../contabilidad/pages/planCuentas/store/types';
import { menuService } from '../../../../../../services/menu.service';
import { addToast } from '../../../../../../store/toasterReducer';
import { AccionMenu, ToastTypes } from '../../../../../../store/types';
import { useDispatch, useSelector } from 'react-redux'
import { changeLoader } from '../../../../../../store/reducers';
import { setCurrentAccion, setSeleccionarDato, setCurrentFunction, setData as setDataCuentasBancos } from '../../store/CCAhorrosReducers';
import { RootState } from '../../../../../../store/store'
import Buscar from '../buscar'
import Nuevo from '../nuevo'
import CardContent from '../../../../components/cardContent'
import { ExportarExcelService } from '../../services/exportarExcel.service'
import { findOnObject } from '../../../../../../helpers/Helper';
import { GlobalMenu } from '../../../../../../views/componentes/globalMenu/globalMenu';
import { lh, MessagesKeys } from '../../../../../../helpers/localizationHelper';
import { ccahorrosService } from '../../services/CCAhorrosService';

type ModalCuentasBancosProps = {
  show: boolean,
  onClose: () => void,
  cargarCuentas: (evt) => void,
  origen: string
  codModulo: number
}

const ModalCuentasBancos = (props: ModalCuentasBancosProps) => {
  const { codModulo } = props;
  const app = "c.c._y_ahorros"
  const pathVerificador = '/' + app;

  const dispatch = useDispatch()
  const currentActionState = useSelector((state: RootState) => state.bancos.ccahorros.currentAction);
  const registros = useSelector((state: RootState) => state.bancos.ccahorros.registros);
  const menuState = useSelector((state: RootState) => state.global.menu);
  const seleccionado = useSelector((state: RootState) => state.bancos.ccahorros.seleccionado);

  const [acciones, setAcciones] = useState<any>([]);
  const [showConfirm, setShowConfirm] = React.useState<boolean>(false);
  const [confirmDelete, setConfirmDelete] = React.useState<boolean>(false);
  const [confirmUpdate, setConfirmUpdate] = React.useState<boolean>(false);
  const [messageConfirm, setMessageConfirm] = React.useState<string>("Está seguro que desea realizar estos cambios?");
  const [titleConfirm, setTitleConfirm] = React.useState<string>("Confirmar");
  const [options, setOptions] = React.useState<Array<AccionMenu>>([]);
  const [displayDestino, setDisplayDestino] = React.useState<string>("");
  const [confirmChange, setConfirmChange] = React.useState<boolean>(false);

  const getObjects = (obj: any, key: any, val: any) => {
    const find = findOnObject(obj, key, val);
    if (find.length > 0) {
      const opt: any = [];
      find[0]['acciones'].map((item) => {
        opt.push({ ...item, nombre: item['name'] })
      })
      setOptions(opt)
    }
  }

  useEffect(() => {
    if (menuState.length > 0 && options.length === 0) {
      getObjects(menuState, 'to', pathVerificador)
    }
  }, [menuState]);

  const botones = config['menuDefectoSearch'].map(acc => {
    const boton: BotonMenu = {
      id: acc.text as any,
      icon: menuService.getIcon(acc.icon),
      text: acc.text
    }
    return boton;
  });

  React.useEffect(() => {
    if (props?.show === true) {
      dispatch(setCurrentAccion("Buscar"))
      setAcciones(botones)
    }
  }, [props?.show]);


  const setToast = React.useCallback((texto, type) => {
    let tipo: ToastTypes = ToastTypes.Info;
    if (type === 'warning') {
      tipo = ToastTypes.Warning;
    } else if (type === 'success') {
      tipo = ToastTypes.Success;
    } if (type === 'info') {
      tipo = ToastTypes.Info;
    } if (type === 'danger') {
      tipo = ToastTypes.Danger;
    }
    dispatch(addToast({
      id: '',
      autoHide: 5000,
      title: 'acatha',
      content: texto,
      fade: true,
      type: tipo
    }));
  }, [dispatch]);

  const playLoader = React.useCallback(async () => {
    dispatch(changeLoader({ show: true, mensaje: 'Cargando...' }))
  }, [dispatch]);

  const stopLoader = React.useCallback(async () => {
    dispatch(changeLoader({ show: false, mensaje: '' }))
  }, [dispatch]);

  const capturarAccion = (metodo) => {
    switch (metodo['nombre']) {
      case TIPOS_BOTONES.BOTON_NUEVO:
        dispatch(setCurrentAccion("Nuevo"))
        break;
      case TIPOS_BOTONES.BOTON_BUSCAR:
        setDisplayDestino(TIPOS_BOTONES.BOTON_BUSCAR)
        if (currentActionState === "Buscar") {
          dispatch(setCurrentFunction(metodo['nombre']))
        } else {
          if (currentActionState === "Nuevo" || currentActionState === "Editar") {
            setConfirmChange(true)
            setConfirmUpdate(true)
            setMessageConfirm(lh.getMessage(MessagesKeys.FormConfirmExitForm));
            setShowConfirm(true)
            return false;
          } else {
            dispatch(setCurrentAccion(metodo['nombre']))
          }
        }
        break;
      case TIPOS_BOTONES.BOTON_DESHACER:
        if (currentActionState === "Nuevo") {
          dispatch(setCurrentFunction(TIPOS_BOTONES.BOTON_DESHACER))
          return false;
        } else if (currentActionState === "Editar") {
          dispatch(setCurrentFunction(TIPOS_BOTONES.BOTON_DESHACER))
          return false;
        } else if (currentActionState === "Importar") {
          dispatch(setCurrentFunction(TIPOS_BOTONES.BOTON_DESHACER))
          return false;
        } else {
          desSeleccionarRegistro()
          dispatch(setCurrentAccion("Buscar"))
          dispatch(setCurrentFunction(TIPOS_BOTONES.BOTON_DESHACER))
          dispatch(setDataCuentasBancos([]))
        }
        break;
      case TIPOS_BOTONES.BOTON_GUARDAR:
        if (currentActionState === "Nuevo") {
          dispatch(setCurrentFunction(TIPOS_BOTONES.BOTON_GUARDAR))
          return false;
        } else if (currentActionState === "Editar") {
          dispatch(setCurrentFunction(TIPOS_BOTONES.BOTON_GUARDAR))
          return false;
        } else {
          setToast("No se encuentra cambios que realizar.", "info")
        }
        break;
      case TIPOS_BOTONES.BOTON_EDITAR:
        setDisplayDestino(TIPOS_BOTONES.BOTON_EDITAR)

        if (currentActionState === "Editar") {
          setConfirmChange(true)
          setConfirmUpdate(true)
          setMessageConfirm(lh.getMessage(MessagesKeys.FormConfirmChangeForm));
          setShowConfirm(true)
          return false;
        } else {
          if (seleccionado['index'] === null) {
            setToast("No se encuentra un registro seleccionado, Por favor seleccione un registro para editarlo.", "info")
            return false;
          } else {
            setConfirmUpdate(true)
            setMessageConfirm("Está seguro que desea realizar estos cambios?");
            setShowConfirm(true)
          }
        }
        break;
      case TIPOS_BOTONES.BOTON_ELIMINAR:
        clickDelete()
        break;
      default:
        break;
    }
  }

  const pasarCuentaSeleccion = (cuenta) => {
    if (cuenta['row']['codigo'] !== "") {
      props.cargarCuentas(cuenta['row']);
      props.onClose()
    }
  }

  const pasarCuenta = (cuenta) => {
    if (cuenta['codigo'] !== "") {
      props.cargarCuentas(cuenta);
      props.onClose()
    }
  }

  const renderBodyConfirm = () => {
    return (
      <>{messageConfirm}</>
    )
  }

  const confirmAction = () => {
    return (
      <Modalform
        name='confirmar'
        headerTitle={titleConfirm}
        childrenBody={renderBodyConfirm()}
        childrenFooter={renderFooter()}
        closeOnBackdrop={false}
        show={showConfirm}
        onClose={() => setShowConfirm(!showConfirm)}
        centered={true}
        size="sm"
      />
    )
  }

  const onButtonExportarClick = React.useCallback(async (filtro) => {
    try {
      dispatch(changeLoader({
        mensaje: 'Exportando...',
        show: true
      }))

      const response: any = await ExportarExcelService.exportarArchivo(registros);
      const filename = 'Bancos.xlsx';
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      a.click();

    } catch (error) {
      dispatch(addToast({
        title: 'Exportar Excel',
        content: typeof error == 'string' ? error : JSON.stringify(error),
        type: ToastTypes.Danger,
        autoHide: 3000
      }))
    }
    dispatch(changeLoader({
      mensaje: '',
      show: false
    }))
  }, [registros]);

  const exportarDatos = () => {
    if (registros.length > 0) {
      onButtonExportarClick(registros)
    } else {
      setToast("No se encuentran datos para exportar.", "info")
    }
  }

  const clickDelete = () => {
    setConfirmDelete(true);
    setShowConfirm(true);
    setTitleConfirm("Confirmar");
    setMessageConfirm("Está seguro que desea eliminar este item?");
  }

  const handleDelete = async () => {
    playLoader()
    try {
      const idBanco: number = seleccionado['row']['cba_codigo'];
      const data = await ccahorrosService.deleteCCAhorro(idBanco);
      stopLoader()
      if (data['error'] === false) {
        setToast(data['message'], "success");
        dispatch(setCurrentAccion("Buscar"))
        quitarRegistroTabla(Number(idBanco))
        desSeleccionarRegistro()
      } else {
        setToast(data['message'], "warning");
      }
      return await data;
    } catch (error) {
      console.log('error', error);
      stopLoader()
      setToast(JSON.stringify(error), "danger")
    }
  }

  const quitarRegistroTabla = (idCuenta) => {
    const filtrado = registros.findIndex((item: any) => {
      return Number(item.cba_codigo) === Number(idCuenta);
    });
    if (filtrado > -1) {
      const rowsBefore = registros.slice();
      rowsBefore.splice(filtrado, 1);
      dispatch(setDataCuentasBancos(rowsBefore))
    }
  }

  const desSeleccionarRegistro = () => {
    dispatch(setSeleccionarDato(
      {
        index: null,
        row: null,
        selectedRow: null
      }
    ))
  }

  const bodyForm = () => {
    if (currentActionState === "Buscar") {
      return (
        <>
          <Buscar
            actionDelete={() => clickDelete()}
            exportExcel={() => exportarDatos()}
            origen="modal"
            origenAplicacion={props.origen}
            returnSeleccion={(data) => props.cargarCuentas(data)}
            verificaEdicion={() => { console.log("verificaEdicion") }}
          />

        </>
      )
    } else if (currentActionState === "Nuevo") {
      return (
        <>
          <Nuevo accion={currentActionState}
            origen="modal"
            codModulo={codModulo}
          />
        </>
      )
    } else if (currentActionState === "Editar") {
      return (
        <>
          <Nuevo accion={currentActionState}
            origen="modal"
            codModulo={codModulo}
          />
        </>
      )
    } else {
      return (
        <>
        </>
      )
    }
  }

  const getButtonDisabled = React.useCallback((button: AccionMenu) => {
    switch (button.nombre) {
      case TIPOS_BOTONES.BOTON_BUSCAR:
        return false;
      case TIPOS_BOTONES.BOTON_NUEVO:
        return false;
      case TIPOS_BOTONES.BOTON_ELIMINAR:
        return (currentActionState === "Nuevo" || currentActionState === "Editar");
      case TIPOS_BOTONES.BOTON_EDITAR: {
        return (currentActionState !== "Buscar" || seleccionado.index === null);
      }
      case TIPOS_BOTONES.BOTON_DESHACER:
      case TIPOS_BOTONES.BOTON_GUARDAR: {
        return currentActionState === "Buscar";
      }

      default:
        return true;
    }
  }, [currentActionState, seleccionado]);

  const optionsForm = () => {
    if (options.length > 0) {
      return (
        <GlobalMenu
          acciones={options}
          onClick={capturarAccion}
          getButtonDisabled={getButtonDisabled}
        />
      )
    } else {
      return (<> </>)
    }
  }

  const renderBody = () => {

    return (
      <CardContent
        childrenOptions={optionsForm()}
        childrenBody={bodyForm()}
        headerTitle={currentActionState}
        aplicacion={"CCAhorros"}
      />
    );
  }

  const renderFooter = () => {

    if (currentActionState === "Buscar") {
      return (
        <>
          {confirmDelete === true && (
            <CButton color="primary" onClick={() => {
              setShowConfirm(false)
              setConfirmDelete(false)
              handleDelete()
            }}>
              {"Aceptar"}
            </CButton>
          )}
          {confirmUpdate === true && (
            <CButton color="primary" onClick={() => {
              setConfirmUpdate(false);
              setShowConfirm(false)
              dispatch(setCurrentAccion("Editar"))
            }}>
              {"Aceptar"}
            </CButton>
          )}
          <CButton color="secondary" onClick={() => {
            setConfirmUpdate(false);
            setConfirmDelete(false)
            setShowConfirm(!showConfirm)
          }} >{"Cancelar"}</CButton>
        </>
      )
    } else if (currentActionState === "Nuevo" || currentActionState === "Editar") {
      return (
        <>
          {confirmChange === true && (
            <CButton color="primary" onClick={() => {
              setConfirmUpdate(false);
              setShowConfirm(false)
              setConfirmChange(false)
              setMessageConfirm("")
              dispatch(setCurrentAccion(displayDestino))
            }}>
              {"Aceptar"}
            </CButton>
          )}
          <CButton color="secondary" onClick={() => {
            setConfirmUpdate(false);
            setConfirmDelete(false)
            setShowConfirm(!showConfirm)
          }} >{"Cancelar"}</CButton>
        </>
      )
    }
  }

  return (
    <>
      {showConfirm === true && ReactDom.createPortal(confirmAction(), document.body)}

      <CRow key="rowMain">
        <CCol key="colMain" >
          <Modalform
            name='modalCuentasBancos'
            headerTitle={"Cuentas Bancarias"}
            childrenBody={renderBody()}
            childrenFooter={<></>}
            closeOnBackdrop={false}
            show={props.show}
            onClose={() => props.onClose()}
            centered={true}
            size="lg"
          />
        </CCol>
      </CRow>
    </>
  )
}

export default ModalCuentasBancos