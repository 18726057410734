import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ConfiguracionesDocsCtasXPagar } from "../types/types";
import { Autorizacion } from "../../../../../store/types";



const initialState: ConfiguracionesDocsCtasXPagar = {
  modulo: 0
}

const configuracionesDocsCtasXPagarSlice = createSlice(
  {
    name: 'configuracionesDocsCtasXPagar',
    initialState: initialState,
    reducers: {
      setCodigoModulo(state, acion: PayloadAction<number>) {
        state.modulo = acion.payload;
      },
    }
  }
)


export const configuracionesDocsCtosXPagarReducer = configuracionesDocsCtasXPagarSlice.reducer;
export const { setCodigoModulo } = configuracionesDocsCtasXPagarSlice.actions;