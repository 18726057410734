import React, { useEffect } from 'react';
import ReactDom from 'react-dom';
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow
} from '@coreui/react-pro'
import { useDispatch, useSelector } from 'react-redux'
import Modalform from "../../../views/componentes/modalform";
import { RootState } from '../../../store/store';
import { FormIngresos, TIPOS_BOTONES } from "./store/types"
import { lh, MessagesKeys } from '../../../helpers/localizationHelper';
import { changeLoader, setAlert } from '../../../store/reducers';
import { ingresarChequesService } from "./service/modalIngresarCheques.services";
import { AccionMenu, ToastTypes } from '../../../store/types';
import { addToast } from '../../../store/toasterReducer';
import Buscar from "./componentes/buscar";
import Nuevo from "./componentes/nuevo";
import { setCurrentAction, setCurrentFunction, setRegistros as setRegistrosCheques, setResetSeleccion, setFormasPagoSRI, setFormularioIngresoCheques, setModalIngresoCheques } from "./store/modalIngresoCheques";
import { findOnObject } from '../../../helpers/Helper';
import { GlobalMenu } from '../../../views/componentes/globalMenu/globalMenu';
import { DateUtils, formatoFechasApi } from '../../../helpers/dateUtils';
import { Cheque } from '../../bancos/pages/cheques/store/types';
import LoadPanel from 'devextreme-react/load-panel'
import GlobalAlert from '../../../views/componentes/globalAlert/globalAlert';
import { Button } from 'devextreme-react/button';
import { ModalArchivosDigitales } from '../modalArchivosDigitales';
import { EAplicationsAcatha, EModulosAcatha } from '../../../store/enumsAplication';
import { consoleService } from '../../../services/console.service';

type ModalIngresoChequesProps = {
  show: boolean,
  onClose: () => void,
  parametros: (evt) => void,
  pathVerificador: string,
  ejecutaAccion: () => void,
  onSelect: (data) => void,
  configModal: any
}

const app = 'cheques'
const pathVerificador = '/' + app

const ModalIngresoCheques = (props: ModalIngresoChequesProps) => {

  const { configModal } = props;

  const dispatch = useDispatch()

  const formularioIngresoCheques = useSelector((state: RootState) => state.bancos.modalIngresoCheques.formularioIngresoCheques)
  const menuState = useSelector((state: RootState) => state.global.menu)
  const loaderGlobal = useSelector((state: RootState) => state.global.loader)
  const alertGlobal = useSelector((state: RootState) => state.global.alert)
  const antFormaPago = useSelector((state: RootState) => state.clientes.docCuentasXCobrar.anticipos.filter.formasPago)
  const abonarFormaPago = useSelector((state: RootState) => state.clientes.docCuentasXCobrar.abonos.formasPago)
  const abonarFormaPagoPro = useSelector((state: RootState) => state.proveedores.documentosCuentasPorPagar.pay.formasPago)

  const currentAction = useSelector((state: RootState) => state.bancos.modalIngresoCheques.currentAction)
  const currentFunction = useSelector((state: RootState) => state.bancos.modalIngresoCheques.currentFunction)
  const seleccionado = useSelector((state: RootState) => state.bancos.modalIngresoCheques.seleccionado)
  const formasPagoSRIState = useSelector((state: RootState) => state.bancos.modalIngresoCheques.formasPagoSRI)
  const registros = useSelector((state: RootState) => state.bancos.modalIngresoCheques.registros)

  const [titleConfirm, setTitleConfirm] = React.useState<string>('Confirmar')
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [showModalDA, setShowModalDA] = React.useState(false);
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const [confirmUpdate, setConfirmUpdate] = React.useState(false);
  const [messageConfirm, setMessageConfirm] = React.useState("Está seguro que desea realizar estos cambios?");
  const [displayDestino, setDisplayDestino] = React.useState<string>("");
  const [confirmChange, setConfirmChange] = React.useState<boolean>(false);
  const [acciones, setAcciones] = React.useState<any>([]);
  const [codModulo, setCodModulo] = React.useState<number>(0);
  const [fpagosriEnable_cb, setFpagosriEnable_cb] = React.useState<boolean>(false);
  const [total, setTotal] = React.useState<number>(0);
  const [guardado, setGuardado] = React.useState<boolean>(false);
  const [totalIncome, setTotalIncome] = React.useState<number>(0)

  useEffect(() => {
    if (menuState.length > 0 && acciones.length === 0) {
      getObjects(menuState, 'to', pathVerificador)
    }
  }, [menuState])

  const getObjects = (obj: any, key: any, val: any) => {
    const find = findOnObject(obj, key, val)
    if (find.length > 0) {
      const opt: any = []
      find[0]['acciones'].map((item) => {
        if (item['name'] !== 'Deshacer' && item['name'] !== 'Refrescar' && item['name'] !== 'Exportar') {
          opt.push({ ...item, nombre: item['name'] })
        }
      })
      setAcciones(opt)
    }
  }

  const setToast = React.useCallback((texto, type) => {
    let tipo: ToastTypes = ToastTypes.Info
    if (type === 'warning') {
      tipo = ToastTypes.Warning
    } else if (type === 'success') {
      tipo = ToastTypes.Success
    }
    if (type === 'info') {
      tipo = ToastTypes.Info
    }
    if (type === 'danger') {
      tipo = ToastTypes.Danger
    }
    dispatch(
      addToast({
        id: '',
        autoHide: 3500,
        title: 'Acatha',
        content: texto,
        fade: true,
        type: tipo,
      }),
    )
  }, [dispatch])

  React.useEffect(() => {
    setCodModulo(configModal.codModulo ?? 0)
    dispatch(setCurrentAction('Buscar'))
    iniciar()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configModal.codModulo])

  React.useEffect(() => {
    if (currentFunction === 'Buscar') {
      dispatch(setCurrentFunction(''))
      iniciar()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFunction])

  const iniciar = async () => {
    const bus: any = []
    bus['FPA_CODIGO'] = 7
    bus['fecha'] = DateUtils.dateToString(new Date(), formatoFechasApi)
    let CODIGO: number = 0
    let transaccion: string = ''
    let resTotalTran: FormIngresos = {
      valor_ti: 0,
      valorsinretencion: 0,
      total_ti: 0,
      totales: 0,
      total: 0,
    }

    if (Number(configModal.codModulo) === 111) {
      setFpagosriEnable_cb(false)
      transaccion = configModal.parametros.tipoDocumento
      resTotalTran = await devuelveTotalTran(
        configModal.parametros.asientoNumero,
      )
      console.log('resTotalTran', resTotalTran)
      CODIGO = Number(configModal.parametros.ingresos)
      dispatch(
        setFormularioIngresoCheques({
          ...resTotalTran,
          valor_ti: resTotalTran.valor_ti,
          valorsinretencion: resTotalTran.valorsinretencion,
        }),
      )
    } else if (configModal.codModulo === parseInt(EAplicationsAcatha.RolesPago)) {
      transaccion = configModal?.parametros?.tipoDocumento
      CODIGO = configModal?.parametros?.incomeCode
      const data = await ingresarChequesService.getTranRolPay(CODIGO)
      let totalForm = 0
      if (data?.auto && data?.error === false) {
        totalForm = Number(data?.auto?.toFixed(2))
        resTotalTran.valorsinretencion = Number(data['auto'].toFixed(2))
      }
      consoleService.log('resTotalTran', resTotalTran)
      dispatch(
        setFormularioIngresoCheques({
          ...resTotalTran,
          valor_ti: resTotalTran?.valor_ti ?? 0,
          valorsinretencion: resTotalTran?.valorsinretencion ?? 0,
        }),
      )
      dispatch(
        setModalIngresoCheques({
          name: "Roles",
          open: false,
          codModulo: configModal?.codModulo ?? 0,
          parametros: {
            fechaRegistro: configModal?.parametros?.fecha,
            fechaCobro: configModal?.parametros?.fecha,
            tipoDocumento: transaccion,
            ingresos: CODIGO,
            valor_ti: totalForm
          }
        }),
      )
    } else if (configModal.codModulo === parseInt(EModulosAcatha.Proveedores)) {
      //dtosXPagar/Anticipos
      //dtosXPagar/Abonar
      CODIGO = configModal.parametros.incomeCode
      bus['FPA_CODIGO'] = configModal.tipoFP
      //imprimir_btn.enabled = false;
      bus['fecha'] = configModal.parametros.incomeDate
      console.log(bus)
      if (configModal.tab === "ABONAR") {
        transaccion = 'ABONOS COMPRAS'
      } else {
        transaccion = 'ANTICIPO PROVEEDOR'
      }
      resTotalTran = await devuelveTotal(CODIGO, bus['FPA_CODIGO'], configModal.tab, "PROVEEDOR")

      console.log('resTotalTran', resTotalTran)
      dispatch(
        setFormularioIngresoCheques({
          ...resTotalTran,
          valor_ti: resTotalTran?.valor_ti ?? 0,
          valorsinretencion: resTotalTran?.valorsinretencion ?? 0,
        }),
      )

      dispatch(
        setModalIngresoCheques({
          name: "Proveedores",
          open: false,
          codModulo: configModal?.codModulo ?? 0,
          parametros: {
            fechaRegistro: bus['fecha'],
            fechaCobro: bus['fecha'],
            tipoDocumento: transaccion,
            ingresos: CODIGO
          }
        }),
      )

      try {
        const totalIncome = await ingresarChequesService.getTotalIncome(CODIGO)
        if (totalIncome['error'] === false) {
          setTotalIncome(totalIncome.auto)
          setToast(totalIncome['message'], 'success')
        } else {
          setToast(totalIncome['message'], 'warning')
        }
      } catch (error) {
        setToast(JSON.stringify(error), 'danger')
      }
    } else if (configModal.codModulo === parseInt(EModulosAcatha.Clientes)) {
      //dtosXCobrar/Anticipos
      //dtosXCobrar/Abonar
      CODIGO = configModal.parametros.incomeCode
      bus['FPA_CODIGO'] = configModal.tipoFP
      //imprimir_btn.enabled = false;
      bus['fecha'] = configModal.parametros.incomeDate
      console.log(bus)
      if (configModal.tab === "ABONAR") {
        transaccion = 'ABONOS VENTAS'
      } else {
        transaccion = 'ANTICIPO CLIENTE'
      }
      resTotalTran = await devuelveTotal(CODIGO, bus['FPA_CODIGO'], configModal.tab)

      console.log('resTotalTran', resTotalTran)
      dispatch(
        setFormularioIngresoCheques({
          ...resTotalTran,
          valor_ti: resTotalTran?.valor_ti ?? 0,
          valorsinretencion: resTotalTran?.valorsinretencion ?? 0,
        }),
      )
      dispatch(
        setModalIngresoCheques({
          name: "Clientes",
          open: false,
          codModulo: configModal?.codModulo ?? 0,
          parametros: {
            fechaRegistro: bus['fecha'],
            fechaCobro: bus['fecha'],
            tipoDocumento: transaccion,
            ingresos: CODIGO
          }
        }),
      )

      try {
        const totalIncome = await ingresarChequesService.getTotalIncome(CODIGO)
        if (totalIncome['error'] === false) {
          setTotalIncome(totalIncome.auto)
          setToast(totalIncome['message'], 'success')
        } else {
          setToast(totalIncome['message'], 'warning')
        }
      } catch (error) {
        setToast(JSON.stringify(error), 'danger')
      }
    }

    if (formasPagoSRIState.length === 0) {
      const resCombos = await CargarCombosDisponibles(bus, 'Sin Asignar')
      console.log('resCombos', resCombos)
    }

    const resTransac = await CargarTodosTran(transaccion, CODIGO, resTotalTran)
    console.log('resTransac', resTransac)
  }

  const devuelveTotalTran = async (asi_numero: number) => {
    playLoader()
    const find = {
      numeroAsiento: asi_numero,
    }
    const resTotalTran: FormIngresos = {
      valor_ti: 0,
      valorsinretencion: 0,
      total_ti: 0,
      totales: 0,
      total: 0,
    }
    try {
      const data = await ingresarChequesService.devuelveTotalTran(find)
      stopLoader()
      if (data['error'] === false) {
        resTotalTran.valor_ti = Number(data['auto'].toFixed(2))
        resTotalTran.valorsinretencion = Number(data['auto'].toFixed(2))
        return resTotalTran
      } else {
        setToast(data['message'], 'warning')
      }
      return await data
    } catch (error) {
      stopLoader()
      console.error(error)
      setToast(JSON.stringify(error), 'danger')
    }
  }

  const devuelveTotal = async (ingreso: number, tipo: number, modulo: string, tab: string = "") => {
    playLoader()
    const find = {
      codigo: ingreso,
      subtipo: tipo,
    }
    const resTotalTran: FormIngresos = {
      valor_ti: 0,
      valorsinretencion: 0,
      total_ti: 0,
      totales: 0,
      total: 0,
    }
    try {
      const data = await ingresarChequesService.devuelveTotal(find, modulo, tab)
      console.log(data)
      stopLoader()
      if (data !== null && data !== undefined && data['auto'].length > 0 && data['error'] === false) {
        resTotalTran.valor_ti = Number(data['auto'].toFixed(2))
        resTotalTran.valorsinretencion = Number(data['auto'].toFixed(2))
        return resTotalTran
      } else {
        setToast(data['message'], 'warning')
      }
      return await data
    } catch (error) {
      stopLoader()
      console.error(error)
      setToast(JSON.stringify(error), 'danger')
    }
  }

  const CargarCombosDisponibles = async (bus, message: string) => {
    const find = {
      codigo: bus['FPA_CODIGO'],
      fecha: bus['fecha'],
      mensaje: message,
    }
    try {
      const data = await ingresarChequesService.CargarCombosDisponibles(find)
      if (data['error'] === false) {
        if (data['auto'] && data['auto'].length > 0) {
          const dataFormas: any = data['auto']
          const formasPagoSRI: any = []
          dataFormas.map(function (key, i) {
            return formasPagoSRI.push({
              value: Number(key['codigo']),
              label: key['descripcion'],
            })
          })
          dispatch(setFormasPagoSRI(formasPagoSRI))
        }
      }
      return await data
    } catch (error) {
      console.error(error)
      setToast(JSON.stringify(error), 'danger')
    }
  }

  const CargarTodosTran = async (
    transaccion: string,
    codigo: number,
    resTotalTran: FormIngresos,
  ) => {
    playLoader()
    dispatch(setRegistrosCheques([]))
    const find = {
      transaccion: transaccion,
      codigo: codigo,
    }
    try {
      const data = await ingresarChequesService.CargarTodosTran(find)

      if (data['error'] === false) {
        stopLoader()
        setToast(data['message'], 'success')
        if (data['auto'] && data['auto'].length > 0) {
          let cheques: Array<Cheque>
          // eslint-disable-next-line prefer-const
          cheques = data['auto'].map(function (itemCheque: Cheque) {
            return {
              codigo: Number(itemCheque['codigoCheque']), //  CHE_CODIGO;
              numero: Number(itemCheque['numeroCheque'] ?? 0), //  CHE_NUMERO;
              registro: String(itemCheque['fechaRegistro'] ?? ''), //  CHE_FREGISTRO;
              fechaCobro: String(itemCheque['fechaCobro'] ?? ''), // CHE_FCOBRO;
              Transaccion: String(itemCheque['transaccionCheque'] ?? ''), //  CHE_TRANSACCION;
              aFavor: String(itemCheque['aFavorCheque'] ?? ''), //  CHE_AFAVOR;
              formaPago: String(itemCheque['fPago'] ?? ''), // CHE_FPAGO;
              valor: Number(itemCheque['valorCheque'] ?? 0), //  CHE_VALOR;
              estado: Number(itemCheque['estadoCheque'] ?? 0), //  CHE_ESTADO;
              estadoDescripcion: String(itemCheque['descripcionEstado'] ?? ''), //  CHE_ESTADO_DES;
              descripcion: String(itemCheque['descripcionCheque'] ?? ''), //  CHE_DESCRIPCION;
              comprobante: String(itemCheque['comprobante'] ?? ''), //  COMPROBANTE;
              pagos: Number(itemCheque['pagosCheque'] ?? 0), //  CHE_PAGOS;
              cuentaBancoCodigo: Number(itemCheque['codigoCuentaBanco'] ?? 0), //  CBA_CODIGO;
              cuentaBancoNumero: String(itemCheque['numeroCuentaBanco'] ?? ''), // CBA_NUMERO;
              nombreBanco: String(itemCheque['nombreBanco'] ?? ''), //  BAN_NOMBRE;
              cuentaBancoPerteneceDes: String(
                itemCheque['descripcionPertenece'] ?? 0,
              ), //  CBA_PERTENECE_DES;
              formaPagValor: String(itemCheque['valorFPA'] ?? ''), // FPA_VALOR;
              fps_codigo: Number(itemCheque['codigoFPS'] ?? 0), //  FPS_CODIGO;
            }
          })
          console.log('cheques', cheques)
          dispatch(setRegistrosCheques(cheques))
          resultadoBusquedaCheques(cheques, resTotalTran)
        }
      } else {
        stopLoader()
        setToast(data['message'], 'warning')
      }
      return await data
    } catch (error) {
      stopLoader()
      console.error(error)
      setToast(JSON.stringify(error), 'danger')
    }
  }

  const resultadoBusquedaCheques = (
    dataRegistros,
    resTotalTran: FormIngresos,
  ) => {
    let totales: number = 0

    for (let i: number = 0; i < dataRegistros.length; i++) {
      totales = Number(totales) + Number(dataRegistros[i].valor)
    }

    const temporal: any = []

    if (configModal.codModulo === 106 || configModal.codModulo === 111 || configModal.codModulo === parseInt(EAplicationsAcatha.RolesPago)) {
      temporal.push({ SUB_CODIGO: 7 })
    }

    if (configModal.codModulo === parseInt(EModulosAcatha.Clientes) && antFormaPago !== null) {
      if (configModal.tab === "ABONAR" && abonarFormaPago !== null && abonarFormaPago !== undefined) {
        temporal.push({ SUB_CODIGO: abonarFormaPago.codigo })
      } else {
        temporal.push({ SUB_CODIGO: antFormaPago.codigo })
      }
    }

    if (configModal.codModulo === parseInt(EModulosAcatha.Proveedores) && antFormaPago !== null) {
      if (configModal.tab === "ABONAR" && abonarFormaPago !== null && abonarFormaPago !== undefined) {
        temporal.push({ SUB_CODIGO: abonarFormaPagoPro.codigo })
      }
      // else {
      //   temporal.push({ SUB_CODIGO: antFormaPago.codigo })
      // }
    }

    for (let i: number = 0; i < temporal.length; i++) {
      if (temporal[i].SUB_CODIGO == 7) {
        setTotal(Number(totales))
        dispatch(
          setFormularioIngresoCheques({
            ...resTotalTran,
            total_ti: totales,
            totales: totales,
            total: totales,
          }),
        )
        const total_ = Number(totales.toFixed(2))
        if (Number(total_) == resTotalTran.valorsinretencion) {
          setGuardado(true)
        } else {
          alertShow(
            'Los valores registrados no concuerdan con el valor del comprobante corríjalo',
            'ácatha',
          )
          setGuardado(false)
        }
      }
    }
  }

  const alertShow = (message: string, title: string) => {
    setTitleConfirm(title)
    setMessageConfirm(message)
    setShowConfirm(true)
  }

  const anularTransaccion = React.useCallback(async (seleccionadoCheque) => {
    const objeto = {
      chequeCodigo: seleccionadoCheque?.codigo ?? 0,
      cuentaBancoCodigo: seleccionadoCheque.cuentaBancoCodigo ?? 0,
      chequeValor: seleccionadoCheque.valor ?? 0,
    }

    try {
      const data = await ingresarChequesService.inactivarTransaccion(objeto)
      if (data !== null && data['error'] === false) {
        setToast('El registro fue dado de baja con éxito.', 'success')
      } else {
        if (data !== null && data['error'] === true) {
          setToast('No pudimos guardar la información, inténtelo más tarde.', 'danger')
        } else {
          console.log(data)
          setToast('Error: en la data', 'danger')
        }
      }
    } catch (error) {
      console.log(error)
      setToast('Error: Se produjo un error', 'danger')
    }
  }, [setToast])

  const getButtonDisabled = React.useCallback((button: AccionMenu) => {
    switch (button.nombre) {
      case TIPOS_BOTONES.BOTON_DESHACER:
      case TIPOS_BOTONES.BOTON_REFRESCAR:
      case TIPOS_BOTONES.BOTON_EXPORTAR:
        return configModal.tipoFP !== undefined || configModal.tipoFP !== null
        break
      case TIPOS_BOTONES.BOTON_VERASIENTO:
        return configModal.tipoFP === 9
        break
      default:
        return false
    }
  }, [configModal.tipoFP])

  const returnAction = (metodo) => {
    switch (metodo.name) {
      case TIPOS_BOTONES.BOTON_BUSCAR:
        setDisplayDestino(TIPOS_BOTONES.BOTON_BUSCAR)
        if (currentAction === 'Buscar') {
          dispatch(setCurrentFunction(TIPOS_BOTONES.BOTON_BUSCAR))
          return false
        } else {
          if (currentAction === 'Nuevo' || currentAction === 'Editar') {
            setConfirmChange(true)
            setConfirmUpdate(true)
            setMessageConfirm(lh.getMessage(MessagesKeys.FormConfirmExitForm))
            setShowConfirm(true)
            return false
          } else {
            dispatch(setCurrentAction(TIPOS_BOTONES.BOTON_BUSCAR))
          }
        }
        break
      case TIPOS_BOTONES.BOTON_NUEVO:
        setDisplayDestino(TIPOS_BOTONES.BOTON_NUEVO)
        if (currentAction === 'Editar') {
          setConfirmChange(true)
          setConfirmUpdate(true)
          setMessageConfirm(lh.getMessage(MessagesKeys.FormConfirmExitForm))
          setShowConfirm(true)
          return false
        } else {
          dispatch(setCurrentAction(TIPOS_BOTONES.BOTON_NUEVO))
          dispatch(setCurrentFunction(TIPOS_BOTONES.BOTON_NUEVO))
        }
        break
      case TIPOS_BOTONES.BOTON_GUARDAR:
        if (currentAction === 'Nuevo') {
          dispatch(setCurrentFunction(TIPOS_BOTONES.BOTON_GUARDAR))
          return false
        } else if (currentAction === 'Editar') {
          dispatch(setCurrentFunction(TIPOS_BOTONES.BOTON_GUARDAR))
          return false
        } else {
          setToast('No se encuentra cambios que realizar.', 'info')
        }
        break
      case TIPOS_BOTONES.BOTON_ELIMINAR:
        if (currentAction === 'Buscar') {
          if (seleccionado['index'] === null) {
            setToast(lh.getMessage(MessagesKeys.NotFoundRegisterSelec), 'info')
            return false
          } else {
            clickDelete()
          }
        } else {
          setToast(lh.getMessage(MessagesKeys.NotFoundRegisterSelec), 'info')
        }
        break
      case TIPOS_BOTONES.BOTON_DESHACER:
        dispatch(setCurrentFunction(TIPOS_BOTONES.BOTON_DESHACER))
        break
      case TIPOS_BOTONES.BOTON_ANULAR:
        if (seleccionado['row'] !== null && seleccionado['row'] !== undefined) {
          anularTransaccion(seleccionado['row'])
        } else {
          setToast('Seleccione un item para anularlo', ToastTypes.Warning)
        }
        break
      case TIPOS_BOTONES.BOTON_VERASIENTO:
        if (seleccionado['row'] !== null && seleccionado['row'] !== undefined && seleccionado['row']?.codigo) {
          setShowModalDA(true)
        } else {
          setToast('Seleccione un item para ver el asiento', ToastTypes.Warning)
        }
        break
      case TIPOS_BOTONES.BOTON_EDITAR:
        setDisplayDestino(TIPOS_BOTONES.BOTON_EDITAR)
        if (seleccionado['index'] === null) {
          setToast(lh.getMessage(MessagesKeys.NotFoundRegisterSelec), 'info')
          return false
        } else {
          setTitleConfirm('Confirmar')
          setConfirmUpdate(true)
          const sms = lh.getMessage(MessagesKeys.FormConfirmChangeForm)
          const sms_replace = sms.replace(
            '@dinamic_var@',
            'Número : ' + seleccionado['row']['numero'] ?? 's/n',
          )
          setMessageConfirm(sms_replace)
          setShowConfirm(true)
        }
        break
      default:
        break
    }
  }

  const clickDelete = () => {
    setConfirmDelete(true)
    setShowConfirm(true)
    setTitleConfirm('Confirmar')
    setMessageConfirm('Está seguro que desea eliminar este item?')
  }

  const handleDelete = async () => {
    playLoader()
    try {
      const obj = {
        chequeCodigo: seleccionado['row']['codigo'],
        cuentaBancoCodigo: seleccionado['row']['cuentaBancoCodigo'],
        chequeValor: seleccionado['row']['valor'],
        transaccion: configModal.parametros.tipoDocumento,
      }
      const data = await ingresarChequesService.EliminarTran(obj)
      stopLoader()
      if (data['error'] === false) {
        setToast(data['message'], 'success')
        dispatch(setResetSeleccion(null))
        dispatch(setCurrentAction('Buscar'))
        quitarRegistroTabla(Number(seleccionado['row']['codigo']))
        iniciar()
      } else {
        setToast(data['message'], 'warning')
      }
      return await data
    } catch (error) {
      console.log('error', error)
      stopLoader()
      setToast(JSON.stringify(error), 'danger')
    }
  }

  const quitarRegistroTabla = (idIngreso) => {
    const filtrado = registros.findIndex((item: any) => {
      return Number(item.codigo) === Number(idIngreso)
    })
    if (filtrado > -1) {
      const rowsBefore = registros.slice()
      rowsBefore.splice(filtrado, 1)
      dispatch(setRegistrosCheques(rowsBefore))
    }
  }

  const playLoader = () => {
    dispatch(changeLoader({ show: true, mensaje: 'Cargando...' }))
  }

  const stopLoader = () => {
    dispatch(changeLoader({ show: false, mensaje: '' }))
  }



  const bodyModalForm = () => {
    if (currentAction === 'Buscar') {
      return (
        <>
          <Buscar
            onSelect={(data) => console.log(data)}
            codModulo={codModulo}
          />
        </>
      )
    } else if (currentAction === 'Nuevo') {
      return (
        <>
          <Nuevo codModulo={codModulo} totalValue={totalIncome} />
        </>
      )
    } else if (currentAction === 'Editar') {
      return (
        <>
          <Nuevo codModulo={codModulo} totalValue={0} />
        </>
      )
    }
  }

  const optionsForm = () => {

    return (
      <>
        <GlobalMenu
          acciones={acciones}
          onClick={returnAction}
          getButtonDisabled={getButtonDisabled}
        />
      </>
    )

  }
  const renderBody = () => {
    return (
      <>

        <CCard key="cardModal" >
          <CCardHeader >
            <CRow>
              <CCol className="ms-0 d-flex align-items-start justify-content-start" >
                {optionsForm()}
              </CCol>
            </CRow>
          </CCardHeader >

          <CCardBody >
            {alertGlobal.show && ReactDom.createPortal(alertComponent(), document.body)}
            {bodyModalForm()}
          </CCardBody >

        </CCard>
      </>
    )
  }

  const changeNav = (e) => {
    dispatch(setCurrentAction(displayDestino))
  }

  const changeUpdate = (e) => {
    dispatch(setCurrentAction(displayDestino))
    dispatch(setCurrentFunction(displayDestino))
  }

  const renderFooter = () => {
    if (currentAction === 'Buscar') {
      return (
        <>
          {confirmDelete === true && (
            <CButton
              color='primary'
              onClick={() => {
                setShowConfirm(false)
                setConfirmDelete(false)
                handleDelete()
              }}
            >
              {'Aceptar'}
            </CButton>
          )}
          {confirmUpdate === true && (
            <CButton
              color='primary'
              onClick={(e) => {
                setConfirmUpdate(false)
                setShowConfirm(false)
                changeUpdate(e)
              }}
            >
              {'Aceptar'}
            </CButton>
          )}
          <CButton
            color='secondary'
            onClick={() => {
              setConfirmUpdate(false)
              setConfirmDelete(false)
              setShowConfirm(!showConfirm)
            }}
          >
            {'Cancelar'}
          </CButton>
        </>
      )
    } else if (currentAction === "Nuevo" || currentAction === "Editar") {
      return (
        <>
          {confirmChange === true && (
            <CButton color="primary" onClick={(e) => {
              setConfirmUpdate(false);
              setShowConfirm(false)
              setConfirmChange(false)
              setMessageConfirm("")
              changeNav(e)
            }}>
              {"Aceptar"}
            </CButton>
          )}
          <CButton color="secondary" onClick={() => {
            setConfirmUpdate(false);
            setConfirmDelete(false)
            setShowConfirm(!showConfirm)
          }} >{"Cancelar"}</CButton>
        </>
      )
    }
  }

  const renderBodyConfirm = () => {
    return (<> {messageConfirm} </>)
  }

  const confirmAction = () => {
    return (
      <Modalform
        name='confirmarCambios'
        headerTitle={'Confirmar'}
        childrenBody={renderBodyConfirm()}
        childrenFooter={renderFooter()}
        closeOnBackdrop={false}
        show={showConfirm}
        onClose={() => setShowConfirm(!showConfirm)}
        centered={true}
        size="sm"
        key={'confirmarCambios'}
      />
    );
  };

  const loaderComponent = () => {
    return (
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={'center'}
        visible={loaderGlobal.show}
        showIndicator={true}
        shading={true}
        showPane={true}
        message='Buscando...'
        closeOnOutsideClick={false}
      />
    )
  }

  const renderFooterAlert = () => {
    return (
      <>
        <Button
          type="default"
          text="Aceptar"
          onClick={() => {
            dispatch(setAlert({
              alertTitle: "ácatha",
              show: false,
              mensaje: "",
              detalles: []
            }))
          }}
          elementAttr={{
            autofocus: true,
            autoFocus: true
          }}
        />
      </>
    )
  }

  const alertComponent = () => {
    return (<GlobalAlert buttons={renderFooterAlert()} />)
  }

  const openModalDigitalizarArchivos = React.useCallback(() => {
    return (
      <ModalArchivosDigitales
        onChanged={(data) => { }}
        onCancel={() => setShowModalDA(false)}
        show={showModalDA}
        modulo={configModal.tab}
        codModulo={configModal.codModulo}
        chequeNumero={seleccionado.row.codigo ?? 0}
      />
    )
  }, [configModal.tab, showModalDA, configModal.codModulo, seleccionado.row])

  return (
    <>
      {showConfirm === true && ReactDom.createPortal(confirmAction(), document.body)}
      {loaderGlobal.show === true && ReactDom.createPortal(loaderComponent(), document.body)}
      {showModalDA && ReactDom.createPortal(openModalDigitalizarArchivos(), document.body)}

      <CRow key="rowMain">
        <CCol key="colMain" >
          <Modalform
            name='modalIngresoCheques'
            key='modalIngresoCheques'
            headerTitle={"Cheques, NC, ND"}
            childrenBody={renderBody()}
            childrenFooter={<></>}
            closeOnBackdrop={false}
            show={configModal.open}
            onClose={() => {
              dispatch(setResetSeleccion(""))
              props.onClose()
            }}
            size="lg"
          />
        </CCol>
      </CRow>
    </>
  )
}

export default ModalIngresoCheques
