import { TCategory } from '../../../../containers/component/sharedProviders/plans/store/type'
import { CuotasPendientes } from '../../../../store/global/payTypes'
import { LoaderInfo, Plan } from '../../../../store/types'
import { ItemCart, UpgrateState } from '../type/type'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const initialState: UpgrateState = {
  loader: {
    show: false,
    mensaje: '',
  },
  planes: [],
  planSeleccionado: null,
  codPlanVigente: 0,
  valorPagar: 0,
  planVigente: null,
  display: 1,
  lastDisplay: 0,
  showCuentasBancarias: false,
  openPopupTerminos: false,
  collapseCaracteristicas: true,
  collapseUpdatePlan: true,
  collapseContrato: false,
  collapseVencidas: false,
  personalizadoPlan: [],
  cuotasVencidas: [],
  collapsePersonalizado: false,
  ivaPorcentaje: 0,
  categoriasPlanes: [],
}

const suscripcionUpgrateSlice = createSlice({
  name: 'suscripcionUpgrate',
  initialState: initialState,
  reducers: {
    setLoaderPopup(state, action: PayloadAction<LoaderInfo>) {
      state.loader = action.payload
    },
    setDpPlanes(state, action: PayloadAction<Array<Plan>>) {
      state.planes = action.payload
    },
    setCategoriasPlanes(state, action: PayloadAction<Array<TCategory> | []>) {
      state.categoriasPlanes = action.payload
    },
    setPlanSelec(state, action: PayloadAction<Plan | null>) {
      state.planSeleccionado = action.payload
    },
    setCodPlanVigente(state, action: PayloadAction<number>) {
      state.codPlanVigente = action.payload
    },
    setValorPagar(state, action: PayloadAction<number | string>) {
      state.valorPagar = action.payload
    },
    setPlanVigente(state, action: PayloadAction<Plan | null>) {
      state.planVigente = action.payload
    },
    setDisplay(state, action: PayloadAction<number>) {
      state.display = action.payload
    },
    setLastDisplay(state, action: PayloadAction<number>) {
      state.lastDisplay = action.payload
    },
    setShowCuentasBancarias(state, action: PayloadAction<boolean>) {
      state.showCuentasBancarias = action.payload
    },
    setOpenPopupTerminos(state, action: PayloadAction<boolean>) {
      state.openPopupTerminos = action.payload
    },
    setCollapseCaracteristicas(state, action: PayloadAction<boolean>) {
      state.collapseCaracteristicas = action.payload
    },
    setCollapseContrato(state, action: PayloadAction<boolean>) {
      state.collapseContrato = action.payload
    },
    setCollapseUpdatePlan(state, action: PayloadAction<boolean>) {
      state.collapseUpdatePlan = action.payload
    },
    setCollapseVencidas(state, action: PayloadAction<boolean>) {
      state.collapseVencidas = action.payload
    },
    setPersonalizado(state, action: PayloadAction<Array<ItemCart> | []>) {
      state.personalizadoPlan = action.payload
    },
    setCollapsePersonalizado(state, action: PayloadAction<boolean>) {
      state.collapsePersonalizado = action.payload
    },
    setIvaPorcentaje(state, action: PayloadAction<number>) {
      state.ivaPorcentaje = action.payload
    },
    setCuotasVencidas(
      state,
      action: PayloadAction<Array<CuotasPendientes> | []>,
    ) {
      state.cuotasVencidas = action.payload
    },
  },
})

export const {
  setCuotasVencidas,
  setIvaPorcentaje,
  setCollapsePersonalizado,
  setPersonalizado,
  setCollapseUpdatePlan,
  setCollapseVencidas,
  setCollapseContrato,
  setCollapseCaracteristicas,
  setLastDisplay,
  setOpenPopupTerminos,
  setDisplay,
  setLoaderPopup,
  setDpPlanes,
  setPlanSelec,
  setCodPlanVigente,
  setValorPagar,
  setPlanVigente,
  setShowCuentasBancarias,
  setCategoriasPlanes,
} = suscripcionUpgrateSlice.actions
export default suscripcionUpgrateSlice.reducer
export const suscripcionUpgrateReducer = suscripcionUpgrateSlice.reducer
