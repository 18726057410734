import React from 'react';
import SelectBox from 'devextreme-react/select-box';
import { TEtapaProduccionOption } from './store/type';
import { v4 as uuidv4 } from 'uuid'
import { KeyEstadoProdLook } from './store/enumKeys';
import { etapasProduccionService } from './service/etapaProduccion.service';

interface EtapasProduccionLookUpProps extends React.PropsWithChildren {
  selected: TEtapaProduccionOption | null | { codigo: number }
  onChanged: (newValue: TEtapaProduccionOption | null) => void
  onChangedOptions?: (newValue: Array<TEtapaProduccionOption> | []) => void
  disabled?: boolean
  provider?: Array<TEtapaProduccionOption> | []
  id?: string
}

const EtapasProduccionLookUp: React.FC<EtapasProduccionLookUpProps> = (props) => {
  const { id, provider, selected, onChanged, disabled, onChangedOptions } = props
  const selectRef = React.useRef<any>()
  const [options, setOptions] = React.useState<Array<TEtapaProduccionOption> | []>([])

  const loadDatos = React.useCallback(async () => {
    if (provider !== undefined && provider.length > 0) {
      setOptions(provider)
      let marcarOption: TEtapaProduccionOption = provider[0]
      const selectedIndex = provider.findIndex((select: TEtapaProduccionOption) => {
        return select.codigo === selected?.codigo
      });
      if (selectedIndex > -1) {
        marcarOption = provider[selectedIndex] ?? provider[0];
      }
      onChanged(marcarOption)
    } else {

      const data = await etapasProduccionService.getEtapas('Elija una opción')
      if (data.error === false) {
        let opt = []
        if (data.auto.length > 0) {
          opt = data.auto ?? []
        }
        setOptions(opt)
        if (onChangedOptions) {
          onChangedOptions(opt)
        }
        const selectedIndex = opt.findIndex((option) => {
          return option.codigo === selected?.codigo
        })
        if (selectedIndex > -1) {
          onChanged(opt[selectedIndex] ?? opt[0])
        }
      }
    }

  }, [onChanged, onChangedOptions, selected, provider])

  const getItemDisplayExpr = React.useCallback((item: TEtapaProduccionOption) => {
    return item ? `${item[KeyEstadoProdLook.desc]}` : ''
  }, [])

  const onLookUpValueChanged = React.useCallback((evt: any) => {
    if (evt.event !== undefined) {
      onChanged(evt.value)
    }
  }, [onChanged])

  const onSelectBoxFocusIn = React.useCallback((e) => {
    e.element.querySelector('input.dx-texteditor-input').select()
  }, [])

  const refreshSelected = React.useCallback(() => {
    if (options && options.length > 0) {
      const selectedIndex = options.findIndex((option: TEtapaProduccionOption) => {
        return option.codigo === selected?.codigo
      })
      onChanged(options[selectedIndex] ?? options[0])
    }
    selectRef.current.instance.repaint();
  }, [onChanged, options, selected])

  React.useEffect(() => {
    loadDatos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    refreshSelected()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  return (
    <SelectBox
      id="etapaProduccionSelect"
      key={id ?? uuidv4()}
      ref={selectRef}
      items={options ?? []}
      searchEnabled
      displayExpr={getItemDisplayExpr}
      searchExpr={[KeyEstadoProdLook.desc]}
      searchTimeout={500}
      onEnterKey={(e) => { console.log(e) }}
      value={selected}
      onValueChanged={onLookUpValueChanged}
      disabled={disabled}
      onFocusIn={onSelectBoxFocusIn}
    >
      {props.children}
    </SelectBox>
  )
}
export default React.memo(EtapasProduccionLookUp);
