import React from 'react';
import SelectBox from 'devextreme-react/select-box';
import { tittlesService } from './service/tittles.service';
import { TitleOption } from './store/type';
import { v4 as uuidv4 } from 'uuid'
import { utilidades } from '../../../helpers/utilidades';

interface LookUpPropsClasificacionAdicional extends React.PropsWithChildren {
  selected: TitleOption | null,
  onChanged: (newValue: TitleOption | null) => void,
  onChangedOptions?: (newValue: Array<TitleOption> | []) => void,
  disabled?: boolean,
  provider?: Array<TitleOption> | []
  id?: string
}

const LookUpTitles: React.FC<LookUpPropsClasificacionAdicional> = (props) => {
  const { id, provider, selected, onChanged, onChangedOptions, disabled } = props;
  const selectRef = React.useRef<any>();
  const [options, setOptions] = React.useState<Array<TitleOption> | []>([]);

  const loadDatos = React.useCallback(async () => {
    if (provider !== undefined && provider.length > 0) {
      setOptions(provider)
      const selectedIndex = provider.findIndex((select: TitleOption) => {
        return select.codigo === selected?.codigo
      })
      onChanged(provider[selectedIndex] ?? provider[0])
      if (selectedIndex > -1) {
        onChanged(provider[selectedIndex] ?? provider[0])
      }
    } else {
      const data = await tittlesService.getTittesAdicionales("Elija una opción");
      if (data.error === false) {
        let opt = [];
        if (data.auto.length > 0) {
          opt = data.auto.map((x) => {
            const item: TitleOption = {
              codigo: parseInt(x?.codigo) ?? -1,
              descripcion: utilidades.capText(x?.descripcion) ?? '',
              estado: parseInt(x?.estado),
            }
            return item
          })
        }
        setOptions(opt)
        if (onChangedOptions) {
          onChangedOptions(opt)
        }
        const selectedIndex = opt.findIndex((option) => {
          return option.codigo === selected?.codigo
        })
        if (selectedIndex > -1) {
          onChanged(opt[selectedIndex] ?? opt[0])
        }
      }
    }
  }, [onChanged, onChangedOptions, selected, provider]);

  const getItemDisplayExpr = React.useCallback((item: TitleOption) => {
    return item ? `${item.descripcion}` : '';
  }, []);

  const onLookUpValueChanged = React.useCallback(async (evt: any) => {
    if (evt.event !== undefined) {
      await onChanged(evt.value);
    }
  }, [onChanged]);

  const onSelectBoxFocusIn = React.useCallback((e) => {
    e.element.querySelector("input.dx-texteditor-input").select();
  }, [])

  const refreshSelected = React.useCallback(async () => {
    if (options && options.length > 0) {
      const selectedIndex = await options.findIndex((option: TitleOption) => {
        return option.codigo === selected?.codigo
      })
      onChanged(options[selectedIndex] ?? options[0])
    }
    selectRef.current.instance.repaint();
  }, [onChanged, options, selected])

  React.useEffect(() => {
    loadDatos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    refreshSelected()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  return (
    <SelectBox
      id='titleSelect'
      key={id ?? uuidv4()}
      ref={selectRef}
      items={options ?? []}
      searchEnabled
      displayExpr={getItemDisplayExpr}
      searchExpr={['descripcion']}
      searchTimeout={500}
      onEnterKey={(e) => { console.log(e) }}
      value={selected}
      onValueChanged={onLookUpValueChanged}
      disabled={disabled}
      onFocusIn={onSelectBoxFocusIn}
    >
      {props.children}
    </SelectBox>
  )

}
export default React.memo(LookUpTitles);
