import { CCard, CCardBody } from '@coreui/react-pro'
import React from 'react'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../views/componentes/colContainer'
import { TPersonalesData } from '../../store/types'
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput'
import { TipoTransaccion } from '../../../../../componentes/tipoIdentificacion/store/types'
import { Validator } from 'devextreme-react'
import {
  AsyncRule,
  EmailRule,
  PatternRule,
  RequiredRule,
  StringLengthRule,
} from 'devextreme-react/validator'
import TipoIdentificacionLookUp from '../../../../../componentes/tipoIdentificacion/tipoIdentificacion'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/store'
import InputTextDE, {
  InputDateDE,
  InputNumberDE,
  RadioOptionDE,
} from '../../../../../../views/componentes/inputText-DE/inputText-DE'
import { lh, MessagesKeys } from '../../../../../../helpers/localizationHelper'
import TabContactos, {
  ETipoAsociado,
} from '../../../../../proveedores/components/modalAdminProveedores/tabContactos/tabContactos'
import {
  setDatosEdicionLogin,
  setDatosEdicionPersonal,
} from '../../store/editDataReducer'
import { PersonalServices } from '../../../../../nomina/pages/personal/services/personal.service'
import { ToastTypes } from '../../../../../../store/types'
import { addToast } from '../../../../../../store/toasterReducer'
import LocalidadesLookUp from '../../../../../componentes/localidadesLookUp/localidadesLookUp'
import { generosGruposPersonal } from '../mantenimiento'
import { SelectBoxDE } from '../../../../../../views/componentes/selectBox-DE/selectBox-DE'
import { ECountry } from '../../../../../../store/enum/countries'
import { CheckBox } from 'devextreme-react'

interface IPersonalesProps extends React.PropsWithChildren {
  tabId: string
}
export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

export default function TPersonales(props: IPersonalesProps) {
  const tipoIdentificacionRequired =
    'Personal/Tipo de Identificación: Debe seleccionar una opción'
  const codigoIessMin =
    'Personal/Código IESS: Este campo debe tener al menos 4 caracteres'
  const codigoIessMax =
    'Personal/Código IESS: Este campo no puede tener mas de 100 caracteres'
  const lugarResidenciaMessage: string =
    'Personal/Lugar de Residencia: Elija un Lugar de Residencia'
  const lugarOrigenMessage: string = 'Lugar de Origen: Elija un Lugar de Origen'
  const nombreRequired = 'Personal/Nombres: Este campo es requerido'
  const nombreMin =
    'Personal/Nombres: Este campo debe tener al menos 4 caracteres'
  const nombreMax =
    'Personal/Nombres: Este campo no puede tener mas de 100 caracteres'
  const apellidoRequired = 'Personal/Apellidos: Este campo es requerido'
  const apellidoMin =
    'Personal/Apellidos: Este campo debe tener al menos 4 caracteres'
  const apellidoMax =
    'Personal/Apellidos: Este campo no puede tener mas de 45 caracteres'
  const fnacimiendoRequired = 'Personal/F. nacimiento: Este campo es requerido'
  const cargarFamMax =
    'Personal/Cargas Fam.: Este campo no puede tener mas de 3 caracteres'
  const direccionRequired = 'Personal/Dirección: Este campo es requerido'
  const direccionMin =
    'Personal/Dirección: Este campo debe tener al menos 4 caracteres'
  const direccionMax =
    'Personal/Dirección: Este campo no puede tener mas de 100 caracteres'
  const emailRequired = 'Personal/Email: Este campo es requerido'
  const userPattern =
    'Personal/Usuario: Debe ingresar un correo electrónico válido.'
  const numCasaMax =
    'Personal/Numero Casa: Este campo no puede tener mas de 100 caracteres'
  const idRequired =
    'Personal/' +
    lh.getMessage(MessagesKeys.ClienteNumeroIdentificacionRequerido)
  const idLength =
    'Personal/' +
    lh.getMessage(MessagesKeys.ClienteNumeroIdentificacionIncorrecto, '')
  const idAsync =
    'Personal/' +
    lh.getMessage(MessagesKeys.ClienteNumeroIdentificacionIncorrecto)

  const ruclength =
    'Personal/' + lh.getMessage(MessagesKeys.ClienteNumeroRucIncorrecto)

  const { tabId } = props
  const dispatch = useDispatch()
  const [element] = React.useState('1')

  const datosPersonalesState = useSelector((state: RootState) => {
    return state.administracion.configuraciones.usuarios.editData[tabId]
      ?.personales
  })
  const loginState = useSelector(
    (state: RootState) =>
      state.administracion.configuraciones.usuarios.editData[tabId].login,
  )
  const empresaSession = useSelector(
    (state: RootState) => state.global.session.empresa,
  )

  const setToast = React.useCallback(
    (mensaje: string, tipo: ToastTypes) => {
      dispatch(
        addToast({
          title: 'Usuarios - Personal',
          content: mensaje,
          type: tipo,
        }),
      )
    },
    [dispatch],
  )

  const validateAsyncSelect = React.useCallback(
    (datoSeleccion: any, message: string) => {
      if (datoSeleccion.value.codigo > -1) {
        return Promise.resolve()
      } else {
        return Promise.reject(message)
      }
    },
    [],
  )

  const validateAsyncSeleccionTipoId = React.useCallback(
    (datoSeleccion) => {
      return validateAsyncSelect(
        datoSeleccion,
        `Tipo de Identificación: Debe seleccionar una opción.`,
      )
    },
    [validateAsyncSelect],
  )

  const onChangeValue = React.useCallback(
    (data, key: string) => {
      dispatch(
        setDatosEdicionPersonal({
          key: tabId,
          data: {
            ...datosPersonalesState,
            [key]: data,
          },
        }),
      )
    },
    [dispatch, datosPersonalesState, tabId],
  )

  const validateId = React.useCallback(
    async (params) => {
      const data = await PersonalServices.getPersonalById(params?.value ?? '')
      if (data !== null && data !== undefined && data['error'] === false) {
        if (data['auto'] !== 'NOT_FOUND_USER') {
          setToast(data['message'], ToastTypes.Warning)
          return Promise.reject(
            `La persona con identificaión ${
              params.value ?? ''
            } ya se encuentra registrado, si desea modificar los datos debe dar clic en la opción de edición.`,
          )
        } else {
          return Promise.resolve()
        }
      } else {
        return Promise.reject('Error al validar identificación')
      }
    },
    [setToast],
  )

  const validateAsyncSeleccionResidencia = React.useCallback(
    (datoSeleccion) => {
      return validateAsyncSelect(datoSeleccion, lugarResidenciaMessage)
    },
    [validateAsyncSelect],
  )

  const validateAsyncSeleccionOrigen = React.useCallback(
    (datoSeleccion) => {
      return validateAsyncSelect(datoSeleccion, lugarOrigenMessage)
    },
    [validateAsyncSelect],
  )

  const inputIdentificacionCedula = React.useMemo(() => {
    return (
      <div key={'textCedula'}>
        <InputTextDE
          name="identificacionInput"
          placeholder="Identificación"
          value={datosPersonalesState?.identificacion ?? ''}
          onValueChange={(e) => {
            onChangeValue(e, 'identificacion')
          }}
          id={element}
          inputAttr={{ autocomplete: 'nope' }}
        >
          <Validator>
            <RequiredRule trim message={idRequired} />
            <StringLengthRule
              max="10"
              min="10"
              ignoreEmptyValue
              message={idLength}
            />
            <AsyncRule message={idAsync} validationCallback={validateId} />
          </Validator>
        </InputTextDE>
      </div>
    )
  }, [
    datosPersonalesState?.identificacion,
    element,
    idRequired,
    idLength,
    idAsync,
    validateId,
    onChangeValue,
  ])

  const inputIdentificacionRuc = React.useMemo(() => {
    return (
      <div key={'textCedutextRucla'}>
        <InputTextDE
          name="identificacionInput"
          placeholder="Identificación"
          value={datosPersonalesState?.identificacion ?? ''}
          onValueChange={(e) => {
            onChangeValue(e, 'identificacion')
          }}
          id={element}
          inputAttr={{ autocomplete: 'nope' }}
        >
          <Validator>
            <PatternRule
              message={ruclength}
              pattern="^[0-9]{5,}|^[0-9]{10}$|^[0-9]{13}$"
            />
            <AsyncRule message={ruclength} validationCallback={validateId} />
            <StringLengthRule
              min="13"
              max="13"
              message={ruclength}
              ignoreEmptyValue
            />
          </Validator>
        </InputTextDE>
      </div>
    )
  }, [
    datosPersonalesState?.identificacion,
    element,
    ruclength,
    validateId,
    onChangeValue,
  ])

  const inputIdentificacionPasaporte = React.useMemo(() => {
    return (
      <div key="textPasaporte">
        <InputTextDE
          name="ruc"
          placeholder="Identificación"
          value={datosPersonalesState?.identificacion ?? ''}
          onValueChange={(e) => {
            onChangeValue(e, 'identificacion')
          }}
          id={element}
          inputAttr={{ autocomplete: 'nope' }}
        >
          <Validator>
            <RequiredRule trim message={idRequired} />
            <StringLengthRule max="15" message={idLength} ignoreEmptyValue />
          </Validator>
        </InputTextDE>
      </div>
    )
  }, [
    datosPersonalesState?.identificacion,
    element,
    idRequired,
    idLength,
    onChangeValue,
  ])

  const validateIdCliente = React.useMemo(() => {
    if (
      datosPersonalesState?.tipoIdentificacion &&
      datosPersonalesState.tipoIdentificacion?.codigo === '05'
    ) {
      return inputIdentificacionCedula
    } else if (
      datosPersonalesState?.tipoIdentificacion &&
      datosPersonalesState.tipoIdentificacion?.codigo === '04'
    ) {
      return inputIdentificacionRuc
    } else {
      return inputIdentificacionPasaporte
    }
  }, [
    inputIdentificacionCedula,
    datosPersonalesState,
    inputIdentificacionRuc,
    inputIdentificacionPasaporte,
  ])

  return (
    <CCard style={{ border: 1 }} className="m-0">
      <CCardBody>
        <div style={{ padding: '10px', overflowX: 'hidden' }} className="mb-4">
          <RowContainer>
            {/* formulario 1 */}
            <CustomCol xs="12" md="4">
              <RowContainer>
                <CustomCol xs="12" md="12">
                  <RowContainer>
                    <CustomCol xs="12">
                      <Labeled label="Tipo Identificación:">
                        <SelectBoxDE
                          onValueChange={(data) => {
                            onChangeValue(data, 'tipoIdentificacion')
                          }}
                          value={
                            datosPersonalesState?.tipoIdentificacion ?? null
                          }
                          items={
                            datosPersonalesState?.tiposIdentificacion ?? []
                          }
                          id="lookUpTipoIdDGC"
                          displayExpr={'descripcion'}
                          searchExpr={['descripcion']}
                        >
                          <Validator>
                            <RequiredRule
                              message={tipoIdentificacionRequired}
                            />
                            <AsyncRule
                              message={tipoIdentificacionRequired}
                              validationCallback={validateAsyncSeleccionTipoId}
                            />
                          </Validator>
                        </SelectBoxDE>
                      </Labeled>
                    </CustomCol>
                  </RowContainer>

                  <RowContainer>
                    <CustomCol xs="12">
                      <Labeled label="Identificación:">
                        {validateIdCliente}
                      </Labeled>
                    </CustomCol>
                  </RowContainer>
                  {empresaSession.codigoPais === ECountry.Ecuador && (
                    <RowContainer>
                      <CustomCol xs="12">
                        <Labeled label="Código IESS:">
                          <InputTextDE
                            value={datosPersonalesState?.codigoIess ?? ''}
                            placeholder=""
                            onValueChange={(data) => {
                              onChangeValue(data, 'codigoIess')
                            }}
                            showClearButton={true}
                            width="100%"
                            inputAttr={{ autocomplete: 'nope' }}
                          >
                            <Validator>
                              <StringLengthRule
                                ignoreEmptyValue={true}
                                min="4"
                                message={codigoIessMin}
                              />
                              <StringLengthRule
                                max="15"
                                message={codigoIessMax}
                              />
                            </Validator>
                          </InputTextDE>
                        </Labeled>
                      </CustomCol>
                    </RowContainer>
                  )}

                  <RowContainer>
                    <CustomCol xs="12">
                      <Labeled label="Nombres:">
                        <InputTextDE
                          value={datosPersonalesState?.nombres ?? ''}
                          placeholder=""
                          onValueChange={(data) => {
                            onChangeValue(data, 'nombres')
                          }}
                          showClearButton={true}
                          width="100%"
                          inputAttr={{ autocomplete: 'nope' }}
                        >
                          <Validator>
                            <RequiredRule message={nombreRequired} />
                            <StringLengthRule min="4" message={nombreMin} />
                            <StringLengthRule max="45" message={nombreMax} />
                          </Validator>
                        </InputTextDE>
                      </Labeled>
                    </CustomCol>
                  </RowContainer>

                  <RowContainer>
                    <CustomCol xs="12">
                      <Labeled label="Apellidos:">
                        <InputTextDE
                          value={datosPersonalesState?.apellidos ?? ''}
                          placeholder=""
                          onValueChange={(data) => {
                            onChangeValue(data, 'apellidos')
                          }}
                          showClearButton={true}
                          width="100%"
                          inputAttr={{ autocomplete: 'nope' }}
                        >
                          <Validator>
                            <RequiredRule message={apellidoRequired} />
                            <StringLengthRule min="4" message={apellidoMin} />
                            <StringLengthRule max="45" message={apellidoMax} />
                          </Validator>
                        </InputTextDE>
                      </Labeled>
                    </CustomCol>
                  </RowContainer>

                  <RowContainer>
                    <CustomCol xs="12">
                      <Labeled label="F. nacimiento:">
                        <InputDateDE
                          value={datosPersonalesState?.fechaNacimiento ?? ''}
                          placeholder=""
                          onValueChange={(data) => {
                            onChangeValue(data, 'fechaNacimiento')
                          }}
                        >
                          <Validator>
                            <RequiredRule message={fnacimiendoRequired} />
                          </Validator>
                        </InputDateDE>
                      </Labeled>
                    </CustomCol>
                  </RowContainer>

                  <RowContainer>
                    <CustomCol xs="12">
                      <Labeled label="Genero:">
                        <RadioOptionDE
                          name="genero"
                          layout="horizontal"
                          displayExpr={'label'}
                          dataSource={generosGruposPersonal}
                          value={datosPersonalesState?.genero ?? null}
                          onValueChange={(data) => {
                            onChangeValue(data, 'genero')
                          }}
                        />
                      </Labeled>
                    </CustomCol>
                  </RowContainer>

                  <RowContainer>
                    <CustomCol xs="12">
                      <Labeled label="Estado Civil:">
                        <SelectBoxDE
                          value={datosPersonalesState?.estadoCivil ?? null}
                          onValueChange={(data) => {
                            onChangeValue(data, 'estadoCivil')
                          }}
                          items={datosPersonalesState?.estadosCivil ?? []}
                          id={'lookUpEstadosCivilA'}
                          displayExpr={'descripcion'}
                          searchExpr={['descripcion']}
                        />
                      </Labeled>
                    </CustomCol>
                  </RowContainer>

                  <RowContainer>
                    <CustomCol xs="12">
                      <Labeled label="Cargas Fam.:">
                        <InputNumberDE
                          value={datosPersonalesState?.cargasFamiliares}
                          placeholder=""
                          onValueChange={(data) => {
                            onChangeValue(data, 'cargasFamiliares')
                          }}
                          showClearButton={true}
                          width="100%"
                          min={0}
                        >
                          <Validator>
                            <StringLengthRule max="3" message={cargarFamMax} />
                          </Validator>
                        </InputNumberDE>
                      </Labeled>
                    </CustomCol>
                  </RowContainer>

                  <RowContainer>
                    <CustomCol xs="12">
                      <Labeled label="Tipo Sangre:">
                        <SelectBoxDE
                          onValueChange={(data) => {
                            onChangeValue(data, 'tipoSangre')
                          }}
                          items={datosPersonalesState?.tiposSangre ?? null}
                          value={datosPersonalesState?.tipoSangre ?? []}
                          displayExpr={'descripcion'}
                          searchExpr={['descripcion']}
                        />
                      </Labeled>
                    </CustomCol>
                  </RowContainer>

                  <RowContainer>
                    <CustomCol xs="12">
                      <Labeled label="Estado:">
                        <SelectBoxDE
                          onValueChange={(e) => {
                            onChangeValue(e, 'estado')
                          }}
                          value={datosPersonalesState?.estado ?? null}
                          items={datosPersonalesState?.estados ?? []}
                          displayExpr={'descripcion'}
                          searchExpr={['descripcion']}
                        />
                      </Labeled>
                    </CustomCol>
                  </RowContainer>
                </CustomCol>
              </RowContainer>
            </CustomCol>
            {/* formulario 2 */}
            <CustomCol xs="12" md="8">
              <RowContainer>
                <CustomCol xs="12" md="6">
                  <RowContainer>
                    <CustomCol xs="12">
                      <strong>{'Lugar de residencia'}</strong>
                    </CustomCol>
                  </RowContainer>
                  <RowContainer>
                    <CustomCol xs="12">
                      <Labeled label="">
                        <LocalidadesLookUp
                          onChanged={(data) => {
                            onChangeValue(data, 'ciudadReferencia')
                          }}
                          onChangedOptions={() => {}}
                          selected={
                            datosPersonalesState?.ciudadReferencia ?? null
                          }
                          allowEdit
                          allowClear
                        >
                          <Validator>
                            <RequiredRule message={lugarResidenciaMessage} />
                            <AsyncRule
                              message={lugarResidenciaMessage}
                              validationCallback={
                                validateAsyncSeleccionResidencia
                              }
                            />
                          </Validator>
                        </LocalidadesLookUp>
                      </Labeled>
                    </CustomCol>
                  </RowContainer>

                  <RowContainer>
                    <CustomCol xs="12">
                      <Labeled label="Dirección:">
                        <InputTextDE
                          value={datosPersonalesState?.direccion}
                          placeholder=""
                          onValueChange={(data) => {
                            onChangeValue(data, 'direccion')
                          }}
                          showClearButton={true}
                          width="100%"
                          inputAttr={{
                            autocomplete: 'nope',
                          }}
                        >
                          <Validator>
                            <RequiredRule message={direccionRequired} />
                            <StringLengthRule min="4" message={direccionMin} />
                            <StringLengthRule
                              max="100"
                              message={direccionMax}
                            />
                          </Validator>
                        </InputTextDE>
                      </Labeled>
                    </CustomCol>
                  </RowContainer>

                  <RowContainer>
                    <CustomCol xs="12">
                      <Labeled label="Email:">
                        <InputTextDE
                          value={datosPersonalesState?.email}
                          placeholder=""
                          onValueChange={(data) => {
                            onChangeValue(data, 'email')
                            dispatch(
                              setDatosEdicionLogin({
                                key: tabId,
                                data: {
                                  ...loginState,
                                  usuario: data,
                                },
                              }),
                            )
                          }}
                          showClearButton={true}
                          width="100%"
                          inputAttr={{
                            autocomplete: 'nope',
                          }}
                        >
                          <Validator>
                            <RequiredRule message={emailRequired} />
                            <PatternRule
                              message={userPattern}
                              pattern={emailRegex}
                            />
                          </Validator>
                        </InputTextDE>
                      </Labeled>
                    </CustomCol>
                  </RowContainer>
                </CustomCol>
                {/* formulario 3 */}

                <CustomCol xs="12" md="6">
                  <RowContainer>
                    <CustomCol xs="12">
                      <strong>{'Lugar de origen'}</strong>
                    </CustomCol>
                  </RowContainer>

                  <RowContainer>
                    <CustomCol xs="12">
                      <Labeled label="">
                        <LocalidadesLookUp
                          onChanged={(data) => {
                            onChangeValue(data, 'ciudadOrigen')
                          }}
                          onChangedOptions={() => {}}
                          selected={datosPersonalesState?.ciudadOrigen ?? null}
                          allowEdit
                          allowClear
                        >
                          <Validator>
                            <RequiredRule message={lugarOrigenMessage} />
                            <AsyncRule
                              message={lugarOrigenMessage}
                              validationCallback={validateAsyncSeleccionOrigen}
                            />
                          </Validator>
                        </LocalidadesLookUp>
                      </Labeled>
                    </CustomCol>
                  </RowContainer>

                  <RowContainer>
                    <CustomCol xs="5">
                      <Labeled label="Num Casa:">
                        <InputTextDE
                          value={datosPersonalesState?.numeroCasa}
                          placeholder=""
                          onValueChange={(data) => {
                            onChangeValue(data, 'numeroCasa')
                          }}
                          showClearButton={true}
                          width="100%"
                          inputAttr={{
                            autocomplete: 'nope',
                          }}
                        >
                          <Validator>
                            <StringLengthRule max="15" message={numCasaMax} />
                          </Validator>
                        </InputTextDE>
                      </Labeled>
                    </CustomCol>
                    <CustomCol xs="7">
                      <Labeled label="">
                        <CheckBox
                          text="Usuario para facturación"
                          value={
                            datosPersonalesState?.usuarioFacturacion ?? false
                          }
                          onValueChange={(data) => {
                            onChangeValue(data, 'usuarioFacturacion')
                          }}
                        />
                      </Labeled>
                    </CustomCol>
                  </RowContainer>
                </CustomCol>
              </RowContainer>
              <RowContainer>
                <CustomCol xs="12" md="12">
                  <CCard className="mt-2 ">
                    <CCardBody>
                      <TabContactos
                        tipoAsociado={ETipoAsociado.cliente}
                        data={datosPersonalesState?.telefonos ?? []}
                        actionSelect={(data) => {}}
                        tiposOperadoras={[]}
                        returnContactos={(contactos) => {
                          if (contactos?.length > 0) {
                            onChangeValue(contactos, 'telefonos')
                          }
                        }}
                      />
                    </CCardBody>
                  </CCard>
                </CustomCol>
              </RowContainer>
            </CustomCol>
          </RowContainer>
        </div>
      </CCardBody>
    </CCard>
  )
}

export const defaulDatosEdicionTabDatosPersonales: TPersonalesData = {
  tipoIdentificacion: null,
  tiposIdentificacion: [],
  identificacion: '',
  codigoIess: '',
  nombres: '',
  apellidos: '',
  fechaNacimiento: '',
  genero: null,
  estadoCivil: null,
  estadosCivil: [],
  cargasFamiliares: 0,
  tipoSangre: null,
  tiposSangre: [],
  estado: null,
  estados: [],
  ciudadReferencia: null,
  direccion: '',
  email: '',
  telefonos: [],
  ciudadOrigen: null,
  numeroCasa: '',
  usuarioFacturacion: false,
}
