import React from 'react'
import { CCol, CRow, CAlert } from '@coreui/react-pro'
import { useDispatch, useSelector } from 'react-redux'
import {
  Column,
  DataGrid,
  FilterRow,
  HeaderFilter,
} from 'devextreme-react/data-grid'
import RowContainer from '../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../views/componentes/colContainer'
import Labeled from '../../../../../views/componentes/labeledInput/labeledInput'
import { lh, MessagesKeys } from '../../../../../helpers/localizationHelper'
import TextBox from 'devextreme-react/text-box'
import {
  Validator,
  RequiredRule,
  StringLengthRule,
  AsyncRule,
} from 'devextreme-react/validator'
import { ValidationGroup } from 'devextreme-react/validation-group'
import ValidationSummary from 'devextreme-react/validation-summary'
import SelectBox from '../../../../../views/componentes/selectBox/selectBox'
import CheckBox from 'devextreme-react/check-box'
import { TiposOperadoras } from '../../../../ventas/types/types'
import { ToastTypes } from '../../../../../store/types'
import { addToast } from '../../../../../store/toasterReducer'
import { utilidades } from '../../../../../helpers/utilidades'
import Button from 'devextreme-react/button'
import { RootState } from '../../../../../store/store'
import { ECountry } from '../../../../../store/enum/countries'
import { SelectBoxDE } from '../../../../../views/componentes/selectBox-DE/selectBox-DE'
import prefijos from '../../../../../views/pages/registrarUsuario/prefijosTelefonos'
import InputTextDE from '../../../../../views/componentes/inputText-DE/inputText-DE'

type ErrorInfo = {
  tieneError: boolean
  mensaje?: string
}

export enum ETipoAsociado {
  proveedor = 'proveedor',
  cliente = 'cliente',
}

type Contactos = {
  codigo: number
  numero: string
  descripcion: string
  tipo?: number
  tipoDescripcion?: string
  telefonoCodigo?: number
  telefonoDescripcion?: string
  principal: boolean
  asociadoTipo: string
}

interface TabContactosProps extends React.PropsWithChildren {
  data: Array<any>
  actionSelect: (data) => void
  tiposOperadoras: Array<TiposOperadoras>
  returnContactos: (contactos) => void
  tipoAsociado: ETipoAsociado
}

const TIPOSTELEFONO = [
  { valor: 0, descripcion: 'Elija una opción' },
  { valor: 1, descripcion: 'CASA' },
  { valor: 2, descripcion: 'CELULAR' },
  { valor: 3, descripcion: 'FAX' },
  { valor: 4, descripcion: 'TRABAJO' },
]
const spetialCharNotAllowed =
  'Teléfono: Valor Inválido, no puede contener caracteres especiales'
const TabContactos = (props: TabContactosProps) => {
  const { tipoAsociado, returnContactos } = props

  const dispatch = useDispatch()
  const empresa = useSelector((state: RootState) => {
    return state.global.session?.empresa
  })

  const [errorInfo, setErrorInfo] = React.useState<ErrorInfo>({
    tieneError: false,
  })
  const [tiposTelefonos] = React.useState<Array<any>>(TIPOSTELEFONO)
  const [telefono, setTelefono] = React.useState<string>('')
  const [tipoTelefono, setTipoTelefono] = React.useState<any | null>(null)
  const [tipoOperadora, setTipoOperadora] =
    React.useState<TiposOperadoras | null>(null)
  const [tiposOperadora, setTiposOperadoras] = React.useState<
    Array<TiposOperadoras>
  >([])
  const [principal, setPrincipal] = React.useState<boolean>(false)
  const [contactos, setContactos] = React.useState<Array<Contactos>>([])
  const [seleccionarDato, setSeleccionarDato] = React.useState<any | null>(null)
  const [prefijoTelefono, setPrefijoTelefono] = React.useState<any>(
    prefijos[148],
  )

  const formContactos = React.useRef<any>()

  const onSelectionChanged = (selectedRowsData) => {
    if (selectedRowsData['selectedRowsData'].length > 0) {
      const data = selectedRowsData['selectedRowsData'][0]
      setSeleccionarDato({
        index: 1,
        row: data,
      })
    }
  }

  const llenarDataRegistros = React.useCallback(
    async (dataReg) => {
      let item: any = []
      try {
        item = dataReg.map(function (key) {
          return {
            ...key,
            codigo: key.codigo,
            numero: key.numero,
            descripcion: key.descripcion,
            // tipo: key.,
            tipoDescripcion: key.tipoDescripcion,
            // telefonoCodigo: key.,
            // telefonoDescripcion: key.,
            principal: key.principal,
            asociadoTipo: key.asociadoTipo,
          }
        })
        setContactos(item.map((x) => utilidades.unfreeze(x)))
      } catch (error) {
        console.error('on llenarDataRegistros', error)
      }
    },
    [setContactos],
  )

  const handleClickAdd = React.useCallback(
    async (values) => {
      console.log('handleClickAdd', values)
      try {
        const validationResult = await formContactos.current.instance.validate()
        console.log('validationResult', validationResult)

        if (validationResult.isValid === false) {
          dispatch(
            addToast({
              id: '',
              autoHide: true,
              content: 'Por favor revice las advertencias en el formulario ',
              fade: true,
              title: 'Contacto',
              type: ToastTypes.Warning,
            }),
          )
          return false
        }
        const num =
          (values?.prefijoTelefono?.value || '') + (values?.telefono || '')

        const fnAgregar = async () => {
          const filtrado = contactos.findIndex((item: any) => {
            return item.numero === num
          })

          if (filtrado > -1) {
            dispatch(
              addToast({
                id: '',
                autoHide: true,
                content:
                  'El teléfono que intenta ingresar ya esta registrado : ' +
                  num,
                fade: true,
                title: 'Proveedor',
                type: ToastTypes.Warning,
              }),
            )
            return false
          }
          const numeros: Contactos = {
            codigo: 0,
            numero: num,
            descripcion: values.tipoTelefono?.descripcion ?? '',
            tipo: values.tipoOperadora?.codigo ?? 0,
            tipoDescripcion: values.tipoOperadora?.tipo ?? '',
            telefonoCodigo: values.tipoTelefono?.descripcion ?? '',
            telefonoDescripcion: values.tipoTelefono?.valor ?? '',
            principal: values.principal,
            asociadoTipo: tipoAsociado,
          }

          const contactosCopy = contactos.slice()
          contactosCopy.push(numeros)
          setContactos(contactosCopy)
          returnContactos(contactosCopy)
          // formContactos.current.instance.reset()
          return false
        }

        fnAgregar()
      } catch (error) {
        console.error(error)
        dispatch(
          addToast({
            id: '',
            autoHide: true,
            content: 'Error al agregar este contacto, \n' + error,
            fade: true,
            title: 'Contacto',
            type: ToastTypes.Danger,
          }),
        )
      }
    },
    [returnContactos, setContactos, dispatch, contactos, tipoAsociado],
  )

  const handleClickRemove = () => {
    if (seleccionarDato === null) {
      dispatch(
        addToast({
          id: '',
          autoHide: true,
          content: 'No se encuentra seleccionado un contacto para eliminarlo.',
          fade: true,
          title: 'Contactos',
          type: ToastTypes.Info,
        }),
      )
    } else {
      const filtrado = contactos.findIndex((item: any) => {
        return item.numero === seleccionarDato['row']['numero']
      })
      if (filtrado > -1) {
        const rowsBefore = contactos.slice()
        rowsBefore.splice(filtrado, 1)
        setContactos(rowsBefore)
        props.returnContactos(rowsBefore)
        setSeleccionarDato(null)
        dispatch(
          addToast({
            id: '',
            autoHide: true,
            content: 'Contacto removido correctamente.',
            fade: true,
            title: 'Contactos',
            type: ToastTypes.Success,
          }),
        )
      }
    }
  }

  const onValidateSpetialChar = (value) => {
    const regex = /[^\d]/
    if (!regex.test(value?.value)) {
      return Promise.resolve()
    } else {
      return Promise.reject(spetialCharNotAllowed)
    }
  }

  const onInitForm = React.useCallback(() => {
    if (empresa.codigoPais === ECountry.ElSalvador) {
      setPrefijoTelefono(prefijos[138])
    } else if (empresa.codigoPais === ECountry.Ecuador) {
      setPrefijoTelefono(prefijos[148])
    } else {
      setPrefijoTelefono(prefijos[148])
    }
  }, [empresa])

  React.useEffect(() => {
    onInitForm()
    if (props.tiposOperadoras.length > 0) {
      setTiposOperadoras(props.tiposOperadoras)
    }
    if (props.data.length > 0) {
      llenarDataRegistros(props.data)
    } else {
      setContactos([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  return (
    <>
      <ValidationGroup
        id={`valGroupModificarTelefonosProveedor}`}
        ref={formContactos}
      >
        <RowContainer className="mt-2 mb-2">
          <CustomCol xs="12" md="4">
            <ValidationSummary />
          </CustomCol>
        </RowContainer>

        <RowContainer>
          <CustomCol xs="12" md="4">
            {errorInfo.tieneError && (
              <CAlert
                key="modificaTelefonosProveedorErrorAlert"
                color="danger"
                onChange={() => {
                  setErrorInfo({ tieneError: false })
                }}
              >
                {errorInfo.mensaje}
              </CAlert>
            )}
          </CustomCol>
        </RowContainer>
        <RowContainer>
          <CustomCol xs="12" md="2">
            <Labeled label="Prefijo">
              <SelectBoxDE
                items={prefijos}
                value={prefijoTelefono}
                displayExpr="name"
                defaultValue={prefijoTelefono}
                searchEnabled={true}
                onValueChange={(d) => {
                  setPrefijoTelefono(d)
                }}
                id="prefijo"
                key="prefijo"
                inputAttr={{
                  autocomplete: 'nope',
                }}
              />
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="5">
            <Labeled label="Teléfono">
              <InputTextDE
                name="telefono"
                placeholder="Teléfono"
                value={telefono}
                onValueChange={(value) => setTelefono(value)}
                inputAttr={{
                  autoComplete: 'nope',
                }}
                onEnterKey={() => {
                  handleClickAdd({
                    prefijoTelefono,
                    telefono,
                    tipoTelefono,
                    tipoOperadora,
                    principal: principal,
                  })
                }}
                onKeyDown={utilidades.filtraTeclasNoNumericas}
              >
                <Validator>
                  <RequiredRule
                    trim
                    message={lh.getMessage(
                      MessagesKeys.GlobalCampoRequerido,
                      'Teléfono',
                    )}
                  />
                  <StringLengthRule
                    min={empresa?.codigoPais === ECountry.ElSalvador ? 8 : 6}
                    message={
                      'Teléfono: Este campo debe tener un mínimo de ' +
                      (empresa?.codigoPais === ECountry.ElSalvador ? 8 : 6) +
                      ' caracteres'
                    }
                  />
                  <AsyncRule
                    message={spetialCharNotAllowed}
                    validationCallback={onValidateSpetialChar}
                  />
                </Validator>
              </InputTextDE>
            </Labeled>
          </CustomCol>
        </RowContainer>
        <RowContainer>
          <CustomCol xs="12" md="3">
            <Labeled label="Tipos">
              <SelectBoxDE
                id="selectTiposTelefono"
                items={tiposTelefonos}
                onValueChange={(value) => {
                  if (value) {
                    setTipoTelefono(value)
                    setTipoOperadora(null)
                  } else {
                    setTipoTelefono(null)
                    setTipoOperadora(null)
                  }
                }}
                value={tipoTelefono}
                displayExpr="descripcion"
                searchExpr="codigo"
                inputAttr={{
                  autoComplete: 'nope',
                }}
              />
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="1">
            <Labeled label="Principal">
              <CheckBox
                value={principal}
                onValueChange={(data) => {
                  setPrincipal(data)
                }}
              />
            </Labeled>
          </CustomCol>

          <CustomCol xs="12" md="2">
            <Button
              id="btnAdd"
              className="ms-1 mt-3"
              icon="add"
              stylingMode="contained"
              type="default"
              onClick={() =>
                handleClickAdd({
                  prefijoTelefono,
                  telefono,
                  tipoTelefono,
                  tipoOperadora,
                  principal: principal,
                })
              }
            />

            <Button
              id="btnRemove"
              className="ms-1 mt-3"
              icon="trash"
              stylingMode="contained"
              type="danger"
              onClick={handleClickRemove}
            />
          </CustomCol>
        </RowContainer>
      </ValidationGroup>

      <RowContainer>
        <CustomCol xs="12" md="10">
          <CRow>
            <CCol className="d-flex justify-content-end">
              {contactos.length}
            </CCol>
          </CRow>
          <DataGrid
            selection={{ mode: 'single' }}
            hoverStateEnabled={true}
            dataSource={contactos}
            showBorders={true}
            showRowLines={true}
            onSelectionChanged={onSelectionChanged}
            width={'100%'}
          >
            <FilterRow visible={true} />
            <HeaderFilter visible={true} />
            <Column
              dataType="string"
              dataField="numero"
              caption="Numero"
              width="25%"
            />
            <Column
              dataType="string"
              dataField="descripcion"
              caption="Descripción"
              width="25%"
            />
            <Column
              dataField="tipoDescripcion"
              caption="Operadora"
              width="20%"
            />
            <Column
              dataType="boolean"
              dataField="principal"
              caption="Principal"
              width="15%"
            />
          </DataGrid>
        </CustomCol>
      </RowContainer>
    </>
  )
}

export default TabContactos
