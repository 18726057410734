import React from 'react'
import { DocumentInfo } from '../../../../../../../store/types'
import { PersonalDatosEdicion } from '../../../types/types'
import { TabState } from '../../../../../../../store/genericTabTypes'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput'
import { TextArea, Validator } from 'devextreme-react'
import NivelesEstudioLookUp from '../../../../../../componentes/nivelesEstudioLookUp'
import { consoleService } from '../../../../../../../services/console.service'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import { setDatosEdicionEstudios } from '../../../store/editDataReducer'
import { AsyncRule, RequiredRule } from 'devextreme-react/data-grid'

interface INewProps extends React.PropsWithChildren {
  info: DocumentInfo<PersonalDatosEdicion>
  tab: TabState<PersonalDatosEdicion>
  tabId: string
  refForm: any
}

const Estudios: React.FunctionComponent<INewProps> = (props) => {

  const { tabId } = props
  const dispatch = useDispatch()
  const estudiosState = useSelector((state: RootState) => state.nomina.personal.editData[tabId].estudios)
  const estudiosdMessage: string = 'Estudios: elija un nivel'


  const onChangeValue = React.useCallback((data, key: string) => {

    dispatch(setDatosEdicionEstudios({
      key: tabId,
      data: {
        ...estudiosState,
        [key]: data
      }
    }))
  }, [dispatch, estudiosState, tabId])

  const validateAsyncSelect = React.useCallback((datoSeleccion: any, message: string) => {
    if (datoSeleccion.value.codigo > -1) {
      return Promise.resolve()
    } else {
      return Promise.reject(message)
    }
  }, [])

  const validateAsyncSeleccionEstudios = React.useCallback((datoSeleccion) => {
    return validateAsyncSelect(datoSeleccion, estudiosdMessage)
  }, [validateAsyncSelect])
  return (
    <>
      <RowContainer>
        <CustomCol xs="12" md="6">
          <Labeled label='Nivel de Estudios:'>
            <NivelesEstudioLookUp
              onChanged={(data) => {
                consoleService.log('data ', data)
                onChangeValue(data, 'estudios')
              }}
              provider={[]}
              selected={estudiosState?.estudios ?? null}
            >
              <Validator>
                <RequiredRule message={estudiosdMessage} />
                <AsyncRule
                  message={estudiosdMessage}
                  validationCallback={validateAsyncSeleccionEstudios}
                />
              </Validator>
            </NivelesEstudioLookUp>
          </Labeled>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="6">
          <Labeled label='Titulos Obtenidos:'>
            <TextArea value={estudiosState?.titulos ?? ''} onValueChange={(data) => onChangeValue(data, 'titulos')}></TextArea>
          </Labeled>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="6">
          <Labeled label='Cursos Realizados:'>
            <TextArea value={estudiosState?.cursos ?? ''} onValueChange={(data) => onChangeValue(data, 'cursos')} ></TextArea>
          </Labeled>
        </CustomCol>
      </RowContainer>
    </>
  )
}

export default React.memo(Estudios)