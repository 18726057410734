/* eslint-disable no-console */
import { SesionService } from './sesion.service'

export const consoleService = {
  log,
  error,
  warn,
}

function log(
  d: any,
  d1: any = '',
  level: 'info' | 'warn' | 'error' | 'log' = 'log',
) {
  const sesion = SesionService.getCurrentSesion()
  if (sesion?.empresa?.logs === 1) {
    let type = level
    switch (level) {
      case 'info':
        type = 'log'
        break
      case 'warn':
        type = 'warn'
        break
      case 'error':
        type = 'error'
        break
      default:
        type = 'log'
        break
    }
    if (d1 === '') {
      console[type](d)
    }
    if (d !== '' && d1 !== '') {
      console[type](d, d1)
    }
  }
}

function error(d: any, d1: any = '') {
  const sesion = SesionService.getCurrentSesion()
  if (sesion?.empresa?.logs === 1) {
    if (d1 === '') {
      console.error(d)
    }
    if (d !== '' && d1 !== '') {
      console.error(d, d1)
    }
  } else {
    console.error(d, d1)
  }
}

function warn(d: any) {
  console.error(d)
}
