import React from 'react'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput'
import {
  Button,
  CheckBox,
  DataGrid,
  DateBox,
  NumberBox,
  TextArea,
  TextBox,
  ValidationGroup,
  ValidationSummary,
  Validator,
} from 'devextreme-react'
import { ImportacionDto, ImportacionesDatosEdicion, Items } from '../../types/types'
import { ButtonTypes } from '../../../../../../../views/componentes/globalMenu/types'
import { clearButtonClick, setButtons, setCurrentExecutingAction, setNameTabe } from '../../store/tabsReducer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import {
  setCurrentFunction,
} from '../../store/generalReducer'
import { changeLoader, initDatosEdicion, onChangeDetall, onChangeDocumento, onChangeMovimientos, onChangeSeries, onChangeTotales, setDatosEdicion } from '../../store/editDataReducer'
import Dialog from '../../../../../../../views/componentes/librerias/bootstrap-dialog'
import { v4 as uuidv4 } from 'uuid'
import { CButton, CTooltip } from '@coreui/react-pro'
import { TabState } from '../../../../../../../store/genericTabTypes'
import LocalesLookUp from '../../../../../../componentes/localesLookUp'
import { Column, ColumnChooser, Editing, Pager, Paging, Button as DatagridButton, RequiredRule, StringLengthRule, AsyncRule } from 'devextreme-react/data-grid'
import { getAllowedPageSizes } from '../../../../../../../helpers/Helper'
import validations from '../../../../../../../config/validations'
import { MessagesKeys, lh } from '../../../../../../../helpers/localizationHelper'
import { DocumentInfo, ETipoAsociado, ToastTypes } from '../../../../../../../store/types'
import { addToast } from '../../../../../../../store/toasterReducer'
import { consoleService } from '../../../../../../../services/console.service'
import { getIndexProvider } from '../../../../../../shared/helpers/funciones'
import { getTypesMovements } from '../../../ingresosEgresos/helper/helper'
import { DateUtils, formatoFechasApi } from '../../../../../../../helpers/dateUtils'
import { incomeExpensesServices } from '../../../ingresosEgresos/services/ingresosEgresos.services'
import { StatesEdition } from '../../../../../../../store/enums'
import SelectBox from '../../../../../../../views/componentes/selectBox/selectBox'
import { BuscarProveedorLookUp } from '../../../../../../proveedores/components/busquedaProveedor/BuscarProveedorLookUp'
import { ProveedorBusqueda } from '../../../../../../proveedores/store/types'
import { ETypeMovTitle, ETypeMovimiento, ETypeMovimientoCode, GuardarMovimiento } from '../../../ingresosEgresos/types/types'
import BuscarItemPedidoMemoLookUp from '../../../../../../ventas/pages/ordenes/pages/ordenPedido/components/busquedaPedidos/BusquedaItemsSelectBox/BuscarItemPedidoMemoLookUp'
import { ItemsServices } from '../../../../items/services/items.services'
import { InventarioService } from '../../../../../services/inventario.service'
import { ModalModificarPrecioItem } from '../../../../../../componentes/modalModificarPrecioItems'
import MainModalItems from '../../../../../../ventas/components/items/busquedaItemsModal/MainModalItems'
import { ModalIngresoLotes } from '../../../../../../componentes/modalIngresoLotes/modalIngresoLotes'
import { ModalIngresoSeries } from '../../../../../../componentes/modalIngresoSerie'
import { AdminModalLocales } from '../../../../../../componentes/modalAdminLocales/modalAdminLocales'
import VisualizaError from '../../../../../../ventas/pages/shared/visualizaError/visualizaError'
import ListErrosValidationForm from '../../../../../../componentes/listadoErroresValidacionForm'
import ModalAsiento from '../../../../../../contabilidad/pages/mayorGeneral/componentes/modalAsiento'
import BlockUi from '../../../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../../../views/componentes/loadingindicator/loadingindicator'
import { utilidades } from '../../../../../../../helpers/utilidades'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import { bancoService } from '../../../../../../bancos/service/bancos.service'
import { isMobile } from 'react-device-detect'
import { ModuleButtons } from '../../index';
import { ProveedoresService } from '../../../../../../proveedores/services/proveedores.service'
import { MovimientosGridHelper } from '../../../ingresosEgresos/components/helpers/movimientosGridHelper'
import Importar from '../../../../../../clientes/pages/docsCtasXCobrar/components/impportarExcel/importar'
import { EModulosAcatha } from '../../../../../../../store/enumsAplication'
import { Series } from '../../../../../../componentes/modalIngresoSerie/types/types'
import { ModalVisualizarPorCrear } from '../../../../../../componentes/modalVisualizarPorCrear'
import { ReportWithLocalDataModal } from '../../../../../../../views/componentes/xtrareports/ReportWithLocalDataModal'
import { ItemPedido } from '../../../../../../ventas/pages/ordenes/pages/ordenPedido/type/types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import config from '../../../../../../../config/config'

interface INewProps extends React.PropsWithChildren {
  info: DocumentInfo<ImportacionesDatosEdicion>
  tab: TabState<ImportacionesDatosEdicion>
  tabId: string
}

export const optionsPrioridad = [
  { value: false, label: 'Descripcion' },
  { value: true, label: 'Barras' },
]

const Nuevo: React.FunctionComponent<INewProps> = (props) => {
  const { tab, tabId, info } = props
  const dispatch = useDispatch()
  const inputPunto = React.useRef<any>();
  const inputDoc = React.useRef<any>();
  const inputCantidad = React.useRef<any>();

  const loader = useSelector((state: RootState) => state.inventarios.movimientos.importaciones.editData[tabId].loader)
  const importsState = useSelector((state: RootState) => state.inventarios.movimientos.importaciones.editData[tabId])
  const loading = useSelector((state: RootState) => state.inventarios.movimientos.importaciones.editData[tabId].loading)
  const verRegistro = useSelector((state: RootState) => state.inventarios.movimientos.importaciones.search.verRegistro)

  const tabs = useSelector((state: RootState) => state.inventarios.movimientos.importaciones.tabs)
  const empresa = useSelector((state: RootState) => state.global.session.empresa)
  const localSession = useSelector((state: RootState) => state.global.session.local)
  const modulo = useSelector((state: RootState) => state.inventarios.movimientos.importaciones.config.aplicacion)
  const [newRow,] = React.useState<any>({
    codigo: 0,
    impuesto: 0,
    codigoUsuario: '',
    barras: '',
    unidadDescripcion: '',
    descripcion: '',
    cantidad: 0,
    lotes: 0,
    pvp: 0,
    pvd: 0,
    precio: 0,
    numeroSerie: 0,
    valorDescripcion: 0,
    valor: 0,
    oCostos: 0,
    oCostosIVA: 0,
    fracion: 0, // REVISAR
  })
  const [modalIngresoSeries, setModalIngresoSeries] = React.useState<boolean>(false)
  const [modalIngresoLotes, setModalIngresoLotes] = React.useState<boolean>(false)
  const [showErrorPopup, setShowErrorPopup] = React.useState<boolean>(false)
  const [editCosto, setEditCosto] = React.useState<boolean>(true)
  const [showModalPrecioItem, setShowModalPrecioItem] = React.useState<boolean>(false)
  const [showModalLocal, setShowModalLocal] = React.useState<boolean>(false)
  const [showImportButton, setShowImportButton] = React.useState<boolean>(false)
  const [showModalAsiento, setShowModalAsiento] = React.useState<boolean>(false)
  const [showModalItems, setShowModalItems] = React.useState<boolean>(false)
  const [showModalPorcrear, setShowModalPorcrear] = React.useState<boolean>(false)
  const [moreOptions, setMoreOptions] = React.useState<boolean>(false)

  // const [localesAll, setLocalesAll] = React.useState<Array<any>>([]);
  const [reporte, setReporte] = React.useState<null | "Viewer" | "Designer">(null);

  const [arrayErrors, setArrayErrors] = React.useState<Array<string>>([])
  const [changes, setChanges] = React.useState<any>();
  const [editRowKey, setEditRowKey] = React.useState<any>();
  const [selectedDetalle, setSelectedDetalle] = React.useState(null);
  const sms = validations['es']
  const validationFormRef = React.useRef<any>()
  const dialogRef = React.useRef<any>(null)
  const dataGrid = React.useRef<any>()
  const genericErrorMensaje: string = lh.getMessage(MessagesKeys.GlobalErrorSave)
  // let counter = 0
  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(
      addToast({
        title: 'Inventario-Importaciones',
        content: mensaje,
        type: tipo,
      }),
    )
  }, [dispatch])

  const getFilterString = React.useCallback((filter: ImportacionesDatosEdicion) => {
    const filterText = ''
    return filterText
  }, [])

  const onChangesChange = React.useCallback((changes) => {
    setChanges(changes);
  }, [])

  const playLoader = React.useCallback(
    (message = 'Cargando...') => {
      dispatch(changeLoader({
        key: tabId,
        loader: { show: true, mensaje: message }
      }))
    },
    [dispatch, tabId],
  )

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoader({
      key: tabId,
      loader: { show: false, mensaje: '' }
    }))
  }, [dispatch, tabId])

  const generarOptionsLocales = React.useCallback((obtenidos) => {
    const objeto: any = []
    Object.keys(obtenidos).map(function (key, i) {
      objeto.push({
        codigo: Number(obtenidos[key]['localCodigo']),
        nombre: obtenidos[key]['localNombre'],
        proyectoCodigo: Number(obtenidos[key]['localPertenece']),
        proyectoNombre: obtenidos[key]['localPerteneceDescripcion']
      })
    })
    return objeto
  }, []);

  const onGetIVA = React.useCallback(async () => {
    try {
      const fechaIva = DateUtils.format(importsState?.fechaImp, 'dd/MM/yyyy', 'yyyy-MM-dd')
      const iva = await incomeExpensesServices.getIva(fechaIva, empresa?.codigoTipoNegocio ?? 0, empresa?.codigoPais ?? 0)
      if (iva !== null && iva !== undefined && iva['error'] === false) {
        return iva['auto']
      }
      return 0
    } catch (error) {
      return 0
    }
  }, [importsState, empresa])

  const onGetDeatilMove = React.useCallback(async (codigo: number) => {
    try {
      const res = await incomeExpensesServices.getIncomeExpensesDeatil(codigo)
      if (res !== null && res !== undefined && res['error'] === false) {
        return res['auto']
      }
      return []
    } catch (error) {
      return []
    }
  }, [])

  const onGetProveedor = React.useCallback(async (codigo: number) => {
    try {
      const res = await ProveedoresService.getProveedorByCode(codigo)
      if (res !== null && res !== undefined && res?.codigo) {
        return res
      }
      return null
    } catch (error) {
      return null
    }
  }, [])

  const onGetSeries = React.useCallback(async (codigo: number) => {
    try {
      let arraySeries: Array<any> = []
      const data_series = {
        tipo: 'MOVIMIENTOS',
        asociado: codigo,
      }
      const res = await incomeExpensesServices.getSeries(data_series)
      if (res !== null && res !== undefined && res['error'] === false) {
        arraySeries = res['auto'].map(serie => {
          return {
            codigo: serie?.itemCodigo ?? 0,
            barras: serie?.itemBarras ?? '',
            descripcion: serie?.itemDescripcion ?? '',
            serie: serie?.itemSerieSerie ?? '',
            min: serie?.itemSerieMin ?? ''
          }
        })
        let cantidad: number = 0
        let existe: boolean = false

        importsState?.dataGrid.map((item) => {
          existe = false
          cantidad = 0
          if (item.numeroSerie == 1) {
            arraySeries.map(s => {
              if (s.codigo === item.codigo) {
                existe = true
                cantidad++
              }
            })
            if (existe === false || cantidad !== item.cantidad) {
              for (let index = 0; index < item.cantidad; index++) {
                arraySeries.push({
                  codigo: item?.codigo ?? 0,
                  barras: item?.barras ?? '',
                  descripcion: item?.descripcion ?? '',
                  serie: '',
                  min: ''
                })
              }
            }
          }
        });
      }
      return arraySeries
    } catch (error) {
      return []
    }
  }, [importsState])

  const onGetLotes = React.useCallback(async (codigo: number) => {
    try {
      let arrayLotes: Array<any> = []
      const data_series = {
        tipo: 'MOVIMIENTOS',
        codigo: codigo,
      }
      const res = await incomeExpensesServices.getLots(data_series)
      if (res !== null && res !== undefined && res['error'] === false) {
        arrayLotes = MovimientosGridHelper.onParseLotes(res['auto'])
        consoleService.log(arrayLotes, 'getLotes')
      }
      return arrayLotes
    } catch (error) {
      return []
    }
  }, [])

  const onFilterMovimientos = React.useCallback((data = []) => {
    return data.filter(item => item.codigo === -1 || item.codigo === 9)
  }, [])

  const modoNuevo = React.useCallback(async (template: number, limpiar: boolean = false) => {
    if (loading === false && !limpiar) {
      return
    }
    dispatch(setCurrentExecutingAction({
      tabKey: tabId,
      buttonType: ButtonTypes.new
    }))
    playLoader()
    let arrayMovimientos: Array<any> = []
    const resMovimientos = await getTypesMovements()
    const localesApi = await bancoService.getLocales(empresa.codigo, 'Elije una opción');
    if (resMovimientos.length > 0) {
      arrayMovimientos = onFilterMovimientos(resMovimientos)
    }
    const IVARes = await onGetIVA()


    const data = { ...defaultDataImports }

    if (localesApi && localesApi.auto) {
      const localesData = generarOptionsLocales(localesApi.auto);
      const index = await getIndexProvider(localesData, 'codigo', localSession?.codigo ?? 0)
      if (index > -1) {
        data.local = localesData[index]
      } else {
        data.local = localesData[0]
      }
    }
    data.fechaCompra = DateUtils.getCurrentDateAsString()
    data.fechaImp = DateUtils.getCurrentDateAsString()
    data.conceptos = arrayMovimientos
    data.concepto = arrayMovimientos.length > 0 ? arrayMovimientos[1] : null
    data.dataGrid = [...data.dataGrid, { ...newRow }]
    data.documento = ''
    data.loading = false
    data.IVA = IVARes
    data.desdePlantilla = false;
    if (template > 0) {
      data.desdePlantilla = true;
    }
    consoleService.log('modoNuevo')
    dispatch(setDatosEdicion({
      key: tabId,
      importacion: data
    }))
    stopLoader()
    dispatch(setCurrentExecutingAction({
      tabKey: tabId,
      buttonType: undefined
    }))
  }, [dispatch, tabId, playLoader, stopLoader, onGetIVA, loading, onFilterMovimientos, empresa, generarOptionsLocales, localSession, newRow])


  const cargaMovimientoImp = React.useCallback(async (improtacionesData) => {
    if (loading === false) {
      return
    }

    let proveedor = null
    const data = { ...defaultDataImports }
    consoleService.log(improtacionesData)
    consoleService.log('cargaMovimiento')
    dispatch(
      setButtons({
        tabKey: tabId,
        buttons: {
          ...ModuleButtons,
          Eliminar: false,
          Buscar: false,
          Lotes: !verRegistro,
          Ver_asiento: !verRegistro,
          Limpiar: !verRegistro,
          Series: !verRegistro,
          Guardar: !verRegistro,
          Importar: !verRegistro,
          Resumen: true,
          Imprimir: true,
        },
      }),
    )

    dispatch(setCurrentExecutingAction({
      tabKey: tabId,
      buttonType: ButtonTypes.edit
    }))


    try {

      playLoader('Cargando Registro...')

      let arrayMovimientos: Array<any> = []
      const detalleMov = await onGetDeatilMove(improtacionesData?.codigo)
      const resMovimientos = await getTypesMovements()
      if (resMovimientos.length > 0) {
        arrayMovimientos = onFilterMovimientos(resMovimientos)
      }
      const provider = arrayMovimientos.slice(0)
      const index = await getIndexProvider(provider, 'codigo', improtacionesData?.movimiento ?? 0)
      if (index > -1) {
        data.concepto = provider[index]
      } else {
        data.concepto = provider[0]
      }
      data.conceptos = arrayMovimientos

      const localesApi = await bancoService.getLocales(empresa.codigo, 'Elije una opción');
      const IVARes = await onGetIVA()
      const fechaImp = improtacionesData?.fecha ? DateUtils.format(improtacionesData?.fecha, 'yyyy-MM-dd', 'dd/MM/yyyy') : DateUtils.getCurrentDateAsString()
      const fechaComp = improtacionesData?.fecha ? DateUtils.format(improtacionesData?.fechaCompra, 'yyyy-MM-dd', 'dd/MM/yyyy') : DateUtils.getCurrentDateAsString()
      if (data.concepto !== null && data.concepto !== undefined && data.concepto?.asociado == ETypeMovimiento.compras) {
        proveedor = await onGetProveedor(improtacionesData?.productoCliente)
      }
      const edDoc = improtacionesData?.documento.split("-")
      const resSeries = await onGetSeries(improtacionesData?.codigo ?? 0)
      if (resSeries.length > 0) {
        data.dataSeries = resSeries
      }
      const resLotes = await onGetLotes(improtacionesData?.codigo ?? 0);
      if (resLotes.length > 0) {
        data.dataLotes = resLotes
      }

      if (edDoc.length >= 3) {
        data.establecimeinto = edDoc[0]
        data.punto = edDoc[1]
        data.documento = edDoc[2]
      } else {
        data.documento = improtacionesData?.documento
      }

      if (detalleMov !== null && detalleMov !== undefined && detalleMov.length > 0) {
        data.dataGrid = [...detalleMov, { ...newRow }]
      } else {
        setToast('El movimiento no posee detalle, si cree que es un error comuniquese de inmediato con el Administrador del sistema', ToastTypes.Info)
        data.dataGrid = [...data.dataGrid, { ...newRow }]
      }

      if (localesApi && localesApi.auto) {
        const localesData = generarOptionsLocales(localesApi.auto);
        const index = await getIndexProvider(localesData, 'codigo', improtacionesData?.localCodigo ?? 0)
        if (index > -1) {
          data.local = localesData[index]
        } else {
          data.local = localesData[0]
        }
      }



      data.codigo = improtacionesData?.codigo
      data.IVA = IVARes
      data.observaciones = improtacionesData?.observaciones
      data.proveedor = proveedor
      data.fechaImp = fechaImp
      data.enTransito = improtacionesData?.transito === 0 ? false : true
      data.fechaCompra = fechaComp
      data.desdePlantilla = true
      data.loading = false

      stopLoader()

      dispatch(setDatosEdicion({
        key: tabId,
        importacion: data
      }))
      consoleService.log('cargaMovimientoe final')


    } catch (error) {
      stopLoader()
      setToast(error.message, ToastTypes.Danger)
    }

    dispatch(setCurrentExecutingAction({
      tabKey: tabId,
      buttonType: undefined
    }))
  }, [onGetSeries, playLoader, stopLoader, onGetLotes, setToast, dispatch,
    tabId, onGetDeatilMove, onGetProveedor, onGetIVA,
    empresa, generarOptionsLocales, loading, onFilterMovimientos, verRegistro, newRow])




  const onEditRowKeyChange = React.useCallback((editRowKey) => {
    setEditRowKey(editRowKey);
  }, [])

  const onTotales = React.useCallback((data: any[]) => {
    // const precision = empresa !== null ? empresa.precision : 2
    let subTotIva: number = 0
    let subTotl0: number = 0
    data.map(item => {
      if (item?.impuesto === 3) {
        subTotIva += Number(item?.valorDescripcion) ?? 0
      } else {
        subTotl0 += Number(item?.valorDescripcion) ?? 0
      }
    })
    consoleService.log('OnTotales')
    dispatch(onChangeTotales({
      key: tabId,
      subtotalIva: subTotIva,
      subtotalNoIva: subTotl0,
    }))
  }, [dispatch, tabId])

  const onValidate = React.useCallback(() => {
    const error: Array<string> = []
    const newArrayValidate = importsState.dataGrid.filter(x => x.codigo > 0)
    newArrayValidate.map((item) => {
      if (item.cantidad <= 0) {
        error.push(`Cantidad: El item ${item.barras}-${item.descripcion} tiene Cantidad de 0. \n`)
      }
      if (item.precio < 0 || isNaN(item.precio)) {
        error.push(`Costo Unitario: El item ${item.barras}-${item.descripcion} tiene Costo Unitario menor a 0. \n`)
      }
      if (item.valorDescripcion < 0 || isNaN(item.valorDescripcion)) {
        error.push(`Valor General: El item ${item.barras}-${item.descripcion} tiene Valor Original menor a 0. \n`)
      }
      if (item.valor < 0 || isNaN(item.valor)) {
        error.push(`Total: El item ${item.barras}${item.descripcion} tiene Total menor a 0. \n`)
      }

    })
    return error
  }, [importsState])

  const onHandleSave = React.useCallback(async () => {
    let error: Array<string> = []
    let proveedorCodigo = 0
    let proCli = 0


    const result = validationFormRef.current.instance.validate()

    if (importsState?.dataGrid.length > 0) {
      error = onValidate()
    }
    if (importsState.concepto?.asociado !== ETypeMovimiento.ninguno) {
      if (importsState.concepto?.asociado === ETypeMovimiento.compras && (importsState.proveedor === null || importsState.proveedor === undefined)) {
        error.push(`Proveedor: Debe escoger una opción.`)
      }
    }

    if (importsState.local === null || importsState.local === undefined || importsState.local?.codigo <= 0) {
      error.push('Local: Debe elegir un local.')
    }

    if (importsState?.establecimeinto === null || importsState.establecimeinto === undefined || importsState.establecimeinto === '') {
      error.push('Establecimiento: el establecimeinto esta incompleto')
    }

    if (importsState?.punto === null || importsState.punto === undefined || importsState.punto === '') {
      error.push('Punto: el punto esta incompleto')
    }

    if (importsState?.documento === null || importsState.documento === undefined || importsState.documento === '') {
      error.push('Documento: el documento esta incompleto')
    } else if (isNaN(parseInt(importsState?.documento))) {
      error.push(`Documento: El valor del documento debe ser un número.`)
    }

    if (importsState.concepto === null || importsState.concepto === undefined || importsState.concepto?.codigo <= 0) {
      error.push('Concepto: Debe elegir un movimiento.')
    }


    if (!result.isValid || error.length > 0) {
      setToast(genericErrorMensaje ?? 'Tiene errores, por favor verifiquelos', ToastTypes.Warning)
      setArrayErrors(error)
      setShowErrorPopup(true)
    } else {
      consoleService.log(importsState)
      consoleService.log(importsState?.dataSeries)
      consoleService.log(importsState?.dataLotes)

      const fechImp = importsState?.fechaImp ? DateUtils.format(importsState?.fechaImp, 'dd/MM/yyyy', 'yyyy-MM-dd') : DateUtils.getCurrentDateAsString()
      const fechCompra = importsState?.fechaImp ? DateUtils.format(importsState?.fechaCompra, 'dd/MM/yyyy', 'yyyy-MM-dd') : DateUtils.getCurrentDateAsString()
      const documentoNom = `${importsState?.establecimeinto}-${importsState?.punto}-${importsState?.documento}`


      if (importsState.concepto !== null && importsState.concepto !== undefined && importsState.concepto?.asociado === ETypeMovimiento.compras) {
        proveedorCodigo = importsState.proveedor?.codigo ?? 0
      }

      if (proveedorCodigo !== 0) {
        proCli = proveedorCodigo
      }

      const newArraySave = importsState?.dataGrid.filter(x => x?.codigo > 0)

      const save_data: GuardarMovimiento = {
        infoRegistro: {
          codigo: importsState?.codigo ?? 0,
          movimiento: importsState.concepto?.codigo ?? 0,
          productoCliente: proCli,
          localCodigo: importsState.local?.codigo ?? 0,
          fecha: fechImp,
          documento: documentoNom,
          tipo: 'IMPORTACIONES',
          observaciones: importsState?.observaciones ?? '',
          transito: importsState?.enTransito ? 1 : 0,
          fechaCompra: fechCompra,
          detalle: newArraySave,
          series: importsState?.dataSeries,
          lotes: importsState?.dataLotes
        }
      }

      consoleService.log(save_data)

      dispatch(setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: ButtonTypes.save
      }))

      try {
        playLoader('Guardando...')
        const data = await incomeExpensesServices.setIncomeExpense(save_data)
        stopLoader()
        if (data !== null && data !== undefined && data['error'] === false) {
          consoleService.log('onHandleSvae', data)
          dispatch(setNameTabe({
            key: tabId,
            codigo: data['auto'],
            nombre: documentoNom
          }))
          dispatch(
            setButtons({
              tabKey: 'BUSQUEDA',
              buttons: {
                ...ModuleButtons,
                Guardar: false,
                Imprimir: true,
              },
            }),
          )
          setToast(data['message'], ToastTypes.Success)
        } else {
          setToast(data['message'], ToastTypes.Danger)
        }
      } catch (error) {
        stopLoader()
        setToast(error.message, ToastTypes.Danger)
      }

      dispatch(setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: undefined
      }))
    }
  }, [setToast, importsState, playLoader, stopLoader, dispatch, tabId, onValidate, genericErrorMensaje])

  const onLoadSeries = React.useCallback((filaSerie: number, detalle: Array<any>) => {
    let cantidad: number = 0
    let existe: boolean = false
    consoleService.log(detalle)
    consoleService.log(filaSerie)
    consoleService.log(importsState?.dataSeries)
    importsState?.dataSeries.map(s => {
      if (s.codigo === detalle[filaSerie].codigo) {
        existe = true
        cantidad++
      }
    })
    consoleService.log(cantidad)
    consoleService.log(existe)
    if (existe === false || cantidad != detalle[filaSerie].cantidad) {
      const arraySeries: Array<Series> = []
      for (let index = 0; index < detalle[filaSerie].cantidad; index++) {
        arraySeries.push({
          codigo: detalle[filaSerie]?.codigo ?? 0,
          barras: detalle[filaSerie]?.barras ?? '',
          descripcion: detalle[filaSerie]?.descripcion ?? '',
          serie: '',
          min: ''
        })
      }
      consoleService.log('onLoadSeries')
      dispatch(onChangeSeries({
        key: tabId,
        series: arraySeries
      }))

    }
    setModalIngresoSeries(true)
  }, [importsState, dispatch, tabId])

  const onOpenSeries = React.useCallback(async (data: any) => {
    const provider = importsState?.dataGrid.slice(0)
    const index = await getIndexProvider(provider, 'codigo', data?.codigo ?? 0)
    if (index > -1) {
      onLoadSeries(index, provider)
    }
  }, [importsState?.dataGrid, onLoadSeries])

  const openModalLotes = React.useCallback((data: any) => {
    if (data !== null && data !== undefined && data?.codigo !== 0) {
      if (data?.lotes == 1) {
        setModalIngresoLotes(true)
      } else {
        setToast('El registro seleccionado no esta parametrizado para lotes', ToastTypes.Danger)
      }
    } else {
      setToast('Seleccione un item para ver los lotes', ToastTypes.Warning)
    }
  }, [setToast])

  const onLocalitem = React.useCallback((data) => {
    // onChangedValue(data, 'local')
    dispatch(setDatosEdicion({
      key: tabId,
      importacion: {
        ...importsState,
        local: data
      }
    }))
  }, [dispatch, importsState, tabId])

  const onConfirmMode = React.useCallback((type: null | "Viewer" | "Designer") => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Que impresion desea realizar?`,
      actions: [
        Dialog.Action(
          <span>
            <u>A</u>dmin
          </span>,
          (dialog) => {
            dialog.hide()
            dispatch(setDatosEdicion({
              key: tabId,
              importacion: {
                ...importsState,
                adm: true
              }
            }))
            setReporte(type)
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>B</u>odega
          </span>,
          (dialog) => {
            dialog.hide()
            dispatch(setDatosEdicion({
              key: tabId,
              importacion: {
                ...importsState,
                adm: false
              }
            }))
            setReporte(type)
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    return
  }, [importsState, dispatch, tabId])

  const handleButtonClick = React.useCallback(
    (buttonAction: ButtonTypes) => {
      switch (buttonAction) {
        case ButtonTypes.save:
          if (tabs.current === tabId) onHandleSave()
          break;
        case ButtonTypes.broom:
          if (tabs.current === tabId) {
            const id = uuidv4()
            dispatch(
              initDatosEdicion({
                key: id,
                data: {
                  ...defaultDataImports,
                },
              }),
            )
            modoNuevo(0, true)
          }
          break;
        case ButtonTypes.series:
          if (tabs.current === tabId) {
            if (selectedDetalle !== null && selectedDetalle !== undefined && selectedDetalle?.codigo !== 0) {
              if (selectedDetalle?.numeroSerie == 1) {
                onOpenSeries(selectedDetalle)
              } else {
                setToast('No hay numero de serie para el item seleccionado', ToastTypes.Warning)
              }
            } else {
              setToast('Seleccione un item para ver las series', ToastTypes.Warning)
            }
          }
          break;
        case ButtonTypes.lots:
          if (tabs.current === tabId) {
            openModalLotes(selectedDetalle)
          }
          break;
        case ButtonTypes.verAsiento:
          if (tabs.current === tabId) {
            if (importsState?.codigo !== 0) {
              setShowModalAsiento(true)
            } else {
              setToast('Crea un registro para ver el asiento.', ToastTypes.Warning)
            }
          }
          break;
        case ButtonTypes.import:
          if (tabs.current === tabId) {
            if (importsState.local !== null && importsState.local !== undefined && importsState.local?.codigo > 0) {
              setShowImportButton(true)
            } else {
              setToast('Elija un local antes de subir los ítems', ToastTypes.Warning)
            }
          }
          break;
        case ButtonTypes.print:
          if (tabs.current === tabId) {
            onConfirmMode('Viewer')
          }
          break;
        case ButtonTypes.print_design:
          if (tabs.current === tabId) {
            onConfirmMode('Designer')
          }
          break;
        case ButtonTypes.edit:
          break;
        default:
          break
      }
      dispatch(setCurrentFunction(''))
      dispatch(clearButtonClick(tabId))
    },
    [dispatch, onHandleSave, tabId, tabs, importsState, setToast, selectedDetalle, onOpenSeries, openModalLotes, modoNuevo, onConfirmMode])

  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab.globalButtonClick]);

  React.useEffect(() => {
    if (tab.editStatus === StatesEdition.new) {
      modoNuevo(tab?.info?.codigo)
    } else {
      cargaMovimientoImp(info.info)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (importsState.dataGrid) {
      onTotales(importsState.dataGrid)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importsState.dataGrid]);

  const onAsociadoProv = React.useCallback((data) => {
    let data_prov: ProveedorBusqueda = null
    if (importsState.concepto?.asociado === ETypeMovimiento.compras) {
      data_prov = data
      dispatch(setDatosEdicion({
        key: tabId,
        importacion: {
          ...importsState,
          proveedor: data_prov
        }
      }))
    }
  }, [dispatch, importsState, tabId])

  const setCellCantidad = React.useCallback((rowData, value, currentRowData) => {
    let newCantidad: Array<any> = []
    if (currentRowData !== null && currentRowData !== undefined && value) {
      const detalle = importsState?.dataGrid ?? []
      newCantidad = detalle.map((det) => {
        if (currentRowData.codigo === det.codigo) {
          currentRowData.cantidad = value;
          currentRowData.valor = currentRowData.cantidad * currentRowData.precio
          currentRowData.valorDescripcion = currentRowData.valor
          return currentRowData
        }
        return det
      })
      dispatch(onChangeDetall({
        key: tabId,
        detalle: newCantidad,
      }))
      onTotales(newCantidad)
    }
  }, [importsState, dispatch, onTotales, tabId])

  const setCellCosto = React.useCallback((rowData, value, currentRowData) => {
    let newCantidad: Array<any> = []
    if (currentRowData !== null && currentRowData !== undefined && value) {
      const detalle = importsState?.dataGrid ?? []
      newCantidad = detalle.map((cost) => {
        if (currentRowData.codigo === cost.codigo) {
          currentRowData.precio = value;
          currentRowData.valor = currentRowData.cantidad * currentRowData.precio
          currentRowData.valorDescripcion = currentRowData.valor
          return currentRowData
        }
        return cost
      })
      dispatch(onChangeDetall({
        key: tabId,
        detalle: newCantidad,
      }))
      onTotales(newCantidad)
    }
  }, [importsState, dispatch, onTotales, tabId])

  const setCellVOriginal = React.useCallback((rowData, value, currentRowData) => {
    let newCantidad: Array<any> = []
    if (currentRowData !== null && currentRowData !== undefined && value) {
      const detalle = importsState?.dataGrid ?? []
      newCantidad = detalle.map((vorg) => {
        if (currentRowData.codigo === vorg.codigo) {
          return currentRowData
        }
        return vorg
      })
      dispatch(onChangeDetall({
        key: tabId,
        detalle: newCantidad,
      }))
      onTotales(newCantidad)
    }
  }, [importsState, dispatch, onTotales, tabId])

  const setCellValor = React.useCallback((rowData, value, currentRowData) => {
    let newCantidad: Array<any> = []
    if (currentRowData !== null && currentRowData !== undefined && value) {
      const detalle = importsState?.dataGrid ?? []
      newCantidad = detalle.map((val) => {
        if (currentRowData.codigo === val.codigo) {
          currentRowData.valor = value;
          currentRowData.precio = currentRowData.valor / currentRowData.cantidad;
          currentRowData.valorDescripcion = currentRowData.valor
          return currentRowData
        }
        return val
      })
      dispatch(onChangeDetall({
        key: tabId,
        detalle: newCantidad,
      }))
      onTotales(newCantidad)
    }
  }, [importsState, dispatch, onTotales, tabId])

  const onRemoveItem = React.useCallback(async (data: any) => {
    const dataState = { ...importsState }
    if (data !== null && data !== undefined && data?.codigo !== 0) {
      if (importsState?.dataGrid.length > 0) {
        let dataArray = []
        dataArray = importsState?.dataGrid.slice(0)
        const provider = dataArray.filter(item => item.codigo > 0)
        const index = await getIndexProvider(provider, 'codigo', data?.codigo)
        if (index > - 1) {
          provider.splice(index, 1)
          dataArray = provider
          onTotales(dataArray)
          dataState.dataGrid = [...dataArray, { ...newRow }]
          consoleService.log('onRemoveItem')
          dispatch(setDatosEdicion({
            key: tabId,
            importacion: dataState
          }))
        }
      }
    } else {
      setToast('Seleccione un item de la tabla para quitarlo', ToastTypes.Warning)
    }
  }, [setToast, onTotales, dispatch, importsState, tabId, newRow])
  const onExists = React.useCallback(async (data) => {
    const verify_data: any = {
      codigo: importsState?.codigo ?? 0,
      documento: `${importsState?.establecimeinto}-${importsState?.punto}-${data}`,
      procli: importsState.proveedor?.codigo ?? 0,
      tipo: "IMPORTACIONES",
      movimiento: importsState.concepto?.codigo ?? 0,
      locCodigo: importsState.local?.codigo ?? -1,
    }
    try {
      const res = await incomeExpensesServices.existData(verify_data)
      if (res !== null && res !== undefined && res['error'] === false) {
        if (res['auto'] !== 0) {
          setToast('El documento que intenta ingresar ya existe para este Proveedor/Cliente', ToastTypes.Warning)
          return false
        } else {
          return true
        }
      } else {
        setToast(res['message'], ToastTypes.Danger)
        return false
      }
    } catch (error) {
      return false
      setToast(error.message, ToastTypes.Danger)
    }
  }, [importsState, setToast]);

  const onChangedDocumento = React.useCallback(async (data) => {
    consoleService.log('onChangedDocumento')
    let number = data
    if ((data !== null && data !== undefined) && data !== '' && data.length !== 9) {
      const doc = parseInt(data)
      number = utilidades.zeroFill(doc, 9, 'left')
    } else if (data === '') {
      number = ''
    }
    if (onExists(number)) {
      dispatch(onChangeDocumento({
        key: tabId,
        documento: number
      }))
    }
  }, [dispatch, tabId, onExists]);

  const onChangePunto = React.useCallback((data) => {
    consoleService.log('onChangePunto')
    dispatch(setDatosEdicion({
      key: tabId,
      importacion: {
        ...importsState,
        punto: data
      }
    }))
  }, [dispatch, importsState, tabId])

  const onChangeEst = React.useCallback((data) => {
    consoleService.log('onChangeEst')
    dispatch(setDatosEdicion({
      key: tabId,
      importacion: {
        ...importsState,
        establecimeinto: data
      }
    }))
  }, [dispatch, importsState, tabId])

  const onSlectedItem = React.useCallback(({ selectedRowsData }) => {
    consoleService.log('detalle slected', selectedRowsData[0])
    setSelectedDetalle(selectedRowsData[0])
  }, [])

  const onAddItem = React.useCallback(async (item: ItemPedido, isEnter: boolean = false) => {
    consoleService.log('onAddItem')
    consoleService.log(item, 'item')
    const errorsItem: Array<string> = []
    const cantidad = 1
    let dataArray = []
    let index: number = 0
    const data = { ...importsState }
    if (item === null || item === undefined) {
      errorsItem.push('Item: seleccione un Item para agregarlo.')
    }
    const precision = empresa !== null ? empresa.precision : 2;

    if (errorsItem.length === 0) {
      let data_grid = null
      const provider = importsState?.dataGrid.slice(0)
      dataArray = provider.filter(item => item.codigo > 0)
      index = await getIndexProvider(dataArray, 'codigo', item?.codigo ?? 0)
      if (index > - 1) {
        consoleService.log('existe')
        const existe: any = { ...dataArray[index] }
        existe.cantidad = parseInt(existe.cantidad) + cantidad
        existe.valor = existe.cantidad * existe.precio
        dataArray[index] = existe
      } else {
        consoleService.log('nuevo')
        const costo: any = Number(item?.costo).toFixed(precision) ?? 0;
        consoleService.log('costo', costo)
        data_grid = {
          codigo: item?.codigo ?? 0,
          impuesto: item?.impuesto ?? 0,
          codigoUsuario: item?.codigoInterno ?? '',
          barras: item?.codigoBarras ?? '',
          unidadDescripcion: item?.unidadDescripcion ?? '',
          descripcion: item?.descripcion ?? '',
          cantidad: cantidad ?? 0,
          lotes: parseInt(item?.lotes) ?? 0,
          pvp: item?.pvp ?? 0,
          pvd: item?.pvd ?? 0,
          precio: Number(costo),
          numeroSerie: item?.numeroSerie,
          valorDescripcion: (Number(costo) * cantidad) ?? 0,
          valor: (Number(costo) * cantidad) ?? 0,
          oCostos: 0,
          oCostosIVA: 0,
          fracion: 0, // REVISAR
        }
        dataArray.push(data_grid)
      }
      consoleService.log("dataArray", dataArray)


      dispatch(setDatosEdicion({
        key: tabId,
        importacion: {
          ...importsState,
          item: null,
          codigoItem: 0,
          cantidad: null
        }
      }))

      dispatch(onChangeDetall({
        key: tabId,
        detalle: [...dataArray, { ...newRow }],
      }))

      if (parseInt(data_grid?.lotes) == 1) {
        setSelectedDetalle(data_grid)
        openModalLotes(item)
      }

      if (data_grid?.numeroSerie == 1) {
        const provider = dataArray.slice(0)
        index = await getIndexProvider(provider, 'codigo', item?.codigo)
        if (index > -1) {
          onLoadSeries(index, dataArray)
        }
      }
      return false;


    } else {
      setToast('Tiene errores, por favor verifiquelos', ToastTypes.Warning)
      setArrayErrors(errorsItem)
      setShowErrorPopup(true)
    }
  }, [importsState, setToast, onLoadSeries, dispatch, tabId, openModalLotes, empresa, newRow])

  const adidedItem = React.useCallback((data: any[]) => {
    const dataState = { ...importsState }
    data.map(item => {
      dataState.item.codigo = item?.itemCodigo ?? 0
      dataState.item.impuesto = item?.itemImpuestoCodigo ?? 0
      dataState.item.codigoBarras = item?.itemBarras ?? ''
      dataState.item.descripcion = item?.itemDescripcion ?? ''
      dataState.item.existencia = item?.itemExistencia ?? 0
      dataState.item.costo = item?.itemCosto ?? 0
      dataState.item.descripcion = item?.itemUnidadCompraDescripcion ?? ''
      dataState.item.lotes = item?.itemLotes ?? 0
      dataState.item.pvp = item?.itemPVP ?? 0
      dataState.item.pvd = item?.itemPVD ?? 0
      dataState.item.existenciaMax = parseInt(item.itemStockMax)


      if (data.length === 0) {
        dataState.cantidad = null
      } else if (parseInt(item.itemStockMax) > 0) {
        // onAddItem(1, true)
      }
      consoleService.log('onAddidItem')
      dispatch(setDatosEdicion({
        key: tabId,
        importacion: dataState
      }))

    })
  }, [importsState, dispatch, tabId])

  const onGetDatFile = React.useCallback((data: any) => {
    const dataState = { ...importsState }
    consoleService.log(data, 'obtenerDatosExcell')
    if (data !== null && data !== undefined && data['error'] === false) {
      let tempArray = []
      tempArray = data['auto'].map(item => {
        return item
      })
      consoleService.log(tempArray)
      dataState.dataGrid = [...tempArray[0], { ...newRow }]
      dataState.dataLotes = tempArray[1]
      dataState.dataSeries = tempArray[2]
      dataState.dataProCrear = tempArray[3]
      consoleService.log('onGetDatFile')
      dispatch(setDatosEdicion({
        key: tabId,
        importacion: dataState
      }))
      if (tempArray[3] && tempArray[3].length > 1) {
        // Popup visualizar pro crear
        setShowModalPorcrear(true)
      }
      onTotales(tempArray[0])
    } else {
      setToast(data['message'], ToastTypes.Danger)
    }
  }, [onTotales, setToast, dispatch, importsState, tabId, newRow])

  const onItemsValue = React.useCallback(async (data) => {
    consoleService.log('onItemsValue ', data)
    dispatch(setDatosEdicion({
      key: tabId,
      importacion: {
        ...importsState,
        item: data,
        codigoItem: data?.codigo ?? 0
      }
    }))
    if (data === null || data === undefined) {
      return
    }
    try {
      const resItem = await ItemsServices.getItemDetails(data?.codigo)

      let get: boolean = false

      if (importsState.concepto !== null && importsState.concepto !== undefined && importsState.concepto?.tipo === 9) {
        get = true
        setEditCosto(true)
      } else {

        if (resItem?.existencia <= 0 && resItem?.jerarquia !== 1) {
          setToast('El ítem no tiene existencia disponible en este momento\nSeleccione otro ítem de la lista', ToastTypes.Danger);
          return false;
        } else {
          get = true
          setEditCosto(false)
        }
      }
      if (get) {
        playLoader()
        const fechaSearch = DateUtils.format(importsState?.fechaImp, 'dd/MM/yyyy', 'yyyy-MM-dd')
        const res: any = await InventarioService.getItemXCodigoBarras(resItem?.barras ?? '', resItem?.codigo ?? 0, fechaSearch, true)
        stopLoader()
        if (res !== null && res !== undefined) {
          if (res['length'] === 1) {

            adidedItem(res)
          } else if (res['length'] > 1) {
            res.map(item => {
              item.itemStockMax = 0
            })

            // Open item
            // adicionar items conjunto
          }
        } else if (res === null || res === undefined) {
          setToast('El item especial no tiene asociado items.', ToastTypes.Info)
        }
      }
      inputCantidad.current.instance.focus()
    } catch (error) {
      stopLoader()
      setToast(error.message, ToastTypes.Danger)
    }
  }, [importsState, playLoader, stopLoader, setToast, adidedItem, dispatch, tabId])


  const modalAsiento = (
    <ModalAsiento
      show={showModalAsiento}
      onClose={() => setShowModalAsiento(false)}
      infoAsiento={null}
      origenModulo={Number(modulo)}
    />
  )


  const seleccionarProveedor = () => {
    return (
      <Labeled label="Proveedor:">
        <BuscarProveedorLookUp
          disabled={verRegistro}
          selected={importsState.proveedor as ProveedorBusqueda}
          allowAdd={verRegistro === false}
          allowClear={verRegistro === false}
          allowEdit={verRegistro === false}
          onChanged={onAsociadoProv}
        />
      </Labeled>
    )
  }

  const showDinamycLookUp = () => {
    if (importsState.concepto?.asociado === ETypeMovimiento.compras) {
      return seleccionarProveedor()
    }
  }

  const validateState = React.useCallback((selectedState: any) => {
    consoleService.log(selectedState)
    if (selectedState.value.codigo > 0) {
      return Promise.resolve()
    } else {
      return Promise.reject('Almacén: Debe seleccionar una opción')
    }
  }, [])

  const validateAsyncSeleccionTipoId = React.useCallback((datoSeleccion) => {
    return validateState(datoSeleccion)
  }, [validateState])

  const renderSearchItems = React.useCallback(
    (data: any, rowIndex: number, columnIndex: number) => {
      if (
        (data.codigo || importsState.dataGrid[rowIndex]?.codigo) &&
        columnIndex === 2
      )
        return (
          <>
            {data.descripcion
              ? data.descripcion
              : importsState.dataGrid[rowIndex].descripcion}
          </>
        )
      else if (
        (data.codigo || importsState.dataGrid[rowIndex]?.codigo) &&
        columnIndex === 1
      )
        return (
          <>
            {data.barras ? data.barras : importsState.dataGrid[rowIndex].barras}
          </>
        )

      return (
        <>
          <BuscarItemPedidoMemoLookUp
            selected={null}
            onChanged={(item: ItemPedido) => {
              if (item) onAddItem(item, false)
            }}
            cliente={null}
            fecha={DateUtils.getCurrentDateAsString(
              formatoFechasApi,
            )}
            autoComplete={true}
            prioridadBarras={columnIndex === 1 ? true : false}
            modulo={EModulosAcatha.MovimientosIngresosEgresos}
            tipoAsociado={ETipoAsociado.Clientes}
            movimiento={importsState?.concepto}
            local={importsState?.local !== null && importsState?.local !== undefined ? importsState?.local : undefined}
            codigoPrvAsociado={
              importsState?.proveedor?.codigo ?? -1
            }
          />
        </>
      )
    },
    [importsState, onAddItem],
  )


  const onParseData = React.useCallback((importaciones: ImportacionesDatosEdicion) => {
    const registros: Array<ImportacionDto> = [];
    let registro: ImportacionDto = null
    let registrosItems: Array<Items> = [];
    let registroItem: Items = null

    let proveedor = ''
    let asociado = ''
    let tipo = ''

    if (importaciones.concepto !== null && importaciones.concepto !== undefined && importaciones.concepto?.asociado === ETypeMovimiento.compras) {
      proveedor = importaciones.proveedor?.nombre ?? ''
    }

    const arrayPrint = importaciones.dataGrid.filter(x => x?.codigo > 0)

    if (arrayPrint.length > 0) {
      registrosItems = arrayPrint.map(item => {
        registroItem = {
          Barcode: item?.barras ?? '',
          Unit: item?.unidadDescripcion ?? '',
          Description: item?.descripcion ?? '',
          Quantity: item?.cantidad ?? 0,
          UnitCost: item?.precio ?? 0,
          Original: item?.valorDescripcion ?? 0,
          Total: item?.valor ?? 0,
        }
        return registroItem
      })
    }

    if (proveedor !== '') {
      asociado = proveedor
    }

    if (importaciones.concepto?.tipo === ETypeMovimientoCode.ingresos) {
      tipo = ETypeMovTitle.ingresos
    } else if (importaciones.concepto?.tipo === ETypeMovimientoCode.egresos) {
      tipo = ETypeMovTitle.egresos
    }

    registro = {
      Code: importaciones?.codigo ?? 0,
      MovType: tipo,
      Concep: importaciones.concepto?.descripcion ?? '',
      Associated: asociado,
      Date: importaciones?.fechaImp ?? '',
      Document: `${importaciones?.establecimeinto ?? ''}-${importaciones?.punto ?? ''}-${importaciones?.documento ?? ''}`,
      Boved: importaciones.local?.nombre ?? '',
      Items: registrosItems,
      SubTotalIva: importaciones?.subtotalIva ?? 0,
      SubTotal12: 0,
      SubTotal0: importaciones?.subtotalNoIva ?? 0,
      Discount12: 0,
      Discount0: 0,
      IVA: 0,
      Total: 0,
    }
    registros.push(registro)
    consoleService.log(registros)
    return registros

  }, [])

  const onMoreOptions = React.useCallback(async () => {
    setMoreOptions(true)
  }, [])

  return (
    <>
      <ReportWithLocalDataModal
        show={reporte !== null}
        onClose={() => setReporte(null)}
        data={onParseData(importsState)}
        fileName='StaticIngresoEgreso'
        mode={reporte ?? 'Viewer'}
        parameters={{ 'Reporte_Filtro': getFilterString(importsState), isAdmin: importsState?.adm }}
        template='StaticIngresoEgreso'
        key='reportDesigner'
      />
      {moreOptions && (
        <MainModalItems
          transaccion="ventas"
          show={moreOptions}
          cliente={config.rucConsumidorFinal}
          onCancel={() => {
            setMoreOptions(false)
          }}
          returnItem={(e) => {
            let item = null
            item = { ...e }
            onAddItem(item)
          }}
          modulo={EModulosAcatha.MovimientosIngresosEgresos}
          action={'none'}
          itemEditando={selectedDetalle}
          configFiltros={null}
          requestType={null}
          movimiento={importsState.concepto}
          codigoPrvAsociado={importsState?.proveedor?.codigo ?? -1}
          local={importsState?.local !== null && importsState?.local !== undefined ? importsState?.local : undefined}
        />
      )}
      {showModalPrecioItem && (
        <ModalModificarPrecioItem
          onChanged={(data) => consoleService.log(data)}
          onCancel={() => setShowModalPrecioItem(false)}
          show={showModalPrecioItem}
          codigoItem={importsState?.codigoItem}
        />
      )}
      {showModalItems && (
        <MainModalItems
          transaccion="compras"
          action='none'
          show={showModalItems}
          onCancel={() => setShowModalItems(false)}
          returnItem={(item) => {
            onItemsValue(item)
            setShowModalItems(false)
          }}
        />
      )}
      {
        showModalPorcrear && <ModalVisualizarPorCrear
          onCancel={() => setShowModalPorcrear(false)}
          show={showModalPorcrear}
          porCrear={importsState?.dataProCrear ?? []}
        />
      }
      {modalIngresoLotes && (
        <ModalIngresoLotes
          onChanged={(data) => {
            consoleService.log('onChange', data)
            dispatch(setDatosEdicion({
              key: tabId,
              importacion: {
                ...importsState,
                dataLotes: data
              }
            }))
          }}
          onCancel={() => setModalIngresoLotes(false)}
          show={modalIngresoLotes}
          seleccionadoItems={{
            codigo: selectedDetalle?.codigo ?? 21,
            impuesto: selectedDetalle?.impuesto ?? 1,
            codigoUsuario: selectedDetalle?.codigoUsuario ?? 'test data',
            barras: selectedDetalle?.barras ?? 'test data',
            unidadDescripcion:
              selectedDetalle?.unidadDescripcion ?? 'test data',
            oCostos: selectedDetalle?.oCostos ?? 1,
            oCostosIVA: selectedDetalle?.oCostosIVA ?? 1,
            descripcion: selectedDetalle?.descripcion ?? 'test data',
            cantidad: selectedDetalle?.cantidad ?? 1,
            lotes: selectedDetalle?.lotes ?? 1,
            pvp: selectedDetalle?.pvp ?? 1,
            pvd: selectedDetalle?.pvd ?? 1,
            precio: selectedDetalle?.precio ?? 1,
            numeroSerie: selectedDetalle?.numeroSerie ?? 1,
            fracion: selectedDetalle?.fracion ?? 1,
            valorDescripcion: selectedDetalle?.valorDescripcion ?? 1,
            valor: selectedDetalle?.valor ?? 1,
            cantidadMovimiento: selectedDetalle?.cantidadMovimiento ?? 1,
          }}
          lotes={importsState?.dataLotes ?? []}
          detalleItems={importsState?.dataGrid ?? []}
          modulo={modulo}
          localCodigo={importsState?.local !== null ? importsState.local?.codigo : localSession.codigo}
        />
      )}
      {modalIngresoSeries && (
        <ModalIngresoSeries
          onChanged={(data) => {
            consoleService.log(data, 'series')
            dispatch(setDatosEdicion({
              key: tabId,
              importacion: {
                ...importsState,
                dataSeries: data
              }
            }))
            setModalIngresoSeries(false)
          }}
          onCancel={() => setModalIngresoSeries(false)}
          show={modalIngresoSeries}
          codModulo={modulo}
          dataSeries={importsState?.dataSeries}
          dataDetalle={importsState?.dataGrid}
          data={{
            local: importsState.local?.codigo ?? -1,
          }}
        />
      )}
      {showModalAsiento && modalAsiento}
      {showModalLocal && (
        <AdminModalLocales
          onChanged={(data) => {
            onLocalitem(data)
            setShowModalLocal(false)
          }}
          onCancel={() => setShowModalLocal(false)}
          show={showModalLocal}
          modulo={modulo}
        />
      )}
      {showErrorPopup && (
        <VisualizaError
          titulo="Error en Importaciones"
          mensaje={'Error al guardar'}
          content={
            <ListErrosValidationForm
              mainMessage={'Revise los siguientes errores:'}
              errorsList={arrayErrors}
              color={'danger'}
            />
          }
          onOk={() => setShowErrorPopup(false)}
        />
      )}
      <div style={{ overflowX: 'hidden' }}>
        <BlockUi
          tag="div"
          loader={LoadingIndicator}
          blocking={loader.show}
          message={loader.mensaje}
        >
          <fieldset disabled={verRegistro}>
            <RowContainer>
              <Dialog ref={dialogRef} />
              <ValidationGroup id={'formImport'} ref={validationFormRef}>
                <RowContainer>
                  {!isMobile && (
                    <CustomCol lg="6" md="8" sm="12">
                      <ValidationSummary id="summary"></ValidationSummary>
                    </CustomCol>
                  )}
                </RowContainer>
                <RowContainer>
                  <RowContainer>
                    <CustomCol xs="12" md={showImportButton ? '7' : '12'}>
                      <RowContainer>
                        <CustomCol xs="12" md="5">
                          <Labeled label="Concepto:">
                            <SelectBox
                              id="conceptos"
                              placeholder=""
                              options={importsState?.conceptos ?? []}
                              displayExpr="descripcion"
                              keyExpr="codigo"
                              selected={importsState?.concepto ?? null}
                              disabled={verRegistro}
                              onChange={(data) => {
                                dispatch(onChangeMovimientos({
                                  key: tabId,
                                  concepto: data
                                }));
                              }}
                              clearButton={true}
                            ></SelectBox>
                          </Labeled>
                        </CustomCol>
                        <CustomCol xs="12" md="4">
                          <Labeled label="Fecha de Compra:">
                            <DateBox
                              displayFormat="dd/MM/yyyy"
                              value={importsState?.fechaCompra ?? null}
                              readOnly={verRegistro}
                              onValueChange={(data) => {
                                dispatch(setDatosEdicion({
                                  key: tabId,
                                  importacion: {
                                    ...importsState,
                                    fechaCompra: data
                                  }
                                }))
                              }}
                              max={Date.now()}
                            />
                          </Labeled>
                        </CustomCol>
                        <CustomCol xs="12" md="2" style={{ marginTop: 'auto' }}>
                          <CheckBox
                            text="En Tránsito"
                            value={importsState?.enTransito ?? false}
                            readOnly={verRegistro}
                            onValueChange={(data) => {
                              dispatch(setDatosEdicion({
                                key: tabId,
                                importacion: {
                                  ...importsState,
                                  enTransito: data
                                }
                              }))
                            }}

                          />
                        </CustomCol>
                      </RowContainer>
                    </CustomCol>
                    {showImportButton && (
                      <CustomCol xs="12" md="5">
                        <Importar
                          tab={tab}
                          tabId={tabId}
                          openImport={(open) => setShowImportButton(open)}
                          data={{
                            local: importsState.local?.codigo ?? -1,
                          }}
                          moduleCode={modulo}
                          setData={(data) => onGetDatFile(data)}
                        />
                      </CustomCol>
                    )}
                  </RowContainer>
                </RowContainer>
                <RowContainer>
                  <CustomCol xs="12" md="7">
                    {importsState?.concepto !== null && showDinamycLookUp()}
                  </CustomCol>
                </RowContainer>
                <RowContainer>
                  <CustomCol xs="12" md="2">
                    <Labeled label="Fecha Imp:">
                      <DateBox
                        displayFormat="dd/MM/yyyy"
                        value={importsState?.fechaImp ?? null}
                        readOnly={verRegistro}
                        onValueChange={(data) => {
                          dispatch(setDatosEdicion({
                            key: tabId,
                            importacion: {
                              ...importsState,
                              fechaImp: data
                            }
                          }))
                        }}
                        max={Date.now()}
                      />
                    </Labeled>
                  </CustomCol>
                  <CustomCol xs="12" md="6">
                    <Labeled label="Documento:">
                      <RowContainer>
                        <CustomCol xs="3">
                          <TextBox
                            placeholder="001"
                            value={importsState?.establecimeinto ?? ''}
                            width={'100%'}
                            onValueChange={(d) => {
                              onChangeEst(d)
                            }}
                            maxLength={3}
                            valueChangeEvent="keyup"
                            name="establecimeinto"
                            hoverStateEnabled={true}
                            useMaskedValue={true}
                            onEnterKey={() => {
                              if (importsState?.establecimeinto.length === 3) {
                                inputPunto.current.instance.focus()
                              }
                            }}
                            onKeyDown={utilidades.filtraTeclasNoNumericas}
                            onKeyUp={(d: any) => {
                              if (
                                d?.component &&
                                d?.component['_changedValue'] &&
                                d?.component['_changedValue'].length === 3
                              ) {
                                inputPunto.current.instance.focus()
                              }
                            }}
                          >
                            <Validator>
                              <RequiredRule
                                message={'Establecimiento: ' + sms['required']}
                              />
                            </Validator>
                          </TextBox>
                        </CustomCol>
                        <CustomCol xs="3">
                          <TextBox
                            ref={inputPunto}
                            placeholder="0001"
                            value={importsState?.punto}
                            width={'100%'}
                            maxLength={4}
                            valueChangeEvent="keyup"
                            name="punto"
                            hoverStateEnabled={true}
                            useMaskedValue={true}
                            onValueChange={(d) => {
                              onChangePunto(d)
                            }}
                            onEnterKey={() => {
                              if (importsState?.punto.length === 4) {
                                inputDoc.current.instance.focus()
                              }
                            }}
                            onKeyDown={utilidades.filtraTeclasNoNumericas}
                            onKeyUp={(d: any) => {
                              if (
                                d?.component &&
                                d?.component['_changedValue'] &&
                                d?.component['_changedValue'].length === 4
                              ) {
                                inputDoc.current.instance.focus()
                              }
                            }}
                          >
                            <Validator>
                              <RequiredRule
                                message={'Punto: ' + sms['required']}
                              />
                            </Validator>
                          </TextBox>
                        </CustomCol>
                        <CustomCol>
                          <TextBox
                            ref={inputDoc}
                            placeholder="0000000000"
                            value={importsState?.documento ?? ''}
                            width={'100%'}
                            onValueChange={(d) => {
                              onChangedDocumento(d)
                            }}
                            maxLength={9}
                            onKeyDown={utilidades.filtraTeclasNoNumericas}
                          >
                            <Validator>
                              <RequiredRule
                                message={'Documento: ' + sms['required']}
                              />
                              <StringLengthRule
                                message={
                                  'Documento: ' +
                                  sms['min'].replace(
                                    '%num_characters%',
                                    '9',
                                  )
                                }
                                min={9}
                              />
                            </Validator>
                          </TextBox>
                        </CustomCol>
                      </RowContainer>
                    </Labeled>
                  </CustomCol>
                </RowContainer>
                <RowContainer>
                  <CustomCol xs="12" md="9">
                    <Labeled label="Local:">
                      <div
                        style={{
                          display: 'flex',
                          gap: '2px',
                          alignItems: 'center',
                          width: '100%',
                        }}
                      >
                        <CustomCol xs="12" md="4">
                          <LocalesLookUp
                            disabled={verRegistro}
                            onChanged={(data) => {
                              dispatch(setDatosEdicion({
                                key: tabId,
                                importacion: {
                                  ...importsState,
                                  local: data
                                }
                              }))
                            }}
                            onChangedOptions={() => { }}
                            selected={importsState?.local ?? null}
                          >
                            <Validator>
                              <RequiredRule
                                message={'Almacén: Debe seleccionar una opción'}
                              />
                              <AsyncRule
                                message={'Almacén: Debe seleccionar una opción'}
                                validationCallback={validateAsyncSeleccionTipoId}
                              />
                            </Validator>
                          </LocalesLookUp>
                        </CustomCol>
                        <CustomCol
                          md="3"
                          xs="12"
                          className={
                            isMobile
                              ? 'd-flex justify-content-end m-2 p-0'
                              : 'd-flex justify-content-start m-0 p-0'
                          }
                        >
                          {(importsState.local !== null && importsState.local?.codigo > 0 && !verRegistro) && (
                            <>
                              <CTooltip content="Quitar local seleccionado">
                                <div>
                                  <Button
                                    className="ms-1"
                                    stylingMode="contained"
                                    type="danger"
                                    icon="undo"
                                    onClick={() => {
                                      dispatch(setDatosEdicion({
                                        key: tabId,
                                        importacion: {
                                          ...importsState,
                                          local: null
                                        }
                                      }))
                                    }}
                                  ></Button>
                                </div>
                              </CTooltip>
                              <CTooltip content="Añadir un local">
                                <div>
                                  <Button
                                    id="btnAdd"
                                    className="me-1"
                                    icon="add"
                                    stylingMode="contained"
                                    type="default"
                                    onClick={() => setShowModalLocal(true)}
                                  />
                                </div>
                              </CTooltip>
                            </>
                          )}
                        </CustomCol>

                      </div>
                    </Labeled>
                  </CustomCol>

                </RowContainer>
                <RowContainer>
                  {isMobile && (
                    <CustomCol lg="6" md="8" sm="12">
                      <ValidationSummary id="summary"></ValidationSummary>
                    </CustomCol>
                  )}
                </RowContainer>
              </ValidationGroup>
              {
                (importsState?.concepto === null || importsState.concepto?.codigo <= 0 || importsState?.local === null || importsState.local?.codigo <= 0) ? (

                  <RowContainer className="infoButton">
                    <CustomCol md="1" xs="12" className="text-right">
                      <RowContainer>
                        <CustomCol lg="12">
                          <span>
                            <CTooltip placement={'top'} content={'Por favor selecciona el local y el concepto.'}>
                              <CButton id={'infoAsiento'} color="info" size="sm">
                                <FontAwesomeIcon size="lg" icon={['fal', 'info']} style={{ fontWeight: 1 }} />
                              </CButton>
                            </CTooltip>
                          </span>
                        </CustomCol>
                      </RowContainer>
                    </CustomCol>
                  </RowContainer>
                ) :
                  (
                    <RowContainer className="infoButton">
                      <CustomCol md="1" xs="12" className="text-right">
                        <RowContainer>
                          <CustomCol lg="12">
                            <CTooltip content="Más Opciónes">
                              <div>
                                <Button
                                  className="ms-1"
                                  stylingMode="contained"
                                  type="default"
                                  icon="more"
                                  onClick={() => {
                                    onMoreOptions()
                                  }}
                                ></Button>
                              </div>
                            </CTooltip>
                          </CustomCol>
                        </RowContainer>
                      </CustomCol>
                    </RowContainer>
                  )
              }
              <RowContainer>
                <DataGrid
                  style={{ marginTop: '10px' }}
                  ref={dataGrid}
                  id="codigo"
                  focusedRowKey="codigo"
                  selection={{ mode: 'single' }}
                  dataSource={importsState.dataGrid}
                  showColumnLines={true}
                  disabled={verRegistro}
                  showRowLines={true}
                  showBorders={true}
                  columnResizingMode="widget"
                  onSelectionChanged={onSlectedItem}
                  loadPanel={{
                    enabled: true,
                    height: 90,
                    indicatorSrc: '',
                    shading: false,
                    shadingColor: '',
                    showIndicator: true,
                    showPane: true,
                    text: 'Cargando...',
                    width: 200,
                  }}
                >
                  <Editing
                    mode="cell"
                    allowUpdating
                    changes={changes}
                    onChangesChange={onChangesChange}
                    editRowKey={editRowKey}
                    onEditRowKeyChange={onEditRowKeyChange}
                  />
                  <Paging defaultPageSize={10} />
                  <Pager
                    visible={importsState.dataGrid.length > 0 ? true : false}
                    allowedPageSizes={getAllowedPageSizes(importsState.dataGrid)}
                    displayMode={'full'}
                    showPageSizeSelector={true}
                    showInfo={true}
                    showNavigationButtons={true}
                  />
                  <ColumnChooser enabled={true} mode="select" />
                  <Column type="buttons" width='50px'>
                    <DatagridButton
                      icon="trash"
                      hint="Eliminar"
                      visible={(e) => {
                        return e.row?.data.codigo > 0
                      }}
                      disabled={(e) => {
                        consoleService.log('e', e.row?.data)
                        return e.row?.data.codigo <= 0
                      }}
                      onClick={async (e) => {
                        consoleService.log('e', e.row?.data)
                        onRemoveItem(e.row?.data)
                      }}
                    />
                  </Column>
                  <Column
                    allowEditing={false}
                    dataField="codigoUsuario"
                    caption="Interno"
                    visible={false}
                    width="50px"
                  />
                  <Column
                    allowEditing={false}
                    dataField="barras"
                    cellRender={({ data, rowIndex, columnIndex }) =>
                      renderSearchItems(data, rowIndex, columnIndex)}
                    caption="Barras"
                    width="250px"
                  />

                  <Column
                    allowEditing={false}
                    dataField="descripcion"
                    cellRender={({ data, rowIndex, columnIndex }) =>
                      renderSearchItems(data, rowIndex, columnIndex)}
                    caption="Item"
                    width="250px"
                  />
                  <Column
                    allowEditing={false}
                    dataField="unidadDescripcion"
                    caption="Unidad"
                    width="100px"
                  />
                  <Column
                    allowEditing={false}
                    dataField="pvp"
                    caption="PVP IVA"
                    width="11px"
                    visible={false}
                  />
                  <Column
                    allowEditing={true}
                    setCellValue={setCellCantidad}
                    format={{ precision: empresa?.precision ?? 2 }}
                    dataType='number'
                    dataField="cantidad"
                    caption="Cantidad"
                    width="100px"
                  />
                  <Column
                    allowEditing={true}
                    setCellValue={setCellCosto}
                    format={{ precision: empresa?.precision ?? 2 }}
                    dataType='number'
                    dataField="precio"
                    caption="Costo Unitario"
                    width="100px"
                  />
                  <Column
                    allowEditing={true}
                    setCellValue={setCellVOriginal}
                    format={{ precision: empresa?.precision ?? 2 }}
                    dataType='number'
                    dataField="valorDescripcion"
                    caption="Valor Original"
                    width="100px"
                  />
                  <Column
                    allowEditing={true}
                    setCellValue={setCellValor}
                    format={{ precision: empresa?.precision ?? 2 }}
                    dataType='number'
                    dataField="valor"
                    caption="Total"
                    width="100px"
                  />
                </DataGrid>
              </RowContainer>
              <RowContainer>
                <CustomCol xs="12" md="4" className="m2-start m2-end">
                  <Labeled label="Observaciones:">
                    <TextArea
                      name="observaciones"
                      value={importsState?.observaciones ?? ''}
                      onValueChange={(data) => {
                        dispatch(setDatosEdicion({
                          key: tabId,
                          importacion: {
                            ...importsState,
                            observaciones: data
                          }
                        }))
                      }}
                      inputAttr={{
                        autocomplete: 'nope',
                      }}
                    />
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="3" className="m2-start m2-end"></CustomCol>
                <CustomCol xs="12" md="5">
                  <RowContainer className="mt-2">
                    <CustomCol md="12" className="d-flex justify-content-end">
                      <Labeled label="Subtotal Cargan IVA:" position="left">
                        <NumberBox
                          value={importsState?.subtotalIva ?? null}
                          format={{ precision: empresa?.precision ?? 2 }}
                          showClearButton={true}
                          width="115px"
                          inputAttr={{
                            autocomplete: 'nope',
                            class: 'textInputAlignRight',
                          }}
                          readOnly
                        />
                      </Labeled>
                    </CustomCol>
                  </RowContainer>
                  <RowContainer>
                    <CustomCol md="12" className="d-flex justify-content-end">
                      <Labeled label="Subtotal NO Cargan IVA:" position="left">
                        <NumberBox
                          value={importsState?.subtotalNoIva ?? null}
                          format={{ precision: empresa?.precision ?? 2 }}
                          showClearButton={true}
                          width="115px"
                          inputAttr={{
                            autocomplete: 'nope',
                            class: 'textInputAlignRight',
                          }}
                          readOnly
                        />
                      </Labeled>
                    </CustomCol>
                  </RowContainer>
                </CustomCol>
              </RowContainer>
            </RowContainer>
          </fieldset>
        </BlockUi>
      </div>
    </>
  )
}




export default React.memo(Nuevo)

export const defaultDataImports: ImportacionesDatosEdicion = {
  codigo: 0,
  IVA: 0,
  concepto: null,
  conceptos: [],
  fechaCompra: '',
  enTransito: false,
  proveedor: null,
  loading: false,
  fechaImp: '',
  documento: '',
  punto: '',
  establecimeinto: '',
  loader: {
    show: false,
    mensaje: ''
  },
  local: null,
  item: null,
  codigoItem: 0,
  dataGrid: [],
  dataSeries: [],
  dataLotes: [],
  dataProCrear: [],
  orden: null,
  ordenNumero: 0,
  tipoMovimiento: null,
  barras: '',
  interno: '',
  cantidad: null,
  costo: 0,
  pvp: 0,
  pvd: 0,
  stock: 0,
  unidad: '',
  subtotalIva: 0,
  subtotalNoIva: 0,
  observaciones: '',
  guardado: false,
  desdePlantilla: false,
  adm: false
}
