import { RequestHelper } from '../../../../../helpers/requestHelper'
import { OptionGrupos } from '../../../../../store/types'
import { Cheque } from '../store/types'

export const chequesService = {
  obtenerCheques,
  obtenerCombos,
  crearCheque,
  deleteCheque,
  obtenerProveedor,
  devuelveTotalTran,
  obtenerFormasPago,
  obtenerCheque
}

async function obtenerCheques(banco): Promise<any> {
  try {
    const obj = {
      ...banco
    }
    const data = await RequestHelper.getAll<Array<Cheque>>(
      'bancos/cheques', 'all',
      '',
      obj
    )
    return data
  } catch (error) {
    return error
  }
}

async function obtenerCombos(tipo, mensaje): Promise<any> {
  try {
    const obj = {
      tipoCodigo: tipo,
      mensaje: mensaje
    }
    const data = await RequestHelper.get<any>('subtypes', 'cboxLoad', '', obj)
    return data
  } catch (error) {
    return error
  }
}

async function crearCheque(objeto: any): Promise<any> {
  try {
    const obj = {
      infoRegistro: {
        ...objeto
      }
    }
    const data = await RequestHelper.postAll<any>('bancos/cheques', 'save', obj)
    return data
  } catch (error) {
    return error
  }
}

async function deleteCheque(id: any): Promise<any> {
  try {
    const objRequest = {
      codigo: id
    }
    const data = await RequestHelper.deleteRequestAll<any>(
      'bancos/cheques', 'inactive',
      '',
      objRequest
    )
    return data
  } catch (error) {
    return error
  }
}

async function obtenerProveedor(identificacion): Promise<any> {
  try {
    const obj = {
      identificacion: identificacion
    }
    const data = await RequestHelper.get<any>(
      'proveedores',
      'getByIdOrCode',
      '',
      obj
    )
    return data
  } catch (error) {
    return error
  }
}

async function devuelveTotalTran(banco): Promise<any> {
  try {
    const obj = {
      ...banco
    }
    const data = await RequestHelper.get<Array<Cheque>>(
      'bancos/cheques', 'all',
      '',
      obj
    )
    return data
  } catch (error) {
    return error
  }
}
// lista las formas de pago del SRI
async function obtenerFormasPago(banco): Promise<any> {
  try {
    const obj = {
      ...banco
    }
    const data = await RequestHelper.get<Array<OptionGrupos>>(
      'paymentMethodsSriAvailable',
      '',
      '',
      obj
    )
    return data
  } catch (error) {
    return error
  }
}

async function obtenerCheque(codigo: number, ciudad: string, aFavor: string, proveedorCodigo = 0): Promise<any> {
  try {
    const obj = {
      codigo,
      ciudad,
      aFavor,
      proveedorCodigo: proveedorCodigo === 0 ? "" : proveedorCodigo
    }
    const data = await RequestHelper.getAll<any>(
      'bancos/cheques',
      'getCheck',
      '',
      obj
    )
    return data
  } catch (error) {
    return error
  }
}
