import React from 'react';
import CustomModalDevx from '../../../../../../../../views/componentes/modal/Modal';
import { CCard, CCardBody, CCol, CRow } from '@coreui/react-pro'
import Button from 'devextreme-react/button'
import ImageView from "./imageView"
import { IFileEncodeImage } from '../../type/types';
import Container from '../../../../../../../../views/componentes/container';

interface IModalExtrasProps extends React.PropsWithChildren {
  images: Array<IFileEncodeImage>;
  onCancel: () => void;
  onOk: (images: Array<any>) => void;
}

const ModalExtras: React.FunctionComponent<IModalExtrasProps> = (props) => {

  const { images, onCancel, onOk } = props;
  const [imageFile, setImageFile] = React.useState<Array<any> | null>(null);

  const onSubmit = React.useCallback(() => {
    const imageObject: Array<any> = [];
    if (imageFile) {
      for (let i = 0; i < imageFile.length; i++) {
        imageObject.push({
          ...imageFile[i]
        });
      }
    }
    onOk(imageObject ?? [])
  }, [imageFile, onOk]);

  const convertBase64 = React.useCallback(async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }, []);

  const onImages = React.useCallback(async () => {
    const imageObject: Array<IFileEncodeImage> = [];
    for (let i = 0; i < images.length; i++) {

      let fileCoded: any = "";

      if (typeof (images[i]['source']) === 'object') {
        await convertBase64(images[i]['source']).then(data => {
          fileCoded = data;
        }).catch(data => {
          fileCoded = data;
        });
      } else {
        fileCoded = images[i]['source']
      }

      imageObject.push({
        id: images[i].id,
        source: fileCoded,
        filename: images[i].filename
      })


    }
    setImageFile(imageObject)
  }, [images, convertBase64]);

  const removeFile = React.useCallback((file: IFileEncodeImage) => {
    if (imageFile && imageFile?.length > 0) {
      const filtrado: number = imageFile.findIndex((item: IFileEncodeImage) => {
        return String(item.filename.toLocaleUpperCase()) === String(file.filename.toLocaleUpperCase());
      });
      const backImages: Array<IFileEncodeImage> = imageFile.slice();

      if (filtrado > -1) {
        backImages.splice(filtrado, 1);
        setImageFile(backImages)
      }
    }
  }, [imageFile]);

  React.useEffect(() => {
    if (images) {
      onImages();
    }
  }, [images, onImages]);


  return (
    <CustomModalDevx
      show={true}
      size='md'
      title='Archivos Adjuntos'
      onClose={() => { onCancel() }}
      height={450}
      position='center'
      toolbarItems={[
        {
          widget: 'dxButton',
          toolbar: 'bottom',
          location: 'center',
          options: {
            text: 'Aceptar',
            icon: "check",
            type: "success",
            onClick: onSubmit
          }
        },
        {
          widget: 'dxButton',
          toolbar: 'bottom',
          location: 'center',
          options: {
            text: 'Cancelar',
            icon: "close",
            type: "danger",
            onClick: onCancel
          }
        }
      ]}
    >
      <CCard>
        <CCardBody>
          <Container fluid >
            <CRow>
              <CCol xs='12' sm='12' md='12' lg='6' className={"mt-2 mp-2"}>
                {imageFile &&
                  <CRow className={'m-2'}>
                    <CCol md={12} sm={12} >
                      <strong>{"(" + (imageFile.length ?? 0) + ") Archivos"}</strong>
                    </CCol>
                  </CRow>
                }

                <CRow className={'m-2'} >
                  {imageFile && imageFile.map((file, i) =>
                    <CCol key={i} className="m-1 p-2" sm="12" md='12' lg="12" style={{ border: "solid 0.6px #d2c8c8", borderRadius: "2px" }}>
                      <CRow>
                        <CCol md="8" sm="8" >
                          {file.filename}
                        </CCol>
                        <CCol md="4" sm="4" >
                          <Button
                            icon='close'
                            type='danger'
                            stylingMode='contained'
                            onClick={() => {
                              removeFile(file)
                            }}
                          />
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol md="12" sm="12" >
                          <ImageView source={file['source']} />
                        </CCol>
                      </CRow>
                    </CCol>
                  )}
                </CRow>

              </CCol>
            </CRow>
          </Container>
        </CCardBody>
      </CCard>
    </CustomModalDevx>
  );
}

export default ModalExtras;
