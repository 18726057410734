import React from 'react'
import { isMobile } from 'react-device-detect'
import {
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
} from '@coreui/react-pro'
import Buscar from '../buscar'
import { Aplicacion } from '../../../../../store/types'
import Nuevo from '../nuevo'
import {
  useActiveTabChange,
  useHanldeOnTabClosing,
} from '../../../../../../../hooks/useNavigationTabControls'
import TabNavTitle from '../../../../../../componentes/tituloNavTab'
import { TabsState } from '../../../../../../../store/genericTabTypes'
import { TabTypes } from '../../../../../../../store/enums'
import EnvioRecepcion from '../envio'
import Archivo from '../archivo'

interface ITabCatalogosProps extends React.PropsWithChildren {
  tabsState: TabsState<any>
  // eslint-disable-next-line no-unused-vars
  onTabChanged: (tabKey: string) => void
  tipoAplicacion: Aplicacion
  // eslint-disable-next-line no-unused-vars
  ontabClosing: (tabKey: string) => void
}

const TabControlTransferencias: React.FunctionComponent<ITabCatalogosProps> = (
  props,
) => {
  const { onTabChanged, ontabClosing, tipoAplicacion } = props
  const tabs = props.tabsState.tabs
  const onActiveTabChange = useActiveTabChange(tabs, onTabChanged)
  const hanldeOnTabClosing = useHanldeOnTabClosing(tabs, ontabClosing)

  return (
    <div
      className="container-fluid"
      style={isMobile ? { paddingTop: '10px' } : undefined}
    >
      <CNav variant="tabs" role="tablist">
        {Object.keys(tabs).map((key, index) => {
          const tab = tabs[key]
          return (
            <CNavItem key={`${tab.type}${tab.tabKey}`}>
              <CNavLink
                active={key === props.tabsState.current}
                onClick={() => onActiveTabChange(index)}
              >
                <TabNavTitle
                  tab={tab}
                  tabIndex={index}
                  ontabClosing={hanldeOnTabClosing}
                  current={key === props.tabsState.current}
                />
              </CNavLink>
            </CNavItem>
          )
        })}
      </CNav>
      <CTabContent style={{ overflowY: 'unset' }}>
        {Object.keys(tabs).map((key) => {
          const tab = tabs[key]
          return (
            <CTabPane
              key={`${tab.type}${tab.tabKey}content`}
              visible={key === props.tabsState.current}
            >
              {tab.type === TabTypes.busqueda && (
                <div key="serarchComponent" id="mainContent">
                  {tipoAplicacion === Aplicacion.transferencias && (
                    <Buscar tab={tab} tabId={tab.tabKey} />
                  )}
                </div>
              )}
              {tab.type === TabTypes.documento && (
                <div key="newComponent" id="mainContent">
                  {tipoAplicacion === Aplicacion.transferencias && (
                    <Nuevo tab={tab} tabId={tab.tabKey} />
                  )}
                </div>
              )}
              {(tab.type === TabTypes.envios ||
                tab.type === TabTypes.recepcion) && (
                <div key="newShipment" id="mainContent">
                  {tipoAplicacion === Aplicacion.transferencias && (
                    <EnvioRecepcion tab={tab} tabId={tab.tabKey} />
                  )}
                </div>
              )}
              {tab.type === TabTypes.archivar && (
                <div key="newArchived" id="mainContentArchived">
                  {tipoAplicacion === Aplicacion.transferencias && (
                    <Archivo tab={tab} tabId={tab.tabKey} />
                  )}
                </div>
              )}
            </CTabPane>
          )
        })}
      </CTabContent>
    </div>
  )
}
export default React.memo(TabControlTransferencias)
