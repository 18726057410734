import React from 'react';
import { CCol, CRow } from '@coreui/react-pro'
import { useDispatch, useSelector } from 'react-redux'
import { Column, DataGrid, FilterRow, HeaderFilter, Summary, TotalItem } from 'devextreme-react/data-grid';

interface TablaProps extends React.PropsWithChildren {
    name: string,
    data: any,
    actionDelete: () => void,
    exportExcel: () => void,
    origen: string,
    returnSeleccion: (data) => void
}

const Tabla = (props: TablaProps) => {

    const dispatch = useDispatch()

    const [registros, setRegistros] = React.useState<any>([]);

    React.useEffect(() => {
        if (props.data) {
            setRegistros(props.data)
        }
    }, [props.data]);

    const desSeleccionarRegistro = () => {

    }

    const onSelectionChanged = (selectedRowsData) => {
        //props.returnSeleccion(selectedRowsData)
    }

    const onRowDblClick = (onRowDblClick) => {
        if (props.origen === 'modal') {
            const dataRow = onRowDblClick['key'];
            props.returnSeleccion(dataRow)
        }
    }

    return (
        <>
            <CRow>
                <CCol className="d-flex justify-content-end">
                    {registros.length}
                </CCol>
            </CRow>
            <DataGrid
                selection={{ mode: 'single' }}
                hoverStateEnabled={true}
                dataSource={registros}
                showBorders={true}
                showRowLines={true}
                onSelectionChanged={onSelectionChanged}
                onRowDblClick={onRowDblClick}
            >
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                <Column dataType="string" dataField='nombre' caption='Nombres' width='100%' />

            </DataGrid>
        </>
    );
}

export default Tabla;