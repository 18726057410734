import React from 'react'
import SelectBox from 'devextreme-react/select-box'
import { sustentoTributarioServices } from './services/sustentoTributario.service'
import { SutentosTributarios } from './types/types'

interface SuetentosTributariosLookUpProps extends React.PropsWithChildren {
  selected: SutentosTributarios | null
  // eslint-disable-next-line no-unused-vars
  onChanged: (newValue: SutentosTributarios | null) => void
  // eslint-disable-next-line no-unused-vars
  onChangedOptions?: (newValue: Array<SutentosTributarios> | []) => void
  disabled?: boolean
  provider?: Array<SutentosTributarios> | []
}

const SustentosTributariosLookUp: React.FC<SuetentosTributariosLookUpProps> = (
  props,
) => {
  const { provider, selected, onChanged, disabled, onChangedOptions } = props
  const selectRef = React.useRef<any>()
  const [options, setOptions] = React.useState<Array<SutentosTributarios> | []>([])

  const loadDatos = React.useCallback(async () => {
    if (provider !== undefined && provider.length > 0) {
      setOptions(provider)
      const selectedIndex = provider.findIndex((select: SutentosTributarios) => {
        return select.codigo === selected?.codigo
      })
      onChanged(provider[selectedIndex] ?? provider[0])
    } else {
      const data = await sustentoTributarioServices.getTipos();
      if (data.error === false) {
        let optionsLookup = []
        if (data.auto.length > 0) {
          optionsLookup = data.auto.map((sustento: SutentosTributarios) => {
            return {
              codigo: sustento?.codigo ?? '',
              tipo: sustento?.tipo ?? '',
              estado: sustento?.estado ?? 0,
              descripcionEstado: sustento?.descripcionEstado ?? '',
            }
          })
        }
        setOptions(optionsLookup)
        if (onChangedOptions) {
          onChangedOptions(optionsLookup)
        }
        const selectedIndex = optionsLookup.findIndex((option) => {
          return option.codigo === selected?.codigo
        })
        onChanged(optionsLookup[selectedIndex] ?? optionsLookup[0])
      }
    }
  }, [onChanged, onChangedOptions, selected, provider])

  const getItemDisplayExpr = React.useCallback((item: any) => {
    return item ? `${item.tipo}` : ''
  }, [])

  const onLookUpValueChanged = React.useCallback(
    (evt: any) => {
      if (evt.event !== undefined) {
        onChanged(evt.value)
      }
    },
    [onChanged],
  )

  const onSelectBoxFocusIn = React.useCallback((e) => {
    e.element.querySelector('input.dx-texteditor-input').select()
  }, [])

  const refreshSelected = React.useCallback(() => {
    if (options?.length > 0) {
      const selectedIndex = options.findIndex((option: any) => {
        return option.codigo === selected?.codigo
      })
      onChanged(options[selectedIndex] ?? options[0])
    }
  }, [onChanged, options, selected])

  React.useEffect(() => {
    loadDatos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    refreshSelected()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  return (
    <SelectBox
      id="tiposImpuestosSelect"
      ref={selectRef}
      items={options ?? []}
      searchEnabled
      displayExpr={getItemDisplayExpr}
      searchExpr={['tipo']}
      searchTimeout={500}
      value={selected}
      onValueChanged={onLookUpValueChanged}
      disabled={disabled}
      onFocusIn={onSelectBoxFocusIn}
    >
      {props.children}
    </SelectBox>
  )
}
export default React.memo(SustentosTributariosLookUp)
