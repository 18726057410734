import React, { useState } from 'react';
import {
  CCard,
  CCardHeader,
  CCol,
  CRow,
  CCollapse,
  CLink,
  CForm, CButton
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
//import { cuentasService } from "../../services/cuentas.service"
import { addToast } from '../../../../../../../store/toasterReducer';
import { ToastTypes } from '../../../../../../../store/types';
import { useDispatch, useSelector } from 'react-redux'
import { changeLoader } from '../../../../../../../store/reducers';
import Tabla from '../tabla';
//import { setData } from "../../store/cuentasReducer"
import { RootState } from '../../../../../../../store/store';
import { proveedorService } from '../../../services/ProveedoresService';
import Form, {
  GroupItem,
} from 'devextreme-react/form';
import TextBox from 'devextreme-react/text-box';
import CheckBox from 'devextreme-react/check-box';
import Modalform from "../../../../../../../views/componentes/modalform";
import Button from 'devextreme-react/button';
import { Proveedor } from '../../../../../store/types';
import { cilChevronBottom, cilChevronTop } from '@coreui/icons';
import { setData } from '../../../store/modalProveedor';

type BuscarProps = {
  actionDelete: () => void,
  exportExcel: () => void,
  origen: string, // main, modal
  returnSeleccion: (data) => void
}


const Buscar = (props: BuscarProps) => {

  const dispatch = useDispatch()
  const registros = useSelector((state: RootState) => state.proveedores.proveedores.modalProveedores.registros);

  let marcarTipoCuenta = 0;
  if (props.origen === 'modal') {
    marcarTipoCuenta = 1;
  }

  const [cuenta,] = useState<any>();
  const [codigob_ti, setCodigob_ti] = useState<string>("");
  const [nombre_ti, setNombre_ti] = useState<string>("");
  const [rcomercialb_ti, setRcomercialb_ti] = useState<string>("");
  const [estado_cx, setEstado_cx] = React.useState<boolean>(true);
  const [collapsed, setCollapsed] = React.useState(true)
  const [messageConfirm, setMessageConfirm] = React.useState<string>("Está seguro que desea realizar estos cambios?");
  const [titleConfirm, setTitleConfirm] = React.useState<string>("Confirmar");
  const [showConfirm, setShowConfirm] = React.useState<boolean>(false);


  const setToast = (texto, type) => {
    let tipo: ToastTypes = ToastTypes.Info;
    if (type === 'warning') {
      tipo = ToastTypes.Warning;
    } else if (type === 'success') {
      tipo = ToastTypes.Success;
    } if (type === 'info') {
      tipo = ToastTypes.Info;
    } if (type === 'danger') {
      tipo = ToastTypes.Danger;
    }
    dispatch(addToast({
      id: '',
      autoHide: 3500,
      title: 'Acatha',
      content: texto,
      fade: true,
      type: tipo
    }));
  }

  const playLoader = () => {
    dispatch(changeLoader({ show: true, mensaje: 'Cargando...' }))
  }

  const stopLoader = () => {
    dispatch(changeLoader({ show: false, mensaje: '' }))
  }

  const handleSubmitLast = async (ev) => {
    ev.preventDefault()

  }

  const handleReset = () => {

  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (codigob_ti === "" && codigob_ti === "" && rcomercialb_ti === "") {
      setMessageConfirm("Antes de realizar la búsqueda debe al menos ingresar un criterio.")
      setTitleConfirm("Acatha")
      setShowConfirm(true)
      return false;
    }

    playLoader()
    dispatch(setData([]));

    try {

      const find = {
        identificacion: codigob_ti,
        nombre: nombre_ti ?? "",
        razonComercial: rcomercialb_ti ?? "",
        estado: estado_cx === true ? 1 : 0
      }
      const data = await proveedorService.obtenerProveedores(find);
      stopLoader()
      if (data['error'] === false) {
        if (data['auto'].length > 0) {
          setToast("(" + data['auto'].length + ")" + " Registros encontrados.", "success");
          setCollapsed(false)
          let item: Array<Proveedor> | [] = [];
          item = data['auto'].map(function (item: Proveedor) {
            return {
              codigo: Number(item['codigo']), // PRV_CODIGO;
              tipoIdentificacion: String(item['tipoIdentificacion'] ?? ""), // PRV_TIPOIDE;
              tipoIdDescripcion: String(item['tipoIdDescripcion'] ?? ""), // IDE_IDENTIFICACION;
              identificacion: String(item['identificacion'] ?? ""), // PRV_IDENTIFICACION;
              nombre: String(item['nombre'] ?? ""), // PRV_NOMBRE;
              razonComercial: String(item['razonComercial'] ?? ""), // PRV_RCOMERCIAL;
              direccion: String(item['direccion'] ?? ""), // PRV_DIRECCION;
              email: String(item['email'] ?? ""), // PRV_EMAIL;
              telefono: String(item['telefono'] ?? ""), // PRV_TELEFONO;
              limiteCredito: Number(item['limiteCredito'] ?? 0), // PRV_LIMCREDITO;
              diasCredito: Number(item['diasCredito'] ?? 0), // PRV_DIASCREDITO;
              personaAtencionCompras: String(item['personaAtencionCompras'] ?? ""), // PRV_ATECOMPRAS;
              personaAtencionPagos: String(item['personaAtencionPagos'] ?? ""), // PRV_ATEPAGOS;
              intervalo: Number(item['intervalo'] ?? 0), // PRV_INTERVALO;
              diaReposicion: String(item['diaReposicion'] ?? ""), // PRV_DIAREP;
              ciudadNombre: String(item['ciudadNombre'] ?? ""), // CIU_NOMBRE;
            };
          });
          dispatch(setData(item));
        }
      } else {
        setToast(data.message, "warning");
      }
      return await data;

    } catch (error) {
      console.log('error', error);
      stopLoader()
      setToast(JSON.stringify(error), "danger")
    }

  }

  const returnSelectionRow = (data) => {
    if (props.origen === 'modal') {
      console.log("data", data);
      props.returnSeleccion(data)
    }
  }

  const renderBody = () => {
    return (
      <>{messageConfirm}</>
    )
  }

  const renderFooter = () => {
    return (
      <CButton color="primary" onClick={() => {
        setShowConfirm(false)
      }}>
        {"Aceptar"}
      </CButton>
    )
  }

  const confirmAction = () => {
    return (
      <Modalform
        name='confirmar'
        headerTitle={titleConfirm}
        childrenBody={renderBody()}
        childrenFooter={renderFooter()}
        closeOnBackdrop={false}
        show={showConfirm}
        onClose={() => setShowConfirm(!showConfirm)}
        centered={true}
        size="sm"
      />
    )
  }

  return (
    <>
      {showConfirm === true && confirmAction()}
      <CCard>
        <CCardHeader className='d-flex justify-content-between' onClick={() => setCollapsed(!collapsed)} >
          <strong> {"Buscar"} </strong>
          <div className="card-header-actions">
            <CLink className="card-header-action" >
              <CIcon icon={collapsed ? cilChevronBottom : cilChevronTop} />
            </CLink>
          </div>
        </CCardHeader>

        <CCollapse visible={collapsed}>
          <React.Fragment>


            <CForm key='buscarCCahorros' onSubmit={(evt: any) => handleSubmit(evt)} validated>

              <CRow className="m-2 p-2">
                <CCol lg="10" >
                  <Form
                    formData={cuenta}
                    readOnly={false}
                    showColonAfterLabel={true}
                    showValidationSummary={true}
                    validationGroup="cuentaData"
                  >
                    <GroupItem caption="">
                      <div className="dx-field">
                        <div className="dx-field-label">{"Identificación:"}</div>
                        <div className="dx-field-value">
                          <TextBox
                            value={codigob_ti}
                            placeholder=""
                            onValueChanged={(data) => { setCodigob_ti(data.value) }}
                          //onEnterKey={handleSubmit}
                          />
                        </div>
                      </div>

                      <div className="dx-field">
                        <div className="dx-field-label">{"Nombre:"}</div>
                        <div className="dx-field-value">
                          <TextBox
                            value={nombre_ti}
                            placeholder=""
                            onValueChanged={(data) => { setNombre_ti(data.value) }}
                          //onEnterKey={handleSubmit}
                          />
                        </div>
                      </div>

                      <div className="dx-field">
                        <div className="dx-field-label">{"R. Comercial:"}</div>
                        <div className="dx-field-value">
                          <CRow>
                            <CCol lg="6" md="6" sm="12" >
                              <TextBox
                                value={rcomercialb_ti}
                                placeholder=""
                                onValueChanged={(data) => { setRcomercialb_ti(data.value) }}
                              //onEnterKey={handleSubmit}
                              />
                            </CCol>
                            <CCol lg="6" md="6" sm="12" className=" d-flex justify-content-start" >
                              <CheckBox
                                text="Activos"
                                value={estado_cx}
                                onValueChanged={() => setEstado_cx(!estado_cx)}
                              />
                            </CCol>
                          </CRow>
                        </div>
                      </div>

                    </GroupItem>


                    <GroupItem caption="">
                      <div style={{ zIndex: -1, display: 'none' }}>
                        {/*
                                                <CButton id="btnModalBuscar" color="primary" size='sm' onClick={(ev) => handleSubmit(ev)}>{"Buscar"}</CButton>{' '}
                                                */}
                        <Button
                          id="btnModalBuscar"
                          text="Register"
                          type="success"
                          useSubmitBehavior={true}
                        />

                      </div>
                    </GroupItem>


                  </Form>
                </CCol>
              </CRow>

            </CForm>

          </React.Fragment>
        </CCollapse>

      </CCard>

      <CRow>
        <CCol>
          <Tabla
            name="TablaModalProveedores"
            data={registros}
            actionDelete={() => props.actionDelete()}
            exportExcel={() => props.exportExcel()}
            origen={props.origen}
            returnSeleccion={(data) => returnSelectionRow(data)}
          />
        </CCol>
      </CRow>

    </>
  );
}

export default Buscar;