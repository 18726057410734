import React from 'react'
import {
  DatosEdicionHistorico,
} from '../../../../types/types'
import RowContainer from '../../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../../views/componentes/labeledInput/labeledInput'
import {
  CBadge,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
} from '@coreui/react-pro'
import { Button, DateBox, NumberBox, TextBox } from 'devextreme-react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../../store/store'
import { setTabHistorico } from '../../../../store/editDataReducer'
import {
  DateUtils,
  formatoFechasDatePickers,
} from '../../../../../../../../helpers/dateUtils'

interface IDatHistoricalProps {
  tabId: string
  refForm: any
}

const Historicos: React.FunctionComponent<IDatHistoricalProps> = (props) => {
  const { tabId } = props
  const dispatch = useDispatch()

  const stateEditHistorical = useSelector((state: RootState) => {
    return state.proveedores.proveedores.editData[tabId]?.tabs?.historicos
  })

  const onChangedAmountLastPurchase = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setTabHistorico({
            key: tabId,
            data: {
              ...stateEditHistorical,
              ultimaCompra: {
                ...stateEditHistorical.ultimaCompra,
                monto: event.value,
              },
            },
          }),
        )
    },
    [dispatch, stateEditHistorical, tabId],
  )

  const onChangedDocumentLastPurchase = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setTabHistorico({
            key: tabId,
            data: {
              ...stateEditHistorical,
              ultimaCompra: {
                ...stateEditHistorical.ultimaCompra,
                documento: event.value,
              },
            },
          }),
        )
    },
    [dispatch, stateEditHistorical, tabId],
  )

  const onChangedDateLastPurchase = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setTabHistorico({
            key: tabId,
            data: {
              ...stateEditHistorical,
              ultimaCompra: {
                ...stateEditHistorical.ultimaCompra,
                fecha: DateUtils.dateToString(
                  event.value,
                  formatoFechasDatePickers,
                ),
              },
            },
          }),
        )
    },
    [dispatch, stateEditHistorical, tabId],
  )

  const onChangedAmountLastPayment = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setTabHistorico({
            key: tabId,
            data: {
              ...stateEditHistorical,
              ultimoPago: {
                ...stateEditHistorical.ultimoPago,
                monto: event.value,
              },
            },
          }),
        )
    },
    [dispatch, stateEditHistorical, tabId],
  )

  const onChangedDocumentLastPayment = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setTabHistorico({
            key: tabId,
            data: {
              ...stateEditHistorical,
              ultimoPago: {
                ...stateEditHistorical.ultimoPago,
                documento: event.value,
              },
            },
          }),
        )
    },
    [dispatch, stateEditHistorical, tabId],
  )

  const onChangedDateLastPayment = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setTabHistorico({
            key: tabId,
            data: {
              ...stateEditHistorical,
              ultimoPago: {
                ...stateEditHistorical.ultimoPago,
                fecha: DateUtils.dateToString(
                  event.value,
                  formatoFechasDatePickers,
                ),
              },
            },
          }),
        )
    },
    [dispatch, stateEditHistorical, tabId],
  )

  const onChangedAnnualPurchases = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setTabHistorico({
            key: tabId,
            data: {
              ...stateEditHistorical,
              comprasAnuales: event.value,
            },
          }),
        )
    },
    [dispatch, stateEditHistorical, tabId],
  )

  const onChangedDateCreditLimit = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setTabHistorico({
            key: tabId,
            data: {
              ...stateEditHistorical,
              limiteCreditoFecha: event.value,
            },
          }),
        )
    },
    [dispatch, stateEditHistorical, tabId],
  )

  return (
    <>
      <RowContainer>
        <CustomCol xs="12" md="4">
          <Labeled label="Límite de Crédito:">
            <CRow>
              <CCol lg="4" xs="3">
                {'Hasta: '}
              </CCol>
              <CCol lg="8" xs="3">
                <DateBox
                  displayFormat='dd/MM/yyyy'
                  value={stateEditHistorical.limiteCreditoFecha}
                  onValueChanged={onChangedDateCreditLimit}
                />
              </CCol>
            </CRow>
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="4">
          <Labeled label="Compras Anulaes:">
            <CRow>
              <CCol lg="10" xs="3">
                <NumberBox
                  name="comprasAnuales"
                  placeholder=""
                  value={stateEditHistorical.comprasAnuales}
                  onValueChanged={onChangedAnnualPurchases}
                  inputAttr={{
                    autocomplete: 'nope',
                  }}
                />
              </CCol>
              <CCol lg="2" xs="3">
                <Button
                  id="btnAdd"
                  icon="help"
                  stylingMode="contained"
                  type="default"
                />
              </CCol>
            </CRow>
          </Labeled>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="4">
          <CCard
            className="border-top-info border-top-3"
            style={{ marginTop: '4px' }}
          >
            <CCardHeader>
              <CBadge textColor="black">{'Último Pago'}</CBadge>
            </CCardHeader>
            <CCardBody>
              <RowContainer>
                <CustomCol xs="12" md="12" lg="12" className="mt-1 mb-1">
                  <CRow>
                    <CCol lg="6" xs="6">
                      {'Fecha: '}
                    </CCol>
                    <CCol lg="6" xs="6">
                      <DateBox
                        displayFormat='dd/MM/yyyy'
                        value={stateEditHistorical.ultimoPago.fecha ?? null}
                        onValueChanged={onChangedDateLastPayment}
                      />
                    </CCol>
                  </CRow>
                </CustomCol>
                <CustomCol xs="12" md="12" lg="12" className="mt-1 mb-1">
                  <CRow style={{ marginTop: '4px' }}>
                    <CCol lg="6" xs="6">
                      {'Documento: '}
                    </CCol>
                    <CCol lg="6" xs="6">
                      <TextBox
                        name="documentoUltimoPago"
                        placeholder=""
                        value={stateEditHistorical.ultimoPago.documento ?? ''}
                        onValueChanged={onChangedDocumentLastPayment}
                        inputAttr={{
                          autocomplete: 'nope',
                        }}
                      />
                    </CCol>
                  </CRow>
                </CustomCol>
                <CustomCol xs="12" md="12" lg="12" className="mt-1 mb-1">
                  <CRow>
                    <CCol lg="6" xs="6">
                      {'Monto: '}
                    </CCol>
                    <CCol lg="6" xs="6">
                      <NumberBox
                        name="montoUltimoPago"
                        placeholder=""
                        value={stateEditHistorical.ultimoPago.monto ?? 0}
                        onValueChanged={onChangedAmountLastPayment}
                        inputAttr={{
                          autocomplete: 'nope',
                        }}
                      />
                    </CCol>
                  </CRow>
                </CustomCol>
              </RowContainer>
            </CCardBody>
          </CCard>
        </CustomCol>
        <CustomCol xs="12" md="4">
          <CCard
            className="border-top-info border-top-3"
            style={{ marginTop: '4px' }}
          >
            <CCardHeader>
              <CBadge textColor="black">{'Última Compra'}</CBadge>
            </CCardHeader>
            <CCardBody>
              <RowContainer>
                <CustomCol xs="12" md="12" lg="12" className="mt-1 mb-1">
                  <CRow>
                    <CCol lg="6" xs="6">
                      {'Fecha: '}
                    </CCol>
                    <CCol lg="6" xs="6">
                      <DateBox
                        displayFormat='dd/MM/yyyy'
                        value={stateEditHistorical.ultimaCompra.fecha ?? null}
                        onValueChanged={onChangedDateLastPurchase}
                      />
                    </CCol>
                  </CRow>
                </CustomCol>
                <CustomCol xs="12" md="12" lg="12" className="mt-1 mb-1">
                  <CRow>
                    <CCol lg="6" xs="6">
                      {'Documento: '}
                    </CCol>
                    <CCol lg="6" xs="6">
                      <TextBox
                        name="documentoUltimaVenta"
                        placeholder=""
                        value={stateEditHistorical.ultimaCompra.documento ?? ''}
                        onValueChanged={onChangedDocumentLastPurchase}
                        inputAttr={{
                          autocomplete: 'nope',
                        }}
                      />
                    </CCol>
                  </CRow>
                </CustomCol>
                <CustomCol xs="12" md="12" lg="12" className="mt-1 mb-1">
                  <CRow>
                    <CCol lg="6" xs="6">
                      {'Monto: '}
                    </CCol>
                    <CCol lg="6" xs="6">
                      <NumberBox
                        name="montoUltimaVenta"
                        placeholder=""
                        value={stateEditHistorical.ultimaCompra.monto ?? 0}
                        onValueChanged={onChangedAmountLastPurchase}
                        inputAttr={{
                          autocomplete: 'nope',
                        }}
                      />
                    </CCol>
                  </CRow>
                </CustomCol>
              </RowContainer>
            </CCardBody>
          </CCard>
        </CustomCol>
      </RowContainer>
    </>
  )
}

export default Historicos

export const defaultDatosHistorico: DatosEdicionHistorico = {
  limiteCreditoFecha: DateUtils.getCurrentDateAsString(),
  comprasAnuales: 0,
  ultimoPago: { fecha: null, documento: '', monto: 0 },
  ultimaCompra: { fecha: null, documento: '', monto: 0 },
}
