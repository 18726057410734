import React from 'react';
import DataGrid, { Column, Paging, Pager, ColumnChooser, HeaderFilter, LoadPanel } from 'devextreme-react/data-grid';
import { DatosDetalleRetenciones } from '../../../../types/types';
import { utilidades } from '../../../../../../../../helpers/utilidades';

interface ItablaDetalleRetencionesProps extends React.PropsWithChildren {
  data: Array<DatosDetalleRetenciones> | []
}
const pageSizes = [10, 25, 50, 100, 200];

const TablaDetalleRetenciones: React.FunctionComponent<ItablaDetalleRetencionesProps> = (props) => {
  const { data } = props;

  const dataGrid = React.useRef<any>();
  const [dataTable, setDataTable] = React.useState<Array<DatosDetalleRetenciones> | []>([])


  const clearFilter = React.useCallback(() => {
    dataGrid.current.instance.clearSorting();
    dataGrid.current.instance.clearFilter();
  }, []);

  const llenarDataRegistros = React.useCallback((dataReg) => {
    clearFilter();
    let item: any = []
    try {
      item = dataReg.map(function (key) {
        return {
          ...key,
        }
      });
      setDataTable(item.map(x => utilidades.unfreeze(x)))
    } catch (error) {
      setDataTable([])
      console.error("on llenarDataRegistros TablaDetalleRetenciones", error)
    }
  }, [clearFilter]);

  React.useEffect(() => {
    if (data.length > 0) {
      llenarDataRegistros(data)
    } else {
      setDataTable([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <DataGrid
      ref={dataGrid}
      selection={{ mode: 'single' }}
      allowColumnResizing={true}
      columnResizingMode={'nextColumn'}
      focusedRowEnabled={true}
      keyExpr='compraCodigo'
      focusedRowKey='compraCodigo'
      dataSource={dataTable}
      showBorders={true}
      onRowDblClick={() => { }}
      onSelectionChanged={() => { }}

    >
      <ColumnChooser enabled={true} mode="select" />
      <HeaderFilter visible={true} />
      <LoadPanel enabled={true} />

      <Column dataField='conCodigo' caption='Cod. Retención' width='90px' allowEditing={false} visible={true} allowSearch={false} allowFiltering={false} />
      <Column dataField='conImpuesto' caption='Concepto Retención' width='180px' allowEditing={false} visible={true} allowSearch={false} allowFiltering={true} />
      <Column dataField='rcdBase' type={'string'} width='90px' caption='Base Imponible' alignment='right' allowFiltering={false} />
      <Column dataField='rcdPorcentaje' caption='0% Retención' minWidth='90px' width='auto' />
      <Column dataField='rcdValor' caption='Valor Retenido' minWidth='90px' width='auto' />

      <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} showInfo />
      <Paging defaultPageSize={10} />


    </DataGrid>
  );
}
export default TablaDetalleRetenciones;
