import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CustomDictionary, LoaderInfo } from "../../../../../store/types";
import { ContratoDatosEdicion, ContratosDatosEdicion, ObservacionesDatosEdicion } from "../types/types";
import { EdicionPayload } from '../../../store/types';


const initialState: CustomDictionary<ContratosDatosEdicion> = {}

const datosEditSlice = createSlice({
  name: 'contratosEdicion',
  initialState: initialState,
  reducers: {
    deleteEditData(state, action: PayloadAction<string>) {
      delete state[action.payload]
    },
    initDatosEdicion(
      state,
      action: PayloadAction<EdicionPayload<ContratosDatosEdicion>>,
    ) {
      if (!state[action.payload.key]) {
        state[action.payload.key] = action.payload.data
      }
    },
    setDatosEdicion(
      state,
      action: PayloadAction<EdicionPayload<ContratosDatosEdicion>>,
    ) {
      state[action.payload.key] = action.payload.data
    },
    setDatosEdicionContratos(
      state,
      action: PayloadAction<EdicionPayload<ContratoDatosEdicion>>,
    ) {
      state[action.payload.key].contratos = action.payload.data
    },
    setDatosEdicionObservaciones(
      state,
      action: PayloadAction<EdicionPayload<ObservacionesDatosEdicion>>,
    ) {
      state[action.payload.key].observaciones = action.payload.data
    },
    setChangeLoader(state, action: PayloadAction<EdicionPayload<LoaderInfo>>) {
      state[action.payload.key].loader = action.payload.data
    },
  }
})

export const {
  setDatosEdicion,
  initDatosEdicion,
  deleteEditData,
  setChangeLoader,
  setDatosEdicionContratos,
  setDatosEdicionObservaciones,
} = datosEditSlice.actions
export const dataEditReducer = datosEditSlice.reducer