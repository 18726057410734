import React, { useEffect } from 'react'
import ValidationSummary from 'devextreme-react/validation-summary'
import TextBox from 'devextreme-react/text-box'
import { ValidationGroup } from 'devextreme-react/validation-group'
import { Validator } from 'devextreme-react'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import { RequiredRule, StringLengthRule } from 'devextreme-react/validator'
import SelectBox from '../../../../../../../views/componentes/selectBox/selectBox'
import { CCardBody, CCol, CForm, CRow } from '@coreui/react-pro'
import { ToastTypes } from "../../../../../../../store/types"
import validations from '../../../../../../../config/validations'
import { GrupoInventario } from '../../../../../store/types'
import { InventarioService } from '../../../../../services/inventario.service'
import { BuscarGrupoLookUp } from '../../../buscarGrupo'

const sms = validations['es']


const estadoLinea = [
  {
    value: 1,
    label: 'Activo',
  },
  {
    value: 2,
    label: 'Inactivo',
  }
]
interface INuevoProps extends React.PropsWithChildren {
  onChanged: (data) => void
  selection?: any
  onCancel?: () => void
  funcion: string
  currentTab: string
  dataCategoriaWeb: any,
  setCurrenTab: (currentTab: string) => void
  resetFunction: () => void
  resetSelected?: () => void
  setToast: (sms: string, type: ToastTypes) => void
  playLoader?: () => void
  stopLoader?: () => void
}


const Nuevo = (props: INuevoProps) => {
  const { funcion, setToast, selection, playLoader, stopLoader, currentTab, resetFunction, resetSelected, setCurrenTab, dataCategoriaWeb } = props
  const formSearchRef = React.useRef<any>()

  const tempValue: GrupoInventario = {
    codigo: 0,
    descripcion: 'NO APLICA',
    perteneceCodigo: 0,
    perteneceNombre: 'NO APLICA',
    estadoCodigo: 1,
    categoriaWeb: -1,
    estadoNombre: 'ACTIVO',
    items: NaN,
  }

  const [codigo, setCodigo] = React.useState<number>(0)
  const [descripcion, setDescripcion] = React.useState<string>('')
  const [pertenece, setPertenece] = React.useState<GrupoInventario>(tempValue)
  const [estado, setEstado] = React.useState<any | null>(estadoLinea[0])
  const [categoriaWeb, setCategoriaWeb] = React.useState<Array<any> | null>(dataCategoriaWeb[0])


  useEffect(() => {
    if (currentTab === 'Nuevo' || currentTab === 'Editar') {
      if (funcion === 'Guardar') {
        handleSubmit()
      } else if (funcion === 'Editar') {
        handleForm(selection)
      } else {
        handleReset()
      }
      resetFunction()
    }
  }, [funcion])


  const handleSubmit = React.useCallback(async () => {
    const result = formSearchRef.current.instance.validate()
    if (result.isValid) {

      playLoader()
      const object_request = {
        codigo: codigo,
        descripcion: descripcion,
        estado: estado.value,
        pertenece: pertenece?.codigo || 0,
        categoriaWeb: categoriaWeb['id'] === -1 ? 0 : categoriaWeb['id']
      }

      try {
        const data = await InventarioService.createGrupo(object_request)
        stopLoader()
        if (data['error'] === true) {
          setToast(data['message'], ToastTypes.Warning)
        } else {
          if (funcion === 'Editar') {
            setToast(data['message'], ToastTypes.Success)
          } else {
            setToast(data['message'], ToastTypes.Success)
          }
          setCurrenTab('Buscar')
          if (selection) {
            resetSelected()
          }
        }
        handleReset()
        return await data
      } catch (error) {
        console.log(error)
        stopLoader()
        setToast(JSON.stringify(error), ToastTypes.Danger)
      }
    }
  }, [pertenece, categoriaWeb, descripcion])


  const handleForm = ({ row }: any) => {
    const { codigo, descripcion, estadoNombre, perteneceCodigo, perteneceNombre, categoriaWeb } = row
    setCodigo(codigo)
    setDescripcion(descripcion)
    if (estadoNombre === "ACTIVO") {
      setEstado(estadoLinea[0])
    } else {
      setEstado(estadoLinea[1])
    }

    tempValue.codigo = parseInt(perteneceCodigo)
    tempValue.descripcion = perteneceNombre

    setPertenece(tempValue)
    getCategoria(categoriaWeb)
  }


  const getCategoria = (value) => {
    const categoria = dataCategoriaWeb.filter(categoria => categoria.id === parseInt(value))
    setCategoriaWeb(categoria[0])
  }

  const onChangedPertenece = (value) => {
    setPertenece(value)
  }

  const handleReset = () => {
    if (codigo === 0) {
      setCodigo(0)
      setDescripcion('')
      setPertenece(null)
      setCategoriaWeb(dataCategoriaWeb[0])
      setEstado(estadoLinea[0])
    } else {
      handleForm(selection)
    }
  }

  return (
    <>
      <fieldset>
        <ValidationGroup ref={formSearchRef}>


          <CCardBody>


            <CRow className="row mt-1">
              <div className="dx-field-label col-lg-2 col-md-3 col-sm-12">
                {'Descripción:'}
              </div>
              <CustomCol className="dx-field-value" xs="12" md="4">
                <CCol md="12">
                  <TextBox
                    name="descripcion"
                    value={descripcion}
                    onValueChanged={({ value }: any) => setDescripcion(value)}
                    onEnterKey={handleSubmit}
                  >
                    <Validator>
                      <RequiredRule message={'Descripción: ' + sms['required']} />
                      <StringLengthRule message={'Descripción: ' + sms['max'].replace('%num_characters%', '75')} max={75} />
                      <StringLengthRule message={'Descripción: ' + sms['min'].replace('%num_characters%', '3')} min={3} />
                    </Validator>
                  </TextBox>
                </CCol>
              </CustomCol>
            </CRow>


            <CRow className="row mt-1">
              <div className="dx-field-label col-lg-2 col-md-3 col-sm-12">
                {'Pertenece:'}
              </div>
              <CustomCol className="dx-field-value" xs="12" md="4">
                <CCol md="12">
                  <BuscarGrupoLookUp
                    selected={pertenece.codigo}
                    onChanged={onChangedPertenece}
                  />
                </CCol>
                <div style={{ color: '#aaa' }}>
                  <small>{'Por defecto es 0, si no selecciona nada'}</small>
                </div>
              </CustomCol>
            </CRow>


            <CRow className="row mt-2">
              <div className="dx-field-label col-lg-2 col-md-3 col-sm-12">
                {'Categoría Web:'}
              </div>
              <CustomCol className="dx-field-value" xs="12" md="4">
                <CCol md="12">
                  <SelectBox
                    id={'selectedCategoriaWeb'}
                    keyExpr={'id'}
                    displayExpr={'name'}
                    onChange={(evt) => {
                      if (evt) {
                        setCategoriaWeb(evt);
                      } else {
                        setCategoriaWeb(null);
                      }
                    }}
                    options={dataCategoriaWeb}
                    placeholder={'Seleccionar'}
                    selected={categoriaWeb}
                    elementAttr={{
                      autocomplete: "nope",
                      autoComplete: "nope",
                    }}
                  ></SelectBox>
                </CCol>
              </CustomCol>
            </CRow>


            <CRow className="row mt-1">
              <div className="dx-field-label col-lg-2 col-md-3 col-sm-12">
                {'Estado:'}
              </div>
              <CustomCol className="dx-field-value" xs="12" md="4">
                <CCol md="12">
                  <SelectBox
                    id={'selectedEstadoGrupo'}
                    keyExpr={'value'}
                    displayExpr={'label'}
                    onChange={(evt) => {
                      if (evt) {
                        setEstado(evt);
                      } else {
                        setEstado(null);
                      }
                    }}
                    options={estadoLinea}
                    placeholder={'Elija una opción'}
                    selected={estado}
                    elementAttr={{
                      autocomplete: "nope",
                      autoComplete: "nope",
                    }}
                  ></SelectBox>
                </CCol>
              </CustomCol>
            </CRow>

            <CRow>
              <CCol lg="6" md="8" sm="12">
                <ValidationSummary id="summary"></ValidationSummary>
              </CCol>
            </CRow>
          </CCardBody>
        </ValidationGroup>
      </fieldset>
    </>
  )
}

export default Nuevo