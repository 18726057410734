import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CLink
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { LoaderInfo } from '../../../../store/types';
import LoadingIndicator from '../../../../views/componentes/loadingindicator/loadingindicator';
import BlockUi from '../../../../views/componentes/librerias/block-ui';
import { cilXCircle } from '@coreui/icons';

type CardContentProps = {
  childrenOptions: React.ReactElement, // Acciones (CREAR, EDITAR etc) para el header de los formularios
  childrenBody: React.ReactElement, // formulario body
  childrenFooter?: React.ReactElement, // Acciones (Reset, Cancelar etc) para el footer de los formularios
  headerTitle: string, // Titulo para mostrar en el subcard
  aplicacion: string // este texto se mostrara en dispositivos moviles.
  loader?: LoaderInfo,
  idComponent?: string | null
}

const defaultProps = {
  headerTitle: "Buscar",
}

const CardContent = (props: CardContentProps) => {
  const { loader } = props;

  const navigate = useNavigate();

  const redirectDash = () => {
    navigate('/dashboard');
  }

  return (
    <CRow>
      <CCol xs="12" md="12" className="mb-4">
        <CCard>
          <CCardHeader>
            <CRow className=" d-flex flex-nowrap" >
              <CCol>
                {props.childrenOptions}
              </CCol>

              <CCol sm={9} xs={9} className=" d-md-none d-lg-none d-xl-none order-1 p-2 " >
                <strong> {props.aplicacion} </strong>
              </CCol>

              <CCol sm={1} xs={1} className="order-2 p-2">
                <div className="card-header-actions">
                  <CLink className="card-header-action" onClick={() => redirectDash()}>
                    <CIcon icon={cilXCircle} />
                  </CLink>
                </div>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <div>
              <BlockUi tag="div" loader={LoadingIndicator} blocking={loader?.show ?? false} message={loader?.mensaje ?? ""}  >
                <div>
                  {props.childrenBody}
                </div>
              </BlockUi >
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
}

CardContent.defaultProps = defaultProps;

export default CardContent;