import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './folderTreeCSS.css';

class FolderToolbar extends Component {
  static propTypes = {
    deleteObj: PropTypes.func.isRequired,
    toggleAddingNewFile: PropTypes.func.isRequired,
  };

  render() {
    return (
      <div className={styles.folderToolbar}>
        <div className={styles.addButton} onClick={this.props.toggleAddingNewFile}>
          <FontAwesomeIcon
            size="lg"
            icon={['fal', "check-circle"]} style={{ marginLeft: '10px' }}
          />
					Add
					</div>
        <div className={styles.deleteButton} onClick={() => { this.props.deleteObj(); }}>
          <FontAwesomeIcon
            size="lg"
            icon={['fal', "trash-circle"]} style={{ marginLeft: '10px' }}
          />
					Delete
					</div>
      </div>
    );
  }

}

export default FolderToolbar;