import {combineReducers} from "redux";
import {TPaises} from "./types";
import {menuReducer} from "./menu.reducer";
import {tabsReducer} from "./tabs.reducer";
import {searchReducer} from "./search.reducer";
import {dataEditionReducer} from "./edit.reducer";

export const CatologosPaisesReducer = combineReducers<TPaises>({
  menu: menuReducer,
  tabs: tabsReducer,
  editData: dataEditionReducer,
  search: searchReducer,
});
