import React from 'react'
import { CBadge, CButton, CCol, CRow } from '@coreui/react-pro'
import { Button, RadioGroup, TextBox } from 'devextreme-react'
import CustomCol from '../../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../../views/componentes/labeledInput/labeledInput'
import RowContainer from '../../../../../../../../views/componentes/rowContainer/rowContainer'
import { DatosEdicionRed } from '../../../../types/types'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../../store/store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { setTabRed } from '../../../../store/editDataReducer'

interface IAdicionalessProps {
  tabId: string
  refForm: any
}

export const dhcpGruposDHCP = [
  { value: 'ESTATICA', label: 'Estática' },
  { value: 'DINAMICA', label: 'Dinámica' },
]

export const registadoGrupos = [
  { value: 0, label: 'Si' },
  { value: 1, label: 'No' },
]

export const nsGrupos = [
  { value: 'N', label: 'N' },
  { value: 'S', label: 'S' },
]

export const eoGrupos = [
  { value: 'E', label: 'E' },
  { value: 'O', label: 'O' },
]

const Red: React.FunctionComponent<IAdicionalessProps> = (props) => {
  const { tabId } = props
  const dispatch = useDispatch()

  const nodo = useSelector((state: RootState) => {
    return state.clientes.clientes.editData[tabId]?.tabs?.red?.nodo
  })
  const base = useSelector((state: RootState) => {
    return state.clientes.clientes.editData[tabId]?.tabs?.red?.base
  })
  const dhcp = useSelector((state: RootState) => {
    return state.clientes.clientes.editData[tabId]?.tabs?.red?.dhcp
  })
  const ip = useSelector((state: RootState) => {
    return state.clientes.clientes.editData[tabId]?.tabs?.red?.ip
  })
  const servicio = useSelector((state: RootState) => {
    return state.clientes.clientes.editData[tabId]?.tabs?.red?.servicio
  })
  const usuario = useSelector((state: RootState) => {
    return state.clientes.clientes.editData[tabId]?.tabs?.red?.usuario
  })
  const contrasena = useSelector((state: RootState) => {
    return state.clientes.clientes.editData[tabId]?.tabs?.red?.contrasena
  })
  const registrado = useSelector((state: RootState) => {
    return state.clientes.clientes.editData[tabId]?.tabs?.red?.registrado
  })
  const datosRedState = useSelector((state: RootState) => {
    return state.clientes.clientes.editData[tabId]?.tabs?.red
  })

  const onChangeNodo = React.useCallback((data) => {
    if (data.value !== null) {
      dispatch(setTabRed({
        key: tabId,
        data: {
          ...datosRedState,
          nodo: data.value
        }
      }))
    }
  }, [dispatch, tabId, datosRedState])

  const onChangeBase = React.useCallback((data) => {
    if (data.value !== null) {
      dispatch(setTabRed({
        key: tabId,
        data: {
          ...datosRedState,
          base: data.value
        }
      }))
    }
  }, [dispatch, tabId, datosRedState])

  const onChangeDhcp = React.useCallback((data) => {
    if (data.value !== null) {
      dispatch(setTabRed({
        key: tabId,
        data: {
          ...datosRedState,
          dhcp: data.value
        }
      }))
    }
  }, [dispatch, tabId, datosRedState])

  const onChangeServicio = React.useCallback((data) => {
    if (data.value !== null) {
      dispatch(setTabRed({
        key: tabId,
        data: {
          ...datosRedState,
          servicio: data.value
        }
      }))
    }
  }, [dispatch, tabId, datosRedState])

  const onChangeIp = React.useCallback((data) => {
    if (data.value !== null) {
      dispatch(setTabRed({
        key: tabId,
        data: {
          ...datosRedState,
          ip: data.value
        }
      }))
    }
  }, [dispatch, tabId, datosRedState])

  const onChangeUsuario = React.useCallback((data) => {
    if (data.value !== null) {
      dispatch(setTabRed({
        key: tabId,
        data: {
          ...datosRedState,
          usuario: data.value
        }
      }))
    }
  }, [dispatch, tabId, datosRedState])


  const onChangePassword = React.useCallback((data) => {
    if (data.value !== null) {
      dispatch(setTabRed({
        key: tabId,
        data: {
          ...datosRedState,
          contrasena: data.value
        }
      }))
    }
  }, [dispatch, tabId, datosRedState])



  const onChangeRegistrado = React.useCallback((data) => {
    if (data.value !== null) {
      dispatch(setTabRed({
        key: tabId,
        data: {
          ...datosRedState,
          registrado: data.value
        }
      }))
    }
  }, [dispatch, tabId, datosRedState])

  const onChangeNorteSur = React.useCallback((data) => {
    if (data.value !== null) {
      dispatch(setTabRed({
        key: tabId,
        data: {
          ...datosRedState,
          norteSur: data.value
        }
      }))
    }
  }, [dispatch, tabId, datosRedState])

  const onChangeEsteOeste = React.useCallback((data) => {
    if (data.value !== null) {
      dispatch(setTabRed({
        key: tabId,
        data: {
          ...datosRedState,
          esteOeste: data.value
        }
      }))
    }
  }, [dispatch, tabId, datosRedState])

  const onChangeDegns = React.useCallback((data) => {
    if (data.value !== null) {
      dispatch(setTabRed({
        key: tabId,
        data: {
          ...datosRedState,
          degns: data.value
        }
      }))
    }
  }, [dispatch, tabId, datosRedState])

  const onChangeMinns = React.useCallback((data) => {
    if (data.value !== null) {
      dispatch(setTabRed({
        key: tabId,
        data: {
          ...datosRedState,
          minns: data.value
        }
      }))
    }
  }, [dispatch, tabId, datosRedState])

  const onChangeSecns = React.useCallback((data) => {
    if (data.value !== null) {
      dispatch(setTabRed({
        key: tabId,
        data: {
          ...datosRedState,
          secns: data.value
        }
      }))
    }
  }, [dispatch, tabId, datosRedState])

  const onChangeDegeo = React.useCallback((data) => {
    if (data.value !== null) {
      dispatch(setTabRed({
        key: tabId,
        data: {
          ...datosRedState,
          degeo: data.value
        }
      }))
    }
  }, [dispatch, tabId, datosRedState])


  const onChangeMineo = React.useCallback((data) => {
    if (data.value !== null) {
      dispatch(setTabRed({
        key: tabId,
        data: {
          ...datosRedState,
          mineo: data.value
        }
      }))
    }
  }, [dispatch, tabId, datosRedState])

  const onChangeSeceo = React.useCallback((data) => {
    if (data.value !== null) {
      dispatch(setTabRed({
        key: tabId,
        data: {
          ...datosRedState,
          seceo: data.value
        }
      }))
    }
  }, [dispatch, tabId, datosRedState])

  const onChangeAltitud = React.useCallback((data) => {
    if (data.value !== null) {
      dispatch(setTabRed({
        key: tabId,
        data: {
          ...datosRedState,
          altitud: data.value
        }
      }))
    }
  }, [dispatch, tabId, datosRedState])


  return (
    <>
      <RowContainer>
        <CustomCol xs="12" md="3">
          <Labeled label="Nodo:">
            <CRow>
              <CCol md="8" xs="10">
                <TextBox
                  name="nodoRed"
                  value={nodo}
                  onValueChanged={(evt) => {
                    if (evt?.event !== undefined) {
                      onChangeNodo(evt)
                    }
                  }}
                  inputAttr={{
                    autocomplete: 'nope',
                  }}
                />
              </CCol>
              <CCol md="4" xs="2">
                <Button id="btnGoNodo" icon="arrowright" type="default" disabled={nodo.length > 0 ? false : true}
                  onClick={() => {
                    window.open(`http://${nodo}`, '_blank')
                  }}
                />
              </CCol>
            </CRow>
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="3">
          <Labeled label="Base:">
            <CRow>
              <CCol md="8" xs="10">
                <TextBox
                  name="base"
                  value={base}
                  onValueChanged={(evt) => {
                    if (evt?.event !== undefined) {
                      onChangeBase(evt)
                    }
                  }}
                  inputAttr={{
                    autocomplete: 'nope',
                  }}
                />
              </CCol>
              <CCol md="4" xs="2">
                <Button id="btnGoBase" icon="arrowright" type="default" disabled={base.length > 0 ? false : true}
                  onClick={() => {
                    window.open(`http://${base}`, '_blank')
                  }}
                />
              </CCol>
            </CRow>
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="3">
          <Labeled label="DHCP:">
            <RadioGroup
              name="dhcp"
              layout="horizontal"
              displayExpr="label"
              dataSource={dhcpGruposDHCP}
              value={dhcp}
              onValueChanged={(data) => {
                if (data !== null || data !== undefined) {
                  onChangeDhcp(data)
                }
              }}
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="3">
          <Labeled label="Estado:">
            <CBadge color="success" className="ms-2" style={{ fontSize: '10px' }}>
              {'ACTIVO'}
            </CBadge>
            <TextBox
              name="servicio"
              placeholder=""
              value={servicio}
              onValueChanged={(evt) => {
                if (evt?.event !== undefined) {
                  onChangeServicio(evt)
                }
              }}
              inputAttr={{
                autocomplete: 'nope',
              }}
            />
          </Labeled>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="3">
          <Labeled label="IP:">
            <CRow>
              <CCol md="8" xs="10">
                <TextBox
                  name="ip"
                  placeholder=""
                  value={ip}
                  onValueChanged={(evt) => {
                    if (evt?.event !== undefined) {
                      onChangeIp(evt)
                    }
                  }}
                  inputAttr={{
                    autocomplete: 'nope',
                  }}
                />
              </CCol>
              <CCol md="4" xs="2">
                <Button id="btnGoNodo" icon="arrowright" type="default" disabled={ip.length > 0 ? false : true}
                  onClick={() => {
                    window.open(`http://${ip}`, '_blank')
                  }}
                />
              </CCol>
            </CRow>
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="3">
          <Labeled label="Usuario:">
            <TextBox
              name="usuario"
              placeholder=""
              value={usuario}
              onValueChanged={(evt) => {
                if (evt?.event !== undefined) {
                  onChangeUsuario(evt)
                }
              }}
              inputAttr={{
                autocomplete: 'nope',
              }}
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="3">
          <Labeled label="Password:">
            <TextBox
              name="password"
              placeholder=""
              value={contrasena}
              onValueChanged={(evt) => {
                if (evt?.event !== undefined) {
                  onChangePassword(evt)
                }
              }}
              inputAttr={{
                autocomplete: 'nope',
              }}
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="3">
          <Labeled label="Registrado:">
            <RadioGroup
              name="registrados"
              layout="horizontal"
              displayExpr="label"
              dataSource={registadoGrupos}
              value={registrado}
              onValueChanged={(data) => {
                if (data !== null || data !== undefined) {
                  onChangeRegistrado(data)
                }
              }}
            />
          </Labeled>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol>

          <Labeled label="Geolocalización:" />
          <RowContainer className="mt-2 mb-2">
            <CustomCol>
              <RadioGroup name="nortesur" layout="horizontal" displayExpr="label" dataSource={nsGrupos} value={datosRedState.norteSur}
                onValueChanged={(data) => {
                  if (data !== null || data !== undefined) {
                    onChangeNorteSur(data)
                  }
                }} />
            </CustomCol>
            <CustomCol>
              <TextBox
                name="degns"
                value={datosRedState.degns ?? ''}
                placeholder=""
                onValueChanged={(evt) => {
                  if (evt?.event !== undefined) {
                    onChangeDegns(evt)
                  }
                }}
                inputAttr={{
                  autocomplete: 'nope',
                }}
              />
            </CustomCol>

            <CustomCol>
              <FontAwesomeIcon icon={['fas', 'o']} className="" />

            </CustomCol>
            <CustomCol>
              <TextBox
                name="minns"
                value={datosRedState.minns ?? ''}
                placeholder=""
                onValueChanged={(evt) => {
                  if (evt?.event !== undefined) {
                    onChangeMinns(evt)
                  }
                }}
                inputAttr={{
                  autocomplete: 'nope',
                }}
              />
            </CustomCol>
            <CustomCol>
              <FontAwesomeIcon icon={['fas', 'accent-grave']} className="" />
            </CustomCol>
            <CustomCol>
              <TextBox
                name="secns"
                value={datosRedState.secns ?? ''}
                placeholder=""
                onValueChanged={(evt) => {
                  if (evt?.event !== undefined) {
                    onChangeSecns(evt)
                  }
                }}
                inputAttr={{
                  autocomplete: 'nope',
                }}
              />
            </CustomCol>
            <CustomCol>
              <FontAwesomeIcon icon={['fas', 'accent-grave']} className="" />
              <FontAwesomeIcon icon={['fas', 'accent-grave']} className="" />
            </CustomCol>
          </RowContainer>

          <RowContainer className="mt-2 mb-2">
            <CustomCol>
              <RadioGroup name="esteoeste" layout="horizontal" displayExpr="label" dataSource={eoGrupos}
                value={datosRedState?.esteOeste}
                onValueChanged={(data) => {
                  if (data !== null || data !== undefined) {
                    onChangeEsteOeste(data)
                  }
                }}
              />
            </CustomCol>
            <CustomCol>
              <TextBox
                name="degeo"
                value={datosRedState?.degeo ?? ''}
                placeholder=""
                onValueChanged={(evt) => {
                  if (evt?.event !== undefined) {
                    onChangeDegeo(evt)
                  }
                }}
                inputAttr={{
                  autocomplete: 'nope',
                }}
              />
            </CustomCol>
            <CustomCol>
              <FontAwesomeIcon icon={['fas', 'o']} className="" />

            </CustomCol>
            <CustomCol>
              <TextBox
                name="mineo"
                value={datosRedState.mineo ?? ''}
                placeholder=""
                onValueChanged={(evt) => {
                  if (evt?.event !== undefined) {
                    onChangeMineo(evt)
                  }
                }}
                inputAttr={{
                  autocomplete: 'nope',
                }}
              />
            </CustomCol>
            <CustomCol>
              <FontAwesomeIcon icon={['fas', 'accent-grave']} className="" />

            </CustomCol>
            <CustomCol>
              <TextBox
                name="seceo"
                value={datosRedState?.seceo ?? ''}
                placeholder=""
                onValueChanged={(evt) => {
                  if (evt?.event !== undefined) {
                    onChangeSeceo(evt)
                  }
                }}
                inputAttr={{
                  autocomplete: 'nope',
                }}
              />
            </CustomCol>
            <CustomCol>
              <FontAwesomeIcon icon={['fas', 'accent-grave']} className="" />
              <FontAwesomeIcon icon={['fas', 'accent-grave']} className="" />

            </CustomCol>
          </RowContainer>

        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol className="mt-2 mb-2" >
          <CRow>
            <CCol lg="1" md="1" xs="12">
              {'Altitud: '}
            </CCol>
            <CCol lg="3" xs="3">
              <TextBox
                name="altitud"
                value={datosRedState?.altitud ?? ''}
                placeholder=""
                onValueChanged={(evt) => {
                  if (evt?.event !== undefined) {
                    onChangeAltitud(evt)
                  }
                }}
                inputAttr={{
                  autocomplete: 'nope',
                }}
              />
            </CCol>
          </CRow>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CCol className="d-flex justify-content-start">
          <CButton
            style={{ backgroundColor: '#337ab7' }}
            // onClick={() => {
            //   setNotificar(false)
            //   setMessageNotify("")
            // }}
            size="sm"
            active
            aria-pressed="true"
          >
            {'UBICAR EN GOOGLE MAPS'}
          </CButton>
        </CCol>
      </RowContainer>
    </>
  )
}

export default Red

export const defaultDatosEdicionRed: DatosEdicionRed = {
  nodo: '',
  base: '',
  dhcp: dhcpGruposDHCP[1],
  servicio: '',
  ip: '',
  usuario: '',
  contrasena: '',
  registrado: registadoGrupos[1],
  norteSurOptions: nsGrupos,
  norteSur: nsGrupos[1],
  degns: '',
  minns: '',
  secns: '',
  esteOesteOptions: eoGrupos,
  esteOeste: eoGrupos[1],
  degeo: '',
  mineo: '',
  seceo: '',
  altitud: ''
}