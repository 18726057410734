import React from 'react';
import PropTypes from 'prop-types';
import './folderTreeCSS.css'

class EditableName extends React.Component {
	static propTypes = {
		filename: PropTypes.string.isRequired,
		setMyName: PropTypes.func.isRequired,
		selected: PropTypes.number.isRequired,
	};

	constructor(props) {
		super(props);
		this.toggleEditing = this.toggleEditing.bind(this);
		this.handleChangeName = this.handleChangeName.bind(this);

		this.state = {
			editing: false,
			selected: false,
		};
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.selected !== state.selected) {
      return{selected: nextProps.selected === 1 ? 1: 0}
    }
    return null
  }

	toggleEditing() {
		this.setState(prevState => ({ editing: !prevState.editing }));
		if (this.state.editing) {						// TODO: this doesn't work
			this.textInput.focus();
		}
	}

	handleChangeName() {
		this.props.setMyName(this.textInput.value);
		this.toggleEditing();
	}

	render() {
		const name = (
			<span> {' ' + this.props.filename + ' '} </span>
		);

		return (
			<span> {name} </span>
		);
	}
}

export default EditableName;
