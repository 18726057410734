import React from 'react'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
} from '@coreui/react-pro'
import { ingresarChequesService } from '../../service/modalIngresarCheques.services'
import { useDispatch, useSelector } from 'react-redux'
import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box'
import { DateBox, Button as DateBoxButton } from 'devextreme-react/date-box'
import ValidationSummary from 'devextreme-react/validation-summary'
import { Validator, RequiredRule, AsyncRule } from 'devextreme-react/validator'
import NumberBox from 'devextreme-react/number-box'
import { SelectBox } from 'devextreme-react/select-box'
import ModalCuentasBancos from '../../../../bancos/pages/ccahorros/componentes/modalCuentasBancos'
import { setCurrentAction, setResetSeleccion, setCurrentFunction, setFormularioIngresoCheques } from '../../store/modalIngresoCheques'
import { RootState } from '../../../../../store/store'
import { OptionGrupos, ToastTypes } from '../../../../../store/types'
import { addToast } from '../../../../../store/toasterReducer'
import { changeLoader, setAlert } from '../../../../../store/reducers'
import { utilidades } from '../../../../../helpers/utilidades'
import { DateUtils, formatoFechasApi } from '../../../../../helpers/dateUtils'
import ValidationGroup from 'devextreme-react/validation-group';
import { consoleService } from '../../../../../services/console.service'

type NuevoProps = {
  codModulo: number
  totalValue: number
}
const millisecondsInDay = 24 * 60 * 60 * 1000

const defaultFPago = [{ value: 0, label: 'Sin Asignar' }]

const Nuevo = (props: NuevoProps) => {
  const { codModulo, totalValue } = props

  const modalIngresoChequesState = useSelector((state: RootState) => state.bancos.modalIngresoCheques.modal)
  const formularioIngresoCheques = useSelector((state: RootState) => state.bancos.modalIngresoCheques.formularioIngresoCheques)
  const seleccionado = useSelector((state: RootState) => state.bancos.modalIngresoCheques.seleccionado)

  const validationGroupRef = React.useRef<any>();

  const dispatch = useDispatch()

  const currentFunction = useSelector((state: RootState) => state.bancos.modalIngresoCheques.currentFunction)
  const currentAction = useSelector((state: RootState) => state.bancos.modalIngresoCheques.currentAction)
  const formasPagoSRI = useSelector((state: RootState) => state.bancos.modalIngresoCheques.formasPagoSRI)

  const [codcheque_ti, setCodcheque_ti] = React.useState<string>('')
  const [cheque_ti, setCheque_ti] = React.useState<string>('')
  const [registro_ca, setRegistro_ca] = React.useState<Date>(new Date())
  const [afavor_ti, setAfavor_ti] = React.useState<any>('')
  const [cobro_ca, setCobro_ca] = React.useState<any>(new Date().getTime())
  const [cuenta_ti, setCuenta_ti] = React.useState<any>('')
  const [showBancos, setShowBancos] = React.useState<boolean>(false)
  const [valor_ti, setValor_ti] = React.useState<number>(0)
  const [dpfpagosri, setDpfpagosri] = React.useState<Array<OptionGrupos>>(defaultFPago)
  const [fpagosri_cb, setFpagosri_cb] = React.useState<OptionGrupos | []>(defaultFPago[0])
  const [fpagosri_cbEnabled, setFpagosri_cbEnabled] = React.useState<boolean>(true)
  const [CBA_CODIGO, setCBA_CODIGO] = React.useState<number>(0)
  const [CBA_SALDO, setCBA_SALDO] = React.useState<number>(0)
  const [CODIGO, setCODIGO] = React.useState<number>(0)
  const [subcodigo, setSubcodigo] = React.useState<number>(7)
  const [valorsinretencion, setValorsinretencion] = React.useState<number>(0)
  const [estadoCheque, setEstadoCheque] = React.useState<number>(0)


  React.useEffect(() => {
    if (codModulo === 24) setValor_ti(totalValue)
  }, [])


  React.useEffect(() => {
    // setTotales(props.valor_ti)
    setValorsinretencion(formularioIngresoCheques.valorsinretencion)
  }, [props])

  React.useEffect(() => {
    if (formasPagoSRI.length > 0) {
      setDpfpagosri(formasPagoSRI)
      setFpagosri_cb(formasPagoSRI[0])
    } else {
      obtenerFormasDePago()
    }
  }, [])

  React.useEffect(() => {
    if (currentAction === 'Editar' || currentAction === 'Nuevo') {
      if (currentFunction === "Guardar") {
        dispatch(setCurrentFunction(""));
        handleSubmit()
      } else if (currentFunction === "Nuevo") {
        dispatch(setCurrentFunction(""));
        preparaFormNuevo()
      } else if (currentFunction === "Editar") {
        dispatch(setCurrentFunction(""));
        preparaFormEditar()
      } else if (currentFunction === "Deshacer") {
        dispatch(setCurrentFunction(""))
        if (currentAction === "Nuevo") {
          handleReset();
        } else if (currentAction === "Editar") {
          preparaFormEditar();
        }
      }
    }
  }, [currentFunction, currentAction])

  const handleReset = () => {
    setCheque_ti("");
    setValor_ti(0)
    preparaFormNuevo()
  }

  const preparaFormNuevo = async () => {
    await devuelveTotalTran(modalIngresoChequesState.parametros.asientoNumero);
    setCuenta_ti(modalIngresoChequesState.parametros.cuentaDescripcion);
    setValor_ti(modalIngresoChequesState?.parametros?.valor_ti ?? 0);
    setRegistro_ca(DateUtils.strDateToDate(modalIngresoChequesState.parametros.fechaRegistro, formatoFechasApi))
    setCobro_ca(DateUtils.strDateToDate(modalIngresoChequesState.parametros.fechaCobro, formatoFechasApi))
    setCBA_CODIGO(Number(modalIngresoChequesState.parametros.bancoCodigo))
    setCBA_SALDO(Number(modalIngresoChequesState.parametros.saldo))
    setCODIGO(Number(modalIngresoChequesState.parametros.ingresos))
  }

  const preparaFormEditar = async () => {
    const seleccionado_ = seleccionado['row'];
    setCodcheque_ti(seleccionado_['codigo'])
    setCuenta_ti(`${seleccionado_['cuentaBancoNumero']} - ${seleccionado_['nombreBanco']}`)
    setCheque_ti(seleccionado_['numero'])
    setRegistro_ca(DateUtils.strDateToDate(seleccionado_['registro'], formatoFechasApi))
    setCobro_ca(DateUtils.strDateToDate(seleccionado_['fechaCobro'], formatoFechasApi))
    setValor_ti(seleccionado_['valor'])
    setCBA_CODIGO(Number(seleccionado_['codigoCuentaBanco']))
    const resLoadBanco = await cargarBanco(seleccionado_['cuentaBancoCodigo']);
    setAfavor_ti(seleccionado_['aFavor'])
    console.log('restar')
    console.log('saldo', Number(resLoadBanco['auto']['saldo']))
    console.log('valor', Number(seleccionado_['valor']))
    const totales: number = Number(resLoadBanco['auto']['saldo']) - Number(seleccionado_['valor']);
    console.log('totales', Number(totales))
    dispatch(setFormularioIngresoCheques({ ...formularioIngresoCheques, total_ti: totales }))
  }

  const cargarBanco = async (codBanco: number) => {
    playLoader()
    const find = {
      codigoCuentaBanco: codBanco
    };
    try {
      const data = await ingresarChequesService.cargarBanco(find);
      stopLoader()
      if (data["error"] === false) {
        // setValor_ti(Number(data['auto'].toFixed(2)))
        const cuenta = `${data['auto']['numero']} ${data['auto']['bancoNombre']}`;
        setCuenta_ti(cuenta)
        setCBA_SALDO(data['auto']['saldo'])
      } else {
        setToast(data['message'], "warning");
      }
      return await data;
    } catch (error) {
      stopLoader()
      console.log("error", error);
      setToast(JSON.stringify(error), "danger");
    }
  }

  const devuelveTotalTran = async (asi_numero: number) => {
    playLoader()
    const find = {
      numeroAsiento: asi_numero
    };
    try {
      const data = await ingresarChequesService.devuelveTotalTran(find);
      stopLoader();
      if (data["error"] === false) {
        setValor_ti(Number(data['auto'].toFixed(2)))
        dispatch(setFormularioIngresoCheques({
          ...formularioIngresoCheques,
          valor_ti: Number(data['auto'].toFixed(2)),
          valorsinretencion: Number(data['auto'].toFixed(2))
        }))
      } else {
        setToast(data['message'], "warning");
      }
      return await data;
    } catch (error) {
      stopLoader();
      console.log("error", error);
      setToast(JSON.stringify(error), "danger");
    }
  }

  const setToast = (texto, type) => {
    let tipo: ToastTypes = ToastTypes.Info
    if (type === 'warning') {
      tipo = ToastTypes.Warning
    } else if (type === 'success') {
      tipo = ToastTypes.Success
    }
    if (type === 'info') {
      tipo = ToastTypes.Info
    }
    if (type === 'danger') {
      tipo = ToastTypes.Danger
    }
    dispatch(
      addToast({
        id: '',
        autoHide: 3500,
        title: 'Acatha',
        content: texto,
        fade: true,
        type: tipo
      })
    )
  }

  const playLoader = () => {
    dispatch(changeLoader({ show: true, mensaje: 'Cargando...' }))
  }

  const stopLoader = () => {
    dispatch(changeLoader({ show: false, mensaje: '' }))
  }

  const validarFormulario = async () => {
    const mensaje: string[] = [];
    if (cheque_ti === '') {
      mensaje.push("- Numero: Ingrese el número del documento.");
    }
    if (cobro_ca === '') {
      mensaje.push("- Fecha de cobro: Seleccione la fecha de cobro.");
    }
    if (CBA_CODIGO === 0) {
      mensaje.push("- Cuenta: Ingrese la cuenta.");
    }
    if (Number(valor_ti) == 0) {
      mensaje.push("- Valor: cantidad (0) no permitida.");
    }
    const total_ti: number = Number(formularioIngresoCheques.totales) + Number(formularioIngresoCheques.valor_ti);
    dispatch(setFormularioIngresoCheques({ ...formularioIngresoCheques, total_ti: Number(total_ti.toFixed(2)) }))

    if (Number(total_ti) > Number(formularioIngresoCheques.valorsinretencion)) {
      mensaje.push("- Total: el valor total suma una cantidad mayor al valor adeudado.");
    }

    return mensaje;

  }

  const handleSubmit = async () => {

    const result = validationGroupRef.current.instance.validate();
    if (result.isValid === false) {
      setToast("Tiene errores por favor verifíquelos.", "info");
      return false;
    } else {

      const err = await validarFormulario();
      console.log("err", err)

      if (err.length > 0) {
        setToast("Tiene errores por favor verifíquelos.", "info");
        dispatch(setAlert({
          alertTitle: "ácatha",
          show: true,
          mensaje: "Tiene errores por favor verifíquelos.",
          detalles: err
        }))
        return false;
      }

      playLoader()
      const data_request = {
        chequeCodigo: currentAction === "Nuevo" ? 0 : codcheque_ti, // CHE_CODIGO
        chequeNumero: cheque_ti, // CHE_NUMERO
        chequeFechaRegistro: DateUtils.dateToString(registro_ca, formatoFechasApi), // CHE_FREGISTRO
        chequeFechaCobro: DateUtils.dateToString(cobro_ca, formatoFechasApi), // CHE_FCOBRO
        chequeTransaccion: modalIngresoChequesState.parametros.tipoDocumento, // CHE_TRANSACCION
        chequeValor: valor_ti, // CHE_VALOR
        cuentaBancoCodigo: CBA_CODIGO, // CBA_CODIGO
        chequeFormaPago: subcodigo, // CHE_FORMAPAGO
        chequeAFavor: afavor_ti, // CHE_AFAVOR
        formaPagoSRICodigo: fpagosri_cb['value'] === 0 ? "00" : String(fpagosri_cb['value']), // FPS_CODIGO
        codigoTransaccion: CODIGO,
      }
      try {
        stopLoader()
        const data = await ingresarChequesService.crearCheque(data_request)

        if (data['error'] === true) {
          setToast(data['message'], 'warning')
        } else {
          if (currentAction === 'Editar') {
            setToast(data['message'], 'success')
            dispatch(setResetSeleccion(null))
          } else {
            setToast(data['message'], 'success')
          }
          //dispatch(setData([]));
          dispatch(setCurrentAction('Buscar'))
          dispatch(setCurrentFunction('Buscar'))
        }
        return await data
      } catch (error) {
        console.log('error', error)
        stopLoader()
        setToast(JSON.stringify(error), 'danger')
      }
    }
  }

  const onDateChangedRegistro = registro => {
    setRegistro_ca(registro.value)
  }

  const onDateChangedCobro = registro => {
    setCobro_ca(registro.value)
  }

  const obtenerFormasDePago = async () => {
    console.log("obtenerFormasDePago")
    try {
      const data = await obtenerFPagos()
      console.log("obtenerFormasDePago data", data)
      if (data.length > 0) {
        let optionsfpagos: Array<OptionGrupos>
        // eslint-disable-next-line prefer-const
        optionsfpagos = data.map(function (key) {
          return {
            value: Number(key?.codigo) ?? 0,
            label: String(key?.descripcion) ?? ""
          }
        })
        setDpfpagosri(optionsfpagos)
        setFpagosri_cb(optionsfpagos[0])
      } else {
        setDpfpagosri(defaultFPago)
        setFpagosri_cb(defaultFPago[0])
      }
      return await data
    } catch (error) {
      console.log('error', error)
    }
  }

  const obtenerFPagos = async () => {
    const dateServer = await utilidades.getServerCurrentDateAsString()
    try {
      const find = {
        formaPagoCodigo: 7,
        mensaje: 'Sin Asignar',
        fecha: dateServer['date']
      }
      const data = await ingresarChequesService.CargarCombosDisponibles(find)
      return await data
    } catch (error) {
      console.log('error', error)
    }
  }

  const modalCuentaBancos = () => {
    return (
      <ModalCuentasBancos
        show={showBancos}
        onClose={() => setShowBancos(!showBancos)}
        cargarCuentas={data => {
          setShowBancos(!showBancos)
          setCuenta_ti(data['cba_numero'] + ' ' + data['ban_nombre'])
          setCheque_ti(data['num_cheque'])
          setCBA_CODIGO(data['cba_codigo'])
        }}
        origen="cheques"
        codModulo={codModulo}
      />
    )
  }

  const verificarSeleccion = value => {
    const validador = 0
    return new Promise(resolve => {
      setTimeout(function () {
        if (value['value']['value'] !== undefined) {
          resolve(
            Number(value['value']['value']) > Number(validador) === true
              ? true
              : false
          )
        } else {
          resolve(true)
        }
      }, 1000)
    })
  }

  const validateAsyncSeleccion = datoSeleccion => {
    return verificarSeleccion(datoSeleccion)
  }

  const validateAsyncSeleccionNoNull = datoSeleccion => {
    return verificarSeleccionNoNull(datoSeleccion)
  }

  const verificarSeleccionNoNull = value => {
    return new Promise(resolve => {
      setTimeout(function () {
        if (value['value']['value'] !== undefined) {
          resolve((value['value']['value'] === '') === true ? false : true)
        } else {
          resolve(true)
        }
      }, 1000)
    })
  }

  return (
    <>
      {showBancos === true && modalCuentaBancos()}

      <CCard>
        <CCardHeader>
          <strong> {currentAction} </strong>
        </CCardHeader>
        <CCardBody>
          <CRow className='m-2 p-2'>
            <CCol lg='8' md='10' sm='12'>
              <ValidationGroup ref={validationGroupRef}>
                <CRow>
                  <CCol lg="8" md="8" sm="12">


                    <div className='dx-field'>
                      <div className='dx-field-label'>{'Cuenta:'}</div>
                      <div className='dx-field-value d-flex justify-content-start'>
                        <TextBox
                          name='cuenta_ti'
                          id='cuenta_ti'
                          value={cuenta_ti}
                          onValueChanged={(data) => setCuenta_ti(data.value)}
                          showClearButton={true}
                          inputAttr={{
                            autoFocus: true
                          }}
                        >
                          <TextBoxButton
                            name='agregarCuentaCheques'
                            location='after'
                            options={{
                              icon: 'plus',
                              text: '',
                              onClick: () => setShowBancos(true)
                            }}
                          />
                          <Validator>
                            <RequiredRule message='- Cuenta: Ingrese la cuenta.' />
                          </Validator>
                        </TextBox>
                      </div>
                    </div>
                    <div className='dx-field'>
                      <div className='dx-field-label'>{'Num. Documento:'}</div>
                      <div className='dx-field-value d-flex justify-content-start'>
                        <TextBox
                          name='cheque_ti'
                          id='cheque_ti'
                          value={cheque_ti}
                          onValueChanged={(data) => setCheque_ti(data.value)}
                          showClearButton={true}
                        >
                          <Validator>
                            <RequiredRule message='- Numero: Ingrese el número del documento.' />
                          </Validator>
                        </TextBox>
                      </div>
                    </div>
                    <div className='dx-field'>
                      <div className='dx-field-label'>{'Fecha Registro:'}</div>
                      <div className='dx-field-value d-flex justify-content-start'>
                        <DateBox
                          displayFormat='dd/MM/yyyy'
                          value={registro_ca}
                          stylingMode='outlined'
                          onValueChanged={onDateChangedRegistro}
                        >
                          <DateBoxButton
                            name='today'
                            location='before'
                            options={{
                              text: 'HOY',
                              onClick: () => {
                                setRegistro_ca(new Date())
                              }
                            }}
                          />
                          <DateBoxButton
                            name='prevDate'
                            location='before'
                            options={{
                              icon: 'spinprev',
                              stylingMode: 'text',
                              onClick: () => {
                                const x = DateUtils.add(registro_ca, { days: -1 })
                                setRegistro_ca(x)
                              }
                            }}
                          />
                          <DateBoxButton
                            name='nextDate'
                            location='after'
                            options={{
                              icon: 'spinnext',
                              stylingMode: 'text',
                              onClick: () => {
                                const x = DateUtils.add(registro_ca, { days: 1 })
                                setRegistro_ca(x)
                              }
                            }}
                          />
                          <DateBoxButton name='dropDown' />
                          <Validator>
                            <RequiredRule message='Seleccione la fecha de registro' />
                          </Validator>
                        </DateBox>
                      </div>
                    </div>
                    <div className='dx-field'>
                      <div className='dx-field-label'>{'Fecha Cobro:'}</div>
                      <div className='dx-field-value d-flex justify-content-start'>
                        <DateBox
                          displayFormat='dd/MM/yyyy'
                          value={cobro_ca}
                          stylingMode='outlined'
                          onValueChanged={onDateChangedCobro}
                        >
                          <DateBoxButton
                            name='today'
                            location='before'
                            options={{
                              text: 'HOY',
                              onClick: () => {
                                setCobro_ca(new Date().getTime())
                              }
                            }}
                          />
                          <DateBoxButton
                            name='prevDate'
                            location='before'
                            options={{
                              icon: 'spinprev',
                              stylingMode: 'text',
                              onClick: () => {
                                setCobro_ca(
                                  Number(cobro_ca) - millisecondsInDay
                                )
                              }
                            }}
                          />
                          <DateBoxButton
                            name='nextDate'
                            location='after'
                            options={{
                              icon: 'spinnext',
                              stylingMode: 'text',
                              onClick: () => {
                                setCobro_ca(
                                  Number(cobro_ca) + millisecondsInDay
                                )
                              }
                            }}
                          />
                          <DateBoxButton name='dropDown' />

                          <Validator>
                            <RequiredRule message='- Fecha de cobro: Seleccione la fecha de cobro.' />
                          </Validator>
                        </DateBox>
                      </div>
                    </div>

                    <div className='dx-field'>
                      <div className='dx-field-label'>{'A nombre de:'}</div>
                      <div className='dx-field-value d-flex justify-content-start'>
                        <TextBox
                          name='afavor_ti'
                          id='afavor_ti'
                          value={afavor_ti}
                          onValueChanged={(data) => setAfavor_ti(data.value)}
                          showClearButton={true}
                        >
                        </TextBox>
                      </div>
                    </div>
                    <div className='dx-field'>
                      <div className='dx-field-label'> {'Valor:'} </div>
                      <div className='dx-field-value d-flex justify-content-start'>
                        <NumberBox
                          value={valor_ti}
                          placeholder=''
                          format="#,##0.00"
                          onValueChanged={data => {
                            setValor_ti(Number(data.value))
                          }}
                        >
                          <Validator>
                            <RequiredRule message='- Valor: cantidad (0) no permitida.' />
                          </Validator>
                        </NumberBox>
                      </div>
                    </div>
                    <div className='dx-field'>
                      <div className='dx-field-label'>{'Forma Pago SRI:'}</div>
                      <div className='dx-field-value d-flex justify-content-start'>
                        <SelectBox
                          dataSource={dpfpagosri}
                          displayExpr='label'
                          searchEnabled={true}
                          searchMode={'contains'}
                          searchExpr={'label'}
                          searchTimeout={200}
                          minSearchLength={3}
                          showDataBeforeSearch={true}
                          placeholder='Seleccionar'
                          showClearButton={true}
                          defaultValue={fpagosri_cb}
                          value={fpagosri_cb}
                          onValueChanged={data => {
                            if (data.value) {
                              setFpagosri_cb(data.value)
                            } else {
                              setFpagosri_cb(dpfpagosri[0])
                            }
                          }}
                          readOnly={fpagosri_cbEnabled}
                        >
                          <Validator>
                            <RequiredRule message='- Forma de Pago SRI: elija una opción.' />
                            <AsyncRule
                              message='- Forma de Pago SRI: elija una opción.'
                              validationCallback={
                                validateAsyncSeleccionNoNull
                              }
                            />
                          </Validator>
                        </SelectBox>
                      </div>
                    </div>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol lg='10'>
                    <ValidationSummary id='summary'></ValidationSummary>
                  </CCol>
                </CRow>

              </ValidationGroup>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>

      <CRow>
        <CCol></CCol>
      </CRow>
    </>
  )
}

export default Nuevo
