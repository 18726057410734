import React, { useEffect, useState } from 'react'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput'
import { DataGrid, TextBox } from 'devextreme-react'
import { Column, Export, Pager, Paging } from 'devextreme-react/data-grid'
import { DesempenoListado, PerformaceDetail } from '../../types/types'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import {
  clearButtonClick,
  openTab,
  setButtons,
  setCurrentExecutingAction,
} from '../../store/tabsReducer'
import { useDispatch, useSelector } from 'react-redux'
import { performanceServices } from '../../services/desempeno.services'
import { RootState } from '../../../../../../store/store'
import { ToastTypes } from '../../../../../../store/types'
import { addToast } from '../../../../../../store/toasterReducer'
import {
  changeLoaderPerformance,
  setCurrentFunction,
} from '../../store/generalReducer'
import { initDatosEdicion } from '../../store/editDataReduce'
import { v4 as uuidv4 } from 'uuid'
import {
  fetchPerformances,
  selectResultadosBusquedaClientes,
  setCleanResult,
  setNombre,
} from '../../store/serachReducer'
import { getAllowedPageSizes } from '../../../../../../helpers/Helper'
import { TabState } from '../../../../../../store/genericTabTypes'
import ItemsCountIndicator from '../../../../../componentes/indicadorNroRegistros'

interface ISearchProps extends React.PropsWithChildren {
  tab: TabState<DesempenoListado>
  tabId: string
}

const Search: React.FunctionComponent<ISearchProps> = (props) => {
  const { tabId } = props

  const dispatch = useDispatch()
  const currentAction = useSelector(
    (state: RootState) => state.clientes.desempeno.general.currentFunction,
  )
  const resultados = useSelector(selectResultadosBusquedaClientes)
  const name = useSelector(
    (state: RootState) => state.clientes.desempeno.search.filter.nombre,
  )
  const filter = useSelector((state: RootState) => {
    return state.clientes.desempeno.search.filter
  })

  const [selectedPerfomance, setSelectedPerfomance] =
    useState<PerformaceDetail>(null)
  const desempeño = React.useRef<any>()

  const playLoader = React.useCallback(
    (message = 'Cargando...') => {
      dispatch(changeLoaderPerformance({ show: true, mensaje: message }))
    },
    [dispatch],
  )

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoaderPerformance({ show: false, mensaje: '' }))
  }, [dispatch])

  const onChangeName = React.useCallback(
    (data) => {
      if (data.event) dispatch(setNombre(data.value))
    },
    [dispatch],
  )

  const onSelectedPerformanceChanged = React.useCallback(
    ({ selectedRowsData, currentDeselectedRowKeys }) => {
      if (selectedRowsData && resultados.length > 0) {
        setSelectedPerfomance(selectedRowsData[0])
        if (currentDeselectedRowKeys.length === 0)
          dispatch(
            setButtons({
              tabKey: tabId,
              buttons: {
                Nuevo: true,
                Guardar: false,
                Editar: true,
                Eliminar: true,
                Buscar: true,
                Imprimir: false,
                Deshacer: true,
              },
            }),
          )
      }
    },
    [dispatch, resultados, tabId],
  )

  const onCleanResultFilter = React.useCallback(() => {
    dispatch(setCleanResult())
  }, [dispatch])

  const onHandleEdit = React.useCallback(async () => {
    const id = uuidv4()
    dispatch(
      initDatosEdicion({
        key: id,
        data: {
          codigo: selectedPerfomance.codigo,
          nombre: selectedPerfomance.nombre,
          estado: {
            codigo: selectedPerfomance.estado,
            descripcion: '',
          },
          guardado: false,
        },
      }),
    )
    dispatch(
      openTab({
        key: id,
        cliente: {
          codigo: selectedPerfomance.codigo,
          nombre: selectedPerfomance.nombre,
          estado: {
            codigo: selectedPerfomance.estado,
            descripcion: '',
          },
          guardado: false,
        },
      }),
    )
  }, [dispatch, selectedPerfomance])

  const onHandleRemove = React.useCallback(async () => {
    try {
      dispatch(
        setCurrentExecutingAction({
          tabKey: tabId,
          buttonType: ButtonTypes.delete,
        }),
      )
      playLoader('Eliminando . . .')
      const deletePerformance = await performanceServices.deletePerformance(
        selectedPerfomance.codigo,
      )
      if (!deletePerformance.error) {
        stopLoader()
        onCleanResultFilter()
        const toAny: any = fetchPerformances(filter)
        dispatch(toAny)
        dispatch(
          addToast({
            id: '',
            autoHide: true,
            content: deletePerformance.message ?? 'Registro eliminado XXX.',
            fade: true,
            title: 'Eliminar',
            type: ToastTypes.Success,
          }),
        )
      } else {
        dispatch(
          setCurrentExecutingAction({
            tabKey: tabId,
            buttonType: undefined,
          }),
        )
        stopLoader()
        dispatch(
          addToast({
            id: '',
            autoHide: true,
            content: deletePerformance.message ?? 'Error al eliminar.',
            fade: true,
            title: 'Eliminar',
            type: ToastTypes.Warning,
          }),
        )
      }
    } catch (error) {
      dispatch(
        setCurrentExecutingAction({
          tabKey: tabId,
          buttonType: undefined,
        }),
      )
      stopLoader()
      dispatch(
        addToast({
          id: '',
          autoHide: true,
          content:
            'Error al eliminar ' +
            (typeof error === 'string' ? error : JSON.stringify(error)),
          fade: true,
          title: 'Busqueda',
          type: ToastTypes.Danger,
        }),
      )
    }
  }, [
    dispatch,
    filter,
    onCleanResultFilter,
    playLoader,
    selectedPerfomance,
    stopLoader,
    tabId,
  ])

  const handleButtonClick = React.useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.delete:
          onHandleRemove()
          break
        case ButtonTypes.edit:
          onHandleEdit()
          break
        default:
          break
      }
      dispatch(setCurrentFunction(''))
      dispatch(clearButtonClick(tabId))
    },
    [dispatch, onHandleRemove, onHandleEdit, tabId],
  )

  const handleSearch = React.useCallback(() => {
    onCleanResultFilter()
    const toAny: any = fetchPerformances(filter)
    dispatch(toAny)
  }, [dispatch, filter, onCleanResultFilter])

  useEffect(() => {
    if (currentAction !== '') handleButtonClick(currentAction)
  }, [currentAction, handleButtonClick])

  return (
    <>
      <RowContainer>
        <CustomCol xs="12" md="3">
          <Labeled label="Nombre:">
            <TextBox
              value={name}
              onValueChanged={onChangeName}
              onEnterKey={handleSearch}
            />
          </Labeled>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="7">
          <ItemsCountIndicator items={resultados} />
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="7">
          <DataGrid
            ref={desempeño}
            keyExpr="codigo"
            focusedRowKey="codigo"
            id="tabCertificados"
            selection={{ mode: 'single' }}
            dataSource={resultados}
            showColumnLines={true}
            showRowLines={true}
            showBorders={true}
            columnResizingMode="widget"
            rowAlternationEnabled={true}
            onSelectionChanged={onSelectedPerformanceChanged}
            loadPanel={{
              enabled: true,
              height: 90,
              indicatorSrc: '',
              shading: false,
              shadingColor: '',
              showIndicator: true,
              showPane: true,
              text: 'Cargando...',
              width: 200,
            }}
          >
            <Paging defaultPageSize={20} />
            <Pager
              visible={resultados.length > 0 ? true : false}
              allowedPageSizes={getAllowedPageSizes(resultados)}
              displayMode={'full'}
              showPageSizeSelector={true}
              showInfo={true}
              showNavigationButtons={true}
            />
            <Export enabled={true} allowExportSelectedData={true} />

            <Column dataField="nombre" caption="Nombre" width="70%" />
            <Column
              dataField="descripcionEstado"
              caption="Estado"
              width="30%"
            />
          </DataGrid>
        </CustomCol>
      </RowContainer>
    </>
  )
}

export default Search
