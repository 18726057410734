import { DateUtils, formatoFechasApi } from "../../../../helpers/dateUtils";
import { RequestHelper } from "../../../../helpers/requestHelper";
import { SesionService } from "../../../../services/sesion.service";
import { TipoAgente } from "../../../../store/types";

export const tipoAgenteService = { getTipoAgente };

async function getTipoAgente(label: string): Promise<any> {
  try {
    const sesion = SesionService.getCurrentSesion()
    const data: any = {
      paisCodigo: sesion?.local?.ciudad?.paisCodigo ?? 1, // 1 -  Ecuador => por defecto
      label: label
    };
    const keysToCache: any = {
      ...data,
      dateKey: DateUtils.getCurrentDateAsString(formatoFechasApi),
      empresa: sesion?.empresa?.codigo,
    }
    const cacheKey = 'tiposAgentes' + JSON.stringify(keysToCache);
    const cached = sessionStorage.getItem(cacheKey);
    if (cached) {
      return { error: false, auto: JSON.parse(cached) };
    } else {
      const tipos = await RequestHelper.getAll<any>('tipoagente', 'listar', "", data);
      const opt: Array<TipoAgente> = [];
      if (tipos?.auto && tipos?.auto.length > 0) {
        for (const x of tipos.auto) {
          opt.push({
            codigo: parseInt(x.codigo) ?? -1,
            descripcion: x.descripcion ?? '',
            codigoTributario: x?.codigoTributario ?? -1
          })
        }
      }
      sessionStorage.setItem(cacheKey, JSON.stringify(opt));
      return tipos;
    }
  } catch (error) {
    return error;
  }
}

