import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AbonoState, AccountingEntry, SavePayment } from '../types/types'
import { DateUtils } from '../../../../../helpers/dateUtils'
import { Aplicacion } from '../../../store/types'
import { FETCH_STATUS, LoaderInfo, Seleccionado } from '../../../../../store/types'
import { DocCuentasCobrar } from '../services/docCuentasCobrar.service'

const optionsAbono = [
  { value: 0, label: 'Por Factura' },
  { value: 1, label: 'Por Cuotas de Varias Facturas' },
]

const initialState: AbonoState = {
  modulo: 'Clientes',
  currentAction: '',
  currentFunction: '',
  currentExecute: null,
  cliente: null,
  local: null,
  empresa: null,
  puntoVenta: null,
  aplicacion: Aplicacion.cuentasXCobrar,
  loader: {
    show: false,
    mensaje: '',
  },
  mensaje: '',
  incomeCode: 0,
  fecha: DateUtils.getCurrentDateAsString(),
  facturaCuotaVarias: optionsAbono[1],
  formasPago: null,
  concepto: '',
  vendedor: null,
  facturasPendientes: [],
  pagosMetodosGuardar: [],
  cuotasFactura: [],
  coutasPagar: [],
  facturaActual: null,
  totalPendientes: 0,
  cuotaActual: null,
  valorAbonos: 0,
  totalCuotas: 0,
  cuotaPagarActual: null,
  valorPagos: 0,
  status: FETCH_STATUS.IDDLE,
  seleccionado: {
    index: null,
    row: null,
    selectedRow: null,
  }
}

export const fetchAbonarFC = createAsyncThunk<
  Array<any>,
  number
>('ventas/invoicesReceivable', async (clienteCodigo) => {
  try {
    const abonarApi = await DocCuentasCobrar.getFacturasCobrar(clienteCodigo)
    return abonarApi
  } catch (error) {
    return Promise.reject(error)
  }
})

export const fetchAbonarFCEmpresa = createAsyncThunk<
  Array<any>,
  { empresa: number, fechInicio: string, fechFin: string }
>('ventas/invoicesReceivableforCompanies', async (objeto) => {
  try {
    const abonarEmpresaApi = await DocCuentasCobrar.getFacturasCobrarEmpresas(
      objeto.empresa,
      objeto.fechInicio,
      objeto.fechFin,
    )
    return abonarEmpresaApi
  } catch (error) {
    return Promise.reject(error)
  }
})

export const saveAbonar = createAsyncThunk<
  Array<any>,
  SavePayment
>('clientes/DocCuentasPorCobrar/paymentsSales/savePayment', async (objeto) => {
  try {
    const abonarEmpresaApi = await DocCuentasCobrar.savePayments(objeto)
    return abonarEmpresaApi
  } catch (error) {
    return Promise.reject(error)
  }
})


export const generateAcoountEntry = createAsyncThunk<
  any,
  AccountingEntry
>('contabilidad/entries/generateEntry', async (objeto) => {
  try {
    const accountEntryApi = await DocCuentasCobrar.generateAccountingEntry(objeto)
    return accountEntryApi
  } catch (error) {
    return Promise.reject(error)
  }
})

export const fetchCuotasVenta = createAsyncThunk<
  Array<any>,
  number
>('ventas/getSalesInstallments', async (codigo) => {
  try {
    const cuotasVentaApi = await DocCuentasCobrar.getCuotasVenta(codigo)
    return cuotasVentaApi
  } catch (error) {
    return Promise.reject(error)
  }
})

const abonosDocumentosCtasCobrarSlice = createSlice({
  name: 'documentosCtasCobrarAbonos',
  initialState: initialState,
  reducers: {
    setDatosAbonos(state, action: PayloadAction<AbonoState>) {
      state.modulo = action.payload.modulo
      state.currentAction = action.payload.currentAction
      state.currentFunction = action.payload.currentFunction
      state.cliente = action.payload.cliente
      state.local = action.payload.local
      state.empresa = action.payload.empresa
      state.aplicacion = action.payload.aplicacion
      state.loader = action.payload.loader
      state.pagosMetodosGuardar = action.payload.pagosMetodosGuardar
      state.incomeCode = action.payload.incomeCode
      state.fecha = action.payload.fecha
      state.facturaCuotaVarias = action.payload.facturaCuotaVarias
      state.formasPago = action.payload.formasPago
      state.concepto = action.payload.concepto
      state.vendedor = action.payload.vendedor
      state.facturasPendientes = action.payload.facturasPendientes
      state.cuotasFactura = action.payload.cuotasFactura
      state.coutasPagar = action.payload.coutasPagar
      state.facturaActual = action.payload.facturaActual
      state.totalPendientes = action.payload.totalPendientes
      state.cuotaActual = action.payload.cuotaActual
      state.puntoVenta = action.payload.puntoVenta
      state.valorAbonos = action.payload.valorAbonos
      state.totalCuotas = action.payload.totalCuotas
      state.cuotaPagarActual = action.payload.cuotaPagarActual
      state.valorPagos = action.payload.valorPagos
    },
    setInitData(state) {
      state.cuotasFactura = []
      state.facturasPendientes = []
      state.pagosMetodosGuardar = []
      state.fecha = DateUtils.getCurrentDateAsString()
      state.formasPago = null
      state.concepto = ''
      state.vendedor = null
      state.facturaCuotaVarias = optionsAbono[1]
    },
    setResetSeleccion(state) {
      const toAdd = {
        index: null,
        row: null,
        selectedRow: null,
      }
      state.seleccionado = toAdd
    },
    setSeleccionarDato(state, action: PayloadAction<Seleccionado>) {
      const toAdd = {
        index: action.payload.index,
        row: action.payload.row,
        selectedRow: action.payload.selectedRow,
      }
      state.seleccionado = toAdd
    },
    setCurrentAction(state, action: PayloadAction<string>) {
      state.currentAction = action.payload
    },
    changeLoaderAbonos(state, action: PayloadAction<LoaderInfo>) {
      state.loader = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAbonarFC.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchAbonarFC.fulfilled, (state, { payload }) => {
      state.facturasPendientes = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchAbonarFC.rejected, (state, { payload }) => {
      state.facturasPendientes = []
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
    builder.addCase(fetchAbonarFCEmpresa.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchAbonarFCEmpresa.fulfilled, (state, { payload }) => {
      state.facturasPendientes = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchAbonarFCEmpresa.rejected, (state, { payload }) => {
      state.facturasPendientes = []
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
    builder.addCase(saveAbonar.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(saveAbonar.fulfilled, (state, { payload }) => {
      state.currentExecute = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(saveAbonar.rejected, (state, { payload }) => {
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
    builder.addCase(fetchCuotasVenta.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchCuotasVenta.fulfilled, (state, { payload }) => {
      state.currentExecute = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchCuotasVenta.rejected, (state, { payload }) => {
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
    builder.addCase(generateAcoountEntry.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(generateAcoountEntry.fulfilled, (state, { payload }) => {
      state.currentExecute = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(generateAcoountEntry.rejected, (state, { payload }) => {
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
  }
})

export const { setDatosAbonos, setCurrentAction, setInitData, changeLoaderAbonos } = abonosDocumentosCtasCobrarSlice.actions
export const abonosDocumentosCtasCobrarReducer =
  abonosDocumentosCtasCobrarSlice.reducer
