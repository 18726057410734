import { RequestHelper } from '../../../../../helpers/requestHelper'
import { SesionService } from '../../../../../services/sesion.service'

export const asignacionesService = {
  obtenerTipoDescuentos,
  obtenerTiposDepreciacion,
  registrarAsignacionBancos,
  obtenerBancos,
  obtenerAsignacionesCC,
  obtenerConceptosRetencionIva,
  obtenerConceptosRetencion,
  cargarCombos,
  obtenerAsignacionesFPC,
  obtenerAsignaciones,
  registrarAsignacion,
  obtenerLocales,
  obtenerFormasPagoCompra,
  obtenerPuntosDeVentas,
  obtenerAsignacionesFPV,
  obtenerCombos,
  getTypeDescount,
  obtenerAsignacionesDescuentoNomina,
  registrarAsignacionItems,
}

async function getTypeDescount(objeto: any): Promise<any> {
  try {
    const obj = {
      ...objeto,
    }
    const data = await RequestHelper.get<any>(
      'contabilidad',
      'descountTypeList',
      '',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}

async function obtenerAsignaciones(objeto: any): Promise<any> {
  try {
    const obj = {
      ...objeto,
    }
    const data = await RequestHelper.get<any>(
      'contabilidad',
      'assignment/all',
      '',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}

async function obtenerAsignacionesCC(objeto: any): Promise<any> {
  try {
    const obj = {
      ...objeto,
    }
    const data = await RequestHelper.get<any>(
      'contabilidad',
      'assignment/costcenter_inventory/all',
      '',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}

async function registrarAsignacion(objeto: any): Promise<any> {
  try {
    const obj = {
      infoRegistro: {
        asignaciones: objeto,
      },
    }
    const data = await RequestHelper.postAll<any>(
      'proveedores/proveedores',
      'assignments/saveAll',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}

async function registrarAsignacionItems(
  objeto: any,
  lineaCodigo: any,
): Promise<any> {
  try {
    const obj = {
      infoRegistro: {
        asignaciones: objeto,
        item: lineaCodigo,
      },
    }
    const data = await RequestHelper.postAll<any>(
      'inventario/items',
      'assignments/save',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}

async function registrarAsignacionBancos(objeto: any): Promise<any> {
  try {
    const obj = {
      infoRegistro: objeto,
    }
    const data = await RequestHelper.postAll<any>(
      'contabilidad/assignment',
      'bank/save',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}

async function obtenerLocales(find): Promise<any> {
  const sesion = SesionService.getCurrentSesion()
  try {
    const obj = {
      codigoempresa: sesion.empresa.codigo,
      ...find,
    }
    const data = await RequestHelper.get<any>('local', 'cboxLoad', '', obj)
    return data
  } catch (error) {
    return error
  }
}
//  obtener asignaciones formas de pago ventas
async function obtenerAsignacionesFPV(item, tipo): Promise<any> {
  const sesion = SesionService.getCurrentSesion()
  try {
    const obj = {
      identificacion: sesion.usuario.cedula,
      item: item,
      tipo: tipo,
    }
    const data = await RequestHelper.get<any>(
      'contabilidad',
      'assignment/transactions/all',
      '',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}

async function obtenerAsignacionesFPC(item, tipo): Promise<any> {
  const sesion = SesionService.getCurrentSesion()
  try {
    const obj = {
      identificacion: sesion.usuario.cedula,
      item: item,
      tipo: tipo,
      TDE_ESTADO: 1,
    }
    const data = await RequestHelper.get<any>(
      'contabilidad',
      'assignment/transactions/all',
      '',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}

async function obtenerFormasPagoCompra(
  grupos: any = 3,
  todos: any = true,
  mensaje: string = '',
): Promise<any> {
  try {
    const obj: any = {
      grupo: grupos,
      mensaje,
    }

    if (todos !== '') {
      obj.all = todos
    }
    const data = await RequestHelper.get<any>(
      'generales',
      'subtypes/cboxLoadFP',
      '',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}

async function obtenerPuntosDeVentas(): Promise<any> {
  const sesion = SesionService.getCurrentSesion()
  try {
    const obj = {
      identificacion: sesion.usuario.cedula,
      mensaje: 'Todos',
    }
    const data = await RequestHelper.get<any>(
      'generales',
      'pointSale/comboLoad',
      '',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}

async function cargarCombos(consulta): Promise<any> {
  try {
    const obj = {
      ...consulta,
    }
    const data = await RequestHelper.get<any>('subtypes', 'cboxLoad', '', obj)
    return data
  } catch (error) {
    return error
  }
}

async function obtenerConceptosRetencion(consulta): Promise<any> {
  try {
    const data = await RequestHelper.get<any>(
      'contabilidad',
      'conceptosret/listar',
      '',
      consulta,
    )
    return data
  } catch (error) {
    return error
  }
}

async function obtenerConceptosRetencionIva(consulta): Promise<any> {
  try {
    const data = await RequestHelper.get<any>(
      'retentiontax/all',
      '',
      '',
      consulta,
    )
    return data
  } catch (error) {
    return error
  }
}

async function obtenerBancos(findBancos): Promise<any> {
  try {
    const obj = {
      ...findBancos,
    }
    const data = await RequestHelper.get<any>(
      'bancos',
      'bancos/getAll',
      '',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}

async function obtenerTiposDepreciacion(): Promise<any> {
  try {
    const obj = {}
    const data = await RequestHelper.get<any>(
      'contabilidad',
      'tipodepreciacion/listar',
      '',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}

async function obtenerTipoDescuentos(): Promise<any> {
  try {
    const obj = {}
    const data = await RequestHelper.get<any>(
      'contabilidad',
      'tipodescuento/listar',
      '',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}

async function obtenerCombos(tipoCodigo: number, sms: string): Promise<any> {
  try {
    const obj = {
      tipoCodigo: tipoCodigo,
      mensaje: sms,
    }
    const data = await RequestHelper.get<any>('subtypes', 'cboxLoad', '', obj)
    // let
    return data
  } catch (error) {
    return error
  }
}

async function obtenerAsignacionesDescuentoNomina(objeto: any): Promise<any> {
  try {
    const obj = {
      ...objeto,
    }
    const data = await RequestHelper.get<any>(
      'contabilidad',
      'assignment/payroll/getDiscount',
      '',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}
