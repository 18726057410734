import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Calendar, CalendarData, CalendarList } from "../types/types";
import { LoaderInfo } from '../../../../../../store/types';

const initialState: Calendar = {
  loader: {
    show: false,
    mensaje: '',
  },
  calendar: {
    habilitarEmpleado: false,
    empleado: '',
    ususarioCodigo: 0,
    verTodos: false,
    fecha: null,
    fechaActual: null,
    tipo: null,
    motivo: '',
    habilitarHoras: false,
    horario: null,
    horarioCodigo: 0,
    entrada: null,
    salida: null,
    vacacionPeriodo: '',
    codigo: 0,
    habilitarAgregar: true,
    habilitarQuitar: false,
    initial: true,
    horariosData: []
  },
  calendarBackup: {
    habilitarEmpleado: false,
    empleado: '',
    ususarioCodigo: 0,
    verTodos: false,
    fecha: null,
    fechaActual: null,
    tipo: null,
    motivo: '',
    habilitarHoras: false,
    horario: null,
    entrada: null,
    salida: null,
    vacacionPeriodo: '',
    codigo: 0,
    horarioCodigo: 0,
    habilitarAgregar: true,
    habilitarQuitar: false,
    horariosData: [],
    initial: true
  },
  resultados: [],
  seleccionado: null
}

const calendarSlice = createSlice({
  name: 'buscarCalendario',
  initialState: initialState,
  reducers: {
    setResults(state, action: PayloadAction<CalendarList[]>) {
      state.resultados = action.payload
    },
    setResultsReset(state) {
      state.resultados = []
    },
    setSeleccionarDato(state, action: PayloadAction<CalendarList>) {
      state.seleccionado = action.payload
    },
    setResetSeleccion(state) {
      state.seleccionado = null
    },
    changeData(state, action: PayloadAction<CalendarData>) {
      state.calendar.initial = action.payload.initial
      state.calendar.habilitarEmpleado = action.payload.habilitarEmpleado
      state.calendar.empleado = action.payload.empleado
      state.calendar.ususarioCodigo = action.payload.ususarioCodigo
      state.calendar.verTodos = action.payload.verTodos
      state.calendar.fecha = action.payload.fecha
      state.calendar.fechaActual = action.payload.fechaActual
      state.calendar.tipo = action.payload.tipo
      state.calendar.motivo = action.payload.motivo
      state.calendar.habilitarHoras = action.payload.habilitarHoras
      state.calendar.horario = action.payload.horario
      state.calendar.entrada = action.payload.entrada
      state.calendar.salida = action.payload.salida
      state.calendar.vacacionPeriodo = action.payload.vacacionPeriodo
      state.calendar.codigo = action.payload.codigo
      state.calendar.habilitarAgregar = action.payload.habilitarAgregar
      state.calendar.habilitarQuitar = action.payload.habilitarQuitar
      state.calendar.horariosData = action.payload.horariosData
      state.calendar.horarioCodigo = action.payload.horarioCodigo
    },
    changeDataBackup(state, action: PayloadAction<CalendarData>) {
      state.calendarBackup.habilitarEmpleado = action.payload.habilitarEmpleado
      state.calendarBackup.empleado = action.payload.empleado
      state.calendarBackup.ususarioCodigo = action.payload.ususarioCodigo
      state.calendarBackup.verTodos = action.payload.verTodos
      state.calendarBackup.fecha = action.payload.fecha
      state.calendarBackup.fechaActual = action.payload.fechaActual
      state.calendarBackup.tipo = action.payload.tipo
      state.calendarBackup.motivo = action.payload.motivo
      state.calendarBackup.habilitarHoras = action.payload.habilitarHoras
      state.calendarBackup.horario = action.payload.horario
      state.calendarBackup.entrada = action.payload.entrada
      state.calendarBackup.salida = action.payload.salida
      state.calendarBackup.vacacionPeriodo = action.payload.vacacionPeriodo
      state.calendarBackup.codigo = action.payload.codigo
      state.calendarBackup.habilitarAgregar = action.payload.habilitarAgregar
      state.calendarBackup.habilitarQuitar = action.payload.habilitarQuitar
      state.calendarBackup.horariosData = action.payload.horariosData
      state.calendarBackup.horarioCodigo = action.payload.horarioCodigo
      state.calendarBackup.initial = action.payload.initial
    },
    setChangeLoader(state, action: PayloadAction<LoaderInfo>) {
      state.loader = action.payload
    },
  }
})



export const {
  setResetSeleccion,
  changeData,
  changeDataBackup,
  setSeleccionarDato,
  setChangeLoader,
  setResults,
  setResultsReset,
} = calendarSlice.actions
export const calendarReducer = calendarSlice.reducer