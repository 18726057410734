import { sygnet } from './sygnet'
import { logo } from './logo'
import { logoNegative } from './logo-negative'
import {
  cilMoon,
  cilSun,
  cilApplicationsSettings,
  cilBell,
  cilEnvelopeOpen,
  cibYoutube,
  cilUser,
  cilHome,
  cilLockLocked,
  cilLockUnlocked,
  cilUserFollow,
  cilUserUnfollow,
  cilChartPie,
  cilBasket,
  cilSpeedometer,
  cilList,
  cilTouchApp,
  cilInfo,
  cilSpeech,
  cilSettings,
  cilCalendar,
  cilLocationPin,
  cibSkype,
  cilXCircle,
  cilChevronBottom,
  cilPeople,
  cilChevronRight,
  cilChevronTop,
  cilGlobeAlt,
  cifUs,
  cibCcMastercard,
  cifBr,
  cibCcVisa,
  cifIn,
  cibStripe,
  cifFr,
  cibPaypal,
  cifEs,
  cibGooglePlay,
  cifPl,
  cibCcAmex,
  cilCloudDownload,
  cilCloudUpload,
  cilLightbulb,
  cilChart,
  cilBarChart,
  cilAppsSettings,
  cilBuilding,
  cilTriangle,
  cibWhatsapp,
  cilScreenDesktop,
  cilPencil,
  cilMonitor,
  cilChevronLeft,
  cilGraph,
  cilActionUndo,
} from '@coreui/icons'
// import {
//   cibSkype,
//   cibFacebook,
//   cibTwitter,
//   cibLinkedin,
//   cibFlickr,
//   cibTumblr,
//   cibXing,
//   cibGithub,
//   cibStackoverflow,
//   cibYoutube,
//   cibDribbble,
//   cibInstagram,
//   cibPinterest,
//   cibVk,
//   cibYahoo,
//   cibBehance,
//   cibReddit,
//   cibVimeo,
//   cibCcMastercard,
//   cibCcVisa,
//   cibStripe,
//   cibPaypal,
//   cibGooglePay,
//   cibCcAmex
// } from '@coreui/icons'
// import {
//   cifUs,
//   cifBr,
//   cifIn,
//   cifFr,
//   cifEs,
//   cifPl
// } from '@coreui/icons'
// import {
//   cilTriangle,
//   cilAlignCenter,
//   cilAlignLeft,
//   cilAlignRight,
//   cilApplicationsSettings,
//   cilArrowBottom,
//   cilArrowRight,
//   cilArrowTop,
//   cilAsterisk,
//   cilBan,
//   cilBasket,
//   cilBell,
//   cilBold,
//   cilBookmark,
//   cilCalculator,
//   cilCalendar,
//   cilCloudDownload,
//   cilChartPie,
//   cilCheck,
//   cilChevronBottom,
//   cilChevronLeft,
//   cilChevronDoubleLeft,
//   cilChevronRight,
//   cilChevronTop,
//   cilCircle,
//   cilCheckCircle,
//   cilCode,
//   cilCommentSquare,
//   cilCreditCard,
//   cilCursor,
//   cilCursorMove,
//   cilDrop,
//   cilDollar,
//   cilEnvelopeClosed,
//   cilEnvelopeLetter,
//   cilEnvelopeOpen,
//   cilEuro,
//   cilEyedropper,
//   cilFile,
//   cilFullscreen,
//   cilFullscreenExit,
//   cilGlobeAlt,
//   cilGrid,
//   cilGraph,
//   cilHome,
//   cilInbox,
//   cilIndentDecrease,
//   cilIndentIncrease,
//   cilInputPower,
//   cilItalic,
//   cilJustifyCenter,
//   cilJustifyLeft,
//   cilLaptop,
//   cilLayers,
//   cilLightbulb,
//   cilList,
//   cilListNumbered,
//   cilListRich,
//   cilLocationPin,
//   cilLockLocked,
//   cilMagnifyingGlass,
//   cilMap,
//   cilMoon,
//   cilNotes,
//   cilOptions,
//   cilPaperclip,
//   cilPaperPlane,
//   cilPencil,
//   cilPeople,
//   cilPhone,
//   cilPrint,
//   cilPuzzle,
//   cilSave,
//   cilScrubber,
//   cilSettings,
//   cilShare,
//   cilShareAll,
//   cilShareBoxed,
//   cilShieldAlt,
//   cilSpeech,
//   cilSpeedometer,
//   cilSpreadsheet,
//   cilStar,
//   cilSun,
//   cilTags,
//   cilTask,
//   cilTrash,
//   cilTouchApp,
//   cilUnderline,
//   cilUser,
//   cilUserFemale,
//   cilUserFollow,
//   cilUserUnfollow,
//   cilX,
//   cilXCircle,
//   cilWarning,
//   cilSearch,
//   cilFilter,
//   cilFindInPage,
//   cilLowVision,
//   cilNoteAdd,
//   cilActionUndo,
//   cilClearAll,
//   cilStream,
//   cilInfo
// } from '@coreui/icons'

// import {
// } from '@coreui/icons'

export const icons = Object.assign(
  {},
  {
    sygnet,
    logo,
    logoNegative,
    cilMoon,
    cilSun,
    cilApplicationsSettings,
    cilBell,
    cilEnvelopeOpen,
    cibYoutube,
    cilUser,
    cilHome,
    cilLockLocked,
    cilLockUnlocked,
    cilUserFollow,
    cilUserUnfollow,
    cilChartPie,
    cilBasket,
    cilSpeedometer,
    cilList,
    cilTouchApp,
    cilInfo,
    cilSpeech,
    cilSettings,
    cilCalendar,
    cilLocationPin,
    cibSkype,
    cilXCircle,
    cilChevronBottom,
    cilPeople,
    cilChevronRight,
    cilGlobeAlt,
    cifUs,
    cibCcMastercard,
    cifBr,
    cibCcVisa,
    cifIn,
    cibStripe,
    cifFr,
    cibPaypal,
    cifEs,
    cibGooglePlay,
    cifPl,
    cibCcAmex,
    cilCloudDownload,
    cilCloudUpload,
    cilLightbulb,
    cilChevronTop,
    cilChart,
    cilBarChart,
    cilAppsSettings,
    cilBuilding,
    cilTriangle,
    cibWhatsapp,
    cilScreenDesktop,
    cilPencil,
    cilMonitor,
    cilChevronLeft,
    cilGraph,
    cilActionUndo,
  },
)

/*
{
  cilTriangle,
  cilAlignCenter,
  cilAlignLeft,
  cilAlignRight,
  cilApplicationsSettings,
  cilArrowBottom,
  cilArrowRight,
  cilArrowTop,
  cilAsterisk,
  cilBan,
  cilBasket,
  cilBell,
  cilBold,
  cilBookmark,
  cilCalculator,
  cilCalendar,
  cilCloudDownload,
  cilChartPie,
  cilCheck,
  cilChevronBottom,
  cilChevronLeft,
  cilChevronDoubleLeft,
  cilChevronRight,
  cilChevronTop,
  cilCircle,
  cilCheckCircle,
  cilCode,
  cilCommentSquare,
  cilCreditCard,
  cilCursor,
  cilCursorMove,
  cilDrop,
  cilDollar,
  cilEnvelopeClosed,
  cilEnvelopeLetter,
  cilEnvelopeOpen,
  cilEuro,
  cilEyedropper,
  cilFile,
  cilFullscreen,
  cilFullscreenExit,
  cilGlobeAlt,
  cilGraph,
  cilGrid,
  cilHome,
  cilInbox,
  cilIndentDecrease,
  cilIndentIncrease,
  cilInputPower,
  cilItalic,
  cilJustifyCenter,
  cilJustifyLeft,
  cilLaptop,
  cilLayers,
  cilLightbulb,
  cilList,
  cilListNumbered,
  cilListRich,
  cilLocationPin,
  cilLockLocked,
  cilMagnifyingGlass,
  cilMap,
  cilMoon,
  cilNotes,
  cilOptions,
  cilPaperclip,
  cilPaperPlane,
  cilPencil,
  cilPeople,
  cilPhone,
  cilPrint,
  cilPuzzle,
  cilSave,
  cilScrubber,
  cilSettings,
  cilShare,
  cilShareAll,
  cilShareBoxed,
  cilShieldAlt,
  cilSpeech,
  cilSpeedometer,
  cilSpreadsheet,
  cilStar,
  cilSun,
  cilTags,
  cilTask,
  cilTrash,
  cilTouchApp,
  cilUnderline,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilUserUnfollow,
  cilX,
  cilXCircle,
  cilWarning,
  cilSearch,
  cilFilter,
  cilFindInPage,
  cilLowVision,
  cilNoteAdd,
  cilActionUndo,
  cilClearAll,
  cilStream,
  cilInfo
}, {
  cifUs,
  cifBr,
  cifIn,
  cifFr,
  cifEs,
  cifPl
}, {
  cibSkype,
  cibFacebook,
  cibTwitter,
  cibLinkedin,
  cibFlickr,
  cibTumblr,
  cibXing,
  cibGithub,
  cibStackoverflow,
  cibYoutube,
  cibDribbble,
  cibInstagram,
  cibPinterest,
  cibVk,
  cibYahoo,
  cibBehance,
  cibReddit,
  cibVimeo,
  cibCcMastercard,
  cibCcVisa,
  cibStripe,
  cibPaypal,
  cibGooglePay,
  cibCcAmex
}
*/
