import React from 'react';
import { FilaPruebaNC } from './store/types';
import { useDispatch, useSelector } from 'react-redux'
import { setDetalleAsiento } from "../../store/diarioReducer";
import { Column, Lookup, RequiredRule, NumericRule, DataGrid, Editing, FilterRow, HeaderFilter, Summary, TotalItem, Button } from 'devextreme-react/data-grid';
import { CRow, CCol } from '@coreui/react-pro'
import { utilidades } from '../../../../../../helpers/utilidades';
import { cuentasService as planDeCuentas } from "../../../planCuentas/services/cuentas.service"
import { RootState } from '../../../../../../store/store';
import { OptionGrupos } from '../../../../../../store/types';
import { consoleService } from '../../../../../../services/console.service';

interface IDetalleProps extends React.PropsWithChildren {
  asientosDetalle: Array<FilaPruebaNC>, // ItemDetalle - FilaPruebaNC
  // eslint-disable-next-line no-unused-vars
  retornarIndex: (val) => void,
  // eslint-disable-next-line no-unused-vars
  handleClickRemove: (index) => void
  // eslint-disable-next-line no-unused-vars
  returnDetalle: (data) => void
  // eslint-disable-next-line no-unused-vars
  setToast: (texto, type) => void
  playLoader: () => void
  stopLoader: () => void,
  detalleAsiento: Array<any>
  origenModulo?: number
}

const Detalle: React.FC<IDetalleProps> = (props) => {
  const { retornarIndex, asientosDetalle, setToast, playLoader, stopLoader, detalleAsiento, returnDetalle, origenModulo } = props;
  const dataGrid = React.useRef<any>();

  const dispatch = useDispatch()
  const localesAll = useSelector((state: RootState) => state.shared.providers.locales);

  const [registros, setRegistros] = React.useState<Array<any>>([]);
  const [centroCostos, setCentroCostos] = React.useState<Array<OptionGrupos>>([])

  const clearFilter = React.useCallback(() => {
    dataGrid.current.instance.clearSorting();
    dataGrid.current.instance.clearFilter();
  }, []);

  const llenarDataRegistros = React.useCallback((dataReg) => {
    clearFilter();
    let item: any = []
    try {
      if (origenModulo === 24 || origenModulo === 25 || origenModulo === 114) {
        item = dataReg.map(function (key, i) {
          return {
            ...key,
            ORDEN: i,
            FILA: i + 1,
            PLA_CODIGO: Number(key['planCuentasCodigo']) ?? 0,
            PLA_NUMERO: String(key['planCuentasNumero']),
            PLA_DESCRIPCION: String(key['planCuentasDescripcion']),
            LOC_CODIGO: Number(key['codigoLocal']) === -1 ? localesAll[0]?.codigo : Number(key['codigoLocal']),
            TRA_REFERENCIA: String(key['transaccionReferencia']),
            TRA_DEBE: Number(key['transaccionDebe']),
            TRA_HABER: Number(key['transaccionHaber']),
          }
        })
      } else {

        item = dataReg.map(function (key, i) {
          return {
            ...key,
            ORDEN: i,
            FILA: i + 1,
            PLA_CODIGO: Number(key['PLA_CODIGO']) ?? 0,
            PLA_NUMERO: String(key['PLA_NUMERO']),
            PLA_DESCRIPCION: String(key['PLA_DESCRIPCION']),
            LOC_CODIGO: Number(key['LOC_CODIGO']),
            TRA_REFERENCIA: String(key['TRA_REFERENCIA']),
            TRA_DEBE: Number(key['TRA_DEBE']),
            TRA_HABER: Number(key['TRA_HABER']),
          }
        })
      }
      console.log(item, 'res')
      setRegistros(item.map(x => utilidades.unfreeze(x)))

    } catch (error) {
      consoleService.error("on llenarDataRegistros", error)
    }
  }, [clearFilter, origenModulo]);


  const copiarRegistrosToEstado = React.useCallback(async () => { // FilaPruebaNC
    const _data = registros.slice(0);
    setRegistros(_data)
    dispatch(setDetalleAsiento(_data.map(x => utilidades.unfreeze(x))));
  }, [registros, dispatch]);


  const onSelectionChanged = React.useCallback((selectionChanged) => {
    retornarIndex(selectionChanged['selectedRowKeys'][0])
  }, [retornarIndex]);

  const onRowRemoved = React.useCallback((e) => {
    if (e.key !== "") {
      const _detalles = detalleAsiento.slice(0);

      const indexRegistros = _detalles.findIndex((item: any) => {
        return item.id === e.key
      });
      _detalles.splice(indexRegistros, 1);

      returnDetalle(_detalles.map(x => utilidades.unfreeze(x)))

    }
  }, [detalleAsiento, returnDetalle]);

  const cloneIconClick = React.useCallback((clone) => {

  }, []);

  const onRowUpdated = React.useCallback(() => {
    copiarRegistrosToEstado()
  }, [copiarRegistrosToEstado]);

  const onRowUpdating = React.useCallback(async (data) => {

    const detalleUnfreeze = detalleAsiento.map(x => utilidades.unfreeze(x));
    try {

      consoleService.log("onRowUpdating", data)
      let keyTable: string = "";
      Object.keys(data['newData']).map(function (label) {
        consoleService.log("label", label)
        keyTable = label
      })

      let reg: any = detalleUnfreeze.slice(0);

      reg = reg.map(x => utilidades.unfreeze(x));

      const indexRegistros = reg.findIndex((item: any) => {
        return item.id === data['oldData']['id']
      });

      const updateTransaccion: any = {
        ...data.oldData,
        id: data['oldData']['id'],
        LOC_CODIGO: data['oldData']['LOC_CODIGO'],
        LOC_NOMBRE: data['oldData']['LOC_NOMBRE'],
        ORDEN: data['oldData']['ORDEN'],
        PLA_CODIGO: data['oldData']['PLA_CODIGO'],
        PLA_DESCRIPCION: data['oldData']['PLA_DESCRIPCION'],
        PLA_NUMERO: data['oldData']['PLA_NUMERO'],
        TRA_DEBE: data['oldData']['TRA_DEBE'],
        TRA_HABER: data['oldData']['TRA_HABER'],
        TRA_REFERENCIA: data['oldData']['TRA_REFERENCIA'],
        TIPO_TRANSACCION: data['oldData']['TIPO_TRANSACCION'],
      }

      if (keyTable.includes("LOC_CODIGO")) {
        if (data['oldData']['LOC_CODIGO'] !== data['newData']['LOC_CODIGO']) {
          updateTransaccion.LOC_CODIGO = data['newData']['LOC_CODIGO'];
        }
      }
      if (keyTable.includes("TRA_REFERENCIA")) {
        if (data['oldData']['TRA_REFERENCIA'] !== data['newData']['TRA_REFERENCIA']) {
          updateTransaccion.TRA_REFERENCIA = data['newData']['TRA_REFERENCIA'];
        }
      }
      if (keyTable.includes("TRA_DEBE")) {
        if (data['oldData']['TRA_DEBE'] !== data['newData']['TRA_DEBE']) {
          updateTransaccion.TRA_DEBE = data['newData']['TRA_DEBE'];
          updateTransaccion.TRA_HABER = 0;
        }
      }
      if (keyTable.includes("TRA_HABER")) {
        if (data['oldData']['TRA_HABER'] !== data['newData']['TRA_HABER']) {
          updateTransaccion.TRA_DEBE = 0;
          updateTransaccion.TRA_HABER = data['newData']['TRA_HABER'];
        }
      }
      if (keyTable === "PLA_NUMERO") {
        if (data['oldData']['PLA_NUMERO'] !== data['newData']['PLA_NUMERO']) {
          const obj: any = {
            numero: data['newData']['PLA_NUMERO'],
          }

          playLoader()
          const item = await planDeCuentas.obtenerCuentaPorNumero(obj);
          if (item['error'] === false) {
            //detalle.setItem(item);
            consoleService.log("auto", item['auto'])
            if (item['auto'].length === 1) {
              consoleService.log('length 1', item['auto'].length)

              updateTransaccion['PLA_CODIGO'] = item['auto'][0]['codigo'];
              updateTransaccion['PLA_NUMERO'] = item['auto'][0]['numero'];
              updateTransaccion['PLA_DESCRIPCION'] = item['auto'][0]['descripcion'];
              updateTransaccion['PLA_DESCIDIOMA'] = item['auto'][0]['descripcionIdioma'];
              consoleService.log("reg 1: ", reg)

              // setRegistros(reg)
              // copiarRegistrosToEstado()
              stopLoader()

            } else if (item['auto'].length > 1) {

              consoleService.log('length +1', item['auto'].length)

              updateTransaccion['PLA_CODIGO'] = item['auto'][0]['codigo'];
              updateTransaccion['PLA_NUMERO'] = item['auto'][0]['numero'];
              updateTransaccion['PLA_DESCRIPCION'] = item['auto'][0]['descripcion'];
              updateTransaccion['PLA_DESCIDIOMA'] = item['auto'][0]['descripcionIdioma'];
              consoleService.log("reg +1: ", reg)
              // setRegistros(reg)
              // copiarRegistrosToEstado()
              stopLoader()
            } else {
              stopLoader()
              setToast('No se encuentra una cuenta con código ' + data['newData']['PLA_NUMERO'], "warning");
              consoleService.log('length 0')
              updateTransaccion['PLA_CODIGO'] = "";
              updateTransaccion['PLA_NUMERO'] = "";
              updateTransaccion['PLA_DESCRIPCION'] = "";
              updateTransaccion['PLA_DESCIDIOMA'] = "";
              consoleService.log("else if", reg)
              // setRegistros(reg)
              // copiarRegistrosToEstado()
            }

          } else {
            stopLoader()
            setToast('No se encuentra una cuenta con código ' + data['newData']['PLA_NUMERO'], "warning");
            updateTransaccion['PLA_CODIGO'] = "";
            updateTransaccion['PLA_NUMERO'] = "";
            updateTransaccion['PLA_DESCRIPCION'] = "";
            updateTransaccion['PLA_DESCIDIOMA'] = "";
            consoleService.log(reg)
            // setRegistros(reg)
            // copiarRegistrosToEstado()
          }
        }
      }

      reg[indexRegistros] = { ...updateTransaccion };
      returnDetalle(reg)
    } catch (error) {
      consoleService.error(error)
    }
  }, [
    detalleAsiento, playLoader, setToast, stopLoader,
    returnDetalle
  ]);

  const generarAllLocales = React.useCallback((l) => {
    console.log(l)
    setCentroCostos(l)
  }, []);

  React.useEffect(() => {
    generarAllLocales(localesAll)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localesAll])

  React.useEffect(() => {
    if (asientosDetalle.length > 0) {
      llenarDataRegistros(asientosDetalle)
    } else {
      setRegistros([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asientosDetalle])

  return (
    <>
      <CRow>
        <CCol>
          <DataGrid
            ref={dataGrid}
            selection={{ mode: 'single' }}
            hoverStateEnabled={true}
            dataSource={registros}
            showBorders={true}
            showRowLines={true}
            onSelectionChanged={onSelectionChanged}
            columnResizingMode={'nextColumn'}
            onRowUpdated={onRowUpdated}
            keyExpr={"ORDEN"}
            onRowUpdating={onRowUpdating}
            allowColumnResizing={true}
            onRowRemoved={onRowRemoved}
          >
            <FilterRow visible={true} />
            <HeaderFilter visible={true} />
            <Editing
              mode="row"
              useIcons={true}
              allowUpdating={true}
              allowDeleting={true}
            />
            <Column type="buttons" width={110}>
              <Button name="edit" />
              <Button name="delete" />
              <Button name="clone" onClick={cloneIconClick} />
            </Column>
            <Column dataField='FILA' allowEditing={false} caption='Fila' width='8%' />
            <Column dataField='PLA_NUMERO' allowEditing={true} caption='Cuenta' width='12%' >
              <RequiredRule message="Este campo es requerido" />
            </Column>
            <Column dataField='PLA_DESCRIPCION' allowEditing={false} caption='Descripción' width='20%'>
            </Column>
            <Column dataField='LOC_CODIGO' caption='Lugar' width='20%' >
              <Lookup dataSource={centroCostos} displayExpr="nombre" valueExpr="codigo" />
            </Column>
            <Column dataField='TRA_REFERENCIA' allowSearch={false} caption='Referencia' width='20%' />
            <Column dataField='TRA_DEBE' caption='Debe' width='10%' >
              <RequiredRule message="Este campo es requerido" />
              <NumericRule message="Este campo debe ser numérico" />
            </Column>
            <Column dataField='TRA_HABER' caption='Haber' width='10%' >
              <NumericRule message="Este campo debe ser numérico" />
              <RequiredRule message="Este campo es requerido" />
            </Column>
            <Summary>
              <TotalItem
                column="ASI_NUMERO"
                summaryType="count"
                customizeText={(data) => {
                  return `${data.value}`;
                }} />
              <TotalItem
                column="TRA_DEBE"
                summaryType="sum"
                valueFormat="currency"
              />
              <TotalItem
                column="TRA_HABER"
                summaryType="sum"
                valueFormat="currency" />
            </Summary>
          </DataGrid>
        </CCol>
      </CRow>
    </>
  );
};


export default React.memo(Detalle);