import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { configCalendario } from '../types/types'

const initialState: configCalendario = {
  aplicacion: 0,
}

const configuracionCalendario = createSlice({
  name: 'configCalendario',
  initialState: initialState,
  reducers: {
    setAplicacionModulo(state, acion: PayloadAction<number>) {
      state.aplicacion = acion.payload
    },

  },
})

export const configuracionesCalebdario = configuracionCalendario.reducer
export const { setAplicacionModulo } = configuracionCalendario.actions
