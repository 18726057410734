import React from 'react';
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow, CButton, CTooltip
} from '@coreui/react-pro'
import Labelgroup from '../../../../../../views/componentes/Labelgroup'
import { cuentasService } from "../../services/cuentas.service"
import { cuentasService as planDeCuentas } from "../../../planCuentas/services/cuentas.service"
import { ToastTypes } from '../../../../../../store/types';
import { useDispatch, useSelector } from 'react-redux'
import { setCargarCuentaContable, setCurrentFunction, setCurrentAccion, setResetSeleccion, setData, setDetalleAsiento, changeLoaderDiario } from "../../store/diarioReducer"
import { setData as setCuentasPlan, setDataBack as setCuentasPlanBack } from "./../../../planCuentas/modalCuentas/store/modalCuentasReducer"
import { RootState } from '../../../../../../store/store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setModal } from "../../../planCuentas/store/cuentasReducer"
import Detalle from "../tabladetalle"
import Modalform from "../../../../../../views/componentes/modalform"
import ValidationGroup from 'devextreme-react/validation-group';
import { DateBox, Button as DateBoxButton } from 'devextreme-react/date-box';
import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box';
import { NumberBox } from 'devextreme-react/number-box';
import { CheckBox } from 'devextreme-react/check-box';
import RadioGroup from "devextreme-react/radio-group";
import { Validator, RequiredRule, AsyncRule } from "devextreme-react/validator";
import { TextArea } from 'devextreme-react/text-area';
import ValidationSummary from "devextreme-react/validation-summary";
import { DateUtils, formatoFechasApi } from '../../../../../../helpers/dateUtils';
import { setPeriodosContables } from "./../../../../general/store/generalReducers"
import { Lookup } from 'devextreme-react/lookup';
import { utilidades } from '../../../../../../helpers/utilidades';
import { v4 as uuidv4 } from 'uuid';
import { GlobalLabel } from '../../../../../../views/componentes/globalLabel/globalLabel';
import { fetchAAsientoDetalle } from '../../../../../clientes/pages/docsCtasXCobrar/store/anticiposReducer';
import { consoleService } from '../../../../../../services/console.service';
import ProjectsLookUp from '../../../../../../containers/component/sharedProviders/projets/projectsLookUp/projectsLookUp';
import { Local, Project } from '../../../../../shared/types/types';
import { atFocus } from '../../../../../../helpers/Helper';
import { EAplicationsAcatha } from '../../../../../../store/enumsAplication';
import { getIndexProvider } from '../../../../../shared/helpers/funciones';

type NuevoProps = {
  origenModulo?: number
  setToast: (text: string, type: string | ToastTypes) => void
  playLoader: () => void
  stopLoader: () => void
}

const operacionesGrupos = [
  {
    value: 0,
    label: "Debe",
  },
  {
    value: 1,
    label: "Haber",
  },
];

const Nuevo = (props: NuevoProps) => {

  const { origenModulo, setToast, playLoader, stopLoader } = props
  const localSession = useSelector((state: RootState) => { return state.global.session?.local });

  const dispatch = useDispatch()

  const inputValor = React.useRef<any>()
  const inputNumCuenta = React.useRef<any>()
  const inputRefDesc = React.useRef<any>()
  const validationGroupRef = React.useRef<any>();

  const cargarCuentaContable = useSelector((state: RootState) => state.contabilidad.diarioGeneral.cargarCuentaContable);
  const periodosContables = useSelector((state: RootState) => state.contabilidad.general.periodosContables);
  const resultadosBusqueda = useSelector((state: RootState) => state.clientes.docCuentasXCobrar.anticipos.resultadosAsiento)
  const resultadosBusquedaDetalle = useSelector((state: RootState) => state.clientes.docCuentasXCobrar.anticipos.resultadosAsientoDetalle)
  const proyectos = useSelector((state: RootState) => state.shared.providers.proyectos)

  const currentFunction = useSelector((state: RootState) => state.contabilidad.diarioGeneral.currentFunction);
  const currentActionState = useSelector((state: RootState) => state.contabilidad.diarioGeneral.currentAction);
  const asientosDetalle = useSelector((state: RootState) => state.contabilidad.diarioGeneral.detalleAsiento);

  const [currentAction, setCurrentAction] = React.useState<any>(currentActionState)
  const [ASI_DIARIONRO, setASI_DIARIONRO] = React.useState<any>("")
  const [NRO, setNRO] = React.useState<any>(0)
  const [AS_TIPO, setAS_TIPO] = React.useState<any>("MANUAL")
  const [AS_NROTIPO, setAS_NROTIPO] = React.useState<any>(0)
  const [ASI_C_FECHA_AS, setASI_C_FECHA_AS] = React.useState<Date>(new Date())
  const [ASI_FECHA_MIN, setASI_FECHA_MIN] = React.useState<Date>(new Date())
  const [ASI_FECHA_MAX, setASI_FECHA_MAX] = React.useState<Date>(new Date())
  const [AS_C_INICIAL, setAS_C_INICIAL] = React.useState<any>(false)
  const [AS_C_AJUSTE, setAS_C_AJUSTE] = React.useState<any>(false)
  const [ELIMINADOS, setELIMINADOS] = React.useState<any>(false)
  const [COD_LOCALCC, setCOD_LOCALCC] = React.useState<Local | null>(null)
  const [TIPO_TRANSACCION, setTIPO_TRANSACCION] = React.useState<any>(operacionesGrupos[0])
  const [COD_CUENTA, setCOD_CUENTA] = React.useState<any>("")
  const [NUM_CUENTA, setNUM_CUENTA] = React.useState<any>("")
  const [DESC_CUENTA, setDESC_CUENTA] = React.useState<any>("")
  const [VALOR, setVALOR] = React.useState<number>(0)
  const [REF, setREF] = React.useState<any>("")
  const [AS_C_OBSERVACION, setAS_C_OBSERVACION] = React.useState<any>("")
  const [AS_C_COP_REFERENC, setAS_C_COP_REFERENC] = React.useState<any>("")
  const [AS_C_TOT_DEBE, setAS_C_TOT_DEBE] = React.useState<any>(0)
  const [AS_C_TOT_HABER, setAS_C_TOT_HABER] = React.useState<any>(0)
  const [AS_C_TOT_DESCUADRE, setAS_C_TOT_DESCUADRE] = React.useState<any>(0)
  const [messageConfirm, setMessageConfirm] = React.useState<string>("");
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [cleanDetall, setCleanDetall] = React.useState(false);
  const [colorDescuadre, setcolorDescuadre] = React.useState("#0b333c");
  const [filaSeleccionada, setFilaSeleccionada] = React.useState<any>('');
  const [resErrores, setResErrores] = React.useState<any>([]);
  const [proyecto, setProyecto] = React.useState<Project | null>(null)
  const [dpLocales, setDpLocales] = React.useState<Array<Local>>([])
  const [resultados, setResultados] = React.useState<Array<any>>([])


  const openModalCuentas = React.useCallback(() => {
    dispatch(setModal({
      modalName: "modalPlan",
      open: true,
      codModulo: null
    }))
  }, [dispatch]);

  const llenarPeriodos = React.useCallback(async (data) => {
    const cuentas: any = [];
    const periodos = data;
    Object.keys(periodos).map(function (key) {
      return cuentas.push({
        value: Number(periodos[key]["diario"]),
        label: periodos[key]["anio"],
        detalle: periodos[key]
      });
    });
    return { cant: cuentas.length, data: cuentas }
  }, []);

  const obtenerPeriodosContables = React.useCallback(async () => {
    playLoader()
    try {
      const data = await cuentasService.obtenerPeriodosContables();
      stopLoader()
      if (data['error'] === false) {
        if (data['auto'].length > 0) {
          const resPeriodos: any = await llenarPeriodos(data['auto']);
          if (resPeriodos['cant'] > 0) {
            dispatch(setPeriodosContables(resPeriodos['data']))
            setASI_DIARIONRO(resPeriodos['data'][0]['value'])
            const fnac: any = DateUtils.strDateToDate(resPeriodos['data'][0]['detalle']['fechaInicio'], formatoFechasApi);
            const fnacMax: any = DateUtils.strDateToDate(resPeriodos['data'][0]['detalle']['fechaCierre'], formatoFechasApi);
            setASI_FECHA_MIN(fnac)
            setASI_FECHA_MAX(fnacMax)
          }
        }
      } else {
        setToast(data['message'], "warning")
      }
      return await data;
    } catch (error) {
      consoleService.error(error);
      stopLoader()
      setToast(JSON.stringify(error), "danger")
    }
  }, [dispatch, llenarPeriodos, playLoader, setToast, stopLoader]);

  const llenarCombosPeriodos = React.useCallback(async (data) => {
    if (data.length > 0) {
      setASI_DIARIONRO(data[0]['value'])
      const fnac: any = DateUtils.strDateToDate(data[0]['detalle']['fechaInicio'], formatoFechasApi);
      const fnacMax: any = DateUtils.strDateToDate(data[0]['detalle']['fechaCierre'], formatoFechasApi);
      setASI_FECHA_MIN(fnac)
      setASI_FECHA_MAX(fnacMax)
    }
  }, []);

  const findCuentaPlan = React.useCallback(async (TIPO, BUSCAR) => {
    playLoader()
    try {
      let obj: any = {}

      if (TIPO === "NUM_CUENTA") {
        obj = {
          numero: BUSCAR,
          descripcion: "",
          estado: 1,
        }
      } else if (TIPO === "DESC_CUENTA") {
        obj = {
          numero: "",
          descripcion: BUSCAR,
          estado: 1,
        }
      }
      const data = await planDeCuentas.obtenerRegistros(obj);
      consoleService.log('data', data)
      stopLoader();
      if (data['auto'].length > 0) {
        if (data['auto'].length === 1) {
          setCOD_CUENTA(data['auto'][0]['codigo'])
          setNUM_CUENTA(data['auto'][0]['numero'])
          setDESC_CUENTA(data['auto'][0]['descripcion'])
          atFocus(inputValor);
          setToast("Cuenta " + data['auto'][0]['numero'], "success")
          return false;
        } else if (data['auto'].length > 1 && data['auto'].length !== 1) {
          openModalCuentas()
          dispatch(setCuentasPlan(data['auto']))
          dispatch(setCuentasPlanBack(data['auto']));
          setToast("Seleccione la cuenta que desea utilizar", "info")
          return false;
        }
      } else {
        atFocus(inputNumCuenta)
        setToast("No hay resultados en la busqueda.", "warning")
      }
      return await data;
    } catch (error) {
      atFocus(inputNumCuenta)
      consoleService.error(error);
      stopLoader()
      setToast(JSON.stringify(error), "danger")
    }
  }, [dispatch, openModalCuentas, playLoader, setToast, stopLoader]);

  const handleOnKeyPressCuenta = React.useCallback((event) => {
    if (event.event.key === "Enter" || event.event.keyCode === 13) {
      if (NUM_CUENTA.indexOf('..') > -1) {
        setCOD_CUENTA("");
        setDESC_CUENTA("")
        setToast("Ingrese el código correcto de la cuenta, (" + NUM_CUENTA + ") no es correcto", "info")
        return false;
      }
      if (NUM_CUENTA !== "") {
        findCuentaPlan("NUM_CUENTA", NUM_CUENTA);
      }
    } else if (event.event.key === "Tab" || event.event.keyCode === 11) {
      if (NUM_CUENTA.indexOf('..') > -1) {
        setCOD_CUENTA("");
        setDESC_CUENTA("")
        setToast("Ingrese el código correcto de la cuenta, (" + NUM_CUENTA + ") no es correcto", "info")
        return false;
      }
      if (NUM_CUENTA !== "") {
        findCuentaPlan("NUM_CUENTA", NUM_CUENTA);
      }
    }
  }, [findCuentaPlan, NUM_CUENTA, setToast]);

  const handleOnKeyPressDescCuenta = React.useCallback((event) => {
    if (event.event.key === "Enter" || event.event.keyCode === 13) {
      if (DESC_CUENTA !== "") {
        findCuentaPlan("DESC_CUENTA", DESC_CUENTA);
      }
    } else if (event.event.key === "Tab" || event.event.keyCode === 11) {
      if (DESC_CUENTA !== "") {
        findCuentaPlan("DESC_CUENTA", DESC_CUENTA);
      }
    }
  }, [DESC_CUENTA, findCuentaPlan]);

  const validarTransaccion = React.useCallback(() => {
    let mensaje: any = "";
    if (NUM_CUENTA === "") {
      mensaje = mensaje + "-  Datos de cuenta.\n";
    }
    return mensaje;
  }, [NUM_CUENTA]);

  const resetCntrlDetallAsiento = React.useCallback(() => {
    setNUM_CUENTA("")
    setDESC_CUENTA("")
    setVALOR(0)
    setREF("")
    atFocus(inputNumCuenta)
  }, []);

  const calcularTotales = React.useCallback((asientosDetalle_) => {

    const dptransaccion = asientosDetalle_;
    let totalDebe = 0;
    let totalHaber = 0;
    let debe = "";
    let haber = "";

    if (dptransaccion.length > 0) {
      if (origenModulo === 24 || origenModulo === 25 || origenModulo === 114) {
        for (let i = 0; i < dptransaccion.length; i++) {
          const tra_de = asientosDetalle_[i]['transaccionDebe'];
          debe = String(tra_de);

          const tra_ha = asientosDetalle_[i]['transaccionHaber'];
          haber = String(tra_ha);

          totalDebe = totalDebe + Number(debe);
          totalHaber = totalHaber + Number(haber);
        }
      } else {
        for (let i = 0; i < dptransaccion.length; i++) {
          const tra_de = asientosDetalle_[i]['TRA_DEBE'];
          debe = tra_de;

          const tra_ha = asientosDetalle_[i]['TRA_HABER'];
          haber = tra_ha;

          totalDebe = totalDebe + Number(debe);
          totalHaber = totalHaber + Number(haber);
        }
      }


    } else {
      totalDebe = 0;
      totalHaber = 0;
    }

    const totaldebe_ti = Number(Number(totalDebe).toFixed(2));
    const totalhaber_ti = Number(Number(totalHaber).toFixed(2));
    const descuadre_ti = totaldebe_ti - totalhaber_ti;

    if (descuadre_ti != 0) {
      setcolorDescuadre("#ff0000")
    } else {
      setcolorDescuadre("#0b333c")
    }

    setAS_C_TOT_DEBE(totaldebe_ti)
    setAS_C_TOT_HABER(totalhaber_ti)
    setAS_C_TOT_DESCUADRE(Number(descuadre_ti).toFixed(2))
  }, [origenModulo]);

  const handleOnKeyDiferencia = React.useCallback((event) => {
    consoleService.log("handleOnKeyDiferencia", event)
    if (event.event.key === "d" || event.event.keyCode === 68) {
      let VALOR_: number = 0;
      if (Number(AS_C_TOT_DESCUADRE) < 0) {
        VALOR_ = Number(AS_C_TOT_DESCUADRE) * -1;
      } else {
        VALOR_ = Number(AS_C_TOT_DESCUADRE) + 0;
      }
      setVALOR(VALOR_)
      if (TIPO_TRANSACCION.value === 0) {
        setTIPO_TRANSACCION(operacionesGrupos[1])
      } else if (TIPO_TRANSACCION === 1) {
        setTIPO_TRANSACCION(operacionesGrupos[0])
      }
      atFocus(inputRefDesc)
    }
  }, [AS_C_TOT_DESCUADRE, TIPO_TRANSACCION]);

  const handleClickAdd = React.useCallback(() => {

    let sms: string = "Adventencias";
    if (Number(AS_C_TOT_DEBE) <= 0 && (TIPO_TRANSACCION['value'] === 0 ? 0 : 1) === 1) {
      sms = "-    Para ingresar una transacción en el haber, debe ingresar al menos una en el debe.\n";
      setMessageConfirm(sms)
      setShowConfirm(true)
    } else {
      const mensaje = validarTransaccion();
      if (mensaje === "") {
        let detallAdd: any = {}
        const fila: any = asientosDetalle.length;
        const detalle: any = asientosDetalle.slice(0);
        let valor_debe: number = 0;
        let valor_haber: number = 0;

        if (TIPO_TRANSACCION['value'] === 0) {
          valor_debe = VALOR;
          valor_haber = 0;
        } else if (TIPO_TRANSACCION['value'] === 1) {
          valor_debe = 0;
          valor_haber = VALOR;
        }

        detallAdd = {
          id: uuidv4(),
          FILA: fila + 1,
          ASI_NUMERO: "",
          PLA_CODIGO: COD_CUENTA,
          PLA_NUMERO: NUM_CUENTA,
          PLA_DESCRIPCION: DESC_CUENTA,
          PLA_DESCIDIOMA: "",
          LOC_CODIGO: COD_LOCALCC?.codigo ?? -1,
          LOCAL: {
            value: COD_LOCALCC?.codigo ?? -1,
            label: COD_LOCALCC?.nombre ?? '',
          },
          LOC_NOMBRE: COD_LOCALCC?.nombre ?? '',
          TRA_CODIGO: "",
          TRA_REFERENCIA: REF,
          TRA_DEBE: valor_debe,
          TRA_HABER: valor_haber,
          TIPO_TRANSACCION: TIPO_TRANSACCION['value']
        };
        consoleService.log('to add ', detallAdd)
        detalle.push(detallAdd)
        dispatch(setDetalleAsiento(detalle))
        calcularTotales(detalle)
        resetCntrlDetallAsiento()
      } else {
        sms = "    Para ingresar una transacción, debe ingresar los siguientes campos:\n";
        setMessageConfirm(sms + " " + mensaje)
        setShowConfirm(true)
      }
    }
  }, [AS_C_TOT_DEBE, COD_CUENTA, COD_LOCALCC, DESC_CUENTA, NUM_CUENTA, REF, TIPO_TRANSACCION, VALOR, asientosDetalle, calcularTotales, dispatch, resetCntrlDetallAsiento, validarTransaccion]);

  const obtenerSecuencial = React.useCallback(async () => {
    setNRO(0)
    playLoader()
    try {
      const data = await cuentasService.asientoSecuencial();
      stopLoader()
      if (data['auto']) {
        setNRO(Number(data['auto']))
      }
      return await data;
    } catch (error) {
      consoleService.error(error);
      stopLoader()
      setToast(JSON.stringify(error), "danger")
    }
  }, [playLoader, setToast, stopLoader]);

  const handleReset = React.useCallback(() => {
    setASI_DIARIONRO('')
    setNRO(0)
    setAS_TIPO("MANUAL")
    setAS_NROTIPO(0)
    setASI_C_FECHA_AS(new Date())
    setASI_FECHA_MIN(new Date())
    setASI_FECHA_MAX(new Date())
    setAS_C_INICIAL(false)
    setAS_C_AJUSTE(false)
    setELIMINADOS(false)
    setCOD_LOCALCC(null)
    setTIPO_TRANSACCION(operacionesGrupos[0])
    setCOD_CUENTA("")
    setNUM_CUENTA("")
    setDESC_CUENTA("")
    setVALOR(0)
    setREF("")
    setAS_C_OBSERVACION("")
    setAS_C_COP_REFERENC("")
    setAS_C_TOT_DEBE(0)
    setAS_C_TOT_HABER(0)
    setAS_C_TOT_DESCUADRE(0)
    setcolorDescuadre("#0b333c")
    setFilaSeleccionada('')
    dispatch(setDetalleAsiento([]))
    if (periodosContables.length <= 0) {
      obtenerPeriodosContables();
    } else {
      llenarCombosPeriodos(periodosContables)
    }
    if (periodosContables.length <= 0) {
      obtenerPeriodosContables();
    } else {
      llenarCombosPeriodos(periodosContables)
    }


    if (NRO === 0) {
      obtenerSecuencial()
    }
  }, [NRO, dispatch, llenarCombosPeriodos, obtenerPeriodosContables, obtenerSecuencial, periodosContables]);

  const verificarValidaciones = React.useCallback(() => {

    const messagesErrorAsiento: any = [];

    if (asientosDetalle.length <= 0) {
      messagesErrorAsiento.push("-    Registros: no existen registros para este asiento contable.");
    }

    if (AS_C_OBSERVACION === "") {
      messagesErrorAsiento.push("-    Descripción del asiento.");
    }

    if (Number(AS_C_TOT_DESCUADRE) !== 0) {
      messagesErrorAsiento.push("-    Descuadre: el asiento contable tiene problemas revíselo.");
    }

    return messagesErrorAsiento;
  }, [AS_C_OBSERVACION, AS_C_TOT_DESCUADRE, asientosDetalle]);

  const onHandleSave = React.useCallback(async () => {
    playLoader();

    const transaccion: any = []
    let fila: any = 0;

    asientosDetalle.map((e) => {
      const detallAdd: any = {
        FILA: fila + 1,
        ASI_NUMERO: e['ASI_NUMERO'],
        PLA_CODIGO: e['PLA_CODIGO'],
        PLA_NUMERO: e['PLA_NUMERO'],
        PLA_DESCRIPCION: e['PLA_DESCRIPCION'],
        PLA_DESCIDIOMA: e['PLA_DESCIDIOMA'],
        LOC_CODIGO: COD_LOCALCC?.codigo ?? -1,
        TRA_CODIGO: e['TRA_CODIGO'],
        TRA_REFERENCIA: e['TRA_REFERENCIA'],
        TRA_DEBE: e['TRA_DEBE'],
        TRA_HABER: e['TRA_HABER']
      };
      transaccion.push(detallAdd)
      fila++;
    });

    const data_request = {
      numero: 0,
      fecha: DateUtils.dateToString(ASI_C_FECHA_AS, 'dd/MM/yyyy'),
      descripcion: AS_C_OBSERVACION,
      pla_descripcion: "",
      tipo: AS_TIPO, // MANUAL
      debe: Number(AS_C_TOT_DEBE) ?? 0,
      haber: Number(AS_C_TOT_HABER) ?? 0,
      local: Number(COD_LOCALCC.codigo),
      transacciones: transaccion,
    }
    try {
      const data = await cuentasService.crearRegistro(data_request);
      stopLoader()
      if (data['error'] === true) {
        setToast(data['message'], "warning");
      } else {
        if (currentAction === "Editar") {
          setToast(data['message'], "success");
          dispatch(setData([]));
        } else {
          setToast(data['message'], "success");
        }
        dispatch(setResetSeleccion())
        dispatch(setCurrentAccion("Buscar"))
      }
      return await data;

    } catch (error) {
      consoleService.error(error);
      stopLoader()
      setToast(JSON.stringify(error), "danger")
    }
  }, [ASI_C_FECHA_AS, AS_C_OBSERVACION, AS_C_TOT_DEBE, AS_C_TOT_HABER, AS_TIPO, COD_LOCALCC, asientosDetalle, currentAction, dispatch, playLoader, setToast, stopLoader]);

  const handleClickDeleteConfirm = React.useCallback((index) => {
    consoleService.log("handleClickDeleteConfirm", index);
    const rowsBefore = asientosDetalle.slice();
    rowsBefore.splice(index, 1);
    dispatch(setDetalleAsiento(rowsBefore));
    setFilaSeleccionada('')
    calcularTotales(rowsBefore)
  }, [asientosDetalle, calcularTotales, dispatch]);

  const handleClickRemove = React.useCallback(() => {

    playLoader()
    if (asientosDetalle.length > 0) {
      if (filaSeleccionada === "") {
        stopLoader()
        setToast("No se encuentra ninguna fila seleccionada.", "info")
      } else {
        try {

          const rowsBefore = asientosDetalle.slice();
          rowsBefore.splice(Number(filaSeleccionada) - 1, 1);
          dispatch(setDetalleAsiento(rowsBefore));
          setFilaSeleccionada('')
          calcularTotales(rowsBefore)
          stopLoader()
          //setToast("Registro eliminado correctamente.", "success")

        } catch (error) {
          stopLoader()
          setToast("No se puede eliminar el registro.", "info")
        }
      }
    } else {
      stopLoader()
      setToast("No hay registros ingresados en la tabla.", "info")
    }
  }, [asientosDetalle, calcularTotales, dispatch, filaSeleccionada, playLoader, setToast, stopLoader]);

  const handleClickRemoveDetall = React.useCallback(() => {
    if (asientosDetalle.length > 0) {
      const sms = "¿Desea eliminar todo el detalle del asiento?\n";
      setMessageConfirm(sms)
      setShowConfirm(true)
      setCleanDetall(true)
    } else {
      setToast("No hay detalle ingresado en la tabla.", "info")
    }
  }, [asientosDetalle, setToast]);

  const handleClickAddClone = React.useCallback(() => {
    if (asientosDetalle.length > 0) {
      if (filaSeleccionada === "") {
        setToast("No se encuentra ninguna fila seleccionada.", "info")
      } else {
        try {

          let transaccionAgregar: any = {}
          let transaccionSeleccionada: any = {}

          let detalle: any = asientosDetalle.slice(0);
          const clonarFila = detalle[filaSeleccionada];
          detalle.splice(filaSeleccionada, 1);

          if (Number(clonarFila['FILA']) > 1) {

            transaccionAgregar = {
              id: uuidv4(),
              FILA: Number(clonarFila['FILA']),
              ASI_NUMERO: "",
              PLA_CODIGO: "",
              PLA_NUMERO: "",
              PLA_DESCRIPCION: "",
              PLA_DESCIDIOMA: "",
              LOCAL: {
                value: clonarFila?.LOC_CODIGO?.codigo ?? -1,
                label: clonarFila?.LOC_CODIGO?.nombre ?? ''
              },
              LOC_CODIGO: clonarFila?.LOC_CODIGO?.codigo ?? -1,
              LOC_NOMBRE: clonarFila?.LOC_CODIGO?.nombre ?? '',
              TRA_CODIGO: "",
              TRA_REFERENCIA: "",
              TIPO_TRANSACCION: clonarFila['TIPO_TRANSACCION'],
              TRA_DEBE: 0,
              TRA_HABER: 0
            };
            detalle.push(transaccionAgregar)
            transaccionSeleccionada = {
              ...clonarFila,
              FILA: Number(clonarFila['FILA'] + 1)
            }
            detalle.push(transaccionSeleccionada)
            consoleService.log("detalle", detalle)



          } else {
            let contador: number = 1;
            transaccionSeleccionada = {
              ...clonarFila,
              FILA: Number(contador) + 1
            }
            detalle.push(transaccionSeleccionada)

            transaccionAgregar = {
              FILA: contador,
              ASI_NUMERO: "",
              PLA_CODIGO: "",
              PLA_NUMERO: "",
              PLA_DESCRIPCION: "",
              PLA_DESCIDIOMA: "",
              LOCAL: {
                value: clonarFila?.LOC_CODIGO?.codigo ?? -1,
                label: clonarFila?.LOC_CODIGO?.nomnre ?? '',
              },
              LOC_CODIGO: clonarFila?.LOC_CODIGO?.codigo ?? -1,
              LOC_NOMBRE: clonarFila?.LOC_CODIGO?.nombre ?? '',
              TRA_CODIGO: "",
              TRA_REFERENCIA: "",
              TIPO_TRANSACCION: clonarFila['TIPO_TRANSACCION'],
              TRA_DEBE: 0,
              TRA_HABER: 0
            };
            detalle.push(transaccionAgregar)
            const item: any = []
            detalle.map(function (key) {
              const obj: any = {
                ...key,
                FILA: contador
              }
              item.push(obj)
              contador = contador + 1;
            })
            detalle = item;
          }

          dispatch(setDetalleAsiento(detalle))

        } catch (error) {
          consoleService.error(error)
          setToast("No se puede agregar el registro.", "info")
        }
      }
    } else {
      setToast("No hay registros ingresados en la tabla.", "info")
    }
  }, [asientosDetalle, dispatch, filaSeleccionada, setToast]);

  const verificarDescuadre = React.useCallback((value) => {
    const validador = 0;
    return new Promise((resolve) => {
      setTimeout(function () {
        if (value["value"] !== undefined) {
          resolve(
            Number(value["value"]) > Number(validador) === true
              ? false
              : true
          );
        } else {
          resolve(true);
        }
      }, 1000);
    });
  }, []);

  const verificarValorEnTotales = React.useCallback((value) => {
    const validador = 0;
    return new Promise((resolve) => {
      setTimeout(function () {
        if (value["value"] !== undefined) {
          resolve(
            Number(value["value"]) > Number(validador) === true
              ? true
              : false
          );
        } else {
          resolve(true);
        }
      }, 1000);
    });
  }, []);

  const getFields = React.useCallback((resultados) => {
    if (resultados !== null && resultados !== undefined) {
      setASI_DIARIONRO(resultados?.numeroAsientoDiario)
      setAS_TIPO(resultados?.tipoAsiento)
      setNRO(resultados?.numeroAsiento)
      setAS_NROTIPO(resultados?.numeroTipoAsiento)
      setASI_C_FECHA_AS(DateUtils.strDateToDate(DateUtils.format(resultados?.fechaAsiento, 'yyyy-MM-dd', 'dd/MM/yyyy')))
      if (proyectos?.length > 0) {
        setProyecto(proyectos[0])
      } else {
        setProyecto(null)
      }
      if (proyectos?.length > 0 && proyectos[0]?.locales?.length > 0) {
        proyectos[0]?.locales?.forEach((x: Local) => {
          if (x?.codigo === resultados?.codigoLocal) {
            setCOD_LOCALCC(x)
          }
        })
      } else {
        setCOD_LOCALCC(null)
      }
      setTIPO_TRANSACCION(operacionesGrupos[0])
      setAS_C_OBSERVACION(resultados?.descripcionAsiento)
    }
  }, [proyectos])

  const getResultados = React.useCallback(async () => {
    let newArray: any = []
    if (resultadosBusqueda['auto']) {
      const resFields: any = resultadosBusqueda['auto']
      getFields(resFields)
      const toAny: any = fetchAAsientoDetalle(resFields.numeroAsiento)
      const res = await dispatch(toAny)
      if (res !== null && res !== undefined && res.payload['error'] !== true) {
        newArray = res.payload['auto']
      }
    }
    setResultados(newArray)
  }, [resultadosBusqueda, getFields, dispatch]);

  const onChangeProyectoLook = React.useCallback(async (v) => {
    setProyecto(v)
    if (v?.locales) {
      setDpLocales(v.locales ?? [])
      if (resultadosBusqueda['auto'] && resultadosBusqueda['auto'] !== null) {
        const provider = v?.locales?.slice(0)
        const index = await getIndexProvider(provider, 'codigo', resultadosBusqueda['auto']?.codigoLocal)
        if (index > -1) {
          setCOD_LOCALCC(provider[index])
        } else {
          setCOD_LOCALCC(provider[0])
        }
      } else {
        setCOD_LOCALCC(v?.locales[0])
      }
    }
  }, [resultadosBusqueda]);

  const onSubmit = React.useCallback(async () => {
    const result = validationGroupRef.current.instance.validate();
    if (result.isValid) {
      setResErrores([])
      const resValidacion = verificarValidaciones();
      if (resValidacion.length > 0) {
        setResErrores(resValidacion)
        const sms = "No pudimos guardar la información, revise lo siguiente: \n";
        setMessageConfirm(sms)
        setShowConfirm(true)
        return false;
      }
      onHandleSave()
    } else {
      setToast("Tiene errores por favor verifíquelos.", "info");
    }
  }, [onHandleSave, setToast, verificarValidaciones]);


  React.useEffect(() => {
    if (origenModulo === 24 || origenModulo === 25 || origenModulo === 114) {
      getResultados()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [origenModulo, resultadosBusqueda]);

  React.useEffect(() => {
    if (currentFunction === "Guardar") {
      dispatch(setCurrentFunction(""));
      onSubmit()
    } else if (currentFunction === "Deshacer") {
      dispatch(setCurrentFunction(""));
      handleReset()
    } else if (currentFunction === "Nuevo") {
      dispatch(setCurrentFunction(""));
      if (origenModulo !== parseInt(EAplicationsAcatha.RolesPago)) {
        handleReset()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFunction]);

  React.useEffect(() => {
    dispatch(setDetalleAsiento([]))
    // handleReset()
    if (periodosContables.length <= 0) {
      obtenerPeriodosContables();
    } else {
      llenarCombosPeriodos(periodosContables)
    }
    if (NRO === 0) {
      obtenerSecuencial()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodosContables, NRO]);

  React.useEffect(() => {
    if (cargarCuentaContable.codigo !== 0) {
      setCOD_CUENTA(cargarCuentaContable['codigo'])
      setNUM_CUENTA(cargarCuentaContable['numero'])
      setDESC_CUENTA(cargarCuentaContable['descripcion'])
      atFocus(inputValor);
      dispatch(setCargarCuentaContable({
        codigo: 0,
        numero: "",
        descripcion: ""
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cargarCuentaContable]);

  React.useEffect(() => {
    if (currentActionState !== "") {
      setCurrentAction(currentAction)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentActionState]);

  React.useEffect(() => {
    if (origenModulo === 24 || origenModulo === 25 || origenModulo === 114) {
      if (origenModulo === 25) {
        setAS_TIPO("ABONO PROVEEDOR")
      }
      if (resultadosBusquedaDetalle !== null && resultadosBusquedaDetalle['auto'] && resultadosBusquedaDetalle['auto'].length > 0) {
        calcularTotales(resultadosBusquedaDetalle['auto'])
      }
    } else {
      if (asientosDetalle.length > 0) {
        if (currentAction === "Nuevo") {
          calcularTotales(asientosDetalle)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asientosDetalle, origenModulo, resultadosBusquedaDetalle]);

  // #componentes

  const confirmAction = () => {
    return (
      <Modalform
        name='confirmar'
        headerTitle={'Confirmar'}
        childrenBody={renderBody()}
        childrenFooter={renderFooter()}
        closeOnBackdrop={false}
        show={showConfirm}
        onClose={() => setShowConfirm(!showConfirm)}
        centered={true}
        size="sm"
      />
    )
  }

  const renderFooter = () => {
    return (
      <>
        {((showConfirm === true) && (cleanDetall !== true)) && (
          <CButton color="primary" onClick={() => {
            setShowConfirm(false)
          }}>
            {"Aceptar"}
          </CButton>
        )}

        {((showConfirm === true) && (cleanDetall === true)) && (
          <CButton color="primary" onClick={() => {
            dispatch(setDetalleAsiento([]));
            setShowConfirm(false)
            setCleanDetall(false)
            calcularTotales([])
          }}>
            {"Aceptar"}
          </CButton>
        )}

        <CButton color="secondary" onClick={() => {
          setShowConfirm(!showConfirm)
        }} >{"Cancelar"}</CButton>
      </>
    )
  }

  const renderBody = () => {
    return (
      <>
        {messageConfirm}
        <br></br>
        {resErrores.length > 0 && errores()}
      </>
    )
  }

  const errores = () => {
    return (
      <>
        {
          Object.keys(resErrores).map(function (key) {
            return (
              <>
                <GlobalLabel style={{
                  marginTop: "0rem",
                  fontSize: "96%",
                  listStyleType: "none",
                }} key={key} >
                  {resErrores[key]}
                </GlobalLabel>
                <br></br>
              </>);
          })
        }
      </>
    )
  }


  return (
    <>
      {showConfirm === true && confirmAction()}

      <CCard>
        <CCardHeader>
          <strong>{currentAction}</strong>
        </CCardHeader>
        <CCardBody>
          <ValidationGroup ref={validationGroupRef}>
            <CRow>
              <CCol lg="6" md="8" sm="12">
                <ValidationSummary id="summary"></ValidationSummary>
              </CCol>
            </CRow>

            <CRow>
              <CCol lg="4" sm="12">
                <div className="dx-field m-1">
                  <div className="dx-field-label">{'Diario:'}</div>
                  <div className="dx-field-value">
                    <TextBox
                      readOnly
                      width="100%"
                      value={ASI_DIARIONRO}
                      placeholder=""
                    >
                      <Validator>
                        <RequiredRule message={'El Diario es requerido.'} />
                      </Validator>
                    </TextBox>
                  </div>
                </div>

                <div className="dx-field m-1">
                  <div className="dx-field-label">{'Fecha:'}</div>
                  <div className="dx-field-value">
                    <DateBox
                      width="100%"
                      displayFormat="dd/MM/yyyy"
                      value={ASI_C_FECHA_AS}
                      stylingMode="outlined"
                      onValueChange={(data) => setASI_C_FECHA_AS(data)}
                      min={ASI_FECHA_MIN}
                      max={ASI_FECHA_MAX}
                    >
                      <DateBoxButton
                        name="prevDate"
                        location="before"
                        options={{
                          icon: 'spinprev',
                          stylingMode: 'text',
                          onClick: () => {
                            const x = DateUtils.add(ASI_C_FECHA_AS, {
                              days: -1,
                            })
                            setASI_C_FECHA_AS(x)
                          },
                        }}
                      />
                      <DateBoxButton
                        name="nextDate"
                        location="after"
                        options={{
                          icon: 'spinnext',
                          stylingMode: 'text',
                          onClick: () => {
                            const x = DateUtils.add(ASI_C_FECHA_AS, { days: 1 })
                            setASI_C_FECHA_AS(x)
                          },
                        }}
                      />
                      <DateBoxButton name="dropDown" />
                    </DateBox>
                  </div>
                </div>

                <div className="dx-field m-1">
                  <div className="dx-field-label">{'Proyectos:'}</div>
                  <div className="dx-field-value ">
                    <ProjectsLookUp
                      onChanged={(data) => onChangeProyectoLook(data)}
                      selected={proyecto ?? null}
                      addDefaultPosition={'General'}
                    />
                  </div>
                </div>

                <div className="dx-field m-1">
                  <div className="dx-field-label">{'Lugar o CC:'}</div>
                  <div className="dx-field-value ">
                    <Lookup
                      width="100%"
                      dataSource={dpLocales}
                      displayExpr="nombre"
                      searchEnabled={true}
                      searchMode={'contains'}
                      searchExpr={'nombre'}
                      searchTimeout={200}
                      minSearchLength={3}
                      showDataBeforeSearch={true}
                      placeholder="Seleccionar"
                      showClearButton={true}
                      defaultValue={COD_LOCALCC}
                      value={COD_LOCALCC}
                      onValueChange={(data) => {
                        setCOD_LOCALCC(data)
                      }}
                    ></Lookup>
                  </div>
                </div>
              </CCol>
              <CCol lg="4" sm="12">
                <div className="dx-field m-1">
                  <div className="dx-field-label">{'Nro.:'}</div>
                  <div className="dx-field-value">
                    <TextBox
                      readOnly
                      width="70%"
                      value={NRO}
                      placeholder=""
                      onValueChange={(data) => {
                        setNRO(data)
                      }}
                    ></TextBox>
                  </div>
                </div>
                <div className="dx-field m-1">
                  <div className="dx-field-value d-flex align-items-start">
                    <CheckBox
                      width={100}
                      text="Inicial"
                      value={AS_C_INICIAL}
                      onValueChange={() => setAS_C_INICIAL(!AS_C_INICIAL)}
                    />{' '}
                    <CheckBox
                      width={100}
                      text="Ajuste"
                      value={AS_C_AJUSTE}
                      onValueChange={() => setAS_C_AJUSTE(!AS_C_AJUSTE)}
                    />{' '}
                    <CheckBox
                      width={100}
                      text="Eliminados"
                      value={ELIMINADOS}
                      onValueChange={() => setELIMINADOS(!ELIMINADOS)}
                    />
                  </div>
                </div>
              </CCol>
              <CCol lg="4" sm="12">
                <Labelgroup
                  value={AS_TIPO + '-' + AS_NROTIPO}
                  name={''}
                  label={''}
                  labelmd="1"
                  inputxs="12"
                  inputmd="11"
                />
              </CCol>
            </CRow>

            <CRow>
              <CCol className="float-right" lg="12">
                <strong>
                  <hr></hr>
                </strong>
              </CCol>
            </CRow>

            <CRow>
              <CCol sm="12" md="4" lg="4">
                <div className="dx-field m-1">
                  <div className="dx-field-label">{'Cuenta:'}</div>
                  <div className="dx-field-value">
                    <TextBox
                      ref={inputNumCuenta}
                      width="70%"
                      name="NUM_CUENTA"
                      id="NUM_CUENTA"
                      value={NUM_CUENTA}
                      showClearButton={true}
                      placeholder=""
                      onValueChange={(data) => {
                        setNUM_CUENTA(data)
                        // onBlurCuentas(data.value);
                      }}
                      onEnterKey={(evt) => {
                        // handleOnKeyPressCuenta(evt);
                        if (NUM_CUENTA.length > 0) {
                          handleOnKeyPressCuenta(evt)
                        } else {
                          openModalCuentas()
                        }
                      }}
                      elementAttr={{
                        id: 'NUM_CUENTA',
                      }}
                      onKeyDown={utilidades.filtraTeclasNoNumericasAndKeyD}
                    />
                  </div>
                </div>
              </CCol>
              <CCol sm="12" md="5" lg="5">
                <div className="dx-field m-1 d-flex align-items-start">
                  <div className="dx-field-value">
                    <TextBox
                      width="100%"
                      value={DESC_CUENTA}
                      placeholder=""
                      showClearButton={true}
                      onValueChange={(data) => {
                        setDESC_CUENTA(data)
                      }}
                      //onKeyPress={(evt) => handleOnKeyPressDescCuenta(evt)}
                      onEnterKey={(evt) => {
                        if (DESC_CUENTA.length > 0) {
                          handleOnKeyPressDescCuenta(evt)
                        } else {
                          openModalCuentas()
                        }
                      }}
                    >
                      <TextBoxButton
                        name="agregarCuenta"
                        location="after"
                        options={{
                          icon: 'plus',
                          text: '',
                          onClick: () => openModalCuentas(),
                        }}
                      />
                    </TextBox>
                  </div>
                </div>
              </CCol>
            </CRow>

            <CRow>
              <CCol sm={12} md={4} lg={4}>
                <div className="dx-field m-1">
                  <div className="dx-field-label">{'Valor:'}</div>
                  <div className="dx-field-value">
                    <NumberBox
                      ref={inputValor}
                      width="70%"
                      value={VALOR}
                      placeholder=""
                      onValueChange={(data) => {
                        if (data) {
                          setVALOR(data)
                        } else {
                          setVALOR(0)
                        }
                      }}
                      onEnterKey={() => handleClickAdd()}
                      onKeyUp={(evt) => handleOnKeyDiferencia(evt)}
                      onKeyDown={utilidades.filtraTeclasNoNumericasAndKeyD}
                    />
                  </div>
                </div>
              </CCol>
              <CCol sm={12} md={4} lg={4}>
                <CRow className="d-flex align-items-center">
                  <CCol className="d-flex align-items-start" md="2">
                    <GlobalLabel className="  m-1">{'Ref.:'} </GlobalLabel>
                  </CCol>
                  <CCol className="d-flex align-items-start" md="10">
                    <TextBox
                      ref={inputRefDesc}
                      className="m-1"
                      width="100%"
                      value={REF}
                      placeholder=""
                      onValueChange={(data) => {
                        setREF(data)
                      }}
                      onEnterKey={() => handleClickAdd()}
                    />
                  </CCol>
                </CRow>
              </CCol>
              <CCol sm={12} md={2} lg={2}>
                <div className="dx-field">
                  <div className="dx-field-value">
                    <RadioGroup
                      name="operacion"
                      dataSource={operacionesGrupos}
                      value={TIPO_TRANSACCION}
                      layout="horizontal"
                      displayExpr="label"
                      onValueChange={(e) => {
                        consoleService.log('radio e ', e)
                        setTIPO_TRANSACCION(e)
                      }}
                    />
                  </div>
                </div>
              </CCol>
              <CCol sm={12} md={2} lg={2}>
                <CTooltip
                  key={'tool-plus-account'}
                  placement="top"
                  content={'Agregar'}
                >
                  <CButton
                    id="btnBuscarCuentas"
                    color="secondary"
                    className="ms-1"
                    size="sm"
                    onClick={() => handleClickAdd()}
                  >
                    <FontAwesomeIcon
                      size="lg"
                      icon={['fal', 'plus']}
                      style={{ fontWeight: 1 }}
                    />
                  </CButton>
                </CTooltip>

                <CTooltip
                  key={'tool-minus-account'}
                  placement="top"
                  content={'Quitar'}
                >
                  <CButton
                    id="btnBuscarCuentas"
                    color="secondary"
                    className="ms-1"
                    size="sm"
                    onClick={() => handleClickRemove()}
                  >
                    <FontAwesomeIcon
                      size="lg"
                      icon={['fal', 'minus']}
                      style={{ fontWeight: 1 }}
                    />
                  </CButton>
                </CTooltip>
              </CCol>
            </CRow>

            <CRow>
              <CCol sm={12} md={12} lg={12} className="d-flex align-items-end">
                <CTooltip
                  key={'tool-minus-account'}
                  placement="top"
                  content={'Vaciar Detalle'}
                >
                  <CButton
                    id="btnVaciarDetalle"
                    color="secondary"
                    className="ms-1"
                    size="sm"
                    onClick={() => handleClickRemoveDetall()}
                  >
                    <FontAwesomeIcon
                      size="lg"
                      icon={['fal', 'trash']}
                      style={{ fontWeight: 1 }}
                    />
                  </CButton>
                </CTooltip>

                <CTooltip key={'tool-plus'} placement="top" content={'Agregar'}>
                  <CButton
                    id="btnBuscarCuentas"
                    color="secondary"
                    className="ms-1"
                    size="sm"
                    onClick={() => handleClickAddClone()}
                  >
                    <FontAwesomeIcon
                      size="lg"
                      icon={['fal', 'chevron-left']}
                      style={{ fontWeight: 1 }}
                    />
                    <FontAwesomeIcon
                      size="lg"
                      icon={['fal', 'plus']}
                      style={{ fontWeight: 1 }}
                    />
                    <FontAwesomeIcon
                      size="lg"
                      icon={['fal', 'chevron-right']}
                      style={{ fontWeight: 1 }}
                    />
                  </CButton>
                </CTooltip>

                <CTooltip key={'tool-minus'} placement="top" content={'Quitar'}>
                  <CButton
                    id="btnBuscarCuentas"
                    color="secondary"
                    className="ms-1"
                    size="sm"
                    onClick={() => handleClickRemove()}
                  >
                    <FontAwesomeIcon
                      size="lg"
                      icon={['fal', 'chevron-left']}
                      style={{ fontWeight: 1 }}
                    />
                    <FontAwesomeIcon
                      size="lg"
                      icon={['fal', 'minus']}
                      style={{ fontWeight: 1 }}
                    />
                    <FontAwesomeIcon
                      size="lg"
                      icon={['fal', 'chevron-right']}
                      style={{ fontWeight: 1 }}
                    />
                  </CButton>
                </CTooltip>
              </CCol>
            </CRow>
            <br></br>
            <CRow>
              <CCol sm={12} md={12} lg={12}>
                <Detalle
                  asientosDetalle={
                    origenModulo === 24 || origenModulo === 25 || origenModulo === 114
                      ? resultados
                      : asientosDetalle
                  }
                  retornarIndex={(row) => setFilaSeleccionada(row)}
                  handleClickRemove={(index) => handleClickDeleteConfirm(index)}
                  detalleAsiento={
                    origenModulo === 24 || origenModulo === 25 || origenModulo === 25
                      ? resultados
                      : asientosDetalle
                  }
                  returnDetalle={(detalle) => {
                    dispatch(setDetalleAsiento(detalle))
                  }}
                  setToast={setToast}
                  playLoader={playLoader}
                  stopLoader={stopLoader}
                  origenModulo={origenModulo}
                />
              </CCol>
            </CRow>

            <CRow className="m-1">
              <CCol sm={12} md={8} lg={8}>
                <CRow>
                  <CCol md={2}>
                    <GlobalLabel
                      htmlFor={'Valor'}
                      className="col-form-label-md"
                    >
                      {'Descripción:'}
                    </GlobalLabel>
                  </CCol>
                  <CCol xs={12} md={8}>
                    <TextArea
                      placeholder="..."
                      value={AS_C_OBSERVACION}
                      onValueChange={(data) => setAS_C_OBSERVACION(data)}
                    >
                      <Validator>
                        <RequiredRule message={'Descripción requerida'} />
                      </Validator>
                    </TextArea>
                  </CCol>
                </CRow>

                <CRow>
                  <CCol md="9">
                    <CheckBox
                      width={100}
                      text="Copiar con referencia"
                      value={AS_C_COP_REFERENC}
                      onValueChange={() =>
                        setAS_C_COP_REFERENC(!AS_C_COP_REFERENC)
                      }
                    />
                  </CCol>
                </CRow>
              </CCol>

              <CCol sm={12} md={4} lg={4}>
                <CRow>
                  <CCol sm={12} md={6} lg={6}>
                    <CRow>
                      <CCol xs={12} md={9}>
                        <TextBox
                          readOnly
                          width="100%"
                          value={AS_C_TOT_DEBE}
                          placeholder=""
                        >
                          <Validator>
                            <AsyncRule
                              message={'El total del debe no es válido.'}
                              validationCallback={verificarValorEnTotales}
                            />
                          </Validator>
                        </TextBox>
                      </CCol>
                    </CRow>
                  </CCol>
                  <CCol sm={12} md={6} lg={6}>
                    <CRow>
                      <CCol xs={12} md={9}>
                        <TextBox
                          readOnly
                          width="100%"
                          value={AS_C_TOT_HABER}
                          placeholder=""
                        >
                          <Validator>
                            <AsyncRule
                              message={'El total del haber no es válido.'}
                              validationCallback={verificarValorEnTotales}
                            />
                          </Validator>
                        </TextBox>
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm={12} md={12} lg={12}>
                    <div className="dx-field m-1">
                      <div className="dx-field-label">{'Descuadre:'}</div>
                      <div className="dx-field-value">
                        <TextBox
                          width="100%"
                          value={AS_C_TOT_DESCUADRE}
                          placeholder=""
                          style={{ borderColor: colorDescuadre }}
                        >
                          <Validator>
                            <AsyncRule
                              message={'Existe un descuadre en la transacción'}
                              validationCallback={verificarDescuadre}
                            />
                          </Validator>
                        </TextBox>
                      </div>
                    </div>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </ValidationGroup>
        </CCardBody>
      </CCard>
    </>
  )
}

export default React.memo(Nuevo);
