import {FiltroBusquedaPaisesState, PaisesListado} from "../store/types";
import {PaginationInfo} from "../../../../../store/types";
import {RequestHelper} from "../../../../../helpers/requestHelper";

export const paisesServices = {
  cargarPaises,
  getAllCountries,
  saveCountries,
  deletePais,
}

async function cargarPaises(id: any): Promise<any> {
  try {
    const query = {
      pais: id,
    }

    return await RequestHelper.get<any>('paises', 'cargar', '', query);
  } catch (error) {
    return error;
  }
}

async function getAllCountries(filtro: FiltroBusquedaPaisesState): Promise<Array<PaisesListado>> {
  const query = buildQuery(filtro);
  const pagedResponse = await RequestHelper.getAll<any>('countries', 'getAll', '', query);
  return pagedResponse.auto? parsePaises(pagedResponse.auto) : [];
}

async function saveCountries(formData: any): Promise<any> {
  try {
    const body = {
      infoRegistro: {
        ...formData,
      }
    }

    return await RequestHelper.postAll<any>('countries', 'save', body);
  } catch (error) {
    return error;
  }
}

async function deletePais(id: any): Promise<void> {
  try {
    const body = {
      codigo: id
    }

    return new Promise((resolve, reject) => {});
  } catch (error) {
    return error
  }
}

// Private
function buildQuery(
  filtro: FiltroBusquedaPaisesState,
  pagination?: PaginationInfo,
): any {

  const query: any = {
    ...(filtro.codigoInternacional && { codigoInternacional: filtro.codigoInternacional }),
    ...(filtro.nombre && { nombrePais: filtro.nombre }),
  }

  if (pagination) {
    query.page = pagination.current
    query.limit = pagination.size
    query.isPaged = true
  }

  const filters = ['nombrePais']

  filters.forEach((field) => {
    if (filtro[field] && filtro[field].length > 0) {
      query[field] = filtro[field]
    }
  })

  return query
}

function parsePaises(dataApi: any[]): Array<PaisesListado> {
  return dataApi.map((item: any) => ({
    codigo: item.codigo,
    nombre: item.nombre,
    codigoInternacional: item.codigoInternacional,
    codigoUaf: item.uaf,
    codigoPaisLocal: item.codigoLocal,
    region: item.region,
  }))
}
