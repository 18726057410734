import React from 'react';
import {
  Validator,
  RequiredRule,
  AsyncRule,
} from 'devextreme-react/validator';
import { ValidationGroup } from 'devextreme-react/validation-group';
import ValidationSummary from 'devextreme-react/validation-summary';
import NumberBox from 'devextreme-react/number-box';
import { useSelector } from 'react-redux';
import { Cliente } from '../../../../../../types/types';
import { DetallePedido } from '../../type/detallePedido';
import { ItemProforma } from '../../type/types';
import { RootState } from '../../../../../../../../store/store';
import CustomModalDevx from '../../../../../../../../views/componentes/modal/Modal';
import RowContainer from '../../../../../../../../views/componentes/rowContainer/rowContainer';
import CustomCol from '../../../../../../../../views/componentes/colContainer';
import Labeled from '../../../../../../../../views/componentes/labeledInput/labeledInput';
import { lh, MessagesKeys } from '../../../../../../../../helpers/localizationHelper';
import { BuscarItemPedidoLookUp } from './BusquedaItemsSelectBox/BuscarItemsPedidoSelectBox';

interface IAgregarDetallePedidoMobileProps extends React.PropsWithChildren {
  cliente: Cliente | null;
  onCancel: () => void;
  onOk: (detalle: DetallePedido) => void;
  fecha: string;
}

const AgregarDetallePedidoMobile: React.FunctionComponent<IAgregarDetallePedidoMobileProps> = (props) => {
  const empresa = useSelector((state: RootState) => { return state.global.session?.empresa });
  const { cliente, onCancel, onOk, fecha } = props;
  const textBoxCantidad = React.useRef<any>(null);
  const [item, SetItem] = React.useState<ItemProforma | null>(null);
  const [cantidad, setCantidad] = React.useState(1);
  const [precio, setPrecio] = React.useState(0);
  const validationGroupRef = React.useRef<any>();

  const getTotal = React.useCallback((tipo: 'total') => {
    switch (tipo) {
      case "total":
        return (cantidad * precio) ?? 0
      default:
        return 0;
    }
  }, [cantidad, precio])


  const onSubmit = React.useCallback(() => {
    const validationResult = validationGroupRef.current.instance.validate();
    if (!validationResult.isValid) {
      //setTieneErroresValidacion(true);
      return;
    };
    validationResult.complete.then(async resolve => {

      await resolve;
      if (resolve.isValid) {
        if (item) {

          const detalle = new DetallePedido();
          detalle.setItem(item, empresa?.precision ?? 4);
          detalle.cantidad = cantidad;
          detalle.precio = precio;
          //setSubmitting(false);
          console.log("detalle", detalle)
          onOk(detalle);
        }
      }
    })
  }, [item, cantidad, precio, empresa, onOk]);

  const validateCantidad = React.useCallback((params) => {
    const { value } = params;
    return value <= 0 ? Promise.reject("La cantidad debe ser mayor a 0.") : Promise.resolve();
  }, []);

  const validatePrecio = React.useCallback((params) => {
    const { value } = params;
    return value <= 0 ? Promise.reject("El precio debe ser mayor a 0.") : Promise.resolve();
  }, []);

  React.useEffect(() => {
    if (item) {
      console.log("item", item)
      setPrecio(item.pvp);
      // setPrecioIva(item.pvp)
      textBoxCantidad?.current?.instance?.focus();
    }
  }, [item]);



  return (
    <CustomModalDevx
      show={true}
      size='sm'
      title='Agregar Detalle'
      onClose={() => { onCancel() }}
      height={300}
      position='center'
      toolbarItems={[
        {
          widget: 'dxButton',
          toolbar: 'bottom',
          location: 'center',
          options: {
            text: 'Aceptar',
            icon: "check",
            type: "success",
            onClick: onSubmit
          }
        },
        {
          widget: 'dxButton',
          toolbar: 'bottom',
          location: 'center',
          options: {
            text: 'Cancelar',
            icon: "close",
            type: "danger",
            onClick: onCancel
          }
        }
      ]}
    >
      <ValidationGroup id={'AgregarDetalleVentaMovil'} ref={validationGroupRef}>
        <RowContainer >
          <CustomCol xs='12'>
            <Labeled label='Producto'            >
              <BuscarItemPedidoLookUp
                selected={item}
                onChanged={(value) => SetItem(value)}
                cliente={cliente}
                fecha={fecha}
              >
                <Validator>
                  <RequiredRule message={lh.getMessage(MessagesKeys.GlobalCampoRequerido, "Producto")}  ></RequiredRule>
                </Validator>
              </BuscarItemPedidoLookUp>
            </Labeled>
          </CustomCol>
          <CustomCol xs='4'>
            <Labeled
              label='Cantidad'
            >
              <NumberBox
                value={cantidad}
                onValueChanged={({ value }: any) => setCantidad(value ?? 0)}
                ref={textBoxCantidad}
                min={1}
              >
                <Validator>
                  <AsyncRule validationCallback={validateCantidad} reevaluate />
                </Validator>
              </NumberBox>
            </Labeled>

          </CustomCol>
          <CustomCol xs='4'>
            <Labeled
              label='Precio'
            >
              <NumberBox
                value={precio}
                onValueChanged={({ value }: any) => {
                  const nuevoPrecio = (value ?? 0);
                  setPrecio(nuevoPrecio);
                  // if (item) {
                  //   const nuevoPreciova = item.porcentajeIva > 0 ? nuevoPrecio * (1 + (item.porcentajeIva / 100)) : nuevoPrecio;
                  //   setPrecioIva(parseFloat(nuevoPreciova.toFixed(2)))
                  // } else {
                  //   setPrecioIva(nuevoPrecio);
                  // }
                }} >
                <Validator>
                  <AsyncRule validationCallback={validatePrecio} reevaluate />
                </Validator>
              </NumberBox>
            </Labeled>

          </CustomCol>

        </RowContainer>
        <RowContainer>
          <CustomCol xs='4'>
            <Labeled
              label='Total'
            >
              <NumberBox
                readOnly
                value={parseFloat(getTotal('total').toFixed(2))} />
            </Labeled>
          </CustomCol>

          <CustomCol xs='4'>
            <Labeled
              label='Stock'
            >
              <NumberBox
                readOnly
                value={item?.existencia} />
            </Labeled>
          </CustomCol>

        </RowContainer>
        <ValidationSummary />
      </ValidationGroup>
    </CustomModalDevx>
  );
};

export default AgregarDetallePedidoMobile;
