import React from 'react'
import { CCol, CRow } from '@coreui/react-pro'
import { useDispatch, useSelector } from 'react-redux'
import {
  Column,
  DataGrid,
  FilterRow,
  HeaderFilter,
  Summary,
  TotalItem
} from 'devextreme-react/data-grid'
import { RootState } from '../../../../../store/store'
import { setSeleccionarDato } from "../../store/modalIngresoCheques"
import { NumberBox } from 'devextreme-react/number-box'
import { utilidades } from '../../../../../helpers/utilidades'

interface TablaProps extends React.PropsWithChildren {
  name: string
  data: any
  actionDelete: () => void
  exportExcel: () => void
  origen: string
  returnSeleccion: (data) => void
}

const Tabla = (props: TablaProps) => {

  const { returnSeleccion, origen } = props;

  const formularioIngresoCheques = useSelector((state: RootState) => state.bancos.modalIngresoCheques.formularioIngresoCheques)

  const seleccionado = useSelector((state: RootState) => state.bancos.modalIngresoCheques.seleccionado)
  const registros = useSelector((state: RootState) => state.bancos.modalIngresoCheques.registros)

  const dispatch = useDispatch()
  const dataGrid = React.useRef<any>();

  const [data, setData] = React.useState<Array<any>>([])
  const [total_ti, setTotal_ti] = React.useState<number>(0)

  React.useEffect(() => {
    setTotal_ti(formularioIngresoCheques.total_ti)
  }, [formularioIngresoCheques])

  React.useEffect(() => {
    if (registros.length > 0) {
      llenarData(registros)
    } else {
      setData([])
    }
  }, [registros]);

  const clearFilter = () => {
    dataGrid.current.instance.clearSorting();
    dataGrid.current.instance.clearFilter();
  }

  const llenarData = React.useCallback((data) => {
    clearFilter();
    const item: any = []
    let contar: number = 0;
    try {
      data.map(function (key, i) {
        let itemAdd: any = {}
        itemAdd = {
          ...key,
          ORDEN: Number(i)
        }
        contar = contar + 1;
        item.push(itemAdd);
      })
      setData(item.map(x => utilidades.unfreeze(x)))
    } catch (error) {
      console.error("on llenarData", error)
    }
  }, []);

  const onSelectionChanged = React.useCallback((selectedRowsData) => {
    if (data.length > 0) {
      if (selectedRowsData['selectedRowsData'].length > 0) {
        dispatch(setSeleccionarDato(
          {
            index: selectedRowsData['selectedRowKeys'],
            row: selectedRowsData['selectedRowsData'][0]
          }
        ))
      }
    }
  }, [data, dispatch]);

  const onRowDblClick = React.useCallback((onRowDblClick) => {
    if (origen === 'modal') {
      const dataRow = onRowDblClick['key']
      returnSeleccion(dataRow)
    }
  }, [returnSeleccion, origen]);

  return (
    <>
      <CRow>
        <CCol className='d-flex justify-content-end'>{data.length}</CCol>
      </CRow>
      <DataGrid
        ref={dataGrid}
        selection={{ mode: 'single' }}
        hoverStateEnabled={true}
        dataSource={data}
        showBorders={true}
        showRowLines={true}
        onSelectionChanged={onSelectionChanged}
        onRowDblClick={onRowDblClick}
      >
        <FilterRow visible={true} />
        <HeaderFilter visible={true} />
        <Column
          dataType='string'
          dataField='numero'
          caption='Número'
          width='20%'
        />
        <Column
          dataType='string'
          dataField='nombreBanco'
          caption='Banco'
          width='20%'
        />
        <Column
          dataType='string'
          dataField='valor'
          caption='Valor'
          width='20%'
        />
        <Column
          dataType='string'
          dataField='fechaCobro'
          caption='Cobrar'
          width='20%'
        />
        <Column
          dataType='string'
          dataField='estadoDescripcion'
          caption='Estado'
          width='20%'
        />
        <Summary>
          <TotalItem column='valor' summaryType='sum' valueFormat='currency' />
        </Summary>
      </DataGrid>

      <CRow className="mt-2" >
        <CCol className='d-flex justify-content-end' lg={12} md={12}>
          <div className="dx-field">
            <div className="dx-field-label">
              {"Total de Cheques:"}
            </div>
            <div className="dx-field-value">
              <NumberBox
                width={"60%"}
                value={total_ti}
                placeholder=""
                format="#,##0.00"
              />
            </div>
          </div>
        </CCol>
      </CRow>
    </>
  )
}

export default Tabla
