import { DateUtils, formatoFechasApi } from '../../../../helpers/dateUtils'
import { RequestHelper } from '../../../../helpers/requestHelper'
import { utilidades } from '../../../../helpers/utilidades'
import { SesionService } from '../../../../services/sesion.service'
import { MarcaWeb } from '../types/types'

export const marcasWebServices = { getWebBrands }

async function getWebBrands(): Promise<any> {
  try {
    const data: any = {}
    const sesion = SesionService.getCurrentSesion()
    const keysToCache: any = {
      ...data,
      dateKey: DateUtils.getCurrentDateAsString(formatoFechasApi),
      empresa: sesion?.empresa?.codigo,
    }
    const cacheKey = 'marcasWeb' + JSON.stringify(keysToCache)
    const cached = sessionStorage.getItem(cacheKey)
    if (cached) {
      return { error: false, auto: JSON.parse(cached) }
    } else {
      const resApi = await RequestHelper.getAll<any>(
        'generales',
        'webmarks/list',
        '',
        data,
      )
      const opt: Array<MarcaWeb> = []
      if (resApi?.auto && resApi?.auto.length > 0) {
        for (const itemResApi of resApi.auto) {
          opt.push({
            codigo: itemResApi?.codigo ?? '',
            descripcion: utilidades.capText(itemResApi?.descripcion) ?? '',
          })
        }
      }
      sessionStorage.setItem(cacheKey, JSON.stringify(opt))
      return resApi
    }
  } catch (error) {
    return error
  }
}
