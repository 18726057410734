import { combineReducers } from '@reduxjs/toolkit'
import { LibroTransaccionalState } from '../types/types'
import { searchAnexoReducer } from './searchReducer'
import { tabsReducer } from './tabsReduxcers'
import { menuReducer } from './menuReducer'

export const libroTransaccionalReducer =
  combineReducers<LibroTransaccionalState>({
    menu: menuReducer,
    tabs: tabsReducer,
    search: searchAnexoReducer,
  })
