import React, { memo } from 'react'
import { CCol, CRow } from '@coreui/react-pro'
import { TextBox, ValidationGroup } from 'devextreme-react'

import { PaginationInfo, ToastTypes } from "../../../../../../../store/types"
import { MarcaInventario } from '../../../../../store/types'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import ResultadoBusqueda from './resultadoBusqueda'
import { InventarioService } from '../../../../../services/inventario.service'
import SelectBox from '../../../../../../../views/componentes/selectBox/selectBox'

interface IBuscarProps extends React.PropsWithChildren {
  onChanged: (data) => void
  onSelection: (data) => void
  grupos?: Array<number>
  lineas?: Array<number>
  deletedItem: any
  currentTab: string
  dataMarcaWeb: any
  setCurrenTab?: (currentTab: string) => void
  onCancel?: () => void
  funcion: string
  resetSelected?: () => void
  resetFunction: () => void
  setToast: (sms: string, type: ToastTypes) => void
  playLoader?: () => void
  stopLoader?: () => void
}

const Buscar = (props: IBuscarProps) => {
  const {
    funcion,
    setCurrenTab,
    onChanged,
    resetFunction,
    setToast,
    stopLoader,
    playLoader,
    onSelection,
    deletedItem,
    currentTab,
    resetSelected,
    dataMarcaWeb,
    grupos,
    lineas,
  } = props

  const formSearchRef = React.useRef<any>()
  const [nombre, setNombre] = React.useState<string>('')
  const [marcaWeb, setMarcaWeb] = React.useState<{
    id: number
    name: string
  } | null>(dataMarcaWeb[0])
  const [items, setItems] = React.useState<Array<MarcaInventario>>([])

  React.useEffect(() => {
    if (currentTab === 'Buscar') {
      if (funcion === 'Buscar') {
        if (grupos?.length > 0) {
          handleSubmitByGrupos()
        } else {
          handleSubmit()
        }
      } else if (funcion === 'Eliminar') {
        deleteItemTable(deletedItem)
      }
      resetFunction()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [funcion])

  const deleteItemTable = (idMarca) => {
    const filtrado = items.findIndex((item: any) => {
      return Number(item.codigo) === Number(idMarca['codigo'])
    })
    const rowsBefore = items.slice()
    if (filtrado > -1) {
      rowsBefore.splice(filtrado, 1)
      setItems(rowsBefore)
      resetSelected()
      setCurrenTab('Buscar')
      resetFunction()
    }
  }

  const handleSubmitByGrupos = React.useCallback(async () => {
    setItems([])
    playLoader()
    const paginInfo: PaginationInfo = {
      current: 0,
      size: 10,
    }

    try {
      const marcasByGrupos = await InventarioService.getMarkssByGrups(
        lineas,
        grupos,
        nombre,
        paginInfo,
      )
      stopLoader()
      const marcas = marcasByGrupos.result
      if (marcas && marcas.length > 0) {
        let item: Array<MarcaInventario> = []
        item = marcas.map(function (item: any) {
          return {
            ...item,
            nombre: String(item['nombre'] ?? ''),
            estado: String(item['estadoNombre'] ?? ''),
          }
        })
        setItems(item)
      }

      return await items
    } catch (error) {
      stopLoader()
      setToast(JSON.stringify(error), ToastTypes.Danger)
    }
  }, [grupos, lineas, items, nombre, playLoader, setToast, stopLoader])

  const handleSubmit = React.useCallback(async () => {
    setItems([])
    playLoader()
    const data_request = {
      nombre: nombre ? nombre : '',
      codigo: marcaWeb?.id > 0 ? marcaWeb?.id : 0,
    }
    try {
      const marcas = await InventarioService.getMarcas(data_request)
      stopLoader()
      if (marcas && marcas.length > 0) {
        let item: Array<MarcaInventario> = []
        item = marcas.map(function (item: any) {
          return {
            ...item,
            nombre: String(item['nombre'] ?? ''),
            estado: String(item['estadoNombre'] ?? ''),
          }
        })
        setItems(item)
      }

      return items
    } catch (error) {
      console.log(error)
    }
  }, [nombre, marcaWeb, items, playLoader, stopLoader])

  return (
    <>
      <fieldset>
        <ValidationGroup ref={formSearchRef}>
          <CRow className="row">
            <div className="dx-field-label col-lg-2 col-md-3 col-sm-12">
              {'Nombre:'}
            </div>
            <CustomCol className="dx-field-value" xs="12" md="4">
              <CCol md="12">
                <TextBox
                  name="codigo"
                  value={nombre ?? ''}
                  onValueChanged={({ value }: any) => setNombre(value)}
                  onEnterKey={handleSubmit}
                ></TextBox>
              </CCol>
            </CustomCol>
          </CRow>

          <CRow className="row mt-2">
            <div className="dx-field-label col-lg-2 col-md-3 col-sm-12">
              {'Marca Web:'}
            </div>
            <CustomCol className="dx-field-value" xs="12" md="4">
              <CCol md="12">
                <SelectBox
                  id={'selectedMarcaaWeb'}
                  keyExpr={'id'}
                  displayExpr={'name'}
                  onChange={(evt) => {
                    if (evt) {
                      setMarcaWeb(evt)
                    } else {
                      setMarcaWeb(null)
                    }
                  }}
                  options={dataMarcaWeb}
                  placeholder={'Seleccionar'}
                  selected={marcaWeb}
                  elementAttr={{
                    autocomplete: 'nope',
                    autoComplete: 'nope',
                  }}
                ></SelectBox>
              </CCol>
            </CustomCol>
          </CRow>

          <ResultadoBusqueda
            funcion={''}
            data={items ?? []}
            setToast={setToast}
            capturaSeleccion={(data) => onSelection(data)}
            onChanged={onChanged}
          />
        </ValidationGroup>
      </fieldset>
    </>
  )
}

export default memo(Buscar)
