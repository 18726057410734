import { combineReducers } from '@reduxjs/toolkit'
import { CatalogosState } from '../types/types'
import { LineasReducer } from '../lineas/store/lineasReducer'
import { MarcasReducer } from '../marcas/store/marcasReducer'
import { GruposReducer } from '../grupos/store/gruposReducer'
import { UnidadesReducer } from '../unidades/store/unidadesReducer'
import { AplicacionesReducer } from '../aplicaciones/store/aplicacionesReducer'
import { GemasReducer } from '../caracteristicasGemas/store/gemasReducer'
import { FichaTecnicaReducer } from '../fichaTecnica/store/fichaTecnicaReducer'
import { AtributosReducer } from '../atributos/store/atributosTecnicaReducer'

export const CatalogosReducer = combineReducers<CatalogosState>({
  lineas: LineasReducer,
  marcas: MarcasReducer,
  grupos: GruposReducer,
  unidades: UnidadesReducer,
  aplicaciones: AplicacionesReducer,
  gemas: GemasReducer,
  fechaTecnica: FichaTecnicaReducer,
  atributos: AtributosReducer,
})
