import { RequestHelper } from "../helpers/requestHelper";
import { TIngresarDeposito } from "../modulos/cuenta/upgrate/component/registrarDeposito";
import { ETiposPagos } from "../store/types";
import { SesionService } from "./sesion.service";

export const DepositosService = {
  getCuentasDeposito, ingresarPago, setTransaccionRegister, verificarEstadoPagos
}

async function getCuentasDeposito(): Promise<Array<any>> {
  const sesion = SesionService.getCurrentSesion();
  const query: any = {
    local: sesion.local.codigo
  }
  const itemsApi = await RequestHelper.get<Array<any>>('cuenta', 'getCuentasAcatha', '', query);
  const items = parseApiItemsData(itemsApi);
  return items;
}


function parseApiItemsData(apiresult: Array<any>): Array<any> {
  const items = apiresult.map(x => {
    const item: any = {
      codigo: parseInt(x.codigo),
      cuenta: x.cuenta,
      numero: x.numero,
      nombre: x.nombre,
      valor: x.valor,
      empresa: x.empresa,

    };
    return item;
  });
  return items;
}

async function ingresarPago(pago: TIngresarDeposito): Promise<Promise<any>> {
  try {
    const infoRegistro = {
      infoRegistro: pago
    }
    const creada = await RequestHelper.postAll<any>("cuenta", "deposito/save", infoRegistro);
    return creada;
  } catch (error) {
    return error;
  }
}

async function setTransaccionRegister(clientTransactionId: string, id: string, valor: number, codigoEmpresa: number, tipoPago: ETiposPagos, transaction: any): Promise<any> {
  try {
    const query: any = {
      clientTransactionId: clientTransactionId,
      id: id,
      valor: valor,
      codigoEmpresa: codigoEmpresa,
      tipoPago: tipoPago
    }
    if (transaction.detallesPlan !== null) {
      query.codigoPlan = transaction.detallesPlan.codigoPlan
      query.codigoPeriodicidad = transaction.detallesPlan.codigoPeriodicidad
      query.detallesPlan = transaction.detallesPlan.detallesPlan
      query.cantidadComprobantes = transaction.detallesPlan.cantidadComprobantes
      query.cantidadUsuarios = transaction.detallesPlan.cantidadUsuarios
    }
    if (transaction.adicionales !== null) {
      query.adicionales = transaction.adicionales
    }
    console.log("setTransaccionRegister", query)
    const items = await RequestHelper.postAll<any>('payphone', 'transaction', query);
    return items;
  } catch (error) {
    return error;
  }
}

async function verificarEstadoPagos(): Promise<Promise<any>> {
  try {
    const infoRequest = {}
    const review = await RequestHelper.post<any>("public", "reviewStatePay", infoRequest);
    return review;
  } catch (error) {
    return error;
  }
}
