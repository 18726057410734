import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AccionMenu } from '../../../../../store/types'
import { MenuState } from '../../../../ventas/types/types'

const initialState: MenuState = {
  loading: true,
  accions: [],
}

const menuConfSlice = createSlice({
  name: 'TransporteMenu',
  initialState: initialState,
  reducers: {
    setMenuButtons(state, action: PayloadAction<Array<AccionMenu>>) {
      state.accions = action.payload
      state.loading = false
    },
  },
});

export const { setMenuButtons } = menuConfSlice.actions
export const menuReducer = menuConfSlice.reducer
