import React, { Fragment } from 'react'
import Switch from "react-switch";

type SwitchProps = {
  label?: string
  name?: string
  id?: string
  text?: string
  labelOn?: string
  labelOff?: string
  checked: boolean
  onChange: any //React.ReactEventHandler<HTMLInputElement>,
  value?: any
  isStrong?: boolean,
  disabled?: boolean
}
//  { text, label, valueOn, valueOff, valueCheck }
const CSwitch: React.FC<SwitchProps> = (
  props: SwitchProps,
) => {

  return (
    <Fragment>

      <Switch
        className="react-switch"
        onChange={props.onChange}
        checked={props.checked}
        height={20}
        width={40}
        name={props.name}
        id={props.id}
        disabled={props.disabled ?? false}
      />

    </Fragment>
  )
}

export default CSwitch
