import React, { useCallback, useEffect } from 'react'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import { CCardBody, CCardHeader, CCollapse, CLink } from '@coreui/react-pro'
import { changeFilter, changeFilterBackup, setChangeLoader, setCollapsed, setCollapsedDetail, setImport } from '../../store/searchReducer'
import CIcon from '@coreui/icons-react'
import { cilChevronBottom, cilChevronTop } from '@coreui/icons'
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput'
import { Button, DateBox, NumberBox, RadioGroup, SelectBox, TextBox } from 'devextreme-react'
import MesesLookUp, { MesesData } from '../../../../../../componentes/mesesLookUp'
import { consoleService } from '../../../../../../../services/console.service'
import LocalesLookUp from '../../../../../../componentes/localesLookUp'
import { EAplicationsAcatha } from '../../../../../../../store/enumsAplication'
import ModalUsuarios from '../../../../../../componentes/modalUsuarios'
import { ETipoTransaccion, TFormaPagoOption } from '../../../../../../componentes/formasPagoLookUp/store/type'
import PaisesLookUp from '../../../../../../componentes/paisesLookUp'
import { TabState } from '../../../../../../../store/genericTabTypes'
import { FiltroBusquedaRolesPago, RolesPadoDatosEdicion } from '../../types/types'
import Importar from '../../../../../../clientes/pages/docsCtasXCobrar/components/impportarExcel/importar'
import { adminService } from '../../../../../../admin/cuenta/usuario/services/adminService'
import { localidadesService } from '../../../../../../../services/localidades.service'
import { DateUtils } from '../../../../../../../helpers/dateUtils'
import { formasPagoService } from '../../../../../../componentes/formasPagoLookUp/service/formasPago.service'
import { ToastTypes } from '../../../../../../../store/types'
import { addToast } from '../../../../../../../store/toasterReducer'
import { EFormasPago } from '../../../../../../../store/enum/formasPago'
import { utilidades } from '../../../../../../../helpers/utilidades'


export const gruposPersonal = [
  { value: 0, label: 'Todos' },
  { value: 1, label: 'Administrativo' },
  { value: 2, label: 'Obrero' },
  { value: 3, label: 'Gerente' },
]

interface IFilterhProps extends React.PropsWithChildren {
  tab: TabState<RolesPadoDatosEdicion>
  tabId: string
  onSearchEnter: () => void
}

const FiltrosBusqueda: React.FunctionComponent<IFilterhProps> = (props) => {
  const { tabId, tab, onSearchEnter } = props

  const dispatch = useDispatch()
  const filter = useSelector((state: RootState) => state.nomina.rol.rolesPago.search.filter)
  const collapsed = useSelector((state: RootState) => state.nomina.rol.rolesPago.search.colapsado)
  const collapsedDetail = useSelector((state: RootState) => state.nomina.rol.rolesPago.search.colapsadoDetalle)
  const modulo = useSelector((state: RootState) => state.nomina.rol.rolesPago.condfig.aplicacion)
  const localSession = useSelector((state: RootState) => state.global.session.local)
  const showImportButton = useSelector((state: RootState) => state.nomina.rol.rolesPago.search.import)
  const [showUsers, setShowUsers] = React.useState<boolean>(false)

  const onLoader = useCallback((showLoader: boolean, mensaje: string) => {
    dispatch(setChangeLoader({
      show: showLoader,
      mensaje: mensaje
    }))
  }, [dispatch])

  const setToast = useCallback(
    (mensaje: string, tipo: ToastTypes) => {
      dispatch(
        addToast({
          title: 'Nómina - Roles de Pago',
          content: mensaje,
          type: tipo,
        }),
      )
    },
    [dispatch],
  )

  const onLoadInitial = useCallback(async () => {
    const temp = { ...filter }
    if (temp.initial) {
      onLoader(true, 'Cargando...')
      const dataLocal = await adminService.obtenerLocalesUsuario("Elija un local", true)
      const resPais = await localidadesService.getDpPaises("Elija una opción")
      const dataFP = await formasPagoService.getFormasPago(ETipoTransaccion.ventas, "Elija una opción");
      consoleService.log(dataFP, 'data fp')
      if (dataFP?.auto && dataFP?.error === false) {
        const newFormPago = []
        dataFP?.auto.forEach(x => {
          if (x?.codigo === 7 || x?.codigo === -1 || x?.codigo === 6 || x?.codigo === 114 || x?.codigo === EFormasPago.otros) {
            newFormPago.push(x)
          }
        })
        temp.listaFormasPagos = newFormPago
        temp.formaPago = newFormPago[0]
        temp.opcionFormaPago = newFormPago[0]
      }
      if (resPais.length > 0) {
        const provider = resPais.slice(0)
        temp.pais = {
          codigo: provider[0]?.codigo ?? 0,
          nombre: provider[0]?.nombre ?? '',
          uaf: provider[0]?.codigoUaf ?? ''
        }
      }

      if (dataLocal?.auto && dataLocal?.error === false) {
        temp.lugar = dataLocal?.auto[0]
      }
      // temp.opcionFecha = DateUtils.getCurrentDateAsString()
      temp.opcionFecha = null
      temp.anio = new Date().getFullYear()
      temp.todos = gruposPersonal[0]
      temp.mes = MesesData[0]
      temp.initial = false
      dispatch(changeFilter({
        ...temp,
      }))
      dispatch(changeFilterBackup({
        ...temp,
      }))
      onLoader(false, '')
    }
  }, [dispatch, filter, onLoader])


  const onValueChange = useCallback((data, key: string) => {
    dispatch(changeFilter({
      ...filter,
      [key]: data
    }))
  }, [dispatch, filter])

  useEffect(() => {
    onLoadInitial()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getItemDisplayExpr = React.useCallback((item: TFormaPagoOption) => {
    return item ? `${item.descripcion}` : '';
  }, []);

  const onGetData = useCallback((data: any) => {
    if (data?.error === true) {
      setToast(data?.message, ToastTypes.Danger)
    }
  }, [setToast])

  return (
    <>
      {showUsers && (
        <ModalUsuarios
          show={showUsers}
          onClose={() => setShowUsers(false)}
          modulo={EAplicationsAcatha.RolesPago}
          onSelectUser={(user) => {
            consoleService.log(user)
            dispatch(changeFilter({
              ...filter,
              cedula: user?.cedula,
              nombres: user?.nombres,
              apellidos: user?.apellidos,
            }))
            setShowUsers(false)
          }}
        />
      )}
      <CCardHeader
        onClick={() => dispatch(setCollapsed(!collapsed))}
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <strong> {collapsed ? '' : 'Filtro de Búsqueda'} </strong>
        <CLink className="card-header-action">
          <CIcon icon={collapsed ? cilChevronBottom : cilChevronTop} />
        </CLink>
      </CCardHeader>
      <CCollapse visible={collapsed}>
        <CCardBody>
          <RowContainer>
            <CustomCol xs="12" md={showImportButton ? '7' : '12'}>
              <RowContainer>
                <CustomCol xs="12" md="2">
                  <Labeled label='Año:'>
                    <TextBox
                      value={String(filter?.anio) ?? null}
                      onValueChange={(data) => onValueChange(parseInt(data), 'anio')}
                      onEnterKey={onSearchEnter}
                      maxLength={4}
                      onKeyDown={utilidades.filtraTeclasNoNumericas}
                    />
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="3">
                  <Labeled label='Mes:'>
                    <MesesLookUp
                      onChanged={(data) => {
                        consoleService.log('meses ', data)
                        onValueChange(data, 'mes')
                      }}
                      selected={filter?.mes ?? null}
                    />
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="4">
                  <Labeled label="Lugar o CC:">
                    <LocalesLookUp
                      onChanged={(data) => onValueChange(data, 'lugar')}
                      onChangedOptions={() => { }}
                      selected={filter?.lugar ?? null}
                    />
                  </Labeled>
                </CustomCol>
              </RowContainer>
            </CustomCol>
            {showImportButton && (
              <CustomCol xs="12" md="5">
                <Importar
                  tab={tab}
                  tabId={tabId}
                  openImport={(open) => dispatch(setImport(open))}
                  moduleCode={modulo}
                  setData={(data) => onGetData(data)}
                  data={{
                    local: localSession?.codigo ?? -1,
                  }}
                />
              </CustomCol>
            )}
          </RowContainer>
        </CCardBody>
        <CCardHeader
          onClick={() => dispatch(setCollapsedDetail(!collapsedDetail))}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <strong> {collapsedDetail ? '' : 'Más Opciones'} </strong>
          <CLink className="card-header-action">
            <CIcon icon={collapsedDetail ? cilChevronBottom : cilChevronTop} />
          </CLink>
        </CCardHeader>
        <CCollapse visible={collapsedDetail}>
          <CCardBody>
            <RowContainer>
              <CustomCol xs="12" md="6">
                <RowContainer>
                  <CustomCol xs="12" md="6">
                    <Labeled label='Cédula:'>
                      <div
                        style={{
                          display: 'flex',
                          gap: '2px',
                          alignItems: 'center',
                          width: '100%',
                        }}
                      >

                        <TextBox
                          value={filter?.cedula ?? ''}
                          onValueChange={(data) => onValueChange(data, 'cedula')}
                          onEnterKey={onSearchEnter}
                        />
                        <Button
                          id="btnAdd"
                          className="me-1"
                          icon="plus"
                          stylingMode="contained"
                          type="default"
                          onClick={() => setShowUsers(true)}
                        />
                      </div>
                    </Labeled>
                  </CustomCol>
                  <CustomCol xs="12" md="6">
                    <Labeled label='Nombres:'>
                      <TextBox
                        value={filter?.nombres ?? ''}
                        onValueChange={(data) => onValueChange(data, 'nombres')}
                        onEnterKey={onSearchEnter}
                      />
                    </Labeled>
                  </CustomCol>
                </RowContainer>
                <RowContainer>
                  <CustomCol xs="12" md="6">
                    <Labeled label='Apellidos:'>
                      <TextBox
                        value={filter?.apellidos ?? ''}
                        onValueChange={(data) => onValueChange(data, 'apellidos')}
                        onEnterKey={onSearchEnter}
                      />
                    </Labeled>
                  </CustomCol>
                  <CustomCol xs="12" md="6">
                    <Labeled label="Forma de Pago:">
                      <SelectBox
                        id='TFormaPagoOptionSelect'
                        items={filter?.listaFormasPagos ?? []}
                        searchEnabled
                        displayExpr={getItemDisplayExpr}
                        searchExpr={['descripcion']}
                        searchTimeout={500}
                        value={filter?.formaPago ?? null}
                        onValueChange={(data) => onValueChange(data, 'formaPago')}
                      />
                    </Labeled>
                  </CustomCol>
                </RowContainer>
                <RowContainer>
                  <CustomCol xs="12" md="6">
                    <Labeled label="Pais:">
                      <PaisesLookUp
                        onChanged={(data) => onValueChange(data, 'pais')}
                        selected={filter?.pais ?? null}
                        onChangedOptions={() => { }}
                        defecto={false}
                      />
                    </Labeled>
                  </CustomCol>
                </RowContainer>
              </CustomCol>
              <CustomCol xs="12" md="6">
                <h6>{'Opción válida para pagos'}</h6>
                <RowContainer>
                  <CustomCol xs="12" md="6">
                    <Labeled label='Fecha de Cobro:'>
                      <DateBox
                        value={filter?.opcionFecha ?? null}
                        onValueChange={(data) => {
                          if (data !== null) {
                            const fecha = DateUtils.dateToString(data)
                            onValueChange(fecha, 'opcionFecha')
                          }
                        }} />
                    </Labeled>
                  </CustomCol>
                </RowContainer>
                <RowContainer>
                  <CustomCol xs="12" md="6">
                    <Labeled label="Forma de Pago:">
                      <SelectBox
                        id='TFormaPagoOptionSelect'
                        items={filter?.listaFormasPagos ?? []}
                        searchEnabled
                        displayExpr={getItemDisplayExpr}
                        searchExpr={['descripcion']}
                        searchTimeout={500}
                        value={filter?.opcionFormaPago ?? null}
                        onValueChange={(data) => onValueChange(data, 'opcionFormaPago')}
                      />
                    </Labeled>
                  </CustomCol>
                </RowContainer>
              </CustomCol>
            </RowContainer>
            <RowContainer>
              <CustomCol xs="12" md="5">
                <Labeled label=''>
                  <RadioGroup
                    name="personal"
                    layout="horizontal"
                    displayExpr="label"
                    dataSource={gruposPersonal}
                    value={filter?.todos ?? null}
                    onValueChange={(data) => onValueChange(data, 'todos')}
                  />
                </Labeled>
              </CustomCol>
            </RowContainer>
          </CCardBody>
        </CCollapse>
      </CCollapse>
    </>
  )
}

export default React.memo(FiltrosBusqueda)

export const defaultInitialFilter: FiltroBusquedaRolesPago = {
  anio: 0,
  mes: null,
  lugar: null,
  cedula: '',
  nombres: '',
  apellidos: '',
  formaPago: null,
  listaFormasPagos: [],
  opcionFormaPago: null,
  opcionFecha: '',
  pais: null,
  todos: null,
  initial: true,
}