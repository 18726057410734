import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ModalOpen } from '../../../../../contabilidad/general/store/types'
import { LoaderInfo, Seleccionado } from "../../../../../../store/types";
import { statusForms } from "../../../movimientosBancos/store/types";
import { DatosFormularioEgresosCaja, DetalleEgreso, Egresos, EgresosCaja, formularioBusqueda } from "./types";

const initialState: EgresosCaja = {
  modulo: 'egresosCaja',
  codModulo: null,
  currentAction: 'Buscar',
  currentFunction: "",
  registros: [],
  seleccionado: {
    index: null,
    row: null,
    selectedRow: null,
  },
  detalleEgreso: [],
  modalDisplay: {
    open: false,
    name: '',
    parametros: [],
    codModulo: 0
  },
  formulario: {},
  dpTipoTransaccion: [],
  loader: {
    show: false,
    mensaje: ''
  },
  currentFormulario: null,
  formularioEdicion: {
    estadoFormulario: statusForms.pending,
    diarioNumero: 0,
    fechaEgreso: "",
    fechaAuditoria: "",
    usuario: "",
    numeroDocumento: 0,
    numeroAsiento: 0,
    numeroTipo: 0,
    tipoDocumento: "",
    totalDebe: 0,
    totalHaber: 0,
    proyecto: null,
    local: null,
    codigoEgreso: 0,
    descripcion: "",
    lote: 0,
    asociadoNombre: "",
    puntoVentaDescripcion: "",
    puntoVentaCodigo: 0,
    asientoNumeroTipo: 0,
    detalleAsiento: []
  },
  formularioBusqueda: {
    interno: 0,
    numero: "",
    recibido: "",
    concepto: "",
    fechaDesde: "",
    fechaHasta: "",
  },
}

const egresosCajaSlice = createSlice({
  name: "egresoND",
  initialState: initialState,
  reducers: {
    setCodModulo(state, action: PayloadAction<number | null>) {
      state.codModulo = action.payload
    },
    setCurrentAccionEgresos(state, action: PayloadAction<any>) {
      state.currentAction = action.payload
    },
    setCurrentFunctionEgresos(state, action: PayloadAction<string>) {
      state.currentFunction = action.payload
    },
    setData(state, action: PayloadAction<Array<Egresos>>) {
      state.registros = action.payload
    },
    setSeleccionarDato(state, action: PayloadAction<Seleccionado>) {
      state.seleccionado = action.payload;
    },
    setResetSeleccionEgresosCaja(state) {
      const toAdd = {
        index: null,
        row: null,
        selectedRow: null,
      }
      state.seleccionado = toAdd
    },
    setDetalleEgreso(state, action: PayloadAction<Array<DetalleEgreso>>) {
      state.detalleEgreso = action.payload
    },
    setModalDisplay(state, action: PayloadAction<ModalOpen>) {
      state.modalDisplay = action.payload
    },
    setFormulario(state, action: PayloadAction<any>) {
      state.formulario = action.payload
    },
    setDpTipoTransaccion(state, action: PayloadAction<Array<any>>) {
      state.dpTipoTransaccion = action.payload
    },
    changeLoaderEngresosCaja(state, action: PayloadAction<LoaderInfo>) {
      state.loader = action.payload
    },
    setCurrentFormulario(state, action: PayloadAction<any>) {
      state.currentFormulario = action.payload
    },
    setFormularioEdicionEgresos(state, action: PayloadAction<DatosFormularioEgresosCaja>) {
      state.formularioEdicion = action.payload
    },
    setFormularioBusqueda(state, action: PayloadAction<formularioBusqueda>) {
      state.formularioBusqueda = action.payload
    },
  }
})
export const {
  setCodModulo,
  setCurrentAccionEgresos,
  setCurrentFunctionEgresos,
  setData,
  setSeleccionarDato,
  setResetSeleccionEgresosCaja,
  setDetalleEgreso,
  setModalDisplay,
  setFormulario,
  setDpTipoTransaccion,
  changeLoaderEngresosCaja,
  setCurrentFormulario,
  setFormularioEdicionEgresos,
  setFormularioBusqueda
} = egresosCajaSlice.actions;
export const EgresosCajaReducer = egresosCajaSlice.reducer;

