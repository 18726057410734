import React, { useCallback, useEffect } from 'react'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import { TextBox, ValidationGroup, Validator } from 'devextreme-react'
import {
  Characteristic,
  ItemEditData,
  LedgerAccount,
  Parts,
  PricesItems,
  Promotion,
  SaveItem,
  SupplierTable,
  optionsAges,
  optionsGroupsCosteo,
  optionsGroupsTarj,
  tabsNewItem,
} from '../../types/types'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import {
  clearButtonClick,
  closeTabMultistore,
  openTab,
  setCurrentExecutingAction,
  setNameTab,
} from '../../store/tabsReducer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/store'
import { ToastTypes } from '../../../../../../store/types'
import {
  changeLoaderItems,
  setCurrentFunction,
} from '../../store/generalReducer'
import {
  deleteEditData,
  initDatosEdicion,
  setDataActivesToEdit,
  setDataExtrasToEdit,
  setDataGeneralsToEdit,
  setDataPricesControlToEdit,
  setDataToEdit,
  setDatosEdicion,
  setDatosEdicionBackup,
  setIvaInclude,
  setLoaded,
} from '../../store/editDataReduce'
import Dialog from '../../../../../../views/componentes/librerias/bootstrap-dialog'
import { defaultDataItem } from '../..'
import { v4 as uuidv4 } from 'uuid'
import {
  CButton,
  CCol,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
} from '@coreui/react-pro'
import DatosGenereales, {
  optionsHierarchy,
  optionsTypesNewItem,
} from './components/generales'
import PreciosControl from './components/preciosControl'
import CuentasContables from './components/cuentasContables'
import Existencia, { optionsRecompras } from './components/existencia'
import Proveedores from './components/proveedores'
import Imagenes from './components/imagenes'
import { Aplicaciones } from './components/aplicaciones'
import Activos, { optionsDepreciacion } from './components/activos'
import Partes from './components/partes'
import Promociones from './components/promociones'
import Historicos from './components/historicos'
import Extras, {
  optionsBajoPedido,
  optionsLiqTransporte,
  optionsVirtual,
} from './components/extras'
import Observaciones from './components/observaciones'
import Atributos from './components/atributos'
import CustomCol from '../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput'
import { TabState } from '../../../../../../store/genericTabTypes'
import { ItemsServices } from '../../services/items.services'
import { RequiredRule, StringLengthRule } from 'devextreme-react/validator'
import { useSetToast } from '../../../../../../hooks/useGlobalHooks'
import VisualizaError from '../../../../../ventas/pages/shared/visualizaError/visualizaError'
import ListErrosValidationForm from '../../../../../componentes/listadoErroresValidacionForm'
import { DateUtils } from '../../../../../../helpers/dateUtils'
import { AplicacionInventario } from '../../../catalogos/aplicaciones/types/types'
import { utilidades } from '../../../../../../helpers/utilidades'
import { StatesEdition } from '../../../../../../store/enums'
import ModalArmarKit from '../modalArmarKit'

interface INewProps extends React.PropsWithChildren {
  tab: TabState<ItemEditData>
  tabId: string
}

const Nuevo: React.FunctionComponent<INewProps> = (props) => {
  const { tabId } = props
  const dispatch = useDispatch()

  const tabs = useSelector((state: RootState) => {
    return state.inventarios.items.tabs
  })
  const currentAction = useSelector(
    (state: RootState) => state.inventarios.items.general.currentFunction,
  )
  const itemState = useSelector(
    (state: RootState) => state.inventarios.items.editData[tabId],
  )
  const wooUrlCompany = useSelector(
    (state: RootState) => state.global.session.empresa.wooUrl,
  )
  const decPrec = useSelector((state: RootState) => {
    return state.global.session.empresa.precision
  })
  const selectedItem = useSelector(
    (state: RootState) => state.inventarios.items.search.selectedItem,
  )
  const preciosIva = useSelector(
    (state: RootState) => state.global.session.empresa.preciosIva,
  )
  const ipWeb = useSelector(
    (state: RootState) => state.global.session?.empresa.ipWeb,
  )
  const local = useSelector((state: RootState) => state.global.session.local)
  const itemBackup = useSelector(
    (state: RootState) => state.inventarios.items.editData[tabId].backup,
  )

  const setToastMessage = useSetToast()

  const [confirmarNuevo, setconfirmarNuevo] = React.useState<boolean>(false)
  const [currentTab, serCurrentTab] = React.useState<string>(tabsNewItem[0])
  const [validationFormErrors, setValidationFormErrors] = React.useState<
    Array<any>
  >([])
  const [showPopupError, setShowPopupError] = React.useState<boolean>(false)
  const [showAssemblyKit, setShowAssemblyKit] = React.useState<boolean>(false)
  const [newItem, setNewItem] = React.useState<boolean>(false)
  const validationGroupRef = React.useRef<any>(null)
  const dialogRef = React.useRef<any>(null)

  const playLoader = React.useCallback(
    (message = 'Cargando...') => {
      dispatch(changeLoaderItems({ show: true, mensaje: message }))
    },
    [dispatch],
  )

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoaderItems({ show: false, mensaje: '' }))
  }, [dispatch])

  const openNewTab = useCallback(() => {
    const id = uuidv4()
    const dataItem = structuredClone(defaultDataItem)
    dataItem.generales.proyecto = {
      codigo: local.codigo ?? -1,
      siglasLocal: '',
    }
    dispatch(
      initDatosEdicion({
        key: id,
        data: dataItem,
      }),
    )
    dispatch(openTab({ key: id }))
  }, [dispatch, local?.codigo])

  useEffect(() => {
    if (newItem === true) {
      setNewItem(false)
      openNewTab()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newItem])

  const onConfirmarNuevo = React.useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea crear un nuevo registro?`,
      actions: [
        Dialog.Action(
          <span>
            <u>P</u>estaña Actual
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-success',
          'p',
        ),
        Dialog.Action(
          <span>
            <u>N</u>ueva Pestaña
          </span>,
          (dialog) => {
            dialog.hide()
            setNewItem(true)
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setNewItem(false)
    setconfirmarNuevo(false)
    return
  }, [])

  const onChangedGenericName = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setDataToEdit({
            key: tabId,
            item: {
              ...itemState,
              nombreGenerico: event.value,
            },
          }),
        )
    },
    [dispatch, itemState, tabId],
  )

  const onChangedName = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setDataToEdit({
            key: tabId,
            item: {
              ...itemState,
              nombre: event.value,
            },
          }),
        )
    },
    [dispatch, itemState, tabId],
  )

  const onChangedCode = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setDataToEdit({
            key: tabId,
            item: {
              ...itemState,
              codigo: event.value,
            },
          }),
        )
    },
    [dispatch, itemState, tabId],
  )

  const onChangedBarCode = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setDataToEdit({
            key: tabId,
            item: {
              ...itemState,
              barras: event.value,
            },
          }),
        )
    },
    [dispatch, itemState, tabId],
  )

  const generateBarcode = useCallback(async () => {
    try {
      playLoader('Generando Código de Barras . . .')
      const dataToGenerateBarCode = {
        procedencia: itemState.generales.procedencia?.label ?? '',
        linea: itemState.generales.linea?.descripcion ?? '',
        grupo: itemState.generales.grupo?.descripcion ?? '',
        descripcion: itemState.nombre,
        proyecto: itemState.generales.proyecto?.siglasLocal ?? '',
      }
      const barCode = await ItemsServices.generateBarCode(dataToGenerateBarCode)
      stopLoader()
      if (!barCode.error && barCode.auto) {
        setToastMessage(
          'Código de barras',
          'Código de barras generado correctamente',
          ToastTypes.Success,
        )
        dispatch(
          setDataToEdit({
            key: tabId,
            item: {
              ...itemState,
              barras: barCode.auto,
              codigo: barCode.auto,
            },
          }),
        )
      } else
        setToastMessage(
          'Código de barras',
          barCode.message ?? 'Error al generar código de barras',
          ToastTypes.Warning,
        )
    } catch (error) {
      stopLoader()
      setToastMessage('Código de barras', error, ToastTypes.Danger)
    }
  }, [dispatch, itemState, playLoader, setToastMessage, stopLoader, tabId])

  React.useEffect(() => {
    if (confirmarNuevo) onConfirmarNuevo()
  }, [confirmarNuevo, onConfirmarNuevo])

  const validateFormPrices = useCallback(() => {
    const formErros: Array<string> = []
    if (itemState.preciosControl.pvd > itemState.preciosControl.pvp)
      formErros.push('PVD debe ser menor ó igual a PVP')
    if (
      itemState.generales.tipoItem.label != 'Otros/Gastos' &&
      itemState.generales.tipoItem.label != 'Activo Fijo'
    ) {
      if (itemState.preciosControl.dataGridPrecios?.length > 0) {
        itemState.preciosControl.dataGridPrecios.map((price: PricesItems) => {
          if (price.precioIva == 0 && price.tipoClienteCodigo <= 2)
            formErros.push(`Precio ${price.tipoClienteDescripcion}`)
        })
      } else formErros.push('No existen precios para el item')
    } else {
      const priceItems = structuredClone(itemState.preciosControl)
      priceItems.dataGridPrecios.map((price: PricesItems) => {
        price.precioIva = 0
      })
      dispatch(
        setDataPricesControlToEdit({
          key: tabId,
          priceControl: {
            ...priceItems,
          },
        }),
      )
    }
    return { isValid: formErros.length === 0, errors: formErros }
  }, [dispatch, itemState, tabId])

  const validateFormActives = useCallback(() => {
    const formErros: Array<string> = []
    if (
      itemState.activos.dataGrid?.length === 0 &&
      itemState.generales.tipoItem.value === 7
    )
      formErros.push('No existen registradas Características del Activo')
    return { isValid: formErros.length === 0, errors: formErros }
  }, [itemState])

  const validateKardexItem = useCallback(() => {
    const formErros: Array<string> = []
    if (
      itemState.codigoInterno != 0 &&
      itemState.numKardex > 0 &&
      itemState.generales.estado.codigo === 2
    )
      formErros.push('Estado, el item tiene MOVIMIENTOS en el inventario')
    return { isValid: formErros.length === 0, errors: formErros }
  }, [itemState])

  const validateFormImages = useCallback(() => {
    const formErros: Array<string> = []
    if (
      itemState.generales.tipoItem.value <= 3 &&
      wooUrlCompany != '' &&
      itemState.generales.mobile === true &&
      itemState.generales.jerarquia.value === 2 &&
      (itemState.imagenes[0].name === '' ||
        itemState.imagenes[0].name === 'vacio.jpg')
    )
      formErros.push(
        'Imagen, está seleccionada la opción WEB, por lo que deberá subir una imagen para su e-commerce',
      )
    return { isValid: formErros.length === 0, errors: formErros }
  }, [itemState, wooUrlCompany])

  const onHandleSave = React.useCallback(async () => {
    dispatch(
      setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: ButtonTypes.save,
      }),
    )
    const validationPrices = validateFormPrices()
    const validationActives = validateFormActives()
    const validationKardex = validateKardexItem()
    const validationImages = validateFormImages()
    let validationFormResult = validationGroupRef.current.instance.validate()
    if (validationFormResult.status === 'pending')
      validationFormResult = await validationFormResult.complete
    if (
      validationFormResult.isValid === false ||
      validationPrices.isValid === false ||
      validationActives.isValid === false ||
      validationKardex.isValid === false
    ) {
      const errorList = []
      validationFormResult.brokenRules.map((rule) => {
        errorList.push(rule.message)
      })
      validationPrices.errors.map((error) => {
        errorList.push(error)
      })
      validationActives.errors.map((error) => {
        errorList.push(error)
      })
      validationKardex.errors.map((error) => {
        errorList.push(error)
      })
      validationImages.errors.map((error) => {
        errorList.push(error)
      })
      setValidationFormErrors(errorList)
      setShowPopupError(true)
      setToastMessage(
        'Guardar Item',
        'Existen Errores en los datos, por favor verifiquelos.',
        ToastTypes.Warning,
      )
      dispatch(
        setCurrentExecutingAction({
          tabKey: tabId,
          buttonType: undefined,
        }),
      )
    } else {
      try {
        playLoader('Guardando Item . . . ')
        let pvdToSave: number = 0
        let pvpToSave: number = 0
        const pricesTosave: Array<PricesItems> = structuredClone(
          itemState.preciosControl.dataGridPrecios,
        )
        if (itemState.preciosControl.incluyenIva) {
          pvdToSave =
            itemState.preciosControl.pvd /
            (1 + itemState.preciosControl.tipoImpuesto.valor / 100)
          pvpToSave =
            itemState.preciosControl.pvp /
            (1 + itemState.preciosControl.tipoImpuesto.valor / 100)

          pricesTosave.map((price: PricesItems) => {
            price.precio = parseFloat(
              (
                price.precioIva /
                (1 + itemState.preciosControl.tipoImpuesto.valor / 100)
              ).toFixed(decPrec),
            )
          })
        } else {
          pvdToSave = itemState.preciosControl.pvd
          pvpToSave = itemState.preciosControl.pvp

          pricesTosave.map((price: PricesItems) => {
            price.precio = price.precioIva
          })
        }

        let characteristicsToSave: Array<Characteristic> = []
        if (itemState.generales.tipoItem.value === 7)
          characteristicsToSave = structuredClone(itemState.activos.dataGrid)

        const promotionsToSave = structuredClone(itemState.promociones.dataGrid)
        if (itemState.codigoInterno > 0)
          promotionsToSave.map((promotion: Promotion) => {
            if (promotion.codigo === 0) {
              promotion.desde = DateUtils.dateBoxToApiDate(promotion.desde)
              promotion.hasta = DateUtils.dateBoxToApiDate(promotion.hasta)
            }
          })

        const newItem: SaveItem = {
          infoRegistro: {
            codigo: itemState.codigoInterno,
            codigoUsu: itemState.codigo.toUpperCase(),
            barras: itemState.barras.toUpperCase(),
            nombre: itemState.nombre.toUpperCase(),
            generico: itemState.nombreGenerico.toUpperCase(),
            //GENERALES
            tipoItem: itemState.generales.tipoItem.value ?? 1,
            linea: itemState.generales.linea?.codigo ?? 0,
            grupo:
              typeof itemState.generales.grupo?.codigo === 'number'
                ? itemState.generales.grupo?.codigo
                : 0,
            marca:
              typeof itemState.generales.marca?.codigo === 'number'
                ? itemState.generales.marca?.codigo
                : 0,
            procedencia: itemState.generales.procedencia.value,
            local: itemState.generales.proyecto?.codigo ?? 0,
            tsurtido: itemState.generales.tiempoSurtido ?? 0,
            factor: itemState.generales.factor ?? 1.0,
            estado: itemState.generales.estado.codigo ?? 1,
            jerarquia: itemState.generales.jerarquia.value ?? 0,
            controlador:
              itemState.generales.controlador === null
                ? null
                : itemState.generales.controlador === true
                ? 1
                : 0,
            dinamico: itemState.generales.dinamico === true ? 1 : 0,
            modelo: itemState.generales.performance.value ?? -1,
            numserie: itemState.generales.serieImei === true ? 1 : 0,
            lotes: itemState.generales.lotes === true ? 1 : 0,
            aduanales: itemState.generales.aduanales === true ? 1 : 0,
            mobile: itemState.generales.mobile === true ? 1 : 0,
            conjunto: itemState.generales.conjuntos === true ? 1 : 0,
            receta: itemState.generales.receta === true ? 1 : 0,
            plantilla: itemState.generales.plantilla === true ? 1 : 0,
            pesado: itemState.generales.pesado === true ? 1 : 0,
            horas: itemState.generales.tiempo === true ? 1 : 0,
            minGratis: itemState.generales.minGratis ?? 0,
            tiempoMin: itemState.generales.cadaMin ?? 0,
            tarjeta: itemState.generales.tarjetas.value ?? 0,
            tClase:
              itemState.generales.tarjetas.value >= 3
                ? itemState.generales.edades.value
                : '',
            unidadc: itemState.generales.unidadCompra.codigo ?? 34,
            unidadv: itemState.generales.unidadVenta.codigo ?? 34,
            unidade: itemState.generales.unidadPorEmpaque ?? 0,
            ticket: itemState.generales.ticket === true ? 1 : 0,
            paquetes: itemState.generales.paquetes === true ? 1 : 0,
            codRetencion:
              itemState.cuentasContables.retencion.planCuentasCodigo ?? 0,
            //PRECIOS Y CONTROL
            gratuito: itemState.preciosControl.gratuito === true ? 1 : 0,
            descuento: itemState.preciosControl.sinDescuento === true ? 1 : 0,
            limite: itemState.preciosControl.limitePrecios === true ? 1 : 0,
            precios: pricesTosave,
            pvd: parseFloat(pvdToSave.toFixed(decPrec)),
            pvp: parseFloat(pvpToSave.toFixed(decPrec)),
            gananciaPvp: itemState.preciosControl.utilidadPvp ?? 0,
            gananciaPvd: itemState.preciosControl.utilidadPvd ?? 0,
            comision: itemState.preciosControl.comision ?? 0,
            tipoImpuesto: itemState.preciosControl.tipoImpuesto.codigo ?? 0,
            tipoIce: itemState.preciosControl.tipoIce.codigo ?? 0,
            irbpnr: itemState.preciosControl.ibnpr === true ? 1 : 0,
            sustentoCodigo:
              itemState.preciosControl.sustentoTributario.codigo ?? '',
            partidaAran:
              itemState.preciosControl.partidaArancelariaLookup.codigo ?? '',
            costeo: itemState.preciosControl.costeos.value ?? 0,
            //CUENTAS CONTABLES
            cuentasContables: { ...itemState.cuentasContables },
            //EXISTENCIAS
            stockMax: itemState.existencias.maximo ?? 0,
            stockMin: itemState.existencias.minimo ?? 0,
            localizacion:
              itemState.existencias.localizacion.toUpperCase() ?? '',
            recompras: itemState.existencias.recompra?.value ?? 0,
            //PROVEEDORES
            proveedores: [...itemState.proveedores.tablaProveedores],
            //IMAGENES
            imagenes: [...itemState.imagenes],
            //APLICACIONES
            aplicaciones: [...itemState.aplicaciones],
            //ACTIVOS
            depreciacionAmor: itemState.activos.tipo.value ?? 0,
            fechaDisponible:
              itemState.activos.tipo.value === 3
                ? DateUtils.dateBoxToApiDate(itemState.activos.fecha) ?? ''
                : '',
            caracteristicas: [...characteristicsToSave],
            sitContable: 0,
            condicionActivo: itemState.activos.condicion?.codigo ?? 0,
            anios: itemState.activos.anios ?? 0,
            coef: itemState.activos.coef ?? 0,
            metododepreciacion:
              parseInt(itemState.activos.metodoDep?.value) ?? 0,
            metodoDepreciacionInf:
              parseInt(itemState.activos.metodoDepInf?.value) ?? 0,
            tipoDepreciacion: itemState.activos.tipoDep?.codigo ?? 0,
            valorResidual: itemState.activos.valorResidual ?? 0,
            factorDepreciacion: itemState.activos.factor ?? 0,
            cuentaDepreciacion: { ...itemState.activos.depreciacion },
            cuentaDepreciacionAcu: {
              ...itemState.activos.depreciacionAcumulada,
            },
            //PARTES
            partes: { ...itemState.partes },
            //PROMOCIONES
            promociones: { ...promotionsToSave },
            //HISTORICOS
            //EXTRAS
            virtual: itemState.extras.virtual.value ?? 0,
            individual: itemState.extras.venderIndividual.value ?? 0,
            bajoPedido: itemState.extras.venderBajoPedido.value ?? 0,
            peso: itemState.extras.peso ?? 0,
            largo: itemState.extras.largo ?? 0,
            ancho: itemState.extras.ancho ?? 0,
            alto: itemState.extras.alto ?? 0,
            nota: itemState.extras.nota ?? '',
            tags: itemState.extras.tags ?? '',
            especificaciones: itemState.extras.especificaciones ?? '',
            codigoImpuesto: itemState.extras.codImpuesto ?? 0,
            capacidadCmCubicos: itemState.extras.capacidadCC ?? 0,
            codigoClasificacion: itemState.extras.codClasificacion ?? 0,
            codigoUnidad: itemState.extras.codUnidad ?? 0,
            codigoMarca: itemState.extras.codMarca ?? 0,
            paisOrigen: itemState.extras.paisOrigen ?? 0,
            codigoPresentacion: itemState.extras.codPresentacion ?? 0,
            gradoAlcolico: itemState.extras.gradoAlcohol ?? 0,
            codGradoAlcohol: itemState.extras.codGradoAlcohol ?? 0,
            estadoIce: itemState.extras.estado ?? 0,
            gramosAzucar: itemState.extras.gramoAzucar ?? 0,
            impresora: itemState.extras.impresora.codigo ?? 0,
            uafe: itemState.extras.uafe === true ? 1 : 0,
            nivelMls: itemState.extras.nivelMls ?? '',
            deducible: itemState.extras.deducible.value ?? 0,
            tipoDeducible: itemState.extras.tiposDeducible.value ?? 0,
            planes: itemState.extras.planes.codigoBase ?? 0,
            subsidio: itemState.extras.subsidio.codigo ?? 0,
            liquidacionTrasporte: itemState.extras.liqTansporte.value ?? '',
            desperdicio: itemState.extras.desperdicio ?? 0,
            costoProduccion: itemState.extras.costoProduccion ?? 0,
            cmuAncho: itemState.extras.cmuAncho ?? 0,
            cmuLargo: itemState.extras.cmuLargo ?? 0,
            //OBSERVACIONES
            observaciones: itemState.observaciones.observaciones ?? '',
            costoReferencial: itemState.observaciones.costoReferencial ?? 0,
            modificado: '',
            tituloAdicional: itemState.observaciones.titulo ?? '',
            valorAdicional: itemState.observaciones.valor ?? '',
            manualPdf: itemState.observaciones.manual ?? '',
            especificacionesPdf: itemState.observaciones.especificaciones ?? '',
            //ATRIBUTOS (propiedaddes)
            propiedades: [...itemState.atributos.dataGrid],
          },
        }
        const saveItem = await ItemsServices.saveItem(newItem)
        stopLoader()
        dispatch(
          setCurrentExecutingAction({
            tabKey: tabId,
            buttonType: undefined,
          }),
        )
        if (!saveItem.error && saveItem.auto) {
          dispatch(
            setDataToEdit({
              key: tabId,
              item: {
                ...itemState,
                codigoInterno:
                  itemState.codigoInterno === 0
                    ? Number(saveItem.auto)
                    : itemState.codigoInterno,
                saved: true,
              },
            }),
          )
          dispatch(
            setNameTab({
              key: tabId,
              codigoBarras: itemState.barras,
            }),
          )
          setToastMessage(
            'Guardar Item',
            saveItem.message ?? 'Item registrado correctamente',
            ToastTypes.Success,
          )
          if (itemState.generales.tipoItem.value === 3) setShowAssemblyKit(true)
          else {
            dispatch(closeTabMultistore(tabId))
            await utilidades.sleep(50)
            dispatch(deleteEditData(tabId))
          }
        } else {
          setToastMessage(
            'Guardar Item',
            saveItem.message ?? 'Error al registrar el item',
            ToastTypes.Warning,
          )
        }
      } catch (err) {
        stopLoader()
        dispatch(
          setCurrentExecutingAction({
            tabKey: tabId,
            buttonType: undefined,
          }),
        )
        setToastMessage(
          'Guardar Item',
          err ?? 'Error al registrar el item',
          ToastTypes.Danger,
        )
      }
    }
  }, [
    validateFormPrices,
    validateFormActives,
    validateKardexItem,
    validateFormImages,
    setToastMessage,
    itemState,
    decPrec,
    playLoader,
    stopLoader,
    dispatch,
    tabId,
  ])

  const getItemDetails = useCallback(async () => {
    try {
      const newItemData = structuredClone(defaultDataItem)
      const loadErrors: Array<string> = []
      playLoader('Cargando Item . . .')
      dispatch(
        setCurrentExecutingAction({
          tabKey: tabId,
          buttonType: ButtonTypes.new,
        }),
      )
      const itemCode =
        tabs.tabs[tabId].editStatus === StatesEdition.save ||
        tabs.tabs[tabId].editStatus === StatesEdition.edit
          ? selectedItem.itemCodigo
          : 0

      const pricesItems = await ItemsServices.getPricesItem(itemCode)
      let pricesItemsArray: Array<PricesItems> = []
      if (!pricesItems.error && pricesItems.auto)
        pricesItemsArray = [...pricesItems.auto]
      else loadErrors.push('Precios')
      newItemData.preciosControl.dataGridPrecios = [...pricesItemsArray]

      if (
        tabs.tabs[tabId].editStatus === StatesEdition.save ||
        tabs.tabs[tabId].editStatus === StatesEdition.edit
      ) {
        const accoutingAccounts = await ItemsServices.getAccoutingAccounts(
          selectedItem.itemCodigo,
          'ITEM',
        )
        if (!accoutingAccounts.error && accoutingAccounts.auto) {
          if (accoutingAccounts.auto.length > 0) {
            accoutingAccounts.auto.map((account: LedgerAccount) => {
              switch (account.transaccion) {
                case 'VENTA':
                  newItemData.cuentasContables.venta = { ...account }
                  break
                case 'COMPRA':
                  newItemData.cuentasContables.compra = { ...account }
                  break
                case 'INVENTARIO':
                  newItemData.cuentasContables.inventario = { ...account }
                  break
                case 'COSTOVENTAS':
                  newItemData.cuentasContables.costoVentas = { ...account }
                  break
                case 'CUSTODIA':
                  newItemData.cuentasContables.custodia = { ...account }
                  break
                case 'DEPRECIACION':
                  newItemData.activos.depreciacion = { ...account }

                  break
                case 'DEPACUMULADA':
                  newItemData.activos.depreciacionAcumulada = { ...account }
                  break
              }
            })
          }
        } else loadErrors.push('Cuentas Contables')

        let existenceItems: any = null
        if (selectedItem.itemTipo === 3)
          existenceItems = await ItemsServices.getItemExistenceKitStore(
            selectedItem.itemCodigo,
            selectedItem.itemLoteDescripcion === 'SI' ? 1 : 0,
          )
        else
          existenceItems = await ItemsServices.getItemExistenceStore(
            selectedItem.itemCodigo,
            selectedItem.itemLoteDescripcion === 'SI' ? 1 : 0,
          )
        let existenceItemsArray: Array<{
          local: string
          existencia: number
        }> = []
        if (!existenceItems.error && existenceItems.auto) {
          existenceItemsArray = [...existenceItems.auto]
        } else loadErrors.push('Existencias')

        const suppliers = await ItemsServices.getSuppliers(
          selectedItem.itemCodigo,
        )
        let suppliersArray: Array<SupplierTable> = []
        if (!suppliers.error && suppliers.auto) {
          suppliersArray = [...suppliers.auto]
        } else loadErrors.push('Proveedores')

        const aplications = await ItemsServices.getAplications(
          selectedItem.itemCodigo,
        )
        const aplicationsArray: Array<AplicacionInventario> = []
        if (!aplications.error && aplications.auto) {
          aplications.auto.forEach((aplication) => {
            aplicationsArray.push({
              codigo: aplication.aplicacionCodigo ?? 0,
              descripcion: aplication.aplicacionDescripcion ?? '',
              observaciones: '',
              pertenece: 0,
              perteneceDescripcion: '',
              estado: 1,
            })
          })
        } else loadErrors.push('Aplicaciones')

        let characteristics: any = null
        const characteristicsArray: Array<Characteristic> = []
        if (selectedItem.itemTipo === 7) {
          characteristics = await ItemsServices.getCharacteristics(
            selectedItem.itemCodigo,
          )
          if (!characteristics.error && characteristics.auto) {
            characteristics.auto.forEach((characteristic) => {
              characteristicsArray.push({
                codigo: characteristic.caracteristicaCodigo ?? 0,
                nombre: characteristic.caracteristicaNombre ?? '',
                estado: 1,
                estadoDescripcion: '',
                valor: characteristic.valor ?? 0,
              })
            })
          } else loadErrors.push('Caracteristicas Activos')
        }

        const parts = await ItemsServices.getParts(selectedItem.itemCodigo)
        let partsArray: Array<Parts> = []
        if (!parts.error && parts.auto) {
          partsArray = [...parts.auto]
        } else loadErrors.push('Partes Relacionadas')

        const promotions = await ItemsServices.getPromotions(
          selectedItem.itemCodigo,
        )
        let promotionsArray: Array<Promotion> = []
        if (!promotions.error && promotions.auto) {
          promotionsArray = [...promotions.auto]
        } else loadErrors.push('Promociones')

        const itemDetails = await ItemsServices.getItemDetails(
          selectedItem.itemCodigo,
        )
        if (itemDetails.length === 0) loadErrors.push('Detalles Item')

        if (loadErrors.length > 0) {
          const messageError: string = `Error al cargar los siguientes datos:
            ${loadErrors.join(', ')}`
          setToastMessage(
            'Cargar Detalles Item',
            messageError,
            ToastTypes.Danger,
          )
        }

        newItemData.loaded = true
        newItemData.codigoInterno =
          tabs.tabs[tabId].editStatus === StatesEdition.edit
            ? 0
            : itemDetails.codigo
        newItemData.codigo = itemDetails.codigoint
        newItemData.barras =
          tabs.tabs[tabId].editStatus === StatesEdition.edit
            ? ''
            : itemDetails.barras
        newItemData.nombre = itemDetails.descripcion
        newItemData.nombreGenerico = itemDetails.generico
        //  GENERALES
        newItemData.generales.tipoItem =
          optionsTypesNewItem[itemDetails.tipo - 1] ?? null
        newItemData.generales.jerarquia =
          optionsHierarchy[itemDetails.jerarquia] ?? null
        newItemData.generales.controlador =
          itemDetails.controlador === 1 ? true : false
        newItemData.generales.dinamico =
          itemDetails.dinamico === 1 ? true : false
        newItemData.generales.linea = {
          codigo: itemDetails.lineaCod ?? 0,
          descripcion: itemDetails.lineaDes ?? '',
        }
        newItemData.generales.grupo = {
          codigo: itemDetails.grupoCod ?? 0,
          descripcion: itemDetails.grupoDes ?? '',
        }
        newItemData.generales.marca = {
          codigo: itemDetails.marcaCod ?? 0,
          nombre: itemDetails.marcaDes ?? '',
        }
        newItemData.generales.procedencia = {
          value: itemDetails.procedencia ?? -1,
          label: '',
        }
        newItemData.generales.proyecto = {
          codigo: itemDetails.locProyecto ?? -1,
          siglasLocal: '',
        }
        newItemData.generales.tiempoSurtido = itemDetails.tiempoSurtido ?? 1
        newItemData.generales.unidadCompra = {
          codigo: itemDetails.unidadComCod ?? 0,
          nombre: itemDetails.unidadComDes ?? '',
        }
        newItemData.generales.unidadVenta = {
          codigo: itemDetails.unidadVenCod ?? 0,
          nombre: itemDetails.unidadVenDes ?? '',
        }
        newItemData.generales.unidadPorEmpaque = itemDetails.unidadEmpaque ?? 0
        newItemData.generales.factor = itemDetails.factor ?? 0
        newItemData.generales.salida = itemDetails.factor ?? 0
        newItemData.generales.entrada = itemDetails.factor > 0 ? 1 : 0
        newItemData.generales.estado = {
          codigo: itemDetails.estado ?? -1,
          descripcion: '',
        }
        newItemData.generales.performance = {
          value: itemDetails.modelo ?? 0,
          label: '',
        }
        newItemData.generales.serieImei =
          itemDetails.series === 1 ? true : false
        newItemData.generales.lotes = itemDetails.lotes === 1 ? true : false
        newItemData.generales.mobile = itemDetails.mobile === 1 ? true : false
        newItemData.generales.conjuntos =
          itemDetails.conjunto === 1 ? true : false
        newItemData.generales.plantilla =
          itemDetails.plantilla === 1 ? true : false
        newItemData.generales.pesado = itemDetails.pesado === 1 ? true : false
        newItemData.generales.receta = itemDetails.receta === 1 ? true : false
        newItemData.generales.tiempo = itemDetails.horas === 1 ? true : false
        newItemData.generales.minGratis = itemDetails.minGratis ?? 0
        newItemData.generales.cadaMin = itemDetails.cadaMin ?? 0
        newItemData.generales.tarjetas =
          optionsGroupsTarj[itemDetails.tarjeta] ?? null
        const indexEdades = optionsAges.findIndex(
          (item) => item.value === itemDetails.edad,
        )
        newItemData.generales.edades =
          indexEdades === -1 ? null : optionsAges[indexEdades]
        newItemData.generales.ticket = itemDetails.ticket === 1 ? true : false
        newItemData.generales.paquetes =
          itemDetails.paquetes === 1 ? true : false
        //PRECIOS CONTROL
        newItemData.preciosControl.incluyenIva = preciosIva === 1 ? true : false
        newItemData.preciosControl.gratuito =
          itemDetails.freeitem === 1 ? true : false
        newItemData.preciosControl.sinDescuento =
          itemDetails.descuentos === 1 ? true : false
        newItemData.preciosControl.limitePrecios =
          itemDetails.limites === 1 ? true : false
        newItemData.preciosControl.costo =
          itemDetails.costo === 0
            ? itemDetails.costoProduccion
            : itemDetails.costo
        newItemData.preciosControl.dataGridPrecios = [...pricesItemsArray]
        let pvpValue: number = 0
        let pvdValue: number = 0
        if (preciosIva === 1) {
          pvpValue = itemDetails.pvp * (1 + itemDetails.porcentajeIva / 100)
          pvdValue = itemDetails.pvd * (1 + itemDetails.porcentajeIva / 100)
        } else {
          pvpValue = itemDetails.pvp
          pvdValue = itemDetails.pvd
        }
        newItemData.preciosControl.pvp =
          parseFloat(pvpValue.toFixed(decPrec)) ?? 0
        newItemData.preciosControl.utilidadPvp = itemDetails.utilidadPvp ?? 0
        newItemData.preciosControl.pvd =
          parseFloat(pvdValue.toFixed(decPrec)) ?? 0
        newItemData.preciosControl.utilidadPvd = itemDetails.utIlidadPvd ?? 0
        newItemData.preciosControl.comision = itemDetails.comision ?? 0
        newItemData.preciosControl.tipoImpuesto = {
          codigo: itemDetails.impuesto ?? -1,
          descripcion: '',
          valor: 0,
        }
        newItemData.preciosControl.tipoIce = { codigo: itemDetails.ice ?? -1 }
        newItemData.preciosControl.ibnpr =
          itemDetails.ibnpr === 1 ? true : false
        newItemData.preciosControl.sustentoTributario = {
          codigo: itemDetails.sustentoTributario ?? '',
          tipo: '',
          estado: 0,
          descripcionEstado: '',
        }
        const indexCosteos = optionsGroupsCosteo.findIndex(
          (item) => item.value === itemDetails.costeos,
        )
        newItemData.preciosControl.costeos =
          indexCosteos === -1 ? null : optionsGroupsCosteo[indexCosteos]
        newItemData.preciosControl.partidaArancelariaLookup = {
          codigo: itemDetails.partidaArancelaria ?? '',
          designacion: '',
          unidad: '',
          tarifa: 0,
          observaciones: '',
          estado: 0,
          estadoDescripcion: '',
        }
        //CUENTAS CONTABLES
        newItemData.cuentasContables.retencion.planCuentasCodigo =
          itemDetails.codRetencion ?? 0
        newItemData.cuentasContables.retencion.planCuentasDescripcion =
          itemDetails.conceptoRetencion ?? ''
        //EXISTENCIAS
        newItemData.existencias.existencia =
          parseFloat(itemDetails.existencia.toFixed(decPrec)) ?? 0
        newItemData.existencias.reservado =
          parseFloat(itemDetails.reserva.toFixed(decPrec)) ?? 0
        newItemData.existencias.maximo =
          parseFloat(itemDetails.maximo.toFixed(decPrec)) ?? 0
        newItemData.existencias.minimo =
          parseFloat(itemDetails.minimo.toFixed(decPrec)) ?? 0
        newItemData.existencias.localizacion = itemDetails.localizacion ?? ''
        newItemData.existencias.recompra =
          itemDetails.recompra === 0
            ? null
            : optionsRecompras[itemDetails.recompra - 1]
        newItemData.existencias.localExistencia = [...existenceItemsArray]
        //PROVEEDORES
        newItemData.proveedores.tablaProveedores = [...suppliersArray]
        //IMAGENES
        newItemData.imagenes[0] = {
          show: itemDetails.imagen !== '' ? true : false,
          name: itemDetails.imagen !== '' ? itemDetails.imagen : '',
          base64:
            itemDetails.imagen !== ''
              ? `${ipWeb}/SolucionEmpresarial/SigNum/fotos/${itemDetails.imagen}`
              : '',
        }
        newItemData.imagenes[1] = {
          show: itemDetails.imagen1 !== '' ? true : false,
          name: itemDetails.imagen1 !== '' ? itemDetails.imagen1 : '',
          base64:
            itemDetails.imagen1 !== ''
              ? `${ipWeb}/SolucionEmpresarial/SigNum/fotos/${itemDetails.imagen1}`
              : '',
        }
        newItemData.imagenes[2] = {
          show: itemDetails.imagen2 !== '' ? true : false,
          name: itemDetails.imagen2 !== '' ? itemDetails.imagen2 : '',
          base64:
            itemDetails.imagen2 !== ''
              ? `${ipWeb}/SolucionEmpresarial/SigNum/fotos/${itemDetails.imagen2}`
              : '',
        }
        newItemData.imagenes[3] = {
          show: itemDetails.imagen3 !== '' ? true : false,
          name: itemDetails.imagen3 !== '' ? itemDetails.imagen3 : '',
          base64:
            itemDetails.imagen3 !== ''
              ? `${ipWeb}/SolucionEmpresarial/SigNum/fotos/${itemDetails.imagen3}`
              : '',
        }
        newItemData.imagenes[4] = {
          show: itemDetails.imagen4 !== '' ? true : false,
          name: itemDetails.imagen4 !== '' ? itemDetails.imagen4 : '',
          base64:
            itemDetails.imagen4 !== ''
              ? `${ipWeb}/SolucionEmpresarial/SigNum/fotos/${itemDetails.imagen4}`
              : '',
        }
        newItemData.imagenes[5] = {
          show: itemDetails.imagen5 !== '' ? true : false,
          name: itemDetails.imagen5 !== '' ? itemDetails.imagen5 : '',
          base64:
            itemDetails.imagen5 !== ''
              ? `${ipWeb}/SolucionEmpresarial/SigNum/fotos/${itemDetails.imagen5}`
              : '',
        }
        //APLICACIONES
        newItemData.aplicaciones = [...aplicationsArray]
        //ACTIVOS
        newItemData.activos.tipo =
          itemDetails.tipoAmortizacion > 0
            ? optionsDepreciacion[itemDetails.tipoAmortizacion - 1]
            : optionsDepreciacion[0]
        newItemData.activos.fecha =
          itemDetails.tipoAmortizacion === 3 ? itemDetails.fechaDisponible : ''
        newItemData.activos.dataGrid = [...characteristicsArray]
        //DEPRECIACIO Y ACUMULADA OBTENER DE ASIGNACIONES
        newItemData.activos.condicion = {
          codigo: itemDetails.condicionActivo ?? null,
          descripcion: '',
          denominacion: '',
        }
        newItemData.activos.metodoDep = {
          value: itemDetails.metodoDepreciacion ?? '',
          label: '',
        }
        newItemData.activos.metodoDepInf = {
          value: itemDetails.metodoDepreciacionInf ?? '',
          label: '',
        }
        newItemData.activos.tipoDep = {
          codigo: itemDetails.tipoDepreciacion ?? 0,
          descripcion: '',
          porcentaje: 0,
          vidaUtil: 0,
          vidaUtilInf: 0,
          estado: 0,
          depamo: 0,
        }
        newItemData.activos.valorResidual = itemDetails.valorResidual ?? 0
        newItemData.activos.factor = itemDetails.factorDepreciacion ?? 0
        //PARTES
        newItemData.partes = [...partsArray]
        //PROMOCIONES
        newItemData.promociones.dataGrid = [...promotionsArray]
        //EXTRAS
        newItemData.extras.virtual =
          itemDetails.virtual === 1 ? optionsVirtual[0] : optionsVirtual[1]
        newItemData.extras.venderIndividual =
          itemDetails.venderIndividual === 1
            ? optionsVirtual[0]
            : optionsVirtual[1]
        const indexPedido = optionsBajoPedido.findIndex(
          (item) => item.value === itemDetails.bajoPedido,
        )
        newItemData.extras.venderBajoPedido =
          indexPedido === -1
            ? optionsBajoPedido[1]
            : optionsBajoPedido[indexPedido]
        newItemData.extras.peso = itemDetails.peso ?? 0
        newItemData.extras.largo = itemDetails.largo ?? 0
        newItemData.extras.ancho = itemDetails.ancho ?? 0
        newItemData.extras.alto = itemDetails.alto ?? 0
        newItemData.extras.nota = itemDetails.nota ?? ''
        newItemData.extras.tags = itemDetails.tags ?? ''
        newItemData.extras.especificaciones = itemDetails.especificaciones ?? ''
        newItemData.extras.codImpuesto = itemDetails.codImpuestoIce ?? 0
        newItemData.extras.capacidadCC = itemDetails.capacidadCc ?? 0
        newItemData.extras.codClasificacion =
          itemDetails.codClasificacionIce ?? 0
        newItemData.extras.codUnidad = itemDetails.codUnidadIce ?? 0
        newItemData.extras.codMarca = itemDetails.codMarcaIce ?? 0
        newItemData.extras.paisOrigen = itemDetails.paisOrigen ?? 593
        newItemData.extras.codPresentacion = itemDetails.codPresentacionIce ?? 0
        newItemData.extras.gradoAlcohol = itemDetails.gradoAlcoholico ?? 0
        newItemData.extras.codGradoAlcohol = itemDetails.codGradoAlcoholico ?? 0
        newItemData.extras.estado = itemDetails.estadoIce ?? 0
        newItemData.extras.gramoAzucar =
          parseFloat(itemDetails.gramoAzucar.toFixed(decPrec)) ?? 0
        newItemData.extras.impresora = {
          codigo: itemDetails.codigoImpresora ?? -1,
          nombre: '',
        }
        newItemData.extras.uafe = itemDetails.uafe === 1 ? true : false
        newItemData.extras.nivelMls = itemDetails.nivelMsl ?? ''
        newItemData.extras.deducible =
          itemDetails.deducible === 1 ? optionsVirtual[0] : optionsVirtual[1]
        newItemData.extras.tiposDeducible = {
          value: itemDetails.tipoDeducible ?? -1,
          label: '',
        }
        newItemData.extras.planes = {
          id: itemDetails.planId ?? -1,
          codigoBase: 0,
          codigoItem: '',
          codigoPeriodo: 0,
          codigoCategoria: 0,
          cadena: '',
          plan: '',
        }
        newItemData.extras.subsidio = {
          codigo: itemDetails.subsidio ?? -1,
          desde: '',
          hasta: '',
          nombre: '',
          valor: -1,
        }
        const indexLiqTrans = optionsLiqTransporte.findIndex(
          (item) => item.value === itemDetails.liquidacionTransporte,
        )
        newItemData.extras.liqTansporte =
          indexLiqTrans === -1
            ? optionsLiqTransporte[2]
            : optionsLiqTransporte[indexLiqTrans]
        newItemData.extras.desperdicio =
          parseFloat(itemDetails.desperdicio.toFixed(decPrec)) ?? 0
        newItemData.extras.costoProduccion =
          parseFloat(itemDetails.costoProduccion.toFixed(decPrec)) ?? 0
        newItemData.extras.cmuLargo =
          parseFloat(itemDetails.cmuLargo.toFixed(decPrec)) ?? 0
        newItemData.extras.cmuAncho =
          parseFloat(itemDetails.cmuAncho.toFixed(decPrec)) ?? 0
        //  OBSERVACIONES
        newItemData.observaciones.observaciones =
          itemDetails.observaciones ?? ''
        newItemData.observaciones.costoReferencial =
          parseFloat(itemDetails.costoReferencial.toFixed(2)) ?? 0
        newItemData.observaciones.costoReal =
          parseFloat(itemDetails.pvp.toFixed(2)) ?? 0
        newItemData.observaciones.modificado =
          itemDetails.fechaModificado !== '' &&
          itemDetails.fechaModificado !== '00/00/0000'
            ? DateUtils.apiDateToPickersDate(itemDetails.fechaModificado)
            : ''
        newItemData.observaciones.titulo = itemDetails.titadicional ?? ''
        newItemData.observaciones.valor = itemDetails.valadicional ?? ''
        newItemData.observaciones.manual = itemDetails.manual ?? ''
        newItemData.observaciones.especificaciones =
          itemDetails.especificacion ?? ''
        //ATRIBUTOS
        if (itemDetails.atributos?.length > 0)
          itemDetails.atributos.forEach((atributo) => {
            newItemData.atributos.dataGrid.push({
              codigo: Number(atributo.PRO_CODIGO) ?? 0,
              descripcion: atributo.PRO_DESCRIPCION ?? '',
              valor: atributo.PRI_VALOR ?? '',
            })
          })

        dispatch(
          setDatosEdicion({
            key: tabId,
            data: newItemData,
          }),
        )
      } else {
        dispatch(
          setIvaInclude({
            key: tabId,
            include: preciosIva === 1 ? true : false,
          }),
        )
        dispatch(
          setLoaded({
            key: tabId,
            loaded: true,
          }),
        )
        dispatch(
          setDataPricesControlToEdit({
            key: tabId,
            priceControl: {
              ...newItemData.preciosControl,
              dataGridPrecios: [...pricesItems.auto],
              costeos: optionsGroupsCosteo[2],
            },
          }),
        )
        dispatch(
          setDataGeneralsToEdit({
            key: tabId,
            generals: {
              ...newItemData.generales,
              tipoItem: optionsTypesNewItem[0],
              jerarquia: optionsHierarchy[0],
              tarjetas: optionsGroupsTarj[0],
            },
          }),
        )
        dispatch(
          setDataActivesToEdit({
            key: tabId,
            actives: {
              ...newItemData.activos,
              tipo: optionsDepreciacion[0],
            },
          }),
        )
        dispatch(
          setDataExtrasToEdit({
            key: tabId,
            extras: {
              ...newItemData.extras,
              virtual: optionsVirtual[1],
              venderIndividual: optionsVirtual[1],
              venderBajoPedido: optionsBajoPedido[1],
              deducible: optionsVirtual[1],
              liqTansporte: optionsLiqTransporte[2],
            },
          }),
        )
      }
      dispatch(
        setDatosEdicionBackup({
          key: tabId,
          data: newItemData,
        }),
      )
      stopLoader()
      dispatch(
        setCurrentExecutingAction({
          tabKey: tabId,
          buttonType: undefined,
        }),
      )
    } catch (error) {
      stopLoader()
      dispatch(
        setCurrentExecutingAction({
          tabKey: tabId,
          buttonType: undefined,
        }),
      )
      setToastMessage(
        'Cargar Detalles Item',
        error ?? 'Error al cargar detalles del item',
        ToastTypes.Danger,
      )
    }
  }, [
    dispatch,
    playLoader,
    selectedItem,
    setToastMessage,
    stopLoader,
    decPrec,
    preciosIva,
    ipWeb,
    tabId,
    tabs,
  ])

  useEffect(() => {
    if (itemState.loaded === false) getItemDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onHandleUndo = useCallback(() => {
    if (
      tabs.tabs[tabId].editStatus === StatesEdition.save ||
      tabs.tabs[tabId].editStatus === StatesEdition.edit
    )
      dispatch(
        setDatosEdicion({
          key: tabId,
          data: { ...itemBackup, backup: itemBackup },
        }),
      )
    else
      dispatch(
        setDatosEdicion({
          key: tabId,
          data: {
            ...defaultDataItem,
            preciosControl: {
              ...defaultDataItem.preciosControl,
              dataGridPrecios: [...itemBackup.preciosControl.dataGridPrecios],
            },
            backup: itemBackup,
          },
        }),
      )
  }, [dispatch, itemBackup, tabId, tabs?.tabs])

  const handleButtonClick = React.useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.save:
          if (tabs.current === tabId) onHandleSave()
          break
        case ButtonTypes.new:
          if (tabs.current === tabId) setconfirmarNuevo(true)
          break
        case ButtonTypes.undo:
          if (tabs.current === tabId) onHandleUndo()
          break
        default:
          break
      }
      dispatch(setCurrentFunction(''))
      dispatch(clearButtonClick(tabId))
    },
    [dispatch, onHandleSave, onHandleUndo, tabId, tabs],
  )

  useEffect(() => {
    if (currentAction !== '') handleButtonClick(currentAction)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAction])

  return (
    <>
      {showAssemblyKit && (
        <ModalArmarKit
          show={showAssemblyKit}
          onClose={() => setShowAssemblyKit(false)}
          costo={itemState?.preciosControl?.costo ?? 0.0}
          precio={itemState?.preciosControl?.pvd ?? 0.0}
        />
      )}
      {showPopupError && (
        <VisualizaError
          titulo="Error en Items"
          mensaje={'Error al guardar'}
          content={
            <ListErrosValidationForm
              mainMessage={
                'Antes de guardar la información revise lo siguiente:'
              }
              errorsList={validationFormErrors}
              color={'danger'}
            />
          }
          onOk={() => setShowPopupError(false)}
        />
      )}
      <Dialog ref={dialogRef} />
      <ValidationGroup
        key={'valitadationItems'}
        id={'valitadationItems'}
        ref={validationGroupRef}
      >
        <RowContainer>
          <CustomCol xs="12" md="3">
            <Labeled label="Barras *:">
              <TextBox
                value={itemState.barras}
                placeholder=""
                onValueChanged={onChangedBarCode}
                showClearButton={true}
                width="100%"
                inputAttr={{ autocomplete: 'nope' }}
                maxLength={50}
              >
                <Validator>
                  <RequiredRule
                    message={'El campo Código de Barras es obligatorio'}
                  />
                  <StringLengthRule
                    min={3}
                    message="Código de Barras: Debe contener al menos 3 caracteres."
                  />
                </Validator>
              </TextBox>
            </Labeled>
          </CustomCol>
          <CCol xs="12" md="3" style={{ marginTop: 'auto' }}>
            <CButton
              id="btnConfirmar"
              color="secondary"
              className="m-1"
              size="sm"
              onClick={generateBarcode}
            >
              {'Generar código de barras'}
            </CButton>
          </CCol>
        </RowContainer>
        <RowContainer>
          <CustomCol xs="12" md="3">
            <Labeled label="Código *:">
              <TextBox
                value={itemState.codigo}
                placeholder=""
                onValueChanged={onChangedCode}
                showClearButton={true}
                width="100%"
                inputAttr={{ autocomplete: 'nope' }}
                maxLength={50}
              >
                <Validator>
                  <RequiredRule
                    message={
                      'El campo Código establecido por el usuario es obligatorio'
                    }
                  />
                </Validator>
              </TextBox>
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="4">
            <Labeled label="Nombre *:">
              <TextBox
                value={itemState.nombre}
                placeholder=""
                onValueChanged={onChangedName}
                showClearButton={true}
                width="100%"
                inputAttr={{ autocomplete: 'nope' }}
              >
                <Validator>
                  <RequiredRule
                    message={'El campo Nombre (Descripción) es obligatorio'}
                  />
                </Validator>
              </TextBox>
            </Labeled>
          </CustomCol>
        </RowContainer>
        <RowContainer>
          <CustomCol xs="12" md="7">
            <Labeled label="Nombre Genérico:">
              <TextBox
                value={itemState.nombreGenerico}
                placeholder=""
                onValueChanged={onChangedGenericName}
                showClearButton={true}
                width="100%"
                inputAttr={{ autocomplete: 'nope' }}
              />
            </Labeled>
          </CustomCol>
        </RowContainer>
        <RowContainer className="mt-2 border">
          <CNav variant="tabs" role="tablist" className="mt-4">
            <CNav variant="tabs">
              {tabsNewItem.map((tab, index) => (
                <CNavItem key={index}>
                  <CNavLink
                    onClick={() => serCurrentTab(tabsNewItem[index])}
                    active={currentTab === tab}
                    disabled={
                      (itemState.generales.tipoItem.value != 7 &&
                        tab === 'Activos') ||
                      (tab === 'Promociones' && itemState.codigoInterno === 0)
                    }
                  >
                    {tab}
                  </CNavLink>
                </CNavItem>
              ))}
            </CNav>
            <CTabContent
              style={{
                overflowY: 'unset',
                width: '100%',
                marginBottom: '10px',
              }}
            >
              <CTabPane
                role="tabpanel"
                aria-labelledby="generales-tab"
                visible={currentTab === tabsNewItem[0]}
              >
                <DatosGenereales {...props} />
              </CTabPane>
              <CTabPane
                role="tabpanel"
                aria-labelledby="preciosControl-tab"
                visible={currentTab === tabsNewItem[1]}
              >
                <PreciosControl {...props} />
              </CTabPane>
              <CTabPane
                role="tabpanel"
                aria-labelledby="cuentasContables-tab"
                visible={currentTab === tabsNewItem[2]}
              >
                <CuentasContables {...props} />
              </CTabPane>
              <CTabPane
                role="tabpanel"
                aria-labelledby="existencia-tab"
                visible={currentTab === tabsNewItem[3]}
              >
                <Existencia {...props} />
              </CTabPane>
              <CTabPane
                role="tabpanel"
                aria-labelledby="proveedores-tab"
                visible={currentTab === tabsNewItem[4]}
              >
                <Proveedores {...props} />
              </CTabPane>
              <CTabPane
                role="tabpanel"
                aria-labelledby="imagenes-tab"
                visible={currentTab === tabsNewItem[5]}
              >
                <Imagenes {...props} />
              </CTabPane>
              <CTabPane
                role="tabpanel"
                aria-labelledby="aplicaciones-tab"
                visible={currentTab === tabsNewItem[6]}
              >
                <Aplicaciones {...props} />
              </CTabPane>
              <CTabPane
                role="tabpanel"
                aria-labelledby="activos-tab"
                visible={currentTab === tabsNewItem[7]}
              >
                <Activos {...props} />
              </CTabPane>
              <CTabPane
                role="tabpanel"
                aria-labelledby="partes-tab"
                visible={currentTab === tabsNewItem[8]}
              >
                <Partes {...props} />
              </CTabPane>
              <CTabPane
                role="tabpanel"
                aria-labelledby="promociones-tab"
                visible={currentTab === tabsNewItem[9]}
              >
                <Promociones {...props} />
              </CTabPane>
              <CTabPane
                role="tabpanel"
                aria-labelledby="historicos-tab"
                visible={currentTab === tabsNewItem[10]}
              >
                <Historicos {...props} />
              </CTabPane>
              <CTabPane
                role="tabpanel"
                aria-labelledby="extras-tab"
                visible={currentTab === tabsNewItem[11]}
              >
                <Extras {...props} />
              </CTabPane>
              <CTabPane
                role="tabpanel"
                aria-labelledby="observaciones-tab"
                visible={currentTab === tabsNewItem[12]}
              >
                <Observaciones {...props} />
              </CTabPane>
              <CTabPane
                role="tabpanel"
                aria-labelledby="atributos-tab"
                visible={currentTab === tabsNewItem[13]}
              >
                <Atributos {...props} />
              </CTabPane>
            </CTabContent>
          </CNav>
        </RowContainer>
      </ValidationGroup>
    </>
  )
}

export default React.memo(Nuevo)
