import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../../store/store'
import { FETCH_STATUS } from '../../../../../store/types'
import {
  TRegimenListado,
  TFiltroBusquedaRegimenState
} from './types';
import { TConfigSearchState } from '../../store/types'
import {RegimenService} from "../services/regimen.services";

const initialState: TConfigSearchState<TRegimenListado> = {
  filter: {
    codigo: 0,
    descripcion: '',
    codigoTributario: '',
    estado: 1,
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  resultados: [],
  selectedItem: null,
  error: false,
}

export const fetchRegimen = createAsyncThunk<
  Array<TRegimenListado>,
  TFiltroBusquedaRegimenState
>('catalogos/getAll/regimen', async (filtro) => {
  try {
    return await RegimenService.getCollection(filtro)
  } catch (error) {
    return Promise.reject(error)
  }
});

const searchConfSlice = createSlice({
  name: 'RegimenSearch',
  initialState: initialState,
  reducers: {
    resetState(state) {
      state = {
        ...initialState,
        filter: {
          ...initialState.filter,
        },
      }
    },
    changeFilter(state, action: PayloadAction<TFiltroBusquedaRegimenState>) {
      state.filter = action.payload
    },
    setCleanResult(state) {
      state.resultados = []
    },
    setResultados(state, action: PayloadAction<Array<TRegimenListado> | []>) {
      state.resultados = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRegimen.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
        state.error = false
      }
    })
    builder.addCase(fetchRegimen.fulfilled, (state, { payload }: {payload:any}) => {
      state.resultados = payload.auto
      state.status = FETCH_STATUS.SUCCESS
      state.error = payload.error
      state.mensaje = payload.message
    })
    builder.addCase(fetchRegimen.rejected, (state, { payload }: {payload:any}) => {
      state.resultados = []
      state.status = FETCH_STATUS.FAILED
      state.error = payload.error
      state.mensaje = payload.message
    })
  }
});

export const selectFiltroBusquedaRegimen = (state: RootState) => {
  return state.administracion.catalagos.regimen.search.filter
}

export const selectResultadosBusquedaRegimen = (state: RootState) => {
  return state.administracion.catalagos.regimen.search.resultados
}

export const selectEstadoBusquedaRegimen = (state: RootState) => {
  return state.administracion.catalagos.regimen.search.status
}

export const { setResultados, resetState, setCleanResult, changeFilter } = searchConfSlice.actions
export const searchReducer = searchConfSlice.reducer
