import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CustomDictionary, LoaderInfo } from '../../../../../store/types'
import { EdicionPayload } from '../../../../inventario/store/types'
import { NewCandidate } from '../types/types'
import { CargoDepartamento } from '../../../../componentes/cargosDepartamentosLookup/types/types'
import { CargoRol } from '../../../../componentes/cargosLookup/types/types'
import { OptionCiudad } from '../../../../../containers/component/formLocal/types/types'

const initialState: CustomDictionary<NewCandidate> = {}

const datosEditSlice = createSlice({
  name: 'candidateDataEdit',
  initialState: initialState,
  reducers: {
    deleteDataCandidate(state, action: PayloadAction<string>) {
      delete state[action.payload]
    },
    setDataCandidate(
      state,
      action: PayloadAction<{
        key: string
        candidate: NewCandidate
      }>,
    ) {
      const { key, candidate } = action.payload
      state[key].codigo = candidate.codigo
      state[key].identificacion = candidate.identificacion
      state[key].apellidos = candidate.apellidos
      state[key].nombres = candidate.nombres
      state[key].fecha = candidate.fecha
      state[key].email = candidate.email
      state[key].telefono = candidate.telefono
      state[key].liderazgo = candidate.liderazgo
      state[key].actitud = candidate.actitud
      state[key].comunicacion = candidate.comunicacion
      state[key].flexibilidad = candidate.flexibilidad
      state[key].colaboracion = candidate.colaboracion
      state[key].dedicacion = candidate.dedicacion
      state[key].confianza = candidate.confianza
      state[key].integridad = candidate.integridad
      state[key].iniciativa = candidate.iniciativa
      state[key].aprendizaje = candidate.aprendizaje
      state[key].carpeta = candidate.carpeta
    },
    setPayroll(
      state,
      action: PayloadAction<{
        key: string
        payroll: CargoRol
      }>,
    ) {
      state[action.payload.key].aplica = action.payload.payroll
    },
    setDepartament(
      state,
      action: PayloadAction<{
        key: string
        departament: CargoDepartamento
      }>,
    ) {
      state[action.payload.key].departamento = action.payload.departament
    },
    setCity(
      state,
      action: PayloadAction<{
        key: string
        city: OptionCiudad
      }>,
    ) {
      state[action.payload.key].ciudad = action.payload.city
    },
    initDataCandidate(
      state,
      action: PayloadAction<EdicionPayload<NewCandidate>>,
    ) {
      if (!state[action.payload.key])
        state[action.payload.key] = action.payload.data
    },
    setAllDataCandidate(
      state,
      action: PayloadAction<EdicionPayload<NewCandidate>>,
    ) {
      state[action.payload.key] = action.payload.data
    },
    setTabLoader(
      state,
      action: PayloadAction<{
        key: string
        loader: LoaderInfo
      }>,
    ) {
      state[action.payload.key].loader = action.payload.loader
    },
  },
})

export const {
  setDataCandidate,
  initDataCandidate,
  deleteDataCandidate,
  setTabLoader,
  setPayroll,
  setDepartament,
  setCity,
  setAllDataCandidate,
} = datosEditSlice.actions
export const dataEditCandidateReducer = datosEditSlice.reducer
