import React from "react";
import FolderComponent from './Components/FolderComponent';
import FileComponent from './Components/FileComponent';
import FolderTree from './Components/FolderTree';
import { CCol, CRow } from '@coreui/react-pro'
import { EAplicationsAcatha } from "../../../../../../store/enumsAplication";

interface ArbolProps extends React.PropsWithChildren {
  name: string,
  data: any,
  activedense?: boolean,
  sendData?: (data: any) => void,
  modulo?: number,
  jerarquiaName?: string
}

const Arbol = (props: ArbolProps) => {
  //CONTEXT

  const grupo = {
    id: "Grupo",
    filename: "Grupo",
    children: [],
  };


  let treeData = [];

  const initPlanFunction = (estado) => {
    const newVar = {
      open: estado,
      id: props?.jerarquiaName ?? "Plan",
      filename: props?.jerarquiaName ?? "Plan",
      children: [
        {
          id: "ACTIVO",
          filename: "ACTIVO",
          children: [grupo],
        },
        {
          id: "PASIVO",
          filename: "PASIVO",
          children: [grupo],
        },
        {
          id: "PATROMONIO",
          filename: "PATROMONIO",
          children: [grupo],
        },
        {
          id: "INGRESOS",
          filename: "INGRESOS",
          children: [grupo],
        },
        {
          id: "COSTOS",
          filename: "COSTOS",
          children: [grupo],
        },
        {
          id: "GASTOS",
          filename: "GASTOS",
          children: [grupo],
        },
        {
          id: "GASTOS/INGRESOS NO OPERACIONALES",
          filename: "GASTOS/INGRESOS NO OPERACIONALES",
          children: [grupo],
        },
        {
          id: "OTROS GASTOS NO DEDUCIBLES",
          filename: "OTROS GASTOS NO DEDUCIBLES",
          children: [grupo],
        },
      ],
    };
    return newVar;
  };

  // Ordenamos el json.
  // Ojo, hay que incluir como parámetro:
  // El JSON original
  // La propiedad que queremos ordenar
  // El orden (asc | desc)
  //let jsonSort = sortJSON(treeData, "id", "desc");
  const sortJSON = (data, key, orden) => {
    return data.sort(function (a, b) {
      const x = a[key],
        y = b[key];

      if (orden === "asc") {
        return x < y ? -1 : x > y ? 1 : 0;
      }

      if (orden === "desc") {
        return x > y ? -1 : x < y ? 1 : 0;
      }
    });
  };

  const formaterJsonToTree = (json) => {
    if (json.length > 0) {
      const plan: any = [];
      json.forEach(function (plCuenta) {
        plan.push({
          open: props.activedense,
          id: Number(plCuenta["codigo"]),
          key: Number(plCuenta["codigo"]),
          filename: plCuenta["numero"] + " " + plCuenta["descripcion"],
          padre: Number(plCuenta["padre"]),
          loading: true,
          toggled: props.activedense,
          children: plCuenta["children"] ?? [],
          acciones: plCuenta["acciones"] ?? [],
        });
      });
      treeData = plan;
      return plan;
    }
  };

  const generateArbol = (estado, json) => {

    const arbol = formaterJsonToTree(json);

    if (arbol.length > 0) {
      const tree = listToTree(arbol, {
        idKey: "id",
        parentKey: "padre",
        childrenKey: "children",
      });

      const generatedTree = [
        {
          open: estado,
          id: "0",
          filename: props?.jerarquiaName ?? "Plan",
          toggled: estado,
          children: [...tree],
        },
      ];

      if (props.modulo !== null && props.modulo !== undefined && props.modulo === parseInt(EAplicationsAcatha.Aplicaciones)) {
        generatedTree.push({
          open: estado,
          id: "1",
          filename: "Aplicacion",
          toggled: estado,
          children: [...tree],
        })
        return generatedTree[1];
      }


      return generatedTree[0];
    }
  };

  const listToTree = (data, options) => {
    options = options || {};
    const ID_KEY = options.idKey || "id";
    const PARENT_KEY = options.parentKey || "parent";
    const CHILDREN_KEY = options.childrenKey || "children";

    const tree: any = [],
      childrenOf = {};
    let item, id, parentId;
    for (let i = 0, length = data.length; i < length; i++) {
      item = data[i];
      id = item[ID_KEY];
      parentId = item[PARENT_KEY] || 0;
      // every item may have children
      childrenOf[id] = childrenOf[id] || [];
      // init its children
      item[CHILDREN_KEY] = childrenOf[id];
      if (parentId != 0) {
        // init its parent's children object
        childrenOf[parentId] = childrenOf[parentId] || [];
        // push it into its parent's children object
        childrenOf[parentId].push(item);
      } else {
        tree.push(item);
      }
    }

    return tree;
  };

  const capturaValor = (val) => {
    console.log('val', val);
    props.sendData(val)
  }

  return (
    <>
      <CRow>
        <CCol lg="12" className="d-flex justify-content-end">
          <strong>{props.data.length}</strong>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          {props.data.length > 0 ? (
            <>
              <FolderTree
                fileComponent={FileComponent}
                folderComponent={FolderComponent}
                id={!props.activedense}
                data={generateArbol(!props.activedense, props.data)}
                showToolbar={false}
                onChange={capturaValor}
              />
            </>
          ) : (
            <>
              <FolderTree
                fileComponent={FileComponent}
                folderComponent={FolderComponent}
                id={!props.activedense}
                data={initPlanFunction(!props.activedense)}
                showToolbar={false}
                onChange={capturaValor}
              />
            </>
          )}
        </CCol>
      </CRow>
    </>
  );
}

export default React.memo(Arbol);
