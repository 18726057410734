/* eslint-disable no-unused-vars */
/**
 * Estado de edicion
 */
export enum StatesEdition {
  new = 'new',
  edit = 'edit',
  save = 'save',
  doesNotApply = 'doesNotApply',
}

/**
 * Tipos de tabs definidos para el uso en un control de tabs de documentos
 */
export enum TabTypes {
  busqueda,
  documento,
  import,
  abonar,
  saldos,
  anticipos,
  cartera,
  carteraVencida,
  verAbonos,
  ingresosCaja,
}

/**
 * ETiposNegociosPermitidosMaxCFinal = Actividades Economicas de tipo de negocios que no se necesitas controla el valor max de factura a consumidor final
 */
export enum ETiposNegociosPermitidosMaxCFinal {
  transporteComercialTaxis = 57,
  transportePublicoIntra = 58,
}
