import {
  FunctionComponent,
  PropsWithChildren,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../../store/store'
import { ButtonTypes } from '../../../../views/componentes/globalMenu/types'
import {
  useButtonDisabled,
  useMenuOptions,
} from '../../../../hooks/useMenuButtonControls'
import {
  AccionMenu,
  ConfigPopupMsg,
  CustomButtons,
  ToastTypes,
} from '../../../../store/types'
import PositionsMain from './components/shared/mains'
import {
  buttonClick,
  changeCurrentTab,
  clearButtonClick,
  closeTabPosition,
  openTab,
  openTabOrganizationChart,
  openTabPermisions,
  setButtons,
} from './store/tabsReducer'
import { deletePosition, initPosition } from './store/editDataReducer'
import { useSetToast } from '../../../../hooks/useGlobalHooks'
import { Position, NewPosition, AssigndedComand } from './types/types'
import {
  setCollpased,
  setFilter,
  setIsSearch,
  setResults,
} from './store/searchReducer'
import { useLoaderPositions } from './customHooks'
import { ReportWithLocalDataModal } from '../../../../views/componentes/xtrareports/ReportWithLocalDataModal'
import { isMobile } from 'react-device-detect'
import ConfirmarAccionMsj from '../../../../views/componentes/confirmarAccionMsj'
import { PositionsServices } from './services/positions.services'
import { defaultPopupMsgConfig } from '../../store/types'
import { deletePermission, initPermission } from './store/permisosReducer'
import { TabTypes } from '../../../../store/enums'

const pathVerificador = '/cargos'

interface ICandidatesProps extends PropsWithChildren {}

const Cargos: FunctionComponent<ICandidatesProps> = () => {
  const dispatch = useDispatch()
  const setToastMessage = useSetToast()
  const { showLoaderTabs } = useLoaderPositions()

  const menuState = useSelector((state: RootState) => state.global.menu)
  const tabs = useSelector((state: RootState) => state.nomina.cargos.tabs)
  const searchFilter = useSelector(
    (state: RootState) => state.nomina.cargos.search.filter,
  )
  const currentTab = useSelector(
    (state: RootState) => state.nomina.cargos.tabs.current,
  )
  const globalButtonClick = useSelector(
    (state: RootState) =>
      state.nomina.cargos.tabs.tabs[currentTab].globalButtonClick,
  )
  const selectedPosition = useSelector(
    (state: RootState) => state.nomina.cargos.search.selected,
  )
  const positionsList = useSelector(
    (state: RootState) => state.nomina.cargos.search.positionsList,
  )
  const currentPosition = useSelector(
    (state: RootState) => state.nomina.cargos.editData[currentTab],
  )
  const currentPermises = useSelector(
    (state: RootState) => state.nomina.cargos.permissions[currentTab],
  )

  const getButtonDisabled = useButtonDisabled(tabs)
  const { getMenuOptions, options } = useMenuOptions()

  const [popupMsgConfig, setPopupMsgConfig] = useState<ConfigPopupMsg>(
    defaultPopupMsgConfig,
  )
  const [pdfReport, setPdfReport] = useState<null | 'Viewer' | 'Designer'>(null)
  const [reportName, setReportName] = useState<
    'StaticPosition' | 'StaticPositionsList' | 'StaticPermisesPosition' | null
  >(null)
  const [optionsMenu, setOptionsMenu] = useState<AccionMenu[]>([])

  const onTabClosing = useCallback(
    (tabKey: string) => {
      const closedTab = tabs.tabs[tabKey]
      dispatch(closeTabPosition(closedTab.tabKey ?? tabKey))
      if (closedTab.type === TabTypes.permisos)
        dispatch(deletePermission(closedTab.tabKey ?? tabKey))
      else dispatch(deletePosition(closedTab.tabKey ?? tabKey))
    },
    [dispatch, tabs.tabs],
  )

  const onTabChanged = useCallback(
    (tabKey: string) => {
      dispatch(changeCurrentTab(tabKey))
    },
    [dispatch],
  )

  const onHandleClearResults = useCallback(() => {
    dispatch(setResults([]))
  }, [dispatch])

  const onHandleUndo = useCallback(() => {
    dispatch(setFilter({ cargo: '' }))
  }, [dispatch])

  const openNewTab = useCallback(() => {
    const id = crypto.randomUUID()
    dispatch(
      initPosition({
        key: id,
        data: defaultPosition,
      }),
    )
    dispatch(openTab({ key: id }))
  }, [dispatch])

  const openTabToEdit = useCallback(() => {
    const id = crypto.randomUUID()
    dispatch(
      initPosition({
        key: id,
        data: {
          codigo: selectedPosition.codigo,
          descripcion: selectedPosition.descripcion,
          contrato: selectedPosition.contrato,
          estado: { codigo: selectedPosition.estado, descripcion: '' },
          loader: { show: false, mensaje: '' },
        },
      }),
    )
    dispatch(
      openTab({
        key: id,
        position: selectedPosition,
        templateMode: false,
      }),
    )
  }, [dispatch, selectedPosition])

  const onHandleSearch = useCallback(async () => {
    try {
      onHandleClearResults()
      showLoaderTabs(
        true,
        'BUSQUEDA',
        false,
        'Buscando Roles . . .',
        ButtonTypes.find,
      )
      const positionsList = await PositionsServices.getListPositions<Position>(
        searchFilter.cargo ?? '',
      )
      showLoaderTabs(false)
      if (
        !positionsList.error &&
        positionsList.auto &&
        Array.isArray(positionsList.auto)
      ) {
        dispatch(setResults(positionsList.auto))
        if (positionsList.auto.length > 5) dispatch(setCollpased(false))
      }
      setToastMessage(
        'Buscar Roles',
        positionsList.message,
        positionsList.error ? ToastTypes.Warning : ToastTypes.Success,
      )
    } catch (error) {
      showLoaderTabs(false)
      setToastMessage('Buscar Roles', error, ToastTypes.Danger)
    }
  }, [
    dispatch,
    onHandleClearResults,
    searchFilter,
    setToastMessage,
    showLoaderTabs,
  ])

  const onHandleDelete = useCallback(async () => {
    try {
      showLoaderTabs(
        true,
        'BUSQUEDA',
        false,
        'Eliminando Rol . . .',
        ButtonTypes.delete,
      )
      const deletePosition = await PositionsServices.deletePosition<boolean>(
        selectedPosition.codigo,
      )
      showLoaderTabs(false)
      if (
        !deletePosition.error &&
        deletePosition.auto &&
        typeof deletePosition.auto === 'boolean'
      )
        onHandleSearch()
      setToastMessage(
        'Eliminar Rol',
        deletePosition.message,
        deletePosition.error ? ToastTypes.Warning : ToastTypes.Success,
      )
    } catch (error) {
      showLoaderTabs(false)
      setToastMessage('Eliminar Rol', error, ToastTypes.Danger)
    }
  }, [
    onHandleSearch,
    setToastMessage,
    showLoaderTabs,
    selectedPosition?.codigo,
  ])

  const onParseDtoToPrint = useCallback(() => {
    const positionsDto: any = []
    if (reportName === 'StaticPositionsList')
      positionsList.forEach((candidate: Position) => {
        positionsDto.push({
          Description: candidate.descripcion,
        })
      })
    else if (reportName === 'StaticPosition')
      positionsDto.push({
        Description: currentPosition.descripcion,
        Contract: currentPosition.contrato,
        Status: currentPosition.estado.descripcion ?? 'N/A',
      })
    else if (reportName === 'StaticPermisesPosition')
      currentPermises.assignedCommands.forEach((command: AssigndedComand) => {
        positionsDto.push({
          Cargo: tabs.tabs[currentTab].info.info.descripcion ?? 'N/A',
          Menu: command.menuNombre,
          Comando: command.botonDescripcion,
        })
      })
    return positionsDto
  }, [
    reportName,
    positionsList,
    currentPosition,
    currentPermises,
    tabs,
    currentTab,
  ])

  const onOpenPermissionsTab = useCallback(() => {
    const id = crypto.randomUUID()
    dispatch(
      initPermission({
        key: id,
        data: {
          loader: { show: false, mensaje: '' },
          availableCommands: [],
          isLoadedCommands: false,
          assignedCommands: [],
        },
      }),
    )
    dispatch(
      openTabPermisions({
        key: id,
        position: selectedPosition,
      }),
    )
  }, [dispatch, selectedPosition])

  const onHandleConfirm = useCallback(() => {
    if (popupMsgConfig.type === 'confirm') {
      if (popupMsgConfig.currentAction === ButtonTypes.edit) openTabToEdit()
      else if (popupMsgConfig.currentAction === ButtonTypes.delete) {
        if (currentTab === 'BUSQUEDA') onHandleDelete()
        else
          dispatch(
            buttonClick({ tabKey: currentTab, button: ButtonTypes.delete }),
          )
      } else if (popupMsgConfig.currentAction === ButtonTypes.permissions)
        onOpenPermissionsTab()
    } else openNewTab()
    setPopupMsgConfig(defaultPopupMsgConfig)
  }, [
    popupMsgConfig,
    openNewTab,
    openTabToEdit,
    onOpenPermissionsTab,
    currentTab,
    onHandleDelete,
    dispatch,
  ])

  const onHandleCancel = useCallback(() => {
    setPopupMsgConfig(defaultPopupMsgConfig)
  }, [])

  const handlePrint = useCallback(() => {
    setPdfReport('Viewer')
    if (currentTab === 'BUSQUEDA') setReportName('StaticPositionsList')
    else if (tabs.tabs[currentTab].type === TabTypes.permisos)
      setReportName('StaticPermisesPosition')
    else if (tabs.tabs[currentTab].type === TabTypes.documento)
      setReportName('StaticPosition')
  }, [currentTab, tabs])

  const onMenuButtonClick = useCallback(
    async (action: AccionMenu | { actionType: string }) => {
      switch (action.actionType) {
        case ButtonTypes.new:
          if (currentTab === 'BUSQUEDA') openNewTab()
          else
            setPopupMsgConfig({
              show: true,
              title: 'Ácatha',
              message: 'Desea crear un nuevo registro?',
              type: 'newTab',
            })
          break
        case ButtonTypes.find:
          if (currentTab === 'BUSQUEDA') {
            if (isMobile) dispatch(setIsSearch(true))
            onHandleSearch()
          } else dispatch(changeCurrentTab('BUSQUEDA'))
          break
        case ButtonTypes.broom:
          if (currentTab === 'BUSQUEDA') onHandleClearResults()
          break
        case ButtonTypes.undo:
          if (currentTab === 'BUSQUEDA') onHandleUndo()
          else
            dispatch(
              buttonClick({ tabKey: currentTab, button: ButtonTypes.undo }),
            )
          break
        case ButtonTypes.save:
          if (currentTab !== 'BUSQUEDA')
            dispatch(
              buttonClick({ tabKey: currentTab, button: ButtonTypes.save }),
            )
          break
        case ButtonTypes.edit:
          if (currentTab === 'BUSQUEDA')
            setPopupMsgConfig({
              show: true,
              title: 'Ácatha',
              message: `¿Desea EDITAR el cargo ${selectedPosition.descripcion}?`,
              type: 'confirm',
              currentAction: ButtonTypes.edit,
            })
          break
        case ButtonTypes.delete:
          setPopupMsgConfig({
            show: true,
            title: 'Ácatha',
            message:
              currentTab === 'BUSQUEDA'
                ? `¿Desea ELIMINAR el cargo ${selectedPosition.descripcion}?`
                : 'Está seguro que desea eliminar el comando seleccionado ?',
            type: 'confirm',
            currentAction: ButtonTypes.delete,
          })
          break
        case ButtonTypes.print:
          handlePrint()
          break
        case ButtonTypes.permissions:
          setPopupMsgConfig({
            show: true,
            title: 'Ácatha',
            message: `¿Desea editar los PERMISOS del cargo ${selectedPosition.descripcion}?`,
            type: 'confirm',
            currentAction: ButtonTypes.permissions,
          })
          break
        case ButtonTypes.assignPositionOrganizationChart:
          dispatch(openTabOrganizationChart())
          break
        default:
          break
      }
      if (currentTab === 'BUSQUEDA') dispatch(clearButtonClick(currentTab))
    },
    [
      dispatch,
      openNewTab,
      onHandleClearResults,
      onHandleSearch,
      onHandleUndo,
      currentTab,
      selectedPosition,
      handlePrint,
    ],
  )

  useEffect(() => {
    dispatch(
      setButtons({
        tabKey: 'BUSQUEDA',
        buttons: {
          Nuevo: true,
          Buscar: true,
          Imprimir: positionsList.length > 0,
          Deshacer: true,
          Limpiar: true,
          Asignar_Roles_Organigrama: true,
        },
      }),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positionsList])

  useEffect(() => {
    if (globalButtonClick !== ButtonTypes.none && currentTab === 'BUSQUEDA')
      onMenuButtonClick({ actionType: globalButtonClick })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalButtonClick])

  const onParseButtons = useCallback(() => {
    const acctionButtons = structuredClone(options)
    const index = acctionButtons.findIndex(
      (action: AccionMenu) =>
        action.nombre === 'Asignación de roles al organigrama',
    )
    if (index > -1)
      acctionButtons[index].actionType =
        ButtonTypes.assignPositionOrganizationChart
    setOptionsMenu(acctionButtons)
  }, [options])

  useEffect(() => {
    if (options.length > 0 && optionsMenu.length === 0) onParseButtons()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options])

  useEffect(() => {
    if (menuState.length > 0 && options.length === 0)
      getMenuOptions(menuState, 'to', pathVerificador)
  }, [menuState, getMenuOptions, options])

  return (
    <>
      <ConfirmarAccionMsj
        title={popupMsgConfig.title}
        isVisible={popupMsgConfig.show}
        handleConfirm={onHandleConfirm}
        handleCancel={onHandleCancel}
        message={popupMsgConfig.message}
        typeMessage={popupMsgConfig.type}
        typeInputInfo={null}
      />
      {pdfReport && reportName && (
        <ReportWithLocalDataModal
          show={pdfReport !== null}
          onClose={() => setPdfReport(null)}
          data={onParseDtoToPrint() ?? []}
          fileName={reportName}
          mode={pdfReport ?? 'Viewer'}
          parameters={{
            Reporte_Filtro: '',
          }}
          template={reportName}
          key="reportDesignerPositions"
        />
      )}
      <PositionsMain
        actionsMenu={optionsMenu}
        onMenuButtonClick={onMenuButtonClick}
        getButtonDisabled={getButtonDisabled}
        currentWorking={tabs.tabs[tabs.current].currentExecutingAction}
        tabs={tabs}
        onTabChanged={onTabChanged}
        onTabClosing={onTabClosing}
      />
    </>
  )
}

export default memo(Cargos)

export const defaultPosition: NewPosition = {
  codigo: 0,
  descripcion: '',
  contrato: '',
  estado: null,
  loader: { show: false, mensaje: '' },
}

export const NewTabButtons: CustomButtons = {
  Nuevo: true,
  Guardar: true,
  Editar: false,
  Eliminar: false,
  Buscar: true,
  Imprimir: false,
  Deshacer: true,
  Limpiar: false,
}
