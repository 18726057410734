import { CCol, CDropdown, CDropdownItem, CDropdownToggle, CRow, CDropdownMenu } from '@coreui/react-pro';
import { faInfo, faPlus, faTrash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Col } from 'react-bootstrap';
import ReactDOM from 'react-dom';

export default function SheetCommandViewer(props) {
  const { cell, row } = props
  if (!cell.hasRowData) {
    return <div style={{ width: '100%', height: '100%' }} className='' ></div>
  }
  const element = document.querySelector("#modal");

  return (
    <div className='' style={{ width: '100%', height: '100%' }}>
      <CDropdown className=""  >
        <CDropdownToggle size='sm' caret color="info" >
          <FontAwesomeIcon size='xs' icon={faPlus} />
        </CDropdownToggle>
        {
          ReactDOM.createPortal(<CDropdownMenu>
            <CDropdownItem
              onClick={() => cell.eliminar(row)}
            >
              <CRow  >
                <Col xs='1'>
                  <FontAwesomeIcon size='1x' color='Tomato' icon={faTrash} />
                </Col>
                <CCol>
                  <span>Eliminar detalle</span>
                </CCol>
              </CRow>
            </CDropdownItem>
            <CDropdownItem
              onClick={() => cell.verDetalles(cell.data.codigoBarras)}
            >
              <CRow  >
                <Col xs='1'>
                  <FontAwesomeIcon size='1x' icon={faInfo} color='Dodgerblue' />
                </Col>
                <CCol>
                  <span>Ver detalles del Producto</span>
                </CCol>
              </CRow>
            </CDropdownItem>
            <CDropdownItem divider />

          </CDropdownMenu>, element)
        }

      </CDropdown>
    </div>
  )
}