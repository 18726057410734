import React from 'react'
import { tipoEstadoService } from './service/tipoEstado.service'
import { TEstado } from '../../../store/types'
import { SelectBoxDE } from '../../../views/componentes/selectBox-DE/selectBox-DE'
import { v4 as uuidv4 } from 'uuid'

interface ITipoEstadoLookUpProps extends React.PropsWithChildren {
  selected: TEstado | null
  onChanged: (newValue: TEstado | null) => void
  disabled?: boolean
  provider?: Array<TEstado> | []
}

const TipoEstadoLookUp: React.FC<ITipoEstadoLookUpProps> = (props) => {
  const { provider, selected, onChanged, disabled } = props
  const selectRef = React.useRef<any>('state')
  const [options, setOptions] = React.useState<Array<TEstado> | []>([])

  const loadDatos = React.useCallback(async () => {
    if (provider !== undefined && provider.length > 0) {
      setOptions(provider)
      const selectedIndex = provider.findIndex((select: TEstado) => {
        return select.codigo === selected?.codigo
      })
      onChanged(provider[selectedIndex] ?? provider[0])
      if (selectedIndex > -1) {
        onChanged(provider[selectedIndex] ?? provider[0])
      }
    } else {
      const data = await tipoEstadoService.getTipoEstado('Elija un estado')
      if (data.error === false) {
        let opt = []
        if (data.auto.length > 0) {
          opt = data.auto.map((x) => {
            const item: TEstado = {
              codigo: parseInt(x?.codigo) ?? -1,
              descripcion: x?.descripcion ?? '',
            }
            return item
          })
        }
        setOptions(opt)
        const selectedIndex = opt.findIndex((option) => {
          return option.codigo === selected?.codigo
        })
        onChanged(opt[selectedIndex] ?? opt[0])
      }
    }
  }, [onChanged, selected, provider])

  const getItemDisplayExpr = React.useCallback((item: TEstado) => {
    return item ? `${item.descripcion}` : ''
  }, [])

  const onLookUpValueChanged = React.useCallback(
    (evt: any) => {
      onChanged(evt)
    },
    [onChanged],
  )

  const onSelectBoxFocusIn = React.useCallback((e) => {
    e.element.querySelector('input.dx-texteditor-input').select()
  }, [])

  const refreshSelected = React.useCallback(() => {
    if (options && options.length > 0) {
      const selectedIndex = options.findIndex((option: TEstado) => {
        return option.codigo === selected?.codigo
      })
      onChanged(options[selectedIndex] ?? options[0])
    }
  }, [onChanged, options, selected])

  React.useEffect(() => {
    loadDatos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    refreshSelected()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  return (
    <SelectBoxDE
      id="tiposEstadoSelect"
      key={uuidv4()}
      ref={selectRef}
      items={options ?? []}
      searchEnabled
      displayExpr={getItemDisplayExpr}
      searchExpr={['descripcion']}
      searchTimeout={500}
      value={selected}
      onValueChange={onLookUpValueChanged}
      disabled={disabled}
      onFocusIn={onSelectBoxFocusIn}
    >
      {props.children}
    </SelectBoxDE>
  )
}
export default React.memo(TipoEstadoLookUp)
