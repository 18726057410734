import React, { useEffect } from 'react'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import { DataGrid } from 'devextreme-react'
import { Column, Export, Pager, Paging } from 'devextreme-react/data-grid'
import { getAllowedPageSizes } from '../../../../../../../helpers/Helper'
import ItemsCountIndicator from '../../../../../../componentes/indicadorNroRegistros'
import { isMobile } from 'react-device-detect'
import { setButtons } from '../../store/tabsReducer'

const Detalle = () => {
  const dispatch = useDispatch()

  const resultsSearch = useSelector((state: RootState) => {
    return state.inventarios.movimientos.transferencias.search.resultsDetails
  })
  const currentTab = useSelector((state: RootState) => {
    return state.inventarios.movimientos.transferencias.search.currentTab
  })

  const dataGrid = React.useRef<any>()

  useEffect(() => {
    if (currentTab === 'Detalle')
      dispatch(
        setButtons({
          tabKey: 'BUSQUEDA',
          buttons: {
            Nuevo: true,
            Buscar: true,
            Limpiar: true,
            Duplicar: false,
            Editar: false,
            Eliminar: false,
            Envios: false,
            Recepcion: false,
            Archivado: false,
            Ver_asiento: false,
            Imprimir: false,
          },
        }),
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab])

  return (
    <RowContainer className="mt-2">
      <ItemsCountIndicator items={resultsSearch} />
      <DataGrid
        ref={dataGrid}
        keyExpr="uuid"
        id="tabDetailsTransfer"
        selection={{ mode: 'single' }}
        dataSource={resultsSearch}
        showColumnLines={true}
        showRowLines={true}
        showBorders={true}
        columnResizingMode="widget"
        focusedRowEnabled={true}
        loadPanel={{ enabled: true }}
        allowColumnResizing={true}
      >
        <Paging defaultPageSize={20} />
        <Pager
          visible={resultsSearch.length > 0 ? true : false}
          allowedPageSizes={getAllowedPageSizes(resultsSearch)}
          displayMode={'full'}
          showPageSizeSelector={true}
          showInfo={true}
          showNavigationButtons={true}
        />
        <Export enabled={true} allowExportSelectedData={true} />
        <Column
          dataField="codigo"
          caption="Código"
          width={isMobile ? '70px' : '5%'}
        />
        <Column
          dataField="numero"
          caption="Número"
          width={isMobile ? '90px' : '8%'}
        />
        <Column
          dataField="fecha"
          caption="Fecha"
          width={isMobile ? '80px' : '8%'}
        />
        <Column
          dataField="descripcion"
          caption="Descripción"
          width={isMobile ? '325px' : '11%'}
        />
        <Column
          dataField="localOrigenNombre"
          caption="Local Origen"
          width={isMobile ? '125px' : '6%'}
        />
        <Column
          dataField="localDestinoNombre"
          caption="Local Destino"
          width={isMobile ? '125px' : '6%'}
        />
        <Column
          dataField="itemCodigoUsuario"
          caption="Interno"
          width={isMobile ? '130px' : '6%'}
        />
        <Column
          dataField="itemBarras"
          caption="Barras"
          width={isMobile ? '130px' : '6%'}
        />
        <Column
          dataField="itemDescripcion"
          caption="Descrpción"
          width={isMobile ? '325px' : '11%'}
        />
        <Column
          dataField="cuentaContableCompra"
          caption="CC Compra"
          width={isMobile ? '200px' : '6%'}
        />
        <Column
          dataField="cuentaContableCosto"
          caption="CC Costo"
          width={isMobile ? '150px' : '6%'}
        />
        <Column
          dataField="transferenciaDetalleCantidad"
          caption="Cantidad"
          width={isMobile ? '80px' : '6%'}
        />
        <Column
          dataField="transferenciaDetalleReferencia"
          caption="Referencia"
          width={isMobile ? '80px' : '6%'}
        />
        <Column
          dataField="transferenciaDetallePrecioUnitario"
          caption="Precio"
          width={isMobile ? '100px' : '6%'}
        />
        <Column
          dataField="transferenciaDetalleValor"
          caption="Valor"
          width={isMobile ? '100px' : '6%'}
        />
      </DataGrid>
    </RowContainer>
  )
}

export default React.memo(Detalle)
