import { DateUtils, formatoFechasApi } from '../../../../helpers/dateUtils'
import { RequestHelper } from '../../../../helpers/requestHelper'
import { utilidades } from '../../../../helpers/utilidades'
import { SesionService } from '../../../../services/sesion.service'
import { TipoPropiedad } from '../types/types'

export const TiposPropiedades = { getTypes }

async function getTypes(message: string): Promise<any> {
  try {
    const jquery: any = { mensaje: message }
    const sesion = SesionService.getCurrentSesion()
    const keysToCache: any = {
      ...jquery,
      dateKey: DateUtils.getCurrentDateAsString(formatoFechasApi),
      empresa: sesion?.empresa?.codigo,
    }
    const cacheKey = 'tipoAttr' + JSON.stringify(keysToCache)
    const cached = sessionStorage.getItem(cacheKey)
    if (cached) {
      return { error: false, auto: JSON.parse(cached) }
    } else {
      const data = await RequestHelper.getAll<any>(
        'inventario/items',
        'property/cBoxLoad',
        '',
        jquery,
      )
      const opt: Array<TipoPropiedad> = []
      if (data?.auto && data?.auto.length > 0) {
        for (const x of data.auto) {
          opt.push({
            codigo: x?.codigo ?? -1,
            opciones: x?.opciones ?? '',
            estado: x?.estado ?? -1,
            orden: x?.orden ?? -1,
            idwoo: x?.idwoo ?? -1,
            descripcion: utilidades.capText(x?.descripcion) ?? '',
          })
        }
      }
      data.auto = opt ?? []
      sessionStorage.setItem(cacheKey, JSON.stringify(opt))
      return data
    }
  } catch (error) {
    return error
  }
}
