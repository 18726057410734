import React, { useEffect } from 'react'
import { FiltrosInventariosMovState, InventarioInicial, InventarioMovDatosEdicion } from '../../types/types'
import { ButtonTypes } from '../../../../../../../views/componentes/globalMenu/types'
import { clearButtonClick, openTab, setCurrentExecutingAction } from '../../store/tabsReducer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import {
  changeLoaderInventory,
  setCurrentFunction,
} from '../../store/generalReducer'
import { TabState } from '../../../../../../../store/genericTabTypes'
import ResultadosBusqueda from './ResultadosBusqueda'
import FiltroBusqueda from './FiltroBusqueda'
import { changeFilter, setResetSeleccion, setResultados, setVerRegistro } from '../../store/serachReducer'
import { DateUtils } from '../../../../../../../helpers/dateUtils'
import { tipoEstadoService } from '../../../../../../componentes/tipoEstado/service/tipoEstado.service'
import { consoleService } from '../../../../../../../services/console.service'
import BlockUi from '../../../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../../../views/componentes/loadingindicator/loadingindicator'
import { isMobile } from 'react-device-detect'
import { FETCH_STATUS, ToastTypes } from '../../../../../../../store/types'
import TableLoader from '../../../../../../ventas/components/ventas/busquedaVentas/TableLoader'
import { Draggable, SpeedDialAction } from 'devextreme-react'
import Dialog from '../../../../../../../views/componentes/librerias/bootstrap-dialog'
import { OrdenPedidoService } from '../../../../../../ventas/pages/ordenes/pages/ordenPedido/services/ordenPedido.services'
import { addToast } from '../../../../../../../store/toasterReducer'
import { getIndexProvider } from '../../../../../../shared/helpers/funciones'
import { initDatosEdicion } from '../../store/editDataReducer'
import { defaultDataInventory } from '../nuevo/index';
import { v4 as uuidv4 } from 'uuid'
import { CCard } from '@coreui/react-pro'

interface ISearchProps extends React.PropsWithChildren {
  tab: TabState<InventarioMovDatosEdicion>
  tabId: string
}

enum VistasBusqueda {
  Filtros,
  ResultadosBusqueda,
  Resumen,
  Loading,
  Error
}

const draggingGroupName = 'appointmentsGroup';

const Search: React.FunctionComponent<ISearchProps> = (props) => {
  const { tabId, tab } = props
  const dialogRef = React.useRef<any>(null)

  const dispatch = useDispatch()
  const selected = useSelector((state: RootState) => {
    return state.inventarios.movimientos.inventarioInicial.search.seleccionado
  })
  const estadoBusqueda = useSelector((state: RootState) => state.inventarios.movimientos.inventarioInicial.search.status)
  const searchFilter = useSelector((state: RootState) => state.inventarios.movimientos.inventarioInicial.search.filter)
  const currentTab = useSelector((state: RootState) => state.inventarios.movimientos.inventarioInicial.tabs.current)
  const invInicialLoader = useSelector((state: RootState) => state.inventarios.movimientos.inventarioInicial.general)
  const periodoActivo = useSelector(
    (state: RootState) => state.shared.providers.periodoContable,
  )
  const [vistaActual, setVistaActual] = React.useState<VistasBusqueda>(VistasBusqueda.Filtros)
  const [confirmDelete, setConfirmDelete] = React.useState<boolean>(false)
  const [confirmEdit, setConfirmEdit] = React.useState<boolean>(false)

  const fechaFormato: string = 'dd/MM/yyyy'
  const fechaFormatoInput: string = 'yyyy-MM-dd'
  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(
      addToast({
        title: 'Inventario - Inventario Inicial',
        content: mensaje,
        type: tipo,
      }),
    )
  }, [dispatch])

  const setButtonExecuttion = React.useCallback((tipo: ButtonTypes | undefined) => {
    dispatch(
      setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: tipo,
      }),
    )
  }, [dispatch, tabId])
  const onInitFilter = React.useCallback(async () => {
    const data = { ...searchFilter }
    if (searchFilter.initial) {
      const estado = await tipoEstadoService.getTipoEstado('Elija un estado')
      consoleService.log(estado)
      if (estado['auto'] && estado['error'] === false) {
        data.estado = estado['auto'][0]
      }
      data.documento = ''
      data.observaciones = ''
      data.fechaDesde = periodoActivo.length > 0 ? DateUtils.format(periodoActivo[0].finicio, fechaFormatoInput, fechaFormato) : DateUtils.getCurrentDateAsString(fechaFormatoInput)
      data.fechaHasta = periodoActivo.length > 0 ? DateUtils.format(periodoActivo[0].fcierre, fechaFormatoInput, fechaFormato) : DateUtils.getCurrentDateAsString(fechaFormatoInput)
      data.initial = false
      dispatch(changeFilter({
        ...data
      }))
    }
  }, [dispatch, periodoActivo, searchFilter])

  const playLoader = React.useCallback((message = 'Cargando...') => {
    dispatch(changeLoaderInventory({ show: true, mensaje: message }))
  }, [dispatch])

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoaderInventory({ show: false, mensaje: '' }))
  }, [dispatch])

  const onHandleRemove = React.useCallback(async () => {
    try {
      setButtonExecuttion(ButtonTypes.delete)
      playLoader('Eliminando...')
      const data = await OrdenPedidoService.deletePedido(selected?.codigo ?? 0)
      stopLoader()
      setButtonExecuttion(undefined)
      if (data !== null && data !== undefined && data['error'] === false) {
        dispatch(setResetSeleccion())
        setToast(data['message'], ToastTypes.Success)
      } else {
        setToast(data['message'], ToastTypes.Danger)
      }
    } catch (error) {
      stopLoader()
      setButtonExecuttion(undefined)
      setToast(error.message, ToastTypes.Danger)
    }
  }, [selected, setToast, playLoader, stopLoader, setButtonExecuttion, dispatch])

  const onHandleEdit = React.useCallback(async (inventario: any) => {
    consoleService.log(inventario)
    const id = uuidv4();
    dispatch(initDatosEdicion({
      key: id,
      data: {
        ...defaultDataInventory,
        loading: true,
      }
    }));
    const inventarioEdit: any = {
      ...inventario,
      codigo: inventario.codigo,
      nroInventario: inventario.numero
    }
    // inventario.nroInventario = inventario.numero
    dispatch(openTab({ key: id, inventarioInicial: inventarioEdit }));
  }, [dispatch])

  const onConfirmDelete = React.useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea Eliminar el Registro: ${selected?.numero ?? '-'}?`,
      actions: [
        Dialog.Action(
          <span>
            <u>A</u>ceptar
          </span>,
          (dialog) => {
            dialog.hide()
            onHandleRemove()
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setConfirmDelete(false)
    return
  }, [onHandleRemove, selected])

  const onConfirmEdit = React.useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea Editar el registro: ${selected?.numero ?? '-'}?`,
      actions: [
        Dialog.Action(
          <span>
            <u>A</u>ceptar
          </span>,
          (dialog) => {
            dialog.hide()
            dispatch(setVerRegistro(false))
            onHandleEdit(selected)
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setConfirmEdit(false)
    return
  }, [onHandleEdit, selected, dispatch])

  const handleButtonClick = React.useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.delete:
          setConfirmDelete(true)
          break
        case ButtonTypes.edit:
          setConfirmEdit(true)
          break
        default:
          break
      }
      dispatch(setCurrentFunction(''))
      dispatch(clearButtonClick(tabId))
    },
    [dispatch, setConfirmEdit, tabId],
  )

  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab.globalButtonClick]);

  React.useEffect(() => {
    if (confirmDelete) {
      onConfirmDelete()
    }
  }, [confirmDelete, onConfirmDelete])

  React.useEffect(() => {
    if (confirmEdit) {
      onConfirmEdit()
    }
  }, [confirmEdit, onConfirmEdit])

  useEffect(() => {
    onInitFilter()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (periodoActivo.length > 0) {
      dispatch(changeFilter({
        ...searchFilter,
        fechaDesde: DateUtils.format(periodoActivo[0].finicio, fechaFormatoInput, fechaFormato),
        fechaHasta: DateUtils.format(periodoActivo[0].fcierre, fechaFormatoInput, fechaFormato)
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodoActivo])

  React.useEffect(() => {
    switch (estadoBusqueda) {
      case FETCH_STATUS.IDDLE:
        setVistaActual(VistasBusqueda.Filtros);
        break;
      case FETCH_STATUS.LOADING:
        setVistaActual(VistasBusqueda.Loading);
        break;
      case FETCH_STATUS.SUCCESS:
        setVistaActual(VistasBusqueda.ResultadosBusqueda);
        break;
      case FETCH_STATUS.FAILED:
        setVistaActual(VistasBusqueda.Error);
        break;
      default:
        break;
    }
  }, [estadoBusqueda])

  if (isMobile) {
    return (
      <>
        <CCard>
          <Dialog ref={dialogRef} />
          {vistaActual === VistasBusqueda.Filtros &&

            (
              <>
                <FiltroBusqueda tab={tab} tabId={tabId} />
              </>
            )
          }
          {vistaActual === VistasBusqueda.ResultadosBusqueda &&
            (<>
              <ResultadosBusqueda tab={tab} tabId={tabId} onDBEdit={() => setConfirmEdit(true)} onDelete={() => setConfirmDelete(true)} onVer={(data) => onHandleEdit(data)} />
            </>)
          }
          {vistaActual === VistasBusqueda.Error &&
            <></>
          }
          {vistaActual === VistasBusqueda.Loading &&
            <TableLoader />
          }
          {
            currentTab === tabId && (
              <Draggable
                id="list"
                data="dropArea"
                group={draggingGroupName}
              >
                <SpeedDialAction
                  icon="filter"
                  label='Filtros'
                  visible={true}
                  index={1}
                  onClick={() => {
                    setVistaActual(VistasBusqueda.Filtros);
                  }}
                />
                <SpeedDialAction
                  icon="search"
                  label='Busqueda'
                  visible={true}
                  index={2}
                  onClick={() => {
                    setVistaActual(VistasBusqueda.ResultadosBusqueda);
                  }}
                />
              </Draggable>
            )
          }
        </CCard>
      </>
    )
  }

  return (
    <>
      <Dialog ref={dialogRef} />
      <div id='ingEgresoContent'>
        <BlockUi
          tag="div"
          loader={LoadingIndicator}
          blocking={invInicialLoader.loader.show}
          message={invInicialLoader.loader.mensaje}
        >
          <CCard>
            <FiltroBusqueda tab={tab} tabId={tabId} />
            <ResultadosBusqueda tab={tab} tabId={tabId} onVer={(data) => onHandleEdit(data)} onDBEdit={() => setConfirmEdit(true)} onDelete={() => setConfirmDelete(true)} />
          </CCard>
        </BlockUi>
      </div>
    </>
  )
}

export default React.memo(Search)

export const inititalState: FiltrosInventariosMovState = {
  documento: '',
  fechaDesde: '',
  fechaHasta: '',
  estado: null,
  item: null,
  observaciones: '',
  initial: true
}
