import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { NotificationList } from '../types/types'
import { EdicionPayload } from '../../../store/types'
import { CustomDictionary } from '../../../../../store/types'

const initialState: CustomDictionary<NotificationList> = {}

const datosEditSlice = createSlice({
  name: 'notificationDataEdit',
  initialState: initialState,
  reducers: {
    deleteEditData(state, action: PayloadAction<string>) {
      delete state[action.payload]
    },
    setDatosEdicion(
      state,
      action: PayloadAction<{
        key: string
        notification: NotificationList
      }>,
    ) {
      state[action.payload.key].codigo = action.payload.notification.codigo
      state[action.payload.key].descripcion =
        action.payload.notification.descripcion
      state[action.payload.key].fechaInicio =
        action.payload.notification.fechaInicio
      state[action.payload.key].fechaFin = action.payload.notification.fechaFin
      state[action.payload.key].titulo = action.payload.notification.titulo
      state[action.payload.key].imagen = action.payload.notification.imagen
      state[action.payload.key].permanente =
        action.payload.notification.permanente
      state[action.payload.key].tipoId = action.payload.notification.tipoId
      state[action.payload.key].mostrarPopup =
        action.payload.notification.mostrarPopup
      state[action.payload.key].imagenCodificada =
        action.payload.notification.imagenCodificada
    },
    initDatosEdicion(
      state,
      action: PayloadAction<EdicionPayload<NotificationList>>,
    ) {
      if (!state[action.payload.key]) {
        state[action.payload.key] = action.payload.data
      }
    },
  },
})

export const { setDatosEdicion, initDatosEdicion, deleteEditData } =
  datosEditSlice.actions
export const dataEditReducer = datosEditSlice.reducer
