import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UsuarioState } from './types'

const initialState: UsuarioState = {
  modulo: 'empresa',
  currentAction: 'Editar',
  currentFunction: '',
  currentTab: 0, // 0,1,2
  currentSubTab: 0,
  datosUsuario: null,
}

const usuarioSlice = createSlice({
  name: 'usuario',
  initialState: initialState,
  reducers: {
    setCurrentAccion(state, action: PayloadAction<string>) {
      state.currentAction = action.payload
    },
    setCurrentFunction(state, action: PayloadAction<string>) {
      state.currentFunction = action.payload
    },
    setCurrentTab(state, action: PayloadAction<number>) {
      state.currentTab = action.payload
    },
    setCurrentSubTab(state, action: PayloadAction<number>) {
      state.currentSubTab = action.payload
    },
    setDataUsuario(state, action: PayloadAction<any>) {
      state.datosUsuario = action.payload
    },
  },
})
export const {
  setCurrentAccion,
  setCurrentFunction,
  setDataUsuario,
  setCurrentTab,
  setCurrentSubTab,
} = usuarioSlice.actions
export const UsuarioReducer = usuarioSlice.reducer
