import React from 'react'
import { TipoIdentificacion, TipoTransaccion } from './store/types'
import { tipoIdentificacionService } from './service/tipoId.service'
import { v4 as uuidv4 } from 'uuid'
import { SelectBoxDE } from '../../../views/componentes/selectBox-DE/selectBox-DE'

interface ITipoIdentificacionLookUpProps extends React.PropsWithChildren {
  selected: TipoIdentificacion | null
  onChanged: (newValue: TipoIdentificacion | null) => void
  transaccion: TipoTransaccion
  disabled?: boolean
  provider?: Array<TipoIdentificacion> | []
  id?: string
  codigoPais?: number
}

const TipoIdentificacionLookUp: React.FC<ITipoIdentificacionLookUpProps> = (props) => {
  const { codigoPais, id, selected, onChanged, transaccion, disabled, provider } = props
  const selectRef = React.useRef<any>();
  const [options, setOptions] = React.useState<TipoIdentificacion[] | []>([]);

  const loadDatos = React.useCallback(async () => {
    if (provider?.length) {
      setOptions(provider);
      return;
    }

    const { error, auto } = await tipoIdentificacionService.getTipoIdetificacion(transaccion, codigoPais);
    if (!error && auto.length) {
      const opt = auto.map((x) => {
        const item: TipoIdentificacion = {
          codigo: String(x.codigo).toString() ?? '',
          descripcion: x.descripcion ?? '',
          codTributario: x.codTributario ?? '',
        }
        return item
      })
      setOptions(opt)
    }
  }, [codigoPais, transaccion, provider, setOptions])

  const onValueChanged = React.useCallback((evt: any) => {
    onChanged(evt)
  }, [onChanged]);

  React.useEffect(() => {
    if (!selected && options.length > 0) {
      const defaultOption = options.find(opt => opt.codigo === '-1');
      if (defaultOption) {
        onValueChanged(defaultOption);
      }
    }
  }, [selected, options, onValueChanged]);

  React.useEffect(() => {
    loadDatos();
  }, [loadDatos]);

  return (
    <SelectBoxDE
      id="tiposIdentificacionSelect"
      key={id ?? uuidv4()}
      ref={selectRef}
      items={options ?? []}
      searchEnabled
      displayExpr="descripcion"
      searchExpr={['descripcion']}
      searchTimeout={500}
      value={selected}
      onValueChange={onValueChanged}
      disabled={disabled}
      onFocusIn={(e: any) => e.element.querySelector('input.dx-texteditor-input').select()}
    >
      {props.children}
    </SelectBoxDE>
  )
}
export default React.memo(TipoIdentificacionLookUp)
