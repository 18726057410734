import { TabsState } from "../../../types/generics";

export const ordenClearDatosEdicion = (state, action) => {
    delete state[action.payload];
};

const getNewTabKeyOnClose = (
    state: TabsState<any>,
    keyToClose: string
) => {
    let newCurrent = "";
    for (const key in state.tabs) {
        if (newCurrent === "") {
            newCurrent = key;
        }
        if (keyToClose === key) {
            return newCurrent;
        }
    }
    return "BUSQUEDA";
};

export const cuadresCloseTab = (state, action) => {
    const newCurrent = getNewTabKeyOnClose(state, action.payload);
    state.current = newCurrent;
    delete state.tabs[action.payload];
};