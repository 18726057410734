import React from 'react'
import SelectBox from 'devextreme-react/select-box'
import { Subsidio } from './types/types'
import { SubsidiosServices } from './services/subsidios.services'

interface SunsidiosLookUpProps extends React.PropsWithChildren {
  selected: Subsidio | null
  // eslint-disable-next-line no-unused-vars
  onChanged: (newValue: Subsidio | null) => void
  // eslint-disable-next-line no-unused-vars
  onChangedOptions?: (newValue: Array<Subsidio> | []) => void
  disabled?: boolean
}

const TiposSubsidiosLookUp: React.FC<SunsidiosLookUpProps> = (props) => {
  const { selected, onChanged, disabled, onChangedOptions } = props
  const selectRef = React.useRef<any>()
  const [options, setOptions] = React.useState<Array<Subsidio> | []>([])

  const loadDatos = React.useCallback(async () => {
    const data = await SubsidiosServices.getSubsidios('Elije un subsidio')
    if (data.error === false) {
      let optionsLookup = []
      if (data.auto.length > 0) {
        optionsLookup = data.auto.map((option: any) => {
          return {
            codigo: Number(option?.codigo) ?? -1,
            desde: option?.desde ?? '',
            hasta: option?.hasta ?? '',
            nombre: option?.nombre ?? '',
            valor: Number(option?.valor) ?? 0,
          }
        })
      }
      setOptions(optionsLookup)
      if (onChangedOptions) {
        onChangedOptions(optionsLookup)
      }
      const selectedIndex = optionsLookup.findIndex((option) => {
        return option.codigo === selected?.codigo
      })
      onChanged(optionsLookup[selectedIndex] ?? optionsLookup[0])
    }
  }, [onChanged, onChangedOptions, selected])

  const getItemDisplayExpr = React.useCallback((item: any) => {
    return item ? `${item.nombre}` : ''
  }, [])

  const onLookUpValueChanged = React.useCallback(
    (evt: any) => {
      if (evt.event !== undefined) {
        onChanged(evt.value)
      }
    },
    [onChanged],
  )

  const onSelectBoxFocusIn = React.useCallback((e) => {
    e.element.querySelector('input.dx-texteditor-input').select()
  }, [])

  const refreshSelected = React.useCallback(() => {
    if (options && options.length > 0) {
      const selectedIndex = options.findIndex((option: Subsidio) => {
        return option.codigo === selected?.codigo
      })
      onChanged(options[selectedIndex] ?? options[0])
    }
  }, [onChanged, options, selected])

  React.useEffect(() => {
    loadDatos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    refreshSelected()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  return (
    <SelectBox
      id="tiposSunsidiosSelect"
      ref={selectRef}
      items={options ?? []}
      searchEnabled
      displayExpr={getItemDisplayExpr}
      searchExpr={['nombre']}
      searchTimeout={500}
      value={selected}
      onValueChanged={onLookUpValueChanged}
      disabled={disabled}
      onFocusIn={onSelectBoxFocusIn}
    >
      {props.children}
    </SelectBox>
  )
}
export default React.memo(TiposSubsidiosLookUp)
