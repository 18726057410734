import { Local, Project } from '../../../../modulos/shared/types/types'
import { KeyLoc, KeyLocNoCast, KeyPro } from './enumKeys'

async function onParseProjets(p): Promise<Array<Project>> {
  const _proj: Array<Project> = []
  Object.keys(p).map(function (key) {
    _proj.push({
      codigo: Number(p[key][KeyPro.cod]),
      nombre: p[key][KeyPro.name],
      locales: onParseLocs(p[key][KeyPro.locs], KeyLocNoCast),
    })
  })
  return _proj
}

function onParseLocs(x, ENUM): Array<Local> {
  let locs: Array<Local> = []
  if (ENUM === '') {
    // si el enumerador esta vacio es porque ya fue casteado/tipeado y retorna el mismo objeto ya casteado/tipeado
    locs = x
    return locs
  }
  Object.keys(x).map(function (key) {
    if (key && (key !== null || key !== 'null')) {
      if (!x[key]) {
        return false
      }
      locs.push({
        codigo: Number(x[key][ENUM.cod]) ?? 0,
        clase: Number(x[key][ENUM.class]) ?? 0,
        nombre: String(x[key][ENUM.name]) ?? '',
        siglas: String(x[key][ENUM.acronym]) ?? '',
        ciudad: {
          codigo: Number(x[key][ENUM.cityCod]) ?? 0,
          descripcion: String(x[key][ENUM.cityName]) ?? '',
        },
        direccion: String(x[key][ENUM.address]) ?? '',
        telefono: String(x[key][ENUM.numberPhone]) ?? '',
        gerente: String(x[key][ENUM.ger]) ?? '',
        tipo: {
          value: Number(x[key][ENUM.typeCod]) ?? 0,
          label: String(x[key][ENUM.typeDesc]) ?? '',
        },
        pertenece: {
          pertenece: Number(x[key][ENUM.owner]) ?? 0,
          perteneceDescripcion: String(x[key][ENUM.ownerDes]) ?? '',
        },
        kardex: Number(x[key][ENUM.kardex]) ?? 0,
        estado: {
          codigo: Number(x[key][ENUM.stateCod]) ?? 0,
          descripcion: String(x[key][ENUM.stateDes]) ?? '',
        },
        siglasLocal: String(x[key][ENUM.acronym]) ?? '',
        siglasBodega: String(x[key][ENUM.acronymLoc]) ?? '',
        web: Number(x[key][ENUM.web]),
        uaf: String(x[key][ENUM.uaf]) ?? '',
        descuento: Number(x[key][ENUM.descount]) ?? 0,
        contrato: Number(x[key][ENUM.contract]) ?? 0,
        observaciones: String(x[key][ENUM.observations]) ?? '',
        usuarioDokan: String(x[key][ENUM.userDokan]) ?? '',
        passwordDokan: String(x[key][ENUM.passDokan]) ?? '',
        uloTipo: Number(x[key][ENUM.uloTipo]) ?? 0,
        logo: String(x[key][ENUM.logo]) ?? '',
        listaPrecios: Number(x[key][ENUM.pricelist]) ?? 0,
        establecimiento: String(x[key][ENUM.establishment]) ?? '',
      })
    }
  })
  return locs
}

function onDetectEnumLocal(x): KeyLocNoCast | KeyLoc | '' {
  let enumerador: any = ''
  if (x.localCodigo !== undefined) {
    enumerador = KeyLoc
  } else if (x.LOC_CODIGO !== undefined) {
    enumerador = KeyLocNoCast
  } else {
    enumerador = ''
  }
  return enumerador
}

export const ProjectsHelper = { onParseProjets, onParseLocs, onDetectEnumLocal }
