import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ButtonTypes } from '../../../../../views/componentes/globalMenu/types'
import { StatesEdition, TabTypes } from '../../../../ventas/types/enums'
import { DocumentosCtasCobrarListado } from '../types/types'
import { ModuleButtons } from '../components/documento'
import { ModuleButtonsImport } from '../components/importar'
import { ModuleButtonsAnticipos } from '../components/anticipos'
import { ModuleButtonsSaldos } from '../components/saldos'
import { ModuleButtonsVerAbonos } from '../components/verAbonos/resultadoBusqueda'
import { ModuleButtonsAbonar } from '../components/abonos'
import { ModuleButtonsCartera } from '../components/corteComprobantes'
import { TabsState, TabState } from '../../../../../store/genericTabTypes'
import {
  closeTab,
  tabExists,
  tabIsVisible,
} from '../../../../../hooks/useNavigationTabControls'
import { CustomButtons } from '../../../../../store/types'

const initialState: TabsState<DocumentosCtasCobrarListado> = {
  current: 'BUSQUEDA',
  tabs: {
    BUSQUEDA: {
      id: 0,
      canClose: false,
      editStatus: StatesEdition.doesNotApply,
      globalButtonClick: ButtonTypes.none,
      tittle: 'Búsqueda',
      type: TabTypes.busqueda,
      imprimir: false,
      tabKey: 'BUSQUEDA',
      buttons: {
        Buscar: true,
        Nuevo: true,
        Exportar: true,
        Abonar: true,
        Saldos: true,
        Anticipos: true,
        Cartera: true,
        Cartera_vencida: false,
        Ver_abonos: true,
        Deshacer: true,
      },
    },
  },
}

const tabsDocCtasXCobrarSlice = createSlice({
  name: 'docCuentasXCobrarTabs',
  initialState: initialState,
  reducers: {
    clearDataEdition: closeTab,
    openTab(
      state,
      action: PayloadAction<{
        key: string
        cliente?: DocumentosCtasCobrarListado
        templateMode?: boolean
      }>,
    ) {
      if (action.payload.cliente === undefined || action.payload.templateMode) {
        const tab: TabState<DocumentosCtasCobrarListado> = {
          id: -1,
          canClose: true,
          editStatus: StatesEdition.new,
          globalButtonClick: ButtonTypes.none,
          tittle: 'Nueva',
          type: TabTypes.documento,
          imprimir: false,
          tabKey: action.payload.key,
          info: {
            codigo: 0,
          },
          buttons: ModuleButtons,
          templateMode: action.payload.templateMode,
        }
        state.tabs[action.payload.key] = tab
        state.current = action.payload.key
      } else {
        const tabKey = tabExists(state.tabs, action.payload.cliente.codigo)
        if (tabKey) {
          state.current = tabKey
        } else {
          const tab: TabState<DocumentosCtasCobrarListado> = {
            tabKey: action.payload.key,
            id: action.payload.cliente.codigo,
            tittle: `${action.payload.cliente.codigo}-${action.payload.cliente.identificacion}-${action.payload.cliente.codigo}`,
            canClose: true,
            editStatus: StatesEdition.save,
            type: TabTypes.documento,
            info: {
              codigo: action.payload.cliente.codigo,
              info: action.payload.cliente,
            },
            globalButtonClick: ButtonTypes.none,
            imprimir: false,
            buttons: ModuleButtons,
          }
          state.tabs[action.payload.key] = tab
          state.current = action.payload.key
        }
      }
    },
    openTabImport(
      state,
      action: PayloadAction<{
        key: string
        type: TabTypes
      }>,
    ) {
      console.log('action.payload.type:', action.payload.type)
      if (action.payload.type === TabTypes.import) {
        const tabKey = tabIsVisible(state.tabs, action.payload.type)
        if (tabKey) {
          state.current = tabKey
        } else {
          const tab: TabState<DocumentosCtasCobrarListado> = {
            id: -2,
            tittle: 'Importar',
            type: TabTypes.import,
            info: {
              codigo: 0,
            },
            canClose: true,
            editStatus: StatesEdition.new,
            globalButtonClick: ButtonTypes.none,
            imprimir: false,
            tabKey: action.payload.key,
            buttons: ModuleButtonsImport,
          }
          state.tabs[action.payload.key] = tab
          state.current = action.payload.key
        }
      }
    },
    openTabAbonar(
      state,
      action: PayloadAction<{
        key: string
        type: TabTypes
      }>,
    ) {
      if (action.payload.type === TabTypes.abonar) {
        const tabKey = tabIsVisible(state.tabs, action.payload.type)
        if (tabKey) {
          state.current = tabKey
        } else {
          const tab: TabState<DocumentosCtasCobrarListado> = {
            id: -1,
            tittle: 'Abonar',
            type: TabTypes.abonar,
            info: {
              codigo: 0,
            },
            canClose: true,
            editStatus: StatesEdition.new,
            globalButtonClick: ButtonTypes.none,
            imprimir: false,
            tabKey: action.payload.key,
            // buttons: ModuleButtonsImport,
            buttons: ModuleButtonsAbonar,
          }
          state.tabs[action.payload.key] = tab
          state.current = action.payload.key
        }
      }
    },
    openTabSaldos(
      state,
      action: PayloadAction<{
        key: string
        type: TabTypes
      }>,
    ) {
      if (action.payload.type === TabTypes.saldos) {
        const tabKey = tabIsVisible(state.tabs, action.payload.type)
        if (tabKey) {
          state.current = tabKey
        } else {
          const tab: TabState<DocumentosCtasCobrarListado> = {
            id: -1,
            tittle: 'Saldos',
            type: TabTypes.saldos,
            info: {
              codigo: 0,
            },
            canClose: true,
            editStatus: StatesEdition.new,
            globalButtonClick: ButtonTypes.none,
            imprimir: false,
            tabKey: action.payload.key,
            buttons: ModuleButtonsSaldos,
          }
          state.tabs[action.payload.key] = tab
          state.current = action.payload.key
        }
      }
    },
    openTabAnticipos(
      state,
      action: PayloadAction<{
        key: string
        type: TabTypes
      }>,
    ) {
      if (action.payload.type === TabTypes.anticipos) {
        const tabKey = tabIsVisible(state.tabs, action.payload.type)
        if (tabKey) {
          state.current = tabKey
        } else {
          const tab: TabState<DocumentosCtasCobrarListado> = {
            id: -1,
            tittle: 'Anticipos',
            type: TabTypes.anticipos,
            info: {
              codigo: 0,
            },
            canClose: true,
            editStatus: StatesEdition.new,
            globalButtonClick: ButtonTypes.none,
            imprimir: false,
            tabKey: action.payload.key,
            buttons: ModuleButtonsAnticipos,
          }
          state.tabs[action.payload.key] = tab
          state.current = action.payload.key
        }
      }
    },
    openTabVerAbonos(
      state,
      action: PayloadAction<{
        key: string
        type: TabTypes
      }>,
    ) {
      if (action.payload.type === TabTypes.verAbonos) {
        const tabKey = tabIsVisible(state.tabs, action.payload.type)
        if (tabKey) {
          state.current = tabKey
        } else {
          const tab: TabState<DocumentosCtasCobrarListado> = {
            id: -1,
            tittle: 'Ver Abonos',
            type: TabTypes.verAbonos,
            info: {
              codigo: 0,
            },
            canClose: true,
            editStatus: StatesEdition.new,
            globalButtonClick: ButtonTypes.none,
            imprimir: false,
            tabKey: action.payload.key,
            buttons: ModuleButtonsVerAbonos,
          }
          state.tabs[action.payload.key] = tab
          state.current = action.payload.key
        }
      }
    },
    openTabCartera(
      state,
      action: PayloadAction<{
        key: string
        type: TabTypes
      }>,
    ) {
      if (action.payload.type === TabTypes.cartera) {
        const tabKey = tabIsVisible(state.tabs, action.payload.type)
        if (tabKey) {
          state.current = tabKey
        } else {
          const tab: TabState<DocumentosCtasCobrarListado> = {
            id: -1,
            tittle: 'Cartera',
            type: TabTypes.cartera,
            info: {
              codigo: 0,
            },
            canClose: true,
            editStatus: StatesEdition.new,
            globalButtonClick: ButtonTypes.none,
            imprimir: false,
            tabKey: action.payload.key,
            buttons: ModuleButtonsCartera,
          }
          state.tabs[action.payload.key] = tab
          state.current = action.payload.key
        }
      }
    },
    openTabCarteraVencida(
      state,
      action: PayloadAction<{
        key: string
        type: TabTypes
      }>,
    ) {
      if (action.payload.type === TabTypes.carteraVencida) {
        const tabKey = tabIsVisible(state.tabs, action.payload.type)
        if (tabKey) {
          state.current = tabKey
        } else {
          const tab: TabState<DocumentosCtasCobrarListado> = {
            id: -1,
            tittle: 'CarteraVencida',
            type: TabTypes.carteraVencida,
            info: {
              codigo: 0,
            },
            canClose: true,
            editStatus: StatesEdition.new,
            globalButtonClick: ButtonTypes.none,
            imprimir: false,
            tabKey: action.payload.key,
            buttons: ModuleButtonsImport,
          }
          state.tabs[action.payload.key] = tab
          state.current = action.payload.key
        }
      }
    },
    changeCurrentTab(state, action: PayloadAction<string>) {
      if (Object.prototype.hasOwnProperty.call(state.tabs, action.payload)) {
        state.current = action.payload
      }
    },
    setCurrentExecutingAction(
      state,
      action: PayloadAction<{
        tabKey: string
        buttonType: ButtonTypes | undefined
      }>,
    ) {
      const { buttonType, tabKey } = action.payload
      state.tabs[tabKey].currentExecutingAction = buttonType
    },
    setButtons(
      state,
      action: PayloadAction<{ tabKey: string; buttons: CustomButtons }>,
    ) {
      const { buttons, tabKey } = action.payload
      state.tabs[tabKey].buttons = buttons
      state.tabs[tabKey].currentExecutingAction = undefined
    },
    buttonClick(
      state,
      action: PayloadAction<{ tabKey: string; button: ButtonTypes }>,
    ) {
      state.tabs[action.payload.tabKey].globalButtonClick =
        action.payload.button
    },
    clearButtonClick(state, action: PayloadAction<string>) {
      if (Object.prototype.hasOwnProperty.call(state.tabs, action.payload)) {
        state.tabs[action.payload].globalButtonClick = ButtonTypes.none
      }
    },
  },
})

export const {
  clearDataEdition,
  openTab,
  openTabImport,
  openTabAbonar,
  openTabCarteraVencida,
  openTabCartera,
  openTabAnticipos,
  openTabSaldos,
  changeCurrentTab,
  setCurrentExecutingAction,
  openTabVerAbonos,
  setButtons,
  buttonClick,
  clearButtonClick,
} = tabsDocCtasXCobrarSlice.actions
export const tabsReducer = tabsDocCtasXCobrarSlice.reducer
