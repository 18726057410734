import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ConfigConceptoMov } from '../types/types'

const initialState: ConfigConceptoMov = {
  aplicacion: 0,
}

const configuracionConceptoMov = createSlice({
  name: 'configConceptoMov',
  initialState: initialState,
  reducers: {
    setAplicacionModulo(state, acion: PayloadAction<number>) {
      state.aplicacion = acion.payload
    },
  },
})

export const configuracionesConceptosMvo = configuracionConceptoMov.reducer
export const { setAplicacionModulo } = configuracionConceptoMov.actions
