import { SelectBox } from 'devextreme-react'
import React from 'react'
import { TOptionGenerico } from '../../clientes/pages/clientes/types/types'
import { ProcesosTransformacionesService } from './services/procesoTransformacion.service'

interface ProcesosLookUpProps extends React.PropsWithChildren {
  selected: TOptionGenerico | null
  // eslint-disable-next-line no-unused-vars
  onChanged: (newValue: TOptionGenerico | null) => void
  disabled?: boolean
  provider: Array<TOptionGenerico> | []
}

const ProcesosLookup: React.FC<ProcesosLookUpProps> = (props) => {
  const { provider, selected, onChanged, disabled } = props
  const selectRef = React.useRef<any>()
  const [options, setOptions] = React.useState<Array<TOptionGenerico> | []>([])

  const loadDatos = React.useCallback(async () => {
    if (provider !== undefined && provider.length > 0) {
      setOptions(provider)
      const selectedIndex = provider.findIndex((select: TOptionGenerico) => {
        return select.value === selected?.value
      })
      onChanged(provider[selectedIndex] ?? provider[0])
      if (selectedIndex > -1) onChanged(provider[selectedIndex] ?? provider[0])
    } else {
      const data = await ProcesosTransformacionesService.getTransformationProcess('Ninguno')
      if (data.error === false) {
        let opt = []
        if (data.auto.length > 0) {
          opt = data.auto.map((x) => {
            const item: TOptionGenerico = {
              value: parseInt(x?.codigo) ?? -1,
              label: x?.descripcion ?? '',
            }
            return item
          })
        }
        setOptions(opt)
        const selectedIndex = opt.findIndex((option: TOptionGenerico) => {
          return option.value === selected?.value
        })
        if (selectedIndex > -1) {
          onChanged(opt[selectedIndex] ?? opt[0])
        }
      }
    }
  }, [onChanged, provider, selected])

  const onLookUpValueChanged = React.useCallback(
    (evt: any) => {
      if (evt !== undefined) {
        onChanged(evt)
      }
    },
    [onChanged],
  )

  const onSelectBoxFocusIn = React.useCallback((e) => {
    e.element.querySelector('input.dx-texteditor-input').select()
  }, [])

  const refreshSelected = React.useCallback(async () => {
    if (options && options.length > 0) {
      const selectedIndex = await options.findIndex(
        (option: TOptionGenerico) => {
          return option.value === selected?.value
        },
      )
      onChanged(options[selectedIndex] ?? options[0])
    }
  }, [onChanged, options, selected])

  React.useEffect(() => {
    loadDatos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (selected !== null) {
      refreshSelected()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  return (
    <SelectBox
      id="procesosSelect"
      ref={selectRef}
      items={options ?? []}
      searchEnabled
      displayExpr={'label'}
      searchExpr={['label']}
      searchTimeout={500}
      value={selected}
      onValueChange={onLookUpValueChanged}
      disabled={disabled}
      onFocusIn={onSelectBoxFocusIn}
    >
      {props.children}
    </SelectBox>
  )
}

export default React.memo(ProcesosLookup)

