import { CModal, CModalBody } from '@coreui/react-pro'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import LoadingIndicator from '../loadingindicator/loadingindicator'
import { Portal } from 'react-portal'
import './styleLoader.scss'

export default function GlobalLoader() {
  const loaderState = useSelector((state: RootState) => state.global.loader)
  if (!loaderState.show) {
    return <></>
  }
  return (
    <Portal node={document && document.getElementById('root')}>
      <CModal
        visible={loaderState.show}
        alignment="center"
        // closeOnBackdrop={false}
        backdrop={false}
        key="loaderModal"
        id="loaderModal"
        className="loaderModal"
      >
        <CModalBody
          className="loaderModalBody"
          key="loaderModalBody"
          id="loaderModalBody"
        >
          <div key="loaderGlobal" id="loaderGlobal">
            <LoadingIndicator mensaje={loaderState.mensaje} />
            <br></br>
          </div>
        </CModalBody>
      </CModal>
    </Portal>
  )
}
