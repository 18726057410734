import { DateUtils, formatoFechasApi } from "../../../../helpers/dateUtils";
import { RequestHelper } from "../../../../helpers/requestHelper";
import { SesionService } from "../../../../services/sesion.service";
import { parseLocalOptions } from "../helper/helper";
import { TEtapaProduccionOption } from "../store/type";

export const etapasProduccionService = { getEtapas, getAllEtapas, saveEtapa };

async function getEtapas(label: string): Promise<any> {
  try {
    const data: any = {
      mensaje: label
    };
    const sesion = SesionService.getCurrentSesion()
    const keysToCache: any = {
      ...data,
      dateKey: DateUtils.getCurrentDateAsString(formatoFechasApi),
      empresa: sesion?.empresa?.codigo,
    }
    const cacheKey = 'tipoEtapaProduccion' + JSON.stringify(keysToCache);
    const cached = sessionStorage.getItem(cacheKey);
    if (cached) {
      return { error: false, auto: JSON.parse(cached) };
    } else {
      const res = await RequestHelper.getAll<any>('inventario/catalogos', 'etapa/cBoxLoad', "", data);
      let opt: Array<TEtapaProduccionOption> = [];
      if (res?.auto && res?.auto.length > 0) {
        opt = parseLocalOptions(res.auto);
      }
      sessionStorage.setItem(cacheKey, JSON.stringify(opt));
      res.auto = opt ?? []
      return res;
    }
  } catch (error) {
    return error;
  }
}

async function getAllEtapas(data: any): Promise<any> {
  try {
    const obj: any = {
      ...data
    }
    const apiData = await RequestHelper.getAll<any>('inventario/catalogos', 'etapa/getAll', "", obj);
    return apiData
  } catch (error) {
    return error;
  }
}

async function saveEtapa(data: any): Promise<any> {
  try {
    const obj: any = {
      ...data
    }
    const apiData = await RequestHelper.postAll<any>('inventario/catalogos', 'etapa/save', obj);
    return apiData
  } catch (error) {
    return error;
  }
}

