import React from 'react';
import Checkbox from './Checkbox';
import EditableName from './EditableName';
import PropTypes from 'prop-types';
import './folderTreeCSS.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FileComponent = ({ level, checked, handleCheck, filename, setMyName, selectMe, selected }) => (
  <div className={"file"} style={{ marginLeft: getInden(level) }}>
    <Checkbox status={checked} handleCheck={handleCheck} />

    <span className={selected ? ["fileText", "selected"].join(' ') : "fileText"} onClick={selectMe}>
      <FontAwesomeIcon
        size="lg"
        icon={['fal', "file"]} style={{ marginLeft: '10px' }}
        className={"fileIcon"}
      />
      <EditableName filename={filename} setMyName={setMyName} selected={selected} />
    </span>

  </div>
);

FileComponent.propTypes = {
  path: PropTypes.array.isRequired,
  level: PropTypes.number.isRequired,
  checked: PropTypes.number.isRequired,
  filename: PropTypes.string.isRequired,
  selected: PropTypes.number.isRequired,

  selectMe: PropTypes.func.isRequired,
  setMyName: PropTypes.func.isRequired,
  handleCheck: PropTypes.func.isRequired,
};

function getInden(level) {
  return `${5 * level}px`;
}

export default FileComponent;