import { useCallback, useEffect, useRef } from 'react'
import { addToast } from '../store/toasterReducer'
import { useDispatch } from 'react-redux'
import { ToastTypes } from '../store/types'

function useConvertToBase64() {
  const convertToBase64 = useCallback(async (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }, [])

  return convertToBase64
}

function useSetToast() {
  const dispatch = useDispatch()

  const setToastMessage = useCallback(
    (title: string, message: string, type: ToastTypes) => {
      dispatch(
        addToast({
          title: title,
          content: message,
          type: type,
        }),
      )
    },
    [dispatch],
  )

  return setToastMessage
}

/**
 * useTriggerEffect: Custom hook que ejecuta una acción cuando un disparador (trigger) cambia.
 * Útil para encapsular lógica de efecto común en varios componentes.
 *
 * @param {boolean} trigger - El disparador que activará la ejecución de la acción.
 * @param {function} resetTrigger - Función para reiniciar el disparador después de ejecutar la acción.
 * @param {function} callback - La acción que se ejecutará cuando el disparador sea verdadero.
 */
const useTriggerEffect = (trigger: boolean, resetTrigger, callback) => {
  useEffect(() => {
    if (trigger) {
      resetTrigger(false)
      callback()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger])
}

/**
 * Custom hook que devuelve un callback con una restricción de tiempo.
 * @param {function} callback - La función que se ejecutará con la restricción de tiempo.
 * @param {number} delayMs - El tiempo en milisegundos que debe transcurrir antes de poder ejecutar el callback nuevamente.
 * @returns {function} - El callback con la restricción de tiempo.
 * @note Útil para manejar varios clicks rápidos en un mismo botón, ejecutando la acción solo una vez después del tiempo de retardo.
 */
const useDelayedCallback = (customCallback, delayMs = 1000) => {
  const lastCallTime = useRef(0)

  const setDelayedCallback = useCallback(() => {
    const currentTime = Date.now()
    const timeElapsedSinceLastCall = currentTime - lastCallTime.current

    if (timeElapsedSinceLastCall >= delayMs) {
      if (customCallback && typeof customCallback === 'function')
        customCallback()
      lastCallTime.current = currentTime
    }
  }, [customCallback, delayMs])

  return setDelayedCallback
}

export default useTriggerEffect

export { useConvertToBase64, useSetToast, useTriggerEffect, useDelayedCallback }
