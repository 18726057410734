import { consoleService } from "../console.service";
import { KeyItemLocalSimple, KeyItemSales } from "../itemEnum/enumItem";
import { TItemLocalSimple } from "../types/types";

export function parseApiItemVenta(x: any) {
  consoleService.log("parseApiItemVenta: ", x)
  const item = {
    codigo: parseInt(x[KeyItemSales.cod]),
    codigoInterno: x[KeyItemSales.codInt],
    codigoBarras: x[KeyItemSales.barrraCod],
    descripcion: x[KeyItemSales.descripcion],
    existencia: parseFloat(x[KeyItemSales.existencia]),
    pvdiva: parseFloat(x[KeyItemSales.pvdiva]),
    pvpiva: parseFloat(x[KeyItemSales.pvpiva]),
    pvd: parseFloat(x[KeyItemSales.pvd]),
    pvp: parseFloat(x[KeyItemSales.pvp]),
    codigoImpuesto: parseInt(x[KeyItemSales.codigoImpuesto]),
    tieneIce: (x[KeyItemSales.tieneIce] === '-1'),
    porcentajeIva: parseFloat(x[KeyItemSales.porcentajeIva]),
    costo: parseFloat(x[KeyItemSales.costo]),
    tipoCodigo: parseInt(x[KeyItemSales.tipoCodigo]),
    numeroSerie: parseInt(x[KeyItemSales.numeroSerie]),
    plantilla: parseInt(x[KeyItemSales.plantilla]),
    freeitem: parseInt(x[KeyItemSales.freeitem]),
    gradoAcoholico: parseInt(x[KeyItemSales.gradoAcoholico]),
    capacidad: parseFloat(x[KeyItemSales.capacidad]),
    factor: parseFloat(x[KeyItemSales.factor]),
    jerarquia: x[KeyItemSales.jerarquia],
    descuentos: parseFloat(x[KeyItemSales.descuentos]),
    titadicional: x[KeyItemSales.titadicional],
    valadicional: x[KeyItemSales.valadicional],
    codigoUnidadVenta: parseInt(x[KeyItemSales.codigoUnidadVenta]),
    tipoDescripcion: x[KeyItemSales.tipoDescripcion],
    codigoGrupo: parseInt(x[KeyItemSales.codigoGrupo]),
    reservas: parseInt(x[KeyItemSales.reservas]),
    stockMin: parseInt(x[KeyItemSales.stockMin]),
    stockMax: parseInt(x[KeyItemSales.stockMax])
  };
  return item;
}



export function parseApiItemsLocalSimple(data: any): Array<TItemLocalSimple> {
  consoleService.log("parseApiItemsLocalSimple: ", data)
  const items = data.map(x => {
    const item = {
      codigo: parseInt(x[KeyItemLocalSimple.cod]),
      codigoInterno: x[KeyItemLocalSimple.codInt],
      codigoBarras: x[KeyItemLocalSimple.barrraCod],
      descripcion: x[KeyItemLocalSimple.descripcion],
      generico: x[KeyItemLocalSimple.generico],
      existencia: parseFloat(x[KeyItemLocalSimple.existencia]),
      pvdiva: parseFloat(x[KeyItemLocalSimple.pvdiva]),
      pvpiva: parseFloat(x[KeyItemLocalSimple.pvpiva]),
      pvd: parseFloat(x[KeyItemLocalSimple.pvd]),
      pvp: parseFloat(x[KeyItemLocalSimple.pvp]),
      codigoImpuesto: parseInt(x[KeyItemLocalSimple.codigoImpuesto]),
      tieneIce: x[KeyItemLocalSimple.tieneIce], // verificar boolean
      porcentajeIva: parseFloat(x[KeyItemLocalSimple.porcentajeIva]),
      costo: parseFloat(x[KeyItemLocalSimple.costo]),
      tipoCodigo: parseInt(x[KeyItemLocalSimple.tipoCodigo]),
      numeroSerie: parseInt(x[KeyItemLocalSimple.numeroSerie]),
      plantilla: parseInt(x[KeyItemLocalSimple.plantilla]),
      freeitem: parseInt(x[KeyItemLocalSimple.freeitem]),
      gradoAcoholico: parseInt(x[KeyItemLocalSimple.gradoAcoholico]),
      capacidad: parseFloat(x[KeyItemLocalSimple.capacidad]),
      factor: parseFloat(x[KeyItemLocalSimple.factor]),
      descuentos: parseFloat(x[KeyItemLocalSimple.descuentos]),
      jerarquia: x[KeyItemLocalSimple.jerarquia],
      titadicional: x[KeyItemLocalSimple.titadicional],
      valadicional: x[KeyItemLocalSimple.valadicional],
      codigoUnidadIce: parseInt(x[KeyItemLocalSimple.codigoUnidadIce]),
      lineaDescripcion: x[KeyItemLocalSimple.lineaDescripcion],
      marcaDescripcion: x[KeyItemLocalSimple.marcaDescripcion],
      grupoCodigo: parseInt(x[KeyItemLocalSimple.grupoCodigo]),
      grupoDescripcion: x[KeyItemLocalSimple.grupoDescripcion],
      unidadDescripcion: x[KeyItemLocalSimple.unidadDescripcion],
      imagen: parseInt(x[KeyItemLocalSimple.imagen]),
      lotes: x[KeyItemLocalSimple.lotes],
      procedenciaDescripcion: x[KeyItemLocalSimple.procedenciaDescripcion],
      impuesto: parseInt(x[KeyItemLocalSimple.impuesto]),
      impuestoValor: parseInt(x[KeyItemLocalSimple.impuestoValor]),
      pvo: parseInt(x[KeyItemLocalSimple.pvo]),
      costeo: parseInt(x[KeyItemLocalSimple.costeo]),
      costoIVA: parseInt(x[KeyItemLocalSimple.costoIVA]),
      aplicaciones: parseInt(x[KeyItemLocalSimple.aplicaciones]),
      peso: parseInt(x[KeyItemLocalSimple.peso]),
      largo: parseInt(x[KeyItemLocalSimple.largo]),
      ancho: parseInt(x[KeyItemLocalSimple.ancho]),
      alto: parseInt(x[KeyItemLocalSimple.alto]),
      nota: parseInt(x[KeyItemLocalSimple.nota]),
      tags: parseInt(x[KeyItemLocalSimple.tags]),
      gananciaPVD: parseInt(x[KeyItemLocalSimple.gananciaPVD]),
      gananciaPVP: parseInt(x[KeyItemLocalSimple.gananciaPVP]),
      localCodigo: parseInt(x[KeyItemLocalSimple.localCodigo]),
      loteNumero: parseInt(x[KeyItemLocalSimple.loteNumero]),
      loteCaducidad: parseInt(x[KeyItemLocalSimple.loteCaducidad]),
      localizacion: parseInt(x[KeyItemLocalSimple.localizacion]),
      proveedores: parseInt(x[KeyItemLocalSimple.proveedores]),
      padre: parseInt(x[KeyItemLocalSimple.padre]),
      controlador: parseInt(x[KeyItemLocalSimple.controlador]),
      tallaInicial: parseInt(x[KeyItemLocalSimple.tallaInicial]),
      tallaFinal: parseInt(x[KeyItemLocalSimple.tallaFinal]),
      valores: parseInt(x[KeyItemLocalSimple.valores]),
      propiedades: parseInt(x[KeyItemLocalSimple.propiedades]),
      desperdicio: parseInt(x[KeyItemLocalSimple.desperdicio]),
      subsidio: parseInt(x[KeyItemLocalSimple.subsidio]),
      saldoReservas: parseInt(x[KeyItemLocalSimple.saldoFecha]),
      existenciaMax: parseInt(x[KeyItemLocalSimple.existenciaMax]),
      existenciaMin: parseInt(x[KeyItemLocalSimple.existenciaMin]),
      saldoFecha: parseInt(x[KeyItemLocalSimple.saldoReservas]),
      unidadVenCod: parseInt(x[KeyItemLocalSimple.unidadVenCod]),
      unidadVenDes: parseInt(x[KeyItemLocalSimple.unidadVenDes]),
      tipoDescripcion: parseInt(x[KeyItemLocalSimple.tipoDescripcion]),
    };
    return item;
  });
  return items;
}