import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import { StatesEdition, TabTypes } from '../../../../../ventas/types/enums'
import { GemasDatosEdicion } from '../types/types'
import { ModuleButtons } from '..'
import { TabsState, TabState } from '../../../../../../store/genericTabTypes'
import {
  tabExists,
  closeTab,
} from '../../../../../../hooks/useNavigationTabControls'
import { CustomButtons } from '../../../../../../store/types'

const initialState: TabsState<GemasDatosEdicion> = {
  current: 'BUSQUEDA',
  tabs: {
    BUSQUEDA: {
      id: 0,
      canClose: false,
      editStatus: StatesEdition.doesNotApply,
      globalButtonClick: ButtonTypes.none,
      tittle: 'Búsqueda',
      type: TabTypes.busqueda,
      imprimir: false,
      tabKey: 'BUSQUEDA',
      buttons: {
        Nuevo: true,
        Guardar: false,
        Editar: false,
        Eliminar: false,
        Buscar: true,
        Imprimir: false,
        Deshacer: false,
      },
    },
  },
}

const tabsGemasSlice = createSlice({
  name: 'catalogosGemasTabs',
  initialState: initialState,
  reducers: {
    closeTabAtributos: closeTab,
    openTab(
      state,
      action: PayloadAction<{
        key: string
        gema?: GemasDatosEdicion
        templateMode?: boolean
      }>,
    ) {
      const { key, gema, templateMode } = action.payload
      if (gema === undefined || templateMode) {
        const tab: TabState<GemasDatosEdicion> = {
          id: -1,
          canClose: true,
          editStatus: StatesEdition.new,
          globalButtonClick: ButtonTypes.none,
          tittle: 'Nueva',
          type: TabTypes.documento,
          imprimir: false,
          tabKey: key,
          info: {
            codigo: 0,
          },
          buttons: ModuleButtons,
          templateMode: templateMode,
        }
        state.tabs[key] = tab
        state.current = key
      } else {
        const tabKey = tabExists(state.tabs, gema.codigo)
        if (tabKey) {
          state.current = tabKey
        } else {
          const tab: TabState<GemasDatosEdicion> = {
            tabKey: key,
            id: gema.codigo,
            tittle: gema.descripcion,
            canClose: true,
            editStatus: StatesEdition.save,
            type: TabTypes.documento,
            info: {
              codigo: gema.codigo,
              info: gema,
            },
            globalButtonClick: ButtonTypes.none,
            imprimir: false,
            buttons: ModuleButtons,
          }
          state.tabs[key] = tab
          state.current = key
        }
      }
    },
    setNameTabe(
      state,
      action: PayloadAction<{ key: string; codigo: number; nombre: string }>,
    ) {
      state.tabs[
        action.payload.key
      ].tittle = `${action.payload.codigo}-${action.payload.nombre}`
      state.tabs[action.payload.key].info.codigo = action.payload.codigo
    },
    changeCurrentTab(state, action: PayloadAction<string>) {
      if (Object.prototype.hasOwnProperty.call(state.tabs, action.payload)) {
        state.current = action.payload
      }
    },
    setCurrentExecutingAction(
      state,
      action: PayloadAction<{
        tabKey: string
        buttonType: ButtonTypes | undefined
      }>,
    ) {
      const { buttonType, tabKey } = action.payload
      state.tabs[tabKey].currentExecutingAction = buttonType
    },
    setButtons(
      state,
      action: PayloadAction<{ tabKey: string; buttons: CustomButtons }>,
    ) {
      const { buttons, tabKey } = action.payload
      state.tabs[tabKey].buttons = buttons
      state.tabs[tabKey].currentExecutingAction = undefined
    },
    buttonClick(
      state,
      action: PayloadAction<{ tabKey: string; button: ButtonTypes }>,
    ) {
      state.tabs[action.payload.tabKey].globalButtonClick =
        action.payload.button
    },
    clearButtonClick(state, action: PayloadAction<string>) {
      if (Object.prototype.hasOwnProperty.call(state.tabs, action.payload)) {
        state.tabs[action.payload].globalButtonClick = ButtonTypes.none
      }
    },
  },
})

export const {
  closeTabAtributos,
  openTab,
  changeCurrentTab,
  setCurrentExecutingAction,
  setButtons,
  buttonClick,
  clearButtonClick,
  setNameTabe,
} = tabsGemasSlice.actions
export const tabsReducer = tabsGemasSlice.reducer
