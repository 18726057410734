import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  FiltrosCombinacionesState,
  BusquedaState,
  Combinacion,
} from '../types/types'
import { FETCH_STATUS } from '../../../../../../store/types'
import { conceptsServices } from '../services/combinaciones.services'

const initialState: BusquedaState<Combinacion> = {
  filter: {},
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  resultados: [],
}

export const fetchCombination = createAsyncThunk<
  Array<Combinacion>,
  FiltrosCombinacionesState
>('inventarios/movimientos/combinaciones/listar', async (filtro) => {
  try {
    const conbinationsList = conceptsServices.getConcepts(filtro)
    return conbinationsList
  } catch (error) {
    return Promise.reject(error)
  }
})

const searchSlice = createSlice({
  name: 'buscarCombinacionesMov',
  initialState: initialState,
  reducers: {
    setCleanResult(state) {
      state.resultados = []
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCombination.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchCombination.fulfilled, (state, { payload }) => {
      state.resultados = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchCombination.rejected, (state, { payload }) => {
      state.resultados = []
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
  },
})

export const { setCleanResult } = searchSlice.actions

export const searchReducer = searchSlice.reducer
