import { RequestHelper } from '../../../../helpers/requestHelper'

export const condicionACtivoServices = { getConditions }

async function getConditions(): Promise<any> {
  try {
    const data = await RequestHelper.getAll<any>(
      'inventario/items',
      'condition/getAll',
      '',
      {},
    )
    return data
  } catch (error) {
    return error
  }
}
