import { CompraDetalleInfo } from './../../../types/types';

import { DetalleCompra, DetalleCompraRedux } from "../types/detalleCompra";
import { DetalleVenta, DetalleVentaRedux } from '../../../../ventas/pages/ventas/types/detalleVenta';

export const CompraHelper = {
  detallesToDetallesCompra,
  detallesToDetallesVenta,
}

export function detallesToDetallesCompra(detalles: Array<CompraDetalleInfo>): Array<DetalleCompraRedux> {
  return detalles.map(det => {
    const detalle: DetalleCompraRedux = {
      codigo: det.codigo,
      codigoLocal: det.localCodigo,
      codigoInterno: det.itemCodigo.toString(),
      codigoBarras: det.itemBarras,
      descripcion: det.itemDescripcion,
      porcentajeiva: det.porcentajeIva / 100,
      _cantidad: det.cantidad,
      _precioUnitario: det.precioUnitario,
      tieneIva: det.porcentajeIva > 0,
      imagenes: [],// det.imagenes,
      _descuento: 0,
      _iva: 0,
      _porcentajeDescuento: det.descuento,
      _precioIva: 0,
      _subtotal: 0,
      _subtotal0: 0,
      _subtotalIva: 0,
      _total: 0,
      fecha: ''
    }
    const _detalle: DetalleCompra = new DetalleCompra();
    _detalle.setDetalle(detalle);

    // const detalle = new DetalleVenta();
    // detalle.setData(det.codigo, det.codigoint, det.barras, det.descripcion, det.porcentajeiva);
    // detalle.cantidad = det.cantidad;
    // detalle.precio = det.pvd;
    // detalle.imagenes = det.imagenes;
    return _detalle.getDetalle();
  })
  //return [];
}

function detallesToDetallesVenta(
  detalles: Array<CompraDetalleInfo>,
  presicionDecimal: number,
): Array<DetalleVentaRedux> {
  return detalles.map((det) => {


    const detalle: DetalleVentaRedux = {
      codigo: det.codigo,
      codigoInterno: det.itemNumeroSerie,
      codigoBarras: det.itemBarras,
      codigoImpuesto: det.impuesto,
      descripcion: det.itemDescripcion,
      porcentajeiva: det.porcentajeIva / 100,
      _cantidad: det.cantidad,
      _precioUnitario: det.precioUnitario,
      tieneIva: det.porcentajeIva > 0,
      imagenes: det.imagenes,
      _descuento: det.descuento,
      _iva: 0,
      _porcentajeDescuento: det.descuento,
      _precioIva: 0,
      _subtotal: 0,
      _subtotal0: 0,
      _subtotalIva: 0,
      _total: 0,
      _subtotal5: 0,
      fecha: '',
    }
    const _detalle: DetalleVenta = new DetalleVenta()
    _detalle.setDetalle(detalle, presicionDecimal)

    // const detalle = new DetalleVenta();
    // detalle.setData(det.codigo, det.codigoint, det.barras, det.descripcion, det.porcentajeiva);
    // detalle.cantidad = det.cantidad;
    // detalle.precio = det.pvd;
    // detalle.imagenes = det.imagenes;
    return _detalle.getDetalle()
  })
  //return [];
}

