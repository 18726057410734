import { memo } from 'react'
import { GlobalMenu } from '../../../../../../views/componentes/globalMenu/globalMenu'
import TabControlPayroll from './index'
import { Aplicacion, IGnericMainTabProps } from '../../../../store/types'
import { Candidate } from '../../types/types'

const PayrollMain = (props: IGnericMainTabProps<Candidate>) => {
  const {
    actionsMenu,
    onMenuButtonClick,
    getButtonDisabled,
    currentWorking,
    tabs,
    onTabChanged,
    onTabClosing,
  } = props

  return (
    <div style={{ backgroundColor: '#fff' }}>
      <GlobalMenu
        printButtonAsDropdown={false}
        showExportDesingOptions
        acciones={actionsMenu}
        onClick={onMenuButtonClick}
        getButtonDisabled={getButtonDisabled}
        currentWorking={currentWorking}
      />
      <TabControlPayroll
        tabsState={tabs}
        onTabChanged={onTabChanged}
        ontabClosing={onTabClosing}
        aplicationType={Aplicacion.candidatos}
      />
    </div>
  )
}

export default memo(PayrollMain)
