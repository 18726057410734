import { SelectBox } from 'devextreme-react'
import React from 'react'
import { staticLookUpCliente } from '../lookupZonasGeograficas/store/types'

interface ActividadEcoLookupProps extends React.PropsWithChildren {
  selected: staticLookUpCliente | null
  onChanged: (newValue: staticLookUpCliente | null) => void
  disabled?: boolean
  provider: Array<staticLookUpCliente> | []
}

const ActividadEcoLookup: React.FC<ActividadEcoLookupProps> = (props) => {
  const { provider, selected, onChanged, disabled } = props
  const selectRef = React.useRef<any>()
  const [options, setOptions] = React.useState<Array<staticLookUpCliente> | []>([]);

  const loadDatos = React.useCallback(async () => {
    if (provider !== undefined && provider.length > 0) {
      setOptions(provider)
      const selectedIndex = provider.findIndex((select: staticLookUpCliente) => {
        return select.codigo === selected?.codigo
      })
      onChanged(provider[selectedIndex] ?? provider[0])
      if (selectedIndex > -1) {
        onChanged(provider[selectedIndex] ?? provider[0])
      }
    } else {
      const opt = actividadEconomica
      setOptions(opt)
      const selectedIndex = opt.findIndex((option) => {
        return option.codigo === selected?.codigo
      })
      if (selectedIndex > -1) {
        onChanged(opt[selectedIndex] ?? opt[0])
      }
    }
  }, [onChanged, provider, selected]);

  const onLookUpValueChanged = React.useCallback(
    (evt: any) => {
      if (evt.event !== undefined) {
        onChanged(evt.value)
      }
    },
    [onChanged])

  const onSelectBoxFocusIn = React.useCallback((e) => {
    e.element.querySelector('input.dx-texteditor-input').select()
  }, []);

  const refreshSelected = React.useCallback(async () => {
    if (options && options.length > 0) {
      const selectedIndex = await options.findIndex((option: staticLookUpCliente) => {
        return option.codigo === selected?.codigo
      })
      onChanged(options[selectedIndex] ?? options[0])
    }
  }, [onChanged, options, selected])

  React.useEffect(() => {
    loadDatos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (selected !== null) {
      refreshSelected()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])


  return (
    <SelectBox
      id="zonaGeograficaSelect"
      ref={selectRef}
      items={actividadEconomica ?? []}
      searchEnabled
      displayExpr={'descripcion'}
      searchExpr={['descripcion']}
      searchTimeout={500}
      onEnterKey={(e) => {
        console.log(e)
      }}
      value={selected}
      onValueChanged={onLookUpValueChanged}
      disabled={disabled}
      onFocusIn={onSelectBoxFocusIn}
    >
      {props.children}
    </SelectBox>
  )
}

export default React.memo(ActividadEcoLookup)

export const actividadEconomica: Array<staticLookUpCliente> = [
  {
    codigo: 0,
    descripcion: 'Elija una opción',
    porcentaje: 0,
    natural: 50,
    juridica: 100,
  },
  {
    codigo: 1,
    descripcion: 'Actividades no consideradas de alto riesgo',
    porcentaje: 0,
    natural: 25,
    juridica: 30,
  },
  {
    codigo: 2,
    descripcion: 'Casinos, salas de juegos e hipódromos',
    porcentaje: 100,
    natural: 25,
    juridica: 30,
  },
  {
    codigo: 3,
    descripcion: 'Remesadoras de fondos',
    porcentaje: 100,
    natural: 25,
    juridica: 30,
  },
  {
    codigo: 4,
    descripcion: 'Organizaciones No Gubernamentales (ONG´S)',
    porcentaje: 100,
    natural: 25,
    juridica: 30,
  },
  {
    codigo: 5,
    descripcion: 'Sociedad financiera de inversión.',
    porcentaje: 100,
    natural: 25,
    juridica: 30,
  },
  {
    codigo: 6,
    descripcion: 'Productores, comerciantes y distribuidores de armas',
    porcentaje: 100,
    natural: 25,
    juridica: 30,
  },
  {
    codigo: 7,
    descripcion: 'Comerciantes y distribuidores de metales preciosos',
    porcentaje: 100,
    natural: 25,
    juridica: 30,
  },
  {
    codigo: 8,
    descripcion: 'Comerciantes en antigüedades',
    porcentaje: 100,
    natural: 25,
    juridica: 30,
  },
  {
    codigo: 9,
    descripcion:
      'Abogados o contadores que manejan fondos de sus clientes en sus cuentas',
    porcentaje: 100,
    natural: 25,
    juridica: 30,
  },
  {
    codigo: 10,
    descripcion: 'Casas de empeño',
    porcentaje: 100,
    natural: 25,
    juridica: 30,
  },
  {
    codigo: 11,
    descripcion: 'Compañías inmobiliarias',
    porcentaje: 100,
    natural: 25,
    juridica: 30,
  },
  {
    codigo: 12,
    descripcion: 'Empresas constructoras en zonas turísticas',
    porcentaje: 100,
    natural: 25,
    juridica: 30,
  },
  {
    codigo: 13,
    descripcion: 'Hoteles',
    porcentaje: 100,
    natural: 25,
    juridica: 30,
  },
  {
    codigo: 14,
    descripcion:
      'Otros negocios intensivos en efectivos, ejemplo: supermercados, restaurantes, bares, garajes, etc',
    porcentaje: 100,
    natural: 25,
    juridica: 30,
  },
]
