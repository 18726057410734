import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FETCH_STATUS, LoaderInfo } from "../../../../../store/types";
import { RolesList, RolState, RolXMLList, UsuarioLocalList, UsuariosRolList } from "../types/types";


const initialState: RolState<RolXMLList> = {
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  resultadosOrganigrama: [],
  resultadosRoles: [],
  resultadosUsuarios: [],
  resultadosUsuariosRol: [],
  seleccionadoUsuarios: null,
  seleccionadoRoles: null,
  seleccionadoUsuariosRol: null,
  loader: {
    show: false,
    mensaje: '',
  },
  initial: true
}



const organigramaSlice = createSlice({
  name: 'busquedaOrganigrama',
  initialState: initialState,
  reducers: {
    setResults(state, action: PayloadAction<RolXMLList[]>) {
      state.resultadosOrganigrama = action.payload
    },
    setResultsRol(state, action: PayloadAction<RolesList[]>) {
      state.resultadosRoles = action.payload
    },
    setResultsUsers(state, action: PayloadAction<UsuarioLocalList[]>) {
      state.resultadosUsuarios = action.payload
    },
    setResultsUsersRol(state, action: PayloadAction<UsuariosRolList[]>) {
      state.resultadosUsuariosRol = action.payload
    },
    setChangeLoader(state, action: PayloadAction<LoaderInfo>) {
      state.loader = action.payload
    },
    setResetSeleccion(state) {
      state.seleccionadoUsuarios = null
    },
    setSeleccionarDato(state, action: PayloadAction<UsuarioLocalList>) {
      state.seleccionadoUsuarios = action.payload
    },
    setResetSeleccionRol(state) {
      state.seleccionadoUsuariosRol = null
    },
    setSeleccionarDatoRol(state, action: PayloadAction<UsuariosRolList>) {
      state.seleccionadoUsuariosRol = action.payload
    },
    setResetSeleccionRoles(state) {
      state.seleccionadoRoles = null
    },
    setSeleccionarDatoRoles(state, action: PayloadAction<RolesList>) {
      state.seleccionadoRoles = action.payload
    },
    setInitital(state, action: PayloadAction<boolean>) {
      state.initial = action.payload
    },
  }
})

export const {
  setResults,
  setChangeLoader,
  setResetSeleccion,
  setSeleccionarDato,
  setResultsRol,
  setResultsUsers,
  setResultsUsersRol,
  setResetSeleccionRol,
  setSeleccionarDatoRol,
  setResetSeleccionRoles,
  setSeleccionarDatoRoles,
  setInitital,
} = organigramaSlice.actions

export const organigramaReducer = organigramaSlice.reducer