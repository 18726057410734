import React from 'react'
import CIcon from '@coreui/icons-react'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CCollapse,
  CLink,
  CRow,
} from '@coreui/react-pro'
import { cilChevronBottom, cilChevronTop } from '@coreui/icons'
import CustomCol from '../../../../../../views/componentes/colContainer'
import { CheckBox, DataGrid, DateBox, LoadPanel, NumberBox, RadioGroup } from 'devextreme-react'
import { Column, FilterRow, HeaderFilter, Pager, Paging } from 'devextreme-react/data-grid'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput'
import LocalidadesLookUp from '../../../../../componentes/localidadesLookUp/localidadesLookUp'
import CabeceraFiltroBusqueda from '../shared/cabeceraFiltroBusqueda'
import { TabState } from '../../../../../../store/genericTabTypes'
import { OptionCiudad } from '../../../../../../containers/component/formLocal/types/types'
import { CustomButtons, FETCH_STATUS, ToastTypes } from '../../../../../../store/types'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import { clearButtonClick, setCurrentExecutingAction } from '../../store/tabsReducer'
import { useDispatch, useSelector } from 'react-redux'
import { fetchWallet, setChangeFilter } from '../../store/walletReducer'
import { RootState } from '../../../../../../store/store'
import { DateUtils } from '../../../../../../helpers/dateUtils'
import { addToast } from '../../../../../../store/toasterReducer'
import { MessagesKeys, lh } from '../../../../../../helpers/localizationHelper'
import { ReportWithLocalDataModal } from '../../../../../../views/componentes/xtrareports/ReportWithLocalDataModal'
import { FilterWalletState } from '../../types/types'
import { cleanFilter } from '../../store/searchReducer'

const gruposTipos = [
  { value: "", label: 'Todos' },
  { value: "N", label: 'Nacional' },
  { value: "E", label: 'Extrangero' },
]

interface ICorteComprobantesProps {
  tabId: string
  tab: TabState<any>
}

const CorteComprobantes: React.FunctionComponent<ICorteComprobantesProps> = (props) => {
  const { tab, tabId } = props
  const dispatch = useDispatch()

  const filterGlobal = useSelector((state: RootState) => state.proveedores.documentosCuentasPorPagar.search.filter)
  const filtros = useSelector((state: RootState) => state.proveedores.documentosCuentasPorPagar.wallet.filter)
  const cartera = useSelector((state: RootState) => state.proveedores.documentosCuentasPorPagar.wallet)

  const [collapsed, setCollapsed] = React.useState(true)
  const [displayMode] = React.useState<string | 'full' | 'compact'>('full')
  const [showPageSizeSelector] = React.useState<boolean>(true)
  const [showInfo] = React.useState<boolean>(true)
  const [showNavButtons] = React.useState<boolean>(true)
  const [reporte, setReporte] = React.useState<null | "Viewer" | "Designer">(null)

  const [corte, setCorte] = React.useState<Date>(new Date())
  const [radioOption, setRadioOption] = React.useState(gruposTipos[0])
  const [ciudad, setCiudad] = React.useState<OptionCiudad>(null)
  const [consolidado, setConsolidado] = React.useState<boolean>(false)
  const [saldoMayorCero, setSaldoMayorCero] = React.useState<boolean>(false)
  const [total, setTotal] = React.useState<number>(null)

  const [resultados, setResultados] = React.useState<Array<any>>([])

  const position = { of: '#walletContent' }

  const getFilterString = React.useCallback((filter: FilterWalletState) => {
    const filterText = "";

    return filterText;
  }, [])

  const onButtonPrint = React.useCallback(async (filtro: null | "Viewer" | "Designer") => {
    setReporte(filtro);
  }, []);

  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(
      addToast({
        title: 'Ver Cartera',
        content: mensaje,
        type: tipo,
      }),
    )
  }, [dispatch])

  const onChangedData = React.useCallback((data, key) => {
    if (data.value !== null && data.value !== undefined) {
      let makeDispatch = true
      switch (key) {
        case 'corte':
          setCorte(data.value)
          break;
        case 'radioOption':
          setRadioOption(data.value)
          break;
        case 'consolidado':
          setConsolidado(data.value)
          break;
        case 'saldoMayorCero':
          setSaldoMayorCero(data.value)
          break;

        default:
          makeDispatch = false
          break;
      }

      if (makeDispatch) {
        let value = null
        if (key === "corte") {
          value = DateUtils.dateToString(data.value, 'dd/MM/yyyy')
        } else {
          value = data.value
        }
        dispatch(setChangeFilter({
          ...filtros,
          [key]: value
        }))
      }
    } else if (data !== null && data !== undefined && key === "ciudad") {
      setCiudad(data)
      dispatch(setChangeFilter({
        ...filtros,
        [key]: data
      }))
    }
  }, [dispatch, filtros])

  const onBroom = React.useCallback(() => {
    dispatch(cleanFilter())
    setCorte(new Date())
    setRadioOption(gruposTipos[0])
    setCiudad(null)
    setConsolidado(false)
    setSaldoMayorCero(null)
    setTotal(null)
    setResultados([])
  }, [dispatch])

  const onFind = React.useCallback(async () => {
    try {
      const object_request = {
        codigo: filtros.proveedor !== null ? filtros.proveedor?.codigo : 0,
        ciudad: ciudad !== null ? ciudad?.codigo : -1,
        local: filtros.local !== null ? filtros.local?.codigo : 0,
        corte: DateUtils.dateToString(corte, 'dd/MM/yyyy'),
        consolidado: consolidado,
        tipoproveedor: radioOption.value,
        mayor: saldoMayorCero,
      }
      setResultados([])
      const toAny: any = fetchWallet(object_request)
      const res = await dispatch(toAny)
      if (res !== null && res !== undefined && res.payload['error'] === false) {
        let total = 0
        res.payload['auto'].map(item => {
          total += item.saldoCorte
        })
        setTotal(total)
        setResultados(res.payload['auto'])
        setToast(res.payload['message'], ToastTypes.Success)
      } else {
        if (res !== null && res !== undefined && res.payload['error'] === true) {
          setToast(res.payload['message'], ToastTypes.Danger)
        } else {
          setToast(res.payload['message'], ToastTypes.Danger)
        }
      }
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
  }, [ciudad, consolidado, corte, filtros, radioOption, saldoMayorCero, setToast, dispatch])

  const handleButtonClick = React.useCallback((button: ButtonTypes) => {
    switch (button) {
      case ButtonTypes.find:
        onFind()
        break
      case ButtonTypes.broom:
        onBroom()
        break
      case ButtonTypes.print:
        if (resultados.length > 0) {
          onButtonPrint('Viewer')
        } else {
          setToast(lh.getMessage(MessagesKeys.GlobalPrintSearch), ToastTypes.Warning)
        }
        break
      case ButtonTypes.print_design:
        if (resultados.length > 0) {
          onButtonPrint('Designer')
        } else {
          setToast(lh.getMessage(MessagesKeys.GlobalPrintSearch), ToastTypes.Warning)
        }
        break
      default:
        break
    }
    dispatch(clearButtonClick(tabId))
  }, [dispatch, tabId, onFind, onButtonPrint, resultados, setToast, onBroom])

  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
  }, [handleButtonClick, tab.globalButtonClick])

  React.useEffect(() => {
    dispatch(setChangeFilter({
      ...filtros,
      proveedor: filterGlobal.proveedor,
      local: filterGlobal.local,
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterGlobal])

  React.useEffect(() => {
    if (cartera.status === FETCH_STATUS.FAILED) {
      dispatch(
        addToast({
          title: 'Cartera',
          content: cartera.mensaje ?? 'Error al realizar la búsqueda',
          type: ToastTypes.Danger,
        }),
      )
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'CARTERA',
          buttonType: undefined,
        }),
      )
    }
    if (cartera.status === FETCH_STATUS.LOADING) {
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'CARTERA',
          buttonType: ButtonTypes.find,
        }),
      )
    }
    if (cartera.status === FETCH_STATUS.SUCCESS) {
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'CARTERA',
          buttonType: undefined,
        }),
      )
    }
  }, [dispatch, cartera.status, cartera.mensaje])

  const onParseData = React.useCallback((cartera: Array<any> = []) => {
    let registros: Array<any> = [];
    if (cartera.length > 0) {
      registros = cartera.map((x) => {
        return {
          cedula: x?.proveedorIdentificacion ?? '',
          nombre: x?.proveedorNombre ?? '',
          fecha: x?.fecha ?? '',
          fechaActual: DateUtils.getCurrentDateAsString(),
          doc: x?.ticComprobante ?? 0,
          numero: x?.numero ?? 0,
          credito: x?.credito ?? 0,
          cobros: x?.pagos ?? 0,
          nc: x?.notasCredito ?? 0,
          ret: x?.retencion ?? 0,
          saldo: x?.saldo ?? 0,
        }
      });
    }
    return registros;
  }, []);

  return (
    <>
      <ReportWithLocalDataModal
        show={reporte !== null}
        onClose={() => setReporte(null)}
        data={onParseData(resultados)}
        fileName='StaticCarteraPendientes'
        mode={reporte ?? 'Viewer'}
        parameters={{ 'Reporte_Filtro': getFilterString(filtros), Modulo: "Proveedores" }}
        template='StaticCarteraPendientes'
        key='reportDesigner'
      />
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={position}
        visible={cartera.status === FETCH_STATUS.LOADING}
        showIndicator={true}
        shading={true}
        showPane={true}
        closeOnOutsideClick={false}
      />
      <CCard id='walletContent'>
        <CCardHeader
          onClick={() => setCollapsed(!collapsed)}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <strong> {'Lista de Cartera por Cobrar'} </strong>
          <CLink className="card-header-action">
            <CIcon icon={collapsed ? cilChevronBottom : cilChevronTop} />
          </CLink>
        </CCardHeader>
        <CCardBody>
          <CCollapse visible={collapsed}>
            <CabeceraFiltroBusqueda />
            <br />
            <RowContainer>
              <CustomCol xs="12" md="4">
                <RadioGroup
                  name="tiposNacionalidad"
                  layout="horizontal"
                  displayExpr="label"
                  dataSource={gruposTipos}
                  onValueChanged={(data) => onChangedData(data, 'radioOption')}
                  value={radioOption}
                />
              </CustomCol>
              <CustomCol xs="12" md="8">
                {lh.getMessage(MessagesKeys.GlobalCarteraInfo)}
              </CustomCol>
            </RowContainer>
            <RowContainer>
              <CustomCol xs="12" md="3">
                <Labeled label="Ciudad:">
                  <LocalidadesLookUp
                    onChanged={(data) => onChangedData(data, 'ciudad')}
                    onChangedOptions={() => { }}
                    selected={ciudad}
                    allowEdit
                    allowClear
                  />
                </Labeled>
              </CustomCol>
              <CustomCol xs="12" md="3">
                <Labeled label="Corte:">
                  <DateBox
                    value={corte}
                    onValueChanged={(data) => onChangedData(data, 'corte')}
                  // onEnterKey={onEnterKey}
                  />
                </Labeled>
              </CustomCol>
              <CustomCol xs="12" md="2" className="d-flex align-items-center">
                <CheckBox
                  text="Consolidado "
                  value={consolidado}
                  onValueChanged={(data) => onChangedData(data, 'consolidado')}
                />
              </CustomCol>
              <CustomCol xs="12" md="2" className="d-flex align-items-center">
                <CheckBox
                  text="Saldo Mayor a Cero "
                  value={saldoMayorCero}
                  onValueChanged={(data) => onChangedData(data, 'saldoMayorCero')}
                />
              </CustomCol>
            </RowContainer>
          </CCollapse>
          <CustomCol xs="12" md="12">
            <CCol className="d-flex justify-content-end">{resultados.length}</CCol>
            <DataGrid
              selection={{ mode: 'single' }}
              hoverStateEnabled={true}
              dataSource={resultados}
              showBorders={true}
              showRowLines={true}
            >
              <FilterRow visible={true} />
              <HeaderFilter visible={true} />
              <Paging defaultPageSize={10} />
              <Pager
                displayMode={displayMode}
                showPageSizeSelector={showPageSizeSelector}
                showInfo={showInfo}
                showNavigationButtons={showNavButtons}
              />
              <Column
                dataType="string"
                dataField="proveedorIdentificacion"
                caption="Ced/Ruc"
                width="10%"
              />
              <Column
                dataType="string"
                dataField="proveedorNombre"
                caption="Proveedor"
                width="20%"
              />
              <Column
                dataField="ciudad"
                caption="Ciudad"
                width="5%"
              />
              <Column
                dataType="string"
                dataField="ticComprobante"
                caption="Comprobante"
                width="5%"
              />
              <Column
                dataType="string"
                dataField="numero"
                caption="Numero"
                width="5%"
              />
              <Column dataField="est" caption="Est" width="5%" />
              <Column
                dataType="string"
                dataField="pto"
                caption="Pto"
                width="5%"
              />
              <Column
                dataType="string"
                dataField="num"
                caption="Num"
                width="5%"
              />
              <Column dataField="anio" caption="Año" width="5%" />
              <Column
                dataType="string"
                dataField="observaciones"
                caption="Obs"
                width="5%"
              />
              <Column dataField="fecha" caption="Fecha" width="5%" />
              <Column
                dataType="number"
                dataField="total"
                caption="Total Comprobante"
                width="5%"
              />
              <Column
                dataType="number"
                dataField="credito"
                caption="Crédito"
                width="5%"
              />
              <Column
                dataType="number"
                dataField="pagos"
                caption="Pagos"
                width="5%"
              />
              <Column dataType="number" dataField="notasCredito" caption="NC" width="5%" />
              <Column dataType="number" dataField="retencion" caption="Retención" width="5%" />
              <Column
                dataType="number"
                dataField="saldoCorte"
                caption="Saldo"
                width="5%"
              />
            </DataGrid>
            <CRow>
              <CCol className="mt-1 d-flex justify-content-end">
                <div className="dx-field">
                  <div className="dx-field-label">{'Total:'}</div>
                  <div className="dx-field-value">
                    <NumberBox
                      width="100%"
                      value={total}
                      placeholder=""
                      format="#,##0.00"
                    />
                  </div>
                </div>
              </CCol>
            </CRow>
          </CustomCol>
        </CCardBody>
      </CCard>
    </>
  )
}

export default React.memo(CorteComprobantes)

export const ModuleButtonsCartera: CustomButtons = {
  Buscar: true,
  Imprimir: true,
  Proveedores: true,
  Limpiar: true,
}
