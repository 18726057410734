import React from 'react'
import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CCol,
  CRow,
} from '@coreui/react-pro'
import {
  DataGrid,
  DateBox,
  NumberBox,
  TextBox,
} from 'devextreme-react'
import { Column, FilterRow, HeaderFilter, Pager, Paging, Selection } from 'devextreme-react/data-grid'

import CustomCol from '../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import CabeceraFiltroBusqueda from '../../shared/cabeceraFiltroBusqueda'
import FormasPagoLookUp from '../../../../../componentes/formasPagoLookUp'
import {
  ETipoTransaccion,
  TFormaPagoOption,
} from '../../../../../componentes/formasPagoLookUp/store/type'
import { useDispatch, useSelector } from 'react-redux'
import {
  changeFilter,
  deleteAnticipos,
  fetchFormasPagoANL,
  getFiltroAnticipos,
  getResultadosAnticipos,
  saveAnticpos,
  setCleanFields,
  setSeleccionarDato,
  fetchAnticpos,
  setCleanResult,
  changeLoaderAnticipos,
} from '../../store/anticiposReducer'
import { setCleanResult as setCleanCabecera } from '../../store/searchReducer'
import { getAllowedPageSizes } from '../../../../../../helpers/Helper'
import { DateUtils } from '../../../../../../helpers/dateUtils'
import { RootState } from '../../../../../../store/store'
import {
  CustomButtons,
  FETCH_STATUS,
  ToastTypes,
} from '../../../../../../store/types'
import { ModalListarAnticipos } from '../../../../../componentes/modalListarAnticipos/modalListarAnticipos'
import { addToast } from '../../../../../../store/toasterReducer'
import {
  AbonosIngresos,
  AccountingEntry,
  AsientosIngresos,
  FiltrosAnticiposState,
  FiltrosDocumentosState,
  Ingreso,
  IngresosCajaDto,
  Pendientes,
  Venta,
} from '../../types/types'
import Importar from '../impportarExcel/importar'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import { clearButtonClick, setCurrentExecutingAction } from '../../store/tabsReducer'
import ModalAsiento from '../../../../../contabilidad/pages/mayorGeneral/componentes/modalAsiento'
import { generateAcoountEntry } from '../../store/abonosReducer'
import VisualizaError from '../../../../../ventas/pages/shared/visualizaError/visualizaError'
import { DocCuentasCobrar } from '../../services/docCuentasCobrar.service'
import { ReportWithLocalDataModal } from '../../../../../../views/componentes/xtrareports/ReportWithLocalDataModal'
import ModalIngresoCheques from '../../../../../componentes/modalIngresoCheques'
import ModalTarjetas from '../../../../../componentes/modalTarjetas'
import ModalAnticipos from '../../../../../shared/components/modales/modalAnticipos'
import VariasFormasPago from '../../../../../ventas/components/ventas/variasFormasPago/variasFormasPago'
import { formasPagoService } from '../../../../../componentes/formasPagoLookUp/service/formasPago.service'
import { getIndexProvider } from '../../../../../shared/helpers/funciones'
import { TabState } from '../../../../../../store/genericTabTypes'
import { consoleService } from '../../../../../../services/console.service'
import BlockUi from '../../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../../views/componentes/loadingindicator/loadingindicator'
import { EFormasPago } from '../../../../../../store/enum/formasPago'


interface IAnticiposProps {
  tabId: string
  tab: TabState<any>
}
const Anticipos: React.FunctionComponent<IAnticiposProps> = (props) => {
  const { tabId, tab } = props
  const dispatch = useDispatch()

  const resultadosAnticipos = useSelector(getResultadosAnticipos)

  const filtroAnticipos = useSelector(getFiltroAnticipos)
  const filtroGlobales = useSelector((state: RootState) => state.clientes.docCuentasXCobrar.search.filter)
  const seleccionado = useSelector((state: RootState) => state.clientes.docCuentasXCobrar.anticipos.seleccionado)
  const estadoBusqueda = useSelector((state: RootState) => state.clientes.docCuentasXCobrar.anticipos.status)
  const empresa = useSelector((state: RootState) => { return state.global.session?.empresa });
  const modulo = useSelector((state: RootState) => state.clientes.docCuentasXCobrar.configuraciones.modulo)
  const peridoActual = useSelector(
    (state: RootState) => state.shared.providers.periodoContable,
  )
  const anticiposLoader = useSelector((state: RootState) => {
    return state.clientes.docCuentasXCobrar.anticipos.loader
  })
  const usuarioGlobal = useSelector((state: RootState) => state.global.usuario)

  const [displayMode,] = React.useState<string | 'full' | 'compact'>('full');
  const [showPageSizeSelector,] = React.useState<boolean>(true);
  const [showInfo,] = React.useState<boolean>(true);
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [showModalsiento, setShowModalsiento] = React.useState<boolean>(false)
  const [showNavButtons,] = React.useState<boolean>(true);
  const [filtroGlobal, setfiltroGlobal] = React.useState<FiltrosDocumentosState>(filtroGlobales)
  const [showImportButton, setShowImportButton] = React.useState<boolean>(false)
  const [showErrorPopup, setShowErrorPopup] = React.useState<boolean>(false)
  const [arrayErrors, setArrayErrors] = React.useState<Array<string>>([])

  const [resultados, setResultados] = React.useState<Array<any>>([])


  const [fechaAnticipo, setFechaAnticipo] = React.useState(new Date())
  const [numero, setNumero] = React.useState<number>(null)
  const [codigo, setCodigo] = React.useState<number>(null)
  const [concepto, setConcepto] = React.useState<string>(null)
  const [valor, setValor] = React.useState<string>(null)
  const [documento, setDocumento] = React.useState<string>(null)
  const [formaPago, setFormaPago] = React.useState<TFormaPagoOption>(null)
  const [resultadoImprimir, setResultadoImprimir] = React.useState<any>({})
  const [showCreditCard, setShowCreditCard] = React.useState<boolean>(false)
  const [showAdvances, setShowAdvances] = React.useState<boolean>(false)
  const [showBankChecks, setShowBankChecks] = React.useState<boolean>(false)
  const [showOthersPayMethod, setShowOthersMethod] = React.useState<boolean>(false)
  const [clean, setClean] = React.useState<boolean>(false)

  const [reporte, setReporte] = React.useState<null | "Viewer" | "Designer">(null)
  const getFilterString = React.useCallback((filter: FiltrosAnticiposState) => {
    const filterText = "";

    return filterText;
  }, [])

  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(addToast({
      content: mensaje,
      title: 'Anticipos',
      type: tipo
    }));
  }, [dispatch])
  const setCurrentExecutionAction = React.useCallback((type: ButtonTypes | undefined) => {
    dispatch(setCurrentExecutingAction({
      tabKey: tabId,
      buttonType: type
    }))
  }, [dispatch, tabId])

  const setLoader = React.useCallback((mostrar: boolean, mensaje: string) => {
    dispatch(changeLoaderAnticipos({
      show: mostrar,
      mensaje: mensaje
    }))
  }, [dispatch])

  const onEnterKey = React.useCallback((data, component: string) => {
    dispatch(setCleanResult())
    const toAny: any = fetchAnticpos(filtroAnticipos)
    dispatch(toAny)
  }, [dispatch, filtroAnticipos])

  const onChangeFecha = React.useCallback((e) => {
    consoleService.log(e)
    if (e !== null && e !== undefined) {
      setFechaAnticipo(e)
      const fechaNueva = DateUtils.dateToString(e, "dd/MM/yyyy")
      dispatch(changeFilter({
        ...filtroAnticipos,
        fecha: fechaNueva
      }))
    } else {
      setFechaAnticipo(new Date())
      const fechaTemp = DateUtils.dateToString(fechaAnticipo, "dd/MM/yyyy")
      dispatch(changeFilter({
        ...filtroAnticipos,
        fecha: fechaTemp
      }))
      setToast("Borro fecha hasta seleccionada", ToastTypes.Danger)
    }
  }, [dispatch, filtroAnticipos, fechaAnticipo, setToast])


  const onChangeNumero = React.useCallback((data) => {
    consoleService.log(data)
    if (data !== null || data !== undefined) {
      setNumero(data)
      dispatch(changeFilter({
        ...filtroAnticipos,
        numero: data,
      }))
    }
  }, [dispatch, filtroAnticipos])

  const onChangeCodigo = React.useCallback((data) => {
    consoleService.log(data)
    if (data !== null || data !== undefined) {
      setCodigo(data)
      dispatch(changeFilter({
        ...filtroAnticipos,
        codigo: data,
      }))
    }
  }, [dispatch, filtroAnticipos])

  const onChangeConcepto = React.useCallback((data) => {
    consoleService.log(data)
    if (data !== null || data !== undefined) {
      setConcepto(data)
      dispatch(changeFilter({
        ...filtroAnticipos,
        concepto: data,
      }))
    }
  }, [dispatch, filtroAnticipos])

  const onChangeValor = React.useCallback((data) => {
    consoleService.log(data)
    if (data !== null || data !== undefined) {
      setValor(data)
      dispatch(changeFilter({
        ...filtroAnticipos,
        valor: data,
      }))
    }
  }, [dispatch, filtroAnticipos])

  const onChangeDocumento = React.useCallback((data) => {
    consoleService.log(data)
    if (data !== null || data !== undefined) {
      setDocumento(data)
      dispatch(changeFilter({
        ...filtroAnticipos,
        documento: data,
      }))
    }
  }, [dispatch, filtroAnticipos])

  const onChangeFormaPago = React.useCallback((data) => {
    consoleService.log(data)
    if (data !== null && data !== undefined) {
      setFormaPago(data)
      dispatch(changeFilter({
        ...filtroAnticipos,
        formasPago: data,
      }),)
    }
  }, [dispatch, filtroAnticipos])

  // eslint-disable-next-line react-hooks/exhaustive-deps


  const getAnticipo = React.useCallback((): number => {
    let total = 0;
    if (resultados.length > 0) {
      resultados.map((item) => {
        total = total + item.valor
      })
    }
    return total
  }, [resultados])


  const deleteItemTabla = React.useCallback(async () => {
    if (seleccionado.row !== null && seleccionado.row !== undefined) {
      if (resultados !== null && resultados !== undefined && resultados.length > 0) {
        const provider = resultados.slice(0);
        const index = await getIndexProvider(provider, 'codigo', seleccionado.row.codigo)
        if (index > -1) {
          provider.splice(index, 1)
          setResultados(provider)
        }
      }
    }
  }, [resultados, seleccionado.row])


  const getDvengado = React.useCallback((): number => {
    let total = 0;
    if (resultados.length > 0) {
      resultados.map((item) => {
        total = total + item.devengado
      })
    }
    return total
  }, [resultados])


  const getSaldo = React.useCallback((): number => {
    let total = 0;
    if (resultados.length > 0) {
      resultados.map((item) => {
        total = total + item.saldo
      })
    }
    return total
  }, [resultados])

  const isValidateForm = React.useCallback(() => {
    let errorsPaymensMethods: boolean = false
    const errors: Array<string> = [
      'Antes de guardar la información revise lo siguiente:',
    ]
    if (numero === null || numero === undefined || numero === 0) {
      errors.push('Numero: este campo esta vacío o es incorrecto.')
    }
    if (concepto === null || concepto === undefined || concepto === "") {
      errors.push('Concepto: este campo esta vacío o es incorrecto.')
    }

    if (valor === null || valor === undefined || valor === "" || isNaN(parseInt(valor))) {
      errors.push('Valor: este campo esta vacío o tiene un valor incorrecto.')
    }

    if (documento === null || documento === undefined || documento === "") {
      errors.push('Documento: este campo esta vacío.')
    }

    if (formaPago === null || formaPago === undefined || formaPago.codigo === -1) {
      errors.push('Forma de Pago: seleccione una Forma de Pago')
    } else if (formaPago === null || formaPago === undefined || formaPago.codigo === 8) {
      errors.push('Forma de pago: no puede realizar el cobro con Crédito')
    } else if (formaPago === null || formaPago === undefined || formaPago.codigo === EFormasPago.otros) {
      errorsPaymensMethods = true
      errors.push(
        'Forma de pago: establezca los valores de las distintas formas de pago',
      )
    }

    if (errorsPaymensMethods) setShowOthersMethod(true)

    return errors
  }, [numero, valor, concepto, documento, formaPago])

  const resultToast = React.useCallback((res) => {
    if (res !== null && res.payload['error'] === false) {
      setToast(res.payload['message'], ToastTypes.Success)
    } else {
      consoleService.log(res)
      setToast(res.payload['message'], ToastTypes.Danger)
    }
  }, [setToast])


  const getAnticiposFNL = React.useCallback(async (codigoAsociado: number) => {
    const toAny: any = fetchFormasPagoANL(codigoAsociado)
    const res = await dispatch(toAny)
    consoleService.log(res)
  }, [dispatch])

  const onBroomAnticipos = React.useCallback(() => {
    setClean(true)
    dispatch(setCleanResult())
    dispatch(setCleanFields())
    dispatch(setCleanCabecera())
    setFechaAnticipo(new Date())
    setNumero(null)
    setConcepto(null)
    setValor(null)
    setDocumento(null)
    setFormaPago(null)
  }, [dispatch])

  const onBroom = React.useCallback(() => {
    dispatch(setCleanResult())
  }, [dispatch])

  const onGetFields = React.useCallback(async () => {
    if (seleccionado.row !== null && seleccionado.row !== undefined && seleccionado.row.origen !== "NOTA DE CREDITO VENTA") {
      const data = seleccionado.row
      const fecha = DateUtils.strDateToDate(data.registro, 'dd/MM/yyyy')

      dispatch(changeFilter({
        ...filtroAnticipos,
        numero: data.numeroTipoAsiento,
        concepto: data.concepto,
        documento: data.documento,
        valor: data.valor.toString(),
        codigoAnticipo: data.codigo,
        codigoAsociado: data.codigoAso,
      }),)

      setFechaAnticipo(fecha)
      setNumero(data.numeroTipoAsiento)
      setConcepto(data.concepto)
      setValor(data.valor.toString())
      setDocumento(data.documento)
      if (!isNaN(data.codigoAso)) {
        getAnticiposFNL(data.codigoAso)
      }
    }
  }, [seleccionado.row, getAnticiposFNL, dispatch, filtroAnticipos])

  const onDelete = React.useCallback(async () => {
    if (seleccionado.row !== null && seleccionado.row !== undefined) {
      try {
        setLoader(true, 'Eliminando...')
        const toAny: any = deleteAnticipos(seleccionado)
        const res = await dispatch(toAny)
        setLoader(false, '')
        if (res !== null && res.payload['error'] === false) {
          deleteItemTabla()
        }
        resultToast(res)
      } catch (error) {
        setLoader(false, '')
        consoleService.log(error)
        setToast(error.message, ToastTypes.Danger)
      }
    } else {
      setToast('Seleccione un anticipo para eliminarlo', ToastTypes.Success)
    }
  }, [seleccionado, resultToast, setToast, dispatch, deleteItemTabla, setLoader])

  const onNewAnticipos = React.useCallback(() => {
    setClean(true)
    setFechaAnticipo(new Date())
    setNumero(null)
    setCodigo(null)
    setConcepto('')
    setValor(null)
    setDocumento('')
    setFormaPago(null)
  }, [])



  const onFindAnticipos = React.useCallback(async () => {
    try {
      dispatch(setCleanResult())
      setLoader(true, 'Cargando...')
      const toAny: any = fetchAnticpos(filtroAnticipos)
      const res = await dispatch(toAny)
      setLoader(false, '')
      resultToast(res)
    } catch (error) {
      setLoader(false, '')
      setToast(error.message, ToastTypes.Danger)
    }
  }, [filtroAnticipos, dispatch, resultToast, setLoader, setToast])

  const generateAE = React.useCallback(async (data, pagos) => {
    try {
      const accountingEntry: AccountingEntry = {
        infoRegistro: {
          movimiento: 'ANTICIPO CLIENTE',
        },
        fecha: data?.ANT_REGISTRO ?? filtroAnticipos.fecha,
        descripcion: `Abono registrado por: ${data.ANT_ASOCIADO_DES}`,
        numeroDocumento: data.ASO_CODIGO, // ASO_CODIGO
        codigo: data.ANT_CODIGO, // ASO_CODIGO
        diarioNumero: peridoActual.length > 0 ? peridoActual[0].diario : 0, //GET THE NUMBER OF ACCOUNT PERIOD
        localCodigo: filtroAnticipos.local !== null ? filtroAnticipos.local.codigo : -1,
        formaPago: pagos,
      }
      setLoader(true, 'Generando Asiento...')
      const toAny: any = generateAcoountEntry(accountingEntry)
      const res = await dispatch(toAny)
      setLoader(false, '')
      consoleService.log(res)
      resultToast(res)
    } catch (error) {
      setLoader(false, '')
      consoleService.log(error)
      setToast(error.message, ToastTypes.Danger)
    }
  }, [dispatch, filtroAnticipos, peridoActual, resultToast, setToast, setLoader])

  const generateEntry = React.useCallback(async (data, tipoPagonant: Array<any>) => {
    consoleService.log(data)
    await onFindAnticipos()
    let generar = false
    const pagos: Array<{ codigo: number; valor: number }> = []
    tipoPagonant.map(pago => {
      pagos.push({
        codigo: pago.codigoSub,
        valor: pago.valorFormaPago
      })

      if (
        pago.codigoSub === 7 ||
        pago.codigoSub === 9 ||
        pago.codigoSub === 22 ||
        pago.codigoSub === 30 ||
        pago.codigoSub === 114
      ) {
        generar = true
      }
    })

    if (!generar) {
      generateAE(data, pagos)
    }


  }, [onFindAnticipos, generateAE])



  const onEnterChecksCredtiCardAdvances = React.useCallback(
    (paymentsMethods) => {
      let openModalChekcs = false
      let openModalCreditCards = false
      let openModalAdvances = false
      paymentsMethods.map((payment) => {
        if (
          payment.codigoSub === 7 ||
          payment.codigoSub === 114 ||
          payment.codigoSub === 9
        )
          openModalChekcs = true
        else if (payment.codigoSub === 22) openModalCreditCards = true
        else if (payment.codigoSub === 30) openModalAdvances = true
      })
      if (openModalChekcs || openModalCreditCards || openModalAdvances)
        if (openModalChekcs) setShowBankChecks(true)
        else if (openModalCreditCards) setShowCreditCard(true)
        else if (openModalAdvances) setShowAdvances(true)
    },
    [],
  )


  const onSaveAnticipos = React.useCallback(async () => {
    let resultadoPagoAnt: any = []
    const precision = empresa?.precision ?? 2

    try {
      const errors = isValidateForm()
      if (errors.length === 1) {
        if (filtroAnticipos.formasPago.codigo !== EFormasPago.otros) {
          resultadoPagoAnt = []
        }
        if (filtroAnticipos.formasPago.codigo !== EFormasPago.otros && parseInt(parseInt(valor).toFixed(precision)) > 0) {
          resultadoPagoAnt.push({ codigoSub: filtroAnticipos.formasPago.codigo, valorFormaPago: valor })
        }

        const obj_request = {
          filtro: filtroAnticipos,
          array: resultadoPagoAnt
        }
        setLoader(true, 'Guardar...')
        const toAny: any = saveAnticpos(obj_request)
        const res = await dispatch(toAny)
        setLoader(false, '')
        if (res !== null && res.payload['error'] !== true) {
          onEnterChecksCredtiCardAdvances(resultadoPagoAnt)
          await generateEntry(res.payload['auto'], resultadoPagoAnt)
          resultToast(res)
        } else {
          setLoader(false, '')
          resultToast(res)
        }
      } else {
        setArrayErrors(errors)
        setShowErrorPopup(true)
      }
    } catch (error) {
      setLoader(false, '')
      consoleService.log(error)
      setToast(error.message, ToastTypes.Danger)
    }

  }, [filtroAnticipos, isValidateForm, dispatch, empresa?.precision, valor, resultToast, generateEntry, onEnterChecksCredtiCardAdvances, setLoader, setToast])

  const onChangeFilter = React.useCallback((accion: string) => {
    if (accion === "Buscar") {
      onFindAnticipos()
    } else if (accion === "Guardar") {
      onSaveAnticipos()
    }
  }, [onFindAnticipos, onSaveAnticipos])

  const onSelectionChanged = React.useCallback((selectionChanged) => {
    consoleService.log("onSelectionChanged", selectionChanged['selectedRowsData'][0])
    dispatch(setSeleccionarDato(
      {
        index: [selectionChanged['currentSelectedRowKeys'][0]],
        row: selectionChanged['selectedRowsData'][0],
        selectedRow: [selectionChanged['currentSelectedRowKeys'][0]]
      }
    ))
  }, [dispatch]);

  const onDBSelectedRow = React.useCallback(({ data }) => {
    consoleService.log(data)
    if (data !== null && data !== undefined) {
      const fecha = DateUtils.strDateToDate(data.registro, 'dd/MM/yyyy')

      dispatch(changeFilter({
        ...filtroAnticipos,
        numero: data.numeroTipoAsiento,
        concepto: data.concepto,
        documento: data.documento,
        valor: data.valor.toString(),
        codigoAnticipo: data.codigo,
        codigoAsociado: data.codigoAso,
      }),)

      setFechaAnticipo(fecha)
      setNumero(data.numeroTipoAsiento)
      setConcepto(data.concepto)
      setValor(data.valor.toString())
      setDocumento(data.documento)
      if (!isNaN(data.codigoAso)) {
        getAnticiposFNL(data.codigoAso)
      }
    }
  }, [dispatch, filtroAnticipos, getAnticiposFNL])

  const getApiData = React.useCallback(async (codigo: number, accion: string) => {
    try {
      setLoader(true, 'Cargando...')
      const data = await DocCuentasCobrar.getDataPrint('ANTICIPOS', codigo)
      setLoader(false, '')
      if (data !== null && data !== undefined && data['auto'] !== null) {
        setResultadoImprimir(data['auto'])
        if (accion === 'Imprimir') {
          setReporte('Viewer')
        } else {
          setReporte('Designer')
        }
      } else {
        if (data !== null && data !== undefined && data['message']) {
          setToast(data['message'], ToastTypes.Danger)
        } else {
          consoleService.log(data)
          setToast('Hubo un error', ToastTypes.Danger)
        }
        setResultadoImprimir({})
      }
    } catch (error) {
      consoleService.log(error)
      setLoader(false, '')
      setResultadoImprimir({})
      setToast('Error: Hubo un error en la peticion', ToastTypes.Danger)
    }
  }, [setToast, setLoader])

  const modalAnticiposComponent = () => {
    consoleService.log(seleccionado.row)
    return (
      <ModalListarAnticipos
        onChanged={(data) => consoleService.log("onChange", data)}
        onCancel={() => setShowModal(false)}
        show={showModal}
        codigoAnticipo={seleccionado.row.codigo}
      />
    )
  }

  const handleButtonClick = React.useCallback((button: ButtonTypes) => {
    switch (button) {
      case ButtonTypes.find:
        onChangeFilter(ButtonTypes.find)
        break
      case ButtonTypes.save:
        onChangeFilter(ButtonTypes.save)
        break
      case ButtonTypes.new:
        onNewAnticipos()
        break
      case ButtonTypes.broom:
        onBroom()
        break
      case ButtonTypes.import:
        setShowImportButton(true)
        break
      case ButtonTypes.delete:
        onDelete()
        break
      case ButtonTypes.edit:
        onGetFields()
        break
      case ButtonTypes.undo:
        if (seleccionado.row !== null && seleccionado.row !== undefined) {
          onGetFields()
        } else {
          onNewAnticipos()
        }
        break
      case ButtonTypes.print:
        if (seleccionado.row !== null && seleccionado.row !== undefined) {
          getApiData(seleccionado.row.codigo, 'Imprimir')
        } else {
          setToast("Seleccione un anticipo para poder imprimirlo.", ToastTypes.Danger)
        }
        break
      case ButtonTypes.print_design:
        if (seleccionado.row !== null && seleccionado.row !== undefined) {
          getApiData(seleccionado.row.codigo, 'Diseñar')
        } else {
          setToast("Seleccione un anticipo para poder diseñarlo.", ToastTypes.Danger)
        }
        break
      case ButtonTypes.verAsiento:
        if (seleccionado.row !== null && seleccionado.row !== undefined) {
          setShowModalsiento(true)
        } else {
          setToast("Seleccione un anticipo para ver el asiento", ToastTypes.Danger)
        }
        break
      case ButtonTypes.checks_pf:
        if (seleccionado.row !== null && seleccionado.row !== undefined) {
          if (filtroAnticipos.formasPago !== null && filtroAnticipos.formasPago !== undefined && filtroAnticipos.formasPago.codigo === 9) {
            setShowBankChecks(true)
          } else {
            setToast("Seleccione Forma de Pago: Cheque Post-Fechado", ToastTypes.Warning)
          }
        } else {
          setToast("Seleccione un anticipo", ToastTypes.Warning)
        }
        break
      case ButtonTypes.transfers:
        if (seleccionado.row !== null && seleccionado.row !== undefined) {
          if (filtroAnticipos.formasPago !== null && filtroAnticipos.formasPago !== undefined && filtroAnticipos.formasPago.codigo === 114) {
            setShowBankChecks(true)
          } else {
            setToast("Seleccione Forma de Pago: Transferencias Bancarias", ToastTypes.Warning)
          }
        } else {
          setToast("Seleccione un anticipo", ToastTypes.Warning)
        }
        break
      case ButtonTypes.check:
        if (seleccionado.row !== null && seleccionado.row !== undefined) {
          if (filtroAnticipos.formasPago !== null && filtroAnticipos.formasPago !== undefined && filtroAnticipos.formasPago.codigo === 7) {
            setShowBankChecks(true)
          } else {
            setToast("Seleccione Forma de Pago: Cheque", ToastTypes.Warning)
          }
        } else {
          setToast("Seleccione un anticipo", ToastTypes.Warning)
        }
        break
      default:
        consoleService.log(button)
        break
    }
    dispatch(clearButtonClick(tabId))
  }, [dispatch, onNewAnticipos, tabId, onChangeFilter, onDelete, onGetFields, onBroom, seleccionado.row, getApiData, filtroAnticipos.formasPago, setToast])

  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    } else {
      consoleService.log(tab.globalButtonClick)
    }
  }, [handleButtonClick, tab.globalButtonClick])

  const generaDetalleErrores = React.useCallback((item: any) => {
    if (item.length > 0) {
      return <CAlert color='danger'>
        <p>{item[0]}</p>
        <ul className="errorList">
          {item.map(function (item, id) {
            if (id === 0) return null
            return <li key={id}>{item}</li>
          })}
        </ul></CAlert>
    } else {
      return <></>
    }
  }, []);

  const getResultados = React.useCallback(() => {
    let newArray = []
    if (resultadosAnticipos['auto'] && resultadosAnticipos['auto'].length > 0) {
      newArray = resultadosAnticipos['auto']
    }
    setResultados(newArray)
  }, [resultadosAnticipos])

  const calcularTotalLote = React.useCallback(() => {
    consoleService.log('XXXXX')
  }, [])

  const setLote_lb = React.useCallback((data) => {
    consoleService.log('CC', data)
  }, [])

  const onSetValuePaymentsMethods = React.useCallback((paymentMetohds) => {
    const othersPaymentsMethods: Array<TFormaPagoOption> = []
    for (const payment of paymentMetohds) {
      const pay: TFormaPagoOption = {
        codigo: payment.codigoFormaPago,
        descripcion: payment.descripcionFormaPago,
        asignadoSri: payment.asignado,
        grupo: payment.valor.toString(),
      }
      othersPaymentsMethods.push(pay)
    }
    dispatch(changeFilter({
      ...filtroAnticipos,
      formasPago: othersPaymentsMethods[0],
    }),)
    setShowOthersMethod(false)
  }, [dispatch, filtroAnticipos])

  React.useEffect(() => {
    dispatch(changeFilter({
      ...filtroAnticipos,
      local: filtroGlobal.lugar,
      cliente: filtroGlobal.cliente
    }),)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtroGlobal])

  React.useEffect(() => {
    onBroomAnticipos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  React.useEffect(() => {
    getResultados()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultadosAnticipos])

  const getParseData = React.useCallback((anticipo: any = {}) => {
    consoleService.log(anticipo)
    const { resultado } = anticipo
    const registros: Array<IngresosCajaDto> = []
    const abonos: Array<AbonosIngresos> = null
    let asientos: Array<AsientosIngresos> = []
    const pendientes: Array<Pendientes> = null
    let ingreso: Ingreso = null
    let venta: Venta = null

    if (resultado !== null && resultado !== undefined && resultado.length > 0) {
      asientos = resultado.map((x) => {
        const debe = x?.traDebe ?? 0
        const haber = x?.traHaber ?? 0

        return {
          codigo: x?.traCodigo ?? 0,
          PlanCtaNumero: x?.plaNumero ?? "",
          PlanCtaDescripcion: x?.plaDescripcion ?? "",
          TransaccionReferencia: x?.traReferencia ?? "",
          TransaccionDebe: debe !== "" ? parseInt(debe) : 0,
          TransaccionHaber: haber !== "" ? parseInt(haber) : 0,
        }
      })
    }
    const fechaIng = anticipo?.fecha !== "" ? DateUtils.strDateToDate(anticipo?.fecha, "dd/MM/yyyy") : new Date()

    ingreso = {
      Valor: parseInt(anticipo?.valor ?? '0'),
      Fecha: fechaIng,
      Concepto: anticipo?.concepto ?? '',
      AsientoNumeroTipo: anticipo?.numeroIngreso ?? '',
      LocalNombre: anticipo?.local ?? '',
    }

    venta = {
      Establecimiento: anticipo?.establecimiento ?? '',
      ClienteNombre: anticipo?.asociadoDes ?? "",
      ClienteIdentificacion: anticipo?.asociadoId ?? "",
      Direccion: "",
      Telefono: "",
      Email: "",
      FormaPago: "",
      Pendiente: 0,
    }

    const debe = !isNaN(anticipo?.totalDebe ?? 0) ? parseInt(anticipo?.totalDebe) : 0
    const haber = !isNaN(anticipo?.totalHaber ?? 0) ? parseInt(anticipo?.totalHaber) : 0

    const anticipoImp: IngresosCajaDto = {
      Elaborado: usuarioGlobal?.idsesion ?? "",
      IngresoTipo: 'ANTICIPO CLIENTE',
      TotalDebe: debe,
      TotalHaber: haber,
      Ingreso: ingreso,
      Venta: venta,
      Abonos: abonos,
      Asientos: asientos,
      Pendientes: pendientes,
    }

    registros.push(anticipoImp)
    consoleService.log(registros)
    return registros
  }, [usuarioGlobal])

  const getFormaPago = React.useCallback(async () => {
    const codigoTipoPago = seleccionado.row.formaPago
    const transaccion = ETipoTransaccion.ventas
    const data = await formasPagoService.getFormasPago(transaccion, "Elija una opción");
    if (data !== null && data !== undefined && data['auto'] && data['auto'].length > 0) {
      const fp = data['auto'].find(p => p.codigo == codigoTipoPago)
      setFormaPago(fp)
    }
  }, [seleccionado])

  React.useEffect(() => {
    if (seleccionado.row !== null && seleccionado.row !== undefined) {
      getFormaPago()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seleccionado])

  const modalAsiento = (

    <ModalAsiento
      show={showModalsiento}
      onClose={() => setShowModalsiento(false)}
      infoAsiento={seleccionado}
      origenModulo={Number(modulo)}
    />

  )

  React.useEffect(() => {
    if (estadoBusqueda === FETCH_STATUS.FAILED) {
      dispatch(addToast({
        title: 'Buscar Anticipos',
        content: 'Error al realizar la búsqueda',
        type: ToastTypes.Danger
      }))
      dispatch(setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: undefined
      }))
    }
    if (estadoBusqueda === FETCH_STATUS.LOADING) {
      dispatch(setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: ButtonTypes.find
      }))
    }
    if (estadoBusqueda === FETCH_STATUS.SUCCESS) {
      dispatch(setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: undefined
      }))
    }
  }, [dispatch, estadoBusqueda, tabId])


  return (
    <>
      <ReportWithLocalDataModal
        show={reporte !== null}
        onClose={() => setReporte(null)}
        data={getParseData(resultadoImprimir)}
        fileName='StaticIngresosCaja'
        mode={reporte ?? 'Viewer'}
        parameters={{ 'Reporte_Filtro': getFilterString(filtroAnticipos), Modulo: "Clientes" }}
        template='StaticIngresosCaja'
        key='reportDesigner'
      />
      {showErrorPopup && (<VisualizaError titulo='Error en Anticipos' mensaje={'Error al guardar'} content={generaDetalleErrores(arrayErrors)} onOk={() => {
        setShowErrorPopup(false)
        // dispatch(setMuestraError({ key: props.tabId, tieneError: false, mensajeError: '' }))
      }} />)}
      {showModalsiento && modalAsiento}
      {showOthersPayMethod && (
        <VariasFormasPago
          numeroVenta={0}
          tiposPagos={[]}
          fechaFactura={filtroAnticipos.fecha}
          totalCobrar={0}
          onCancel={() => setShowOthersMethod(false)}
          onOk={onSetValuePaymentsMethods}
        />
      )}
      {showBankChecks && (
        <ModalIngresoCheques
          show={showBankChecks}
          configModal={{
            open: showBankChecks,
            name: 'Ingresar Cheque Anticipo Venta',
            parametros: {
              incomeCode: seleccionado.row.codigoAso, // anticipo.asociadoCodigo
              incomeDate: seleccionado.row.registro,
            },
            codModulo: Number(modulo),
            tab: 'ANTICIPOS',
            tipoFP: filtroAnticipos.formasPago.codigo,
          }}
          onClose={async () => {
            setShowBankChecks(false)
            const tiposPago: Array<any> = []
            tiposPago.push({ codigo: filtroAnticipos.formasPago.codigo, valor: seleccionado.row?.valor })
            const data: any = {
              ANT_ASOCIADO_DES: seleccionado.row?.asociadoDes ?? "",
              ANT_REGISTRO: seleccionado.row?.registro ?? DateUtils.getCurrentDateAsString,
              ASO_CODIGO: seleccionado.row?.codigoAso ?? 0,
              ANT_CODIGO: filtroAnticipos.formasPago.codigo ?? 0,
            }
            await generateAE(data, tiposPago)
          }}
          parametros={(evt) => consoleService.log(evt)}
          pathVerificador={''}
          ejecutaAccion={() => consoleService.log('ejecutaAccion')}
          onSelect={(evt) => consoleService.log(evt)}
        />
      )}
      {showAdvances && (
        <ModalAnticipos
          show={showAdvances}
          onClose={() => setShowAdvances(false)}
          pathVerificador={''}
          modulo={Number(modulo)}
        />
      )}
      {showCreditCard && (
        <ModalTarjetas
          show={showCreditCard}
          onClose={() => setShowCreditCard(false)}
          parametros={() => { }}
          pathVerificador={''}
          ejecutaAccion={calcularTotalLote}
          setTotales={(data) => setLote_lb(data)}
          onSelected={(data) => {
            consoleService.log('tarjetas onSelected', data)
          }}
        />
      )}
      <BlockUi
        tag="div"
        loader={LoadingIndicator}
        blocking={anticiposLoader.show}
        message={anticiposLoader.mensaje}
      >
        <CCard id='anticiposContent'>
          {showModal === true && modalAnticiposComponent()}
          <CCardBody>
            <CabeceraFiltroBusqueda
              filtroBusqueda={{
                empresa: null,
                cliente: null,
                puntoVenta: null,
                proyecto: null,
                lugar: null,
                nombre: ''
              }}
              clean={clean}
              setClean={setClean}
              seleccionado={seleccionado}
              getCabeceraFiltro={(data) => setfiltroGlobal(data)} />

            <RowContainer>
              <CustomCol xs="12" md="3">
                <Labeled label="Fecha:">
                  <DateBox
                    value={fechaAnticipo}
                    onValueChange={onChangeFecha}
                    onEnterKey={(evt) => onEnterKey(evt, 'Fecha')}
                  />
                </Labeled>
              </CustomCol>
            </RowContainer>
            <RowContainer>
              <CustomCol xs="12" md={showImportButton ? "7" : "12"}>
                <RowContainer>
                  <CustomCol xs="12" md="4">
                    <Labeled label="Número:">
                      <NumberBox
                        value={numero}
                        onValueChange={onChangeNumero}
                        onEnterKey={(evt) => onEnterKey(evt, 'Numero')}
                        showClearButton
                      />
                    </Labeled>
                  </CustomCol>
                  <CustomCol xs="12" md="4">
                    <Labeled label="Código:">
                      <NumberBox
                        value={codigo}
                        onValueChange={onChangeCodigo}
                        onEnterKey={(evt) => onEnterKey(evt, 'Codigo')}
                        showClearButton
                      />
                    </Labeled>
                  </CustomCol>
                  <CustomCol xs="12" md="4">
                    <Labeled label="Concepto:">
                      <TextBox
                        value={concepto}
                        onValueChange={onChangeConcepto}
                        onEnterKey={(evt) => onEnterKey(evt, 'Concepto')}
                        showClearButton
                      />
                    </Labeled>
                  </CustomCol>
                </RowContainer>
                <RowContainer>
                  <CustomCol xs="12" md="4">
                    <Labeled label="Valor:">
                      <TextBox
                        value={valor} // valor
                        onValueChange={onChangeValor}
                        onEnterKey={(evt) => onEnterKey(evt, 'Valor')}
                        showClearButton
                      />
                    </Labeled>
                  </CustomCol>
                  <CustomCol xs="12" md="4">
                    <Labeled label="Documento:">
                      <TextBox
                        value={documento}
                        onValueChange={onChangeDocumento}
                        onEnterKey={(evt) => onEnterKey(evt, 'Documento')}
                        showClearButton
                      />
                    </Labeled>
                  </CustomCol>
                  <CustomCol xs="12" md="4">
                    <Labeled label="Formas de Pago:">
                      <FormasPagoLookUp
                        onChanged={(data) => {
                          if (data !== null || data !== undefined) {
                            consoleService.log("data ", data)
                            onChangeFormaPago(data)
                          }
                        }}
                        onChangedOptions={(data) => {
                          consoleService.log("data ", data)
                        }}
                        selected={formaPago}
                        transaccion={ETipoTransaccion.ventas}
                      />
                    </Labeled>
                  </CustomCol>
                </RowContainer>
              </CustomCol>
              {
                showImportButton && (
                  <CustomCol xs="12" md="5">
                    <Importar
                      tab={tab}
                      tabId={tabId}
                      openImport={(open) => setShowImportButton(open)}
                    />
                  </CustomCol>
                )
              }
            </RowContainer>
            <CustomCol xs="12" md="12">
              <CCol className="d-flex justify-content-end">{resultados.length > 0 ? resultados.length : 0}</CCol>
              <DataGrid
                selection={{ mode: 'single' }}
                hoverStateEnabled={true}
                dataSource={resultados}
                showBorders={true}
                showRowLines={true}
                onSelectionChanged={onSelectionChanged}
                onRowDblClick={onDBSelectedRow}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                keyExpr="codigo"
                key="gridAnticipos"
              >
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                <Selection
                  mode="single"
                  selectAllMode={"onClick"}
                  showCheckBoxesMode={[]}
                />

                <Paging defaultPageSize={10} />
                <Pager
                  visible={resultados.length > 0 ? true : false}
                  allowedPageSizes={getAllowedPageSizes(resultados)}
                  displayMode={displayMode}
                  showPageSizeSelector={showPageSizeSelector}
                  showInfo={showInfo}
                  showNavigationButtons={showNavButtons} />
                <Column
                  dataType="string"
                  dataField="registro"
                  caption="Fecha"
                  width="7%"
                />
                <Column
                  dataType="string"
                  dataField="numeroTipoAsiento"
                  caption="Diario"
                  width="5%"
                />
                <Column dataField="hostPto" caption="POS" width="9%" />
                <Column
                  dataType="string"
                  dataField="nombreLocal"
                  caption="Local/CC"
                  width="9%"
                />
                <Column
                  dataType="string"
                  dataField="asociadoId"
                  caption="Ced/Ruc"
                  width="9%"
                />
                <Column
                  dataField="asociadoDes"
                  caption="Cliente"
                  width="15%"
                />
                <Column
                  dataType="string"
                  dataField="concepto"
                  caption="Concepto"
                  width="9%"
                />
                <Column
                  dataType="string"
                  dataField="documento"
                  caption="Documento"
                  width="9%"
                />
                <Column dataField="valor" caption="Valor" width="9%" />
                <Column
                  dataType="string"
                  dataField="devengado"
                  caption="Deverngado"
                  width="9%"
                />
                <Column
                  dataType="string"
                  dataField="saldo"
                  caption="Saldo"
                  width="9%"
                />
              </DataGrid>
            </CustomCol>
            <RowContainer>
              <CustomCol xs="12" md="2">
                <CButton
                  id="btnConfirmar"
                  color="secondary"
                  disabled={seleccionado.row === null || seleccionado.row === undefined}
                  className="m-1"
                  size="sm"
                  onClick={() => {
                    setShowModal(true)
                    consoleService.log(showModal)
                  }}
                >
                  {'Ver Detalle'}
                </CButton>
              </CustomCol>
              <CustomCol
                xs="12"
                md="10"
                className="d-flex justify-content-end align-items-center"
              >
                <CRow>
                  <CCol md="6" className="d-flex justify-content-end">
                    <strong> {'Totales:'} </strong>
                  </CCol>
                  <CCol md="2">
                    <NumberBox
                      readOnly
                      width="100%"
                      value={getAnticipo()}
                      placeholder=""
                      format="#,##0.00"
                    />
                  </CCol>
                  <CCol md="2">
                    <NumberBox
                      readOnly
                      width="100%"
                      value={getDvengado()}
                      placeholder=""
                      format="#,##0.00"
                    />
                  </CCol>
                  <CCol md="2">
                    <NumberBox
                      readOnly
                      width="100%"
                      value={getSaldo()}
                      placeholder=""
                      format="#,##0.00"
                    />
                  </CCol>
                </CRow>
              </CustomCol>
            </RowContainer>
          </CCardBody>
        </CCard>
      </BlockUi>
    </>
  )
}

export default React.memo(Anticipos)

export const ModuleButtonsAnticipos: CustomButtons = {
  Nuevo: true,
  Guardar: true,
  Buscar: true,
  Deshacer: true,
  Eliminar: true,
  Editar: true,
  Imprimir: true,
  Importar: true,
  Limpiar: true,
  Ver_asiento: true,
  Cheque: true,
  Cheques_pf: true,
  Transferencias: true,
}
