import { RequestHelper } from "../../../../../helpers/requestHelper"
import { SaveRegisterData } from "../types/types"




export const RegisterServices = { deleteRegister, saveRegister }

async function deleteRegister(codigo: number): Promise<any> {
  try {
    const obj = {
      registro: codigo
    }
    const apiData = await RequestHelper.deleteRequestAll<any>('nomina/registro/marcacion', 'registro/delete', '', obj)
    return apiData
  } catch (error) {
    return error
  }
}


async function saveRegister(data: SaveRegisterData): Promise<any> {
  try {
    const obj = {
      ...data
    }
    const apiData = await RequestHelper.postAll<any>('nomina/registro/marcacion', 'registro/register', obj)
    return apiData
  } catch (error) {
    return error
  }
}