import { TabsState } from "../../../../../../store/genericTabTypes"
import { CustomDictionary, FETCH_STATUS, LoaderInfo } from "../../../../../../store/types"
import { PeriodoActivo } from "../../../../../bancos/general/types"
import { TFormaPagoOption } from "../../../../../componentes/formasPagoLookUp/store/type"
import { MenuState } from "../../../../../ventas/types/types"
import { RolParamsList, TransferList } from "../../rolesPago/types/types"



export enum DecimoTipo {
  tercero = 13,
  cuarto = 14,
}


export type TenthList = {
  codigo: number
  fecha: string
  tipo: number
  formaPago: string
  valor: number
  usuCodigo: number
  fechaCobro: string
  perDiario: number
  perAnio: number
  fPagoDes: string
  identificacion: string
  nombre: string
  egrCodigo: number
}

export type FiltroBudquedaDecios = {
  identificacion: string
  nombre: string
  periodo: PeriodoActivo | null
  estado: { label: string, value: number }
  fechaCobro: string
  formaPago: TFormaPagoOption | null
  listaFormasPagos: Array<TFormaPagoOption>
  initial: boolean
}

export type BusquedaState<TenthList> = {
  filter: FiltroBudquedaDecios
  filterBackup: FiltroBudquedaDecios
  colapsado: boolean
  colapsadoDetalle: boolean
  rolparametros: RolParamsList | null
  import: boolean
  searchDecimT: boolean
  searchDecimC: boolean
  seleccionado: TenthList
  loader: LoaderInfo
  nroRegistrosEncontradosT: number
  nroRegistrosEncontradosF: number
  status: FETCH_STATUS
  resultadosTercero: Array<TenthList>
  resultadosCuarto: Array<TenthList>
  total: number
}

export type DecimosDatosEdicion = {
  codigo: number
  empleado: string
  empleadoCodigo: number
  periodo: number
  fechaEmision: string
  decimo: number
  fechaCobro: string
  formaPago: TFormaPagoOption | null
  loader: LoaderInfo
}

export type configTenth = {
  appThree: number
  appFour: number
  currentApp: number
  editData?: CustomDictionary<DecimosDatosEdicion>
}

export type FiltroBusqudaTransfer = {
  cuentaNombre: string
  cuentaCodigo: number
  anio: number
  initial: boolean
}

export type BusquedaTransferState = {
  filter: FiltroBusqudaTransfer
  filterBackup: FiltroBusqudaTransfer
  colapsado: boolean
  import: boolean
  searchRols: boolean
  seleccionado: TransferList
  loader: LoaderInfo
  nroRegistrosEncontrados: number
  resultados: Array<TransferList>
}

export type CalculateList = {
  fCobro: string
  fecha: string
  fPago: string
  tipo: string
  valor: number
  anio: number
  diario: number
  usuarioCodigo: number
  usuarioNombre: string
}

export type Calculate = {
  periodo: number
  fechaEmision: string
  initial: boolean
}

export type Todos = {
  calculate: Calculate
  loader: LoaderInfo
  nroRegistrosEncontrados: number
  resultados: Array<CalculateList>
}

export type SaveList = {
  decimos: Array<TenthList>
  fpago: number
}

export type CalculateTenth = {
  diario: number
  anio: number
  fecha: string
  tipo: number
}

export type SaveAllData = {
  decimos: Array<CalculateList>
}

export type ModuleTenthState = {
  menu: MenuState
  search: BusquedaState<TenthList>
  config: configTenth
  editData: CustomDictionary<DecimosDatosEdicion>
  transfer: BusquedaTransferState
  todos: Todos
  tabs: TabsState<DecimosDatosEdicion>
}