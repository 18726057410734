import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FiltrosUnidadessState, BusquedaState } from '../types/types'
import { FETCH_STATUS, Seleccionado } from '../../../../../../store/types'
import { UnidadInventario } from '../../../../store/types'
import { InventarioService } from '../../../../services/inventario.service';


const initialState: BusquedaState<UnidadInventario> = {
  filter: {
    nombre: '',
    estado: null,
    initital: true,
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  seleccionado: {
    index: null,
    row: null,
    selectedRow: null,
  },
  resultados: [],
  colapsado: true,
  nroItemsEncontrados: 0,
}

export const fetchUnidades = createAsyncThunk<
  Array<UnidadInventario>,
  any
>('inventarios/catalogos/unidades/listar', async (filtro) => {
  try {
    const unitsList = await InventarioService.getUnidades(filtro)
    return unitsList
  } catch (error) {
    return Promise.reject(error)
  }
})

const searchSlice = createSlice({
  name: 'buscarUnidades',
  initialState: initialState,
  reducers: {
    setCleanResult(state) {
      state.resultados = []
    },
    setResultados(state, action: PayloadAction<Array<UnidadInventario>>) {
      state.resultados = action.payload
    },
    setCollapsed(state, action: PayloadAction<boolean>) {
      state.colapsado = action.payload
    },
    changeFilter(state, action: PayloadAction<FiltrosUnidadessState>) {
      state.filter.nombre = action.payload.nombre
      state.filter.estado = action.payload.estado
      state.filter.initital = action.payload.initital
    },
    setResetSeleccion(state) {
      const toAdd = {
        index: null,
        row: null,
        selectedRow: null,
      }
      state.seleccionado = toAdd
    },
    setSeleccionarDato(state, action: PayloadAction<Seleccionado>) {
      const toAdd = {
        index: action.payload.index,
        row: action.payload.row,
        selectedRow: action.payload.selectedRow,
      }
      state.seleccionado = toAdd
    },
    setNroUnidadesEncontradas(state, action: PayloadAction<number>) {
      state.nroItemsEncontrados = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUnidades.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchUnidades.fulfilled, (state, { payload }) => {
      state.resultados = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchUnidades.rejected, (state, { payload }) => {
      state.resultados = []
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
  },
})

export const {
  changeFilter,
  setCleanResult,
  setResetSeleccion,
  setSeleccionarDato,
  setResultados,
  setCollapsed,
  setNroUnidadesEncontradas,
} = searchSlice.actions

export const searchReducer = searchSlice.reducer
