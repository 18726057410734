import React from 'react';
import { Lookup, DropDownOptions, } from 'devextreme-react/lookup';
import { localidadesService } from '../../../../services/localidades.service';
import { Ciudad } from '../../../ventas/store/types';
interface IBuscarCiudadLookUpProps extends React.PropsWithChildren {
  value: Ciudad | null,
  onChanged: (newValue: Ciudad | null) => void
}

export function BuscarCiudadLookUp(props: IBuscarCiudadLookUpProps) {
  const { onChanged, value } = props;

  const datasource = React.useMemo(() => {
    console.log("getCiudadesDatasource");
    return localidadesService.getCiudadesDatasource();
  }, []);

  const onValueChanged = React.useCallback(({ value }: any) => {
    onChanged(value);
  }, [onChanged])

  const getCiudadDisplayExpr = React.useCallback((ciudad: Ciudad) => {
    return ciudad ? `${ciudad.nombre}` : '';
  }, []);

  return (
    <Lookup
      dataSource={datasource}
      value={value}
      minSearchLength={1}
      searchTimeout={500}
      displayExpr={getCiudadDisplayExpr}
      searchExpr={['nombre']}
      pageLoadMode='scrollBottom'
      showClearButton={true}
      onValueChanged={onValueChanged}
      elementAttr={{
        id: "lookupCiudades"
      }}
    >
      <DropDownOptions
        tabIndex={3000}
        closeOnOutsideClick={true}
        showTitle={false}
      />
    </Lookup>
  );
}
