import { combineReducers } from "redux";
import { MarcationState } from "../types/types";
import { menuReducer } from "./menuReducer";
import { searchReducer } from "./searchReducer";
import { dataEditReducer } from "./editDataReducer";
import { configuracionesMarcacion } from "./configReducer";
import { tabsReducer } from "./tabsReducer";



export const MarcacionReducer = combineReducers<MarcationState>({
  menu: menuReducer,
  search: searchReducer,
  tabs: tabsReducer,
  editData: dataEditReducer,
  config: configuracionesMarcacion
})