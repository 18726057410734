import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import { DataGrid } from 'devextreme-react'
import { Column, Export, Pager, Paging, Button as DatagridButton, ColumnChooser } from 'devextreme-react/data-grid'
import { allowedPageSizes } from '../../../../../../../helpers/Helper'
import ItemsCountIndicator from '../../../../../../componentes/indicadorNroRegistros'
import { setCollapsed, setSeleccionarDato } from '../../store/serachReducer'
import Dialog from '../../../../../../../views/componentes/librerias/bootstrap-dialog'
import { Workbook } from 'exceljs'
import { exportDataGrid } from 'devextreme/excel_exporter';
import { saveAs } from 'file-saver';
import { consoleService } from '../../../../../../../services/console.service'
import { GrupoInventario } from '../../../../../store/types'
import { ActionSheetCommandsMobile } from './ActionSheetCommandsMobile'
import { isMobile } from 'react-device-detect'
import DataSource from 'devextreme/data/data_source'

interface IResultsProps extends React.PropsWithChildren {
  onDBClick: () => void
  handleOptionChange: (e: any) => void
  data: DataSource
  pageIndex: number
  pageSize: number
  onDelete: () => void
  onSinc: () => void
}

const Lista: React.FunctionComponent<IResultsProps> = (props) => {
  const {
    onDBClick,
    data,
    handleOptionChange,
    pageIndex,
    pageSize,
    onDelete,
    onSinc,
  } = props

  const dispatch = useDispatch()
  const nroItemsFound = useSelector(
    (state: RootState) =>
      state.inventarios.catalogos.grupos.search.nroItemsEncontrados,
  )
  const tablaRefPager = React.useRef<any>()
  const [isPagin] = React.useState<boolean>(true)
  const dialogRef = React.useRef<any>(null)

  const onCollapsed = React.useCallback(() => {
    if (nroItemsFound > 5) {
      dispatch(setCollapsed(false))
    }
  }, [dispatch, nroItemsFound])

  const onSelectedCahnge = React.useCallback(
    ({ selectedRowsData }) => {
      consoleService.log(selectedRowsData[0])
      dispatch(
        setSeleccionarDato({
          index: selectedRowsData[0]?.codigo ?? 0,
          row: selectedRowsData[0],
          selectedRow: [selectedRowsData[0]],
        }),
      )
    },
    [dispatch],
  )

  const onExportExcell = React.useCallback((e) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('Main sheet')
    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'Lineas_Inventario.xlsx',
        )
      })
    })
  }, [])


  const actionSheetCommandsMobile = React.useCallback(
    (cellData) => {
      const data = cellData.data as GrupoInventario
      return (
        <ActionSheetCommandsMobile
          grupo={data}
          key={data.codigo}
          onDelete={(data) => {
            dispatch(
              setSeleccionarDato({
                index: data?.codigo ?? 0,
                row: data,
                selectedRow: data,
              }),
            )
            onDelete()
          }}
          onEdit={(data) => {
            dispatch(
              setSeleccionarDato({
                index: data?.codigo ?? 0,
                row: data,
                selectedRow: data,
              }),
            )
            onDBClick()
          }}
          onSinc={(data) => {
            dispatch(
              setSeleccionarDato({
                index: data?.codigo ?? 0,
                row: data,
                selectedRow: data,
              }),
            )
            onSinc()
          }}
        />
      )
    },
    [dispatch, onDelete, onDBClick, onSinc],
  )


  React.useEffect(() => {
    onCollapsed()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nroItemsFound])

  return (
    <>
      <Dialog ref={dialogRef} />
      <ItemsCountIndicator numberOfItems={data ? nroItemsFound : 0} />
      <DataGrid
        id="tablaSeguimientoRegistros"
        ref={tablaRefPager}
        dataSource={data ?? []}
        selection={{ mode: 'single' }}
        hoverStateEnabled={true}
        showBorders={true}
        showRowLines={true}
        focusedRowEnabled={true}
        onSelectionChanged={onSelectedCahnge}
        onRowDblClick={onDBClick}
        repaintChangesOnly={true}
        highlightChanges={true}
        keyExpr="uuid"
        key="uuid"
        focusedRowKey="uuid"
        showColumnLines={true}
        onExporting={(e) => onExportExcell(e)}
        className={'m-2 p-2'}
        onOptionChanged={(e) => handleOptionChange(e)}
        remoteOperations={true}
        loadPanel={{
          enabled: true,
          height: 90,
          indicatorSrc: '',
          shading: false,
          shadingColor: '',
          showIndicator: true,
          showPane: true,
          text: 'Cargando...',
          width: 200,
        }}
      >
        <Paging pageIndex={pageIndex} pageSize={pageSize} enabled={isPagin} />
        <Pager
          ref={tablaRefPager}
          showPageSizeSelector={true}
          allowedPageSizes={allowedPageSizes}
          showNavigationButtons={true}
          showInfo={true}
          infoText="Page #{0}. Total: {1} ({2} items)"
        />
        <Export enabled={true} allowExportSelectedData={true} />
        <ColumnChooser enabled={true} mode="select" />
        {!isMobile && (
          <Column type="buttons" width="100px">
            <DatagridButton
              icon="trash"
              hint="Eliminar"
              onClick={async (e) => {
                consoleService.log('e', e)
                const data: GrupoInventario = e.row.data
                dispatch(
                  setSeleccionarDato({
                    index: data?.codigo ?? 0,
                    row: data,
                    selectedRow: data,
                  }),
                )
                onDelete()
              }}
            />
            <DatagridButton
              icon="edit"
              hint="Editar"
              onClick={async (e) => {
                consoleService.log('e', e)
                const data: GrupoInventario = e.row.data
                dispatch(
                  setSeleccionarDato({
                    index: data?.codigo ?? 0,
                    row: data,
                    selectedRow: data,
                  }),
                )
                onDBClick()
              }}
            />
            <DatagridButton
              icon="globe"
              hint="Sincronizar Woo"
              onClick={async (e) => {
                consoleService.log('e', e)
                const data: GrupoInventario = e.row.data
                dispatch(
                  setSeleccionarDato({
                    index: data?.codigo ?? 0,
                    row: data,
                    selectedRow: data,
                  }),
                )
                onSinc()
              }}
            />
          </Column>
        )}
        {isMobile && (
          <Column
            caption="Tareas"
            dataField="codigo"
            cellRender={actionSheetCommandsMobile}
            width="50px"
            allowFiltering={false}
          ></Column>
        )}
        <Column dataField="descripcion" caption="Descripción" width="25%" />
        <Column dataField="estadoNombre" caption="Estado" width="25%" />
        <Column dataField="items" caption="Items" width="25%" />
        <Column dataField="categoriaWeb" caption="Sinc" width="25%" />
      </DataGrid>
    </>
  )
}

export default React.memo(Lista)
