import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ConfiguracionesNotaDebito } from '../types/types'
import { AutorizacionDocumentoVenta } from '../../../types/types'

const initialState: ConfiguracionesNotaDebito = {
 autorizacionDocumento: null,
}

const configuracionesNotaDebitoSlice = createSlice({
 name: 'configuracionesNDebito',
 initialState: initialState,
 reducers: {
  initialize(state, acion: PayloadAction<AutorizacionDocumentoVenta>) {
   state.autorizacionDocumento = acion.payload
  },
  setAutorizacionModuloNDebito(
   state,
   acion: PayloadAction<AutorizacionDocumentoVenta>,
  ) {
   state.autorizacionDocumento = acion.payload
  },
 },
})

export const configuracionesReducer = configuracionesNotaDebitoSlice.reducer
export const { setAutorizacionModuloNDebito } =
 configuracionesNotaDebitoSlice.actions
