import React from 'react'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import { getAllowedPageSizes } from '../../../../../../../helpers/Helper'
import ItemsCountIndicator from '../../../../../../componentes/indicadorNroRegistros'
import { setSeleccionarDato, setVerRegistro } from '../../store/serachReducer'
import { Workbook } from 'exceljs'
import { exportDataGrid } from 'devextreme/excel_exporter';
import { saveAs } from 'file-saver';
import { ETittleTab, MovimientoTipo } from '../../types/types'
import Dialog from '../../../../../../../views/componentes/librerias/bootstrap-dialog'
import { isMobile } from 'react-device-detect';
import { consoleService } from '../../../../../../../services/console.service'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import DataGrid, {
  Column, Paging, Pager, Button as DatagridButton, Export, ColumnChooser, MasterDetail
} from 'devextreme-react/data-grid';
import { ActionSheetCommandsMobile } from './ActionSheetCommandsMobile'
import DetalleIngresoEgreso from './detalleIngresoEgreso'

interface ICabeceraProps extends React.PropsWithChildren {
  onDelete: () => void
  onDBEdit: () => void
  onSee: (data: MovimientoTipo) => void
}

const Cabecera = (props: ICabeceraProps) => {
  const { onDelete, onDBEdit, onSee } = props;
  const dispatch = useDispatch()
  const resultsSerach = useSelector((state: RootState) => state.inventarios.movimientos.ingresosEgresos.search.resultados)
  const currentTab = useSelector((state: RootState) => state.inventarios.movimientos.ingresosEgresos.search.currentTab)
  const [resultados, setResultados] = React.useState([])
  const dataGrid = React.useRef<any>()
  const dialogRef = React.useRef<any>(null)


  const onGetData = React.useCallback(() => {
    let newArray = []
    if (currentTab === ETittleTab.cab) {
      if (resultsSerach !== null && resultsSerach !== undefined && resultsSerach['error'] === false) {
        newArray = resultsSerach['auto'].map(item => {
          return item
        })
        setResultados(newArray)
      } else {
        setResultados([])
      }
    } else {
      setResultados([])
    }
  }, [resultsSerach, currentTab])

  const onSelectedIngEgChanged = React.useCallback(({ selectedRowsData }) => {
    consoleService.log('selected ', selectedRowsData[0])
    dispatch(setSeleccionarDato({ ...selectedRowsData[0] }))
  }, [dispatch])

  const onDBClickEdit = React.useCallback(() => {
    onDBEdit()
  }, [onDBEdit])

  React.useEffect(() => {
    onGetData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultsSerach])

  const onExportExcell = React.useCallback((e) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('Main sheet')
    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'IngreososEgresos_Inventario.xlsx')
      });
    });
  }, [])

  const actionSheetCommandsMobile = React.useCallback(
    (cellData) => {
      const data = cellData.data as any
      return (
        <ActionSheetCommandsMobile
          ingresoEgreso={data}
          key={data.codigo}
          onDelete={(data) => {
            dispatch(setSeleccionarDato({ ...data }))
            onDelete()
          }}
          onEdit={(data) => {
            dispatch(setSeleccionarDato({ ...data }))
            onDBEdit()
          }}
          onVer={(data) => {
            dispatch(setSeleccionarDato({ ...data }))
            dispatch(setVerRegistro(true))
            onSee(data)
          }}
        />
      )
    },
    [onDelete, dispatch, onDBEdit, onSee],
  )

  return (
    <>
      <Dialog ref={dialogRef} />
      <RowContainer className="mt-2">
        <CustomCol xs="12" md="12">
          <ItemsCountIndicator items={resultados} />
          <DataGrid
            ref={dataGrid}
            keyExpr="codigo"
            focusedRowKey="codigo"
            id="tabCertificados"
            selection={{ mode: 'single' }}
            dataSource={resultados}
            showColumnLines={true}
            hoverStateEnabled={true}
            showRowLines={true}
            showBorders={true}
            columnResizingMode="widget"
            focusedRowEnabled={true}
            onSelectionChanged={onSelectedIngEgChanged}
            onRowDblClick={onDBClickEdit}
            onExporting={onExportExcell}
            loadPanel={{
              enabled: true,
              height: 90,
              indicatorSrc: '',
              shading: false,
              shadingColor: '',
              showIndicator: true,
              showPane: true,
              text: 'Cargando...',
              width: 200,
            }}
          >
            <Paging defaultPageSize={10} />
            <Pager
              visible={resultados.length > 0 ? true : false}
              allowedPageSizes={getAllowedPageSizes(resultados)}
              displayMode={'full'}
              showPageSizeSelector={true}
              showInfo={true}
              showNavigationButtons={true}
            />
            <ColumnChooser enabled={true} mode="select" />
            <Export enabled={true} allowExportSelectedData={true} />
            {
              !isMobile && (
                <Column type="buttons" width="100px">
                  <DatagridButton
                    icon="trash"
                    hint="Eliminar"
                    onClick={async (e) => {
                      consoleService.log('e', e)
                      const data: MovimientoTipo = e.row.data
                      dispatch(setSeleccionarDato({ ...data }))
                      onDelete()
                    }}
                  />
                  <DatagridButton
                    icon="file"
                    hint="Ver"
                    onClick={async (e) => {
                      consoleService.log('e', e)
                      const data: MovimientoTipo = e.row.data
                      dispatch(setSeleccionarDato({ ...data }))
                      dispatch(setVerRegistro(true))
                      onSee(data)
                    }}
                  />
                  <DatagridButton
                    icon="edit"
                    hint="Editar"
                    onClick={async (e) => {
                      consoleService.log('e', e)
                      const data: MovimientoTipo = e.row.data
                      dispatch(setSeleccionarDato({ ...data }))
                      onDBEdit()
                    }}
                  />
                </Column>
              )
            }
            {!isMobile && (
              <MasterDetail
                enabled={true}
                render={(params) => (
                  <>
                    <DetalleIngresoEgreso {...params} />{' '}
                  </>
                )}
              />
            )}
            {isMobile && (
              <Column
                caption="Tareas"
                dataField="codigo"
                cellRender={actionSheetCommandsMobile}
                width="50px"
                allowFiltering={false}
              ></Column>
            )}
            <Column dataField="codigo" caption="Cod. Movimiento" width="100px" />
            <Column dataField="documento" caption="Documento" width="150px" />
            <Column dataField="movimientoDescripcion" caption="Movimiento" width="150px" />
            <Column dataField="productoClienteDescripcion" caption="Nombre" width="150px" />
            <Column dataField="localCodigoDescripcion" caption="Alamcén" width="100px" />
            <Column dataField="fecha" caption="Fecha" width="150px" />
            <Column dataField="numeroCompra" caption="Compra Asociado" width="120px" />
          </DataGrid>
        </CustomCol>
      </RowContainer>
    </>
  )
}

export default React.memo(Cabecera)
