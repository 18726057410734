import React from 'react'
import RowContainer from '../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../views/componentes/colContainer'
import Labeled from '../../../../../views/componentes/labeledInput/labeledInput'
import { CheckBox, DataGrid, TextBox } from 'devextreme-react'
// import ItemsCountIndicator from '../../../../../../componentes/indicadorNroRegistros'
import ItemsCountIndicator from '../../../../componentes/indicadorNroRegistros'
import { CCol } from '@coreui/react-pro'
import { Column, Pager, Paging } from 'devextreme-react/data-grid'
import { getAllowedPageSizes } from '../../../../../helpers/Helper'
import { etapasProduccionService } from '../../../etapasProduccionLookUp/service/etapaProduccion.service'
import { useDispatch } from 'react-redux'
import { addToast } from '../../../../../store/toasterReducer'
import { ToastTypes } from '../../../../../store/types'

interface IBuscarProps extends React.PropsWithChildren {
  currentTab: string
  currentFunction: string
  setFunction: (data: string) => void
  setShowLoader: (data: boolean) => void
  setSelected: (data: any) => void
  setConfirmEdit: () => void
}

export const Buscar: React.FunctionComponent<IBuscarProps> = (props) => {

  const { currentTab, currentFunction, setFunction, setShowLoader, setSelected, setConfirmEdit } = props

  const etapasProdDataGrid = React.useRef<any>()
  const dispatch = useDispatch()

  const [descripcion, setDescripcion] = React.useState<string>(null)
  const [activo, setActivo] = React.useState<boolean>(true)

  const [resultados, setResultados] = React.useState([])


  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(
      addToast({
        title: 'Etapas Producción',
        content: mensaje,
        type: tipo,
      }),
    )
  }, [dispatch])
  const handleSearch = React.useCallback(() => {
    console.log('buscar')
  }, [])

  const onValueChanged = React.useCallback((data, key: string) => {
    if (data.value !== null && data.value !== undefined) {
      switch (key) {
        case 'descripcion':
          setDescripcion(data.value)
          break;
        case 'activo':
          setActivo(data.value)
          break;

        default:
          break;
      }
    }
  }, [])

  const onSearch = React.useCallback(async () => {
    try {
      setResultados([])
      const search_data = {
        descripcion: descripcion !== null ? descripcion : '',
        activo: activo ? 1 : 0
      }
      setShowLoader(true)
      const res = await etapasProduccionService.getAllEtapas(search_data)
      setShowLoader(false)
      if (res !== null && res !== undefined && res['error'] === false) {
        setResultados(res['auto'])
        setToast(res['message'], ToastTypes.Success)
      } else {
        setToast(res['message'], ToastTypes.Danger)
      }
    } catch (error) {
      setShowLoader(false)
      setToast(error.message, ToastTypes.Danger)
      console.log(error)
    }
  }, [setShowLoader, descripcion, activo, setToast])

  const onSelectedChanged = React.useCallback(({ selectedRowsData }) => {
    console.log('selected', selectedRowsData[0])
    setSelected(selectedRowsData[0])
  }, [setSelected])

  React.useEffect(() => {
    if (currentTab === "Buscar") {
      if (currentFunction === "Buscar") {
        onSearch()
      }
    }
    setFunction('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFunction])

  return (
    <>
      <RowContainer>
        <CustomCol xs="12" md="3">
          <Labeled label="Descripción:">
            <TextBox
              value={descripcion}
              onValueChanged={(data) => onValueChanged(data, 'descripcion')}
              onEnterKey={handleSearch}
            />
          </Labeled>
        </CustomCol>
        <CCol xs="12" md="3" style={{ marginTop: 'auto' }}>
          <CheckBox text="Activos" value={activo} onValueChanged={(data) => onValueChanged(data, 'activo')} />
        </CCol>
      </RowContainer>
      <ItemsCountIndicator items={resultados} />
      <RowContainer>
        <DataGrid
          ref={etapasProdDataGrid}
          id="codigo"
          selection={{ mode: 'single' }}
          dataSource={resultados}
          showColumnLines={true}
          showRowLines={true}
          showBorders={true}
          columnResizingMode="widget"
          onSelectionChanged={onSelectedChanged}
          onRowDblClick={setConfirmEdit}
          loadPanel={{
            enabled: true,
            height: 90,
            indicatorSrc: '',
            shading: false,
            shadingColor: '',
            showIndicator: true,
            showPane: true,
            text: 'Cargando...',
            width: 200,
          }}
        >
          <Paging defaultPageSize={10} />
          <Pager
            visible={resultados.length > 0 ? true : false}
            allowedPageSizes={getAllowedPageSizes(resultados)}
            displayMode={'full'}
            showPageSizeSelector={true}
            showInfo={true}
            showNavigationButtons={true}
          />
          <Column dataField="codigo" caption="Cod." width="15%" />
          <Column dataField="descripcion" caption="Descripción" width="15%" />
          <Column dataField="orden" caption="Orden" width="70%" />
        </DataGrid>
      </RowContainer>
    </>
  )
}
