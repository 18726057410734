import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LoaderInfo } from '../../../../../../../store/types'
import { CustomDictionary } from '../../../../../types/generics'
import {
  CuadresCajaDatosEdicionPayload,
  EgresosListado,
  IngresoCajaDatosEdicion,
} from '../../../types/types'
import { cuadresClearDatosEdicion } from '../../../store/reducers'
import { PuntoVenta } from '../../../../../../componentes/lookUpPuntosVenta/store/type'

const initialState: CustomDictionary<IngresoCajaDatosEdicion> = {}

const datosCashSlice = createSlice({
  name: 'cashEdicion',
  initialState: initialState,
  reducers: {
    updateICFecha(
      state,
      action: PayloadAction<{ key: number | string; fecha: string }>,
    ) {
      state[action.payload.key].fecha = action.payload.fecha
    },

    cuadresClearDatosEdicion,

    setICDatosEdicion(
      state,
      action: PayloadAction<{
        key: number | string
        data: IngresoCajaDatosEdicion
      }>,
    ) {
      state[action.payload.key] = action.payload.data
    },
    initICDatosEdicion(
      state,
      action: PayloadAction<
        CuadresCajaDatosEdicionPayload<IngresoCajaDatosEdicion>
      >,
    ) {
      if (!state[action.payload.key]) {
        state[action.payload.key] = action.payload.data
      }
    },
    setEditLoader(
      state,
      action: PayloadAction<{ key: number | string; info: LoaderInfo }>,
    ) {
      state[action.payload.key].loader = action.payload.info
    },
    setMuestraError(
      state,
      action: PayloadAction<{
        key: number | string
        tieneError: boolean
        mensajeError: string
      }>,
    ) {
      state[action.payload.key].tieneError = action.payload.tieneError
      state[action.payload.key].mensajeError = action.payload.mensajeError
    },
    updateICPuntoVenta(
      state,
      action: PayloadAction<{ key: number | string; puntoventa: PuntoVenta }>,
    ) {
      state[action.payload.key].puntoVenta = action.payload.puntoventa
    },
    updateICPuntosVenta(
      state,
      action: PayloadAction<{
        key: number | string
        puntosVenta: Array<PuntoVenta> | []
      }>,
    ) {
      state[action.payload.key].puntosVenta = action.payload.puntosVenta
    },
    updateICValor(
      state,
      action: PayloadAction<{ key: number | string; valor: number }>,
    ) {
      state[action.payload.key].valor = action.payload.valor
    },
    updateICEgresos(
      state,
      action: PayloadAction<{
        key: number | string
        egresos: Array<EgresosListado> | []
      }>,
    ) {
      state[action.payload.key].egresos = action.payload.egresos
    },

    seleccionarRegistro(
      state,
      action: PayloadAction<{
        tabKey: string
        egreso: EgresosListado | null
      }>,
    ) {
      const { egreso, tabKey } = action.payload
      state[tabKey].seleccionado = egreso
    },
    resetSeleccionIngresoCaja(
      state,
      action: PayloadAction<{
        tabKey: string
      }>,
    ) {
      const { tabKey } = action.payload
      state[tabKey].seleccionado = null
    },
  },
})

export const {
  resetSeleccionIngresoCaja,
  seleccionarRegistro,
  updateICEgresos,
  updateICFecha,
  updateICPuntoVenta,
  updateICPuntosVenta,
  setICDatosEdicion,
  setEditLoader,
  setMuestraError,
  initICDatosEdicion,
  cuadresClearDatosEdicion: clearDatosEdicion,
  updateICValor,
} = datosCashSlice.actions
export const cashReceiptsReducer = datosCashSlice.reducer
