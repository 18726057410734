import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DateUtils } from "../../../../../helpers/dateUtils";
import { FETCH_STATUS, Seleccionado } from "../../../../../store/types";
import { AbonosCuadresCajaListado } from "../../../../ventas/pages/cuadres/types/types";
import { FiltrosAbonosState, VerAbonosState } from "../types/types";
import { RootState } from "../../../../../store/store";
import { VerAbonosService } from "../components/verAbonos/services/verAbonos.service";

const initialState: VerAbonosState<AbonosCuadresCajaListado> = {
  filter: {
    cliente: null,
    formasPago: null,
    puntoVenta: null,
    vendedor: null,
    imprimirTodos: false,
    anulados: false,
    lugar: null,
    comprobante: '',
    cheque: '',
    ingreso: '',
    fechaDesde: DateUtils.dateToString(new Date(), 'dd/MM/yyyy'),
    fechaHasta: DateUtils.dateToString(new Date(), 'dd/MM/yyyy'),
  },
  status: FETCH_STATUS.IDDLE,
  tabs: ['Abonos', 'Auditoria'],
  currentTab: 'Abonos',
  keyTab: 'Abonos',
  deleteItem: false,
  mensaje: '',
  resultadosAbonos: [],
  resultadosAuditoria: [],
  seleccionado: {
    index: null,
    row: null,
    selectedRow: null,
  },
  currentExecute: null,
  currentActiontate: ''
}

export const fetchAbonos = createAsyncThunk<
  Array<AbonosCuadresCajaListado>,
  FiltrosAbonosState
>('generales/seePayments', async (filtro) => {
  try {
    const abonosApi = await VerAbonosService.getAbonos(
      filtro.cliente !== null ? filtro.cliente.codigo : 0,
      filtro.comprobante,
      filtro.fechaDesde,
      filtro.fechaHasta,
      filtro.vendedor !== null ? filtro.vendedor.vendedorCodigo : 0,
      filtro.formasPago !== null ? filtro.formasPago.codigo : 0,
      filtro.puntoVenta !== null ? filtro.puntoVenta.codigo : -1,
      filtro.ingreso,
      filtro.cheque,
      filtro.anulados === true ? 2 : 1,
    )

    return abonosApi
  } catch (error) {
    console.log(error)
    return Promise.reject(error)
  }
})

export const deleteAbonos = createAsyncThunk<
  Array<AbonosCuadresCajaListado>,
  Seleccionado
>('generales/seePayments', async (filtro) => {
  try {
    const abonosApi = await VerAbonosService.deleteAbonos(
      filtro.row !== null ? filtro.row.ingresocodigo : 0,
      filtro.index,
    )

    return abonosApi
  } catch (error) {
    console.log(error)
    return Promise.reject(error)
  }
})

const verAbonosSlice = createSlice({
  name: 'verAbonosDocumentos',
  initialState: initialState,
  reducers: {
    setCleanResultAbonos(state) {
      state.resultadosAbonos = []
    },
    setDeleteItem(state, action: PayloadAction<boolean>) {
      state.deleteItem = action.payload
    },
    changeResultAbonos(state, action: PayloadAction<Array<AbonosCuadresCajaListado>>) {
      state.resultadosAbonos = action.payload
    },
    setCurrentAction(state, action: PayloadAction<string>) {
      state.currentActiontate = action.payload
    },
    setCleanFieldsAbonos(state) {
      state.filter.cliente = null
      state.filter.formasPago = null
      state.filter.puntoVenta = null
      state.filter.vendedor = null
      state.filter.imprimirTodos = false
      state.filter.anulados = false
      state.deleteItem = false
      state.filter.comprobante = ''
      state.filter.cheque = ''
      state.filter.ingreso = ''
    },
    initData(state) {
      state.filter.cliente = null
      state.filter.formasPago = null
      state.filter.puntoVenta = null
      state.filter.vendedor = null
      state.filter.imprimirTodos = false
      state.filter.anulados = false
      state.filter.comprobante = ''
      state.deleteItem = false,
        state.filter.cheque = ''
      state.filter.ingreso = ''
    },
    changeFilter(state, action: PayloadAction<FiltrosAbonosState>) {
      state.filter = action.payload
      state.filter.cliente = action.payload.cliente
      state.filter.formasPago = action.payload.formasPago
      state.filter.puntoVenta = action.payload.puntoVenta
      state.filter.vendedor = action.payload.vendedor
      state.filter.imprimirTodos = action.payload.imprimirTodos
      state.filter.anulados = action.payload.anulados
      state.filter.comprobante = action.payload.comprobante
      state.filter.cheque = action.payload.cheque
      state.filter.ingreso = action.payload.ingreso
      state.filter.fechaDesde = action.payload.fechaDesde
      state.filter.fechaHasta = action.payload.fechaHasta
    },
    changeCurrentTab(state, action: PayloadAction<string>) {
      state.currentTab = action.payload
      state.keyTab = action.payload
    },
    setResetSeleccion(state) {
      const toAdd = {
        index: null,
        row: null,
        selectedRow: null,
      }
      state.seleccionado = toAdd
    },
    setSeleccionarDato(state, action: PayloadAction<Seleccionado>) {
      const toAdd = {
        index: action.payload.index,
        row: action.payload.row,
        selectedRow: action.payload.selectedRow,
      }
      state.seleccionado = toAdd
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAbonos.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchAbonos.fulfilled, (state, { payload }) => {
      state.resultadosAbonos = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchAbonos.rejected, (state, { payload }) => {
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
  },
})


export const selectFiltroVerAbonos = (state: RootState) => {
  return state.clientes.docCuentasXCobrar.verAbonos.filter
}

export const getAbonosCurrentTab = (state: RootState) => {
  return state.clientes.docCuentasXCobrar.verAbonos.currentTab
}


export const { changeFilter, setCleanResultAbonos, initData, changeCurrentTab, setCleanFieldsAbonos, setSeleccionarDato, setCurrentAction, setResetSeleccion, setDeleteItem } =
  verAbonosSlice.actions

export const verAbonosReducer = verAbonosSlice.reducer