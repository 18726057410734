import { utilidades } from "../../../../helpers/utilidades";
import { etapasProduccionService } from "../service/etapaProduccion.service";
import { KeyEstadoProdLook } from "../store/enumKeys";
import { TEtapaProduccionOption } from "../store/type";

export async function getLocales(mensaje: string) {
  const data = await etapasProduccionService.getEtapas(mensaje);
  let opt: Array<TEtapaProduccionOption> = []
  if (data.auto.length > 0) {
    opt = data.auto;
  }
  return opt
}

export function parseLocalOptions(options: Array<any>) {

  let opt: Array<TEtapaProduccionOption> = []
  if (options.length > 0) {
    opt = options.map((x) => {
      const item: TEtapaProduccionOption = {
        codigo: parseInt(x[KeyEstadoProdLook.cod]) ?? 0,
        descripcion: utilidades.capText(x[KeyEstadoProdLook.desc]) ?? '',
        estado: parseInt(x?.[KeyEstadoProdLook.state]) ?? 0,
      }
      return item
    })
  }

  return opt
}