import React, { memo } from 'react'
import TextBox from 'devextreme-react/text-box'
import ResultadoBusqueda from './resultadoBusqueda'
import CustomCol from '../../../../../../../../views/componentes/colContainer'
import { ToastTypes } from '../../../../../../../../store/types'
import RowContainer from '../../../../../../../../views/componentes/rowContainer/rowContainer'
import Labeled from '../../../../../../../../views/componentes/labeledInput/labeledInput'
import { characteristicsServices } from '../../services/modalCaracteristicas.services'
import { Characteristic } from '../../../../types/types'

interface IBuscarProps extends React.PropsWithChildren {
  // eslint-disable-next-line no-unused-vars
  onSelectItem: (item) => void
  // eslint-disable-next-line no-unused-vars
  onSelectedItem: (item) => void
  deletedItem: any
  currentAction: string
  // eslint-disable-next-line no-unused-vars
  setCurrenAction?: (currentAction: string) => void
  clickedButton: string
  resetSelected?: () => void
  resetAction: () => void
  // eslint-disable-next-line no-unused-vars
  setToast: (sms: string, type: ToastTypes) => void
  playLoader: () => void
  stopLoader: () => void
}

const Buscar = (props: IBuscarProps) => {
  const {
    clickedButton,
    setCurrenAction,
    onSelectItem,
    resetAction,
    setToast,
    stopLoader,
    playLoader,
    onSelectedItem,
    deletedItem,
    currentAction,
    resetSelected,
  } = props

  const [characteristics, setCharacteristics] = React.useState<
    Array<Characteristic>
  >([])
  const [name, setName] = React.useState<string>('')

  React.useEffect(() => {
    if (currentAction === 'Buscar') {
      if (clickedButton === 'Buscar') {
        onHandleSearch()
      } else if (clickedButton === 'Eliminar') {
        deleteItemTable(deletedItem)
      }
      resetAction()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickedButton])

  const deleteItemTable = (idCuenta) => {
    const filtrado = characteristics.findIndex((item: any) => {
      return Number(item.codigo) === Number(idCuenta['codigo'])
    })
    const rowsBefore = characteristics.slice()
    if (filtrado > -1) {
      rowsBefore.splice(filtrado, 1)
      setCharacteristics(rowsBefore)
      resetSelected()
      setCurrenAction('Buscar')
      resetAction()
    }
  }

  const onHandleSearch = React.useCallback(async () => {
    try {
      setCharacteristics([])
      playLoader()
      const aplicationsData = await characteristicsServices.getCharacteristics(
        name,
      )
      stopLoader()
      if (!aplicationsData.error && aplicationsData.auto)
        setCharacteristics(aplicationsData.auto)
      else
        setToast(
          aplicationsData.message ?? 'Error al buscar Características',
          ToastTypes.Warning,
        )
    } catch (error) {
      stopLoader()
      setToast(error, ToastTypes.Danger)
    }
  }, [name, playLoader, setToast, stopLoader])

  const onHandleChange = React.useCallback(
    (setState: any) => (event: any) => {
      if (event.event) setState(event.value)
    },
    [],
  )

  return (
    <>
      <RowContainer>
        <CustomCol xs="12" md="5">
          <Labeled label="Nombre:">
            <TextBox
              value={name}
              onValueChanged={onHandleChange(setName)}
              onEnterKey={onHandleSearch}
            />
          </Labeled>
        </CustomCol>
      </RowContainer>
      <ResultadoBusqueda
        dataSource={characteristics ?? []}
        setToast={setToast}
        onSelectedItem={(item) => onSelectedItem(item)}
        onSelectItem={onSelectItem}
      />
    </>
  )
}
export default memo(Buscar)
