import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LoaderInfo, TEstado } from '../../../../../../store/types'
import { CustomDictionary } from '../../../../../ventas/types/generics'
import { SujetoExcluidoDatosEdicion, TTotalesFacturaSE } from '../types/types'
import { ProveedorBusqueda } from '../../../../../proveedores/store/types'
import {
  FacturacionEdicionPayload,
  TiposPagosVenta,
} from '../../../../../ventas/pages/ventas/types/types'
import {
  CuotaCreditoVenta,
  FormaPagoModuloVentas,
} from '../../../../../ventas/types/types'
import { ventasClearDatosEdicion } from '../../../../../ventas/store/reducers'
import { DetalleSujetoExcluidoRedux } from '../types/detalleSujetoExcluido'
import { Ciudad } from '../../../../../ventas/store/types'

const initialState: CustomDictionary<SujetoExcluidoDatosEdicion> = {}
const datosEdicionComprasSlice = createSlice({
  name: 'comprasSujetoExcluidoDatosEdicion',
  initialState: initialState,
  reducers: {
    ventasClearDatosEdicion,
    setDatosEdicion(
      state,
      action: PayloadAction<
        FacturacionEdicionPayload<SujetoExcluidoDatosEdicion>
      >,
    ) {
      state[action.payload.key] = action.payload.data
    },
    updateTotales(
      state,
      action: PayloadAction<{
        key: number | string
        totales: TTotalesFacturaSE
      }>,
    ) {
      state[action.payload.key].totales = action.payload.totales
      state[action.payload.key].descuentoGlobal =
        action.payload.totales.descuentoGloabal
    },
    updateDetalles(
      state,
      action: PayloadAction<{
        key: number | string
        detalles: Array<DetalleSujetoExcluidoRedux>
      }>,
    ) {
      state[action.payload.key].detalles = action.payload.detalles
    },
    addDetalle(
      state,
      action: PayloadAction<{
        key: number | string
        detalle: DetalleSujetoExcluidoRedux
      }>,
    ) {
      state[action.payload.key].detalles.push(action.payload.detalle)
    },
    updateProveedor(
      state,
      action: PayloadAction<{
        key: number | string
        proveedor: ProveedorBusqueda | null
        formasPago: Array<FormaPagoModuloVentas>
        formaPago: Array<FormaPagoModuloVentas>
        detalles?: Array<DetalleSujetoExcluidoRedux>
      }>,
    ) {
      state[action.payload.key].proveedor = action.payload.proveedor
      state[action.payload.key].formasPago = action.payload.formasPago
      state[action.payload.key].formaPago = action.payload.formaPago

      if (action.payload.detalles)
        state[action.payload.key].detalles = action.payload.detalles
    },
    updateProveedorOnly(
      state,
      action: PayloadAction<{
        key: number | string
        proveedor: ProveedorBusqueda
      }>,
    ) {
      state[action.payload.key].proveedor = action.payload.proveedor
    },
    updateFechaEmision(
      state,
      action: PayloadAction<{ key: string; fecha: string }>,
    ) {
      state[action.payload.key].fecha = action.payload.fecha
    },
    updateObservaciones(
      state,
      action: PayloadAction<{ key: number | string; observaciones: string }>,
    ) {
      state[action.payload.key].observaciones = action.payload.observaciones
    },
    initDatosEdicion(
      state,
      action: PayloadAction<
        FacturacionEdicionPayload<SujetoExcluidoDatosEdicion>
      >,
    ) {
      if (!state[action.payload.key]) {
        state[action.payload.key] = action.payload.data
      }
    },
    setEditLoader(
      state,
      action: PayloadAction<{ key: number | string; info: LoaderInfo }>,
    ) {
      state[action.payload.key].loader = action.payload.info
    },
    setMuestraError(
      state,
      action: PayloadAction<{
        key: number | string
        tieneError: boolean
        mensajeError: string
      }>,
    ) {
      state[action.payload.key].tieneError = action.payload.tieneError
      state[action.payload.key].mensajeError = action.payload.mensajeError
    },
    setImprimir(
      state,
      action: PayloadAction<{ key: number | string; imprimir: boolean }>,
    ) {
      state[action.payload.key].imprimir = action.payload.imprimir
    },
    setFormaPago(
      state,
      action: PayloadAction<{
        key: number | string
        formaPago?: FormaPagoModuloVentas
      }>,
    ) {
      const { formaPago, key } = action.payload
      state[key].formaPago = formaPago ? [formaPago] : []
      if (formaPago && formaPago.descripcion !== 'CREDITO') {
        //Limpiar cuotas
        state[key].cuotas = []
      }
    },
    setCuotasCredito(
      state,
      action: PayloadAction<{
        key: number | string
        cuotas: Array<CuotaCreditoVenta>
      }>,
    ) {
      const { key, cuotas } = action.payload
      state[key].cuotas = cuotas
    },
    setMetodosAgregados(
      state,
      action: PayloadAction<{
        key: number | string
        tiposPagos: Array<TiposPagosVenta>
      }>,
    ) {
      const { key, tiposPagos } = action.payload
      state[key].tiposPagos = tiposPagos
    },
    setMostrarBusquedaItems(
      state,
      action: PayloadAction<{ key: string; mostrarBusquedaItems: boolean }>,
    ) {
      const { key, mostrarBusquedaItems } = action.payload
      state[key].mostrarBusquedaItems = mostrarBusquedaItems
    },
    setFletes(
      state,
      action: PayloadAction<{ key: number | string; flete: number }>,
    ) {
      const { key, flete } = action.payload
      state[key].flete = flete
    },
    setSeguro(
      state,
      action: PayloadAction<{ key: number | string; seguro: number }>,
    ) {
      const { key, seguro } = action.payload
      state[key].seguro = seguro
    },
    setTipoClasificacion(
      state,
      action: PayloadAction<{
        key: number | string
        tipoClasificacion?: TEstado | null
      }>,
    ) {
      const { tipoClasificacion, key } = action.payload
      state[key].tipoClasificacion = tipoClasificacion
    },
    setTipoSector(
      state,
      action: PayloadAction<{
        key: number | string
        tipoSector?: TEstado | null
      }>,
    ) {
      const { tipoSector, key } = action.payload
      state[key].tipoSector = tipoSector
    },
    setTipoCosto(
      state,
      action: PayloadAction<{
        key: number | string
        tipoCosto?: TEstado | null
      }>,
    ) {
      const { tipoCosto, key } = action.payload
      state[key].tipoCosto = tipoCosto
    },
    setTipoOperacion(
      state,
      action: PayloadAction<{
        key: number | string
        tipoOperacion?: TEstado | null
      }>,
    ) {
      const { tipoOperacion, key } = action.payload
      state[key].tipoOperacion = tipoOperacion
    },
    updateLocalidadesReceptorOnly(
      state,
      action: PayloadAction<{
        key: number | string
        localidadReceptor: Ciudad | null
      }>,
    ) {
      state[action.payload.key].localidadReceptor =
        action.payload.localidadReceptor
    },
    updateRetenerRentaValorOnly(
      state,
      action: PayloadAction<{
        key: number | string
        retenerRentaValor: number
      }>,
    ) {
      state[action.payload.key].retenerRentaValor =
        action.payload.retenerRentaValor
    },
    updateIsRetenerRenta(
      state,
      action: PayloadAction<{
        key: number | string
        isRetenerRenta: boolean
      }>,
    ) {
      state[action.payload.key].isRetenerRenta = action.payload.isRetenerRenta
    },
    updateRetenerIvaValorOnly(
      state,
      action: PayloadAction<{
        key: number | string
        retenerIvaValor: number
      }>,
    ) {
      state[action.payload.key].retenerIvaValor = action.payload.retenerIvaValor
    },
    updateIsRetenerIva(
      state,
      action: PayloadAction<{
        key: number | string
        isRetenerIva: boolean
      }>,
    ) {
      state[action.payload.key].isRetenerIva = action.payload.isRetenerIva
    },
    setAdvertencias(
      state,
      action: PayloadAction<{
        key: number | string
        advertencias: string[] | []
      }>,
    ) {
      state[action.payload.key].advertencias = action.payload.advertencias
    },
  },
})

export const {
  setAdvertencias,
  updateFechaEmision,
  initDatosEdicion,
  setDatosEdicion,
  updateTotales,
  updateDetalles,
  updateProveedor,
  updateObservaciones,
  setEditLoader,
  ventasClearDatosEdicion: clearDatosEdicion,
  setMuestraError,
  addDetalle,
  setImprimir,
  setFormaPago,
  setCuotasCredito,
  setMostrarBusquedaItems,
  updateProveedorOnly,
  setMetodosAgregados,
  setFletes,
  setSeguro,
  setTipoClasificacion,
  setTipoSector,
  setTipoCosto,
  setTipoOperacion,
  updateLocalidadesReceptorOnly,
  updateRetenerRentaValorOnly,
  updateIsRetenerRenta,
  updateRetenerIvaValorOnly,
  updateIsRetenerIva,
} = datosEdicionComprasSlice.actions
export const datosEdicionReducer = datosEdicionComprasSlice.reducer
