import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EdicionPayload } from '../../../../store/types'
import { ImportacionesDatosEdicion } from '../types/types'
import { CustomDictionary, LoaderInfo } from '../../../../../../store/types'
import { Movimiento } from '../../conceptosMovimientos/types/types'
import { LocalOption } from '../../../../../componentes/localesLookUp/store/type'

const initialState: CustomDictionary<ImportacionesDatosEdicion> = {}

const datosEditSlice = createSlice({
  name: 'importacionesDatosEdicion',
  initialState: initialState,
  reducers: {
    deleteEditData(state, action: PayloadAction<string>) {
      delete state[action.payload]
    },
    setDatosEdicion(
      state,
      action: PayloadAction<{
        key: string
        importacion: ImportacionesDatosEdicion
      }>,
    ) {
      state[action.payload.key] = action.payload.importacion
    },
    initDatosEdicion(
      state,
      action: PayloadAction<EdicionPayload<ImportacionesDatosEdicion>>,
    ) {
      if (!state[action.payload.key]) {
        state[action.payload.key] = action.payload.data
      }
    },
    onChangeMovimientos(
      state,
      action: PayloadAction<{ key: number | string; concepto: Movimiento }>,
    ) {
      state[action.payload.key].concepto = action.payload.concepto
    },
    onChangeLocal(
      state,
      action: PayloadAction<{ key: number | string; local: LocalOption }>,
    ) {
      state[action.payload.key].local = action.payload.local
    },
    changeLoader(state, action: PayloadAction<{ key: number | string; loader: LoaderInfo }>) {
      state[action.payload.key].loader = action.payload.loader
    },
    onChangeDocumento(
      state,
      action: PayloadAction<{ key: number | string; documento: string }>,
    ) {
      state[action.payload.key].documento = action.payload.documento
    },
    onChangeDetall(
      state,
      action: PayloadAction<{ key: number | string; detalle: Array<any> }>,
    ) {
      state[action.payload.key].dataGrid = action.payload.detalle
    },
    onChangeSeries(
      state,
      action: PayloadAction<{ key: number | string; series: Array<any> }>,
    ) {
      state[action.payload.key].dataSeries = action.payload.series
    },
    onChangeTotales(
      state,
      action: PayloadAction<{
        key: number | string
        subtotalIva: number | null
        subtotalNoIva: number | null
      }>,
    ) {
      state[action.payload.key].subtotalIva = action.payload.subtotalIva
      state[action.payload.key].subtotalNoIva = action.payload.subtotalNoIva
    },
    onChangeCantidadAdd(
      state,
      action: PayloadAction<{ key: number | string; cantidad: number | null }>,
    ) {
      state[action.payload.key].cantidad = action.payload.cantidad
    },
  },
})

export const { setDatosEdicion, initDatosEdicion, deleteEditData, onChangeMovimientos, onChangeSeries, changeLoader,
  onChangeLocal, onChangeDocumento, onChangeDetall, onChangeTotales, onChangeCantidadAdd } = datosEditSlice.actions
export const dataEditReducer = datosEditSlice.reducer
