import React from 'react'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput'
import { RadioGroup, TextBox, ValidationGroup, ValidationSummary, Validator } from 'devextreme-react'
import { UnidadesDatosEdicion } from '../../types/types'
import { ButtonTypes } from '../../../../../../../views/componentes/globalMenu/types'
import { clearButtonClick, setCurrentExecutingAction, setNameTabe } from '../../store/tabsReducer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import TipoEstadoLookUp from '../../../../../../componentes/tipoEstado'
import {
  changeLoaderTab,
  setCurrentFunction,
} from '../../store/generalReducer'
import { setDatosEdicion } from '../../store/editDataReducer'
import GruposUnidadesLookUp from '../../../../../../componentes/gruposUnidadesLookUp'
import { TabState } from '../../../../../../../store/genericTabTypes'
import { AsyncRule, RequiredRule } from 'devextreme-react/validator'
import validations from '../../../../../../../config/validations'
import { MessagesKeys, lh } from '../../../../../../../helpers/localizationHelper'
import { DocumentInfo, ToastTypes } from '../../../../../../../store/types'
import { addToast } from '../../../../../../../store/toasterReducer'
import { SaveUnit } from '../../../../items/types/types'
import { unitsServices } from '../../../../items/components/modalUnidades/services/modalUnidades.services'
import BlockUi from '../../../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../../../views/componentes/loadingindicator/loadingindicator'
import { unitGroupsServices } from '../../../../../../componentes/gruposUnidadesLookUp/services/gruposUnidadesServices'
import { tipoEstadoService } from '../../../../../../componentes/tipoEstado/service/tipoEstado.service'
import { StatesEdition } from '../../../../../../ventas/types/enums'
import VisualizaError from '../../../../../../ventas/pages/shared/visualizaError/visualizaError'
import ListErrosValidationForm from '../../../../../../componentes/listadoErroresValidacionForm'
import { isMobile } from 'react-device-detect'
import { setDatosEdicionBackup } from '../../store/configReducer'

export const optionsValues = [
  { value: 0, label: 'Enteros' },
  { value: 1, label: 'Decimales' },
]

interface INewProps extends React.PropsWithChildren {
  info: DocumentInfo<UnidadesDatosEdicion>
  tab: TabState<UnidadesDatosEdicion>
  tabId: string
}

const Nuevo: React.FunctionComponent<INewProps> = (props) => {
  const { tab, tabId, info } = props
  const dispatch = useDispatch()
  const loader = useSelector((state: RootState) => state.inventarios.catalogos.unidades.general.loaderTab)
  const unitsState = useSelector((state: RootState) => state.inventarios.catalogos.unidades.editData[tabId])
  const unitsStateBackup = useSelector((state: RootState) => state.inventarios.catalogos.unidades.config[tabId])
  const loading = useSelector((state: RootState) => state.inventarios.catalogos.unidades.editData[tabId].loading)
  const tabs = useSelector((state: RootState) => state.inventarios.catalogos.unidades.tabs)
  const sms = validations['es']
  const [arrayErrors, setArrayErrors] = React.useState<Array<string>>([])
  const [showErrorPopup, setShowErrorPopup] = React.useState<boolean>(false)
  const validationGroupRef = React.useRef<any>()

  const playLoader = React.useCallback(
    (message = 'Cargando...') => {
      dispatch(changeLoaderTab({ show: true, mensaje: message }))
    },
    [dispatch],
  )

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoaderTab({ show: false, mensaje: '' }))
  }, [dispatch])

  const setButtonExecuttion = React.useCallback((tipo: ButtonTypes | undefined) => {
    dispatch(
      setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: tipo,
      }),
    )
  }, [dispatch, tabId])

  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(
      addToast({
        title: 'Inventario - Unidades',
        content: mensaje,
        type: tipo,
      }),
    )
  }, [dispatch])

  const onObtnerTipo = React.useCallback(async (codigo: number) => {
    try {
      const data = await unitGroupsServices.getUnitsGroups()
      if (data !== null && data !== undefined && data['error'] === false) {
        const tipo = data['auto'].find(item => item?.codigo === codigo)
        return tipo
      }
      return null
    } catch (error) {
      return null
    }
  }, [])
  const onObtnerEstado = React.useCallback(async (codigo: number) => {
    try {
      const data = await tipoEstadoService.getTipoEstado('Elija un estado')
      if (data !== null && data !== undefined && data['error'] === false) {
        const estado = data['auto'].find(item => item?.codigo === codigo)
        return estado
      }
      return null
    } catch (error) {
      return null
    }
  }, [])

  const modoNuevo = React.useCallback(async (template: number, limpiar: boolean = false) => {
    if (loading === false && !limpiar) {
      return
    }
    setButtonExecuttion(ButtonTypes.new)
    playLoader()
    const tipo = await onObtnerTipo(-1)
    const estado = await onObtnerEstado(1)
    const data = { ...defaultDataUnits }
    data.valores = optionsValues[0]
    data.tipo = tipo
    data.estado = estado
    dispatch(setDatosEdicion({
      key: tabId,
      unidad: data
    }))
    dispatch(setDatosEdicionBackup({
      key: tabId,
      unidad: data
    }))
    stopLoader()
    setButtonExecuttion(undefined)
  }, [loading, playLoader, setButtonExecuttion, stopLoader, dispatch, tabId, onObtnerTipo, onObtnerEstado])

  const cargarUnidad = React.useCallback(async (loder: boolean, unidadData) => {
    if (loder === false) {
      return
    }
    setButtonExecuttion(ButtonTypes.new)
    playLoader('Cargando unidad...')
    const tipo = await onObtnerTipo(unidadData?.grupoCodigo)
    const estado = await onObtnerEstado(unidadData?.estadoCodigo)
    const data = { ...unitsState }

    data.codigo = unidadData?.codigo ?? 0,
      data.nombre = unidadData?.nombre ?? '',
      data.abreviatura = unidadData?.siglas ?? '',
      data.tipo = tipo
    data.valores = unidadData?.fraccion === 1 ? optionsValues[1] : optionsValues[0],
      data.estado = estado
    data.loading = false
    dispatch(setDatosEdicion({
      key: tabId,
      unidad: data
    }))
    dispatch(setDatosEdicionBackup({
      key: tabId,
      unidad: data
    }))
    stopLoader()
    setButtonExecuttion(undefined)
  }, [dispatch, onObtnerEstado, onObtnerTipo, playLoader, setButtonExecuttion, stopLoader, tabId, unitsState])


  const onBroom = React.useCallback(() => {
    dispatch(setDatosEdicion({
      key: tabId,
      unidad: defaultDataUnits
    }))
  }, [dispatch, tabId])

  const onValueChangedForm = React.useCallback((data, key: string) => {
    if (data !== null && data !== undefined) {
      let value = null
      if (data?.event !== null && data?.event !== undefined) {
        value = data?.value
      } else if (key === "estado" || key === "tipo") {
        value = data
      }
      if (value !== null && value !== undefined) {
        dispatch(setDatosEdicion({
          key: tabId,
          unidad: {
            ...unitsState,
            [key]: value
          }
        }))
      }
    }
  }, [dispatch, tabId, unitsState])

  const validateAsyncSelect = React.useCallback((selectedState: any, message: string) => {
    if (selectedState.value.codigo > -1) {
      return Promise.resolve()
    } else {
      return Promise.reject(message)
    }
  }, [])

  const validateAsyncSeleccionEstado = React.useCallback((datoSeleccion) => {
    return validateAsyncSelect(datoSeleccion, `Estado: Debe seleccionar una opción.`)
  }, [validateAsyncSelect])


  const validateAsyncSeleccionTipo = React.useCallback((datoSeleccion) => {
    return validateAsyncSelect(datoSeleccion, `Tipo: Debe seleccionar una opción.`)
  }, [validateAsyncSelect])

  const onValidate = React.useCallback(() => {
    const arrayErrors: Array<string> = []
    if (unitsState?.nombre === null || unitsState?.nombre === undefined || unitsState?.nombre === '') {
      arrayErrors.push('Código: Ingrese el código de la unidad.')
    }
    if (unitsState?.tipo === null || unitsState?.tipo === undefined || unitsState.tipo?.codigo <= 0) {
      arrayErrors.push('Tipo: Elija el tipo de la unidad.')
    }
    if (unitsState?.estado === null || unitsState?.estado === undefined || unitsState.estado?.codigo <= 0) {
      arrayErrors.push('Estado: Elija el estado de la unidad.')
    }
    return arrayErrors
  }, [unitsState])

  const onHandleSave = React.useCallback(async () => {
    let errors: Array<string> = []
    const result = validationGroupRef.current.instance.validate()
    errors = onValidate()
    if (result.isValid && errors.length === 0) {
      try {
        const save_data: SaveUnit = {
          infoRegistro: {
            codigo: unitsState?.codigo ?? 0,
            nombre: unitsState?.nombre ?? "",
            siglas: unitsState?.abreviatura ?? "",
            grupo: unitsState.tipo?.codigo ?? 0,
            estado: unitsState.estado?.codigo ?? 0,
            fraccion: unitsState.valores.value ?? 0
          }
        }
        setButtonExecuttion(ButtonTypes.save)
        playLoader('Guardando...')
        const data = await unitsServices.saveUnit(save_data)
        stopLoader()
        setButtonExecuttion(undefined)
        if (data !== null && data !== undefined && data['error'] === false) {
          dispatch(setNameTabe({
            key: tabId,
            codigo: data['auto'],
            nombre: unitsState?.nombre
          }))
          setToast(data['message'], ToastTypes.Success)
        } else {
          setToast(data['message'], ToastTypes.Danger)
        }
      } catch (error) {
        stopLoader()
        setButtonExecuttion(undefined)
        setToast(error.message, ToastTypes.Danger)
      }
    } else {
      setArrayErrors(errors)
      setShowErrorPopup(true)
      setToast(lh.getMessage(MessagesKeys.GlobalErrorSave), ToastTypes.Warning)
    }
  }, [setToast, unitsState, playLoader, stopLoader, setButtonExecuttion, dispatch, tabId, onValidate])

  const onUndo = React.useCallback(() => {
    dispatch(setDatosEdicion({
      key: tabId,
      unidad: { ...unitsStateBackup }
    }))
  }, [dispatch, tabId, unitsStateBackup])

  const handleButtonClick = React.useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.save:
          if (tabs.current === tabId) onHandleSave()
          break
        case ButtonTypes.broom:
          if (tabs.current === tabId) {
            onBroom()
          }
          break
        case ButtonTypes.undo:
          if (tabs.current === tabId) {
            onUndo()
          }
          break
        default:
          break
      }
      dispatch(setCurrentFunction(''))
      dispatch(clearButtonClick(tabId))
    },
    [dispatch, onHandleSave, tabId, tabs, onUndo, onBroom],
  )

  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab.globalButtonClick])

  React.useEffect(() => {
    if (tab.editStatus === StatesEdition.new) {
      modoNuevo(tab?.info?.codigo)
    } else {
      cargarUnidad(loading, info.info)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {showErrorPopup && (
        <VisualizaError
          titulo="Error en Grupos"
          mensaje={'Error al guardar'}
          content={
            <ListErrosValidationForm
              mainMessage={'Revise los siguientes errores:'}
              errorsList={arrayErrors}
              color={'danger'}
            />
          }
          onOk={() => setShowErrorPopup(false)}
        />
      )}
      <div style={{ overflowX: 'hidden' }}>
        <BlockUi
          tag="div"
          loader={LoadingIndicator}
          blocking={loader.show}
          message={loader.mensaje}
        >
          <RowContainer>
            <ValidationGroup id={'formUnidades'} ref={validationGroupRef}>
              {
                !isMobile && (
                  <RowContainer>
                    <CustomCol lg="6" md="8" sm="12">
                      <ValidationSummary id="summary"></ValidationSummary>
                    </CustomCol>
                  </RowContainer>
                )
              }
              <RowContainer>
                <CustomCol xs="12" md="3">
                  <Labeled label="Nombre:">
                    <TextBox
                      value={unitsState?.nombre ?? ''}
                      onValueChanged={(data) => onValueChangedForm(data, 'nombre')}
                    >
                      <Validator>
                        <RequiredRule message={'Nombre: ' + sms['required']} />
                      </Validator>
                    </TextBox>
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="2">
                  <Labeled label="Abreviatura:">
                    <TextBox
                      value={unitsState?.abreviatura ?? ''}
                      onValueChanged={(data) => onValueChangedForm(data, 'abreviatura')}
                    />
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="3">
                  <Labeled label="Tipo:">
                    <GruposUnidadesLookUp
                      selected={unitsState?.tipo ?? null}
                      onChanged={(data) => onValueChangedForm(data, 'tipo')}
                      onChangedOptions={() => { }}
                    >
                      <Validator>
                        <RequiredRule message={'Tipo: Debe seleccionar una opción'} />
                        <AsyncRule
                          message={'Tipo: Debe seleccionar una opción'}
                          validationCallback={validateAsyncSeleccionTipo}
                        />
                      </Validator>
                    </GruposUnidadesLookUp>
                  </Labeled>
                </CustomCol>
              </RowContainer>
              <RowContainer>
                <CustomCol xs="12" md="3">
                  <Labeled label="Valores:">
                    <RadioGroup
                      name="grupos-activos"
                      layout="horizontal"
                      displayExpr="label"
                      items={optionsValues}
                      value={unitsState?.valores ?? null}
                      onValueChanged={(data) => onValueChangedForm(data, 'valores')}
                    />
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="2">
                  <Labeled label="Estado:">
                    <TipoEstadoLookUp onChanged={(data) => onValueChangedForm(data, 'estado')} selected={unitsState?.estado ?? null} >
                      <Validator>
                        <RequiredRule message={'Estado: Debe seleccionar una opción'} />
                        <AsyncRule
                          message={'Estado: Debe seleccionar una opción'}
                          validationCallback={validateAsyncSeleccionEstado}
                        />
                      </Validator>
                    </TipoEstadoLookUp>
                  </Labeled>
                </CustomCol>
              </RowContainer>
              {
                isMobile && (
                  <RowContainer>
                    <CustomCol lg="6" md="8" sm="12">
                      <ValidationSummary id="summary"></ValidationSummary>
                    </CustomCol>
                  </RowContainer>
                )
              }
            </ValidationGroup>
          </RowContainer>
        </BlockUi>
      </div>
    </>
  )
}

export default React.memo(Nuevo)

export const defaultDataUnits: UnidadesDatosEdicion = {
  codigo: 0,
  nombre: '',
  abreviatura: '',
  tipo: null,
  valores: null,
  estado: null,
  guardado: false,
  loading: false
}
