

import React from 'react'
import { CCard, CCardBody, CCardHeader } from '@coreui/react-pro'
import { DataGrid, LoadPanel } from 'devextreme-react'
import Modalform from '../../../views/componentes/modalform'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import { ButtonTypes } from '../../../views/componentes/globalMenu/types'
import { AccionMenu } from '../../../store/types'
import { GlobalMenu } from '../../../views/componentes/globalMenu/globalMenu'
import { Column, Pager, Paging } from 'devextreme-react/data-grid'
import { getAllowedPageSizes } from '../../../helpers/Helper'


interface IModalItemsConjuntos extends React.PropsWithChildren {
  onChanged: (dataLocal: any) => void
  onCancel: () => void
  show: boolean
  codModulo: number
  dataItems: Array<any>
}

const menuDefecto = [
  {
    icon: 'fal fa-arrow-left',
    id: 'Enviar',
    text: 'Enviar',
    type: ButtonTypes.sendMail,
  }
]


export const ModalItemsConjuntos: React.FC<IModalItemsConjuntos> = (props) => {

  const { onChanged, onCancel, show, codModulo, dataItems } = props

  const loader = useSelector((state: RootState) => state.global.loader);
  const [resultados, setResultados] = React.useState([])
  const [selected, setSelected] = React.useState(null)

  const botones = menuDefecto.map(acc => {
    const boton: AccionMenu = {
      nombre: acc.id,
      icon: acc.icon,//as any,
      actionType: acc.type,
      main: false,
      modal: false,
      shortcut: null
    }
    return boton;
  })


  const [acciones,] = React.useState<Array<AccionMenu>>(botones);
  const dataGrid = React.useRef<any>()

  const getButtonDisabled = React.useCallback((button: AccionMenu) => {
    switch (button.actionType) {
      case ButtonTypes.sendMail:
        return selected === null || selected === undefined
        break
      default:
        return false;
    }
  }, [selected])

  const onSelectedItemsChanged = React.useCallback(({ selectedRowsData }) => {
    console.log('detalle select', selectedRowsData[0])
    setSelected(selectedRowsData[0])
  }, [])

  const onSendData = React.useCallback(() => {
    console.log(selected)
    onChanged(selected)
  }, [onChanged, selected])

  React.useEffect(() => {
    setResultados(dataItems)
  }, [dataItems])

  const returnAction = (boton: AccionMenu) => {

    switch (boton.actionType) {
      case ButtonTypes.sendMail:
        onSendData()
        return
      default:
        break;
    }
  }

  const botonesModal = () => {
    return (

      <GlobalMenu
        acciones={acciones}
        onClick={returnAction}
        getButtonDisabled={getButtonDisabled}
      />

    );
  }

  const bodyModal = () => {

    return (
      <>
        <DataGrid
          ref={dataGrid}
          id="codigo"
          selection={{ mode: 'single' }}
          dataSource={resultados}
          showColumnLines={true}
          showRowLines={true}
          showBorders={true}
          columnResizingMode="widget"
          onSelectionChanged={onSelectedItemsChanged}
          onRowDblClick={onSendData}
          loadPanel={{
            enabled: true,
            height: 90,
            indicatorSrc: '',
            shading: false,
            shadingColor: '',
            showIndicator: true,
            showPane: true,
            text: 'Cargando...',
            width: 200,
          }}
        >
          <Paging defaultPageSize={10} />
          <Pager
            visible={resultados.length > 0 ? true : false}
            allowedPageSizes={getAllowedPageSizes(resultados)}
            displayMode={'full'}
            showPageSizeSelector={true}
            showInfo={true}
            showNavigationButtons={true}
          />
          <Column dataField="barras" caption="Barras" width="100%" />
          <Column dataField="descripcion" caption="Descripción" width="100%" />
          <Column dataField="unidad" caption="Unidad" width="100%" />
          <Column dataField="existencia" caption="Existencia" width="100%" />
          <Column dataField="precioUnitario" caption="P. Unitario" width="100%" />
          <Column dataField="costo" caption="Costo" width="100%" visible={codModulo === 11 || codModulo === 144} />
          <Column dataField="cantidad" caption="Cantidad" width="100%" visible={codModulo === 11 || codModulo === 144} />

        </DataGrid>
      </>
    )
  }



  const renderContent = () => {
    return (
      <CCard>
        <CCardHeader>
          {botonesModal()}
        </CCardHeader>
        <CCardBody>
          <LoadPanel
            shadingColor="rgba(0,0,0,0.4)"
            position={'center'}
            visible={loader.show}
            showIndicator={true}
            shading={true}
            showPane={true}
            message={loader.mensaje}
            closeOnOutsideClick={false}
          />
          {bodyModal()}
        </CCardBody>
      </CCard>
    )
  }

  return (
    <>
      <>
        <Modalform
          name={'itemPorConjuntos'}
          key={'itemPorConjuntos'}
          headerTitle={"Items Por Conjuntos"}
          childrenBody={renderContent()}
          closeOnBackdrop={false}
          show={show}
          onClose={() => onCancel()}
          centered={true}
          size='lg'
        />
      </>
    </>
  )
}
