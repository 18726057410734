import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MenuState } from '../../../../../ventas/types/types'
import { AccionMenu } from '../../../../../../store/types'

const initialState: MenuState = {
  loading: true,
  accions: [],
}

const menuContratosSlice = createSlice({
  name: 'nominaRolPagosMenu',
  initialState: initialState,
  reducers: {
    setMenuButtons(state, action: PayloadAction<Array<AccionMenu>>) {
      state.accions = action.payload
      state.loading = false
    },
  },
})

export const { setMenuButtons } = menuContratosSlice.actions
export const menuReducer = menuContratosSlice.reducer
