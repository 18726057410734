import { combineReducers } from '@reduxjs/toolkit'
import { tabsReducer } from './tabsReducer'
import { generalReducer } from './generalReducer'
import { InventarioInicialState } from '../types/types'
import { dataEditReducer } from './editDataReducer'
import { searchReducer } from './serachReducer'
import { menuReducer } from './menuReducer'
import { configuracionesInventarioInicial } from './configReducer'

export const InventarioInicialReducer = combineReducers<InventarioInicialState>(
  {
    config: configuracionesInventarioInicial,
    menu: menuReducer,
    general: generalReducer,
    tabs: tabsReducer,
    editData: dataEditReducer,
    search: searchReducer,
  },
)
