import React, { useEffect } from 'react'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput'
import {
  NumberBox,
  TextBox,
  ValidationGroup,
  ValidationSummary,
  Validator,
} from 'devextreme-react'
import { RequiredRule, StringLengthRule } from 'devextreme-react/data-grid'
import { ZonasListado, SaveZona } from '../../types/types'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import {
  clearButtonClick,
  openTab,
  setButtons,
  setCurrentExecutingAction,
  setNameTabe,
} from '../../store/tabsReducer'
import { useDispatch, useSelector } from 'react-redux'
import { zonasServices } from '../../services/zonas.services'
import { RootState } from '../../../../../../store/store'
import { ToastTypes } from '../../../../../../store/types'
import {
  changeLoaderZonas,
  setCurrentFunction,
} from '../../store/generalReducer'
import { addToast } from '../../../../../../store/toasterReducer'
import { initDatosEdicion, setDatosEdicion } from '../../store/editDataReduce'
import { StatesEdition } from '../../../../../ventas/types/enums'
import Dialog from '../../../../../../views/componentes/librerias/bootstrap-dialog'
import { defaultDataZona } from '../..'
import { v4 as uuidv4 } from 'uuid'
import { TabState } from '../../../../../../store/genericTabTypes'

interface INewProps extends React.PropsWithChildren {
  tab: TabState<ZonasListado>
  tabId: string
}

const Nuevo: React.FunctionComponent<INewProps> = (props) => {
  const { tab, tabId } = props
  const dispatch = useDispatch()
  const currentAction = useSelector(
    (state: RootState) => state.clientes.zonas.general.currentFunction,
  )
  const zonasState = useSelector((state: RootState) => {
    return state.clientes.zonas.editData[tabId]
  })
  const tabs = useSelector((state: RootState) => {
    return state.clientes.zonas.tabs
  })

  const [confirmarNuevo, setconfirmarNuevo] = React.useState<boolean>(false)
  const validationGroupRef = React.useRef<any>()
  const dialogRef = React.useRef<any>(null)

  const playLoader = React.useCallback(
    (message = 'Cargando...') => {
      dispatch(changeLoaderZonas({ show: true, mensaje: message }))
    },
    [dispatch],
  )

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoaderZonas({ show: false, mensaje: '' }))
  }, [dispatch])

  const onChangedCodeZona = React.useCallback(
    (code: number) => {
      dispatch(
        setDatosEdicion({
          key: tabId,
          zonas: {
            ...zonasState,
            codigo: code,
            guardado: true,
          },
        }),
      )
    },
    [dispatch, zonasState, tabId],
  )

  const onChangedCode = React.useCallback(
    (data) => {
      if (data.event)
        dispatch(
          setDatosEdicion({
            key: tabId,
            zonas: {
              ...zonasState,
              codigo: data.value,
            },
          }),
        )
    },
    [dispatch, zonasState, tabId],
  )

  const onChangedZona = React.useCallback(
    (data) => {
      if (data.event) {
        dispatch(
          setDatosEdicion({
            key: tabId,
            zonas: {
              ...zonasState,
              nombre: data.value,
            },
          }),
        )
      }
    },
    [dispatch, zonasState, tabId],
  )

  const setDataPerformance = React.useCallback(
    (zonas) => {
      dispatch(
        setDatosEdicion({
          key: tabId,
          zonas: {
            codigo: zonas.codigo,
            nombre: zonas.nombre,
            estado: zonas.estado,
            guardado: false,
          },
        }),
      )
    },
    [dispatch, tabId],
  )

  const onConfirmarNuevo = React.useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea crear un nuevo registro?`,
      actions: [
        Dialog.Action(
          <span>
            <u>P</u>estaña Actual
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-success',
          'p',
        ),
        Dialog.Action(
          <span>
            <u>N</u>ueva Pestaña
          </span>,
          (dialog) => {
            dialog.hide()
            const id = uuidv4()
            dispatch(
              initDatosEdicion({
                key: id,
                data: defaultDataZona,
              }),
            )
            dispatch(openTab({ key: id }))
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setconfirmarNuevo(false)
    return
  }, [dispatch])

  React.useEffect(() => {
    if (confirmarNuevo) {
      onConfirmarNuevo()
    }
  }, [confirmarNuevo, onConfirmarNuevo])

  const onHandleSave = React.useCallback(async () => {
    const result = validationGroupRef.current.instance.validate()
    if (result.isValid === false) {
      dispatch(
        addToast({
          id: '',
          autoHide: true,
          content: 'Existen Errores en los datos, por favor verifiquelos.',
          fade: true,
          title: 'Guardar',
          type: ToastTypes.Warning,
        }),
      )
    } else {
      try {
        dispatch(
          setCurrentExecutingAction({
            tabKey: tabId,
            buttonType: ButtonTypes.save,
          }),
        )
        playLoader('Guardando . . .')
        const zona: SaveZona = {
          nombre: zonasState.nombre,
          codigo: zonasState.codigo,
          estado: zonasState.estado,
        }
        const newZona = await zonasServices.setZona(zona)
        if (!newZona.error) {
          onChangedCodeZona(
            tab.editStatus === StatesEdition.save
              ? Number(tab.info.info.codigo)
              : Number(newZona.auto),
          )
          dispatch(
            setNameTabe({
              key: tabId,
              codigo:
                tab.editStatus === StatesEdition.save
                  ? tab.info.info.codigo
                  : newZona.auto,
              nombre: zonasState.nombre,
            }),
          )
          dispatch(
            setButtons({
              tabKey: tabId,
              buttons: {
                Nuevo: true,
                Guardar: false,
                Editar: false,
                Eliminar: false,
                Buscar: true,
                Imprimir: false,
                Deshacer: false,
              },
            }),
          )
          stopLoader()
          dispatch(
            addToast({
              id: '',
              autoHide: true,
              content: newZona.message ?? 'Registro Satisfactorio',
              fade: true,
              title: 'Guardar',
              type: ToastTypes.Success,
            }),
          )
        } else {
          dispatch(
            setCurrentExecutingAction({
              tabKey: tabId,
              buttonType: undefined,
            }),
          )
          stopLoader()
          dispatch(
            addToast({
              id: '',
              autoHide: true,
              content: newZona.message ?? 'Error al guardar.',
              fade: true,
              title: 'Guardar',
              type: ToastTypes.Danger,
            }),
          )
        }
      } catch (error) {
        dispatch(
          setCurrentExecutingAction({
            tabKey: tabId,
            buttonType: undefined,
          }),
        )
        stopLoader()
        dispatch(
          addToast({
            id: '',
            autoHide: true,
            content:
              'Error al guardar ' +
              (typeof error === 'string' ? error : JSON.stringify(error)),
            fade: true,
            title: 'Guardar',
            type: ToastTypes.Danger,
          }),
        )
      }
    }
  }, [
    dispatch,
    playLoader,
    zonasState,
    tabId,
    tab,
    stopLoader,
    onChangedCodeZona,
  ])

  const onHandleUndo = React.useCallback(() => {
    if (tab.editStatus === StatesEdition.save) {
      dispatch(
        setDatosEdicion({
          key: tabId,
          zonas: {
            ...zonasState,
            nombre: tab.info.info.nombre,
            codigo: tab.info.info.codigo,
          },
        }),
      )
    } else {
      dispatch(
        setDatosEdicion({
          key: tabId,
          zonas: {
            ...zonasState,
            nombre: defaultDataZona.nombre,
            codigo: defaultDataZona.codigo,
          },
        }),
      )
    }
  }, [dispatch, tab, tabId, zonasState])

  const handleButtonClick = React.useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.save:
          if (tabs.current === tabId) onHandleSave()
          break
        case ButtonTypes.new:
          if (tabs.current === tabId) setconfirmarNuevo(true)
          break
        case ButtonTypes.undo:
          if (tabs.current === tabId) onHandleUndo()
          break
        default:
          break
      }
      dispatch(setCurrentFunction(''))
      dispatch(clearButtonClick(tabId))
    },
    [dispatch, onHandleSave, onHandleUndo, tabId, tabs],
  )

  const onEnterKeyHandle = React.useCallback(() => {
    if (!zonasState.guardado) onHandleSave()
  }, [onHandleSave, zonasState])

  useEffect(() => {
    if (currentAction !== '') handleButtonClick(currentAction)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAction])

  React.useEffect(() => {
    if (tab.editStatus === StatesEdition.save) setDataPerformance(tab.info.info)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Dialog ref={dialogRef} />
      <ValidationGroup
        key={'valitadionZonas'}
        id={'valitadionZonas'}
        ref={validationGroupRef}
      >
        <RowContainer>
          <ValidationSummary
            id="summaryValidateZonas"
            style={{ marginTop: '12px' }}
          />
          <CustomCol xs="12" md="3">
            <Labeled label="Código:">
              <NumberBox
                value={zonasState.codigo}
                onValueChanged={onChangedCode}
                readOnly
                disabled={true}
              >
                <Validator>
                  <RequiredRule message={'Código: El código es requerido.'} />
                </Validator>
              </NumberBox>
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="3">
            <Labeled label="Zona:">
              <TextBox
                value={zonasState.nombre}
                onValueChanged={onChangedZona}
                onEnterKey={onEnterKeyHandle}
              >
                <Validator>
                  <RequiredRule
                    message={
                      'Zona: Debe tener al menos 3 y máximo 250 caracteres.'
                    }
                  />
                  <StringLengthRule
                    min={3}
                    max={250}
                    message={
                      'Zona: Debe tener al menos 3 y máximo 250 caracteres.'
                    }
                  />
                </Validator>
              </TextBox>
            </Labeled>
          </CustomCol>
        </RowContainer>
      </ValidationGroup>
    </>
  )
}

export default Nuevo
