import React from 'react'
import { useDispatch, useSelector } from 'react-redux';

import { AbonosCuadresCajaListado } from '../../../../../../ventas/pages/cuadres/types/types';
import { RootState } from '../../../../../../../store/store';
import { changeFilter } from '../../../store/searchReducer';
import { Cliente } from '../../../../../../ventas/types/types';
import { CCol, CRow } from '@coreui/react-pro';
import { DataGrid, NumberBox, TextBox } from 'devextreme-react';
import { Column, Editing, FilterRow, Grouping, HeaderFilter, Pager, Paging } from 'devextreme-react/data-grid';
import { getAllowedPageSizes } from '../../../../../../../helpers/Helper';
import { setDeleteItem, setSeleccionarDato } from '../../../store/verAbonosReducer';
import { getIndexProvider } from '../../../../../../shared/helpers/funciones';

interface IAbonos extends React.PropsWithChildren {
  data: Array<AbonosCuadresCajaListado>
}


const Abonos: React.FunctionComponent<IAbonos> = (props) => {

  const [displayMode,] = React.useState<string | 'full' | 'compact'>('full');
  const [showPageSizeSelector,] = React.useState<boolean>(true);
  const [showInfo,] = React.useState<boolean>(true);
  const [showNavButtons,] = React.useState<boolean>(true);
  const [resultados, setResultados] = React.useState<Array<any>>([]);
  const { data } = props

  const dispatch = useDispatch()

  const filtroGlobal = useSelector((state: RootState) => state.clientes.docCuentasXCobrar.search.filter)
  const seleccionado = useSelector((state: RootState) => state.clientes.docCuentasXCobrar.verAbonos.seleccionado)
  const deleteItem = useSelector((state: RootState) => state.clientes.docCuentasXCobrar.verAbonos.deleteItem)

  // const resultados = data['auto'] ?? []

  const getResultados = React.useCallback(() => {
    let newArray = []
    if (data !== null && data !== undefined && data['auto'] && data['auto'].length > 0) {
      newArray = data['auto']
    }
    setResultados(newArray)
  }, [data])

  const deleteRegistroTabla = React.useCallback(async () => {
    if (seleccionado.row !== null && seleccionado.row !== undefined) {
      if (resultados !== null && resultados !== undefined && resultados.length > 0) {
        const provider = resultados.slice(0);
        const index = await getIndexProvider(provider, 'ingresocodigo', seleccionado.row.ingresocodigo)
        if (index > -1) {
          provider.splice(index, 1)
          setResultados(provider)
        }
      }
    }
    dispatch(setDeleteItem(false))
  }, [resultados, seleccionado.row, dispatch])

  const getTotalSaldo = React.useCallback((): number => {
    let total = 0;
    if (resultados.length > 0) {
      resultados.map((item) => {
        total = total + item?.abonoVentaValor ?? 0
      })
    }
    return total
  }, [resultados])

  React.useEffect(() => {
    getResultados()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  React.useEffect(() => {
    if (deleteItem) {
      deleteRegistroTabla()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultados, seleccionado, deleteItem])

  const onSelectionChanged = React.useCallback((selectionChanged) => {
    console.log("onSelectionChanged", selectionChanged['selectedRowsData'][0])
    dispatch(setSeleccionarDato(
      {
        index: filtroGlobal?.cliente ? filtroGlobal.cliente?.codigo : 0,
        row: selectionChanged['selectedRowsData'][0],
        selectedRow: [selectionChanged['currentSelectedRowKeys'][0]]
      }
    ))
  }, [dispatch, filtroGlobal]);

  return (
    <>
      <CRow>
        <CCol lg="12" className="d-flex justify-content-end">
          <strong>{resultados.length}</strong>
        </CCol>
      </CRow>
      <CRow className='border mt-2'>
        <CCol xs={12} sm={12} md={12} xl={12} >
          <DataGrid
            selection={{ mode: 'single' }}
            hoverStateEnabled={true}
            dataSource={resultados}
            showBorders={true}
            showRowLines={true}
            allowColumnReordering={true}
            onSelectionChanged={onSelectionChanged}
            allowColumnResizing={true}
            columnAutoWidth={true}
            key="ingresoCodigo"
          >
            <FilterRow visible={true} />
            <Grouping autoExpandAll={false} />
            <HeaderFilter visible={true} />
            <Editing mode='cell' allowUpdating={true} />

            <Paging defaultPageSize={10} />
            <Pager
              visible={resultados.length > 0 ? true : false}
              allowedPageSizes={getAllowedPageSizes(resultados)}
              displayMode={displayMode}
              showPageSizeSelector={showPageSizeSelector}
              showInfo={showInfo}
              showNavigationButtons={showNavButtons} />

            <Column dataField='ingresoValor' groupIndex={0} caption='' width='100%' allowEditing={false} />
            <Column dataField='ventaFecha' caption='Fecha de Registro' width='100%' allowEditing={false} />
            <Column dataField='clienteNombre' caption='Cliente' width='100%' allowEditing={false} />
            <Column dataField='pagoVentaVencimiento' caption='Vencimiento' width='100%' allowEditing={false} />
            <Column dataField='abonoVentaFecha' caption='Fecha Abono' width='100%' allowEditing={false} />
            <Column dataField='ventaTipoDocumentoDescripcion' caption='Comprobante' width='100%' allowEditing={false} />
            <Column dataField='puntoVentaHost' caption='POS' width='100%' allowEditing={false} />
            <Column dataField='vendedor' caption='Vendedor' width='100%' allowEditing={false} />
            <Column dataField='numero' caption='Numero' width='100%' allowEditing={false} />
            <Column dataField='abonoVentaTipoPagoDescripcion' caption='Forma Pago' width='100%' allowEditing={false} />
            <Column dataField='doc' caption='Doc' width='100%' allowEditing={false} />
            <Column dataField='pagoventaCuota' caption='Cuota' width='100%' allowEditing={false} />
            <Column dataField='abonoVentaTotal' caption='Abono' width='100%' allowEditing={false} />
            <Column dataField='pagoVentaSaldo' caption='Saldo' width='100%' allowEditing={false} />
          </DataGrid>
        </CCol>
      </CRow>
      <CRow>
        <CCol className="mt-1 d-flex justify-content-end">
          <div className="dx-field">
            <div className="dx-field-label">{'Total:'}</div>
            <div className="dx-field-value">
              <NumberBox
                width="100%"
                value={getTotalSaldo()}
                placeholder=""
                format="#,##0.00"
              />
            </div>
          </div>
        </CCol>
      </CRow>
    </>
  )
}

export default Abonos