import { AccionMenu } from "../../../../store/types"
import { SeleccionadoTableItem } from "../../../bancos/store/types"
import { ModalOpen } from "../../../contabilidad/general/store/types"
import { Alerta } from "../../../contabilidad/pages/diarioGeneral/components/modalAnticipos/store/types"

export enum TIPOS_BOTONES {
  BOTON_NUEVO = 'Nuevo',
  BOTON_GUARDAR = 'Guardar',
  BOTON_BUSCAR = 'Buscar',
  BOTON_EDITAR = 'Editar',
  BOTON_ELIMINAR = 'Eliminar',
  BOTON_IMPRIMIR = 'Imprimir',
  BOTON_DESHACER = 'Deshacer',
  BOTON_LIMPIAR = 'Limpiar',
}

export type Registros = {
  ventaFecha: string,
  ventaNumero: string,
  clienteNombre: string,
  ventaTotal: number,
}

export type ModalListadoComprobantesState = {
  modulo: string,
  currentAction: string,
  currentFunction: string,
  aplicacion: string,
  acciones: Array<AccionMenu>,
  registros: Array<Registros>,
  refrescaTabla: boolean,
  modal: ModalOpen,
}