import { DateUtils, formatoFechasApi } from '../../../../helpers/dateUtils'
import { RequestHelper } from '../../../../helpers/requestHelper'
import { utilidades } from '../../../../helpers/utilidades'
import { SesionService } from '../../../../services/sesion.service'
import { Movimiento } from '../../../inventario/pages/movimientos/conceptosMovimientos/types/types'

export const typesMovementsServices = { getTypesMovements }

async function getTypesMovements(): Promise<any> {
  try {
    const data: any = { mensaje: 'Elija un movimiento' }
    const sesion = SesionService.getCurrentSesion()
    const keysToCache: any = {
      ...data,
      dateKey: DateUtils.getCurrentDateAsString(formatoFechasApi),
      empresa: sesion?.empresa?.codigo,
    }
    const cacheKey = 'tiposMovimientos' + JSON.stringify(keysToCache)
    const cached = sessionStorage.getItem(cacheKey)
    if (cached) {
      return { error: false, auto: JSON.parse(cached) }
    } else {
      const typesMovementsData = await RequestHelper.getAll<any>(
        'inventario/movimientos',
        'ingresosEgresos/movements',
        '/cBoxLoad',
        data,
      )
      if (typesMovementsData?.auto?.length > 0) {
        const typesMovements: Array<Movimiento> = []
        for (const typeMovement of typesMovementsData.auto) {
          typesMovements.push({
            codigo: typeMovement?.codigo ?? -1,
            tipo: typeMovement?.tipo ?? -1,
            tipoDescripcion: typeMovement?.tipoCodigo ?? '',
            descripcion: utilidades.capText(typeMovement?.descripcion) ?? '',
            cuenta: typeMovement?.cuenta ?? -1,
            cuentaDescripcion: typeMovement?.cuentaDescripcion ?? '',
            asociado: typeMovement?.asociado ?? -1,
            asociadoDescripcion: typeMovement?.asociadoDescripcion ?? '',
            asiento: typeMovement?.asiento ?? -1,
          })
        }
        sessionStorage.setItem(cacheKey, JSON.stringify(typesMovements))
        return { error: false, auto: typesMovements }
      } else
        return {
          error: true,
          message: typesMovementsData.message
            ? typesMovementsData.message
            : 'Error al obtener los datos: Movimientos',
        }
    }
  } catch (error) {
    return error
  }
}
