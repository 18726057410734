import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { DecimosDatosEdicion, configTenth } from '../types/types'
import { EdicionPayload } from '../../../../store/types'

const initialState: configTenth = {
  appThree: 0,
  appFour: 0,
  currentApp: 0
}

const configuracionDecimo = createSlice({
  name: 'configDecimosNomina',
  initialState: initialState,
  reducers: {
    setAplicacionModuloTercero(state, acion: PayloadAction<number>) {
      state.appThree = acion.payload
    },
    setAplicacionModuloCuarto(state, acion: PayloadAction<number>) {
      state.appFour = acion.payload
    },
    setAplicacion(state, acion: PayloadAction<number>) {
      state.currentApp = acion.payload
    },
    deleteEditDataBackup(state, action: PayloadAction<string>) {
      delete state[action.payload]
    },
    setDatosEdicionBackup(
      state,
      action: PayloadAction<EdicionPayload<DecimosDatosEdicion>>,
    ) {
      state[action.payload.key] = action.payload.data
    },
  },
})

export const configuracionesDecimos = configuracionDecimo.reducer
export const { setAplicacionModuloTercero, setAplicacionModuloCuarto, setAplicacion, deleteEditDataBackup, setDatosEdicionBackup } = configuracionDecimo.actions
