import { RequestHelper } from "../../../../../helpers/requestHelper"
import { Banco } from "../store/types";

export const bancosService = {
  obtenerBancos, obtenerCombos, crearBanco, deleteBanco
}

async function obtenerBancos(banco): Promise<any> {
  try {
    const obj = {
      ...banco
    }
    const data = await RequestHelper.getAll<Array<Banco>>('bancos/bancos', 'all', "", obj);
    return data;
  } catch (error) {
    return error;
  }
}

async function obtenerCombos(tipo, mensaje): Promise<any> {
  try {
    const obj = {
      tipoCodigo: tipo,
      mensaje: mensaje
    }
    const data = await RequestHelper.getAll<any>('subtypes', 'cboxLoad', "", obj);
    return data;
  } catch (error) {
    return error;
  }
}

async function crearBanco(objeto: any): Promise<any> {
  try {
    const obj = {
      infoRegistro: {
        ...objeto
      }
    }
    const data = await RequestHelper.postAll<any>('bancos/bancos', 'save', obj);
    return data;
  } catch (error) {
    return error;
  }
}

async function deleteBanco(id: any): Promise<any> {
  try {
    const objRequest = {
      codigo: id
    }
    const data = await RequestHelper.deleteRequestAll<any>('bancos/bancos', 'inactive', "", objRequest);
    return data;
  } catch (error) {
    return error;
  }
}
