import React, { useEffect } from 'react'
import { ValidationGroup } from 'devextreme-react/validation-group'
import ValidationSummary from 'devextreme-react/validation-summary'
import TextBox from 'devextreme-react/text-box'
import { CCardBody, CCol, CRow } from '@coreui/react-pro'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import { useDispatch } from 'react-redux'
import { ToastTypes } from '../../../../../../../store/types'
import { NumberBox, Validator } from 'devextreme-react'
import SelectBox from '../../../../../../../views/componentes/selectBox/selectBox'
import { RequiredRule, StringLengthRule } from 'devextreme-react/validator'
import validations from '../../../../../../../config/validations'
import { InventarioService } from '../../../../../services/inventario.service';

const sms = validations['es']


const estadoLinea = [
  {
    value: 1,
    label: 'Activo',
  },
  {
    value: 2,
    label: 'Inactivo',
  }
]


interface INuevoProps extends React.PropsWithChildren {
  onChanged: (data) => void
  selection?: any
  onCancel?: () => void
  funcion: string
  currentTab: string
  setCurrenTab: (currentTab: string) => void
  resetFunction: () => void
  resetSelected?: () => void
  setToast: (sms: string, type: ToastTypes) => void
  playLoader: () => void
  stopLoader: () => void
}

const Nuevo = (props: INuevoProps) => {
  const { onChanged, funcion, setToast, selection, playLoader, stopLoader, currentTab, resetFunction, resetSelected, setCurrenTab } = props
  const dispatch = useDispatch()
  const formSearchRef = React.useRef<any>()

  const [codigoPrimario, setCodigoPrimario] = React.useState<number>(0)
  const [codigo, setCodigo] = React.useState<string>('')
  const [descripcion, setDescripcion] = React.useState<string>('')
  const [porcentUtilidad, setPorcentUtilidad] = React.useState<number>(0.00)
  const [estado, setEstado] = React.useState<any | null>(estadoLinea[0])
  const [conConcepto, setConConcepto] = React.useState<any | null>(null)
  const [susCodigo, setSusCodigo] = React.useState<string | null>("00")


  useEffect(() => {
    if (currentTab === 'Nuevo' || currentTab === 'Editar') {
      if (funcion === 'Guardar') {
        handleSubmit()
      } else if (funcion === 'Editar') {
        handleForm(selection)
      } else {
        handleReset()
      }
      resetFunction()
    }
  }, [funcion])

  const handleReset = () => {
    if (codigoPrimario === 0) {
      setCodigo('')
      setDescripcion('')
      setPorcentUtilidad(0.00)
      setEstado(estadoLinea[0])
    } else {
      handleForm(selection)
    }
  }

  const handleForm = ({ row }: any) => {
    const { codigo, particular, descripcion, utilidad, estadoNombre, conConcepto, susCodigo } = row
    setCodigoPrimario(codigo)
    setCodigo(particular)
    setDescripcion(descripcion)
    setPorcentUtilidad(utilidad)
    if (estadoNombre === "ACTIVO") {
      setEstado(estadoLinea[0])
    } else {
      setEstado(estadoLinea[1])
    }
    setConConcepto(conConcepto)
    setSusCodigo(susCodigo)
  }


  const handleSubmit = React.useCallback(async () => {
    const result = formSearchRef.current.instance.validate()
    if (result.isValid) {
      const data_request = {
        codigoPrimario: codigoPrimario,
        codigo: codigo,
        descripcion: descripcion,
        porcentUtilidad: porcentUtilidad,
        estado: estado.value,
        conConcepto: conConcepto,
        susCodigo: susCodigo
      }
      playLoader()
      try {
        const data = await InventarioService.createLinea(data_request)
        stopLoader()
        if (data['error'] === true) {
          setToast(data['message'], ToastTypes.Warning)
        } else {
          if (funcion === 'Editar') {
            setToast(data['message'], ToastTypes.Success)
          } else {
            setToast(data['message'], ToastTypes.Success)
          }
          setCurrenTab('Buscar')
          if (selection) {
            resetSelected()
          }
        }
        handleReset()
        return await data
      } catch (error) {
        console.log(error)
        stopLoader()
        setToast(JSON.stringify(error), ToastTypes.Danger)
      }
    }
  }, [codigo, descripcion, porcentUtilidad, estado])


  return (
    <>
      <fieldset>
        <ValidationGroup ref={formSearchRef}>
          <CCardBody>
            <CRow className="row">
              <div className="dx-field-label col-lg-2 col-md-3 col-sm-12">
                {'Código:'}
              </div>
              <CustomCol className="dx-field-value" xs="12" md="4">
                <CCol md="12">
                  <TextBox
                    name="codigo"
                    value={codigo}
                    onValueChanged={({ value }: any) => setCodigo(value)}
                    onEnterKey={handleSubmit}
                  >
                    <Validator>
                      <RequiredRule message={'Código: ' + sms['required']} />
                      <StringLengthRule message={'Código: ' + sms['max'].replace('%num_characters%', '75')} max={75} />
                      <StringLengthRule message={'Código: ' + sms['min'].replace('%num_characters%', '3')} min={3} />
                    </Validator>
                  </TextBox>
                </CCol>
              </CustomCol>
            </CRow>

            <CRow className="row mt-1">
              <div className="dx-field-label col-lg-2 col-md-3 col-sm-12">
                {'Descripción:'}
              </div>
              <CustomCol className="dx-field-value" xs="12" md="4">
                <CCol md="12">
                  <TextBox
                    name="descripcion"
                    value={descripcion}
                    onValueChanged={({ value }: any) => setDescripcion(value)}
                    onEnterKey={handleSubmit}
                  >
                    <Validator>
                      <RequiredRule message={'Descripcion: ' + sms['required']} />
                      <StringLengthRule message={'Descripcion: ' + sms['max'].replace('%num_characters%', '75')} max={75} />
                      <StringLengthRule message={'Descripcion: ' + sms['min'].replace('%num_characters%', '3')} min={3} />
                    </Validator>
                  </TextBox>
                </CCol>
              </CustomCol>
            </CRow>

            <CRow className="row mt-1">
              <div className="dx-field-label col-lg-2 col-md-3 col-sm-12">
                {'% de Utilidad:'}
              </div>
              <CustomCol className="dx-field-value" xs="12" md="4">
                <CCol md="12">
                  <NumberBox
                    name="porcentUtilidad"
                    format="#,##0.00"
                    value={porcentUtilidad}
                    onValueChanged={({ value }: any) => setPorcentUtilidad(value)}
                    onEnterKey={handleSubmit}
                  >
                    <Validator>
                      <RequiredRule message={'Porcentaje de Utilidad: ' + sms['required']} />
                      <StringLengthRule message={'Porcentaje de Utilidad: ' + sms['max'].replace('%num_characters%', '3')} max={3} />
                      <StringLengthRule message={'Porcentaje de Utilidad: ' + sms['min'].replace('%num_characters%', '1')} min={1} />
                    </Validator>
                  </NumberBox>
                </CCol>
              </CustomCol>
            </CRow>

            <CRow className="row mt-1">
              <div className="dx-field-label col-lg-2 col-md-3 col-sm-12">
                {'Estado:'}
              </div>
              <CustomCol className="dx-field-value" xs="12" md="4">
                <CCol md="12">
                  <SelectBox
                    id={'selectedEstadoLinea'}
                    keyExpr={'value'}
                    displayExpr={'label'}
                    onChange={(evt) => {
                      if (evt) {
                        setEstado(evt);
                      } else {
                        setEstado(null);
                      }
                    }}
                    options={estadoLinea}
                    placeholder={'Elija una opción'}
                    selected={estado}
                    elementAttr={{
                      autocomplete: "nope",
                      autoComplete: "nope",
                    }}
                  ></SelectBox>
                </CCol>
              </CustomCol>
            </CRow>

            <CRow>
              <CCol lg="6" md="8" sm="12">
                <ValidationSummary id="summary"></ValidationSummary>
              </CCol>
            </CRow>
          </CCardBody>
        </ValidationGroup>
      </fieldset>
    </>
  )
}
export default Nuevo
