import { DateUtils, formatoFechasApi } from "../../../../helpers/dateUtils";
import { RequestHelper } from "../../../../helpers/requestHelper";
import { utilidades } from "../../../../helpers/utilidades";
import { SesionService } from "../../../../services/sesion.service";
import { ETipoTransaccion, TFormaPagoOption, TFormasPago } from "../store/type";

export const formasPagoService = { getFormasPago, getFormasPagoProvider };

async function getFormasPago(trasaccion: ETipoTransaccion, label: string): Promise<any> {
  try {
    const sesion = SesionService.getCurrentSesion()
    const data: any = {
      mensaje: label,
      grupo: trasaccion
    };
    const keysToCache: any = {
      ...data,
      dateKey: DateUtils.getCurrentDateAsString(formatoFechasApi),
      empresa: sesion?.empresa?.codigo,
    }
    const cacheKey = 'tipoFormasPago' + JSON.stringify(keysToCache);
    const cached = sessionStorage.getItem(cacheKey);
    if (cached) {
      return { error: false, auto: JSON.parse(cached) };
    } else {
      const res = await RequestHelper.getAll<any>('generales/subtypes', 'cboxLoadFP', "", data);
      let opt: Array<TFormaPagoOption> = []
      if (res?.auto && res?.auto.length > 0) {
        opt = res.auto.map(x => {
          const item: TFormaPagoOption = {
            codigo: parseInt(x.codigo),
            descripcion: utilidades.capText(x?.descripcion),
            grupo: null,
            asignadoSri: null,
          };
          return item;
        });
        res.auto = opt;
      }
      sessionStorage.setItem(cacheKey, JSON.stringify(opt));
      return res;
    }
  } catch (error) {
    return error;
  }
}

export async function getFormasPagoProvider(grupo: string, mensaje: string): Promise<any> {
  const sesion = SesionService.getCurrentSesion()
  const data: any = {
    grupo: grupo,
    mensaje: mensaje
  }
  const keysToCache: any = {
    ...data,
    dateKey: DateUtils.getCurrentDateAsString(formatoFechasApi),
    empresa: sesion?.empresa?.codigo,
  }
  const cacheKey = 'tipoFormasPagoProvider' + JSON.stringify(keysToCache);
  const cached = sessionStorage.getItem(cacheKey);
  if (cached) {
    return { error: false, auto: JSON.parse(cached) };
  } else {
    const apiData = await RequestHelper.getAll('generales/subtypes', 'cboxLoadFP', '', data)
    let opt: Array<TFormasPago> = []
    if (apiData?.auto && apiData?.auto.length > 0) {
      opt = apiData.auto.map(x => {
        const item: TFormasPago = {
          codigoFormaPago: parseInt(x.codigo),
          descripcionFormaPago: x.descripcion,
          transaccionAsignacion: null,
          codigoCuenta: null,
          itemAsignacion: x.asignado,
          cuentaNumero: null,
          cuentaDescripcion: null,
        };
        return item;
      });
      apiData.auto = opt;
    }
    sessionStorage.setItem(cacheKey, JSON.stringify(opt));
    return apiData
  }

}