import { combineReducers } from '@reduxjs/toolkit'
import { ModuloProveedoresState } from './types'
import { proveedoresReducer } from '../pages/proveedores/store/proveedoresReducers'
import { documentosCuentasPorPagarReducer } from '../pages/docsCtasXPagar/store/documentosCuentasPorPagarReducers'
import { specialtyReducer } from '../pages/especialidad/store/specialtyReducers'

export const moduloProveedoresReducer = combineReducers<ModuloProveedoresState>({
  proveedores: proveedoresReducer,
  documentosCuentasPorPagar: documentosCuentasPorPagarReducer,
  especialidad: specialtyReducer,
})
