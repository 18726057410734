import { RequestHelper } from "../../../../helpers/requestHelper";

export const modalListaRetencionesIVA = { getRetencionesIVA };

async function getRetencionesIVA(tipo): Promise<any> {
    try {
        const data: any = {
            ...tipo
        };
        const res = await RequestHelper.getAll<any>('subtypes', 'cboxLoad', "", data);
        return res;
    } catch (error) {
        return error;
    }
}

