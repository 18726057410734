import {
  FunctionComponent,
  PropsWithChildren,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { GlobalMenu } from '../../../../views/componentes/globalMenu/globalMenu'
import { AccionMenu, CustomButtons, ToastTypes } from '../../../../store/types'
import CardContent from '../../../bancos/components/cardContent'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../../store/store'
import { ItemEditData, ItemsListResult, SyncWoo } from './types/types'
import { changeLoaderItems, setCurrentFunction } from './store/generalReducer'
import TabControl from './components/shared'
import { Aplicacion } from '../../store/types'
import {
  changeCurrentTab,
  clearButtonClick,
  closeTabMultistore,
  openTab,
  openTabUnificar,
  setButtons,
  setCurrentExecutingAction,
} from './store/tabsReducer'
import { ButtonTypes } from '../../../../views/componentes/globalMenu/types'
import { v4 as uuidv4 } from 'uuid'
import { deleteEditData, initDatosEdicion } from './store/editDataReduce'
import Dialog from '../../../../views/componentes/librerias/bootstrap-dialog'
import { defaultGeneralsItem } from './components/nuevo/components/generales'
import { defaultPreciosControl } from './components/nuevo/components/preciosControl'
import { defaultCuentasContables } from './components/nuevo/components/cuentasContables'
import { defaultExistencia } from './components/nuevo/components/existencia'
import { defaultProveedores } from './components/nuevo/components/proveedores'
import { defaultImagenes } from './components/nuevo/components/imagenes'
import {
  useButtonDisabled,
  useMenuOptions,
} from '../../../../hooks/useMenuButtonControls'
import { ItemsServices } from './services/items.services'
import { defaultHistoricos } from './components/nuevo/components/historicos'
import { defaultAplicaciones } from './components/nuevo/components/aplicaciones'
import { defaultExtras } from './components/nuevo/components/extras'
import { defaultAtributos } from './components/nuevo/components/atributos'
import { defaultObservaciones } from './components/nuevo/components/observaciones'
import { defaultPromociones } from './components/nuevo/components/promociones'
import { defaultPartes } from './components/nuevo/components/partes'
import { defaultActivos } from './components/nuevo/components/activos'
import { StatesEdition } from '../../../ventas/types/enums'
import { TabTypes } from '../../../../store/enums'
import { useSetToast } from '../../../../hooks/useGlobalHooks'
import ModalFullScreen from './components/modalPantallaCompleta'
import ModalEditarPrecios from './components/modalEditarPrecios'
import ModalArmarKit from './components/modalArmarKit'
import { ReportWithLocalDataModal } from '../../../../views/componentes/xtrareports/ReportWithLocalDataModal'
import ModalCodigoBarras from '../../../componentes/modalCodigoBarras'
import {
  initialFilter,
  setCleanResult,
  setFilter,
  setSearchItem,
  setSelectedItem,
  setStoreFilter,
  setTaxFilter,
  setTotalCount,
} from './store/searchReducer'

const pathVerificador = '/items'

interface ItemsProps extends PropsWithChildren {}

const Items: FunctionComponent<ItemsProps> = () => {
  const dispatch = useDispatch()
  const setToastMessage = useSetToast()

  const menuState = useSelector((state: RootState) => state.global.menu)
  const tabs = useSelector((state: RootState) => {
    return state.inventarios.items.tabs
  })
  const loader = useSelector((state: RootState) => {
    return state.inventarios.items.general.loader
  })
  const searchFilter = useSelector((state: RootState) => {
    return state.inventarios.items.search.filter
  })
  const listItems = useSelector((state: RootState) => {
    return state.inventarios.items.search.resultList
  })
  const totalCountItems = useSelector((state: RootState) => {
    return state.inventarios.items.search.totalCount
  })
  const selectedItem = useSelector(
    (state: RootState) => state.inventarios.items.search.selectedItem,
  )
  const local = useSelector((state: RootState) => state.global.session.local)

  const [confirmToRemove, setConfirmToRemove] = useState<boolean>(false)
  const [confirmToEdit, setConfirmToEdit] = useState<boolean>(false)
  const [confirmToClone, setConfirmToClone] = useState<boolean>(false)
  const [confirmToUnify, setConfirmToUnify] = useState<boolean>(false)
  const [confirmToSyncWoo, setConfirmToSyncWoo] = useState<boolean>(false)
  const [optionsMenu, setOptionsMenu] = useState<Array<AccionMenu>>([])
  const [showFullScreen, setShowFullScreen] = useState<boolean>(false)
  const [showEditPrice, setShowEditPrice] = useState<boolean>(false)
  const [showAssemblyKit, setShowAssemblyKit] = useState<boolean>(false)
  const [pdfReport, setPdfReport] = useState<null | 'Viewer' | 'Designer'>(null)
  const dialogRef = useRef<any>(null)
  const [cloneItem, setCloneItem] = useState<boolean>(false)
  const [deleteItem, setDeleteItem] = useState<boolean>(false)
  const [wooItem, setWooItem] = useState<boolean>(false)
  const [showBarCode, setShowBarCode] = useState<boolean>(false)

  const getButtonDisabled = useButtonDisabled(tabs)
  const { getMenuOptions, options } = useMenuOptions()

  const playLoader = useCallback(
    (message = 'Cargando...') => {
      dispatch(changeLoaderItems({ show: true, mensaje: message }))
    },
    [dispatch],
  )

  const stopLoader = useCallback(() => {
    dispatch(changeLoaderItems({ show: false, mensaje: '' }))
  }, [dispatch])

  const onTabClosing = useCallback(
    async (index) => {
      const closedTab = tabs.tabs[index]
      dispatch(closeTabMultistore(closedTab.tabKey ?? index))
      dispatch(deleteEditData(closedTab.tabKey ?? index))
    },
    [dispatch, tabs.tabs],
  )

  const onTabChanged = useCallback(
    (tabKey: string) => {
      dispatch(changeCurrentTab(tabKey))
    },
    [dispatch],
  )

  const onConfirmToEdit = useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea editar la información del item ${selectedItem.itemBarras}?`,
      actions: [
        Dialog.Action(
          <span>
            <u>A</u>ceptar
          </span>,
          (dialog) => {
            dialog.hide()
            dispatch(setCurrentFunction(ButtonTypes.edit))
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setConfirmToEdit(false)
    return
  }, [dispatch, selectedItem])

  useEffect(() => {
    if (deleteItem === true) {
      setDeleteItem(false)
      dispatch(setCurrentFunction(ButtonTypes.delete))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteItem])

  const onConfirmarBorrar = useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea eliminar toda la informacipon del item ${selectedItem.itemBarras}?`,
      actions: [
        Dialog.Action(
          <span>
            <u>A</u>ceptar
          </span>,
          (dialog) => {
            dialog.hide()
            setDeleteItem(true)
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setDeleteItem(false)
    setConfirmToRemove(false)
    return
  }, [selectedItem])

  const onOpenTabToClone = useCallback(() => {
    const id = crypto.randomUUID()
    dispatch(
      initDatosEdicion({
        key: id,
        data: { ...defaultDataItem },
      }),
    )
    dispatch(
      openTab({
        key: id,
        editStatus: StatesEdition.edit,
        item: {
          ...selectedItem,
          codigo: selectedItem.itemCodigoUsuario,
          barras: selectedItem.itemBarras,
          codigoInterno: selectedItem.itemCodigo,
        },
      }),
    )
  }, [dispatch, selectedItem])

  useEffect(() => {
    if (cloneItem === true) {
      setCloneItem(false)
      onOpenTabToClone()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cloneItem])

  const onConfirmToClone = useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea duplicar la información del item ${selectedItem.itemBarras}?`,
      actions: [
        Dialog.Action(
          <span>
            <u>A</u>ceptar
          </span>,
          (dialog) => {
            dialog.hide()
            setCloneItem(true)
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setConfirmToClone(false)
    setCloneItem(false)
    return
  }, [selectedItem?.itemBarras])

  const onOpenTabToUnify = useCallback(() => {
    dispatch(
      openTabUnificar({
        key: crypto.randomUUID(),
        type: TabTypes.unificar,
      }),
    )
  }, [dispatch])

  const onConfirmToUnify = useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Está seguro que desea unificar la información del Ítem ${selectedItem.itemBarras}?`,
      actions: [
        Dialog.Action(
          <span>
            <u>A</u>ceptar
          </span>,
          (dialog) => {
            dialog.hide()
            onOpenTabToUnify()
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setConfirmToUnify(false)
    return
  }, [onOpenTabToUnify, selectedItem])

  const onHandleSyncWoo = useCallback(async () => {
    try {
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'BUSQUEDA',
          buttonType: ButtonTypes.save,
        }),
      )
      playLoader('Sincronizando WooCommerce . . .')
      const syncWooItem: SyncWoo = {
        infoRegistro: {
          codigoUsuario: searchFilter.codigo ?? '',
          barras: searchFilter.barras ?? '',
          descripcion: searchFilter.nombre ?? '',
          generico: searchFilter.generico ?? '',
          linea: searchFilter.linea ?? '',
          grupo: searchFilter.grupo ?? '',
          marca: searchFilter.marca ?? '',
          aplicaciones: searchFilter.aplicacion ?? '',
          tipo: searchFilter.tipoItem?.value ?? 0,
          impuesto: searchFilter.tipoImpuesto?.valor ?? 0,
          nota: '',
          peso: 0,
          tags: '',
          estado: searchFilter.estadoItem?.value ?? 0,
          pvdIva: searchFilter.precioDesde ?? 0,
          pvpIva: searchFilter.precioHasta ?? 0,
          incremental: searchFilter.busquedaIncremental === true ? 1 : 0,
          mobile: searchFilter.movilweb?.value ?? 0,
          codigoTecnologia: searchFilter.codProv ?? '',
          modeloBom: searchFilter.modelo ?? '',
          busqueda: searchFilter.inicioFinOpt.value === 'f' ? 1 : 0,
          jerarquia:
            searchFilter.agrupador?.value > 0
              ? searchFilter.agrupador?.value
              : 0,
          idwoo: searchFilter.noSincronizados === true ? 1 : 0,
          imagen: searchFilter.imagen?.value ?? '',
        },
      }
      const sycWooItems = await ItemsServices.syncItemWoo(syncWooItem)
      stopLoader()
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'BUSQUEDA',
          buttonType: undefined,
        }),
      )
      if (!sycWooItems.error && sycWooItems.auto)
        setToastMessage(
          'Guardar Unificar Item',
          sycWooItems.message ??
            'Item sincronizado con woocommerce correctamente',
          ToastTypes.Success,
        )
      else
        setToastMessage(
          'Sincronizar WooCommerce',
          sycWooItems.message ?? 'Error al al sincronizar woocommerc',
          ToastTypes.Warning,
        )
    } catch (error) {
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'BUSQUEDA',
          buttonType: undefined,
        }),
      )
      stopLoader()
      setToastMessage(
        'Sincronizar WooCommerce',
        JSON.stringify(error) ?? 'Error al sincronizar woocommerce',
        ToastTypes.Danger,
      )
    }
  }, [dispatch, playLoader, searchFilter, setToastMessage, stopLoader])

  useEffect(() => {
    if (wooItem === true) {
      setWooItem(false)
      onHandleSyncWoo()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wooItem])

  const onConfirmToSyncWoo = useCallback(() => {
    const store: string =
      searchFilter.local?.codigo === -1
        ? 'de TODOS LOS LOCALES'
        : `del local ${searchFilter.local?.nombre}`
    dialogRef.current.show({
      title: 'Ácatha',
      body: `Esta a punto de sincronizar todos los items configurados para Móvil o Web Store ${store}. Desea continuar??`,
      actions: [
        Dialog.Action(
          <span>
            <u>A</u>ceptar
          </span>,
          (dialog) => {
            dialog.hide()
            setWooItem(true)
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setWooItem(false)
    setConfirmToSyncWoo(false)
    return
  }, [searchFilter])

  useEffect(() => {
    if (confirmToSyncWoo) onConfirmToSyncWoo()
  }, [confirmToSyncWoo, onConfirmToSyncWoo])

  useEffect(() => {
    if (confirmToUnify) onConfirmToUnify()
  }, [confirmToUnify, onConfirmToUnify])

  useEffect(() => {
    if (confirmToClone) onConfirmToClone()
  }, [confirmToClone, onConfirmToClone])

  useEffect(() => {
    if (confirmToEdit) onConfirmToEdit()
  }, [confirmToEdit, onConfirmToEdit])

  useEffect(() => {
    if (confirmToRemove) onConfirmarBorrar()
  }, [confirmToRemove, onConfirmarBorrar])

  const onParseData = useCallback((listItems: Array<ItemsListResult>) => {
    let itemsList: Array<any> = []
    itemsList = listItems.map((item: ItemsListResult) => {
      return {
        barras: item.itemBarras ?? '',
        descripcion: item.itemDescripcion ?? '',
        generico: item.itemGenerico ?? '',
        linea: item.itemLineaDescripcion ?? '',
        grupo: item.itemGrupoDescripcion ?? '',
        marca: item.itemMarcaDescripcion ?? '',
        stock: item.itemExistencia ?? 0,
        costo: item.itemCosto ?? 0,
        utilidad: item.itemGananciaPVP ?? 0,
        precio: item.itemPVPIVA ?? 0,
        total: item.itemExistencia * item.itemPVPIVA ?? 0,
      }
    })
    return itemsList
  }, [])

  const onHandleUndo = useCallback(() => {
    dispatch(setFilter(initialFilter))
    dispatch(setTaxFilter({ tax: null }))
    dispatch(setStoreFilter({ store: null }))
  }, [dispatch])

  const onHandleBroom = useCallback(() => {
    dispatch(setCleanResult())
    dispatch(setSelectedItem(null))
    dispatch(setTotalCount(0))
    dispatch(setSearchItem(false))
    dispatch(
      setButtons({
        tabKey: 'BUSQUEDA',
        buttons: {
          Nuevo: true,
          Buscar: true,
          Imprimir: false,
          Limpiar: true,
          Woocommerce: true,
          Importar: true,
          Armar_Kit: true,
          Editar_Precio: false,
          Desactivar_Item: false,
          Pantalla_Completa: true,
          Exportar: false,
          Editar: false,
          Eliminar: false,
          Duplicar: false,
          Unificar: false,
          Codigo_Barras: false,
          Deshacer: true,
        },
      }),
    )
  }, [dispatch])

  const onMenuButtonClick = useCallback(
    async (action: AccionMenu | { actionType: string }) => {
      const id = uuidv4()
      switch (action.actionType) {
        case ButtonTypes.find:
          if (tabs.current === 'BUSQUEDA')
            dispatch(setCurrentFunction(ButtonTypes.find))
          else dispatch(changeCurrentTab('BUSQUEDA'))
          break
        case ButtonTypes.new:
          if (tabs.current === 'BUSQUEDA') {
            const dataItem = structuredClone(defaultDataItem)
            dataItem.generales.proyecto = {
              codigo: local.codigo ?? -1,
              siglasLocal: '',
            }
            dispatch(
              initDatosEdicion({
                key: id,
                data: dataItem,
              }),
            )
            dispatch(openTab({ key: id }))
          } else dispatch(setCurrentFunction(ButtonTypes.new))
          break
        case ButtonTypes.save:
          if (tabs.current !== 'BUSQUEDA')
            dispatch(setCurrentFunction(ButtonTypes.save))
          break
        case ButtonTypes.edit:
          if (tabs.current === 'BUSQUEDA') setConfirmToEdit(true)
          break
        case ButtonTypes.delete:
          if (tabs.current === 'BUSQUEDA') setConfirmToRemove(true)
          break
        case ButtonTypes.clone:
          if (tabs.current === 'BUSQUEDA') setConfirmToClone(true)
          break
        case ButtonTypes.unify:
          if (tabs.current === 'BUSQUEDA') setConfirmToUnify(true)
          break
        case ButtonTypes.woocommerce:
          if (tabs.current === 'BUSQUEDA') setConfirmToSyncWoo(true)
          break
        case ButtonTypes.import:
          if (tabs.current === 'BUSQUEDA')
            dispatch(setCurrentFunction(ButtonTypes.import))
          break
        case ButtonTypes.fullScreen:
          if (tabs.current === 'BUSQUEDA') setShowFullScreen(true)
          break
        case ButtonTypes.editPrice:
          if (tabs.current === 'BUSQUEDA') setShowEditPrice(true)
          break
        case ButtonTypes.assembleKit:
          if (tabs.current === 'BUSQUEDA') setShowAssemblyKit(true)
          break
        case ButtonTypes.deactivateItem:
          if (tabs.current === 'BUSQUEDA')
            dispatch(setCurrentFunction(ButtonTypes.deactivateItem))
          break
        case ButtonTypes.print:
          if (tabs.current === 'BUSQUEDA') setPdfReport('Viewer')
          break
        case ButtonTypes.export:
          if (tabs.current === 'BUSQUEDA')
            dispatch(setCurrentFunction(ButtonTypes.export))
          break
        case ButtonTypes.barCode:
          if (tabs.current === 'BUSQUEDA') setShowBarCode(true)
          break
        case ButtonTypes.broom:
          if (tabs.current === 'BUSQUEDA') {
            dispatch(setCurrentFunction(ButtonTypes.broom))
            onHandleBroom()
          }
          break
        case ButtonTypes.undo:
          if (tabs.current === 'BUSQUEDA') onHandleUndo()
          else dispatch(setCurrentFunction(ButtonTypes.undo))
          break
        default:
          break
      }
      dispatch(clearButtonClick(tabs.current))
    },
    [dispatch, tabs, local, onHandleBroom, onHandleUndo],
  )

  const onParseButtons = useCallback(() => {
    const acctionButtons = structuredClone(options)
    acctionButtons.forEach((action: AccionMenu) => {
      if (action.nombre === 'Armar kit')
        action.actionType = ButtonTypes.assembleKit
      else if (action.nombre === 'Editar precios de items seleccionados')
        action.actionType = ButtonTypes.editPrice
      else if (action.nombre === 'Pantalla completa')
        action.actionType = ButtonTypes.fullScreen
      else if (action.nombre === 'Codigo de barras')
        action.actionType = ButtonTypes.barCode
      else if (action.nombre === 'Deshabilitar items')
        action.actionType = ButtonTypes.deactivateItem
    })
    const indexDuplicate = acctionButtons.findIndex((button: AccionMenu) => {
      return button.nombre === 'Duplicar'
    })
    if (acctionButtons.length >= 5 && indexDuplicate > 4) {
      const button = acctionButtons[4]
      const duplicateButton = acctionButtons[indexDuplicate]
      acctionButtons[4] = duplicateButton
      acctionButtons[indexDuplicate] = button
    }
    setOptionsMenu(acctionButtons)
  }, [options])

  useEffect(() => {
    if (menuState.length > 0 && options.length === 0)
      getMenuOptions(menuState, 'to', pathVerificador)
  }, [menuState, getMenuOptions, options])

  useEffect(() => {
    if (options.length > 0 && optionsMenu.length === 0) onParseButtons()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options])

  useEffect(() => {
    if (tabs.tabs[tabs.current].globalButtonClick !== ButtonTypes.none)
      onMenuButtonClick({
        actionType: tabs.tabs[tabs.current].globalButtonClick,
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs.tabs[tabs.current].globalButtonClick])

  useEffect(() => {
    dispatch(
      setButtons({
        tabKey: 'BUSQUEDA',
        buttons: {
          Nuevo: true,
          Buscar: true,
          Imprimir: totalCountItems > 0,
          Limpiar: true,
          Woocommerce: true,
          Importar: true,
          Armar_Kit: true,
          Editar_Precio: totalCountItems > 0,
          Desactivar_Item: totalCountItems > 0,
          Pantalla_Completa: true,
          Exportar: totalCountItems > 0,
          Deshacer: true,
        },
      }),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCountItems])

  const optionsForm = () => {
    if (optionsMenu.length > 0) {
      return (
        <>
          <GlobalMenu
            printButtonAsDropdown={false}
            showExportDesingOptions
            acciones={optionsMenu}
            onClick={onMenuButtonClick}
            getButtonDisabled={getButtonDisabled}
            currentWorking={tabs.tabs[tabs.current].currentExecutingAction}
          />
        </>
      )
    } else {
      return <> </>
    }
  }

  const bodyForm = () => {
    return (
      <TabControl
        tabsState={tabs}
        onTabChanged={onTabChanged}
        ontabClosing={onTabClosing}
        tipoAplicacion={Aplicacion.items}
      />
    )
  }

  return (
    <>
      {pdfReport !== null && (
        <ReportWithLocalDataModal
          show={pdfReport !== null}
          onClose={() => setPdfReport(null)}
          data={onParseData(listItems) ?? []}
          fileName="StaticItemsList"
          mode={pdfReport ?? 'Viewer'}
          parameters={{ Reporte_Filtro: '' }}
          template="StaticItemsList"
          key="reportDesignerItems"
        />
      )}
      {showBarCode && (
        <ModalCodigoBarras
          show={showBarCode}
          onClose={() => setShowBarCode(false)}
          storeCode={local?.codigo ?? -1}
          barCode={selectedItem?.itemBarras ?? ''}
          pvpIva={selectedItem?.itemPVPIVA ?? 0}
          pvdIva={selectedItem?.itemPVDIVA ?? 0}
          descripcion={selectedItem?.itemDescripcion ?? ''}
        />
      )}
      {showAssemblyKit && (
        <ModalArmarKit
          show={showAssemblyKit}
          onClose={() => setShowAssemblyKit(false)}
        />
      )}
      {showEditPrice && (
        <ModalEditarPrecios
          show={showEditPrice}
          onClose={() => {
            setShowEditPrice(false)
          }}
        />
      )}
      {showFullScreen && (
        <ModalFullScreen
          show={showFullScreen}
          onClose={() => {
            setShowFullScreen(false)
          }}
        />
      )}
      <div id={'invItems'}>
        <Dialog ref={dialogRef} />
        <CardContent
          childrenOptions={optionsForm()}
          childrenBody={bodyForm()}
          headerTitle={'Items'}
          aplicacion={'Items'}
          loader={loader}
        />
      </div>
    </>
  )
}

export default memo(Items)

export const ModuleButtons: CustomButtons = {
  Nuevo: true,
  Guardar: true,
  Editar: false,
  Eliminar: false,
  Duplicar: false,
  Unificar: false,
  Buscar: true,
  Limpiar: false,
  Exportar: false,
  Imprimir: false,
  Woocommerce: false,
  Auditoria: false,
  Deshacer: true,
}

export const defaultDataItem: ItemEditData = {
  loaded: false,
  saved: false,
  codigoInterno: 0,
  barras: '',
  codigo: '',
  nombre: '',
  nombreGenerico: '',
  numKardex: 0,
  generales: { ...defaultGeneralsItem },
  preciosControl: { ...defaultPreciosControl },
  cuentasContables: { ...defaultCuentasContables },
  existencias: { ...defaultExistencia },
  proveedores: { ...defaultProveedores },
  imagenes: [...defaultImagenes],
  aplicaciones: defaultAplicaciones,
  activos: { ...defaultActivos },
  partes: defaultPartes,
  promociones: { ...defaultPromociones },
  historicos: { ...defaultHistoricos },
  extras: { ...defaultExtras },
  observaciones: { ...defaultObservaciones },
  atributos: { ...defaultAtributos },
  backup: null,
}
