import { DateUtils, formatoFechasApi } from "../../../../helpers/dateUtils";
import { RequestHelper } from "../../../../helpers/requestHelper";
import { SesionService } from "../../../../services/sesion.service";
import { OptionRelations } from "../types/types";

export const RelationsService = { getRelations };

async function getRelations(): Promise<any> {
  const sesion = SesionService.getCurrentSesion();
  try {
    const data: any = {
      codigo: sesion?.empresa?.codigo
    }
    const keysToCache: any = {
      ...data,
      dateKey: DateUtils.getCurrentDateAsString(formatoFechasApi),
      empresa: sesion?.empresa?.codigo,
    }
    const cacheKey = 'tipoRelaciones' + JSON.stringify(keysToCache);
    const cached = sessionStorage.getItem(cacheKey);
    if (cached) {
      return { error: false, auto: JSON.parse(cached) };
    } else {
      const tipos: any = await RequestHelper.getAll('nomina/contratos', 'rolparams/loadRelations', "", data);
      const opt: Array<OptionRelations> = []
      if (tipos?.auto && tipos?.auto.length > 0) {
        for (const x of tipos.auto) {
          opt.push(x)
        }
      }
      sessionStorage.setItem(cacheKey, JSON.stringify(opt));
      tipos.auto = opt ?? []
      return tipos;
    }
  } catch (error) {
    return error;
  }
}

