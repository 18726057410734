import { Duration, format as dateFormat, parse } from 'date-fns'
import { add as dateAdd } from 'date-fns'
export const DateUtils = {
  getCurrentDateAsString,
  apiDateToPickersDate,
  pickersDateToApiDate,
  strDateToDate,
  dateToString,
  add,
  format,
  dateBoxToApiDate,
  getCurrentTime,
}

export const formatoFechasApi = 'dd/MM/yyyy'
export const formatoFechasDatePickers = 'yyyy-MM-dd'
//export const formatoAnioFiscal = 'MM/yyyy';
function getCurrentDateAsString(format: string = 'yyyy-MM-dd'): string {
  return dateToString(new Date(), format)
  //return moment().format(format)
}

function apiDateToPickersDate(apiDate: string): string {
  const returnDate = parse(apiDate, formatoFechasApi, new Date())
  return dateFormat(returnDate, formatoFechasDatePickers) //moment(apiDate, formatoFechasDatePickers).format(formatoFechasApi);
  //return moment(apiDate, formatoFechasApi).format(formatoFechasDatePickers);
}

function pickersDateToApiDate(pickersDate: string): string {
  //const data = moment(apiDate, formatoFechasDatePickers);
  const returnDate = parse(pickersDate, formatoFechasDatePickers, new Date())
  return dateFormat(returnDate, formatoFechasApi) //moment(apiDate, formatoFechasDatePickers).format(formatoFechasApi);
}

function strDateToDate(
  dateString: string,
  format: string = 'yyyy-MM-dd',
): Date {
  const date = parse(dateString, format, new Date())
  return date
}

function dateToString(date: Date, formatString: string = 'yyyy-MM-dd'): string {
  return dateFormat(date, formatString)
}

function add(date: Date, duration: Duration): Date {
  return dateAdd(date, duration)
}

function format(
  date: string | Date,
  formatStr: string,
  inputFormat: string = formatoFechasDatePickers,
): string {
  if (typeof date == 'string') {
    const _date = strDateToDate(
      date,
      inputFormat ? inputFormat : formatoFechasDatePickers,
    )
    return dateFormat(_date, formatStr)
  } else {
    return dateFormat(date, formatStr)
  }
}

/**
 * Convierte una fecha en formato de cadena 'Tue Sep 26 2023 00:00:00 GMT-0500'
 * a formato 'dd/mm/aaaa'.
 * @param {string} dateString - La cadena de fecha en formato de entrada.
 * @returns {string} La cadena de fecha en formato 'dd/MM/yyyy'.
 */
function dateBoxToApiDate(dateString: string): string {
  const date = new Date(dateString)
  const day = date.getDate().toString().padStart(2, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const year = date.getFullYear().toString()
  const formattedDate = `${day}/${month}/${year}`
  return formattedDate
}

function getCurrentTime(): string {
  const now = new Date()
  const hours = now.getHours()
  const minutes = now.getMinutes()
  const seconds = now.getSeconds()

  // Formatear horas, minutos y segundos con dos dígitos
  const formattedHours = hours.toString().padStart(2, '0')
  const formattedMinutes = minutes.toString().padStart(2, '0')
  const formattedSeconds = seconds.toString().padStart(2, '0')

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
}
