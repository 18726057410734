import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  DocumentoOrigenFacturacion,
  TTotalesFactura,
} from '../../../pages/ventas/types/types'
import {
  seleccionarSesion,
  setLoadSummaryDocs,
} from '../../../../../store/reducers'
import { RootState } from '../../../../../store/store'
import {
  AutorizacionDocumentoVenta,
  Cliente,
  InfoDocumentoVentaAfectar,
  ItemVenta,
  VentaListado,
} from '../../../types/types'
import { VentasService } from '../../../services/ventas.service'
import { ClientesService } from '../../../services/clientes.service'
import { isMobile, isMobileOnly } from 'react-device-detect'
import { VentaHelper } from '../../../pages/ventas/ventaHelper'
import { StatesEdition } from '../../../types/enums'
import config from '../../../../../config/config'
import { addToast } from '../../../../../store/toasterReducer'
import { TiposComprobantesSLV, TiposComprobantesSri, ToastTypes } from '../../../../../store/types'
import {
  DetalleVenta,
  DetalleVentaRedux,
} from '../../../pages/ventas/types/detalleVenta'
import { ButtonTypes } from '../../../../../views/componentes/globalMenu/types'
import { VerRIDE } from '../../verRide/verRide'
import AgregarDetalleVentaMobile from '.././../ventas/venta/agregarDetalleVentaMobile'
import ModificaItem from '../../items/modificarItem'
import MainModalItems from '../../items/busquedaItemsModal/MainModalItems'
import RowContainer from '../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../views/componentes/colContainer'
import Labeled from '../../../../../views/componentes/labeledInput/labeledInput'
import { CBadge, CButton, CCol, CRow } from '@coreui/react-pro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-light-svg-icons'
import Barcode from '../../../../../views/componentes/barcode/barcode'
import MUIDataTable from 'mui-datatables'
import {
  getColumnnasTablaDetalleVentas,
  OptionsTablaDetalleVentas,
} from './helper'
import SelectBox from '../../../../../views/componentes/selectBox/selectBox'
import { CustomButtons, DocumentInfo, TabState } from '../../../types/generics'
import { v4 as uuidv4 } from 'uuid'
import { CustomSheet } from '../../../../../views/componentes/customSheet/customSheet'
import { DataSheetColumn } from '../../../../../views/componentes/customSheet/dataSheetColumn'
import { CustomSheetTextEditor } from '../../../../../views/componentes/customSheet/editors/textEditor'
import { CustomSheetNumberEditor } from '../../../../../views/componentes/customSheet/editors/numberEditor'
import CustomSheetNumberViewer from '../../../../../views/componentes/customSheet/viewers/sheetNumberViewer'
import { CellChangedArgs } from '../../../../../views/componentes/customSheet/cellChangedArgs'
import { RowCommand } from '../../../../../views/componentes/customSheet/genericRow'
import { SheetDescripcionItemSelectEditor } from '../../ventas/sheetEditors/sheetDescripcionItemSelectEditor'
import { SheetTextEditor } from '../../ventas/sheetEditors/sheetTextEditor'
import { defaultDatosEdicionFactura } from '../../ventas/venta/venta'
import {
  changeEditStatus,
  clearButtonClick,
  closeTab,
  setButtons,
  setCurrentExecutingAction,
} from '../../../pages/notasDebito/store/tabsReducer'
import {
  clearDatosEdicion,
  setDatosEdicion,
  setEditLoader,
  updateCliente,
  updateDetalles,
  updateObservaciones,
  updateTotales,
  updateDocumentoOrigen,
  setMuestraError,
  setClaveAccesoGuardada,
  addDetalle,
  setFormaPago,
  setImprimir,
  updateFechaEmision,
} from '../../../pages/notasDebito/store/editDataReducer'
import DocumentoVentaAfectar from '../../busquedaDocumentoVentaAfectar/documentoVentaAfectar'
import VisualizaError from '../../../pages/shared/visualizaError/visualizaError'
import {
  InfoNotaDebitoDetalleIngresar,
  TNotaDebitoIngresar,
} from '../../../pages/notasDebito/types/types'
import {
  DateUtils,
  formatoFechasApi,
  formatoFechasDatePickers,
} from '../../../../../helpers/dateUtils'
import BuscarClienteLookUp from '../../busquedaCliente/busquedaCliente'
import LoadingIndicator from '../../../../../views/componentes/loadingindicator/loadingindicator'
import Button from 'devextreme-react/button'
import PopupReenvioMail from '../../ventas/busquedaVentas/popupReenvioMail/index'
import DateBox from 'devextreme-react/date-box'
import TextBox from 'devextreme-react/text-box'
import TextArea from 'devextreme-react/text-area'
import { ComprobantesService } from '../../../../../containers/component/infoEmergente/comprobantes.service'
import { lh, MessagesKeys } from '../../../../../helpers/localizationHelper'
import Dialog from '../../../../../views/componentes/librerias/bootstrap-dialog'
import BlockUi from '../../../../../views/componentes/librerias/block-ui'
import { useNavigate } from 'react-router-dom'

interface INotaCreditoProps extends React.PropsWithChildren {
  info: DocumentInfo<VentaListado>
  tabId: string
  tab: TabState<VentaListado>
}

const NotaDebito: React.FunctionComponent<INotaCreditoProps> = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { tabId, info, tab } = props
  const sesion = useSelector(seleccionarSesion)
  const usuario = useSelector((state: RootState) => {
    return state.global.session?.usuario
  })
  const empresa = useSelector((state: RootState) => {
    return state.global.session?.empresa
  })
  const local = useSelector((state: RootState) => {
    return state.global.session?.local
  })
  const datosEdicion = useSelector((state: RootState) => {
    return state.ventas.notasDebito.editData[tabId]
  })
  const claveAcceso = useSelector((state: RootState) => {
    return state.ventas.notasDebito.editData[tabId].claveAcceso
  })
  const detalles = useSelector((state: RootState) => {
    return state.ventas.notasDebito.editData[tabId].detalles
  })
  const loading = useSelector((state: RootState) => {
    return state.ventas.notasDebito.editData[tabId].loading
  })
  const cliente = useSelector((state: RootState) => {
    return state.ventas.notasDebito.editData[tabId].cliente
  })
  const fecha = useSelector((state: RootState) => {
    return state.ventas.notasDebito.editData[tabId].fecha
  })
  const autorizacionDocumentoVenta = useSelector((state: RootState) => {
    return state.ventas.notasDebito.editData[tabId].autorizacionDocumentoVenta
  })
  const puntoVenta = useSelector((state: RootState) => {
    return state.global.puntoVenta
  })
  const formasPago = useSelector((state: RootState) => {
    return state.ventas.notasDebito.editData[tabId].formasPago
  })
  const observaciones = useSelector((state: RootState) => {
    return state.ventas.notasDebito.editData[props.tabId].observaciones
  })
  const iva = useSelector((state: RootState) => {
    return state.ventas.notasDebito.editData[props.tabId].iva
  })
  const cuotasVencidas = useSelector(
    (state: RootState) => state.suscripcionUpgrate.cuotasVencidas,
  )

  const dialogRef = React.useRef<any>(null)
  const [filaEliminar, setFilaEliminar] = React.useState(-1)
  const [agregarDetalleMovil, setAgregarDetalleMovil] = React.useState(false)
  const [mostrarEdicionItems, setMostrarEdicionItems] = React.useState(false)
  const [mostrarBusquedaItems, setMostrarBusquedaItems] = React.useState(false)
  const [codigoBarrasItemEditar, setCodigoBarrasItemEditar] = React.useState('')
  const [verFacturaPdf, setVerFacturaPdf] = React.useState<{
    claveAcceso: string
    design: boolean
  }>()
  const [verSeleccionDocumentos, setVerSeleccionDocumentos] =
    React.useState(false)
  const [showPopupCustomer, setShowPopupCustomer] =
    React.useState<boolean>(false)
  const [showReenviarMail, setShowReenviarMail] = React.useState<boolean>(false)
  const [mostrarAnular, setMostrarAnular] = React.useState(false)

  const sheetCommands = React.useMemo(() => {
    const commads: Array<RowCommand<DetalleVentaRedux>> = []
    commads.push({
      id: 'Eliminar',
      text: 'Eliminar',
      icon: 'minus',
      onExecute: (rowIndex) => {
        setFilaEliminar(rowIndex)
      },
    })
    commads.push({
      id: 'Editar',
      text: 'Editar',
      icon: 'edit',
      onExecute: (rowIndex) => {
        setMostrarEdicionItems(true)
        setCodigoBarrasItemEditar(detalles[rowIndex].codigoBarras)
      },
    })
    return commads
  }, [detalles])

  const showEditLoader = React.useCallback(
    (mensaje: string) => {
      dispatch(
        setEditLoader({
          key: tabId,
          info: {
            mensaje: mensaje,
            show: true,
          },
        }),
      )
    },
    [dispatch, tabId],
  )

  const hideEditLoader = React.useCallback(() => {
    dispatch(
      setEditLoader({
        key: tabId,
        info: {
          mensaje: '',
          show: false,
        },
      }),
    )
  }, [dispatch, tabId])

  const cambiarDocumentoOrigenFacturacion = React.useCallback(
    async (documento: DocumentoOrigenFacturacion) => {
      showEditLoader('Cargando documento...')
      const _venta = await VentasService.getVenta(documento.comprobante)
      if (_venta) {
        const cliente = await ClientesService.getCliente(
          _venta?.clienteIdentificacion,
        )
        const _detallesApi = await VentasService.getDetalleVenta(
          _venta.codigo,
          TiposComprobantesSri.Factura,
        )
        const _detalles = VentaHelper.detallesToDetallesVenta(
          _detallesApi,
          empresa?.precision ?? 4,
        )
        const formasPago = await VentasService.getFormasPago(
          cliente.identificacion,
        )
        const formaPago = formasPago.find(
          (fp) => fp.codigo === _venta.formaPago,
        )
        let mostrarAlertaDescuento = false
        for (let index = 0; index < _detalles.length; index++) {
          const _detalle = _detalles[index]
          if (_detalle._descuento > 0) {
            mostrarAlertaDescuento = true
          }
          const tmpDetallle = new DetalleVenta()
          tmpDetallle.setDetalle(_detalle, empresa?.precision ?? 4)
          tmpDetallle.porcentajeDescuento = 0
          _detalles[index] = tmpDetallle.getDetalle()
        }
        if (mostrarAlertaDescuento) {
          dispatch(
            addToast({
              title: 'Acatha',
              content: 'Tipo de documento no admite descuentos',
              type: ToastTypes.Danger,
            }),
          )
        }
        dispatch(
          updateDocumentoOrigen({
            key: tabId,
            documento: documento,
            cliente: cliente,
            detalles: _detalles,
            formaPago: formaPago,
            formasPago,
          }),
        )
      }
      hideEditLoader()
    },
    [dispatch, tabId, empresa, showEditLoader, hideEditLoader],
  )

  const cargarVenta = React.useCallback(
    async (numero: number, setInitData: boolean) => {
      if (datosEdicion.imprimir) {
        setVerFacturaPdf({
          claveAcceso: datosEdicion.claveAcceso,
          design: false,
        })
      }
      if (loading === false) {
        return
      }
      const data = { ...defaultDatosEdicionFactura }
      try {
        if (setInitData)
          dispatch(
            setDatosEdicion({
              data: { ...data },
              key: tabId,
            }),
          )
        dispatch(
          setCurrentExecutingAction({
            tabKey: tabId,
            buttonType: ButtonTypes.find,
          }),
        )
        const venta = await VentasService.getVenta(numero)
        if (venta) {
          const detalles = await VentasService.getDetalleVenta(
            numero,
            TiposComprobantesSri.NotaCredito,
          )
          const cliente = await ClientesService.getCliente(
            venta.clienteIdentificacion,
          )
          const formasPago = await VentasService.getFormasPago(
            cliente.identificacion,
          )
          const iva = await VentasService.getIva(venta.fecha, null)
          data.iva = iva
          data.establecimiento = venta.establecimiento
          data.puntoEmision = venta.puntoEmision
          data.numerofactura = venta.numero
          data.observaciones = venta.observaciones
          data.formasPago = formasPago
          const formaPago = formasPago.find((x) => x.codigo === venta.formaPago)
          if (formaPago) {
            data.formaPago = [formaPago]
          }
          data.cliente = cliente
          const tmpDet2 = VentaHelper.detallesToDetallesVenta(
            detalles,
            empresa?.precision ?? 4,
          )
          data.detalles = tmpDet2
          const fechaAutorizacion = venta.claveAccesoFecha ?? ''
          data.fechaAutorizacion =
            fechaAutorizacion === '' ? 'NO AUTORIZADO' : fechaAutorizacion
          data.autorizacion = venta.claveAcceso ?? ''
          data.autorizacionVenta = await VentasService.getAutorizacion(
            puntoVenta ?? '',
            TiposComprobantesSri.NotaDebito,
          )
          data.claveAcceso = venta.claveAcceso ?? ''
          data.fecha = DateUtils.apiDateToPickersDate(venta.fecha)
          data.descuentoGlobal = 0
          data.loader = {
            mensaje: '',
            show: false,
          }
          data.loading = false
          if (venta.ventaAfectaNumero) {
            data.documentoOrigen = {
              comprobante: 0,
              tipoDoc: '01',
              fechaEmision: '',
              fechaNegociacion: '',
              numero: `${venta.ventaAfectaEstablecimiento}-${venta.ventaAfectaPtoEmision}-${venta.ventaAfectaNumero}`,
              totalDocumento: venta.ventaAfectaTotal ?? 0,
              formaPagoCodigo: venta.ventaAfectaFormaPagoCodigo ?? 0,
            }
          }
          dispatch(
            setDatosEdicion({
              data: data,
              key: tabId,
            }),
          )
          dispatch(
            setButtons({
              tabKey: tabId,
              buttons: {
                ...DebitNoteButtons,
                Guardar: false,
                Autorizar: venta.claveAccesoFecha === '',
                Enviar: venta.claveAccesoFecha !== '',
                Deshacer: false,
                Descuento: false,
              },
            }),
          )
        }
      } catch (error) {
        data.loading = false
        data.tieneError = true
        data.mensajeError = error as any
        dispatch(
          setDatosEdicion({
            data: data,
            key: tabId,
          }),
        )
      }
    },
    [
      datosEdicion,
      empresa,
      loading,
      dispatch,
      tabId,
      puntoVenta,
    ],
  )

  const modoNuevo = React.useCallback(
    async (puntoVenta: string, limpiar: boolean = false) => {
      if (loading === false && !limpiar) {
        return
      }
      sessionStorage.removeItem('autorizacionModulo')
      const data = { ...defaultDatosEdicionFactura }
      dispatch(
        setButtons({
          tabKey: tabId,
          buttons: {
            ...DebitNoteButtons,
            Editar: false,
            Imprimir: false,
            Exportar: false,
            Autorizar: false,
            Enviar: false,
            Guardar: true,
          },
        }),
      )
      try {
        const fecha = DateUtils.getCurrentDateAsString(formatoFechasDatePickers) //  moment().format('yyyy-MM-DD');
        const formasPago = await VentasService.getFormasPago('9')
        let autorizacion:AutorizacionDocumentoVenta
        if (!autorizacionDocumentoVenta) {
          autorizacion = await VentasService.getAutorizacion(
            puntoVenta,
            TiposComprobantesSri.NotaCredito,
          )
        } else {
          autorizacion = autorizacionDocumentoVenta
        }
        sessionStorage.setItem('autorizacionModulo',
          JSON.stringify(autorizacion),
        )

        const iva = await VentasService.getIva(
          DateUtils.pickersDateToApiDate(fecha),
          autorizacion,
        )
        data.autorizacion = ''
        data.autorizacionVenta = autorizacion
        data.fechaAutorizacion = 'NO AUTORIZADO (NUEVO)'
        data.detalles = []
        data.iva = iva
        data.fecha = fecha
        data.establecimiento = autorizacion.establecimiento
        data.puntoEmision = autorizacion.ptoemision
        data.numerofactura = autorizacion.numActual.toString()
        data.identificadorTransaccion = uuidv4()
        data.observaciones = ''
        data.formasPago = formasPago
        const fp = formasPago.find(
          (fp) => fp.descripcion.toUpperCase() === 'EFECTIVO',
        )
        if (fp) {
          data.formaPago = [fp]
        }
        data.loading = false
        data.loader = {
          mensaje: '',
          show: false,
        }
        dispatch(
          setDatosEdicion({
            data: data,
            key: tabId,
          }),
        )
        setVerSeleccionDocumentos(true)
      } catch (error) {
        data.loading = false
        data.tieneError = true
        data.mensajeError =
          typeof error == 'string' ? error : JSON.stringify(error)
        data.loader = {
          mensaje: '',
          show: false,
        }
      }
    },
    [dispatch, tabId, loading, autorizacionDocumentoVenta],
  )

  const modoEdicion = React.useCallback(() => {
    if (datosEdicion == null || datosEdicion.cliente == null) {
      return
    }
    if (
      datosEdicion.claveAcceso.length === 37 ||
      datosEdicion.claveAcceso.length === 49
    ) {
      dispatch(
        addToast({
          autoHide: true,
          content: 'Una Nota Débito eléctronica no puede ser editada.',
          fade: true,
          id: '',
          title: 'Editar Nota Crédito',
          type: ToastTypes.Info,
        }),
      )
    }
  }, [dispatch, datosEdicion])

  const guardar = React.useCallback(async () => {
    if (
      usuario == null ||
      empresa == null ||
      local == null ||
      puntoVenta == null
    ) {
      return
    }

    if (datosEdicion.detalles.length === 0) {
      dispatch(
        addToast({
          autoHide: true,
          content: 'La Nota de Crédito no tiene detalles',
          fade: true,
          title: 'Guardar',
          type: ToastTypes.Warning,
        }),
      )
      return
    }

    if (datosEdicion.totales.total === 0) {
      dispatch(
        addToast({
          autoHide: true,
          content: 'La Nota de Crédito no puede ser por un valor igual 0',
          fade: true,
          title: 'Guardar',
          type: ToastTypes.Warning,
        }),
      )
      return
    }
    if (!datosEdicion.cliente) {
      dispatch(
        addToast({
          id: '',
          autoHide: true,
          content: 'Debe de seleccionar el cliente',
          fade: true,
          title: 'Guardar',
          type: ToastTypes.Warning,
        }),
      )
      return
    }

    if (
      datosEdicion.formaPago === null ||
      datosEdicion.formaPago.length === 0 ||
      datosEdicion.formaPago[0] == null
    ) {
      dispatch(
        addToast({
          id: '',
          autoHide: true,
          content: 'Debe de seleccionar la forma de pago',
          fade: true,
          title: 'Guardar',
          type: ToastTypes.Warning,
        }),
      )
      return
    }

    try {
      showEditLoader('Guardando Nota de débito...')
      // verifica deudas
      // cuotasVencidas

      let valorSaldoVencidas: number = 0
      if (cuotasVencidas.length > 0) {
        cuotasVencidas.forEach((key: any) => {
          valorSaldoVencidas += key.saldo
        })
        if (valorSaldoVencidas > 0) {
          valorSaldoVencidas =
            Number(parseFloat(valorSaldoVencidas.toFixed(2))) + 0
        }
      }
      if (valorSaldoVencidas > 0) {
        hideEditLoader()
        dispatch(
          addToast({
            autoHide: true,
            content: lh.getMessage(MessagesKeys.GenericPasDue, ''),
            fade: true,
            title: 'Deudas vencidas.',
            type: ToastTypes.Warning,
          }),
        )
        navigate('/past_due_payments')
        return false
      }

      const contrato = await ComprobantesService.getRecurrente(empresa.uuid)
      if (contrato.codigo && contrato.planActivo === false) {
        dispatch(setLoadSummaryDocs(true))
        hideEditLoader()
        dispatch(
          addToast({
            autoHide: true,
            content: lh.getMessage(MessagesKeys.GenericSuscriptionEnd, ''),
            fade: true,
            title: 'Renovar Suscripción.',
            type: ToastTypes.Warning,
          }),
        )
        return false
      }
      dispatch(
        setCurrentExecutingAction({
          tabKey: tabId,
          buttonType: ButtonTypes.save,
        }),
      )
      const notaDebito: TNotaDebitoIngresar = {
        notaDebito: {
          pventa: puntoVenta,
          infoNotaDebito: {
            fechaEmision: DateUtils.pickersDateToApiDate(datosEdicion.fecha),
            codDocModificado: datosEdicion.documentoOrigen?.tipoDoc ?? '',
            numDocModificado: datosEdicion.documentoOrigen?.numero ?? '',
            fechaEmisionDocSustento:
              datosEdicion.documentoOrigen?.fechaEmision ?? '',
            identificacionComprador: datosEdicion.cliente.identificacion,
            impuestos: {
              impuesto: [
                {
                  codigo: '2',
                  codigoPorcentaje: '0',
                  baseImponible: parseFloat(
                    datosEdicion.totales.subtotal0.toFixed(2),
                  ),
                  valor: 0,
                },
                {
                  codigo: '2',
                  codigoPorcentaje: '2',
                  baseImponible: parseFloat(
                    datosEdicion.totales.subtotalIva.toFixed(2),
                  ),
                  valor: parseFloat(datosEdicion.totales.iva.toFixed(2)),
                },
              ],
            },
            totalSinImpuestos: parseFloat(
              datosEdicion.totales.subtotal.toFixed(2),
            ),
            valorTotal: parseFloat(datosEdicion.totales.total.toFixed(2)),
            observaciones: observaciones,
          },
          detalles: {
            detalle: datosEdicion.detalles.map((detalle) => {
              const _detalle: InfoNotaDebitoDetalleIngresar = {
                codigoInterno: detalle.codigoInterno,
                codigoAdicional: detalle.codigoBarras,
                cantidad: detalle._cantidad,
                descripcion: detalle.descripcion,
                precioTotalSinImpuesto: detalle._subtotal,
                precioUnitario: detalle._precioUnitario,
                impuestos: {
                  impuesto: [
                    {
                      codigo: detalle.tieneIva ? 3 : 2,
                      codigoPorcentaje: detalle.tieneIva ? 2 : 0,
                      baseImponible: detalle._subtotal0,
                      tarifa: parseFloat(
                        (detalle.porcentajeiva * 100).toFixed(0),
                      ),
                      valor: detalle._iva,
                    },
                  ],
                },
              }
              return _detalle
            }),
          },
          pagos: [
            {
              medio: datosEdicion.formaPago[0].descripcion,
              total: parseFloat(datosEdicion.totales.total.toFixed(2)),
            },
          ],
        },
      }
      const resultado = await VentasService.ingresarNotaDebito(notaDebito)

      dispatch(
        setClaveAccesoGuardada({
          key: tabId,
          claveAcceso: resultado.claveAcceso,
          imprimir: empresa.imprimeAutomaticamenteAlfacturar ?? false,
        }),
      )

      dispatch(
        changeEditStatus({
          estado: StatesEdition.save,
          tabKey: tabId,
          info: resultado,
          buttons: {
            ...DebitNoteButtons,
            Guardar: false,
            Editar: true,
            Deshacer: false,
            Autorizar: resultado.estadoElectronico !== 'AUTORIZADO',
            Enviar: resultado.estadoElectronico === 'AUTORIZADO',
            Descuento: false,
            Anular: resultado.estadoElectronico === 'AUTORIZADO',
          },
        }),
      )
      dispatch(
        addToast({
          id: '',
          autoHide: true,
          content: 'Guardado',
          fade: true,
          title: 'Guardar',
          type: ToastTypes.Success,
        }),
      )
    } catch (error) {
      console.log(error)
      dispatch(
        addToast({
          id: '',
          autoHide: true,
          content: typeof error === 'string' ? error : JSON.stringify(error),
          fade: true,
          title: 'Guardar',
          type: ToastTypes.Danger,
        }),
      )
      dispatch(
        setCurrentExecutingAction({
          tabKey: tabId,
          buttonType: undefined,
        }),
      )
    }
    hideEditLoader()
  }, [
    dispatch,
    hideEditLoader,
    showEditLoader,
    tabId,
    datosEdicion,
    empresa,
    local,
    puntoVenta,
    usuario,
    cuotasVencidas,
    navigate,
    observaciones,
  ])

  const deshacer = React.useCallback(() => {
    if (info.numero === 0) {
      const tId: any = tabId
      dispatch(closeTab(tId))
      dispatch(clearDatosEdicion(tId))
    } else {
      dispatch(
        changeEditStatus({
          estado: StatesEdition.save,
          tabKey: tabId,
          buttons: { ...DebitNoteButtons },
        }),
      )
      cargarVenta(datosEdicion.numero, false)
    }
  }, [dispatch, tabId, info, cargarVenta, datosEdicion.numero])

  const sheetOnCellsChanged = React.useCallback(
    async (arrayOfChanges: Array<CellChangedArgs<DetalleVentaRedux>>) => {
      const _detalles = detalles.slice(0)
      for (const change of arrayOfChanges) {
        const detalle = new DetalleVenta() //clase que realiza los calculos para un detalle
        detalle.setDetalle(change.rowData, empresa?.precision ?? 4)

        let fecha = ''
        if (detalle.fecha !== '') {
          fecha = DateUtils.pickersDateToApiDate(detalle.fecha)
        } else {
          fecha = DateUtils.getCurrentDateAsString()
        }

        switch (change.col.name) {
          case 'codigoBarras':
            showEditLoader('Buscando item...')
            // eslint-disable-next-line no-case-declarations
            const item = await VentasService.getItemPorCodigoBarras(
              detalle.codigoBarras,
              datosEdicion.cliente?.identificacion ?? config.rucConsumidorFinal,
              fecha,
            )

            if (item) {
              detalle.setItem(item, empresa?.precision ?? 4)
              hideEditLoader()
            } else {
              dispatch(
                addToast({
                  content:
                    'No se encuentra el item con código ' + change.newValue,
                  title: 'Agregar item',
                  type: ToastTypes.Warning,
                  autoHide: 2000,
                }),
              )
              continue
            }
            break
          case 'cantidad':
            detalle.cantidad = change.newValue
            detalle.calcular()
            break
          case 'descripcion':
            if (typeof change.newValue === 'string') {
              detalle.descripcion = change.newValue
            }
            break
          case '_cantidad':
            detalle.cantidad = parseFloat(change.newValue)
            break
          case '_porcentajeDescuento':
            detalle.porcentajeDescuento = parseFloat(change.newValue)
            break
          case '_precioUnitario':
            detalle.precio = parseFloat(change.newValue)
            break
          case '_precioIva':
            detalle.precioIva = parseFloat(change.newValue)
            break
          default:
            break
        }
        if (change.rowIndex === -1) {
          //nueva fila
          _detalles.push(detalle.getDetalle())
        } else {
          _detalles[change.rowIndex] = detalle.getDetalle()
        }
      }
      dispatch(
        updateDetalles({
          detalles: _detalles,
          key: tabId,
        }),
      )
      hideEditLoader()
    },
    [
      dispatch,
      hideEditLoader,
      showEditLoader,
      tabId,
      detalles,
      empresa,
      datosEdicion.cliente,
    ],
  )

  const calcularTotales = React.useCallback(
    (detalles: Array<DetalleVentaRedux>) => {
      const totalCero: TTotalesFactura = {
        descuento: 0,
        descuentoGloabal: 0,
        ice: 0,
        iva: 0,
        subtotal: 0,
        subtotal0: 0,
        subtotalIva: 0,
        total: 0,
      }
      const totales = detalles.reduce((sum, next) => {
        sum.subtotal += next._subtotal
        sum.subtotal0 += next._subtotal0
        sum.subtotalIva += next._subtotalIva
        sum.iva += next._iva
        sum.descuento += 0
        sum.total += next._total
        return sum
      }, totalCero)
      dispatch(
        updateTotales({
          totales: totales,
          key: tabId,
        }),
      )
    },
    [dispatch, tabId],
  )

  const agregarDetalleVentaMobile = React.useCallback(
    (detalle: DetalleVenta) => {
      const _detalles = detalles.slice(0)
      _detalles.push(detalle.getDetalle())
      dispatch(
        updateDetalles({
          key: tabId,
          detalles: _detalles,
        }),
      )
      setAgregarDetalleMovil(false)
    },
    [dispatch, tabId, detalles],
  )

  const cambiarClienteConfirm = React.useCallback(
    (cliente: Cliente, detalles: Array<DetalleVentaRedux>, fecha: string) => {
      dialogRef.current.show({
        title: 'Cambio de cliente',
        body: `¿Desea actualizar el cliente?, esto afectará a los precios de los productos seleccionados actualmente`,
        actions: [
          Dialog.Action(
            <span>
              <u>A</u>ceptar
            </span>,
            async () => {
              showEditLoader('Actualizando precios...')
              for (let index = 0; index < detalles.length; index++) {
                const detalle = detalles[index]
                const _detalle = new DetalleVenta()
                const item = await VentasService.getItemPorCodigoBarras(
                  detalle.codigoBarras,
                  cliente.identificacion,
                  DateUtils.pickersDateToApiDate(fecha),
                )
                if (item) {
                  _detalle.setDetalle(detalle, empresa?.precision ?? 4)
                  _detalle.setItem(item, empresa?.precision ?? 4)
                  detalles[index] = _detalle.getDetalle()
                } else {
                  addToast({
                    content: `No se pudo recuperar el item ${detalle.descripcion}, el item no existe`,
                    title: 'Cambiar precios',
                    type: ToastTypes.Danger,
                  })
                }
              }
              dispatch(updateDetalles({ key: tabId, detalles: detalles }))
              dispatch(
                updateCliente({
                  cliente: cliente,
                  key: tabId,
                }),
              )
              addToast({
                content: `Precios actualizados`,
                title: 'Cambiar precios',
                type: ToastTypes.Success,
              })
              hideEditLoader()
            },
            'btn-success',
            'a',
          ),
          Dialog.Action(
            <span>
              <u>C</u>ancelar
            </span>,
            (dialog) => {
              setShowPopupCustomer(true)
              hideEditLoader()
              dialog.hide()
            },
            'btn-danger',
            'c',
          ),
        ],
        bsSize: 'small',
        onHide: (dialog) => {
          dialog.hide()
        },
      })
      return
    },
    [dispatch, tabId, empresa, hideEditLoader, showEditLoader],
  )

  const cambiarClienteSeleccionado = React.useCallback(
    async (clienteORuc: string | Cliente | null) => {
      if (datosEdicion.detalles.length > 0) {
        if (typeof clienteORuc === 'string') {
          setShowPopupCustomer(false)
          showEditLoader('Recuperando cliente...')
        }
        const cliente =
          typeof clienteORuc === 'string'
            ? await ClientesService.getCliente(clienteORuc)
            : clienteORuc

        if (cliente) {
          cambiarClienteConfirm(
            cliente,
            datosEdicion.detalles.slice(0),
            datosEdicion.fecha,
          )
        }
      } else {
        const cliente =
          typeof clienteORuc === 'string'
            ? await ClientesService.getCliente(clienteORuc)
            : clienteORuc
        if (cliente) {
          dispatch(
            updateCliente({
              cliente: cliente,
              key: tabId,
            }),
          )
        }
      }
    },
    [
      dispatch,
      cambiarClienteConfirm,
      showEditLoader,
      tabId,
      datosEdicion.detalles,
      datosEdicion.fecha,
    ],
  )

  const buscarClienteOnChanged = React.useCallback(
    async (value) => {
      if (value === null || value === undefined) {
        cambiarClienteSeleccionado(config.rucConsumidorFinal)
      } else if (value.codigo) {
        cambiarClienteSeleccionado(value)
      }
    },
    [cambiarClienteSeleccionado],
  )

  const onObservacionesChanged = React.useCallback(
    ({ value }: any) => {
      dispatch(
        updateObservaciones({
          key: tabId,
          observaciones: value ?? '',
        }),
      )
    },
    [tabId, dispatch],
  )

  const muiTableCellsChanged = React.useCallback(
    (row, col, newValue) => {
      const _detalles = detalles.slice(0)
      const _detalle = new DetalleVenta()
      _detalle.setDetalle(_detalles[row], empresa?.precision ?? 4)
      switch (col) {
        case 2: //TABLECOL_DESCRIPCION
          _detalle.descripcion = newValue
          break
        case 3: //TABLECOL_CANTIDAD
          _detalle.cantidad = parseFloat(newValue)
          break
        case 4: //TABLECOL_PRECIO
          _detalle.precio = parseFloat(newValue)
          break
        default:
          break
      }
      _detalles[row] = _detalle.getDetalle()
      dispatch(
        updateDetalles({
          key: tabId,
          detalles: _detalles,
        }),
      )
    },
    [dispatch, tabId, detalles, empresa],
  )

  const onModalItemsItemSelected = React.useCallback(
    (item: ItemVenta) => {
      const detalle: DetalleVenta = new DetalleVenta()
      detalle.setItem(item, empresa?.precision ?? 4)
      detalle.cantidad = 1
      dispatch(
        addDetalle({
          key: tabId,
          detalle: detalle.getDetalle(),
        }),
      )
      setMostrarBusquedaItems(false)
    },
    [empresa, tabId, dispatch],
  )

  //#region efectos
  const reenviarAutorizacion = React.useCallback(async () => {
    try {
      showEditLoader('Reenviando documento a autorizar...')
      await VentasService.reintentarAutorizacion(
        claveAcceso,
        TiposComprobantesSri.NotaDebito,
      )
      dispatch(
        addToast({
          content: 'El comprobante fue enviado al sri para su autorización.',
          type: ToastTypes.Info,
        }),
      )
    } catch (error) {
      if (typeof error == 'string') {
        dispatch(
          addToast({
            content: error,
            type: ToastTypes.Danger,
          }),
        )
      }
      console.log(error)
    }
    hideEditLoader()
  }, [claveAcceso, showEditLoader, hideEditLoader, dispatch])

  const handlePrint = React.useCallback(
    (design: boolean) => {
      setVerFacturaPdf({ claveAcceso: claveAcceso, design: design })
    },
    [claveAcceso],
  )

  const buttonClickAnular = React.useCallback(() => {
    if (info && info.info?.estadoElectronico !== 'AUTORIZADO') {
      dispatch(
        addToast({
          content:
            'La Nota de Debito no está autorizada, debe autorizarce antes de proceder a la anulación.',
          type: ToastTypes.Warning,
        }),
      )
    }
    setMostrarAnular(true)
  }, [info, dispatch])

  const onFormaPagoChanged = React.useCallback(
    (newValue) => {
      dispatch(
        setFormaPago({
          formaPago: newValue,
          key: tabId,
        }),
      )
    },
    [tabId, dispatch],
  )

  const onFechaEmisionChanged = React.useCallback(
    ({ value }: any) => {
      dispatch(
        updateFechaEmision({
          key: tabId,
          fechaEmision: value ?? '',
        }),
      )
    },
    [tabId, dispatch],
  )

  const isReadonlyDate = React.useCallback(() => {
    if (
      empresa?.editarFechaEmision &&
      Number(empresa?.editarFechaEmision) > 0
    ) {
      return false
    }
    return true
  }, [empresa])

  const handleButtonClick = React.useCallback(
    (button: ButtonTypes) => {
      switch (button) {
        case ButtonTypes.edit:
          modoEdicion()
          break
        case ButtonTypes.save: {
          guardar()
          break
        }
        case ButtonTypes.undo:
          deshacer()
          break
        case ButtonTypes.print:
          handlePrint(false)
          break
        case ButtonTypes.print_design:
          handlePrint(true)
          break
        case ButtonTypes.sendMail:
          setShowReenviarMail(true)
          break
        case ButtonTypes.authorizations:
          reenviarAutorizacion()
          break
        case ButtonTypes.broom:
          modoNuevo(puntoVenta ?? '', true)
          break
        case ButtonTypes.disable:
          buttonClickAnular()
          break
        default:
          break
      }
      dispatch(clearButtonClick(tabId))
    },
    [
      deshacer,
      dispatch,
      guardar,
      buttonClickAnular,
      handlePrint,
      modoEdicion,
      modoNuevo,
      tabId,
      puntoVenta,
      reenviarAutorizacion,
    ],
  )

  React.useEffect(() => {
    if (tab.editStatus === StatesEdition.new) {
      modoNuevo(puntoVenta ?? '')
    } else {
      cargarVenta(info.numero, true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
  }, [handleButtonClick, tab.globalButtonClick])

  React.useEffect(() => {
    if (!loading) {
      calcularTotales(detalles)
    }
  }, [loading, detalles, calcularTotales])

  React.useEffect(() => {
    const eliminarDetalle = (
      indice: number,
      detalles: Array<DetalleVentaRedux>,
    ) => {
      detalles.splice(indice, 1)
      dispatch(
        updateDetalles({
          detalles: detalles,
          key: tabId,
        }),
      )
      setFilaEliminar(-1)
    }

    const eliminarDetalleConfirm = (indice: number) => {
      const detalle = detalles[indice]
      dialogRef.current.show({
        title: 'Eliminar detalle',
        body: `¿Desea eliminar el item ${detalle.descripcion}?`,
        actions: [
          Dialog.Action(
            <span>
              <u>E</u>liminar
            </span>,
            () => {
              eliminarDetalle(indice, detalles.slice(0))
            },
            'btn-success',
            'e',
          ),
          Dialog.Action(
            <span>
              <u>C</u>ancelar
            </span>,
            (dialog) => {
              setFilaEliminar(-1)
              dialog.hide()
            },
            'btn-danger',
            'c',
          ),
        ],
        bsSize: 'small',
        onHide: (dialog) => {
          dialog.hide()
          setFilaEliminar(-1)
          console.log('closed by clicking background.')
        },
      })
      return
    }
    if (filaEliminar >= 0) {
      eliminarDetalleConfirm(filaEliminar)
    }
  }, [dispatch, tabId, filaEliminar, detalles])
  //#endregion

  const sm = window.innerWidth < 1000
  return (
    <div style={{ padding: '10px', overflowX: 'hidden' }} className='mb-4' >
      <BlockUi
        tag="div"
        loader={LoadingIndicator}
        blocking={datosEdicion.loader.show}
        message={datosEdicion.loader.mensaje}
      >
        {showReenviarMail && datosEdicion.cliente != null && (
          <PopupReenvioMail
            showConfirm={showReenviarMail}
            itemReenviarMail={{
              claveAcceso: claveAcceso,
              cliente: datosEdicion.cliente,
            }}
            closeConfirm={() => setShowReenviarMail(false)}
          />
        )}
        {datosEdicion.tieneError && (
          <VisualizaError
            titulo="Error en Nota de Débito"
            mensaje={datosEdicion.mensajeError}
            onOk={() => {
              dispatch(
                setMuestraError({
                  key: props.tabId,
                  tieneError: false,
                  mensajeError: '',
                }),
              )
            }}
          />
        )}
        {tab.editStatus === StatesEdition.save && verFacturaPdf && (
          <VerRIDE
            modo="Legacy"
            vista={verFacturaPdf.design ? 'Designer' : 'Viewer'}
            claveAcceso={verFacturaPdf.claveAcceso}
            onClose={() => {
              setVerFacturaPdf(undefined)
              dispatch(
                setImprimir({
                  imprimir: false,
                  key: tabId,
                }),
              )
            }}
          />
        )}
        {verSeleccionDocumentos && (
          <DocumentoVentaAfectar
            onCancel={() => {
              setVerSeleccionDocumentos(false)
            }}
            onOk={(documento: InfoDocumentoVentaAfectar) => {
              cambiarDocumentoOrigenFacturacion({
                comprobante: documento.codigo,
                fechaEmision: DateUtils.format(
                  documento.fecha,
                  formatoFechasApi,
                ),
                fechaNegociacion: '',
                formaPagoCodigo: documento.formaPagoCodigo,
                numero: documento.numero,
                tipoDoc: documento.tipoComprobante,
                totalDocumento: documento.total,
              })
              setVerSeleccionDocumentos(false)
            }}
            tipo={TiposComprobantesSri.NotaDebito}
          />
        )}
        {agregarDetalleMovil && (
          <AgregarDetalleVentaMobile
            key="agregarDetalleVentaMovil"
            onOk={agregarDetalleVentaMobile}
            onCancel={() => setAgregarDetalleMovil(false)}
            cliente={datosEdicion.cliente}
            fecha={datosEdicion.fecha}
          />
        )}
        <Dialog ref={dialogRef} />
        {mostrarEdicionItems && (
          <ModificaItem
            transaccion={'ventas'}
            show={mostrarEdicionItems}
            codigoBarras={codigoBarrasItemEditar}
            onCancel={() => setMostrarEdicionItems(false)}
            onChanged={async (codigoBarras) => {
              setMostrarEdicionItems(false)
              setCodigoBarrasItemEditar('')
              if (codigoBarrasItemEditar === '') {
                //nuevo
                const items = await VentasService.buscarItemCodigoBarras(
                  codigoBarras,
                  cliente?.identificacion ?? config.rucConsumidorFinal,
                  DateUtils.pickersDateToApiDate(fecha),
                )
                if (items.length > 0) {
                  onModalItemsItemSelected(items[0])
                }
              }
            }}
          />
        )}
        {mostrarBusquedaItems && (
          <MainModalItems
            transaccion="ventas"
            action="none"
            show={mostrarBusquedaItems}
            cliente={
              datosEdicion.cliente !== null
                ? datosEdicion.cliente
                : config.rucConsumidorFinal
            }
            onCancel={() => setMostrarBusquedaItems(false)}
            returnItem={onModalItemsItemSelected}
          />
        )}
        <fieldset
          data-tut="sectionTutComprobante"
          disabled={tab.editStatus === StatesEdition.save}
        >
          <div>
            <RowContainer>
              <CustomCol xs="12" md="7">
                <div>
                  <RowContainer>
                    <CustomCol xs="12">
                      <Labeled label="Nombre / Razón Social del cliente">
                        <BuscarClienteLookUp
                          disabled={tab.editStatus === StatesEdition.save}
                          selected={datosEdicion.cliente}
                          onChanged={buscarClienteOnChanged}
                          // onChanged={(value) => {
                          //   if (value.codigo) {
                          //     if (!showPopupCustomer) setShowPopupCustomer(true)
                          //     else cambiarClienteSeleccionado(value ?? config.rucConsumidorFinal)
                          //   }
                          // }}
                          allowAdd
                          allowClear
                          allowEdit
                        />
                      </Labeled>
                    </CustomCol>
                  </RowContainer>
                  {cliente && (
                    <RowContainer>
                      <CustomCol xs="12">
                        <Labeled label="Dirección">
                          <TextBox value={cliente.direccion} readOnly />
                        </Labeled>
                      </CustomCol>
                      <CustomCol xs="4">
                        <Labeled label="Teléfono">
                          <TextBox readOnly value={cliente.telefono ?? ''} />
                        </Labeled>
                      </CustomCol>
                      <CustomCol xs="8">
                        <Labeled label="Correo eléctronico">
                          <TextBox readOnly value={cliente.email ?? ''} />
                        </Labeled>
                      </CustomCol>
                    </RowContainer>
                  )}
                </div>
              </CustomCol>
              <CustomCol xs="12" md="5">
                <div>
                  <RowContainer>
                    <CustomCol xs="12" md="5">
                      <Labeled label="Fecha">
                        <DateBox
                          value={fecha}
                          readOnly={isReadonlyDate()}
                          onValueChanged={(evt) => {
                            if (evt?.event !== undefined) {
                              onFechaEmisionChanged(evt?.value)
                            }
                          }}
                          max={DateUtils.getCurrentDateAsString(
                            formatoFechasDatePickers,
                          )}
                        />
                      </Labeled>
                    </CustomCol>
                    <CustomCol xs="12" md="7">
                      <Labeled label="Número">
                        <CRow>
                          <CCol xs="3">
                            <TextBox
                              readOnly
                              placeholder="001"
                              value={datosEdicion.establecimiento}
                            />
                          </CCol>
                          <CCol xs="3">
                            <TextBox
                              readOnly
                              placeholder="001"
                              value={datosEdicion.puntoEmision}
                            />
                          </CCol>
                          <CCol xs="6">
                            <TextBox
                              readOnly
                              placeholder="0000000000"
                              value={
                                '0'.repeat(
                                  10 -
                                  datosEdicion.numerofactura.toString()
                                    .length,
                                ) + datosEdicion.numerofactura.toString()
                              }
                            />
                          </CCol>
                        </CRow>
                      </Labeled>
                    </CustomCol>
                  </RowContainer>
                  <RowContainer>
                    <CustomCol xs="6" md="3">
                      <Labeled label="# Detalles">
                        <TextBox
                          readOnly
                          value={datosEdicion.detalles.length.toString()}
                        />
                      </Labeled>
                    </CustomCol>
                    <CustomCol xs="6" md="3">
                      <Labeled label="# Articulos">
                        <TextBox
                          readOnly
                          value={detalles
                            .reduce((prev, next) => prev + next._cantidad, 0)
                            .toFixed(2)}
                        />
                      </Labeled>
                    </CustomCol>
                    <CustomCol xs="12" md="6">
                      <Labeled label="Punto Venta">
                        <TextBox readOnly text={puntoVenta ?? ''} />
                      </Labeled>
                    </CustomCol>
                  </RowContainer>
                  {tab.editStatus === StatesEdition.save && ( //Electronica
                    <>
                      <RowContainer>
                        <CustomCol xs="12" lg="6">
                          {!isMobileOnly && (
                            <Barcode text={datosEdicion.autorizacion} />
                          )}
                          {isMobileOnly && (
                            <Labeled label="Autorización">
                              <small>{datosEdicion.claveAcceso}</small>
                            </Labeled>
                          )}
                        </CustomCol>
                      </RowContainer>
                      <RowContainer>
                        <CustomCol>
                          <Labeled label="Fecha Autorización">
                            <CBadge
                              color={
                                datosEdicion.fechaAutorizacion
                                  ? 'info'
                                  : 'danger'
                              }
                            >
                              {datosEdicion.fechaAutorizacion
                                ? datosEdicion.fechaAutorizacion
                                : 'No Autorizado'}
                            </CBadge>
                          </Labeled>
                        </CustomCol>
                        <CustomCol>
                          <Labeled label="Comprobante al que afecta">
                            {datosEdicion.documentoOrigen && (
                              <span>{`FACTURA ${datosEdicion.documentoOrigen?.numero}`}</span>
                            )}
                            {!datosEdicion.documentoOrigen && (
                              <CButton
                                onClick={() => {
                                  setVerSeleccionDocumentos(true)
                                }}
                              >
                                <FontAwesomeIcon icon={faSearch} />
                                Buscar
                              </CButton>
                            )}
                          </Labeled>
                        </CustomCol>
                      </RowContainer>
                    </>
                  )}
                </div>
              </CustomCol>
            </RowContainer>
            {tab.editStatus !== StatesEdition.save && (
              <RowContainer className="mt-2 mb-2">
                <CCol>
                  <div className="buttonsContainer">
                    {isMobileOnly && (
                      <Button
                        text="Agregar Detalle"
                        icon="add"
                        stylingMode="contained"
                        type="default"
                        onClick={() => {
                          setAgregarDetalleMovil(true)
                        }}
                      />
                    )}
                    <Button
                      style={{ marginLeft: '2px' }}
                      text="Crear Producto"
                      icon="file"
                      stylingMode="contained"
                      type="default"
                      onClick={() => {
                        setCodigoBarrasItemEditar('')
                        setMostrarEdicionItems(true)
                      }}
                    />
                    <Button
                      style={{ marginLeft: '2px' }}
                      hint="Más opciones"
                      icon="more"
                      stylingMode="contained"
                      type="default"
                      onClick={() => {
                        setMostrarBusquedaItems(true)
                      }}
                    />
                  </div>
                </CCol>
              </RowContainer>
            )}

            {isMobileOnly && ( //Mobiles
              <RowContainer>
                <div style={{ paddingTop: '5px' }}>
                  <MUIDataTable
                    data={detalles}
                    columns={getColumnnasTablaDetalleVentas(
                      muiTableCellsChanged,
                      (row) => setFilaEliminar(row),
                    )}
                    options={OptionsTablaDetalleVentas}
                  />
                </div>
              </RowContainer>
            )}
            {isMobile &&
              !isMobileOnly && ( //Tablets
                <RowContainer>
                  <CCol xs="12">
                    <div
                      className="detallesTableContainer"
                      style={{ maxHeight: `${window.innerHeight / 2}px` }}
                    >
                      <CustomSheet
                        id="sheetDetalle"
                        blankRows={2}
                        striped={true}
                        columns={sheetColumns}
                        data={detalles}
                        editable={tab.editStatus !== StatesEdition.save}
                        initializeNewRow={(col, value) => {
                          const detalle: DetalleVenta = new DetalleVenta()
                          if (col.name === 'descripcion') {
                            const item = value as ItemVenta
                            detalle.setItem(item, empresa?.precision ?? 4)
                          } else {
                            detalle[col.name] = value
                          }
                          return detalle.getDetalle()
                        }}
                        onCellChanged={sheetOnCellsChanged}
                        showRowNumber
                        rowCommands={sheetCommands}
                      />
                    </div>
                  </CCol>
                </RowContainer>
              )}
            {!isMobile && ( //Escritorio
              <RowContainer>
                <CCol xs="12">
                  <div
                    className="detallesTableContainer overflow-auto"
                    onKeyDown={(e) => { }}
                    style={{
                      width:
                        window.innerWidth > 900
                          ? '100%'
                          : `${window.innerWidth - 20}px`,
                      maxHeight: `${window.innerHeight / 2}px`,
                    }}
                  >
                    <CustomSheet
                      id="sheetDetalle"
                      blankRows={2}
                      striped={true}
                      columns={sheetColumns}
                      data={detalles}
                      editable={tab.editStatus !== StatesEdition.save}
                      initializeNewRow={(col, value) => {
                        const detalle: DetalleVenta = new DetalleVenta()
                        if (col.name === 'descripcion') {
                          const item = value as ItemVenta
                          detalle.setItem(item, empresa?.precision ?? 4)
                        } else {
                          detalle[col.name] = value
                        }
                        return detalle.getDetalle()
                      }}
                      onCellChanged={sheetOnCellsChanged}
                      showRowNumber
                      rowCommands={sheetCommands}
                    />
                  </div>
                </CCol>
              </RowContainer>
            )}
            <RowContainer>
              <CustomCol xs="12" md={8}>
                <Labeled label="Observaciones">
                  <TextArea
                    value={observaciones}
                    onValueChanged={onObservacionesChanged}
                  />
                </Labeled>
              </CustomCol>
              <CustomCol xs="12" md="3">
                <Labeled label="Forma Pago">
                  <SelectBox
                    id="selectFormaPago"
                    displayExpr="descripcion"
                    keyExpr="codigo"
                    options={formasPago}
                    selected={
                      datosEdicion.formaPago &&
                        datosEdicion.formaPago.length > 0
                        ? datosEdicion.formaPago[0]
                        : null
                    }
                    placeholder="Forma de pago"
                    multiple={false}
                    onChange={onFormaPagoChanged}
                    disabled={tab.editStatus === StatesEdition.save}
                    clearButton={true}
                    invalid={datosEdicion.formaPago.length === 0}
                  />
                </Labeled>
              </CustomCol>
            </RowContainer>
            {!sm && (
              <RowContainer>
                <div className="totalesContainer">
                  <div className="totalContainer">
                    <div className="totalLabel">
                      <span>Subtotal 0%</span>
                    </div>
                    <div className="totalValue">
                      <span>{datosEdicion.totales.subtotal0.toFixed(2)} </span>
                    </div>
                  </div>
                  <div className="totalContainer">
                    <div className="totalLabel">
                      <span>Subtotal IVA</span>
                    </div>
                    <div className="totalValue">
                      <span>
                        {' '}
                        {datosEdicion.totales.subtotalIva.toFixed(2)}{' '}
                      </span>
                    </div>
                  </div>
                  <div className="totalContainer">
                    <div className="totalLabel">
                      <span>Subtotal</span>
                    </div>
                    <div className="totalValue">
                      <span> {datosEdicion.totales.subtotal.toFixed(2)} </span>
                    </div>
                  </div>
                  <div className="totalContainer">
                    <div className="totalLabel">
                      <span>Descuento</span>
                    </div>
                    <div className="totalValue">
                      <span> {datosEdicion.totales.descuento.toFixed(2)} </span>
                    </div>
                  </div>
                  <div className="totalContainer">
                    <div className="totalLabel">
                      <span>IVA {iva}%</span>
                    </div>
                    <div className="totalValue">
                      <span> {datosEdicion.totales.iva.toFixed(2)} </span>
                    </div>
                  </div>
                  <div className="totalContainer">
                    <div className="totalLabel">
                      <span>Total</span>
                    </div>
                    <div className="totalValue">
                      <span> {datosEdicion.totales.total.toFixed(2)} </span>
                    </div>
                  </div>
                </div>
              </RowContainer>
            )}
            {sm && (
              <>
                <RowContainer>
                  <CustomCol xs="8">
                    <small>Subtotal</small>
                  </CustomCol>
                  <CustomCol xs="4">
                    <small>{datosEdicion.totales.subtotal.toFixed(2)}</small>
                  </CustomCol>
                </RowContainer>
                <RowContainer>
                  <CustomCol xs="8">
                    <small>Subtotal 0%</small>
                  </CustomCol>
                  <CustomCol xs="4">
                    <small>{datosEdicion.totales.subtotal0.toFixed(2)}</small>
                  </CustomCol>
                </RowContainer>
                <RowContainer>
                  <CustomCol xs="8">
                    <small>ICE</small>
                  </CustomCol>
                  <CustomCol xs="4">
                    <small>{datosEdicion.totales.ice.toFixed(2)}</small>
                  </CustomCol>
                </RowContainer>
                <RowContainer>
                  <CustomCol xs="8">
                    <small>Subtotal IVA</small>
                  </CustomCol>
                  <CustomCol xs="4">
                    <small>{datosEdicion.totales.subtotalIva.toFixed(2)}</small>
                  </CustomCol>
                </RowContainer>
                <RowContainer>
                  <CustomCol xs="8">
                    <small>Impuestos</small>
                  </CustomCol>
                  <CustomCol xs="4">
                    <small>{datosEdicion.totales.iva.toFixed(2)}</small>
                  </CustomCol>
                </RowContainer>
                <RowContainer>
                  <CustomCol xs="8">
                    <small>Total</small>
                  </CustomCol>
                  <CustomCol xs="4">
                    <small>{datosEdicion.totales.total.toFixed(2)}</small>
                  </CustomCol>
                </RowContainer>
              </>
            )}
          </div>
        </fieldset>
      </BlockUi>
    </div>
  )
}

const sheetColumns: Array<DataSheetColumn> = [
  {
    id: 1,
    colSpan: 1,
    name: 'codigoBarras',
    headerText: 'Código',
    width: '10%',
    order: 1,
    dataEditor: CustomSheetTextEditor,
    getReadOnly: (data) => {
      return data !== null
    },
  },
  {
    id: 2,
    colSpan: 1,
    name: 'descripcion',
    headerText: 'Descripción',
    width: '20%',
    order: 2,
    getReadOnly: (data) => {
      return false
    },
    getDataEditor: (data) => {
      return data == null ? SheetDescripcionItemSelectEditor : SheetTextEditor
    },
  },
  {
    id: 3,
    colSpan: 1,
    name: 'porcentajeiva',
    headerText: 'Imp.',
    width: '100px',
    order: 3,
    readOnly: true,
    getDisplay: (value, data: DetalleVentaRedux) =>
      `${(data.porcentajeiva * 100).toFixed(2)}% - ${data._iva.toFixed(2)}`,
  },
  {
    id: 4,
    colSpan: 1,
    name: '_cantidad',
    headerText: 'Cant.',
    width: '60px',
    order: 4,
    getReadOnly: (data) => {
      return data == null
    },
    dataEditor: CustomSheetNumberEditor,
    valueViewer: CustomSheetNumberViewer,
  },
  {
    id: 5,
    colSpan: 1,
    name: '_porcentajeDescuento',
    headerText: 'Desc %.',
    width: '80px',
    order: 5,
    getReadOnly: (data) => {
      return data == null
    },
    dataEditor: CustomSheetNumberEditor,
    valueViewer: CustomSheetNumberViewer,
    getDisplay: (value, data: DetalleVentaRedux) =>
      `${data._porcentajeDescuento.toFixed(2)}% (${data._descuento.toFixed(
        2,
      )})`,
  },
  {
    id: 6,
    colSpan: 1,
    name: '_precioUnitario',
    headerText: 'P. Unit.',
    width: '80px',
    order: 6,
    getReadOnly: (data) => {
      return data == null
    },
    dataEditor: CustomSheetNumberEditor,
    valueViewer: CustomSheetNumberViewer,
  },
  {
    id: 7,
    colSpan: 1,
    name: '_precioIva',
    headerText: 'Unit. IVA',
    width: '80px',
    order: 7,
    getReadOnly: (data) => {
      return data == null
    },
    dataEditor: CustomSheetNumberEditor,
    valueViewer: CustomSheetNumberViewer,
  },
  {
    id: 8,
    colSpan: 1,
    name: '_total',
    headerText: 'Subtotal',
    width: '80px',
    order: 8,
    readOnly: true,
    valueViewer: CustomSheetNumberViewer,
    getDisplay: (value, data: DetalleVentaRedux) => data._subtotal.toFixed(4),
  },
]

export const DebitNoteButtons: CustomButtons = {
  Nuevo: true,
  Guardar: true,
  Buscar: true,
  Deshacer: true,
  Editar: true,
  Imprimir: true,
  Enviar: true,
  Autorizar: true,
}

export default NotaDebito
