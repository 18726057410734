import React from 'react'
import { Column } from 'devextreme-react/data-grid'
import { CButton, CCard, CCol, CRow } from '@coreui/react-pro'
import CustomCol from '../../../../../../../../views/componentes/colContainer'
import {
  Button, DataGrid, NumberBox, Switch, TextBox
} from 'devextreme-react'
import Labeled from '../../../../../../../../views/componentes/labeledInput/labeledInput'
import RowContainer from '../../../../../../../../views/componentes/rowContainer/rowContainer'
import { BuscarVendedorLookUp } from '../../../../../../../shared/components/buscarVendedorLookMemoUp/buscarVendedorLookUp'
import ImpresionesLookUp from '../../../../../../../componentes/impresionesLookUp'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../../../../../../store/store'
import { DatosEdicionVentasCliente, TRetencionesIva } from '../../../../types/types'
import { ToastTypes } from '../../../../../../../../store/types'
import { addToast } from '../../../../../../../../store/toasterReducer'
import { ModalListaRetencionesIVA } from '../../../../../../../componentes/modalListaRetencionesIVA/modalListaRetencionesIVA'
import ModalCuentas from '../../../../../../../contabilidad/pages/planCuentas/modalCuentas'
import { TRetencionesOptionsIva } from '../../../../../../../componentes/modalListaRetencionesIVA/components/types/type'
import { v4 as uuidv4 } from 'uuid'
import { setTabVentas } from '../../../../store/editDataReducer'
import { TFormasPago } from '../../../../../../../componentes/formasPagoLookUp/store/type'
interface IVentasProps {
  tabId: string
  refForm: any
}

const Ventas: React.FunctionComponent<IVentasProps> = (props) => {
  const { tabId } = props
  const dispatch = useDispatch()

  const datosVentasState = useSelector((state: RootState) => {
    return state.clientes.clientes.editData[props.tabId]?.tabs?.ventas
  })
  const retenciones = useSelector((state: RootState) => {
    return state.clientes.clientes.editData[props.tabId]?.tabs?.ventas?.retenciones
  })
  const formasPagoState = useSelector((state: RootState) => {
    return state.clientes.clientes.editData[props.tabId]?.tabs?.ventas?.formasPago
  })

  const [formaPagoSelec, setformaPagoSelec] = React.useState<TFormasPago>(null)
  const [showlistaRet, setShowlistaRet] = React.useState<boolean>(false)
  const [showModal, setShowModal] = React.useState<boolean>(false)

  const onChangedDeshabilitarCredito = React.useCallback(
    (data) => {
      if (data.event !== undefined) {
        dispatch(
          setTabVentas({
            key: tabId,
            data: {
              ...datosVentasState,
              deshabilitarCredito: data.value,
            }
          })
        )
      }
    },
    [dispatch, tabId, datosVentasState],
  )

  const onChangeVentasDiasCredito = React.useCallback(
    (data) => {
      if (data.event !== undefined) {
        dispatch(
          setTabVentas({
            key: tabId,
            data: {
              ...datosVentasState,
              diasCredito: data.value,
            }
          })
        )
      }
    },
    [dispatch, tabId, datosVentasState],
  )

  const onChangeRevisionPago = React.useCallback(
    (data) => {
      if (data.event !== undefined) {
        dispatch(
          setTabVentas({
            key: tabId,
            data: {
              ...datosVentasState,
              revisionPago: data.value,
            }
          })
        )
      }
    },
    [dispatch, tabId, datosVentasState])

  const onChangeLimiteCredito = React.useCallback(
    (data) => {
      if (data.event !== undefined) {
        dispatch(
          setTabVentas({
            key: tabId,
            data: {
              ...datosVentasState,
              limiteCredito: data.value,
            }
          })
        )
      }
    },
    [dispatch, tabId, datosVentasState])

  const onChangeSaldo = React.useCallback(
    (data) => {
      if (data.event !== undefined) {
        dispatch(
          setTabVentas({
            key: tabId,
            data: {
              ...datosVentasState,
              saldo: data.value,
            }
          })
        )
      }
    },
    [dispatch, tabId, datosVentasState])

  const onChangeAtencionVentas = React.useCallback(
    (data) => {
      if (data.event !== undefined) {
        dispatch(
          setTabVentas({
            key: tabId,
            data: {
              ...datosVentasState,
              atencionVentas: data.value,
            }
          })
        )
      }
    },
    [dispatch, tabId, datosVentasState])

  const onChangePorcentajeDescuento = React.useCallback(
    (data) => {
      if (data.event !== undefined) {
        dispatch(
          setTabVentas({
            key: tabId,
            data: {
              ...datosVentasState,
              porcentajeDescuento: data.value,
            }
          })
        )
      }
    },
    [dispatch, tabId, datosVentasState])

  const onChangeAtencionCobranza = React.useCallback(
    (data) => {
      if (data.event !== undefined) {
        dispatch(
          setTabVentas({
            key: tabId,
            data: {
              ...datosVentasState,
              atencionCobranza: data.value,
            }
          })
        )
      }
    },
    [dispatch, tabId, datosVentasState])

  const onChangedNecesitaReferencias = React.useCallback(
    (data) => {
      if (data.event !== undefined) {
        dispatch(
          setTabVentas({
            key: tabId,
            data: {
              ...datosVentasState,
              necesitaReferencias: data.value,
            }
          })
        )
      }
    },
    [dispatch, tabId, datosVentasState])

  const onChangedVendedor = React.useCallback(
    (data) => {
      if (data !== null) {
        dispatch(
          setTabVentas({
            key: tabId,
            data: {
              ...datosVentasState,
              vendedor: data,
            }
          })
        )
      }
    },
    [dispatch, tabId, datosVentasState])

  const onChangedImpresiones = React.useCallback(
    (data) => {
      if (data !== null) {
        dispatch(
          setTabVentas({
            key: tabId,
            data: {
              ...datosVentasState,
              impresion: data,
            }
          })
        )
      }
    },
    [dispatch, tabId, datosVentasState])

  const onAddCuentasFPago = React.useCallback(() => {
    if (formaPagoSelec === null) {
      dispatch(
        addToast({
          content: 'Debe seleccionar una forma de pago.',
          type: ToastTypes.Info,
        }),
      )
    } else {
      setShowModal(true)
    }
  }, [formaPagoSelec, dispatch])

  const onChangedSelectedFPago = React.useCallback((selectionChanged) => {
    if (selectionChanged) {
      if (selectionChanged['selectedRowsData'].length > 0) {
        setformaPagoSelec(selectionChanged['selectedRowsData'][0])
      }
    }
  }, [])

  const onChangeRetencionIva = React.useCallback(
    (data: TRetencionesOptionsIva) => {
      const ret: TRetencionesIva = {
        id: uuidv4(),
        impuesto: 0,
        porcentaje: data.grupo ?? 0,
        retencion: 0,
        codigo: data.codigo,
        tipo: data.tipoCodigo,
        descripcion: data.valor,
      }
      const selectedIndex = retenciones.findIndex((retState: TRetencionesIva) => {
        return retState.codigo === ret.codigo
      })
      if (selectedIndex === -1) {
        dispatch(
          setTabVentas({
            key: tabId,
            data: {
              ...datosVentasState,
              retenciones: [...retenciones, ret],
            }
          })
        )
      }
    },
    [dispatch, retenciones, tabId, datosVentasState],
  )

  const onDeleteSelectedRet = React.useCallback(
    ({ key }) => {
      const editedRetenciones = JSON.parse(JSON.stringify(retenciones))
      const deletedRetencion = editedRetenciones.filter((ret) => ret.codigo !== key.codigo)
      dispatch(
        setTabVentas({
          key: tabId,
          data: {
            ...datosVentasState,
            retenciones: deletedRetencion,
          }
        })
      )
    },
    [dispatch, datosVentasState, retenciones, tabId],
  )

  const cargarCuentas = React.useCallback((data) => {

    const formaPagoEdit = formasPagoState.slice();
    const selectedForma = formaPagoEdit.findIndex((forma: TFormasPago) => {
      return forma.codigoFormaPago === formaPagoSelec.codigoFormaPago
    })
    if (selectedForma > -1) {
      formaPagoEdit[selectedForma] = {
        ...formaPagoEdit[selectedForma],
        codigoCuenta: data.codigo,
        cuentaNumero: data.numero,
        cuentaDescripcion: data.descripcion,
      }
      dispatch(
        setTabVentas({
          key: tabId,
          data: {
            ...datosVentasState,
            formasPago: formaPagoEdit
          }
        })
      )
    }


    setShowModal(false)
  },
    [dispatch, formaPagoSelec, datosVentasState, formasPagoState, tabId],
  )


  // const onFormasPago = React.useCallback(() => {
  //   const formas: Array<TFormasPago> = []
  //   for (const item of formasPagoState) {
  //     const i: TFormasPago = {
  //       ...item
  //     }
  //     formas.push(i)
  //   }
  //   setFormasPago(formas);
  // }, [formasPagoState]);

  // React.useEffect(() => {
  //   onFormasPago()
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [formasPagoState])


  return (
    <>
      {showlistaRet &&
        <ModalListaRetencionesIVA
          onChanged={(data) => {
            onChangeRetencionIva(data)
            setShowlistaRet(false)
          }}
          onCancel={() => {
            setShowlistaRet(false)
          }}
          show={showlistaRet}
        />
      }
      <ModalCuentas show={showModal} onClose={() => setShowModal(false)} cargarCuentas={(evt) => cargarCuentas(evt)} />
      <RowContainer>
        <CustomCol>
          <RowContainer>
            <CustomCol xs="12" md="5">
              <Labeled label="Deshabilitar Crédito:">
                <Switch
                  value={datosVentasState?.deshabilitarCredito}
                  onValueChanged={(data) => {
                    if (data !== null || data !== undefined) {
                      onChangedDeshabilitarCredito(data)
                    }
                  }}
                />
              </Labeled>
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol xs="12" md="3">
              <Labeled label="Días de Crédito:">
                <NumberBox
                  name="diasCredito"
                  placeholder="Número de días de crédito"
                  value={datosVentasState?.diasCredito}
                  onValueChanged={(data) => {
                    if (data?.event !== undefined) {
                      onChangeVentasDiasCredito(data)
                    }
                  }}
                  showSpinButtons
                  min={0}
                />
              </Labeled>
            </CustomCol>
            <CustomCol xs="12" md="3">
              <Labeled label="Revisión y Pago:">
                <TextBox
                  name="revisionPago"
                  placeholder="dd/mm de pagos"
                  mask='AA-##'
                  maskRules={'AA-##'}
                  value={datosVentasState?.revisionPago}
                  onValueChanged={(data) => {
                    if (data?.event !== undefined) {
                      onChangeRevisionPago(data)
                    }
                  }}
                />
              </Labeled>
            </CustomCol>
          </RowContainer>

          <RowContainer>
            <CustomCol xs="12" md="3">
              <Labeled label="Límite de Crédito:">
                <NumberBox
                  name="limiteCredito"
                  placeholder="Valor del limite de crédito"
                  value={datosVentasState?.limiteCredito}
                  onValueChanged={(data) => {
                    if (data?.event !== undefined) {
                      onChangeLimiteCredito(data)
                    }
                  }}
                  className='textInputAlignRight'
                />
              </Labeled>
            </CustomCol>

            <CustomCol xs="12" md="4">
              <Labeled label="Saldo:">
                <NumberBox
                  name="saldo"
                  value={datosVentasState?.saldo}
                  onValueChanged={(data) => {
                    if (data?.event !== undefined) {
                      onChangeSaldo(data)
                    }
                  }}
                  className='textInputAlignRight'
                />
              </Labeled>
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol xs="12" md="3">
              <Labeled label="Atención Ventas:">
                <TextBox name="atencionVentas"
                  value={datosVentasState?.atencionVentas}
                  onValueChanged={(data) => {
                    if (data?.event !== undefined) {
                      onChangeAtencionVentas(data)
                    }
                  }}
                />
              </Labeled>
            </CustomCol>
            <CustomCol xs="12" md="4">
              <Labeled label="% de Descuento:">
                <NumberBox
                  name="porcentajeDescuento"
                  placeholder="Valor del porcentaje de descuento"
                  value={datosVentasState?.porcentajeDescuento}
                  onValueChanged={(data) => {
                    if (data?.event !== undefined) {
                      onChangePorcentajeDescuento(data)
                    }
                  }}
                  className='textInputAlignRight'
                />
              </Labeled>
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol xs="12" md="3">
              <Labeled label="Atención Cobranza:">
                <TextBox name="atencionCobranzas"
                  value={datosVentasState?.atencionCobranza}
                  onValueChanged={(data) => {
                    if (data?.event !== undefined) {
                      onChangeAtencionCobranza(data)
                    }
                  }}
                />
              </Labeled>
            </CustomCol>

            <CustomCol xs="12" md="4">
              <Labeled label="Necesita referencias:">
                <Switch
                  value={datosVentasState?.necesitaReferencias}
                  onValueChanged={(e) => {
                    if (e.event !== null || e.event !== undefined) {
                      onChangedNecesitaReferencias(e)
                    }
                  }}
                />
              </Labeled>
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol xs="12" md="6">
              <Labeled label="Vendedor:">
                <BuscarVendedorLookUp
                  selected={datosVentasState?.vendedor}
                  onChanged={(data) => {
                    if (data !== null || data !== undefined) {
                      onChangedVendedor(data)
                    }
                  }}
                  allowAdd
                  allowClear
                  allowEdit
                />
              </Labeled>
            </CustomCol>
            <CustomCol xs="12" md="4">
              <Labeled label="Impresion">
                <ImpresionesLookUp
                  selected={datosVentasState?.impresion}
                  provider={datosVentasState?.impresiones ?? []}
                  onChanged={(data) => {
                    if (data !== null || data !== undefined) {
                      onChangedImpresiones(data)
                    }
                  }}
                />
              </Labeled>
            </CustomCol>
          </RowContainer>

          <RowContainer>
            <CustomCol xs="12" md="10" className="mt-2 mb-2">
              <CCard>
                <CRow className="d-flex justify-content-between">
                  <CCol xs="12" md="6" className="m-2">
                    <Labeled label="RETENCIONES EN LA FUENTE DEL I.V.A." />
                  </CCol>
                  <CCol xs="12" md="6" className="m-2">
                    <Button
                      onClick={() => {
                        setShowlistaRet(true)
                      }}
                      icon="add"
                      text="Agregar"
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol xs="12" md="12">
                    <DataGrid
                      className="m-2"
                      selection={{ mode: 'single' }}
                      hoverStateEnabled={true}
                      dataSource={datosVentasState?.retenciones}
                      showBorders={true}
                      showRowLines={true}
                      onRowDblClick={onDeleteSelectedRet}
                    >
                      <Column dataType="string" dataField="descripcion" caption="Impuesto" width="70%" />
                      <Column dataType="string" dataField="porcentaje" caption="Porcentaje" width="30%" />
                    </DataGrid>
                  </CCol>
                </CRow>
              </CCard>
            </CustomCol>
            <CustomCol xs="12" md="10">
              <CRow>
                <CCol md="10" xs="8">
                  <Labeled label="Formas de Pago:" />
                </CCol>
                <CCol md="2" xs="4" className="d-flex justify-content-end">
                  <CButton
                    style={{ marginTop: '4px' }}
                    onClick={() => {
                      onAddCuentasFPago()
                    }}
                    size="sm"
                    active
                    color="secondary"
                    aria-pressed="true"
                  >
                    {'Cuentas'}
                  </CButton>
                </CCol>
              </CRow>
              <CRow>
                <CCol md="12" xs="12" className='mt-2'>
                  <DataGrid
                    selection={{ mode: 'single' }}
                    hoverStateEnabled={true}
                    dataSource={formasPagoState}
                    showBorders={true}
                    showRowLines={true}
                    onSelectionChanged={onChangedSelectedFPago}
                  >
                    <Column dataType="string" dataField="descripcionFormaPago" caption="Formas de Pago" width="20%" />
                    <Column dataType="string" dataField="cuentaNumero" caption="Nro. de Cuenta" width="20%" />
                    <Column dataType="string" dataField="cuentaDescripcion" caption="Cuenta" width="60%" />
                  </DataGrid>
                </CCol>
              </CRow>
            </CustomCol>
          </RowContainer>
        </CustomCol>
      </RowContainer>
    </>
  )
}

export default Ventas

export const defaultDatosEdicionVentaslCliente: DatosEdicionVentasCliente = {
  deshabilitarCredito: false,
  diasCredito: 0,
  revisionPago: '',
  limiteCredito: 0.00,
  saldo: null,
  atencionVentas: '',
  porcentajeDescuento: null,
  atencionCobranza: '',
  necesitaReferencias: false,
  impresion: null,
  impresiones: [],
  vendedor: null,
  retenciones: [],
  iva: [],
  formasPago: [],
}