import { DateUtils, formatoFechasApi } from "../../../../helpers/dateUtils";
import { RequestHelper } from "../../../../helpers/requestHelper";
import { utilidades } from "../../../../helpers/utilidades";
import { SesionService } from "../../../../services/sesion.service";
import { TOptionGenericoSelect } from "../../../clientes/pages/clientes/types/types";

export const zonasService = { getZonas };

async function getZonas(label: string): Promise<any> {
  const sesion = SesionService.getCurrentSesion()
  const data: any = {
    mensaje: label
  };
  const keysToCache: any = {
    ...data,
    dateKey: DateUtils.getCurrentDateAsString(formatoFechasApi),
    empresa: sesion?.empresa?.codigo,
  }
  const cacheKey = 'tipoZonas' + JSON.stringify(keysToCache);
  const cached = sessionStorage.getItem(cacheKey);
  if (cached) {
    return { error: false, auto: JSON.parse(cached) };
  } else {
    const tipos = await RequestHelper.getAll<any>('inventario', 'zonasSe/cBoxLoad', "", data);
    const opt: Array<TOptionGenericoSelect> = [];
    if (tipos?.auto && tipos?.auto.length > 0) {
      for (const x of tipos.auto) {
        opt.push({
          codigo: parseInt(x.codigo) ?? -1,
          descripcion: utilidades.capText(x?.nombre) ?? '',
          estado: x.estado ?? 0,
        })
      }
      tipos.auto = opt
    }
    sessionStorage.setItem(cacheKey, JSON.stringify(opt));
    return tipos;

  }
}

