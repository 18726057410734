import React, { useCallback, useState } from 'react'
import RowContainer from '../../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../../views/componentes/labeledInput/labeledInput'
import {
  Button,
  CheckBox,
  DataGrid,
  NumberBox,
  RadioGroup,
  TextBox,
  Validator,
} from 'devextreme-react'
import TipoClienteLookUp from '../../../../../../../componentes/tipoCliente'
import LocalesLookUp from '../../../../../../../componentes/localesLookUp'
import {
  AsyncRule,
  Column,
  Editing,
  RequiredRule,
} from 'devextreme-react/data-grid'
import { CCol, CLink, CRow, CTooltip } from '@coreui/react-pro'
import TiposImpuestosLookup from '../../../../../../../componentes/tiposImpuestosLookup'
import SustentosTributariosLookUp from '../../../../../../../componentes/sustentosTributarioLookUp'
import TiposIceLookUp from '../../../../../../../componentes/tiposIceLookUp'
import PartidaArancelariaLookUp from '../../../../../../../componentes/partidasArancelariasLookup'
import {
  ItemPriceControl,
  PricesItems,
  optionsGroupsCosteo,
} from '../../../../types/types'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../../store/store'
import {
  setDataPricesControlToEdit,
  setIce,
  setStore,
  setTariffItem,
  setTaxSupport,
  setTypeCustomer,
  setTypeTax,
} from '../../../../store/editDataReduce'
import { TipoCliente } from '../../../../../../../ventas/types/types'
import { LocalOption } from '../../../../../../../componentes/localesLookUp/store/type'
import { OptionTiposImpuestos } from '../../../../../../../componentes/tiposImpuestosLookup/types/types'
import { TiposIce } from '../../../../../../../componentes/tiposIceLookUp/types/types'
import { SutentosTributarios } from '../../../../../../../componentes/sustentosTributarioLookUp/types/types'
import { PartidaArancelaria } from '../../../../../../../componentes/partidasArancelariasLookup/types/types'
import { ToastTypes } from '../../../../../../../../store/types'
import { useSetToast } from '../../../../../../../../hooks/useGlobalHooks'
import CIcon from '@coreui/icons-react'
import { cilInfo } from '@coreui/icons'
import { isMobile } from 'react-device-detect'

const PreciosControl = (props) => {
  const { tabId } = props

  const dispatch = useDispatch()

  const decimalPrecision = useSelector((state: RootState) => {
    return state.global.session.empresa.precision
  })
  const originItem = useSelector((state: RootState) => {
    return state.inventarios.items.editData[tabId].generales.procedencia
  })
  const dataToEditPriceControls = useSelector(
    (state: RootState) =>
      state.inventarios.items.editData[tabId].preciosControl,
  )
  const typeItem = useSelector((state: RootState) => {
    return state.inventarios.items.editData[tabId].generales.tipoItem
  })

  const setToastMessage = useSetToast()
  const [selectedPrice, setSelectedPrice] = useState<PricesItems>(null)

  const onChangedPartidaArancelariaLookUp = React.useCallback(
    (partidasArancelaria: PartidaArancelaria) => {
      dispatch(
        setTariffItem({
          key: tabId,
          TariffItem: partidasArancelaria,
        }),
      )
    },
    [dispatch, tabId],
  )

  const onChangedPartidaArancelaria = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setDataPricesControlToEdit({
            key: tabId,
            priceControl: {
              ...dataToEditPriceControls,
              partidaArancelaria: event.value,
            },
          }),
        )
    },
    [dispatch, dataToEditPriceControls, tabId],
  )

  const onChangedCosteos = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setDataPricesControlToEdit({
            key: tabId,
            priceControl: {
              ...dataToEditPriceControls,
              costeos: event.value,
            },
          }),
        )
    },
    [dispatch, dataToEditPriceControls, tabId],
  )

  const onChangeSustentosTributarios = React.useCallback(
    (sustentoTributario: SutentosTributarios) => {
      dispatch(
        setTaxSupport({
          key: tabId,

          taxSupport: sustentoTributario,
        }),
      )
    },
    [dispatch, tabId],
  )

  const onChangedIbnpr = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setDataPricesControlToEdit({
            key: tabId,
            priceControl: {
              ...dataToEditPriceControls,
              ibnpr: event.value,
            },
          }),
        )
    },
    [dispatch, dataToEditPriceControls, tabId],
  )

  const onChangedTipoIce = React.useCallback(
    (tipoIce: TiposIce) => {
      dispatch(
        setIce({
          key: tabId,
          ice: tipoIce,
        }),
      )
    },
    [dispatch, tabId],
  )

  const onChangedTipoImpuesto = React.useCallback(
    (tipoImpuesto: OptionTiposImpuestos) => {
      dispatch(
        setTypeTax({
          key: tabId,
          tax: tipoImpuesto,
        }),
      )
    },
    [dispatch, tabId],
  )

  const onChangedUtilidadPvp = React.useCallback(
    (event: any) => {
      if (event.event) {
        const { value } = event
        const { costo, dataGridPrecios } = dataToEditPriceControls
        const newPricePvp = value > 0 ? costo + costo * (value / 100) : 0
        const utilidadPvp = value > 0 ? parseFloat(value.toFixed(2)) : 0
        const updatedPrices = structuredClone(dataGridPrecios)
        updatedPrices.forEach((price: PricesItems) => {
          if (price.tipoClienteCodigo === 1) {
            price.precioIva = parseFloat(newPricePvp.toFixed(decimalPrecision))
            price.utilidad = utilidadPvp
          }
        })
        dispatch(
          setDataPricesControlToEdit({
            key: tabId,
            priceControl: {
              ...dataToEditPriceControls,
              utilidadPvp: utilidadPvp,
              pvp: parseFloat(newPricePvp.toFixed(decimalPrecision)),
              dataGridPrecios: updatedPrices,
            },
          }),
        )
      }
    },
    [dispatch, dataToEditPriceControls, tabId, decimalPrecision],
  )

  const onChangedPvp = React.useCallback(
    (event: any) => {
      if (event.event) {
        const { value } = event
        const { dataGridPrecios } = dataToEditPriceControls
        const newPvp: number =
          value > 0 ? parseFloat(value.toFixed(decimalPrecision)) : 0
        const newArrayPrices = structuredClone(dataGridPrecios)
        newArrayPrices.forEach((price: PricesItems) => {
          if (price.tipoClienteCodigo === 1) price.precioIva = newPvp
        })
        dispatch(
          setDataPricesControlToEdit({
            key: tabId,
            priceControl: {
              ...dataToEditPriceControls,
              pvp: newPvp,
              dataGridPrecios: newArrayPrices,
            },
          }),
        )
      }
    },
    [decimalPrecision, dataToEditPriceControls, dispatch, tabId],
  )

  const onChangedComision = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setDataPricesControlToEdit({
            key: tabId,
            priceControl: {
              ...dataToEditPriceControls,
              comision: event.value,
            },
          }),
        )
    },
    [dispatch, dataToEditPriceControls, tabId],
  )

  const onChangedUtilidadPvd = React.useCallback(
    (event: any) => {
      if (event.event) {
        const { value } = event
        const { costo, dataGridPrecios } = dataToEditPriceControls
        const newPricePvd = value > 0 ? costo + costo * (value / 100) : 0
        const utilidadPvd = value > 0 ? parseFloat(value.toFixed(2)) : 0
        const updatedPrices = structuredClone(dataGridPrecios)
        updatedPrices.forEach((price: PricesItems) => {
          if (price.tipoClienteCodigo === 2) {
            price.precioIva = parseFloat(newPricePvd.toFixed(decimalPrecision))
            price.utilidad = utilidadPvd
          }
        })
        dispatch(
          setDataPricesControlToEdit({
            key: tabId,
            priceControl: {
              ...dataToEditPriceControls,
              utilidadPvd: utilidadPvd,
              pvd: parseFloat(newPricePvd.toFixed(decimalPrecision)),
              dataGridPrecios: updatedPrices,
            },
          }),
        )
      }
    },
    [dispatch, dataToEditPriceControls, tabId, decimalPrecision],
  )

  const onChangedPvd = React.useCallback(
    (event: any) => {
      if (event.event) {
        const { value } = event
        const { dataGridPrecios } = dataToEditPriceControls
        const newPvd: number =
          value === null ? 0 : parseFloat(value.toFixed(decimalPrecision))
        const newArrayPrices = structuredClone(dataGridPrecios)
        newArrayPrices.forEach((price: PricesItems) => {
          if (price.tipoClienteCodigo === 2) price.precioIva = newPvd
        })
        dispatch(
          setDataPricesControlToEdit({
            key: tabId,
            priceControl: {
              ...dataToEditPriceControls,
              pvd: newPvd,
              dataGridPrecios: newArrayPrices,
            },
          }),
        )
      }
    },
    [dispatch, dataToEditPriceControls, tabId, decimalPrecision],
  )

  const onChangedLocal = React.useCallback(
    (local: LocalOption) => {
      dispatch(
        setStore({
          key: tabId,
          store: local,
        }),
      )
    },
    [dispatch, tabId],
  )

  const onChangedTipoCliente = React.useCallback(
    (tipoCLiente: TipoCliente) => {
      dispatch(
        setTypeCustomer({
          key: tabId,
          typeCustomer: tipoCLiente,
        }),
      )
    },
    [dispatch, tabId],
  )

  const onChangedCosto = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setDataPricesControlToEdit({
            key: tabId,
            priceControl: {
              ...dataToEditPriceControls,
              costo:
                event.value > 0
                  ? parseFloat(event.value.toFixed(decimalPrecision))
                  : 0,
            },
          }),
        )
    },
    [dispatch, dataToEditPriceControls, tabId, decimalPrecision],
  )

  const onChangedLimitesPrecio = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setDataPricesControlToEdit({
            key: tabId,
            priceControl: {
              ...dataToEditPriceControls,
              limitePrecios: event.value,
            },
          }),
        )
    },
    [dispatch, dataToEditPriceControls, tabId],
  )

  const onChangedSinDescuento = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setDataPricesControlToEdit({
            key: tabId,
            priceControl: {
              ...dataToEditPriceControls,
              sinDescuento: event.value,
            },
          }),
        )
    },
    [dispatch, dataToEditPriceControls, tabId],
  )

  const onChangeGratuito = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setDataPricesControlToEdit({
            key: tabId,
            priceControl: {
              ...dataToEditPriceControls,
              gratuito: event.value,
            },
          }),
        )
    },
    [dispatch, dataToEditPriceControls, tabId],
  )

  const onChangedIncluyenIva = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setDataPricesControlToEdit({
            key: tabId,
            priceControl: {
              ...dataToEditPriceControls,
              incluyenIva: event.value,
            },
          }),
        )
    },
    [dispatch, dataToEditPriceControls, tabId],
  )

  const addItemPrice = React.useCallback(() => {
    const priceItems = structuredClone(dataToEditPriceControls.dataGridPrecios)
    const indexPreiceToAdd = priceItems.findIndex((priceItem: PricesItems) => {
      return (
        priceItem.localCodigo === dataToEditPriceControls.local?.codigo &&
        priceItem.tipoClienteCodigo ===
          dataToEditPriceControls.tipoCliente.codigo
      )
    })
    if (indexPreiceToAdd === -1) {
      priceItems.push({
        tipoClienteCodigo: dataToEditPriceControls.tipoCliente.codigo,
        tipoClienteDescripcion: dataToEditPriceControls.tipoCliente.descripcion,
        itemCodigo: 0,
        localCodigo: dataToEditPriceControls.local?.codigo ?? -1,
        localNombre: dataToEditPriceControls.local?.nombre ?? 'Todos',
        precio: 0,
        precioIva: 0,
        utilidad: 0,
      })
      dispatch(
        setDataPricesControlToEdit({
          key: tabId,
          priceControl: {
            ...dataToEditPriceControls,
            dataGridPrecios: [...priceItems],
          },
        }),
      )
    } else
      setToastMessage(
        'Agregar Precio',
        'El tipo de cliente y local ya se encuentran registrados',
        ToastTypes.Warning,
      )
  }, [dataToEditPriceControls, dispatch, setToastMessage, tabId])

  const deleteItemPrice = React.useCallback(() => {
    if (selectedPrice && selectedPrice?.localCodigo !== -1) {
      const priceItems = structuredClone(
        dataToEditPriceControls.dataGridPrecios,
      )
      const indexPreiceToDelete = priceItems.findIndex(
        (priceItem: PricesItems) => {
          return (
            priceItem.localCodigo === selectedPrice.localCodigo &&
            priceItem.tipoClienteCodigo === selectedPrice.tipoClienteCodigo
          )
        },
      )
      if (indexPreiceToDelete >= 0) priceItems.splice(indexPreiceToDelete, 1)
      dispatch(
        setDataPricesControlToEdit({
          key: tabId,
          priceControl: {
            ...dataToEditPriceControls,
            dataGridPrecios: [...priceItems],
          },
        }),
      )
    } else {
      let message: string = ' Debe seleccionar un precio para eliminarlo'
      if (selectedPrice) message = 'No puede eliminar un precio general'
      setToastMessage('Eliminar Precio', message, ToastTypes.Warning)
    }
    setSelectedPrice(null)
  }, [dataToEditPriceControls, dispatch, selectedPrice, setToastMessage, tabId])

  const updateCellValue = useCallback(
    (newData, value, currentRowData, columnToUpdate: string) => {
      if (value && currentRowData) {
        if ((value < 0 || value > 100) && columnToUpdate === 'utilidad') {
          setToastMessage(
            'Actualizar valor utilidad',
            'El valor de la utilidad debe estar entre 0 y 100',
            ToastTypes.Warning,
          )
          return false
        }
        if (value < 0 && columnToUpdate === 'precioIva') {
          setToastMessage(
            'Actualizar valor precio',
            'El valor del precio no puede ser menor a 0',
            ToastTypes.Warning,
          )
          return false
        }
        newData = structuredClone(dataToEditPriceControls.dataGridPrecios)
        const indexUtilityToUpdate = newData.findIndex(
          (priceItem: PricesItems) => {
            return (
              priceItem.localCodigo === currentRowData.localCodigo &&
              priceItem.tipoClienteCodigo === currentRowData.tipoClienteCodigo
            )
          },
        )
        let priceToUpdate: string = ''
        if (columnToUpdate === 'utilidad') {
          currentRowData.utilidad = parseFloat(value.toFixed(2))
          if (currentRowData.tipoClienteDescripcion === 'Publico General')
            priceToUpdate = 'utilidadPvp'
          else priceToUpdate = 'utilidadPvd'
        } else {
          currentRowData.precioIva = parseFloat(value.toFixed(decimalPrecision))
          if (currentRowData.tipoClienteDescripcion === 'Publico General')
            priceToUpdate = 'pvp'
          else priceToUpdate = 'pvd'
        }
        newData[indexUtilityToUpdate] = currentRowData
        dispatch(
          setDataPricesControlToEdit({
            key: tabId,
            priceControl: {
              ...dataToEditPriceControls,
              dataGridPrecios: [...newData],
              [priceToUpdate]: value,
            },
          }),
        )
      }
    },
    [
      dataToEditPriceControls,
      dispatch,
      setToastMessage,
      tabId,
      decimalPrecision,
    ],
  )

  const validateCosteo = React.useCallback(
    (selected: any) => {
      if (selected?.value.value < 1 && typeItem.value === 1)
        return Promise.reject('El campo Costeo es obligatorio')
      else return Promise.resolve()
    },
    [typeItem.value],
  )

  const validateIva = React.useCallback((selected: any) => {
    if (selected?.value?.codigo < 1)
      return Promise.reject(
        'El IVA no se encuentra seleccionado, por favor elija una opción.',
      )
    else return Promise.resolve()
  }, [])

  return (
    <RowContainer className="m-2">
      <RowContainer>
        <CustomCol xs="12" md="7">
          <Labeled label="Precios">
            <CRow>
              <CCol xs="12" md="3">
                <CheckBox
                  text="Incluyen I.V.A."
                  value={dataToEditPriceControls.incluyenIva}
                  onValueChanged={onChangedIncluyenIva}
                  disabled={true}
                />
              </CCol>
              <CCol xs="12" md="3">
                <CheckBox
                  text="Gratuito"
                  value={dataToEditPriceControls.gratuito}
                  onValueChanged={onChangeGratuito}
                />
              </CCol>
              <CCol xs="12" md="3">
                <CheckBox
                  text="Sin Descuento"
                  value={dataToEditPriceControls.sinDescuento}
                  onValueChanged={onChangedSinDescuento}
                />
              </CCol>
              <CCol xs="12" md="3">
                <CheckBox
                  text="Límite Precios"
                  value={dataToEditPriceControls.limitePrecios}
                  onValueChanged={onChangedLimitesPrecio}
                />
              </CCol>
            </CRow>
          </Labeled>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="7">
          <CRow>
            <CustomCol
              xs="12"
              md="5"
              className={isMobile ? 'mt-1 p-0' : 'mt-2 mr-2 p-0'}
            >
              <TipoClienteLookUp
                selected={dataToEditPriceControls.tipoCliente}
                onChanged={onChangedTipoCliente}
              />
            </CustomCol>
            <CustomCol
              xs="12"
              md="5"
              className={isMobile ? 'mt-1 p-0' : 'mt-2 ml-2 p-0'}
            >
              <LocalesLookUp
                selected={dataToEditPriceControls.local}
                onChanged={onChangedLocal}
                onChangedOptions={() => {}}
                message="Todos"
              />
            </CustomCol>
            <CustomCol
              xs="12"
              md="2"
              className={
                isMobile
                  ? 'd-flex justify-content-end mt-1 p-0'
                  : 'd-flex justify-content-end mt-2 p-0'
              }
            >
              <div style={{ display: 'flex', marginLeft: 'auto' }}>
                <Button
                  id="btnAdd"
                  className="me-1"
                  icon="add"
                  stylingMode="contained"
                  type="default"
                  onClick={addItemPrice}
                  disabled={
                    dataToEditPriceControls.tipoCliente?.codigo <= 0 ||
                    !dataToEditPriceControls.local
                  }
                />
                <Button
                  id="btnRemove"
                  icon="trash"
                  stylingMode="contained"
                  type="danger"
                  onClick={deleteItemPrice}
                  disabled={!selectedPrice}
                />
              </div>
            </CustomCol>
          </CRow>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="7">
          <DataGrid
            className="mt-1"
            selection={{ mode: 'single' }}
            hoverStateEnabled={true}
            dataSource={dataToEditPriceControls.dataGridPrecios}
            onSelectionChanged={({ selectedRowsData }) =>
              setSelectedPrice(selectedRowsData[0])
            }
            loadPanel={{ enabled: true }}
            repaintChangesOnly
            showBorders={true}
            showRowLines={true}
            height={'140px'}
          >
            <Editing mode="cell" allowUpdating />
            <Column
              dataType="string"
              dataField="tipoClienteDescripcion"
              caption="Tipo"
              width="25%"
              allowEditing={false}
            />
            <Column
              dataType="string"
              dataField="localNombre"
              caption="Local"
              width="25%"
              allowEditing={false}
            />
            <Column
              dataField="utilidad"
              caption="Utilidad %"
              width="25%"
              allowEditing={true}
              setCellValue={(newData, value, currentRowData) =>
                updateCellValue(newData, value, currentRowData, 'utilidad')
              }
            />
            <Column
              dataField="precioIva"
              caption="Precio"
              width="25%"
              allowEditing={true}
              setCellValue={(newData, value, currentRowData) =>
                updateCellValue(newData, value, currentRowData, 'precioIva')
              }
            />
          </DataGrid>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="7">
          <CRow>
            <CCol xs="12" md="4">
              <Labeled label="PVD *:">
                <NumberBox
                  value={dataToEditPriceControls.pvd}
                  onValueChanged={onChangedPvd}
                  min={0}
                />
              </Labeled>
            </CCol>
            <CCol xs="12" md="4">
              <Labeled label="Uti. PVD:">
                <NumberBox
                  value={dataToEditPriceControls.utilidadPvd}
                  onValueChanged={onChangedUtilidadPvd}
                  max={100}
                  min={0}
                  step={0.01}
                />
              </Labeled>
            </CCol>
            <CCol xs="12" md="4">
              <Labeled label="Comisión:">
                <NumberBox
                  value={dataToEditPriceControls.comision}
                  onValueChanged={onChangedComision}
                  min={0}
                />
              </Labeled>
            </CCol>
          </CRow>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="7">
          <CRow>
            <CCol xs="12" md="4">
              <Labeled label="PVP *:">
                <NumberBox
                  value={dataToEditPriceControls.pvp}
                  onValueChanged={onChangedPvp}
                  min={0}
                />
              </Labeled>
            </CCol>
            <CCol xs="12" md="4">
              <Labeled label="Uti. PVP:">
                <NumberBox
                  value={dataToEditPriceControls.utilidadPvp}
                  onValueChanged={onChangedUtilidadPvp}
                  max={100}
                  min={0}
                  step={0.01}
                />
              </Labeled>
            </CCol>
            <CCol xs="12" md="4">
              <Labeled label="Costo:">
                <div
                  style={{ display: 'flex', gap: '10px', alignItems: 'center' }}
                >
                  <NumberBox
                    value={dataToEditPriceControls.costo}
                    onValueChanged={onChangedCosto}
                    readOnly={true}
                  />
                  <CTooltip
                    key={'tool-more-auth-side'}
                    placement="right"
                    content={
                      dataToEditPriceControls.costo > 0
                        ? 'Kardex'
                        : 'Registrado'
                    }
                  >
                    <CLink className="card-header-action m-0">
                      <CIcon icon={cilInfo} />
                    </CLink>
                  </CTooltip>
                </div>
              </Labeled>
            </CCol>
          </CRow>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <Labeled label="Impuestos *">
          <CRow>
            <CCol xs="12" md="3">
              <div
                style={{
                  display: 'flex',
                  gap: '10px',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                {'IVA:'}
                <TiposImpuestosLookup
                  selected={dataToEditPriceControls.tipoImpuesto}
                  onChanged={onChangedTipoImpuesto}
                  onChangedOptions={() => {}}
                >
                  <Validator>
                    <AsyncRule validationCallback={validateIva} />
                    <RequiredRule
                      message={
                        'El IVA no se encuentra seleccionado, por favor elija una opción.'
                      }
                    />
                  </Validator>
                </TiposImpuestosLookup>
              </div>
            </CCol>
            <CCol xs="12" md="3">
              <div
                style={{
                  display: 'flex',
                  gap: '10px',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                {'ICE:'}
                <TiposIceLookUp
                  selected={dataToEditPriceControls.tipoIce}
                  onChanged={onChangedTipoIce}
                  onChangedOptions={() => {}}
                />
              </div>
            </CCol>
            <CCol xs="12" md="2" style={{ display: 'flex' }}>
              <CheckBox
                text="IBNPR"
                value={dataToEditPriceControls.ibnpr}
                onValueChanged={onChangedIbnpr}
              />
            </CCol>
          </CRow>
        </Labeled>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="3">
          <Labeled label="Sustento Tributario">
            <SustentosTributariosLookUp
              selected={dataToEditPriceControls.sustentoTributario}
              onChanged={onChangeSustentosTributarios}
              onChangedOptions={() => {}}
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="4">
          <Labeled label="Costeo">
            <RadioGroup
              name="idTypesCosteos"
              dataSource={optionsGroupsCosteo}
              value={dataToEditPriceControls.costeos}
              layout="horizontal"
              displayExpr="label"
              onValueChanged={onChangedCosteos}
              width="100%"
            >
              <Validator>
                <AsyncRule validationCallback={validateCosteo} />
                <RequiredRule message={'El campo Costeo es obligatorio'} />
              </Validator>
            </RadioGroup>
          </Labeled>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <Labeled label="Partida Arancelaria">
          <CRow>
            <CCol xs="12" md="3">
              <TextBox
                value={dataToEditPriceControls.partidaArancelaria}
                placeholder=""
                onValueChanged={onChangedPartidaArancelaria}
                showClearButton={true}
                width="100%"
                inputAttr={{ autocomplete: 'nope' }}
              />
            </CCol>
            <CCol xs="12" md="3">
              <PartidaArancelariaLookUp
                selected={dataToEditPriceControls.partidaArancelariaLookup}
                onChanged={onChangedPartidaArancelariaLookUp}
                onChangedOptions={() => {}}
                disabled={originItem?.value === 1 ? false : true}
              />
            </CCol>
          </CRow>
        </Labeled>
      </RowContainer>
    </RowContainer>
  )
}

export default React.memo(PreciosControl)

export const defaultPreciosControl: ItemPriceControl = {
  incluyenIva: null,
  gratuito: false,
  sinDescuento: false,
  limitePrecios: false,
  costo: 0,
  tipoCliente: null,
  local: null,
  dataGridPrecios: null,
  pvd: 0,
  utilidadPvd: 0,
  pvp: 0,
  utilidadPvp: 0,
  comision: 0,
  tipoImpuesto: { codigo: 3, descripcion: '', valor: 0 },
  tipoIce: null,
  ibnpr: false,
  sustentoTributario: null,
  costeos: optionsGroupsCosteo[2],
  partidaArancelaria: '',
  partidaArancelariaLookup: null,
}
