import React from 'react';
import SelectBox from 'devextreme-react/select-box';
import { PaisOption } from './store/type';
import { v4 as uuidv4 } from 'uuid'
import { GeneralService } from '../../../services/general.service';
import { localidadesService } from '../../../services/localidades.service';
import { utilidades } from '../../../helpers/utilidades';
import { CapitalizeMode } from '../../../store/types';

interface PaisesLookUpProps extends React.PropsWithChildren {
  selected: PaisOption | null | { codigo: number }
  onChanged: (newValue: PaisOption | null) => void
  onChangedOptions?: (newValue: Array<PaisOption> | []) => void
  disabled?: boolean
  provider?: Array<PaisOption> | []
  id?: string
  defecto?: boolean
}

const PaisesLookUp: React.FC<PaisesLookUpProps> = (props) => {
  const { id, provider, selected, onChanged, disabled, onChangedOptions, defecto = true } = props
  const selectRef = React.useRef<any>()
  const [options, setOptions] = React.useState<Array<PaisOption> | []>([])

  const loadDatos = React.useCallback(async () => {
    if (provider !== undefined && provider.length > 0) {
      setOptions(provider)
      let marcarOption: PaisOption = provider[0]
      const selectedIndex = provider.findIndex((select: PaisOption) => {
        return select.codigo === selected?.codigo
      });
      if (selectedIndex > -1) {
        marcarOption = provider[selectedIndex] ?? provider[0];
      }
      onChanged(marcarOption)
    } else {
      let data = null
      if (defecto) {
        data = await GeneralService.getCountries();
      } else {
        let paisOpciones: PaisOption[] = []
        const dataPais = await localidadesService.getDpPaises("Elija una opción");
        if (dataPais.length > 0) {
          paisOpciones = dataPais.map(x => {
            const p: PaisOption = {
              codigo: x?.codigo ?? 0,
              nombre: utilidades.capText(x?.nombre, CapitalizeMode.upper) ?? '',
              uaf: x?.codigoUaf ?? ''
            }
            return p
          })
          data = {
            error: false,
            auto: paisOpciones
          }
        }
      }

      if (data.error === false && data?.auto && data?.auto.length > 0) {
        let opt = []
        if (data.auto.length > 0) {
          opt = data.auto ?? []
        }
        setOptions(opt)
        if (onChangedOptions) {
          onChangedOptions(opt)
        }
        const selectedIndex = opt.findIndex((option) => {
          return option.codigo === selected?.codigo
        })
        let marcaOption = opt[0];
        if (selectedIndex > -1) {
          marcaOption = opt[selectedIndex];
        }
        onChanged(marcaOption)
      }
    }

  }, [onChanged, onChangedOptions, selected, provider, defecto])

  const getItemDisplayExpr = React.useCallback((item: PaisOption) => {
    return item ? `${item.nombre}` : ''
  }, [])

  const onLookUpValueChanged = React.useCallback((evt: any) => {
    if (evt !== undefined) {
      onChanged(evt)
    }
  }, [onChanged])

  const onSelectBoxFocusIn = React.useCallback((e) => {
    e.element.querySelector('input.dx-texteditor-input').select()
  }, [])

  const refreshSelected = React.useCallback(() => {
    if (options && options.length > 0) {
      const selectedIndex = options.findIndex((option: PaisOption) => {
        return option.codigo === selected?.codigo
      })
      let marcarOpcion = options[0];
      if (selectedIndex > -1) {
        marcarOpcion = options[selectedIndex];
      }
      selectRef.current.instance.repaint();
      onChanged(marcarOpcion)
    }
  }, [onChanged, options, selected])

  React.useEffect(() => {
    loadDatos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    refreshSelected()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  return (
    <SelectBox
      id="paisSelect"
      key={id ?? uuidv4()}
      ref={selectRef}
      items={options ?? []}
      searchEnabled
      displayExpr={getItemDisplayExpr}
      searchExpr={['nombre']}
      searchTimeout={500}
      onEnterKey={(e) => { console.log(e) }}
      value={selected}
      onValueChange={onLookUpValueChanged}
      // onValueChanged={onLookUpValueChanged}
      disabled={disabled}
      onFocusIn={onSelectBoxFocusIn}
    >
      {props.children}
    </SelectBox>
  )
}
export default React.memo(PaisesLookUp);
