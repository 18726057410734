import { CCardBody, CCardHeader, CCollapse, CLink } from '@coreui/react-pro'
import React from 'react'
import CIcon from '@coreui/icons-react';
import { cilChevronBottom, cilChevronTop } from '@coreui/icons';
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer';
import CustomCol from '../../../../../../../views/componentes/colContainer';
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput';
import { CheckBox, TextBox } from 'devextreme-react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../../store/store';
import { changeFilter, setCollapsed } from '../../store/serachReducer';

interface ISearchProps extends React.PropsWithChildren {
  onSearchEnter: () => void
}


const FiltroBusqueda: React.FunctionComponent<ISearchProps> = (props) => {
  const { onSearchEnter } = props
  const dispatch = useDispatch()

  const searchFilter = useSelector((state: RootState) => state.inventarios.catalogos.atributos.search.filter)
  const collapsed = useSelector((state: RootState) => state.inventarios.catalogos.atributos.search.colapsed)


  const onValueChanged = React.useCallback((data, key: string) => {
    if (data?.event !== null && data?.event !== undefined) {
      dispatch(changeFilter({
        ...searchFilter,
        [key]: data.value
      }))
    }
  }, [dispatch, searchFilter])


  return (
    <>
      <CCardHeader
        onClick={() => dispatch(setCollapsed(!collapsed))}
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <strong> {collapsed ? '' : 'Filtro de Búsqueda'} </strong>
        <CLink className="card-header-action">
          <CIcon icon={collapsed ? cilChevronBottom : cilChevronTop} />
        </CLink>
      </CCardHeader>
      <CCollapse visible={collapsed}>
        <CCardBody>
          <RowContainer>
            <CustomCol xs="12" md="3">
              <Labeled label="Propiedad:">
                <TextBox
                  value={searchFilter?.propiedad ?? ''}
                  onValueChanged={(data) => onValueChanged(data, 'propiedad')}
                  onEnterKey={onSearchEnter}
                />
              </Labeled>
            </CustomCol>
            <CustomCol xs="12" md="3" style={{ marginTop: 'auto' }}>
              <Labeled label=''>
                <CheckBox text="Activos" value={searchFilter?.activo ?? false} onValueChanged={(data) => onValueChanged(data, 'activo')} />
              </Labeled>
            </CustomCol>
          </RowContainer>
        </CCardBody>
      </CCollapse>
    </>
  )
}

export default React.memo(FiltroBusqueda)