import React from 'react'
import Modalform from '../../../views/componentes/modalform'
import { CBadge, CCard, CCardBody, CCol } from '@coreui/react-pro'
import { DataGrid, LoadPanel } from 'devextreme-react'
import { useDispatch } from 'react-redux'
import { Column } from 'devextreme-react/data-grid'
import { DetalleVenta } from './store/types'
import { modalDetallesVenta } from './services/detallesVenta.service'
import { ToastTypes } from '../../../store/types'
import RowContainer from '../../../views/componentes/rowContainer/rowContainer'
import { addToast } from '../../../store/toasterReducer'
import { AbonarXPagar } from '../../proveedores/pages/docsCtasXPagar/components/abonar/service/abonarxPagar.service'

interface IModalDetallesVentaProps extends React.PropsWithChildren {
  show: boolean
  codigoVenta: number
  codigoModulo?: number
  onClose: () => void
}
const ModalDetallesVenta: React.FC<IModalDetallesVentaProps> = (props) => {
  const dispatch = useDispatch()

  const [detallesV, setDetalles] = React.useState<Array<DetalleVenta>>([])
  const [local, setLocal] = React.useState<string>('')
  const [loader, setLoader] = React.useState<{
    show: boolean
    mensaje: string
  }>({ show: false, mensaje: '' })

  const setToast = React.useCallback(
    (message: string, type: ToastTypes) => {
      dispatch(
        addToast({
          content: message,
          type: type,
        }),
      )
    },
    [dispatch],
  )

  const onDetallesParse = React.useCallback(
    (detalles: any): Array<DetalleVenta> => {
      const detallesVenta: Array<DetalleVenta> = []
      if (detalles.length === 0) return detallesVenta
      for (const detalle of detalles) {
        const det: DetalleVenta = {
          codigo: props?.codigoModulo === 25 ? detalle.itemBarras : detalle.codigoInterno,
          descripcion: props?.codigoModulo === 25 ? detalle.itemDescripcion : detalle.descripcion,
          cantidad: detalle.cantidad,
          descuento: detalle.descuento,
          precioUnitario: props?.codigoModulo === 25 ? detalle.precioUnitario : detalle.pvp,
          valorVenta: detalle.valor,
        }
        detallesVenta.push(det)
      }
      return detallesVenta
    },
    [props?.codigoModulo],
  )

  const getDetallesVenta = React.useCallback(async () => {
    setLoader({ show: true, mensaje: 'Cargando...' })
    try {
      const find = {
        codigo: props.codigoVenta,
        tipoDoc: '04',
      }
      let detalles = null
      if (props.codigoModulo && props.codigoModulo === 25) {
        detalles = await AbonarXPagar.PurchaseDetail(props.codigoVenta)
      } else {
        detalles = await modalDetallesVenta.getDetallesVenta(find)
      }
      if (!detalles.error) {
        if (detalles.auto.length === 0)
          setToast('La búsqueda no produjo resultados', ToastTypes.Success)
        else {
          const detallesVenta = onDetallesParse(detalles.auto)
          setDetalles(detallesVenta)
        }
      } else {
        setToast(detalles.message, ToastTypes.Warning)
      }
      const local = await modalDetallesVenta.getLocal(props.codigoVenta)
      if (!local.error) {
        if (local.auto.length === 0)
          setToast(
            'La búsqueda de Local no produjo resultados',
            ToastTypes.Success,
          )
        else {
          setLocal(local.auto)
        }
      } else {
        setToast(local.message, ToastTypes.Warning)
      }
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
    setLoader({ show: false, mensaje: '' })
  }, [onDetallesParse, props.codigoVenta, setToast, props.codigoModulo])

  React.useEffect(() => {
    getDetallesVenta()
  }, [])

  const bodyModal = () => {
    return (
      <>
        <RowContainer>
          <CCol className="d-flex justify-content-start">
            <div>{`Local: ${local}`}</div>
          </CCol>
          <CCol className="d-flex justify-content-end">
            <div>
              <CBadge color="info">
                {`${detallesV.length} REGISTROS ENCONTRADOS`}
              </CBadge>
            </div>
          </CCol>
        </RowContainer>
        <DataGrid
          style={{ marginTop: '5px' }}
          hoverStateEnabled={true}
          dataSource={detallesV}
          showBorders={true}
          showRowLines={true}
        >
          <Column dataField="codigo" dataType="string"
            caption={props?.codigoModulo === 25 ? "Barras" : "Código"} width="15%" />
          <Column dataField="descripcion"
            caption="Descripción" width="37%" />
          <Column dataField="cantidad" caption="Cantidad" width="9%" />
          <Column dataField="descuento" caption="Desc. %" width="9%" />
          <Column
            dataField="precioUnitario"
            caption="P. Unitario"
            width="15%"
          />
          <Column dataField="valorVenta" caption="Valor Venta" width="15%" />
        </DataGrid>
      </>
    )
  }
  const renderContent = () => {
    return (
      <CCard>
        <CCardBody>
          <LoadPanel
            shadingColor="rgba(0,0,0,0.4)"
            position={'center'}
            visible={loader.show}
            showIndicator={true}
            shading={true}
            showPane={true}
            message={loader.mensaje}
            closeOnOutsideClick={false}
          />
          {bodyModal()}
        </CCardBody>
      </CCard>
    )
  }

  return (
    <Modalform
      name="Detalles de la Venta"
      headerTitle={'Detalles de la Venta'}
      childrenBody={renderContent()}
      closeOnBackdrop={false}
      show={props.show}
      onClose={() => props.onClose()}
      centered={true}
      size="lg"
    />
  )
}

export default ModalDetallesVenta
