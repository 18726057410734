import { GeneralesState } from '../types/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LoaderInfo } from '../../../../../../store/types'

const initialState: GeneralesState = {
  modulo: 'inventarios',
  currentAction: 'Buscar',
  currentFunction: '',
  aplicacion: 'catalogos/marcas',
  loader: {
    show: false,
    mensaje: '',
  },
}

const marcasSlice = createSlice({
  name: 'catalogosMarcas',
  initialState: initialState,
  reducers: {
    setCurrentAccion(state, action: PayloadAction<string>) {
      state.currentAction = action.payload
    },
    setCurrentFunction(state, action: PayloadAction<string>) {
      state.currentFunction = action.payload
    },
    changeLoaderBrands(state, action: PayloadAction<LoaderInfo>) {
      state.loader = action.payload
    },
  },
})

export const { setCurrentAccion, setCurrentFunction, changeLoaderBrands } =
  marcasSlice.actions

export const generalReducer = marcasSlice.reducer
