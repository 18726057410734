import { LoaderInfo, Plan } from '../../../../store/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DeuPyDuePayments } from '../type/type'
import { RecurrenteData } from '../../../../containers/component/infoEmergente/contadorDocumentos/type/type'
import { CuotasPendientes } from '../../../../store/global/payTypes'

export const initialState: DeuPyDuePayments = {
  loader: {
    show: false,
    mensaje: '',
  },
  planes: [],
  vencidas: [],
  planSeleccionado: null,
  codPlanVigente: 0,
  valorPagar: 0,
  planVigente: null,
  display: 1,
  showCuentasBancarias: false,
  openPopupTerminos: false,
  collapseCaracteristicas: true,
  collapseContrato: false,
  collapseVencidas: false,
  mensajePopup: '',
  contratoEmpresa: null,
  showFacturas: false,
}

const pastduePaymentsSlice = createSlice({
  name: 'pastduePayments',
  initialState: initialState,
  reducers: {
    setShowFacturas(state, action: PayloadAction<boolean>) {
      state.showFacturas = action.payload
    },
    setContratoEmpresa(state, action: PayloadAction<RecurrenteData | null>) {
      state.contratoEmpresa = action.payload
    },
    setMensajePopup(state, action: PayloadAction<string>) {
      state.mensajePopup = action.payload
    },
    setLoaderPopup(state, action: PayloadAction<LoaderInfo>) {
      state.loader = action.payload
    },
    setDpPlanes(state, action: PayloadAction<Array<Plan>>) {
      state.planes = action.payload
    },
    setVencidas(state, action: PayloadAction<Array<CuotasPendientes> | []>) {
      state.vencidas = action.payload
    },
    setPlanSelec(state, action: PayloadAction<Plan | null>) {
      state.planSeleccionado = action.payload
    },
    setCodPlanVigente(state, action: PayloadAction<number>) {
      state.codPlanVigente = action.payload
    },
    setValorPagar(state, action: PayloadAction<number | string>) {
      state.valorPagar = action.payload
    },
    setPlanVigente(state, action: PayloadAction<Plan | null>) {
      state.planVigente = action.payload
    },
    setDisplay(state, action: PayloadAction<number>) {
      state.display = action.payload
    },
    setShowCuentasBancarias(state, action: PayloadAction<boolean>) {
      state.showCuentasBancarias = action.payload
    },
    setOpenPopupTerminos(state, action: PayloadAction<boolean>) {
      state.openPopupTerminos = action.payload
    },
    setCollapseCaracteristicas(state, action: PayloadAction<boolean>) {
      state.collapseCaracteristicas = action.payload
    },
    setCollapseContrato(state, action: PayloadAction<boolean>) {
      state.collapseContrato = action.payload
    },
    setCollapseVencidas(state, action: PayloadAction<boolean>) {
      state.collapseVencidas = action.payload
    },
  },
})

export const {
  setShowFacturas,
  setContratoEmpresa,
  setMensajePopup,
  setVencidas,
  setCollapseVencidas,
  setCollapseContrato,
  setCollapseCaracteristicas,
  setOpenPopupTerminos,
  setDisplay,
  setLoaderPopup,
  setDpPlanes,
  setPlanSelec,
  setCodPlanVigente,
  setValorPagar,
  setPlanVigente,
  setShowCuentasBancarias,
} = pastduePaymentsSlice.actions
export default pastduePaymentsSlice.reducer
export const pastDuePaymentsReducer = pastduePaymentsSlice.reducer
