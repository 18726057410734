import React, { useEffect, useState } from 'react'
import { CCard, CCardBody, CCol, CRow } from '@coreui/react-pro'
import { useDispatch } from 'react-redux'
import { changeLoader } from '../../../../../../store/reducers'
import Modalform from '../../../../../../views/componentes/modalform'
import { useSetToast } from '../../../../../../hooks/useGlobalHooks'
import { ToastTypes } from '../../../../../../store/types'
import { KardexServices } from '../../../kardex/services/kardex.services'

type ModalProcedenciaProps = {
  show: boolean
  onClose: () => void
  codeKardex: number
}

const ModalProcedencia = (props: ModalProcedenciaProps) => {
  const dispatch = useDispatch()
  const setToastMessage = useSetToast()

  const [procedencia, setProcedencia] = useState<string>('')

  const playLoader = React.useCallback(
    (message: string) => {
      dispatch(changeLoader({ show: true, mensaje: message }))
    },
    [dispatch],
  )

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoader({ show: false, mensaje: '' }))
  }, [dispatch])

  const closeModal = React.useCallback(() => {
    props.onClose()
  }, [props])

  const onSearchData = React.useCallback(async () => {
    try {
      playLoader('')
      const provenance = await KardexServices.getProvenance(props.codeKardex)
      stopLoader()
      if (!provenance.error && provenance.auto) setProcedencia(provenance.auto)
      else {
        closeModal()
        setToastMessage(
          'Buscar Procedencia Kardex',
          provenance.message ?? 'Error al buscar proveedores',
          ToastTypes.Warning,
        )
      }
    } catch (error) {
      stopLoader()
      setToastMessage('Buscar Procedencia Kardex', error, ToastTypes.Danger)
    }
  }, [playLoader, props.codeKardex, setToastMessage, stopLoader, closeModal])

  useEffect(() => {
    onSearchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const bodyModalForm = () => {
    return <>{procedencia}</>
  }

  const renderBody = () => {
    return (
      <>
        <CCard key="cardModalProveedoresKardex">
          <CCardBody>{bodyModalForm()}</CCardBody>
        </CCard>
      </>
    )
  }

  return (
    <CRow key="rowMain">
      <CCol key="colMain">
        <Modalform
          name="modalProcedenciaKardex"
          key="modalProcedenciaKardex"
          headerTitle={'Procedencia'}
          childrenBody={renderBody()}
          childrenFooter={<></>}
          closeOnBackdrop={false}
          show={props.show}
          onClose={closeModal}
          size="sm"
        />
      </CCol>
    </CRow>
  )
}

export default React.memo(ModalProcedencia)
