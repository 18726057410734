import { RequestHelper } from "../../../../../helpers/requestHelper";
import { SesionService } from "../../../../../services/sesion.service";
import { parseLocalOptions } from "../../../../componentes/localesLookUp/helper/helper";
import { LocalOption } from "../../../../componentes/localesLookUp/store/type";

export const adminService = {
  obtenerTiposIdentificacion, obtenerOperadoras, obtenerEstados, obtenerDatosUsuario, consultarUsuario, obtenerLocalesUsuario, generarPassword, modificarDatosUsuario, docUsuariosUplod
}

async function obtenerTiposIdentificacion(tipoCodigo: any, mensaje: string): Promise<any> {
  try {
    const obj = {
      tipo: tipoCodigo,
      mensaje: mensaje
    }
    const data = await RequestHelper.getAll<any>('cuenta', 'tipoId/listar', "", obj);
    return data;
  } catch (error) {
    return error;
  }
}


async function obtenerOperadoras(mensaje: string): Promise<any> {
  try {
    const obj = {
      tipoCodigo: "COMPRA",
      mensaje: mensaje,
    }
    const data = await RequestHelper.getAll<any>('generales', 'operadoras/listar', "", obj);
    return data;
  } catch (error) {
    return error;
  }
}

async function obtenerEstados(tipo: any, mensaje: string): Promise<any> {
  try {
    const obj = {
      tipoCodigo: tipo,
      mensaje: mensaje,
    }
    const data = await RequestHelper.getAll<any>('subtypes/cboxLoad', '', "", obj);
    return data;
  } catch (error) {
    return error;
  }
}

async function obtenerDatosUsuario(sesion): Promise<any> {
  try {
    const obj = {
    }
    const data = await RequestHelper.getAll<any>('cuenta', 'usuario/load', "", obj);
    return data;
  } catch (error) {
    return error;
  }
}


async function consultarUsuario(find): Promise<any> {
  try {
    const obj = {
      identificacion: find['identificacion']
    }
    const data = await RequestHelper.getAll<any>('cuenta', 'usuario/load', "", obj);
    return data;
  } catch (error) {
    return error;
  }
}

async function obtenerLocalesUsuario(message, parse: boolean = false): Promise<any> {
  const sesion = SesionService.getCurrentSesion();
  try {
    const obj = {
      codigoempresa: sesion.empresa.codigo,
      mensaje: message
    }
    const data = await RequestHelper.getAll<any>('usuarioLocales', 'cboxLoad', "", obj);
    if (parse) {
      let opt: Array<LocalOption> = [];

      if (data?.auto && data?.auto.length > 0) {
        const res: any = {}
        opt = parseLocalOptions(data.auto);
        res.error = false
        res.auto = opt
        return res
      }
    }
    return data;
  } catch (error) {
    return error;
  }
}

async function generarPassword(): Promise<any> {
  try {
    const obj = {
    }
    const data = await RequestHelper.getAll<any>('admin', 'usuarios/generatePassword', "", obj);
    return data;
  } catch (error) {
    return error;
  }
}

async function modificarDatosUsuario(objeto: any): Promise<any> {
  try {
    const obj = {
      infoRegistro: {
        ...objeto
      }
    }
    const data = await RequestHelper.postAll<any>('cuenta', 'usuario/register', obj);
    return data;
  } catch (error) {
    return error;
  }
}

async function docUsuariosUplod(objeto: any): Promise<boolean> {
  try {
    const data = await RequestHelper.postData<boolean>(
      "uploadS3",
      "uploadFile",
      objeto
    );
    return data;
  } catch (error) {
    return error;
  }
}