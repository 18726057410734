import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Local, ProvidersState, Project } from '../types/types'
import { PeriodoActivo } from '../../bancos/general/types'

const initialState: ProvidersState = {
  proyectos: [],
  locales: [],
  periodoContable: [],
}

const providersSlice = createSlice({
  name: 'providers',
  initialState: initialState,
  reducers: {
    setProjets(state, action: PayloadAction<Array<Project> | []>) {
      state.proyectos = action.payload
    },
    setLocs(state, action: PayloadAction<Array<Local> | []>) {
      state.locales = action.payload
    },
    setPeriodoContable(state, action: PayloadAction<Array<PeriodoActivo>>) {
      state.periodoContable = action.payload
    },
  },
})

export const { setProjets, setLocs, setPeriodoContable } =
  providersSlice.actions
export const providersReducer = providersSlice.reducer
