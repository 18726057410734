import React from 'react'
import { SelectBox as DevExSelectBox } from 'devextreme-react/select-box'

interface ISelectBoxBaseProps extends React.PropsWithChildren {
  id?: string
  ref?: any
  items?: any
  searchEnabled?: boolean
  displayExpr?: any
  searchExpr?: any
  searchTimeout?: number
  onEnterKey?: any
  value?: any
  onValueChanged?: any
  onValueChange?: any
  disabled?: boolean
  onFocusIn?: any
  defaultValue?: any
  inputAttr?: any
}

export function SelectBoxDE(props: ISelectBoxBaseProps) {
  const {
    id,
    ref,
    items,
    searchEnabled,
    displayExpr,
    searchExpr,
    searchTimeout,
    onEnterKey,
    value,
    onValueChanged,
    onValueChange,
    disabled,
    onFocusIn,
    defaultValue,
    inputAttr,
  } = props

  return (
    <DevExSelectBox
      {...(id && { id })}
      {...(ref && { ref })}
      {...(items && { items })}
      {...(searchEnabled && { searchEnabled })}
      {...(displayExpr && { displayExpr })}
      {...(searchExpr && { searchExpr })}
      {...(searchTimeout && { searchTimeout })}
      {...(onEnterKey && { onEnterKey })}
      {...(value && { value })}
      {...(onValueChanged && { onValueChanged })}
      {...(onValueChange && { onValueChange })}
      {...(disabled && { disabled })}
      {...(onFocusIn && { onFocusIn })}
      {...(defaultValue && { defaultValue })}
      {...(inputAttr && { inputAttr })}
    >
      {props.children}
    </DevExSelectBox>
  )
}
