import { CCardFooter } from '@coreui/react-pro'
import React from 'react'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import { DataGrid } from 'devextreme-react'
import { Column, Export, Pager, Paging, Button as DatagridButton } from 'devextreme-react/data-grid'
import { getAllowedPageSizes } from '../../../../../../../helpers/Helper'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import ItemsCountIndicator from '../../../../../../componentes/indicadorNroRegistros'
import { consoleService } from '../../../../../../../services/console.service'
import { setSeleccionarDato } from '../../store/serachReducer'
import { Movimiento } from '../../types/types'
import { ActionSheetCommandsMobile } from './ActionSheetCommandsMobile'
import { isMobile } from 'react-device-detect'
import { Workbook } from 'exceljs'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { saveAs } from 'file-saver';


interface IResultadosBusquedaProps extends React.PropsWithChildren {
  onDBEdit: () => void
}

const ResultadosBusqueda: React.FunctionComponent<IResultadosBusquedaProps> = (props) => {
  const { onDBEdit } = props
  const unitsDataGrid = React.useRef<any>()
  const dispatch = useDispatch()

  const searchResults = useSelector((state: RootState) => state.inventarios.movimientos.conceptos.search.resultados)

  const onSelectedChanged = React.useCallback(({ selectedRowsData }) => {
    consoleService.log('selected ', selectedRowsData[0])
    if (selectedRowsData[0]?.codigo) {
      dispatch(setSeleccionarDato({ ...selectedRowsData[0] }))
    }
  }, [dispatch])

  const onDBClick = React.useCallback(() => {
    onDBEdit()
  }, [onDBEdit])

  const actionSheetCommandsMobile = React.useCallback(
    (cellData) => {
      const data = cellData.data as Movimiento
      return (
        <ActionSheetCommandsMobile
          movimiento={data}
          key={data.codigo}
          onEdit={(data) => {
            dispatch(setSeleccionarDato({ ...data }))
            onDBEdit()
          }}
        />
      )
    },
    [dispatch, onDBEdit],
  )

  const onExportExcell = React.useCallback((e) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('Main sheet')
    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Inventario_Inicial.xlsx')
      });
    });
  }, [])

  return (
    <>
      <ItemsCountIndicator items={searchResults['auto'] ?? []} />
      <DataGrid
        ref={unitsDataGrid}
        keyExpr="codigo"
        id="tabConceptosMovimiento"
        selection={{ mode: 'single' }}
        dataSource={searchResults['auto'] ?? []}
        showColumnLines={true}
        showRowLines={true}
        showBorders={true}
        columnResizingMode="widget"
        focusedRowEnabled={true}
        onSelectionChanged={onSelectedChanged}
        onRowDblClick={onDBClick}
        onExporting={onExportExcell}
        loadPanel={{
          enabled: true,
          height: 90,
          indicatorSrc: '',
          shading: false,
          shadingColor: '',
          showIndicator: true,
          showPane: true,
          text: 'Cargando...',
          width: 200,
        }}
      >
        <Paging defaultPageSize={10} />
        <Pager
          visible={(searchResults['auto'] && searchResults['auto'].length > 0) ? true : false}
          allowedPageSizes={getAllowedPageSizes(searchResults['auto'] ?? [])}
          displayMode={'full'}
          showPageSizeSelector={true}
          showInfo={true}
          showNavigationButtons={true}
        />
        <Export enabled={true} allowExportSelectedData={true} />
        {
          !isMobile && (
            <Column type="buttons" width="50px">
              <DatagridButton
                icon="edit"
                hint="Editar"
                onClick={async (e) => {
                  consoleService.log('e', e)
                  const data: Movimiento = e.row.data
                  dispatch(setSeleccionarDato({ ...data }))
                  onDBEdit()
                }}
              />
            </Column>
          )
        }
        {isMobile && (
          <Column
            caption="Tareas"
            dataField="codigo"
            cellRender={actionSheetCommandsMobile}
            width="50px"
            allowFiltering={false}
          ></Column>
        )}
        <Column dataField="codigo" caption="Código" width="10%" />
        <Column dataField="descripcion" caption="Descripción" width="60%" />
        <Column dataField="asociadoDescripcion" caption="Asociado a" width="15%" />
        <Column dataField="cuentaDescripcion" caption="Cuenta Contable" width="15%" />
      </DataGrid>
    </>
  )
}

export default React.memo(ResultadosBusqueda)