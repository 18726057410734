import React, {
  FunctionComponent,
  PropsWithChildren,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput'
import { CheckBox, DataGrid, RadioGroup, TextBox } from 'devextreme-react'
import {
  Column,
  Export,
  Pager,
  Paging,
  Grouping,
} from 'devextreme-react/data-grid'
import {
  StoreList,
  StoreListResult,
  optListaPrecios,
  optNegativos,
  optionsSearch,
} from '../../types/types'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import {
  buttonClick,
  clearButtonClick,
  openTab,
  setButtons,
  setCurrentExecutingAction,
} from '../../store/tabsReducer'
import { useDispatch, useSelector } from 'react-redux'
import { MultistoreServices } from '../../services/multialmacen.services'
import { RootState } from '../../../../../../store/store'
import { ToastTypes } from '../../../../../../store/types'
import { addToast } from '../../../../../../store/toasterReducer'
import {
  changeLoaderMultistore,
  setCollpased,
  setCurrentFunction,
} from '../../store/generalReducer'
import { initDatosEdicion } from '../../store/editDataReduce'
import { v4 as uuidv4 } from 'uuid'
import {
  fetchStores,
  setCleanResult,
  setFilter,
} from '../../store/serachReducer'
import { getAllowedPageSizes } from '../../../../../../helpers/Helper'
import { TabState } from '../../../../../../store/genericTabTypes'
import ItemsCountIndicator from '../../../../../componentes/indicadorNroRegistros'
import { isMobile } from 'react-device-detect'
import { CCard, CCardHeader, CCollapse, CLink } from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilChevronBottom, cilChevronTop } from '@coreui/icons'

interface ISearchProps extends PropsWithChildren {
  tab: TabState<StoreList>
  tabId: string
}

const SearchStore: FunctionComponent<ISearchProps> = (props) => {
  const { tabId } = props

  const dispatch = useDispatch()
  const currentAction = useSelector(
    (state: RootState) =>
      state.inventarios.multialmacen.general.currentFunction,
  )
  const listResults = useSelector((state: RootState) => {
    return state.inventarios.multialmacen.search.resultList
  })
  const filter = useSelector((state: RootState) => {
    return state.inventarios.multialmacen.search.filter
  })
  const collapsed = useSelector(
    (state: RootState) => state.inventarios.multialmacen.general.collapse,
  )

  const [selectedStore, setSelectedStore] = useState<StoreListResult>(null)
  const dataGrid = useRef<any>()

  const playLoader = useCallback(
    (message = 'Cargando...') => {
      dispatch(changeLoaderMultistore({ show: true, mensaje: message }))
    },
    [dispatch],
  )

  const stopLoader = useCallback(() => {
    dispatch(changeLoaderMultistore({ show: false, mensaje: '' }))
  }, [dispatch])

  const onChangedName = useCallback(
    (data: any) => {
      if (data.event)
        dispatch(
          setFilter({
            ...filter,
            nombre: data.value,
          }),
        )
    },
    [dispatch, filter],
  )

  const onChangedState = useCallback(
    (data: any) => {
      if (data.event)
        dispatch(
          setFilter({
            ...filter,
            clase: data.value,
          }),
        )
    },
    [dispatch, filter],
  )

  const onChangedActive = useCallback(
    (data: any) => {
      if (data.event)
        dispatch(
          setFilter({
            ...filter,
            estado: data.value === true ? 1 : 0,
          }),
        )
    },
    [dispatch, filter],
  )

  const onSelectedStoreChanged = useCallback(
    ({ selectedRowsData, currentDeselectedRowKeys }) => {
      if (selectedRowsData && listResults.length > 0) {
        setSelectedStore(selectedRowsData[0])
        if (currentDeselectedRowKeys.length === 0)
          dispatch(
            setButtons({
              tabKey: tabId,
              buttons: {
                Nuevo: true,
                Guardar: false,
                Editar: true,
                Eliminar: true,
                Buscar: true,
                Imprimir: false,
                Deshacer: true,
                Limpiar: true,
              },
            }),
          )
      }
    },
    [dispatch, listResults, tabId],
  )

  const onCleanResultFilter = useCallback(() => {
    dispatch(setCleanResult())
  }, [dispatch])

  const onHandleEdit = useCallback(() => {
    const id = uuidv4()
    const dataStore: StoreList = {
      codigo: selectedStore.localCodigo,
      calseLocal: optionsSearch[selectedStore.localClase] ?? null,
      stockWeb: selectedStore.localWEB === 1 ? true : false,
      nombre: selectedStore.localNombre,
      siglas: selectedStore.localSiglas,
      inv: selectedStore.localSiglasLocal,
      desc: selectedStore.localDescuento,
      cuidad: {
        codigo: selectedStore.localCiudad,
        codigoPais: null,
        nombrePais: '',
        codigoProvincia: null,
        nombreProvincia: '',
        nombre: '',
      },
      direccion: selectedStore.localDireccion,
      telefono: selectedStore.localTelefono,
      gerente: selectedStore.localGerente,
      tipo: {
        value: selectedStore.localTipo,
        label: selectedStore.localTipoDescripcion,
      },
      pertence: { codigo: selectedStore.localPertenece } ?? null,
      transaccionEnCero:
        selectedStore.localKardex === -1
          ? optNegativos[1]
          : optNegativos[0] ?? null,
      usarListaPrecios:
        selectedStore.localListaPrecios == 1
          ? optListaPrecios[0]
          : optListaPrecios[1] ?? null,
      codigoUaf: selectedStore.ULOTipo,
      estado: {
        codigo: selectedStore.localEstado,
        descripcion: selectedStore.localEstadoDescripcion,
      },
      usuario: selectedStore.localUsuarioDokan,
      contrasena: selectedStore.localPasswordDokan,
      imagen: selectedStore.localLogo,
      imagenCodificada: '',
    }
    dispatch(
      initDatosEdicion({
        key: id,
        data: dataStore,
      }),
    )
    dispatch(
      openTab({
        key: id,
        store: dataStore,
      }),
    )
  }, [dispatch, selectedStore])

  const onHandleRemove = useCallback(async () => {
    try {
      dispatch(
        setCurrentExecutingAction({
          tabKey: tabId,
          buttonType: ButtonTypes.delete,
        }),
      )
      playLoader('Eliminando . . .')
      const deletePerformance = await MultistoreServices.deleteStore(
        selectedStore.localCodigo,
      )
      if (!deletePerformance.error) {
        stopLoader()
        onCleanResultFilter()
        const toAny: any = fetchStores(filter)
        dispatch(toAny)
        dispatch(
          addToast({
            id: '',
            autoHide: true,
            content: deletePerformance.message ?? 'Registro eliminado.',
            fade: true,
            title: 'Eliminar',
            type: ToastTypes.Success,
          }),
        )
      } else {
        dispatch(
          setCurrentExecutingAction({
            tabKey: tabId,
            buttonType: undefined,
          }),
        )
        stopLoader()
        dispatch(
          addToast({
            id: '',
            autoHide: true,
            content: deletePerformance.message ?? 'Error al eliminar.',
            fade: true,
            title: 'Eliminar',
            type: ToastTypes.Warning,
          }),
        )
      }
    } catch (error) {
      dispatch(
        setCurrentExecutingAction({
          tabKey: tabId,
          buttonType: undefined,
        }),
      )
      stopLoader()
      dispatch(
        addToast({
          id: '',
          autoHide: true,
          content:
            'Error al eliminar ' +
            (typeof error === 'string' ? error : JSON.stringify(error)),
          fade: true,
          title: 'Busqueda',
          type: ToastTypes.Danger,
        }),
      )
    }
  }, [
    dispatch,
    filter,
    onCleanResultFilter,
    playLoader,
    selectedStore,
    stopLoader,
    tabId,
  ])

  const handleButtonClick = useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.delete:
          onHandleRemove()
          break
        case ButtonTypes.edit:
          onHandleEdit()
          break
        default:
          break
      }
      dispatch(setCurrentFunction(''))
      dispatch(clearButtonClick(tabId))
    },
    [dispatch, onHandleRemove, onHandleEdit, tabId],
  )

  const onOpenItemToEdit = useCallback(() => {
    dispatch(buttonClick({ tabKey: tabId, button: ButtonTypes.edit }))
  }, [dispatch, tabId])

  const handleSearch = useCallback(() => {
    onCleanResultFilter()
    const toAny: any = fetchStores(filter)
    dispatch(toAny)
  }, [dispatch, filter, onCleanResultFilter])

  useEffect(() => {
    if (currentAction !== '') handleButtonClick(currentAction)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAction])

  return (
    <>
      <CCard>
        <CCardHeader
          onClick={() => dispatch(setCollpased(!collapsed))}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <strong> {!collapsed ? 'Filtro de Búsqueda' : ''} </strong>
          <CLink className="card-header-action">
            <CIcon icon={collapsed ? cilChevronBottom : cilChevronTop} />
          </CLink>
        </CCardHeader>
        <CCollapse visible={collapsed}>
          <RowContainer className="m-2">
            <RowContainer>
              <CustomCol xs="12" md="6">
                <Labeled label="Clase:">
                  <RadioGroup
                    name="idSearchOptionsPlace"
                    dataSource={optionsSearch}
                    value={filter.clase}
                    layout="horizontal"
                    displayExpr="label"
                    onValueChanged={onChangedState}
                    width="100%"
                  />
                </Labeled>
              </CustomCol>
            </RowContainer>
            <RowContainer>
              <CustomCol xs="12" md="6">
                <Labeled label="Nombre:">
                  <TextBox
                    value={filter.nombre}
                    placeholder=""
                    onValueChanged={onChangedName}
                    showClearButton={true}
                    width="100%"
                    inputAttr={{ autocomplete: 'nope' }}
                    onEnterKey={handleSearch}
                  />
                </Labeled>
              </CustomCol>
              <CustomCol xs="12" md="2">
                <Labeled label="Estado:">
                  <CheckBox
                    text="Activos"
                    value={filter.estado === 1 ? true : false}
                    onValueChanged={onChangedActive}
                  />
                </Labeled>
              </CustomCol>
            </RowContainer>
          </RowContainer>
        </CCollapse>
      </CCard>
      <ItemsCountIndicator items={listResults} />
      <RowContainer>
        <CustomCol xs="12" md="12">
          <DataGrid
            ref={dataGrid}
            keyExpr="localCodigo"
            focusedRowKey="codigo"
            id="tabCertificados"
            selection={{ mode: 'single' }}
            dataSource={listResults}
            showColumnLines={true}
            showRowLines={true}
            showBorders={true}
            columnResizingMode="widget"
            onSelectionChanged={onSelectedStoreChanged}
            onRowDblClick={onOpenItemToEdit}
            loadPanel={{ enabled: true }}
          >
            <Grouping autoExpandAll={true} />
            <Paging defaultPageSize={20} />
            <Pager
              visible={listResults.length > 0 ? true : false}
              allowedPageSizes={getAllowedPageSizes(listResults)}
              displayMode={'full'}
              showPageSizeSelector={true}
              showInfo={true}
              showNavigationButtons={true}
            />
            <Export enabled={true} allowExportSelectedData={true} />
            <Column
              groupIndex={0}
              dataField="localPerteneceDescripcion"
              caption="Local"
              width={isMobile ? '270px' : '25%'}
            />
            <Column
              dataField="localNombre"
              caption="Local"
              width={isMobile ? '270px' : '25%'}
            />
            <Column
              dataField="localClaseDescripcion"
              caption="Tipo"
              width={isMobile ? '120px' : '15%'}
            />
            <Column
              dataField="localCiudadNombre"
              caption="Ciudad"
              width={isMobile ? '120px' : '15%'}
            />
            <Column
              dataField="localDireccion"
              caption="Dirección"
              minWidth="165px"
              width={isMobile ? '165px' : '15%'}
            />
            <Column
              dataField="localTelefono"
              caption="Telefono"
              width={isMobile ? '120px' : '15%'}
            />
            <Column
              dataField="localGerente"
              caption="Gerente"
              width={isMobile ? '150px' : '15%'}
            />
          </DataGrid>
        </CustomCol>
      </RowContainer>
    </>
  )
}

export default memo(SearchStore)
