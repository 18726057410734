import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ButtonTypes } from '../../../../../views/componentes/globalMenu/types'
import { StatesEdition, TabTypes } from '../../../../ventas/types/enums'
import { TabsState, TabState } from '../../../../../store/genericTabTypes'
import {
  closeTab,
  tabExists,
} from '../../../../../hooks/useNavigationTabControls'
import { CustomButtons } from '../../../../../store/types'
import { Candidate } from '../types/types'
import { NewTabButtons } from '..'

const initialState: TabsState<Candidate> = {
  current: 'BUSQUEDA',
  tabs: {
    BUSQUEDA: {
      id: 0,
      canClose: false,
      editStatus: StatesEdition.doesNotApply,
      globalButtonClick: ButtonTypes.none,
      tittle: 'Búsqueda',
      type: TabTypes.busqueda,
      imprimir: false,
      tabKey: 'BUSQUEDA',
      buttons: {
        Nuevo: true,
        Guardar: false,
        Editar: false,
        Eliminar: false,
        Buscar: true,
        Imprimir: false,
        Limpiar: true,
        Deshacer: true,
      },
    },
  },
}

const tabsCandidatesSlice = createSlice({
  name: 'candidatesTabs',
  initialState: initialState,
  reducers: {
    closeTabCandidate: closeTab,
    openTab(
      state,
      action: PayloadAction<{
        key: string
        candidate?: Candidate
        templateMode?: boolean
      }>,
    ) {
      if (
        action.payload.candidate === undefined ||
        action.payload.templateMode
      ) {
        const tab: TabState<Candidate> = {
          id: -1,
          canClose: true,
          editStatus: StatesEdition.new,
          globalButtonClick: ButtonTypes.none,
          tittle: 'Nueva',
          type: TabTypes.documento,
          imprimir: false,
          tabKey: action.payload.key,
          info: {
            codigo: 0,
          },
          buttons: NewTabButtons,
          templateMode: action.payload.templateMode,
        }
        state.tabs[action.payload.key] = tab
        state.current = action.payload.key
      } else {
        const tabKey = tabExists(state.tabs, action.payload.candidate.codigo)
        if (tabKey) state.current = tabKey
        else {
          const { key, candidate } = action.payload
          const tab: TabState<Candidate> = {
            tabKey: key,
            id: candidate.codigo,
            tittle: `${candidate.codigo}-${candidate.apellidos} ${candidate.nombres}`,
            canClose: true,
            editStatus: StatesEdition.edit,
            type: TabTypes.documento,
            info: {
              codigo: candidate.codigo,
              info: candidate,
            },
            globalButtonClick: ButtonTypes.none,
            imprimir: false,
            buttons: { ...NewTabButtons, Imprimir: true },
          }
          state.tabs[key] = tab
          state.current = key
        }
      }
    },
    changeCurrentTab(state, action: PayloadAction<string>) {
      if (Object.prototype.hasOwnProperty.call(state.tabs, action.payload)) {
        state.current = action.payload
      }
    },
    setCurrentExecutingAction(
      state,
      action: PayloadAction<{
        tabKey: string
        buttonType: ButtonTypes | undefined
      }>,
    ) {
      const { buttonType, tabKey } = action.payload
      state.tabs[tabKey].currentExecutingAction = buttonType
    },
    setButtons(
      state,
      action: PayloadAction<{ tabKey: string; buttons: CustomButtons }>,
    ) {
      const { buttons, tabKey } = action.payload
      state.tabs[tabKey].buttons = buttons
      state.tabs[tabKey].currentExecutingAction = undefined
    },
    buttonClick(
      state,
      action: PayloadAction<{ tabKey: string; button: ButtonTypes }>,
    ) {
      state.tabs[action.payload.tabKey].globalButtonClick =
        action.payload.button
    },
    clearButtonClick(state, action: PayloadAction<string>) {
      if (Object.prototype.hasOwnProperty.call(state.tabs, action.payload)) {
        state.tabs[action.payload].globalButtonClick = ButtonTypes.none
      }
    },
    setNameTab(
      state,
      action: PayloadAction<{
        key: string
        codigo: number
        nombres: string
        apellidos: string
      }>,
    ) {
      const { key, codigo, nombres, apellidos } = action.payload
      state.tabs[key].tittle = `${codigo}-${apellidos} ${nombres}`
      state.tabs[key].info.codigo = codigo
    },
  },
})

export const {
  closeTabCandidate,
  openTab,
  changeCurrentTab,
  setCurrentExecutingAction,
  setButtons,
  buttonClick,
  clearButtonClick,
  setNameTab,
} = tabsCandidatesSlice.actions
export const tabsCandidatesReducer = tabsCandidatesSlice.reducer
