import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../../../../store/store";
import { ConfiguracionesOrdenPedido } from "../../../type/types";

const initialState: ConfiguracionesOrdenPedido = {
  ordenPedidoSeleccionado: null
}

const configuracionesOrdenPedidoSlice = createSlice(
  {
    name: 'configuraciones',
    initialState: initialState,
    reducers: {
      seleccionarCuadre(state, acion: PayloadAction<any>) {
        state.ordenPedidoSeleccionado = acion.payload;
      },
      resetSeleccioCuadre(state) {
        state.ordenPedidoSeleccionado = null;
      }
    }
  }
)

export const configuracionesOrdenPedidoReducer = configuracionesOrdenPedidoSlice.reducer;
export const {
  seleccionarCuadre, resetSeleccioCuadre
} = configuracionesOrdenPedidoSlice.actions;