import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoaderInfo } from "../../../../../../store/types";
import { PageState, Registros, Seleccionado, formularioBusqueda, TotalesBalance } from "./types";

const initialState: PageState = {
  modulo: 'contabilidad',
  currentAction: "Buscar",
  currentFunction: "",
  aplicacion: 'balanceGeneral',
  acciones: [],
  registros: [],
  registrosBackup: [],
  refrescaTabla: false,
  seleccionado: {
    index: null,
    row: null,
    selectedRow: null,
  },
  formularioBusqueda: {
    ASI_DIARIONRO: -1,
    INICIA_PERIODO: null,
    ASI_FECHA_D: new Date().toDateString(),
    ASI_FECHA_H: new Date().toDateString(),
    COD_PROYECTO: -1,
    COD_LOCALCC: -1,
    COD_TIPO_CTA: -1,
    CUENTA_COD: "",
    CUENTAS_ALL: false,
    IDIOMA_ALTERNO: false,
    VALOR: "",
    MAX_NIVEL: 1,
    desde: new Date().toDateString(),
    hasta: new Date().toDateString(),
  },
  totalesBalance: {
    activo: 0,
    pasivo: 0,
    patrimonio: 0,
    utilidad: 0,
  },
  loader: {
    show: false,
    mensaje: ''
  },
}

const balanceGeneralSlice = createSlice({
  name: "balanceGeneral",
  initialState: initialState,
  reducers: {
    obtenerAcciones(state, action: PayloadAction<any>) {
      state.acciones = action.payload
    },
    setData(state, action: PayloadAction<Array<Registros>>) {
      state.registros = action.payload
    },
    setDataBackup(state, action: PayloadAction<Array<Registros>>) {
      state.registrosBackup = action.payload
    },
    setCurrentAccion(state, action: PayloadAction<any>) {
      state.currentAction = action.payload
    },
    setCurrentFunction(state, action: PayloadAction<string>) {
      state.currentFunction = action.payload
    },
    setSeleccionarDato(state, action: PayloadAction<Seleccionado>) {
      const toAdd = {
        index: action.payload.index,
        row: action.payload.row,
        selectedRow: action.payload.selectedRow,
      }
      state.seleccionado = toAdd
    },
    setResetSeleccion(state) {
      const toAdd = {
        index: null,
        row: null,
        selectedRow: null,
      }
      state.seleccionado = toAdd
    },
    setFormularioBusqueda(state, action: PayloadAction<formularioBusqueda>) {
      state.formularioBusqueda = action.payload
    },
    setTotalesBalance(state, action: PayloadAction<TotalesBalance>) {
      state.totalesBalance = action.payload
    },
    changeLoaderBalanceGeneral(state, action: PayloadAction<LoaderInfo>) {
      state.loader = action.payload
    },
  }
})

export const { changeLoaderBalanceGeneral, setTotalesBalance, setCurrentFunction, obtenerAcciones, setData, setDataBackup, setCurrentAccion, setSeleccionarDato, setResetSeleccion, setFormularioBusqueda } = balanceGeneralSlice.actions;
export const balanceGeneralReducer = balanceGeneralSlice.reducer;