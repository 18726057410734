import { desencriptarJson, encriptarJson } from '../helpers/Helper'
import { autorizacionModuloDtestore } from './zustand/autorizacionModuloDte.store'

export const saveState = (state) => {
  try {
    if (state) {
      const serializedState = JSON.stringify(state)
      const encriptedState = encriptarJson(serializedState)
      //sessionStorage.setItem('reduxState', serializedState);
      sessionStorage.setItem('reduxState', encriptedState)
    }
  } catch {
    // ignore write errors
  }
}
export const loadAutorizacionModuloState = () => {
  try {
    const autorizacionDte = autorizacionModuloDtestore.getState().autorizacion;
    let serializedState = sessionStorage.getItem(autorizacionDte);
    if (serializedState === null) {
      serializedState = sessionStorage.getItem('autorizacionModulo')
      if (serializedState === null) {
        return undefined
      }
    }
    const data = JSON.parse(serializedState)
    return data
  } catch (err) {
    return undefined
  }
}
export const loadState = () => {
  try {
    const encriptedState = sessionStorage.getItem('reduxState')
    if (encriptedState === null) {
      return undefined
    }
    const serializedState = desencriptarJson(encriptedState)
    const data = JSON.parse(serializedState)
    // if(data.session){
    //   return data.session;
    // }else{
    //   return undefined;
    // }
    return data
  } catch (err) {
    return undefined
  }
}
