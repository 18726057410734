import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SujetoExcluidoListado } from '../types/types'
import { StatesEdition } from '../../../../../../store/enums'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import {
  CustomButtons,
  CustomDictionary,
  TiposComprobantesSLV,
} from '../../../../../../store/types'
import { InvoiceButtons } from '../../../../../ventas/components/ventas/venta/venta'
import { RootState } from '../../../../../../store/store'
import { TabTypes } from '../../../../../ventas/types/enums'
import { TabsState, TabState } from '../../../../../../store/genericTabTypes'

const initialState: TabsState<SujetoExcluidoListado> = {
  current: 'BUSQUEDA',
  tabs: {
    BUSQUEDA: {
      id: 0,
      canClose: false,
      editStatus: StatesEdition.doesNotApply,
      globalButtonClick: ButtonTypes.none,
      tittle: 'Búsqueda',
      type: TabTypes.busqueda,
      imprimir: false,
      tabKey: 'BUSQUEDA',
      buttons: {
        Buscar: true,
        Nuevo: true,
      },
    },
  },
}

const existeTab = (
  tabs: CustomDictionary<TabState<any>>,
  comprobante: number,
) => {
  for (const key in tabs) {
    const tab = tabs[key]
    if (tab.id === comprobante) return key
  }
}

const getNewTabKeyOnClose = (state: TabsState<any>, keyToClose: string) => {
  let newCurrent = ''
  for (const key in state.tabs) {
    if (newCurrent === '') {
      newCurrent = key
    }
    if (keyToClose === key) {
      return newCurrent
    }
  }
  return 'BUSQUEDA'
}

const tabsSujetoExcluidoSlice = createSlice({
  name: 'sujetoExcluidoTabs',
  initialState: initialState,
  reducers: {
    closeTab(state, action: PayloadAction<string>) {
      const newCurrent = getNewTabKeyOnClose(state, action.payload)
      state.current = newCurrent
      delete state.tabs[action.payload]
    },
    openTab(
      state,
      action: PayloadAction<{
        key: string
        compra?: SujetoExcluidoListado
        templateMode?: boolean
      }>,
    ) {
      if (action.payload.compra === undefined || action.payload.templateMode) {
        const tab: TabState<SujetoExcluidoListado> = {
          id: -1,
          canClose: true,
          editStatus: StatesEdition.new,
          globalButtonClick: ButtonTypes.none,
          tittle: 'Nueva',
          type: TabTypes.documento,
          imprimir: false,
          tabKey: action.payload.key,
          info: {
            codigo:
              action.payload.templateMode && action.payload.compra
                ? action.payload.compra.codigo
                : 0,
            info: action.payload.compra,
          },
          buttons: InvoiceButtons,
          templateMode: action.payload.templateMode,
        }
        state.tabs[action.payload.key] = tab
        state.current = action.payload.key
      } else {
        const tabKey = existeTab(state.tabs, action.payload.compra.codigo)
        if (tabKey) {
          state.current = tabKey
        } else {
          const tab: TabState<SujetoExcluidoListado> = {
            tabKey: action.payload.key,
            id: action.payload.compra.codigo,
            tittle: `${action.payload.compra.establecimiento}-${action.payload.compra.puntoEmision}-${action.payload.compra.numero}`,
            canClose: true,
            editStatus: StatesEdition.save,
            type: TabTypes.documento,
            info: {
              codigo: action.payload.compra.codigo,
              info: action.payload.compra,
            },
            globalButtonClick: ButtonTypes.none,
            imprimir: false,
            buttons: InvoiceButtons,
          }
          state.tabs[action.payload.key] = tab
          state.current = action.payload.key
        }
      }
    },
    changeEditStatus(
      state,
      action: PayloadAction<{
        tabKey: string
        estado: StatesEdition
        info?: SujetoExcluidoListado
        buttons: CustomButtons
      }>,
    ) {
      const { estado, tabKey, info, buttons } = action.payload
      state.tabs[tabKey].editStatus = estado
      state.tabs[tabKey].currentExecutingAction = undefined
      state.tabs[tabKey].buttons = buttons
      // if (info) {
      //   state.tabs[tabKey].info = {
      //     claveAcceso: info.claveAcceso,
      //     numero: info.codigo,
      //     tipoComprobante:  TiposComprobantesSLV.FacturaExcluidoSujeto,
      //     info: info ?? null,
      //   }
      //   state.tabs[tabKey].id = info.codigo
      //   state.tabs[
      //     tabKey
      //   ].tittle = `${info.establecimiento}-${info.puntoEmision}-${info.numero}`
      // }
    },
    buttonClick(
      state,
      action: PayloadAction<{ tabKey: string; button: ButtonTypes }>,
    ) {
      state.tabs[action.payload.tabKey].globalButtonClick =
        action.payload.button
    },
    clearButtonClick(state, action: PayloadAction<string>) {
      if (Object.prototype.hasOwnProperty.call(state.tabs, action.payload)) {
        state.tabs[action.payload].globalButtonClick = ButtonTypes.none
      }
    },
    changeCurrentTab(state, action: PayloadAction<string>) {
      if (Object.prototype.hasOwnProperty.call(state.tabs, action.payload)) {
        state.current = action.payload
      }

      //if (action.payload >= state.tabs.length) return;
    },
    setTabInfoAsInactive(state, action: PayloadAction<string>) {
      // const info = state.tabs[action.payload].info
      // if (info !== undefined) {
      //   if (info.info) {
      //     info.info.estadoDescripcion = 'ANULADO'
      //   }
      //   state.tabs[action.payload].info = info
      // }
    },
    setButtons(
      state,
      action: PayloadAction<{ tabKey: string; buttons: CustomButtons }>,
    ) {
      const { buttons, tabKey } = action.payload
      state.tabs[tabKey].buttons = buttons
      state.tabs[tabKey].currentExecutingAction = undefined
    },
    setCurrentExecutingAction(
      state,
      action: PayloadAction<{
        tabKey: string
        buttonType: ButtonTypes | undefined
      }>,
    ) {
      const { buttonType, tabKey } = action.payload
      state.tabs[tabKey].currentExecutingAction = buttonType
    },
  },
})

export const selectTabsRetenciones = (state: RootState) => {
  return state.compras.comprasSV.sujetoExcluido.tabs
}

export const {
  closeTab,
  openTab,
  changeCurrentTab,
  changeEditStatus,
  clearButtonClick,
  buttonClick,
  setButtons,
  setCurrentExecutingAction,
  setTabInfoAsInactive,
} = tabsSujetoExcluidoSlice.actions
export const tabsReducer = tabsSujetoExcluidoSlice.reducer
