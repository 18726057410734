import { RequestHelper } from '../../../../../../helpers/requestHelper'
import { FiltrosSearch, GuardarIventarioInicial } from '../types/types'

export const conceptsServices = {
  getOrdenes,
  saveInventario,
  deleteCOncept,
  getConsolidate,
  saveConsolidate,
}

async function getOrdenes(data: FiltrosSearch): Promise<any> {
  try {
    const obj = {
      numero: data.numero,
      tipo: data.tipo,
      cliente: data.cliente,
      observaciones: data.observaciones,
      estado: data.estado,
      fechaDesde: data.fechaDesde,
      fechaHasta: data.fechaHasta,
    }
    const dataApi = await RequestHelper.getAll<any>('inventario/movimientos/inventarioInicial', 'ordenes/getAll', '', obj)
    return dataApi
  } catch (error) {
    return error
  }
}

async function getConsolidate(data: any): Promise<any> {
  try {
    const obj = {
      local: data['local'],
      fecha: data['fecha'],
    }
    const dataApi = await RequestHelper.getAll<any>('inventario/movimientos/inventarioInicial', 'ordenes/consolidate', '', obj)
    return dataApi
  } catch (error) {
    return error
  }
}

async function saveConsolidate(data: any): Promise<any> {
  try {
    const obj = {
      infoRegistro: {
        local: data['local'],
        fechac: data['fechac'],
        fechacr: data['fechacr'],
      }
    }
    const dataApi = await RequestHelper.postAll<any>('inventario/movimientos/inventarioInicial', 'ordenes/save', obj)
    return dataApi
  } catch (error) {
    return error
  }
}


async function saveInventario(requestBody: GuardarIventarioInicial): Promise<any> {
  try {
    const obj = {
      ...requestBody
    }
    const dataApi = await RequestHelper.postAll<any>('ordenes/save', '', obj)
    return dataApi
  } catch (error) {
    return error
  }
}

async function deleteCOncept(performanceCode: number): Promise<any> {
  return Promise.resolve()
}
