import { DateUtils, formatoFechasApi } from '../../../../helpers/dateUtils'
import { RequestHelper } from '../../../../helpers/requestHelper'
import { utilidades } from '../../../../helpers/utilidades'
import { SesionService } from '../../../../services/sesion.service'
import { Combinacion } from '../../../inventario/pages/movimientos/combinaciones/types/types'

export const typesCombinationsServices = { getTypesCombinations }

async function getTypesCombinations(): Promise<any> {
  try {
    const sesion = SesionService.getCurrentSesion()
    const keysToCache: any = {
      dateKey: DateUtils.getCurrentDateAsString(formatoFechasApi),
      empresa: sesion?.empresa?.codigo,
    }
    const cacheKey = 'tiposCombinaciones' + JSON.stringify(keysToCache)
    const cached = sessionStorage.getItem(cacheKey)
    if (cached) {
      return { error: false, auto: JSON.parse(cached) }
    } else {
      const typesCombinationsData = await RequestHelper.getAll<any>(
        'inventario/movimientos',
        'combinaciones/cBoxLoad',
        '',
        { mensaje: 'Elija una combinación' },
      )
      if (typesCombinationsData?.auto?.length > 0) {
        const typesCombinations: Array<Combinacion> = []
        for (const typeCombination of typesCombinationsData.auto) {
          typesCombinations.push({
            codigo: typeCombination?.codigo ?? -1,
            numero: typeCombination?.numero ?? -1,
            descripcion: utilidades.capText(typeCombination?.descripcion) ?? '',
            resultado: typeCombination?.resultado ?? -1,
            resultadoDes: typeCombination?.resultadoDes ?? '',
            componente: typeCombination?.componente ?? -1,
            componenteBarra: typeCombination?.componenteBarra ?? '',
            componenteDes: typeCombination?.componenteDes ?? '',
            itemCosteo: typeCombination?.itemCosteo ?? -1,
            itemCosto: typeCombination?.itemCosto ?? -1,
            resultadoPrecio: typeCombination?.resultadoPrecio ?? -1,
            itemTipo: typeCombination?.itemTipo ?? -1,
            itemLotes: typeCombination?.itemLotes ?? -1,
            existencia: typeCombination?.existencia ?? -1,
            estado: typeCombination?.estado ?? '',
          })
        }
        sessionStorage.setItem(cacheKey, JSON.stringify(typesCombinations))
        return { error: false, auto: typesCombinations }
      } else
        return {
          error: true,
          message: typesCombinationsData.message
            ? typesCombinationsData.message
            : 'Error al obtener los  datos: Combinaciones',
        }
    }
  } catch (error) {
    return error
  }
}
