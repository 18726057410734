import React, { useCallback, useEffect, useState } from 'react'
import { ToastTypes } from '../../../../../store/types'
import RowContainer from '../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../views/componentes/colContainer'
import { DateBox, NumberBox, TextArea } from 'devextreme-react'
import { DateUtils } from '../../../../../helpers/dateUtils'
import { RegisterServices } from '../services/register.services'
import { DataHours, SaveRegisterData } from '../types/types'
import { consoleService } from '../../../../../services/console.service'

interface INuevoProps extends React.PropsWithChildren {
  onChanged: (data) => void
  selection?: any
  onCancel?: () => void
  funcion: string
  currentTab: string
  setCurrenTab: (currentTab: string) => void
  resetFunction: () => void
  resetSelected?: () => void
  setToast: (sms: string, type: ToastTypes) => void
  playLoader: () => void
  stopLoader: () => void
  data: DataHours
}


const Nuevo = (props: INuevoProps) => {
  const { onChanged, funcion, setToast, selection, playLoader, stopLoader, data, currentTab, resetFunction, resetSelected, setCurrenTab } = props
  const [fecha, setFecha] = useState<Date>(null)
  const [hora, setHora] = useState<number>(0)
  const [min, setmin] = useState<number>(0)
  const [observaciones, setObservaciones] = useState<string>('')


  const handleReset = useCallback(() => {
    setFecha(null)
    setHora(0)
    setmin(0)
    setObservaciones('')
  }, [])

  const handleSubmit = useCallback(async () => {
    playLoader()
    try {
      let nuevaFecha = ''
      if (fecha !== null) {
        nuevaFecha = DateUtils.dateToString(fecha, 'dd/MM/yyyy')
      }
      const data_save: SaveRegisterData = {
        codigoUsuario: data?.usuario ?? 0,
        fecha: nuevaFecha ?? '',
        dia: fecha.getDay() ?? 0,
        hora: `${hora}:${min}:00`,
        observaciones: observaciones ?? '',
        local: 0,
        tipo: 'ADM',
      }
      const dataSave = await RegisterServices.saveRegister(data_save)
      consoleService.log(dataSave, 'data save')
      if (dataSave?.auto && dataSave?.error === false) {
        setToast(dataSave?.message, ToastTypes.Success)
        handleReset()
        setCurrenTab('Buscar')
      } else {
        setToast(dataSave?.message, ToastTypes.Danger)
      }
    } catch (error) {
      setToast(error?.message, ToastTypes.Danger)
    }
    stopLoader()
  }, [setToast, data, playLoader, stopLoader, fecha, observaciones, hora, min, handleReset, setCurrenTab])



  useEffect(() => {
    if (currentTab === 'Nuevo') {
      if (funcion === 'Guardar') {
        handleSubmit()
      } else {
        handleReset()
      }
      resetFunction()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [funcion])

  return (
    <>
      <RowContainer>
        <CustomCol xs="12" md="2">
          <DateBox value={fecha} onValueChange={(data) => setFecha(data)} />
        </CustomCol>
        <CustomCol xs="12" md="2">
          <NumberBox value={hora} onValueChange={(data) => setHora(data)} />
        </CustomCol>
        <CustomCol xs="12" md="2">
          <NumberBox value={min} onValueChange={(data) => setmin(data)} />
        </CustomCol>
        <CustomCol xs="12" md="5">
          <TextArea value={observaciones} onValueChange={(data) => setObservaciones(data)} />
        </CustomCol>
      </RowContainer>
    </>
  )
}

export default Nuevo