import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  AnexoEdicionPayload,
  AnexoTransacDatosEdicion,
  ComprasType,
  DatosAnuladas,
  DatosDetalleRetenciones,
  DatosRetenciones,
  DatosVenta,
  DatosVentaEstablecimiento,
} from '../types/types'
import { impuestosClearDatosEdicion } from '../store/reducer'
import { CustomDictionary } from '../../../../../../types/generics'
import { LoaderInfo } from '../../../../../../../../store/types'

const initialState: CustomDictionary<AnexoTransacDatosEdicion> = {}
const datosEdicionAnexoSlice = createSlice({
  name: 'anexoDatosEdicionSV',
  initialState: initialState,
  reducers: {
    impuestosClearDatosEdicion,
    setDatosEdicion(
      state,
      action: PayloadAction<AnexoEdicionPayload<AnexoTransacDatosEdicion>>,
    ) {
      state[action.payload.key] = action.payload.data
    },
    updateRuc(
      state,
      action: PayloadAction<{ key: number | string; ruc: string }>,
    ) {
      state[action.payload.key].ruc = action.payload.ruc
    },
    updateCompras(
      state,
      action: PayloadAction<{
        key: number | string
        compras: Array<ComprasType> | []
      }>,
    ) {
      state[action.payload.key].compras = action.payload.compras
    },
    updateRetenciones(
      state,
      action: PayloadAction<{
        key: number | string
        retenciones: Array<DatosRetenciones> | []
      }>,
    ) {
      state[action.payload.key].retenciones = action.payload.retenciones
    },
    updateDetalleRetenciones(
      state,
      action: PayloadAction<{
        key: number | string
        detalleRetenciones: Array<DatosDetalleRetenciones> | []
      }>,
    ) {
      state[action.payload.key].detalleRetenciones =
        action.payload.detalleRetenciones
    },
    updateVentas(
      state,
      action: PayloadAction<{
        key: number | string
        ventas: Array<DatosVenta> | []
      }>,
    ) {
      state[action.payload.key].consumidorFinal = action.payload.ventas
    },
    updateVentasEstablecimiento(
      state,
      action: PayloadAction<{
        key: number | string
        ventasEstablecimiento: Array<DatosVentaEstablecimiento> | []
      }>,
    ) {
      state[action.payload.key].ventasEstablecimiento =
        action.payload.ventasEstablecimiento
    },
    updateAnulados(
      state,
      action: PayloadAction<{
        key: number | string
        anulados: Array<DatosAnuladas> | []
      }>,
    ) {
      state[action.payload.key].anulados = action.payload.anulados
    },
    //Establecer datos edicion para un tab nuevo
    initDatosEdicion(
      state,
      action: PayloadAction<AnexoEdicionPayload<AnexoTransacDatosEdicion>>,
    ) {
      if (!state[action.payload.key]) {
        state[action.payload.key] = action.payload.data
      }
    },
    setEditLoader(
      state,
      action: PayloadAction<{ key: number | string; info: LoaderInfo }>,
    ) {
      state[action.payload.key].loader = action.payload.info
    },
    setMuestraError(
      state,
      action: PayloadAction<{
        key: number | string
        tieneError: boolean
        mensajeError: string
      }>,
    ) {
      state[action.payload.key].tieneError = action.payload.tieneError
      state[action.payload.key].mensajeError = action.payload.mensajeError
    },
    setImprimir(
      state,
      action: PayloadAction<{ key: number | string; imprimir: boolean }>,
    ) {
      state[action.payload.key].imprimir = action.payload.imprimir
    },
  },
})

export const {
  impuestosClearDatosEdicion: clearDatosEdicion,
  initDatosEdicion,
  setDatosEdicion,
  updateAnulados,
  updateCompras,
  updateDetalleRetenciones,
  updateVentas,
  updateVentasEstablecimiento,
  setMuestraError,
  setImprimir,
} = datosEdicionAnexoSlice.actions
export const datosEdicionReducer = datosEdicionAnexoSlice.reducer
