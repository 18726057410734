import React from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { setSeleccionarDato, setNumeroAsientoActual, setCurrentAccion } from "../../store/diarioReducer";
import {
  CButton,
  CCol, CRow, CTooltip
} from '@coreui/react-pro'
import { Column, DataGrid, FilterRow, HeaderFilter, Summary, TotalItem, Pager, Paging, LoadPanel, ColumnChooser } from 'devextreme-react/data-grid';
import { utilidades } from '../../../../../../helpers/utilidades';
import { RootState } from '../../../../../../store/store';
import { DateUtils, formatoFechasApi } from '../../../../../../helpers/dateUtils';
import "./tabla.scss";
import { TIPOS_BOTONES } from '../../store/types';
import { lh, MessagesKeys } from '../../../../../../helpers/localizationHelper';
import Modalform from '../../../../../../views/componentes/modalform';
import { getAllowedPageSizes } from '../../../../../../helpers/Helper';
interface TablaProps extends React.PropsWithChildren {
  name: string,
  data: any,
  actionDelete: () => void,
  exportExcel: () => void
}


const Tabla = (props: TablaProps) => {

  const dataGrid = React.useRef<any>();

  const dispatch = useDispatch()
  const seleccionado = useSelector((state: RootState) => state.contabilidad.diarioGeneral.seleccionado);

  const [data, setData] = React.useState<Array<any>>([]);
  const [displayMode,] = React.useState<string | 'full' | 'compact'>('full');
  const [showInfo,] = React.useState<boolean>(true);
  const [showNavButtons,] = React.useState<boolean>(true);
  const [showPageSizeSelector,] = React.useState<boolean>(true);
  const [messageConfirm, setMessageConfirm] = React.useState("Está seguro que desea realizar estos cambios?");
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [confirmChange, setConfirmChange] = React.useState(false);


  const onRowDoubleClick = React.useCallback((e) => {
    const { data } = e;
    const sms = lh.getMessage(MessagesKeys.FormConfirmChangeForm);
    const sms_replace = sms.replace("@dinamic_var@", seleccionado['row']['COD'] ?? 's/n');
    setMessageConfirm(sms_replace);
    setShowConfirm(true);
    setConfirmChange(true);
  }, [seleccionado]);

  const llenarData = React.useCallback((data) => {
    clearFilter();
    let item: any = []
    try {
      item = data.map(function (key, i) {
        return {
          ...key,
          ORDEN: Number(i),
        }
      })
      setData(item.map(x => utilidades.unfreeze(x)))
    } catch (error) {
      console.error("on llenarData", error)
    }
  }, []);

  const onSelectionChanged = React.useCallback((selectedRowsData) => {
    if (selectedRowsData && selectedRowsData['selectedRowsData'] && selectedRowsData['selectedRowsData'].length > 0) {
      dispatch(setSeleccionarDato(
        {
          index: 1,
          row: selectedRowsData['selectedRowsData'][0],
          selectedRow: [0]
        }
      ))
      const numeroAsiento: number = selectedRowsData['selectedRowsData'][0]['COD'];
      dispatch(setNumeroAsientoActual(numeroAsiento));
    }
  }, [dispatch]);

  const clearFilter = () => {
    dataGrid.current.instance.clearSorting();
    dataGrid.current.instance.clearFilter();
  }

  React.useEffect(() => {
    if (props.data.length > 0) {
      llenarData(props.data)
    } else {
      setData([])
    }
  }, [props.data, llenarData])

  const renderBody = () => {
    return (
      <>{messageConfirm}</>
    )
  }

  const renderFooter = () => {
    document?.getElementById("confirm")?.focus();
    return (
      <>
        {confirmChange === true && (
          <CButton color="primary" onClick={() => {
            setMessageConfirm("")
            setShowConfirm(false)
            setConfirmChange(false)
            dispatch(setCurrentAccion(TIPOS_BOTONES.BOTON_EDITAR));
          }}>
            {"Aceptar"}
          </CButton>
        )}
        <CButton color="secondary" onClick={() => {
          setShowConfirm(!showConfirm)
        }} >
          {"Cancelar"}
        </CButton>
      </>
    )
  }

  const confirmAction = () => {
    return (
      <Modalform
        name='confirmar'
        headerTitle={'Confirmar'}
        childrenBody={renderBody()}
        childrenFooter={renderFooter()}
        closeOnBackdrop={false}
        show={showConfirm}
        onClose={() => setShowConfirm(!showConfirm)}
        centered={true}
        size="sm"
      />
    )
  }

  return (
    <React.Fragment>
      {showConfirm === true && confirmAction()}

      <DataGrid
        ref={dataGrid}
        selection={{ mode: 'single' }}
        hoverStateEnabled={true}
        dataSource={data}
        showBorders={true}
        showRowLines={true}
        onSelectionChanged={(evt) => onSelectionChanged(evt)}
        columnResizingMode={'nextColumn'}
        allowColumnResizing={true}
        columnMinWidth={70}
        columnAutoWidth={true}
        width="100%"
        onRowDblClick={onRowDoubleClick}
      >
        <ColumnChooser enabled={true} mode="select" />
        <LoadPanel enabled={true} />
        <FilterRow visible={true} />
        <HeaderFilter visible={true} />
        <Paging defaultPageSize={20} />
        <Pager
          visible={data.length > 0 ? true : false}
          allowedPageSizes={getAllowedPageSizes(data)}
          displayMode={displayMode}
          showPageSizeSelector={showPageSizeSelector}
          showInfo={showInfo}
          showNavigationButtons={showNavButtons} />
        <Column dataField='fechaAUD' visible={false} caption='F.Reg' width={100} />
        <Column dataField='sessionID' visible={false} caption='Usuario' width={100} />
        <Column dataField='numeroAsiento' visible={false} caption='N. Reg' width={100} />
        <Column dataField='tipoNumero' visible={true} caption='N. Tipo' width={70} />
        <Column dataField='tipo' visible={true} caption='Tipo' width={160} />
        <Column dataField='fecha' format="dd/MM/yyyy" calculateCellValue={convertirFechaTransaccion} allowSearch={true} visible={true} caption='Fecha' dataType="datetime" width={35} />
        <Column dataField='numeroPlanCuentas' visible={true} caption='Numero' width={120} />
        <Column dataField='descripcionPlanCuentas' visible={true} caption='Cuenta' width={250} allowHiding={false} cellRender={renderCell} />
        <Column dataField='descripcionAsiento' visible={false} caption='Descripción' width={270} />
        <Column dataField='referenciaTransaccion' visible={true} caption='Ref.' width={60} />
        <Column dataField='nombreLocal' visible={true} caption='Lugar o CC' width={120} />
        <Column dataField='debeTransaccion' visible={true} caption='Debe' width={90} allowHiding={false} />
        <Column dataField='haberTransaccion' visible={true} caption='Haber' width={90} allowHiding={false} />

        <Summary>
          <TotalItem
            column="numeroAsiento"
            summaryType="count"
            customizeText={(data) => {
              return `${data.value}`;
            }} />
          <TotalItem
            column="debeTransaccion"
            summaryType="sum"
            valueFormat="currency"
          />
          <TotalItem
            column="haberTransaccion"
            summaryType="sum"
            valueFormat="currency" />
        </Summary>
      </DataGrid>
      {
        data.length > 0 && seleccionado['index'] !== null &&
        <CRow>
          <CCol>
            <div id="employee-info" className="m-2">
              <label>
                <strong>{'Descripción:'}</strong>
              </label>
              <p className="employee-notes">{seleccionado['row'] && seleccionado['row']['descripcionAsiento'] && seleccionado['row']['descripcionAsiento']}</p>
            </div>
          </CCol>
        </CRow>
      }
    </React.Fragment>

  )

}

function convertirFechaTransaccion(data) {
  if (data && data.fecha) {
    return DateUtils.strDateToDate(data.fecha, formatoFechasApi);
  } else {
    return null;
  }
}

function renderCell(cellData) {
  return (
    <div >
      <CTooltip
        key="title"
        placement="top"
        content={cellData.data.descripcionAsiento}
      >
        <label className={cellData.data.haberTransaccion !== 0 ? "aplicaMargen" : ""} >
          {cellData.data.descripcionPlanCuentas}
        </label>
      </CTooltip>
    </div>
  );
}


export default React.memo(Tabla);