import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AccionMenu } from '../../../../../../store/types'
import { MenuState } from '../../../../../ventas/types/types'


const initialState: MenuState = {
  loading: true,
  accions: [],
}

const menuCalendarioSlice = createSlice({
  name: 'nominaCalendarioMenu',
  initialState: initialState,
  reducers: {
    setMenuButtons(state, action: PayloadAction<Array<AccionMenu>>) {
      state.accions = action.payload
      state.loading = false
    },
  },
})

export const { setMenuButtons } = menuCalendarioSlice.actions
export const menuReducer = menuCalendarioSlice.reducer
