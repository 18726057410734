import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EdicionPayload } from '../../../store/types'
import { DocumentosCtasCobrarDatosEdicion } from '../types/types'
import { CustomDictionary } from '../../../../../store/types'

const initialState: CustomDictionary<DocumentosCtasCobrarDatosEdicion> = {}

const datosEdicionDocumentosCtasCobrarSlice = createSlice({
  name: 'documentosCtasCobrarDatosEdicion',
  initialState: initialState,
  reducers: {
    setDatosEdicion(
      state,
      action: PayloadAction<EdicionPayload<DocumentosCtasCobrarDatosEdicion>>,
    ) {
      state[action.payload.key] = action.payload.data
    },
    initDatosEdicion(
      state,
      action: PayloadAction<EdicionPayload<DocumentosCtasCobrarDatosEdicion>>,
    ) {
      if (!state[action.payload.key]) {
        state[action.payload.key] = action.payload.data
      }
    },
  },
})

export const { setDatosEdicion, initDatosEdicion } =
  datosEdicionDocumentosCtasCobrarSlice.actions
export const datosEdicionDocumentosCtaCobrarReducer =
  datosEdicionDocumentosCtasCobrarSlice.reducer
