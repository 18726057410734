/* eslint-disable prefer-const */
import React, { useState } from 'react';
import {
  CCard,
  CCardHeader,
  CCol,
  CRow,
  CCollapse,
  CLink,
  CForm,
  CFormCheck
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { addToast } from '../../../../../../store/toasterReducer';
import { ToastTypes } from '../../../../../../store/types';
import { useDispatch, useSelector } from 'react-redux';
import Tabla from '../tabla';
import { RootState } from '../../../../../../store/store';
import { ccahorrosService } from '../../services/CCAhorrosService';
import { setData, setCurrentFunction, setSeleccionarDato, changeLoaderCuentaAhorros } from '../../store/CCAhorrosReducers';
import { CCAHORRO } from '../../store/types';
import TextBox from 'devextreme-react/text-box';
import ValidationGroup from 'devextreme-react/validation-group';
import { changeLoader } from '../../../../../../store/reducers';
import { cilChevronBottom, cilChevronTop } from '@coreui/icons';
import { GlobalLabel } from '../../../../../../views/componentes/globalLabel/globalLabel';

type BuscarProps = {
  actionDelete: () => void,
  exportExcel: () => void,
  origen: string, // main, modal
  returnSeleccion: (data) => void,
  origenAplicacion: string,
  verificaEdicion: () => void,
}

const tipoCuentas = [
  { label: "Todos", value: 0 },
  { label: "Empresa", value: 1 },
  { label: "Proveedores", value: 2 },
  { label: "Clientes", value: 3 },
  { label: "Empleados", value: 4 },
];

const Buscar = (props: BuscarProps) => {
  const { verificaEdicion, origen } = props;

  const dispatch = useDispatch()

  const validationGroupRef = React.useRef<any>();

  const registros = useSelector((state: RootState) => state.bancos.ccahorros.registros);
  const currentFunction = useSelector((state: RootState) => state.bancos.ccahorros.currentFunction);

  let marcarTipoCuenta = 0;
  if (props.origen === 'modal') {
    marcarTipoCuenta = 1;
    if (props.origenAplicacion === "cheques") {
      marcarTipoCuenta = 0;
    }
  }

  const [tipoCuenta, setTipoCuenta] = useState<number>(marcarTipoCuenta);
  const [busqueda_ti, setBusqueda_ti] = useState<string>("");
  const [nombre_ti, setNombre_ti] = useState<string>("");
  const [collapsed, setCollapsed] = React.useState(true);

  React.useEffect(() => {
    if (currentFunction === "Buscar") {
      dispatch(setCurrentFunction(""))
      dispatch(setSeleccionarDato(
        {
          index: null,
          row: null,
          selectedRow: null
        }
      ));
      handleSubmit();
    } else if (currentFunction === "Deshacer") {
      dispatch(setCurrentFunction(""))
      //handleReset();
    }
  }, [currentFunction]);

  const setToast = React.useCallback((texto, type) => {
    let tipo: ToastTypes = ToastTypes.Info;
    if (type === 'warning') {
      tipo = ToastTypes.Warning;
    } else if (type === 'success') {
      tipo = ToastTypes.Success;
    } if (type === 'info') {
      tipo = ToastTypes.Info;
    } if (type === 'danger') {
      tipo = ToastTypes.Danger;
    }
    dispatch(addToast({
      id: '',
      autoHide: 5000,
      title: 'Acatha',
      content: texto,
      fade: true,
      type: tipo
    }));
  }, [dispatch]);

  const playLoader = () => {
    dispatch(changeLoader({ show: true, mensaje: 'Cargando...' }))
  }

  const stopLoader = () => {
    dispatch(changeLoader({ show: false, mensaje: '' }))
  }

  const handleSubmit = async () => {

    playLoader()
    dispatch(setData([]));

    try {

      let asociado_: any = "";
      if (tipoCuenta === 4 && nombre_ti === "") {
        asociado_ = 0;
      } else {
        asociado_ = nombre_ti;
      }
      const find = {
        pertenece: tipoCuenta,
        numero: busqueda_ti ?? "",
        asociado: asociado_
      }
      const data = await ccahorrosService.obtenerCCahorros(find);
      stopLoader()
      if (data['error'] === false) {

        setToast(data['message'], "success");

        if (data['auto'].length > 0) {
          setCollapsed(false)
          let itemBanco: Array<CCAHORRO>;
          itemBanco = data['auto'].map(function (item: CCAHORRO) {
            return {
              ban_codigo: Number(item['bancoCodigo'] ?? null),
              ban_nombre: String(item['bancoNombre'] ?? ""),
              asociado: Number(item['asociado'] ?? null),
              asociado_id: String(item['asociadoId'] ?? ""),
              asociado_nombre: String(item['asociadoNombre'] ?? ""),
              cba_codigo: Number(item['codigo'] ?? null),
              cba_contador: Number(item['contador'] ?? null),
              cba_estado: Number(item['estado'] ?? null),
              fecha_corte: String(item['fechaCorte'] ?? ""),
              num_cheque: String(item['numeroCheque'] ?? ""),
              cba_numero: String(item['numero'] ?? ""),
              cba_oficial: String(item['oficial'] ?? ""),
              cba_pertenece: Number(item['pertenece'] ?? null),
              cba_pertenece_des: String(item['perteneceDescripcion'] ?? ""),
              cba_saldo: Number(item['saldo'] ?? null),
              cba_tipo: Number(item['tipo'] ?? null),
              pla_codigo: Number(item['planCuentasCodigo'] ?? null),
              pla_descripcion: String(item['planCuentasDescripcion'] ?? ""),
              pla_numero: String(item['planCuentasNumero'] ?? ""),
              sub_valor: String(item['subValor'] ?? ""),
            };
          });
          dispatch(setData(itemBanco));
        }

      } else {
        setToast(data.message, "warning");
      }
      return await data;

    } catch (error) {
      console.log('error', error);
      stopLoader()
      setToast(JSON.stringify(error), "danger")
    }

  }


  const returnSelectionRow = (data) => {
    if (props.origen === 'modal') {
      console.log("data", data);
      props.returnSeleccion(data)
    }
  }

  const radioItems = tipoCuentas.map(function (item, i) {
    let nameButon = item.label.replace(/ /gi, "");
    return (
      <>
        <CForm key={i + nameButon + "_formgroup"}>
        <CFormCheck type='radio'  id={item.label} className="form-check-input input-sm" name="opt" value={item.label} key={i + nameButon + "_input"}
          checked={tipoCuenta === item.value ? true : false}
          onChange={() => setTipoCuenta(item.value)} />
        <GlobalLabel variant="custom-checkbox" htmlFor={item.label} key={i + nameButon + "_label"} >{item.label}</GlobalLabel>
      </CForm> 
      </>
    );
  });

  return (
    <>

      <CCard>
        <CCardHeader onClick={() => setCollapsed(!collapsed)} className="d-flex justify-content-between">
          <strong> {"Buscar"} </strong>
          <div className="card-header-actions">
            <CLink className="card-header-action" >
              <CIcon icon={collapsed ? cilChevronBottom : cilChevronTop} />
            </CLink>
          </div>
        </CCardHeader>

        <CCollapse visible={collapsed}>
          <React.Fragment>

            <ValidationGroup ref={validationGroupRef}>
              <CRow className="m-2 p-2">
                <CCol lg="7" md="8" sm="12" xs="12">

                  <div className="dx-field">
                    <div className="dx-field-label">{"Cuenta"}</div>
                    <div className="dx-field-value">
                      <TextBox
                        width="80%"
                        value={busqueda_ti}
                        placeholder=""
                        onValueChanged={(data) => { setBusqueda_ti(data.value) }}
                        onEnterKey={handleSubmit}
                        inputAttr={{
                          autoFocus: true
                        }}
                      />
                    </div>
                  </div>

                  <div className="dx-field">
                    <div className="dx-field-label">{"Proveedor / Cliente"}</div>
                    <div className="dx-field-value">
                      <TextBox
                        width="80%"
                        value={nombre_ti}
                        placeholder=""
                        onValueChanged={(data) => { setNombre_ti(data.value) }}
                        onEnterKey={handleSubmit}
                      />
                    </div>
                  </div>

                </CCol>
              </CRow>

              <CRow className="m-2 p-2">
                <CCol lg="10" md="8" sm="12" xs="12">
                  <div className="dx-field d-flex align-items-start">
                    <div className="dx-field-value">
                      <fieldset className='d-flex' disabled={origen === "modal" ? true : false}>
                        {radioItems}
                      </fieldset>
                    </div>
                  </div>
                </CCol>
              </CRow>

            </ValidationGroup>

          </React.Fragment>
        </CCollapse>

      </CCard>

      <CRow>
        <CCol>
          <Tabla
            name="TablaCCAhorros"
            data={registros}
            actionDelete={() => props.actionDelete()}
            exportExcel={() => props.exportExcel()}
            origen={props.origen}
            returnSeleccion={(data) => returnSelectionRow(data)}
            verificaEdicion={verificaEdicion}
          />
        </CCol>
      </CRow>

    </>
  );
}

export default Buscar;