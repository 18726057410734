import React, { PureComponent } from 'react';
import { Highlighter } from 'react-bootstrap-typeahead';
import AsyncSelectBox from '../../../../../../../views/componentes/asyncSelectBox/asyncSelectBox';
import { ENTER_KEY, ESCAPE_KEY, TAB_KEY } from '../../../../../../../views/componentes/customSheet/constants';
import { OrdenPedidoService } from '../../../pages/ordenPedido/services/ordenPedido.services';
import './sheetDescripcionItemSelectEditor.scss';

const getTipoIva = (tipo) => {
  switch (tipo) {
    case 2:
      return 'IVA 0%'
    case 3:
      return 'IVA 12%'
    default:
      return 'No Objeto de Impuesto'
  }
}

class SheetPedidosDescripcionItemSelectEditor extends PureComponent {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.onSearch = this.onSearch.bind(this);
    const value = [];
    this.state = { isFirst: true, value: value }
  }

  async onSearch(text) {
    // let date = DateUtils.getCurrentDateAsString(formatoFechasApi);
    let date = "26/02/2022";
    const items = await OrdenPedidoService.buscarItemPedido(text, '', '9999999999999', date);
    this.setState({ isFirst: false });
    return items;
  }

  handleChange(opt) {
    if (this.state.isFirst) {
      return;
    }
    const { onCommit, onRevert } = this.props
    if (!opt) {
      return onRevert()
    }
    onCommit(opt && opt.length > 0 ? opt[0] : null, null)
  }

  handleKeyDown(e) {
    if (e.which === ESCAPE_KEY) {
      this.setState({ e, isFirst: false })
      this.handleChange(null);
      return;
    }
    if ((e.which === ENTER_KEY || e.which === TAB_KEY) && (this.state.e && e.which === this.state.e.which)) {
      e.persist()
      this.setState({ e, isFirst: false })
    } else {
      this.setState({ e })
    }
  }

  render() {
    const value = this.state.value;
    return (
      <AsyncSelectBox
        id='selectBusquedaItemsDetalle'
        selected={value ? value : []}
        minSearchlength={3}
        onSearch={this.onSearch}
        labelKey='descripcion'
        placeholder=''
        onChange={this.handleChange}
        onKeyDown={this.handleKeyDown}
        autofocus={true}
        renderMenuItemChildren={(option, { text }, index) => {
          return (
            <div>
              <Highlighter search={text}>
                {option.descripcion}
              </Highlighter>,
              <div className='buquedaDetailsContainer'>
                <small className='buquedaDetailsItem'>
                  Código: {option.codigoBarras}
                </small>
                <small className={`buquedaDetailsItem ${option.existencia < 0 ? 'existenciaNegativa' : (option.existencia === 0 ? 'existenciaCero' : '')}`}>
                  Existencia: {option.existencia}
                </small>
                <small className={`buquedaDetailsItem ${option.existencia < 0 ? 'existenciaNegativa' : (option.existencia === 0 ? 'existenciaCero' : '')}`}>
                  Impuesto: {getTipoIva(option.codigoImpuesto)}
                </small>
              </div>
            </div >
          );
        }}
      />
    )
  }
}


export { SheetPedidosDescripcionItemSelectEditor };