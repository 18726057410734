import React from 'react'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput'
import {
  CheckBox,
  DateBox,
  RadioGroup,
  TextBox,
} from 'devextreme-react'
import LocalesLookUp from '../../../../../../componentes/localesLookUp'
import { CCardBody, CCardHeader, CCollapse, CLink } from '@coreui/react-pro'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import { ETipoAsociado, ToastTypes } from '../../../../../../../store/types'
import { addToast } from '../../../../../../../store/toasterReducer'
import { changeFilter, fetchImports, setColapsado } from '../../store/searchReducer'
import { DateUtils, formatoFechasApi } from '../../../../../../../helpers/dateUtils'
import { FiltrosImportacionesState } from '../../types/types'
import { getTypesMovements } from '../../../ingresosEgresos/helper/helper'
import { getLocales } from '../../../../../../componentes/localesLookUp/helper/helper'
import { ProveedorBusqueda } from '../../../../../../proveedores/store/types'
import { BuscarProveedorLookUp } from '../../../../../../proveedores/components/busquedaProveedor/BuscarProveedorLookUp'
import BuscarClienteLookUp from '../../../../../../ventas/components/busquedaCliente/busquedaCliente'
import { ETypeMovimiento } from '../../../ingresosEgresos/types/types'
import BuscarItemPedidoMemoLookUp from '../../../../../../ventas/pages/ordenes/pages/ordenPedido/components/busquedaPedidos/BusquedaItemsSelectBox/BuscarItemPedidoMemoLookUp'
import SelectBox from '../../../../../../../views/componentes/selectBox/selectBox'
import { EModulosAcatha } from '../../../../../../../store/enumsAplication'
import CIcon from '@coreui/icons-react'
import { isMobile } from 'react-device-detect'
import { cilChevronBottom, cilChevronTop } from '@coreui/icons'
import { utilidades } from '../../../../../../../helpers/utilidades'
import { getIndexProvider } from '../../../../../../shared/helpers/funciones'




export const optionsItems = [
  { value: 0, label: 'Todos' },
  { value: 1, label: 'Item' },
  { value: 4, label: 'Materia Prima' },
  { value: 7, label: 'Activos/Bienes' },
]

export const optionsTransito = [
  { value: -1, label: 'Todos' },
  { value: 1, label: 'En Tránsito' },
  { value: 0, label: 'En Inventario' },
]


export const optionsPrioridad = [
  { value: false, label: 'Descripcion' },
  { value: true, label: 'Barras' },
]

const FiltroBusqueda = () => {

  const dispatch = useDispatch()
  const searchFilter = useSelector((state: RootState) => state.inventarios.movimientos.importaciones.search.filter)
  const collapsed = useSelector((state: RootState) => state.inventarios.movimientos.importaciones.search.colapsado)
  const currentTab = useSelector((state: RootState) => state.inventarios.movimientos.importaciones.search.currentTab)
  const periodoActivo = useSelector(
    (state: RootState) => state.shared.providers.periodoContable,
  )
  const localSession = useSelector((state: RootState) => state.global.session.local)
  const [prioridadBarras, setPrioridadBarras] = React.useState<{ value: boolean, label: string }>(optionsPrioridad[1])

  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(
      addToast({
        title: 'Inventario-Importaciones',
        content: mensaje,
        type: tipo,
      }),
    )
  }, [dispatch])
  const fechaFormato: string = 'dd/MM/yyyy'
  const fechaFormatoInput: string = 'yyyy-MM-dd'


  const onHandleSearch = React.useCallback(async () => {
    const cod = searchFilter?.codigo ? parseInt(searchFilter?.codigo) : 0

    const desde = searchFilter?.fechaDesde ? DateUtils.format(searchFilter?.fechaDesde, fechaFormato, fechaFormatoInput) : DateUtils.getCurrentDateAsString(fechaFormato)
    const hasta = searchFilter?.fechaHasta ? DateUtils.format(searchFilter?.fechaHasta, fechaFormato, fechaFormatoInput) : DateUtils.getCurrentDateAsString(fechaFormato)

    const search_data: any = {
      codigo: cod,
      documento: searchFilter?.documento ?? '',
      codigoLocal: searchFilter.almacen?.codigo ?? -1,
      prodCliente: searchFilter.proveedor?.codigo ?? 0,
      tipo: 'IMPORTACIONES',
      impreso: searchFilter.itemRadioGroup?.value ?? 0,
      movimiento: searchFilter.movimiento?.codigo ?? -1,
      observaciones: searchFilter?.observaciones ?? '',
      estado: searchFilter.item?.codigoBarras ?? '',
      transito: searchFilter.transito?.value ?? 0,
      fechaDesde: desde,
      fechaHasta: hasta,
      currentTab,
      consolidado: searchFilter?.consolidar ? true : false
    }
    try {
      const toAny: any = fetchImports(search_data)
      const res = await dispatch(toAny)
      if (res !== null && res !== undefined && res['payload']?.error === false) {
        if (res['payload']?.auto && res['payload'].auto.length > 5) {
          dispatch(setColapsado(false))
        }
        setToast(res['payload']?.message, ToastTypes.Success)
      } else {
        setToast(res['payload']?.message, ToastTypes.Danger)
      }
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
  }, [searchFilter, currentTab, dispatch, setToast])

  const onFilterMovimientos = React.useCallback((data = []) => {
    return data.filter(item => item.codigo === -1 || item.codigo === 9)
  }, [])

  const onInitSearchingEg = React.useCallback(async () => {
    const data = { ...searchFilter }
    if (data.initial) {
      let arrayMovimientos: Array<any> = []
      const resMovimientos = await getTypesMovements()
      const resLocal = await getLocales('Elija una opcion')
      if (resMovimientos.length > 0) {
        arrayMovimientos = onFilterMovimientos(resMovimientos)
      }
      data.itemRadioGroup = optionsItems[0]
      data.transito = optionsTransito[0]
      data.fechaDesde = periodoActivo.length > 0 ? DateUtils.format(periodoActivo[0].finicio, fechaFormatoInput, fechaFormato) : DateUtils.getCurrentDateAsString(fechaFormatoInput)
      data.fechaHasta = periodoActivo.length > 0 ? DateUtils.format(periodoActivo[0].fcierre, fechaFormatoInput, fechaFormato) : DateUtils.getCurrentDateAsString(fechaFormatoInput)
      data.movimiento = arrayMovimientos.length > 0 ? arrayMovimientos[1] : null
      data.movimientos = arrayMovimientos
      if (resLocal.length > 0) {
        const provider = resLocal.slice(0)
        const index = await getIndexProvider(provider, 'codigo', localSession?.codigo ?? 0)
        if (index > -1) {
          data.almacen = resLocal[index]
        } else {
          data.almacen = resLocal[0]
        }
      }
      data.esRapida = false
      data.item = null
      data.initial = false

      dispatch(changeFilter({
        ...data
      }))
    }
  }, [dispatch, periodoActivo, onFilterMovimientos, searchFilter, localSession])


  const onAsociadoProv = React.useCallback((data) => {
    let data_prov: ProveedorBusqueda = null
    if (searchFilter.movimiento?.asociado === ETypeMovimiento.compras) {
      data_prov = data
      dispatch(changeFilter({
        ...searchFilter,
        proveedor: data_prov
      }))
    } else if (searchFilter.movimiento?.asociado === ETypeMovimiento.ventas) {
      dispatch(changeFilter({
        ...searchFilter,
        proveedor: data_prov
      }))
    }
  }, [searchFilter, dispatch])

  const seleccionarProveedor = () => {
    return (
      <Labeled label="Proveedor:">
        <BuscarProveedorLookUp
          selected={searchFilter.proveedor as ProveedorBusqueda}
          allowAdd
          allowClear
          allowEdit
          onChanged={onAsociadoProv}
        />
      </Labeled>
    )
  }

  const seleccionarCliente = () => {
    return (
      <Labeled label="Cliente:">
        <BuscarClienteLookUp
          selected={null}
          onChanged={onAsociadoProv}
          allowClear
          allowAdd
          allowEdit />
      </Labeled>
    )
  }


  const showDinamycLookUp = () => {
    if (searchFilter.movimiento?.asociado === ETypeMovimiento.ventas) {
      return seleccionarCliente()
    } else if (searchFilter.movimiento?.asociado === ETypeMovimiento.compras) {
      return seleccionarProveedor()
    }
  }

  React.useEffect(() => {
    onInitSearchingEg()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (periodoActivo.length > 0) {
      dispatch(changeFilter({
        ...searchFilter,
        fechaDesde: DateUtils.format(periodoActivo[0].finicio, fechaFormatoInput, fechaFormato),
        fechaHasta: DateUtils.format(periodoActivo[0].fcierre, fechaFormatoInput, fechaFormato)
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodoActivo])

  return (
    <>
      <CCardHeader
        onClick={() => dispatch(setColapsado(!collapsed))}
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <strong> {collapsed ? '' : 'Filtro de Búsqueda'} </strong>
        <CLink className="card-header-action">
          <CIcon icon={collapsed ? cilChevronBottom : cilChevronTop} />
        </CLink>
      </CCardHeader>
      <CCollapse visible={collapsed}>
        <CCardBody>
          <RowContainer>
            <RowContainer>
              <CustomCol xs="12" md="2">
                <Labeled label="Código Mov:">
                  <TextBox
                    value={searchFilter?.codigo ?? ''}
                    onValueChanged={(data) => {
                      if (data?.event !== null && data?.event !== undefined) {
                        dispatch(changeFilter({
                          ...searchFilter,
                          codigo: data?.value
                        }))
                      }
                    }}
                    onEnterKey={onHandleSearch}
                    onKeyDown={utilidades.filtraTeclasNoNumericas}
                  />
                </Labeled>
              </CustomCol>
              <CustomCol xs="12" md="3">
                <Labeled label="Documento:">
                  <TextBox
                    value={searchFilter?.documento ?? ''}
                    onValueChanged={(data) => {
                      if (data?.event !== null && data?.event !== undefined) {
                        dispatch(changeFilter({
                          ...searchFilter,
                          documento: data?.value
                        }))
                      }
                    }}
                    onEnterKey={onHandleSearch}
                    onKeyDown={utilidades.filtraTeclasNoNumericas}
                  />
                </Labeled>
              </CustomCol>
            </RowContainer>
            <RowContainer>
              <CustomCol xs="12" md="5">
                <Labeled label="Almacén:">
                  <LocalesLookUp
                    selected={searchFilter?.almacen ?? null}
                    onChanged={(data) => {
                      dispatch(changeFilter({
                        ...searchFilter,
                        almacen: data
                      }))
                    }}
                    onChangedOptions={() => { }}
                  />
                </Labeled>
              </CustomCol>
              <CustomCol xs="12" md="3">
                <Labeled label="Movimiento:">
                  <SelectBox
                    id="movimientos"
                    placeholder=""
                    options={searchFilter?.movimientos ?? []}
                    displayExpr="descripcion"
                    keyExpr="codigo"
                    selected={searchFilter?.movimiento ?? null}
                    onChange={(data) => {
                      dispatch(changeFilter({
                        ...searchFilter,
                        movimiento: data
                      }))
                    }}
                    clearButton={true}
                  ></SelectBox>
                </Labeled>
              </CustomCol>
            </RowContainer>
            <RowContainer>
              <CustomCol xs="12" md="5">
                {searchFilter?.movimiento !== null && showDinamycLookUp()}
              </CustomCol>
              <CustomCol xs="12" md="5" style={{ marginTop: 'auto' }}>
                <Labeled label=''>
                  <RadioGroup
                    name="selectItemIMportaciones"
                    layout="horizontal"
                    displayExpr={'label'}
                    items={optionsItems}
                    value={searchFilter?.itemRadioGroup ?? null}
                    onValueChanged={(data) => {
                      if (data?.event !== null && data?.event !== undefined) {
                        dispatch(changeFilter({
                          ...searchFilter,
                          itemRadioGroup: data?.value
                        }))
                      }
                    }}
                  />
                </Labeled>
              </CustomCol>
            </RowContainer>
            <RowContainer>
              <CustomCol xs="12" md="9">
                <Labeled label="Items:">
                  <RowContainer>
                    <CustomCol md={searchFilter.item === null ? "6" : "8"}>
                      <BuscarItemPedidoMemoLookUp
                        selected={searchFilter.item ?? null}
                        onChanged={(data) => {
                          dispatch(changeFilter({
                            ...searchFilter,
                            item: data
                          }))
                        }}
                        cliente={null}
                        fecha={DateUtils.getCurrentDateAsString(formatoFechasApi)}
                        prioridadBarras={prioridadBarras?.value ?? true}
                        modulo={EModulosAcatha.Kardex}
                        tipoAsociado={
                          searchFilter?.movimiento !== null &&
                            searchFilter.movimiento?.asociado ===
                            ETypeMovimiento.ventas
                            ? ETipoAsociado.Clientes
                            : null
                        }
                        local={searchFilter?.almacen !== null && searchFilter?.almacen !== undefined ? searchFilter?.almacen : undefined}
                        movimiento={searchFilter?.movimiento}
                        allowMoreOption={true}
                        allowClear={true}
                        allowEdit={true}
                        allowAdd={true}
                        codigoPrvAsociado={-1}
                      />
                    </CustomCol>
                    <CustomCol md="4" className='mr-2'>
                      <RadioGroup
                        name="iOptionsMov"
                        dataSource={optionsPrioridad}
                        value={prioridadBarras}
                        layout="horizontal"
                        displayExpr="label"
                        onValueChange={(data) => {
                          setPrioridadBarras(data)
                        }}
                      />
                    </CustomCol>
                  </RowContainer>
                </Labeled>
              </CustomCol>
            </RowContainer>
            <RowContainer>
              <CustomCol xs="12" md="5">
                <Labeled label="Observaciones:">
                  <TextBox
                    value={searchFilter?.observaciones ?? ''}
                    onValueChanged={(data) => {
                      if (data?.event !== null && data?.event !== undefined) {
                        dispatch(changeFilter({
                          ...searchFilter,
                          observaciones: data?.value
                        }))
                      }
                    }}
                    onEnterKey={onHandleSearch}
                  />
                </Labeled>
              </CustomCol>
              <CustomCol xs="12" md="2" style={{ marginTop: 'auto' }}>
                <Labeled label=''>
                  <CheckBox text="Consolidar" value={searchFilter?.consolidar ?? null} onValueChanged={(data) => {
                    if (data?.event !== null && data?.event !== undefined) {
                      dispatch(changeFilter({
                        ...searchFilter,
                        consolidar: data?.value
                      }))
                    }
                  }} />
                </Labeled>
              </CustomCol>
            </RowContainer>

            <CustomCol xs="12" md="5">
              <Labeled label="Rango de Fechas:">
                {
                  isMobile && (
                    <>
                      <DateBox
                        displayFormat="dd/MM/yyyy"
                        value={searchFilter?.fechaDesde ?? null}
                        onValueChanged={(data) => {
                          if (data?.event !== null && data?.event !== undefined) {
                            dispatch(changeFilter({
                              ...searchFilter,
                              fechaDesde: data?.value
                            }))
                          }
                        }}
                        onEnterKey={onHandleSearch}
                      />
                      <DateBox
                        displayFormat="dd/MM/yyyy"
                        value={searchFilter?.fechaHasta ?? null}
                        onValueChanged={(data) => {
                          if (data?.event !== null && data?.event !== undefined) {
                            dispatch(changeFilter({
                              ...searchFilter,
                              fechaHasta: data?.value
                            }))
                          }
                        }}
                        onEnterKey={onHandleSearch}
                      />
                    </>
                  )
                }
                {
                  !isMobile && (

                    <div
                      style={{
                        display: 'flex',
                        gap: '2px',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <DateBox
                        displayFormat="dd/MM/yyyy"
                        value={searchFilter?.fechaDesde ?? null}
                        onValueChanged={(data) => {
                          if (data?.event !== null && data?.event !== undefined) {
                            dispatch(changeFilter({
                              ...searchFilter,
                              fechaDesde: data?.value
                            }))
                          }
                        }}
                        onEnterKey={onHandleSearch}
                      />
                      <DateBox
                        displayFormat="dd/MM/yyyy"
                        value={searchFilter?.fechaHasta ?? null}
                        onValueChanged={(data) => {
                          if (data?.event !== null && data?.event !== undefined) {
                            dispatch(changeFilter({
                              ...searchFilter,
                              fechaHasta: data?.value
                            }))
                          }
                        }}
                        onEnterKey={onHandleSearch}
                      />
                    </div>
                  )
                }
              </Labeled>
            </CustomCol>
            <CustomCol xs="12" md="5" style={{ marginTop: 'auto' }}>
              <Labeled label=''>

                <RadioGroup
                  name="selectTransito"
                  layout="horizontal"
                  displayExpr={'label'}
                  items={optionsTransito}
                  value={searchFilter?.transito ?? null}
                  onValueChanged={(data) => {
                    if (data?.event !== null && data?.event !== undefined) {
                      dispatch(changeFilter({
                        ...searchFilter,
                        transito: data?.value
                      }))
                    }
                  }}
                />
              </Labeled>
            </CustomCol>
            <CustomCol xs="12" md="2" style={{ marginTop: 'auto' }}>
              <CheckBox
                text="Mostrar E/S Rápida"
                value={searchFilter?.esRapida}
                onValueChanged={(data) => {
                  if (data?.event !== null && data?.event !== undefined) {
                    dispatch(changeFilter({
                      ...searchFilter,
                      esRapida: data?.value
                    }))
                  }
                }}
              />
            </CustomCol>
          </RowContainer>
        </CCardBody>
      </CCollapse>
    </>
  )
}


export const initSearch: FiltrosImportacionesState = {
  codigo: '',
  documento: '',
  almacen: null,
  movimiento: null,
  movimientos: [],
  proveedor: null,
  item: null,
  itemRadioGroup: null,
  observaciones: '',
  fechaDesde: '',
  fechaHasta: '',
  consolidar: false,
  transito: null,
  esRapida: null,
  initial: true
}


export default React.memo(FiltroBusqueda)
