import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { BlockEntryState } from '../types/types'
import {
  GrupoInventario,
  LineaInventario,
  MarcaInventario,
} from '../../../../store/types'
import { DateUtils } from '../../../../../../helpers/dateUtils'
import { LoaderInfo } from '../../../../../../store/types'

const initialState: BlockEntryState = {
  loader: { show: false, mensaje: '' },
  currentAction: 'Buscar',
  item: null,
  fechaCorte: DateUtils.getCurrentDateAsString(),
  linea: null,
  grupo: null,
  marca: null,
  compra: null,
  dataGrid: [],
  selected: null,
  collapse: true,
}

const blockEntrySlice = createSlice({
  name: 'ingresosBloque',
  initialState: initialState,
  reducers: {
    setEntry(state, action: PayloadAction<BlockEntryState>) {
      state.loader = action.payload.loader
      state.item = action.payload.item
      state.fechaCorte = action.payload.fechaCorte
      state.compra = action.payload.compra
      state.dataGrid = action.payload.dataGrid
    },
    setLine(state, action: PayloadAction<LineaInventario>) {
      state.linea = action.payload
    },
    setGroup(state, action: PayloadAction<GrupoInventario>) {
      state.grupo = action.payload
    },
    setBrand(state, action: PayloadAction<MarcaInventario>) {
      state.marca = action.payload
    },
    setCurrentFunction(state, action: PayloadAction<string>) {
      state.currentAction = action.payload
    },
    setLoader(state, action: PayloadAction<LoaderInfo>) {
      state.loader = action.payload
    },
    setSelectedItem(state, action: PayloadAction<any>) {
      state.selected = action.payload
    },
    setCollapsed(state, action: PayloadAction<boolean>) {
      state.collapse = action.payload
    },
  },
})

export const {
  setEntry,
  setLine,
  setBrand,
  setGroup,
  setCurrentFunction,
  setLoader,
  setSelectedItem,
  setCollapsed,
} = blockEntrySlice.actions

export const blockEntryReducer = blockEntrySlice.reducer
