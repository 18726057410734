import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { WizardState, ItemSource } from './types'

const initialState: WizardState = {
  position: 0,
  mostrar: false,
  itemsNavegation: [
    {
      formulario: 1, // form welcome
      completado: false,
      data: {},
    },
    {
      formulario: 2, // form config punto venta
      completado: false,
      data: {},
    },
  ],
  infoFormEmpresa: '',
  submitForm: '',
}

const wizardSlice = createSlice({
  name: 'wizard',
  initialState: initialState,
  reducers: {
    setPositionWizard(state, action: PayloadAction<number>) {
      state.position = action.payload
    },
    setMostrarWizard(state, action: PayloadAction<boolean>) {
      state.mostrar = action.payload
    },
    setItemsNavigation(state, action: PayloadAction<Array<ItemSource>>) {
      state.itemsNavegation = action.payload
    },
    setInfoFormEmpresa(state, action: PayloadAction<any>) {
      state.infoFormEmpresa = action.payload
    },
    setSubmitForm(state, action: PayloadAction<any>) {
      state.submitForm = action.payload
    },
  },
})
export const {
  setSubmitForm,
  setInfoFormEmpresa,
  setPositionWizard,
  setMostrarWizard,
  setItemsNavigation,
} = wizardSlice.actions
export const WizardReducer = wizardSlice.reducer
