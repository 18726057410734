import { utilidades } from "../../../../helpers/utilidades";
import { tipoItemService } from "../service/tipoItem.service";
import { TTipoItem } from "../store/types";

export async function getTipoItems() {
	const data = await tipoItemService.getTipoItems();
	let opt: Array<TTipoItem> = []
	if (data.auto.length > 0) {
		opt = data.auto;
	}
	return opt
}

/**
 * Función para analizar las opciones y convertirlas en un arreglo de objetos de tipo TTipoItem.
 * @param options - Arreglo de opciones a analizar.
 * @returns Un arreglo de objetos de tipo TTipoItem.
 */
export function parseOptions(options: Array<any>): Array<TTipoItem> {
	let opt: Array<TTipoItem> = []
	if (options.length > 0) {
		opt = options.map((x) => {
			const item: TTipoItem = {
				codigo: x.codigo ?? -1,
				descripcion: utilidades.capText(x.descripcion) ?? '',
				codTributario: x.codigoTributario ?? '',
			}
			return item
		})
	}
	return opt
}