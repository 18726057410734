import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../../store/store'
import { FETCH_STATUS } from '../../../../../store/types'
// import { VentasService } from '../../../services/ventas.service'
import { UsuarioListado, FiltroBusquedaUsuarioState } from './types'
import { TConfigSearchState } from '../../store/types'
import { UsuariosService } from '../services/usuarios.services'

const initialState: TConfigSearchState<UsuarioListado> = {
  filter: {
    usuario: '',
    identificaciom: '',
    nombres: '',
    apellidos: '',
    ubicacion: null,
    local: null,
    estado: true,
    soloEmpresa: false,
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  resultados: [],
  selectedItem: null,
}

export const fetchUsuarios = createAsyncThunk<
  Array<UsuarioListado>,
  FiltroBusquedaUsuarioState
>('all/searchUsuarios', async (filtro) => {
  try {
    const usuarios = await UsuariosService.getAllUsuarios(filtro)
    return usuarios
  } catch (error) {
    return Promise.reject(error)
  }
})

export const fetchEmpresaUsuarios = createAsyncThunk<
  Array<UsuarioListado>,
  FiltroBusquedaUsuarioState
>('empresa/searchUsuarios', async (filtro) => {
  try {
    const usuarios = await UsuariosService.getEmpresaUsuarios(filtro)
    return usuarios
  } catch (error) {
    return Promise.reject(error)
  }
})

const searchConfUsuarioSlice = createSlice({
  name: 'searchUsuarios',
  initialState: initialState,
  reducers: {
    resetState(state) {
      state = {
        ...initialState,
        filter: {
          ...initialState.filter,
        },
      }
    },
    changeFilter(state, action: PayloadAction<FiltroBusquedaUsuarioState>) {
      state.filter = action.payload
    },
    setIdentificacion(state, action: PayloadAction<string>) {
      state.filter.identificacion = action.payload
    },
    setNombre(state, action: PayloadAction<string>) {
      state.filter.nombre = action.payload
    },
    setCleanResult(state) {
      state.resultados = []
    },
    setResultados(state, action: PayloadAction<Array<UsuarioListado> | []>) {
      state.resultados = action.payload
    },

    setSelectedItem(state, action: PayloadAction<UsuarioListado | null>) {
      state.selectedItem = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUsuarios.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchUsuarios.fulfilled, (state, { payload }) => {
      state.resultados = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchEmpresaUsuarios.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchEmpresaUsuarios.fulfilled, (state, { payload }) => {
      state.resultados = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchUsuarios.rejected, (state, { payload }) => {
      state.resultados = []
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
  },
})

export const selectFiltroBusquedaUsuarios = (state: RootState) => {
  return state.administracion.configuraciones.usuarios.search.filter
}
export const selectResultadosBusquedaUsuarios = (state: RootState) => {
  return state.administracion.configuraciones.usuarios.search.resultados
}
export const selectBusquedaUsuariosEstado = (state: RootState) => {
  return state.administracion.configuraciones.usuarios.search.status
}
export const {
  setResultados,
  resetState,
  setCleanResult,
  changeFilter,
  setSelectedItem,
} = searchConfUsuarioSlice.actions
export const searchReducer = searchConfUsuarioSlice.reducer
