import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FETCH_STATUS } from '../../../../../store/types'
import {
  DtosPendingToPayList,
  FilterDocumentsToPay,
  SuppliersToPayState,
} from '../types/types'
import { DocumentsToPayService } from '../services/docsCtasXPagar.services'
import { DateUtils } from '../../../../../helpers/dateUtils'

const initialState: SuppliersToPayState<DtosPendingToPayList> = {
  filter: {
    numComprobante: null,
    sinCuotas: 0,
    sinCheques: 0,
    sinTarjetas: 0,
    fechaActual: DateUtils.dateToString(new Date(), 'dd/MM/yyyy'),
    fechaDesde: DateUtils.dateToString(
      new Date(new Date().getFullYear(), 11, 1),
      'dd/MM/yyyy',
    ),
    fechaHasta: DateUtils.dateToString(
      new Date(new Date().getFullYear() + 1, 11, 31),
      'dd/MM/yyyy',
    ),
  },
  status: FETCH_STATUS.IDDLE,
  message: '',
  pendingsList: [],
  paidList: [],
  tabs: ['Pendientes', 'Pagados'],
  currentTab: 'Pendientes',
}

export const fetchDocumentsToPay = createAsyncThunk<
  Array<DtosPendingToPayList>,
  FilterDocumentsToPay
>('proveedores/dtosPagar/listarPendientes', async (searchFilter) => {
  try {
    const dtosToPay = await DocumentsToPayService.getPendingPayments(
      searchFilter,
    )
    return dtosToPay
  } catch (error) {
    return Promise.reject(error)
  }
})

export const fetchDocumentsPaid = createAsyncThunk<
  Array<DtosPendingToPayList>,
  FilterDocumentsToPay
>('proveedores/dtosPagar/listarPagados', async (searchFilter) => {
  try {
    const dtosPagados = await DocumentsToPayService.getDocumentsPaid(
      searchFilter,
    )
    return dtosPagados
  } catch (error) {
    return Promise.reject(error)
  }
})

const suppliersTopaySlice = createSlice({
  name: 'suppliersTopay',
  initialState: initialState,
  reducers: {
    setCleanResult(state, action: PayloadAction<string>) {
      if (action.payload === 'Pendientes') state.pendingsList = []
      else state.paidList = []
    },
    changeFilterDocs(state, action: PayloadAction<FilterDocumentsToPay>) {
      state.filter.numComprobante = action.payload.numComprobante
      state.filter.sinCuotas = action.payload.sinCuotas
      state.filter.sinCuotas = action.payload.sinCuotas
      state.filter.sinTarjetas = action.payload.sinTarjetas
      state.filter.fechaDesde = action.payload.fechaDesde
      state.filter.fechaHasta = action.payload.fechaHasta
    },
    cleanFilterDocs(state, action: PayloadAction<FilterDocumentsToPay>) {
      state.filter.numComprobante = null
      state.filter.sinCuotas = 0
      state.filter.sinCuotas = 0
      state.filter.sinTarjetas = 0
      state.filter.fechaDesde = action.payload.fechaDesde
      state.filter.fechaHasta = action.payload.fechaHasta
    },
    changeCurrentTabSearchDocs(state, action: PayloadAction<string>) {
      state.currentTab = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDocumentsToPay.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.message = ''
      }
    })
    builder.addCase(fetchDocumentsToPay.fulfilled, (state, { payload }) => {
      state.pendingsList = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchDocumentsToPay.rejected, (state, { error }) => {
      state.pendingsList = []
      state.status = FETCH_STATUS.FAILED
      state.message = error.message as string
    })
    builder.addCase(fetchDocumentsPaid.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.message = ''
      }
    })
    builder.addCase(fetchDocumentsPaid.fulfilled, (state, { payload }) => {
      state.paidList = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchDocumentsPaid.rejected, (state, { error }) => {
      state.paidList = []
      state.status = FETCH_STATUS.FAILED
      state.message = error.message as string
    })
  },
})

export const { changeFilterDocs, setCleanResult, changeCurrentTabSearchDocs, cleanFilterDocs } =
  suppliersTopaySlice.actions

export const supplierstoPayReducer = suppliersTopaySlice.reducer
