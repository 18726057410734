import { RequestHelper } from "../../../../../../../helpers/requestHelper";

export const ImportExcellService = {
  plantillaImportar,
  exportarDemo,
}




async function plantillaImportar(objeto: any): Promise<any> {

  const fileUpload: any = {
    ...objeto,
    archivo: objeto.file,
    tipo: 'archivo',
  }

  try {
    const data = await RequestHelper.postData<any>(
      "generales",
      "getExcelBalance",
      fileUpload
    );
    return data;

  } catch (error) {
    console.log(error)
    return error
  }

}

function exportarDemo(fileName, tipo: string = "") {
  const config = RequestHelper.getConfig("GET");
  const datosSearch = {
    archivo: fileName,
    tipo
  }
  const response = fetch(RequestHelper.getRequestUrl('generales', 'downloadFile', null, datosSearch), {
    method: 'get',
    headers: config.headers
  });
  return response;
}