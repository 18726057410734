import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FETCH_STATUS, LoaderInfo } from "../../../../../../store/types";
import { BusquedaState, FiltroBusquedaRolesPago, RolesPayList } from "../types/types";
import { defaultInitialFilter } from '../components/buscar/FiltrosBusqueda'

const initialState: BusquedaState<RolesPayList> = {
  filter: {
    anio: 0,
    mes: null,
    lugar: null,
    cedula: '',
    nombres: '',
    apellidos: '',
    formaPago: null,
    opcionFormaPago: null,
    opcionFecha: '',
    pais: null,
    todos: null,
    initial: true,
    listaFormasPagos: [],
  },
  filterBackup: {
    anio: 0,
    mes: null,
    lugar: null,
    cedula: '',
    nombres: '',
    apellidos: '',
    formaPago: null,
    opcionFormaPago: null,
    opcionFecha: '',
    pais: null,
    todos: null,
    initial: true,
    listaFormasPagos: [],
  },
  status: FETCH_STATUS.IDDLE,
  resultados: [],
  seleccionado: null,
  colapsado: true,
  colapsadoDetalle: false,
  import: false,
  searchRols: false,
  loader: {
    show: false,
    mensaje: '',
  },
  nroRegistrosEncontrados: 0,
  total: 0,
  numroles: 0
}


const searchSlice = createSlice({
  name: 'buscarRolPagos',
  initialState: initialState,
  reducers: {
    setResetResults(state) {
      state.resultados = []
    },
    setResults(state, action: PayloadAction<RolesPayList[]>) {
      state.resultados = action.payload
    },
    setResetSeleccion(state) {
      state.seleccionado = null
    },
    setNumRoles(state, action: PayloadAction<number>) {
      state.numroles = action.payload
    },
    setSeleccionarDato(state, action: PayloadAction<RolesPayList>) {
      state.seleccionado = action.payload
    },
    setCollapsed(state, action: PayloadAction<boolean>) {
      state.colapsado = action.payload
    },
    changeFilter(state, action: PayloadAction<FiltroBusquedaRolesPago>) {
      state.filter.anio = action.payload.anio
      state.filter.mes = action.payload.mes
      state.filter.lugar = action.payload.lugar
      state.filter.cedula = action.payload.cedula
      state.filter.nombres = action.payload.nombres
      state.filter.apellidos = action.payload.apellidos
      state.filter.formaPago = action.payload.formaPago
      state.filter.listaFormasPagos = action.payload.listaFormasPagos
      state.filter.opcionFormaPago = action.payload.opcionFormaPago
      state.filter.opcionFecha = action.payload.opcionFecha
      state.filter.pais = action.payload.pais
      state.filter.todos = action.payload.todos
      state.filter.initial = action.payload.initial
    },
    changeFilterBackup(state, action: PayloadAction<FiltroBusquedaRolesPago>) {
      state.filterBackup.anio = action.payload.anio
      state.filterBackup.mes = action.payload.mes
      state.filterBackup.lugar = action.payload.lugar
      state.filterBackup.cedula = action.payload.cedula
      state.filterBackup.nombres = action.payload.nombres
      state.filterBackup.apellidos = action.payload.apellidos
      state.filterBackup.formaPago = action.payload.formaPago
      state.filterBackup.listaFormasPagos = action.payload.listaFormasPagos
      state.filterBackup.opcionFormaPago = action.payload.opcionFormaPago
      state.filterBackup.opcionFecha = action.payload.opcionFecha
      state.filterBackup.pais = action.payload.pais
      state.filterBackup.todos = action.payload.todos
      state.filterBackup.initial = action.payload.initial
    },
    setCollapsedDetail(state, action: PayloadAction<boolean>) {
      state.colapsadoDetalle = action.payload
    },
    setTotal(state, action: PayloadAction<number>) {
      state.total = action.payload
    },
    setImport(state, action: PayloadAction<boolean>) {
      state.import = action.payload
    },
    setNroRegistrosEncontradas(state, action: PayloadAction<number>) {
      state.nroRegistrosEncontrados = action.payload
    },
    setChangeLoader(state, action: PayloadAction<LoaderInfo>) {
      state.loader = action.payload
    },
    setSearch(state, action: PayloadAction<boolean>) {
      state.searchRols = action.payload
    },
  }
})


export const {
  setResetResults,
  setResults,
  changeFilter,
  changeFilterBackup,
  setResetSeleccion,
  setSeleccionarDato,
  setCollapsed,
  setCollapsedDetail,
  setImport,
  setNroRegistrosEncontradas,
  setChangeLoader,
  setSearch,
  setTotal,
  setNumRoles,
} = searchSlice.actions
export const searchReducer = searchSlice.reducer
