import { combineReducers } from "redux";
import { OrganigramaState } from "../types/types";
import { menuReducer } from "./menuReducer";
import { searchReducer } from "./searchReducer";
import { tabsReducer } from "./tabsReducer";
import { dataEditReducer } from "./editDataReducer";
import { configuracionesOrganicoNomina } from "./configReducer";
import { dataNominaEditReducer } from "./nominaReducer";


export const OrganigramaReducer = combineReducers<OrganigramaState>({
  menu: menuReducer,
  search: searchReducer,
  tabs: tabsReducer,
  editData: dataEditReducer,
  config: configuracionesOrganicoNomina,
  nomina: dataNominaEditReducer,
})