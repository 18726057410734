import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FETCH_STATUS, LoaderInfo } from "../../../../../../store/types";
import { BusquedaState, FiltrosBusquedaMarcacion, MarcationList } from "../types/types";
import { defaultFilter } from '../components/buscar/FiltroBusqueda'


const initialState: BusquedaState<MarcationList> = {
  filtro: {
    ...defaultFilter
  },
  filtroBackup: {
    ...defaultFilter
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  resultados: [],
  seleccionado: null,
  colapsado: true,
  searchMarcacion: false,
  loader: {
    show: false,
    mensaje: '',
  },
  nroRegistrosEncontrados: 0
}


const searchSlice = createSlice({
  name: 'buscarMarcacion',
  initialState: initialState,
  reducers: {
    setResetResults(state) {
      state.resultados = []
    },
    setResults(state, action: PayloadAction<MarcationList[]>) {
      state.resultados = action.payload
    },
    setCollapsed(state, action: PayloadAction<boolean>) {
      state.colapsado = action.payload
    },
    changeFilter(state, action: PayloadAction<FiltrosBusquedaMarcacion>) {
      state.filtro.identificacion = action.payload.identificacion
      state.filtro.empleado = action.payload.empleado
      state.filtro.empleadoCodigo = action.payload.empleadoCodigo
      state.filtro.anio = action.payload.anio
      state.filtro.mes = action.payload.mes
      state.filtro.tipoImpresion = action.payload.tipoImpresion
      state.filtro.initital = action.payload.initital
      state.filtro.atrasos = action.payload.atrasos
      state.filtro.salidas = action.payload.salidas
      state.filtro.tiempoAtrasos = action.payload.tiempoAtrasos
      state.filtro.tiempoSalAnt = action.payload.tiempoSalAnt
      state.filtro.hoEx50 = action.payload.hoEx50
      state.filtro.hoEx100 = action.payload.hoEx100
      state.filtro.htrCodigo = action.payload.htrCodigo
    },
    changeFilterBackup(state, action: PayloadAction<FiltrosBusquedaMarcacion>) {
      state.filtroBackup.identificacion = action.payload.identificacion
      state.filtroBackup.empleado = action.payload.empleado
      state.filtroBackup.empleadoCodigo = action.payload.empleadoCodigo
      state.filtroBackup.anio = action.payload.anio
      state.filtroBackup.mes = action.payload.mes
      state.filtroBackup.tipoImpresion = action.payload.tipoImpresion
      state.filtroBackup.initital = action.payload.initital
      state.filtroBackup.atrasos = action.payload.atrasos
      state.filtroBackup.salidas = action.payload.salidas
      state.filtroBackup.tiempoAtrasos = action.payload.tiempoAtrasos
      state.filtroBackup.tiempoSalAnt = action.payload.tiempoSalAnt
      state.filtroBackup.hoEx50 = action.payload.hoEx50
      state.filtroBackup.hoEx100 = action.payload.hoEx100
      state.filtroBackup.htrCodigo = action.payload.htrCodigo
    },
    setNroRegistrosEncontradas(state, action: PayloadAction<number>) {
      state.nroRegistrosEncontrados = action.payload
    },
    setChangeLoader(state, action: PayloadAction<LoaderInfo>) {
      state.loader = action.payload
    },
    setSearch(state, action: PayloadAction<boolean>) {
      state.searchMarcacion = action.payload
    },
    setSeleccionarDato(state, action: PayloadAction<MarcationList>) {
      state.seleccionado = action.payload
    },
    setResetSeleccion(state) {
      state.seleccionado = null
    },
  }
})


export const {
  setResetResults,
  setResults,
  changeFilter,
  changeFilterBackup,
  setCollapsed,
  setNroRegistrosEncontradas,
  setChangeLoader,
  setSearch,
  setSeleccionarDato,
  setResetSeleccion,
} = searchSlice.actions
export const searchReducer = searchSlice.reducer