import { combineReducers } from '@reduxjs/toolkit'
import { menuReducer } from './menuReducer'
import { searchRetencionesReducer } from './searchReducer'
import { datosEdicionReducer } from './editDataReducer'
import { tabsReducer } from './tabsReducer'
import { configuracionesReducer } from './configuracionesRetenciones'
import { RetencionesState } from '../types/types'

export const retencionesReducer = combineReducers<RetencionesState>({
 menu: menuReducer,
 tabs: tabsReducer,
 search: searchRetencionesReducer,
 editData: datosEdicionReducer,
 configuraciones: configuracionesReducer,
})
