import Lookup from 'devextreme-react/lookup';
import { DropDownOptions } from 'devextreme-react/select-box';
import React from 'react';
import { InventarioService } from '../../services/inventario.service';
import { GrupoInventario } from '../../store/types';

interface IBuscarGrupoLookUpProps extends React.PropsWithChildren {
  selected?: GrupoInventario | number | null,
  lineas?: Array<number>,
  onChanged: (newValue: GrupoInventario | undefined) => void,
  allowEdit?: boolean,
  allowClear?: boolean,
  allowAdd?: boolean
}

export const BuscarGrupoLookUp: React.FC<IBuscarGrupoLookUpProps> = (props) => {
  const { selected, onChanged } = props;

  const datasource = React.useMemo(() => {
    return InventarioService.getGruposDatasource();
  }, []);

  const getDisplayExpr = React.useCallback((grupo: GrupoInventario) => {
    return grupo ? grupo.descripcion : '';
  }, []);

  return (
    <Lookup
      dataSource={datasource}
      showDataBeforeSearch
      value={selected}
      minSearchLength={3}
      searchTimeout={500}
      displayExpr={getDisplayExpr}
      searchExpr={['descripcion']}
      pageLoadMode='scrollBottom'
      showClearButton={true}
      onValueChanged={({ value }: any) => {
        onChanged(value);
      }}
    >
      <DropDownOptions
        tabIndex={3000}
        closeOnOutsideClick={true}
        showTitle={false}
      />
      {props.children}
    </Lookup>
  )
};
