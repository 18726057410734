import Lookup from 'devextreme-react/lookup';
import { DropDownOptions } from 'devextreme-react/select-box';
import React from 'react';
import { InventarioService } from '../../services/inventario.service';
import { IceInventario } from '../../store/types';

interface IBuscarIceLookUpProps extends React.PropsWithChildren {
  selected?: number,
  onChanged: (newValue: number | undefined) => void,
  allowEdit?: boolean,
  allowClear?: boolean,
  allowAdd?: boolean
}

export const BuscarIceLookUp: React.FC<IBuscarIceLookUpProps> = (props) => {
  const { selected, onChanged } = props;
  const [listado, setListado] = React.useState<Array<IceInventario>>()




  const getDisplayExpr = React.useCallback((ice: IceInventario) => {
    return ice ? ice.nombre : '';
  }, []);

  React.useEffect(() => {
    const cargarIce = async () => {
      const ice = await InventarioService.getIce();
      setListado(ice);
    }
    cargarIce();
  }, [])

  return (
    <Lookup
      dataSource={listado}
      showDataBeforeSearch
      value={selected}
      minSearchLength={3}
      searchTimeout={500}
      displayExpr={getDisplayExpr}
      searchExpr={['nombre']}
      pageLoadMode='scrollBottom'
      showClearButton={true}
      valueExpr='codigo'
      onValueChanged={({ value }: any) => {
        onChanged(value);
      }}
    >
      <DropDownOptions
        tabIndex={3000}
        closeOnOutsideClick={true}
        showTitle={false}
      />
      {props.children}
    </Lookup>
  )
};
