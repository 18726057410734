/* eslint-disable no-undef */
export const prefijoPaths = {
  getConfigPaths,
}
function getConfigPaths() {
  const configPaths = {
    reports: process?.env?.['REACT_APP_PREFIJO_PATH_REPORTS'] ?? '',
    dashboard: process?.env?.['REACT_APP_PREFIJO_PATH_DASHBOARD'] ?? '',
  }
  return configPaths
}
