import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ConfiguracionesDocsCtasXCobrar } from "../types/types";
import { Autorizacion } from "../../../../../store/types";



const initialState: ConfiguracionesDocsCtasXCobrar = {
  modulo: 0
}

const configuracionesDocsCtasXCobrarSlice = createSlice(
  {
    name: 'configuracionesDocsCtasXCobrar',
    initialState: initialState,
    reducers: {
      setCodigoModulo(state, acion: PayloadAction<number>) {
        state.modulo = acion.payload;
      },
    }
  }
)


export const configuracionesDocsCtosXCobrarReducer = configuracionesDocsCtasXCobrarSlice.reducer;
export const { setCodigoModulo } = configuracionesDocsCtasXCobrarSlice.actions;