import React from 'react'
import SelectBox from 'devextreme-react/select-box'
import { asignacionesService } from '../../contabilidad/pages/asignaciones/services/asignaciones.service'
import { TipoDepreciacion } from './types/types'

interface TiposIceLookUpProps extends React.PropsWithChildren {
  selected: TipoDepreciacion | null
  // eslint-disable-next-line no-unused-vars
  onChanged: (newValue: TipoDepreciacion | null) => void
  // eslint-disable-next-line no-unused-vars
  onChangedOptions: (newValue: Array<TipoDepreciacion> | []) => void
  disabled?: boolean
}

const TiposDepreciacionLookUp: React.FC<TiposIceLookUpProps> = (props) => {
  const { selected, onChanged, disabled, onChangedOptions } = props
  const selectRef = React.useRef<any>()
  const [options, setOptions] = React.useState<Array<TipoDepreciacion> | []>([])

  const loadDatos = React.useCallback(async () => {
    const data = await asignacionesService.obtenerTiposDepreciacion()
    if (data?.length > 0) {
      let optionsLookup = []
      optionsLookup = data.map((option: any) => {
        return {
          codigo: option?.TDE_CODIGO ?? 0,
          descripcion: option?.TDE_DESCRIPCION ?? '',
          porcentaje: option?.TDE_PORCENTAJE ?? 0,
          vidaUtil: option?.TDE_VIDAUTIL ?? '',
          vidaUtilInf: option?.TDE_VIDAUTILNIIF ?? '',
          estado: option?.TDE_ESTADO ?? 0,
          depamo: option?.TDE_DEPAMO ?? '',
        }
      })
      setOptions(optionsLookup)
      onChangedOptions(optionsLookup)
      const selectedIndex = optionsLookup.findIndex((option) => {
        return option.codigo === selected?.codigo
      })
      onChanged(optionsLookup[selectedIndex] ?? optionsLookup[0])
    }
  }, [onChanged, onChangedOptions, selected])

  const getItemDisplayExpr = React.useCallback((item: any) => {
    return item ? `${item.descripcion}` : ''
  }, [])

  const onLookUpValueChanged = React.useCallback(
    (evt: any) => {
      if (evt.event !== undefined) {
        onChanged(evt.value)
      }
    },
    [onChanged],
  )

  const onSelectBoxFocusIn = React.useCallback((e) => {
    e.element.querySelector('input.dx-texteditor-input').select()
  }, [])

  const refreshSelected = React.useCallback(() => {
    if (options?.length > 0) {
      const selectedIndex = options.findIndex((option: any) => {
        return option.codigo === selected?.codigo
      })
      onChanged(options[selectedIndex] ?? options[0])
    }
  }, [onChanged, options, selected])

  React.useEffect(() => {
    loadDatos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    refreshSelected()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  return (
    <SelectBox
      id="tiposImpuestosSelect"
      ref={selectRef}
      items={options ?? []}
      searchEnabled
      displayExpr={getItemDisplayExpr}
      searchExpr={['descripcion']}
      searchTimeout={500}
      value={selected}
      onValueChanged={onLookUpValueChanged}
      disabled={disabled}
      onFocusIn={onSelectBoxFocusIn}
    >
      {props.children}
    </SelectBox>
  )
}
export default React.memo(TiposDepreciacionLookUp)
