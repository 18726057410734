import React, { useEffect } from 'react'
import {
  AccionMenu,
  CustomButtons,
  FETCH_STATUS,
  ToastTypes,
} from '../../../../../store/types'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../../../store/store'
import {
  changeLoaderAplications,
} from './store/generalReducer'
import { addToast } from '../../../../../store/toasterReducer'
import { Aplicacion } from '../../../store/types'
import { utilidades } from '../../../../../helpers/utilidades'
import {
  buttonClick,
  changeCurrentTab,
  closeTabAplicaciones,
  openTab,
  setButtons,
  setCurrentExecutingAction,
} from './store/tabsReducer'
import { ButtonTypes } from '../../../../../views/componentes/globalMenu/types'
import { v4 as uuidv4 } from 'uuid'
import { deleteEditData, initDatosEdicion } from './store/editDataReducer'
import Dialog from '../../../../../views/componentes/librerias/bootstrap-dialog'
import {
  useButtonDisabled,
  useMenuOptions,
} from '../../../../../hooks/useMenuButtonControls'
import { deleteEditDataBackup, setCodigoModulo } from './store/configReducer'
import { EAplicationsAcatha } from '../../../../../store/enumsAplication'
import AplicacionesMain from './components/shared/main'
import { setMenuButtons } from './store/menuReducer'
import { defaultDataAplications } from './components/nuevo'

const pathVerificador = '/aplicaciones'

interface CatalogosAplicacionesProps extends React.PropsWithChildren { }

const CatalogosAplicaciones: React.FunctionComponent<
  CatalogosAplicacionesProps
> = () => {
  const dispatch = useDispatch()
  const menuState = useSelector((state: RootState) => state.global.menu)
  const tabs = useSelector((state: RootState) => {
    return state.inventarios.catalogos.aplicaciones.tabs
  })
  const dataEdit = useSelector((state: RootState) => {
    return state.inventarios.catalogos.aplicaciones.search.dataEdit
  })
  const searchStatus = useSelector((state: RootState) => {
    return state.inventarios.catalogos.aplicaciones.search.status
  })
  const searchResults = useSelector((state: RootState) => {
    return state.inventarios.catalogos.aplicaciones.search.resultados
  })

  const dialogRef = React.useRef<any>(null)
  const getButtonDisabled = useButtonDisabled(tabs)
  const { getMenuOptions, options } = useMenuOptions()

  const playLoader = React.useCallback(
    (message = 'Cargando...') => {
      dispatch(changeLoaderAplications({ show: true, mensaje: message }))
    },
    [dispatch],
  )

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoaderAplications({ show: false, mensaje: '' }))
  }, [dispatch])

  const onTabClosing = React.useCallback(
    async (index) => {
      const closedTab = tabs.tabs[index]
      dispatch(closeTabAplicaciones(closedTab.tabKey ?? index))
      await utilidades.sleep(50)
      dispatch(deleteEditData(closedTab.tabKey ?? index))
      dispatch(deleteEditDataBackup(closedTab.tabKey ?? index))
    },
    [dispatch, tabs.tabs],
  )

  const onTabChanged = React.useCallback(
    (tabKey: string) => {
      dispatch(changeCurrentTab(tabKey))
    },
    [dispatch],
  )

  const onMenuButtonClick = React.useCallback(
    async (action: AccionMenu, tab: string = "") => {
      const id = uuidv4()
      switch (action.actionType) {
        case ButtonTypes.find:
          if (tabs.current === 'BUSQUEDA') {
            dispatch(buttonClick({
              tabKey: tab === "" ? tabs.current : tab,
              button: action.actionType
            }))
          } else dispatch(changeCurrentTab('BUSQUEDA'))
          break
        case ButtonTypes.new:
          dispatch(
            initDatosEdicion({
              key: id,
              data: {
                ...defaultDataAplications,
                loading: true
              },
            }),
          )
          dispatch(openTab({ key: id }))
          break
        case ButtonTypes.save:
          if (tabs.current !== 'BUSQUEDA')
            dispatch(buttonClick({
              tabKey: tab === "" ? tabs.current : tab,
              button: action.actionType
            }))
          break
        case ButtonTypes.edit:
          if (tabs.current === 'BUSQUEDA') {
            dispatch(buttonClick({
              tabKey: tab === "" ? tabs.current : tab,
              button: action.actionType
            }))
          }
          break
        case ButtonTypes.print:
          if (tabs.current === 'BUSQUEDA') {
            dispatch(buttonClick({
              tabKey: tab === "" ? tabs.current : tab,
              button: action.actionType
            }))
          }
          break
        case ButtonTypes.print_design:
          if (tabs.current === 'BUSQUEDA') {
            dispatch(buttonClick({
              tabKey: tab === "" ? tabs.current : tab,
              button: action.actionType
            }))
          }
          break
        case ButtonTypes.delete:
          if (tabs.current === 'BUSQUEDA') {
            dispatch(buttonClick({
              tabKey: tab === "" ? tabs.current : tab,
              button: action.actionType
            }))
          }
          break
        case ButtonTypes.broom:
          dispatch(buttonClick({
            tabKey: tab === "" ? tabs.current : tab,
            button: action.actionType
          }))
          break
        case ButtonTypes.undo:
          dispatch(buttonClick({
            tabKey: tab === "" ? tabs.current : tab,
            button: action.actionType
          }))
          break
        default:
          break
      }
    },
    [dispatch, tabs],
  )

  const onGetAppCode = React.useCallback(() => {
    dispatch(setCodigoModulo(parseInt(EAplicationsAcatha.Aplicaciones)))
  }, [dispatch])

  React.useEffect(() => {
    onGetAppCode()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (menuState.length > 0 && options.length === 0) {
      getMenuOptions(menuState, 'to', pathVerificador)
    }
  }, [menuState, getMenuOptions, options])

  React.useEffect(() => {
    if (searchStatus === FETCH_STATUS.FAILED) {
      stopLoader()
      dispatch(
        addToast({
          title: 'Buscar Aplicaciones',
          content: 'Error al realizar la búsqueda',
          type: ToastTypes.Danger,
        }),
      )
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'BUSQUEDA',
          buttonType: undefined,
        }),
      )
    }
    if (searchStatus === FETCH_STATUS.LOADING) {
      playLoader('Buscando...')
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'BUSQUEDA',
          buttonType: ButtonTypes.find,
        }),
      )
    }
    if (searchStatus === FETCH_STATUS.SUCCESS) {
      stopLoader()
      dispatch(
        setButtons({
          tabKey: 'BUSQUEDA',
          buttons: {
            Nuevo: true,
            Guardar: false,
            Editar: dataEdit !== null && dataEdit !== undefined,
            Eliminar: dataEdit !== null && dataEdit !== undefined,
            Buscar: true,
            Imprimir: searchResults['auto'] && searchResults['auto'].length > 0,
            Limpiar: true,
            Deshacer: true,
          },
        }),
      )
    }
  }, [dispatch, searchStatus, playLoader, searchResults, stopLoader, dataEdit])

  const onSearchExecutingAction = React.useCallback((tabKey: string, type?: ButtonTypes) => {
    dispatch(setCurrentExecutingAction({
      tabKey: tabKey,
      buttonType: type
    }))
  }, [dispatch]);

  const onActionsButtonsLoaded = React.useCallback((actions: Array<AccionMenu>) => {
    dispatch(setMenuButtons(actions));
  }, [dispatch])


  return (
    <>
      <div>
        <Dialog ref={dialogRef} />
      </div>
      <AplicacionesMain
        menu={{ loading: false, accions: options }}
        tabs={tabs}
        onTabChanged={onTabChanged}
        codigoModulo={parseInt(EAplicationsAcatha.Aplicaciones)}
        opciones={OpcionesModeulo}
        onActionsButtonsLoaded={onActionsButtonsLoaded}
        onMenuButtonClick={onMenuButtonClick}
        searching={searchStatus === FETCH_STATUS.LOADING}
        ontabClosing={onTabClosing}
        hayResultadosBusqueda={searchResults.length > 0}
        verificarPuntoVenta
        verificarAutorizacion
        onExecutingAction={onSearchExecutingAction}
        tipoAplicacion={Aplicacion.catalogosAplicaciones}
      />
    </>
  )
}

export default React.memo(CatalogosAplicaciones)
const OpcionesModeulo = [parseInt(EAplicationsAcatha.Aplicaciones)];

export const ModuleButtons: CustomButtons = {
  Nuevo: true,
  Guardar: true,
  Editar: false,
  Eliminar: false,
  Buscar: false,
  Imprimir: false,
  Limpiar: false,
  Deshacer: true,
}

