import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  FiltrosInventariosMovState,
  BusquedaState,
  InventarioInicial,
  FiltrosSearch,
  FiltrosInventariosConsolidarState,
} from '../types/types'
import { FETCH_STATUS, LoaderInfo } from '../../../../../../store/types'
import { conceptsServices } from '../services/conceptos.services'
import { inititalState } from '../components/buscar'
import { initialConsolidate } from '../components/consolidar'

const initialState: BusquedaState<InventarioInicial> = {
  filter: { ...inititalState },
  filterConsolidar: { ...initialConsolidate },
  status: FETCH_STATUS.IDDLE,
  statusConsolidate: FETCH_STATUS.IDDLE,
  seleccionado: null,
  verRegistro: false,
  colapsado: true,
  mensaje: '',
  resultados: [],
}

export const fetchInventorys = createAsyncThunk<
  Array<InventarioInicial>,
  FiltrosSearch
>('inventarios/movimientos/inventario_iniical/listar', async (filtro) => {
  try {
    const inventorysList = await conceptsServices.getOrdenes(filtro)
    return inventorysList
  } catch (error) {
    return Promise.reject(error)
  }
})

export const fetchConsolidate = createAsyncThunk<
  Array<any>,
  any
>('inventarios/movimientos/inventario_iniical/consolidar/listar', async (filtro) => {
  try {
    const inventorysList = await conceptsServices.getConsolidate(filtro)
    return inventorysList
  } catch (error) {
    return Promise.reject(error)
  }
})

const searchSlice = createSlice({
  name: 'buscarInventarioinicialMov',
  initialState: initialState,
  reducers: {
    setCleanResult(state) {
      state.resultados = []
    },
    setResultados(state, action: PayloadAction<Array<InventarioInicial>>) {
      state.resultados = action.payload
    },
    setVerRegistro(state, action: PayloadAction<boolean>) {
      state.verRegistro = action.payload
    },
    setColapsado(state, action: PayloadAction<boolean>) {
      state.colapsado = action.payload
    },
    changeFilter(state, action: PayloadAction<FiltrosInventariosMovState>) {
      state.filter.documento = action.payload.documento
      state.filter.fechaDesde = action.payload.fechaDesde
      state.filter.fechaHasta = action.payload.fechaHasta
      state.filter.estado = action.payload.estado
      state.filter.item = action.payload.item
      state.filter.observaciones = action.payload.observaciones
      state.filter.initial = action.payload.initial
    },
    changeFilterCon(state, action: PayloadAction<FiltrosInventariosConsolidarState>) {
      state.filterConsolidar.anio = action.payload.anio
      state.filterConsolidar.fechaInv = action.payload.fechaInv
      state.filterConsolidar.local = action.payload.local
      state.filterConsolidar.dataItems = action.payload.dataItems
      state.filterConsolidar.dataMov = action.payload.dataMov
      state.filterConsolidar.subtotal0 = action.payload.subtotal0
      state.filterConsolidar.subtotalIVA = action.payload.subtotalIVA
      state.filterConsolidar.subtotal = action.payload.subtotal
      state.filterConsolidar.iva = action.payload.iva
      state.filterConsolidar.IVA = action.payload.IVA
      state.filterConsolidar.total = action.payload.total
      state.filterConsolidar.initial = action.payload.initial
    },
    setResetSeleccion(state) {
      state.seleccionado = null
    },
    setSeleccionarDato(state, action: PayloadAction<InventarioInicial>) {
      state.seleccionado = action.payload
    },
    setChangeLoaderCon(state, action: PayloadAction<LoaderInfo>) {
      state.filterConsolidar.loader = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchInventorys.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchInventorys.fulfilled, (state, { payload }) => {
      state.resultados = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchInventorys.rejected, (state, { payload }) => {
      state.resultados = []
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
    builder.addCase(fetchConsolidate.pending, (state) => {
      if (
        state.statusConsolidate === FETCH_STATUS.IDDLE ||
        state.statusConsolidate === FETCH_STATUS.SUCCESS
      ) {
        state.statusConsolidate = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchConsolidate.fulfilled, (state, { payload }) => {
      state.filterConsolidar.dataItems = payload
      state.statusConsolidate = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchConsolidate.rejected, (state, { payload }) => {
      state.filterConsolidar.dataItems = []
      state.statusConsolidate = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
  },
})

export const { changeFilter, setCleanResult, changeFilterCon, setResetSeleccion, setSeleccionarDato, setResultados, setChangeLoaderCon, setColapsado, setVerRegistro } = searchSlice.actions

export const searchReducer = searchSlice.reducer
