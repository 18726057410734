import React from 'react'
import { CCol, CRow } from '@coreui/react-pro';
import { Column, Editing, FilterRow, HeaderFilter, Pager, Paging } from 'devextreme-react/data-grid';
import { DataGrid, NumberBox } from 'devextreme-react';

import { AbonosCuadresCajaListado } from '../../../../../../ventas/pages/cuadres/types/types';
import { getAllowedPageSizes } from '../../../../../../../helpers/Helper';

interface IAuditoria extends React.PropsWithChildren {
  data: Array<AbonosCuadresCajaListado>
}


const Auditoria: React.FunctionComponent<IAuditoria> = (props) => {

  const { data } = props

  const [displayMode,] = React.useState<string | 'full' | 'compact'>('full');
  const [showPageSizeSelector,] = React.useState<boolean>(true);
  const [showInfo,] = React.useState<boolean>(true);
  const [showNavButtons,] = React.useState<boolean>(true);
  const [resultados, setResultados] = React.useState<Array<any>>([]);

  // const resultados = data['auto'] || []

  const getResultados = React.useCallback(() => {
    let newArray = []
    if (data !== null && data !== undefined && data['auto'] && data['auto'].length > 0) {
      newArray = data['auto']
    }
    setResultados(newArray)
  }, [data])

  const getTotalSaldo = React.useCallback((): number => {
    let total = 0;
    if (resultados.length > 0) {
      resultados.map((item) => {
        total = total + item?.abonoVentaValor ?? 0
      })
    }
    return total
  }, [resultados])

  React.useEffect(() => {
    getResultados()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])


  return (
    <>
      <CRow>
        <CCol lg="12" className="d-flex justify-content-end">
          <strong>{resultados.length}</strong>
        </CCol>
      </CRow>
      <CRow className='border mt-2'>
        <CCol xs={12} sm={12} md={12} xl={12} >
          <DataGrid
            selection={{ mode: 'single' }}
            hoverStateEnabled={true}
            dataSource={resultados}
            showBorders={true}
            showRowLines={true}
            allowColumnReordering={true}
            allowColumnResizing={true}
            columnAutoWidth={true}
            key="ingresoCodigo"
          >
            <FilterRow visible={true} />
            <HeaderFilter visible={true} />
            <Editing mode='cell' allowUpdating={true} />

            <Paging defaultPageSize={10} />
            <Pager
              visible={resultados.length > 0 ? true : false}
              allowedPageSizes={getAllowedPageSizes(resultados)}
              displayMode={displayMode}
              showPageSizeSelector={showPageSizeSelector}
              showInfo={showInfo}
              showNavigationButtons={showNavButtons} />
            <Column dataField='ventaFecha' caption='Registro' width='100%' allowEditing={false} />
            <Column dataField='asientoNumero' caption='Diario' width='100%' allowEditing={false} />
            <Column dataField='clienteNombre' caption='Clientes' width='100%' allowEditing={false} />
            <Column dataField='clienteIdentificacion' caption='RUC/Cedula' width='100%' allowEditing={false} />
            <Column dataField='ingresoValor' caption='Numero' width='100%' allowEditing={false} />
            <Column dataField='ventaTipoDocumentoDescripcion' caption='Comprobante' width='100%' allowEditing={false} />
            <Column dataField='numero' caption='Numero' width='100%' allowEditing={false} />
            <Column dataField='localNombre' caption='Local' width='100%' allowEditing={false} />
            <Column dataField='pagoVentaVencimiento' caption='Vencimiento' width='100%' allowEditing={false} />
            <Column dataField='abonoVentaFecha' caption='Fecha Abono' width='100%' allowEditing={false} />
            <Column dataField='puntoVentaHost' caption='POS' width='100%' allowEditing={false} />
            <Column dataField='vendedorCompleto' caption='Vendedor' width='100%' allowEditing={false} />
            <Column dataField='abonoVentaTipoPagoDescripcion' caption='Forma Pago' width='100%' allowEditing={false} />
            <Column dataField='abonoVentaTipoPagoDocumentoDescripcion' caption='Doc' width='100%' allowEditing={false} />
            <Column dataField='pagoventaCuota' caption='Cuota' width='100%' allowEditing={false} />
            <Column dataField='abonoVentaTotal' caption='Abono' width='100%' allowEditing={false} />
            <Column dataField='pagoVentaSaldo' caption='Saldo' width='100%' allowEditing={false} />
          </DataGrid>
        </CCol>
      </CRow>
      <CRow>
        <CCol className="mt-1 d-flex justify-content-end">
          <div className="dx-field">
            <div className="dx-field-label">{'Total:'}</div>
            <div className="dx-field-value">
              <NumberBox
                width="100%"
                value={getTotalSaldo()}
                placeholder=""
                format="#,##0.00"
              />
            </div>
          </div>
        </CCol>
      </CRow>
    </>
  )
}

export default Auditoria