import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { OrganicoDatosEdicion, configOrganico } from '../types/types'
import { EdicionPayload } from '../../../store/types'

const initialState: configOrganico = {
  aplicacion: 0,
}

const configuracionOrganico = createSlice({
  name: 'configOrganicoNomina',
  initialState: initialState,
  reducers: {
    setAplicacionModulo(state, acion: PayloadAction<number>) {
      state.aplicacion = acion.payload
    },
    deleteEditDataBackup(state, action: PayloadAction<string>) {
      delete state[action.payload]
    },
    setDatosEdicionBackup(
      state,
      action: PayloadAction<EdicionPayload<OrganicoDatosEdicion>>,
    ) {
      state[action.payload.key] = action.payload.data
    },
  },
})

export const configuracionesOrganicoNomina = configuracionOrganico.reducer
export const { setAplicacionModulo, deleteEditDataBackup, setDatosEdicionBackup } = configuracionOrganico.actions
