import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SearchState } from '../types/types'
import { LoaderInfo } from '../../../../../store/types'

export const initialState: SearchState = {
  loader: { show: false, mensaje: '' },
  currentFunction: '',
  item: '',
  barras: '',
  decripcion: '',
  pvp: null,
  pvd: null,
  existencia: null,
  imagen: '',
  collapsed: true,
}

const checkPrices = createSlice({
  name: 'searchCheckPrices',
  initialState: initialState,
  reducers: {
    setItemPrice(state, action: PayloadAction<SearchState>) {
      state.item = action.payload.item
      state.barras = action.payload.barras
      state.decripcion = action.payload.decripcion
      state.pvp = action.payload.pvp
      state.pvd = action.payload.pvd
      state.existencia = action.payload.existencia
      state.imagen = action.payload.imagen
    },
    setCurrentFunction(state, action: PayloadAction<string>) {
      state.currentFunction = action.payload
    },
    setLoader(state, action: PayloadAction<LoaderInfo>) {
      state.loader = action.payload
    },
    setCollapsed(state, action: PayloadAction<boolean>) {
      state.collapsed = action.payload
    },
  },
})

export const { setItemPrice, setCurrentFunction, setLoader, setCollapsed } =
  checkPrices.actions

export const checkPricesReducer = checkPrices.reducer
