import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GenrateRols, GenrateRolsList } from "../types/types";
import { LoaderInfo } from "../../../../../../store/types";



const initialState: GenrateRols = {
  anio: 2013,
  mes: null,
  lugar: null,
  pais: null,
  tipoPersonal: null,
  resultadosTodos: [],
  seleccionado: null,
  loader: {
    show: false,
    mensaje: '',
  },
  loading: true,
  initial: true
}


const excellSlice = createSlice({
  name: 'rolPagosgenerar',
  initialState: initialState,
  reducers: {
    setChangeLoader(state, action: PayloadAction<LoaderInfo>) {
      state.loader = action.payload
    },
    setInitital(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setRolesGenList(state, action: PayloadAction<Array<GenrateRolsList>>) {
      state.resultadosTodos = action.payload
    },
    setSeleccionarDato(state, action: PayloadAction<GenrateRolsList>) {
      state.seleccionado = action.payload
    },
    setChangeData(state, action: PayloadAction<GenrateRols>) {
      state.anio = action.payload.anio
      state.mes = action.payload.mes
      state.lugar = action.payload.lugar
      state.pais = action.payload.pais
      state.tipoPersonal = action.payload.tipoPersonal
      state.initial = action.payload.initial
    },
  }
})

export const {
  setChangeLoader,
  setInitital,
  setRolesGenList,
  setSeleccionarDato,
  setChangeData,
} = excellSlice.actions

export const generateReducer = excellSlice.reducer