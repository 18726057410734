import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import { StatesEdition } from '../../../../../ventas/types/enums'
import { TransferEditData } from '../types/types'
import { ModuleButtons } from '..'
import { TabsState, TabState } from '../../../../../../store/genericTabTypes'
import {
  tabExists,
  closeTab,
} from '../../../../../../hooks/useNavigationTabControls'
import { CustomButtons } from '../../../../../../store/types'
import { TabTypes } from '../../../../../../store/enums'

const initialState: TabsState<TransferEditData> = {
  current: 'BUSQUEDA',
  tabs: {
    BUSQUEDA: {
      id: 0,
      canClose: false,
      editStatus: StatesEdition.doesNotApply,
      globalButtonClick: ButtonTypes.none,
      tittle: 'Búsqueda',
      type: TabTypes.busqueda,
      imprimir: false,
      tabKey: 'BUSQUEDA',
      buttons: {
        Nuevo: true,
        Guardar: false,
        Editar: false,
        Eliminar: false,
        Buscar: true,
        Imprimir: false,
        Deshacer: true,
        Limpiar: true,
      },
    },
  },
}

const tabsLineasSlice = createSlice({
  name: 'transferenciasTabs',
  initialState: initialState,
  reducers: {
    closeTabTransferencias: closeTab,
    openTab(
      state,
      action: PayloadAction<{
        key: string
        transferencia?: any
        templateMode?: boolean
        editStatus?: StatesEdition
      }>,
    ) {
      const { key, transferencia, templateMode, editStatus } = action.payload
      if (transferencia === undefined || templateMode) {
        const tab: TabState<TransferEditData> = {
          id: -1,
          canClose: true,
          editStatus: StatesEdition.new,
          globalButtonClick: ButtonTypes.none,
          tittle: 'Nueva',
          type: TabTypes.documento,
          imprimir: false,
          tabKey: key,
          info: {
            codigo: 0,
          },
          buttons: ModuleButtons,
          templateMode: templateMode,
        }
        state.tabs[key] = tab
        state.current = key
      } else {
        const tabKey =
          editStatus === StatesEdition.edit
            ? false
            : tabExists(state.tabs, transferencia.codigo)
        if (tabKey) state.current = tabKey
        else {
          const tab: TabState<TransferEditData> = {
            tabKey: key,
            id: editStatus === StatesEdition.edit ? -1 : transferencia.codigo,
            tittle:
              editStatus === StatesEdition.edit
                ? 'Nueva'
                : `${transferencia.codigo} - ${transferencia.progresoDescripcion}`,
            canClose: true,
            editStatus: editStatus,
            type: TabTypes.documento,
            info: {
              codigo: transferencia.codigo,
              info: transferencia,
            },
            globalButtonClick: ButtonTypes.none,
            imprimir: false,
            buttons: ModuleButtons,
          }
          state.tabs[key] = tab
          state.current = key
        }
      }
    },
    openTabShipments(
      state,
      action: PayloadAction<{
        key: string
        type: TabTypes
        codeTransfer: number
      }>,
    ) {
      const { key, type, codeTransfer } = action.payload
      const tabKey = tabExists(state.tabs, codeTransfer)
      if (tabKey) state.current = tabKey
      else {
        const tab: TabState<any> = {
          id: codeTransfer,
          tittle: `${
            type === TabTypes.envios ? 'ENVIAR' : 'RECIBIR'
          } - TRF: ${codeTransfer}`,
          type: type,
          info: {
            codigo: codeTransfer,
          },
          canClose: true,
          editStatus: StatesEdition.new,
          globalButtonClick: ButtonTypes.none,
          imprimir: false,
          tabKey: key,
          buttons: {
            Guardar: true,
            Buscar: true,
          },
        }
        state.tabs[key] = tab
        state.current = key
      }
    },
    openTabArchived(
      state,
      action: PayloadAction<{
        key: string
        codeTransfer: number
      }>,
    ) {
      const { key, codeTransfer } = action.payload
      const tabKey = tabExists(state.tabs, codeTransfer)
      if (tabKey) state.current = tabKey
      else {
        const tab: TabState<any> = {
          id: codeTransfer,
          tittle: `ARCHIVAR - TRF: ${codeTransfer}`,
          type: TabTypes.archivar,
          info: {
            codigo: codeTransfer,
          },
          canClose: true,
          editStatus: StatesEdition.new,
          globalButtonClick: ButtonTypes.none,
          imprimir: false,
          tabKey: key,
          buttons: {
            Guardar: true,
            Buscar: true,
          },
        }
        state.tabs[key] = tab
        state.current = key
      }
    },
    setNameTab(
      state,
      action: PayloadAction<{ key: string; codigo: number; progreso: string }>,
    ) {
      state.tabs[
        action.payload.key
      ].tittle = `${action.payload.codigo}-${action.payload.progreso}`
      state.tabs[action.payload.key].info.codigo = action.payload.codigo
    },
    changeCurrentTab(state, action: PayloadAction<string>) {
      if (Object.prototype.hasOwnProperty.call(state.tabs, action.payload)) {
        state.current = action.payload
      }
    },
    setCurrentExecutingAction(
      state,
      action: PayloadAction<{
        tabKey: string
        buttonType: ButtonTypes | undefined
      }>,
    ) {
      const { buttonType, tabKey } = action.payload
      state.tabs[tabKey].currentExecutingAction = buttonType
    },
    setButtons(
      state,
      action: PayloadAction<{ tabKey: string; buttons: CustomButtons }>,
    ) {
      const { buttons, tabKey } = action.payload
      state.tabs[tabKey].buttons = buttons
      state.tabs[tabKey].currentExecutingAction = undefined
    },
    buttonClick(
      state,
      action: PayloadAction<{ tabKey: string; button: ButtonTypes }>,
    ) {
      state.tabs[action.payload.tabKey].globalButtonClick =
        action.payload.button
    },
    clearButtonClick(state, action: PayloadAction<string>) {
      if (Object.prototype.hasOwnProperty.call(state.tabs, action.payload)) {
        state.tabs[action.payload].globalButtonClick = ButtonTypes.none
      }
    },
  },
})

export const {
  closeTabTransferencias,
  openTab,
  changeCurrentTab,
  setCurrentExecutingAction,
  setButtons,
  buttonClick,
  clearButtonClick,
  setNameTab,
  openTabShipments,
  openTabArchived,
} = tabsLineasSlice.actions
export const tabsReducer = tabsLineasSlice.reducer
