import React, { useCallback, useState } from 'react'
import { CCard, CCardBody, CCardHeader } from '@coreui/react-pro'
import FIleInput from '../../../../../componentes/fileInput'
import { RadioGroup } from 'devextreme-react';
import { CustomButtons } from '../../../../../../store/types'

const gruposDtosCobrar = [
  { value: 0, label: 'Abonos' },
  { value: 1, label: 'Anticipos' },
  { value: 2, label: 'Saldos' },
  { value: 3, label: 'Cartera' },
  { value: 4, label: 'Cartera Vencida' },
  { value: 5, label: 'Listado de Abonos' },
];

type ImportarProps = {
  downDemo: () => void
}

const Importar = (props: ImportarProps) => {
  const [dto, setDto] = React.useState<{ value: number; label: string }>(
    gruposDtosCobrar[0],
  )
  const [labelImport, setLabelImport] = useState<string>('');
  const [archivo, setArchivo] = useState<any>(null);

  const handleReset = () => {
    setLabelImport('')
    setArchivo(null)
  }

  const onFileChanged = useCallback((file: any) => {
    if (file === null || file.value.length === 0) handleReset()
    else if (file.value.length > 0) {
      setArchivo(file.value[0])
      setLabelImport(file.value[0].name)
    }
  }, [])


  return (
    <CCard>
      <CCardHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
        <strong> {'Importar'} </strong>
      </CCardHeader>
      <CCardBody>
        <RadioGroup
          name="idCodigoComprobantes"
          dataSource={gruposDtosCobrar}
          value={dto}
          layout='horizontal'
          displayExpr='label'
          onValueChanged={(evt) => {
            if (evt?.event !== undefined) setDto(evt.value)
          }}
          width="100%"
        />
        <FIleInput
          accept={'application/vnd.ms-excel'}
          onChanged={onFileChanged}
          downDemo={props.downDemo}
        />
      </CCardBody>
    </CCard>
  )
}

export default Importar

export const ModuleButtonsImport: CustomButtons = {
  Nuevo: true,
  Guardar: true,
  Buscar: true,
  Deshacer: true,
  Eliminar: true,
  Editar: true,
  Imprimir: false,
  Importar: true,
  Limpiar: true,
}
