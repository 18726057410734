import React from 'react'
import './customSpinner.scss'

export const MenuSpinner = (props) => {
  return (
    <div className="loader-wrapper">
      <div className="loader">{props.children}</div>
    </div>
  )
}
