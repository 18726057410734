import { DateUtils, formatoFechasApi } from "../../../../helpers/dateUtils";
import { RequestHelper } from "../../../../helpers/requestHelper";
import { utilidades } from "../../../../helpers/utilidades";
import { SesionService } from "../../../../services/sesion.service";
import { CapitalizeMode, TEstado } from "../../../../store/types";

export const jornadaService = { getJornadaLabolar };

async function getJornadaLabolar(label: string): Promise<any> {
  const sesion = SesionService.getCurrentSesion();
  try {
    const data: any = {
      mensaje: label,
      codigo: sesion?.empresa?.codigo
    };
    const keysToCache: any = {
      ...data,
      dateKey: DateUtils.getCurrentDateAsString(formatoFechasApi),
      empresa: sesion?.empresa?.codigo,
    }
    const cacheKey = 'tipoJornadaLaboral' + JSON.stringify(keysToCache);
    const cached = sessionStorage.getItem(cacheKey);
    if (cached) {
      return { error: false, auto: JSON.parse(cached) };
    } else {
      const tipos: any = await RequestHelper.getAll('nomina/contratos', 'workingDay/cBoxLoad', "", data);

      const opt: Array<TEstado> = []

      if (tipos?.auto && tipos?.auto.length > 0) {
        for (const x of tipos.auto) {
          opt.push({
            codigo: x?.codigo ?? -1,
            descripcion: utilidades.capText(x?.nombre, CapitalizeMode.upperFirst) ?? '',
          })
        }
      }
      sessionStorage.setItem(cacheKey, JSON.stringify(opt));
      tipos.auto = opt ?? []
      return tipos;
    }
  } catch (error) {
    return error;
  }
}