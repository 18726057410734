import { CAlert } from '@coreui/react-pro'
import React from 'react'
import CustomModalDevx from '../../../../../views/componentes/modal/Modal'

interface Props extends React.PropsWithChildren {
  titulo: string,
  mensaje: string | string[],
  content?: any,
  onOk: () => void
  type?: 'danger' | 'success' | 'warning' | 'info'
}

const VisualizaError = (props: Props) => {

  if (props.content) {
    return (
      <CustomModalDevx
        show
        size='sm'
        onClose={() => props.onOk()}
        title={props.titulo ? `Acatha - ${props.titulo}` : 'Acatha'}
      >
        {props.content}
      </CustomModalDevx>
    )
  }

  return (
    <CustomModalDevx
      show
      size='sm'
      onClose={() => props.onOk()}
      title={props.titulo ? `Acatha - ${props.titulo}` : 'Acatha'}
    >
      <CAlert
        color={props?.type ?? 'danger'}
      >
        {props.mensaje}
      </CAlert>
    </CustomModalDevx>
  )
}

export default VisualizaError
