import React, { Fragment } from 'react'
import { CCol, CRow, CFormLabel } from '@coreui/react-pro'
import TextBox from 'devextreme-react/text-box'

type InputProps = {
  placeholder?: string
  value: string
  label: any
  name: string
  required?: boolean
  readOnly?: boolean
  autoComplete?: string
  onChange: (data) => void
  className?: any
  onKeyDown?: any
  maxLength?: number
}

const defaultProps = {
  valid: true,
  placeholder: '',
  labelmd: '3',
  inputxs: '12',
  inputmd: '9',
  readOnly: false,
  type: 'text',
}

const Input: React.FC<InputProps> = ({
  label,
  value,
  onChange,
  onKeyDown,
  maxLength,
}) => {
  return (
    <Fragment>
      <CRow className="mb-1 align-items-center ">
        <CCol>
          <CFormLabel className="d-flex justify-content-start  col-form-label">
            {label}
          </CFormLabel>
        </CCol>
        <CCol sm={4} className="d-flex justify-content-end">
          <TextBox
            value={value}
            placeholder=""
            onValueChanged={(data) => onChange(data)}
            width="100%"
            inputAttr={{ autocomplete: 'nope' }}
            onKeyDown={onKeyDown}
            maxLength={maxLength}
          />
        </CCol>
      </CRow>
    </Fragment>
  )
}

Input.defaultProps = defaultProps
export default Input
