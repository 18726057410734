import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { UrlHelper } from '../../../../../../helpers/urlHelper'
import {
  AsignacionesFormasClientes,
  AsignacionesFormasPagoCliente,
  ClientesDatosEdicion,
  ClientesDatosExportar,
  ClientesListado,
  DatosEdicionAdicionales,
  DatosEdicionContribuyente,
  DatosEdicionDatosGenerales,
  DatosEdicionGrupos,
  DatosEdicionHistoricosCliente,
  DatosEdicionLineas,
  DatosEdicionMarcas,
  DatosEdicionRed,
  DatosEdicionSucursal,
  DatosEdicionUafe,
  DatosEdicionVentasCliente,
  DetailGetCustomer,
  FactorsDescrictionList,
  TClienteIngresar,
  TMainEdition,
  TRetencionesIva,
  TabClienteState,
  TelefonoCliente,
} from '../../types/types'
import { StatesEdition } from '../../../../../ventas/types/enums'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import { isMobile } from 'react-device-detect'
import BlockUi from '../../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../../views/componentes/loadingindicator/loadingindicator'
import { RootState } from '../../../../../../store/store'
import { CAlert } from '@coreui/react-pro'
import { defaultDatosEdicionDatosGenerales } from './componentes/datosgenerales'
import { defaultDatosEdicionSucursalCliente } from './componentes/sucursales'
import { defaultDatosEdicionVentaslCliente } from './componentes/ventas'
import { defaultDatosEdicionHistoricosCliente } from './componentes/historicos'
import { defaultDatosEdicionAdicionales } from './componentes/adicionales'
import {
  defaultDatosEdicionRed,
  dhcpGruposDHCP,
  eoGrupos,
  nsGrupos,
  registadoGrupos,
} from './componentes/red'
import { defaultDatosEdicionLineas } from './componentes/lineas'
import { defaultDatosEdicionGrupos } from './componentes/grupos'
import { defaultDatosEdicionMarcas } from './componentes/marcas'
import { defaultDatosEdicionUafe } from './componentes/uafe'
import { defaultDatosEdicionDatosContribuyente } from './componentes/main'
import {
  changeEditStatus,
  clearButtonClick,
  setButtons,
  setCurrentExecutingAction,
} from '../../store/tabsReducer'
import { addToast } from '../../../../../../store/toasterReducer'
import {
  CustomButtons,
  DocumentInfo,
  ToastTypes,
} from '../../../../../../store/types'
import {
  setDatosEdicion, setMuestraError, setMuestraErrores, updateLoader
} from '../../store/editDataReducer'
import { ClientesService } from '../../services/clientes.service'
import { FormaPagoModuloVentas } from '../../../../../ventas/types/types'
import { ValidationGroup, ValidationSummary } from 'devextreme-react'
import { getCiudades, getClasificacion, getDetalleContactos, getDpDesempenios, getDpOrigen, getDpRegimenEmpresa, getDpSituacionLaboral, getEstados, getEstadosCivil, getEstudios, getFormasPago, getImpresiones, getOrigen, getTipoIdetificacion, getTiposAgente, getTiposClientes, getZonas } from '../../helper/helper'
import { PopupContent } from '../../../../../../views/componentes/popupContent'
import TabsEdition from './componentes/tabsEdition/tabsEdition'
import VisualizaError from '../../../../../ventas/pages/shared/visualizaError/visualizaError'
import { DateUtils } from '../../../../../../helpers/dateUtils'
import { TFormasPago } from '../../../../../componentes/formasPagoLookUp/store/type'
import { TDetalleContactosSucursales } from './componentes/sucursales/contactosLookUp/types/type'
import { v4 as uuidv4 } from 'uuid';
import { VendedoresService } from '../../../../../shared/components/buscarVendedorLookMemoUp/service/vendedor.service'
import { zonasGeograficas } from '../../../../../componentes/lookupZonasGeograficas'
import { actividadEconomica } from '../../../../../componentes/lookUpActividadEconomica'
import { personasPoliticamenteExp } from '../../../../../componentes/lookUpPersonasPoliticamenteExpuestas'
import { profesiones } from '../../../../../componentes/lookUpProfesiones'
import { tipoCliente } from '../../../../../componentes/lookUpTipoCliente'
import { concentracionAccionaria } from '../../../../../componentes/lookUpConcentracionAccionaria'
import { ReportWithLocalDataModal } from '../../../../../../views/componentes/xtrareports/ReportWithLocalDataModal'
import { TabState } from '../../../../../../store/genericTabTypes'
import { consoleService } from '../../../../../../services/console.service'
import { setDatosEdicionBackup } from '../../store/configClientesReducer'
import { EFormasPago } from '../../../../../../store/enum/formasPago'
import {ECountry} from "../../../../../../store/enum/countries";

interface IClienteProps {
  info: DocumentInfo<ClientesListado>
  tabId: string
  tab: TabState<ClientesListado>
  templateMode?: boolean
}



export const generosGrupos = [
  { value: 0, label: 'M' },
  { value: 1, label: 'F' },
  { value: -1, label: 'N/A' },
]

export const realacionadoGrupos = [
  { value: 0, label: 'SI' },
  { value: 1, label: 'NO' },
]


const Cliente: React.FunctionComponent<IClienteProps> = (props) => {
  consoleService.log("Cliente")
  consoleService.log("Cliente props:", props)
  const { tabId, info, tab } = props
  const urls = UrlHelper.getUrls()

  const dispatch = useDispatch()
  const usuario = useSelector((state: RootState) => { return state.global.session?.usuario });
  const empresa = useSelector((state: RootState) => { return state.global.session?.empresa });
  const local = useSelector((state: RootState) => { return state.global.session?.local });

  const datosEdicion = useSelector((state: RootState) => { return state.clientes.clientes.editData[tabId] })
  const datosEdicionBackup = useSelector((state: RootState) => { return state.clientes.clientes.configCliente[tabId] })
  const edicionLoaderState = useSelector((state: RootState) => { return state.clientes.clientes.editData[tabId]?.loader })
  const edicionContribuyenteState = useSelector((state: RootState) => { return state.clientes.clientes.editData[tabId]?.tabs?.contribuyente })
  const edicionGeneralesState = useSelector((state: RootState) => { return state.clientes.clientes.editData[tabId]?.tabs?.generales })
  const edicionSucursalesState = useSelector((state: RootState) => { return state.clientes.clientes.editData[tabId]?.tabs?.sucursales })
  const edicionVentasState = useSelector((state: RootState) => { return state.clientes.clientes.editData[tabId]?.tabs?.ventas })
  const edicionHistoricosState = useSelector((state: RootState) => { return state.clientes.clientes.editData[tabId]?.tabs?.historico })
  const edicionAdicionalesState = useSelector((state: RootState) => { return state.clientes.clientes.editData[tabId]?.tabs?.adicionales })
  const edicionRedState = useSelector((state: RootState) => { return state.clientes.clientes.editData[tabId]?.tabs?.red })
  const edicionLineasState = useSelector((state: RootState) => { return state.clientes.clientes.editData[tabId]?.tabs?.lineas })
  const edicionMarcasState = useSelector((state: RootState) => { return state.clientes.clientes.editData[tabId]?.tabs?.marcas })
  const edicionGruposState = useSelector((state: RootState) => { return state.clientes.clientes.editData[tabId]?.tabs?.grupos })
  const edicionUafeState = useSelector((state: RootState) => { return state.clientes.clientes.editData[tabId]?.tabs?.uafe })

  const loading = useSelector((state: RootState) => { return state.clientes.clientes.editData[tabId].loading })

  const validationGroupRef = React.useRef<any>()
  const [error, setError] = React.useState<{ errors: Array<any>, show: boolean }>({ errors: [], show: false });
  const [verPdf, setVerPdf] = React.useState<{ design: boolean }>();

  const getVerificaPath = React.useCallback(async () => {
    if (urls.origin.includes('dev.acatha.com') || urls.origin.includes('localhost')) {
      // setHabilitarDisenador(true)
    }
  }, [urls])

  const setToast = React.useCallback(
    (texto, type: ToastTypes) => {
      let time: number = 3000
      time = texto.length < 50 ? 5000 : 10500
      dispatch(
        addToast({
          id: '',
          autoHide: time,
          title: 'Acatha',
          content: texto,
          fade: true,
          type: type,
        }),
      )
    }, [dispatch])



  const onFormasPagoNuevo = React.useCallback((formasPago: Array<FormaPagoModuloVentas>): Array<TFormasPago> => {
    const formas: Array<TFormasPago> = []
    for (const item of formasPago) {
      if (item.descripcion != 'Formas de Pago' && item.codigo != EFormasPago.otros) {
        const i: TFormasPago = {
          codigoFormaPago: item.codigo,
          codigoCuenta: 0, //PLA_CODIGO codigo de la cuenta
          descripcionFormaPago: item.descripcion, //ASG_DESCRIPCION Nombre forma pago
          transaccionAsignacion: item.codigo, //ASG_TRANSACCION codigo forma pago
          itemAsignacion: 0, //CODIGO_CLI codigo cliente
          cuentaNumero: '', //PLA_NUMERO numero del paln de cuentas
          cuentaDescripcion: '', //PLA_DESCRIPCION descripcion de la cuenta
        }
        formas.push(i)
      }
    }
    return formas
  }, [])

  const verificaFormasPagoAsignadas = React.useCallback(
    (formasPago: Array<TFormasPago>, asignadas: Array<AsignacionesFormasClientes>): Array<TFormasPago> => {
      for (const item of asignadas) {
        const selectedIndex = formasPago.findIndex((forma) => {
          return forma.transaccionAsignacion === Number(item.transaccion)
        })
        formasPago[selectedIndex].codigoCuenta = item.codigo
        formasPago[selectedIndex].itemAsignacion = item.item
        formasPago[selectedIndex].cuentaNumero = item.numeroPlanCuentas
        formasPago[selectedIndex].cuentaDescripcion = item.descripcion
      }
      return formasPago
    },
    [],
  )

  const getAsignaciones = React.useCallback(
    async (codigoCliente: number, mode: boolean) => {
      //mode: true->Editar
      try {
        const fPago = await ClientesService.getFormasPago('V')
        const fPagoParse = onFormasPagoNuevo(fPago)
        if (mode) {
          const asignacionesFPagoClientes = await ClientesService.getAsignacionesClientes(codigoCliente)
          const formasAsignadas = verificaFormasPagoAsignadas(fPagoParse, asignacionesFPagoClientes)
          return formasAsignadas
        } else {
          return fPagoParse
        }
      } catch (error) {
        return false
      }
    },
    [onFormasPagoNuevo, verificaFormasPagoAsignadas],
  )

  const onRetencionIvaParse = React.useCallback((RetencionesIva: any): Array<TRetencionesIva> => {
    const RetIva: Array<TRetencionesIva> = []
    if (RetencionesIva.length === 0) return RetIva
    for (const item of RetencionesIva) {
      const i: TRetencionesIva = {
        codigo: item.codigo,
        tipo: item.tipo,
        id: item.id,
        impuesto: item.impuesto,
        porcentaje: item.porcentajeRetencion,
        descripcion: item.descripcionRetencion,
        retencion: item.retencion,
      }
      RetIva.push(i)
    }
    return RetIva
  }, [])

  const getRetencionesIva = React.useCallback(
    async (codigoCliente: number) => {
      try {
        const iva = await ClientesService.getRetenciones(codigoCliente, 55, 65)
        const ivaParse = onRetencionIvaParse(iva.auto)
        const renta = await ClientesService.getRetenciones(codigoCliente, 55, 66)
        const rentaParse = onRetencionIvaParse(renta.auto)
        return {
          iva: ivaParse,
          renta: rentaParse,
        }
      } catch (error) {
        return false
      }
    }, [onRetencionIvaParse])




  const modoNuevo = React.useCallback(async (template: number, limpiar: boolean = false) => {
    if (loading === false && !limpiar) {
      return
    }

    dispatch(
      updateLoader({
        key: tabId,
        loader: { mensaje: 'Cargando', show: true },
      }),
    )
    const data = { ...defaultDatosEdicionCliente };

    try {

      dispatch(
        setButtons({
          tabKey: tabId,
          buttons: {
            ...ModuleButtons,
            Editar: false,
            Imprimir: false,
            Exportar: false,
            Autorizar: false,
            Enviar: false,
            Guardar: true,
          },
        }),
      )

      dispatch(setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: ButtonTypes.new
      }))

      // inicializa datos generales
      const datosGeneralesUpdate: DatosEdicionDatosGenerales = defaultDatosEdicionDatosGenerales;
      const datosSucursalesUpdate: DatosEdicionSucursal = defaultDatosEdicionSucursalCliente;
      const datosVentasUpdate: DatosEdicionVentasCliente = defaultDatosEdicionVentaslCliente;
      const datosHistoricoUpdate: DatosEdicionHistoricosCliente = defaultDatosEdicionHistoricosCliente;
      const datosAdicionalesUpdate: DatosEdicionAdicionales = defaultDatosEdicionAdicionales;
      const datosRedUpdate: DatosEdicionRed = defaultDatosEdicionRed;
      const datosLineasUpdate: DatosEdicionLineas = defaultDatosEdicionLineas;
      const datosGruposUpdate: DatosEdicionGrupos = defaultDatosEdicionGrupos;
      const datosMarcasUpdate: DatosEdicionMarcas = defaultDatosEdicionMarcas;
      const datosUafeUpdate: DatosEdicionUafe = defaultDatosEdicionUafe;

      const formasPago = await getFormasPago('V', 'Formas dePago');
      const optionZonas = await getZonas();
      const optionTiposIdetificacion = await getTipoIdetificacion();
      const optionTiposAgentes = await getTiposAgente();
      const optionTiposClientes = await getTiposClientes();
      const optionEstados = await getEstados();
      const optionImpresiones = await getImpresiones("");
      const optionOrigen = await getOrigen("");


      console.log("formasPago: ", formasPago)
      // data.ventas.formasPago = formasPago !== false ? formasPago : []
      const tab: any = {}

      if (template > 0) {
        const optionTelefonos = await getDetalleContactos(data.codigoCliente);

        if (optionTelefonos.length > 0) {
          datosSucursalesUpdate.telefono = optionTelefonos[0];
          datosSucursalesUpdate.telefonos = optionTelefonos;
        }

      } else {

        // datos por defecto del tab datos generales
        datosGeneralesUpdate.genero = generosGrupos[0]
        datosGeneralesUpdate.parteRelacionada = realacionadoGrupos[1]

        if (optionTiposIdetificacion.length > 0) {
          datosGeneralesUpdate.tipoIdentificacion = optionTiposIdetificacion[0];
          datosGeneralesUpdate.tiposIdentificacion = optionTiposIdetificacion;

          if (empresa.codigoPais === ECountry.ElSalvador) {
            const findIndexTipo = optionTiposIdetificacion.find((item) => {
              return String(item?.descripcion).toLocaleUpperCase().includes('NIT');
            })

            if (findIndexTipo) {
              datosGeneralesUpdate.tipoIdentificacion = findIndexTipo;
            }
          }
        }
        if (optionZonas.length > 0) {
          datosGeneralesUpdate.ruta = optionZonas[0];
          datosGeneralesUpdate.rutas = optionZonas;
        }
        if (optionTiposAgentes.length > 0) {
          datosGeneralesUpdate.tipoAgente = optionTiposAgentes[0];
          datosGeneralesUpdate.tiposAgente = optionTiposAgentes;
        }
        if (optionTiposClientes.length > 0) {
          datosGeneralesUpdate.tipoCliente = optionTiposClientes[0];
          datosGeneralesUpdate.tiposCliente = optionTiposClientes;
        }
        if (optionEstados.length > 0) {
          datosGeneralesUpdate.estado = optionEstados[1];
          datosGeneralesUpdate.estados = optionEstados;
        }
        if (optionImpresiones.length > 0) {
          datosVentasUpdate.impresion = optionImpresiones[1];
          datosVentasUpdate.impresiones = optionImpresiones;
        }
        if (optionOrigen.length > 0) {
          datosAdicionalesUpdate.origen = optionOrigen[1];
          datosAdicionalesUpdate.origenes = optionOrigen;
        }


        tab.contribuyente = {
          ...defaultDatosEdicionDatosContribuyente
        }

        tab.generales = {
          ...datosGeneralesUpdate
        }

        tab.sucursales = {
          ...datosSucursalesUpdate,
        }

        // datos por defecto del tab sucursales

        if (optionZonas.length > 0) {
          datosSucursalesUpdate.zona = optionZonas[0];
          datosSucursalesUpdate.zonas = optionZonas;
        }


        // datos por defecto del tab ventas

        if (formasPago.length > 0) {
          datosVentasUpdate.formasPago = formasPago;
        }

        tab.ventas = {
          ...datosVentasUpdate
        }

        // datos por defecto del tab historico

        tab.historico = {
          ...datosHistoricoUpdate
        }


        // datos por defecto del tab adicionales

        tab.adicionales = {
          ...datosAdicionalesUpdate
        }


        // datos por defecto del tab red

        tab.red = {
          ...datosRedUpdate
        }

        // datos por defecto del tab lineas

        tab.lineas = {
          ...datosLineasUpdate
        }

        // datos por defecto del tab grupos

        tab.grupos = {
          ...datosGruposUpdate
        }

        // datos por defecto del tab marcas

        tab.marcas = {
          ...datosMarcasUpdate
        }

        // datos por defecto del tab uafe

        tab.uafe = {
          ...datosUafeUpdate
        }

        data.tabs = tab

        data.desdePlantilla = false;

        data.loading = false;
        data.loader = {
          mensaje: '',
          show: false
        }

      }



      dispatch(
        updateLoader({
          key: tabId,
          loader: { mensaje: '', show: false },
        }),
      )

      dispatch(setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: undefined
      }))


    } catch (error) {
      console.error(error)
      data.tieneError = true;
      data.mensajeError = error;
      data.loading = false;

      dispatch(addToast({
        content: data.mensajeError,
        type: ToastTypes.Danger
      }))

      dispatch(setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: undefined
      }))

    }

    dispatch(setDatosEdicion({
      data: data,
      key: tabId,
    }))

    dispatch(setDatosEdicionBackup({
      data: data,
      key: tabId,
    }))



  },
    [loading, dispatch, tabId],
  )

  const modoEdicion = React.useCallback(async (cliente) => {
    dispatch(
      updateLoader({
        key: tabId,
        loader: { mensaje: 'Cargando', show: true },
      }),
    )
    const find: { [key: string]: number } = {}
    find['clienteCodigo'] = Number(cliente.codigo)
    try {

      dispatch(setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: ButtonTypes.edit
      }))

      const clienteData: DetailGetCustomer = await ClientesService.getCustomer(find);
      const clienteContactos = await ClientesService.getDetailsCustomer(clienteData?.codigo);
      const clienteSucursales = await ClientesService.getSucursales(clienteData?.codigo);
      const ventasRetenciones: any = await getRetencionesIva(clienteData?.codigo);

      const ventasFormasPago = await getAsignaciones(clienteData?.codigo, true);
      const lineasCliente: any = await ClientesService.getLineasCliente(clienteData?.codigo);
      const gruposCliente: any = await ClientesService.getGruposCliente(clienteData?.codigo);
      const marcasCliente: any = await ClientesService.getMarcasCliente(clienteData?.codigo);

      const dataCliente: ClientesDatosEdicion = {
        ...defaultDatosEdicionCliente,
      }

      const datosContribuyenteUpdate: DatosEdicionContribuyente = { ...defaultDatosEdicionDatosContribuyente };
      const datosGeneralesUpdate: DatosEdicionDatosGenerales = { ...defaultDatosEdicionDatosGenerales };
      const datosSucursalesUpdate: DatosEdicionSucursal = { ...defaultDatosEdicionSucursalCliente };
      const datosVentasUpdate: DatosEdicionVentasCliente = { ...defaultDatosEdicionVentaslCliente };
      const datosHistoricoUpdate: DatosEdicionHistoricosCliente = { ...defaultDatosEdicionHistoricosCliente };
      const datosAdicionalesUpdate: DatosEdicionAdicionales = { ...defaultDatosEdicionAdicionales };
      const datosRedUpdate: DatosEdicionRed = { ...defaultDatosEdicionRed };
      const datosLineasUpdate: DatosEdicionLineas = { ...defaultDatosEdicionLineas };
      const datosGruposUpdate: DatosEdicionGrupos = { ...defaultDatosEdicionGrupos };
      const datosMarcasUpdate: DatosEdicionMarcas = { ...defaultDatosEdicionMarcas };
      const datosUafeUpdate: DatosEdicionUafe = { ...defaultDatosEdicionUafe };

      // set data tab contribuyente
      datosContribuyenteUpdate.nombre = clienteData?.nombre ?? '';
      datosContribuyenteUpdate.razonComercial = clienteData?.rcomercial ?? '';
      datosContribuyenteUpdate.codigo = clienteData?.codigo ?? 0;
      console.log('contribuyent--------------------')
      console.log('datosContribuyenteUpdate:', datosContribuyenteUpdate)

      // set data tab datos generales
      const optionTiposIdetificacion = await getTipoIdetificacion();
      if (optionTiposIdetificacion.length > 0) {
        datosGeneralesUpdate.tipoIdentificacion = optionTiposIdetificacion.find(x => x.codigo === clienteData?.tipoIdentificacion) ?? optionTiposIdetificacion[0];
        datosGeneralesUpdate.tiposIdentificacion = optionTiposIdetificacion;
      }
      datosGeneralesUpdate.identificacion = clienteData?.identificacion ?? '';
      datosGeneralesUpdate.genero = generosGrupos.find(x => x.value === clienteData?.genero) ?? generosGrupos[0];
      datosGeneralesUpdate.discapacidad = clienteData?.discapacidad === 1 ? true : false;
      datosGeneralesUpdate.direccion = clienteData?.direccion ?? '';
      datosGeneralesUpdate.referenciaDireccion = clienteData?.referencia ?? '';
      datosGeneralesUpdate.email = clienteData?.email ?? '';
      if (clienteContactos.length > 0) {
        let contactos: Array<TelefonoCliente> = [];
        contactos = clienteContactos.map((x) => {
          return {
            descripcion: x?.descripcion ?? '',
            numero: x?.numero ?? '',
            tipo: x?.tipoDescripcion ?? '',
            principal: x?.principal ?? 0
          }
        })
        datosGeneralesUpdate.telefonos = contactos ?? [];

        let contactosSucursales: Array<TDetalleContactosSucursales> = [];
        contactosSucursales = clienteContactos.map((x) => {
          return {
            codigo: x?.codigo,
            descripcion: x?.descripcion ?? '',
            numero: x?.numero ?? '',
            tipo: x?.tipoDescripcion ?? '',
            tipoDescripcion: x?.tipoDescripcion ?? '',
            estado: x?.estado ?? 0,
            asociadoCodigo: x?.asociadoCodigo ?? 0,
            asociadoTipo: x?.asociadoTipo ?? 0,
            principal: x?.principal ?? 0
          }
        })

        datosSucursalesUpdate.telefonos = contactosSucursales;
        datosSucursalesUpdate.telefono = contactosSucursales[0];
      }
      const optionCiudades = await getCiudades(-1);
      if (optionCiudades.length > 0) {
        datosGeneralesUpdate.ciudad = optionCiudades.find(x => x.codigo === clienteData?.ubicacion) ?? optionCiudades[0];
      }
      datosGeneralesUpdate.codigoParroquia = clienteData?.parroquia ?? '';

      const optionZonas = await getZonas();
      if (optionZonas.length > 0) {
        datosGeneralesUpdate.ruta = optionZonas.find(x => x.codigo === clienteData?.zonCodigo) ?? optionZonas[0];
        datosGeneralesUpdate.rutas = optionZonas;
      }

      const optionTiposAgentes = await getTiposAgente();
      if (optionTiposAgentes.length > 0) {
        datosGeneralesUpdate.tipoAgente = optionTiposAgentes.find(x => x.codigo === clienteData?.codigoTipoAgente) ?? optionTiposAgentes[0];
        datosGeneralesUpdate.tiposAgente = optionTiposAgentes;
      }

      const optionTiposClientes = await getTiposClientes();
      if (optionTiposClientes.length > 0) {
        datosGeneralesUpdate.tipoCliente = optionTiposClientes.find(x => x.codigo === clienteData?.codigoTipoCliente) ?? optionTiposClientes[0];
        datosGeneralesUpdate.tiposCliente = optionTiposClientes;
      }

      const optionEstados = await getEstados();
      if (optionEstados.length > 0) {
        datosGeneralesUpdate.estado = optionEstados.find(x => x.codigo === clienteData?.estado) ?? optionEstados[0];
        datosGeneralesUpdate.estados = optionEstados;
      }
      datosGeneralesUpdate.contribuyente = clienteData?.contribuyente ?? '';
      datosGeneralesUpdate.parteRelacionada = realacionadoGrupos.find(x => x.value === clienteData?.relacionada) ?? realacionadoGrupos[0];
      datosGeneralesUpdate.numeroIntegrantesFamilia = clienteData?.integrantes ?? 0;
      console.log('general--------------------')
      console.log('datosGeneralesUpdate:', datosGeneralesUpdate)

      // set data tab datos sucursales
      if (clienteSucursales.length > 0) {
        datosSucursalesUpdate.sucursales = clienteSucursales.map((x) => {
          return {
            codigo: x.codigo,
            clienteCodigo: x.codigoCliente,
            direccion: x.direccion,
            referencia: x.referencia,
            email: x.email,
            contacto: x.telefonoNumero,
            codContacto: x.telefonoCodigo,
            uuid: uuidv4(),
            ciudadCodigo: x.codigoCiudad,
            ciudadDescripcion: x.ciudadNombre,
            zonaCodigo: x.codigoZona,
            zonaDescripcion: x.zonaDescripcion
          }
        })
      }
      if (optionCiudades.length > 0) {
        datosSucursalesUpdate.ciudad = optionCiudades[0];
      }
      if (optionZonas.length > 0) {
        datosSucursalesUpdate.zona = optionZonas.find(x => x.codigo === clienteData?.zonCodigo) ?? optionZonas[0];
        datosSucursalesUpdate.zonas = optionZonas;
      }
      console.log('surcursales--------------------')
      console.log('datosSucursalesUpdate:', datosSucursalesUpdate)

      // set data tab datos ventas
      datosVentasUpdate.deshabilitarCredito = clienteData?.deshabilitarCredito === 1 ? true : false
      datosVentasUpdate.diasCredito = clienteData?.diasCredito ?? 0
      datosVentasUpdate.revisionPago = clienteData?.revisionPago ?? ''
      datosVentasUpdate.limiteCredito = clienteData?.limiteCredito ?? 0
      datosVentasUpdate.saldo = clienteData?.saldo ?? 0
      datosVentasUpdate.atencionVentas = clienteData?.ateVentas ?? ''
      datosVentasUpdate.porcentajeDescuento = clienteData?.descuento ?? 0
      datosVentasUpdate.atencionCobranza = clienteData?.ateCobranza ?? ''
      datosVentasUpdate.necesitaReferencias = clienteData?.referencias === 1 ? true : false ?? false
      if (clienteData?.vendedor > 0) {
        datosVentasUpdate.vendedor = await VendedoresService.getVendedorByCode(clienteData?.vendedor);
      }
      if (ventasRetenciones?.renta && ventasRetenciones?.renta.length > 0) {
        datosVentasUpdate.retenciones = ventasRetenciones?.renta.map((x) => {
          return {
            codigo: parseInt(x.codigo) ?? 0,
            tipo: x.tipo ?? '',
            id: parseInt(x.id) ?? 0,
            impuesto: parseInt(x.impuesto) ?? 0,
            porcentaje: parseInt(x.porcentaje) ?? 0,
            descripcion: x.descripcion ?? '',
            retencion: parseInt(x.retencion) ?? 0,
          }
        })
      }
      if (ventasRetenciones?.iva && ventasRetenciones?.iva.length > 0) {
        datosVentasUpdate.iva = ventasRetenciones?.iva.map((x) => {
          return {
            codigo: parseInt(x.codigo) ?? 0,
            tipo: x.tipo ?? '',
            id: parseInt(x.id) ?? 0,
            impuesto: parseInt(x.impuesto) ?? 0,
            porcentaje: parseInt(x.porcentaje) ?? 0,
            descripcion: x.descripcion ?? '',
            retencion: parseInt(x.retencion) ?? 0,
          }
        })
      }

      if (ventasFormasPago && ventasFormasPago.length > 0) {
        datosVentasUpdate.formasPago = ventasFormasPago.map((x) => {
          return {
            codigoFormaPago: x?.codigoFormaPago ?? 0,
            descripcionFormaPago: x?.descripcionFormaPago ?? '',
            codigoCuenta: x?.codigoCuenta ?? 0,
            transaccionAsignacion: x?.transaccionAsignacion ?? 0,
            itemAsignacion: x?.itemAsignacion ?? 0,
            cuentaNumero: x?.cuentaNumero ?? '',
            cuentaDescripcion: x?.cuentaDescripcion ?? '',
          }
        })
      }

      console.log('venta--------------------')
      console.log('datosVentasUpdate:', datosVentasUpdate)

      // set data tab datos ventas
      datosHistoricoUpdate.limiteCreditoFecha = DateUtils.getCurrentDateAsString();
      datosHistoricoUpdate.ventasAnuales = Number(clienteData?.vAnuales) ?? 0
      let fechaUltimoPago = null;
      if (clienteData?.fechaUltimoPago === '') {
        fechaUltimoPago = null;
      } else if (clienteData?.fechaUltimoPago !== "00/00/0000") {
        fechaUltimoPago = DateUtils.apiDateToPickersDate(clienteData?.fechaUltimoPago);
      }
      datosHistoricoUpdate.ultimoPago = {
        fecha: fechaUltimoPago,
        documento: clienteData?.diaUltimoPago ?? '',
        monto: Number(clienteData?.montoPago) ?? 0
      }
      let fechaUltimaVenta = '';
      if (clienteData?.fechaUltimaVenta === '') {
        fechaUltimaVenta = null;
      } else {
        fechaUltimaVenta = DateUtils.apiDateToPickersDate(clienteData?.fechaUltimaVenta);
      }
      datosHistoricoUpdate.ultimaVenta = {
        fecha: fechaUltimaVenta,
        documento: clienteData?.diaUltimaVenta ?? '',
        monto: Number(clienteData?.montoVenta) ?? 0
      }
      consoleService.log('historico--------------------')
      consoleService.log('datosHistoricoUpdate:', datosHistoricoUpdate)

      datosAdicionalesUpdate.observaciones = clienteData?.observaciones ?? ''
      let fechaNacimiento = null;
      if (clienteData?.fechaNacimiento !== '') {
        fechaNacimiento = DateUtils.apiDateToPickersDate(clienteData?.fechaNacimiento) ?? '';
      }
      datosAdicionalesUpdate.fechaNacimiento = fechaNacimiento;

      const optionEstadosCivil = await getEstadosCivil();
      if (optionEstadosCivil.length > 0) {
        datosAdicionalesUpdate.estadoCivil = optionEstadosCivil.find(x => x.codigo === clienteData?.estadoCivil) ?? optionEstadosCivil[0];
        datosAdicionalesUpdate.estadosCivil = optionEstadosCivil;
      }

      const optionClasificacion = await getClasificacion();
      if (optionClasificacion.length > 0) {
        datosAdicionalesUpdate.clasificacion = optionClasificacion.find(x => x.codigo === clienteData?.clasificacion) ?? optionClasificacion[0];
        datosAdicionalesUpdate.clasificaciones = optionClasificacion;
      }

      const optionEstudios = await getEstudios();
      if (optionEstudios.length > 0) {
        datosAdicionalesUpdate.estudios = optionEstudios.find(x => x.codigo === clienteData?.estudios) ?? optionEstudios[0];
        datosAdicionalesUpdate.estudiosOptions = optionEstudios;
      }
      datosAdicionalesUpdate.profesion = clienteData?.profesion ?? '';
      datosAdicionalesUpdate.sitioWeb = clienteData?.web ?? '';
      datosAdicionalesUpdate.beca = clienteData?.becado === 1 ? true : false;
      datosAdicionalesUpdate.lugarTrabajo = clienteData?.empresa ?? '';

      const optionDpSituacionLaboral = await getDpSituacionLaboral();
      if (optionDpSituacionLaboral.length > 0) {
        datosAdicionalesUpdate.situacionLaboral = optionDpSituacionLaboral.find(x => x.codigo === clienteData?.laboral) ?? optionDpSituacionLaboral[0];
        datosAdicionalesUpdate.situacionLaboralDp = optionDpSituacionLaboral;
      }

      const optionDpRegismenEmpresas = await getDpRegimenEmpresa();
      if (optionDpRegismenEmpresas.length > 0) {
        datosAdicionalesUpdate.tipoEmpresa = optionDpRegismenEmpresas.find(x => x.codigo === clienteData?.tipoEmpresa) ?? optionDpRegismenEmpresas[0];
        datosAdicionalesUpdate.tipoEmpresaDp = optionDpRegismenEmpresas;
      }


      const optionDpDesempenios = await getDpDesempenios();
      if (optionDpDesempenios.length > 0) {
        datosAdicionalesUpdate.desempeno = optionDpDesempenios.find(x => x.codigo === clienteData?.desempeno) ?? optionDpDesempenios[0];
        datosAdicionalesUpdate.desempenoDp = optionDpDesempenios;
      }

      const optionDpOrigen = await getDpOrigen();
      if (optionDpOrigen.length > 0) {
        datosAdicionalesUpdate.origen = optionDpOrigen.find(x => x.opcion === clienteData?.origen) ?? optionDpOrigen[0];
        datosAdicionalesUpdate.origenes = optionDpOrigen;
      }

      consoleService.log('adicionales--------------------')
      consoleService.log('datosAdicionalesUpdate:', datosAdicionalesUpdate)

      datosRedUpdate.nodo = clienteData?.nodo ?? '';
      datosRedUpdate.base = clienteData?.base ?? '';
      datosRedUpdate.dhcp = dhcpGruposDHCP.find(x => x.value === clienteData?.dhcp) ?? dhcpGruposDHCP[0];
      datosRedUpdate.servicio = clienteData?.servicio ?? '';
      datosRedUpdate.contrasena = clienteData?.password ?? '';
      datosRedUpdate.registrado = registadoGrupos.find(x => x.value === parseInt(clienteData?.registrado)) ?? registadoGrupos[0];

      const latitud: Array<string> = String(clienteData?.latitud).split("|");
      if (latitud.length > 0) {
        datosRedUpdate.norteSur = nsGrupos.find(x => x.value === latitud[0]) ?? nsGrupos[0];
        datosRedUpdate.degns = latitud[1] ?? '';
        datosRedUpdate.minns = latitud[2] ?? '';
        datosRedUpdate.secns = latitud[3] ?? '';
      }

      const longitud: Array<string> = String(clienteData?.longitud).split("|");
      if (longitud.length > 0) {
        datosRedUpdate.esteOeste = eoGrupos.find(x => x.value === latitud[0]) ?? eoGrupos[0];
        datosRedUpdate.degeo = longitud[1] ?? '';
        datosRedUpdate.mineo = longitud[2] ?? '';
        datosRedUpdate.seceo = longitud[3] ?? '';
      }
      datosRedUpdate.altitud = clienteData?.altitud ?? '';

      consoleService.log('red--------------------')
      consoleService.log('datosRedUpdate:', datosRedUpdate)

      datosLineasUpdate.lineas = lineasCliente.map((x) => {
        return {
          codigo: parseInt(x?.codigo) ?? 0,
          descripcion: x?.particular ?? '',
        }
      }) ?? []

      consoleService.log('lineas--------------------')
      consoleService.log('datosLineasUpdate:', datosLineasUpdate)

      datosGruposUpdate.grupos = gruposCliente.map((x) => {
        return {
          codigo: parseInt(x?.codigoGrupo) ?? 0,
          descripcion: x?.descripcion ?? '',
        }
      }) ?? []

      consoleService.log('grupos--------------------')
      consoleService.log('datosGruposUpdate:', datosGruposUpdate)

      datosMarcasUpdate.marcas = marcasCliente.map((x) => {
        return {
          codigo: parseInt(x?.codigoMarca) ?? 0,
          nombre: x?.nombre ?? '',
        }
      }) ?? []

      consoleService.log('marcas--------------------')
      consoleService.log('datosMarcasUpdate:', datosMarcasUpdate)
      consoleService.log('zonaGeografica:', clienteData?.zonaGeografica)
      consoleService.log('politica:', clienteData?.politica)
      consoleService.log('profesionUAF:', clienteData?.profesionUAF)
      consoleService.log('tipoCliente:', clienteData?.tipoCliente)
      consoleService.log('accionaria:', clienteData?.accionaria)
      consoleService.log('estructura:', clienteData?.estructura)

      datosUafeUpdate.zonaGeografica = zonasGeograficas.find(x => x.codigo === clienteData?.zonaGeografica) ?? zonasGeograficas[0];
      datosUafeUpdate.zonaGeograficaDp = zonasGeograficas ?? []
      datosUafeUpdate.actividadEconomica = actividadEconomica.find(x => x.codigo === clienteData?.actividadDeco) ?? actividadEconomica[0];
      datosUafeUpdate.actividadEconomicaDp = actividadEconomica ?? []
      datosUafeUpdate.personaPolitica = personasPoliticamenteExp.find(x => x.codigo === clienteData?.politica) ?? personasPoliticamenteExp[0];
      datosUafeUpdate.personaPoliticaDp = personasPoliticamenteExp ?? []
      datosUafeUpdate.profesiones = profesiones.find(x => x.codigo === clienteData?.profesionUAF) ?? profesiones[0];
      datosUafeUpdate.profesionesDp = profesiones ?? []
      datosUafeUpdate.tipoCliente = tipoCliente.find(x => x.codigo === clienteData?.tipoCliente) ?? tipoCliente[0];
      datosUafeUpdate.tipoClienteDp = tipoCliente ?? []
      datosUafeUpdate.concentracionAccionaria = concentracionAccionaria.find(x => x.codigo === clienteData?.accionaria) ?? concentracionAccionaria[0];
      datosUafeUpdate.concentracionAccionariaDp = concentracionAccionaria ?? []
      datosUafeUpdate.extranjero = clienteData?.estructura === 1 ? true : false
      // datosUafeUpdate.extranjero =  clienteData?.ex


      consoleService.log('marcas--------------------')
      consoleService.log('datosUafeUpdate:', datosUafeUpdate)

      const tab: TabClienteState = {
        contribuyente: datosContribuyenteUpdate,
        generales: datosGeneralesUpdate,
        sucursales: datosSucursalesUpdate,
        ventas: datosVentasUpdate,
        historico: datosHistoricoUpdate,
        adicionales: datosAdicionalesUpdate,
        red: datosRedUpdate,
        lineas: datosLineasUpdate,
        grupos: datosGruposUpdate,
        marcas: datosMarcasUpdate,
        uafe: datosUafeUpdate
      }

      dataCliente.tabs = tab
      dataCliente.desdePlantilla = false;
      dataCliente.loading = false;
      dataCliente.loader = {
        mensaje: '',
        show: false
      }

      dispatch(setDatosEdicion({
        key: tabId,
        data: dataCliente
      }));

      dispatch(setDatosEdicionBackup({
        key: tabId,
        data: dataCliente
      }));

      dispatch(setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: undefined
      }))

    } catch (error) {

      dispatch(setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: undefined
      }))

      dispatch(
        addToast({
          content: error.message,
          type: ToastTypes.Danger,
        }),
      )
      //return false
    }
    dispatch(
      updateLoader({
        key: tabId,
        loader: { mensaje: '', show: false },
      }),
    )
  },
    [dispatch, getAsignaciones, getRetencionesIva, tabId],
  )

  const cargarCliente = React.useCallback(async (codigo: number, setInitData: boolean) => {
    modoEdicion({ codigo: codigo })
  }, [modoEdicion]);


  const validarFormulario = React.useCallback(async () => {
    const errors: any = [];
    if (edicionGeneralesState.ciudad === null) {
      errors.push("Ciudad: Debe seleccionar una opción");
    }
    if (edicionGeneralesState.estado === null) {
      errors.push("Estado: Debe seleccionar una opción");
    }
    if (edicionGeneralesState.tipoAgente === null) {
      errors.push("Tipo Agente: Debe seleccionar una opción");
    }
    if (edicionGeneralesState.tipoCliente === null) {
      errors.push("Tipo Cliente: Debe seleccionar una opción");
    }
    if (edicionGeneralesState.tipoIdentificacion === null) {
      errors.push("Tipo Id: Debe seleccionar una opción");
    }
    if (edicionGeneralesState.ruta === null) {
      errors.push("Ruta: Debe seleccionar una opción");
    }
    return errors;
  }, [
    edicionGeneralesState
  ]);

  const guardar = React.useCallback(async () => {
    if (usuario == null || empresa == null || local == null) {
      return;
    }

    dispatch(setCurrentExecutingAction({
      tabKey: tabId,
      buttonType: ButtonTypes.save
    }))

    const validationResult = await validationGroupRef.current.instance.validate()

    if (validationResult.isValid === false) {
      let mensajes: Array<string> = [];
      if (validationResult.brokenRules) {
        mensajes = validationResult.brokenRules.map((x) => {
          return x.message
        })
      }

      dispatch(setMuestraErrores({
        key: tabId,
        tieneError: true,
        mensajesErrors: mensajes ?? []
      }));

      dispatch(addToast({
        content: 'Tiene errores por favor verifíquelos.',
        type: ToastTypes.Danger,
      }))

      dispatch(setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: undefined
      }))
      return false
    } else {

      const resValidacion = await validarFormulario();

      if (resValidacion.length > 0) {
        dispatch(setCurrentExecutingAction({
          tabKey: tabId,
          buttonType: undefined
        }))

        setError({ errors: resValidacion, show: true })

        return false;
      }

      consoleService.log("sucursales:", edicionSucursalesState.sucursales)

      let fechaUltimoPago = "00/00/0000";
      let fechaUltimaVenta = null;
      let fechaNacimiento = null;


      consoleService.log("ultimoPago:", edicionHistoricosState?.ultimoPago?.fecha)
      consoleService.log("ultimaVenta:", edicionHistoricosState?.ultimaVenta?.fecha)
      consoleService.log("fechaNacimiento:", edicionAdicionalesState?.fechaNacimiento)
      if (edicionHistoricosState?.ultimoPago?.fecha !== null) {
        fechaUltimoPago = DateUtils.pickersDateToApiDate(edicionHistoricosState?.ultimoPago?.fecha) ?? '';
      }
      if (edicionHistoricosState?.ultimaVenta?.fecha !== null) {
        fechaUltimaVenta = DateUtils.pickersDateToApiDate(edicionHistoricosState?.ultimaVenta?.fecha) ?? '';
      }
      if (edicionAdicionalesState?.fechaNacimiento !== null || edicionAdicionalesState?.fechaNacimiento !== '') {
        fechaNacimiento = DateUtils.pickersDateToApiDate(edicionAdicionalesState?.fechaNacimiento) ?? '';
      }

      const dpIva = []
      const dpRenta = []

      if (edicionVentasState.iva.length > 0) {
        edicionVentasState.iva.map(c => {
          if (parseInt(c.impuesto) === 66) {
            dpIva.push(c)
          }
        })
      }
      if (edicionVentasState.iva.length > 0) {
        edicionVentasState.iva.map(c => {
          dpRenta.push(c)
        })
      }

      let redLatitud = "";
      let redLongitud = "";
      if (edicionRedState.degns !== '' && edicionRedState.minns !== '' && edicionRedState.secns !== '') {
        redLatitud = `${edicionRedState.degns}|${edicionRedState.minns}|${edicionRedState.secns}`;
      }

      if (edicionRedState.degeo !== '' && edicionRedState.mineo !== '' && edicionRedState.seceo !== '') {
        redLongitud = `${edicionRedState.degeo}|${edicionRedState.mineo}|${edicionRedState.seceo}`;
      }

      const asignacionesFormasPago: Array<AsignacionesFormasPagoCliente> = []
      if (edicionVentasState?.formasPago && edicionVentasState?.formasPago.length > 0) {

        for (const x of edicionVentasState?.formasPago) {
          if (x.codigoCuenta > 0 && x.cuentaDescripcion !== '') {
            asignacionesFormasPago.push({
              codigo: 0,
              item: String(edicionContribuyenteState?.codigo ?? 0),
              tipo: 'CLIENTES',
              planCuentasCodigo: x?.codigoCuenta ?? 0,
              transaccion: String(x?.codigoFormaPago) ?? '',
              transaccionCuenta: x?.descripcionFormaPago.toString() ?? '',
            })
          }
        }

      }


      const cliente: TClienteIngresar = {
        codigo: edicionContribuyenteState?.codigo ?? 0,
        nombre: edicionContribuyenteState?.nombre ?? '',
        tipoIdentificacion: edicionGeneralesState?.tipoIdentificacion?.codigo ?? '-1',
        identificacion: edicionGeneralesState?.identificacion ?? '',
        direccion: edicionGeneralesState?.direccion ?? '',
        referenciaDireccion: edicionGeneralesState?.referenciaDireccion ?? '',
        telefonos: edicionGeneralesState?.telefonos ?? [],
        discapacidad: (edicionGeneralesState?.discapacidad === true ? 1 : 0) ?? 0,
        sexo: edicionGeneralesState?.genero?.value ?? 0,
        email: edicionGeneralesState?.email ?? '',
        ciudadCodigo: edicionGeneralesState?.ciudad?.codigo ?? -1,
        parroquia: edicionGeneralesState?.codigoParroquia ?? '',
        zonaCodigo: edicionGeneralesState?.ruta?.codigo ?? -1,
        codigoTipoAgente: edicionGeneralesState?.tipoAgente?.codigo ?? -1,
        codigoTipoCliente: edicionGeneralesState?.tipoCliente?.codigo ?? -1,
        contribuyente: edicionGeneralesState?.contribuyente ?? '',
        estado: edicionGeneralesState?.estado?.codigo ?? -1,
        parteRelacionada: edicionGeneralesState?.parteRelacionada?.value ?? -1,
        numeroIntegrantesFamilia: edicionGeneralesState?.numeroIntegrantesFamilia ?? 0,

        deshabilitarCredito: (edicionVentasState?.deshabilitarCredito === true ? 1 : 0) ?? 0,
        diasCredito: edicionVentasState?.diasCredito ?? 0,
        revisionPago: edicionVentasState?.revisionPago ?? '',
        limiteCredito: edicionVentasState?.limiteCredito ?? 0,
        saldo: edicionVentasState?.saldo ?? 0,
        atencionVentas: edicionVentasState?.atencionVentas ?? '',
        descuento: edicionVentasState?.porcentajeDescuento ?? 0,
        atencionCobranza: edicionVentasState?.atencionCobranza ?? '',
        necesitaReferencias: (edicionVentasState?.necesitaReferencias === true ? 1 : 0) ?? 0,
        vendedor: edicionVentasState?.vendedor?.vendedorCodigo ?? null,
        impresion: edicionVentasState?.impresion?.archivoImpresion ?? '',

        ventasAnuales: edicionHistoricosState?.ventasAnuales ?? null,
        fechaUltimoPago: fechaUltimoPago,
        documentoUltimoPago: isNaN(parseInt(edicionHistoricosState?.ultimoPago?.documento ?? '0')) ? 0 : parseInt(edicionHistoricosState?.ultimoPago?.documento ?? '0'),
        montoPago: edicionHistoricosState?.ultimoPago?.monto ?? 0,
        fechaUltimaVenta: fechaUltimaVenta,
        documentoUltimaVenta: isNaN(parseInt(edicionHistoricosState?.ultimoPago?.documento ?? '0')) ? 0 : parseInt(edicionHistoricosState?.ultimoPago?.documento ?? '0'),
        montoVenta: edicionHistoricosState?.ultimaVenta?.monto ?? 0,

        observaciones: edicionAdicionalesState?.observaciones ?? '',
        fechaNacimiento: fechaNacimiento,
        estadoCivil: edicionAdicionalesState?.estadoCivil?.codigo ?? -1,
        clasificacion: edicionAdicionalesState?.clasificacion?.codigo ?? -1,
        estudios: edicionAdicionalesState?.estudios?.codigo ?? -1,
        profesion: edicionAdicionalesState?.profesion ?? '',
        situacionLaboral: edicionAdicionalesState?.situacionLaboral?.codigo ?? -1,
        empresa: edicionAdicionalesState?.lugarTrabajo ?? '',
        tipoEmpresa: edicionAdicionalesState?.tipoEmpresa?.codigo ?? 0,
        web: edicionAdicionalesState?.sitioWeb ?? '',
        desempeno: edicionAdicionalesState?.desempeno?.codigo ?? -1,
        origen: edicionAdicionalesState?.origen?.opcion ?? '',
        becado: (edicionAdicionalesState?.beca === true ? 1 : 0) ?? 0,

        razonComercial: edicionContribuyenteState?.razonComercial ?? '',

        nodo: edicionRedState?.nodo ?? '',
        base: edicionRedState?.base ?? '',
        dhcp: edicionRedState?.dhcp?.value ?? '',
        ip: edicionRedState?.ip ?? '',
        servicio: edicionRedState?.servicio ?? '',
        userName: edicionRedState?.usuario ?? '',
        password: edicionRedState?.contrasena ?? '',
        registradoRed: edicionRedState?.registrado?.value ?? 0,
        latitud: redLatitud ?? '',
        longitud: redLongitud ?? '',
        altitud: edicionRedState?.altitud.toString() ?? '',


        zonaGeografica: edicionUafeState?.zonaGeografica?.codigo ?? -1,
        actividadEconomica: edicionUafeState?.actividadEconomica?.codigo ?? -1,
        politica: edicionUafeState?.personaPolitica?.codigo ?? -1,
        profesionUAF: edicionUafeState?.profesiones?.codigo ?? -1,
        tipoCliente: edicionUafeState?.tipoCliente?.codigo ?? -1,
        accionaria: edicionUafeState?.concentracionAccionaria?.codigo ?? -1,
        estructura: (edicionUafeState?.extranjero === true ? 1 : 0) ?? 0,


        locales: [],
        sucursales: edicionSucursalesState?.sucursales.map(c => {
          return {
            codigo: parseInt(c.codigo),
            codigoCliente: parseInt(c.clienteCodigo),
            codigoTelefono: parseInt(c.codigoTelefono),
            codigoCiudad: parseInt(c.ciudadCodigo),
            codigoZona: parseInt(c.zonaCodigo),
            direccion: c.direccion.toString(),
            email: c.email.toString(),
            referencia: c.referencia.toString(),
          }
        }) ?? [],
        asignacionesFormasPago: asignacionesFormasPago ?? [],
        asignacionesIva: {
          tipo: 55,
          clienteCodigo: edicionContribuyenteState.codigo ?? 0,
          impuesto: 66,
          asignaciones: dpIva.map(c => {
            return {
              retencion: parseInt(c.codigo),
            }
          }) ?? []
        },
        asignacionesRenta: {
          tipo: 55,
          clienteCodigo: edicionContribuyenteState.codigo ?? 0,
          impuesto: 65,
          asignaciones: dpRenta.map(c => {
            return {
              retencion: parseInt(c.codigo),
            }
          }) ?? []
        },
        asignacionesLineas: edicionLineasState.lineas.map(c => {
          return {
            codigoLinea: parseInt(c.codigo),
            codigoCliente: edicionContribuyenteState.codigo ?? 0,
          }
        }) ?? [],
        asignacionesMarcas: edicionGruposState.grupos.map(c => {
          return {
            codigoMarca: parseInt(c.codigo),
            codigoCliente: edicionContribuyenteState.codigo ?? 0,
          }
        }) ?? [],
        asignacionesGrupo: edicionMarcasState.marcas.map(c => {
          return {
            codigoGrupo: parseInt(c.codigo),
            codigoCliente: edicionContribuyenteState.codigo ?? 0,
          }
        }) ?? [],
      }

      consoleService.log("===> cliente guardar : ", cliente)
      dispatch(setDatosEdicion({
        data: {
          ...datosEdicion,
          loader: {
            show: true,
            mensaje: 'Guardando...'
          },
        },
        key: tabId
      }));
      try {
        const resultado = await ClientesService.saveCustomer(cliente)
        consoleService.log("resultado: ", resultado)
        if (resultado.error === false) {
          const clienteRegistrado: number = resultado['auto'];
          consoleService.log("datosEdicion: ", datosEdicion)

          dispatch(setDatosEdicion({
            data: {
              ...datosEdicion,
              codigoCliente: clienteRegistrado,
              loader: {
                show: false,
                mensaje: ''
              },
            },
            key: tabId
          }));

          dispatch(changeEditStatus({
            estado: StatesEdition.save,
            tittle: `${edicionGeneralesState?.identificacion ?? ''}-${edicionContribuyenteState?.codigo ?? 0}`,
            tabKey: tabId,
            info: {
              codigo: clienteRegistrado,
              // ...datosEdicion
              ...info?.info
            },
            buttons: {
              ...ModuleButtons,
              Guardar: false,
              Editar: true,
              Deshacer: false,
            }
          }));

          dispatch(addToast({
            id: '',
            autoHide: true,
            content: resultado.message ?? "Registro Satisfactorio",
            fade: true,
            title: 'Guardar',
            type: ToastTypes.Success
          }))

          dispatch(setCurrentExecutingAction({
            tabKey: tabId,
            buttonType: undefined
          }))

        } else {

          dispatch(addToast({
            id: '',
            autoHide: true,
            content: (resultado.message + JSON.stringify(resultado.auto)) ?? "Error al guardar.",
            fade: true,
            title: 'Guardar',
            type: ToastTypes.Danger
          }));

          dispatch(setDatosEdicion({
            data: {
              ...datosEdicion,
              loader: {
                show: false,
                mensaje: ''
              }
            },
            key: tabId
          }));

          dispatch(setCurrentExecutingAction({
            tabKey: tabId,
            buttonType: undefined
          }))
        }

      } catch (error) {
        console.error(error);
        dispatch(addToast({
          id: '',
          autoHide: true,
          content: 'Error al guardar ' + (typeof error === 'string' ? error : JSON.stringify(error)),
          fade: true,
          title: 'Guardar',
          type: ToastTypes.Danger
        }));

        dispatch(setDatosEdicion({
          data: {
            ...datosEdicion,
            loader: {
              show: false,
              mensaje: ''
            }
          },
          key: tabId
        }));

        dispatch(setCurrentExecutingAction({
          tabKey: tabId,
          buttonType: undefined
        }))
        return;
      }
    }
  }, [
    dispatch,
    datosEdicion,
    edicionContribuyenteState,
    edicionGeneralesState,
    edicionSucursalesState,
    edicionVentasState,
    edicionHistoricosState,
    edicionAdicionalesState,
    edicionRedState,
    edicionLineasState,
    edicionMarcasState,
    edicionGruposState,
    edicionUafeState, tabId, validarFormulario,
    empresa, local, usuario, info
  ])

  const deshacer = React.useCallback(() => {
    dispatch(setDatosEdicion({
      data: datosEdicionBackup,
      key: tabId,
    }))
  }, [datosEdicionBackup, dispatch, tabId])

  const handlePrint = React.useCallback((design: boolean) => {
    setVerPdf({ design: design });
  }, [])

  const handleButtonClick = React.useCallback(
    (button: ButtonTypes) => {
      switch (button) {
        case ButtonTypes.edit:
          modoEdicion(info)
          break
        case ButtonTypes.save: {
          guardar()
          break
        }
        case ButtonTypes.undo:
          deshacer()
          break
        case ButtonTypes.print:
          handlePrint(false)
          break
        case ButtonTypes.print_design:
          handlePrint(true)
          break
        case ButtonTypes.export:
          // exportarDetalle();
          break
        case ButtonTypes.sendMail:
          // setShowReenviarMail(true);
          break
        case ButtonTypes.broom:
          modoNuevo(tab.info?.codigo, true);
          break
        default:
          break
      }
      dispatch(clearButtonClick(tabId))
    },
    [tabId, tab, info, modoNuevo, dispatch, deshacer, guardar, handlePrint, modoEdicion])




  React.useEffect(() => {
    if (tab.editStatus === StatesEdition.new) {
      modoNuevo(tab.info?.codigo ?? 0)
    } else {
      modoEdicion(info)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
  }, [handleButtonClick, tab.globalButtonClick])

  React.useEffect(() => {
    getVerificaPath()
  }, [getVerificaPath])

  const generaDetalleErrores = React.useCallback((item: any) => {
    if (item.length > 0) {
      return <CAlert color='danger' id={"alert-" + tabId}>
        <ul className="errorList" id={tabId + "errorList"}>
          {item.map(function (item, id) {
            return <li key={id + "-" + tabId}>{item}</li>
          })}
        </ul></CAlert>
    } else {
      return <></>
    }
  }, [tabId]);

  const onParseData = React.useCallback((cliente: ClientesDatosEdicion) => {
    let level = '';
    let numberPhone = '';
    let cellPhone = '';
    const factorsDescriction: Array<FactorsDescrictionList> = [
      {
        Code: 0,
        Descripcion: 'ZONA GEOGRAFICA',
        Score: 40,
        Reference: '',
        PorcentageRisk: 0,
        ScoreRisk: 0,
        Visible: 'NJ',
      },
      {
        Code: 1,
        Descripcion: 'ACTIVIDAD ECONOMICA',
        Score: 25,
        Reference: '',
        PorcentageRisk: 0,
        ScoreRisk: 0,
        Visible: 'NJ',
      },
      {
        Code: 2,
        Descripcion: 'SUJETO POLITICAMENTE VINCULADO',
        Score: 15,
        Reference: '',
        PorcentageRisk: 0,
        ScoreRisk: 0,
        Visible: 'N',
      },
      {
        Code: 3,
        Descripcion: 'PROFESION',
        Score: 10,
        Reference: '',
        PorcentageRisk: 0,
        ScoreRisk: 0,
        Visible: 'N',
      },
      {
        Code: 4,
        Descripcion: 'TIPO CLIENTE(OCUPACION)',
        Score: 10,
        Reference: '',
        PorcentageRisk: 0,
        ScoreRisk: 0,
        Visible: 'NJ',
      },
      {
        Code: 5,
        Descripcion: 'CONCENTRACION DE ACCIONES',
        Score: 15,
        Reference: '',
        PorcentageRisk: 0,
        ScoreRisk: 0,
        Visible: 'J',
      },
      {
        Code: 6,
        Descripcion: 'ESTRUCTURA EXT',
        Score: 5,
        Reference: '',
        PorcentageRisk: 0,
        ScoreRisk: 0,
        Visible: 'J',
      },
    ];
    const factorsDescrictionObject: Array<FactorsDescrictionList> = [];

    let existeZonaGeografica: number = -1;
    let existeTipoCliente: number = -1;
    let existeConcentracionAcciones: number = -1;
    let existeEstructuraExt: number = -1;
    let existeProfecsion: number = -1;
    let existeSujetoPolitico: number = -1;
    let existeActividadEconomica: number = -1;

    for (let i: number = 0; i < factorsDescriction.length; i++) {
      if (factorsDescriction[i].Descripcion == "ZONA GEOGRAFICA") {
        existeZonaGeografica = i
      }
      if (factorsDescriction[i].Descripcion == "ACTIVIDAD ECONOMICA") {
        existeActividadEconomica = i
      }
      if (factorsDescriction[i].Descripcion == "SUJETO POLITICAMENTE VINCULADO") {
        existeSujetoPolitico = i
      }
      if (factorsDescriction[i].Descripcion == "PROFESION") {
        existeProfecsion = i
      }
      if (factorsDescriction[i].Descripcion == "TIPO CLIENTE(OCUPACION)") {
        existeTipoCliente = i
      }
      if (factorsDescriction[i].Descripcion == "CONCENTRACION DE ACCIONES") {
        existeConcentracionAcciones = i
      }
      if (factorsDescriction[i].Descripcion == "ESTRUCTURA EXT") {
        existeEstructuraExt = i
      }
    }

    // ZONA GEOGRAFICA
    const existeZonaGeograficaObj = {
      ...factorsDescriction[existeZonaGeografica],
      Reference: cliente?.tabs?.uafe?.zonaGeografica?.descripcion,
      PorcentageRisk: cliente?.tabs?.uafe?.zonaGeografica?.porcentaje,
    }
    if (cliente?.tabs?.generales?.tipoAgente?.codigo == 7) {
      existeZonaGeograficaObj.Score = 30
    } else {
      existeZonaGeograficaObj.Score = 25
    }

    if (cliente?.tabs?.generales?.tipoAgente?.codigo == 7) {
      existeZonaGeograficaObj.ScoreRisk = cliente?.tabs?.uafe?.zonaGeografica?.natural * cliente?.tabs?.uafe?.zonaGeografica?.porcentaje / 100;
    } else {
      existeZonaGeograficaObj.ScoreRisk = cliente?.tabs?.uafe?.zonaGeografica?.juridica * cliente?.tabs?.uafe?.zonaGeografica?.porcentaje / 100;
    }
    factorsDescrictionObject.push(existeZonaGeograficaObj);

    // ACTIVIDAD ECONOMICA
    const existeActividadEconomicaObj = {
      ...factorsDescriction[existeActividadEconomica],
      Reference: cliente?.tabs?.uafe?.actividadEconomica?.descripcion,
      PorcentageRisk: cliente?.tabs?.uafe?.actividadEconomica?.porcentaje,
    }

    if (cliente?.tabs?.generales?.tipoAgente?.codigo == 7) {
      existeActividadEconomicaObj.ScoreRisk = cliente?.tabs?.uafe?.actividadEconomica?.natural * cliente?.tabs?.uafe?.actividadEconomica?.porcentaje / 100;
    } else {
      existeActividadEconomicaObj.ScoreRisk = cliente?.tabs?.uafe?.actividadEconomica?.juridica * cliente?.tabs?.uafe?.actividadEconomica?.porcentaje / 100;
    }
    factorsDescrictionObject.push(existeActividadEconomicaObj);

    // SUJETO POLITICAMENTE VINCULADO
    factorsDescrictionObject.push({
      ...factorsDescriction[existeSujetoPolitico],
      Reference: cliente?.tabs?.uafe?.personaPolitica?.descripcion,
      PorcentageRisk: cliente?.tabs?.uafe?.personaPolitica?.porcentaje,
      ScoreRisk: cliente?.tabs?.uafe?.personaPolitica?.natural * cliente?.tabs?.uafe?.personaPolitica?.porcentaje / 100,
    });

    // PROFESION
    factorsDescrictionObject.push({
      ...factorsDescriction[existeProfecsion],
      Reference: cliente?.tabs?.uafe?.profesiones?.descripcion,
      PorcentageRisk: cliente?.tabs?.uafe?.profesiones?.porcentaje,
      ScoreRisk: cliente?.tabs?.uafe?.profesiones?.natural * cliente?.tabs?.uafe?.profesiones?.porcentaje / 100,
    });

    // TIPO CLIENTE(OCUPACION)
    const existeTipoClienteObj = {
      ...factorsDescriction[existeTipoCliente],
      Reference: cliente?.tabs?.uafe?.tipoCliente?.descripcion,
      PorcentageRisk: cliente?.tabs?.uafe?.tipoCliente?.porcentaje,
    }
    if (cliente?.tabs?.generales?.tipoAgente?.codigo == 7) {
      existeTipoClienteObj.ScoreRisk = cliente?.tabs?.uafe?.tipoCliente?.natural * cliente?.tabs?.uafe?.tipoCliente?.porcentaje / 100;
    } else {
      existeTipoClienteObj.ScoreRisk = cliente?.tabs?.uafe?.tipoCliente?.juridica * cliente?.tabs?.uafe?.tipoCliente?.porcentaje / 100;
    }
    factorsDescrictionObject.push(existeTipoClienteObj);

    // CONCENTRACION DE ACCIONES
    factorsDescrictionObject.push({
      ...factorsDescriction[existeConcentracionAcciones],
      Reference: cliente?.tabs?.uafe?.concentracionAccionaria?.descripcion,
      PorcentageRisk: cliente?.tabs?.uafe?.concentracionAccionaria?.porcentaje,
      ScoreRisk: cliente?.tabs?.uafe?.concentracionAccionaria?.juridica * cliente?.tabs?.uafe?.concentracionAccionaria?.porcentaje / 100,
    });


    // ESTRUCTURA EXT
    const existeEstructuraExtObj = {
      ...factorsDescriction[existeEstructuraExt],
    }
    if (cliente?.tabs?.uafe?.extranjero === true) {
      existeEstructuraExtObj.Reference = 'APLICA';
      existeEstructuraExtObj.PorcentageRisk = 100;
      existeEstructuraExtObj.ScoreRisk = 5;
    } else {
      existeEstructuraExtObj.Reference = 'NO APLICA';
      existeEstructuraExtObj.PorcentageRisk = 0;
      existeEstructuraExtObj.ScoreRisk = 0;
    }
    factorsDescrictionObject.push(existeEstructuraExtObj);

    consoleService.log("factorsDescrictionObject:", factorsDescrictionObject)

    let total: number = 0;
    for (let i: number = 0; i < factorsDescriction.length; i++) {
      total += factorsDescriction[i].ScoreRisk;
    }

    if (total <= 60) {
      level = "BAJO";
    } else if (total <= 85) {
      level = "MEDIO";
    } else {
      level = "ALTO";
    }

    for (const itemPhone of cliente?.tabs?.generales?.telefonos) {
      if (cellPhone === '' && (itemPhone['descripcion'].toLocaleUpperCase() === "CELULAR")) {
        cellPhone = itemPhone?.numero;
      }
      if (numberPhone === '' && (itemPhone['descripcion'].toLocaleUpperCase() === "TELEFONO")) {
        numberPhone = itemPhone?.numero;
      }
    }

    const factorsDescrictionEnd: Array<FactorsDescrictionList> = []

    for (const item of factorsDescrictionObject) {
      if (cliente?.tabs?.generales?.tipoAgente?.codigo == 7) {
        if (item.Visible.includes('N')) {
          factorsDescrictionEnd.push(item)
        }
      } else {
        if (item.Visible.includes('J')) {
          factorsDescrictionEnd.push(item)
        }
      }
    }

    const registros: ClientesDatosExportar = {
      Code: cliente?.tabs?.contribuyente?.codigo ?? 0,
      CodeBranch: local?.uaf ?? '',
      BranchName: local?.nombre ?? '',
      TypeIdentification: cliente?.tabs.generales?.tipoIdentificacion?.descripcion ?? '',
      Identification: cliente?.tabs?.generales?.identificacion ?? '',
      TypeAgent: cliente?.tabs?.generales?.tipoAgente?.descripcion ?? '',
      Name: cliente?.tabs?.contribuyente?.nombre ?? '',
      Address: cliente?.tabs?.generales?.direccion ?? '',
      Number: cliente?.tabs?.generales?.referenciaDireccion ?? '',
      City: cliente?.tabs?.generales?.ciudad?.nombre ?? '',
      Observations: cliente?.tabs?.adicionales?.observaciones ?? '',
      Province: cliente?.tabs?.generales?.ciudad?.nombreProvincia ?? '',
      NumberPhone: numberPhone ?? '',
      NumberCell: cellPhone ?? '',
      Email: cliente?.tabs?.generales?.email ?? '',
      Contact: cliente?.tabs?.ventas?.atencionCobranza ?? '',
      SalesPerson: cliente?.tabs?.ventas?.vendedor?.completo ?? '',
      CodeMatrix: empresa?.uaf ?? '',
      Date: cliente?.tabs?.historico?.ultimaVenta?.fecha ?? '',
      FactorsDescriction: factorsDescrictionEnd ?? [],
      Level: level
    };

    return [registros];
  }, [local, empresa]);

  return (
    <div style={{ padding: '10px', overflowX: 'hidden' }} className='mb-4' >
      <BlockUi
        tag="div"
        loader={LoadingIndicator}
        blocking={edicionLoaderState.show}
        message={edicionLoaderState.mensaje}
      >
        <fieldset disabled={tab.editStatus === StatesEdition.save}>
          {(verPdf !== undefined) &&
            <ReportWithLocalDataModal
              show={verPdf !== null}
              onClose={() => setVerPdf(null)}
              data={
                onParseData(datosEdicion) ?? []
              }
              fileName='StaticCliente'
              mode={(verPdf?.design === true ? 'Designer' : 'Viewer') ?? 'Viewer'}
              parameters={{ 'Reporte_Filtro': [] }}
              template='StaticCliente'
              key='reportDesigner'
            />
          }
          {datosEdicion.tieneError &&
            <VisualizaError
              titulo='Acatha'
              mensaje={datosEdicion.mensajeError}
              content={generaDetalleErrores(datosEdicion.mensajesErrors)}
              onOk={() => {
                dispatch(setMuestraError({ key: props.tabId, tieneError: false, mensajeError: '' }))
              }}
            />
          }
          {error.show && (
            < PopupContent
              show={error.show}
              title={""}
              subTitle={"Por favor revise las siguientes advertencias:"}
              onClose={() => {
                setError({ errors: [], show: false })
              }}
              size={"sm"}
              canClose={true}
              height={'auto'}
            >
              <CAlert color='danger'>
                <ul style={{ listStyle: 'none' }}>
                  {error.errors.map(function (item, id) {
                    return <li key={id}>{item ?? ''}</li>
                  })}
                </ul>
              </CAlert>
            </PopupContent>
          )}
          <ValidationGroup id={'formClientes'} ref={validationGroupRef}>
            <ValidationSummary />
            <TabsEdition
              tabId={tabId}
              tab={tab}
              setToast={setToast}
              refForm={validationGroupRef}
            />
          </ValidationGroup>
        </fieldset>
        {isMobile && <div style={{ height: '175px' }} />}
      </BlockUi>
    </div>
  )
}

export const defaultDatosEdicionConfig: TMainEdition = {
  imprimir: false,
  loader: {
    mensaje: 'Cargando...',
    show: false,
  },
  mensajeError: '',
  mensajesErrors: [],
  tieneError: false,
  loading: true,
  desdePlantilla: false,
  codigoCliente: 0
}

export const defaultDatosEdicionCliente: ClientesDatosEdicion = {
  ...defaultDatosEdicionConfig,
  tabs: {
    // datosConfig: { ...defaultDatosEdicionConfig },
    contribuyente: { ...defaultDatosEdicionDatosContribuyente },
    generales: { ...defaultDatosEdicionDatosGenerales },
    sucursales: { ...defaultDatosEdicionSucursalCliente },
    ventas: { ...defaultDatosEdicionVentaslCliente },
    historico: { ...defaultDatosEdicionHistoricosCliente },
    adicionales: { ...defaultDatosEdicionAdicionales },
    red: { ...defaultDatosEdicionRed },
    uafe: { ...defaultDatosEdicionUafe },
    lineas: { ...defaultDatosEdicionLineas },
    grupos: { ...defaultDatosEdicionGrupos },
    marcas: { ...defaultDatosEdicionMarcas },
  }
}

const buttonsProductoStyle = { marginLeft: '2px' }

export const ModuleButtons: CustomButtons = {
  Nuevo: true,
  Guardar: true,
  Buscar: true,
  Deshacer: true,
  Limpiar: false,
  Editar: true,
  Imprimir: true,
  Importar: true,
}

export default React.memo(Cliente)
