import { TabsState } from '../../../../../store/genericTabTypes'
import {
  CustomDictionary,
  FETCH_STATUS,
  LoaderInfo,
  TEstado,
} from '../../../../../store/types'
import {
  TOptionGenerico,
  TOptionGenericoString,
} from '../../../../clientes/pages/clientes/types/types'
import { CondicionActivo } from '../../../../componentes/condicionActivoLookUp/types/types'
import { Impresora } from '../../../../componentes/impresorasLookUp/types/types'
import { LocalOption } from '../../../../componentes/localesLookUp/store/type'
import { PartidaArancelaria } from '../../../../componentes/partidasArancelariasLookup/types/types'
import { TipoPropiedad } from '../../../../componentes/propiedadesLookUp/types/types'
import { SutentosTributarios } from '../../../../componentes/sustentosTributarioLookUp/types/types'
import { TipoDepreciacion } from '../../../../componentes/tiposDepreciacionLookUp/types/types'
import { TiposIce } from '../../../../componentes/tiposIceLookUp/types/types'
import { OptionTiposImpuestos } from '../../../../componentes/tiposImpuestosLookup/types/types'
import { PlanFacturacion } from '../../../../componentes/tiposPlanesLookUp/types/types'
import { Subsidio } from '../../../../componentes/tiposSubsidiosLookUp/types/types'
import { ProveedorBusqueda } from '../../../../proveedores/store/types'
import { TipoCliente } from '../../../../ventas/types/types'
import {
  GrupoInventario,
  LineaInventario,
  MarcaInventario,
  UnidadInventario,
} from '../../../store/types'
import { AplicacionInventario } from '../../catalogos/aplicaciones/types/types'

export type GeneralState = {
  modulo: string
  currentAction: string
  currentFunction: string
  aplicacion: string
  loader: LoaderInfo
  collapseAdvance: boolean
  collapse: boolean
}

export type ItemEditData = {
  loaded: boolean
  saved: boolean
  codigoInterno: number
  barras: string
  codigo: string
  nombre: string
  nombreGenerico: string
  numKardex: number
  generales: ItemGenerals
  preciosControl: ItemPriceControl
  cuentasContables: ItemLedgerAccount | null
  existencias: ItemStock
  proveedores: ItemSupplier
  imagenes: Array<ItemImages>
  aplicaciones: Array<AplicacionInventario>
  activos: ItemActives
  partes: Array<Parts>
  promociones: ItemPromotions
  historicos: ItemHistorical
  extras: ItemExtras
  observaciones: ItemObservations
  atributos: ItemAttributes
  backup: ItemEditData
}

export type ItemActives = {
  tipo: TOptionGenerico
  fecha: string
  caracteristica: Characteristic
  valor: number
  dataGrid: Array<Characteristic>
  depreciacion: LedgerAccount
  depreciacionAcumulada: LedgerAccount
  condicion: CondicionActivo
  anios: number
  coef: number
  metodoDep: TOptionGenericoString
  metodoDepInf: TOptionGenericoString
  tipoDep: TipoDepreciacion
  vidaUtil: number
  vidaUtilInf: number
  valorResidual: number
  factor: number
}

export type Parts = {
  codigo: number
  itemPrincipal?: number
  itemSecundario?: number
  itemBarras: string
  itemDescripcion: string
}

export type ItemPromotions = {
  descripcion: string
  desde: string
  hasta: string
  descuento: number
  dataGrid: Array<Promotion>
}

export type Promotion = {
  uuid?: string
  codigo: number
  itemCodigo: number
  desde: string
  hasta: string
  descuento: number
  descripcion: string
}

export type ItemObservations = {
  observaciones: string
  costoReferencial: number
  costoReal: number
  modificado: string
  titulo: string
  valor: string
  manual: string
  especificaciones: string
}

export type ItemAttributes = {
  propiedad: TipoPropiedad
  opciones: Array<string>
  dataGrid: Array<{ codigo: number; descripcion: string; valor: string }>
}

export type ItemExtras = {
  virtual: TOptionGenerico
  venderIndividual: TOptionGenerico
  venderBajoPedido: TOptionGenerico
  peso: number
  largo: number
  ancho: number
  alto: number
  nota: string
  tags: string
  especificaciones: string
  codImpuesto: number
  capacidadCC: number
  codClasificacion: number
  codUnidad: number
  codMarca: number
  paisOrigen: number
  codPresentacion: number
  gradoAlcohol: number
  codGradoAlcohol: number
  estado: number
  gramoAzucar: number
  impresora: Impresora
  uafe: boolean
  nivelMls: string
  deducible: TOptionGenerico
  tiposDeducible: TOptionGenerico
  planes: PlanFacturacion
  subsidio: Subsidio
  liqTansporte: TOptionGenericoString
  desperdicio: number
  costoProduccion: number
  cmuAncho: number
  cmuLargo: number
}

export type ItemHistorical = {
  deSurtir: number
  deRecibir: number
  costoPromedio: number
  ultimoCosto: number
  cantidadVentas: number
  montoVenta: number
  ultimaVenta: string
  cantidadCompras: number
  montoCompra: number
  ultimaCompra: string
}

export type ItemImages = {
  show: boolean
  name: string
  base64: string
}

export type ItemSupplier = {
  tipo: TOptionGenericoString
  proveedor: ProveedorBusqueda
  tablaProveedores: Array<SupplierTable>
}

export type SupplierTable = {
  codigo?: number
  item?: number
  proveedor: number
  proveedorDescripcion: string
  unidadCosto?: string
  tecnologia?: string
  tipo: string
  principal: boolean
  codigoProveedor?: number
}

export type ItemStock = {
  existencia: number
  reservado: number
  maximo: number
  minimo: number
  localizacion: string
  recompra: TOptionGenerico
  localExistencia: Array<{ local: string; existencia: number }>
}

export type ItemLedgerAccount = {
  venta: LedgerAccount
  compra: LedgerAccount
  inventario: LedgerAccount
  costoVentas: LedgerAccount
  retencion: LedgerAccount
  custodia: LedgerAccount
}

export type LedgerAccount = {
  codigo?: number
  item?: string
  tipo?: string
  planCuentasCodigo: number
  planCuentasNumero: string
  planCuentasDescripcion: string
  transaccion?: string
  enUso?: number
}

export type ItemPriceControl = {
  incluyenIva: boolean
  gratuito: boolean
  sinDescuento: boolean
  limitePrecios: boolean
  costo: number
  tipoCliente: TipoCliente
  local: LocalOption
  dataGridPrecios: Array<PricesItems>
  pvd: number
  utilidadPvd: number
  pvp: number
  utilidadPvp: number
  comision: number
  tipoImpuesto: OptionTiposImpuestos
  tipoIce: TiposIce | { codigo: number }
  ibnpr: boolean
  sustentoTributario: SutentosTributarios
  costeos: TOptionGenerico
  partidaArancelaria: string
  partidaArancelariaLookup: PartidaArancelaria
}

export type ItemGenerals = {
  tipoItem: TOptionGenerico
  jerarquia: TOptionGenerico
  controlador: boolean
  dinamico: boolean
  linea: LineaInventario
  grupo: GrupoInventario
  marca: MarcaInventario
  procedencia: TOptionGenerico
  proyecto: LocalOption | { codigo: number; siglasLocal: string }
  tiempoSurtido: number
  unidadCompra: UnidadInventario | { codigo: number; nombre: string }
  unidadVenta: UnidadInventario | { codigo: number; nombre: string }
  entrada: number
  salida: number
  factor: number
  unidadPorEmpaque: number
  estado: TEstado
  performance: TOptionGenerico
  serieImei: boolean
  lotes: boolean
  aduanales: boolean
  mobile: boolean
  conjuntos: boolean
  receta: boolean
  plantilla: boolean
  pesado: boolean
  tiempo: boolean
  minGratis: number
  cadaMin: number
  tarjetas: TOptionGenerico
  edades: TOptionGenericoString
  ticket: boolean
  paquetes: boolean
  codRetencion: number
}

export type ItemsState = {
  general: GeneralState
  tabs: TabsState<ItemEditData>
  editData: CustomDictionary<ItemEditData>
  search: SearchState<any>
}

export type SearchState<ItemsListResult> = {
  filter: FilterItemsState
  status: FETCH_STATUS
  message: string
  resultList: Array<ItemsListResult>
  pageIndex: number
  pageSize: number
  tabs: Array<string>
  currentTab: string
  totalCount: number
  properties: Array<TipoPropiedad>
  selectedItem: ItemsListResult
  searchItem: boolean
}

export type FilterItemsState = {
  busquedaIncremental: boolean
  inicioFinOpt: TOptionGenericoString
  movilweb: TOptionGenerico
  imagen: TOptionGenericoString
  codigo: string
  barras: string
  linea: string
  nombre: string
  grupo: string
  marca: string
  generico: string
  aplicacion: string
  codProv: string
  tipoImpuesto: OptionTiposImpuestos
  precioDesde: number
  precioHasta: number
  modelo: string
  local: LocalOption
  tipoItem: TOptionGenerico
  estadoItem: TOptionGenerico
  existencia: boolean
  aplicaciones: boolean
  lotes: boolean
  agrupador: TOptionGenerico
  noSincronizados: boolean
}

export type ItemsListResult = {
  itemCodigo: number
  itemCodigoUsuario: string
  itemBarras: string
  itemDescripcion: string
  itemGenerico: string
  itemTipo: number
  itemTipoDescripcion: string
  itemLineaCodigo: number
  itemLineaDescripcion: string
  itemMarcaCodigo: number
  itemMarcaDescripcion: string
  itemGrupoCodigo: number
  itemGrupoDescripcion: string
  itemUnidadCompraCodigo: number
  itemUnidadCompraDescripcion: string
  itemUnidadVentaCodigo: number
  itemUnidadVentaDescripcion: string
  itemImpuestoCodigo: number
  itemImpuestoDescripcion: string
  itemImpuestoValor: number
  itemIce: number
  itemImagen: string
  itemLoteDescripcion: string
  itemNumeroSerieDescripcion: string
  itemProcedenciaDescripcion: string
  itemPVO: number
  itemCosteo: number
  itemCosto: number
  itemCostoIVA: number
  itemAplicaciones: string
  itemPeso: number
  itemLargo: number
  itemAncho: number
  itemAlto: number
  itemNota: string
  itemTags: string
  itemGananciaPVD: number
  itemGananciaPVP: number
  localCodigo: number
  loteNumero: null | string
  loteCaducidad: string
  itemLocalizacion: null | any
  itemProveedores: null | any
  itemPadre: null | any
  itemControlador: null | any
  itemJerarquia: string
  itemSemana: string
  itemUltimoProveedor: null | any
  impresoraCodigoTecnologia: null | any
  itemModeloBom: null | any
  impresoraTipo: null | any
  proveedorNombreFabricante: null | any
  impresoraCodigoTecnologiaFabricante: null | any
  itemDesperdicio: number
  itemDescuentos: string
  itemNivelMSL: string
  costoTotal: number
  itemLimites: number
  itemProveedor: null | any
  itemReservas: string
  itemExistencia: number
  itemPVD: number
  itemPVDIVA: number
  itemPVP: number
  itemPVPIVA: number
  iceCodigoGradoAlcoholico: string
  iceGradoAlcoholico: string
  iceCapacidaCm3: string
  iceCodigoImpuesto: string
  iceCodigoClasificacion: string
  iceCodigoMarca: string
  iceEstado: string
  iceCodigoPresentacion: string
  iceCodigoUnidad: string
  icePaisOrigen: string
  iceGramosAzucar: string
  subsidio: number
}

export type FilterSearchToApi = {
  itemCodigoUsuario: string
  itemBarras: string
  itemDescripcion: string
  itemGenerico: string
  itemLineaDescripcion: string
  itemGrupoDescripcion: string
  itemMarcaDescripcion: string
  itemAplicaciones: string
  itemTipo: number
  itemImpuesto: number
  itemNota: string
  itemPeso: string
  itemTags: string
  itemEstado: number
  itemPVDIVA: number
  itemPVPIVA: number
  itemIncremental: number
  itemMobile: number
  impresoraCodigoTecnologia: string
  itemModeloBom: string
  itemBusqueda: number
  itemJerarquia: number
  itemIdwoo: number
  subsidio: string
  exacta: string
  local: number
}

export type PaginationSearch = {
  isPaged: boolean
  page: number
  limit: number
}

export type WebCategoryWoo = {
  id: number
  name: string
}

export type SaveWooItem = {
  infoRegistro: {
    codigo: number
    barras: string
    descripcion: string
    observaciones: string
    grupo: number
    tipo: number
    jerarquia: string
    codusu: string
    virtual: number
    individual: number
    largo: number
    ancho: number
    alto: number
    tags: string
    pvpIva: number
    SUD_CODIGO: number
    local: number
    imagenes: Array<ItemImages>
  }
}

export type SaveUnit = {
  infoRegistro: {
    codigo: number
    nombre: string
    siglas: string
    grupo: number
    estado: number
    fraccion?: number
  }
}

export type PricesItems = {
  tipoClienteCodigo: number
  tipoClienteDescripcion: string
  itemCodigo: number
  localCodigo: number
  localNombre: string
  precio: number
  precioIva: number
  utilidad: number
}

export type SaveAplication = {
  infoRegistro: {
    codigo: number
    descripcion: string
    observaciones: string
    pertenece: number
    estado: number
  }
}

export type Characteristic = {
  codigo: number
  nombre: string
  estado: number
  estadoDescripcion: string
  valor?: number
}

export type SaveCharacteristic = {
  infoRegistro: {
    codigo: number
    nombre: string
    estado: number
  }
}

export type SaveItem = {
  infoRegistro: {
    codigo: number
    codigoUsu: string
    barras: string
    nombre: string
    generico: string
    tipoItem: number
    linea: number
    marca: number
    grupo: number
    procedencia: number
    local: number
    tsurtido: number
    factor: number
    estado: number
    jerarquia: number
    controlador: number | null
    dinamico: number | null
    modelo: number
    numserie: number
    lotes: number
    aduanales: number
    mobile: number
    conjunto: number
    receta: number
    plantilla: number
    pesado: number
    horas: number
    minGratis: number
    tiempoMin: number
    tarjeta: number
    tClase: string
    unidade: number
    unidadc: number
    unidadv: number
    ticket: number
    paquetes: number
    codRetencion: number
    gratuito: number
    descuento: number
    limite: number
    precios: Array<PricesItems>
    pvd: number
    pvp: number
    gananciaPvp: number
    gananciaPvd: number
    comision: number
    tipoImpuesto: number
    tipoIce: number
    irbpnr: number
    sustentoCodigo: string
    partidaAran: string
    costeo: number
    cuentasContables: ItemLedgerAccount
    stockMax: number
    stockMin: number
    localizacion: string
    recompras: number
    proveedores: Array<SupplierTable>
    imagenes: Array<ItemImages>
    aplicaciones: Array<AplicacionInventario>
    depreciacionAmor: number
    fechaDisponible: string
    caracteristicas: Array<Characteristic>
    sitContable: number
    condicionActivo: number
    anios: number
    coef: number
    metododepreciacion: number
    metodoDepreciacionInf: number
    tipoDepreciacion: number
    valorResidual: number
    factorDepreciacion: number
    cuentaDepreciacion: LedgerAccount
    cuentaDepreciacionAcu: LedgerAccount
    partes: Array<Parts>
    promociones: Array<Promotion>
    virtual: number
    individual: number
    bajoPedido: number
    peso: number
    largo: number
    ancho: number
    alto: number
    nota: string
    tags: string
    especificaciones: string
    codigoImpuesto: number
    capacidadCmCubicos: number
    codigoClasificacion: number
    codigoUnidad: number
    codigoMarca: number
    paisOrigen: number
    codigoPresentacion: number
    gradoAlcolico: number
    codGradoAlcohol: number
    estadoIce: number
    gramosAzucar: number
    impresora: number
    uafe: number
    nivelMls: string
    deducible: number
    tipoDeducible: number
    planes: number
    subsidio: number
    liquidacionTrasporte: string
    desperdicio: number
    costoProduccion: number
    cmuAncho: number
    cmuLargo: number
    observaciones: string
    costoReferencial: number
    modificado: string
    tituloAdicional: string
    valorAdicional: string
    manualPdf: string
    especificacionesPdf: string
    propiedades: Array<{ codigo: number; descripcion: string; valor: string }>
  }
}

export type SyncWoo = {
  infoRegistro: {
    codigoUsuario: string
    barras: string
    descripcion: string
    generico: string
    linea: string
    grupo: string
    marca: string
    aplicaciones: string
    tipo: number
    impuesto: number
    nota: string
    peso: number
    tags: string
    estado: number
    pvdIva: number
    pvpIva: number
    incremental: number
    mobile: number
    codigoTecnologia: string
    modeloBom: string
    busqueda: number
    jerarquia: number
    idwoo: number
    imagen: string
    local?: number
  }
}

export type EditPrices = {
  infoRegistro: {
    items: Array<ItemsListResult>
    precio: boolean
    pvd: number
    pvp: number
    accion: boolean
  }
}

export const optionsSearch = [
  { value: 'i', label: 'Inicio' },
  { value: 'f', label: 'Fin' },
]

export const optionsMovilWeb = [
  { value: -1, label: 'Todos' },
  { value: 1, label: 'Si' },
  { value: 0, label: 'No' },
]

export const optionsImages = [
  { value: '', label: 'Todos' },
  { value: 'SIN', label: 'Sin Imagenes' },
  { value: 'CON', label: 'Con Imagenes' },
]

export const optionsTypes = [
  { value: 0, label: 'Todos' },
  { value: 1, label: 'Item' },
  { value: 2, label: 'Servicio' },
  { value: 3, label: 'Kit (Conjunto)' },
  { value: 4, label: 'Materia Prima' },
  { value: 5, label: 'Otros/Gastos' },
  { value: 6, label: 'E/S Rápida' },
  { value: 7, label: 'Activos Fijo' },
  { value: 8, label: 'Bienes S/R' },
]

export const optionsStatus = [
  { value: 1, label: 'Activos' },
  { value: 2, label: 'Inactivos' },
]

export const optionsGroups = [
  { value: 0, label: 'Todos' },
  { value: 1, label: 'Agrupador' },
  { value: 2, label: 'Variantes' },
]

export const optionsGroupsTarj = [
  { value: 0, label: 'N/A' },
  { value: 1, label: 'GCard' },
  { value: 2, label: 'Memb' },
  { value: 3, label: 'Bol' },
  { value: 4, label: 'Cor' },
]

export const optionsAges = [
  { value: 'ADULTO', label: 'Adulto' },
  { value: 'NIÑO', label: 'Niño' },
  { value: 'TERCERA EDAD', label: 'Ter. Edad' },
  { value: 'DISCAPACITADO', label: 'Cap. Diferene' },
]

export const optionsGroupsCosteo = [
  { value: 62, label: 'PEPS' },
  { value: 63, label: 'UEPS' },
  { value: 61, label: 'Promedio' },
]

export const tabsNewItem: Array<string> = [
  'Generales',
  'Precios y Control',
  'Cuentas Contables',
  'Existencias',
  'Proveedores',
  'Imágenes',
  'Aplicaciones',
  'Activos',
  'Partes',
  'Promociones',
  'Históricos',
  'Extras',
  'Observaciones',
  'Atributos',
]
