import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { FETCH_STATUS, LoaderInfo } from "../../../../../../store/types";
import { BusquedaState, FiltroBudquedaDecios, TenthList } from "../types/types";
import { FilterInitial } from "../components/buscar/FiltroBusqueda";
import { RolParamsList } from "../../rolesPago/types/types";


const initialState: BusquedaState<TenthList> = {
  filter: {
    identificacion: '',
    nombre: '',
    periodo: null,
    listaFormasPagos: [],
    estado: null,
    fechaCobro: '',
    formaPago: null,
    initial: true
  },
  filterBackup: {
    identificacion: '',
    nombre: '',
    periodo: null,
    listaFormasPagos: [],
    estado: null,
    fechaCobro: '',
    formaPago: null,
    initial: true
  },
  status: FETCH_STATUS.IDDLE,
  rolparametros: null,
  resultadosTercero: [],
  resultadosCuarto: [],
  seleccionado: null,
  colapsado: true,
  colapsadoDetalle: false,
  import: false,
  searchDecimT: false,
  searchDecimC: false,
  loader: {
    show: false,
    mensaje: '',
  },
  nroRegistrosEncontradosT: 0,
  nroRegistrosEncontradosF: 0,
  total: 0,
}


const searchSlice = createSlice({
  name: 'buscarDecimosPagos',
  initialState: initialState,
  reducers: {
    setResetResults(state) {
      state.resultadosTercero = []
    },
    setResultsThierth(state, action: PayloadAction<TenthList[]>) {
      state.resultadosTercero = action.payload
    },
    setResultsFourth(state, action: PayloadAction<TenthList[]>) {
      state.resultadosCuarto = action.payload
    },
    setResetSeleccion(state) {
      state.seleccionado = null
    },
    setSeleccionarDato(state, action: PayloadAction<TenthList>) {
      state.seleccionado = action.payload
    },
    setCollapsed(state, action: PayloadAction<boolean>) {
      state.colapsado = action.payload
    },
    setRolParams(state, action: PayloadAction<RolParamsList>) {
      state.rolparametros = action.payload
    },
    changeFilter(state, action: PayloadAction<FiltroBudquedaDecios>) {
      state.filter.identificacion = action.payload.identificacion
      state.filter.nombre = action.payload.nombre
      state.filter.periodo = action.payload.periodo
      state.filter.estado = action.payload.estado
      state.filter.fechaCobro = action.payload.fechaCobro
      state.filter.formaPago = action.payload.formaPago
      state.filter.listaFormasPagos = action.payload.listaFormasPagos
      state.filter.initial = action.payload.initial
    },
    changeFilterBackup(state, action: PayloadAction<FiltroBudquedaDecios>) {
      state.filterBackup.identificacion = action.payload.identificacion
      state.filterBackup.nombre = action.payload.nombre
      state.filterBackup.periodo = action.payload.periodo
      state.filterBackup.estado = action.payload.estado
      state.filterBackup.fechaCobro = action.payload.fechaCobro
      state.filterBackup.formaPago = action.payload.formaPago
      state.filterBackup.listaFormasPagos = action.payload.listaFormasPagos
      state.filterBackup.initial = action.payload.initial
    },
    setCollapsedDetail(state, action: PayloadAction<boolean>) {
      state.colapsadoDetalle = action.payload
    },
    setTotal(state, action: PayloadAction<number>) {
      state.total = action.payload
    },
    setImport(state, action: PayloadAction<boolean>) {
      state.import = action.payload
    },
    setNroRegistrosEncontradasT(state, action: PayloadAction<number>) {
      state.nroRegistrosEncontradosT = action.payload
    },
    setNroRegistrosEncontradasF(state, action: PayloadAction<number>) {
      state.nroRegistrosEncontradosF = action.payload
    },
    setChangeLoader(state, action: PayloadAction<LoaderInfo>) {
      state.loader = action.payload
    },
    setSearchT(state, action: PayloadAction<boolean>) {
      state.searchDecimT = action.payload
    },
    setSearchC(state, action: PayloadAction<boolean>) {
      state.searchDecimC = action.payload
    },
  }
})

export const {
  setResetResults,
  setResultsThierth,
  setResultsFourth,
  changeFilter,
  changeFilterBackup,
  setResetSeleccion,
  setSeleccionarDato,
  setCollapsed,
  setCollapsedDetail,
  setImport,
  setNroRegistrosEncontradasT,
  setNroRegistrosEncontradasF,
  setChangeLoader,
  setSearchT,
  setSearchC,
  setTotal,
  setRolParams,
} = searchSlice.actions
export const searchReducer = searchSlice.reducer