import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DateUtils } from "../../../../../helpers/dateUtils";
import { RootState } from "../../../../../store/store";
import { DateRange, FETCH_STATUS, } from "../../../../../store/types";
import { RetencionesService } from "../../../services/retenciones.service";
import { SearchState } from "../../../types/generics";
import { FiltroBusquedaState } from "../../../types/types";
import { RetencionventaListado } from "../types/types";



const initialState: SearchState<RetencionventaListado> = {
  filter: {
    fechaInicio: DateUtils.getCurrentDateAsString(),
    fechaFin: DateUtils.getCurrentDateAsString(),
    puntoVenta: '',
    cliente: null,
    activas: false,
    documento: '',
    formaPago: null,
    pendientesAbutorizar: false,
    establecimiento: null,
    estado: null,
    puntoEmision: null,
    institucionesFinancieras: false
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  resultados: []
}

export const fetchRetencionesVentas = createAsyncThunk<Array<RetencionventaListado>, FiltroBusquedaState>(
  'ventas/searchRetenciones',
  async (filtro) => {
    try {
      const rangofechas: DateRange = {
        inicio: DateUtils.pickersDateToApiDate(filtro.fechaInicio),
        fin: DateUtils.pickersDateToApiDate(filtro.fechaFin)
      }
      const retenciones = await RetencionesService.getRetenciones(rangofechas,
        filtro.formaPago ? filtro.formaPago.codigo : -1,
        filtro.institucionesFinancieras ? filtro.institucionesFinancieras : false,
        filtro.cliente ? filtro.cliente.identificacion : '',
        filtro.documento ? filtro.documento : '');
      return retenciones;
    } catch (error) {
      return Promise.reject(error);
    }
  }
)



const searchRetencionesVentasSlice = createSlice({
  name: 'searchRetencionesVentas',
  initialState: initialState,
  reducers: {
    resetState(state) {
      state = {
        ...initialState,
        filter: {
          ...initialState.filter,
          fechaInicio: DateUtils.getCurrentDateAsString(),
          fechaFin: DateUtils.getCurrentDateAsString(),
        },
      };
    },
    changeFilter(state, action: PayloadAction<FiltroBusquedaState>) {
      state.filter = action.payload;
    },
    setSearchStatus(state, action:PayloadAction<FETCH_STATUS>) {
      state.status = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRetencionesVentas.pending, (state) => {
      if (state.status === FETCH_STATUS.IDDLE || state.status === FETCH_STATUS.SUCCESS) {
        state.status = FETCH_STATUS.LOADING;
        state.mensaje = '';
      }
    })
    builder.addCase(fetchRetencionesVentas.fulfilled, (state, { payload }) => {
      state.resultados = payload;
      state.status = FETCH_STATUS.SUCCESS;
    })
    builder.addCase(fetchRetencionesVentas.rejected, (state, { payload }) => {
      state.resultados = [];
      state.status = FETCH_STATUS.FAILED;
      state.mensaje = payload as string;
    })
  }
})


export const selectFiltroBusquedaRetencionesVentas = (state: RootState) => { return state.ventas.retenciones.search.filter };
export const selectResultadosBusquedaRetencionesVentas = (state: RootState) => { return state.ventas.retenciones.search.resultados };
export const selectBusquedaRetencionesVentasEstado = (state: RootState) => { return state.ventas.retenciones.search.status };
export const { changeFilter, setSearchStatus, resetState } = searchRetencionesVentasSlice.actions;
export const searchRetencionesVentasReducer = searchRetencionesVentasSlice.reducer;