import { GeneralState } from '../types/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LoaderInfo } from '../../../../../store/types'

const initialState: GeneralState = {
  modulo: 'inventario',
  currentAction: 'Buscar',
  currentFunction: '',
  aplicacion: 'multialmacen',
  loader: {
    show: false,
    mensaje: '',
  },
  collapse: true,
}

const multistoreSlice = createSlice({
  name: 'multistore',
  initialState: initialState,
  reducers: {
    setCurrentAccion(state, action: PayloadAction<string>) {
      state.currentAction = action.payload
    },
    setCurrentFunction(state, action: PayloadAction<string>) {
      state.currentFunction = action.payload
    },
    changeLoaderMultistore(state, action: PayloadAction<LoaderInfo>) {
      state.loader = action.payload
    },
    setCollpased(state, action: PayloadAction<boolean>) {
      state.collapse = action.payload
    },
  },
})

export const {
  setCurrentAccion,
  setCurrentFunction,
  changeLoaderMultistore,
  setCollpased,
} = multistoreSlice.actions

export const generalReducer = multistoreSlice.reducer
