import React, { useCallback, useEffect } from 'react'
import { ValidationGroup } from 'devextreme-react/validation-group'
import ValidationSummary from 'devextreme-react/validation-summary'
import TextBox from 'devextreme-react/text-box'
import CustomCol from '../../../../../../../../views/componentes/colContainer'
import { TEstado, ToastTypes } from '../../../../../../../../store/types'
import { Validator } from 'devextreme-react'
import { AsyncRule, RequiredRule } from 'devextreme-react/validator'
import RowContainer from '../../../../../../../../views/componentes/rowContainer/rowContainer'
import Labeled from '../../../../../../../../views/componentes/labeledInput/labeledInput'
import GruposUnidadesLookUp from '../../../../../../../componentes/gruposUnidadesLookUp'
import TipoEstadoLookUp from '../../../../../../../componentes/tipoEstado'
import { Subtipo } from '../../../../../../../componentes/gruposUnidadesLookUp/types/types'
import VisualizaError from '../../../../../../../ventas/pages/shared/visualizaError/visualizaError'
import ListErrosValidationForm from '../../../../../../../componentes/listadoErroresValidacionForm'
import { SaveUnit } from '../../../../types/types'
import { unitsServices } from '../../services/modalUnidades.services'

interface INuevoProps extends React.PropsWithChildren {
  selectedItem?: any
  clickedButton: string
  currentTab: string
  // eslint-disable-next-line no-unused-vars
  setCurrentAction: (currentTab: string) => void
  resetAction: () => void
  resetSelected?: () => void
  // eslint-disable-next-line no-unused-vars
  setToast: (sms: string, type: ToastTypes) => void
  playLoader: () => void
  stopLoader: () => void
}

const Nuevo = (props: INuevoProps) => {
  const {
    clickedButton,
    setToast,
    selectedItem,
    playLoader,
    stopLoader,
    currentTab,
    resetAction,
    resetSelected,
    setCurrentAction,
  } = props

  const formSaveUnit = React.useRef<any>()
  const [code, setCode] = React.useState<number>(0)
  const [name, setName] = React.useState<string>('')
  const [abbreviation, setAbbreviation] = React.useState<string>('')
  const [status, setStatus] = React.useState<TEstado>({
    codigo: 1,
    descripcion: 'ACTIVO',
  })
  const [type, setType] = React.useState<Subtipo>(null)
  const [fraction, setFraction] = React.useState<number>(0)
  const [showPopupError, setShowPopupError] = React.useState<boolean>(false)
  const [validationFormErrors, setValidationFormErrors] = React.useState<
    Array<any>
  >([])

  useEffect(() => {
    if (currentTab === 'Nuevo' || currentTab === 'Editar') {
      if (clickedButton === 'Guardar') {
        onHandleSave()
      } else if (clickedButton === 'Editar') {
        setDataUnit(selectedItem)
      }
      resetAction()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickedButton])

  const onHandleReset = useCallback(() => {
    if (code === 0) {
      setName('')
      setAbbreviation('')
      setStatus({ codigo: 1, descripcion: 'ACTIVO' })
      setType(null)
      setFraction(0)
    } else {
      setDataUnit(selectedItem)
    }
  }, [code, selectedItem])

  const setDataUnit = ({ row }) => {
    setCode(row.codigo)
    setName(row.nombre)
    setAbbreviation(row.siglas)
    setStatus({ codigo: row.estadoCodigo, descripcion: row.estadoNombre })
    setType({
      codigo: row.grupoCodigo,
      valor: '',
      tipoCodigo: '',
      grupo: '',
      orden: -1,
      estado: '',
    })
    setFraction(row.fraccion ? row.fraccion : 0)
  }

  const onHandleSave = React.useCallback(async () => {
    let validationFormResult = formSaveUnit.current.instance.validate()
    if (validationFormResult.status === 'pending')
      validationFormResult = await validationFormResult.complete
    if (validationFormResult.isValid === false) {
      const errorList = []
      validationFormResult.brokenRules.map((rule) => {
        errorList.push(rule.message)
      })
      setValidationFormErrors(errorList)
      setShowPopupError(true)
    } else {
      try {
        const unit: SaveUnit = {
          infoRegistro: {
            codigo: code,
            nombre: name,
            siglas: abbreviation,
            grupo: type.codigo,
            estado: status.codigo,
            fraccion: fraction,
          },
        }
        playLoader()
        const saveUnit = await unitsServices.saveUnit(unit)
        stopLoader()
        if (!saveUnit.error && saveUnit.auto) {
          setToast(
            saveUnit.message ?? 'Registro Registrado',
            ToastTypes.Success,
          )
          setCurrentAction('Buscar')
          if (selectedItem) resetSelected()
          onHandleReset()
        } else {
          setToast(
            saveUnit.message ?? 'Error al Guardar Unidad',
            ToastTypes.Warning,
          )
        }
      } catch (error) {
        stopLoader()
        setToast(error, ToastTypes.Danger)
      }
    }
  }, [
    code,
    name,
    abbreviation,
    type,
    status,
    fraction,
    playLoader,
    stopLoader,
    setToast,
    setCurrentAction,
    selectedItem,
    resetSelected,
    onHandleReset,
  ])

  const onHandleChange = React.useCallback(
    (setState: any) => (event: any) => {
      if (event.event) setState(event.value)
    },
    [],
  )

  const validateType = React.useCallback((selected: any) => {
    if (selected?.value?.codigo > -1) {
      return Promise.resolve()
    } else {
      return Promise.reject(
        'El Grupo de la Unidad no se encuentra seleccionado, por favor elija una opción.',
      )
    }
  }, [])

  return (
    <>
      {showPopupError && (
        <VisualizaError
          titulo="Error en Woocommerce"
          mensaje={'Error al guardar'}
          content={
            <ListErrosValidationForm
              mainMessage={
                'Antes de guardar la información revise lo siguiente:'
              }
              errorsList={validationFormErrors}
              color={'danger'}
            />
          }
          onOk={() => setShowPopupError(false)}
        />
      )}
      <ValidationGroup ref={formSaveUnit}>
        <ValidationSummary id="summary" />
        <RowContainer>
          <CustomCol xs="12" md="4">
            <Labeled label="Nombre:">
              <TextBox
                value={name}
                onValueChanged={onHandleChange(setName)}
                showClearButton={true}
              >
                <Validator>
                  <RequiredRule message={'El campo Nombre es obligatorio'} />
                </Validator>
              </TextBox>
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="4">
            <Labeled label="Abreviatura:">
              <TextBox
                value={abbreviation}
                onValueChanged={onHandleChange(setAbbreviation)}
                showClearButton={true}
                maxLength={10}
              />
            </Labeled>
          </CustomCol>
        </RowContainer>
        <RowContainer>
          <CustomCol xs="12" md="4">
            <Labeled label="Tipo:">
              <GruposUnidadesLookUp
                selected={type}
                onChanged={(type: Subtipo) => setType(type)}
                onChangedOptions={() => {}}
              >
                <Validator>
                  <AsyncRule validationCallback={validateType} />
                </Validator>
              </GruposUnidadesLookUp>
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="4">
            <Labeled label="Estado:">
              <TipoEstadoLookUp
                onChanged={(status: TEstado) => setStatus(status)}
                selected={status}
              />
            </Labeled>
          </CustomCol>
        </RowContainer>
      </ValidationGroup>
    </>
  )
}
export default Nuevo
