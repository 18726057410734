import React, { useCallback } from 'react'
import RowContainer from '../../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../../views/componentes/colContainer'
import { Button, DataGrid } from 'devextreme-react'
import { Column } from 'devextreme-react/data-grid'
import { ModalAplicaciones } from '../../../modalAplicaciones'
import { AplicacionInventario } from '../../../../../catalogos/aplicaciones/types/types'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../../store/store'
import { useSetToast } from '../../../../../../../../hooks/useGlobalHooks'
import { ToastTypes } from '../../../../../../../../store/types'
import { setDataAplicationsToEdit } from '../../../../store/editDataReduce'

export const Aplicaciones = (props) => {
  const { tabId } = props

  const dispatch = useDispatch()
  const setToastMessage = useSetToast()

  const aplicationsDataToEdit = useSelector((state: RootState) => {
    return state.inventarios.items.editData[tabId].aplicaciones
  })

  const [showModalAplications, setShowModalAplications] =
    React.useState<boolean>(false)
  const [selectedAplication, setSelectedAplication] =
    React.useState<AplicacionInventario>(null)

  const setAplication = useCallback(
    (newAplication: AplicacionInventario) => {
      const aplications = structuredClone(aplicationsDataToEdit)
      const indexAplication = aplications.findIndex(
        (aplication: AplicacionInventario) => {
          return aplication.codigo === newAplication.codigo
        },
      )
      if (indexAplication === -1) {
        aplications.push(newAplication)
        dispatch(
          setDataAplicationsToEdit({
            key: tabId,
            aplications: aplications,
          }),
        )
      } else
        setToastMessage(
          'Agregar Aplicación',
          'La aplicación ya se encuentra agregado',
          ToastTypes.Warning,
        )
    },
    [aplicationsDataToEdit, dispatch, setToastMessage, tabId],
  )

  const onRemoveSelectedAplication = React.useCallback(() => {
    if (selectedAplication) {
      const aplications: Array<AplicacionInventario> = structuredClone(
        structuredClone(aplicationsDataToEdit),
      )
      const refreshAplications = aplications.filter(
        (aplication: AplicacionInventario) =>
          aplication.codigo !== selectedAplication.codigo,
      )
      dispatch(
        setDataAplicationsToEdit({
          key: tabId,
          aplications: refreshAplications,
        }),
      )
    } else
      setToastMessage(
        'Eliminar Aplicación',
        'Debe seleccionar una aplicación para eliminarla',
        ToastTypes.Warning,
      )
  }, [
    selectedAplication,
    setToastMessage,
    aplicationsDataToEdit,
    dispatch,
    tabId,
  ])

  return (
    <RowContainer className="m-2">
      {showModalAplications && (
        <ModalAplicaciones
          show={showModalAplications}
          onSelectUnit={(aplication: AplicacionInventario) => {
            setShowModalAplications(false)
            setAplication(aplication)
          }}
          onCancel={() => setShowModalAplications(false)}
        />
      )}
      <RowContainer>
        <CustomCol xs="12" md="6">
          <div
            style={{
              display: 'flex',
              gap: '2px',
              alignItems: 'center',
              width: '100%',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              id="btnAdd"
              className="me-1"
              icon="add"
              stylingMode="contained"
              type="default"
              onClick={() => setShowModalAplications(true)}
            />
            <Button
              id="btnRemove"
              icon="trash"
              stylingMode="contained"
              type="danger"
              onClick={() => onRemoveSelectedAplication()}
              disabled={!selectedAplication}
            />
          </div>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="6">
          <DataGrid
            className="m-2"
            selection={{ mode: 'single' }}
            hoverStateEnabled={true}
            dataSource={aplicationsDataToEdit ?? []}
            showBorders={true}
            showRowLines={true}
            onSelectionChanged={({ selectedRowsData }) => {
              setSelectedAplication(selectedRowsData[0])
            }}
            loadPanel={{ enabled: true }}
          >
            <Column
              dataType="string"
              dataField="codigo"
              caption="Código"
              width="25%"
            />
            <Column
              dataType="string"
              dataField="descripcion"
              caption="Nombre"
              width="75%"
            />
          </DataGrid>
        </CustomCol>
      </RowContainer>
    </RowContainer>
  )
}

export const defaultAplicaciones: Array<AplicacionInventario> = []
