import React from 'react';
import SelectBox from 'devextreme-react/select-box';
import { zonasService } from './service/zonas.service';
import { TOptionGenericoSelect } from '../../clientes/pages/clientes/types/types';
import { v4 as uuidv4 } from 'uuid'

interface LookUpPropsZonas extends React.PropsWithChildren {
  id?: string
  selected: TOptionGenericoSelect | null;
  onChanged: (event: any) => void;
  disabled?: boolean;
  provider?: Array<TOptionGenericoSelect> | []
}

const LookUpZonas: React.FC<LookUpPropsZonas> = (props) => {
  const { id, provider, selected, onChanged, disabled } = props;
  const selectRef = React.useRef<any>(null);
  const [options, setOptions] = React.useState<TOptionGenericoSelect[]>([]);

  const loadDatos = React.useCallback(async () => {
    if (provider?.length) {
      setOptions(provider);
      return;
    }
    const { error, auto } = await zonasService.getZonas("Elija una opción");
    if (!error && auto.length) {
      const opt = auto.map(x => ({
        codigo: parseInt(x?.codigo) ?? -1,
        descripcion: x?.descripcion ?? '',
        estado: x?.estado ?? 0
      }));
      setOptions(opt);
    }
  }, [provider]);

  const onValueChanged = React.useCallback((event: any) => {
    if (!event.event) return;
    onChanged(event.value);
  }, [onChanged]);

  React.useEffect(() => {
    if (!selected && options.length > 0) {
      const defaultOption = options.find(opt => opt.codigo === -1);
      if (defaultOption) {
        onValueChanged(defaultOption);
      }
    }
  }, [selected, options, onValueChanged]);

  React.useEffect(() => {
    loadDatos();
  }, [loadDatos]);

  return (
    <SelectBox
      id={id ?? 'zonasSelect'}
      key={uuidv4()}
      ref={selectRef}
      items={options}
      searchEnabled
      displayExpr="descripcion"
      searchExpr={['descripcion']}
      searchTimeout={500}
      value={selected}
      onValueChanged={onValueChanged}
      disabled={disabled}
      onFocusIn={(e: any) => e.element.querySelector("input.dx-texteditor-input").select()}
    >
      {props.children}
    </SelectBox>
  );
}

export default React.memo(LookUpZonas);
