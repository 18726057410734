import { TabsState } from '../../../../../store/genericTabTypes'
import {
  CustomDictionary,
  FETCH_STATUS,
  LoaderInfo,
} from '../../../../../store/types'
import { TOptionGenerico, TOptionGenericoString } from '../../../../clientes/pages/clientes/types/types'
import { LocalOption } from '../../../../componentes/localesLookUp/store/type'
import { ItemPedido } from '../../../../ventas/pages/ordenes/pages/ordenPedido/type/types'
import { MenuState } from '../../../../ventas/types/types'

export type GeneralState = {
  modulo: string
  currentAction: string
  currentFunction: string
  aplicacion: string
  loader: LoaderInfo
}

export enum TitleTabSeries {
  kardex = 'Kardex de la Serie',
  series = 'Series por Comprobante',
}

export type SeriesList = {
  nombre: string
  estado: TOptionGenerico | null
  codigo: number
}

export type SaveStore = {
  infoRegistro: {
    codigo: number
    clase: number
    nombre: string
    siglas: string
    ciudad: number
    direccion: string
    telefono: string
    gerente: string
    tipo: number
    pertenece: number
    kardex: number
    estado: number
    siglasBOD: string
    web: number
    empresaCodigo: number
    uaf: string
    descuento: number
    usuarioDokan: string
    passwordDokan: string
    listaPrecios: number
    nameLogo: string
    codeFileLogo: string
  }
}

export type SeriesState = {
  config: ConfigSeries
  menu: MenuState
  general: GeneralState
  tabs: TabsState<SeriesList>
  editData: CustomDictionary<SeriesList>
  search: SearchState<any>
}

export type SearchState<T> = {
  filter: FilterSeriesState
  status: FETCH_STATUS
  statusCom: FETCH_STATUS
  message: string
  resultList: Array<T>
  resultListCom: Array<T>
  tabs: Array<string>
  currentTab: string
  colapsado: boolean
  colapsadoAvanzado: boolean
}

export type FilterSeriesState = {
  serie: string
  min: string
  verBodegaTransito: boolean
  ingresosEgreso: string
  compraCodigo: number
  compraDescripcion: string
  ventaCodigo: number
  ventaDescripcion: string
  transferencia: string
  item: ItemPedido | null
  local: LocalOption | null
  tipo: TOptionGenericoString
  enStock: boolean
}

export type ConfigSeries = {
  modulo: number
}

export type StoreListResult = {
  localCodigo: number
  localCiudad: number
  localCiudadNombre: string
  localNombre: string
  localDireccion: string
  localTelefono: string
  localGerente: string
  localTipo: number
  localTipoDescripcion: string
  localClase: number
  localClaseDescripcion: string
  localPertenece: number
  localPerteneceDescripcion: string
  localEstado: number
  localEstadoDescripcion: string
  localKardex: number
  localSiglas: string
  localSiglasLocal: string
  localWEB: number
  localUAF: string
  localDescuento: number
  localContrato: number
  localObservaciones: string
  localUsuarioDokan: string
  localPasswordDokan: string
  ULOTipo: string
  localLogo: string
  localListaPrecios: number
}


export type SeriesVoucherDto = {
  Code: string
  Description: string
  Serie: string
  Min: string
  Type: string
  Date: string
  Transaction: string
  Local: string
}

export type KardexSerieDto = {
  Record: string
  KardexDescription: string
  Local: string
  Line: string
  Unit: string
  Income: number
  egress: number
  Stock: number
}