import { combineReducers } from '@reduxjs/toolkit'
import { tabsReducer } from './tabsReducer'
import { generalReducer } from './generalReducer'
import { SeriesState } from '../types/types'
import { dataEditReducer } from './editDataReduce'
import { searchReducer } from './serachReducer'
import { menuReducer } from './menuReducer'
import { configuracionesSeries } from './configReducer'

export const SeriesReducer = combineReducers<SeriesState>({
  menu: menuReducer,
  general: generalReducer,
  tabs: tabsReducer,
  editData: dataEditReducer,
  search: searchReducer,
  config: configuracionesSeries
})
