import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EdicionPayload } from '../../../store/types'
import {
  ClientesDatosEdicion,
  DatosEdicionSucursal,
  DatosEdicionDatosGenerales,
  TDatosPersona,
  DatosEdicionVentasCliente,
  DatosEdicionHistoricosCliente,
  DatosEdicionAdicionales,
  DatosEdicionRed,
  DatosEdicionLineas,
  DatosEdicionGrupos,
  DatosEdicionMarcas,
  DatosEdicionUafe,
  DatosEdicionContribuyente,
} from '../types/types'
import { CustomDictionary } from '../../../../../store/types'
import { clearDataEdition } from '../../../../../hooks/useNavigationTabControls'

const initialState: CustomDictionary<ClientesDatosEdicion> = {}

const datosEdicionClientesSlice = createSlice({
  name: 'clientesDatosEdicion',
  initialState: initialState,
  reducers: {
    clearDataEdition,
    updateLoader(
      state,
      action: PayloadAction<{
        key: string
        loader: { mensaje: string; show: boolean }
      }>,
    ) {
      state[action.payload.key].loader.mensaje = action.payload.loader.mensaje
      state[action.payload.key].loader.show = action.payload.loader.show
    },
    setMuestraError(
      state,
      action: PayloadAction<{
        key: number | string
        tieneError: boolean
        mensajeError: string
      }>,
    ) {
      state[action.payload.key].tieneError = action.payload.tieneError
      state[action.payload.key].mensajeError = action.payload.mensajeError
    },
    setMuestraErrores(
      state,
      action: PayloadAction<{
        key: number | string
        tieneError: boolean
        mensajesErrors: Array<string> | []
      }>,
    ) {
      state[action.payload.key].tieneError = action.payload.tieneError
      state[action.payload.key].mensajesErrors = action.payload.mensajesErrors
    },
    setDatosEdicion(
      state,
      action: PayloadAction<EdicionPayload<ClientesDatosEdicion>>,
    ) {
      state[action.payload.key] = action.payload.data
    },
    initDatosEdicion(
      state,
      action: PayloadAction<EdicionPayload<ClientesDatosEdicion>>,
    ) {
      if (!state[action.payload.key]) {
        state[action.payload.key] = action.payload.data
      }
    },
    setTabContribuyente(
      state,
      action: PayloadAction<EdicionPayload<DatosEdicionContribuyente>>,
    ) {
      state[action.payload.key].tabs.contribuyente = action.payload.data
    },
    // edition info tab general
    setTabDatosGenerales(
      state,
      action: PayloadAction<EdicionPayload<DatosEdicionDatosGenerales>>,
    ) {
      state[action.payload.key].tabs.generales = action.payload.data
    },
    setTabPerson(
      state,
      action: PayloadAction<{
        key: string
        datosPersona: TDatosPersona
      }>,
    ) {
      state[action.payload.key].tabs.generales.direccion =
        action.payload.datosPersona.direccion
      state[action.payload.key].tabs.generales.email =
        action.payload.datosPersona.email
      state[action.payload.key].tabs.contribuyente.nombre =
        action.payload.datosPersona.nombre
      state[action.payload.key].tabs.contribuyente.razonComercial =
        action.payload.datosPersona.razonComercial
      state[action.payload.key].tabs.generales.telefonos =
        action.payload.datosPersona.telefono
    },
    setTabResetPerson(
      state,
      action: PayloadAction<{
        key: string
      }>,
    ) {
      state[action.payload.key].tabs.generales.direccion = ''
      state[action.payload.key].tabs.generales.email = ''
      state[action.payload.key].tabs.contribuyente.nombre = ''
      state[action.payload.key].tabs.contribuyente.razonComercial = ''
      state[action.payload.key].tabs.generales.telefonos = []
    },
    // edition info tab sucursal
    setTabSucursales(
      state,
      action: PayloadAction<EdicionPayload<DatosEdicionSucursal>>,
    ) {
      state[action.payload.key].tabs.sucursales = action.payload.data
    },
    setTabVentas(
      state,
      action: PayloadAction<EdicionPayload<DatosEdicionVentasCliente>>,
    ) {
      state[action.payload.key].tabs.ventas = action.payload.data
    },
    setTabHistorico(
      state,
      action: PayloadAction<EdicionPayload<DatosEdicionHistoricosCliente>>,
    ) {
      state[action.payload.key].tabs.historico = action.payload.data
    },
    setTabAdicionales(
      state,
      action: PayloadAction<EdicionPayload<DatosEdicionAdicionales>>,
    ) {
      state[action.payload.key].tabs.adicionales = action.payload.data
    },
    setTabRed(state, action: PayloadAction<EdicionPayload<DatosEdicionRed>>) {
      state[action.payload.key].tabs.red = action.payload.data
    },
    setTabLineas(
      state,
      action: PayloadAction<EdicionPayload<DatosEdicionLineas>>,
    ) {
      state[action.payload.key].tabs.lineas = action.payload.data
    },
    setTabGrupos(
      state,
      action: PayloadAction<EdicionPayload<DatosEdicionGrupos>>,
    ) {
      state[action.payload.key].tabs.grupos = action.payload.data
    },
    setTabMarcas(
      state,
      action: PayloadAction<EdicionPayload<DatosEdicionMarcas>>,
    ) {
      state[action.payload.key].tabs.marcas = action.payload.data
    },
    setTabUaf(state, action: PayloadAction<EdicionPayload<DatosEdicionUafe>>) {
      state[action.payload.key].tabs.uafe = action.payload.data
    },
  },
})

export const {
  setTabResetPerson,
  setMuestraErrores,
  setMuestraError,
  setDatosEdicion,
  initDatosEdicion,
  updateLoader,
  clearDataEdition: clearDatosEdicion,
  setTabContribuyente,
  setTabDatosGenerales,
  setTabPerson,
  setTabSucursales,
  setTabVentas,
  setTabHistorico,
  setTabAdicionales,
  setTabRed,
  setTabLineas,
  setTabGrupos,
  setTabMarcas,
  setTabUaf,
} = datosEdicionClientesSlice.actions
export const datosEdicionClientesReducer = datosEdicionClientesSlice.reducer
