import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FETCH_STATUS } from '../../../../../store/types'
import {
  FilterItemsState,
  SearchState,
  optionsSearch,
  ItemsListResult,
  optionsMovilWeb,
  optionsImages,
  optionsTypes,
  optionsStatus,
  optionsGroups,
} from '../types/types'
import { OptionTiposImpuestos } from '../../../../componentes/tiposImpuestosLookup/types/types'
import { LocalOption } from '../../../../componentes/localesLookUp/store/type'
import { TipoPropiedad } from '../../../../componentes/propiedadesLookUp/types/types'

export const initialFilter: FilterItemsState = {
  busquedaIncremental: false,
  inicioFinOpt: optionsSearch[0],
  movilweb: optionsMovilWeb[0],
  imagen: optionsImages[0],
  codigo: '',
  barras: '',
  linea: '',
  nombre: '',
  grupo: '',
  marca: '',
  generico: '',
  aplicacion: '',
  codProv: '',
  tipoImpuesto: null,
  precioDesde: null,
  precioHasta: null,
  modelo: '',
  local: null,
  tipoItem: optionsTypes[0],
  estadoItem: optionsStatus[0],
  existencia: false,
  aplicaciones: false,
  lotes: false,
  agrupador: optionsGroups[0],
  noSincronizados: false,
}

const initialState: SearchState<ItemsListResult> = {
  filter: initialFilter,
  status: FETCH_STATUS.IDDLE,
  message: '',
  resultList: [],
  pageIndex: 0,
  pageSize: 20,
  tabs: ['Lista', 'Lista Extendida'],
  currentTab: 'Lista',
  totalCount: 0,
  properties: null,
  selectedItem: null,
  searchItem: false,
}

const searchSlice = createSlice({
  name: 'searchItems',
  initialState: initialState,
  reducers: {
    setFilter(state, action: PayloadAction<FilterItemsState>) {
      state.filter.busquedaIncremental = action.payload.busquedaIncremental
      state.filter.inicioFinOpt = action.payload.inicioFinOpt
      state.filter.movilweb = action.payload.movilweb
      state.filter.imagen = action.payload.imagen
      state.filter.codigo = action.payload.codigo
      state.filter.barras = action.payload.barras
      state.filter.linea = action.payload.linea
      state.filter.nombre = action.payload.nombre
      state.filter.grupo = action.payload.grupo
      state.filter.marca = action.payload.marca
      state.filter.generico = action.payload.generico
      state.filter.aplicacion = action.payload.aplicacion
      state.filter.codProv = action.payload.codProv
      state.filter.precioDesde = action.payload.precioDesde
      state.filter.precioHasta = action.payload.precioHasta
      state.filter.modelo = action.payload.modelo
      state.filter.tipoItem = action.payload.tipoItem
      state.filter.estadoItem = action.payload.estadoItem
      state.filter.existencia = action.payload.existencia
      state.filter.aplicaciones = action.payload.aplicaciones
      state.filter.lotes = action.payload.lotes
      state.filter.agrupador = action.payload.agrupador
      state.filter.noSincronizados = action.payload.noSincronizados
    },
    setTaxFilter(
      state,
      action: PayloadAction<{
        tax: OptionTiposImpuestos
      }>,
    ) {
      state.filter.tipoImpuesto = action.payload.tax
    },
    setStoreFilter(
      state,
      action: PayloadAction<{
        store: LocalOption
      }>,
    ) {
      state.filter.local = action.payload.store
    },
    setPaging(
      state,
      action: PayloadAction<{
        pageIndex: number
        pageSize: number
      }>,
    ) {
      state.pageIndex = action.payload.pageIndex
      state.pageSize = action.payload.pageSize
    },
    setSearchItem(state, action: PayloadAction<boolean>) {
      state.searchItem = action.payload
    },
    setTotalCount(state, action: PayloadAction<number>) {
      state.totalCount = action.payload
    },
    setDataGridResult(state, action: PayloadAction<Array<ItemsListResult>>) {
      state.resultList = action.payload
    },
    setCleanResult(state) {
      state.resultList = null
    },
    setProperties(state, action: PayloadAction<Array<TipoPropiedad>>) {
      state.properties = action.payload
    },
    setSelectedItem(state, action: PayloadAction<ItemsListResult>) {
      state.selectedItem = action.payload
    },
    changeFilter(state, action: PayloadAction<FilterItemsState>) {
      state.filter = action.payload
    },
    changeCurrentSearchTab(state, action: PayloadAction<string>) {
      state.currentTab = action.payload
    },
  },
})

export const {
  setFilter,
  changeFilter,
  setPaging,
  setSearchItem,
  setTotalCount,
  setDataGridResult,
  setCleanResult,
  setProperties,
  setSelectedItem,
  setTaxFilter,
  setStoreFilter,
  changeCurrentSearchTab,
} = searchSlice.actions

export const searchReducer = searchSlice.reducer
