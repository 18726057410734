import React from 'react'
import { CCol, CRow } from '@coreui/react-pro'
import { useSelector } from 'react-redux'
import {
  Column,
  DataGrid,
  FilterRow,
  HeaderFilter,
  Summary,
  TotalItem,
  Paging,
  Pager,
} from 'devextreme-react/data-grid'
import { RootState } from '../../../../../store/store'
import { utilidades } from '../../../../../helpers/utilidades'
import { getAllowedPageSizes } from '../../../../../helpers/Helper'

interface TablaListadoComprobantesProps extends React.PropsWithChildren {
  onSelected: (data) => void
  tipo: string
}

const TablaListadoComprobantesPrv = (props: TablaListadoComprobantesProps) => {
  const { onSelected, tipo } = props

  const registros = useSelector(
    (state: RootState) =>
      state.ventas.cuadres.modalListadoComprobantes.registros,
  )

  const [data, setData] = React.useState<Array<any>>([])
  const [displayMode] = React.useState<string | 'full' | 'compact'>('full')
  const [showPageSizeSelector] = React.useState<boolean>(true)
  const [showInfo] = React.useState<boolean>(true)
  const [showNavButtons] = React.useState<boolean>(true)

  React.useEffect(() => {
    if (registros.length > 0) {
      llenarData(registros)
    } else {
      setData([])
    }
  }, [registros])

  const llenarData = (data) => {
    const item: any = []
    let contar: number = 0
    try {
      data.map(function (key, i) {
        let itemAdd: any = {}
        itemAdd = {
          ...key,
          ORDEN: Number(i),
        }
        contar = contar + 1
        item.push(itemAdd)
      })
      setData(item.map((x) => utilidades.unfreeze(x)))
    } catch (error) {
      console.error('on llenarData', error)
    }
  }

  const onRowDoubleClick = React.useCallback(
    (e) => {
      const { data } = e
      onSelected(data)
    },
    [onSelected],
  )

  return (
    <>
      <CRow>
        <CCol className="d-flex justify-content-start">
          <label>{'Comprobantes sin cargar ' + tipo}</label>
        </CCol>
      </CRow>
      <CRow>
        <CCol className="d-flex justify-content-end">{data.length}</CCol>
      </CRow>
      <CRow>
        <CCol className="d-flex justify-content-center">
          <DataGrid
            selection={{ mode: 'single' }}
            hoverStateEnabled={true}
            dataSource={data}
            showBorders={true}
            showRowLines={true}
            onRowDblClick={onRowDoubleClick}
            allowColumnReordering={true}
            allowColumnResizing={true}
            columnAutoWidth={true}
          >
            <FilterRow visible={true} />
            <HeaderFilter visible={true} />
            <Paging defaultPageSize={10} />
            <Pager
              visible={data.length > 0 ? true : false}
              allowedPageSizes={getAllowedPageSizes(data)}
              displayMode={displayMode}
              showPageSizeSelector={showPageSizeSelector}
              showInfo={showInfo}
              showNavigationButtons={showNavButtons}
            />
            <Column
              dataType="string"
              dataField="ventaFecha"
              caption="Fecha"
              width="15%"
            />
            <Column
              dataType="string"
              dataField="ventaNumero"
              caption="Nro."
              width="15%"
            />
            <Column
              dataType="number"
              dataField="clienteNombre"
              caption="Proveedor"
              width={'25%'}
            />
            <Column dataField="ventaTotal" caption="Total" width="15%" />
            <Summary>
              <TotalItem
                column="ventaTotal"
                summaryType="sum"
                valueFormat="currency"
              />
            </Summary>
          </DataGrid>
        </CCol>
      </CRow>
    </>
  )
}

export default TablaListadoComprobantesPrv
