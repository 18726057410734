import React, { useEffect } from 'react'
import {
  AccionMenu,
  CustomButtons,
  FETCH_STATUS,
} from '../../../../../store/types'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../../../store/store'
import { Aplicacion } from '../../../store/types'
import { utilidades } from '../../../../../helpers/utilidades'
import {
  buttonClick,
  changeCurrentTab,
  closeTabAtributos,
  openTab,
  setButtons,
  setCurrentExecutingAction,
} from './store/tabsReducer'
import { ButtonTypes } from '../../../../../views/componentes/globalMenu/types'
import { v4 as uuidv4 } from 'uuid'
import { deleteEditData, initDatosEdicion } from './store/editDataReducer'
import Dialog from '../../../../../views/componentes/librerias/bootstrap-dialog'
import {
  useButtonDisabled,
  useMenuOptions,
} from '../../../../../hooks/useMenuButtonControls'
import AtributosMain from './components/shared/main'
import { EAplicationsAcatha } from '../../../../../store/enumsAplication'
import { setMenuButtons } from './store/menuReducer'
import { defaultDataSheet } from './components/nuevo'
import { deleteEditDataBackup, setCodigoModulo } from './store/configReducer'

const pathVerificador = '/propiedades_item'

interface AtributosProps extends React.PropsWithChildren {
  onSendData?: (data: any) => void
  modal?: boolean
}

export const optionsActivos = [
  { value: 1, label: 'Activos' },
  { value: 2, label: 'Inactivos' },
]

const Atributos: React.FunctionComponent<AtributosProps> = (props) => {

  const { onSendData, modal } = props

  const dispatch = useDispatch()
  const menuState = useSelector((state: RootState) => state.global.menu)
  const tabs = useSelector((state: RootState) => state.inventarios.catalogos.atributos.tabs)
  const searchStatus = useSelector((state: RootState) => state.inventarios.catalogos.atributos.search.status)
  const searchResults = useSelector((state: RootState) => state.inventarios.catalogos.atributos.search.resultados)
  const selected = useSelector((state: RootState) => state.inventarios.catalogos.atributos.search.seleccionado)

  const dialogRef = React.useRef<any>(null)
  const getButtonDisabled = useButtonDisabled(tabs)
  const { getMenuOptions, options } = useMenuOptions()


  const onTabClosing = React.useCallback(
    async (index) => {
      const closedTab = tabs.tabs[index]
      dispatch(closeTabAtributos(closedTab.tabKey ?? index))
      await utilidades.sleep(50)
      dispatch(deleteEditData(closedTab.tabKey ?? index))
      dispatch(deleteEditDataBackup(closedTab.tabKey ?? index))
    },
    [dispatch, tabs.tabs],
  )

  const onTabChanged = React.useCallback(
    (tabKey: string) => {
      dispatch(changeCurrentTab(tabKey))
    },
    [dispatch],
  )


  const onMenuButtonClick = React.useCallback(
    async (action: AccionMenu, tab: string = "") => {
      const id = uuidv4()
      switch (action.actionType) {
        case ButtonTypes.find:
          if (tabs.current === 'BUSQUEDA') {
            dispatch(buttonClick({
              tabKey: tab === "" ? tabs.current : tab,
              button: action.actionType
            }))
          } else dispatch(changeCurrentTab('BUSQUEDA'))
          break
        case ButtonTypes.new:
          dispatch(
            initDatosEdicion({
              key: id,
              data: {
                ...defaultDataSheet,
                loading: true
              },
            }),
          )
          dispatch(openTab({ key: id }))
          break
        case ButtonTypes.save:
          if (tabs.current !== 'BUSQUEDA')
            dispatch(buttonClick({
              tabKey: tab === "" ? tabs.current : tab,
              button: action.actionType
            }))
          break
        case ButtonTypes.edit:
          if (tabs.current === 'BUSQUEDA') {
            dispatch(buttonClick({
              tabKey: tab === "" ? tabs.current : tab,
              button: action.actionType
            }))
          }
          break
        case ButtonTypes.delete:
          if (tabs.current === 'BUSQUEDA') {
            dispatch(buttonClick({
              tabKey: tab === "" ? tabs.current : tab,
              button: action.actionType
            }))
          }
          break
        case ButtonTypes.broom:
          dispatch(buttonClick({
            tabKey: tab === "" ? tabs.current : tab,
            button: action.actionType
          }))
          break
        case ButtonTypes.undo:
          dispatch(buttonClick({
            tabKey: tab === "" ? tabs.current : tab,
            button: action.actionType
          }))
          break
        case ButtonTypes.print:
          dispatch(buttonClick({
            tabKey: tab === "" ? tabs.current : tab,
            button: action.actionType
          }))
          break
        case ButtonTypes.print_design:
          dispatch(buttonClick({
            tabKey: tab === "" ? tabs.current : tab,
            button: action.actionType
          }))
          break
        default:
          break
      }
    },
    [dispatch, tabs],
  )

  const onGetPupUpData = React.useCallback(() => {
    if (selected.row !== null && selected.row !== undefined) {
      const data = selected.row
      onSendData(data)
    }
  }, [selected, onSendData])

  useEffect(() => {
    if (modal !== null && modal !== undefined && modal) {
      onGetPupUpData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected.row, modal])


  useEffect(() => {
    if (menuState.length > 0 && options.length === 0) {
      getMenuOptions(menuState, 'to', pathVerificador)
    }
  }, [menuState, getMenuOptions, options])

  React.useEffect(() => {
    dispatch(
      setButtons({
        tabKey: 'BUSQUEDA',
        buttons: {
          Nuevo: true,
          Guardar: false,
          Editar: selected.row !== null && selected.row !== undefined,
          Eliminar: selected.row !== null && selected.row !== undefined,
          Buscar: true,
          Imprimir: true,
          Limpiar: true,
          Deshacer: true,
        },
      }),
    )
  }, [dispatch, selected.row])

  const onSearchExecutingAction = React.useCallback((tabKey: string, type?: ButtonTypes) => {
    dispatch(setCurrentExecutingAction({
      tabKey: tabKey,
      buttonType: type
    }))
  }, [dispatch]);

  const onActionsButtonsLoaded = React.useCallback((actions: Array<AccionMenu>) => {
    dispatch(setMenuButtons(actions));
  }, [dispatch])

  const onGetCode = React.useCallback(() => {
    dispatch(setCodigoModulo(parseInt(EAplicationsAcatha.Atributos)))
  }, [dispatch])



  React.useEffect(() => {
    onGetCode()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div>
        <Dialog ref={dialogRef} />
      </div>
      <AtributosMain
        menu={{ loading: false, accions: options }}
        tabs={tabs}
        onTabChanged={onTabChanged}
        codigoModulo={parseInt(EAplicationsAcatha.Atributos)}
        opciones={OpcionesModeulo}
        onActionsButtonsLoaded={onActionsButtonsLoaded}
        onMenuButtonClick={onMenuButtonClick}
        searching={searchStatus === FETCH_STATUS.LOADING}
        ontabClosing={onTabClosing}
        hayResultadosBusqueda={searchResults.length > 0}
        verificarPuntoVenta
        verificarAutorizacion
        onExecutingAction={onSearchExecutingAction}
        tipoAplicacion={Aplicacion.catalogosAtributos}
      />
    </>
  )
}

export default React.memo(Atributos)
const OpcionesModeulo = [parseInt(EAplicationsAcatha.Aplicaciones)];


export const ModuleButtons: CustomButtons = {
  Nuevo: true,
  Guardar: true,
  Editar: false,
  Eliminar: false,
  Buscar: false,
  Imprimir: false,
  Limpiar: false,
  Deshacer: true,
}

