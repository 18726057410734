import React, { useEffect } from 'react'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput'
import { Button, DataGrid, NumberBox, TextBox } from 'devextreme-react'
import { CombinacionesDatosEdicion } from '../../types/types'
import { ButtonTypes } from '../../../../../../../views/componentes/globalMenu/types'
import { clearButtonClick, openTab } from '../../store/tabsReducer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import {
  changeLoaderCombination,
  setCurrentFunction,
} from '../../store/generalReducer'
import { initDatosEdicion } from '../../store/editDataReducer'
import Dialog from '../../../../../../../views/componentes/librerias/bootstrap-dialog'
import { v4 as uuidv4 } from 'uuid'
import { defaultDataInventory } from '../..'
import { TabState } from '../../../../../../../store/genericTabTypes'
import { CCol, CRow } from '@coreui/react-pro'
import { Column } from 'devextreme-react/data-grid'
import { footerStyles } from '../../../ingresosEgresos/components/nuevo'

interface INewProps extends React.PropsWithChildren {
  tab: TabState<CombinacionesDatosEdicion>
  tabId: string
}

const Nuevo: React.FunctionComponent<INewProps> = (props) => {
  const { tab, tabId } = props
  const dispatch = useDispatch()
  const currentAction = useSelector(
    (state: RootState) =>
      state.inventarios.movimientos.combinaciones.general.currentFunction,
  )
  const unitsState = useSelector((state: RootState) => {
    return state.inventarios.movimientos.combinaciones.editData[tabId]
  })
  const tabs = useSelector((state: RootState) => {
    return state.inventarios.movimientos.combinaciones.tabs
  })

  const [confirmNew, setonfirmNew] = React.useState<boolean>(false)
  const validationGroupRef = React.useRef<any>()
  const dialogRef = React.useRef<any>(null)
  const inventoryDatagrid = React.useRef<any>()

  const playLoader = React.useCallback(
    (message = 'Cargando...') => {
      dispatch(changeLoaderCombination({ show: true, mensaje: message }))
    },
    [dispatch],
  )

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoaderCombination({ show: false, mensaje: '' }))
  }, [dispatch])

  const onConfirmNew = React.useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea crear un nuevo registro?`,
      actions: [
        Dialog.Action(
          <span>
            <u>P</u>estaña Actual
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-success',
          'p',
        ),
        Dialog.Action(
          <span>
            <u>N</u>ueva Pestaña
          </span>,
          (dialog) => {
            dialog.hide()
            const id = uuidv4()
            dispatch(
              initDatosEdicion({
                key: id,
                data: defaultDataInventory,
              }),
            )
            dispatch(openTab({ key: id }))
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setonfirmNew(false)
    return
  }, [dispatch])

  React.useEffect(() => {
    if (confirmNew) {
      onConfirmNew()
    }
  }, [confirmNew, onConfirmNew])

  const validateState = React.useCallback((selectedState: any) => {
    if (selectedState.value.codigo > -1) {
      return Promise.resolve()
    } else {
      return Promise.reject('Estado: Debe seleccionar una opción')
    }
  }, [])

  const onHandleSave = React.useCallback(async () => {
    console.log('implements handlle to SAVE ')
  }, [])

  const onHandleUndo = React.useCallback(() => {
    console.log('implements handlle to UNDO ')
  }, [])

  const handleButtonClick = React.useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.save:
          if (tabs.current === tabId) onHandleSave()
          break
        case ButtonTypes.new:
          if (tabs.current === tabId) setonfirmNew(true)
          break
        case ButtonTypes.undo:
          if (tabs.current === tabId) onHandleUndo()
          break
        default:
          break
      }
      dispatch(setCurrentFunction(''))
      dispatch(clearButtonClick(tabId))
    },
    [dispatch, onHandleSave, onHandleUndo, tabId, tabs],
  )

  useEffect(() => {
    if (currentAction !== '') handleButtonClick(currentAction)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAction])

  return (
    <RowContainer className="m-2">
      <Dialog ref={dialogRef} />
      <RowContainer>
        <CustomCol xs="12" md="2">
          <Labeled label="Número:">
            <NumberBox
              value={null}
              showClearButton
              //onValueChanged={onChangedName}
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="2">
          <Labeled label="Nombre:">
            <TextBox
              value={''}
              //onValueChanged={onChangedName}
              showClearButton
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="6">
          <Labeled label="Resultado:">
            <CRow>
              <CCol xs="12" md="3">
                <TextBox
                  value={''}
                  //onValueChanged={onChangedName}
                  showClearButton
                />
              </CCol>
              <CCol xs="12" md="7">
                <TextBox
                  value={''}
                  //onValueChanged={onChangedName}
                  showClearButton
                />
              </CCol>
              <CCol xs="12" md="2">
                <Button
                  id="btnAdd"
                  className="me-1"
                  icon="add"
                  stylingMode="contained"
                  type="default"
                  onClick={() => alert('open modal ITEMS')}
                />
              </CCol>
            </CRow>
          </Labeled>
        </CustomCol>
      </RowContainer>
      <Labeled label="Componentes:" />
      <RowContainer>
        <DataGrid
          style={{ marginTop: '10px' }}
          ref={inventoryDatagrid}
          keyExpr="codigo"
          //focusedRowKey="codigo"
          id="tabNewInictialInventory"
          selection={{ mode: 'single' }}
          dataSource={[]}
          showColumnLines={true}
          showRowLines={true}
          showBorders={true}
          columnResizingMode="widget"
          //focusedRowEnabled={true}
          ///onSelectionChanged={onSelectedLineChanged}
          loadPanel={{
            enabled: true,
            height: 90,
            indicatorSrc: '',
            shading: false,
            shadingColor: '',
            showIndicator: true,
            showPane: true,
            text: 'Cargando...',
            width: 200,
          }}
        >
          <Column dataField="" caption="Interno" width="" />
          <Column dataField="" caption="Barras" width="" />
          <Column dataField="" caption="Descripción" width="" />
        </DataGrid>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="4">
          <CRow>
            <div style={footerStyles}>
              Item:
              <TextBox
                //value={dataToEditCuentasContables.venta.descripcion}
                showClearButton={true}
                width="63%"
                inputAttr={{ autocomplete: 'nope' }}
              />
              <Button
                id="btnAdd"
                className="me-1"
                icon="add"
                stylingMode="contained"
                type="default"
                onClick={() => alert('open modal PROVEEDORES')}
              />
            </div>
          </CRow>
          <CRow>
            <div style={footerStyles}>
              Interno:
              <TextBox
                //value={dataToEditCuentasContables.venta.descripcion}
                showClearButton={true}
                width="80%"
                inputAttr={{ autocomplete: 'nope' }}
              />
            </div>
          </CRow>
          <CRow>
            <CCol>
              <div style={footerStyles}>
                Stock:
                <TextBox
                  //value={dataToEditCuentasContables.venta.descripcion}
                  showClearButton={true}
                  width="75px"
                  inputAttr={{ autocomplete: 'nope' }}
                />
              </div>
            </CCol>
            <CCol>
              <div style={footerStyles}>
                Unidad:
                <TextBox
                  //value={dataToEditCuentasContables.venta.descripcion}
                  showClearButton={true}
                  width="75px"
                  inputAttr={{ autocomplete: 'nope' }}
                />
              </div>
            </CCol>
          </CRow>
          <CRow>
            <div
              style={{
                display: 'flex',
                marginTop: '4px',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                id="btnAdd"
                className="me-1"
                icon="add"
                stylingMode="contained"
                type="default"
                onClick={() => {}}
              />
              <Button
                id="btnAdd"
                className="me-1"
                icon="minus"
                stylingMode="contained"
                type="default"
                onClick={() => {}}
              />
            </div>
          </CRow>
        </CustomCol>
      </RowContainer>
    </RowContainer>
  )
}

export default Nuevo
