import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ConfiguracionesOrdenPedido } from '../../../type/types'
import { RootState } from '../../../../../../../store/store'

const initialState: ConfiguracionesOrdenPedido = {
  ordenPedidoSeleccionado: null,
  usaDescuentoGlobal: false,
}

const configuracionesProformaSlice = createSlice({
  name: 'configuraciones',
  initialState: initialState,
  reducers: {
    seleccionarCuadre(state, acion: PayloadAction<any>) {
      state.ordenPedidoSeleccionado = acion.payload
    },
    resetSeleccioCuadre(state) {
      state.ordenPedidoSeleccionado = null
    },
    toogleUsaDescuentoGlobal(state, acion: PayloadAction) {
      state.usaDescuentoGlobal = !state.usaDescuentoGlobal
    },
  },
})
export const selectUsaDescuentoGlobal = (state: RootState) => {
  return state.ventas.proformas.ordenProforma.configuraciones.usaDescuentoGlobal
}

export const configuracionesProformaReducer =
  configuracionesProformaSlice.reducer

export const {
  seleccionarCuadre,
  resetSeleccioCuadre,
  toogleUsaDescuentoGlobal,
} = configuracionesProformaSlice.actions
