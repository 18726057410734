import { CNav, CNavItem, CNavLink, CTabContent, CTabPane } from '@coreui/react-pro'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import { setChangeTab } from '../../../store/searchReducer'
import { PersonalDatosEdicion, PersonalNuevoTabs } from '../../../types/types'
import { TabState } from '../../../../../../../store/genericTabTypes'
import { DocumentInfo } from '../../../../../../../store/types'
import Personales from './personales'
import Estudios from './estudios'
import Extras from './extras'
import Login from './login'
import Rentas from './rentas'
import Normas from './normas'

interface ITabsProps extends React.PropsWithChildren {
  info: DocumentInfo<PersonalDatosEdicion>
  tab: TabState<PersonalDatosEdicion>
  tabId: string
  refForm: any
}

const TabsEdition: React.FunctionComponent<ITabsProps> = (props) => {
  const { tabId, tab, info, refForm } = props
  const dispatch = useDispatch()
  const currentTab = useSelector((state: RootState) => state.nomina.personal.search.currentTab)
  const tabsNames = useSelector((state: RootState) => state.nomina.personal.search.tabs)
  return (
    <>
      <CNav variant="tabs" role="tablist" className="mt-4">
        <CNav variant="tabs">
          {tabsNames.map((tab, index) => (
            <CNavItem key={index}>
              <CNavLink
                onClick={() => {
                  if (info?.codigo === 0) {
                    if (tab !== PersonalNuevoTabs.normas && tab !== PersonalNuevoTabs.rentas) {
                      dispatch(setChangeTab(tab))
                    }
                  } else {
                    dispatch(setChangeTab(tab))
                  }
                }}
                active={currentTab === tab}
              >
                {tab}
              </CNavLink>
            </CNavItem>
          ))}
        </CNav>
        <CTabContent
          style={{
            overflowY: 'unset',
            width: '100%',
            marginBottom: '10px',
          }}
        >
          <CTabPane
            role="tabpanel"
            aria-labelledby="generales-tab"
            visible={currentTab === tabsNames[0]}
          >
            <Personales tabId={tabId} refForm={refForm} tab={tab} info={info} />
          </CTabPane>
          <CTabPane
            role="tabpanel"
            aria-labelledby="generales-tab"
            visible={currentTab === tabsNames[1]}
          >
            <Estudios tabId={tabId} refForm={refForm} tab={tab} info={info} />
          </CTabPane>
          <CTabPane
            role="tabpanel"
            aria-labelledby="generales-tab"
            visible={currentTab === tabsNames[2]}
          >
            <Extras tabId={tabId} refForm={refForm} tab={tab} info={info} />
          </CTabPane>
          <CTabPane
            role="tabpanel"
            aria-labelledby="generales-tab"
            visible={currentTab === tabsNames[3]}
          >
            <Login tabId={tabId} refForm={refForm} tab={tab} info={info} />
          </CTabPane>
          <CTabPane
            role="tabpanel"
            aria-labelledby="generales-tab"
            visible={currentTab === tabsNames[4]}
          >
            <Rentas tabId={tabId} refForm={refForm} tab={tab} info={info} />
          </CTabPane>
          <CTabPane
            role="tabpanel"
            aria-labelledby="generales-tab"
            visible={currentTab === tabsNames[5]}
          >
            <Normas tabId={tabId} refForm={refForm} tab={tab} info={info} />
          </CTabPane>

        </CTabContent>
      </CNav>
    </>
  )
}

export default TabsEdition