// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: process.env.REACT_APP_aws_project_region,
  aws_cognito_region: process.env.REACT_APP_aws_cognito_region,
  aws_user_pools_id: process.env.REACT_APP_aws_user_pools_id,
  aws_user_pools_web_client_id:
    process.env.REACT_APP_aws_user_pools_web_client_id,
}

// {
//   aws_project_region: "us-west-2",
//   aws_cognito_identity_pool_id: "us-west-2_QJ1DBL2jv",
//   aws_cognito_region: "us-west-2",
//   aws_user_pools_id: "us-west-2_QJ1DBL2jv",
//   aws_user_pools_web_client_id: "6qulncsd08t8knqntgvmrge3n4",
//   oauth: {},
//   aws_cognito_username_attributes: ["EMAIL"],
//   aws_cognito_social_providers: [],
//   aws_cognito_signup_attributes: ["EMAIL"],
//   aws_cognito_mfa_configuration: "OFF",
//   aws_cognito_mfa_types: ["SMS"],
//   aws_cognito_password_protection_settings: {
//     passwordPolicyMinLength: 8,
//     passwordPolicyCharacters: []
//   },
//   aws_cognito_verification_mechanisms: ["EMAIL"]
// }

export default awsmobile
