import { RequestHelper } from '../../../../../helpers/requestHelper'
import { PaginationInfo } from '../../../../../store/types'
import {
  ActividadesEconomicasListado,
  FiltroBusquedaActivadadesEconomicasState,
} from '../store/types'

export const ActividadesEconomicasService = {
  getActividadEconomica,
  getAllActividadesEconomicas,
  saveActividadesEconomicas,
  deleteStore,
}

async function getActividadEconomica(id: any): Promise<any> {
  try {
    const query = {
      codigo: id,
    }

    return await RequestHelper.get<any>('businessType', 'get', '', query);
  } catch (error) {
    return error
  }
}

async function getAllActividadesEconomicas(
  filtro: FiltroBusquedaActivadadesEconomicasState,
): Promise<Array<ActividadesEconomicasListado>> {
  try {
    const query = buildQuery(filtro)
    const pagedApiResult = await RequestHelper.getAll<any>('businessType', 'getAll', '', query);
    pagedApiResult.auto = pagedApiResult.auto ? parseActividadesEconomicas(pagedApiResult.auto) : [];
    return pagedApiResult;
  } catch (error) {
    return error;
  }
}

async function saveActividadesEconomicas(formData: any): Promise<any> {
  try {
    const body = {
      infoRegistro: {
        ...formData,
      }
    }

    return await RequestHelper.postAll<any>('businessType', 'save', body);
  } catch (error) {
    return error;
  }
}

async function deleteStore(id: any): Promise<void> {
  try {
    const body = {
      codigo: id
    }

    return await RequestHelper.deleteRequest<any>(
      'businessType',
      'inactive',
      '',
      body
    );
  } catch (error) {
    return error
  }
}

// Private
function buildQuery(
  filtro: FiltroBusquedaActivadadesEconomicasState,
  pagination?: PaginationInfo,
): any {

  const query: any = {
    ...(filtro.descripcion && { descripcion: filtro.descripcion }),
    ...(filtro.codigoTributario && { codigoTributario: filtro.codigoTributario }),
    ...(filtro.codigoPais && { codigoPais: filtro.codigoPais.codigo }),
  }

  if (pagination) {
    query.page = pagination.current
    query.limit = pagination.size
    query.isPaged = true
  }

  const filters = ['codigo', 'descripcion', 'codigoTributario', 'codigoPais']

  filters.forEach((field) => {
    if (filtro[field] && filtro[field].length > 0) {
      query[field] = filtro[field]
    }
  })

  return query
}

function parseActividadesEconomicas(dataApi: any[]): Array<ActividadesEconomicasListado> {
  return dataApi.map((item: any) => ({
    codigo: item.codigo,
    descripcion: item.descripcion,
    codigoTributario: item.codigoTributario,
    codigoPais: {
      codigo: item.codigoPais,
      nombre: '',
      uaf: ''
    },
    siglas: item.siglas,
  }))
}

