import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../../store/store'
import { ButtonTypes } from '../../../../../views/componentes/globalMenu/types'
import { InvoiceButtons } from '../../../../ventas/components/ventas/venta/venta'
import { StatesEdition, TabTypes } from '../../../../ventas/types/enums'
import {
  CustomButtons,
  CustomDictionary,
} from '../../../../ventas/types/generics'
import { RetencionCompraButtons } from '../../../components/retenciones/retencion/retencion'
import { RetencionInfo } from '../../../types/types'
import { TabsState, TabState } from '../../../../../store/genericTabTypes'

const initialState: TabsState<RetencionInfo> = {
  current: 'BUSQUEDA',
  tabs: {
    BUSQUEDA: {
      id: 0,
      canClose: false,
      editStatus: StatesEdition.doesNotApply,
      globalButtonClick: ButtonTypes.none,
      tittle: 'Búsqueda',
      type: TabTypes.busqueda,
      imprimir: false,
      tabKey: 'BUSQUEDA',
      buttons: {
        Buscar: true,
        Nuevo: true,
      },
    },
  },
}

const existeTab = (
  tabs: CustomDictionary<TabState<any>>,
  comprobante: number,
) => {
  for (const key in tabs) {
    const tab = tabs[key]
    if (tab.id === comprobante) return key
  }
}

const getNewTabKeyOnClose = (state: TabsState<any>, keyToClose: string) => {
  let newCurrent = ''
  for (const key in state.tabs) {
    if (newCurrent === '') {
      newCurrent = key
    }
    if (keyToClose === key) {
      return newCurrent
    }
  }
  return 'BUSQUEDA'
}

const tabsRetencionesComprasSlice = createSlice({
  name: 'retencionesTabs',
  initialState: initialState,
  reducers: {
    closeTab(state, action: PayloadAction<string>) {
      const newCurrent = getNewTabKeyOnClose(state, action.payload)
      state.current = newCurrent
      delete state.tabs[action.payload]
    },
    openTab(
      state,
      action: PayloadAction<{
        key: string
        retencion?: RetencionInfo
      }>,
    ) {
      if (action.payload.retencion === undefined) {
        const tab: TabState<RetencionInfo> = {
          id: -1,
          canClose: true,
          editStatus: StatesEdition.new,
          globalButtonClick: ButtonTypes.none,
          tittle: 'Nueva',
          type: TabTypes.documento,
          imprimir: false,
          tabKey: action.payload.key,
          info: {
            codigo: 0,
          },
          buttons: RetencionCompraButtons,
        }
        state.tabs[action.payload.key] = tab
        state.current = action.payload.key
      } else {
        const tabKey = existeTab(state.tabs, action.payload.retencion.codigo)
        if (tabKey) {
          state.current = tabKey
        } else {
          const tab: TabState<RetencionInfo> = {
            id: action.payload.retencion.codigo,
            tittle: `${action.payload.retencion.establecimiento}-${action.payload.retencion.puntoEmision}-${action.payload.retencion.numero}`,
            canClose: true,
            editStatus: StatesEdition.save,
            type: TabTypes.documento,
            tabKey: action.payload.key,
            info: {
              codigo: action.payload.retencion.codigo,
              info: action.payload.retencion,
            },
            globalButtonClick: ButtonTypes.none,
            imprimir: false,
            buttons: InvoiceButtons,
          }
          state.tabs[action.payload.key] = tab
          state.current = action.payload.key
        }
      }
    },
    changeEditStatus(
      state,
      action: PayloadAction<{
        tabKey: string
        estado: StatesEdition
        info?: RetencionInfo
        buttons: CustomButtons
      }>,
    ) {
      const { estado, tabKey, info, buttons } = action.payload
      state.tabs[tabKey].editStatus = estado
      state.tabs[tabKey].currentExecutingAction = undefined
      state.tabs[tabKey].buttons = buttons
      if (info) {
        state.tabs[tabKey].info = {
          codigo: info.codigo,
          info: info ?? null,
        }
        state.tabs[tabKey].id = info.codigo
        state.tabs[
          tabKey
        ].tittle = `${info.establecimiento}-${info.puntoEmision}-${info.numero}`
      }
    },
    buttonClick(
      state,
      action: PayloadAction<{ tabKey: string; button: ButtonTypes }>,
    ) {
      state.tabs[action.payload.tabKey].globalButtonClick =
        action.payload.button
    },
    clearButtonClick(state, action: PayloadAction<string>) {
      if (Object.prototype.hasOwnProperty.call(state.tabs, action.payload)) {
        state.tabs[action.payload].globalButtonClick = ButtonTypes.none
      }
    },
    changeCurrentTab(state, action: PayloadAction<string>) {
      if (Object.prototype.hasOwnProperty.call(state.tabs, action.payload)) {
        state.current = action.payload
      }
    },
    setTabInfoAsInactive(state, action: PayloadAction<string>) {
      const info = state.tabs[action.payload].info
      if (info !== undefined) {
        if (info.info) {
          info.info.estadoDescripcion = 'ANULADO'
        }
        state.tabs[action.payload].info = info
      }
    },
    setButtons(
      state,
      action: PayloadAction<{ tabKey: string; buttons: CustomButtons }>,
    ) {
      const { buttons, tabKey } = action.payload
      state.tabs[tabKey].buttons = buttons
      state.tabs[tabKey].currentExecutingAction = undefined
    },
    setCurrentExecutingAction(
      state,
      action: PayloadAction<{
        tabKey: string
        buttonType: ButtonTypes | undefined
      }>,
    ) {
      const { buttonType, tabKey } = action.payload
      state.tabs[tabKey].currentExecutingAction = buttonType
    },
  },
})

export const selectTabsRetenciones = (state: RootState) => {
  return state.compras.retenciones.tabs
}

export const {
  closeTab,
  openTab,
  changeCurrentTab,
  changeEditStatus,
  clearButtonClick,
  buttonClick,
  setButtons,
  setCurrentExecutingAction,
  setTabInfoAsInactive,
} = tabsRetencionesComprasSlice.actions
export const tabsReducer = tabsRetencionesComprasSlice.reducer
