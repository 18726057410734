export enum TIPOS_BOTONES {
  BOTON_NUEVO = 'Nuevo',
  BOTON_GUARDAR = 'Guardar',
  BOTON_BUSCAR = 'Buscar',
  BOTON_ELIMINAR = 'Eliminar',
  BOTON_IMPRIMIR = 'Imprimir',
  BOTON_DESHACER = 'Deshacer',
  BOTON_EDITAR = 'Editar',
}

export type vendedorBusqueda = {
  nombres: string
  apellidos: string
  siglas: string
}

export type TVendedorIngresar = {
  codigo: number
  usuarioCodigo: number
  comision: number
  usuarioNombres: string
  usuarioApellidos: string
  siglas: string
  estadoCodigo: number
  localCodigo: number
  usuarioEmail: string
  usuarioIdentificacion: string
  usuarioTelefono: string
  tituloCodigo: number
  zona: string
}
