import { combineReducers } from "redux";
import { CalendariosState } from "../types/types";
import { calendarReducer } from "./calendarReducer";
import { tabsReducer } from "./tabsReducer";
import { configuracionesCalebdario } from "./configReducer";
import { menuReducer } from "./menuReducer";



export const CalendariosReducer = combineReducers<CalendariosState>({
  calendar: calendarReducer,
  menu: menuReducer,
  config: configuracionesCalebdario,
  tabs: tabsReducer
})