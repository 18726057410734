import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { CCard, CCardBody, CCol } from '@coreui/react-pro'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import { DataGrid } from 'devextreme-react'
import { Column, Export, Pager, Paging } from 'devextreme-react/data-grid'
import ItemsCountIndicator from '../../../../../../componentes/indicadorNroRegistros'
import { useSetToast } from '../../../../../../../hooks/useGlobalHooks'
import { TransfersServices } from '../../services/transferencias.services'
import { ToastTypes } from '../../../../../../../store/types'
import { ItemTransferencia } from '../../types/types'
import { onParseItems } from '../nuevo'
import { SmallLoadingIndicator } from '../../../../../../../views/componentes/loadingindicator/loadingindicator'
import { getAllowedPageSizes } from '../../../../../../../helpers/Helper'

const TransferDetails: React.FunctionComponent<any> = (props) => {
  const { data } = props
  const setToastMessage = useSetToast()

  const dataGrid = useRef<any>()
  const [loading, setLoading] = useState(false)
  const [details, setDetails] = useState<ItemTransferencia[]>([])

  const onGetDetailsTransfer = useCallback(async () => {
    try {
      const tarnsferDetails = await TransfersServices.getTransferDetailAll(
        data.codigo,
      )
      if (!tarnsferDetails.error && tarnsferDetails.auto) {
        setDetails(onParseItems(tarnsferDetails.auto))
        setLoading(true)
      } else if (tarnsferDetails.error)
        setToastMessage(
          'Cargar Detalles de Transferencia',
          tarnsferDetails.message,
          ToastTypes.Warning,
        )
    } catch (error) {
      setToastMessage(
        'Cargar Detalles de Transferencia',
        error,
        ToastTypes.Danger,
      )
    }
  }, [data?.codigo, setToastMessage])

  useEffect(() => {
    onGetDetailsTransfer()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {!loading ? (
        <>
          <SmallLoadingIndicator mensaje="Cargando detalles de la transferencia . . ." />
        </>
      ) : (
        <>
          <RowContainer>
            <CCol xs="12" md="12">
              <CCard
                className="border-top-primary border-top-3"
                id="busqueda-modVentas-resumen"
              >
                <CCardBody>
                  <ItemsCountIndicator items={details} />
                  <DataGrid
                    ref={dataGrid}
                    selection={{ mode: 'single' }}
                    hoverStateEnabled={true}
                    dataSource={details}
                    showBorders={true}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    columnAutoWidth={true}
                    keyExpr="codigo"
                    key="gridListItems"
                    loadPanel={{ enabled: true }}
                    repaintChangesOnly
                  >
                    <Export enabled={true} allowExportSelectedData={true} />
                    <Paging defaultPageSize={20} />
                    <Pager
                      visible={details.length > 0 ? true : false}
                      allowedPageSizes={getAllowedPageSizes(details)}
                      displayMode={'full'}
                      showPageSizeSelector={true}
                      showInfo={true}
                      showNavigationButtons={true}
                    />
                    <Column
                      dataField="codigoUsuario"
                      caption="Código"
                      width="10%"
                    />
                    <Column dataField="barras" caption="Barras" width="10%" />
                    <Column
                      dataField="descripcion"
                      caption="Descripción"
                      width="32%"
                    />
                    <Column
                      dataField="unidadDescripcion"
                      caption="Unidad"
                      width="8%"
                    />
                    <Column
                      dataField="cantidad"
                      caption="Cantidad"
                      width="8%"
                    />
                    <Column
                      dataField="referencia"
                      caption="Referencia"
                      width="12%"
                    />
                    <Column dataField="pvp" caption="PVP" width="10%" />
                    <Column dataField="pvd" caption="PVD" width="10%" />
                    <Column
                      dataField="progresoDes"
                      caption="Progreso"
                      width="12%"
                    />
                  </DataGrid>
                </CCardBody>
              </CCard>
            </CCol>
          </RowContainer>
        </>
      )}
    </>
  )
}

export default memo(TransferDetails)
