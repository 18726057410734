import React, { useCallback } from 'react'
import RowContainer from '../../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../../views/componentes/colContainer'
import { Button, DataGrid, SelectBox } from 'devextreme-react'
import { Column } from 'devextreme-react/data-grid'
import TiposPropiedadesLookUp from '../../../../../../../componentes/propiedadesLookUp'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../../store/store'
import { ItemAttributes } from '../../../../types/types'
import { TipoPropiedad } from '../../../../../../../componentes/propiedadesLookUp/types/types'
import {
  setDataAttributesToEdit,
  setPropertiesOptions,
} from '../../../../store/editDataReduce'
import { useSetToast } from '../../../../../../../../hooks/useGlobalHooks'
import { ToastTypes } from '../../../../../../../../store/types'
import { CRow } from '@coreui/react-pro'
import { isMobile } from 'react-device-detect'

const Atributos = (props) => {
  const { tabId } = props

  const dispatch = useDispatch()
  const setToastMessage = useSetToast()

  const dataToEditAttributes = useSelector(
    (state: RootState) => state.inventarios.items.editData[tabId].atributos,
  )

  const selectRef = React.useRef<any>()

  const [selectedProperty, setSelectedProperty] = React.useState<any>(null)
  const [selectedOption, setSelectedOption] = React.useState<string>('')

  const onChangedProperties = useCallback(
    (property: TipoPropiedad) => {
      if (property.opciones?.length) setSelectedOption(property.opciones[0])
      dispatch(
        setPropertiesOptions({
          key: tabId,
          property: property,
          options: property.codigo > 0 ? property.opciones : [],
        }),
      )
    },
    [dispatch, tabId],
  )

  const onAddProperty = React.useCallback(() => {
    const indexNewPropertyToAdd = dataToEditAttributes.dataGrid.findIndex(
      (property: any) => {
        return property.codigo === dataToEditAttributes.propiedad.codigo
      },
    )
    if (indexNewPropertyToAdd === -1) {
      const newAttribute = {
        codigo: dataToEditAttributes.propiedad.codigo,
        descripcion: dataToEditAttributes.propiedad.descripcion,
        valor: selectedOption,
      }
      const refreshDataTableProperty: Array<any> = structuredClone(
        dataToEditAttributes.dataGrid,
      )
      refreshDataTableProperty.push(newAttribute)
      dispatch(
        setDataAttributesToEdit({
          key: tabId,
          atributos: {
            ...dataToEditAttributes,
            dataGrid: refreshDataTableProperty,
          },
        }),
      )
    } else
      setToastMessage(
        'Agregar Propiedad',
        'La propiedad ya se encuentra agregada',
        ToastTypes.Warning,
      )
  }, [dataToEditAttributes, setToastMessage, selectedOption, dispatch, tabId])

  const onRemoveProperty = React.useCallback(() => {
    const attributes = structuredClone(dataToEditAttributes.dataGrid)
    const refreshAttributes = attributes.filter(
      (atribute) => atribute.codigo !== selectedProperty.codigo,
    )
    dispatch(
      setDataAttributesToEdit({
        key: tabId,
        atributos: {
          ...dataToEditAttributes,
          dataGrid: refreshAttributes,
        },
      }),
    )
  }, [dataToEditAttributes, dispatch, selectedProperty, tabId])

  return (
    <RowContainer className="m-2">
      <RowContainer>
        <CustomCol xs="12" md="6">
          <CRow>
            <CustomCol
              xs="12"
              md="5"
              className={isMobile ? 'mt-1 p-0' : 'mt-2 mr-2 p-0'}
            >
              <TiposPropiedadesLookUp
                selected={dataToEditAttributes.propiedad}
                onChanged={onChangedProperties}
                onChangedOptions={() => {}}
              />
            </CustomCol>
            <CustomCol
              xs="12"
              md="5"
              className={isMobile ? 'mt-1 p-0' : 'mt-2 ml-2 p-0'}
            >
              <SelectBox
                id="tiposImpuestosSelect"
                ref={selectRef}
                items={dataToEditAttributes.opciones ?? []}
                searchEnabled
                searchTimeout={500}
                value={selectedOption}
                onValueChanged={(event) => {
                  if (event.event) setSelectedOption(event.value)
                }}
                disabled={
                  dataToEditAttributes.opciones?.length > 0 ? false : true
                }
              />
            </CustomCol>
            <CustomCol
              xs="12"
              md="2"
              className={
                isMobile
                  ? 'd-flex justify-content-end mt-1 p-0'
                  : 'd-flex justify-content-end mt-2 p-0'
              }
            >
              <div style={{ display: 'flex', marginLeft: 'auto' }}>
                <Button
                  id="btnAdd"
                  className="me-1"
                  icon="add"
                  stylingMode="contained"
                  type="default"
                  onClick={() => onAddProperty()}
                  disabled={dataToEditAttributes.propiedad?.codigo <= 0}
                />
                <Button
                  id="btnRemove"
                  icon="trash"
                  stylingMode="contained"
                  type="danger"
                  onClick={() => onRemoveProperty()}
                  disabled={!selectedProperty}
                />
              </div>
            </CustomCol>
          </CRow>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="6">
          <DataGrid
            className="m-2"
            selection={{ mode: 'single' }}
            hoverStateEnabled={true}
            dataSource={dataToEditAttributes.dataGrid}
            showBorders={true}
            showRowLines={true}
            onSelectionChanged={({ selectedRowsData }) =>
              setSelectedProperty(selectedRowsData[0])
            }
            loadPanel={{ enabled: true }}
          >
            <Column
              dataType="string"
              dataField="descripcion"
              caption="Atributo"
              width="50%"
            />
            <Column
              dataType="string"
              dataField="valor"
              caption="Valor"
              width="50%"
            />
          </DataGrid>
        </CustomCol>
      </RowContainer>
    </RowContainer>
  )
}

export default Atributos

export const defaultAtributos: ItemAttributes = {
  propiedad: null,
  opciones: [],
  dataGrid: [],
}
