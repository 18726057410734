import React, { useEffect } from 'react'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import { DataGrid } from 'devextreme-react'
import {
  Column,
  Export,
  Pager,
  Paging,
  Button as DatagridButton,
  MasterDetail,
} from 'devextreme-react/data-grid'
import { getAllowedPageSizes } from '../../../../../../../helpers/Helper'
import ItemsCountIndicator from '../../../../../../componentes/indicadorNroRegistros'
import { setSelectedTransfer } from '../../store/searchReducer'
import { buttonClick, setButtons } from '../../store/tabsReducer'
import { ButtonTypes } from '../../../../../../../views/componentes/globalMenu/types'
import { Progreso, TransfersListAll } from '../../types/types'
import { isMobile } from 'react-device-detect'
import { utilidades } from '../../../../../../../helpers/utilidades'
import TransferDetails from './detallesTransferencia'

const Cabecera = (props) => {
  const { tabId } = props
  const dispatch = useDispatch()

  const resultsSearch = useSelector((state: RootState) => {
    return state.inventarios.movimientos.transferencias.search.resultsHeader
  })
  const currentTab = useSelector((state: RootState) => {
    return state.inventarios.movimientos.transferencias.search.currentTab
  })
  const selectedTransfer = useSelector((state: RootState) => {
    return state.inventarios.movimientos.transferencias.search.selectedTransfer
  })

  const dataGrid = React.useRef<any>()

  const onSelectedItemChanged = React.useCallback(
    (data: TransfersListAll) => {
      dispatch(setSelectedTransfer(data))
      dispatch(
        setButtons({
          tabKey: tabId,
          buttons: {
            Nuevo: true,
            Editar: true,
            Duplicar: true,
            Buscar: true,
            Eliminar: true,
            Envios: true,
            Recepcion: true,
            Archivado: true,
            Limpiar: true,
            Imprimir: true,
            Ver_asiento: data.progreso === Progreso.RECIBIDO,
          },
        }),
      )
    },
    [dispatch, tabId],
  )

  const onHandleDoubleClick = React.useCallback(() => {
    dispatch(buttonClick({ tabKey: tabId, button: ButtonTypes.edit }))
  }, [dispatch, tabId])

  const onHandleActionClick = React.useCallback(
    async (transferData: TransfersListAll, button: ButtonTypes) => {
      dispatch(setSelectedTransfer({ ...transferData }))
      await utilidades.sleep(10)
      dispatch(buttonClick({ tabKey: tabId, button: button }))
    },
    [dispatch, tabId],
  )

  useEffect(() => {
    if (currentTab === 'Cabecera' && selectedTransfer?.codigo > 0)
      dispatch(
        setButtons({
          tabKey: tabId,
          buttons: {
            Nuevo: true,
            Editar: true,
            Duplicar: true,
            Buscar: true,
            Eliminar: true,
            Envios: true,
            Recepcion: true,
            Archivado: true,
            Limpiar: true,
            Imprimir: true,
            Ver_asiento: selectedTransfer?.progreso === Progreso.RECIBIDO,
          },
        }),
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab])

  return (
    <RowContainer className="mt-2">
      <ItemsCountIndicator items={resultsSearch} />
      <DataGrid
        ref={dataGrid}
        selection={{ mode: 'single' }}
        hoverStateEnabled={true}
        dataSource={resultsSearch}
        showBorders={true}
        allowColumnReordering={true}
        allowColumnResizing={true}
        keyExpr="codigo"
        key="gridListTransferHeader"
        loadPanel={{ enabled: true }}
        repaintChangesOnly={true}
        onCellDblClick={onHandleDoubleClick}
        onRowClick={({ data }) => onSelectedItemChanged(data)}
      >
        <Paging defaultPageSize={20} />
        <Pager
          visible={resultsSearch.length > 0 ? true : false}
          allowedPageSizes={getAllowedPageSizes(resultsSearch)}
          displayMode={'full'}
          showPageSizeSelector={true}
          showInfo={true}
          showNavigationButtons={true}
        />
        <Export enabled={true} allowExportSelectedData={true} />
        {!isMobile && (
          <MasterDetail
            enabled={true}
            render={(params) => (
              <>
                <TransferDetails {...params} />{' '}
              </>
            )}
          />
        )}
        {!isMobile && (
          <Column type="buttons" width="140px">
            <DatagridButton
              icon="edit"
              hint="Editar"
              onClick={({ row }) =>
                onHandleActionClick(row.data, ButtonTypes.edit)
              }
            />
            <DatagridButton
              icon="trash"
              hint="Eliminar"
              onClick={({ row }) =>
                onHandleActionClick(row.data, ButtonTypes.delete)
              }
            />
            <DatagridButton
              icon="copy"
              hint="Duplicar"
              onClick={({ row }) =>
                onHandleActionClick(row.data, ButtonTypes.clone)
              }
            />
            <DatagridButton
              icon="arrowright"
              hint="Enviar"
              onClick={({ row }) =>
                onHandleActionClick(row.data, ButtonTypes.shipments)
              }
            />
            <DatagridButton
              icon="arrowleft"
              hint="Recibir"
              onClick={({ row }) =>
                onHandleActionClick(row.data, ButtonTypes.reception)
              }
            />
            <DatagridButton
              icon="activefolder"
              hint="Archivar"
              onClick={({ row }) =>
                onHandleActionClick(row.data, ButtonTypes.archived)
              }
            />
            <DatagridButton
              icon="print"
              hint="Imprimir"
              onClick={({ row }) =>
                onHandleActionClick(row.data, ButtonTypes.print)
              }
            />
          </Column>
        )}
        <Column
          dataField="codigo"
          caption="Código"
          width={isMobile ? '70px' : '5%'}
        />
        <Column
          dataField="numero"
          caption="Número"
          width={isMobile ? '90px' : '8%'}
        />
        <Column
          dataField="fecha"
          caption="Fecha"
          width={isMobile ? '80px' : '8%'}
        />
        <Column
          dataField="descripcion"
          caption="Descripción"
          width={isMobile ? '325px' : '26%'}
        />
        <Column
          dataField="localOrigenNombre"
          caption="Local Origen"
          width={isMobile ? '125px' : '10%'}
        />
        <Column
          dataField="localDestinoNombre"
          caption="Local Destino"
          width={isMobile ? '125px' : '10%'}
        />
        <Column
          dataField="progresoDescripcion"
          caption="Progreso"
          width={isMobile ? '80' : '10%'}
        />
        <Column
          dataField="estadoDescripcion"
          caption="Estado"
          width={isMobile ? '70' : '6%'}
        />
        <Column
          dataField="responsableNombre"
          caption="Responsable"
          width={isMobile ? '150px' : '10%'}
        />
        <Column
          dataField="archivado"
          caption="Archivado"
          width={isMobile ? '70px' : '7%'}
        />
      </DataGrid>
    </RowContainer>
  )
}

export default React.memo(Cabecera)
