import { RequestHelper } from '../../../../../helpers/requestHelper'
import { FilterDocumentsToPay } from '../types/types'

export const DocumentsToPayService = {
  getPendingPayments,
  getDocumentsPaid,
  getDataPrintPurchase,
}

async function getPendingPayments(
  filter: FilterDocumentsToPay,
): Promise<Array<any>> {
  const data: any = {
    identificacion: filter.identificacion,
    nombre: filter.nombre,
    numeroComp: filter?.numComprobante ? filter.numComprobante : '',
    fecha: filter.fechaActual,
    fechaHasta: filter.fechaHasta,
  }
  const pendingPayments = await RequestHelper.getAll<Array<any>>(
    'proveedores',
    'documentosPorPagar/supplierByPay',
    '',
    data,
  )
  return pendingPayments
}

async function getDocumentsPaid(
  filter: FilterDocumentsToPay,
): Promise<Array<any>> {
  const data: any = {
    identificacion: filter.identificacion,
    nombre: filter.nombre,
    fechaDesde: filter.fechaDesde,
    fechaHasta: filter.fechaHasta,
  }
  const documentsPaid = await RequestHelper.getAll<Array<any>>(
    'proveedores',
    'documentosPorPagar/supplierPayed',
    '',
    data,
  )
  return documentsPaid
}

// codigoAsociado: number = null,
//   codigoIngreso: number = null,
//   modeloImpresion: string = "",
//   tipo: string = ""

async function getDataPrintPurchase(objeto): Promise<any> {
  try {
    const obj = {
      tab: objeto['tab'],
      egresoAsociado: objeto['codigoAsociado'],
      egresoCodigo: objeto['egresoCodigo'],
      desde: objeto['desde'],
      hasta: objeto['hasta'],
      tipo: objeto['tipo'],
    }
    const data = await RequestHelper.getAll<any>(
      'proveedores/documentosPorPagar',
      'getTransactionsPurchase',
      '',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}
