import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EdicionPayload } from '../../../store/types'
import { TypeCustomerListado } from '../types/types'
import { CustomDictionary } from '../../../../../store/types'

const initialState: CustomDictionary<TypeCustomerListado> = {}

const datosEditSlice = createSlice({
  name: 'typeCustomerDataEdit',
  initialState: initialState,
  reducers: {
    deleteEditData(state, action: PayloadAction<string>) {
      delete state[action.payload]
    },
    setDatosEdicion(
      state,
      action: PayloadAction<{
        key: string
        typeCustomer: TypeCustomerListado
      }>,
    ) {
      state[action.payload.key].codigo = action.payload.typeCustomer.codigo
      state[action.payload.key].descripcion =
        action.payload.typeCustomer.descripcion
      state[action.payload.key].disminuir =
        action.payload.typeCustomer.disminuir
      state[action.payload.key].estado = action.payload.typeCustomer.estado
      state[action.payload.key].guardado = action.payload.typeCustomer.guardado
    },
    initDatosEdicion(
      state,
      action: PayloadAction<EdicionPayload<TypeCustomerListado>>,
    ) {
      if (!state[action.payload.key]) {
        state[action.payload.key] = action.payload.data
      }
    },
  },
})

export const { setDatosEdicion, initDatosEdicion, deleteEditData } =
  datosEditSlice.actions
export const dataEditReducer = datosEditSlice.reducer
