import React, { useCallback, useEffect } from 'react'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import {
  FilterItemsState,
  FilterSearchToApi,
  ItemEditData,
  ItemsListResult,
  PaginationSearch,
} from '../../types/types'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import {
  buttonClick,
  changeCurrentTab,
  clearButtonClick,
  openTab,
  setCurrentExecutingAction,
} from '../../store/tabsReducer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/store'
import {
  changeLoaderItems,
  setCollpased,
  setCurrentFunction,
} from '../../store/generalReducer'
import {
  changeCurrentSearchTab,
  setDataGridResult,
  setPaging,
  setSearchItem,
  setTotalCount,
} from '../../store/searchReducer'
import {
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
} from '@coreui/react-pro'
import FiltroBusqueda from './filtroBusqueda'
import Lista from './lista'
import { TabState } from '../../../../../../store/genericTabTypes'
import { defaultDataItem } from '../..'
import { initDatosEdicion } from '../../store/editDataReduce'
import { PagedResult, ToastTypes } from '../../../../../../store/types'
import { useSetToast } from '../../../../../../hooks/useGlobalHooks'
import { ItemsServices } from '../../services/items.services'
import { StatesEdition } from '../../../../../../store/enums'
import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'
import {
  ColInfo,
  ExportarExcelData,
  ExportarExcelService,
  FielTypes,
} from '../../../../../../services/exportarExcel.service'
import { DateUtils } from '../../../../../../helpers/dateUtils'
import { isMobile } from 'react-device-detect'
import { SpeedDialAction } from 'devextreme-react/speed-dial-action'

const colmuns: Array<ColInfo> = [
  {
    fieldName: 'itemCodigoUsuario',
    fieldTitle: 'Código',
    order: 0,
    datatype: FielTypes.String,
  },
  {
    fieldName: 'itemBarras',
    fieldTitle: 'Barras',
    order: 1,
    datatype: FielTypes.String,
  },
  {
    fieldName: 'itemDescripcion',
    fieldTitle: 'Descripción',
    order: 2,
    datatype: FielTypes.String,
  },
  {
    fieldName: 'itemLineaDescripcion',
    fieldTitle: 'Línea',
    order: 3,
    datatype: FielTypes.String,
  },
  {
    fieldName: 'itemGrupoDescripcion',
    fieldTitle: 'Grupo',
    order: 4,
    datatype: FielTypes.String,
  },
  {
    fieldName: 'itemMarcaDescripcion',
    fieldTitle: 'Marca',
    order: 5,
    datatype: FielTypes.String,
  },
  {
    fieldName: 'itemImpuestoDescripcion',
    fieldTitle: 'Impuesto',
    order: 6,
    datatype: FielTypes.String,
  },
  {
    fieldName: 'itemCosto',
    fieldTitle: 'Costo',
    order: 7,
    datatype: FielTypes.Decimal,
  },
  {
    fieldName: 'itemGananciaPVP',
    fieldTitle: '% Utilidad',
    order: 8,
    datatype: FielTypes.Decimal,
  },
  {
    fieldName: 'itemPVPIVA',
    fieldTitle: 'PVP IVA',
    order: 10,
    datatype: FielTypes.Decimal,
  },
  {
    fieldName: 'itemPVDIVA',
    fieldTitle: 'PVD IVA',
    order: 11,
    datatype: FielTypes.Decimal,
  },
  {
    fieldName: 'itemReservas',
    fieldTitle: 'Stock Reservas',
    order: 12,
    datatype: FielTypes.Decimal,
  },
  {
    fieldName: 'itemExistencia',
    fieldTitle: 'Stock Físico',
    order: 13,
    datatype: FielTypes.Decimal,
  },
  {
    fieldName: 'itemUnidadVentaDescripcion',
    fieldTitle: 'Unidad',
    order: 14,
    datatype: FielTypes.String,
  },
  {
    fieldName: 'itemTipoDescripcion',
    fieldTitle: 'Tipo',
    order: 15,
    datatype: FielTypes.String,
  },
  {
    fieldName: 'itemLoteDescripcion',
    fieldTitle: 'Lote',
    order: 16,
    datatype: FielTypes.String,
  },
  {
    fieldName: 'itemNumeroSerieDescripcion',
    fieldTitle: 'Serie',
    order: 17,
    datatype: FielTypes.String,
  },
  {
    fieldName: 'itemProcedenciaDescripcion',
    fieldTitle: 'Procedencia',
    order: 18,
    datatype: FielTypes.String,
  },
  {
    fieldName: 'itemSemana',
    fieldTitle: 'Semana',
    order: 19,
    datatype: FielTypes.String,
  },
  {
    fieldName: 'itemPeso',
    fieldTitle: 'Peso',
    order: 20,
    datatype: FielTypes.Decimal,
  },
  {
    fieldName: 'itemAncho',
    fieldTitle: 'Ancho',
    order: 21,
    datatype: FielTypes.Decimal,
  },
  {
    fieldName: 'itemLargo',
    fieldTitle: 'Largo',
    order: 22,
    datatype: FielTypes.Decimal,
  },
  {
    fieldName: 'itemAlto',
    fieldTitle: 'Alto',
    order: 23,
    datatype: FielTypes.Decimal,
  },
  {
    fieldName: 'itemNota',
    fieldTitle: 'Nota',
    order: 24,
    datatype: FielTypes.String,
  },
  {
    fieldName: 'itemTags',
    fieldTitle: 'Tags',
    order: 25,
    datatype: FielTypes.String,
  },
]

// eslint-disable-next-line no-unused-vars
export enum SearchViews {
  // eslint-disable-next-line no-unused-vars
  Filter,
  // eslint-disable-next-line no-unused-vars
  Search,
}

interface ISearchProps extends React.PropsWithChildren {
  tab: TabState<ItemEditData>
  tabId: string
}

const SearchStore: React.FunctionComponent<ISearchProps> = (props) => {
  const { tabId } = props

  const dispatch = useDispatch()
  const setToastMessage = useSetToast()

  const currentAction = useSelector(
    (state: RootState) => state.inventarios.items.general.currentFunction,
  )
  const tabs = useSelector((state: RootState) => {
    return state.inventarios.items.tabs
  })
  const searchFilter = useSelector((state: RootState) => {
    return state.inventarios.items.search
  })
  const selectedItem = useSelector((state: RootState) => {
    return state.inventarios.items.search.selectedItem
  })
  const listItems = useSelector((state: RootState) => {
    return state.inventarios.items.search.resultList
  })
  const totalCountItems = useSelector((state: RootState) => {
    return state.inventarios.items.search.totalCount
  })
  const searchItem = useSelector(
    (state: RootState) => state.inventarios.items.search.searchItem,
  )
  const user = useSelector((state: RootState) => state.global.usuario)
  const company = useSelector(
    (state: RootState) => state.global.session.empresa,
  )

  const [dataSourceItems, setDataSourceItems] = React.useState<DataSource>(null)
  const [currentView, setCurrentView] = React.useState<SearchViews>(
    SearchViews.Filter,
  )

  const playLoader = React.useCallback(
    (message = 'Cargando...') => {
      dispatch(changeLoaderItems({ show: true, mensaje: message }))
    },
    [dispatch],
  )

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoaderItems({ show: false, mensaje: '' }))
  }, [dispatch])

  const onHandleEdit = React.useCallback(async () => {
    const id = crypto.randomUUID()
    dispatch(
      initDatosEdicion({
        key: id,
        data: { ...defaultDataItem },
      }),
    )
    dispatch(
      openTab({
        key: id,
        editStatus: StatesEdition.save,
        item: {
          ...selectedItem,
          codigo: selectedItem.itemCodigoUsuario,
          barras: selectedItem.itemBarras,
          codigoInterno: selectedItem.itemCodigo,
        },
      }),
    )
  }, [dispatch, selectedItem])

  const onHandleRemove = React.useCallback(async () => {
    if (selectedItem.itemExistencia === 0) {
      try {
        playLoader('Eliminando Item . . . ')
        dispatch(
          setCurrentExecutingAction({
            tabKey: tabId,
            buttonType: ButtonTypes.delete,
          }),
        )
        const deleteItem = await ItemsServices.deleteItem(
          selectedItem.itemCodigo,
        )
        stopLoader()
        dispatch(
          setCurrentExecutingAction({
            tabKey: tabId,
            buttonType: undefined,
          }),
        )
        if (!deleteItem.error && deleteItem.auto)
          dispatch(buttonClick({ tabKey: tabId, button: ButtonTypes.find }))
        setToastMessage(
          'Eliminar Item',
          deleteItem.message,
          deleteItem.error ? ToastTypes.Warning : ToastTypes.Success,
        )
      } catch (error) {
        stopLoader()
        dispatch(
          setCurrentExecutingAction({
            tabKey: tabId,
            buttonType: undefined,
          }),
        )
        setToastMessage(
          'Eliminar Item',
          error ?? 'Error al eliminar el item',
          ToastTypes.Danger,
        )
      }
    } else
      setToastMessage(
        'EliminarItem',
        'No puede eliminar un item con existencia',
        ToastTypes.Warning,
      )
  }, [dispatch, playLoader, selectedItem, setToastMessage, stopLoader, tabId])

  const setSearchFilter = React.useCallback(
    (filter: FilterItemsState): FilterSearchToApi => {
      const searchFilterToApi: FilterSearchToApi = {
        itemCodigoUsuario: filter?.codigo ?? '',
        itemBarras: filter?.barras ?? '',
        itemDescripcion: filter?.nombre ?? '',
        itemGenerico: filter?.generico ?? '',
        itemLineaDescripcion: filter?.linea ?? '',
        itemGrupoDescripcion: filter?.grupo ?? '',
        itemMarcaDescripcion: filter?.marca ?? '',
        itemAplicaciones: filter?.aplicacion ?? '',
        itemTipo: filter?.tipoItem?.value ?? -1,
        itemImpuesto: filter?.tipoImpuesto?.codigo ?? 0,
        itemNota: '', // CHECK ORIGIN/USE OF THIS KEY
        itemPeso: '', // CHECK ORIGIN/USE OF THIS KEY
        itemTags: '', // CHECK ORIGIN/USE OF THIS KEY
        itemEstado: filter?.estadoItem.value ?? 1,
        itemPVDIVA: filter?.precioDesde ?? 0,
        itemPVPIVA: filter?.precioHasta ?? 0,
        itemIncremental: 0,
        itemMobile: filter?.movilweb.value ?? 0,
        impresoraCodigoTecnologia: '', // CHECK ORIGIN/USE OF THIS KEY
        itemModeloBom: filter?.modelo ?? '',
        itemBusqueda: filter?.inicioFinOpt.value === 'i' ? 0 : 1,
        itemJerarquia: filter?.agrupador.value ?? -1,
        itemIdwoo: filter?.noSincronizados ? 1 : 0,
        subsidio: '', // CHECK ORIGIN/USE OF THIS KEY
        exacta: '', // CHECK ORIGIN/USE OF THIS KEY
        local: filter?.local?.codigo ?? -1,
      }
      return searchFilterToApi
    },
    [],
  )

  const DataSourceObject = React.useMemo(() => {
    const customStoreItemsBase = new CustomStore({
      key: 'itemCodigo',
      load: async (loadOptions) => {
        playLoader('Buscando ítems . . .')
        dispatch(
          setCurrentExecutingAction({
            tabKey: 'BUSQUEDA',
            buttonType: ButtonTypes.find,
          }),
        )
        const pagination: PaginationSearch = {
          isPaged: true,
          page: (loadOptions.skip ?? 0) / (loadOptions.take ?? 0) + 1,
          limit: loadOptions.take ?? 20,
        }

        let itemsSearchResult: PagedResult<ItemsListResult> = {
          currentPage: 0,
          from: 0,
          to: 0,
          count: 0,
          result: [],
        }

        try {
          itemsSearchResult = await ItemsServices.getItmesByBase(
            setSearchFilter(searchFilter.filter),
            pagination,
          )
        } catch (error) {
          setToastMessage('Buscar Ítems', error, ToastTypes.Danger)
          stopLoader()
          dispatch(
            setCurrentExecutingAction({
              tabKey: 'BUSQUEDA',
              buttonType: undefined,
            }),
          )
        }

        stopLoader()
        dispatch(
          setCurrentExecutingAction({
            tabKey: 'BUSQUEDA',
            buttonType: undefined,
          }),
        )
        setToastMessage(
          'Buscar Ítems',
          `${itemsSearchResult.count} Registro(s) encontrado(s)`,
          ToastTypes.Success,
        )

        if (itemsSearchResult.result?.length === 0)
          dispatch(setSearchItem(false))
        else if (itemsSearchResult.result?.length > 5)
          dispatch(setCollpased(false))

        return {
          data: itemsSearchResult.result,
          totalCount: itemsSearchResult.count,
        }
      },
    })

    return new DataSource({
      totalCount: customStoreItemsBase.totalCount,
      paginate: true,
      pageSize: 20,
      store: customStoreItemsBase,
      key: 'itemCodigo',
      requireTotalCount: true,
      onChanged: () => {
        dispatch(setDataGridResult(DataSourceObject.items()))
        const totalCount = DataSourceObject.totalCount()
        if (totalCount !== totalCountItems) dispatch(setTotalCount(totalCount))
      },
    })
  }, [
    dispatch,
    playLoader,
    searchFilter,
    setSearchFilter,
    stopLoader,
    totalCountItems,
    setToastMessage,
  ])

  const onHandleSearch = React.useCallback(async () => {
    dispatch(
      setPaging({
        pageIndex: 0,
        pageSize: 20,
      }),
    )
    setDataSourceItems(DataSourceObject)
    dispatch(setSearchItem(true))
  }, [dispatch, DataSourceObject])

  const onHandleExport = useCallback(async () => {
    try {
      playLoader('Exportando . . .')
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'BUSQUEDA',
          buttonType: ButtonTypes.export,
        }),
      )
      const dataToExcel: ExportarExcelData = {
        sheetName: 'ListadoItems',
        exportedBy: `${user.nombres} ${user.apellidos}`,
        titulos: [
          `${company.comercial.toUpperCase()}`,
          `Fecha: ${DateUtils.getCurrentDateAsString()}`,
          'Expresado en USD',
        ],
        data: listItems,
        emptyRowsAfterTitles: 0,
        cols: colmuns,
      }
      const response: any = await ExportarExcelService.exportarExcel(
        dataToExcel,
      )
      stopLoader()
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'BUSQUEDA',
          buttonType: undefined,
        }),
      )
      const filename = 'Listado_Items.xlsx'
      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = filename
      a.click()
    } catch (error) {
      stopLoader()
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'BUSQUEDA',
          buttonType: undefined,
        }),
      )
      setToastMessage('Exportar datos a excel', error, ToastTypes.Danger)
    }
  }, [
    company,
    dispatch,
    listItems,
    playLoader,
    setToastMessage,
    stopLoader,
    user,
  ])

  const handleButtonClick = React.useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.find:
          if (tabs.current === 'BUSQUEDA') {
            setCurrentView(SearchViews.Search)
            onHandleSearch()
          } else dispatch(changeCurrentTab('BUSQUEDA'))
          break
        case ButtonTypes.delete:
          onHandleRemove()
          break
        case ButtonTypes.edit:
          onHandleEdit()
          break
        case ButtonTypes.export:
          if (tabs.current === 'BUSQUEDA') onHandleExport()
          break
        case ButtonTypes.broom:
          if (tabs.current === 'BUSQUEDA') setDataSourceItems(null)
          break
        default:
          break
      }
      dispatch(setCurrentFunction(''))
      dispatch(clearButtonClick(tabId))
    },
    [
      dispatch,
      onHandleRemove,
      onHandleEdit,
      tabId,
      onHandleSearch,
      tabs,
      onHandleExport,
    ],
  )

  useEffect(() => {
    if (currentAction !== '') handleButtonClick(currentAction)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAction])

  useEffect(() => {
    if (searchItem) setDataSourceItems(DataSourceObject)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {isMobile && tabs.current === 'BUSQUEDA' && (
        <>
          <SpeedDialAction
            icon="filter"
            label="Filtros"
            visible={true}
            index={1}
            onClick={() => {
              setCurrentView(SearchViews.Filter)
            }}
          />
          <SpeedDialAction
            icon="search"
            label="Búsqueda"
            visible={true}
            index={2}
            onClick={() => {
              setCurrentView(SearchViews.Search)
            }}
          />
        </>
      )}
      {(currentView === SearchViews.Filter || !isMobile) && <FiltroBusqueda />}
      {(currentView === SearchViews.Search || !isMobile) && (
        <RowContainer>
          <CNav variant="tabs" role="tablist" className="mt-4">
            <CNav variant="tabs">
              {searchFilter.tabs.map((tab, index) => (
                <CNavItem key={index}>
                  <CNavLink
                    onClick={() =>
                      dispatch(changeCurrentSearchTab(searchFilter.tabs[index]))
                    }
                    active={searchFilter.currentTab === tab}
                  >
                    {tab}
                  </CNavLink>
                </CNavItem>
              ))}
            </CNav>
            <CTabContent
              style={{
                overflowY: 'unset',
                width: '100%',
                marginBottom: '10px',
              }}
            >
              <CTabPane
                role="tabpanel"
                aria-labelledby="pendientes-tab"
                visible={searchFilter.currentTab === searchFilter.tabs[0]}
              >
                <Lista data={dataSourceItems ?? []} tabId={tabId} />
              </CTabPane>
              <CTabPane
                role="tabpanel"
                aria-labelledby="pagados-tab"
                visible={searchFilter.currentTab === searchFilter.tabs[1]}
              >
                <Lista data={dataSourceItems ?? []} tabId={tabId} />
              </CTabPane>
            </CTabContent>
          </CNav>
        </RowContainer>
      )}
    </>
  )
}

export default React.memo(SearchStore)
