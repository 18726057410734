import React, { useCallback, useState } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CTooltip,
} from '@coreui/react-pro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch, useSelector } from 'react-redux'
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import { RootState } from '../../../../../../store/store'
import { changeLoader } from '../../../../../../store/reducers'
import { ToastTypes } from '../../../../../../store/types'
import Modalform from '../../../../../../views/componentes/modalform'
import CustomCol from '../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput'
import { DataGrid, NumberBox, RadioGroup } from 'devextreme-react'
import { TIPOS_BOTONES } from '../../../../../proveedores/pages/proveedores/types/types'
import { TOptionGenerico } from '../../../../../clientes/pages/clientes/types/types'
import ListErrosValidationForm from '../../../../../componentes/listadoErroresValidacionForm'
import { ItemsServices } from '../../services/items.services'
import VisualizaError from '../../../../../ventas/pages/shared/visualizaError/visualizaError'
import { useSetToast } from '../../../../../../hooks/useGlobalHooks'
import { EditPrices } from '../../types/types'
import { Column, Pager, Paging } from 'devextreme-react/data-grid'
import { getAllowedPageSizes } from '../../../../../../helpers/Helper'

export const optionsUpdate: Array<TOptionGenerico> = [
  { value: 0, label: 'Costo' },
  { value: 1, label: 'Precio' },
]

export const optionsAction: Array<TOptionGenerico> = [
  { value: 0, label: 'Aumentar' },
  { value: 1, label: 'Disminuir' },
]

const buttons = [
  {
    icon: 'fal fa-save',
    id: 'Guardar',
    text: 'Guardar',
    type: ButtonTypes.save,
  },
  {
    icon: 'fal fa-times',
    id: 'Salir',
    text: 'Salir',
    type: ButtonTypes.times,
  },
]

type ModalEditPriceProps = {
  show: boolean
  onClose: () => void
}

const ModalEditarPrecios = (props: ModalEditPriceProps) => {
  const dispatch = useDispatch()
  const setToastMessage = useSetToast()

  const selectedItems = useSelector((state: RootState) => {
    return state.inventarios.items.search.resultList
  })

  const [acciones] = useState<any>(buttons)
  const [showConfirm, setShowConfirm] = React.useState(false)
  const [messageConfirm] = React.useState(
    '¿Esta a punto de cambiar los precios de los ítems seleccionados, Desea continuar?',
  )
  const [updateTo, setUpdateTo] = React.useState<TOptionGenerico>(
    optionsUpdate[1],
  )
  const [updateAction, setUpdateAction] = React.useState<TOptionGenerico>(
    optionsAction[0],
  )
  const [pvd, setPvd] = React.useState<number>(0.0)
  const [pvp, setPvp] = React.useState<number>(0.0)
  const [validationFormErrors, setValidationFormErrors] = React.useState<
    Array<any>
  >([])
  const [showPopupError, setShowPopupError] = React.useState<boolean>(false)
  const [showErrorSave, setShowErrorSave] = React.useState<boolean>(false)
  const [errorsSave, setErrorsSave] = React.useState<Array<any>>([])
  const tablaRef = React.useRef<any>()
  const tablaRefPager = React.useRef<any>()

  const playLoader = React.useCallback(
    (message: string) => {
      dispatch(changeLoader({ show: true, mensaje: message }))
    },
    [dispatch],
  )

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoader({ show: false, mensaje: '' }))
  }, [dispatch])

  const closeModal = React.useCallback(() => {
    props.onClose()
  }, [props])

  const renderBodyConfirm = () => {
    return <> {messageConfirm} </>
  }

  const onHandleChange = React.useCallback(
    (setState: any) => (event: any) => {
      if (event.event) setState(event.value)
    },
    [],
  )

  const onChangedPrices = React.useCallback((event: any, price: string) => {
    if (event.event) {
      const newPrice = parseFloat(event.value.toFixed(2)) ?? 0
      if (price === 'pvd') setPvd(newPrice)
      else setPvp(newPrice)
    }
  }, [])

  const ValidateProcesForm = useCallback(() => {
    const errors: Array<string> = []
    if (updateTo.value === 0 && pvd > pvp)
      errors.push(
        'Para actualizar precio por costo PVD debe ser menor o igual PVP',
      )
    if (updateTo.value === 0 && updateAction.value === 1)
      errors.push('No se puede disminur precio por Costo')
    if (pvp === 0)
      errors.push('Para PVP ingrese valores numèricos entre 1 y 100')
    if (pvd === 0)
      errors.push('Para PVD ingrese valores numèricos entre 1 y 100')
    return errors
  }, [pvd, pvp, updateAction, updateTo])

  const onHandleSave = React.useCallback(async () => {
    const validationFormResult = ValidateProcesForm()
    if (validationFormResult.length > 0) {
      setValidationFormErrors(validationFormResult)
      setShowPopupError(true)
    } else {
      try {
        playLoader('Guardando . . .')
        const itemsToEitd: EditPrices = {
          infoRegistro: {
            items: [...selectedItems],
            precio: updateTo.value === 1 ? true : false,
            pvd: pvd,
            pvp: pvp,
            accion: updateAction.value === 0 ? true : false,
          },
        }
        const editPrices = await ItemsServices.editPriceItems(itemsToEitd)
        stopLoader()
        if (!editPrices.error && editPrices.auto) {
          closeModal()
          setToastMessage(
            'Editar Precio Ítems',
            editPrices.message ?? 'Precios de ítems actualizados correctamente',
            ToastTypes.Success,
          )
        } else {
          if (editPrices.auto.length > 0) {
            setShowErrorSave(true)
            setErrorsSave(editPrices.auto)
          }
          setToastMessage(
            'Editar Precio Ítems',
            editPrices.message ?? 'Error al editar precios de ítems',
            ToastTypes.Warning,
          )
        }
      } catch (error) {
        stopLoader()
        setToastMessage(
          'Editar Precio Ítems',
          error ?? 'Error al editar precios de ítems',
          ToastTypes.Danger,
        )
      }
    }
  }, [
    ValidateProcesForm,
    playLoader,
    selectedItems,
    updateTo,
    pvd,
    pvp,
    updateAction,
    stopLoader,
    closeModal,
    setToastMessage,
  ])

  const returnAction = React.useCallback(
    (metodo: string) => {
      switch (metodo) {
        case TIPOS_BOTONES.BOTON_GUARDAR:
          setShowConfirm(true)
          break
        case TIPOS_BOTONES.BOTON_SALIR:
          closeModal()
          break
        default:
          break
      }
    },
    [closeModal],
  )

  const bodyModalForm = () => {
    return (
      <>
        {showPopupError && (
          <VisualizaError
            titulo="Error en Editar Precios"
            mensaje={'Error al guardar'}
            content={
              <ListErrosValidationForm
                mainMessage={
                  'Antes de guardar la información revise lo siguiente:'
                }
                errorsList={validationFormErrors}
                color={'danger'}
              />
            }
            onOk={() => setShowPopupError(false)}
          />
        )}
        <CRow>
          <CustomCol xs="12" md="12">
            <Labeled label="Actualizar Por:">
              <RadioGroup
                name="idStatusItem"
                dataSource={optionsUpdate}
                value={updateTo}
                layout="horizontal"
                displayExpr="label"
                onValueChanged={onHandleChange(setUpdateTo)}
                width="100%"
              />
            </Labeled>
          </CustomCol>
        </CRow>
        <CRow>
          <CustomCol xs="12" md="6">
            <Labeled label="P.V.D.">
              <NumberBox
                value={pvd}
                onValueChanged={(evt) => onChangedPrices(evt, 'pvd')}
                min={1}
                max={100}
                width="100%"
              />
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="6">
            <Labeled label="P.V.P.">
              <NumberBox
                value={pvp}
                onValueChanged={(evt) => onChangedPrices(evt, 'pvp')}
                width="100%"
                min={1}
                max={100}
              />
            </Labeled>
          </CustomCol>
        </CRow>
        <CCol
          style={{
            display: 'flex',
            justifyContent: 'end',
            marginTop: '10px',
            marginRight: 'auto',
          }}
        >
          <RadioGroup
            name="idStatusItem"
            dataSource={optionsAction}
            value={updateAction}
            layout="horizontal"
            displayExpr="label"
            onValueChanged={onHandleChange(setUpdateAction)}
            width="100%"
          />
        </CCol>
      </>
    )
  }

  const renderBodyErrores = () => {
    return (
      <>
        <DataGrid
          ref={tablaRef}
          selection={{ mode: 'single' }}
          className="border"
          dataSource={errorsSave}
          width={'100%'}
          keyExpr=""
        >
          <Paging enabled={true} defaultPageSize={10} />
          <Pager
            ref={tablaRefPager}
            showPageSizeSelector={true}
            allowedPageSizes={getAllowedPageSizes(errorsSave)}
            showNavigationButtons={true}
            showInfo={true}
          />
          <Column dataField="0" caption="Código" width="12%" />
          <Column dataField="1" caption="Descripción" width="44%" />
          <Column dataField="2" caption="Error" width="44%" />
        </DataGrid>
      </>
    )
  }

  const errosSave = () => {
    return (
      <Modalform
        name="showErrorsSAve"
        headerTitle={'Errores al Editar Precio de Ítems'}
        childrenBody={renderBodyErrores()}
        childrenFooter={<></>}
        closeOnBackdrop={false}
        show={showErrorSave}
        onClose={() => {
          setShowErrorSave(false)
          closeModal()
        }}
        centered={true}
        size="lg"
        key={'erroresSave'}
      />
    )
  }

  const confirmAction = () => {
    return (
      <Modalform
        name="confirmarCambios"
        headerTitle={'Confirmar'}
        childrenBody={renderBodyConfirm()}
        childrenFooter={renderFooter()}
        closeOnBackdrop={false}
        show={showConfirm}
        onClose={() => setShowConfirm(!showConfirm)}
        centered={true}
        size="sm"
        key={'confirmarCambios'}
      />
    )
  }

  const renderBody = () => {
    return (
      <>
        {showErrorSave === true && errosSave()}
        {showConfirm === true && confirmAction()}
        <CCard key="cardCuentas">
          <CCardHeader>
            <CRow>
              <CCol className="ms-0 d-flex align-items-start justify-content-start">
                <div
                  key="menuItems"
                  className="btn-toolbar"
                  role="toolbar"
                  aria-label="Toolbar with button groups"
                  style={{ marginBottom: '5px', paddingTop: '-2rem' }}
                >
                  <div
                    key="menuItemsBotones"
                    className="btn-group-lg btn-group btn-sm me-2"
                    role="group"
                    aria-label="First group"
                  >
                    {acciones.map(function (action: any) {
                      const prefixButton = 'buttonsEditPrices_'
                      const nameButon = action.text
                      const disabled = false
                      return (
                        <CTooltip
                          key={'tool-' + prefixButton + nameButon}
                          placement="top"
                          content={nameButon}
                        >
                          <CButton
                            key={prefixButton + nameButon}
                            name={prefixButton + nameButon}
                            title={action.text}
                            id={prefixButton + nameButon}
                            value={action.text}
                            className="btn btn-secondary sm"
                            variant="ghost"
                            // eslint-disable-next-line no-unused-vars
                            onClick={(ev) => returnAction((ev = action.text))}
                            size="sm"
                            disabled={disabled}
                          >
                            <FontAwesomeIcon
                              size="sm"
                              icon={action.icon ?? faQuestionCircle}
                            />
                          </CButton>
                        </CTooltip>
                      )
                    })}
                  </div>
                </div>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>{bodyModalForm()}</CCardBody>
        </CCard>
      </>
    )
  }

  const renderFooter = () => {
    {
      return (
        <>
          <CButton
            color="primary"
            onClick={() => {
              setShowConfirm(false)
              onHandleSave()
            }}
          >
            {'Aceptar'}
          </CButton>
          <CButton color="secondary" onClick={() => setShowConfirm(false)}>
            {'Cancelar'}
          </CButton>
        </>
      )
    }
  }

  return (
    <CRow key="rowMain">
      <CCol key="colMain">
        <Modalform
          name="modalUpdatePrice"
          key="modalUpdatePrice"
          headerTitle={'Actualizar Precios de Items Seleccionados'}
          childrenBody={renderBody()}
          childrenFooter={<></>}
          closeOnBackdrop={false}
          show={props.show}
          onClose={closeModal}
          size="sm"
        />
      </CCol>
    </CRow>
  )
}

export default React.memo(ModalEditarPrecios)
