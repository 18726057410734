import { combineReducers } from '@reduxjs/toolkit'
import { tabsReducer } from './tabsReducer'
import { generalReducer } from './generalReducer'
import { TransferenciasState } from '../types/types'
import { dataEditReducer } from './editDataReducer'
import { searchReducer } from './searchReducer'

export const TransferecniasReducer = combineReducers<TransferenciasState>({
  general: generalReducer,
  tabs: tabsReducer,
  editData: dataEditReducer,
  search: searchReducer,
})
