import {
  FunctionComponent,
  PropsWithChildren,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../../store/store'
import { ButtonTypes } from '../../../../views/componentes/globalMenu/types'
import {
  useButtonDisabled,
  useMenuOptions,
} from '../../../../hooks/useMenuButtonControls'
import {
  AccionMenu,
  ConfigPopupMsg,
  CustomButtons,
  ToastTypes,
} from '../../../../store/types'
import PayrollMain from './components/shared/mains'
import {
  buttonClick,
  changeCurrentTab,
  clearButtonClick,
  closeTabCandidate,
  openTab,
  setButtons,
} from './store/tabsReducer'
import { deleteDataCandidate, initDataCandidate } from './store/editDataReducer'
import { useSetToast } from '../../../../hooks/useGlobalHooks'
import { CandidatesServices } from './services/candidates.services'
import { Candidate, NewCandidate } from './types/types'
import { DateUtils } from '../../../../helpers/dateUtils'
import {
  initialState,
  setCollpasedFilter,
  setFilter,
  setIsSearch,
  setResultsCandidate,
  setSelectedCandidate,
} from './store/searchReducer'
import { useLoaderCandidates } from './customHooks'
import { OptionCiudad } from '../../../../containers/component/formLocal/types/types'
import { ReportWithLocalDataModal } from '../../../../views/componentes/xtrareports/ReportWithLocalDataModal'
import { isMobile } from 'react-device-detect'
import ConfirmarAccionMsj from '../../../../views/componentes/confirmarAccionMsj'
import { defaultPopupMsgConfig } from '../../store/types'

const pathVerificador = '/candidatos'

interface ICandidatesProps extends PropsWithChildren {}

const Candidatos: FunctionComponent<ICandidatesProps> = () => {
  const dispatch = useDispatch()
  const setToastMessage = useSetToast()
  const { showLoaderTabs } = useLoaderCandidates()

  const menuState = useSelector((state: RootState) => state.global.menu)
  const tabs = useSelector((state: RootState) => state.nomina.candidatos.tabs)
  const searchFilter = useSelector(
    (state: RootState) => state.nomina.candidatos.search.filter,
  )
  const currentTab = useSelector((state: RootState) => {
    return state.nomina.candidatos.tabs.current
  })
  const globalButtonClick = useSelector((state: RootState) => {
    return state.nomina.candidatos.tabs.tabs[currentTab].globalButtonClick
  })
  const selectedCandidate = useSelector(
    (state: RootState) => state.nomina.candidatos.search.selected,
  )
  const candidatesList = useSelector(
    (state: RootState) => state.nomina.candidatos.search.candidatesList,
  )
  const currentCandidate = useSelector((state: RootState) => {
    return state.nomina.candidatos.editData[currentTab]
  })

  const getButtonDisabled = useButtonDisabled(tabs)
  const { getMenuOptions, options } = useMenuOptions()

  const [popupMsgConfig, setPopupMsgConfig] = useState<ConfigPopupMsg>(
    defaultPopupMsgConfig,
  )
  const [pdfReport, setPdfReport] = useState<null | 'Viewer' | 'Designer'>(null)
  const [reportName, setReportName] = useState<
    'StaticCandidate' | 'StaticCandidatesList' | null
  >(null)

  const onTabClosing = useCallback(
    (tabKey: string) => {
      const closedTab = tabs.tabs[tabKey]
      dispatch(closeTabCandidate(closedTab.tabKey ?? tabKey))
      dispatch(deleteDataCandidate(closedTab.tabKey ?? tabKey))
    },
    [dispatch, tabs.tabs],
  )

  const onTabChanged = useCallback(
    (tabKey: string) => {
      dispatch(changeCurrentTab(tabKey))
    },
    [dispatch],
  )

  const onHandleClearResults = useCallback(() => {
    dispatch(setResultsCandidate([]))
    dispatch(setSelectedCandidate(null))
    dispatch(
      setButtons({
        tabKey: 'BUSQUEDA',
        buttons: {
          Nuevo: true,
          Guardar: false,
          Editar: false,
          Eliminar: false,
          Buscar: true,
          Imprimir: false,
          Deshacer: true,
          Limpiar: true,
        },
      }),
    )
  }, [dispatch])

  const onHandleUndo = useCallback(() => {
    dispatch(setFilter(initialState.filter))
  }, [dispatch])

  const openNewTab = useCallback(() => {
    const id = crypto.randomUUID()
    dispatch(
      initDataCandidate({
        key: id,
        data: defaultDataCandidate,
      }),
    )
    dispatch(openTab({ key: id }))
  }, [dispatch])

  const openTabToEdit = useCallback(() => {
    const id = crypto.randomUUID()
    dispatch(
      initDataCandidate({
        key: id,
        data: parseCndidateToNewCandidateType(selectedCandidate),
      }),
    )
    dispatch(
      openTab({
        key: id,
        candidate: selectedCandidate,
        templateMode: false,
      }),
    )
  }, [dispatch, selectedCandidate])

  const onHandleSearch = useCallback(async () => {
    try {
      onHandleClearResults()
      showLoaderTabs(
        true,
        'BUSQUEDA',
        'Buscando Candidatos . . .',
        ButtonTypes.find,
      )
      const candidatesList =
        await CandidatesServices.getListCandidates<Candidate>({
          rolCodigo: searchFilter.cargo.codigo ?? -1,
          identificacion: searchFilter.identificacion ?? '',
          apellidos: searchFilter.apellidos ?? '',
          nombres: searchFilter.nombres ?? '',
          capLiderazgo: searchFilter.liderazgo ? 1 : 0,
          actitudPositiva: searchFilter.actitud ? 1 : 0,
          comunicacion: searchFilter.comunicacion ? 1 : 0,
          flexibilidad: searchFilter.flexibilidad ? 1 : 0,
          trabajoEquipo: searchFilter.colaboracion ? 1 : 0,
          dedicacion: searchFilter.dedicacion ? 1 : 0,
          confianza: searchFilter.confianza ? 1 : 0,
          honestidad: searchFilter.integridad ? 1 : 0,
          iniciativa: searchFilter.iniciativa ? 1 : 0,
          desaprender: searchFilter.aprendizaje ? 1 : 0,
          fechaDesde:
            DateUtils.pickersDateToApiDate(searchFilter.registroDesde) ?? '',
          fechaHasta:
            DateUtils.pickersDateToApiDate(searchFilter.registroHasta) ?? '',
        })
      showLoaderTabs(false)
      if (
        !candidatesList.error &&
        candidatesList.auto &&
        Array.isArray(candidatesList.auto)
      ) {
        dispatch(setResultsCandidate(candidatesList.auto))
        if (candidatesList.auto.length > 5) dispatch(setCollpasedFilter(false))
      }
      setToastMessage(
        'Buscar Candidatos',
        candidatesList.message,
        candidatesList.error ? ToastTypes.Warning : ToastTypes.Success,
      )
    } catch (error) {
      showLoaderTabs(false)
      setToastMessage('Buscar Candidatos', error, ToastTypes.Danger)
    }
  }, [
    dispatch,
    onHandleClearResults,
    searchFilter,
    setToastMessage,
    showLoaderTabs,
  ])

  const onHandleDelete = useCallback(async () => {
    try {
      showLoaderTabs(
        true,
        'BUSQUEDA',
        'Eliminando Candidato . . .',
        ButtonTypes.delete,
      )
      const deleteCandidate = await CandidatesServices.deleteCandidate<boolean>(
        selectedCandidate.codigo,
      )
      showLoaderTabs(false)
      if (
        !deleteCandidate.error &&
        deleteCandidate.auto &&
        typeof deleteCandidate.auto === 'boolean'
      )
        onHandleSearch()
      setToastMessage(
        'Eliminar Candidato',
        deleteCandidate.message,
        deleteCandidate.error ? ToastTypes.Warning : ToastTypes.Success,
      )
    } catch (error) {
      showLoaderTabs(false)
      setToastMessage('Eliminar Candidato', error, ToastTypes.Danger)
    }
  }, [
    onHandleSearch,
    selectedCandidate?.codigo,
    setToastMessage,
    showLoaderTabs,
  ])

  const onParseDtoToPrint = useCallback(() => {
    const candidatesDto: any = []
    if (reportName === 'StaticCandidatesList')
      candidatesList.forEach((candidate: Candidate) => {
        candidatesDto.push({
          Surnames: candidate.apellidos,
          Names: candidate.nombres,
          Identification: candidate.identificacion,
          Telephone: candidate.telefono,
          Email: candidate.email,
          Date: candidate.fecha,
          Position: candidate.rolDescripcion,
          City: candidate.ciudadNombre,
        })
      })
    else
      candidatesDto.push({
        Surnames: currentCandidate.apellidos,
        Names: currentCandidate.nombres,
        Identification: currentCandidate.identificacion,
        Telephone: currentCandidate.telefono,
        Email: currentCandidate.email,
        Date: currentCandidate.fecha,
        Position: currentCandidate.aplica.descripcion ?? 'N/A',
        City: currentCandidate.ciudad.nombre ?? 'N/A',
        Resume: currentCandidate.carpeta ?? 'N/A',
        Leadership: currentCandidate.liderazgo ? 'SI' : 'NO',
        Attitude: currentCandidate.actitud ? 'SI' : 'NO',
        Communication: currentCandidate.comunicacion ? 'SI' : 'NO',
        Flexibility: currentCandidate.flexibilidad ? 'SI' : 'NO',
        Collaboration: currentCandidate.colaboracion ? 'SI' : 'NO',
        Dedication: currentCandidate.dedicacion ? 'SI' : 'NO',
        Trust: currentCandidate.confianza ? 'SI' : 'NO',
        Integrity: currentCandidate.integridad ? 'SI' : 'NO',
        Initiative: currentCandidate.iniciativa ? 'SI' : 'NO',
        Learning: currentCandidate.aprendizaje ? 'SI' : 'NO',
      })
    return candidatesDto
  }, [candidatesList, currentCandidate, reportName])

  const onHandleConfirm = useCallback(() => {
    if (popupMsgConfig.type === 'confirm') {
      if (popupMsgConfig.currentAction === ButtonTypes.edit) openTabToEdit()
      else if (popupMsgConfig.currentAction === ButtonTypes.delete)
        onHandleDelete()
    } else openNewTab()
    setPopupMsgConfig(defaultPopupMsgConfig)
  }, [onHandleDelete, openNewTab, openTabToEdit, popupMsgConfig])

  const onHandleCancel = useCallback(() => {
    setPopupMsgConfig(defaultPopupMsgConfig)
  }, [])

  const onMenuButtonClick = useCallback(
    async (action: AccionMenu | { actionType: string }) => {
      switch (action.actionType) {
        case ButtonTypes.new:
          if (currentTab === 'BUSQUEDA') openNewTab()
          else
            setPopupMsgConfig({
              show: true,
              title: 'Ácatha',
              message: 'Desea crear un nuevo registro?',
              type: 'newTab',
            })
          break
        case ButtonTypes.find:
          if (currentTab === 'BUSQUEDA') {
            if (isMobile) dispatch(setIsSearch(true))
            onHandleSearch()
          } else dispatch(changeCurrentTab('BUSQUEDA'))
          break
        case ButtonTypes.broom:
          if (currentTab === 'BUSQUEDA') onHandleClearResults()
          break
        case ButtonTypes.undo:
          if (currentTab === 'BUSQUEDA') onHandleUndo()
          else
            dispatch(
              buttonClick({ tabKey: currentTab, button: ButtonTypes.undo }),
            )
          break
        case ButtonTypes.save:
          if (currentTab !== 'BUSQUEDA')
            dispatch(
              buttonClick({ tabKey: currentTab, button: ButtonTypes.save }),
            )
          break
        case ButtonTypes.edit:
          if (currentTab === 'BUSQUEDA')
            setPopupMsgConfig({
              show: true,
              title: 'Ácatha',
              message: `¿Desea EDITAR el candidato ${selectedCandidate.apellidos} ${selectedCandidate.nombres}?`,
              type: 'confirm',
              currentAction: ButtonTypes.edit,
            })
          break
        case ButtonTypes.delete:
          if (currentTab === 'BUSQUEDA')
            setPopupMsgConfig({
              show: true,
              title: 'Ácatha',
              message: `¿Desea ELIMINAR el candidato ${selectedCandidate.apellidos} ${selectedCandidate.nombres}?`,
              type: 'confirm',
              currentAction: ButtonTypes.delete,
            })
          break
        case ButtonTypes.print:
          setPdfReport('Viewer')
          setReportName(
            currentTab === 'BUSQUEDA'
              ? 'StaticCandidatesList'
              : 'StaticCandidate',
          )
          break
        default:
          break
      }
      if (currentTab === 'BUSQUEDA') dispatch(clearButtonClick(currentTab))
    },
    [
      dispatch,
      openNewTab,
      onHandleClearResults,
      onHandleSearch,
      onHandleUndo,
      currentTab,
      selectedCandidate,
    ],
  )

  useEffect(() => {
    dispatch(
      setButtons({
        tabKey: 'BUSQUEDA',
        buttons: {
          Nuevo: true,
          Guardar: false,
          Editar: false,
          Eliminar: false,
          Buscar: true,
          Imprimir: candidatesList.length > 0,
          Deshacer: true,
          Limpiar: true,
        },
      }),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidatesList])

  useEffect(() => {
    if (globalButtonClick !== ButtonTypes.none && currentTab === 'BUSQUEDA')
      onMenuButtonClick({ actionType: globalButtonClick })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalButtonClick])

  useEffect(() => {
    if (menuState.length > 0 && options.length === 0)
      getMenuOptions(menuState, 'to', pathVerificador)
  }, [menuState, getMenuOptions, options])

  return (
    <>
      <ConfirmarAccionMsj
        title={popupMsgConfig.title}
        isVisible={popupMsgConfig.show}
        handleConfirm={onHandleConfirm}
        handleCancel={onHandleCancel}
        message={popupMsgConfig.message}
        typeMessage={popupMsgConfig.type}
        typeInputInfo={null}
      />
      {pdfReport && reportName && (
        <ReportWithLocalDataModal
          show={pdfReport !== null}
          onClose={() => setPdfReport(null)}
          data={onParseDtoToPrint() ?? []}
          fileName={reportName}
          mode={pdfReport ?? 'Viewer'}
          parameters={{
            Reporte_Filtro: `${DateUtils.pickersDateToApiDate(
              searchFilter.registroDesde,
            )} - ${DateUtils.pickersDateToApiDate(searchFilter.registroHasta)}`,
          }}
          template={reportName}
          key="reportDesignerCandidates"
        />
      )}
      <PayrollMain
        actionsMenu={options}
        onMenuButtonClick={onMenuButtonClick}
        getButtonDisabled={getButtonDisabled}
        currentWorking={tabs.tabs[tabs.current].currentExecutingAction}
        tabs={tabs}
        onTabChanged={onTabChanged}
        onTabClosing={onTabClosing}
      />
    </>
  )
}

export default memo(Candidatos)

export const defaultDataCandidate: NewCandidate = {
  codigo: 0,
  fecha: DateUtils.getCurrentDateAsString(),
  ciudad: null,
  nombres: '',
  apellidos: '',
  identificacion: '',
  telefono: '',
  email: '',
  departamento: null,
  aplica: null,
  liderazgo: false,
  actitud: false,
  comunicacion: false,
  flexibilidad: false,
  colaboracion: false,
  dedicacion: false,
  confianza: false,
  integridad: false,
  iniciativa: false,
  aprendizaje: false,
  carpeta: '',
  loader: { show: false, mensaje: '' },
}

export const NewTabButtons: CustomButtons = {
  Nuevo: true,
  Guardar: true,
  Editar: false,
  Eliminar: false,
  Buscar: true,
  Imprimir: false,
  Deshacer: true,
  Limpiar: false,
}

export const newCity: OptionCiudad = {
  codigo: -1,
  nombre: '',
  codigoPais: -1,
  nombrePais: '',
  codigoProvincia: -1,
  nombreProvincia: '',
}

export const parseCndidateToNewCandidateType = (
  candidate: Candidate,
): NewCandidate => {
  return {
    codigo: candidate.codigo,
    fecha: DateUtils.apiDateToPickersDate(candidate.fecha),
    ciudad: {
      ...newCity,
      codigo: candidate.ciudad ?? -1,
      nombre: candidate.ciudadNombre ?? '',
    },
    nombres: candidate.nombres,
    apellidos: candidate.apellidos,
    identificacion: candidate.identificacion,
    telefono: candidate.telefono,
    email: candidate.email,
    departamento: null,
    aplica: {
      codigo: candidate.rol,
      descripcion: '',
      contrato: '',
      estado: -1,
    },
    liderazgo: candidate.liderazgo,
    actitud: candidate.actitud,
    comunicacion: candidate.comunicacion,
    flexibilidad: candidate.flexibilidad,
    colaboracion: candidate.colaboracion,
    dedicacion: candidate.dedicacion,
    confianza: candidate.confianza,
    integridad: candidate.integridad,
    iniciativa: candidate.iniciativa,
    aprendizaje: candidate.aprendizaje,
    carpeta: candidate.carpeta,
    loader: { show: false, mensaje: '' },
  }
}
