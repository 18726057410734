
import React from 'react'
import { ButtonTypes } from '../../../views/componentes/globalMenu/types'
import Modalform from '../../../views/componentes/modalform'
import { CCard, CCardBody, CCardHeader } from '@coreui/react-pro'
import { CheckBox, LoadPanel, TextBox } from 'devextreme-react'
import { GlobalMenu } from '../../../views/componentes/globalMenu/globalMenu'
import { AccionMenu, ToastTypes } from '../../../store/types'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import RowContainer from '../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../views/componentes/colContainer'
import Labeled from '../../../views/componentes/labeledInput/labeledInput'
import FIleInput from '../fileInput'
import { changeLoader } from '../../../store/reducers'
import { addToast } from '../../../store/toasterReducer'
import DataGrid, { Column, ColumnChooser, Editing, Export, Pager, Paging } from 'devextreme-react/data-grid'
import { getAllowedPageSizes } from '../../../helpers/Helper'
import ItemsCountIndicator from '../indicadorNroRegistros'
import { EAplicationsAcatha } from '../../../store/enumsAplication'
import { ImportExcellService } from '../../clientes/pages/docsCtasXCobrar/components/impportarExcel/service/importExcell.service'
import { Workbook } from 'exceljs'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { saveAs } from 'file-saver';
import { consoleService } from '../../../services/console.service'
import { DetalleMov, Series } from './types/types'
import { getIndexProvider } from '../../shared/helpers/funciones'


interface IModalIngresoSeries extends React.PropsWithChildren {
  onChanged: (dataLocal: any) => void
  onCancel: () => void
  show: boolean
  data?: any
  dataSeries: Array<Series>
  dataDetalle: Array<DetalleMov>
  codModulo: number
}

const menuDefecto = [
  {
    icon: 'fal fa-print',
    id: 'Imprimir',
    text: 'Imprimir',
    type: ButtonTypes.print,
  },
  {
    icon: 'fal fa-save',
    id: 'Guardar',
    text: 'Guardar',
    type: ButtonTypes.save,
  },
]


export const ModalIngresoSeries: React.FC<IModalIngresoSeries> = (props) => {
  const { onChanged, onCancel, data, dataSeries, show, codModulo, dataDetalle } = props
  const dispatch = useDispatch()
  const dataGrid = React.useRef<any>()

  const botones = menuDefecto.map(acc => {
    const boton: AccionMenu = {
      nombre: acc.id,
      icon: acc.icon,//as any,
      actionType: acc.type,
      main: false,
      modal: false,
      shortcut: null
    }
    return boton;
  });


  const playLoader = React.useCallback(() => {
    dispatch(changeLoader({ show: true, mensaje: 'Cargando...' }))
  }, [dispatch])

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoader({ show: false, mensaje: '' }))
  }, [dispatch])

  const [acciones,] = React.useState<Array<AccionMenu>>(botones)
  const loader = useSelector((state: RootState) => state.global.loader);
  const [filtrarPor, setFiltrarPor] = React.useState<string>('')
  const [seriesIngresadas, setSeriesIngresadas] = React.useState<string>(null)
  const [minIngresadas, setMinIngresadas] = React.useState<string>(null)
  const [resultados, setResultados] = React.useState<Array<any>>([]);
  const [resultadosAux, setResultadosAux] = React.useState<Array<any>>([]);

  const [changes, setChanges] = React.useState<any>();
  const [editRowKey, setEditRowKey] = React.useState<any>();


  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(addToast({
      content: mensaje,
      title: 'Números de Series o IMEI',
      type: tipo
    }));
  }, [dispatch])
  const docaccept = ['.xls']



  const onVerificateNumSerie = React.useCallback((data: Array<any>) => {
    let series = 0
    let min = 0
    data.forEach((item) => {
      if (String(item?.serie).length > 1) {
        series++
      }

      if (String(item?.min).length > 2) {
        min++
      }
      setSeriesIngresadas(series.toString())
      setMinIngresadas(min.toString())
    })
  }, [])

  const onFilterSeries = React.useCallback((data: Array<any>, dataAux: Array<any>, value: string) => {
    const auxRes = dataAux
    let arrayResFilter: Array<any> = []
    if (value !== null && value !== undefined && value !== '' && value) {
      arrayResFilter = data.filter(r => {
        if (r && r?.serie && r.serie.toLowerCase().includes(value.toLowerCase())) {
          return r
        }
      })
      console.log(arrayResFilter)
      setResultados(arrayResFilter)
    } else {
      setResultados(auxRes)
    }
  }, [])

  const onInitData = React.useCallback(() => {
    consoleService.log(dataSeries)
    setResultados(dataSeries)
    setResultadosAux(dataSeries)
    onVerificateNumSerie(dataSeries)
    onFilterSeries(dataSeries, dataSeries, '')
  }, [dataSeries, onVerificateNumSerie, onFilterSeries])

  const onFileChanged = React.useCallback(async (file: any) => {
    if (file && file.length > 0) {
      const upload: any = {}
      if (codModulo === parseInt(EAplicationsAcatha.IngresosEgresos)) {
        upload.plantilla = 'items'
        upload.local = data?.local ?? -1
      }
      try {
        playLoader()
        upload.file = file[0]

        const subirPlantilla = await ImportExcellService.plantillaImportar(upload);
        stopLoader()
        console.log("subirPlantilla", subirPlantilla);

        if (subirPlantilla.error === false) {
          setToast(subirPlantilla.message, ToastTypes.Success)
        }
        const temp: Array<any> = subirPlantilla['auto']
        let arrayDetalle = []
        arrayDetalle = dataDetalle.map(item => {
          return item
        })

        if (resultados.length === temp.length) {
          arrayDetalle.map(item => {
            if (item?.numeroSerie === 1) {
              let cantidad: number = 0
              cantidad = item.cantidad
              let contador: number = 0
              for (let index = 0; index < cantidad; index++) {
                if (item.codigo === temp[index].codigo) {
                  contador++
                }

              }
              if (contador !== cantidad) {
                setToast(`El numero de series para el item ${item.barras} ${contador} es diferente a la cantidad ingresada en el comprobante ${cantidad}`, ToastTypes.Danger)
              }
            }
          })
          // envia la data series al padre
          onChanged(subirPlantilla['auto'])
        } else {
          setToast(`El archivo no poseee el numero de registros necesarios ${temp.length} para  la cantidad de series a registrar ${resultados.length}`, ToastTypes.Danger)
        }

      } catch (error) {
        stopLoader()
        setToast(error.message, ToastTypes.Danger)
      }
    }

  }, [codModulo, data, playLoader, setToast, stopLoader, dataDetalle, resultados, onChanged])

  const onSave = React.useCallback(() => {
    onChanged(resultados)
  }, [onChanged, resultados])


  const onChangedValue = React.useCallback((data, key: string) => {
    if (data?.value !== null && data?.value !== undefined && data?.value !== '') {
      switch (key) {
        case 'filtrarPor':
          setFiltrarPor(data?.value)
          break;
        case 'seriesIngresadas':
          setSeriesIngresadas(data?.value)
          break;
        case 'minIngresadas':
          setMinIngresadas(data?.value)
          break;

        default:
          break;
      }
    }
  }, [])

  const returnAction = (boton: AccionMenu) => {

    switch (boton.actionType) {
      case ButtonTypes.save:
        onSave()
        break;
      case ButtonTypes.print:
        console.log('imprimir')
        break;
      default:
        break;
    }
  }


  const setCellCantidad = React.useCallback(async (rowData, value, currentRowData) => {
    let newCantidad: Array<any> = []
    if (currentRowData !== null && currentRowData !== undefined && value) {
      newCantidad = resultados.slice(0)
      const index = await getIndexProvider(newCantidad, 'min', currentRowData?.min)
      if (index > -1) {
        const existe: any = { ...newCantidad[index] }
        existe.min = value
        newCantidad[index] = existe
      }
      onVerificateNumSerie(newCantidad)
      setResultados(newCantidad)
    }
  }, [resultados, onVerificateNumSerie])

  const setCellSerie = React.useCallback(async (rowData, value, currentRowData) => {
    let newCantidad: Array<any> = []
    if (currentRowData !== null && currentRowData !== undefined && value) {
      let existe: boolean = false
      resultados.forEach(s => {
        if (value === s.serie) {
          existe = true
        }
      })
      if (existe) {
        setToast(`La serie: ${value} ya existe, utilice el filtro para cambiar la duplicada`, ToastTypes.Danger)
      }
      newCantidad = resultados.slice(0)
      const index = await getIndexProvider(newCantidad, 'serie', currentRowData?.serie)
      if (index > -1) {
        const existe: any = { ...newCantidad[index] }
        existe.serie = value
        newCantidad[index] = existe
      }
      onVerificateNumSerie(newCantidad)
      setResultados(newCantidad)
    }
  }, [resultados, setToast, onVerificateNumSerie])

  const onChangesChange = React.useCallback((changes) => {
    setChanges(changes);
  }, [])

  const onEditRowKeyChange = React.useCallback((editRowKey) => {
    setEditRowKey(editRowKey);
  }, [])

  const getButtonDisabled = React.useCallback((button: AccionMenu) => {
    switch (button.actionType) {

      case ButtonTypes.print:
        return true
        break
      default:
        return false;
    }
  }, [])


  React.useEffect(() => {
    onInitData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  const botonesModal = () => {
    return (

      <GlobalMenu
        acciones={acciones}
        onClick={returnAction}
        getButtonDisabled={getButtonDisabled}
      />

    );
  }

  const onExportExcell = React.useCallback((e) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('Main sheet')
    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Series_Inventario.xlsx')
      });
    });
  }, [])


  const bodyModal = () => {

    return (
      <>
        <RowContainer>
          <CustomCol xs="12" md={"6"}>
            <RowContainer>
              <CustomCol xs="12" md="6">
                <Labeled label='Filtrar por:'>
                  <TextBox
                    value={filtrarPor}
                    onValueChanged={(data) => onChangedValue(data, 'filtrarPor')}
                    onEnterKey={() => onFilterSeries(resultados, resultadosAux, filtrarPor)} // filtrar
                    onKeyUp={(d: any) => {
                      if (d?.event && d.event.target && d.event.target['value']) {
                        onFilterSeries(resultados, resultadosAux, d.event.target['value'])
                      } else {
                        setResultados(resultadosAux)
                      }
                    }}
                  />
                </Labeled>
              </CustomCol>
            </RowContainer>
            <RowContainer>
              <CustomCol xs="12" md="6">
                <Labeled label="Series Ingresadas:">
                  <TextBox
                    value={seriesIngresadas}
                    readOnly
                    onValueChanged={(data) => onChangedValue(data, 'seriesIngresadas')}
                  />
                </Labeled>

              </CustomCol>
              <CustomCol xs="12" md="6">
                <Labeled label="# Min Ingresadas:">
                  <TextBox
                    value={minIngresadas}
                    readOnly
                    onValueChanged={(data) => onChangedValue(data, 'minIngresadas')}
                  />
                </Labeled>

              </CustomCol>
            </RowContainer>
          </CustomCol>
          <CustomCol xs="12" md="6">
            <Labeled label='Archivo'>
              <FIleInput
                accept={''}
                allowedFileExtensions={docaccept}
                onChanged={onFileChanged}
                showDownloadDemo={false}
              />
            </Labeled>
          </CustomCol>
        </RowContainer>
        <ItemsCountIndicator items={resultados} />
        <DataGrid
          style={{ marginTop: '10px' }}
          ref={dataGrid}
          id="codigo"
          focusedRowKey="codigo"
          selection={{ mode: 'single' }}
          dataSource={resultados}
          showColumnLines={true}
          showRowLines={true}
          showBorders={true}
          columnResizingMode="widget"
          onExporting={onExportExcell}
          loadPanel={{
            enabled: true,
            height: 90,
            indicatorSrc: '',
            shading: false,
            shadingColor: '',
            showIndicator: true,
            showPane: true,
            text: 'Cargando...',
            width: 200,
          }}
        >
          <Editing
            mode="cell"
            allowUpdating
            changes={changes}
            onChangesChange={onChangesChange}
            editRowKey={editRowKey}
            onEditRowKeyChange={onEditRowKeyChange}
          />
          <Paging defaultPageSize={10} />
          <ColumnChooser enabled={true} mode="select" />
          <Export enabled={true} allowExportSelectedData={true} />
          <Pager
            visible={resultados.length > 0 ? true : false}
            allowedPageSizes={getAllowedPageSizes(resultados)}
            displayMode={'full'}
            showPageSizeSelector={true}
            showInfo={true}
            showNavigationButtons={true}
          />
          <Column allowEditing={false} dataField="barras" caption="Codigo" width="100%" />
          <Column allowEditing={false} dataField="descripcion" caption="Descripción" width="100%" />
          <Column allowEditing={true} setCellValue={setCellSerie} dataField="serie" caption="Serie" width="100%" />
          <Column allowEditing={true} setCellValue={setCellCantidad} dataField="min" caption="Min" width="100%" />

        </DataGrid>
      </>
    )
  }


  const renderContent = () => {
    return (
      <CCard>
        <CCardHeader>
          {botonesModal()}
        </CCardHeader>
        <CCardBody>
          <LoadPanel
            shadingColor="rgba(0,0,0,0.4)"
            position={'center'}
            visible={loader.show}
            showIndicator={true}
            shading={true}
            showPane={true}
            message={loader.mensaje}
            closeOnOutsideClick={false}
          />
          {bodyModal()}
        </CCardBody>
      </CCard>
    )
  }

  return (
    <>
      <Modalform
        name={'ingresarSeries'}
        key={'ingresarSeries'}
        headerTitle={"Número de Series o IMEI"}
        childrenBody={renderContent()}
        closeOnBackdrop={false}
        show={show}
        onClose={() => onCancel()}
        centered={true}
        size='lg'
      />
    </>
  )
}



