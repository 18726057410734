import { OptionCiudad } from "../../../../../containers/component/formLocal/types/types";
import { localidadesService } from "../../../../../services/localidades.service";
import { TEstado, TipoAgente } from "../../../../../store/types";
import { EstadosCivilOption } from "../../../../componentes/estadosCivilLookUp/store/type";
import { estadosService as estadosCivilService } from "../../../../componentes/estadosCivilLookUp/service/estados.service";
import { formasPagoService } from "../../../../componentes/formasPagoLookUp/service/formasPago.service";
import { TFormasPago } from "../../../../componentes/formasPagoLookUp/store/type";
import { impresionService } from "../../../../componentes/impresionesLookUp/service/impresiones.service";
import { ImpresionOption } from "../../../../componentes/impresionesLookUp/store/type";
import { clasificacionesService } from "../../../../componentes/lookUpClasificacionAdicional/service/clasificaciones.service";
import { ClasificacionOption } from "../../../../componentes/lookUpClasificacionAdicional/store/type";
import { tipoAgenteService } from "../../../../componentes/tipoAgente/service/tipoAgente.service";
import { tipoClienteService } from "../../../../componentes/tipoCliente/service/tipoCliente.service";
import { tipoEstadoService } from "../../../../componentes/tipoEstado/service/tipoEstado.service";
import { tipoIdentificacionService } from "../../../../componentes/tipoIdentificacion/service/tipoId.service";
import { TipoIdentificacion, TipoTransaccion } from "../../../../componentes/tipoIdentificacion/store/types";
import { zonasService } from "../../../../componentes/zonasLookUp/service/zonas.service";
import { TipoCliente, TipoProveedor } from "../../../../ventas/types/types";
import { clientesOrigen } from "../../../components/origenClienteLookUp/service/origenclientes.service";
import { OpcionCliente } from "../../../components/origenClienteLookUp/store/types";
import { detalleSucursalesService } from "../components/documento/componentes/sucursales/contactosLookUp/service/detalleSucursales.service";
import { TDetalleContactosSucursales } from "../components/documento/componentes/sucursales/contactosLookUp/types/type";
import { TOptionGenericoSelect } from "../types/types";
import { estudiosService } from "../../../../componentes/nivelesEstudioLookUp/service/estudios.service";
import { NivelesEstudioOption } from "../../../../componentes/nivelesEstudioLookUp/store/type";
import { estadosService as dpSituacionLaboral } from "../../../../componentes/situacionLaboralLookUp/service/estados.service";
import { estadosService as dpRegimenEmpresa } from "../../../../componentes/tiposEmpresasLookUp/service/estados.service";
import { TiposEmpresasOption } from "../../../../componentes/tiposEmpresasLookUp/store/type";
import { desempenoService } from "../../../../componentes/lookUpDesempenoAdicional/service/desempeno.service";
import { DesempenoOption } from "../../../../componentes/lookUpDesempenoAdicional/store/type";
import { tipoProveedorService } from "../../../../componentes/tipoProveedor/service/tiposProveedor.service";
import { sustentoTributarioServices } from "../../../../componentes/sustentosTributarioLookUp/services/sustentoTributario.service";
import { SutentosTributarios } from "../../../../componentes/sustentosTributarioLookUp/types/types";
import { EspecialidadPrv } from "../../../../componentes/especialidadProveedor/store/types";
import { specialityServices } from "../../../../componentes/especialidadProveedor/service/especialidadproveedor.service";

export async function getTipoIdetificacion() {
  const data = await tipoIdentificacionService.getTipoIdetificacion(TipoTransaccion.venta);
  let opt = [];
  if (data.error === false) {
    if (data.auto.length > 0) {
      opt = data.auto.map(x => {
        const item: TipoIdentificacion = {
          codigo: String(x.codigo).toString() ?? '',
          descripcion: x.descripcion ?? '',
        };
        return item;
      });
    }
  }
  return opt
}

export async function getZonas() {
  const data = await zonasService.getZonas("Elija una opción");
  let opt = [];
  if (data.error === false) {
    if (data.auto.length > 0) {
      opt = data.auto.map(x => {
        const item: TOptionGenericoSelect = {
          codigo: parseInt(x.codigo) ?? -1,
          descripcion: x.descripcion ?? '',
          estado: x.estado ?? 0,
        };
        return item;
      });
    }

  }
  return opt
}

export async function getTiposAgente() {
  const data = await tipoAgenteService.getTipoAgente("Tipo de Agente");
  let opt = [];
  if (data.error === false) {
    if (data.auto.length > 0) {
      opt = data.auto.map(x => {
        const item: TipoAgente = {
          codigo: parseInt(x.codigo) ?? -1,
          descripcion: x.descripcion ?? '',
        };
        return item;
      });
    }
  }
  return opt
}

export async function getTiposClientes() {
  const data = await tipoClienteService.getTipoCliente("Tipo de Cliente");
  let opt = [];
  if (data.error === false) {
    if (data.auto.length > 0) {
      opt = data.auto.map(x => {
        const item: TipoCliente = {
          codigo: parseInt(x.codigo) ?? -1,
          descripcion: x.descripcion ?? '',
          estado: x.estado ?? 0,
          estadoDescripcion: x.estadoDescripcion ?? '',
          porcentajeDescuento: x.porcentajeDescuento ?? 0,
        };
        return item;
      });
    }
  }
  return opt
}

export async function getEstados() {
  const data = await tipoEstadoService.getTipoEstado("Elija un estado");
  let opt = [];
  if (data.error === false) {
    if (data.auto.length > 0) {
      opt = data.auto.map(x => {
        const item: TEstado = {
          codigo: parseInt(x.codigo) ?? -1,
          descripcion: x.descripcion ?? '',
        };
        return item;
      });
    }
  }
  return opt
}

export async function getDetalleContactos(codigoEntidad: number) {
  const data = await detalleSucursalesService.getDetalleSucursales(codigoEntidad);
  let opt = [];
  if (data.error === false) {
    if (data.auto.length > 0) {
      opt = data.auto.map(x => {
        const item: TDetalleContactosSucursales = {
          codigo: parseInt(x.codigo) ?? -1,
          descripcion: x.descripcion ?? '',
          numero: x.numero ?? '',
          tipo: x.tipo ?? '',
          tipoDescripcion: x.tipoDescripcion ?? '',
          estado: parseInt(x.codigo) ?? -1,
          asociadoCodigo: parseInt(x.codigo) ?? -1,
          asociadoTipo: x.asociadoTipo ?? '',
          principal: parseInt(x.codigo) ?? -1,
        };
        return item;
      });
    }
  }
  return opt
}

export async function getFormasPago(grupo: string, mensaje: string) {

  const data = await formasPagoService.getFormasPagoProvider(grupo, mensaje);

  let opt: Array<TFormasPago> = []
  if (data.auto.length > 0) {
    opt = data.auto.map((x) => {
      const item: TFormasPago = {
        codigoFormaPago: parseInt(x.codigoFormaPago),
        descripcionFormaPago: x.descripcionFormaPago,
        transaccionAsignacion: null,
        codigoCuenta: null,
        itemAsignacion: x.asignado,
        cuentaNumero: null,
        cuentaDescripcion: null,
      }
      return item
    })
  }

  return opt
}

export async function getImpresiones(mensaje: string) {

  const data = await impresionService.getImpresiones(mensaje);

  let opt: Array<ImpresionOption> = []
  if (data.auto.length > 0) {
    opt = data.auto.map((x) => {
      const item: ImpresionOption = {
        codigo: parseInt(x.codigoImpresion) ?? -1,
        descripcion: x.nombre ?? '',
        codigoTic: x.codigoTic ?? 0,
        comprobanteTic: x.comprobanteTic ?? '',
        archivoImpresion: x.archivoImpresion ?? '',
        limiteImpresion: x.limiteImpresion,
        electronica: x.electronica === 1 ? true : false,
      }
      return item
    })
  }

  return opt
}


export async function getOrigen(mensaje: string) {
  const data = await clientesOrigen.getOptions();
  let opt: Array<OpcionCliente> = []
  if (data.auto.length > 0) {
    opt = data.auto.map((x) => {
      const item: OpcionCliente = {
        opcion: String(x.opcion).toString() ?? '',
      }
      return item
    })
  }
  return opt
}

export async function getCiudades(idProvincia: number) {
  const ciudades = [] as Array<OptionCiudad>;
  const data = await localidadesService.getDpCiudades(idProvincia, "Elija una opción");

  if (data && data.length > 0) {
    for (const item of data) {
      ciudades.push({
        codigoPais: Number(item?.paisCodigo) ?? 0,
        nombrePais: String(item?.paisNombre) ?? "",
        codigoProvincia: Number(item?.provinciaCodigo) ?? 0,
        nombreProvincia: String(item?.provinciaNombre) ?? "",
        codigo: Number(item?.codigo) ?? 0,
        nombre: String(item?.nombre) ?? "",
      })
    }
  }
  return ciudades;
}

export async function getEstadosCivil() {
  const data = await estadosCivilService.getEstados("Elija un estado");
  let opt = [];
  if (data.error === false) {
    if (data.auto.length > 0) {
      opt = data.auto.map(x => {
        const item: EstadosCivilOption = {
          codigo: parseInt(x?.codigo) ?? -1,
          descripcion: x?.descripcion ?? '',
        };
        return item;
      });
    }
  }
  return opt
}

export async function getClasificacion() {
  const data = await clasificacionesService.getClasificacionesAdicionales("Elija una opción");
  let opt = [];
  if (data.error === false) {
    if (data.auto.length > 0) {
      opt = data.auto.map((x) => {
        const item: ClasificacionOption = {
          codigo: parseInt(x?.codigo) ?? -1,
          descripcion: x?.descripcion ?? '',
          tipoCodigo: x?.tipoCodigo ?? 0,
          grupo: x?.grupo ?? '',
          orden: parseInt(x?.orden) ?? 1,
          estado: parseInt(x?.estado) === 1 ? true : false,
        }
        return item
      })
    }
  }
  return opt
}

export async function getEstudios() {
  const data = await estudiosService.getEstados("Elija una opción");
  let opt = [];
  if (data.error === false) {
    if (data.auto.length > 0) {
      opt = data.auto.map(x => {
        const item: NivelesEstudioOption = {
          codigo: parseInt(x.codigo) ?? 0,
          descripcion: x.descripcion ?? '',
        };
        return item;
      });
    }
  }
  return opt
}

export async function getDpSituacionLaboral() {
  const data = await dpSituacionLaboral.getEstados("Elija una opción");
  let opt = [];
  if (data.error === false) {
    if (data.auto.length > 0) {
      opt = data.auto.map(x => {
        const item: NivelesEstudioOption = {
          codigo: parseInt(x.codigo) ?? 0,
          descripcion: x.descripcion ?? '',
        };
        return item;
      });
    }
  }
  return opt
}

export async function getDpRegimenEmpresa() {
  const data = await dpRegimenEmpresa.getEstados("Elija una opción");
  let opt = [];
  if (data.error === false) {
    if (data.auto.length > 0) {
      opt = data.auto.map(x => {
        const item: TiposEmpresasOption = {
          codigo: parseInt(x.codigo) ?? 0,
          descripcion: x.descripcion ?? '',
        };
        return item;
      });
    }
  }
  return opt
}

export async function getDpDesempenios() {
  const data = await desempenoService.getDesempenoAdicionales("Elija una opción");
  let opt = [];
  if (data.error === false) {
    if (data.auto.length > 0) {
      opt = data.auto.map(x => {
        const item: DesempenoOption = {
          codigo: parseInt(x.codigo) ?? -1,
          descripcion: x.descripcion ?? '',
          codigoEstado: parseInt(x.codigoEstado) ?? 1,
          descripcionEstado: x.descripcionEstado,
        };
        return item;
      });
    }
  }
  return opt
}

export async function getDpOrigen() {
  const data = await clientesOrigen.getOptions();
  let opt = [];
  if (data.error === false) {
    if (data.auto.length > 0) {
      opt = data.auto.map(x => {
        const item: OpcionCliente = {
          opcion: String(x.opcion).toString() ?? '',
        };
        return item;
      });
    }
  }
  return opt
}

export async function getTipoProveedor() {
  const data = await tipoProveedorService.getTiposProveedor("Elige una opción");
  let opt = [];
  if (data.error === false) {
    if (data.auto.length > 0) {
      opt = data.auto.map(x => {
        const item: TipoProveedor = {
          codigo: x?.codigo ?? '-1',
          descripcion: x?.descripcion ?? '',
        };
        return item;
      });
    }
  }
  return opt
}

export async function getSustentosTributarios() {
  const data = await sustentoTributarioServices.getTipos();
  let opt = [];
  if (data.error === false) {
    if (data.auto.length > 0) {
      opt = data.auto.map((x: SutentosTributarios) => {
        return {
          codigo: x?.codigo ?? '',
          tipo: x?.tipo ?? '', //to show
          estado: x?.estado ?? 0,
          descripcionEstado: x?.descripcionEstado ?? '',
        }
      })
    }
  }
  return opt
}

export async function getDpEspecialidades() {
  const data = await specialityServices.getSpeciality("Elija una opción");
  let opt = [];
  if (data.error === false) {
    if (data.auto.length > 0) {
      opt = data.auto.map(x => {
        const item: EspecialidadPrv = {
          codigo: parseInt(x?.codigo) ?? 0,
          estado: parseInt(x?.estado) ?? 0,
          descripcion: x?.descripcion ?? '',
        };
        return item;
      });
    }
  }
  return opt
}
