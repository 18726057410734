import React from 'react'
import CIcon from '@coreui/icons-react'
import { CCardBody, CCardHeader, CCollapse, CLink } from '@coreui/react-pro'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput'
import { RadioGroup, TextBox } from 'devextreme-react'
import MarcasWebLookUp from '../../../../../../componentes/marcasWebLookUp'
import { useDispatch, useSelector } from 'react-redux'
import { changeFilter, setCollapsed } from '../../store/serachReducer'
import { MarcaWeb } from '../../../../../../componentes/marcasWebLookUp/types/types'
import { RootState } from '../../../../../../../store/store'
import { cilChevronBottom, cilChevronTop } from '@coreui/icons'


export const optionsActivos = [
  { value: 1, label: 'Activos' },
  { value: 2, label: 'Inactivos' },
]

interface ISearchProps extends React.PropsWithChildren {
  onSearchEnter: () => void
}

const FiltroBusqueda: React.FunctionComponent<ISearchProps> = (props) => {
  const { onSearchEnter } = props
  const dispatch = useDispatch()
  const [marcasWeb, setMarcasWeb] = React.useState<Array<MarcaWeb>>([])
  const [estado, setEstado] = React.useState(optionsActivos[0])
  const searchFilter = useSelector((state: RootState) => state.inventarios.catalogos.grupos.search.filter)
  const collapsed = useSelector((state: RootState) => state.inventarios.catalogos.grupos.search.colapsado)



  const onChangeData = React.useCallback((data, key: string) => {
    if (data !== null && data !== undefined) {
      let value = null
      if (data?.event !== null && data?.event !== undefined) {
        value = data?.value
      } else if (key === 'categoriaWebLookup') {
        value = data
      }
      if (value !== null && value !== undefined) {
        dispatch(changeFilter({
          ...searchFilter,
          [key]: value
        }))
      }
    }
  }, [dispatch, searchFilter])

  const onFilterMarcaWeb = React.useCallback((data) => {
    onChangeData(data, 'categoriaWeb')
    if (data?.event !== null && data?.event !== undefined) {
      const marcaWeb: string = data?.value
      if (marcasWeb !== null && marcasWeb !== undefined && marcasWeb.length > 0) {
        let newArayy = []
        newArayy = marcasWeb.map(item => {
          if (item?.descripcion && item?.descripcion.indexOf(marcaWeb) > 0) {
            return item
          }
        })
        setMarcasWeb(newArayy)
      }
    }
  }, [marcasWeb, onChangeData])


  return (
    <>
      <CCardHeader
        onClick={() => dispatch(setCollapsed(!collapsed))}
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <strong> {collapsed ? '' : 'Filtro de Búsqueda'} </strong>
        <CLink className="card-header-action">
          <CIcon icon={collapsed ? cilChevronBottom : cilChevronTop} />
        </CLink>
      </CCardHeader>
      <CCollapse visible={collapsed}>
        <CCardBody>
          <RowContainer>
            <RowContainer>
              <CustomCol xs="12" md="4">
                <Labeled label="Descripción:">
                  <TextBox
                    value={searchFilter?.descripcion ?? ''}
                    onValueChanged={(data) => onChangeData(data, 'descripcion')}
                    onEnterKey={onSearchEnter}
                  />
                </Labeled>
              </CustomCol>
              <CustomCol xs="12" md="3">
                <Labeled label="Categoría web:">
                  <TextBox
                    value={searchFilter?.categoriaWeb ?? ''}
                    onValueChanged={(data) => onFilterMarcaWeb(data)}
                  // onEnterKey={onSearchEnter}
                  />
                </Labeled>
              </CustomCol>
            </RowContainer>
            <RowContainer>
              <CustomCol xs="12" md="4" style={{ marginTop: '8px' }}>
                <Labeled label=''>
                  <RadioGroup
                    name="grupos-activos"
                    layout="horizontal"
                    displayExpr="label"
                    items={optionsActivos}
                    value={searchFilter?.estado ?? null}
                    onValueChanged={(data) => onChangeData(data, 'estado')}
                  />
                </Labeled>
              </CustomCol>
              <CustomCol xs="12" md="3" style={{ marginTop: '8px' }}>
                <Labeled label=''>
                  <MarcasWebLookUp
                    selected={searchFilter?.categoriaWebLookup ?? null}
                    onChanged={(data) => onChangeData(data, 'categoriaWebLookup')}
                    onChangedOptions={(data) => setMarcasWeb(data)}
                    provider={marcasWeb}
                  />
                </Labeled>
              </CustomCol>
            </RowContainer>
          </RowContainer>
        </CCardBody>
      </CCollapse>
    </>
  )
}

export default React.memo(FiltroBusqueda)