import React, { useCallback, useEffect } from 'react'
import { ContractDto, ContratoDatosEdicion, ContratosDatosEdicion, ObservacionesDatosEdicion, OpcionesEmpresaTipos, PrivacionesTipos, SaveContract } from '../../types/types'
import { DocumentInfo, ToastTypes } from '../../../../../../store/types'
import { TabState } from '../../../../../../store/genericTabTypes'
import TabsEdition from './components/tabsEdition'
import { useDispatch, useSelector } from 'react-redux'
import { clearButtonClick, setButtons, setCurrentExecutingAction, setNameTab } from '../../store/tabsReducer'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import { RootState } from '../../../../../../store/store'
import { consoleService } from '../../../../../../services/console.service'
import { StatesEdition } from '../../../../../../store/enums'
import { addToast } from '../../../../../../store/toasterReducer'
import { setChangeLoader, setDatosEdicion } from '../../store/editDataReducer'
import { setDatosEdicionBackup } from '../../store/configReducer'
import { gruposContratos, gruposPersonal } from './components/contrato'
import { DateUtils } from '../../../../../../helpers/dateUtils'
import { jornadaService } from '../../../../../componentes/jornadaLookUp/services/joranda.services'
import { payrollDepartamentServices } from '../../../../../componentes/cargosDepartamentosLookup/services/cargosDepartamentos.services'
import { tipoContratoService } from '../../../../../componentes/tipoContratoLookup/services/tipoContrato.service'
import { orgrollServices } from '../../../../../componentes/organicoCargosLookup/services/organicoCargos.services'
import { tipoDiscapacidadService } from '../../../../../componentes/tipoDiscapacidadLookUp/services/tipoDiscapacidad.service'
import BlockUi from '../../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../../views/componentes/loadingindicator/loadingindicator'
import { ValidationGroup, ValidationSummary } from 'devextreme-react'
import { isMobile } from 'react-device-detect'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../views/componentes/colContainer'
import VisualizaError from '../../../../../ventas/pages/shared/visualizaError/visualizaError'
import ListErrosValidationForm from '../../../../../componentes/listadoErroresValidacionForm'
import { MessagesKeys, lh } from '../../../../../../helpers/localizationHelper'
import { ContratsServices } from '../../services/contratos.services'
import { ModuleButtons } from '../..'
import { getIndexProvider } from '../../../../../shared/helpers/funciones'
import { ReportWithLocalDataModal } from '../../../../../../views/componentes/xtrareports/ReportWithLocalDataModal'

interface INuevoProps extends React.PropsWithChildren {
  info: DocumentInfo<ContratoDatosEdicion>
  tab: TabState<ContratoDatosEdicion>
  tabId: string
}

const Nuevo: React.FunctionComponent<INuevoProps> = (props) => {
  const { tabId, tab, info } = props
  const dispatch = useDispatch()
  const tabs = useSelector((state: RootState) => state.nomina.contratos.tabs)
  const usuario = useSelector((state: RootState) => state.global.session.usuario)
  const loading = useSelector((state: RootState) => state.nomina.contratos.editData[tabId].loading)
  const contractsState = useSelector((state: RootState) => state.nomina.contratos.editData[tabId])
  const contractsStateBackup = useSelector((state: RootState) => state.nomina.contratos.config[tabId])
  const observationsState = useSelector((state: RootState) => state.nomina.contratos.editData[tabId].observaciones)
  const loader = useSelector((state: RootState) => state.nomina.contratos.editData[tabId].loader)
  const validationContract = React.useRef<any>()
  const [arrayErrors, setArrayErrors] = React.useState<Array<string>>([])
  const [showErrorPopup, setShowErrorPopup] = React.useState<boolean>(false)
  const [reporte, setReporte] = React.useState<null | "Viewer" | "Designer">(null);

  const onSetButtonAction = useCallback(
    (tipo: ButtonTypes | undefined) => {
      dispatch(
        setCurrentExecutingAction({
          tabKey: tabId,
          buttonType: tipo,
        }),
      )
    },
    [dispatch, tabId],
  )

  const onLoader = React.useCallback((showLoader: boolean, text: string) => {
    dispatch(setChangeLoader({
      key: tabId,
      data: {
        show: showLoader,
        mensaje: text
      }
    }))
  }, [dispatch, tabId])

  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(
      addToast({
        title: 'Nómina - Contratos',
        content: mensaje,
        type: tipo,
      }),
    )
  }, [dispatch])

  const modoNuevo = React.useCallback(async (template: number, limpiar: boolean = false) => {
    if (loading === false && !limpiar) {
      return
    }

    onSetButtonAction(ButtonTypes.new)
    onLoader(true, 'Cargando...')
    try {
      const data = { ...defaulContratosData }
      const tipoJornada = await jornadaService.getJornadaLabolar('Elija una opción')
      const departamento = await payrollDepartamentServices.getPyarollsList()
      const tipoContrato = await tipoContratoService.getTipoContrato('Elija un contrato')
      const cargo = await orgrollServices.getorgrollsList(0)
      const discapacidad = await tipoDiscapacidadService.getTipoDiscapacidad('')
      const dataContratos: ContratoDatosEdicion = { ...data.contratos }
      const dataObservaciones: ObservacionesDatosEdicion = { ...data.observaciones }
      // Contratos

      if (tipoContrato?.auto && tipoContrato?.error === false) {
        dataContratos.tipoContrato = tipoContrato?.auto[0]
      }
      if (tipoJornada?.auto && tipoJornada?.error === false) {
        dataContratos.jornada = tipoJornada?.auto[0]
      }
      if (departamento?.auto && departamento?.error === false) {
        dataContratos.departamento = departamento?.auto[0]
      }

      if (cargo?.auto && cargo?.error === false) {
        dataContratos.cargo = cargo?.auto[0]
      }

      dataContratos.proceso = 'Nuevo'
      dataContratos.contrato = gruposContratos[0]
      dataContratos.tipoPersonal = gruposPersonal[0]
      dataContratos.fechaIngreso = DateUtils.getCurrentDateAsString()
      dataContratos.fechaSalida = ''
      dataContratos.numeroTipoParcial = 4
      dataContratos.sueldoNomina = 292
      dataContratos.sueldoNominaB = 300


      // Observaciones
      if (discapacidad?.auto && discapacidad?.error === false) {
        dataObservaciones.discapacidad = discapacidad?.auto[0]
      }
      dataObservaciones.fecha = DateUtils.getCurrentDateAsString()

      data.contratos = dataContratos
      data.observaciones = dataObservaciones

      data.loading = false
      dispatch(setDatosEdicion({
        key: tabId,
        data: { ...data }
      }))
      dispatch(setDatosEdicionBackup({
        key: tabId,
        data: { ...data }
      }))

    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }

    onLoader(false, '')
    onSetButtonAction(undefined)
  }, [loading, onLoader, onSetButtonAction, setToast, dispatch, tabId])


  const cargarContrato = React.useCallback(async (loader: boolean, contractData) => {
    if (loader === false) {
      return
    }

    dispatch(
      setButtons({
        tabKey: tabId,
        buttons: {
          ...ModuleButtons,
          Imprimir: true,
        }
      }),
    )

    onSetButtonAction(ButtonTypes.edit)
    onLoader(true, 'Cargando Contrato...')

    try {
      const contractEdit = contractData?.contratos
      const data = { ...defaulContratosData }
      const tipoJornada = await jornadaService.getJornadaLabolar('Elija una opción')
      const departamento = await payrollDepartamentServices.getPyarollsList()
      const tipoContrato = await tipoContratoService.getTipoContrato('Elija un contrato')
      const cargo = await orgrollServices.getorgrollsList(0)
      const discapacidad = await tipoDiscapacidadService.getTipoDiscapacidad('')
      const dataContratos: ContratoDatosEdicion = { ...data.contratos }
      const dataObservaciones: ObservacionesDatosEdicion = { ...data.observaciones }
      const privaciones: PrivacionesTipos = { ...data.observaciones.privaciones }
      const opciones: OpcionesEmpresaTipos = { ...data.observaciones.opcionEmpres }
      consoleService.log(contractEdit)


      data.codigo = contractEdit?.codigo ?? 0
      // Contratos
      dataContratos.proceso = contractEdit?.procesoDescripcion ?? ''
      dataContratos.codigoHorarioLabores = 0
      dataContratos.codigoEmpleado = contractEdit?.usuarioCodigo ?? 0
      dataContratos.empleado = `${contractEdit?.usuarioApellidos ?? ''} ${contractEdit?.usuarioNombres ?? ''}(${contractEdit?.usuarioCedula ?? ''})`

      if (tipoContrato?.auto && tipoContrato?.error === false) {
        const provider = tipoContrato?.auto.slice(0)
        const index = await getIndexProvider(provider, 'codigo', contractEdit?.tipo ?? 0)
        if (index > -1) {
          dataContratos.tipoContrato = provider[index]
        } else {
          dataContratos.tipoContrato = provider[0]
        }
      }
      if (contractEdit?.tipo === 5 || contractEdit?.tipo === 6 || contractEdit?.tipo === 7) {
        dataContratos.numeroTipoParcial = contractEdit?.horasTiempoParcial ?? 0
        dataContratos.numeroTipoParcialShow = true
      } else {
        dataContratos.numeroTipoParcial = 4
        dataContratos.numeroTipoParcialShow = false
      }
      dataContratos.sueldoNomina = contractEdit?.nominal ?? 0
      if (contractEdit?.fechaIngreso !== '') {
        dataContratos.fechaIngreso = DateUtils.format(contractEdit?.fechaIngreso, 'yyyy-MM-dd', 'dd/MM/yyyy')
      }
      dataContratos.funciones = contractEdit?.funciones ?? ''
      dataContratos.contatoRt = contractEdit?.contrato ?? ''
      dataContratos.contrato = contractEdit?.confianza === 1 ? gruposContratos[1] : gruposContratos[0]
      if (tipoJornada?.auto && tipoJornada?.error === false) {
        const provider = tipoJornada?.auto.slice(0)
        const index = await getIndexProvider(provider, 'codigo', contractEdit?.jornada ?? 0)
        if (index > -1) {
          dataContratos.jornada = provider[index]
        } else {
          dataContratos.jornada = provider[0]
        }
      }
      dataContratos.codigoHorarioLabores = contractEdit?.horarioTrabajo ?? 0
      dataContratos.horarioLabores = contractEdit?.horarioTrabajoDescripcion ?? ''
      if (departamento?.auto && departamento?.error === false) {
        const provider = departamento?.auto.slice(0)
        const index = await getIndexProvider(provider, 'codigo', contractEdit?.organico ?? 0)
        if (index > -1) {
          dataContratos.departamento = provider[index]
        } else {
          dataContratos.departamento = provider[0]
        }
      }
      if (cargo?.auto && cargo?.error === false) {
        const provider = cargo?.auto.slice(0)
        const index = await getIndexProvider(provider, 'organicoRolCodigo', contractEdit?.organicoRol ?? 0)
        if (index > -1) {
          dataContratos.cargo = provider[index]
        } else {
          dataContratos.cargo = provider[0]
        }
      }
      switch (contractEdit?.tipoPersonal) {
        case 1:
          dataContratos.tipoPersonal = gruposPersonal[0]
          break;
        case 2:
          dataContratos.tipoPersonal = gruposPersonal[1]
          break;
        case 3:
          dataContratos.tipoPersonal = gruposPersonal[3]
          break;

        default:
          break;
      }

      // Observaciones
      dataObservaciones.archivo = contractEdit?.archivo ?? ''
      if (contractEdit?.fecha !== '') {
        dataObservaciones.fecha = DateUtils.format(contractEdit?.fecha, 'yyyy-MM-dd', 'dd/MM/yyyy')
      }

      if (contractEdit?.liquido === 2) {
        opciones.sistemasalarioneto = true
      }
      dataObservaciones.observacionesIngreso = contractEdit?.observacionesIngreso ?? ''
      dataObservaciones.observacionesSalida = contractEdit?.observacionesSalida ?? ''
      opciones.acumulafondoreserva = contractEdit?.acumulaFondoReserva === 1 ? true : false
      opciones.pagaseguridadsocial = contractEdit?.pagarIESS === 1 ? true : false
      opciones.acumuladecimotercero = contractEdit?.acumulaDecimoTercero === 1 ? true : false
      opciones.acumuladecimocuarto = contractEdit?.acumulaDecimoCuarto === 1 ? true : false
      privaciones.nocturno = contractEdit?.privacionesNocturno === 1 ? true : false
      privaciones.suplementarios = contractEdit?.privacionesSuplementarios === 1 ? true : false
      privaciones.extras = contractEdit?.privacionesExtras === 1 ? true : false
      privaciones.decimoTercero = contractEdit?.privacionesDecimoTercero === 1 ? true : false
      privaciones.decimoCuarto = contractEdit?.privacionesDecimoCuarto === 1 ? true : false
      opciones.pagaimpuestorenta = contractEdit?.pagaImpuestoRenta === 1 ? true : false
      dataObservaciones.codigoCtaBancaria = contractEdit?.cuentaBanco ?? 0
      dataObservaciones.ctaBancaria = contractEdit?.cuentaBancoDescripcion ?? ''
      opciones.pagofondosinicio = contractEdit?.pagaFondosReservaInicio === 1 ? true : false
      if (discapacidad?.auto && discapacidad?.error === false) {
        const provider = discapacidad?.auto.slice(0)
        const index = await getIndexProvider(provider, 'descripcion', contractEdit?.discapacidad ?? '')
        if (index > -1) {
          dataObservaciones.discapacidad = provider[index]
        } else {
          dataObservaciones.discapacidad = provider[0]
        }
      }
      dataObservaciones.discapacidadPorcentaje = contractEdit?.porcentajeDiscapacidad ?? 0


      data.contratos = dataContratos
      dataObservaciones.privaciones = privaciones
      dataObservaciones.opcionEmpres = opciones

      data.observaciones = dataObservaciones
      dispatch(setDatosEdicion({
        key: tabId,
        data: { ...data }
      }))
      dispatch(setDatosEdicionBackup({
        key: tabId,
        data: { ...data }
      }))

    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }

    onLoader(false, '')
    onSetButtonAction(undefined)
  }, [dispatch, onLoader, onSetButtonAction, tabId, setToast])

  const onValidate = React.useCallback(() => {
    const errors: Array<string> = []
    if (contractsState?.contratos?.codigoEmpleado === 0) {
      errors.push('Contratos - Empleado: elija al empleado.')
    }
    if (contractsState?.contratos?.tipoContrato === null || contractsState?.contratos?.tipoContrato === undefined || contractsState?.contratos?.tipoContrato?.codigo <= 0) {
      errors.push('Contratos - Tipo de Contrato: elija el tipo de contrato.')
    }
    if (contractsState?.contratos?.jornada === null || contractsState?.contratos?.jornada === undefined || contractsState?.contratos?.jornada?.codigo <= 0) {
      errors.push('Contratos - Jornada: elija una jornada.')
    }
    if (contractsState?.observaciones?.fecha === null || contractsState?.observaciones?.fecha === undefined || contractsState?.observaciones?.fecha === '') {
      errors.push('Observaciones - Fecha: seleccione una fecha de registro.')
    }
    if (contractsState?.contratos?.sueldoNomina === 0 && (usuario?.rol === 'ADM' || usuario?.rol === 'SADM')) {
      errors.push('Contratos - Sueldo Nominal: ingrese un valor.')
    }
    if (contractsState?.contratos?.codigoHorarioLabores === 0) {
      errors.push('Contratos - Horarios: seleccione un horario de labores.')
    }
    if (contractsState?.contratos?.cargo === null || contractsState?.contratos?.cargo === undefined || contractsState?.contratos?.cargo?.organicoCodigo <= 0) {
      errors.push('Contratos - Cargo: seleccione un cargo.')
    }
    if (contractsState?.contratos?.fechaIngreso === null || contractsState?.contratos?.fechaIngreso === '') {
      errors.push('Contratos - Fecha de Ingreso: elija una fecha de ingreso.')
    }
    return errors
  }, [contractsState, usuario])

  const onHandleSave = React.useCallback(async () => {
    let errors: Array<string> = []
    const result = validationContract.current.instance.validate()
    errors = onValidate()
    consoleService.log(errors)
    if (result.isValid && errors.length === 0) {
      consoleService.log(contractsState)
      onSetButtonAction(ButtonTypes.save)
      onLoader(true, 'Guardando...')
      const fechaO = DateUtils.format(contractsState?.observaciones?.fecha, 'dd/MM/yyyy', 'yyyy-MM-dd')
      let fechaI = ''
      let fechaS = ''
      if (contractsState?.contratos?.fechaIngreso !== "" && contractsState?.contratos?.fechaIngreso !== null) {
        fechaI = DateUtils.format(contractsState?.contratos?.fechaIngreso, 'dd/MM/yyyy', 'yyyy-MM-dd')
      }
      if (contractsState?.contratos?.fechaSalida !== "") {
        fechaS = DateUtils.format(contractsState?.contratos?.fechaSalida, 'dd/MM/yyyy', 'yyyy-MM-dd')
      }
      let numtparcial = 0
      let nominal = contractsState?.contratos?.sueldoNomina
      if (contractsState?.contratos?.tipoContrato?.codigo === 5 || contractsState?.contratos?.tipoContrato?.codigo === 6 || contractsState?.contratos?.tipoContrato?.codigo === 7) {
        numtparcial = contractsState?.contratos?.numeroTipoParcial
      }

      if (contractsState?.contratos?.aumentoSueldo) {
        nominal = contractsState?.contratos?.sueldoNominaB
      }
      try {

        const save_data: SaveContract = {
          infoRegistro: {
            fecha: fechaO ?? '',
            fechaIngreso: fechaI ?? '',
            fechaSalida: fechaS ?? '',
            codigo: contractsState?.codigo ?? 0,
            usuarioCodigo: contractsState?.contratos?.codigoEmpleado ?? 0,
            tipoPersonal: contractsState?.contratos?.tipoPersonal?.value ?? 0,
            archivo: '',
            observacionesIngreso: contractsState?.observaciones?.observacionesIngreso ?? '',
            observacionesSalida: contractsState?.observaciones?.observacionesSalida ?? '',
            funciones: contractsState?.contratos?.funciones ?? '',
            contrato: contractsState?.contratos?.contatoRt ?? '',
            cargo: contractsState?.contratos?.cargo?.organicoRolCodigo ?? 0,
            nominal: contractsState?.contratos?.sueldoNomina ?? 0,
            discapacidad: contractsState?.observaciones?.discapacidad?.descripcion ?? '',
            porcentajeDiscapacidad: contractsState?.observaciones?.discapacidadPorcentaje ?? 0,
            tipo: contractsState?.contratos?.tipoContrato?.codigo ?? 0,
            horasTiempoParcial: numtparcial ?? 0,
            detalle: {
              codigo: 0,
              contrato: contractsState?.codigo ?? 0,
              nominal: nominal ?? 0,
              liquido: contractsState?.observaciones?.opcionEmpres?.sistemasalarioneto ? 2 : 1,
              jornada: contractsState?.contratos?.jornada?.codigo ?? 0,
              horarioTrabajo: contractsState?.contratos?.codigoHorarioLabores ?? 0,
              organicoRol: contractsState?.contratos?.cargo?.organicoRolCodigo ?? 0,
              confianza: contractsState?.contratos?.tipoContrato?.codigo ?? 0,
              acumulaFondoReserva: contractsState?.observaciones.opcionEmpres?.acumulafondoreserva ? 1 : 0,
              pagarIESS: contractsState?.observaciones.opcionEmpres?.pagaseguridadsocial ? 1 : 0,
              acumulaDecimoTercero: contractsState?.observaciones.opcionEmpres?.acumuladecimotercero ? 1 : 0,
              acumulaDecimoCuarto: contractsState?.observaciones.opcionEmpres?.acumuladecimocuarto ? 1 : 0,
              pagaImpuestoRenta: contractsState?.observaciones.opcionEmpres?.pagaimpuestorenta ? 1 : 0,
              privacionesNocturno: contractsState?.observaciones.privaciones?.nocturno ? 1 : 0,
              privacionesSuplementarios: contractsState?.observaciones.privaciones?.suplementarios ? 1 : 0,
              privacionesExtras: contractsState?.observaciones.privaciones?.extras ? 1 : 0,
              privacionesDecimoTercero: contractsState?.observaciones.privaciones?.decimoTercero ? 1 : 0,
              privacionesDecimoCuarto: contractsState?.observaciones.privaciones?.decimoCuarto ? 1 : 0,
              estado: 1,
              cuentaBanco: contractsState?.observaciones?.codigoCtaBancaria ?? 0,
              pagaFondosReservaInicio: contractsState?.observaciones.opcionEmpres?.pagofondosinicio ? 1 : 0,
            }
          }
        }
        consoleService.log(save_data, 'save_data Inforegistro')
        const dataSave = await ContratsServices.saveContract(save_data)
        consoleService.log(dataSave, 'result save')
        if (dataSave['auto'] && dataSave['error'] === false) {
          setToast(dataSave['message'], ToastTypes.Success)
          dispatch(setNameTab({
            key: tabId,
            codigo: dataSave['auto'] ?? 0,
            nombre: contractsState?.contratos?.empleado ?? ''
          }))

          dispatch(
            setButtons({
              tabKey: tabId,
              buttons: {
                ...ModuleButtons,
                Guardar: false,
                Imprimir: true
              }
            }),
          )

          const detailData = await ContratsServices.getDetail(dataSave['auto'])
          if (detailData['auto'] && detailData['error'] === false) {
            dispatch(setDatosEdicion({
              key: tabId,
              data: {
                ...contractsState,
                observaciones: {
                  ...observationsState,
                  detalleLista: detailData['auto'] ?? []
                }
              }
            }))
          } else {
            setToast(detailData['message'] ?? 'Error', ToastTypes.Danger)
          }

        } else {
          setToast(dataSave['message'], ToastTypes.Danger)
        }
      } catch (error) {
        setToast(error.message, ToastTypes.Danger)
      }
      onSetButtonAction(undefined)
      onLoader(false, '')

    } else {
      setToast(lh.getMessage(MessagesKeys.GlobalErrorSave), ToastTypes.Warning)
      setArrayErrors(errors)
      setShowErrorPopup(true)
    }
  }, [contractsState, onValidate, setToast, onLoader, onSetButtonAction, dispatch, tabId, observationsState])

  const onUndo = React.useCallback(() => {
    dispatch(setDatosEdicion({
      key: tabId,
      data: { ...contractsStateBackup }
    }))
  }, [contractsStateBackup, tabId, dispatch])

  const handleButtonClick = useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.save:
          if (tabs.current === tabId) onHandleSave()
          break
        case ButtonTypes.broom:
          if (tabs.current === tabId) modoNuevo(0, true)
          break
        case ButtonTypes.undo:
          if (tabs.current === tabId) onUndo()
          break
        case ButtonTypes.print:
          if (tabs.current === tabId) setReporte('Viewer')
          break
        case ButtonTypes.print_design:
          if (tabs.current === tabId) setReporte('Designer')
          break
        default:
          break
      }
      dispatch(clearButtonClick(tabId))
    }, [dispatch, tabId, tabs, onHandleSave, modoNuevo, onUndo])

  const onParseData = useCallback(() => {
    consoleService.log(contractsState)
    const registros: Array<ContractDto> = []

    let salarioContrato = contractsState?.contratos?.sueldoNomina ?? 0
    if (contractsState?.contratos?.aumentoSueldo) {
      salarioContrato = contractsState?.contratos?.sueldoNominaB
    }

    let fechaIngreso = ''
    let fechaSalida = ''
    let fecha = ''
    if (contractsState?.contratos?.fechaIngreso !== "") {
      fechaIngreso = DateUtils.format(contractsState?.contratos?.fechaIngreso, 'dd/MM/yyyy', 'yyyy-MM-dd')
    }
    if (contractsState?.contratos?.fechaSalida !== "") {
      fechaSalida = DateUtils.format(contractsState?.contratos?.fechaSalida, 'dd/MM/yyyy', 'yyyy-MM-dd')
    }
    if (contractsState?.observaciones?.fecha !== "") {
      fecha = DateUtils.format(contractsState?.observaciones?.fecha, 'dd/MM/yyyy', 'yyyy-MM-dd')
    }

    const registro: ContractDto = {
      Code: contractsState?.codigo ?? 0,
      Identification: contractsState?.contratos?.empleado ?? '',
      Names: contractsState?.contratos?.empleado ?? '',
      BeginDate: fechaIngreso ?? '',
      DescriptionRole: contractsState?.contratos?.cargo?.rolDescripcion ?? '',
      EndDate: fechaSalida ?? '',
      TypePersonal: contractsState?.contratos?.tipoPersonal?.label ?? '',
      Contract: contractsState?.contratos?.contrato?.label ?? '',
      TypeContract: contractsState?.contratos?.tipoContrato?.descripcion ?? '',
      WorkingDay: contractsState?.contratos?.jornada?.descripcion ?? '',
      Salary: salarioContrato ?? 0,
      Functions: contractsState?.contratos?.funciones ?? '',
      Department: contractsState?.contratos?.departamento?.descripcion ?? '',
      Date: fecha ?? '',
      Disability: contractsState?.observaciones?.discapacidad?.descripcion ?? '',
      DisabilityPercent: contractsState?.observaciones?.discapacidadPorcentaje ?? 0,
    }
    registros.push(registro)
    consoleService.log(registros)
    return registros
  }, [contractsState])

  useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab.globalButtonClick])

  useEffect(() => {
    if (tab.editStatus === StatesEdition.new) {
      modoNuevo(tab?.info?.codigo)
    } else {
      cargarContrato(loading, info.info)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <ReportWithLocalDataModal
        show={reporte !== null}
        onClose={() => setReporte(null)}
        data={onParseData()}
        fileName='StaticContract'
        mode={reporte ?? 'Viewer'}
        parameters={{ 'Reporte_Filtro': '' }}
        template='StaticContract'
        key='reportDesigner'
      />
      {showErrorPopup && (
        <VisualizaError
          titulo="Error en Contratos"
          mensaje={'Error al guardar'}
          content={
            <ListErrosValidationForm
              mainMessage={'Revise los siguientes errores:'}
              errorsList={arrayErrors}
              color={'danger'}
            />
          }
          onOk={() => setShowErrorPopup(false)}
        />
      )}
      <div style={{ overflowX: 'hidden' }}>
        <BlockUi
          tag="div"
          loader={LoadingIndicator}
          blocking={loader.show}
          message={loader.mensaje}
        >
          <ValidationGroup id={'formContract'} ref={validationContract}>
            {
              !isMobile && (
                <RowContainer>
                  <CustomCol lg="6" md="8" sm="12">
                    <ValidationSummary id="summary"></ValidationSummary>
                  </CustomCol>
                </RowContainer>
              )
            }
            <TabsEdition info={info} tab={tab} tabId={tabId} />
            {
              isMobile && (
                <RowContainer>
                  <CustomCol lg="6" md="8" sm="12">
                    <ValidationSummary id="summary"></ValidationSummary>
                  </CustomCol>
                </RowContainer>
              )
            }
          </ValidationGroup>
        </BlockUi>
      </div>
    </>
  )
}

export default React.memo(Nuevo)

export const defaulContratosData: ContratosDatosEdicion = {
  codigo: 0,
  loader: {
    show: false,
    mensaje: ''
  },
  loading: false,
  contratos: {
    proceso: '',
    contatoRt: '',
    activaIngreso: false,
    nroIngreso: null,
    contrato: null,
    cargo: null,
    aumentoSueldo: false,
    tipoPersonal: null,
    fechaIngreso: '',
    empleado: '',
    codigoEmpleado: 0,
    tipoContrato: null,
    jornada: null,
    departamento: null,
    numeroTipoParcial: 0,
    numeroTipoParcialShow: true,
    funciones: '',
    sueldoNomina: 0,
    sueldoNominaB: 0,
    fechaSalida: '',
    horarioLabores: '',
    codigoHorarioLabores: 0,
  },
  observaciones: {
    fecha: '',
    discapacidad: null,
    discapacidadPorcentaje: 0,
    privaciones: {
      nocturno: false,
      suplementarios: false,
      extras: false,
      decimoCuarto: false,
      decimoTercero: false,
    },
    opcionEmpres: {
      pagaimpuestorenta: false,
      pagaseguridadsocial: false,
      pagofondosinicio: false,
      sistemasalarioneto: false,
      acumuladecimotercero: false,
      acumulafondoreserva: false,
      acumuladecimocuarto: false,
    },
    ctaBancaria: '',
    archivo: '',
    codigoCtaBancaria: 0,
    archivoNombre: '',
    observacionesIngreso: '',
    observacionesSalida: '',
    relacion: null,
    detalleLista: []
  }
}