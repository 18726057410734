import { combineReducers } from '@reduxjs/toolkit'
import { anexoTransaccionalReducer } from '../pages/anexoTransaccional/store/anexoTransaccionalReducer'
import { ModuloImpuestosSVState } from '../types/types'
import { libroTransaccionalReducer } from '../pages/anexoTransaccional/pages/libroTransacciones/store/libroTransaccionalReducer'

export const moduloImpuestosSVReducer = combineReducers<ModuloImpuestosSVState>(
  {
    anexoTransaccional: anexoTransaccionalReducer,
    libroTransaccional: libroTransaccionalReducer,
  },
)
