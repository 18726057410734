import React from 'react'
import { TextBox } from 'devextreme-react'

import CustomCol from '../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import LocalesLookUp from '../../../../../componentes/localesLookUp'
import PuntosVentaLookUp from '../../../../../../modulos/componentes/lookUpPuntosVenta'
import { PuntoVenta } from '../../../../../componentes/lookUpPuntosVenta/store/type'
import BuscarClienteLookUp from '../../../../../../modulos/ventas/components/busquedaCliente/busquedaCliente'
import EmpresasLookUp from '../../../../../componentes/empresasLookUp'
import ProyectosLookUp from '../../../../../componentes/proyectosLookUp/proyectosLookUp'
import { FiltrosDocumentosState } from '../../types/types'
import { puntosVentaService } from '../../../../../componentes/lookUpPuntosVenta/service/puntosVenta.service'
import { Seleccionado } from '../../../../../bancos/store/types'
import { ClientesService } from '../../../../../ventas/services/clientes.service'


interface ICabeceraFiltroBusqueda extends React.PropsWithChildren {
  filtroBusqueda: FiltrosDocumentosState
  getPuntoVenta?: (data: PuntoVenta) => void
  getCabeceraFiltro?: (data: FiltrosDocumentosState) => void
  seleccionado?: Seleccionado
  clean: boolean
  setClean: (data: boolean) => void
}


const CabeceraFiltroBusqueda: React.FunctionComponent<ICabeceraFiltroBusqueda> = (props) => {
  const { filtroBusqueda, getCabeceraFiltro, seleccionado, clean = false, setClean } = props;

  // const filtroBusqueda = useSelector(selectFiltroBusquedaDocs)
  // const [filtroGlobal, setfiltroGlobal] = React.useState<FiltrosDocumentosState>(filtroBusqueda)
  const [cliente, setcliente] = React.useState(filtroBusqueda.cliente)
  const [empresa, setEmpresa] = React.useState(filtroBusqueda.empresa)
  const [lugar, setLugar] = React.useState(filtroBusqueda.lugar)
  const [nombre, setNombre] = React.useState(filtroBusqueda.nombre)
  const [proyecto, setProyecto] = React.useState(filtroBusqueda.proyecto)
  const [puntoVenta, setPuntoVenta] = React.useState(filtroBusqueda.puntoVenta)

  function onEnterKey(): void {
    throw new Error('Function not implemented.')
  }


  const onChangeEmpresa = React.useCallback((data) => {
    if (data !== null && data !== undefined) {
      setEmpresa(data)
    } else {
      setEmpresa(null)
    }
  }, [])

  const onChangeCliente = React.useCallback((data) => {
    if (data !== null && data !== undefined) {
      setcliente(data)
      setNombre(data.nombres)
    } else {
      setcliente(null)
      setNombre('')
    }
  }, [])

  const onChangeLugar = React.useCallback((data) => {
    if (data !== null && data !== undefined) {
      setLugar(data)
    } else {
      setLugar(null)
    }
  }, [])

  const onChangePuntoVenta = React.useCallback((data) => {
    if (data !== null && data !== undefined) {
      setPuntoVenta(data)
    } else {
      setPuntoVenta(null)
    }
  }, [])

  const onChangeProyecto = React.useCallback((data) => {
    if (data !== null && data !== undefined) {
      setProyecto(data)
    } else {
      setProyecto(null)
    }
  }, [])

  const onChangeNombre = React.useCallback((data) => {
    if (data.value !== null && data !== undefined) {
      setNombre(data.value)
    } else {
      setNombre('')
    }
  }, [])

  const getDataPuntoVenta = React.useCallback(async (seleccionado) => {
    const data = await puntosVentaService.getPuntosVenta("Todos")
    if (data !== null && data['error'] === false && data['auto'].length > 0) {
      let pvCodigo = 0
      if (seleccionado?.puntoVentaCodigo) {
        pvCodigo = !isNaN(seleccionado?.puntoVentaCodigo) && parseInt(seleccionado.puntoVentaCodigo)
      } else if (seleccionado?.codigoPto) {
        pvCodigo = seleccionado?.asociado
      }
      const pv = data['auto'].find(p => p.codigo === pvCodigo)
      setPuntoVenta(pv)

    }

    if (data !== null && data['error'] === false && data['auto'].length > 0) {
      let cliCodigo = 0
      if (seleccionado?.clienteCodigo) {
        cliCodigo = seleccionado.clienteCodigo
      } else if (seleccionado?.asociado) {
        cliCodigo = seleccionado?.asociado
      }
      const cliente = await ClientesService.getCliente("", cliCodigo)
      setcliente(cliente)
      setNombre(cliente?.nombres)
    }
  }, [])



  React.useEffect(() => {
    if (seleccionado !== undefined && seleccionado !== null && seleccionado.row !== null && seleccionado.row !== undefined) {
      getDataPuntoVenta(seleccionado.row)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seleccionado])

  React.useEffect(() => {
    const getFiltro: FiltrosDocumentosState = {
      empresa: empresa,
      cliente: cliente,
      puntoVenta: puntoVenta,
      proyecto: proyecto,
      lugar: lugar,
      nombre: nombre
    }

    getCabeceraFiltro(getFiltro)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cliente, empresa, lugar, nombre, proyecto, puntoVenta])

  React.useEffect(() => {
    if (clean) {
      setcliente(null)
      setEmpresa(null)
      setLugar(null)
      setNombre('')
      setProyecto(null)
      setPuntoVenta(null)
      setClean(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clean])

  return (
    <>
      <RowContainer>
        <CustomCol xs="12" md="3">
          <Labeled label="Empresas">
            <EmpresasLookUp
              onChanged={(data) => {
                if (data !== null || data !== undefined) {
                  onChangeEmpresa(data)
                }
              }}
              onChangedOptions={() => { }}
              selected={empresa}
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="3">
          <Labeled label="Puntos de Venta">
            <PuntosVentaLookUp
              onChanged={(data) => {
                if (data !== null || data !== undefined) {
                  onChangePuntoVenta(data)
                }
              }}
              onChangedOptions={() => { }}
              selected={puntoVenta}
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="3">
          <Labeled label="Proyecto">
            <ProyectosLookUp
              onChanged={(data) => {
                if (data !== null || data !== undefined) {
                  onChangeProyecto(data)
                }
              }}
              onChangedOptions={() => { }}
              selected={proyecto}
            />
          </Labeled>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="5">
          <Labeled label="Cliente">
            <BuscarClienteLookUp
              selected={cliente}
              onChanged={(data) => {
                if (data !== null || data !== undefined) {
                  onChangeCliente(data)
                } else {
                  onChangeCliente(null)
                }
              }}
              allowClear
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="4">
          <Labeled label="Sr.(es):">
            <TextBox
              value={nombre}
              onValueChanged={(data) => {
                if (data.event !== undefined) {
                  onChangeNombre(data)
                }
              }}
              // onEnterKey={(evt) => onEnterKey(evt, 'comprobante')}
              showClearButton />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="3">
          <Labeled label="Lugar o CC.">
            <LocalesLookUp
              onChanged={(data) => {
                if (data !== null || data !== undefined) {
                  onChangeLugar(data)
                }
              }}
              onChangedOptions={(opt) => {
                console.log('localidad ', opt)
              }}
              selected={lugar}
            />
          </Labeled>
        </CustomCol>
      </RowContainer>
    </>
  )
}

export default React.memo(CabeceraFiltroBusqueda)
