import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { ConceptiosMovEdicion, MovTypes, Movimiento } from '../../types/types'
import { ButtonTypes } from '../../../../../../../views/componentes/globalMenu/types'
import { clearButtonClick, openTab } from '../../store/tabsReducer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import {
  setCurrentFunction,
} from '../../store/generalReducer'
import { TabState } from '../../../../../../../store/genericTabTypes'
import { CCard } from '@coreui/react-pro'
import FiltrosBusqueda from './FiltrosBusqueda'
import Dialog from '../../../../../../../views/componentes/librerias/bootstrap-dialog'
import BlockUi from '../../../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../../../views/componentes/loadingindicator/loadingindicator'
import ResultadosBusqueda from './ResultadosBusqueda'
import { isMobile } from 'react-device-detect'
import { Draggable, SpeedDialAction } from 'devextreme-react'
import TableLoader from '../../../../../../ventas/components/ventas/busquedaVentas/TableLoader'
import { FETCH_STATUS, ToastTypes } from '../../../../../../../store/types'
import { changeFilter, changeMaxs, fetchConcepts, setCleanResult, setCollapsed, setResetSeleccion } from '../../store/serachReducer'
import { addToast } from '../../../../../../../store/toasterReducer'
import { typesConceptsMovementsServices } from '../../../../../../componentes/tiposConceptosMovLookUp/services/tiposConceptosMov.services'
import { initDatosEdicion } from '../../store/editDataReducer'
import { defaultDataConcepts } from '../nuevo/index';

interface ISearchProps extends React.PropsWithChildren {
  tab: TabState<ConceptiosMovEdicion>
  tabId: string
}

enum VistasBusqueda {
  Filtros,
  ResultadosBusqueda,
  Resumen,
  Loading,
  Error
}

const draggingGroupName = 'appointmentsGroup';

const Search: React.FunctionComponent<ISearchProps> = (props) => {
  const { tabId, tab } = props
  const dialogRef = React.useRef<any>(null)

  const dispatch = useDispatch()
  const [vistaActual, setVistaActual] = React.useState<VistasBusqueda>(VistasBusqueda.Filtros)
  const [confirmEdit, setConfirmEdit] = React.useState<boolean>(false)

  const currentTab = useSelector(
    (state: RootState) =>
      state.inventarios.movimientos.conceptos.tabs.current,
  )
  const selected = useSelector(
    (state: RootState) =>
      state.inventarios.movimientos.conceptos.search.seleccionado,
  )
  const conMovLoader = useSelector(
    (state: RootState) =>
      state.inventarios.movimientos.conceptos.general,
  )
  const searchFilter = useSelector(
    (state: RootState) =>
      state.inventarios.movimientos.conceptos.search.filter,
  )
  const stateMaxs = useSelector(
    (state: RootState) =>
      state.inventarios.movimientos.conceptos.search.maxs,
  )

  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(
      addToast({
        title: 'Inventario - Conceptos Movimientos',
        content: mensaje,
        type: tipo,
      }),
    )
  }, [dispatch])

  const estadoBusqueda = useSelector((state: RootState) => state.inventarios.movimientos.conceptos.search.status)

  const onInitSearch = React.useCallback(async () => {
    const data = { ...searchFilter }
    if (data.initial) {
      const res = await typesConceptsMovementsServices.getTypesMovements()
      if (res['auto'] && res['error'] === false) {
        data.tipo = res['auto'][0]
      }
      data.descripcion = ''
      data.initial = false

      dispatch(changeFilter({
        ...data
      }))
    }
  }, [dispatch, searchFilter])

  const onGetMaxs = React.useCallback((dataResults: Array<Movimiento>) => {
    const data = { ...stateMaxs }
    dataResults.forEach(r => {
      if (r?.tipo === MovTypes.TipoIngresos) {
        if (r?.codigo > data.maxIngresos) {
          data.maxIngresos = r?.codigo ?? 0
        }
      }
      if (r?.tipo === MovTypes.TipoEgresos) {
        if (r?.codigo > data.maxIngresos) {
          data.maxEgresos = r?.codigo ?? 0
        }
      }
      if (r?.tipo === MovTypes.TipoTrans) {
        if (r?.codigo > data.maxTrans) {
          data.maxTrans = r?.codigo ?? 0
        }
      }
    })
    data.maxIngresos++
    data.maxEgresos++
    data.maxTrans++

    dispatch(changeMaxs({
      ...data
    }))
  }, [dispatch, stateMaxs])


  const onCleanResultFilter = React.useCallback(async () => {
    const data = { ...searchFilter }
    dispatch(setCleanResult())
    dispatch(setResetSeleccion())
    const res = await typesConceptsMovementsServices.getTypesMovements()
    if (res['auto'] && res['error'] === false) {
      data.tipo = res['auto'][0]
    }
    data.descripcion = ''
    data.initial = false

    dispatch(changeFilter({
      ...data
    }))
  }, [dispatch, searchFilter])

  const handleSearch = React.useCallback(async () => {
    try {
      const toAny: any = fetchConcepts(searchFilter)
      const res = await dispatch(toAny)
      if (res !== null && res !== undefined && res['payload']?.error === false) {
        setToast(res['payload']?.message, ToastTypes.Success)
        if (res['payload']?.auto && res['payload'].auto.length > 5) {
          dispatch(setCollapsed(false))
        }
        onGetMaxs(res['payload']?.auto)
      } else {
        setToast(res['payload']?.message, ToastTypes.Danger)
      }
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
  }, [dispatch, searchFilter, setToast, onGetMaxs])

  const onHandleEdit = React.useCallback(async (conceptoData: any) => {
    const id = uuidv4()
    dispatch(
      initDatosEdicion({
        key: id,
        data: {
          ...defaultDataConcepts,
          codigo: conceptoData?.codigo ?? 0,
          loading: true
        },
      }),
    )
    dispatch(openTab({ key: id, unidad: conceptoData }))
  }, [dispatch])


  const onConfirmEdit = React.useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea Editar el registro: ${selected?.descripcion ?? '-'}?`,
      actions: [
        Dialog.Action(
          <span>
            <u>A</u>ceptar
          </span>,
          (dialog) => {
            dialog.hide()
            onHandleEdit(selected)
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setConfirmEdit(false)
    return
  }, [onHandleEdit, selected])


  const handleButtonClick = React.useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.find:
          handleSearch()
          break
        case ButtonTypes.broom:
          onCleanResultFilter()
          break
        case ButtonTypes.edit:
          setConfirmEdit(true)
          break
        default:
          break
      }
      dispatch(setCurrentFunction(''))
      dispatch(clearButtonClick(tabId))
    },
    [dispatch, tabId, handleSearch, onCleanResultFilter],
  )

  React.useEffect(() => {
    if (confirmEdit) {
      onConfirmEdit()
    }
  }, [confirmEdit, onConfirmEdit])

  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab.globalButtonClick]);

  React.useEffect(() => {
    onInitSearch()
    handleSearch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    switch (estadoBusqueda) {
      case FETCH_STATUS.IDDLE:
        setVistaActual(VistasBusqueda.Filtros);
        break;
      case FETCH_STATUS.LOADING:
        setVistaActual(VistasBusqueda.Loading);
        break;
      case FETCH_STATUS.SUCCESS:
        setVistaActual(VistasBusqueda.ResultadosBusqueda);
        break;
      case FETCH_STATUS.FAILED:
        setVistaActual(VistasBusqueda.Error);
        break;
      default:
        break;
    }
  }, [estadoBusqueda])

  if (isMobile) {
    return (
      <>
        <CCard>
          <Dialog ref={dialogRef} />
          {vistaActual === VistasBusqueda.Filtros &&

            (
              <>
                <FiltrosBusqueda onHandleSearch={handleSearch} />
              </>
            )
          }
          {vistaActual === VistasBusqueda.ResultadosBusqueda &&
            (<>
              <ResultadosBusqueda onDBEdit={() => setConfirmEdit(true)} />
            </>)
          }
          {vistaActual === VistasBusqueda.Error &&
            <></>
          }
          {vistaActual === VistasBusqueda.Loading &&
            <TableLoader />
          }
          {
            currentTab === tabId && (
              <Draggable
                id="list"
                data="dropArea"
                group={draggingGroupName}
              >
                <SpeedDialAction
                  icon="filter"
                  label='Filtros'
                  visible={true}
                  index={1}
                  onClick={() => {
                    setVistaActual(VistasBusqueda.Filtros);
                  }}
                />
                <SpeedDialAction
                  icon="search"
                  label='Busqueda'
                  visible={true}
                  index={2}
                  onClick={() => {
                    setVistaActual(VistasBusqueda.ResultadosBusqueda);
                  }}
                />
              </Draggable>
            )
          }
        </CCard>
      </>
    )
  }


  return (
    <>
      <Dialog ref={dialogRef} />
      <div id='conMovContent'>
        <BlockUi
          tag="div"
          loader={LoadingIndicator}
          blocking={conMovLoader.loader.show}
          message={conMovLoader.loader.mensaje}
        >
          <CCard>
            <FiltrosBusqueda onHandleSearch={handleSearch} />
            <ResultadosBusqueda onDBEdit={() => setConfirmEdit(true)} />
          </CCard>
        </BlockUi>
      </div>
    </>
  )
}

export default React.memo(Search)
