import React from 'react';
import { throttle } from 'lodash';
import { VentasService } from '../../../services/ventas.service';
import '../sheetEditors/sheetDescripcionItemSelectEditor.scss'
import './venta.scss'
import { ItemVenta } from '../../../types/types';
import { DateUtils, formatoFechasApi } from '../../../../../helpers/dateUtils';
import { Autocomplete, TextField } from '@mui/material';
interface ICustomAutocompleteProps extends React.PropsWithChildren {
  id: string,
  value: ItemVenta | null,
  onChange: (newValue: ItemVenta | null) => void,
  rucCliente: string,
  tipoBusqueda: "nombre" | "codigo",
  tieneError?: boolean,
  feedBack?: any,
  onBlur: any
}
//size="small"
const CustomAutocompleteProducto: React.FunctionComponent<ICustomAutocompleteProps> = (props) => {
  const [options, setOptions] = React.useState<Array<ItemVenta>>([]);
  const [loading, setLoading] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');

  const fetch = React.useMemo(
    () =>
      throttle((request: { input: string, rucCliente: string, tipoBusqueda: string }, callback: (results?: Array<ItemVenta>) => void) => {


        setLoading(true);
        const busqueda = request.tipoBusqueda === "nombre" ?
          VentasService
            .buscarItem(request.input, '', request.rucCliente, DateUtils.getCurrentDateAsString(formatoFechasApi))
          :
          VentasService
            .buscarItemCodigoBarras(request.input, request.rucCliente, DateUtils.getCurrentDateAsString(formatoFechasApi));
        busqueda
          .then((resultados) => {

            callback(resultados);
            setLoading(false);
          }).catch(reason => {
            setLoading(false);
          });
        //(autocompleteService.current as any).getPlacePredictions(request, callback);
      }, 1000),
    [],
  );



  React.useEffect(() => {
    let active = true;
    if (inputValue === '') {
      setOptions(props.value ? [props.value] : []);
      return undefined;
    }

    if (inputValue.length < 4) {
      setOptions(props.value ? [props.value] : []);
      return undefined;
    }

    fetch(
      {
        input: inputValue,
        rucCliente: props.rucCliente,
        tipoBusqueda: props.tipoBusqueda
      }, (results?: ItemVenta[]) => {
        if (active) {
          let newOptions: ItemVenta[] = [];

          if (props.value) {
            newOptions = [props.value];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }
          setOptions(newOptions);
        }
      });
    return () => {
      active = false;
    };
  }, [props, inputValue, fetch])

  const renderOptionSelect = (option: any, data: any) => {
    return (
      < div className='autoCompleteItemContainer' onClick={() => {
      }}>
        <div className='autoCompleteItemDescripcion'>{data.descripcion ?? ""}  </div>
        <div className='autoCompleteItemDetails'>
          <small className='autoCompleteItemDetail'>
            Código: {data.codigoBarras}
          </small>
          <small className={`autoCompleteItemDetail ${data.existencia < 0 ? 'existenciaNegativa' : (data.existencia === 0 ? 'existenciaCero' : '')}`}>
            Existencia: {data.existencia}
          </small>
        </div>
      </div >
    )
  }

  return (
    <>
      <Autocomplete
        size='small'
        multiple={false}
        id={props.id}
        loading={loading}
        loadingText='Buscando productos...'
        noOptionsText=''
        options={options}
        onBlur={props.onBlur}
        getOptionLabel={(option) => props.tipoBusqueda === 'codigo' ? option.codigoBarras : (option.descripcion + "(Existencias :" + option.existencia + " Código : " + option.codigoBarras + " )")}
        value={props.value}
        onChange={(event: any, newValue) => {
          setOptions(newValue ? [newValue, ...options] : options);
          props.onChange(newValue);
        }}
        // renderOption={(opt, data) => (
        //   < div className='autoCompleteItemContainer' onClick={() => {
        //   }}>
        //     <div className='autoCompleteItemDescripcion'>{data.descripcion ?? ""}  </div>
        //     <div className='autoCompleteItemDetails'>
        //       <small className='autoCompleteItemDetail'>
        //         Código: {data.codigoBarras}
        //       </small>
        //       <small className={`autoCompleteItemDetail ${data.existencia < 0 ? 'existenciaNegativa' : (data.existencia === 0 ? 'existenciaCero' : '')}`}>
        //         Existencia: {data.existencia}
        //       </small>
        //     </div>
        //   </div >
        // )}
        // renderOption={(props, option, state) =>
        //   [props, option, state.index] as React.ReactNode
        // }
        renderInput={(params) => (
          <TextField {...params} variant="standard"
            label={props.tipoBusqueda === 'codigo' ? 'Código Producto' : 'Descripción producto'}
            size='small'
            placeholder={'Seleccionar producto'}
            error={props.tieneError}
            helperText={props.feedBack}
            fullWidth />
        )}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
      />
    </>
  );
};

export default CustomAutocompleteProducto;
