import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AccionMenu, LoaderInfo } from "../../../../../store/types";
import { DiarioBancosState, RegistrosDiarioBancos, Seleccionado } from "./types";

const initialState: DiarioBancosState = {
  modulo: 'contabilidad',
  currentAction: "Buscar",
  currentFunction: "",
  aplicacion: 'diarioBancos',
  acciones: [],
  registros: [],
  refrescaTabla: false,
  seleccionado: {
    index: null,
    row: null
  },
  loader: {
    show: false,
    mensaje: ''
  },
}

const diarioBancosSlice = createSlice({
  name: "diarioBancos",
  initialState: initialState,
  reducers: {
    obtenerAcciones(state, action: PayloadAction<Array<AccionMenu>>) {
      state.acciones = action.payload
    },
    setData(state, action: PayloadAction<Array<RegistrosDiarioBancos>>) {
      state.registros = action.payload
    },
    setCurrentAccion(state, action: PayloadAction<string>) {
      state.currentAction = action.payload
    },
    setCurrentFunction(state, action: PayloadAction<string>) {
      state.currentFunction = action.payload
    },
    setSeleccionarDato(state, action: PayloadAction<Seleccionado>) {
      const toAdd = {
        index: action.payload.index,
        row: action.payload.row,
      }
      state.seleccionado = toAdd
    },
    setResetSeleccion(state) {
      const toAdd = {
        index: null,
        row: null,
      }
      state.seleccionado = toAdd
    },
    changeLoaderDiarioBancos(state, action: PayloadAction<LoaderInfo>) {
      state.loader = action.payload
    },
  }
})

export const { changeLoaderDiarioBancos, setCurrentFunction, obtenerAcciones, setData, setCurrentAccion, setSeleccionarDato, setResetSeleccion } = diarioBancosSlice.actions;
export const DiarioBancosReducer = diarioBancosSlice.reducer;