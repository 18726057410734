import React from 'react'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput'
import { List } from 'devextreme-react'
import { consoleService } from '../../../../../../services/console.service'
import { TabState } from '../../../../../../store/genericTabTypes'
import LocalesLookUp from '../../../../../componentes/localesLookUp'
import TiposPersonasLookUp from '../../../../../componentes/tiposPersonasLookUp'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/store'
import { PersonalServices } from '../../services/personal.service'
import { changeFilterUserLocal, setChangeLoaderUserLocal, setResetSeleccionUsuario, setResetSeleccionlocal, setResultsUserLocal, setResultsUserSinLocal, setSeleccionarDatoLocal, setSeleccionarDatoUser } from '../../store/searchReducer'
import { ToastTypes, UsuarioLocal } from '../../../../../../store/types'
import { addToast } from '../../../../../../store/toasterReducer'
import BlockUi from '../../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../../views/componentes/loadingindicator/loadingindicator'
import { setCurrentExecutingAction } from '../../store/tabsReducer'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import { CButton, CTooltip } from '@coreui/react-pro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { adminService } from '../../../../../admin/cuenta/usuario/services/adminService'
import { sidebarService } from '../../../../../../services/sidebar.service'
import Dialog from '../../../../../../views/componentes/librerias/bootstrap-dialog'
import { UsuarioLocalList } from '../../types/types'
import { getIndexProvider } from '../../../../../shared/helpers/funciones'
import { tiposPersonasServices } from '../../../../../componentes/tiposPersonasLookUp/service/tiposPersonas.service'
import { OptionTiposPersonas } from '../../../../../componentes/tiposPersonasLookUp/types/types'

interface IAsignarUsuariosLocalesProps extends React.PropsWithChildren {
  tab: TabState<any>
  tabId: string
}

const AsignrUsuariosLocales: React.FunctionComponent<IAsignarUsuariosLocalesProps> = (props) => {
  const { tabId } = props


  const dispatch = useDispatch()
  const usersLocalState = useSelector((state: RootState) => state.nomina.personal.search.filtroUsuarioLocal)
  const user = useSelector((state: RootState) => state.global.usuario)
  const resUsuariosLocal = useSelector((state: RootState) => state.nomina.personal.search.resultadosUsuarioLocal)
  const seleccionado = useSelector((state: RootState) => state.nomina.personal.search.seleccionadoUsuario)
  const seleccionadoLocal = useSelector((state: RootState) => state.nomina.personal.search.seleccionadoLocal)
  const loader = useSelector((state: RootState) => state.nomina.personal.search.loaderUserLocal)
  const resUsuariosSinLocal = useSelector((state: RootState) => state.nomina.personal.search.resultadosUsuarioSinLocal)
  const empresaState = useSelector(
    (state: RootState) => state.global.session?.empresa,
  )
  const dialogRef = React.useRef<any>(null)
  const [confirmDelete, setConfirmDelete] = React.useState<boolean>(false)

  const onLoader = React.useCallback((showLoader: boolean, mensaje: string) => {
    dispatch(setChangeLoaderUserLocal({
      show: showLoader,
      mensaje: mensaje
    }))
  }, [dispatch])

  const onSetButtonAction = React.useCallback(
    (tipo: ButtonTypes | undefined) => {
      dispatch(
        setCurrentExecutingAction({
          tabKey: tabId,
          buttonType: tipo,
        }),
      )
    },
    [dispatch, tabId],
  )

  const setToast = React.useCallback(
    (mensaje: string, tipo: ToastTypes) => {
      dispatch(
        addToast({
          title: 'Nómina - Personal/UsuariosLocal',
          content: mensaje,
          type: tipo,
        }),
      )
    },
    [dispatch],
  )


  const onGetInitData = React.useCallback(async (localCodigo: number) => {
    onSetButtonAction(ButtonTypes.assignLocalUsers)
    onLoader(true, 'Cargando...')
    try {
      const dataRes = await PersonalServices.getUsuariosSinLocal(localCodigo, user?.rol ?? '')
      const data = await PersonalServices.getUsuariosLocal(localCodigo)
      if (dataRes['auto'] && dataRes['error'] === false) {
        dispatch(setResultsUserSinLocal(dataRes['auto']))
      }
      if (data['auto'] && data['error'] === false) {
        dispatch(setResultsUserLocal(data['auto']))
      }
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
    onLoader(false, '')
    onSetButtonAction(undefined)
  }, [setToast, user, dispatch, onLoader, onSetButtonAction])

  const onChangeValue = React.useCallback((data, key: string) => {
    dispatch(changeFilterUserLocal({
      ...usersLocalState,
      [key]: data
    }))
  }, [usersLocalState, dispatch])

  const getRecordList = React.useCallback(async (data: UsuarioLocalList, from: Array<UsuarioLocalList>, to: Array<UsuarioLocalList>, option: number) => {
    const provider = from.slice(0)
    const usuarioLocal = to.slice(0)
    const index = await getIndexProvider(provider, 'codigoUsuario', data?.codigoUsuario ?? 0)
    if (index > -1) {
      const nuevo = provider[index]
      provider.splice(index, 1)
      usuarioLocal.push({ ...nuevo })
      if (option == 1) {
        dispatch(setResultsUserSinLocal(provider))
        dispatch(setResultsUserLocal(usuarioLocal))
        dispatch(setResetSeleccionUsuario())
      } else if (option == 2) {
        dispatch(setResultsUserLocal(provider))
        dispatch(setResultsUserSinLocal(usuarioLocal))
        dispatch(setResetSeleccionlocal())
      }
    }
  }, [dispatch])

  const onAddUserToLocal = React.useCallback(async () => {
    if (seleccionado !== null && seleccionado?.codigoUsuario) {
      if (usersLocalState?.tipoUsuario && usersLocalState?.tipoUsuario?.codigo > 0) {
        if (resUsuariosLocal.length >= empresaState?.numUsuarios && empresaState?.tipoLicencia === 0) {
          setToast('Alcanzó el máximo de usarios permitidos.', ToastTypes.Warning)
        } else {
          onSetButtonAction(ButtonTypes.assignLocalUsers)
          onLoader(true, 'Asignando...')
          const usuarioLocal: UsuarioLocal = {
            local: usersLocalState?.locales ? usersLocalState?.locales?.codigo : 0,
            usuarioCodigo: seleccionado?.codigoUsuario ?? 0,
            usuarioTipo: usersLocalState?.tipoUsuario ? usersLocalState?.tipoUsuario?.descripcion : '',
            empresaCodigo: empresaState?.codigo ?? 2
          }
          try {

            const relacionado = await sidebarService.relationUserLocal(usuarioLocal);
            consoleService.log(relacionado, 'relacionado')
            if (relacionado['auto'] && relacionado['error'] === false) {
              setToast(relacionado['message'], ToastTypes.Success)
              getRecordList(seleccionado, resUsuariosSinLocal, resUsuariosLocal, 1)
            } else {
              setToast(relacionado['message'], ToastTypes.Danger)
            }
          } catch (error) {
            setToast(error.message, ToastTypes.Danger)
          }
          onLoader(false, '')
          onSetButtonAction(undefined)
        }
      } else {
        setToast('Usted no ha seleccionado un tipo de usuario.', ToastTypes.Warning)
      }
    } else {
      setToast('Debe seleccionar un usuario para poder asignar.', ToastTypes.Warning)
    }
  }, [seleccionado, setToast, usersLocalState, empresaState, onLoader, onSetButtonAction, resUsuariosLocal, getRecordList, resUsuariosSinLocal])

  const onConfirm = React.useCallback(async () => {
    if (seleccionadoLocal !== null && seleccionadoLocal?.codigoUsuario) {
      setConfirmDelete(true)
    } else {
      setToast('Debe seleccionar el usuario que desea separar del local.', ToastTypes.Warning)
    }
  }, [seleccionadoLocal, setToast])

  const onHandleDelete = React.useCallback(async (data: any) => {
    onSetButtonAction(ButtonTypes.assignLocalUsers)
    onLoader(true, 'Quitando...')
    try {
      const dataRes = await PersonalServices.deleteUserLocal(usersLocalState?.locales?.codigo ?? 0, data?.codigoUsuario ?? 0)
      if (dataRes['auto'] && dataRes['error'] === false) {
        setToast(dataRes['message'], ToastTypes.Success)
        getRecordList(seleccionadoLocal, resUsuariosLocal, resUsuariosSinLocal, 2)
      } else {
        setToast(dataRes['message'], ToastTypes.Danger)
      }
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
    onLoader(false, '')
    onSetButtonAction(undefined)
  }, [setToast, usersLocalState, onLoader, onSetButtonAction, getRecordList, resUsuariosSinLocal, resUsuariosLocal, seleccionadoLocal])

  const onGetLocal = React.useCallback(async () => {
    const temp = { ...usersLocalState }
    if (temp.initial) {
      const resLocal = await adminService.obtenerLocalesUsuario("Elija un local")
      const data = await tiposPersonasServices.getTipos(179, 'Elija tipo')
      if (data['auto'] && data['error'] === false) {
        const item: OptionTiposPersonas = {
          codigo: 0,
          descripcion: data['auto'][0]?.descripcion ?? '',
        }
        temp.tipoUsuario = item
      }
      consoleService.log(resLocal, 'resLocal')
      if (resLocal['auto'] && resLocal['error'] === false) {
        onGetInitData(resLocal['auto'][0]?.localCodigo ?? -1)
        temp.locales = resLocal['auto'][0]
      }
      temp.initial = false
      dispatch(changeFilterUserLocal({
        ...temp
      }))
    }
  }, [onGetInitData, dispatch, usersLocalState])

  const onSelectionChangeUser = React.useCallback(({ addedItems }) => {
    consoleService.log(addedItems[0], 'UsurioToLocal add')
    if (addedItems[0]?.codigoUsuario) {
      dispatch(setSeleccionarDatoUser({ ...addedItems[0] }))
    }
  }, [dispatch])

  const onSelectionChangeLocal = React.useCallback(({ addedItems }) => {
    consoleService.log(addedItems[0], 'UsurioToLocal delete')
    if (addedItems[0]?.codigoUsuario) {
      dispatch(setSeleccionarDatoLocal({ ...addedItems[0] }))
    }
  }, [dispatch])

  React.useEffect(() => {
    onGetLocal()
    // onGetInitData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const onConfirmDelete = React.useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea Quitar el usuario: ${seleccionadoLocal?.nombresUsuario ?? '-'} del local: ${usersLocalState?.locales?.nombre ?? '-'}`,
      actions: [
        Dialog.Action(
          <span>
            <u>A</u>ceptar
          </span>,
          (dialog) => {
            dialog.hide()
            onHandleDelete(seleccionadoLocal)
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setConfirmDelete(false)
    return
  }, [onHandleDelete, seleccionadoLocal, usersLocalState])

  React.useEffect(() => {
    if (confirmDelete) {
      onConfirmDelete()
    }
  }, [confirmDelete, onConfirmDelete])

  return (
    <>
      <Dialog ref={dialogRef} />
      <div id="PersonalContent">
        <BlockUi
          tag="div"
          loader={LoadingIndicator}
          blocking={loader.show}
          message={loader.mensaje}
        >

          <RowContainer>
            <CustomCol xs="12" md="5" >
              <RowContainer>
                <CustomCol>
                  <Labeled label="Tipo de Uusario:">
                    <TiposPersonasLookUp
                      provider={[]}
                      onChanged={(data) => {
                        consoleService.log('data ', data)
                        onChangeValue(data, 'tipoUsuario')
                      }}
                      onChangedOptions={() => { }}
                      selected={usersLocalState?.tipoUsuario ?? null}

                    />
                  </Labeled>
                </CustomCol>
              </RowContainer>
              <RowContainer>
                <CustomCol xs="12" md="12">
                  <Labeled label="Usuarios por Asignar:">
                    <List
                      searchEnabled={true}
                      onSelectionChanged={onSelectionChangeUser}
                      searchMode='contains'
                      dataSource={resUsuariosSinLocal ?? []}
                      selectionMode='single'
                      keyExpr="codigoUsuario"
                      height={500}
                      displayExpr="nombresUsuario"
                      searchExpr="nombresUsuario"
                    />
                  </Labeled>
                </CustomCol>
              </RowContainer>
            </CustomCol>
            <CustomCol xs="12" md="2">
              <div
                style={{
                  display: 'grid',
                  gap: '2px',
                  alignContent: 'center',
                  justifyContent: 'center',
                  height: '100%',
                  width: '100%',
                }}
              >

                <CButton
                  id="btnBuscarCuentas"
                  color="secondary"
                  className="ms-1"
                  disabled={seleccionado === null}
                  size="sm"
                  onClick={() => onAddUserToLocal()}
                >
                  <FontAwesomeIcon
                    size="lg"
                    icon={['fal', 'chevron-right']}
                    style={{ fontWeight: 1 }}
                  />
                  <FontAwesomeIcon
                    size="lg"
                    icon={['fal', 'chevron-right']}
                    style={{ fontWeight: 1 }}
                  />

                </CButton>
                <CButton
                  id="btnBuscarCuentas"
                  color="secondary"
                  className="ms-1"
                  disabled={seleccionadoLocal === null}
                  size="sm"
                  onClick={() => onConfirm()}
                >
                  <FontAwesomeIcon
                    size="lg"
                    icon={['fal', 'chevron-left']}
                    style={{ fontWeight: 1 }}
                  />
                  <FontAwesomeIcon
                    size="lg"
                    icon={['fal', 'chevron-left']}
                    style={{ fontWeight: 1 }}
                  />
                </CButton>
              </div>
            </CustomCol>
            <CustomCol xs="12" md="5" >
              <RowContainer>
                <CustomCol>
                  <Labeled label="Locales:">
                    <LocalesLookUp
                      onChanged={(data) => {
                        if (data !== usersLocalState?.locales && data !== null) {
                          onChangeValue(data, 'locales')
                          onGetInitData(data?.codigo ?? -1)
                        }
                      }}
                      defecto={false}
                      onChangedOptions={() => { }}
                      selected={usersLocalState?.locales ?? null}
                    />
                  </Labeled>
                </CustomCol>
              </RowContainer>
              <RowContainer>
                <CustomCol xs="12" md="12">
                  <Labeled label="Usuarios Asignados">
                    <List
                      searchEnabled={true}
                      onSelectionChanged={onSelectionChangeLocal}
                      searchMode='contains'
                      dataSource={resUsuariosLocal ?? []}
                      selectionMode='single'
                      keyExpr="codigoUsuario"
                      height={500}
                      displayExpr="nombresUsuario"
                      searchExpr="nombresUsuario"
                    />
                  </Labeled>
                </CustomCol>
              </RowContainer>
            </CustomCol>
          </RowContainer>
        </BlockUi>
      </div>
    </>
  )
}

export default React.memo(AsignrUsuariosLocales)