import React from 'react';
import SelectBox from 'devextreme-react/select-box';
import { tipoEstablecimientoService } from './service/tipoEstablecimiento.service';
import { TipoEstablecimientoOption } from './store/type';
import { v4 as uuidv4 } from 'uuid'

interface LookUpPropsTipoEstablecimiento extends React.PropsWithChildren {
  selected: TipoEstablecimientoOption | null,
  onChanged: (newValue: TipoEstablecimientoOption | null) => void,
  disabled?: boolean,
  provider?: Array<TipoEstablecimientoOption> | []
  id?: string
  paisCodigo: number
}

const LookUpTipoEstablecimiento: React.FC<LookUpPropsTipoEstablecimiento> = (props) => {
  const { paisCodigo, id, provider, selected, onChanged, disabled } = props;
  const selectRef = React.useRef<any>();
  const [options, setOptions] = React.useState<Array<TipoEstablecimientoOption> | []>([]);

  const loadDatos = React.useCallback(async () => {
    if (provider !== undefined && provider.length > 0) {
      setOptions(provider)
      const selectedIndex = provider.findIndex((select: TipoEstablecimientoOption) => {
        return select.codigo === selected?.codigo
      })
      onChanged(provider[selectedIndex] ?? provider[0])
      if (selectedIndex > -1) {
        onChanged(provider[selectedIndex] ?? provider[0])
      }
    } else {
      const data = await tipoEstablecimientoService.getTiposEstablecimientos("Elija una opción", paisCodigo);
      if (data.error === false) {
        let opt = [];
        if (data.auto.length > 0) {
          opt = data.auto.map((x) => {
            const item: TipoEstablecimientoOption = {
              codigo: parseInt(x?.codigo) ?? -1,
              descripcion: x?.descripcion ?? '',
              tipoCodigo: x?.tipoCodigo ?? 0,
              grupo: x?.grupo ?? '',
              orden: parseInt(x?.orden) ?? 1,
              estado: parseInt(x?.estado) === 1 ? true : false,
            }
            return item
          })
        }
        setOptions(opt)
        const selectedIndex = opt.findIndex((option) => {
          return option.codigo === selected?.codigo
        })
        if (selectedIndex > -1) {
          onChanged(opt[selectedIndex] ?? opt[0])
        }
      }
    }
  }, [onChanged, selected, provider, paisCodigo]);

  const getItemDisplayExpr = React.useCallback((item: TipoEstablecimientoOption) => {
    return item ? `${item.descripcion}` : '';
  }, []);

  const onLookUpValueChanged = React.useCallback(async (value: any) => {
    if (value !== undefined) {
      await onChanged(value);
    }
  }, [onChanged]);

  const onSelectBoxFocusIn = React.useCallback((e) => {
    e.element.querySelector("input.dx-texteditor-input").select();
  }, [])

  const refreshSelected = React.useCallback(async () => {
    if (options && options.length > 0) {
      const selectedIndex = await options.findIndex((option: TipoEstablecimientoOption) => {
        return option.codigo === selected?.codigo
      })
      onChanged(options[selectedIndex] ?? options[0])
    }
    selectRef.current.instance.repaint();
  }, [onChanged, options, selected])

  React.useEffect(() => {
    loadDatos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    refreshSelected()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  return (
    <SelectBox
      id='tipoEstablecimientoSelect'
      key={id ?? uuidv4()}
      ref={selectRef}
      items={options ?? []}
      searchEnabled
      displayExpr={getItemDisplayExpr}
      searchExpr={['descripcion']}
      searchTimeout={500}
      value={selected}
      onValueChange={onLookUpValueChanged}
      disabled={disabled}
      onFocusIn={onSelectBoxFocusIn}
    >
      {props.children}
    </SelectBox>
  )

}
export default React.memo(LookUpTipoEstablecimiento);
