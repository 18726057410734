import { utilidades } from "../../../../helpers/utilidades"
import { KeyKitsPartesLook } from "../store/enumKeys"
import { TKitsPartesOption } from "../store/type"

export function parseLocalOptions(options: Array<any>) {

  let opt: Array<TKitsPartesOption> = []
  if (options.length > 0) {
    opt = options.map((x) => {
      const item: TKitsPartesOption = {
        codigo: parseInt(x[KeyKitsPartesLook.cod]) ?? 0,
        descripcion: utilidades.capText(x[KeyKitsPartesLook.desc]) ?? '',
        estado: parseInt(x?.[KeyKitsPartesLook.state]) ?? 0,
      }
      return item
    })
  }

  return opt
}