const validations = {
  es: {
    required: 'es requerido',
    select: 'debe seleccionar una opción.',
    min: 'debe tener al menos %num_characters% caracteres',
    max: 'no puede ser mayor a %num_characters% caracteres',
    email: 'dirección de correo electrónico no válida',
    number: 'debe ser un tipo numérico',
    password:
      'debe contener 8 caracteres, una mayúscula, una minúscula, un número y un carácter especial.',
    formatUnsoported: 'Formato no soportado',
    sizeLong: 'Archivo demasiado grande',
    match: 'no coinciden',
    positive: 'no puede tener un valor negativo.',
  },
  en: {
    required: 'is required',
    select: 'you must select an option.',
    min: 'has to be at least %num_characters% characters',
    max: 'cannot be longer than %num_characters% characters',
    email: 'invalid email address',
    number: 'must be a number type',
    password:
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special Character.',
    formatUnsoported: 'Unsupported Format',
    sizeLong: 'File too large',
    match: 'do not match',
    positive: 'cannot have a negative value',
  },
}

export default validations
