import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput'
import {
  CheckBox,
  DateBox,
  RadioGroup,
  TextBox,
} from 'devextreme-react'
import LocalesLookUp from '../../../../../../componentes/localesLookUp'
import TiposMovimientosLookUp from '../../../../../../componentes/tiposMovimientosLookUp'
import { RootState } from '../../../../../../../store/store'
import { changeFilter, fetchIncomeExpenses, setAlmacen, setColapsado } from '../../store/serachReducer'
import { getIndexProvider } from '../../../../../../shared/helpers/funciones'
import { ProveedorBusqueda } from '../../../../../../proveedores/store/types'
import { DateUtils, formatoFechasApi } from '../../../../../../../helpers/dateUtils'
import { ETipoAsociado, ToastTypes } from '../../../../../../../store/types';
import { addToast } from '../../../../../../../store/toasterReducer'
import { ETypeMovimiento, FiltrosIngresosEgresosState } from '../../types/types';
import { BuscarProveedorLookUp } from '../../../../../../proveedores/components/busquedaProveedor/BuscarProveedorLookUp'
import BuscarClienteLookUp from '../../../../../../ventas/components/busquedaCliente/busquedaCliente'
import { getTypesMovements } from '../../helper/helper'
import { getLocales } from '../../../../../../componentes/localesLookUp/helper/helper'
import BuscarItemPedidoMemoLookUp from '../../../../../../ventas/pages/ordenes/pages/ordenPedido/components/busquedaPedidos/BusquedaItemsSelectBox/BuscarItemPedidoMemoLookUp'
import { consoleService } from '../../../../../../../services/console.service'
import { EModulosAcatha } from '../../../../../../../store/enumsAplication'
import { CCardBody, CCardHeader, CCollapse, CLink } from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilChevronBottom, cilChevronTop } from '@coreui/icons'
import { isMobile } from 'react-device-detect'
import { utilidades } from '../../../../../../../helpers/utilidades'


export const typeItems = [
  { value: 0, label: 'Todos' },
  { value: 1, label: 'Item' },
  { value: 4, label: 'Materia Prima' },
  { value: 7, label: 'Activos/Bienes' },
]

export const optionsPrioridad = [
  { value: false, label: 'Descripcion' },
  { value: true, label: 'Barras' },
]

const FiltroBusqueda = () => {

  const dispatch = useDispatch()
  const searchFilter = useSelector((state: RootState) => state.inventarios.movimientos.ingresosEgresos.search.filter)
  const collapsed = useSelector((state: RootState) => state.inventarios.movimientos.ingresosEgresos.search.colapsado)
  const currentTab = useSelector((state: RootState) => state.inventarios.movimientos.ingresosEgresos.search.currentTab)
  const periodoActivo = useSelector(
    (state: RootState) => state.shared.providers.periodoContable,
  )
  const [prioridadBarras, setPrioridadBarras] = React.useState<{ value: boolean, label: string }>(optionsPrioridad[1])
  const localSession = useSelector((state: RootState) => state.global.session.local)

  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(
      addToast({
        title: 'Inventario-Ingresos/Egresos',
        content: mensaje,
        type: tipo,
      }),
    )
  }, [dispatch])
  const fechaFormato: string = 'dd/MM/yyyy'
  const fechaFormatoInput: string = 'yyyy-MM-dd'

  const onHandleSearch = React.useCallback(async () => {
    const cod = searchFilter?.codigo ? parseInt(searchFilter?.codigo) : 0

    const desde = searchFilter?.fechaDesde ? DateUtils.format(searchFilter?.fechaDesde, fechaFormato, fechaFormatoInput) : DateUtils.getCurrentDateAsString(fechaFormato)
    const hasta = searchFilter?.fechaHasta ? DateUtils.format(searchFilter?.fechaHasta, fechaFormato, fechaFormatoInput) : DateUtils.getCurrentDateAsString(fechaFormato)

    const search_data: any = {
      codigo: cod,
      documento: searchFilter?.documento ?? '',
      codigoLocal: searchFilter.almacen?.codigo ?? -1,
      prodCliente: searchFilter.proveedor?.codigo ?? 0,
      tipo: searchFilter.inventarioInicial ? 'INVENTARIO' : 'AJUSTES',
      impreso: searchFilter.tipoItem?.value ?? 0,
      movimiento: searchFilter.movimientos?.codigo ?? -1,
      observaciones: searchFilter?.observaciones ?? '',
      estado: searchFilter.items?.codigoBarras ?? '',
      fechaDesde: desde,
      fechaHasta: hasta,
      currentTab,
      consolidado: searchFilter?.cosolidar ? true : false
    }
    try {
      const toAny: any = fetchIncomeExpenses(search_data)
      const res = await dispatch(toAny)
      if (res !== null && res !== undefined && res['payload']?.error === false) {
        if (res['payload']?.auto && res['payload'].auto.length > 5) {
          dispatch(setColapsado(false))
        }
        setToast(res['payload']?.message, ToastTypes.Success)
      } else {
        setToast(res['payload']?.message, ToastTypes.Danger)
      }
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
  }, [searchFilter, currentTab, dispatch, setToast])


  const onInitSearchingEg = React.useCallback(async () => {
    const data = { ...searchFilter }
    if (data.initial) {
      const resMovimientos = await getTypesMovements()
      const resLocal = await getLocales('Elija una opcion')

      data.tipoItem = typeItems[0]
      consoleService.log(periodoActivo)
      data.fechaDesde = periodoActivo.length > 0 ? DateUtils.format(periodoActivo[0].finicio, fechaFormatoInput, fechaFormato) : DateUtils.getCurrentDateAsString(fechaFormatoInput)
      data.fechaHasta = periodoActivo.length > 0 ? DateUtils.format(periodoActivo[0].fcierre, fechaFormatoInput, fechaFormato) : DateUtils.getCurrentDateAsString(fechaFormatoInput)
      data.movimientos = resMovimientos.length > 0 ? resMovimientos[0] : null
      if (resLocal.length > 0) {
        const provider = resLocal.slice(0)
        const index = await getIndexProvider(provider, 'codigo', localSession?.codigo ?? 0)
        if (index > -1) {
          data.almacen = resLocal[index]
        } else {
          data.almacen = resLocal[0]
        }
      }
      data.items = null
      data.initial = false

      dispatch(changeFilter({
        ...data
      }))
    }
  }, [dispatch, periodoActivo, searchFilter, localSession])

  const onValueChanged = React.useCallback(async (data, key: string) => {
    let value = null
    if (data?.event !== null && data?.event !== undefined) {
      value = data.value
    } else if (key === "almacen" || key === "movimientos" || key === "proveedor" || key === "cliente" || key === "items") {
      value = data
    } else if (key === "tipoItem" && data?.value !== null) {
      const provider = typeItems.slice(0)
      const index = await getIndexProvider(provider, 'value', data.value)
      if (index > -1) {
        value = provider[index]
      }
    }

    if (key === "proveedor" || key === "cliente" || key === "items") {
      dispatch(changeFilter({
        ...searchFilter,
        [key]: value
      }))
    } else {
      if (value !== null && value !== undefined) {
        dispatch(changeFilter({
          ...searchFilter,
          [key]: value
        }))
      }
    }
  }, [dispatch, searchFilter])


  const onAsociadoProv = React.useCallback((data) => {
    let data_prov: ProveedorBusqueda = null
    if (searchFilter.movimientos?.asociado === ETypeMovimiento.compras) {
      data_prov = data
      onValueChanged(data_prov, 'proveedor')
    } else if (searchFilter.movimientos?.asociado === ETypeMovimiento.ventas) {
      onValueChanged(data, 'cliente')
    }
  }, [onValueChanged, searchFilter.movimientos?.asociado])

  const onChangeAlamcen = React.useCallback((data) => {
    dispatch(setAlmacen(data))
  }, [dispatch])

  const seleccionarProveedor = () => {
    return (
      <Labeled label="Proveedor:">
        <BuscarProveedorLookUp
          selected={searchFilter.proveedor as ProveedorBusqueda}
          allowAdd
          allowClear
          allowEdit
          onChanged={onAsociadoProv}
        />
      </Labeled>
    )
  }

  const seleccionarCliente = () => {
    return (
      <Labeled label="Cliente:">
        <BuscarClienteLookUp
          selected={searchFilter?.cliente}
          onChanged={onAsociadoProv}
          allowClear
          allowAdd
          allowEdit />
      </Labeled>
    )
  }

  const showDinamycLookUp = () => {
    if (searchFilter.movimientos?.asociado === ETypeMovimiento.ventas) {
      return seleccionarCliente()
    } else if (searchFilter.movimientos?.asociado === ETypeMovimiento.compras) {
      return seleccionarProveedor()
    }
  }


  React.useEffect(() => {
    onInitSearchingEg()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (periodoActivo.length > 0) {
      dispatch(changeFilter({
        ...searchFilter,
        fechaDesde: DateUtils.format(periodoActivo[0].finicio, fechaFormatoInput, fechaFormato),
        fechaHasta: DateUtils.format(periodoActivo[0].fcierre, fechaFormatoInput, fechaFormato)
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodoActivo])

  return (
    <>
      <CCardHeader
        onClick={() => dispatch(setColapsado(!collapsed))}
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <strong> {collapsed ? '' : 'Filtro de Búsqueda'} </strong>
        <CLink className="card-header-action">
          <CIcon icon={collapsed ? cilChevronBottom : cilChevronTop} />
        </CLink>
      </CCardHeader>
      <CCollapse visible={collapsed}>
        <CCardBody>
          <RowContainer>
            <RowContainer>
              <CustomCol xs="12" md="4">
                <Labeled label="Rango de Fechas:">
                  <div
                    style={{
                      display: 'flex',
                      gap: '2px',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <DateBox
                      displayFormat="dd/MM/yyyy"
                      value={searchFilter?.fechaDesde}
                      onValueChanged={(data) => onValueChanged(data, 'fechaDesde')}
                      onEnterKey={onHandleSearch}
                    />
                    <DateBox
                      displayFormat="dd/MM/yyyy"
                      value={searchFilter?.fechaHasta}
                      onValueChanged={(data) => onValueChanged(data, 'fechaHasta')}
                      onEnterKey={onHandleSearch}
                    />
                  </div>
                </Labeled>
              </CustomCol>
              <CustomCol xs="12" md="2">
                <Labeled label="Código Mov:">
                  <TextBox
                    value={searchFilter?.codigo ?? ''}
                    onValueChanged={(data) => onValueChanged(data, 'codigo')}
                    onEnterKey={onHandleSearch}
                    onKeyDown={utilidades.filtraTeclasNoNumericas}
                  />
                </Labeled>
              </CustomCol>
              <CustomCol xs="12" md="2">
                <Labeled label="Documento:">
                  <TextBox
                    value={searchFilter?.documento ?? ''}
                    onValueChanged={(data) => onValueChanged(data, 'documento')}
                    onEnterKey={onHandleSearch}
                    onKeyDown={utilidades.filtraTeclasNoNumericas}
                  />
                </Labeled>
              </CustomCol>
            </RowContainer>
            <RowContainer>
              <CustomCol xs="12" md="4">
                <Labeled label="Movimientos:">
                  <TiposMovimientosLookUp
                    selected={searchFilter?.movimientos ?? null}
                    onChanged={(data) => onValueChanged(data, 'movimientos')}
                    onChangedOptions={() => { }}
                  />
                </Labeled>
              </CustomCol>
              <CustomCol xs="12" md="4">
                {searchFilter?.movimientos !== null && showDinamycLookUp()}
              </CustomCol>
            </RowContainer>
            <RowContainer>
              <CustomCol xs="12" md="4">
                <Labeled label="Almacén:">
                  <LocalesLookUp
                    onChanged={onChangeAlamcen}
                    onChangedOptions={() => { }}
                    selected={searchFilter.almacen ?? null}
                  />
                </Labeled>
              </CustomCol>
            </RowContainer>
            <RowContainer>
              <CustomCol xs="12" md="9">
                <Labeled label="Items:">
                  <RowContainer>
                    <CustomCol md={searchFilter.items === null ? "6" : "8"}>
                      <BuscarItemPedidoMemoLookUp
                        selected={searchFilter.items ?? null}
                        onChanged={(data) => onValueChanged(data, 'items')}
                        cliente={
                          searchFilter.cliente !== null ? searchFilter.cliente : null
                        }
                        fecha={DateUtils.getCurrentDateAsString(formatoFechasApi)}
                        prioridadBarras={prioridadBarras?.value ?? true}
                        modulo={EModulosAcatha.Kardex}
                        tipoAsociado={
                          searchFilter?.movimientos !== null &&
                            searchFilter.movimientos?.asociado ===
                            ETypeMovimiento.ventas
                            ? ETipoAsociado.Clientes
                            : null
                        }
                        local={searchFilter?.almacen !== null && searchFilter?.almacen !== undefined ? searchFilter?.almacen : undefined}
                        movimiento={searchFilter?.movimientos}
                        allowMoreOption={true}
                        allowClear={true}
                        allowEdit={true}
                        allowAdd={true}
                        codigoPrvAsociado={-1}
                      />
                    </CustomCol>
                    <CustomCol md="4">
                      <RadioGroup
                        name="iOptionsMov"
                        dataSource={optionsPrioridad}
                        value={prioridadBarras}
                        layout="horizontal"
                        displayExpr="label"
                        onValueChange={(data) => {
                          setPrioridadBarras(data)
                        }}
                      />
                    </CustomCol>
                  </RowContainer>
                </Labeled>
              </CustomCol>
            </RowContainer>
            <RowContainer></RowContainer>
            <RowContainer>
              <CustomCol xs="12" md="4">
                <Labeled label="Observaciones:">
                  <TextBox
                    value={searchFilter?.observaciones ?? ''}
                    onValueChanged={(data) => onValueChanged(data, 'observaciones')}
                    onEnterKey={onHandleSearch}
                  />
                </Labeled>
              </CustomCol>
              <CustomCol
                xs="12"
                md="7"
                style={{ display: 'flex', marginTop: 'auto', gap: '6 px' }}
              >
                <Labeled label="">
                  <CheckBox
                    text="Consolidar"
                    className="m-3"
                    value={searchFilter?.cosolidar ?? false}
                    onValueChanged={(data) => onValueChanged(data, 'cosolidar')}
                  />
                  <CheckBox
                    text="Inventario Inicial"
                    className="m-3"
                    value={searchFilter?.inventarioInicial ?? false}
                    onValueChanged={(data) =>
                      onValueChanged(data, 'inventarioInicial')
                    }
                  />
                  <CheckBox
                    text="Mostrar E/S Rápida"
                    className="m-3"
                    value={searchFilter?.esRapida ?? false}
                    onValueChanged={(data) => onValueChanged(data, 'esRapida')}
                  />
                </Labeled>
              </CustomCol>
            </RowContainer>
            <RowContainer>
              <CustomCol md="6">
                <Labeled label="">
                  <RadioGroup
                    name="tipo-movimiento"
                    layout="horizontal"
                    displayExpr="label"
                    items={typeItems}
                    value={searchFilter.tipoItem ?? null}
                    onValueChanged={(data) => onValueChanged(data, 'tipoItem')}
                  />
                </Labeled>
              </CustomCol>
            </RowContainer>
          </RowContainer>
        </CCardBody>
      </CCollapse>
    </>
  )
}


export const initSearch: FiltrosIngresosEgresosState = {
  fechaDesde: DateUtils.dateToString(
    new Date(new Date().getFullYear(), 0, 1),
  ),
  fechaHasta: DateUtils.dateToString(
    new Date(new Date().getFullYear(), 11, 31),
  ),
  codigo: '',
  documento: '',
  almacen: null,
  proveedor: null,
  cliente: null,
  cosolidar: false,
  inventarioInicial: false,
  esRapida: false,
  movimientos: null,
  items: null,
  tipoItem: null,
  observaciones: '',
  initial: true
}
export default React.memo(FiltroBusqueda)
