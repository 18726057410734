import { combineReducers } from "redux";
import { ModuloRegistrosState } from "../types/types";
import { HorariosReducer } from "../horarios/store/horariosReducer";
import { CalendariosReducer } from "../calendarios/store/calendarioReducer";
import { MarcacionReducer } from "../marcacion/store/marcacionReducer";


export const moduloRegistrosReducer = combineReducers<ModuloRegistrosState>({
  horarios: HorariosReducer,
  calendarios: CalendariosReducer,
  marcacion: MarcacionReducer
})