import React, { useCallback, useEffect, useRef } from 'react'
import { DocumentInfo, ToastTypes } from '../../../../../../../store/types'
import { AddHour, HorarioDatosEdicion, HorarioDet, HorarioDto } from '../../types/types'
import { TabState } from '../../../../../../../store/genericTabTypes'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput'
import { Button, DataGrid, DateBox, TextBox, ValidationGroup, ValidationSummary, Validator } from 'devextreme-react'
import TipoEstadoLookUp from '../../../../../../componentes/tipoEstado/'
import { consoleService } from '../../../../../../../services/console.service'
import DiasLookup from '../../../../../../componentes/diasLookup'
import { AsyncRule, Column, Pager, Paging, RequiredRule } from 'devextreme-react/data-grid'
import { getAllowedPageSizes } from '../../../../../../../helpers/Helper'
import ResultadosBusqueda from '../buscar/ResultadosBusqueda'
import ItemsCountIndicator from '../../../../../../componentes/indicadorNroRegistros'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import { ButtonTypes } from '../../../../../../../views/componentes/globalMenu/types'
import { clearButtonClick, setButtons, setCurrentExecutingAction, setNameTab } from '../../store/tabsReducer'
import { StatesEdition } from '../../../../../../../store/enums'
import { tipoEstadoService } from '../../../../../../componentes/tipoEstado/service/tipoEstado.service'
import { setChangeLoader, setDatosEdicion, setEditSeleccionado } from '../../store/editDataReducer'
import { addToast } from '../../../../../../../store/toasterReducer'
import { setDatosEdicionBackup } from '../../store/configReducer'
import { days } from '../../../../../../componentes/diasLookup/types/types'
import BlockUi from '../../../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../../../views/componentes/loadingindicator/loadingindicator'
import { isMobile } from 'react-device-detect'
import validations from '../../../../../../../config/validations'
import VisualizaError from '../../../../../../ventas/pages/shared/visualizaError/visualizaError'
import ListErrosValidationForm from '../../../../../../componentes/listadoErroresValidacionForm'
import { MessagesKeys, lh } from '../../../../../../../helpers/localizationHelper'
import { HoursServices } from '../../services/horarios.services'
import { ModuleButtons } from '../../index';
import { getIndexProvider } from '../../../../../../shared/helpers/funciones'
import { HorariosTrabajosServices } from '../../../../../../componentes/modalHorariosTrabajo/services/horariosTrabajo.services'
import { ReportWithLocalDataModal } from '../../../../../../../views/componentes/xtrareports/ReportWithLocalDataModal'


interface INuevoProps extends React.PropsWithChildren {
  info: DocumentInfo<HorarioDatosEdicion>
  tab: TabState<HorarioDatosEdicion>
  tabId: string
}


const Nuevo: React.FunctionComponent<INuevoProps> = (props) => {

  const { info, tab, tabId } = props

  const dispatch = useDispatch()
  const validationHorario = useRef<any>()
  const validationHorarioDet = useRef<any>()

  const tabs = useSelector((state: RootState) => state.nomina.registros.horarios.tabs)
  const hoursState = useSelector((state: RootState) => state.nomina.registros.horarios.editData[tabId])
  const hoursStateBackup = useSelector((state: RootState) => state.nomina.registros.horarios.config[tabId])
  const loading = useSelector((state: RootState) => state.nomina.registros.horarios.editData[tabId].loading)
  const loader = useSelector((state: RootState) => state.nomina.registros.horarios.editData[tabId].loader)
  const seleccionadoHora = useSelector((state: RootState) => state.nomina.registros.horarios.editData[tabId].seleccionadoHora)
  const estadoMessage: string = 'Estado: Elija el estado del registro.'
  const sms = validations['es']
  const [reporte, setReporte] = React.useState<null | "Viewer" | "Designer">(null);
  const [arrayErrors, setArrayErrors] = React.useState<Array<string>>([])
  const [showErrorPopup, setShowErrorPopup] = React.useState<boolean>(false)
  const onSetButtonAction = useCallback(
    (tipo: ButtonTypes | undefined) => {
      dispatch(
        setCurrentExecutingAction({
          tabKey: tabId,
          buttonType: tipo,
        }),
      )
    },
    [dispatch, tabId],
  )

  const setToast = useCallback(
    (mensaje: string, tipo: ToastTypes) => {
      dispatch(
        addToast({
          title: 'Nómina - Horarios',
          content: mensaje,
          type: tipo,
        }),
      )
    },
    [dispatch],
  )

  const onLoader = useCallback((showLoader: boolean, mensaje: string) => {
    dispatch(setChangeLoader({
      key: tabId,
      data: {
        show: showLoader,
        mensaje: mensaje
      }
    }))
  }, [dispatch, tabId])

  const modoNuevo = React.useCallback(async (template: number, limpiar: boolean = false) => {
    if (loading === false && !limpiar) {
      return
    }
    onSetButtonAction(ButtonTypes.new)
    onLoader(true, 'Cargando...')
    try {
      const dataEstado = await tipoEstadoService.getTipoEstado('Elija un estado')
      const data = { ...defaultHoursData }
      data.descripcion = ''
      if (dataEstado?.auto && dataEstado?.error === false) {
        data.estado = dataEstado?.auto[1]
      }
      data.dias = days[0]
      data.loading = false
      dispatch(setDatosEdicion({
        key: tabId,
        data: {
          ...data
        }
      }))
      dispatch(setDatosEdicionBackup({
        key: tabId,
        data: {
          ...data
        }
      }))
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
    onLoader(false, '')
    onSetButtonAction(undefined)

  }, [loading, setToast, onLoader, onSetButtonAction, dispatch, tabId])

  const cargarHorario = React.useCallback(async (loader: boolean, hourData) => {
    if (loader === false) {
      return
    }

    dispatch(
      setButtons({
        tabKey: tabId,
        buttons: {
          ...ModuleButtons,
          Imprimir: true,
        }
      }),
    )

    onSetButtonAction(ButtonTypes.edit)
    onLoader(true, 'Cargando Registro...')
    try {
      const dataEstado = await tipoEstadoService.getTipoEstado('Elija un estado')
      const dataHoras = await HorariosTrabajosServices.loadHour(hourData?.codigo ?? 0, false)
      const data = { ...defaultHoursData }
      data.codigo = hourData?.codigo ?? 0
      if (dataHoras?.auto && dataHoras?.error === false) {
        data.horarios = dataHoras?.auto
      }
      if (dataEstado?.auto && dataEstado?.error === false) {
        const provider = dataEstado?.auto.slice(0)
        const index = await getIndexProvider(provider, 'codigo', parseInt(hourData?.estado) ?? 0)
        if (index > -1) {
          data.estado = provider[index]
        } else {
          data.estado = provider[0]
        }
      }
      data.dias = days[0]
      data.descripcion = hourData?.descripcion ?? ''
      data.deshabilitar = false
      data.loading = false
      dispatch(setDatosEdicion({
        key: tabId,
        data: {
          ...data
        }
      }))
      dispatch(setDatosEdicionBackup({
        key: tabId,
        data: {
          ...data
        }
      }))
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
    onLoader(false, '')
    onSetButtonAction(undefined)
  }, [onLoader, onSetButtonAction, dispatch, setToast, tabId])

  const onValidate = useCallback(() => {
    const errors: Array<string> = []
    if (hoursState?.descripcion === "") {
      errors.push('Descripcion: La descripcion es requerida.')
    }
    if (hoursState?.estado === null || hoursState?.estado === undefined || hoursState?.estado?.codigo <= 0) {
      errors.push('Estado: Elija el estado del registro.')
    }
    return errors
  }, [hoursState])

  const onSave = useCallback(async () => {
    let errors: Array<string> = []
    const result = validationHorario.current.instance.validate()
    errors = onValidate()
    consoleService.log(errors)
    if (result.isValid && errors.length === 0) {
      onSetButtonAction(ButtonTypes.save)
      onLoader(true, 'Guardando...')
      consoleService.log(hoursState)
      try {
        const estado = hoursState?.estado?.codigo ?? 0
        const data = await HoursServices.saveHour(hoursState?.codigo ?? 0, hoursState?.descripcion ?? '', estado)
        consoleService.log(data, 'save data')
        if (data?.auto && data?.error === false) {
          setToast(data?.message, ToastTypes.Success)
          dispatch(setNameTab({
            key: tabId,
            codigo: data?.auto,
            nombre: hoursState?.descripcion
          }))
          dispatch(
            setButtons({
              tabKey: tabId,
              buttons: {
                ...ModuleButtons,
                Guardar: false,
                Imprimir: true,
              }
            }),
          )
          dispatch(setDatosEdicion({
            key: tabId,
            data: {
              ...hoursState,
              deshabilitar: false,
              codigo: data?.auto,
            }
          }))
        } else {
          setToast(data?.message, ToastTypes.Danger)
        }
      } catch (error) {
        setToast(error.message, ToastTypes.Danger)
      }
      onLoader(false, '')
      onSetButtonAction(undefined)
    } else {
      setToast(lh.getMessage(MessagesKeys.GlobalErrorSave), ToastTypes.Warning)
      setArrayErrors(errors)
      setShowErrorPopup(true)
    }
  }, [onValidate, setToast, hoursState, onLoader, onSetButtonAction, dispatch, tabId])

  const validHour = useCallback(() => {
    const errors: Array<string> = []
    if (hoursState?.horaEntrada === null || hoursState?.horaEntrada === undefined) {
      errors.push('Hora de Entrada: La hora de entrada es requerida.')
    }
    if (hoursState?.estado === null || hoursState?.estado === undefined || hoursState?.estado?.codigo <= 0) {
      errors.push('Hora de Salida: La hora de salida es requerida.')
    }
    return errors
  }, [hoursState])

  const onAddHour = useCallback(async () => {
    let errors: Array<string> = []
    const result = validationHorarioDet.current.instance.validate()
    errors = validHour()
    consoleService.log(errors)
    if (result.isValid && errors.length === 0) {

      let horaEntrada = ''
      let horae = ''
      let minutose = ''
      let horaSalida = ''
      let horas = ''
      let minutoss = ''
      const he = hoursState?.horaEntrada.getHours()
      const me = hoursState?.horaEntrada.getMinutes()
      if (he >= 10) {
        horae = `${he}`
      } else {
        horae = `0${he}`
      }
      if (me >= 10) {
        minutose = `${me}`
      } else {
        minutose = `0${me}`
      }

      const hs = hoursState?.horaSalida.getHours()
      const ms = hoursState?.horaSalida.getMinutes()
      if (hs >= 10) {
        horas = `${hs}`
      } else {
        horas = `0${hs}`
      }
      if (ms >= 10) {
        minutoss = `${ms}`
      } else {
        minutoss = `0${ms}`
      }
      horaEntrada = `${horae}:${minutose}`
      horaSalida = `${horas}:${minutoss}`
      consoleService.log(hoursState?.dias)
      consoleService.log(horaEntrada)
      consoleService.log(horaSalida)
      onLoader(true, 'Guardando hora...')
      try {
        const addHour: AddHour = {
          infoRegistro: {
            dia: hoursState?.dias?.codigo ?? 0,
            horaEntrada: horaEntrada ?? '',
            horaSalida: horaSalida ?? '',
            horarioTrabajo: hoursState?.codigo ?? 0,
          }
        }

        consoleService.log(addHour)
        const data = await HoursServices.addHour(addHour)
        consoleService.log(data, 'Add hour')
        if (data?.auto && data?.error === false) {
          const provider = hoursState?.horarios.slice(0)
          provider.push({
            codigo: data?.auto ?? 0,
            dia: hoursState?.dias?.codigo ?? 0,
            descripcion: hoursState?.dias?.valor ?? '',
            horaEntrada: horaEntrada ?? '',
            horaSalida: horaSalida ?? '',
            horarioTrabajoCodigo: hoursState?.codigo ?? 0,
          })
          dispatch(setDatosEdicion({
            key: tabId,
            data: {
              ...hoursState,
              horarios: provider ?? []
            }
          }))
        } else {
          setToast(data?.message, ToastTypes.Danger)
        }
      } catch (error) {
        setToast(error.message, ToastTypes.Danger)
      }
      onLoader(false, '')
    } else {
      setToast(lh.getMessage(MessagesKeys.GlobalErrorSave), ToastTypes.Warning)
      setArrayErrors(errors)
      setShowErrorPopup(true)
    }
  }, [hoursState, setToast, validHour, onLoader, dispatch, tabId])

  const onRemoveHour = useCallback(async () => {
    if (seleccionadoHora !== null && seleccionadoHora?.codigo) {
      try {
        const data = await HoursServices.removetHour(seleccionadoHora?.codigo ?? 0)
        consoleService.log(data, 'remove hour')
        if (data?.auto && data?.error === false) {
          const provider = hoursState?.horarios.slice(0)
          const index = await getIndexProvider(provider, 'codigo', seleccionadoHora?.codigo ?? 0)
          if (index > -1) {
            provider.splice(index, 1)
            dispatch(setDatosEdicion({
              key: tabId,
              data: {
                ...hoursState,
                horarios: provider
              }
            }))
          }
        } else {
          setToast(data?.message, ToastTypes.Danger)
        }
      } catch (error) {
        setToast(error.message, ToastTypes.Danger)
      }
    }
  }, [seleccionadoHora, setToast, dispatch, hoursState, tabId])

  const onUndo = useCallback(() => {
    dispatch(setDatosEdicion({
      key: tabId,
      data: {
        ...hoursStateBackup,
      }
    }))
  }, [hoursStateBackup, dispatch, tabId])

  const handleButtonClick = useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.save:
          if (tabs.current === tabId) onSave()
          break
        case ButtonTypes.undo:
          if (tabs.current === tabId) onUndo()
          break
        case ButtonTypes.broom:
          if (tabs.current === tabId) modoNuevo(0, true)
          break
        case ButtonTypes.print:
          if (tabs.current === tabId) setReporte('Viewer')
          break
        case ButtonTypes.print_design:
          if (tabs.current === tabId) setReporte('Designer')
          break
        default:
          break
      }
      dispatch(clearButtonClick(tabId))
    }, [dispatch, tabId, tabs, onSave, onUndo, modoNuevo])


  useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab.globalButtonClick])

  useEffect(() => {
    if (tab.editStatus === StatesEdition.new) {
      modoNuevo(tab?.info?.codigo)
    } else {
      cargarHorario(loading, info.info)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onValueChange = useCallback((data, key: string) => {
    dispatch(setDatosEdicion({
      key: tabId,
      data: {
        ...hoursState,
        [key]: data
      }
    }))
  }, [dispatch, hoursState, tabId])

  const validateAsyncSelect = React.useCallback((datoSeleccion: any, message: string) => {
    if (datoSeleccion.value.codigo > -1) {
      return Promise.resolve()
    } else {
      return Promise.reject(message)
    }
  }, [])

  const validateAsyncSeleccionEstado = React.useCallback((datoSeleccion) => {
    return validateAsyncSelect(datoSeleccion, estadoMessage)
  }, [validateAsyncSelect])

  const onSelectedChanged = useCallback(
    ({ selectedRowsData }) => {
      consoleService.log(selectedRowsData[0], 'seleccionado selectedRowsData[0]')
      if (selectedRowsData[0] && selectedRowsData[0]?.codigo) {
        dispatch(
          setEditSeleccionado({
            key: tabId,
            data: { ...selectedRowsData[0] }
          }),
        )
      }
    },
    [dispatch, tabId],
  )

  const onParseData = useCallback(() => {
    const registros: Array<HorarioDto> = []
    let registrosDet: Array<HorarioDet> = []


    consoleService.log(hoursState)
    if (hoursState?.horarios.length > 0) {
      registrosDet = hoursState?.horarios.map(x => {
        const registroDet: HorarioDet = {
          Day: x?.descripcion ?? '',
          EntryTime: x?.horaEntrada ?? '',
          DepartureTime: x?.horaSalida ?? '',
        }
        return registroDet
      })
    }

    const registro: HorarioDto = {
      Code: hoursState?.codigo ?? 0,
      Description: hoursState?.descripcion ?? '',
      Hours: registrosDet
    }

    registros.push(registro)

    consoleService.log(registros)
    return registros
  }, [hoursState])

  return (
    <>
      <ReportWithLocalDataModal
        show={reporte !== null}
        onClose={() => setReporte(null)}
        data={onParseData()}
        fileName='StaticHour'
        mode={reporte ?? 'Viewer'}
        parameters={{ 'Reporte_Filtro': '' }}
        template='StaticHour'
        key='reportDesigner'
      />
      {showErrorPopup && (
        <VisualizaError
          titulo="Error en Horarios"
          mensaje={'Error al guardar'}
          content={
            <ListErrosValidationForm
              mainMessage={'Revise los siguientes errores:'}
              errorsList={arrayErrors}
              color={'danger'}
            />
          }
          onOk={() => setShowErrorPopup(false)}
        />
      )}
      <div style={{ overflowX: 'hidden' }}>
        <BlockUi
          tag="div"
          loader={LoadingIndicator}
          blocking={loader.show}
          message={loader.mensaje}
        >

          <RowContainer>
            <RowContainer>
              <CustomCol xs="12" md="6">
                <ValidationGroup id={'formHorario'} ref={validationHorario}>
                  {
                    !isMobile && (
                      <RowContainer>
                        <CustomCol lg="6" md="8" sm="12">
                          <ValidationSummary id="summary"></ValidationSummary>
                        </CustomCol>
                      </RowContainer>
                    )
                  }
                  <RowContainer>
                    <CustomCol xs="12" md="6">
                      <Labeled label='Descripción:'>
                        <TextBox value={hoursState?.descripcion ?? ''} onValueChange={(data) => onValueChange(data, 'descripcion')} >
                          <Validator>
                            <RequiredRule message={'Descripción: ' + sms['required']} />
                          </Validator>
                        </TextBox>
                      </Labeled>
                    </CustomCol>
                  </RowContainer>
                  <RowContainer>
                    <CustomCol xs="12" md="6">
                      <Labeled label="Estado:">
                        <TipoEstadoLookUp
                          onChanged={(e) => {
                            if (e !== null || e !== undefined) {
                              consoleService.log(e)
                              onValueChange(e, 'estado')
                            }
                          }}
                          selected={hoursState?.estado ?? null}
                          provider={[]}
                        >
                          <Validator>
                            <RequiredRule message={estadoMessage} />
                            <AsyncRule
                              message={estadoMessage}
                              validationCallback={validateAsyncSeleccionEstado}
                            />
                          </Validator>
                        </TipoEstadoLookUp>
                      </Labeled>
                    </CustomCol>
                  </RowContainer>
                  {
                    isMobile && (
                      <RowContainer>
                        <CustomCol lg="6" md="8" sm="12">
                          <ValidationSummary id="summary"></ValidationSummary>
                        </CustomCol>
                      </RowContainer>
                    )
                  }
                </ValidationGroup>
              </CustomCol>
              <CustomCol xs="12" md="6">
                <ValidationGroup id={'formHorarioDet'} ref={validationHorarioDet}>
                  {
                    !isMobile && (
                      <RowContainer>
                        <CustomCol lg="6" md="8" sm="12">
                          <ValidationSummary id="summary"></ValidationSummary>
                        </CustomCol>
                      </RowContainer>
                    )
                  }
                  <RowContainer>
                    <CustomCol xs="12" md="6">
                      <Labeled label='Día:'>
                        <DiasLookup
                          onChanged={(data) => onValueChange(data, 'dias')}
                          onChangedOptions={(data) => { }}
                          disabled={hoursState?.deshabilitar}
                          selected={hoursState?.dias ?? null}
                        />
                      </Labeled>
                    </CustomCol>
                  </RowContainer>
                  <RowContainer>
                    <CustomCol xs="12" md="3">
                      <Labeled label='Hora de Entrada:'>
                        <DateBox
                          value={hoursState?.horaEntrada ?? null}
                          onValueChange={(data) => onValueChange(data, 'horaEntrada')}
                          type="time"
                          inputAttr={{ 'aria-label': 'Time' }}
                          disabled={hoursState?.deshabilitar}
                        >
                          <Validator>
                            <RequiredRule message={'Hora de Entrada: ' + sms['required']} />
                          </Validator>
                        </DateBox>
                      </Labeled>
                    </CustomCol>
                    <CustomCol xs="12" md="3">
                      <Labeled label='Hora de Salida:'>
                        <DateBox
                          value={hoursState?.horaSalida ?? null}
                          onValueChange={(data) => onValueChange(data, 'horaSalida')}
                          type="time"
                          inputAttr={{ 'aria-label': 'Time' }}
                          disabled={hoursState?.deshabilitar}
                        >
                          <Validator>
                            <RequiredRule message={'Hora de Salida: ' + sms['required']} />
                          </Validator>
                        </DateBox>
                      </Labeled>
                    </CustomCol>
                    <CustomCol xs="12" md="4">
                      <div
                        style={{
                          display: 'flex',
                          gap: '2px',
                          alignItems: 'center',
                          width: '100%',
                        }}
                      >
                        <Button
                          id="btnAdd"
                          className="me-1"
                          icon="plus"
                          stylingMode="contained"
                          type="default"
                          disabled={hoursState?.deshabilitar}
                          onClick={() => onAddHour()}
                        />
                        <Button
                          id="btnRemove"
                          icon="minus"
                          stylingMode="contained"
                          type="danger"
                          disabled={hoursState?.deshabilitar || seleccionadoHora === null}
                          onClick={() => onRemoveHour()}
                        />
                      </div>
                    </CustomCol>
                  </RowContainer>
                  {
                    isMobile && (
                      <RowContainer>
                        <CustomCol lg="6" md="8" sm="12">
                          <ValidationSummary id="summary"></ValidationSummary>
                        </CustomCol>
                      </RowContainer>
                    )
                  }
                </ValidationGroup>
              </CustomCol>
            </RowContainer>
            <ItemsCountIndicator numberOfItems={0} />
            <RowContainer>
              <CustomCol xs="12" md="12">
                <DataGrid
                  keyExpr="codigo"
                  focusedRowKey="codigo"
                  id="tabCertificados"
                  selection={{ mode: 'single' }}
                  disabled={hoursState?.deshabilitar}
                  dataSource={hoursState?.horarios ?? []}
                  showColumnLines={true}
                  onSelectionChanged={onSelectedChanged}
                  showRowLines={true}
                  showBorders={true}
                  columnResizingMode="widget"
                  focusedRowEnabled={true}
                  loadPanel={{
                    enabled: true,
                    height: 90,
                    indicatorSrc: '',
                    shading: false,
                    shadingColor: '',
                    showIndicator: true,
                    showPane: true,
                    text: 'Cargando...',
                    width: 200,
                  }}
                >
                  <Paging defaultPageSize={10} />
                  <Pager
                    visible={true}
                    allowedPageSizes={getAllowedPageSizes(ResultadosBusqueda)}
                    displayMode={'full'}
                    showPageSizeSelector={true}
                    showInfo={true}
                    showNavigationButtons={true}
                  />

                  <Column dataField="descripcion" caption="Día" width="100%" />
                  <Column dataField="horaEntrada" caption="Hora de Entrada" width="100%" />
                  <Column dataField="horaSalida" caption="Hora de Salida" width="100%" />
                </DataGrid>
              </CustomCol>
            </RowContainer>
          </RowContainer>
        </BlockUi>
      </div>
    </>
  )
}

export default React.memo(Nuevo)

export const defaultHoursData: HorarioDatosEdicion = {
  codigo: 0,
  descripcion: '',
  estado: null,
  seleccionadoHora: null,
  horaCodigo: 0,
  horarios: [],
  horaEntrada: new Date(),
  horaSalida: new Date(),
  dias: null,
  loader: {
    show: false,
    mensaje: '',
  },
  loading: false,
  deshabilitar: true,
}