/* eslint-disable no-case-declarations */
/* eslint-disable prefer-const */
import React, { useCallback, } from 'react'
import { CustomButtons, DocumentInfo } from '../../../../../../types/generics'
import { ITotalesPedido, OrdenPedidoDatosEdicion, TabStateOrdenPedido } from '../../../../type/types'
import { DataPedidoExportar, IFileEncodeImage, ItemPedido, PedidoInfo, TOrdenDetalleIngresar, TOrdenPedidoIngresar } from '../../type/types'
import { DateUtils, formatoFechasDatePickers } from '../../../../../../../../helpers/dateUtils'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../../store/store'
import VisualizaError from '../../../../../shared/visualizaError/visualizaError'
import { updateImagenReferencia, updateImagenDecoracion, addDetalle, setDatosEdicion, setEditLoader, setMostrarBusquedaItems, setMuestraError, updateAbono, updateCliente, updateClienteOnly, updateCoordenates, updateDetalles, updateFechaHora, updateGarantia, updateObservaciones, updatePorciones, updateTotales, updateVendedor } from '../../store/editDataReducer'
import LoadingIndicator from '../../../../../../../../views/componentes/loadingindicator/loadingindicator'
import { CAlert, CCol, CLink, CRow } from '@coreui/react-pro'
import ValidationGroup from 'devextreme-react/validation-group'
import RowContainer from '../../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../../views/componentes/colContainer'
import ValidationSummary from 'devextreme-react/validation-summary';
import { isMobile, isMobileOnly } from 'react-device-detect'
import Labeled from '../../../../../../../../views/componentes/labeledInput/labeledInput'
import { StatesEdition } from '../../../../../../types/enums'
import config from '../../../../../../../../config/config'
import { ClientesService } from '../../../../../../services/clientes.service'
import { Cliente } from '../../../../../../types/types'
import { addToast } from '../../../../../../../../store/toasterReducer'
import { ToastTypes } from '../../../../../../../../store/types'
import BuscarClienteLookUp from "../../../../../../components/busquedaCliente/busquedaCliente"
import { changeEditStatus, clearButtonClick, setButtons, setCurrentExecutingAction } from '../../store/tabsReducer'
import { ButtonTypes } from '../../../../../../../../views/componentes/globalMenu/types'
import { NumberBox } from 'devextreme-react/number-box'
import BuscarVendedorLookMemoUp from "../../../../../../../shared/components/buscarVendedorLookMemoUp/buscarVendedorLookMemoUp"
import TextArea from 'devextreme-react/text-area';
import { CustomSheet } from '../../../../../../../../views/componentes/customSheet/customSheet'
import { CustomSheetTextEditor } from '../../../../../../../../views/componentes/customSheet/editors/textEditor'
import { DataSheetColumn } from '../../../../../../../../views/componentes/customSheet/dataSheetColumn'
import { CustomSheetNumberEditor } from '../../../../../../../../views/componentes/customSheet/editors/numberEditor'
import CustomSheetNumberViewer from '../../../../../../../../views/componentes/customSheet/viewers/sheetNumberViewer'
import { SheetTextEditor } from '../../../../../../components/ventas/sheetEditors/sheetTextEditor'
import { RowCommand } from '../../../../../../../../views/componentes/customSheet/genericRow'
import { CellChangedArgs } from '../../../../../../../../views/componentes/customSheet/cellChangedArgs'
import FileUploader from 'devextreme-react/file-uploader';
import ProgressBar from 'devextreme-react/progress-bar';
import Map, { ApiKey } from 'devextreme-react/map';
import DateBox from 'devextreme-react/date-box';
import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box'
import { OrdenPedidoService } from '../../services/ordenPedido.services'
import { utilidades } from '../../../../../../../../helpers/utilidades'
import { Validator, RequiredRule } from 'devextreme-react/validator'
import { SheetPedidosDescripcionItemSelectEditor } from '../../../../components/shared/sheetEditors/sheetPedidosDescripcionItemSelectEditor'
import { DetallePedido, DetallePedidoRedux } from '../../type/detallePedido'
import "./pedido.scss";
import { PedidoHelper } from '../../type/pedidosHelper'
import { v4 as uuidv4 } from 'uuid';
import { UrlHelper } from '../../../../../../../../helpers/urlHelper'
import ScrollView from 'devextreme-react/scroll-view';
import ModificaItem from '../../../../../../components/items/modificarItem'
import { ReportWithLocalDataModal } from '../../../../../../../../views/componentes/xtrareports/ReportWithLocalDataModal';
import { RequestHelperDotNet } from '../../../../../../../../../src/helpers/requestHelperDotNet';
import AgregarDetallePedidoMobile from '../busquedaPedidos/agregarDetalleVentaMobile'
import Button from 'devextreme-react/button'
import MainModalItems from '../../../../../../components/items/busquedaItemsModal/MainModalItems'
import ModalExtras from '../modalExtras/modalExtras';
import ImageView from '../modalExtras/imageView'
import BlockUi from '../../../../../../../../views/componentes/librerias/block-ui'
import Dialog from '../../../../../../../../views/componentes/librerias/bootstrap-dialog'

interface ICPedidoProps extends React.PropsWithChildren {
  // info: DocumentInfo<PedidoInfo>,
  info: any,
  tabId: string,
  tab: TabStateOrdenPedido<PedidoInfo>
}

const allowedFileExtensions = ['.jpg', '.jpeg', '.gif', '.png'];

const initMarkersData = [
  {
    location: [-2.8551045999999918, -78.77869114417955],
    tooltip: {
      text: 'Mi Ubicación actual',
    },
  }
];

const Pedido: React.FunctionComponent<ICPedidoProps> = (props) => {
  console.log('------------', config)

  const urls = UrlHelper.getUrls();

  const { info, tabId, tab } = props;

  const dispatch = useDispatch();

  const dialogRef = React.useRef<any>(null);
  const validationGroupRef = React.useRef<any>();
  const imageReference = React.useRef<any>();
  const imageDecoracion = React.useRef<any>();

  const usuario = useSelector((state: RootState) => { return state.global.session?.usuario });
  const empresa = useSelector((state: RootState) => { return state.global.session?.empresa });
  const localSession = useSelector((state: RootState) => { return state.global.session?.local });
  const puntoVenta = useSelector((state: RootState) => { return state.global.puntoVenta });

  const datosEdicion = useSelector((state: RootState) => { return state.ventas.ordenes.ordenPedido.editData[tabId] });
  const loader = useSelector((state: RootState) => { return state.ventas.ordenes.ordenPedido.editData[tabId].loader });
  const cliente = useSelector((state: RootState) => { return state.ventas.ordenes.ordenPedido.editData[tabId].cliente });
  const detalles = useSelector((state: RootState) => { return state.ventas.ordenes.ordenPedido.editData[tabId].detalles });
  const desdePlantilla = useSelector((state: RootState) => { return state.ventas.ordenes.ordenPedido.editData[tabId].desdePlantilla });
  const fechaSolicitud = useSelector((state: RootState) => { return state.ventas.ordenes.ordenPedido.editData[tabId].fechaSolicitud });
  const fechaEntrega = useSelector((state: RootState) => { return state.ventas.ordenes.ordenPedido.editData[tabId].fechaEntrega });
  const loading = useSelector((state: RootState) => { return state.ventas.ordenes.ordenPedido.editData[tabId].loading });
  const porciones = useSelector((state: RootState) => { return state.ventas.ordenes.ordenPedido.editData[tabId].porciones });
  const numero = useSelector((state: RootState) => { return state.ventas.ordenes.ordenPedido.editData[tabId].numero });
  const vendedor = useSelector((state: RootState) => { return state.ventas.ordenes.ordenPedido.editData[tabId].vendedor });
  const observaciones = useSelector((state: RootState) => { return state.ventas.ordenes.ordenPedido.editData[tabId].observaciones });
  const garantia = useSelector((state: RootState) => { return state.ventas.ordenes.ordenPedido.editData[tabId].garantia });
  const abono = useSelector((state: RootState) => { return state.ventas.ordenes.ordenPedido.editData[tabId].abono });
  const totales = useSelector((state: RootState) => { return state.ventas.ordenes.ordenPedido.editData[tabId].totales });
  const codigo = useSelector((state: RootState) => { return state.ventas.ordenes.ordenPedido.editData[tabId].codigo });
  const mostrarBusquedaItems = useSelector((state: RootState) => { return state.ventas.ordenes.ordenPedido.editData[tabId].mostrarBusquedaItems });

  const [showErrorMessages, setShowErrorMessages] = React.useState<Array<any>>([]);
  const [filaEliminar, setFilaEliminar] = React.useState(-1);
  const [filaClonar, setFilaClonar] = React.useState(-1);
  const [mostrarEdicionItems, setMostrarEdicionItems] = React.useState(false);
  const [codigoBarrasItemEditar, setCodigoBarrasItemEditar] = React.useState('');

  const [isDropZoneActive, setIsDropZoneActive] = React.useState<boolean>(false);
  const [imageSource, setImageSource] = React.useState<any>('');
  const [textVisible, setTextVisible] = React.useState<boolean>(true);
  const [progressVisible, setProgressVisible] = React.useState<boolean>(false);
  const [progressValue, setProgressValue] = React.useState<number>(0);

  const [isDropZoneActiveDecoracion, setIsDropZoneActiveDecoracion] = React.useState<boolean>(false);
  const [imageSourceDecoracion, setImageSourceDecoracion] = React.useState<any>('');
  const [textVisibleDecoracion, setTextVisibleDecoracion] = React.useState<boolean>(true);
  const [progressVisibleDecoracion, setProgressVisibleDecoracion] = React.useState<boolean>(false);
  const [progressValueDecoracion, setProgressValueDecoracion] = React.useState<number>(0);

  const [markersData, setMarkersData] = React.useState<any>(initMarkersData);
  const [reporte, setReporte] = React.useState<null | "Viewer" | "Designer">(null);
  const [enlaceUbicacion, setEnlaceUbicacion] = React.useState<string>("");
  const [latitud, setLatitud] = React.useState<number>(0);
  const [longitud, setLongitud] = React.useState<number>(0);

  const [noEnlace, setNoEnlace] = React.useState<boolean>(false);

  const [verFacturaPdf, setVerFacturaPdf] = React.useState<{ codigo: number, design: boolean }>();
  const [infoReporte, setInfoReporte] = React.useState<any>(null);
  const [dataHeaders, setDataHeaders] = React.useState<any>(null);
  const [agregarDetalleMovil, setAgregarDetalleMovil] = React.useState(false);
  const [showImages, setShowImages] = React.useState<boolean>(false);
  const [filesVisor, setFilesVisor] = React.useState<Array<any> | []>([]);
  const [imageFile, setImageFile] = React.useState<Array<any> | null>(null);


  const sheetCommands = React.useMemo(() => {
    const commads: Array<RowCommand<DetallePedidoRedux>> = [];
    commads.push({
      id: 'Eliminar',
      text: 'Eliminar',
      icon: 'minus',
      onExecute: (rowIndex) => { setFilaEliminar(rowIndex) },
    });
    commads.push({
      id: 'Editar',
      text: 'Editar',
      icon: 'edit',
      onExecute: (rowIndex) => {
        setMostrarEdicionItems(true);
        setCodigoBarrasItemEditar(detalles[rowIndex].codigoBarras);
      },
    });
    // commads.push({
    //   id: 'Duplicar',
    //   text: 'Duplicar',
    //   icon: faCopy,
    //   onExecute: (rowIndex) => {
    //     setFilaClonar(rowIndex)
    //   },
    // });
    return commads;
  }, [detalles]);

  const getSessionData = React.useCallback(async () => {
    const conf = await RequestHelperDotNet.getConfigAsync("POST");
    const head = {
      "authorization": conf.headers['x-authorization'],
      "client_id": conf.headers['client-id'],
      "secret_key": conf.headers['secret-key'],
      "Session-ID": conf.headers['Session-ID'],
      "x-csrf-token": String(conf.headers['authorization']).replaceAll("Bearer", "")
    }
    setDataHeaders(head);
    return head;
  }, []);

  const showEditLoader = React.useCallback((mensaje: string) => {
    dispatch(setEditLoader({
      key: tabId,
      info: {
        mensaje: mensaje,
        show: true
      }
    }));
  }, [tabId, dispatch])


  const hideEditLoader = React.useCallback(() => {
    dispatch(setEditLoader({
      key: tabId,
      info: {
        mensaje: '',
        show: false
      }
    }));
  }, [tabId, dispatch])

  const sheetOnCellsChanged = React.useCallback(async (arrayOfChanges: Array<CellChangedArgs<DetallePedidoRedux>>) => {
    const _detalles = detalles.slice(0);
    for (const change of arrayOfChanges) {
      const detalle = new DetallePedido(); //clase que realiza los calculos para un detalle
      detalle.setDetalle(change.rowData);
      switch (change.col.name) {
        case 'codigoBarras':
          showEditLoader('Buscando item...');
          const item = await OrdenPedidoService.getItemPorCodigoBarrasPedido(detalle.codigoBarras, datosEdicion.cliente?.identificacion ??
            config.rucConsumidorFinal, DateUtils.pickersDateToApiDate(detalle.fecha));
          if (item && item.length > 0) {
            detalle.setItem(item[0], empresa?.precision ?? 4);
            hideEditLoader();
          } else {
            dispatch(addToast({
              content: 'No se encuentra el item con código ' + change.newValue,
              title: 'Agregar item',
              type: ToastTypes.Warning,
              autoHide: 2000
            }))
            continue;
          }
          break;
        case 'cantidad':
          detalle.cantidad = change.newValue;
          detalle.calcular();
          break;
        case 'descripcion':
          if (typeof change.newValue === 'string') {
            if (detalle.codigo === undefined) {
              dispatch(addToast({
                content: 'No se puede pegar un item, debe usar la búsqueda o la columna código.',
                title: 'Agregar item',
                type: ToastTypes.Warning,
                autoHide: 2000
              }))
              continue;
            }
            detalle.descripcion = change.newValue;
          }
          break;
        case '_cantidad':
          detalle.cantidad = parseFloat(change.newValue);
          //detalle.calcular();
          break;
        case '_porcentajeDescuento':
          detalle.porcentajeDescuento = parseFloat(change.newValue);
          //detalle.calcular();
          break;
        case '_precioUnitario':
          detalle.precio = parseFloat(change.newValue);
          //detalle.calcular();
          break;
        default:
          break;
      }
      if (change.rowIndex === -1) { //nueva fila
        _detalles.push(detalle.getDetalle());
      } else {
        _detalles[change.rowIndex] = detalle.getDetalle();
      }
    }
    dispatch(updateDetalles({
      detalles: _detalles,
      key: tabId
    }));
    hideEditLoader();
  }, [empresa, datosEdicion, detalles, dispatch, hideEditLoader, tabId, showEditLoader]);



  const generaDetalleErrores = React.useCallback((item: any) => {
    if (item.length > 0) {
      return <CAlert color='danger'>
        <ul className="errorList">
          {item.map(function (item, id) {
            return <li key={id}>{item['message']}</li>
          })}
        </ul></CAlert>
    } else {
      return <></>
    }
  }, []);

  const cambiarCliente = React.useCallback(async (cliente: Cliente) => {
    //setFormasPago(formasPago);
    //dispatch(updateDetalles({ key: tabId, detalles: detalles }))
    dispatch(updateCliente({
      cliente: cliente,
      key: tabId
    }));
  }, [tabId, dispatch])

  const cambiarClienteConfirm = React.useCallback((clienteNuevo: Cliente, detalles: Array<any>, fecha: string) => {
    if (cliente?.codigo === clienteNuevo.codigo) {
      dispatch(updateClienteOnly({
        cliente: clienteNuevo,
        key: tabId
      }));
      return;
    }

    const clienteLocal = clienteNuevo;
    const fnActualizaOnOk = async (clienteNuevo) => {
      showEditLoader('Actualizando precios...')
      for (let index = 0; index < detalles.length; index++) {
        const detalle = detalles[index];
        const _detalle = new DetallePedido();
        const item = await OrdenPedidoService.getItemPorCodigoBarrasPedido(detalle.codigoBarras, clienteNuevo.identificacion, DateUtils.pickersDateToApiDate(fecha));
        if (item && item.length > 0) {
          _detalle.setDetalle(detalle);
          _detalle.setItem(item[0], empresa?.precision ?? 4);
          detalles[index] = _detalle.getDetalle();
        } else {
          addToast({
            content: `No se pudo recuperar el item ${detalle.descripcion}, el item no existe`,
            title: 'Cambiar precios',
            type: ToastTypes.Danger,
          })
        }
      }

      dispatch(updateCliente({
        cliente: clienteNuevo,
        detalles: detalles,
        key: tabId
      }));
      addToast({
        content: `Precios actualizados`,
        title: 'Cambiar precios',
        type: ToastTypes.Success,
      })
      hideEditLoader();
    };
    dialogRef.current.show({
      title: 'Cambio de cliente',
      body: `¿Desea actualizar el cliente?, esto afectará a los precios de los productos seleccionados actualmente`,
      actions: [
        Dialog.Action(
          <span><u>{'A'}</u>{'ceptar'}</span>,
          () => fnActualizaOnOk(clienteLocal),
          'btn-success',
          'a'
        ),
        Dialog.Action(
          <span><u>C</u>ancelar</span>,
          (dialog) => {
            if (cliente !== null) {
              const tmpCliente = { ...cliente };
              cambiarCliente(tmpCliente)
            }
            dialog.hide()
          },
          'btn-danger',
          'c'
        )
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        // dialog.hide()
      }
    });
    return;
  }, [tabId, cliente, cambiarCliente, hideEditLoader, showEditLoader, dispatch, empresa]);



  const cambiarClienteSeleccionado = React.useCallback(async (clienteORuc: string | Cliente | null) => {
    clienteORuc = clienteORuc ?? config.rucConsumidorFinal
    if (tab.editStatus === StatesEdition.save) {
      const cliente = (typeof clienteORuc === 'string') ? await ClientesService.getCliente(clienteORuc) : clienteORuc;
      dispatch(updateCliente({
        cliente: cliente,
        key: tabId,
      }));
      return;
    }
    //setMostrarEdicionClientes(false);
    if (detalles.length > 0) {
      if (typeof clienteORuc === 'string') {
        showEditLoader('Recuperando cliente...');
      }
      const cliente = (typeof clienteORuc === 'string') ? await ClientesService.getCliente(clienteORuc) : clienteORuc;
      if (cliente == null) {
        dispatch(addToast({ content: 'No se puede recuperar el cliente ' + clienteORuc, type: ToastTypes.Danger }))
        return;
      }
      if (detalles.length > 0 && !desdePlantilla) {
        cambiarClienteConfirm(cliente, detalles.slice(0), fechaSolicitud)
      } else {
        cambiarCliente(cliente);
      }
    } else {
      // if (typeof clienteORuc === 'string' && clienteORuc !== config.rucConsumidorFinal) {
      // }
      const cliente = (typeof clienteORuc === 'string') ? await ClientesService.getCliente(clienteORuc) : clienteORuc;
      if (cliente) {
        dispatch(updateCliente({
          cliente: cliente,
          key: tabId,
        }));
      }
      //setFormasPago(formasPago);
      // if (typeof clienteORuc === 'string' && clienteORuc !== config.rucConsumidorFinal) {
      //   //setLocalLoader({ show: false, mensaje: 'Recuperando cliente...' })
      // }
    }

  }, [detalles, fechaSolicitud, desdePlantilla, cambiarClienteConfirm, cambiarCliente, tab, tabId, showEditLoader, dispatch]);

  const cambiarVendedorSeleccionado = React.useCallback((data) => {
    dispatch(updateVendedor({ key: tabId, vendedor: data }));
  }, [tabId, dispatch]);

  const verificaFechaEntrega = React.useCallback((dateApi: string) => {
    let dateEntrega: string = DateUtils.dateToString(new Date(), "dd/MM/yyyy HH:mm");
    if (dateApi) {
      const fechaHora = dateApi.split(" ");
      if (fechaHora.length > 0) {
        const fecha = fechaHora[0];
        const hora = fechaHora[1];
        const horaSplit = hora.split(":");
        dateEntrega = DateUtils.format(fecha, "dd/MM/yyyy", "yyyy-MM-dd") + " " + horaSplit[0] + ":" + horaSplit[1];
      }
    }
    return dateEntrega;
  }, [])


  const parseData = React.useCallback(async (infoPedidoExportar: any) => {

    const pedido = await OrdenPedidoService.getOrden(infoPedidoExportar?.codigo);
    const detalles = await OrdenPedidoService.getOrdenDetalle(infoPedidoExportar?.codigo);
    const cliente = await ClientesService.getCliente(pedido?.clienteIdentificacion);

    let vendedor = {
      codigo: 0,
      usuarioEmail: "",
      usuarioIdentificacion: "",
      completo: ""
    }
    if (pedido?.vendedorCodigo !== 0) {
      const vendedorApi = await OrdenPedidoService.getVendedorByCode(pedido?.vendedorCodigo);
      if (vendedorApi) {
        vendedor = {
          codigo: vendedorApi?.vendedorCodigo ?? 0,
          usuarioEmail: vendedorApi?.usuarioEmail ?? "",
          usuarioIdentificacion: vendedorApi?.usuarioIdentificacion ?? "",
          completo: vendedorApi?.completo ?? ""
        }
      }
    }

    const itemDetalle: any = [];
    detalles.map(det => {
      itemDetalle.push({
        ItemCode: Number(det.codigo) ?? 0,
        Barcode: String(det.codigoBarras) ?? 0,
        ItemDescription: String(det.descripcion) ?? 0,
        Quantity: Number(det.detalleCantidad) ?? 0,
        porcentajeDescuento: Number(det.detalleDescuento) ?? 0,
        UnitPriceIva: Number(det.detallePrecio) ?? 0,
        Subtotal: 0,
        Cost: Number(det.detalleValor) ?? 0,
      })
    });

    const saldo: number = (pedido?.total - pedido?.abono);

    let imgRef: Array<any> = [];
    let imgDec: Array<any> = [];
    if (pedido.imagenAlternativa && infoPedidoExportar.imagenAlternativa !== "") {

      if (pedido.imagenAlternativa.includes(",")) {
        const imgToArray = pedido.imagenAlternativa.split(',');
        for (const imgIte of imgToArray) {
          imgDec.push({
            source: imgIte,
            name: imgIte
          });
        }
      } else {
        imgDec = [{
          source: pedido.imagenAlternativa,
          filename: pedido.imagenAlternativa
        }];
      }

    }

    if (pedido.imagenReferencia && pedido.imagenReferencia !== "") {

      if (pedido.imagenReferencia.includes(",")) {
        const imgToArray = pedido.imagenReferencia.split(',');
        for (const imgIte of imgToArray) {
          imgRef.push({
            source: imgIte,
            name: imgIte
          });
        }
      } else {
        imgRef = [{
          source: pedido.imagenReferencia,
          filename: pedido.imagenReferencia
        }];
      }

    }

    let dataExport: DataPedidoExportar;
    dataExport = {
      Code: pedido?.codigo ?? 0,
      Number: pedido?.numero ?? "",
      ImageReference: imgRef ?? [],
      ImageDecoration: imgDec ?? [],
      Portion: pedido?.porciones ?? 0,
      Observation: pedido?.observaciones ?? "",
      Guarantee: pedido?.garantia ?? 0,
      Balance: saldo ?? 0,
      Total: pedido?.total ?? 0,
      Payment: pedido?.abono ?? 0,
      DateDelivery: pedido?.fechaHoraEntrega ?? "",
      DateOrder: pedido?.fecha ?? "",
      Customer: {
        Code: cliente?.codigo ?? 0,
        Address: cliente?.direccion ?? "",
        Email: cliente?.email ?? "",
        Identification: cliente?.identificacion ?? "",
        Phone: cliente?.telefono ?? "",
        Ciudad: cliente?.ciudad ?? "",
        Names: cliente?.nombres ?? ""
      },
      Seller: {
        Code: vendedor?.codigo ?? 0,
        Email: vendedor?.usuarioEmail ?? "",
        Identificacion: vendedor?.usuarioIdentificacion ?? "",
        Names: vendedor?.completo ?? ""
      },
      Detall: itemDetalle
    }


    setInfoReporte(dataExport);
  }, []);



  const cargarPedido = React.useCallback(async (codigo: number, setInitData: boolean) => {
    if (datosEdicion.imprimir) {
      setVerFacturaPdf({ codigo: datosEdicion.codigo, design: false });
    }
    if (datosEdicion.loading === false) {
      return;
    }
    const data = { ...defaulDatosEdicionOrdenPedido }; //utilidades.unfreeze(defaultDatosEdicionFactura);
    try {
      dispatch(setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: ButtonTypes.find
      }))
      const pedido = await OrdenPedidoService.getOrden(codigo);
      if (pedido) {
        const cliente = await ClientesService.getCliente(pedido?.clienteIdentificacion);

        const detalles = await OrdenPedidoService.getOrdenDetalle(codigo);

        if (pedido?.vendedorCodigo !== 0) {
          const vendedorApi = await OrdenPedidoService.getVendedorByCode(Number(pedido?.vendedorCodigo));

          if (vendedorApi) {
            data.vendedor = {
              vendedorCodigo: parseInt(vendedorApi.vendedorCodigo) ?? 0,
              usuarioCodigo: parseInt(vendedorApi.usuarioCodigo) ?? 0,
              comision: parseInt(vendedorApi.comision) ?? 0,
              vendedorZona: String(vendedorApi.vendedorZona) ?? "",
              usuarioNombres: String(vendedorApi.usuarioNombres) ?? "",
              usuarioApellidos: String(vendedorApi.usuarioApellidos) ?? "",
              completo: String(vendedorApi.completo) ?? "",
              siglas: String(vendedorApi.siglas) ?? "",
              estado: String(vendedorApi.estado) ?? "",
              localCodigo: String(vendedorApi.localCodigo) ?? "",
              usuarioEmail: String(vendedorApi.usuarioEmail) ?? "",
              usuarioIdentificacion: String(vendedorApi.usuarioIdentificacion) ?? "",
              usuarioTelefono: String(vendedorApi.usuarioTelefono) ?? "",
            }
          }
        }

        data.codigo = pedido.codigo;
        data.cliente = cliente;
        data.observaciones = pedido.observaciones;
        data.numero = pedido.numero;
        data.total = Number(pedido.total);
        data.abono = Number(pedido.abono);
        data.porciones = Number(pedido.porciones);
        data.garantia = Number(pedido.garantia);

        if (pedido.latitud && pedido.longitud) {
          setLatitud(pedido.latitud)
          setLongitud(pedido.longitud)
        }
        if (pedido.imagenReferencia) {

          setIsDropZoneActive(false)
          setTextVisible(false)
          setImageSource(pedido.imagenReferencia);
          const imgRef = [{
            id: 1,
            source: pedido.imagenReferencia,
            filename: pedido.imagenReferencia
          }];
          dispatch(updateImagenReferencia({
            imgReferencia: imgRef,
            key: tabId,
          }));
          data.imgReferencia = imgRef;

        }

        if (pedido.imagenAlternativa) {
          if (pedido.imagenAlternativa.includes(",")) {
            const imgToArray = pedido.imagenAlternativa.split(',');
            const imgs: Array<IFileEncodeImage> = [];
            let i: number = 1;
            for (const imgIte of imgToArray) {
              imgs.push({
                id: i,
                source: imgIte,
                filename: imgIte
              });
              i++;
            }

            setFilesVisor(imgs)

            setIsDropZoneActiveDecoracion(false)
            setTextVisibleDecoracion(false)
            setImageSourceDecoracion(imgs[0].filename);

            dispatch(updateImagenDecoracion({
              imgDecoracion: imgs,
              key: tabId,
            }));
            data.imgDecoracion = imgs;

          } else {
            const imgs = [{
              id: 1,
              source: pedido.imagenAlternativa,
              filename: pedido.imagenAlternativa
            }];
            setFilesVisor(imgs)
            dispatch(updateImagenDecoracion({
              imgDecoracion: imgs,
              key: tabId,
            }));
            setIsDropZoneActiveDecoracion(false)
            setTextVisibleDecoracion(false)
            setImageSourceDecoracion(pedido.imagenAlternativa);

            data.imgDecoracion = imgs;

          }
        }

        data.coordenadas = {
          lat: pedido.latitud,
          long: pedido.longitud
        }

        if (pedido.fechaHoraEntrega) {
          data.fechaEntrega = verificaFechaEntrega(pedido.fechaHoraEntrega);
        }

        const tmpDet2 = PedidoHelper.detallesToDetallesPedido(detalles);
        data.detalles = tmpDet2;


        data.loader = {
          mensaje: '',
          show: false
        }
        data.loading = false;

        dispatch(setDatosEdicion({
          data: data,
          key: tabId
        }));

        dispatch(setButtons({
          tabKey: tabId,
          buttons: {
            ...OrdenPedidoButtons,
            Guardar: true,
            Deshacer: false,
            Descuento: false
          }
        }))

        await parseData(data)
      }

    } catch (error) {
      const dataError: any = error;
      data.tieneError = true;
      data.mensajeError = dataError;
      data.loading = false;
      dispatch(setDatosEdicion({
        data: data,
        key: tabId
      }));
    }
  }, [
    parseData,
    datosEdicion.codigo,
    datosEdicion.imprimir, datosEdicion.loading,
    dispatch, tabId, verificaFechaEntrega
  ]);




  const convertBase64 = React.useCallback(async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }, []);
  // convierte las imagenes tipo File a base64.
  const parseFile = React.useCallback(async (images: Array<IFileEncodeImage>) => {

    const filesGenerated: Array<IFileEncodeImage> = [];
    if (images.length > 0) {
      for (let i = 0; i < images.length; i++) {

        let fileCoded: any = "";

        if (typeof (images[i].source) === 'object') {
          await convertBase64(images[i].source).then(data => {
            fileCoded = data;
          }).catch(data => {
            fileCoded = data;
          });
        } else {
          fileCoded = images[i].source
        }

        const itemDetall: IFileEncodeImage = {
          source: images[i].id === 0 ? fileCoded : "",
          filename: images[i].filename,
          id: images[i].id
        }
        filesGenerated.push(itemDetall);

      }
    }
    return filesGenerated;
  }, [convertBase64])


  const modoNuevo = React.useCallback(async (template: number, limpiar: boolean = false) => {
    if (loading === false && !limpiar) {
      return;
    }
    sessionStorage.removeItem('autorizacionModulo');

    const data = { ...defaulDatosEdicionOrdenPedido };
    dispatch(setButtons({
      tabKey: tabId,
      buttons: {
        ...OrdenPedidoButtons,
        Editar: false,
        Imprimir: false,
        Exportar: false,
        Autorizar: false,
        Enviar: false,
        Guardar: true,
      }
    }))
    try {
      const fecha = DateUtils.getCurrentDateAsString(formatoFechasDatePickers);//  moment().format('yyyy-MM-DD');

      if (template > 0) {
        const pedido = await OrdenPedidoService.getOrden(template);

        if (pedido) {

          if (pedido?.vendedorCodigo !== 0) {
            const vendedorApi = await OrdenPedidoService.getVendedorByCode(Number(pedido?.vendedorCodigo));

            if (vendedorApi) {
              data.vendedor = {
                vendedorCodigo: parseInt(vendedorApi.vendedorCodigo) ?? 0,
                usuarioCodigo: parseInt(vendedorApi.usuarioCodigo) ?? 0,
                comision: parseInt(vendedorApi.comision) ?? 0,
                vendedorZona: String(vendedorApi.vendedorZona) ?? "",
                usuarioNombres: String(vendedorApi.usuarioNombres) ?? "",
                usuarioApellidos: String(vendedorApi.usuarioApellidos) ?? "",
                completo: String(vendedorApi.completo) ?? "",
                siglas: String(vendedorApi.siglas) ?? "",
                estado: String(vendedorApi.estado) ?? "",
                localCodigo: String(vendedorApi.localCodigo) ?? "",
                usuarioEmail: String(vendedorApi.usuarioEmail) ?? "",
                usuarioIdentificacion: String(vendedorApi.usuarioIdentificacion) ?? "",
                usuarioTelefono: String(vendedorApi.usuarioTelefono) ?? "",
              }

            }
          }

          const secuencial = await OrdenPedidoService.getSecuencial("PROD");

          const detalles = await OrdenPedidoService.getOrdenDetalle(template);
          const cliente = await ClientesService.getCliente(pedido?.clienteIdentificacion);
          data.desdePlantilla = true;

          data.codigo = 0;
          data.numero = secuencial;
          data.total = Number(pedido.total);
          data.abono = Number(pedido.abono);
          data.porciones = Number(pedido.porciones);
          data.garantia = Number(pedido.garantia);

          if (pedido.latitud && pedido.longitud) {
            setLatitud(pedido.latitud)
            setLongitud(pedido.longitud)
          }
          if (pedido.imagenReferencia) {
            setIsDropZoneActive(false)
            setTextVisible(false)
            setImageSource(pedido.imagenReferencia);
          }

          if (pedido.imagenAlternativa) {
            setIsDropZoneActiveDecoracion(false)
            setTextVisibleDecoracion(false)
            setImageSourceDecoracion(pedido.imagenAlternativa);
          }

          data.coordenadas = {
            lat: pedido.latitud,
            long: pedido.longitud
          }

          if (pedido.fechaHoraEntrega) {
            data.fechaEntrega = verificaFechaEntrega(pedido.fechaHoraEntrega);
          }

          const tmpDet2 = PedidoHelper.detallesToDetallesPedido(detalles);
          data.detalles = tmpDet2;

          data.cliente = cliente;

          data.observaciones = pedido.observaciones;
          data.fechaSolicitud = DateUtils.getCurrentDateAsString();

        }
      } else {
        const secuencial = await OrdenPedidoService.getSecuencial("PROD");
        const cliente = await ClientesService.getCliente(config.rucConsumidorFinal);
        data.cliente = cliente;
        data.detalles = [];
        data.observaciones = '';
        data.desdePlantilla = false;
        data.numero = secuencial
        data.fechaSolicitud = fechaSolicitud;
      }

      data.loading = false;
      data.loader = {
        mensaje: '',
        show: false
      }

    } catch (error) {
      data.loading = false;
      data.tieneError = true;
      data.mensajeError = typeof error == 'string' ? error : JSON.stringify(error);
      data.loader = {
        mensaje: '',
        show: false
      }
      dispatch(addToast({
        content: data.mensajeError,
        type: ToastTypes.Danger
      }))
    }
    dispatch(setDatosEdicion({
      data: data,
      key: tabId
    }));

  }, [verificaFechaEntrega, loading, tabId, dispatch, fechaSolicitud]);

  const setToast = React.useCallback((texto, type, tittle: string = "Acatha") => {
    let tipo: ToastTypes = ToastTypes.Info;
    if (type === 'warning') {
      tipo = ToastTypes.Warning;
    } else if (type === 'success') {
      tipo = ToastTypes.Success;
    } if (type === 'info') {
      tipo = ToastTypes.Info;
    } if (type === 'danger') {
      tipo = ToastTypes.Danger;
    }
    dispatch(addToast({
      id: '',
      autoHide: 5000,
      title: tittle,
      content: texto,
      fade: true,
      type: tipo
    }));
  }, [dispatch]);

  const validarFormulario = React.useCallback(async () => {

    const smsErrors: Array<any> = [];
    if (datosEdicion?.cliente?.codigo === 0) {
      const sms: string = "Seleccione el Cliente por favor.";
      smsErrors.push({ sms });
    }
    if (datosEdicion?.vendedor === null || datosEdicion?.vendedor?.vendedorCodigo === 0) {
      const sms: string = "Seleccione el Vendedor por favor.";
      smsErrors.push({ sms });
    }
    if (datosEdicion?.porciones === 0) {
      const sms: string = "Ingresa la cantidad de las porciones por favor.";
      smsErrors.push({ sms });
    }
    if (datosEdicion?.observaciones === "") {
      const sms: string = "Ingrese el modelo por favor.";
      smsErrors.push({ sms });
    }
    if (datosEdicion.detalles.length === 0) {
      const sms: string = "Ingrese al menos un item por favor.";
      smsErrors.push({ sms });
    }
    if (datosEdicion?.numero && Number(datosEdicion?.numero) === 0) {
      const sms: string = "El número de orden no es el correcto.";
      smsErrors.push({ sms });
    }
    if (datosEdicion?.fechaEntrega === "") {
      const sms: string = "Ingrese la Fecha de Entrega por favor.";
      smsErrors.push({ sms });
    }
    if (datosEdicion?.imgReferencia === null || datosEdicion?.imgReferencia?.length === 0) {
      const sms: string = "Ingrese la imagen de referencia por favor.";
      smsErrors.push({ sms });
    }
    if (datosEdicion?.garantia < 0) {
      const sms: string = "La garantía ingresada no es correcta.";
      smsErrors.push({ sms });
    }
    if (datosEdicion?.abono < 0) {
      const sms: string = "El Abono ingresado no es correcto.";
      smsErrors.push({ sms });
    }
    if (datosEdicion?.saldo < 0) {
      const sms: string = "El Saldo no es correcto.";
      smsErrors.push({ sms });
    }
    if (datosEdicion?.total < 0) {
      const sms: string = "El Total no es correcto.";
      smsErrors.push({ sms });
    }
    return smsErrors;
  }, [datosEdicion]);

  const onErrorConfirm = React.useCallback((messages: any, tittle?: string) => {
    dialogRef.current.show({
      title: <><strong>{"Ácatha"}</strong></>,
      body:
        <>
          <strong style={{ fontSize: "14px" }}>{tittle}</strong>
          <br />
          <CAlert color='danger'>
            <ul className="errorList"  >
              {messages.map(function (item, id) {
                return <li key={id} >{item?.sms ?? ""}</li>
              })}
            </ul>
          </CAlert>
        </>,
      actions: [
        Dialog.Action(
          <span><u>{"A"}</u>{"ceptar"}</span>,
          (dialog) => {
            dialog.hide();
          },
          'btn-success',
          'n'
        ),
        Dialog.Action(
          <span><u>{"C"}</u>{"ancelar"}</span>,
          (dialog) => {
            dialog.hide();
          },
          'btn-danger',
          'c'
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide();
      }
    });
    return;
  }, []);

  const guardar = React.useCallback(async () => {

    if (usuario == null || empresa == null || localSession == null || puntoVenta == null) {
      return;
    }

    const resValidarFormulario = await validarFormulario();
    if (resValidarFormulario.length > 0) {
      onErrorConfirm(resValidarFormulario, "Antes de continuar revise lo siguiente:");
      return false
    }


    try {

      dispatch(setDatosEdicion({
        data: {
          ...datosEdicion,
          loader: {
            show: true,
            mensaje: 'Guardando Orden...'
          }
        },
        key: tabId
      }));

      dispatch(setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: ButtonTypes.save
      }));



      const ordenRegistrar: TOrdenPedidoIngresar = {
        codigo: datosEdicion.codigo ?? 0,
        numero: datosEdicion.numero === "" ? "0000000" : datosEdicion.numero,
        fecha: DateUtils.pickersDateToApiDate(datosEdicion.fechaSolicitud),
        fechaEntrega: datosEdicion.fechaEntrega,
        vehiculoKilometraje: "",
        vehiculoCodigo: 0,
        clienteCodigo: datosEdicion?.cliente?.codigo ?? 0,
        detalleCono: 0,
        detalleTrabajo: "",
        detalleRecepcion: "",
        detalleObservaciones: datosEdicion?.observaciones ?? "",
        detalleTipo: "PROD",
        detalleOrigen: 0,
        localCodigo: localSession?.codigo ?? -1,
        salonCodigo: 0,
        imagenReferencia: '',
        imagenDecoracion: '',
        garantia: datosEdicion?.garantia ?? 0,
        abono: datosEdicion?.abono ?? 0,
        total: datosEdicion?.totales?.total ?? 0,
        ubicacion: datosEdicion.coordenadas,
        porciones: datosEdicion?.porciones ?? 0,
        vendedorCodigo: datosEdicion?.vendedor?.vendedorCodigo ?? 0,
        pventa: puntoVenta,
        valorDetalle: datosEdicion.detalles.map(det => {
          const detalle: TOrdenDetalleIngresar = {
            itemCodigo: det.codigo,
            itemBarras: det.codigoBarras.toString(),
            detalleCantidad: det._cantidad,
            itemDescripcion: det.descripcion,
            detalleImpuesto: 0,
            itemUnidadCompraDescripcion: "",
            detallePorcentaje: 0,
            detalleDescuento: det._descuento,
            detallePrecio: det._precioUnitario,
            detalleValor: det._total
          };
          return detalle;
        })
      }
      if (imageFile && imageFile.length > 0) {
        const filesReferene = await parseFile(imageFile);
        if (filesReferene) {
          ordenRegistrar.imgReferencia = filesReferene;
        }
      }
      if (filesVisor && filesVisor.length > 0) {
        const files = await parseFile(filesVisor);
        if (files) {
          ordenRegistrar.imgDecoracion = files;
        }
      }

      const resultado = await OrdenPedidoService.ingresarOrden(ordenRegistrar);
      let sms = "";
      sms = resultado.message;
      let typeToast = 'danger';

      if (resultado['error'] === true) {
        typeToast = 'warning';
        setToast(sms, typeToast, "Guardar");
        dispatch(setDatosEdicion({
          data: {
            ...datosEdicion,
            loader: {
              show: false,
              mensaje: ''
            }
            // imprimir: empresa.imprimeAutomaticamenteAlfacturar ?? false
          },
          key: tabId
        }));
        dispatch(setCurrentExecutingAction({
          tabKey: tabId,
          buttonType: undefined
        }))
        return false;
      } else {
        typeToast = 'success';
        setToast(sms, typeToast, "Guardar")
      }

      dispatch(setDatosEdicion({
        data: {
          ...datosEdicion,
          codigo: parseInt(resultado['auto'].codigo),
          numero: resultado['auto'].numero.toString(),
          // fechaSolicitud: DateUtils.getCurrentDateAsString(DateUtils.apiDateToPickersDate(resultado['auto'].fecha.toString())),
          loader: {
            show: false,
            mensaje: ''
          },
          imprimir: empresa.imprimeAutomaticamenteAlfacturar ?? false
        },
        key: tabId
      }));

      dispatch(changeEditStatus({
        estado: StatesEdition.save,
        tabKey: tabId,
        info: resultado['auto'],
        buttons: {
          ...OrdenPedidoButtons,
          Guardar: true,
          Editar: true,
          Deshacer: false,
          Descuento: false,
        }
      }));


      if (empresa.imprimeAutomaticamenteAlfacturar) {
        await parseData(resultado['auto']);
        setReporte('Viewer');
      }

      dispatch(setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: undefined
      }))

    } catch (error) {
      console.error(error);
      const err: any = error;
      let message = "";
      if (err && err['error'] === true) {
        message = err['message'];
      } else {
        message = 'Error al guardar la orden ' + (typeof err === 'string' ? err : JSON.stringify(err));
      }
      dispatch(addToast({
        id: '',
        autoHide: 5000,
        content: message,
        fade: true,
        title: 'Guardar',
        type: ToastTypes.Danger
      }));
      dispatch(setDatosEdicion({
        data: {
          ...datosEdicion,
          loader: {
            show: false,
            mensaje: ''
          }
        },
        key: tabId
      }));
      dispatch(setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: undefined
      }))
      return;
    }

  }, [parseFile, filesVisor, puntoVenta, onErrorConfirm, validarFormulario, localSession, parseData, imageFile, datosEdicion, tabId, dispatch, empresa, setToast, usuario]);

  const handlePrint = React.useCallback((design: boolean) => {
    setVerFacturaPdf({ codigo: codigo, design: design });
  }, [codigo]);

  const modoEdicion = React.useCallback(() => {
    console.log("modoEdicion")
  }, []);


  const handleButtonClick = React.useCallback((button: ButtonTypes) => {
    switch (button) {
      case ButtonTypes.save: {
        guardar();
        break;
      }
      case ButtonTypes.print:
        setReporte("Viewer")
        handlePrint(false);
        break;
      case ButtonTypes.print_design:
        setReporte("Designer")
        handlePrint(true);
        break;
      case ButtonTypes.broom:
        modoNuevo(0, true);
        break;
      case ButtonTypes.edit:
        modoEdicion();
        break;
      default:
        break;
    }
    dispatch(clearButtonClick(tabId))
  }, [dispatch, modoNuevo, guardar, tabId, handlePrint, modoEdicion])


  const onDropZoneEnter = React.useCallback((e) => {
    if (e.dropZoneElement.id === 'dropzone-external') {
      setIsDropZoneActive(true);
    }
  }, [])

  const onDropZoneLeave = React.useCallback((e) => {
    if (e.dropZoneElement.id === 'dropzone-external') {
      setIsDropZoneActive(false)
    }
  }, [])


  const onUploaded = React.useCallback((e) => {

    const { file } = e;
    setTextVisible(false)
    setProgressVisible(false)
    setProgressValue(0)

  }, [])

  const onSelectedFilesChanged = React.useCallback(async (e) => {
    if (e.value && e.value.length > 0) {

      const itemImage: Array<IFileEncodeImage> = [];

      for (const imgIte of e.value) {

        let fileCoded: any = "";

        await convertBase64(imgIte).then(data => {
          fileCoded = data;
        }).catch(data => {
          fileCoded = data;
        });

        const ext: string = imgIte['name'].split('.').pop() ?? "";

        const addItem: IFileEncodeImage = {
          id: 0,
          source: fileCoded,
          filename: uuidv4() + "." + ext
        }
        itemImage.push(addItem)
      }

      setIsDropZoneActive(false)
      setImageSource(itemImage[0].source)
      setImageFile(itemImage)

      dispatch(updateImagenReferencia({
        imgReferencia: itemImage,
        key: tabId,
      }));

    }

  }, [convertBase64, dispatch, tabId])


  const onCleanSelectedFilesChangedDec = React.useCallback(() => {
    setIsDropZoneActiveDecoracion(true)
    setTextVisibleDecoracion(true)
    setImageSourceDecoracion(null)
    setFilesVisor([])
    setShowImages(false)
    imageDecoracion.current.instance.reset();
  }, []);

  const onSelectedFilesChangedDec = React.useCallback(async (e) => {
    if (e.value && e.value.length > 0) {

      if (e.value.length > 0) {
        const itemImage: Array<IFileEncodeImage> = [];
        if (filesVisor.length > 0) {
          for (const item of filesVisor) {
            itemImage.push({
              id: item.id,
              source: item.source,
              filename: item.filename
            });
          }
        }
        for (const item of e.value) {

          let fileCoded: any = "";

          await convertBase64(item).then(data => {
            fileCoded = data;
          }).catch(data => {
            fileCoded = data;
          });

          const ext: string = item['name'].split('.').pop() ?? "";

          const addItem: IFileEncodeImage = {
            id: 0,
            source: fileCoded,
            filename: uuidv4() + "." + ext
          }
          itemImage.push(addItem)
        }

        setFilesVisor(itemImage)

        setIsDropZoneActiveDecoracion(false)
        setImageSourceDecoracion(itemImage[0].source)

        dispatch(updateImagenDecoracion({
          imgDecoracion: itemImage,
          key: tabId,
        }));

      }

    }
  }, [dispatch, tabId, filesVisor, convertBase64])

  const onDropZoneEnterDecoracion = React.useCallback((e) => {
    if (e.dropZoneElement.id === 'dropzone-external-dec') {
      setIsDropZoneActiveDecoracion(true);
    }
  }, [])

  const onDropZoneLeaveDecoracion = React.useCallback((e) => {
    if (e.dropZoneElement.id === 'dropzone-external-dec') {
      setIsDropZoneActiveDecoracion(false)
    }
  }, [])


  const onUploadedDecoracion = React.useCallback((e) => {
    const { file } = e;

    setTextVisibleDecoracion(false)
    setProgressVisibleDecoracion(false)
    setProgressValueDecoracion(0)

  }, [])

  const drawMap = React.useCallback(() => {
    setMarkersData([
      {
        location: [latitud, longitud],
        tooltip: {
          text: 'Mi Ubicación actual',
        },
      }
    ]);
    dispatch(updateCoordenates({
      coordenadas: {
        lat: latitud ?? null,
        long: longitud ?? null
      },
      key: tabId
    }));
  }, [latitud, longitud, dispatch, tabId]);

  const cleanCoord = React.useCallback(() => {
    setEnlaceUbicacion("")
    setLatitud(0);
    setLongitud(0);
    dispatch(updateCoordenates({
      coordenadas: {
        lat: 0,
        long: 0
      },
      key: tabId
    }));
  }, [dispatch, tabId]);

  const hiddeInputsCoord = React.useCallback(() => {
    setNoEnlace(false);
    cleanCoord();
  }, [cleanCoord]);

  const showInputsCoord = React.useCallback(() => {
    setNoEnlace(true);
    cleanCoord();
  }, [cleanCoord]);

  const getUrlCoordenadas = React.useCallback((data) => {

    setLatitud(0);
    setLongitud(0);

    showEditLoader('Cargando...');
    setEnlaceUbicacion(data)
    const urlCoordenadas: string = data.toUpperCase();
    let latitud: number = 0;
    let longitud: number = 0;
    if (urlCoordenadas.includes("Q=")) {
      const getCoord = urlCoordenadas.split("Q=");
      if (getCoord.length > 0) {
        const coordValidas = getCoord[1];

        const coord = coordValidas.split("%2C");
        if (coord.length > 0) {
          latitud = Number(coord[0]);
          if (coord[1].includes("&Z")) {
            const limpiaLong = coord[1].split("&Z");
            if (limpiaLong.length > 0) {
              longitud = Number(limpiaLong[0]);
            }
          }
        }

      }

      setLatitud(latitud);
      setLongitud(longitud);



      hideEditLoader();

    } else {
      hideEditLoader();

      addToast({
        content: `Ingrese una la ubicación correcta.`,
        title: 'Verificar Ubicación',
        type: ToastTypes.Warning,
      })
    }
  }, [hideEditLoader, showEditLoader]);

  const calcularTotales = React.useCallback((detalles: Array<DetallePedidoRedux>) => {
    const totalCero: ITotalesPedido = {
      saldo: 0,
      total: 0
    };
    const totales = detalles.reduce((sum, next) => {
      sum.total += next._total;
      return sum;
    }, totalCero);

    const saldo = totales.total - abono;

    totales.saldo = saldo;

    dispatch(updateTotales({
      totales: totales,
      key: tabId
    }));

    return totales;

  }, [tabId, dispatch, abono])

  const onModalItemsItemSelected = React.useCallback((item: ItemPedido) => {
    const detalle: DetallePedido = new DetallePedido();
    detalle.setItem(item, empresa?.precision ?? 4);
    detalle.cantidad = 1;
    dispatch(addDetalle({
      key: tabId,
      detalle: detalle.getDetalle()
    }))
    // dispatch(setMostrarBusquedaItems({ key: tabId, mostrarBusquedaItems: false }));
  }, [tabId, empresa, dispatch]);


  const onCloneItemSelected = React.useCallback((index: number) => {
    // const detalle: DetallePedido = new DetallePedido();

    // const _detalles = detalles.slice(0);
    // let copyItem = _detalles[index];
    // detalle.setItem(copyItem, empresa?.precision ?? 4);
    // detalle.cantidad = copyItem.cant;
    // dispatch(addDetalle({
    //   key: tabId,
    //   detalle: detalle.getDetalle()
    // }))
    // dispatch(setMostrarBusquedaItems({ key: tabId, mostrarBusquedaItems: false }));
  }, [tabId, empresa, dispatch, detalles]);


  const getFilterString = React.useCallback((filter: any) => {
    const filterText = "";
    // if (filter.documento) {
    //   filterText = `Número: ${filter.documento}`;
    // } else {
    //   filterText = `Fechas: ${filter.fechaInicio} - ${filter.fechaFin}`;
    //   if (filter.cliente) {
    //     filterText = `${filterText} / Cliente:${filter.cliente.nombres}`;
    //   }
    //   if (filter.establecimiento) {
    //     filterText = `${filterText} / Establecimiento:${filter.establecimiento}`;
    //   }
    //   if (filter.puntoEmision) {
    //     filterText = `${filterText} / Punto Emision:${filter.puntoEmision}`;
    //   }
    //   if (filter.estado) {
    //     filterText = `${filterText} / Estado:${filter.estado.descripcion}`;
    //   }
    //   if (filter.formaPago) {
    //     filterText = `${filterText} / Forma Pago:${filter.formaPago.descripcion}`;
    //   }
    // }
    return filterText;
  }, [])


  React.useEffect(() => {
    getSessionData();
  }, []);

  React.useEffect(() => {
    if (tab.editStatus === StatesEdition.new) {
      modoNuevo(tab.info?.codigo ?? 0);
    } else {
      cargarPedido(info.codigo, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
  }, [handleButtonClick, tab.globalButtonClick]);

  React.useEffect(() => {
    if (latitud !== null && longitud !== null) {
      drawMap()
    }
  }, [latitud, longitud, drawMap]);

  React.useEffect(() => {
    if (detalles && detalles.length >= 0) {
      calcularTotales(detalles);
    }
  }, [loader, detalles, calcularTotales])

  React.useEffect(() => {
    const eliminarDetalle = (indice: number, detalles: Array<DetallePedidoRedux>) => {
      detalles.splice(indice, 1);
      dispatch(updateDetalles({
        detalles: detalles,
        key: tabId
      }));
      setFilaEliminar(-1);
    };

    const eliminarDetalleConfirm = (indice: number) => {
      const detalle = detalles[indice];
      dialogRef.current.show({
        title: 'Eliminar detalle',
        body: `¿Desea eliminar el item ${detalle.descripcion}?`,
        actions: [
          Dialog.Action(
            <span><u>{"E"}</u>{"liminar"}</span>,
            () => {
              eliminarDetalle(indice, detalles.slice(0));
            },
            'btn-success',
            'e'
          ),
          Dialog.Action(
            <span><u>{"C"}</u>{"ancelar"}</span>,
            (dialog) => {
              setFilaEliminar(-1);
              dialog.hide()
            },
            'btn-danger',
            'c'
          )
        ],
        bsSize: 'small',
        onHide: (dialog) => {
          dialog.hide();
          setFilaEliminar(-1);
        }
      });
      return;
    };
    if (filaEliminar >= 0) {
      eliminarDetalleConfirm(filaEliminar);
    }
  }, [filaEliminar, detalles, tabId, dispatch])

  React.useEffect(() => {
    if (filaClonar >= 0 && detalles.length > 0) {
      onCloneItemSelected(filaClonar);
    }
  }, [filaClonar, detalles])

  const agregarDetallePedidoMobile = React.useCallback((detalle: DetallePedido) => {
    const _detalles = detalles.slice(0);
    _detalles.push(detalle.getDetalle());
    dispatch(updateDetalles({
      key: tabId,
      detalles: _detalles
    }))
    setAgregarDetalleMovil(false);
  }, [detalles, tabId, dispatch])

  //#region productos


  const onCrearProductoButtonClick = React.useCallback(() => {
    setCodigoBarrasItemEditar('');
    setMostrarEdicionItems(true);
  }, [])

  const onMasOpcionesButtonClick = React.useCallback(() => {
    dispatch(setMostrarBusquedaItems({ key: tabId, mostrarBusquedaItems: true }));
  }, [tabId, dispatch])

  //#endregion

  const ocultarBusquedaItems = React.useCallback(() => {
    dispatch(setMostrarBusquedaItems({ key: tabId, mostrarBusquedaItems: false }));
  }, [tabId, dispatch]);


  const reloadLocation = React.useCallback(async (lat, long) => {
    setLatitud(0);
    setLongitud(0)
    setTimeout(() => {
      setLatitud(lat);
      setLongitud(long)
    }, 500);
  }, []);

  return (
    <>
      <div style={{ padding: '10px', overflowX: 'hidden', paddingBottom: "150px" }} id="mantenimientoPedido"  >
        <Dialog ref={dialogRef} />

        {datosEdicion.tieneError &&
          <VisualizaError titulo='Error en Pedido' mensaje={datosEdicion.mensajeError} content={generaDetalleErrores(showErrorMessages)} onOk={() => {
            dispatch(setMuestraError({ key: props.tabId, tieneError: false, mensajeError: '' }))
          }} />
        }

        {showImages &&
          <ModalExtras
            images={filesVisor ?? []}
            onCancel={() => { setShowImages(false) }}
            onOk={(data) => {
              setFilesVisor(data)
              setShowImages(false)
            }}
          />
        }
        {agregarDetalleMovil &&
          <AgregarDetallePedidoMobile
            key='agregarDetalleVentaMovil'
            onOk={agregarDetallePedidoMobile}
            onCancel={() => setAgregarDetalleMovil(false)}
            cliente={datosEdicion.cliente}
            fecha={DateUtils.pickersDateToApiDate(datosEdicion.fechaSolicitud)}
          />
        }
        {mostrarBusquedaItems && (
          <MainModalItems
            transaccion='ventas'
            action='none'
            show={mostrarBusquedaItems}
            cliente={datosEdicion.cliente !== null ? datosEdicion.cliente : config.rucConsumidorFinal}
            onCancel={ocultarBusquedaItems}
            returnItem={onModalItemsItemSelected}
          />
        )}
        {/* {tab.editStatus === StatesEdition.save && verFacturaPdf && */}
        {(reporte !== null && infoReporte !== null) &&
          <ReportWithLocalDataModal
            show={reporte !== null}
            onClose={() => setReporte(null)}
            data={[infoReporte]}
            fileName='StaticOrdenPedido'
            mode={reporte ?? 'Viewer'}
            parameters={{ 'Local': localSession?.nombre ?? "" }}
            template='StaticOrdenPedido'
            key='reportDesigner'
          />
        }

        {mostrarEdicionItems &&
          <ModificaItem
            transaccion={"ventas"}
            show={mostrarEdicionItems}
            codigoBarras={codigoBarrasItemEditar}
            onCancel={() => setMostrarEdicionItems(false)}
            onChanged={async (codigoBarras) => {
              setMostrarEdicionItems(false);
              setCodigoBarrasItemEditar('');
              if (codigoBarrasItemEditar === '') { //nuevo
                const items = await OrdenPedidoService.getItemPorCodigoBarrasPedido(codigoBarras, cliente?.identificacion ?? config.rucConsumidorFinal, DateUtils.pickersDateToApiDate(fechaSolicitud)
                );
                if (items.length > 0) {
                  onModalItemsItemSelected(items[0]);
                }
              }
            }}
          />
        }

        <BlockUi loader={LoadingIndicator} tag="div" blocking={loader.show} message={loader.mensaje}  >

          <fieldset  >

            <ValidationGroup id={`valGroupOrden${props.tabId}`} ref={validationGroupRef}>

              {!isMobileOnly &&
                <RowContainer>
                  <CustomCol>
                    <ValidationSummary />
                  </CustomCol>
                </RowContainer>
              }

              <div>

                <RowContainer>
                  <CustomCol xs='5' md='3' lg='4'>
                    <Labeled
                      label='Nro. Orden #'
                    >
                      <TextBox
                        disabled={datosEdicion.numero !== ""}
                        placeholder="######"
                        value={numero}
                        className="inputPedidos"
                      />
                    </Labeled>
                  </CustomCol>
                </RowContainer>

                <RowContainer>

                  <CustomCol xs='12' md='6' lg='7'>
                    <div>
                      <RowContainer>
                        <CustomCol xs='12'>
                          <Labeled label='Cliente'>
                            <BuscarClienteLookUp
                              disabled={false}
                              selected={cliente}
                              onChanged={cambiarClienteSeleccionado}
                              allowAdd
                              allowClear
                              allowEdit
                            />
                          </Labeled>
                        </CustomCol>
                      </RowContainer>
                      {cliente &&
                        <RowContainer>
                          <CustomCol
                            xs='12'
                          >
                            <Labeled
                              label='Dirección'
                            >
                              <TextBox value={cliente.direccion} readOnly
                                className="inputPedidos"
                              />
                            </Labeled>
                          </CustomCol>
                          <CustomCol
                            xs='4'
                          >
                            <Labeled
                              label='Teléfono'
                            >
                              <TextBox
                                readOnly
                                value={cliente.telefono ?? ''}
                                className="inputPedidos"
                              />
                            </Labeled>
                          </CustomCol>
                          <CustomCol
                            xs='8'
                          >
                            <Labeled
                              label='Correo eléctronico'
                            >
                              <TextBox
                                readOnly
                                value={cliente.email ?? ''}
                                className="inputPedidos"
                              />
                            </Labeled>
                          </CustomCol>
                        </RowContainer>
                      }
                    </div>

                  </CustomCol>

                  <CustomCol xs='12' md='3'>
                    <Labeled
                      label='Porciones'
                    >
                      <NumberBox
                        value={porciones}
                        placeholder=''
                        onValueChanged={data => {
                          dispatch(updatePorciones({
                            porciones: data.value,
                            key: tabId
                          }));
                        }}
                        showClearButton={true}
                        width="100%"
                        onKeyDown={utilidades.filtraTeclasNoNumericas}
                        className="inputPedidos"
                        inputAttr={{
                          id: "porciones"
                        }}
                        onFocusIn={() => {
                          const element = document.getElementById("porciones");
                          if (element !== null) {
                            (document.getElementById('porciones') as HTMLInputElement).select();
                          }
                        }}
                      >
                        <Validator>
                          <RequiredRule message='- El numero de porciones es requerido.' />
                        </Validator>
                      </NumberBox>
                    </Labeled>
                  </CustomCol>


                  <CustomCol xs='12' md='6' lg='7'>
                    <Labeled
                      label='Vendedor'
                    >
                      <BuscarVendedorLookMemoUp
                        vendedor={vendedor}
                        tab={tab}
                        cambiarVendedorSeleccionado={cambiarVendedorSeleccionado}
                        disabled={false}
                        allowAdd
                        allowClear
                        allowEdit
                      />
                    </Labeled>
                  </CustomCol>

                </RowContainer>
                <RowContainer>
                  <CustomCol xs='12' md='10' lg='12'>
                    <Labeled
                      label='Modelo'
                    >
                      <TextArea
                        width='100%'
                        height={isMobile ? 170 : 200}
                        name='modelo'
                        id='modelo'
                        value={observaciones}
                        onValueChanged={(data) => {
                          dispatch(updateObservaciones({
                            observaciones: data.value,
                            key: tabId
                          }));
                        }}
                        showClearButton={true}
                        className={"textAreaPedidos"}
                      >
                        <Validator>
                          <RequiredRule message='- El modelo es requerido.' />
                        </Validator>
                      </TextArea>
                    </Labeled>
                  </CustomCol>
                </RowContainer>

                < RowContainer >
                  <CCol xs='12' sm='12' md='12'>
                    <Labeled
                      label='Materiales'
                    >
                      <ScrollView
                        width='100%'
                        height='100%'
                      >
                        <RowContainer>
                          <CCol>
                            <div className='buttonsContainer' >
                              {isMobileOnly &&
                                <>
                                  <Button
                                    text='Agregar Detalle'
                                    icon='add'
                                    stylingMode='contained'
                                    type='default'
                                    onClick={() => {
                                      setAgregarDetalleMovil(true);
                                    }}
                                  />
                                </>
                              }
                              <Button className='mb-1'
                                style={buttonsProductoStyle}
                                text='Crear Producto / Servicio'
                                icon='file'
                                stylingMode='contained'
                                type='default'
                                onClick={onCrearProductoButtonClick}
                                elementAttr={{
                                  id: 'btnCreateItem'
                                }}
                              />
                            </div>
                          </CCol>
                        </RowContainer>

                        <div className='detallesTableContainer overflow-auto' onKeyDown={(e) => { }} style={{ width: window.innerWidth > 900 ? '100%' : `${window.innerWidth - 20}px`, maxHeight: `${window.innerHeight / 2}px` }}  >
                          <CustomSheet
                            id='sheetDetalle'
                            blankRows={2}
                            striped
                            columns={sheetColumns}
                            data={detalles}
                            editable={tab.editStatus !== StatesEdition.save}
                            initializeNewRow={(col, value) => {
                              const detalle: DetallePedido = new DetallePedido();
                              if (col.name === 'descripcion') {
                                const item = value as ItemPedido;
                                detalle.setItem(item, empresa?.precision ?? 4);
                              } else {
                                detalle[col.name] = value;
                              }

                              return detalle.getDetalle();
                            }}
                            onCellChanged={sheetOnCellsChanged}
                            showRowNumber
                            rowCommands={sheetCommands}
                          />
                        </div>
                      </ScrollView>
                    </Labeled>
                  </CCol>
                </RowContainer>


                <RowContainer>

                  <CCol xs='12' sm='12' md='6' lg='6' className={"mt-2 mp-2"}>

                    <div className="widget-container flex-box">
                      <span>{"Imagen de Referencia"}</span>
                      <div id="dropzone-external" className={`flex-box ${isDropZoneActive ? 'dx-theme-accent-as-border-color dropzone-active' : 'dx-theme-border-color'}`}>
                        {imageSource &&
                          <ImageView source={imageSource} />
                        }
                        {textVisible
                          && <div id="dropzone-text" className="flex-box">
                            <span>{"Arrastra y suelta el archivo deseado"}</span>
                            <span>{"...o haga clic para buscar un archivo en su lugar."}</span>
                          </div>}
                        <ProgressBar
                          id="upload-progress"
                          min={0}
                          max={100}
                          width="30%"
                          showStatus={false}
                          visible={progressVisible}
                          value={progressValue}
                        />
                      </div>
                      <FileUploader
                        ref={imageReference}
                        accept={'.jpg,.jpeg'}
                        id="file-uploader-referencia"
                        dialogTrigger="#dropzone-external"
                        dropZone="#dropzone-external"
                        multiple={false}
                        allowedFileExtensions={allowedFileExtensions}
                        visible={false}
                        onDropZoneEnter={onDropZoneEnter}
                        onDropZoneLeave={onDropZoneLeave}
                        onUploaded={onUploaded}
                        uploadUrl={urls.documentServerBasePath + '/amfphp/services/SIGNUM/API/v4/compras/documentos/ordenes/uploadFile'}
                        uploadMethod="POST"
                        uploadHeaders={dataHeaders}
                        uploadCustomData={{
                          "ruc": empresa?.ruc ?? "",
                          "usuario": usuario?.codigo ?? 0,
                          "validate": true,
                        }}
                        onValueChanged={onSelectedFilesChanged}
                      />
                    </div>

                  </CCol>

                  <CCol xs='12' sm='12' md='6' lg='6' className={"mt-2 mp-2"}>

                    <div className="widget-container flex-box">
                      <span>
                        {"Imagen de Decoración "}
                        {filesVisor && filesVisor?.length > 0 && (
                          <>
                            <Button
                              text={"(" + (filesVisor?.length ?? 0) + ') Adjuntos'}
                              icon=''
                              type='normal'
                              stylingMode='contained'
                              onClick={() => {
                                setShowImages(true)
                              }}
                            />
                            {" "}
                            <Button
                              icon='close'
                              type='normal'
                              stylingMode='contained'
                              onClick={() => {
                                onCleanSelectedFilesChangedDec()
                              }}
                            />
                          </>
                        )}
                      </span>
                      <div id="dropzone-external-dec" className={`flex-box ${isDropZoneActiveDecoracion ? 'dx-theme-accent-as-border-color dropzone-active' : 'dx-theme-border-color'}`}>
                        {imageSourceDecoracion &&
                          <ImageView source={imageSourceDecoracion} />
                        }
                        {textVisibleDecoracion
                          && <div id="dropzone-text" className="flex-box">
                            <span>{"Arrastra y suelta el archivo deseado"}</span>
                            <span>{"...o haga clic para buscar un archivo en su lugar."}</span>
                          </div>}
                        <ProgressBar
                          id="upload-progress-decoration"
                          min={0}
                          max={100}
                          width="30%"
                          showStatus={false}
                          visible={progressVisibleDecoracion}
                          value={progressValueDecoracion}
                        ></ProgressBar>
                      </div>
                      <FileUploader
                        ref={imageDecoracion}
                        id="file-uploader"
                        dialogTrigger="#dropzone-external-dec"
                        dropZone="#dropzone-external-dec"
                        multiple={true}
                        allowedFileExtensions={allowedFileExtensions}
                        visible={false}
                        accept={'.jpg,.jpeg'}
                        onDropZoneEnter={onDropZoneEnterDecoracion}
                        onDropZoneLeave={onDropZoneLeaveDecoracion}
                        onUploaded={onUploadedDecoracion}
                        uploadUrl={urls.documentServerBasePath + '/amfphp/services/SIGNUM/API/v4/compras/documentos/ordenes/uploadFile'}
                        uploadMethod="POST"
                        uploadHeaders={dataHeaders}
                        uploadCustomData={{
                          "ruc": empresa?.ruc ?? "",
                          "usuario": usuario?.codigo ?? 0,
                          "validate": true,
                        }}
                        onValueChanged={(data) => {
                          onSelectedFilesChangedDec(data)
                        }}
                      />
                    </div>

                  </CCol>

                </RowContainer>

                <RowContainer>
                  <CCol xs='12' sm='12' md='10' className={"mt-2 mp-2"}>
                    <Labeled
                      label='Croquis'
                    >
                      <CRow>
                        {noEnlace === false &&
                          <CCol onClick={() => {
                            showInputsCoord()
                          }}>
                            <CLink>
                              {" No tengo enlace de ubicación. "}
                            </CLink>
                          </CCol>
                        }
                        {noEnlace === true &&
                          <CCol onClick={() => {
                            hiddeInputsCoord()
                          }}>
                            <CLink>
                              {" Ingresar enlace de ubicación. "}
                            </CLink>
                          </CCol>
                        }

                      </CRow>
                      <CRow>
                        <CCol>
                          {noEnlace === false &&
                            <TextBox
                              value={enlaceUbicacion}
                              onValueChanged={(data) => {
                                if (data.value) {
                                  getUrlCoordenadas(String(data.value).trim())
                                } else {
                                  setToast("Ingresa las ubicación por favor", 'warning', "bicación")
                                }
                              }}
                              // showClearButton={true}
                              className="mt-2 mb-2 inputPedidos"
                              inputAttr={{
                                id: "ubicacion"
                              }}
                              onFocusIn={() => {
                                const element = document.getElementById("ubicacion");
                                if (element !== null) {
                                  (document.getElementById('ubicacion') as HTMLInputElement).select();
                                }
                              }}
                            >
                              <TextBoxButton
                                name='verUbicacion'
                                location='after'
                                options={{
                                  icon: 'isnotblank',
                                  text: 'Ver Ubicación',
                                  onClick: () => {
                                    drawMap();
                                  }
                                }}
                              />
                              {/* <TextBoxButton
                                name='initUbicacion'
                                location='after'
                                options={{
                                  icon: 'isnotblank',
                                  onClick: () => {
                                    cleanCoord();
                                  }
                                }}
                              /> */}
                              {enlaceUbicacion !== "" &&
                                <TextBoxButton
                                  name='cleanCoor'
                                  location='after'
                                  options={{
                                    icon: 'trash',
                                    onClick: () => {
                                      cleanCoord();
                                    }
                                  }}
                                />
                              }
                            </TextBox>
                          }
                          {
                            noEnlace === true &&
                            <>
                              <RowContainer>
                                <CCol xs='12' sm='12' md='8' className={"mt-2 mp-2"}>
                                  <Labeled
                                    label='Latitud'
                                  >
                                    <TextBox
                                      value={latitud === 0 ? "" : String(latitud)}
                                      onValueChanged={(data) => {
                                        setLatitud(data.value)
                                      }}
                                      // showClearButton={true}
                                      className="mt-2 mb-2 inputPedidos"
                                      inputAttr={{
                                        id: "latitud"
                                      }}
                                      onFocusIn={() => {
                                        const element = document.getElementById("latitud");
                                        if (element !== null) {
                                          (document.getElementById('latitud') as HTMLInputElement).select();
                                        }
                                      }}
                                    >
                                      <Validator>
                                        <RequiredRule message='- La latitud es requerida.' />
                                      </Validator>
                                    </TextBox>
                                  </Labeled>
                                </CCol>
                              </RowContainer>
                              <RowContainer>
                                <CCol xs='12' sm='12' md='8' className={"mt-2 mp-2"}>
                                  <Labeled
                                    label='Longitud'
                                  >
                                    <TextBox
                                      value={longitud === 0 ? "" : String(longitud)}
                                      onValueChanged={(data) => {
                                        setLongitud(data.value)
                                      }}
                                      // showClearButton={true}
                                      className="mt-2 mb-2 inputPedidos"
                                      inputAttr={{
                                        id: "longitud"
                                      }}
                                      onFocusIn={() => {
                                        const element = document.getElementById("longitud");
                                        if (element !== null) {
                                          (document.getElementById('longitud') as HTMLInputElement).select();
                                        }
                                      }}
                                    >
                                      <Validator>
                                        <RequiredRule message='- La longitud es requerida.' />
                                      </Validator>
                                    </TextBox>
                                  </Labeled>
                                </CCol>
                              </RowContainer>
                              <RowContainer>
                                <CCol xs='12' sm='12' md='8' className={"mt-2 mp-2"}>
                                  <Button
                                    text='Ver Ubicación'
                                    icon='isnotblank'
                                    stylingMode='contained'
                                    type='normal'
                                    onClick={() => {
                                      reloadLocation(latitud, longitud)
                                    }}
                                  />
                                </CCol>
                              </RowContainer>
                            </>
                          }
                        </CCol>
                      </CRow>
                    </Labeled>
                  </CCol>
                </RowContainer>

                <RowContainer>
                  <CCol xs='12' sm='12' md='12' className={"mt-2 mp-2"}>
                    <fieldset>
                      {
                        latitud !== 0 && longitud !== 0 &&
                        <Map
                          defaultZoom={20}
                          height={440}
                          width="100%"
                          controls={true}
                          markers={markersData}
                          provider="google"
                          center={{ lat: latitud, lng: longitud }}
                        >
                          <ApiKey
                            bing="MY_BING_MAPS_KEY"
                            google={config.googleSecretKey ?? ""}
                            googleStatic="MY_GOOGLE_STATIC_MAPS_KEY"
                          />
                        </Map>
                      }
                    </fieldset>
                  </CCol>
                </RowContainer>

                <RowContainer>
                  <CCol xs='12' sm='12' md='6' className={"mt-2 mp-2 d-flex justify-content-start"}>
                    <Labeled
                      label='Fecha y Hora de Entrega'
                    >
                      <DateBox
                        value={DateUtils.strDateToDate(fechaEntrega, "dd/MM/yyyy HH:mm")}
                        type="datetime"
                        displayFormat="dd/MM/yyyy HH:mm"
                        onValueChanged={(data) => {
                          if (data.value) {
                            dispatch(updateFechaHora({
                              fechaEntrega: DateUtils.dateToString(data.value, "dd/MM/yyyy HH:mm"),
                              key: tabId
                            }));
                          }
                        }}
                        className="  inputPedidos"
                      />
                    </Labeled>
                  </CCol>
                </RowContainer>

                <RowContainer>
                  <CCol xs='12' sm='12' md='6' className="d-flex justify-content-start" >
                    <Labeled
                      label='Garantía'
                    >

                      <NumberBox
                        value={garantia}
                        placeholder=''
                        onValueChanged={data => {
                          dispatch(updateGarantia({
                            garantia: data.value,
                            key: tabId
                          }));
                        }}
                        showClearButton={true}
                        width="100%"
                        onKeyDown={utilidades.filtraTeclasNoNumericasAndKeyD}
                        min={0}
                        max={totales?.total ?? 0}
                        className="  inputPedidos"
                        inputAttr={{
                          id: "garantia"
                        }}
                        onFocusIn={() => {
                          const element = document.getElementById("garantia");
                          if (element !== null) {
                            (document.getElementById('garantia') as HTMLInputElement).select();
                          }
                        }}
                      >
                        <Validator>
                          <RequiredRule message='- La garantía es requerida.' />
                        </Validator>
                      </NumberBox>

                    </Labeled>
                  </CCol>
                </RowContainer>
                <RowContainer>
                  <CCol xs='12' sm='12' md='6' className="d-flex justify-content-start"  >
                    <Labeled
                      label='Abono'
                    >
                      <NumberBox
                        value={abono}
                        placeholder=''
                        onValueChanged={data => {
                          dispatch(updateAbono({
                            abono: data.value,
                            key: tabId
                          }));
                        }}
                        showClearButton={true}
                        width="100%"
                        onKeyDown={utilidades.filtraTeclasNoNumericasAndKeyD}
                        min={0}
                        max={totales?.total ?? 0}
                        className="  inputPedidos"
                        inputAttr={{
                          id: "abono"
                        }}
                        onFocusIn={() => {
                          const element = document.getElementById("abono");
                          if (element !== null) {
                            (document.getElementById('abono') as HTMLInputElement).select();
                          }
                        }}
                      >
                        <Validator>
                          <RequiredRule message='- El abono es requerido.' />
                        </Validator>
                      </NumberBox>
                    </Labeled>
                  </CCol>
                </RowContainer>

                {
                  !isMobile && (
                    <RowContainer>
                      <CCol xs='12' sm='12' md='12' className="d-flex justify-content-start mt-2 mb-2" >

                        <CRow>
                          <CCol xs='12' sm='12' md='5' className="me-2" >
                            <strong> <h3> {'TOTAL'} </h3> </strong>
                            <h3> <span> {totales?.total.toFixed(2)} </span></h3>
                          </CCol>
                        </CRow>

                        <CRow>
                          <CCol xs='12' sm='12' md='5' className="ms-2" >
                            <strong> <h3> {'SALDO'} </h3></strong>
                            <h3><span> {totales?.saldo.toFixed(2)} </span></h3>
                          </CCol>
                        </CRow>

                      </CCol>
                    </RowContainer>
                  )
                }

                {isMobile && (
                  <div id="flotantePie" onTouchStart={() => {
                    console.log('touch')
                  }}>
                    <CRow>
                      <CCol sm='12' md="12" className="d-flex justify-content-center m-2  mt-2 mb-2" >

                        <CRow>
                          <CCol xs="12" md="6" >
                            <CRow>
                              <CCol className="d-flex justify-content-start " >
                                <span className='textFooter'>  {'TOTAL '}   </span>
                              </CCol>
                              <CCol className="d-flex justify-content-end " >
                                <span className='textFooter'> {totales?.total.toFixed(2)}  </span>
                              </CCol>
                            </CRow>
                          </CCol>
                          <CCol xs="12" md="6" >
                            <CRow>
                              <CCol className="d-flex justify-content-start " >
                                <span className='textFooter'>  {'SALDO '} </span>
                              </CCol>
                              <CCol className="d-flex justify-content-end " >
                                <span className='textFooter'> {totales?.saldo.toFixed(2)} </span>
                              </CCol>
                            </CRow>
                          </CCol>
                        </CRow>

                      </CCol>
                    </CRow>
                  </div>
                )}
              </div>


              {isMobileOnly &&
                <RowContainer>
                  <CustomCol>
                    <ValidationSummary />
                  </CustomCol>
                </RowContainer>
              }


            </ValidationGroup>


          </fieldset>

        </BlockUi>
      </div>
    </>
  );

}

const sheetColumns: Array<DataSheetColumn> = [
  {
    id: 1,
    colSpan: 1,
    name: 'codigoBarras',
    headerText: 'Código',
    width: '10%',
    order: 1,
    dataEditor: CustomSheetTextEditor,
    getReadOnly: (data) => {
      return data !== null;
    }
  },
  {
    id: 2,
    colSpan: 1,
    name: 'descripcion',
    headerText: 'Descripción',
    width: '20%',
    order: 2,
    getReadOnly: (data) => {
      return false;
    },
    getDataEditor: (data) => {
      return data == null ? SheetPedidosDescripcionItemSelectEditor : SheetTextEditor
    }
  },
  {
    id: 4,
    colSpan: 1,
    name: '_cantidad',
    headerText: 'Cant.',
    width: '60px',
    order: 4,
    getReadOnly: (data) => {
      return data == null;
    },
    dataEditor: CustomSheetNumberEditor,
    valueViewer: CustomSheetNumberViewer
  },
  {
    id: 6,
    colSpan: 1,
    name: '_precioUnitario',
    headerText: 'Valor',
    width: '80px',
    order: 6,
    getReadOnly: (data) => {
      return data == null;
    },
    dataEditor: CustomSheetNumberEditor,
    valueViewer: CustomSheetNumberViewer
  },
  {
    id: 8,
    colSpan: 1,
    name: '_total',
    headerText: 'Total',
    width: '80px',
    order: 8,
    readOnly: true,
    valueViewer: CustomSheetNumberViewer,
    getDisplay: (value, data: DetallePedidoRedux) => data._subtotal.toFixed(2)
  }
];

export const defaulDatosEdicionOrdenPedido: OrdenPedidoDatosEdicion = {
  codigo: 0,
  numero: "",
  cliente: null,
  detalles: [],
  dpvendedores: [],
  vendedor: null,
  fechaSolicitud: DateUtils.getCurrentDateAsString(),
  fechaEntrega: DateUtils.dateToString(new Date(), "dd/MM/yyyy HH:mm"),
  porciones: 6,
  observaciones: "",
  imgDecoracion: null,
  imgReferencia: null,
  coordenadas: {
    lat: 0,
    long: 0
  },
  garantia: 0,
  abono: 0,
  total: 0,
  saldo: 0,
  loader: {
    mensaje: 'Cargando...',
    show: true
  },
  loading: true,
  imprimir: false,
  tieneError: false,
  mensajeError: "",
  mostrarBusquedaItems: false,
  mostrarEdicionItems: false,
  desdePlantilla: false,
  totales: {
    total: 0,
    saldo: 0
  }
}

export const OrdenPedidoButtons: CustomButtons = {
  Nuevo: true,
  Guardar: true,
  Buscar: true,
  Deshacer: true,
  Editar: false,
  Imprimir: true,
  Descuento: true,
  Credito: true,
  Extraer: false,
}

const buttonsProductoStyle = { marginLeft: '2px' };

export default React.memo(Pedido);
