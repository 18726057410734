import React from 'react'
import {
  CCol,
  CRow
} from '@coreui/react-pro'
import { useDispatch, useSelector } from 'react-redux'
import { setRowSelectedAsignados } from "../../store/modalAnticiposReducer"
import {
  Column,
  DataGrid,
  FilterRow,
  HeaderFilter,
  Editing,
  Selection,
  Paging,
} from 'devextreme-react/data-grid';
import { RootState } from '../../../../../../../store/store';
import { utilidades } from '../../../../../../../helpers/utilidades';
import { GlobalLabel } from '../../../../../../../views/componentes/globalLabel/globalLabel';

type TabAsignadosProps = {
  actionDeleteAsignado: () => void
}

const TabAsignados = (props: TabAsignadosProps) => {

  const dispatch = useDispatch()

  const asignadosState = useSelector((state: RootState) => state.contabilidad.modalAnticipos.asignados);

  const [data, setData] = React.useState<Array<any>>([])

  React.useEffect(() => {
    if (asignadosState.length > 0) {
      llenarData(asignadosState)
    } else {
      setData([])
    }
  }, [asignadosState])

  const llenarData = (data) => {
    let item: any = []
    item = data.map(function (key, i) {
      return {
        ...key,
        ORDEN: Number(i),
      }
    })
    setData(item.map(x => utilidades.unfreeze(x)))
  }

  const onSelectionChanged = (rowData) => {
    dispatch(setRowSelectedAsignados({
      index: [rowData['currentSelectedRowKeys'][0]],
      row: rowData['selectedRowsData'][0],
      selectedRow: [rowData['currentSelectedRowKeys'][0]]
    }))
  }

  return (
    <>
      <CRow className="mt-1 mb-1">
        <CCol lg="12" className="d-flex justify-content-start" >
          <GlobalLabel> <strong>{"Seleccionados"}</strong></GlobalLabel>
        </CCol>
      </CRow>
      <CRow className="mt-3">
        <CCol lg="12"  >

          <DataGrid
            selection={{ mode: 'single' }}
            hoverStateEnabled={true}
            dataSource={data}
            showBorders={true}
            showRowLines={true}
            onSelectionChanged={onSelectionChanged}
            allowColumnReordering={true}
            allowColumnResizing={true}
            columnAutoWidth={true}
            keyExpr="ORDEN"
            key="gridAsignados"
          //onRowDblClick={onRowDblClick}
          >
            <FilterRow visible={true} />
            <HeaderFilter visible={true} />
            <Paging enabled={true} />
            <Editing mode='cell' allowUpdating={true} />
            <Selection
              mode="single"
              selectAllMode={"onClick"}
              showCheckBoxesMode={[]}
            />
            <Column visible={false} dataField="ORDEN" caption="Orden" width={90} />
            <Column allowEditing={false} dataField='anticipoNumero' caption='Num.' width='100%' />
            <Column allowEditing={false} dataField='anticipoConcepto' caption='Concepto' width='100%' />
            <Column allowEditing={false} dataField='anticipoCompraVentaValor' caption='Valor' width='100%' />
          </DataGrid>

        </CCol>
      </CRow>
      <CRow className="mt-1 mb-1">
        <CCol lg="12" className="d-flex justify-content-start" >
          <GlobalLabel> <strong>{data.length}</strong></GlobalLabel>
        </CCol>
      </CRow>
    </>
  )
}

export default React.memo(TabAsignados);
