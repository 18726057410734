import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DateUtils } from "../../../../../helpers/dateUtils";
import { FETCH_STATUS } from "../../../../../store/types";
import { AdvancesState, FiltrosAdvancesState } from "../types/types";
import { Seleccionado } from "../../../../bancos/store/types";
import { cuentasService } from "../../../../contabilidad/pages/diarioGeneral/services/cuentas.service";


const initialState: AdvancesState<any> = {
  filter: {
    fecha: DateUtils.dateToString(new Date(), 'dd/MM/yyyy'),
    local: null,
    proveedor: null,
    puntoVenta: null,
    numero: null,
    codigo: null,
    concepto: null,
    valor: null,
    documento: null,
    formasPago: null,
    codigoAnticipo: 0,
    codigoAsociado: 0,
  },
  seleccionado: {
    index: null,
    row: null,
    selectedRow: null,
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  resultados: [],
  resultadosAsiento: [],
  resultadosAsientoDetalle: [],
  tipoPagoAnt: [],
  currentExecute: null,
  currentActiontate: ''
}

export const fetchAdvances = createAsyncThunk<
  Array<any>,
  { pertence: number, asociado: number, nrtipo: number, codigo: number, local: number, hasta: string }
>('contabilidad/diarioGeneral/advances/getAll', async (filter) => {
  try {
    const saldosApi = await cuentasService.getAnticipos(
      filter.pertence,
      filter.asociado,
      filter.nrtipo,
      filter.codigo,
      filter.local,
      filter.hasta
    )
    return saldosApi
  } catch (error) {
    return Promise.reject(error)
  }
})

const advancesSlice = createSlice({
  name: 'anticiposPagar',
  initialState: initialState,
  reducers: {
    setCleanResult(state) {
      state.resultados = []
    },
    setCleanPagoAnt(state) {
      state.tipoPagoAnt = []
    },
    setCleanFields(state) {
      state.filter.fecha = DateUtils.dateToString(new Date(), 'dd/MM/yyyy')
      state.filter.local = null
      state.filter.proveedor = null
      state.filter.numero = null
      state.filter.codigo = null
      state.filter.concepto = ''
      state.filter.valor = ''
      state.filter.documento = ''
      state.filter.formasPago = null
      state.filter.codigoAnticipo = 0
      state.filter.codigoAsociado = 0
      state.tipoPagoAnt = []
    },
    initData(state) {
      state.filter.fecha = DateUtils.dateToString(new Date(), 'dd/MM/yyyy')
      state.filter.numero = null
      state.filter.codigo = null
      state.filter.concepto = ''
      state.filter.valor = ''
      state.filter.documento = ''
      state.filter.formasPago = null
      state.filter.codigoAnticipo = 0
      state.filter.codigoAsociado = 0
      state.tipoPagoAnt = []
    },
    changeFilter(state, action: PayloadAction<FiltrosAdvancesState>) {
      state.filter = action.payload
      state.filter.fecha = action.payload.fecha
      state.filter.local = action.payload.local
      state.filter.proveedor = action.payload.proveedor
      state.filter.numero = action.payload.numero
      state.filter.codigo = action.payload.codigo
      state.filter.concepto = action.payload.concepto
      state.filter.valor = action.payload.valor
      state.filter.documento = action.payload.documento
      state.filter.formasPago = action.payload.formasPago
      state.filter.codigoAnticipo = action.payload.codigoAnticipo
      state.filter.codigoAsociado = action.payload.codigoAsociado
    },
    setResetSeleccion(state) {
      const toAdd = {
        index: null,
        row: null,
        selectedRow: null,
      }
      state.seleccionado = toAdd
    },
    setSeleccionarDato(state, action: PayloadAction<Seleccionado>) {
      const toAdd = {
        index: action.payload.index,
        row: action.payload.row,
        selectedRow: action.payload.selectedRow,
      }
      state.seleccionado = toAdd
    },
    setCurrentAction(state, action: PayloadAction<string>) {
      state.currentActiontate = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAdvances.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchAdvances.fulfilled, (state, { payload }) => {
      state.resultados = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchAdvances.rejected, (state, { payload }) => {
      state.resultados = []
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
  },
})


export const { changeFilter, setCleanResult, initData, setResetSeleccion, setSeleccionarDato, setCleanFields, setCurrentAction, setCleanPagoAnt } =
  advancesSlice.actions

export const advancesReducer = advancesSlice.reducer