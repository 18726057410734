import { DateUtils, formatoFechasApi } from '../../../../helpers/dateUtils'
import { RequestHelper } from '../../../../helpers/requestHelper'
import { SesionService } from '../../../../services/sesion.service'

export const EstadoProgresoServices = { getProgressStatus }

async function getProgressStatus(message: string, code: number): Promise<any> {
  try {
    const data: any = { mensaje: message, tipoCodigo: code }
    const sesion = SesionService.getCurrentSesion()
    const keysToCache: any = {
      ...data,
      dateKey: DateUtils.getCurrentDateAsString(formatoFechasApi),
      empresa: sesion?.empresa?.codigo,
    }
    const cacheKey = 'tipoProgreso' + JSON.stringify(keysToCache)
    const cachedData = sessionStorage.getItem(cacheKey)
    if (cachedData) return { error: false, auto: JSON.parse(cachedData) }
    else {
      const res = await RequestHelper.getAll<any>(
        'subtypes',
        'cboxLoad',
        '',
        data,
      )
      sessionStorage.setItem(cacheKey, JSON.stringify(res?.auto ?? []))
      return res
    }
  } catch (error) {
    return error
  }
}
