import React from 'react'
import Modalform from '../../../views/componentes/modalform'
import { CCard, CCardBody, CCardHeader, CCol, CNav, CNavItem, CNavLink, CRow, CTabContent, CTabPane } from '@coreui/react-pro'
import { LoadPanel, TextBox } from 'devextreme-react'
import { GlobalMenu } from '../../../views/componentes/globalMenu/globalMenu'
import { AccionMenu, ToastTypes } from '../../../store/types'
import { ButtonTypes } from '../../../views/componentes/globalMenu/types'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import RowContainer from '../../../views/componentes/rowContainer/rowContainer'
import DataGrid, { Column, Editing, FilterRow, HeaderFilter, Pager, Paging } from 'devextreme-react/data-grid'
import { getAllowedPageSizes } from '../../../helpers/Helper'
import Labeled from '../../../views/componentes/labeledInput/labeledInput'
import { asignacionesService } from '../../contabilidad/pages/asignaciones/services/asignaciones.service'
import { addToast } from '../../../store/toasterReducer'
import { EAplicationsAcatha } from '../../../store/enumsAplication'


interface IModalConceptoRetencion extends React.PropsWithChildren {
  onChanged: (dataLocal: any) => void
  onCancel: () => void
  show: boolean
  codModulo: number
}

const menuDefecto = [
  {
    icon: 'fal fa-arrow-left',
    id: 'Elegir',
    text: 'Elegir',
    type: ButtonTypes.sendMail,
  }
]

const tabs = ['Fuente', 'I.V.A.']


export const ModalConceptoRetencion: React.FC<IModalConceptoRetencion> = (props) => {
  const { show, onCancel, onChanged, codModulo } = props
  const dispatch = useDispatch()
  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(
      addToast({
        title: 'Conceptos de Retencion',
        content: mensaje,
        type: tipo,
      }),
    )
  }, [dispatch])
  const botones = menuDefecto.map(acc => {
    const boton: AccionMenu = {
      nombre: acc.id,
      icon: acc.icon,//as any,
      actionType: acc.type,
      main: false,
      modal: false,
      shortcut: null
    }
    return boton;
  })
  const loader = useSelector((state: RootState) => state.global.loader)
  const [acciones,] = React.useState<Array<AccionMenu>>(botones)
  const [currentTab, setCurrentTab] = React.useState<string>('Fuente')
  const [busqueda, setBusqueda] = React.useState<string>(null)
  const [seleccionado, setSeleccionado] = React.useState<any>(null)
  const [fuente, setFuente] = React.useState<Array<any>>([])
  const [iva, setIva] = React.useState<Array<any>>([])

  const onSearchAll = React.useCallback(async () => {
    try {
      setFuente([])
      setIva([])
      let fechaActual: any = new Date()
      fechaActual = fechaActual.getDate() + '/' + (fechaActual.getMonth() + 1) + '/' + fechaActual.getFullYear()
      const requestBody: any = {
        fecha: fechaActual,
        retencion: busqueda !== null ? busqueda : "",
        tipo: 'todos',
      }
      const dataFuente = await asignacionesService.obtenerConceptosRetencion(requestBody)
      if (dataFuente !== null && dataFuente !== undefined) {
        setFuente(dataFuente)
      }
      requestBody.tipo = "iva"
      const dataIva = await asignacionesService.obtenerConceptosRetencion(requestBody)
      if (dataIva !== null && dataIva !== undefined) {
        setIva(dataIva)
      }
    } catch (error) {
      console.log(error)
    }
  }, [busqueda])

  React.useEffect(() => {
    onSearchAll()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const returnAction = (boton: AccionMenu) => {

    switch (boton.actionType) {
      case ButtonTypes.sendMail:
        onSendData()
        break;
      default:
        break;
    }
  }

  const onSendData = React.useCallback(() => {
    const data_send: any = {
      descripcion: seleccionado?.CON_CONCEPTO ?? "",
      numero: seleccionado?.CON_NUMERO ?? "",
      codigo: parseInt(seleccionado?.CON_CODIGO) ?? 0,
      porcentaje: seleccionado?.CON_PORCENTAJE ?? "",
      estado: parseInt(seleccionado?.CON_ESTADO) ?? 0,
    }
    onChanged(data_send)

    // onCancel()
  }, [seleccionado, onChanged])


  const onSelectedData = React.useCallback(({ selectedRowsData }) => {
    setSeleccionado(selectedRowsData[0])
  }, [])

  const onDBSelectedData = React.useCallback(({ data }) => {
    onSendData()
  }, [onSendData])



  const getButtonDisabled = React.useCallback((button: AccionMenu) => {
    switch (button.actionType) {
      case ButtonTypes.sendMail:
        return seleccionado === null || seleccionado === undefined
        break;
      default:
        return false;
    }
  }, [seleccionado])


  const botonesModal = () => {
    return (

      <GlobalMenu
        acciones={acciones}
        onClick={returnAction}
        getButtonDisabled={getButtonDisabled}
      />

    );
  }
  const bodyModal = () => {

    return (
      <>
        <RowContainer className="mt-2 border">
          <CRow>
            <CCol>
              <CNav variant="tabs" role="tablist" className="mt-4">
                <CNav variant="tabs">
                  {tabs.map((tab, index) => (
                    <CNavItem key={index}>
                      <CNavLink
                        onClick={() => {
                          setCurrentTab(tab)
                        }}
                        active={currentTab === tab}
                      >
                        {tab}
                      </CNavLink>
                    </CNavItem>
                  ))}
                </CNav>
                <CTabContent
                  style={{
                    overflowY: 'unset',
                    width: '100%',
                    marginBottom: '10px',
                  }}
                >
                  <CTabPane
                    role="tabpanel"
                    aria-labelledby="pendientes-tab"
                    visible={
                      currentTab === tabs[0]
                    }
                  >
                    <CRow className='mt-1 mb-1'>
                      <CCol xs="12" md="12">
                        <Labeled label="Número / Descripción:">
                          <TextBox
                            value={busqueda}
                            onValueChanged={(data) => {
                              if (data.value !== null && data.value !== undefined) {
                                setBusqueda(data.value)
                                const newArray = fuente.filter(item => {
                                  if ((item.CON_NUMERO.indexOf(data.value) >= 0) || (item.CON_CONCEPTO.indexOf(data.value) >= 0)) {
                                    return item
                                  }
                                })
                                setFuente(newArray)
                              }
                            }}
                          />
                        </Labeled>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol xs="12" md="12">
                        <DataGrid
                          selection={{ mode: 'single' }}
                          hoverStateEnabled={true}
                          dataSource={fuente}
                          showBorders={true}
                          showRowLines={true}
                          allowColumnReordering={true}
                          allowColumnResizing={true}
                          columnAutoWidth={true}
                          onSelectionChanged={onSelectedData}
                          onRowDblClick={onDBSelectedData}
                          id="CON_CODIGO"
                          key="fuente"
                        >
                          <FilterRow visible={true} />
                          <HeaderFilter visible={true} />
                          <Editing mode="cell" allowUpdating={true} />
                          <Paging defaultPageSize={10} />
                          <Pager
                            visible={fuente.length > 0 ? true : false}
                            allowedPageSizes={getAllowedPageSizes(fuente)}
                            displayMode={'full'}
                            showPageSizeSelector={true}
                            showInfo={true}
                            showNavigationButtons={true}
                          />
                          <Column
                            allowEditing={false}
                            dataField="CON_NUMERO"
                            caption="Código"
                            width="100%"
                          />
                          <Column
                            allowEditing={false}
                            dataField="CON_CONCEPTO"
                            caption="Descripcion"
                            width="100%"
                          />
                          <Column
                            allowEditing={false}
                            dataField="CON_PORCENTAJE"
                            caption="Porcentaje"
                            width="100%"
                          />
                        </DataGrid>
                      </CCol>
                    </CRow>
                  </CTabPane>
                  <CTabPane
                    role="tabpanel"
                    aria-labelledby="pagados-tab"
                    visible={
                      currentTab === tabs[1]
                    }
                  >
                    <DataGrid
                      selection={{ mode: 'single' }}
                      hoverStateEnabled={true}
                      dataSource={iva}
                      showBorders={true}
                      showRowLines={true}
                      allowColumnReordering={true}
                      allowColumnResizing={true}
                      onSelectionChanged={onSelectedData}
                      onRowDblClick={onDBSelectedData}
                      columnAutoWidth={true}
                      id="CON_CODIGO"
                      key="iva"
                    >
                      <FilterRow visible={true} />
                      <HeaderFilter visible={true} />
                      <Editing mode="cell" allowUpdating={true} />
                      <Paging defaultPageSize={10} />
                      <Pager
                        visible={iva.length > 0 ? true : false}
                        allowedPageSizes={getAllowedPageSizes(iva)}
                        displayMode={'full'}
                        showPageSizeSelector={true}
                        showInfo={true}
                        showNavigationButtons={true}
                      />
                      <Column
                        allowEditing={false}
                        dataField="CON_NUMERO"
                        caption="Código"
                        width="100%"
                      />
                      <Column
                        allowEditing={false}
                        dataField="CON_CONCEPTO"
                        caption="Descripcion"
                        width="100%"
                      />
                      <Column
                        allowEditing={false}
                        dataField="CON_PORCENTAJE"
                        caption="Porcentaje"
                        width="100%"
                      />
                    </DataGrid>
                  </CTabPane>
                </CTabContent>
              </CNav>
            </CCol>
          </CRow>
        </RowContainer>
      </>
    )
  }
  const renderContent = () => {
    return (
      <CCard>
        <CCardHeader>
          {botonesModal()}
        </CCardHeader>
        <CCardBody>
          <LoadPanel
            shadingColor="rgba(0,0,0,0.4)"
            position={'center'}
            visible={loader.show}
            showIndicator={true}
            shading={true}
            showPane={true}
            message={loader.mensaje}
            closeOnOutsideClick={false}
          />
          {bodyModal()}
        </CCardBody>
      </CCard>
    )
  }

  return (
    <>
      <Modalform
        name={'seleccionarCoceptosRetencion'}
        key={'seleccionarCoceptosRetencion'}
        headerTitle={"Conceptos de Retencion"}
        childrenBody={renderContent()}
        closeOnBackdrop={false}
        show={show}
        onClose={() => onCancel()}
        centered={true}
        size='lg'
      />
    </>
  )
}
