import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ConfigFichaTecnica } from "../types/types";



const initialState: ConfigFichaTecnica = {
  modulo: 0
}

const configuracionFichaTecnica = createSlice(
  {
    name: 'configFichaTecnica',
    initialState: initialState,
    reducers: {
      setCodigoModulo(state, acion: PayloadAction<number>) {
        state.modulo = acion.payload;
      },
    }
  }
)


export const configuracionesFichaTecnica = configuracionFichaTecnica.reducer;
export const { setCodigoModulo } = configuracionFichaTecnica.actions;