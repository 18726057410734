import React from 'react'
import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CCol,
  CRow,
  CTooltip,
  CLink,
} from '@coreui/react-pro'
import { useDispatch, useSelector } from 'react-redux'
import {
  Validator,
  RequiredRule,
  StringLengthRule,
  AsyncRule,
} from 'devextreme-react/validator'
import { Switch } from 'devextreme-react/switch'
import { RootState } from '../../../store/store'
import ValidationGroup from 'devextreme-react/validation-group'
import ValidationSummary from 'devextreme-react/validation-summary'
import NumberBox from 'devextreme-react/number-box'
import { TextBox } from 'devextreme-react/text-box'
import { LocalEmpresa, ToastTypes } from '../../../store/types'
import { addToast } from '../../../store/toasterReducer'
import { localidadesService } from '../../../services/localidades.service'
import { sidebarService } from '../../../services/sidebar.service'
import TextArea from 'devextreme-react/text-area'
import CIcon from '@coreui/icons-react'
import AtributosLocal from './atributos'
import { PopupContent } from '../../../views/componentes/popupContent'
import {
  EdicionLocalEmpresa,
  OptionCiudad,
  OptionPais,
  OptionProvincia,
} from './types/types'
import * as reducerSidebar from '../../../store/reducers'
import LoadingIndicator from '../../../views/componentes/loadingindicator/loadingindicator'
import FileUploader from 'devextreme-react/file-uploader'
import ImagenEmpresa from '../formEmpresa/component/imagenEmpresa'
import { v4 as uuidv4 } from 'uuid'
import { utilidades } from '../../../helpers/utilidades'
import Dialog from '../../../views/componentes/librerias/bootstrap-dialog'
import BlockUi from '../../../views/componentes/librerias/block-ui'
import { cilPencil } from '@coreui/icons'
import SelectBox from 'devextreme-react/select-box'
import LookUpTipoEstablecimiento from '../../../modulos/componentes/lookUpTipoEstablecimiento'
import { consoleService } from '../../../services/console.service'
import { TipoEstablecimientoOption } from '../../../modulos/componentes/lookUpTipoEstablecimiento/store/type'
import { tipoEstablecimientoService } from '../../../modulos/componentes/lookUpTipoEstablecimiento/service/tipoEstablecimiento.service'
import { Ciudad } from '../../../modulos/ventas/store/types'

interface IFormlocalProps extends React.PropsWithChildren {
  onChange: (data) => void
}

const FILE_SIZE = 1000000
const allowExtention = ['.jpg', '.jpeg']

export type TAtributos = {
  valor: string
  atributo: string
  idAtributo: number
  idAtributoValor: number
  nueva: boolean
}

const Formlocal: React.FunctionComponent<IFormlocalProps> = (props) => {
  const { onChange } = props
  const imageLogo = React.useRef<any>()

  const dialogRef = React.useRef<any>(null)
  const validationGroupRef = React.useRef<any>()
  const localState = useSelector(
    (state: RootState) => state.global.session?.local,
  )
  const empresaState = useSelector(
    (state: RootState) => state.global.session?.empresa,
  )
  const usuarioState = useSelector(
    (state: RootState) => state.global.session?.usuario,
  )
  const ingresoState = useSelector(
    (state: RootState) => state.global.session?.ingreso,
  )
  const dpPaisRedux = useSelector(
    (state: RootState) => state.global.sidebar.configLocalEmpresa.dpPais,
  )
  const configLocalEmpresaRedux = useSelector(
    (state: RootState) => state.global.sidebar.configLocalEmpresa,
  )
  const loadTabSidebarState = useSelector(
    (state: RootState) => state.global.sidebar.loadTab,
  )

  const dispatch = useDispatch()

  const [openAtributos, setOpenAtributos] = React.useState<boolean>(false)
  const [atributos, setAtributos] = React.useState<Array<TAtributos>>([])
  const [showErrorPopup, setShowErrorPopup] = React.useState<boolean>(false)
  const [showErrorMessages, setShowErrorMessages] = React.useState<Array<any>>(
    [],
  )
  const [loaderLocal, setLoaderLocal] = React.useState<{
    show: boolean
    message: string
  }>({ show: false, message: '' })
  const [errorsLogo, setErrorsLogo] = React.useState<any>([])
  const [imageSource, setImageSource] = React.useState<string>('')
  const [file, setFile] = React.useState<File | null>(null)
  const [nombre, setNombre] = React.useState<string>('')
  const [direccion, setDireccion] = React.useState<string>('')
  const [siglas, setSiglas] = React.useState<string>('')
  const [descuento, setDescuento] = React.useState<number>(0)
  const [telefono, setTelefono] = React.useState<string>('')
  const [pais, setPais] = React.useState<OptionPais | null>(null)
  const [provincia, setProvincia] = React.useState<OptionProvincia | null>(null)
  const [dpProvincia, setDpProvincia] = React.useState<
    Array<OptionProvincia> | []
  >([])
  const [ciudad, setCiudad] = React.useState<OptionCiudad | null>(null)
  const [controlaKardex, setControlaKardex] = React.useState<boolean>(false)
  const [listaPrecios, setListaPrecios] = React.useState<boolean>(false)
  const [dpPais, setDpPais] = React.useState<Array<OptionPais> | []>([])
  const [dpCiudad, setDpCiudad] = React.useState<Array<OptionCiudad> | []>([])
  const [tipoEstablecimiento, setTipoEstablecimiento] =
    React.useState<TipoEstablecimientoOption | null>(null)

  const setToast = React.useCallback(
    (texto, type: ToastTypes) => {
      let time: number = 3000
      if (texto.length < 50) {
        time = 5000
      } else if (texto.length > 50) {
        time = 9000
      }
      dispatch(
        addToast({
          id: '',
          autoHide: time,
          title: 'Acatha',
          content: texto,
          fade: true,
          type: type,
        }),
      )
    },
    [dispatch],
  )

  const llenarCiudades = React.useCallback(async (idProvincia) => {
    try {
      const ciudades = [] as Array<OptionCiudad>
      const data = await localidadesService.getDpCiudades(
        idProvincia,
        'Elija una opción',
      )

      if (data && data.length > 0) {
        for (const item of data) {
          ciudades.push({
            codigoPais: Number(item?.paisCodigo) ?? 0,
            nombrePais: String(item?.paisNombre) ?? '',
            codigoProvincia: Number(item?.provinciaCodigo) ?? 0,
            nombreProvincia: String(item?.provinciaNombre) ?? '',
            codigo: Number(item?.codigo) ?? 0,
            nombre: String(item?.nombre) ?? '',
          })
        }
      }
      return await ciudades
    } catch (error) {
      return await []
    }
  }, [])

  const llenarProvincias = React.useCallback(async (paisSeleccionado) => {
    const provincias = [] as Array<OptionProvincia>
    if (
      paisSeleccionado['provincias'] &&
      paisSeleccionado['provincias'].length > 0
    ) {
      await paisSeleccionado['provincias'].forEach((item: any) => {
        provincias.push({
          codigo: Number(item.codigo) ?? 0,
          codigoPais: Number(item.codigoPais) ?? 0,
          nombre: String(item.nombre) ?? '',
        })
      })
    }
    return provincias
  }, [])

  const filtraSeleccion = React.useCallback(async (buscar: any, data) => {
    const filtrado = await data.findIndex((item: any) => {
      if (typeof buscar === 'string') {
        return String(item.codigo) === String(buscar)
      } else if (typeof buscar === 'number') {
        return Number(item.codigo) === Number(buscar)
      }
    })
    return filtrado
  }, [])

  const parseSesionLocal = React.useCallback(
    (apiresult: any): LocalEmpresa => {
      const localEmpresa: LocalEmpresa = {
        codigo: apiresult?.codigo ?? 0,
        nombre: apiresult?.nombre ?? '',
        ciudad: apiresult?.ciudad ?? null,
        ciudadCodigo: apiresult?.ciudad?.codigo ?? -1,
        ciudadNombre: apiresult?.ciudad?.nombre ?? '',
        clase: apiresult?.clase ?? 0,
        descuento: apiresult?.descuento ?? 0,
        direccion: apiresult?.direccion ?? '',
        telefono: apiresult?.telefono ?? '',
        tipoRol: localState?.tipoRol ?? '',
        controlaKardex: apiresult?.kardex ?? false,
        establecimiento: apiresult?.establecimiento ?? '',
        siglas: apiresult?.siglas ?? '',
        uaf: apiresult.uaf ?? '',
        tipo: apiresult.tipo ?? 0,
        estado: localState?.estado ?? 0,
      }
      return localEmpresa
    },
    [localState],
  )

  const parseSesionCiudad = React.useCallback((apiresult: any): Ciudad => {
    const ciudad: Ciudad = {
      codigo: apiresult[0].codigo,
      nombre: apiresult[0].nombre,
      provinciaCodigo: parseInt(apiresult[0].codigoProvincia),
      provinciaNombre: apiresult[0].nombreProvincia,
      paisCodigo: parseInt(apiresult[0].codigoPais),
      paisNombre: apiresult[0].nombrePais,
      rDEPCodigo: 0,
      codigoPaisInternacional: apiresult[0]?.codigoPaisInternacional ?? 1,
      codigoProvinciaOrigen: apiresult[0]?.codigoProvinciaOrigen ?? 1,
      codigoOrigen: apiresult[0]?.codigoOrigen ?? 1,
    }
    return ciudad
  }, [])

  const parseApiLocalEmpresaData = React.useCallback(
    async (apiresult: any): Promise<EdicionLocalEmpresa> => {
      let dpPais = [] as Array<OptionPais>
      let dpProvincias = [] as Array<OptionProvincia>
      let dpCiudades = [] as Array<OptionCiudad>

      let pais: OptionPais | null = null
      let provincia: OptionProvincia | null = null
      let ciudad: OptionCiudad | null = null

      const attr = [] as Array<TAtributos>

      if (apiresult.atributos) {
        if (apiresult.atributos && apiresult.atributos.length > 0) {
          for (const item of apiresult.atributos) {
            attr.push({
              ...item,
              nueva: false,
            })
          }
        }
      }

      dpPais = apiresult.dpPais

      const filtropais = await filtraSeleccion(apiresult.paisCodigo, dpPais)
      if (filtropais > -1) {
        pais = dpPais[filtropais]
        dpProvincias = await llenarProvincias(dpPais[filtropais])

        const filtroProvincia = await filtraSeleccion(
          apiresult.provinciaCodigo,
          dpProvincias,
        )

        if (dpProvincias[filtroProvincia]) {
          provincia = dpProvincias[filtroProvincia]
          const ciudades = await llenarCiudades(
            dpProvincias[filtroProvincia]['codigo'],
          )
          dpCiudades = ciudades ?? []
          if (ciudades && ciudades.length > 0) {
            const filtroCiudad = await filtraSeleccion(
              apiresult.ciudadCodigo,
              ciudades,
            )
            if (filtroCiudad > -1) {
              ciudad = ciudades[filtroCiudad]
            }
          }
        }
      }

      let siglas: string = ''

      if (apiresult.siglas === '') {
        const defaultSiglas = apiresult.nombre.substring(-1, 3)
        siglas = String(defaultSiglas).toUpperCase() ?? ''
      } else {
        siglas = String(apiresult.siglas).toUpperCase() ?? ''
      }

      const infoLocalEmpresa: EdicionLocalEmpresa = {
        edition: false,
        codigo: apiresult.codigo ?? 0,
        nombre: apiresult.nombre ?? '',
        direccion: apiresult.direccion ?? '',
        siglas: siglas ?? '',
        siglasBodega: apiresult.siglasBod ?? '',
        telefono: apiresult.telefono ?? '',
        descuento: apiresult.descuento ?? 0,
        claseCodigo: apiresult.claseCodigo ?? 0,
        gerente: apiresult.gerente ?? '',
        tipo: apiresult.tipo ?? 0,
        perteneceCodigo: apiresult.perteneceCodigo ?? 0,
        estadoCodigo: apiresult.estadoCodigo ?? 0,
        web: apiresult.web ?? '',
        uaf: apiresult.uaf ?? '',
        usuarioDokan: apiresult.usuarioDokan ?? '',
        passwordDokan: apiresult.passwordDokan ?? '',
        controlaKardex: apiresult.controlaKardex ?? false,
        listaPrecios: apiresult.listaPrecios ?? false,
        pais: pais ?? null,
        provincia: provincia ?? null,
        ciudad: ciudad ?? null,
        dpPais: dpPais ?? [],
        dpProvincia: dpProvincias ?? [],
        dpCiudad: dpCiudades ?? [],
        atributos: attr,
        logo: apiresult.logo ?? null,
      }
      return infoLocalEmpresa
    },
    [filtraSeleccion, llenarCiudades, llenarProvincias],
  )

  const getPaises = React.useCallback(async () => {
    if (dpPaisRedux.length === 0) {
      const paises = [] as Array<OptionPais>
      const data = await localidadesService.getDpPaises('Elija una opción')

      if (data && data.length > 0) {
        for (const item of data) {
          const provincias = [] as Array<OptionProvincia>
          if (item['provincias'] && item['provincias']?.length > 0) {
            for (const provItem of item.provincias) {
              provincias.push({
                codigoPais: Number(provItem.codigoPais) ?? 0,
                codigo: Number(provItem.codigo) ?? 0,
                nombre: String(provItem.nombre) ?? '',
              })
            }
          }
          paises.push({
            ...item,
            codigo: Number(item.codigo) ?? 0,
            descripcion: String(item.nombre) ?? '',
            nombre: String(item.nombre) ?? '',
            codigoUaf: String(item.codigoUaf) ?? '',
            provincias: provincias ?? [],
          })
        }
      }
      return paises
    } else {
      return dpPaisRedux
    }
  }, [dpPaisRedux])

  const initDatosLocalEmpresa = React.useCallback(
    async (reload: boolean = false) => {
      if (localState?.codigo === 0) {
        return false
      }
      setLoaderLocal({ show: true, message: 'Cargando...' })

      const dataLocalEmpresaEdition = { ...defaultEditionLocalEmpresa }

      try {
        let paisesApi: Array<OptionPais> | [] = []

        let dataApi: any = []
        let infoParse: EdicionLocalEmpresa = {
          ...dataLocalEmpresaEdition,
        }

        infoParse.edition = false

        if (configLocalEmpresaRedux.codigo !== 0) {
          infoParse = configLocalEmpresaRedux
        } else {
          dataApi = await sidebarService.localExtraer(localState?.codigo ?? 0)
          paisesApi = await getPaises()
          dataApi.dpPais = paisesApi
          dataApi.atributos = []
          const atributosApi = await sidebarService.localAtributosExtraer(
            localState?.codigo ?? 0,
          )
          if (atributosApi.auto && atributosApi.auto.length > 0) {
            dataApi.atributos = atributosApi.auto
          }
          infoParse = await parseApiLocalEmpresaData(dataApi)
        }

        if (reload) {
          dataApi = await sidebarService.localExtraer(localState?.codigo ?? 0)
          paisesApi = await getPaises()
          dataApi.dpPais = paisesApi
          const atributosApi = await sidebarService.localAtributosExtraer(
            localState?.codigo ?? 0,
          )
          dataApi.atributos = []
          if (atributosApi.auto && atributosApi.auto.length > 0) {
            dataApi.atributos = atributosApi.auto
          }
          infoParse = await parseApiLocalEmpresaData(dataApi)
        }
        if (String(infoParse.logo).length > 0) {
          setImageSource(infoParse.logo ?? '')
        }
        if (infoParse.atributos && infoParse.atributos.length > 0) {
          setAtributos(infoParse.atributos)
        }
        setDpPais(infoParse.dpPais)
        setDpProvincia(infoParse.dpProvincia)
        setDpCiudad(infoParse.dpCiudad)

        setFile(null)
        setNombre(infoParse.nombre)
        setDireccion(infoParse.direccion)
        setSiglas(infoParse.siglas)
        setDescuento(infoParse.descuento)
        setTelefono(infoParse.telefono)
        setPais(infoParse.pais)
        setProvincia(infoParse.provincia)
        setCiudad(infoParse.ciudad)
        setControlaKardex(infoParse.controlaKardex)
        setListaPrecios(infoParse.listaPrecios)

        const tiposEstApi =
          await tipoEstablecimientoService.getTiposEstablecimientos(
            'Elija una opción',
            infoParse?.pais?.codigo,
          )
        if (tiposEstApi.auto && tiposEstApi.auto.length > 0) {
          const selectedIndex = tiposEstApi.auto.findIndex(
            (select: TipoEstablecimientoOption) => {
              return select.codigo === infoParse?.tipo
            },
          )
          if (selectedIndex > -1) {
            setTipoEstablecimiento(tiposEstApi.auto[selectedIndex])
          }
        }

        dispatch(reducerSidebar.setConfigLocalEmpresa(infoParse))
        setLoaderLocal({ show: false, message: '' })
      } catch (error) {
        consoleService.error(error)
        setLoaderLocal({ show: false, message: JSON.stringify(error) })
      }
    },
    [
      localState,
      parseApiLocalEmpresaData,
      configLocalEmpresaRedux,
      getPaises,
      dispatch,
    ],
  )

  const convertBase64 = React.useCallback(async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }, [])

  const onSelectedFilesChangedDec = React.useCallback(
    async (e) => {
      if (e.value && e.value.length > 0) {
        setFile(e.value[0])

        for (const item of e.value) {
          let fileCoded: any = ''

          await convertBase64(item)
            .then((data) => {
              fileCoded = data
            })
            .catch((data) => {
              fileCoded = data
            })

          setImageSource(fileCoded)
        }
      }
    },
    [convertBase64],
  )

  const validarFormatoPermitido = React.useCallback((file) => {
    let resRetorno: boolean = false
    let smsErrorsLogo: any = []
    let extention: string = ''
    if (file && file.length > 0) {
      extention = utilidades.getExtension(file[0].name)

      extention = extention.toLowerCase()

      if (allowExtention.includes('.' + extention)) {
        smsErrorsLogo = []
        resRetorno = true
      } else {
        const sms: string = 'El formato permitido es .jpg'
        smsErrorsLogo.push({ sms })
        resRetorno = false
      }
      setErrorsLogo(smsErrorsLogo)
    }
    return resRetorno
  }, [])

  const playLoader = React.useCallback(async () => {
    setLoaderLocal({ show: true, message: 'Cargando...' })
  }, [])

  const stopLoader = React.useCallback(async () => {
    setLoaderLocal({ show: false, message: '' })
  }, [])

  const loadProvincias = React.useCallback(
    async (provinciaData: OptionProvincia) => {
      let dpCiudades = [] as Array<OptionCiudad>
      let ciudad: OptionCiudad | null = null
      playLoader()
      setDpCiudad([])
      setCiudad(null)
      if (provinciaData.codigo) {
        const ciudades = await llenarCiudades(provinciaData.codigo)
        dpCiudades = ciudades ?? []
        ciudad = dpCiudades[0]
      } else {
        dpCiudades = []
        ciudad = null
      }

      // dispatch(reducerSidebar.setConfigLocalEmpresa({
      //   ...configLocalEmpresaRedux,
      //   provincia: provinciaData,
      //   dpCiudad: dpCiudades,
      //   ciudad: ciudad
      // }))

      setDpCiudad(dpCiudades)
      setCiudad(ciudad)
      // setProvincia(provinciaData)
      stopLoader()
    },
    [
      llenarCiudades,
      playLoader,
      stopLoader,
      // configLocalEmpresaRedux,
      // dispatch
    ],
  )

  const loadPais = React.useCallback(
    async (paisData: OptionPais) => {
      let dpProvincias = [] as Array<OptionProvincia>
      let dpCiudades = [] as Array<OptionCiudad>
      let provincia: OptionProvincia | null = null
      let ciudad: OptionCiudad | null = null
      playLoader()
      setDpProvincia([])
      setProvincia(null)

      if (paisData.provincias && paisData.provincias.length > 0) {
        dpProvincias = await llenarProvincias(paisData)
        provincia = dpProvincias[0]
        const ciudades = await llenarCiudades(dpProvincias[0]['codigo'])
        dpCiudades = ciudades ?? []
        ciudad = dpCiudades[0]
      } else {
        dpProvincias = []
        provincia = null
        dpCiudades = []
        ciudad = null
      }

      // dispatch(reducerSidebar.setConfigLocalEmpresa({
      //   ...configLocalEmpresaRedux,
      //   dpProvincia: dpProvincias,
      //   dpCiudad: dpCiudades,
      //   provincia: provincia,
      //   ciudad: ciudad
      // }))
      setDpProvincia(dpProvincias)
      setDpCiudad(dpCiudades)
      stopLoader()
    },
    [
      llenarProvincias,
      llenarCiudades,
      playLoader,
      stopLoader,
      // configLocalEmpresaRedux,
      // dispatch
    ],
  )

  const onChangeListaPrecios = React.useCallback((data) => {
    if (data.event !== undefined) {
      setListaPrecios(data.value)
    }
  }, [])

  const onChangeControlaKardex = React.useCallback((data) => {
    if (data.event !== undefined) {
      setControlaKardex(data.value)
    }
  }, [])

  const onChangePais = React.useCallback(
    (evt) => {
      if (evt.event !== undefined) {
        if (evt.value) {
          setPais(evt.value)
          loadPais(evt.value)
        } else {
          setPais(dpPais[0])
          loadPais(dpPais[0])
        }
      }
    },
    [dpPais, loadPais],
  )

  const onChangeProvincia = React.useCallback(
    (evt) => {
      if (evt.event !== undefined) {
        if (evt.value) {
          setProvincia(evt.value)
          loadProvincias(evt.value)
        } else {
          setProvincia(dpProvincia[0])
          loadProvincias(dpProvincia[0])
        }
      }
    },
    [dpProvincia, loadProvincias],
  )

  const onChangeCiudad = React.useCallback(
    (evt) => {
      if (evt.event !== undefined) {
        if (evt.value) {
          setCiudad(evt.value)
        } else {
          setCiudad(dpCiudad[0])
        }
      }
    },
    [dpCiudad],
  )

  const validateAsyncSelect = React.useCallback((datoSeleccion, message) => {
    if (datoSeleccion.value.codigo > -1) {
      return Promise.resolve()
    } else {
      return Promise.reject(message)
    }
  }, [])

  const validateAsyncSeleccionPais = React.useCallback(
    (datoSeleccion) => {
      return validateAsyncSelect(
        datoSeleccion,
        `- País: Debe seleccionar una opción.`,
      )
    },
    [validateAsyncSelect],
  )

  const validateAsyncSeleccionProvincia = React.useCallback(
    (datoSeleccion) => {
      return validateAsyncSelect(
        datoSeleccion,
        `- Provincia: Debe seleccionar una opción.`,
      )
    },
    [validateAsyncSelect],
  )

  const validateAsyncSeleccionCiudad = React.useCallback(
    (datoSeleccion) => {
      return validateAsyncSelect(
        datoSeleccion,
        `- Ciudad: Debe seleccionar una opción.`,
      )
    },
    [validateAsyncSelect],
  )

  const onErrorConfirm = React.useCallback((messages: any, tittle?: string) => {
    dialogRef.current.show({
      title: (
        <>
          <strong>{'Ácatha'}</strong>
        </>
      ),
      body: (
        <>
          <strong style={{ fontSize: '14px' }}>{tittle}</strong>
          <br />
          <CAlert color="danger">
            <ul className="errorList">
              {messages.map(function (item, id) {
                return <li key={id}>{item}</li>
              })}
            </ul>
          </CAlert>
        </>
      ),
      actions: [
        Dialog.Action(
          <span>
            <u>{'A'}</u>
            {'ceptar'}
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>{'C'}</u>
            {'ancelar'}
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    return
  }, [])

  const validarFormulario = React.useCallback(async () => {
    const errors: any = []
    if (
      configLocalEmpresaRedux.pais === null ||
      configLocalEmpresaRedux.pais.codigo < 0
    ) {
      errors.push('País: Debe seleccionar una opción.')
    }
    if (
      configLocalEmpresaRedux.provincia === null ||
      configLocalEmpresaRedux.provincia.codigo < 0
    ) {
      errors.push('Provincia: Debe seleccionar una opción.')
    }
    if (ciudad === null || ciudad.codigo < 0) {
      errors.push('Ciudad: Debe seleccionar una opción.')
    }

    if (errors.length > 0) {
      return {
        errors: true,
        messages: errors,
      }
    } else {
      return {
        errors: false,
        messages: errors,
      }
    }
  }, [configLocalEmpresaRedux, ciudad])

  const handleSubmit = React.useCallback(async () => {
    const result = validationGroupRef.current.instance.validate()
    if (result.isValid === false) {
      setToast('Tiene errores por favor verifíquelos.', ToastTypes.Info)

      setShowErrorPopup(true)
      setShowErrorMessages(result.brokenRules)

      return false
    } else {
      setShowErrorPopup(false)
      setShowErrorMessages([])

      const resValidar = await validarFormulario()
      if (resValidar['errors'] === true) {
        onErrorConfirm(
          resValidar['messages'],
          'Antes de continuar revise lo siguiente:',
        )
        return false
      }

      playLoader()
      const dataLocal: any = {
        codigo: localState?.codigo,
        clase: configLocalEmpresaRedux.claseCodigo,
        nombre: nombre,
        siglas: siglas,
        ciudad: ciudad?.codigo ?? -1,
        direccion: direccion,
        telefono: telefono,
        gerente: configLocalEmpresaRedux.gerente,
        tipo: tipoEstablecimiento.codigo,
        pertenece: configLocalEmpresaRedux.perteneceCodigo,
        kardex: controlaKardex === true ? 1 : 0,
        estado: configLocalEmpresaRedux.estadoCodigo,
        siglasBOD: configLocalEmpresaRedux.siglasBodega,
        web: configLocalEmpresaRedux.web,
        empresaCodigo: empresaState?.codigo,
        uaf: configLocalEmpresaRedux.uaf,
        descuento: descuento,
        usuarioDokan: configLocalEmpresaRedux.usuarioDokan,
        passwordDokan: configLocalEmpresaRedux.passwordDokan,
        listaPrecios: listaPrecios === true ? 1 : 0,
        nameLogo:
          configLocalEmpresaRedux.logo === null
            ? ''
            : configLocalEmpresaRedux.logo ?? '',
        codeFileLogo: '',
      }

      if (file !== null) {
        const extentionFile: string = file['name'].split('.').pop() ?? ''

        if (extentionFile !== 'jpg') {
          stopLoader()
          setToast(
            'El formato del logo ingresado no es permitido.',
            ToastTypes.Info,
          )
          return false
        }

        dataLocal.nameLogo =
          (empresaState && empresaState['codigo']) +
          '_' +
          (localState && localState['codigo']) +
          '.' +
          extentionFile
        let fileCoded: any = ''

        if (typeof file === 'object') {
          await convertBase64(file)
            .then((data) => {
              fileCoded = data
            })
            .catch((data) => {
              fileCoded = data
            })
        } else {
          fileCoded = file
        }
        dataLocal.codeFileLogo = fileCoded
      }

      if (dataLocal?.nameLogo) {
        if (dataLocal.nameLogo.includes('null')) {
          dataLocal.nameLogo = ''
        } else if (dataLocal.nameLogo.includes('/')) {
          const fileName = utilidades.getTextAfterLastSlash(dataLocal.nameLogo)
          dataLocal.nameLogo = fileName
        }
      }

      try {
        const data = await sidebarService.datosLocalActualizar(dataLocal)
        stopLoader()
        if (data['error'] === true) {
          setToast(data['message'], ToastTypes.Warning)
          return false
        } else {
          initDatosLocalEmpresa(true)
          setToast(data['message'], ToastTypes.Success)
          // update session store local
          dataLocal.ciudad = ciudad
          let toSessionLocal = parseSesionLocal(dataLocal)
          // LocalEmpresa
          const apiDataCiudadLocal = await localidadesService.consultarCiudades(
            ciudad?.codigo,
          )
          console.log('apiDataCiudadLocal', apiDataCiudadLocal)

          const ciudadLocal = parseSesionCiudad(apiDataCiudadLocal)

          toSessionLocal = {
            ...toSessionLocal,
            ciudad: ciudadLocal,
          }

          dispatch(
            reducerSidebar.establecerSession({
              empresa: empresaState,
              local: toSessionLocal,
              usuario: usuarioState,
              ingreso: ingresoState,
            }),
          )
        }
      } catch (error) {
        consoleService.error(error)
        stopLoader()
        setToast(error, ToastTypes.Danger)
      }
    }
  }, [
    playLoader,
    setToast,
    stopLoader,
    dispatch,
    validarFormulario,
    onErrorConfirm,
    initDatosLocalEmpresa,
    configLocalEmpresaRedux,
    empresaState,
    localState,
    convertBase64,
    file,
    nombre,
    direccion,
    siglas,
    descuento,
    telefono,
    ciudad,
    controlaKardex,
    listaPrecios,
    tipoEstablecimiento,
    parseSesionLocal,
    usuarioState,
    ingresoState,
    parseSesionCiudad,
  ])

  React.useEffect(() => {
    if (loadTabSidebarState === 'config_empresa_local') {
      initDatosLocalEmpresa(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadTabSidebarState])

  return (
    <div>
      {showErrorPopup && (
        <PopupContent
          show={showErrorPopup}
          title={'Acatha'}
          subTitle={'Por favor revise las siguientes advertencias:'}
          onClose={() => {
            setShowErrorPopup(false)
            setShowErrorMessages([])
          }}
          size={'sm'}
          canClose={true}
          height={'auto'}
        >
          <CAlert color="danger">
            <ul style={{ listStyle: 'none' }}>
              {showErrorMessages.map(function (item, id) {
                return <li key={id}>{item['message']}</li>
              })}
            </ul>
          </CAlert>
        </PopupContent>
      )}
      {openAtributos === true && (
        <AtributosLocal
          show={openAtributos}
          onClose={() => {
            setOpenAtributos(!openAtributos)
          }}
          onChange={() => {
            setOpenAtributos(false)
            initDatosLocalEmpresa(true)
          }}
          atributos={configLocalEmpresaRedux.atributos}
          setToast={setToast}
        />
      )}
      <div>
        <Dialog ref={dialogRef} />
      </div>
      <div id="configLocal">
        <BlockUi
          tag="div"
          loader={LoadingIndicator}
          blocking={loaderLocal?.show ?? false}
          message={loaderLocal?.message ?? ''}
        >
          <fieldset disabled={loaderLocal.show}>
            <ValidationGroup ref={validationGroupRef} className="">
              <CCard style={{ border: 1 }} className="m-0">
                <CCardBody>
                  <CRow>
                    <CCol className=" d-flex " md="12">
                      <div className="dx-field" style={{ width: '100%' }}>
                        <CRow>
                          <CCol lg="12" md="12" sm="12">
                            <div
                              className="dx-field-label"
                              style={{ width: '100%' }}
                            >
                              {'Nombre Local*:'}
                            </div>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol lg="12" md="12" sm="12">
                            <TextBox
                              value={nombre}
                              placeholder=""
                              onValueChanged={(data) => {
                                if (data.value) {
                                  setNombre(data.value)
                                }
                              }}
                              showClearButton={true}
                              width="100%"
                            >
                              <Validator>
                                <RequiredRule message="- Nombre Local: Este campo es requerido" />
                                <StringLengthRule
                                  min="4"
                                  message="- Nombre Local: Este campo debe tener al menos 4 caracteres"
                                />
                                <StringLengthRule
                                  max="250"
                                  message="- Nombre Local: Este campo no puede tener mas de 250 caracteres"
                                />
                              </Validator>
                            </TextBox>
                          </CCol>
                        </CRow>
                      </div>
                    </CCol>
                  </CRow>

                  <CRow>
                    <CCol className=" d-flex " md="12">
                      <div className="dx-field" style={{ width: '100%' }}>
                        <CRow>
                          <CCol lg="12" md="12" sm="12">
                            <div
                              className="dx-field-label"
                              style={{ width: '100%' }}
                            >
                              {'Dirección(*):'}
                            </div>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol lg="12" md="12" sm="12">
                            <TextArea
                              value={direccion}
                              placeholder=""
                              onValueChanged={(data) => {
                                if (data.value) {
                                  setDireccion(data.value)
                                }
                              }}
                              showClearButton={true}
                              width="100%"
                              onEnterKey={() => {
                                handleSubmit()
                              }}
                            >
                              <Validator>
                                <RequiredRule message="- Dirección: Este campo es requerido" />
                                <StringLengthRule
                                  min="8"
                                  message="- Dirección: Este campo debe tener al menos 8 caracteres"
                                />
                                <StringLengthRule
                                  max="200"
                                  message="- Dirección: Este campo no puede tener mas de 200 caracteres"
                                />
                              </Validator>
                            </TextArea>
                          </CCol>
                        </CRow>
                      </div>
                    </CCol>
                  </CRow>

                  <CRow>
                    <CCol className=" d-flex " md="6">
                      <div className="dx-field" style={{ width: '100%' }}>
                        <CRow>
                          <CCol lg="12" md="12" sm="12">
                            <div
                              className="dx-field-label"
                              style={{ width: '100%' }}
                            >
                              {'Siglas(*):'}
                            </div>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol lg="12" md="12" sm="12">
                            <TextBox
                              value={siglas}
                              placeholder=""
                              onValueChanged={(data) => {
                                if (data.value) {
                                  setSiglas(data.value)
                                }
                              }}
                              showClearButton={true}
                              width="100%"
                              onEnterKey={() => {
                                handleSubmit()
                              }}
                            >
                              <Validator>
                                <RequiredRule message="- Siglas: Este campo es requerido" />
                                <StringLengthRule
                                  min="3"
                                  message="- Siglas: Este campo debe tener al menos 3 caracteres"
                                />
                                <StringLengthRule
                                  max="10"
                                  message="- Siglas: Este campo no puede tener mas de 10 caracteres"
                                />
                              </Validator>
                            </TextBox>
                          </CCol>
                        </CRow>
                      </div>
                    </CCol>

                    <CCol className=" d-flex " md="6">
                      <div className="dx-field" style={{ width: '100%' }}>
                        <CRow>
                          <CCol lg="12" md="12" sm="12">
                            <div
                              className="dx-field-label"
                              style={{ width: '100%' }}
                            >
                              {'Descuento(*):'}
                            </div>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol lg="12" md="12" sm="12">
                            <NumberBox
                              value={descuento}
                              placeholder="3.99"
                              onValueChange={(v) => {
                                setDescuento(v)
                              }}
                              width="100%"
                              onEnterKey={() => {
                                handleSubmit()
                              }}
                              min={0.0}
                              max={100}
                              showSpinButtons={true}
                              showClearButton={true}
                              defaultValue={0}
                            >
                              <Validator>
                                <RequiredRule message="- Descuento: Ingrese un valor en descuento o al menos (0)" />
                              </Validator>
                            </NumberBox>
                          </CCol>
                        </CRow>
                      </div>
                    </CCol>
                  </CRow>

                  <CRow>
                    <CCol className=" d-flex " md="12">
                      <div className="dx-field" style={{ width: '100%' }}>
                        <CRow>
                          <CCol lg="12" md="12" sm="12">
                            <div
                              className="dx-field-label"
                              style={{ width: '100%' }}
                            >
                              {'Teléfono (*):'}
                            </div>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol lg="12" md="12" sm="12">
                            <TextBox
                              value={telefono}
                              placeholder=""
                              onValueChanged={(data) => {
                                if (data.value) {
                                  setTelefono(data.value)
                                }
                              }}
                              showClearButton={true}
                              width="100%"
                              onEnterKey={() => {
                                handleSubmit()
                              }}
                            >
                              <Validator>
                                <RequiredRule message="- Teléfono: Este campo es requerido" />
                                <StringLengthRule
                                  min="6"
                                  message="- Teléfono: Este campo debe tener al menos 6 caracteres"
                                />
                                <StringLengthRule
                                  max="15"
                                  message="- Teléfono: Este campo no puede tener mas de 15 caracteres"
                                />
                              </Validator>
                            </TextBox>
                          </CCol>
                        </CRow>
                      </div>
                    </CCol>
                  </CRow>

                  <CRow>
                    <CCol className=" d-flex " md="12">
                      <div className="dx-field" style={{ width: '100%' }}>
                        <CRow>
                          <CCol lg="12" md="12" sm="12">
                            <div
                              className="dx-field-label"
                              style={{ width: '100%' }}
                            >
                              {'País :'}
                            </div>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol lg="12" md="12" sm="12">
                            <SelectBox
                              id="selectPaises"
                              width="100%"
                              dataSource={dpPais ?? []}
                              displayExpr="descripcion"
                              searchEnabled={true}
                              searchMode={'contains'}
                              searchExpr={'descripcion'}
                              searchTimeout={200}
                              minSearchLength={3}
                              placeholder="Seleccionar"
                              showClearButton={true}
                              value={pais ?? null}
                              showDataBeforeSearch={false}
                              onValueChanged={(e) => onChangePais(e)}
                            >
                              <Validator>
                                <RequiredRule
                                  message={'- País: Este campo es requerido'}
                                />
                                <AsyncRule
                                  reevaluate
                                  message={
                                    '- País: Debe seleccionar una opción'
                                  }
                                  validationCallback={
                                    validateAsyncSeleccionPais
                                  }
                                />
                              </Validator>
                            </SelectBox>
                          </CCol>
                        </CRow>
                      </div>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol className=" d-flex " md="12">
                      <div className="dx-field" style={{ width: '100%' }}>
                        <CRow>
                          <CCol lg="12" md="12" sm="12">
                            <div
                              className="dx-field-label"
                              style={{ width: '100%' }}
                            >
                              {'Provincia :'}
                            </div>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol lg="12" md="12" sm="12">
                            <SelectBox
                              id="selectProvincias"
                              width="100%"
                              dataSource={dpProvincia ?? []}
                              displayExpr="nombre"
                              searchEnabled={true}
                              searchMode={'contains'}
                              searchExpr={'nombre'}
                              searchTimeout={200}
                              minSearchLength={3}
                              showDataBeforeSearch={true}
                              placeholder="Seleccionar"
                              showClearButton={true}
                              value={provincia ?? null}
                              onValueChanged={(e) => onChangeProvincia(e)}
                            >
                              <Validator>
                                <RequiredRule
                                  message={
                                    '- Provincia: Este campo es requerido'
                                  }
                                />
                                <AsyncRule
                                  reevaluate
                                  message={
                                    '- Provincia: Debe seleccionar una opción'
                                  }
                                  validationCallback={
                                    validateAsyncSeleccionProvincia
                                  }
                                />
                              </Validator>
                            </SelectBox>
                          </CCol>
                        </CRow>
                      </div>
                    </CCol>
                  </CRow>

                  <CRow>
                    <CCol className=" d-flex " md="12">
                      <div className="dx-field" style={{ width: '100%' }}>
                        <CRow>
                          <CCol lg="12" md="12" sm="12">
                            <div
                              className="dx-field-label"
                              style={{ width: '100%' }}
                            >
                              {'Ciudad :'}
                            </div>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol lg="12" md="12" sm="12">
                            <SelectBox
                              id="selectCiudades"
                              width="100%"
                              dataSource={dpCiudad ?? []}
                              displayExpr="nombre"
                              searchEnabled={true}
                              searchMode={'contains'}
                              searchExpr={'nombre'}
                              searchTimeout={200}
                              minSearchLength={3}
                              showDataBeforeSearch={true}
                              placeholder="Seleccionar"
                              showClearButton={true}
                              value={ciudad ?? null}
                              onValueChanged={(e) => onChangeCiudad(e)}
                            >
                              <Validator>
                                <RequiredRule
                                  message={'- Ciudad: Este campo es requerido'}
                                />
                                <AsyncRule
                                  reevaluate
                                  message={
                                    '- Ciudad: Debe seleccionar una opción'
                                  }
                                  validationCallback={
                                    validateAsyncSeleccionCiudad
                                  }
                                />
                              </Validator>
                            </SelectBox>
                          </CCol>
                        </CRow>
                      </div>
                    </CCol>
                  </CRow>

                  {pais !== null && (
                    <CRow>
                      <CCol className=" d-flex " md="12">
                        <div className="dx-field" style={{ width: '100%' }}>
                          <CRow>
                            <CCol lg="12" md="12" sm="12">
                              <div
                                className="dx-field-label"
                                style={{ width: '100%' }}
                              >
                                {'Tipo Establecimiento :'}
                              </div>
                            </CCol>
                          </CRow>
                          <CRow>
                            <CCol lg="12" md="12" sm="12">
                              <LookUpTipoEstablecimiento
                                onChanged={(data) => {
                                  consoleService.log('data ', data)
                                  setTipoEstablecimiento(data)
                                }}
                                selected={tipoEstablecimiento}
                                paisCodigo={pais.codigo}
                              />
                            </CCol>
                          </CRow>
                        </div>
                      </CCol>
                    </CRow>
                  )}

                  <CRow>
                    <CCol className=" d-flex " md="12">
                      <div className="dx-field" style={{ width: '100%' }}>
                        <CRow>
                          <CCol lg="12" md="12" sm="12">
                            <div
                              className="dx-field-label"
                              style={{ width: '100%' }}
                            >
                              {'Transacciones en negativo*:'}
                            </div>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol lg="12" md="12" sm="12">
                            <Switch
                              value={controlaKardex}
                              onValueChanged={onChangeControlaKardex}
                              switchedOffText="NO"
                              switchedOnText="SI"
                            />
                          </CCol>
                        </CRow>
                      </div>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol className=" d-flex " md="12">
                      <div className="dx-field" style={{ width: '100%' }}>
                        <CRow>
                          <CCol lg="12" md="12" sm="12">
                            <div
                              className="dx-field-label"
                              style={{ width: '100%' }}
                            >
                              {'Utilizar Lista de Precio*:'}
                            </div>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol lg="12" md="12" sm="12">
                            <Switch
                              value={listaPrecios}
                              onValueChanged={onChangeListaPrecios}
                              switchedOffText="NO"
                              switchedOnText="SI"
                            />
                          </CCol>
                        </CRow>
                      </div>
                    </CCol>
                  </CRow>

                  <CRow>
                    <CCol lg="12" className={'mt-2 mb-2'}>
                      <CCard className={'p-2'}>
                        <CRow>
                          <CCol lg="12" className={'mt-2 mb-2'}>
                            <strong className="m-2">{'ATRIBUTOS'}</strong>
                            <CTooltip
                              key="title"
                              placement="top"
                              content={'Editar Atributos'}
                            >
                              <CIcon
                                onClick={() => {
                                  setOpenAtributos(true)
                                }}
                                className="ms-3"
                                size="sm"
                                icon={cilPencil}
                              />
                            </CTooltip>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol lg="12">
                            <ul style={{ listStyle: 'none' }}>
                              {atributos.length > 0 &&
                                atributos.map(function (x, i) {
                                  return (
                                    <li key={i}>
                                      <div key={i.toString()}>
                                        <span key={'labSpan-' + i}>
                                          {' ' + x.atributo + ' : '}
                                        </span>
                                        <label
                                          key={'lab-' + i}
                                          style={{
                                            backgroundColor: String(x?.atributo)
                                              .toLocaleLowerCase()
                                              .includes('color')
                                              ? String(x?.valor)
                                              : '',
                                          }}
                                        >
                                          {' ' + x.valor}
                                        </label>
                                      </div>
                                    </li>
                                  )
                                })}
                            </ul>
                          </CCol>
                        </CRow>
                      </CCard>
                    </CCol>
                  </CRow>

                  <CTooltip
                    key={'tool-subirLogoLocal'}
                    placement="top"
                    content={
                      'Medidas recomendadas 487x295 px y en formato (.jpg) '
                    }
                  >
                    <CLink> {'Carga tu Logo'} </CLink>
                  </CTooltip>
                  <br />
                  <small>
                    {'Medidas recomendadas 487x295 px y en formato (.jpg) '}
                  </small>
                  <CRow className="sectionErrorsLogo">
                    <CCol lg="12" className="sectionErrorsLogo">
                      <ul className="sectionErrorsLogo">
                        {errorsLogo.map((objFile, i) => {
                          return (
                            <li key={i} className="smsError">
                              {objFile['sms']}
                            </li>
                          )
                        })}
                      </ul>
                    </CCol>
                  </CRow>
                  {(file !== null || imageSource !== '') && (
                    <CRow className="mt-1 mb-1">
                      <CCol lg="12">
                        <CLink
                          className="card-header-action sectionErrorsLogo"
                          onClick={() => {
                            dispatch(
                              reducerSidebar.setConfigLocalEmpresa({
                                ...configLocalEmpresaRedux,
                                logo: null,
                              }),
                            )
                            setFile(null)
                            setImageSource('')
                            setErrorsLogo([])
                            imageLogo.current.instance.reset()
                          }}
                        >
                          {'Reset Logo'}
                        </CLink>
                      </CCol>
                    </CRow>
                  )}

                  <CRow className="m-0">
                    <CCol lg="12" className="sectionErrorsLogo">
                      <FileUploader
                        showFileList={false}
                        multiple={false}
                        accept={'.jpg,.jpeg'}
                        ref={imageLogo}
                        id="subirLogo"
                        labelText=""
                        selectButtonText="Seleccionar logo"
                        uploadMode="useForm"
                        onValueChanged={(data) => {
                          if (data.value) {
                            validarFormatoPermitido(data.value)
                            onSelectedFilesChangedDec(data)
                          }
                        }}
                        maxFileSize={FILE_SIZE}
                        allowedFileExtensions={['.jpg', '.jpeg']}
                      />

                      <div className="fileuploader-container" key={uuidv4()}>
                        {imageSource && (
                          <ImagenEmpresa imagenBlob={imageSource} />
                        )}
                      </div>
                    </CCol>
                  </CRow>

                  <CRow>
                    <CCol lg="12">
                      <ValidationSummary id="summary"></ValidationSummary>
                    </CCol>
                  </CRow>

                  <CRow id="configDataEmpresaTour">
                    <CCol className="mt-2 mb-2 d-grid">
                      <CButton
                        onClick={() => handleSubmit()}
                        variant="outline"
                        color="primary"
                      >
                        {loaderLocal.show === true
                          ? 'POR FAVOR ESPERE...'
                          : 'APLICAR'}
                      </CButton>
                    </CCol>
                  </CRow>
                </CCardBody>
              </CCard>
            </ValidationGroup>
          </fieldset>
        </BlockUi>
      </div>
    </div>
  )
}

export default React.memo(Formlocal)

export const defaultEditionLocalEmpresa: EdicionLocalEmpresa = {
  edition: false,
  codigo: 0,
  nombre: '',
  direccion: '',
  siglas: '',
  siglasBodega: '',
  telefono: '',
  descuento: 0,
  claseCodigo: 0,
  gerente: '',
  tipo: 0,
  perteneceCodigo: 0,
  estadoCodigo: 0,
  web: '',
  uaf: '',
  usuarioDokan: '',
  passwordDokan: '',
  controlaKardex: false,
  listaPrecios: false,
  pais: null,
  provincia: null,
  ciudad: null,
  dpPais: [],
  dpProvincia: [],
  dpCiudad: [],
  atributos: [],
  logo: null,
}
