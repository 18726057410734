import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../../store/store'
import { FETCH_STATUS } from '../../../../../store/types'
import {
  TUnidadMedidaListado,
  TFiltroBusquedaUnidadMedidaState
} from './types';
import { TConfigSearchState } from '../../store/types'
import {UnidadMedidaService} from "../services/unidadMedida.services";

const initialState: TConfigSearchState<TUnidadMedidaListado> = {
  filter: {
    codigo: 0,
    nombre: '',
    siglas: '',
    codigoTributario: '',
    estado: 1,
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  resultados: [],
  selectedItem: null,
  error: false,
}

export const fetchUnidadMedida = createAsyncThunk<
  Array<TUnidadMedidaListado>,
  TFiltroBusquedaUnidadMedidaState
>('inventario/unidades/listar', async (filtro) => {
  try {
    return await UnidadMedidaService.getAllUnidadMedida(filtro)
  } catch (error) {
    return Promise.reject(error)
  }
});

const searchConfSlice = createSlice({
  name: 'UnidadMedidaSearch',
  initialState: initialState,
  reducers: {
    resetState(state) {
      state = {
        ...initialState,
        filter: {
          ...initialState.filter,
        },
      }
    },
    changeFilter(state, action: PayloadAction<TFiltroBusquedaUnidadMedidaState>) {
      state.filter = action.payload
    },
    setCleanResult(state) {
      state.resultados = []
    },
    setResultados(state, action: PayloadAction<Array<TUnidadMedidaListado> | []>) {
      state.resultados = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUnidadMedida.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
        state.error = false
      }
    })
    builder.addCase(fetchUnidadMedida.fulfilled, (state, { payload }: {payload:any}) => {
      state.resultados = payload.auto
      state.status = FETCH_STATUS.SUCCESS
      state.error = payload.error
      state.mensaje = payload.message
    })
    builder.addCase(fetchUnidadMedida.rejected, (state, { payload }: {payload:any}) => {
      state.resultados = []
      state.status = FETCH_STATUS.FAILED
      state.error = payload.error
      state.mensaje = payload.message
    })
  }
});

export const selectFiltroBusquedaUnidadMedida = (state: RootState) => {
  return state.administracion.catalagos.unidadMedida.search.filter
}

export const selectResultadosBusquedaUnidadMedida = (state: RootState) => {
  return state.administracion.catalagos.unidadMedida.search.resultados
}

export const selectBusquedaUnidadMedidaEstado = (state: RootState) => {
  return state.administracion.catalagos.unidadMedida.search.status
}

export const { setResultados, resetState, setCleanResult, changeFilter } = searchConfSlice.actions
export const searchReducer = searchConfSlice.reducer
