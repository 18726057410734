import { combineReducers } from '@reduxjs/toolkit'
import { menuReducer } from './menuReducer'
import { searchSujetoExcluidoReducer } from './searchReducer'
import { datosEdicionReducer } from './editDataReducer'
import { tabsReducer } from './tabsReducer'
import { configuracionesReducer } from './configuracionesSujetoExcluido'
import { SujetoExcluidoState } from '../types/types'

export const sujetoExcluidoReducer = combineReducers<SujetoExcluidoState>({
  menu: menuReducer,
  tabs: tabsReducer,
  search: searchSujetoExcluidoReducer,
  editData: datosEdicionReducer,
  configuraciones: configuracionesReducer,
})
