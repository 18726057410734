import { useCallback } from "react"
import { RequestHelper } from "../../../../../helpers/requestHelper"
import { PagedResult, PaginationInfo } from "../../../../../store/types"
import { FiltroBusquedaOrganico, OrganicSave, OrganicoList } from "../types/types"
import { useDispatch } from "react-redux"
import CustomStore from "devextreme/data/custom_store"
import { setNroRegistrosEncontradas, setSearch } from "../store/searchReducer"
import DataSource from "devextreme/data/data_source"


export const OrganicServices = { useSetDataSources, deleteOrganic, saveOrganic, getOrganic }


async function getOrganic(
  filter: FiltroBusquedaOrganico,
): Promise<any> {

  try {
    const queryData = {
      descripcion: filter?.descripcion ?? '',
    }

    const apiData = await RequestHelper.getAll<any>(
      'admin/organico',
      'getAll',
      '',
      queryData,
    )
    return apiData

  } catch (error) {
    return error
  }
}

async function getOrganicPaged(
  filter: FiltroBusquedaOrganico,
  pagination: PaginationInfo,
): Promise<PagedResult<OrganicoList>> {

  const queryData = {
    descripcion: filter?.descripcion ?? '',
    isPaged: true,
    page: pagination.current,
    limit: pagination.size,
  }

  const apiDataPaged = await RequestHelper.get<any>(
    'admin/organico',
    'getAll',
    '',
    queryData,
  )
  const apiData = apiDataPaged.auto as Array<OrganicoList>
  const contrats = apiData
  const pagedResult: PagedResult<OrganicoList> = {
    currentPage: parseInt(apiDataPaged.paginaActual),
    from: parseInt(apiDataPaged.desde),
    to: parseInt(apiDataPaged.hasta),
    count: parseInt(apiDataPaged.totalRegistros),
    result: contrats,
  }
  return pagedResult
}




function useSetDataSources() {
  const dispatch = useDispatch()
  const getOrganicDatasource = useCallback(
    (find: FiltroBusquedaOrganico) => {
      const store = new CustomStore<void, OrganicoList>({
        useDefaultSearch: true,
        key: 'organico',
        byKey: async (key) => {
          console.log(key)
        },
        load: async (loadOptions) => {
          const paginInfo: PaginationInfo = {
            current: (loadOptions.skip ?? 0) / (loadOptions.take ?? 0) + 1,
            size: loadOptions.take ?? 10,
          }

          let pagedResult: PagedResult<OrganicoList> = {
            currentPage: 0,
            from: 0,
            to: 0,
            count: 0,
            result: [],
          }

          pagedResult = await getOrganicPaged(find, paginInfo)
          if (pagedResult.result.length === 0) {
            dispatch(setSearch(false))
          }
          return {
            data: pagedResult.result,
            totalCount: pagedResult.count,
          }
        },
      })
      const dataSource = new DataSource({
        totalCount: store.totalCount,
        paginate: true,
        pageSize: 10,
        store: store,
        key: 'organico',
        requireTotalCount: true,
        onChanged: () => {
          dispatch(setNroRegistrosEncontradas(dataSource.totalCount()))
        },
      })
      return dataSource
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return { getOrganicDatasource }
}


async function deleteOrganic(codigo: number): Promise<any> {

  const obj = {
    codigo
  }
  try {
    const data = await RequestHelper.deleteRequestAll<any>('admin/organico', 'inactive', '', obj)
    return data
  } catch (error) {
    return error
  }
}

async function saveOrganic(data: OrganicSave): Promise<any> {

  const obj = {
    ...data
  }
  try {
    const data = await RequestHelper.postAll<any>('admin/organico', 'save', obj)
    return data
  } catch (error) {
    return error
  }
}