import React from 'react'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/store'
import { DataGrid } from 'devextreme-react'
import { Column, Export, Pager, Paging } from 'devextreme-react/data-grid'
import { getAllowedPageSizes } from '../../../../../../helpers/Helper'
import ItemsCountIndicator from '../../../../../componentes/indicadorNroRegistros'
import { setCodeSelectedKardex } from '../../store/serachReducer'
import { isMobile } from 'react-device-detect'

const decimalPrecision: number = 4

const Item = () => {
  const dispatch = useDispatch()

  const resultsSerach = useSelector((state: RootState) => {
    return state.inventarios.kardex.search.resultItem
  })

  const dataGrid = React.useRef<any>()

  return (
    <RowContainer className="mt-2">
      <ItemsCountIndicator items={resultsSerach} />
      <DataGrid
        ref={dataGrid}
        keyExpr="codigo"
        focusedRowKey="codigo"
        id="tabKardexTodos"
        selection={{ mode: 'single' }}
        dataSource={resultsSerach}
        showColumnLines={true}
        showRowLines={true}
        showBorders={true}
        columnResizingMode="widget"
        focusedRowEnabled={true}
        onSelectionChanged={() => {}}
        loadPanel={{ enabled: true }}
        allowColumnResizing={true}
        onRowDblClick={({ data }) =>
          dispatch(setCodeSelectedKardex(data.codigo))
        }
      >
        <Paging defaultPageSize={20} />
        <Pager
          visible={resultsSerach.length > 0 ? true : false}
          allowedPageSizes={getAllowedPageSizes(resultsSerach)}
          displayMode={'full'}
          showPageSizeSelector={true}
          showInfo={true}
          showNavigationButtons={true}
        />
        <Export enabled={true} allowExportSelectedData={true} />
        <Column dataField="codigo" caption="Fecha" width="0%" visible={false} />
        <Column
          dataField="registro"
          caption="Fecha"
          width={isMobile ? '75px' : '10%'}
        />
        <Column
          dataField="itemCodigoUsuario"
          caption="Interno"
          width={isMobile ? '120px' : '10%'}
        />
        <Column
          dataField="kardexDescripcion"
          caption="Movimiento"
          width={isMobile ? '220px' : '20%'}
        />
        <Column
          dataField="localNombre"
          caption="Local"
          width={isMobile ? '150px' : '10%'}
        />
        <Column
          dataField="lineaDescripcion"
          caption="Línea"
          width={isMobile ? '200px' : '10%'}
        />
        <Column
          dataField="unidadNombre"
          caption="Unidad"
          width={isMobile ? '110px' : '10%'}
        />
        <Column caption="Ingresos" alignment="center">
          <Column
            dataField="cantIng"
            caption="Cantidad"
            width={isMobile ? '100px' : '10%'}
            format={{ precision: decimalPrecision }}
          />
          <Column
            dataField="precioUniIng"
            caption="P. Unitario"
            width={isMobile ? '100px' : '10%'}
          />
          <Column
            dataField="valorIng"
            caption="Valor"
            width={isMobile ? '100px' : '10%'}
          />
        </Column>
        <Column caption="Egresos" alignment="center">
          <Column
            dataField="cantEgr"
            caption="Cantidad"
            width={isMobile ? '100px' : '10%'}
          />
          <Column
            dataField="precioUniEgr"
            caption="P. Unitario"
            width={isMobile ? '100px' : '10%'}
          />
          <Column
            dataField="valorEgr"
            caption="Valor"
            width={isMobile ? '100px' : '10%'}
          />
        </Column>
        <Column caption="Stock" alignment="center">
          <Column
            dataField="saldo"
            caption="Cantidad"
            width={isMobile ? '100px' : '10%'}
          />
          <Column
            dataField="costo"
            caption="P. Unitario"
            width={isMobile ? '100px' : '10%'}
          />
          <Column
            dataField="totalX"
            caption="Valor"
            width={isMobile ? '100px' : '10%'}
          />
        </Column>
      </DataGrid>
    </RowContainer>
  )
}

export default React.memo(Item)
