

import React from 'react'
import { SmallLoadingIndicator } from '../../../../../../views/componentes/loadingindicator/loadingindicator'
import { consoleService } from '../../../../../../services/console.service'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../views/componentes/colContainer'
import { CBadge, CCard, CCardBody, CCardHeader, CFormInput } from '@coreui/react-pro'
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput'
import { payrollDepartamentServices } from '../../../../../componentes/cargosDepartamentosLookup/services/cargosDepartamentos.services'
import { tipoDiscapacidadService } from '../../../../../componentes/tipoDiscapacidadLookUp/services/tipoDiscapacidad.service'
import { getIndexProvider } from '../../../../../shared/helpers/funciones'
import { gruposPersonal } from '../nuevo/components/contrato'

const DetalleContrato: React.FunctionComponent<any> = (props) => {
  const { data } = props

  const [loading, setLoading] = React.useState(false)
  const [loaded, setLoaded] = React.useState(false)
  const [contract, setContract] = React.useState<any>({})

  const onLoadDetail = React.useCallback(async (contractData) => {
    setLoading(true)
    consoleService.log(contractData, 'detail contract')
    const departamento = await payrollDepartamentServices.getPyarollsList()
    const discapacidad = await tipoDiscapacidadService.getTipoDiscapacidad('Discapacidad')
    const detailData: any = {}
    if (departamento?.auto && departamento?.error === false) {
      const provider = departamento?.auto.slice(0)
      const index = await getIndexProvider(provider, 'codigo', contractData?.organico ?? 0)
      if (index > -1) {
        detailData.departamento = provider[index]?.descripcion ?? ''
      } else {
        detailData.departamento = provider[0]?.descripcion ?? ''
      }
    }

    if (discapacidad?.auto && discapacidad?.error === false) {
      const provider = discapacidad?.auto.slice(0)
      const index = await getIndexProvider(provider, 'descripcion', contractData?.discapacidad ?? '')
      if (index > -1) {
        detailData.discapacidad = provider[index]?.descripcion ?? ''
      } else {
        detailData.discapacidad = provider[0]?.descripcion ?? ''
      }
    }

    switch (contractData?.tipoPersonal) {
      case 1:
        detailData.personal = gruposPersonal[0]?.label
        break;
      case 2:
        detailData.personal = gruposPersonal[1]?.label
        break;
      case 3:
        detailData.personal = gruposPersonal[3]?.label
        break;

      default:
        break;
    }


    detailData.codigo = contractData?.codigo ?? 0
    detailData.usuarioCedula = contractData?.usuarioCedula ?? ''
    detailData.usuarioNombres = contractData?.usuarioNombres ?? ''
    detailData.usuarioApellidos = contractData?.usuarioApellidos ?? ''
    detailData.tipoDescripcion = contractData?.tipoDescripcion ?? ''
    detailData.horarioTrabajoDescripcion = contractData?.horarioTrabajoDescripcion ?? ''


    setContract(detailData)
    setLoading(false)
    setLoaded(true)
  }, [])

  React.useEffect(() => {
    onLoadDetail(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  if (loading) {
    return <SmallLoadingIndicator mensaje="Cargando información adicional del registro..." />
  }

  if (!loaded) {
    return <></>
  }

  return (
    <RowContainer>
      <CustomCol xs="12" md="10">
        <CCard
          className="border-top-primary border-top-3"
          id="busqueda-modVentas-resumen"
        >
          <CCardHeader>
            <span style={{ float: 'left' }}>
              <CBadge color="secondary" textColor="black">
                <strong>{`Código: ${contract?.codigo ?? 0}`}</strong>
              </CBadge>
            </span>
          </CCardHeader>
          <CCardBody>
            <RowContainer>
              <CustomCol sm="12" md="2">
                <Labeled label="Identificacion:">
                  <CFormInput
                    value={contract?.usuarioCedula ?? ''}
                    readOnly={true}
                    size="sm"
                  />
                </Labeled>
              </CustomCol>
              <CustomCol sm="12" md="2">
                <Labeled label="Nombres:">
                  <CFormInput value={contract?.usuarioNombres ?? ''} readOnly={true} size="sm" />
                </Labeled>
              </CustomCol>
              <CustomCol sm="12" md="2">
                <Labeled label="Apellidos:">
                  <CFormInput value={contract?.usuarioApellidos ?? ''} readOnly={true} size="sm" />
                </Labeled>
              </CustomCol>
              <CustomCol sm="12" md="2">
                <Labeled label="Tipo de Contrato:">
                  <CFormInput value={contract?.tipoDescripcion ?? ''} readOnly={true} size="sm" />
                </Labeled>
              </CustomCol>
              <CustomCol sm="12" md="2">
                <Labeled label="Horario:">
                  <CFormInput value={contract?.horarioTrabajoDescripcion ?? ''} readOnly={true} size="sm" />
                </Labeled>
              </CustomCol>
              <CustomCol sm="12" md="2">
                <Labeled label="Depatmaneto:">
                  <CFormInput value={contract?.departamento ?? ''} readOnly={true} size="sm" />
                </Labeled>
              </CustomCol>
            </RowContainer>
            <RowContainer>
              <CustomCol sm="12" md="2">
                <Labeled label="Discapacidad:">
                  <CFormInput value={contract?.discapacidad ?? ''} readOnly={true} size="sm" />
                </Labeled>
              </CustomCol>
              <CustomCol sm="12" md="2">
                <Labeled label="Tipo de Personal:">
                  <CFormInput value={contract?.personal ?? ''} readOnly={true} size="sm" />
                </Labeled>
              </CustomCol>
            </RowContainer>
          </CCardBody>
        </CCard>
      </CustomCol>
    </RowContainer>
  )
}

export default DetalleContrato