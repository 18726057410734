export type TypeDays = {
  codigo: number,
  valor: string
}

export const days: Array<TypeDays> = [
  { codigo: 0, valor: "Domingo" },
  { codigo: 1, valor: "Lunes" },
  { codigo: 2, valor: "Martes" },
  { codigo: 3, valor: "Miercoles" },
  { codigo: 4, valor: "Jueves" },
  { codigo: 5, valor: "Viernes" },
  { codigo: 6, valor: "Sabado" },
];