import { AccionMenu, OptionGrupos } from "../../../../store/types"
import { Cheque } from "../../../bancos/pages/cheques/store/types"
import { SeleccionadoTableItem } from "../../../bancos/store/types"
import { ModalOpen } from "../../../contabilidad/general/store/types"

export enum TIPOS_BOTONES {
  BOTON_NUEVO = 'Nuevo',
  BOTON_GUARDAR = 'Guardar',
  BOTON_BUSCAR = 'Buscar',
  BOTON_ELIMINAR = 'Eliminar',
  BOTON_IMPRIMIR = 'Imprimir',
  BOTON_DESHACER = 'Deshacer',
  BOTON_EDITAR = 'Editar',
  BOTON_ANULAR = 'Anular',
  BOTON_VERASIENTO = 'Ver asiento',
  BOTON_REFRESCAR = 'Refrescar',
  BOTON_EXPORTAR = 'Exportar',
}

export type FormIngresos = {
  valor_ti: number,
  valorsinretencion: number,
  total_ti: number,
  totales: number,
  total: number
}

export type ModalIngresoChequeState = {
  modulo: string,
  currentAction: string,
  currentFunction: string,
  aplicacion: string,
  acciones: Array<AccionMenu>,
  registros: Array<Cheque>,
  seleccionado: SeleccionadoTableItem,
  modal: ModalOpen,
  formasPagoSRI: Array<OptionGrupos>,
  formularioIngresoCheques: FormIngresos
}