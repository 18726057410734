import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../../store/store";
import { TiposComprobantesSri } from "../../../../../store/types";
import { ButtonTypes } from "../../../../../views/componentes/globalMenu/types";
import { StatesEdition, TabTypes } from "../../../types/enums";
import { TabsState, TabState } from "../../../types/generics";
import { RetencionventaListado } from "../types/types";

const initialState: TabsState<RetencionventaListado> = {
  current: "BUSQUEDA",
  tabs: {
    "BUSQUEDA":
    {
      id: 0,
      canClose: false,
      editStatus: StatesEdition.doesNotApply,
      globalButtonClick: ButtonTypes.none,
      tittle: "Búsqueda",
      type: TabTypes.busqueda,
      imprimir: false,
      tabKey: "BUSQUEDA",
      buttons:{}
    },
  },
};



const existeTab = (
  tabs: Array<TabState<RetencionventaListado>>,
  id: number
): number => {
  return tabs.findIndex((tab) => tab.id === id);
};

const tabsVentasSlice = createSlice({
  name: "retencionesVentasTabs",
  initialState: initialState,
  reducers: {
    closeTab(state, action: PayloadAction<string | number>) {
      // if (typeof action.payload == "number") {
      //   if (state.current === action.payload) {
      //     //current tab closed
      //     state.current = action.payload - 1;
      //   }
      //   state.tabs.splice(action.payload, 1);
      //   state.current = state.current + 0;
      // } else {
      //   const indexTabToClose = state.tabs.findIndex(
      //     (x) => x.tabKey === action.payload
      //   );
      //   if (state.current === indexTabToClose) {
      //     //current tab closed
      //     state.current = indexTabToClose - 1;
      //   }
      //   state.tabs.splice(indexTabToClose, 1);
      //   state.current = state.current + 0;
      // }
    },
    openTab(
      state,
      action: PayloadAction<{
        key: string;
        retencion: RetencionventaListado | null;
      }>
    ) {
      // if (action.payload.retencion == null) {
      //   const tab = {
      //     id: -1,
      //     canClose: true,
      //     editStatus: StatesEdition.new,
      //     globalButtonClick: ButtonTypes.none,
      //     tittle: "Nueva",
      //     type: TabTypes.documento,
      //     imprimir: false,
      //     tabKey: action.payload.key,
      //     info: {
      //       claveAcceso: "",
      //       numero: 0,
      //       tipoComprobante: TiposComprobantesSri.Factura,
      //       canSendMail: false,
      //       canSendAutorizacion: false,
      //       canInactive: false
      //     },
      //   };
      //   state.tabs.push(tab);
      //   state.current = state.tabs.length - 1;
      // } else {
      //   const idTab = existeTab(state.tabs, action.payload.retencion.codigo);
      //   if (idTab > 0) {
      //     state.current = idTab;
      //   } else {
      //     const tab: TabState<RetencionventaListado> = {
      //       tabKey: action.payload.key,
      //       id: action.payload.retencion.codigo,
      //       tittle: `${action.payload.retencion.establecimiento}-${action.payload.retencion.puntoEmision}-${action.payload.retencion.numero}`,
      //       canClose: true,
      //       editStatus: StatesEdition.save,
      //       type: TabTypes.documento,
      //       info: {
      //         claveAcceso: action.payload.retencion.autorizacion,
      //         numero: action.payload.retencion.codigo,
      //         tipoComprobante: TiposComprobantesSri.Retencion,
      //         info: action.payload.retencion,
      //         canSendMail: false,
      //         canSendAutorizacion: false,
      //         canInactive: action.payload.retencion.estadoCodigo !== 2
      //       },
      //       globalButtonClick: ButtonTypes.none,
      //       imprimir: false,
      //     };
      //     state.tabs.push(tab);
      //     state.current = state.tabs.length - 1;
      //   }
      // }
    },
    changeEditStatus(
      state,
      action: PayloadAction<{
        tabId: number;
        estado: StatesEdition;
        info: RetencionventaListado;
      }>
    ) {
      // state.tabs[action.payload.tabId].editStatus = action.payload.estado;
      // if (action.payload.info) {
      //   state.tabs[action.payload.tabId].info = {
      //     claveAcceso: action.payload.info.autorizacion,
      //     numero: action.payload.info.codigo,
      //     tipoComprobante: TiposComprobantesSri.Factura,
      //     info: action.payload.info ?? null,
      //     canSendMail: false,
      //     canSendAutorizacion: false,
      //     canInactive: action.payload.info.estadoCodigo !== 2
      //   };
      //   state.tabs[action.payload.tabId].id = action.payload.info.codigo;
      //   state.tabs[
      //     action.payload.tabId
      //   ].tittle = `${action.payload.info.establecimiento}-${action.payload.info.puntoEmision}-${action.payload.info.numero}`;
      // }
    },
    buttonClick(
      state,
      action: PayloadAction<{ tabId: string; button: ButtonTypes }>
    ) {
      state.tabs[action.payload.tabId].globalButtonClick =
        action.payload.button;
    },
    clearButtonClick(state, action: PayloadAction<string>) {
      // if (action.payload < state.tabs.length) {
      //   state.tabs[action.payload].globalButtonClick = ButtonTypes.none;
      // }
    },
    changeCurrentTab(state, action: PayloadAction<number>) {
      // if (action.payload >= state.tabs.length) return;
      // state.current = action.payload;
    },
  },
});

export const selectTabsventas = (state: RootState) => {
  return state.ventas.ventas.tabs;
};
export const {
  closeTab,
  openTab,
  changeCurrentTab,
  changeEditStatus,
  clearButtonClick,
  buttonClick,
} = tabsVentasSlice.actions;
export const tabsReducer = tabsVentasSlice.reducer;
