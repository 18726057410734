import React from 'react'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput'
import { Button, TextBox, ValidationGroup, ValidationSummary, Validator } from 'devextreme-react'
import { GruposDatosEdicion } from '../../types/types'
import { ButtonTypes } from '../../../../../../../views/componentes/globalMenu/types'
import { clearButtonClick, setButtons, setCurrentExecutingAction, setNameTabe } from '../../store/tabsReducer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import TipoEstadoLookUp from '../../../../../../componentes/tipoEstado'
import {
  changeLoaderTab,
  setCurrentFunction,
} from '../../store/generalReducer'
import { setDatosEdicion } from '../../store/editDataReducer'
import Dialog from '../../../../../../../views/componentes/librerias/bootstrap-dialog'
import MarcasWebLookUp from '../../../../../../componentes/marcasWebLookUp'
import { GrupoInventario, LineaInventario } from '../../../../../store/types'
import { ModalAdminGrupos } from '../../../../../components/buscarGrupo/modalAdminGrupos/modaAdminGrupos'
import { TabState } from '../../../../../../../store/genericTabTypes'
import { MarcaWeb } from '../../../../../../componentes/marcasWebLookUp/types/types'
import { AsyncRule, RequiredRule } from 'devextreme-react/validator'
import validations from '../../../../../../../config/validations'
import { MessagesKeys, lh } from '../../../../../../../helpers/localizationHelper'
import { DocumentInfo, ToastTypes } from '../../../../../../../store/types'
import { addToast } from '../../../../../../../store/toasterReducer'
import { InventarioService } from '../../../../../services/inventario.service'
import { StatesEdition } from '../../../../../../ventas/types/enums'
import { tipoEstadoService } from '../../../../../../componentes/tipoEstado/service/tipoEstado.service'
import BlockUi from '../../../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../../../views/componentes/loadingindicator/loadingindicator'
import { isMobile } from 'react-device-detect'
import { CLink, CTooltip } from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilInfo } from '@coreui/icons'
import VisualizaError from '../../../../../../ventas/pages/shared/visualizaError/visualizaError'
import ListErrosValidationForm from '../../../../../../componentes/listadoErroresValidacionForm'
import { marcasWebServices } from '../../../../../../componentes/marcasWebLookUp/services/marcasWeb.services'
import { ModuleButtons } from '../../index';
import { setDatosEdicionBackup } from '../../store/configReducer'

interface INewProps extends React.PropsWithChildren {
  info: DocumentInfo<GruposDatosEdicion>
  tab: TabState<GruposDatosEdicion>
  tabId: string
}

const Nuevo: React.FunctionComponent<INewProps> = (props) => {
  const { tab, tabId, info } = props
  const dispatch = useDispatch()
  const sms = validations['es']
  const groupState = useSelector((state: RootState) => {
    return state.inventarios.catalogos.grupos.editData[tabId]
  })
  const groupStateBackup = useSelector((state: RootState) => {
    return state.inventarios.catalogos.grupos.config[tabId]
  })
  const loading = useSelector((state: RootState) => {
    return state.inventarios.catalogos.grupos.editData[tabId].loader
  })
  const loader = useSelector((state: RootState) => {
    return state.inventarios.catalogos.grupos.general.loaderTab
  })
  const tabs = useSelector((state: RootState) => {
    return state.inventarios.catalogos.grupos.tabs
  })
  const [arrayErrors, setArrayErrors] = React.useState<Array<string>>([])
  const [showErrorPopup, setShowErrorPopup] = React.useState<boolean>(false)
  const [showModalGroup, setShowModalGroup] = React.useState<boolean>(false)
  const [marcasWeb, setMarcasWeb] = React.useState<Array<MarcaWeb>>([])
  const [marcaWebText, setMarcaWebText] = React.useState<string>(null)
  const [marcaWeb, setMarcaWeb] = React.useState<MarcaWeb>(null)
  const validationGroupRef = React.useRef<any>()
  const dialogRef = React.useRef<any>(null)

  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(
      addToast({
        title: 'Inventario - Grupos',
        content: mensaje,
        type: tipo,
      }),
    )
  }, [dispatch])

  const setButtonExecuttion = React.useCallback((tipo: ButtonTypes | undefined) => {
    dispatch(
      setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: tipo,
      }),
    )
  }, [dispatch, tabId])

  const playLoader = React.useCallback(
    (message = 'Cargando...') => {
      dispatch(changeLoaderTab({ show: true, mensaje: message }))
    },
    [dispatch],
  )

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoaderTab({ show: false, mensaje: '' }))
  }, [dispatch])

  const getEstado = React.useCallback(async (codigo: number) => {
    try {
      const data = await tipoEstadoService.getTipoEstado('Elija un estado')
      if (data !== null && data !== undefined && data['error'] === false) {
        const estado = data['auto'].find(item => item.codigo === codigo)
        return estado
      }
      return null
    } catch (error) {
      return null
    }
  }, [])

  const getMarcasWeb = React.useCallback(async (codigo: number) => {
    try {
      const data = await marcasWebServices.getWebBrands()
      if (data !== null && data !== undefined && data.length > 0) {
        const marcasWeb = data['auto'].find(item => item.codigo === codigo)
        return marcasWeb
      }
      return null
    } catch (error) {
      return null
    }
  }, [])


  const modoNuevo = React.useCallback(async (template: number, limpiar: boolean = false) => {
    if (loading === false && !limpiar) {
      return
    }
    setButtonExecuttion(ButtonTypes.new)
    playLoader()
    setMarcaWebText(null)
    setMarcaWeb(null)
    const data = { ...defaultDataGroups }
    const estado = await getEstado(1)
    data.estado = estado
    dispatch(setDatosEdicion({
      key: tabId,
      grupo: data
    }))
    dispatch(setDatosEdicionBackup({
      key: tabId,
      grupo: data
    }))
    stopLoader()
    setButtonExecuttion(undefined)

  }, [loading, dispatch, getEstado, tabId, playLoader, stopLoader, setButtonExecuttion])

  const cargarGrupo = React.useCallback(async (loder: boolean, grupoData) => {
    if (loder === false) {
      return
    }
    setButtonExecuttion(ButtonTypes.edit)
    playLoader('Cargando Grupo...')
    const estado = await getEstado(grupoData?.estadoCodigo)
    const marWeb = await getMarcasWeb(grupoData?.marcaWeb)
    const pertenece: LineaInventario = {
      codigo: parseInt(grupoData?.perteneceCodigo) ?? 0,
      descripcion: grupoData?.perteneceNombre ?? "",
    }
    const data = { ...groupState }
    data.descripcion = grupoData?.descripcion,
      data.categoriaWeb = null,
      data.categoriaWebLookup = marWeb,
      data.pertenece = pertenece
    data.estado = estado
    data.guardado = false
    data.loader = false
    dispatch(setDatosEdicion({
      key: tabId,
      grupo: { ...data }
    }))
    dispatch(setDatosEdicionBackup({
      key: tabId,
      grupo: { ...data }
    }))
    stopLoader()
    setButtonExecuttion(undefined)
  }, [dispatch, getEstado, getMarcasWeb, groupState, playLoader, setButtonExecuttion, tabId, stopLoader])




  const onChangeData = React.useCallback((data, key: string) => {
    if (data !== null && data !== undefined) {
      let value = null
      if (data?.value !== null && data?.value !== undefined) {
        value = data.value
      } else {
        value = data
      }
      if (key === 'categoriaWebLookup') {
        setMarcaWeb(value)
      } else if (key === 'pertenece') {
        const data: LineaInventario = {
          codigo: parseInt(value?.perteneceCodigo) ?? 0,
          descripcion: value?.perteneceNombre ?? '',
        }
        value = data
      }
      if (value !== null && value !== undefined) {
        dispatch(setDatosEdicion({
          key: tabId,
          grupo: {
            ...groupState,
            [key]: value
          }
        }))
      }
    }
  }, [dispatch, groupState, tabId])

  const onValidate = React.useCallback(() => {
    const arrayErrors: Array<string> = []
    if (groupState.descripcion === null || groupState.descripcion === undefined || groupState.descripcion === '') {
      arrayErrors.push('Descripción: La Descripción es requerida')
    }
    if (groupState.estado === null || groupState.estado === undefined || groupState.estado?.codigo <= 0) {
      arrayErrors.push('Estado: Elija un estado del grupo.')
    }
    return arrayErrors
  }, [groupState])


  const onHandleSave = React.useCallback(async () => {
    let arrayErrors: Array<string> = []
    const result = validationGroupRef.current.instance.validate()
    arrayErrors = onValidate()
    if (result.isValid && arrayErrors.length === 0) {
      let catwEB = 0
      if (marcasWeb.length > 0) {
        catwEB = marcaWeb?.codigo ?? 0
      }
      try {
        const data_save = {
          codigo: groupState?.codigo ?? 0,
          descripcion: groupState?.descripcion ?? '',
          pertenece: groupState?.pertenece?.codigo ?? 0,
          estado: groupState?.estado?.codigo ?? -1,
          categoriaWeb: catwEB,
        }
        setButtonExecuttion(ButtonTypes.save)
        playLoader('Guardando...')
        const data = await InventarioService.createGrupo(data_save)
        stopLoader()
        setButtonExecuttion(undefined)
        if (data !== null && data !== undefined && data['error'] === false) {
          dispatch(setNameTabe({
            key: tabId,
            codigo: data['auto'],
            nombre: groupState?.descripcion
          }))
          dispatch(
            setButtons({
              tabKey: tabId,
              buttons: {
                ...ModuleButtons,
                Guardar: false
              },
            }),
          )
          setToast(data['message'], ToastTypes.Success)
        } else {
          setToast(data['message'], ToastTypes.Danger)
        }
      } catch (error) {
        stopLoader()
        setButtonExecuttion(undefined)
        setToast(error.message, ToastTypes.Danger)
      }
    } else {
      setArrayErrors(arrayErrors)
      setShowErrorPopup(true)
      setToast(lh.getMessage(MessagesKeys.GlobalErrorSave), ToastTypes.Warning)
    }
  }, [groupState, setToast, marcasWeb, marcaWeb, playLoader, stopLoader, dispatch, tabId, setButtonExecuttion, onValidate])

  const onBroom = React.useCallback(() => {
    dispatch(setDatosEdicion({
      key: tabId,
      grupo: { ...defaultDataGroups }
    }))
  }, [dispatch, tabId])

  const onUndo = React.useCallback(() => {
    setMarcaWebText('')
    setMarcaWeb(null)
    dispatch(setDatosEdicion({
      key: tabId,
      grupo: { ...groupStateBackup }
    }))
  }, [dispatch, tabId, groupStateBackup])


  const handleButtonClick = React.useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.save:
          if (tabs.current === tabId) onHandleSave()
          break
        case ButtonTypes.broom:
          if (tabs.current === tabId) {
            onBroom()
          }
          break
        case ButtonTypes.undo:
          if (tabs.current === tabId) {
            onUndo()
          }
          break
        default:
          break
      }
      dispatch(setCurrentFunction(''))
      dispatch(clearButtonClick(tabId))
    },
    [dispatch, onHandleSave, tabId, tabs, onBroom, onUndo],
  )

  const validateAsyncSelect = React.useCallback((datoSeleccion: any, message: string) => {
    if (datoSeleccion.value.codigo > -1) {
      return Promise.resolve()
    } else {
      return Promise.reject(message)
    }
  }, [])

  const validateAsyncSeleccionTipoId = React.useCallback((datoSeleccion) => {
    return validateAsyncSelect(datoSeleccion, `Estado: Debe seleccionar una opción.`)
  }, [validateAsyncSelect])

  const onFilterMarcaWeb = React.useCallback((data) => {
    if (data.value !== null && data.value !== undefined) {
      const marcaWeb: string = data.value
      setMarcaWebText(marcaWeb)
      if (marcasWeb !== null && marcasWeb !== undefined && marcasWeb.length > 0) {
        let newArayy = []
        newArayy = marcasWeb.map(item => {
          if (item?.descripcion && item?.descripcion.indexOf(marcaWeb) > 0) {
            return item
          }
        })
        setMarcasWeb(newArayy)
      }
    }
  }, [marcasWeb])

  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab.globalButtonClick])

  React.useEffect(() => {
    if (tab.editStatus === StatesEdition.new) {
      modoNuevo(tab?.info?.codigo)
    } else {
      cargarGrupo(loading, info.info)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {showModalGroup && (
        <ModalAdminGrupos
          onChanged={(group: GrupoInventario) => {
            onChangeData(group, 'pertenece')
            setShowModalGroup(false)
          }}
          onCancel={() => setShowModalGroup(false)}
          show={showModalGroup}
        />
      )}
      {showErrorPopup && (
        <VisualizaError
          titulo="Error en Grupos"
          mensaje={'Error al guardar'}
          content={
            <ListErrosValidationForm
              mainMessage={'Revise los siguientes errores:'}
              errorsList={arrayErrors}
              color={'danger'}
            />
          }
          onOk={() => setShowErrorPopup(false)}
        />
      )}
      <Dialog ref={dialogRef} />
      <div style={{ overflowX: 'hidden' }}>
        <BlockUi
          tag="div"
          loader={LoadingIndicator}
          blocking={loader.show}
          message={loader.mensaje}
        >
          <RowContainer className="m-2">

            <ValidationGroup id={'formLineas'} ref={validationGroupRef}>
              {
                !isMobile && (
                  <RowContainer>
                    <CustomCol lg="6" md="8" sm="12">
                      <ValidationSummary id="summary"></ValidationSummary>
                    </CustomCol>
                  </RowContainer>
                )
              }
              <RowContainer>
                <CustomCol xs="12" md="3">
                  <Labeled label="Descripción:">
                    <TextBox
                      value={groupState?.descripcion ?? ''}
                      onValueChanged={(data) => onChangeData(data, 'descripcion')}
                    >
                      <Validator>
                        <RequiredRule message={'Nombre: ' + sms['required']} />
                      </Validator>
                    </TextBox>
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="4">
                  <Labeled label="Categoría Web:">
                    <TextBox
                      value={marcaWebText ?? ''}
                      onValueChanged={(data) => onFilterMarcaWeb(data)}
                    />
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="4">
                  <Labeled label="Pertence:">
                    <div
                      style={{
                        display: 'flex',
                        gap: '2px',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <TextBox
                        value={groupState.pertenece?.descripcion ?? ''}
                        showClearButton={true}
                        width="100%"
                        inputAttr={{ autocomplete: 'nope' }}
                      />
                      <Button
                        id="btnAdd"
                        className="me-1"
                        icon="add"
                        stylingMode="contained"
                        type="default"
                        onClick={() => setShowModalGroup(true)}
                      />
                      <CTooltip
                        key={'tool-more-auth-side'}
                        placement="top"
                        content={
                          'Si deja el campo vacio el sistema interpretará que pertenece al grupo principal.'
                        }
                      >
                        <div className="card-header-actions">
                          <CLink className="card-header-action m-0">
                            <CIcon icon={cilInfo} className="ms-1 me-1" />
                          </CLink>
                        </div>
                      </CTooltip>
                    </div>
                  </Labeled>
                </CustomCol>
              </RowContainer>
              <RowContainer>
                <CustomCol xs="12" md="3">
                  <Labeled label="Estado:">
                    <TipoEstadoLookUp onChanged={(data) => onChangeData(data, 'estado')} selected={groupState?.estado ?? null} >
                      <Validator>
                        <RequiredRule message={'Estado: Debe seleccionar una opción'} />
                        <AsyncRule
                          message={'Estado: Debe seleccionar una opción'}
                          validationCallback={validateAsyncSeleccionTipoId}
                        />
                      </Validator>
                    </TipoEstadoLookUp>
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="3" style={{ marginTop: 'auto' }}>
                  <Labeled label=''>
                    <MarcasWebLookUp
                      selected={marcaWeb ?? null}
                      onChanged={(data) => onChangeData(data, 'categoriaWebLookup')}
                      onChangedOptions={(data) => setMarcasWeb(data)}
                      provider={marcasWeb}
                    />
                  </Labeled>
                </CustomCol>
              </RowContainer>
              {
                isMobile && (
                  <RowContainer>
                    <CustomCol lg="6" md="8" sm="12">
                      <ValidationSummary id="summary"></ValidationSummary>
                    </CustomCol>
                  </RowContainer>
                )
              }
            </ValidationGroup>
          </RowContainer>
        </BlockUi>
      </div>
    </>
  )
}

export default React.memo(Nuevo)

export const defaultDataGroups: GruposDatosEdicion = {
  codigo: 0,
  descripcion: '',
  categoriaWeb: '',
  categoriaWebLookup: null,
  pertenece: null,
  estado: null,
  guardado: false,
  loader: false,
}
