import { combineReducers } from '@reduxjs/toolkit'
import { TModuloComprasSV } from '../types/types'
import { retencionesSVReducer } from '../pages/retenciones/store/retencionesReducer'
import { liquicionesSVReducer } from '../pages/liquidaciones/store/liquidacionesReducer'
import { sujetoExcluidoReducer } from '../pages/sujetoExcluido/store/sujetoExcluidoReducer'
import { compraSVReducer } from '../pages/compras/store/compraSVReducer'

export const moduloComprasSVReducer = combineReducers<TModuloComprasSV>({
  retenciones: retencionesSVReducer,
  liquidacionesCompras: liquicionesSVReducer,
  sujetoExcluido: sujetoExcluidoReducer,
  compras: compraSVReducer,
})
