import React, { FunctionComponent, PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../views/componentes/colContainer'
import { DataGrid } from 'devextreme-react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/store'
import { Column, Export, Pager, Paging, Button as DatagridButton } from 'devextreme-react/data-grid'
import { allowedPageSizes } from '../../../../../../helpers/Helper'
import DataSource from 'devextreme/data/data_source'
import ItemsCountIndicator from '../../../../../componentes/indicadorNroRegistros'
import { consoleService } from '../../../../../../services/console.service'
import { setCollapsed, setSeleccionarDato } from '../../store/searchReducer'
import { Workbook } from 'exceljs'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { saveAs } from 'file-saver';
import { isMobile } from 'react-device-detect'
import { ActionSheetCommandsMobile } from './ActionSheetCommandsMobile'
import { OrganicoList } from '../../types/types'

interface IResultsProps extends PropsWithChildren {
  onDBClick: () => void
  // eslint-disable-next-line no-unused-vars
  handleOptionChange: (e: any) => void
  data: DataSource
  pageIndex: number
  pageSize: number
  onDelete: () => void
}


const ResultadosBusqueda: FunctionComponent<IResultsProps> = (props) => {
  const { onDBClick, data, handleOptionChange, pageIndex, pageSize, onDelete } =
    props

  const dispatch = useDispatch()
  const totalCountHpurs = useSelector((state: RootState) => state.nomina.organico.search.nroRegistrosEncontrados)
  const tablaRefPager = useRef<any>()
  const [isPagin] = useState<boolean>(true)



  const onCollapsed = useCallback(async () => {
    if (totalCountHpurs > 5) {
      dispatch(setCollapsed(false))
    }
  }, [dispatch, totalCountHpurs])

  useEffect(() => {
    onCollapsed()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCountHpurs])

  const onSelectedChanged = useCallback(
    ({ selectedRowsData }) => {
      consoleService.log(selectedRowsData[0], 'seleccionado selectedRowsData[0]')
      if (selectedRowsData[0] && selectedRowsData[0]?.organico) {
        dispatch(
          setSeleccionarDato({ ...selectedRowsData[0] }),
        )
      }
    },
    [dispatch],
  )

  const onExportExcell = useCallback((e) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('Main sheet')
    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'Organigrama_Nomina.xlsx',
        )
      })
    })
  }, [])

  const actionSheetCommandsMobile = React.useCallback(
    (cellData) => {
      const data = cellData.data as any
      return (
        <ActionSheetCommandsMobile
          organic={data}
          key={data.codigo}
          onDelete={(data) => {
            dispatch(setSeleccionarDato({ ...data }))
            onDelete()
          }}
          onEdit={(data) => {
            dispatch(setSeleccionarDato({ ...data }))
            onDBClick()
          }}
        />
      )
    },
    [onDelete, dispatch, onDBClick],
  )

  return (
    <>
      <ItemsCountIndicator numberOfItems={data ? totalCountHpurs : 0} />
      <RowContainer>
        <CustomCol xs="12" md="12">
          <DataGrid
            id="tablaOrganico"
            ref={tablaRefPager}
            dataSource={data ?? []}
            selection={{ mode: 'single' }}
            hoverStateEnabled={true}
            showBorders={true}
            showRowLines={true}
            focusedRowEnabled={true}
            onSelectionChanged={onSelectedChanged}
            onRowDblClick={onDBClick}
            repaintChangesOnly={true}
            highlightChanges={true}
            keyExpr="uuid"
            key="uuid"
            focusedRowKey="uuid"
            showColumnLines={true}
            onExporting={onExportExcell}
            className={'m-2 p-2'}
            onOptionChanged={handleOptionChange}
            remoteOperations={true}
            loadPanel={{
              enabled: true,
              height: 90,
              indicatorSrc: '',
              shading: false,
              shadingColor: '',
              showIndicator: true,
              showPane: true,
              text: 'Cargando...',
              width: 200,
            }}
          >
            <Paging
              pageIndex={pageIndex}
              pageSize={pageSize}
              enabled={isPagin}
            />
            <Pager
              ref={tablaRefPager}
              showPageSizeSelector={true}
              allowedPageSizes={allowedPageSizes}
              showNavigationButtons={true}
              showInfo={true}
              infoText="Page #{0}. Total: {1} ({2} items)"
            />
            <Export enabled={true} allowExportSelectedData={true} />
            {
              !isMobile && (
                <Column allowEditing={false} type="buttons" width="50px">
                  <DatagridButton
                    icon="trash"
                    hint="Eliminar"
                    onClick={async (e) => {
                      consoleService.log('e', e)
                      const data: OrganicoList = e.row.data
                      dispatch(setSeleccionarDato({ ...data }))
                      onDelete()
                    }}
                  />
                  <DatagridButton
                    icon="edit"
                    hint="Editar"
                    onClick={async (e) => {
                      consoleService.log('e', e)
                      const data: OrganicoList = e.row.data
                      dispatch(setSeleccionarDato({ ...data }))
                      onDBClick()
                    }}
                  />
                </Column>
              )
            }
            {isMobile && (
              <Column
                allowEditing={false}
                caption="Tareas"
                dataField="codigo"
                cellRender={actionSheetCommandsMobile}
                width="50px"
                allowFiltering={false}
              ></Column>
            )}
            <Column dataField="descripcion" caption="Descripción" width="100%" />
            <Column dataField="siglas" caption="Siglas" width="50%" />
            <Column dataField="padreNombre" caption="Pertenece a" width="100%" />
          </DataGrid>
        </CustomCol>
      </RowContainer>
    </>
  )
}

export default React.memo(ResultadosBusqueda)