import React, { useEffect, useState } from 'react'
import { CCard, CCardBody, CCol, CRow } from '@coreui/react-pro'
import { useDispatch } from 'react-redux'
import { changeLoader } from '../../../../../../store/reducers'
import Modalform from '../../../../../../views/componentes/modalform'
import { DataGrid } from 'devextreme-react'
import { useSetToast } from '../../../../../../hooks/useGlobalHooks'
import { ToastTypes } from '../../../../../../store/types'
import { Column, Pager, Paging } from 'devextreme-react/data-grid'
import { getAllowedPageSizes } from '../../../../../../helpers/Helper'
import { ProveedorKdx } from '../../../kardex/types/types'
import { KardexServices } from '../../../kardex/services/kardex.services'
import ItemsCountIndicator from '../../../../../componentes/indicadorNroRegistros'

type ModalProveesoresItemProps = {
  show: boolean
  onClose: () => void
  code: number
}

const ModalProveedoresKdx = (props: ModalProveesoresItemProps) => {
  const dispatch = useDispatch()
  const setToastMessage = useSetToast()

  const [proveedores, setProveedores] = useState<Array<ProveedorKdx>>([])
  const tablaRef = React.useRef<any>()
  const tablaRefPager = React.useRef<any>()

  const playLoader = React.useCallback(
    (message: string) => {
      dispatch(changeLoader({ show: true, mensaje: message }))
    },
    [dispatch],
  )

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoader({ show: false, mensaje: '' }))
  }, [dispatch])

  const closeModal = React.useCallback(() => {
    props.onClose()
  }, [props])

  const onSearchData = React.useCallback(async () => {
    try {
      playLoader('')
      const suppliers = await KardexServices.getSupplierByItem(props.code)
      stopLoader()
      if (!suppliers.error && suppliers.auto) {
        if (suppliers.auto.length === 0) closeModal()
        setToastMessage(
          'Buscar Proveedores Por Ítem',
          suppliers.message,
          ToastTypes.Success,
        )
        setProveedores(suppliers.auto)
      } else
        setToastMessage(
          'Buscar Proveedores Por Ítem',
          suppliers.message ?? 'Error al buscar proveedores',
          ToastTypes.Warning,
        )
    } catch (error) {
      stopLoader()
      setToastMessage('Buscar Proveedores Por Ítem', error, ToastTypes.Danger)
    }
  }, [closeModal, playLoader, props.code, setToastMessage, stopLoader])

  useEffect(() => {
    onSearchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const bodyModalForm = () => {
    return (
      <>
        <ItemsCountIndicator items={proveedores} />
        <DataGrid
          ref={tablaRef}
          selection={{ mode: 'single' }}
          className="border"
          dataSource={proveedores ?? []}
          width={'100%'}
          keyExpr=""
        >
          <Paging enabled={true} defaultPageSize={10} />
          <Pager
            ref={tablaRefPager}
            showPageSizeSelector={true}
            allowedPageSizes={getAllowedPageSizes(proveedores)}
            showNavigationButtons={true}
            showInfo={true}
          />
          <Column dataField="codigo" caption="Código" width="15%" />
          <Column dataField="nombre" caption="Nombre" width="35%" />
          <Column
            dataField="razonComercial"
            caption="Razón Comercial"
            width="50%"
          />
        </DataGrid>
      </>
    )
  }

  const renderBody = () => {
    return (
      <>
        <CCard key="cardModalProveedoresKardex">
          <CCardBody>{bodyModalForm()}</CCardBody>
        </CCard>
      </>
    )
  }

  return (
    <CRow key="rowMain">
      <CCol key="colMain">
        <Modalform
          name="modalProveedoresKardex"
          key="modalProveedoresKardex"
          headerTitle={'Proveedores'}
          childrenBody={renderBody()}
          childrenFooter={<></>}
          closeOnBackdrop={false}
          show={props.show}
          onClose={closeModal}
          size="lg"
        />
      </CCol>
    </CRow>
  )
}

export default React.memo(ModalProveedoresKdx)
