import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DateUtils } from '../../../../../helpers/dateUtils'
import { RootState } from '../../../../../store/store'
import { FETCH_STATUS } from '../../../../../store/types'
import { RetencionCompraService } from '../../../services/retencionCompra.service'
import {
  FiltroBusquedaState,
  RetencionInfo,
  SearchState,
} from '../../../types/types'

const initialState: SearchState<RetencionInfo> = {
  filter: {
    fechaInicio: DateUtils.getCurrentDateAsString(),
    fechaFin: DateUtils.getCurrentDateAsString(),
    puntoVenta: '',
    activas: false,
    documento: '',
    pendientesAbutorizar: false,
    proveedor: null,
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  resultados: [],
}

export const fetchRetenciones = createAsyncThunk<
  Array<RetencionInfo>,
  FiltroBusquedaState
>('retenciones/searchRetenciones', async (filtro) => {
  try {
    const retenciones = RetencionCompraService.buscarRetenciones(
      filtro.fechaInicio,
      filtro.fechaFin,
      filtro,
    )
    return retenciones
  } catch (error) {
    return Promise.reject(error)
  }
})

const searchRetencionesSlice = createSlice({
  name: 'searchRetenciones',
  initialState: initialState,
  reducers: {
    resetState(state) {
      state = {
        ...initialState,
        filter: {
          ...initialState.filter,
          fechaInicio: DateUtils.getCurrentDateAsString(),
          fechaFin: DateUtils.getCurrentDateAsString(),
        },
      }
    },
    changeFilter(state, action: PayloadAction<FiltroBusquedaState>) {
      state.filter = action.payload
    },
    setCleanResult(state) {
      state.resultados = []
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRetenciones.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchRetenciones.fulfilled, (state, { payload }) => {
      state.resultados = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchRetenciones.rejected, (state, { payload }) => {
      state.resultados = []
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
  },
})

export const selectFiltroBusquedaRetenciones = (state: RootState) => {
  return state.compras.retenciones.search.filter
}
export const selectResultadosBusquedaRetenciones = (state: RootState) => {
  return state.compras.retenciones.search.resultados
}
export const selectBusquedaRetencionesEstado = (state: RootState) => {
  return state.compras.retenciones.search.status
}
export const { changeFilter, resetState, setCleanResult } =
  searchRetencionesSlice.actions
export const searchRetencionesReducer = searchRetencionesSlice.reducer
