import { RequestHelper } from "../../../../helpers/requestHelper"


export const kitsPartsServices = {
  getAllKitsParts,
  saveKitsParts,
  loadKitsParts,
}

async function getAllKitsParts(data: any): Promise<any> {
  try {
    const obj = {
      agrupar: data['agrupar'],
      descripcion: data['descripcion'],
      codigo: data['codigo'],
    }

    const dataApi = await RequestHelper.getAll<any>('inventario/catalogos/fichaTecnica', 'kitspartes/getAll', '', obj)
    return dataApi
  } catch (error) {
    return error
  }
}
async function loadKitsParts(): Promise<any> {
  try {
    const obj = {}

    const dataApi = await RequestHelper.getAll<any>('inventario/catalogos/fichaTecnica', 'kitspartes/loadAll', '', obj)
    return dataApi
  } catch (error) {
    return error
  }
}

async function saveKitsParts(data: any): Promise<any> {
  try {
    const obj = {
      ...data
    }

    const dataApi = await RequestHelper.postAll<any>('inventario/catalogos/fichaTecnica', 'kitspartes/save', obj)
    return dataApi
  } catch (error) {
    return error
  }
}