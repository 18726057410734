import React from 'react'
import SelectBox from 'devextreme-react/select-box'
import { tiposImpuestodServices } from './services/tiposImpuestos.service'
import { OptionTiposImpuestos } from './types/types'
import { v4 as uuidv4 } from 'uuid'

interface TiposImpuestosLookUpProps extends React.PropsWithChildren {
  selected: OptionTiposImpuestos | null
  // eslint-disable-next-line no-unused-vars
  onChanged: (newValue: OptionTiposImpuestos | null) => void
  // eslint-disable-next-line no-unused-vars
  onChangedOptions?: (newValue: Array<OptionTiposImpuestos> | []) => void
  disabled?: boolean
}

const TiposImpuestosLookUp: React.FC<TiposImpuestosLookUpProps> = (props) => {
  const { selected, onChanged, disabled, onChangedOptions } = props
  const selectRef = React.useRef<any>()
  const [options, setOptions] = React.useState<
    Array<OptionTiposImpuestos> | []
  >([])

  const loadDatos = React.useCallback(async () => {
    const data = await tiposImpuestodServices.getTipos()
    console.log('loadDatos', data)

    if (data.error === false) {
      let optionsLookup = []
      if (data.auto.length > 0) {
        optionsLookup = data.auto.map((option) => {
          return {
            codigo: Number(option?.codigo) ?? 0,
            descripcion: option?.descripcion ?? '',
            valor: Number(option?.valor) ?? 0,
          }
        })
      }
      setOptions(optionsLookup)
      if (onChangedOptions) {
        onChangedOptions(optionsLookup)
      }
      const selectedIndex = optionsLookup.findIndex((option) => {
        return option.codigo === selected?.codigo
      })
      onChanged(optionsLookup[selectedIndex] ?? optionsLookup[0])
    }
  }, [onChanged, onChangedOptions, selected])

  const getItemDisplayExpr = React.useCallback((item: any) => {
    return item ? `${item.descripcion}` : ''
  }, [])

  const onLookUpValueChanged = React.useCallback(
    (e: any) => {
      onChanged(e)
    }, [onChanged])

  const onSelectBoxFocusIn = React.useCallback((e) => {
    e.element.querySelector('input.dx-texteditor-input').select()
  }, [])

  const refreshSelected = React.useCallback(async () => {
    if (options && options.length > 0) {
      const selectedIndex = await options.findIndex((option: any) => {
        return option.codigo === selected?.codigo
      })
      let marcarOption: OptionTiposImpuestos = options[0]
      if (selectedIndex > -1) {
        marcarOption = options[selectedIndex]
      }
      onChanged(marcarOption)
    }
    selectRef.current.instance.repaint()
  }, [onChanged, options, selected])

  React.useEffect(() => {
    loadDatos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    refreshSelected()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  return (
    <SelectBox
      id="tiposImpuestosSelect"
      key={uuidv4()}
      ref={selectRef}
      items={options ?? []}
      searchEnabled
      displayExpr={getItemDisplayExpr}
      searchExpr={['descripcion']}
      searchTimeout={500}
      value={selected}
      onValueChange={onLookUpValueChanged}
      disabled={disabled}
      onFocusIn={onSelectBoxFocusIn}
    >
      {props.children}
    </SelectBox>
  )
}
export default React.memo(TiposImpuestosLookUp)
