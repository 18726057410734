import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CCol, CNav, CNavItem, CNavLink, CRow, CTabContent, CTabPane } from '@coreui/react-pro'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer';
import { RootState } from '../../../../../../store/store';
import Auditoria from './auditoria';
import Abonos from './abonos';
import { changeCurrentTab } from '../../store/verAbonosReducer';
import { CustomButtons } from '../../../../../../store/types';

interface IResultadoBusquedaProps extends React.PropsWithChildren {
  tab
}


const ResultadoBusqueda: React.FunctionComponent<IResultadoBusquedaProps> = (props) => {
  const dispatch = useDispatch();

  const resultadosAbonos = useSelector((state: RootState) => state.clientes.docCuentasXCobrar.verAbonos.resultadosAbonos)
  const tabNames = useSelector((state: RootState) => state.clientes.docCuentasXCobrar.verAbonos.tabs)

  const { tab } = props;

  const [showTab, setShowTab] = React.useState<string>(tabNames[0])


  const changeTab = React.useCallback((tab: string) => {
    dispatch(changeCurrentTab(tab))
  }, [dispatch])


  return (
    <>
      <RowContainer className='mt-2 border'>
        <CRow>
          <CCol>

            <CNav variant="tabs" role="tablist" className='mt-4'>
              <CNav variant="tabs">
                {tabNames.map((tab, index) => (
                  <CNavItem key={index}>
                    <CNavLink
                      onClick={() => {
                        setShowTab(tab)
                        changeTab(tab)
                      }}
                      active={showTab === tab}
                    >
                      {tab}
                    </CNavLink>
                  </CNavItem>
                ))}
              </CNav>
              <CTabContent
                style={{ overflowY: 'unset', width: '100%', marginBottom: '10px' }}
              >
                <CTabPane
                  role="tabpanel"
                  aria-labelledby="pendientes-tab"
                  visible={showTab === tabNames[0]}
                >
                  <Abonos data={resultadosAbonos} />
                </CTabPane>
                <CTabPane
                  role="tabpanel"
                  aria-labelledby="cbrados-tab"
                  visible={showTab === tabNames[1]}
                >
                  <Auditoria data={resultadosAbonos} />
                </CTabPane>
              </CTabContent>
            </CNav>
          </CCol>
        </CRow>
      </RowContainer>
    </>
  );
}
export default React.memo(ResultadoBusqueda)


export const ModuleButtonsVerAbonos: CustomButtons = {
  Nuevo: false,
  Buscar: true,
  Deshacer: false,
  Eliminar: true,
  Editar: false,
  Imprimir: true,
  Importar: true,
  Exportar: true,
  Ver_asiento: true,
  Limpiar: true,
}

