import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { CCard, CCardBody, CCardHeader, CLink } from '@coreui/react-pro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import FIleInput from '../../../../../componentes/fileInput'
import { changeLoader } from '../../../../../../store/reducers';

import "./import.scss"
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types';
import { clearButtonClick } from '../../store/tabsReducer';
import { ImportExcellService } from './service/importExcell.service';
import { addToast } from '../../../../../../store/toasterReducer';
import { ToastTypes } from '../../../../../../store/types';
import { RootState } from '../../../../../../store/store';
import { TabState } from '../../../../../../store/genericTabTypes'
import { EAplicationsAcatha, EModulosAcatha } from '../../../../../../store/enumsAplication';

interface IImporatrProps extends React.PropsWithChildren {
  tabId: string,
  tab: TabState<any>
  data?: any
  openImport: (open: boolean) => void
  moduleCode?: number
  setData?: (data: any) => void
}

const Importar: React.FunctionComponent<IImporatrProps> = (props) => {
  const dispatch = useDispatch();
  const { tab, tabId, openImport, data, setData } = props;
  const [file, setFile] = React.useState<any>([]);

  const puntoVentaGlobal = useSelector((state: RootState) => state.global.puntoVenta)

  const showEditLoader = React.useCallback((mensaje: string) => {
    dispatch(changeLoader({
      mensaje: mensaje,
      show: true
    }));
  }, [dispatch])

  const hideEditLoader = React.useCallback(() => {
    dispatch(changeLoader({
      mensaje: '',
      show: false
    }));
  }, [dispatch])


  const onClean = React.useCallback(() => {
    setFile([])
  }, [])

  const downDemo = React.useCallback(async () => {

    try {
      showEditLoader("Exportando...")
      let filename = ''
      if (tabId === "SALDOS") {
        filename = props.moduleCode === parseInt(EModulosAcatha.Proveedores) ? 'saldosproveedores' : 'saldosclientes'
      } else if (tabId === "ANTICIPOS") {
        filename = props.moduleCode === parseInt(EModulosAcatha.Proveedores) ? 'anticiposproveedor' : 'anticiposcliente'
      } else if (tabId === "BUSQUEDA" && (props.moduleCode === parseInt(EModulosAcatha.Proveedor))) {
        filename = 'proveedores'
      } else if (tabId === "BUSQUEDA" && (props.moduleCode === parseInt(EModulosAcatha.Personal))) {
        filename = 'usuarios'
      } else if (tabId === "BUSQUEDA" && (props.moduleCode === parseInt(EAplicationsAcatha.Contratos))) {
        filename = 'contratosEmpleados'
      } else if ((props.moduleCode === parseInt(EAplicationsAcatha.Marcacion))) {
        filename = 'marcaciones'
      } else if ((props.moduleCode === parseInt(EAplicationsAcatha.RolesPago))) {
        filename = 'rol_pagos'
      } else if (props.moduleCode === parseInt(EAplicationsAcatha.IngresosEgresos)
        || props.moduleCode === parseInt(EAplicationsAcatha.Importaciones)) {
        filename = 'movimientos'
      } else if (props.moduleCode === parseInt(EAplicationsAcatha.DecimoTercero)
        || props.moduleCode === parseInt(EAplicationsAcatha.DecimoCuarto)) {
        filename = 'decimos'
      } else {
        filename = props.moduleCode === parseInt(EModulosAcatha.Proveedores) ? 'abonoscompras' : 'abonosventas'
      }
      const response = await ImportExcellService.exportarDemo(filename);
      hideEditLoader();
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = filename + '.xls';
      a.click();

    } catch (error) {

      hideEditLoader();
      console.error(error)
      dispatch(addToast({
        title: 'Exportar Demo',
        content: typeof error == 'string' ? error : JSON.stringify(error),
        type: ToastTypes.Danger,
        autoHide: 3000
      }))
    }

  }, [dispatch, hideEditLoader, showEditLoader, tabId, props.moduleCode])

  const buttonClickImportar = React.useCallback(async () => {
    try {
      if (file.length === 0) {
        dispatch(addToast({
          id: '',
          autoHide: true,
          content: 'No se encuentra una plantilla para importar.',
          fade: true,
          title: 'Importar',
          type: ToastTypes.Danger
        }));
        return false;
      }
      showEditLoader("Importando documento...")

      if (file && file.length > 0) {
        const upload: any = {}
        if (props.moduleCode === parseInt(EModulosAcatha.Proveedor)) {
          upload.modulo = "proveedor"
        } else if (props.moduleCode === parseInt(EAplicationsAcatha.IngresosEgresos)
          || props.moduleCode === parseInt(EAplicationsAcatha.Importaciones)) {
          upload.modulo = 'movimientos'
        } else if (props.moduleCode === parseInt(EAplicationsAcatha.DecimoTercero)
          || props.moduleCode === parseInt(EAplicationsAcatha.DecimoTercero)) {
          upload.modulo = 'rolDecimos'
        } else if (props.moduleCode === parseInt(EModulosAcatha.Personal)) {
          upload.modulo = 'personal'
        } else if (props.moduleCode === parseInt(EAplicationsAcatha.Contratos)) {
          upload.modulo = 'contrato'
        } else if (props.moduleCode === parseInt(EAplicationsAcatha.Marcacion)) {
          upload.modulo = 'marcacion'
        } else if (props.moduleCode === parseInt(EAplicationsAcatha.RolesPago)) {
          upload.modulo = 'rolpagos'
        } else {
          upload.modulo = props.moduleCode === parseInt(EModulosAcatha.Proveedores) ? 'compras' : 'ventas'
        }
        if (tabId === "ABONAR") {
          upload.plantilla = props.moduleCode === parseInt(EModulosAcatha.Proveedores) ? 'abonoscompras' : 'abonosventas'
        } else if (tabId === "ANTICIPOS") {
          upload.plantilla = 'anticipos'
          upload.puntoVenta = puntoVentaGlobal
        } else if (tabId === "BUSQUEDA" && (props.moduleCode === parseInt(EModulosAcatha.Proveedor))) {
          upload.plantilla = 'proveedores'
        } else if (tabId === "BUSQUEDA" && (props.moduleCode === parseInt(EModulosAcatha.Personal))) {
          upload.plantilla = 'usuarios'
        } else if (tabId === "BUSQUEDA" && (props.moduleCode === parseInt(EAplicationsAcatha.Contratos))) {
          upload.plantilla = 'contratos'
        } else if (props.moduleCode === parseInt(EAplicationsAcatha.Marcacion)) {
          upload.plantilla = 'marcaciones'
          upload.archivo = file[0]?.name
        } else if (props.moduleCode === parseInt(EAplicationsAcatha.RolesPago)) {
          upload.plantilla = 'rolespago'
          upload.local = data?.local ?? -1
        } else if (props.moduleCode === parseInt(EAplicationsAcatha.IngresosEgresos)
          || props.moduleCode === parseInt(EAplicationsAcatha.Importaciones)) {
          upload.plantilla = 'mov'
          upload.local = data?.local ?? -1
        } else if (props.moduleCode === parseInt(EAplicationsAcatha.DecimoTercero)
          || props.moduleCode === parseInt(EAplicationsAcatha.DecimoCuarto)) {
          upload.plantilla = 'decimos'
          upload.tipo = props.moduleCode === parseInt(EAplicationsAcatha.DecimoTercero) ? "III" : "IV"
        } else {
          upload.plantilla = props.moduleCode === parseInt(EModulosAcatha.Proveedores) ? 'pagos' : 'cartera'
        }
        upload.file = file[0]

        const subirPlantilla = await ImportExcellService.plantillaImportar(upload);
        hideEditLoader();
        openImport(false)

        console.log("subirPlantilla", subirPlantilla);

        if (subirPlantilla.error === false) {
          dispatch(addToast({
            id: '',
            autoHide: true,
            content: subirPlantilla.message,
            fade: true,
            title: 'Importar',
            type: ToastTypes.Success
          }));
        }

        if (props.moduleCode === parseInt(EAplicationsAcatha.IngresosEgresos)
          || props.moduleCode === parseInt(EAplicationsAcatha.Importaciones)
          || props.moduleCode === parseInt(EAplicationsAcatha.Contratos)
          || props.moduleCode === parseInt(EAplicationsAcatha.RolesPago)
          || props.moduleCode === parseInt(EModulosAcatha.Personal)
          || props.moduleCode === parseInt(EAplicationsAcatha.Marcacion)
          || props.moduleCode === parseInt(EAplicationsAcatha.DecimoTercero)
          || props.moduleCode === parseInt(EAplicationsAcatha.DecimoCuarto)) {
          setData(subirPlantilla)
        }

      }
    } catch (error) {
      hideEditLoader();
      let sms = '';
      const capturaError: any = error;
      if (capturaError && capturaError.message) {
        sms = capturaError.message;
      } else {
        sms = 'Error al importar la plantilla ' + (typeof capturaError === 'string' ? capturaError : JSON.stringify(capturaError));
      }
      dispatch(addToast({
        id: '',
        autoHide: true,
        content: sms,
        fade: true,
        title: 'Importar',
        type: ToastTypes.Danger
      }));
    }
  }, [file, dispatch, hideEditLoader, showEditLoader, openImport, tabId, puntoVentaGlobal, props.moduleCode, data, setData]);

  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      switch (tab.globalButtonClick) {
        case ButtonTypes.save:
          buttonClickImportar();
          break;
        case ButtonTypes.import:
          buttonClickImportar();
          break;
        default:
          break;
      }
      dispatch(clearButtonClick(tabId))
    }
  }, [tab, dispatch, buttonClickImportar, tabId]);

  const onFileChanged = React.useCallback((file: any) => {
    console.log(file)
    if (file === null || file.value.length === 0) onClean()
    else if (file.value.length > 0) setFile(file.value)
  }, [onClean])

  return (
    <div  >
      <CCard style={{ overflowX: 'hidden', backgroundColor: "#f3f3f3", borderRadius: "3px", border: 'solid 1px #ced2d8' }}>
        <CCardHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
          <strong> {"Importar Archivo"} </strong>
          <div className="card-header-actions">
            <CLink className="card-header-action" onClick={() => { openImport(false); }}>
              <FontAwesomeIcon
                size="xl"
                icon={['fal', "times"]} style={{ fontWeight: 1, marginRight: '-12px', cursor: 'pointer' }}
              />
            </CLink>
          </div>
        </CCardHeader>
        <CCardBody>
          <FIleInput
            accept={'application/vnd.ms-excel'}
            onChanged={onFileChanged}
            downDemo={downDemo}
          />
        </CCardBody>
      </CCard>
    </div >
  );
}

export default React.memo(Importar);
