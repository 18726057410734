/* eslint-disable no-unused-vars */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataGrid, { Column, Paging, Pager, ColumnChooser, HeaderFilter, Button as DatagridButton, TotalItem, Summary } from 'devextreme-react/data-grid';
import { ComprasType } from '../../../../types/types';


interface ITablaComprasProps extends React.PropsWithChildren {
  data: Array<ComprasType> | []
  cargar: (data) => void
}
const pageSizes = [10, 25, 50, 100, 200];

const TablaCompras: React.FunctionComponent<ITablaComprasProps> = (props) => {
  const { data, cargar } = props;
  const dispatch = useDispatch();

  const dialogRef = React.useRef<any>(null);
  const dataGrid = React.useRef<any>();


  const clearFilter = React.useCallback(() => {
    dataGrid.current.instance.clearSorting();
    dataGrid.current.instance.clearFilter();
  }, []);

  const onRowDblClick = React.useCallback((e) => {
    console.log("onRowDblClick", e)
    // cargar();
  }, []);

  const onSelectionChanged = React.useCallback((e) => {
    const { selectedRowsData, selectedRowKeys } = e;
    if (selectedRowsData.length > 0) {
      const dataSelec: ComprasType = selectedRowsData[0];
      cargar({
        data: dataSelec, index: (selectedRowKeys[0] - 1)
      });
    }
  }, [cargar]);

  return (
    <DataGrid
      ref={dataGrid}
      selection={{ mode: 'single' }}
      allowColumnResizing={true}
      columnResizingMode={'nextColumn'}
      focusedRowEnabled={true}
      keyExpr='nro'
      focusedRowKey='nro'
      dataSource={data}
      showBorders={true}
      onRowDblClick={onRowDblClick}
      onSelectionChanged={onSelectionChanged}

    >
      <ColumnChooser enabled={true} mode="select" />
      <HeaderFilter visible={true} />

      <Column dataField='nro' caption='Nro' width='80px' allowEditing={false} visible={true} allowSearch={false} allowFiltering={false} />
      <Column dataField='sustentoTipo' caption='Sustento' width='110px' allowEditing={false} visible={true} allowSearch={false} allowFiltering={true} />
      <Column dataField='proveedorIdentificacion' type={'string'} width='100px' caption='Id. Proveedor' alignment='right' allowSearch={true} allowFiltering={true} >
        <HeaderFilter allowSearch={true} />
      </Column>
      <Column dataField='tipoComprobante' caption='Tipo Comprobante' minWidth='200px' width='auto' />
      <Column dataField='numeroCompleto' caption='Comprobante' minWidth='220px' width='auto' allowSearch={true} allowFiltering={true} >
        <HeaderFilter allowSearch={true} />
      </Column>
      <Column dataField='autorizacion' caption='Autorización' minWidth='220px' width='auto' />
      <Column dataField='subtotal0' caption='Base IVA 0%' alignment='right' width='85px' allowFiltering={false} />
      <Column dataField='subtotal12' caption='Base IVA 12%' alignment='right' width='85px' allowFiltering={false} />
      <Column dataField='subtotalNoIVA' caption='Base NO IVA' alignment='right' width='85px' allowFiltering={false} />
      <Column dataField='IVA' caption='IVA' alignment='right' width='85px' allowFiltering={false} />

      <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} showInfo />
      <Paging defaultPageSize={10} />


    </DataGrid>
  );
}
export default TablaCompras;
