import React from 'react'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput'
import { Button, TextArea, TextBox, ValidationGroup, ValidationSummary, Validator } from 'devextreme-react'
import { AplicacionesDatosEdicion } from '../../types/types'
import { ButtonTypes } from '../../../../../../../views/componentes/globalMenu/types'
import { clearButtonClick, setCurrentExecutingAction, setNameTabe } from '../../store/tabsReducer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import TipoEstadoLookUp from '../../../../../../componentes/tipoEstado'
import {
  changeLoaderAplicationsTab,
  setCurrentFunction,
} from '../../store/generalReducer'
import { setDatosEdicion } from '../../store/editDataReducer'
import Dialog from '../../../../../../../views/componentes/librerias/bootstrap-dialog'
import { TabState } from '../../../../../../../store/genericTabTypes'
import { ModalAplicaciones } from '../../../../items/components/modalAplicaciones'
import { AsyncRule, RequiredRule } from 'devextreme-react/validator'
import validations from '../../../../../../../config/validations'
import { DocumentInfo, ToastTypes } from '../../../../../../../store/types'
import { MessagesKeys, lh } from '../../../../../../../helpers/localizationHelper'
import { addToast } from '../../../../../../../store/toasterReducer'
import { SaveAplication } from '../../../../items/types/types'
import { aplicationsModalServices } from '../../../../items/components/modalAplicaciones/services/modalAplicaciones.services'
import { StatesEdition } from '../../../../../../ventas/types/enums'
import BlockUi from '../../../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../../../views/componentes/loadingindicator/loadingindicator'
import { tipoEstadoService } from '../../../../../../componentes/tipoEstado/service/tipoEstado.service'
import VisualizaError from '../../../../../../ventas/pages/shared/visualizaError/visualizaError'
import ListErrosValidationForm from '../../../../../../componentes/listadoErroresValidacionForm'
import { isMobile } from 'react-device-detect'
import { CLink, CTooltip } from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilInfo } from '@coreui/icons'
import { aplicationsServices } from '../../../../../../componentes/aplicacionesLookUp/sevices/aplicaciones.services'
import { consoleService } from '../../../../../../../services/console.service'
import { setDatosEdicionBackup } from '../../store/configReducer'

export const optionsValues = [
  { value: 0, label: 'Enteros' },
  { value: 1, label: 'Decimales' },
]

interface INewProps extends React.PropsWithChildren {
  info: DocumentInfo<AplicacionesDatosEdicion>
  tab: TabState<AplicacionesDatosEdicion>
  tabId: string
}

const Nuevo: React.FunctionComponent<INewProps> = (props) => {
  const { tab, tabId, info } = props

  const dispatch = useDispatch()
  const sms = validations['es']
  const loading = useSelector((state: RootState) => state.inventarios.catalogos.aplicaciones.editData[tabId].loading)
  const loader = useSelector((state: RootState) => state.inventarios.catalogos.aplicaciones.general.loaderTab)
  const appsState = useSelector((state: RootState) => state.inventarios.catalogos.aplicaciones.editData[tabId])
  const appsStateBackup = useSelector((state: RootState) => state.inventarios.catalogos.aplicaciones.config[tabId])
  const tabs = useSelector((state: RootState) => state.inventarios.catalogos.aplicaciones.tabs)
  const [arrayErrors, setArrayErrors] = React.useState<Array<string>>([])
  const [showErrorPopup, setShowErrorPopup] = React.useState<boolean>(false)
  const [showPopup, setshowPoup] = React.useState<boolean>(false)
  const validationGroupRef = React.useRef<any>()
  const dialogRef = React.useRef<any>(null)

  const playLoader = React.useCallback((message = 'Cargando...') => {
    dispatch(changeLoaderAplicationsTab({ show: true, mensaje: message }))
  }, [dispatch])

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoaderAplicationsTab({ show: false, mensaje: '' }))
  }, [dispatch])

  const setButtonExecuttion = React.useCallback((tipo: ButtonTypes | undefined) => {
    dispatch(
      setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: tipo,
      }),
    )
  }, [dispatch, tabId])

  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(
      addToast({
        title: 'Inventario - Aplicaciones',
        content: mensaje,
        type: tipo,
      }),
    )
  }, [dispatch])

  const onGetEstado = React.useCallback(async (estado: number) => {
    try {
      const data = await tipoEstadoService.getTipoEstado('Elija un estado')
      if (data !== null && data !== undefined && data['error'] === false) {
        const est = data['auto'].find(item => item.codigo === estado)
        return est
      }
      return null
    } catch (error) {
      return null
    }
  }, [])

  const onGetPertenece = React.useCallback(async (codigo: number) => {
    try {
      const data = await aplicationsServices.getAplications()
      if (data !== null && data !== undefined && data['error'] === false) {
        const est = data['auto'].find(item => item.codigo === codigo)
        return est
      }
      return null
    } catch (error) {
      return null
    }
  }, [])

  const modoNuevo = React.useCallback(async (template: number, limpiar: boolean = false) => {
    if (loading === false && !limpiar) {
      return
    }
    setButtonExecuttion(ButtonTypes.new)
    playLoader()
    const estado = await onGetEstado(1)
    const data = { ...defaultDataAplications }
    data.descripcion = ''
    data.observaciones = ''
    data.estado = estado
    data.pertenece = null
    data.loading = false
    dispatch(setDatosEdicion({
      key: tabId,
      aplicacion: { ...data }
    }))
    dispatch(setDatosEdicionBackup({
      key: tabId,
      aplicacion: { ...data }
    }))
    stopLoader()
    setButtonExecuttion(undefined)
  }, [loading, onGetEstado, playLoader, setButtonExecuttion, stopLoader, dispatch, tabId])

  const cargarAplicacion = React.useCallback(async (loder: boolean, aplicacionData) => {
    if (loder === false) {
      return
    }
    setButtonExecuttion(ButtonTypes.edit)
    playLoader('Cargando aplicacion...')
    consoleService.log(aplicacionData)
    const estado = await onGetEstado(aplicacionData?.estado)
    let pertenece = null
    if (aplicacionData?.padre > 0) {
      pertenece = await onGetPertenece(aplicacionData?.padre)
    }
    const data = { ...appsState }
    data.descripcion = aplicacionData?.descripcion ?? '',
      data.pertenece = pertenece
    data.estado = estado
    data.observaciones = aplicacionData?.observaciones ?? '',
      data.guardado = false,
      data.loading = false,
      dispatch(setDatosEdicion({
        key: tabId,
        aplicacion: { ...data }
      }))
    dispatch(setDatosEdicionBackup({
      key: tabId,
      aplicacion: { ...data }
    }))
    stopLoader()
    setButtonExecuttion(undefined)
  }, [appsState, onGetEstado, onGetPertenece, playLoader, stopLoader, setButtonExecuttion, dispatch, tabId])


  const onChangeValueData = React.useCallback((data, key: string) => {
    if (data !== null && data !== undefined) {
      let value = null
      if (data?.value !== null && data?.value !== undefined) {
        value = data.value
      } else {
        value = data
      }
      dispatch(setDatosEdicion({
        key: tabId,
        aplicacion: {
          ...appsState,
          [key]: value
        }
      }))
    }
  }, [dispatch, appsState, tabId])

  const validateAsyncSelect = React.useCallback((datoSeleccion: any, mensaje: string) => {
    if (datoSeleccion.value.codigo > -1) {
      return Promise.resolve()
    } else {
      return Promise.reject(mensaje)
    }
  }, [])

  const validateAsyncSeleccionTipoId = React.useCallback((datoSeleccion) => {
    return validateAsyncSelect(datoSeleccion, `Estado: Debe seleccionar una opción.`)
  }, [validateAsyncSelect])

  const onValidate = React.useCallback(() => {
    const arrayErrors: Array<string> = []
    if (appsState?.descripcion === null || appsState?.descripcion === undefined || appsState?.descripcion === '') {
      arrayErrors.push('Descripción: Ingrese una Descripción del registro.')
    }
    if (appsState?.estado === null || appsState?.estado === undefined || appsState.estado?.codigo <= 0) {
      arrayErrors.push('Estado: Elija el estado del registro.')
    }
    return arrayErrors
  }, [appsState])

  const onHandleSave = React.useCallback(async () => {
    let errors: Array<string> = []
    errors = onValidate()
    const result = validationGroupRef.current.instance.validate()
    if (result.isValid && errors.length === 0) {

      const save_data: SaveAplication = {
        infoRegistro: {
          codigo: appsState?.codigo ?? 0,
          descripcion: appsState?.descripcion ?? '',
          observaciones: appsState?.observaciones ?? '',
          pertenece: appsState.pertenece?.codigo ?? 0,
          estado: appsState.estado?.codigo ?? 0,
        }
      }

      try {
        setButtonExecuttion(ButtonTypes.save)
        playLoader('Guardando...')
        const data = await aplicationsModalServices.saveAplication(save_data)
        stopLoader()
        setButtonExecuttion(undefined)
        if (data !== null && data !== undefined && data['error'] === false) {
          setToast(data['message'], ToastTypes.Success)
          dispatch(setNameTabe({
            key: tabId,
            codigo: data['auto'],
            nombre: appsState?.descripcion
          }))
        } else {
          setToast(data['message'], ToastTypes.Danger)
        }
      } catch (error) {
        stopLoader()
        setButtonExecuttion(undefined)
        setToast(error.message, ToastTypes.Danger)
      }
    } else {
      setArrayErrors(errors)
      setShowErrorPopup(true)
      setToast(lh.getMessage(MessagesKeys.GlobalErrorSave), ToastTypes.Warning)
    }
  }, [appsState, setToast, playLoader, stopLoader, setButtonExecuttion, dispatch, tabId, onValidate])

  const onUndo = React.useCallback(() => {
    dispatch(setDatosEdicion({
      key: tabId,
      aplicacion: { ...appsStateBackup }
    }))
  }, [appsStateBackup, dispatch, tabId])

  const onBroom = React.useCallback(() => {
    dispatch(setDatosEdicion({
      key: tabId,
      aplicacion: { ...defaultDataAplications }
    }))
  }, [dispatch, tabId])



  const handleButtonClick = React.useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.save:
          if (tabs.current === tabId) onHandleSave()
          break
        case ButtonTypes.broom:
          if (tabs.current === tabId) {
            onBroom()
          }
          break
        case ButtonTypes.undo:
          if (tabs.current === tabId) {
            onUndo()
          }
          break
        default:
          break
      }
      dispatch(setCurrentFunction(''))
      dispatch(clearButtonClick(tabId))
    },
    [dispatch, onHandleSave, tabId, tabs, onUndo, onBroom],
  )

  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab.globalButtonClick])

  React.useEffect(() => {
    if (tab.editStatus === StatesEdition.new) {
      modoNuevo(tab?.info?.codigo)
    } else {
      cargarAplicacion(loading, info.info)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {showErrorPopup && (
        <VisualizaError
          titulo="Error en Aplicaciones"
          mensaje={'Error al guardar'}
          content={
            <ListErrosValidationForm
              mainMessage={'Revise los siguientes errores:'}
              errorsList={arrayErrors}
              color={'danger'}
            />
          }
          onOk={() => setShowErrorPopup(false)}
        />
      )}
      {
        showPopup && <ModalAplicaciones
          show={showPopup}
          onCancel={() => setshowPoup(false)}
          onSelectUnit={(data) => {
            onChangeValueData(data, 'pertenece')
            setshowPoup(false)
          }}
        />
      }
      <div style={{ overflowX: 'hidden' }}>
        <BlockUi
          tag="div"
          loader={LoadingIndicator}
          blocking={loader.show}
          message={loader.mensaje}
        >
          <RowContainer>
            <Dialog ref={dialogRef} />
            <ValidationGroup id={'formLineas'} ref={validationGroupRef}>
              {
                !isMobile && (
                  <RowContainer>
                    <CustomCol lg="6" md="8" sm="12">
                      <ValidationSummary id="summary"></ValidationSummary>
                    </CustomCol>
                  </RowContainer>
                )
              }
              <RowContainer>
                <CustomCol xs="12" md="3">
                  <Labeled label="Descripción:">
                    <TextBox
                      value={appsState?.descripcion ?? ''}
                      onValueChanged={(data) => {
                        setshowPoup(false)
                        onChangeValueData(data, 'descripcion')
                      }}
                    >
                      <Validator>
                        <RequiredRule message={'Descripción: ' + sms['required']} />
                      </Validator>
                    </TextBox>
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="4">
                  <Labeled label="Pertence:">
                    <div
                      style={{
                        display: 'flex',
                        gap: '2px',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <TextBox
                        value={appsState?.pertenece?.descripcion ?? ''}
                        showClearButton={true}
                        width="100%"
                        inputAttr={{ autocomplete: 'nope' }}
                      />
                      <Button
                        id="btnAdd"
                        className="me-1"
                        icon="add"
                        stylingMode="contained"
                        type="default"
                        onClick={() => setshowPoup(true)}
                      />
                      <CTooltip
                        key={'tool-more-auth-side'}
                        placement="top"
                        content={
                          'Si deja el campo vacio el sistema interpretará que pertenece al registro raíz.'
                        }
                      >
                        <div className="card-header-actions">
                          <CLink className="card-header-action m-0">
                            <CIcon icon={cilInfo} className="ms-1 me-1" />
                          </CLink>
                        </div>
                      </CTooltip>
                    </div>
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="3">
                  <Labeled label="Estado:">
                    <TipoEstadoLookUp onChanged={(data) => onChangeValueData(data, 'estado')} selected={appsState?.estado ?? null} >
                      <Validator>
                        <RequiredRule message={'Estado: Debe seleccionar una opción'} />
                        <AsyncRule
                          message={'Estado: Debe seleccionar una opción'}
                          validationCallback={validateAsyncSeleccionTipoId}
                        />
                      </Validator>
                    </TipoEstadoLookUp>
                  </Labeled>
                </CustomCol>
              </RowContainer>
              {
                isMobile && (
                  <RowContainer>
                    <CustomCol lg="6" md="8" sm="12">
                      <ValidationSummary id="summary"></ValidationSummary>
                    </CustomCol>
                  </RowContainer>
                )
              }
            </ValidationGroup>
            <RowContainer>
              <CustomCol xs="12" md="6">
                <Labeled label="Observaciones:">
                  <TextArea
                    value={appsState?.observaciones ?? ''}
                    onValueChanged={(data) => onChangeValueData(data, 'observaciones')}
                  />
                </Labeled>
              </CustomCol>
            </RowContainer>
          </RowContainer>
        </BlockUi>
      </div>
    </>
  )
}

export default React.memo(Nuevo)

export const defaultDataAplications: AplicacionesDatosEdicion = {
  codigo: 0,
  descripcion: '',
  pertenece: null,
  estado: null,
  observaciones: '',
  guardado: false,
  loading: false,
}