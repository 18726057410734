import React, { useEffect } from 'react'
import {
  AccionMenu,
  CustomButtons,
  FETCH_STATUS,
  ToastTypes,
} from '../../../../../store/types'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../../../store/store'
import { ETypeMovimiento, FiltrosIngresosEgresosState, IngresosEgresosDto } from './types/types'
import { changeLoaderGroups, setCurrentFunction } from './store/generalReducer'
import { addToast } from '../../../../../store/toasterReducer'
import { Aplicacion } from '../../../store/types'
import { utilidades } from '../../../../../helpers/utilidades'
import {
  buttonClick,
  changeCurrentTab,
  closeTabINgresosEgresos,
  openTab,
  openTabResum,
  setButtons,
  setCurrentExecutingAction,
} from './store/tabsReducer'
import { ButtonTypes } from '../../../../../views/componentes/globalMenu/types'
import { v4 as uuidv4 } from 'uuid'
import { deleteEditData, initDatosEdicion } from './store/editDataReducer'
import { changeFilter, fetchIncomeExpenses, setCleanResult, setColapsado, setResetSeleccion, setVerRegistro } from './store/serachReducer'
import Dialog from '../../../../../views/componentes/librerias/bootstrap-dialog'
import {
  useButtonDisabled,
  useMenuOptions,
} from '../../../../../hooks/useMenuButtonControls'
import { DateUtils } from '../../../../../helpers/dateUtils'
import { TabTypes } from '../../../../ventas/types/enums'
import { EAplicationsAcatha } from '../../../../../store/enumsAplication'
import { setAplicacionModulo } from './store/configReducer'
import { setMenuButtons } from './store/menuReducer'
import IngresosEgresosMain from './components/shared/main'
import { consoleService } from '../../../../../services/console.service'
import { defaultDataIncomeExpenses } from './components/nuevo'
import { MessagesKeys, lh } from '../../../../../helpers/localizationHelper'
import { getTypesMovements } from './helper/helper'
import { getLocales } from '../../../../componentes/localesLookUp/helper/helper'
import { typeItems } from './components/buscar/filtroBusqueda'
import { initSearch } from './components/buscar/filtroBusqueda';
import { getIndexProvider } from '../../../../shared/helpers/funciones'
import { ReportWithLocalDataModal } from '../../../../../views/componentes/xtrareports/ReportWithLocalDataModal'
const pathVerificador = '/ingresos/egresos'

interface CatalogosGruposProps extends React.PropsWithChildren { }

const IngresosEgresos: React.FunctionComponent<CatalogosGruposProps> = () => {
  const dispatch = useDispatch()
  const menuState = useSelector((state: RootState) => state.global.menu)
  const tabs = useSelector((state: RootState) => state.inventarios.movimientos.ingresosEgresos.tabs)
  const tabsMovimientos = useSelector((state: RootState) => { return state.inventarios.movimientos.ingresosEgresos.tabs });


  const searchFilter = useSelector((state: RootState) => state.inventarios.movimientos.ingresosEgresos.search.filter)
  const currentTab = useSelector((state: RootState) => state.inventarios.movimientos.ingresosEgresos.search.currentTab)
  const searchStatus = useSelector((state: RootState) => state.inventarios.movimientos.ingresosEgresos.search.status)
  const searchResults = useSelector((state: RootState) => state.inventarios.movimientos.ingresosEgresos.search.resultados)
  const selected = useSelector((state: RootState) => state.inventarios.movimientos.ingresosEgresos.search.seleccionado)
  const periodoActivo = useSelector(
    (state: RootState) => state.shared.providers.periodoContable,
  )

  const [reporte, setReporte] = React.useState<null | "Viewer" | "Designer">(null);

  const localSession = useSelector((state: RootState) => state.global.session.local)

  const dialogRef = React.useRef<any>(null)
  const getButtonDisabled = useButtonDisabled(tabs)
  const { getMenuOptions, options } = useMenuOptions()

  const playLoader = React.useCallback((message = 'Cargando...') => {
    dispatch(changeLoaderGroups({ show: true, mensaje: message }))
  }, [dispatch])

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoaderGroups({ show: false, mensaje: '' }))
  }, [dispatch])

  const getFilterString = React.useCallback((filter: FiltrosIngresosEgresosState) => {
    const filterText = ''
    return filterText
  }, [])


  const fechaFormato: string = 'dd/MM/yyyy'
  const fechaFormatoInput: string = 'yyyy-MM-dd'

  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(
      addToast({
        title: 'Inventario-Ingresos/Egresos',
        content: mensaje,
        type: tipo,
      }),
    )
  }, [dispatch])

  const onTabClosing = React.useCallback(
    async (index) => {
      const closedTab = tabs.tabs[index]
      dispatch(closeTabINgresosEgresos(closedTab.tabKey ?? index))
      await utilidades.sleep(50)
      dispatch(deleteEditData(closedTab.tabKey ?? index))
    },
    [dispatch, tabs.tabs],
  )

  const onTabChanged = React.useCallback(
    (tabKey: string) => {
      dispatch(changeCurrentTab(tabKey))

    },
    [dispatch],
  )

  const onCleanResultFilter = React.useCallback(async () => {
    const data = { ...initSearch }
    const resMovimientos = await getTypesMovements()
    const resLocal = await getLocales('Elija una opcion')

    data.tipoItem = typeItems[0]
    consoleService.log(periodoActivo)
    data.fechaDesde = periodoActivo.length > 0 ? DateUtils.format(periodoActivo[0].finicio, fechaFormatoInput, fechaFormato) : DateUtils.getCurrentDateAsString(fechaFormatoInput)
    data.fechaHasta = periodoActivo.length > 0 ? DateUtils.format(periodoActivo[0].fcierre, fechaFormatoInput, fechaFormato) : DateUtils.getCurrentDateAsString(fechaFormatoInput)
    data.movimientos = resMovimientos.length > 0 ? resMovimientos[0] : null
    if (resLocal.length > 0) {
      const provider = resLocal.slice(0)
      const index = await getIndexProvider(provider, 'codigo', localSession?.codigo ?? 0)
      if (index > -1) {
        data.almacen = resLocal[index]
      } else {
        data.almacen = resLocal[0]
      }
    }
    data.items = null
    data.initial = false

    dispatch(changeFilter({
      ...data
    }))
    dispatch(setCleanResult())
    dispatch(setResetSeleccion())
  }, [dispatch, localSession, periodoActivo])

  const onSearch = React.useCallback(async () => {
    let clienteCodigo = 0
    let proveedorCodigo = 0
    let proCli = 0


    const cod = searchFilter?.codigo ? parseInt(searchFilter?.codigo) : 0

    const desde = searchFilter?.fechaDesde ? DateUtils.format(searchFilter?.fechaDesde, fechaFormato, fechaFormatoInput) : DateUtils.getCurrentDateAsString(fechaFormato)
    const hasta = searchFilter?.fechaHasta ? DateUtils.format(searchFilter?.fechaHasta, fechaFormato, fechaFormatoInput) : DateUtils.getCurrentDateAsString(fechaFormato)

    if (searchFilter.movimientos !== null && searchFilter.movimientos !== undefined && searchFilter.movimientos?.asociado === ETypeMovimiento.ventas) {
      clienteCodigo = searchFilter.cliente?.codigo ?? 0
    } else if (searchFilter.movimientos !== null && searchFilter.movimientos !== undefined && searchFilter.movimientos?.asociado === ETypeMovimiento.compras) {
      proveedorCodigo = searchFilter.proveedor?.codigo ?? 0
    }

    if (clienteCodigo !== 0) {
      proCli = clienteCodigo
    } else if (proveedorCodigo !== 0) {
      proCli = proveedorCodigo
    }

    const search_data: any = {
      codigo: cod,
      documento: searchFilter?.documento ?? '',
      codigoLocal: searchFilter.almacen?.codigo ?? -1,
      prodCliente: proCli,
      tipo: searchFilter.inventarioInicial ? 'INVENTARIO' : 'AJUSTES',
      impreso: searchFilter.tipoItem?.value ?? 0,
      movimiento: searchFilter.movimientos?.codigo ?? -1,
      observaciones: searchFilter?.observaciones ?? '',
      estado: searchFilter.items?.codigoBarras ?? '',
      fechaDesde: desde,
      fechaHasta: hasta,
      currentTab,
      consolidado: searchFilter?.cosolidar ? true : false
    }
    try {
      const toAny: any = fetchIncomeExpenses(search_data)
      const res = await dispatch(toAny)
      if (res !== null && res !== undefined && res['payload']?.error === false) {
        setToast(res['payload']?.message, ToastTypes.Success)
        if (res['payload']?.auto && res['payload'].auto.length > 5) {
          dispatch(setColapsado(false))
        }
      } else {
        setToast(res['payload']?.message, ToastTypes.Danger)
      }
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
  }, [searchFilter, currentTab, dispatch, setToast])





  const onMenuButtonClick = React.useCallback(
    async (action: AccionMenu, tab: string = "") => {
      const id = uuidv4()
      switch (action.actionType) {
        case ButtonTypes.find:
          if (tabs.current === 'BUSQUEDA') {
            onSearch()
          } else if (tabs.current === 'VERABONOS') {
            dispatch(buttonClick({
              tabKey: tab === "" ? tabsMovimientos.current : tab,
              button: action.actionType
            }))
          } else dispatch(changeCurrentTab('BUSQUEDA'))
          break
        case ButtonTypes.new:
          dispatch(setVerRegistro(false))
          dispatch(initDatosEdicion({
            key: id,
            data: {
              ...defaultDataIncomeExpenses,
              loading: true
            },
          }))
          dispatch(openTab({ key: id }))
          break;
        case ButtonTypes.edit:
          if (selected === null) {
            setToast(lh.getMessage(MessagesKeys.NotFoundRegisterSelec), ToastTypes.Info)
            return false;
          } else {
            dispatch(buttonClick({
              tabKey: tab === "" ? tabsMovimientos.current : tab,
              button: action.actionType
            }))
          }
          break;
        case ButtonTypes.save:
          if (tabs.current !== 'BUSQUEDA') {
            dispatch(setCurrentFunction(ButtonTypes.save))
            dispatch(
              buttonClick({
                tabKey: tabs.current,
                button: action.actionType,
              }),
            )
          }
          break
        case ButtonTypes.delete:
          if (tabs.current === 'BUSQUEDA') {
            dispatch(buttonClick({
              tabKey: tab === "" ? tabsMovimientos.current : tab,
              button: action.actionType
            }))
          }
          break
        case ButtonTypes.broom:
          if (tabs.current === 'BUSQUEDA') onCleanResultFilter()
          else {
            dispatch(buttonClick({
              tabKey: tab === "" ? tabsMovimientos.current : tab,
              button: action.actionType
            }))
            // dispatch(setCurrentFunction(ButtonTypes.broom))
          }
          break
        case ButtonTypes.series:
          if (tabs.current !== 'BUSQUEDA') {
            dispatch(buttonClick({
              tabKey: tab === "" ? tabsMovimientos.current : tab,
              button: action.actionType
            }))
          }
          break
        case ButtonTypes.lots:
          if (tabs.current !== 'BUSQUEDA') {
            dispatch(buttonClick({
              tabKey: tab === "" ? tabsMovimientos.current : tab,
              button: action.actionType
            }))
          }
          break
        case ButtonTypes.import:
          if (tabs.current !== 'BUSQUEDA') {
            dispatch(buttonClick({
              tabKey: tab === "" ? tabsMovimientos.current : tab,
              button: action.actionType
            }))
          }
          break
        case ButtonTypes.print:
          if (tabs.current === 'BUSQUEDA') {
            setReporte('Viewer')
          } else {
            dispatch(buttonClick({
              tabKey: tab === "" ? tabsMovimientos.current : tab,
              button: action.actionType
            }))
          }
          break
        case ButtonTypes.print_design:
          if (tabs.current === 'BUSQUEDA') {
            setReporte('Designer')
          } else {
            dispatch(buttonClick({
              tabKey: tab === "" ? tabsMovimientos.current : tab,
              button: action.actionType
            }))
          }
          break
        case ButtonTypes.resumen:
          dispatch(openTabResum({ key: 'VERABONOS', type: TabTypes.verAbonos }))
          break
        default:
          dispatch(buttonClick({
            tabKey: tab === "" ? tabsMovimientos.current : tab,
            button: action.actionType
          }))
          break;
      }
    },
    [
      selected, dispatch, onCleanResultFilter,
      tabs, tabsMovimientos, onSearch,
      setToast
    ])

  const onGetCodeModule = React.useCallback(() => {
    dispatch(setAplicacionModulo(parseInt(EAplicationsAcatha.IngresosEgresos)))
  }, [dispatch])

  useEffect(() => {
    if (menuState.length > 0 && options.length === 0) {
      getMenuOptions(menuState, 'to', pathVerificador)
    }
  }, [menuState, getMenuOptions, options])

  React.useEffect(() => {
    onGetCodeModule()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    stopLoader()
    if (searchStatus === FETCH_STATUS.FAILED) {
      dispatch(
        addToast({
          title: 'Buscar clientes',
          content: 'Error al realizar la búsqueda',
          type: ToastTypes.Danger,
        }),
      )
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'BUSQUEDA',
          buttonType: undefined,
        }),
      )
    }
    if (searchStatus === FETCH_STATUS.LOADING) {
      playLoader()
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'BUSQUEDA',
          buttonType: ButtonTypes.find,
        }),
      )
    }
    if (searchStatus === FETCH_STATUS.SUCCESS) {
      stopLoader()
      dispatch(
        setButtons({
          tabKey: 'BUSQUEDA',
          buttons: {
            Nuevo: true,
            Auditoria: true,
            Guardar: false,
            Editar: selected !== null && selected !== undefined,
            Eliminar: selected !== null && selected !== undefined,
            Ver_asiento: false,
            Lotes: false,
            Buscar: true,
            Imprimir: searchResults['auto'] ?? [],
            Importar: false,
            Exportar: false,
            Limpiar: true,
            Series: false,
            Resumen: true,
          },
        }),
      )
    }
  }, [dispatch, searchStatus, searchResults, selected, playLoader, stopLoader]);

  const onActionsButtonsLoaded = React.useCallback((actions: Array<AccionMenu>) => {
    dispatch(setMenuButtons(actions));
  }, [dispatch]);

  const onSearchExecutingAction = React.useCallback((tabKey: string, type?: ButtonTypes) => {
    dispatch(setCurrentExecutingAction({
      tabKey: tabKey,
      buttonType: type
    }))
  }, [dispatch]);

  const onParseData = React.useCallback((ingresosEgresos: Array<any>) => {
    let registros: Array<IngresosEgresosDto> = [];
    let registro: IngresosEgresosDto = null

    if (ingresosEgresos.length > 0) {
      registros = ingresosEgresos.map(item => {
        registro = {
          Code: item?.codigo ?? 0,
          Document: item?.documento ?? '',
          Movement: item?.movimientoDescripcion ?? '',
          Name: item?.productoClienteDescripcion ?? '',
          Store: item?.localCodigoDescripcion ?? '',
          Date: item?.fecha ?? DateUtils.getCurrentDateAsString(),
        }
        return registro
      })
      // registros.push(registro)
    }

    consoleService.log(registros)
    return registros

  }, []);


  return (
    <>
      <ReportWithLocalDataModal
        show={reporte !== null}
        onClose={() => setReporte(null)}
        data={onParseData(searchResults['auto'] ?? [])}
        fileName='StaticIngresosEgresos'
        mode={reporte ?? 'Viewer'}
        parameters={{ 'Reporte_Filtro': getFilterString(searchFilter) }}
        template='StaticIngresosEgresos'
        key='reportDesigner'
      />
      <div>
        <Dialog ref={dialogRef} />
      </div>
      <IngresosEgresosMain
        menu={{ loading: false, accions: options }}
        tabs={tabsMovimientos}
        onTabChanged={onTabChanged}
        codigoModulo={parseInt(EAplicationsAcatha.IngresosEgresos)}
        opciones={OpcionesModeulo}
        onActionsButtonsLoaded={onActionsButtonsLoaded}
        onMenuButtonClick={onMenuButtonClick}
        searching={searchStatus === FETCH_STATUS.LOADING}
        ontabClosing={onTabClosing}
        hayResultadosBusqueda={searchResults.length > 0}
        verificarPuntoVenta
        verificarAutorizacion
        onExecutingAction={onSearchExecutingAction}
        tipoAplicacion={Aplicacion.ingresosEgresos}
      />
    </>
  )
}

export default React.memo(IngresosEgresos)

export const ModuleButtons: CustomButtons = {
  Nuevo: true,
  Guardar: true,
  Editar: false,
  Eliminar: false,
  Buscar: false,
  Imprimir: false,
  Importar: true,
  Lotes: true,
  Limpiar: true,
  Ver_asiento: true,
  Exportar: false,
  Auditoria: true,
  Series: true,
  Resumen: true,
}


const OpcionesModeulo = [parseInt(EAplicationsAcatha.IngresosEgresos)];
