import { combineReducers } from '@reduxjs/toolkit'
import { VentasState } from '../../../types/types'
import { FacturaDatosEdicion } from '../types/types'
import { configuracionesReducer } from './configuracionesVentaReducer'
import { datosEdicionReducer } from './editDataReducer'
import { menuReducer } from './menuReducer'
import { searchVentasReducer } from './searchReducer'
import { tabsReducer } from './tabsReducer'

const ediState: Array<FacturaDatosEdicion> = []

export const ventasReducer = combineReducers<VentasState>({
  menu: menuReducer,
  tabs: tabsReducer,
  search: searchVentasReducer,
  editData: datosEdicionReducer,
  configuraciones: configuracionesReducer,
})
