import { combineReducers } from '@reduxjs/toolkit'
import { tabsReducer } from './tabsReducer'
import { generalReducer } from './generalReducer'
import { TransformacionesState } from '../types/types'
import { dataEditReducer } from './editDataReducer'
import { searchReducer } from './serachReducer'

export const TransformacionesReducer = combineReducers<TransformacionesState>({
  general: generalReducer,
  tabs: tabsReducer,
  editData: dataEditReducer,
  search: searchReducer,
})
