import React, { useEffect } from 'react'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import { KardexList } from '../../types/types'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import { clearButtonClick } from '../../store/tabsReducer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/store'
import { setCurrentFunction } from '../../store/generalReducer'
import { changeCurrentTab } from '../../store/serachReducer'
import {
  CCol,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
} from '@coreui/react-pro'
import FiltroBusqueda from './filtroBusqueda'
import Item from './item'
import Todas from './todas'
import Reservas from './reservas'
import { Draggable, NumberBox, SpeedDialAction } from 'devextreme-react'
import CustomCol from '../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput'
import { TabState } from '../../../../../../store/genericTabTypes'
import { isMobile } from 'react-device-detect'
import { SearchViews } from '../../../items/components/buscar'

interface ISearchProps extends React.PropsWithChildren {
  tab: TabState<KardexList>
  tabId: string
}

const Search: React.FunctionComponent<ISearchProps> = (props) => {
  const { tabId } = props

  const dispatch = useDispatch()
  const currentAction = useSelector(
    (state: RootState) => state.inventarios.kardex.general.currentFunction,
  )
  const currentTabState = useSelector((state: RootState) => {
    return state.inventarios.kardex.search
  })
  const searchResults = useSelector((state: RootState) => {
    return state.inventarios.kardex.search
  })
  const [currentView, setCurrentView] = React.useState<SearchViews>(
    SearchViews.Filter,
  )

  const handleButtonClick = React.useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.find:
          if (isMobile && currentView === SearchViews.Search)
            setCurrentView(SearchViews.Filter)
          break
        default:
          break
      }
      dispatch(setCurrentFunction(''))
      dispatch(clearButtonClick(tabId))
    },
    [dispatch, tabId, currentView],
  )

  useEffect(() => {
    if (currentAction !== '') handleButtonClick(currentAction)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAction])

  return (
    <>
      {isMobile && (
        <Draggable id="list" data="dropArea" group={'appointmentsGroup'}>
          <SpeedDialAction
            icon="filter"
            label="Filtros"
            visible={true}
            index={1}
            onClick={() => {
              setCurrentView(SearchViews.Filter)
            }}
          />
          <SpeedDialAction
            icon="search"
            label="Búsqueda"
            visible={true}
            index={2}
            onClick={() => {
              setCurrentView(SearchViews.Search)
            }}
          />
        </Draggable>
      )}
      {(currentView === SearchViews.Filter || !isMobile) && (
        <FiltroBusqueda
          searchModeMobile={() => {
            if (isMobile) setCurrentView(SearchViews.Search)
          }}
        />
      )}
      {(currentView === SearchViews.Search || !isMobile) && (
        <>
          <RowContainer>
            <CNav variant="tabs" role="tablist" className="mt-4">
              <CNav variant="tabs">
                {currentTabState.tabs.map((tab, index) => (
                  <CNavItem key={index}>
                    <CNavLink
                      onClick={() =>
                        dispatch(changeCurrentTab(currentTabState.tabs[index]))
                      }
                      active={currentTabState.currentTab === tab}
                    >
                      {tab}
                    </CNavLink>
                  </CNavItem>
                ))}
              </CNav>
              <CTabContent
                style={{
                  overflowY: 'unset',
                  width: '100%',
                  marginBottom: '10px',
                }}
              >
                <CTabPane
                  role="tabpanel"
                  aria-labelledby="items-tab"
                  visible={
                    currentTabState.currentTab === currentTabState.tabs[0]
                  }
                >
                  <Item />
                </CTabPane>
                <CTabPane
                  role="tabpanel"
                  aria-labelledby="todos-tab"
                  visible={
                    currentTabState.currentTab === currentTabState.tabs[1]
                  }
                >
                  <Todas />
                </CTabPane>
                <CTabPane
                  role="tabpanel"
                  aria-labelledby="reservas-tab"
                  visible={
                    currentTabState.currentTab === currentTabState.tabs[2]
                  }
                >
                  <Reservas />
                </CTabPane>
              </CTabContent>
            </CNav>
          </RowContainer>
          <RowContainer className="mt-2 d-flex justify-content-end">
            <CustomCol xs="12" md="2">
              <Labeled label="Existencia Mínima:">
                <NumberBox
                  width="70%"
                  value={searchResults.existenciaMin}
                  readOnly={true}
                />
              </Labeled>
            </CustomCol>
            <CustomCol xs="12" md="2">
              <Labeled label="Costo:">
                <NumberBox
                  width="70%"
                  value={searchResults.costo}
                  readOnly={true}
                />
              </Labeled>
            </CustomCol>
            <CustomCol xs="12" md="2">
              <Labeled label="Saldo Reservas:">
                <NumberBox
                  width="70%"
                  value={searchResults.saldoReservas}
                  readOnly={true}
                />
              </Labeled>
            </CustomCol>
          </RowContainer>
          <RowContainer className="mt-2 d-flex justify-content-end">
            <CCol xs="12" md="6" style={{ marginTop: 'auto' }}>
              {
                'Para verificar la referencia del producto haz doble clic sobre el registro deseado.'
              }
            </CCol>
            <CustomCol xs="12" md="2">
              <Labeled label="Existencia Máxima:">
                <NumberBox
                  width="70%"
                  value={searchResults.existenciaMax}
                  readOnly={true}
                />
              </Labeled>
            </CustomCol>
            <CustomCol xs="12" md="2"></CustomCol>
            <CustomCol xs="12" md="2">
              <Labeled label="Saldo a la Fecha:">
                <NumberBox
                  width="70%"
                  value={searchResults.saldoFecha}
                  readOnly={true}
                />
              </Labeled>
            </CustomCol>
          </RowContainer>
        </>
      )}
    </>
  )
}

export default Search
