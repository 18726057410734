import {PaginationInfo} from "../../../../../store/types";
import {TFiltroBusquedaRegimenState, TRegimenListado} from "../store/types";
import {RequestHelper} from "../../../../../helpers/requestHelper";

export const RegimenService = {
  getCollection,
  getResource,
  postResource,
  deleteResource,
}

async function getCollection(filtro: TFiltroBusquedaRegimenState): Promise<any> {
  try {
    const query = buildQuery(filtro);
    const pagedResponse = await RequestHelper.getAll<any>('catalogos', 'getAll', '', query);
    pagedResponse.auto = pagedResponse.auto? parseResponse(pagedResponse.auto) : [];
    return pagedResponse;
  } catch (error) {
    return error;
  }
}

async function getResource(id: any): Promise<any> {
  try {
    // const query = {
    //   codigo: id,
    // };
    // return await RequestHelper.get<any>('province', 'getProvince', '', query);
    return new Promise((resolve, reject) => {});
  } catch (error) {
    return error;
  }
}

async function postResource(formData: any): Promise<any> {
  try {
    const body = {
      infoRegistro: {
        catalogo: '028',
        ...formData,
      }
    };

    return await RequestHelper.postAll<any>('catalogos', 'save', body);
  } catch (error) {
    return error;
  }
}

async function deleteResource(id: any): Promise<any> {
  try {
    const query = {
      codigo: id
    }

    return await RequestHelper.deleteRequestAll<any>('catalogos', 'inactive', '', query);
  } catch (error) {
    return error;
  }
}

// Private
function buildQuery(
  filtro: TFiltroBusquedaRegimenState,
  pagination?: PaginationInfo,
): any {

  const query: any = {
    catalogo: '028',
    ...(filtro.descripcion && { descripcion: filtro.descripcion }),
    ...(filtro.codigoTributario && { codigoTributario: filtro.codigoTributario }),
    estado: filtro.estado,
  }

  if (pagination) {
    query.page = pagination.current
    query.limit = pagination.size
    query.isPaged = true
  }

  const filters = ['catalogo', 'descripcion', 'codigoTributario', 'estado'];

  filters.forEach((field) => {
    if (filtro[field] && filtro[field].length > 0) {
      query[field] = filtro[field]
    }
  })

  return query;
}

function parseResponse(dataApi: any[]): Array<TRegimenListado> {
  return dataApi.map((item: any) => ({
    codigo: item.codigo,
    descripcion: item.descripcion,
    codigoTributario: item.codigoTributario,
    estado: item.estado,
  }))
}
