import React from 'react';
import SelectBox from 'devextreme-react/select-box';
import { projetsService } from '../projets.services';
import { Project } from '../../../../../modulos/shared/types/types'
import { v4 as uuidv4 } from 'uuid'

interface TProjectsLookUpProps extends React.PropsWithChildren {
  selected: Project | null | { codigo: number; locales: any[] }
  // eslint-disable-next-line no-unused-vars
  onChanged: (newValue: Project | null) => void
  // eslint-disable-next-line no-unused-vars
  onChangedOptions?: (newValue: Array<Project> | []) => void
  disabled?: boolean
  addDefaultPosition?: string
}

const ProjectsLookUp: React.FC<TProjectsLookUpProps> = (props) => {
  const {
    onChangedOptions,
    selected,
    onChanged,
    disabled,
    addDefaultPosition,
  } = props
  const selectRef = React.useRef<any>()
  const [options, setOptions] = React.useState<Array<Project> | []>([])

  const loadDatos = React.useCallback(async () => {
    const pro = await projetsService.getProj()
    if (pro.auto && pro.auto.length > 0) {
      const opt = pro.auto
      if (addDefaultPosition && opt.length > 0) {
        opt.forEach((project) => {
          project.locales.unshift({
            codigo: -1,
            nombre: addDefaultPosition,
          })
        })
      }
      setOptions(opt ?? [])
      if (onChangedOptions) onChangedOptions(opt)
      const selectedIndex = opt.findIndex((option) => {
        return option.codigo === selected?.codigo
      })
      if (selectedIndex > -1) {
        onChanged(opt[selectedIndex])
      } else {
        onChanged(opt[0])
      }
    }
  }, [addDefaultPosition, onChangedOptions, onChanged, selected?.codigo])

  const getItemDisplayExpr = React.useCallback((item: Project) => {
    return item ? `${item.nombre}` : ''
  }, [])

  const onLookUpValueChanged = React.useCallback(
    (evt: any) => {
      onChanged(evt)
    },
    [onChanged],
  )

  const onSelectBoxFocusIn = React.useCallback((e) => {
    e.element.querySelector('input.dx-texteditor-input').select()
  }, [])

  const refreshSelected = React.useCallback(() => {
    if (options && options.length > 0) {
      const selectedIndex = options.findIndex((option: Project) => {
        return option.codigo === selected.codigo
      })
      let marcarOption: Project = options[0]
      if (selectedIndex > -1) {
        marcarOption = options[selectedIndex] ?? options[0]
      }
      onChanged(marcarOption)
    }
    selectRef.current.instance.repaint()
  }, [onChanged, options, selected])

  React.useEffect(() => {
    loadDatos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (selected !== null) {
      refreshSelected()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  return (
    <SelectBox
      id={uuidv4() + 'proyectoSelect'}
      key={uuidv4()}
      ref={selectRef}
      items={options ?? []}
      searchEnabled
      displayExpr={getItemDisplayExpr}
      searchExpr={['descripcion']}
      searchTimeout={500}
      value={selected}
      onValueChange={onLookUpValueChanged}
      disabled={disabled}
      onFocusIn={onSelectBoxFocusIn}
      width={'100%'}
    >
      {props.children}
    </SelectBox>
  )
}
export default React.memo(ProjectsLookUp);
