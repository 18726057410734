import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  BusquedaState,
  TransfersListAll,
  SearchFilter,
  TransfersListDetails,
} from '../types/types'
import { FETCH_STATUS, LoaderInfo } from '../../../../../../store/types'
import { LocalOption } from '../../../../../componentes/localesLookUp/store/type'
import { ProgressStatus } from '../../../../../componentes/progresoEstadoLookUp/types/types'

const initialState: BusquedaState<TransfersListAll | TransfersListDetails> = {
  filter: {
    fechaDesde: '',
    fechaHasta: '',
    codigo: '',
    numero: null,
    origen: null,
    destino: null,
    activos: true,
    archivados: false,
    item: '',
    movimiento: '',
    progreso: {
      codigo: 50,
      valor: '',
      tipoCodigo: '',
      grupo: '',
      orden: 0,
      estado: '',
    },
    observaciones: '',
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  resultsHeader: [],
  resultsDetails: [],
  tabs: ['Cabecera', 'Detalle'],
  currentTab: 'Cabecera',
  selectedTransfer: null,
  loader: { show: false, mensaje: '' },
}

const searchSlice = createSlice({
  name: 'buscarTransferencias',
  initialState: initialState,
  reducers: {
    setCleanResult(state) {
      state.resultsHeader = []
    },
    changeFilter(state, action: PayloadAction<SearchFilter>) {
      state.filter.fechaDesde = action.payload.fechaDesde
      state.filter.fechaHasta = action.payload.fechaHasta
      state.filter.codigo = action.payload.codigo
      state.filter.numero = action.payload.numero
      state.filter.activos = action.payload.activos
      state.filter.archivados = action.payload.archivados
      state.filter.item = action.payload.item
      state.filter.movimiento = action.payload.movimiento
      state.filter.observaciones = action.payload.observaciones
    },
    changeOrigin(state, action: PayloadAction<LocalOption>) {
      state.filter.origen = action.payload
    },
    changeDestination(state, action: PayloadAction<LocalOption>) {
      state.filter.destino = action.payload
    },
    changeProgress(state, action: PayloadAction<ProgressStatus>) {
      state.filter.progreso = action.payload
    },
    changeCurrentTab(state, action: PayloadAction<string>) {
      state.currentTab = action.payload
    },
    setSearchResult(state, action: PayloadAction<Array<TransfersListAll>>) {
      state.resultsHeader = action.payload
    },
    setSearchResultDetails(
      state,
      action: PayloadAction<Array<TransfersListDetails>>,
    ) {
      state.resultsDetails = action.payload
    },
    setSelectedTransfer(state, action: PayloadAction<TransfersListAll>) {
      state.selectedTransfer = action.payload
    },
    setSearchLoader(state, action: PayloadAction<LoaderInfo>) {
      state.loader = action.payload
    },
  },
})

export const {
  changeFilter,
  setCleanResult,
  setSearchResult,
  setSearchResultDetails,
  changeOrigin,
  changeDestination,
  changeProgress,
  changeCurrentTab,
  setSelectedTransfer,
  setSearchLoader,
} = searchSlice.actions

export const searchReducer = searchSlice.reducer
