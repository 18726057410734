import { DateUtils, formatoFechasApi } from '../../../../helpers/dateUtils';
import { RequestHelper } from '../../../../helpers/requestHelper'
import { SesionService } from '../../../../services/sesion.service';
import { PartidaArancelaria } from '../types/types';

export const PartidasArancelariasServices = { getTipos }

async function getTipos(message: string): Promise<any> {
  try {
    const sesion = SesionService.getCurrentSesion()
    const data: any = { mensaje: message };
    const keysToCache: any = {
      ...data,
      dateKey: DateUtils.getCurrentDateAsString(formatoFechasApi),
      empresa: sesion?.empresa?.codigo,
    }
    const cacheKey = 'tipoPartidasArancelarias' + JSON.stringify(keysToCache);
    const cached = sessionStorage.getItem(cacheKey);
    if (cached) {
      return { error: false, auto: JSON.parse(cached) };
    } else {
      const resApi = await RequestHelper.getAll<any>(
        'inventario/partidasArancelarias',
        'cBoxLoad',
        '',
        data,
      )
      const opt: Array<PartidaArancelaria> = [];
      if (resApi?.auto && resApi?.auto.length > 0) {
        for (const x of resApi.auto) {
          opt.push({
            codigo: x?.codigo ?? 0,
            designacion: x?.desigancion ?? '',
            estado: x?.estado ?? '',
            estadoDescripcion: x?.estadoDescricion ?? 0,
            observaciones: x?.observaciones ?? '',
            tarifa: Number(x?.hasta) ?? 0,
            unidad: x?.unidad ?? '',
          })
        }
      }
      sessionStorage.setItem(cacheKey, JSON.stringify(opt));
      return resApi
    }
  } catch (error) {
    return error
  }
}
