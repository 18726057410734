import { DateUtils, formatoFechasApi } from '../../../../helpers/dateUtils'
import { RequestHelper } from '../../../../helpers/requestHelper'
import { utilidades } from '../../../../helpers/utilidades'
import { SesionService } from '../../../../services/sesion.service'
import { AplicacionInventario } from '../../../inventario/pages/catalogos/aplicaciones/types/types'

export const aplicationsServices = { getAplications }
async function getAplications(
  mensaje: string = 'Todos',
  pertenece: number = 0,
  descripcion: string = '',
  estado: number = 1,
): Promise<any> {
  try {
    const data: any = { mensaje, pertenece, descripcion, estado }
    const sesion = SesionService.getCurrentSesion()
    const keysToCache: any = {
      ...data,
      dateKey: DateUtils.getCurrentDateAsString(formatoFechasApi),
      empresa: sesion?.empresa?.codigo,
    }
    const cacheKey = 'tipoAplicacionesCatalogos' + JSON.stringify(keysToCache)
    const cached = sessionStorage.getItem(cacheKey)
    if (cached) {
      return { error: false, auto: JSON.parse(cached) }
    } else {
      const aplicationsData = await RequestHelper.getAll<any>(
        'inventario/catalogos',
        'aplicaciones/cBoxLoad',
        '',
        data,
      )
      const aplications: Array<AplicacionInventario> = []
      if (aplicationsData?.auto && aplicationsData?.auto.length > 0) {
        for (const aplication of aplicationsData.auto) {
          aplications.push({
            codigo: aplication?.codigo ?? -1,
            descripcion: utilidades.capText(aplication?.descripcion) ?? '',
            observaciones: aplication?.observaciones ?? '',
            pertenece: aplication?.pertenece ?? -1,
            perteneceDescripcion: aplication?.perteneceDescripcion ?? -1,
            estado: aplication?.estado ?? '',
          })
        }
        sessionStorage.setItem(cacheKey, JSON.stringify(aplications))
        return { error: false, auto: aplications }
      } else
        return {
          error: true,
          message: aplicationsData.message
            ? aplicationsData.message
            : 'Error al obtener los datos',
        }
    }
  } catch (error) {
    return error
  }
}
