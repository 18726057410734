import { cilChevronBottom, cilChevronTop } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CCardBody, CCardHeader, CCollapse, CLink } from '@coreui/react-pro'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import { changeFilter, setCollapsed } from '../../store/searchReducer'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput'
import { RadioGroup, TextBox } from 'devextreme-react'



export const optionsActivos = [
  { value: 1, label: 'Activos' },
  { value: 2, label: 'Inactivos' },
]

interface ISearchProps extends React.PropsWithChildren {
  onSearchEnter: () => void
}


const FiltroBusqueda: React.FunctionComponent<ISearchProps> = (props) => {
  const { onSearchEnter } = props
  const dispatch = useDispatch()
  const filter = useSelector((state: RootState) => state.nomina.registros.horarios.search.filtro)
  const collapsed = useSelector((state: RootState) => state.nomina.registros.horarios.search.colapsado)

  const onChangeValue = useCallback((data, key: string) => {
    dispatch(changeFilter({
      ...filter,
      [key]: data
    }))
  }, [dispatch, filter])


  return (
    <>
      <CCardHeader
        onClick={() => dispatch(setCollapsed(!collapsed))}
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <strong> {collapsed ? '' : 'Filtro de Búsqueda'} </strong>
        <CLink className="card-header-action">
          <CIcon icon={collapsed ? cilChevronBottom : cilChevronTop} />
        </CLink>
      </CCardHeader>
      <CCollapse visible={collapsed}>
        <CCardBody>
          <RowContainer>
            <CustomCol xs="12" md="6" >
              <Labeled label='Descripción:' >
                <TextBox
                  value={filter?.descripcion ?? ''}
                  showClearButton
                  onValueChange={(data) => onChangeValue(data, 'descripcion')}
                  onEnterKey={onSearchEnter}
                />
              </Labeled>
            </CustomCol>
            <CustomCol xs="12" md="6" >
              <Labeled label=''>
                <RadioGroup
                  name="grupos-activos"
                  layout="horizontal"
                  displayExpr="label"
                  items={optionsActivos}
                  value={filter?.estado ?? null}
                  onValueChange={(data) => onChangeValue(data, 'estado')}
                />
              </Labeled>
            </CustomCol>
          </RowContainer>
        </CCardBody>
      </CCollapse>
    </>
  )
}

export default React.memo(FiltroBusqueda)