import React from 'react'
import { CButton, CTooltip } from '@coreui/react-pro'

const ButtonsInfo = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div>
        <CTooltip
          key={'tool-1'}
          placement="top"
          content={'Valor abonado del comprobante inmediato anterior listado.'}
        >
          <div className={''} id="1">
            <CButton id="1" color="info" className="m-1" size="sm">
              <strong>
                {'('} &#92; {')'}
              </strong>
            </CButton>
          </div>
        </CTooltip>
      </div>
      <div>
        <CTooltip
          key={'tool-2'}
          placement="top"
          content={'Comprobantes Anulados.'}
        >
          <div className={''} id="2asterisco">
            <CButton id="2" color="info" className="m-1" size="sm">
              <strong>{'(*)'}</strong>
            </CButton>
          </div>
        </CTooltip>
      </div>
      <div>
        <CTooltip
          key={'tool-3'}
          placement="top"
          content={
            'Si existe un comprobante fuera del rango de fechas y este tiene un abono se considera unicamente el valor del abono dentro de los totales.'
          }
        >
          <div className={''} id="3">
            <CButton id="3" color="info" className="m-1" size="sm">
              <strong>{'?'}</strong>
            </CButton>
          </div>
        </CTooltip>
      </div>
    </div>
  )
}

export default React.memo(ButtonsInfo)
