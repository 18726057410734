import { combineReducers } from "redux";
import { HorariosState } from "../types/types";
import { menuReducer } from "./menuReducer";
import { searchReducer } from "./searchReducer";
import { tabsReducer } from "./tabsReducer";
import { dataEditReducer } from "./editDataReducer";
import { configuracionesHorarios } from "./configReducer";


export const HorariosReducer = combineReducers<HorariosState>({
  menu: menuReducer,
  search: searchReducer,
  tabs: tabsReducer,
  editData: dataEditReducer,
  config: configuracionesHorarios
})