import React from 'react'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput'
import Arbol from '../../../../../contabilidad/pages/planCuentas/components/arbol'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/store'
import { DataGrid, TextBox } from 'devextreme-react'
import { Column, Grouping } from 'devextreme-react/data-grid'
import { ToastTypes } from '../../../../../../store/types'
import { addToast } from '../../../../../../store/toasterReducer'
import { setChangeLoader, setInitital, setResetSeleccion, setResetSeleccionPermisos, setResults, setResultsCommands, setResultsOptions, setSeleccionarDato, setSeleccionarDatoPermisos } from '../../store/permisosReducer'
import { PersonalServices } from '../../services/personal.service'
import { consoleService } from '../../../../../../services/console.service'
import BlockUi from '../../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../../views/componentes/loadingindicator/loadingindicator'
import { TabState } from '../../../../../../store/genericTabTypes'
import { changeCurrentTab, clearButtonClick, setCurrentExecutingAction } from '../../store/tabsReducer'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import VisualizaError from '../../../../../ventas/pages/shared/visualizaError/visualizaError'
import ListErrosValidationForm from '../../../../../componentes/listadoErroresValidacionForm'
import { MessagesKeys, lh } from '../../../../../../helpers/localizationHelper'
import { Permises, PermisesUserDto, SavePermises } from '../../types/types'
import Dialog from '../../../../../../views/componentes/librerias/bootstrap-dialog'
import { deleteEditData } from '../../store/editDataReducer'
import { ReportWithLocalDataModal } from '../../../../../../views/componentes/xtrareports/ReportWithLocalDataModal'
import { menuService } from '../../../../../../services/menu.service'
import { getIndexProvider } from '../../../../../shared/helpers/funciones'

interface IPermisesProps extends React.PropsWithChildren {
  tab: TabState<any>
  tabId: string
}

const Permisos: React.FunctionComponent<IPermisesProps> = (props) => {
  const { tabId, tab } = props
  const dispatch = useDispatch()

  const modulo = useSelector((state: RootState) => state.nomina.personal.config.aplicacion)
  const usuario = useSelector((state: RootState) => state.global.session.usuario)
  const seleccionadoUsuario = useSelector((state: RootState) => state.nomina.personal.search.seleccionado)
  const resultadosPermisos = useSelector((state: RootState) => state.nomina.personal.permisos.resultadosPermisos)
  const resultadosOpciones = useSelector((state: RootState) => state.nomina.personal.permisos.resultadosOpciones)
  const resultadosComandos = useSelector((state: RootState) => state.nomina.personal.permisos.resultadosComandos)
  const seleccionadoComando = useSelector((state: RootState) => state.nomina.personal.permisos.seleccionadoComando)
  const seleccionadoPermiso = useSelector((state: RootState) => state.nomina.personal.permisos.seleccionadoPermiso)
  const inicial = useSelector((state: RootState) => state.nomina.personal.permisos.initial)
  const loader = useSelector((state: RootState) => state.nomina.personal.permisos.loader)
  const tabs = useSelector((state: RootState) => state.nomina.personal.tabs)
  const dialogRef = React.useRef<any>(null)
  const [reporte, setReporte] = React.useState<null | "Viewer" | "Designer">(null);

  const [confirmDelete, setConfirmDelete] = React.useState<boolean>(false)

  const [arrayErrors, setArrayErrors] = React.useState<Array<string>>([])
  const [showErrorPopup, setShowErrorPopup] = React.useState<boolean>(false)
  const setToast = React.useCallback(
    (mensaje: string, tipo: ToastTypes) => {
      dispatch(
        addToast({
          title: 'Nómina - Personal/Permisos',
          content: mensaje,
          type: tipo,
        }),
      )
    },
    [dispatch],
  )

  const onSetButtonAction = React.useCallback(
    (tipo: ButtonTypes | undefined) => {
      dispatch(
        setCurrentExecutingAction({
          tabKey: tabId,
          buttonType: tipo,
        }),
      )
    },
    [dispatch, tabId],
  )

  const onLoader = React.useCallback((showLoader: boolean, mensaje: string) => {
    dispatch(setChangeLoader({
      show: showLoader,
      mensaje: mensaje
    }))
  }, [dispatch])

  const onLoadCommandAsign = React.useCallback(async () => {
    try {
      const data = await PersonalServices.getPermises(seleccionadoUsuario?.codigo ?? 0, 'USUARIO')
      consoleService.log(data, '-----getPermisos')
      if (data['auto'] && data['error'] === false) {
        dispatch(setResults(data['auto']))
      } else if (data['auto'] === "sin roles" && data['error'] === true) {
        dispatch(changeCurrentTab('BUSQUEDA'))
        setToast(data['message'], ToastTypes.Danger)
        dispatch(deleteEditData(tabId))
        return false
      } else {
        dispatch(setResults([]))
        setToast(data['message'], ToastTypes.Danger)
      }
      return true
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
  }, [dispatch, seleccionadoUsuario, setToast, tabId])

  const onLoadChilds = React.useCallback((data: any, padre: number, arrayMenu: any[] = []) => {
    let temp = []
    if (data.length > 0) {
      data.forEach((object => {
        let element: any = ''
        element = {
          codigo: parseInt(object?.codigo) ?? 0,
          numero: '',
          descripcion: object?.name ?? '',
          padre: padre,
          estado: 0,
          observaciones: '',
          acciones: object?.acciones ?? [],
          children: [],
        }
        arrayMenu.push(element)
        // let childs: any = []
        if (object['children'] !== undefined) {
          temp = onLoadChilds(object['children'] ?? [], parseInt(object['codigo']) ?? 0, arrayMenu)
          if (temp.length > 0) {
            arrayMenu.concat(temp)
          }
        }
      }))
    }
    return arrayMenu
  }, [])

  const onLoadData = React.useCallback(async () => {
    onSetButtonAction(ButtonTypes.permissions)
    onLoader(true, 'Cargando...')
    const res = await onLoadCommandAsign()
    if (res) {
      try {
        const tempArray = []
        let newArray = []
        // const menu = await PersonalServices.getMenu(0, seleccionadoUsuario?.codigo ?? 0, usuario?.rol ?? '')
        const menu = await menuService.cargarMenu({ usuario: seleccionadoUsuario?.codigo ?? 0 })
        consoleService.log(menu, '------Menu')
        if (menu['auto'] && menu['error'] === false) {
          newArray = onLoadChilds(menu['auto'], 0, tempArray)
          consoleService.log(newArray, 'array opciones')
          if (newArray.length > 0) {
            dispatch(setResultsOptions(newArray))
          }
        }
      } catch (error) {
        setToast(error.message, ToastTypes.Danger)
      }
    }
    onLoader(false, '')
    onSetButtonAction(undefined)
  }, [onLoader, onLoadCommandAsign, setToast, seleccionadoUsuario, dispatch, onSetButtonAction, onLoadChilds])

  const loadComands = React.useCallback(async (data) => {
    consoleService.log(data, 'arbol data')
    try {
      const command = await PersonalServices.getCommandsAvaiable(seleccionadoUsuario?.codigo ?? 0, data?.id ?? 0, seleccionadoUsuario?.tipo ?? '')
      consoleService.log(command, 'Comands aviable')
      if (command['auto'] && command['error'] === false) {
        dispatch(setResultsCommands(command['auto']))
      } else {
        dispatch(setResultsCommands([]))
        setToast(command['message'], ToastTypes.Danger)
      }
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
  }, [setToast, seleccionadoUsuario, dispatch])


  const onGetDataChange = React.useCallback(async (data) => {
    consoleService.log(data, 'opcion selected')
    dispatch(setSeleccionarDato({ ...data }))
    dispatch(setResultsCommands(data['acciones']))
  }, [dispatch])

  const onDeleteAviableCommand = React.useCallback(async (data) => {
    if (data?.codigo) {
      const provider = resultadosComandos.slice(0)
      const index = await getIndexProvider(provider, 'codigo', data?.codigo ?? 0)
      if (index > -1) {
        provider.splice(index, 1)
        dispatch(setResultsCommands(provider))
      }
    }
  }, [dispatch, resultadosComandos])


  const onSlectionChange = React.useCallback(async ({ data }) => {
    consoleService.log(data, '____data select')
    const errores: Array<string> = []
    if (seleccionadoUsuario === null || seleccionadoUsuario === undefined) {
      errores.push('Seleccione un usuario.')
    }
    if (data === null || data === undefined) {
      errores.push('Seleccione el permiso a asignar.')
    }

    if (errores.length === 0) {
      onSetButtonAction(ButtonTypes.permissions)
      onLoader(true, 'Guardando...')
      try {
        const data_save: SavePermises = {
          infoRegistro: {
            codigo: 0,
            menuBotCodigo: data?.mebcodigo ?? 0,
            asignado: seleccionadoUsuario?.codigo ?? 0,
            tipo: 86,
          }
        }
        const savePermises = await PersonalServices.savePermises(data_save)
        consoleService.log(savePermises, '---savePermises')
        if (savePermises['auto'] && savePermises['error'] === false) {
          setToast(savePermises['message'], ToastTypes.Success)
          dispatch(setResetSeleccion())
          await onLoadCommandAsign()
          onDeleteAviableCommand(data)
        } else {
          setToast(savePermises['message'], ToastTypes.Danger)
        }
      } catch (error) {
        setToast(error.message, ToastTypes.Danger)
      }
      onLoader(false, '')
      onSetButtonAction(undefined)
    } else {
      setToast(lh.getMessage(MessagesKeys.GlobalErrorSave), ToastTypes.Warning)
      setArrayErrors(errores)
      setShowErrorPopup(true)
    }

  }, [seleccionadoUsuario, setToast, onLoadCommandAsign, onLoader, onSetButtonAction, dispatch, onDeleteAviableCommand])

  const onDelteTable = React.useCallback(async (data) => {
    if (data?.codigo) {
      const provider = resultadosPermisos.slice(0)
      const index = await getIndexProvider(provider, 'codigo', data?.codigo ?? 0)
      if (index > -1) {
        provider.splice(index, 1)
        dispatch(setResults(provider))
        dispatch(setResetSeleccionPermisos())
      }
    }
  }, [dispatch, resultadosPermisos])

  const onHandleDelete = React.useCallback(async () => {
    consoleService.log(seleccionadoPermiso)
    onSetButtonAction(ButtonTypes.delete)
    onLoader(true, 'Eliminado...')
    try {
      const permiso = await PersonalServices.deletePermise(seleccionadoPermiso?.codigo ?? 0)
      consoleService.log(permiso, 'delete permiso')
      if (permiso['auto'] && permiso['error'] === false) {
        setToast(permiso['message'], ToastTypes.Success)
        onDelteTable(seleccionadoPermiso)
        if (seleccionadoComando !== null && seleccionadoComando !== undefined) {
          await loadComands(seleccionadoComando)
        }
      } else {
        setToast(permiso['message'], ToastTypes.Danger)
      }
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
    onLoader(false, '')
    onSetButtonAction(undefined)
  }, [seleccionadoPermiso, setToast, onLoader, onSetButtonAction, onDelteTable, loadComands, seleccionadoComando])

  const onConfirmDelete = React.useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Está seguro que desea eliminar el permiso: ${seleccionadoPermiso?.botonDescripcion ?? '-'}?`,
      actions: [
        Dialog.Action(
          <span>
            <u>A</u>ceptar
          </span>,
          (dialog) => {
            dialog.hide()
            onHandleDelete()
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setConfirmDelete(false)
    return
  }, [onHandleDelete, seleccionadoPermiso])

  const onConfirmDel = React.useCallback(() => {
    if (seleccionadoPermiso?.tipo === 86) {
      setConfirmDelete(true)
    } else {
      setToast('No puede eliminar permisos asignados a rol para ello ingrese a Administracion -> Roles', ToastTypes.Warning)
    }
  }, [seleccionadoPermiso, setToast])


  const handleButtonClick = React.useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.delete:
          if (tabs.current === tabId) {
            if (seleccionadoPermiso !== null && seleccionadoPermiso !== undefined) {
              onConfirmDel()
            } else {
              setToast('Debe elegir un permiso para eliminarlo.', ToastTypes.Warning)
            }
          }
          break
        case ButtonTypes.print:
          if (tabs.current === tabId) setReporte('Viewer')
          break
        case ButtonTypes.print_design:
          if (tabs.current === tabId) setReporte('Designer')
          break
        default:
          break
      }
      dispatch(clearButtonClick(tabId))
    }, [dispatch, tabId, setToast, seleccionadoPermiso, tabs, onConfirmDel])

  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab.globalButtonClick])

  React.useEffect(() => {
    if (inicial) {
      dispatch(setResults([]))
      dispatch(setResultsOptions([]))
      dispatch(setResultsCommands([]))
      onLoadData()
      dispatch(setInitital(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSelectedChanged = React.useCallback(
    ({ selectedRowsData }) => {
      if (selectedRowsData[0] && selectedRowsData[0]?.codigo) {
        dispatch(
          setSeleccionarDatoPermisos({ ...selectedRowsData[0] }),
        )
      }
    },
    [dispatch],
  )


  React.useEffect(() => {
    if (confirmDelete) {
      onConfirmDelete()
    }
  }, [confirmDelete, onConfirmDelete])


  const onParseData = React.useCallback(() => {
    const registros: Array<PermisesUserDto> = []
    let permises: Array<Permises> = []
    let permise: Permises
    const usuario = seleccionadoUsuario
    const permisos = resultadosPermisos.slice(0)
    consoleService.log(usuario, permisos)

    if (permisos.length > 0) {
      permises = permisos.map(x => {
        permise = {
          MenuName: x?.menuNombre ?? '',
          ButtonDescription: x?.botonDescripcion ?? '',
          TypeDescription: x?.tipoDescripcion ?? '',
        }
        return permise
      })
    }

    const registro: PermisesUserDto = {
      Identification: usuario?.cedula ?? '',
      User: usuario?.idsesion ?? '',
      Name: usuario?.nombre ?? '',
      CreatioDate: usuario?.fcreacion ?? '',
      Email: usuario?.email ?? '',
      Place: `${usuario?.ciuNombre ?? ''} (${usuario?.proNombre ?? ''})`,
      Cellphone: usuario?.telefono ?? '',
      Permises: permises,
    }
    consoleService.log(registro)
    registros.push(registro)

    return registros
  }, [resultadosPermisos, seleccionadoUsuario])

  return (
    <>
      <ReportWithLocalDataModal
        show={reporte !== null}
        onClose={() => setReporte(null)}
        data={onParseData()}
        fileName='StaticPermisesUser'
        mode={reporte ?? 'Viewer'}
        parameters={{ 'Reporte_Filtro': '' }}
        template='StaticPermisesUser'
        key='reportDesigner'
      />
      <Dialog ref={dialogRef} />
      {showErrorPopup && (
        <VisualizaError
          titulo="Error en Permisos"
          mensaje={'Error al guardar'}
          content={
            <ListErrosValidationForm
              mainMessage={'Revise los siguientes errores:'}
              errorsList={arrayErrors}
              color={'danger'}
            />
          }
          onOk={() => setShowErrorPopup(false)}
        />
      )}
      <div id="PersonalContent">
        <BlockUi
          tag="div"
          loader={LoadingIndicator}
          blocking={loader.show}
          message={loader.mensaje}
        >
          <RowContainer>
            <CustomCol xs="12" md="5">
              <Labeled label=''>
                <TextBox readOnly value={`${seleccionadoUsuario?.nombres ?? '-'} ${seleccionadoUsuario?.apellidos ?? '-'}`} />
              </Labeled>
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol xs="12" md="4">
              <Labeled label='Opciones de Menú::'>
                <Arbol name="ArbolPermisos" data={resultadosOpciones ?? []} jerarquiaName='Opciones' activedense={false} sendData={(data) => onGetDataChange(data)} modulo={modulo} />

              </Labeled>
            </CustomCol>
            <CustomCol xs="12" md="3">
              <RowContainer>
                <CustomCol md="12">

                  <DataGrid
                    id="tablaComandos"
                    dataSource={resultadosComandos ?? []}
                    selection={{ mode: 'single' }}
                    hoverStateEnabled={true}
                    focusedRowEnabled={true}
                    onRowDblClick={onSlectionChange}
                    highlightChanges={true}
                    keyExpr="codigo"
                    key="codigo"
                    focusedRowKey="codigo"
                  >
                    <Column dataField="name" caption='Comandos Disponibles' width="100%" />
                  </DataGrid>
                </CustomCol>
              </RowContainer>
            </CustomCol>
            <CustomCol xs="12" md="5">
              <RowContainer>
                <CustomCol md="12">
                  <DataGrid
                    id="tablaPermisos"
                    dataSource={resultadosPermisos ?? []}
                    selection={{ mode: 'single' }}
                    hoverStateEnabled={true}
                    showBorders={true}
                    showRowLines={true}
                    focusedRowEnabled={true}
                    onSelectionChanged={onSelectedChanged}
                    repaintChangesOnly={true}
                    highlightChanges={true}
                    keyExpr="codigo"
                    key="codigo"
                    focusedRowKey="codigo"
                    showColumnLines={true}
                    className={'m-2 p-2'}
                  >
                    <Grouping autoExpandAll={false} />
                    <Column dataField="indexNombre" groupIndex={0} caption='' width="100%" />
                    <Column dataField="menuNombre" caption="Menu" width="100%" />
                    <Column dataField="botonDescripcion" caption="Accesos" width="100%" />
                    <Column dataField="tipoDescripcion" caption="Pertenece" width="100%" />
                  </DataGrid>
                </CustomCol>
              </RowContainer>
            </CustomCol>
          </RowContainer>
        </BlockUi>
      </div>
    </>
  )
}

export default React.memo(Permisos)