import React from 'react'
import { useDispatch } from 'react-redux'
import { changeLoader } from '../../../../../../store/reducers'
import { addToast } from '../../../../../../store/toasterReducer'
import { CustomButtons, ToastTypes } from '../../../../../../store/types'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import FIleInput from '../../../../../componentes/fileInput'
import { ClientesService } from '../../services/clientes.service'
import BlockUi from '../../../../../../views/componentes/librerias/block-ui'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../views/componentes/colContainer'
import LoadingIndicator from '../../../../../../views/componentes/loadingindicator/loadingindicator'
import { isMobile } from 'react-device-detect'
import { clearButtonClick } from '../../store/tabsReducer'

interface IImporatrProps extends React.PropsWithChildren {
  tabId: string
  tab: any
}

const Importar: React.FunctionComponent<IImporatrProps> = (props) => {
  const dispatch = useDispatch()
  const { tab, tabId } = props
  const [file, setFile] = React.useState<any>([])

  const showEditLoader = React.useCallback(
    (mensaje: string) => {
      dispatch(
        changeLoader({
          mensaje: mensaje,
          show: true,
        }),
      )
    },
    [dispatch],
  )

  const hideEditLoader = React.useCallback(() => {
    dispatch(
      changeLoader({
        mensaje: '',
        show: false,
      }),
    )
  }, [dispatch])

  const onClean = React.useCallback(() => {
    setFile([])
  }, [])

  const downDemo = React.useCallback(async () => {
    try {
      showEditLoader('Exportando...')

      const filename = 'clientes'
      const response = await ClientesService.exportarDemo(filename)

      hideEditLoader()
      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = filename + '.xls'
      a.click()
    } catch (error) {
      hideEditLoader()
      console.error(error)
      dispatch(
        addToast({
          title: 'Exportar Demo',
          content: typeof error == 'string' ? error : JSON.stringify(error),
          type: ToastTypes.Danger,
          autoHide: 3000,
        }),
      )
    }
  }, [dispatch, hideEditLoader, showEditLoader])

  const onImport = React.useCallback(async () => {
    console.log("onImport")
    try {
      if (file.length === 0) {
        dispatch(
          addToast({
            id: '',
            autoHide: true,
            content: 'No se encuentra una plantilla para importar.',
            fade: true,
            title: 'Importar',
            type: ToastTypes.Danger,
          }),
        )
        return false
      }
      showEditLoader('Importando...')

      if (file && file.length > 0) {
        const upload = {
          file: file[0]
        }
        const subirPlantilla = await ClientesService.plantillaImportar(upload);
        hideEditLoader();
        console.log("subirPlantilla", subirPlantilla);
        if (subirPlantilla.includes('readable')) {
          dispatch(addToast({
            id: '',
            autoHide: true,
            content: subirPlantilla,
            fade: true,
            title: 'Importar',
            type: ToastTypes.Warning
          }));
          return false;
        }

        dispatch(addToast({
          id: '',
          autoHide: true,
          content: subirPlantilla.message,
          fade: true,
          title: 'Importar',
          type: ToastTypes.Success
        }));

      }
    } catch (error) {
      hideEditLoader()
      let sms = ''
      const capturaError: any = error
      if (capturaError && capturaError.message) {
        sms = capturaError.message
      } else {
        sms =
          'Error al importar la plantilla ' +
          (typeof capturaError === 'string'
            ? capturaError
            : JSON.stringify(capturaError))
      }
      dispatch(
        addToast({
          id: '',
          autoHide: true,
          content: sms,
          fade: true,
          title: 'Importar',
          type: ToastTypes.Danger,
        }),
      )
    }
  }, [file, dispatch, hideEditLoader, showEditLoader])

  const deshacer = React.useCallback(() => {
    console.log('deshacer')
  }, [])

  const handleButtonClick = React.useCallback(
    (button: ButtonTypes) => {
      switch (button) {
        case ButtonTypes.save: {
          onImport()
          break
        }
        case ButtonTypes.undo:
          deshacer()
          break
        case ButtonTypes.import:
          onImport()
          break
        default:
          break
      }
      dispatch(clearButtonClick(tabId))
    },
    [deshacer, onImport],
  )

  const onFileChanged = React.useCallback(
    (file: any) => {
      if (file === null || file.value.length === 0) onClean()
      else if (file.value.length > 0) setFile(file.value)
    },
    [onClean],
  )

  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
  }, [handleButtonClick, tab.globalButtonClick])

  return (
    <div style={{ padding: '10px', overflowX: 'hidden' }} className='mb-4' >
      <BlockUi
        tag="div"
        loader={LoadingIndicator}
        blocking={false}
        message={''}
      >
        <fieldset>
          <div>
            <RowContainer>
              <CustomCol xs="12">
                <FIleInput
                  accept={'application/vnd.ms-excel'}
                  onChanged={onFileChanged}
                  downDemo={downDemo}
                />
              </CustomCol>
            </RowContainer>
          </div>
        </fieldset>
        {isMobile && <div style={{ height: '40px' }} />}
      </BlockUi>
    </div>
  )
}
export const ModuleButtonsImport: CustomButtons = {
  Nuevo: false,
  Guardar: true,
  Buscar: true,
  Deshacer: true,
  Editar: false,
  Imprimir: false,
  Importar: true,
}
export default React.memo(Importar)
