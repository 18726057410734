import { RequestHelper } from '../../../../../../../helpers/requestHelper'
import { SaveUnit } from '../../../types/types'

export const unitsServices = {
  getUnits,
  deleteUnit,
  saveUnit,
}

async function getUnits(name: string, status: number): Promise<any> {
  try {
    const res = await RequestHelper.getAll<any>(
      'inventario/unidades',
      'listar',
      '',
      { nombre: name, estado: status },
    )
    return res
  } catch (error) {
    return error
  }
}

async function deleteUnit(code: number): Promise<any> {
  try {
    const res = await RequestHelper.deleteRequestAll<any>(
      'inventario/catalogos',
      'unidades/delete',
      '',
      { codigo: code },
    )
    return res
  } catch (error) {
    return error
  }
}

async function saveUnit(unit: SaveUnit): Promise<any> {
  try {
    const res = await RequestHelper.postAll<any>(
      'inventario/catalogos',
      'unidades/save',
      { ...unit },
    )
    return res
  } catch (error) {
    return error
  }
}
