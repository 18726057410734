/* eslint-disable no-useless-catch */
import config from '../config/config'
import { UrlHelper } from './urlHelper'
import axios, { AxiosRequestConfig, Method, AxiosError } from 'axios'
import { Auth } from 'aws-amplify'
import {
  ApiRequestEntityResult,
  cerrarSesionOnUnauthorized,
} from './requestHelper'
import { ENetworkStates } from './enum/requestType'

export const RequestHelperCountries = {
  post,
  postAll,
  postDataNoSession,
}

export const API_URL = `${UrlHelper.getUrls().apiDotNetPathMiddlewareSalvador}${
  config.slvApiVersionMiddleware
}`

function validateStatusAxios(status) {
  return status >= 200 && status < 500 // default
}

async function getConfigAsync(
  method: Method,
  contentype = 'application/json',
): Promise<AxiosRequestConfig> {
  const tokenEmpresa = sessionStorage.getItem('tokenEmpresa')
  const currentSesion = await Auth.currentSession()
  const token = currentSesion.getIdToken()
  const sessionId = localStorage.getItem('sesionIdentificador') ?? ''

  return {
    method: method,
    headers: {
      authorization: tokenEmpresa,
      client_id: config.CLIENT_ID,
      secret_key: config.SECRET_KEY,
      'x-csrf-token': token.getJwtToken(),
      'Content-Type': contentype,
      'Session-ID': sessionId,
    },
    validateStatus: validateStatusAxios,
  }
}

async function getConfigAsyncNoSession(
  method: Method,
  contentype = 'application/json',
): Promise<AxiosRequestConfig> {
  const tokenEmpresa = sessionStorage.getItem('tokenEmpresa')
  const currentSesion = await Auth.currentSession()
  const token = currentSesion.getIdToken()
  const sessionId = localStorage.getItem('sesionIdentificador') ?? ''

  return {
    method: method,
    headers: {
      authorization: tokenEmpresa,
      client_id: config.CLIENT_ID,
      secret_key: config.SECRET_KEY,
      'x-csrf-token': token.getJwtToken(),
      'Content-Type': contentype,
      'Session-ID': sessionId,
    },
    validateStatus: validateStatusAxios,
  }
}

function getRequestUrl(
  modulo: string,
  controller: string,
  key: string | null,
  query: any | null,
): string {
  const url = `${API_URL}/${modulo}${
    controller.length > 0 ? '/' : ''
  }${controller}${key === null ? '' : key}`
  let params = '?'
  if (query !== null && query !== undefined) {
    const keys = Object.keys(query)
    for (let index = 0; index < keys.length; index++) {
      const value = query[keys[index]]
      params += `${keys[index]}=${value}&`
    }
  }
  params = params.slice(0, -1)
  return url + params
}

async function post<T>(
  modulo: string,
  controller: string,
  data: any | null,
): Promise<any> {
  try {
    const url = getRequestUrl(modulo, controller, null, null)
    const cfg = await getConfigAsync('POST')
    const postResult = await axios.post(url, data, cfg)
    if (postResult.status === 200) {
      if (postResult.data.error) {
        throw postResult.data.message
      } else {
        const apiData = postResult.data as ApiRequestEntityResult<T>
        return apiData.auto
      }
    } else if (postResult.status === 401) {
      cerrarSesionOnUnauthorized()
    }
    throw postResult.statusText
  } catch (error) {
    throw new Error(error)
  }
}

async function postAll<T>(
  modulo: string,
  controller: string,
  data: any | null,
): Promise<T> {
  try {
    const url = getRequestUrl(modulo, controller, null, null)
    const cfg = await getConfigAsync('POST')

    const postResult = await axios.post(url, data, cfg)
    if (postResult.status === 401) {
      cerrarSesionOnUnauthorized()
    }
    return postResult.data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError
      const mensajeError = manejarErrorAxios(axiosError)
      throw mensajeError
    }
    throw error
  }

  // const url = getRequestUrl(modulo, controller, null, null)
  // const cfg = await getConfigAsync('POST')
  // const postResult = await axios
  //   .post(url, data, cfg)
  //   .then((response) => {
  //     if (response.status === 401) {
  //       cerrarSesionOnUnauthorized()
  //     }
  //     return response.data
  //   })
  //   .catch((error: Error | AxiosError) => {
  //     console.error('CatchError:', error)
  //     console.error('CatchError.message:', error.message)
  //     return error
  //   })
  // return postResult
}

async function postDataNoSession<T>(
  modulo: string,
  controller: string,
  data: any | null,
): Promise<T> {
  try {
    const url = getRequestUrl(modulo, controller, null, null)
    const formdata = new FormData()
    for (const clave in data) {
      formdata.append(clave, data[clave])
    }
    const cfg = await getConfigAsyncNoSession('POST', 'multipart/form-data')
    const postResult = await axios.post(url, formdata, cfg)
    throw postResult
  } catch (error) {
    throw error
  }
}

// Función para manejar los errores de Axios
function manejarErrorAxios(error: AxiosError): string {
  if (error.response) {
    // Si hay una respuesta del servidor con un código de error
    const status = error.response.status
    switch (status) {
      case 400: // Bad Request
        return ENetworkStates.BadRequest
      case 401: // Unauthorized
        return ENetworkStates.Unauthorized
      case 403: // Forbidden
        return ENetworkStates.Forbidden
      case 404: // Not Found
        return ENetworkStates.NotFound
      case 405: // Method Not Allowed
        return ENetworkStates.MethodNotAllowed
      case 500: // Internal Server Error
        return ENetworkStates.InternalServerError
      case 502: // Bad Gateway
        return ENetworkStates.BadGateway
      // Agregar más casos según sea necesario
      default:
        return ENetworkStates.errorDefault
    }
  } else if (error.request) {
    // Si la solicitud no recibió respuesta del servidor
    return ENetworkStates.errorConexion
  } else {
    // Otros errores, como errores de configuración
    return ENetworkStates.errorSolicitud
    // return `${ENetworkCause.errorSolicitud} ${error.message}`
  }
}
