import { DateUtils, formatoFechasApi } from "../../../../helpers/dateUtils";
import { RequestHelper } from "../../../../helpers/requestHelper";
import { utilidades } from "../../../../helpers/utilidades";
import { SesionService } from "../../../../services/sesion.service";
import { ImpresionOption } from "../store/type";

export const impresionService = { getImpresiones };

async function getImpresiones(label: string, codigoTic: string = '01'): Promise<any> {
  try {
    const sesion = SesionService.getCurrentSesion()
    const data: any = {
      mensaje: label,
      codigoTic: codigoTic,
      electronica: true
    };
    const keysToCache: any = {
      ...data,
      dateKey: DateUtils.getCurrentDateAsString(formatoFechasApi),
      empresa: sesion?.empresa?.codigo,
    }
    const cacheKey = 'tipoImpresiones' + JSON.stringify(keysToCache);
    const cached = sessionStorage.getItem(cacheKey);
    if (cached) {
      return { error: false, auto: JSON.parse(cached) };
    } else {
      const res = await RequestHelper.getAll<any>('generales/impressions', 'cboxLoad', "", data);

      const opt: Array<ImpresionOption> = [];

      if (res?.auto && res?.auto.length > 0) {
        for (const x of res.auto) {
          opt.push({
            codigo: parseInt(x.codigoImpresion) ?? -1,
            descripcion: utilidades.capText(x?.nombre) ?? '',
            codigoTic: x.codigoTic ?? 0,
            comprobanteTic: x.comprobanteTic ?? '',
            archivoImpresion: x.archivoImpresion ?? '',
            limiteImpresion: x.limiteImpresion,
            electronica: x.electronica === 1 ? true : false,
          })
        }
        res.auto = opt
      }
      sessionStorage.setItem(cacheKey, JSON.stringify(opt));


      return res;
    }

  } catch (error) {
    return error;
  }
}

