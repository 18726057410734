import React from 'react';
import { RootState } from '../../../store/store';
import {
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle, CRow, CCol
} from '@coreui/react-pro'
import { setAlert } from "../../../store/reducers"
import { useDispatch, useSelector } from 'react-redux'

interface IGlobalAlertProps extends React.PropsWithChildren {
  buttons?: React.ReactElement,
  titleStrong?: boolean,
  component?: React.ReactElement
}
// tipo => warning" | 'success' | 'info' | 'danger'
const GlobalAlert = (props: IGlobalAlertProps) => {
  const dispatch = useDispatch()

  const alertState = useSelector((state: RootState) => state.global.alert);

  const listWarnings = alertState.detalles.map((key, i) =>
    <li key={i} className="m-0" >
      {key}
    </li>
  );

  const putTitle = () => {
    if (props.titleStrong && props.titleStrong === true) {
      return (
        <>
          <strong>
            {alertState.mensaje}
          </strong>
        </>
      )
    } else {
      return (
        <>
          <label>
            {alertState.mensaje}
          </label>
        </>
      )
    }
  }

  const generateTexto = (texto) => {
    const toString = String(texto);
    return (
      <div dangerouslySetInnerHTML={{ __html: toString }}></div>
    )
  }

  const generateBody = () => {
    if (props.component) {
      return (
        <>
          {props.component}
        </>
      )
    } else {
      return (
        <>
          {listWarnings && listWarnings.length > 0 && (
            <ul style={{ listStyle: "none", marginLeft: '-40px' }}>
              {
                listWarnings.length > 0 && listWarnings.map((m, i) => {
                  return (
                    <div key={i}>
                      <li key={i}>
                        {generateTexto(m)}
                      </li>
                    </div>
                  )
                })
              }
            </ul>
          )}
        </>
      )
    }

  }

  return (
    <>
      <CModal
        visible={alertState.show}
        onClose={() => {
          dispatch(setAlert({
            alertTitle: "ácatha",
            show: false,
            mensaje: "",
            detalles: []
          }))
        }}
        size={"sm"}
        // closeOnBackdrop={false}
        key={'alertModal'}
        alignment='center'
        color={alertState.tipo ?? ""}
      >
        <CModalHeader closeButton>
          <CModalTitle>{alertState.alertTitle ?? "ácatha"}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          {props.titleStrong && (
            <CRow>
              <CCol className="m-0">
                {putTitle()}
              </CCol>
            </CRow>
          )}
          <CRow>
            <CCol className="mt-0">
              {generateBody()}
            </CCol>
          </CRow>
        </CModalBody>
        {props.buttons && (
          <CModalFooter>
            {props.buttons}
          </CModalFooter>
        )}
      </CModal>
    </>
  );
}
export default GlobalAlert;