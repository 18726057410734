import { GeneralesState } from '../types/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LoaderInfo } from '../../../../../../store/types'

const initialState: GeneralesState = {
  modulo: 'inventarios',
  currentAction: 'Buscar',
  currentFunction: '',
  aplicacion: 'movimientos/transferencias',
  loader: {
    show: false,
    mensaje: '',
  },
  isChecked: false,
  collapse: true,
}

const transfersSlice = createSlice({
  name: 'transferenciasMov',
  initialState: initialState,
  reducers: {
    setIsChecked(state, action: PayloadAction<boolean>) {
      state.isChecked = action.payload
    },
    setCurrentAccion(state, action: PayloadAction<string>) {
      state.currentAction = action.payload
    },
    setCurrentFunction(state, action: PayloadAction<string>) {
      state.currentFunction = action.payload
    },
    changeLoaderTransfers(state, action: PayloadAction<LoaderInfo>) {
      state.loader = action.payload
    },
    setCollpased(state, action: PayloadAction<boolean>) {
      state.collapse = action.payload
    },
  },
})

export const {
  setCurrentAccion,
  setCurrentFunction,
  changeLoaderTransfers,
  setIsChecked,
  setCollpased,
} = transfersSlice.actions

export const generalReducer = transfersSlice.reducer
