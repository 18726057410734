import React from 'react'
import Modalform from '../../../views/componentes/modalform'
import { CCard, CCardBody, CCardHeader } from '@coreui/react-pro'
import { LoadPanel } from 'devextreme-react'
import { GlobalMenu } from '../../../views/componentes/globalMenu/globalMenu'
import { AccionMenu, ToastTypes } from '../../../store/types'
import { ButtonTypes } from '../../../views/componentes/globalMenu/types'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import RowContainer from '../../../views/componentes/rowContainer/rowContainer'
import { addToast } from '../../../store/toasterReducer'
import { changeLoader } from '../../../store/reducers'
import DataGrid, { Column, ColumnChooser, Export, Pager, Paging } from 'devextreme-react/data-grid'
import { getAllowedPageSizes } from '../../../helpers/Helper'
import ItemsCountIndicator from '../indicadorNroRegistros'
import { Workbook } from 'exceljs'
import { exportDataGrid } from 'devextreme/excel_exporter';
import { saveAs } from 'file-saver';


interface IModalVisualizarPorCrear extends React.PropsWithChildren {
  onCancel: () => void
  show: boolean
  porCrear: Array<any>
}
const menuDefecto = [
  // {
  //   icon: 'fal fa-save',
  //   id: 'Exportar',
  //   text: 'Exportar',
  //   type: ButtonTypes.export,
  // },
]


export const ModalVisualizarPorCrear: React.FC<IModalVisualizarPorCrear> = (props) => {

  const { onCancel, show, porCrear } = props
  const dispatch = useDispatch()

  const dataGrid = React.useRef<any>()
  const [resultados, setResultados] = React.useState([])
  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(
      addToast({
        title: 'Modificar Precio Item',
        content: mensaje,
        type: tipo,
      }),
    )
  }, [dispatch])


  const playLoader = React.useCallback(() => {
    dispatch(changeLoader({ show: true, mensaje: 'Cargando...' }))
  }, [dispatch])

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoader({ show: false, mensaje: '' }))
  }, [dispatch])

  const botones = menuDefecto.map(acc => {
    const boton: AccionMenu = {
      nombre: acc.id,
      icon: acc.icon,//as any,
      actionType: acc.type,
      main: false,
      modal: false,
      shortcut: null
    }
    return boton;
  });

  const [acciones,] = React.useState<Array<AccionMenu>>(botones);
  const loader = useSelector((state: RootState) => state.global.loader);


  const returnAction = (boton: AccionMenu) => {

    switch (boton.actionType) {
      case ButtonTypes.export:
        console.log('Exportar')
        break
      default:
        break;
    }
  }


  const getButtonDisabled = React.useCallback((button: AccionMenu) => {
    switch (button.actionType) {
      case ButtonTypes.export:
        return true

      default:
        return false;
    }
  }, [])



  const botonesModal = () => {
    return (

      <GlobalMenu
        acciones={acciones}
        onClick={returnAction}
        getButtonDisabled={getButtonDisabled}
      />

    );
  }

  const onExportExcell = React.useCallback((e) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('Main sheet')
    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Items_PorCrear.xlsx')
      });
    });
  }, [])


  const bodyModal = () => {

    return (
      <>
        <ItemsCountIndicator items={resultados} />
        <RowContainer>
          <DataGrid
            style={{ marginTop: '10px' }}
            ref={dataGrid}
            id="codigo"
            focusedRowKey="codigo"
            selection={{ mode: 'single' }}
            dataSource={porCrear}
            showColumnLines={true}
            showRowLines={true}
            showBorders={true}
            onExporting={onExportExcell}
            columnResizingMode="widget"
            loadPanel={{
              enabled: true,
              height: 90,
              indicatorSrc: '',
              shading: false,
              shadingColor: '',
              showIndicator: true,
              showPane: true,
              text: 'Cargando...',
              width: 200,
            }}
          >
            <Paging defaultPageSize={10} />
            <Pager
              visible={false}
              allowedPageSizes={getAllowedPageSizes(0)}
              displayMode={'full'}
              showPageSizeSelector={true}
              showInfo={true}
              showNavigationButtons={true}
            />
            <ColumnChooser enabled={true} mode="select" />
            <Export enabled={true} allowExportSelectedData={true} />
            <Column dataField="1" caption="A" width="100px" />
            <Column dataField="2" caption="B" width="100px" />
            <Column dataField="3" caption="C" width="100px" />
            <Column dataField="4" caption="D" width="100px" />
            <Column dataField="5" caption="E" width="100px" />
            <Column dataField="6" caption="F" width="100px" />
            <Column dataField="7" caption="G" width="100px" />
            <Column dataField="8" caption="H" width="100px" />
            <Column dataField="9" caption="I" width="100px" />
            <Column dataField="10" caption="J" width="100px" />
            <Column dataField="11" caption="K" width="100px" />
            <Column dataField="12" caption="L" width="100px" />
            <Column dataField="13" caption="M" width="100px" />
            <Column dataField="14" caption="N" width="100px" />
            <Column dataField="15" caption="O" width="100px" />
            <Column dataField="16" caption="P" width="100px" />
            <Column dataField="17" caption="Q" width="100px" />
            <Column dataField="18" caption="R" width="100px" />
            <Column dataField="19" caption="S" width="100px" />
            <Column dataField="20" caption="T" width="100px" />
            <Column dataField="21" caption="U" width="100px" />
            <Column dataField="22" caption="V" width="100px" />
            <Column dataField="23" caption="W" width="100px" />
            <Column dataField="24" caption="X" width="100px" />
            <Column dataField="25" caption="Y" width="100px" />
            <Column dataField="26" caption="Z" width="100px" />
          </DataGrid>
        </RowContainer>
      </>
    )
  }


  const renderContent = () => {
    return (
      <CCard>
        <CCardHeader>
          {botonesModal()}
        </CCardHeader>
        <CCardBody>
          <LoadPanel
            shadingColor="rgba(0,0,0,0.4)"
            position={'center'}
            visible={loader.show}
            showIndicator={true}
            shading={true}
            showPane={true}
            message={loader.mensaje}
            closeOnOutsideClick={false}
          />
          {bodyModal()}
        </CCardBody>
      </CCard>
    )
  }


  return (
    <>
      <Modalform
        name={'verAuditoria'}
        key={'verAuditoria'}
        headerTitle={'Auditoria'}
        childrenBody={renderContent()}
        closeOnBackdrop={false}
        show={show}
        onClose={() => onCancel()}
        centered={true}
        size='lg'
      />
    </>
  )
}
