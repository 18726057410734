import { TipoAgente, TipoNegocio } from '../../../../../store/types'

export enum TIPOS_BOTONES {
  BOTON_GUARDAR = 'Guardar',
}

export type TransaccionesContador = {
  transacciones: string
  contador: number
}

export type TRimpe = {
  codigo: number
  descripcion: string
}

export type EmpresaDatos = {
  codigo: number
  logo: string
  ruc: string
  nombre: string
  nombreComercial: string
  direccion: string
  telefono: string
  email: string
  tipoIdentificacionRep: number
  identificacionRep: string
  rucContador: string
  barrasCodigo: string
  path: string
  pathWeb: string
  pathBD: string
  ipWeb: string
  inventarios: number
  transparencia: string
  proforma: string
  precision: number
  kardex: number
  tSesion: number
  distinguir: number
  preguntarImprimir: number
  tipoAgente: number
  preciosIva: number
  adicionar: number
  iProforma: string
  mayusculas: number
  cuotasC: number
  cuotasV: number
  imgMovile: number
  movimientoUnico: number
  auditoria: number
  idiomas: number
  impcodigo: number
  retencionC: number
  retencionV: number
  unico: number
  orden: number
  ccostos: number
  pagosC: number
  cobrosC: number
  costoVentas: number
  modulos: string
  codigoCreditos: string
  transferencias: string
  codigoInterno: number
  reconstruccion: number
  vendedor: number
  diasBackup: string
  horaBackup: number
  minutoBackuo: number
  ubicacionBackup: string
  consignacion: number
  actualizarPrecio: number
  solcaVentas: number
  estado: number
  modoFacturacion: number
  modoNotaCredito: number
  modoNotaDebito: number
  modoRetencion: number
  modoGuia: number
  tipoAmbiente: number
  numeroIntentos: number
  modoEnvio: number
  secuencialNumIndefinido: number
  urlPruebas: string
  urlProduccion: string
  rutaWebVentaFE: string
  envioEmail: number
  contribuyente: string
  timeoutFE: number
  creditos: number
  limiteCreditos: number
  clipro: number
  maxConsumidorFinal: number
  cargaPos: number
  tipoLic: number
  numUsu: number
  descuentosG: number
  emails: string
  isd: number
  biometrico: string
  verificar: number
  numeroRoles: number
  listaPrecios: number
  trfSalidas: number
  wooUrl: string
  wooApiKey: string
  wooApiSecretKey: string
  transferenciasEntreAlmacenes: number
  archivoImpActas: string
  cabeceraActas: string
  pieActas: string
  representante: string
  rcClaveSitio: string
  rcClaveSecreta: string
  sustento: number
  diasCuota: number
  usoEquipo: number
  pvpActualProforma: number
  uPrecio: number
  uaf: string
  esquema: number
  series: number
  acAmbiente: number
  academiProduccion: string
  academiPruebas: string
  movimientosTransferencias: number
  unificarnomina: number
  impuesto332: number
  codigoTecnologia: number
  msjRecemail: string
  msjVenemail: string
  msjRecsms: string
  msjVensms: string
  numeroTransacciones: number
  transacciones: Array<TransaccionesContador> | []
  correoFE: string
  passwordFE: string
  actPrecioTrf: number
  iPlantilla: number
  itemSnc: number
  numComprobantes: number
  participacion: number
  formaPagoCompra: number
  formaPagoVenta: number
  tipoNombre: number
  mailChimp: string
  alertaStock: number
  validaPos: number
  cliDirecto: number
  creacionCortaCli: number
  peso: number
  cambioPass: number
  modoLiquidacion: number
  consignacionInventario: number
  codigoBarrasCorto: number
  leyendaCE: string
  serverSmtp: string
  puertoSmtp: number
  seguridadSmtp: string
  guiaFactura: number
  pvpPorUtilidad: number
  cupo: number
  buscarConStock: number
  usuarioIndu: string
  passwordIndu: string
  usuarioDokan: string
  passwordDokan: string
  creacionItems: number
  precioFinalC: number
  microempresa: number
  aRetencion: number
  logs: number
  planId: number
  tipoNegocioId: number
  ipBase: string
  bancosEmpresaClientes: number
  stockMinimo: number
  stockMaximo: number
  uafu: string
  contaRec: number
  configurado: boolean
  rimpeCodigo: number | null
  rimpeLeyenda: string | null
  codigoParaComprobantes: number
  nuevasImpresiones: number
  utilizarImpuestos: number
  recintoFiscal: string
  regimen: string
  tipoItemExporta: number
}

export type TipoIdentificacion = {
  codigo: number
  tipo: string
}

export type OptionCodigoComprobantes = {
  id: number
  text: string
}

// ArchivoExternos son archivos .php personalizados para cada empresa
export type ArchivoExternos = {
  codigo: string // codigo ejemplo proformapdfSM, proformapdfCS
  descripcion: string
}

// TipoAgente {
//   codigo: Number;
//   descripcion: string;
// }

// TipoNegocio {
//   codigo: Number;
//   descripcion: string;
// }

type TabFacturacionElectronica = {
  configuracion: EdicionDatosFacturacionElectronica
  recumenComprobantes: EdicionDatosResumenComprobantes
}

export type EmpresaState = {
  modulo: string
  currentAction: string
  currentFunction: string
  currentTab: number
  currentSubTab: number
  dpTipoIdentificaciones: Array<TipoIdentificacion> | []
  dpTipoAgente: Array<TipoAgente> | []
  dpTipoNegocio: Array<TipoNegocio> | []
  dpRimpeClasificacion: Array<TRimpe> | []
  datosEmpresa: EmpresaDatos
  datosGenerales: EdicionDatosGenerales
  datosDocumentos: EdicionDatosDocumentos
  datosFacturacionElectronica: TabFacturacionElectronica
}

export const defaultDatosEmpresa: EmpresaDatos = {
  codigo: 0,
  logo: '',
  ruc: '',
  nombre: '',
  nombreComercial: '',
  direccion: '',
  telefono: '',
  email: '',
  tipoIdentificacionRep: 0,
  identificacionRep: '',
  rucContador: '',
  barrasCodigo: '',
  path: '',
  pathWeb: '',
  pathBD: '',
  ipWeb: '',
  inventarios: 0,
  transparencia: '',
  proforma: '',
  precision: 0,
  kardex: 0,
  tSesion: 0,
  distinguir: 0,
  preguntarImprimir: 0,
  tipoAgente: 0,
  preciosIva: 0,
  adicionar: 0,
  iProforma: '',
  mayusculas: 0,
  cuotasC: 0,
  cuotasV: 0,
  imgMovile: 0,
  movimientoUnico: 0,
  auditoria: 0,
  idiomas: 0,
  impcodigo: 0,
  retencionC: 0,
  retencionV: 0,
  unico: 0,
  orden: 0,
  ccostos: 0,
  pagosC: 0,
  cobrosC: 0,
  costoVentas: 0,
  modulos: '',
  codigoCreditos: '',
  transferencias: '',
  codigoInterno: 0,
  reconstruccion: 0,
  vendedor: 0,
  diasBackup: '',
  horaBackup: 0,
  minutoBackuo: 0,
  ubicacionBackup: '',
  consignacion: 0,
  actualizarPrecio: 0,
  solcaVentas: 0,
  estado: 0,
  modoFacturacion: 0,
  modoNotaCredito: 0,
  modoNotaDebito: 0,
  modoRetencion: 0,
  modoGuia: 0,
  tipoAmbiente: 0,
  numeroIntentos: 0,
  modoEnvio: 0,
  secuencialNumIndefinido: 0,
  urlPruebas: '',
  urlProduccion: '',
  rutaWebVentaFE: '',
  envioEmail: 0,
  contribuyente: '',
  timeoutFE: 0,
  creditos: 0,
  limiteCreditos: 0,
  clipro: 0,
  maxConsumidorFinal: 0,
  cargaPos: 0,
  tipoLic: 0,
  numUsu: 0,
  descuentosG: 0,
  emails: '',
  isd: 0,
  biometrico: '',
  verificar: 0,
  numeroRoles: 0,
  listaPrecios: 0,
  trfSalidas: 0,
  wooUrl: '',
  wooApiKey: '',
  wooApiSecretKey: '',
  transferenciasEntreAlmacenes: 0,
  archivoImpActas: '',
  cabeceraActas: '',
  pieActas: '',
  representante: '',
  rcClaveSitio: '',
  rcClaveSecreta: '',
  sustento: 0,
  diasCuota: 0,
  usoEquipo: 0,
  pvpActualProforma: 0,
  uPrecio: 0,
  uaf: '',
  esquema: 0,
  series: 0,
  acAmbiente: 0,
  academiProduccion: '',
  academiPruebas: '',
  movimientosTransferencias: 0,
  unificarnomina: 0,
  impuesto332: 0,
  codigoTecnologia: 0,
  msjRecemail: '',
  msjVenemail: '',
  msjRecsms: '',
  msjVensms: '',
  numeroTransacciones: 0,
  transacciones: [],
  correoFE: '',
  passwordFE: '',
  actPrecioTrf: 0,
  iPlantilla: 0,
  itemSnc: 0,
  numComprobantes: 0,
  participacion: 0,
  formaPagoCompra: 0,
  formaPagoVenta: 0,
  tipoNombre: 0,
  mailChimp: '',
  alertaStock: 0,
  validaPos: 0,
  cliDirecto: 0,
  creacionCortaCli: 0,
  peso: 0,
  cambioPass: 0,
  modoLiquidacion: 0,
  consignacionInventario: 0,
  codigoBarrasCorto: 0,
  leyendaCE: '',
  serverSmtp: '',
  puertoSmtp: 0,
  seguridadSmtp: '',
  guiaFactura: 0,
  pvpPorUtilidad: 0,
  cupo: 0,
  buscarConStock: 0,
  usuarioIndu: '',
  passwordIndu: '',
  usuarioDokan: '',
  passwordDokan: '',
  creacionItems: 0,
  precioFinalC: 0,
  microempresa: 0,
  aRetencion: 0,
  logs: 0,
  planId: 0,
  tipoNegocioId: 0,
  ipBase: '',
  bancosEmpresaClientes: 0,
  stockMinimo: 0,
  stockMaximo: 0,
  uafu: '',
  contaRec: 0,
  configurado: false,
  rimpeCodigo: null,
  rimpeLeyenda: null,
  codigoParaComprobantes: 0,
  nuevasImpresiones: 0,
  utilizarImpuestos: 0,
  recintoFiscal: '',
  regimen: '',
  tipoItemExporta: 0,
}

export type EdicionDatosGenerales = {
  codigo: number
  ruc: string
  nombre: string
  nombreComercial: string
  representante: string
  direccion: string
  email: string
  telefono: string
  tipoIdentificacion: TipoIdentificacion | null
  identificacionRep: string
  rucContador: string
  uaf: string
  uafu: string
  codigoCreditos: string
  tipoAgente: TipoAgente | null
  contribuyente: string
  tipoNegocio: TipoNegocio | null
  aRetencion: boolean
  orden: number
  rimpeEmpresa: TRimpe | null
}

export const defaultDatosGenerales: EdicionDatosGenerales = {
  codigo: 0,
  ruc: '',
  nombre: '',
  nombreComercial: '',
  representante: '',
  direccion: '',
  email: '',
  telefono: '',
  tipoIdentificacion: null,
  identificacionRep: '',
  rucContador: '',
  uaf: '',
  uafu: '',
  codigoCreditos: '',
  tipoAgente: null,
  contribuyente: '',
  tipoNegocio: null,
  aRetencion: false,
  orden: 0,
  rimpeEmpresa: null,
}

export type EdicionDatosDocumentos = {
  codigo: number
  logo: string
  logoTransaparencia: string
  archivoProforma: ArchivoExternos | null
  observacionesProformas: string
  observacionesTransparencia: string
  archivoImpresionActas: ArchivoExternos | null
  cabeceraActas: string
  pieActas: string
}

export const defaultDatosDocumentos: EdicionDatosDocumentos = {
  codigo: 0,
  logo: '',
  logoTransaparencia: '',
  archivoProforma: null,
  observacionesProformas: '',
  observacionesTransparencia: '',
  archivoImpresionActas: null,
  cabeceraActas: '',
  pieActas: '',
}

export type EdicionDatosFacturacionElectronica = {
  codigo: number
  modoFacturacion: boolean
  modoLiquidaciones: boolean
  modoNotaCredito: boolean
  modoNotaDebito: boolean
  modoRetencion: boolean
  modoGuia: boolean
  numComprobantes: number
  esquema: boolean
  leyendaCE: string
  correoFE: string
  passwordFE: string
  serverSmtp: string
  puertoSmtp: number | null
  seguridadSmtp: string
  codigoParaComprobantes: OptionCodigoComprobantes | null
  nuevasImpresiones: boolean
}

export const defaultDatosFacturacionElectronica: EdicionDatosFacturacionElectronica =
  {
    codigo: 0,
    modoFacturacion: false,
    modoLiquidaciones: false,
    modoNotaCredito: false,
    modoNotaDebito: false,
    modoRetencion: false,
    modoGuia: false,
    numComprobantes: 0,
    esquema: false,
    leyendaCE: '',
    correoFE: '',
    passwordFE: '',
    serverSmtp: '',
    puertoSmtp: null,
    seguridadSmtp: '',
    codigoParaComprobantes: null,
    nuevasImpresiones: false,
  }

export type EdicionDatosResumenComprobantes = {
  codigo: number
  fechaDesde: string
  fechaHasta: string
  transacciones: Array<any> | []
}

export const defaultDatosResumenComprobantes: EdicionDatosResumenComprobantes =
  {
    codigo: 0,
    fechaDesde: '',
    fechaHasta: '',
    transacciones: [],
  }
