import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  ItemActives,
  ItemAttributes,
  ItemEditData,
  ItemExtras,
  ItemGenerals,
  ItemHistorical,
  ItemImages,
  ItemLedgerAccount,
  ItemObservations,
  ItemPriceControl,
  ItemPromotions,
  ItemStock,
  ItemSupplier,
  Parts,
} from '../types/types'
import { EdicionPayload } from '../../../store/types'
import { CustomDictionary, TEstado } from '../../../../../store/types'
import {
  TOptionGenerico,
  TOptionGenericoString,
} from '../../../../clientes/pages/clientes/types/types'
import { TipoCliente } from '../../../../ventas/types/types'
import { LocalOption } from '../../../../componentes/localesLookUp/store/type'
import { OptionTiposImpuestos } from '../../../../componentes/tiposImpuestosLookup/types/types'
import { TiposIce } from '../../../../componentes/tiposIceLookUp/types/types'
import { SutentosTributarios } from '../../../../componentes/sustentosTributarioLookUp/types/types'
import { PartidaArancelaria } from '../../../../componentes/partidasArancelariasLookup/types/types'
import { AplicacionInventario } from '../../catalogos/aplicaciones/types/types'
import { PlanFacturacion } from '../../../../componentes/tiposPlanesLookUp/types/types'
import { Subsidio } from '../../../../componentes/tiposSubsidiosLookUp/types/types'
import { Impresora } from '../../../../componentes/impresorasLookUp/types/types'
import { TipoPropiedad } from '../../../../componentes/propiedadesLookUp/types/types'
import { CondicionActivo } from '../../../../componentes/condicionActivoLookUp/types/types'
import { TipoDepreciacion } from '../../../../componentes/tiposDepreciacionLookUp/types/types'

const initialState: CustomDictionary<ItemEditData> = {}

const datoaEditItemSlice = createSlice({
  name: 'itemDataEdit',
  initialState: initialState,
  reducers: {
    deleteEditData(state, action: PayloadAction<string>) {
      delete state[action.payload]
    },
    setDataToEdit(
      state,
      action: PayloadAction<{
        key: string
        item: ItemEditData
      }>,
    ) {
      const { key, item } = action.payload
      state[key].saved = item.saved
      state[key].barras = item.barras
      state[key].codigo = item.codigo
      state[key].codigoInterno = item.codigoInterno
      state[key].nombre = item.nombre
      state[key].nombreGenerico = item.nombreGenerico
      state[key].numKardex = item.numKardex
    },
    setLoaded(
      state,
      action: PayloadAction<{
        key: string
        loaded: boolean
      }>,
    ) {
      state[action.payload.key].loaded = action.payload.loaded
    },
    setDataGeneralsToEdit(
      state,
      action: PayloadAction<{
        key: string
        generals: ItemGenerals
      }>,
    ) {
      const { key, generals } = action.payload
      state[key].generales.tipoItem = generals.tipoItem
      state[key].generales.jerarquia = generals.jerarquia
      state[key].generales.controlador = generals.controlador
      state[key].generales.dinamico = generals.dinamico
      state[key].generales.linea = generals.linea
      state[key].generales.grupo = generals.grupo
      state[key].generales.marca = generals.marca
      state[key].generales.tiempoSurtido = generals.tiempoSurtido
      state[key].generales.unidadCompra = generals.unidadCompra
      state[key].generales.unidadVenta = generals.unidadVenta
      state[key].generales.entrada = generals.entrada
      state[key].generales.salida = generals.salida
      state[key].generales.factor = generals.factor
      state[key].generales.unidadPorEmpaque = generals.unidadPorEmpaque
      state[key].generales.serieImei = generals.serieImei
      state[key].generales.lotes = generals.lotes
      state[key].generales.mobile = generals.mobile
      state[key].generales.conjuntos = generals.conjuntos
      state[key].generales.receta = generals.receta
      state[key].generales.plantilla = generals.plantilla
      state[key].generales.pesado = generals.pesado
      state[key].generales.tiempo = generals.tiempo
      state[key].generales.minGratis = generals.minGratis
      state[key].generales.cadaMin = generals.cadaMin
      state[key].generales.tarjetas = generals.tarjetas
      state[key].generales.edades = generals.edades
      state[key].generales.ticket = generals.ticket
      state[key].generales.paquetes = generals.paquetes
    },
    setPerfomance(
      state,
      action: PayloadAction<{
        key: string
        performance: TOptionGenerico
      }>,
    ) {
      state[action.payload.key].generales.performance =
        action.payload.performance
    },
    setOrigin(
      state,
      action: PayloadAction<{
        key: string
        origin: TOptionGenerico
      }>,
    ) {
      state[action.payload.key].generales.procedencia = action.payload.origin
    },
    setProject(
      state,
      action: PayloadAction<{
        key: string
        project: LocalOption
      }>,
    ) {
      state[action.payload.key].generales.proyecto = action.payload.project
    },
    setStatus(
      state,
      action: PayloadAction<{
        key: string
        status: TEstado
      }>,
    ) {
      state[action.payload.key].generales.estado = action.payload.status
    },
    setDataPricesControlToEdit(
      state,
      action: PayloadAction<{
        key: string
        priceControl: ItemPriceControl
      }>,
    ) {
      const { key, priceControl } = action.payload
      state[key].preciosControl.gratuito = priceControl.gratuito
      state[key].preciosControl.sinDescuento = priceControl.sinDescuento
      state[key].preciosControl.limitePrecios = priceControl.limitePrecios
      state[key].preciosControl.costo = priceControl.costo
      state[key].preciosControl.dataGridPrecios = priceControl.dataGridPrecios
      state[key].preciosControl.pvd = priceControl.pvd
      state[key].preciosControl.utilidadPvd = priceControl.utilidadPvd
      state[key].preciosControl.pvp = priceControl.pvp
      state[key].preciosControl.utilidadPvp = priceControl.utilidadPvp
      state[key].preciosControl.comision = priceControl.comision
      state[key].preciosControl.ibnpr = priceControl.ibnpr
      state[key].preciosControl.costeos = priceControl.costeos
      state[key].preciosControl.partidaArancelaria =
        priceControl.partidaArancelaria
    },
    setIvaInclude(
      state,
      action: PayloadAction<{
        key: string
        include: boolean
      }>,
    ) {
      state[action.payload.key].preciosControl.incluyenIva =
        action.payload.include
    },
    setTypeCustomer(
      state,
      action: PayloadAction<{
        key: string
        typeCustomer: TipoCliente
      }>,
    ) {
      state[action.payload.key].preciosControl.tipoCliente =
        action.payload.typeCustomer
    },
    setStore(
      state,
      action: PayloadAction<{
        key: string
        store: LocalOption
      }>,
    ) {
      state[action.payload.key].preciosControl.local = action.payload.store
    },
    setTypeTax(
      state,
      action: PayloadAction<{
        key: string
        tax: OptionTiposImpuestos
      }>,
    ) {
      state[action.payload.key].preciosControl.tipoImpuesto = action.payload.tax
    },
    setIce(
      state,
      action: PayloadAction<{
        key: string
        ice: TiposIce
      }>,
    ) {
      state[action.payload.key].preciosControl.tipoIce = action.payload.ice
    },
    setTaxSupport(
      state,
      action: PayloadAction<{
        key: string
        taxSupport: SutentosTributarios
      }>,
    ) {
      state[action.payload.key].preciosControl.sustentoTributario =
        action.payload.taxSupport
    },
    setTariffItem(
      state,
      action: PayloadAction<{
        key: string
        TariffItem: PartidaArancelaria
      }>,
    ) {
      state[action.payload.key].preciosControl.partidaArancelariaLookup =
        action.payload.TariffItem
    },
    setDataLegerAccountToEdit(
      state,
      action: PayloadAction<{
        key: string
        ledgerAccount: ItemLedgerAccount
      }>,
    ) {
      const { key, ledgerAccount } = action.payload
      state[key].cuentasContables.venta = ledgerAccount.venta
      state[key].cuentasContables.compra = ledgerAccount.compra
      state[key].cuentasContables.inventario = ledgerAccount.inventario
      state[key].cuentasContables.costoVentas = ledgerAccount.costoVentas
      state[key].cuentasContables.retencion = ledgerAccount.retencion
      state[key].cuentasContables.custodia = ledgerAccount.custodia
    },
    setDataStockToEdit(
      state,
      action: PayloadAction<{
        key: string
        stock: ItemStock
      }>,
    ) {
      const { key, stock } = action.payload
      state[key].existencias.existencia = stock.existencia
      state[key].existencias.reservado = stock.reservado
      state[key].existencias.maximo = stock.maximo
      state[key].existencias.minimo = stock.minimo
      state[key].existencias.localizacion = stock.localizacion
      state[key].existencias.recompra = stock.recompra
      state[key].existencias.localExistencia = stock.localExistencia
    },
    setDataSupplierToEdit(
      state,
      action: PayloadAction<{
        key: string
        supplier: ItemSupplier
      }>,
    ) {
      const { key, supplier } = action.payload
      state[key].proveedores.tipo = supplier.tipo
      state[key].proveedores.proveedor = supplier.proveedor
      state[key].proveedores.tablaProveedores = supplier.tablaProveedores
    },
    setDataImagesToEdit(
      state,
      action: PayloadAction<{
        key: string
        images: Array<ItemImages>
      }>,
    ) {
      const { key, images } = action.payload
      state[key].imagenes = images
    },
    setDataAplicationsToEdit(
      state,
      action: PayloadAction<{
        key: string
        aplications: Array<AplicacionInventario>
      }>,
    ) {
      const { key, aplications } = action.payload
      state[key].aplicaciones = aplications
    },
    setDataActivesToEdit(
      state,
      action: PayloadAction<{
        key: string
        actives: ItemActives
      }>,
    ) {
      const { key, actives } = action.payload
      state[key].activos.tipo = actives.tipo
      state[key].activos.fecha = actives.fecha
      state[key].activos.caracteristica = actives.caracteristica
      state[key].activos.valor = actives.valor
      state[key].activos.dataGrid = actives.dataGrid
      state[key].activos.depreciacion = actives.depreciacion
      state[key].activos.depreciacionAcumulada = actives.depreciacionAcumulada
      state[key].activos.anios = actives.anios
      state[key].activos.coef = actives.coef
      state[key].activos.vidaUtil = actives.vidaUtil
      state[key].activos.vidaUtilInf = actives.vidaUtilInf
      state[key].activos.valorResidual = actives.valorResidual
      state[key].activos.factor = actives.factor
    },
    setConditionActive(
      state,
      action: PayloadAction<{
        key: string
        condition: CondicionActivo
      }>,
    ) {
      state[action.payload.key].activos.condicion = action.payload.condition
    },
    setDepreciationMethod(
      state,
      action: PayloadAction<{
        key: string
        method: TOptionGenericoString
      }>,
    ) {
      state[action.payload.key].activos.metodoDep = action.payload.method
    },
    setDepreciationType(
      state,
      action: PayloadAction<{
        key: string
        type: TipoDepreciacion
      }>,
    ) {
      state[action.payload.key].activos.tipoDep = action.payload.type
    },
    setDepreciationMethodInf(
      state,
      action: PayloadAction<{
        key: string
        methodInf: TOptionGenericoString
      }>,
    ) {
      state[action.payload.key].activos.metodoDepInf = action.payload.methodInf
    },
    setDataPartsToEdit(
      state,
      action: PayloadAction<{
        key: string
        parts: Array<Parts>
      }>,
    ) {
      const { key, parts } = action.payload
      state[key].partes = parts
    },
    setDataPromotionsToEdit(
      state,
      action: PayloadAction<{
        key: string
        promotion: ItemPromotions
      }>,
    ) {
      const { key, promotion } = action.payload
      state[key].promociones.descripcion = promotion.descripcion
      state[key].promociones.desde = promotion.desde
      state[key].promociones.hasta = promotion.hasta
      state[key].promociones.descuento = promotion.descuento
      state[key].promociones.dataGrid = promotion.dataGrid
    },
    setDataHistoricalToEdit(
      state,
      action: PayloadAction<{
        key: string
        historical: ItemHistorical
      }>,
    ) {
      const { key, historical } = action.payload
      state[key].historicos.deSurtir = historical.deSurtir
      state[key].historicos.deRecibir = historical.deRecibir
      state[key].historicos.costoPromedio = historical.costoPromedio
      state[key].historicos.ultimoCosto = historical.ultimoCosto
      state[key].historicos.cantidadVentas = historical.cantidadVentas
      state[key].historicos.montoVenta = historical.montoVenta
      state[key].historicos.ultimaVenta = historical.ultimaVenta
      state[key].historicos.cantidadCompras = historical.cantidadCompras
      state[key].historicos.montoCompra = historical.montoCompra
      state[key].historicos.ultimaCompra = historical.ultimaCompra
    },
    setDataExtrasToEdit(
      state,
      action: PayloadAction<{
        key: string
        extras: ItemExtras
      }>,
    ) {
      const { key, extras } = action.payload
      state[key].extras.virtual = extras.virtual
      state[key].extras.venderIndividual = extras.venderIndividual
      state[key].extras.venderBajoPedido = extras.venderBajoPedido
      state[key].extras.peso = extras.peso
      state[key].extras.largo = extras.largo
      state[key].extras.ancho = extras.ancho
      state[key].extras.alto = extras.alto
      state[key].extras.nota = extras.nota
      state[key].extras.tags = extras.tags
      state[key].extras.especificaciones = extras.especificaciones
      state[key].extras.codImpuesto = extras.codImpuesto
      state[key].extras.capacidadCC = extras.capacidadCC
      state[key].extras.codClasificacion = extras.codClasificacion
      state[key].extras.codUnidad = extras.codUnidad
      state[key].extras.codMarca = extras.codMarca
      state[key].extras.paisOrigen = extras.paisOrigen
      state[key].extras.codPresentacion = extras.codPresentacion
      state[key].extras.gradoAlcohol = extras.gradoAlcohol
      state[key].extras.codGradoAlcohol = extras.codGradoAlcohol
      state[key].extras.estado = extras.estado
      state[key].extras.gramoAzucar = extras.gramoAzucar
      state[key].extras.uafe = extras.uafe
      state[key].extras.nivelMls = extras.nivelMls
      state[key].extras.deducible = extras.deducible
      state[key].extras.liqTansporte = extras.liqTansporte
      state[key].extras.desperdicio = extras.desperdicio
      state[key].extras.costoProduccion = extras.costoProduccion
      state[key].extras.cmuAncho = extras.cmuAncho
      state[key].extras.cmuLargo = extras.cmuLargo
    },
    setPrinter(
      state,
      action: PayloadAction<{
        key: string
        printer: Impresora
      }>,
    ) {
      state[action.payload.key].extras.impresora = action.payload.printer
    },
    setDeductiblesTypes(
      state,
      action: PayloadAction<{
        key: string
        deductiblesTypes: TOptionGenerico
      }>,
    ) {
      state[action.payload.key].extras.tiposDeducible =
        action.payload.deductiblesTypes
    },
    setPlans(
      state,
      action: PayloadAction<{
        key: string
        plans: PlanFacturacion
      }>,
    ) {
      state[action.payload.key].extras.planes = action.payload.plans
    },
    setSubsidy(
      state,
      action: PayloadAction<{
        key: string
        subsidy: Subsidio
      }>,
    ) {
      state[action.payload.key].extras.subsidio = action.payload.subsidy
    },
    setDataAttributesToEdit(
      state,
      action: PayloadAction<{
        key: string
        atributos: ItemAttributes
      }>,
    ) {
      const { key, atributos } = action.payload
      state[key].atributos.dataGrid = atributos.dataGrid
    },
    setPropertiesOptions(
      state,
      action: PayloadAction<{
        key: string
        property: TipoPropiedad
        options: Array<string>
      }>,
    ) {
      state[action.payload.key].atributos.propiedad = action.payload.property
      state[action.payload.key].atributos.opciones = action.payload.options
    },
    setDatObservationsToEdit(
      state,
      action: PayloadAction<{
        key: string
        observation: ItemObservations
      }>,
    ) {
      const { key, observation } = action.payload
      state[key].observaciones.observaciones = observation.observaciones
      state[key].observaciones.costoReferencial = observation.costoReferencial
      state[key].observaciones.costoReal = observation.costoReal
      state[key].observaciones.modificado = observation.modificado
      state[key].observaciones.titulo = observation.titulo
      state[key].observaciones.valor = observation.valor
      state[key].observaciones.manual = observation.manual
      state[key].observaciones.especificaciones = observation.especificaciones
    },
    setDatosEdicion(
      state,
      action: PayloadAction<EdicionPayload<ItemEditData>>,
    ) {
      state[action.payload.key] = action.payload.data
    },
    initDatosEdicion(
      state,
      action: PayloadAction<EdicionPayload<ItemEditData>>,
    ) {
      if (!state[action.payload.key])
        state[action.payload.key] = action.payload.data
    },
    setDatosEdicionBackup(
      state,
      action: PayloadAction<EdicionPayload<ItemEditData>>,
    ) {
      state[action.payload.key].backup = action.payload.data
    },
  },
})

export const {
  setDataToEdit,
  initDatosEdicion,
  setDatosEdicion,
  deleteEditData,
  setDataGeneralsToEdit,
  setPerfomance,
  setOrigin,
  setProject,
  setStatus,
  setDataPricesControlToEdit,
  setTypeCustomer,
  setStore,
  setTypeTax,
  setIce,
  setTaxSupport,
  setTariffItem,
  setDataLegerAccountToEdit,
  setDataStockToEdit,
  setDataSupplierToEdit,
  setDataImagesToEdit,
  setDataAplicationsToEdit,
  setDataPromotionsToEdit,
  setDataHistoricalToEdit,
  setDataExtrasToEdit,
  setPrinter,
  setDeductiblesTypes,
  setPlans,
  setSubsidy,
  setDataAttributesToEdit,
  setPropertiesOptions,
  setDatObservationsToEdit,
  setDataPartsToEdit,
  setConditionActive,
  setDepreciationMethod,
  setDepreciationType,
  setDepreciationMethodInf,
  setDataActivesToEdit,
  setIvaInclude,
  setLoaded,
  setDatosEdicionBackup,
} = datoaEditItemSlice.actions
export const dataEditReducer = datoaEditItemSlice.reducer
