export enum ENetworkMessages {
  errorSolicitud = 'Error en la solicitud:',
  errorDefault = 'Error de servidor:',
  errorConexion = 'Error de conexión: No se recibió respuesta del servidor.',
  error400 = 'Error de solicitud: La solicitud es incorrecta.',
  error401 = 'Error de autorización: No estás autorizado para acceder a este recurso.',
  error403 = 'Error: Recurso no acepta la solicitud.',
  error404 = 'Error: Recurso no encontrado.',
  error405 = 'Error: Recurso no permitido e esta solicitud.',
  error500 = 'Error: Se produjo un error interno en el servidor.',
  error502 = 'Error: Se produjo un conflicto de solicitud entre puertas de enlaces.',
}

export enum ENetworkStates {
  errorSolicitud = 'errorSolicitud',
  errorDefault = 'errorDefault',
  errorConexion = 'errorConexion',
  BadRequest = 'BadRequest',
  Unauthorized = 'Unauthorized',
  Forbidden = 'Forbidden',
  NotFound = 'NotFound',
  MethodNotAllowed = 'MethodNotAllowed',
  InternalServerError = 'InternalServerError',
  BadGateway = 'BadGateway',
}
