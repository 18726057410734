import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TToaster, TToastNotification } from './types'
import { v4 as uuidv4 } from 'uuid'
import { RootState } from './store'

const initialState: TToaster = {
  notifications: [],
}

const toasterSlice = createSlice({
  name: 'toaster',
  initialState: initialState,
  reducers: {
    addToast(state, action: PayloadAction<TToastNotification>) {
      const toast = action.payload
      toast.id = uuidv4()
      state.notifications.push(toast)
    },
    addToasts(state, action: PayloadAction<Array<TToastNotification>>) {
      const toasts = action.payload
      toasts.forEach((toast) => {
        toast.id = uuidv4()
        state.notifications.push(toast)
      })
    },
    clearAllToast(state, action: PayloadAction) {
      state.notifications.splice(0)
    },
  },
})

export const toasterReducer = toasterSlice.reducer

export const { addToast, clearAllToast, addToasts } = toasterSlice.actions

export const selectNotifications = (state: RootState) => {
  return state.toaster.notifications
}
