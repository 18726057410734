import React from 'react';
import { CCol, CRow } from '@coreui/react-pro'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/store'
import { setSeleccionarDato } from "../../store/CCAhorrosReducers";
import { LoadPanel as LoadPanelGrid, Column, DataGrid, FilterRow, HeaderFilter, Summary, TotalItem, Paging, Pager } from 'devextreme-react/data-grid';
import { utilidades } from '../../../../../../helpers/utilidades';
import { getAllowedPageSizes } from '../../../../../../helpers/Helper';

interface TablaProps extends React.PropsWithChildren {
  name: string,
  data: any,
  actionDelete: () => void,
  exportExcel: () => void,
  origen: string,
  returnSeleccion: (data) => void,
  verificaEdicion: () => void
}

const Tabla = (props: TablaProps) => {
  const { verificaEdicion, returnSeleccion, origen } = props;
  const dataGrid = React.useRef<any>();

  const seleccionado = useSelector((state: RootState) => state.bancos.ccahorros.seleccionado);

  const dispatch = useDispatch()

  const [data, setData] = React.useState<Array<any>>([]);
  const [showInfo,] = React.useState<boolean>(true);
  const [showNavButtons,] = React.useState<boolean>(true);
  const [showPageSizeSelector,] = React.useState<boolean>(true);
  const [displayMode,] = React.useState<string | 'full' | 'compact'>('full');

  React.useEffect(() => {
    if (props.data.length > 0) {
      llenarData(props.data)
    } else {
      setData([])
    }
  }, [props.data]);

  const clearFilter = React.useCallback(() => {
    dataGrid.current.instance.clearSorting();
    dataGrid.current.instance.clearFilter();
  }, []);

  const llenarData = React.useCallback((data) => {
    clearFilter();
    let item: any = []
    try {
      item = data.map(function (key, i) {
        return {
          ...key,
          ORDEN: Number(i),
        }
      })
      setData(item.map(x => utilidades.unfreeze(x)))
    } catch (error) {
      console.error("on llenarData", error)
    }
  }, [clearFilter]);

  const desSeleccionarRegistro = React.useCallback(() => {
    dispatch(setSeleccionarDato({
      index: null,
      row: null,
      selectedRow: null
    }))
  }, []);

  const onSelectionChanged = React.useCallback((selectedRowsData) => {
    const data = selectedRowsData['selectedRowsData'][0];
    dispatch(setSeleccionarDato({
      index: 0,
      row: data,
      selectedRow: 0
    }))
  }, [dispatch]);

  const onRowDblClick = React.useCallback((onRowDblClick) => {
    if (origen === 'modal') {
      const dataRow = onRowDblClick['key'];
      returnSeleccion(dataRow)
    } else {
      verificaEdicion();
    }
  }, [returnSeleccion, verificaEdicion, origen]);

  return (
    <>
      <CRow>
        <CCol className="d-flex justify-content-end">
          {data.length}
        </CCol>
      </CRow>
      <DataGrid
        ref={dataGrid}
        selection={{ mode: 'single' }}
        hoverStateEnabled={true}
        dataSource={data}
        showBorders={true}
        showRowLines={true}
        onSelectionChanged={onSelectionChanged}
        onRowDblClick={onRowDblClick}
      >
        <LoadPanelGrid enabled={true} />
        <FilterRow visible={true} />
        <Paging defaultPageSize={20} />
        <Pager
          visible={data.length > 0 ? true : false}
          allowedPageSizes={getAllowedPageSizes(data)}
          displayMode={displayMode}
          showPageSizeSelector={showPageSizeSelector}
          showInfo={showInfo}
          showNavigationButtons={showNavButtons} />
        <HeaderFilter visible={true} />
        <Column dataType="string" dataField='sub_valor' caption='Tipo' width='20%' />
        <Column dataType="string" dataField='cba_numero' caption='Cuenta' width='20%' />
        <Column dataField='ban_nombre' caption='Banco' width='20%' />

        <Column dataField='asociado_nombre' visible={props.origen === 'modal' ? true : false} caption='Nombre' width='20%' />

        <Column dataField='cba_oficial' visible={props.origen === 'main' ? true : false} caption='Oficial' width='20%' />
        <Column dataField='cba_saldo' visible={props.origen === 'main' ? true : false} caption='Saldo' width='auto' />

        <Summary>
          <TotalItem
            column="cba_saldo"
            summaryType="sum"
            valueFormat="currency" />
        </Summary>
      </DataGrid>
    </>
  );
}

export default Tabla;