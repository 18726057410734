/* eslint-disable prefer-const */
import React from 'react';
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CCollapse,
  CLink
} from '@coreui/react-pro';
import CIcon from '@coreui/icons-react'
import { isMobileOnly } from 'react-device-detect'
import { TextBox } from 'devextreme-react/text-box';
import { CheckBox } from 'devextreme-react/check-box';
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/store';
import { modalClientesService } from '../../service/modalClientesServices';
import { modalCliente } from '../../store/types';
import Tabla from "../tabla/index"

type IBuscarProps = {
  referencia: any,
  returnCliente: (evt) => void,
  codModulo: number,
  setToast: (texto, type) => void
  playLoader: () => void
  stopLoader: () => void
  returnAction: () => void
  verificaEdicion: () => void
  pasarSeleccionados: (data) => void
  setCurrentFunction: (data) => void
  onResetSeleccion: () => void
  onSeleccion: (data) => void,
  currentFunction: any
}

const Buscar = (props: IBuscarProps) => {


  const { currentFunction, onSeleccion, onResetSeleccion, pasarSeleccionados, verificaEdicion, setCurrentFunction, setToast, playLoader, stopLoader } = props;


  const [collapsed, setCollapsed] = React.useState(true)
  const [identificacionb_ti, setIdentificacionb_ti] = React.useState<string>("")
  const [nombreb_ti, setNombreb_ti] = React.useState<string>("")
  const [rcomercialb_ti, setRcomercialb_ti] = React.useState<string>("")
  const [estado_cx, setEstado_cx] = React.useState<boolean>(false)
  const [data, setData] = React.useState<any>([])

  const handleSubmit = async () => {
    if (nombreb_ti === "" && rcomercialb_ti === "" && identificacionb_ti === "") {
      setToast("Antes de realizar la búsqueda debe al menos ingresar un criterio.", "info");
      return false;
    }

    playLoader()
    setData([])
    try {
      const find = {
        codigo: "",
        nombre: nombreb_ti,
        razonComercial: rcomercialb_ti,
        identificacion: identificacionb_ti,
        empresa: "",
        estado: estado_cx,
        ubicacion: "",
      }
      console.log('find', find)
      const data = await modalClientesService.obtenerClientes(find);
      console.log(data)
      stopLoader()
      if (data['error'] === false) {
        setToast(data['message'], 'success')
        if (data['auto'].length > 0) {
          if (isMobileOnly) {
            setCollapsed(false);
          }
          let itemCLiente: Array<modalCliente>
          itemCLiente = data['auto'].map(function (item: any) {
            return {
              identificacion: String(item['identificacion']) ?? "",
              nombres: String(item['nombres']) ?? "",
              rcomercial: String(item['rcomercial']) ?? "",
              direccion: String(item['direccion']) ?? "",
              telefono: String(item['telefono']) ?? "",
              email: String(item['email']) ?? "",
              descuento: Number(item['descuento']) ?? 0,
              ciudad: String(item['ciudad']) ?? "",
              tipo: String(item['tipo']) ?? "",
              codigo: Number(item['codigo']) ?? 0,
              tipo_id: Number(item['tipo_id']) ?? 0,
            }
          })
          setData(itemCLiente)
        }
      } else {
        setToast(data['message'], 'warning')
      }
      return await data
    } catch (error) {
      console.log('error', error)
      stopLoader()
      setToast(JSON.stringify(error), 'danger')
    }
  }

  React.useEffect(() => {
    if (currentFunction === "Buscar") {
      setCurrentFunction("")
      handleSubmit()
    }
  }, [currentFunction]);

  return (
    <>
      <CCard>
        <CCardHeader onClick={() => setCollapsed(!collapsed)}>
          <strong> {'Buscar'} </strong>
          <div className='card-header-actions'>
            <CLink className='card-header-action'>
              <CIcon
                name={collapsed ? 'cil-chevron-bottom' : 'cil-chevron-top'}
              />
            </CLink>
          </div>
        </CCardHeader>
        <CCollapse visible={collapsed}>

          <CCardBody>
            <CRow className='m-2 p-2'>
              <CCol lg='8' sm='12'>

                <div className='dx-field'>
                  <div className='dx-field-label'>{'Identificación:'}</div>
                  <div className='dx-field-value'>
                    <TextBox
                      name='identificacionb_ti'
                      id='identificacionb_ti'
                      value={identificacionb_ti}
                      onValueChanged={data => setIdentificacionb_ti(data.value)}
                      showClearButton={true}
                      onEnterKey={() => props.returnAction()}
                    >
                    </TextBox>
                  </div>
                </div>


                <div className='dx-field'>
                  <div className='dx-field-label'>{'Nombre / Razón Social:'}</div>
                  <div className='dx-field-value'>
                    <TextBox
                      name='nombreb_ti'
                      id='nombreb_ti'
                      value={nombreb_ti}
                      onValueChanged={data => setNombreb_ti(data.value)}
                      showClearButton={true}
                      onEnterKey={() => props.returnAction()}
                    >
                    </TextBox>
                  </div>
                </div>


                <div className='dx-field'>
                  <div className='dx-field-label'>{'Nombre Comercial:'}</div>
                  <div className='dx-field-value'>
                    <TextBox
                      name='rcomercialb_ti'
                      id='rcomercialb_ti'
                      value={rcomercialb_ti}
                      onValueChanged={data => setRcomercialb_ti(data.value)}
                      showClearButton={true}
                      onEnterKey={() => props.returnAction()}
                    >
                    </TextBox>
                  </div>
                </div>

                <div className='dx-field'>
                  <div className='dx-field-label'>{' '}</div>
                  <div className='dx-field-value'>
                    <CheckBox
                      width={100}
                      text="Activos"
                      value={estado_cx}
                      onValueChanged={() => setEstado_cx(!estado_cx)}
                    />
                  </div>
                </div>


              </CCol>
            </CRow>
          </CCardBody>

        </CCollapse>
      </CCard>

      <CRow>
        <CCol>
          <Tabla
            name='TablaIngresos'
            actionDelete={() => { console.log('delete') }}
            exportExcel={() => { console.log('delete') }}
            verificaEdicion={verificaEdicion}
            pasarSeleccionados={pasarSeleccionados}
            onResetSeleccion={onResetSeleccion}
            onSeleccion={onSeleccion}
            registros={data}
          />
        </CCol>
      </CRow>
    </>
  );
}
export default React.memo(Buscar);
