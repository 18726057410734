import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FETCH_STATUS, LoaderInfo } from "../../../../../../store/types";
import { WorkingHour } from "../../../../../componentes/modalHorariosTrabajo/types/type";
import { BusquedaState, FiltroBusquedaHorarios } from "../types/types";
import { HoursServices } from '../services/horarios.services';

const initialState: BusquedaState<WorkingHour> = {
  filtro: {
    descripcion: '',
    estado: null,
    initial: true
  },

  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  resultados: [],
  seleccionado: null,
  colapsado: true,
  searchHorarios: false,
  loader: {
    show: false,
    mensaje: '',
  },
  nroRegistrosEncontrados: 0
}

export const fetchHourList = createAsyncThunk<
  Array<WorkingHour>,
  FiltroBusquedaHorarios
>('nomina/registros/horarios/listar', async (filtro) => {
  try {
    const hoursList = HoursServices.getHours(filtro)
    return hoursList
  } catch (error) {
    return Promise.reject(error)
  }
})

const searchSlice = createSlice({
  name: 'buscarHorarios',
  initialState: initialState,
  reducers: {
    setResetResults(state) {
      state.resultados = []
    },
    setResults(state, action: PayloadAction<WorkingHour[]>) {
      state.resultados = action.payload
    },
    setResetSeleccion(state) {
      state.seleccionado = null
    },
    changeFilter(state, action: PayloadAction<FiltroBusquedaHorarios>) {
      state.filtro.descripcion = action.payload.descripcion
      state.filtro.estado = action.payload.estado
      state.filtro.initial = action.payload.initial
    },
    setSeleccionarDato(state, action: PayloadAction<WorkingHour>) {
      state.seleccionado = action.payload
    },
    setCollapsed(state, action: PayloadAction<boolean>) {
      state.colapsado = action.payload
    },
    setNroRegistrosEncontradas(state, action: PayloadAction<number>) {
      state.nroRegistrosEncontrados = action.payload
    },
    setChangeLoader(state, action: PayloadAction<LoaderInfo>) {
      state.loader = action.payload
    },
    setSearch(state, action: PayloadAction<boolean>) {
      state.searchHorarios = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchHourList.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchHourList.fulfilled, (state, { payload }) => {
      state.resultados = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchHourList.rejected, (state, { payload }) => {
      state.resultados = []
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
  },
})

export const {
  setResetResults,
  setResults,
  setResetSeleccion,
  changeFilter,
  setSeleccionarDato,
  setCollapsed,
  setNroRegistrosEncontradas,
  setChangeLoader,
  setSearch,
} = searchSlice.actions
export const searchReducer = searchSlice.reducer