import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import { setSearchLoader } from '../store/searchReducer'
import { setCurrentExecutingAction } from '../store/tabsReducer'
import { setTabLoader } from '../store/editDataReducer'

function useLoaderTransfers() {
  const dispatch = useDispatch()

  const playLoaderSearch = useCallback(
    (message: string, button: ButtonTypes) => {
      dispatch(setSearchLoader({ show: true, mensaje: message }))
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'BUSQUEDA',
          buttonType: button,
        }),
      )
    },
    [dispatch],
  )

  const stopLoaderSearch = useCallback(() => {
    dispatch(setSearchLoader({ show: false, mensaje: '' }))
    dispatch(
      setCurrentExecutingAction({
        tabKey: 'BUSQUEDA',
        buttonType: undefined,
      }),
    )
  }, [dispatch])

  const playLoader = useCallback(
    (key: string, message: string, button: ButtonTypes) => {
      dispatch(
        setTabLoader({ key: key, loader: { show: true, mensaje: message } }),
      )
      dispatch(
        setCurrentExecutingAction({
          tabKey: key,
          buttonType: button,
        }),
      )
    },
    [dispatch],
  )

  const stopLoader = useCallback(
    (key: string) => {
      dispatch(setTabLoader({ key: key, loader: { show: false, mensaje: '' } }))
      dispatch(
        setCurrentExecutingAction({
          tabKey: key,
          buttonType: undefined,
        }),
      )
    },
    [dispatch],
  )

  return { playLoaderSearch, stopLoaderSearch, playLoader, stopLoader }
}

export { useLoaderTransfers }
