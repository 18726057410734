import Lookup from 'devextreme-react/lookup';
import { DropDownOptions } from 'devextreme-react/select-box';
import React from 'react';
import { InventarioService } from '../../services/inventario.service';
import { LineaInventario } from '../../store/types';

interface IBuscarLineaLookUpProps extends React.PropsWithChildren {
  selected?: number | null,
  onChanged: (newValue: LineaInventario | undefined) => void,
  allowEdit?: boolean,
  allowClear?: boolean,
  allowAdd?: boolean
}

export const BuscarLineaLookUp: React.FC<IBuscarLineaLookUpProps> = (props) => {
  const { selected, onChanged, } = props;

  const datasource = React.useMemo(() => {
    return InventarioService.getLineasDatasource();
  }, []);

  const getDisplayExpr = React.useCallback((linea: LineaInventario) => {
    return linea ? linea.descripcion : '';
  }, []);

  const onChangeLinea = React.useCallback((data) => {
    if (data.event !== undefined) {
      onChanged(data.value)
    }
  }, [onChanged])

  return (
    <Lookup
      dataSource={datasource}
      showDataBeforeSearch
      value={selected}
      minSearchLength={3}
      searchTimeout={500}
      displayExpr={getDisplayExpr}
      searchExpr={['descripcion']}
      pageLoadMode='scrollBottom'
      showClearButton={true}
      onValueChanged={onChangeLinea}
    >
      <DropDownOptions
        tabIndex={3000}
        closeOnOutsideClick={true}
        showTitle={false}
      />
      {props.children}
    </Lookup>
  )
};
