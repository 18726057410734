import { RequestHelper } from '../../../../../helpers/requestHelper'
import { FilterStoreState, SaveStore } from '../types/types'

export const MultistoreServices = {
  getStores,
  saveStore,
  deleteStore,
}

async function getStores(local: FilterStoreState): Promise<Array<any>> {
  try {
    const data: any = {
      nombre: local.nombre,
      estado: local.estado,
      codigo: local.codigo,
      uaf: local.uaf,
    }
    const res = await RequestHelper.get<any>('local', 'getAllBase', '', data)
    return res
  } catch (error) {
    return error
  }
}
async function saveStore(requestBody: SaveStore): Promise<any> {
  try {
    const data = await RequestHelper.postAll<any>(
      'inventario/multialmacen',
      'local/save',
      requestBody,
    )
    return data
  } catch (error) {
    return error
  }
}

async function deleteStore(storeCode: number): Promise<any> {
  try {
    const obj = {
      codigo: storeCode,
    }
    const data = await RequestHelper.deleteRequestAll<any>(
      'inventario/multialmacen',
      'local/delete',
      '',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}
