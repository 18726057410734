import { TabsState } from "../../../../../../store/genericTabTypes"
import { CustomDictionary, FETCH_STATUS, LoaderInfo } from "../../../../../../store/types"
import { TOptionGenericoString } from "../../../../../clientes/pages/clientes/types/types"
import { TFormaPagoOption } from "../../../../../componentes/formasPagoLookUp/store/type"
import { LocalOption } from "../../../../../componentes/localesLookUp/store/type"
import { PaisOption } from "../../../../../componentes/paisesLookUp/store/type"
import { MenuState } from "../../../../../ventas/types/types"
import { ContratosList } from "../../../contratos/types/types"



export enum SearchTabs {
  rol = 'Roles',
  his = 'Historial',
}

export type RolesPayList = {
  codigo: number
  usuario: number
  cedula: string
  nombres: string
  apellidos: string
  sueldoNominal: number
  descripcion: string
  fechaRegistro: string
  fechaCobro: string
  fechaLabor: string
  mes: string
  anio: number
  dias: number
  sueldo: number
  jornada: number
  porcentajeJornada: number
  valorJornada: number
  horasSuplementarias: number
  porcentajeSuplementarias: number
  valorHoraSuplementarias: string
  horasExtras: string
  porcentajeExtra: string
  valorHoraExtra: string
  comisiones: string
  pagoIess: number
  iess: number
  secap: number
  iece: number
  prestamos: number
  anticipos: number
  descuentos: number
  renta: number
  fondosReserva: number
  multas: number
  reemplazo: string
  atrasos: string
  sueldoMes: number
  egreso: string
  bonificacion: number
  acumularFondosReserva: string
  nominal: string
  unificado: number
  observaciones: string
  fPago: string
  formaPago: string
  nombresCompletos: string
  numeroCheque: string
  faltas: string
  bai: number
  totalIngresos: number
  totalEgresos: number
  vacaciones: number
  fechaProporcional: string
  calcularNominal: number
  pagarIess: number
  decimoTercero: number
  decimoCuarto: number
  subsidioMaternidad: number
  subsidioEnfermedad: number
  local: number
  cuentaBancoNumero: string
  bancoNombre: string
  localNombre: string
  contratoTipo: number
  horasTiempoParcial: string
  tipoPersonal: string
  direccion: string
  ciudadCodigo: string
  ciudad: string
  HL: string
  ML: string
  HN: string
  MN: string
  HS: string
  MS: string
  HE: string
  me: string
  porcentajeAportePatronal: number
  porcentajeAportePersonal: number
  secapPorcentaje: number
  iecePorcentaje: number
  ajusteSueldo: number
  bonos: number
  prestamosQuirografarios: number
  prestamosHipotecarios: number
}

export type RolParamsList = {
  codigo: number
  empresa: number
  dias: number
  porcentajeExtras: number
  porcentajeSuplementarias: number
  nocturna: number
  aportePersonal: number
  aportePatronal: number
  vacaciones: number
  decimoTercerPago: number
  decimoTercerDesde: string
  decimoTercerHasta: string
  decimoCuartoPago: number
  decimoCuartoDesde: string
  decimoCuartoHasta: string
  horas: number
  sbu: number
  decimoTercerPagoMensual: number
  decimoCuartoPagoMensual: number
  nocturnoDesde: string
  nocturnoHasta: string
  multa: number
  maximoAnticipos: number
  atrasoTolerado: number
  atrasoMes: number
  salidaTolerada: number
  salidaToleradaMes: number
  horasSuplementariasMinimo: number
  horasSuplementariasMaximo: number
  horasExtrasMininimo: number
  utilidadesMesPago: number
  utilidadesDesde: string
  utilidadesHasta: string
  utilidadesSinCargas: number
  utilidadesCargas: number
}

export type CentrosAsignados = {
  codigo: number
  nombre: string
  porcentaje: number
}


export type RolesPadoDatosEdicion = {
  codigo: number
  tipo: number
  htrCodigo: number
  egresosCodigo: number
  mes: TOptionGenericoString | null
  anio: number
  lugar: LocalOption | null
  rolparametros: RolParamsList | null
  listaFormasPagos: Array<TFormaPagoOption>
  tipoPago: Array<any>
  contratoEmpleado: ContratosList | null
  centrosAsignados: Array<CentrosAsignados>
  centrosAsignadosSeleccionado: CentrosAsignados | null
  horasMes: number
  horasDias: number
  horasMesParcial: number
  empleado: string
  empleadoCodigo: number
  empleadoCedula: string
  cargo: string
  diasEfecivos: number
  minTrabajados: number
  vacacionesRg: { label: string, value: number }
  sueldoNominal: number
  cambioOp: boolean
  dias: number
  d_horas: string
  d_min: string
  horas: string
  min: string
  jornada: { label: string, value: number }
  nocturnoHora: number
  nocturnoMin: number
  supleHora: number
  supleMin: number
  extraHora: number
  extraMin: number
  sueldoPercibido: number
  horarioNocturno: number
  horarioNH: number
  horarioNM: number
  horarioSuple: number
  horarioSH: number
  horarioSM: number
  horarioExtra: number
  horarioEH: number
  horarioEM: number
  comisiones: number
  bonoAportable: number
  baseAporteIess: number
  bonos: number
  bonificaciones: number
  totalIngreso: number
  vacaciones: number
  fondosReserva: number
  decimoTercero: number
  decimoCurto: number
  totalBeneficios: number
  iessPatronal: number
  secap: number
  iece: number
  iessPersonal: number
  subsidioMaternidad: number
  subsidioEnfermedad: number
  prestamos: number
  anticipos: number
  prestamosQuirografarios: number
  prestamosHipotecarios: number
  descuentos: number
  renta: number
  multas: number
  atrasos: number
  remmplazoOtros: number
  totalDescuentos: number
  totalPagar: number
  formaPago: TFormaPagoOption | null
  fechaRegistro: string
  fechaElaboracion: string
  fechaPago: string
  observaciones: string
  loading: boolean,
  horNH: boolean,
  horNM: boolean,
  sbu: number,
  modoEdicion: boolean
  loader: LoaderInfo
}

export type ExcellView = {
  archivo: string
  tamanio: string
  initial: boolean
  loader: LoaderInfo
}

export type GenrateRolsList = {
  codigo: number
  usuario: number
  nombre: string
  fechaRegistro: string
  fechaLabor: string
  fechaCobro: string
  HL: string
  ML: string
  dias: number
  sueldo: number
  MN: string
  jornada: string
  porcentajeJornada: number
  valorJornada: number
  MS: string
  horasSuplementarias: string
  porcentajeSuplementarias: number
  valorHoraSuplementarias: string
  ME: string
  horasExtras: string
  porcentajeExtra: string
  valorHoraExtra: string
  pagoIessPorcentaje
  pagoIess: number
  iessPorcentaje: number
  iess: number
  secapPorcentaje: number
  secap: number
  iecePorcentaje: number
  iece: number
  ajusteSueldo: number
  prestamos: number
  bonos: number
  prestamosQuirografarios: number
  prestamosHipotecarios: number
  anticipos: number
  descuentos: number
  renta: number
  fondosReserva: number
  multas: number
  reemplazo: string
  bonificacion: number
  fPago: number
  decimoTercero: number
  acumularFondosReserva: string
  nominal: number
  unificado: number
  observaciones: string
  rolDescripcion: string
  anio: number
  mes: number
  vacaciones: number
  convacaciones: number
  pagarIess: number
  decimoCuarto: number
  subsidioEnfermedad: number
  subsidioMaterniad: number
  atrasos: number
  localCodigo: number
}

export type GenerateData = {
  nmes: string
  mes: string
  anio: number
  tipoPersonal: number
  pais: number
  local: number
}

export type GenrateRols = {
  anio: number
  mes: TOptionGenericoString | null
  lugar: LocalOption | null
  pais: PaisOption | null
  tipoPersonal: { label: string, value: number }
  resultadosTodos: Array<GenrateRolsList>
  seleccionado: GenrateRolsList
  loading: boolean
  initial: boolean
  loader: LoaderInfo
}

export type TransferList = {
  pa: string
  cuentaEmpresa: string
  secuencial: string
  cuentaPago: string
  cuentaProveedor: string
  moneda: string
  formaPago: string
  cif: string
  bancoTipo: string
  tipoCuenta: string
  numeroCuenta: string
  ideCodigo: string
  tipoID: string
  usuarioCedula: string
  nombres: string
  usuarioDireccion: string
  ciudadCodigo: string
  ciudad: string
  sueldoMes: string
  valor: string
  flabor: string
}

export type FiltroBusqudaTransfer = {
  cuentaNombre: string
  cuentaCodigo: number
  mes: TOptionGenericoString | null
  anio: number
  initial: boolean
}

export type BusquedaTransferState<TransferList> = {
  filter: FiltroBusqudaTransfer
  filterBackup: FiltroBusqudaTransfer
  colapsado: boolean
  import: boolean
  searchRols: boolean
  seleccionado: TransferList
  loader: LoaderInfo
  nroRegistrosEncontrados: number
  resultados: Array<TransferList>
}


export type FiltroBusquedaRolesPago = {
  anio: number
  mes: TOptionGenericoString | null
  lugar: LocalOption | null
  cedula: string
  nombres: string
  apellidos: string
  formaPago: TFormaPagoOption | null
  listaFormasPagos: Array<TFormaPagoOption>
  opcionFormaPago: TFormaPagoOption | null
  opcionFecha: string | null
  pais: PaisOption | null
  todos: { label: string, value: number }
  initial: boolean
}


export type BusquedaState<RolesPayList> = {
  filter: FiltroBusquedaRolesPago
  filterBackup: FiltroBusquedaRolesPago
  colapsado: boolean
  colapsadoDetalle: boolean
  import: boolean
  searchRols: boolean
  seleccionado: RolesPayList
  loader: LoaderInfo
  nroRegistrosEncontrados: number
  status: FETCH_STATUS
  resultados: Array<RolesPayList>
  total: number
  numroles: number
}

export type SaveDataList = {
  fpaCodigo: number
  cobrado: number
  infoRegistro: Array<RolesPayList>
  fecha: string
}

export type PayRolsState = {
  menu: MenuState
  search: BusquedaState<RolesPayList>
  transfer: BusquedaTransferState<TransferList>
  editData: CustomDictionary<RolesPadoDatosEdicion>
  excell: ExcellView
  generate: GenrateRols
  tabs: TabsState<RolesPadoDatosEdicion>
  condfig: configRols
}

export type configRols = {
  aplicacion: number
  editData?: CustomDictionary<RolesPadoDatosEdicion>

}

export type SaveRolsPay = {
  infoRegistro: {
    codigo: number
    usuario: number
    anio: number
    mes: string
    sueldoNominal: number
    sueldo: number
    dias: string
    jornada: number
    porcentajeJornada: number
    valorJornada: number
    horasSuplementarias: number
    porcentajeSuplementarias: number
    valorHoraSuplementarias: number
    horasExtras: number
    porcentajeExtra: number
    valorHoraExtra: number
    comisiones: number
    porcentajeAportePatronal: number
    pagoIess: number
    porcentajeAportePersonal: number
    iess: number
    secapPorcentaje: number
    secap: number
    iecePorcentaje: number
    iece: number
    ajusteSueldo: number
    prestamos: number
    bonos: number
    prestamosQuirografarios: number
    prestamosHipotecarios: number
    anticipos: number
    descuentos: number
    renta: number
    fondosReserva: number
    subsidioMaternidad: number
    subsidioEnfermedad: number
    multas: number
    remplazo: number
    atrasos: number
    bonificacion: number
    acumularFondosReserva: number
    nominal: number
    unificado: number
    fechaCobro: string
    observaciones: string
    vacaciones: number
    calcularNominal: number
    pagarIess: number
    decimoTercero: number
    decimoCuarto: number
    local: number
    fpago: number
    formasPago: Array<any>
    centros: Array<CentrosAsignados>
  }
}


export type GenerateEntryNomina = {
  descripcion: string
  nrdoc: number
  diarioNumero: number
  codigo: number
  usuario: number
  anio: number
  mes: string
}

export type UpdatePay = {
  infoRegistro: {
    fechaCobro: string
    fechaLabor: string
    codigo: number
    usuario: number
    formaPago: number
    sueldoMes: number
    tipoPago: Array<any>
  }
}

export type GetTransfers = {
  cuentaBanco: number
  nombreMes: string
  mes: string
  anio: number
}


export type RolsListDto = {
  Month: string
  Employee: string
  Nominal: number
  Salary2: number
  Vjournal: number
  VSuple: number
  VExtra: number
  Commission: number
  SalaryAdjustment: number
  Faults: number
  Base: number
  Bonuses: number
  Booking: number
  Bonus: number
  IESS: number
  Loans: number
  Advances: number
  UnsecuredLoans: number
  MortgageLoans: number
  Discounts: number
  Income: number
  Fines: number
  Others: number
  Total3: number
}

export type AllRolsList = {
  Rols: PaymentsRolListDto[]
}

export type PaymentsRolListDto = {
  Code: number
  Type: string
  Month: string
  Year: number
  Employee: string
  Charge: string
  NominaSalary: number
  WorkDaysD: string
  WorkDaysH: string
  WorkDaysM: string
  SalaryReceived: number
  NightH2: number
  SupH3: number
  ExtraH4: number
  VJournal: number
  VSup: number
  VExtra: number
  Commissions: number
  SalaryAdjustments: number
  Base: number
  Bonuses: number
  Bonification: number
  Total1: number
  Booking: number
  Vacations: number
  Thirtheen: number
  Fourtheen: number
  Total4: number
  Total5: number
  IESS: number
  Subsidy: number
  Loans: number
  Advances: number
  UnsecuredLoans: number
  MortgageLoans: number
  Discounts: number
  Income: number
  Arrears: number
  Fines: number
  Others: number
  Total2: number
  Total3: number
  Observations: string
}
