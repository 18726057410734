import { ClientesStateEdition } from '../pages/clientes/types/types'
import { DesempenoState } from '../pages/desempeno/types/types'
import { DocCuentasXCobrarState } from '../pages/docsCtasXCobrar/types/types'
import { TipoClienteState } from '../pages/tipoCliente/types/types'
import { ZonasState } from '../pages/zonas/types/types'

export type ModuloClientesState = {
  clientes: ClientesStateEdition
  docCuentasXCobrar: DocCuentasXCobrarState
  desempeno: DesempenoState
  zonas: ZonasState
  tipoCliente: TipoClienteState
}

export enum Aplicacion {
  cliente = 'clientes',
  cuentasXCobrar = 'cuentasXCobrar',
  desempeno = 'desempeno',
  tipoCliente = 'tipo-cliente',
  zonas = 'zonas',
}

export type EdicionPayload<T> = {
  key: number | string
  data: T
}
