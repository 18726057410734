import {
  FunctionComponent,
  PropsWithChildren,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Draggable, SpeedDialAction } from 'devextreme-react'
import {
  FiltrosLineasState,
  LineasDatosEdicion,
  LinesReport,
} from '../../types/types'
import { ButtonTypes } from '../../../../../../../views/componentes/globalMenu/types'
import {
  clearButtonClick,
  openTab,
  setCurrentExecutingAction,
} from '../../store/tabsReducer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import {
  changeLoaderLines,
  setCurrentFunction,
} from '../../store/generalReducer'
import {
  changeFilter,
  fetchLineas,
  setCleanResult,
  setResetSeleccion,
} from '../../store/serachReducer'
import { TabState } from '../../../../../../../store/genericTabTypes'
import { FETCH_STATUS, ToastTypes } from '../../../../../../../store/types'
import { addToast } from '../../../../../../../store/toasterReducer'
import { InventarioService } from '../../../../../services/inventario.service'
import { v4 as uuidv4 } from 'uuid'
import { initDatosEdicion } from '../../store/editDataReducer'
import { ReportWithLocalDataModal } from '../../../../../../../views/componentes/xtrareports/ReportWithLocalDataModal'
import ResultadosBusqueda from './ResultadosBusqueda'
import BlockUi from '../../../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../../../views/componentes/loadingindicator/loadingindicator'
import FiltroBusqueda from './FiltroBusqueda'
import { isMobile } from 'react-device-detect'
import TableLoader from '../../../../../../ventas/components/ventas/busquedaVentas/TableLoader'
import Dialog from '../../../../../../../views/componentes/librerias/bootstrap-dialog'
import { defaultDataLines } from '../nuevo/index'
import { CCard, CCardFooter } from '@coreui/react-pro'
import { LineaInventario } from '../../../../../store/types'
import DataSource from 'devextreme/data/data_source'


interface ISearchProps extends PropsWithChildren {
  tab: TabState<LineasDatosEdicion>
  tabId: string
}

enum VistasBusqueda {
  Filtros,
  ResultadosBusqueda,
  Resumen,
  Loading,
  Error,
}

const draggingGroupName = 'appointmentsGroup'

const Search: FunctionComponent<ISearchProps> = (props) => {
  const { tabId, tab } = props

  const dispatch = useDispatch()
  const [vistaActual, setVistaActual] = useState<VistasBusqueda>(
    VistasBusqueda.Filtros,
  )
  const estadoBusqueda = useSelector(
    (state: RootState) => state.inventarios.catalogos.lineas.search.status,
  )

  const searchFilter = useSelector((state: RootState) => {
    return state.inventarios.catalogos.lineas.search.filter
  })
  const currentTab = useSelector((state: RootState) => {
    return state.inventarios.catalogos.lineas.tabs.current
  })
  const lineasLoader = useSelector((state: RootState) => {
    return state.inventarios.catalogos.lineas.general
  })
  const seleccionado = useSelector((state: RootState) => {
    return state.inventarios.catalogos.lineas.search.seleccionado
  })
  const { getLinesDatasource } = InventarioService.useSetDataSources()

  const DataSourceLines = useMemo(
    () => getLinesDatasource(searchFilter),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchFilter],
  )

  const [confirmDelete, setConfirmDelete] = useState<boolean>(false)
  const [confirmEdit, setConfirmEdit] = useState<boolean>(false)
  const [dataLineasPrint, setDataLineasPrint] = useState<LineaInventario[]>([])
  const [pageIndex, setPageIndex] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(10)
  const [reporte, setReporte] = useState<null | 'Viewer' | 'Designer'>(null)
  const dialogRef = useRef<any>(null)
  const [dataSourceLines, setDataSourceLines] = useState<DataSource>(null)

  const getFilterString = useCallback(() => {
    const filterText = ''
    return filterText
  }, [])

  const onSetButtonAction = useCallback(
    (tipo: ButtonTypes | undefined) => {
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'BUSQUEDA',
          buttonType: tipo,
        }),
      )
    },
    [dispatch],
  )

  const playLoader = useCallback(
    (message = 'Cargando...') => {
      dispatch(changeLoaderLines({ show: true, mensaje: message }))
    },
    [dispatch],
  )

  const stopLoader = useCallback(() => {
    dispatch(changeLoaderLines({ show: false, mensaje: '' }))
  }, [dispatch])

  const setToast = useCallback(
    (mensaje: string, tipo: ToastTypes) => {
      dispatch(
        addToast({
          title: 'Inventario - Lineas',
          content: mensaje,
          type: tipo,
        }),
      )
    },
    [dispatch],
  )

  const onResetPag = useCallback(async () => {
    setPageIndex(0)
    setPageSize(10)
  }, [])

  const onInitSerachFilter = useCallback(() => {
    const data = { ...searchFilter }
    if (data.initial) {
      data.descripcion = ''
      data.particular = ''
      data.initial = false
    }

    dispatch(
      changeFilter({
        ...data,
      }),
    )
  }, [dispatch, searchFilter])

  const onHandleEdit = useCallback(
    async (linea: any) => {
      const id = uuidv4()
      dispatch(
        initDatosEdicion({
          key: id,
          data: {
            ...defaultDataLines,
            loading: true,
          },
        }),
      )
      const lineaEdit: any = {
        ...linea,
        codigo: linea?.codigo ?? 0,
        codigoParticular: linea?.particular ?? 'nueva',
      }
      dispatch(openTab({ key: id, linea: lineaEdit }))
    },
    [dispatch],
  )

  const onBusqueda = useCallback(async () => {
    onSetButtonAction(ButtonTypes.find)
    playLoader('Buscando...')
    try {
      if (isMobile) {
        setVistaActual(VistasBusqueda.Loading)
      }
      await DataSourceLines.reload()
      setDataSourceLines(DataSourceLines)
      if (isMobile) {
        setVistaActual(VistasBusqueda.ResultadosBusqueda)
      }
    } catch (error) {
      setToast(error, ToastTypes.Danger)
    }
    stopLoader()
    onSetButtonAction(undefined)
  }, [
    playLoader,
    stopLoader,
    setToast,
    onSetButtonAction,
    DataSourceLines,
  ])

  const onHandleRemove = useCallback(async () => {
    try {
      const idLinea = seleccionado.row?.codigo ?? 0
      onSetButtonAction(ButtonTypes.delete)
      playLoader()
      if (isMobile) {
        setVistaActual(VistasBusqueda.Loading)
      }
      const data = await InventarioService.deleteLinea(idLinea)
      if (isMobile) {
        setVistaActual(VistasBusqueda.ResultadosBusqueda)
      }
      stopLoader()
      onSetButtonAction(undefined)
      if (
        data !== null &&
        data !== null &&
        data['error'] === false &&
        data['auto'] === true
      ) {
        onBusqueda()
        setToast(data['message'], ToastTypes.Success)
      } else {
        setToast(data['message'], ToastTypes.Danger)
      }
    } catch (error) {
      stopLoader()
      onSetButtonAction(undefined)
      setToast(error.message, ToastTypes.Danger)
    }
  }, [
    seleccionado,
    setToast,
    onBusqueda,
    onSetButtonAction,
    playLoader,
    stopLoader,
  ])

  const onBusquedaPrint = useCallback(
    async (tipo: null | 'Viewer' | 'Designer') => {
      onSetButtonAction(ButtonTypes.print)
      playLoader('Generando Reporte...')
      try {
        const toAny: any = fetchLineas(searchFilter)
        const res = await dispatch(toAny)
        if (res !== null && res !== undefined && res['payload'].length >= 0) {
          setDataLineasPrint(res['payload'])
          setReporte(tipo)
        } else {
          setToast(res, ToastTypes.Danger)
        }
      } catch (error) {
        setToast(error, ToastTypes.Danger)
      }
      stopLoader()
      onSetButtonAction(undefined)
    },
    [
      dispatch,
      searchFilter,
      setToast,
      onSetButtonAction,
      playLoader,
      stopLoader,
    ],
  )

  const onCleanResultFilter = useCallback(() => {
    const data = { ...searchFilter }
    data.descripcion = ''
    data.particular = ''
    dispatch(changeFilter({ ...data }))
    dispatch(setCleanResult())
    dispatch(setResetSeleccion())
    setDataLineasPrint([])
  }, [dispatch, searchFilter])

  const onBroomResult = useCallback(() => {
    setDataSourceLines(null)
  }, [])

  const onConfirmDelete = useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea Eliminar el Registro: ${seleccionado.row?.descripcion ?? '-'
        }?`,
      actions: [
        Dialog.Action(
          <span>
            <u>A</u>ceptar
          </span>,
          (dialog) => {
            dialog.hide()
            onHandleRemove()
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setConfirmDelete(false)
    return
  }, [onHandleRemove, seleccionado?.row])

  const onConfirmEdit = useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea Editar el registro: ${seleccionado.row?.descripcion ?? '-'
        }?`,
      actions: [
        Dialog.Action(
          <span>
            <u>A</u>ceptar
          </span>,
          (dialog) => {
            dialog.hide()
            onHandleEdit(seleccionado.row)
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setConfirmEdit(false)
    return
  }, [onHandleEdit, seleccionado?.row])

  const handleButtonClick = useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.find:
          onResetPag()
          onBusqueda()
          break
        case ButtonTypes.delete:
          setConfirmDelete(true)
          break
        case ButtonTypes.edit:
          setConfirmEdit(true)
          break
        case ButtonTypes.broom:
          onBroomResult()
          break
        case ButtonTypes.undo:
          onCleanResultFilter()
          break
        case ButtonTypes.print:
          if (dataLineasPrint.length > 0) {
            setReporte('Viewer')
          } else {
            onBusquedaPrint('Viewer')
          }
          break
        case ButtonTypes.print_design:
          if (dataLineasPrint.length > 0) {
            setReporte('Designer')
          } else {
            onBusquedaPrint('Designer')
          }
          break
        default:
          break
      }
      dispatch(setCurrentFunction(''))
      dispatch(clearButtonClick(tabId))
    },
    [
      dispatch,
      tabId,
      onBusquedaPrint,
      onBusqueda,
      dataLineasPrint,
      onCleanResultFilter,
      onBroomResult,
      onResetPag
    ],
  )

  useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab.globalButtonClick])

  useEffect(() => {
    if (confirmDelete) {
      onConfirmDelete()
    }
  }, [confirmDelete, onConfirmDelete])

  useEffect(() => {
    if (confirmEdit) {
      onConfirmEdit()
    }
  }, [confirmEdit, onConfirmEdit])

  useEffect(() => {
    onInitSerachFilter()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOptionChange = useCallback(async (e) => {
    if (e.fullName === 'paging.pageSize') {
      setPageSize(e.value)
    }
    if (e.fullName === 'paging.pageIndex') {
      setPageIndex(e.value)
    }
  }, [])

  const onParseData = useCallback((lineas: Array<any> = []) => {
    let registros: Array<LinesReport> = []
    if (lineas.length > 0) {
      registros = lineas.map((x) => {
        return {
          Code: x?.particular ?? 0, // particular
          Description: x?.descripcion ?? '',
          State: x?.estado ? 1 : 2,
        }
      })
    }
    return registros
  }, [])

  useEffect(() => {
    switch (estadoBusqueda) {
      case FETCH_STATUS.IDDLE:
        setVistaActual(VistasBusqueda.Filtros)
        break
      case FETCH_STATUS.LOADING:
        setVistaActual(VistasBusqueda.Loading)
        break
      case FETCH_STATUS.SUCCESS:
        setVistaActual(VistasBusqueda.ResultadosBusqueda)
        break
      case FETCH_STATUS.FAILED:
        setVistaActual(VistasBusqueda.Error)
        break
      default:
        break
    }
  }, [estadoBusqueda])

  if (isMobile) {
    return (
      <>
        <ReportWithLocalDataModal
          show={reporte !== null}
          onClose={() => setReporte(null)}
          data={onParseData(dataLineasPrint)}
          fileName="StaticLineasListado"
          mode={reporte ?? 'Viewer'}
          parameters={{ Reporte_Filtro: getFilterString() }}
          template="StaticLineasListado"
          key="reportDesigner"
        />
        <CCard>
          <Dialog ref={dialogRef} />
          {vistaActual === VistasBusqueda.Filtros && (
            <>
              <FiltroBusqueda onSearchEnter={onBusqueda} />
            </>
          )}
          {vistaActual === VistasBusqueda.ResultadosBusqueda && (
            <>
              <ResultadosBusqueda
                handleOptionChange={handleOptionChange}
                pageIndex={pageIndex}
                pageSize={pageSize}
                data={dataSourceLines}
                onDBClick={() => setConfirmEdit(true)}
                onDelete={() => setConfirmDelete(true)}
              />
            </>
          )}
          {vistaActual === VistasBusqueda.Error && <></>}
          {vistaActual === VistasBusqueda.Loading && (
            <CCardFooter>
              <TableLoader />
            </CCardFooter>
          )}
          {currentTab === tabId && (
            <Draggable id="list" data="dropArea" group={draggingGroupName}>
              <SpeedDialAction
                icon="filter"
                label="Filtros"
                visible={true}
                index={1}
                onClick={() => {
                  setVistaActual(VistasBusqueda.Filtros)
                }}
              />
              <SpeedDialAction
                icon="search"
                label="Busqueda"
                visible={true}
                index={2}
                onClick={() => {
                  setVistaActual(VistasBusqueda.ResultadosBusqueda)
                }}
              />
            </Draggable>
          )}
        </CCard>
      </>
    )
  }

  return (
    <>
      <div>
        <Dialog ref={dialogRef} />
      </div>
      <ReportWithLocalDataModal
        show={reporte !== null}
        onClose={() => setReporte(null)}
        data={onParseData(dataLineasPrint)}
        fileName="StaticLineasListado"
        mode={reporte ?? 'Viewer'}
        parameters={{ Reporte_Filtro: getFilterString() }}
        template="StaticLineasListado"
        key="reportDesigner"
      />
      <div id="lineasContent">
        <BlockUi
          tag="div"
          loader={LoadingIndicator}
          blocking={lineasLoader.loader.show}
          message={lineasLoader.loader.mensaje}
        >
          <CCard>
            <FiltroBusqueda onSearchEnter={onBusqueda} />
            <ResultadosBusqueda
              data={dataSourceLines}
              onDBClick={() => setConfirmEdit(true)}
              handleOptionChange={handleOptionChange}
              pageIndex={pageIndex}
              pageSize={pageSize}
              onDelete={() => setConfirmDelete(true)}
            />
          </CCard>
        </BlockUi>
      </div>
    </>
  )
}

export default memo(Search)

export const initFilterLines: FiltrosLineasState = {
  particular: null,
  descripcion: null,
  initial: true,
}
