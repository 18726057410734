import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../../store/store'
import { FETCH_STATUS } from '../../../../../store/types'
import {
  TTransporteListado,
  TFiltroBusquedaTransporteState
} from './types';
import { TConfigSearchState } from '../../store/types'
import {TransporteService} from "../services/transporte.services";
import {fetchAbonarFCEmpresa} from "../../../../clientes/pages/docsCtasXCobrar/store/abonosReducer";

const initialState: TConfigSearchState<TTransporteListado> = {
  filter: {
    codigo: 0,
    descripcion: '',
    codigoTributario: '',
    estado: 1,
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  resultados: [],
  selectedItem: null,
  error: false,
}

export const fetchTransporte = createAsyncThunk<
  Array<TTransporteListado>,
  TFiltroBusquedaTransporteState
>('catalogos/getAll/transporte', async (filtro) => {
  try {
    return await TransporteService.getCollection(filtro)
  } catch (error) {
    return Promise.reject(error)
  }
});

const searchConfSlice = createSlice({
  name: 'TransporteSearch',
  initialState: initialState,
  reducers: {
    resetState(state) {
      state = {
        ...initialState,
        filter: {
          ...initialState.filter,
        },
      }
    },
    changeFilter(state, action: PayloadAction<TFiltroBusquedaTransporteState>) {
      state.filter = action.payload
    },
    setCleanResult(state) {
      state.resultados = []
    },
    setResultados(state, action: PayloadAction<Array<TTransporteListado> | []>) {
      state.resultados = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTransporte.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
        state.error = false
      }
    })
    builder.addCase(fetchTransporte.fulfilled, (state, { payload }: {payload:any}) => {
      state.resultados = payload.auto
      state.status = FETCH_STATUS.SUCCESS
      state.error = payload.error
      state.mensaje = payload.message
    })
    builder.addCase(fetchTransporte.rejected, (state, { payload }: {payload:any}) => {
      state.resultados = []
      state.status = FETCH_STATUS.FAILED
      state.error = payload.error
      state.mensaje = payload.message
    })
  }
});

export const selectFiltroBusquedaTransporte = (state: RootState) => {
  return state.administracion.catalagos.transporte.search.filter
}

export const selectResultadosBusquedaTransporte = (state: RootState) => {
  return state.administracion.catalagos.transporte.search.resultados
}

export const selectEstadoBusquedaTransporte = (state: RootState) => {
  return state.administracion.catalagos.transporte.search.status
}

export const { setResultados, resetState, setCleanResult, changeFilter } = searchConfSlice.actions
export const searchReducer = searchConfSlice.reducer
