import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { OptionGrupos } from '../../../../../store/types'
import { OptionProyectos } from '../../../../bancos/general/types'
import {
  UsuarioModal, ModalUsuarioState
} from './types'

const initialState: ModalUsuarioState = {
  modulo: 'modalUsuarios',
  actionDisplay: '',
  currentAction: 'Buscar',
  registros: [],
  tipos: [],
  estados: [],
  proyectos: [],
  proyectosBack: [],
  locales: [],
  localesBack: [],
}

const modalUsuariosSlice = createSlice({
  name: 'modalUsuarios',
  initialState: initialState,
  reducers: {
    setCurrentAccion(state, action: PayloadAction<any>) {
      state.currentAction = action.payload
    },
    setData(state, action: PayloadAction<Array<UsuarioModal>>) {
      state.registros = action.payload
    },
    setProyectos(state, action: PayloadAction<Array<OptionProyectos>>) {
      state.proyectos = action.payload
    },
    setProyectosBack(state, action: PayloadAction<Array<OptionProyectos>>) {
      state.proyectosBack = action.payload
    },
    setLocales(state, action: PayloadAction<Array<OptionGrupos>>) {
      state.locales = action.payload
    },
    setLocalesBack(state, action: PayloadAction<Array<OptionGrupos>>) {
      state.localesBack = action.payload
    },
  }
})
export const {
  setData,
  setCurrentAccion,
  setProyectos,
  setProyectosBack,
  setLocalesBack,
  setLocales,
} = modalUsuariosSlice.actions
export const ModalUsuariosReducer = modalUsuariosSlice.reducer
