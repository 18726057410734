import { DateUtils, formatoFechasApi } from "../../../../helpers/dateUtils";
import { RequestHelper } from "../../../../helpers/requestHelper";
import { utilidades } from "../../../../helpers/utilidades";
import { SesionService } from "../../../../services/sesion.service";
import { ESubtipo } from "../../../../store/enum/subtypes";
import { TiposEmpresasOption } from "../store/type";

export const estadosService = { getEstados };

async function getEstados(label: string): Promise<any> {
  try {
    const data: any = {
      mensaje: label,
      tipoCodigo: ESubtipo.TipoEmpresa
    };
    const sesion = SesionService.getCurrentSesion()
    const keysToCache: any = {
      ...data,
      dateKey: DateUtils.getCurrentDateAsString(formatoFechasApi),
      empresa: sesion?.empresa?.codigo,
    }
    const cacheKey = 'tipoEmpresas' + JSON.stringify(keysToCache);
    const cached = sessionStorage.getItem(cacheKey);
    if (cached) {
      return { error: false, auto: JSON.parse(cached) };
    } else {
      const res = await RequestHelper.getAll<any>('subtypes', 'cboxLoad', "", data);
      const opt: Array<TiposEmpresasOption> = [];
      if (res?.auto && res?.auto.length > 0) {
        for (const x of res.auto) {
          opt.push({
            codigo: (x?.grupo === null ? -1 : parseInt(x?.grupo)) ?? -1,
            descripcion: utilidades.capText(x?.valor) ?? '',
          })
        }
        res.auto = opt
      }
      sessionStorage.setItem(cacheKey, JSON.stringify(opt));
      return res;
    }
  } catch (error) {
    return error;
  }
}

