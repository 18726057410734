import React, { useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useDispatch, useSelector } from 'react-redux'

import { AccionMenu, CustomButtons, FETCH_STATUS, ToastTypes } from '../../../../store/types'
import { RootState } from '../../../../store/store'
import { useMenuOptions } from '../../../../hooks/useMenuButtonControls'
import PersonalMain from './components/shared/main'
import Dialog from '../../../../views/componentes/librerias/bootstrap-dialog'
import { buttonClick, changeCurrentTab, openTab, openTabAsign, openTabAsignUserLocal, openTabPermission, personalCloseTab, setCurrentExecutingAction } from './store/tabsReducer'
import { EModulosAcatha } from '../../../../store/enumsAplication'
import { Aplicacion } from '../../store/types'
import { setMenuButtons } from './store/menuReducer'
import { ButtonTypes } from '../../../../views/componentes/globalMenu/types'
import { deleteEditData, initDatosEdicion } from './store/editDataReducer'
import { utilidades } from '../../../../helpers/utilidades'
import { deleteEditDataBackup, setAplicacionModulo } from './store/configReducer'
import { defaultPersonalData } from './components/nuevo'
import { TabTypes } from '../../../../store/enums'
import { addToast } from '../../../../store/toasterReducer'
import { setInitital } from './store/permisosReducer'


const pathVerificador = '/personal'
interface PersonalProps extends React.PropsWithChildren { }


const Personal: React.FunctionComponent<PersonalProps> = () => {

  const { getMenuOptions, options } = useMenuOptions()

  const dispatch = useDispatch()

  const tabs = useSelector((state: RootState) => {
    return state.nomina.personal.tabs
  })
  const searchResults = useSelector((state: RootState) => {
    return state.nomina.personal.search.resultados
  })
  const searchStatus = useSelector((state: RootState) => {
    return state.nomina.personal.search.status
  })
  const seleccionado = useSelector((state: RootState) => {
    return state.nomina.personal.search.seleccionado
  })
  const dialogRef = React.useRef<any>(null)
  const menuState = useSelector((state: RootState) => state.global.menu)

  const setToast = React.useCallback(
    (mensaje: string, tipo: ToastTypes) => {
      dispatch(
        addToast({
          title: 'Nómina - Personal',
          content: mensaje,
          type: tipo,
        }),
      )
    },
    [dispatch],
  )


  const onTabClosing = React.useCallback(
    async (index) => {
      const closedTab = tabs.tabs[index]
      dispatch(personalCloseTab(closedTab.tabKey ?? index))
      await utilidades.sleep(50)
      dispatch(deleteEditData(closedTab.tabKey ?? index))
      dispatch(deleteEditDataBackup(closedTab.tabKey ?? index))
    },
    [dispatch, tabs.tabs],
  )

  const onTabChanged = React.useCallback(
    (tabKey: string) => {
      dispatch(changeCurrentTab(tabKey))
    },
    [dispatch],
  )

  const onActionsButtonsLoaded = React.useCallback((actions: Array<AccionMenu>) => {
    dispatch(setMenuButtons(actions));
  }, [dispatch]);

  const onMenuButtonClick = React.useCallback(
    async (action: AccionMenu, tab: string = "") => {
      const id = uuidv4()
      switch (action.actionType) {
        case ButtonTypes.find:
          if (tabs.current === 'BUSQUEDA') {
            dispatch(buttonClick({
              tabKey: tab === "" ? tabs.current : tab,
              button: action.actionType
            }))
          } else dispatch(changeCurrentTab('BUSQUEDA'))
          break
        case ButtonTypes.new:
          dispatch(
            initDatosEdicion({
              key: id,
              data: {
                ...defaultPersonalData,
                loading: true,
              },
            }),
          )
          dispatch(openTab({ key: id }))
          break
        case ButtonTypes.delete:
          dispatch(buttonClick({
            tabKey: tab === "" ? tabs.current : tab,
            button: action.actionType
          }))
          break
        case ButtonTypes.edit:
          dispatch(buttonClick({
            tabKey: tab === "" ? tabs.current : tab,
            button: action.actionType
          }))
          break
        case ButtonTypes.broom:
          dispatch(buttonClick({
            tabKey: tab === "" ? tabs.current : tab,
            button: action.actionType
          }))
          break
        case ButtonTypes.import:
          dispatch(buttonClick({
            tabKey: tab === "" ? tabs.current : tab,
            button: action.actionType
          }))
          break
        case ButtonTypes.print:
          dispatch(buttonClick({
            tabKey: tab === "" ? tabs.current : tab,
            button: action.actionType
          }))
          break
        case ButtonTypes.print_design:
          dispatch(buttonClick({
            tabKey: tab === "" ? tabs.current : tab,
            button: action.actionType
          }))
          break
        case ButtonTypes.assignLocalUsers:
          dispatch(openTabAsignUserLocal({ key: 'ASIGNARUSULOCAL', type: TabTypes.asignarUsuariosLocales }))
          break
        case ButtonTypes.assign:
          dispatch(openTabAsign({ key: 'ASIGNAR', type: TabTypes.asignarUsuariosOrganigrama }))
          break
        case ButtonTypes.permissions:
          if (seleccionado !== null && seleccionado !== undefined && seleccionado?.codigo) {
            dispatch(setInitital(true))
            dispatch(openTabPermission({ key: 'PERMISOS', type: TabTypes.permisos }))
          } else {
            setToast('Debe elegir un usuario para poder asignar permisos.', ToastTypes.Warning)
          }
          break
        case ButtonTypes.undo:
          dispatch(buttonClick({
            tabKey: tab === "" ? tabs.current : tab,
            button: action.actionType
          }))
          break
        case ButtonTypes.users:
          if (tabs.current === 'BUSQUEDA') {
            dispatch(buttonClick({
              tabKey: tab === "" ? tabs.current : tab,
              button: action.actionType
            }))
          } else dispatch(changeCurrentTab('BUSQUEDA'))
          break
        case ButtonTypes.save:
          dispatch(buttonClick({
            tabKey: tab === "" ? tabs.current : tab,
            button: action.actionType
          }))
          break
        default:
          break
      }
    },
    [dispatch, tabs, setToast, seleccionado],
  )

  const onGetAplicationCode = React.useCallback(() => {
    dispatch(setAplicacionModulo(parseInt(EModulosAcatha.Personal)))
  }, [dispatch])

  const onSearchExecutingAction = React.useCallback((tabKey: string, type?: ButtonTypes) => {
    dispatch(setCurrentExecutingAction({
      tabKey: tabKey,
      buttonType: type
    }))
  }, [dispatch]);

  useEffect(() => {
    if (menuState.length > 0 && options.length === 0) {
      getMenuOptions(menuState, 'to', pathVerificador)
    }
  }, [menuState, getMenuOptions, options])

  useEffect(() => {
    onGetAplicationCode()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div>
        <Dialog ref={dialogRef} />
      </div>
      <PersonalMain
        menu={{ loading: false, accions: options }}
        tabs={tabs}
        onTabChanged={onTabChanged}
        codigoModulo={parseInt(EModulosAcatha.Personal)}
        opciones={OpcionesModeulo}
        onActionsButtonsLoaded={onActionsButtonsLoaded}
        onMenuButtonClick={onMenuButtonClick}
        searching={searchStatus === FETCH_STATUS.LOADING}
        ontabClosing={onTabClosing}
        hayResultadosBusqueda={searchResults.length > 0}
        verificarPuntoVenta
        verificarAutorizacion
        onExecutingAction={onSearchExecutingAction}
        tipoAplicacion={Aplicacion.personal}
      />
    </>
  )
}

export default React.memo(Personal)

export const ModuleButtons: CustomButtons = {
  Nuevo: true,
  Buscar: false,
  Editar: false,
  Eliminar: false,
  Guardar: true,
  Exportar: false,
  Deshacer: true,
  Imprimir: false,
  Limpiar: false,
  Permisos: true,
  Asignar: true,
  Asignar_usuarios_a_locales: true,
  Usuarios: true,
}

export const ModuleButtonsUserLocal: CustomButtons = {
  Nuevo: true,
  Buscar: false,
  Editar: false,
  Eliminar: false,
  Guardar: false,
  Exportar: false,
  Deshacer: false,
  Imprimir: false,
  Limpiar: false,
  Permisos: true,
  Asignar: true,
  Asignar_usuarios_a_locales: true,
  Usuarios: true,
}
export const ModuleButtonsOrganigrama: CustomButtons = {
  Nuevo: true,
  Buscar: false,
  Editar: false,
  Eliminar: false,
  Guardar: false,
  Exportar: false,
  Deshacer: false,
  Imprimir: false,
  Limpiar: false,
  Permisos: true,
  Asignar: true,
  Asignar_usuarios_a_locales: true,
  Usuarios: true,
}

export const ModuleButtonsPermisos: CustomButtons = {
  Nuevo: true,
  Buscar: false,
  Editar: false,
  Eliminar: true,
  Guardar: false,
  Exportar: false,
  Deshacer: false,
  Imprimir: true,
  Limpiar: false,
  Permisos: true,
  Asignar: true,
  Asignar_usuarios_a_locales: true,
  Usuarios: true,
}


const OpcionesModeulo = [parseInt(EModulosAcatha.Personal)];
