import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DataGrid, {
  Column,
  Paging,
  Pager,
  ColumnChooser,
  HeaderFilter,
  TotalItem,
  Summary,
  Export,
} from 'devextreme-react/data-grid'
import { DatosVenta } from '../../../../types/types'
import { getAllowedPageSizes } from '../../../../../../../../../../../helpers/Helper'
import { RootState } from '../../../../../../../../../../../store/store'
import { Workbook } from 'exceljs'
import { saveAs } from 'file-saver'
import { exportDataGrid } from 'devextreme/excel_exporter'
interface ITablaCCFProps extends React.PropsWithChildren {
  data: Array<DatosVenta> | []
  onExport: (data) => void
}
const pageSizes = [10, 25, 50, 100, 200]

const TablaSuj: React.FunctionComponent<ITablaCCFProps> = (props) => {
  const { data, onExport } = props

  const filtros = useSelector((state: RootState) => {
    return state.impuestosSV.anexoTransaccional.search.filter
  })

  const [showInfo] = React.useState<boolean>(true)
  const [showNavButtons] = React.useState<boolean>(true)
  const [showPageSizeSelector] = React.useState<boolean>(true)
  const [displayMode] = React.useState<string | 'full' | 'compact'>('full')

  const dispatch = useDispatch()

  const dataGrid = React.useRef<any>()

  const clearFilter = React.useCallback(() => {
    dataGrid.current.instance.clearSorting()
    dataGrid.current.instance.clearFilter()
  }, [])

  const onExportExcell = React.useCallback(
    (e) => {
      console.log('onExportExcell', e)
      const rango = `${filtros?.fechaInicio} - ${filtros?.fechaFinal}`
      const fileName = `anexo_sujetoExcluido-${rango}`
      if (e.format === 'xlsx') {
        const workbook = new Workbook()
        const worksheet = workbook.addWorksheet('SujetoExcluido')
        e.component.exportDataFieldHeaders = false
        exportDataGrid({
          component: e.component,
          worksheet,
          autoFilterEnabled: true,
        }).then(() => {
          workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(
              new Blob([buffer], { type: 'application/octet-stream' }),
              fileName + '.xlsx',
            )
          })
        })
      } else if (e.format === 'pdf') {
        // const doc = new jsPDF();
        // exportDataGridToPdf({
        //   jsPDFDocument: doc,
        //   component: e.component
        // }).then(() => {
        //   doc.save('anexo_consumidor_final.pdf');
        // })
      } else if (e.format === 'csv') {
        onExport({
          title: 'SujetoExcluido',
          format: e.format,
          component: e.component,
          filename: fileName,
        })
      }
    },
    [filtros, onExport],
  )

  const calculateCustomSummary = React.useCallback((options) => {
    if (options.summaryProcess === 'start') {
      options.totalValue = 0
    } else if (options.summaryProcess === 'calculate') {
      if (options.value.tipoDocumento === '04') {
        options.totalValue = options.totalValue - options.value[options.name]
      } else {
        options.totalValue = options.totalValue + options.value[options.name]
      }
    }
  }, [])

  const customizeSummaryText = React.useCallback((data) => {
    return data.value.toFixed(2)
  }, [])

  return (
    <DataGrid
      ref={dataGrid}
      selection={{ mode: 'single' }}
      allowColumnResizing={true}
      columnResizingMode={'nextColumn'}
      focusedRowEnabled={true}
      keyExpr="comprobante"
      focusedRowKey="comprobante"
      dataSource={data}
      showBorders={true}
      onExporting={onExportExcell}
      width={'100%'}
    >
      <Pager
        visible={data.length > 0 ? true : false}
        allowedPageSizes={getAllowedPageSizes(data)}
        displayMode={displayMode}
        showPageSizeSelector={showPageSizeSelector}
        showInfo={showInfo}
        showNavigationButtons={showNavButtons}
      />
      {/* <Toolbar>
        <Item>
          <Button
            onClick={() => {
              onExport(data)
            }}
            icon="exportxlsx"
            disabled={false}
            text="Exportar"
          />
        </Item>
        <Item name="columnChooserButton" />
      </Toolbar> */}
      <Export
        enabled={true}
        allowExportSelectedData={false}
        formats={['csv', 'xlsx']}
      />
      <ColumnChooser enabled={true} mode="select" />
      <HeaderFilter visible={true} />
      <Column
        dataField="tipoDocProveedor"
        caption="TIPO DE DOCUMENTO"
        width="80px"
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />
      <Column
        dataField="proveedorIdentificacion"
        caption="NÚMERO DE NIT, DUI U OTRO DOCUMENTO"
        width="110px"
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />
      <Column
        dataField="proveedorNombre"
        caption="NOMBRE, RAZÓN SOCIAL O DENOMINACIÓN"
        width="110px"
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />
      <Column
        dataField="fechaNegociacion"
        caption="FECHA DE EMISIÓN DEL DOCUMENTO"
        width="110px"
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />
      <Column
        dataField="autorizacionHacienda.selloRecibido"
        caption="NÚMERO DE SERIE DEL DOCUMENTO"
        width="110px"
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />
      <Column
        dataField="autorizacionHacienda.codigoGeneracion"
        caption="NÚMERO DE DOCUMENTO"
        width="110px"
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />
      <Column
        dataField="total"
        caption="MONTO DE LA OPERACIÓN"
        width="110px"
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />
      <Column
        dataField="retenidoIva"
        caption="MONTO DE LA RETENCIÓN DEL IVA 13%"
        width="110px"
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />
      <Column
        dataField="operacionDescripcion"
        caption="TIPO DE OPERACIÓN"
        width="110px"
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />
      <Column
        dataField="clasificacionDescripcion"
        caption="CLASIFICACIÓN "
        width="110px"
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />
      <Column
        dataField="sectorDescripcion"
        caption="SECTOR  "
        width="110px"
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />
      <Column
        dataField="tipoCostoDescripcion"
        caption="TIPO DE COSTO/GASTO   "
        width="110px"
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />

      <Column
        dataField="numeroAnexo"
        caption="NÚMERO DEL ANEXO"
        width="110px"
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={true}
      >
        <HeaderFilter allowSearch={true} />
      </Column>

      <Pager
        allowedPageSizes={pageSizes}
        showPageSizeSelector={true}
        showInfo
      />
      <Paging defaultPageSize={10} />

      <Summary calculateCustomSummary={calculateCustomSummary}>
        <TotalItem
          customizeText={customizeSummaryText}
          name="subtotal0"
          showInColumn="subtotal0"
          summaryType="custom"
          displayFormat="{0}"
          valueFormat="currency"
          alignment="right"
        />
        <TotalItem
          customizeText={customizeSummaryText}
          name="subtotalIva"
          showInColumn="subtotalIva"
          summaryType="custom"
          displayFormat="{0}"
          valueFormat="currency"
          alignment="right"
        />
        <TotalItem
          customizeText={customizeSummaryText}
          name="subtotalNoIva"
          showInColumn="subtotalNoIva"
          summaryType="custom"
          displayFormat="{0}"
          valueFormat="currency"
          alignment="right"
        />
        <TotalItem
          customizeText={customizeSummaryText}
          name="iva"
          showInColumn="iva"
          summaryType="custom"
          displayFormat="{0}"
          valueFormat="currency"
          alignment="right"
        />
        <TotalItem
          customizeText={customizeSummaryText}
          name="total"
          showInColumn="total"
          summaryType="custom"
          displayFormat="{0}"
          valueFormat="currency"
          alignment="right"
        />
      </Summary>
    </DataGrid>
  )
}
export default TablaSuj
