import { RequestHelper } from '../../../../helpers/requestHelper'
import {
  DateRange,
  TiposComprobantesSLV,
  TiposComprobantesSri,
} from '../../../../store/types'
import { TAutorizacionDocumentoSV } from '../../../ventas/types/types'
import {
  ComprasSVListado,
  SincronizadosListado,
  TComprasIngresarSV,
} from '../pages/compras/types/types'

export const ComprasSVService = {
  ingresarCompra,
  buscarComprasSV,
  buscarSincronizadosSV,
}

async function ingresarCompra(
  payload: TComprasIngresarSV,
): Promise<Promise<any>> {
  try {
    const creada = await RequestHelper.postAll<any>(
      'compras',
      'insert',
      payload,
    )
    return creada
  } catch (error) {
    return error
  }
}

async function buscarComprasSV(
  range: DateRange | null,
  numero: string,
  identificacion: string,
  tipoDoc: TiposComprobantesSLV | TiposComprobantesSri | null,
  fpago: string,
  claveAcceso: string,
): Promise<Array<ComprasSVListado>> {
  const queryData: any = {}

  if (range) {
    queryData.desde = range.inicio
    queryData.hasta = range.fin
  }

  if (numero && numero.length > 0) {
    queryData.compraNumero = numero
  }

  if (identificacion && identificacion.length > 0) {
    queryData.proveedorIdentificacion = identificacion
  }

  if (tipoDoc && tipoDoc.length > 0) {
    queryData.compraTipoDocumento = tipoDoc
  } else {
    queryData.compraTipoDocumento = -1
  }

  if (fpago && fpago.length > 0) {
    queryData.compraFormaPago = fpago
  }

  if (claveAcceso) {
    queryData.claveAcceso = claveAcceso
  }

  let res = await RequestHelper.get<Array<ComprasSVListado>>(
    'compras',
    'compras/getAll',
    '',
    queryData,
  )

  if (res.length > 0) {
    res = parseDataSujeto(res)
  }
  return res
}

function parseDataSujeto(apiData: Array<any>): Array<ComprasSVListado> {
  const items = apiData.map((x) => {
    let autorizacionHacienda: TAutorizacionDocumentoSV = null
    if (x?.autorizacionHacienda) {
      autorizacionHacienda = {
        estado: x?.autorizacionHacienda?.estado ?? '',
        version: x?.autorizacionHacienda?.version ?? '',
        ambiente: x?.autorizacionHacienda?.ambiente ?? '',
        codigoMensaje: x?.autorizacionHacienda?.codigoMensaje ?? '',
        versionApp: x?.autorizacionHacienda?.versionApp ?? '',
        clasificacionMensaje:
          x?.autorizacionHacienda?.clasificacionMensaje ?? '',
        selloRecibido: x?.autorizacionHacienda?.selloRecibido ?? '',
        descripcionMensaje: x?.autorizacionHacienda?.descripcionMensaje ?? '',
        fechaProcesamiento: x?.autorizacionHacienda?.fechaProcesamiento ?? '',
        tipoComprobante: x?.autorizacionHacienda?.tipoComprobante ?? '',
        codigoGeneracion: x?.autorizacionHacienda?.codigoGeneracion ?? '',
        claveAcceso: x?.autorizacionHacienda?.claveAcceso ?? '',
        codigo: x?.autorizacionHacienda?.codigo ?? '',
      }
    }

    const item: ComprasSVListado = {
      ice: parseFloat(x.ICE) ?? 0,
      iva: parseFloat(x.IVA) ?? 0,
      identificador: x?.identificador ?? '',
      codigoGeneracion: x?.identificador ?? '',
      selloRecepcion: x?.selloRecepcion ?? '',
      asientoNumero: x?.asientoNumero !== null ? String(x.asientoNumero) : '',
      autorizacion: String(x?.autorizacion) ?? '',
      autorizacionHacienda: autorizacionHacienda,
      claveAcceso: String(x?.claveAcceso) ?? '',
      relacionado: x?.relacionado,
      claveFecha: String(x?.claveFecha) ?? '',
      retener: Number(x?.retener) ?? 0,
      codigo: parseInt(x?.codigo) ?? 0,
      descuento: parseFloat(x.descuento) ?? 0,
      establecimiento: String(x?.establecimiento) ?? '',
      estadoCodigo: parseInt(x?.estado) ?? 0,
      estadoDescripcion: String(x?.estadoDescripcion) ?? '',
      fecha: String(x?.fecha) ?? '',
      fechaAutorizacion: String(x?.fechaAutorizacion) ?? '',
      fechaHoraEmision: String(x?.fechaHoraEmision) ?? '',
      formaPagoCodigo: parseInt(x?.formaPagoCodigo) ?? 0,
      formaPagoDescripcion: String(x?.formaPagoDescripcion) ?? '',
      formaPagoCodigoTributario: String(x?.formaPagoCodigoTributario) ?? '',
      puntoEmision: String(x?.puntoEmision) ?? '',
      localCodigo: parseInt(x?.localCodigo) ?? 0,
      localNombre: String(x?.localNombre) ?? '',
      numero: String(x?.numero) ?? '',
      observaciones: String(x?.observaciones) ?? '',
      proveedorCodigo: parseInt(x?.proveedorCodigo) ?? 0,
      proveedorDireccion: String(x?.proveedorDireccion) ?? '',
      proveedorEmail: String(x?.proveedorEmail) ?? '',
      proveedorIdentificacion: String(x?.proveedorIdentificacion) ?? '',
      proveedorTipoIdentificacionCodigo:
        parseInt(x?.proveedorTipoIdentificacion) ?? 0,
      proveedorTipoIdentificacionCodigoTributario:
        String(x?.proveedorTipoIdentificacionCodigoTributario) ?? '',
      proveedorNombre: String(x?.proveedorNombre) ?? '',
      proveedorRazonComercial: String(x?.proveedorRazonComercial) ?? '',
      proveedorTelefono: String(x?.proveedorTelefono) ?? '',
      puntoVenta: String(x?.puntoVenta) ?? '',
      puntoVentaCodigo: x?.puntoVentaCodigo ?? -1,
      subtotalIva: parseFloat(x?.subtotal12) ?? 0,
      subtotal0: parseFloat(x?.subtotal0) ?? 0,
      subtotalNoIva: parseFloat(x?.subtotalNoIVA) ?? 0,
      subtotal: parseFloat(x?.subtotal) ?? 0,
      totalExcenta: parseFloat(x?.totalExcenta) ?? 0,
      totalGravada: parseFloat(x?.totalGravada) ?? 0,
      tipoDocumentoDescripcion: String(x?.tipoDocumentoDescripcion) ?? '',
      tipoDocumentoCodigo: String(x?.tipoDocumento) as TiposComprobantesSLV,
      total: parseFloat(x?.total) ?? 0,
      estadoElectronico: String(x?.estadoElectronico) ?? '',
      retenidoIva: parseFloat(x?.retenidoIva) ?? 0,
      retenidoRenta: parseFloat(x?.retenidoRenta) ?? 0,
      saldo: parseFloat(x?.saldo) ?? 0,
    }
    return item
  })
  return items
}

async function buscarSincronizadosSV(
  range: DateRange | null,
  numero: string,
  identificacion: string,
  tipoDoc: TiposComprobantesSLV | TiposComprobantesSri | null,
  fpago: string,
  claveAcceso: string,
): Promise<Array<SincronizadosListado>> {
  const queryData: any = {}

  if (range) {
    queryData.desde = range.inicio
    queryData.hasta = range.fin
  }

  if (numero && numero.length > 0) {
    queryData.compraNumero = numero
  }

  if (identificacion && identificacion.length > 0) {
    queryData.proveedorIdentificacion = identificacion
  }

  if (tipoDoc && tipoDoc.length > 0) {
    queryData.compraTipoDocumento = tipoDoc
  } else {
    queryData.compraTipoDocumento = -1
  }

  if (fpago && fpago.length > 0) {
    queryData.compraFormaPago = fpago
  }

  if (claveAcceso) {
    queryData.claveAcceso = claveAcceso
  }

  let res = await RequestHelper.get<Array<SincronizadosListado>>(
    'compras',
    'extractElectronics',
    '',
    queryData,
  )

  if (res.length > 0) {
    res = parseDataSincronizados(res)
  }
  return res
}

function parseDataSincronizados(
  apiData: Array<any>,
): Array<SincronizadosListado> {
  const items = apiData.map((x) => {
    const item: SincronizadosListado = {
      claveAcceso: x?.CLAVE_ACCESO ?? '',
      comprobante: x?.COMPROBANTE ?? '',
      fechaAutorizacion: x?.FECHA_AUTORIZACION ?? '',
      fechaEmision: x?.FECHA_EMISION ?? '',
      identificadorReceptor: x?.IDENTIFICACION_RECEPTOR ?? '',
      importeTotal: x?.IMPORTE_TOTAL ?? '',
      razonSocialEmisor: x?.RAZON_SOCIAL_EMISOR ?? '',
      identificacionEmisor: x?.RUC_EMISOR,
      serieComprobante: x?.SERIE_COMPROBANTE ?? '',
      tipoEmision: x?.TIPO_EMISION ?? 0,
      estado: x?.ESTADO ?? '',
      detalle: x?.DETALLE ? JSON.parse(x?.DETALLE) : null,
    }
    return item
  })
  return items
}
