import { ComprasSVListado } from "../types/types";

export const VComprasSVListado: ComprasSVListado = {
  ice: 0,
  iva: 0,
  asientoNumero: '',
  autorizacion: '',
  autorizacionHacienda: null,
  claveAcceso: '',
  claveFecha: '',
  codigo: 0,
  descuento: 0,
  establecimiento: '',
  estadoCodigo: 0,
  estadoDescripcion: '',
  fecha: '',
  fechaAutorizacion: '',
  formaPagoCodigo: 0,
  formaPagoDescripcion: '',
  formaPagoCodigoTributario: '',
  puntoEmision: '',
  localCodigo: 0,
  localNombre: '',
  numero: '',
  observaciones: '',
  proveedorCodigo: 0,
  proveedorDireccion: '',
  proveedorEmail: '',
  identificador: '',
  proveedorIdentificacion: '',
  proveedorTipoIdentificacionCodigo: 0,
  proveedorTipoIdentificacionCodigoTributario: '',
  proveedorNombre: '',
  proveedorRazonComercial: '',
  proveedorTelefono: '',
  puntoVenta: '',
  puntoVentaCodigo: 0,
  subtotalIva: 0,
  subtotal0: 0,
  subtotalNoIva: 0,
  subtotal: 0,
  totalExcenta: 0,
  totalGravada: 0,
  tipoDocumentoDescripcion: '',
  tipoDocumentoCodigo: null,
  total: 0,
  estadoElectronico: '',
  relacionado: '',
  retener: 0,
}