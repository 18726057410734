/* eslint-disable prefer-const */
import { RequestHelper } from '../../../helpers/requestHelper'
import { SesionService } from '../../../services/sesion.service'
import {
  VentaDetalleInfo,
  VentaListado,
  GuiaInfo,
  FormaPagoModuloVentas,
  AutorizacionDocumentoVenta,
  ItemVenta,
  UnidadVenta,
  InfoDocumentosAutorizados,
  ItemventaDatosModificar,
  TipoIva,
  InfoDocumentoVentaAfectar,
  EstadoComprobante,
  EstablecimientoSri,
  PuntoEmisionSri,
  VentaInfo,
  GuiaDatosEdicionDetalle,
  Cliente,
  TAutorizacionDocumentoSV,
  TAutorizacionBloqueResponse,
  FormaPagoMinisterio,
  TAnularDocumentos,
} from '../types/types'
import CustomStore from 'devextreme/data/custom_store'
import DataSource from 'devextreme/data/data_source'
import {
  PagosAsignados,
  TFacturaIngresar,
  TiposPagosVenta,
} from '../pages/ventas/types/types'
import { TiposComprobantesSLV } from '../../../store/types'
import {
  PagedResult,
  PaginationInfo,
  TiposComprobantesSri,
} from '../../../store/types'
import { TNotaDebitoIngresar } from '../pages/notasDebito/types/types'
import { TNotaCreditoIngresar } from '../pages/notasCredito/types/types'
import { TGuiasRemisionIngresar } from '../pages/guiasRemision/types/types'
import { DateUtils, formatoFechasApi } from '../../../helpers/dateUtils'
import { utilidades } from '../../../helpers/utilidades'
import { RequestHelperDotNet } from '../../../helpers/requestHelperDotNet'
import config from '../../../config/config'
import { TOptionGenerico } from '../../clientes/pages/clientes/types/types'
import {
  parseApiItemsLocal,
  parseApiItemsLocalSimple,
} from '../pages/ordenes/pages/ordenPedido/services/ordenPedido.services'
import { Movimiento } from '../../inventario/pages/movimientos/conceptosMovimientos/types/types'
import { LocalOption } from '../../componentes/localesLookUp/store/type'
import {
  EAplicationsAcatha,
  EModulosAcatha,
} from '../../../store/enumsAplication'
import { ConfigFiltros } from '../pages/ordenes/pages/ordenPedido/components/busquedaPedidos/BusquedaItemsSelectBox/BuscarItemPedidoMemoLookUp'
import { consoleService } from '../../../services/console.service'
import { TFiltroAutorizacion } from '../../../containers/component/infoEmergente/verificarAutorizados/verificarAutorizados'

export const VentasService = {
  getVentas,
  getVenta,
  getDetalleVenta,
  getFormasPago,
  getIva,
  getAutorizacion,
  getEstadisticasAutorizacion,
  buscarItem,
  getItemPorCodigoBarras,
  buscarItemCodigoBarras,
  getUnidadesventa,
  modificarItem,
  getTiposIva,
  ingresarVenta,
  obtenerRutasDocumentoElectornico,
  buscarItemCodYDescripcion,
  desactivarItem,
  getGuias,
  getDetallesGuia,
  buscarDocumentosAfectar,
  ingresarNotaCredito,
  ingresarNotaDebito,
  ingresarGuiaRemision,
  getEstadosComprobantes,
  getEstablecimientosSri,
  getEstablecimientosSriWithPuntosEmision,
  getPuntosEmisionSri,
  reenviarDocumento,
  reintentarAutorizacion,
  obtenerPdfFactura,
  getItemsDatasource,
  getItemsGridDatasource,
  anularDocumento,
  getMetodosPago,
  getMetodosPagoAsignados,
  autorizacionBloque,
  getAutorizacionDocumento,
  ingresarVentasPrueba,
  ingresarGuiaRemision_SV,
  getFormasPagoMinisterio,
}

async function getVenta(codigo: number): Promise<VentaInfo | null> {
  const queryData = {
    codigo: codigo,
  }
  const apiData = RequestHelper.get<VentaInfo | null>(
    'ventas',
    'getInvoice',
    '',
    queryData,
  )
  return apiData
}

async function getVentas(
  codigo: number,
  fechaInicio: string,
  fechaFin: string,
  puntoVenta: string,
  numero: string | undefined,
  estadoFactura: string | undefined,
  identificacion: string | undefined,
  autorizado: boolean | undefined,
  clave_acceso: string | undefined,
  formaPago: string | undefined,
  establecimiento: string | undefined,
  puntoEmision: string | undefined,
  tipoDoc: TiposComprobantesSri | TiposComprobantesSLV,
): Promise<Array<VentaListado>> {
  const inicio = fechaInicio //fechaInicio.length > 0 ? moment(fechaInicio, "yyyy-MM-DD").format("DD/MM/yyyy") : "";
  const fin = fechaFin //fechaInicio.length > 0 ? moment(fechaFin, "yyyy-MM-DD").format("DD/MM/yyyy") : "";
  const data: any = {
    clave_acceso: clave_acceso ?? '',
    tipodoc: tipoDoc,
    desde: inicio,
    hasta: fin,
    numero: numero ?? '',
    nombre: '',
    identificacion: identificacion ?? '',
    autorizado: autorizado ?? '',
    pventa: puntoVenta,
    codigo: codigo,
  }

  if (estadoFactura) {
    data.estadoFactura = estadoFactura
  }
  if (formaPago) {
    data.formaPago = formaPago
  }
  if (establecimiento) {
    data.establecimiento = establecimiento
  }
  if (puntoEmision) {
    data.ptoemision = puntoEmision
  }
  if (clave_acceso) {
    data.claveAcceso = clave_acceso
  }

  const ventas = await RequestHelper.get<Array<VentaListado>>(
    'ventas',
    'listar',
    '',
    data,
  )
  return ventas
}

async function getGuias(
  claveAcceso: string,
  fechaInicio: string,
  fechaFin: string,
  activas: boolean,
  numero: string,
  codigo: number = 0,
  tipoDoc: TiposComprobantesSri | TiposComprobantesSLV,
): Promise<Array<GuiaInfo>> {
  consoleService.log('getGuias')

  const queryData: any = {}
  let apiData: Array<any> = []
  if (codigo) {
    queryData.codigo = codigo
    const guia = await RequestHelper.get<Array<GuiaInfo>>(
      'ventas',
      'guiaremisiones/listarPorCodigo',
      '',
      queryData,
    )
    if (guia) apiData.push(guia)
  } else {
    queryData.numero = numero
    queryData.desde = fechaInicio
    queryData.hasta = fechaFin
    queryData.estado = activas ? '1' : '0'
    if (claveAcceso) {
      queryData.claveAcceso = claveAcceso ?? null
    }

    queryData.tipoDoc = tipoDoc ?? ''
    apiData = await RequestHelper.get<Array<GuiaInfo>>(
      'ventas',
      'guiaremisiones/listar',
      '',
      queryData,
    )
  }

  return apiData
}

async function getDetallesGuia(
  codigo: number,
): Promise<Array<GuiaDatosEdicionDetalle>> {
  const queryData = {
    codigo: codigo,
  }
  const apiData = await RequestHelper.get<Array<any>>(
    'ventas',
    'remission_guide/get_details',
    '',
    queryData,
  )
  const detalles: Array<GuiaDatosEdicionDetalle> = []
  for (const detalleApi of apiData) {
    detalles.push({
      cantidadItem: parseFloat(detalleApi.GRD_CANTIDAD),
      codigoBarras: detalleApi.ITE_BARRAS,
      idItem: parseInt(detalleApi.ITE_CODIGO),
      descripcionItem: detalleApi.ITE_DESCRIPCION,
      valor: 0,
      pvp: detalleApi.ITE_PVP,
      pvpiva: detalleApi.ITE_PVPIVA,
      descuento: detalleApi.ITE_DESCUENTOS,
    })
  }
  return detalles
}

async function getDetalleVenta(
  codigo: number,
  tipoDocumento: TiposComprobantesSri | TiposComprobantesSLV,
) {
  const data = {
    codigo: codigo,
    tipodoc: tipoDocumento,
  }
  const apiDetalles = await RequestHelper.get<Array<VentaDetalleInfo>>(
    'ventas',
    'ventas/getDetail',
    '',
    data,
  )
  return apiDetalles
}

async function getFormasPago(
  identificacion: string,
): Promise<Array<FormaPagoModuloVentas>> {
  const data = {
    identificacion: identificacion,
  }

  const cacheKey = 'formasPagoVenta' + JSON.stringify(data)
  const cached = sessionStorage.getItem(cacheKey)
  if (cached) {
    return JSON.parse(cached)
  } else {
    const apiData = await RequestHelper.postUrlEncoded<Array<any>>(
      'formasdepago',
      'venta',
      data,
    )
    const formasPago: Array<FormaPagoModuloVentas> = []
    for (const formaPagoApi of apiData) {
      formasPago.push({
        codigo: parseInt(formaPagoApi.codigo),
        descripcion: formaPagoApi.descripcion,
      })
    }
    sessionStorage.setItem(cacheKey, JSON.stringify(formasPago))
    return formasPago
  }
}

async function getIva(
  fecha: string,
  autorizacion: AutorizacionDocumentoVenta | null,
): Promise<number> {
  const sesionAutorizacionModulo = SesionService.getCurrentModulosAutorizacion()
  const sesion = SesionService.getCurrentSesion()
  let data: any = {
    fecha: fecha, //DateUtils.getCurrentDateAsString(formatoFechasApi)
    paisCodigo: sesion?.empresa?.codigoPais ?? 1,
  }
  if (sesionAutorizacionModulo && sesionAutorizacionModulo.establecimiento) {
    data.establecimiento = sesionAutorizacionModulo.establecimiento
  }
  const cacheKey = 'porcentajeIva' + JSON.stringify(data)
  const cached = sessionStorage.getItem(cacheKey)
  if (cached) {
    return parseFloat(cached)
  } else {
    const detalles = await RequestHelper.get<number>(
      'ventas',
      'ivaEstablecimiento',
      '',
      data,
    )
    sessionStorage.setItem(cacheKey, detalles.toString())
    return detalles
  }
}

async function getAutorizacion(
  puntoVenta: string,
  tipoComprobante: TiposComprobantesSri | TiposComprobantesSLV,
): Promise<AutorizacionDocumentoVenta> {
  const data = {
    comprobante_tipo: tipoComprobante,
    equipo: puntoVenta,
  }
  const d: any = await RequestHelper.get(
    'sidebar',
    'autorizacion/extraer',
    '',
    data,
  )
  const detalles: AutorizacionDocumentoVenta = {
    asociado: d?.asociado ?? '',
    asociadoDes: d?.asociadoDes ?? '',
    asociadoID: parseInt(d?.asociadoID) ?? -1,
    aut: d?.aut ?? '',
    codigoAutorizacion: d?.autorizacionCodigo,
    caducidad: d?.caducidad ?? '',
    codTributarioTipoDeNegocio: parseInt(d?.codTributarioTipoDeNegocio) ?? null,
    codigoImpresora: parseInt(d?.codigoImpresion) ?? null,
    compartida: d?.compartida ?? false,
    copiasImp: d?.copiasImp ?? 0,
    descTipoDeNegocio: d?.descTipoDeNegocio ?? '',
    desde: d?.desde ?? '',
    establecimiento: d?.establecimiento ?? '',
    estado: d?.estado ?? false,
    hasta: d?.hasta ?? '',
    idTipoDeNegocio: d?.idTipoDeNegocio ?? 0,
    imprenta: d?.imprenta ?? '',
    numActual: d?.numActual ?? 0,
    pertenece: d?.pertenece ?? 0,
    perteneceDes: d?.perteneceDes ?? '',
    ptoemision: d?.ptoemision ?? '',
    codigoTIC: d?.tipoComprobanteCodigo ?? '',
    comprobandeTIC: d?.tipoComprobanteNombre ?? '',
    especial: d?.especial ?? '',
    restaurante: d?.restaurante === 1,
  }
  return detalles
}

async function getEstadisticasAutorizacion(
  tipoComprobante: TiposComprobantesSri,
  fechas,
): Promise<InfoDocumentosAutorizados> {
  const data = {
    desde: fechas.desde,
    hasta: fechas.hasta,
    tipodoc: tipoComprobante,
  }
  const apiData = await RequestHelper.get<any>('electronics', 'count', '', data)
  let comp: any = []
  if (apiData.length > 0) {
    comp = apiData.map((x) => {
      let cant = 0
      if (x?.SINAUTORIZAR !== '.................') {
        cant = parseInt(x?.SINAUTORIZAR) ?? 0
      } else {
        cant = 0
      }
      const i: InfoDocumentosAutorizados = {
        comprobante: x.COMPROBANTE,
        totalEmitidos: x.TOTAL,
        autorizados: x.AUTORIZADOS,
        sinAutorizar: cant,
      }
      return i
    })
  }
  return comp
}

function parseApiItemsData(apiresult: Array<any>): Array<ItemVenta> {
  const items = apiresult.map((x) => {
    const item: ItemVenta = {
      codigo: parseInt(x.codigo),
      codigoInterno: x.codigoint,
      codigoBarras: x.barras.toString(),
      descripcion: x.descripcion,
      existencia: parseFloat(x.existencia),
      pvdiva: parseFloat(x.pvdiva),
      pvpiva: parseFloat(x.pvpiva),
      pvd: parseFloat(x.pvd),
      pvp: parseFloat(x.pvp),
      codigoImpuesto: parseInt(x.impuesto),
      tieneIce: x.ice,
      porcentajeIva: x?.porcentajeIva ? parseFloat(x.porcentajeIva) : 0,
      costo: parseFloat(x.costo),
      tipoCodigo: parseInt(x.tipo),
      numeroSerie: parseInt(x.numserie),
      plantilla: parseInt(x.plantilla),
      freeitem: parseInt(x.freeitem),
      gradoAcoholico: parseInt(x.galcoholico),
      capacidad: parseFloat(x.capacidad),
      factor: parseFloat(x.factor),
      jerarquia: x.jerarquia,
      descuentos: parseFloat(x.descuentos),
      titadicional: x.titadicional,
      valadicional: x.valadicional,
      grupo: x.grupo,
      codigoUnidadVenta: parseInt(x.unidadv),
      tipoDescripcion: x.tipo_descripcion,
      codigoGrupo: parseInt(x.codgrupo),
      reservas: parseInt(x.saldoReservas),
      stockMin: parseInt(x.existenciaMin),
      stockMax: parseInt(x.existenciaMax),
    }
    return item
  })
  return items
}

async function getItemPorCodigoBarras(
  codigoBarras: string,
  identificacionCliente: string,
  fecha: string,
): Promise<ItemVenta | undefined> {
  const sesion = SesionService.getCurrentSesion()
  const sesionAutorizacionModulo = SesionService.getCurrentModulosAutorizacion()

  let query: any = {
    local: sesion.local.codigo,
    identificacion: identificacionCliente,
    barras: codigoBarras,
    fecha: fecha,
    exacta: true,
  }
  if (sesionAutorizacionModulo && sesionAutorizacionModulo.establecimiento) {
    query.establecimiento = sesionAutorizacionModulo.establecimiento
  }
  const itemsApi = await RequestHelper.get<Array<any>>(
    'inventario',
    'items/searchItemsLocalSimpleSale',
    '',
    query,
  )
  const items = parseApiItemsData(itemsApi)
  if (items.length > 0) {
    return items[0]
  } else {
    return undefined
  }
}

function getItemsDatasource(
  cliente: Cliente | null,
  fecha: string = DateUtils.getCurrentDateAsString(formatoFechasApi),
) {
  const store = new CustomStore({
    key: 'codigoBarras',
    byKey: async (key) => {
      const item = await buscarItemCodigoBarras(
        key,
        cliente?.identificacion ?? config.rucConsumidorFinal,
        fecha,
      )
      return item.length > 0 ? item[0] : undefined
    },
    load: async (loadOptions) => {
      const paginInfo: PaginationInfo = {
        current: (loadOptions.skip ?? 0) / (loadOptions.take ?? 0) + 1,
        size: loadOptions.take ?? 10,
      }

      let pagedResult: PagedResult<ItemVenta>
      pagedResult = await buscarItemsPaged(
        loadOptions.searchValue ?? '',
        '',
        cliente?.identificacion ?? config.rucConsumidorFinal,
        fecha,
        paginInfo,
      )
      return {
        data: pagedResult.result,
        totalCount: pagedResult.count,
      }
    },
  })
  const source = new DataSource({
    paginate: true,
    pageSize: 10,
    store: store,
    key: 'codigoBarras',
  })
  return source
}

async function buscarItemsPaged(
  descripcion: string,
  codigoBarras: string,
  identificacionCliente: string,
  fecha: string,
  pagination: PaginationInfo,
  modulo: EAplicationsAcatha | EModulosAcatha = EModulosAcatha.Ventas,
  tipoItems: TOptionGenerico | null = null,
  configFiltros: ConfigFiltros | null = null,
  movimiento: Movimiento | null = null,
  codigoPrvAsociado: number | null = null,
  local: LocalOption | undefined = undefined,
): Promise<PagedResult<ItemVenta>> {
  const sesion = SesionService.getCurrentSesion()
  const sesionAutorizacionModulo = SesionService.getCurrentModulosAutorizacion()

  let query: any = {
    local: local !== undefined ? local.codigo : sesion.local.codigo,
    identificacion: identificacionCliente,
    descripcion: descripcion,
    barras: codigoBarras,
    fecha: fecha,
    isPaged: true,
    page: pagination.current,
    limit: pagination.size,
  }
  if (sesionAutorizacionModulo && sesionAutorizacionModulo.establecimiento) {
    query.establecimiento = sesionAutorizacionModulo.establecimiento
  }

  let pagedApiResult: any = null
  let itemsParsed: any = null

  if (modulo === EModulosAcatha.Items) {
    if (tipoItems !== null && tipoItems.value === 3) {
      // kit
      pagedApiResult = await RequestHelper.get<any>(
        'inventario',
        'items/searchItemsKits',
        '',
        query,
      )
      itemsParsed = parseApiItemsData(pagedApiResult.auto)
    } else {
      if (configFiltros?.jerarquia === 1) {
        query.jerarquia = 1
      }
      pagedApiResult = await RequestHelper.get<any>(
        'inventario',
        'items/searchItemsLocal',
        '',
        query,
      )
      itemsParsed = parseApiItemsData(pagedApiResult.auto)
    }
  } else if (
    modulo === EModulosAcatha.Ventas ||
    modulo === EModulosAcatha.Proformas
  ) {
    pagedApiResult = await RequestHelper.get<any>(
      'inventario',
      'items/searchItemsLocalSimpleSale',
      '',
      query,
    )
    itemsParsed = parseApiItemsData(pagedApiResult.auto)
  } else if (modulo === EModulosAcatha.Pedidos) {
    pagedApiResult = await RequestHelper.get<any>(
      'inventario',
      'items/searchItemsLocalSimpleSale',
      '',
      query,
    )
    itemsParsed = parseApiItemsData(pagedApiResult.auto)
  } else if (modulo === EModulosAcatha.Kardex) {
    pagedApiResult = await RequestHelper.get<any>(
      'inventario',
      'items/searchItemsLocal',
      '',
      query,
    )
    itemsParsed = parseApiItemsLocal(pagedApiResult.auto)
  } else if (
    modulo === EModulosAcatha.MovimientosIngresosEgresos ||
    modulo === EModulosAcatha.MovimientosImportaciones ||
    modulo === EModulosAcatha.MovimientosInventarioInicial
  ) {
    if (movimiento?.asociado === 56) query.proveedor = codigoPrvAsociado
    pagedApiResult = await RequestHelper.get<any>(
      'inventario',
      'items/searchItemsLocal',
      '',
      query,
    )
    itemsParsed = parseApiItemsLocal(pagedApiResult.auto)
  } else if (modulo === EAplicationsAcatha.Grupos) {
    query.grupoDescripcion = descripcion
    query.local = -1
    query.identificacion = ''
    query.barras = ''
    query.fecha = ''
    query.descripcion = ''
    pagedApiResult = await RequestHelper.get<any>(
      'inventario',
      'items/searchItemsLocal',
      '',
      query,
    )
    itemsParsed = parseApiItemsLocal(pagedApiResult.auto)
  } else if (modulo === EModulosAcatha.Transferencias) {
    pagedApiResult = await RequestHelper.get<any>(
      'inventario',
      'items/searchItemsLocalCorte',
      '',
      query,
    )
    itemsParsed = parseApiItemsLocalSimple(pagedApiResult.auto)
  } else if (modulo === EModulosAcatha.IngresoBloque) {
    pagedApiResult = await RequestHelper.get<any>(
      'inventario',
      'items/searchItemsLocalSimple',
      '',
      query,
    ) // revisar ek parse correcto para este API
    itemsParsed = parseApiItemsLocalSimple(pagedApiResult.auto)
  }

  return {
    currentPage: parseInt(pagedApiResult.paginaActual),
    from: parseInt(pagedApiResult.desde),
    to: parseInt(pagedApiResult.hasta),
    count: parseInt(pagedApiResult.totalRegistros),
    result: itemsParsed,
  }
}

async function buscarItem(
  descripcion: string,
  codigoBarras: string,
  identificacionCliente: string,
  fecha: string,
): Promise<Array<ItemVenta>> {
  const sesion = SesionService.getCurrentSesion()
  const sesionAutorizacionModulo = SesionService.getCurrentModulosAutorizacion()
  let query: any = {
    local: sesion.local.codigo,
    identificacion: identificacionCliente,
    descripcion: descripcion,
    barras: codigoBarras,
    fecha: fecha,
  }
  if (sesionAutorizacionModulo && sesionAutorizacionModulo.establecimiento) {
    query.establecimiento = sesionAutorizacionModulo.establecimiento
  }
  const itemsApi = await RequestHelper.get<Array<any>>(
    'inventario',
    'items/searchItemsLocalSimpleSale',
    '',
    query,
  )
  const items = parseApiItemsData(itemsApi)
  return items
}

async function buscarItemCodigoBarras(
  codigoBarras: string,
  identificacionCliente: string,
  fecha: string,
): Promise<Array<ItemVenta>> {
  const sesion = SesionService.getCurrentSesion()
  const sesionAutorizacionModulo = SesionService.getCurrentModulosAutorizacion()

  let query: any = {
    local: sesion.local.codigo,
    identificacion: identificacionCliente,
    barras: codigoBarras,
    fecha: fecha,
    exacta: false,
  }
  if (sesionAutorizacionModulo && sesionAutorizacionModulo.establecimiento) {
    query.establecimiento = sesionAutorizacionModulo.establecimiento
  }
  const itemsApi = await RequestHelper.get<Array<any>>(
    'inventario',
    'items/searchItemsLocalSimpleSale',
    '',
    query,
  )
  const items = parseApiItemsData(itemsApi)
  return items
}

async function buscarItemCodYDescripcion(
  codigoBarras: string,
  descripcion: string,
  identificacionCliente: string,
  fecha: string,
): Promise<Array<ItemVenta>> {
  const sesion = SesionService.getCurrentSesion()
  const sesionAutorizacionModulo = SesionService.getCurrentModulosAutorizacion()

  let query: any = {
    local: sesion.local.codigo,
    identificacion: identificacionCliente,
    barras: codigoBarras,
    descripcion: descripcion,
    fecha: fecha,
    exacta: false,
  }
  if (sesionAutorizacionModulo && sesionAutorizacionModulo.establecimiento) {
    query.establecimiento = sesionAutorizacionModulo.establecimiento
  }
  const itemsApi = await RequestHelper.get<Array<any>>(
    'inventario',
    'items/searchItemsLocalSimpleSale',
    '',
    query,
  )
  const items = itemsApi.map((x) => {
    const item: ItemVenta = {
      codigo: parseInt(x.codigo),
      codigoInterno: x.codigoint,
      codigoBarras: x.barras,
      descripcion: x.descripcion,
      existencia: parseFloat(x.existencia),
      pvdiva: parseFloat(x.pvdiva),
      pvpiva: parseFloat(x.pvpiva),
      pvd: parseFloat(x.pvd),
      pvp: parseFloat(x.pvp),
      codigoImpuesto: parseInt(x.impuesto),
      tieneIce: x.ice === '-1',
      porcentajeIva: parseFloat(x.porcentajeIva),
      costo: parseFloat(x.costo),
      tipoCodigo: parseInt(x.tipo),
      numeroSerie: parseInt(x.numserie),
      plantilla: parseInt(x.plantilla),
      freeitem: parseInt(x.freeitem),
      gradoAcoholico: parseInt(x.galcoholico),
      capacidad: parseFloat(x.capacidad),
      factor: parseFloat(x.factor),
      jerarquia: x.jerarquia,
      descuentos: parseFloat(x.descuentos),
      titadicional: x.titadicional,
      valadicional: x.valadicional,
      grupo: x.grupo,
      codigoUnidadVenta: parseInt(x.unidadv),
      tipoDescripcion: x.tipo_descripcion,
      codigoGrupo: parseInt(x.codgrupo),
      reservas: parseInt(x.reservas),
      stockMin: parseInt(x.stockMin),
      stockMax: parseInt(x.stockMax),
    }
    return item
  })
  return items
}

async function getUnidadesventa(): Promise<Array<UnidadVenta>> {
  const queryData = {
    estado: 1,
  }
  const cacheKey = 'unidadesVenta' + JSON.stringify(queryData)
  const savedData = sessionStorage.getItem(cacheKey) ?? null
  if (savedData) {
    return JSON.parse(savedData)
  }
  const apiData = await RequestHelper.get<Array<UnidadVenta>>(
    'inventario',
    'unidades/listar',
    '',
    queryData,
  )

  sessionStorage.setItem(cacheKey, JSON.stringify(apiData))
  return apiData
}

async function modificarItem(
  item: ItemventaDatosModificar,
): Promise<ItemVenta> {
  const sesion = SesionService.getCurrentSesion()
  const data: any = {
    local: Number(sesion.local.codigo),
    barras: item.codigoBarras,
    nombre: item.descripcion,
    unidadv: Number(item.codigoUnidadVenta),
    linea: item.codigoLinea,
    grupo: Number(item.codigoGrupo),
    marca: item.codigoMarca,
    pvp: Number(item.pvp),
    pvd: Number(item.pvd),
    // iva: item.codigoImpuesto === 1 ? "NO SUJETO" : (item.codigoImpuesto === 2 ? 'NO' : "SI"),
    // iva: item.codigoImpuesto === 3 ? 'SI' : 'NO',
    iva: item.codigoImpuesto,
    tipo: item.tipoCodigo,
    codigo: item.codigo,
    tipoIce: item.iceTipoIce,
    codGradoAlcohol: item.iceCodigoGradoAlcoholico,
    gradoAlcolico: item.iceGradoAlcoholico,
    capacidadCmCubicos: item.iceCapacidaCm3,
    codigoImpuesto: item.iceCodigoImpuesto,
    codigoClasificacion: item.iceCodigoClasificacion,
    codigoMarca: item.iceCodigoMarca,
    estado: item.iceEstado,
    codigoPresentacion: item.iceCodigoPresentacion,
    codigoUnidad: item.iceCodigoUnidad,
    gramosAzucar: item.iceGramosAzucar,
    ice: item.ice,
  }
  if (item?.codigoUsu) {
    data.codusu = item.codigoUsu
  }
  const apiData = await RequestHelper.post<any>(
    'inventario',
    'items/save',
    data,
  )
  return apiData
}

async function getTiposIva(): Promise<Array<TipoIva>> {
  const queryData = {}
  const apiData = await RequestHelper.get<Array<any>>(
    'iva',
    'listar',
    '',
    queryData,
  )
  const tiposIva = apiData
    .filter((x) => x.IVA_TIPO !== -1)
    .map((x) => {
      const tipoIva: TipoIva = {
        codigo: x.IVA_TIPO,
        descripcion: x.IVA_DESCRIPCION,
        valor: parseFloat(x.IVA_VALOR),
      }
      return tipoIva
    })
  return tiposIva
}

async function ingresarVenta(venta: TFacturaIngresar): Promise<Promise<any>> {
  // VentaListado
  try {
    const creada = await RequestHelper.postAll<any>('ventas', 'ingresar', venta)
    return creada
  } catch (error) {
    return error
  }
}

async function ingresarVentasPrueba(data: any): Promise<any> {
  try {
    const creada = await RequestHelper.postAll<any>(
      'integraciones',
      'sv/generateDocs',
      data,
    )
    return creada
  } catch (error) {
    return error
  }
}

async function ingresarNotaCredito(
  notaCredito: TNotaCreditoIngresar,
): Promise<VentaListado> {
  const creada = await RequestHelper.post<VentaListado>(
    'ventas',
    'notacredito/ingresar',
    notaCredito,
  )
  return creada
}

async function ingresarNotaDebito(
  notaDebito: TNotaDebitoIngresar,
): Promise<VentaListado> {
  const creada = await RequestHelper.post<VentaListado>(
    'ventas',
    'notadebito/ingresar',
    notaDebito,
  )
  return creada
}

async function ingresarGuiaRemision(
  guia: TGuiasRemisionIngresar,
): Promise<GuiaInfo> {
  const creada = await RequestHelper.post<GuiaInfo>(
    'ventas',
    'remission_guide/insert',
    guia,
  )
  return creada
}

async function ingresarGuiaRemision_SV(
  guia: TGuiasRemisionIngresar,
): Promise<Promise<any>> {
  try {
    const creada = await RequestHelper.postAll<any>(
      'ventas',
      'remission_guide/insert',
      guia,
    )
    return creada
  } catch (error) {
    return error
  }
}

async function obtenerPdfFactura(claveAcceso: string): Promise<Blob> {
  try {
    const queryData = {
      claveacceso: claveAcceso,
    }
    const blob = await RequestHelper.getFile(
      'ventas',
      'obtenerRide',
      '',
      queryData,
      { type: 'application/pdf' },
    )
    return blob
  } catch (error) {
    throw error
  }
}

async function obtenerRutasDocumentoElectornico(
  claveAcceso: string,
): Promise<any> {
  // { pdf: string; xml: string; url: string }
  const queryData = {
    claveacceso: claveAcceso,
  }
  const apiData = await RequestHelper.getAll<any>(
    'ventas',
    'impresion',
    '',
    queryData,
  )
  // const pathapiPdf = `${apiData.pdf}`
  // const pathapiXml = `${apiData.xml}`
  // const pathapiPrint = `${apiData.url}`
  // const path = {
  //   pdf: pathapiPdf,
  //   xml: pathapiXml,
  //   url: pathapiPrint,
  // }
  //const blob = await Axios.get(path, { headers } )
  // return path
  return apiData
}

async function desactivarItem(item: ItemVenta | number): Promise<boolean> {
  const queryData = {
    codigo: typeof item === 'number' ? item : item.codigo,
  }
  const deleteResult = await RequestHelper.deleteRequest<boolean>(
    'inventario',
    'items/inactive',
    '',
    queryData,
  )
  return deleteResult
}

async function buscarDocumentosAfectar(
  identificacion: string,
  nombres: string,
  comprobante: string,
  tipo: TiposComprobantesSri | TiposComprobantesSLV,
): Promise<Array<InfoDocumentoVentaAfectar>> {
  const queryData = {
    id: identificacion,
    numero: comprobante,
    tipo: tipo,
  }
  const apiData = await RequestHelper.get<Array<any>>(
    'ventas',
    'comprobante/modificar',
    '',
    queryData,
  )

  const result: Array<InfoDocumentoVentaAfectar> = []
  for (const ventaApi of apiData) {
    const _venta: InfoDocumentoVentaAfectar = {
      codigo: parseInt(ventaApi.CODIGO),
      tipoComprobante: ventaApi.TIC_CODIGO as TiposComprobantesSri,
      tipoComprobanteNombre: ventaApi.TIC_COMPROBANTE,
      numero: ventaApi.NUMERO,
      clienteCodigo: parseInt(ventaApi.CLI_CODIGO),
      clienteIdentificacion: ventaApi.CLI_IDENTIFICACION,
      clienteNombre: ventaApi.CLI_NOMBRE,
      clienteDireccion: ventaApi.CLI_DIRECCION,
      clienteTelefono: ventaApi.CLI_TELEFONO,
      asociado: ventaApi.ASOCIADO,
      clienteEmail: ventaApi.CLI_EMAIL,
      subtotal0: parseFloat(ventaApi.VEN_SUBTOTAL0),
      subtotal12: parseFloat(ventaApi.VEN_SUBTOTAL12),
      subtotalNoIva: parseFloat(ventaApi.VEN_SUBTOTALNOIVA),
      descuento: parseFloat(ventaApi.VEN_DESCUENTO),
      descuentoGlobal: parseFloat(ventaApi.VEN_DESCUENTOG),
      iva: parseFloat(ventaApi.VEN_IVA),
      total: parseFloat(ventaApi.VEN_TOTAL),
      fecha: DateUtils.strDateToDate(ventaApi.VEN_FECHA, formatoFechasApi),
      formaPagoCodigo: ventaApi.FPAGO,
      formaPagoDescripcion: ventaApi.FPAGO_DES,
      puntoVenta: ventaApi.PTO_HOST,
      autorizacionExtras: ventaApi.CLA_EXTRAS,
      retencionIva: ventaApi.retencionIva,
      retencionRenta: ventaApi.retencionRenta,
    }
    result.push(_venta)
  }
  return result
}

async function getEstadosComprobantes(): Promise<Array<EstadoComprobante>> {
  const sesion = SesionService.getCurrentSesion()
  const keysToCache = {
    dateKey: DateUtils.getCurrentDateAsString(formatoFechasApi),
  }
  const data: any = {
    mensaje: '',
  }
  data.paisCodigo = sesion.empresa?.codigoPais ?? 1
  const cacheKey = 'receiptStatus' + JSON.stringify(keysToCache)
  const cachedData = sessionStorage.getItem(cacheKey)
  if (cachedData) return JSON.parse(cachedData)
  else {
    if (utilidades.canUseNetApi()) {
      const apiData = await RequestHelperDotNet.get<Array<EstadoComprobante>>(
        'EntityStatus',
        'All',
        '',
        null,
      )
      return apiData
    } else {
      const apiData = await RequestHelper.get<EstadoComprobante[]>(
        'ventas',
        'comprobanteestado/listar',
        '',
        data,
      )
      const estados: EstadoComprobante[] = apiData.map((estado) => {
        return {
          codigo: estado.codigo,
          numero: estado.numero,
          descripcion: estado.descripcion,
        }
      })
      sessionStorage.setItem(cacheKey, JSON.stringify(estados))
      return estados
    }
  }
}

async function getEstablecimientosSriWithPuntosEmision(): Promise<
  Array<EstablecimientoSri>
> {
  const data = await RequestHelperDotNet.get<Array<EstablecimientoSri>>(
    'SalesPoint',
    'EstablecimientoSri',
    '',
    null,
  )
  return data
}

async function getEstablecimientosSri(): Promise<Array<EstablecimientoSri>> {
  const sesion = SesionService.getCurrentSesion()
  const keysToCache = {
    dateKey: DateUtils.getCurrentDateAsString(formatoFechasApi),
  }
  const data: any = {
    mensaje: '',
  }
  data.paisCodigo = sesion.empresa?.codigoPais ?? 1
  const cacheKey = 'establishmentsOptions' + JSON.stringify(keysToCache)
  const cachedData = sessionStorage.getItem(cacheKey)
  if (cachedData) return JSON.parse(cachedData)
  else {
    const apiData = await RequestHelper.get<Array<string>>(
      'ventas',
      'sri/establishments/getAll',
      '',
      null,
    )
    const establecimientos = apiData.slice(1).map((x) => {
      return { establecimiento: x, puntosEmision: [] }
    })
    sessionStorage.setItem(cacheKey, JSON.stringify(establecimientos))
    return establecimientos
  }
}

async function getPuntosEmisionSri(): Promise<Array<PuntoEmisionSri>> {
  const sesion = SesionService.getCurrentSesion()
  const keysToCache = {
    dateKey: DateUtils.getCurrentDateAsString(formatoFechasApi),
  }
  const data: any = {
    mensaje: '',
  }
  data.paisCodigo = sesion.empresa?.codigoPais ?? 1
  const cacheKey = 'emissionPointOptions' + JSON.stringify(keysToCache)
  const cachedData = sessionStorage.getItem(cacheKey)
  if (cachedData) return JSON.parse(cachedData)
  else {
    const apiData = await RequestHelper.get<Array<string>>(
      'ventas',
      'sri/emissionPoint/getAll',
      '',
      null,
    )
    const puntosEmision = apiData.slice(1).map((x) => {
      return { puntoEmision: x, establecimiento: null }
    })
    sessionStorage.setItem(cacheKey, JSON.stringify(puntosEmision))
    return puntosEmision
  }
}

async function reenviarDocumento(claveAcceso: string) {
  const sesion = SesionService.getCurrentSesion()
  const queryData = {
    dirArchivo: sesion.empresa.pathArchivosPaginaWeb,
    claveAcceso: claveAcceso,
    emp_name: sesion.empresa.comercial,
    pais: sesion?.local?.ciudad?.paisCodigo,
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const apiData = await RequestHelper.get<Array<any>>(
    'ventas',
    'voucher/send',
    '',
    queryData,
  )
  return true
}

async function reintentarAutorizacion(
  claveAcceso: string,
  tipoComprobante: TiposComprobantesSri,
) {
  const sesion = SesionService.getCurrentSesion()
  const queryData = {
    local: sesion.local.codigo,
    claveAcceso,
    tipoDocumento: tipoComprobante,
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const apiData = await RequestHelper.get<Array<any>>(
    'generales',
    'xml/getWS',
    '',
    queryData,
  )
  return true
}

function getItemsGridDatasource(
  cliente: Cliente | null,
  fecha: string = DateUtils.getCurrentDateAsString(formatoFechasApi),
  findItem,
  modulo: EAplicationsAcatha | EModulosAcatha = EModulosAcatha.Ventas,
  tipoItems: TOptionGenerico,
  configFiltros: ConfigFiltros = null,
  movimiento: Movimiento,
  codigoPrvAsociado: number,
  local?: LocalOption,
) {
  const store = new CustomStore({
    key: 'codigoBarras',
    byKey: async (key) => {
      const item = await buscarItemCodigoBarras(
        key,
        cliente?.identificacion ?? config.rucConsumidorFinal,
        fecha,
      )
      return item.length > 0 ? item[0] : undefined
    },
    load: async (loadOptions) => {
      const paginInfo: PaginationInfo = {
        current: (loadOptions.skip ?? 0) / (loadOptions.take ?? 0) + 1,
        size: loadOptions.take ?? 10,
      }
      let pagedResult: PagedResult<ItemVenta>

      pagedResult = await buscarItemsPaged(
        findItem['descripcion'],
        findItem['codigoBarras'],
        cliente?.identificacion ?? config.rucConsumidorFinal,
        fecha,
        paginInfo,
        modulo,
        tipoItems,
        configFiltros,
        movimiento,
        codigoPrvAsociado,
        local,
      )

      return {
        data: pagedResult.result,
        totalCount: pagedResult.count,
      }
    },
  })
  const source = new DataSource({
    totalCount: store.totalCount,
    paginate: true,
    pageSize: 10,
    store: store,
    key: 'codigoBarras',
  })
  return source
}

async function anularDocumento(info: VentaListado): Promise<boolean> {
  const infoRegistro: TAnularDocumentos = {
    codigo: info.comprobante,
    fecha: info.fecha,
    tipodoc: info.tipoDocumentoCodigo,
    fechan: info.fechaNegociacion,
  }
  if (info?.relacionado !== '' && info?.relacionadoCodigoDocumento !== null) {
    infoRegistro.relacionado = info.relacionadoCodigoDocumento
  }
  const postData = {
    infoRegistro: {
      ...infoRegistro,
    },
  }
  const result = await RequestHelper.deleteRequest<boolean>(
    'ventas',
    'ventas/cancel',
    '',
    undefined,
    postData,
  )
  //console.log(result);
  //await utilidades.sleep(2000);
  return result
}

// return all methods pay for modal
async function getMetodosPago(
  fechaFactura: string,
): Promise<Array<TiposPagosVenta>> {
  const data = {
    grupo: 3,
    all: true,
    fechaVenta: fechaFactura,
  }

  const cacheKey = 'metodosPagoVenta' + JSON.stringify(data)
  const cached = sessionStorage.getItem(cacheKey)
  if (cached) {
    return JSON.parse(cached)
  } else {
    const apiData = await RequestHelper.get<Array<any>>(
      'generales/subtypes',
      'cboxLoadFP',
      '',
      data,
    )
    const metodosPago: Array<TiposPagosVenta> = []
    for (const formaPagoApi of apiData) {
      metodosPago.push({
        codigoFormaPago: parseInt(formaPagoApi.codigo),
        descripcionFormaPago: formaPagoApi.descripcion,
        valor: 0,
        asignado: formaPagoApi.asignado,
      })
    }
    sessionStorage.setItem(cacheKey, JSON.stringify(metodosPago))
    return metodosPago
  }
}

async function getMetodosPagoAsignados(
  codVenta: number,
): Promise<Array<PagosAsignados>> {
  const data = {
    venta: codVenta,
  }

  const apiData = await RequestHelper.get<Array<any>>(
    'generales/formasPago',
    'v/getAll',
    '',
    data,
  )
  const metodosAsignados: Array<PagosAsignados> = []
  for (const forma of apiData) {
    metodosAsignados.push({
      codigo: parseInt(forma.codigo),
      ventaCodigo: forma.ventaCodigo,
      ventaFecha: forma.ventaFecha,
      ventaNumero: forma.ventaNumero,
      clienteIdentificacion: forma.clienteIdentificacion,
      clienteNombre: forma.clienteNombre,
      subCodigo: parseInt(forma.subCodigo),
      tipCodigo: parseInt(forma.tipCodigo),
      subValor: forma.subValor,
      valor: forma.valor,
    })
  }
  return metodosAsignados
}

async function autorizacionBloque(
  fl: TFiltroAutorizacion,
): Promise<TAutorizacionBloqueResponse> {
  try {
    const sesion = SesionService.getCurrentSesion()
    const queryData: any = {
      desde: fl.desde,
      hasta: fl.hasta,
      paisCodigo: sesion?.local?.ciudad?.paisCodigo,
    }
    if (fl?.paisCodigo) {
      queryData.paisCodigo = fl?.paisCodigo ?? 1
    }
    if (fl?.id) {
      queryData.id = fl?.id ?? 1
    }

    if (fl?.tipoDocumento) {
      queryData.tipoDocumento = fl?.tipoDocumento ?? '01'
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const apiData = await RequestHelper.postAll<any>(
      'admin/comprobantesElectronicos',
      'autorizarElectronicos',
      queryData,
    )
    return apiData
  } catch (error) {
    return error
  }
}

async function getAutorizacionDocumento(
  codigoDocumento: number,
): Promise<TAutorizacionDocumentoSV> {
  const data = { codigoDocumento: codigoDocumento }

  const apiData: any = await RequestHelper.get(
    'generales',
    'voucher/extras',
    '',
    data,
  )

  const aut: TAutorizacionDocumentoSV = {
    estado: apiData?.estado ?? '',
    version: apiData?.version ?? '',
    ambiente: apiData?.ambiente ?? '',
    codigoMensaje: apiData?.codigoMensaje ?? '',
    versionApp: apiData?.versionApp ?? '',
    clasificacionMensaje: apiData?.clasificacionMensaje ?? '',
    selloRecibido: apiData?.selloRecibido ?? '',
    descripcionMensaje: apiData?.descripcionMensaje ?? '',
    fechaProcesamiento: apiData?.fechaProcesamiento ?? '',
    tipoComprobante: apiData?.tipoComprobante ?? '',
    codigoGeneracion: apiData?.codigoGeneracion ?? '',
    claveAcceso: apiData?.claveAcceso ?? '',
    codigo: apiData?.codigo ?? '',
  }
  return aut
}

async function getFormasPagoMinisterio(): Promise<Array<FormaPagoMinisterio>> {
  const sesion = SesionService.getCurrentSesion()

  const data = {
    paisCodigo: sesion?.empresa?.codigoPais ?? 1,
  }
  const cacheKey = 'formasPagoVentaMinisterio' + JSON.stringify(data)
  const cached = sessionStorage.getItem(cacheKey)
  if (cached) {
    return JSON.parse(cached)
  } else {
    const apiData = await RequestHelper.getAll<any>(
      'generales/paymentForms',
      'getCboxSRI',
      '',
      data,
    )

    const formasPago: Array<FormaPagoMinisterio> = []
    if (apiData?.auto) {
      for (const formaPagoApi of apiData.auto) {
        const relacionadas = []
        if (formaPagoApi.relacionadas && formaPagoApi.relacionadas.length > 0) {
          for (const formaPagoRelacionada of formaPagoApi.relacionadas) {
            relacionadas.push({
              formaPagoCodigo: parseInt(formaPagoRelacionada.formaPagoCodigo),
              formaPagoDescripcion: formaPagoRelacionada.formaPagoDescripcion,
              formaPagoSRICodigo: formaPagoRelacionada.formaPagoSRICodigo,
            })
          }
        }
        formasPago.push({
          codigo: formaPagoApi.formaPagoSRICodigo,
          descripcion: formaPagoApi.formaPagoSRIDescripcion,
          estado: formaPagoApi.formaPagoSRIEstado,
          formaPagoCodigo: parseInt(formaPagoApi.formaPagoCodigo),
          relacionadas: relacionadas ?? [],
        })
      }
    }
    sessionStorage.setItem(cacheKey, JSON.stringify(formasPago))
    return formasPago
  }
}
