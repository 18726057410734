import { combineReducers } from '@reduxjs/toolkit'
import { ConfiguracionesState } from './types'
import { ConfigEmpresaReducer } from '../empresa/store/empresaReducers'
import { ConfigLocalReducer } from '../local/store/localReducers'
import { ConfigUsuariosReducer } from '../usuario/store/usuarioReducers'

export const ConfiguracionesReducer = combineReducers<ConfiguracionesState>({
  empresa: ConfigEmpresaReducer,
  usuarios: ConfigUsuariosReducer,
  locales: ConfigLocalReducer,
})
