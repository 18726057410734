/* eslint-disable no-unused-vars */
import { TabsState } from '../../../../../../store/genericTabTypes'
import {
  CustomDictionary,
  FETCH_STATUS,
  LoaderInfo,
} from '../../../../../../store/types'
import { LocalOption } from '../../../../../componentes/localesLookUp/store/type'
import { ProgressStatus } from '../../../../../componentes/progresoEstadoLookUp/types/types'
import { Project } from '../../../../../shared/types/types'
import { ItemPedido } from '../../../../../ventas/pages/ordenes/pages/ordenPedido/type/types'

export enum Progreso {
  INGRESADO = 50,
  ENVIADO = 51,
  RECIBIDO = 52,
  NO_ENVIADO = 53,
  NO_RECIBIDO = 54,
}

export type GeneralesState = {
  modulo: string
  currentAction: string
  currentFunction: string
  aplicacion: string
  loader: LoaderInfo
  isChecked: boolean
  collapse: boolean
}

export type TransferEditData = {
  codigo: number
  numero: number
  fecha: string
  descripcion: string
  progreso: Progreso
  destinoProyecto: Project | Pick<Project, 'codigo' | 'locales'>
  destinoLocal: LocalOption
  origenProyecto: Project | Pick<Project, 'codigo' | 'locales'>
  origenLocal: LocalOption
  responsable: Usuario | Pick<Usuario, 'codigo' | 'nombres' | 'apellidos'>
  item: ItemPedido
  compra: number
  movimiento: number
  existencia: number
  cantidad: number
  unidad: string
  referencia: number
  ordenAsociada: number
  detalles: ItemTransferencia[]
  series: SeriesItem[]
  lotes: LotesItem[]
  guardado: boolean
  loaded: boolean
  selectedAll?: boolean
  horaInicio?: string
  horaFin?: string
  loader: LoaderInfo
  backup: TransferEditData
}

export type TransferenciasState = {
  general: GeneralesState
  tabs: TabsState<TransferEditData>
  editData: CustomDictionary<TransferEditData>
  search: BusquedaState<TransfersListAll>
}

export type BusquedaState<TransfersListAll> = {
  filter: SearchFilter
  status: FETCH_STATUS
  mensaje: string
  resultsHeader: Array<TransfersListAll>
  resultsDetails: Array<TransfersListDetails>
  tabs: Array<string>
  currentTab: string
  selectedTransfer: TransfersListAll
  loader: LoaderInfo
}

export type SearchFilter = {
  fechaDesde: string
  fechaHasta: string
  codigo: string
  numero: number
  origen: LocalOption
  destino: LocalOption
  activos: boolean
  archivados: boolean
  item: string
  movimiento: string
  progreso: ProgressStatus
  observaciones: string
}

export interface TransfersListAll {
  codigo: number
  numero: string
  fecha: string
  descripcion: string
  localOrigen: number
  localOrigenNombre: string
  localDestino: number
  localDestinoNombre: string
  progreso: number
  progresoDescripcion: string
  estado: number
  estadoDescripcion: string
  usuarioCodigo: number
  usuarioCedula: string
  usuarioNombres: string
  ordenTrabajoCodigo: number
  ordenTrabajoNumero: string
  archivado: string
  horaInicio: string
  horaFin: string
  responsable: number
  responsableNombre: string
  usuarioCompleto: string
  movimientoCabeceraCodigo: number
}

export interface TransfersListDetails {
  transferenciaDetalleCodigo: number
  codigo: number
  numero: string
  fecha: string
  descripcion: string
  localOrigen: number
  localOrigenNombre: string
  localDestino: number
  localDestinoNombre: string
  itemCodigo: number
  itemCodigoUsuario: string
  itemBarras: string
  itemDescripcion: string
  itemUnidadDescripcion: string
  transferenciaDetalleCantidad: number
  transferenciaDetalleReferencia: number
  transferenciaDetalleProgreso: string
  transferenciaDetallePrecioUnitario: number
  transferenciaDetalleValor: number
  cuentaContableCompra: string
  cuentaContableCosto: string
}

export interface SearchFilters {
  codigo: string
  numero: string
  barras: string
  descripcion: string
  fechaDesde: string
  fechaHasta: string
  origen: number
  destino: number
  progreso: number
  estado: number
  archivado: number
  movimientoCabeceraCodigo: string
}

export type SearchUsers = {
  cedula: string
  nombres: string
  apellidos: string
  empresaCodigo: number
}

export interface Usuario {
  codigo: number
  nombres: string
  apellidos: string
  nombre: string
  idsesion: string
  cedula: string
  fcreacion: string
  fnacimiento: string
  direccion: string
  numero: string
  telefono: string
  email: string
  tipo: string
  ciuCodigo: number
  ciuNombre: string
  ciuDepCodigo: number
  proCodigo: number
  proNombre: string
  proRDepCodigo: number
  ideCodigo: string
  pase: string
  genero: number
  ecivil: number
  foto: string
  estado: number
  tipoSangre: string
  codigoIess: number
  ciuOrigen: number
  paiCodigo: number
  paiNombre: string
  cursos: string
  host: string
}

export type ItemTransferencia = {
  codigoTrd: number
  codigo: number
  codigoUsuario: string
  barras: string
  descripcion: string
  unidadDescripcion: string
  cantidad: number
  referencia: number
  existencia: number
  numSerie: number
  lotes: number
  pvd: number
  pvp: number
  fraccion: number
  progreso: number
  enviado?: boolean
  series?: any[]
  lotesItem?: any[]
  codigoImp?: number
  costo?: number
  progresoDes?: string
}

export interface DetailItem {
  codigo: number
  cantidad: number
  pvp: number
  pvd: number
  referencia: number
  progreso: number
  codigoTrd: number
}

export interface SaveTransfer {
  infoRegistro: {
    codigo: number
    numero: string
    descripcion: string
    responsable: number
    fecha: string
    origen: number
    destino: number
    progreso: number
    movimientoCabeceraCodigo: number
    detalle: DetailItem[]
    series: SeriesItem[]
    lotes: LotesItem[]
  }
}

export interface ShipmmentTransfer {
  codigo: number
  fecha: string
  descripcion: string
  origenLocal: Pick<LocalOption, 'codigo'>
  destinoLocal: Pick<LocalOption, 'codigo'>
  progreso: number
  detalles: ItemTransferencia[]
  series: SeriesItem[]
  selectedAll: boolean
  loaded: boolean
}

export interface SeriesItem {
  itemCodigo: number
  itemBarras: string
  itemDescripcion: string
  itemSerieSerie: string
  itemSerieMin: string
  itemSerieTipo?: string
  itemSerieAsociado?: number
}

export type LotesItem = {
  codigo: number
  codigoItem: number
  numero: string
  barras: string
  existencia: number
  caducidad: string
  ubicado: string
  cantidad: number
}
