import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FETCH_STATUS } from '../../../../../store/types'
import {
  TipoClienteDetail,
  FiltrosTipoClienteState,
  SearchState,
} from '../types/types'
import { tiposClientesServices } from '../services/tiposClientes.services'

const initialState: SearchState<TipoClienteDetail> = {
  filter: {
    descripcion: '',
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  resultados: [],
}

export const fetchTypesCustomer = createAsyncThunk<
  Array<TipoClienteDetail>,
  FiltrosTipoClienteState
>('clientes/tipos-de-clientes/listar', async (filtro) => {
  try {
    const performanceList = tiposClientesServices.getTypesCustomers(
      filtro.descripcion,
    )
    return performanceList
  } catch (error) {
    return Promise.reject(error)
  }
})

const searchSlice = createSlice({
  name: 'searchTypeCustomer',
  initialState: initialState,
  reducers: {
    setFilter(state, action: PayloadAction<FiltrosTipoClienteState>) {
      state.filter.descripcion = action.payload.descripcion
    },
    setCleanResult(state) {
      state.resultados = []
    },
    changeFilter(state, action: PayloadAction<FiltrosTipoClienteState>) {
      state.filter = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTypesCustomer.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchTypesCustomer.fulfilled, (state, { payload }) => {
      state.resultados = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchTypesCustomer.rejected, (state, { payload }) => {
      state.resultados = []
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
  },
})

export const { setFilter, changeFilter, setCleanResult } = searchSlice.actions

export const searchReducer = searchSlice.reducer
