/* eslint-disable no-unused-vars */
export enum KeyPro {
  cod = 'PRO_CODIGO',
  name = 'PRO_NOMBRE',
  locs = 'LOCALES',
}

export enum KeyLoc {
  cod = 'localCodigo',
  cityCod = 'localCiudad',
  cityName = 'localCiudadNombre',
  name = 'localNombre',
  address = 'localDireccion',
  numberPhone = 'localTelefono',
  ger = 'localGerente',
  typeCod = 'localTipo',
  typeDesc = 'localTipoDescripcion',
  class = 'localClase',
  classDes = 'localClaseDescripcion',
  owner = 'localPertenece',
  ownerDes = 'localPerteneceDescripcion',
  stateCod = 'localEstado',
  stateDes = 'localEstadoDescripcion',
  kardex = 'localKardex',
  acronym = 'localSiglas',
  acronymLoc = 'localSiglasLocal',
  web = 'localWEB',
  uaf = 'localUAF',
  descount = 'localDescuento',
  contract = 'localContrato',
  observations = 'localObservaciones',
  userDokan = 'localUsuarioDokan',
  passDokan = 'localPasswordDokan',
  uloTipo = 'ULOTipo',
  logo = 'localLogo',
  pricelist = 'localListaPrecios',
  establishment = 'localEstablecimiento',
}

export enum KeyLocNoCast {
  cod = 'LOC_CODIGO',
  cityCod = 'LOC_CIUDAD',
  cityName = 'LOC_CIUDAD_NOM',
  name = 'LOC_NOMBRE',
  address = 'LOC_DIRECCION',
  numberPhone = 'LOC_TELEFONO',
  ger = 'LOC_GERENTE',
  typeCod = 'LOC_TIPO',
  typeDesc = 'LOC_TIPO_DES',
  class = 'LOC_CLASE',
  classDes = 'LOC_CLASE_DES',
  owner = 'LOC_PERTENECE',
  ownerDes = 'LOC_PERTENECE_DES',
  stateCod = 'LOC_ESTADO',
  stateDes = 'LOC_ESTADO_DES',
  kardex = 'LOC_KARDEX',
  acronym = 'LOC_SIGLAS',
  acronymLoc = 'LOC_SIGLASBOD',
  web = 'LOC_WEB',
  uaf = 'LOC_UAF',
  descount = 'LOC_DESCUENTO',
  contract = 'LOC_CONTRATO',
  observations = 'LOC_OBSERVACIONES',
  userDokan = 'LOC_USUARIODOKAN',
  passDokan = 'LOC_PASSWORDDOKAN',
  uloTipo = 'ULO_TIPO',
  pricelist = 'LOC_LISTAPRECIOS',
  logo = 'LOC_LOGO',
  establishment = 'LOC_ESTABLECIMIENTO',
}
