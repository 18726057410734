import React, { useCallback, useEffect, useRef } from 'react'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput'
import { Button, DateBox, NumberBox, TextBox, ValidationGroup, ValidationSummary, Validator } from 'devextreme-react'
import DataGrid, { Column, Export, Pager, Paging, RequiredRule } from 'devextreme-react/data-grid'
import { getAllowedPageSizes } from '../../../../../../../helpers/Helper'
import ItemsCountIndicator from '../../../../../../componentes/indicadorNroRegistros'
import { TabState } from '../../../../../../../store/genericTabTypes'
import { CalculateTenth, DecimoTipo, DecimosDatosEdicion, SaveAllData } from '../../types/types'
import { useDispatch, useSelector } from 'react-redux'
import { CCard, CCardBody } from '@coreui/react-pro'
import { RootState } from '../../../../../../../store/store'
import { DateUtils } from '../../../../../../../helpers/dateUtils'
import { changeData, setChangeLoader, setResults } from '../../store/todosReducer'
import { Workbook } from 'exceljs'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { saveAs } from 'file-saver';
import { EAplicationsAcatha } from '../../../../../../../store/enumsAplication'
import { utilidades } from '../../../../../../../helpers/utilidades'
import { ButtonTypes } from '../../../../../../../views/componentes/globalMenu/types'
import { changeCurrentTab, clearButtonClick, setCurrentExecutingAction } from '../../store/tabsReducer'
import { ToastTypes } from '../../../../../../../store/types'
import { addToast } from '../../../../../../../store/toasterReducer'
import { TheenthServices } from '../../services/theenth.services'
import { consoleService } from '../../../../../../../services/console.service'
import { isMobile } from 'react-device-detect'
import validations from '../../../../../../../config/validations'
import BlockUi from '../../../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../../../views/componentes/loadingindicator/loadingindicator'
import { MessagesKeys, lh } from '../../../../../../../helpers/localizationHelper'
import ListErrosValidationForm from '../../../../../../componentes/listadoErroresValidacionForm'
import VisualizaError from '../../../../../../ventas/pages/shared/visualizaError/visualizaError'


interface IAllProps extends React.PropsWithChildren {
  tab: TabState<DecimosDatosEdicion>
  tabId: string
}

const Todos: React.FunctionComponent<IAllProps> = (props) => {
  const { tabId, tab } = props

  const dispatch = useDispatch()
  const todoState = useSelector((state: RootState) => state.nomina.rol.decimos.todos.calculate)
  const currentApp = useSelector((state: RootState) => state.nomina.rol.decimos.config.currentApp)
  const tabs = useSelector((state: RootState) => state.nomina.rol.decimos.tabs)
  const resultados = useSelector((state: RootState) => state.nomina.rol.decimos.todos.resultados)
  const loader = useSelector((state: RootState) => state.nomina.rol.decimos.todos.loader)
  const activePeriod = useSelector((state: RootState) => state.shared.providers.periodoContable)
  const validationAll = useRef<any>()
  const sms = validations['es']

  const [arrayErrorsTodos, setArrayErrorsTodos] = React.useState<Array<string>>([])
  const [showErrorPopupTodos, setShowErrorPopupTodos] = React.useState<boolean>(false)

  const onSetButtonAction = useCallback(
    (tipo: ButtonTypes | undefined) => {
      dispatch(
        setCurrentExecutingAction({
          tabKey: tabId,
          buttonType: tipo,
        }),
      )
    },
    [dispatch, tabId],
  )

  const setToast = useCallback(
    (mensaje: string, tipo: ToastTypes) => {
      dispatch(
        addToast({
          title: `Nómina - Decimo ${currentApp === parseInt(EAplicationsAcatha.DecimoTercero) ? 'Tercero' : 'Cuarto'}`,
          content: mensaje,
          type: tipo,
        }),
      )
    },
    [dispatch, currentApp],
  )

  const onLoader = useCallback((showLoader: boolean, mensaje: string) => {
    dispatch(setChangeLoader({
      show: showLoader,
      mensaje: mensaje
    }))
  }, [dispatch])

  const onLoad = useCallback(() => {
    const temp = { ...todoState }
    if (temp.initial) {
      temp.periodo = activePeriod.length > 0 ? activePeriod[0]?.anio : new Date().getFullYear()
      temp.fechaEmision = DateUtils.getCurrentDateAsString()
      temp.initial = false
      dispatch(changeData({
        ...temp
      }))
    }
  }, [dispatch, todoState, activePeriod])

  const onValueChange = useCallback((data, key: string) => {
    dispatch(changeData({
      ...todoState,
      [key]: data
    }))
  }, [dispatch, todoState])

  const onValidate = useCallback(() => {
    const errors: Array<string> = []
    if (todoState?.periodo === 0 || todoState?.periodo === undefined || todoState?.periodo === null) {
      errors.push('Ingrese el año del periodo a calcular.')
    }
    if (todoState?.fechaEmision === "" || todoState?.fechaEmision === undefined || todoState?.fechaEmision === null) {
      errors.push('Ingrese la Fecha de Emision.')
    }
    return errors
  }, [todoState])

  const onCalculate = useCallback(async () => {
    let errors: Array<string> = []
    errors = onValidate()
    const result = validationAll.current.instance.validate()
    if (result.isValid && errors.length === 0) {
      onSetButtonAction(ButtonTypes.all)
      onLoader(true, 'Calculando...')
      try {
        let fecha = ''
        if (todoState?.fechaEmision !== "" && todoState?.fechaEmision !== null) {
          fecha = DateUtils.format(todoState?.fechaEmision, 'dd/MM/yyyy', 'yyyy-MM-dd')
        }
        const calculate: CalculateTenth = {
          diario: activePeriod?.length > 0 ? activePeriod[0]?.diario : 0,
          anio: todoState?.periodo ?? 0,
          fecha,
          tipo: currentApp === parseInt(EAplicationsAcatha.DecimoTercero) ? DecimoTipo.tercero : DecimoTipo.cuarto,
        }
        const data = await TheenthServices.calculateTenth(calculate)
        consoleService.log(data, 'data calculate')
        if (data?.auto && data?.error === false) {
          setToast(data?.message, ToastTypes.Success)
          dispatch(setResults(data?.auto ?? []))
        } else {
          setToast(data?.message, ToastTypes.Danger)
          dispatch(setResults([]))
        }
      } catch (error) {
        setToast(error.message, ToastTypes.Danger)
      }
      onLoader(false, '')
      onSetButtonAction(undefined)
    } else {
      setArrayErrorsTodos(errors)
      setShowErrorPopupTodos(true)
      setToast(lh.getMessage(MessagesKeys.GlobalErrorSave), ToastTypes.Warning)
    }

  }, [onLoader, onSetButtonAction, activePeriod, currentApp, todoState, onValidate, setToast, dispatch])

  const onSave = useCallback(async () => {
    consoleService.log('on save')
    let errors: Array<string> = []
    errors = onValidate()
    const result = validationAll.current.instance.validate()
    if (result.isValid && errors.length === 0) {
      onSetButtonAction(ButtonTypes.save)
      onLoader(true, 'Guardando todos...')
      try {
        const data_save: SaveAllData = {
          decimos: resultados
        }
        const data = await TheenthServices.SaveAll(data_save)
        consoleService.log(data, 'save all')
        if (data?.auto && data?.error === false) {
          setToast(data?.message, ToastTypes.Success)
          dispatch(changeCurrentTab('BUSQUEDA'))
        } else {
          setToast(data?.message, ToastTypes.Danger)
        }
      } catch (error) {
        setToast(error?.message, ToastTypes.Danger)
      }
      onLoader(false, '')
      onSetButtonAction(undefined)
    } else {
      setArrayErrorsTodos(errors)
      setShowErrorPopupTodos(true)
      setToast(lh.getMessage(MessagesKeys.GlobalErrorSave), ToastTypes.Warning)
    }
  }, [onValidate, setToast, resultados, dispatch, onLoader, onSetButtonAction])

  const handleButtonClick = useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.save:
          if (tabs.current === tabId) onSave()
          break
        case ButtonTypes.print:
          if (tabs.current === tabId) consoleService.log('imprimir')
          break
        case ButtonTypes.print_design:
          if (tabs.current === tabId) consoleService.log('imprimir')
          break
        default:
          break
      }
      dispatch(clearButtonClick(tabId))
    }, [dispatch, tabId, tabs, onSave])



  useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab.globalButtonClick])

  useEffect(() => {
    onLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const onExportExcell = useCallback((e) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('Main sheet')
    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          `Decimo_${currentApp === parseInt(EAplicationsAcatha.DecimoTercero) ? 'Tercero' : 'Cuarto'}_Todos.xlsx`,
        )
      })
    })
  }, [currentApp])

  const onTotal = useCallback(() => {
    let totales = 0
    const provider = resultados.slice(0)
    provider.forEach(x => {
      totales += x?.valor ?? 0
    })
    return totales
  }, [resultados])

  return (
    <>
      {showErrorPopupTodos && (
        <VisualizaError
          titulo="Error en Todos"
          mensaje={'Error al generar roles'}
          content={
            <ListErrosValidationForm
              mainMessage={'Revise los siguientes errores:'}
              errorsList={arrayErrorsTodos}
              color={'danger'}
            />
          }
          onOk={() => setShowErrorPopupTodos(false)}
        />
      )}
      <div id="TodosContent">
        <BlockUi
          tag="div"
          loader={LoadingIndicator}
          blocking={loader.show}
          message={loader.mensaje}
        >
          <RowContainer>
            <CCard>
              <CCardBody>
                <ValidationGroup id={'formTodos'} ref={validationAll}>
                  <RowContainer>
                    {!isMobile && (
                      <CustomCol lg="6" md="8" sm="12">
                        <ValidationSummary id="summary"></ValidationSummary>
                      </CustomCol>
                    )}
                  </RowContainer>
                  <RowContainer>
                    <CustomCol xs="12" md="3">
                      <Labeled label='Periodo:'>
                        <div
                          style={{
                            display: 'flex',
                            gap: '2px',
                            alignItems: 'center',
                            width: '100%',
                          }}
                        >

                          <TextBox
                            value={String(todoState?.periodo) ?? null}
                            onValueChange={(data) => onValueChange(parseInt(data), 'periodo')}
                            maxLength={4}
                            onKeyDown={utilidades.filtraTeclasNoNumericas}
                          >
                            <Validator>
                              <RequiredRule
                                message={'Periodo: ' + sms['required']}
                              />

                            </Validator>
                          </TextBox>
                          <Button
                            id="btnAdd"
                            className="me-1"
                            stylingMode="contained"
                            type="default"
                            onClick={onCalculate}
                          >{'Calcular'}</Button>
                        </div>
                      </Labeled>
                    </CustomCol>
                    <CustomCol xs="12" md="2">
                      <Labeled label='Fecha de Emisión:'>
                        <DateBox value={todoState?.fechaEmision ?? null} onValueChange={(data) => onValueChange(data, 'fechaEmision')} >
                          <Validator>
                            <RequiredRule
                              message={'Fecha de Emisión: ' + sms['required']}
                            />

                          </Validator>
                        </DateBox>
                      </Labeled>
                    </CustomCol>
                  </RowContainer>
                  <RowContainer>
                    {isMobile && (
                      <CustomCol lg="6" md="8" sm="12">
                        <ValidationSummary id="summary"></ValidationSummary>
                      </CustomCol>
                    )}
                  </RowContainer>
                </ValidationGroup>
              </CCardBody>
              <ItemsCountIndicator numberOfItems={resultados?.length} />
              <RowContainer>
                <CustomCol xs="12" md="12">
                  <DataGrid
                    id="tablaTodos"
                    dataSource={resultados}
                    selection={{ mode: 'single' }}
                    hoverStateEnabled={true}
                    showBorders={true}
                    showRowLines={true}
                    repaintChangesOnly={true}
                    highlightChanges={true}
                    keyExpr="secuencial"
                    key="secuencial"
                    focusedRowKey="secuencial"
                    showColumnLines={true}
                    onExporting={onExportExcell}
                    className={'m-2 p-2'}
                    remoteOperations={true}
                    loadPanel={{
                      enabled: true,
                      height: 90,
                      indicatorSrc: '',
                      shading: false,
                      shadingColor: '',
                      showIndicator: true,
                      showPane: true,
                      text: 'Cargando...',
                      width: 200,
                    }}
                  >
                    <Export enabled={true} allowExportSelectedData={true} />
                    <Paging defaultPageSize={20} />
                    <Pager
                      visible={resultados?.length > 0 ? true : false}
                      allowedPageSizes={getAllowedPageSizes(resultados)}
                      displayMode={'full'}
                      showPageSizeSelector={true}
                      showInfo={true}
                      showNavigationButtons={true}
                    />

                    <Column dataField='anio' caption="PERIODO" width="100%" />
                    <Column dataField='usuarioNombre' caption="NOMBRE" width="100%" />
                    <Column dataField='fecha' caption="FECHA EMISION" width="100%" />
                    <Column dataField='valor' caption="VALOR" width="100%" />
                    {/* <Column dataField='' caption="DECIMO" width="100%" /> */}
                  </DataGrid>
                </CustomCol>
              </RowContainer>
              <RowContainer>
                <CustomCol md="10" className="d-flex justify-content-end">
                  <strong> {'Totales:'} </strong>
                </CustomCol>

                <CustomCol md="2">
                  <NumberBox
                    readOnly
                    width="100%"
                    value={onTotal()}
                    placeholder=""
                    className='mb-1'
                    format="#,##0.00"
                  />
                </CustomCol>
              </RowContainer>
            </CCard>
          </RowContainer>
        </BlockUi>
      </div>
    </>
  )
}

export default React.memo(Todos)