import { cilChevronBottom, cilChevronTop } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CCardBody, CCardHeader, CCollapse, CLink } from '@coreui/react-pro'
import React from 'react'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput'
import { RadioGroup, TextBox } from 'devextreme-react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import AplicacionesLookUp from '../../../../../../componentes/aplicacionesLookUp'
import { changeFilter, setCollapsed } from '../../store/serachReducer'
import { TOptionGenerico } from '../../../../../../clientes/pages/clientes/types/types'

export const stateOptions: Array<TOptionGenerico> = [
  { value: 1, label: 'Activos' },
  { value: 2, label: 'Inactivos' },
  { value: 0, label: 'Todos' },
]

interface ISearchProps extends React.PropsWithChildren {
  onSearchEnter: () => void
}


const FiltroBusqueda: React.FunctionComponent<ISearchProps> = (props) => {
  const { onSearchEnter } = props
  const dispatch = useDispatch()

  const searchFilter = useSelector((state: RootState) => state.inventarios.catalogos.aplicaciones.search.filter)
  const collapsed = useSelector((state: RootState) => state.inventarios.catalogos.aplicaciones.search.colapsado)


  const onChangeValueData = React.useCallback((data, key: string) => {
    if (data !== null && data !== undefined) {
      let value = null
      if (data?.event !== null && data?.event !== undefined) {
        value = data?.value
      } else if (key === 'pertence') {
        value = data
      }
      if (value !== null && value !== undefined) {
        dispatch(changeFilter({
          ...searchFilter,
          [key]: value
        }))
      }
    }
  }, [dispatch, searchFilter])

  return (
    <>
      <CCardHeader
        onClick={() => dispatch(setCollapsed(!collapsed))}
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <strong> {collapsed ? '' : 'Filtro de Búsqueda'} </strong>
        <CLink className="card-header-action">
          <CIcon icon={collapsed ? cilChevronBottom : cilChevronTop} />
        </CLink>
      </CCardHeader>
      <CCollapse visible={collapsed}>
        <CCardBody>
          <RowContainer>
            <CustomCol xs="12" md="3">
              <Labeled label="Descripción:">
                <TextBox
                  value={searchFilter?.descripcion ?? ''}
                  onValueChanged={(data) => {
                    onChangeValueData(data, 'descripcion')
                  }}
                  onEnterKey={onSearchEnter}
                />
              </Labeled>
            </CustomCol>
            <CustomCol xs="12" md="3">
              <Labeled label="Pertence:">
                <AplicacionesLookUp
                  selected={searchFilter?.pertence ?? null}
                  onChanged={(data) => {
                    onChangeValueData(data, 'pertence')

                  }}
                  onChangedOptions={() => { }}
                />
              </Labeled>
            </CustomCol>
            <CustomCol xs="12" md="4">
              <Labeled label={"Estado:"}>
                <RadioGroup
                  key="estados-activos"
                  layout="horizontal"
                  displayExpr="label"
                  items={stateOptions}
                  value={searchFilter?.estado ?? null}
                  onValueChanged={(data) => {
                    onChangeValueData(data, 'estado')
                  }}
                />
              </Labeled>
            </CustomCol>
          </RowContainer>
        </CCardBody>
      </CCollapse>
    </>
  )
}

export default React.memo(FiltroBusqueda)