import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LoaderInfo } from '../../../../../store/types'
import { CustomDictionary } from '../../../../ventas/types/generics'
import { EmpresaDatosEdicion } from './types'
import { TConfEdicionPayload } from '../../store/types'

const initialState: CustomDictionary<EmpresaDatosEdicion> = {}
const datosEdicionConfEmpresaSlice = createSlice({
  name: 'empresaDatosEdicion',
  initialState: initialState,
  reducers: {
    // ventasClearDatosEdicion,
    setDatosEdicion(
      state,
      action: PayloadAction<TConfEdicionPayload<EmpresaDatosEdicion>>,
    ) {
      state[action.payload.key] = action.payload.data
    },
    //Establecer datos edicion para un tab nuevo
    initDatosEdicion(
      state,
      action: PayloadAction<TConfEdicionPayload<EmpresaDatosEdicion>>,
    ) {
      if (!state[action.payload.key]) {
        state[action.payload.key] = action.payload.data
      }
    },
    // setEditLoader(
    //   state,
    //   action: PayloadAction<{ key: number | string; info: LoaderInfo }>,
    // ) {
    //   state[action.payload.key].loader = action.payload.info
    // },
  },
})

export const { initDatosEdicion, setDatosEdicion } =
  datosEdicionConfEmpresaSlice.actions
export const dataEditionReducer = datosEdicionConfEmpresaSlice.reducer
