import React from 'react';
import SelectBox from 'devextreme-react/select-box';
import { TypeDays, days } from './types/types';



interface DaysLookUpProps extends React.PropsWithChildren {
  selected: TypeDays | null,
  onChanged: (newValue: TypeDays | null) => void,
  onChangedOptions: (newValue: Array<TypeDays> | []) => void,
  disabled?: boolean,
}



const DiasLookUp: React.FC<DaysLookUpProps> = (props) => {
  const { selected, onChanged, disabled, onChangedOptions } = props;
  const selectRef = React.useRef<any>();
  const [options, setOptions] = React.useState<Array<TypeDays> | []>([]);

  const loadDatos = React.useCallback(async () => {
    const opt = days ?? []
    setOptions(opt)
    onChangedOptions(opt)
    if (selected === null)
      onChanged(opt[0])

  }, [onChanged, onChangedOptions, selected]);

  const getItemDisplayExpr = React.useCallback((item: TypeDays) => {
    return item ? `${item.valor}` : '';
  }, []);

  const onLookUpValueChanged = React.useCallback((evt: any) => {
    if (evt !== undefined) {
      onChanged(evt);
    }
  }, [onChanged]);

  const onSelectBoxFocusIn = React.useCallback((e) => {
    e.element.querySelector("input.dx-texteditor-input").select();
  }, [])

  React.useEffect(() => {
    loadDatos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <SelectBox
      id='diasSelect'
      ref={selectRef}
      items={options ?? []}
      searchEnabled
      displayExpr={getItemDisplayExpr}
      searchExpr={['valor']}
      searchTimeout={500}
      value={selected}
      onValueChange={onLookUpValueChanged}
      disabled={disabled}
      onFocusIn={onSelectBoxFocusIn}
    >
      {props.children}
    </SelectBox>
  )

}
export default React.memo(DiasLookUp);
