import React from 'react'
import SelectBox from 'devextreme-react/select-box'
import { Subtipo } from '../gruposUnidadesLookUp/types/types'
import { typesConceptsMovementsServices } from './services/tiposConceptosMov.services'

interface TiposConceptoMovLookUpProps extends React.PropsWithChildren {
  selected: Subtipo | null
  // eslint-disable-next-line no-unused-vars
  onChanged: (newValue: Subtipo | null) => void
  // eslint-disable-next-line no-unused-vars
  onChangedOptions: (newValue: Array<Subtipo> | []) => void
  disabled?: boolean
  provider?: Array<Subtipo> | []
}

const TiposConceptosMovLookUp: React.FC<TiposConceptoMovLookUpProps> = (
  props,
) => {
  const { provider, selected, onChanged, disabled, onChangedOptions } = props
  const selectRef = React.useRef<any>()
  const [options, setOptions] = React.useState<Array<Subtipo> | []>([])

  const loadDatos = React.useCallback(async () => {
    if (provider !== undefined && provider.length > 0) {
      setOptions(provider)
      const selectedIndex = provider.findIndex((select: Subtipo) => {
        return select.codigo === selected?.codigo
      })
      onChanged(provider[selectedIndex] ?? provider[0])
      if (selectedIndex > -1) {
        onChanged(provider[selectedIndex] ?? provider[0])
      }
    } else {
      const data = await typesConceptsMovementsServices.getTypesMovements()
      if (data?.error === false) {
        const optionsLookup = data?.auto?.length > 0 ? data.auto : []
        setOptions(optionsLookup)
        onChangedOptions(optionsLookup)
        const selectedIndex = optionsLookup.findIndex((option) => {
          return option.codigo === selected?.codigo
        })
        if (selectedIndex > -1) {
          onChanged(optionsLookup[selectedIndex] ?? optionsLookup[0])
        }
      }
    }
  }, [onChanged, onChangedOptions, selected, provider])

  const getItemDisplayExpr = React.useCallback((item: Subtipo) => {
    return item ? `${item.valor}` : ''
  }, [])

  const onLookUpValueChanged = React.useCallback(
    (evt: any) => {
      if (evt.event !== undefined) {
        onChanged(evt.value)
      }
    },
    [onChanged],
  )

  const onSelectBoxFocusIn = React.useCallback((e) => {
    e.element.querySelector('input.dx-texteditor-input').select()
  }, [])

  const refreshSelected = React.useCallback(() => {
    if (options?.length > 0) {
      const selectedIndex = options.findIndex((option: Subtipo) => {
        return option.codigo === selected?.codigo
      })
      onChanged(options[selectedIndex] ?? options[0])
    }
  }, [onChanged, options, selected])

  React.useEffect(() => {
    loadDatos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    refreshSelected()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  return (
    <SelectBox
      id="tiposImpuestosSelect"
      ref={selectRef}
      items={options ?? []}
      searchEnabled
      displayExpr={getItemDisplayExpr}
      searchExpr={['valor']}
      searchTimeout={500}
      value={selected}
      onValueChanged={onLookUpValueChanged}
      disabled={disabled}
      onFocusIn={onSelectBoxFocusIn}
    >
      {props.children}
    </SelectBox>
  )
}
export default React.memo(TiposConceptosMovLookUp)
