import React from 'react';
import { Lookup, DropDownOptions } from 'devextreme-react/lookup';
import { ItemCompras } from '../../../../compras/types/types';
import { CompraService } from '../../../../compras/services/compra.service';

interface IBuscarItemParaLiquidacionProps extends React.PropsWithChildren {
  selected: ItemCompras | null,
  onChanged: (newValue: ItemCompras | null) => void,
  tipoBusqueda: "nombre" | "codigo",
  allowAdd?: boolean,
  allowClear?: boolean,
  allowEdit?: boolean,
  disabled?: boolean,
  proveedor: any
}

export const BuscarItemParaLiquidacion: React.FC<IBuscarItemParaLiquidacionProps> = (props) => {
  const { selected, onChanged, disabled, proveedor } = props;

  //const [options, setOptions] = React.useState<Array<ItemCompras>>([]);

  const datasource = React.useMemo(() => {
    return CompraService.getItemsDatasource(proveedor);
  }, []);

  const getProveedorDisplayExpr = React.useCallback((item: ItemCompras) => {
    return item ? `${item.codigoBarras} - ${item.descripcion}` : '';
  }, []);

  const onSelectBoxFocusIn = React.useCallback((e) => {
    e.element.querySelector("input.dx-texteditor-input").select();
  }, [])

  return (
    <>
      <Lookup
        dataSource={datasource}
        showDataBeforeSearch
        value={selected}
        minSearchLength={3}
        searchTimeout={500}
        displayExpr={getProveedorDisplayExpr}
        searchExpr={['descripcion', 'barras']}
        pageLoadMode='scrollBottom'
        showClearButton={true}
        onValueChanged={({ value }: any) => {
          onChanged(value);
        }}
        disabled={disabled}
        onFocusIn={onSelectBoxFocusIn}
        elementAttr={{
          autofocus: true
        }}
      >
        <DropDownOptions
          tabIndex={3000}
          closeOnOutsideClick={true}
          showTitle={false}
        />
        {props.children}
      </Lookup>
    </>
  );
}