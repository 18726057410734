import React from 'react'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput'
import { TextBox, Button, NumberBox, ValidationGroup, Validator, ValidationSummary } from 'devextreme-react'
import { LineasDatosEdicion } from '../../types/types'
import { ButtonTypes } from '../../../../../../../views/componentes/globalMenu/types'
import { clearButtonClick, openTab, setButtons, setCurrentExecutingAction, setNameTabe } from '../../store/tabsReducer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import TipoEstadoLookUp from '../../../../../../componentes/tipoEstado'
import {
  changeLoaderLines,
  setCurrentFunction,
} from '../../store/generalReducer'
import { initDatosEdicion, setDatosEdicion } from '../../store/editDataReducer'
import Dialog from '../../../../../../../views/componentes/librerias/bootstrap-dialog'
import { v4 as uuidv4 } from 'uuid'
import ModalCuentas from '../../../../../../contabilidad/pages/planCuentas/modalCuentas'
import SustentosTributariosLookUp from '../../../../../../componentes/sustentosTributarioLookUp'
import { TabState } from '../../../../../../../store/genericTabTypes'
import { MessagesKeys, lh } from '../../../../../../../helpers/localizationHelper'
import { asignacionesService } from '../../../../../../contabilidad/pages/asignaciones/services/asignaciones.service'
import { addToast } from '../../../../../../../store/toasterReducer'
import { DocumentInfo, ToastTypes } from '../../../../../../../store/types'
import { ModalConceptoRetencion } from '../../../../../../componentes/modalConceptoRetenecion'
import { AsyncRule, RequiredRule } from 'devextreme-react/validator'
import validations from '../../../../../../../config/validations'
import { InventarioService } from '../../../../../services/inventario.service'
import { StatesEdition } from '../../../../../../ventas/types/enums'
import { tipoEstadoService } from '../../../../../../componentes/tipoEstado/service/tipoEstado.service'
import { consoleService } from '../../../../../../../services/console.service'
import { sustentoTributarioServices } from '../../../../../../componentes/sustentosTributarioLookUp/services/sustentoTributario.service'
import BlockUi from '../../../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../../../views/componentes/loadingindicator/loadingindicator'
import VisualizaError from '../../../../../../ventas/pages/shared/visualizaError/visualizaError'
import ListErrosValidationForm from '../../../../../../componentes/listadoErroresValidacionForm'
import { isMobile } from 'react-device-detect'
import { ModuleButtons } from '../../index';
import { setDatosEdicionBackup } from '../../store/configReducer'

interface INewProps extends React.PropsWithChildren {
  info: DocumentInfo<LineasDatosEdicion>
  tab: TabState<LineasDatosEdicion>
  tabId: string
}

const Nuevo: React.FunctionComponent<INewProps> = (props) => {
  const { tab, tabId, info } = props
  const dispatch = useDispatch()
  const loading = useSelector(
    (state: RootState) =>
      state.inventarios.catalogos.lineas.editData[tabId].loading
  )
  const linesState = useSelector((state: RootState) => {
    return state.inventarios.catalogos.lineas.editData[tabId]
  })
  const linesStateBackup = useSelector((state: RootState) => {
    return state.inventarios.catalogos.lineas.config[tabId]
  })
  const modulo = useSelector((state: RootState) => {
    return state.inventarios.catalogos.lineas.config.aplicacion
  })
  const tabs = useSelector((state: RootState) => {
    return state.inventarios.catalogos.lineas.tabs
  })
  const loader = useSelector((state: RootState) => state.inventarios.catalogos.lineas.general.loader)

  const setButtonExecuttion = React.useCallback((tipo: ButtonTypes | undefined) => {
    dispatch(
      setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: tipo,
      }),
    )
  }, [dispatch, tabId])

  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(
      addToast({
        title: 'Inventario - Lineas',
        content: mensaje,
        type: tipo,
      }),
    )
  }, [dispatch])
  const sms = validations['es']

  const [confirmNew, setonfirmNew] = React.useState<boolean>(false)
  const [arrayErrors, setArrayErrors] = React.useState<Array<string>>([])
  const [showErrorPopup, setShowErrorPopup] = React.useState<boolean>(false)
  const validationGroupRef = React.useRef<any>()
  const dialogRef = React.useRef<any>(null)
  const [showModalPlanCuentas, setShowModalPlanCuentas] =
    React.useState<boolean>(false)
  const [modalConceptoRetencion, setModalConceptoRetencion] = React.useState<boolean>(false)
  const [tipoLinea, setTipoLinea] = React.useState<string>(null)

  const playLoader = React.useCallback(
    (message = 'Cargando...') => {
      dispatch(changeLoaderLines({ show: true, mensaje: message }))
    },
    [dispatch],
  )

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoaderLines({ show: false, mensaje: '' }))
  }, [dispatch])

  const onChangeData = React.useCallback((data, key: string) => {
    let value = null
    if (data?.event && data.event !== null && data.event !== undefined) {
      value = data.value
    } else if (key === 'venta' || key === 'compra' || key === 'sustentoTributario' ||
      key === 'inventario' || key === 'costoVentas' || key === 'retencion' || key === 'estado') {
      value = data
    }
    if (value !== null && value !== undefined) {
      dispatch(setDatosEdicion({
        key: tabId,
        line: {
          ...linesState,
          [key]: value
        }
      }))
    }
  }, [dispatch, linesState, tabId])

  const onValidateForm = React.useCallback(() => {
    const arrayErrores: Array<string> = []
    if (linesState?.codigoParticular === null || linesState?.codigoParticular === undefined || linesState?.codigoParticular === '') {
      arrayErrores.push('Código: Ingrese el código de la línea.')
    }
    if (linesState?.descripcion === null || linesState?.descripcion === undefined || linesState?.descripcion === '') {
      arrayErrores.push('Descripción: Ingrese una descripción de la línea.')
    }
    if (linesState?.estado === null || linesState?.estado === undefined || linesState.estado?.codigo <= 0) {
      arrayErrores.push('Estado: Elija el estado de la línea.')
    }
    return arrayErrores
  }, [linesState])

  const onHandleSave = React.useCallback(async () => {
    let errores: Array<string> = []
    const result = validationGroupRef.current.instance.validate()
    errores = onValidateForm()
    if (result.isValid && errores.length === 0) {

      try {
        const request_save = {
          codigoPrimario: linesState?.codigo ?? 0,
          codigo: linesState?.codigoParticular ?? "",
          descripcion: linesState?.descripcion ?? '',
          porcentUtilidad: linesState?.porcentajeUtilidad ?? 0,
          estado: linesState.estado?.codigo ?? -1,
          conConcepto: linesState.retencion?.codigo ?? 0,
          susCodigo: linesState.sustentoTributario?.codigo ?? "00",
        }
        setButtonExecuttion(ButtonTypes.save)
        playLoader('Guardando...')
        const res = await InventarioService.createLinea(request_save)
        if (res !== null && res !== undefined && res['error'] === false) {
          let codigoLinea: number = 0
          if (linesState?.codigo > 0) {
            codigoLinea = linesState?.codigo
          } else {
            codigoLinea = res['auto'] ?? 0
            dispatch(setDatosEdicion({
              key: tabId,
              line: {
                ...linesState,
                codigo: codigoLinea
              }
            }))
          }
          dispatch(setNameTabe({
            key: tabId,
            codigo: codigoLinea,
            nombre: linesState?.descripcion
          }))

          dispatch(
            setButtons({
              tabKey: tabId,
              buttons: {
                ...ModuleButtons,
                Guardar: false
              },
            }),
          )

          setToast(res['message'], ToastTypes.Success)
          const asignaciones = []
          try {
            if (linesState?.venta !== null && linesState?.venta !== undefined && linesState?.venta?.codigo !== 0) {
              asignaciones.push({ tipo: "LINEA", planCuentasCodigo: (linesState?.venta?.codigo), transaccion: "VENTA" })
            }
            if (linesState?.compra !== null && linesState?.compra !== undefined && linesState?.compra?.codigo !== 0) {
              asignaciones.push({ tipo: "LINEA", planCuentasCodigo: linesState.compra?.codigo, transaccion: "COMPRA" })
            }
            if (linesState?.inventario !== null && linesState?.inventario !== undefined && linesState?.inventario?.codigo !== 0) {
              asignaciones.push({ tipo: "LINEA", planCuentasCodigo: linesState.inventario?.codigo, transaccion: "INVENTARIO" })
            }
            if (linesState?.costoVentas !== null && linesState?.costoVentas !== undefined && linesState?.costoVentas?.codigo !== 0) {
              asignaciones.push({ tipo: "LINEA", planCuentasCodigo: linesState.costoVentas?.codigo, transaccion: "COSTOVENTAS" })
            }
            const resAsig = await asignacionesService.registrarAsignacionItems(asignaciones, codigoLinea)
            if (resAsig !== null && resAsig !== undefined && resAsig['error'] === true) {
              setToast(resAsig['message'], ToastTypes.Danger)
            }
          } catch (error) {
            setToast(error.message, ToastTypes.Danger)
            return error
          }
        } else {
          setToast(res['message'], ToastTypes.Danger)
        }
        stopLoader()
        setButtonExecuttion(undefined)

      } catch (error) {
        stopLoader()
        setButtonExecuttion(undefined)
        setToast(error.message, ToastTypes.Danger)
      }
    } else {
      setToast(lh.getMessage(MessagesKeys.GlobalErrorSave), ToastTypes.Warning)
      setArrayErrors(errores)
      setShowErrorPopup(true)
    }
  }, [linesState, setToast, playLoader, stopLoader, setButtonExecuttion, dispatch, tabId, onValidateForm])


  const onGetAsignaciones = React.useCallback(async (codigo: number) => {
    const getAsignaciones = []
    try {
      const request_obj = {
        tipo: 'LINEA',
        item: codigo
      }
      const data = await asignacionesService.obtenerAsignaciones(request_obj)
      consoleService.log(data)
      if (data !== null && data !== undefined && data.length > 0) {
        setToast('Asignaciones encontradas', ToastTypes.Success)
        data.map(item => {
          const asignacionSave: any = {}
          asignacionSave.numero = item.PLA_NUMERO ?? ""
          asignacionSave.codigo = item.PLA_CODIGO ?? ""
          asignacionSave.descripcion = item.PLA_DESCRIPCION ?? ""
          switch (item.ASG_TRANSACCION) {
            case "VENTA":
              getAsignaciones.push({ nombre: 'VENTA', detalle: { ...asignacionSave } })
              break;
            case "COMPRA":
              getAsignaciones.push({ nombre: 'COMPRA', detalle: { ...asignacionSave } })
              break;
            case "INVENTARIO":
              getAsignaciones.push({ nombre: 'INVENTARIO', detalle: { ...asignacionSave } })
              break;
            case "COSTOVENTAS":
              getAsignaciones.push({ nombre: 'COSTOVENTAS', detalle: { ...asignacionSave } })
              break;
            default:
              break;
          }
        })
      } else {
        setToast('No se encontraron asignaciones', ToastTypes.Info)
      }
      return getAsignaciones
    } catch (error) {
      setToast(error, ToastTypes.Success)
    }
  }, [setToast])

  const validateAsyncSelect = React.useCallback((datoSeleccion: any, message: string) => {
    if (datoSeleccion.value.codigo > -1) {
      return Promise.resolve()
    } else {
      return Promise.reject(message)
    }
  }, [])

  const validateAsyncSeleccionTipoId = React.useCallback((datoSeleccion) => {
    return validateAsyncSelect(datoSeleccion, `Estado: Debe seleccionar una opción.`)
  }, [validateAsyncSelect])



  const removerCuenta = React.useCallback((key: string) => {
    dispatch(setDatosEdicion({
      key: tabId,
      line: {
        ...linesState,
        [key]: null
      }
    }))
  }, [dispatch, linesState, tabId])

  const modoNuevo = React.useCallback(async (template: number, limpiar: boolean = false) => {
    if (loading === false && !limpiar) {
      return
    }
    setButtonExecuttion(ButtonTypes.new)
    playLoader('Iniciando...')
    const data = { ...defaultDataLines }
    const estado = await tipoEstadoService.getTipoEstado('Elija un estado')
    const sutento = await sustentoTributarioServices.getTipos();
    consoleService.log(sutento)
    if (estado['auto'] && estado['error'] === false) {
      data.estado = estado['auto'][1]
    }
    if (sutento['auto'] && sutento['error'] === false) {
      data.sustentoTributario = sutento['auto'][0]
    }
    data.loading = false
    dispatch(setDatosEdicion({
      key: tabId,
      line: {
        ...data
      }
    }))
    dispatch(setDatosEdicionBackup({
      key: tabId,
      line: {
        ...data
      }
    }))
    stopLoader()
    setButtonExecuttion(undefined)
  }, [loading, playLoader, stopLoader, setButtonExecuttion, dispatch, tabId])

  const onGetEstado = React.useCallback(async (estado: boolean) => {
    try {
      let codigo = -1
      if (estado === true) {
        codigo = 1
      } else if (estado === false) {
        codigo = 2
      }
      const data = await tipoEstadoService.getTipoEstado('Elija un estado')
      if (data !== null && data !== undefined && data['error'] === false) {
        const est = data['auto'].find(item => item.codigo === codigo)
        return est
      }
      return null
    } catch (error) {
      return null
    }
  }, [])


  const onGetSusTri = React.useCallback(async (susCodigo: boolean) => {
    try {
      const data = await sustentoTributarioServices.getTipos();
      if (data !== null && data !== undefined && data['error'] === false) {
        const est = data['auto'].find(item => item.codigo === susCodigo)
        return est
      }
      return null
    } catch (error) {
      return null
    }
  }, [])


  const onGetFuente = React.useCallback(async (codigo) => {
    try {
      let fechaActual: any = new Date()
      fechaActual = fechaActual.getDate() + '/' + (fechaActual.getMonth() + 1) + '/' + fechaActual.getFullYear()
      const requestBody: any = {
        fecha: fechaActual,
        retencion: "",
        tipo: 'todos',
      }
      const data = await asignacionesService.obtenerConceptosRetencion(requestBody)
      if (data !== null && data !== undefined && data.length > 0) {
        const fuente = data.find(item => parseInt(item.CON_CODIGO) === codigo)
        return fuente
      }
      return null
    } catch (error) {
      return null
    }
  }, [])


  const cargarLineas = React.useCallback(async (loder: boolean, lineaData) => {
    if (loder === false) {
      return
    }
    setButtonExecuttion(ButtonTypes.edit)
    playLoader('Cargando Linea...')
    const data = { ...defaultDataLines }
    consoleService.log(lineaData, 'linea')
    const asig = await onGetAsignaciones(lineaData?.codigo ?? 0)
    const estado = await onGetEstado(lineaData?.estado)
    const susTri = await onGetSusTri(lineaData?.susCodigo)
    const fuente = await onGetFuente(lineaData?.conCodigo)
    const retencionFuente = {
      codigo: fuente?.CON_CODIGO ?? "",
      numero: fuente?.CON_NUMERO ?? "",
      descripcion: fuente?.CON_CONCEPTO ?? "",
    }
    consoleService.log(asig)
    if (asig.length > 0) {
      asig.forEach(a => {
        switch (a.nombre) {
          case "VENTA":
            data.venta = a.detalle
            break;
          case "COMPRA":
            data.compra = a.detalle
            break;
          case "INVENTARIO":
            data.inventario = a.detalle
            break;
          case "COSTOVENTAS":
            data.costoVentas = a.detalle
            break;
          default:
            break;
        }
      })
    }

    data.estado = estado
    data.sustentoTributario = susTri
    data.retencion = retencionFuente
    data.codigoParticular = lineaData?.particular
    data.descripcion = lineaData?.descripcion
    data.porcentajeUtilidad = lineaData?.utilidad
    data.codigo = lineaData?.codigo
    dispatch(setDatosEdicion({
      key: tabId,
      line: data
    }))
    dispatch(setDatosEdicionBackup({
      key: tabId,
      line: {
        ...data
      }
    }))
    stopLoader()
    setButtonExecuttion(undefined)
  }, [dispatch, playLoader, setButtonExecuttion, stopLoader, tabId, onGetSusTri, onGetEstado, onGetFuente, onGetAsignaciones])

  const onConfirmNew = React.useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea crear un nuevo registro?`,
      actions: [
        Dialog.Action(
          <span>
            <u>P</u>estaña Actual
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-success',
          'p',
        ),
        Dialog.Action(
          <span>
            <u>N</u>ueva Pestaña
          </span>,
          (dialog) => {
            dialog.hide()
            const id = uuidv4()
            dispatch(
              initDatosEdicion({
                key: id,
                data: {
                  ...defaultDataLines,
                  loading: true
                },
              }),
            )
            dispatch(openTab({ key: id }))
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setonfirmNew(false)
    return
  }, [dispatch])


  const handleButtonClick = React.useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.save:
          if (tabs.current === tabId) onHandleSave()
          break
        case ButtonTypes.new:
          if (tabs.current === tabId) setonfirmNew(true)
          break
        case ButtonTypes.broom:
          if (tabs.current === tabId) {
            dispatch(setDatosEdicion({
              key: tabId,
              line: {
                ...defaultDataLines
              }
            }))
          }
          break
        case ButtonTypes.undo:
          if (tabs.current === tabId) {
            dispatch(setDatosEdicion({
              key: tabId,
              line: {
                ...linesStateBackup
              }
            }))
          }
          break
        default:
          break
      }
      dispatch(setCurrentFunction(''))
      dispatch(clearButtonClick(tabId))
    },
    [dispatch, onHandleSave, tabId, tabs, linesStateBackup],
  )

  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab.globalButtonClick]);

  React.useEffect(() => {
    if (tab.editStatus === StatesEdition.new) {
      modoNuevo(tab?.info?.codigo)
    } else {
      cargarLineas(loading, info.info)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  React.useEffect(() => {
    if (confirmNew) {
      onConfirmNew()
    }
  }, [confirmNew, onConfirmNew])

  return (
    <>
      {showModalPlanCuentas && <ModalCuentas
        show={showModalPlanCuentas}
        onClose={() => setShowModalPlanCuentas(false)}
        cargarCuentas={(account) => {
          onChangeData(account, tipoLinea)
          setShowModalPlanCuentas(false)
        }}
      />}
      {modalConceptoRetencion && <ModalConceptoRetencion
        onChanged={(data) => {
          onChangeData(data, 'retencion')
          setModalConceptoRetencion(false)
        }}
        onCancel={() => setModalConceptoRetencion(false)}
        show={modalConceptoRetencion}
        codModulo={modulo}
      />}
      {showErrorPopup && (
        <VisualizaError
          titulo="Error en Lineas"
          mensaje={'Error al guardar'}
          content={
            <ListErrosValidationForm
              mainMessage={'Revise los siguientes errores:'}
              errorsList={arrayErrors}
              color={'danger'}
            />
          }
          onOk={() => setShowErrorPopup(false)}
        />
      )}
      <div style={{ overflowX: 'hidden' }}>
        <BlockUi
          tag="div"
          loader={LoadingIndicator}
          blocking={loader.show}
          message={loader.mensaje}
        >
          <RowContainer className="m-2">
            <Dialog ref={dialogRef} />

            <ValidationGroup id={'formLineas'} ref={validationGroupRef}>
              {
                !isMobile && (
                  <RowContainer>
                    <CustomCol lg="6" md="8" sm="12">
                      <ValidationSummary id="summary"></ValidationSummary>
                    </CustomCol>
                  </RowContainer>
                )
              }
              <RowContainer>
                <CustomCol xs="12" md="3">
                  <Labeled label="Código:">
                    <TextBox
                      value={linesState?.codigoParticular ?? ""}
                      onValueChanged={(data) => onChangeData(data, 'codigoParticular')}
                    >
                      <Validator>
                        <RequiredRule message={'Código: ' + sms['required']} />
                      </Validator>
                    </TextBox>
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="4">
                  <Labeled label="Descripción:">
                    <TextBox
                      value={linesState?.descripcion ?? ""}
                      onValueChanged={(data) => { onChangeData(data, 'descripcion') }}
                    >
                      <Validator>
                        <RequiredRule message={'Descripción: ' + sms['required']} />
                      </Validator>
                    </TextBox>
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="3">
                  <Labeled label="Estado:">
                    <TipoEstadoLookUp
                      onChanged={(data) => onChangeData(data, 'estado')}
                      selected={linesState?.estado} >
                      <Validator>
                        <RequiredRule message={'Estado: Debe seleccionar una opción'} />
                        <AsyncRule
                          message={'Estado: Debe seleccionar una opción'}
                          validationCallback={validateAsyncSeleccionTipoId}
                        />
                      </Validator>
                    </TipoEstadoLookUp>
                  </Labeled>
                </CustomCol>
              </RowContainer>
              {
                isMobile && (
                  <RowContainer>
                    <CustomCol lg="6" md="8" sm="12">
                      <ValidationSummary id="summary"></ValidationSummary>
                    </CustomCol>
                  </RowContainer>
                )
              }
            </ValidationGroup>
            <p style={{ margin: '5px 0 0 0' }}>
              {lh.getMessage(MessagesKeys.InventarioLineasNuevoInfo)}
            </p>
            <RowContainer>
              <Labeled label="Venta">
                <RowContainer>
                  <CustomCol xs="12" md="6">
                    <div
                      style={{
                        display: 'flex',
                        gap: '2px',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <TextBox
                        value={`${linesState.venta?.numero ?? ''} ${linesState.venta?.descripcion ?? ''}`}
                        readOnly={true}
                        showClearButton={true}
                        width="100%"
                        inputAttr={{ autocomplete: 'nope' }}
                      />
                      <Button
                        id="btnAdd"
                        className="me-1"
                        icon="add"
                        stylingMode="contained"
                        type="default"
                        onClick={() => {
                          setTipoLinea('venta')
                          setShowModalPlanCuentas(true)
                        }}
                      />
                      <Button
                        id="btnRemove"
                        icon="trash"
                        stylingMode="contained"
                        type="danger"
                        onClick={() => removerCuenta('venta')}
                      />
                    </div>
                  </CustomCol>
                </RowContainer>
              </Labeled>
            </RowContainer>
            <RowContainer>
              <Labeled label="Compra">
                <RowContainer>
                  <CustomCol xs="12" md="6">
                    <div
                      style={{
                        display: 'flex',
                        gap: '2px',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <TextBox
                        value={`${linesState.compra?.numero ?? ''} ${linesState.compra?.descripcion ?? ''}`}
                        readOnly={true}
                        showClearButton={true}
                        width="100%"
                        inputAttr={{ autocomplete: 'nope' }}
                      />
                      <Button
                        id="btnAdd"
                        className="me-1"
                        icon="add"
                        stylingMode="contained"
                        type="default"
                        onClick={() => {
                          setTipoLinea('compra')
                          setShowModalPlanCuentas(true)
                        }}
                      />
                      <Button
                        id="btnRemove"
                        icon="trash"
                        stylingMode="contained"
                        type="danger"
                        onClick={() => removerCuenta('compra')}
                      />
                    </div>
                  </CustomCol>
                </RowContainer>
              </Labeled>
            </RowContainer>
            <RowContainer>
              <Labeled label="Inventario">
                <RowContainer>
                  <CustomCol xs="12" md="6">
                    <div
                      style={{
                        display: 'flex',
                        gap: '2px',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <TextBox
                        value={`${linesState.inventario?.numero ?? ''} ${linesState.inventario?.descripcion ?? ''}`}
                        readOnly={true}
                        showClearButton={true}
                        width="100%"
                        inputAttr={{ autocomplete: 'nope' }}
                      />
                      <Button
                        id="btnAdd"
                        className="me-1"
                        icon="add"
                        stylingMode="contained"
                        type="default"
                        onClick={() => {
                          setTipoLinea('inventario')
                          setShowModalPlanCuentas(true)
                        }}
                      />
                      <Button
                        id="btnRemove"
                        icon="trash"
                        stylingMode="contained"
                        type="danger"
                        onClick={() => removerCuenta('inventario')}
                      />
                    </div>
                  </CustomCol>
                </RowContainer>
              </Labeled>
            </RowContainer>
            <RowContainer>
              <Labeled label="Costo de Ventas">
                <RowContainer>
                  <CustomCol xs="12" md="6">
                    <div
                      style={{
                        display: 'flex',
                        gap: '2px',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <TextBox
                        value={`${linesState.costoVentas?.numero ?? ''} ${linesState.costoVentas?.descripcion ?? ''}`}
                        readOnly={true}
                        showClearButton={true}
                        width="100%"
                        inputAttr={{ autocomplete: 'nope' }}
                      />
                      <Button
                        id="btnAdd"
                        className="me-1"
                        icon="add"
                        stylingMode="contained"
                        type="default"
                        onClick={() => {
                          setTipoLinea('costoVentas')
                          setShowModalPlanCuentas(true)
                        }}
                      />
                      <Button
                        id="btnRemove"
                        icon="trash"
                        stylingMode="contained"
                        type="danger"
                        onClick={() => removerCuenta('costoVentas')}
                      />
                    </div>
                  </CustomCol>
                </RowContainer>
              </Labeled>
            </RowContainer>
            <RowContainer>
              <Labeled label="Retención">
                <RowContainer>
                  <CustomCol xs="12" md="6">
                    <div
                      style={{
                        display: 'flex',
                        gap: '2px',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <TextBox
                        value={`${linesState.retencion?.numero ?? ''} ${linesState.retencion?.descripcion ?? ''}`}
                        readOnly={true}
                        showClearButton={true}
                        width="100%"
                        inputAttr={{ autocomplete: 'nope' }}
                      />
                      <Button
                        id="btnAdd"
                        className="me-1"
                        icon="add"
                        stylingMode="contained"
                        type="default"
                        onClick={() => setModalConceptoRetencion(true)}
                      />
                      <Button
                        id="btnRemove"
                        icon="trash"
                        stylingMode="contained"
                        type="danger"
                        onClick={() => removerCuenta('retencion')}
                      />
                    </div>
                  </CustomCol>
                </RowContainer>
              </Labeled>
            </RowContainer>
            <RowContainer>
              <CustomCol xs="12" md="5">
                <Labeled label="Sustento Tributario">
                  <SustentosTributariosLookUp
                    selected={linesState?.sustentoTributario ?? null}
                    onChanged={(data) => onChangeData(data, 'sustentoTributario')}
                    onChangedOptions={() => { }}
                  />
                </Labeled>
              </CustomCol>
              <CustomCol xs="12" md="3">
                <Labeled label="Porcentaje de Utilidad:">
                  <NumberBox
                    value={linesState?.porcentajeUtilidad ?? null}
                    onValueChanged={(data) => onChangeData(data, 'porcentajeUtilidad')}
                  />
                </Labeled>
              </CustomCol>
            </RowContainer>
          </RowContainer>
        </BlockUi>
      </div>
    </>
  )
}

export default React.memo(Nuevo)

export const defaultDataLines: LineasDatosEdicion = {
  codigo: 0,
  codigoParticular: '',
  loading: false,
  descripcion: '',
  estado: null,
  venta: null,
  compra: null,
  inventario: null,
  costoVentas: null,
  retencion: null,
  sustentoTributario: null,
  porcentajeUtilidad: null,
  guardado: false,
}
