import React from 'react';
import SelectBox from 'devextreme-react/select-box';
import { clasificacionesService } from './service/clasificaciones.service';
import { ClasificacionOption } from './store/type';
import { v4 as uuidv4 } from 'uuid'

interface LookUpPropsClasificacionAdicional extends React.PropsWithChildren {
  selected: ClasificacionOption | null;
  onChanged: (event: any) => void;
  onChange?: (event: any) => void;
  disabled?: boolean;
  provider?: Array<ClasificacionOption> | []
  id?: string
}

const LookUpClasificacionAdicional: React.FC<LookUpPropsClasificacionAdicional> = (props) => {
  const { id, provider, selected, onChanged, onChange, disabled } = props;
  const selectRef = React.useRef<any>(null);
  const [options, setOptions] = React.useState<ClasificacionOption[]>([]);

  const loadDatos = React.useCallback(async () => {
    if (provider?.length) {
      setOptions(provider);
      return;
    }

    const { error, auto } = await clasificacionesService.getClasificacionesAdicionales("Elija una opción");
    if (!error && auto.length) {
      const opt = auto.map(x => ({
        codigo: parseInt(x?.codigo) ?? -1,
        descripcion: x?.descripcion ?? '',
        tipoCodigo: x?.tipoCodigo ?? 0,
        grupo: x?.grupo ?? '',
        orden: parseInt(x?.orden) ?? 1,
        estado: parseInt(x?.estado) === 1
      }));
      setOptions(opt);
    }
  }, [provider]);

  const onValueChanged = React.useCallback((event: any) => {
    if (!event.event) return;
    onChanged(event.value);
    onChange?.(event.value);
  }, [onChanged, onChange]);

  React.useEffect(() => {
    if (!selected && options.length > 0) {
      const defaultOption = options.find(opt => opt.codigo === -1);
      if (defaultOption) {
        onValueChanged(defaultOption);
      }
    }
  }, [selected, options, onValueChanged]);

  React.useEffect(() => {
    loadDatos();
  }, [loadDatos]);

  return (
    <SelectBox
      id={id ?? 'impresionesSelect'}
      key={uuidv4()}
      ref={selectRef}
      items={options}
      searchEnabled
      displayExpr="descripcion"
      searchExpr={['descripcion']}
      searchTimeout={500}
      value={selected}
      onValueChanged={onValueChanged}
      disabled={disabled}
      onFocusIn={(e: any) => e.element.querySelector("input.dx-texteditor-input").select()}
    >
      {props.children}
    </SelectBox>
  );

}
export default React.memo(LookUpClasificacionAdicional);
