import { RequestHelper } from "../../../../helpers/requestHelper"



export const DetailReister = { getDetail }



async function getDetail(usuarioCodigo: number, fecha: string): Promise<any> {
  try {
    const obj = {
      usuario: usuarioCodigo,
      fechaDesde: fecha,
      fechaHasta: fecha,
    }
    const apiData = await RequestHelper.getAll<any>('nomina/registro/marcacion', 'registro/getAll', '', obj)
    return apiData
  } catch (error) {
    return error
  }
}