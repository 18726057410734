import { AnexoTransaccionalState } from "../pages/anexoTransaccional/types/types";

export type ModuloImpuestosState = {
  anexoTransaccional: AnexoTransaccionalState
}

export interface CustomDictionaryImpuestos<T> {
  [key: string]: T;
}

export enum PERIOD_TYPE {
  MONTLY = "Mensual",
  BIANNUAL = "Semestral",
}

export enum ATS_TYPE {
  VIG_SINCE_SEP_2016 = 4,
  VIG_SINCE_MAR_2015 = 3,
  VIG_TO_FEB_2015 = 2,
  VIG_TO_DEC_2012 = 1,
}