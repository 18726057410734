import React, { memo } from 'react'
import { PaginationInfo, ToastTypes } from "../../../../../../../store/types"
import { GrupoInventario } from '../../../../../store/types';
import { CheckBox, TextBox, ValidationGroup } from 'devextreme-react'
import { CCol, CRow } from '@coreui/react-pro'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import ResultadoBusqueda from './resultadoBusqueda'
import { InventarioService } from '../../../../../services/inventario.service'
import SelectBox from '../../../../../../../views/componentes/selectBox/selectBox';



interface IBuscarProps extends React.PropsWithChildren {
  onChanged: (data) => void
  onSelection: (data) => void
  lineas?: Array<number>
  deletedItem: any,
  dataCategoriaWeb: any,
  currentTab: string
  setCurrenTab?: (currentTab: string) => void
  onCancel?: () => void
  funcion: string
  resetSelected?: () => void
  resetFunction: () => void
  setToast: (sms: string, type: ToastTypes) => void
  playLoader?: () => void
  stopLoader?: () => void
}

const Buscar = (props: IBuscarProps) => {
  const {
    funcion,
    setCurrenTab,
    onChanged,
    resetFunction,
    setToast,
    stopLoader,
    playLoader,
    onSelection,
    deletedItem,
    currentTab,
    resetSelected,
    dataCategoriaWeb,
    lineas,
  } = props

  const formSearchRef = React.useRef<any>()
  const [descripcion, setDescripcion] = React.useState<string>('')
  const [categoriaWeb, setCategoriaWeb] = React.useState<Array<any> | null>(
    dataCategoriaWeb[0],
  )
  const [activo, setActivo] = React.useState<boolean>(true)
  const [items, setItems] = React.useState<Array<GrupoInventario>>([])

  React.useEffect(() => {
    if (currentTab === 'Buscar') {
      if (funcion === 'Buscar') {
        if (lineas?.length > 0) {
          handleSubmitByLineas()
        } else {
          handleSubmit()
        }
      } else if (funcion === 'Eliminar') {
        deleteItemTable(deletedItem)
      }
      resetFunction()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [funcion])

  const deleteItemTable = (idCuenta) => {
    const filtrado = items.findIndex((item: any) => {
      return Number(item.codigo) === Number(idCuenta['codigo'])
    })
    const rowsBefore = items.slice()
    if (filtrado > -1) {
      rowsBefore.splice(filtrado, 1)
      setItems(rowsBefore)
      resetSelected()
      setCurrenTab('Buscar')
      resetFunction()
    }
  }

  const handleSubmitByLineas = React.useCallback(async () => {
    setItems([])
    playLoader()
    const paginInfo: PaginationInfo = {
      current: 0,
      size: 10,
    }

    try {
      const gruposByLineas = await InventarioService.getGrupsByLines(
        lineas,
        descripcion,
        paginInfo,
      )
      const grupos = gruposByLineas.result
      stopLoader()
      if (grupos && grupos.length > 0) {
        // setToast('(' + data.length + ')' + ' Registros encontrados.', 'success')
        let item: Array<GrupoInventario> = []
        item = grupos.map(function (item: any) {
          return {
            ...item,
            descripcion: String(item['descripcion'] ?? ''),
            categoriaWeb:
              item['categoriaWeb'] === null ? -1 : item['categoriaWeb'],
            pertenece: String(item['perteneceNombre'] ?? ''),
          }
        })
        setItems(item)
      }

      return await items
    } catch (error) {
      stopLoader()
      setToast(JSON.stringify(error), ToastTypes.Danger)
    }
  }, [descripcion, items, playLoader, setToast, stopLoader, lineas])

  const handleSubmit = React.useCallback(async () => {
    setItems([])
    playLoader()
    try {
      let catWeb = "0"
      if (categoriaWeb !== null && categoriaWeb !== undefined && categoriaWeb['codigo']) {
        catWeb = categoriaWeb['id'].toString()
      }
      let estado = '0'
      if (estado !== null && estado !== undefined && estado) {
        estado = '1'
      }
      const grupos = await InventarioService.getGrupos(descripcion, catWeb, estado)
      stopLoader()
      if (grupos && grupos.length > 0) {
        // setToast('(' + data.length + ')' + ' Registros encontrados.', 'success')
        let item: Array<GrupoInventario> = []
        item = grupos.map(function (item: any) {
          return {
            ...item,
            descripcion: String(item['descripcion'] ?? ''),
            categoriaWeb:
              item['categoriaWeb'] === null ? -1 : item['categoriaWeb'],
            pertenece: String(item['perteneceNombre'] ?? ''),
          }
        })
        setItems(item)
      }

      return await items
    } catch (error) {
      stopLoader()
      setToast(JSON.stringify(error), ToastTypes.Danger)
    }
  }, [descripcion, items, playLoader, setToast, stopLoader, categoriaWeb])

  return (
    <>
      <fieldset>
        <ValidationGroup ref={formSearchRef}>
          <CRow className="row">
            <div className="dx-field-label col-lg-2 col-md-3 col-sm-12">
              {'Nombre:'}
            </div>
            <CustomCol className="dx-field-value" xs="12" md="4">
              <CCol md="8">
                <TextBox
                  name="codigo"
                  value={descripcion ?? ''}
                  onValueChanged={({ value }: any) => setDescripcion(value)}
                  onEnterKey={handleSubmit}
                ></TextBox>
              </CCol>
            </CustomCol>
          </CRow>
          <CRow className="row mt-2">
            <div className="dx-field-label col-lg-2 col-md-3 col-sm-12">
              {'Activo:'}
            </div>
            <CustomCol className="dx-field-value" xs="12" md="4">
              <CCol md="8">
                <CheckBox
                  value={activo}
                  onValueChanged={(e) => {
                    setActivo(e.value)
                  }}
                />
              </CCol>
            </CustomCol>
          </CRow>
          <CRow className="row mt-2">
            <div className="dx-field-label col-lg-2 col-md-3 col-sm-12">
              {'Categoría Web:'}
            </div>
            <CustomCol className="dx-field-value" xs="12" md="4">
              <CCol md="12">
                <SelectBox
                  id={'selectedCategoriaWeb'}
                  keyExpr={'id'}
                  displayExpr={'name'}
                  onChange={(evt) => {
                    if (evt) {
                      setCategoriaWeb(evt)
                    } else {
                      setCategoriaWeb(null)
                    }
                  }}
                  options={dataCategoriaWeb}
                  placeholder={'Seleccionar'}
                  selected={categoriaWeb}
                  elementAttr={{
                    autocomplete: 'nope',
                    autoComplete: 'nope',
                  }}
                  onEnterKey={handleSubmit}
                ></SelectBox>
              </CCol>
            </CustomCol>
          </CRow>

          <ResultadoBusqueda
            funcion={''}
            data={items ?? []}
            setToast={setToast}
            capturaSeleccion={(data) => onSelection(data)}
            onChanged={onChanged}
          />
        </ValidationGroup>
      </fieldset>
    </>
  )
}

export default memo(Buscar)
