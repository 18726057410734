import React, { useState, useEffect } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CTooltip,
} from '@coreui/react-pro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch, useSelector } from 'react-redux'
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import { RootState } from '../../../../../../store/store'

import { changeLoader } from '../../../../../../store/reducers'
import { ToastTypes } from '../../../../../../store/types'
import { addToast } from '../../../../../../store/toasterReducer'
import Modalform from '../../../../../../views/componentes/modalform'
import CustomCol from '../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput'
import {
  CheckBox,
  RadioGroup,
  TextArea,
  TextBox,
  ValidationGroup,
  ValidationSummary,
  Validator,
} from 'devextreme-react'
import CategoriasWebLookUp from '../../../../../componentes/categoriasWebLookUp'
import FIleInput from '../../../../../componentes/fileInput'
import { TIPOS_BOTONES } from '../../../../../proveedores/pages/proveedores/types/types'
import ImageViewer from '../../../../../componentes/imagen'
import { ItemImages, SaveWooItem, WebCategoryWoo } from '../../types/types'
import { defaultImagenes } from '../nuevo/components/imagenes'
import { TOptionGenerico } from '../../../../../clientes/pages/clientes/types/types'
import { useConvertToBase64 } from '../../../../../../hooks/useGlobalHooks'
import { AsyncRule, RequiredRule } from 'devextreme-react/validator'
import VisualizaError from '../../../../../ventas/pages/shared/visualizaError/visualizaError'
import ListErrosValidationForm from '../../../../../componentes/listadoErroresValidacionForm'
import { ItemsServices } from '../../services/items.services'

export const optionsImages: Array<TOptionGenerico> = [
  { value: 0, label: '1' },
  { value: 1, label: '2' },
  { value: 2, label: '3' },
  { value: 3, label: '4' },
  { value: 4, label: '5' },
  { value: 5, label: '6' },
]

type ModalWoocommerceProps = {
  show: boolean
  onClose: () => void
}

const ModalWoocommerce = (props: ModalWoocommerceProps) => {
  const dispatch = useDispatch()

  const companyCode = useSelector(
    (state: RootState) => state.global.session?.empresa.codigo,
  )
  const ipWebCompany = useSelector(
    (state: RootState) => state.global.session?.empresa.ipWeb,
  )
  const storeCode = useSelector(
    (state: RootState) => state.global.session?.local.codigo,
  )
  const selectedItem = useSelector((state: RootState) => {
    return state.inventarios.items.search.selectedItem
  })

  const [acciones, setAcciones] = useState<any>([])
  const [showConfirm, setShowConfirm] = React.useState(false)
  const [messageConfirm] = React.useState(
    '¿Esta a punto de sincronizar todos los items configurados para Móvil o Web Store , Desea continuar?',
  )
  const validationGroupRef = React.useRef<any>()
  const [mobileWeb, setMobileWeb] = React.useState<boolean>(false)
  const [descriptionItem, setDescriptionItem] = React.useState<string>(
    selectedItem?.itemDescripcion ?? '',
  )
  const [descriptionWoo, setDescriptionWoo] = React.useState<string>('')
  const [tags, setTags] = React.useState<string>('')
  const [webCategory, setCategoriaWeb] = React.useState<string>('')
  const [srcImage, setSrcImage] = React.useState<string>('')
  const [altImage, setAltImage] = React.useState<string>('imagen1')
  const [arrayImages, setArrayImages] =
    React.useState<Array<ItemImages>>(defaultImagenes)
  const [numberImage, setNumberImage] = React.useState<TOptionGenerico>(
    optionsImages[0],
  )
  const [lookupwebCategory, setLookupWebCategories] =
    React.useState<WebCategoryWoo>(null)
  const [validationFormErrors, setValidationFormErrors] = React.useState<
    Array<any>
  >([])
  const [showPopupError, setShowPopupError] = React.useState<boolean>(false)
  const convertToBase64 = useConvertToBase64()

  const buttons = [
    {
      icon: 'fal fa-save',
      id: 'Guardar',
      text: 'Guardar',
      type: ButtonTypes.save,
    },
    {
      icon: 'fal fa-times',
      id: 'Salir',
      text: 'Salir',
      type: ButtonTypes.times,
    },
  ]

  const playLoader = React.useCallback(
    (message: string) => {
      dispatch(changeLoader({ show: true, mensaje: message }))
    },
    [dispatch],
  )

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoader({ show: false, mensaje: '' }))
  }, [dispatch])

  const closeModal = React.useCallback(() => {
    props.onClose()
  }, [props])

  const renderBodyConfirm = () => {
    return <> {messageConfirm} </>
  }

  const onHandleChange = React.useCallback(
    (setState: any) => (event: any) => {
      if (event.event) setState(event.value)
    },
    [],
  )

  const onChangedImage = React.useCallback(
    async (imageIndex: number, eventImage: any) => {
      const imagesArrayState: Array<ItemImages> = structuredClone(arrayImages)
      if (!eventImage) {
        imagesArrayState[imageIndex] = {
          show: false,
          name: '',
          base64: '',
        }
        setArrayImages(imagesArrayState)
      } else if (eventImage?.value?.length > 0) {
        let fileCoded: any = ''
        await convertToBase64(eventImage.value[0])
          .then((data) => {
            fileCoded = data
          })
          .catch((data) => {
            fileCoded = data
          })
        imagesArrayState[imageIndex] = {
          show: true,
          name: eventImage.value[0].name,
          base64: fileCoded,
        }
        setArrayImages(imagesArrayState)
      }
    },
    [arrayImages, convertToBase64],
  )

  const onHandleSave = React.useCallback(async () => {
    let validationFormResult = validationGroupRef.current.instance.validate()
    if (validationFormResult.status === 'pending')
      validationFormResult = await validationFormResult.complete
    if (validationFormResult.isValid === false) {
      const errorList = []
      validationFormResult.brokenRules.map((rule) => {
        errorList.push(rule.message)
      })
      setValidationFormErrors(errorList)
      setShowPopupError(true)
    } else {
      playLoader('Guardando . . .')
      try {
        const imagesItem = structuredClone(arrayImages)
        imagesItem.forEach(function (image: ItemImages) {
          image.name =
            image.name.length > 0 &&
            image.name != 'vacio.jpg' &&
            image.base64.length > 0
              ? `${companyCode}_${crypto.randomUUID()}.jpg`
              : image.name
        })
        const itemWoo: SaveWooItem = {
          infoRegistro: {
            codigo: selectedItem.itemCodigo,
            barras: selectedItem.itemBarras,
            descripcion: descriptionItem,
            observaciones: descriptionWoo,
            grupo: lookupwebCategory?.id ?? -1,
            tipo: selectedItem.itemTipo,
            jerarquia: selectedItem.itemJerarquia,
            codusu: selectedItem.itemCodigoUsuario,
            virtual: selectedItem.itemVirtual ?? 0,
            individual: selectedItem.itemIndividual ?? 0,
            largo: selectedItem.itemLargo,
            ancho: selectedItem.itemAncho,
            alto: selectedItem.itemAlto,
            tags: selectedItem.itemTags === '' ? tags : selectedItem.itemTags,
            pvpIva: selectedItem.itemPVPIVA,
            SUD_CODIGO: selectedItem.subsidio,
            imagenes: imagesItem,
            local: storeCode ?? -1,
          },
        }
        const newItemWoo = await ItemsServices.saveItemWoo(itemWoo)
        stopLoader()
        if (!newItemWoo.error) {
          dispatch(
            addToast({
              id: '',
              autoHide: true,
              content: newItemWoo.message ?? 'Registro Satisfactorio',
              fade: true,
              title: 'Guardar Woo',
              type: ToastTypes.Success,
            }),
          )
        } else {
          dispatch(
            addToast({
              id: '',
              autoHide: true,
              content: newItemWoo.message ?? 'Error al guardar.',
              fade: true,
              title: 'Guardar Woo',
              type: ToastTypes.Danger,
            }),
          )
        }
      } catch (error) {
        stopLoader()
        dispatch(
          addToast({
            id: '',
            autoHide: true,
            content:
              'Error al guardar Woo' +
              (typeof error === 'string' ? error : JSON.stringify(error)),
            fade: true,
            title: 'Guardar Woo',
            type: ToastTypes.Danger,
          }),
        )
      }
    }
  }, [
    arrayImages,
    companyCode,
    descriptionItem,
    descriptionWoo,
    dispatch,
    lookupwebCategory?.id,
    playLoader,
    selectedItem,
    stopLoader,
    storeCode,
    tags,
  ])

  const validateWebCategory = React.useCallback((selected: any) => {
    if (selected?.value?.id > -1) {
      return Promise.resolve()
    } else {
      return Promise.reject(
        'El Categoría Web no se encuentra seleccionado, por favor elija una opción.',
      )
    }
  }, [])

  const validateMainImage = React.useCallback(() => {
    if (arrayImages[0].name !== '') {
      return Promise.resolve()
    } else {
      return Promise.reject('El campo Imagen Principal es obligatorio')
    }
  }, [arrayImages])

  useEffect(() => {
    const indexImage = numberImage.value
    setAltImage(`imagen${indexImage + 1}`)
    if (indexImage === 0) {
      if (arrayImages[0].base64.length > 0) setSrcImage(arrayImages[0].base64)
      else {
        setSrcImage(
          selectedItem?.itemImagen?.length > 0
            ? `${ipWebCompany}/SolucionEmpresarial/SigNum/fotos/${selectedItem.itemImagen}`
            : `${ipWebCompany}/SolucionEmpresarial/SigNum/fotos/vacio.jpg`,
        )
        const imagesArrayState: Array<ItemImages> = structuredClone(arrayImages)
        imagesArrayState[0] = {
          show: false,
          name:
            selectedItem?.itemImagen?.length > 0
              ? selectedItem.itemImagen
              : 'vacio.jpg',
          base64: '',
        }
        setArrayImages(imagesArrayState)
      }
    } else setSrcImage(arrayImages[indexImage].base64)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberImage, arrayImages])

  const returnAction = React.useCallback(
    (metodo: string) => {
      switch (metodo) {
        case TIPOS_BOTONES.BOTON_GUARDAR:
          setShowConfirm(true)
          break
        case TIPOS_BOTONES.BOTON_SALIR:
          closeModal()
          break
        default:
          break
      }
    },
    [closeModal],
  )

  const bodyModalForm = () => {
    return (
      <>
        {showPopupError && (
          <VisualizaError
            titulo="Error en Woocommerce"
            mensaje={'Error al guardar'}
            content={
              <ListErrosValidationForm
                mainMessage={
                  'Antes de guardar la información revise lo siguiente:'
                }
                errorsList={validationFormErrors}
                color={'danger'}
              />
            }
            onOk={() => setShowPopupError(false)}
          />
        )}
        <ValidationGroup
          key={'validationModalWoo'}
          id={'validationModalWoo'}
          ref={validationGroupRef}
        >
          <ValidationSummary
            id="summaryValidatinoMOdalWoo"
            style={{ marginTop: '12px' }}
          />
          <CRow>
            <CustomCol xs="12" md="4">
              <Labeled label="Barras:">
                <TextBox
                  value={selectedItem?.itemBarras ?? ''}
                  showClearButton={true}
                  width="100%"
                  readOnly={true}
                />
              </Labeled>
            </CustomCol>
            <CCol
              xs="12"
              md="3"
              style={{ marginTop: 'auto', marginLeft: 'auto' }}
            >
              <CheckBox
                text="Móviles y Web"
                value={mobileWeb}
                onValueChanged={() => setMobileWeb(!mobileWeb)}
                width="100%"
              />
            </CCol>
          </CRow>
          <CRow>
            <CustomCol xs="12" md="4">
              <Labeled label="Descripción Item:">
                <TextBox
                  value={descriptionItem}
                  onValueChanged={onHandleChange(setDescriptionItem)}
                  showClearButton={true}
                  width="100%"
                >
                  <Validator>
                    <RequiredRule
                      message={'El campo Descripción Item es obligatorio'}
                    />
                  </Validator>
                </TextBox>
              </Labeled>
            </CustomCol>
            <CustomCol xs="12" md="4">
              <Labeled label="Categorías Web:">
                <TextBox
                  value={webCategory}
                  placeholder=""
                  onValueChanged={onHandleChange(setCategoriaWeb)}
                  showClearButton={true}
                  width="100%"
                />
              </Labeled>
            </CustomCol>
            <CCol xs="12" md="3" style={{ marginTop: 'auto' }}>
              <CategoriasWebLookUp
                selected={lookupwebCategory}
                onChanged={(webCategory) => {
                  setLookupWebCategories(webCategory)
                }}
                onChangedOptions={() => {}}
              >
                <Validator>
                  <AsyncRule validationCallback={validateWebCategory} />
                </Validator>
              </CategoriasWebLookUp>
            </CCol>
          </CRow>
          <CRow>
            <CustomCol xs="12" md="4">
              <Labeled label="Imagen Principal:">
                <FIleInput
                  accept={'.jpg'}
                  allowedFileExtensions={['.jpg', '.jpeg']}
                  onChanged={(dataImage: any) =>
                    onChangedImage(numberImage.value, dataImage)
                  }
                  showDownloadDemo={false}
                />
              </Labeled>
            </CustomCol>
            <CustomCol xs="12" md="5">
              <ImageViewer show={true} source={srcImage} alt={altImage} />
            </CustomCol>
            <CCol xs="12" md="3" style={{ marginTop: 'auto' }}>
              {'Medidas Recomendadas: 450px X 450px'}
              <RadioGroup
                name="idStatusItem"
                dataSource={optionsImages}
                value={numberImage}
                layout="vertical"
                displayExpr="label"
                onValueChanged={onHandleChange(setNumberImage)}
                width="100%"
              >
                <Validator>
                  <AsyncRule validationCallback={validateMainImage} />
                  <RequiredRule
                    message={'El campo Imagen Principal es obligatorio'}
                  />
                </Validator>
              </RadioGroup>
            </CCol>
          </CRow>
          <CRow>
            <CustomCol xs="12" md="8">
              <Labeled label="Descripción Woo:">
                <TextArea
                  value={descriptionWoo}
                  onValueChanged={onHandleChange(setDescriptionWoo)}
                  showClearButton={true}
                  width="100%"
                >
                  <Validator>
                    <RequiredRule
                      message={'El campo Descripción Woo es obligatorio'}
                    />
                  </Validator>
                </TextArea>
              </Labeled>
            </CustomCol>
            <CustomCol xs="12" md="4">
              <Labeled label="Tags:">
                <TextBox
                  value={tags}
                  placeholder=""
                  onValueChanged={onHandleChange(setTags)}
                  showClearButton={true}
                  width="100%"
                />
              </Labeled>
            </CustomCol>
          </CRow>
        </ValidationGroup>
      </>
    )
  }

  const confirmAction = () => {
    return (
      <Modalform
        name="confirmarCambios"
        headerTitle={'Confirmar'}
        childrenBody={renderBodyConfirm()}
        childrenFooter={renderFooter()}
        closeOnBackdrop={false}
        show={showConfirm}
        onClose={() => setShowConfirm(!showConfirm)}
        centered={true}
        size="sm"
        key={'confirmarCambios'}
      />
    )
  }

  const renderBody = () => {
    return (
      <>
        {showConfirm === true && confirmAction()}
        <CCard key="cardCuentas">
          <CCardHeader>
            <CRow>
              <CCol className="ms-0 d-flex align-items-start justify-content-start">
                <div
                  key="menuItems"
                  className="btn-toolbar"
                  role="toolbar"
                  aria-label="Toolbar with button groups"
                  style={{ marginBottom: '5px', paddingTop: '-2rem' }}
                >
                  <div
                    key="menuItemsBotones"
                    className="btn-group-lg btn-group btn-sm me-2"
                    role="group"
                    aria-label="First group"
                  >
                    {acciones.map(function (action: any) {
                      const prefixButton = 'buttonsWoocommerce_'
                      const nameButon = action.text
                      const disabled = false
                      return (
                        <CTooltip
                          key={'tool-' + prefixButton + nameButon}
                          placement="top"
                          content={nameButon}
                        >
                          <CButton
                            key={prefixButton + nameButon}
                            name={prefixButton + nameButon}
                            title={action.text}
                            id={prefixButton + nameButon}
                            value={action.text}
                            className="btn btn-secondary sm"
                            variant="ghost"
                            // eslint-disable-next-line no-unused-vars
                            onClick={(ev) => returnAction((ev = action.text))}
                            size="sm"
                            disabled={disabled}
                          >
                            <FontAwesomeIcon
                              size="sm"
                              icon={action.icon ?? faQuestionCircle}
                            />
                          </CButton>
                        </CTooltip>
                      )
                    })}
                  </div>
                </div>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>{bodyModalForm()}</CCardBody>
        </CCard>
      </>
    )
  }

  const renderFooter = () => {
    {
      return (
        <>
          <CButton
            color="primary"
            onClick={() => {
              setShowConfirm(false)
              onHandleSave()
            }}
          >
            {'Aceptar'}
          </CButton>
          <CButton color="secondary" onClick={() => setShowConfirm(false)}>
            {'Cancelar'}
          </CButton>
        </>
      )
    }
  }

  useEffect(() => {
    setAcciones(buttons)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CRow key="rowMain">
      <CCol key="colMain">
        <Modalform
          name="modalWoocommerce"
          key="modalWoo"
          headerTitle={'Woocommerce'}
          childrenBody={renderBody()}
          childrenFooter={<></>}
          closeOnBackdrop={false}
          show={props.show}
          onClose={closeModal}
          size="lg"
        />
      </CCol>
    </CRow>
  )
}

export default React.memo(ModalWoocommerce)
