import React from 'react'
import { CCol, CRow } from '@coreui/react-pro'
import {
  Column,
  DataGrid,
  FilterRow,
  HeaderFilter,
  Paging,
  Pager,
  ColumnChooser
} from 'devextreme-react/data-grid'
import { utilidades } from '../../../../../../helpers/utilidades';

interface TablaProps extends React.PropsWithChildren {
  name: string
  actionDelete: () => void
  exportExcel: () => void
  verificaEdicion: () => void
  pasarSeleccionados: (data) => void,
  registros: Array<any> | [],
  onResetSeleccion: () => void
  onSeleccion: (data) => void
}
export const allowedPageSizes = [5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 250, 500];

const Tabla = (props: TablaProps) => {

  const { pasarSeleccionados, verificaEdicion, registros, onResetSeleccion, onSeleccion } = props;

  const dataGrid = React.useRef<any>();

  const [data, setData] = React.useState<any>([])
  const [showInfo,] = React.useState<boolean>(true);
  const [showNavButtons,] = React.useState<boolean>(true);
  const [showPageSizeSelector,] = React.useState<boolean>(true);
  const [displayMode,] = React.useState<string | 'full' | 'compact'>('full');

  const clearFilter = React.useCallback(() => {
    dataGrid.current.instance.clearSorting();
    dataGrid.current.instance.clearFilter();
  }, []);

  const sortByProperty = React.useCallback((property) => {
    return function (a, b) {
      if (a[property] > b[property]) return 1
      else if (a[property] < b[property]) return -1
      return 0
    }
  }, []);

  const resetSeleccion = React.useCallback(() => {
    onResetSeleccion();
  }, [onResetSeleccion]);

  const llenarData = React.useCallback((data) => {
    clearFilter();
    resetSeleccion();
    let item: any = []
    let contar: number = 0;
    try {
      item = data.map(function (key, i) {
        contar = contar + 1;
        return {
          ...key,
          ORDEN: Number(i)
        }
      })
      item = item.sort(sortByProperty('codigo'))

      setData(item.map(x => utilidades.unfreeze(x)));

    } catch (error) {
      console.error("on llenarData", error)
    }
  }, [clearFilter, sortByProperty, resetSeleccion]);

  const onSelectionChanged = React.useCallback((selectionChanged) => {
    if (data.length > 0) {
      if (selectionChanged['currentSelectedRowKeys'].length > 0) {
        onSeleccion({
          index: selectionChanged['currentSelectedRowKeys'][0]['ORDEN'],
          row: selectionChanged['currentSelectedRowKeys'][0],
          selectedRow: [selectionChanged['currentSelectedRowKeys'][0]]
        })
      }
    }
  }, [onSeleccion, data]);

  const onRowDblClick = React.useCallback((e) => {
    verificaEdicion();
    if (e.data) {
      pasarSeleccionados(e.data);
    }
  }, [verificaEdicion, pasarSeleccionados]);

  React.useEffect(() => {
    if (registros.length > 0) {
      llenarData(registros)
    } else {
      setData([])
    }
  }, [registros, llenarData]);

  return (
    <>
      <CRow>
        <CCol className='d-flex justify-content-end'>{data.length}</CCol>
      </CRow>
      <DataGrid
        ref={dataGrid}
        selection={{ mode: 'single' }}
        hoverStateEnabled={true}
        dataSource={data}
        showBorders={true}
        showRowLines={true}
        onSelectionChanged={onSelectionChanged}
        onRowDblClick={onRowDblClick}
        allowColumnResizing={true}
      >
        <ColumnChooser enabled={true} mode="select" />

        <Paging defaultPageSize={20} />
        <Pager
          visible={data.length > 0 ? true : false}
          allowedPageSizes={allowedPageSizes}
          displayMode={displayMode}
          showPageSizeSelector={showPageSizeSelector}
          showInfo={showInfo}
          showNavigationButtons={showNavButtons} />
        <FilterRow visible={true} />
        <HeaderFilter visible={true} />
        <Column dataType='string' dataField='identificacion' caption='Identificación' width='15%' allowHiding={false} visible={true} />
        <Column dataField='nombres' caption='Nombres' width='35%' allowHiding={false} visible={true} />
        <Column dataField='telefono' caption='Teléfono' width='25%' visible={true} />
        <Column dataField='email' caption='Correo' width='20%' visible={true} />
        <Column dataField='direccion' caption='Dirección' width='20%' allowHiding={true} visible={false} />
        <Column dataField='rcomercial' caption='Nombre Comercial' width='20%' allowHiding={true} visible={false} />
        <Column dataField='ciudad' caption='Ciudad' width='20%' allowHiding={true} visible={false} />
        <Column dataField='descuento' caption='Descuento' width='20%' allowHiding={true} visible={false} />
      </DataGrid>
    </>
  )
}

export default Tabla
