import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { setCurrentExecutingAction } from '../store/tabsReducer'
import { ButtonTypes } from '../../../../../views/componentes/globalMenu/types'
import {
  showLoaderOrganizationChart,
  showLoaderSearch,
} from '../store/searchReducer'
import { showTabLoader } from '../store/editDataReducer'
import { showLoaderPermissionsTab } from '../store/permisosReducer'

function useLoaderPositions() {
  const dispatch = useDispatch()

  const showLoaderTabs = useCallback(
    (
      showLoader: boolean,
      tabKey: string = 'BUSQUEDA',
      isTabPermissions: boolean = false,
      message: string = '',
      button: ButtonTypes = undefined,
    ) => {
      if (tabKey === 'BUSQUEDA')
        dispatch(
          showLoaderSearch({
            show: showLoader ? true : false,
            mensaje: message,
          }),
        )
      else if (tabKey === 'ASIGNACION_ROL_ORGANIGRAMA')
        dispatch(
          showLoaderOrganizationChart({
            show: showLoader ? true : false,
            mensaje: message,
          }),
        )
      else {
        if (isTabPermissions)
          dispatch(
            showLoaderPermissionsTab({
              key: tabKey,
              loader: { show: showLoader ? true : false, mensaje: message },
            }),
          )
        else
          dispatch(
            showTabLoader({
              key: tabKey,
              loader: { show: showLoader ? true : false, mensaje: message },
            }),
          )
      }

      dispatch(
        setCurrentExecutingAction({
          tabKey: tabKey,
          buttonType: button,
        }),
      )
    },
    [dispatch],
  )

  return { showLoaderTabs }
}

export { useLoaderPositions }
