import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../../store/store'
import { FETCH_STATUS } from '../../../../../store/types'
import {
  FiltroBusquedaCiudadesState, CiudadesListado,
} from './types';
import { TConfigSearchState } from '../../store/types'
import {CiudadesService} from "../services/ciudades.services";

const initialState: TConfigSearchState<CiudadesListado> = {
  filter: {
    codigo: '',
    origen: 0,
    nombre: '',
    provinciaCodigo: 0,
    paisCodigo: '',
    inec: '',
    uaf: '',
    codigoOrigen: '',
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  resultados: [],
  selectedItem: null,
  mantenimiento: false,
}

export const fetchProvincias = createAsyncThunk<
  Array<CiudadesListado>,
  FiltroBusquedaCiudadesState
>('city/getAll', async (filtro) => {
  try {
    return await CiudadesService.getAllCiudades(filtro)
  } catch (error) {
    return Promise.reject(error)
  }
});

const searchConfCiudadesSlice = createSlice({
  name: 'searchCiudades',
  initialState: initialState,
  reducers: {
    resetState(state) {
      state = {
        ...initialState,
        filter: {
          ...initialState.filter,
        },
      }
    },
    changeFilter(state, action: PayloadAction<FiltroBusquedaCiudadesState>) {
      state.filter = action.payload
    },
    setCleanResult(state) {
      state.resultados = []
    },
    setResultados(state, action: PayloadAction<Array<CiudadesListado> | []>) {
      state.resultados = action.payload
    },
    toggleDesactivarVarios(state) {
      state.mantenimiento = !state.mantenimiento;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProvincias.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchProvincias.fulfilled, (state, { payload }) => {
      state.resultados = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchProvincias.rejected, (state, { payload }) => {
      state.resultados = []
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
  }
});

export const selectFiltroBusquedaCiudades = (state: RootState) => {
  return state.administracion.catalagos.ciudades.search.filter
}

export const selectResultadosBusquedaCiudades = (state: RootState) => {
  return state.administracion.catalagos.ciudades.search.resultados
}

export const selectBusquedaCiudadesEstado = (state: RootState) => {
  return state.administracion.catalagos.ciudades.search.status
}

export const selectMantenimientoCiudadesEstado = (state: RootState) => {
  return state.administracion.catalagos.ciudades.search.mantenimiento
}

export const { toggleDesactivarVarios, setResultados, resetState, setCleanResult, changeFilter } = searchConfCiudadesSlice.actions
export const searchReducer = searchConfCiudadesSlice.reducer
