import React from 'react'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput'
import { RadioGroup } from 'devextreme-react'
import LocalesLookUp from '../../../../../componentes/localesLookUp'
import BuscarItemPedidoMemoLookUp from '../../../../../ventas/pages/ordenes/pages/ordenPedido/components/busquedaPedidos/BusquedaItemsSelectBox/BuscarItemPedidoMemoLookUp'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/store'
import { changeFilter, setCollapsed } from '../../store/serachReducer'
import { DateUtils, formatoFechasApi } from '../../../../../../helpers/dateUtils'
import { FilterLotesState } from '../../types/types'
import { getLocales } from '../../../../../componentes/localesLookUp/helper/helper'
import { EModulosAcatha } from '../../../../../../store/enumsAplication'
import { CCardBody, CCardHeader, CCollapse, CLink } from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilChevronBottom, cilChevronTop } from '@coreui/icons'
import { getIndexProvider } from '../../../../../shared/helpers/funciones'



export const optionsPrioridad = [
  { value: false, label: 'Descripcion' },
  { value: true, label: 'Barras' },
]

const FiltroBusqueda = () => {
  const dispatch = useDispatch()
  const searchFilter = useSelector((state: RootState) => state.inventarios.lotes.search.filter)
  const collapsed = useSelector((state: RootState) => state.inventarios.lotes.search.colapsed)
  const localSession = useSelector((state: RootState) => state.global.session.local)

  const [prioridadBarras, setPrioridadBarras] = React.useState<{ value: boolean, label: string }>(optionsPrioridad[1])


  const onInitSearch = React.useCallback(async () => {
    const data = { ...initialFilter }
    if (data.initial) {
      const resLocal = await getLocales('Elija una opcion')
      const provider = resLocal.slice(0)
      const index = await getIndexProvider(provider, 'codigo', localSession?.codigo ?? 0)
      if (index > -1) {
        data.local = provider[index]
      } else {
        data.local = provider[0]
      }
      data.items = null
      data.initial = false
      dispatch(changeFilter({
        ...data
      }))
    }
  }, [dispatch, localSession])


  React.useEffect(() => {
    onInitSearch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <CCardHeader
        onClick={() => dispatch(setCollapsed(!collapsed))}
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <strong> {collapsed ? '' : 'Filtro de Búsqueda'} </strong>
        <CLink className="card-header-action">
          <CIcon icon={collapsed ? cilChevronBottom : cilChevronTop} />
        </CLink>
      </CCardHeader>
      <CCollapse visible={collapsed}>
        <CCardBody>
          <RowContainer>
            <CustomCol xs="12" md="10">
              <Labeled label="Items:">
                <RowContainer>
                  <CustomCol md={searchFilter.items === null ? "6" : "8"}>
                    <BuscarItemPedidoMemoLookUp
                      selected={searchFilter.items ?? null}
                      onChanged={(data) => {
                        dispatch(changeFilter({
                          ...searchFilter,
                          items: data
                        }))
                      }}
                      cliente={null}
                      fecha={DateUtils.getCurrentDateAsString(formatoFechasApi)}
                      prioridadBarras={prioridadBarras?.value ?? true}
                      modulo={EModulosAcatha.Lotes}
                      tipoAsociado={null}
                      local={searchFilter?.local !== null && searchFilter?.local !== undefined ? searchFilter?.local : undefined}
                      movimiento={null}
                      allowMoreOption={true}
                      allowClear={true}
                      allowEdit={true}
                      allowAdd={true}
                      codigoPrvAsociado={-1}
                    />
                  </CustomCol>
                  <CustomCol md="4">
                    <RadioGroup
                      name="iOptionsMov"
                      dataSource={optionsPrioridad}
                      value={prioridadBarras}
                      layout="horizontal"
                      displayExpr="label"
                      onValueChange={(data) => {
                        setPrioridadBarras(data)
                      }}
                    />
                  </CustomCol>
                </RowContainer>
              </Labeled>
            </CustomCol>
            <RowContainer>
              <CustomCol xs="12" md="4">
                <Labeled label="Local:">
                  <LocalesLookUp
                    selected={searchFilter?.local ?? null}
                    onChanged={(data) => {
                      dispatch(changeFilter({
                        ...searchFilter,
                        local: data
                      }))
                    }}
                    onChangedOptions={() => { }}
                  />
                </Labeled>
              </CustomCol>
            </RowContainer>
          </RowContainer>
        </CCardBody>
      </CCollapse>
    </>
  )
}

export default React.memo(FiltroBusqueda)

export const initialFilter: FilterLotesState = {
  local: null,
  items: null,
  initial: true
}
