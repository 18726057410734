import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RowContainer from '../../../../views/componentes/rowContainer/rowContainer';
import CustomCol from '../../../../views/componentes/colContainer';
import { AccionMenu, ToastTypes } from '../../../../store/types';
import config from '../../../../config/config';
import {
  CCard, CCardHeader, CCardBody, CButton
} from '@coreui/react-pro';
import { ButtonTypes } from '../../../../views/componentes/globalMenu/types';
import { GlobalMenu } from '../../../../views/componentes/globalMenu/globalMenu';
import FormularioEdicion from "./components/formularioEdicion";
import Buscar from "./components/buscar";
import Tabla from "./components/tabla";
import LoadPanel from 'devextreme-react/load-panel';
import { RootState } from '../../../../store/store';
import { addToast } from '../../../../store/toasterReducer';
import Modalform from '../../../../views/componentes/modalform';
import { changeLoader } from '../../../../store/reducers';
import { ProveedoresService } from '../../services/proveedores.service';

interface IAdminProveedoresModalProps extends React.PropsWithChildren {
  onChanged: (dataProveedor: any) => void;
  onCancel: () => void;
  show: boolean,
}

export const emailPatern = /^([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]{1,}[\\;]?)+){1,}$/

type infoPaginationType = {
  desde: number,
  hasta: number,
  paginaActual: number,
  totalPaginas: number,
  totalRegistros: number
}

const infoPaginationInit = {
  desde: 1,
  hasta: 10,
  paginaActual: 1,
  totalPaginas: 1,
  totalRegistros: 0
}

export const AdminProveedoresModal: React.FC<IAdminProveedoresModalProps> = (props) => {

  const dispatch = useDispatch();
  const loader = useSelector((state: RootState) => state.global.loader);

  const validationGroupRef = React.useRef<any>();

  const { show, onCancel, onChanged } = props;

  const botones = config['menuDefecto'].map(acc => {
    const boton: AccionMenu = {
      nombre: acc.id,
      icon: acc.icon,//as any,
      actionType: acc.type,
      main: false,
      modal: false,
      shortcut: null
    }
    return boton;
  });

  const [acciones,] = React.useState<Array<AccionMenu>>(botones);
  const [currentTab, setCurrentTab] = React.useState<any>("Buscar");
  const [seleccion, setSeleccion] = React.useState<any>({
    index: null,
    row: null,
    selectedRow: null,
  });
  const [infoPagination, setInfoPagination] = React.useState<infoPaginationType>(infoPaginationInit);
  const [funcion, setFuncion] = React.useState<string>("");
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const [confirmUpdate, setConfirmUpdate] = React.useState(false);
  const [messageConfirm, setMessageConfirm] = React.useState("Está seguro que desea realizar estos cambios?");
  const [proveedores, setProveedores] = React.useState<any>([]);
  const [dataSearch, setDataSearch] = React.useState<any>({});
  const [codigoProveedor, setCodigoProveedor] = React.useState<number | null>(null);

  React.useEffect(() => {
    if (show) {
      setCurrentTab("Buscar")
    }
  }, [show]);


  const getButtonDisabled = React.useCallback((button: AccionMenu) => {
    switch (button.actionType) {
      case ButtonTypes.find:
      case ButtonTypes.undo:
        return false;
      case ButtonTypes.new: {
        return currentTab !== "Buscar" || seleccion.index !== null;
      }
      case ButtonTypes.delete:
      case ButtonTypes.edit: {
        return (currentTab !== "Buscar" || seleccion.index === null);
      }
      case ButtonTypes.save: {
        return currentTab === "Buscar";
      }
      default:
        return true;
    }
  }, [currentTab, seleccion]);

  const resetSearched = React.useCallback(() => {
    // setDataSearch({})
    setSeleccion({
      index: null,
      row: null,
      selectedRow: null,
    })
  }, []);

  const returnAction = (boton: AccionMenu) => {

    console.log("returnAction", boton);
    console.log("currentTab", currentTab);
    switch (boton.actionType) {
      case ButtonTypes.find:
        if (currentTab == "Buscar") {
          setFuncion(ButtonTypes.find)
          return false;
        } else if (currentTab === "Editar") {
          resetSearched()
          setCurrentTab("Buscar")
        } else {
          setCurrentTab("Buscar")
          setFuncion(ButtonTypes.undo)
        }
        break;
      case ButtonTypes.new:
        setCurrentTab("Nuevo")
        setFuncion("")
        break;
      case ButtonTypes.save:
        if (currentTab == "Nuevo") {
          setFuncion(ButtonTypes.save)
          return false;
        } else if (currentTab == "Editar") {
          setFuncion(ButtonTypes.save)
          return false;
        } else {
          setToast("No se encuentra cambios que realizar.", "info")
        }
        break;
      case ButtonTypes.undo:
        if (currentTab == "Nuevo") {
          setFuncion(ButtonTypes.undo)
          return false;
        } else if (currentTab == "Editar") {
          setFuncion(ButtonTypes.undo)
          return false;
        }
        break;
      case ButtonTypes.edit:
        if (seleccion['index'] === null) {
          setToast("No se encuentra un registro seleccionado, Por favor seleccione un Item para editarlo.", "info")
          return false;
        } else {
          setActionUpdate()
          return false;
        }
        break;
      case ButtonTypes.delete:
        if (seleccion.index !== null) {
          setActionDelete(seleccion);
          return false;
        } else {
          setToast("No se encuentra seleccionado un proveedor, por favor seleccione un proveedor para eliminarlo.", "info")
          return false;
        }
        break;
      default:
        break;
    }
  }


  const setToast = (texto, type) => {
    let tipo: ToastTypes = ToastTypes.Info;
    if (type === 'warning') {
      tipo = ToastTypes.Warning;
    } else if (type === 'success') {
      tipo = ToastTypes.Success;
    } if (type === 'info') {
      tipo = ToastTypes.Info;
    } if (type === 'danger') {
      tipo = ToastTypes.Danger;
    }
    dispatch(addToast({
      id: '',
      autoHide: 3500,
      title: 'Acatha',
      content: texto,
      fade: true,
      type: tipo
    }));
  }


  const botonesModal = () => {
    return (
      <>
        <RowContainer>
          <CustomCol xs='12' md='4'>
            <GlobalMenu
              acciones={acciones}
              onClick={returnAction}
              getButtonDisabled={getButtonDisabled}
            />
          </CustomCol>
        </RowContainer>
      </>
    );
  }

  const capturaSeleccion = (data) => {
    setSeleccion(data)
  }

  const setActionUpdate = () => {
    setMessageConfirm('Está seguro que desea actualizar este proveedor?')
    setShowConfirm(true)
    setConfirmUpdate(true)
  }

  const setActionDelete = (data) => {
    setMessageConfirm('Está seguro que desea eliminar este proveedor?')
    setShowConfirm(true)
    setConfirmDelete(true)
  }

  const playLoader = () => {
    dispatch(changeLoader({ show: true, mensaje: 'Cargando...' }))
  }

  const stopLoader = () => {
    dispatch(changeLoader({ show: false, mensaje: '' }))
  }

  const deleteItem = async () => {
    setConfirmDelete(false)
    if (seleccion['index'] === null) {
      setToast("No se encuentra seleccionado un proveedor, por favor seleccione un proveedor para eliminarlo.", "warning")
      return false;
    }
    playLoader()
    try {
      const data = await ProveedoresService.eliminarProveedor(seleccion['row']['codigo']);
      setCurrentTab("Buscar")
      setSeleccion({
        index: null,
        row: null,
        selectedRow: null,
      })
      setToast("Registro eliminado.", "success")
      stopLoader()
      return await data;
    } catch (error) {
      console.log('error', error);
      stopLoader()
      setToast(JSON.stringify(error), "danger")
    }

  }

  const renderBodyConfirm = () => {
    return (
      <>
        {messageConfirm}
      </>
    )
  }

  const renderFooter = () => {
    if (currentTab === "Buscar") {
      return (
        <>
          {confirmDelete === true && (
            <CButton color="primary" onClick={() => {
              setShowConfirm(false)
              deleteItem()
              setConfirmDelete(false)
            }}>
              {"Aceptar"}
            </CButton>
          )}
          {confirmUpdate === true && (
            <CButton color="primary" onClick={() => {
              setShowConfirm(false)
              setCodigoProveedor(seleccion['row']['codigo'])
              setCurrentTab("Editar")
              setConfirmUpdate(false)
            }}>
              {"Aceptar"}
            </CButton>
          )}
          <CButton color="secondary" onClick={() => {
            setShowConfirm(!showConfirm)
            setConfirmDelete(false)
            setConfirmUpdate(false)
          }}>Cancelar</CButton>
        </>
      )
    }
  }

  const bodyModal = () => {
    return (
      <>
        <RowContainer>
          <CustomCol xs='12' md='12'>
            <Modalform
              name='confirmarEliminarItem'
              headerTitle={'Confirmar'}
              childrenBody={renderBodyConfirm()}
              childrenFooter={renderFooter()}
              closeOnBackdrop={false}
              show={showConfirm}
              onClose={() => setShowConfirm(!showConfirm)}
              centered={true}
              size="sm"
            />
            {currentTab === "Nuevo" && (
              <FormularioEdicion
                onChanged={(data) => console.log('onChanged', data)}
                codigoProveedor={codigoProveedor}
                currentTab={currentTab}
                funcion={funcion}
                onResetFuncion={() => { setFuncion("") }}
              />
            )}

            {currentTab === "Editar" && (
              <FormularioEdicion
                onChanged={(data) => console.log('onChanged', data)}
                codigoProveedor={codigoProveedor}
                currentTab={currentTab}
                funcion={funcion}
                onResetFuncion={() => { setFuncion("") }}
              />
            )}
            {currentTab === "Buscar" && (
              <Buscar
                funcion={funcion}
                resetFunction={() => setFuncion("")}
                onChanged={(data) => {
                  setDataSearch(data)
                }}
                ejecutarBusqueda={() => setFuncion("Buscar")}
              />
            )}
          </CustomCol>
        </RowContainer>
        <RowContainer className="mt-2">
          <CustomCol xs='12' md='12'>
            {currentTab === "Buscar" && (
              <Tabla
                funcion={funcion}
                name="tableProveedores"
                dataProveedores={proveedores}
                capturaSeleccion={(data) => {
                  console.log("capturaSeleccion(data)")
                  capturaSeleccion(data)
                }}
                filaSeleccionada={seleccion}
                actionDelete={(data) => setActionDelete(data)}
                returnItem={(data) => props.onChanged(data ?? "")}
                pagination={infoPagination}
                dataSearch={dataSearch}
                resetFunction={() => { setFuncion("") }}
                setDataItems={(data: any) => { setProveedores(data) }}
              />
            )}
          </CustomCol>
        </RowContainer>
      </>
    );
  }

  const renderContent = () => {
    return (
      <>
        <CCard>
          <CCardHeader>
            {botonesModal()}
          </CCardHeader>
          <CCardBody>
            <LoadPanel
              shadingColor="rgba(0,0,0,0.4)"
              position={'center'}
              visible={loader.show}
              showIndicator={true}
              shading={true}
              showPane={true}
              message={loader.mensaje}
              closeOnOutsideClick={false}
            />
            {bodyModal()}
          </CCardBody>
        </CCard>
      </>
    );
  }

  return (
    <Modalform
      name="administracionProveedoresPopup"
      headerTitle="Administración de Proveedor"
      show={show}
      childrenBody={renderContent()}
      closeOnBackdrop={false}
      onClose={onCancel}
      centered={true}
      size={'lg'}
    />
  );
}
