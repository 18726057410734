import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../../store/store'
import { FETCH_STATUS } from '../../../../../store/types'
import {
  ActividadesEconomicasListado,
  FiltroBusquedaActivadadesEconomicasState,
} from './types'
import { TConfigSearchState } from '../../store/types'
import { ActividadesEconomicasService } from '../services/actividadesEconomicas.services'

const initialState: TConfigSearchState<ActividadesEconomicasListado> = {
  filter: {
    codigo: '',
    descripcion: '',
    codigoTributario: '',
    codigoPais: '',
    estado: 1,
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  resultados: [],
  selectedItem: null,
}

export const fetchActividadesEconomicas = createAsyncThunk<
  Array<ActividadesEconomicasListado>,
  FiltroBusquedaActivadadesEconomicasState
>('businessType/getAll', async (filtro) => {
  try {
    return await ActividadesEconomicasService.getAllActividadesEconomicas(filtro)
  } catch (error) {
    return Promise.reject(error)
  }
});

const searchConfActividadesEconomicasSlice = createSlice({
  name: 'searchActividadesEconomicas',
  initialState: initialState,
  reducers: {
    resetState(state) {
      state = {
        ...initialState,
        filter: {
          ...initialState.filter,
        },
      }
    },
    changeFilter(state, action: PayloadAction<FiltroBusquedaActivadadesEconomicasState>) {
      state.filter = action.payload
    },
    setCleanResult(state) {
      state.resultados = []
    },
    setResultados(state, action: PayloadAction<Array<ActividadesEconomicasListado> | []>) {
      state.resultados = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchActividadesEconomicas.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
        state.error = false
      }
    })
    builder.addCase(fetchActividadesEconomicas.fulfilled, (state, { payload }: {payload: any}) => {
      state.resultados = payload.auto
      state.status = FETCH_STATUS.SUCCESS
      state.error = payload.error
      state.mensaje = payload.message
    })
    builder.addCase(fetchActividadesEconomicas.rejected, (state, { payload }: {payload: any}) => {
      state.resultados = []
      state.status = FETCH_STATUS.FAILED
      state.error = payload.error
      state.mensaje = payload.message
    })
  }
});

export const selectFiltroBusquedaActividadesEconomicas = (state: RootState) => {
  return state.administracion.catalagos.actividadesEconomicas.search.filter
}

export const selectResultadosBusquedaActividadesEconomicas = (state: RootState) => {
  return state.administracion.catalagos.actividadesEconomicas.search.resultados
}

export const selectBusquedaActividadesEconomicasEstado = (state: RootState) => {
  return state.administracion.catalagos.actividadesEconomicas.search.status
}

export const { setResultados, resetState, setCleanResult, changeFilter } = searchConfActividadesEconomicasSlice.actions
export const searchReducer = searchConfActividadesEconomicasSlice.reducer
