import { TabsState } from '../../../../../store/genericTabTypes'
import {
  CustomDictionary,
  FETCH_STATUS,
  LoaderInfo,
} from '../../../../../store/types'
import { TOptionGenerico } from '../../../../clientes/pages/clientes/types/types'

export type GeneralState = {
  modulo: string
  currentAction: string
  currentFunction: string
  aplicacion: string
  loader: LoaderInfo
  typesNotification: Array<typeNotification> | null
}

export type NotificationList = {
  codigo: number
  descripcion: string
  fechaInicio: string
  fechaFin: string
  titulo: string
  imagen: string
  permanente: number
  tipoId: TOptionGenerico | null
  mostrarPopup: number
  imagenCodificada: string
  defaultContent?: string
}

export type NotificationListResult = {
  codigo: number
  descripcion: string
  fechaInicio: string
  fechaFin: string
  titulo: string
  imagen: string
  permanente: number
  tipoId: number
  mostratPopup: number
}

export type SaveNotification = {
  infoRegistro: {
    codigo: number
    descripcion: string
    fechaInicio: string
    fechaFin: string
    titulo: string
    imagen: string
    permanente: number
    tipoId: number
    mostratPopup: number
    imagenCodificada: string
  }
}

export type NotificationState = {
  general: GeneralState
  tabs: TabsState<NotificationList>
  editData: CustomDictionary<NotificationList>
  search: SearchState<any>
}

export type SearchState<T> = {
  filter: FilterNotificationState
  status: FETCH_STATUS
  mensaje: string
  resultados: Array<T>
}

export type FilterNotificationState = {
  descripcion: TOptionGenerico | null
}

export type typeNotification = {
  value: number
  label: string
}

export const optionsSearch = [
  { value: 0, label: 'Todos' },
  { value: 1, label: 'Activos' },
]
