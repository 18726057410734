import { RequestHelper } from "../../../../helpers/requestHelper";

export const empresasService = { getEmpresas };

async function getEmpresas(label: string): Promise<any> {
  try {
    const data: any = {
      mensaje: label
    };
    const res = await RequestHelper.getAll<any>('clientes/general', 'getCompany', "", data);
    return res;
  } catch (error) {
    return error;
  }
}

