import React, { useCallback, useEffect } from 'react'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput'
import {
  Button,
  DataGrid,
  DateBox,
  NumberBox,
  RadioGroup,
  SelectBox,
  TextBox,
  ValidationGroup,
  Validator,
} from 'devextreme-react'
import {
  DetailItem,
  ItemTransferencia,
  LotesItem,
  Progreso,
  SaveTransfer,
  SeriesItem,
  TransferEditData,
  Usuario,
} from '../../types/types'
import { ButtonTypes } from '../../../../../../../views/componentes/globalMenu/types'
import {
  clearButtonClick,
  setNameTab,
  setCurrentExecutingAction,
  setButtons,
} from '../../store/tabsReducer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import { setCurrentFunction } from '../../store/generalReducer'
import {
  setBackupTransfer,
  setDataToEdit,
  setDatosEdicion,
  setDestinationStore,
  setDestintionProject,
  setOriginProject,
  setOriginStore,
} from '../../store/editDataReducer'
import Dialog from '../../../../../../../views/componentes/librerias/bootstrap-dialog'
import { CButton, CCol, CRow } from '@coreui/react-pro'
import { TabState } from '../../../../../../../store/genericTabTypes'
import {
  AsyncRule,
  Column,
  Editing,
  Export,
  Pager,
  Paging,
  RequiredRule,
  Button as DatagridButton,
} from 'devextreme-react/data-grid'
import { getAllowedPageSizes } from '../../../../../../../helpers/Helper'
import ProjectsLookUp from '../../../../../../../containers/component/sharedProviders/projets/projectsLookUp/projectsLookUp'
import { Project } from '../../../../../../shared/types/types'
import { LocalOption } from '../../../../../../componentes/localesLookUp/store/type'
import { AdminModalLocales } from '../../../../../../componentes/modalAdminLocales/modalAdminLocales'
import ModalUsuarios from '../../../../../../componentes/modalUsuarios'
import { ETipoAsociado, ToastTypes } from '../../../../../../../store/types'
import BuscarItemPedidoMemoLookUp from '../../../../../../ventas/pages/ordenes/pages/ordenPedido/components/busquedaPedidos/BusquedaItemsSelectBox/BuscarItemPedidoMemoLookUp'
import {
  DateUtils,
  formatoFechasApi,
} from '../../../../../../../helpers/dateUtils'
import { ItemPedido } from '../../../../../../ventas/pages/ordenes/pages/ordenPedido/type/types'
import { EModulosAcatha } from '../../../../../../../store/enumsAplication'
import { optionsSearch } from '../../../../kardex/components/buscar/filtroBusqueda'
import { useSetToast } from '../../../../../../../hooks/useGlobalHooks'
import ItemsCountIndicator from '../../../../../../componentes/indicadorNroRegistros'
import { TransfersServices } from '../../services/transferencias.services'
import VisualizaError from '../../../../../../ventas/pages/shared/visualizaError/visualizaError'
import ListErrosValidationForm from '../../../../../../componentes/listadoErroresValidacionForm'
import { StatesEdition } from '../../../../../../../store/enums'
import { defaultDataTranfers, emptyItem, newLocal } from '../..'
import { ModalIngresoSeries } from '../../../../../../componentes/modalIngresoSerie'
import {
  ModalIngresoLotes,
  TItemSeleccionado,
} from '../../../../../../componentes/modalIngresoLotes/modalIngresoLotes'
import BlockUi from '../../../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../../../views/componentes/loadingindicator/loadingindicator'
import { isMobile } from 'react-device-detect'
import { useLoaderTransfers } from '../../customHooks'

interface INewProps extends React.PropsWithChildren {
  tab: TabState<TransferEditData>
  tabId: string
}

const Nuevo: React.FunctionComponent<INewProps> = (props) => {
  const { tabId } = props
  const dispatch = useDispatch()
  const setToastMessage = useSetToast()
  const { playLoader, stopLoader } = useLoaderTransfers()

  const currentAction = useSelector(
    (state: RootState) =>
      state.inventarios.movimientos.transferencias.general.currentFunction,
  )
  const newTransfer = useSelector((state: RootState) => {
    return state.inventarios.movimientos.transferencias.editData[tabId]
  })
  const tabs = useSelector((state: RootState) => {
    return state.inventarios.movimientos.transferencias.tabs
  })
  const store = useSelector((state: RootState) => {
    return state.global.session.local
  })
  const selectedTransfer = useSelector(
    (state: RootState) =>
      state.inventarios.movimientos.transferencias.search.selectedTransfer,
  )
  const tabStatus = useSelector(
    (state: RootState) =>
      state.inventarios.movimientos.transferencias.tabs.tabs[tabId].editStatus,
  )
  const inventarios = useSelector(
    (state: RootState) => state.global.session.empresa.inventarios,
  )
  const loader = useSelector((state: RootState) => {
    return state.inventarios.movimientos.transferencias.editData[tabId].loader
  })

  const [confirmTransferAll, setConfirmTransferAll] =
    React.useState<boolean>(false)
  const [transferAll, setTransferAll] = React.useState<boolean>(false)
  const [loadOriDes, setLoadOriDes] = React.useState<boolean>(false)
  const [loadPurchase, setLoadPurcahse] = React.useState<boolean>(false)
  const [storeOrigin, setStoreOrigin] = React.useState<boolean>(false)
  const [confirmChangeOriginStore, setConfirmChangeOriginStore] =
    React.useState<{ show: boolean; store: LocalOption }>({
      show: false,
      store: null,
    })
  const [validationFormErrors, setValidationFormErrors] = React.useState<
    Array<any>
  >([])
  const [showPopupError, setShowPopupError] = React.useState<boolean>(false)
  const [showSeries, setShowSeries] = React.useState<boolean>(false)
  const [showLotes, setShowLotes] = React.useState<boolean>(false)
  const [itemsLotes, setItemsLotes] = React.useState<TItemSeleccionado[]>([])
  const [selectedtemLote, setSelectedtemLote] =
    React.useState<TItemSeleccionado>(null)
  const [series, setSeries] = React.useState<any[]>([])
  const [confirmLoad, setConfirmLoad] = React.useState<boolean>(false)
  const [confirmLoadPurcahse, setConfirmLoadPurchase] =
    React.useState<boolean>(false)
  const [showUsers, setShowUsers] = React.useState<boolean>(false)
  const [showModalStores, setShowModalStores] = React.useState<{
    show: boolean
    store: string
  }>({ show: false, store: '' })
  const [searchFrom, setSearchFrom] = React.useState(optionsSearch[0])
  const [rowIndex, setRowIndex] = React.useState<number>(-1)
  const validationGroupRef = React.useRef<any>()
  const dialogRef = React.useRef<any>(null)
  const selectRef = React.useRef<any>()
  const dataGrid = React.useRef<any>()

  useEffect(() => {
    if (storeOrigin === true) {
      setStoreOrigin(false)
      onSetOriginStore(confirmChangeOriginStore.store)
      dispatch(
        setDatosEdicion({
          key: tabId,
          transferencia: {
            ...newTransfer,
            detalles: [emptyItem],
          },
        }),
      )
      setConfirmChangeOriginStore({ show: false, store: null })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeOrigin])

  const onConfirmChangeStore = useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: 'Esta intentando cambiar el local origen, si continua se borrará el detalle ingresado, Desea continuar?',
      actions: [
        Dialog.Action(
          <span>
            <u>A</u>ceptar
          </span>,
          (dialog) => {
            dialog.hide()
            setStoreOrigin(true)
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setStoreOrigin(false)
    setConfirmChangeOriginStore({ ...confirmChangeOriginStore, show: false })
    return
  }, [confirmChangeOriginStore])

  useEffect(() => {
    if (confirmChangeOriginStore.show) onConfirmChangeStore()
  }, [confirmChangeOriginStore, onConfirmChangeStore])

  const onLoadMovement = useCallback(async () => {
    try {
      playLoader(
        tabId,
        `Cargando todos los ítems del movimiento ${newTransfer.movimiento} . . .`,
        ButtonTypes.find,
      )
      const itemsMovement = await TransfersServices.getAllItemsMovement(
        newTransfer.movimiento,
      )
      stopLoader(tabId)
      if (!itemsMovement.error && itemsMovement.auto) {
        if (itemsMovement.auto.origen > 0) {
          const indexOrigin = newTransfer.origenProyecto.locales.findIndex(
            (store) => {
              return store.codigo === itemsMovement.auto.origen
            },
          )
          if (indexOrigin > -1)
            dispatch(
              setOriginStore({
                key: tabId,
                store: newTransfer.origenProyecto.locales[indexOrigin],
              }),
            )
        }
        if (itemsMovement.auto.destino > 0) {
          const indexDestination =
            newTransfer.destinoProyecto.locales.findIndex((store) => {
              return store.codigo === itemsMovement.auto.destino
            })
          if (indexDestination > -1)
            dispatch(
              setDestinationStore({
                key: tabId,
                store: newTransfer.destinoProyecto.locales[indexDestination],
              }),
            )
        }
        dispatch(
          setDatosEdicion({
            key: tabId,
            transferencia: {
              ...newTransfer,
              descripcion: itemsMovement.auto.descripcion ?? '',
              detalles: onParseItems(itemsMovement.auto.detalle),
            },
          }),
        )
      }
      setToastMessage(
        `Cargar Items Movimiento`,
        itemsMovement.message,
        itemsMovement.error ? ToastTypes.Warning : ToastTypes.Success,
      )
    } catch (error) {
      stopLoader(tabId)
      dispatch(
        setCurrentExecutingAction({
          tabKey: tabId,
          buttonType: undefined,
        }),
      )
      setToastMessage(`Cargar Items Movimiento`, error, ToastTypes.Danger)
    }
  }, [dispatch, newTransfer, playLoader, setToastMessage, stopLoader, tabId])

  const onLoadPurchase = useCallback(async () => {
    try {
      playLoader(
        tabId,
        `Cargando todos los ítems de la compra ${newTransfer.compra} . . .`,
        ButtonTypes.find,
      )
      const itemsBuy = await TransfersServices.getAllItemsPurchase(
        newTransfer.compra,
        newTransfer.origenLocal.codigo,
        newTransfer.destinoLocal.codigo,
      )
      stopLoader(tabId)
      if (!itemsBuy.error && itemsBuy.auto)
        dispatch(
          setDatosEdicion({
            key: tabId,
            transferencia: {
              ...newTransfer,
              detalles: onParseItems(itemsBuy.auto),
            },
          }),
        )
      setToastMessage(
        `Cargar Items Compra`,
        itemsBuy.message,
        itemsBuy.error ? ToastTypes.Warning : ToastTypes.Success,
      )
    } catch (error) {
      stopLoader(tabId)
      setToastMessage(`Cargar Items Compra`, error, ToastTypes.Danger)
    }
  }, [dispatch, newTransfer, playLoader, setToastMessage, stopLoader, tabId])

  useEffect(() => {
    if (loadPurchase === true) {
      setLoadPurcahse(false)
      onLoadPurchase()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadPurchase])

  const onConfirmLoadBuy = useCallback(
    (isStoresSelected: boolean) => {
      dialogRef.current.show({
        title: 'Ácatha',
        body: isStoresSelected
          ? `Esta seguro que desea cargar todos los items de la compra ${newTransfer.compra}?`
          : 'Elija los locales Origen - Destino para poder cargar información de la compra',
        actions: [
          Dialog.Action(
            <span>
              <u>A</u>ceptar
            </span>,
            (dialog) => {
              dialog.hide()
              if (isStoresSelected) setLoadPurcahse(true)
            },
            'btn-success',
            'n',
          ),
          Dialog.Action(
            <span>
              <u>C</u>ancelar
            </span>,
            (dialog) => {
              dialog.hide()
            },
            'btn-danger',
            'c',
          ),
        ],
        bsSize: 'small',
        onHide: (dialog) => {
          dialog.hide()
        },
      })
      setLoadPurcahse(false)
      setConfirmLoadPurchase(false)
      return
    },
    [newTransfer.compra],
  )

  useEffect(() => {
    if (confirmLoadPurcahse)
      onConfirmLoadBuy(
        newTransfer.origenLocal.codigo > 0 &&
          newTransfer.destinoLocal.codigo > 0,
      )
  }, [
    confirmLoadPurcahse,
    newTransfer.destinoLocal?.codigo,
    newTransfer.origenLocal?.codigo,
    onConfirmLoadBuy,
  ])

  const onLoadOriginDestination = useCallback(async () => {
    try {
      playLoader(
        tabId,
        `Cargando todos los ítems del local ${newTransfer.origenLocal.nombre} -  ${newTransfer.destinoLocal.nombre} . . .`,
        ButtonTypes.find,
      )
      const itemsOriginDestination =
        await TransfersServices.getAllItemsOriginDestination(
          newTransfer.origenLocal.codigo,
          newTransfer.destinoLocal.codigo,
        )
      stopLoader(tabId)
      if (!itemsOriginDestination.error && itemsOriginDestination.auto)
        dispatch(
          setDatosEdicion({
            key: tabId,
            transferencia: {
              ...newTransfer,
              detalles: onParseItems(itemsOriginDestination.auto),
            },
          }),
        )
      setToastMessage(
        `Cargar Items Local ${newTransfer.origenLocal.nombre}`,
        itemsOriginDestination.message,
        itemsOriginDestination.error ? ToastTypes.Warning : ToastTypes.Success,
      )
    } catch (error) {
      stopLoader(tabId)
      setToastMessage(
        `Cargar Items Local ${newTransfer.origenLocal.nombre}`,
        error,
        ToastTypes.Danger,
      )
    }
  }, [dispatch, newTransfer, playLoader, setToastMessage, stopLoader, tabId])

  useEffect(() => {
    if (loadOriDes === true) {
      setLoadOriDes(false)
      onLoadOriginDestination()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOriDes])

  const onConfirmLoad = useCallback(
    (isStoresSelected: boolean) => {
      dialogRef.current.show({
        title: 'Ácatha',
        body: isStoresSelected
          ? `Esta seguro que desea cargar todos los items de los locales ${newTransfer.origenLocal.nombre} -  ${newTransfer.destinoLocal.nombre}?`
          : 'Elija los locales Origen - Destino para poder cargar información',
        actions: [
          Dialog.Action(
            <span>
              <u>A</u>ceptar
            </span>,
            (dialog) => {
              dialog.hide()
              if (isStoresSelected) setLoadOriDes(true)
            },
            'btn-success',
            'n',
          ),
          Dialog.Action(
            <span>
              <u>C</u>ancelar
            </span>,
            (dialog) => {
              dialog.hide()
            },
            'btn-danger',
            'c',
          ),
        ],
        bsSize: 'small',
        onHide: (dialog) => {
          dialog.hide()
        },
      })
      setLoadOriDes(false)
      setConfirmLoad(false)
      return
    },
    [newTransfer.destinoLocal?.nombre, newTransfer.origenLocal?.nombre],
  )

  useEffect(() => {
    if (confirmLoad)
      onConfirmLoad(
        newTransfer.origenLocal.codigo > 0 &&
          newTransfer.destinoLocal.codigo > 0,
      )
  }, [
    confirmLoad,
    newTransfer.destinoLocal?.codigo,
    newTransfer.origenLocal?.codigo,
    onConfirmLoad,
  ])

  const onHandleTransferAll = useCallback(async () => {
    try {
      playLoader(
        tabId,
        `Cargando todos los ítems del local ${
          newTransfer.origenLocal?.codigo > 0
            ? newTransfer.origenLocal?.nombre
            : ''
        } . . .`,
        ButtonTypes.find,
      )
      const allItemsStore = await TransfersServices.getAllItemsStore(
        newTransfer.origenLocal?.codigo,
        DateUtils.pickersDateToApiDate(newTransfer.fecha),
      )
      stopLoader(tabId)
      if (!allItemsStore.error && allItemsStore.auto)
        dispatch(
          setDatosEdicion({
            key: tabId,
            transferencia: {
              ...newTransfer,
              detalles: onParseItems(allItemsStore.auto),
            },
          }),
        )

      setToastMessage(
        `Cargar Items Local ${newTransfer.origenLocal?.nombre}`,
        allItemsStore.message,
        allItemsStore.error ? ToastTypes.Warning : ToastTypes.Success,
      )
    } catch (error) {
      stopLoader(tabId)
      setToastMessage(
        `Cargar Items Local ${newTransfer.origenLocal?.nombre}`,
        error,
        ToastTypes.Danger,
      )
    }
  }, [dispatch, newTransfer, playLoader, setToastMessage, stopLoader, tabId])

  useEffect(() => {
    if (transferAll === true) {
      setTransferAll(false)
      onHandleTransferAll()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transferAll])

  const onConfirmTransferAll = useCallback(
    (isOriginSelected: boolean) => {
      dialogRef.current.show({
        title: 'Ácatha',
        body: `Esta seguro que desea cargar todos los items del local ${
          isOriginSelected ? newTransfer.origenLocal.nombre : ''
        } ?`,
        actions: [
          Dialog.Action(
            <span>
              <u>A</u>ceptar
            </span>,
            (dialog) => {
              dialog.hide()
              setTransferAll(true)
            },
            'btn-success',
            'n',
          ),
          Dialog.Action(
            <span>
              <u>C</u>ancelar
            </span>,
            (dialog) => {
              dialog.hide()
            },
            'btn-danger',
            'c',
          ),
        ],
        bsSize: 'small',
        onHide: (dialog) => {
          dialog.hide()
        },
      })
      setTransferAll(false)
      setConfirmTransferAll(false)
      return
    },
    [newTransfer.origenLocal],
  )

  useEffect(() => {
    if (confirmTransferAll)
      onConfirmTransferAll(newTransfer.origenLocal?.codigo > 0)
  }, [
    confirmTransferAll,
    newTransfer.origenLocal?.codigo,
    onConfirmTransferAll,
  ])

  const onHandleChanged = useCallback(
    (event: any, valueToUpdate: string) => {
      if (event.event) {
        dispatch(
          setDatosEdicion({
            key: tabId,
            transferencia: {
              ...newTransfer,
              [valueToUpdate]: event.value,
            },
          }),
        )
      }
    },
    [dispatch, newTransfer, tabId],
  )

  const onChangedOriginProject = useCallback(
    (project: Project) => {
      dispatch(
        setOriginProject({
          key: tabId,
          origin: project,
        }),
      )
      const codeToCompare =
        tabStatus === StatesEdition.save || tabStatus === StatesEdition.edit
          ? newTransfer.origenLocal?.codigo
          : store?.codigo
      const indexStore = project.locales.findIndex((option: Project) => {
        return option.codigo === codeToCompare
      })
      dispatch(
        setOriginStore({
          key: tabId,
          store: project.locales[indexStore >= 0 ? indexStore : 0],
        }),
      )
    },
    [
      dispatch,
      newTransfer.origenLocal?.codigo,
      store?.codigo,
      tabId,
      tabStatus,
    ],
  )

  const onSetOriginStore = useCallback(
    (local: LocalOption) => {
      const indexStore = newTransfer.origenProyecto?.locales.findIndex(
        (store) => {
          return store.codigo === local.codigo
        },
      )
      dispatch(
        setOriginStore({
          key: tabId,
          store:
            newTransfer.origenProyecto?.locales[
              indexStore > 0 ? indexStore : 0
            ],
        }),
      )
    },
    [dispatch, newTransfer.origenProyecto?.locales, tabId],
  )

  const onChangedOriginStore = useCallback(
    (local: LocalOption) => {
      if (
        local.codigo > 0 &&
        local.codigo === newTransfer.destinoLocal.codigo
      ) {
        setToastMessage(
          `Cargar Items Movimiento`,
          'El local de Origen no puede ser el mismo que el Destino',
          ToastTypes.Warning,
        )
        return false
      }
      if (newTransfer.detalles.length > 0)
        setConfirmChangeOriginStore({ show: true, store: local })
      else onSetOriginStore(local)
    },
    [
      newTransfer.detalles.length,
      newTransfer.destinoLocal?.codigo,
      onSetOriginStore,
      setToastMessage,
    ],
  )

  const onChangedDestinationProject = useCallback(
    (project: Project) => {
      dispatch(
        setDestintionProject({
          key: tabId,
          destination: project,
        }),
      )
      let indexStore = -1
      if (
        tabStatus === StatesEdition.save ||
        tabStatus === StatesEdition.edit
      ) {
        indexStore = project.locales.findIndex((option: Project) => {
          return option.codigo === newTransfer.destinoLocal?.codigo
        })
      }
      dispatch(
        setDestinationStore({
          key: tabId,
          store: project.locales[indexStore >= 0 ? indexStore : 0],
        }),
      )
    },
    [dispatch, newTransfer.destinoLocal?.codigo, tabId, tabStatus],
  )

  const onChangedDestinationStore = useCallback(
    (local: LocalOption) => {
      if (local.codigo > 0 && local.codigo === newTransfer.origenLocal.codigo) {
        setToastMessage(
          `Cargar Items Movimiento`,
          'El local de Destino no puede ser el mismo que el Origen',
          ToastTypes.Warning,
        )
        return false
      }
      const indexStore = newTransfer.destinoProyecto?.locales.findIndex(
        (store) => {
          return store.codigo === local.codigo
        },
      )
      dispatch(
        setDestinationStore({
          key: tabId,
          store:
            newTransfer.destinoProyecto?.locales[
              indexStore > 0 ? indexStore : 0
            ],
        }),
      )
    },
    [
      dispatch,
      newTransfer.destinoProyecto?.locales,
      newTransfer.origenLocal?.codigo,
      setToastMessage,
      tabId,
    ],
  )

  const onChangedItem = useCallback(
    (item: ItemPedido) => {
      if (newTransfer.origenLocal?.kardex >= 0 && item?.existencia <= 0) {
        setToastMessage(
          'Agregar Ítem',
          `El ítem ${item?.codigoBarras} no tiene existencia disponible en este momento\nSeleccione otro ítem de la lista`,
          ToastTypes.Warning,
        )
        return
      }
      dispatch(
        setDatosEdicion({
          key: tabId,
          transferencia: {
            ...newTransfer,
            item: item,
            existencia: item?.existencia ?? null,
            unidad: item?.unidadDescripcion ?? '',
          },
        }),
      )
    },
    [dispatch, newTransfer, setToastMessage, tabId],
  )

  const onRemoveItem = useCallback(
    (rowIndex: number) => {
      if (!newTransfer.detalles[rowIndex].codigo) return false
      const currentDataGrid = structuredClone(newTransfer.detalles)
      currentDataGrid.splice(rowIndex, 1)
      dispatch(
        setDatosEdicion({
          key: tabId,
          transferencia: {
            ...newTransfer,
            detalles: currentDataGrid,
          },
        }),
      )
      setRowIndex(-1)
    },
    [dispatch, newTransfer, tabId],
  )

  const onAddItemsLotes = useCallback(
    (items: ItemTransferencia[]): TItemSeleccionado[] => {
      const detailsItems: TItemSeleccionado[] = []
      items.forEach((item: ItemTransferencia) => {
        detailsItems.push({
          codigo: item.codigo,
          impuesto: item.codigoImp ?? 0,
          codigoUsuario: item.codigoUsuario ?? '',
          barras: item.barras ?? '',
          unidadDescripcion: item.unidadDescripcion ?? '',
          oCostos: 0,
          oCostosIVA: 0,
          descripcion: item.descripcion ?? '',
          cantidad: item.cantidad ?? 0,
          lotes: item.lotes ?? 0,
          pvp: item.pvp ?? 0,
          pvd: item.pvd ?? 0,
          precio: 0,
          numeroSerie: 0,
          fracion: item.fraccion ?? 0,
          valorDescripcion: item.cantidad * item.costo ?? 0,
          valor: item.costo,
          cantidadMovimiento: item.cantidad ?? 0,
        })
      })
      setItemsLotes(detailsItems)
      return detailsItems
    },
    [],
  )

  const onAddItem = useCallback(
    (newItem: ItemPedido, addNewRow: boolean = true) => {
      let message: string = ''
      if (newItem === null) message = 'Seleccione un ítem antes de agregarlo'
      else if (newTransfer.cantidad <= 0 && addNewRow)
        message = 'La cantidad no puede ser menor o igual a CERO (0)'
      else if (
        newTransfer.origenLocal?.kardex === 0 &&
        (newTransfer.cantidad > newTransfer.existencia ||
          newItem.existencia <= 0)
      )
        message = 'La cantidad no puede ser mayor a la existencia'
      const indexItem = newTransfer.detalles.findIndex(
        (item) => item.codigo === newItem?.codigo,
      )
      if (indexItem > -1)
        message =
          'El ítem seleccionado ya existe en la lista de ítems a transferir, si desea modificar haga click sobre la cantidad del ítem y cambiela'

      if (message !== '') {
        setToastMessage('Agregar Ítem', message, ToastTypes.Warning)
        return
      }
      // if (newItem.jerarquia === '1')
      // impelmentar funcion seleccionarItemConjunto -> FLEX
      setSeries([])
      let series = []
      if (newItem.numeroSerie === 1) {
        series = Array(addNewRow ? newTransfer.cantidad : 1).fill({
          codigo: newItem.codigo ?? 0,
          serie: '',
          min: '',
          barras: newItem.codigoBarras ?? '',
          descripcion: newItem.descripcion ?? '',
        })
        setSeries(series)
        setShowSeries(true)
      }

      const newDataGrid = structuredClone(newTransfer.detalles)
      const newDetail: ItemTransferencia = {
        codigoTrd: 0,
        codigo: newItem.codigo ?? 0,
        codigoUsuario: newItem.codigoInterno ?? '',
        barras: newItem.codigoBarras ?? '',
        descripcion: newItem.descripcion ?? '',
        unidadDescripcion: newItem.unidadDescripcion ?? '',
        cantidad: addNewRow ? newTransfer.cantidad : 1,
        referencia: addNewRow ? newTransfer.referencia : null,
        existencia: newItem.existencia ?? 0,
        numSerie: newItem.numeroSerie ?? 0,
        lotes: parseInt(newItem.lotes) ?? 0,
        pvd: newItem.pvdiva ?? 0,
        pvp: newItem.pvpiva ?? 0,
        fraccion: newItem.fraccion ?? 0,
        progreso: Progreso.INGRESADO,
        series: series,
        codigoImp: newItem.codigoImpuesto ?? 0,
        costo: newItem.costo ?? 0,
        lotesItem: [],
      }

      if (newDataGrid.length > 0 && newDataGrid[newDataGrid.length - 1]?.codigo)
        newDataGrid.push(newDetail)
      else newDataGrid[newDataGrid.length - 1] = newDetail

      newDataGrid.push(emptyItem)

      if (newDetail.lotes === 1) {
        const itemsLotes = onAddItemsLotes(newDataGrid)
        setSelectedtemLote(itemsLotes[itemsLotes.length - 1])
        setShowLotes(true)
      }

      dispatch(
        setDatosEdicion({
          key: tabId,
          transferencia: {
            ...newTransfer,
            detalles: newDataGrid,
            item: addNewRow ? null : newTransfer.item,
            existencia: null,
            unidad: '',
            referencia: null,
            cantidad: 0,
          },
        }),
      )
    },
    [dispatch, newTransfer, onAddItemsLotes, setToastMessage, tabId],
  )

  const updateCellValue = useCallback(
    (newData, value, currentRowData, cellToUpdate: string) => {
      if (value && currentRowData) {
        if (value <= 0 && typeof value === 'number') {
          setToastMessage(
            `Actualizar Valor ${cellToUpdate}`,
            `El valor de ${cellToUpdate} debe ser mayor a cero 0`,
            ToastTypes.Warning,
          )
          return false
        }
        newData = structuredClone(newTransfer.detalles)
        const indexItemToUpdate = newData.findIndex(
          (item: ItemTransferencia) => {
            return item.codigo === currentRowData.codigo
          },
        )
        if (cellToUpdate === 'cantidad' && currentRowData.numSerie === 1) {
          let series = []
          series = Array(value).fill({
            codigo: currentRowData.codigo ?? 0,
            serie: '',
            min: '',
            barras: currentRowData.barras ?? '',
            descripcion: currentRowData.descripcion ?? '',
          })
          currentRowData.series = series
          setSeries(series)
          setShowSeries(true)
        }
        currentRowData[cellToUpdate] = value
        newData[indexItemToUpdate] = currentRowData
        dispatch(
          setDatosEdicion({
            key: tabId,
            transferencia: {
              ...newTransfer,
              detalles: newData,
            },
          }),
        )
      }
    },
    [dispatch, newTransfer, setToastMessage, tabId],
  )

  const validateStores = React.useCallback((selected: any, store: string) => {
    if (selected?.value?.codigo === -1)
      return Promise.reject(
        `El campo Local de ${store} no se encuentra seleccionado, por favor elija una opción.`,
      )
    else return Promise.resolve()
  }, [])

  const validateTransfer = useCallback(() => {
    const errors: Array<string> = []
    if (newTransfer.detalles.length === 1)
      errors.push('No existen registrados ítems para la transferencia.')
    newTransfer.detalles.forEach((item: ItemTransferencia, index: number) => {
      if (item.cantidad <= 0 && item.codigo > 0)
        errors.push(
          `Item: ${item.barras} tiene cantidad CERO (0), Fila Nro. ${
            index + 1
          }`,
        )
    })
    if (newTransfer.ordenAsociada > 0)
      errors.push('No se puede editar transferecia tiene Orden Asociada')
    return { isValid: errors.length === 0, errors: errors }
  }, [newTransfer.detalles, newTransfer.ordenAsociada])

  const onHandleSave = useCallback(async () => {
    dispatch(
      setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: ButtonTypes.save,
      }),
    )
    const validationDetails = validateTransfer()
    let validationFormResult = validationGroupRef.current.instance.validate()
    if (validationFormResult.status === 'pending')
      validationFormResult = await validationFormResult.complete
    if (
      validationFormResult.isValid === false ||
      validationDetails.isValid === false
    ) {
      const errorList = []
      validationFormResult.brokenRules.forEach((rule) => {
        errorList.push(rule.message)
      })
      validationDetails.errors.forEach((error) => {
        errorList.push(error)
      })
      setValidationFormErrors(errorList)
      setShowPopupError(true)
      setToastMessage(
        'Guardar Transferencia',
        'Existen Errores en los datos, por favor verifiquelos.',
        ToastTypes.Warning,
      )
      dispatch(
        setCurrentExecutingAction({
          tabKey: tabId,
          buttonType: undefined,
        }),
      )
    } else {
      try {
        playLoader(tabId, 'Guardando Transferencia . . . ', ButtonTypes.save)
        const detailsTrasfer: DetailItem[] = []
        const seriesTransfer: SeriesItem[] = []
        const lotesTransfer: LotesItem[] = []
        newTransfer.detalles.forEach((item: ItemTransferencia) => {
          if (item.codigo) {
            detailsTrasfer.push({
              codigo: item.codigo,
              cantidad: item.cantidad,
              pvp: item.pvp,
              pvd: item.pvd,
              referencia: item.referencia,
              progreso: item.progreso,
              codigoTrd: item.codigoTrd ?? 0,
            })
            item.series.forEach((serie) => {
              seriesTransfer.push({
                itemCodigo: serie.codigo,
                itemBarras: serie.barras,
                itemDescripcion: serie.descripcion,
                itemSerieSerie: serie.serie,
                itemSerieMin: serie.min,
              })
            })
            item.lotesItem.forEach((lote: LotesItem) => {
              lotesTransfer.push(lote)
            })
          }
        })
        const transferToSave: SaveTransfer = {
          infoRegistro: {
            codigo: newTransfer.codigo,
            numero: newTransfer.numero.toString(),
            descripcion: newTransfer.descripcion,
            responsable: newTransfer.responsable?.codigo ?? 1,
            fecha: DateUtils.pickersDateToApiDate(newTransfer.fecha),
            origen: newTransfer.origenLocal.codigo,
            destino: newTransfer.destinoLocal.codigo,
            progreso: newTransfer.progreso,
            movimientoCabeceraCodigo: newTransfer.movimiento ?? 0,
            detalle: detailsTrasfer,
            series: seriesTransfer,
            lotes: lotesTransfer,
          },
        }
        const saveTransfer = await TransfersServices.saveTransfer(
          transferToSave,
        )
        stopLoader(tabId)
        if (!saveTransfer.error && saveTransfer.auto) {
          if (newTransfer.codigo === 0) {
            dispatch(
              setDatosEdicion({
                key: tabId,
                transferencia: {
                  ...newTransfer,
                  codigo: Number(saveTransfer.auto),
                },
              }),
            )
            dispatch(
              setNameTab({
                key: tabId,
                codigo: Number(saveTransfer.auto),
                progreso: 'INGRESADO',
              }),
            )
          }
        }
        setToastMessage(
          'Guardar Transferencia',
          saveTransfer.message,
          saveTransfer.error ? ToastTypes.Warning : ToastTypes.Success,
        )
      } catch (error) {
        stopLoader(tabId)
        setToastMessage('Guardar Transferencia', error, ToastTypes.Danger)
      }
    }
  }, [
    dispatch,
    newTransfer,
    playLoader,
    setToastMessage,
    stopLoader,
    tabId,
    validateTransfer,
  ])

  const onGetDetailsTransfer = useCallback(async () => {
    try {
      playLoader(tabId, 'Cargando Transferencia . . . ', ButtonTypes.new)
      let errorMessage: string = ''
      if (
        tabStatus === StatesEdition.save ||
        tabStatus === StatesEdition.edit
      ) {
        const currentTransfer = structuredClone(defaultDataTranfers)
        currentTransfer.codigo =
          tabStatus === StatesEdition.save ? selectedTransfer.codigo : 0
        currentTransfer.numero = parseInt(selectedTransfer.numero)
        currentTransfer.fecha =
          DateUtils.apiDateToPickersDate(selectedTransfer.fecha) ?? ''
        currentTransfer.descripcion = selectedTransfer.descripcion ?? ''
        currentTransfer.responsable = {
          codigo: selectedTransfer.responsable ?? 1,
          nombres: selectedTransfer.responsableNombre ?? '',
          apellidos: '',
        }
        currentTransfer.movimiento =
          selectedTransfer.movimientoCabeceraCodigo ?? null
        currentTransfer.ordenAsociada = selectedTransfer.ordenTrabajoCodigo ?? 0
        currentTransfer.loaded = true
        const deatilsTarnsfer = await TransfersServices.getTransferDetailAll(
          selectedTransfer.codigo,
        )
        if (!deatilsTarnsfer.error && deatilsTarnsfer.auto) {
          currentTransfer.detalles = onParseItems(deatilsTarnsfer.auto)
          currentTransfer.detalles.forEach((detail: ItemTransferencia) => {
            if (detail.numSerie === 1) {
              let series = []
              series = Array(detail.cantidad).fill({
                codigo: detail.codigo ?? 0,
                serie: '',
                min: '',
                barras: detail.barras ?? '',
                descripcion: detail.descripcion ?? '',
              })
              detail.series = series
            }
          })
          currentTransfer.detalles.push(emptyItem)
        } else errorMessage = deatilsTarnsfer.message
        dispatch(
          setDatosEdicion({
            key: tabId,
            transferencia: currentTransfer,
          }),
        )
        dispatch(
          setBackupTransfer({
            key: tabId,
            data: currentTransfer,
          }),
        )
      } else {
        const numberTransfer = await TransfersServices.getLastNumber('TRF')
        if (!numberTransfer.error && numberTransfer.auto)
          dispatch(
            setDatosEdicion({
              key: tabId,
              transferencia: {
                ...newTransfer,
                numero: parseInt(numberTransfer.auto),
                loaded: true,
              },
            }),
          )
        else errorMessage = numberTransfer.message
      }
      stopLoader(tabId)
      if (errorMessage !== '')
        setToastMessage(
          `Cargar Detalles Transferencia`,
          errorMessage,
          ToastTypes.Warning,
        )
    } catch (error) {
      stopLoader(tabId)
      setToastMessage('Cargar Detalles Transferencia', error, ToastTypes.Danger)
    }
  }, [
    dispatch,
    newTransfer,
    playLoader,
    selectedTransfer,
    setToastMessage,
    stopLoader,
    tabId,
    tabStatus,
  ])

  const onSelectedChanged = useCallback(
    (rowIndex: number, dataRow: ItemTransferencia) => {
      if (!dataRow.codigo) return false
      setSeries([])
      const haveLots = newTransfer.detalles.some(
        (detail: ItemTransferencia) => {
          return detail.lotes === 1
        },
      )
      if (haveLots && dataRow.lotes) {
        const itemsLotes = onAddItemsLotes(newTransfer.detalles)
        setSelectedtemLote(itemsLotes[rowIndex])
      }
      setRowIndex(rowIndex)
      dispatch(
        setButtons({
          tabKey: tabId,
          buttons: {
            Nuevo: true,
            Buscar: true,
            Guardar: true,
            Series: dataRow.numSerie === 1,
            Lotes: dataRow.lotes === 1,
            Deshacer: true,
          },
        }),
      )
      setSeries(dataRow.series)
    },
    [dispatch, newTransfer.detalles, onAddItemsLotes, tabId],
  )

  const onChangedSeriesModal = useCallback(
    (series) => {
      const indexDetail =
        rowIndex >= 0 ? rowIndex : newTransfer.detalles.length - 1
      const newDetails = structuredClone(newTransfer.detalles)
      newDetails[indexDetail].series = series
      dispatch(
        setDatosEdicion({
          key: tabId,
          transferencia: {
            ...newTransfer,
            detalles: newDetails,
          },
        }),
      )
    },
    [dispatch, newTransfer, rowIndex, tabId],
  )

  const onChangedLotesModal = useCallback(
    (lotes: any[]) => {
      const indexDetail =
        rowIndex >= 0 ? rowIndex : newTransfer.detalles.length - 1
      const newDetails = structuredClone(newTransfer.detalles)
      newDetails[indexDetail].lotesItem = lotes
      dispatch(
        setDatosEdicion({
          key: tabId,
          transferencia: {
            ...newTransfer,
            detalles: newDetails,
          },
        }),
      )
    },
    [dispatch, newTransfer, rowIndex, tabId],
  )

  const onHandleLots = useCallback(() => {
    if (inventarios === 1) setShowLotes(true)
    else
      setToastMessage(
        'Cargar Lotes del Ítem',
        'El Inventario solo se registra por <strong>Ingresos/Egresos</strong>, lotes desactivados',
        ToastTypes.Warning,
      )
  }, [inventarios, setToastMessage])

  const onHandleUndo = useCallback(() => {
    if (tabStatus === StatesEdition.save || tabStatus === StatesEdition.edit) {
      dispatch(
        setDataToEdit({
          key: tabId,
          data: {
            ...newTransfer.backup,
            origenLocal: {
              ...newLocal,
              codigo: selectedTransfer?.localOrigen ?? -1,
            },
            destinoLocal: {
              ...newLocal,
              codigo: selectedTransfer?.localDestino ?? -1,
            },
            backup: newTransfer.backup,
          },
        }),
      )
    } else
      dispatch(
        setDataToEdit({
          key: tabId,
          data: defaultDataTranfers,
        }),
      )
  }, [dispatch, tabId, tabStatus, newTransfer, selectedTransfer])

  const handleButtonClick = useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.save:
          if (tabs.current === tabId) onHandleSave()
          break
        case ButtonTypes.series:
          if (tabs.current === tabId) setShowSeries(true)
          break
        case ButtonTypes.lots:
          if (tabs.current === tabId) onHandleLots()
          break
        case ButtonTypes.undo:
          if (tabs.current === tabId) onHandleUndo()
          break
        default:
          break
      }
      dispatch(setCurrentFunction(''))
      dispatch(clearButtonClick(tabId))
    },
    [dispatch, onHandleSave, tabId, tabs, onHandleLots, onHandleUndo],
  )

  useEffect(() => {
    dispatch(
      setButtons({
        tabKey: tabId,
        buttons: {
          Nuevo: true,
          Buscar: true,
          Guardar: true,
          Series: false,
          Lotes: false,
          Deshacer: true,
        },
      }),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (currentAction !== '') handleButtonClick(currentAction)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAction])

  useEffect(() => {
    if (newTransfer.loaded === false) onGetDetailsTransfer()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) ///rowIndex

  const renderSearchItems = useCallback(
    (data: ItemTransferencia, rowIndex: number, columnIndex: number) => {
      if (
        (data.codigo || newTransfer.detalles[rowIndex]?.codigo) &&
        columnIndex === 3
      )
        return (
          <>
            {data.descripcion
              ? data.descripcion
              : newTransfer.detalles[rowIndex].descripcion}
          </>
        )
      else if (
        (data.codigo || newTransfer.detalles[rowIndex]?.codigo) &&
        columnIndex === 2
      )
        return (
          <>
            {data.barras ? data.barras : newTransfer.detalles[rowIndex].barras}
          </>
        )

      return (
        <>
          <BuscarItemPedidoMemoLookUp
            selected={null}
            onChanged={(item: ItemPedido) => {
              if (item) onAddItem(item, false)
            }}
            cliente={null}
            fecha={DateUtils.getCurrentDateAsString(formatoFechasApi)}
            prioridadBarras={columnIndex === 3 ? false : true}
            modulo={EModulosAcatha.Transferencias}
            tipoAsociado={ETipoAsociado.Clientes}
            local={newTransfer.origenLocal}
            autoComplete={true}
          />
        </>
      )
    },
    [newTransfer.detalles, newTransfer.origenLocal, onAddItem],
  )

  return (
    <BlockUi
      tag="div"
      loader={LoadingIndicator}
      blocking={loader.show && tabId === tabs.current}
      message={loader.mensaje}
    >
      <RowContainer className="m-2">
        {showLotes && (
          <ModalIngresoLotes
            onChanged={(lotes) => onChangedLotesModal(lotes)}
            onCancel={() => setShowLotes(false)}
            show={showLotes}
            modulo={2}
            lotes={[]}
            detalleItems={itemsLotes}
            seleccionadoItems={selectedtemLote}
            localCodigo={newTransfer.origenLocal?.codigo ?? -1}
          />
        )}
        {showSeries && (
          <ModalIngresoSeries
            onChanged={(series) => {
              onChangedSeriesModal(series)
            }}
            onCancel={() => setShowSeries(false)}
            show={showSeries}
            codModulo={2}
            data={newTransfer.origenLocal?.codigo ?? -1}
            dataSeries={series}
            dataDetalle={[]}
          />
        )}
        {showPopupError && (
          <VisualizaError
            titulo="Error en Transferencias"
            mensaje={'Error al guardar'}
            content={
              <ListErrosValidationForm
                mainMessage={
                  'Antes de guardar la información revise lo siguiente:'
                }
                errorsList={validationFormErrors}
                color={'danger'}
              />
            }
            onOk={() => setShowPopupError(false)}
          />
        )}
        {showUsers && (
          <ModalUsuarios
            show={showUsers}
            onClose={() => setShowUsers(false)}
            modulo={EModulosAcatha.Transferencias}
            onSelectUser={(user: Usuario) => {
              onHandleChanged({ event: true, value: user }, 'responsable')
              setShowUsers(false)
            }}
          />
        )}
        {showModalStores.show && (
          <AdminModalLocales
            onChanged={(store: LocalOption) => {
              if (showModalStores.store === 'origen')
                onChangedOriginStore(store)
              else onChangedDestinationStore(store)
              setShowModalStores({ show: false, store: '' })
            }}
            onCancel={() => setShowModalStores({ show: false, store: '' })}
            show={showModalStores.show}
            modulo={2}
          />
        )}
        <Dialog ref={dialogRef} />
        <ValidationGroup
          key={'valitadationTransfer'}
          id={'valitadationTransfer'}
          ref={validationGroupRef}
        >
          <RowContainer>
            <CustomCol xs="12" md="2">
              <Labeled label="TRANSFERENCIA NRO *:">
                <NumberBox
                  value={newTransfer.numero}
                  onValueChanged={(evt: unknown) =>
                    onHandleChanged(evt, 'numero')
                  }
                  showClearButton={true}
                  min={1}
                  max={9999999999}
                >
                  <Validator>
                    <RequiredRule
                      message={
                        'El campo Número de Transferencia es obligatorio'
                      }
                    />
                  </Validator>
                </NumberBox>
              </Labeled>
            </CustomCol>
            <CustomCol xs="12" md="2">
              <Labeled label="Fecha:">
                <DateBox
                  displayFormat="dd/MM/yyyy"
                  value={newTransfer.fecha}
                  onValueChanged={(evt: unknown) =>
                    onHandleChanged(evt, 'fecha')
                  }
                >
                  <Validator>
                    <RequiredRule message={'El campo Fecha es obligatorio'} />
                  </Validator>
                </DateBox>
              </Labeled>
            </CustomCol>
            <CustomCol xs="12" md="7">
              <Labeled label="Descripción *:">
                <TextBox
                  value={newTransfer.descripcion}
                  onValueChanged={(evt: unknown) =>
                    onHandleChanged(evt, 'descripcion')
                  }
                  showClearButton={true}
                >
                  <Validator>
                    <RequiredRule
                      message={'El campo Descripción es obligatorio'}
                    />
                  </Validator>
                </TextBox>
              </Labeled>
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol xs="12" md="4">
              <Labeled label="Origen *:">
                <CCol xs="11" md="11" style={{ marginBottom: '8px' }}>
                  <ProjectsLookUp
                    selected={newTransfer.origenProyecto}
                    onChanged={(project: Project) => {
                      onChangedOriginProject(project)
                    }}
                    onChangedOptions={() => {}}
                    addDefaultPosition={'Elije un local ó CC'}
                  />
                </CCol>
                <CRow>
                  <CCol xs="9" md="10">
                    <SelectBox
                      id="localOrigenSelect"
                      ref={selectRef}
                      items={newTransfer.origenProyecto?.locales ?? []}
                      searchEnabled
                      searchTimeout={500}
                      value={newTransfer.origenLocal}
                      onValueChanged={(event) => {
                        if (event.event) onChangedOriginStore(event.value)
                      }}
                      searchExpr={['nombre']}
                      displayExpr={'nombre'}
                    >
                      <Validator>
                        <AsyncRule
                          validationCallback={(selected) =>
                            validateStores(selected, 'Origen')
                          }
                        />
                        <RequiredRule />
                      </Validator>
                    </SelectBox>
                  </CCol>
                  <CCol xs="3" md="2">
                    <Button
                      style={{ marginLeft: '-20px' }}
                      id="btnAdd"
                      className="me-1"
                      icon="add"
                      stylingMode="contained"
                      type="default"
                      onClick={() =>
                        setShowModalStores({ show: true, store: 'origen' })
                      }
                    />
                  </CCol>
                </CRow>
              </Labeled>
            </CustomCol>
            <CustomCol xs="12" md="4">
              <Labeled label="Destino *:">
                <CCol xs="11" md="11" style={{ marginBottom: '8px' }}>
                  <ProjectsLookUp
                    selected={newTransfer.destinoProyecto}
                    onChanged={(project: Project) => {
                      onChangedDestinationProject(project)
                    }}
                    onChangedOptions={() => {}}
                    addDefaultPosition={'Elije un local ó CC'}
                  />
                </CCol>
                <CRow>
                  <CCol xs="9" md="10">
                    <SelectBox
                      id="localDestinoSelect"
                      ref={selectRef}
                      items={newTransfer.destinoProyecto?.locales ?? []}
                      searchEnabled
                      searchTimeout={500}
                      value={newTransfer.destinoLocal}
                      onValueChanged={(event) => {
                        if (event.event) onChangedDestinationStore(event.value)
                      }}
                      searchExpr={['nombre']}
                      displayExpr={'nombre'}
                    >
                      <Validator>
                        <AsyncRule
                          validationCallback={(selected) =>
                            validateStores(selected, 'Destino')
                          }
                        />
                        <RequiredRule />
                      </Validator>
                    </SelectBox>
                  </CCol>
                  <CCol xs="3" md="2">
                    <Button
                      style={{ marginLeft: '-20px' }}
                      id="btnAdd"
                      className="me-1"
                      icon="add"
                      stylingMode="contained"
                      type="default"
                      onClick={() =>
                        setShowModalStores({ show: true, store: 'proyecto' })
                      }
                    />
                  </CCol>
                </CRow>
              </Labeled>
            </CustomCol>
            <CustomCol xs="12" md="4">
              <Labeled label="Responsable:">
                <CRow>
                  <CCol xs="9" md="10">
                    <TextBox
                      value={
                        newTransfer.responsable
                          ? `${newTransfer.responsable?.nombres} ${newTransfer.responsable?.apellidos}`
                          : ''
                      }
                      readOnly={true}
                    />
                  </CCol>
                  <CCol xs="3" md="2">
                    <Button
                      style={{ marginLeft: '-20px' }}
                      id="btnAdd"
                      className="me-1"
                      icon="add"
                      stylingMode="contained"
                      type="default"
                      onClick={() => setShowUsers(true)}
                    />
                  </CCol>
                </CRow>
              </Labeled>
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol xs="12" md="8">
              <Labeled label="Item:">
                <BuscarItemPedidoMemoLookUp
                  selected={newTransfer.item}
                  onChanged={(item: ItemPedido) => onChangedItem(item)}
                  cliente={null}
                  fecha={DateUtils.getCurrentDateAsString(formatoFechasApi)}
                  prioridadBarras={searchFrom.value}
                  modulo={EModulosAcatha.Transferencias}
                  tipoAsociado={ETipoAsociado.Clientes}
                  local={newTransfer.origenLocal}
                  allowMoreOption={true}
                  allowAdd={true}
                  allowClear={true}
                  allowEdit={true}
                />
              </Labeled>
              <div style={{ display: 'flex', gap: '8px' }}>
                {'Buscar por: '}
                <RadioGroup
                  name="selectSearckLookup"
                  layout="horizontal"
                  displayExpr={'label'}
                  items={optionsSearch}
                  value={searchFrom}
                  onValueChanged={(evt) => {
                    if (evt.event) setSearchFrom(evt.value)
                  }}
                />
              </div>
            </CustomCol>
            <CustomCol xs="12" md="2" />
            <CustomCol xs="12" md="2">
              <Labeled label="Movimiento:">
                <div
                  style={{
                    display: 'flex',
                    gap: '10px',
                    alignItems: 'center',
                  }}
                >
                  <NumberBox
                    value={newTransfer.movimiento}
                    onValueChanged={(evt: unknown) =>
                      onHandleChanged(evt, 'movimiento')
                    }
                    min={0}
                  />
                  <Button
                    id="btnAdd"
                    className="me-1"
                    icon="arrowright"
                    stylingMode="contained"
                    type="default"
                    hint="Cargar ítems del movimiento"
                    onClick={() => onLoadMovement()}
                    disabled={newTransfer.movimiento <= 0}
                  />
                </div>
              </Labeled>
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol xs="12" md="2">
              <Labeled label="Existencia:">
                <NumberBox value={newTransfer.existencia} readOnly={true} />
              </Labeled>
            </CustomCol>
            <CustomCol xs="12" md="2">
              <Labeled label="Cantidad:">
                <NumberBox
                  value={newTransfer.cantidad}
                  onValueChanged={(evt: unknown) =>
                    onHandleChanged(evt, 'cantidad')
                  }
                  onEnterKey={() => onAddItem(newTransfer.item)}
                  min={0}
                />
              </Labeled>
            </CustomCol>
            <CustomCol xs="12" md="2">
              <Labeled label="Unidad:">
                <TextBox value={newTransfer.unidad} readOnly={true} />
              </Labeled>
            </CustomCol>
            <CustomCol xs="12" md="2">
              <Labeled label="Referencia:">
                <NumberBox
                  value={newTransfer.referencia}
                  onValueChanged={(evt: unknown) =>
                    onHandleChanged(evt, 'referencia')
                  }
                  min={0}
                />
              </Labeled>
            </CustomCol>
            {!isMobile && (
              <CCol xs="12" md="2" style={{ marginTop: 'auto' }}>
                <Button
                  id="btnAdd"
                  className="me-1"
                  icon="add"
                  stylingMode="contained"
                  type="default"
                  onClick={() => onAddItem(newTransfer.item)}
                  disabled={
                    newTransfer.cantidad <= 0 || newTransfer.item === null
                  }
                  hint="Agregar Ítem"
                />
                <Button
                  id="btnAdd"
                  className="me-1"
                  icon="trash"
                  stylingMode="contained"
                  type="default"
                  onClick={() => onRemoveItem(rowIndex)}
                  hint="Eliminar Ítem"
                  disabled={rowIndex === -1}
                />
              </CCol>
            )}
            <CustomCol xs="12" md="2">
              <Labeled label="Compra:">
                <div
                  style={{
                    display: 'flex',
                    gap: '10px',
                    alignItems: 'center',
                  }}
                >
                  <NumberBox
                    value={newTransfer.compra}
                    onValueChanged={(evt: unknown) =>
                      onHandleChanged(evt, 'compra')
                    }
                    min={0}
                  />
                  <Button
                    id="btnAdd"
                    className="me-1"
                    icon="arrowright"
                    stylingMode="contained"
                    type="default"
                    hint="Cargar ítems de la compra"
                    onClick={() => setConfirmLoadPurchase(true)}
                    disabled={!newTransfer.compra && newTransfer.compra <= 0}
                  />
                </div>
              </Labeled>
            </CustomCol>
          </RowContainer>
          {isMobile ? (
            <>
              <RowContainer>
                <CCol xs="12">
                  <CButton
                    id="btnConfirmar"
                    color="secondary"
                    className="m-1"
                    size="sm"
                    onClick={() => setConfirmLoad(true)}
                  >
                    {'Cargar Origen Destino'}
                  </CButton>
                </CCol>
                <CRow>
                  <CCol xs="8">
                    <CButton
                      id="btnConfirmar"
                      color="secondary"
                      className="m-1"
                      size="sm"
                      onClick={() => setConfirmTransferAll(true)}
                    >
                      {'Transferir Todo'}
                    </CButton>
                  </CCol>
                  <CCol xs="4" style={{ display: 'flex', marginTop: 'auto' }}>
                    <Button
                      id="btnAdd"
                      className="me-1"
                      icon="add"
                      stylingMode="contained"
                      type="default"
                      onClick={() => onAddItem(newTransfer.item)}
                      disabled={
                        newTransfer.cantidad <= 0 || newTransfer.item === null
                      }
                      hint="Agregar Ítem"
                    />
                    <Button
                      id="btnAdd"
                      className="me-1"
                      icon="trash"
                      stylingMode="contained"
                      type="default"
                      onClick={() => onRemoveItem(rowIndex)}
                      hint="Eliminar Ítem"
                      disabled={rowIndex === -1}
                    />
                  </CCol>
                </CRow>
              </RowContainer>
            </>
          ) : (
            <>
              <RowContainer className="m-1 d-flex align-items-end justify-content-end">
                <CCol
                  xs="6"
                  md="3"
                  className="d-flex align-items-end justify-content-end"
                >
                  <CButton
                    id="btnConfirmar"
                    color="secondary"
                    className="m-1"
                    size="sm"
                    onClick={() => setConfirmLoad(true)}
                  >
                    {'Cargar Origen Destino'}
                  </CButton>
                </CCol>
                <CCol
                  xs=""
                  md="2"
                  className="d-flex align-items-end justify-content-start"
                >
                  <CButton
                    id="btnConfirmar"
                    color="secondary"
                    className="m-1"
                    size="sm"
                    onClick={() => setConfirmTransferAll(true)}
                  >
                    {'Transferir Todo'}
                  </CButton>
                </CCol>
              </RowContainer>
            </>
          )}
          <ItemsCountIndicator items={newTransfer.detalles} />
          <DataGrid
            ref={dataGrid}
            selection={{ mode: 'single' }}
            hoverStateEnabled={true}
            dataSource={newTransfer.detalles}
            showBorders={true}
            allowColumnReordering={true}
            allowColumnResizing={true}
            columnAutoWidth={true}
            keyExpr="codigo"
            key="gridListItems"
            loadPanel={{ enabled: true }}
            repaintChangesOnly
            onRowClick={({ rowIndex, data }) =>
              onSelectedChanged(rowIndex, data)
            }
          >
            <Editing mode="cell" allowUpdating />
            <Export enabled={true} allowExportSelectedData={true} />
            <Paging defaultPageSize={20} />
            <Pager
              visible={newTransfer.detalles.length > 0 ? true : false}
              allowedPageSizes={getAllowedPageSizes(newTransfer.detalles)}
              displayMode={'full'}
              showPageSizeSelector={true}
              showInfo={true}
              showNavigationButtons={true}
            />
            {!isMobile && (
              <Column type="buttons" width="40px">
                <DatagridButton
                  icon="trash"
                  hint="Eliminar"
                  onClick={async ({ row }) => onRemoveItem(row.dataIndex)}
                  visible={({ row }) => {
                    return row?.data.codigo > 0
                  }}
                />
              </Column>
            )}
            <Column
              dataField="codigoUsuario"
              caption="Código"
              width={isMobile ? '120px' : '10%'}
              allowEditing={false}
            />
            <Column
              dataField="barras"
              caption="Barras"
              width={isMobile ? '120px' : '10%'}
              allowEditing={false}
              cellRender={({ data, rowIndex, columnIndex }) =>
                renderSearchItems(data, rowIndex, columnIndex)
              }
            />
            <Column
              dataField="descripcion"
              caption="Descripción"
              width={isMobile ? '350px' : '32%'}
              allowEditing={false}
              cellRender={({ data, rowIndex, columnIndex }) =>
                renderSearchItems(data, rowIndex, columnIndex)
              }
            />
            <Column
              dataField="unidadDescripcion"
              caption="Unidad"
              width={isMobile ? '80px' : '8%'}
              allowEditing={false}
            />
            <Column
              dataField="cantidad"
              caption="Cantidad"
              width={isMobile ? '90px' : '8%'}
              allowEditing={true}
              setCellValue={(newData, value, currentRowData) =>
                updateCellValue(newData, value, currentRowData, 'cantidad')
              }
            />
            <Column
              dataField="referencia"
              caption="Referencia"
              width={isMobile ? '90px' : '12%'}
              allowEditing={true}
              setCellValue={(newData, value, currentRowData) =>
                updateCellValue(newData, value, currentRowData, 'referencia')
              }
            />
            <Column
              dataField="pvp"
              caption="PVP"
              width={isMobile ? '90px' : '10%'}
              allowEditing={true}
              setCellValue={(newData, value, currentRowData) =>
                updateCellValue(newData, value, currentRowData, 'pvp')
              }
            />
            <Column
              dataField="pvd"
              caption="PVD"
              width={isMobile ? '90px' : '10%'}
              allowEditing={true}
              setCellValue={(newData, value, currentRowData) =>
                updateCellValue(newData, value, currentRowData, 'pvd')
              }
            />
          </DataGrid>
        </ValidationGroup>
      </RowContainer>
    </BlockUi>
  )
}

export default React.memo(Nuevo)

export const onParseItems = (items: any[]): ItemTransferencia[] => {
  const itemsTransfer: ItemTransferencia[] = []
  items.forEach((item) => {
    itemsTransfer.push({
      codigoTrd: item.codigo ?? 0,
      codigo: item.itemCodigo ?? 0,
      codigoUsuario: item.itemCodigoUsuario ?? '',
      barras: item.itemBarras ?? '',
      descripcion: item.itemDescripcion ?? '',
      unidadDescripcion: item.itemUnidadDescripcion ?? '',
      cantidad: item.cantidad ?? 0,
      referencia: item.referencia ?? null,
      existencia: item.existencia ?? 0,
      numSerie: item.itemNumeroSerie ?? 0,
      lotes: 0,
      pvd: item.pvd ?? 0,
      pvp: item.pvp ?? 0,
      fraccion: item.itemFraccion ?? 0,
      progreso: item.progreso ?? 0,
      enviado: false,
      series: [],
      lotesItem: [],
      costo: item.costo ?? 0,
      progresoDes: item.progresoDescripcion ?? 'N/A',
    })
  })
  return itemsTransfer
}

