import { RequestHelper } from "../../../../../helpers/requestHelper"
import { SesionService } from "../../../../../services/sesion.service";
import { DateUtils, formatoFechasApi } from "../../../../../helpers/dateUtils";
import { RequestHelperDotNet } from "../../../../../helpers/requestHelperDotNet";
import { RowStyle } from "../../../../../store/types";

export const ExportarExcelService = {
  exportarArchivo, exportarDemo
}

type ExportarPlanCuenta = {
  numero: string,
  descripcion: string,
  descripcionIdioma: string,
  numeroPadre: string,
  descripcionTipo: string,
  operacion: string,
  descripcionCategoria: string,
  fiscal: string,
  numeroPLF: string,
  style: RowStyle
}

type ExportarPlan = {
  sheetName: string,
  exportedBy: string,
  titulos: Array<string>,
  data: Array<ExportarPlanCuenta>,
  emptyRowsAfterTitles: number,
  cols: Array<{ fieldName: string, fieldTitle: string, order: number, datatype: string, sumtype?: string }>
}

type ExportarPlanPdf = ExportarPlan & {
  id: string,
  ruc: string,
  titleDocument: string
}

const columnas = [
  {
    "fieldName": "numero",
    "fieldTitle": "Nro.",
    "order": 1,
    "datatype": "String"
  },
  {
    "fieldName": "descripcion",
    "fieldTitle": "Descripción",
    "order": 2,
    "datatype": "String"
  },
  {
    "fieldName": "descripcionIdioma",
    "fieldTitle": "Alterna",
    "order": 3,
    "datatype": "String"
  },
  {
    "fieldName": "numeroPadre",
    "fieldTitle": "Padre",
    "order": 4,
    "datatype": "String"
  },
  {
    "fieldName": "descripcionTipo",
    "fieldTitle": "Tipo",
    "order": 5,
    "datatype": "String"
  },
  {
    "fieldName": "operacion",
    "fieldTitle": "Operación",
    "order": 6,
    "datatype": "String"
  },
  {
    "fieldName": "descripcionCategoria",
    "fieldTitle": "Categoría",
    "order": 7,
    "datatype": "String"
  },
  {
    "fieldName": "fiscal",
    "fieldTitle": "Fiscal",
    "order": 8,
    "datatype": "String"
  },
  {
    "fieldName": "numeroPLF",
    "fieldTitle": "Flujo de Caja",
    "order": 9,
    "datatype": "String"
  }
]

async function exportarArchivo(planCuentas) {
  const sesion = SesionService.getCurrentSesion();
  const datosExportar: ExportarPlan = {
    sheetName: 'PlanCuentas',
    data: [],
    emptyRowsAfterTitles: 0,
    exportedBy: `${sesion.usuario.nombres} ${sesion.usuario.apellidos}`,
    titulos: [sesion.empresa.comercial,
    `FECHA:${DateUtils.getCurrentDateAsString(formatoFechasApi)}`,
      "EXPRESADO EN USD"],
    cols: columnas
  }
  datosExportar.data = planCuentas.map(tipo => {
    const ventaExportar: ExportarPlanCuenta = {
      numero: tipo.numero ?? "-",
      descripcion: tipo.descripcion ?? "-",
      descripcionIdioma: tipo.descripcionIdioma ?? "-",
      numeroPadre: tipo.numeroPadre ?? "-",
      descripcionTipo: tipo.descripcionTipo ?? "-",
      operacion: tipo.operacion ?? "-",
      descripcionCategoria: tipo.descripcionCategoria ?? "-",
      fiscal: tipo.fiscal ?? "-",
      numeroPLF: tipo.numeroPLF ?? "-",
      style: String(tipo.descripcionCategoria) === "DE GRUPO" ? { FontBool: true } : { FontBool: false }
    }
    return ventaExportar;
  });
  const config = await RequestHelperDotNet.getConfigAsync("POST");
  const response = fetch(RequestHelperDotNet.getRequestUrl('ExcelExport', 'ConvertDataToExcel', null, null), {
    method: 'post',
    body: JSON.stringify(datosExportar),
    headers: config.headers
  })
  return response;
}

function exportarDemo(fileName) {
  const config = RequestHelper.getConfig("GET");
  const datosSearch = {
    archivo: fileName
  }
  const response = fetch(RequestHelper.getRequestUrl('generales', 'downloadFile', null, datosSearch), {
    method: 'get',
    headers: config.headers
  });
  return response;
}
