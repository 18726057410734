import React, {
  FunctionComponent,
  PropsWithChildren,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { GlobalMenu } from '../../../../views/componentes/globalMenu/globalMenu'
import {
  AccionMenu,
  CustomButtons,
  FETCH_STATUS,
  ToastTypes,
} from '../../../../store/types'
import CardContent from '../../../bancos/components/cardContent'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../../store/store'
import {
  StoreList,
  StoreListResult,
  optListaPrecios,
  optNegativos,
  optionsSearch,
} from './types/types'
import {
  changeLoaderMultistore,
  setCollpased,
  setCurrentFunction,
} from './store/generalReducer'
import { addToast } from '../../../../store/toasterReducer'
import TabControl from './components/shared'
import { Aplicacion } from '../../store/types'
import {
  changeCurrentTab,
  clearButtonClick,
  closeTabMultistore,
  openTab,
  setButtons,
  setCurrentExecutingAction,
} from './store/tabsReducer'
import { ButtonTypes } from '../../../../views/componentes/globalMenu/types'
import { v4 as uuidv4 } from 'uuid'
import { deleteEditData, initDatosEdicion } from './store/editDataReduce'
import {
  fetchStores,
  initialStateSearch,
  setCleanResult,
  setFilter,
} from './store/serachReducer'
import Dialog from '../../../../views/componentes/librerias/bootstrap-dialog'
import {
  useButtonDisabled,
  useMenuOptions,
} from '../../../../hooks/useMenuButtonControls'
import { ReportWithLocalDataModal } from '../../../../views/componentes/xtrareports/ReportWithLocalDataModal'

const pathVerificador = '/multialmacén'

interface MultistoreProps extends PropsWithChildren {}

const Multialmacen: FunctionComponent<MultistoreProps> = () => {
  const dispatch = useDispatch()
  const menuState = useSelector((state: RootState) => state.global.menu)
  const tabs = useSelector((state: RootState) => {
    return state.inventarios.multialmacen.tabs
  })
  const loader = useSelector((state: RootState) => {
    return state.inventarios.multialmacen.general.loader
  })
  const searchFilter = useSelector((state: RootState) => {
    return state.inventarios.multialmacen.search.filter
  })
  const statusSearch = useSelector((state: RootState) => {
    return state.inventarios.multialmacen.search.status
  })
  const resultsSearch = useSelector((state: RootState) => {
    return state.inventarios.multialmacen.search.resultList
  })

  const [pdfReport, setPdfReport] = useState<null | 'Viewer' | 'Designer'>(null)
  const [confirmarBorrar, setConfirmarBorrar] = useState<boolean>(false)
  const [confirmarEditar, setConfirmarEditar] = useState<boolean>(false)
  const dialogRef = useRef<any>(null)

  const getButtonDisabled = useButtonDisabled(tabs)
  const { getMenuOptions, options } = useMenuOptions()

  const playLoader = useCallback(
    (message = 'Cargando...') => {
      dispatch(changeLoaderMultistore({ show: true, mensaje: message }))
    },
    [dispatch],
  )

  const stopLoader = useCallback(() => {
    dispatch(changeLoaderMultistore({ show: false, mensaje: '' }))
  }, [dispatch])

  const onTabClosing = useCallback(
    async (index) => {
      const closedTab = tabs.tabs[index]
      dispatch(closeTabMultistore(closedTab.tabKey ?? index))
      dispatch(deleteEditData(closedTab.tabKey ?? index))
    },
    [dispatch, tabs.tabs],
  )

  const onTabChanged = useCallback(
    (tabKey: string) => {
      dispatch(changeCurrentTab(tabKey))
    },
    [dispatch],
  )

  const onCleanFilters = useCallback(() => {
    dispatch(setFilter(initialStateSearch.filter))
  }, [dispatch])

  const onCleanResultsSearch = useCallback(() => {
    dispatch(setCleanResult())
  }, [dispatch])

  const onConfirmarEditar = useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea Editar el registro?`,
      actions: [
        Dialog.Action(
          <span>
            <u>A</u>ceptar
          </span>,
          (dialog) => {
            dialog.hide()
            dispatch(setCurrentFunction(ButtonTypes.edit))
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setConfirmarEditar(false)
    return
  }, [dispatch])

  const onConfirmarBorrar = useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea Eliminar el Registro?`,
      actions: [
        Dialog.Action(
          <span>
            <u>A</u>ceptar
          </span>,
          (dialog) => {
            dialog.hide()
            dispatch(setCurrentFunction(ButtonTypes.delete))
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setConfirmarBorrar(false)
    return
  }, [dispatch])

  useEffect(() => {
    if (confirmarEditar) onConfirmarEditar()
  }, [confirmarEditar, onConfirmarEditar])

  useEffect(() => {
    if (confirmarBorrar) onConfirmarBorrar()
  }, [confirmarBorrar, onConfirmarBorrar])

  const onMenuButtonClick = useCallback(
    async (action: AccionMenu | { actionType: string }) => {
      const id = uuidv4()
      switch (action.actionType) {
        case ButtonTypes.find:
          if (tabs.current === 'BUSQUEDA') {
            onCleanResultsSearch()
            const toAny: any = fetchStores(searchFilter)
            dispatch(toAny)
          } else dispatch(changeCurrentTab('BUSQUEDA'))
          break
        case ButtonTypes.new:
          if (tabs.current === 'BUSQUEDA') {
            dispatch(
              initDatosEdicion({
                key: id,
                data: { ...defaultDataStore },
              }),
            )
            dispatch(openTab({ key: id }))
          } else dispatch(setCurrentFunction(ButtonTypes.new))
          break
        case ButtonTypes.save:
          if (tabs.current !== 'BUSQUEDA')
            dispatch(setCurrentFunction(ButtonTypes.save))
          break
        case ButtonTypes.edit:
          if (tabs.current === 'BUSQUEDA') setConfirmarEditar(true)
          break
        case ButtonTypes.delete:
          if (tabs.current === 'BUSQUEDA') setConfirmarBorrar(true)
          break
        case ButtonTypes.broom:
          if (tabs.current === 'BUSQUEDA') onCleanResultsSearch()
          break
        case ButtonTypes.print:
          if (tabs.current === 'BUSQUEDA') setPdfReport('Viewer')
          break
        case ButtonTypes.undo:
          if (tabs.current === 'BUSQUEDA') onCleanFilters()
          else dispatch(setCurrentFunction(ButtonTypes.undo))
          break
        default:
          break
      }
      dispatch(clearButtonClick(tabs.current))
    },
    [dispatch, searchFilter, onCleanResultsSearch, onCleanFilters, tabs],
  )

  useEffect(() => {
    if (menuState.length > 0 && options.length === 0) {
      getMenuOptions(menuState, 'to', pathVerificador)
    }
  }, [menuState, getMenuOptions, options])

  useEffect(() => {
    if (
      tabs.tabs[tabs.current].globalButtonClick !== ButtonTypes.none &&
      tabs.tabs[tabs.current].globalButtonClick === ButtonTypes.edit
    )
      onMenuButtonClick({
        actionType: tabs.tabs[tabs.current].globalButtonClick,
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs.tabs[tabs.current].globalButtonClick])

  useEffect(() => {
    if (statusSearch === FETCH_STATUS.FAILED) {
      stopLoader()
      dispatch(
        addToast({
          title: 'Buscar Locales',
          content: 'Error al realizar la búsqueda',
          type: ToastTypes.Danger,
        }),
      )
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'BUSQUEDA',
          buttonType: undefined,
        }),
      )
    }
    if (statusSearch === FETCH_STATUS.LOADING) {
      playLoader()
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'BUSQUEDA',
          buttonType: ButtonTypes.find,
        }),
      )
    }
    if (statusSearch === FETCH_STATUS.SUCCESS) {
      stopLoader()
      dispatch(
        setButtons({
          tabKey: 'BUSQUEDA',
          buttons: {
            Nuevo: true,
            Guardar: false,
            Editar: false,
            Eliminar: false,
            Buscar: true,
            Imprimir: resultsSearch.length > 0,
            Limpiar: true,
            Deshacer: true,
          },
        }),
      )
      if (resultsSearch.length > 5) dispatch(setCollpased(false))
    }
  }, [dispatch, statusSearch, playLoader, stopLoader, resultsSearch])

  const onParseData = useCallback((stores: Array<StoreListResult>) => {
    let storesList: Array<any> = []
    storesList = stores.map((store: StoreListResult) => {
      return {
        local: store?.localNombre ?? '',
        ciudad: store?.localCiudadNombre ?? '',
        direccion: store?.localDireccion ?? '',
        telefono: store?.localTelefono ?? '',
        gerente: store?.localGerente ?? '',
        tipo: store?.localTipoDescripcion ?? '',
      }
    })
    return storesList
  }, [])

  const optionsForm = () => {
    if (options.length > 0) {
      return (
        <>
          <GlobalMenu
            printButtonAsDropdown={false}
            showExportDesingOptions
            acciones={options}
            onClick={onMenuButtonClick}
            getButtonDisabled={getButtonDisabled}
            currentWorking={tabs.tabs[tabs.current].currentExecutingAction}
          />
        </>
      )
    } else {
      return <> </>
    }
  }

  const bodyForm = () => {
    return (
      <TabControl
        tabsState={tabs}
        onTabChanged={onTabChanged}
        ontabClosing={onTabClosing}
        tipoAplicacion={Aplicacion.multialmacen}
      />
    )
  }

  return (
    <>
      <Dialog ref={dialogRef} />
      {pdfReport && (
        <ReportWithLocalDataModal
          show={pdfReport !== null}
          onClose={() => setPdfReport(null)}
          data={onParseData(resultsSearch) ?? []}
          fileName="StaticListadoLocales"
          mode={pdfReport ?? 'Viewer'}
          parameters={{ Reporte_Filtro: '' }}
          template="StaticListadoLocales"
          key="reportDesigner"
        />
      )}
      <CardContent
        childrenOptions={optionsForm()}
        childrenBody={bodyForm()}
        headerTitle={'Multialmacén'}
        aplicacion={'Multialmacén'}
        loader={loader}
      />
    </>
  )
}

export default memo(Multialmacen)

export const ModuleButtons: CustomButtons = {
  Nuevo: true,
  Guardar: true,
  Editar: false,
  Eliminar: false,
  Buscar: true,
  Imprimir: false,
  Deshacer: true,
}

export const defaultDataStore: StoreList = {
  codigo: 0,
  calseLocal: optionsSearch[0],
  stockWeb: false,
  nombre: '',
  siglas: '',
  inv: '',
  desc: 0,
  cuidad: null,
  direccion: '',
  telefono: '',
  gerente: '',
  tipo: null,
  pertence: null,
  transaccionEnCero: optNegativos[0],
  usarListaPrecios: optListaPrecios[1],
  codigoUaf: '',
  estado: {
    codigo: 1,
    descripcion: 'ACTIVO',
  },
  usuario: '',
  contrasena: '',
  imagen: '',
  imagenCodificada: '',
}
