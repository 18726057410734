import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../store/store'
import { buttonClick, changeCurrentTab, contratosCloseTab, openTab, setCurrentExecutingAction } from './store/tabsReducer'
import { EAplicationsAcatha } from '../../../../../store/enumsAplication'
import { Aplicacion } from '../../../store/types'
import { AccionMenu, CustomButtons, FETCH_STATUS } from '../../../../../store/types'
import { setMenuButtons } from './store/menuReducer'
import { ButtonTypes } from '../../../../../views/componentes/globalMenu/types'
import { utilidades } from '../../../../../helpers/utilidades'
import { deleteEditData, initDatosEdicion } from './store/editDataReducer'
import { deleteEditDataBackup } from './store/configReducer'
import { useMenuOptions } from '../../../../../hooks/useMenuButtonControls'
import ReistroHorariosMain from './components/shared/main'
import { defaultHoursData } from './components/nuevo'

const pathVerificador = '/horarios'
const Horarios = () => {

  const dispatch = useDispatch()
  const { getMenuOptions, options } = useMenuOptions()
  const menuState = useSelector((state: RootState) => state.global.menu)

  const tabs = useSelector((state: RootState) => {
    return state.nomina.registros.horarios.tabs
  })
  const searchStatus = useSelector((state: RootState) => {
    return state.nomina.registros.horarios.search.status
  })
  const searchResults = useSelector((state: RootState) => {
    return state.nomina.registros.horarios.search.resultados
  })
  const onTabClosing = React.useCallback(
    async (index) => {
      const closedTab = tabs.tabs[index]
      dispatch(contratosCloseTab(closedTab.tabKey ?? index))
      await utilidades.sleep(50)
      dispatch(deleteEditData(closedTab.tabKey ?? index))
      dispatch(deleteEditDataBackup(closedTab.tabKey ?? index))
    },
    [dispatch, tabs.tabs],
  )


  const onSearchExecutingAction = React.useCallback((tabKey: string, type?: ButtonTypes) => {
    dispatch(setCurrentExecutingAction({
      tabKey: tabKey,
      buttonType: type
    }))
  }, [dispatch]);

  const onTabChanged = React.useCallback(
    (tabKey: string) => {
      dispatch(changeCurrentTab(tabKey))
    },
    [dispatch],
  )

  const onMenuButtonClick = React.useCallback(async (action: AccionMenu, tab: string = "") => {
    const id = uuidv4()
    switch (action.actionType) {
      case ButtonTypes.find:
        if (tabs.current === 'BUSQUEDA') {
          dispatch(buttonClick({
            tabKey: tab === "" ? tabs.current : tab,
            button: action.actionType
          }))
        } else dispatch(changeCurrentTab('BUSQUEDA'))
        break
      case ButtonTypes.new:
        dispatch(
          initDatosEdicion({
            key: id,
            data: {
              ...defaultHoursData,
              loading: true,
            },
          }),
        )
        dispatch(openTab({ key: id }))
        break
      case ButtonTypes.undo:
        dispatch(buttonClick({
          tabKey: tab === "" ? tabs.current : tab,
          button: action.actionType
        }))
        break
      case ButtonTypes.broom:
        dispatch(buttonClick({
          tabKey: tab === "" ? tabs.current : tab,
          button: action.actionType
        }))
        break
      case ButtonTypes.delete:
        dispatch(buttonClick({
          tabKey: tab === "" ? tabs.current : tab,
          button: action.actionType
        }))
        break
      case ButtonTypes.edit:
        dispatch(buttonClick({
          tabKey: tab === "" ? tabs.current : tab,
          button: action.actionType
        }))
        break
      case ButtonTypes.save:
        dispatch(buttonClick({
          tabKey: tab === "" ? tabs.current : tab,
          button: action.actionType
        }))
        break
      case ButtonTypes.print:
        dispatch(buttonClick({
          tabKey: tab === "" ? tabs.current : tab,
          button: action.actionType
        }))
        break
      case ButtonTypes.print_design:
        dispatch(buttonClick({
          tabKey: tab === "" ? tabs.current : tab,
          button: action.actionType
        }))
        break

      default:
        break
    }
  }, [dispatch, tabs])

  const onActionsButtonsLoaded = React.useCallback((actions: Array<AccionMenu>) => {
    dispatch(setMenuButtons(actions));
  }, [dispatch]);

  React.useEffect(() => {
    if (menuState.length > 0 && options.length === 0) {
      getMenuOptions(menuState, 'to', pathVerificador)
    }
  }, [menuState, getMenuOptions, options])

  return (
    <>
      <ReistroHorariosMain
        menu={{ loading: false, accions: options }}
        tabs={tabs}
        onTabChanged={onTabChanged}
        codigoModulo={parseInt(EAplicationsAcatha.Horarios)}
        opciones={OpcionesModeulo}
        onActionsButtonsLoaded={onActionsButtonsLoaded}
        onMenuButtonClick={onMenuButtonClick}
        searching={searchStatus === FETCH_STATUS.LOADING}
        ontabClosing={onTabClosing}
        hayResultadosBusqueda={searchResults.length > 0}
        verificarPuntoVenta
        verificarAutorizacion
        onExecutingAction={onSearchExecutingAction}
        tipoAplicacion={Aplicacion.horarios}
      />
    </>
  )
}

export default React.memo(Horarios)

const OpcionesModeulo = [parseInt(EAplicationsAcatha.Horarios)];

export const ModuleButtons: CustomButtons = {
  Nuevo: true,
  Buscar: false,
  Editar: false,
  Eliminar: false,
  Guardar: true,
  Deshacer: true,
  Imprimir: false,
  Limpiar: false,
}
