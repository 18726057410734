import React, { useEffect } from 'react'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput'
import { Button, DataGrid, DateBox, TextBox, ValidationGroup, ValidationSummary, Validator } from 'devextreme-react'
import { FichaTecnicaDatosEdicion, GuardarFichaTecnica, GuardarFichaTecnicaDetalle } from '../../types/types'
import { ButtonTypes } from '../../../../../../../views/componentes/globalMenu/types'
import { clearButtonClick, openTab } from '../../store/tabsReducer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import {
  changeLoaderDataSheet,
  setCurrentFunction,
} from '../../store/generalReducer'
import { initDatosEdicion, setDatosEdicion } from '../../store/editDataReducer'
import Dialog from '../../../../../../../views/componentes/librerias/bootstrap-dialog'
import { v4 as uuidv4 } from 'uuid'
import { defaultDataSheet } from '../..'
import { CCol, CRow } from '@coreui/react-pro'
import { Column, Grouping, Pager, Paging } from 'devextreme-react/data-grid'
import { getAllowedPageSizes } from '../../../../../../../helpers/Helper'
import { TabState } from '../../../../../../../store/genericTabTypes'
import { PatternRule, RequiredRule, StringLengthRule } from 'devextreme-react/validator'
import validations from '../../../../../../../config/validations'
import { addToast } from '../../../../../../../store/toasterReducer'
import { MessagesKeys, lh } from '../../../../../../../helpers/localizationHelper'
import { ToastTypes } from '../../../../../../../store/types'
import MainModalItems from '../../../../../../ventas/components/items/busquedaItemsModal/MainModalItems'
import config from '../../../../../../../config/config'
import { DateUtils } from '../../../../../../../helpers/dateUtils'
import { dataSheetServices } from '../../services/unidades.services'
import { ModalKitPartes } from '../../../../../../componentes/modalKitsPartes'
import { kitsPartsServices } from '../../../../../../componentes/modalKitsPartes/services/kitspartes.service'
import { InventarioService } from '../../../../../services/inventario.service'
import { getIndexProvider } from '../../../../../../shared/helpers/funciones'
import { ModalItemsConjuntos } from '../../../../../../componentes/modalItemsPorConjuntos'

interface INewProps extends React.PropsWithChildren {
  tab: TabState<FichaTecnicaDatosEdicion>
  tabId: string
}

const Nuevo: React.FunctionComponent<INewProps> = (props) => {
  const { tab, tabId } = props
  const dispatch = useDispatch()
  const currentAction = useSelector((state: RootState) => state.inventarios.catalogos.fechaTecnica.general.currentFunction)

  const fichaState = useSelector((state: RootState) => state.inventarios.catalogos.fechaTecnica.editData[tabId])
  const tabs = useSelector((state: RootState) => state.inventarios.catalogos.fechaTecnica.tabs)
  const modulo = useSelector((state: RootState) => state.inventarios.catalogos.fechaTecnica.config.modulo)
  const sms = validations['es']
  const [showModal, setShowModal] = React.useState<boolean>(false)
  const [showModalItemCon, setShowModalItemCon] = React.useState<boolean>(false)
  const [poupNombre, setPoupNombre] = React.useState<string>(null)
  const [dataItemsConjunto, setDataItemsConjunto] = React.useState([])

  const [confirmNew, setonfirmNew] = React.useState<boolean>(false)
  const [showKitsPartes, setShowKitsPartes] = React.useState<boolean>(false)
  const [selectDetalle, setSelectDetalle] = React.useState<any>(null)
  const validationGroupRef = React.useRef<any>()
  const validationFTDRef = React.useRef<any>()
  const dialogRef = React.useRef<any>(null)
  const dataGrid = React.useRef<any>()
  const searchResults = []
  const foto: string = "vacio.jpg"
  const formatoFecha: string = 'dd/MM/yyyy'
  const formatoFechaInput: string = 'yyyy-MM-dd'

  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(
      addToast({
        title: 'Inventario - Ficha Tecnica',
        content: mensaje,
        type: tipo,
      }),
    )
  }, [dispatch])

  const playLoader = React.useCallback(
    (message = 'Cargando...') => {
      dispatch(changeLoaderDataSheet({ show: true, mensaje: message }))
    },
    [dispatch],
  )

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoaderDataSheet({ show: false, mensaje: '' }))
  }, [dispatch])
  const onHandleBroon = React.useCallback(() => {
    dispatch(setDatosEdicion({
      key: tabId,
      ficha: defaultDataSheet
    }))
  }, [dispatch, tabId])


  const onConfirmNew = React.useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea crear un nuevo registro?`,
      actions: [
        Dialog.Action(
          <span>
            <u>P</u>estaña Actual
          </span>,
          (dialog) => {
            dialog.hide()
            onHandleBroon()
          },
          'btn-success',
          'p',
        ),
        Dialog.Action(
          <span>
            <u>N</u>ueva Pestaña
          </span>,
          (dialog) => {
            dialog.hide()
            const id = uuidv4()
            dispatch(
              initDatosEdicion({
                key: id,
                data: defaultDataSheet,
              }),
            )
            dispatch(openTab({ key: id }))
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setonfirmNew(false)
    return
  }, [dispatch, onHandleBroon])

  const onChangedValue = React.useCallback((data, key: string) => {
    if (data !== null && data !== undefined) {
      let value = null
      if (data?.value !== null && data?.value !== undefined) {
        value = data?.value
      } else {
        value = data
      }
      dispatch(setDatosEdicion({
        key: tabId,
        ficha: {
          ...fichaState,
          [key]: value
        }
      }))
    }
  }, [dispatch, fichaState, tabId])

  const onAddsetItem = React.useCallback((data, key: string) => {
    if (key === "cabecera") {
      const data_items = {
        codigo: data?.itemCodigo ?? 0,
        codigoUsu: data?.itemCodigoUsuario ?? '',
        barras: data?.itemBarras ?? '',
        descripcion: data?.itemDescripcion ?? '',
      }
      const serieNum = data?.itemTallaini - data?.itemTallafin
      dispatch(setDatosEdicion({
        key: tabId,
        ficha: {
          ...fichaState,
          itemReferencia: data_items,
          grupo: data?.itemGrupoDescripcion ?? '',
          propiedades: data?.itemValores ?? '',
          serie: serieNum.toString(),
          nroBase: data?.itemTalla ?? ''
        }
      }))
    }
    if (key === "detalle") {
      const data_items_detalle = {
        codigo: data?.itemCodigo ?? 0,
        codigoUsu: data?.itemCodigoUsuario ?? '',
        barras: data?.itemBarras ?? '',
        descripcion: data?.itemDescripcion ?? '',
      }
      dispatch(setDatosEdicion({
        key: tabId,
        ficha: {
          ...fichaState,
          materiales: data_items_detalle,
          grupo: data?.itemGrupoDescripcion ?? '',
          unidad: data?.itemUnidadCompraDescripcion ?? '',
          costo: data?.itemCosto ?? '',
        }
      }))
    }
  }, [dispatch, fichaState, tabId])

  const onChangedItemModal = React.useCallback(async (data, key: string) => {
    if (key === "horma") return
    console.log(data)
    const data_items = {
      codigo: data?.codigo ?? 0,
      barras: data?.codigoBarras ?? '',
      descripcion: data?.descripcion ?? '',
      usuarioCodigo: data?.codigoBarras ?? '',
      jerarquia: data?.jerarquia ?? '',
      grupo: data?.grupo ?? '',
    }
    let modalNombre = ""
    if (key === "cabecera") {
      modalNombre = "itemReferencia"
    } else if (key === "detalle") {
      modalNombre = "materiales"
    } else {
      modalNombre = "horma"
    }
    dispatch(setDatosEdicion({
      key: tabId,
      ficha: {
        ...fichaState,
        [modalNombre]: data_items,
      }
    }))
    const formatDate = DateUtils.format(fichaState.fecha, formatoFecha, formatoFechaInput)
    if (data.jerarquia === "1") {
      try {
        playLoader()
        const res = await InventarioService.getItemXCodigoBarras('', data?.codigo, formatDate, true)
        stopLoader()
        if (res !== null && res !== undefined && res['length'] === 1) {
          onAddsetItem(res[0], key)
        } else if (res !== null && res !== undefined && res['length'] > 1) {
          setDataItemsConjunto(res as any)
          setShowModalItemCon(true)
        } else {
          setToast('El item especial no tiene asociado items.', ToastTypes.Info)
        }
      } catch (error) {
        stopLoader()
        setToast(error.message, ToastTypes.Danger)
      }
    } else {
      setToast('Item seleccionado no cumple con el formato para armar la Ficha técnica', ToastTypes.Warning)
    }
  }, [fichaState, setToast, playLoader, stopLoader, onAddsetItem, dispatch, tabId])

  const onHandleSave = React.useCallback(async () => {
    const result = validationGroupRef.current.instance.validate()
    if (result.isValid) {
      try {
        const save_data: GuardarFichaTecnica = {
          infoRegistro: {
            codigo: fichaState?.codigo ?? 0,
            serie: fichaState?.serie ?? '',
            base: fichaState?.nroBase ?? '',
            itemCodigo: fichaState.itemReferencia?.codigo ?? 0, //ITE_CODIGOP -> Cabecera
            estado: 1,
            fecha: DateUtils.format(fichaState?.fecha, formatoFecha) ?? DateUtils.getCurrentDateAsString(formatoFecha),
            observaciones: fichaState?.observaciones ?? '',
            horma: fichaState.horma?.codigo ?? 0, //ITE_CODIGOPH -> Horma
          }
        }
        playLoader()
        const data = await dataSheetServices.setDataSheets(save_data)
        stopLoader()
        if (data !== null && data !== undefined && data['error'] === false) {
          dispatch(setDatosEdicion({
            key: tabId,
            ficha: {
              ...fichaState,
              codigo: data['auto']
            }
          }))
          setToast(data['message'], ToastTypes.Success)
        } else {
          setToast(data['message'], ToastTypes.Danger)
        }
      } catch (error) {
        stopLoader()
        setToast(error.message, ToastTypes.Danger)
      }
    } else {
      setToast(lh.getMessage(MessagesKeys.GlobalErrorSave), ToastTypes.Warning)
    }
  }, [fichaState, setToast, playLoader, stopLoader, dispatch, tabId])

  const handleButtonClick = React.useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.save:
          if (tabs.current === tabId) onHandleSave()
          break
        case ButtonTypes.new:
          if (tabs.current === tabId) setonfirmNew(true)
          break
        case ButtonTypes.broom:
          if (tabs.current === tabId) onHandleBroon()
          break
        default:
          break
      }
      dispatch(setCurrentFunction(''))
      dispatch(clearButtonClick(tabId))
    },
    [dispatch, onHandleSave, onHandleBroon, tabId, tabs],
  )

  const onGetData = React.useCallback(async (id: number) => {
    const search_data = {
      agrupar: true,
      descripcion: '',
      codigo: 0,
    }
    try {

      const res = await kitsPartsServices.getAllKitsParts(search_data)
      if (res !== null && res !== undefined && res['error'] === false) {
        const data = res['auto'].find(item => item.codigo === id)
        return data
      }
      return null
    } catch (error) {
      return null
    }
  }, [])

  const onSaveDetalle = React.useCallback(async () => {
    const resultPrincipal = validationGroupRef.current.instance.validate()
    const resultDetalle = validationFTDRef.current.instance.validate()

    if (resultPrincipal.isValid && resultDetalle.isValid) {
      let cantidadTotal = "0"
      let desperdicioTotal = "0"
      if (fichaState?.cantidad !== null && fichaState?.cantidad !== undefined && fichaState?.cantidad !== "") {
        cantidadTotal = fichaState?.cantidad
      }
      if (fichaState?.desperdicio !== null && fichaState?.desperdicio !== undefined && fichaState?.desperdicio !== "") {
        desperdicioTotal = fichaState?.desperdicio
      }
      const cantidad = parseInt(cantidadTotal) * (1 + (parseInt(desperdicioTotal) / 100))

      let save_deatilsave: GuardarFichaTecnicaDetalle = null
      if (selectDetalle !== null && selectDetalle !== undefined) {
        save_deatilsave = {
          infoRegistro: {
            codigoFicha: fichaState?.codigo ?? 0,
            serieFicha: fichaState?.serie ?? '',
            baseFicha: fichaState?.nroBase ?? '',
            codigoItem: fichaState.itemReferencia?.codigo ?? 0,
            estadoFicha: 1,
            fechaFicha: DateUtils.format(fichaState?.fecha, formatoFecha, formatoFechaInput) ?? DateUtils.getCurrentDateAsString(formatoFecha),
            codigoKitscompuestos: selectDetalle?.codigo ?? 0,
            cantidadKitscompuestos: parseInt(cantidad.toFixed(4)) ?? 0,
            valorKitscompuestos: selectDetalle?.valor ?? 0,
            estadoKitscompuestos: selectDetalle?.estado ?? 0,
            codigoKitsPartes: selectDetalle?.kitPartesCodigo ?? 0,
            codigoItems: selectDetalle?.itemCodigo ?? 0,
          }
        }
      } else {
        save_deatilsave = {
          infoRegistro: {
            codigoFicha: fichaState?.codigo ?? 0,
            serieFicha: fichaState?.serie ?? '',
            baseFicha: fichaState?.nroBase ?? '',
            codigoItem: fichaState.itemReferencia?.codigo ?? 0,
            estadoFicha: 1,
            fechaFicha: DateUtils.format(fichaState?.fecha, formatoFecha, formatoFechaInput) ?? DateUtils.getCurrentDateAsString(formatoFecha),
            codigoKitscompuestos: 0,
            cantidadKitscompuestos: parseInt(cantidad.toFixed(4)) ?? 0,
            valorKitscompuestos: parseInt(fichaState?.costo) ?? 0,
            estadoKitscompuestos: 1,
            codigoKitsPartes: fichaState.kitPartes?.codigo ?? 0,
            codigoItems: fichaState.materiales?.codigo ?? 0,
          }
        }
      }

      try {
        playLoader()
        const data = await dataSheetServices.setDataSheetsDetail(save_deatilsave)
        stopLoader()
        if (data !== null && data !== undefined && data['error'] === false) {
          setToast(data['message'], ToastTypes.Success)
        } else {
          setToast(data['message'], ToastTypes.Danger)
        }
      } catch (error) {
        setToast(error.message, ToastTypes.Danger)
      }
    } else {
      setToast(lh.getMessage(MessagesKeys.GlobalErrorSave), ToastTypes.Warning)
    }
  }, [fichaState, setToast, selectDetalle, playLoader, stopLoader])

  const deleteItemTabla = React.useCallback(async () => {
    if (selectDetalle !== null && selectDetalle !== undefined) {
      if (fichaState?.datagrid !== null && fichaState?.datagrid !== undefined && fichaState?.datagrid.length > 0) {
        const provider = fichaState?.datagrid.slice(0);
        const index = await getIndexProvider(provider, 'codigo', selectDetalle.codigo)
        if (index > -1) {
          provider.splice(index, 1)
          dispatch(setDatosEdicion({
            key: tabId,
            ficha: {
              ...fichaState,
              datagrid: provider
            }
          }))
        }
      }
    }
  }, [selectDetalle, dispatch, fichaState, tabId])

  const onDeleteDetalle = React.useCallback(async () => {
    if (selectDetalle !== undefined && selectDetalle !== null && fichaState?.codigo !== 0) {
      const dataDetalle_delete = {
        codigo: selectDetalle?.codigo ?? 0,
        ficha: fichaState?.codigo ?? 0,
      }

      try {
        playLoader()
        const data = await dataSheetServices.deleteDataSheetsDetail(dataDetalle_delete)
        stopLoader()
        if (data !== null && data !== undefined && data['error'] === false) {
          deleteItemTabla()
          setToast(data['message'], ToastTypes.Success)
        } else {
          setToast(data['message'], ToastTypes.Danger)
        }
      } catch (error) {
        setToast(error.message, ToastTypes.Danger)
      }
    } else {
      setToast('Seleccione un detalle para quitarlo.', ToastTypes.Warning)
    }
  }, [selectDetalle, setToast, fichaState, playLoader, stopLoader, deleteItemTabla])

  const onEditDataDetalle = React.useCallback(() => {
    if (selectDetalle !== null && selectDetalle !== undefined) {
      const kitPartes_data = {
        codigo: selectDetalle?.kitPartesCodigo.toString() ?? '',
        descripcion: selectDetalle?.kitPartesDescripcion ?? '',
        padreCodigo: selectDetalle?.kitPartesPadre.toString() ?? '',
        padreDescripcion: selectDetalle?.kitPartesPadreDes ?? '',
      }

      const materiales_data = {
        codigo: selectDetalle?.itemCodigo ?? 0,
        barras: selectDetalle?.barras ?? '',
        usuarioCodigo: selectDetalle?.itemCodigoUsu ?? '',
        costo: selectDetalle?.itemCosto ?? '',
        descripcion: selectDetalle?.itemDescripcion ?? '',
        unidadCopuesto: selectDetalle?.itemUnidadC ?? 0,
        unidadCopuestoDes: selectDetalle?.itemUnidadCDes ?? '',
      }
      dispatch(setDatosEdicion({
        key: tabId,
        ficha: {
          ...fichaState,
          kitPartes: kitPartes_data,
          materiales: materiales_data,
          color: selectDetalle?.color ?? '',
          cantidad: selectDetalle?.kitCompCantidad.toString() ?? '',
          costo: selectDetalle?.valor.toString() ?? '',
          unidad: selectDetalle?.itemUnidadCDes ?? '',
        }
      }))
    }
  }, [selectDetalle, dispatch, fichaState, tabId])

  const onSelectedLineChanged = React.useCallback(({ selectedRowsData }) => {
    console.log('detalle select', selectedRowsData[0])
    setSelectDetalle(selectedRowsData[0])
  }, [])

  const onGetKitPartes = React.useCallback(async (data) => {
    if (data !== null && data !== undefined) {
      playLoader()
      try {
        const cargarKits = await kitsPartsServices.loadKitsParts()
        if (cargarKits !== null && cargarKits !== undefined && cargarKits['error'] === true) {
          setToast(cargarKits['message'], ToastTypes.Danger)
        }
        const kits = await onGetData(data.id)
        if (kits !== null && kits !== undefined && kits?.padre !== 0) {
          const kitsEdit = {
            codigo: kits?.codigo ?? 0,
            padreDescripcion: kits?.descripcion ?? '',
            descripcion: kits?.etapaDescripcion ?? '',
            padreDes: kits?.padreDescripcion ?? '',
            padre: kits?.padre ?? 0,
            etapaCodigo: kits?.etapaCodigo ?? 0,
          }
          dispatch(setDatosEdicion({
            key: tabId,
            ficha: {
              ...fichaState,
              kitPartes: kitsEdit
            }
          }))
          stopLoader()
        } else {
          setToast('Debe seleccionar el ultimo nivel para generar la Ficha', ToastTypes.Warning)
        }
      } catch (error) {
        stopLoader()
        setToast(error.message, ToastTypes.Danger)
      }
    }
  }, [onGetData, playLoader, stopLoader, fichaState, dispatch, tabId, setToast])

  useEffect(() => {
    if (currentAction !== '') handleButtonClick(currentAction)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAction])

  React.useEffect(() => {
    if (confirmNew) {
      onConfirmNew()
    }
  }, [confirmNew, onConfirmNew])

  return (
    <>
      {
        <ModalItemsConjuntos
          onChanged={(data) => console.log(data)}
          onCancel={() => setShowModalItemCon(false)}
          show={showModalItemCon}
          codModulo={modulo}
          dataItems={dataItemsConjunto}
        />
      }
      {showModal && <MainModalItems
        transaccion='ventas'
        action='none'
        show={showModal}
        cliente={config.rucConsumidorFinal}
        onCancel={() => setShowModal(false)}
        returnItem={(data) => {
          onChangedItemModal(data, poupNombre)
          setShowModal(false)
        }}
      />}
      {
        showKitsPartes && (<ModalKitPartes
          onChanged={(data) => {
            onGetKitPartes(data)
            setShowKitsPartes(false)
          }}
          onCancel={() => setShowKitsPartes(false)}
          show={showKitsPartes}
        />)
      }
      <RowContainer className="m-2">
        <Dialog ref={dialogRef} />
        <ValidationGroup id={'formLineas'} ref={validationGroupRef}>
          <RowContainer>
            <CustomCol xs="12" md="2">
              <Labeled label="Fecha:">
                <DateBox
                  value={fichaState?.fecha ?? null}
                  displayFormat={formatoFecha}
                  onValueChanged={(data) => onChangedValue(data, 'fecha')}
                />
              </Labeled>
            </CustomCol>
            <CustomCol xs="12" md="3">
              <Labeled label="Item Referencia:">
                <div style={{ display: 'flex', gap: '5px' }}>
                  <TextBox
                    value={fichaState.itemReferencia?.descripcion ?? ''}
                    width="100%"
                    inputAttr={{ autocomplete: 'nope' }}
                  >
                    <Validator>
                      <RequiredRule message={'Item Referencia: ' + sms['required']} />
                    </Validator>
                  </TextBox>
                  <Button
                    id="btnAdd"
                    className="me-1"
                    icon="add"
                    stylingMode="contained"
                    type="default"
                    onClick={() => {
                      setPoupNombre('cabecera')
                      setShowModal(true)
                    }}
                  />
                </div>
              </Labeled>
            </CustomCol>
            <CustomCol xs="12" md="2">
              <Labeled label="Serie:">
                <TextBox
                  value={fichaState?.serie ?? ''}
                  width="100%"
                  inputAttr={{ autocomplete: 'nope' }}
                />
              </Labeled>
            </CustomCol>
            <CustomCol xs="12" md="3">
              <Labeled label="Observaciones:">
                <TextBox
                  value={fichaState?.observaciones ?? ''}
                  width="100%"
                  inputAttr={{ autocomplete: 'nope' }}
                  onValueChanged={(data) => onChangedValue(data, 'observaciones')}
                />
              </Labeled>
            </CustomCol>
            <CustomCol xs="12" md="2">
              <Labeled label="Imagen:">
                <img
                  key={uuidv4()}
                  id={uuidv4()}
                  src={'../../../../../../../.././../' + foto}
                  className="avathar"
                  style={{
                    maxWidth: '250px',
                    maxHeight: '250px',
                    objectFit: 'cover',
                  }}
                  alt={'imagen_item'}
                />
              </Labeled>
            </CustomCol>
          </RowContainer>
          <CRow>
            <CCol lg="6" md="8" sm="12">
              <ValidationSummary id="summary"></ValidationSummary>
            </CCol>
          </CRow>
        </ValidationGroup>
        <RowContainer>
          <CustomCol xs="12" md="2">
            <Labeled label="Nro Base:">
              <TextBox
                value={fichaState?.nroBase ?? ''}
                width="100%"
                inputAttr={{ autocomplete: 'nope' }}
              />
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="3">
            <Labeled label="Horma:">
              <div style={{ display: 'flex', gap: '5px' }}>
                <TextBox
                  value={fichaState.horma?.descripcion ?? ''}
                  width="100%"
                  inputAttr={{ autocomplete: 'nope' }}
                />
                <Button
                  id="btnAdd"
                  className="me-1"
                  icon="add"
                  stylingMode="contained"
                  type="default"
                  onClick={() => {
                    setPoupNombre('horma')
                    setShowModal(true)
                  }}
                />
              </div>
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="2">
            <Labeled label="Grupo:">
              <TextBox
                value={fichaState?.grupo ?? ''}
                width="100%"
                inputAttr={{ autocomplete: 'nope' }}
                onValueChanged={(data) => onChangedValue(data, 'grupo')}
              />
            </Labeled>
          </CustomCol>
        </RowContainer>
        <RowContainer>
          <CustomCol xs="12" md="5">
            <Labeled label="Propiedades:">
              <TextBox
                value={fichaState?.propiedades ?? ''}
                width="100%"
                inputAttr={{ autocomplete: 'nope' }}
                onValueChanged={(data) => onChangedValue(data, 'propiedades')}
              />
            </Labeled>
          </CustomCol>
        </RowContainer>
        <ValidationGroup id={'formDetalle'} ref={validationFTDRef}>
          <RowContainer>
            <CustomCol xs="12" md="3">
              <Labeled label="Kit Partes:">
                <div style={{ display: 'flex', gap: '5px' }}>
                  <TextBox
                    value={fichaState.kitPartes?.descripcion ?? ''}
                    width="100%"
                    inputAttr={{ autocomplete: 'nope' }}
                  >
                    <Validator>
                      <RequiredRule message={'Kits Partes: ' + sms['required']} />
                    </Validator>
                  </TextBox>
                  <Button
                    id="btnAdd"
                    className="me-1"
                    icon="add"
                    stylingMode="contained"
                    type="default"
                    onClick={() => setShowKitsPartes(true)}
                  />
                </div>
              </Labeled>
            </CustomCol>
            <CustomCol xs="12" md="3">
              <Labeled label="Materiales:">
                <div style={{ display: 'flex', gap: '5px' }}>
                  <TextBox
                    value={fichaState.materiales?.barras ?? ''}
                    width="100%"
                    inputAttr={{ autocomplete: 'nope' }}
                  >
                    <Validator>
                      <RequiredRule message={'Item Materiales: ' + sms['required']} />
                    </Validator>
                  </TextBox>
                  <Button
                    id="btnAdd"
                    className="me-1"
                    icon="add"
                    stylingMode="contained"
                    type="default"
                    onClick={() => {
                      setPoupNombre('detalle')
                      setShowModal(true)
                    }}
                  />
                </div>
              </Labeled>
            </CustomCol>
            <CustomCol xs="12" md="3">
              <Labeled label="Color:">
                <TextBox
                  value={fichaState?.color ?? ''}
                  width="100%"
                  inputAttr={{ autocomplete: 'nope' }}
                  onValueChanged={(data) => onChangedValue(data, 'color')}
                />
              </Labeled>
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol xs="12" md="3">
              <TextBox
                style={{ marginTop: '6px' }}
                value={fichaState.kitPartes?.padreDescripcion ?? ''}
                width="100%"
                inputAttr={{ autocomplete: 'nope' }}
              />
            </CustomCol>
            <CustomCol xs="12" md="6">
              <TextBox
                style={{ marginTop: '6px' }}
                value={fichaState.materiales?.descripcion ?? ''}
                width="100%"
                inputAttr={{ autocomplete: 'nope' }}
              />
            </CustomCol>
            <CustomCol xs="12" md="3">
              <TextBox
                style={{ marginTop: '6px' }}
                value={fichaState.materiales?.usuarioCodigo ?? ''}
                width="100%"
                inputAttr={{ autocomplete: 'nope' }}
              />
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol xs="12" md="3">
              <Labeled label="Cantidad:">
                <TextBox
                  value={fichaState?.cantidad ?? ''}
                  width="100%"
                  inputAttr={{ autocomplete: 'nope' }}
                  onValueChanged={(data) => onChangedValue(data, 'cantidad')}
                >
                  <Validator>
                    <RequiredRule message={'Cantidad: ' + sms['required']} />
                    <StringLengthRule message={'Cantidad: ' + sms['min'].replace('%num_characters%', '1')} min={1} />
                    <PatternRule
                      pattern="^[0-9]+$"
                      message="Ingrese solo numeros por favor."
                    />
                  </Validator>
                </TextBox>
              </Labeled>
            </CustomCol>
            <CCol xs="12" md="1" style={{ marginTop: 'auto' }}>
              <TextBox
                value={fichaState?.unidad ?? ''}
                width="100%"
                inputAttr={{ autocomplete: 'nope' }}
              />
            </CCol>
            <CCol xs="12" md="1" style={{ marginTop: 'auto' }}>
              <TextBox
                value={fichaState?.desperdicio ?? ''}
                width="100%"
                inputAttr={{ autocomplete: 'nope' }}
              />
            </CCol>
            <CustomCol xs="12" md="3">
              <Labeled label="Costo:">
                <TextBox
                  value={fichaState?.costo ?? ''}
                  width="100%"
                  inputAttr={{ autocomplete: 'nope' }}
                  onValueChanged={(data) => onChangedValue(data, 'costo')}
                />
              </Labeled>
            </CustomCol>
            <CCol xs="12" md="4" style={{ marginTop: 'auto' }}>
              <div
                style={{ display: 'flex', gap: '5px', justifyContent: 'flex-end' }}
              >
                <Button
                  id="btnAdd"
                  className="me-1"
                  icon="add"
                  stylingMode="contained"
                  type="default"
                  onClick={() => onSaveDetalle()}
                />
                <Button
                  id="btnAdd"
                  className="me-1"
                  icon="minus"
                  stylingMode="contained"
                  type="default"
                  onClick={() => onDeleteDetalle()}
                />
              </div>
            </CCol>
          </RowContainer>
          <CRow>
            <CCol lg="6" md="8" sm="12">
              <ValidationSummary id="summary"></ValidationSummary>
            </CCol>
          </CRow>
        </ValidationGroup>
        <RowContainer>
          <DataGrid
            ref={dataGrid}
            id="codigo"
            selection={{ mode: 'single' }}
            dataSource={fichaState?.datagrid}
            showColumnLines={true}
            showRowLines={true}
            showBorders={true}
            columnResizingMode="widget"
            onSelectionChanged={onSelectedLineChanged}
            onRowDblClick={onEditDataDetalle}
            loadPanel={{
              enabled: true,
              height: 90,
              indicatorSrc: '',
              shading: false,
              shadingColor: '',
              showIndicator: true,
              showPane: true,
              text: 'Cargando...',
              width: 200,
            }}
          >
            <Paging defaultPageSize={10} />
            <Pager
              visible={searchResults.length > 0 ? true : false}
              allowedPageSizes={getAllowedPageSizes(searchResults)}
              displayMode={'full'}
              showPageSizeSelector={true}
              showInfo={true}
              showNavigationButtons={true}
            />
            <Grouping autoExpandAll={false} />
            <Column dataField="" caption="" width="23%" />
            <Column dataField="etapaDescripcion" caption="" groupIndex={0} width="23%" />
            <Column dataField="nivel0" caption="" groupIndex={1} width="23%" />
            <Column dataField="nivel1" caption="" groupIndex={2} width="23%" />
            <Column dataField="itemCodigoUsu" caption="Ref." width="11%" />
            <Column dataField="barras" caption="Barras" width="11%" />
            <Column dataField="itemDescripcion" caption="Descripción" width="11%" />
            <Column dataField="color" caption="Color" width="11%" />
            <Column dataField="kitCompCantidad" caption="Cantidad" width="11%" />
            <Column dataField="valor" caption="Valor" width="11%" />
            <Column dataField="itemUnidadCDes" caption="Unidad" width="11%" />
          </DataGrid>
        </RowContainer>
      </RowContainer>
    </>
  )
}

export default Nuevo
