import React from 'react'
import {
  CButton,
  CCol,
  CRow,
  CCard,
  CCardBody,
  CLink,
  CTooltip,
  CAlert,
  CFormLabel,
} from '@coreui/react-pro'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import { addToast } from '../../../store/toasterReducer'
import {
  ClasificacionRimpe,
  Empresa,
  TImpuestos,
  TipoAgente,
  TipoNegocio,
  ToastTypes,
} from '../../../store/types' // InfoPlan
import ValidationSummary from 'devextreme-react/validation-summary'
import {
  Validator,
  RequiredRule,
  StringLengthRule,
  AsyncRule,
  EmailRule,
} from 'devextreme-react/validator'
import ValidationGroup from 'devextreme-react/validation-group'
import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box'
import { NumberBox } from 'devextreme-react/number-box'
import { Lookup } from 'devextreme-react/lookup'
import { sidebarService } from '../../../services/sidebar.service'
import * as reducerSidebar from '../../../store/reducers'
import FileUploader from 'devextreme-react/file-uploader'
import { v4 as uuidv4 } from 'uuid'
import { UrlHelper } from '../../../helpers/urlHelper'
import { Switch } from 'devextreme-react/switch'
import { utilidades } from '../../../helpers/utilidades'
import ImageEmpresa from './component/imagenEmpresa'
import { useNavigate } from 'react-router-dom'
import {
  EdicionEmpresa,
  EmpresaBasicInfo,
  EmpresaBasicInfoSV,
  EmpresaIngresar,
  SV_EmpresaIngresar,
  TInfoPlan,
} from './types/types'
import { PopupContent } from '../../../views/componentes/popupContent'
import LoadingIndicator from '../../../views/componentes/loadingindicator/loadingindicator'
import RadioGroup from 'devextreme-react/radio-group'
import BlockUi from '../../../views/componentes/librerias/block-ui'
import { consoleService } from '../../../services/console.service'
import { ECountry } from '../../../store/enum/countries'
import { TTipoItem } from '../../../modulos/componentes/tipoItemLookUp/store/types'
import TipoItemLookUp from '../../../modulos/componentes/tipoItemLookUp/tipoItemLookUp'
import { getTipoItems } from '../../../modulos/componentes/tipoItemLookUp/helper/helper'
import './formEmpresa.scss'

interface IFormempresaProps extends React.PropsWithChildren {
  cols: number | 1 | 2
  // eslint-disable-next-line no-unused-vars
  onChange: (data: any) => void
}

const allowExtention = ['.jpg', '.jpeg']

const FILE_SIZE = 1000000

const Formempresa: React.FunctionComponent<IFormempresaProps> = (props) => {
  consoleService.log('Formempresa')

  const { cols } = props
  const loadTabSidebarState = useSelector(
    (state: RootState) => state.global.sidebar.loadTab,
  )
  const contratoEmpresaState = useSelector(
    (state: RootState) => state.pagosSuscripcion.contratoEmpresa,
  )

  const navigate = useNavigate()

  const dispatch = useDispatch()
  const validationGroupRef = React.useRef<any>()
  const imageLogo = React.useRef<any>()

  const empresaState = useSelector(
    (state: RootState) => state.global.session?.empresa,
  )
  const localState = useSelector(
    (state: RootState) => state.global.session?.local,
  )
  const usuarioState = useSelector(
    (state: RootState) => state.global.session?.usuario,
  )
  const tiposAgentesState = useSelector(
    (state: RootState) => state.global.sidebar.tiposAgentes,
  )
  const tiposNegociosState = useSelector(
    (state: RootState) => state.global.sidebar.tiposNegocios,
  )
  const dpClasificacionRimpeState = useSelector(
    (state: RootState) => state.global.sidebar.dpClasificacionRimpe,
  )
  const configEmpresaRedux = useSelector(
    (state: RootState) => state.global.sidebar.configEmpresa,
  )
  const loader = useSelector((state: RootState) => state.global.loader)
  const ingresoState = useSelector(
    (state: RootState) => state.global.session?.ingreso,
  )

  const [file, setFile] = React.useState<File | null>(null)
  const [errorsLogo, setErrorsLogo] = React.useState<any>([])
  const [dataEmpresa, setDataEmpresa] = React.useState<EdicionEmpresa>(
    defaultEditionEmpresa,
  )
  const [showErrorPopup, setShowErrorPopup] = React.useState<boolean>(false)
  const [showErrorMessages, setShowErrorMessages] = React.useState<Array<any>>(
    [],
  )
  const [loaderLocal, setLoaderLocal] = React.useState<{
    show: boolean
    message: string
  }>({ show: false, message: '' })

  const imagenMemo = React.useMemo(() => {
    return <ImageEmpresa imagenBlob={dataEmpresa?.imageSource} />
  }, [dataEmpresa])

  const parseSesionEmpresa = React.useCallback(
    (dataParse: any): Empresa => {
      consoleService.log('parseSesionEmpresa: ', dataParse)
      const sesionEmpresa: Empresa = {
        ...empresaState,
        ...dataParse,
        regimen: dataParse?.regimen ?? '',
        recintoFiscal: dataParse?.recintoFiscal ?? '',
        tipoItemExporta: dataParse?.codigoTipoItemExportar ?? null,
        utilizarImpuestos: dataParse?.utilizarImpuestos ?? 0,
        orden: dataParse?.orden ?? 0,
        uso_retencion: dataParse?.retencion,
        rimpre: dataParse?.rimpe,
        codigoTipoNegocio: dataParse?.tipoIndustria,
        tipoAgente: dataParse?.tipoAgente,
        telefono: dataParse?.telefono,
        email: dataParse?.email,
        direccion: dataParse?.direccion,
        comercial: dataParse?.comercial,
        nombre: dataParse?.razonsocial,
        precision: dataParse?.precisionDecimal ?? 2,
      }
      consoleService.log('sesionEmpresa', sesionEmpresa)
      return sesionEmpresa
    },
    [empresaState],
  )

  const playLoader = React.useCallback(() => {
    setLoaderLocal({ show: true, message: 'Cargando...' })
  }, [])

  const stopLoader = React.useCallback(() => {
    setLoaderLocal({ show: false, message: '' })
  }, [])

  const validarFormatoPermitido = React.useCallback((file) => {
    let resRetorno: boolean = false
    let smsErrorsLogo: any = []
    let extention: string = ''
    if (file && file.length > 0) {
      extention = utilidades.getExtension(file[0].name)
      extention = extention.toLowerCase()
      if (allowExtention.includes('.' + extention)) {
        smsErrorsLogo = []
        resRetorno = true
      } else {
        const sms: string = 'El formato permitido es .jpg'
        smsErrorsLogo.push({ sms })
        resRetorno = false
      }
      setErrorsLogo(smsErrorsLogo)
    }
    return resRetorno
  }, [])

  const filtraSeleccion = React.useCallback(async (buscar: number, data) => {
    const filtrado = await data.findIndex((item: any) => {
      return Number(item.codigo) === Number(buscar)
    })
    if (filtrado > -1) {
      return data[Number(filtrado)]
    }
    return null
  }, [])

  const getTiposNegocios = React.useCallback(async () => {
    if (tiposNegociosState.length === 0) {
      const data = await sidebarService.tiponegocioListar()
      dispatch(reducerSidebar.setTiposNegocios(data))
      return data
    } else {
      return tiposNegociosState
    }
  }, [dispatch, tiposNegociosState])

  const getImpuestosListar = React.useCallback(async () => {
    // if (tiposNegociosState.length === 0) {
    const data = await sidebarService.impuestosListar()
    // dispatch(reducerSidebar.setTiposNegocios(data))
    let impuestosApi: Array<TImpuestos> | [] = []
    if (data?.auto && data?.auto.length > 0) {
      impuestosApi = data.auto
    }
    return impuestosApi
  }, [])

  const getTiposAgentes = React.useCallback(async () => {
    if (tiposAgentesState.length === 0) {
      const data = await sidebarService.tipoagenteListar()
      dispatch(reducerSidebar.setTiposAgentes(data))
      return data
    } else {
      return tiposAgentesState
    }
  }, [dispatch, tiposAgentesState])

  const getClasificacionRimpe = React.useCallback(async () => {
    if (dpClasificacionRimpeState.length === 0) {
      const data = await sidebarService.clasificacionRimpeListar()
      dispatch(reducerSidebar.setDpClaificacionRimpe(data))
      return data
    } else {
      return dpClasificacionRimpeState
    }
  }, [dispatch, dpClasificacionRimpeState])

  const setToast = React.useCallback(
    (texto, type: ToastTypes) => {
      dispatch(
        addToast({
          id: '',
          autoHide: 3500,
          title: 'Acatha',
          content: texto,
          fade: true,
          type: type,
        }),
      )
    },
    [dispatch],
  )

  const uploadFile = React.useCallback(
    async (file: any, type: string, fileName: string) => {
      const fileUpload = {
        tipo: type,
        archivo: file,
        usuario: usuarioState?.codigo,
        ruc: empresaState?.ruc,
        nombre: fileName,
        password: '',
      }
      playLoader()
      try {
        const data = await sidebarService.docEmpresaUplod(fileUpload)
        stopLoader()
        if (data['error'] === true) {
          setToast(
            JSON.stringify('Error al subir archivo ' + data['message']),
            ToastTypes.Warning,
          )
        }
        setDataEmpresa({ ...dataEmpresa, imageSource: '' })
        imageLogo.current.instance.reset()
        setFile(null)
        return await data
      } catch (error) {
        stopLoader()
        consoleService.error(error)
        setToast(JSON.stringify(error), ToastTypes.Danger)
      }
    },
    [empresaState, playLoader, setToast, stopLoader, usuarioState, dataEmpresa],
  )

  const parseApiEmpresaDataRedux = React.useCallback(async (apiresult: any) => {
    const tipoAgente: TipoAgente | null = apiresult.tipoAgente
    const tipoIndustria: TipoNegocio | null = apiresult.tipoIndustria

    let imageLogo: string = ''
    if (apiresult.logo && String(apiresult.logo).length > 0) {
      imageLogo = apiresult.logo
    }

    const infoPlan: TInfoPlan = {
      cadena: apiresult?.infoPlan?.cadena ?? '',
      estado: apiresult?.infoPlan?.estado ?? '',
      id: apiresult?.infoPlan?.id ?? 0,
      plan: apiresult?.infoPlan?.plan ?? '',
      precio: apiresult?.infoPlan?.precio ?? 0,
    }

    const infoEmpresa: EmpresaBasicInfo = {
      ruc: apiresult.ruc,
      razonSocial: apiresult?.razonSocial ?? '',
      nombreComercial: apiresult?.nombreComercial ?? '',
      direccion: apiresult?.direccion ?? '',
      email: apiresult?.email ?? '',
      telefono: apiresult?.telefono ?? '',
      codTipoAgente: parseInt(apiresult?.tipoAgente?.codigo) ?? null,
      codTipoIndustria: parseInt(apiresult?.tipoIndustria?.codigo) ?? null,
      tipoAgente: tipoAgente ?? null,
      tipoIndustria: tipoIndustria ?? null,
      descuento: parseFloat(apiresult?.descuento) ?? 0,
      codigoPlan: parseInt(apiresult.infoPlan?.id) ?? 1,
      logo: imageLogo ?? '',
      retencion: apiresult.retencion === '1' ? true : false,
      isRimpe: apiresult.isRimpe ?? false,
      rimpe: apiresult.rimpe ?? null,
      orden: parseInt(apiresult?.orden) ?? 0,
      transparencia: apiresult?.transparencia ?? '',
      contribuyente: apiresult?.contribuyente ?? '',
      precisionDecimal: apiresult?.precision ?? 0,
      infoPlan: infoPlan,
    }

    const infoEmpresaSV: EmpresaBasicInfoSV = {
      tipoItemExportar: apiresult?.tipoItemExportar ?? null,
      recintoFiscal: apiresult?.recintoFiscal ?? '',
      regimen: apiresult?.regimen ?? '',
      utilizarImpuestos: apiresult?.utilizarImpuestos ?? '',
      haciendaapikey: apiresult?.haciendaapikey ?? '',
      nrc: apiresult?.nrc ?? '',
    }

    const datosEmpresa = {
      ...infoEmpresa,
      ...infoEmpresaSV,
    }

    return await datosEmpresa
  }, [])

  const parseApiEmpresaData = React.useCallback(
    async (apiresult: any) => {
      let tipoAgente: TipoAgente | null = null
      let tipoIndustria: TipoNegocio | null = null
      let rimpeEmpresa: ClasificacionRimpe | null = null
      let tipoItemExportar: TTipoItem | null = null

      if (apiresult.tiposAgenteApi) {
        const filtrado = await filtraSeleccion(
          parseInt(apiresult?.TAG_CODIGO),
          apiresult.tiposAgenteApi,
        )
        if (filtrado !== null) {
          tipoAgente = filtrado
        }
      }

      if (apiresult.tipoNegociosApi) {
        const filtrado = await filtraSeleccion(
          parseInt(apiresult?.tipo_negocio_id),
          apiresult.tipoNegociosApi,
        )
        if (filtrado !== null) {
          tipoIndustria = filtrado
        }
      }

      if (
        apiresult.dpClasificacionRimpe.length > 0 &&
        apiresult.rimpeCodigo !== null
      ) {
        const filtrado = await filtraSeleccion(
          parseInt(apiresult?.rimpeCodigo),
          apiresult.dpClasificacionRimpe,
        )
        if (filtrado !== null) {
          rimpeEmpresa = filtrado
        }
      }

      let imageLogo: string = ''
      if (
        apiresult?.EMP_LOGO &&
        (apiresult.EMP_LOGO.length > 0 || apiresult.EMP_LOGO !== 'null')
      ) {
        imageLogo = apiresult.EMP_LOGO
      }
      const infoPlan: TInfoPlan = {
        cadena: '',
        estado: '',
        id: 0,
        plan: '',
        precio: 0,
      }

      if (contratoEmpresaState.codigoPlanVigente !== undefined) {
        const plan: any = await sidebarService.planObtener(
          contratoEmpresaState.codigoPlanVigente,
        )
        if (plan) {
          infoPlan.id = plan?.id ?? 0
          infoPlan.plan = plan?.plan ?? ''
          infoPlan.cadena = plan?.cadena ?? ''
          infoPlan.precio = Number(plan?.precio) ?? 0
        }
      }

      if (apiresult.tipoItemExportar) {
        const filtrado = await filtraSeleccion(
          parseInt(apiresult?.tipoItemExportar),
          apiresult.dpTipoItemExportar,
        )
        if (filtrado !== null) {
          tipoItemExportar = filtrado
        }
      }

      const infoEmpresa: EmpresaBasicInfo = {
        ruc: apiresult.EMP_RUC,
        razonSocial: apiresult?.EMP_NOMBRE ?? '',
        nombreComercial: apiresult?.EMP_NCOMERCIAL ?? '',
        direccion: apiresult?.EMP_DIRECCION ?? '',
        email: apiresult?.EMP_EMAIL ?? '',
        telefono: apiresult?.EMP_TELEFONO ?? '',
        codTipoAgente: parseInt(apiresult?.TAG_CODIGO) ?? null,
        codTipoIndustria: parseInt(apiresult?.tipo_negocio_id) ?? null,
        tipoAgente: tipoAgente ?? null,
        tipoIndustria: tipoIndustria ?? null,
        codigoPlan: parseInt(apiresult.plan_id) ?? 1,
        logo: imageLogo ?? '',
        retencion: apiresult.EMP_ARETENCION === '1' ? true : false,
        isRimpe: rimpeEmpresa !== null ? true : false,
        rimpe: rimpeEmpresa ?? null,
        orden: parseInt(apiresult?.EMP_ORDEN) ?? 0,
        transparencia: apiresult?.EMP_TRANSPARENCIA ?? '',
        contribuyente: apiresult?.EMP_CONTRIBUYENTE ?? 0,
        precisionDecimal: apiresult?.precisionDecimal ?? 0,
        descuento: parseFloat(apiresult?.descuento) ?? 0,
        infoPlan: infoPlan,
      }

      const infoEmpresaSV: EmpresaBasicInfoSV = {
        tipoItemExportar: tipoItemExportar ?? null,
        recintoFiscal: apiresult?.recintoFiscal ?? '',
        regimen: apiresult?.regimen ?? '',
        utilizarImpuestos: apiresult?.utilizarImpuestos ?? '',
        haciendaapikey: apiresult?.haciendaapikey ?? '',
        nrc: apiresult?.nrc ?? '',
      }

      const datosEmpresa = {
        ...infoEmpresa,
        ...infoEmpresaSV,
      }
      return await datosEmpresa
    },
    [filtraSeleccion, contratoEmpresaState],
  )

  const initDatosEmpresa = React.useCallback(
    async (reload: boolean = false) => {
      playLoader()
      const dataEmpresaEdition = { ...defaultEditionEmpresa }

      try {
        let tiposAgenteApi: Array<TipoNegocio> | [] = []
        let tipoNegociosApi: Array<TipoNegocio> | [] = []
        let clasificacionRimpeApi: Array<ClasificacionRimpe> | [] = []
        let impuestosApi: Array<TImpuestos> | [] = []
        let tipoItemsApi: Array<TTipoItem> | [] = []

        let dataApi: any = []
        let infoParse: EmpresaBasicInfo

        //read state redux configEmpresa
        if (configEmpresaRedux.ruc && configEmpresaRedux.ruc !== '') {
          tiposAgenteApi = configEmpresaRedux.dpTipoAgente ?? []
          tipoNegociosApi = configEmpresaRedux.dpTipoIndustria ?? []
          clasificacionRimpeApi = configEmpresaRedux.dpClasificacionRimpe ?? []

          //parse data state redux configEmpresa to state local

          infoParse = await parseApiEmpresaDataRedux(configEmpresaRedux)
        } else {
          tipoItemsApi = await getTipoItems()
          tipoNegociosApi = await getTiposNegocios()
          impuestosApi = await getImpuestosListar()
          tiposAgenteApi = await getTiposAgentes()
          clasificacionRimpeApi = await getClasificacionRimpe()
          dataApi = await sidebarService.datosEmpresaObtener({})
          dataApi.tiposAgenteApi = tiposAgenteApi
          dataApi.tipoNegociosApi = tipoNegociosApi
          dataApi.dpClasificacionRimpe = clasificacionRimpeApi
          dataApi.dpTipoItemExportar = tipoItemsApi
          infoParse = await parseApiEmpresaData(dataApi)
        }

        if (reload) {
          tipoItemsApi = await getTipoItems()
          tipoNegociosApi = await getTiposNegocios()
          impuestosApi = await getImpuestosListar()
          tiposAgenteApi = await getTiposAgentes()
          clasificacionRimpeApi = await getClasificacionRimpe()
          dataApi = await sidebarService.datosEmpresaObtener({})
          dataApi.tiposAgenteApi = tiposAgenteApi
          dataApi.tipoNegociosApi = tipoNegociosApi
          dataApi.dpClasificacionRimpe = clasificacionRimpeApi
          dataApi.dpTipoItemExportar = tipoItemsApi
          infoParse = await parseApiEmpresaData(dataApi)
        }

        consoleService.log('impuestosApi: ', impuestosApi)

        dataEmpresaEdition.ruc = infoParse.ruc
        dataEmpresaEdition.razonSocial = infoParse.razonSocial
        dataEmpresaEdition.nombreComercial = infoParse.nombreComercial
        dataEmpresaEdition.direccion = infoParse.direccion
        dataEmpresaEdition.email = infoParse.email
        dataEmpresaEdition.telefono = infoParse.telefono
        dataEmpresaEdition.transparencia = infoParse.transparencia
        dataEmpresaEdition.contribuyente = infoParse.contribuyente
        dataEmpresaEdition.isRimpe = infoParse.isRimpe
        dataEmpresaEdition.rimpe = infoParse.rimpe
        dataEmpresaEdition.retencion = infoParse.retencion
        dataEmpresaEdition.orden = infoParse.orden
        dataEmpresaEdition.codigoPlan = infoParse.codigoPlan
        dataEmpresaEdition.logo = infoParse.logo
        dataEmpresaEdition.descuento = infoParse.descuento
        dataEmpresaEdition.dpTipoAgente = tiposAgenteApi ?? []
        dataEmpresaEdition.dpImpuestos = impuestosApi ?? []
        dataEmpresaEdition.dpTipoIndustria = tipoNegociosApi ?? []
        dataEmpresaEdition.dpClasificacionRimpe = clasificacionRimpeApi ?? []
        dataEmpresaEdition.tipoIndustria = infoParse.tipoIndustria ?? null
        dataEmpresaEdition.tipoAgente = infoParse.tipoAgente ?? null
        dataEmpresaEdition.infoPlan = infoParse.infoPlan ?? null
        dataEmpresaEdition.dpTipoItemExportar = tipoItemsApi ?? []
        dataEmpresaEdition.tipoItemExportar =
          infoParse?.tipoItemExportar ?? null
        dataEmpresaEdition.recintoFiscal = infoParse?.recintoFiscal ?? null
        dataEmpresaEdition.regimen = infoParse?.regimen ?? null
        dataEmpresaEdition.utilizarImpuestos =
          infoParse?.utilizarImpuestos ?? null
        dataEmpresaEdition.imageSource = infoParse.logo ?? null
        dataEmpresaEdition.haciendaapikey = infoParse.haciendaapikey ?? ''
        dataEmpresaEdition.nrc = infoParse.nrc ?? ''
        dataEmpresaEdition.descuento = infoParse.descuento ?? 0
        dataEmpresaEdition.precisionDecimal = infoParse.precisionDecimal ?? 0

        consoleService.log('dataEmpresaEdition: ', dataEmpresaEdition)

        setDataEmpresa(dataEmpresaEdition)
        dispatch(reducerSidebar.setConfigEmpresa(dataEmpresaEdition))

        stopLoader()

        return await dataApi
      } catch (error) {
        consoleService.error(error)
        stopLoader()
      }
    },
    [
      dispatch,
      parseApiEmpresaDataRedux,
      playLoader,
      stopLoader,
      configEmpresaRedux,
      parseApiEmpresaData,
      getTiposAgentes,
      getTiposNegocios,
      getClasificacionRimpe,
      getImpuestosListar,
    ],
  )

  const onHandleSave = React.useCallback(async () => {
    const result = validationGroupRef.current.instance.validate()
    if (result.isValid === false) {
      setToast('Tiene errores por favor verifíquelos.', ToastTypes.Info)

      setShowErrorPopup(true)
      setShowErrorMessages(result.brokenRules)

      return false
    } else {
      setShowErrorPopup(false)
      setShowErrorMessages([])

      if (
        configEmpresaRedux.tipoIndustria &&
        Number(configEmpresaRedux.tipoIndustria.codigo) < 0
      ) {
        setToast('El Tipo de negocio es requerido', ToastTypes.Info)
        return false
      }

      let setNameLogo: string =
        configEmpresaRedux.logo.split('/').pop() ?? 'logoblanco.jpg'

      if (file !== null) {
        const extentionFile: string = file['name'].split('.').pop() ?? ''

        if (extentionFile !== 'jpg') {
          setToast(
            'El formato del logo ingresado no es permitido.',
            ToastTypes.Info,
          )
          return false
        }

        setNameLogo =
          (empresaState && empresaState['codigo']) + '.' + extentionFile
      }

      playLoader()

      const empresaActualizar: EmpresaIngresar = {
        ruc: configEmpresaRedux.ruc,
        razonsocial: dataEmpresa?.razonSocial ?? '',
        comercial: dataEmpresa?.nombreComercial ?? '',
        direccion: dataEmpresa?.direccion ?? '',
        email: dataEmpresa?.email ?? '',
        telefono: dataEmpresa?.telefono ?? '',
        agente: dataEmpresa?.tipoAgente?.codigo ?? null,
        tipo_negocio_id: dataEmpresa?.tipoIndustria?.codigo ?? null,
        descuento: dataEmpresa.descuento ?? 0,
        logo: setNameLogo,
        transparencia: configEmpresaRedux.transparencia,
        contribuyente: dataEmpresa?.contribuyente ?? '',
        rimpeEmpresa:
          dataEmpresa?.isRimpe === true
            ? dataEmpresa?.rimpe?.codigo ?? null
            : null,
        aretencion: dataEmpresa?.retencion === true ? 1 : 0,
        orden: dataEmpresa?.orden,
        precisionDecimal: dataEmpresa?.precisionDecimal,
      }
      let empresaActualizarSV: SV_EmpresaIngresar | null = null
      if (empresaState.codigoPais === ECountry.ElSalvador) {
        empresaActualizarSV = {
          recintoFiscal: dataEmpresa?.recintoFiscal ?? '',
          regimen: dataEmpresa?.regimen ?? '',
          utilizarImpuestos: dataEmpresa?.utilizarImpuestos ?? 0,
          codigoTipoItemExportar: null,
          nrc: dataEmpresa?.nrc ?? '',
        }
        if (dataEmpresa?.tipoItemExportar?.codigo !== -1) {
          empresaActualizarSV.codigoTipoItemExportar =
            dataEmpresa?.tipoItemExportar.codigo ?? null
        }
      }

      const saveEmpresa: any = {
        infoEmpresa: {
          ...empresaActualizar,
          ...empresaActualizarSV,
          tipoAgente: empresaActualizar.agente,
        },
      }

      try {
        const data = await sidebarService.datosEmpresaActualizar(saveEmpresa)

        stopLoader()
        if (data['error'] === true) {
          setToast(data['message'], ToastTypes.Warning)
        } else {
          dispatch(
            reducerSidebar.setConfigEmpresa({ ...defaultEditionEmpresa }),
          )

          const toSessionEmpresa = parseSesionEmpresa({
            ...empresaActualizar,
            ...empresaActualizarSV,
          })
          dispatch(
            reducerSidebar.establecerSession({
              empresa: toSessionEmpresa,
              local: localState,
              usuario: usuarioState,
              ingreso: ingresoState,
            }),
          )

          if (file !== null) {
            await uploadFile(file, 'logo', setNameLogo ?? '')
          } else {
            // migrar nombre de logo el los directorios del server para que tengan el nombre codigoEMpres. jpg
            const extentionFile: string = setNameLogo.split('.').pop() ?? ''
            const imagesAvailable = [
              'logoblanco.jpg',
              (empresaState && empresaState['codigo']) + '.' + extentionFile,
            ]
            if (!imagesAvailable.includes(setNameLogo)) {
              const urls = UrlHelper.getUrls()
              const renombrar = {
                ruc: configEmpresaRedux.ruc,
                origen: urls.pathLogos,
                directorio: 'SolucionEmpresarial/SigNum/isologos/',
                newname:
                  (empresaState && empresaState['codigo']) +
                  '.' +
                  extentionFile,
                oldname: setNameLogo.split('/').pop(),
              }
              await sidebarService.renombrarArchivo(renombrar)
            }
          }

          initDatosEmpresa(true)
          setToast(data['message'], ToastTypes.Success)
          props.onChange(data) // retorna el cambio al componente padre
        }
        return await data
      } catch (error) {
        consoleService.error(error)
        stopLoader()
        setToast(error, ToastTypes.Danger)
      }
    }
  }, [
    setToast,
    configEmpresaRedux,
    file,
    playLoader,
    dataEmpresa,
    empresaState,
    stopLoader,
    dispatch,
    parseSesionEmpresa,
    localState,
    usuarioState,
    ingresoState,
    initDatosEmpresa,
    props,
    uploadFile,
  ])

  const verificarSeleccion = React.useCallback((value) => {
    const validador = 0
    return new Promise((resolve) => {
      setTimeout(function () {
        if (value['value']['codigo'] !== undefined) {
          resolve(
            Number(value['value']['codigo']) > Number(validador) === true
              ? true
              : false,
          )
        } else {
          resolve(true)
        }
      }, 1000)
    })
  }, [])

  const validateAsyncSeleccion = React.useCallback(
    (datoSeleccion) => {
      return verificarSeleccion(datoSeleccion)
    },
    [verificarSeleccion],
  )

  const convertBase64 = React.useCallback(async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }, [])

  const onSelectedFilesChangedDec = React.useCallback(
    async (e) => {
      setFile(e[0])
      if (e && e.length > 0) {
        for (const item of e) {
          let fileCoded: any = ''
          await convertBase64(item)
            .then((data) => {
              fileCoded = data
            })
            .catch((data) => {
              fileCoded = data
            })
          setDataEmpresa({ ...dataEmpresa, imageSource: fileCoded ?? null })
        }
      }
    },
    [convertBase64, dataEmpresa],
  )

  const onChangeDesc = React.useCallback(
    (v) => {
      setDataEmpresa({ ...dataEmpresa, descuento: v })
    },
    [dataEmpresa],
  )

  const onChangePrecisionDecimal = React.useCallback(
    (v) => {
      setDataEmpresa({ ...dataEmpresa, precisionDecimal: v })
    },
    [dataEmpresa],
  )

  const onChangeRazonSocial = React.useCallback(
    (v) => {
      setDataEmpresa({ ...dataEmpresa, razonSocial: v })
    },
    [dataEmpresa],
  )

  const onChangeNComercial = React.useCallback(
    (v) => {
      setDataEmpresa({ ...dataEmpresa, nombreComercial: v })
    },
    [dataEmpresa],
  )

  const onChangeDireccion = React.useCallback(
    (v) => {
      setDataEmpresa({ ...dataEmpresa, direccion: v })
    },
    [dataEmpresa],
  )

  const onChangeEmail = React.useCallback(
    (v) => {
      setDataEmpresa({ ...dataEmpresa, email: v })
    },
    [dataEmpresa],
  )

  const onChangeTelefono = React.useCallback(
    (v) => {
      setDataEmpresa({ ...dataEmpresa, telefono: v })
    },
    [dataEmpresa],
  )

  const onChangeTAgente = React.useCallback(
    (v) => {
      setDataEmpresa({ ...dataEmpresa, tipoAgente: v })
    },
    [dataEmpresa],
  )

  const onChangeTIndustria = React.useCallback(
    (v) => {
      setDataEmpresa({ ...dataEmpresa, tipoIndustria: v })
    },
    [dataEmpresa],
  )

  const onChangeContribuyente = React.useCallback(
    (v) => {
      setDataEmpresa({ ...dataEmpresa, contribuyente: v })
    },
    [dataEmpresa],
  )

  const onChangeIsRimpe = React.useCallback(
    (data) => {
      // setIsRimpe(data)
      // setRimpe(data === false ? null : dpClasificacionRimpeState[0])

      setDataEmpresa({
        ...dataEmpresa,
        isRimpe: data,
        rimpe: data === false ? null : dpClasificacionRimpeState[0],
      })
    },
    [dpClasificacionRimpeState, dataEmpresa],
  )

  const onChangeRimpe = React.useCallback(
    (v) => {
      setDataEmpresa({ ...dataEmpresa, rimpe: v })
    },
    [dataEmpresa],
  )

  const onChangeIsRetencion = React.useCallback(
    (v) => {
      // setIsRetencion(data)
      setDataEmpresa({ ...dataEmpresa, retencion: v })
    },
    [dataEmpresa],
  )

  const onChangeOrden = React.useCallback(
    (v) => {
      setDataEmpresa({ ...dataEmpresa, orden: v })
    },
    [dataEmpresa],
  )

  const onChangeRecintoFiscal = React.useCallback(
    (v) => {
      setDataEmpresa({ ...dataEmpresa, recintoFiscal: v })
    },
    [dataEmpresa],
  )

  const onChangeRegimen = React.useCallback(
    (v) => {
      setDataEmpresa({ ...dataEmpresa, regimen: v })
    },
    [dataEmpresa],
  )

  const onChangeUtilizarImpuestos = React.useCallback(
    (v) => {
      consoleService.log('on ', v)
      setDataEmpresa({ ...dataEmpresa, utilizarImpuestos: v === true ? 1 : 0 })
    },
    [dataEmpresa],
  )

  const onChangeTipoItemExportar = React.useCallback(
    (v) => {
      setDataEmpresa({ ...dataEmpresa, tipoItemExportar: v })
    },
    [dataEmpresa],
  )

  const onChangeNrc = React.useCallback(
    (v) => {
      setDataEmpresa({ ...dataEmpresa, nrc: v })
    },
    [dataEmpresa],
  )

  const onChangeFile = React.useCallback(
    (data) => {
      validarFormatoPermitido(data)
      onSelectedFilesChangedDec(data)
    },
    [validarFormatoPermitido, onSelectedFilesChangedDec],
  )

  React.useEffect(() => {
    if (
      loadTabSidebarState === 'config_empresa_local' &&
      contratoEmpresaState !== undefined
    ) {
      initDatosEmpresa(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadTabSidebarState, contratoEmpresaState])

  function identification() {
    if (empresaState.codigoPais === ECountry.ElSalvador) {
      return (
        <CRow>
          <CCol lg="12" md="12" sm="12" className="mt-1 mb-1">
            <div className="dx-field">
              <div className="dx-field-label">{'NIT*:'}</div>
              <div className="dx-field-value d-flex justify-content-end">
                <TextBox
                  value={dataEmpresa.ruc ?? ''}
                  showClearButton={true}
                  width="90%"
                  maxLength={14}
                  readOnly
                >
                  <Validator>
                    <RequiredRule message="- NIT: Este campo es requerido" />
                    <StringLengthRule
                      min="1"
                      message="- NIT: Este campo debe tener al menos 1 caracteres"
                    />
                    <StringLengthRule
                      max="14"
                      message="- NIT: Este campo no puede tener mas de 14 caracteres"
                    />
                  </Validator>
                </TextBox>
              </div>
            </div>
          </CCol>
        </CRow>
      )
    } else {
      return (
        <CRow>
          <CCol lg="12" md="12" sm="12">
            <div className="dx-field">
              <div className="dx-field-label">{'RUC*:'}</div>
              <div className="dx-field-value d-flex justify-content-end">
                <TextBox
                  value={dataEmpresa.ruc ?? ''}
                  showClearButton={true}
                  width="80%"
                  maxLength={13}
                  readOnly
                >
                  <Validator>
                    <RequiredRule message="- RUC: Este campo es requerido" />
                    <StringLengthRule
                      min="1"
                      message="- RUC: Este campo debe tener al menos 1 caracteres"
                    />
                    <StringLengthRule
                      max="13"
                      message="- RUC: Este campo no puede tener mas de 13 caracteres"
                    />
                  </Validator>
                </TextBox>
              </div>
            </div>
          </CCol>
        </CRow>
      )
    }
  }

  const fieldTipoNeg = React.useCallback((data) => {
    if (data && data?.data !== null) {
      if (data?.data?.descripcion && data?.data?.descripcion !== null) {
        return (
          <CTooltip
            key={'tool-tipoNegocio'}
            placement="top"
            content={data?.data?.descripcion ?? ''}
          >
            <div className="custom-item">
              <div className="product-name">
                {data?.data?.descripcion ?? ''}{' '}
              </div>
            </div>
          </CTooltip>
        )
      }
    }
  }, [])

  return (
    <>
      {showErrorPopup && (
        <PopupContent
          show={showErrorPopup}
          title={'Acatha'}
          subTitle={'Por favor revise las siguientes advertencias:'}
          onClose={() => {
            setShowErrorPopup(false)
            setShowErrorMessages([])
          }}
          size={'sm'}
          canClose={true}
          height={'auto'}
        >
          <CAlert color="danger">
            <ul style={{ listStyle: 'none' }}>
              {showErrorMessages.map(function (item, id) {
                return <li key={id}>{item['message']}</li>
              })}
            </ul>
          </CAlert>
        </PopupContent>
      )}
      <div id="configEmpresa">
        <BlockUi
          tag="div"
          loader={LoadingIndicator}
          blocking={loaderLocal?.show ?? false}
          message={loaderLocal?.message ?? ''}
        >
          <fieldset disabled={loaderLocal.show}>
            <ValidationGroup ref={validationGroupRef} className="mb-5">
              <CCard style={{ border: 1 }} className="m-0">
                <CCardBody>
                  <CRow>
                    <CCol lg={cols === 1 ? 12 : 6}>
                      {identification()}

                      <CRow>
                        <CCol lg="12" md="12" sm="12">
                          <div className="dx-field">
                            <CRow>
                              <CCol lg="12" md="12" sm="12">
                                <div className="dx-field-label">
                                  {'Razón Social*:'}
                                </div>
                              </CCol>
                            </CRow>
                            <CRow>
                              <CCol lg="12" md="12" sm="12">
                                <TextBox
                                  value={dataEmpresa?.razonSocial ?? ''}
                                  onValueChange={onChangeRazonSocial}
                                  showClearButton={true}
                                  width="100%"
                                >
                                  <Validator>
                                    <RequiredRule message="- Razón Social: Este campo es requerido" />
                                    <StringLengthRule
                                      min="4"
                                      message="- Razón Social: Este campo debe tener al menos 4 caracteres"
                                    />
                                    <StringLengthRule
                                      max="250"
                                      message="- Razón Social: Este campo no puede tener mas de 250 caracteres"
                                    />
                                  </Validator>
                                </TextBox>
                              </CCol>
                            </CRow>
                          </div>
                        </CCol>
                      </CRow>

                      <CRow>
                        <CCol lg="12" md="12" sm="12">
                          <div className="dx-field">
                            <CRow>
                              <CCol lg="12" md="12" sm="12">
                                <div
                                  className="dx-field-label"
                                  style={{ width: '100%' }}
                                >
                                  {'Nombre Comercial*:'}
                                </div>
                              </CCol>
                            </CRow>
                            <CRow>
                              <CCol lg="12" md="12" sm="12">
                                <TextBox
                                  value={dataEmpresa?.nombreComercial ?? ''}
                                  onValueChange={onChangeNComercial}
                                  showClearButton={true}
                                  width="100%"
                                >
                                  <Validator>
                                    <RequiredRule message="- Nombre Comercial: Este campo es requerido" />
                                    <StringLengthRule
                                      min="4"
                                      message="- Nombre Comercial: Este campo debe tener al menos 4 caracteres"
                                    />
                                    <StringLengthRule
                                      max="250"
                                      message="- Nombre Comercial: Este campo no puede tener mas de 250 caracteres"
                                    />
                                  </Validator>
                                </TextBox>
                              </CCol>
                            </CRow>
                          </div>
                        </CCol>
                      </CRow>

                      <CRow>
                        <CCol lg="12" md="12" sm="12">
                          <div className="dx-field">
                            <CRow>
                              <CCol lg="12" md="12" sm="12">
                                <div className="dx-field-label">
                                  {'Dirección*:'}
                                </div>
                              </CCol>
                            </CRow>
                            <CRow>
                              <CCol lg="12" md="12" sm="12">
                                <TextBox
                                  value={dataEmpresa?.direccion ?? ''}
                                  onValueChange={onChangeDireccion}
                                  showClearButton={true}
                                  width="100%"
                                >
                                  <Validator>
                                    <RequiredRule message="- Dirección: Este campo es requerido" />
                                    <StringLengthRule
                                      min="8"
                                      message="- Dirección: Este campo debe tener al menos 8 caracteres"
                                    />
                                    <StringLengthRule
                                      max="200"
                                      message="- Dirección: Este campo no puede tener mas de 200 caracteres"
                                    />
                                  </Validator>
                                </TextBox>
                              </CCol>
                            </CRow>
                          </div>
                        </CCol>
                      </CRow>

                      <CRow>
                        <CCol lg="12" md="12" sm="12">
                          <div className="dx-field">
                            <CRow>
                              <CCol lg="12" md="12" sm="12">
                                <div className="dx-field-label">
                                  {'Email*:'}
                                </div>
                              </CCol>
                            </CRow>
                            <CRow>
                              <CCol lg="12" md="12" sm="12">
                                <TextBox
                                  value={dataEmpresa?.email ?? ''}
                                  onValueChange={onChangeEmail}
                                  showClearButton={true}
                                  width="100%"
                                >
                                  <Validator>
                                    <RequiredRule message="- Email: Este campo es requerido" />
                                    <StringLengthRule
                                      min="8"
                                      message="- Email: Este campo debe tener al menos 8 caracteres"
                                    />
                                    <StringLengthRule
                                      max="70"
                                      message="- Email: Este campo no puede tener mas de 70 caracteres"
                                    />
                                    <EmailRule message="- Email: El Email ingresado es invalido" />
                                  </Validator>
                                </TextBox>
                              </CCol>
                            </CRow>
                          </div>
                        </CCol>
                      </CRow>

                      <CRow>
                        <CCol lg="12" md="12" sm="12" className="mt-2">
                          <div className="dx-field">
                            <div className="dx-field-label">{'Teléfono*:'}</div>
                            <div className="dx-field-value d-flex justify-content-end">
                              <TextBox
                                value={dataEmpresa?.telefono ?? ''}
                                onValueChange={onChangeTelefono}
                                showClearButton={true}
                                width="80%"
                              >
                                <Validator>
                                  <RequiredRule message="- Teléfono: Este campo es requerido" />
                                  <StringLengthRule
                                    min="6"
                                    message="- Teléfono: Este campo debe tener al menos 6 caracteres"
                                  />
                                  <StringLengthRule
                                    max="45"
                                    message="- Teléfono: Este campo no puede tener mas de 45 caracteres"
                                  />
                                </Validator>
                              </TextBox>
                            </div>
                          </div>
                        </CCol>
                      </CRow>

                      <CRow>
                        <CCol lg="12" md="12" sm="12">
                          <div className="dx-field">
                            <CRow>
                              <CCol lg="12" md="12" sm="12">
                                <div
                                  className="dx-field-label"
                                  style={{ width: '100%' }}
                                >
                                  {'Tipo de agente*:'}
                                </div>
                              </CCol>
                            </CRow>
                            <CRow>
                              <CCol lg="12" md="12" sm="12">
                                <Lookup
                                  width="100%"
                                  dataSource={dataEmpresa.dpTipoAgente ?? []}
                                  displayExpr="descripcion"
                                  searchEnabled={true}
                                  searchMode={'contains'}
                                  searchExpr={'descripcion'}
                                  searchTimeout={200}
                                  minSearchLength={3}
                                  showDataBeforeSearch={true}
                                  placeholder="Seleccionar"
                                  showClearButton={true}
                                  value={dataEmpresa?.tipoAgente ?? null}
                                  onValueChange={onChangeTAgente}
                                  itemRender={itemRender}
                                >
                                  <Validator></Validator>
                                </Lookup>
                              </CCol>
                            </CRow>
                          </div>
                        </CCol>
                      </CRow>
                    </CCol>
                    <CCol lg={cols === 1 ? 12 : 6}>
                      <CRow>
                        <CCol lg="12" md="12" sm="12">
                          <div className="dx-field">
                            <CRow>
                              <CCol lg="12" md="12" sm="12">
                                <div
                                  className="dx-field-label"
                                  style={{ width: '100%' }}
                                >
                                  {'Tipo de negocio*:'}
                                </div>
                              </CCol>
                            </CRow>
                            <CRow>
                              <CCol lg="12" md="12" sm="12">
                                <Lookup
                                  width="100%"
                                  dataSource={dataEmpresa.dpTipoIndustria ?? []}
                                  displayExpr="descripcion"
                                  searchEnabled={true}
                                  searchMode={'contains'}
                                  searchExpr={'descripcion'}
                                  searchTimeout={200}
                                  minSearchLength={3}
                                  showDataBeforeSearch={true}
                                  placeholder="Seleccionar"
                                  showClearButton={true}
                                  value={dataEmpresa?.tipoIndustria ?? null}
                                  onValueChange={onChangeTIndustria}
                                  itemRender={itemRender}
                                  fieldComponent={fieldTipoNeg}
                                >
                                  <Validator>
                                    <RequiredRule
                                      message={
                                        '- Tipo de negocio: Este campo es requerido'
                                      }
                                    />
                                    <AsyncRule
                                      message={
                                        '- Tipo de negocio: Debe seleccionar una opción'
                                      }
                                      validationCallback={
                                        validateAsyncSeleccion
                                      }
                                    />
                                  </Validator>
                                </Lookup>
                              </CCol>
                            </CRow>
                          </div>
                        </CCol>
                      </CRow>
                      {dataEmpresa?.tipoAgente &&
                        dataEmpresa?.tipoAgente['codigo'] === 2 && (
                          <CRow>
                            <CCol lg="12" md="12" sm="12">
                              <div
                                className="dx-field"
                                style={{ width: '100%' }}
                              >
                                <CRow>
                                  <CCol lg="12" md="12" sm="12">
                                    <div
                                      className="dx-field-label"
                                      style={{ width: '100%' }}
                                    >
                                      {'Numero de Contribuyente Especial*:'}
                                    </div>
                                  </CCol>
                                </CRow>
                                <CRow>
                                  <CCol lg="12" md="12" sm="12">
                                    <TextBox
                                      value={dataEmpresa?.contribuyente ?? ''}
                                      onValueChange={onChangeContribuyente}
                                      showClearButton={true}
                                      width="100%"
                                      onKeyDown={
                                        utilidades.filtraTeclasNoNumericas
                                      }
                                      maxLength={13}
                                    >
                                      <Validator>
                                        <RequiredRule
                                          message={
                                            '- Contribuyente: Este campo es requerido'
                                          }
                                        />
                                        <StringLengthRule
                                          max="13"
                                          message="- Contribuyente: Este campo no puede tener mas de 13 caracteres"
                                        />
                                      </Validator>
                                    </TextBox>
                                  </CCol>
                                </CRow>
                              </div>
                            </CCol>
                          </CRow>
                        )}
                      {empresaState?.codigoPais !== ECountry.ElSalvador && (
                        <>
                          <CRow className="mb-1 align-items-center ">
                            <CCol sm={8}>
                              <CFormLabel className="d-flex justify-content-start  col-form-label">
                                {'Régimen rimpe*:'}
                              </CFormLabel>
                            </CCol>
                            <CCol className="d-flex justify-content-end">
                              <Switch
                                value={dataEmpresa?.isRimpe}
                                onValueChange={onChangeIsRimpe}
                                switchedOffText="NO"
                                switchedOnText="SI"
                              />
                            </CCol>
                          </CRow>

                          {dataEmpresa?.isRimpe === true && (
                            <CRow className="mb-1 align-items-center ">
                              <CCol lg="12" md="12" sm="12">
                                <RadioGroup
                                  name="selecRimpe"
                                  layout="vertical"
                                  displayExpr={'descripcion'}
                                  items={dataEmpresa.dpClasificacionRimpe ?? []}
                                  value={dataEmpresa?.rimpe}
                                  onValueChange={onChangeRimpe}
                                />
                              </CCol>
                            </CRow>
                          )}
                        </>
                      )}

                      <CRow className="mb-1 align-items-center ">
                        <CCol sm={8}>
                          <CFormLabel className="d-flex justify-content-start  col-form-label">
                            {'Agente retención*:'}
                          </CFormLabel>
                        </CCol>
                        <CCol className="d-flex justify-content-end">
                          <Switch
                            value={dataEmpresa?.retencion}
                            onValueChange={onChangeIsRetencion}
                            switchedOffText="NO"
                            switchedOnText="SI"
                          />
                        </CCol>
                      </CRow>

                      <CRow>
                        <CCol lg="12" md="12" sm="12">
                          <div className="dx-field">
                            <div className="dx-field-label">{'Orden*:'}</div>
                            <div className="dx-field-value d-flex justify-content-end">
                              <NumberBox
                                value={dataEmpresa?.orden}
                                onValueChange={onChangeOrden}
                                showClearButton={true}
                                width="45%"
                                step={1}
                                min={1}
                                max={20}
                              >
                                <Validator>
                                  <RequiredRule message="- Orden: Este campo es requerido" />
                                  <StringLengthRule
                                    min="1"
                                    message="- Orden: Este campo debe tener al menos 1 caracteres"
                                  />
                                  <StringLengthRule
                                    max="20"
                                    message="- Orden: Este campo no puede tener mas de 20 caracteres"
                                  />
                                </Validator>
                              </NumberBox>
                            </div>
                          </div>
                        </CCol>
                      </CRow>

                      {usuarioState?.rol === 'SADM' && (
                        <>
                          <CRow>
                            <CCol lg="12" md="12" sm="12" className="mt-2">
                              <div className="dx-field">
                                <div className="dx-field-label">
                                  {'Descuento*:'}
                                </div>
                                <div className="dx-field-value d-flex justify-content-end">
                                  <NumberBox
                                    value={dataEmpresa?.descuento}
                                    onValueChange={onChangeDesc}
                                    showClearButton={true}
                                    width="45%"
                                    step={1}
                                    min={0}
                                    max={100}
                                  >
                                    <Validator>
                                      <RequiredRule message="- Descuento: Este campo es requerido" />
                                      <StringLengthRule
                                        max="3"
                                        message="- Descuento: Este campo no puede tener mas de 3 caracteres"
                                      />
                                    </Validator>
                                  </NumberBox>
                                </div>
                              </div>
                            </CCol>
                          </CRow>
                          <CRow>
                            <CCol lg="12" md="12" sm="12" className="mt-2">
                              <div className="dx-field">
                                <div className="dx-field-label">
                                  {'Decimales*:'}
                                </div>
                                <div className="dx-field-value d-flex justify-content-end">
                                  <NumberBox
                                    value={dataEmpresa?.precisionDecimal}
                                    onValueChange={onChangePrecisionDecimal}
                                    showClearButton={true}
                                    width="45%"
                                    step={1}
                                    min={2}
                                    max={20}
                                  >
                                    <Validator>
                                      <RequiredRule message="- Decimales: Este campo es requerido" />
                                      <StringLengthRule
                                        min="1"
                                        message="- Decimales: Este campo debe tener al menos 1 caracteres"
                                      />
                                    </Validator>
                                  </NumberBox>
                                </div>
                              </div>
                            </CCol>
                          </CRow>
                        </>
                      )}

                      {empresaState?.codigoPais === ECountry.ElSalvador && (
                        <>
                          <CRow>
                            <CCol lg="12" md="12" sm="12" className="mt-2">
                              <div className="dx-field">
                                <div className="dx-field-label">
                                  {'Recinto Fiscal:'}
                                </div>
                                <div className="dx-field-value d-flex justify-content-end">
                                  <TextBox
                                    value={dataEmpresa?.recintoFiscal}
                                    onValueChange={onChangeRecintoFiscal}
                                    showClearButton={true}
                                  >
                                    <Validator>
                                      <StringLengthRule
                                        max="45"
                                        message="- Recinto Fiscal: Este campo no puede tener mas de 45 caracteres"
                                      />
                                    </Validator>
                                  </TextBox>
                                </div>
                              </div>
                            </CCol>
                          </CRow>
                          <CRow>
                            <CCol lg="12" md="12" sm="12" className="mt-2">
                              <div className="dx-field">
                                <div className="dx-field-label">
                                  {'Régimen :'}
                                </div>
                                <div className="dx-field-value d-flex justify-content-end">
                                  <TextBox
                                    value={dataEmpresa?.regimen}
                                    onValueChange={onChangeRegimen}
                                    showClearButton={true}
                                  >
                                    <Validator>
                                      <StringLengthRule
                                        max="45"
                                        message="- Regimen: Este campo no puede tener mas de 45 caracteres"
                                      />
                                    </Validator>
                                  </TextBox>
                                </div>
                              </div>
                            </CCol>
                          </CRow>
                          <CRow className="mb-1 align-items-center ">
                            <CCol sm={8}>
                              <CFormLabel className="d-flex justify-content-start  col-form-label">
                                {'Utilizar Impuestos:'}
                              </CFormLabel>
                            </CCol>
                            <CCol className="d-flex justify-content-end">
                              <Switch
                                value={
                                  dataEmpresa?.utilizarImpuestos === 1
                                    ? true
                                    : false
                                }
                                onValueChange={onChangeUtilizarImpuestos}
                                switchedOffText="NO"
                                switchedOnText="SI"
                              />
                            </CCol>
                          </CRow>
                          <CRow>
                            <CCol lg="12" md="12" sm="12" className="mb-2">
                              <div className="dx-field">
                                <CRow>
                                  <CCol lg="12" md="12" sm="12">
                                    <div
                                      className="dx-field-label"
                                      style={{ width: '100%' }}
                                    >
                                      {'Tipo Item Exportación:'}
                                    </div>
                                  </CCol>
                                </CRow>
                                <CRow>
                                  <CCol lg="12" md="12" sm="12">
                                    <TipoItemLookUp
                                      selected={
                                        dataEmpresa?.tipoItemExportar ?? null
                                      }
                                      onChanged={onChangeTipoItemExportar}
                                    />
                                  </CCol>
                                </CRow>
                              </div>
                            </CCol>
                          </CRow>
                          <CRow>
                            <CCol lg="12" md="12" sm="12" className="mt-2">
                              <div className="dx-field">
                                <div className="dx-field-label">
                                  {'NRC(*):'}
                                </div>
                                <div className="dx-field-value d-flex justify-content-end">
                                  <TextBox
                                    value={dataEmpresa?.nrc}
                                    onValueChange={onChangeNrc}
                                    showClearButton={true}
                                  >
                                    <Validator>
                                      <RequiredRule message="- NRC: Este campo es requerido" />
                                      <StringLengthRule
                                        max="15"
                                        message="- NRC: Este campo no puede tener mas de 15 caracteres"
                                      />
                                    </Validator>
                                  </TextBox>
                                </div>
                              </div>
                            </CCol>
                          </CRow>
                        </>
                      )}

                      <CTooltip
                        key={'tool-subirLogoEmpresa'}
                        placement="top"
                        content={
                          'Medidas recomendadas 487x295 px y en formato (.jpg) '
                        }
                      >
                        <CLink> {'Carga tu Logo'} </CLink>
                      </CTooltip>
                      <br />
                      <small>
                        {'Medidas recomendadas 487x295 px y en formato (.jpg) '}
                      </small>
                      <CRow className="sectionErrorsLogo">
                        <CCol lg="12" className="sectionErrorsLogo">
                          <ul className="sectionErrorsLogo">
                            {errorsLogo.map((objFile, i) => {
                              return (
                                <li key={i} className="smsError">
                                  {objFile['sms']}
                                </li>
                              )
                            })}
                          </ul>
                        </CCol>
                      </CRow>
                      {file !== null && (
                        <CRow className="mt-1 mb-1">
                          <CCol lg="12">
                            <CLink
                              className="card-header-action sectionErrorsLogo"
                              onClick={() => {
                                setFile(null)
                                setDataEmpresa({
                                  ...dataEmpresa,
                                  imageSource: '',
                                })
                                setErrorsLogo([])
                                imageLogo.current.instance.reset()
                              }}
                            >
                              {'Reset Logo'}
                            </CLink>
                          </CCol>
                        </CRow>
                      )}

                      <CRow className="m-0">
                        <CCol lg="12" className="sectionErrorsLogo">
                          <FileUploader
                            showFileList={false}
                            multiple={false}
                            accept={'.jpg'}
                            ref={imageLogo}
                            id="subirLogo"
                            labelText=""
                            selectButtonText="Seleccionar logo"
                            uploadMode="useForm"
                            onValueChange={onChangeFile}
                            maxFileSize={FILE_SIZE}
                            allowedFileExtensions={['.jpg', '.jpeg']}
                          />
                          <div
                            className="fileuploader-container"
                            key={uuidv4()}
                          >
                            {dataEmpresa?.imageSource && imagenMemo}
                          </div>
                        </CCol>
                      </CRow>

                      {/* {utilizarImpuestos &&
                        <CRow>
                          <CCol lg='12' className={"mt-2 mb-2"}>
                            <CCard className={"p-2"} >
                              <CRow>
                                <CCol lg='12' className={"mt-2 mb-2"}>
                                  <strong className='m-2'>{"IMPUESTOS"}</strong>
                                </CCol>
                              </CRow>
                              <CRow>
                                <CCol lg='12'>
                                  lista
                                </CCol>
                              </CRow>
                            </CCard>
                          </CCol>
                        </CRow>
                      } */}

                      {cols === 1 && (
                        <>
                          <CRow>
                            <CCol lg="10">
                              <ValidationSummary id="summary"></ValidationSummary>
                            </CCol>
                          </CRow>

                          <CRow
                            className="mt-2 mb-2"
                            id="configDataEmpresaTour"
                          >
                            <CCol className="mt-2 mb-2 d-grid">
                              <CButton
                                onClick={() => onHandleSave()}
                                variant="outline"
                                color="primary"
                              >
                                {loader.show === true
                                  ? 'POR FAVOR ESPERE...'
                                  : 'APLICAR'}
                              </CButton>
                            </CCol>
                          </CRow>
                        </>
                      )}

                      {configEmpresaRedux.infoPlan?.id !== 0 && (
                        <CRow className="mt-2" style={{ fontSize: '14px' }}>
                          <CCol lg="12">
                            <label>
                              {' '}
                              <strong> {'Mi Plan:'} </strong>{' '}
                              {configEmpresaRedux.infoPlan?.plan}
                            </label>
                          </CCol>
                          <CCol lg="12" className="mt-2 mb-2 d-grid">
                            {/* <CLink href="http://www.acatha.io/#planes" target="_blank">
                            <CButton variant="outline" color="primary" block >{'Upgrade'}</CButton>
                          </CLink> */}

                            {usuarioState != null
                              ? usuarioState.rol &&
                                (usuarioState.rol === 'ADM' ||
                                  usuarioState.rol === 'SADM') && (
                                  <CButton
                                    variant="outline"
                                    color="primary"
                                    onClick={() => {
                                      navigate('/suscripcion')
                                      dispatch(
                                        reducerSidebar.setAsideShow(false),
                                      )
                                    }}
                                  >
                                    {'MI PLAN'}
                                  </CButton>
                                )
                              : ''}
                          </CCol>
                        </CRow>
                      )}

                      <CRow>
                        <CCol lg="12"></CCol>
                      </CRow>
                    </CCol>
                  </CRow>
                  {cols === 2 && (
                    <>
                      <CRow>
                        <CCol lg="12">
                          <ValidationSummary id="summary"></ValidationSummary>
                        </CCol>
                      </CRow>

                      <CRow className="mt-2 mb-2" id="configDataEmpresaTour">
                        <CCol className="mt-2 mb-2 d-grid">
                          <CButton
                            onClick={() => onHandleSave()}
                            variant="outline"
                            color="primary"
                          >
                            {loader.show === true
                              ? 'POR FAVOR ESPERE...'
                              : 'APLICAR'}
                          </CButton>
                        </CCol>
                      </CRow>
                    </>
                  )}
                </CCardBody>
              </CCard>
            </ValidationGroup>
          </fieldset>
        </BlockUi>
      </div>
    </>
  )
  function itemRender(data) {
    return (
      <CTooltip
        key={'tool-tiposItemSelect'}
        placement="top"
        content={data?.descripcion ?? ''}
      >
        <div className="custom-item">
          <div className="product-name">{data.descripcion} </div>
        </div>
      </CTooltip>
    )
  }
}

export default Formempresa

export const defaultEditionEmpresa: EdicionEmpresa = {
  ruc: '',
  razonSocial: '',
  nombreComercial: '',
  direccion: '',
  email: '',
  telefono: '',
  tipoAgente: null,
  dpTipoAgente: [],
  dpImpuestos: [],
  tipoIndustria: null,
  dpTipoIndustria: [],
  logo: '',
  transparencia: '',
  contribuyente: '',
  orden: 1,
  retencion: false,
  isRimpe: false,
  rimpe: null,
  codigoPlan: 0,
  descuento: 0,
  infoPlan: {
    cadena: '',
    estado: '',
    id: 0,
    plan: '',
    precio: 0,
  },
  dpClasificacionRimpe: [],
  recintoFiscal: '',
  regimen: '',
  utilizarImpuestos: 0,
  dpTipoItemExportar: [],
  tipoItemExportar: null,
  imageSource: null,
  haciendaapikey: '',
  nrc: '',
  precisionDecimal: 0,
}
