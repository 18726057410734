import React from 'react'
import RowContainer from '../../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../../views/componentes/labeledInput/labeledInput'
import {
  Button,
  CheckBox,
  NumberBox,
  RadioGroup,
  TextBox,
  Validator,
} from 'devextreme-react'
import {
  ItemGenerals,
  optionsAges,
  optionsGroupsTarj,
} from '../../../../types/types'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../../store/store'
import {
  setDataGeneralsToEdit,
  setOrigin,
  setPerfomance,
  setProject,
  setStatus,
} from '../../../../store/editDataReduce'
import { CCol, CLink, CTooltip } from '@coreui/react-pro'
import { ModalAdminLineas } from '../../../../../../components/buscarLinea/modalAdminLineas/modalAdminLineas'
import { ModalAdminGrupos } from '../../../../../../components/buscarGrupo/modalAdminGrupos/modaAdminGrupos'
import { ModalAdminMarcas } from '../../../../../../components/buscarMarca/modalAdminMarcas/modalAdminMarcas'
import {
  GrupoInventario,
  LineaInventario,
  MarcaInventario,
  UnidadInventario,
} from '../../../../../../store/types'
import TiposProcedenciaLookUp from '../../../../../../../componentes/tipoProcedencisLookUp/'
import TiposPerformanceLookUp from '../../../../../../../componentes/tipoPerformancesLookUp'
import { TOptionGenerico } from '../../../../../../../clientes/pages/clientes/types/types'
import TipoEstadoLookUp from '../../../../../../../componentes/tipoEstado'
import { TEstado } from '../../../../../../../../store/types'
import { AsyncRule, RequiredRule } from 'devextreme-react/validator'
import { ModalUnidades } from '../../../modalUnidades'
import { cilInfo } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { isMobile } from 'react-device-detect'
import LocalesLookUp from '../../../../../../../componentes/localesLookUp'
import { LocalOption } from '../../../../../../../componentes/localesLookUp/store/type'

export const optionsTypesNewItem = [
  { value: 1, label: 'Item' },
  { value: 2, label: 'Servicio' },
  { value: 3, label: 'Kit (Conjunto)' },
  { value: 4, label: 'Materia Prima' },
  { value: 5, label: 'Otros/Gastos' },
  { value: 6, label: 'E/S Rápida' },
  { value: 7, label: 'Activo Fijo' },
  { value: 8, label: 'Bienes S/R' },
]

export const optionsHierarchy = [
  { value: 0, label: 'No Aplica' },
  { value: 1, label: 'Agrupador' },
  { value: 2, label: 'Simple' },
]
const DatosGenereales = (props) => {
  const { tabId } = props

  const dispatch = useDispatch()

  const dataToEditGenerals = useSelector(
    (state: RootState) => state.inventarios.items.editData[tabId].generales,
  )

  const [showModalLines, setShowModalLines] = React.useState<boolean>(false)
  const [showModalGroup, setShowModalGroup] = React.useState<boolean>(false)
  const [showModalBrand, setShowModalBrand] = React.useState<boolean>(false)
  const [showModalUnits, setShowModalUnits] = React.useState<boolean>(false)
  const [unitSell, setUnitSell] = React.useState<boolean>(false)

  const onChangedUnitBuy = React.useCallback(
    (unit: UnidadInventario) => {
      dispatch(
        setDataGeneralsToEdit({
          key: tabId,
          generals: {
            ...dataToEditGenerals,
            unidadCompra: unit,
          },
        }),
      )
    },
    [dispatch, dataToEditGenerals, tabId],
  )

  const onChangedUnitSell = React.useCallback(
    (unit: UnidadInventario) => {
      dispatch(
        setDataGeneralsToEdit({
          key: tabId,
          generals: {
            ...dataToEditGenerals,
            unidadVenta: unit,
          },
        }),
      )
    },
    [dispatch, dataToEditGenerals, tabId],
  )

  const onChangedAges = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setDataGeneralsToEdit({
            key: tabId,
            generals: {
              ...dataToEditGenerals,
              edades: event.value,
            },
          }),
        )
    },
    [dispatch, dataToEditGenerals, tabId],
  )

  const onChangedCards = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setDataGeneralsToEdit({
            key: tabId,
            generals: {
              ...dataToEditGenerals,
              tarjetas: event.value,
            },
          }),
        )
    },
    [dispatch, dataToEditGenerals, tabId],
  )

  const onChangedCadaMin = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setDataGeneralsToEdit({
            key: tabId,
            generals: {
              ...dataToEditGenerals,
              cadaMin: event.value,
            },
          }),
        )
    },
    [dispatch, dataToEditGenerals, tabId],
  )

  const onChangedminGratis = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setDataGeneralsToEdit({
            key: tabId,
            generals: {
              ...dataToEditGenerals,
              minGratis: event.value,
            },
          }),
        )
    },
    [dispatch, dataToEditGenerals, tabId],
  )

  const onChangedTiempo = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setDataGeneralsToEdit({
            key: tabId,
            generals: {
              ...dataToEditGenerals,
              tiempo: event.value,
            },
          }),
        )
    },
    [dispatch, dataToEditGenerals, tabId],
  )

  const onChangedPesado = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setDataGeneralsToEdit({
            key: tabId,
            generals: {
              ...dataToEditGenerals,
              plantilla: event.value,
            },
          }),
        )
    },
    [dispatch, dataToEditGenerals, tabId],
  )

  const onChangedPlantilla = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setDataGeneralsToEdit({
            key: tabId,
            generals: {
              ...dataToEditGenerals,
              plantilla: event.value,
            },
          }),
        )
    },
    [dispatch, dataToEditGenerals, tabId],
  )

  const onChangedReceta = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setDataGeneralsToEdit({
            key: tabId,
            generals: {
              ...dataToEditGenerals,
              receta: event.value,
            },
          }),
        )
    },
    [dispatch, dataToEditGenerals, tabId],
  )

  const onChangedConjuntos = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setDataGeneralsToEdit({
            key: tabId,
            generals: {
              ...dataToEditGenerals,
              conjuntos: event.value,
            },
          }),
        )
    },
    [dispatch, dataToEditGenerals, tabId],
  )

  const onChangedMobile = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setDataGeneralsToEdit({
            key: tabId,
            generals: {
              ...dataToEditGenerals,
              mobile: event.value,
            },
          }),
        )
    },
    [dispatch, dataToEditGenerals, tabId],
  )

  const onChangedLotes = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setDataGeneralsToEdit({
            key: tabId,
            generals: {
              ...dataToEditGenerals,
              lotes: event.value,
            },
          }),
        )
    },
    [dispatch, dataToEditGenerals, tabId],
  )

  const onChangedSerieImei = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setDataGeneralsToEdit({
            key: tabId,
            generals: {
              ...dataToEditGenerals,
              serieImei: event.value,
            },
          }),
        )
    },
    [dispatch, dataToEditGenerals, tabId],
  )

  const onChangedPerformance = React.useCallback(
    (performance: TOptionGenerico) => {
      dispatch(
        setPerfomance({
          key: tabId,
          performance: performance,
        }),
      )
    },
    [dispatch, tabId],
  )

  const onChangedStatus = React.useCallback(
    (status: TEstado) => {
      dispatch(
        setStatus({
          key: tabId,
          status: status,
        }),
      )
    },
    [dispatch, tabId],
  )

  const onChangedPackagingUnit = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setDataGeneralsToEdit({
            key: tabId,
            generals: {
              ...dataToEditGenerals,
              unidadPorEmpaque: parseFloat(event.value.toFixed(2)) ?? 0,
            },
          }),
        )
    },
    [dispatch, dataToEditGenerals, tabId],
  )

  const onChangedLuckyTime = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setDataGeneralsToEdit({
            key: tabId,
            generals: {
              ...dataToEditGenerals,
              tiempoSurtido: event.value,
            },
          }),
        )
    },
    [dispatch, dataToEditGenerals, tabId],
  )

  const onChangedOut = React.useCallback(
    (event: any) => {
      if (event.event) {
        const factor: number =
          event.value == 0 || dataToEditGenerals.entrada === 0
            ? 0
            : parseFloat((event.value / dataToEditGenerals.entrada).toFixed(2))
        dispatch(
          setDataGeneralsToEdit({
            key: tabId,
            generals: {
              ...dataToEditGenerals,
              salida: event.value,
              factor: factor,
            },
          }),
        )
      }
    },
    [dispatch, dataToEditGenerals, tabId],
  )

  const onChangedIn = React.useCallback(
    (event: any) => {
      if (event.event) {
        const factor: number =
          event.value == 0 || dataToEditGenerals.salida === 0
            ? 0
            : parseFloat(
                (dataToEditGenerals.salida / event.value).toFixed(2),
              ) ?? 0
        dispatch(
          setDataGeneralsToEdit({
            key: tabId,
            generals: {
              ...dataToEditGenerals,
              entrada: event.value,
              factor: factor,
            },
          }),
        )
      }
    },
    [dispatch, dataToEditGenerals, tabId],
  )

  const onChangedProject = React.useCallback(
    (project: LocalOption) => {
      dispatch(
        setProject({
          key: tabId,
          project: project,
        }),
      )
    },
    [dispatch, tabId],
  )

  const onChangedOrigin = React.useCallback(
    (origin: TOptionGenerico) => {
      dispatch(
        setOrigin({
          key: tabId,
          origin: origin,
        }),
      )
    },
    [dispatch, tabId],
  )

  const onChangedBrand = React.useCallback(
    (brand: MarcaInventario) => {
      if (brand)
        dispatch(
          setDataGeneralsToEdit({
            key: tabId,
            generals: {
              ...dataToEditGenerals,
              marca: brand,
            },
          }),
        )
      setShowModalBrand(false)
    },
    [dispatch, dataToEditGenerals, tabId],
  )

  const onChangedGroup = React.useCallback(
    (group: GrupoInventario) => {
      if (group)
        dispatch(
          setDataGeneralsToEdit({
            key: tabId,
            generals: {
              ...dataToEditGenerals,
              grupo: group,
            },
          }),
        )
      setShowModalGroup(false)
    },
    [dispatch, dataToEditGenerals, tabId],
  )

  const onChangedLines = React.useCallback(
    (line: LineaInventario) => {
      if (line)
        dispatch(
          setDataGeneralsToEdit({
            key: tabId,
            generals: {
              ...dataToEditGenerals,
              linea: line,
            },
          }),
        )
      setShowModalLines(false)
    },
    [dispatch, dataToEditGenerals, tabId],
  )

  const onChangedDinamic = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setDataGeneralsToEdit({
            key: tabId,
            generals: {
              ...dataToEditGenerals,
              dinamico: event.value,
            },
          }),
        )
    },
    [dispatch, dataToEditGenerals, tabId],
  )

  const onChangedControler = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setDataGeneralsToEdit({
            key: tabId,
            generals: {
              ...dataToEditGenerals,
              controlador: event.value,
            },
          }),
        )
    },
    [dispatch, dataToEditGenerals, tabId],
  )

  const onChangedHierarchy = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setDataGeneralsToEdit({
            key: tabId,
            generals: {
              ...dataToEditGenerals,
              jerarquia: event.value,
            },
          }),
        )
    },
    [dispatch, dataToEditGenerals, tabId],
  )

  const onChangedTypeItem = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setDataGeneralsToEdit({
            key: tabId,
            generals: {
              ...dataToEditGenerals,
              tipoItem: event.value,
            },
          }),
        )
    },
    [dispatch, dataToEditGenerals, tabId],
  )

  const onChnagedTicket = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setDataGeneralsToEdit({
            key: tabId,
            generals: {
              ...dataToEditGenerals,
              ticket: event.value,
              paquetes: !event.value,
            },
          }),
        )
    },
    [dispatch, dataToEditGenerals, tabId],
  )

  const onChnagedPackage = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setDataGeneralsToEdit({
            key: tabId,
            generals: {
              ...dataToEditGenerals,
              ticket: !event.value,
              paquetes: event.value,
            },
          }),
        )
    },
    [dispatch, dataToEditGenerals, tabId],
  )

  const validateOrigin = React.useCallback(
    (selected: any) => {
      if (
        selected?.value?.value === -1 &&
        dataToEditGenerals.tipoItem.value != 2 &&
        dataToEditGenerals.tipoItem.value != 5
      )
        return Promise.reject(
          'El campo Procedencia no se encuentra seleccionado, por favor elija una opción.',
        )
      else return Promise.resolve()
    },
    [dataToEditGenerals.tipoItem.value],
  )

  const validateStatus = React.useCallback((selected: any) => {
    if (selected?.value?.codigo > 0) return Promise.resolve()
    else
      return Promise.reject(
        'El campo Estado no se encuentra seleccionado, por favor elija una opción.',
      )
  }, [])

  const validateUnitBuy = React.useCallback(
    (selected: any) => {
      if (selected?.value === '' && dataToEditGenerals.tipoItem.value === 1)
        return Promise.reject('El campo Unidad Compra es obligatorio')
      else return Promise.resolve()
    },
    [dataToEditGenerals.tipoItem.value],
  )

  const validateUnitSell = React.useCallback(
    (selected: any) => {
      if (selected?.value === '' && dataToEditGenerals.tipoItem.value === 1)
        return Promise.reject('El campo UnidadVenta es obligatorio')
      else return Promise.resolve()
    },
    [dataToEditGenerals.tipoItem.value],
  )

  return (
    <RowContainer className="m-2">
      {showModalLines && (
        <ModalAdminLineas
          onChanged={(line: LineaInventario) => onChangedLines(line)}
          onCancel={() => setShowModalLines(false)}
          show={showModalLines}
        />
      )}
      {showModalGroup && (
        <ModalAdminGrupos
          onChanged={(group: GrupoInventario) => onChangedGroup(group)}
          onCancel={() => setShowModalGroup(false)}
          show={showModalGroup}
        />
      )}
      {showModalBrand && (
        <ModalAdminMarcas
          onChanged={(brand: MarcaInventario) => onChangedBrand(brand)}
          onCancel={() => setShowModalBrand(false)}
          show={showModalBrand}
        />
      )}
      {showModalUnits && (
        <ModalUnidades
          show={showModalUnits}
          onSelectUnit={(unit: UnidadInventario) => {
            if (unitSell) {
              onChangedUnitSell(unit)
              setUnitSell(false)
            } else onChangedUnitBuy(unit)
            setShowModalUnits(false)
          }}
          onCancel={() => setShowModalUnits(false)}
        />
      )}
      <RowContainer>
        <CustomCol xs="12" md="12">
          <Labeled label="">
            <RadioGroup
              name="idTypesItem"
              dataSource={optionsTypesNewItem}
              value={dataToEditGenerals.tipoItem}
              layout="horizontal"
              displayExpr="label"
              onValueChanged={onChangedTypeItem}
              width="100%"
            />
          </Labeled>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="4">
          <Labeled label="Jerarquía:">
            <RadioGroup
              name="idTypesHierarchy"
              dataSource={optionsHierarchy}
              value={dataToEditGenerals.jerarquia}
              layout="horizontal"
              displayExpr="label"
              onValueChanged={onChangedHierarchy}
              width="100%"
            />
          </Labeled>
        </CustomCol>
        <CCol xs="12" md="4" style={{ marginTop: 'auto' }}>
          <CheckBox
            text="Controlador"
            value={dataToEditGenerals.controlador}
            onValueChanged={onChangedControler}
            disabled={true}
            visible={false}
          />
          <CheckBox
            style={{ marginLeft: '14px' }}
            text="Dinámico"
            value={dataToEditGenerals.dinamico}
            onValueChanged={onChangedDinamic}
            disabled={dataToEditGenerals.tipoItem?.value !== 3}
          />
        </CCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="3">
          <Labeled label="Línea *:">
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              <CTooltip
                key={'tool-more-auth-side'}
                placement="right"
                content={
                  'Para el caso de empresas proveedoras de internet este campo se usa para definir COMPRESION.'
                }
              >
                <CLink className="card-header-action m-0">
                  <CIcon icon={cilInfo} />
                </CLink>
              </CTooltip>
              <TextBox
                value={dataToEditGenerals?.linea?.descripcion ?? ''}
                width="100%"
                inputAttr={{ autocomplete: 'nope' }}
                readOnly={true}
              >
                <Validator>
                  <RequiredRule message={'El campo Línea es obligatorio'} />
                </Validator>
              </TextBox>
              <Button
                id="btnAdd"
                className="me-1"
                icon="add"
                stylingMode="contained"
                type="default"
                onClick={() => setShowModalLines(true)}
              />
            </div>
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="3">
          <Labeled label="Performance:">
            <TiposPerformanceLookUp
              selected={dataToEditGenerals.performance}
              onChanged={onChangedPerformance}
              onChangedOptions={() => {}}
            />
          </Labeled>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="3">
          <Labeled label="Grupo:">
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              <CTooltip
                key={'tool-more-auth-side'}
                placement="right"
                content="Para el caso de empresas proveedoreas de internet, este campo se usa para definir VELOCIDAD."
              >
                <CLink className="card-header-action m-0">
                  <CIcon icon={cilInfo} />
                </CLink>
              </CTooltip>
              <TextBox
                value={dataToEditGenerals?.grupo?.descripcion ?? ''}
                width="100%"
                inputAttr={{ autocomplete: 'nope' }}
                readOnly={true}
              />
              <Button
                id="btnAdd"
                className="me-1"
                icon="add"
                stylingMode="contained"
                type="default"
                onClick={() => setShowModalGroup(true)}
              />
            </div>
          </Labeled>
        </CustomCol>
        {!isMobile && (
          <CustomCol xs="12" md="5">
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                margin: '6px 0',
              }}
            >
              <div style={{ display: 'flex', gap: '10px', margin: '6px 0' }}>
                <CheckBox
                  text="# de Serie ó IMEI"
                  value={dataToEditGenerals.serieImei}
                  onValueChanged={onChangedSerieImei}
                />
                <CheckBox
                  text="Lotes"
                  value={dataToEditGenerals.lotes}
                  onValueChanged={onChangedLotes}
                />
                <CheckBox
                  text="Visible en Dispositivos Móviles ó Web Store"
                  value={dataToEditGenerals.mobile}
                  onValueChanged={onChangedMobile}
                />
              </div>
              <div style={{ display: 'flex', gap: '10px', margin: '6px 0' }}>
                <CheckBox
                  text="Se vende en conjuntos"
                  value={dataToEditGenerals.conjuntos}
                  onValueChanged={onChangedConjuntos}
                />
                <CheckBox
                  text="Receta"
                  value={dataToEditGenerals.receta}
                  onValueChanged={onChangedReceta}
                />
                <CheckBox
                  text="Kit Plantilla"
                  value={dataToEditGenerals.plantilla}
                  onValueChanged={onChangedPlantilla}
                />
                <CheckBox
                  text="Pesado"
                  value={dataToEditGenerals.pesado}
                  onValueChanged={onChangedPesado}
                />
              </div>
            </div>
          </CustomCol>
        )}
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="3">
          <Labeled label="Marca:">
            <div style={{ display: 'flex', gap: '10px' }}>
              <TextBox
                style={{ marginLeft: '34px' }}
                value={dataToEditGenerals?.marca?.nombre ?? ''}
                width="100%"
                inputAttr={{ autocomplete: 'nope' }}
                readOnly={true}
              />
              <Button
                id="btnAdd"
                className="me-1"
                icon="add"
                stylingMode="contained"
                type="default"
                onClick={() => setShowModalBrand(true)}
              />
            </div>
          </Labeled>
        </CustomCol>
        {isMobile ? (
          <CustomCol xs="12" md="5">
            <CheckBox
              text="# de Serie ó IMEI"
              value={dataToEditGenerals.serieImei}
              onValueChanged={onChangedSerieImei}
            />
            <CheckBox
              style={{ marginLeft: '8px' }}
              text="Lotes"
              value={dataToEditGenerals.lotes}
              onValueChanged={onChangedLotes}
            />
            <CheckBox
              text="Visible en Dispositivos Móviles ó Web Store"
              value={dataToEditGenerals.mobile}
              onValueChanged={onChangedMobile}
            />
            <CheckBox
              text="Se vende en conjuntos"
              value={dataToEditGenerals.conjuntos}
              onValueChanged={onChangedConjuntos}
            />
            <CheckBox
              text="Receta"
              value={dataToEditGenerals.receta}
              onValueChanged={onChangedReceta}
            />
            <CheckBox
              style={{ marginLeft: '8px' }}
              text="Kit Plantilla"
              value={dataToEditGenerals.plantilla}
              onValueChanged={onChangedPlantilla}
            />
            <CheckBox
              text="Pesado"
              value={dataToEditGenerals.pesado}
              onValueChanged={onChangedPesado}
            />
            <CheckBox
              style={{ marginLeft: '8px' }}
              text="Tiempo"
              value={dataToEditGenerals.tiempo}
              onValueChanged={onChangedTiempo}
            />
            <div
              style={{
                display: 'flex',
                gap: '10px',
                margin: '6px 0',
                alignItems: 'center',
              }}
            >
              {'Min Gratis:'}
              <NumberBox
                value={dataToEditGenerals.minGratis}
                onValueChanged={onChangedminGratis}
                height={'25px'}
                width={'50px'}
                min={0}
                max={60}
              />
            </div>
            <div
              style={{
                display: 'flex',
                gap: '10px',
                margin: '6px 0',
                alignItems: 'center',
              }}
            >
              {'Cada Min:'}
              <NumberBox
                value={dataToEditGenerals.cadaMin}
                onValueChanged={onChangedCadaMin}
                height={'25px'}
                width={'50px'}
                min={0}
                max={60}
              />
            </div>
          </CustomCol>
        ) : (
          <CustomCol xs="12" md="5">
            <div
              style={{
                display: 'flex',
                gap: '10px',
                margin: '6px 0',
                alignItems: 'center',
              }}
            >
              <CheckBox
                text="Tiempo"
                value={dataToEditGenerals.tiempo}
                onValueChanged={onChangedTiempo}
              />
              {'Min Gratis:'}
              <NumberBox
                value={dataToEditGenerals.minGratis}
                onValueChanged={onChangedminGratis}
                height={'25px'}
                width={'50px'}
                min={0}
                max={60}
              />
              {'Cada Min:'}
              <NumberBox
                value={dataToEditGenerals.cadaMin}
                onValueChanged={onChangedCadaMin}
                height={'25px'}
                width={'50px'}
                min={0}
                max={60}
              />
            </div>
          </CustomCol>
        )}
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="3">
          <Labeled label="Procedencia *:">
            <TiposProcedenciaLookUp
              selected={dataToEditGenerals.procedencia}
              onChanged={onChangedOrigin}
              onChangedOptions={() => {}}
            >
              <Validator>
                <AsyncRule validationCallback={validateOrigin} />
                <RequiredRule
                  message={
                    'El campo Categoría Web no se encuentra seleccionado, por favor elija una opción.'
                  }
                />
              </Validator>
            </TiposProcedenciaLookUp>
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="5">
          <Labeled label="Tarjetas:" />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <RadioGroup
              name="idTypesCards"
              dataSource={optionsGroupsTarj}
              value={dataToEditGenerals.tarjetas}
              layout="horizontal"
              displayExpr="label"
              onValueChanged={onChangedCards}
              width="100%"
            />
            <RadioGroup
              name="idTypesAges"
              dataSource={optionsAges}
              value={dataToEditGenerals.edades}
              layout="horizontal"
              displayExpr="label"
              onValueChanged={onChangedAges}
              width="100%"
              disabled={dataToEditGenerals.tarjetas?.value < 3}
            />
          </div>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="3">
          <Labeled label="Proyecto:">
            <LocalesLookUp
              selected={dataToEditGenerals.proyecto}
              onChanged={onChangedProject}
              onChangedOptions={() => {}}
              message="Todos"
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="3">
          <Labeled label="Unidad Compra: *">
            <div style={{ display: 'flex', gap: '10px' }}>
              <TextBox
                value={dataToEditGenerals?.unidadCompra?.nombre ?? ''}
                width="100%"
                inputAttr={{ autocomplete: 'nope' }}
                readOnly={true}
              >
                <Validator>
                  <AsyncRule validationCallback={validateUnitBuy} />
                  <RequiredRule
                    message={'El campo Unidad Compra es obligatorio'}
                  />
                </Validator>
              </TextBox>
              <Button
                id="btnAdd"
                className="me-1"
                icon="add"
                stylingMode="contained"
                type="default"
                onClick={() => setShowModalUnits(true)}
              />
            </div>
          </Labeled>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="3">
          <Labeled label="Tiempo de Surtido: *">
            <div style={{ display: 'flex', gap: '10px' }}>
              <NumberBox
                value={dataToEditGenerals.tiempoSurtido}
                onValueChanged={onChangedLuckyTime}
                height={'26px'}
                min={1}
              >
                <Validator>
                  <RequiredRule
                    message={
                      'El campo Tiempo de Surtido no se encuentra seleccionado, por favor elija una opción.'
                    }
                  />
                </Validator>
              </NumberBox>
              <p>días</p>
            </div>
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="3">
          <Labeled label="Unidad Venta: *">
            <div style={{ display: 'flex', gap: '10px' }}>
              <TextBox
                value={dataToEditGenerals?.unidadVenta?.nombre ?? ''}
                width="100%"
                inputAttr={{ autocomplete: 'nope' }}
                readOnly={true}
              >
                <Validator>
                  <AsyncRule validationCallback={validateUnitSell} />
                  <RequiredRule
                    message={'El campo Unidad Venta es obligatorio'}
                  />
                </Validator>
              </TextBox>
              <Button
                id="btnAdd"
                className="me-1"
                icon="add"
                stylingMode="contained"
                type="default"
                onClick={() => {
                  setShowModalUnits(true)
                  setUnitSell(true)
                }}
              />
            </div>
          </Labeled>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="3">
          <Labeled label="Factor Entre Unidades:">
            <div style={{ display: 'flex', gap: '10px' }}>
              <NumberBox
                value={dataToEditGenerals.entrada}
                onValueChanged={onChangedIn}
                height={'26px'}
                min={0}
              />
              <p>{' / '}</p>
              <NumberBox
                value={dataToEditGenerals.salida}
                onValueChanged={onChangedOut}
                height={'26px'}
                min={0}
              />
              <NumberBox
                value={dataToEditGenerals.factor}
                readOnly={true}
                height={'26px'}
                min={0}
              />
            </div>
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="3">
          <Labeled label="Unidad por Empaque:">
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              <NumberBox
                value={dataToEditGenerals.unidadPorEmpaque}
                width="100%"
                onValueChanged={onChangedPackagingUnit}
                min={0}
              />
              <CTooltip
                key={'tool-more-auth-side'}
                placement="right"
                content={'Campo informativo.'}
              >
                <CLink className="card-header-action m-0">
                  <CIcon icon={cilInfo} />
                </CLink>
              </CTooltip>
            </div>
          </Labeled>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="3">
          <Labeled label="Estado *:">
            <TipoEstadoLookUp
              onChanged={onChangedStatus}
              selected={dataToEditGenerals?.estado ?? null}
            >
              <Validator>
                <AsyncRule validationCallback={validateStatus} />
                <RequiredRule
                  message={
                    'El Estado no se encuentra seleccionado, por favor elija una opción.'
                  }
                />
              </Validator>
            </TipoEstadoLookUp>
          </Labeled>
        </CustomCol>
        <CCol xs="12" md="3" style={{ marginTop: 'auto' }}>
          <CheckBox
            text="Ticket"
            value={dataToEditGenerals.ticket}
            onValueChanged={onChnagedTicket}
            visible={false}
          />
          <CheckBox
            style={{ marginLeft: '10px' }}
            text="Paquetes"
            value={dataToEditGenerals.paquetes}
            onValueChanged={onChnagedPackage}
            visible={false}
          />
        </CCol>
      </RowContainer>
    </RowContainer>
  )
}

export default React.memo(DatosGenereales)

export const defaultGeneralsItem: ItemGenerals = {
  tipoItem: optionsTypesNewItem[0],
  jerarquia: optionsHierarchy[0],
  controlador: null,
  dinamico: false,
  linea: null,
  grupo: null,
  marca: null,
  procedencia: { value: 0, label: 'NACIONAL' },
  proyecto: null,
  tiempoSurtido: 1,
  unidadCompra: { codigo: 34, nombre: 'UNIDAD' },
  unidadVenta: { codigo: 34, nombre: 'UNIDAD' },
  entrada: 1,
  salida: 1,
  factor: 1,
  unidadPorEmpaque: null,
  estado: { codigo: 1, descripcion: 'ACTIVO' },
  performance: null,
  serieImei: false,
  lotes: false,
  aduanales: false,
  mobile: false,
  conjuntos: false,
  receta: false,
  plantilla: false,
  pesado: false,
  tiempo: false,
  minGratis: 0,
  cadaMin: 0,
  tarjetas: optionsGroupsTarj[0],
  edades: null,
  ticket: false,
  paquetes: false,
  codRetencion: 0,
}
