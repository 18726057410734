import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DateUtils } from "../../../../../helpers/dateUtils";
import { FETCH_STATUS, Seleccionado } from "../../../../../store/types";
import { IngresosCuadresCajaListado } from "../../../../ventas/pages/cuadres/types/types";
import { FiltrosSaldosState, SaldosState } from "../types/types";
import { RootState } from "../../../../../store/store";
import { SaldosDctosYCtasXCobrar } from "../components/saldos/services/saldosDctosYCtasXCobrar.service";
import { CuotaCreditoVenta } from "../../../../ventas/types/types";



const initialState: SaldosState<IngresosCuadresCajaListado> = {
  filter: {
    fecha: DateUtils.dateToString(new Date(new Date().getFullYear(), 0, 1), 'dd/MM/yyyy'),
    cliente: null,
    local: null,
    empresa: null,
    numero: null,
    esteblecimientoNum: '001',
    ptoEmision: '001',
    saldoAnterior: null,
    observaciones: null,
    ventacodigo: null,
  },
  seleccionado: {
    index: null,
    row: null,
    selectedRow: null,
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  resultados: [],
  currentExecute: null,
  currentUltimoNum: null,
  currentActiontate: ""
}

export const fetchSaldos = createAsyncThunk<
  Array<IngresosCuadresCajaListado>,
  FiltrosSaldosState
>('clientes/DocCuentasPorCobrar/sales/getOpeningBalance', async (filtro) => {
  try {
    const saldosApi = await SaldosDctosYCtasXCobrar.getSaldoInicial(
      filtro.cliente !== null ? filtro.cliente.codigo : 0,
      filtro.fecha,
      filtro.numero,
    )
    return saldosApi
  } catch (error) {
    return Promise.reject(error)
  }
})
export const fetchSaldosNum = createAsyncThunk<
  Array<IngresosCuadresCajaListado>,
  FiltrosSaldosState
>(
  'clientes/DocCuentasPorCobrar/sales/getNumberOfLastClientBalance',
  async (filtro) => {
    try {
      const saldosApi = await SaldosDctosYCtasXCobrar.getNumUltimoSaldo(
        filtro.cliente !== null ? filtro.cliente.codigo : 0,
        filtro.numero,
      )
      return saldosApi
    } catch (error) {
      return Promise.reject(error)
    }
  },
)

export const deleteSaldos = createAsyncThunk<
  Array<IngresosCuadresCajaListado>,
  Seleccionado
>('clientes/DocCuentasPorCobrar/balance/delete', async (filtro) => {
  try {
    const saldosApi = await SaldosDctosYCtasXCobrar.deleteSaldo(
      filtro.row !== null ? filtro.row.ventaCodigo : 0,
      filtro.row !== null ? filtro.row.ventaFecha : '',
    )
    return saldosApi
  } catch (error) {
    return Promise.reject(error)
  }
})

export const saveSaldosNew = createAsyncThunk<
  Array<IngresosCuadresCajaListado>,
  FiltrosSaldosState
>('ventas/ventas/save', async (filtro) => {
  try {
    const saldosApi = await SaldosDctosYCtasXCobrar.saveSaldo(
      filtro.ventacodigo !== null ? filtro.ventacodigo : 0,
      '99',
      filtro.numero,
      filtro.fecha,
      filtro.esteblecimientoNum,
      filtro.ptoEmision,
      filtro.fecha,
      '',
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      filtro.saldoAnterior !== null ? parseInt(filtro.saldoAnterior) : 0,
      8,
      filtro.cliente !== null ? filtro.cliente.codigo : -1,
      0,
      filtro.empresa !== null ? parseInt(filtro.empresa.codigo) : 0,
      filtro.local !== null ? filtro.local.codigo : -1,
      filtro.observaciones,
      1,
      0,
      0,
      [],
      [{ subCodigo: 8, fpvValor: filtro.saldoAnterior }],
      [],
      [],
      [],
      0,
    )
    return saldosApi
  } catch (error) {
    return Promise.reject(error)
  }
})


export const fetchSaldoSinRetencion = createAsyncThunk<
  Array<IngresosCuadresCajaListado>,
  number
>('ventas/getTotalWithoutRetentionSales', async (ventaCodigo) => {
  try {
    const saldoPopup = await SaldosDctosYCtasXCobrar.getTotalSinRetencion(
      ventaCodigo,
      8
    )
    return saldoPopup
  } catch (error) {
    return Promise.reject(error)
  }
})

export const fetchCuotasVentas = createAsyncThunk<
  Array<CuotaCreditoVenta>,
  number
>('ventas/getSalesInstallments', async (ventaCodigo) => {
  try {
    const saldoPopup = await SaldosDctosYCtasXCobrar.getCuotasVenta(
      ventaCodigo,
    )
    return saldoPopup
  } catch (error) {
    return Promise.reject(error)
  }
})

export const saveCuotasVentas = createAsyncThunk<
  Array<IngresosCuadresCajaListado>,
  {
    codigo: number,
    cuotas: Array<CuotaCreditoVenta>
  }
>('ventas/ventas/saveInstalment', async (filter) => {
  try {
    const saldoPopup = await SaldosDctosYCtasXCobrar.postCuotas(
      filter.codigo,
      filter.cuotas
    )
    return saldoPopup
  } catch (error) {
    return Promise.reject(error)
  }
})

export const deleteCuotasVentas = createAsyncThunk<
  Array<IngresosCuadresCajaListado>,
  string
>('proveedores/general/paymentsPurchases/deleteAll', async (codigo) => {
  try {
    const saldoPopup = await SaldosDctosYCtasXCobrar.deleteCuotas(
      codigo
    )
    return saldoPopup
  } catch (error) {
    return Promise.reject(error)
  }
})


const saldosSlice = createSlice({
  name: 'saldosDocumentos',
  initialState: initialState,
  reducers: {
    setCleanResult(state) {
      state.resultados = []
    },
    setCleanCurrentExecute(state) {
      state.currentExecute = null
    },
    setCurrentAction(state, action: PayloadAction<string>) {
      state.currentActiontate = action.payload
    },
    setCleanFields(state) {
      state.currentExecute = null
      state.filter.fecha = DateUtils.dateToString(new Date(new Date().getFullYear(), 0, 1), 'dd/MM/yyyy')
      state.filter.cliente = null
      state.filter.numero = null
      state.filter.esteblecimientoNum = '001'
      state.filter.ptoEmision = '001'
      state.filter.observaciones = null
      state.filter.saldoAnterior = null
      state.filter.empresa = null
      state.filter.local = null
      state.filter.ventacodigo = 0
    },
    initData(state) {
      state.filter.fecha = DateUtils.dateToString(new Date(new Date().getFullYear(), 0, 1), 'dd/MM/yyyy')
      state.filter.numero =
        state.currentUltimoNum !== null
          ? state.currentUltimoNum.auto.toString()
          : '1'
      state.filter.esteblecimientoNum = '001'
      state.filter.ptoEmision = '001'
      state.filter.observaciones = null
      state.filter.saldoAnterior = null
      state.filter.empresa = null
      state.filter.local = null
      state.filter.ventacodigo = 0
    },
    changeFilter(state, action: PayloadAction<FiltrosSaldosState>) {
      state.filter.fecha = action.payload.fecha
      state.filter.cliente = action.payload.cliente
      state.filter.empresa = action.payload.empresa
      state.filter.local = action.payload.local
      state.filter.numero = action.payload.numero
      state.filter.esteblecimientoNum = action.payload.esteblecimientoNum
      state.filter.ptoEmision = action.payload.ptoEmision
      state.filter.observaciones = action.payload.observaciones
      state.filter.saldoAnterior = action.payload.saldoAnterior
      state.filter.ventacodigo = action.payload.ventacodigo
    },
    setResetSeleccion(state) {
      const toAdd = {
        index: null,
        row: null,
        selectedRow: null,
      }
      state.seleccionado = toAdd
    },
    setSeleccionarDato(state, action: PayloadAction<Seleccionado>) {
      const toAdd = {
        index: action.payload.index,
        row: action.payload.row,
        selectedRow: action.payload.selectedRow,
      }
      state.seleccionado = toAdd
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSaldos.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchSaldos.fulfilled, (state, { payload }) => {
      state.resultados = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchSaldos.rejected, (state, { payload }) => {
      state.resultados = []
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
    builder.addCase(fetchSaldosNum.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchSaldosNum.fulfilled, (state, { payload }) => {
      state.currentUltimoNum = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchSaldosNum.rejected, (state, { payload }) => {
      state.currentUltimoNum = null
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
    builder.addCase(deleteSaldos.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(deleteSaldos.fulfilled, (state, { payload }) => {
      state.currentExecute = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(deleteSaldos.rejected, (state, { payload }) => {
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
    builder.addCase(saveSaldosNew.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(saveSaldosNew.fulfilled, (state, { payload }) => {
      state.currentExecute = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(saveSaldosNew.rejected, (state, { payload }) => {
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
    builder.addCase(fetchSaldoSinRetencion.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchSaldoSinRetencion.fulfilled, (state, { payload }) => {
      state.currentExecute = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchSaldoSinRetencion.rejected, (state, { payload }) => {
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
    builder.addCase(fetchCuotasVentas.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchCuotasVentas.fulfilled, (state, { payload }) => {
      state.currentExecute = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchCuotasVentas.rejected, (state, { payload }) => {
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
    builder.addCase(saveCuotasVentas.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(saveCuotasVentas.fulfilled, (state, { payload }) => {
      state.currentExecute = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(saveCuotasVentas.rejected, (state, { payload }) => {
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
    builder.addCase(deleteCuotasVentas.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(deleteCuotasVentas.fulfilled, (state, { payload }) => {
      state.currentExecute = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(deleteCuotasVentas.rejected, (state, { payload }) => {
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
  }
})

export const getFiltroSaldos = (state: RootState) => {
  return state.clientes.docCuentasXCobrar.saldos.filter
}

export const getResultadosSaldos = (state: RootState) => {
  return state.clientes.docCuentasXCobrar.saldos.resultados
}

export const getCurrentExecute = (state: RootState) => {
  return state.clientes.docCuentasXCobrar.saldos.currentExecute
}

export const {
  changeFilter,
  setCleanResult,
  setResetSeleccion,
  setSeleccionarDato,
  setCleanFields,
  initData,
  setCleanCurrentExecute,
  setCurrentAction
} = saldosSlice.actions


export const saldosReducer = saldosSlice.reducer
