import { LoaderInfo, OptionGrupos, Seleccionado } from "../../../../../store/types"

export enum TIPOS_BOTONES {
  BOTON_NUEVO = 'Nuevo',
  BOTON_GUARDAR = 'Guardar',
  BOTON_EDITAR = 'Editar',
  BOTON_ELIMINAR = 'Eliminar',
  BOTON_BUSCAR = 'Buscar',
  BOTON_REFRESCAR = 'Refrescar',
  BOTON_IMPRIMIR = 'Imprimir',
  BOTON_DESHACER = 'Deshacer',
  BOTON_EXPORTAR = 'Exportar',
  BOTON_LIMPIAR = 'Limpiar',
  BOTON_SALIR = 'Salir',
  BOTON_PRINT_DESIGN = "Imprimir_Diseño",
}

export type Banco = {
  codigo: number, // CODIGO
  nombre: string, // BAN_NOMBRE
  ciudadCodigo: number, // BAN_CIUDAD
  direccion: string, // BAN_DIRECCION
  telefono: string, // BAN_TELEFONO
  tipo: number, // BAN_TIPO
  tipo_nombre: string, // BAN_TIPO_NOMBRE
  ruc: string, // BAN_RUC
  especial: string, // BAN_ESPECIAL
  estado: number, // BAN_ESTADO
}

export type formularioBusqueda = {
  banco: string | null,
}

export type LocalidadInfo = {
  codigo: number,
  codigoPais: number,
  codigoProvincia: number,
  nombre: string,
  nombrePais: string,
  nombreProvincia: string
}

export type BancoState = {
  modulo: string,
  currentAction: string,
  currentFunction: string,
  registros: Array<Banco>,
  seleccionado: Seleccionado,
  tipos: Array<OptionGrupos>,
  estados: Array<OptionGrupos>,
  loader: LoaderInfo,
  formularioBusqueda: formularioBusqueda,
  currentExecute: any,
}