import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FiltroBusquedaPersonal, PersonalDatosEdicion, PersonalList, PersonalListDto } from '../../types/types'
import { TabState } from '../../../../../../store/genericTabTypes'
import FiltroBusqueda from './FiltroBusqueda'
import ResultadosBusqueda from './ResultadosBusqueda'
import { CCard, CCardFooter } from '@coreui/react-pro'
import { useDispatch, useSelector } from 'react-redux'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import { clearButtonClick, openTab, setButtons, setCurrentExecutingAction } from '../../store/tabsReducer'
import { RootState } from '../../../../../../store/store'
import { changeFilter, fetchPersonalList, setChangeLoader, setImport, setResetSeleccion, setSearch } from '../../store/searchReducer'
import { getLocales } from '../../../../../componentes/localesLookUp/helper/helper'
import { getIndexProvider } from '../../../../../shared/helpers/funciones'
import { PersonalServices } from '../../services/personal.service'
import DataSource from 'devextreme/data/data_source'
import BlockUi from '../../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../../views/componentes/loadingindicator/loadingindicator'
import { consoleService } from '../../../../../../services/console.service'
import { FETCH_STATUS, ToastTypes } from '../../../../../../store/types'
import { addToast } from '../../../../../../store/toasterReducer'
import Dialog from '../../../../../../views/componentes/librerias/bootstrap-dialog'
import { initDatosEdicion } from '../../store/editDataReducer'
import { defaultPersonalData } from '../nuevo/index';
import { v4 as uuidv4 } from 'uuid'
import { tipoIdentificacionService } from '../../../../../componentes/tipoIdentificacion/service/tipoId.service'
import { estadosService } from '../../../../../componentes/estadosCivilLookUp/service/estados.service'
import { tipoSangreService } from '../../../../../componentes/tipoSangre/services/tipoSangre.service'
import { tipoEstadoService } from '../../../../../componentes/tipoEstado/service/tipoEstado.service'
import { estudiosService } from '../../../../../componentes/nivelesEstudioLookUp/service/estudios.service'
import { localesService } from '../../../../../componentes/localesLookUp/service/locales.service'
import { localidadesService } from '../../../../../../services/localidades.service'
import { TipoTransaccion } from '../../../../../componentes/tipoIdentificacion/store/types'
import { isMobile } from 'react-device-detect'
import TableLoader from '../../../../../ventas/components/ventas/busquedaVentas/TableLoader'
import { Draggable, SpeedDialAction } from 'devextreme-react'
import { ReportWithLocalDataModal } from '../../../../../../views/componentes/xtrareports/ReportWithLocalDataModal'

interface ISearchProps extends React.PropsWithChildren {
  tab: TabState<PersonalDatosEdicion>
  tabId: string
}

enum VistasBusqueda {
  Filtros,
  ResultadosBusqueda,
  Resumen,
  Loading,
  Error,
}

const draggingGroupName = 'appointmentsGroup'

const Search: React.FunctionComponent<ISearchProps> = (props) => {

  const { tab, tabId } = props

  const dispatch = useDispatch()
  const filterSearch = useSelector((state: RootState) => state.nomina.personal.search.filtro)
  const tabs = useSelector((state: RootState) => state.nomina.personal.tabs)
  const seleccionado = useSelector((state: RootState) => state.nomina.personal.search.seleccionado)
  const searchPersonal = useSelector((state: RootState) => state.nomina.personal.search.searchPersonal)
  const estadoBusqueda = useSelector((state: RootState) => state.nomina.personal.search.status)
  const importar = useSelector((state: RootState) => state.nomina.personal.search.import)
  const loader = useSelector((state: RootState) => state.nomina.personal.search.loader)
  const localSession = useSelector((state: RootState) => state.global.session.local)
  // const empresa = useSelector((state: RootState) => state.global.session.empresa)
  const localUsuario = useSelector((state: RootState) => state.global.session.usuario)
  const currentTab = useSelector((state: RootState) => {
    return state.nomina.personal.tabs.current
  })
  const searchFilter = useSelector((state: RootState) => {
    return state.nomina.personal.search.filtro
  })

  const { getPersonalDatasource } = PersonalServices.useSetDataSources()
  const getFilterString = React.useCallback((filter: FiltroBusquedaPersonal) => {
    const filterText = ''
    return filterText
  }, [])
  const DataSourcePersonal = useMemo(
    () => getPersonalDatasource(filterSearch, localUsuario?.rol ?? ''),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterSearch],
  )

  const [reporte, setReporte] = React.useState<null | "Viewer" | "Designer">(null);
  const [dataSourcePersonal, setDataSourcePersonal] = useState<DataSource>(null)
  const [pageIndex, setPageIndex] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(10)
  const [resultadoImprimir, setResultadoImprimir] = useState<Array<PersonalList> | []>([])
  const dialogRef = useRef<any>(null)
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false)
  const [confirmEdit, setConfirmEdit] = useState<boolean>(false)
  const [vistaActual, setVistaActual] = useState<VistasBusqueda>(
    VistasBusqueda.Filtros,
  )
  const onSetButtonAction = useCallback(
    (tipo: ButtonTypes | undefined) => {
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'BUSQUEDA',
          buttonType: tipo,
        }),
      )
    },
    [dispatch],
  )

  const setToast = useCallback(
    (mensaje: string, tipo: ToastTypes) => {
      dispatch(
        addToast({
          title: 'Nómina - Personal',
          content: mensaje,
          type: tipo,
        }),
      )
    },
    [dispatch],
  )

  const onLoader = useCallback((showLoader: boolean, mensaje: string) => {
    dispatch(setChangeLoader({
      show: showLoader,
      mensaje: mensaje
    }))
  }, [dispatch])



  const initFilter = React.useCallback(async () => {
    const filter = { ...filterSearch }
    if (filter?.initital) {
      onSetButtonAction(ButtonTypes.users)
      onLoader(true, 'Cargando...')
      await tipoIdentificacionService.getTipoIdetificacion(TipoTransaccion.compra, localSession?.ciudad?.paisCodigo ?? 1)
      await estadosService.getEstados("Elija un estado")
      await tipoSangreService.getTipoSangre('Elija tipo')
      await tipoEstadoService.getTipoEstado('Elija un estado')
      await estudiosService.getEstados("Elija una opción")
      await localesService.getLocales('Elija una opción')
      await localidadesService.getDpCiudades(localSession?.ciudad?.provinciaCodigo ?? 1, "Elija una opción");

      const resPais = await localidadesService.getDpPaises("Elija una opción");
      const resLocal = await getLocales('Elija una opcion')

      if (resLocal.length > 0) {
        const provider = resLocal.slice(0)
        const index = await getIndexProvider(provider, 'codigo', localSession?.codigo ?? 0)
        if (index > -1) {
          filter.locales = resLocal[index]
        } else {
          filter.locales = resLocal[0]
        }
      }
      if (resPais.length > 0) {
        const provider = resPais.slice(0)
        filter.pais = {
          codigo: provider[0]?.codigo ?? 0,
          nombre: provider[0]?.nombre ?? '',
          uaf: provider[0]?.codigoUaf ?? ''
        }
      }

      filter.activos = true
      filter.usuario = ''
      filter.cedula = ''
      filter.nombres = ''
      filter.apellidos = ''
      filter.initital = false
      consoleService.log(filter, '____filter initital personal')
      dispatch(changeFilter({ ...filter }))
      onLoader(false, '')
      onSetButtonAction(undefined)
    }
  }, [dispatch, filterSearch, localSession, onLoader, onSetButtonAction])

  const onResetPag = useCallback(async () => {
    setPageIndex(0)
    setPageSize(10)
  }, [])


  const handleOptionChange = useCallback(async (e) => {
    if (e.fullName === 'paging.pageSize') {
      setPageSize(e.value)
    }
    if (e.fullName === 'paging.pageIndex') {
      setPageIndex(e.value)
    }
  }, [])


  const onFind = useCallback(async () => {
    onSetButtonAction(ButtonTypes.find)
    if (!isMobile) {
      onLoader(true, 'Buscando...')
    }
    try {
      if (isMobile) {
        setVistaActual(VistasBusqueda.Loading)
      }
      await DataSourcePersonal.reload()
      setDataSourcePersonal(DataSourcePersonal)
      dispatch(setSearch(true))
      if (isMobile) {
        setVistaActual(VistasBusqueda.ResultadosBusqueda)
      }
      setToast(`${DataSourcePersonal.totalCount()} Registro(s) Encontrado(s)`, ToastTypes.Success)
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
      if (isMobile) {
        setVistaActual(VistasBusqueda.Error)
      }
    }
    if (!isMobile) {
      onLoader(false, '')
    }
    onSetButtonAction(undefined)
  }, [onLoader, DataSourcePersonal, setToast, onSetButtonAction, dispatch])

  const onHandleDelete = React.useCallback(async () => {
    onSetButtonAction(ButtonTypes.delete)
    onLoader(true, 'Eliminando...')
    try {
      const data = await PersonalServices.deletePersonal(seleccionado?.codigo ?? 0)
      if (data !== null && data !== undefined && data['error'] === false) {
        setToast(data['message'], ToastTypes.Success)
      } else {
        setToast(data['message'], ToastTypes.Danger)
      }
    } catch (error) {
      setToast(error?.message, ToastTypes.Danger)
    }
    onLoader(false, '')
    onSetButtonAction(undefined)
  }, [setToast, seleccionado, onLoader, onSetButtonAction])

  const onBroom = React.useCallback(() => {
    setDataSourcePersonal(null)
  }, [])

  const onUndo = React.useCallback(async () => {
    const filter = { ...filterSearch }
    const resLocal = await getLocales('Elija una opcion')
    const resPais = await localidadesService.getDpPaises("Elija una opción");
    if (resPais.length > 0) {
      const provider = resPais.slice(0)
      filter.pais = {
        codigo: provider[0]?.codigo ?? 0,
        nombre: provider[0]?.nombre ?? '',
        uaf: provider[0]?.codigoUaf ?? ''
      }
    }
    if (resLocal.length > 0) {
      const provider = resLocal.slice(0)
      const index = await getIndexProvider(provider, 'codigo', localSession?.codigo ?? 0)
      if (index > -1) {
        filter.locales = resLocal[index]
      } else {
        filter.locales = resLocal[0]
      }
    }
    filter.activos = true
    filter.usuario = ''
    filter.cedula = ''
    filter.nombres = ''
    filter.apellidos = ''
    consoleService.log(filter)
    dispatch(changeFilter({ ...filter }))
    dispatch(setResetSeleccion())
    setResultadoImprimir([])
  }, [dispatch, filterSearch, localSession])

  const onImport = React.useCallback(() => {
    dispatch(setImport(!importar))
  }, [dispatch, importar])

  const onHandleEdit = React.useCallback((personal: any) => {
    const id = uuidv4()
    dispatch(
      initDatosEdicion({
        key: id,
        data: {
          ...defaultPersonalData,
          loading: true,
        },
      }),
    )
    const data: any = {
      codigo: personal?.codigo ?? 0,
      personales: {
        ...personal,
      }
    }
    dispatch(openTab({ key: id, store: data }))
  }, [dispatch])

  const onSaveList = React.useCallback(async () => {
    const saveArray: Array<any> = []
    const newArray = DataSourcePersonal.items()
    consoleService.log(newArray)
    newArray.forEach(x => {
      if (x?.codigo) {
        saveArray.push(x)
      }
    })
    consoleService.log(saveArray, '___saveArray')
    onSetButtonAction(ButtonTypes.save)
    onLoader(true, 'Guardando usuarios...')
    try {
      const data = await PersonalServices.saveGrid(saveArray)
      consoleService.log(data, '___saveArray')
      if (data !== null && data !== undefined && data['error'] === false) {
        setToast(data['message'], ToastTypes.Success)
        DataSourcePersonal.reload()
        setDataSourcePersonal(DataSourcePersonal)
      } else {
        setToast(data['message'], ToastTypes.Danger)
      }
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
    onLoader(false, '')
    onSetButtonAction(undefined)
  }, [DataSourcePersonal, setToast, onLoader, onSetButtonAction])

  const onPrintData = React.useCallback(async (reporte: null | "Viewer" | "Designer") => {
    onSetButtonAction(ButtonTypes.print)
    onLoader(true, 'Genrando reporte...')
    try {
      const toAny: any = fetchPersonalList({ filtro: searchFilter, tipo: localUsuario?.rol ?? '' })
      const res = await dispatch(toAny)
      consoleService.log(res, 'res imprimir')
      if (res?.payload && res?.payload?.error === false) {
        setToast(res?.payload?.message, ToastTypes.Success)
        setResultadoImprimir(res?.payload?.auto ?? [])
        setReporte(reporte)
      } else {
        setToast(res?.payload?.message, ToastTypes.Danger)
        setResultadoImprimir([])
      }
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
    onLoader(false, '')
    onSetButtonAction(undefined)
  }, [dispatch, searchFilter, setToast, onLoader, onSetButtonAction, localUsuario])

  const onConfirmDelete = useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea Eliminar el Registro: ${seleccionado?.nombres ?? '-'}?`,
      actions: [
        Dialog.Action(
          <span>
            <u>A</u>ceptar
          </span>,
          (dialog) => {
            dialog.hide()
            onHandleDelete()
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setConfirmDelete(false)
    return
  }, [onHandleDelete, seleccionado])

  const onConfirmEdit = useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea Editar el registro: ${seleccionado?.nombres ?? '-'}?`,
      actions: [
        Dialog.Action(
          <span>
            <u>A</u>ceptar
          </span>,
          (dialog) => {
            dialog.hide()
            onHandleEdit(seleccionado)
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setConfirmEdit(false)
    return
  }, [onHandleEdit, seleccionado])

  const handleButtonClick = useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.users:
        case ButtonTypes.find:
          if (tabs.current === tabId) {
            onResetPag()
            onFind()
          }
          break
        case ButtonTypes.delete:
          if (tabs.current === tabId) {
            if (seleccionado !== null && seleccionado !== undefined && seleccionado?.codigo) {
              setConfirmDelete(true)
            }
          }
          break
        case ButtonTypes.edit:
          if (tabs.current === tabId) {
            if (seleccionado !== null && seleccionado !== undefined && seleccionado?.codigo) {
              setConfirmEdit(true)
            }
          }
          break
        case ButtonTypes.broom:
          if (tabs.current === tabId) onBroom()
          break
        case ButtonTypes.undo:
          if (tabs.current === tabId) onUndo()
          break
        case ButtonTypes.import:
          if (tabs.current === tabId) onImport()
          break
        case ButtonTypes.save:
          if (tabs.current === tabId) onSaveList()
          break
        case ButtonTypes.print:
          if (tabs.current === tabId) onPrintData('Viewer')
          break
        case ButtonTypes.print_design:
          if (tabs.current === tabId) onPrintData('Designer')
          break
        default:
          break
      }
      dispatch(clearButtonClick(tabId))
    }, [dispatch, tabId, onFind, onResetPag, seleccionado, onBroom, onImport, onUndo, tabs, onSaveList, onPrintData])

  useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab.globalButtonClick])


  useEffect(() => {
    initFilter()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (searchPersonal) setDataSourcePersonal(DataSourcePersonal)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (confirmDelete) {
      onConfirmDelete()
    }
  }, [confirmDelete, onConfirmDelete])

  useEffect(() => {
    if (confirmEdit) {
      onConfirmEdit()
    }
  }, [confirmEdit, onConfirmEdit])

  const onParseData = React.useCallback((personal: Array<PersonalList>) => {
    consoleService.log(personal)
    let registros: Array<PersonalListDto> = [];
    let registro: PersonalListDto = null

    if (personal.length > 0) {
      registros = personal.map(item => {
        registro = {
          Identification: item?.cedula ?? '',
          User: item?.idsesion ?? '',
          Firstnames: item?.nombres ?? '',
          Lastnames: item?.apellidos ?? '',
          Direction: item?.direccion ?? '',
          Email: item?.email ?? '',
          Cellphone: item?.telefono ?? ''
        }
        return registro
      })
      // registros.push(registro)
    }

    consoleService.log(registros)
    return registros

  }, []);
  React.useEffect(() => {
    dispatch(
      setButtons({
        tabKey: 'BUSQUEDA',
        buttons: {
          Nuevo: true,
          Guardar: dataSourcePersonal !== null,
          Editar: seleccionado !== null && seleccionado !== undefined,
          Eliminar: seleccionado !== null && seleccionado !== undefined,
          Buscar: true,
          Imprimir: dataSourcePersonal !== null,
          Deshacer: true,
          Limpiar: dataSourcePersonal !== null,
          Permisos: seleccionado !== null && seleccionado !== undefined,
          Asignar: true,
          Asignar_usuarios_a_locales: true,
          Usuarios: true,
          Importar: true,
        },
      }),
    )
  }, [dispatch, seleccionado, dataSourcePersonal])

  useEffect(() => {
    switch (estadoBusqueda) {
      case FETCH_STATUS.IDDLE:
        setVistaActual(VistasBusqueda.Filtros)
        break
      case FETCH_STATUS.LOADING:
        setVistaActual(VistasBusqueda.Loading)
        break
      case FETCH_STATUS.SUCCESS:
        setVistaActual(VistasBusqueda.ResultadosBusqueda)
        break
      case FETCH_STATUS.FAILED:
        setVistaActual(VistasBusqueda.Error)
        break
      default:
        break
    }
  }, [estadoBusqueda])

  if (isMobile) {
    return (
      <>
        <ReportWithLocalDataModal
          show={reporte !== null}
          onClose={() => setReporte(null)}
          data={onParseData(resultadoImprimir ?? [])}
          fileName='StaticPersonalList'
          mode={reporte ?? 'Viewer'}
          parameters={{ 'Reporte_Filtro': getFilterString(searchFilter) }}
          template='StaticPersonalList'
          key='reportDesigner'
        />
        <CCard>
          <Dialog ref={dialogRef} />
          {vistaActual === VistasBusqueda.Filtros && (
            <>
              <FiltroBusqueda tab={tab} tabId={tabId} onEnter={onFind} />
            </>
          )}
          {vistaActual === VistasBusqueda.ResultadosBusqueda && (
            <>
              <ResultadosBusqueda
                handleOptionChange={handleOptionChange}
                pageIndex={pageIndex}
                pageSize={pageSize}
                data={dataSourcePersonal}
                onDBClick={() => setConfirmEdit(true)}
                onDelete={() => setConfirmDelete(true)}
              />
            </>
          )}
          {vistaActual === VistasBusqueda.Error && <></>}
          {vistaActual === VistasBusqueda.Loading && (
            <CCardFooter>
              <TableLoader />
            </CCardFooter>
          )}
          {currentTab === tabId && (
            <Draggable id="list" data="dropArea" group={draggingGroupName}>
              <SpeedDialAction
                icon="filter"
                label="Filtros"
                visible={true}
                index={1}
                onClick={() => {
                  setVistaActual(VistasBusqueda.Filtros)
                }}
              />
              <SpeedDialAction
                icon="search"
                label="Busqueda"
                visible={true}
                index={2}
                onClick={() => {
                  setVistaActual(VistasBusqueda.ResultadosBusqueda)
                }}
              />
            </Draggable>
          )}
        </CCard>
      </>
    )
  }

  return (
    <>
      <ReportWithLocalDataModal
        show={reporte !== null}
        onClose={() => setReporte(null)}
        data={onParseData(resultadoImprimir ?? [])}
        fileName='StaticPersonalList'
        mode={reporte ?? 'Viewer'}
        parameters={{ 'Reporte_Filtro': getFilterString(searchFilter) }}
        template='StaticPersonalList'
        key='reportDesigner'
      />
      <Dialog ref={dialogRef} />
      <div id="PersonalContent">
        <BlockUi
          tag="div"
          loader={LoadingIndicator}
          blocking={loader.show}
          message={loader.mensaje}
        >
          <CCard>
            <FiltroBusqueda tab={tab} tabId={tabId} onEnter={onFind} />
            <ResultadosBusqueda
              onDBClick={() => setConfirmEdit(true)}
              data={dataSourcePersonal}
              handleOptionChange={handleOptionChange}
              pageIndex={pageIndex}
              pageSize={pageSize}
              onDelete={() => setConfirmDelete(true)}
            />
          </CCard>
        </BlockUi>
      </div>
    </>
  )
}

export default React.memo(Search)