import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  FiltrosIngresosEgresosState,
  BusquedaState,
  MovimientoTipo,
  ETittleTab,
} from '../types/types'
import { FETCH_STATUS } from '../../../../../../store/types'
import { incomeExpensesServices } from '../services/ingresosEgresos.services'
import { initSearch } from '../components/buscar/filtroBusqueda'
import { initSearchResumen } from '../components/resumenProductos'
import { LocalOption } from '../../../../../componentes/localesLookUp/store/type'

const initialState: BusquedaState<MovimientoTipo> = {
  filter: {
    ...initSearch,
  },
  filterResumen: {
    ...initSearchResumen,
  },
  status: FETCH_STATUS.IDDLE,
  statusResume: FETCH_STATUS.IDDLE,
  statusItems: FETCH_STATUS.IDDLE,
  mensaje: '',
  resultados: [],
  resultadosResumen: [],
  resultadosItems: [],
  seleccionado: null,
  verRegistro: false,
  colapsado: true,
  colapsadoResumen: true,
  tabs: ['Cabecera', 'Detalle', 'Base', 'Consolidado'],
  currentTab: ETittleTab.cab,
}

export const fetchIncomeExpenses = createAsyncThunk<Array<MovimientoTipo>, any>(
  'inventarios/movimientos/ingresos/egresos/listar',
  async (filtro) => {
    try {
      const incomeExpensesList =
        incomeExpensesServices.getIncomeExpenses(filtro)
      return incomeExpensesList
    } catch (error) {
      return Promise.reject(error)
    }
  },
)

export const fetchIncomeExpensesResume = createAsyncThunk<Array<MovimientoTipo>, any>(
  'inventarios/movimientos/ingresos/egresos/listarResumen',
  async (filtro) => {
    try {
      const incomeExpensesList =
        incomeExpensesServices.postIncomeExpensesReport(filtro)
      return incomeExpensesList
    } catch (error) {
      return Promise.reject(error)
    }
  },
)


export const fetchAllItemsByLocal = createAsyncThunk<Array<MovimientoTipo>, number>(
  'inventarios/movimientos/ingresos/egresos/listarItems',
  async (id) => {
    try {
      const incomeExpensesList =
        await incomeExpensesServices.getIncomeExpensesItemsLocal(id)
      return incomeExpensesList
    } catch (error) {
      return Promise.reject(error)
    }
  },
)

const searchSlice = createSlice({
  name: 'buscarIngEgr',
  initialState: initialState,
  reducers: {
    setCleanResult(state) {
      state.resultados = []
    },
    setResultados(state, action: PayloadAction<Array<MovimientoTipo>>) {
      state.resultados = action.payload
    },
    setAlmacen(state, action: PayloadAction<LocalOption>) {
      state.filter.almacen = action.payload
    },
    setVerRegistro(state, action: PayloadAction<boolean>) {
      state.verRegistro = action.payload
    },
    setColapsado(state, action: PayloadAction<boolean>) {
      state.colapsado = action.payload
    },
    setColapsadoResumen(state, action: PayloadAction<boolean>) {
      state.colapsadoResumen = action.payload
    },
    changeFilter(state, action: PayloadAction<FiltrosIngresosEgresosState>) {
      state.filter.fechaDesde = action.payload.fechaDesde
      state.filter.fechaHasta = action.payload.fechaHasta
      state.filter.codigo = action.payload.codigo
      state.filter.documento = action.payload.documento
      state.filter.almacen = action.payload.almacen
      state.filter.proveedor = action.payload.proveedor
      state.filter.cliente = action.payload.cliente
      state.filter.cosolidar = action.payload.cosolidar
      state.filter.inventarioInicial = action.payload.inventarioInicial
      state.filter.esRapida = action.payload.esRapida
      state.filter.movimientos = action.payload.movimientos
      state.filter.items = action.payload.items
      state.filter.tipoItem = action.payload.tipoItem
      state.filter.initial = action.payload.initial
    },
    changeFilterResumen(state, action: PayloadAction<FiltrosIngresosEgresosState>) {
      state.filterResumen.fechaDesde = action.payload.fechaDesde
      state.filterResumen.fechaHasta = action.payload.fechaHasta
      state.filterResumen.codigo = action.payload.codigo
      state.filterResumen.documento = action.payload.documento
      state.filterResumen.almacen = action.payload.almacen
      state.filterResumen.proveedor = action.payload.proveedor
      state.filterResumen.cliente = action.payload.cliente
      state.filterResumen.cosolidar = action.payload.cosolidar
      state.filterResumen.inventarioInicial = action.payload.inventarioInicial
      state.filterResumen.esRapida = action.payload.esRapida
      state.filterResumen.movimientos = action.payload.movimientos
      state.filterResumen.items = action.payload.items
      state.filterResumen.tipoItem = action.payload.tipoItem
      state.filterResumen.initial = action.payload.initial
    },
    changeCurrentTab(state, action: PayloadAction<string>) {
      state.currentTab = action.payload
    },
    setResetSeleccion(state) {
      state.seleccionado = null
    },
    setSeleccionarDato(state, action: PayloadAction<MovimientoTipo>) {
      state.seleccionado = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchIncomeExpenses.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchIncomeExpenses.fulfilled, (state, { payload }) => {
      state.resultados = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchIncomeExpenses.rejected, (state, { payload }) => {
      state.resultados = []
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
    builder.addCase(fetchIncomeExpensesResume.pending, (state) => {
      if (
        state.statusResume === FETCH_STATUS.IDDLE ||
        state.statusResume === FETCH_STATUS.SUCCESS
      ) {
        state.statusResume = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchIncomeExpensesResume.fulfilled, (state, { payload }) => {
      state.resultadosResumen = payload
      state.statusResume = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchIncomeExpensesResume.rejected, (state, { payload }) => {
      state.resultadosResumen = []
      state.statusResume = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
    builder.addCase(fetchAllItemsByLocal.pending, (state) => {
      if (
        state.statusItems === FETCH_STATUS.IDDLE ||
        state.statusItems === FETCH_STATUS.SUCCESS
      ) {
        state.statusItems = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchAllItemsByLocal.fulfilled, (state, { payload }) => {
      state.resultadosItems = payload
      state.statusItems = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchAllItemsByLocal.rejected, (state, { payload }) => {
      state.resultadosItems = []
      state.statusItems = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
  },
})

export const {
  changeFilter,
  setCleanResult,
  changeCurrentTab,
  setResetSeleccion,
  setSeleccionarDato,
  setResultados,
  setAlmacen,
  changeFilterResumen,
  setVerRegistro,
  setColapsado,
  setColapsadoResumen
} = searchSlice.actions

export const searchReducer = searchSlice.reducer
