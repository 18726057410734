import { CCard, CCardBody, CCardHeader } from '@coreui/react-pro'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import RowContainer from '../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../views/componentes/colContainer'
import { GlobalMenu } from '../../../views/componentes/globalMenu/globalMenu'
import LoadPanel from 'devextreme-react/load-panel';
import config from '../../../config/config'
import { AccionMenu, ToastTypes } from '../../../store/types'
import { ButtonTypes } from '../../../views/componentes/globalMenu/types'
import { addToast } from '../../../store/toasterReducer'
import { RootState } from '../../../store/store'
// import Buscar from './components/buscar'
import { changeLoader } from '../../../store/reducers'
import { TListadoAnticipos } from './store/types'
import Modalform from '../../../views/componentes/modalform'
import Buscar from './componentes/buscar/buscar'

interface IModalListarAnticiposProps extends React.PropsWithChildren {
  onChanged: (data: TListadoAnticipos) => void
  onCancel: () => void
  show: boolean,
  codigoAnticipo: number
}

export const ModalListarAnticipos: React.FC<IModalListarAnticiposProps> = (
  props,
) => {
  const dispatch = useDispatch()
  const { show, onCancel, onChanged, codigoAnticipo } = props
  const loader = useSelector((state: RootState) => state.global.loader);

  const botones = config['menuDefectoSearch'].map(acc => {
    const boton: AccionMenu = {
      nombre: acc.id,
      icon: acc.icon,
      actionType: acc.type,
      main: false,
      modal: false,
      shortcut: null
    }
    return boton;
  });

  const [acciones,] = React.useState<Array<AccionMenu>>(botones);
  const [currentTab, setCurrentTab] = React.useState<any>("Buscar");
  const [funcion, setFuncion] = React.useState<string>("");



  const setToast = React.useCallback((texto: string, type: ToastTypes) => {
    dispatch(addToast({
      id: '',
      autoHide: 3500,
      title: 'Acatha',
      content: texto,
      fade: true,
      type: type
    }));
  }, [dispatch]);


  const playLoader = React.useCallback(async () => {
    dispatch(changeLoader({ show: true, mensaje: 'Cargando...' }))
  }, [dispatch]);

  const stopLoader = React.useCallback(async () => {
    dispatch(changeLoader({ show: false, mensaje: '' }))
  }, [dispatch]);

  const returnAction = (boton: AccionMenu) => {

    switch (boton.actionType) {
      case ButtonTypes.find:
        if (currentTab == "Buscar") {
          setFuncion(ButtonTypes.find)
          return false;
        } else {
          setCurrentTab("Buscar")
          setFuncion(ButtonTypes.broom)
        }
        break;
      case ButtonTypes.broom:
        setFuncion(ButtonTypes.broom)
        break;
      case ButtonTypes.close:
        onCancel()
        break;
      default:
        break;
    }
  }

  const getButtonDisabled = React.useCallback((button: AccionMenu) => {
    switch (button.actionType) {
      case ButtonTypes.find:
      case ButtonTypes.broom:
      case ButtonTypes.close:
        return false;
      default:
        return true;
    }
  }, []);

  const onVerificaCodAnticipo = React.useCallback(() => {
    if (codigoAnticipo === undefined || codigoAnticipo === null || codigoAnticipo === 0) {
      setToast("No se encuentra el código del anticipo", ToastTypes.Warning);
      onCancel();
    }
  }, [codigoAnticipo, setToast, onCancel]);

  React.useEffect(() => {
    setCurrentTab("Buscar")
    setFuncion(ButtonTypes.find)
  }, []);

  React.useEffect(() => {
    if (show === true) {
      onVerificaCodAnticipo()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const botonesModal = () => {
    return (
      <GlobalMenu
        acciones={acciones}
        onClick={returnAction}
        getButtonDisabled={getButtonDisabled}
      />
    );
  }

  const bodyModal = () => {
    return (
      <RowContainer>
        <CustomCol xs='12' md='12'>
          {currentTab === "Buscar" && (
            <Buscar
              onChanged={onChanged}
              funcion={funcion}
              resetFunction={() => setFuncion('')}
              ejecutarBusqueda={() => setFuncion("Buscar")}
              setToast={setToast}
              playLoader={playLoader}
              stopLoader={stopLoader}
              codigoAnticipo={codigoAnticipo}
            />
          )}
        </CustomCol>
      </RowContainer>
    );
  }

  const renderContent = () => {
    return (
      <CCard>
        <CCardHeader>
          {botonesModal()}
        </CCardHeader>
        <CCardBody>
          <LoadPanel
            shadingColor="rgba(0,0,0,0.4)"
            position={'center'}
            visible={loader.show}
            showIndicator={true}
            shading={true}
            showPane={true}
            message={loader.mensaje}
            closeOnOutsideClick={false}
          />
          {bodyModal()}
        </CCardBody>
      </CCard>
    )
  }

  return (
    <Modalform
      name="seleccionarAnticipo"
      headerTitle={'Lista de Anticipos'}
      childrenBody={renderContent()}
      childrenFooter={null}
      closeOnBackdrop={false}
      show={show}
      onClose={() => onCancel()}
      centered={true}
      size="lg"
    />
  )
}

