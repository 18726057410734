import React, { useEffect } from 'react'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput'
import { SeriesList } from '../../types/types'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import { clearButtonClick, openTab } from '../../store/tabsReducer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/store'
import {
  changeLoaderMultistore,
  setCurrentFunction,
} from '../../store/generalReducer'
import { initDatosEdicion } from '../../store/editDataReduce'
import Dialog from '../../../../../../views/componentes/librerias/bootstrap-dialog'
import { defaultDataStore } from '../..'
import { v4 as uuidv4 } from 'uuid'
import { TextBox } from 'devextreme-react'
import TipoEstadoLookUp from '../../../../../componentes/tipoEstado'
import { TabState } from '../../../../../../store/genericTabTypes'

interface INewProps extends React.PropsWithChildren {
  tab: TabState<SeriesList>
  tabId: string
}

const Nuevo: React.FunctionComponent<INewProps> = (props) => {
  const { tab, tabId } = props
  const dispatch = useDispatch()

  const currentAction = useSelector(
    (state: RootState) => state.inventarios.series.general.currentFunction,
  )
  const tabs = useSelector((state: RootState) => {
    return state.inventarios.series.tabs
  })

  const [confirmarNuevo, setconfirmarNuevo] = React.useState<boolean>(false)
  const dialogRef = React.useRef<any>(null)

  const playLoader = React.useCallback(
    (message = 'Cargando...') => {
      dispatch(changeLoaderMultistore({ show: true, mensaje: message }))
    },
    [dispatch],
  )

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoaderMultistore({ show: false, mensaje: '' }))
  }, [dispatch])

  const onConfirmarNuevo = React.useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea crear un nuevo registro?`,
      actions: [
        Dialog.Action(
          <span>
            <u>P</u>estaña Actual
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-success',
          'p',
        ),
        Dialog.Action(
          <span>
            <u>N</u>ueva Pestaña
          </span>,
          (dialog) => {
            dialog.hide()
            const id = uuidv4()
            const dataInitialDefault = {
              ...defaultDataStore,
            }
            dispatch(
              initDatosEdicion({
                key: id,
                data: dataInitialDefault,
              }),
            )
            dispatch(openTab({ key: id }))
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setconfirmarNuevo(false)
    return
  }, [dispatch])

  React.useEffect(() => {
    if (confirmarNuevo) {
      onConfirmarNuevo()
    }
  }, [confirmarNuevo, onConfirmarNuevo])

  const onHandleSave = React.useCallback(async () => {}, [])

  const onHandleUndo = React.useCallback(() => {}, [])

  const handleButtonClick = React.useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.save:
          if (tabs.current === tabId) onHandleSave()
          break
        case ButtonTypes.new:
          if (tabs.current === tabId) setconfirmarNuevo(true)
          break
        case ButtonTypes.undo:
          if (tabs.current === tabId) onHandleUndo()
          break
        default:
          break
      }
      dispatch(setCurrentFunction(''))
      dispatch(clearButtonClick(tabId))
    },
    [dispatch, onHandleSave, onHandleUndo, tabId, tabs],
  )

  useEffect(() => {
    if (currentAction !== '') handleButtonClick(currentAction)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAction])

  return (
    <>
      <Dialog ref={dialogRef} />
      <RowContainer>
        <CustomCol xs="12" md="3">
          <Labeled label="Nombre:">
            <TextBox
              value={null}
              onValueChanged={() => []}
              //onEnterKey={onEnterKeyHandle}
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="3">
          <Labeled label="Estado:">
            <TipoEstadoLookUp onChanged={() => {}} selected={null} />
          </Labeled>
        </CustomCol>
      </RowContainer>
    </>
  )
}

export default React.memo(Nuevo)
