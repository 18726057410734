import React from 'react'
import {
  CButton,
  CCol,
  CRow, CTooltip
} from '@coreui/react-pro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux'
import {
  Column,
  DataGrid,
  FilterRow,
  HeaderFilter,
  Editing,
  Selection,
  RequiredRule,
  NumericRule,
  Paging,
  KeyboardNavigation
} from 'devextreme-react/data-grid';
import NumberBox from 'devextreme-react/number-box';
import { RootState } from '../../../../../../../store/store';
import { setAnticipos, setRowSelectedAnticipos } from '../../store/modalAnticiposReducer';
import { changeLoader } from '../../../../../../../store/reducers';
import { utilidades } from '../../../../../../../helpers/utilidades';

type TabAnticiposProps = {
  agregarAsignacion: () => void
  removerAsignacion: () => void
}

const TabAnticipos = (props: TabAnticiposProps) => {

  const dispatch = useDispatch()

  // const anticiposState = useSelector((state: RootState) => state.bancos.modalAnticipos.anticipos);
  // const asignadosState = useSelector((state: RootState) => state.bancos.modalAnticipos.asignados);
  // const rowsSelectedAnticipos = useSelector((state: RootState) => state.bancos.modalAnticipos.rowsSelectedAnticipos);

  // const [data, setData] = React.useState<Array<any>>([])
  // const [guardar, setGuardar] = React.useState<boolean>(false);

  // let ACV_VALOR: number = 0;

  // React.useEffect(() => {
  //   if (anticiposState.length > 0) {
  //     llenarData(anticiposState)
  //   } else {
  //     setData([])
  //   }
  // }, [anticiposState])

  // const llenarData = (data) => {
  //   let item: any = []
  //   item = data.map(function (key, i) {
  //     return {
  //       ...key,
  //       ORDEN: Number(i),
  //       ACV_VALOR: Number(key['ACV_VALOR'])
  //     }
  //   })
  //   setData(item.map(x => utilidades.unfreeze(x)))
  // }

  // const playLoader = () => {
  //   dispatch(changeLoader({ show: true, mensaje: 'Cargando...' }))
  // }

  // const stopLoader = () => {
  //   dispatch(changeLoader({ show: false, mensaje: '' }))
  // }

  // // const onRowUpdating = React.useCallback((rowUpdating) => {
  // //   console.log("onRowUpdating", rowUpdating)
  // //   dispatch(setRowSelectedAnticipos({
  // //     index: [rowUpdating['key']],
  // //     row: { ...rowUpdating['oldData'], ACV_VALOR: rowUpdating['newData']['ACV_VALOR'] },
  // //     selectedRow: [rowUpdating['key']]
  // //   }))
  // //   setSelectedItemKeys([rowUpdating['key']])
  // // }, [])

  // const onEditingChange = (editingChange) => {
  //   console.log('onEditingChange', editingChange)
  // }

  // const onEditingStart = React.useCallback((editingStart) => {
  //   console.log('onEditingStart', editingStart)
  //   console.log('anticiposState', data)
  //   console.log('anticiposState key', data[editingStart['key']])
  //   ACV_VALOR = 0;
  //   setGuardar(false)
  //   dispatch(setRowSelectedAnticipos({
  //     index: [editingStart['key']],
  //     row: data[editingStart['key']],
  //     selectedRow: [editingStart['key']]
  //   }))
  //   // setSelectedItemKeys([editingStart['key']])
  // }, [data])

  // // const onRowDblClick = (rowDblClick) => {
  // //   console.log("onRowDblClick", rowDblClick)
  // // }

  // // const onRowClick = (rowClick) => {
  // //   console.log("onRowClick", rowClick)
  // // }

  // const onSelectionChanged = (rowData) => {
  //   console.log('onSelectionChanged', rowData)
  //   dispatch(setRowSelectedAnticipos({
  //     index: [rowData['currentSelectedRowKeys'][0]],
  //     row: rowData['selectedRowsData'][0],
  //     selectedRow: [rowData['currentSelectedRowKeys'][0]]
  //   }))
  //   // setSelectedItemKeys([rowData['currentSelectedRowKeys'][0]])
  // }

  // const onRowUpdatedFunction = React.useCallback((dataRow) => {
  //   console.log('onRowUpdatedFunction', dataRow)
  //   playLoader()
  //   dispatch(setRowSelectedAnticipos({
  //     index: [dataRow['key']],
  //     row: {
  //       ...dataRow['data'], ACV_VALOR: dataRow['data']['ACV_VALOR']
  //     },
  //     selectedRow: [dataRow['key']]
  //   }))
  //   // setSelectedItemKeys([dataRow['key']])
  //   let rowsBefore = data.slice();
  //   rowsBefore = rowsBefore.map(x => utilidades.unfreeze(x))
  //   console.log("rowsBefore", rowsBefore)
  //   console.log("ACV_VALOR", rowsBefore[dataRow['key']])
  //   rowsBefore[dataRow['key']]['ACV_VALOR'] = dataRow['data']['ACV_VALOR'];
  //   dispatch(setAnticipos(rowsBefore))
  //   // props.agregarAsignacion()
  //   setGuardar(true)
  //   stopLoader()
  // }, [data])

  // const onKeyUp = React.useCallback((dataRow) => {
  //   if (dataRow.event.keyCode === 13) {
  //     console.log('pasar anticipo')
  //   }
  // }, []);

  // const customInput = (cell) => {
  //   const { data } = cell;
  //   if (data.ACV_VALOR) {

  //     return <div>
  //       <NumberBox
  //         // onKeyUp={onKeyUp}
  //         defaultValue={cell.value}
  //         onValueChanged={(e) => {
  //           cell.setValue(e.value)
  //           ACV_VALOR = e.value;
  //         }}
  //         format="#,##0.00"
  //         inputAttr={{
  //           autofocus: true
  //         }}
  //         valueChangeEvent="keyup"
  //       />
  //     </div>

  //   } else {

  //     return <div >
  //       <NumberBox
  //         format="#,##0.00"
  //         defaultValue={cell.value}
  //         onValueChanged={(e) => cell.setValue(e.value)}
  //       />
  //     </div >
  //   }

  // }

  // const onInputChanged = React.useCallback((newData, value, currentRowData) => {
  //   if (value) {
  //     const _value = parseFloat(value);
  //     newData.ACV_VALOR = parseFloat(_value.toFixed(2))
  //   } else {
  //     newData.ACV_VALOR = 0;
  //   }
  // }, []);

  return (
    <>
      {/* <CRow className="mt-3">
        <CCol lg="12"  >
          <DataGrid
            hoverStateEnabled={true}
            dataSource={data.map(x => utilidades.unfreeze(x))}
            showBorders={true}
            showRowLines={true}
            onSelectionChanged={onSelectionChanged}
            allowColumnReordering={true}
            allowColumnResizing={true}
            columnAutoWidth={true}
            keyExpr="ORDEN"
            key="gridAnticipos"
            selectedRowKeys={rowsSelectedAnticipos['index']}
            onEditingStart={onEditingStart}
            // onEditingChange={onEditingChange}
            // onRowUpdating={onRowUpdating}
            onRowUpdated={onRowUpdatedFunction}
          // onRowClick={onRowClick}
          // onRowDblClick={onRowDblClick}
          >
            <KeyboardNavigation
              editOnKeyPress={true}
              enterKeyAction={'moveFocus'}
              enterKeyDirection={'column'} />
            <Selection
              mode="multiple"
              showCheckBoxesMode={"onCLick"}
            />
            <FilterRow visible={true} />
            <HeaderFilter visible={true} />
            <Paging enabled={true} defaultPageSize={10} />
            <Editing mode='cell' allowUpdating={true} />

            <Column visible={false} dataField="ORDEN" caption="Orden" width={90} />
            <Column allowEditing={false} dataField='anticipoTipo' caption='Tipo' width='100%' />
            <Column allowEditing={false} dataField='anticipoNumero' caption='Num.' width='100%' />
            <Column allowEditing={false} dataField='anticipoRegistro' caption='Fecha' width='100%' />
            <Column allowEditing={false} dataField='anticipoAsociadoDescripcion' caption='Asociado' width='100%' />
            <Column allowEditing={false} dataField='anticipoConcepto' caption='Concepto' width='100%' />
            <Column allowEditing={false} dataField='anticipoDocumento' caption='Documento' width='100%' />
            <Column allowEditing={false} dataField='anticipoValor' caption='Total' width='100%' />
            <Column allowEditing={false} dataField='anticipoDevengado' caption='Devengado' width='100%' />
            <Column allowEditing={false} dataField='anticipoSaldoReal' caption='Saldo' width='100%' />
            <Column
              editCellRender={customInput}
              setCellValue={onInputChanged}
              allowEditing={true} dataType='number'
              alignment='center'
              dataField='ACV_VALOR'
              caption='Valor'
              width='100%'
            >
              <RequiredRule message='El valor es requerido' />
              <NumericRule message='El valor debe ser numérico' />
            </Column>

          </DataGrid>

        </CCol>
      </CRow>

      <br></br>
      <CRow>
        <CCol sm={12} md={12} lg={12} className="d-flex justify-content-end" >
          <CTooltip
            key={"tool-plus-account-anticipo"}
            placement="top"
            content={"Agregar"}
          >
            <CButton id="btnAgregaAnticipo" disabled={!guardar} color="secondary" className="ms-1" size='sm' onClick={() => props.agregarAsignacion()} >
              <FontAwesomeIcon
                size="lg"
                icon={['fal', "plus"]} style={{ fontWeight: 1 }}
              />
            </CButton>
          </CTooltip>
          <CTooltip
            key={"tool-minus-account-anticipo"}
            placement="top"
            content={"Quitar"}
          >
            <CButton id="btnRemoveAnticipo" color="secondary" disabled={asignadosState.length > 0 ? false : true} className="ms-1" size='sm' onClick={() => props.removerAsignacion()} >
              <FontAwesomeIcon
                size="lg"
                icon={['fal', "minus"]} style={{ fontWeight: 1 }}
              />
            </CButton>
          </CTooltip>
        </CCol>
      </CRow> */}
    </>
  )
}

export default React.memo(TabAnticipos);
