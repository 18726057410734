import { utilidades } from "../../../../../../helpers/utilidades";
import { typesMovementsServices } from "../../../../../componentes/tiposMovimientosLookUp/services/tiposMovimientos.services";
import { Movimiento } from "../../conceptosMovimientos/types/types";

export async function getTypesMovements() {
  const data = await typesMovementsServices.getTypesMovements();
  let opt = [];
  if (data.error === false) {
    if (data.auto.length > 0) {
      opt = data.auto.map(x => {
        const item: Movimiento = {
          codigo: x?.codigo ?? -1,
          tipo: x?.tipo ?? -1,
          tipoDescripcion: x?.tipoCodigo ?? '',
          descripcion: utilidades.capText(x?.descripcion) ?? '',
          cuenta: x?.cuenta ?? -1,
          cuentaDescripcion: x?.cuentaDescripcion ?? '',
          asociado: x?.asociado ?? -1,
          asociadoDescripcion: x?.asociadoDescripcion ?? '',
          asiento: x?.asiento ?? -1,

        };
        return item;
      });
    }
  }
  return opt
}