import { combineReducers } from '@reduxjs/toolkit'
import { tabsReducer } from './tabsReducer'
import { generalReducer } from './generalReducer'
import { AtributosState } from '../types/types'
import { dataEditReducer } from './editDataReducer'
import { searchReducer } from './serachReducer'
import { menuReducer } from './menuReducer'
import { configuracionesAtributos } from './configReducer'

export const AtributosReducer = combineReducers<AtributosState>({
  general: generalReducer,
  tabs: tabsReducer,
  editData: dataEditReducer,
  search: searchReducer,
  menu: menuReducer,
  config: configuracionesAtributos
})
