/* eslint-disable prefer-const */
import React, { useState, useEffect } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormInput,
  CRow, CTooltip
} from '@coreui/react-pro'
import Modalform from "../../../../../../views/componentes/modalform"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TIPOS_BOTONES } from './../../store/types';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import config from '../../../../../../config/config';
import { BotonMenu } from '../../../../../contabilidad/pages/planCuentas/store/types';
import { menuService } from '../../../../../../services/menu.service';
import { bancosService } from '../../services/bancosService';
import { addToast } from '../../../../../../store/toasterReducer';
import { ToastTypes } from '../../../../../../store/types';
import { useDispatch, useSelector } from 'react-redux'
import { changeLoader } from '../../../../../../store/reducers';
import { Banco } from '../../store/types';
import lang from '../../../../../../config/lang';
import Tabla from "./tabla"
import { GlobalLabel } from '../../../../../../views/componentes/globalLabel/globalLabel';

type ModalBancosProps = {
  show: boolean,
  onClose: () => void,
  cargarCuentas: (evt) => void
}

const ModalBancos = (props: ModalBancosProps) => {

  const dispatch = useDispatch()

  const [acciones, setAcciones] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [busqueda_ti, setBusqueda_ti] = useState<any>("");

  const botones = config['menuDefectoSearch'].map(acc => {
    const boton: BotonMenu = {
      id: acc.text as any,
      icon: menuService.getIcon(acc.icon),
      text: acc.text
    }
    return boton;
  });

  React.useEffect(() => {
    if (props?.show === true) {
      setAcciones(botones)
    }
  }, [props?.show]);

  React.useEffect(() => {
    handleSubmit()
  }, []);



  const setToast = (texto, type) => {
    let tipo: ToastTypes = ToastTypes.Info;
    if (type === 'warning') {
      tipo = ToastTypes.Warning;
    } else if (type === 'success') {
      tipo = ToastTypes.Success;
    } if (type === 'info') {
      tipo = ToastTypes.Info;
    } if (type === 'danger') {
      tipo = ToastTypes.Danger;
    }
    dispatch(addToast({
      id: '',
      autoHide: 3500,
      title: 'Acatha',
      content: texto,
      fade: true,
      type: tipo
    }));
  }

  const playLoader = () => {
    dispatch(changeLoader({ show: true, mensaje: 'Cargando...' }))
  }

  const stopLoader = () => {
    dispatch(changeLoader({ show: false, mensaje: '' }))
  }

  const handleSubmit = async () => {
    setData([]);
    playLoader()
    const buscar = {
      nombre: busqueda_ti
    };
    try {
      const data = await bancosService.obtenerBancos(buscar);
      stopLoader()
      if (data['error'] === false) {
        if (data['auto'].length > 0) {
          setToast("(" + data['auto'].length + ")" + " Registros encontrados.", "success");
          let itemBanco: Array<Banco>;
          itemBanco = data['auto'].map(function (item: Banco) {
            return {
              codigo: Number(item["BAN_CODIGO"]),
              nombre: String(item["BAN_NOMBRE"]),
              ciudad: Number(item["BAN_CIUDAD"]),
              direccion: String(item["BAN_DIRECCION"]),
              telefono: String(item["BAN_TELEFONO"]),
              tipo: Number(item["BAN_TIPO"]),
              tipo_nombre: String(item["BAN_TIPO_NOMBRE"]),
              ruc: String(item["BAN_RUC"]),
              especial: String(item["BAN_ESPECIAL"]),
              estado: Number(item["BAN_ESTADO"]),
            };
          });
          setData(itemBanco);
        } else {
          setToast("(" + data['auto'].length + ")" + " Registros encontrados.", "success");
        }
      } else {
        setToast(data.message, "warning");
      }
      return await data;
    } catch (error) {
      console.log('error', error);
      stopLoader()
      setToast(JSON.stringify(error), "danger")
    }

  }

  const returnAction = (metodo) => {
    switch (metodo) {
      case TIPOS_BOTONES.BOTON_LIMPIAR:
        setData([])
        setBusqueda_ti("")
        break;
      case TIPOS_BOTONES.BOTON_BUSCAR:
        handleSubmit()
        break;
      case TIPOS_BOTONES.BOTON_SALIR:
        props.onClose()
        break;
      default:
        break;
    }
  }


  const pasarCuentaSeleccion = (cuenta) => {
    if (cuenta['row']['codigo'] !== "") {
      props.cargarCuentas(cuenta['row']);
      props.onClose()
    }
  }

  const pasarCuenta = (cuenta) => {
    console.log("pasarCuenta", cuenta)
    if (cuenta['codigo'] !== "") {
      props.cargarCuentas(cuenta);
      props.onClose()
    } else {
      setToast("No se encuentra datos de la cuenta seleccionada.", "warning");
    }
  }

  const renderBody = () => {

    return (
      <CCard key="cardCuentas" >
        <CCardHeader >
          <CRow>
            <CCol className="ms-0 d-flex align-items-start justify-content-start" >
              <div key='menuItems' className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups" style={{ marginBottom: '5px', paddingTop: '-2rem' }}>
                <div key='menuItemsBotones' className="btn-group-lg btn-group btn-sm me-2" role="group" aria-label="First group" >
                  {acciones.map(function (x, i) {
                    const prefixButton = "buttonsCuentas_";
                    const nameButon = x.text;
                    const disabled = false;
                    return (
                      <CTooltip
                        key={"tool-" + prefixButton + nameButon}
                        placement="top"
                        content={nameButon}
                      >
                        <CButton
                          key={prefixButton + nameButon}
                          name={prefixButton + nameButon}
                          title={x.text}
                          id={prefixButton + nameButon}
                          value={x.text}
                          className="btn btn-secondary sm"
                          variant="ghost"
                          onClick={(ev) => returnAction(ev = x.text)}
                          size='sm'
                          disabled={disabled}
                        >
                          <FontAwesomeIcon size='sm' icon={x.icon ?? faQuestionCircle} />
                        </CButton>
                      </CTooltip>
                    );
                  })}
                </div>
              </div>
            </CCol>
          </CRow>
        </CCardHeader >

        <CCardBody >

          < >
            <CCol md="2">
              <GlobalLabel htmlFor="busqueda_ti">{"Banco:"}</GlobalLabel>
            </CCol>
            <CCol xs="12" md="7">
              <CFormInput
                type="text"
                size="sm"
                id="busqueda_ti"
                name="busqueda_ti"
                placeholder=""
                autoComplete="off"
                value={busqueda_ti}
                onChange={(
                  ev: React.ChangeEvent<HTMLInputElement>,
                ): void => {
                  setBusqueda_ti(ev.target.value)
                }}
              />
            </CCol>
          </>


          <Tabla
            name="tablamodalBancos"
            data={data}
            actionDelete={() => console.log("data")}
            exportExcel={() => console.log("data")}
            origen="modal"
            returnSeleccion={(data) => {
              pasarCuenta(data)
            }
            }
          />

        </CCardBody >


      </CCard>
    )
  }

  const renderFooter = () => {
    return <></>
  }

  return (
    <CRow key="rowMain">
      <CCol key="colMain" >
        <Modalform
          name='modalBancos'
          headerTitle={"Bancos"}
          childrenBody={renderBody()}
          childrenFooter={renderFooter()}
          closeOnBackdrop={false}
          show={props.show}
          onClose={() => props.onClose()}
          centered={true}
          size="lg"
        />
      </CCol>
    </CRow>
  )
}

export default ModalBancos