import { RequestHelper } from '../../../../../../../helpers/requestHelper'
import { SaveCharacteristic } from '../../../types/types'

export const characteristicsServices = {
  getCharacteristics,
  deleteCharacteristic,
  saveCharacteristic,
}

async function getCharacteristics(name: string): Promise<any> {
  try {
    const res = await RequestHelper.getAll<any>(
      'inventario/catalogos',
      'caracteristicas/getAll',
      '',
      { nombre: name },
    )
    return res
  } catch (error) {
    return error
  }
}

async function deleteCharacteristic(code: number): Promise<any> {
  try {
    const res = await RequestHelper.deleteRequestAll<any>(
      'inventario/catalogos',
      'caracteristicas/delete',
      '',
      { codigo: code },
    )
    return res
  } catch (error) {
    return error
  }
}

async function saveCharacteristic(
  characteristic: SaveCharacteristic,
): Promise<any> {
  try {
    const res = await RequestHelper.postAll<any>(
      'inventario/catalogos',
      'caracteristicas/save',
      { ...characteristic },
    )
    return res
  } catch (error) {
    return error
  }
}
