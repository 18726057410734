import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import DecimoMain from './../components/shared/main'
import { useMenuOptions } from '../../../../../../hooks/useMenuButtonControls'
import { RootState } from '../../../../../../store/store'
import { useDispatch, useSelector } from 'react-redux'
import { utilidades } from '../../../../../../helpers/utilidades'
import { deleteEditData } from '../store/editDataReducer'
import { deleteEditDataBackup } from '../store/configReducer'
import { AccionMenu, CustomButtons, FETCH_STATUS } from '../../../../../../store/types'
import { setMenuButtons } from '../store/menuReducer'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import { buttonClick, changeCurrentTab, decimosCloseTab, openTab, openTabTodos, openTabTransfer, setCurrentExecutingAction } from '../store/tabsReducer'
import { Aplicacion } from '../../../../store/types'
import { TabTypes } from '../../../../../../store/enums'
import { DateUtils } from '../../../../../../helpers/dateUtils'
import { EAplicationsAcatha } from '../../../../../../store/enumsAplication'
import Dialog from '../../../../../../views/componentes/librerias/bootstrap-dialog'

interface IMainProps extends React.PropsWithChildren {
  codigoModulo: number
  pathVerificador: string
}

const MainDecimo: React.FunctionComponent<IMainProps> = (props) => {
  const { codigoModulo, pathVerificador } = props

  const { getMenuOptions, options } = useMenuOptions()
  const dispatch = useDispatch()
  const menuState = useSelector((state: RootState) => state.global.menu)
  const tabs = useSelector((state: RootState) => state.nomina.rol.decimos.tabs)
  const rolparametros = useSelector((state: RootState) => state.nomina.rol.decimos.search.rolparametros)
  const currentApp = useSelector((state: RootState) => state.nomina.rol.decimos.config?.currentApp)
  const usuario = useSelector((state: RootState) => state.global.session.usuario)
  const dialogRef = React.useRef<any>(null)
  const searchStatus = useSelector((state: RootState) => state.nomina.rol.decimos.search.status)
  const searchResults = useSelector((state: RootState) => state.nomina.rol.decimos.search.resultadosTercero)

  const onTabClosing = React.useCallback(
    async (index) => {
      const closedTab = tabs.tabs[index]
      dispatch(decimosCloseTab(closedTab.tabKey ?? index))
      await utilidades.sleep(50)
      dispatch(deleteEditData(closedTab.tabKey ?? index))
      dispatch(deleteEditDataBackup(closedTab.tabKey ?? index))
    },
    [dispatch, tabs.tabs],
  )

  const onActionsButtonsLoaded = React.useCallback((actions: Array<AccionMenu>) => {
    if (actions.length > 0) {
      dispatch(setMenuButtons(actions));
    }
  }, [dispatch]);

  const onConfirmNew = React.useCallback((id) => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `Desea realizar un nuevo registro por persona?`,
      actions: [
        Dialog.Action(
          <span>
            <u>S</u>i
          </span>,
          (dialog) => {
            dialog.hide()
            dispatch(openTab({ key: id }))
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>N</u>o
          </span>,
          (dialog) => {
            dialog.hide()
            dispatch(openTabTodos({ key: 'TODOS', type: TabTypes.todos }))
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    return
  }, [dispatch])

  const onMenuButtonClick = React.useCallback(async (action: AccionMenu, tab: string = "") => {
    const id = uuidv4()
    const fecha = DateUtils.getCurrentDateAsString().substring(3, 2)
    let rolparametro = 0
    if (currentApp === parseInt(EAplicationsAcatha.DecimoTercero)) {
      rolparametro = rolparametros?.decimoTercerPago
    } else {
      rolparametro = rolparametros?.decimoCuartoPago
    }
    switch (action.actionType) {
      case ButtonTypes.find:
        if (tabs.current === 'BUSQUEDA' || tabs.current === "TRANSFERENCIAS") {
          dispatch(buttonClick({
            tabKey: tab === "" ? tabs.current : tab,
            button: action.actionType
          }))
        } else dispatch(changeCurrentTab('BUSQUEDA'))
        break;
      case ButtonTypes.rols:
        if (tabs.current === 'BUSQUEDA' || tabs.current === "TRANSFERENCIAS") {
          dispatch(buttonClick({
            tabKey: tab === "" ? tabs.current : tab,
            button: action.actionType
          }))
        } else dispatch(changeCurrentTab('BUSQUEDA'))
        break;
      case ButtonTypes.new:
        if (usuario?.rol === "ADM" || usuario?.rol === "SADM" || rolparametro === parseInt(fecha)) {
          if (tabs.current === 'BUSQUEDA') {
            dispatch(openTab({ key: id }))
          } else {
            onConfirmNew(id)
          }
        }
        break;
      case ButtonTypes.transfers:
        dispatch(openTabTransfer({ key: 'TRANSFERENCIAS', type: TabTypes.transferencia }))
        break
      case ButtonTypes.all:
        dispatch(openTabTodos({ key: 'TODOS', type: TabTypes.todos }))
        break
      case ButtonTypes.delete:
        dispatch(buttonClick({
          tabKey: tab === "" ? tabs.current : tab,
          button: action.actionType
        }))
        break
      case ButtonTypes.edit:
        dispatch(buttonClick({
          tabKey: tab === "" ? tabs.current : tab,
          button: action.actionType
        }))
        break
      case ButtonTypes.import:
        dispatch(buttonClick({
          tabKey: tab === "" ? tabs.current : tab,
          button: action.actionType
        }))
        break
      case ButtonTypes.save:
        dispatch(buttonClick({
          tabKey: tab === "" ? tabs.current : tab,
          button: action.actionType
        }))
        break
      case ButtonTypes.salaries:
        dispatch(buttonClick({
          tabKey: tab === "" ? tabs.current : tab,
          button: action.actionType
        }))
        break
      case ButtonTypes.undo:
        dispatch(buttonClick({
          tabKey: tab === "" ? tabs.current : tab,
          button: action.actionType
        }))
        break
      case ButtonTypes.broom:
        dispatch(buttonClick({
          tabKey: tab === "" ? tabs.current : tab,
          button: action.actionType
        }))
        break
      case ButtonTypes.print:
        dispatch(buttonClick({
          tabKey: tab === "" ? tabs.current : tab,
          button: action.actionType
        }))
        break
      case ButtonTypes.print_design:
        dispatch(buttonClick({
          tabKey: tab === "" ? tabs.current : tab,
          button: action.actionType
        }))
        break
      default:
        break;
    }
  }, [dispatch, tabs, currentApp, rolparametros, usuario, onConfirmNew])

  const onSearchExecutingAction = React.useCallback((tabKey: string, type?: ButtonTypes) => {
    dispatch(setCurrentExecutingAction({
      tabKey: tabKey,
      buttonType: type
    }))
  }, [dispatch]);


  React.useEffect(() => {
    if (menuState.length > 0 && options.length === 0) {
      getMenuOptions(menuState, 'to', pathVerificador)
    }
  }, [menuState, getMenuOptions, options, pathVerificador])

  const onTabChanged = React.useCallback(
    (tabKey: string) => {
      dispatch(changeCurrentTab(tabKey))
    },
    [dispatch],
  )

  return (
    <>
      <Dialog ref={dialogRef} />
      <DecimoMain
        menu={{ loading: false, accions: options }}
        tabs={tabs}
        onTabChanged={onTabChanged}
        codigoModulo={codigoModulo}
        opciones={[codigoModulo]}
        onActionsButtonsLoaded={onActionsButtonsLoaded}
        onMenuButtonClick={onMenuButtonClick}
        searching={searchStatus === FETCH_STATUS.LOADING}
        ontabClosing={onTabClosing}
        hayResultadosBusqueda={searchResults.length > 0}
        verificarPuntoVenta
        verificarAutorizacion
        onExecutingAction={onSearchExecutingAction}
        tipoAplicacion={Aplicacion.decimos}
      />
    </>
  )
}

export default React.memo(MainDecimo)


export const ModuleButtonsAll: CustomButtons = {
  Nuevo: true,
  Buscar: false,
  Editar: false,
  Eliminar: false,
  Guardar: true,
  Imprimir: false,
  Cheque: false,
  Ver_asiento: false,
  Transferencias: true,
  Pagos: false,
  Sueldos: false,
  Roles: true,
  Todos: false,
  Importar: false,
  Deshacer: true,
  Limpiar: true,
  Adjuntar: false,
}