import { combineReducers } from "redux";
import { menuReducer } from "./menuReducer";
import { tabsReducer } from "./tabsReducer";
import { dataEditReducer } from "./editDataReducer";
import { searchReducer } from "./searchReducer";
import { configuracionesPersonalNomina } from "./configReducer";
import { PersonalState } from "../types/types";
import { organigramaReducer } from "./organigramaReducer";
import { permisosReducer } from "./permisosReducer";


export const PersonalReducer = combineReducers<PersonalState>({
  menu: menuReducer,
  tabs: tabsReducer,
  editData: dataEditReducer,
  search: searchReducer,
  config: configuracionesPersonalNomina,
  organigrama: organigramaReducer,
  permisos: permisosReducer
})