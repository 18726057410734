import {
  FunctionComponent,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Candidate } from '../../types/types'
import SearchFilter from './seacrhFilter'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import { DataGrid, SpeedDialAction } from 'devextreme-react'
import {
  Column,
  Export,
  Pager,
  Paging,
  Button as DatagridButton,
} from 'devextreme-react/data-grid'
import { isMobile } from 'react-device-detect'
import { getAllowedPageSizes } from '../../../../../../helpers/Helper'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/store'
import ItemsCountIndicator from '../../../../../componentes/indicadorNroRegistros'
import BlockUi from '../../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../../views/componentes/loadingindicator/loadingindicator'
import { setIsSearch, setSelectedCandidate } from '../../store/searchReducer'
import { buttonClick, setButtons } from '../../store/tabsReducer'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import { utilidades } from '../../../../../../helpers/utilidades'
import { ActionSheetCommandsMobile } from './actionSheetMobile'
import { SearchViews } from '../../../../../inventario/pages/items/components/buscar'
import { IGenericTabProps } from '../../../../store/types'

const SearchCandidate: FunctionComponent<IGenericTabProps<Candidate>> = (
  props,
) => {
  const { tabId } = props
  const dispatch = useDispatch()

  const resultsSearch = useSelector(
    (state: RootState) => state.nomina.candidatos.search.candidatesList,
  )
  const loader = useSelector(
    (state: RootState) => state.nomina.candidatos.search.loader,
  )
  const currentTab = useSelector(
    (state: RootState) => state.nomina.candidatos.tabs.current,
  )
  const isSearch = useSelector(
    (state: RootState) => state.nomina.candidatos.search.isSearch,
  )

  const dataGrid = useRef<any>()
  const [currentView, setCurrentView] = useState<SearchViews>(
    SearchViews.Filter,
  )

  const onSelectedItemChanged = useCallback(
    (selectedCandidate: Candidate[]) => {
      if (selectedCandidate) {
        dispatch(setSelectedCandidate(selectedCandidate[0]))
        dispatch(
          setButtons({
            tabKey: tabId,
            buttons: {
              Nuevo: true,
              Guardar: false,
              Editar: true,
              Eliminar: true,
              Buscar: true,
              Imprimir: true,
              Deshacer: true,
              Limpiar: true,
            },
          }),
        )
      }
    },
    [dispatch, tabId],
  )

  const onHandleDoubleClick = useCallback(() => {
    dispatch(buttonClick({ tabKey: tabId, button: ButtonTypes.edit }))
  }, [dispatch, tabId])

  const onHandleActionClick = useCallback(
    async (candidateData: Candidate, button: ButtonTypes) => {
      dispatch(setSelectedCandidate({ ...candidateData }))
      await utilidades.sleep(10)
      dispatch(buttonClick({ tabKey: tabId, button: button }))
    },
    [dispatch, tabId],
  )

  const actionSheetCommandsMobile = useCallback(
    ({ row: { data } }) => {
      return (
        <ActionSheetCommandsMobile
          key={crypto.randomUUID()}
          onEdit={() => onHandleActionClick(data, ButtonTypes.edit)}
          onDelete={() => onHandleActionClick(data, ButtonTypes.delete)}
        />
      )
    },
    [onHandleActionClick],
  )

  useEffect(() => {
    if (isSearch) {
      setCurrentView(SearchViews.Search)
      dispatch(setIsSearch(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearch])

  return (
    <>
      {isMobile && currentTab === 'BUSQUEDA' && (
        <>
          <SpeedDialAction
            icon="filter"
            label="Filtros"
            visible={true}
            index={1}
            onClick={() => {
              setCurrentView(SearchViews.Filter)
            }}
          />
          <SpeedDialAction
            icon="search"
            label="Búsqueda"
            visible={true}
            index={2}
            onClick={() => {
              setCurrentView(SearchViews.Search)
            }}
          />
        </>
      )}
      <BlockUi
        tag="div"
        loader={LoadingIndicator}
        blocking={loader.show}
        message={loader.mensaje}
      >
        {(currentView === SearchViews.Filter || !isMobile) && <SearchFilter />}
        {(currentView === SearchViews.Search || !isMobile) && (
          <RowContainer className="mt-2">
            <ItemsCountIndicator items={resultsSearch} />
            <DataGrid
              className="mb-2"
              ref={dataGrid}
              keyExpr="codigo"
              id="tabDetailsTransfer"
              selection={{ mode: 'single' }}
              dataSource={resultsSearch}
              showColumnLines={true}
              showRowLines={true}
              showBorders={true}
              columnResizingMode="widget"
              loadPanel={{ enabled: true }}
              allowColumnResizing={true}
              onCellDblClick={onHandleDoubleClick}
              onSelectionChanged={({ selectedRowsData }) =>
                onSelectedItemChanged(selectedRowsData)
              }
              remoteOperations={true}
            >
              <Paging defaultPageSize={20} />
              <Pager
                visible={resultsSearch.length > 0 ? true : false}
                allowedPageSizes={getAllowedPageSizes(resultsSearch)}
                displayMode={'full'}
                showPageSizeSelector={true}
                showInfo={true}
                showNavigationButtons={true}
              />
              <Export enabled={true} allowExportSelectedData={true} />
              {!isMobile && (
                <Column type="buttons" width="140px">
                  <DatagridButton
                    icon="edit"
                    hint="Editar"
                    onClick={({ row }) =>
                      onHandleActionClick(row.data, ButtonTypes.edit)
                    }
                  />
                  <DatagridButton
                    icon="trash"
                    hint="Eliminar"
                    onClick={({ row }) =>
                      onHandleActionClick(row.data, ButtonTypes.delete)
                    }
                  />
                </Column>
              )}
              {isMobile && (
                <Column
                  caption="Tareas"
                  cellRender={actionSheetCommandsMobile}
                  width="50px"
                  allowFiltering={false}
                />
              )}
              <Column
                dataField="apellidos"
                caption="Apellidos"
                width={isMobile ? '175px' : '20%'}
              />
              <Column
                dataField="nombres"
                caption="Nombres"
                width={isMobile ? '175px' : '20%'}
              />
              <Column
                dataField="identificacion"
                caption="Cédula"
                width={isMobile ? '90px' : '10%'}
              />
              <Column
                dataField="telefono"
                caption="Teléfono"
                width={isMobile ? '100px' : '10%'}
              />
              <Column
                dataField="email"
                caption="Email"
                width={isMobile ? '150px' : '12%'}
              />
              <Column
                dataField="fecha"
                caption="Fecha"
                width={isMobile ? '90px' : '8%'}
              />
              <Column
                dataField="rolDescripcion"
                caption="Aplica"
                width={isMobile ? '150px' : '10%'}
              />
              <Column
                dataField="ciudadNombre"
                caption="Lugar"
                width={isMobile ? '150px' : '10%'}
              />
            </DataGrid>
          </RowContainer>
        )}
      </BlockUi>
    </>
  )
}

export default memo(SearchCandidate)
