import React, { useCallback } from 'react'
import RowContainer from '../../../../../../../../views/componentes/rowContainer/rowContainer'
import { Button, CheckBox, DataGrid } from 'devextreme-react'
import TiposProveedoresLookUp from '../../../../../../../componentes/tiposProveedoresLookUp'
import { BuscarProveedorLookUp } from '../../../../../../../proveedores/components/busquedaProveedor/BuscarProveedorLookUp'
import CustomCol from '../../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../../views/componentes/labeledInput/labeledInput'
import { Column } from 'devextreme-react/data-grid'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../../store/store'
import { setDataSupplierToEdit } from '../../../../store/editDataReduce'
import { SupplierTable } from '../../../../types/types'
import { ToastTypes } from '../../../../../../../../store/types'
import { TOptionGenericoString } from '../../../../../../../clientes/pages/clientes/types/types'
import { ProveedorBusqueda } from '../../../../../../../proveedores/store/types'
import { useSetToast } from '../../../../../../../../hooks/useGlobalHooks'

const Proveedores = (props) => {
  const { tabId } = props

  const dispatch = useDispatch()
  const setToastMessage = useSetToast()

  const dataToEditSupplier = useSelector(
    (state: RootState) => state.inventarios.items.editData[tabId].proveedores,
  )

  const [selectedSupplier, setSelectedSupplier] =
    React.useState<SupplierTable>(null)

  const updateSuppliers = useCallback(
    (event: any, rowIndex: number) => {
      const dataGridProviders = structuredClone(
        dataToEditSupplier.tablaProveedores,
      )
      if (event.value === false)
        dataGridProviders[rowIndex].principal = event.value
      else {
        dataGridProviders.map((supplier: SupplierTable, index) => {
          if (index === rowIndex) dataGridProviders[index].principal = true
          else dataGridProviders[index].principal = false
        })
      }
      dispatch(
        setDataSupplierToEdit({
          key: tabId,
          supplier: {
            ...dataToEditSupplier,
            tablaProveedores: dataGridProviders,
          },
        }),
      )
    },
    [dataToEditSupplier, dispatch, tabId],
  )

  const renderCheckBox = React.useCallback(
    ({ data, rowIndex }) => {
      return (
        <CheckBox
          style={{ marginLeft: '14px' }}
          text=""
          value={data.principal}
          onValueChanged={(event) => {
            if (event.event) updateSuppliers(event, rowIndex)
          }}
        />
      )
    },
    [updateSuppliers],
  )

  const onRemoveSelectedSupplier = React.useCallback(() => {
    if (selectedSupplier?.proveedor > 0) {
      const suppliers: Array<SupplierTable> = structuredClone(
        dataToEditSupplier.tablaProveedores,
      )
      const refreshSuppliers = suppliers.filter(
        (supplier: SupplierTable) =>
          supplier.proveedor !== selectedSupplier.proveedor,
      )
      dispatch(
        setDataSupplierToEdit({
          key: tabId,
          supplier: {
            ...dataToEditSupplier,
            tablaProveedores: refreshSuppliers,
          },
        }),
      )
    } else
      setToastMessage(
        'Eliminar Proveedor',
        'Debe seleccionar un proveedor para eliminarlo',
        ToastTypes.Warning,
      )
  }, [dataToEditSupplier, dispatch, selectedSupplier, tabId, setToastMessage])

  const onSelectedSupplierChanged = React.useCallback(
    ({ selectedRowsData }) => {
      setSelectedSupplier(selectedRowsData[0])
    },
    [],
  )

  const addSupplerToTable = React.useCallback(() => {
    if (dataToEditSupplier.proveedor?.codigo > 0) {
      const indexNewSupplierToAdd =
        dataToEditSupplier.tablaProveedores.findIndex(
          (supplier: SupplierTable) => {
            return supplier.proveedor === dataToEditSupplier.proveedor?.codigo
          },
        )
      if (indexNewSupplierToAdd === -1) {
        const newSupplier: SupplierTable = {
          proveedor: dataToEditSupplier.proveedor.codigo,
          tipo: dataToEditSupplier.tipo.value,
          proveedorDescripcion: dataToEditSupplier.proveedor.nombre,
          principal: false,
        }
        const refreshDataTableSupplier: Array<SupplierTable> = structuredClone(
          dataToEditSupplier.tablaProveedores,
        )
        refreshDataTableSupplier.push(newSupplier)
        dispatch(
          setDataSupplierToEdit({
            key: tabId,
            supplier: {
              ...dataToEditSupplier,
              tablaProveedores: refreshDataTableSupplier,
            },
          }),
        )
      } else
        setToastMessage(
          'Agregar Proveedor',
          'El proveedor ya se encuentra agregado',
          ToastTypes.Warning,
        )
    } else
      setToastMessage(
        'Agregar Proveedor',
        'Debe elegir un proveedor para agregarlo',
        ToastTypes.Warning,
      )
  }, [dispatch, dataToEditSupplier, tabId, setToastMessage])

  const onChangedDataSupplier = React.useCallback(
    (field: string, value: TOptionGenericoString | ProveedorBusqueda) => {
      dispatch(
        setDataSupplierToEdit({
          key: tabId,
          supplier: {
            ...dataToEditSupplier,
            [field]: value,
          },
        }),
      )
    },
    [dispatch, dataToEditSupplier, tabId],
  )

  return (
    <RowContainer className="m-2">
      <RowContainer>
        <CustomCol xs="12" md="5">
          <Labeled label="Proveedor">
            <BuscarProveedorLookUp
              selected={dataToEditSupplier.proveedor}
              onChanged={(value) => onChangedDataSupplier('proveedor', value)}
              allowClear
              allowAdd
              allowEdit
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="5">
          <Labeled label="Tipo">
            <div
              style={{
                display: 'flex',
                gap: '2px',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <TiposProveedoresLookUp
                selected={dataToEditSupplier.tipo}
                onChanged={(value) => onChangedDataSupplier('tipo', value)}
                onChangedOptions={() => {}}
              />
              <Button
                id="btnAdd"
                className="me-1"
                icon="add"
                stylingMode="contained"
                type="default"
                onClick={() => addSupplerToTable()}
                disabled={dataToEditSupplier.proveedor === null}
                hint="Agregar proveedor seleccionado"
              />
              <Button
                id="btnRemove"
                icon="trash"
                stylingMode="contained"
                type="danger"
                onClick={() => onRemoveSelectedSupplier()}
                disabled={dataToEditSupplier.tablaProveedores.length === 0}
                hint="Eliminar proveedor seleccionado"
              />
            </div>
          </Labeled>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="8">
          <DataGrid
            className="m-2"
            selection={{ mode: 'single' }}
            hoverStateEnabled={true}
            dataSource={dataToEditSupplier.tablaProveedores}
            showBorders={true}
            showRowLines={true}
            onSelectionChanged={onSelectedSupplierChanged}
            loadPanel={{ enabled: true }}
          >
            <Column
              dataType="string"
              dataField="proveedorDescripcion"
              caption="Nombre"
              width="50%"
            />
            <Column
              dataType="string"
              dataField=""
              caption="Costo"
              width="25%"
            />
            <Column
              dataType="string"
              dataField="tipo"
              caption="Tipo"
              width="15%"
            />
            <Column
              dataType="string"
              dataField="principal"
              caption="Principal"
              width="10%"
              cellRender={renderCheckBox}
            />
          </DataGrid>
        </CustomCol>
      </RowContainer>
    </RowContainer>
  )
}

export default React.memo(Proveedores)

export const defaultProveedores = {
  tipo: { value: 'PROVEEDOR', label: 'PROVEEDOR' },
  proveedor: null,
  tablaProveedores: [],
}
