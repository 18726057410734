import { RequestHelper } from "../../../../../../../helpers/requestHelper"


export const WalletService = {
  seeWallet,
}


async function seeWallet(object): Promise<any> {
  const obj = {
    prvCodigo: object['codigo'],
    ciudadCodigo: object['ciudad'],
    locCodigo: object['local'],
    hasta: object['corte'],
    consolidado: object['consolidado'],
    tipoproveedor: object['tipoproveedor'],
    mayorACero: object['mayor'],
  }

  try {
    const data = await RequestHelper.getAll<any>('proveedores/documentosPorPagar', 'seeWallet', '', obj)
    return data
  } catch (error) {
    return error
  }
}