import React, { createContext, useState } from 'react'
import { Cliente } from '../../../../../../../types/types'
import { ItemPedido } from '../../../type/types'
import { BuscarItemPedidoLookUp } from './BuscarItemsPedidoSelectBox'
import {
  ETipoAsociado,
  ETypeItems,
} from '../../../../../../../../../store/types'
import { Movimiento } from '../../../../../../../../inventario/pages/movimientos/conceptosMovimientos/types/types'
import { LocalOption } from '../../../../../../../../componentes/localesLookUp/store/type'
import { EModulosAcatha } from '../../../../../../../../../store/enumsAplication'

export type ConfigFiltros = {
  jerarquia?: number
}

export const LoaderContext = createContext(null)

interface IBuscarItemPedidoMemoLookUp {
  selected: ItemPedido | null
  // eslint-disable-next-line no-unused-vars
  onChanged: (newValue: ItemPedido | null) => void
  fecha: string
  cliente: Cliente | null
  allowEdit?: boolean
  allowClear?: boolean
  allowAdd?: boolean
  disabled?: boolean
  onEnterKey?: () => void
  prioridadBarras?: boolean
  modulo: EModulosAcatha
  tipoAsociado: ETipoAsociado
  movimiento?: Movimiento
  allowMoreOption?: boolean
  configFiltros?: ConfigFiltros
  requestType?: ETypeItems
  codigoPrvAsociado?: number
  local?: LocalOption
  autoComplete?: boolean
}

const BuscarItemPedidoMemoLookUp: React.FC<IBuscarItemPedidoMemoLookUp> = (
  props,
) => {
  const {
    requestType,
    configFiltros,
    allowMoreOption,
    movimiento,
    modulo,
    tipoAsociado,
    prioridadBarras,
    onEnterKey,
    selected,
    onChanged,
    allowAdd,
    allowClear,
    allowEdit,
    disabled,
    cliente,
    fecha,
    codigoPrvAsociado,
    local,
    autoComplete,
  } = props

  const [loader, setLoader] = useState<boolean>(false)
  const [numberItemsFound, setNumberItemsFound] = useState<number>(-1)

  return (
    <LoaderContext.Provider
      value={{ loader, setLoader, numberItemsFound, setNumberItemsFound }}
    >
      <BuscarItemPedidoLookUp
        selected={selected}
        onChanged={onChanged}
        fecha={fecha}
        cliente={cliente}
        allowEdit={allowEdit}
        allowClear={allowClear}
        allowAdd={allowAdd}
        disabled={disabled}
        onEnterKey={onEnterKey}
        prioridadBarras={prioridadBarras}
        modulo={modulo}
        tipoAsociado={tipoAsociado}
        movimiento={movimiento}
        allowMoreOption={allowMoreOption}
        configFiltros={configFiltros}
        requestType={requestType}
        codigoPrvAsociado={codigoPrvAsociado}
        local={local}
        autoComplete={autoComplete}
      />
    </LoaderContext.Provider>
  )
}

export default React.memo(BuscarItemPedidoMemoLookUp)
