import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ConfigGrupos, GruposDatosEdicion } from "../types/types";



const initialState: ConfigGrupos = {
  modulo: 0
}

const configuracionGrupos = createSlice(
  {
    name: 'configGrupos',
    initialState: initialState,
    reducers: {
      setCodigoModulo(state, acion: PayloadAction<number>) {
        state.modulo = acion.payload;
      },
      setDatosEdicionBackup(
        state,
        action: PayloadAction<{
          key: string
          grupo: GruposDatosEdicion
        }>,
      ) {
        state[action.payload.key] = action.payload.grupo
      },
      deleteEditDataBackup(state, action: PayloadAction<string>) {
        delete state[action.payload]
      },
    }
  }
)


export const configuracionesGrupos = configuracionGrupos.reducer;
export const { setCodigoModulo, setDatosEdicionBackup, deleteEditDataBackup } = configuracionGrupos.actions;