import { combineReducers } from '@reduxjs/toolkit'
import { MovimientosState } from '../types/types'
import { ConceptosReducer } from '../conceptosMovimientos/store/conceptosReducer'
import { IngresosEgresosReducer } from '../ingresosEgresos/store/ingresosEgresosReducer'
import { TransferecniasReducer } from '../transferencias/store/transferenciasReducer'
import { ImportacionesReducer } from '../importaciones/store/importacionesReducer'
import { InventarioInicialReducer } from '../inventarioInicial/store/inventarioInicialReducer'
import { CombinacionesReducer } from '../combinaciones/store/combinacionesReducer'
import { TransformacionesReducer } from '../transformaciones/store/transformacionesReducer'
import { IngresoBloqueReducer } from '../ingresoPorBloque/store/ingresoBloqueReducer'

export const MovimientosReducer = combineReducers<MovimientosState>({
  conceptos: ConceptosReducer,
  ingresosEgresos: IngresosEgresosReducer,
  transferencias: TransferecniasReducer,
  importaciones: ImportacionesReducer,
  inventarioInicial: InventarioInicialReducer,
  combinaciones: CombinacionesReducer,
  transformaciones: TransformacionesReducer,
  ingresoBloque: IngresoBloqueReducer,
})
