import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FiltrosLineasState, BusquedaState } from '../types/types'
import { FETCH_STATUS, Seleccionado } from '../../../../../../store/types'
import { LineaInventario } from '../../../../store/types'
import { InventarioService } from '../../../../services/inventario.service'

const initialState: BusquedaState<LineaInventario> = {
  filter: {
    particular: null,
    descripcion: null,
    initial: true,
  },
  seleccionado: {
    index: null,
    row: null,
    selectedRow: null,
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  colapsado: true,
  resultados: [],
  nroItemsEncontrados: 0,
}

export const fetchLineas = createAsyncThunk<
  Array<LineaInventario>,
  FiltrosLineasState
>('inventarios/catalogos/lineas/listar', async (filtro) => {
  try {
    const linesList = InventarioService.getLineas(filtro)
    return linesList
  } catch (error) {
    return Promise.reject(error)
  }
})

const searchSlice = createSlice({
  name: 'buscarLineas',
  initialState: initialState,
  reducers: {
    setCleanResult(state) {
      state.resultados = []
    },
    setResultados(state, action: PayloadAction<Array<LineaInventario>>) {
      state.resultados = action.payload
    },
    setCollapsed(state, action: PayloadAction<boolean>) {
      state.colapsado = action.payload
    },
    changeFilter(state, action: PayloadAction<FiltrosLineasState>) {
      state.filter.particular = action.payload.particular
      state.filter.descripcion = action.payload.descripcion
      state.filter.initial = action.payload.initial
    },
    setResetSeleccion(state) {
      const toAdd = {
        index: null,
        row: null,
        selectedRow: null,
      }
      state.seleccionado = toAdd
    },
    setSeleccionarDato(state, action: PayloadAction<Seleccionado>) {
      const toAdd = {
        index: action.payload.index,
        row: action.payload.row,
        selectedRow: action.payload.selectedRow,
      }
      state.seleccionado = toAdd
    },
    setNroItemsEncontados(state, action: PayloadAction<number>) {
      state.nroItemsEncontrados = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLineas.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchLineas.fulfilled, (state, { payload }) => {
      state.resultados = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchLineas.rejected, (state, { payload }) => {
      state.resultados = []
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
  },
})

export const {
  changeFilter,
  setCleanResult,
  setResetSeleccion,
  setSeleccionarDato,
  setResultados,
  setCollapsed,
  setNroItemsEncontados,
} = searchSlice.actions

export const searchReducer = searchSlice.reducer
