import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FETCH_STATUS } from '../../../../../store/types'
import { KardexListResult, FilterLotesState, SearchState } from '../types/types'
import { KardexServices } from '../../kardex/services/kardex.services'
import { initialFilter } from '../components/buscar/filtroBusqueda';
import { lotsServices } from '../services/kardex.services';

const initialState: SearchState<KardexListResult> = {
  filter: { ...initialFilter },
  status: FETCH_STATUS.IDDLE,
  statusTransactions: FETCH_STATUS.IDDLE,
  mensaje: '',
  seleccionado: null,
  resultados: [],
  resultadosTransacciones: [],
  tabs: ['Lotes Agregados al Item', 'Transacciones'],
  currentTab: 'Lotes Agregados al Item',
  colapsed: true
}

export const fetchLots = createAsyncThunk<
  Array<KardexListResult>,
  FilterLotesState
>('inventarios/lotes/listar', async (filtro) => {
  try {
    const lotesList = KardexServices.getLotes(filtro.items?.codigo ?? 0, filtro.local?.codigo ?? -1)
    return lotesList
  } catch (error) {
    return Promise.reject(error)
  }
})

export const fetchLotsTransactions = createAsyncThunk<
  Array<KardexListResult>,
  number
>('inventarios/lotes/listarTransaccion', async (filtro) => {
  try {
    const lotesList = lotsServices.getLotesTransacciones(filtro)
    return lotesList
  } catch (error) {
    return Promise.reject(error)
  }
})

const searchSlice = createSlice({
  name: 'searchLotes',
  initialState: initialState,
  reducers: {
    setCleanResult(state) {
      state.resultados = []
      state.resultadosTransacciones = []
    },
    changeFilter(state, action: PayloadAction<FilterLotesState>) {
      state.filter = action.payload
    },
    setCollapsed(state, action: PayloadAction<boolean>) {
      state.colapsed = action.payload
    },
    changeCurrentTab(state, action: PayloadAction<string>) {
      state.currentTab = action.payload
    },
    setResetSeleccion(state) {
      state.seleccionado = null
    },
    setSeleccionarDato(state, action: PayloadAction<KardexListResult>) {
      state.seleccionado = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLots.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchLots.fulfilled, (state, { payload }) => {
      state.resultados = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchLots.rejected, (state, { payload }) => {
      state.resultados = []
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
    builder.addCase(fetchLotsTransactions.pending, (state) => {
      if (
        state.statusTransactions === FETCH_STATUS.IDDLE ||
        state.statusTransactions === FETCH_STATUS.SUCCESS
      ) {
        state.statusTransactions = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchLotsTransactions.fulfilled, (state, { payload }) => {
      state.resultadosTransacciones = payload
      state.statusTransactions = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchLotsTransactions.rejected, (state, { payload }) => {
      state.resultadosTransacciones = []
      state.statusTransactions = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
  },
})

export const { changeFilter, setCleanResult, changeCurrentTab, setSeleccionarDato, setResetSeleccion, setCollapsed } =
  searchSlice.actions

export const searchReducer = searchSlice.reducer
