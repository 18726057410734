import { CButton, CCard, CCardBody, CCardHeader, CCol, CRow, CTooltip, CAlert } from '@coreui/react-pro';
import { Popup, ScrollView } from 'devextreme-react'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LoadPanel from 'devextreme-react/load-panel';
//import Nuevo from './componentes/nuevo'
import Buscar from './components/buscar'
import { RootState } from '../../../../../store/store'
import Modalform from '../../../../../views/componentes/modalform'
import RowContainer from '../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../views/componentes/colContainer'
import { GlobalMenu } from '../../../../../views/componentes/globalMenu/globalMenu';
import config from '../../../../../config/config';
import { AccionMenu, ToastTypes } from '../../../../../store/types';
import { ButtonTypes } from '../../../../../views/componentes/globalMenu/types';
import { addToast } from '../../../../../store/toasterReducer';
import { changeLoader } from '../../../../../store/reducers';
import Nuevo from './components/nuevo';
import { InventarioService } from '../../../services/inventario.service';

interface IModalAdminLineasProps extends React.PropsWithChildren {
  onChanged: (dataProveedor: any) => void
  onCancel: () => void
  show: boolean
}

export const ModalAdminLineas: React.FC<IModalAdminLineasProps> = (
  props,
) => {
  const dispatch = useDispatch()
  const { show, onCancel, onChanged } = props
  const loader = useSelector((state: RootState) => state.global.loader);

  const botones = config['menuDefecto'].map(acc => {
    const boton: AccionMenu = {
      nombre: acc.id,
      icon: acc.icon,//as any,
      actionType: acc.type,
      main: false,
      modal: false,
      shortcut: null
    }
    return boton;
  });

  const [acciones,] = React.useState<Array<AccionMenu>>(botones);
  const [seleccion, setSeleccion] = React.useState<any>({
    index: null,
    row: null,
    selectedRow: null,
  });
  const [messageConfirm, setMessageConfirm] = React.useState("Está seguro que desea realizar estos cambios?");
  const [currentTab, setCurrentTab] = React.useState<any>("Buscar");
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [confirmUpdate, setConfirmUpdate] = React.useState(false);
  const [confirmChange, setConfirmChange] = React.useState<boolean>(false);
  const [deleteItem, setDeleteItem] = React.useState<any>(null);
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const [funcion, setFuncion] = React.useState<string>("");
  const resetSearched = React.useCallback(() => {
    // setDataSearch({})
    setSeleccion({
      index: null,
      row: null,
      selectedRow: null,
    })
  }, []);
  const setToast = (texto, type) => {
    let tipo: ToastTypes = ToastTypes.Info;
    if (type === 'warning') {
      tipo = ToastTypes.Warning;
    } else if (type === 'success') {
      tipo = ToastTypes.Success;
    } if (type === 'info') {
      tipo = ToastTypes.Info;
    } if (type === 'danger') {
      tipo = ToastTypes.Danger;
    }
    dispatch(addToast({
      id: '',
      autoHide: 3500,
      title: 'Acatha',
      content: texto,
      fade: true,
      type: tipo
    }));
  }
  const setActionUpdate = ({ row }) => {
    setMessageConfirm(`Está seguro que desea actualizar el item: ${row.particular}?`)
    setConfirmUpdate(true)
    setShowConfirm(true)
  }


  const setActionDelete = ({ row }) => {
    setShowConfirm(true)
    setConfirmDelete(true);
    setMessageConfirm(`Está seguro que desea eliminar el item: ${row.particular}?`)
  }


  const handleDelete = async () => {
    const { row: { codigo } } = seleccion
    playLoader()
    try {
      const data = await InventarioService.deleteLinea(codigo)
      setFuncion(ButtonTypes.delete)
      setDeleteItem(seleccion['row'])
      stopLoader()
    } catch (error) {
      console.log(error)
      stopLoader();
      setToast(JSON.stringify(error), ToastTypes.Danger);
    }
  }

  const playLoader = React.useCallback(async () => {
    dispatch(changeLoader({ show: true, mensaje: 'Cargando...' }))
  }, [dispatch]);

  const stopLoader = React.useCallback(async () => {
    dispatch(changeLoader({ show: false, mensaje: '' }))
  }, [dispatch]);

  const returnAction = (boton: AccionMenu) => {
    switch (boton.actionType) {
      case ButtonTypes.find:
        if (currentTab == "Nuevo") {
          resetSearched()
          setConfirmChange(true)
          setShowConfirm(true)
          setMessageConfirm("¿Esta seguro que desea salir de este formulario?")
          return false;
        } else if (currentTab === "Editar") {
          resetSearched()
          setConfirmChange(true)
          setShowConfirm(true)
          setMessageConfirm("¿Esta seguro que desea salir de este formulario?")
        } else {
          setCurrentTab("Buscar")
          setFuncion(ButtonTypes.find)
        }
        break;
      case ButtonTypes.new:
        setCurrentTab("Nuevo")
        setFuncion(ButtonTypes.new)
        break;
      case ButtonTypes.save:
        if (currentTab == "Nuevo") {
          setFuncion(ButtonTypes.save)
          return false;
        } else if (currentTab == "Editar") {
          setFuncion(ButtonTypes.save)
          return false;
        } else {
          setToast("No se encuentra cambios que realizar.", "info")
        }
        break;
      case ButtonTypes.undo:
        if (currentTab == "Nuevo") {
          setFuncion(ButtonTypes.undo)
          return false;
        } else if (currentTab == "Editar") {
          setFuncion(ButtonTypes.undo)
          return false;
        } else {
          resetSearched()
        }
        break;
      case ButtonTypes.edit:
        if (seleccion['index'] === null) {
          setToast("No se encuentra un registro seleccionado, Por favor seleccione un Item para editarlo.", "info")
          return false;
        } else {
          setActionUpdate(seleccion)
          // setCurrentTab("Editar")
          // setFuncion("Editar")
          return false;
        }
        break;
      case ButtonTypes.delete:
        if (seleccion.index !== null) {
          setActionDelete(seleccion);
          // handleDelete();
          return false;
        } else {
          setToast("No se encuentra seleccionado un proveedor, por favor seleccione un proveedor para eliminarlo.", "info")
          return false;
        }
        break;
      default:
        break;
    }
  }

  const getButtonDisabled = React.useCallback((button: AccionMenu) => {
    switch (button.actionType) {
      case ButtonTypes.find:
      case ButtonTypes.undo:
        return false;
      case ButtonTypes.new: {
        return currentTab !== "Buscar" || seleccion.index !== null;
      }
      case ButtonTypes.delete:
      case ButtonTypes.edit: {
        return (currentTab !== "Buscar" || seleccion.index === null);
      }
      case ButtonTypes.save: {
        return currentTab === "Buscar";
      }
      default:
        return true;
    }
  }, [currentTab, seleccion]);
  const botonesModal = () => {
    return (
      <>
        <RowContainer>
          <CustomCol xs='12' md='4'>
            <GlobalMenu
              acciones={acciones}
              onClick={returnAction}
              getButtonDisabled={getButtonDisabled}
            />
          </CustomCol>
        </RowContainer>
      </>
    );
  }

  //Body Modal
  const renderBodyConfirm = () => {
    return (
      <>
        {messageConfirm}
      </>
    )
  }



  const renderFooter = () => {
    if (currentTab === "Buscar") {
      return (
        <>
          {confirmDelete === true && (
            <CButton
              color="primary"
              onClick={() => {
                setShowConfirm(false);
                setConfirmDelete(false);
                handleDelete();
              }}
            >
              {"Aceptar"}
            </CButton>
          )}
          {confirmUpdate === true && (
            <CButton
              color="primary"
              onClick={() => {
                setConfirmUpdate(false);
                setShowConfirm(false);
                setFuncion(ButtonTypes.edit)
                setCurrentTab('Editar')
              }}
            >
              {"Aceptar"}
            </CButton>
          )}
          <CButton
            color="secondary"
            onClick={() => {
              setConfirmUpdate(false);
              setConfirmDelete(false);
              setShowConfirm(!showConfirm);
            }}
          >
            {"Cancelar"}
          </CButton>
        </>
      );
    } else if (currentTab === "Nuevo" || currentTab === "Editar") {
      return (
        <>
          {confirmChange === true && (
            <CButton color="primary" onClick={() => {
              setConfirmUpdate(false);
              setShowConfirm(false)
              setConfirmChange(false)
              setMessageConfirm("¿Esta seguro que desea salir de este formulario?")
              setCurrentTab('Buscar')
              // setFuncion(ButtonTypes.save)
            }}>
              {"Aceptar"}
            </CButton>
          )}
          <CButton color="secondary" onClick={() => {
            setConfirmUpdate(false);
            setConfirmDelete(false)
            setShowConfirm(!showConfirm)
          }} >{"Cancelar"}</CButton>
        </>
      )
    }
  };


  const confirmAction = () => {
    return (
      <Modalform
        name='confirmarEliminarItem'
        headerTitle={'Confirmar'}
        childrenBody={renderBodyConfirm()}
        childrenFooter={renderFooter()}
        closeOnBackdrop={false}
        show={showConfirm}
        onClose={() => setShowConfirm(!showConfirm)}
        centered={true}
        size="sm"
      />
    );
  };


  const bodyModal = () => {

    return (
      <>
        <RowContainer>
          <CustomCol xs='12' md='12'>

            {currentTab === "Nuevo" && (
              <Nuevo
                funcion={funcion}
                setCurrenTab={(tab) => setCurrentTab(tab)}
                currentTab={currentTab}
                resetFunction={() => setFuncion('')}
                onChanged={onChanged}
                setToast={(sms, type) => setToast(sms, type)}
                playLoader={playLoader}
                stopLoader={stopLoader}
              />
            )}

            {currentTab === "Editar" && (
              <Nuevo
                funcion={funcion}
                selection={seleccion}
                setCurrenTab={(tab) => setCurrentTab(tab)}
                currentTab={currentTab}
                resetFunction={() => setFuncion('')}
                resetSelected={() => resetSearched()}
                onChanged={onChanged}
                setToast={(sms, type) => setToast(sms, type)}
                playLoader={playLoader}
                stopLoader={stopLoader}

              />
            )}
            {currentTab === "Buscar" && (
              <Buscar
                funcion={funcion}
                resetFunction={() => setFuncion('')}
                onChanged={onChanged}
                onSelection={setSeleccion}
                deletedItem={deleteItem}
                currentTab={currentTab}
                setCurrenTab={(currentTab) => setCurrentTab(currentTab)}
                resetSelected={() => resetSearched()}
                setToast={setToast}
                playLoader={playLoader}
                stopLoader={stopLoader}

              />
            )}
          </CustomCol>
        </RowContainer>
      </>
    );
  }

  const renderContent = () => {
    return (
      <CCard>
        <CCardHeader>
          {botonesModal()}
        </CCardHeader>
        <CCardBody>

          <LoadPanel
            shadingColor="rgba(0,0,0,0.4)"
            position={'center'}
            visible={loader.show}
            showIndicator={true}
            shading={true}
            showPane={true}
            message={loader.mensaje}
            closeOnOutsideClick={false}
          />

          {bodyModal()}
        </CCardBody>
      </CCard>
    )
  }

  return (
    <>
      {showConfirm === true && confirmAction()}
      <Modalform
        name='Lineas'
        headerTitle={'Lista de Lineas Activas'}
        childrenBody={renderContent()}
        closeOnBackdrop={false}
        show={show}
        onClose={() => onCancel()}
        centered={true}
        size="lg"
      />
    </>

  )
}

