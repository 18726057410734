import React from 'react'
import Modalform from '../../../views/componentes/modalform'
import { CCard, CCardBody, CCardHeader } from '@coreui/react-pro'
import { LoadPanel, NumberBox, TextBox, ValidationGroup, ValidationSummary, Validator } from 'devextreme-react'
import { GlobalMenu } from '../../../views/componentes/globalMenu/globalMenu'
import { AccionMenu, ToastTypes } from '../../../store/types'
import { ButtonTypes } from '../../../views/componentes/globalMenu/types'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import RowContainer from '../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../views/componentes/colContainer'
import Labeled from '../../../views/componentes/labeledInput/labeledInput'
import { ItemsServices } from '../../inventario/pages/items/services/items.services'
import { addToast } from '../../../store/toasterReducer'
import { MessagesKeys, lh } from '../../../helpers/localizationHelper'
import { RequiredRule } from 'devextreme-react/validator'
import validations from '../../../config/validations'
import VisualizaError from '../../ventas/pages/shared/visualizaError/visualizaError'
import ListErrosValidationForm from '../listadoErroresValidacionForm'
import { changeLoader } from '../../../store/reducers'
import { incomeExpensesServices } from '../../inventario/pages/movimientos/ingresosEgresos/services/ingresosEgresos.services'


interface IModalModificarPrecioItem extends React.PropsWithChildren {
  onChanged: (dataLocal: any) => void
  onCancel: () => void
  show: boolean
  codigoItem: number
}
const menuDefecto = [
  {
    icon: 'fal fa-save',
    id: 'Guardar',
    text: 'Guardar',
    type: ButtonTypes.save,
  },
]


export const ModalModificarPrecioItem: React.FC<IModalModificarPrecioItem> = (props) => {

  const { onChanged, onCancel, show, codigoItem } = props
  const dispatch = useDispatch()
  const [codigoInt, setCodigoInt] = React.useState<number>(0)
  const [codigo, setCodigo] = React.useState<string>(null)
  const [barras, setBarras] = React.useState<string>(null)
  const [nombre, setNombre] = React.useState<string>(null)
  const [nombreGenerico, setNombreGenerico] = React.useState<string>(null)
  const [costo, setCosto] = React.useState<number>(null)
  const [unidadPVD, setUnidadPVD] = React.useState<number>(null)
  const [pvdSinIVA, setPvdSinIVA] = React.useState<number>(null)
  const [utilidadPVP, setUtilidadPVP] = React.useState<number>(null)
  const [pvpSinIVA, setPvpSinIVA] = React.useState<number>(null)
  const [arrayErrors, setArrayErrors] = React.useState<Array<string>>([])
  const [showErrorPopup, setShowErrorPopup] = React.useState<boolean>(false)

  const validationFormRef = React.useRef<any>()
  const sms = validations['es']

  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(
      addToast({
        title: 'Modificar Precio Item',
        content: mensaje,
        type: tipo,
      }),
    )
  }, [dispatch])


  const playLoader = React.useCallback(() => {
    dispatch(changeLoader({ show: true, mensaje: 'Cargando...' }))
  }, [dispatch])

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoader({ show: false, mensaje: '' }))
  }, [dispatch])

  const botones = menuDefecto.map(acc => {
    const boton: AccionMenu = {
      nombre: acc.id,
      icon: acc.icon,//as any,
      actionType: acc.type,
      main: false,
      modal: false,
      shortcut: null
    }
    return boton;
  });

  const [acciones,] = React.useState<Array<AccionMenu>>(botones);
  const loader = useSelector((state: RootState) => state.global.loader);

  const onChangeValue = React.useCallback((data, key: string) => {
    switch (key) {
      case 'costo':
        setCosto(data?.value)
        break;
      case 'unidadPVD':
        setUnidadPVD(data?.value)
        break;
      case 'pvdSinIVA':
        setPvdSinIVA(data?.value)
        break;
      case 'utilidadPVP':
        setUtilidadPVP(data?.value)
        break;
      case 'pvpSinIVA':
        setPvpSinIVA(data?.value)
        break;

      default:
        break;
    }
  }, [])


  const onLoadItem = React.useCallback(async (codigo: number) => {
    try {
      playLoader()
      const res = await ItemsServices.getItemDetails(codigo)
      stopLoader()
      console.log(res)
      if (res !== null && res !== undefined && res?.codigo) {
        setCodigoInt(res?.codigo)
        setCodigo(res?.codigoint ?? '')
        setBarras(res?.barras ?? '')
        setNombre(res?.descripcion ?? '')
        setNombreGenerico(res?.generico ?? '')
        setCosto(res?.costoProduccion ?? 0)
        setUnidadPVD(res?.utlidadPvd ?? 0)
        setPvdSinIVA(res?.pvd ?? 0)
        setUtilidadPVP(res?.utilidadPvp ?? 0)
        setPvpSinIVA(res?.pvp ?? 0)
      }
    } catch (error) {
      stopLoader()
      setToast(error.message, ToastTypes.Danger)
    }
  }, [setToast, playLoader, stopLoader])

  const onValidate = React.useCallback(() => {
    const errores: Array<string> = []
    if (pvdSinIVA > pvpSinIVA) {
      errores.push('PVD no puede ser superior a PVP')
    }
    return errores
  }, [pvdSinIVA, pvpSinIVA])

  const onSaveData = React.useCallback(async () => {
    const result = validationFormRef.current.instance.validate()
    let errores: Array<string> = []

    errores = onValidate()
    if (result.isValid) {
      if (errores.length > 0) {
        setArrayErrors(errores)
        setShowErrorPopup(true)
      } else {

        const data_save = {
          codigo: codigoInt ?? 0,
          utilidadPVD: unidadPVD ?? 0,
          pvd: pvdSinIVA ?? 0,
          utilidadPVP: utilidadPVP ?? 0,
          pvp: pvpSinIVA ?? 0,
          costo: costo ?? 0,
        }

        try {
          playLoader()
          const data = await incomeExpensesServices.updateItemPrice(data_save)
          stopLoader()
          if (data !== null && data !== undefined && data['error'] === false) {
            setToast(data['message'], ToastTypes.Success)
            if (data['auto']) {
              onChanged(data['auto'])
              onCancel()
            }
          } else {
            setToast(data['message'], ToastTypes.Danger)
          }
        } catch (error) {
          stopLoader()
          setToast(error?.message, ToastTypes.Danger)
        }
      }
    } else {
      setToast(lh.getMessage(MessagesKeys.GlobalErrorSave), ToastTypes.Warning)
    }

  }, [codigoInt, unidadPVD, pvdSinIVA, utilidadPVP, pvpSinIVA, costo, setToast, onValidate, playLoader, stopLoader, onCancel, onChanged])

  const returnAction = (boton: AccionMenu) => {

    switch (boton.actionType) {
      case ButtonTypes.save:
        onSaveData()
        break
      default:
        break;
    }
  }


  const getButtonDisabled = React.useCallback((button: AccionMenu) => {
    switch (button.actionType) {
      case ButtonTypes.save:
        return codigoInt === 0

      default:
        return false;
    }
  }, [codigoInt])

  React.useEffect(() => {
    if (codigoItem !== 0) onLoadItem(codigoItem)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codigoItem])


  const botonesModal = () => {
    return (

      <GlobalMenu
        acciones={acciones}
        onClick={returnAction}
        getButtonDisabled={getButtonDisabled}
      />

    );
  }


  const bodyModal = () => {

    return (
      <>
        <RowContainer>
          <CustomCol xs="12" md="6">
            <Labeled label="Codigo:">
              <TextBox
                value={codigo ?? ''}
                readOnly
              // onValueChanged={(data) => console.log(data)}
              />
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="6">
            <Labeled label="Barras:">
              <TextBox
                value={barras ?? ''}
                readOnly
              // onValueChanged={(data) => console.log(data)}
              />
            </Labeled>
          </CustomCol>
        </RowContainer>
        <RowContainer>
          <CustomCol xs="12" md="6">
            <Labeled label="Nombre:">
              <TextBox
                value={nombre ?? ''}
                readOnly
              // onValueChanged={(data) => console.log(data)}
              />
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="6">
            <Labeled label="Nombre Genérico:">
              <TextBox
                value={nombreGenerico ?? ''}
                readOnly
              // onValueChanged={(data) => console.log(data)}
              />
            </Labeled>
          </CustomCol>
        </RowContainer>

        {/*  */}
        <ValidationGroup id={'formIngEg'} ref={validationFormRef}>
          <RowContainer>
            <CustomCol xs="12" md="6">
              <Labeled label="Costo:">
                <NumberBox
                  format="#,##0.00"
                  value={costo ?? null}
                  onValueChanged={(data) => onChangeValue(data, 'costo')}
                >
                  <Validator>
                    <RequiredRule message={'Precio: ' + sms['required']} />
                  </Validator>
                </NumberBox>
              </Labeled>
            </CustomCol>
            <CustomCol xs="12" md="6">
              <Labeled label="% Utilidad P.V.D.:">
                <NumberBox
                  format="#,##0.00"
                  value={unidadPVD ?? null}
                  onValueChanged={(data) => onChangeValue(data, 'unidadPVD')}
                />
              </Labeled>
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol xs="12" md="6">
              <Labeled label="P.V.D. sin I.V.A.:">
                <NumberBox
                  format="#,##0.00"
                  value={pvdSinIVA ?? null}
                  onValueChanged={(data) => onChangeValue(data, 'pvdSinIVA')}
                />
              </Labeled>
            </CustomCol>
            <CustomCol xs="12" md="6">
              <Labeled label="% Utilidad P.V.P.:">
                <NumberBox
                  format="#,##0.00"
                  value={utilidadPVP ?? null}
                  onValueChanged={(data) => onChangeValue(data, 'utilidadPVP')}
                />
              </Labeled>
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol xs="12" md="6">
              <Labeled label="P.V.P. sin I.V.A.:">
                <NumberBox
                  format="#,##0.00"
                  value={pvpSinIVA ?? null}
                  onValueChanged={(data) => onChangeValue(data, 'pvpSinIVA')}
                />
              </Labeled>
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol lg="6" md="8" sm="12">
              <ValidationSummary id="summary"></ValidationSummary>
            </CustomCol>
          </RowContainer>
        </ValidationGroup>
      </>
    )
  }


  const renderContent = () => {
    return (
      <CCard>
        <CCardHeader>
          {botonesModal()}
        </CCardHeader>
        <CCardBody>
          <LoadPanel
            shadingColor="rgba(0,0,0,0.4)"
            position={'center'}
            visible={loader.show}
            showIndicator={true}
            shading={true}
            showPane={true}
            message={loader.mensaje}
            closeOnOutsideClick={false}
          />
          {bodyModal()}
        </CCardBody>
      </CCard>
    )
  }


  return (
    <>
      {
        showErrorPopup && (<VisualizaError
          titulo="Error en Modificar Precio Item"
          mensaje={'Error antes de Guardar'}
          content={
            <ListErrosValidationForm
              mainMessage={'Revise los siguientes errores:'}
              errorsList={arrayErrors}
              color={'danger'}
            />
          }
          onOk={() => setShowErrorPopup(false)}
        />)
      }
      <Modalform
        name={'modificarPrecioItem'}
        key={'modificarPrecioItem'}
        headerTitle={"Modificar Precio de Item"}
        childrenBody={renderContent()}
        closeOnBackdrop={false}
        show={show}
        onClose={() => onCancel()}
        centered={true}
        size='lg'
      />
    </>
  )
}
