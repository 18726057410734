import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  FiltrosGemasState,
  BusquedaState,
  AplicacionInventario,
} from '../types/types'
import { FETCH_STATUS, Seleccionado } from '../../../../../../store/types'
import { GemsServices } from '../services/gemas.services'


const optionsGems = [
  { value: 1, label: 'Engaste' },
  { value: 2, label: 'Corte' },
  { value: 3, label: 'Posición' },
]

const initialState: BusquedaState<AplicacionInventario> = {
  filter: {
    descripcion: '',
    estado: optionsGems[0],
  },
  status: FETCH_STATUS.IDDLE,
  seleccionado: {
    index: null,
    row: null,
    selectedRow: null,
  },
  mensaje: '',
  resultados: [],
}

export const fetcGemas = createAsyncThunk<
  Array<AplicacionInventario>,
  any
>('inventarios/catalogos/gemas/listar', async (filtro) => {
  try {
    const gemsList = await GemsServices.getGems(filtro)
    return gemsList
  } catch (error) {
    return Promise.reject(error)
  }
})

const searchSlice = createSlice({
  name: 'buscarAplicaciones',
  initialState: initialState,
  reducers: {
    setCleanResult(state) {
      state.resultados = []
      state.filter.descripcion = ''
      state.filter.estado = optionsGems[0]
    },
    changeFilter(state, action: PayloadAction<FiltrosGemasState>) {
      state.filter.descripcion = action.payload.descripcion
      state.filter.estado = action.payload.estado
    },
    setResetSeleccion(state) {
      const toAdd = {
        index: null,
        row: null,
        selectedRow: null,
      }
      state.seleccionado = toAdd
    },
    setSeleccionarDato(state, action: PayloadAction<Seleccionado>) {
      const toAdd = {
        index: action.payload.index,
        row: action.payload.row,
        selectedRow: action.payload.selectedRow,
      }
      state.seleccionado = toAdd
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetcGemas.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetcGemas.fulfilled, (state, { payload }) => {
      state.resultados = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetcGemas.rejected, (state, { payload }) => {
      state.resultados = []
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
  },
})

export const { changeFilter, setCleanResult, setSeleccionarDato } = searchSlice.actions

export const searchReducer = searchSlice.reducer
