import { DateUtils, formatoFechasApi } from "../../../../helpers/dateUtils";
import { RequestHelper } from "../../../../helpers/requestHelper";
import { utilidades } from "../../../../helpers/utilidades";
import { SesionService } from "../../../../services/sesion.service";

export const tiposLocalesServices = { getTipos };

async function getTipos(tipo: any, mensaje: string): Promise<any> {
  try {
    const sesion = SesionService.getCurrentSesion()
    const data = {
      tipoCodigo: tipo,
      mensaje: mensaje,
      paisCodigo: sesion?.local?.ciudad?.paisCodigo ?? 1,
    }
    const keysToCache: any = {
      ...data,
      dateKey: DateUtils.getCurrentDateAsString(formatoFechasApi),
      empresa: sesion?.empresa?.codigo,
    }
    const cacheKey = 'tiposEstablecimientos' + JSON.stringify(keysToCache);
    const cached = sessionStorage.getItem(cacheKey);
    if (cached) {
      return { error: false, auto: JSON.parse(cached) };
    } else {
      const res = await RequestHelper.getAll<any>('subtypes/cboxLoad', '', "", data);
      let opt = []
      if (res.auto.length > 0) {
        opt = res.auto.map((x) => {
          return {
            codigo: Number(x?.codigo),
            valor: utilidades.capText(String(x?.valor)) ?? '',
          }
        })
      }
      res.auto = opt
      sessionStorage.setItem(cacheKey, JSON.stringify(opt));
      return res;
    }
  } catch (error) {
    return error;
  }
}

