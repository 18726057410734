import {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { CTooltip } from '@coreui/react-pro'
import { SelectBox } from 'devextreme-react/select-box'
import { ItemPedido } from '../../../type/types'
import { Cliente } from '../../../../../../../types/types'
import {
  OrdenPedidoService,
  parseApiItemsLocal,
} from '../../../services/ordenPedido.services'
import RowContainer from '../../../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../../../views/componentes/colContainer'
import Button from 'devextreme-react/button'
import { Lookup, DropDownOptions } from 'devextreme-react/lookup'
import { v4 as uuidv4 } from 'uuid'
import {
  ETipoAsociado,
  ETypeItems,
  ToastTypes,
} from '../../../../../../../../../store/types'
import { Movimiento } from '../../../../../../../../inventario/pages/movimientos/conceptosMovimientos/types/types'
import MainModalItems from '../../../../../../../components/items/busquedaItemsModal/MainModalItems'
import config from '../../../../../../../../../config/config'
import { ConfigFiltros, LoaderContext } from './BuscarItemPedidoMemoLookUp'
import { LocalOption } from '../../../../../../../../componentes/localesLookUp/store/type'
import { isMobile } from 'react-device-detect'
import { EModulosAcatha } from '../../../../../../../../../store/enumsAplication'
import { Autocomplete } from 'devextreme-react/autocomplete'
import { useSetToast } from '../../../../../../../../../hooks/useGlobalHooks'

interface IBuscarItemsLookUpProps extends PropsWithChildren {
  selected: ItemPedido | null
  // eslint-disable-next-line no-unused-vars
  onChanged: (newValue: ItemPedido | null) => void
  fecha: string
  cliente: Cliente | null
  allowEdit?: boolean
  allowClear?: boolean
  allowAdd?: boolean
  allowMoreOption?: boolean
  disabled?: boolean
  onEnterKey?: () => void
  id?: string
  prioridadBarras?: boolean
  modulo: EModulosAcatha
  tipoAsociado: ETipoAsociado
  movimiento?: Movimiento
  configFiltros?: ConfigFiltros
  requestType?: ETypeItems
  codigoPrvAsociado?: number
  local?: LocalOption
  autoComplete?: boolean
}

export const BuscarItemPedidoLookUp: FC<IBuscarItemsLookUpProps> = (props) => {
  const {
    requestType,
    configFiltros,
    movimiento,
    tipoAsociado,
    modulo,
    prioridadBarras,
    id,
    onEnterKey,
    selected,
    onChanged,
    allowAdd,
    allowClear,
    allowEdit,
    disabled,
    cliente,
    fecha,
    allowMoreOption,
    codigoPrvAsociado,
    local,
    autoComplete,
  } = props

  const [itemEditando, setItemEditando] = useState<ItemPedido | null>(null)
  const [action, setAction] = useState<'update' | 'create' | 'read' | 'none'>(
    'none',
  )
  const [, setSelectedInternal] = useState<string>()
  const [moreOptions, setMoreOptions] = useState<boolean>(false)
  const [buscar, setBuscar] = useState<string>('')
  const selectRef = useRef<any>()
  const setToastMessage = useSetToast()
  const { loader, numberItemsFound, setNumberItemsFound } =
    useContext(LoaderContext)
  const { getItemsDatasource } = OrdenPedidoService.useGetItemsDatasource()

  const datasource = useMemo(() => {
    return getItemsDatasource(
      cliente,
      fecha,
      prioridadBarras,
      tipoAsociado,
      modulo,
      movimiento,
      codigoPrvAsociado,
      local,
      autoComplete,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prioridadBarras])

  const onLookUpValueChanged = useCallback(
    ({ value }: any) => {
      if (typeof value == 'string') return
      onChanged(value)
    },
    [onChanged],
  )

  const onAutocompleteInputChanged = useCallback((data: any) => {
    if (data.event && (typeof data.value === 'string' || data.value === null))
      setBuscar(data.value ? data.value : '')
  }, [])

  const getItemDisplayExpr = useCallback((item: ItemPedido) => {
    return item ? `${item.codigoBarras} - ${item.descripcion}` : ''
  }, [])

  useEffect(() => {
    setSelectedInternal(selected ? getItemDisplayExpr(selected) : undefined)
  }, [setSelectedInternal, getItemDisplayExpr, selected])

  const onSelectBoxFocusIn = useCallback((e) => {
    e.element.querySelector('input.dx-texteditor-input').select()
  }, [])

  const onMoreOptions = useCallback(async () => {
    setMoreOptions(true)
  }, [])

  const onParseItems = useCallback(
    (items) => {
      let item = null
      switch (modulo) {
        case EModulosAcatha.Kardex:
          item = { ...items }
          break
        case EModulosAcatha.Pedidos:
          item = parseApiItemsLocal([items])[0]
          break
        case EModulosAcatha.Proformas:
          item = parseApiItemsLocal([items])[0]
          break
        case EModulosAcatha.MovimientosIngresosEgresos:
        case EModulosAcatha.MovimientosImportaciones:
        case EModulosAcatha.MovimientosInventarioInicial:
          item = { ...items }
          break
        case EModulosAcatha.Transferencias:
          item = { ...items }
          break
        case EModulosAcatha.IngresoBloque:
          item = { ...items }
          break
        default:
          break
      }

      onChanged(item)
      setMoreOptions(false)
    },
    [modulo, onChanged],
  )

  useEffect(() => {
    if (numberItemsFound === 0 && autoComplete) {
      setNumberItemsFound(-1)
      setToastMessage(
        'Buscar Ítem',
        `No se encuentra el ítem con: <strong>${
          prioridadBarras ? 'código' : 'descripción'
        }: ${buscar}</strong>`,
        ToastTypes.Warning,
      )
      if (prioridadBarras) setBuscar('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberItemsFound])

  const renderAutocomple = (data: ItemPedido) => {
    return (
      <>
        <span>{data.descripcion}</span>
        <br />
        <small className="buquedaDetailsItem">
          Código: {data.codigoBarras}
        </small>
        <small className="buquedaDetailsItem existenciaCero">
          Existencia: {data.existencia}
        </small>
      </>
    )
  }

  if (autoComplete) {
    return (
      <>
        <Autocomplete
          dataSource={datasource}
          value={buscar}
          onValueChanged={onAutocompleteInputChanged}
          placeholder="Buscar..."
          valueExpr={getItemDisplayExpr(selected)}
          itemRender={renderAutocomple}
          searchTimeout={500}
          minSearchLength={3}
          searchEnabled
          onSelectionChanged={({ selectedItem }) => {
            if (selectedItem) onChanged(selectedItem)
          }}
          showClearButton={true}
        />
        {loader && <span>Buscando Ítem...</span>}
      </>
    )
  }

  if (allowAdd || allowEdit || allowClear || allowMoreOption) {
    return (
      <>
        {moreOptions && (
          <MainModalItems
            transaccion="ventas"
            show={moreOptions}
            cliente={config.rucConsumidorFinal}
            onCancel={() => {
              setMoreOptions(false)
            }}
            returnItem={(e) => {
              onParseItems(e)
            }}
            modulo={modulo ?? EModulosAcatha.Items}
            action={action}
            itemEditando={itemEditando}
            configFiltros={configFiltros}
            requestType={requestType}
            movimiento={movimiento}
            codigoPrvAsociado={codigoPrvAsociado}
            local={local}
          />
        )}
        <RowContainer gutters={false}>
          <CustomCol xs="12" md="9" style={{ width: '100%' }} className="pe-0">
            <SelectBox
              key={id ?? uuidv4()}
              dataSource={datasource}
              showDataBeforeSearch
              searchEnabled
              displayExpr={getItemDisplayExpr}
              searchTimeout={500}
              value={selected}
              onValueChanged={onLookUpValueChanged}
              onFocusIn={onSelectBoxFocusIn}
              disabled={disabled}
              minSearchLength={1}
              ref={selectRef}
              width={'100%'}
            >
              {props.children}
            </SelectBox>
          </CustomCol>
          <CustomCol
            md="3"
            xs="12"
            className={
              isMobile
                ? 'd-flex justify-content-end m-2 p-0'
                : 'd-flex justify-content-start m-0 p-0'
            }
          >
            {allowEdit && selected && (
              <CTooltip content="Editar Item">
                <div>
                  <Button
                    className="ms-1"
                    stylingMode="contained"
                    type="normal"
                    icon="edit"
                    onClick={() => {
                      setAction('update')
                      setItemEditando(selected)
                      setMoreOptions(true)
                    }}
                  ></Button>
                </div>
              </CTooltip>
            )}
            {!disabled && allowAdd && (
              <CTooltip content="Crear Item">
                <div>
                  <Button
                    className="ms-1"
                    stylingMode="contained"
                    type="default"
                    icon="plus"
                    onClick={() => {
                      setAction('create')
                      setItemEditando(null)
                      setMoreOptions(true)
                    }}
                  ></Button>
                </div>
              </CTooltip>
            )}
            {!disabled && allowClear && selected && (
              <CTooltip content="Quitar item seleccionado">
                <div>
                  <Button
                    className="ms-1"
                    stylingMode="contained"
                    type="danger"
                    icon="undo"
                    onClick={() => {
                      onChanged(null)
                    }}
                  ></Button>
                </div>
              </CTooltip>
            )}
            {allowMoreOption && (
              <CTooltip content="Más Opciónes">
                <div>
                  <Button
                    className="ms-1"
                    stylingMode="contained"
                    type="default"
                    icon="more"
                    onClick={() => {
                      onMoreOptions()
                    }}
                  ></Button>
                </div>
              </CTooltip>
            )}
          </CustomCol>
        </RowContainer>
      </>
    )
  }

  return (
    <Lookup
      key={id ?? uuidv4()}
      dataSource={datasource}
      showDataBeforeSearch
      value={selected}
      minSearchLength={3}
      searchTimeout={500}
      displayExpr={getItemDisplayExpr}
      searchExpr={['descripcion']}
      pageLoadMode="scrollBottom"
      showClearButton={true}
      onValueChanged={onLookUpValueChanged}
      disabled={disabled}
      onEnterKey={onEnterKey}
      width={'100%'}
    >
      <DropDownOptions tabIndex={3000} showTitle={false} />
      {props.children}
    </Lookup>
  )
}
