import { RequestHelper } from "../../../../../../../helpers/requestHelper"



export const VerAbonosService = {
  getAbonos,
  deleteAbonos
}


async function getAbonos(
  codigo: number,
  numero: string,
  desde: string,
  hasta: string,
  vendedorCodigo: number,
  formaPago: number,
  puntoVenta: number,
  ingreso: string,
  cheque: string,
  estado: number
): Promise<Promise<any>> {
  const query: any = {
    codigo,
    numero,
    desde,
    hasta,
    vendedorCodigo,
    formaPago,
    puntoVenta,
    ingreso,
    cheque,
    estado,
    venta: 0
  }


  try {
    const dataPendientes = await RequestHelper.getAll<any>(
      'generales',
      'seePayments',
      '',
      query,
    )
    return dataPendientes
  } catch (error) {
    console.log(error)
    return error
  }
}


async function deleteAbonos(
  codigo: number,
  clienteCodigo: number
): Promise<Promise<any>> {
  const query: any = {
    codigo,
    clienteCodigo
  }


  try {
    const deleteApi = await RequestHelper.deleteRequestAll<any>(
      'clientes/DocCuentasPorCobrar',
      'paymentsSales/delete',
      '',
      query,
    )
    return deleteApi
  } catch (error) {
    console.log(error)
    return error
  }
}