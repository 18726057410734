/* eslint-disable no-unused-vars */

export enum KeyLocLook {
  cod = 'localCodigo',
  cityCod = 'localCiudad',
  cityName = 'localCiudadNombre',
  name = 'localNombre',
  address = 'localDireccion',
  numberPhone = 'localTelefono',
  ger = 'localGerente',
  typeCod = 'localTipo',
  typeDesc = 'localTipoDescripcion',
  class = 'localClase',
  classDes = 'localClaseDescripcion',
  owner = 'localPertenece',
  ownerDes = 'localPerteneceDescripcion',
  stateCod = 'localEstado',
  stateDes = 'localEstadoDescripcion',
  kardex = 'localKardex',
  acronym = 'localSiglas',
  acronymLoc = 'localSiglasLocal',
  web = 'localWEB',
  uaf = 'localUAF',
  descount = 'localDescuento',
  contract = 'localContrato',
  observations = 'localObservaciones',
  userDokan = 'localUsuarioDokan',
  passDokan = 'localPasswordDokan',
  uloTipo = 'ULOTipo',
  logo = 'localLogo',
  pricelist = 'localListaPrecios',
  establishment = 'localEstablecimiento',
}
