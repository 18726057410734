import {PaginationInfo} from "../../../../../store/types";
import {FiltroBusquedaCiudadesState, CiudadesListado} from "../store/types";
import {RequestHelper} from "../../../../../helpers/requestHelper";

export const CiudadesService = {
  getAllCiudades,
  getCiudad,
  saveCiudad,
  deleteCiudad,
}

async function getAllCiudades(filtro: FiltroBusquedaCiudadesState): Promise<any> {
  try {
    const query = buildQuery(filtro);
    const pagedResponse = await RequestHelper.getAll<any>('generales/city', 'getAll', '', query);
    return pagedResponse.auto? parseProvincias(pagedResponse.auto) : [];
  } catch (error) {
    return error;
  }
}

async function getCiudad(id: any): Promise<any> {
  try {
    const query = {
      codigo: id,
    };
    return await RequestHelper.get<any>('city', 'getCity', '', query);
  } catch (error) {
    return error;
  }
}

async function saveCiudad(formData: any): Promise<any> {
  try {
    const body = {
      infoRegistro: {
        ...formData,
      }
    };

    return await RequestHelper.postAll<any>('generales/cities', 'save', body);
  } catch (error) {
    return error;
  }
}

async function deleteCiudad(id: any): Promise<void> {
  try {
    const query = {
      codigo: id
    }

    return await RequestHelper.deleteRequestAll<any>(
      'generales/cities',
      'inactive',
      '',
      query
    );
  } catch (error) {
    return error;
  }
}

// Private
function buildQuery(
  filtro: FiltroBusquedaCiudadesState,
  pagination?: PaginationInfo,
): any {

  const query: any = {
    ...(filtro.codigo && { codigo: filtro.codigo }),
    ...(filtro.nombre && { ciudad: filtro.nombre }),
    ...(filtro.provinciaCodigo && (parseInt(filtro.provinciaCodigo) > 0) && { codigoProvincia: filtro.provinciaCodigo }),
  }

  if (pagination) {
    query.page = pagination.current
    query.limit = pagination.size
    query.isPaged = true
  }

  const filters = ['codigo', 'nombre', 'provinciaCodigo']

  filters.forEach((field) => {
    if (filtro[field] && filtro[field].length > 0) {
      query[field] = filtro[field]
    }
  })

  return query
}

function parseProvincias(dataApi: any[]): Array<CiudadesListado> {
  return dataApi.map((item: any) => ({
    codigo: item.codigo,
    origen: item.origen,
    nombre: item.nombre,
    provinciaCodigo: item.provinciaCodigo,
    dep: item.codigoRDepOrigen,
    inec: item.inec,
    uaf: item.uaf,
    codigoOrigen: item.codigoOrigen,
  }))
}
