import React from 'react'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import { DataGrid } from 'devextreme-react'
import { Column, ColumnChooser, Export, Pager, Paging } from 'devextreme-react/data-grid'
import { getAllowedPageSizes } from '../../../../../../../helpers/Helper'
import ItemsCountIndicator from '../../../../../../componentes/indicadorNroRegistros'

const Detalle = () => {
  const resultsSerach = useSelector((state: RootState) => {
    return state.inventarios.movimientos.importaciones.search.resultados
  })

  const dataGrid = React.useRef<any>()

  return (
    <RowContainer className="mt-2">
      <ItemsCountIndicator items={resultsSerach['auto'] ?? []} />
      <DataGrid
        ref={dataGrid}
        keyExpr="codigo"
        focusedRowKey="codigo"
        id="tabCertificados"
        selection={{ mode: 'single' }}
        dataSource={resultsSerach['auto'] ?? []}
        showColumnLines={true}
        showRowLines={true}
        showBorders={true}
        columnResizingMode="widget"
        focusedRowEnabled={true}
        onSelectionChanged={() => { }}
        loadPanel={{
          enabled: true,
          height: 90,
          indicatorSrc: '',
          shading: false,
          shadingColor: '',
          showIndicator: true,
          showPane: true,
          text: 'Cargando...',
          width: 200,
        }}
      >
        <Paging defaultPageSize={20} />
        <Pager
          visible={(resultsSerach['auto'] && resultsSerach['auto'].length > 0) ? true : false}
          allowedPageSizes={getAllowedPageSizes(resultsSerach['auto'] ?? [])}
          displayMode={'full'}
          showPageSizeSelector={true}
          showInfo={true}
          showNavigationButtons={true}
        />
        <Export enabled={true} allowExportSelectedData={true} />
        <ColumnChooser enabled={true} mode="select" />
        <Export enabled={true} allowExportSelectedData={true} />
        <Column dataField="codigo" caption="Cod. Movimiento" width="70px" />
        <Column dataField="documento" caption="Documento" width="150px" />
        <Column dataField="movimientoDescripcion" caption="Movimeinto" width="120px" />
        <Column dataField="productoClienteDescripcion" caption="Nombre" width="150px" />
        <Column dataField="localCodigoDescripcion" caption="Alamcén" width="80px" />
        <Column dataField="planCuentasDescripcion" caption="CC Compra" width="60px" />
        <Column dataField="fecha" caption="Fecha" width="70px" />
        <Column dataField="itemCodigoUsuario" caption="Interno" width="90px" />
        <Column dataField="itemBarras" caption="Barras" width="90px" />
        <Column dataField="itemDescripcion" caption="Descripción" width="150px" />
        <Column dataField="itemUnidadDescripcion" caption="Unidad" width="70px" />
        <Column dataField="movimientoDetalleCantidad" caption="Cantidad" width="70px" />
        <Column dataField="movimientoDetallePrecio" caption="Precio" width="70px" />
        <Column dataField="movimientoDetalleValor" caption="Subtotal" width="70px" />
      </DataGrid>
    </RowContainer>
  )
}

export default React.memo(Detalle)
