import React, { useEffect } from 'react'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput'
import { Button, DataGrid, DateBox, TextBox } from 'devextreme-react'
import { CombinacionesDatosEdicion } from '../../types/types'
import { ButtonTypes } from '../../../../../../../views/componentes/globalMenu/types'
import { clearButtonClick } from '../../store/tabsReducer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import {
  changeLoaderCombination,
  setCurrentFunction,
} from '../../store/generalReducer'
import { Column, Export, Pager, Paging } from 'devextreme-react/data-grid'
import { getAllowedPageSizes } from '../../../../../../../helpers/Helper'
import { TabState } from '../../../../../../../store/genericTabTypes'
import TipoEstadoLookUp from '../../../../../../componentes/tipoEstado'
import ItemsCountIndicator from '../../../../../../componentes/indicadorNroRegistros'
import {
  MessagesKeys,
  lh,
} from '../../../../../../../helpers/localizationHelper'
import { CCol } from '@coreui/react-pro'

interface ISearchProps extends React.PropsWithChildren {
  tab: TabState<CombinacionesDatosEdicion>
  tabId: string
}

const Search: React.FunctionComponent<ISearchProps> = (props) => {
  const { tabId } = props

  const dispatch = useDispatch()
  const currentAction = useSelector(
    (state: RootState) =>
      state.inventarios.movimientos.inventarioInicial.general.currentFunction,
  )
  const searchResults = useSelector(
    (state: RootState) =>
      state.inventarios.movimientos.inventarioInicial.search.resultados,
  )

  const unitsDataGrid = React.useRef<any>()

  const playLoader = React.useCallback(
    (message = 'Cargando...') => {
      dispatch(changeLoaderCombination({ show: true, mensaje: message }))
    },
    [dispatch],
  )

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoaderCombination({ show: false, mensaje: '' }))
  }, [dispatch])

  const onHandleEdit = React.useCallback(async () => {
    console.log('implements handlle to EDIT ')
  }, [])

  const onHandleRemove = React.useCallback(async () => {
    console.log('implements handlle to DELETE ')
  }, [])

  const handleButtonClick = React.useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.delete:
          onHandleRemove()
          break
        case ButtonTypes.edit:
          onHandleEdit()
          break
        default:
          break
      }
      dispatch(setCurrentFunction(''))
      dispatch(clearButtonClick(tabId))
    },
    [dispatch, onHandleRemove, onHandleEdit, tabId],
  )

  const handleSearch = React.useCallback(() => {
    console.log('implements handlle to SEARCH ')
  }, [])

  useEffect(() => {
    if (currentAction !== '') handleButtonClick(currentAction)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAction])

  return (
    <>
      <RowContainer>
        <ItemsCountIndicator items={searchResults} />
        <DataGrid
          ref={unitsDataGrid}
          keyExpr="codigo"
          //focusedRowKey="codigo"
          id="tabConceptosMovimiento"
          selection={{ mode: 'single' }}
          dataSource={searchResults}
          showColumnLines={true}
          showRowLines={true}
          showBorders={true}
          columnResizingMode="widget"
          //focusedRowEnabled={true}
          ///onSelectionChanged={onSelectedLineChanged}
          loadPanel={{
            enabled: true,
            height: 90,
            indicatorSrc: '',
            shading: false,
            shadingColor: '',
            showIndicator: true,
            showPane: true,
            text: 'Cargando...',
            width: 200,
          }}
        >
          <Paging defaultPageSize={20} />
          <Pager
            visible={searchResults.length > 0 ? true : false}
            allowedPageSizes={getAllowedPageSizes(searchResults)}
            displayMode={'full'}
            showPageSizeSelector={true}
            showInfo={true}
            showNavigationButtons={true}
          />
          <Export enabled={true} allowExportSelectedData={true} />
          <Column dataField="" caption="Número" width="" />
          <Column dataField="" caption="Combinación" width="" />
          <Column dataField="" caption="Resultado" width="" />
          <Column dataField="" caption="Estado" width="" />
        </DataGrid>
      </RowContainer>
    </>
  )
}

export default Search
