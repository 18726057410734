import { OpenModal } from "../../../../../store/types"

export enum TIPOS_BOTONES {
  BOTON_NUEVO = 'Nuevo',
  BOTON_GUARDAR = 'Guardar',
  BOTON_EDITAR = 'Editar',
  BOTON_DESHACER = 'Deshacer',
  BOTON_BUSCAR = 'Buscar',
  BOTON_ELIMINAR = 'Eliminar',
  BOTON_LIMPIAR = 'Limpiar',
}

export type SeleccionCliente = {
  index: any,
  row: any,
  selectedRow: any,
}

export type formularioBusqueda = {
  identificacion: string,
  nombre: string,
  razonComercial: string,
  empresa: string,
  activos: boolean
}

export type modalCliente = {
  identificacion: string
  nombres: string
  rcomercial: string
  direccion: string
  telefono: string
  email: string
  descuento: number
  ciudad: string
  tipo: string
  codigo: number
  tipo_id: number
}

export type ModalClientesState = {
  modulo: string,
  currentAction: string,
  currentFunction: string,
  aplicacion: string,
  registros: Array<any>,
  seleccionado: SeleccionCliente,
  modal: OpenModal,
  formularioBusqueda: formularioBusqueda
}