import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { addToast } from '../../../../store/toasterReducer';
import { TiposComprobantesSLV, TiposComprobantesSri, ToastTypes } from '../../../../store/types';
import ModalLoader from '../../../../views/componentes/modalLoader/modalLoader';
import { PdfVisualizer } from '../../../../views/componentes/pdfVisualizer/pdfVisualizer';
import { ReportWithLocalDataModal } from '../../../../views/componentes/xtrareports/ReportWithLocalDataModal';
import { VentasHelper } from '../../pages/helper/helper';
import { RideService } from '../../services/ride.service';
import { VentasService } from '../../services/ventas.service';
import { ECountry } from '../../../../store/enum/countries';
import { consoleService } from '../../../../services/console.service';

interface IVerFacturaProps extends React.PropsWithChildren {
  claveAcceso: string,
  onClose: () => void,
  modo: 'DevExpressReport' | 'Legacy',
  vista?: 'Viewer' | 'Designer'
}


type TPathDocs = {
  pdf: string, xml: string, url: string, urlBlobPdf: string
}

export function VerRIDE(props: IVerFacturaProps) {
  consoleService.log("VerRIDE", props)

  const { modo, claveAcceso, onClose, vista } = props;

  const empresa = useSelector((state: RootState) => { return state.global.session?.empresa });
  const local = useSelector((state: RootState) => { return state.global.session?.local })

  const [loading, setLoading] = React.useState(true);
  const [paths, setPaths] = React.useState<TPathDocs | null>(null);
  const [error, setError] = React.useState('');
  const dispatch = useDispatch();
  const [reportData, setReportData] = React.useState<any>();
  const [ivaComprobante, setIvaComprobante] = React.useState<any>(0);

  const [templateName, setTemplateName] = React.useState('RideFactura');

  const cargarPdfLegacy = React.useCallback(async () => {
    consoleService.log("cargarPdfLegacy")
    const pathsDocs: TPathDocs = {
      pdf: '', xml: '', url: '', urlBlobPdf: ''
    }
    try {
      const resPaths = await VentasService.obtenerRutasDocumentoElectornico(claveAcceso);
      consoleService.log("resPaths init", resPaths)

      if (resPaths?.error !== true && resPaths.auto) {
        consoleService.log("in paths ")
        pathsDocs.pdf = resPaths?.auto?.pdf ?? '';
        pathsDocs.url = resPaths?.auto?.url ?? '';
        pathsDocs.xml = resPaths?.auto?.xml ?? '';
      }
      consoleService.log("resPaths inProccess", resPaths)
      consoleService.log("pathsDocs", pathsDocs)

      const blob = await VentasService.obtenerPdfFactura(claveAcceso);
      consoleService.log("blob", blob)
      if (blob) {
        consoleService.log(window.URL.createObjectURL(blob))
        pathsDocs.urlBlobPdf = window.URL.createObjectURL(blob) ?? '';
      }

    } catch (e) {
      consoleService.error(e);
      setError(JSON.stringify(e));
    }
    finally {
      setPaths(pathsDocs);
      setLoading(false);
    }
  }, [claveAcceso])

  const loadReportData = React.useCallback(async () => {
    consoleService.log('loadReportData')
    consoleService.log('local : ', local)
    consoleService.log('claveAcceso : ', claveAcceso)
    if (local.ciudad.paisCodigo === ECountry.Ecuador) {
      const voucherType = VentasHelper.getVoucherTypeFromAccesKey(claveAcceso);
      if (voucherType === undefined) {
        dispatch(addToast({
          autoHide: 3500,
          content: 'No se pudo encontrar un tipo de documento válido en la clave de acceso ' + claveAcceso,
          type: ToastTypes.Warning
        }))
        onClose();
        return;
      }
      try {
        let data: any;
        let iva: any = 0;
        switch (voucherType) {
          case TiposComprobantesSri.NotaCredito:
            setTemplateName('RideNotaCredito');
            data = await RideService.getRideData(claveAcceso);
            iva = await VentasHelper.comprobanteIva(data, TiposComprobantesSri.NotaCredito);
            data.procentajeIva = iva;
            break;
          case TiposComprobantesSri.NotaDebito:
            setTemplateName('RideNotaDebito');
            data = await RideService.getRideData(claveAcceso);
            iva = await VentasHelper.comprobanteIva(data, TiposComprobantesSri.NotaDebito);
            data.procentajeIva = iva;
            break;
          case TiposComprobantesSri.Factura:
            data = await RideService.getRideData(claveAcceso);
            iva = await VentasHelper.comprobanteIva(data, TiposComprobantesSri.Factura);
            data.procentajeIva = iva;
            break;
          case TiposComprobantesSri.Retencion:
            setTemplateName('RideRetencion');
            data = await RideService.getRideData(claveAcceso);
            data.procentajeIva = 0;
            break;
          case TiposComprobantesSri.GuiaRemision:
            setTemplateName('RideGuiaRemision');
            data = await RideService.getRideData(claveAcceso);
            data.procentajeIva = 0;
            break;
          case TiposComprobantesSri.LiquidacionCompra:
            setTemplateName('RideLiquidacionCompra');
            data = await RideService.getRideData(claveAcceso);
            iva = await VentasHelper.comprobanteIva(data, TiposComprobantesSri.LiquidacionCompra);
            data.procentajeIva = iva;
            break;
          default:
            break;
        }
        setReportData(data);
        setIvaComprobante(iva);
      } catch (error) {
        setError(JSON.stringify(error));
      }
      finally {
        setLoading(false);
      }
    } else if (local.ciudad.paisCodigo === ECountry.ElSalvador) {
      const voucherType = VentasHelper.getVoucherTypeFromAccesKeySV(claveAcceso);
      if (voucherType === undefined) {
        dispatch(addToast({
          autoHide: 3500,
          content: 'No se pudo encontrar un tipo de documento válido  ' + claveAcceso,
          type: ToastTypes.Warning
        }))
        onClose();
        return;
      }
      consoleService.log('voucherType : ', voucherType)

      try {
        let data: any;
        switch (voucherType) {
          case TiposComprobantesSLV.Factura:
            setTemplateName('SV_RideFactura');
            data = await RideService.getRideData(claveAcceso);
            break;
          case TiposComprobantesSLV.CreditoFiscal:
            setTemplateName('RideNotaCredito');
            data = await RideService.getRideData(claveAcceso);
            break;
          case TiposComprobantesSLV.NotaRemision:
            setTemplateName('RideNotaCredito');
            data = await RideService.getRideData(claveAcceso);
            break;
          case TiposComprobantesSLV.NotaCredito:
            setTemplateName('RideNotaCredito');
            data = await RideService.getRideData(claveAcceso);
            break;
          case TiposComprobantesSLV.NotaDebito:
            setTemplateName('RideNotaCredito');
            data = await RideService.getRideData(claveAcceso);
            break;
          case TiposComprobantesSLV.Retencion:
            setTemplateName('RideNotaCredito');
            data = await RideService.getRideData(claveAcceso);
            break;
          case TiposComprobantesSLV.Liquidacion:
            setTemplateName('RideNotaCredito');
            data = await RideService.getRideData(claveAcceso);
            break;
          case TiposComprobantesSLV.LiquidacionContable:
            setTemplateName('RideNotaCredito');
            data = await RideService.getRideData(claveAcceso);
            break;
          case TiposComprobantesSLV.FacturaExportacion:
            setTemplateName('RideNotaCredito');
            data = await RideService.getRideData(claveAcceso);
            break;
          case TiposComprobantesSLV.FacturaExcluidoSujeto:
            setTemplateName('RideNotaCredito');
            data = await RideService.getRideData(claveAcceso);
            break;
          default:
            break;
        }
        setReportData(data);
      } catch (error) {
        setError(JSON.stringify(error));
      }
      finally {
        setLoading(false);
      }
    }
  }, [claveAcceso, onClose, dispatch, local])

  const onLoad = React.useCallback(async () => {
    if (modo === 'Legacy') {
      cargarPdfLegacy();
    } else {
      loadReportData();
    }
  }, [cargarPdfLegacy, loadReportData, modo])

  const onLoadError = React.useCallback(async () => {
    dispatch(addToast({
      id: '',
      autoHide: true,
      content: 'Error al descargar el archivo pdf ' + error,
      fade: true,
      title: 'Descargar Pdf',
      type: ToastTypes.Warning

    }))
    // onClose();
  }, [dispatch, error])


  React.useEffect(() => {
    onLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (error && error.length > 0) {
      onLoadError()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  if (modo === 'Legacy') {
    return (
      <>
        {loading &&
          <ModalLoader show={true} message='Descargando documento...' />
        }
        {!loading &&
          <PdfVisualizer
            fileName={`${props.claveAcceso}`}
            urlPdf={paths?.pdf ?? ''}
            urlXml={paths?.xml ?? ''}
            urlPrint={paths?.url ?? ''}
            urlBlobPdf={paths?.urlBlobPdf ?? ''}
            onClose={props.onClose} />
        }
      </>
    );
  }
  return (
    <React.Fragment key='RideDocumentoElectronico'>
      {loading &&
        <ModalLoader show={true} message='Descargando documento...' />
      }
      <ReportWithLocalDataModal
        show={!loading}
        onClose={onClose}
        data={[reportData]}
        fileName={templateName}
        mode={vista ?? 'Viewer'}
        parameters={{
          leyendaFacturas: empresa?.leyendaComprobantesElctronicos ?? "",
          PorcentajeIVA: ivaComprobante ?? 0
        }}
        template={templateName}
        key='reportDesigner'
      />
    </React.Fragment>
  )
}

