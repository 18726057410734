import React, { useCallback, useEffect } from 'react'
import { ValidationGroup } from 'devextreme-react/validation-group'
import ValidationSummary from 'devextreme-react/validation-summary'
import TextBox from 'devextreme-react/text-box'
import CustomCol from '../../../../../../../../views/componentes/colContainer'
import { TEstado, ToastTypes } from '../../../../../../../../store/types'
import { Validator } from 'devextreme-react'
import { RequiredRule } from 'devextreme-react/validator'
import RowContainer from '../../../../../../../../views/componentes/rowContainer/rowContainer'
import Labeled from '../../../../../../../../views/componentes/labeledInput/labeledInput'
import TipoEstadoLookUp from '../../../../../../../componentes/tipoEstado'
import VisualizaError from '../../../../../../../ventas/pages/shared/visualizaError/visualizaError'
import ListErrosValidationForm from '../../../../../../../componentes/listadoErroresValidacionForm'
import { SaveCharacteristic } from '../../../../types/types'
import { characteristicsServices } from '../../services/modalCaracteristicas.services'

interface INuevoProps extends React.PropsWithChildren {
  selectedItem?: any
  clickedButton: string
  currentTab: string
  // eslint-disable-next-line no-unused-vars
  setCurrentAction: (currentTab: string) => void
  resetAction: () => void
  resetSelected?: () => void
  // eslint-disable-next-line no-unused-vars
  setToast: (sms: string, type: ToastTypes) => void
  playLoader: () => void
  stopLoader: () => void
}

const Nuevo = (props: INuevoProps) => {
  const {
    clickedButton,
    setToast,
    selectedItem,
    playLoader,
    stopLoader,
    currentTab,
    resetAction,
    resetSelected,
    setCurrentAction,
  } = props

  const formSaveUnit = React.useRef<any>()
  const [code, setCode] = React.useState<number>(0)
  const [name, setName] = React.useState<string>('')
  const [status, setStatus] = React.useState<TEstado>({
    codigo: 1,
    descripcion: 'ACTIVO',
  })
  const [showPopupError, setShowPopupError] = React.useState<boolean>(false)
  const [validationFormErrors, setValidationFormErrors] = React.useState<
    Array<any>
  >([])

  useEffect(() => {
    if (currentTab === 'Nuevo' || currentTab === 'Editar') {
      if (clickedButton === 'Guardar') {
        onHandleSave()
      } else if (clickedButton === 'Editar') {
        setDataAplication(selectedItem)
      }
      resetAction()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickedButton])

  const onHandleReset = useCallback(() => {
    if (code === 0) {
      setName('')
      setStatus({ codigo: 1, descripcion: 'ACTIVO' })
    } else setDataAplication(selectedItem)
  }, [code, selectedItem])

  const setDataAplication = ({ row }) => {
    setCode(row.codigo)
    setName(row.nombre)
    setStatus({
      codigo: row.estado,
      descripcion: row.estado === 1 ? 'ACTIVO' : 'INACTIVO',
    })
  }

  const onHandleSave = React.useCallback(async () => {
    const validationFormResult = formSaveUnit.current.instance.validate()
    if (validationFormResult.isValid === false) {
      const errorList = []
      validationFormResult.brokenRules.map((rule) => {
        errorList.push(rule.message)
      })
      setValidationFormErrors(errorList)
      setShowPopupError(true)
    } else {
      try {
        const characteristic: SaveCharacteristic = {
          infoRegistro: {
            codigo: code,
            nombre: name,
            estado: status.codigo,
          },
        }
        playLoader()
        const saveAplication = await characteristicsServices.saveCharacteristic(
          characteristic,
        )
        stopLoader()
        if (!saveAplication.error && saveAplication.auto) {
          setToast(
            saveAplication.message ?? 'Característica Registrada',
            ToastTypes.Success,
          )
          setCurrentAction('Buscar')
          if (selectedItem) resetSelected()
          onHandleReset()
        } else {
          setToast(
            saveAplication.message ?? 'Error al guardar característica',
            ToastTypes.Warning,
          )
        }
      } catch (error) {
        stopLoader()
        setToast(error, ToastTypes.Danger)
      }
    }
  }, [
    code,
    name,
    status,
    playLoader,
    stopLoader,
    setToast,
    setCurrentAction,
    selectedItem,
    resetSelected,
    onHandleReset,
  ])

  const onHandleChange = React.useCallback(
    (setState: any) => (event: any) => {
      if (event.event) setState(event.value)
    },
    [],
  )

  return (
    <>
      {showPopupError && (
        <VisualizaError
          titulo="Error en Características"
          mensaje={'Error al guardar'}
          content={
            <ListErrosValidationForm
              mainMessage={
                'Antes de guardar la información revise lo siguiente:'
              }
              errorsList={validationFormErrors}
              color={'danger'}
            />
          }
          onOk={() => setShowPopupError(false)}
        />
      )}
      <ValidationGroup ref={formSaveUnit}>
        <ValidationSummary id="summary" />
        <RowContainer>
          <CustomCol xs="12" md="4">
            <Labeled label="Nombre:">
              <TextBox
                value={name}
                onValueChanged={onHandleChange(setName)}
                showClearButton={true}
                maxLength={250}
              >
                <Validator>
                  <RequiredRule message={'El campo Nombre es obligatorio'} />
                </Validator>
              </TextBox>
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="4">
            <Labeled label="Estado:">
              <TipoEstadoLookUp
                onChanged={(status: TEstado) => setStatus(status)}
                selected={status}
              />
            </Labeled>
          </CustomCol>
        </RowContainer>
      </ValidationGroup>
    </>
  )
}
export default Nuevo
