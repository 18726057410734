import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CustomDictionary } from '../../../../../types/generics'
import {
  ITotalesProforma,
  OrdenProformaDatosEdicion,
  OrdenProformaDatosEdicionPayload,
} from '../../../type/types'
import { ordenClearDatosEdicion } from '../../../store/reducers'
import { LoaderInfo } from '../../../../../../../store/types'
import { Cliente } from '../../../../../types/types'
import { Vendedor } from '../../../../../../shared/components/buscarVendedorLookMemoUp/type/types'
import { DetalleProformaRedux } from '../type/detallePedido'
import { Local, Project } from '../../../../../../shared/types/types'

const initialState: CustomDictionary<OrdenProformaDatosEdicion> = {}

const datosEdicionProformasSlice = createSlice({
  name: 'OrdenProformaDatosEdicion',
  initialState: initialState,
  reducers: {
    ordenClearDatosEdicion,
    setDatosEdicion(
      state,
      action: PayloadAction<{
        key: number | string
        data: OrdenProformaDatosEdicion
      }>,
    ) {
      state[action.payload.key] = action.payload.data
    },
    initDatosEdicion(
      state,
      action: PayloadAction<
        OrdenProformaDatosEdicionPayload<OrdenProformaDatosEdicion>
      >,
    ) {
      if (!state[action.payload.key]) {
        state[action.payload.key] = action.payload.data
      }
    },
    setEditLoader(
      state,
      action: PayloadAction<{ key: number | string; info: LoaderInfo }>,
    ) {
      state[action.payload.key].loader = action.payload.info
    },
    setMuestraError(
      state,
      action: PayloadAction<{
        key: number | string
        tieneError: boolean
        mensajeError: string
      }>,
    ) {
      state[action.payload.key].tieneError = action.payload.tieneError
      state[action.payload.key].mensajeError = action.payload.mensajeError
    },
    updateCliente(
      state,
      action: PayloadAction<{
        key: number | string
        cliente: Cliente | null
        detalles?: Array<any>
      }>,
    ) {
      state[action.payload.key].cliente = action.payload.cliente
    },
    updateClienteOnly(
      state,
      action: PayloadAction<{ key: number | string; cliente: Cliente }>,
    ) {
      state[action.payload.key].cliente = action.payload.cliente
    },
    updateVendedor(
      state,
      action: PayloadAction<{ key: number | string; vendedor: Vendedor }>,
    ) {
      state[action.payload.key].vendedor = action.payload.vendedor
    },
    updateObservaciones(
      state,
      action: PayloadAction<{ key: number | string; observaciones: string }>,
    ) {
      state[action.payload.key].observaciones = action.payload.observaciones
    },

    updateDetalles(
      state,
      action: PayloadAction<{
        key: number | string
        detalles: Array<DetalleProformaRedux>
      }>,
    ) {
      state[action.payload.key].detalles = action.payload.detalles
    },
    addDetalle(
      state,
      action: PayloadAction<{
        key: number | string
        detalle: DetalleProformaRedux
      }>,
    ) {
      state[action.payload.key].detalles.push(action.payload.detalle)
    },
    updateFechaHora(
      state,
      action: PayloadAction<{ key: number | string; fechaEntrega: string }>,
    ) {
      state[action.payload.key].fechaEntrega = action.payload.fechaEntrega
    },
    updateTotales(
      state,
      action: PayloadAction<{
        key: number | string
        totales: ITotalesProforma
      }>,
    ) {
      state[action.payload.key].totales = action.payload.totales
      state[action.payload.key].descuentoGlobal =
        action.payload.totales.descuentoGloabal
    },
    setMostrarBusquedaItems(
      state,
      action: PayloadAction<{ key: string; mostrarBusquedaItems: boolean }>,
    ) {
      const { key, mostrarBusquedaItems } = action.payload
      state[key].mostrarBusquedaItems = mostrarBusquedaItems
    },
    updateProyecto(
      state,
      action: PayloadAction<{ key: string; proyecto: Project }>,
    ) {
      state[action.payload.key].proyecto = action.payload.proyecto
    },
    updateLocal(state, action: PayloadAction<{ key: string; local: Local }>) {
      state[action.payload.key].local = action.payload.local
    },
  },
})

export const {
  setDatosEdicion,
  setEditLoader,
  setMuestraError,
  initDatosEdicion,
  updateCliente,
  updateClienteOnly,
  updateVendedor,
  updateObservaciones,
  updateDetalles,
  updateFechaHora,
  updateTotales,
  addDetalle,
  setMostrarBusquedaItems,
  ordenClearDatosEdicion: clearDatosEdicion,
  updateProyecto,
  updateLocal,
} = datosEdicionProformasSlice.actions
export const datosEdicionProformaReducer = datosEdicionProformasSlice.reducer
