import React from 'react'
import { useModalConvertSize } from '../../../../../hooks/useModalSize'
import CustomCol from '../../../../../views/componentes/colContainer'
import CustomModalDevx from '../../../../../views/componentes/modal/Modal'
import RowContainer from '../../../../../views/componentes/rowContainer/rowContainer'
import { TiposPagosVenta } from '../../../pages/ventas/types/types'
import DataGrid, {
  Column,
  Editing,
  Paging,
  Pager,
} from 'devextreme-react/data-grid'
import applyChanges from 'devextreme/data/apply_changes'
import Labeled from '../../../../../views/componentes/labeledInput/labeledInput'
import NumberBox from 'devextreme-react/number-box'
import { CCard, CCardBody } from '@coreui/react-pro'
import Validator, { AsyncRule } from 'devextreme-react/validator'
import ValidationSummary from 'devextreme-react/validation-summary'
import { VentasService } from '../../../services/ventas.service'
import Container from '../../../../../views/componentes/container'
import ValidationGroup from 'devextreme-react/validation-group'
import { isMobileOnly } from 'react-device-detect'
import { utilidades } from '../../../../../helpers/utilidades'
import { useDispatch } from 'react-redux'
import { addToast } from '../../../../../store/toasterReducer'
import { ToastTypes } from '../../../../../store/types'
import { LoadPanel } from 'devextreme-react/load-panel'
import { DateUtils, formatoFechasApi } from '../../../../../helpers/dateUtils'
import { consoleService } from '../../../../../services/console.service'

export interface IVariasFormasPagoProps extends React.PropsWithChildren {
  numeroVenta: number
  totalCobrar: number
  fechaFactura: string
  tiposPagos: Array<TiposPagosVenta> | []
  onOk: (tiposPagos: Array<TiposPagosVenta>) => void
  onCancel: () => void
}
const pageSizes = [10, 25, 50, 100, 200]

const VariasFormasPago: React.FunctionComponent<IVariasFormasPagoProps> = (
  props,
) => {
  const dispatch = useDispatch()

  const { onCancel, onOk, totalCobrar, numeroVenta, fechaFactura } = props

  const validationGroupRef = React.useRef<any>()

  const [modalSize] = useModalConvertSize('lg')

  const [changes, setChanges] = React.useState<any>()
  const [editRowKey, setEditRowKey] = React.useState<any>()
  const [metodos, setMetodos] = React.useState<Array<TiposPagosVenta>>([])
  const [descuadre, setDescuandre] = React.useState(0)
  const [loading, setLoading] = React.useState<boolean>(false)

  const validateRules = React.useCallback(async () => {
    let resolved = false
    let status = false
    const p = new Promise(async (resolve) => {
      while (!resolved) {
        await utilidades.sleep(10)
      }
      resolve(resolved)
    })
    const validationResult = validationGroupRef.current.instance.validate()
    if (!validationResult.isValid && validationResult.status !== 'pending') {
      //setTieneErroresValidacion(true);
      return false
    }
    if (validationResult.status === 'pending') {
      validationResult.complete.then(async (resolve) => {
        await resolve
        status = resolve.isValid
        resolved = true
      })
      await p
      return status
    }
    return true
  }, [])

  const validateMetodos = React.useCallback(
    (cuotas: Array<TiposPagosVenta>): boolean => {
      const cuotasOrdenadas = cuotas
      if (cuotasOrdenadas.length === 1) return true
      let index = 1
      let verificador = 0
      while (index < cuotasOrdenadas.length) {
        if (cuotasOrdenadas[index].valor < 0) {
          verificador++
        }
        index++
      }
      return verificador === 0
    },
    [],
  )

  const handleOnOk = React.useCallback(async () => {
    const verificador = validateMetodos(metodos)

    if ((await validateRules()) && verificador) {
      const metodosSeleccionados: Array<TiposPagosVenta> = []

      for (let index = 0; index < metodos.length; index++) {
        if (metodos[index].valor > 0) {
          metodosSeleccionados.push({ ...metodos[index] })
        }
      }
      onOk(metodosSeleccionados)
      return
    }

    dispatch(
      addToast({
        content: 'Verifique las formas de pago!!!',
        type: ToastTypes.Danger,
      }),
    )
  }, [metodos, validateMetodos, validateRules, dispatch, onOk])

  const onSaving = React.useCallback(
    (e) => {
      e.cancel = true
      const newData = applyChanges(metodos, e.changes, {
        keyExpr: 'codigoFormaPago',
      })
      setChanges([])
      setEditRowKey(null)
      setMetodos(newData)
      //e.promise = saveChange(dispatch, e.changes[0]);
    },
    [metodos],
  )

  const setMethodValue = React.useCallback((rowData, value) => {
    if (value) {
      rowData.valor = value
    }
    return false
  }, [])

  const validateRetencion = React.useCallback((e) => {
    return e.value === 0
      ? Promise.resolve()
      : Promise.reject(
        'La suma de las formas de pago no es igual al valor de la factura.',
      )
  }, [])

  const calcularCuotas = React.useCallback(() => {
    const sumanValor = parseFloat(
      metodos.reduce((prev, next) => prev + next.valor, 0).toFixed(2),
    )

    const diferencia = parseFloat((totalCobrar - sumanValor).toFixed(2))

    if (diferencia > 0.01 || diferencia < 0) {
      setDescuandre(diferencia)
    } else {
      setDescuandre(0)
    }
  }, [totalCobrar, metodos])

  const loadMetodosPago = React.useCallback(async () => {
    consoleService.log('loadMetodosPago')
    try {
      const fecha = DateUtils.format(fechaFactura, formatoFechasApi)
      consoleService.log('fecha', fecha)
      const metodos = await VentasService.getMetodosPago(fecha)
      consoleService.log('metodos', metodos)
      const addItem: Array<TiposPagosVenta> = []

      for (let index = 0; index < metodos.length; index++) {
        if (metodos[index].asignado !== false) {
          addItem.push({ ...metodos[index] })
        }
      }
      consoleService.log('addItem', addItem)

      return addItem
    } catch (error) {
      console.error(error)
      return []
    }
  }, [fechaFactura])

  const metodosPagoDisponibles = React.useCallback(async () => {
    consoleService.log('metodosPagoDisponibles')

    try {
      setLoading(true)
      const metodosDisponibles = await loadMetodosPago()
      consoleService.log('metodosDisponibles', metodosDisponibles)

      setMetodos(metodosDisponibles)
      calcularCuotas()
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }, [calcularCuotas, loadMetodosPago])

  const sortByProperty = (property) => {
    return function (a, b) {
      if (a[property] > b[property]) return 1
      else if (a[property] < b[property]) return -1

      return 0
    }
  }

  const loadMetodosAsignados = React.useCallback(async () => {
    consoleService.log('loadMetodosAsignados')
    try {
      setLoading(true)
      const formasExistentes = await loadMetodosPago()
      consoleService.log('formasExistentes', formasExistentes)
      const metodosAsignados = await VentasService.getMetodosPagoAsignados(
        numeroVenta,
      )

      const addItem: Array<TiposPagosVenta> = []
      const indexEliminar: Array<{ key: number }> = []

      for (
        let indexTiposExisten = 0;
        indexTiposExisten < formasExistentes.length;
        indexTiposExisten++
      ) {
        for (
          let indexAsignados = 0;
          indexAsignados < metodosAsignados.length;
          indexAsignados++
        ) {
          if (
            Number(formasExistentes[indexTiposExisten].codigoFormaPago) ===
            Number(metodosAsignados[indexAsignados].subCodigo)
          ) {
            addItem.push({
              codigoFormaPago: Number(
                formasExistentes[indexTiposExisten].codigoFormaPago,
              ),
              valor: Number(metodosAsignados[indexTiposExisten].valor),
              descripcionFormaPago:
                formasExistentes[indexTiposExisten].descripcionFormaPago,
              asignado: formasExistentes[indexTiposExisten].asignado,
            })
            indexEliminar.push({
              key: Number(formasExistentes[indexTiposExisten].codigoFormaPago),
            })
          }
        }
      }

      const formas = formasExistentes

      for (
        let eindexDelete = 0;
        eindexDelete < indexEliminar.length;
        eindexDelete++
      ) {
        const filtroImpresora: number = formas.findIndex((item: any) => {
          return (
            Number(item.codigoFormaPago) ===
            Number(indexEliminar[eindexDelete].key)
          )
        })

        if (filtroImpresora > -1) {
          formas.splice(filtroImpresora, 1)
        }
      }
      const convinar = formas.concat(addItem)
      convinar.sort(sortByProperty('codigoFormaPago'))
      setMetodos(convinar)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }, [numeroVenta, loadMetodosPago])

  const onChangesChange = React.useCallback((changes) => {
    setChanges(changes)
  }, [])

  const onEditRowKeyChange = React.useCallback((editRowKey) => {
    setEditRowKey(editRowKey)
  }, [])

  React.useEffect(() => {
    if (metodos.length > 0) {
      calcularCuotas()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metodos])

  React.useEffect(() => {
    if (numeroVenta === 0) {
      metodosPagoDisponibles()
    } else if (numeroVenta !== 0) {
      loadMetodosAsignados()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CustomModalDevx
      size={modalSize}
      title="Formas de Pago"
      onClose={onCancel}
      show
      toolbarItems={[
        {
          toolbar: 'bottom',
          widget: 'dxButton',
          location: 'after',
          visible: numeroVenta === 0,
          options: {
            text: 'Guardar',
            type: 'default',
            onClick: handleOnOk,
          },
        },
        {
          toolbar: 'bottom',
          widget: 'dxButton',
          location: 'after',
          options: {
            text: 'Cancelar',
            type: 'danger',
            onClick: onCancel,
          },
        },
      ]}
    >
      <ValidationGroup id={`valGroupFormasPago`} ref={validationGroupRef}>
        <CCard>
          <CCardBody>
            <Container fluid>
              {loading && (
                <LoadPanel
                  id="loadingPanelDevx"
                  shadingColor="rgba(0,0,0,0.4)"
                  visible={loading}
                  showIndicator={true}
                  shading={true}
                  showPane={true}
                  closeOnOutsideClick={false}
                />
              )}

              {!isMobileOnly && (
                <RowContainer>
                  <CustomCol>
                    <ValidationSummary />
                  </CustomCol>
                </RowContainer>
              )}

              <RowContainer className="mb-1">
                <CustomCol xs="6" lg="6" sm="12">
                  <Labeled label="Total">
                    <NumberBox
                      readOnly={true}
                      format={{ precision: 2 }}
                      value={totalCobrar}
                    />
                  </Labeled>
                </CustomCol>
                <CustomCol xs="6" lg="6" sm="12">
                  <Labeled label="Descuadre">
                    <NumberBox
                      readOnly={true}
                      format={{ precision: 2 }}
                      value={descuadre}
                    >
                      <Validator>
                        <AsyncRule
                          reevaluate
                          validationCallback={validateRetencion}
                        >
                          {' '}
                        </AsyncRule>
                      </Validator>
                    </NumberBox>
                  </Labeled>
                </CustomCol>
              </RowContainer>
              <RowContainer className="mb-1">
                <CustomCol xs="12" className="mb-1">
                  <DataGrid
                    className="mb-1"
                    keyExpr="codigoFormaPago"
                    focusedRowEnabled={true}
                    dataSource={metodos}
                    showBorders
                    repaintChangesOnly
                    onSaving={onSaving}
                  >
                    <Editing
                      mode="cell"
                      allowUpdating
                      changes={changes}
                      onChangesChange={onChangesChange}
                      editRowKey={editRowKey}
                      onEditRowKeyChange={onEditRowKeyChange}
                    />
                    <Column
                      dataField="descripcionFormaPago"
                      minWidth="75px"
                      caption="Forma de Pago"
                      allowEditing={false}
                      allowSearch={false}
                      allowFiltering
                    />
                    <Column
                      dataField="valor"
                      caption="Valor"
                      minWidth="70px"
                      allowEditing={true}
                      setCellValue={setMethodValue}
                    />

                    <Pager
                      allowedPageSizes={pageSizes}
                      showPageSizeSelector={true}
                      showInfo
                    />
                    <Paging defaultPageSize={10} />
                  </DataGrid>
                </CustomCol>
              </RowContainer>

              {isMobileOnly && (
                <RowContainer className="ms-2">
                  <CustomCol>
                    <ValidationSummary />
                  </CustomCol>
                </RowContainer>
              )}
            </Container>
          </CCardBody>
        </CCard>
      </ValidationGroup>
    </CustomModalDevx>
  )
}
export default React.memo(VariasFormasPago)
