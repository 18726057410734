import React, { useState } from 'react';
import {
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CRow,
  CCollapse,
  CLink,
  CForm, CButton, CTooltip
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { OptionGrupos } from '../../../../../../store/types';
import { useDispatch, useSelector } from 'react-redux'
import Tabla from "../tabla";
import { RootState } from '../../../../../../store/store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextBox } from 'devextreme-react/text-box'
import { SelectBox } from 'devextreme-react/select-box';
import { setProyectos, setLocales, setLocalesBack, setProyectosBack } from "../../store/modalUsuariosReducers"
import { modalUsuariosServices } from "../../../../services/modalUsuariosServices"
import { OptionProyectos } from '../../../../../bancos/general/types';
import { cilChevronBottom, cilChevronTop } from '@coreui/icons';

type BuscarProps = {
  cargarCuentas: (evt) => void
  setToast: (texto, type) => void
  playLoader: () => void
  stopLoader: () => void
  codModulo: number
}

const Buscar = (props: BuscarProps) => {
  const { cargarCuentas, setToast, playLoader, stopLoader, codModulo } = props;

  const dispatch = useDispatch()

  const proyectos = useSelector((state: RootState) => state.nomina.modalUsuarios.proyectos)
  const proyectosBack = useSelector((state: RootState) => state.nomina.modalUsuarios.proyectosBack)

  const [collapsed, setCollapsed] = React.useState(true)
  const [data, setData] = useState<any>([]);
  const [cedulab_ti, setCedulab_ti] = useState<any>("");
  const [apellidosb_ti, setApellidosb_ti] = useState<any>("");
  const [nombresb_ti, setNombresb_ti] = useState<any>("");
  const [centrocostos_cb, setCentrocostos_cb] = React.useState<OptionGrupos | []>([])
  const [dplocales, setDplocales] = React.useState<Array<OptionGrupos> | []>([])
  const [dpproyectos, setDpproyectos] = React.useState<Array<OptionProyectos> | []>([])
  const [proyectos_cb, setProyectos_cb] = React.useState<OptionProyectos | []>([])
  const [proyectosAux_cb, setProyectosAux_cb] = React.useState<null>(null)
  const [centrocostosAux_cb, setCentrocostosAux_cb] = React.useState<null>(null)


  const generarOptionsProyectos = React.useCallback(async (data) => {
    const obtenidos: any = [];
    Object.keys(data).map(function (key, i) {
      return obtenidos.push({
        value: Number(data[key]["PRO_CODIGO"]),
        label: data[key]["PRO_NOMBRE"],
        locales: data[key]["LOCALES"],
      });
    });
    return { cant: obtenidos.length, data: obtenidos }
  }, []);

  const generarLocales = React.useCallback(async (obtenidos) => {
    if (obtenidos.length > 0) {
      const proyectos_object: any = [];
      Object.keys(obtenidos).map((key) => {
        if (obtenidos[key]["locales"].length > 0) {
          proyectos_object.push(...obtenidos[key]["locales"]);
        }
      });
      if (proyectos_object.length > 0) {
        const local_object: any = [];
        proyectos_object.forEach(function (tipo_I_) {
          if (tipo_I_ !== "false" && tipo_I_ !== false) {
            const to_array_proy: any = {
              value: Number(tipo_I_["LOC_CODIGO"]),
              label:
                tipo_I_["LOC_NOMBRE"] === "Elija una opción"
                  ? "General"
                  : tipo_I_["LOC_NOMBRE"],
            };
            local_object.push(to_array_proy);
          }
        });
        setDplocales(local_object)
        setCentrocostos_cb(local_object[0])
        return { cant: local_object.length, data: local_object }
      }
    }
  }, []);

  const obteneProyectos = React.useCallback(async () => {
    try {
      playLoader()
      const data = await modalUsuariosServices.getProyectos();
      stopLoader()
      console.log("obteneProyectos", data)
      if (data['error'] === false) {
        if (data['auto'].length > 0) {
          const resProyectos: any = await generarOptionsProyectos(data['auto']);
          if (resProyectos['cant'] > 0) {
            dispatch(setProyectos(resProyectos['data']))
            dispatch(setProyectosBack(data['auto']))
            setDpproyectos(resProyectos['data'])
            const resLocales: any = await generarLocales(resProyectos['data']);
            if (resLocales['cant'] > 0) {
              setProyectos_cb(resProyectos['data'][0])
              dispatch(setLocales(resLocales['data']))
              dispatch(setLocalesBack(resLocales['data']))
              setDplocales(resLocales['data'])
              setCentrocostos_cb(resLocales['data'][0])
            }
          }
        }
      } else {
        setToast(data['message'], "warning")
      }
      return await data;
    } catch (error) {
      stopLoader()
      console.error(error)
      setToast(JSON.stringify(error), 'danger')
    }
  }, [dispatch, generarLocales, generarOptionsProyectos, setToast]);


  const llenarComboProyectos = React.useCallback(async (data) => {
    const resProyectos: any = await generarOptionsProyectos(data);
    if (resProyectos['cant'] > 0) {
      setDpproyectos(resProyectos['data'])
      setProyectos_cb(resProyectos['data'][0])
      const resLocales: any = await generarLocales(resProyectos['data']);
      if (resLocales['cant'] > 0) {
        setDplocales(resLocales['data'])
        setCentrocostos_cb(resLocales['data'][0])
      }
    }
  }, [dispatch, generarLocales, generarOptionsProyectos]);



  const handleSubmit = async (evt) => {

  }

  const handleReset = () => {

  }

  const validateAsyncSeleccionNoNull = datoSeleccion => {
    return verificarSeleccionNoNull(datoSeleccion)
  }

  const verificarSeleccionNoNull = value => {
    return new Promise(resolve => {
      setTimeout(function () {
        if (value['value']['value'] !== undefined) {
          resolve((value['value']['value'] === '') === true ? false : true)
        } else {
          resolve(true)
        }
      }, 1000)
    })
  }

  const cargarCuenta = (cuenta: any) => {
    props.cargarCuentas(cuenta)
    closeModal();
  }

  const closeModal = () => {

  }

  const extraerLocales = React.useCallback(async (obtenidos) => {

    if (obtenidos !== "") {
      if (obtenidos['value'] > 0) {
        dispatch(setLocales([]))
        const local_object: any = [];
        local_object.push({
          value: -1,
          label: "General"
        });
        obtenidos['locales'].forEach(function (tipo_I_) {
          if (tipo_I_ !== "false" && tipo_I_ !== false) {
            const to_array_proy: any = {
              value: Number(tipo_I_["LOC_CODIGO"]),
              label:
                tipo_I_["LOC_NOMBRE"] === "Elija una opción"
                  ? "General"
                  : tipo_I_["LOC_NOMBRE"],
            };
            local_object.push(to_array_proy);
          }
        });
        setDplocales(local_object)
        setCentrocostos_cb(local_object[0])
      } else {
        const resLocales: any = await generarLocales(proyectosBack)
        if (resLocales['cant'] > 0) {
          setDplocales(resLocales['data'])
          setCentrocostos_cb(resLocales['data'][0])
        }
      }
    }
  }, [dispatch, generarLocales, proyectosBack]);


  const onChangeProyecto = React.useCallback((ev) => {
    if (ev) {
      if (ev.value !== -1) {
        extraerLocales(ev)
      } else {
        generarLocales(proyectos)
      }
    } else {
      generarLocales(proyectos)
    }
  }, [proyectos, extraerLocales, generarLocales]);

  const marcarSelect = React.useCallback(async (buscarIndex, dataProvider, modificarEstado) => {

    try {
      if (dataProvider.length > 0) {

        let resTipoFiltrado: any = {}

        resTipoFiltrado = await dataProvider.findIndex((item: any) => {
          return Number(item.value) === Number(buscarIndex);
        });


        if (resTipoFiltrado > 0) {
          switch (modificarEstado) {
            case 'setCentrocostos_cb':
              setCentrocostosAux_cb(null)
              await setCentrocostos_cb(dataProvider[Number(resTipoFiltrado)])
              break;
            case 'setProyectos_cb':
              setProyectosAux_cb(null)
              await setProyectos_cb(dataProvider[Number(resTipoFiltrado)])
              break;
            default:
              break;
          }
        }
      }
    } catch (error) {
      console.error(error)
    }
  }, []);


  React.useEffect(() => {
    if (proyectos.length <= 0) {
      obteneProyectos()
    } else {
      llenarComboProyectos(proyectosBack)
    }
  }, []);

  React.useEffect(() => {
    if (proyectosAux_cb !== null && dpproyectos.length > 0) {
      marcarSelect(proyectosAux_cb, dpproyectos, 'setProyectos_cb')
    }
  }, [proyectosAux_cb, dpproyectos])

  React.useEffect(() => {
    if (centrocostosAux_cb !== null && dplocales.length > 0) {
      marcarSelect(centrocostosAux_cb, dplocales, 'setCentrocostos_cb')
    }
  }, [centrocostosAux_cb, dplocales])

  const renderButton = () => {
    return <CTooltip
      key={"tool-add"}
      placement="top"
      content={"Para elejir haz doble click sobre la cuenta."}
    >
      <CButton id="btnBuscarCuentas" color="primary" className="ms-1" size='sm' >
        <FontAwesomeIcon
          size="sm"
          icon={['fal', "info"]} style={{ fontWeight: 1 }}
        />
      </CButton>
    </CTooltip>
  }

  return (
    <>
      <CForm onSubmit={(evt: any) => handleSubmit(evt)}
        key='buscar'>

        <CCard>
          <CCardHeader onClick={() => setCollapsed(!collapsed)} >
            <strong> {"Buscar"} </strong>
            <div className="card-header-actions">
              <CLink className="card-header-action" >
                <CIcon icon={collapsed ? cilChevronBottom : cilChevronTop} />
              </CLink>
            </div>
          </CCardHeader>
          <CCollapse visible={collapsed}>
            <CCardBody>
              <CRow>
                <CCol lg="8" sm="12" >

                  <div className='dx-field'>
                    <div className='dx-field-label'>{'Cédula:'}</div>
                    <div className='dx-field-value'>
                      <TextBox
                        name='cedulab_ti'
                        id='cedulab_ti'
                        value={cedulab_ti}
                        onValueChanged={data => setCedulab_ti(data.value)}
                        showClearButton={true}
                      // onEnterKey={() => props.returnAction()}
                      />
                    </div>
                  </div>

                  <div className='dx-field'>
                    <div className='dx-field-label'>{'Apellidos:'}</div>
                    <div className='dx-field-value'>
                      <TextBox
                        name='apellidosb_ti'
                        id='apellidosb_ti'
                        value={apellidosb_ti}
                        onValueChanged={data => setApellidosb_ti(data.value)}
                        showClearButton={true}
                      // onEnterKey={() => props.returnAction()}
                      />
                    </div>
                  </div>

                  <div className='dx-field'>
                    <div className='dx-field-label'>{'Nombres:'}</div>
                    <div className='dx-field-value'>
                      <TextBox
                        name='nombresb_ti'
                        id='nombresb_ti'
                        value={nombresb_ti}
                        onValueChanged={data => setNombresb_ti(data.value)}
                        showClearButton={true}
                      // onEnterKey={() => props.returnAction()}
                      />
                    </div>
                  </div>

                  <div className='dx-field'>
                    <div className='dx-field-label'>
                      {'Proyecto:'}
                    </div>
                    <div className='dx-field-value '>
                      <SelectBox
                        width='100%'
                        dataSource={dpproyectos}
                        displayExpr='label'
                        searchEnabled={true}
                        searchMode={'contains'}
                        searchExpr={'label'}
                        searchTimeout={200}
                        minSearchLength={3}
                        showDataBeforeSearch={true}
                        placeholder='Seleccionar'
                        showClearButton={true}
                        defaultValue={proyectos_cb}
                        value={proyectos_cb}
                        onValueChanged={data => {
                          if (data.value) {
                            setProyectos_cb(data.value)
                            onChangeProyecto(data.value)
                          } else {
                            setProyectos_cb(dpproyectos[0])
                            setCentrocostos_cb(dplocales[0])
                            onChangeProyecto(dplocales[0])
                          }
                        }}
                      >
                      </SelectBox>
                    </div>
                  </div>

                  <div className='dx-field'>
                    <div className='dx-field-label '>
                      {'Lugar o CC.:'}
                    </div>
                    <div className='dx-field-value '>
                      <SelectBox
                        width='100%'
                        dataSource={dplocales}
                        displayExpr='label'
                        searchEnabled={true}
                        searchMode={'contains'}
                        searchExpr={'label'}
                        searchTimeout={200}
                        minSearchLength={3}
                        showDataBeforeSearch={true}
                        placeholder='Seleccionar'
                        showClearButton={true}
                        defaultValue={centrocostos_cb}
                        value={centrocostos_cb}
                        onValueChanged={data => {
                          if (data.value) {
                            setCentrocostos_cb(data.value)
                          } else {
                            setCentrocostos_cb(dplocales[0])
                          }
                        }}
                      >
                      </SelectBox>
                    </div>
                  </div>

                </CCol>
              </CRow>

            </CCardBody>
            <div style={{ zIndex: -1, display: 'none' }}>
              <CCardFooter style={{ zIndex: -1 }}>
                <CButton type="submit" id="btnPlanCuentasBuscar" color="primary" size='sm' >{"Buscar"}</CButton>{' '}
                <CButton color="danger" size='sm' onClick={() => handleReset()}>{"Reset"}</CButton>{' '}
                <CButton color="secondary" size='sm' onClick={() => ""}>{"Cancelar"}</CButton>
              </CCardFooter>
            </div>
          </CCollapse>



        </CCard>

      </CForm>

      <CRow>
        <CCol>

          <Tabla
            name="tablaModalUsuario"
            data={[]}
            actionDelete={() => console.log("actionDelete")}
            exportExcel={() => console.log("exportExcel")}
            origen="modal"
            returnSeleccion={(data) => console.log("data", data)}
          />

        </CCol>
      </CRow>

    </>
  );
}

export default Buscar;