import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { DateUtils } from "../../../../../helpers/dateUtils"
import { FETCH_STATUS } from "../../../../../store/types"
import { Seleccionado } from "../../../../bancos/store/types"
import { FilterWalletState, WalletState } from "../types/types"
import { WalletService } from "../components/corteComprobantes/service/cartera.service"


const gruposTipos = [
  { value: "", label: 'Todos' },
  { value: "N", label: 'Nacional' },
  { value: "E", label: 'Extrangero' },
]

const initialState: WalletState<any> = {
  filter: {
    tipoProveedor: gruposTipos[0],
    proveedor: null,
    local: null,
    ciudad: null,
    corte: DateUtils.getCurrentDateAsString('dd/MM/yyyy'),
    consolidado: false,
    saldoMayorCero: false,
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  seleccionado: {
    index: null,
    row: null,
    selectedRow: null,
  },
  resultados: []
}

export const fetchWallet = createAsyncThunk<
  Array<any>,
  any
>('proveedores/documentosPorPagar/seeWallet', async (object) => {
  try {
    const saldosApi = await WalletService.seeWallet(object)
    return saldosApi
  } catch (error) {
    return Promise.reject(error)
  }
})

const walletSlice = createSlice({
  name: 'carteraPagar',
  initialState: initialState,
  reducers: {
    setCleanResult(state) {
      state.resultados = []
    },
    setChangeFilter(state, action: PayloadAction<FilterWalletState>) {
      state.filter.tipoProveedor = action.payload.tipoProveedor
      state.filter.proveedor = action.payload.proveedor
      state.filter.local = action.payload.local
      state.filter.ciudad = action.payload.ciudad
      state.filter.corte = action.payload.corte
      state.filter.consolidado = action.payload.consolidado
      state.filter.saldoMayorCero = action.payload.saldoMayorCero
    },
    setResetSeleccion(state) {
      const toAdd = {
        index: null,
        row: null,
        selectedRow: null,
      }
      state.seleccionado = toAdd
    },
    setSeleccionarDato(state, action: PayloadAction<Seleccionado>) {
      const toAdd = {
        index: action.payload.index,
        row: action.payload.row,
        selectedRow: action.payload.selectedRow,
      }
      state.seleccionado = toAdd
    },
  }, extraReducers: (builder) => {
    builder.addCase(fetchWallet.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchWallet.fulfilled, (state, { payload }) => {
      state.resultados = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchWallet.rejected, (state, { payload }) => {
      state.resultados = []
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
  }
})

export const {
  setCleanResult,
  setChangeFilter,
  setResetSeleccion,
  setSeleccionarDato,
} = walletSlice.actions


export const walletReducer = walletSlice.reducer