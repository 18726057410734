import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataGrid, { Column, Paging, Pager, ColumnChooser, HeaderFilter } from 'devextreme-react/data-grid';
import { DatosRetenciones } from '../../../../types/types';
import { utilidades } from '../../../../../../../../helpers/utilidades';


interface ITablaRetencionesProps extends React.PropsWithChildren {
  data: Array<DatosRetenciones> | []
  cargar: (data: DatosRetenciones) => void
}
const pageSizes = [10, 25, 50, 100, 200];

const TablaRetenciones: React.FunctionComponent<ITablaRetencionesProps> = (props) => {
  const { data, cargar } = props;
  const dispatch = useDispatch();

  const dataGrid = React.useRef<any>();
  const [dataTable, setDataTable] = React.useState<Array<DatosRetenciones> | []>([])


  const clearFilter = React.useCallback(() => {
    dataGrid.current.instance.clearSorting();
    dataGrid.current.instance.clearFilter();
  }, []);

  const llenarDataRegistros = React.useCallback((dataReg) => {
    clearFilter();
    let item: any = []
    try {
      item = dataReg.map(function (key) {
        return {
          ...key,
        }
      });
      setDataTable(item.map(x => utilidades.unfreeze(x)))
    } catch (error) {
      setDataTable([])
      console.error("on llenarDataRegistros TablaRetenciones", error)
    }
  }, [clearFilter]);

  const onSelectionChanged = React.useCallback((e) => {
    const { selectedRowsData } = e;
    if (selectedRowsData.length > 0) {
      const dataSelec: DatosRetenciones = selectedRowsData[0];
      cargar(dataSelec);
    }
  }, [cargar]);

  React.useEffect(() => {
    if (data.length > 0) {
      llenarDataRegistros(data)
    } else {
      setDataTable([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <DataGrid
      ref={dataGrid}
      selection={{ mode: 'single' }}
      allowColumnResizing={true}
      columnResizingMode={'nextColumn'}
      focusedRowEnabled={true}
      keyExpr='completo'
      focusedRowKey='completo'
      dataSource={dataTable}
      showBorders={true}
      onRowDblClick={() => { }}
      onSelectionChanged={onSelectionChanged}


    >
      <ColumnChooser enabled={true} mode="select" />
      <HeaderFilter visible={true} />

      <Column dataField='completo' caption='Nro Retención' width='120px' allowEditing={false} visible={true} allowSearch={false} allowFiltering={false} />
      <Column dataField='autorizacion' caption='Autorización' width='170px' allowEditing={false} visible={true} allowSearch={false} allowFiltering={true} />
      <Column dataField='fecha' type={'string'} width='100px' caption='Fecha Emisión' alignment='right' allowFiltering={false} />

      <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} showInfo />
      <Paging defaultPageSize={10} />


    </DataGrid>
  );
}
export default TablaRetenciones;
