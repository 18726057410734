import { RequestHelper } from '../../../../../helpers/requestHelper'
import { SaveSpecialty } from '../types/types'

export const specialityServices = {
  getSpeciality,
  setPerformance,
  deleteSpecialty,
}

async function getSpeciality(name: string): Promise<any> {
  try {
    const data: any = {
      nombre: name,
    }
    const res = await RequestHelper.get<any>(
      'proveedores',
      'especialidad/getByNombre',
      '',
      data,
    )
    return res
  } catch (error) {
    return error
  }
}
async function setPerformance(requestBody: SaveSpecialty): Promise<any> {
  try {
    const data = await RequestHelper.postAll<any>(
      'proveedores',
      'especialidad/save',
      requestBody,
    )
    return data
  } catch (error) {
    return error
  }
}

async function deleteSpecialty(performanceCode: number): Promise<any> {
  try {
    const obj = {
      codigo: performanceCode,
    }
    const data = await RequestHelper.deleteRequestAll<any>(
      'proveedores',
      'especialidad/delete',
      '',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}
