import { combineReducers } from '@reduxjs/toolkit'
import {CatalogosState} from "./types";
import {CtlgActividadesEconomicasReducer} from "../actividadesEconomicas/store/actividadesEconomicasReducers";
import {CatologosPaisesReducer} from "../paises/store/paises.combine.reducers";
import {CatalogosProvinciasReducer} from "../provincias/store/provincias.combine.reducers";
import {CatalogosCiudadesReducer} from "../ciudades/store/ciudades.combine.reducers";
import {CatalogosUnidadMedidaReducer} from "../unidadMedida/store/unidadMedida.combine.reducers";
import {CatalogosRecintoFiscalReducer} from "../recintoFiscal/store/recintoFiscal.combine.reducers";
import {CatalogosRegimenReducer} from "../regimen/store/regimen.combine.reducers";
import {CatalogosTransporteReducer} from "../transporte/store/transporte.combine.reducers";
import {CatalogosFormaPagoReducer} from "../formaPago/store/formaPago.combine.reducers";
import {CatalogosIncotermsReducer} from "../incoterms/store/regimen.combine.reducers";

export const CatalogosReducers = combineReducers<CatalogosState>({
  actividadesEconomicas: CtlgActividadesEconomicasReducer,
  paises: CatologosPaisesReducer,
  provincias: CatalogosProvinciasReducer,
  ciudades: CatalogosCiudadesReducer,
  unidadMedida: CatalogosUnidadMedidaReducer,
  recintoFiscal: CatalogosRecintoFiscalReducer,
  regimen: CatalogosRegimenReducer,
  transporte: CatalogosTransporteReducer,
  formaPago: CatalogosFormaPagoReducer,
  incoterms: CatalogosIncotermsReducer,
});
