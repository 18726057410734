import { RequestHelper } from "../../../../helpers/requestHelper";
import { SesionService } from "../../../../services/sesion.service";

export const tarjetasService = {
  CargarTodosVenta, cargarTarjetas, cargarComisiones, registrarTarjetaV, devuelveTotalFormasPagoABV, devuelveTotalSinRetencion, tarCargarTodosVenta, deleteTarjeta
}

async function CargarTodosVenta(find): Promise<any> {
  try {
    const obj = {
      ...find
    }
    const data = await RequestHelper.getAll<any>('contabilidad', 'period/getAll', "", obj);
    return data;
  } catch (error) {
    return error;
  }
}

async function cargarTarjetas(find): Promise<any> {
  try {
    const obj = {
      ...find
    }
    const data = await RequestHelper.getAll<any>('bancos/tarjetas', 'cards/getCards', "", obj);
    return data;
  } catch (error) {
    return error;
  }
}

async function cargarComisiones(find): Promise<any> {
  try {
    const obj = {
      ...find
    }
    const data = await RequestHelper.getAll<any>('bancos/tarjetas', 'cards/getCommissions', "", obj);
    return data;
  } catch (error) {
    return error;
  }
}

async function registrarTarjetaV(objetoSave: any): Promise<any> {
  try {
    const obj = {
      infoRegistro: {
        ...objetoSave
      }
    }
    const data = await RequestHelper.postAll<any>('bancos/tarjetas', 'cardsales/save', obj);
    return data;
  } catch (error) {
    return error;
  }
}

async function devuelveTotalSinRetencion(find: any): Promise<any> {
  try {
    const obj = {
      ...find
    }
    const data = await RequestHelper.getAll<any>('ventas', 'getTotalWithoutRetentionSales', "", obj);
    return data;
  } catch (error) {
    return error;
  }
}

async function devuelveTotalFormasPagoABV(find: any): Promise<any> {
  try {
    const obj = {
      ...find
    }
    const data = await RequestHelper.getAll<any>('bancos/movimientosBancos', 'egresos/getTotal', "", obj);
    return data;
  } catch (error) {
    return error;
  }
}

async function tarCargarTodosVenta(find: any): Promise<any> {
  try {
    const obj = {
      ...find
    }
    const data = await RequestHelper.getAll<any>('bancos/tarjetas', 'cardsales/getAllSales', "", obj);
    return data;
  } catch (error) {
    return error;
  }
}

async function deleteTarjeta(obj: any): Promise<any> {
  try {
    const objRequest = {
      ...obj
    }
    const data = await RequestHelper.deleteRequestAll<any>(
      'bancos', 'cardsales/delete',
      '',
      objRequest
    )
    return data
  } catch (error) {
    return error
  }
}