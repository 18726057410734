import { utilidades } from '../../../../../helpers/utilidades'
import { sidebarService } from '../../../../../services/sidebar.service'
import { TipoAgente, TipoNegocio } from '../../../../../store/types'
import { RimpeOption } from '../formularios/formRegEmpresa'
import { wizardService } from '../servicios/wizard.service'

export async function getTypeActivities(
  codeCountry: number,
): Promise<Array<TipoNegocio>> {
  const data = await wizardService.getTiposNegocios(codeCountry ?? 1)
  if (data.auto.length > 0) {
    let opt: Array<TipoNegocio> = []
    opt = data?.auto ?? []
    return opt
  }
  return []
}

export function parseActivities(options: Array<any>) {
  let opt: Array<TipoNegocio> = []
  if (options.length > 0) {
    opt = options.map((x) => {
      const item: TipoNegocio = {
        codigo: parseInt(x['codigo']) ?? 0,
        descripcion: utilidades.capText(x['descripcion']) ?? '',
        codigoTributario: parseInt(x?.codigoTributario) ?? null,
      }
      return item
    })
  }
  return opt
}

export async function getTypeAgent(
  codeCountry: number,
): Promise<Array<TipoAgente>> {
  const data = await wizardService.getTiposAgente(codeCountry)
  if (data.auto.length > 0) {
    let opt: Array<TipoAgente> = []
    opt = data?.auto ?? []
    return opt
  }
  return []
}

export function parseAgent(options: Array<any>) {
  let opt: Array<TipoAgente> = []
  if (options.length > 0) {
    opt = options.map((x) => {
      const item: TipoAgente = {
        codigo: parseInt(x['codigo']) ?? 0,
        descripcion: utilidades.capText(x['descripcion']) ?? '',
        codigoTributario: parseInt(x['codigoTributario']) ?? 0,
      }
      return item
    })
  }
  return opt
}

export async function getTypeRimpe(): Promise<Array<RimpeOption>> {
  const data = await wizardService.getTiposRimpe()
  if (data.auto.length) {
    let opt: Array<RimpeOption> = []
    opt = data?.auto ?? []
    return opt
  }
  return []
}

export function parseRimpe(options: Array<any>) {
  let opt: Array<RimpeOption> = []
  if (options.length > 0) {
    opt = options.map((x) => {
      const item: RimpeOption = {
        codigo: parseInt(x['codigo']) ?? 0,
        descripcion: utilidades.capText(x['descripcion']) ?? '',
      }
      return item
    })
  }
  return opt
}

export async function getEconomicActivities(
  codeCountry: number | string = '',
): Promise<Array<TipoNegocio>> {
  const data = await sidebarService.tiponegocioListar()
  if (data.length > 0) {
    let opt: Array<TipoNegocio> = []
    opt = data ?? []
    return opt
  }
  return []
}
