import React from 'react';
import { BuscarVendedorLookUp } from './buscarVendedorLookUp';
import { StatesEdition } from '../../../ventas/types/enums';
import { VendedorBusqueda } from './type/types';

interface IBuscarVendedorLookUpMemoProps extends React.PropsWithChildren {
  vendedor: VendedorBusqueda | null,
  tab: any,
  cambiarVendedorSeleccionado: any,
  disabled?: boolean,
  allowAdd?: boolean,
  allowClear?: boolean,
  allowEdit?: boolean,
}

const BuscarVendedorLookMemoUp: React.FC<IBuscarVendedorLookUpMemoProps> = (props) => {

  const { disabled, vendedor, tab, cambiarVendedorSeleccionado, allowAdd, allowClear, allowEdit, } = props;

  return (
    <BuscarVendedorLookUp
      selected={vendedor}
      // allowEdit={tab.editStatus === StatesEdition.save ? false : true}
      // allowAdd={tab.editStatus === StatesEdition.save ? false : true}
      allowEdit={allowEdit ?? false}
      allowAdd={allowAdd ?? false}
      allowClear={tab.editStatus === StatesEdition.save ? false : true}
      onChanged={cambiarVendedorSeleccionado}
      disabled={disabled}
    />
  )
}

export default React.memo(BuscarVendedorLookMemoUp);
