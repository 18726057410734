import React from 'react'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import { DataGrid } from 'devextreme-react'
import { Column, Export, Pager, Paging } from 'devextreme-react/data-grid'
import { getAllowedPageSizes } from '../../../../../../helpers/Helper'
import ItemsCountIndicator from '../../../../../componentes/indicadorNroRegistros'

const Cabecera = () => {
  const data = []
  const dataGridKardexSerie = React.useRef<any>()

  return (
    <>
      <RowContainer className="mt-2 border">
        <ItemsCountIndicator items={data} />
        <DataGrid
          ref={dataGridKardexSerie}
          selection={{ mode: 'single' }}
          hoverStateEnabled={true}
          dataSource={data}
          showBorders={true}
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          //onSelectionChanged={onSelectedItemChanged}
          //onCellDblClick={onOpenItemToEdit}
          keyExpr="itemCodigo"
          key="gridListItems"
        >
          <Export enabled={true} allowExportSelectedData={true} />
          <Paging defaultPageSize={20} />
          <Pager
            visible={data.length > 0 ? true : false}
            allowedPageSizes={getAllowedPageSizes(data)}
            displayMode={'full'}
            showPageSizeSelector={true}
            showInfo={true}
            showNavigationButtons={true}
          />
          <Column visible={false} dataField="itemCodigo" width={0} />
          <Column dataField="" caption="Código" width="10%" />
          <Column dataField="" caption="Número" width="10%" />
          <Column dataField="" caption="Fecha" width="10%" />
          <Column dataField="" caption="Descripción" width="10%" />
          <Column dataField="" caption="Local Origen" width="10%" />
          <Column dataField="" caption="Local Destino" width="10%" />
          <Column dataField="" caption="Progreso" width="10%" />
          <Column dataField="" caption="Estado" width="10%" />
          <Column dataField="" caption="Responsable" width="10%" />
          <Column dataField="" caption="Activado" width="10%" />
        </DataGrid>
      </RowContainer>
    </>
  )
}

export default Cabecera
