import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../../store/store'
import { FETCH_STATUS } from '../../../../../store/types'
import {
  FiltroBusquedaPaisesState, PaisesListado,
} from './types'
import { TConfigSearchState } from '../../store/types'
import {paisesServices} from "../services/paises.services";

const initialState: TConfigSearchState<PaisesListado> = {
  filter: {
    codigo: 0,
    nombre: '',
    codigoInternacional: '',
    codigoUaf: '',
    codigoPaisLocal: '',
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  resultados: [],
  selectedItem: null,
}

export const fetchPaises = createAsyncThunk<
  Array<PaisesListado>,
  FiltroBusquedaPaisesState
>('countries/getAll', async (filtro) => {
  try {
    return await paisesServices.getAllCountries(filtro)
  } catch (error) {
    return Promise.reject(error)
  }
});

const searchConfPaisesSlice = createSlice({
  name: 'searchPaises',
  initialState: initialState,
  reducers: {
    resetState(state) {
      state = {
        ...initialState,
        filter: {
          ...initialState.filter,
        },
      }
    },
    changeFilter(state, action: PayloadAction<FiltroBusquedaPaisesState>) {
      state.filter = action.payload
    },
    setCleanResult(state) {
      state.resultados = []
    },
    setResultados(state, action: PayloadAction<Array<PaisesListado> | []>) {
      state.resultados = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPaises.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchPaises.fulfilled, (state, { payload }) => {
      state.resultados = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchPaises.rejected, (state, { payload }) => {
      state.resultados = []
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
  }
});

export const selectFiltroBusquedaPaises = (state: RootState) => {
  return state.administracion.catalagos.paises.search.filter
}

export const selectResultadosBusquedaPaises = (state: RootState) => {
  return state.administracion.catalagos.paises.search.resultados
}

export const selectBusquedaPaisesEstado = (state: RootState) => {
  return state.administracion.catalagos.paises.search.status
}

export const { setResultados, resetState, setCleanResult, changeFilter } = searchConfPaisesSlice.actions
export const searchReducer = searchConfPaisesSlice.reducer
