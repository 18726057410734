import React from 'react';
import SelectBox from 'devextreme-react/select-box';
import { detalleSucursalesService } from './service/detalleSucursales.service';
import { TDetalleContactosSucursales } from './types/type';

interface ContactosLookUpProps extends React.PropsWithChildren {
  selected: TDetalleContactosSucursales | null,
  onChanged?: (newValue: TDetalleContactosSucursales | null) => void,
  onChangedOptions?: (newValue: Array<TDetalleContactosSucursales> | []) => void,
  disabled?: boolean,
  provider?: Array<TDetalleContactosSucursales> | [],
  codigoEntidad: number, // codigo cliente, codigo proveedor
}

const ContactosLookUp: React.FC<ContactosLookUpProps> = (props) => {
  const { codigoEntidad, provider, selected, onChanged, disabled, onChangedOptions } = props;
  const selectRef = React.useRef<any>();
  const [options, setOptions] = React.useState<Array<TDetalleContactosSucursales> | []>([]);

  const loadDatos = React.useCallback(async () => {
    if (provider !== undefined && provider.length > 0) {
      setOptions(provider)
      const selectedIndex = provider.findIndex((select: TDetalleContactosSucursales) => {
        return select.codigo === selected?.codigo
      })
      onChanged(provider[selectedIndex] || provider[0])
      if (selectedIndex > -1) {
        onChanged(provider[selectedIndex] || provider[0])
      }
    } else {
      const data = await detalleSucursalesService.getDetalleSucursales(codigoEntidad);
      if (data.error === false) {
        let opt = [];
        if (data.auto.length > 0) {
          opt = data.auto.map(x => {
            const item: TDetalleContactosSucursales = {
              codigo: parseInt(x.codigo) ?? -1,
              descripcion: x.descripcion ?? '',
              numero: x.numero ?? '',
              tipo: x.tipo ?? '',
              tipoDescripcion: x.tipoDescripcion ?? '',
              estado: parseInt(x.codigo) ?? -1,
              asociadoCodigo: parseInt(x.codigo) ?? -1,
              asociadoTipo: x.asociadoTipo ?? '',
              principal: parseInt(x.codigo) ?? -1,
            };
            return item;
          });
        }
        setOptions(opt)
        if (onChangedOptions) {
          onChangedOptions(opt)
        }
        if (selected === null)
          onChanged(opt[0])
      }
    }

  }, [onChanged, onChangedOptions, selected, provider, codigoEntidad]);

  const getItemDisplayExpr = React.useCallback((item: TDetalleContactosSucursales) => {
    return item ? `${item.numero}` : '';
  }, []);

  const onLookUpValueChanged = React.useCallback((evt: any) => {
    if (evt.event !== undefined) {
      onChanged(evt.value);
    }
  }, [onChanged]);

  const onSelectBoxFocusIn = React.useCallback((e) => {
    e.element.querySelector("input.dx-texteditor-input").select();
  }, [])

  React.useEffect(() => {
    loadDatos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  return (
    <SelectBox
      id='contactosSelect'
      ref={selectRef}
      items={options ?? []}
      searchEnabled
      displayExpr={getItemDisplayExpr}
      searchExpr={['numero']}
      searchTimeout={500}
      onEnterKey={(e) => { console.log(e) }}
      value={selected}
      onValueChanged={onLookUpValueChanged}
      disabled={disabled}
      onFocusIn={onSelectBoxFocusIn}
    >
      {props.children}
    </SelectBox>
  )

}
export default React.memo(ContactosLookUp);
