import { CuotasPendientes } from '../../../../store/global/payTypes'
import { LoaderInfo, Plan } from '../../../../store/types'
import {
  PersonalizaRecurrenteData,
  RecurrenteData,
} from '../../infoEmergente/contadorDocumentos/type/type'

export enum ActionWizard {
  submit = 'submit',
  none = 'none',
  print = 'print',
}
export interface PagosSuscripcion {
  action: ActionWizard
  display: number
  metodoPago: IMethodPay
  openPopupTerminos: boolean
  loader: LoaderInfo
  planes: Array<Plan>
  planSeleccionado: Plan | null
  codPlanVigente: number
  valorPagar: number | string
  codigoContrato: number
  contratoEmpresa: RecurrenteData | null
  planVigente: Plan | null
  showFacturas: boolean
  showCuentasBancarias: boolean
  vencidas: Array<CuotasPendientes> | []
  cuentasDepositos: Array<any> | []
  cuentasMain: string
  contratoEmpresaPersonaliza: PersonalizaRecurrenteData | null
}

export enum IMethodPay {
  deposito = 'deposito',
  payphone = 'payphone',
}
