import { memo, useCallback } from 'react'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import { CCard, CCardHeader, CCol, CCollapse, CLink } from '@coreui/react-pro'
import { useDispatch, useSelector } from 'react-redux'
import CIcon from '@coreui/icons-react'
import { cilChevronBottom, cilChevronTop } from '@coreui/icons'
import {
  setCollpasedFilter,
  setFilterCandidates,
  setFilterPayroll,
} from '../../store/searchReducer'
import { RootState } from '../../../../../../store/store'
import CustomCol from '../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput'
import { CheckBox, DateBox, TextBox } from 'devextreme-react'
import CargosRolLookUp from '../../../../../componentes/cargosLookup'
import { CargoRol } from '../../../../../componentes/cargosLookup/types/types'
import { ValueChangedEvent } from 'devextreme/ui/text_box'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import { buttonClick } from '../../store/tabsReducer'
import { isMobile } from 'react-device-detect'
import { CheckBoxArrayLeft, CheckBoxArrayRigth } from '../../types/types'
import { ValueChangedEvent as EventCheckBox } from 'devextreme/ui/check_box'

const SearchFilter = () => {
  const dispatch = useDispatch()

  const searchFilter = useSelector(
    (state: RootState) => state.nomina.candidatos.search.filter,
  )
  const collapsed = useSelector(
    (state: RootState) => state.nomina.candidatos.search.collapsedFilter,
  )
  const currentTab = useSelector((state: RootState) => {
    return state.nomina.candidatos.tabs.current
  })
  const currentExecutingAction = useSelector((state: RootState) => {
    return state.nomina.candidatos.tabs.tabs[currentTab].currentExecutingAction
  })

  const updateFormField = useCallback(
    (evt: ValueChangedEvent | EventCheckBox, valueToUpdate: string) => {
      if (evt.event)
        dispatch(
          setFilterCandidates({
            ...searchFilter,
            [valueToUpdate]: evt.value,
          }),
        )
    },
    [dispatch, searchFilter],
  )

  const onPayrollChanged = useCallback(
    (payRoll: CargoRol) => {
      dispatch(setFilterPayroll(payRoll))
    },
    [dispatch],
  )

  const onHandleSearch = useCallback(() => {
    if (currentExecutingAction === undefined)
      dispatch(buttonClick({ tabKey: 'BUSQUEDA', button: ButtonTypes.find }))
  }, [currentExecutingAction, dispatch])

  return (
    <CCard>
      <CCardHeader
        onClick={() => dispatch(setCollpasedFilter(!collapsed))}
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <strong> {!collapsed ? 'Filtro de Búsqueda' : ''} </strong>
        <CLink className="card-header-action">
          <CIcon icon={collapsed ? cilChevronBottom : cilChevronTop} />
        </CLink>
      </CCardHeader>
      <CCollapse visible={collapsed}>
        <RowContainer className="m-2">
          <RowContainer>
            <CustomCol xs="12" md="3">
              <Labeled label="Apellidos">
                <TextBox
                  inputAttr={{ autocomplete: 'nope' }}
                  value={searchFilter.apellidos}
                  onValueChanged={(evt: ValueChangedEvent) =>
                    updateFormField(evt, 'apellidos')
                  }
                  showClearButton={true}
                  onEnterKey={onHandleSearch}
                />
              </Labeled>
            </CustomCol>
            <CustomCol xs="12" md="3">
              <Labeled label="Nombres:">
                <TextBox
                  inputAttr={{ autocomplete: 'nope' }}
                  value={searchFilter.nombres}
                  onValueChanged={(evt: ValueChangedEvent) =>
                    updateFormField(evt, 'nombres')
                  }
                  onEnterKey={onHandleSearch}
                />
              </Labeled>
            </CustomCol>
            {!isMobile && (
              <>
                <CCol
                  xs="12"
                  md="2"
                  style={{ marginTop: 'auto', marginBottom: '4px' }}
                >
                  <CheckBox
                    style={{ marginBottom: '4px' }}
                    text="Capacidad de Liderazgo"
                    value={searchFilter.liderazgo}
                    onValueChanged={(evt: any) =>
                      updateFormField(evt, 'liderazgo')
                    }
                  />
                  <CheckBox
                    style={{ marginBottom: '4px' }}
                    text="Actitud Positiva"
                    value={searchFilter.actitud}
                    onValueChanged={(evt: any) =>
                      updateFormField(evt, 'actitud')
                    }
                  />
                </CCol>
                <CCol xs="12" md="1" style={{ marginTop: 'auto' }}>
                  <CheckBox
                    style={{ marginBottom: '4px' }}
                    text="Dedicación"
                    value={searchFilter.dedicacion}
                    onValueChanged={(evt: any) =>
                      updateFormField(evt, 'dedicacion')
                    }
                  />
                  <CheckBox
                    style={{ marginBottom: '4px' }}
                    text="Confianza"
                    value={searchFilter.confianza}
                    onValueChanged={(evt: any) =>
                      updateFormField(evt, 'confianza')
                    }
                  />
                </CCol>
              </>
            )}
          </RowContainer>
          <RowContainer>
            <CustomCol xs="12" md="3">
              <Labeled label="Identificación:">
                <TextBox
                  inputAttr={{ autocomplete: 'nope' }}
                  value={searchFilter.identificacion}
                  onValueChanged={(evt: ValueChangedEvent) =>
                    updateFormField(evt, 'identificacion')
                  }
                  showClearButton={true}
                  onEnterKey={onHandleSearch}
                />
              </Labeled>
            </CustomCol>
            <CustomCol xs="12" md="3">
              <Labeled label="Cargo:">
                <CargosRolLookUp
                  selected={searchFilter.cargo}
                  onChanged={(payRoll: CargoRol) => onPayrollChanged(payRoll)}
                />
              </Labeled>
            </CustomCol>
            {!isMobile && (
              <>
                <CCol
                  xs="12"
                  md="2"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  <CheckBox
                    text="Comunicación"
                    value={searchFilter.comunicacion}
                    onValueChanged={(evt: any) =>
                      updateFormField(evt, 'comunicacion')
                    }
                  />
                  <CheckBox
                    text="Flexibilidad"
                    value={searchFilter.flexibilidad}
                    onValueChanged={(evt: any) =>
                      updateFormField(evt, 'flexibilidad')
                    }
                  />
                  <CheckBox
                    text="Trabajo en Equipo"
                    value={searchFilter.colaboracion}
                    onValueChanged={(evt: any) =>
                      updateFormField(evt, 'colaboracion')
                    }
                  />
                </CCol>
                <CCol
                  xs="12"
                  md="2"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  <CheckBox
                    text="Honestidad e Integridad"
                    value={searchFilter.integridad}
                    onValueChanged={(evt: any) =>
                      updateFormField(evt, 'integridad')
                    }
                  />
                  <CheckBox
                    text="Iniciativa"
                    value={searchFilter.iniciativa}
                    onValueChanged={(evt: any) =>
                      updateFormField(evt, 'iniciativa')
                    }
                  />
                  <CheckBox
                    text="Deseo de Aprender"
                    value={searchFilter.aprendizaje}
                    onValueChanged={(evt: any) =>
                      updateFormField(evt, 'aprendizaje')
                    }
                  />
                </CCol>
              </>
            )}
          </RowContainer>
          <RowContainer>
            <CustomCol xs="12" md="3">
              <Labeled label="Registro:">
                <div
                  style={{
                    display: 'flex',
                    gap: '2px',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <DateBox
                    displayFormat="dd/MM/yyyy"
                    value={searchFilter.registroDesde}
                    onValueChanged={(evt: any) =>
                      updateFormField(evt, 'registroDesde')
                    }
                  />
                  {' - '}
                  <DateBox
                    displayFormat="dd/MM/yyyy"
                    value={searchFilter.registroHasta}
                    onValueChanged={(evt: any) =>
                      updateFormField(evt, 'registroHasta')
                    }
                  />
                </div>
              </Labeled>
            </CustomCol>
          </RowContainer>
          {isMobile && (
            <>
              <RowContainer>
                <Labeled label="Cualidades:">
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '8px',
                    }}
                  >
                    {CheckBoxArrayLeft.map((checkBox) => {
                      return (
                        <CheckBox
                          key={checkBox.name}
                          text={checkBox.title}
                          value={searchFilter[checkBox.name]}
                          onValueChanged={(evt: EventCheckBox) =>
                            updateFormField(evt, checkBox.name)
                          }
                        />
                      )
                    })}
                    {CheckBoxArrayRigth.map((checkBox) => {
                      return (
                        <CheckBox
                          key={checkBox.name}
                          text={checkBox.title}
                          value={searchFilter[checkBox.name]}
                          onValueChanged={(evt: EventCheckBox) =>
                            updateFormField(evt, checkBox.name)
                          }
                        />
                      )
                    })}
                  </div>
                </Labeled>
              </RowContainer>
            </>
          )}
        </RowContainer>
      </CCollapse>
    </CCard>
  )
}

export default memo(SearchFilter)
