import { RequestHelper } from '../../../helpers/requestHelper'
import { SesionService } from '../../../services/sesion.service'
import { UsuarioModal } from '../componentes/modalUsuarios/store/types'

export const modalUsuariosServices = {
  obtenerEmpreados,
  getProyectos
}

async function obtenerEmpreados(find): Promise<any> {
  const sesion = SesionService.getCurrentSesion()
  try {
    const obj = {
      ruc: sesion.empresa.ruc,
      usuario: sesion.usuario.codigo,
      ...find
    }
    const data = await RequestHelper.getAll<any>(
      'nomina',
      'loadEmployes',
      '',
      obj
    )
    return data
  } catch (error) {
    return error
  }
}


async function getProyectos(): Promise<any> {
  try {
    const obj = {
    }
    const data = await RequestHelper.getAll<any>('proyectos/listar', '', "", obj);
    return data;
  } catch (error) {
    return error;
  }
}
