import DataGrid, { Column, ColumnChooser, Export, Pager, Paging, Button as DatagridButton, } from 'devextreme-react/data-grid'
import React, { useCallback } from 'react'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import { getAllowedPageSizes } from '../../../../../../../helpers/Helper'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import ItemsCountIndicator from '../../../../../../componentes/indicadorNroRegistros'
import { Workbook } from 'exceljs'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { saveAs } from 'file-saver';
import { ModalDetalleRegistro } from '../../../../../../componentes/modalDetallesRegistro'
import { consoleService } from '../../../../../../../services/console.service'
import { setSeleccionarDato } from '../../store/searchReducer'
import { isMobile } from 'react-device-detect'
import { ActionSheetCommandsMobile } from './ActionSheetCommandsMobile'
import { MarcationList } from '../../types/types'

interface IMarcacionProps extends React.PropsWithChildren {
}

const ResultadosBusqueda = (props: IMarcacionProps) => {

  const dispatch = useDispatch()
  const resultados = useSelector((state: RootState) => state.nomina.registros.marcacion.search.resultados)
  const seleccionado = useSelector((state: RootState) => state.nomina.registros.marcacion.search.seleccionado)
  const filtro = useSelector((state: RootState) => state.nomina.registros.marcacion.search.filtro)
  const [showDetalleRegistro, setShowDetalleRegistro] = React.useState<any>(false)

  const onExportExcell = React.useCallback((e) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('Main sheet')
    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Registros_Marcacion.xlsx')
      });
    });
  }, [])

  const onSelectedChanged = useCallback(
    ({ selectedRowsData }) => {
      consoleService.log(selectedRowsData[0], 'seleccionado selectedRowsData[0]')
      if (selectedRowsData[0] && selectedRowsData[0]?.fecha) {
        dispatch(
          setSeleccionarDato({ ...selectedRowsData[0] }),
        )
      }
    },
    [dispatch],
  )

  const onDBClick = useCallback(() => {
    if (seleccionado !== null || seleccionado !== undefined) {
      setShowDetalleRegistro(true)
    }
  }, [seleccionado])

  const actionSheetCommandsMobile = React.useCallback(
    (cellData) => {
      const data = cellData.data as any
      return (
        <ActionSheetCommandsMobile
          marcation={data}
          key={new Date().getMilliseconds()}
          shoDetail={(data) => {
            dispatch(setSeleccionarDato({ ...data }))
            setShowDetalleRegistro(true)
          }}
        />
      )
    },
    [dispatch],
  )


  return (
    <>
      {showDetalleRegistro && <ModalDetalleRegistro
        onCancel={() => setShowDetalleRegistro(false)}
        show={showDetalleRegistro}
        usuarioCodigo={filtro?.empleadoCodigo}
        fecha={seleccionado?.fecha}
      />}
      <RowContainer className="mt-2">
        <CustomCol xs="12" md="12">
          <ItemsCountIndicator items={resultados} />
          <DataGrid
            keyExpr="fecha"
            focusedRowKey="fecha"
            id="tabCertificados"
            selection={{ mode: 'single' }}
            dataSource={resultados ?? []}
            showColumnLines={true}
            hoverStateEnabled={true}
            showRowLines={true}
            showBorders={true}
            columnResizingMode="widget"
            focusedRowEnabled={true}
            onSelectionChanged={onSelectedChanged}
            onRowDblClick={onDBClick}
            onExporting={onExportExcell}
            loadPanel={{
              enabled: true,
              height: 90,
              indicatorSrc: '',
              shading: false,
              shadingColor: '',
              showIndicator: true,
              showPane: true,
              text: 'Cargando...',
              width: 200,
            }}
          >
            <Paging defaultPageSize={10} />
            <Pager
              visible={resultados.length > 0 ? true : false}
              allowedPageSizes={getAllowedPageSizes(resultados)}
              displayMode={'full'}
              showPageSizeSelector={true}
              showInfo={true}
              showNavigationButtons={true}
            />
            <ColumnChooser enabled={true} mode="select" />
            <Export enabled={true} allowExportSelectedData={true} />
            {
              !isMobile && (
                <Column allowEditing={false} type="buttons" width="50px">
                  <DatagridButton
                    icon="file"
                    hint="Detalle"
                    onClick={async (e) => {
                      consoleService.log('e', e)
                      const data: MarcationList = e.row.data
                      dispatch(setSeleccionarDato({ ...data }))
                      setShowDetalleRegistro(true)
                    }}
                  />
                </Column>
              )
            }
            {isMobile && (
              <Column
                allowEditing={false}
                caption="Tareas"
                dataField="fecha"
                cellRender={actionSheetCommandsMobile}
                width="50px"
                allowFiltering={false}
              ></Column>
            )}

            <Column dataField='fecha' caption="Fecha" width="100px" />
            <Column dataField='diaDescripcion' caption="Día" width="100px" />
            <Column dataField='localNombre' caption="Local" width="100px" />
            <Column dataField='minutosTrabajados' caption="Min.Trabajo" width="100px" />
            <Column dataField='atrasos' caption="Atrasos" width="100px" />
            <Column dataField='minutosAtraso' caption="Min.Atra" width="100px" />
            <Column dataField='antes' caption="Salidas Antic" width="100px" />
            <Column dataField='minutosAntes' caption="MIn.Sal.Ant" width="100px" />
            <Column dataField='minutosExtra50' caption="Extras 50% (min)" width="100px" />
            <Column dataField='minutosExtra100' caption="Extras 100% (min)" width="100px" />
            <Column dataField='error' caption="Errores" width="100px" />
          </DataGrid>
        </CustomCol>
      </RowContainer>
    </>
  )
}

export default React.memo(ResultadosBusqueda)