import {PaginationInfo} from "../../../../../store/types";
import {FiltroBusquedaProvinciasState, ProvinciasListado} from "../store/types";
import {RequestHelper} from "../../../../../helpers/requestHelper";

export const ProvinciasService = {
  getAllProvincias,
  getProvincia,
  saveProvincia,
  deleteProvincia,
}

async function getAllProvincias(filtro: FiltroBusquedaProvinciasState): Promise<any> {
  try {
    const query = buildQuery(filtro);
    const pagedResponse = await RequestHelper.getAll<any>('provinces', 'getAll', '', query);
    pagedResponse.auto = pagedResponse.auto? parseProvincias(pagedResponse.auto) : [];
    return pagedResponse;
  } catch (error) {
    return error;
  }
}

async function getProvincia(id: any): Promise<any> {
  try {
    const query = {
      codigo: id,
    };
    return await RequestHelper.get<any>('province', 'getProvince', '', query);
  } catch (error) {
    return error;
  }
}

async function saveProvincia(formData: any): Promise<any> {
  try {
    const body = {
      infoRegistro: {
        ...formData,
      }
    };

    return await RequestHelper.postAll<any>('provinces', 'save', body);
  } catch (error) {
    return error;
  }
}

async function deleteProvincia(id: string): Promise<any> {
  try {
    const body = {
      codigo: id
    }

    return new Promise((resolve, reject) => {});
  } catch (error) {
    return error
  }
}

// Private
function buildQuery(
  filtro: FiltroBusquedaProvinciasState,
  pagination?: PaginationInfo,
): any {

  const query: any = {
    ...(filtro.nombre && { nombre: filtro.nombre }),
    ...(filtro.paisCodigo && { codigoPais: filtro.paisCodigo.codigo }),
  }

  if (pagination) {
    query.page = pagination.current
    query.limit = pagination.size
    query.isPaged = true
  }

  const filters = ['nombre', 'codigoPais'];

  filters.forEach((field) => {
    if (filtro[field] && filtro[field].length > 0) {
      query[field] = filtro[field]
    }
  })

  return query
}

function parseProvincias(dataApi: any[]): Array<ProvinciasListado> {
  return dataApi.map((item: any) => ({
    codigo: item.provinciaCodigo,
    nombre: item.provinciaNombre,
    paisCodigo: {
      codigo: item.paisCodigo,
      nombre: '',
      uaf: ''
    },
    codigoDep: item.codigoDep,
    codigoOrigen: item.codigoOrigen,
    inec: item.provinciaInec,
  }))
}
