import { combineReducers } from '@reduxjs/toolkit'
import { CustomDictionary } from '../../../types/generics'
import { FacturaDatosEdicion } from '../../ventas/types/types'
import { datosEdicionReducer } from './editDataReducer'

import { menuReducer } from './menuReducer'
import { searchNotasDebitoReducer } from './searchReducer'
import { tabsReducer } from './tabsReducer'
import { configuracionesReducer } from './configuracionesNDebitoReducer'
import { NotasDebitoState } from '../types/types'

// const initialState: WindowVentasState<InfoVenta> = {
//   menu: {},
//   search: {},
//   tabs: {
//     current: "",
//     tabs: []
//   }
// }

const ediState: CustomDictionary<FacturaDatosEdicion> = {}

export const notasDebitoReducer = combineReducers<NotasDebitoState>({
 menu: menuReducer,
 tabs: tabsReducer,
 search: searchNotasDebitoReducer,
 editData: datosEdicionReducer,
 configuraciones: configuracionesReducer,
})
