import { RequestHelper } from "../../../../../../../../../../helpers/requestHelper";

export const detalleSucursalesService = { getDetalleSucursales };

async function getDetalleSucursales(codigo: number): Promise<any> {
  try {
    const data: any = {
      codigo: codigo
    };
    const res = await RequestHelper.getAll<any>('clientes', 'getDetall', "", data);
    return res;
  } catch (error) {
    return error;
  }
}

