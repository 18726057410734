import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput'
import {
  Button,
  CheckBox,
  DataGrid,
  DateBox,
  RadioGroup,
} from 'devextreme-react'
import LocalesLookUp from '../../../../../componentes/localesLookUp'
import { KardexListResult, SearchKardex, optionsStock } from '../../types/types'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/store'
import {
  CCard,
  CCardHeader,
  CCol,
  CCollapse,
  CLink,
  CRow,
} from '@coreui/react-pro'
import { BuscarGrupoLookUp } from '../../../../components/buscarGrupo/buscarGrupo'
import { BuscarMarcaLookUp } from '../../../../components/buscarMarca/buscarMarca'
import { useSetToast } from '../../../../../../hooks/useGlobalHooks'
import {
  setBrand,
  setFilter,
  setFooter,
  setGroup,
  setSearchAll,
  setSearchReserve,
  setSearchResult,
  setStore,
} from '../../store/serachReducer'
import { LocalOption } from '../../../../../componentes/localesLookUp/store/type'
import { GrupoInventario, MarcaInventario } from '../../../../store/types'
import MainModalItems from '../../../../../ventas/components/items/busquedaItemsModal/MainModalItems'
import { ItemVenta } from '../../../../../ventas/types/types'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import { setCollpased, setCurrentFunction } from '../../store/generalReducer'
import {
  clearButtonClick,
  setCurrentExecutingAction,
} from '../../store/tabsReducer'
import { ETipoAsociado, ToastTypes } from '../../../../../../store/types'
import Dialog from '../../../../../../views/componentes/librerias/bootstrap-dialog'
import { KardexServices } from '../../services/kardex.services'
import {
  DateUtils,
  formatoFechasApi,
} from '../../../../../../helpers/dateUtils'
import { Column } from 'devextreme-react/data-grid'
import { AdminModalLocales } from '../../../../../componentes/modalAdminLocales/modalAdminLocales'
import BuscarItemPedidoMemoLookUp from '../../../../../ventas/pages/ordenes/pages/ordenPedido/components/busquedaPedidos/BusquedaItemsSelectBox/BuscarItemPedidoMemoLookUp'
import { ItemPedido } from '../../../../../ventas/pages/ordenes/pages/ordenPedido/type/types'
import { isMobile } from 'react-device-detect'
import { EModulosAcatha } from '../../../../../../store/enumsAplication'
import { useLoaderKardex } from '../../customHooks'
import { clearChanges } from '../../../../store/globalReducerInventarios'
import { cilChevronBottom, cilChevronTop } from '@coreui/icons'
import CIcon from '@coreui/icons-react'

export const optionsSearch = [
  { value: true, label: 'Barras' },
  { value: false, label: 'Descripción' },
]

const FiltroBusqueda = (props) => {
  const { searchModeMobile } = props
  const dispatch = useDispatch()
  const setToastMessage = useSetToast()
  const { playLoader, stopLoader } = useLoaderKardex()

  const currentTabState = useSelector((state: RootState) => {
    return state.inventarios.kardex.search.currentTab
  })
  const searchFilter = useSelector((state: RootState) => {
    return state.inventarios.kardex.search.filter
  })
  const currentAction = useSelector(
    (state: RootState) => state.inventarios.kardex.general.currentFunction,
  )
  const decPrec = useSelector((state: RootState) => {
    return state.global.session.empresa.precision
  })
  const accountingPeriod = useSelector((state: RootState) => {
    return state.shared.providers.periodoContable
  })
  const transfersChanges = useSelector((state: RootState) => {
    return state.inventarios.global.incomesExpenses
  })
  const collapsed = useSelector(
    (state: RootState) => state.inventarios.kardex.general.collapse,
  )

  const [showModalItems, setShowModalItems] = useState<boolean>(false)
  const [showModalStores, setShowModalStores] = useState<boolean>(false)
  const [searchFrom, setSearchFrom] = useState(optionsSearch[0])
  const [confirmSearchAll, setConfirmSearchAll] = useState<boolean>(false)
  const [rowIndex, setRowIndex] = useState<number>(-1)
  const dialogRef = useRef<any>(null)

  const onHandleChange = useCallback(
    (event: any, valueToUpdate: string) => {
      if ((event.event && event.value) || valueToUpdate === 'locales')
        dispatch(
          setFilter({
            ...searchFilter,
            [valueToUpdate]: valueToUpdate === 'locales' ? event : event.value,
          }),
        )
    },
    [dispatch, searchFilter],
  )

  const onAddItem = useCallback(
    (item: ItemVenta) => {
      dispatch(
        setFilter({
          ...searchFilter,
          codigo: item.codigo ?? 0,
          barras: item.codigoBarras ?? '',
          descripcion: item.descripcion ?? '',
          itemLookup: {
            codigo: item.codigo ?? 0,
            codigoInterno: item.codigoInterno ?? '',
            codigoBarras: item.codigoBarras ?? '',
            descripcion: item.descripcion ?? '',
            existencia: item.existencia ?? 0,
            pvdiva: item.pvdiva ?? 0,
            pvpiva: item.pvpiva ?? 0,
            pvd: item.pvd ?? 0,
            pvp: item.pvp ?? 0,
            codigoImpuesto: item.codigoImpuesto ?? 0,
            tieneIce: item.tieneIce,
            porcentajeIva: item.porcentajeIva ?? 0,
            costo: item.costo ?? 0,
            tipoCodigo: item.tipoCodigo ?? 0,
            numeroSerie: item.numeroSerie ?? 0,
            plantilla: item.plantilla ?? 0,
            freeitem: item.freeitem ?? 0,
            gradoAcoholico: item.gradoAcoholico ?? 0,
            capacidad: item.capacidad ?? 0,
            factor: item.factor ?? 0,
            jerarquia: item.jerarquia ?? '',
            descuentos: item.descuentos ?? 0,
            titadicional: item.titadicional ?? '',
            valadicional: item.valadicional ?? '',
            grupo: item.grupo ?? '',
            codigoGrupo: item.codigoGrupo ?? 0,
            codigoUnidadVenta: item.codigoUnidadVenta ?? 0,
            tipoDescripcion: item.tipoDescripcion ?? '',
            existenciaMin: item.stockMin ?? 0,
            existenciaMax: item.stockMax ?? 0,
            saldoReservas: item.reservas ?? 0,
            saldoFecha: item.existencia ?? 0,
            lotes: '',
            fraccion: 0,
            unidadDescripcion: '',
            unidadIce: 0,
            impuesto: 0,
            impuestoValor: 0,
          },
        }),
      )
      dispatch(
        setFooter({
          existenciaMin: item.stockMin ?? null,
          existenciaMax: item.stockMax ?? null,
          costo: item.costo ?? null,
          saldoReservas: item.reservas ?? null,
          saldoFecha: item.existencia ?? null,
        }),
      )
      setShowModalItems(false)
    },
    [dispatch, searchFilter],
  )

  const onAddItemLookup = useCallback(
    (item: ItemPedido) => {
      if (item) {
        dispatch(
          setFilter({
            ...searchFilter,
            codigo: item.codigo ?? 0,
            barras: item.codigoBarras ?? '',
            descripcion: item.descripcion ?? '',
            itemLookup: item,
          }),
        )
        dispatch(
          setFooter({
            existenciaMin: item.existenciaMin ?? null,
            existenciaMax: item.existenciaMax ?? null,
            costo: item.costo ?? null,
            saldoReservas: item.saldoReservas ?? null,
            saldoFecha: item.saldoFecha ?? null,
          }),
        )
      } else if (item === null) {
        dispatch(
          setFilter({
            ...searchFilter,
            itemLookup: null,
            codigo: 0,
            barras: '',
            descripcion: '',
          }),
        )
        dispatch(
          setFooter({
            existenciaMin: null,
            existenciaMax: null,
            costo: null,
            saldoReservas: null,
            saldoFecha: null,
          }),
        )
      }
    },
    [dispatch, searchFilter],
  )

  const onParseKardexAll = useCallback(
    (dataKardex: Array<KardexListResult>) => {
      dataKardex.map((itemKardex: KardexListResult) => {
        const valorIngEgr = parseFloat(
          (
            itemKardex.transferenciaCantidad *
            itemKardex.transferenciaPrecioUnitario
          ).toFixed(decPrec),
        )
        if (itemKardex.tipo === 9 || itemKardex.tipo === 11) {
          itemKardex.cantIng = itemKardex.transferenciaCantidad ?? 0
          itemKardex.precioUniIng = itemKardex.transferenciaPrecioUnitario ?? 0
          itemKardex.valorIng = valorIngEgr ?? 0
        } else if (itemKardex.tipo === 10) {
          itemKardex.cantEgr = itemKardex.transferenciaCantidad ?? 0
          itemKardex.precioUniEgr = itemKardex.transferenciaPrecioUnitario ?? 0
          itemKardex.valorEgr = valorIngEgr ?? 0
        } else if (itemKardex.tipo === 0) {
          itemKardex.cantIng = itemKardex.cantidad ?? 0
          itemKardex.precioUniIng = itemKardex.precioUnitario ?? 0
          itemKardex.valorIng = null
          itemKardex.cantEgr = itemKardex.cantidad ?? 0
          itemKardex.precioUniEgr = itemKardex.precioUnitario ?? 0
          itemKardex.valorEgr = null
        }
      })
      const filteredKardexAll = dataKardex.filter(
        (itemKardex: KardexListResult) => itemKardex.local > 0,
      )
      setToastMessage(
        'Buscar Kardex Todos',
        `${filteredKardexAll.length} Registro(s) encontrado(s)`,
        ToastTypes.Success,
      )
      return filteredKardexAll
    },
    [decPrec, setToastMessage],
  )

  const onParseKardexItem = useCallback(
    (dataKardex: Array<KardexListResult>) => {
      dataKardex.map((itemKardex: KardexListResult) => {
        if (itemKardex.tipo === 9 || itemKardex.tipo === 11) {
          itemKardex.cantIng = itemKardex.transferenciaCantidad ?? 0
          itemKardex.precioUniIng = itemKardex.transferenciaPrecioUnitario ?? 0
          itemKardex.valorIng = itemKardex.totalIE ?? 0
        } else if (itemKardex.tipo === 10) {
          itemKardex.cantEgr = itemKardex.transferenciaCantidad ?? 0
          itemKardex.precioUniEgr = itemKardex.transferenciaPrecioUnitario ?? 0
          itemKardex.valorEgr = itemKardex.totalIE ?? 0
        }
      })
      const filteredKardexItem = dataKardex.filter(
        (itemKardex: KardexListResult) => itemKardex.local > 0,
      )
      setToastMessage(
        'Buscar Kardex Item',
        `${filteredKardexItem.length} Registro(s) encontrado(s)`,
        ToastTypes.Success,
      )
      return filteredKardexItem
    },
    [setToastMessage],
  )

  const onHandleSearchAll = useCallback(async () => {
    try {
      playLoader('Buscando kardex . . .')
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'BUSQUEDA',
          buttonType: ButtonTypes.find,
        }),
      )
      const itemKardex: SearchKardex = {
        itemCodigo: searchFilter.codigo ?? 0,
        localCodigo:
          searchFilter.local?.codigo > 0 ? searchFilter.local?.codigo : 0,
        fechaDesde:
          DateUtils.pickersDateToApiDate(searchFilter.fechaDesde) ?? ' ',
        fechaHasta:
          DateUtils.pickersDateToApiDate(searchFilter.fechaHasta) ?? '',
        stock: searchFilter.stock.value ?? '',
        marcaCodigo: searchFilter.marca?.codigo ?? -1,
        grupoCodigo: searchFilter.grupo?.codigo ?? -1,
        locales: searchFilter.locales ?? [],
      }
      const searchKardexAll = await KardexServices.getKardexAll(itemKardex)
      stopLoader()
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'BUSQUEDA',
          buttonType: undefined,
        }),
      )
      if (!searchKardexAll.error && searchKardexAll.auto) {
        if (searchKardexAll.auto.length > 5) dispatch(setCollpased(false))
        dispatch(setSearchAll(onParseKardexAll(searchKardexAll.auto)))
      } else
        setToastMessage(
          'Buscar Kardex Item',
          searchKardexAll.message ?? 'Error al buscar kardex',
          ToastTypes.Warning,
        )
    } catch (error) {
      stopLoader()
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'BUSQUEDA',
          buttonType: undefined,
        }),
      )
      setToastMessage('Buscar Kardex Item', error, ToastTypes.Danger)
    }
  }, [
    onParseKardexAll,
    playLoader,
    searchFilter,
    setToastMessage,
    stopLoader,
    dispatch,
  ])

  const onConfirmSearchAll = useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `Esta seguro que desea consultar el kardex de todos los items existentes?`,
      actions: [
        Dialog.Action(
          <span>
            <u>A</u>ceptar
          </span>,
          (dialog) => {
            if (isMobile) searchModeMobile()
            dialog.hide()
            onHandleSearchAll()
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setConfirmSearchAll(false)
    return
  }, [onHandleSearchAll, searchModeMobile])

  useEffect(() => {
    if (confirmSearchAll) onConfirmSearchAll()
  }, [confirmSearchAll, onConfirmSearchAll])

  const onHandleSearch = useCallback(async () => {
    if (searchFilter.fechaDesde !== '' && searchFilter.fechaHasta !== '') {
      if (currentTabState === 'Item' || currentTabState === 'Reservas') {
        if (searchFilter.codigo > 0) {
          if (isMobile) searchModeMobile()
          playLoader('Buscando kardex . . .')
          dispatch(
            setCurrentExecutingAction({
              tabKey: 'BUSQUEDA',
              buttonType: ButtonTypes.find,
            }),
          )
          const itemKardex: SearchKardex = {
            itemCodigo: searchFilter.codigo ?? 0,
            localCodigo:
              searchFilter.local?.codigo > 0 ? searchFilter.local?.codigo : 0,
            fechaDesde:
              DateUtils.dateBoxToApiDate(searchFilter.fechaDesde) ?? ' ',
            fechaHasta:
              DateUtils.dateBoxToApiDate(searchFilter.fechaHasta) ?? '',
            stock: searchFilter.stock.value ?? '',
          }
          let searchKardex = null
          if (currentTabState === 'Item') {
            if (searchFilter.local?.codigo > 0)
              searchKardex = await KardexServices.getKardexByStore(itemKardex)
            else searchKardex = await KardexServices.getKardex(itemKardex)
          } else
            searchKardex = await KardexServices.getKardexReserves(itemKardex)
          stopLoader()
          dispatch(
            setCurrentExecutingAction({
              tabKey: 'BUSQUEDA',
              buttonType: undefined,
            }),
          )
          if (!searchKardex.error && searchKardex.auto) {
            if (searchKardex.auto.length > 5) dispatch(setCollpased(false))
            if (currentTabState === 'Item')
              dispatch(setSearchResult(onParseKardexItem(searchKardex.auto)))
            else {
              setToastMessage(
                'Buscar Kardex Reservas',
                searchKardex.message,
                ToastTypes.Success,
              )
              dispatch(setSearchReserve(searchKardex.auto))
            }
          } else
            setToastMessage(
              'Buscar Kardex',
              searchKardex.message ?? 'Error al buscar kardex',
              ToastTypes.Warning,
            )
        } else
          setToastMessage(
            'Buscar Kardex',
            'Seleccione un ítem para consultar',
            ToastTypes.Warning,
          )
      } else setConfirmSearchAll(true)
    } else
      setToastMessage(
        'Buscar Kardex',
        'Seleccione un rango de fechas para consultar',
        ToastTypes.Warning,
      )
  }, [
    searchFilter,
    setToastMessage,
    currentTabState,
    playLoader,
    stopLoader,
    dispatch,
    onParseKardexItem,
    searchModeMobile,
  ])

  const onAddStore = useCallback(() => {
    if (searchFilter.local?.codigo > 0) {
      const indexStore = searchFilter.locales.findIndex((local) => {
        return local.codigo === searchFilter.local.codigo
      })
      if (indexStore === -1) {
        const stores = structuredClone(searchFilter.locales)
        stores.push({
          codigo: searchFilter.local.codigo,
          nombre: searchFilter.local.nombre,
        })
        onHandleChange(stores, 'locales')
      }
    }
  }, [onHandleChange, searchFilter])

  const onRemoveStore = useCallback(() => {
    if (rowIndex >= 0) {
      const stores = structuredClone(searchFilter.locales)
      stores.splice(rowIndex, 1)
      onHandleChange(stores, 'locales')
      setRowIndex(-1)
    }
  }, [onHandleChange, rowIndex, searchFilter])

  const handleButtonClick = useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.find:
          onHandleSearch()
          break
        default:
          break
      }
      dispatch(setCurrentFunction(''))
      dispatch(clearButtonClick('BUSQUEDA'))
    },
    [dispatch, onHandleSearch],
  )

  useEffect(() => {
    if (currentAction !== '') handleButtonClick(currentAction)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAction])

  useEffect(() => {
    if (
      accountingPeriod?.length > 0 &&
      (searchFilter.fechaDesde === '' || searchFilter.fechaHasta === '')
    ) {
      dispatch(
        setFilter({
          ...searchFilter,
          fechaDesde:
            DateUtils.apiDateToPickersDate(accountingPeriod[0]?.finicio) ?? '',
          fechaHasta:
            DateUtils.apiDateToPickersDate(accountingPeriod[0]?.fcierre) ?? '',
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountingPeriod])

  useEffect(() => {
    if (transfersChanges.length > 0) {
      const isCodeInArray = transfersChanges.some(
        (item) => item.codigo === searchFilter.codigo,
      )
      if (isCodeInArray) onHandleSearch()
      dispatch(clearChanges())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transfersChanges])

  return (
    <>
      {showModalStores && (
        <AdminModalLocales
          onChanged={(store) => {
            dispatch(setStore(store))
            setShowModalStores(false)
          }}
          onCancel={() => setShowModalStores(false)}
          show={showModalStores}
          modulo={2}
        />
      )}
      {showModalItems && (
        <MainModalItems
          transaccion="ventas"
          action="none"
          show={showModalItems}
          onCancel={() => setShowModalItems(false)}
          returnItem={(item: ItemVenta) => onAddItem(item)}
        />
      )}
      <Dialog ref={dialogRef} />
      <CCard>
        <CCardHeader
          onClick={() => dispatch(setCollpased(!collapsed))}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <strong> {!collapsed ? 'Filtro de Búsqueda' : ''} </strong>
          <CLink className="card-header-action">
            <CIcon icon={collapsed ? cilChevronBottom : cilChevronTop} />
          </CLink>
        </CCardHeader>
        <CCollapse visible={collapsed}>
          <RowContainer className="m-2">
            <CCol xs="12" md="7">
              <RowContainer>
                <CustomCol xs="12" md="12">
                  <Labeled label="Seleccionar ítem:">
                    <BuscarItemPedidoMemoLookUp
                      selected={searchFilter.itemLookup}
                      onChanged={(item: ItemPedido) => onAddItemLookup(item)}
                      cliente={null}
                      fecha={DateUtils.getCurrentDateAsString(formatoFechasApi)}
                      prioridadBarras={searchFrom.value}
                      modulo={EModulosAcatha.Kardex}
                      tipoAsociado={ETipoAsociado.Clientes}
                      allowMoreOption={true}
                      allowAdd={true}
                      allowClear={true}
                      allowEdit={true}
                    />
                  </Labeled>
                </CustomCol>
              </RowContainer>
              <div style={{ display: 'flex', gap: '8px' }}>
                {'Buscar por: '}
                <RadioGroup
                  name="selectSearckLookup"
                  layout="horizontal"
                  displayExpr={'label'}
                  items={optionsSearch}
                  value={searchFrom}
                  onValueChanged={(evt) => {
                    if (evt.event) setSearchFrom(evt.value)
                  }}
                />
              </div>
              <RowContainer>
                <CustomCol xs="12" md="6">
                  <Labeled label="Local:">
                    <div
                      style={{
                        display: 'flex',
                        gap: '2px',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <LocalesLookUp
                        onChanged={(store: LocalOption) =>
                          dispatch(setStore(store))
                        }
                        onChangedOptions={() => {}}
                        selected={searchFilter.local}
                      />
                      <Button
                        id="btnAdd"
                        className="me-1"
                        icon="add"
                        stylingMode="contained"
                        type="default"
                        onClick={() => setShowModalStores(true)}
                      />
                    </div>
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="6">
                  <Labeled label="Rango de Fechas:">
                    <div
                      style={{
                        display: 'flex',
                        gap: '2px',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <DateBox
                        displayFormat="dd/MM/yyyy"
                        value={searchFilter.fechaDesde}
                        onValueChanged={(evt) =>
                          onHandleChange(evt, 'fechaDesde')
                        }
                      />
                      <DateBox
                        displayFormat="dd/MM/yyyy"
                        value={searchFilter.fechaHasta}
                        onValueChanged={(evt) =>
                          onHandleChange(evt, 'fechaHasta')
                        }
                      />
                    </div>
                  </Labeled>
                </CustomCol>
              </RowContainer>
              <RowContainer>
                <CustomCol xs="12" md="5">
                  <CheckBox
                    style={{ marginTop: '10px' }}
                    text="Visualizar Bodega en Tránsito"
                    value={searchFilter.visualizarBodega}
                    onValueChanged={(evt) =>
                      onHandleChange(evt, 'visualizarBodega')
                    }
                  />
                </CustomCol>
                <CustomCol xs="12" md="7">
                  <RadioGroup
                    style={{ marginTop: '10px' }}
                    name="selectStockKardex"
                    layout="horizontal"
                    displayExpr={'label'}
                    items={optionsStock}
                    value={searchFilter.stock}
                    onValueChanged={(evt) => onHandleChange(evt, 'stock')}
                  />
                </CustomCol>
              </RowContainer>
            </CCol>
            {currentTabState === 'Todas' && (
              <CCol xs="12" md="5" style={{ marginTop: '10px' }}>
                <CRow>
                  <CustomCol xs="12" md="2">
                    <Button
                      id="btnAdd"
                      className="me-1"
                      icon="arrowright"
                      stylingMode="contained"
                      type="default"
                      onClick={() => onAddStore()}
                    />
                    <Button
                      style={{ marginTop: '6px' }}
                      id="btnAdd"
                      className="me-1"
                      icon="arrowleft"
                      stylingMode="contained"
                      type="default"
                      onClick={() => onRemoveStore()}
                    />
                  </CustomCol>
                  <CustomCol xs="12" md="8">
                    <DataGrid
                      selection={{ mode: 'single' }}
                      hoverStateEnabled={true}
                      dataSource={searchFilter.locales ?? []}
                      loadPanel={{ enabled: true }}
                      onRowClick={({ rowIndex }) => setRowIndex(rowIndex)}
                      showBorders={true}
                      showRowLines={true}
                    >
                      <Column
                        dataField="codigo"
                        caption=""
                        width="0%"
                        visible={false}
                      />
                      <Column
                        dataField="nombre"
                        caption="Locales"
                        width="100%"
                      />
                    </DataGrid>
                  </CustomCol>
                </CRow>
                <CRow>
                  <CustomCol xs="12" md="6">
                    <Labeled label="Grupo:">
                      <BuscarGrupoLookUp
                        selected={searchFilter.grupo}
                        onChanged={(group: GrupoInventario) =>
                          dispatch(setGroup(group))
                        }
                      />
                    </Labeled>
                  </CustomCol>
                  <CustomCol xs="12" md="6">
                    <Labeled label="Marca:">
                      <BuscarMarcaLookUp
                        selected={searchFilter.marca}
                        onChanged={(marca: MarcaInventario) =>
                          dispatch(setBrand(marca))
                        }
                      />
                    </Labeled>
                  </CustomCol>
                </CRow>
              </CCol>
            )}
          </RowContainer>
        </CCollapse>
      </CCard>
    </>
  )
}

export default memo(FiltroBusqueda)
