import React from 'react'
import { BuscarProveedorLookUp } from '../../../../components/busquedaProveedor/BuscarProveedorLookUp'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput'
import CustomCol from '../../../../../../views/componentes/colContainer'
import { TextBox } from 'devextreme-react'
import ProyectosLookUp from '../../../../../componentes/proyectosLookUp/proyectosLookUp'
import LocalesLookUp from '../../../../../componentes/localesLookUp'
import PuntosVentaLookUp from '../../../../../../modulos/componentes/lookUpPuntosVenta'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/store'
import { ProveedorBusqueda } from '../../../../store/types'
import { changeFilter } from '../../store/searchReducer'
import { PuntoVenta } from '../../../../../componentes/lookUpPuntosVenta/store/type'
import { LocalOption } from '../../../../../componentes/localesLookUp/store/type'
import { TProyectoOption } from '../../../../../componentes/proyectosLookUp/store/type'
import { FiltrosDocumentosCtasXPagar } from '../../types/types'
import { Seleccionado } from '../../../../../bancos/store/types'
import { ProveedoresService } from '../../../../services/proveedores.service'
import { puntosVentaService } from '../../../../../componentes/lookUpPuntosVenta/service/puntosVenta.service'
import { localesService } from '../../../../../componentes/localesLookUp/service/locales.service'

interface ICabeceraFiltroBusqueda extends React.PropsWithChildren {
  filtroBusqueda?: FiltrosDocumentosCtasXPagar
  getCabeceraFiltro?: (data: FiltrosDocumentosCtasXPagar) => void
  seleccionado?: Seleccionado
}

const CabeceraFiltroBusqueda: React.FunctionComponent<ICabeceraFiltroBusqueda> = (props) => {
  const { seleccionado } = props
  const dispatch = useDispatch()

  const [, setProveedor] = React.useState<ProveedorBusqueda>(null)
  const [, setNombre] = React.useState<string>("")
  const [, setLocal] = React.useState<LocalOption>(null)
  const [, setProyecto] = React.useState<TProyectoOption>(null)
  const [, setPuntoVenta] = React.useState<PuntoVenta>(null)

  const searchFilter = useSelector((state: RootState) => state.proveedores.documentosCuentasPorPagar.search.filter)
  const pointSale = useSelector((state: RootState) => state.global.puntoVenta)
  const localRedux = useSelector((state: RootState) => state.global.session.local.codigo)

  const onChangedPlace = React.useCallback(
    (place: LocalOption) => {
      setLocal(place)
      dispatch(
        changeFilter({
          ...searchFilter,
          local: place,
        }),
      )
    },
    [dispatch, searchFilter],
  )

  const onChangedProject = React.useCallback(
    (project: TProyectoOption) => {
      setProyecto(project)
      dispatch(
        changeFilter({
          ...searchFilter,
          proyecto: project,
        }),
      )
    },
    [dispatch, searchFilter],
  )

  const onChangedPonitSale = React.useCallback(
    (pointSale: PuntoVenta) => {
      setPuntoVenta(pointSale)
      dispatch(
        changeFilter({
          ...searchFilter,
          puntoVenta: pointSale,
        }),
      )
    },
    [dispatch, searchFilter],
  )

  const onChangedSupplier = React.useCallback(
    (supplier: ProveedorBusqueda) => {
      if (supplier) {
        setProveedor(supplier)
        setNombre(supplier.nombre)
        dispatch(
          changeFilter({
            ...searchFilter,
            proveedor: supplier,
            nombre: supplier.nombre,
          }),
        )
      } else {
        setNombre(null)
        setProveedor(null)
        dispatch(
          changeFilter({
            ...searchFilter,
            proveedor: null,
            nombre: '',
          }),
        )
      }

    },
    [dispatch, searchFilter],
  )

  const getData = React.useCallback(async (seleccionado) => {
    if (seleccionado.row !== null && seleccionado.row?.asociadoId) {
      const provCodigo = seleccionado.row?.asociadoId
      const prov = await ProveedoresService.getProveedor(provCodigo ?? 0)
      setProveedor(prov)
      setNombre(prov.nombre)
      dispatch(
        changeFilter({
          ...searchFilter,
          proveedor: prov,
          nombre: prov.nombre,
        }),
      )
    } else if (seleccionado.row !== null && seleccionado.row?.proveedorIdentificacion) {
      const provCodigo = seleccionado.row?.proveedorIdentificacion
      const prov = await ProveedoresService.getProveedor(provCodigo ?? 0)
      setProveedor(prov)
      setNombre(prov.nombre)
      dispatch(
        changeFilter({
          ...searchFilter,
          proveedor: prov,
          nombre: prov.nombre,
        }),
      )
    }
  }, [dispatch, searchFilter])

  const onGetDataHeadSearch = React.useCallback(async () => {
    if (pointSale !== null && pointSale !== undefined && pointSale !== "") {
      const data = await puntosVentaService.getPuntosVenta("Todos");
      if (data !== null && data['error'] === false) {
        const pv = data['auto'].find(p => p.nombre.toLocaleLowerCase() === pointSale.toLocaleLowerCase())
        setPuntoVenta(pv)
        dispatch(
          changeFilter({
            ...searchFilter,
            puntoVenta: pv
          }),
        )
      }
    }

    if (localRedux !== null && localRedux !== undefined && localRedux !== -1) {
      const data = await localesService.getLocales('Elija una opción')
      if (data !== null && data !== undefined && data['error'] === false) {
        const loc = data['auto'].find(l => l.localCodigo === localRedux)
        setLocal(loc)
        dispatch(
          changeFilter({
            ...searchFilter,
            local: loc
          }),
        )
      }
    }
  }, [dispatch, pointSale, searchFilter, localRedux])


  React.useEffect(() => {
    onGetDataHeadSearch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (seleccionado !== undefined && seleccionado !== null && seleccionado.row !== null && seleccionado.row !== undefined) {
      getData(seleccionado)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <RowContainer>
        <CustomCol xs="12" md="5">
          <Labeled label="Proveedor">
            <BuscarProveedorLookUp
              selected={searchFilter.proveedor ?? null}
              onChanged={onChangedSupplier}
              disabled={false}
              allowClear
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="4">
          <Labeled label="Sr.(es):">
            <TextBox value={searchFilter.nombre ?? ''} showClearButton />
          </Labeled>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="3">
          <Labeled label="Puntos de Venta">
            <PuntosVentaLookUp
              onChanged={onChangedPonitSale}
              onChangedOptions={() => { }}
              selected={searchFilter.puntoVenta ?? null}
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="3">
          <Labeled label="Proyecto">
            <ProyectosLookUp
              onChanged={onChangedProject}
              onChangedOptions={() => { }}
              selected={searchFilter.proyecto ?? null}
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="3">
          <Labeled label="Lugar o CC.">
            <LocalesLookUp
              onChanged={onChangedPlace}
              onChangedOptions={(opt) => {
                console.log('localidad ', opt)
              }}
              selected={searchFilter.local ?? null}
            />
          </Labeled>
        </CustomCol>
      </RowContainer>
    </>
  )
}

export default CabeceraFiltroBusqueda
