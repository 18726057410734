import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DateUtils } from '../../../../../../helpers/dateUtils'
import { RootState } from '../../../../../../store/store'
import { FiltroBusquedaState, SearchState } from '../../../../types/types'
import { ComprasSVListado } from '../types/types'
import {
  FETCH_STATUS,
  TiposComprobantesSLV,
} from '../../../../../../store/types'
import { utilidades } from '../../../../../../helpers/utilidades'
import { ComprasSVService } from '../../../services/comprasSV.service'

const initialState: SearchState<ComprasSVListado> = {
  filter: {
    fechaInicio: DateUtils.getCurrentDateAsString(),
    fechaFin: DateUtils.getCurrentDateAsString(),
    puntoVenta: '',
    activas: false,
    documento: '',
    pendientesAbutorizar: false,
    proveedor: null,
    tipoDoc: null,
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  resultados: [],
}

export const fetchCompraSV = createAsyncThunk<
  Array<ComprasSVListado>,
  FiltroBusquedaState
>('compras/searchCompras', async (filtro) => {
  try {
    const comprasSVList = await ComprasSVService.buscarComprasSV(
      {
        inicio: DateUtils.pickersDateToApiDate(filtro.fechaInicio),
        fin: DateUtils.pickersDateToApiDate(filtro.fechaFin),
      },
      filtro.documento,
      filtro?.proveedor ? filtro?.proveedor?.identificacion : undefined,
      filtro.tipoDoc,
      filtro?.formaPago ? filtro?.formaPago?.codigo.toString() : undefined,
      filtro.claveAcceso,
    )

    let data = comprasSVList

    if (comprasSVList.length > 0) {
      data = utilidades.sortJSON(comprasSVList, 'codigo', 'desc')
    }
    return data
  } catch (error) {
    return Promise.reject(error)
  }
})

const searchComprasSVSlice = createSlice({
  name: 'searchComprasSV',
  initialState: initialState,
  reducers: {
    resetState(state) {
      state = {
        ...initialState,
        filter: {
          ...initialState.filter,
          fechaInicio: DateUtils.getCurrentDateAsString(),
          fechaFin: DateUtils.getCurrentDateAsString(),
        },
      }
    },
    changeFilter(state, action: PayloadAction<FiltroBusquedaState>) {
      state.filter = action.payload
    },
    setCleanResult(state) {
      state.resultados = []
    },
    setResultados(state, action: PayloadAction<any>) {
      state.resultados = action.payload.resultados
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCompraSV.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchCompraSV.fulfilled, (state, { payload }) => {
      state.resultados = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchCompraSV.rejected, (state, { payload }) => {
      state.resultados = []
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
  },
})

export const selectFiltroBusquedaComprasSV = (state: RootState) => {
  return state.compras.comprasSV.compras.search.filter
}
export const selectResultadosBusquedaComprasSV = (state: RootState) => {
  return state.compras.comprasSV.compras.search.resultados
}
export const selectBusquedaComprasSVEstado = (state: RootState) => {
  return state.compras.comprasSV.compras.search.status
}
export const { setResultados, changeFilter, resetState, setCleanResult } =
  searchComprasSVSlice.actions
export const searchComprasSVReducer = searchComprasSVSlice.reducer
