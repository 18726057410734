import { library } from '@fortawesome/fontawesome-svg-core'
import { faSearch } from '@fortawesome/pro-duotone-svg-icons/faSearch'
import { faEyeSlash } from '@fortawesome/pro-duotone-svg-icons/faEyeSlash'
import { faPlusCircle } from '@fortawesome/pro-duotone-svg-icons/faPlusCircle'
import { faEdit } from '@fortawesome/pro-duotone-svg-icons/faEdit'
import { faUndo } from '@fortawesome/pro-duotone-svg-icons/faUndo'
import { faShoppingBasket } from '@fortawesome/pro-duotone-svg-icons/faShoppingBasket'

library.add(
  faSearch,
  faEyeSlash,
  faPlusCircle,
  faEdit,
  faUndo,
  faShoppingBasket,
)
