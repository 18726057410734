import { combineReducers } from '@reduxjs/toolkit'
import { menuReducer } from './menuReducer'
import { searchReducer } from './searchReducer'
import { datosEdicionReducer } from './editDataReducer'
import { tabsReducer } from './tabsReducer'
import { configuracionesReducer } from './configuracionesLiquidaciones'
import { LiquidacionesSVState } from '../types/types'

export const liquicionesSVReducer = combineReducers<LiquidacionesSVState>({
  menu: menuReducer,
  tabs: tabsReducer,
  search: searchReducer,
  editData: datosEdicionReducer,
  configuraciones: configuracionesReducer,
})
