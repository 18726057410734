import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ConfigMarcas, MarcasDatosEdicion } from "../types/types";



const initialState: ConfigMarcas = {
  aplicacion: 0
}

const configuracionMarcas = createSlice(
  {
    name: 'configMarcas',
    initialState: initialState,
    reducers: {
      setCodigoModulo(state, acion: PayloadAction<number>) {
        state.aplicacion = acion.payload;
      },
      setDatosEdicionBackup(
        state,
        action: PayloadAction<{
          key: string
          marca: MarcasDatosEdicion
        }>,
      ) {
        state[action.payload.key] = action.payload.marca
      },
      deleteEditDataBackup(state, action: PayloadAction<string>) {
        delete state[action.payload]
      },
    }
  }
)


export const configuracionesMarcas = configuracionMarcas.reducer;
export const { setCodigoModulo, setDatosEdicionBackup, deleteEditDataBackup } = configuracionMarcas.actions;