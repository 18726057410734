import React, { FC, useState } from 'react'
import Button from 'devextreme-react/button'
import ActionSheet from 'devextreme-react/action-sheet'

const buttons: any[] = [
  { text: 'Editar Ítem', icon: 'edit' },
  { text: 'Eliminar Ítem', icon: 'trash' },
  { text: 'Clonar Ítem', icon: 'selectall' },
  { text: 'Unificar Ítem', icon: 'isblank' },
]

interface IActionSheetCommandsMobileProps extends React.PropsWithChildren {
  // eslint-disable-next-line no-unused-vars
  onEdit: () => void
  // eslint-disable-next-line no-unused-vars
  onDelete: () => void
  // eslint-disable-next-line no-unused-vars
  onDuplicate: () => void
  // eslint-disable-next-line no-unused-vars
  onUnify: () => void
}

export const ActionSheetCommandsMobile: FC<IActionSheetCommandsMobileProps> = (
  props,
) => {
  const { onEdit, onDelete, onDuplicate, onUnify } = props
  const [visible, setVisible] = useState<boolean>(false)

  const onItemClick = React.useCallback(
    (item) => {
      const { itemData } = item
      switch (itemData.text) {
        case buttons[0].text:
          onDelete()
          break
        case buttons[1].text:
          onEdit()
          break
        case buttons[2].text:
          onDuplicate()
          break
        case buttons[3].text:
          onUnify()
          break
        default:
          break
      }
    },
    [onDelete, onDuplicate, onEdit, onUnify],
  )

  const keyId = `entryBlockRow${crypto.randomUUID()}`

  return (
    <>
      <Button
        id={keyId}
        icon="overflow"
        type="default"
        onClick={() => setVisible(!visible)}
      />
      <ActionSheet
        title="Tareas"
        usePopover={true}
        visible={visible}
        target={'#' + keyId}
        items={buttons}
        itemComponent={ActionSheetCommand}
        onItemClick={onItemClick}
        onVisibleChange={(value) => {
          if (value !== visible) setVisible(!visible)
        }}
      />
    </>
  )
}

const ActionSheetCommand: FC<any> = (props) => {
  return (
    <Button
      stylingMode="contained"
      type="default"
      text={props.data.text}
      icon={props.data.icon}
    />
  )
}
