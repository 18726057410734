import React from 'react'
import RowContainer from '../../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../../views/componentes/labeledInput/labeledInput'
import FIleInput from '../../../../../../../componentes/fileInput'
import { ItemImages } from '../../../../types/types'
import { setDataImagesToEdit } from '../../../../store/editDataReduce'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../../store/store'
import ImageViewer from '../../../../../../../componentes/imagen'
import { useConvertToBase64 } from '../../../../../../../../hooks/useGlobalHooks'
const Imagenes = (props) => {
  const { tabId } = props

  const dispatch = useDispatch()

  const dataToEditImages = useSelector(
    (state: RootState) => state.inventarios.items.editData[tabId].imagenes,
  )

  const convertToBase64 = useConvertToBase64()

  const onChangedImage = React.useCallback(
    async (imageIndex: number, eventImage: any) => {
      if (!eventImage) {
        const imagesArrayState = structuredClone(dataToEditImages)
        imagesArrayState[imageIndex - 1] = {
          show: false,
          name: '',
          base64: '',
        }
        dispatch(
          setDataImagesToEdit({
            key: tabId,
            images: imagesArrayState,
          }),
        )
      } else if (eventImage?.value?.length > 0) {
        let fileCoded: any = ''
        await convertToBase64(eventImage.value[0])
          .then((data) => {
            fileCoded = data
          })
          .catch((data) => {
            fileCoded = data
          })
        const imagesArrayState = structuredClone(dataToEditImages)
        imagesArrayState[imageIndex - 1] = {
          show: true,
          name: `${crypto.randomUUID()}.jpg`,
          base64: fileCoded,
        }
        dispatch(
          setDataImagesToEdit({
            key: tabId,
            images: imagesArrayState,
          }),
        )
      }
    },
    [convertToBase64, dataToEditImages, dispatch, tabId],
  )

  return (
    <RowContainer className="m-2">
      <strong>
        Las imagenes deben estar en formato .jpg. Tamaño recomendado: 450x450
        px. Tamaño máximo del archivo: 1Mb
      </strong>
      <RowContainer>
        <CustomCol xs="12" md="4">
          <Labeled label="Imagen 1">
            <ImageViewer
              show={dataToEditImages[0].show}
              source={dataToEditImages[0].base64}
              alt={'imagen1'}
            />
            <FIleInput
              accept={'.jpg'}
              allowedFileExtensions={['.jpg', '.jpeg']}
              onChanged={(dataImage: any) => onChangedImage(1, dataImage)}
              showDownloadDemo={false}
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="4">
          <Labeled label="Imagen 2">
            <ImageViewer
              show={dataToEditImages[1].show}
              source={dataToEditImages[1].base64}
              alt={'imagen2'}
            />
            <FIleInput
              accept={'.jpg'}
              allowedFileExtensions={['.jpg', '.jpeg']}
              onChanged={(dataImage: any) => onChangedImage(2, dataImage)}
              showDownloadDemo={false}
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="4">
          <Labeled label="Imagen 3">
            <ImageViewer
              show={dataToEditImages[2].show}
              source={dataToEditImages[2].base64}
              alt={'imagen3'}
            />
            <FIleInput
              accept={'.jpg'}
              allowedFileExtensions={['.jpg', '.jpeg']}
              onChanged={(dataImage: any) => onChangedImage(3, dataImage)}
              showDownloadDemo={false}
            />
          </Labeled>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="4">
          <Labeled label="Imagen 4">
            <ImageViewer
              show={dataToEditImages[3].show}
              source={dataToEditImages[3].base64}
              alt={'imagen4'}
            />
            <FIleInput
              accept={'.jpg'}
              allowedFileExtensions={['.jpg', '.jpeg']}
              onChanged={(dataImage: any) => onChangedImage(4, dataImage)}
              showDownloadDemo={false}
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="4">
          <Labeled label="Imagen 5">
            <ImageViewer
              show={dataToEditImages[4].show}
              source={dataToEditImages[4].base64}
              alt={'imagen5'}
            />
            <FIleInput
              accept={'.jpg'}
              allowedFileExtensions={['.jpg', '.jpeg']}
              onChanged={(dataImage: any) => onChangedImage(5, dataImage)}
              showDownloadDemo={false}
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="4">
          <Labeled label="Imagen 6">
            <ImageViewer
              show={dataToEditImages[5].show}
              source={dataToEditImages[5].base64}
              alt={'imagen6'}
            />
            <FIleInput
              accept={'.jpg'}
              allowedFileExtensions={['.jpg', '.jpeg']}
              onChanged={(dataImage: any) => onChangedImage(6, dataImage)}
              showDownloadDemo={false}
            />
          </Labeled>
        </CustomCol>
      </RowContainer>
    </RowContainer>
  )
}

export default Imagenes

export const defaultImagenes: Array<ItemImages> = Array(6).fill({
  show: false,
  name: '',
  base64: '',
})
