import {
  CCard,
  CCardBody,
  CCol,
  CModal,
  CModalBody,
  CModalHeader,
  CRow,
} from '@coreui/react-pro'

export interface IPopupMensajesProps extends React.PropsWithChildren {
  messages: any
  show: boolean
  onClose: () => void
}

const PopupMensajes = (props: IPopupMensajesProps) => {
  const { onClose, show, messages } = props

  const generarContenido = (contenido) => {
    const clean: any = contenido
    return clean
  }

  return (
    <>
      <CModal
        id="popupMensajes"
        key="popupMensajes"
        visible={show}
        onClose={() => onClose()}
        size="lg"
        backdrop={'static'}
        // closeOnBackdrop={false}
      >
        <CModalHeader closeButton>{'Acatha Informa'}</CModalHeader>
        <CModalBody>
          <CCard>
            <CCardBody
              className="notification_image"
              style={{ maxHeight: '85vh' }}
            >
              {messages.length > 0 &&
                messages.map(function (key, i) {
                  return (
                    <div key={i}>
                      <CRow>
                        <CCol>
                          <div>
                            {key['titulo'] !== undefined ? key['titulo'] : ''}
                          </div>
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol>
                          <div style={{ maxHeight: '85vh' }}>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: generarContenido(key['descripcion']),
                              }}
                              style={{ margin: '0px', display: 'block' }}
                            />
                          </div>
                        </CCol>
                      </CRow>
                    </div>
                  )
                })}
            </CCardBody>
          </CCard>
        </CModalBody>
      </CModal>
    </>
  )
}

export default PopupMensajes
