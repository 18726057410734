import { library } from '@fortawesome/fontawesome-svg-core'

import { faCalculatorAlt } from '@fortawesome/pro-solid-svg-icons/faCalculatorAlt'
import { faDollyFlatbed } from '@fortawesome/pro-solid-svg-icons/faDollyFlatbed'
import { faDollyFlatbedAlt } from '@fortawesome/pro-solid-svg-icons/faDollyFlatbedAlt'
import { faExclamation } from '@fortawesome/pro-solid-svg-icons/faExclamation'
import { faFileInvoiceDollar } from '@fortawesome/pro-solid-svg-icons/faFileInvoiceDollar'
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown'
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons/faCaretRight'
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight'
import { faLightbulbOn } from '@fortawesome/pro-solid-svg-icons/faLightbulbOn'
import { faLightbulb } from '@fortawesome/pro-solid-svg-icons/faLightbulb'
import { faEye } from '@fortawesome/pro-solid-svg-icons/faEye'
import { faEyeSlash } from '@fortawesome/pro-solid-svg-icons/faEyeSlash'
import { faCashRegister } from '@fortawesome/pro-solid-svg-icons/faCashRegister'
import { faBookOpen } from '@fortawesome/pro-solid-svg-icons/faBookOpen'
import { faSpinner } from '@fortawesome/pro-solid-svg-icons/faSpinner'
import { faAmbulance } from '@fortawesome/pro-solid-svg-icons/faAmbulance'
import { faDollarSign } from '@fortawesome/pro-solid-svg-icons/faDollarSign'
import { faMoneyCheck } from '@fortawesome/pro-solid-svg-icons/faMoneyCheck'
import { faCreditCard } from '@fortawesome/pro-solid-svg-icons/faCreditCard'
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons/faTriangleExclamation'
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons/faCircleCheck'
import { faMoneyBillTransfer } from '@fortawesome/pro-solid-svg-icons/faMoneyBillTransfer'
import { faAccentGrave } from '@fortawesome/pro-solid-svg-icons/faAccentGrave'
import { faO } from '@fortawesome/pro-solid-svg-icons/faO'
import { faFileInvoice } from '@fortawesome/pro-solid-svg-icons/faFileInvoice'
import { faFileSignature } from '@fortawesome/pro-solid-svg-icons/faFileSignature'
import { faFileCertificate } from '@fortawesome/pro-solid-svg-icons/faFileCertificate'

library.add(
  faCalculatorAlt,
  faDollyFlatbed,
  faDollyFlatbedAlt,
  faExclamation,
  faFileInvoiceDollar,
  faCaretDown,
  faCaretRight,
  faChevronRight,
  faLightbulbOn,
  faLightbulb,
  faEye,
  faEyeSlash,
  faCashRegister,
  faBookOpen,
  faSpinner,
  faAmbulance,
  faDollarSign,
  faMoneyCheck,
  faCreditCard,
  faTriangleExclamation,
  faCircleCheck,
  faMoneyBillTransfer,
  faAccentGrave,
  faO,
  faFileInvoice,
  faFileSignature,
  faFileCertificate
)
