import { combineReducers } from '@reduxjs/toolkit'
import { WindowLiquidacionState } from '../../../types/generics'
import { datosEdicionReducer } from './editDataReducer'
import { menuReducer } from './menuReducer'
import { searchReducer } from './searchReducer'
import { tabsReducer } from './tabsReducer'
import { configuracionesReducer } from './configuracionesLiquidacionesReducer'

export const liquidacionesReducer = combineReducers<WindowLiquidacionState>({
 menu: menuReducer,
 tabs: tabsReducer,
 search: searchReducer,
 editData: datosEdicionReducer,
 configuraciones: configuracionesReducer,
})
