import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../../../../store/store'
import { ButtonTypes } from '../../../../../../../views/componentes/globalMenu/types'
import { StatesEdition, TabTypes } from '../../../../../types/enums'
import { CustomButtons, CustomDictionary } from '../../../../../types/generics'
import { cuadresCloseTab } from '../../../store/reducers'
import {
  TabStateOrdenProforma,
  TabsStateOrdenProforma,
  OrdenProformaListado,
} from '../../../type/types'
import { OrdenProformaButtons } from '../components/proforma/proforma'

const initialState: TabsStateOrdenProforma<OrdenProformaListado> = {
  current: 'BUSQUEDA',
  tabs: {
    BUSQUEDA: {
      id: 0,
      canClose: false,
      editStatus: StatesEdition.doesNotApply,
      globalButtonClick: ButtonTypes.none,
      tittle: 'Búsqueda',
      type: TabTypes.busqueda,
      imprimir: false,
      tabKey: 'BUSQUEDA',
      buttons: {},
    },
  },
}

const existeTab = (
  tabs: CustomDictionary<TabStateOrdenProforma<any>>,
  id: number,
) => {
  for (const key in tabs) {
    const tab = tabs[key]
    if (tab.id === id) return key
  }
}

const tabsOrdenPedidoSlice = createSlice({
  name: 'ordenProformasTabs',
  initialState: initialState,
  reducers: {
    cuadresCloseTab,
    closeTab(state, action: PayloadAction<string | number>) {
      // if (typeof action.payload == "number") {
      //   if (state.current === action.payload) {
      //     //current tab closed
      //     state.current = action.payload - 1;
      //   }
      //   state.tabs.splice(action.payload, 1);
      //   state.current = state.current + 0;
      // } else {
      //   const indexTabToClose = state.tabs.findIndex(
      //     (x) => x.tabKey === action.payload
      //   );
      //   if (state.current === indexTabToClose) {
      //     //current tab closed
      //     state.current = indexTabToClose - 1;
      //   }
      //   state.tabs.splice(indexTabToClose, 1);
      //   state.current = state.current + 0;
      // }
    },
    openTab(
      state,
      action: PayloadAction<{
        key: string
        proforma?: OrdenProformaListado
        templateMode?: boolean
      }>,
    ) {
      if (
        action.payload.proforma === undefined ||
        action.payload.templateMode
      ) {
        const tab: TabStateOrdenProforma<OrdenProformaListado> = {
          id: -1,
          canClose: true,
          editStatus: StatesEdition.new,
          globalButtonClick: ButtonTypes.none,
          tittle: 'Nueva',
          type: TabTypes.documento,
          imprimir: false,
          tabKey: action.payload.key,
          info: {
            codigo:
              action.payload.templateMode && action.payload.proforma
                ? action.payload.proforma.codigo
                : 0,
          },
          buttons: OrdenProformaButtons,
          templateMode: action.payload.templateMode,
        }
        state.tabs[action.payload.key] = tab
        state.current = action.payload.key
      } else {
        const tabKey = existeTab(state.tabs, action.payload.proforma.codigo)
        if (tabKey) {
          state.current = tabKey
        } else {
          const tab: TabStateOrdenProforma<OrdenProformaListado> = {
            tabKey: action.payload.key,
            id: action.payload.proforma.codigo,
            tittle: `(${action.payload.proforma.numero})- ${
              action.payload.proforma.fechaEntrega
            } ${action.payload.proforma.horaEntrega}-${String(
              action.payload.proforma.numero,
            ).toUpperCase()}`,
            canClose: true,
            editStatus: StatesEdition.save,
            type: TabTypes.documento,
            info: {
              codigo: action.payload.proforma.codigo,
              info: action.payload.proforma,
            },
            globalButtonClick: ButtonTypes.none,
            imprimir: false,
            buttons: OrdenProformaButtons,
          }
          state.tabs[action.payload.key] = tab
          state.current = action.payload.key
        }
      }
    },
    changeEditStatus(
      state,
      action: PayloadAction<{
        tabKey: string
        estado: StatesEdition
        info?: OrdenProformaListado
        buttons: CustomButtons
      }>,
    ) {
      const { estado, tabKey, info, buttons } = action.payload
      state.tabs[tabKey].editStatus = estado
      state.tabs[tabKey].currentExecutingAction = undefined
      state.tabs[tabKey].buttons = buttons
      if (info) {
        state.tabs[tabKey].info = {
          codigo: info.codigo,
          info: action.payload.info,
        }
        state.tabs[tabKey].id = action.payload.info.codigo
        state.tabs[
          tabKey
        ].tittle = `${action.payload.info.fechaEntrega}-${action.payload.info.numero}`
      }
    },
    buttonClick(
      state,
      action: PayloadAction<{ tabKey: string; button: ButtonTypes }>,
    ) {
      state.tabs[action.payload.tabKey].globalButtonClick =
        action.payload.button
    },
    clearButtonClick(state, action: PayloadAction<string>) {
      if (Object.prototype.hasOwnProperty.call(state.tabs, action.payload)) {
        state.tabs[action.payload].globalButtonClick = ButtonTypes.none
      }
    },
    changeCurrentTab(state, action: PayloadAction<string>) {
      if (Object.prototype.hasOwnProperty.call(state.tabs, action.payload)) {
        state.current = action.payload
      }
    },
    setButtons(
      state,
      action: PayloadAction<{ tabKey: string; buttons: CustomButtons }>,
    ) {
      const { buttons, tabKey } = action.payload
      state.tabs[tabKey].buttons = buttons
      state.tabs[tabKey].currentExecutingAction = undefined
    },

    setCurrentExecutingAction(
      state,
      action: PayloadAction<{
        tabKey: string
        buttonType: ButtonTypes | undefined
      }>,
    ) {
      const { buttonType, tabKey } = action.payload
      state.tabs[tabKey].currentExecutingAction = buttonType
    },
  },
})

export const selectTabsventas = (state: RootState) => {
  return state.ventas.proformas.ordenProforma.tabs
}
export const {
  cuadresCloseTab: closeTab,
  openTab,
  changeCurrentTab,
  changeEditStatus,
  clearButtonClick,
  buttonClick,
  setButtons,
  setCurrentExecutingAction,
} = tabsOrdenPedidoSlice.actions
export const tabsReducer = tabsOrdenPedidoSlice.reducer
