import React from 'react'
import SelectBox from 'devextreme-react/select-box'
import { PlanesServices } from './services'
import { PlanFacturacion } from './types/types'

interface TiposPlanesLookUpProps extends React.PropsWithChildren {
  selected: PlanFacturacion | null
  // eslint-disable-next-line no-unused-vars
  onChanged: (newValue: PlanFacturacion | null) => void
  // eslint-disable-next-line no-unused-vars
  onChangedOptions?: (newValue: Array<PlanFacturacion> | []) => void
  disabled?: boolean
}

const TiposPlanesLookUp: React.FC<TiposPlanesLookUpProps> = (props) => {
  const { selected, onChanged, disabled, onChangedOptions } = props
  const selectRef = React.useRef<any>()
  const [options, setOptions] = React.useState<Array<PlanFacturacion> | []>([])

  const loadDatos = React.useCallback(async () => {
    const data = await PlanesServices.getPlanes()
    if (data.error === false) {
      let optionsLookup = []
      if (data.auto.length > 0) {
        optionsLookup = data.auto.map((option: any) => {
          return {
            id: option?.id ?? -1,
            codigoBase: option?.codigoBase ?? -1,
            codigoItem: option?.codigoItem ?? '',
            codigoPeriodo: option?.codigoPeriodo ?? -1,
            codigoCategoria: option?.codigoCategoria ?? -1,
            cadena: option?.cadena ?? '',
            plan: option?.plan ?? '',
          }
        })
      }
      optionsLookup.unshift({
        id: -1,
        codigoBase: -1,
        codigoItem: '',
        codigoPeriodo: -1,
        codigoCategoria: -1,
        cadena: '',
        plan: 'Elija un plan',
      })
      setOptions(optionsLookup)
      if (onChangedOptions) {
        onChangedOptions(optionsLookup)
      }
      const selectedIndex = optionsLookup.findIndex((option) => {
        return option.codigoBase === selected?.codigoBase
      })
      onChanged(optionsLookup[selectedIndex] ?? optionsLookup[0])
    }
  }, [onChanged, onChangedOptions, selected])

  const getItemDisplayExpr = React.useCallback((item: any) => {
    return item ? `${item.plan}` : ''
  }, [])

  const onLookUpValueChanged = React.useCallback(
    (evt: any) => {
      if (evt.event !== undefined) {
        onChanged(evt.value)
      }
    },
    [onChanged],
  )

  const onSelectBoxFocusIn = React.useCallback((e) => {
    e.element.querySelector('input.dx-texteditor-input').select()
  }, [])

  const refreshSelected = React.useCallback(() => {
    if (options && options.length > 0) {
      const selectedIndex = options.findIndex((option: PlanFacturacion) => {
        return option.codigoBase === selected?.codigoBase
      })
      onChanged(options[selectedIndex] ?? options[0])
    }
  }, [onChanged, options, selected])

  React.useEffect(() => {
    loadDatos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    refreshSelected()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  return (
    <SelectBox
      id="tiposImpuestosSelect"
      ref={selectRef}
      items={options ?? []}
      searchEnabled
      displayExpr={getItemDisplayExpr}
      searchExpr={['plan']}
      searchTimeout={500}
      value={selected}
      onValueChanged={onLookUpValueChanged}
      disabled={disabled}
      onFocusIn={onSelectBoxFocusIn}
    >
      {props.children}
    </SelectBox>
  )
}
export default React.memo(TiposPlanesLookUp)
