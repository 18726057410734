import { combineReducers } from '@reduxjs/toolkit'
import { tabsReducer } from './tabsReducer'
import { generalReducer } from './generalReducer'
import { MarcasState } from '../types/types'
import { dataEditReducer } from './editDataReducer'
import { searchReducer } from './serachReducer'
import { configuracionesMarcas } from './configReducer'
import { menuReducer } from './menuReducer'

export const MarcasReducer = combineReducers<MarcasState>({
  general: generalReducer,
  tabs: tabsReducer,
  editData: dataEditReducer,
  search: searchReducer,
  config: configuracionesMarcas,
  menu: menuReducer
})
