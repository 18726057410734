import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OptionGrupos, Puntoventa } from "../../../store/types";
import { ModalOpen } from "../../contabilidad/general/store/types";
import { SeleccionadoTableItem } from "../store/types";
import { OptionGrupoPais, GeneralBancosState, PeriodoActivo, OptionGruposDetalle, OptionProyectos } from "./types";

const formInit = {
  id: "",
  aplicacion: "",
  formulario: {},
  seleccionado: {
    index: null,
    row: []
  }
}

const initialState: GeneralBancosState = {
  dpPaises: [],
  dpProvincias: [],
  dpCiudades: [],
  dpTipoBanco: [],
  dpEstados: [],
  dpTipoCuentas: [],
  dpPeriodoActivo: [],
  dpPeriodosContables: [],
  proyectos: [],
  proyectosBack: [],
  locales: [],
  localesBack: [],
  modalDisplay: {
    open: false,
    name: '',
    parametros: [],
    codModulo: 0
  },
  codModulo: 0,
  actionDisplay: '',
  currentAction: 'Buscar',
  currentFunction: "",
  seleccionado: {
    index: null,
    row: []
  },
  formulario: {},
  formularios: {},
  dpPuntosVenta: [],
}

const generalBancosSlice = createSlice({
  name: "generalBancos",
  initialState: initialState,
  reducers: {
    setDpPaises(state, action: PayloadAction<Array<OptionGrupoPais>>) {
      state.dpPaises = action.payload
    },
    setDpProvincias(state, action: PayloadAction<Array<OptionGrupos>>) {
      state.dpProvincias = action.payload
    },
    setDpCiudades(state, action: PayloadAction<Array<OptionGrupos>>) {
      state.dpCiudades = action.payload
    },
    setDpTipoBanco(state, action: PayloadAction<Array<OptionGrupos>>) {
      state.dpTipoBanco = action.payload
    },
    setDpEstados(state, action: PayloadAction<Array<OptionGrupos>>) {
      state.dpEstados = action.payload
    },
    setDpTipoCuentas(state, action: PayloadAction<Array<OptionGrupos>>) {
      state.dpTipoCuentas = action.payload
    },
    setDpPeriodoActivo(state, action: PayloadAction<Array<PeriodoActivo>>) {
      state.dpPeriodoActivo = action.payload
    },
    setDpPeriodosContables(state, action: PayloadAction<Array<OptionGruposDetalle>>) {
      state.dpPeriodosContables = action.payload
    },
    setProyectos(state, action: PayloadAction<Array<OptionProyectos>>) {
      state.proyectos = action.payload
    },
    setProyectosBack(state, action: PayloadAction<Array<OptionProyectos>>) {
      state.proyectosBack = action.payload
    },
    setLocales(state, action: PayloadAction<Array<OptionGrupos>>) {
      state.locales = action.payload
    },
    setLocalesBack(state, action: PayloadAction<Array<OptionGrupos>>) {
      state.localesBack = action.payload
    },
    setModalDisplay(state, action: PayloadAction<ModalOpen>) {
      state.modalDisplay = action.payload
    },
    setCodModulo(state, action: PayloadAction<number>) {
      state.codModulo = action.payload
    },
    setActionDisplay(state, action: PayloadAction<string>) {
      state.actionDisplay = action.payload
    },
    setCurrentAccionGlobal(state, action: PayloadAction<any>) {
      state.currentAction = action.payload
    },
    setCurrentFunctionGlobal(state, action: PayloadAction<string>) {
      state.currentFunction = action.payload
    },
    setSeleccionarDato(state, action: PayloadAction<SeleccionadoTableItem>) {
      const toAdd = {
        index: action.payload.index,
        row: action.payload.row
      }
      state.seleccionado = toAdd
    },
    setResetSeleccion(state, action: PayloadAction<any>) {
      const toAdd = {
        index: null,
        row: []
      }
      state.seleccionado = toAdd
    },
    setFormulario(state, action: PayloadAction<any>) {
      state.formulario = action.payload
    },
    setFormularios(state, action: PayloadAction<any>) {
      const form = {
        id: action.payload.id,
        aplicacion: action.payload.aplicacion,
        formulario: action.payload.formulario,
        seleccionado: action.payload.seleccionado,
      }
      state.formularios[action.payload.id] = form
    },
    setResetFormularioGeneral(state, action) {
      delete state.formularios[action.payload];
    },
    setResetCurrentFormulario(state, action) {
      state.formularios[action.payload.id] = formInit;
    },
    setPuntosVentaBancos(state, action: PayloadAction<Array<Puntoventa>>) {
      state.dpPuntosVenta = action.payload;
    },
  }
})
export const {
  setProyectos, setProyectosBack, setLocalesBack, setLocales, setDpPeriodosContables, setDpPaises, setDpProvincias, setDpCiudades, setDpTipoBanco,
  setDpEstados, setDpTipoCuentas, setDpPeriodoActivo, setModalDisplay, setCodModulo, setActionDisplay, setCurrentAccionGlobal, setResetCurrentFormulario,
  setCurrentFunctionGlobal, setSeleccionarDato, setResetSeleccion, setFormulario, setFormularios, setResetFormularioGeneral, setPuntosVentaBancos
} = generalBancosSlice.actions;
export const GeneralBancosReducer = generalBancosSlice.reducer;

