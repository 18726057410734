import React from 'react'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput'
import { Draggable, SpeedDialAction, TextBox } from 'devextreme-react'
import { FilterSeriesState, SeriesList, TitleTabSeries } from '../../types/types'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/store'
import {
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCollapse,
  CLink,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
} from '@coreui/react-pro'
import { changeCurrentSearchTab, changeFilter, setCollapsed } from '../../store/serachReducer'
import KardexSerie from './kardexSerie'
import SeriesComprobante, { optionsSeries } from './seriesComprobante'
import { TabState } from '../../../../../../store/genericTabTypes'
import { getLocales } from '../../../../../componentes/localesLookUp/helper/helper'
import BlockUi from '../../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../../views/componentes/loadingindicator/loadingindicator'
import { FETCH_STATUS } from '../../../../../../store/types'
import { isMobile } from 'react-device-detect'
import TableLoader from '../../../../../ventas/components/ventas/busquedaVentas/TableLoader'
import CIcon from '@coreui/icons-react'
import { cilChevronBottom, cilChevronTop } from '@coreui/icons'
import { clearButtonClick } from '../../store/tabsReducer'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'

interface ISearchProps extends React.PropsWithChildren {
  tab: TabState<SeriesList>
  tabId: string
}

enum VistasBusqueda {
  Filtros,
  ResultadosBusqueda,
  Resumen,
  Loading,
  Error
}

const draggingGroupName = 'appointmentsGroup';

const SearchSeries: React.FunctionComponent<ISearchProps> = (props) => {
  const { tabId, tab } = props

  const dispatch = useDispatch()
  const [vistaActual, setVistaActual] = React.useState<VistasBusqueda>(VistasBusqueda.Filtros)

  const collapsed = useSelector(
    (state: RootState) =>
      state.inventarios.series.search.colapsado,
  )
  const tabsSearchseries = useSelector((state: RootState) => {
    return state.inventarios.series.search
  })

  const currentTab = useSelector((state: RootState) => {
    return state.inventarios.series.tabs.current
  })

  const current = useSelector((state: RootState) => {
    return state.inventarios.series.search.currentTab
  })

  const searchFilter = useSelector((state: RootState) => {
    return state.inventarios.series.search.filter
  })
  const seriesLoader = useSelector((state: RootState) => {
    return state.inventarios.series.general
  })
  const estadoBusqueda = useSelector((state: RootState) => {
    return state.inventarios.series.search.status
  })

  const estadoBusquedaCom = useSelector((state: RootState) => {
    return state.inventarios.series.search.statusCom
  })
  const [reporte, setReporte] = React.useState<null | 'Viewer' | 'Designer'>(
    null,
  )
  const [reporteCom, setReporteCom] = React.useState<null | 'Viewer' | 'Designer'>(
    null,
  )
  const initSearch = React.useCallback(async () => {
    const data = { ...initialFilterState }
    const resLocal = await getLocales('Elija una opcion')

    data.serie = ''
    data.min = ''
    data.verBodegaTransito = false
    data.ingresosEgreso = ''
    data.compraCodigo = 0
    data.compraDescripcion = ''
    data.ventaCodigo = 0
    data.tipo = optionsSeries[0]
    data.ventaDescripcion = ''
    data.transferencia = ''
    data.item = null
    data.local = resLocal.length > 0 ? resLocal[0] : null

    dispatch(changeFilter({
      ...data
    }))
  }, [dispatch])

  const handleButtonClick = React.useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.print:
          if (current === TitleTabSeries.series) {
            setReporteCom('Viewer')
          } else if (current === TitleTabSeries.kardex) {
            setReporte('Viewer')
          }
          break
        case ButtonTypes.print_design:
          if (current === TitleTabSeries.series) {
            setReporteCom('Designer')
          } else if (current === TitleTabSeries.kardex) {
            setReporte('Designer')
          }
          break
        default:
          break
      }
      dispatch(clearButtonClick(tabId))
    },
    [
      dispatch,
      tabId,
      current
    ],
  )

  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab.globalButtonClick])

  React.useEffect(() => {
    initSearch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    switch (estadoBusqueda) {
      case FETCH_STATUS.IDDLE:
        setVistaActual(VistasBusqueda.Filtros);
        break;
      case FETCH_STATUS.LOADING:
        setVistaActual(VistasBusqueda.Loading);
        break;
      case FETCH_STATUS.SUCCESS:
        setVistaActual(VistasBusqueda.ResultadosBusqueda);
        break;
      case FETCH_STATUS.FAILED:
        setVistaActual(VistasBusqueda.Error);
        break;
      default:
        break;
    }
  }, [estadoBusqueda])

  React.useEffect(() => {
    switch (estadoBusquedaCom) {
      case FETCH_STATUS.IDDLE:
        setVistaActual(VistasBusqueda.Filtros);
        break;
      case FETCH_STATUS.LOADING:
        setVistaActual(VistasBusqueda.Loading);
        break;
      case FETCH_STATUS.SUCCESS:
        setVistaActual(VistasBusqueda.ResultadosBusqueda);
        break;
      case FETCH_STATUS.FAILED:
        setVistaActual(VistasBusqueda.Error);
        break;
      default:
        break;
    }
  }, [estadoBusquedaCom])

  const onFilterBusqueda = () => {
    return (
      <>
        <CCardHeader
          onClick={() => dispatch(setCollapsed(!collapsed))}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <strong> {collapsed ? '' : 'Filtro de Búsqueda'} </strong>
          <CLink className="card-header-action">
            <CIcon icon={collapsed ? cilChevronBottom : cilChevronTop} />
          </CLink>
        </CCardHeader>
        <CCollapse visible={collapsed}>
          <CCardBody>
            <RowContainer>
              <CustomCol xs="12" md="4">
                <Labeled label="Serie:">
                  <TextBox
                    value={searchFilter?.serie ?? ''}
                    onValueChanged={(data) => {
                      if (data.event !== null && data.event !== undefined) {
                        dispatch(changeFilter({
                          ...searchFilter,
                          serie: data?.value
                        }))
                      }
                    }}
                    showClearButton={true}
                  />
                </Labeled>
              </CustomCol>
              <CustomCol xs="12" md="3">
                <Labeled label="Min:">
                  <TextBox
                    value={searchFilter?.min ?? ''}
                    onValueChanged={(data) => {
                      if (data.event !== null && data.event !== undefined) {
                        dispatch(changeFilter({
                          ...searchFilter,
                          serie: data?.value
                        }))
                      }
                    }}
                    showClearButton={true}
                  />
                </Labeled>
              </CustomCol>
            </RowContainer>
          </CCardBody>
        </CCollapse>
      </>
    )
  }

  if (isMobile) {
    return (
      <>
        <CCard>
          {vistaActual === VistasBusqueda.Filtros &&

            (
              <>
                {onFilterBusqueda()}
                <CNav variant="tabs">
                  {tabsSearchseries.tabs.map((tab, index) => (
                    <CNavItem key={index}>
                      <CNavLink
                        onClick={() =>
                          dispatch(
                            changeCurrentSearchTab(tabsSearchseries.tabs[index]),
                          )
                        }
                        active={tabsSearchseries.currentTab === tab}
                      >
                        {tab}
                      </CNavLink>
                    </CNavItem>
                  ))}
                </CNav>
              </>
            )
          }
          {vistaActual === VistasBusqueda.ResultadosBusqueda &&
            (<>
              <CTabContent
                style={{
                  overflowY: 'unset',
                  width: '100%',
                  marginBottom: '10px',
                }}
              >
                <CTabPane
                  role="tabpanel"
                  aria-labelledby="pendientes-tab"
                  visible={tabsSearchseries.currentTab === tabsSearchseries.tabs[0]}
                >
                  <KardexSerie setReporte={setReporte} reporte={reporte} />
                </CTabPane>
                <CTabPane
                  role="tabpanel"
                  aria-labelledby="pagados-tab"
                  visible={tabsSearchseries.currentTab === tabsSearchseries.tabs[1]}
                >
                  <SeriesComprobante setReporte={setReporteCom} reporte={reporteCom} />
                </CTabPane>
              </CTabContent>
            </>)
          }
          {vistaActual === VistasBusqueda.Error &&
            <></>
          }
          {vistaActual === VistasBusqueda.Loading &&
            <CCardFooter>
              <TableLoader />
            </CCardFooter>
          }
          {
            currentTab === tabId && (
              <Draggable
                id="list"
                data="dropArea"
                group={draggingGroupName}
              >
                <SpeedDialAction
                  icon="filter"
                  label='Filtros'
                  visible={true}
                  index={1}
                  onClick={() => {
                    setVistaActual(VistasBusqueda.Filtros);
                  }}
                />
                <SpeedDialAction
                  icon="search"
                  label='Busqueda'
                  visible={true}
                  index={2}
                  onClick={() => {
                    setVistaActual(VistasBusqueda.ResultadosBusqueda);
                  }}
                />
              </Draggable>
            )
          }
        </CCard>
      </>
    )
  }



  return (
    <>
      <BlockUi
        tag="div"
        loader={LoadingIndicator}
        blocking={seriesLoader.loader.show}
        message={seriesLoader.loader.mensaje}
      >
        <CCard>
          {onFilterBusqueda()}
          <CNav variant="tabs" role="tablist" className="mt-4">
            <CNav variant="tabs">
              {tabsSearchseries.tabs.map((tab, index) => (
                <CNavItem key={index}>
                  <CNavLink
                    onClick={() =>
                      dispatch(
                        changeCurrentSearchTab(tabsSearchseries.tabs[index]),
                      )
                    }
                    active={tabsSearchseries.currentTab === tab}
                  >
                    {tab}
                  </CNavLink>
                </CNavItem>
              ))}
            </CNav>
            <CTabContent
              style={{
                overflowY: 'unset',
                width: '100%',
                marginBottom: '10px',
              }}
            >
              <CTabPane
                role="tabpanel"
                aria-labelledby="pendientes-tab"
                visible={tabsSearchseries.currentTab === tabsSearchseries.tabs[0]}
              >
                <KardexSerie setReporte={setReporte} reporte={reporte} />
              </CTabPane>
              <CTabPane
                role="tabpanel"
                aria-labelledby="pagados-tab"
                visible={tabsSearchseries.currentTab === tabsSearchseries.tabs[1]}
              >
                <SeriesComprobante setReporte={setReporteCom} reporte={reporteCom} />
              </CTabPane>
            </CTabContent>
          </CNav>
        </CCard>
      </BlockUi>
    </>
  )
}

export default React.memo(SearchSeries)

export const initialFilterState: FilterSeriesState = {
  serie: '',
  min: '',
  verBodegaTransito: false,
  ingresosEgreso: null,
  compraCodigo: null,
  compraDescripcion: null,
  ventaCodigo: null,
  ventaDescripcion: null,
  transferencia: null,
  local: null,
  item: null,
  tipo: null,
  enStock: false
}