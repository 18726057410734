import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LoaderInfo } from '../../../../../../store/types'
import { statusForms } from '../../store/types'
import {
  IngresosNCState,
  Ingresos,
  DetalleIngreso,
  formularioBusqueda,
  Seleccionado, DatosFormularioIngresos
} from './types'

const initialState: IngresosNCState = {
  modulo: 'ingresosNC',
  registros: [],
  detalleIngreso: [],
  dpTipoTransaccion: [],
  loader: {
    show: false,
    mensaje: ''
  },
  formularioBusqueda: {
    fechaDesde: "",
    fechaHasta: "",
    codigo: 0,
    numero: "",
    recibido: "",
    tipo: 0,
    concepto: "",
    planCuentasCodigo: 0,
    fecha: ""
  },
  seleccionado: {
    index: null,
    row: null,
    selectedRow: null,
  },
  currentFormulario: null,
  currentAction: 'Buscar',
  currentFunction: "",
  formularioEdicion: {
    estadoFormulario: statusForms.pending,
    diarioNumero: 0,
    fechaIngreso: "",
    numeroDocumento: 0,
    numeroAsiento: 0,
    numeroTipo: 0,
    tipoDocumento: "",
    totalDebe: 0,
    totalHaber: 0,
    local: null,
    proyecto: null,
    codigoCompra: 0,
    totalCompra: 0,
    codigoRetencion: 0,
    totalRetencion: 0,
    codigoIngreso: 0,
    descripcion: "",
    lote: 0,
    asociadoNombre: "",
    detalleAsiento: []
  }
}

const ingresosNCSlice = createSlice({
  name: 'ingresosNC',
  initialState: initialState,
  reducers: {
    setData(state, action: PayloadAction<Array<Ingresos>>) {
      state.registros = action.payload
    },
    setDetalleIngreso(state, action: PayloadAction<Array<DetalleIngreso>>) {
      state.detalleIngreso = action.payload
    },
    setDpTipoTransaccion(state, action: PayloadAction<Array<any>>) {
      state.dpTipoTransaccion = action.payload
    },
    changeLoaderIngresosNC(state, action: PayloadAction<LoaderInfo>) {
      state.loader = action.payload
    },
    setFormularioBusqueda(state, action: PayloadAction<formularioBusqueda>) {
      state.formularioBusqueda = action.payload
    },
    setSeleccionarDato(state, action: PayloadAction<Seleccionado>) {
      state.seleccionado = action.payload;
    },
    setResetSeleccionIngresoNC(state) {
      const toAdd = {
        index: null,
        row: null,
        selectedRow: null,
      }
      state.seleccionado = toAdd
    },
    setCurrentFormulario(state, action: PayloadAction<any>) {
      state.currentFormulario = action.payload
    },
    setCurrentAccionIngresos(state, action: PayloadAction<any>) {
      state.currentAction = action.payload
    },
    setCurrentFunctionIngresos(state, action: PayloadAction<string>) {
      state.currentFunction = action.payload
    },
    setFormularioEdicionIngresos(state, action: PayloadAction<DatosFormularioIngresos>) {
      state.formularioEdicion = action.payload
    },
  }
})
export const {
  setData, setDetalleIngreso, setDpTipoTransaccion, changeLoaderIngresosNC, setFormularioBusqueda,
  setSeleccionarDato, setResetSeleccionIngresoNC, setCurrentFormulario, setCurrentAccionIngresos,
  setCurrentFunctionIngresos, setFormularioEdicionIngresos
} = ingresosNCSlice.actions
export const IngresosNCReducer = ingresosNCSlice.reducer
