import { DetallePedido, DetalleProformaRedux } from './detallePedido'

export const PedidoHelper = {
  detallesToDetallesPedido,
}

function detallesToDetallesPedido(
  detalles: Array<any>,
  presicionDecimal: number,
): Array<DetalleProformaRedux> {
  return detalles.map((det) => {
    const detalle: DetalleProformaRedux = {
      codigo: det.codigo,
      codigoInterno: det.codigoInterno,
      codigoBarras: det.codigoBarras,
      descripcion: det.descripcion,
      codigoImpuesto: det.detalleImpuesto,
      porcentajeiva: det.porcentajeIva / 100,
      _cantidad: det.detalleCantidad,
      _precioUnitario: det.detallePrecio,
      tieneIva: det.porcentajeIva > 0,
      imagenes: det.itemImagen,
      _descuento: 0,
      _iva: 0,
      _porcentajeDescuento: 0,
      _precioIva: 0,
      _subtotal: 0,
      _subtotal0: 0,
      _subtotalIva: 0,
      _total: 0,
      fecha: '',
    }
    const _detalle: DetallePedido = new DetallePedido()
    _detalle.setDetalle(detalle, presicionDecimal)

    return _detalle.getDetalle()
  })
}
