import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ConfigGemas } from "../types/types";



const initialState: ConfigGemas = {
  modulo: 0
}

const configuracionGemas = createSlice(
  {
    name: 'configGemas',
    initialState: initialState,
    reducers: {
      setCodigoModulo(state, acion: PayloadAction<number>) {
        state.modulo = acion.payload;
      },
    }
  }
)


export const configuracionesGemas = configuracionGemas.reducer;
export const { setCodigoModulo } = configuracionGemas.actions;