import { DateUtils, formatoFechasApi } from "../../../../helpers/dateUtils";
import { RequestHelper } from "../../../../helpers/requestHelper";
import { utilidades } from "../../../../helpers/utilidades";
import { SesionService } from "../../../../services/sesion.service";
import { ESubtipo } from "../../../../store/enum/subtypes";
import { DesempenoOption } from "../store/type";

export const desempenoService = { getDesempenoAdicionales };

async function getDesempenoAdicionales(label: string): Promise<any> {
  try {
    const sesion = SesionService.getCurrentSesion()
    const data: any = {
      mensaje: label,
      tipoCodigo: ESubtipo.Desempeno,
    };
    const keysToCache: any = {
      ...data,
      dateKey: DateUtils.getCurrentDateAsString(formatoFechasApi),
      empresa: sesion?.empresa?.codigo,
    }
    const cacheKey = 'tipoDesempeno' + JSON.stringify(keysToCache);
    const cached = sessionStorage.getItem(cacheKey);
    if (cached) {
      return { error: false, auto: JSON.parse(cached) };
    } else {
      const res = await RequestHelper.getAll<any>('clientes', 'performance/cboxLoad', "", data);
      const opt: Array<DesempenoOption> = [];
      if (res?.auto && res?.auto.length > 0) {
        for (const x of res.auto) {
          opt.push({
            codigo: parseInt(x.codigo) ?? -1,
            descripcion: utilidades.capText(x?.descripcion) ?? '',
            codigoEstado: parseInt(x.codigoEstado) ?? 1,
            descripcionEstado: x.descripcionEstado,
          })
        }
        res.auto = opt
      }
      sessionStorage.setItem(cacheKey, JSON.stringify(opt));
      return res;
    }
  } catch (error) {
    return error;
  }
}

