import { CAlert, CCard, CCardBody, CCol, CRow } from '@coreui/react-pro'
import {
  DataGrid,
  DateBox,
  LoadPanel,
  NumberBox,
  TextArea,
  TextBox,
} from 'devextreme-react'
import {
  Column,
  FilterRow,
  HeaderFilter,
  Pager,
  Paging,
  Selection,
} from 'devextreme-react/data-grid'
import React from 'react'
import CustomCol from '../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import { useDispatch, useSelector } from 'react-redux'
import CabeceraFiltroBusqueda from '../shared/cabeceraFiltroBusqueda'
import { TabState } from '../../../../../../store/genericTabTypes'
import { RootState } from '../../../../../../store/store'
import { changeFilter, fetchBalances, fetchBalancesNum, getToatalWithoutRetentionBuy, initData, setSeleccionarDato } from '../../store/balancesReducer'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import { clearButtonClick, setCurrentExecutingAction } from '../../store/tabsReducer'
import { addToast } from '../../../../../../store/toasterReducer'
import { CustomButtons, FETCH_STATUS, ToastTypes } from '../../../../../../store/types'
import { DateUtils } from '../../../../../../helpers/dateUtils'
import { BalancesServicesPagar } from './services/saldos.service'
import VisualizaError from '../../../../../ventas/pages/shared/visualizaError/visualizaError'
import { fetchAbonarCuotasCompra } from '../../store/payReducer'
import { MessagesKeys, lh } from '../../../../../../helpers/localizationHelper'
import { getIndexProvider } from '../../../../../shared/helpers/funciones'
import { cuotasCreditosService } from '../../../../../componentes/modalCreditos/creditosCompras/services/cuotasCreditosServices'
import { ModalCreaditos } from '../../../../../componentes/modalCreditos'
import Importar from '../../../../../clientes/pages/docsCtasXCobrar/components/impportarExcel/importar'
import { ReportWithLocalDataModal } from '../../../../../../views/componentes/xtrareports/ReportWithLocalDataModal'
import { FilterBalancesState } from '../../types/types'
import { DocumentsToPayService } from '../../services/docsCtasXPagar.services'
import ListErrosValidationForm from '../../../../../componentes/listadoErroresValidacionForm'
import { consoleService } from '../../../../../../services/console.service'

interface ISaldosProps {
  tabId: string
  tab: TabState<any>
}

const Saldos: React.FunctionComponent<ISaldosProps> = (props) => {
  const { tab, tabId } = props
  const dispatch = useDispatch()
  const filterGlobal = useSelector((state: RootState) => state.proveedores.documentosCuentasPorPagar.search.filter)
  const empresa = useSelector((state: RootState) => state.global.empresasUsuario)
  const modulo = useSelector((state: RootState) => state.proveedores.documentosCuentasPorPagar.configuraciones.modulo)
  const filtros = useSelector((state: RootState) => state.proveedores.documentosCuentasPorPagar.balances.filter)
  const seleccionado = useSelector((state: RootState) => state.proveedores.documentosCuentasPorPagar.balances.seleccionado)
  const saldos = useSelector((state: RootState) => state.proveedores.documentosCuentasPorPagar.balances)
  const position = { of: '#balencesContent' }

  const fPagoCodigo = 8
  const formatoFecha: string = 'dd/MM/yyyy'
  const [reporte, setReporte] = React.useState<null | "Viewer" | "Designer">(null)

  const [displayMode] = React.useState<string | 'full' | 'compact'>('full')
  const [showPageSizeSelector] = React.useState<boolean>(true)
  const [showInfo] = React.useState<boolean>(true)
  const [showLoader, setShowLoader] = React.useState<boolean>(false)
  const [showNavButtons] = React.useState<boolean>(true)
  const [showErrorPopup, setShowErrorPopup] = React.useState<boolean>(false)
  const [arrayErrors, setArrayErrors] = React.useState<Array<string>>([])
  const [showImportButton, setShowImportButton] = React.useState<boolean>(false)

  const [fecha, setFecha] = React.useState<Date>(new Date(new Date().getFullYear(), 0, 1))
  const [establecimiento, setEstablecimiento] = React.useState<string>("001")
  const [ptoEmision, setPtoEmision] = React.useState<string>("001")
  const [numero, setNumero] = React.useState<string>(null)
  const [saldoAnterior, setSaldoAnterior] = React.useState<number>(null)
  const [observaciones, setObservaciones] = React.useState<string>(null)
  const [compraCodigo, setCompraCodigo] = React.useState<number>(0)
  const [resultados, setResultados] = React.useState<Array<any>>([])

  const [total, setTotal] = React.useState<number>(0)
  const [cuotas, setCuotas] = React.useState<Array<any>>([])
  const [mostrarIngresoCreditos, setMostrarIngresoCreditos] = React.useState(false);
  const [resultadoImprimir, setResultadoImprimir] = React.useState<Array<any>>([])
  const getFilterString = React.useCallback((filter: FilterBalancesState) => {
    const filterText = "";

    return filterText;
  }, [])

  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(
      addToast({
        title: 'Saldos',
        content: mensaje,
        type: tipo,
      }),
    )
  }, [dispatch])


  const onOpenModalCuotas = React.useCallback(async (codigo) => {
    if (
      codigo !== 0 &&
      codigo !== undefined &&
      codigo !== null
    ) {
      const retention_request = {
        codigo,
        tipoPago: fPagoCodigo
      }
      try {
        const toAnyRetencion: any = getToatalWithoutRetentionBuy(retention_request)
        const resRetencion = await dispatch(toAnyRetencion)
        const toAnyCuotas: any = fetchAbonarCuotasCompra(codigo)
        setShowLoader(true)
        const resCuotas = await dispatch(toAnyCuotas)
        setShowLoader(false)


        if (resRetencion !== null && resRetencion.payload['error'] === false) {
          setTotal(resRetencion.payload['auto'])
        }
        if (resCuotas !== null && resCuotas.payload['error'] === false) {
          setCuotas(resCuotas.payload['auto'])
        }
        setMostrarIngresoCreditos(true)
      } catch (error) {
        setShowLoader(false)
        setToast(error.message, ToastTypes.Danger)
      }
    } else {
      setToast('Seleccione una cuota para ver el credito', ToastTypes.Warning)
    }
  }, [dispatch, setToast])

  const resultMessage = React.useCallback(
    (respuesta, resultado) => {
      let mensaje = ''
      let tipo = ToastTypes.Danger
      if (respuesta === 'PERIODO') {
        mensaje +=
          'La fecha del comprobante esta fuera del periodo contable activo'
      } else if (respuesta === 'EXISTE') {
        mensaje += 'Existe un comprobante con estas caracteristicas, revíselo'
      } else if (respuesta === 'ANULADO') {
        mensaje += 'El comprobante se encuentra en la lista de comprobantes anulados, revíselo'
      } else if (respuesta === 'RETENCION') {
        mensaje += 'Existe una Retencion asociada al comprobante, para poder actualizar la transacción deberá eliminarla'
      } else if (respuesta === 'CHEQUES') {
        mensaje +=
          'Existen Cheques asociados al comprobante, para poder actualizar la transacción deberá eliminarlos.'
      } else if (respuesta === 'TARJETAS') {
        mensaje +=
          'Existen Vouchers asociados al comprobante, para poder actualizar la transacción deberá eliminarlos.'
      } else if (respuesta === 'ABONOS') {
        mensaje +=
          'Existen Abonos asociados al comprobante, para poder actualizar la transacción deberá eliminarlos.'
      } else if (respuesta === 'SALDO') {
        mensaje +=
          'El saldo del comprobante al que afecta es menor al total en crédito de la Nota de Crédito'
      } else if (String(respuesta).substring(0, 16) === 'VERIFICAR STOCK:') {
        mensaje += respuesta.toString()
      } else {
        mensaje += 'Registro existoso'
        tipo = ToastTypes.Success
        if (!isNaN(parseInt(resultado))) {
          const compraCodigoNumber = parseInt(resultado)
          setCompraCodigo(compraCodigoNumber)
          dispatch(changeFilter({
            ...filtros,
            compraCodigo: compraCodigoNumber
          }))
          setToast(mensaje, tipo)
          onOpenModalCuotas(compraCodigoNumber)
        } else {
          mensaje = respuesta
          setToast(mensaje, ToastTypes.Danger)
        }
      }
      if (tipo === ToastTypes.Danger) {
        setToast(mensaje, tipo)
      }
    },
    [onOpenModalCuotas, setToast, dispatch, filtros])



  const onChangeData = React.useCallback((data, key) => {
    if (data.value !== null && data.value !== undefined) {
      let makeDispatch: boolean = true
      switch (key) {
        case 'fecha':
          setFecha(data.value)
          break;
        case 'establecimiento':
          setEstablecimiento(data.value)
          break;
        case 'ptoEmision':
          setPtoEmision(data.value)
          break;
        case 'numero':
          setNumero(data.value)
          break;
        case 'saldoAnterior':
          setSaldoAnterior(data.value)
          break;
        case 'observaciones':
          setObservaciones(data.value)
          break;
        default:
          makeDispatch = false
          break;
      }
      if (makeDispatch) {
        let value = null
        if (key === 'fecha') {
          value = DateUtils.dateToString(data.value, formatoFecha)
        } else {
          value = data.value
        }
        dispatch(changeFilter({
          ...filtros,
          [key]: value
        }))
      }
    }
  }, [dispatch, filtros])

  const onNewBalnace = React.useCallback((accion: string) => {
    dispatch(initData())
    setFecha(new Date(new Date().getFullYear(), 0, 1))
    setEstablecimiento("001")
    setPtoEmision("001")
    if (accion === "Nuevo") {
      setNumero("0")
    } else {
      setNumero(null)
    }
    setSaldoAnterior(null)
    setObservaciones("")
    setCompraCodigo(0)
  }, [dispatch])

  const onGetData = React.useCallback((editData) => {
    const fechaEditar = DateUtils.strDateToDate(editData.compraFecha, formatoFecha)
    const sAnterior = editData.compraTotal.toString()
    setNumero(editData.compraNumero)
    setCompraCodigo(editData.compraCodigo)
    setSaldoAnterior(editData.compraTotal)
    setObservaciones(editData.compraObservaciones)
    setFecha(fechaEditar)

    dispatch(changeFilter({
      ...filtros,
      saldoAnterior: sAnterior,
      observaciones: editData.compraObservaciones,
      fecha: editData.compraFecha
    }))
  }, [dispatch, filtros])

  const validateBalances = React.useCallback(() => {
    const errors: Array<string> = []

    if (filtros.proveedor === null || filtros.proveedor === undefined || filtros.proveedor.codigo === 0) {
      errors.push('Proveedor: Debe elegir un proveedor')
    }
    if (saldoAnterior === null || saldoAnterior === undefined || isNaN(saldoAnterior)) {
      errors.push('Saldo Anterior: Verifique que el saldo anterior sea un número')
    }

    if (observaciones === null || observaciones === undefined || observaciones === "") {
      errors.push("Observaciones: Ingrese las observaciones")
    }

    return errors
  }, [filtros.proveedor, observaciones, saldoAnterior])

  const deleteItemTabla = React.useCallback(async () => {
    if (seleccionado.row !== null && seleccionado.row !== undefined) {
      if (
        resultados !== null &&
        resultados !== undefined &&
        resultados.length > 0
      ) {
        const provider = resultados.slice(0)
        const index = await getIndexProvider(
          provider,
          'compraCodigo',
          seleccionado.row.compraCodigo,
        )
        if (index > -1) {
          provider.splice(index, 1)
          setResultados(provider)
        }
      }
    }
  }, [resultados, seleccionado.row])

  const onCancel = React.useCallback(async () => {
    const delete_request = {
      codigo: seleccionado.row.compraCodigo,
      fecha: seleccionado.row.compraFecha,
    }
    try {
      setShowLoader(true)
      const data = await BalancesServicesPagar.deleteBalance(delete_request)
      setShowLoader(false)
      if (data !== null && data !== undefined && data['error'] === false) {
        deleteItemTabla()
        setToast(data['message'], ToastTypes.Success)
      } else {
        setToast(data['message'], ToastTypes.Danger)
      }
    } catch (error) {
      setShowLoader(false)
      setToast(error.message, ToastTypes.Danger)
    }
  }, [setToast, deleteItemTabla, seleccionado])

  const onSave = React.useCallback(async () => {
    const errors = validateBalances()
    if (errors.length > 0) {
      setArrayErrors(errors)
      setShowErrorPopup(true)
    } else {
      const dpTipoPago = []
      dpTipoPago.push({ formaPagoCodigo: fPagoCodigo, formaPagoCompraCodigo: saldoAnterior })

      const autorizacion = DateUtils.dateToString(fecha, formatoFecha)
      const object_request = {
        comCodigo: compraCodigo,
        comNumero: numero !== null ? numero : "",
        comAutorizacion: autorizacion.substring(autorizacion.length - 4),
        comEstablecimiento: establecimiento !== null ? establecimiento : "",
        comPuntoEmision: ptoEmision !== null ? ptoEmision : "",
        comFecha: DateUtils.dateToString(fecha, formatoFecha),
        saldoAnterior: saldoAnterior !== null ? saldoAnterior.toString() : "",
        prvCodigo: filtros.proveedor?.codigo ?? 0,
        // prvCodigo: 58,
        empCodigo: empresa[0]?.codigo ?? 0,
        locCodigo: filtros.local?.codigo ?? -1,
        comObservaciones: observaciones !== null ? observaciones : "",
        items: [],
        series: [],
        tipoPago: dpTipoPago,
      }

      try {
        setShowLoader(true)
        const data = await BalancesServicesPagar.saveBalances(object_request)
        setShowLoader(false)
        resultMessage(data['message'], data['auto'])
      } catch (error) {
        setShowLoader(false)
        setToast(error.message, ToastTypes.Danger)
      }
    }

  }, [compraCodigo, numero, establecimiento, ptoEmision, fecha, saldoAnterior, filtros, observaciones, setToast, validateBalances, resultMessage, empresa])

  const onFind = React.useCallback(async () => {
    if (filtros.proveedor !== null && filtros.proveedor !== undefined && filtros.proveedor.codigo) {

      const data_request = {
        codigo: filtros.proveedor?.codigo ?? 0,
        fecha: DateUtils.dateToString(fecha, formatoFecha),
        numero
      }
      try {
        const toAny: any = fetchBalances(data_request)
        const res = await dispatch(toAny)

        const toAnyNum: any = fetchBalancesNum(data_request)
        await dispatch(toAnyNum)
        if (res !== undefined && res !== null && res.payload['error'] === false) {
          setResultados(res.payload['auto'])
          setToast(res.payload['message'], ToastTypes.Success)
        } else {
          setResultados([])
          setToast(res.payload['message'], ToastTypes.Danger)
        }

      } catch (error) {
        setToast(error.emsaage, ToastTypes.Danger)
      }
    } else {
      setToast('Por favor, seleccione un proveedor', ToastTypes.Warning)
    }
  }, [filtros, numero, fecha, setToast, dispatch])

  const getDataResult = React.useCallback(async (accion: string) => {
    try {
      setShowLoader(true)
      const request_search = {
        tab: 'SALDOS'
      }
      const data = await DocumentsToPayService.getDataPrintPurchase(request_search)
      setShowLoader(false)
      if (data !== null && data['auto'] && data['error'] === false) {
        setResultadoImprimir(data['auto'])
        if (accion === 'Imprimir') {
          setReporte('Viewer')
        } else {
          setReporte('Designer')
        }
      } else {
        setResultadoImprimir([])
        setToast(data['message'], ToastTypes.Danger)
      }
    } catch (error) {
      setShowLoader(false)
      setResultadoImprimir([])
      setToast(error.message, ToastTypes.Danger)
    }
  }, [setToast])

  const handleButtonClick = React.useCallback((button: ButtonTypes) => {
    switch (button) {
      case ButtonTypes.new:
        onNewBalnace(ButtonTypes.new)
        break
      case ButtonTypes.broom:
        onNewBalnace(ButtonTypes.broom)
        break
      case ButtonTypes.find:
        onFind()
        break
      case ButtonTypes.delete:
        onCancel()
        break
      case ButtonTypes.edit:
        if (seleccionado.row !== null && seleccionado.row !== undefined) {
          onGetData(seleccionado.row)
        } else {
          setToast(lh.getMessage(MessagesKeys.NotFoundRegisterSelec), ToastTypes.Warning)
        }
        break
      case ButtonTypes.undo:
        if (seleccionado.row !== null && seleccionado.row !== undefined) {
          onGetData(seleccionado.row)
        } else {
          setToast(lh.getMessage(MessagesKeys.NotFoundRegisterSelec), ToastTypes.Warning)
        }
        break
      case ButtonTypes.save:
        onSave()
        break
      case ButtonTypes.credit:
        if (seleccionado.row !== null && seleccionado.row !== undefined) {
          onOpenModalCuotas(seleccionado.row.compraCodigo)
        } else {
          setToast(lh.getMessage(MessagesKeys.NotFoundRegisterSelec), ToastTypes.Warning)
        }
        break
      case ButtonTypes.import:
        setShowImportButton(true)
        break
      case ButtonTypes.print:
        if (resultadoImprimir.length === 0) {
          getDataResult('Imprimir')
        } else {
          setReporte('Viewer')
        }
        break
      case ButtonTypes.print_design:
        if (resultadoImprimir.length === 0) {
          getDataResult('Diseñar')
        } else {
          setReporte('Designer')
        }
        break
      default:
        break
    }
    dispatch(clearButtonClick(tabId))
  }, [dispatch, tabId, onFind, onNewBalnace, onSave, onCancel, onGetData, seleccionado, setToast, onOpenModalCuotas, getDataResult, resultadoImprimir])

  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
  }, [handleButtonClick, tab.globalButtonClick])

  React.useEffect(() => {
    dispatch(changeFilter({
      ...filtros,
      proveedor: filterGlobal.proveedor,
      local: filterGlobal.local
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterGlobal])

  React.useEffect(() => {
    if (saldos.status === FETCH_STATUS.FAILED) {
      dispatch(
        addToast({
          title: 'Saldos',
          content: saldos.mensaje ?? lh.getMessage(MessagesKeys.GlobalButtonSearchError),
          type: ToastTypes.Danger,
        }),
      )
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'SALDOS',
          buttonType: undefined,
        }),
      )
    }
    if (saldos.status === FETCH_STATUS.LOADING) {
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'SALDOS',
          buttonType: ButtonTypes.find,
        }),
      )
    }
    if (saldos.status === FETCH_STATUS.SUCCESS) {
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'SALDOS',
          buttonType: undefined,
        }),
      )
    }
  }, [dispatch, saldos.status, saldos.mensaje])

  const onSelectionChanged = React.useCallback((selectionChanged) => {
    console.log("onSelectionChanged", selectionChanged['selectedRowsData'][0])
    dispatch(setSeleccionarDato(
      {
        index: [selectionChanged['currentSelectedRowKeys'][0]],
        row: selectionChanged['selectedRowsData'][0],
        selectedRow: [selectionChanged['currentSelectedRowKeys'][0]]
      }
    ))
  }, [dispatch]);

  const getParseData = React.useCallback((saldos: Array<any> = []) => {
    let registros: Array<any> = []
    if (saldos.length > 0) {
      registros = saldos.map((x) => {
        return {
          fecha:
            DateUtils.strDateToDate(x?.compraFecha, formatoFecha) ?? new Date(),
          numero: x?.compraNumero ?? '',
          identificaion: x?.proveedorIdentificacion ?? '',
          nombre: x?.proveedorNombre ?? '',
          total: x?.compraTotal ?? '',
        }
      })
    }
    consoleService.log(registros)
    return registros
  }, [])

  const onDBSelectedRow = React.useCallback((dbSelectionChanged) => {
    const editData = dbSelectionChanged.data
    onGetData(editData)
  }, [onGetData])

  const onCuotasCreditoChanged = React.useCallback(async (nuevasCuotas) => {
    const cuotas_save = {
      codigo: seleccionado.row !== null ? seleccionado.row.compraCodigo : filtros.compraCodigo,
      cuotas: nuevasCuotas
    }
    try {
      setShowLoader(true)
      const res = await cuotasCreditosService.guardarCuotasCompras(cuotas_save)
      setShowLoader(false)
      if (res !== null && res !== undefined && res['error'] === false) {
        setToast(res['message'], ToastTypes.Success)
      } else {
        if (res !== null && res !== undefined && res['error'] === false) {
          setToast(res['message'], ToastTypes.Danger)
        }
      }
    } catch (error) {
      setShowLoader(false)
      setToast(error.message, ToastTypes.Danger)
    }
    setMostrarIngresoCreditos(false);

  }, [seleccionado, setToast, filtros])

  const getDataFormat = React.useCallback(() => {
    let fechaCredito = DateUtils.dateToString(fecha)
    if (seleccionado.row !== null && seleccionado.row !== undefined && seleccionado.row.compraFecha) {
      const fechaToTranform = new Date(seleccionado.row.compraFecha)
      fechaCredito = DateUtils.dateToString(fechaToTranform)
    }
    return fechaCredito
  }, [fecha, seleccionado.row])


  return (
    <>
      <ReportWithLocalDataModal
        show={reporte !== null}
        onClose={() => setReporte(null)}
        data={getParseData(resultadoImprimir)}
        fileName='StaticClientesSaldosIniciales'
        mode={reporte ?? 'Viewer'}
        parameters={{ 'Reporte_Filtro': getFilterString(filtros), Modulo: "Proveedores" }}
        template='StaticClientesSaldosIniciales'
        key='reportDesigner'
      />
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={position}
        visible={saldos.status === FETCH_STATUS.LOADING || showLoader}
        showIndicator={true}
        shading={true}
        showPane={true}
        closeOnOutsideClick={false}
      />
      {mostrarIngresoCreditos &&
        <ModalCreaditos
          cuotas={cuotas}
          fechaFactura={getDataFormat()}
          totalCobrar={Number(total.toFixed(2))}
          onCancel={() => setMostrarIngresoCreditos(false)}
          onOk={onCuotasCreditoChanged}
          moduloCodigo={modulo}
          facturaCompra={seleccionado.row !== null ? seleccionado.row.compraNumero : numero}
        />
      }
      {
        showErrorPopup && (<VisualizaError
          titulo="Error en Saldos"
          mensaje={'Error al guardar'}
          content={
            <ListErrosValidationForm
              mainMessage={'Revise los siguientes errores:'}
              errorsList={arrayErrors}
              color={'danger'}
            />
          }
          onOk={() => setShowErrorPopup(false)}
        />)
      }
      <CCard id="balencesContent">
        <CCardBody>
          <CabeceraFiltroBusqueda />
          <RowContainer className='mt-1 mb-1'>
            <CustomCol xs="12" md={showImportButton ? "7" : "12"}>
              <RowContainer>
                <CustomCol xs="12" md="2">
                  <Labeled label="Fecha:">
                    <DateBox
                      value={fecha}
                      onValueChanged={(data) => onChangeData(data, 'fecha')}
                    />
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="4">
                  <Labeled label="Número:">
                    <CRow>
                      <CCol xs="12" md="3">
                        <TextBox
                          value={establecimiento}
                          onValueChanged={(data) => onChangeData(data, 'establecimiento')}
                        />
                      </CCol>
                      <CCol xs="12" md="3">
                        <TextBox
                          value={ptoEmision}
                          onValueChanged={(data) => onChangeData(data, 'ptoEmision')}
                        />
                      </CCol>
                      <CCol xs="12" md="6">
                        <TextBox
                          value={numero}
                          onValueChanged={(data) => onChangeData(data, 'numero')}
                          placeholder='Número'
                          maxLength={6}
                        />
                      </CCol>
                    </CRow>
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="3">
                  <Labeled label="Saldo Anterior:">
                    <NumberBox
                      value={saldoAnterior}
                      onValueChanged={(data) => onChangeData(data, 'saldoAnterior')}
                    />
                  </Labeled>
                </CustomCol>
              </RowContainer>
              <RowContainer>
                <CustomCol xs="12" md="5">
                  <Labeled label="Observaciones:">
                    <TextArea
                      value={observaciones}
                      onValueChanged={(data) => onChangeData(data, 'observaciones')}
                    />
                  </Labeled>
                </CustomCol>
              </RowContainer>
            </CustomCol>
            {showImportButton && (
              <CustomCol xs="12" md="4">
                <Importar
                  tab={tab}
                  tabId={tabId}
                  openImport={(open) => setShowImportButton(open)}
                  moduleCode={modulo}
                />
              </CustomCol>
            )}
          </RowContainer>
          <RowContainer>
            <p style={{ marginTop: '4px' }}>
              {lh.getMessage(MessagesKeys.GlobalSaldoInfo)}
            </p>
          </RowContainer>
          <CustomCol xs="12" md="8">
            <CCol className="d-flex justify-content-end">
              {resultados.length}
            </CCol>
            <DataGrid
              style={{ marginTop: '5px' }}
              selection={{ mode: 'single' }}
              hoverStateEnabled={true}
              dataSource={resultados}
              showBorders={true}
              showRowLines={true}
              onRowDblClick={onDBSelectedRow}
              keyExpr="compraCodigo"
              onSelectionChanged={onSelectionChanged}
            >
              <FilterRow visible={true} />
              <HeaderFilter visible={true} />
              <Selection
                mode="single"
                selectAllMode={'onClick'}
                showCheckBoxesMode={[]}
              />

              <Paging defaultPageSize={10} />
              <Pager
                //visible={resultados.length > 0 ? true : false}
                //allowedPageSizes={getAllowedPageSizes(resultados)}
                displayMode={displayMode}
                showPageSizeSelector={showPageSizeSelector}
                showInfo={showInfo}
                showNavigationButtons={showNavButtons}
              />
              <Column
                dataField="compraNumero"
                caption="Número"
                width="20%"
              />
              <Column
                dataField="compraFecha"
                caption="Fecha"
                width="20%"
              />
              <Column dataField="compraPuntoEmision" caption="Número" width="20%" />
              <Column
                dataField="compraObservaciones"
                caption="Observaciones"
                width="20%"
              />
              <Column dataField="compraTotal" caption="Valor" width="20%" />
            </DataGrid>
          </CustomCol>
        </CCardBody>
      </CCard>
    </>
  )
}

export default React.memo(Saldos)

export const ModuleButtonsSaldos: CustomButtons = {
  Nuevo: true,
  Guardar: true,
  Buscar: true,
  Eliminar: true,
  Editar: true,
  Imprimir: true,
  Importar: true,
  Limpiar: true,
  Credito: true,
  Proveedores: true,
  Deshacer: true,
}
