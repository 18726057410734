import { RequestHelper } from "../../../../helpers/requestHelper";
import { SesionService } from "../../../../services/sesion.service";
import { Organico } from "../store/types";

export const rolesUsuariosService = {
  obtenerOrganicos, verificarRoles, verificarUsuariosAsignar, verificarUsuariosAsignados, crearAsignacion, removerAsignacion, buscarAsignacion
}

async function obtenerOrganicos(): Promise<any> {
  const sesion = SesionService.getCurrentSesion();
  try {
    const obj = {
      ruc: sesion.empresa.ruc,
      usuario: sesion.usuario.codigo,
      padre: 0
    }
    const data = await RequestHelper.getAll<Array<Organico>>('admin/organico', 'getAll', "", obj);
    return data;
  } catch (error) {
    return error;
  }
}


async function verificarRoles(codOrganico: number): Promise<any> {
  const sesion = SesionService.getCurrentSesion();
  try {
    const obj = {
      ruc: sesion.empresa.ruc,
      usuario: sesion.usuario.codigo,
      organicoCodigo: codOrganico
    }
    const data = await RequestHelper.getAll<Array<Organico>>('admin/roles', 'organico/getAssign', "", obj);
    return data;
  } catch (error) {
    return error;
  }
}

async function verificarUsuariosAsignar(codRol: number): Promise<any> {
  const sesion = SesionService.getCurrentSesion();
  try {
    const obj = {
      ruc: sesion.empresa.ruc,
      usuario: sesion.usuario.codigo,
      codigo: codRol
    }
    const data = await RequestHelper.getAll<Array<Organico>>('admin/usuarios', 'getByAsign', "", obj);
    return data;
  } catch (error) {
    return error;
  }
}

async function verificarUsuariosAsignados(codRol: number): Promise<any> {
  const sesion = SesionService.getCurrentSesion();
  try {
    const obj = {
      ruc: sesion.empresa.ruc,
      usuario: sesion.usuario.codigo,
      codigo: codRol
    }
    const data = await RequestHelper.getAll<Array<Organico>>('admin/organico', 'rolUsuario/getAsings', "", obj);
    return data;
  } catch (error) {
    return error;
  }
}

async function crearAsignacion(objeto: any): Promise<any> {
  const sesion = SesionService.getCurrentSesion();
  try {
    const obj = {
      infoEmpresa: {
        ruc: sesion.empresa.ruc,
      },
      infoUsuario: {
        usuario: Number(sesion.usuario.codigo),
      },
      infoRegistro: {
        asignaciones: [
          ...objeto
        ]
      }
    }
    const data = await RequestHelper.postAll<any>('admin/organico', 'rolUsuario/save', obj);
    return data;
  } catch (error) {
    return error;
  }
}

async function removerAsignacion(objeto: any): Promise<any> {
  const sesion = SesionService.getCurrentSesion();
  try {
    const obj = {
      infoEmpresa: {
        ruc: sesion.empresa.ruc,
      },
      infoUsuario: {
        usuario: Number(sesion.usuario.codigo),
      },
      infoRegistro: {
        asignaciones: [
          ...objeto
        ]
      }
    }
    const data = await RequestHelper.postAll<any>('admin/organico', 'rolUsuario/delete', obj);
    return data;
  } catch (error) {
    return error;
  }
}

async function buscarAsignacion(objeto: any): Promise<any> {
  const sesion = SesionService.getCurrentSesion();
  try {
    const obj = {
      ruc: sesion.empresa.ruc,
      usuario: sesion.usuario.codigo,
      ...objeto
    }
    const data = await RequestHelper.getAll<any>('admin/organico', 'rolUsuario/searchUsersAsign', "", obj);
    return data;
  } catch (error) {
    return error;
  }
}