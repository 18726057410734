import { TabsState } from '../../../../../../store/genericTabTypes'
import {
  CustomDictionary,
  FETCH_STATUS,
  LoaderInfo,
} from '../../../../../../store/types'
import { TOptionGenerico } from '../../../../../clientes/pages/clientes/types/types'
import { Subtipo } from '../../../../../componentes/gruposUnidadesLookUp/types/types'
import { MenuState } from '../../../../../ventas/types/types'

export type GeneralesState = {
  modulo: string
  currentAction: string
  currentFunction: string
  aplicacion: string
  loader: LoaderInfo
  loaderTab: LoaderInfo
}

export enum MovTypes {
  TipoIngresos = 9,
  TipoEgresos = 10,
  TipoTrans = 11
}

export type ConceptiosMovEdicion = {
  codigo: number
  codigoConcepto: number
  planCuenta: number
  tipo: TOptionGenerico
  descripcion: string
  asociado: Subtipo
  asiento: { value: number, label: string }
  guardado: boolean
  loading: boolean
}

export type GuardarConceptoMov = {
  infoRegistro: {
    codigo: number
    codigoConcepto: number
    tipo: number
    descripcion: string
    cuenta: number
    asociado: number
    asiento: number
  }
}

export type ConceptosState = {
  config: ConfigConceptoMov
  menu: MenuState
  general: GeneralesState
  tabs: TabsState<ConceptiosMovEdicion>
  editData: CustomDictionary<ConceptiosMovEdicion>
  search: BusquedaState<Movimiento>
}

export type BusquedaState<ConceptoMovimiento> = {
  filter: FiltrosConceptosMovState
  maxs: Maximos
  status: FETCH_STATUS
  mensaje: string
  resultados: Array<ConceptoMovimiento>
  colapsed: boolean
  seleccionado: Movimiento | null
}

export type Maximos = {
  maxIngresos: number
  maxEgresos: number
  maxTrans: number
}

export type FiltrosConceptosMovState = {
  tipo: Subtipo
  descripcion: string
  initial: boolean
}

export interface Movimiento {
  codigo: number
  tipo: number
  tipoDescripcion: string
  descripcion: string
  cuenta: number
  cuentaDescripcion: string
  asociado: number
  asociadoDescripcion: string
  asiento: number
}

export type ConfigConceptoMov = {
  aplicacion: number
}


export type ConceptsMovDto = {
  Code: number
  Description: string
  AsociatedTo: string
  AccountDEscripcion: string
}