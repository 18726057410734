import { create } from 'zustand'
import zustymiddleware from 'zustymiddleware'
interface IAutorizacionModuloDteStore {
  autorizacion: string
  setAutorizacion: (autorizacion: string) => void
  reset: () => void
  getAutorizacion: () => string
}

export const autorizacionModuloDtestore = create(
  zustymiddleware<IAutorizacionModuloDteStore>((set) => ({
    autorizacion: '',
    setAutorizacion: (autorizacion: string) => {

      set({ autorizacion })
    },
    reset: () => set({ autorizacion: '' }),
    getAutorizacion: () => {
      return autorizacionModuloDtestore.getState().autorizacion
    },
  })),
)
