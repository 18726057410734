import React from 'react';
import { TTipoItem } from './store/types';
import SelectBox from 'devextreme-react/select-box';
import { tipoItemService } from './service/tipoItem.service';
import { v4 as uuidv4 } from 'uuid'
import { CTooltip } from '@coreui/react-pro';

interface ITipoItemLookUpProps extends React.PropsWithChildren {
  selected: TTipoItem | null,
  // eslint-disable-next-line no-unused-vars
  onChanged: (newValue: TTipoItem | null) => void,
  disabled?: boolean,
  provider?: Array<TTipoItem> | []
  id?: string
}

const TipoItemLookUp: React.FC<ITipoItemLookUpProps> = (props) => {
  const { id, selected, onChanged, disabled, provider } = props;
  const selectRef = React.useRef<any>();
  const [options, setOptions] = React.useState<Array<TTipoItem> | []>([]);

  const loadDatos = React.useCallback(async () => {
    if (provider !== undefined && provider.length > 0) {
      setOptions(provider)
      const selectedIndex = provider.findIndex((select: TTipoItem) => {
        return select.codigo === selected?.codigo
      })
      if (selectedIndex > -1) {
        onChanged(provider[selectedIndex] ?? provider[0])
      } else {
        onChanged(provider[0])
      }
    } else {
      const data = await tipoItemService.getTipoItems()
      if (data.error === false) {
        let opt = []
        if (data.auto.length > 0) {
          opt = data.auto.map((x) => {
            const item: TTipoItem = {
              codigo: x.codigo ?? '',
              descripcion: x.descripcion ?? '',
              codTributario: x.codTributario ?? '',
            }
            return item
          })
        }
        setOptions(opt)
        const selectedIndex = opt.findIndex((select) => {
          return select.codigo === selected?.codigo
        })
        if (selectedIndex > -1) {
          onChanged(opt[selectedIndex])
        } else {
          onChanged(opt[0])
        }
      }
    }
  }, [provider, selected, onChanged])

  const getItemDisplayExpr = React.useCallback((item: TTipoItem) => {
    return item ? `${item.descripcion}` : ''
  }, [])

  const onLookUpValueChanged = React.useCallback((evt: any) => {
    onChanged(evt)
  }, [onChanged])

  const refreshSelected = React.useCallback(() => {
    if (options && options.length > 0) {
      const selectedIndex = options.findIndex((option: TTipoItem) => {
        return option.codigo === selected?.codigo
      })
      onChanged(options[selectedIndex] ?? options[0])
    }
  }, [onChanged, options, selected])

  const onSelectBoxFocusIn = React.useCallback((e) => {
    e.element.querySelector('input.dx-texteditor-input').select()
  }, []);

  React.useEffect(() => {
    loadDatos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    refreshSelected()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  return (
    <SelectBox
      id='tiposItemSelect'
      key={id ?? uuidv4()}
      ref={selectRef}
      items={options ?? []}
      searchEnabled
      displayExpr={getItemDisplayExpr}
      searchExpr={['descripcion']}
      searchTimeout={500}
      value={selected}
      onValueChange={onLookUpValueChanged}
      disabled={disabled}
      onFocusIn={onSelectBoxFocusIn}
      itemRender={itemRender}
    >
      {props.children}
    </SelectBox>
  )

  function itemRender(data) {
    return (
      <CTooltip
        key={'tool-tiposItemSelect'}
        placement="top"
        content={data?.descripcion ?? ''}
      >
        <div className="custom-item" >
          <div className="product-name">{data.descripcion}  </div>
        </div>
      </CTooltip>
    );
  }

}
export default React.memo(TipoItemLookUp);
