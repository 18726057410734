/* eslint-disable prefer-const */
import { RequestHelper } from '../../../helpers/requestHelper'
import {
  LineaInventario,
  MarcaInventario,
  GrupoInventario,
  UnidadInventario,
  ItemInventario,
  IceInventario,
} from '../store/types'
import CustomStore from 'devextreme/data/custom_store'
import DataSource from 'devextreme/data/data_source'
import { PagedResult, PaginationInfo } from '../../../store/types'
import { SesionService } from '../../../services/sesion.service'
import { FiltrosLineasState } from '../pages/catalogos/lineas/types/types'
import { FiltrosMarcasState } from '../pages/catalogos/marcas/types/types'
import { FiltrosGruposState } from '../pages/catalogos/grupos/types/types'
import { FiltrosUnidadessState } from '../pages/catalogos/unidades/types/types'
import { setNroItemsEncontados } from '../pages/catalogos/lineas/store/serachReducer'
import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { setnrRegistrosMarcasLista } from '../pages/catalogos/marcas/store/serachReducer'
import { setNroGroupsEncontados } from '../pages/catalogos/grupos/store/serachReducer'
import { setNroUnidadesEncontradas } from '../pages/catalogos/unidades/store/serachReducer'

export const InventarioService = {
  getLineas,
  getLineasPaged,
  useSetDataSources,
  createLinea,
  deleteLinea,
  getGrupos,
  getGrupsByLines,
  getCategoriasWeb,
  createGrupo,
  deleteGrupo,
  getGruposPaged,
  getGruposDatasource,
  getMarcas,
  getMarcasWeb,
  createMarca,
  deleteMarca,
  getMarcasPaged,
  useGeMarcasDataSource,
  getMarkssByGrups,
  getMarcasDatasource,
  getUnidades,
  getIce,
  getItemXCodigoBarras,
  deleteUnidades,
  getItemXLocal,
  getAtributosWeb,
  getLineasDatasource,
}

/*
LINEAS SERVICE
*/

async function getLinea(codigo: number) {
  const queryData = {
    codigo: codigo,
  }
  const apiData = await RequestHelper.get<LineaInventario>(
    'inventario',
    'line/get',
    '',
    queryData,
  )
  return apiData
}

function getLineasDatasource() {
  const store = new CustomStore({
    key: 'codigo',
    byKey: async (key) => {
      const linea = await getLinea(key)
      return linea
    },
    load: async (loadOptions) => {
      const paginInfo: PaginationInfo = {
        current: (loadOptions.skip ?? 0) / (loadOptions.take ?? 0) + 1,
        size: loadOptions.take ?? 10,
      }
      let pagedResult: PagedResult<LineaInventario>
      pagedResult = await getLineasPaged(
        loadOptions.searchValue ?? '',
        paginInfo,
      )
      return {
        data: pagedResult.result,
        totalCount: pagedResult.count,
      }
    },
  })
  const source = new DataSource({
    paginate: true,
    pageSize: 10,
    store: store,
    key: 'codigo',
  })
  return source
}

async function getLineas(objeto: any) {
  const queryData = {
    descripcion: objeto['descripcion'],
    particular: objeto['particular'],
  }
  const apiData = await RequestHelper.get<Array<any>>(
    'inventario',
    'classification/all',
    '',
    queryData,
  )
  const lineas = parseLineasApiData(apiData)
  return lineas
}

function parseLineasApiData(apiData: Array<any>): Array<LineaInventario> {
  const lineas: Array<LineaInventario> = apiData.map((lineaApi) => {
    return {
      codigo: lineaApi.lineaCodigo,
      particular: lineaApi.lineaParticular,
      descripcion: lineaApi.lineaDescripcion,
      estado: lineaApi.lineaEstado === 1,
      estadoNombre: lineaApi.lineaEstadoDes,
      imagen: lineaApi.lineaImagen,
      conCodigo: lineaApi.conCodigo,
      conConcepto: lineaApi.conConcepto,
      susCodigo: lineaApi.susCodigo,
      utilidad: lineaApi.lineaUtilidad,
    }
  })
  return lineas
}

async function getLineasPaged(
  descripcion: string,
  pagination: PaginationInfo,
): Promise<PagedResult<LineaInventario>> {
  const queryData = {
    descripcion: descripcion,
    isPaged: true,
    page: pagination.current,
    limit: pagination.size,
  }
  const apiDataPaged = await RequestHelper.get<any>(
    'inventario',
    'classification/all',
    '',
    queryData,
  )
  const apiData = apiDataPaged.auto as Array<any> //await RequestHelper.get<Array<any>>('inventario', 'classification/all', '', queryData);
  const lineas = parseLineasApiData(apiData)
  const pagedResult: PagedResult<LineaInventario> = {
    currentPage: parseInt(apiDataPaged.paginaActual),
    from: parseInt(apiDataPaged.desde),
    to: parseInt(apiDataPaged.hasta),
    count: parseInt(apiDataPaged.totalRegistros),
    result: lineas,
  }
  return pagedResult
}

function useSetDataSources() {
  const dispatch = useDispatch()

  const getLinesDatasource = useCallback(
    (find: FiltrosLineasState) => {
      const store = new CustomStore<void, LineaInventario>({
        useDefaultSearch: true,
        key: 'codigo',
        byKey: async (key) => {
          console.log(key)
        },
        load: async (loadOptions) => {
          const paginInfo: PaginationInfo = {
            current: (loadOptions.skip ?? 0) / (loadOptions.take ?? 0) + 1,
            size: loadOptions.take ?? 10,
          }

          let pagedResult: PagedResult<LineaInventario> = {
            currentPage: 0,
            from: 0,
            to: 0,
            count: 0,
            result: [],
          }

          pagedResult = await getLineasPaged(find?.descripcion ?? '', paginInfo)

          return {
            data: pagedResult.result,
            totalCount: pagedResult.count,
          }
        },
      })
      const dataSource = new DataSource({
        totalCount: store.totalCount,
        paginate: true,
        pageSize: 10,
        store: store,
        key: 'codigo',
        requireTotalCount: true,
        onChanged: () => {
          dispatch(setNroItemsEncontados(dataSource.totalCount()))
        },
      })
      return dataSource
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const getGroupsDatasource = useCallback((find: FiltrosGruposState) => {
    const store = new CustomStore<void, any>({
      useDefaultSearch: true,
      key: 'codigo',
      byKey: async (key) => {
        console.log(key)
      },
      load: async (loadOptions) => {
        const paginInfo: PaginationInfo = {
          current: (loadOptions.skip ?? 0) / (loadOptions.take ?? 0) + 1,
          size: loadOptions.take ?? 10,
        }

        let pagedResult: PagedResult<any> = {
          currentPage: 0,
          from: 0,
          to: 0,
          count: 0,
          result: [],
        }

        pagedResult = await getGruposPaged(
          find?.descripcion ?? '',
          paginInfo,
          find.categoriaWeb,
          find.estado?.value ?? -1,
        )

        return {
          data: pagedResult.result,
          totalCount: pagedResult.count,
        }
      },
    })
    const source = new DataSource({
      totalCount: store.totalCount,
      paginate: true,
      pageSize: 10,
      store: store,
      key: 'codigo',
      requireTotalCount: true,
      onChanged: () => {
        dispatch(setNroGroupsEncontados(source.totalCount()))
      },
    })
    return source
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getUnitsDatasource = useCallback((find: FiltrosUnidadessState) => {
    const store = new CustomStore<void, any>({
      useDefaultSearch: true,
      key: 'codigo',
      byKey: async (key) => {
        console.log(key)
      },
      load: async (loadOptions) => {
        const paginInfo: PaginationInfo = {
          current: (loadOptions.skip ?? 0) / (loadOptions.take ?? 0) + 1,
          size: loadOptions.take ?? 10,
        }

        let pagedResult: PagedResult<any> = {
          currentPage: 0,
          from: 0,
          to: 0,
          count: 0,
          result: [],
        }

        pagedResult = await getUnidadesPaged(
          find.estado?.value,
          find?.nombre,
          paginInfo,
        )

        return {
          data: pagedResult.result,
          totalCount: pagedResult.count,
        }
      },
    })
    const source = new DataSource({
      totalCount: store.totalCount,
      paginate: true,
      pageSize: 10,
      store: store,
      key: 'codigo',
      requireTotalCount: true,
      onChanged: () => {
        dispatch(setNroUnidadesEncontradas(source.totalCount()))
      },
    })
    return source
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { getLinesDatasource, getGroupsDatasource, getUnitsDatasource }
}

async function createLinea(objeto: any): Promise<any> {
  try {
    const objRequest = {
      infoRegistro: {
        codigo: objeto['codigoPrimario'],
        particular: objeto['codigo'],
        descripcion: objeto['descripcion'],
        utilidad: objeto['porcentUtilidad'],
        estado: objeto['estado'],
        conceptoCodigo: objeto['conConcepto'],
        sustentoCodigo: objeto['susCodigo'],
      },
    }
    const data = await RequestHelper.postAll<any>(
      'inventario/catalogos/lineas',
      'save',
      objRequest,
    )
    return data
  } catch (error) {
    return error
  }
}

async function deleteLinea(idLinea: any): Promise<any> {
  try {
    const objRequest = {
      codigo: idLinea,
    }
    const data = await RequestHelper.deleteRequestAll<any>(
      'inventario/catalogos/lineas',
      'delete',
      '',
      objRequest,
    )
    return data
  } catch (error) {
    return error
  }
}

/*
GUPOS SERVICE
*/

async function getGrupos(
  descripcion: string,
  categoriaWeb: string = '',
  estado: any = '1',
) {
  try {
    const queryData = {
      descripcion: descripcion,
      categoriaweb: categoriaWeb,
      estado,
    }

    const apiData = await RequestHelper.get<Array<any>>(
      'inventario',
      'grupos/listar',
      '',
      queryData,
    )

    const grupos = parseGruposApidata(apiData)
    return grupos
  } catch (error) {
    return error
  }
}

async function getCategoriasWeb() {
  try {
    const categoriasWeb = await RequestHelper.get<any>(
      'generales',
      'webcategories/list',
      '',
      {},
    )
    return categoriasWeb
  } catch (error) {
    return error
  }
}

async function deleteGrupo(idLinea: any): Promise<any> {
  try {
    const objRequest = {
      codigo: idLinea,
    }
    const data = await RequestHelper.deleteRequestAll<any>(
      'inventario/catalogos/grupos',
      'delete',
      '',
      objRequest,
    )
    return data
  } catch (error) {
    return error
  }
}

async function createGrupo(objeto: any): Promise<any> {
  try {
    const objRequest = {
      infoRegistro: {
        codigo: objeto['codigo'],
        descripcion: objeto['descripcion'],
        pertenece: objeto['pertenece'],
        estado: objeto['estado'],
        categoria: objeto['categoriaWeb'],
      },
    }
    const data = await RequestHelper.postAll<any>(
      'inventario/catalogos/grupos',
      'save',
      objRequest,
    )
    return data
  } catch (error) {
    return error
  }
}

function parseGruposApidata(apiData: Array<any>): Array<GrupoInventario> {
  const grupos: Array<GrupoInventario> = apiData.map((g) => {
    const grupo: GrupoInventario = {
      codigo: g.grupoCodigo,
      descripcion: g.grupoDescripcion,
      perteneceCodigo: g.grupoPertenece,
      perteneceNombre: g.grupoPerteneceDes,
      estadoCodigo: g.grupoEstado,
      estadoNombre: g.grupoEstadoDes,
      categoriaWeb: g.grupoCategoriaWeb === null ? -1 : g.grupoCategoriaWeb,
      items: parseInt(g?.ITEMS ?? g?.items),
    }
    return grupo
  })
  return grupos
}

async function getGrupsByLines(
  lineas: Array<number>,
  descripcion: string,
  pagination: PaginationInfo,
): Promise<any> {
  const newLines = lineas.map((id) => {
    return { codigo: String(id) }
  })
  try {
    const object = {
      infoFiltro: {
        descripcion: descripcion,
        estado: 1,
        lineas: newLines,
      },
      isPaged: true,
      page: pagination.current,
      limit: pagination.size,
    }
    const apiDataPaged = await RequestHelper.postAll<any>(
      'inventario/catalogos/grupos',
      'getAllLine',
      object,
    )

    return apiDataPaged
  } catch (error) {
    return error
  }
}

async function getGruposPaged(
  descripcion: string,
  pagination: PaginationInfo,
  categoriaWeb: string = '',
  estado: any = '1',
): Promise<PagedResult<GrupoInventario>> {
  const queryData = {
    descripcion: descripcion,
    isPaged: true,
    page: pagination.current,
    limit: pagination.size,
    categoriaweb: categoriaWeb,
    estado,
  }

  const apiDataPaged = await RequestHelper.get<any>(
    'inventario',
    'grupos/listar',
    '',
    queryData,
  )
  const apiData = apiDataPaged.auto as Array<any>
  const grupos = parseGruposApidata(apiData)
  const pagedResult: PagedResult<GrupoInventario> = {
    currentPage: parseInt(apiDataPaged.paginaActual),
    from: parseInt(apiDataPaged.desde),
    to: parseInt(apiDataPaged.hasta),
    count: parseInt(apiDataPaged.totalRegistros),
    result: grupos,
  }
  return pagedResult
}

async function getGrupo(codigo: number): Promise<GrupoInventario | null> {
  const queryData = {
    codigo: codigo,
  }
  const apiData = await RequestHelper.get<GrupoInventario>(
    'inventario',
    'group/get',
    '',
    queryData,
  )
  return apiData
}

function getGruposDatasource() {
  const store = new CustomStore({
    key: 'codigo',
    byKey: async (key) => {
      const linea = await getGrupo(key)
      return linea
    },
    load: async (loadOptions) => {
      const paginInfo: PaginationInfo = {
        current: (loadOptions.skip ?? 0) / (loadOptions.take ?? 0) + 1,
        size: loadOptions.take ?? 10,
      }
      let pagedResult: PagedResult<GrupoInventario>
      pagedResult = await getGruposPaged(
        loadOptions.searchValue ?? '',
        paginInfo,
      )
      console.log('current page ', pagedResult)
      return {
        data: pagedResult.result,
        totalCount: pagedResult.count,
      }
    },
  })
  const source = new DataSource({
    paginate: true,
    pageSize: 10,
    store: store,
    key: 'codigo',
  })
  return source
}

async function getMarca(codigo: number): Promise<MarcaInventario | null> {
  const queryData = {
    codigo: codigo,
  }
  const apiData = await RequestHelper.get<MarcaInventario>(
    'inventario',
    'brand/get',
    '',
    queryData,
  )
  return apiData
}

/*
GUPOS MARCAS
*/

async function getMarcasWeb() {
  const queryData = {}
  const marcasWeb = await RequestHelper.get<Array<any>>(
    'generales',
    'webmarks/list',
    '',
    queryData,
  )
  return marcasWeb
}

async function getAtributosWeb(): Promise<any> {
  try {
    const queryData = {}
    const marcasWeb = await RequestHelper.getAll<any>(
      'generales',
      'atributtesweb/cbxload',
      '',
      queryData,
    )
    return marcasWeb
  } catch (error) {
    return error
  }
}

async function getMarcas(objeto: { codigo: number; nombre: string }) {
  try {
    const apiData = await RequestHelper.get<Array<any>>(
      'inventario',
      'brand/all',
      '',
      objeto,
    )
    const marcas = parseMarcasApiData(apiData)
    return marcas
  } catch (error) {
    return error
  }
}

async function createMarca(objeto: any): Promise<any> {
  try {
    const objRequest = {
      infoRegistro: {
        codigo: objeto['codigo'],
        nombre: objeto['nombre'],
        estado: objeto['estado'],
        marcaweb:
          objeto['marcaWeb']['id'] === -1 ? 0 : objeto['marcaWeb']['id'],
      },
    }
    const data = await RequestHelper.postAll<any>(
      'inventario',
      'brand/save',
      objRequest,
    )
    return data
  } catch (error) {
    return error
  }
}

async function deleteMarca(idMarca: any): Promise<any> {
  try {
    const objRequest = {
      codigo: idMarca,
    }
    const data = await RequestHelper.deleteRequestAll<any>(
      'inventario',
      'brand/delete',
      '',
      objRequest,
    )
    return data
  } catch (error) {
    return error
  }
}

async function getMarkssByGrups(
  lineas: Array<number>,
  grupos: Array<number>,
  nombre: string,
  pagination: PaginationInfo,
): Promise<any> {
  const newLineas = lineas.map((id) => {
    return { codigo: String(id) }
  })
  const newGrupos = grupos.map((id) => {
    return { codigo: String(id) }
  })
  try {
    const object = {
      infoFiltro: {
        nombre,
        estado: 1,
        lineas: newLineas,
        grupos: newGrupos,
      },
      isPaged: true,
      page: pagination.current,
      limit: pagination.size,
    }
    console.log(object)
    const apiDataPaged = await RequestHelper.postAll<any>(
      'inventario',
      'brand/getAllGroup',
      object,
    )

    return apiDataPaged
  } catch (error) {
    return error
  }
}

async function getMarcasPaged(
  nombre: string,
  pagination: PaginationInfo,
): Promise<PagedResult<MarcaInventario>> {
  const queryData = {
    nombre: nombre,
    isPaged: true,
    page: pagination.current,
    limit: pagination.size,
  }
  const apiDataPaged = await RequestHelper.get<any>(
    'inventario',
    'brand/all',
    '',
    queryData,
  )
  const apiData = apiDataPaged.auto as Array<any>
  const marcas = parseMarcasApiData(apiData)
  const pagedResult: PagedResult<MarcaInventario> = {
    currentPage: parseInt(apiDataPaged.paginaActual),
    from: parseInt(apiDataPaged.desde),
    to: parseInt(apiDataPaged.hasta),
    count: parseInt(apiDataPaged.totalRegistros),
    result: marcas,
  }
  return pagedResult
}

function parseMarcasApiData(apiData: Array<any>): Array<MarcaInventario> {
  const marcas: Array<MarcaInventario> = apiData.map((marcaApi) => {
    return {
      codigo: marcaApi.marcaCodigo,
      nombre: marcaApi.marcaNombre,
      estado: marcaApi.marcaEstado,
      estadoNombre: marcaApi.marcaEstadoDes,
      marcaWeb: marcaApi.marcaMarcaWeb,
    }
  })
  return marcas
}

function parseIceApiData(apiData: Array<any>): Array<IceInventario> {
  const ice: Array<IceInventario> = apiData.map((iceApi) => {
    return {
      codigo: iceApi.codigo,
      nombre: iceApi.nombre,
      descripcion: iceApi.descripcion,
      valor: iceApi.valor,
      vigencia: iceApi.vigencia,
      hasta: iceApi.hasta,
      estado: iceApi.estado,
      estadoDescripcion: iceApi.estadoDescripcion,
      tipo: iceApi.tipo,
      tipoValor: iceApi.tipoValor,
      adValorEm: iceApi.adValorEm,
      exFabrica: iceApi.exFabrica,
      exAduana: iceApi.exAduana,
      exhibir: iceApi.exhibir,
    }
  })
  return ice
}

function getMarcasDatasource() {
  const store = new CustomStore({
    key: 'codigo',
    byKey: async (key) => {
      const linea = await getMarca(key)
      return linea
    },
    load: async (loadOptions) => {
      const paginInfo: PaginationInfo = {
        current: (loadOptions.skip ?? 0) / (loadOptions.take ?? 0) + 1,
        size: loadOptions.take ?? 10,
      }
      let pagedResult: PagedResult<MarcaInventario>
      pagedResult = await getMarcasPaged(
        loadOptions.searchValue ?? '',
        paginInfo,
      )
      console.log('current page ', pagedResult)
      return {
        data: pagedResult.result,
        totalCount: pagedResult.count,
      }
    },
  })
  const source = new DataSource({
    paginate: true,
    pageSize: 10,
    store: store,
    key: 'codigo',
  })
  return source
}
function useGeMarcasDataSource() {
  const dispatch = useDispatch()

  const getMarcasDatasource = useCallback(
    (find: FiltrosMarcasState) => {
      const store = new CustomStore<void, MarcaInventario>({
        useDefaultSearch: true,
        key: 'codigo',
        byKey: async (key) => {
          console.log(key)
        },
        load: async (loadOptions) => {
          const paginInfo: PaginationInfo = {
            current: (loadOptions.skip ?? 0) / (loadOptions.take ?? 0) + 1,
            size: loadOptions.take ?? 10,
          }

          let pagedResult: PagedResult<MarcaInventario> = {
            currentPage: 0,
            from: 0,
            to: 0,
            count: 0,
            result: [],
          }

          pagedResult = await getMarcasPaged(find?.codigo ?? '', paginInfo)

          return {
            data: pagedResult.result,
            totalCount: pagedResult.count,
          }
        },
      })
      const dataSource = new DataSource({
        totalCount: store.totalCount,
        paginate: true,
        pageSize: 10,
        store: store,
        key: 'codigo',
        requireTotalCount: true,
        onChanged: () => {
          dispatch(setnrRegistrosMarcasLista(dataSource.totalCount()))
        },
      })
      return dataSource
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return { getMarcasDatasource }
}

async function getUnidades(objeto: any = {}): Promise<Array<UnidadInventario>> {
  const queryData = {
    estado: objeto?.estado ?? 1,
    nombre: objeto?.nombre ?? '',
  }
  try {
    const apiData = await RequestHelper.get<Array<any>>(
      'inventario',
      'unidades/listar',
      '',
      queryData,
    )
    const unidades = parseUnidadApiData(apiData)
    // sessionStorage.setItem(cacheKey, JSON.stringify(unidades))
    return unidades
  } catch (error) {
    return error
  }
}

async function getUnidadesPaged(
  estado: number,
  descripcion: string,
  pagination: PaginationInfo,
): Promise<PagedResult<UnidadInventario>> {
  const queryData = {
    estado,
    nombre: descripcion,
    isPaged: true,
    page: pagination.current,
    limit: pagination.size,
  }

  try {
    const apiDataPaged = await RequestHelper.get<any>(
      'inventario',
      'unidades/listar',
      '',
      queryData,
    )

    const apiData = apiDataPaged.auto as Array<any>
    const unidades = parseUnidadApiData(apiData)
    const pagedResult: PagedResult<UnidadInventario> = {
      currentPage: parseInt(apiDataPaged.paginaActual),
      from: parseInt(apiDataPaged.desde),
      to: parseInt(apiDataPaged.hasta),
      count: parseInt(apiDataPaged.totalRegistros),
      result: unidades,
    }
    return pagedResult
  } catch (error) {
    return error
  }
}

async function deleteUnidades(idUnidad: number): Promise<any> {
  const queryData = {
    codigo: idUnidad,
  }
  try {
    const apiData = await RequestHelper.deleteRequestAll<Array<any>>(
      'inventario/catalogos',
      'unidades/delete',
      '',
      queryData,
    )

    return apiData
  } catch (error) {
    return error
  }
}

function parseUnidadApiData(apiData: Array<any>): Array<UnidadInventario> {
  const unidades = apiData.map((u) => {
    const unidad: UnidadInventario = {
      codigo: u.codigo,
      nombre: u.nombre,
      siglas: u.siglas,
      grupoCodigo: u.grupoCodigo,
      grupoNombre: u.grupoNombre,
      estadoCodigo: u.estadoCodigo,
      estadoNombre: u.estadoNombre,
      fraccion: u.fraccion,
    }
    return unidad
  })
  return unidades
}

async function getIce(): Promise<Array<IceInventario>> {
  const queryData = {
    estado: 1,
  }
  const cacheKey = 'iceVenta' + JSON.stringify(queryData)
  const savedData = sessionStorage.getItem(cacheKey) ?? null
  if (savedData) {
    return JSON.parse(savedData)
  }
  const apiData = await RequestHelper.get<Array<IceInventario>>(
    'generales',
    'taxes/cBoxLoadICE',
    '',
    queryData,
  )
  const ice = apiData //parseIceApiData(apiData);
  sessionStorage.setItem(cacheKey, JSON.stringify(ice))
  return ice
}

async function getItemXCodigoBarras(
  codigoBarras: string,
  padreCodigo: number = 0,
  fecha: string = '',
  filter: boolean = false,
  local: number = -1
): Promise<ItemInventario | ItemInventario[] | null> {
  const sesion = SesionService.getCurrentSesion()
  if (local === -1) {
    local = sesion.local.codigo
  }
  const queryData = {
    itemMobile: -1,
    itemBarras: codigoBarras,
    itemFecha: fecha,
    itemPadre: padreCodigo,
    exacta: 1,
    local: local,
  }

  const apiData = await RequestHelper.get<Array<ItemInventario>>(
    'inventario',
    'items/searchItemsBase',
    '',
    queryData,
  )
  if (filter) {
    return apiData
  }
  return apiData.length > 0 ? apiData[0] : null
}

async function getItemXLocal(
  codigoBarras: string,
  fecha: string = '',
  local: number = -1,
): Promise<any> {
  try {
    const queryData = {
      fecha,
      barras: codigoBarras,
      local,
    }

    const apiData = await RequestHelper.getAll<any>(
      'inventario',
      'items/searchItemsLocalSimple',
      '',
      queryData,
    )
    if (apiData['error'] === false && apiData['auto'].length === 0) {
      return apiData['message']
    }
    return apiData !== null ? apiData['auto'][0] : null
  } catch (error) {
    return error
  }
}

