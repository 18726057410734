import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ConfigLineas, LineasDatosEdicion } from "../types/types";



const initialState: ConfigLineas = {
  aplicacion: 0,
}

const configuracionLineas = createSlice(
  {
    name: 'configLineas',
    initialState: initialState,
    reducers: {
      setCodigoModulo(state, acion: PayloadAction<number>) {
        state.aplicacion = acion.payload;
      },
      setDatosEdicionBackup(
        state,
        action: PayloadAction<{
          key: string
          line: LineasDatosEdicion
        }>,
      ) {
        state[action.payload.key] = action.payload.line
      },
      deleteEditDataBackup(state, action: PayloadAction<string>) {
        delete state[action.payload]
      },
    }
  }
)


export const configuracionesLineas = configuracionLineas.reducer;
export const { setCodigoModulo, setDatosEdicionBackup, deleteEditDataBackup } = configuracionLineas.actions;