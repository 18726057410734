import { RequestHelperDotNet } from "../../../helpers/requestHelperDotNet";
import { TiposComprobantesSri } from "../../../store/types";
import { VentasHelper } from "../pages/helper/helper";

export const RideService = {
  getRideData,
};

async function getRideData(accesKey: string) {
  const query = {
    accesKey,
  };
  const voucherType = VentasHelper.getVoucherTypeFromAccesKey(accesKey);
  let data: any;
  const errorInfo =
    "No se pudo encontrar un tipo de documento válido en la clave de acceso " +
    accesKey;
  switch (voucherType) {
    case TiposComprobantesSri.Factura:
      data = await RequestHelperDotNet.get(
        "Billing",
        "ec/Rides/GetInvoiceData",
        "",
        query
      );
      break;
    case TiposComprobantesSri.NotaCredito:
      data = await RequestHelperDotNet.get(
        "Billing",
        "ec/Rides/GetCreditNoteData",
        "",
        query
      );
      break;
    case TiposComprobantesSri.NotaDebito:
      data = await RequestHelperDotNet.get(
        "Billing",
        "ec/Rides/GetDebitNoteData",
        "",
        query
      );
      break;
    case TiposComprobantesSri.GuiaRemision:
      data = await RequestHelperDotNet.get(
        "Billing",
        "ec/Rides/GetRemisionGuideData",
        "",
        query
      );
      break;
    case TiposComprobantesSri.Retencion:
      data = await RequestHelperDotNet.get(
        "Billing",
        "ec/Rides/GetPurchaseRetentionData",
        "",
        query
      );
      break;
      case TiposComprobantesSri.LiquidacionCompra:
        data = await RequestHelperDotNet.get(
          "Billing",
          "ec/Rides/GetPurchaseData",
          "",
          query
        );
        break;
    default:
      throw errorInfo;
  }
  return data;
}
