import React, { useEffect, useState } from 'react'
import {
  CCard,
  CCardBody,
  CCol,
  CNav,
  CNavItem,
  CNavLink,
  CRow,
  CTabContent,
  CTabPane,
} from '@coreui/react-pro'
import { useDispatch } from 'react-redux'

import { changeLoader } from '../../../../../../store/reducers'
import Modalform from '../../../../../../views/componentes/modalform'
import CustomCol from '../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput'
import { DataGrid, TextBox } from 'devextreme-react'
import { useSetToast } from '../../../../../../hooks/useGlobalHooks'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import { KardexServices } from '../../services/kardex.services'
import { ToastTypes } from '../../../../../../store/types'
import { LotesKardex, PAdanuales } from '../../types/types'
import { Column, Pager, Paging } from 'devextreme-react/data-grid'
import { getAllowedPageSizes } from '../../../../../../helpers/Helper'

const tabs: Array<string> = ['Pedimentos', 'Lotes']

type ModalAdaunaLotesProps = {
  show: boolean
  onClose: () => void
  code: number
  barras: string
  description: string
}

const ModalAduanaLotes = (props: ModalAdaunaLotesProps) => {
  const dispatch = useDispatch()
  const setToastMessage = useSetToast()

  const [currentTab, setCurrentTab] = useState<string>('Pedimentos')
  const [padanuales, setPadanuales] = useState<Array<PAdanuales>>([])
  const [lotes, setLotes] = useState<Array<LotesKardex>>([])
  const tablaRef = React.useRef<any>()
  const tablaRefPager = React.useRef<any>()
  const tablaRefLotes = React.useRef<any>()
  const tablaRefPagerLotes = React.useRef<any>()

  const playLoader = React.useCallback(
    (message: string) => {
      dispatch(changeLoader({ show: true, mensaje: message }))
    },
    [dispatch],
  )

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoader({ show: false, mensaje: '' }))
  }, [dispatch])

  const closeModal = React.useCallback(() => {
    props.onClose()
  }, [props])

  const onSearchData = React.useCallback(async () => {
    try {
      playLoader('')
      const padAnuales = await KardexServices.getPadanuales(props.code, '')
      const lotes = await KardexServices.getLotes(props.code)
      stopLoader()
      if (!padAnuales.error && padAnuales.auto) setPadanuales(padAnuales.auto)
      else
        setToastMessage(
          'Buscar Pedimentos Aduanales',
          padAnuales.message ?? 'Error al buscar pedimentos adanuales',
          ToastTypes.Warning,
        )
      if (!lotes.error && lotes.auto) setLotes(lotes.auto)
      else
        setToastMessage(
          'Buscar Lotes',
          lotes.message ?? 'Error al buscar lotes',
          ToastTypes.Warning,
        )
    } catch (error) {
      stopLoader()
      setToastMessage(
        'Buscar Pedimentos Aduanales y Lotes',
        error,
        ToastTypes.Danger,
      )
    }
  }, [playLoader, props.code, setToastMessage, stopLoader])

  useEffect(() => {
    onSearchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const bodyModalForm = () => {
    return (
      <>
        <RowContainer>
          <CustomCol xs="12" md="4">
            <Labeled label="Código:">
              <TextBox value={props.barras ?? ''} readOnly={true} />
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="6">
            <Labeled label="Descripción:">
              <TextBox value={props.description ?? ''} readOnly={true} />
            </Labeled>
          </CustomCol>
        </RowContainer>
        <CNav variant="tabs" role="tablist" className="mt-4">
          <CNav variant="tabs">
            {tabs.map((tab, index) => (
              <CNavItem key={index}>
                <CNavLink
                  onClick={() => setCurrentTab(tabs[index])}
                  active={currentTab === tab}
                >
                  {tab}
                </CNavLink>
              </CNavItem>
            ))}
          </CNav>
          <CTabContent
            style={{
              overflowY: 'unset',
              width: '100%',
              marginBottom: '10px',
            }}
          >
            <CTabPane
              role="tabpanel"
              aria-labelledby="items-tab"
              visible={currentTab === tabs[0]}
            >
              <DataGrid
                ref={tablaRef}
                selection={{ mode: 'single' }}
                className="border"
                dataSource={padanuales ?? []}
                width={'100%'}
                keyExpr=""
              >
                <Paging enabled={true} defaultPageSize={10} />
                <Pager
                  ref={tablaRefPager}
                  showPageSizeSelector={true}
                  allowedPageSizes={getAllowedPageSizes(padanuales)}
                  showNavigationButtons={true}
                  showInfo={true}
                />
                <Column dataField="pedimento" caption="Pedimento" width="25%" />
                <Column dataField="cantidad" caption="Cantidad" width="25%" />
                <Column dataField="ingreso" caption="Ingreso" width="25%" />
                <Column dataField="aduanaNombre" caption="Aduna" width="25%" />
              </DataGrid>
            </CTabPane>
            <CTabPane
              role="tabpanel"
              aria-labelledby="todos-tab"
              visible={currentTab === tabs[1]}
            >
              <DataGrid
                ref={tablaRefLotes}
                selection={{ mode: 'single' }}
                className="border"
                dataSource={lotes ?? []}
                width={'100%'}
                keyExpr=""
              >
                <Paging enabled={true} defaultPageSize={10} />
                <Pager
                  ref={tablaRefPagerLotes}
                  showPageSizeSelector={true}
                  allowedPageSizes={getAllowedPageSizes(lotes)}
                  showNavigationButtons={true}
                  showInfo={true}
                />
                <Column dataField="numero" caption="Número" width="25%" />
                <Column dataField="cantidad" caption="Cantidad" width="25%" />
                <Column dataField="precio" caption="Precio" width="25%" />
                <Column dataField="existencia" caption="Saldo" width="25%" />
              </DataGrid>
            </CTabPane>
          </CTabContent>
        </CNav>
      </>
    )
  }

  const renderBody = () => {
    return (
      <>
        <CCard key="cardLotes">
          <CCardBody>{bodyModalForm()}</CCardBody>
        </CCard>
      </>
    )
  }

  return (
    <CRow key="rowMain">
      <CCol key="colMain">
        <Modalform
          name="modalPadanuales"
          key="modalPadanuales"
          headerTitle={'Pedimentos Aduanales y Lotes'}
          childrenBody={renderBody()}
          childrenFooter={<></>}
          closeOnBackdrop={false}
          show={props.show}
          onClose={closeModal}
          size="lg"
        />
      </CCol>
    </CRow>
  )
}

export default React.memo(ModalAduanaLotes)
