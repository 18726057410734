import React from 'react'
import RowContainer from '../../../../../../../../views/componentes/rowContainer/rowContainer'
import Labeled from '../../../../../../../../views/componentes/labeledInput/labeledInput'
import {
  Button,
  DataGrid,
  NumberBox,
  RadioGroup,
  TextBox,
} from 'devextreme-react'
import CustomCol from '../../../../../../../../views/componentes/colContainer'
import { Column } from 'devextreme-react/data-grid'
import { ItemStock } from '../../../../types/types'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../../store/store'
import { setDataStockToEdit } from '../../../../store/editDataReduce'
import { ToastTypes } from '../../../../../../../../store/types'
import { useSetToast } from '../../../../../../../../hooks/useGlobalHooks'
import { CLink, CTooltip } from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilInfo } from '@coreui/icons'

export const optionsRecompras = [
  { value: 1, label: 'Semana 1' },
  { value: 2, label: 'Semana 2' },
  { value: 3, label: 'Semana 3' },
  { value: 4, label: 'Semana 4' },
]

const Existencia = (props) => {
  const { tabId } = props

  const dispatch = useDispatch()
  const setToastMessage = useSetToast()

  const dataToEditStock = useSelector(
    (state: RootState) => state.inventarios.items.editData[tabId].existencias,
  )

  const onChangedRecompras = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setDataStockToEdit({
            key: tabId,
            stock: {
              ...dataToEditStock,
              recompra: event.value,
            },
          }),
        )
    },
    [dispatch, dataToEditStock, tabId],
  )

  const onChangedLocalizacion = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setDataStockToEdit({
            key: tabId,
            stock: {
              ...dataToEditStock,
              localizacion: event.value,
            },
          }),
        )
    },
    [dispatch, dataToEditStock, tabId],
  )

  const onChangedMaximo = React.useCallback(
    (event: any) => {
      if (event.event) {
        let value: number = parseFloat(event.value.toFixed(2)) ?? 0
        if (event.value < dataToEditStock.minimo) {
          setToastMessage(
            'Actualizar valor máximo',
            'El valor máximo debe ser mayor o igual al valor mínimo',
            ToastTypes.Warning,
          )
          value = event.previousValue ?? 0
        }
        dispatch(
          setDataStockToEdit({
            key: tabId,
            stock: {
              ...dataToEditStock,
              maximo: value,
            },
          }),
        )
      }
    },
    [dispatch, dataToEditStock, tabId, setToastMessage],
  )

  const onChangedMinimo = React.useCallback(
    (event: any) => {
      if (event.event) {
        let value: number = parseFloat(event.value.toFixed(2)) ?? 0
        if (event.value > dataToEditStock.maximo) {
          setToastMessage(
            'Actualizar valor mínimo',
            'El valor mínimo debe ser menor o igual al valor máximo',
            ToastTypes.Warning,
          )
          value = event.previousValue ?? 0
        }
        dispatch(
          setDataStockToEdit({
            key: tabId,
            stock: {
              ...dataToEditStock,
              minimo: value,
            },
          }),
        )
      }
    },
    [dataToEditStock, dispatch, tabId, setToastMessage],
  )

  return (
    <RowContainer className="m-2">
      <RowContainer>
        <CustomCol xs="12" md="3">
          <Labeled label="Existencia">
            <div
              style={{
                display: 'flex',
                gap: '2px',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <NumberBox
                value={dataToEditStock.existencia}
                readOnly={true}
                showClearButton={true}
                width="100%"
                inputAttr={{ autocomplete: 'nope' }}
              />
              <CTooltip
                key={'tool-more-auth-side'}
                placement="right"
                content={'Campo informativo.'}
              >
                <CLink className="card-header-action m-0">
                  <CIcon icon={cilInfo} />
                </CLink>
              </CTooltip>
            </div>
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="3">
          <Labeled label="Reservado">
            <div
              style={{
                display: 'flex',
                gap: '2px',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <NumberBox
                value={dataToEditStock.reservado}
                readOnly={true}
                showClearButton={true}
                width="100%"
                inputAttr={{ autocomplete: 'nope' }}
              />
              <CTooltip
                key={'tool-more-auth-side'}
                placement="right"
                content={'Campo informativo.'}
              >
                <CLink className="card-header-action m-0">
                  <CIcon icon={cilInfo} />
                </CLink>
              </CTooltip>
            </div>
          </Labeled>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="3">
          <Labeled label="Mínimo">
            <NumberBox
              value={dataToEditStock.minimo}
              onValueChanged={onChangedMinimo}
              width="100%"
              inputAttr={{ autocomplete: 'nope' }}
              min={0}
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="3">
          <Labeled label="Máximo">
            <NumberBox
              value={dataToEditStock.maximo}
              onValueChanged={onChangedMaximo}
              width="100%"
              inputAttr={{ autocomplete: 'nope' }}
              min={0}
            />
          </Labeled>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="3">
          <Labeled label="Localizacion">
            <TextBox
              value={dataToEditStock.localizacion}
              onValueChanged={onChangedLocalizacion}
              showClearButton={true}
              width="100%"
              inputAttr={{ autocomplete: 'nope' }}
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="6">
          <Labeled label="Recompras en">
            <RadioGroup
              name="idTypesItem"
              dataSource={optionsRecompras}
              value={dataToEditStock.recompra}
              layout="horizontal"
              displayExpr="label"
              onValueChanged={onChangedRecompras}
              width="100%"
            />
          </Labeled>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="6">
          <DataGrid
            className="m-2"
            selection={{ mode: 'single' }}
            hoverStateEnabled={true}
            dataSource={dataToEditStock.localExistencia}
            showBorders={true}
            showRowLines={true}
          >
            <Column
              dataType="string"
              dataField="local"
              caption="Local"
              width="50%"
            />
            <Column
              dataType="number"
              dataField="existencia"
              caption="Cantidad"
              width="50%"
            />
          </DataGrid>
        </CustomCol>
      </RowContainer>
    </RowContainer>
  )
}

export default React.memo(Existencia)

export const defaultExistencia: ItemStock = {
  existencia: 0,
  reservado: 0,
  maximo: 10,
  minimo: 2,
  localizacion: '',
  recompra: null,
  localExistencia: [],
}
