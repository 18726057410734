import { TabsState } from '../../../../../../store/genericTabTypes'
import {
  CustomDictionary,
  FETCH_STATUS,
  LoaderInfo,
  Seleccionado,
} from '../../../../../../store/types'
import { TOptionGenerico } from '../../../../../clientes/pages/clientes/types/types'
import { LocalOption } from '../../../../../componentes/localesLookUp/store/type'
import { TProyectoOption } from '../../../../../componentes/proyectosLookUp/store/type'
import { Proveedor } from '../../../../../proveedores/store/types'
import { Project } from '../../../../../shared/types/types'
import { ItemPedido } from '../../../../../ventas/pages/ordenes/pages/ordenPedido/type/types'
import { Cliente, MenuState } from '../../../../../ventas/types/types'
import { Movimiento } from '../../conceptosMovimientos/types/types'
import { Proyecto } from '../../../../../bancos/pages/movimientosBancos/ingresosNC/store/types';

export enum ETittleTab {
  cab = 'Cabecera',
  det = 'Detalle',
  bas = 'Base',
  cons = 'Consolidado',
}

export enum ETypeMovimiento {
  ventas = 55,
  compras = 56,
  ninguno = 57,
  default = 0,
}

export enum ETypeMovimientoCode {
  ingresos = 9,
  egresos = 10,
}

export enum ETypeMovTitle {
  ingresos = 'INGRESO A BODEGA',
  egresos = 'EGRESO A BODEGA',
}

export type GeneralesState = {
  modulo: string
  currentAction: string
  currentFunction: string
  aplicacion: string
  loader: LoaderInfo
  loaderResumen: LoaderInfo
}

export type IngresosEgresosDatosEdicion = {
  codigo: number
  concepto: Movimiento | null
  conceptosMovimientos: Array<Movimiento> | []
  proveedor: Proveedor | null
  cliente: Cliente | null
  loading: boolean
  fecha: string
  documento: string
  loader: LoaderInfo
  desbloquear: boolean
  proyectos: Project | null
  proyectosData: Array<Project>
  local: LocalOption | null
  locales: Array<LocalOption>
  dataGrid: Array<Detalle>
  dataConjuntos: Array<Detalle>
  dataSeries: Array<any>
  dataLotes: Array<any>
  dataProCrear: Array<any>
  orden: any
  ordenNumero: number
  distinguir: boolean
  tipoMovimiento: TOptionGenerico | null
  item: ItemPedido | null
  codigoItem: number
  barras: string
  descripcion: string
  cantidad: number
  costo: number
  pvp: number
  pvd: number
  stock: number
  lotes: number
  numeroSerie: number
  existencia: number
  unidadDescripcion: string
  interno: string
  unidad: string
  IVA: number
  maxDoc: number
  impuesto: string
  numFact: string
  establecimeinto: string
  punto: string
  numArt: number
  subtotalIva: number
  subtotalIva0: number
  descuento12: number
  descuento0: number
  iva: number
  total: number
  observaciones: string
  guardado: boolean
  desdePlantilla: boolean
  adm: boolean
}

export type GuardarMovimiento = {
  infoRegistro: {
    codigo: number
    movimiento: number
    productoCliente: number
    localCodigo: number
    fecha: string
    documento: string
    tipo: string
    observaciones: string
    transito: number
    fechaCompra: string
    detalle: Array<any>
    series: Array<any>
    lotes: Array<any>
  }
}

export type IngresosEgresosState = {
  menu: MenuState
  general: GeneralesState
  tabs: TabsState<IngresosEgresosDatosEdicion>
  editData: CustomDictionary<IngresosEgresosDatosEdicion>
  search: BusquedaState<MovimientoTipo>
  config: ConfigIngEgr
}

export type BusquedaState<MovimientoTipo> = {
  filter: FiltrosIngresosEgresosState
  filterResumen: FiltrosIngresosEgresosState
  status: FETCH_STATUS
  statusResume: FETCH_STATUS
  statusItems: FETCH_STATUS
  mensaje: string
  resultados: Array<MovimientoTipo>
  resultadosResumen: Array<MovimientoTipo>
  resultadosItems: Array<any>
  tabs: Array<string>
  currentTab: string
  verRegistro: boolean
  colapsado: boolean
  colapsadoResumen: boolean
  seleccionado: MovimientoTipo
}

export type FiltrosIngresosEgresosState = {
  fechaDesde: string
  fechaHasta: string
  codigo: string
  documento: string
  almacen: LocalOption | null
  proveedor: Proveedor | null
  cliente: Cliente | null
  cosolidar: boolean
  inventarioInicial: boolean
  esRapida: boolean
  movimientos: Movimiento | null
  items: ItemPedido | null
  tipoItem: TOptionGenerico | null
  observaciones: string
  initial: boolean
}

export interface MovimientoTipo {
  codigo: number
  movimiento: number
  movimientoDescripcion: string
  tipo: string
  descripcion: string
  fecha: string
  documento: string
  productoCliente: number
  productoClienteIdentificacion: string
  productoClienteDescripcion: string
  localCodigo: number
  localCodigoDescripcion: string
  impreso: number
  observaciones: string
  estado: number
  estadoDescripcion: string
  transito: number
  fechaCompra: string
}

export type Detalle = {
  codigo: number
  impuesto: number
  codigoUsuario: string
  interno: string
  barras: string
  unidadDescripcion: string
  oCostos: number
  oCostosIVA: number
  descripcion: string
  cantidad: number
  lotes: number
  series: number
  pvp: number
  pvd: number
  precio: number
  numeroSerie: number
  stock: number
  existencia: number
  costo: number
  fracion: number
  valorDescripcion: number
  valor: number
}

export type ConfigIngEgr = {
  aplicacion: number
}


export type IngresosEgresosDto = {
  Code: number
  Document: string
  Movement: string
  Name: string
  Store: string
  Date: string
}

export type MovimientosResumenDto = {
  Code: string
  Description: string
  Group: string
  Line: string
  Brand: string
  Stock: number
  Cost: number
  Total: number
}

export type IngresoEgresoDto = {
  Code: number
  MovType: string
  Concep: string
  Associated: string
  Date: string
  Document: string
  Boved: string
  Items: Items[]
  SubTotalIva: number
  SubTotal12: number
  SubTotal0: number
  Discount12: number
  Discount0: number
  IVA: number
  Total: number
}


export type Items = {
  Barcode: string
  Unit: string
  Description: string
  Quantity: number
  UnitCost: number
  Original: number
  Total: number
}
