import { OptionCiudad } from '../../../../../containers/component/formLocal/types/types'
import { TabsState } from '../../../../../store/genericTabTypes'
import {
  CustomDictionary,
  FETCH_STATUS,
  LoaderInfo,
  TEstado,
} from '../../../../../store/types'
import { TOptionGenerico } from '../../../../clientes/pages/clientes/types/types'
import { LocalOption } from '../../../../componentes/localesLookUp/store/type'
import { OptionTiposLocales } from '../../../../componentes/tiposLocalesLookUp/types/types'

export type GeneralState = {
  modulo: string
  currentAction: string
  currentFunction: string
  aplicacion: string
  loader: LoaderInfo
  collapse: boolean
}

export type StoreList = {
  codigo: number
  calseLocal: TOptionGenerico | null
  stockWeb: boolean
  nombre: string
  siglas: string
  inv: string
  desc: number
  cuidad: OptionCiudad | null
  direccion: string
  telefono: string
  gerente: string
  tipo: OptionTiposLocales | null
  pertence: LocalOption | null | { codigo: number }
  transaccionEnCero: TOptionGenerico | null
  usarListaPrecios: TOptionGenerico | null
  codigoUaf: string
  estado: TEstado | null
  usuario: string
  contrasena: string
  imagen: string
  imagenCodificada: string
}

export type SaveStore = {
  infoRegistro: {
    codigo: number
    clase: number
    nombre: string
    siglas: string
    ciudad: number
    direccion: string
    telefono: string
    gerente: string
    tipo: number
    pertenece: number
    kardex: number
    estado: number
    siglasBOD: string
    web: number
    empresaCodigo: number
    uaf: string
    descuento: number
    usuarioDokan: string
    passwordDokan: string
    listaPrecios: number
    nameLogo: string
    codeFileLogo: string
  }
}

export type MultistoreState = {
  general: GeneralState
  tabs: TabsState<StoreList>
  editData: CustomDictionary<StoreList>
  search: SearchState<any>
}

export type SearchState<T> = {
  filter: FilterStoreState
  status: FETCH_STATUS
  message: string
  resultList: Array<T>
}

export type FilterStoreState = {
  nombre: string
  estado: number
  codigo: number
  uaf: string
  clase?: TOptionGenerico | null
}

export type StoreListResult = {
  localCodigo: number
  localCiudad: number
  localCiudadNombre: string
  localNombre: string
  localDireccion: string
  localTelefono: string
  localGerente: string
  localTipo: number
  localTipoDescripcion: string
  localClase: number
  localClaseDescripcion: string
  localPertenece: number
  localPerteneceDescripcion: string
  localEstado: number
  localEstadoDescripcion: string
  localKardex: number
  localSiglas: string
  localSiglasLocal: string
  localWEB: number
  localUAF: string
  localDescuento: number
  localContrato: number
  localObservaciones: string
  localUsuarioDokan: string
  localPasswordDokan: string
  ULOTipo: string
  localLogo: string
  localListaPrecios: number
}

export const optionsSearch = [
  { value: 0, label: 'Almacén' },
  { value: 1, label: 'Bodega General' },
  { value: 2, label: 'Bodega Sucursales' },
]

export const optNegativos = [
  { value: -1, label: 'No' },
  { value: 0, label: 'Negativos' },
]

export const optListaPrecios = [
  { value: 1, label: 'Si' },
  { value: 0, label: 'No' },
]
