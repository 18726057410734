import React from 'react'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import { DataGrid } from 'devextreme-react'
import { Column, ColumnChooser, Export, Pager, Paging } from 'devextreme-react/data-grid'
import { getAllowedPageSizes } from '../../../../../../helpers/Helper'
import ItemsCountIndicator from '../../../../../componentes/indicadorNroRegistros'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/store'
import { consoleService } from '../../../../../../services/console.service'
import { changeCurrentTab, fetchLotsTransactions, setSeleccionarDato } from '../../store/serachReducer'
import { ToastTypes } from '../../../../../../store/types'
import { addToast } from '../../../../../../store/toasterReducer'
import { changeLoaderKardex } from '../../store/generalReducer'
import { setCurrentExecutingAction } from '../../store/tabsReducer'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import { CCardFooter } from '@coreui/react-pro'

const LotesAgregadosItem = () => {
  const dispatch = useDispatch()
  const searchResults = useSelector((state: RootState) => state.inventarios.lotes.search.resultados)
  const selected = useSelector((state: RootState) => state.inventarios.lotes.search.seleccionado)

  const dataGrid = React.useRef<any>()

  const setLoader = React.useCallback(
    (mostrar: boolean, message = 'Cargando...') => {
      dispatch(changeLoaderKardex({ show: mostrar, mensaje: message }))
    },
    [dispatch],
  )

  const setButtonExecution = React.useCallback((type: ButtonTypes | undefined) => {
    dispatch(
      setCurrentExecutingAction({
        tabKey: 'BUSQUEDA',
        buttonType: type,
      }),
    )
  }, [dispatch])

  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(
      addToast({
        title: 'Inventario-Lotes',
        content: mensaje,
        type: tipo,
      }),
    )
  }, [dispatch])

  const onSelectedLotChanged = React.useCallback(({ selectedRowsData }) => {
    consoleService.log('selected ', selectedRowsData[0])
    dispatch(setSeleccionarDato({ ...selectedRowsData[0] }))
  }, [dispatch])

  const onDBClickTran = React.useCallback(async () => {
    if (selected !== null && selected !== undefined && selected?.codigo) {
      try {
        setButtonExecution(ButtonTypes.find)
        setLoader(true, 'Cargando Transaccion...')
        const toAny: any = fetchLotsTransactions(selected?.codigo)
        const res = await dispatch(toAny)
        setLoader(false, '')
        setButtonExecution(undefined)
        consoleService.log(res, '--resTran')
        if (res !== null && res !== undefined && res['payload']['error'] === false) {
          setToast(res['payload']['message'], ToastTypes.Success)
        } else {
          setToast(res['payload']['message'], ToastTypes.Danger)
        }
        dispatch(changeCurrentTab('Transacciones'))
      } catch (error) {
        setLoader(false, '')
        setButtonExecution(undefined)
        setToast(error, ToastTypes.Danger)
      }
    }
  }, [dispatch, selected, setToast, setLoader, setButtonExecution])

  return (
    <>
      <CCardFooter>
        <RowContainer>
          <ItemsCountIndicator items={searchResults['auto'] ?? []} />
          <DataGrid
            ref={dataGrid}
            keyExpr="codigo"
            focusedRowKey="codigo"
            id="tabCertificados"
            selection={{ mode: 'single' }}
            dataSource={searchResults['auto'] ?? []}
            showColumnLines={true}
            showRowLines={true}
            showBorders={true}
            columnResizingMode="widget"
            focusedRowEnabled={true}
            onSelectionChanged={onSelectedLotChanged}
            onRowDblClick={onDBClickTran}
            loadPanel={{
              enabled: true,
              height: 90,
              indicatorSrc: '',
              shading: false,
              shadingColor: '',
              showIndicator: true,
              showPane: true,
              text: 'Cargando...',
              width: 200,
            }}
          >
            <Paging defaultPageSize={20} />
            <Pager
              visible={(searchResults['auto'] && searchResults['auto'].length > 0) ? true : false}
              allowedPageSizes={getAllowedPageSizes(searchResults['auto'] ?? [])}
              displayMode={'full'}
              showPageSizeSelector={true}
              showInfo={true}
              showNavigationButtons={true}
            />
            <Export enabled={true} allowExportSelectedData={true} />
            <ColumnChooser enabled={true} mode="select" />
            <Column dataField="numero" caption="Número" width="100px" />
            <Column dataField="barras" caption="Cod. Barras" width="100px" />
            <Column dataField="caducidad" dataType={'string'} caption="Caducidad" width="100px" />
            <Column dataField="ubicado" caption="Ubicación" width="100px" />
            <Column dataField="existencia" caption="Existencia" width="100px" />
          </DataGrid>
        </RowContainer>
      </CCardFooter>
    </>
  )
}

export default React.memo(LotesAgregadosItem)
