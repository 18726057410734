import { FC, PropsWithChildren, useCallback, useState } from 'react'
import Button from 'devextreme-react/button'
import ActionSheet from 'devextreme-react/action-sheet'

const buttons: any[] = [
  { text: 'Editar Cargo', icon: 'edit' },
  { text: 'Eliminar Cargo', icon: 'trash' },
  { text: 'Permisos Cargo', icon: 'key' },
]

interface IActionSheetCommandsMobileProps extends PropsWithChildren {
  // eslint-disable-next-line no-unused-vars
  onEdit: () => void
  // eslint-disable-next-line no-unused-vars
  onDelete: () => void
  // eslint-disable-next-line no-unused-vars
  onPermises: () => void
}

export const ActionSheetCommandsMobile: FC<IActionSheetCommandsMobileProps> = (
  props,
) => {
  const { onEdit, onDelete, onPermises } = props
  const [visible, setVisible] = useState<boolean>(false)

  const onItemClick = useCallback(
    (item) => {
      const { itemData } = item
      switch (itemData.text) {
        case buttons[0].text:
          onEdit()
          break
        case buttons[1].text:
          onDelete()
          break
        case buttons[2].text:
          onPermises()
          break
        default:
          break
      }
    },
    [onDelete, onEdit, onPermises],
  )

  const keyId = `rowCandidate${crypto.randomUUID()}`

  return (
    <>
      <Button
        id={keyId}
        icon="overflow"
        type="default"
        onClick={() => {
          setVisible(!visible)
        }}
      />
      <ActionSheet
        title="Tareas"
        usePopover={true}
        visible={visible}
        target={'#' + keyId}
        items={buttons}
        itemComponent={ActionSheetCommand}
        onItemClick={onItemClick}
        onVisibleChange={(value) => {
          if (value !== visible) setVisible(!visible)
        }}
      />
    </>
  )
}

const ActionSheetCommand: FC<any> = (props) => {
  return (
    <Button
      stylingMode="contained"
      type="default"
      text={props.data.text}
      icon={props.data.icon}
    />
  )
}
