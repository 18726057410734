import React, { useCallback } from 'react'
import { ToastTypes } from '../../../../../store/types'
import RowContainer from '../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../views/componentes/colContainer'
import Labeled from '../../../../../views/componentes/labeledInput/labeledInput'
import { List } from 'devextreme-react'
import { FilterRow } from 'devextreme-react/gantt'
import DataGrid, { Column, HeaderFilter, Pager, Paging } from 'devextreme-react/data-grid'
import { HoursLoadService } from '../../../horariosCombosLookUp/services/HoursCombos.services'
import { GetHour, THoursLoad } from '../../../horariosCombosLookUp/types/types'
import { DetailReister } from '../../../modalDetallesRegistro/services/detalleRegistro.services'
import { DataHours } from '../types/types'
import { RegisterDetail } from '../../../modalDetallesRegistro/types/types'
import { consoleService } from '../../../../../services/console.service'


interface IBuscarProps extends React.PropsWithChildren {
  onChanged: (data) => void
  onSelection: (data) => void
  onSelectionHour: (data) => void
  deletedItem: any,
  currentTab: string
  setCurrenTab?: (currentTab: string) => void
  onCancel?: () => void
  funcion: string
  resetSelected?: () => void
  resetFunction: () => void
  setToast: (sms: string, type: ToastTypes) => void
  playLoader: () => void
  stopLoader: () => void
  data: DataHours
}

const Buscar = (props: IBuscarProps) => {
  const { funcion, setCurrenTab, onChanged, data, resetFunction, setToast, stopLoader, playLoader, onSelection, deletedItem, onSelectionHour, currentTab, resetSelected } = props

  const formSearchRef = React.useRef<any>()
  const [horarios, setHorarios] = React.useState<Array<THoursLoad> | []>([])
  const [detalle, setDetalle] = React.useState<Array<RegisterDetail> | []>([]);


  const handleSubmit = React.useCallback(async () => {
    setDetalle([])
    setHorarios([])
    playLoader()
    try {
      const data_request: GetHour = {
        htrcodigo: data?.htrcodigo,
        dia: data?.dia,
        codigo: 0
      }
      const dataHorarios = await HoursLoadService.getHoursLoad(data_request, true, 'Ninguno')
      const dataRegistro = await DetailReister.getDetail(data?.usuario, data?.fecha)
      consoleService.log(dataHorarios, 'get horarios')
      if (dataHorarios?.auto && dataHorarios?.error === false) {
        setHorarios(dataHorarios?.auto ?? [])
      } else {
        setToast(dataHorarios?.message, ToastTypes.Danger)
      }
      consoleService.log(dataRegistro, 'get registro')
      if (dataRegistro?.auto && dataRegistro?.error === false) {
        setDetalle(dataRegistro?.auto ?? [])
      } else {
        setToast(dataRegistro?.message, ToastTypes.Danger)
      }
    } catch (error) {
      setToast(JSON.stringify(error), ToastTypes.Danger)
    }
    stopLoader()
  }, [data, playLoader, stopLoader, setToast])


  React.useEffect(() => {
    if (currentTab === 'Buscar') {
      if (funcion === 'Buscar') {
        handleSubmit()
      } else if (funcion === 'Eliminar') {
        // deleteItemTable(deletedItem)
      }
      resetFunction()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [funcion])

  React.useEffect(() => {
    handleSubmit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSelectedChanged = useCallback(
    ({ selectedRowsData }) => {
      consoleService.log(selectedRowsData[0], 'seleccionado selectedRowsData[0]')
      if (selectedRowsData[0] && selectedRowsData[0]?.codigo) {
        onSelection(selectedRowsData[0])
      }
    },
    [onSelection],
  )
  const onSelectionChangeHour = React.useCallback(({ addedItems }) => {
    consoleService.log(addedItems[0], 'Usurios select')
    if (addedItems[0]?.codigoUsuario) {
      onSelectionHour({ ...addedItems[0] })
    }
  }, [onSelectionHour])

  return (
    <>
      <RowContainer>
        <CustomCol xs="12" md="6">
          <RowContainer>
            <CustomCol>
              <DataGrid
                selection={{ mode: 'single' }}
                className='border'
                dataSource={detalle ?? []}
                onSelectionChanged={onSelectedChanged}
                width={'100%'}
                keyExpr=''
              >
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                <Paging
                  enabled={true}
                  defaultPageSize={10}
                />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[8, 12, 20]}
                  showNavigationButtons={true}
                  showInfo={true}
                  infoText="Page #{0}. Total: {1} ({2} items)"
                />
                <Column dataField='registro' caption='Registro' width='120px' allowEditing={false} />
                <Column dataField='hora' caption='Hora de Registro' width='120px' allowEditing={false} />
                <Column dataField='horaa' caption='Hora debida' width='120px' allowEditing={false} />
              </DataGrid>
            </CustomCol>
          </RowContainer>
        </CustomCol>
        <CustomCol xs="12" md="6">
          <Labeled label='Detalle del Horario:'>
            <List
              dataSource={horarios ?? []}
              keyExpr="codigo"
              onSelectionChanged={onSelectionChangeHour}
              displayExpr='descripcion'
              height={100}
              selectionMode='single'
            />
          </Labeled>
        </CustomCol>
      </RowContainer>
    </>
  )
}

export default Buscar