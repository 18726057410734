import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FETCH_STATUS, LoaderInfo } from "../../../../../store/types";
import { BusquedaState, FiltroBusquedaPersonal, FiltroUsuariosLocales, PersonalList, PersonalNuevoTabs, UsuarioLocalList } from "../types/types";
import { PersonalServices } from '../services/personal.service';


const initialState: BusquedaState<PersonalList> = {
  filtro: {
    usuario: '',
    cedula: '',
    nombres: '',
    apellidos: '',
    pais: null,
    locales: null,
    activos: true,
    initital: true,
  },
  filtroUsuarioLocal: {
    tipoUsuario: null,
    locales: null,
    initial: true
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  resultados: [],
  resultadosUsuarioLocal: [],
  resultadosUsuarioSinLocal: [],
  seleccionado: null,
  seleccionadoUsuario: null,
  seleccionadoLocal: null,
  colapsado: true,
  import: false,
  searchPersonal: false,
  loader: {
    show: false,
    mensaje: '',
  },
  loaderUserLocal: {
    show: false,
    mensaje: '',
  },
  tabs: [PersonalNuevoTabs.personal, PersonalNuevoTabs.estudios, PersonalNuevoTabs.extras, PersonalNuevoTabs.login, PersonalNuevoTabs.rentas, PersonalNuevoTabs.normas],
  currentTab: PersonalNuevoTabs.personal,
  nroRegistrosEncontrados: 0
}


export const fetchPersonalList = createAsyncThunk<
  Array<PersonalList>,
  { filtro: FiltroBusquedaPersonal, tipo: string }
>('nomina/personal/listar', async (filtro) => {
  try {
    const linesList = PersonalServices.getPersonal(filtro.filtro, filtro.tipo)
    return linesList
  } catch (error) {
    return Promise.reject(error)
  }
})

const searchSlice = createSlice({
  name: 'buscarPersonal',
  initialState: initialState,
  reducers: {
    setResetResults(state) {
      state.resultados = []
    },
    setResults(state, action: PayloadAction<PersonalList[]>) {
      state.resultados = action.payload
    },
    setSearch(state, action: PayloadAction<boolean>) {
      state.searchPersonal = action.payload
    },
    setResultsUserLocal(state, action: PayloadAction<UsuarioLocalList[]>) {
      state.resultadosUsuarioLocal = action.payload
    },
    setResultsUserSinLocal(state, action: PayloadAction<UsuarioLocalList[]>) {
      state.resultadosUsuarioSinLocal = action.payload
    },
    changeFilter(state, action: PayloadAction<FiltroBusquedaPersonal>) {
      state.filtro.usuario = action.payload.usuario
      state.filtro.cedula = action.payload.cedula
      state.filtro.nombres = action.payload.nombres
      state.filtro.apellidos = action.payload.apellidos
      state.filtro.pais = action.payload.pais
      state.filtro.locales = action.payload.locales
      state.filtro.activos = action.payload.activos
      state.filtro.initital = action.payload.initital
    },
    changeFilterUserLocal(state, action: PayloadAction<FiltroUsuariosLocales>) {
      state.filtroUsuarioLocal.tipoUsuario = action.payload.tipoUsuario
      state.filtroUsuarioLocal.locales = action.payload.locales
      state.filtroUsuarioLocal.initial = action.payload.initial
    },
    setChangeTab(state, action: PayloadAction<string>) {
      state.currentTab = action.payload
    },
    setResetSeleccion(state) {
      state.seleccionado = null
    },
    setSeleccionarDato(state, action: PayloadAction<PersonalList>) {
      state.seleccionado = action.payload
    },
    setResetSeleccionUsuario(state) {
      state.seleccionadoUsuario = null
    },
    setSeleccionarDatoUser(state, action: PayloadAction<UsuarioLocalList>) {
      state.seleccionadoUsuario = action.payload
    },
    setResetSeleccionlocal(state) {
      state.seleccionadoLocal = null
    },
    setSeleccionarDatoLocal(state, action: PayloadAction<UsuarioLocalList>) {
      state.seleccionadoLocal = action.payload
    },
    setCollapsed(state, action: PayloadAction<boolean>) {
      state.colapsado = action.payload
    },
    setImport(state, action: PayloadAction<boolean>) {
      state.import = action.payload
    },
    setNroRegistrosEncontradas(state, action: PayloadAction<number>) {
      state.nroRegistrosEncontrados = action.payload
    },
    setChangeLoader(state, action: PayloadAction<LoaderInfo>) {
      state.loader = action.payload
    },
    setChangeLoaderUserLocal(state, action: PayloadAction<LoaderInfo>) {
      state.loaderUserLocal = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPersonalList.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchPersonalList.fulfilled, (state, { payload }) => {
      state.resultados = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchPersonalList.rejected, (state, { payload }) => {
      state.resultados = []
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
  },
})

export const {
  setResetSeleccion,
  setSeleccionarDato,
  setCollapsed,
  changeFilter,
  setImport,
  setChangeTab,
  setNroRegistrosEncontradas,
  setChangeLoader,
  setResults,
  changeFilterUserLocal,
  setResultsUserLocal,
  setChangeLoaderUserLocal,
  setResultsUserSinLocal,
  setResetSeleccionUsuario,
  setSeleccionarDatoUser,
  setResetSeleccionlocal,
  setSeleccionarDatoLocal,
  setSearch,
} = searchSlice.actions

export const searchReducer = searchSlice.reducer