import { RequestHelper } from "../../../../../../../helpers/requestHelper"
import { CuotaCreditoVenta } from "../../../../../../ventas/types/types"


export const SaldosDctosYCtasXCobrar = {
  getSaldoInicial,
  getNumUltimoSaldo,
  deleteSaldo,
  saveSaldo,
  getTotalSinRetencion,
  getCuotasVenta,
  postCuotas,
  deleteCuotas,
}

async function getSaldoInicial(
  cliente: number,
  fecha: string,
  venta: string,
): Promise<Promise<any>> {
  const query = {
    clienteCodigo: cliente,
    ventaFecha: fecha,
    ventaNumero: venta,
  }

  try {
    const dataPendientes = await RequestHelper.getAll<any>(
      'clientes/DocCuentasPorCobrar',
      'sales/getOpeningBalance',
      '',
      query,
    )
    return dataPendientes
  } catch (error) {
    console.log(error)
    return error
  }
}

async function getNumUltimoSaldo(
  cliente: number,
  venta: string,
): Promise<Promise<any>> {
  const query = {
    clienteCodigo: cliente,
    ventaNumero: venta,
  }

  try {
    const dataPendientes = await RequestHelper.getAll<any>(
      'clientes/DocCuentasPorCobrar',
      'sales/getNumberOfLastClientBalance',
      '',
      query,
    )
    return dataPendientes
  } catch (error) {
    console.log(error)
    return error
  }
}


async function deleteSaldo(
  vencodigo: number,
  venfecha: string
): Promise<Promise<any>> {
  const query = {
    ventaCodigo: vencodigo,
    ventaFecha: venfecha,
  }

  try {
    const dataPendientes = await RequestHelper.deleteRequestAll<any>(
      'clientes/DocCuentasPorCobrar',
      'balance/delete',
      '',
      query,
    )
    return dataPendientes
  } catch (error) {
    console.log(error)
    return error
  }
}

async function saveSaldo(
  codigo: number,
  tipoDocumento: string,
  numero: string,
  autorizacion: string,
  establecimiento: string,
  puntoEmision: string,
  fecha: string,
  guia: string,
  subtotal12: number,
  subtotal0: number,
  descuento: number,
  subtotal: number,
  ice: number,
  subtotalSinIva: number,
  iva: number,
  irbpnr: number,
  total: number,
  formaPago: number,
  clienteCodigo: number,
  usuaCodigo: number,
  empresaCodigo: number,
  localCodigo: number,
  observaciones: string,
  impreso: number,
  servicios: number,
  sucursalCodigo: number,
  detalle: Array<any>,
  pagos: Array<any>,
  series: Array<any>,
  lotes: Array<any>,
  equipo: Array<any>,
  ventaCodigoMod: number,
): Promise<Promise<any>> {
  const obj = {
    infoRegistro: {
      codigo,
      tipoDocumento,
      numero,
      autorizacion,
      establecimiento,
      puntoEmision,
      fecha,
      // fechaN: "",
      guia,
      subtotal12,
      subtotal0,
      descuento,
      // descuentoG: 0,
      subtotal,
      ice,
      subtotalSinIva,
      iva,
      irbpnr,
      total,
      formaPago,
      clienteCodigo,
      usuaCodigo,
      empresaCodigo,
      localCodigo,
      observaciones,
      impreso,
      servicios,
      sucursalCodigo,
      detalle,
      pagos,
      series,
      lotes,
      equipo,
      ventaCodigoMod,
    }
  }

  console.log(obj)

  try {
    const dataPendientes = await RequestHelper.postAll<any>(
      'ventas',
      'ventas/save',
      obj,
    )
    return dataPendientes
  } catch (error) {
    console.log(error)
    return error
  }
}



async function getTotalSinRetencion(
  ventaCodigo: number,
  tipoPago: number,
): Promise<Promise<any>> {
  const query = {
    ventaCodigo,
    tipoPago,
  }

  try {
    const datCuotas = await RequestHelper.getAll<any>(
      'ventas',
      'getTotalWithoutRetentionSales',
      '',
      query,
    )
    return datCuotas
  } catch (error) {
    console.log(error)
    return error
  }
}

async function getCuotasVenta(
  codigo: number,
): Promise<Promise<any>> {
  const query = {
    codigo,
  }

  try {
    const datCuotas = await RequestHelper.getAll<any>(
      'ventas',
      'getSalesInstallments',
      '',
      query,
    )
    return datCuotas
  } catch (error) {
    console.log(error)
    return error
  }
}

async function postCuotas(
  venCodigo: number,
  cuentas: Array<CuotaCreditoVenta>
): Promise<Promise<any>> {
  const obj = {
    infoRegistro: {
      venCodigo,
      cuentas
    }
  }

  try {
    const datCuotas = await RequestHelper.postAll<any>(
      'ventas/ventas',
      'saveInstalment',
      obj,
    )
    return datCuotas
  } catch (error) {
    console.log(error)
    return error
  }
}


async function deleteCuotas(
  codigo: string
): Promise<Promise<any>> {
  const obj = {
    codigo
  }

  try {
    const datCuotas = await RequestHelper.deleteRequestAll<any>(
      'proveedores/general',
      'paymentsPurchases/deleteAll',
      '',
      obj,
    )
    return datCuotas
  } catch (error) {
    console.log(error)
    return error
  }
}