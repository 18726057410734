import React from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import { setSeleccionarDato } from "./../../store/modalCuentasReducer";
import { setData, setDataBack } from "./../../store/modalCuentasReducer"
import { Column, DataGrid, FilterRow, HeaderFilter, Pager, LoadPanel as LoadPanelGrid, Editing, Selection, Button as DatagridButton } from 'devextreme-react/data-grid';
import { CCol, CRow } from '@coreui/react-pro'
import { utilidades } from '../../../../../../../helpers/utilidades';
import { LoadPanel } from 'devextreme-react/load-panel';
import { isMobileOnly } from 'react-device-detect'

interface TablaProps extends React.PropsWithChildren {
  name: string,
  data: any,
  actionDelete: () => void,
  actionCargaCuenta: (evt) => void,
}

const Tabla = (props: TablaProps) => {
  const { actionCargaCuenta, data } = props;

  const dispatch = useDispatch();
  const dataGrid = React.useRef<any>();

  const loader = useSelector((state: RootState) => state.global.loader);
  const [registros, setRegistros] = React.useState<any>([]);


  const clearFilter = React.useCallback(() => {
    dataGrid.current.instance.clearSorting();
    dataGrid.current.instance.clearFilter();
  }, []);


  const llenarData = React.useCallback((data: any) => {
    clearFilter();
    let item: any = []
    try {
      item = data.map(function (key, i) {
        return {
          ...key,
          ORDEN: Number(i),
          PLA_DESCRIPCION_SHOW: key.PLA_SHOW === undefined ? key.descripcion : key.PLA_SHOW
        }
      })
      setRegistros(item.map(x => utilidades.unfreeze(x)))
    } catch (error) {
      console.error("on llenarData", error)
    }
  }, [clearFilter]);

  const onRowDblClick = React.useCallback((rowDblClick) => {
    console.log("onRowDblClick", rowDblClick)
    actionCargaCuenta(rowDblClick['data'])
    dispatch(setData([]));
    dispatch(setDataBack([]));
  }, [actionCargaCuenta, dispatch]);

  const onSelectionChanged = React.useCallback((selectionChanged) => {
    dispatch(setSeleccionarDato(
      {
        index: selectionChanged['selectedRowKeys'],
        row: selectionChanged['selectedRowsData'][0],
        selectedRow: [selectionChanged['selectedRowKeys']]
      }
    ))
  }, [dispatch]);


  React.useEffect(() => {
    if (data.length > 0) {
      llenarData(data)
    } else {
      setRegistros([])
    }
  }, [data]);

  return (
    <>

      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={'center'}
        visible={loader.show === true}
        showIndicator={true}
        shading={true}
        showPane={true}
        message='Buscando...'
        closeOnOutsideClick={false}
      />

      <CRow>
        <CCol lg="12" className="d-flex justify-content-end">
          <strong>{registros.length}</strong>
        </CCol>
      </CRow>

      <CRow>
        <CCol >
          <DataGrid
            ref={dataGrid}
            selection={{ mode: 'single' }}
            hoverStateEnabled={true}
            dataSource={registros}
            showBorders={true}
            showRowLines={true}
            onSelectionChanged={onSelectionChanged}
            onRowDblClick={onRowDblClick}
            allowColumnReordering={true}
            allowColumnResizing={true}
            columnAutoWidth={true}
            keyExpr="ORDEN"
            key="gridTabBalanceComprobacion"
            id="content-cuentas"
          >
            <LoadPanelGrid enabled={true} />
            <FilterRow visible={true} />
            <HeaderFilter visible={true} />
            <Pager
              showPageSizeSelector={true}
              allowedPageSizes={[5, 10, 20, 40, 60, 80, 100, registros.length]}
            />
            <Editing mode='cell' allowUpdating={true} />
            <Selection
              mode="single"
              selectAllMode={"onClick"}
              showCheckBoxesMode={[]}
            />
            <Column type='buttons' width={isMobileOnly ? "15%" : "10%"} >
              <DatagridButton icon='add' visible={true} hint='Seleccionar'
                onClick={(e) => {
                  onRowDblClick(e.row)
                }} />
            </Column>
            <Column visible={false} dataField="ORDEN" caption="Orden" width={90} />
            <Column allowEditing={false} dataField='numero' caption='Código' width='100%' />
            <Column allowEditing={false} dataField='PLA_DESCRIPCION_SHOW' caption='Descripción' width='100%' />
          </DataGrid>
        </CCol>
      </CRow>

    </>
  );
}

export default React.memo(Tabla);