import React from 'react'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import { DataGrid } from 'devextreme-react'
import { useDispatch, useSelector } from 'react-redux'
import { setCollapsed, setSeleccionarDato } from '../../store/serachReducer'
import { Workbook } from 'exceljs'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { saveAs } from 'file-saver'
import { consoleService } from '../../../../../../../services/console.service'
import {
  Column,
  Export,
  Pager,
  Paging,
  Button as DatagridButton,
  ColumnChooser,
} from 'devextreme-react/data-grid'
import { allowedPageSizes } from '../../../../../../../helpers/Helper'
import { ActionSheetCommandsMobile } from './ActionSheetCommandsMobile'
import { UnidadInventario } from '../../../../../store/types'
import { isMobile } from 'react-device-detect'
import DataSource from 'devextreme/data/data_source'
import { RootState } from '../../../../../../../store/store'
import ItemsCountIndicator from '../../../../../../componentes/indicadorNroRegistros'

interface IResultsProps extends React.PropsWithChildren {
  onDBClick: () => void
  handleOptionChange: (e: any) => void
  data: DataSource
  pageIndex: number
  pageSize: number
  onDelete: () => void
}

const ResultadosBusqueda: React.FunctionComponent<IResultsProps> = (props) => {
  const { onDBClick, data, handleOptionChange, pageIndex, pageSize, onDelete } =
    props

  const nroItemsFound = useSelector(
    (state: RootState) =>
      state.inventarios.catalogos.unidades.search.nroItemsEncontrados,
  )

  const dispatch = useDispatch()
  const tablaRefPager = React.useRef<any>()
  const [isPagin] = React.useState<boolean>(true)
  const onCollapsed = React.useCallback(() => {
    if (nroItemsFound > 5) {
      dispatch(setCollapsed(false))
    }
  }, [dispatch, nroItemsFound])
  const onSelectedChange = React.useCallback(
    ({ selectedRowsData }) => {
      consoleService.log(selectedRowsData[0])
      dispatch(
        setSeleccionarDato({
          index: selectedRowsData[0],
          row: selectedRowsData[0],
          selectedRow: selectedRowsData[0],
        }),
      )
    },
    [dispatch],
  )



  const onExportExcell = React.useCallback((e) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('Main sheet')
    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'Unidades_Inventario.xlsx',
        )
      })
    })
  }, [])


  React.useEffect(() => {
    onCollapsed()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nroItemsFound])

  const actionSheetCommandsMobile = React.useCallback(
    (cellData) => {
      const data = cellData.data as UnidadInventario
      return (
        <ActionSheetCommandsMobile
          unidad={data}
          key={data.codigo}
          onDelete={(data) => {
            dispatch(
              setSeleccionarDato({
                index: data?.codigo ?? 0,
                row: data,
                selectedRow: data,
              }),
            )
            onDelete()
          }}
          onEdit={(data) => {
            dispatch(
              setSeleccionarDato({
                index: data?.codigo ?? 0,
                row: data,
                selectedRow: data,
              }),
            )
            onDBClick()
          }}
        />
      )
    },
    [dispatch, onDelete, onDBClick],
  )

  return (
    <>
      <ItemsCountIndicator numberOfItems={data ? nroItemsFound : 0} />
      <RowContainer>
        <CustomCol xs="12" md="12">
          <DataGrid
            id="tablaSeguimientoRegistros"
            ref={tablaRefPager}
            dataSource={data}
            selection={{ mode: 'single' }}
            hoverStateEnabled={true}
            showBorders={true}
            showRowLines={true}
            focusedRowEnabled={true}
            onSelectionChanged={onSelectedChange}
            onRowDblClick={onDBClick}
            repaintChangesOnly={true}
            highlightChanges={true}
            keyExpr="uuid"
            key="uuid"
            focusedRowKey="uuid"
            showColumnLines={true}
            onExporting={(e) => onExportExcell(e)}
            className={'m-2 p-2'}
            onOptionChanged={(e) => handleOptionChange(e)}
            remoteOperations={true}
            loadPanel={{
              enabled: true,
              height: 90,
              indicatorSrc: '',
              shading: false,
              shadingColor: '',
              showIndicator: true,
              showPane: true,
              text: 'Cargando...',
              width: 200,
            }}
          >
            <Paging
              pageIndex={pageIndex}
              pageSize={pageSize}
              enabled={isPagin}
            />
            <Pager
              ref={tablaRefPager}
              showPageSizeSelector={true}
              allowedPageSizes={allowedPageSizes}
              showNavigationButtons={true}
              showInfo={true}
              infoText="Page #{0}. Total: {1} ({2} items)"
            />
            <Export enabled={true} allowExportSelectedData={true} />
            <ColumnChooser enabled={true} mode="select" />
            {!isMobile && (
              <Column type="buttons" width="100px">
                <DatagridButton
                  icon="trash"
                  hint="Eliminar"
                  onClick={async (e) => {
                    consoleService.log('e', e)
                    const data: UnidadInventario = e.row.data
                    dispatch(
                      setSeleccionarDato({
                        index: data?.codigo ?? 0,
                        row: data,
                        selectedRow: data,
                      }),
                    )
                    onDelete()
                  }}
                />
                <DatagridButton
                  icon="edit"
                  hint="Editar"
                  onClick={async (e) => {
                    consoleService.log('e', e)
                    const data: UnidadInventario = e.row.data
                    dispatch(
                      setSeleccionarDato({
                        index: data?.codigo ?? 0,
                        row: data,
                        selectedRow: data,
                      }),
                    )
                    onDBClick()
                  }}
                />
              </Column>
            )}
            {isMobile && (
              <Column
                caption="Tareas"
                dataField="codigo"
                cellRender={actionSheetCommandsMobile}
                width="50px"
                allowFiltering={false}
              ></Column>
            )}
            <Column dataField="nombre" caption="Nombre" width="75%" />
            <Column dataField="siglas" caption="Abreviatura" width="25%" />
            <Column dataField="grupoNombre" caption="Tipo" width="25%" />
          </DataGrid>
        </CustomCol>
      </RowContainer>
    </>
  )
}

export default React.memo(ResultadosBusqueda)
