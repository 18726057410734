import { DataGrid } from 'devextreme-react';
import { Column, FilterRow, HeaderFilter, Pager, Paging, Button as DatagridButton } from 'devextreme-react/data-grid';
import React from 'react';
import { ToastTypes } from '../../../../../store/types';
import CustomCol from '../../../../../views/componentes/colContainer';
import RowContainer from '../../../../../views/componentes/rowContainer/rowContainer';

interface IResultadoBusquedaProps extends React.PropsWithChildren {
  funcion: string,
  data: any
  setToast: (sms: string, type: ToastTypes) => void,
  capturaSeleccion: (data) => void,
  onChanged: (data) => void;
}

const ResultadoBusqueda: React.FunctionComponent<IResultadoBusquedaProps> = (props) => {

  const { capturaSeleccion, data, onChanged } = props
  const [items, setItems] = React.useState<Array<any> | []>(data)
  const tablaRef = React.useRef<any>()
  const tablaRefPager = React.useRef<any>()

  const selectionChanged = React.useCallback((selectionChanged) => {

    if (items.length > 0) {
      if (selectionChanged['selectedRowsData'].length > 0) {

        const filt: number = items.findIndex((item: any) => {
          return Number(item.codigo) === Number(selectionChanged['selectedRowsData'][0]['codigo']);
        });

        capturaSeleccion(
          {
            index: filt,
            row: selectionChanged['selectedRowsData'][0],
            selectedRow: filt
          }
        )

      }
    }
  }, [items, capturaSeleccion]);

  React.useEffect(() => {
    setItems(data)
  }, [data])

  return (

    <RowContainer className='mt-2 '>
      <CustomCol>
        <DataGrid
          ref={tablaRef}
          selection={{ mode: 'single' }}
          className='border'
          dataSource={items ?? []}
          onSelectionChanged={selectionChanged}
          width={'100%'}
          keyExpr='codigo'
        >
          <FilterRow visible={true} />
          <HeaderFilter visible={true} />
          <Paging
            enabled={true}
            defaultPageSize={10}
          />
          <Pager
            ref={tablaRefPager}
            showPageSizeSelector={true}
            allowedPageSizes={[8, 12, 20]}
            showNavigationButtons={true}
            showInfo={true}
            infoText="Page #{0}. Total: {1} ({2} items)"
          />
          <Column type='buttons' width='50px' >
            <DatagridButton icon='arrowleft' visible={true} hint='Seleccionar'
              onClick={(e) => {
                onChanged(e.row.data)
              }} />
          </Column>
          <Column dataField='nombre' caption='Local' width='200px' allowEditing={false} />
          <Column dataField='tipo' caption='Tipo' width='80px' allowEditing={false} />
          <Column dataField='ciudadNombre' caption='Ciudad' width='100px' allowEditing={false} />
          <Column dataField='direccion' caption='Dirección' width='400px' allowEditing={false} />
          <Column dataField='telefono' caption='Teléfono ' width='100px' allowEditing={false} />
        </DataGrid>
      </CustomCol>

    </RowContainer>

  );
}
export default ResultadoBusqueda;

