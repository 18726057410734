import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FiltrosImportacionesState, BusquedaState, MovimientoImp } from '../types/types'
import { FETCH_STATUS } from '../../../../../../store/types';
import { initSearch } from '../components/buscar/filtroBusqueda';
import { initSearchRes } from '../components/resumenProductos';
import { incomeExpensesServices } from '../../ingresosEgresos/services/ingresosEgresos.services';
import { EAplicationsAcatha } from '../../../../../../store/enumsAplication';

const initialState: BusquedaState<MovimientoImp> = {
  filter: { ...initSearch },
  filterResumen: { ...initSearchRes },
  status: FETCH_STATUS.IDDLE,
  statusResume: FETCH_STATUS.IDDLE,
  mensaje: '',
  resultados: [],
  resultadosResumen: [],
  seleccionado: null,
  colapsado: true,
  colapsadoResumen: true,
  tabs: ['Cabecera', 'Detalle', 'Consolidado'],
  currentTab: 'Cabecera',
  verRegistro: false
}

export const fetchImports = createAsyncThunk<
  Array<MovimientoImp>,
  any
>('inventarios/movimientos/importaciones/listar', async (filtro) => {
  try {
    const importsList = incomeExpensesServices.getIncomeExpenses(filtro, parseInt(EAplicationsAcatha.Importaciones))
    return importsList
  } catch (error) {
    return Promise.reject(error)
  }
})

export const fetchImportsResume = createAsyncThunk<Array<MovimientoImp>, any>(
  'inventarios/movimientos/importaciones/listarResumen',
  async (filtro) => {
    try {
      const incomeExpensesList =
        incomeExpensesServices.postIncomeExpensesReport(filtro)
      return incomeExpensesList
    } catch (error) {
      return Promise.reject(error)
    }
  },
)

const searchSlice = createSlice({
  name: 'buscarImportaciones',
  initialState: initialState,
  reducers: {
    setCleanResult(state) {
      state.resultados = []
    },
    setVerRegistro(state, action: PayloadAction<boolean>) {
      state.verRegistro = action.payload
    },
    setResultados(state, action: PayloadAction<Array<MovimientoImp>>) {
      state.resultados = action.payload
    },
    setColapsado(state, action: PayloadAction<boolean>) {
      state.colapsado = action.payload
    },
    setColapsadoResumen(state, action: PayloadAction<boolean>) {
      state.colapsadoResumen = action.payload
    },
    changeFilter(state, action: PayloadAction<FiltrosImportacionesState>) {
      state.filter.fechaDesde = action.payload.fechaDesde
      state.filter.fechaHasta = action.payload.fechaHasta
      state.filter.documento = action.payload.documento
      state.filter.codigo = action.payload.codigo
      state.filter.consolidar = action.payload.consolidar
      state.filter.item = action.payload.item
      state.filter.almacen = action.payload.almacen
      state.filter.proveedor = action.payload.proveedor
      state.filter.movimiento = action.payload.movimiento
      state.filter.movimientos = action.payload.movimientos
      state.filter.itemRadioGroup = action.payload.itemRadioGroup
      state.filter.transito = action.payload.transito
      state.filter.observaciones = action.payload.observaciones
      state.filter.esRapida = action.payload.esRapida
      state.filter.initial = action.payload.initial
    },
    changeFilterResumen(state, action: PayloadAction<FiltrosImportacionesState>) {
      state.filterResumen.fechaDesde = action.payload.fechaDesde
      state.filterResumen.fechaHasta = action.payload.fechaHasta
      state.filterResumen.documento = action.payload.documento
      state.filterResumen.codigo = action.payload.codigo
      state.filterResumen.consolidar = action.payload.consolidar
      state.filterResumen.item = action.payload.item
      state.filterResumen.almacen = action.payload.almacen
      state.filterResumen.proveedor = action.payload.proveedor
      state.filterResumen.movimiento = action.payload.movimiento
      state.filterResumen.movimientos = action.payload.movimientos
      state.filterResumen.itemRadioGroup = action.payload.itemRadioGroup
      state.filterResumen.transito = action.payload.transito
      state.filterResumen.observaciones = action.payload.observaciones
      state.filterResumen.esRapida = action.payload.esRapida
      state.filterResumen.initial = action.payload.initial
    },
    changeCurrentTab(state, action: PayloadAction<string>) {
      state.currentTab = action.payload
    },
    setResetSeleccion(state) {
      state.seleccionado = null
    },
    setSeleccionarDato(state, action: PayloadAction<MovimientoImp>) {
      state.seleccionado = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchImports.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchImports.fulfilled, (state, { payload }) => {
      state.resultados = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchImports.rejected, (state, { payload }) => {
      state.resultados = []
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
    builder.addCase(fetchImportsResume.pending, (state) => {
      if (
        state.statusResume === FETCH_STATUS.IDDLE ||
        state.statusResume === FETCH_STATUS.SUCCESS
      ) {
        state.statusResume = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchImportsResume.fulfilled, (state, { payload }) => {
      state.resultadosResumen = payload
      state.statusResume = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchImportsResume.rejected, (state, { payload }) => {
      state.resultadosResumen = []
      state.statusResume = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
  },
})

export const { changeFilter, setCleanResult, changeCurrentTab, setResetSeleccion, setSeleccionarDato, setResultados, changeFilterResumen, setVerRegistro, setColapsado
  , setColapsadoResumen } =
  searchSlice.actions

export const searchReducer = searchSlice.reducer
