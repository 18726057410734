import React, { useState, useEffect, useCallback } from 'react'
import { CCard, CCardBody, CCardHeader, CCol, CRow } from '@coreui/react-pro'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/store'
import { changeLoader } from '../../../../../../store/reducers'
import { ToastTypes } from '../../../../../../store/types'
import Modalform from '../../../../../../views/componentes/modalform'
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput'
import { DataGrid, TextBox } from 'devextreme-react'
import { FilterItemsState, FilterSearchToApi } from '../../types/types'
import { useSetToast } from '../../../../../../hooks/useGlobalHooks'
import { ItemsServices } from '../../services/items.services'
import { Column, Export, Pager, Paging } from 'devextreme-react/data-grid'
import { getAllowedPageSizes } from '../../../../../../helpers/Helper'
import ItemsCountIndicator from '../../../../../componentes/indicadorNroRegistros'

type ModalFullScreenProps = {
  show: boolean
  onClose: () => void
}

const ModalFullScreen = (props: ModalFullScreenProps) => {
  const dispatch = useDispatch()
  const setToastMessage = useSetToast()

  const searchFilter = useSelector((state: RootState) => {
    return state.inventarios.items.search.filter
  })

  const [dataItems, setDataItems] = useState<Array<any>>([])
  const dataGridList = React.useRef<any>()

  const playLoader = React.useCallback(
    (message: string) => {
      dispatch(changeLoader({ show: true, mensaje: message }))
    },
    [dispatch],
  )

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoader({ show: false, mensaje: '' }))
  }, [dispatch])

  const closeModal = React.useCallback(() => {
    props.onClose()
  }, [props])

  const setSearchFilter = React.useCallback(
    (filter: FilterItemsState): FilterSearchToApi => {
      const searchFilterToApi: FilterSearchToApi = {
        itemCodigoUsuario: filter?.codigo ?? '',
        itemBarras: filter?.barras ?? '',
        itemDescripcion: filter?.nombre ?? '',
        itemGenerico: filter?.generico ?? '',
        itemLineaDescripcion: filter?.linea ?? '',
        itemGrupoDescripcion: filter?.grupo ?? '',
        itemMarcaDescripcion: filter?.marca ?? '',
        itemAplicaciones: filter?.aplicacion ?? '',
        itemTipo: filter?.tipoItem.value ?? -1,
        itemImpuesto: filter?.tipoImpuesto.codigo ?? 0,
        itemNota: '',
        itemPeso: '',
        itemTags: '',
        itemEstado: filter?.estadoItem.value ?? 1,
        itemPVDIVA: filter?.precioDesde ?? 0,
        itemPVPIVA: filter?.precioHasta ?? 0,
        itemIncremental: 0,
        itemMobile: filter?.movilweb.value ?? 0,
        impresoraCodigoTecnologia: '',
        itemModeloBom: filter?.modelo ?? '',
        itemBusqueda: filter?.inicioFinOpt.value === 'i' ? 0 : 1,
        itemJerarquia: filter?.agrupador.value ?? -1,
        itemIdwoo: filter?.noSincronizados ? 1 : 0,
        subsidio: '',
        exacta: '',
        local: filter?.local?.codigo ?? -1,
      }
      return searchFilterToApi
    },
    [],
  )

  const filterItems = useCallback(
    (event) => {
      if (
        event.event &&
        event.value &&
        dataItems.length > 0 &&
        event.value !== ''
      ) {
        const filter: string = event.value.toLowerCase() ?? ''
        const filteredItems = dataItems.filter((item) => {
          item.impresoraCodigoTecnologia?.toLowerCase().includes(filter)
        })
        if (filteredItems.length > 0) setDataItems(filteredItems)
      }
    },
    [dataItems],
  )

  const getDataItems = useCallback(async () => {
    try {
      playLoader('Buscando ítems . . .')
      const items = await ItemsServices.getItmesBySupplier(
        setSearchFilter(searchFilter),
      )
      stopLoader()
      if (!items.error && items.auto) setDataItems(items.auto)
      else
        setToastMessage(
          'Buscar Ítems',
          items.message ?? 'Error al buscar ítems',
          ToastTypes.Warning,
        )
    } catch (error) {
      stopLoader()
      setToastMessage(
        'Buscar Ítems',
        JSON.stringify(error) ?? 'Error al buscar ítems',
        ToastTypes.Danger,
      )
    }
  }, [playLoader, searchFilter, setSearchFilter, setToastMessage, stopLoader])

  useEffect(() => {
    getDataItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const bodyModalForm = () => {
    return (
      <>
        <ItemsCountIndicator items={dataItems} />
        <DataGrid
          ref={dataGridList}
          selection={{ mode: 'single' }}
          hoverStateEnabled={true}
          dataSource={dataItems ?? []}
          showBorders={true}
          showRowLines={true}
          allowColumnResizing={true}
          repaintChangesOnly={true}
          remoteOperations={true}
          key="gridListItems"
          loadPanel={{
            enabled: true,
          }}
        >
          <Export enabled={true} allowExportSelectedData={true} />
          <Paging defaultPageSize={20} />
          <Pager
            visible={dataItems.length > 0 ? true : false}
            allowedPageSizes={getAllowedPageSizes(dataItems)}
            displayMode={'full'}
            showPageSizeSelector={true}
            showInfo={true}
            showNavigationButtons={true}
          />
          <Column visible={false} dataField="itemCodigo" width={0} />
          <Column
            dataType="string"
            dataField="itemCodigoUsuario"
            caption="Código"
            width="120px"
          />
          <Column
            dataField="itemDescripcion"
            caption="Descripción"
            width="250px"
          />
          <Column
            dataField="itemLineaDescripcion"
            caption="Categoría"
            width="100px"
          />
          <Column
            dataField="itemGrupoDescripcion"
            caption="Grupo"
            width="100px"
          />
          <Column
            dataField="itemUnidadCompraDescripcion"
            caption="Unidad"
            width="80px"
          />
          <Column dataField="itemModeloBom" caption="Modelo" width="85px" />
          <Column dataField="itemNivelMsl" caption="Nivel MSL" width="80px" />
          <Column
            dataField="itemUltimoProveedor"
            caption="Proveedor Tecnologia"
            width="250px"
          />
          <Column
            dataField="impresoraCodigoTecnologia"
            caption="Cod. Proveedor"
            width="100px"
          />
          <Column
            dataField="proveedorNombreFabricante"
            caption="Fabricante"
            width="150px"
          />
          <Column
            dataField="impresoraCodigoTecnologiaFabricante"
            caption="Cod. Fabricante"
            width="100px"
          />
        </DataGrid>
      </>
    )
  }

  const renderBody = () => {
    return (
      <>
        <CCard key="cardCuentas">
          <CCardHeader>
            <CCol xs="12" md="3">
              <Labeled label="Filtros:">
                <div
                  style={{
                    display: 'flex',
                    gap: '6px',
                    alignItems: 'center',
                  }}
                >
                  {'Cod. Tecnología:'}
                  <TextBox
                    value={''}
                    onValueChanged={(evt) => filterItems(evt)}
                  />
                </div>
              </Labeled>
            </CCol>
          </CCardHeader>
          <CCardBody>{bodyModalForm()}</CCardBody>
        </CCard>
      </>
    )
  }

  return (
    <CRow key="rowMain">
      <CCol key="colMain">
        <Modalform
          name="modalFullSCreen"
          key="modalFullScreen"
          headerTitle={'Códigos'}
          childrenBody={renderBody()}
          childrenFooter={<></>}
          closeOnBackdrop={false}
          show={props.show}
          onClose={closeModal}
          size="xl"
        />
      </CCol>
    </CRow>
  )
}

export default React.memo(ModalFullScreen)
