import React from 'react'
import { KardexList } from '../../types/types'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/store'
import { changeCurrentTab } from '../../store/serachReducer'
import {
  CCard,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
} from '@coreui/react-pro'
import FiltroBusqueda from './filtroBusqueda'
import Transacciones from './transacciones'
import LotesAgregadosItem from './lotesAgregadosItem'
import { TabState } from '../../../../../../store/genericTabTypes'
import BlockUi from '../../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../../views/componentes/loadingindicator/loadingindicator'
import { Draggable, SpeedDialAction } from 'devextreme-react'
import TableLoader from '../../../../../ventas/components/ventas/busquedaVentas/TableLoader'
import { isMobile } from 'react-device-detect'
import { FETCH_STATUS } from '../../../../../../store/types'

interface ISearchProps extends React.PropsWithChildren {
  tab: TabState<KardexList>
  tabId: string
}

enum VistasBusqueda {
  Filtros,
  ResultadosBusqueda,
  Resumen,
  Loading,
  Error
}

const draggingGroupName = 'appointmentsGroup';


const Search: React.FunctionComponent<ISearchProps> = (props) => {
  const { tabId } = props

  const dispatch = useDispatch()
  const [vistaActual, setVistaActual] = React.useState<VistasBusqueda>(VistasBusqueda.Filtros)

  const currentTabState = useSelector((state: RootState) => {
    return state.inventarios.lotes.search
  })
  const currentTab = useSelector((state: RootState) => {
    return state.inventarios.lotes.tabs.current
  })
  const lotesLoader = useSelector((state: RootState) => {
    return state.inventarios.lotes.general
  })

  const estadoBusqueda = useSelector((state: RootState) => {
    return state.inventarios.lotes.search.status
  })
  const estadoBusquedaTran = useSelector((state: RootState) => {
    return state.inventarios.lotes.search.statusTransactions
  })


  React.useEffect(() => {
    switch (estadoBusqueda) {
      case FETCH_STATUS.IDDLE:
        setVistaActual(VistasBusqueda.Filtros);
        break;
      case FETCH_STATUS.LOADING:
        setVistaActual(VistasBusqueda.Loading);
        break;
      case FETCH_STATUS.SUCCESS:
        setVistaActual(VistasBusqueda.ResultadosBusqueda);
        break;
      case FETCH_STATUS.FAILED:
        setVistaActual(VistasBusqueda.Error);
        break;
      default:
        break;
    }
  }, [estadoBusqueda])

  React.useEffect(() => {
    switch (estadoBusquedaTran) {
      case FETCH_STATUS.IDDLE:
        setVistaActual(VistasBusqueda.Filtros);
        break;
      case FETCH_STATUS.LOADING:
        setVistaActual(VistasBusqueda.Loading);
        break;
      case FETCH_STATUS.SUCCESS:
        setVistaActual(VistasBusqueda.ResultadosBusqueda);
        break;
      case FETCH_STATUS.FAILED:
        setVistaActual(VistasBusqueda.Error);
        break;
      default:
        break;
    }
  }, [estadoBusquedaTran])

  if (isMobile) {
    return (
      <>
        <CCard>
          {vistaActual === VistasBusqueda.Filtros &&

            (
              <>
                < FiltroBusqueda key='filtroBusqueda' />
                <CNav variant="tabs" role="tablist" className="mt-4">
                  <CNav variant="tabs">
                    {currentTabState.tabs.map((tab, index) => (
                      <CNavItem key={index}>
                        <CNavLink
                          onClick={() =>
                            dispatch(changeCurrentTab(currentTabState.tabs[index]))
                          }
                          active={currentTabState.currentTab === tab}
                        >
                          {tab}
                        </CNavLink>
                      </CNavItem>
                    ))}
                  </CNav>
                </CNav>
              </>
            )
          }
          {vistaActual === VistasBusqueda.ResultadosBusqueda &&
            (<>
              <CTabContent
                style={{
                  overflowY: 'unset',
                  width: '100%',
                  marginBottom: '10px',
                }}
              >
                <CTabPane
                  role="tabpanel"
                  aria-labelledby="items-tab"
                  visible={currentTabState.currentTab === currentTabState.tabs[0]}
                >
                  <LotesAgregadosItem />
                </CTabPane>
                <CTabPane
                  role="tabpanel"
                  aria-labelledby="todos-tab"
                  visible={currentTabState.currentTab === currentTabState.tabs[1]}
                >
                  <Transacciones />
                </CTabPane>
              </CTabContent>
            </>)
          }
          {vistaActual === VistasBusqueda.Error &&
            <></>
          }
          {vistaActual === VistasBusqueda.Loading &&
            <TableLoader />
          }
          {
            currentTab === tabId && (
              <Draggable
                id="list"
                data="dropArea"
                group={draggingGroupName}
              >
                <SpeedDialAction
                  icon="filter"
                  label='Filtros'
                  visible={true}
                  index={1}
                  onClick={() => {
                    setVistaActual(VistasBusqueda.Filtros);
                  }}
                />
                <SpeedDialAction
                  icon="search"
                  label='Busqueda'
                  visible={true}
                  index={2}
                  onClick={() => {
                    setVistaActual(VistasBusqueda.ResultadosBusqueda);
                  }}
                />
              </Draggable>
            )
          }
        </CCard>
      </>
    )
  }



  return (
    <>
      <BlockUi
        tag="div"
        loader={LoadingIndicator}
        blocking={lotesLoader.loader.show}
        message={lotesLoader.loader.mensaje}
      >
        <CCard>
          <FiltroBusqueda />
          <CNav variant="tabs" role="tablist" className="mt-1">
            <CNav variant="tabs">
              {currentTabState.tabs.map((tab, index) => (
                <CNavItem key={index}>
                  <CNavLink
                    onClick={() =>
                      dispatch(changeCurrentTab(currentTabState.tabs[index]))
                    }
                    active={currentTabState.currentTab === tab}
                  >
                    {tab}
                  </CNavLink>
                </CNavItem>
              ))}
            </CNav>
            <CTabContent
              style={{
                overflowY: 'unset',
                width: '100%',
                marginBottom: '10px',
              }}
            >
              <CTabPane
                role="tabpanel"
                aria-labelledby="items-tab"
                visible={currentTabState.currentTab === currentTabState.tabs[0]}
              >
                <LotesAgregadosItem />
              </CTabPane>
              <CTabPane
                role="tabpanel"
                aria-labelledby="todos-tab"
                visible={currentTabState.currentTab === currentTabState.tabs[1]}
              >
                <Transacciones />
              </CTabPane>
            </CTabContent>
          </CNav>
        </CCard>
      </BlockUi>
    </>
  )
}

export default Search
