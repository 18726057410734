import { RequestHelper } from "../../../../../../../helpers/requestHelper"

export const AbonarXPagar = {
  BillsPayable,
  PurchaseDetail,
  SavePayment,
}

async function BillsPayable(proveedorId: number) {
  const query = {
    codigo: proveedorId,
  }

  try {
    const data = await RequestHelper.getAll<any>(
      'proveedores/documentosPorPagar',
      'invoiceByPay',
      '',
      query,
    )
    return data
  } catch (error) {
    return error
  }
}

async function PurchaseDetail(codigo: number, itemCodigo: string = "") {
  const query = {
    compraCodigo: codigo,
    itemCodigo
  }

  try {
    const data = await RequestHelper.getAll<any>(
      'compras/compras',
      'getDetail',
      '',
      query,
    )
    return data
  } catch (error) {
    return error
  }
}
async function SavePayment(objeto) {


  try {
    const data = await RequestHelper.postAll<any>(
      'proveedores/documentosPorPagar',
      'savePayment',
      objeto,
    )
    return data
  } catch (error) {
    return error
  }
}