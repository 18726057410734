import React from 'react'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import { DataGrid, NumberBox } from 'devextreme-react'
import { Column, ColumnChooser, Export } from 'devextreme-react/data-grid'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import { Workbook } from 'exceljs'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { saveAs } from 'file-saver';
import ItemsCountIndicator from '../../../../../../componentes/indicadorNroRegistros'

const ResultadosBusqueda = () => {
  const searchFilCon = useSelector((state: RootState) => state.inventarios.movimientos.inventarioInicial.search.filterConsolidar)
  const dataItems = useSelector((state: RootState) => state.inventarios.movimientos.inventarioInicial.search.filterConsolidar.dataItems)
  const inventoryDatagrid = React.useRef<any>()
  const empresa = useSelector((state: RootState) => state.global.session.empresa)

  const onExportExcell = React.useCallback((e) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('Main sheet')
    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Inventario_Inicial_Items.xlsx')
      });
    });
  }, [])

  return (
    <>
      <RowContainer>
        <ItemsCountIndicator items={dataItems['auto'] ?? []} />
        <DataGrid
          style={{ marginTop: '10px' }}
          ref={inventoryDatagrid}
          keyExpr="itemCodigo"
          id="tabNewInictialInventory"
          selection={{ mode: 'single' }}
          dataSource={dataItems['auto'] ?? []}
          showColumnLines={true}
          showRowLines={true}
          showBorders={true}
          columnResizingMode="widget"
          onExporting={onExportExcell}
          loadPanel={{
            enabled: true,
            height: 90,
            indicatorSrc: '',
            shading: false,
            shadingColor: '',
            showIndicator: true,
            showPane: true,
            text: 'Cargando...',
            width: 200,
          }}
        >
          <ColumnChooser enabled={true} mode="select" />
          <Export enabled={true} allowExportSelectedData={true} />
          <Column dataField="itemBarras" caption="Codigo" width="100px" />
          <Column dataField="itemDescripcion" caption="Descripción" width="200px" />
          <Column dataField="ordenDetalleCantidad" caption="Cantidad" width="100px" />
          <Column dataField="ordenDetallePrecio" format={{ precision: empresa?.precision ?? 2 }} caption="Precio" width="100px" />
          <Column dataField="ordenDetalleValor" format={{ precision: empresa?.precision ?? 2 }} caption="Valor" width="100px" />
        </DataGrid>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="9" />
        <CustomCol xs="12" md="3">
          <RowContainer className="mt-2">
            <CustomCol md="12" className="d-flex justify-content-end">
              <Labeled label="Subtotal 0:" position="left">
                <NumberBox
                  value={searchFilCon?.subtotal0 ?? null}
                  format={{ precision: empresa?.precision ?? 2 }}
                  showClearButton={true}
                  width="115px"
                  inputAttr={{
                    autocomplete: 'nope',
                    class: 'textInputAlignRight',
                  }}
                  readOnly
                />
              </Labeled>
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol md="12" className="d-flex justify-content-end">
              <Labeled label="Subtotal IVA:" position="left">
                <NumberBox
                  value={searchFilCon?.subtotalIVA ?? null}
                  format={{ precision: empresa?.precision ?? 2 }}
                  showClearButton={true}
                  width="115px"
                  inputAttr={{
                    autocomplete: 'nope',
                    class: 'textInputAlignRight',
                  }}
                  readOnly
                />
              </Labeled>
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol md="12" className="d-flex justify-content-end">
              <Labeled label="Subtotal:" position="left">
                <NumberBox
                  value={searchFilCon?.subtotal ?? null}
                  format={{ precision: empresa?.precision ?? 2 }}
                  showClearButton={true}
                  width="115px"
                  inputAttr={{
                    autocomplete: 'nope',
                    class: 'textInputAlignRight',
                  }}
                  readOnly
                />
              </Labeled>
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol md="12" className="d-flex justify-content-end">
              <Labeled
                label='I.V.A.'
                position="left"
              >
                <NumberBox
                  value={searchFilCon?.iva ?? null}
                  showClearButton={true}
                  format={{ precision: empresa?.precision ?? 2 }}
                  width="115px"
                  inputAttr={{
                    autocomplete: 'nope',
                    class: 'textInputAlignRight',
                  }}
                />
              </Labeled>
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol md="12" className="d-flex justify-content-end">
              <Labeled label="TOTAL:" position="left">
                <NumberBox
                  value={searchFilCon?.total ?? null}
                  format={{ precision: empresa?.precision ?? 2 }}
                  showClearButton={true}
                  width="115px"
                  inputAttr={{
                    autocomplete: 'nope',
                    class: 'textInputAlignRight',
                  }}
                />
              </Labeled>
            </CustomCol>
          </RowContainer>
        </CustomCol>
      </RowContainer>
    </>
  )
}

export default React.memo(ResultadosBusqueda)