import { RequestHelper } from '../../../../../helpers/requestHelper'
import { FilterSeriesState, SaveStore } from '../types/types'

export const SeriesServices = {
  getSeries,
  getSeriesCom,
  saveStore,
  deleteStore,
}

async function getSeries(filtro: FilterSeriesState): Promise<Array<any>> {
  try {
    const data: any = {
      serie: filtro.serie,
      min: filtro.min,
      itemCodigo: filtro.item?.codigo ?? 0,
      localCodigo: filtro.local?.codigo ?? -1,
      tipo: filtro?.tipo.value,
      movimientoCodigo: filtro?.ingresosEgreso,
      compraCodigo: filtro.compraCodigo,
      transferenciaCodigo: filtro.transferencia,
      ventaCodigo: filtro.ventaCodigo,
      stock: filtro.enStock,
    }
    const res = await RequestHelper.getAll<any>('inventario', 'kardex/getAllSeries', '', data)
    return res
  } catch (error) {
    return error
  }
}


async function getSeriesCom(filtro: FilterSeriesState): Promise<Array<any>> {
  try {
    const data: any = {
      serie: filtro.serie,
      min: filtro.min,
      itemCodigo: filtro.item?.codigo ?? 0,
      localCodigo: filtro.local?.codigo ?? -1,
      tipo: filtro?.tipo.value,
      movimientoCodigo: filtro?.ingresosEgreso,
      compraCodigo: filtro.compraCodigo,
      transferenciaCodigo: filtro.transferencia,
      ventaCodigo: filtro.ventaCodigo,
      stock: filtro.enStock,
    }
    const res = await RequestHelper.getAll<any>('inventario', 'series/getItemSeries', '', data)
    return res
  } catch (error) {
    return error
  }
}
async function saveStore(requestBody: SaveStore): Promise<any> {
  try {
    const data = await RequestHelper.postAll<any>(
      'inventario/multialmacen',
      'local/save',
      requestBody,
    )
    return data
  } catch (error) {
    return error
  }
}

async function deleteStore(storeCode: number): Promise<any> {
  try {
    const obj = {
      codigo: storeCode,
    }
    const data = await RequestHelper.deleteRequestAll<any>(
      'inventario/multialmacen',
      'local/delete',
      '',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}
