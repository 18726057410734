import { RequestHelper } from "../../../../helpers/requestHelper";
import { SesionService } from "../../../../services/sesion.service";
import { Cheque } from "../../../bancos/pages/cheques/store/types";

export const ingresarChequesService = {
  CargarCombosDisponibles,
  crearCheque,
  devuelveTotalTran,
  devuelveTotal,
  CargarTodosTran,
  EliminarTran,
  cargarBanco,
  getTotalIncome,
  inactivarTransaccion,
  getTranRolPay,
}

async function EliminarTran(find): Promise<any> {
  const sesion = SesionService.getCurrentSesion()
  try {
    const obj = {
      ruc: sesion.empresa.ruc,
      usuario: sesion.usuario.codigo,
      ...find,
    }
    const data = await RequestHelper.deleteRequestAll<Array<Cheque>>(
      'bancos/cheques',
      'deleteTransaction',
      '',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}

async function CargarTodosTran(find): Promise<any> {
  const sesion = SesionService.getCurrentSesion()
  try {
    const obj = {
      ruc: sesion.empresa.ruc,
      usuario: sesion.usuario.codigo,
      ...find,
    }
    const data = await RequestHelper.getAll<Array<Cheque>>(
      'bancos/cheques',
      'getAllTransaction',
      '',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}

async function CargarCombosDisponibles(find): Promise<any> {
  try {
    const obj = {
      ...find,
    }
    const data = await RequestHelper.getAll<Array<any>>(
      'generales/formasPago',
      'sri/getCboxAvailable',
      '',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}

async function crearCheque(objeto: any): Promise<any> {
  const sesion = SesionService.getCurrentSesion()
  try {
    const obj = {
      infoEmpresa: {
        ruc: sesion.empresa.ruc,
      },
      infoUsuario: {
        usuario: Number(sesion.usuario.codigo),
      },
      infoRegistro: {
        ...objeto,
      },
    }
    const data = await RequestHelper.postAll<any>(
      'bancos/cheques',
      'saveTransaction',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}

async function inactivarTransaccion(objeto: any): Promise<any> {
  try {
    const obj = {
      chequeCodigo: objeto['chequeCodigo'],
      cuentaBancoCodigo: objeto['cuentaBancoCodigo'],
      chequeValor: objeto['chequeValor'],
    }
    const data = await RequestHelper.deleteRequestAll<any>(
      'bancos/cheques',
      'inactiveTransaction',
      '',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}

async function devuelveTotalTran(find): Promise<any> {
  const sesion = SesionService.getCurrentSesion()
  try {
    const obj = {
      usuario: sesion.usuario.codigo,
      ...find,
    }
    const data = await RequestHelper.getAll<Array<any>>(
      'bancos/movimientosBancos/general',
      'egress/getTotalTran',
      '',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}
async function devuelveTotal(find, modulo: string, tab: string = ""): Promise<any> {
  let path = ''
  if (tab === "PROVEEDOR") {
    if (modulo === "ANTICIPOS") {
      path = "anp/getAll"
    } else {
      path = "abc/getAll"
    }
  } else {
    if (modulo === "ANTICIPOS") {
      path = "anl/getTotal"
    } else {
      path = "abv/getTotal"
    }
  }
  try {
    const obj = {
      ...find,
    }
    const data = await RequestHelper.getAll<Array<any>>(
      'generales/formasPago',
      path,
      '',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}

async function cargarBanco(find): Promise<any> {
  const sesion = SesionService.getCurrentSesion()
  try {
    const obj = {
      ruc: sesion.empresa.ruc,
      usuario: sesion.usuario.codigo,
      ...find,
    }
    const data = await RequestHelper.getAll<Array<any>>(
      'bancos/bancos',
      'getAccount',
      '',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}

async function getTotalIncome(incomeCode: number): Promise<Promise<any>> {
  const sesion = SesionService.getCurrentSesion()
  const query = {
    usuario: sesion.usuario.codigo,
    codigo: incomeCode,
  }
  try {
    const dataPendientes = await RequestHelper.getAll<any>(
      'bancos/movimientosBancos',
      'general/income',
      '/getTotal',
      query,
    )
    return dataPendientes
  } catch (error) {
    return error
  }
}


async function getTranRolPay(incomeCode: number): Promise<Promise<any>> {
  const query = {
    rolPago: incomeCode,
    formaPago: 7,
  }
  try {
    const dataApi = await RequestHelper.getAll<any>(
      'generales/formasPago',
      'rol/getTotal',
      '',
      query,
    )
    return dataApi
  } catch (error) {
    return error
  }
}
