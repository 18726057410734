import { combineReducers } from '@reduxjs/toolkit'
import { clientesReducer } from '../pages/clientes/store/clientesReducers'
import { docCuentasXCobrarReducer } from '../pages/docsCtasXCobrar/store/docCuentasXCobrarReducers'
import { ModuloClientesState } from './types'
import { desempenoReducer } from '../pages/desempeno/store/perfomanceReducers'
import { zonasReducer } from '../pages/zonas/store/zonaReducers'
import { tipoClienteReducer } from '../pages/tipoCliente/store/tipoClienteReducers'

export const moduloClientesReducer = combineReducers<ModuloClientesState>({
  clientes: clientesReducer,
  docCuentasXCobrar: docCuentasXCobrarReducer,
  desempeno: desempenoReducer,
  zonas: zonasReducer,
  tipoCliente: tipoClienteReducer,
})
