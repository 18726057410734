import React from 'react'
import { SelectBox } from 'devextreme-react'
import { TOptionGenerico } from '../../clientes/pages/clientes/types/types'

interface PlantillaBarrasLookupProps extends React.PropsWithChildren {
  selected: TOptionGenerico | null
  // eslint-disable-next-line no-unused-vars
  onChanged: (newValue: TOptionGenerico | null) => void
  disabled?: boolean
  provider?: Array<TOptionGenerico> | []
}

const PlantillaBarrasLookup: React.FC<PlantillaBarrasLookupProps> = (props) => {
  const { provider, selected, onChanged, disabled } = props
  const selectRef = React.useRef<any>()
  const [options, setOptions] = React.useState<Array<TOptionGenerico> | []>([])

  const loadDatos = React.useCallback(async () => {
    if (provider !== undefined && provider.length > 0) {
      setOptions(provider)
      const selectedIndex = provider.findIndex((select: TOptionGenerico) => {
        return select.value === selected?.value
      })
      onChanged(provider[selectedIndex] ?? provider[0])
      if (selectedIndex > -1) onChanged(provider[selectedIndex] ?? provider[0])
    } else {
      const opt = PlatillasCodigoBarras
      setOptions(opt)
      const selectedIndex = opt.findIndex((option) => {
        return option.value === selected?.value
      })
      if (selectedIndex > -1) onChanged(opt[selectedIndex] ?? opt[0])
    }
  }, [onChanged, provider, selected])

  const onLookUpValueChanged = React.useCallback(
    (evt: any) => {
      if (evt.event !== undefined) {
        onChanged(evt.value)
      }
    },
    [onChanged],
  )

  const onSelectBoxFocusIn = React.useCallback((e) => {
    e.element.querySelector('input.dx-texteditor-input').select()
  }, [])

  const refreshSelected = React.useCallback(async () => {
    if (options && options.length > 0) {
      const selectedIndex = await options.findIndex(
        (option: TOptionGenerico) => {
          return option.value === selected?.value
        },
      )
      onChanged(options[selectedIndex] ?? options[0])
    }
  }, [onChanged, options, selected])

  React.useEffect(() => {
    loadDatos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (selected) refreshSelected()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  return (
    <SelectBox
      id="codificacionBarCodeSelect"
      ref={selectRef}
      items={PlatillasCodigoBarras ?? []}
      searchEnabled
      displayExpr={'label'}
      searchExpr={['label']}
      searchTimeout={500}
      onEnterKey={() => {}}
      value={selected}
      onValueChanged={onLookUpValueChanged}
      disabled={disabled}
      onFocusIn={onSelectBoxFocusIn}
    >
      {props.children}
    </SelectBox>
  )
}

export default React.memo(PlantillaBarrasLookup)

export const PlatillasCodigoBarras: Array<TOptionGenerico> = [
  { label: 'Seleccione una Plantilla', value: -1 },
  { label: '2" x 2" (x1)', value: 0 },
  { label: '4.0" x 1.5" (x2)', value: 1 },
  { label: '4.0" x 1" (x3)', value: 2 },
  { label: '2.0" x 4.0" (x1)', value: 3 },
  { label: 'A4 4.5" x 5.0" (x1)', value: 4 },
  { label: '4.4" x 3.3" (x1)', value: 5 },
  { label: '5.8cm x 1.2cm (x1)', value: 6 },
  { label: '5.5cm x 2.8cm (x1)', value: 7 },
  { label: '5.8cm x 2.8cm (x1)', value: 8 },
  { label: '4.5cm x 1cm (x1)', value: 9 },
  { label: '5.1cm x 2.5cm (x1)', value: 10 },
  { label: '1.5" x 1.0" (x1)', value: 11 },
  { label: '5.0cm x 2.5cm (x1)', value: 12 },
  { label: '5.0cm x 2.5cm 2 Precios(x1)', value: 13 },
  { label: '5.0cm x 2.5cm (C)', value: 14 },
  { label: '5.0cm x 2.5cm (x1) Promos', value: 15 },
  { label: '7.6cm x 1.5cm Joyas', value: 16 },
]
