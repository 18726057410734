import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoaderInfo } from "../../../../../store/types";
import { CustomDictionary } from "../../../../ventas/types/generics";
import { Cliente, FormaPagoModuloVentas } from "../../../types/types";
import { DetalleVentaRedux } from "../../ventas/types/detalleVenta";
import {
  DocumentoOrigenFacturacion,
  FacturacionEdicionPayload,
  FacturaDatosEdicion,
  TTotalesFactura,
} from "../../ventas/types/types";
import { ventasClearDatosEdicion } from "../../../store/reducers";

const initialState: CustomDictionary<FacturaDatosEdicion> = {};

const datosEdicionVentasSlice = createSlice({
  name: "notasCreditoDatosEdicion",
  initialState: initialState,
  reducers: {
    ventasClearDatosEdicion,
    setDatosEdicion(
      state,
      action: PayloadAction<FacturacionEdicionPayload<FacturaDatosEdicion>>
    ) {
      state[action.payload.key] = action.payload.data;
    },
    updateTotales(
      state,
      action: PayloadAction<{ key: string | number; totales: TTotalesFactura }>
    ) {
      state[action.payload.key].totales = action.payload.totales;
    },
    updateDetalles(
      state,
      action: PayloadAction<{
        key: string | number;
        detalles: Array<DetalleVentaRedux>;
      }>
    ) {
      state[action.payload.key].detalles = action.payload.detalles;
    },
    updateCliente(
      state,
      action: PayloadAction<{ key: string | number; cliente: Cliente | null }>
    ) {
      state[action.payload.key].cliente = action.payload.cliente;
    },
    updateObservaciones(
      state,
      action: PayloadAction<{ key: string | number; observaciones: string }>
    ) {
      state[action.payload.key].observaciones = action.payload.observaciones;
    },
    updateFechaEmision(
      state,
      action: PayloadAction<{ key: string | number; fechaEmision: string }>
    ) {
      state[action.payload.key].fecha = action.payload.fechaEmision;
    },
    updateMotivo(
      state,
      action: PayloadAction<{ key: string | number; motivo: string }>
    ) {
      state[action.payload.key].motivoNotaCredito = action.payload.motivo;
    },
    //Establecer datos edicion para un tab nuevo
    initDatosEdicion(
      state,
      action: PayloadAction<FacturacionEdicionPayload<FacturaDatosEdicion>>
    ) {
      if (!state[action.payload.key]) {
        state[action.payload.key] = action.payload.data;
      }
    },
    setEditLoader(
      state,
      action: PayloadAction<{ key: string | number; info: LoaderInfo }>
    ) {
      state[action.payload.key].loader = action.payload.info;
    },
    updateDocumentoOrigen(
      state,
      action: PayloadAction<{
        key: string | number;
        documento: DocumentoOrigenFacturacion;
        detalles: Array<DetalleVentaRedux>;
        cliente: Cliente;
        formaPago?: FormaPagoModuloVentas;
        formasPago?: Array<FormaPagoModuloVentas>;
      }>
    ) {
      state[action.payload.key].documentoOrigen = action.payload.documento;
      state[action.payload.key].cliente = action.payload.cliente;
      state[action.payload.key].detalles = action.payload.detalles;
      state[action.payload.key].formaPago = action.payload.formaPago ? [action.payload.formaPago] : [];
      state[action.payload.key].formasPago = action.payload.formasPago ? action.payload.formasPago : [];
    },
    setMuestraError(
      state,
      action: PayloadAction<{
        key: string | number;
        tieneError: boolean;
        mensajeError: string;
      }>
    ) {
      state[action.payload.key].tieneError = action.payload.tieneError;
      state[action.payload.key].mensajeError = action.payload.mensajeError;
    },
    setLocalLoader(
      state,
      action: PayloadAction<{ key: string | number; loader: LoaderInfo }>
    ) {
      state[action.payload.key].loader = action.payload.loader;
    },
    setClaveAccesoGuardada(
      state,
      action: PayloadAction<{
        key: string | number;
        claveAcceso: string;
        imprimir: boolean;
        fechaAutorizacion?: string
      }>
    ) {
      state[action.payload.key].claveAcceso = action.payload.claveAcceso;
      state[action.payload.key].autorizacion = action.payload.claveAcceso;
      state[action.payload.key].imprimir = action.payload.imprimir;
      state[action.payload.key].fechaAutorizacion = action.payload.fechaAutorizacion ?? "NO AUTORIZADO";
    },
    setImprimir(state, action: PayloadAction<{ key: number | string, imprimir: boolean }>) {
      state[action.payload.key].imprimir = action.payload.imprimir;
    },
    addDetalle(state, action: PayloadAction<{ key: number | string, detalle: DetalleVentaRedux }>) {
      state[action.payload.key].detalles.push(action.payload.detalle);
    },
    setFormaPago(state, action: PayloadAction<{ key: number | string, formaPago: FormaPagoModuloVentas }>) {
      state[action.payload.key].formaPago = action.payload.formaPago ? [action.payload.formaPago] : []
    },
    setFormasPago(state, action: PayloadAction<{ key: number | string, formasPago: Array<FormaPagoModuloVentas> | [] }>) {
      state[action.payload.key].formasPago = action.payload.formasPago ?? []
    },
    updateFechaNegociacionOrigen(state, action: PayloadAction<{ key: number | string, documentoOrigen: any, fechaNegociacion: string }>) {
      const origen = state[action.payload.key].documentoOrigen;
      if (origen !== undefined) {
        if (origen.fechaNegociacion) {
          state[action.payload.key].documentoOrigen = {
            ...origen,
            fechaNegociacion: action.payload.fechaNegociacion
          };
        }
      }

    },
  },
});

export const {
  updateFechaEmision,
  updateFechaNegociacionOrigen,
  initDatosEdicion,
  setDatosEdicion,
  updateTotales,
  updateDetalles,
  updateCliente,
  updateObservaciones,
  setEditLoader,
  ventasClearDatosEdicion: clearDatosEdicion,
  updateDocumentoOrigen,
  updateMotivo,
  setMuestraError,
  setLocalLoader,
  setClaveAccesoGuardada,
  setImprimir,
  addDetalle,
  setFormaPago,
  setFormasPago
} = datosEdicionVentasSlice.actions;
export const datosEdicionReducer = datosEdicionVentasSlice.reducer;
