import { RequestHelper } from '../../../../../helpers/requestHelper'
import { SaveNotification } from '../types/types'

export const lotsServices = {
  getNotifications,
  saveNotification,
  deleteNotification,
  getTypeNotification,
  getLotesTransacciones
}

async function getNotifications(description: number): Promise<any> {
  try {
    const data: any = {
      estado: description,
    }
    const res = await RequestHelper.get<any>(
      'admin',
      'notificaciones/getAll',
      '',
      data,
    )
    return res
  } catch (error) {
    return error
  }
}
async function saveNotification(requestBody: SaveNotification): Promise<any> {
  try {
    const data = await RequestHelper.postAll<any>(
      'admin',
      'notificaciones/save',
      requestBody,
    )
    return data
  } catch (error) {
    return error
  }
}

async function deleteNotification(notificationCode: number): Promise<any> {
  try {
    const obj = {
      codigo: notificationCode,
    }
    const data = await RequestHelper.deleteRequestAll<any>(
      'admin',
      'notificaciones/delete',
      '',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}

async function getTypeNotification(): Promise<any> {
  try {
    const res = await RequestHelper.getAll<any>(
      'admin',
      'notificaciones/getAllTypesNotifications',
      '',
      {},
    )
    return res
  } catch (error) {
    return error
  }
}

async function getLotesTransacciones(loteCodigo: number): Promise<any> {
  try {
    const res = await RequestHelper.getAll<any>(
      'inventario/kardex',
      'lots/getAllgetAllTransactios',
      '',
      { loteCodigo },
    )
    return res
  } catch (error) {
    return error
  }
}
