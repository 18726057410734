import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AccionMenu } from "../../../../../../store/types";
import { ModalOpen } from "../../../../../contabilidad/general/store/types";
import { ModalAnticiposState, Seleccionado, Registros, OptionGrupos, Anticipo, Asignado, FormularioAnticipos, Alerta } from "./types";

const initialState: ModalAnticiposState = {
  modulo: '',
  currentAction: "Buscar",
  currentFunction: "",
  aplicacion: 'modalAnticipos',
  acciones: [],
  registros: [],
  refrescaTabla: false,
  seleccionado: {
    index: null,
    row: null,
    selectedRow: null,
  },
  modulos: [],
  modal: {
    open: false,
    name: "",
    parametros: [],
    codModulo: 0
  },
  anticipos: [],
  asignados: [],
  anticiposBackup: [],
  asignadosBackup: [],
  rowsSelectedAnticipos: {
    index: null,
    row: null,
    selectedRow: null,
  },
  rowsSelectedAsignados: {
    index: null,
    row: null,
    selectedRow: null,
  },
  formularioAnticipos: {
    TOTAL: 0,
    DESCUADRE: 0,
    CLASE_DESCUADRE: "textSuccess",
    VALOR_SIN_RETENCION: 0,
    FECHA: null,
    ACV_TIPO: "",
    COMPROBANTE: "",
    DOCUMENTO: "",
  },
  motrarAlerta: {
    mostrar: false,
    mensaje: ""
  }
}

const modalAntSlice = createSlice({
  name: "modalAnticipos",
  initialState: initialState,
  reducers: {
    obtenerAcciones(state, action: PayloadAction<Array<AccionMenu>>) {
      state.acciones = action.payload
    },
    setData(state, action: PayloadAction<Array<Registros>>) {
      state.registros = action.payload
    },
    setCurrentAccion(state, action: PayloadAction<string>) {
      state.currentAction = action.payload
    },
    setCurrentFunction(state, action: PayloadAction<string>) {
      state.currentFunction = action.payload
    },
    setSeleccionarDato(state, action: PayloadAction<Seleccionado>) {
      const toAdd = {
        index: action.payload.index,
        row: action.payload.row,
        selectedRow: action.payload.selectedRow,
      }
      state.seleccionado = toAdd
    },
    setModulosOptions(state, action: PayloadAction<Array<OptionGrupos>>) {
      state.modulos = action.payload
    },
    setModalAnticipos(state, action: PayloadAction<ModalOpen>) {
      const toAdd = {
        name: action.payload.name,
        open: action.payload.open,
        codModulo: action.payload.codModulo,
        parametros: action.payload.parametros,
      }
      state.modal = toAdd
    },
    setAnticipos(state, action: PayloadAction<Array<Anticipo>>) {
      state.anticipos = action.payload
    },
    setAsignados(state, action: PayloadAction<Array<Asignado>>) {
      state.asignados = action.payload
    },
    setAnticiposBackup(state, action: PayloadAction<Array<Anticipo>>) {
      state.anticiposBackup = action.payload
    },
    setAsignadosBackup(state, action: PayloadAction<Array<Asignado>>) {
      state.asignadosBackup = action.payload
    },
    setRowSelectedAnticipos(state, action: PayloadAction<Seleccionado>) {
      const toAdd = {
        index: action.payload.index,
        row: action.payload.row,
        selectedRow: action.payload.selectedRow,
      }
      state.rowsSelectedAnticipos = toAdd
    },
    setResetSeleccionAnticipo(state, action: PayloadAction<any>) {
      const toAdd = {
        index: null,
        row: null,
        selectedRow: null,
      }
      state.rowsSelectedAnticipos = toAdd
    },
    setRowSelectedAsignados(state, action: PayloadAction<Seleccionado>) {
      const toAdd = {
        index: action.payload.index,
        row: action.payload.row,
        selectedRow: action.payload.selectedRow,
      }
      state.rowsSelectedAsignados = toAdd
    },
    setResetSeleccionAsignados(state, action: PayloadAction<any>) {
      const toAdd = {
        index: null,
        row: null,
        selectedRow: null,
      }
      state.rowsSelectedAsignados = toAdd
    },
    setFormulario(state, action: PayloadAction<FormularioAnticipos>) {
      state.formularioAnticipos = action.payload
    },
    setMostrarAlerta(state, action: PayloadAction<Alerta>) {
      const toAdd = {
        mostrar: action.payload.mostrar,
        mensaje: action.payload.mensaje,
      }
      state.motrarAlerta = toAdd
    },
  }
})

export const {
  setCurrentFunction, setAsignadosBackup, setAnticiposBackup, setMostrarAlerta, setFormulario, setRowSelectedAnticipos, setRowSelectedAsignados, setAnticipos, setAsignados, setModalAnticipos, obtenerAcciones, setData, setCurrentAccion, setSeleccionarDato,
  setResetSeleccionAnticipo, setResetSeleccionAsignados
} = modalAntSlice.actions;
export const modalAnticiposReducer = modalAntSlice.reducer;