import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EdicionPayload } from '../../../../store/types'
import { TranformacionesDatosEdicion } from '../types/types'
import { CustomDictionary } from '../../../../../../store/types'

const initialState: CustomDictionary<TranformacionesDatosEdicion> = {}

const datosEditSlice = createSlice({
  name: 'transformacionesDatosEdicion',
  initialState: initialState,
  reducers: {
    deleteEditData(state, action: PayloadAction<string>) {
      delete state[action.payload]
    },
    setDatosEdicion(
      state,
      action: PayloadAction<{
        key: string
        transformacion: TranformacionesDatosEdicion
      }>,
    ) {
      const { key, transformacion } = action.payload
      state[key].codigo = transformacion.codigo
      state[key].fecha = transformacion.fecha
      state[key].local = transformacion.local
      state[key].proceso = transformacion.proceso
      state[key].combinacion = transformacion.combinacion
      state[key].resultado = transformacion.resultado
      state[key].stock = transformacion.stock
      state[key].puerzaDe = transformacion.puerzaDe
      state[key].puerzaA = transformacion.puerzaA
      state[key].cantidad = transformacion.cantidad
      state[key].resultadoReal = transformacion.resultadoReal
      state[key].merma = transformacion.merma
      state[key].dataGrid = transformacion.dataGrid
      state[key].observaciones = transformacion.observaciones
      state[key].guardado = transformacion.guardado
    },
    initDatosEdicion(
      state,
      action: PayloadAction<EdicionPayload<TranformacionesDatosEdicion>>,
    ) {
      if (!state[action.payload.key]) {
        state[action.payload.key] = action.payload.data
      }
    },
  },
})

export const { setDatosEdicion, initDatosEdicion, deleteEditData } =
  datosEditSlice.actions
export const dataEditReducer = datosEditSlice.reducer
