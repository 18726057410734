export const messagesSvMiddleware = {
  'Emisor:  el cod actividad debe tener al menos 5 caracteres':
    'Emisor: La actividad seleccionada es incorrecta',
  'Receptor:  el cod actividad debe tener al menos 5 caracteres':
    'Receptor: La actividad seleccionada es incorrecta',
  'La contraseña debe de ser un string':
    'Verifica las credenciales de hacienda',
  'Each value in claves acceso must be a string':
    'Ocurrió un error al enviar la solicitud, por favor intente de nuevo',
  'Documento: tipo documento must be one of the following values:':
    'Documento: Error en el tipo de documento',
  'Documento: el tipo de documento debe de ser un string':
    'Documento: Error en el tipo de documento',
  'Motivo: tip doc solicita must be one of the following values:':
    'Motivo: Error en motivo de solicitud',
  'emisor tipo item expor': 'El tipo de item de exportación no es el correcto.',
  'nit contribuyente no existe':
    'El NIT proporcionado del contribuyente no existe.',
  'recinto fiscal vacio': 'El Recinto fiscal proporcionado no es el correcto.',
  'regimen vacio': 'El Regimen proporcionado no es el correcto.',
  'el receptor no debe ser igual al emisor':
    'Receptor proporcionado no puede ser igual al mismo Emisor.',
  'emisor.codActividad': 'La actividad del contribuyente no corresponde.',
  'Emisor.cod actividad': 'La actividad del contribuyente no corresponde.',
  'Identificacion.numero':
    'Ya existe un registro con ese valor Identificación -> numero',
}
