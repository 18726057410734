import React from 'react'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput'
import TipoEstadoLookUp from '../../../../../../componentes/tipoEstado'
import { DateBox, RadioGroup, TextBox } from 'devextreme-react'
import { MessagesKeys, lh } from '../../../../../../../helpers/localizationHelper'
import { TabState } from '../../../../../../../store/genericTabTypes'
import { EInvIniTipo, FiltrosSearch, InventarioMovDatosEdicion } from '../../types/types'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import BuscarItemPedidoMemoLookUp from '../../../../../../ventas/pages/ordenes/pages/ordenPedido/components/busquedaPedidos/BusquedaItemsSelectBox/BuscarItemPedidoMemoLookUp'
import { DateUtils, formatoFechasApi } from '../../../../../../../helpers/dateUtils'
import { EModulosAcatha } from '../../../../../../../store/enumsAplication'
import { changeFilter, fetchInventorys, setColapsado } from '../../store/serachReducer'
import { consoleService } from '../../../../../../../services/console.service'
import { ToastTypes } from '../../../../../../../store/types'
import { addToast } from '../../../../../../../store/toasterReducer'
import { isMobile } from 'react-device-detect'
import { CCardBody, CCardHeader, CCollapse, CLink } from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilChevronBottom, cilChevronTop } from '@coreui/icons'
import { utilidades } from '../../../../../../../helpers/utilidades'


interface IFiltrosBusquedaProps extends React.PropsWithChildren {
  tab: TabState<InventarioMovDatosEdicion>
  tabId: string
}


export const optionsPrioridad = [
  { value: false, label: 'Descripcion' },
  { value: true, label: 'Barras' },
]


const FiltroBusqueda: React.FunctionComponent<IFiltrosBusquedaProps> = (props) => {
  const dispatch = useDispatch()
  const searchFilter = useSelector((state: RootState) => state.inventarios.movimientos.inventarioInicial.search.filter)
  const collapsed = useSelector((state: RootState) => state.inventarios.movimientos.inventarioInicial.search.colapsado)
  const [prioridadBarras, setPrioridadBarras] = React.useState<{ value: boolean, label: string }>(optionsPrioridad[1])
  const fechaFormato: string = 'dd/MM/yyyy'
  const fechaFormatoInput: string = 'yyyy-MM-dd'
  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(
      addToast({
        title: 'Inventario-InventarioInicial',
        content: mensaje,
        type: tipo,
      }),
    )
  }, [dispatch])

  const handleSearch = React.useCallback(async () => {
    const desde = searchFilter?.fechaDesde ? DateUtils.format(searchFilter?.fechaDesde, fechaFormato, fechaFormatoInput) : DateUtils.getCurrentDateAsString(fechaFormato)
    const hasta = searchFilter?.fechaHasta ? DateUtils.format(searchFilter?.fechaHasta, fechaFormato, fechaFormatoInput) : DateUtils.getCurrentDateAsString(fechaFormato)

    try {
      const data_search: FiltrosSearch = {
        numero: searchFilter?.documento ?? '',
        tipo: EInvIniTipo.tipo,
        cliente: searchFilter.item?.codigoBarras ?? '',
        observaciones: searchFilter?.observaciones ?? '',
        estado: searchFilter.estado?.codigo ?? -1,
        fechaDesde: desde,
        fechaHasta: hasta,
      }
      const toAny: any = fetchInventorys(data_search)
      const res = await dispatch(toAny)
      consoleService.log(res)
      if (res !== null && res !== undefined && res['payload']['error'] === false) {
        if (res['payload']?.auto && res['payload'].auto.length > 5) {
          dispatch(setColapsado(false))
        }
        setToast(res['payload']['message'], ToastTypes.Success)
      } else {
        setToast(res['payload']['message'], ToastTypes.Danger)
      }
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
  }, [dispatch, searchFilter, setToast])

  return (
    <>
      <CCardHeader
        onClick={() => dispatch(setColapsado(!collapsed))}
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <strong> {collapsed ? '' : 'Filtro de Búsqueda'} </strong>
        <CLink className="card-header-action">
          <CIcon icon={collapsed ? cilChevronBottom : cilChevronTop} />
        </CLink>
      </CCardHeader>
      <CCollapse visible={collapsed}>
        <CCardBody>
          <RowContainer>
            <CustomCol xs="12" md="2">
              <Labeled label="Documento:">
                <TextBox
                  value={searchFilter?.documento ?? ''}
                  onValueChanged={(data) => {
                    if (data?.event !== null && data?.event !== undefined) {
                      dispatch(changeFilter({
                        ...searchFilter,
                        documento: data?.value
                      }))
                    }
                  }}
                  onEnterKey={handleSearch}
                  placeholder="0000000"
                  onKeyDown={utilidades.filtraTeclasNoNumericas}
                />
              </Labeled>
            </CustomCol>
            <CustomCol style={{ marginTop: 'auto' }}>
              {lh.getMessage(MessagesKeys.NumberIncludingZeros)}
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol xs="12" md="4">
              <Labeled label="Rango:">

                {isMobile && (
                  <>
                    <div className='m-1'>
                      <DateBox
                        displayFormat="dd/MM/yyyy"
                        value={searchFilter?.fechaDesde ?? null}
                        onValueChanged={(data) => {
                          if (data?.event !== null && data?.event !== undefined) {
                            dispatch(changeFilter({
                              ...searchFilter,
                              fechaDesde: data?.value
                            }))
                          }
                        }}
                        onEnterKey={handleSearch}
                      />
                    </div>
                    <div className='m-1'>
                      <DateBox
                        displayFormat="dd/MM/yyyy"
                        value={searchFilter?.fechaHasta ?? null}
                        onValueChanged={(data) => {
                          if (data?.event !== null && data?.event !== undefined) {
                            dispatch(changeFilter({
                              ...searchFilter,
                              fechaHasta: data?.value
                            }))
                          }
                        }}
                        onEnterKey={handleSearch}
                      />
                    </div>

                  </>
                )}

                {
                  !isMobile && (
                    <div
                      style={{
                        display: 'flex',
                        gap: '2px',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <DateBox
                        displayFormat="dd/MM/yyyy"
                        value={searchFilter?.fechaDesde ?? null}
                        onValueChanged={(data) => {
                          if (data?.event !== null && data?.event !== undefined) {
                            dispatch(changeFilter({
                              ...searchFilter,
                              fechaDesde: data?.value
                            }))
                          }
                        }}
                        onEnterKey={handleSearch}
                      />
                      <DateBox
                        displayFormat="dd/MM/yyyy"
                        value={searchFilter?.fechaHasta ?? null}
                        onValueChanged={(data) => {
                          if (data?.event !== null && data?.event !== undefined) {
                            dispatch(changeFilter({
                              ...searchFilter,
                              fechaHasta: data?.value
                            }))
                          }
                        }}
                        onEnterKey={handleSearch}
                      />
                    </div>
                  )
                }
              </Labeled>
            </CustomCol>
            <CustomCol xs="12" md="2">
              <Labeled label="Estado:">
                <TipoEstadoLookUp
                  onChanged={(data) => {
                    dispatch(changeFilter({
                      ...searchFilter,
                      estado: data
                    }))
                  }}
                  selected={searchFilter?.estado ?? null} />
              </Labeled>
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol xs="12" md='9'>
              <Labeled label="Items:">
                <RowContainer>
                  <CustomCol md={searchFilter.item !== null ? "8" : "6"}>
                    <BuscarItemPedidoMemoLookUp
                      selected={searchFilter.item ?? null}
                      onChanged={(data) => {
                        dispatch(changeFilter({
                          ...searchFilter,
                          item: data
                        }))
                      }}
                      cliente={null}
                      fecha={DateUtils.getCurrentDateAsString(formatoFechasApi)}
                      prioridadBarras={prioridadBarras?.value ?? true}
                      modulo={EModulosAcatha.Kardex}
                      tipoAsociado={null}
                      local={undefined}
                      movimiento={null}
                      allowMoreOption={true}
                      allowClear={true}
                      allowEdit={true}
                      allowAdd={true}
                      codigoPrvAsociado={-1}
                    />
                  </CustomCol>
                  <CustomCol md="4">
                    <RadioGroup
                      name="iOptionsMov"
                      dataSource={optionsPrioridad}
                      value={prioridadBarras}
                      layout="horizontal"
                      displayExpr="label"
                      onValueChange={(data) => {
                        setPrioridadBarras(data)
                      }}
                    />
                  </CustomCol>
                </RowContainer>
              </Labeled>
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol xs="12" md="5">
              <Labeled label="Observaciones:">
                <TextBox
                  value={searchFilter?.observaciones ?? ''}
                  onEnterKey={handleSearch}
                  onValueChanged={(data) => {
                    if (data?.event !== null && data?.event !== undefined) {
                      dispatch(changeFilter({
                        ...searchFilter,
                        observaciones: data?.value
                      }))
                    }
                  }} />
              </Labeled>
            </CustomCol>
          </RowContainer>
        </CCardBody>
      </CCollapse>
    </>
  )
}

export default React.memo(FiltroBusqueda)