import { LoaderInfo, OptionGrupos } from "../../../../../store/types"
import { Seleccionado } from "../../../store/types"

export enum TIPOS_BOTONES {
  BOTON_NUEVO = 'Nuevo',
  BOTON_GUARDAR = 'Guardar',
  BOTON_EDITAR = 'Editar',
  BOTON_ELIMINAR = 'Eliminar',
  BOTON_BUSCAR = 'Buscar',
  BOTON_REFRESCAR = 'Refrescar',
  BOTON_IMPRIMIR = 'Imprimir',
  BOTON_DESHACER = 'Deshacer',
  BOTON_EXPORTAR = 'Exportar',
  BOTON_PRINT_DESIGN = "Imprimir_Diseño",
  BOTON_LIMPIAR = 'Limpiar',
}

export type CCAHORRO = {
  ban_codigo: number | null, //BAN_CODIGO: "3"
  ban_nombre: string,//BAN_NOMBRE: "Banco Pichincha"
  asociado: number | null,//CBA_ASOCIADO: "0"
  asociado_id: string,//CBA_ASOCIADO_ID: null
  asociado_nombre: string,//CBA_ASOCIADO_NOM: null
  cba_codigo: number | null,//CBA_CODIGO: "3"
  cba_contador: number | null,//CBA_CONTADOR: "0"
  cba_estado: number | null,//CBA_ESTADO: "1"
  fecha_corte: string,//CBA_FCORTE: "30/06/2012"
  num_cheque: string,//CBA_NUMCHEQUE: "918"
  cba_numero: string,//CBA_NUMERO: "3405730704"
  cba_oficial: string,//CBA_OFICIAL: "CRISTINA CORDOVA"
  cba_pertenece: number | null,//CBA_PERTENECE: "1"
  cba_pertenece_des: string,//CBA_PERTENECE_DES: "Empresa"
  cba_saldo: number | null,//CBA_SALDO: -1131.95
  cba_tipo: number | null,//CBA_TIPO: "93"
  pla_codigo: number | null,//PLA_CODIGO: "918"
  pla_descripcion: string,//PLA_DESCRIPCION: "BANCO ACATHA"
  pla_numero: string,//PLA_NUMERO: "1.1.1.02.04"
  sub_valor: string,//SUB_VALOR: "CORRIENTE"
}

export type cuentaBanco = {
  nombeBanco: string | null,
  codigoBanco: number
}

export type formularioBusqueda = {
  pertenece: number | null,
  numero: string | null,
  asociado: number | null
}

export type CCAhorrosState = {
  modulo: string,
  currentAction: string,
  currentFunction: string,
  currentExecute: any,
  registros: Array<CCAHORRO>,
  seleccionado: Seleccionado,
  tipos: Array<OptionGrupos>,
  estados: Array<OptionGrupos>,
  loader: LoaderInfo,
  formularioBusqueda: formularioBusqueda
}