import { RequestHelper } from '../../../../../../helpers/requestHelper'
import { GuardarGema } from '../types/types'

export const GemsServices = {
  getGems,
  setGem,
  deleteGem,
}

async function getGems(data: any): Promise<any> {
  const obj = {
    tipo: data['tipo'],
    descripcion: data['descripcion'],
  }

  try {
    const data = await RequestHelper.getAll<any>('inventario/catalogos', 'caracteristicasGemas/getAll', '', obj)
    return data
  } catch (error) {
    return error
  }
  return Promise.resolve()
}
async function setGem(requestBody: GuardarGema): Promise<any> {
  try {
    const obj = {
      ...requestBody,
    }
    const data = await RequestHelper.postAll<any>('inventario/catalogos', 'caracteristicasGemas/save', obj)
    return data
  } catch (error) {
    return error
  }
  return Promise.resolve()
}

async function deleteGem(performanceCode: number): Promise<any> {
  return Promise.resolve()
}
