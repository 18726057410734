import { SesionService } from "../../../../../services/sesion.service";
import { ColInfo, ExportarExcelService as EES, FielTypes } from '../../../../../services/exportarExcel.service'
import { DateUtils, formatoFechasApi } from "../../../../../helpers/dateUtils";
import { consoleService } from "../../../../../services/console.service";
export const ExportarExcelService = {
  exportarArchivo
}

const cols: Array<ColInfo> = [
  {
    "fieldName": "sub_valor",
    "fieldTitle": "TIPO",
    "order": 1,
    "datatype": FielTypes.String
  },
  {
    "fieldName": "cba_numero",
    "fieldTitle": "CUENTA",
    "order": 2,
    "datatype": FielTypes.String
  },
  {
    "fieldName": "ban_nombre",
    "fieldTitle": "BANCO",
    "order": 3,
    "datatype": FielTypes.String
  },
  {
    "fieldName": "cba_oficial",
    "fieldTitle": "OFICIAL",
    "order": 3,
    "datatype": FielTypes.String
  },
];

function exportarArchivo(exportarDatos) {
  const sesion = SesionService.getCurrentSesion();

  let response: any = '';

  try {
    response = EES.exportarExcel({
      cols: cols,
      data: exportarDatos,
      emptyRowsAfterTitles: 0,
      exportedBy: `${sesion.usuario.nombres} ${sesion.usuario.apellidos}`,
      sheetName: 'CCYAhorros',
      titulos: [
        sesion.empresa.comercial,
        `FECHA:${DateUtils.getCurrentDateAsString(formatoFechasApi)}`,
        "EXPRESADO EN USD"
      ]
    })

  } catch (error) {
    consoleService.error(error)
  }
  return response;
}


