import { CButton, CCol, CContainer, CRow, CTooltip } from '@coreui/react-pro';
import React from 'react';
import { NumberBox } from 'devextreme-react/number-box'
import { RootState } from '../../../../../../../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { CuadresService } from '../../../../../services/cuadres.service';
import { DateUtils, formatoFechasApi } from '../../../../../../../../../helpers/dateUtils';
import { setDatosEdicion, updateDenominacionOnly, updateDescuadredes_tiOnly, updateDescuadre_tiOnly, updateDpIngresosOnly, updateGenerarCuadreOnly, updateTotalesaldosi_tiOnly, updateTotalinicialb_tiOnly, updateTotalinicial_tiOnly, updateGenerarCuadresGeneral, updateDpCierre3Only, updateDpCierre7Only, updateDpCierre8Only, updateDpCierre1Only, updateDpCierre2Only, updateDpCierre9Only, updateDpCierre10Only, updateDpCierre11Only, updateDpCierre4Only, updateDpCierre5Only, updateLoadDpCierre1Only, updateDpCierre1Load, updateDpCierre7Load, updateDpCierre8Load, updateDpCierre3Load, updateDpCierre2Load, updateDpCierre9Load, updateDpCierre10Load, updateDpCierre11Load, updateDpCierre4Load, updateDpCierre5Load } from '../../../store/editDataReducer';
import { AbonoComprasCuadresCajaListado, AbonosCuadresCajaListado, AnticiposCuadresCajaListado, ChequesCuadresCajaListado, ComprasCuadresCajaListado, ComprobantesCuadresCajaListado, CuadresCajaListado, DenominacionListado, LetrasCuadresCajaListado, TabStateCuadre, formasPagoCuadre } from '../../../../../types/types';
import { StatesEdition } from '../../../../../../../types/enums';
import Dialog from '../../../../../../../../../views/componentes/librerias/bootstrap-dialog';
import { GlobalLabel } from '../../../../../../../../../views/componentes/globalLabel/globalLabel';
import { getAbonosComprasCHelper, getAbonosCuadres, getChequesCuadresHelper, getCierreCajaNCHelper, getCierreCajaRet, getCierreCuadreActicipos, getCierreCuadreActiciposProveedoresHelper, getDatosFacturas, getLetrasCuadresHelper, getPagosCuadresHelper } from '../../../../../services/helper.cuadreCaja';
interface IDenominacionProps extends React.PropsWithChildren {
  // currentTab: string,
  tab: TabStateCuadre<CuadresCajaListado>,
  setToast: (texto, type) => void,
  playLoader: () => void,
  stopLoader: () => void,
  onErrorConfirm: (sms, title) => void,
  onConfirmarRegistro: (sms, method) => void
  tabId: string,
  statusLoaded: (load) => void
}

const objetoBilletes = [
  { descripcion: "CIEN", billeteValor: 100, cantidad: 0, total: 0.00, grupo: 1 },
  { descripcion: "CINCUENTA", billeteValor: 50, cantidad: 0, total: 0.00, grupo: 1 },
  { descripcion: "VEINTE", billeteValor: 20, cantidad: 0, total: 0.00, grupo: 1 },
  { descripcion: "DIEZ", billeteValor: 10, cantidad: 0, total: 0.00, grupo: 1 },
  { descripcion: "CINCO", billeteValor: 5, cantidad: 0, total: 0.00, grupo: 1 },
  { descripcion: "UNO", billeteValor: 1, cantidad: 0, total: 0.00, grupo: 1 },
];

const objetoMonedas = [
  { descripcion: "UND", monedaValor: 1, cantidad: 0, total: 0.00, grupo: 2 },
  { descripcion: "CINCUENTA", monedaValor: 0.50, cantidad: 0, total: 0.00, grupo: 2 },
  { descripcion: "VEINTECINCO", monedaValor: 0.25, cantidad: 0, total: 0.00, grupo: 2 },
  { descripcion: "DIEZ", monedaValor: 0.10, cantidad: 0, total: 0.00, grupo: 2 },
  { descripcion: "CINCO", monedaValor: 0.05, cantidad: 0, total: 0.00, grupo: 2 },
  { descripcion: "UNC", monedaValor: 0.01, cantidad: 0, total: 0.00, grupo: 2 },
]



const Denominacion: React.FunctionComponent<IDenominacionProps> = (props) => {
  const {
    tabId, setToast, playLoader, stopLoader,
    onErrorConfirm, onConfirmarRegistro, tab,
    statusLoaded
  } = props;
  const dialogRefPrint = React.useRef<any>(null);

  const dispatch = useDispatch();
  const datosEdicion = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId]
  })
  const local = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[tabId].local });
  const puntoVenta = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[tabId].puntoVenta });
  const horaCierre = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].horaCierre });
  const minutoCierre = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].minutoCierre });
  const fechaCierre = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].fechaCierre });
  const formEdition = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId] });
  const totalegreso_ti = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].totalegreso_ti });
  const descuadre_ti = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].descuadre_ti });
  const descuadredes_ti = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].descuadredes_ti });
  const dptotales = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dptotales });
  const venInicial1 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venInicial1 });
  const venFinal1 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venFinal1 });

  const venInicial7 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venInicial7 });
  const venFinal7 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venFinal7 });

  const venInicial8 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venInicial8 });
  const venFinal8 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venFinal8 });

  const venInicial3 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venInicial3 });
  const venFinal3 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venFinal3 });

  const venInicial2 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venInicial2 });
  const venFinal2 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venFinal2 });

  const venInicial9 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venInicial9 });
  const venFinal9 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venFinal9 });

  const venInicial10 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venInicial10 });
  const venFinal10 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venFinal10 });

  const venInicial11 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venInicial11 });
  const venFinal11 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venFinal11 });

  const venInicial4 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venInicial4 });
  const venFinal4 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venFinal4 });


  const venInicial5 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venInicial5 });
  const venFinal5 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venFinal5 });
  const dptotalresumen = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dptotalresumen });


  const generarCuadreState = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].generarCuadre });
  const ccaCod1State = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].ccaCod1 });
  const ccaCod2State = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].ccaCod2 });
  const ccaCod3State = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].ccaCod3 });
  const ccaCod4State = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].ccaCod4 });
  const ccaCod5State = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].ccaCod5 });
  const ccaCod6State = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].ccaCod6 });
  const ccaCod7State = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].ccaCod7 });
  const ccaCod8State = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].ccaCod8 });
  const ccaCod9State = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].ccaCod9 });
  const ccaCod10State = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].ccaCod10 });
  const ccaCod11State = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].ccaCod11 });

  const [objBilletes, setObjBilletes] = React.useState(objetoBilletes);
  const [objMonedas, setObjMonedas] = React.useState(objetoMonedas);
  const [denominacion, setDenominacion] = React.useState<Array<DenominacionListado> | []>([]);
  const [editableInput, setEditableInput] = React.useState<boolean>(false);

  const updateDenominacion = React.useCallback(() => {
    const denominacionVar: Array<DenominacionListado> = [];
    objBilletes.forEach(function (item: any) {
      denominacionVar.push({ descripcion: item.descripcion, valor: item.total, grupo: 1 })
    })
    objMonedas.forEach(function (item: any) {
      denominacionVar.push({ descripcion: item.descripcion, valor: item.total, grupo: 2 })
    })
    setDenominacion(denominacionVar);

    dispatch(updateDenominacionOnly({
      denominacion: denominacionVar,
      key: tabId
    }));

  }, [objBilletes, objMonedas, dispatch, tabId]);

  const aplicaNuevoValorBilletes = React.useCallback((data, i) => {
    const rowsDataUpdate = objBilletes.slice();
    rowsDataUpdate[i]['cantidad'] = data.value;
    rowsDataUpdate[i]['total'] = (Number(rowsDataUpdate[i]['cantidad']) * Number(rowsDataUpdate[i]['billeteValor']));
    setObjBilletes(rowsDataUpdate);
    updateDenominacion();
  }, [
    objBilletes, updateDenominacion
  ]);

  const aplicaNuevoValorMonedas = React.useCallback((data, i) => {
    const rowsDataUpdate = objMonedas.slice();
    rowsDataUpdate[i]['cantidad'] = data.value;
    rowsDataUpdate[i]['total'] = (Number(rowsDataUpdate[i]['cantidad']) * Number(rowsDataUpdate[i]['monedaValor']));
    setObjMonedas(rowsDataUpdate);
    updateDenominacion();
  }, [objMonedas, updateDenominacion]);

  const getValueEfectivo = React.useCallback(() => {
    let valorBilletes: number = 0;
    let valorMonedas: number = 0;
    let total: number = 0;
    objBilletes.forEach(function (item: any) {
      valorBilletes = valorBilletes + item.total;
    })
    objMonedas.forEach(function (item: any) {
      valorMonedas = valorMonedas + item.total;
    })
    total = Number(valorBilletes) + Number(valorMonedas);
    return total;
  }, [objMonedas, objBilletes]);

  const validarFormulario = React.useCallback(async () => {
    const errors: any = []
    if ((puntoVenta && puntoVenta.codigoPuntoVenta < 0) || (local && local.codigo < 0)) {
      errors.push('Debe elegir un local o punto de venta.')
    }
    if (denominacion.length === 0) {
      errors.push('No existe registro de efectivo.')
    }
    if (minutoCierre === null || horaCierre === null) {
      errors.push('Ingrese la hora y minuto(s) del cuadre.')
    }

    if (fechaCierre === null || fechaCierre === "") {
      errors.push('Ingrese la fecha del cierre.')
    }

    if (errors.length > 0) {
      return {
        errors: true,
        messages: errors
      }
    } else {
      return {
        errors: false,
        messages: errors
      }
    }
  }, [puntoVenta, local, denominacion, minutoCierre, horaCierre, fechaCierre]);

  // request cierreCaja2
  const getDatosFacturasCierre = React.useCallback(async () => {
    const resDpfacturasCuadre: any = await getDatosFacturas(fechaCierre, local, puntoVenta, venInicial1, venFinal1);
    return resDpfacturasCuadre;
  }, [fechaCierre, local, puntoVenta, venFinal1, venInicial1]);

  // request CuadreCaja
  const getDatosActicipos = React.useCallback(async () => {
    const resDpCierreRet: any = await getCierreCuadreActicipos(fechaCierre, local, puntoVenta, venInicial7, venFinal7);
    return resDpCierreRet;
  }, [fechaCierre, local, puntoVenta, venFinal7, venInicial7]);

  // request VerAbonosCuadres
  const getCuadresAbonos = React.useCallback(async () => {
    const resDpCierreRet: any = await getAbonosCuadres(fechaCierre, local, puntoVenta, venInicial8, venFinal8);
    return resDpCierreRet

  }, [fechaCierre, local, puntoVenta, venFinal8, venInicial8]);

  // request cierreCaja
  const getCajaRetCierre = React.useCallback(async () => {
    const resDpCierreRet: any = await getCierreCajaRet(fechaCierre, local, puntoVenta, venInicial3, venFinal3);
    return resDpCierreRet
  }, [fechaCierre, local, puntoVenta, venFinal3, venInicial3]);

  const getCierreCajaNC = React.useCallback(async () => {
    const resDpCierre: any = await getCierreCajaNCHelper(fechaCierre, local, puntoVenta, venInicial2, venFinal2);
    return resDpCierre
  }, [fechaCierre, local, puntoVenta, venInicial2, venFinal2]);

  const getCuadresCompras = React.useCallback(async () => {
    const resDpCierreRet: any = await getAbonosComprasCHelper(fechaCierre, local, puntoVenta, venInicial9, venFinal9);
    return resDpCierreRet
  }, [fechaCierre, local, puntoVenta, venInicial9, venFinal9]);

  const getPagosCuadres = React.useCallback(async () => {
    const resDpCierreRet: any = await getPagosCuadresHelper(fechaCierre, local, puntoVenta, venInicial10, venFinal10);
    return resDpCierreRet
  }, [
    fechaCierre, local, puntoVenta, venInicial10, venFinal10
  ]);

  const getCuadreActiciposProv = React.useCallback(async () => {
    const resDpCierreRet: any = await getCierreCuadreActiciposProveedoresHelper(fechaCierre, local, puntoVenta, venInicial11, venFinal11);
    return resDpCierreRet
  }, [
    fechaCierre, local, puntoVenta, venInicial11, venFinal11
  ]);

  const getChequesCuadres = React.useCallback(async () => {
    const resDpCierreRet: any = await getChequesCuadresHelper(fechaCierre, local, puntoVenta, venInicial4, venFinal4);
    return resDpCierreRet;
  }, [
    fechaCierre, local, puntoVenta, venInicial4, venFinal4
  ]);

  const getLetrasCuadres = React.useCallback(async () => {
    const resDpCierreRet: any = await getLetrasCuadresHelper(fechaCierre, local, puntoVenta, venInicial5, venFinal5);
    return resDpCierreRet
  }, [
    fechaCierre, local, puntoVenta, venInicial5, venFinal5
  ]);

  const generarCuadre = React.useCallback(async () => {
    let dpfacturasCuadre = []
    let dpAnticiposClientes = []
    let dpCobrosCartera = []
    let dpRetenciones = []
    let dpNotasCredito = []
    let dpCompras = []
    let dpPagos = []
    let dpAnticiposProveedores = []
    let dpCheques = []
    let dpLetras = []

    dpfacturasCuadre = await getDatosFacturasCierre();
    console.log("dpfacturasCuadre : ", dpfacturasCuadre)
    if (dpfacturasCuadre.length > 0) {
      dispatch(updateDpCierre1Load(
        {
          data: {
            loaddpcierre1: true,
            dpcierre1: dpfacturasCuadre,
          },
          key: tabId
        }
      ))
    }
    dpAnticiposClientes = await getDatosActicipos();
    console.log("dpAnticiposClientes : ", dpAnticiposClientes)
    if (dpAnticiposClientes.length > 0) {
      dispatch(updateDpCierre7Load(
        {
          data: {
            loaddpcierre7: true,
            dpcierre7: dpAnticiposClientes,
          },
          key: tabId
        }
      ))
    }
    dpCobrosCartera = await getCuadresAbonos();
    console.log("dpCobrosCartera : ", dpCobrosCartera)
    if (dpCobrosCartera.length > 0) {
      dispatch(updateDpCierre8Load(
        {
          data: {
            loaddpcierre8: true,
            dpcierre8: dpCobrosCartera,
          },
          key: tabId
        }
      ))
    }
    dpRetenciones = await getCajaRetCierre();
    console.log("dpRetenciones : ", dpRetenciones)
    if (dpRetenciones.length > 0) {
      dispatch(updateDpCierre3Load(
        {
          data: {
            loaddpcierre3: true,
            dpcierre3: dpRetenciones,
          },
          key: tabId
        }
      ))
    }
    dpNotasCredito = await getCierreCajaNC();
    console.log("dpNotasCredito : ", dpNotasCredito)
    if (dpNotasCredito.length > 0) {
      dispatch(updateDpCierre2Load(
        {
          data: {
            loaddpcierre2: true,
            dpcierre2: dpNotasCredito,
          },
          key: tabId
        }
      ))
    }
    dpCompras = await getCuadresCompras();
    console.log("dpCompras : ", dpCompras)
    if (dpCompras.length > 0) {
      dispatch(updateDpCierre9Load(
        {
          data: {
            loaddpcierre9: true,
            dpcierre9: dpCompras,
          },
          key: tabId
        }
      ))
    }
    dpPagos = await getPagosCuadres();
    console.log("dpPagos : ", dpPagos)
    if (dpPagos.length > 0) {
      dispatch(updateDpCierre10Load(
        {
          data: {
            loaddpcierre10: true,
            dpcierre10: dpPagos,
          },
          key: tabId
        }
      ))
    }
    dpAnticiposProveedores = await getCuadreActiciposProv();
    console.log("dpAnticiposProveedores : ", dpAnticiposProveedores)
    if (dpAnticiposProveedores.length > 0) {
      dispatch(updateDpCierre11Load(
        {
          data: {
            loaddpcierre11: true,
            dpcierre11: dpAnticiposProveedores,
          },
          key: tabId
        }
      ))
    }
    dpCheques = await getChequesCuadres();
    console.log("dpCheques : ", dpCheques)
    if (dpCheques.length > 0) {
      dispatch(updateDpCierre4Load(
        {
          data: {
            loaddpcierre4: true,
            dpcierre4: dpCheques,
          },
          key: tabId
        }
      ))
    }
    dpLetras = await getLetrasCuadres();
    console.log("dpLetras : ", dpLetras)
    if (dpLetras.length > 0) {
      dispatch(updateDpCierre5Load(
        {
          data: {
            loaddpcierre5: true,
            dpcierre5: dpLetras,
          },
          key: tabId
        }
      ))
    }

    // dispatch(updateGenerarCuadresGeneral({
    //   cuadre: {
    //     facturas: dpfacturasCuadre,
    //     anticiposClientes: dpAnticiposClientes,
    //     cobrosCartera: dpCobrosCartera,
    //     retenciones: dpRetenciones,
    //     notasCredito: dpNotasCredito,
    //     compras: dpCompras,
    //     pagos: dpPagos,
    //     anticiposProveedores: dpAnticiposProveedores,
    //     cheques: dpCheques,
    //     letras: dpLetras
    //   },
    //   key: tabId
    // }))

    dispatch(updateGenerarCuadreOnly({
      generarCuadre: true,
      key: tabId
    }));

  }, [
    dispatch, tabId, getDatosFacturasCierre,
    getDatosActicipos, getCajaRetCierre,
    getCuadresAbonos, getCierreCajaNC,
    getCuadresCompras, getPagosCuadres,
    getCuadreActiciposProv, getChequesCuadres,
    getLetrasCuadres
  ]);

  // const ingresosBoveda = React.useCallback(async () => {
  //   // geIngresosBoveda
  //   playLoader();
  //   let fechaFilter = DateUtils.format(fechaCierre, formatoFechasApi);
  //   let dpingresosboveda = await CuadresService.geIngresosBoveda(puntoVenta?.codigoPuntoVenta ?? -1, fechaFilter);
  //   stopLoader();
  // }, [fechaCierre, tabId, dispatch, playLoader, stopLoader]);

  const resultadoEspecial = React.useCallback(async () => {

    dispatch(
      setDatosEdicion({
        data: {
          ...datosEdicion,
          loader: {
            show: true,
            mensaje: 'Por favor espere...',
          },
        },
        key: tabId,
      }),
    )

    setEditableInput(true)
    const fechaFilter = DateUtils.format(fechaCierre, formatoFechasApi);
    const dpingresos = await CuadresService.getEspecial(String(fechaFilter), 98);

    let saldoinicial: number = 0;
    let saldoboveda: number = 0;

    if (dpingresos.length > 0) {
      for (const cuadreItem of dpingresos) {
        if (cuadreItem.puntoVentaEspecial === 'BOVEDA') {
          saldoboveda = saldoboveda + cuadreItem.ventaTotal;
        }
        else {
          saldoinicial = saldoinicial + cuadreItem.ventaTotal;
        }
      }

      dispatch(updateTotalinicial_tiOnly({
        totalinicial_ti: saldoinicial,
        key: tabId
      }));

      dispatch(updateTotalinicialb_tiOnly({
        totalinicialb_ti: saldoboveda,
        key: tabId
      }));

      let totalesaldosi_ti: number = 0;
      if (saldoboveda > 0) {
        totalesaldosi_ti = (Number(saldoboveda) - Number(saldoinicial));
      } else {
        totalesaldosi_ti = Number(saldoinicial);
      }

      dispatch(updateTotalesaldosi_tiOnly({
        totalesaldosi_ti: totalesaldosi_ti,
        key: tabId
      }));

      // await ingresosBoveda();

    }

    dispatch(updateDpIngresosOnly({
      dpingresos: dpingresos,
      key: tabId
    }));

    await generarCuadre();
    statusLoaded(true)

  }, [
    datosEdicion, fechaCierre, tabId,
    dispatch, generarCuadre, statusLoaded
  ]);

  const onLoadDetalleCuadre = React.useCallback(async () => {
    dispatch(
      setDatosEdicion({
        data: {
          ...datosEdicion,
          loader: {
            show: true,
            mensaje: 'Por favor espere...',
          },
        },
        key: tabId,
      }),
    )
    generarCuadre();
  }, [generarCuadre, dispatch, datosEdicion, tabId]);

  const handleClickConfirmar = React.useCallback(async () => {
    const res = await validarFormulario();
    if (res.errors === true) {
      onErrorConfirm(res.messages, "Antes de continuar revise lo siguiente:")
    } else {
      const sms: string = "Esta a punto de registrar el cuadre, confirma la fecha " + fechaCierre + " y hora " + horaCierre.toString() + ":" + minutoCierre.toString();
      onConfirmarRegistro(sms, resultadoEspecial);
    }
  }, [
    validarFormulario, onErrorConfirm, onConfirmarRegistro, resultadoEspecial,
    horaCierre, minutoCierre, fechaCierre
  ]);

  const sortJSON = (data, key, orden) => {
    return data.sort(function (a: number, b: number) {
      const x = a[key],
        y = b[key];

      if (orden === "asc") {
        return x < y ? -1 : x > y ? 1 : 0;
      }

      if (orden === "desc") {
        return x > y ? -1 : x < y ? 1 : 0;
      }
    });
  };

  const verificarDescuadre = React.useCallback((denominacion, totalresumen) => {

    let descuadre: number = 0;
    let totalDenominacion: number = 0;
    let textoDescuadre: string = "Sobrante";
    denominacion.forEach(function (det) {
      totalDenominacion = Number(totalDenominacion) + Number(det.valor);
    });
    let ccdValor = 0;
    if (totalresumen && totalresumen.length > 0 && totalresumen?.[0]?.total) {
      ccdValor = Number(totalresumen?.[0]?.total ?? 0)
    }
    descuadre = Number(totalDenominacion - Number(ccdValor));
    descuadre = Number(descuadre.toFixed(2));

    if (descuadre < 0) {
      textoDescuadre = "Faltante";
    } else {
      textoDescuadre = "Sobrante";
    }
    dispatch(updateDescuadre_tiOnly({
      descuadre_ti: descuadre,
      key: tabId
    }));
    dispatch(updateDescuadredes_tiOnly({
      descuadredes_ti: textoDescuadre,
      key: tabId
    }));
  }, [dispatch, tabId]);


  const initForm = React.useCallback((denominacion, edicion: boolean) => {
    let rowsObjetoBilletes: Array<any> = [];
    let rowsObjetoMonedas: Array<any> = [];

    if (edicion === false) {
      setEditableInput(false)

      rowsObjetoBilletes = [
        { descripcion: "CIEN", billeteValor: 100, cantidad: 0, total: 0.00 },
        { descripcion: "CINCUENTA", billeteValor: 50, cantidad: 0, total: 0.00 },
        { descripcion: "VEINTE", billeteValor: 20, cantidad: 0, total: 0.00 },
        { descripcion: "DIEZ", billeteValor: 10, cantidad: 0, total: 0.00 },
        { descripcion: "CINCO", billeteValor: 5, cantidad: 0, total: 0.00 },
        { descripcion: "UNO", billeteValor: 1, cantidad: 0, total: 0.00 },
      ];

      rowsObjetoMonedas = [
        { descripcion: "UND", monedaValor: 1, cantidad: 0, total: 0.00 },
        { descripcion: "CINCUENTA", monedaValor: 0.50, cantidad: 0, total: 0.00 },
        { descripcion: "VEINTECINCO", monedaValor: 0.25, cantidad: 0, total: 0.00 },
        { descripcion: "DIEZ", monedaValor: 0.10, cantidad: 0, total: 0.00 },
        { descripcion: "CINCO", monedaValor: 0.05, cantidad: 0, total: 0.00 },
        { descripcion: "UNC", monedaValor: 0.01, cantidad: 0, total: 0.00 },
      ]
    } else {
      setEditableInput(true)

      denominacion.forEach(function (det) {
        if (det.grupo === 0) {
          if (det.descripcion && String(det.descripcion).toUpperCase() === 'CIEN') {
            rowsObjetoBilletes.push({ ...det, descripcion: det.descripcion, total: Number(det.total), billeteValor: 100, cantidad: Number(det.cantidad), tipo: 'billete' })
          }
          if (det.descripcion && String(det.descripcion).toUpperCase() === 'CINCUENTA') {
            rowsObjetoBilletes.push({ ...det, descripcion: det.descripcion, total: Number(det.total), billeteValor: 50, cantidad: Number(det.cantidad), tipo: 'billete' })
          }
          if (det.descripcion && String(det.descripcion).toUpperCase() === 'VEINTE') {
            rowsObjetoBilletes.push({ ...det, descripcion: det.descripcion, total: Number(det.total), billeteValor: 20, cantidad: Number(det.cantidad), tipo: 'billete' })
          }
          if (det.descripcion && String(det.descripcion).toUpperCase() === 'DIEZ') {
            rowsObjetoBilletes.push({ ...det, descripcion: det.descripcion, total: Number(det.total), billeteValor: 10, cantidad: Number(det.cantidad), tipo: 'billete' })
          }
          if (det.descripcion && String(det.descripcion).toUpperCase() === 'CINCO') {
            rowsObjetoBilletes.push({ ...det, descripcion: det.descripcion, total: Number(det.total), billeteValor: 5, cantidad: Number(det.cantidad), tipo: 'billete' })
          }
          if (det.descripcion && String(det.descripcion).toUpperCase() === 'UNO') {
            rowsObjetoBilletes.push({ ...det, descripcion: det.descripcion, total: Number(det.total), billeteValor: 1, cantidad: Number(det.cantidad), tipo: 'billete' })
          }
        }
      })
      rowsObjetoBilletes = sortJSON(rowsObjetoBilletes, "billeteValor", 'desc')

      denominacion.forEach(function (det) {
        if (det.grupo === 1) {
          if (det.descripcion && String(det.descripcion).toUpperCase() === 'UND') {
            rowsObjetoMonedas.push({ ...det, descripcion: det.descripcion, total: Number(det.total), monedaValor: 1, cantidad: Number(det.cantidad), tipo: 'moneda' })
          }
          if (det.descripcion && String(det.descripcion).toUpperCase() === 'CINCUENTA') {
            rowsObjetoMonedas.push({ ...det, descripcion: det.descripcion, total: Number(det.total), monedaValor: 0.50, cantidad: Number(det.cantidad), tipo: 'moneda' })
          }
          if (det.descripcion && String(det.descripcion).toUpperCase() === 'VEINTECINCO') {
            rowsObjetoMonedas.push({ ...det, descripcion: det.descripcion, total: Number(det.total), monedaValor: 0.25, cantidad: Number(det.cantidad), tipo: 'moneda' })
          }
          if (det.descripcion && String(det.descripcion).toUpperCase() === 'DIEZ') {
            rowsObjetoMonedas.push({ ...det, descripcion: det.descripcion, total: Number(det.total), monedaValor: 0.10, cantidad: Number(det.cantidad), tipo: 'moneda' })
          }
          if (det.descripcion && String(det.descripcion).toUpperCase() === 'CINCO') {
            rowsObjetoMonedas.push({ ...det, descripcion: det.descripcion, total: Number(det.total), monedaValor: 0.05, cantidad: Number(det.cantidad), tipo: 'moneda' })
          }
          if (det.descripcion && String(det.descripcion).toUpperCase() === 'UNC') {
            rowsObjetoMonedas.push({ ...det, descripcion: det.descripcion, total: Number(det.total), monedaValor: 0.01, cantidad: Number(det.cantidad), tipo: 'moneda' })
          }
        }
      })
      rowsObjetoMonedas = sortJSON(rowsObjetoMonedas, "monedaValor", 'desc')

    }

    setObjBilletes(rowsObjetoBilletes);
    setObjMonedas(rowsObjetoMonedas);
  }, []);


  React.useEffect(() => {
    initForm([], false)
  }, [puntoVenta, local, initForm]);

  React.useEffect(() => {
    if (tab.editStatus === StatesEdition.save && formEdition.denominacion) {
      initForm(formEdition.denominacion, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formEdition.denominacion]);

  React.useEffect(() => {
    verificarDescuadre(formEdition.denominacion, dptotalresumen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formEdition.denominacion, dptotalresumen]);

  // React.useEffect(() => {
  //   if (
  //     ccaCod1State > 0 &&
  //     ccaCod2State > 0 &&
  //     ccaCod3State > 0 &&
  //     ccaCod4State > 0 &&
  //     ccaCod5State > 0 &&
  //     ccaCod6State > 0 &&
  //     ccaCod7State > 0 &&
  //     ccaCod8State > 0 &&
  //     ccaCod9State > 0 &&
  //     ccaCod10State > 0
  //   ) {
  //     onLoadDetalleCuadre()
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [
  //   ccaCod1State,
  //   ccaCod2State,
  //   ccaCod3State,
  //   ccaCod4State,
  //   ccaCod5State,
  //   ccaCod6State,
  //   ccaCod7State,
  //   ccaCod8State,
  //   ccaCod9State,
  //   ccaCod10State,
  //   ccaCod11State]);

  return (
    <>
      <Dialog ref={dialogRefPrint} />

      <CRow className={'m-2'}>
        <CCol lg="6" xl="6" md="6" sm="12">

          <CRow className={'mt-2 mp-2 d-flex justify-content-start'}>
            <CCol lg="12" xl="12" sm="12">
              <strong>{"BILLETES"}</strong>
            </CCol>
          </CRow>

          {objBilletes.map((billete, i) => {
            return (
              <CRow key={i} className={'m-2 d-flex justify-content-center align-items-center'}>
                <CCol xl="2" md="1" sm="1" className={'d-flex justify-content-end'} >
                  <strong>{billete.billeteValor}</strong>
                </CCol>
                <CCol xl="0" md="1" sm="1" >
                  <strong>{"X"}</strong>
                </CCol>
                <CCol xl="3" md="3" sm="2" >
                  <NumberBox readOnly={editableInput} format="#0" min={0} placeholder='' value={billete.cantidad} showSpinButtons={true} onValueChanged={(data) => aplicaNuevoValorBilletes(data, i)}
                  // onEnterKey={() => handleClickConfirmar()}
                  />
                </CCol>
                <CCol xl="0" md="1" sm="1" >
                  <strong>{"="}</strong>
                </CCol>
                <CCol xl="3" md="1" sm="1" >
                  <strong>{Number(billete.total).toFixed(2)}</strong>
                </CCol>
              </CRow>
            )
          })}


        </CCol>
        <CCol lg="6" xl="6" md="6" sm="12">

          <CRow className={'mt-2 mp-2 d-flex justify-content-start'}>
            <CCol lg="12" xl="12" sm="12">
              <strong>{"MONEDAS"}</strong>
            </CCol>
          </CRow>

          {objMonedas.map((moneda, i) => {
            return (
              <CRow key={i} className={'m-2 d-flex justify-content-center align-items-center'}>
                <CCol xl="2" md="1" sm="1" className={'d-flex justify-content-end'} >
                  <strong>{moneda.monedaValor}</strong>
                </CCol>
                <CCol xl="0" md="1" sm="1" >
                  <strong>{"X"}</strong>
                </CCol>
                <CCol xl="3" md="3" sm="2" >
                  <NumberBox readOnly={editableInput} format="#0" min={0} placeholder='' value={moneda.cantidad} showSpinButtons={true} onValueChanged={(data) => aplicaNuevoValorMonedas(data, i)}
                  // onEnterKey={() => handleClickConfirmar()}
                  />
                </CCol>
                <CCol xl="0" md="1" sm="1" >
                  <strong>{"="}</strong>
                </CCol>
                <CCol xl="3" md="1" sm="1" >
                  <strong>{Number(moneda.total).toFixed(2)}</strong>
                </CCol>
              </CRow>
            )
          })}

        </CCol>
      </CRow>

      <CContainer>
        <div className='row mt-4 mp-2 d-flex justify-content-center align-items-center'>

          <CCol md={{ span: 8, offset: 0 }} className='  d-flex justify-content-center align-items-center'>
            <strong style={{ fontSize: "20px", fontWeight: 400 }}>
              {"TOTAL EFECTIVO : "}
            </strong>
            <strong style={{ fontSize: "20px", fontWeight: 600 }}>
              {getValueEfectivo().toFixed(2)}
            </strong>
          </CCol>

          <CCol md={{ span: 8, offset: 0 }} className='  d-flex justify-content-center align-items-center'>
            <CTooltip
              key={"tool-plus-account"}
              placement="top"
              content={"CONFIRMAR"}
            >
              <CButton
                id="btnConfirmar"
                color="secondary"
                className="mt-4 mb-4"
                size='sm'
                onClick={() => handleClickConfirmar()}
              >
                {"CONFIRMAR"}
              </CButton>
            </CTooltip>

          </CCol>

          <CCol md={{ span: 8, offset: 0 }} className='  d-flex justify-content-center align-items-center'>
            <strong
              style={{ fontSize: "20px", fontWeight: 400 }}>
              {"TOTAL EGRESOS : "}
            </strong>
            <strong style={{ fontSize: "20px", fontWeight: 600 }}>
              {Number(Number(totalegreso_ti).toFixed(2))}
            </strong>
          </CCol>

          <CCol md={{ span: 8, offset: 0 }} className='  d-flex justify-content-center align-items-center'>
            <strong
              className={""}
              style={{ fontSize: "20px", fontWeight: 400 }}
            >
              {"DESCUADRE : "}
            </strong>
            <strong style={{ fontSize: "20px", color: descuadredes_ti === "Faltante" ? "#A52A2A" : "" }}>
              {Number(Number(descuadre_ti).toFixed(2))} {" " + descuadredes_ti}
            </strong>
          </CCol>
        </div>
      </CContainer >




    </>
  )
}
export default Denominacion;
