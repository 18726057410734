import { combineReducers } from "@reduxjs/toolkit";
import { ModuloNominaState } from "./types";
import { ModalUsuariosReducer } from '../componentes/modalUsuarios/store/modalUsuariosReducers'
import { candidatosReducer } from '../pages/candidatos/store/candidatosReducer'
import { PersonalReducer } from "../pages/personal/store/personalReducers";
import { cargosReducer } from '../pages/cargos/store/cargosReducer'
import { ContratosReducer } from "../pages/contratos/store/contratosReducer";
import { moduloRegistrosReducer } from "../pages/registro/store/registroReducer";
import { OrganigramaReducer } from "../pages/organigrama/store/organicoReducer";
import { MarcacionReducer } from "../pages/registro/marcacion/store/marcacionReducer";
import { moduloRolesReducer } from "../pages/roles/store/rolesReducer";

export const ModuloNominaReducer = combineReducers<ModuloNominaState>({
  modalUsuarios: ModalUsuariosReducer,
  candidatos: candidatosReducer,
  personal: PersonalReducer,
  cargos: cargosReducer,
  contratos: ContratosReducer,
  registros: moduloRegistrosReducer,
  organico: OrganigramaReducer,
  rol: moduloRolesReducer
})
