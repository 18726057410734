import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../../store/store'
import { FETCH_STATUS } from '../../../../../store/types'
import { DocCuentasCobrar } from '../services/docCuentasCobrar.service'
import {
  DocumentosCtasCobrarListado,
  FiltrosDocumentosState,
  SearchState,
} from '../types/types'
import { TEmpresasOption } from '../../../../componentes/empresasLookUp/store/type'

const initialState: SearchState<DocumentosCtasCobrarListado> = {
  filter: {
    empresa: null,
    cliente: null,
    puntoVenta: null,
    proyecto: null,
    lugar: null,
    nombre: '',
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  resultados: [],
}

export const fetchDocs = createAsyncThunk<
  Array<DocumentosCtasCobrarListado>,
  FiltrosDocumentosState
>('clientes/clientes/listar', async (filtro) => {
  try {
    const clientesApi = await DocCuentasCobrar.getDocumentos(
      parseInt(filtro.empresa.codigo),
      filtro.cliente.codigo,
      'filtro.razonSocial',
      'filtro.comprobante',
      filtro.puntoVenta.codigo,
      filtro.proyecto.codigo,
      filtro.lugar.codigo,
      true,
      true,
      'filtro.fecha',
    )
    return clientesApi
  } catch (error) {
    return Promise.reject(error)
  }
})

const searchDocumentosSlice = createSlice({
  name: 'searchDocumentos',
  initialState: initialState,
  reducers: {
    setEmpresa(state, action: PayloadAction<TEmpresasOption | null>) {
      state.filter.empresa = action.payload
    },
    setCleanResult(state) {
      state.resultados = []
      state.filter.empresa = null
      state.filter.cliente = null
      state.filter.puntoVenta = null
      state.filter.proyecto = null
      state.filter.lugar = null
      state.filter.nombre = ''
    },
    changeFilter(state, action: PayloadAction<FiltrosDocumentosState>) {
      // state.filter = action.payload
      state.filter.empresa = action.payload.empresa
      state.filter.cliente = action.payload.cliente
      state.filter.puntoVenta = action.payload.puntoVenta
      state.filter.lugar = action.payload.lugar
      state.filter.proyecto = action.payload.proyecto
      state.filter.nombre = action.payload.nombre
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDocs.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchDocs.fulfilled, (state, { payload }) => {
      state.resultados = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchDocs.rejected, (state, { payload }) => {
      state.resultados = []
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
  },
})

export const selectFiltroBusquedaDocs = (state: RootState) => {
  return state.clientes.docCuentasXCobrar.search.filter
}

export const { setEmpresa, changeFilter, setCleanResult } =
  searchDocumentosSlice.actions

export const searchDocumentosReducer = searchDocumentosSlice.reducer
