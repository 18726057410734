import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EdicionPayload } from '../../../../store/types'
import { InventarioMovDatosEdicion } from '../types/types'
import { CustomDictionary, LoaderInfo } from '../../../../../../store/types'

const initialState: CustomDictionary<InventarioMovDatosEdicion> = {}

const datosEditSlice = createSlice({
  name: 'inventarioInicialDatosEdicion',
  initialState: initialState,
  reducers: {
    deleteEditData(state, action: PayloadAction<string>) {
      delete state[action.payload]
    },
    setDatosEdicion(
      state,
      action: PayloadAction<{
        key: string
        inventario: InventarioMovDatosEdicion
      }>,
    ) {
      state[action.payload.key] = action.payload.inventario
    },
    changeLoaderImport(state, action: PayloadAction<{ key: number | string; loader: LoaderInfo }>) {
      state[action.payload.key].loader = action.payload.loader
    },
    initDatosEdicion(
      state,
      action: PayloadAction<EdicionPayload<InventarioMovDatosEdicion>>,
    ) {
      if (!state[action.payload.key]) {
        state[action.payload.key] = action.payload.data
      }
    },
    onChangeTotales(
      state,
      action: PayloadAction<{
        key: number | string
        subtotalIva: number | null
        subtotal: number | null
        subtotal0: number | null
        totalIva: number | null
        total: number | null
      }>,
    ) {
      state[action.payload.key].subtotalIva = action.payload.subtotalIva
      state[action.payload.key].subtotal0 = action.payload.subtotal0
      state[action.payload.key].subtotal = action.payload.subtotal
      state[action.payload.key].totalIva = action.payload.totalIva
      state[action.payload.key].total = action.payload.total
    },
    onChangeDetall(
      state,
      action: PayloadAction<{ key: number | string; detalle: Array<any> }>,
    ) {
      state[action.payload.key].dataGrid = action.payload.detalle
    },
  },
})

export const { setDatosEdicion, initDatosEdicion, deleteEditData, onChangeDetall, changeLoaderImport, onChangeTotales } =
  datosEditSlice.actions
export const dataEditReducer = datosEditSlice.reducer
