import { RequestHelper } from "../../../../helpers/requestHelper";
import { Cheque } from "../../../bancos/pages/cheques/store/types";

export const listadoComprobantesService = {
  verificarSinCuotas,
  verificarSinTarjetas,
  verificarSinCheques,
  verificarSinCuotasPunto,
  verificarSinChequesPunto,
  verificarSinTarjetasPunto,
  verificarSinCuotasPrv,
  verificarSinTarjetasPrv,
  verificarSinChequesPrv,
}

async function verificarSinCuotas(find): Promise<any> {
  try {
    const obj = {
      tipo: find,
    }
    const data = await RequestHelper.getAll<Array<Cheque>>(
      'clientes/DocCuentasPorCobrar',
      'sales/verifySalesWithoutDues',
      '',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}

async function verificarSinCheques(find): Promise<any> {
  try {
    const obj = {
      tipo: find,
    }
    const data = await RequestHelper.getAll<Array<Cheque>>(
      'clientes/DocCuentasPorCobrar',
      'sales/verifySalesWithoutChecks',
      '',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}

async function verificarSinTarjetas(find): Promise<any> {
  try {
    const obj = {
      tipo: find,
    }
    const data = await RequestHelper.getAll<Array<Cheque>>(
      'clientes/DocCuentasPorCobrar',
      'sales/verifySalesWithoutCreditCards',
      '',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}

async function verificarSinCuotasPunto(find, puntoVenta): Promise<any> {
  try {
    const obj = {
      tipo: find,
      puntoVenta: puntoVenta,
    }
    const data = await RequestHelper.getAll<Array<Cheque>>(
      'generales/sales',
      'verifyWithoutCuotDot',
      '',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}

async function verificarSinChequesPunto(find, puntoVenta): Promise<any> {
  try {
    const obj = {
      tipo: find,
      puntoVenta: puntoVenta,
    }
    const data = await RequestHelper.getAll<Array<Cheque>>(
      'generales/sales',
      'verifyWithoutChecksDot',
      '',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}

async function verificarSinTarjetasPunto(find, puntoVenta): Promise<any> {
  try {
    const obj = {
      tipo: find,
      puntoVenta: puntoVenta,
    }
    const data = await RequestHelper.getAll<Array<Cheque>>(
      'generales/sales',
      'verifyWithoutCardDot',
      '',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}

async function verificarSinCuotasPrv(find): Promise<any> {
  try {
    const obj = {
      tipo: find,
    }
    const data = await RequestHelper.getAll<Array<Cheque>>(
      'proveedores/documentosPorPagar',
      'verifyWithoutPay',
      '',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}

async function verificarSinChequesPrv(find): Promise<any> {
  try {
    const obj = {
      tipo: find,
    }
    const data = await RequestHelper.getAll<Array<Cheque>>(
      'proveedores/documentosPorPagar',
      'verifyWithoutChecks',
      '',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}

async function verificarSinTarjetasPrv(find): Promise<any> {
  try {
    const obj = {
      tipo: find,
    }
    const data = await RequestHelper.getAll<Array<Cheque>>(
      'proveedores/documentosPorPagar',
      'verifyWithoutCard',
      '',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}
