import React from 'react'
import { estadosService } from './service/estados.service'
import { EstadosCivilOption } from './store/type'
import { SelectBoxDE } from '../../../views/componentes/selectBox-DE/selectBox-DE'

interface EstadosCivilLookUpProps extends React.PropsWithChildren {
  selected: EstadosCivilOption | null
  onChanged: (newValue: EstadosCivilOption | null) => void
  disabled?: boolean
  provider?: Array<EstadosCivilOption> | []
  id: string
}

const EstadosCivilLookUp: React.FC<EstadosCivilLookUpProps> = (props) => {
  const { provider, selected, onChanged, disabled, id } = props
  const selectRef = React.useRef<any>()
  const [options, setOptions] = React.useState<Array<EstadosCivilOption> | []>(
    [],
  )

  const loadDatos = React.useCallback(async () => {
    if (provider !== undefined && provider.length > 0) {
      setOptions(provider)
      const selectedIndex = provider.findIndex((select: EstadosCivilOption) => {
        return select.codigo === selected?.codigo
      })
      onChanged(provider[selectedIndex] ?? provider[0])
      if (selectedIndex > -1) {
        onChanged(provider[selectedIndex] ?? provider[0])
      }
    } else {
      const data = await estadosService.getEstados('Elija un estado')
      if (data.error === false) {
        let opt = []
        if (data.auto.length > 0) {
          opt = data.auto.map((x) => {
            const item: EstadosCivilOption = {
              codigo: parseInt(x?.codigo) ?? -1,
              descripcion: x?.descripcion ?? '',
            }
            return item
          })
        }
        setOptions(opt)
        const selectedIndex = opt.findIndex((option) => {
          return option.codigo === selected?.codigo
        })
        if (selectedIndex > -1) {
          onChanged(opt[selectedIndex] ?? opt[0])
        }
      }
    }
  }, [onChanged, selected, provider])

  const getItemDisplayExpr = React.useCallback((item: EstadosCivilOption) => {
    return item ? `${item.descripcion}` : ''
  }, [])

  const onLookUpValueChanged = React.useCallback(
    async (evt: any) => {
      await onChanged(evt)
    },
    [onChanged],
  )

  const refreshSelected = React.useCallback(async () => {
    if (options && options.length > 0) {
      const selectedIndex = await options.findIndex(
        (option: EstadosCivilOption) => {
          return option.codigo === selected?.codigo
        },
      )
      onChanged(options[selectedIndex] ?? options[0])
    }
    selectRef?.current?.instance?.repaint()
  }, [onChanged, options, selected])

  const onSelectBoxFocusIn = React.useCallback((e) => {
    e.element.querySelector('input.dx-texteditor-input').select()
  }, [])

  React.useEffect(() => {
    loadDatos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    refreshSelected()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  return (
    <SelectBoxDE
      id="estadoCivilSelect"
      ref={selectRef}
      items={options ?? []}
      searchEnabled
      displayExpr={getItemDisplayExpr}
      searchExpr={['descripcion']}
      value={selected}
      onValueChange={onLookUpValueChanged}
      disabled={disabled}
      onFocusIn={onSelectBoxFocusIn}
    >
      {props.children}
    </SelectBoxDE>
  )
}
export default React.memo(EstadosCivilLookUp)
