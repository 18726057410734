import React, { useCallback, useEffect } from 'react'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import { CCardBody, CCardHeader, CCollapse, CLink } from '@coreui/react-pro'
import { useDispatch, useSelector } from 'react-redux'
import { cilChevronBottom, cilChevronTop } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { RootState } from '../../../../../../../store/store'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput'
import { DateBox, RadioGroup, SelectBox, TextBox } from 'devextreme-react'
import { changeFilter, changeFilterBackup, setChangeLoader, setCollapsed, setImport, setRolParams } from '../../store/searchReducers'
import { ETipoTransaccion, TFormaPagoOption } from '../../../../../../componentes/formasPagoLookUp/store/type'
import { DecimosDatosEdicion, FiltroBudquedaDecios } from '../../types/types'
import { formasPagoService } from '../../../../../../componentes/formasPagoLookUp/service/formasPago.service'
import { ToastTypes } from '../../../../../../../store/types'
import { addToast } from '../../../../../../../store/toasterReducer'
import { EAplicationsAcatha } from '../../../../../../../store/enumsAplication'
import { TabState } from '../../../../../../../store/genericTabTypes'
import Importar from '../../../../../../clientes/pages/docsCtasXCobrar/components/impportarExcel/importar'
import { DateUtils } from '../../../../../../../helpers/dateUtils'
import { RolsPaymentsServices } from '../../../rolesPago/services/rolsPays.services'


export const gruposDecimos = [
  { value: 0, label: 'Todos' },
  { value: 1, label: 'Cobrados' },
  { value: 2, label: 'Sin Cobrar' },
]

interface IFilterhProps extends React.PropsWithChildren {
  tab: TabState<DecimosDatosEdicion>
  tabId: string
  onSearchEnter: () => void
}

const FiltroBusqueda: React.FunctionComponent<IFilterhProps> = (props) => {
  const { tab, tabId, onSearchEnter } = props
  const dispatch = useDispatch()
  const collapsed = useSelector((state: RootState) => state.nomina.rol.decimos.search.colapsado)
  const currentApp = useSelector((state: RootState) => state.nomina.rol.decimos.config.currentApp)
  const filter = useSelector((state: RootState) => state.nomina.rol.decimos.search.filter)
  const showImportButton = useSelector((state: RootState) => state.nomina.rol.decimos.search.import)
  const activePeriod = useSelector((state: RootState) => state.shared.providers.periodoContable)


  const setToast = useCallback(
    (mensaje: string, tipo: ToastTypes) => {
      dispatch(
        addToast({
          title: `Nómina - Decimo ${currentApp === parseInt(EAplicationsAcatha.DecimoTercero) ? 'Tercero' : 'Cuarto'}`,
          content: mensaje,
          type: tipo,
        }),
      )
    },
    [dispatch, currentApp],
  )

  const onLoader = useCallback((showLoader: boolean, mensaje: string) => {
    dispatch(setChangeLoader({
      show: showLoader,
      mensaje: mensaje
    }))
  }, [dispatch])

  const onInitialValues = useCallback(async () => {
    const temp = { ...filter }
    if (temp.initial) {
      onLoader(true, 'Cargando...')
      let params = null
      try {
        const rolParams = await RolsPaymentsServices.loadRolParameters(0)
        const dataFP = await formasPagoService.getFormasPago(ETipoTransaccion.ventas, "Elija una opción");
        if (rolParams?.auto && rolParams?.error === false) {
          params = rolParams?.auto
        }
        if (dataFP?.auto && dataFP?.error === false) {
          const newFormPago = []
          dataFP?.auto.forEach(x => {
            if (x?.codigo < 8 || x?.codigo === 114) {
              newFormPago.push(x)
            }
          })
          temp.listaFormasPagos = newFormPago
          temp.formaPago = newFormPago[0]
        }
        temp.periodo = activePeriod.length > 0 ? activePeriod[0] : null
        temp.estado = gruposDecimos[0]
      } catch (error) {
        setToast(error?.message, ToastTypes.Danger)
      }
      temp.initial = false
      dispatch(changeFilter({
        ...temp
      }))
      dispatch(changeFilterBackup({
        ...temp
      }))
      dispatch(setRolParams(params))
      onLoader(false, '')
    }
  }, [filter, dispatch, activePeriod, setToast, onLoader])


  const onValueChange = useCallback((data, key: string) => {
    dispatch(changeFilter({
      ...filter,
      [key]: data
    }))
  }, [filter, dispatch])

  useEffect(() => {
    onInitialValues()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getItemDisplayExpr = React.useCallback((item: TFormaPagoOption) => {
    return item ? `${item.descripcion}` : '';
  }, []);

  const onGetData = useCallback((data: any) => {
    if (data?.error === true) {
      setToast(data?.message, ToastTypes.Danger)
    }
  }, [setToast])


  return (
    <>
      <CCardHeader
        onClick={() => dispatch(setCollapsed(!collapsed))}
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <strong> {collapsed ? '' : 'Filtro de Búsqueda'} </strong>
        <CLink className="card-header-action">
          <CIcon icon={collapsed ? cilChevronBottom : cilChevronTop} />
        </CLink>
      </CCardHeader>
      <CCollapse visible={collapsed}>
        <CCardBody>
          <RowContainer>
            <CustomCol xs="12" md={showImportButton ? '7' : '12'}>
              <RowContainer>
                <CustomCol xs="12" md="6">
                  <RowContainer>
                    <CustomCol xs="12" md="12">
                      <Labeled label='Identificación:'>
                        <TextBox
                          value={filter?.identificacion ?? ''}
                          onValueChange={(data) => onValueChange(data, 'identificacion')}
                          onEnterKey={onSearchEnter}
                        />
                      </Labeled>
                    </CustomCol>
                  </RowContainer>
                  <RowContainer>
                    <CustomCol xs="12" md="12">
                      <Labeled label='Nombre:'>
                        <TextBox
                          value={filter?.nombre ?? ''}
                          onValueChange={(data) => onValueChange(data, 'nombre')}
                          onEnterKey={onSearchEnter}
                        />
                      </Labeled>
                    </CustomCol>
                  </RowContainer>
                  <RowContainer>
                    <CustomCol xs="12" md="12">
                      <Labeled label="Periodo:">
                        <SelectBox
                          id='TFormaPeriodo'
                          items={activePeriod ?? []}
                          searchEnabled
                          displayExpr={'anio'}
                          searchExpr={['anio']}
                          searchTimeout={500}
                          value={filter?.periodo ?? null}
                          onValueChange={(data) => onValueChange(data, 'periodo')}
                        />
                      </Labeled>
                    </CustomCol>
                  </RowContainer>
                  <RowContainer>
                    <CustomCol xs="12" md="12">
                      <Labeled label='Estado:'>
                        <RadioGroup
                          name="decimos"
                          layout="horizontal"
                          displayExpr="label"
                          dataSource={gruposDecimos}
                          value={filter?.estado ?? null}
                          onValueChange={(data) => onValueChange(data, 'estado')}
                        />
                      </Labeled>
                    </CustomCol>
                  </RowContainer>
                </CustomCol>
                <CustomCol xs="12" md="6">
                  <CustomCol xs="12" md="6">
                    <RowContainer>
                      <h6>{'Opción válida para pagos'}</h6>
                      <CustomCol xs="12" md="12">
                        <Labeled label='Fecha de Cobro:'>
                          <DateBox
                            value={filter?.fechaCobro ?? null}
                            onValueChange={(data) => {
                              if (data !== null && data !== "" && data !== undefined) {
                                if (typeof data === "object") {
                                  const fecha = DateUtils.dateToString(data)
                                  onValueChange(fecha, 'fechaCobro')
                                } else {
                                  onValueChange(data, 'fechaCobro')
                                }
                              }
                            }}
                            onEnterKey={onSearchEnter}
                          />
                        </Labeled>
                      </CustomCol>
                    </RowContainer>
                    <RowContainer>
                      <CustomCol xs="12" md="12">
                        <Labeled label="Forma de Pago:">
                          <SelectBox
                            id='TFormaPagoOptionSelect'
                            items={filter?.listaFormasPagos ?? []}
                            searchEnabled
                            displayExpr={getItemDisplayExpr}
                            searchExpr={['descripcion']}
                            searchTimeout={500}
                            value={filter?.formaPago ?? null}
                            onValueChange={(data) => onValueChange(data, 'formaPago')}
                          />
                        </Labeled>
                      </CustomCol>
                    </RowContainer>
                  </CustomCol>
                </CustomCol>
              </RowContainer>
            </CustomCol>
            {
              showImportButton && (
                <CustomCol xs="12" md="5">
                  <Importar
                    tab={tab}
                    tabId={tabId}
                    openImport={(open) => dispatch(setImport(open))}
                    moduleCode={currentApp}
                    setData={(data) => onGetData(data)}
                  />
                </CustomCol>
              )
            }
          </RowContainer>
        </CCardBody>
      </CCollapse>
    </>
  )
}

export default React.memo(FiltroBusqueda)

export const FilterInitial: FiltroBudquedaDecios = {
  identificacion: '',
  nombre: '',
  periodo: null,
  listaFormasPagos: [],
  estado: null,
  fechaCobro: '',
  formaPago: null,
  initial: true
}