import React, { useState, useEffect } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow, CTooltip
} from '@coreui/react-pro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux'
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import Buscar from "./components/buscar";
import { BotonMenu } from '../../../contabilidad/pages/planCuentas/store/types';
import config from '../../../../config/config';
import { menuService } from '../../../../services/menu.service';
import { TIPOS_BOTONES } from './store/types';
import { changeLoader } from '../../../../store/reducers';
import { Seleccionado, ToastTypes } from '../../../../store/types';
import { addToast } from '../../../../store/toasterReducer';
import { lh, MessagesKeys } from '../../../../helpers/localizationHelper';
import Modalform from '../../../../views/componentes/modalform';
import Dialog from '../../../../views/componentes/librerias/bootstrap-dialog';

type IModalClientesProps = {
  show: boolean,
  onClose: () => void,
  returnCliente: (evt) => void
}


export const ModalClientes: React.FC<IModalClientesProps> = (props) => {

  const { onClose, show, returnCliente } = props;
  const refBuscar = React.useRef();
  const dialogRef = React.useRef<any>(null);

  const dispatch = useDispatch()

  const [acciones, setAcciones] = useState<any>([]);
  const [codModulo, setCodModulo] = React.useState<number>(0);
  const [currentAction, setCurrentAccion] = React.useState<TIPOS_BOTONES>(TIPOS_BOTONES.BOTON_BUSCAR);
  const [currentFunction, setCurrentFunction] = React.useState<string>("");
  const [seleccionado, setSeleccionarCliente] = React.useState<Seleccionado>({
    index: null,
    row: null,
    selectedRow: null
  });

  const botones = config['menuDefecto'].map(acc => {
    const boton: BotonMenu = {
      id: acc.text as any,
      icon: menuService.getIcon(acc.icon),
      text: acc.text
    }
    return boton;
  });

  const playLoader = React.useCallback(() => {
    dispatch(changeLoader({ show: true, mensaje: 'Cargando...' }))
  }, [dispatch]);

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoader({ show: false, mensaje: '' }))
  }, [dispatch]);

  const setToast = React.useCallback((texto, type) => {
    let tipo: ToastTypes = ToastTypes.Info;
    if (type === 'warning') {
      tipo = ToastTypes.Warning;
    } else if (type === 'success') {
      tipo = ToastTypes.Success;
    } if (type === 'info') {
      tipo = ToastTypes.Info;
    } if (type === 'danger') {
      tipo = ToastTypes.Danger;
    }
    dispatch(addToast({
      id: '',
      autoHide: 3500,
      title: 'Acatha',
      content: texto,
      fade: true,
      type: tipo
    }));
  }, [dispatch]);

  const getButtonDisabled = React.useCallback((button: BotonMenu) => {
    switch (button.id) {
      case TIPOS_BOTONES.BOTON_BUSCAR:
        return false;
      default:
        return true;
    }
  }, [currentAction, seleccionado]);

  const changeNav = React.useCallback((destinoNavegacion: TIPOS_BOTONES) => {
    setCurrentAccion(destinoNavegacion)
  }, []);


  const onResetSeleccion = React.useCallback(() => {
    setSeleccionarCliente({
      index: null,
      row: null,
      selectedRow: null
    })
  }, []);

  const onSeleccion = React.useCallback((data: Seleccionado) => {
    setSeleccionarCliente(data)
  }, []);

  const onConfirmarSalir = React.useCallback((destinoNavegacion: TIPOS_BOTONES) => {
    dialogRef.current.show({
      title: <><strong>{"Ácatha"}</strong></>,
      body: <label style={{ fontSize: "14px", fontWeight: 600 }}>{lh.getMessage(MessagesKeys.FormConfirmExitForm)}</label>,
      actions: [
        Dialog.Action(
          <span><u>{"A"}</u>{"ceptar"}</span>,
          (dialog) => {
            console.log("success");
            // resetFormularioRedux();
            changeNav(destinoNavegacion);
            dialog.hide();
          },
          'btn-success',
          'n'
        ),
        Dialog.Action(
          <span><u>{"C"}</u>{"ancelar"}</span>,
          (dialog) => {
            dialog.hide();
          },
          'btn-danger',
          'c'
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide();
      }
    });
    return;
  }, [changeNav]);

  const returnAction = React.useCallback((metodo: TIPOS_BOTONES) => {
    console.log("returnAction", metodo)
    console.log("currentAction", currentAction)
    switch (metodo) {
      case TIPOS_BOTONES.BOTON_BUSCAR:
        if (currentAction === TIPOS_BOTONES.BOTON_BUSCAR) {
          setCurrentFunction(TIPOS_BOTONES.BOTON_BUSCAR)
        } else {

          if (currentAction === TIPOS_BOTONES.BOTON_NUEVO) {
            onConfirmarSalir(TIPOS_BOTONES.BOTON_BUSCAR);
            return false;
          } if (currentAction === TIPOS_BOTONES.BOTON_EDITAR) {
            onConfirmarSalir(TIPOS_BOTONES.BOTON_BUSCAR);
            return false;
          } else {
            onResetSeleccion();
            setCurrentAccion(TIPOS_BOTONES.BOTON_BUSCAR)
          }

        }
        break;
      default:
        break;
    }
  }, [
    currentAction, onResetSeleccion, onConfirmarSalir
  ]);

  const verificaEdicion = React.useCallback(async () => {

  }, []);


  const bodyModalForm = () => {
    if (currentAction === "Buscar") {
      return (
        <>
          <Buscar
            referencia={refBuscar}
            returnCliente={(evt) => returnCliente(evt)}
            codModulo={codModulo}
            setToast={setToast}
            playLoader={playLoader}
            stopLoader={stopLoader}
            returnAction={() => { setCurrentFunction(TIPOS_BOTONES.BOTON_BUSCAR) }}
            verificaEdicion={verificaEdicion}
            pasarSeleccionados={returnCliente}
            setCurrentFunction={(data) => { setCurrentFunction(data) }}
            onResetSeleccion={onResetSeleccion}
            onSeleccion={onSeleccion}
            currentFunction={currentFunction}
          />
        </>
      )
    } else {
      return (
        <>
        </>
      )
    }
  }


  const renderBody = () => {

    return (
      <>


        <CCard key="cardClientes" >
          <CCardHeader >
            <CRow>
              <CCol className="ms-0 d-flex align-items-start justify-content-start" >
                <div key='menuItems' className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups" style={{ marginBottom: '5px', paddingTop: '-2rem' }}>
                  <div key='menuItemsBotones' className="btn-group-lg btn-group btn-sm me-2" role="group" aria-label="First group" >
                    {acciones.map(function (x, i) {
                      const prefixButton = "buttonsCuentas_";
                      const nameButon = x.text;
                      const disabled = getButtonDisabled(x);
                      return (
                        <CTooltip
                          key={"tool-" + prefixButton + nameButon}
                          placement="top"
                          content={nameButon}
                        >
                          <CButton
                            key={prefixButton + nameButon}
                            name={prefixButton + nameButon}
                            title={x.text}
                            id={prefixButton + nameButon}
                            value={x.text}
                            className="btn btn-secondary sm"
                            variant="ghost"
                            onClick={(ev) => returnAction(ev = x.text)}
                            size='sm'
                            disabled={disabled}
                          >
                            <FontAwesomeIcon size='sm' icon={x.icon ?? faQuestionCircle} />
                          </CButton>
                        </CTooltip>
                      );
                    })}
                  </div>
                </div>
              </CCol>
            </CRow>
          </CCardHeader >

          <CCardBody >
            {bodyModalForm()}
          </CCardBody >


        </CCard>
      </>
    )
  }

  useEffect(() => {
    if (show) {
      setAcciones(botones)
      setCodModulo(codModulo);
    }
  }, [show]);

  return (
    <CRow key="rowMain">
      <div>
        <Dialog ref={dialogRef} />
      </div>
      <CCol key="colMain" >
        <Modalform
          name='apis'
          key='modalClientes'
          headerTitle={"Clientes"}
          childrenBody={renderBody()}
          childrenFooter={<></>}
          closeOnBackdrop={false}
          show={show}
          onClose={onClose}
          centered={true}
          size="lg"
        />
      </CCol>
    </CRow>
  );
}

