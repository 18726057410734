import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CustomDictionary, LoaderInfo } from '../../../../../store/types'
import { Permissions } from '../types/types'
import { EdicionPayload } from '../../../store/types'

const initialState: CustomDictionary<Permissions> = {}

const permissionsSlice = createSlice({
  name: 'positions-permissions-DataEdit',
  initialState: initialState,
  reducers: {
    deletePermission(state, action: PayloadAction<string>) {
      delete state[action.payload]
    },
    updatePermissions(
      state,
      action: PayloadAction<{
        key: string
        permission: Permissions
      }>,
    ) {
      const { key, permission } = action.payload
      state[key].availableCommands = permission.availableCommands
      state[key].isLoadedCommands = permission.isLoadedCommands
      state[key].assignedCommands = permission.assignedCommands
    },
    initPermission(state, action: PayloadAction<EdicionPayload<Permissions>>) {
      if (!state[action.payload.key])
        state[action.payload.key] = action.payload.data
    },
    showLoaderPermissionsTab(
      state,
      action: PayloadAction<{
        key: string
        loader: LoaderInfo
      }>,
    ) {
      state[action.payload.key].loader = action.payload.loader
    },
  },
})

export const {
  deletePermission,
  showLoaderPermissionsTab,
  updatePermissions,
  initPermission,
} = permissionsSlice.actions
export const permissionsReducer = permissionsSlice.reducer
