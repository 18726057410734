import { combineReducers } from "@reduxjs/toolkit";
import { CCAhorrosReducer } from '../pages/ccahorros/store/CCAhorrosReducers';
import { BancosReducer } from '../pages/bancos/store/BancosReducers';
import { ModuloBancosState } from "./types";
import { CConciliacionReducer } from "../pages/conciliacionban/store/CConciliacionReducers";
import { ChequesReducer } from "../pages/cheques/store/ChequesReducers";
import { MovimientoBancosReducer } from "../pages/movimientosBancos/store/movimientoBancosReducers";
import { GeneralBancosReducer } from "../general/generalBancosReducer";
import { DiarioBancosReducer } from "../pages/diarioBancos/store/diarioBancosReducer";
import { modalTarjetasReducer } from "../../componentes/modalTarjetas/store/modalTarjetasReducer";
import { modalAnticiposReducer } from "../../componentes/modalAnticipos/store/modalAnticiposReducer";
import { modalNovedadesReducer } from "../../componentes/modalNovedades/store/modalNovedadesReducer";
import { modalIngresosReducer } from "../../componentes/modalIngresos/store/modalNovedadesReducer";
import { modalSeleccionRetencionReducer } from "../../componentes/modalSeleccionRetencion/store/modalSeleccionRetencion";
import { modalIngresoChequesReducer } from "../../componentes/modalIngresoCheques/store/modalIngresoCheques";
import { modalSeleccionChequesReducer } from "../../componentes/modalSeleccionCheques/store/modalSeleccionChequesReducer";
import { MovimientoCajasReducer } from "../pages/movimientosCajas/store/movimientoCajasReducers";

export const moduloBancosReducer = combineReducers<ModuloBancosState>({
  bancos: BancosReducer,
  ccahorros: CCAhorrosReducer,
  cconciliacion: CConciliacionReducer,
  cheques: ChequesReducer,
  movimientoBancos: MovimientoBancosReducer,
  movimientoCajas: MovimientoCajasReducer,
  diarioBancos: DiarioBancosReducer,
  generalBancos: GeneralBancosReducer,
  modalTarjetas: modalTarjetasReducer,
  modalAnticipos: modalAnticiposReducer,
  modalNovedades: modalNovedadesReducer,
  modalIngresos: modalIngresosReducer,
  modalSeleccionRetencion: modalSeleccionRetencionReducer,
  modalIngresoCheques: modalIngresoChequesReducer,
  modalSeleccionCheques: modalSeleccionChequesReducer
})
