import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { StoreList } from '../types/types'
import { EdicionPayload } from '../../../store/types'
import { OptionTiposLocales } from '../../../../componentes/tiposLocalesLookUp/types/types'
import { LocalOption } from '../../../../componentes/localesLookUp/store/type'
import { CustomDictionary, TEstado } from '../../../../../store/types'

const initialState: CustomDictionary<StoreList> = {}

const datosEditSlice = createSlice({
  name: 'multisotreDataEdit',
  initialState: initialState,
  reducers: {
    deleteEditData(state, action: PayloadAction<string>) {
      delete state[action.payload]
    },
    setDatosEdicion(
      state,
      action: PayloadAction<{
        key: string
        store: StoreList
      }>,
    ) {
      state[action.payload.key].codigo = action.payload.store.codigo
      state[action.payload.key].calseLocal = action.payload.store.calseLocal
      state[action.payload.key].stockWeb = action.payload.store.stockWeb
      state[action.payload.key].nombre = action.payload.store.nombre
      state[action.payload.key].siglas = action.payload.store.siglas
      state[action.payload.key].inv = action.payload.store.inv
      state[action.payload.key].desc = action.payload.store.desc
      state[action.payload.key].cuidad = action.payload.store.cuidad
      state[action.payload.key].direccion = action.payload.store.direccion
      state[action.payload.key].telefono = action.payload.store.telefono
      state[action.payload.key].gerente = action.payload.store.gerente
      state[action.payload.key].tipo = action.payload.store.tipo
      state[action.payload.key].pertence = action.payload.store.pertence
      state[action.payload.key].transaccionEnCero =
        action.payload.store.transaccionEnCero
      state[action.payload.key].usarListaPrecios =
        action.payload.store.usarListaPrecios
      state[action.payload.key].codigoUaf = action.payload.store.codigoUaf
      state[action.payload.key].estado = action.payload.store.estado
      state[action.payload.key].usuario = action.payload.store.usuario
      state[action.payload.key].contrasena = action.payload.store.contrasena
      state[action.payload.key].imagen = action.payload.store.imagen
      state[action.payload.key].imagenCodificada =
        action.payload.store.imagenCodificada
    },
    setTypeStore(
      state,
      action: PayloadAction<{
        key: string
        typeStore: OptionTiposLocales
      }>,
    ) {
      state[action.payload.key].tipo = action.payload.typeStore
    },
    setStoreBelongs(
      state,
      action: PayloadAction<{
        key: string
        storeBelongs: LocalOption
      }>,
    ) {
      state[action.payload.key].pertence = action.payload.storeBelongs
    },
    setStoreStatus(
      state,
      action: PayloadAction<{
        key: string
        storeStatus: TEstado
      }>,
    ) {
      state[action.payload.key].estado = action.payload.storeStatus
    },
    initDatosEdicion(state, action: PayloadAction<EdicionPayload<StoreList>>) {
      if (!state[action.payload.key]) {
        state[action.payload.key] = action.payload.data
      }
    },
  },
})

export const {
  setDatosEdicion,
  initDatosEdicion,
  deleteEditData,
  setTypeStore,
  setStoreBelongs,
  setStoreStatus,
} = datosEditSlice.actions
export const dataEditReducer = datosEditSlice.reducer
