import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './store'
import { Dashboard, DashboardModes, DashboardsState } from './types'

export const InitialState: DashboardsState = {
  dashboards: [],
  loading: true,
  mode: DashboardModes.Viewer,
}

const dashboardsSlice = createSlice({
  name: 'dashboards',
  initialState: InitialState,
  reducers: {
    changeCurrentDashboard(
      state,
      action: PayloadAction<Dashboard | undefined>,
    ) {
      state.currentDashboard = action.payload
    },
    setDashboards(state, action: PayloadAction<Array<Dashboard>>) {
      state.dashboards = action.payload
      if (action.payload.length > 0) {
        state.currentDashboard = action.payload[0]
      } else {
        state.currentDashboard = undefined
      }
      state.loading = false
    },
    setDashboardMode(state, action: PayloadAction<DashboardModes>) {
      state.mode = action.payload
    },
  },
})

export const { changeCurrentDashboard, setDashboards, setDashboardMode } =
  dashboardsSlice.actions
export const selectCurrentDashboard = (state: RootState) => {
  return state.dashboards.currentDashboard
}
export const selectDashboards = (state: RootState) => {
  return state.dashboards.dashboards
}
export const selectDashboardsLoading = (state: RootState) => {
  return state.dashboards.loading
}
export const selectDashboardMode = (state: RootState) => {
  return state.dashboards.mode
}
export const dashboardsReducer = dashboardsSlice.reducer
