import React from 'react';
import Button from 'devextreme-react/button';
import ActionSheet from 'devextreme-react/action-sheet';

interface IActionSheetCommandsMobileProps extends React.PropsWithChildren {
  importacion: any
  onDelete: (importacion: any) => void,
  onVer: (importacion: any) => void,
  onEdit: (importacion: any) => void,
}

/*
{ text: 'Email', icon: 'email' },
  { text: 'Autorizar', icon: 'email' },
*/

export const ActionSheetCommandsMobile: React.FC<IActionSheetCommandsMobileProps> = (props) => {
  const { onDelete, importacion, onVer, onEdit } = props;
  const [visible, setVisible] = React.useState<boolean>(false);
  const [commands, setCommands] = React.useState<Array<any>>([]);

  const onButtonClick = () => {
    setVisible(!visible)
  }

  const onItemClick = React.useCallback(async (e) => {
    const { itemData } = e;
    switch (itemData.text) {
      case "Anular":
        onDelete(importacion)
        break;
      case "Ver":
        onVer(importacion)
        break;
      case "Editar":
        onEdit(importacion)
        break;

      default:
        break;
    }
  }, [onDelete, importacion, onVer, onEdit])

  React.useEffect(() => {
    const cmds: Array<any> = [
      { text: 'Anular', icon: 'trash' },
      { text: 'Ver', icon: 'file' },
      { text: 'Editar', icon: 'edit' },
    ];
    setCommands(cmds)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const keyId = `filamovimiento${importacion.documento ?? importacion.codigo}`
  return (
    <>
      <Button id={keyId} icon='overflow' type='default' onClick={onButtonClick} />
      <ActionSheet
        title="Tareas"
        usePopover={true}
        visible={visible}
        target={'#' + keyId}
        items={commands}
        itemComponent={ActionSheetCommand}
        onItemClick={onItemClick}
        onVisibleChange={(value) => {
          if (value !== visible) setVisible(!visible)
        }}
      />
    </>
  );
};



const ActionSheetCommand: React.FC<any> = (props) => {

  return <Button stylingMode='contained' type='default' text={props.data.text} icon={props.data.icon} />
}