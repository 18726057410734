import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BusquedaTransferState, FiltroBusqudaTransfer } from "../types/types";
import { LoaderInfo } from '../../../../../../store/types';
import { TransferList } from '../../rolesPago/types/types';


const initialState: BusquedaTransferState = {
  filter: {
    cuentaNombre: '',
    cuentaCodigo: 0,
    anio: 0,
    initial: true
  },
  filterBackup: {
    cuentaNombre: '',
    cuentaCodigo: 0,
    anio: 0,
    initial: true
  },
  resultados: [],
  seleccionado: null,
  colapsado: true,
  import: false,
  searchRols: false,
  loader: {
    show: false,
    mensaje: '',
  },
  nroRegistrosEncontrados: 0,
}


const transferSlice = createSlice({
  name: 'buscarTransfer',
  initialState: initialState,
  reducers: {
    setResetResults(state) {
      state.resultados = []
    },
    setResults(state, action: PayloadAction<TransferList[]>) {
      state.resultados = action.payload
    },
    setResetSeleccion(state) {
      state.seleccionado = null
    },
    setSeleccionarDato(state, action: PayloadAction<TransferList>) {
      state.seleccionado = action.payload
    },
    changeFilter(state, action: PayloadAction<FiltroBusqudaTransfer>) {
      state.filter.cuentaNombre = action.payload.cuentaNombre
      state.filter.cuentaCodigo = action.payload.cuentaCodigo
      state.filter.anio = action.payload.anio
      state.filter.initial = action.payload.initial
    },
    changeFilterBackup(state, action: PayloadAction<FiltroBusqudaTransfer>) {
      state.filterBackup.cuentaNombre = action.payload.cuentaNombre
      state.filterBackup.cuentaCodigo = action.payload.cuentaCodigo
      state.filterBackup.anio = action.payload.anio
      state.filterBackup.initial = action.payload.initial
    },
    setCollapsed(state, action: PayloadAction<boolean>) {
      state.colapsado = action.payload
    },
    setNroRegistrosEncontradas(state, action: PayloadAction<number>) {
      state.nroRegistrosEncontrados = action.payload
    },
    setChangeLoader(state, action: PayloadAction<LoaderInfo>) {
      state.loader = action.payload
    },
    setImport(state, action: PayloadAction<boolean>) {
      state.import = action.payload
    },
    setSearch(state, action: PayloadAction<boolean>) {
      state.searchRols = action.payload
    },
  }
})


export const {
  setResetResults,
  setResults,
  changeFilter,
  changeFilterBackup,
  setResetSeleccion,
  setSeleccionarDato,
  setCollapsed,
  setImport,
  setNroRegistrosEncontradas,
  setChangeLoader,
  setSearch,
} = transferSlice.actions
export const transferReducer = transferSlice.reducer