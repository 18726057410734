import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../../store/store'
import { ButtonTypes } from '../../../../../views/componentes/globalMenu/types'
import { StatesEdition, TabTypes } from '../../../../../store/enums'
import { EmpresaListado } from './types'
import { TabsState, TabState } from '../../../../../store/genericTabTypes'
import { CustomButtons } from '../../../../../store/types'

const initialState: TabsState<EmpresaListado> = {
  current: 'MANTENIMIENTO',
  tabs: {
    MANTENIMIENTO: {
      id: 0,
      canClose: false,
      editStatus: StatesEdition.doesNotApply,
      globalButtonClick: ButtonTypes.none,
      tittle: 'Mi Empresa',
      type: TabTypes.documento,
      imprimir: false,
      tabKey: 'MANTENIMIENTO',
      buttons: {
        Guardar: true,
        Deshacer: true,
      },
    },
  },
}

const tabsConfEmpresaSlice = createSlice({
  name: 'empresaTabs',
  initialState: initialState,
  reducers: {
    // ventasCloseTab,
    openTab(
      state,
      action: PayloadAction<{
        key: string
        venta?: EmpresaListado
        templateMode?: boolean
      }>,
    ) {
      if (action.payload.venta === undefined || action.payload.templateMode) {
        // const tab: TabState<EmpresaListado> = {
        //   id: -1,
        //   canClose: true,
        //   editStatus: StatesEdition.new,
        //   globalButtonClick: ButtonTypes.none,
        //   tittle: 'Nueva',
        //   type: TabTypes.documento,
        //   imprimir: false,
        //   tabKey: action.payload.key,
        //   info: {
        //     claveAcceso: '',
        //     numero:
        //       action.payload.templateMode && action.payload.venta
        //         ? action.payload.venta.comprobante
        //         : 0,
        //   },
        //   buttons: InvoiceButtons,
        //   templateMode: action.payload.templateMode,
        // }
        // state.tabs[action.payload.key] = tab
        // state.current = action.payload.key
      } else {
        // const tabKey = existeTab(state.tabs, action.payload.venta.comprobante)
        // if (tabKey) {
        //   state.current = tabKey
        // } else {
        //   const tab: TabState<EmpresaListado> = {
        //     tabKey: action.payload.key,
        //     id: action.payload.venta.comprobante,
        //     tittle: `${action.payload.venta.establecimiento}-${action.payload.venta.puntoEmision}-${action.payload.venta.numero}`,
        //     canClose: true,
        //     editStatus: StatesEdition.save,
        //     type: TabTypes.documento,
        //     info: {
        //       claveAcceso: action.payload.venta.claveAcceso,
        //       numero: action.payload.venta.comprobante,
        //       info: action.payload.venta,
        //     },
        //     globalButtonClick: ButtonTypes.none,
        //     imprimir: false,
        //     buttons: InvoiceButtons,
        //   }
        //   state.tabs[action.payload.key] = tab
        //   state.current = action.payload.key
        // }
      }
    },
    changeEditStatus(
      state,
      action: PayloadAction<{
        tabKey: string
        estado: StatesEdition
        info?: EmpresaListado
        buttons: CustomButtons
      }>,
    ) {
      const { estado, tabKey, info, buttons } = action.payload
      state.tabs[tabKey].editStatus = estado
      state.tabs[tabKey].currentExecutingAction = undefined
      state.tabs[tabKey].buttons = buttons
      // if (info) {
      //   state.tabs[tabKey].info = {
      //     claveAcceso: info.claveAcceso,
      //     numero: info.comprobante,
      //     info: info ?? null,
      //   }
      //   state.tabs[tabKey].id = info.comprobante
      //   state.tabs[
      //     action.payload.tabKey
      //   ].tittle = `${info.establecimiento}-${info.puntoEmision}-${info.numero}`
      // }
    },
    buttonClick(
      state,
      action: PayloadAction<{ tabKey: string; button: ButtonTypes }>,
    ) {
      state.tabs[action.payload.tabKey].globalButtonClick =
        action.payload.button
    },
    clearButtonClick(state, action: PayloadAction<string>) {
      if (Object.prototype.hasOwnProperty.call(state.tabs, action.payload)) {
        state.tabs[action.payload].globalButtonClick = ButtonTypes.none
      }
    },
    changeCurrentTab(state, action: PayloadAction<string>) {
      if (Object.prototype.hasOwnProperty.call(state.tabs, action.payload)) {
        state.current = action.payload
      }
    },
    setTabInfoAsInactive(state, action: PayloadAction<string>) {
      const info = state.tabs[action.payload].info
      // if (info !== undefined) {
      //   if (info.info) {
      //     info.info.estado = 'ANULADO'
      //     info.info.estadoElectronico = 'ANULADO'
      //   }
      //   state.tabs[action.payload].info = info
      // }
    },
    setButtons(
      state,
      action: PayloadAction<{ tabKey: string; buttons: CustomButtons }>,
    ) {
      const { buttons, tabKey } = action.payload
      state.tabs[tabKey].buttons = buttons
      state.tabs[tabKey].currentExecutingAction = undefined
    },
    setCurrentExecutingAction(
      state,
      action: PayloadAction<{
        tabKey: string
        buttonType: ButtonTypes | undefined
      }>,
    ) {
      const { buttonType, tabKey } = action.payload
      state.tabs[tabKey].currentExecutingAction = buttonType
    },
  },
})

export const selectTabsventas = (state: RootState) => {
  return state.ventas.ventas.tabs
}
export const {
  // ventasCloseTab: closeTab,
  openTab,
  changeCurrentTab,
  changeEditStatus,
  clearButtonClick,
  buttonClick,
  setTabInfoAsInactive,
  setButtons,
  setCurrentExecutingAction,
} = tabsConfEmpresaSlice.actions
export const tabsReducer = tabsConfEmpresaSlice.reducer
