import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  Button,
  NumberBox,
  Popup,
  TextBox,
  ValidationGroup,
  ValidationSummary,
  Validator,
} from 'devextreme-react'
import './confirmarAccionMsj.css'
import { ValueChangedEvent as TexBoxEvent } from 'devextreme/ui/text_box'
import { ValueChangedEvent as NumberBoxEvent } from 'devextreme/ui/number_box'
import { RequiredRule } from 'devextreme-react/data-grid'

/**
 *Componente que muestra un popup de confirmación con un mensaje y botones de aceptar y cancelar.
 * @component
 * @property {string} title - Título a mostar en el mensaje de confirmación.
 * @property {boolean} isVisible - Indica si el popup de confirmación está visible.
 * @property {function} handleConfirm - Función que se llama al confirmar la acción.
 * @property {function} handleCancel - Función que se llama al cancelar la acción.
 * @property {string} message - Mensaje a mostrar en el popup de confirmación.
 * @property {'confirm' | 'newTab' | 'inputInfo'} typeMessage - Tipo de mensaje que se va a mostrar en el popup.
 * @property {'string' | 'number' | null} typeInputInfo - Tipo de dato que va a manejar el input del pouup.
 * @returns {Component} - Devuelve el componente <Popup/> de la libreria de devextreme-react.
 * @notes typeInputInfo debe ser 'null' cuando typeMessage es igual a 'confirm' | 'newTab'
 */

interface ConfirmAction {
  title: string
  isVisible: boolean
  // eslint-disable-next-line no-unused-vars
  handleConfirm: (value?: string | number) => void
  handleCancel: () => void
  message: string
  typeMessage?: 'confirm' | 'newTab' | 'inputInfo'
  typeInputInfo?: null | 'string' | 'number'
}

const ConfirmarAccionMsj: FunctionComponent<ConfirmAction> = (props) => {
  const {
    title,
    isVisible,
    handleConfirm,
    handleCancel,
    message,
    typeMessage = 'confirm',
    typeInputInfo = null,
  } = props

  const widthMessage: number = typeMessage === 'inputInfo' ? 400 : 350
  const heightMessage: number = typeMessage === 'inputInfo' ? 200 : 150

  const [width] = useState<number>(
    window.innerWidth < widthMessage ? window.innerWidth : widthMessage,
  )
  const [height] = useState<number>(
    window.innerWidth < heightMessage ? window.innerWidth : heightMessage,
  )
  const [textBoxValue, setTextBoxValue] = useState<string>(null)
  const [numberBoxValue, setNumberBoxValue] = useState<number>(null)
  const textBoxRef = useRef<any>(null)
  const numberBoxRef = useRef<any>(null)
  const validationInput = useRef<any>(null)

  useEffect(() => {
    if (isVisible && typeMessage === 'inputInfo') {
      setTextBoxValue(null)
      setNumberBoxValue(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible])

  const handleConfirmButton = useCallback(() => {
    if (typeMessage === 'confirm' || typeMessage === 'newTab') handleConfirm()
    else {
      const validationInputResult = validationInput.current.instance.validate()
      if (validationInputResult.isValid)
        handleConfirm(
          typeInputInfo === 'string' ? textBoxValue : numberBoxValue,
        )
    }
  }, [handleConfirm, numberBoxValue, textBoxValue, typeInputInfo, typeMessage])

  return (
    <Popup
      visible={isVisible}
      onHiding={handleCancel}
      dragEnabled={true}
      hideOnOutsideClick={false}
      showCloseButton={true}
      width={width}
      height={height}
      title={title}
      className="popup-container-confirm-action"
    >
      <div className="popup-content-confirm-action">
        <p className="popup-message-confirm-action">{message}</p>
        {typeMessage === 'inputInfo' && (
          <div className="popup-input-info-confirm-action">
            <ValidationGroup
              key={'valitadationInput'}
              id={'valitadationInput'}
              ref={validationInput}
            >
              {typeInputInfo === 'string' && (
                <TextBox
                  ref={textBoxRef}
                  inputAttr={{ autocomplete: 'nope' }}
                  value={textBoxValue}
                  onValueChanged={(evt: TexBoxEvent) => {
                    if (evt.event) setTextBoxValue(evt.value)
                  }}
                  showClearButton={true}
                >
                  <Validator>
                    <RequiredRule message="Este campo es requerido." />
                  </Validator>
                </TextBox>
              )}
              {typeInputInfo === 'number' && (
                <NumberBox
                  ref={numberBoxRef}
                  inputAttr={{ autocomplete: 'nope' }}
                  value={numberBoxValue}
                  onValueChanged={(evt: NumberBoxEvent) => {
                    if (evt.event) setNumberBoxValue(evt.value)
                  }}
                  showClearButton={true}
                >
                  <Validator>
                    <RequiredRule message="Este campo es requerido." />
                  </Validator>
                </NumberBox>
              )}
              <ValidationSummary />
            </ValidationGroup>
          </div>
        )}
        <div className="popup-buttons-confirm-action">
          <Button
            text={typeMessage === 'newTab' ? 'Pestaña Actual' : 'Aceptar'}
            type="success"
            onClick={
              typeMessage === 'newTab' ? handleCancel : handleConfirmButton
            }
          />
          <Button
            className="popup-button-confirm-action"
            text={'Nueva Pesataña'}
            type="success"
            onClick={handleConfirmButton}
            visible={typeMessage === 'newTab'}
          />
          <Button
            className="popup-button-confirm-action"
            text="Cancelar"
            type="danger"
            onClick={handleCancel}
          />
        </div>
      </div>
    </Popup>
  )
}

export default ConfirmarAccionMsj
