import { ItemPedido } from "../../../../../../ventas/pages/ordenes/pages/ordenPedido/type/types"


export async function parseDetalleItems(dataDetalle = []) {
  return dataDetalle.map(item => {
    return {
      codigo: item?.codigo ?? 0,
      usuarioCodigo: item?.usuarioCodigo ?? 0,
      impuesto: item?.detalleImpuesto ?? 0,
      codigoUsuario: item?.codigoInterno ?? 0,
      barras: item?.codigoBarras ?? '',
      unidadDescripcion: item?.unidadDescripcion ?? '',
      porcentaje: item?.detallePorcentaje ?? 0,
      descuento: item?.detalleDescuento ?? 0,
      descripcion: item?.descripcion ?? 0,
      cantidad: item?.detalleCantidad ?? 0,
      lotes: 0,
      precio: item?.detallePrecio ?? 0,
      pvd: 0,
      numeroSerie: 0,
      valorDescripcion: 0,
      valor: item?.detalleValor ?? 0,
      fraccion: 0,
    }
  })
}

export async function onparseItem(data: any) {
  const itemEdit: ItemPedido = {
    codigo: data?.codigo ?? 0,
    codigoInterno: data?.codigoUsuario ?? '',
    codigoBarras: data?.barras ?? '',
    descripcion: data?.descripcion ?? '',
    existencia: 0,
    pvdiva: 0,
    pvpiva: 0,
    pvd: data?.pvd ?? 0,
    pvp: data?.precio ?? 0,
    codigoImpuesto: data?.impuesto ?? 0,
    tieneIce: false,
    porcentajeIva: data?.porcentaje ?? 0,
    costo: 0,
    tipoCodigo: 0,
    tipo: 0,
    numeroSerie: data?.numeroSerie ?? 0,
    plantilla: 0,
    freeitem: 0,
    gradoAcoholico: 0,
    capacidad: 0,
    factor: 0,
    jerarquia: '',
    descuentos: 0,
    titadicional: '',
    valadicional: '',
    grupo: '',
    codigoGrupo: 0,
    codigoUnidadVenta: 0,
    tipoDescripcion: '',
    existenciaMin: 0,
    existenciaMax: 0,
    saldoReservas: 0,
    saldoFecha: 0,
    lotes: data?.lotes ?? '',
    fraccion: data?.fraccion ?? 0,
    unidadDescripcion: data?.unidadDescripcion ?? '',
    unidadIce: 0,
    impuesto: 0,
    impuestoValor: 0,
  }

  return itemEdit
}