import React from 'react'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput'
import { Button, DataGrid, RadioGroup, TextBox } from 'devextreme-react'
import { Column } from 'devextreme-react/data-grid'
import { NominaDatosEdicion, SeleccionadoDescuento } from '../../types/types'
import { TabState } from '../../../../../../store/genericTabTypes'
import ModalCuentas from '../../../../../contabilidad/pages/planCuentas/modalCuentas'
import { consoleService } from '../../../../../../services/console.service'
import { getIndexProvider } from '../../../../../shared/helpers/funciones'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/store'
import { setDatosEdicionDescuento, setSeleccionadoDescuento } from '../../store/nominaReducer'


interface ITabsDescuentosoProps extends React.PropsWithChildren {
  tab: TabState<NominaDatosEdicion>
  tabId: string
}


export const descuentosGroup = [
  { value: 0, label: 'Descuentos en Nomina' },
  { value: 1, label: 'Una Cuenta General' },
]

const Descuentos: React.FunctionComponent<ITabsDescuentosoProps> = (props) => {
  const { tabId, tab } = props
  const dispatch = useDispatch()
  const descuentoState = useSelector((state: RootState) => state.nomina.organico.nomina[tabId].descuentos)
  const seleccionado = useSelector((state: RootState) => state.nomina.organico.nomina[tabId].descuentos.seleccionadoDescuento)

  const [showModalPlanCuentas, setShowModalPlanCuentas] =
    React.useState<boolean>(false)

  const onChangeValue = React.useCallback((data, key: string) => {

    dispatch(setDatosEdicionDescuento({
      key: tabId,
      data: {
        ...descuentoState,
        [key]: data
      }
    }))
  }, [dispatch, descuentoState, tabId])


  const onChangeNull = React.useCallback((key: string) => {
    dispatch(setDatosEdicionDescuento({
      key: tabId,
      data: {
        ...descuentoState,
        [key]: ''
      }
    }))
  }, [dispatch, descuentoState, tabId])


  const onCargarCuentas = React.useCallback(async (account: any) => {
    const temp = { ...descuentoState }
    if (descuentoState.radioOption === descuentosGroup[0]) {
      const descuentos = descuentoState?.descuentoNomina.slice(0)
      const index = await getIndexProvider(descuentos, 'item', seleccionado?.item)
      if (index > -1) {
        const dataTemp: SeleccionadoDescuento = {
          item: seleccionado?.item ?? 0,
          descripcion: seleccionado?.descripcion ?? '',
          transaccion: seleccionado?.transaccion ?? '',
          tipo: seleccionado?.tipo ?? 0,
          codigo: account?.codigo ?? 0,
          planNumero: account?.numero ?? '',
          planDescripcion: account?.descripcion ?? '',
          enUso: seleccionado?.enUso ?? 0,
        }
        descuentos[index] = { ...dataTemp }
        temp.descuentoNomina = descuentos
      }
    } else {
      temp.numeroDescuento = account?.numero
      temp.descripcionDescuento = account?.descripcion
      temp.codigoDescuento = account?.codigo
    }
    consoleService.log(temp, 'temp')
    dispatch(setDatosEdicionDescuento({
      key: tabId,
      data: { ...temp }
    }))
  }, [descuentoState, dispatch, seleccionado, tabId])

  const onSelectedChanged = React.useCallback(
    ({ selectedRowsData }) => {
      consoleService.log(selectedRowsData[0], 'selectedRowsData- descuento')
      if (selectedRowsData[0] && (selectedRowsData[0]?.codigo || selectedRowsData[0]?.item)) {
        dispatch(
          setSeleccionadoDescuento({
            key: tabId,
            data: { ...selectedRowsData[0] }
          }),
        )
      }
    },
    [dispatch, tabId],
  )

  return (
    <>
      {showModalPlanCuentas && <ModalCuentas
        show={showModalPlanCuentas}
        onClose={() => setShowModalPlanCuentas(false)}
        cargarCuentas={(account) => {
          consoleService.log(account)
          onCargarCuentas(account)
          setShowModalPlanCuentas(false)
        }}
      />}
      <RowContainer>
        <CustomCol md="12">
          <Labeled label="">
            <RadioGroup
              name="generos"
              layout="horizontal"
              displayExpr="label"
              dataSource={descuentosGroup}
              value={descuentoState?.radioOption ?? null}
              onValueChange={(e) => onChangeValue(e, 'radioOption')}
            />
          </Labeled>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="6">
          <RowContainer>
            <Labeled label="">
              <RowContainer>
                <CustomCol md="12">
                  <div
                    style={{
                      display: 'flex',
                      gap: '2px',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <Button
                      id="btnVerCuentas"
                      className="me-1"
                      stylingMode="contained"
                      type="default"
                      disabled={seleccionado === null || descuentoState.radioOption?.value !== 0}
                      onClick={() => setShowModalPlanCuentas(true)}
                    >
                      {'Ver Cuentas'}
                    </Button>
                    <Button
                      id="btnRemove"
                      stylingMode="contained"
                      disabled={seleccionado === null || descuentoState.radioOption?.value !== 0}
                      type="danger"
                      onClick={() => { }}
                    >
                      {'X'}
                    </Button>
                  </div>
                </CustomCol>
              </RowContainer>
            </Labeled>
          </RowContainer>
          <RowContainer>
            <CustomCol md="12">
              <DataGrid
                id="tablaDescuentos"
                // ref={tablaRefPager}
                dataSource={descuentoState?.descuentoNomina ?? []}
                selection={{ mode: 'single' }}
                hoverStateEnabled={true}
                showBorders={true}
                showRowLines={true}
                focusedRowEnabled={true}
                disabled={descuentoState.radioOption?.value !== 0}
                onSelectionChanged={onSelectedChanged}
                repaintChangesOnly={true}
                highlightChanges={true}
                keyExpr="item"
                key="item"
                focusedRowKey="item"
                showColumnLines={true}
                className={'m-2 p-2'}
                remoteOperations={true}
                loadPanel={{
                  enabled: true,
                  height: 90,
                  indicatorSrc: '',
                  shading: false,
                  shadingColor: '',
                  showIndicator: true,
                  showPane: true,
                  text: 'Cargando...',
                  width: 200,
                }}
              >
                <Column dataField="descripcion" caption="Descuento" width="100px" />
                <Column dataField="planNumero" caption="Nro. Cuenta" width="100px" />
                <Column dataField="planDescripcion" caption="Cuenta" width="100px" />
              </DataGrid>
            </CustomCol>
          </RowContainer>
        </CustomCol>
        <CustomCol xs="12" md="6">
          <Labeled label="">
            <RowContainer>
              <CustomCol md="12">
                <div
                  style={{
                    display: 'flex',
                    gap: '2px',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <TextBox
                    value={descuentoState?.numeroDescuento ?? ''}
                    readOnly
                    showClearButton={true}
                    width="30%"
                    inputAttr={{ autocomplete: 'nope' }}
                  />
                  <TextBox
                    value={descuentoState?.descripcionDescuento ?? ''}
                    readOnly
                    showClearButton={true}
                    width="40%"
                    inputAttr={{ autocomplete: 'nope' }}
                  />
                  <Button
                    id="btnVerCuentas"
                    className="me-1"
                    stylingMode="contained"
                    type="default"
                    onClick={() => setShowModalPlanCuentas(true)}
                    disabled={descuentoState.radioOption?.value !== 1}
                  >
                    {'Ver Cuentas'}
                  </Button>
                  <Button
                    id="btnRemove"
                    stylingMode="contained"
                    disabled={descuentoState.radioOption?.value !== 1}
                    type="danger"
                    onClick={() => {
                      onChangeNull('numeroDescuento')
                      onChangeNull('descripcionDescuento')
                    }}
                  >
                    {'X'}
                  </Button>
                </div>
              </CustomCol>
            </RowContainer>
          </Labeled>
        </CustomCol>
      </RowContainer>
    </>
  )
}

export default Descuentos