import { CCardBody, CCardHeader, CCollapse, CLink, CTooltip } from '@coreui/react-pro'
import React from 'react'
import { changeFilter, changeFilterBackup, setCollapsed, setImport } from '../../store/searchReducer'
import CIcon from '@coreui/icons-react'
import { cilChevronBottom, cilChevronTop, cilInfo } from '@coreui/icons'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/store'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput'
import { DateBox, RadioGroup, TextBox } from 'devextreme-react'
import PaisesLookUp from '../../../../../componentes/paisesLookUp'
import TipoEstadoLookUp from '../../../../../componentes/tipoEstado/'
import TipoContratoLookup from '../../../../../componentes/tipoContratoLookup'
import JornadaLookUp from '../../../../../componentes/jornadaLookUp'
import { CargoDepartamento } from '../../../../../componentes/cargosDepartamentosLookup/types/types'
import CargosDepartamentosLookup from '../../../../../componentes/cargosDepartamentosLookup'
import { tipoContratoService } from '../../../../../componentes/tipoContratoLookup/services/tipoContrato.service'
import { tipoEstadoService } from '../../../../../componentes/tipoEstado/service/tipoEstado.service'
import { jornadaService } from '../../../../../componentes/jornadaLookUp/services/joranda.services'
import { payrollDepartamentServices } from '../../../../../componentes/cargosDepartamentosLookup/services/cargosDepartamentos.services'
import { localidadesService } from '../../../../../../services/localidades.service'
import { DateUtils } from '../../../../../../helpers/dateUtils'
import { ContratosDatosEdicion, FiltroBusquedaContratos } from '../../types/types'
import { TabState } from '../../../../../../store/genericTabTypes'
import Importar from '../../../../../clientes/pages/docsCtasXCobrar/components/impportarExcel/importar'
import { ToastTypes } from '../../../../../../store/types'
import { addToast } from '../../../../../../store/toasterReducer'

export const personalGruposContratos = [
  { value: 0, label: 'Todos' },
  { value: 1, label: 'Administrativo' },
  { value: 2, label: 'Obrero' },
  { value: 3, label: 'Gerente' },
]

interface IFiltrosBusquedaProps extends React.PropsWithChildren {
  tab: TabState<ContratosDatosEdicion>
  tabId: string
  onEnter: () => void
}

const FiltroBusqueda: React.FunctionComponent<IFiltrosBusquedaProps> = (props) => {
  const { tab, tabId, onEnter } = props
  const dispatch = useDispatch()
  const collapsed = useSelector((state: RootState) => state.nomina.contratos.search.colapsado)
  const searchFilter = useSelector((state: RootState) => state.nomina.contratos.search.filtro)
  const showImport = useSelector((state: RootState) => state.nomina.contratos.search.import)
  const modulo = useSelector((state: RootState) => state.nomina.contratos.config.aplicacion)
  const activePeriod = useSelector((state: RootState) => state.shared.providers.periodoContable)
  const dateInput = 'dd/MM/yyyy'
  const dateOutput = 'yyyy-MM-dd'


  const setToast = React.useCallback(
    (mensaje: string, tipo: ToastTypes) => {
      dispatch(
        addToast({
          title: 'Nómina - Contratos',
          content: mensaje,
          type: tipo,
        }),
      )
    },
    [dispatch],
  )


  const onInitFilter = React.useCallback(async () => {
    const data = { ...searchFilter }
    if (data?.initital) {
      const tipoContrato = await tipoContratoService.getTipoContrato('Elija un contrato')
      const tipoEstado = await tipoEstadoService.getTipoEstado('Elija un estado')
      const tipoJornada = await jornadaService.getJornadaLabolar('Elija una opción')
      const departamento = await payrollDepartamentServices.getPyarollsList()
      const dataPais = await localidadesService.getDpPaises("Elija una opción");

      if (tipoContrato?.auto && tipoContrato?.error === false) {
        data.tipoContrato = tipoContrato?.auto[0]
      }
      if (tipoEstado?.auto && tipoEstado?.error === false) {
        data.procesos = tipoEstado?.auto[0]
      }
      if (tipoJornada?.auto && tipoJornada?.error === false) {
        data.jornada = tipoJornada?.auto[0]
      }
      if (departamento?.auto && departamento?.error === false) {
        data.departamento = departamento?.auto[0]
      }
      if (dataPais?.length > 0) {
        data.pais = {
          codigo: dataPais[0]?.codigo ?? 0,
          nombre: dataPais[0]?.nombre ?? '',
          uaf: dataPais[0]?.codigoUaf ?? ''
        }
      }
      data.tipoPersonal = personalGruposContratos[0]
      data.desde = activePeriod?.length > 0 ? DateUtils.format(activePeriod[0]?.finicio, dateOutput, dateInput) : DateUtils.getCurrentDateAsString()
      data.hasta = activePeriod?.length > 0 ? DateUtils.format(activePeriod[0]?.fcierre, dateOutput, dateInput) : DateUtils.getCurrentDateAsString()
      data.initital = false
      dispatch(changeFilter({
        ...data
      }))
      dispatch(changeFilterBackup({
        ...data
      }))
    }
  }, [dispatch, searchFilter, activePeriod])

  const onGetDatFile = React.useCallback((data: any) => {
    if (data !== null && data?.error === true) {
      setToast(data['message'], ToastTypes.Danger)
    }
  }, [setToast])


  const onChangeValue = React.useCallback((data, key: string) => {
    dispatch(changeFilter({
      ...searchFilter,
      [key]: data
    }))
  }, [dispatch, searchFilter])

  React.useEffect(() => {
    onInitFilter()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <CCardHeader
        onClick={() => dispatch(setCollapsed(!collapsed))}
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <strong> {collapsed ? '' : 'Filtro de Búsqueda'} </strong>
        <CLink className="card-header-action">
          <CIcon icon={collapsed ? cilChevronBottom : cilChevronTop} />
        </CLink>
      </CCardHeader>
      <CCollapse visible={collapsed}>
        <CCardBody>
          <RowContainer>
            <CustomCol xs="12" md={showImport ? '7' : '12'}>
              <RowContainer>
                <CustomCol xs="12" md="6">
                  <Labeled label='Empleado:'>
                    <div
                      style={{
                        display: 'flex',
                        gap: '2px',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <TextBox
                        value={searchFilter?.empleado ?? ''}
                        onValueChange={(data) => onChangeValue(data, 'empleado')}
                        onEnterKey={onEnter}
                      />
                      <CTooltip
                        key={'tool-more-auth-side'}
                        placement="top"
                        content={
                          'Nombre, apellido o identificacion.'
                        }
                      >
                        <div className="card-header-actions">
                          <CLink className="card-header-action m-0">
                            <CIcon icon={cilInfo} className="ms-1 me-1" />
                          </CLink>
                        </div>
                      </CTooltip>
                    </div>
                  </Labeled>
                </CustomCol>
              </RowContainer>
              <RowContainer>
                <CustomCol xs="12" md="4">
                  <Labeled label='Tipo de Contrato:'>
                    <TipoContratoLookup
                      onChanged={(data) => {
                        onChangeValue(data, 'tipoContrato')
                      }}
                      selected={searchFilter?.tipoContrato ?? null}
                    />
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="4">
                  <Labeled label='Proceso:'>
                    <TipoEstadoLookUp
                      onChanged={(e) => onChangeValue(e, 'procesos')}
                      selected={searchFilter?.procesos ?? null}
                      provider={[]}
                    />
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="4">
                  <Labeled label='Jornada:'>
                    <JornadaLookUp
                      onChanged={(data) => {
                        onChangeValue(data, 'jornada')
                      }}
                      selected={searchFilter?.jornada ?? null}
                    />
                  </Labeled>
                </CustomCol>
              </RowContainer>
              <RowContainer>
                <CustomCol xs="12" md="4">
                  <Labeled label='Departamento:'>
                    <CargosDepartamentosLookup
                      selected={searchFilter?.departamento ?? null}
                      onChanged={(departament: CargoDepartamento) => onChangeValue(departament, 'departamento')}
                    />
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="4">
                  <Labeled label='Pais:'>
                    <PaisesLookUp
                      onChanged={(data) => onChangeValue(data, 'pais')}
                      selected={searchFilter?.pais ?? null}
                      onChangedOptions={() => { }}
                      defecto={false}
                    />
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="4">
                  <Labeled label='Tipo de Personal:'>
                    <RadioGroup
                      name="generos"
                      layout="horizontal"
                      displayExpr="label"
                      dataSource={personalGruposContratos}
                      value={searchFilter?.tipoPersonal ?? null}
                      onValueChange={(e) => onChangeValue(e, 'tipoPersonal')}
                    />
                  </Labeled>
                </CustomCol>
              </RowContainer>
              <RowContainer>
                <CustomCol xs="12" md="6">
                  <Labeled label='Fechas:'>
                    <div
                      style={{
                        display: 'flex',
                        gap: '2px',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <DateBox value={searchFilter?.desde ?? ''} onEnterKey={onEnter} onValueChange={(data) => onChangeValue(data, 'desde')} />
                      <DateBox value={searchFilter?.hasta ?? ''} onEnterKey={onEnter} onValueChange={(data) => onChangeValue(data, 'hasta')} />
                    </div>
                  </Labeled>
                </CustomCol>
              </RowContainer>
            </CustomCol>
            {showImport && (
              <CustomCol xs="12" md="5">
                <Labeled label=''>
                  <Importar
                    tab={tab}
                    tabId={tabId}
                    openImport={(open) => dispatch(setImport(false))}
                    moduleCode={modulo}
                    setData={(data) => onGetDatFile(data)}
                  />
                </Labeled>
              </CustomCol>
            )}
          </RowContainer>
        </CCardBody>
      </CCollapse>
    </>
  )
}

export default React.memo(FiltroBusqueda)

export const initialFilter: FiltroBusquedaContratos = {
  empleado: '',
  tipoContrato: null,
  procesos: null,
  jornada: null,
  departamento: null,
  pais: null,
  tipoPersonal: null,
  desde: '',
  hasta: '',
  initital: true,
}