import { RequestHelper } from '../../../../../../../helpers/requestHelper'
import { SaveAplication } from '../../../types/types'

export const aplicationsModalServices = {
  getAplications,
  deleteAplication,
  saveAplication,
}

async function getAplications(name: string, belongs: number = 0): Promise<any> {
  try {
    const res = await RequestHelper.getAll<any>(
      'inventario/catalogos',
      'aplicaciones/getAll',
      '',
      { pertenece: belongs, descripcion: name },
    )
    return res
  } catch (error) {
    return error
  }
}

async function deleteAplication(code: number): Promise<any> {
  try {
    const res = await RequestHelper.deleteRequestAll<any>(
      'inventario/catalogos',
      'aplicaciones/delete',
      '',
      { codigo: code },
    )
    return res
  } catch (error) {
    return error
  }
}

async function saveAplication(aplication: SaveAplication): Promise<any> {
  try {
    const res = await RequestHelper.postAll<any>(
      'inventario/catalogos',
      'aplicaciones/save',
      { ...aplication },
    )
    return res
  } catch (error) {
    return error
  }
}
