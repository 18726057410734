import React from 'react';
import SelectBox from 'devextreme-react/select-box';
import { utilidades } from '../../../helpers/utilidades';
import { InventarioService } from '../../inventario/services/inventario.service';
import { AtributosOption } from './store/type';

interface AtributosLookUpProps extends React.PropsWithChildren {
  selected: AtributosOption | null,
  onChanged: (newValue: AtributosOption | null) => void,
  onChangedOptions: (newValue: Array<AtributosOption> | []) => void,
  disabled?: boolean,
}

const AtributosLookUp: React.FC<AtributosLookUpProps> = (props) => {
  const { selected, onChanged, disabled } = props;
  const selectRef = React.useRef<any>();
  const [options, setOptions] = React.useState<Array<AtributosOption> | []>([]);

  const loadDatos = React.useCallback(async () => {
    const data = await InventarioService.getAtributosWeb()
    if (data.error === false) {
      let opt = [];
      if (data.auto.length > 0) {
        opt = data.auto.map(x => {
          const item: AtributosOption = {
            codigo: parseInt(x.id) ?? 0,
            nombre: utilidades.capText(x?.name) ?? '',
          };
          return item;
        });
      }
      setOptions(opt)
      const selectedIndex = opt.findIndex((select) => {
        return select.codigo === selected?.codigo
      })
      onChanged(opt[selectedIndex] ?? opt[0])
    }

  }, [onChanged, selected]);

  const getItemDisplayExpr = React.useCallback((item: AtributosOption) => {
    return item ? `${item.nombre}` : '';
  }, []);

  const onLookUpValueChanged = React.useCallback((evt: any) => {
    if (evt.event !== undefined) {
      onChanged(evt.value);
    }
  }, [onChanged]);

  const onSelectBoxFocusIn = React.useCallback((e) => {
    e.element.querySelector("input.dx-texteditor-input").select();
  }, [])

  React.useEffect(() => {
    loadDatos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <SelectBox
      id='estadoSelect'
      ref={selectRef}
      items={options ?? []}
      searchEnabled
      displayExpr={getItemDisplayExpr}
      searchExpr={['nombre']}
      searchTimeout={500}
      onEnterKey={(e) => { console.log(e) }}
      value={selected}
      onValueChanged={onLookUpValueChanged}
      disabled={disabled}
      onFocusIn={onSelectBoxFocusIn}
    >
      {props.children}
    </SelectBox>
  )

}
export default React.memo(AtributosLookUp);
