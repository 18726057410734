import React from 'react'
import {
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from '@coreui/react-pro'
import { getIncrementZIndex } from '../../../hooks/useLastZIndex'
// refactor elimina warnings
type ModalformProps = {
  childrenBody?: React.ReactElement
  childrenFooter?: React.ReactElement
  backdrop?: boolean | 'static' // muestra color de fondo
  closeOnBackdrop?: boolean // permite que se cierre el modal desde el fondo
  size?: 'sm' | 'lg' | 'xl'
  show?: boolean
  enableBackdroop?: boolean
  headerTitle?: string
  name?: string
  onClose: () => void
  centered?: boolean
  closeHeader?: boolean
  className?: string
  style?: any
}

const Modalform = ({
  childrenBody,
  childrenFooter,
  backdrop = true,
  size = 'sm',
  show = false,
  headerTitle = 'Acatha',
  name = '',
  onClose,
  centered = false,
  closeHeader = true,
  className,
  enableBackdroop = true,
  style,
}: ModalformProps) => {
  const agregarEstiloPorClase = (clase, estilo) => {
    const elementos = document.getElementsByClassName(clase)
    for (let i = 0; i < elementos.length; i++) {
      elementos[i]['style'].cssText += estilo
    }
  }

  const addClassFiltroBody = () => {
    document?.querySelector('#root')?.classList.add('filter_screen_lock_acatha')
    const styleAdd: string = `${getIncrementZIndex()} ${'!important'}`
    agregarEstiloPorClase('modalScreenLock', 'z-index: ' + styleAdd)
  }

  // React.useEffect(() => {
  //   if (show && enableBackdroop) {
  //     addClassFiltroBody()
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [show])

  return (
    <>
      <CModal
        visible={show}
        onClose={() => onClose()}
        size={size}
        backdrop={backdrop === true ? 'static' : false}
        id={name}
        className={className}
        key={name}
        alignment={centered === true ? 'center' : 'center'}
        {...(style && { style })}
      >
        <CModalHeader closeButton={closeHeader} id="modalHeader">
          <CModalTitle>{headerTitle}</CModalTitle>
        </CModalHeader>

        <CModalBody>{childrenBody}</CModalBody>
        {childrenFooter && <CModalFooter>{childrenFooter}</CModalFooter>}
      </CModal>
    </>
  )
}

export default React.memo(Modalform)
