import { RequestHelper } from "../../../../helpers/requestHelper";

export const lotesService = { getClientes, getStock, getStockBarras };

async function getClientes(find): Promise<any> {
  try {
    const data: any = {
      ...find
    };
    const res = await RequestHelper.getAll<any>('clientes', 'getAllCort', '', data);
    return res;
  } catch (error) {
    return error;
  }
}


async function getStock(find): Promise<any> {
  try {
    const data: any = {
      ...find
    };
    const res = await RequestHelper.getAll<any>('inventario', 'lots/getWithStock', '', data);
    return res;
  } catch (error) {
    return error;
  }
}

async function getStockBarras(find): Promise<any> {
  try {
    const data: any = {
      ...find
    };
    const res = await RequestHelper.getAll<any>('inventario', 'lots/getWithStockBarras', '', data);
    return res;
  } catch (error) {
    return error;
  }
}
