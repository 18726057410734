import { DateUtils, formatoFechasApi } from '../../../../helpers/dateUtils'
import { RequestHelper } from '../../../../helpers/requestHelper'
import { utilidades } from '../../../../helpers/utilidades'
import { SesionService } from '../../../../services/sesion.service'
import { ESubtipo } from '../../../../store/enum/subtypes'
import { Subtipo } from '../../gruposUnidadesLookUp/types/types'

export const typesAssociatesServices = { getTypesAssociates }

async function getTypesAssociates(): Promise<any> {
  try {
    const data: any = {
      mensaje: 'Selecciona uno',
      tipoCodigo: ESubtipo.AsociadosMovimientos,
    }
    const sesion = SesionService.getCurrentSesion()
    const keysToCache: any = {
      ...data,
      dateKey: DateUtils.getCurrentDateAsString(formatoFechasApi),
      empresa: sesion?.empresa?.codigo,
    }
    const cacheKey = 'tiposAsociadosMovimientos' + JSON.stringify(keysToCache)
    const cached = sessionStorage.getItem(cacheKey)
    if (cached) {
      return { error: false, auto: JSON.parse(cached) }
    } else {
      const typesAssociatesData = await RequestHelper.getAll<any>(
        'subtypes',
        'cboxLoad',
        '',
        data,
      )
      const typesAssociates: Array<Subtipo> = []
      if (typesAssociatesData?.auto && typesAssociatesData?.auto.length > 0) {
        for (const typesAssociate of typesAssociatesData.auto) {
          typesAssociates.push({
            codigo: typesAssociate?.codigo ?? -1,
            valor: utilidades.capText(typesAssociate?.valor) ?? '',
            tipoCodigo: typesAssociate?.tipoCodigo ?? '',
            grupo: typesAssociate?.grupo ?? '',
            orden: typesAssociate?.orden ?? -1,
            estado: typesAssociate?.estado ?? '',
          })
        }
        sessionStorage.setItem(cacheKey, JSON.stringify(typesAssociates))
        return { error: false, auto: typesAssociates }
      } else
        return {
          error: true,
          message: typesAssociatesData.message
            ? typesAssociatesData.message
            : 'Error al obtrner los datos',
        }
    }
  } catch (error) {
    return error
  }
}
