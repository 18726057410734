import React, { useCallback, useEffect, useState } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CTooltip,
} from '@coreui/react-pro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch, useSelector } from 'react-redux'
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import { RootState } from '../../../../../../store/store'
import { changeLoader } from '../../../../../../store/reducers'
import { ToastTypes } from '../../../../../../store/types'
import Modalform from '../../../../../../views/componentes/modalform'
import CustomCol from '../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput'
import { Button, DataGrid, NumberBox, TextBox } from 'devextreme-react'
import { TIPOS_BOTONES } from '../../../../../proveedores/pages/proveedores/types/types'
import ListErrosValidationForm from '../../../../../componentes/listadoErroresValidacionForm'
import { ItemsServices } from '../../services/items.services'
import VisualizaError from '../../../../../ventas/pages/shared/visualizaError/visualizaError'
import { useSetToast } from '../../../../../../hooks/useGlobalHooks'
import { Column, Editing, Pager, Paging } from 'devextreme-react/data-grid'
import { getAllowedPageSizes } from '../../../../../../helpers/Helper'
import MainModalItems from '../../../../../ventas/components/items/busquedaItemsModal/MainModalItems'
import { ItemVenta } from '../../../../../ventas/types/types'
import { EModulosAcatha } from '../../../../../../store/enumsAplication'

const buttons = [
  {
    icon: 'fal fa-save',
    id: 'Guardar',
    text: 'Guardar',
    type: ButtonTypes.save,
  },
  {
    icon: 'fal fa-times',
    id: 'Salir',
    text: 'Salir',
    type: ButtonTypes.times,
  },
]

type ModalAssemblyKitProps = {
  show: boolean
  onClose: () => void
  costo?: number
  precio?: number
}

const ModalArmarKit = (props: ModalAssemblyKitProps) => {
  const dispatch = useDispatch()
  const setToastMessage = useSetToast()

  const decPrec = useSelector((state: RootState) => {
    return state.global.session.empresa.precision
  })
  const selectedItem = useSelector(
    (state: RootState) => state.inventarios.items.search.selectedItem,
  )

  const [acciones] = useState<any>(buttons)
  const [precio, setPrecio] = React.useState<number>(0)
  const [precioReal, setPrecioReal] = React.useState<number>(0)
  const [costo, setCosto] = React.useState<number>(0.0)
  const [totalPartes, settotalPartes] = React.useState<number>(0.0)
  const [codigo, setCodigo] = React.useState<string>('')
  const [precioItem, setPrecioItem] = React.useState<number>(0.0)
  const [descripcion, setDescripcion] = React.useState<string>('')
  const [validationFormErrors, setValidationFormErrors] = React.useState<
    Array<any>
  >([])
  const [showPopupError, setShowPopupError] = React.useState<boolean>(false)
  const [itemsKit, setItemsKit] = React.useState<Array<any>>([])
  const [showModalItems, setShowModalItems] = React.useState<boolean>(false)
  const [rowIndex, setRowIndex] = React.useState<number>(-1)
  const tablaRef = React.useRef<any>()
  const tablaRefPager = React.useRef<any>()

  const playLoader = React.useCallback(
    (message: string) => {
      dispatch(changeLoader({ show: true, mensaje: message }))
    },
    [dispatch],
  )

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoader({ show: false, mensaje: '' }))
  }, [dispatch])

  const closeModal = React.useCallback(() => {
    props.onClose()
  }, [props])

  const onHandleChange = React.useCallback(
    (setState: any) => (event: any) => {
      if (event.event) setState(event.value)
    },
    [],
  )

  const ValidateProcesForm = useCallback(() => {
    const errors: Array<string> = []
    if (itemsKit.length === 0)
      errors.push('Debe agregar por lo menos un ítem para guardar el Kit')
    for (let i = 0; i < itemsKit.length; i++) {
      if (itemsKit[i].cantidad === 0) {
        errors.push('Debe ingresar cantidades mayores a cero')
        break
      }
    }
    return errors
  }, [itemsKit])

  const onHandleSave = React.useCallback(async () => {
    const validationFormResult = ValidateProcesForm()
    if (validationFormResult.length > 0) {
      setValidationFormErrors(validationFormResult)
      setShowPopupError(true)
    } else {
      try {
        playLoader('Guardando . . .')
        const assemblyKit = {
          infoRegistro: {
            item: selectedItem?.itemCodigo ?? 0,
            kits: [...itemsKit],
          },
        }
        const newAssemblyKit = await ItemsServices.assemblyKitItem(assemblyKit)
        stopLoader()
        closeModal()
        if (!newAssemblyKit.error && newAssemblyKit.auto)
          setToastMessage(
            'Guardar Kit',
            newAssemblyKit.message ?? 'Kit guardado correctamente',
            ToastTypes.Success,
          )
        else
          setToastMessage(
            'Guardar Kit',
            newAssemblyKit.message ?? 'Error al guardar kit',
            ToastTypes.Warning,
          )
      } catch (error) {
        stopLoader()
        setToastMessage(
          'Guardar Kit',
          error ?? 'Error al guardar kit',
          ToastTypes.Danger,
        )
      }
    }
  }, [
    ValidateProcesForm,
    closeModal,
    itemsKit,
    playLoader,
    selectedItem,
    setToastMessage,
    stopLoader,
  ])

  const returnAction = React.useCallback(
    (metodo: string) => {
      switch (metodo) {
        case TIPOS_BOTONES.BOTON_GUARDAR:
          onHandleSave()
          break
        case TIPOS_BOTONES.BOTON_SALIR:
          closeModal()
          break
        default:
          break
      }
    },
    [closeModal, onHandleSave],
  )

  const onAddItem = React.useCallback(
    (newItem: ItemVenta) => {
      const indexItemExists = itemsKit.findIndex((item) => {
        return item.partes === newItem.codigo
      })
      if (indexItemExists === -1) {
        const newItemsDataGrid = structuredClone(itemsKit)
        newItemsDataGrid.push({
          partes: newItem.codigo,
          cantidad: 0,
          barras: newItem.codigoBarras,
          descripcion: newItem.descripcion,
          porcentaje: 0,
          suma: 0,
          precio: isNaN(newItem.pvpiva) ? 0 : newItem.pvpiva,
          costo: isNaN(newItem.costo) ? 0 : newItem.costo,
        })
        setItemsKit([...newItemsDataGrid])
        setShowModalItems(false)
      } else
        setToastMessage(
          'Agregar Item  Kit',
          'El Item ya se encuentra registrado en el Kit',
          ToastTypes.Warning,
        )
    },
    [itemsKit, setToastMessage],
  )

  const onRemoveSelectedItem = React.useCallback(() => {
    if (rowIndex >= 0) {
      const newItemsDataGrid = structuredClone(itemsKit)
      newItemsDataGrid.splice(rowIndex, 1)
      setItemsKit([...newItemsDataGrid])
      const totalPartes = newItemsDataGrid.reduce(
        (acc, currentVal) => acc + currentVal.cantidad,
        0,
      )
      const precioTotal = newItemsDataGrid.reduce(
        (acc, currentVal) => acc + currentVal.costo * currentVal.cantidad,
        0,
      )
      setPrecioReal(parseFloat(precioTotal.toFixed(decPrec)))
      settotalPartes(parseFloat(totalPartes.toFixed(2)))
      setCodigo('')
      setPrecioItem(0)
      setDescripcion('')
      setRowIndex(-1)
    } else
      setToastMessage(
        'Eliminar Item',
        'Debe seleccionar un item para eliminarlo',
        ToastTypes.Warning,
      )
  }, [decPrec, itemsKit, rowIndex, setToastMessage])

  const setDetailsItem = useCallback(
    (rowIndex: number) => {
      setCodigo(itemsKit[rowIndex].partes)
      setPrecioItem(itemsKit[rowIndex].precio)
      setDescripcion(itemsKit[rowIndex].descripcion)
    },
    [itemsKit],
  )

  const updateCellValue = useCallback(
    (newData, value, currentRowData, columnToUpdate: string) => {
      if (value && currentRowData) {
        if (value <= 0 && columnToUpdate === 'cantidad') {
          setToastMessage(
            'Actualizar valor cantidad',
            'El valor de la cantidad debe ser mayor que 0',
            ToastTypes.Warning,
          )
          return false
        }
        if (value <= 0 && columnToUpdate === 'suma') {
          setToastMessage(
            'Actualizar valor suma',
            'El valor de la suma debe ser mayor que 0',
            ToastTypes.Warning,
          )
          return false
        }
        newData = structuredClone(itemsKit)
        const indexRowToUpdate = newData.findIndex((item) => {
          return item.partes === currentRowData.partes
        })
        const cantidad = parseFloat(value.toFixed(2))
        if (columnToUpdate === 'cantidad') {
          newData[indexRowToUpdate].cantidad = cantidad
          const precioTotal = newData.reduce(
            (acc, currentVal) => acc + currentVal.costo * currentVal.cantidad,
            0,
          )
          newData.map((item) => {
            if (item.costo > 0)
              item.porcentaje = parseFloat(
                ((item * item.cantidad * 100) / precioTotal).toFixed(2),
              )
            else item.porcentaje = 0
          })
          setItemsKit([...newData])
          const totalPartes = newData.reduce(
            (acc, currentVal) => acc + currentVal.cantidad,
            0,
          )
          settotalPartes(parseFloat(totalPartes.toFixed(2)))
          setPrecioReal(parseFloat(precioTotal.toFixed(decPrec)))
        } else if (columnToUpdate === 'suma') {
          newData[indexRowToUpdate].suma = cantidad
          setItemsKit([...newData])
        }
      }
    },
    [decPrec, itemsKit, setToastMessage],
  )

  useEffect(() => {
    if (props.costo) setCosto(props.costo)
    if (props.precio) setPrecio(props.precio)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const bodyModalForm = () => {
    return (
      <>
        {showModalItems && (
          <MainModalItems
            transaccion="ventas"
            show={showModalItems}
            onCancel={() => setShowModalItems(false)}
            returnItem={(item) => onAddItem(item)}
            action="none"
            modulo={EModulosAcatha.Items}
            tipoItem={{ value: 3, label: 'Kit ' }}
          />
        )}
        {showPopupError && (
          <VisualizaError
            titulo="Error en Armar Kit"
            mensaje={'Error al guardar'}
            content={
              <ListErrosValidationForm
                mainMessage={
                  'Antes de guardar la información revise lo siguiente:'
                }
                errorsList={validationFormErrors}
                color={'danger'}
              />
            }
            onOk={() => setShowPopupError(false)}
          />
        )}
        <Labeled label="DATOS DEL KIT" />
        <CRow>
          <CustomCol xs="12" md="4">
            <Labeled label="Precio:">
              <NumberBox
                value={precio}
                onValueChanged={onHandleChange(setPrecio)}
                min={0}
                max={100}
                width="100%"
              />
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="4">
            <Labeled label="Precio Real:">
              <NumberBox value={precioReal} readOnly={true} />
            </Labeled>
          </CustomCol>
        </CRow>
        <CRow>
          <CustomCol xs="12" md="4">
            <Labeled label="Costo:">
              <NumberBox value={costo} readOnly={true} />
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="4">
            <Labeled label="Total Partes:">
              <NumberBox value={totalPartes} readOnly={true} />
            </Labeled>
          </CustomCol>
        </CRow>
        <Labeled label="DATOS DEL ÍTEM" />
        <CRow>
          <CustomCol xs="12" md="4">
            <Labeled label="Código:">
              <TextBox value={codigo} readOnly={true} />
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="4">
            <Labeled label="Precio:">
              <NumberBox value={precioItem} readOnly={true} />
            </Labeled>
          </CustomCol>
        </CRow>
        <CRow>
          <CustomCol xs="12" md="8">
            <Labeled label="Descripción:">
              <TextBox value={descripcion} readOnly={true} />
            </Labeled>
          </CustomCol>
          <CCol
            style={{
              display: 'flex',
              gap: '2px',
              alignItems: 'center',
              width: '100%',
              justifyContent: 'flex-end',
              marginTop: 'auto',
            }}
          >
            <Button
              id="btnAdd"
              className="me-1"
              icon="add"
              stylingMode="contained"
              type="default"
              onClick={() => setShowModalItems(true)}
            />
            <Button
              id="btnRemove"
              icon="trash"
              stylingMode="contained"
              type="danger"
              onClick={() => onRemoveSelectedItem()}
            />
          </CCol>
        </CRow>
        <DataGrid
          style={{ marginTop: '10px' }}
          ref={tablaRef}
          selection={{ mode: 'single' }}
          className="border"
          dataSource={itemsKit}
          onRowClick={({ rowIndex }) => {
            setRowIndex(rowIndex)
            setDetailsItem(rowIndex)
          }}
          width={'100%'}
          keyExpr="partes"
        >
          <Editing mode="cell" allowUpdating />
          <Paging enabled={true} defaultPageSize={10} />
          <Pager
            ref={tablaRefPager}
            showPageSizeSelector={true}
            allowedPageSizes={getAllowedPageSizes(itemsKit)}
            showNavigationButtons={true}
            showInfo={true}
          />
          <Column dataField="partes" caption="Código" visible={false} />
          <Column
            dataField="cantidad"
            caption="Cantidad"
            width="10%"
            allowEditing={true}
            setCellValue={(newData, value, currentRowData) =>
              updateCellValue(newData, value, currentRowData, 'cantidad')
            }
          />
          <Column
            dataField="barras"
            caption="Barras"
            width="19%"
            allowEditing={false}
          />
          <Column
            dataField="descripcion"
            caption="Descripción"
            width="51%"
            allowEditing={false}
          />
          <Column
            dataField="porcentaje"
            caption="%"
            width="10%"
            allowEditing={false}
          />
          <Column
            dataField="suma"
            caption="Suma"
            width="10%"
            allowEditing={true}
            setCellValue={(newData, value, currentRowData) =>
              updateCellValue(newData, value, currentRowData, 'suma')
            }
          />
        </DataGrid>
      </>
    )
  }

  const renderBody = () => {
    return (
      <>
        <CCard key="cardArmarKit">
          <CCardHeader>
            <CRow>
              <CCol className="ms-0 d-flex align-items-start justify-content-start">
                <div
                  key="menuItems"
                  className="btn-toolbar"
                  role="toolbar"
                  aria-label="Toolbar with button groups"
                  style={{ marginBottom: '5px', paddingTop: '-2rem' }}
                >
                  <div
                    key="menuItemsBotones"
                    className="btn-group-lg btn-group btn-sm me-2"
                    role="group"
                    aria-label="First group"
                  >
                    {acciones.map(function (action: any) {
                      const prefixButton = 'buttonsEditPrices_'
                      const nameButon = action.text
                      const disabled = false
                      return (
                        <CTooltip
                          key={'tool-' + prefixButton + nameButon}
                          placement="top"
                          content={nameButon}
                        >
                          <CButton
                            key={prefixButton + nameButon}
                            name={prefixButton + nameButon}
                            title={action.text}
                            id={prefixButton + nameButon}
                            value={action.text}
                            className="btn btn-secondary sm"
                            variant="ghost"
                            // eslint-disable-next-line no-unused-vars
                            onClick={(ev) => returnAction((ev = action.text))}
                            size="sm"
                            disabled={disabled}
                          >
                            <FontAwesomeIcon
                              size="sm"
                              icon={action.icon ?? faQuestionCircle}
                            />
                          </CButton>
                        </CTooltip>
                      )
                    })}
                  </div>
                </div>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>{bodyModalForm()}</CCardBody>
        </CCard>
      </>
    )
  }

  return (
    <CRow key="rowMain">
      <CCol key="colMain">
        <Modalform
          name="modalAssemblyKit"
          key="modalAssemblyKit"
          headerTitle={'Armar Kit'}
          childrenBody={renderBody()}
          childrenFooter={<></>}
          closeOnBackdrop={false}
          show={props.show}
          onClose={closeModal}
          size="lg"
        />
      </CCol>
    </CRow>
  )
}

export default React.memo(ModalArmarKit)
