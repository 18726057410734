export type TFormaPagoOption = {
  codigo: number
  descripcion: string
  grupo: string
  asignadoSri: string
}

export enum ETipoTransaccion {
  compras = 'C',
  ventas = 'V',
}

export type TFormasPago = {
  codigoFormaPago: number
  descripcionFormaPago: string
  codigoCuenta: number
  transaccionAsignacion: number
  itemAsignacion: number
  cuentaNumero: string
  cuentaDescripcion: string
}
