import React from 'react'
import { PersonalDatosEdicion } from '../../../types/types'
import { DocumentInfo, ToastTypes } from '../../../../../../../store/types'
import { TabState } from '../../../../../../../store/genericTabTypes'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput'
import { Button, FileUploader } from 'devextreme-react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import { setChangeLoaderEdit, setDatosEdicionExtras } from '../../../store/editDataReducer'
import { consoleService } from '../../../../../../../services/console.service'
import { v4 as uuidv4 } from 'uuid'
import { addToast } from '../../../../../../../store/toasterReducer'
import ModalCuentasBancos from '../../../../../../bancos/pages/ccahorros/componentes/modalCuentasBancos'
import { sidebarService } from '../../../../../../../services/sidebar.service'
import { UrlHelper } from '../../../../../../../helpers/urlHelper'

interface INewProps extends React.PropsWithChildren {
  info: DocumentInfo<PersonalDatosEdicion>
  tab: TabState<PersonalDatosEdicion>
  tabId: string
  refForm: any
}


const Extras: React.FunctionComponent<INewProps> = (props) => {


  const { tabId, info } = props
  const dispatch = useDispatch()
  const extrasState = useSelector((state: RootState) => state.nomina.personal.editData[tabId].extras)
  const codModulo = useSelector((state: RootState) => state.nomina.personal.config.aplicacion)
  const empresa = useSelector((state: RootState) => state.global.session.empresa)
  const FILE_SIZE = 1000000;
  const allowExtention = ['.jpg', '.jpeg', '.png'];
  const [showBancos, setShowBancos] = React.useState<boolean>(false)
  const urls = UrlHelper.getUrls();
  const imageLogo = React.useRef<any>();
  const imageFirma = React.useRef<any>();

  const onLoader = React.useCallback((showLoader: boolean, text: string) => {
    dispatch(setChangeLoaderEdit({
      key: tabId,
      data: {
        show: showLoader,
        mensaje: text
      }
    }))
  }, [dispatch, tabId])

  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(
      addToast({
        title: 'Nómina - Personal',
        content: mensaje,
        type: tipo,
      }),
    )
  }, [dispatch])

  const onChangeValue = React.useCallback((data, key: string) => {
    dispatch(setDatosEdicionExtras({
      key: tabId,
      data: {
        ...extrasState,
        [key]: data
      }
    }))
  }, [dispatch, extrasState, tabId])

  const getExtension = (path: string) => {
    const basename: any = path.split(/[\\/]/).pop(),  // extract file name from full path ...
      // (supports `\\` and `/` separators)
      pos = basename.lastIndexOf(".");       // get last position of `.`

    if (basename === "" || pos < 1)            // if file name is empty or ...
      return "";                             //  `.` not found (-1) or comes first (0)

    return basename.slice(pos + 1);            // extract extension ignoring `.`
  }

  const validarFormatoPermitido = React.useCallback((file) => {
    let extention: string = "";
    if (file && file.length > 0) {
      extention = getExtension(file[0].name);
    }
    if (allowExtention.includes("." + extention)) {
      return true;
    } else {
      return false;
    }
  }, [allowExtention])

  const setNameFile = React.useCallback((file, tipo) => {
    const idFoto: string = uuidv4();
    let nameLogo: string = "";
    let extention: string = "";
    if (file && file.length > 0) {
      if (tipo === 'fotoName') {
        extention = getExtension(file[0].name);
        nameLogo = idFoto + "." + extention;
        onChangeValue(nameLogo, tipo)
      } else if (tipo === 'firma') {
        extention = getExtension(file[0].name);
        nameLogo = idFoto + "." + extention;
        onChangeValue(nameLogo, tipo)
      }
    }
  }, [onChangeValue])


  const resetFileUpload = React.useCallback((tipo) => {
    setToast("El formato del archivo no esta permitido.", ToastTypes.Warning);
    if (tipo === "foto") {
      dispatch(setDatosEdicionExtras({
        key: tabId,
        data: {
          ...extrasState,
          fotos: '',
          fotosName: '',
          fotosFile: [],
        }
      }))

    } else {
      dispatch(setDatosEdicionExtras({
        key: tabId,
        data: {
          ...extrasState,
          firma: '',
          firmaName: '',
          firmaFile: [],
        }
      }))

    }
  }, [dispatch, extrasState, setToast, tabId])

  const uploadFile = React.useCallback(async (file: any, type: string, fileName: string, typeValue: string) => {
    consoleService.log({ file, type, fileName })
    const fileUpload = {
      tipo: type,
      archivo: file,
      ruc: empresa?.ruc,
      nombre: fileName,
      password: ""
    }
    onLoader(true, 'Subiendo imagen...')
    try {
      const data = await sidebarService.docEmpresaUplod(fileUpload);
      if (data['error'] === true) {
        setToast(JSON.stringify("Error al subir archivo " + data['message']), ToastTypes.Danger);
      } else if (data['valido'] && data['error'] === false) {
        setToast(data['message'], ToastTypes.Success);
      }
      consoleService.log(data, 'archivo res')
      onChangeValue(fileName, typeValue)
      // imageLogo.current.instance.reset();
    } catch (error) {
      consoleService.error(error);
      setToast(JSON.stringify(error), ToastTypes.Danger)
    }
    onLoader(false, '')
  }, [empresa, setToast, onLoader, onChangeValue]);

  const modalCuentaBancos = () => {
    if (info?.codigo > 0) {
      return (
        <ModalCuentasBancos
          show={showBancos}
          onClose={() => setShowBancos(!showBancos)}
          cargarCuentas={(data) => {
            setShowBancos(!showBancos)
            consoleService.log(data)
          }}
          origen={"modal"}
          codModulo={codModulo}
        />
      )
    } else {
      setToast('No hay un usuario creado', ToastTypes.Warning)
    }
  }

  return (
    <>
      {showBancos && modalCuentaBancos()}
      <RowContainer>
        <CustomCol xs="12" md="5">
          <Labeled label='Cuentas Bancarias:' position='left'>
            <Button
              id="btnAdd"
              className="me-1"
              icon="plus"
              stylingMode="contained"
              type="default"
              onClick={() => setShowBancos(true)}
            />
          </Labeled>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="4">
          <Labeled label="Foto:">
            <div className="selected-item d-flex align-items-center justify-content-center" key={"nameFoto"}>
              {/* `http://127.0.0.1/SolucionEmpresarial/SigNum/isologos/${ extrasState?.fotos ?? '2128-joker.jpg'}` */}
              <img
                key={`${urls.pathLogos}${extrasState?.fotos ?? ''}`}
                id={`${urls.pathLogos}${extrasState?.fotos ?? ''}`}
                src={`${urls.pathLogos}${extrasState?.fotos ?? ''}`}
                className="avatar"
                style={{ maxWidth: "150px" }}
                alt="FOTO"
                onError={() => {
                  onChangeValue("vacio.jpg", 'fotos')
                }}
              >
              </img>
            </div>
            <FileUploader
              ref={imageLogo}
              selectButtonText="Examinar"
              labelText=""
              accept={'.jpg,.jpeg'}
              uploadMode="useForm"
              onValueChanged={(data) => {
                consoleService.log(data, 'archivo')
                if (data.value !== null && data.value !== undefined && data?.value.length > 0) {
                  const allow = validarFormatoPermitido(data.value);
                  if (allow === true) {
                    uploadFile(data?.value[0], 'logo', data?.value[0]?.name, 'fotos')
                  } else {
                    resetFileUpload("fotoName")
                  }
                }
              }}
              maxFileSize={FILE_SIZE}
              allowedFileExtensions={['.jpg', '.jpeg']}
            />
          </Labeled>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="4">
          <Labeled label="Firma:">

            <div className="selected-item d-flex align-items-center justify-content-center" key={"nameFirma"}>

              <img
                key={`${urls.pathLogos}${extrasState?.firma ?? ''}`}
                id={`${urls.pathLogos}${extrasState?.firma ?? ''}`}
                src={`${urls.pathLogos}${extrasState?.firma ?? ''}`}
                className="avatar"
                style={{ maxWidth: "150px" }}
                alt="FIRMA"
                onError={() => {
                  onChangeValue("vacio.jpg", 'firma')
                }}
              >
              </img>
            </div>
            <FileUploader
              ref={imageFirma}
              selectButtonText="Examinar"
              labelText=""
              accept={'.jpg,.jpeg'}
              uploadMode="useForm"
              onValueChanged={(data) => {
                consoleService.log(data)
                if (data.value !== null && data.value !== undefined && data?.value.length > 0) {
                  const allow = validarFormatoPermitido(data.value);
                  if (allow === true) {
                    uploadFile(data?.value[0], 'logo', data?.value[0]?.name, 'firma')
                  } else {
                    resetFileUpload("firmaName")
                  }
                }
              }}
              maxFileSize={FILE_SIZE}
              allowedFileExtensions={allowExtention}
            />
          </Labeled>
        </CustomCol>
      </RowContainer>
    </>
  )
}

export default React.memo(Extras)