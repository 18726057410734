import { RequestHelper } from "../../../../helpers/requestHelper";

export const adminLocales = { getAdminLocales, getLocal };

async function getAdminLocales(tipo): Promise<any> {
  try {
    const data: any = {
      ...tipo
    };
    const res = await RequestHelper.getAll<any>('local', 'getAllBase', '', data);
    return res;
  } catch (error) {
    return error;
  }
}


async function getLocal(codigoLocal): Promise<any> {
  try {
    const data: any = {
      codigo: codigoLocal
    };
    const res = await RequestHelper.getAll<any>('inventario/multialmacen', 'local/get', '', data);
    return res;
  } catch (error) {
    return error;
  }
}
