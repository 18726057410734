import DataGrid, { Column, ColumnChooser, Editing, Export, Lookup, Pager, Paging, Button as DatagridButton, RangeRule, MasterDetail, } from 'devextreme-react/data-grid'
import React, { FunctionComponent, PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react'
import { allowedPageSizes } from '../../../../../../../helpers/Helper'
import { useDispatch, useSelector } from 'react-redux'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import { Workbook } from 'exceljs'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { saveAs } from 'file-saver';
import ItemsCountIndicator from '../../../../../../componentes/indicadorNroRegistros'
import DataSource from 'devextreme/data/data_source'
import { setCollapsed, setSeleccionarDato } from '../../store/searchReducer'
import { RootState } from '../../../../../../../store/store'
import { TEstado } from '../../../../../../../store/types'
import { consoleService } from '../../../../../../../services/console.service'
import { isMobile } from 'react-device-detect'
import { RolesPayList } from '../../types/types'
import { ActionSheetCommandsMobile } from './ActionSheetCommandsMobile'
import DetalleRolPago from './detalleRolPago'


interface IResultsProps extends PropsWithChildren {
  onDBClick: () => void
  // eslint-disable-next-line no-unused-vars
  handleOptionChange: (e: any) => void
  data: DataSource
  pageIndex: number
  pageSize: number
  onDelete: () => void
  onPrint: () => void
}

const ResultadosBusqueda: FunctionComponent<IResultsProps> = (props) => {

  const { onDBClick, data, handleOptionChange, pageIndex, pageSize, onDelete, onPrint } =
    props

  const tablaRefPager = useRef<any>()
  const dispatch = useDispatch()
  const totalCountRols = useSelector((state: RootState) => state.nomina.rol.rolesPago.search.nroRegistrosEncontrados)
  const [isPagin] = useState<boolean>(true)
  const [optPayForm,] = useState<TEstado[] | null>(
    [
      { codigo: 0, descripcion: 'Sin pagar' },
      { codigo: 6, descripcion: 'Efectivo' },
      { codigo: 7, descripcion: 'Cheuqe' },
      { codigo: 114, descripcion: 'Transferencia' },
    ]
  )
  const [changes, setChanges] = React.useState<any>()
  const [editRowKey, setEditRowKey] = React.useState<any>()



  const onCollapsed = useCallback(async () => {
    if (totalCountRols > 5) {
      dispatch(setCollapsed(false))
    }
  }, [dispatch, totalCountRols])

  const onChangesChange = React.useCallback((changes) => {
    setChanges(changes)
  }, [])

  const onEditRowKeyChange = React.useCallback((editRowKey) => {
    setEditRowKey(editRowKey)
  }, [])

  const onExportExcell = useCallback((e) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('Main sheet')
    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'Roles_de_Pago.xlsx',
        )
      })
    })
  }, [])

  useEffect(() => {
    onCollapsed()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCountRols])

  const onSelectedChanged = useCallback(
    ({ selectedRowsData }) => {
      consoleService.log(selectedRowsData[0], 'seleccionado selectedRowsData[0]')
      if (selectedRowsData[0] && selectedRowsData[0]?.codigo) {
        dispatch(
          setSeleccionarDato({ ...selectedRowsData[0] }),
        )
      }
    },
    [dispatch],
  )


  const actionSheetCommandsMobile = React.useCallback(
    (cellData) => {
      const data = cellData.data as any
      return (
        <ActionSheetCommandsMobile
          rols={data}
          key={data.codigo}
          onDelete={(data) => {
            dispatch(setSeleccionarDato({ ...data }))
            onDelete()
          }}
          onEdit={(data) => {
            dispatch(setSeleccionarDato({ ...data }))
            onDBClick()
          }}
          onPrint={(data) => {
            dispatch(setSeleccionarDato({ ...data }))
            onPrint()
          }}
        />
      )
    },
    [onDelete, dispatch, onDBClick, onPrint],
  )


  return (
    <>
      <ItemsCountIndicator numberOfItems={data ? totalCountRols : 0} />
      <RowContainer>
        <CustomCol xs="12" md="12">
          <DataGrid
            id="tablaRoles"
            ref={tablaRefPager}
            dataSource={data ?? []}
            selection={{ mode: 'single' }}
            hoverStateEnabled={true}
            showBorders={true}
            showRowLines={true}
            onSelectionChanged={onSelectedChanged}
            onRowDblClick={onDBClick}
            repaintChangesOnly={true}
            highlightChanges={true}
            keyExpr="uuid"
            key="uuid"
            focusedRowKey="uuid"
            showColumnLines={true}
            onExporting={onExportExcell}
            className={'m-2 p-2'}
            onOptionChanged={handleOptionChange}
            remoteOperations={true}
            loadPanel={{
              enabled: true,
              height: 90,
              indicatorSrc: '',
              shading: false,
              shadingColor: '',
              showIndicator: true,
              showPane: true,
              text: 'Cargando...',
              width: 200,
            }}
          >
            <Editing
              mode="cell"
              refreshMode={'repaint'}
              allowUpdating
              changes={changes}
              onChangesChange={onChangesChange}
              editRowKey={editRowKey}
              onEditRowKeyChange={onEditRowKeyChange}
            />
            <Paging
              pageIndex={pageIndex}
              pageSize={pageSize}
              enabled={isPagin}
            />
            <Pager
              ref={tablaRefPager}
              showPageSizeSelector={true}
              allowedPageSizes={allowedPageSizes}
              showNavigationButtons={true}
              showInfo={true}
              infoText="Page #{0}. Total: {1} ({2} items)"
            />
            <Export enabled={true} allowExportSelectedData={true} />
            <ColumnChooser enabled={true} mode="select" />

            {
              !isMobile && (
                <Column allowEditing={false} type="buttons" width="100px">
                  <DatagridButton
                    icon="trash"
                    hint="Eliminar"
                    onClick={async (e) => {
                      consoleService.log('e', e)
                      const data: RolesPayList = e.row.data
                      dispatch(setSeleccionarDato({ ...data }))
                      onDelete()
                    }}
                  />
                  <DatagridButton
                    icon="edit"
                    hint="Editar"
                    onClick={async (e) => {
                      consoleService.log('e', e)
                      const data: RolesPayList = e.row.data
                      dispatch(setSeleccionarDato({ ...data }))
                      onDBClick()
                    }}
                  />
                  <DatagridButton
                    icon="print"
                    hint="Imprimir"
                    onClick={async (e) => {
                      consoleService.log('e', e)
                      const data: RolesPayList = e.row.data
                      dispatch(setSeleccionarDato({ ...data }))
                      onPrint()
                    }}
                  />
                </Column>
              )
            }

            {isMobile && (
              <Column
                allowEditing={false}
                caption="Tareas"
                dataField="codigo"
                cellRender={actionSheetCommandsMobile}
                width="50px"
                allowFiltering={false}
              ></Column>
            )}

            {!isMobile && (
              <MasterDetail
                enabled={true}
                render={(params) => (
                  <>
                    <DetalleRolPago {...params} />{''}
                  </>
                )}
              />
            )}

            <Column allowEditing={false} dataField='mes' caption="Mes" width="50px" />
            <Column allowEditing={false} dataField='anio' caption="Año" width="50px" />
            <Column allowEditing={false} dataField='cedula' caption="Cedula" width="100px" />
            <Column allowEditing={false} dataField='nombresCompletos' caption="Apellidos y Nombres" width="120px" />
            <Column allowEditing={false} dataField='descripcion' caption="Cargo" width="120px" />
            <Column allowEditing={true} dataType='number' dataField='dias' caption="Días" width="40px" >
              <RangeRule max={30} min={0} />
            </Column>
            <Column allowEditing={true} dataType='number' dataField='HL' caption="HL" width="40px" >
              <RangeRule max={7} min={0} />
            </Column>
            <Column allowEditing={true} dataType='number' dataField='ML' caption="ML" width="40px" >
              <RangeRule max={59} min={0} />
            </Column>
            <Column allowEditing={true} dataType='number' dataField='HN' caption="HN" width="40px" >
              <RangeRule max={300} min={0} />
            </Column>
            <Column allowEditing={true} dataType='number' dataField='MN' caption="MN" width="40px" >
              <RangeRule max={300} min={0} />
            </Column>
            <Column allowEditing={true} dataType='number' dataField='HS' caption="HS" width="40px" >
              <RangeRule max={300} min={0} />
            </Column>
            <Column allowEditing={true} dataType='number' dataField='MS' caption="MS" width="40px" >
              <RangeRule max={300} min={0} />
            </Column>
            <Column allowEditing={true} dataType='number' dataField='HE' caption="HE" width="40px" >
              <RangeRule max={300} min={0} />
            </Column>
            <Column allowEditing={true} dataType='number' dataField='me' caption="ME" width="40px" >
              <RangeRule max={300} min={0} />
            </Column>
            <Column allowEditing={true} dataType='date' dataField='fechaCobro' caption="Cobro" width="100px" />
            <Column allowEditing={true} dataField='bonificacion' caption="Bonificacion" width="40px" />
            <Column allowEditing={true} dataField='prestamos' caption="Prestamos" width="30px" />
            <Column allowEditing={true} dataField='renta' caption="Imp. Renta" width="50px" />
            <Column dataField='fPago' caption="FormaPago" width="100px">
              <Lookup dataSource={optPayForm} valueExpr='codigo' displayExpr='descripcion' />
            </Column>
            <Column allowEditing={false} dataField='numeroCheque' caption="Nro. Cheque" width="100px" />
            <Column allowEditing={false} dataField='cuentaBancoNumero' caption="Cta. Empleado" width="100px" />
            <Column allowEditing={false} dataField='bancoNombre' caption="Banco" width="100px" />
            <Column allowEditing={false} dataField='localNombre' caption="Lugar/CC" width="100px" />
            <Column allowEditing={false} dataField='sueldoMes' caption="Total Pagar" width="80px" />
          </DataGrid>
        </CustomCol>
      </RowContainer>
    </>
  )
}

export default React.memo(ResultadosBusqueda)