import { RequestHelper } from "../../../../../../../helpers/requestHelper"


export const CarteraService = { getCartera }


async function getCartera(
  clienteCodigo: number,
  ciudadCodigo: number,
  vendedorCodigo: number,
  localCodigo: number,
  consignacion: number,
  empresaCodigo: string,
  consolidado: boolean,
  tipoCliente: string,
  fechaFin: string,
  desdecal?: string,
): Promise<any> {
  const obj = {
    clienteCodigo,
    clienteUbicacion: ciudadCodigo,
    clienteVendedor: vendedorCodigo,
    clienteLocal: localCodigo,
    tipoClienteCodigo: consignacion,
    clienteEmpresa: empresaCodigo,
    clienteFechaRegistro: desdecal,
    fechaFin,
    consolidado,
    clienteTipo: tipoCliente,
  }

  console.log(obj)
  try {
    const data = await RequestHelper.getAll<any>("ventas", "duePortfolio", "", obj)
    return data
  } catch (error) {
    console.log(error)
    return error
  }
}