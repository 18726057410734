import React from 'react';
import { DataSheetColumn } from './dataSheetColumn';
import './customsheet.scss';

export interface IVentaDetalleSheetRenderer {
  className: string,
  columns: Array<DataSheetColumn>
  children?: any
}

const Header = (props) => {
  const className = 'cell customsheet-header'
  const { col } = props
  return <th className={className} style={{ width: col.width }}>{col.headerText}</th>
}

export default class GenericSheetRenderer extends React.PureComponent<IVentaDetalleSheetRenderer> {
  public render() {
    const { className, columns } = this.props;
    return (
      <table className={className} style={{ width: '100%' }}>
        <thead>
          <tr>
            {
              columns.map((col, index) => (
                <Header key={col.headerText + col.id} col={col} columnIndex={index} />
              ))
            }
          </tr>
        </thead>
        <tbody>
          {this.props?.children}
        </tbody>
      </table>
    );
  }
}
