import React, { useEffect } from 'react'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput'
import {
  Button,
  CheckBox,
  NumberBox,
  RadioGroup,
  TextBox,
  ValidationGroup,
  ValidationSummary,
  Validator,
} from 'devextreme-react'
import { AsyncRule, RangeRule, RequiredRule } from 'devextreme-react/data-grid'
import {
  StoreList,
  SaveStore,
  optListaPrecios,
  optNegativos,
  optionsSearch,
} from '../../types/types'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import {
  clearButtonClick,
  openTab,
  setButtons,
  setCurrentExecutingAction,
  setNameTabe,
} from '../../store/tabsReducer'
import { useDispatch, useSelector } from 'react-redux'
import { MultistoreServices } from '../../services/multialmacen.services'
import { RootState } from '../../../../../../store/store'
import { ToastTypes } from '../../../../../../store/types'
import {
  changeLoaderMultistore,
  setCurrentFunction,
} from '../../store/generalReducer'
import { addToast } from '../../../../../../store/toasterReducer'
import {
  initDatosEdicion,
  setDatosEdicion,
  setStoreBelongs,
  setStoreStatus,
  setTypeStore,
} from '../../store/editDataReduce'
import { StatesEdition } from '../../../../../ventas/types/enums'
import Dialog from '../../../../../../views/componentes/librerias/bootstrap-dialog'
import { defaultDataStore } from '../..'
import { v4 as uuidv4 } from 'uuid'
import FIleInput from '../../../../../componentes/fileInput'
import { CTooltip } from '@coreui/react-pro'
import LocalesLookUp from '../../../../../componentes/localesLookUp'
import TiposLocalesLookUp from '../../../../../componentes/tiposLocalesLookUp'
import LocalidadesLookUp from '../../../../../componentes/localidadesLookUp/localidadesLookUp'
import TipoEstadoLookUp from '../../../../../componentes/tipoEstado'
import { TabState } from '../../../../../../store/genericTabTypes'
import { Button as TextBoxButton } from 'devextreme-react/text-box'
import VisualizaError from '../../../../../ventas/pages/shared/visualizaError/visualizaError'
import ListErrosValidationForm from '../../../../../componentes/listadoErroresValidacionForm'
import { MessagesKeys, lh } from '../../../../../../helpers/localizationHelper'
import { isMobile } from 'react-device-detect'
import { useConvertToBase64 } from '../../../../../../hooks/useGlobalHooks'

interface INewProps extends React.PropsWithChildren {
  tab: TabState<StoreList>
  tabId: string
}

const Nuevo: React.FunctionComponent<INewProps> = (props) => {
  const { tab, tabId } = props
  const dispatch = useDispatch()

  const currentAction = useSelector(
    (state: RootState) =>
      state.inventarios.multialmacen.general.currentFunction,
  )
  const storeState = useSelector((state: RootState) => {
    return state.inventarios.multialmacen.editData[tabId]
  })
  const tabs = useSelector((state: RootState) => {
    return state.inventarios.multialmacen.tabs
  })
  const companyState = useSelector(
    (state: RootState) => state.global.session?.empresa,
  )
  const localState = useSelector(
    (state: RootState) => state.global.session?.local,
  )

  const [confirmarNuevo, setconfirmarNuevo] = React.useState<boolean>(false)
  const validationGroupRef = React.useRef<any>()
  const dialogRef = React.useRef<any>(null)
  const [imageSoruce, setImageSource] = React.useState<string>('')
  const [imageBackup, setBackupPathImg] = React.useState<string>('')
  const [showImage, setShowImage] = React.useState<boolean>(false)
  const [validationFormErrors, setValidationFormErrors] = React.useState<
    Array<any>
  >([])
  const [showPopupError, setShowPopupError] = React.useState<boolean>(false)
  const [passwordMode, setPasswordMode] = React.useState<'password' | 'text'>(
    'password',
  )

  const convertToBase64 = useConvertToBase64()

  const playLoader = React.useCallback(
    (message = 'Cargando...') => {
      dispatch(changeLoaderMultistore({ show: true, mensaje: message }))
    },
    [dispatch],
  )

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoaderMultistore({ show: false, mensaje: '' }))
  }, [dispatch])

  const onChangedPassword = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setDatosEdicion({
            key: tabId,
            store: {
              ...storeState,
              contrasena: event.value,
            },
          }),
        )
    },
    [dispatch, storeState, tabId],
  )

  const onChangedUser = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setDatosEdicion({
            key: tabId,
            store: {
              ...storeState,
              usuario: event.value,
            },
          }),
        )
    },
    [dispatch, storeState, tabId],
  )

  const onChangedStatus = React.useCallback(
    (data: any) => {
      dispatch(
        setStoreStatus({
          key: tabId,
          storeStatus: data,
        }),
      )
    },
    [dispatch, tabId],
  )

  const onChangedUafCode = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setDatosEdicion({
            key: tabId,
            store: {
              ...storeState,
              codigoUaf: event.value,
            },
          }),
        )
    },
    [dispatch, storeState, tabId],
  )

  const onChangedPriceList = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setDatosEdicion({
            key: tabId,
            store: {
              ...storeState,
              usarListaPrecios: event.value,
            },
          }),
        )
    },
    [dispatch, storeState, tabId],
  )

  const onChangedZero = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setDatosEdicion({
            key: tabId,
            store: {
              ...storeState,
              transaccionEnCero: event.value,
            },
          }),
        )
    },
    [dispatch, storeState, tabId],
  )

  const onChangedStoreBelongs = React.useCallback(
    (data: any) => {
      dispatch(
        setStoreBelongs({
          key: tabId,
          storeBelongs: data,
        }),
      )
    },
    [dispatch, tabId],
  )

  const onChangedTypeStoreMain = React.useCallback(
    (data: any) => {
      dispatch(
        setTypeStore({
          key: tabId,
          typeStore: data,
        }),
      )
    },
    [dispatch, tabId],
  )

  const onChangedManager = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setDatosEdicion({
            key: tabId,
            store: {
              ...storeState,
              gerente: event.value,
            },
          }),
        )
    },
    [dispatch, storeState, tabId],
  )

  const onChangedPhone = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setDatosEdicion({
            key: tabId,
            store: {
              ...storeState,
              telefono: event.value,
            },
          }),
        )
    },
    [dispatch, storeState, tabId],
  )

  const onChangedAddress = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setDatosEdicion({
            key: tabId,
            store: {
              ...storeState,
              direccion: event.value,
            },
          }),
        )
    },
    [dispatch, storeState, tabId],
  )

  const onChangedCity = React.useCallback(
    (data: any) => {
      dispatch(
        setDatosEdicion({
          key: tabId,
          store: {
            ...storeState,
            cuidad: data,
          },
        }),
      )
    },
    [dispatch, storeState, tabId],
  )

  const onChangedDiscount = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setDatosEdicion({
            key: tabId,
            store: {
              ...storeState,
              desc: event.value,
            },
          }),
        )
    },
    [dispatch, storeState, tabId],
  )

  const onChangedInventory = React.useCallback(
    (event: any) => {
      if (event.event) {
        dispatch(
          setDatosEdicion({
            key: tabId,
            store: {
              ...storeState,
              inv: event.value,
            },
          }),
        )
      }
    },
    [dispatch, storeState, tabId],
  )

  const onChangedInitials = React.useCallback(
    (event: any) => {
      if (event.event) {
        dispatch(
          setDatosEdicion({
            key: tabId,
            store: {
              ...storeState,
              siglas: event.value,
            },
          }),
        )
      }
    },
    [dispatch, tabId, storeState],
  )

  const onChangedName = React.useCallback(
    (event: any) => {
      if (event.event) {
        dispatch(
          setDatosEdicion({
            key: tabId,
            store: {
              ...storeState,
              nombre: event.value,
              siglas: event.value.substring(0, 3).toUpperCase() ?? '',
            },
          }),
        )
      }
    },
    [dispatch, tabId, storeState],
  )

  const onChangedStockWeb = React.useCallback(
    (event: any) => {
      if (event.event) {
        dispatch(
          setDatosEdicion({
            key: tabId,
            store: {
              ...storeState,
              stockWeb: event.value,
            },
          }),
        )
      }
    },
    [dispatch, tabId, storeState],
  )

  const onChangedTypeStore = React.useCallback(
    (evenet: any) => {
      if (evenet.event) {
        dispatch(
          setDatosEdicion({
            key: tabId,
            store: {
              ...storeState,
              calseLocal: evenet.value,
            },
          }),
        )
      }
    },
    [dispatch, tabId, storeState],
  )

  const setImageStore = React.useCallback(() => {
    if (storeState?.imagen?.length > 0 && storeState.imagen !== 'NULL') {
      // eslint-disable-next-line no-undef
      const pathImage = `${process.env['REACT_APP_PATH_LOGOS']}${storeState.imagen}`
      setImageSource(pathImage)
      setShowImage(true)
      setBackupPathImg(storeState.imagen)
    }
  }, [storeState])

  const onChangedImage = React.useCallback(
    async (eventImage) => {
      if (eventImage === null || eventImage === undefined) {
        setImageSource('')
        setShowImage(false)
        dispatch(
          setDatosEdicion({
            key: tabId,
            store: {
              ...storeState,
              imagen: '',
              imagenCodificada: '',
            },
          }),
        )
      } else if (
        eventImage &&
        eventImage.value &&
        eventImage.value.length > 0
      ) {
        let fileCoded: any = ''
        await convertToBase64(eventImage.value[0])
          .then((data) => {
            fileCoded = data
          })
          .catch((data) => {
            fileCoded = data
          })
        setImageSource(fileCoded)
        setShowImage(true)
        dispatch(
          setDatosEdicion({
            key: tabId,
            store: {
              ...storeState,
              imagen: eventImage.value[0].name,
              imagenCodificada: fileCoded,
            },
          }),
        )
      }
    },
    [convertToBase64, dispatch, storeState, tabId],
  )

  const onConfirmarNuevo = React.useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea crear un nuevo registro?`,
      actions: [
        Dialog.Action(
          <span>
            <u>P</u>estaña Actual
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-success',
          'p',
        ),
        Dialog.Action(
          <span>
            <u>N</u>ueva Pestaña
          </span>,
          (dialog) => {
            dialog.hide()
            const id = uuidv4()
            const dataInitialDefault = {
              ...defaultDataStore,
            }
            dispatch(
              initDatosEdicion({
                key: id,
                data: dataInitialDefault,
              }),
            )
            dispatch(openTab({ key: id }))
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setconfirmarNuevo(false)
    return
  }, [dispatch])

  React.useEffect(() => {
    if (confirmarNuevo) onConfirmarNuevo()
  }, [confirmarNuevo, onConfirmarNuevo])

  const onHandleSave = React.useCallback(async () => {
    let validationFormResult = validationGroupRef.current.instance.validate()
    if (validationFormResult.status === 'pending')
      validationFormResult = await validationFormResult.complete
    if (validationFormResult.isValid === false) {
      const errorList = []
      validationFormResult.brokenRules.map((rule) => {
        errorList.push(rule.message)
      })
      setValidationFormErrors(errorList)
      setShowPopupError(true)
    } else {
      try {
        dispatch(
          setCurrentExecutingAction({
            tabKey: tabId,
            buttonType: ButtonTypes.save,
          }),
        )
        playLoader('Guardando . . .')
        let nameImage: string = ''
        if (storeState.imagen) {
          const extentionFile: string = storeState.imagen.split('.').pop() ?? ''
          nameImage = `${companyState.codigo}_${localState.codigo}.${extentionFile}`
        }
        const store: SaveStore = {
          infoRegistro: {
            codigo: storeState.codigo,
            clase: storeState.calseLocal.value,
            nombre: storeState.nombre,
            siglas: storeState.siglas,
            ciudad: storeState.cuidad?.codigo,
            direccion: storeState.direccion,
            telefono: storeState.telefono,
            gerente: storeState.gerente,
            tipo: storeState.tipo.value,
            pertenece: storeState.pertence?.codigo ?? -1,
            kardex: storeState.transaccionEnCero.value,
            estado: storeState.estado.codigo,
            siglasBOD: storeState.inv,
            web: storeState.stockWeb === true ? 1 : 0,
            empresaCodigo: companyState.codigo,
            uaf: storeState.codigoUaf,
            descuento: storeState.desc,
            usuarioDokan: storeState.usuario,
            passwordDokan: storeState.contrasena,
            listaPrecios: storeState.usarListaPrecios.value,
            nameLogo: nameImage,
            codeFileLogo: storeState.imagenCodificada,
          },
        }
        const newStore = await MultistoreServices.saveStore(store)
        if (!newStore.error) {
          dispatch(
            setNameTabe({
              key: tabId,
              codigo:
                tab.editStatus === StatesEdition.save
                  ? tab.info.info.codigo
                  : newStore.auto,
              nombre: storeState.nombre,
            }),
          )
          dispatch(
            setButtons({
              tabKey: tabId,
              buttons: {
                Nuevo: true,
                Guardar: false,
                Editar: false,
                Eliminar: false,
                Buscar: true,
                Imprimir: false,
                Deshacer: false,
              },
            }),
          )
          stopLoader()
          dispatch(
            addToast({
              id: '',
              autoHide: true,
              content: newStore.message ?? 'Registro Satisfactorio',
              fade: true,
              title: 'Guardar',
              type: ToastTypes.Success,
            }),
          )
        } else {
          dispatch(
            setCurrentExecutingAction({
              tabKey: tabId,
              buttonType: undefined,
            }),
          )
          stopLoader()
          dispatch(
            addToast({
              id: '',
              autoHide: true,
              content: newStore.message ?? 'Error al guardar.',
              fade: true,
              title: 'Guardar',
              type: ToastTypes.Danger,
            }),
          )
        }
      } catch (error) {
        dispatch(
          setCurrentExecutingAction({
            tabKey: tabId,
            buttonType: undefined,
          }),
        )
        stopLoader()
        dispatch(
          addToast({
            id: '',
            autoHide: true,
            content: 'Error al guardar ' + error,
            fade: true,
            title: 'Guardar',
            type: ToastTypes.Danger,
          }),
        )
      }
    }
  }, [
    dispatch,
    tabId,
    playLoader,
    storeState,
    companyState,
    localState,
    tab,
    stopLoader,
  ])

  const onHandleUndo = React.useCallback(() => {
    if (tab.editStatus === StatesEdition.save) {
      let pathImg: string = ''
      if (imageBackup) {
        pathImg = imageBackup
        setImageSource(
          // eslint-disable-next-line no-undef
          `${process.env['REACT_APP_PATH_LOGOS']}${imageBackup}`,
        )
        setShowImage(true)
      }
      dispatch(
        setDatosEdicion({
          key: tabId,
          store: {
            ...storeState,
            calseLocal: optionsSearch[tab.info.info.calseLocal.value],
            stockWeb: tab.info.info.stockWeb,
            nombre: tab.info.info.nombre,
            siglas: tab.info.info.siglas,
            inv: tab.info.info.inv,
            desc: tab.info.info.desc,
            cuidad: {
              codigoPais: -1,
              nombrePais: '',
              codigoProvincia: -1,
              nombreProvincia: '',
              codigo: tab.info.info.cuidad.codigo,
              nombre: '',
            },
            direccion: tab.info.info.direccion,
            telefono: tab.info.info.telefono,
            gerente: tab.info.info.gerente,
            tipo: tab.info.info.tipo,
            pertence: tab.info.info.pertence,
            transaccionEnCero: tab.info.info.transaccionEnCero,
            usarListaPrecios: tab.info.info.usarListaPrecios,
            codigoUaf: tab.info.info.codigoUaf,
            estado: tab.info.info.estado,
            usuario: tab.info.info.usuario,
            contrasena: tab.info.info.contrasena,
            imagen: pathImg,
            imagenCodificada: '',
          },
        }),
      )
    } else {
      dispatch(
        setDatosEdicion({
          key: tabId,
          store: defaultDataStore,
        }),
      )
      setImageSource('')
      setShowImage(false)
    }
  }, [tab, dispatch, tabId, storeState, imageBackup])

  const handleButtonClick = React.useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.save:
          if (tabs.current === tabId) onHandleSave()
          break
        case ButtonTypes.new:
          if (tabs.current === tabId) setconfirmarNuevo(true)
          break
        case ButtonTypes.undo:
          if (tabs.current === tabId) onHandleUndo()
          break
        default:
          break
      }
      dispatch(setCurrentFunction(''))
      dispatch(clearButtonClick(tabId))
    },
    [dispatch, onHandleSave, onHandleUndo, tabId, tabs],
  )

  const validateAsyncSelectedCity = React.useCallback((selected: any) => {
    if (selected.value.codigo > -1) {
      return Promise.resolve()
    } else {
      return Promise.reject('Ciudad: Debe seleccionar una opción')
    }
  }, [])

  const validateAsyncSelectedType = React.useCallback((selected: any) => {
    if (selected.value.value > -1) {
      return Promise.resolve()
    } else {
      return Promise.reject('Tipo de Local: Debe seleccionar una opción')
    }
  }, [])

  const validateAsyncSelectedBelongs = React.useCallback(
    (selected: any) => {
      if (
        selected?.value?.codigo === -1 ||
        selected.value.ciudad !== storeState.cuidad?.codigo
      )
        return Promise.reject(
          'Pertenece: Elija un Local perteneciente a la misma Ciudad de la Bodega a crear',
        )
      else {
        dispatch(
          setDatosEdicion({
            key: tabId,
            store: {
              ...storeState,
              gerente: selected.value.gerente,
            },
          }),
        )
        return Promise.resolve()
      }
    },
    [dispatch, storeState, tabId],
  )

  useEffect(() => {
    if (currentAction !== '') handleButtonClick(currentAction)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAction])

  React.useEffect(() => {
    if (tab.editStatus === StatesEdition.save) setImageStore()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {showPopupError && (
        <VisualizaError
          titulo="Error en Multialmacén"
          mensaje={'Error al guardar'}
          content={
            <ListErrosValidationForm
              mainMessage={lh.getMessage(MessagesKeys.CheckErrorsInForm)}
              errorsList={validationFormErrors}
              color={'danger'}
            />
          }
          onOk={() => setShowPopupError(false)}
        />
      )}
      <Dialog ref={dialogRef} />
      <ValidationGroup
        key={'valitadationMultialmacen'}
        id={'valitadationMultialmacen'}
        ref={validationGroupRef}
      >
        {!isMobile && (
          <ValidationSummary
            id="summaryValidateTypeCustomer"
            style={{ marginTop: '12px' }}
          />
        )}
        <RowContainer>
          <CustomCol xs="12" md="5">
            <Labeled label="">
              <RadioGroup
                name="idSearchOptionsPlace"
                dataSource={optionsSearch}
                value={storeState.calseLocal}
                layout="horizontal"
                displayExpr="label"
                onValueChanged={onChangedTypeStore}
                width="100%"
              />
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="1" className="d-flex align-items-end">
            <CTooltip
              content="Recuerde: Puede crear una ó más bodegas para un local."
              placement="right"
            >
              <div>
                <Button
                  id="btnInfo"
                  type="normal"
                  icon="info"
                  stylingMode="contained"
                />
              </div>
            </CTooltip>
          </CustomCol>
          <CustomCol xs="12" md="2">
            <Labeled label="">
              <CheckBox
                text="Stock Web"
                value={storeState.stockWeb}
                onValueChanged={onChangedStockWeb}
              />
            </Labeled>
          </CustomCol>
        </RowContainer>
        <RowContainer>
          <CustomCol xs="12" md="4">
            <Labeled label="Nombre:">
              <TextBox
                value={storeState.nombre}
                placeholder=""
                onValueChanged={onChangedName}
                showClearButton={true}
                width="100%"
                inputAttr={{ autocomplete: 'nope' }}
              >
                <Validator>
                  <RequiredRule message="Nombre: Este campo es requerido" />
                </Validator>
              </TextBox>
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="2">
            <Labeled label="Siglas:">
              <TextBox
                value={storeState.siglas}
                placeholder=""
                onValueChanged={onChangedInitials}
                showClearButton={true}
                width="100%"
                inputAttr={{ autocomplete: 'nope' }}
              />
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="2">
            <Labeled label="Inv:">
              <TextBox
                value={storeState.inv}
                placeholder=""
                onValueChanged={onChangedInventory}
                showClearButton={true}
                width="100%"
                inputAttr={{ autocomplete: 'nope' }}
              />
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="2">
            <Labeled label="Desc:">
              <NumberBox
                value={storeState.desc}
                placeholder=""
                onValueChanged={onChangedDiscount}
                showClearButton={true}
                width="100%"
                inputAttr={{ autocomplete: 'nope' }}
              >
                <Validator>
                  <RangeRule
                    min={0}
                    max={100}
                    message="Descuento: El valor debe estar entre 0 y 100."
                  />
                </Validator>
              </NumberBox>
            </Labeled>
          </CustomCol>
        </RowContainer>
        <RowContainer>
          <CustomCol xs="12" md="3">
            <Labeled label="Ciudad:">
              <LocalidadesLookUp
                onChanged={onChangedCity}
                onChangedOptions={() => {}}
                selected={storeState.cuidad}
                allowEdit
                allowClear
              >
                <Validator>
                  <AsyncRule
                    message={'Ciudad: Debe seleccionar una opción'}
                    validationCallback={validateAsyncSelectedCity}
                  />
                  <RequiredRule
                    message={'Ciudad: Debe seleccionar una opción'}
                  />
                </Validator>
              </LocalidadesLookUp>
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="4">
            <Labeled label="Dirección:">
              <TextBox
                value={storeState.direccion}
                placeholder=""
                onValueChanged={onChangedAddress}
                showClearButton={true}
                width="100%"
                inputAttr={{ autocomplete: 'nope' }}
              >
                <Validator>
                  <RequiredRule message="Dirección: Este campo es requerido" />
                </Validator>
              </TextBox>
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="2">
            <Labeled label="Teléfono:">
              <TextBox
                value={storeState.telefono}
                placeholder=""
                onValueChanged={onChangedPhone}
                showClearButton={true}
                width="100%"
                inputAttr={{ autocomplete: 'nope' }}
              >
                <Validator>
                  <RequiredRule message="Teléfono: Este campo es requerido" />
                </Validator>
              </TextBox>
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="3">
            <Labeled label="Gerente:">
              <TextBox
                value={storeState.gerente}
                placeholder=""
                onValueChanged={onChangedManager}
                showClearButton={true}
                width="100%"
                inputAttr={{ autocomplete: 'nope' }}
              >
                <Validator>
                  <RequiredRule message="Gerente: Este campo es requerido" />
                </Validator>
              </TextBox>
            </Labeled>
          </CustomCol>
        </RowContainer>
        <RowContainer>
          <CustomCol xs="12" md="3">
            <Labeled label="Tipo:">
              <TiposLocalesLookUp
                selected={storeState.tipo}
                onChanged={onChangedTypeStoreMain}
                onChangedOptions={() => {}}
              >
                <Validator>
                  <AsyncRule
                    message={'Tipo de Local: Debe seleccionar una opción'}
                    validationCallback={validateAsyncSelectedType}
                  />
                  <RequiredRule
                    message={'Tipo de Local: Debe seleccionar una opción'}
                  />
                </Validator>
              </TiposLocalesLookUp>
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="3">
            <Labeled label="Pertenece:">
              <LocalesLookUp
                selected={storeState.pertence}
                onChanged={onChangedStoreBelongs}
                onChangedOptions={() => {}}
                disabled={storeState.calseLocal.value === 0}
              >
                <Validator>
                  <AsyncRule
                    message={
                      'Pertenece: Elija un Local perteneciente a la misma Ciudad de la Bodega a crear'
                    }
                    validationCallback={validateAsyncSelectedBelongs}
                  />
                  <RequiredRule
                    message={
                      'Pertenece: Elija un Local perteneciente a la misma Ciudad de la Bodega a crear'
                    }
                  />
                </Validator>
              </LocalesLookUp>
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="3">
            <Labeled label="Tansacciones con cero:">
              <RadioGroup
                name="idSearchOptionsPlace"
                dataSource={optNegativos}
                value={storeState.transaccionEnCero}
                layout="horizontal"
                displayExpr="label"
                onValueChanged={onChangedZero}
                width="100%"
              />
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="3">
            <Labeled label="Utilizar Lista de Precios:">
              <RadioGroup
                name="idSearchOptionsPlace"
                dataSource={optListaPrecios}
                value={storeState.usarListaPrecios}
                layout="horizontal"
                displayExpr="label"
                onValueChanged={onChangedPriceList}
                width="100%"
              />
            </Labeled>
          </CustomCol>
        </RowContainer>
        <RowContainer>
          <CustomCol xs="12" md="3">
            <Labeled label="Código UAF Agente:">
              <TextBox
                value={storeState.codigoUaf}
                placeholder=""
                onValueChanged={onChangedUafCode}
                showClearButton={true}
                width="100%"
                inputAttr={{ autocomplete: 'nope' }}
              />
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="3">
            <Labeled label="Estado:">
              <TipoEstadoLookUp
                onChanged={onChangedStatus}
                selected={storeState?.estado ?? null}
              />
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="3">
            <Labeled label="Usuario:">
              <TextBox
                value={storeState.usuario}
                placeholder=""
                onValueChanged={onChangedUser}
                showClearButton={true}
                width="100%"
                inputAttr={{ autocomplete: 'nope' }}
                disabled={storeState.stockWeb === false}
              />
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="3">
            <Labeled label="Password:">
              <TextBox
                value={storeState.contrasena}
                placeholder=""
                onValueChanged={onChangedPassword}
                showClearButton={true}
                width="100%"
                inputAttr={{ autocomplete: 'nope' }}
                disabled={storeState.stockWeb === false}
                mode={passwordMode}
              >
                <TextBoxButton
                  name="password"
                  location="after"
                  options={{
                    icon:
                      passwordMode === 'text'
                        ? './images/eye-slash.png'
                        : './images/eye.png',
                    onClick: () => {
                      setPasswordMode(
                        passwordMode === 'text' ? 'password' : 'text',
                      )
                    },
                  }}
                />
              </TextBox>
            </Labeled>
          </CustomCol>
        </RowContainer>
        <RowContainer>
          <CustomCol xs="12" md="5">
            <Labeled label="Imagen:">
              {showImage && (
                <img
                  key={uuidv4()}
                  id={uuidv4()}
                  src={imageSoruce}
                  className="avathar"
                  style={{
                    marginTop: '12px',
                    maxWidth: '250px',
                    maxHeight: '250px',
                    objectFit: 'cover',
                  }}
                  alt="logo-local"
                />
              )}
              <FIleInput
                accept={'.jpg'}
                allowedFileExtensions={['.jpg', '.jpeg']}
                onChanged={onChangedImage}
                showDownloadDemo={false}
              />
            </Labeled>
          </CustomCol>
        </RowContainer>
        {isMobile && (
          <ValidationSummary
            id="summaryValidateTypeCustomer"
            style={{ marginTop: '12px' }}
          />
        )}
      </ValidationGroup>
    </>
  )
}

export default React.memo(Nuevo)
