import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ClientesDatosEdicion, ClientesListado, ConfiguracionesCliente } from '../types/types'
import { EdicionPayload } from '../../../store/types'
import { clearDataEdition } from '../../../../../hooks/useNavigationTabControls'


const initialState: ConfiguracionesCliente = {
  seleccionado: null,
}

const configClienteSlice = createSlice({
  name: 'configuraciones',
  initialState: initialState,
  reducers: {
    clearDataEdition,
    seleccionarCliente(state, acion: PayloadAction<ClientesListado>) {
      state.seleccionado = acion.payload
    },
    resetSeleccioCliente(state) {
      state.seleccionado = null
    },
    setDatosEdicionBackup(
      state,
      action: PayloadAction<EdicionPayload<ClientesDatosEdicion>>,
    ) {
      state[action.payload.key] = action.payload.data
    },
  },
})

export const configuracionesClienteReducer = configClienteSlice.reducer
export const { seleccionarCliente, resetSeleccioCliente, clearDataEdition: clearDataBackup, setDatosEdicionBackup } =
  configClienteSlice.actions
