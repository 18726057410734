import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { CCol, CContainer, CRow } from '@coreui/react-pro'
import LoadPanel from 'devextreme-react/load-panel'
import { Switch } from 'devextreme-react/switch';
import DataGrid, { Column, SearchPanel, HeaderFilter, Scrolling, LoadPanel as GridLoadPanel, Button as DatagridButton } from 'devextreme-react/data-grid';
import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box';
import { ValidationGroup } from 'devextreme-react/validation-group';
import ScrollView from 'devextreme-react/scroll-view';
import { isMobileOnly } from 'react-device-detect'
import ValidationSummary from 'devextreme-react/validation-summary';
import { useSelector } from 'react-redux';
import { Cliente, VentaListado } from '../../../../types/types'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput'
import { useModalConvertSize } from '../../../../../../hooks/useModalSize';
import { consoleService } from '../../../../../../services/console.service';
import { RootState } from '../../../../../../store/store';
import { addToast } from '../../../../../../store/toasterReducer';
import { TiposComprobantesSri, TiposComprobantesSLV, ToastTypes, DateRange } from '../../../../../../store/types';
import { CompraService } from '../../../../../compras/services/compra.service';
import { VentasService } from '../../../../services/ventas.service';
import CustomModalDevx from '../../../../../../views/componentes/modal/Modal';
import BuscarClienteLookUp from '../../../../../ventas/components/busquedaCliente/busquedaCliente'
import { DateBox } from 'devextreme-react';
import Validator, { PatternRule, RequiredRule } from 'devextreme-react/validator';
import { DateUtils, formatoFechasApi } from '../../../../../../helpers/dateUtils';
import { format, subDays } from 'date-fns';
import { VentasGridHelper } from '../../../ventas/helpers/ventasGridHelper';
import { selectFiltroBusquedaGuias } from '../../../guiasRemision/store/searchReducer';

interface Props extends React.PropsWithChildren {
  onOk: (documento: VentaListado) => void,
  onCancel: () => void
  modulo?: number
  documentos?: Array<VentaListado>
  documentoAnulando: VentaListado
  tipoComprobante?: TiposComprobantesSLV | TiposComprobantesSri
}

const BuscarDocumentos = (props: Props) => {
  const { onOk, onCancel, documentos, documentoAnulando, tipoComprobante } = props;
  const dispatch = useDispatch();
  const empresa = useSelector((state: RootState) => { return state.global.session?.empresa });

  const [cliente, setCliente] = React.useState<Cliente | null>(null);
  const [comprobante, setComprobante] = useState('');
  const [facturas, setFacturas] = useState<Array<VentaListado>>([])
  const [buscando, setBuscando] = useState(false);
  const [modalSize] = useModalConvertSize("md");
  const [loadPanelEnabled, setloadPanelEnabled] = useState(true);
  const validationGroupRef = React.useRef<any>();
  const validationGroupAddDocRef = React.useRef<any>();
  const [buscarPorFechas, setBuscarPorFechas] = React.useState<boolean>(false);
  const [iniciarBusqueda, setIniciarBusqueda] = React.useState<boolean>(false);
  const [startDate, setStartDate] = React.useState<Date>(new Date());
  const [endDate, setEndDate] = React.useState<Date>(new Date());
  const filtroBusqueda = useSelector(selectFiltroBusquedaGuias)

  const onSelectDocument = React.useCallback(async (data: VentaListado) => {
    console.log('onSelectDocument', data)

    if (data && data.estadoElectronico === 'ANULADO') {
      dispatch(addToast({
        title: 'Documento no permitido.',
        content: `${`El documento ha sido anulado previamente y no puede ser utilizado para anular un documento.`}  `,
        type: ToastTypes.Info,
      }));
      return false;
    }
    let authDocument = null
    if (tipoComprobante !== TiposComprobantesSLV.NotaRemision) {
      authDocument = await VentasService.getAutorizacionDocumento(data.comprobante)
    } else {
      authDocument = data.autorizacion
    }

    if (authDocument === null || authDocument === undefined) {
      dispatch(addToast({
        title: 'Documento no permitido.',
        content: "No se encuentra la autorización de este comprobante.",
        type: ToastTypes.Info,
      }));
      return false;
    }
    if (tipoComprobante !== TiposComprobantesSLV.NotaRemision) {

      if (documentoAnulando.comprobante === data.comprobante) {
        dispatch(addToast({
          title: 'Documento no permitido.',
          content: "No se puede utilizar el mismo documento para generar anular el comprobante.",
          type: ToastTypes.Info,
        }));
        return false;
      }
    } else {
      if (documentoAnulando.numero === data.numero) {
        dispatch(addToast({
          title: 'Documento no permitido.',
          content: "No se puede utilizar el mismo documento para generar anular el comprobante.",
          type: ToastTypes.Info,
        }));
        return false;
      }
    }

    if (data.autorizacionHacienda && data.autorizacionHacienda) {
      if (data.autorizacionHacienda.codigoGeneracion && data.autorizacionHacienda.codigoGeneracion !== '') {
        onOk(data);
      } else {
        dispatch(addToast({
          title: 'Documento no permitido.',
          content: "El documento no autorizado no puede ser utilizado.",
          type: ToastTypes.Info,
        }));
        return false;
      }
    } else {
      dispatch(addToast({
        title: 'Documento no permitido.',
        content: "El documento no autorizado no puede ser utilizado.",
        type: ToastTypes.Info,
      }));
      return false;
    }


  }, [onOk, dispatch, documentoAnulando]);

  const onRowDoubleClick = React.useCallback((e) => {
    const { data } = e;
    onSelectDocument(data);
  }, [onSelectDocument]);

  const filtrarPorTipoComprobante = useCallback((objetos: any[]): any[] => {
    let documentos = objetos
    documentos = objetos.filter(objeto => objeto.comprobante !== documentoAnulando.comprobante);

    documentos = objetos.filter(comprobante => 'codigoGeneracion' in comprobante['autorizacionHacienda']);

    return documentos;
  }, [documentoAnulando]);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Meses en JavaScript son de 0 a 11
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const buscarFacturas = useCallback(async (range: DateRange | null) => {
    let startDate, endDate;
    startDate = formatDate(filtroBusqueda.fechaInicio);
    endDate = formatDate(filtroBusqueda.fechaFin);
    if (range) {
      startDate = range.inicio;
      endDate = range.fin;
    }
    setFacturas([])
    try {
      const validationResult = await validationGroupRef.current?.instance.validate();
      //console.log('validationResult',validationResult)
      let _facturas = null
      if (validationResult === undefined || validationResult?.isValid) {
        setBuscando(true);
        if (
          tipoComprobante === TiposComprobantesSLV.CreditoFiscal
          || tipoComprobante === TiposComprobantesSLV.Factura ||
          tipoComprobante === TiposComprobantesSLV.FacturaExportacion ||
          tipoComprobante === TiposComprobantesSLV.NotaCredito ||
          tipoComprobante === TiposComprobantesSLV.NotaDebito
        ) {
          _facturas = await VentasService.getVentas(0, "", "", "", comprobante !== '' ? comprobante.toString() : '0', undefined, cliente !== null ? cliente?.identificacion : undefined, true, undefined, undefined, undefined, undefined, tipoComprobante);
        }
        if (
          tipoComprobante === TiposComprobantesSLV.NotaRemision
        ) {
          _facturas = await VentasService.getGuias(
            null,
            startDate,
            endDate,
            true,
            comprobante,
            null,
            tipoComprobante,
          )
        }
        console.log(_facturas)
        // else if (tipoComprobante === 'compra') {
        //   _facturas = await CompraService.buscarComprobantesModificar(cliente ? cliente.identificacion : '');
        //   _facturas = onParseComprToVentaType(_facturas)
        // }

        let objetosFiltrados = _facturas
        if (tipoComprobante !== TiposComprobantesSLV.NotaRemision) {
          objetosFiltrados = filtrarPorTipoComprobante(_facturas);
        }

        setFacturas(objetosFiltrados);
      }
    } catch (error) {
      consoleService.log('error:', error)

      dispatch(addToast({
        title: 'Buscar documentos',
        content: (typeof error == 'string' ? error : JSON.stringify(error)),
        type: ToastTypes.Warning,
      }

      ))
    }
    setBuscando(false);
  }, [dispatch, tipoComprobante, cliente, comprobante, filtrarPorTipoComprobante])

  const setDocumentos = useCallback(async () => {
    let dtes = []
    if (tipoComprobante === TiposComprobantesSLV.CreditoFiscal) {
      dtes = documentos.filter(objeto => objeto.comprobante !== documentoAnulando.comprobante);
    }
    setFacturas(dtes)
  }, [documentos, tipoComprobante, documentoAnulando]);

  useEffect(() => {
    if (documentos.length === 0) {
      buscarFacturas(null);
    } else {
      setDocumentos()
    }
  }, [buscarFacturas, documentos, setDocumentos])

  useEffect(() => {
    if (cliente !== null) {
      buscarFacturas(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cliente])



  const onBuscar = React.useCallback(async () => {
    const validationResult = validationGroupRef.current.instance.validate();
    if (!validationResult.isValid) {
      return;
    }

    let range: DateRange | null = null;
    if (buscarPorFechas) {
      range = {
        inicio: DateUtils.dateToString(startDate, formatoFechasApi),
        fin: DateUtils.dateToString(endDate, formatoFechasApi)
      }
    }
    await buscarFacturas(range);
  }, [buscarPorFechas, buscarFacturas, startDate, endDate]);
  function statusCellRender(cellData) {
    let data = '';
    if (cellData?.data?.autorizacionHacienda?.codigoGeneracion) {
      data = String(cellData?.data?.autorizacionHacienda?.codigoGeneracion).toLowerCase().toUpperCase()
    }
    return <div> {data ?? ''} </div>
  }
  React.useEffect(() => {
    if (iniciarBusqueda) {
      setIniciarBusqueda(false);
      onBuscar().then(() => {
      }
      )
    }
  }, [iniciarBusqueda, onBuscar])
  return (
    <CustomModalDevx
      id="listadoComprobantes"
      title='Listado de Comprobantes'
      onClose={onCancel}
      show
      size={modalSize}
      height={window.innerHeight * 0.8}
    >
      <ValidationGroup id={`valGroupDocumentoVentaAfectar`} ref={validationGroupRef}>
        <CContainer fluid        >
          <RowContainer>
            <CustomCol xs='12' md='8'>
              <Labeled label='Cliente'              >
                <BuscarClienteLookUp allowClear selected={cliente} onChanged={(value) => setCliente(value)} />
              </Labeled>
            </CustomCol>
            <CustomCol
              xs='12'
              md='4'
            >
              <Labeled
                label='Comprobante'
              >
                <TextBox
                  value={comprobante}
                  onValueChanged={({ value }: any) => setComprobante(value)}
                  onEnterKey={() => {
                    buscarFacturas(null)
                  }}
                >
                  <TextBoxButton
                    name="btnEye"
                    location="after"
                    options={{
                      icon: 'search',
                      onClick: () => {
                        buscarFacturas(null)
                      },
                    }}
                  />
                  <Validator>
                    <PatternRule pattern='^[0-9]{1,10}$' message='El campo comprobante es numerico.' />
                  </Validator>
                </TextBox>
              </Labeled>
            </CustomCol>
          </RowContainer>
          <RowContainer className='mt-1 mb-1'>
            <CustomCol xs='4' lg='2' >
              <Labeled
                label='Fechas'
                position='left'
              >
                <Switch switchedOffText='No' switchedOnText='Si' value={buscarPorFechas} onValueChanged={(e) => {
                  if (e.event !== undefined) {
                    setBuscarPorFechas(e.value)
                    setIniciarBusqueda(true);
                  }
                }} />
              </Labeled>
            </CustomCol>
            {buscarPorFechas &&
              <>
                <CustomCol xs='4' lg='2' className='mt-1 mb-1'>
                  <DateBox applyValueMode="instantly"
                    name='fechaInicio'
                    value={startDate}
                    onValueChanged={(e) => {
                      if (e.value) {
                        setStartDate(e.value);
                      }
                    }} >
                    <RequiredRule message='Debe ingresar la fecha de inicio s' />
                  </DateBox>
                </CustomCol>
                <CustomCol xs='4' lg='2' className='mt-1 mb-1'>
                  <DateBox applyValueMode="instantly"
                    name='fechaFin'
                    value={endDate}
                    onValueChanged={(e) => {
                      if (e.value) {
                        setEndDate(e.value);
                        setIniciarBusqueda(true);
                      }

                    }} >
                    <RequiredRule />
                  </DateBox>
                </CustomCol>
              </>
            }

          </RowContainer>
          <ValidationGroup id={'validationGroupAddDocumento'} ref={validationGroupAddDocRef}>
            <RowContainer>
              <CustomCol>
                <ValidationSummary />
              </CustomCol>
            </RowContainer>
          </ValidationGroup>

          <CRow className='mt-4'>
            <CCol xs="12" sm="12" md="12" lg="12">
              <ScrollView
                direction="horizontal"
                id="scrollTabVentas"
                scrollByContent={true}
              >
                {tipoComprobante !== '04' &&
                  <DataGrid
                    focusedRowEnabled={true}
                    keyExpr='comprobante'
                    hoverStateEnabled={true}
                    dataSource={facturas}
                    showBorders={true}
                    onRowDblClick={onRowDoubleClick}
                    height={isMobileOnly ? `${window.innerHeight * 0.7}px` : `${(window.innerHeight * 0.7).toFixed(0)}px`}
                    width='100%'
                    onContentReady={() => setloadPanelEnabled(false)}
                  >
                    <SearchPanel
                      visible={true}
                      highlightCaseSensitive={true}
                      width={window.innerWidth < 920 ? 720 : 160} />
                    <HeaderFilter visible={true} />
                    < Column type='buttons' width='40px' >
                      <DatagridButton text='+' icon='plus' hint='Selecionar' onClick={async (e) => {
                        const data = e.row.data;
                        onSelectDocument(data);
                      }} />
                    </Column>
                    <Column dataField='fecha' dataType='date' width='80px' allowSearch={false} />
                    <Column dataField='codigoGeneracion' caption='Cod. Generación' width='150px' allowSearch={false}
                      cellRender={statusCellRender} />
                    <Column dataField='numero' caption='Número' width='90px' />
                    <Column dataField='clienteNombre' caption='Cliente' width={window.innerWidth < 1360 ? 'auto' : '*'} />
                    <Column dataField='total' caption='Total' width='90px' alignment='right' allowSearch={false} />
                    <Column dataField='formaPagoDescripcion' caption='Forma Pago' width='120px' allowSearch={false} />
                    <Column dataField='puntoVenta' caption='P. Venta' width='80px' allowSearch={false} />
                    <Scrolling mode="virtual" />
                    <GridLoadPanel enabled={loadPanelEnabled} />
                  </DataGrid>
                }{
                  <DataGrid
                    focusedRowEnabled={true}
                    keyExpr="numero"
                    dataSource={facturas}
                    showBorders={true}
                    width="100%"
                    allowColumnResizing
                    columnResizingMode="widget"
                  >
                    <SearchPanel
                      visible={true}
                      highlightCaseSensitive={true}
                      width={window.innerWidth < 920 ? 720 : 160} />
                    <HeaderFilter visible={true} />
                    < Column type='buttons' width='40px' >
                      <DatagridButton text='+' icon='plus' hint='Selecionar' onClick={async (e) => {
                        const data = e.row.data;
                        onSelectDocument(data);
                      }} />
                    </Column>
                    <Column
                      dataField="fecha"
                      calculateCellValue={
                        VentasGridHelper.convertirFechaTransaccionGuiaRemision
                      }
                      dataType="date"
                      caption="Fecha"
                      width="80px"
                      allowEditing={false}
                      allowSearch={false}
                      allowFiltering
                    />
                    <Column
                      dataField="establecimiento"
                      caption="Estab"
                      width="80px"
                      allowEditing={false}
                    />
                    <Column
                      dataField="puntoEmision"
                      caption="Pto."
                      width="80px"
                      allowEditing={false}
                    />
                    <Column
                      dataField="numero"
                      caption="Nro"
                      width="80px"
                      allowEditing={false}
                    />
                    <Column
                      dataField="clienteNombre"
                      caption="Cliente"
                      allowEditing={false}
                      width="auto"
                    />
                    <Column
                      dataField="proveedorTransportistaNombre"
                      caption="Transportista"
                      allowEditing={false}
                      width="auto"
                    />
                    <Column
                      dataField="estadoDescripcion"
                      caption="Estado"
                      width="120px"
                      allowEditing={false}
                    />
                  </DataGrid>
                }

              </ScrollView>
            </CCol>
          </CRow>

        </CContainer>
      </ValidationGroup>
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={'center'}
        visible={buscando}
        showIndicator={true}
        shading={true}
        showPane={true}
        message='Buscando...'
      />
    </CustomModalDevx>
  )
}

export default BuscarDocumentos