import React, { useMemo } from 'react'
import { Draggable, SpeedDialAction } from 'devextreme-react'
import { UnidadesDatosEdicion, UnitsReport } from '../../types/types'
import { ButtonTypes } from '../../../../../../../views/componentes/globalMenu/types'
import {
  clearButtonClick,
  openTab,
  setCurrentExecutingAction,
} from '../../store/tabsReducer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import {
  changeLoaderGroups,
  setCurrentFunction,
} from '../../store/generalReducer'
import { TabState } from '../../../../../../../store/genericTabTypes'
import {
  changeFilter,
  fetchUnidades,
  setCleanResult,
  setResetSeleccion,
} from '../../store/serachReducer'
import { ToastTypes } from '../../../../../../../store/types'
import { InventarioService } from '../../../../../services/inventario.service'
import { addToast } from '../../../../../../../store/toasterReducer'
import Dialog from '../../../../../../../views/componentes/librerias/bootstrap-dialog'
import { v4 as uuidv4 } from 'uuid'
import { initDatosEdicion } from '../../store/editDataReducer'
import { defaultDataUnits } from '../nuevo'
import { ReportWithLocalDataModal } from '../../../../../../../views/componentes/xtrareports/ReportWithLocalDataModal'
import { UnidadInventario } from '../../../../../store/types'
import { CCard, CCardFooter } from '@coreui/react-pro'
import ResultadosBusqueda from './ResultadosBusqueda'
import FiltrosBusqueda, { optionsActivos } from './FiltrosBusqueda'
import BlockUi from '../../../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../../../views/componentes/loadingindicator/loadingindicator'
import { isMobile } from 'react-device-detect'
import TableLoader from '../../../../../../ventas/components/ventas/busquedaVentas/TableLoader'
import DataSource from 'devextreme/data/data_source'

enum VistasBusqueda {
  Filtros,
  ResultadosBusqueda,
  Resumen,
  Loading,
  Error,
}

interface ISearchProps extends React.PropsWithChildren {
  tab: TabState<UnidadesDatosEdicion>
  tabId: string
}

const draggingGroupName = 'appointmentsGroup'

const Search: React.FunctionComponent<ISearchProps> = (props) => {
  const { tabId, tab } = props
  const gruposLoader = useSelector((state: RootState) => {
    return state.inventarios.catalogos.unidades.general
  })
  const currentTab = useSelector((state: RootState) => {
    return state.inventarios.catalogos.unidades.tabs.current
  })
  const dispatch = useDispatch()
  const dialogRef = React.useRef<any>(null)
  const searchResults = useSelector(
    (state: RootState) =>
      state.inventarios.catalogos.unidades.search.resultados,
  )
  const searchFilter = useSelector(
    (state: RootState) => state.inventarios.catalogos.unidades.search.filter,
  )
  const selected = useSelector(
    (state: RootState) =>
      state.inventarios.catalogos.unidades.search.seleccionado,
  )
  const { getUnitsDatasource } = InventarioService.useSetDataSources()
  const DataSourceUnits = useMemo(
    () => getUnitsDatasource(searchFilter),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchFilter],
  )

  const [dataUnidadesPrint, setDataUnidadesPrint] = React.useState<
    Array<UnidadInventario>
  >([])
  const [vistaActual, setVistaActual] = React.useState<VistasBusqueda>(
    VistasBusqueda.Filtros,
  )
  const [pageIndex, setPageIndex] = React.useState<number>(0)
  const [pageSize, setPageSize] = React.useState<number>(10)
  const [confirmDelete, setConfirmDelete] = React.useState<boolean>(false)
  const [confirmEdit, setConfirmEdit] = React.useState<boolean>(false)
  const [dataSourceUnits, setDataSourceUnits] = React.useState<DataSource>(null)

  const getFilterString = React.useCallback(() => {
    const filterText = ''
    return filterText
  }, [])

  const onSetButtonAction = React.useCallback(
    (tipo: ButtonTypes | undefined) => {
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'BUSQUEDA',
          buttonType: tipo,
        }),
      )
    },
    [dispatch],
  )

  const [reporte, setReporte] = React.useState<null | 'Viewer' | 'Designer'>(
    null,
  )
  const setToast = React.useCallback(
    (mensaje: string, tipo: ToastTypes) => {
      dispatch(
        addToast({
          title: 'Inventario - Unidades',
          content: mensaje,
          type: tipo,
        }),
      )
    },
    [dispatch],
  )
  const playLoader = React.useCallback(
    (message = 'Cargando...') => {
      dispatch(changeLoaderGroups({ show: true, mensaje: message }))
    },
    [dispatch],
  )

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoaderGroups({ show: false, mensaje: '' }))
  }, [dispatch])

  const oninitSearch = React.useCallback(() => {
    const data = { ...searchFilter }
    if (data.initital) {
      data.nombre = ''
      data.estado = optionsActivos[0]
      data.initital = false
      dispatch(
        changeFilter({
          ...data,
        }),
      )
    }
  }, [dispatch, searchFilter])

  const onSearch = React.useCallback(async () => {
    onSetButtonAction(ButtonTypes.find)
    playLoader('Buscando...')
    try {
      if (isMobile) {
        setVistaActual(VistasBusqueda.Loading)
      }
      await DataSourceUnits.reload()
      setDataSourceUnits(DataSourceUnits)
      if (isMobile) {
        setVistaActual(VistasBusqueda.ResultadosBusqueda)
      }
    } catch (error) {
      if (isMobile) {
        setVistaActual(VistasBusqueda.Error)
      }
      setToast(error.message, ToastTypes.Danger)
    }
    stopLoader()
    onSetButtonAction(undefined)
  }, [DataSourceUnits, setToast, onSetButtonAction, playLoader, stopLoader])

  const onSearchPrint = React.useCallback(
    async (tipo: null | 'Viewer' | 'Designer') => {
      onSetButtonAction(ButtonTypes.print)
      try {
        const search_data = {
          estado: searchFilter.estado?.value,
          nombre: searchFilter.nombre,
        }
        playLoader('Genrando Reporte...')
        const toAny: any = fetchUnidades(search_data)
        const res = await dispatch(toAny)
        stopLoader()
        if (res !== null && res !== undefined && res['payload'].length >= 0) {
          setDataUnidadesPrint(res['payload'])
          setReporte(tipo)
        } else {
          setToast(res, ToastTypes.Danger)
        }
      } catch (error) {
        stopLoader()
        setToast(error.message, ToastTypes.Danger)
      }
      onSetButtonAction(undefined)
    },
    [dispatch, searchFilter, setToast, playLoader, stopLoader, onSetButtonAction],
  )

  const onHandleEdit = React.useCallback(
    async (unidadData: any) => {
      const id = uuidv4()
      dispatch(
        initDatosEdicion({
          key: id,
          data: {
            ...defaultDataUnits,
            codigo: unidadData?.codigo ?? 0,
            loading: true,
          },
        }),
      )
      dispatch(openTab({ key: id, unidad: unidadData }))
    },
    [dispatch],
  )

  const onHandleRemove = React.useCallback(async () => {
    onSetButtonAction(ButtonTypes.delete)
    playLoader('Eliminando Registro...')
    try {
      if (isMobile) {
        setVistaActual(VistasBusqueda.Loading)
      }
      const id = selected.row?.codigo ?? 0
      const data = await InventarioService.deleteUnidades(id)
      if (isMobile) {
        setVistaActual(VistasBusqueda.ResultadosBusqueda)
      }
      if (data !== null && data !== undefined && data['error'] === false) {
        onSearch()
        dispatch(setResetSeleccion())
        setToast(data['message'], ToastTypes.Success)
      } else {
        setToast(data['message'], ToastTypes.Danger)
      }
    } catch (error) {
      if (isMobile) {
        setVistaActual(VistasBusqueda.Error)
      }
      setToast(error.message, ToastTypes.Danger)
    }
    stopLoader()
    onSetButtonAction(undefined)
  }, [
    selected,
    setToast,
    dispatch,
    onSearch,
    onSetButtonAction,
    playLoader,
    stopLoader,
  ])

  const onUndotFilter = React.useCallback(() => {
    const data = { ...searchFilter }
    data.nombre = ''
    data.estado = optionsActivos[0]
    dispatch(changeFilter({ ...data }))
    dispatch(setCleanResult())
    dispatch(setResetSeleccion())
    setDataUnidadesPrint([])
  }, [dispatch, searchFilter])

  const onBroomResulta = React.useCallback(() => {
    setDataSourceUnits(null)
  }, [])

  const onConfirmEdit = React.useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea Editar el registro: ${selected.row?.nombre ?? '-'}?`,
      actions: [
        Dialog.Action(
          <span>
            <u>A</u>ceptar
          </span>,
          (dialog) => {
            dialog.hide()
            onHandleEdit(selected.row)
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setConfirmEdit(false)
    return
  }, [onHandleEdit, selected])

  const onConfirmDelete = React.useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea Eliminar el Registro: ${selected.row?.nombre ?? '-'}?`,
      actions: [
        Dialog.Action(
          <span>
            <u>A</u>ceptar
          </span>,
          (dialog) => {
            dialog.hide()
            onHandleRemove()
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setConfirmDelete(false)
    return
  }, [onHandleRemove, selected])

  const handleButtonClick = React.useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.delete:
          setConfirmDelete(true)
          break
        case ButtonTypes.find:
          onSearch()
          break
        case ButtonTypes.edit:
          setConfirmEdit(true)
          break
        case ButtonTypes.broom:
          onBroomResulta()
          break
        case ButtonTypes.undo:
          onUndotFilter()
          break
        case ButtonTypes.print:
          if (dataUnidadesPrint.length > 0) {
            setReporte('Viewer')
          } else {
            onSearchPrint('Viewer')
          }
          break
        case ButtonTypes.print_design:
          if (dataUnidadesPrint.length > 0) {
            setReporte('Designer')
          } else {
            onSearchPrint('Designer')
          }
          break
        default:
          break
      }
      dispatch(setCurrentFunction(''))
      dispatch(clearButtonClick(tabId))
    },
    [
      dispatch,
      tabId,
      onSearch,
      dataUnidadesPrint,
      onSearchPrint,
      onUndotFilter,
      onBroomResulta
    ],
  )

  const handleOptionChange = React.useCallback(async (e) => {
    if (e.fullName === 'paging.pageSize') {
      setPageSize(e.value)
    }
    if (e.fullName === 'paging.pageIndex') {
      setPageIndex(e.value)
    }
  }, [])

  const onParseData = React.useCallback(
    (unidades: Array<UnidadInventario> = []) => {
      let registros: Array<UnitsReport> = []
      if (unidades.length > 0) {
        registros = unidades.map((x) => {
          return {
            Code: x?.codigo ?? 0,
            Description: x?.nombre ?? '',
            State: x?.estadoCodigo ?? 0,
            GrupoDescription: x?.grupoNombre ?? '',
            Abreviature: x?.siglas ?? '',
          }
        })
      }
      return registros
    },
    [],
  )

  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab.globalButtonClick])

  React.useEffect(() => {
    oninitSearch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (confirmDelete) {
      onConfirmDelete()
    }
  }, [confirmDelete, onConfirmDelete])

  React.useEffect(() => {
    if (confirmEdit) {
      onConfirmEdit()
    }
  }, [confirmEdit, onConfirmEdit])

  if (isMobile) {
    return (
      <>
        <ReportWithLocalDataModal
          show={reporte !== null}
          onClose={() => setReporte(null)}
          data={onParseData(searchResults)}
          fileName="StaticUnidadesListado"
          mode={reporte ?? 'Viewer'}
          parameters={{ Reporte_Filtro: getFilterString() }}
          template="StaticUnidadesListado"
          key="reportDesigner"
        />
        <CCard>
          <Dialog ref={dialogRef} />
          {vistaActual === VistasBusqueda.Filtros && (
            <>
              <FiltrosBusqueda onSearchEnter={onSearch} />
            </>
          )}
          {vistaActual === VistasBusqueda.ResultadosBusqueda && (
            <>
              <ResultadosBusqueda
                onDBClick={() => setConfirmEdit(true)}
                handleOptionChange={handleOptionChange}
                data={dataSourceUnits}
                pageIndex={pageIndex}
                pageSize={pageSize}
                onDelete={() => setConfirmDelete(true)}
              />
            </>
          )}
          {vistaActual === VistasBusqueda.Error && <></>}
          {vistaActual === VistasBusqueda.Loading && (
            <CCardFooter>
              <TableLoader />
            </CCardFooter>
          )}
          {currentTab === tabId && (
            <Draggable id="list" data="dropArea" group={draggingGroupName}>
              <SpeedDialAction
                icon="filter"
                label="Filtros"
                visible={true}
                index={1}
                onClick={() => {
                  setVistaActual(VistasBusqueda.Filtros)
                }}
              />
              <SpeedDialAction
                icon="search"
                label="Busqueda"
                visible={true}
                index={2}
                onClick={() => {
                  setVistaActual(VistasBusqueda.ResultadosBusqueda)
                }}
              />
            </Draggable>
          )}
        </CCard>
      </>
    )
  }

  return (
    <>
      <ReportWithLocalDataModal
        show={reporte !== null}
        onClose={() => setReporte(null)}
        data={onParseData(searchResults)}
        fileName="StaticUnidadesListado"
        mode={reporte ?? 'Viewer'}
        parameters={{ Reporte_Filtro: getFilterString() }}
        template="StaticUnidadesListado"
        key="reportDesigner"
      />
      <Dialog ref={dialogRef} />
      <div id="unidadesContent">
        <BlockUi
          tag="div"
          loader={LoadingIndicator}
          blocking={gruposLoader.loader.show}
          message={gruposLoader.loader.mensaje}
        >
          <CCard>
            <FiltrosBusqueda onSearchEnter={onSearch} />
            <ResultadosBusqueda
              onDBClick={() => setConfirmEdit(true)}
              handleOptionChange={handleOptionChange}
              data={dataSourceUnits}
              pageIndex={pageIndex}
              pageSize={pageSize}
              onDelete={() => setConfirmDelete(true)}
            />
          </CCard>
        </BlockUi>
      </div>
    </>
  )
}

export default React.memo(Search)
