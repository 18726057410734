/* eslint-disable prefer-const */
import React, { useState } from 'react';
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CCollapse,
  CLink,
  CButton, CTooltip
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cuentasService } from "../../../services/cuentas.service"
import { useDispatch, useSelector } from 'react-redux'
import Tabla from "./../tabla";
import { setCurrentFunction, setData, setDataBack } from "./../../store/modalCuentasReducer"
import { setModal } from "./../../../store/cuentasReducer"
import { RootState } from '../../../../../../../store/store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextBox } from 'devextreme-react/text-box';
import { CheckBox } from 'devextreme-react/check-box';
import { isMobileOnly } from 'react-device-detect'
import { cilChevronBottom, cilChevronTop } from '@coreui/icons'

type IBuscarProps = {
  referencia: any,
  actionDelete: () => void,
  cargarCuentas: (evt) => void,
  codModulo: number
  setToast: (texto, type) => void
  playLoader: () => void
  stopLoader: () => void
}

const dpEstados = [
  { value: "", label: "Selecciona un estado" },
  { value: 1, label: "ACTIVO" },
  { value: 2, label: "INACTIVO" },
];

const Buscar = (props: IBuscarProps) => {

  const { cargarCuentas, setToast, playLoader, stopLoader, codModulo } = props;
  const inputreferencia = React.useRef<any>();

  const dispatch = useDispatch()
  const registros = useSelector((state: RootState) => state.contabilidad.modalCuentas.registros);
  const currentFunction = useSelector((state: RootState) => state.contabilidad.modalCuentas.currentFunction);


  const [CODIGO, setCODIGO] = useState<any>("");
  const [DESCRIPCION, setDESCRIPCION] = useState<any>("");
  const [ESTADO, setESTADO] = useState<any>(1);
  const [collapsed, setCollapsed] = React.useState(true)
  const [IDIOMA, setIDIOMA] = React.useState<any>(false)

  const handleReset = React.useCallback(() => {
    setCODIGO("")
    setDESCRIPCION("")
    setESTADO(dpEstados[1])
  }, [dpEstados]);

  const closeModal = React.useCallback(() => {
    dispatch(setModal({
      modalName: "modalPlan",
      open: false,
      codModulo: null
    }))
  }, [dispatch]);

  const handleSubmit = React.useCallback(async () => {

    playLoader()
    dispatch(setData([]));
    dispatch(setDataBack([]));
    let categoria_: number = 89;
    // cambiar a 89 cuando el modulo sea 47
    if (Number(codModulo) === 47) {
      categoria_ = 88;
    }
    const data_request = {
      numero: CODIGO,
      descripcion: DESCRIPCION,
      estado: ESTADO,
      categoria: categoria_
    }
    try {
      const data = await cuentasService.cargarTodos(data_request);
      stopLoader()
      if (data['error'] === false) {
        setToast(data['message'], "success");

        if (data['auto'].length > 0) {
          if (isMobileOnly) {
            setCollapsed(false);
          }
          let item: Array<any>
          item = data['auto'].map(function (item: any) {
            return {
              ...item
            }
          })

          const planCuentas: any = item;
          const planCuentas_object: any = [];
          //SOLO CUENTAS DE DETALLE

          planCuentas.forEach(function (plCuenta) {
            if (Number(plCuenta["codigoCategoria"]) !== 88) {
              //if (plCuenta["descripcionCategoria"] !== "DE GRUPO") { // PLA_CATEGORIA_DES
              let plancuentas = [];
              if (IDIOMA === true) {
                plancuentas = {
                  ...plCuenta,
                  PLA_SHOW: plCuenta["descripcionIdioma"],
                };
              } else {
                plancuentas = {
                  ...plCuenta,
                  PLA_SHOW: plCuenta["descripcion"],
                };
              }
              planCuentas_object.push(plancuentas);
              //}
            }
          });

          dispatch(setData(planCuentas_object));
          dispatch(setDataBack(planCuentas_object));
          handleReset();
        } else {
          if (isMobileOnly) {
            setCollapsed(true);
          }
        }
      } else {
        if (isMobileOnly) {
          setCollapsed(true);
        }
        setToast(data['message'], "warning");
      }
      return await data;
    } catch (error) {
      console.error(error);
      stopLoader()
      setToast(JSON.stringify(error), "danger")
    }
  }, [
    CODIGO, DESCRIPCION, ESTADO, IDIOMA,
    dispatch, playLoader, codModulo,
    setToast, stopLoader, handleReset
  ]);



  const cargarCuenta = React.useCallback((cuenta: any) => {
    cargarCuentas(cuenta)
    closeModal();
  }, [closeModal, cargarCuentas]);



  const onChangeIdioma = React.useCallback((_idioma) => {

    if (registros.length > 0) {
      playLoader()
      const planCuentas: any = registros;
      const planCuentas_object: any = [];

      planCuentas.forEach(function (plCuenta) {
        let plancuentas: any = [];
        if (plCuenta["descripcionCategoria"] !== "DE GRUPO") {
          if (_idioma === true) {
            plancuentas = {
              ...plCuenta,
              PLA_SHOW: plCuenta["descripcionIdioma"],
            };
          } else {
            plancuentas = {
              ...plCuenta,
              PLA_SHOW: plCuenta["descripcion"],
            };
          }
        }
        planCuentas_object.push(plancuentas);
      });
      stopLoader()
      dispatch(setData(planCuentas_object));
      dispatch(setDataBack(planCuentas_object));
    }
  }, [dispatch, playLoader, registros, stopLoader]);

  React.useEffect(() => {
    if (currentFunction === "Buscar") {
      dispatch(setCurrentFunction(""))
      handleSubmit()
    }
  }, [currentFunction]);

  React.useEffect(() => {
    setTimeout(function () {
      inputreferencia.current.instance.focus();
    }, 300);
  }, []);

  const renderButton = () => {
    return <CTooltip
      key={"tool-add"}
      placement="top"
      content={"Para elejir haz doble click sobre la cuenta."}
    >
      <CButton id="btnBuscarCuentas" color="primary" className="ms-1" size='sm' >
        <FontAwesomeIcon
          size="sm"
          icon={['fal', "info"]} style={{ fontWeight: 1 }}
        />
      </CButton>
    </CTooltip>
  }

  return (
    <>

      <CCard>
        <CCardHeader onClick={() => setCollapsed(!collapsed)} style={{ display: 'flex', justifyContent: 'space-between' }}>
          <strong> {"Buscar"} </strong>
          <CLink className="card-header-action" >
            <CIcon icon={collapsed ? cilChevronBottom : cilChevronTop} />
          </CLink>
        </CCardHeader>
        <CCollapse visible={collapsed}>
          <CCardBody>
            <CRow>
              <CCol md='8' lg="8" sm="12">
                <div className="dx-field m-1">
                  <div className="dx-field-label">{"Código:"}</div>
                  <div className="dx-field-value">
                    <TextBox
                      ref={inputreferencia}
                      width="70%"
                      value={CODIGO}
                      placeholder=""
                      onValueChanged={(data) => { setCODIGO(data.value) }}
                      onEnterKey={() => handleSubmit()}
                    >
                    </TextBox>
                  </div>
                </div>
              </CCol>
              <CCol md='4' lg="4" sm="12">
                <div className="dx-field-value d-flex align-items-start">
                  <CheckBox
                    width={100}
                    text="Idioma Alterno"
                    value={IDIOMA}
                    onValueChanged={() => {
                      setIDIOMA(!IDIOMA)
                      onChangeIdioma(!IDIOMA)
                    }}
                    elementAttr={{
                      autoFocus: true
                    }}
                  />
                </div>
              </CCol>
            </CRow>
            <CRow>
              <CCol md='8' lg="8" sm="12">
                <div className="dx-field m-1">
                  <div className="dx-field-label">{"Descripción:"}</div>
                  <div className="dx-field-value">
                    <CRow>
                      <CCol>
                        <TextBox
                          width="100%"
                          value={DESCRIPCION}
                          placeholder=""
                          onValueChanged={(data) => { setDESCRIPCION(data.value) }}
                          onEnterKey={() => handleSubmit()}
                        />
                      </CCol>


                    </CRow>
                  </div>
                </div>
              </CCol>
              <CCol>
                {renderButton()}
              </CCol>
            </CRow>
          </CCardBody>

        </CCollapse>



      </CCard>



      <CRow>
        <CCol>

          <Tabla
            name="TablaPlanCuentas"
            data={registros}
            actionDelete={() => props.actionDelete()}
            actionCargaCuenta={(evt) => cargarCuenta(evt)}
          />

        </CCol>
      </CRow>

    </>
  );
}

export default React.memo(Buscar);
