import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../../store/store";
import { LoaderInfo } from "../../../../../store/types";
import { PageState, BotonMenu, Seleccionado, Registros, OptionGrupos } from "./types";

const initialState: PageState = {
  modulo: 'contabilidad',
  currentAction: "Buscar",
  currentFunction: "",
  currentExecute: null,
  aplicacion: 'cuentasFlujo',
  acciones: [],
  registros: [],
  refrescaTabla: false,
  seleccionado: {
    index: null,
    row: null,
    selectedRow: null,
  },
  grupos: [],
  loader: {
    show: false,
    mensaje: ''
  },
}

const cuentasSlice = createSlice({
  name: "cuentasFlujo",
  initialState: initialState,
  reducers: {
    obtenerAcciones(state, action: PayloadAction<Array<BotonMenu>>) {
      state.acciones = action.payload
    },
    setCurrentFunction(state, action: PayloadAction<string>) {
      state.currentFunction = action.payload
    },
    setData(state, action: PayloadAction<Array<Registros>>) {
      state.registros = action.payload
    },
    setCurrentAccion(state, action: PayloadAction<any>) {
      state.currentAction = action.payload
    },
    setSeleccionarDato(state, action: PayloadAction<Seleccionado>) {
      const toAdd = {
        index: action.payload.index,
        row: action.payload.row,
        selectedRow: action.payload.selectedRow,
      }
      state.seleccionado = toAdd
    },
    setResetSeleccion(state) {
      const toAdd = {
        index: null,
        row: null,
        selectedRow: null,
      }
      state.seleccionado = toAdd
    },
    setGruposOptions(state, action: PayloadAction<Array<OptionGrupos>>) {
      state.grupos = action.payload
    },
    changeLoaderCuentasFlujo(state, action: PayloadAction<LoaderInfo>) {
      state.loader = action.payload
    },
    setCurrentExecute(state, action: PayloadAction<any>) {
      state.currentExecute = action.payload
    },
  }
})

export const { setCurrentExecute, changeLoaderCuentasFlujo, setCurrentFunction, obtenerAcciones, setData, setCurrentAccion, setSeleccionarDato, setResetSeleccion, setGruposOptions } = cuentasSlice.actions;
export const cuentasReducer = cuentasSlice.reducer;