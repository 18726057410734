import { RequestHelper } from "../helpers/requestHelper"
import { RequestHelperDotNet } from "../helpers/requestHelperDotNet";
import { utilidades } from "../helpers/utilidades";
import { SavePersonal } from "../modulos/nomina/pages/personal/types/types";
import { Usuario } from "../store/types";

export const cuentaService = {
  getDatosUsuario, getTiposIdentificacion, getEstadosCiviles, getOperadoras, usuarioActualizar, fileUpload, usuarioModificaar
}


async function getDatosUsuario(objeto: any): Promise<any> {
  try {
    if (utilidades.canUseNetApi()) {
      const _usuario = await RequestHelperDotNet.get<any>('Users', '', '', null);
      const usuario: Usuario = {
        codigo: _usuario.codigo,
        nombres: _usuario.nombres,
        apellidos: _usuario.apellidos,
        idsesion: _usuario.usuarioNombre,
        email: _usuario.email,
        firma: _usuario.firma,
        rol: _usuario.tipo,
        asistente: _usuario.asistente,
        cedula: _usuario.identificacion,
        telefono: _usuario.telefono,
        direccion: _usuario.direccion,
        ciudad: _usuario.ciudad,
        codciudad: _usuario.ciudadCodigo,
        codprovincia: _usuario.ciudadProvinciaCodigo,
        provincia: '',
        pais: '',
        privilegio: _usuario.tipo,
      };
      return usuario;
    } else {
      const data = await RequestHelper.postAll<any>('cuenta', 'usuario/cargar', objeto);
      return data;
    }

  } catch (error) {
    return error;
  }
}

async function getTiposIdentificacion(objeto: any): Promise<any> {
  try {
    const data = await RequestHelper.get<any>('cuenta', 'tipoId/listar', "", objeto);
    return data;
  } catch (error) {
    return error;
  }
}

async function getEstadosCiviles(objeto: any): Promise<any> {
  try {
    const data = await RequestHelper.get<any>('cuenta', 'estadocivil/listar', "", objeto);
    return data;
  } catch (error) {
    return error;
  }
}

async function getOperadoras(objeto: any): Promise<any> {
  try {
    const data = await RequestHelper.get<any>('generales', 'operadoras/listar', "", objeto);
    return data;
  } catch (error) {
    return error;
  }
}

async function usuarioActualizar(objeto: SavePersonal): Promise<any> {
  try {
    const data = await RequestHelper.postAll<any>('cuenta', 'usuario/save', objeto);
    return data;
  } catch (error) {
    return error;
  }
}

async function fileUpload(objeto: any): Promise<boolean> {
  try {
    const data = await RequestHelper.postData<boolean>('upload', 'index.php', objeto);
    return data;
  } catch (error) {
    return error;
  }
}

async function usuarioModificaar(objeto: any): Promise<any> {
  try {
    const data = await RequestHelper.postAll<any>('cuenta', 'usuario/modificar', objeto);
    return data;
  } catch (error) {
    return error;
  }
}

