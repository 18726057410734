import { combineReducers } from '@reduxjs/toolkit'
import { tabsReducer } from './tabsReducer'
import { generalReducer } from './generalReducer'
import { KardexState } from '../types/types'
import { searchReducer } from './serachReducer'

export const KardexReducer = combineReducers<KardexState>({
  general: generalReducer,
  tabs: tabsReducer,
  search: searchReducer,
})
