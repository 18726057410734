import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { setCurrentExecutingAction } from '../store/tabsReducer'
import { ButtonTypes } from '../../../../../views/componentes/globalMenu/types'
import { setSearchLoader } from '../store/searchReducer'
import { setTabLoader } from '../store/editDataReducer'

function useLoaderCandidates() {
  const dispatch = useDispatch()

  const showLoaderTabs = useCallback(
    (
      showLoader: boolean,
      tabKey: string = 'BUSQUEDA',
      message: string = '',
      button: ButtonTypes = undefined,
    ) => {
      if (tabKey === 'BUSQUEDA')
        dispatch(
          setSearchLoader({
            show: showLoader ? true : false,
            mensaje: message,
          }),
        )
      else
        dispatch(
          setTabLoader({
            key: tabKey,
            loader: { show: showLoader ? true : false, mensaje: message },
          }),
        )

      dispatch(
        setCurrentExecutingAction({
          tabKey: tabKey,
          buttonType: button,
        }),
      )
    },
    [dispatch],
  )

  return { showLoaderTabs }
}

export { useLoaderCandidates }
