import config from '../../../config/config'
import { RequestHelper } from '../../../helpers/requestHelper'
import { SesionService } from '../../../services/sesion.service'
import {
  DotNetPagedResult,
  PagedResult,
  PaginationInfo,
} from '../../../store/types'
import { Cliente, TipoCliente } from '../types/types'
import CustomStore from 'devextreme/data/custom_store'
import DataSource from 'devextreme/data/data_source'
import { utilidades } from '../../../helpers/utilidades'
import { RequestHelperDotNet } from '../../../helpers/requestHelperDotNet'

export const ClientesService = {
  consultarClientes,
  getCliente,
  getTiposClienntes,
  modificarCliente,
  consultarClientesPaged,
  getClientesDatasource,
  modificarConsumidorFinal,
  modificarRemitenteMail,
}

async function getCliente(
  identificacion: string,
  codigo: number = 0,
): Promise<Cliente> {
  const cacheKey = 'consumidorFinalVentas'
  if (
    identificacion === config.rucConsumidorFinal ||
    (identificacion === '' && codigo === 0)
  ) {
    const cached = sessionStorage.getItem(cacheKey)
    if (cached) {
      return JSON.parse(cached)
    } else {
      identificacion = config.rucConsumidorFinal
    }
  }
  let cliente: Cliente | null
  if (utilidades.canUseNetApi()) {
    if (identificacion.trim().length === 0) {
      const apiData = await RequestHelperDotNet.get<any>(
        'Customers',
        'Customers',
        codigo.toString(),
        null,
      )
      cliente = {
        codigo: apiData.code,
        identificacion: apiData.identification,
        nombres: apiData.names,
        nombreComercial: apiData.tradename,
        direccion: apiData.direction,
        telefono: apiData.phone,
        email: apiData.email,
        descuento: apiData.discount,
        ciudad: apiData.city,
        ciudadCodigo: apiData.cityCode,
        tipoCliente: apiData.typeCustomerCode,
        tipoIdentificacion: apiData.identificationType,
        nrc: apiData.nrc,
        dui: apiData.dui,
        tipoNegocio: apiData.businessType,
      }
    } else {
      const apiData = await RequestHelperDotNet.get<any>(
        'Customers',
        'Customers/GetById',
        '',
        { id: identificacion },
      )
      cliente = {
        codigo: apiData.codigo,
        identificacion: apiData.identificacion,
        nombres: apiData.nombres,
        nombreComercial: apiData.nombreComercial,
        direccion: apiData.direccion,
        telefono: apiData.telefono,
        email: apiData.email,
        descuento: apiData.descuento,
        ciudad: apiData.ciudad,
        ciudadCodigo: apiData.ciudadCodigo,
        tipoCliente: apiData.tipoClienteCodigo,
        tipoIdentificacion: apiData.identificacionTipo,
        nrc: apiData.nrc,
        dui: apiData.dui,
        tipoNegocio: apiData.businessType,
      }
    }
  } else {
    const clientes = await consultarClientes({
      nombre: '',
      identificacion: identificacion,
      codigo: codigo,
    })

    if (codigo > 0) {
      cliente = clientes.length > 0 ? clientes[0] : null
    } else {
      cliente =
        clientes.find((x) => x.identificacion === identificacion) ?? null
    }
  }

  if (!cliente) {
    throw 'No se ecuentra un cliente con identificación ' + identificacion
  }
  if (identificacion === config.rucConsumidorFinal) {
    sessionStorage.setItem(cacheKey, JSON.stringify(cliente))
  }

  return cliente
}

async function consultarClientes(
  { nombre, identificacion = '', codigo = 0 } = { nombre: 'CONSUMIDOR FINAL' },
): Promise<Array<Cliente>> {
  const query: any = {}
  if (identificacion && identificacion.length > 0) {
    query.identificacion = identificacion
  }
  if (nombre && nombre.length > 0) {
    query.nombre = nombre
  }
  if (codigo && codigo > 0) {
    query.codigo = codigo
  }
  if (codigo > 0) {
    const c = await RequestHelper.get<any>(
      'clientes',
      'clientes/listar',
      '',
      query,
    )

    const cliente: Cliente = {
      codigo: parseInt(c.codigo),
      identificacion: c.identificacion,
      nombres: c.nombres,
      nombreComercial: c.rcomercial,
      direccion: c.direccion,
      telefono: c.telefono,
      email: c.email,
      descuento: parseFloat(c.descuento),
      ciudad: c.ciudad,
      ciudadCodigo: parseInt(c.ciucodigo),
      tipoCliente: parseInt(c.tipo),
      tipoIdentificacion: c.tipo_id,
      nrc: c.nrc,
      dui: c.dui,
      tipoNegocio: parseInt(c.tipoNegocio),
    }
    console.log('if cliente---', cliente)

    return [cliente]
  } else {
    const clientesApi = await RequestHelper.get<Array<any>>(
      'clientes',
      'clientes/listar',
      '',
      query,
    )

    const clientes = clientesApi.map((c) => {
      const cliente: Cliente = {
        codigo: parseInt(c.codigo),
        identificacion: c.identificacion,
        nombres: c.nombres,
        nombreComercial: c.rcomercial,
        direccion: c.direccion,
        telefono: c.telefono,
        email: c.email,
        descuento: parseFloat(c.descuento),
        ciudad: c.ciudad,
        ciudadCodigo: parseInt(c.ciucodigo),
        tipoCliente: parseInt(c.tipo),
        tipoIdentificacion: c.tipo_id,
        nrc: c.nrc,
        dui: c.dui,
        tipoNegocio: parseInt(c.tipoNegocio),
      }
      return cliente
    })

    return clientes
  }
}

async function consultarClientesPaged(
  { nombre, identificacion = '' } = { nombre: 'CONSUMIDOR FINAL' },
  pagination: PaginationInfo,
): Promise<PagedResult<Cliente>> {
  return consultarClientesPagedApiNet(
    { nombre: nombre, identificacion: identificacion },
    pagination,
  )
  // if (utilidades.canUseNetApi()) {
  //   return consultarClientesPagedApiNet(
  //     { nombre: nombre, identificacion: identificacion },
  //     pagination
  //   );
  // } else {
  //   return consultarClientesPagedApiPhp(
  //     { nombre: nombre, identificacion: identificacion },
  //     pagination
  //   );
  // }
}

async function consultarClientesPagedApiNet(
  { nombre, identificacion = '' } = { nombre: 'CONSUMIDOR FINAL' },
  pagination: PaginationInfo,
): Promise<PagedResult<Cliente>> {
  const query: any = {
    getPaged: true,
    searchMode: 'Contains',
    pageSize: pagination.size,
    pageIndex: isNaN(pagination.current) ? 0 : pagination.current,
  }
  if (identificacion && identificacion.length > 0) {
    query.by = 'identificacion'
    query.queryValue = identificacion
  }
  if (nombre && nombre.length > 0) {
    query.by = 'nombre'
    query.queryValue = nombre
  }
  const clientesApi = await RequestHelperDotNet.get<DotNetPagedResult<any>>(
    'Customers',
    'Customers/Query',
    '',
    query,
  )
  const clientes = clientesApi.data.map((c) => {
    const cliente: Cliente = {
      codigo: c.code,
      identificacion: c.identification,
      nombres: c.names,
      nombreComercial: c.tradename,
      direccion: c.direction,
      telefono: c.phone,
      email: c.email,
      descuento: c.discount,
      ciudad: c.city,
      ciudadCodigo: c.cityCode,
      tipoCliente: c.typeCustomerCode,
      tipoIdentificacion: c.identificationType,
      nrc: c.nrc,
      dui: c.dui,
      tipoNegocio: c.businessType,
    }
    return cliente
  })

  const pagedResult: PagedResult<Cliente> = {
    currentPage: clientesApi.page,
    from: 0,
    to: 0,
    count: clientesApi.count,
    result: clientes,
  }
  return pagedResult
}

async function consultarClientesPagedApiPhp(
  { nombre, identificacion = '' } = { nombre: 'CONSUMIDOR FINAL' },
  pagination: PaginationInfo,
): Promise<PagedResult<Cliente>> {
  const query: any = {
    page: pagination.current,
    limit: pagination.size,
    isPaged: true,
  }
  if (identificacion && identificacion.length > 0) {
    query.identificacion = identificacion
  }
  if (nombre && nombre.length > 0) {
    query.nombre = nombre
  }

  const pagedApiResult = await RequestHelper.get<any>(
    'clientes',
    'clientes/listar',
    '',
    query,
  )

  const clientesApi = pagedApiResult.auto as Array<any>

  const clientes = clientesApi.map((c) => {
    const cliente: Cliente = {
      codigo: parseInt(c.codigo),
      identificacion: c.identificacion,
      nombres: c.nombres,
      nombreComercial: c.rcomercial,
      direccion: c.direccion,
      telefono: c.telefono,
      email: c.email,
      descuento: parseFloat(c.descuento),
      ciudad: c.ciudad,
      ciudadCodigo: parseInt(c.ciucodigo),
      tipoCliente: parseInt(c.tipo),
      tipoIdentificacion: c.tipo_id,
    }
    return cliente
  })

  const pagedResult: PagedResult<Cliente> = {
    currentPage: parseInt(pagedApiResult.paginaActual),
    from: parseInt(pagedApiResult.desde),
    to: parseInt(pagedApiResult.hasta),
    count: parseInt(pagedApiResult.totalRegistros),
    result: clientes,
  }
  return pagedResult
}

async function getTiposClienntes(): Promise<Array<TipoCliente>> {
  const query: any = {}
  const cacheKey = 'tiposClientes' + JSON.stringify(query)
  const cached = sessionStorage.getItem(cacheKey)
  if (cached) {
    return JSON.parse(cached)
  } else {
    const tiposClienteApi = await RequestHelper.get<Array<any>>(
      'clientes',
      'clientes/tipos',
      '',
      query,
    )
    const tipos = [] as Array<TipoCliente>
    tiposClienteApi
      .filter((x) => x.codigo !== -1)
      .forEach((tipoClienteApi) => {
        if (tipoClienteApi.codigo !== '-1') {
          const tipo: TipoCliente = {
            codigo: parseInt(tipoClienteApi.codigo),
            descripcion: tipoClienteApi.descripcion,
            estado: Number.parseInt(tipoClienteApi.codigoEstado),
            estadoDescripcion: tipoClienteApi.descripcionEstado,
            porcentajeDescuento: Number.parseFloat(
              tipoClienteApi.porcentajeDescuento,
            ),
          }
          tipos.push(tipo)
        }
      })
    sessionStorage.setItem(cacheKey, JSON.stringify(tipos))
    return tipos
  }
}

async function modificarCliente(cliente: Cliente): Promise<any> {
  try {
    const sesion = SesionService.getCurrentSesion()

    const data: any = {
      codigo: cliente.codigo,
      tipo_identificacion: cliente.tipoIdentificacion,
      tipo: cliente.tipoCliente,
      nombre: cliente.nombres,
      rcomercial: cliente.nombreComercial,
      direccion: cliente.direccion,
      telefono: cliente.telefono ?? '',
      email: cliente.email,
      ciudad: cliente.ciudad,
      ciucodigo: cliente.ciudadCodigo,
      identificacion: cliente.identificacion,
    }
    if (cliente?.identificacion) {
      data.nrc = cliente?.nrc ?? ''
    }
    if (cliente?.dui) {
      data.dui = cliente?.dui ?? ''
    }
    if (cliente?.tipoNegocio) {
      data.tipoNegocio = cliente?.tipoNegocio ?? null
    }
    data.paisCodigo = 1
    if (sesion?.empresa?.codigoPais) {
      data.paisCodigo = sesion?.empresa?.codigoPais
    }
    const apiresult = await RequestHelper.postUrlEncodedAll<any>(
      'clientes',
      'clientes/ingresar',
      data,
    )
    return apiresult
  } catch (error) {
    return error
  }
}

function getClientesDatasource() {
  const regex = new RegExp('^[0-9]{1,13}|[0-9]{1,14}$')
  const customCache: any = {}
  const store = new CustomStore({
    key: 'codigo',
    byKey: async (key) => {
      if (customCache[key]) {
        return customCache[key]
      } else {
        const cliente = await getCliente('', key)
        customCache[key] = cliente
        return cliente
      }
    },
    load: async (loadOptions) => {
      if (
        loadOptions.filter &&
        loadOptions.filter.length === 3 &&
        Array.isArray(loadOptions)
      ) {
        //Si hay filtro devolvemos null y ya
        const filter = loadOptions.filter
        if (filter[0] === 'codigo') {
          //Recuperar el cliente por codigo
          if (filter[2] === 0)
            return {
              data: [],
              totalCount: 0,
            } //no existe el cliente con codigo 0
          const cliente = await getCliente('', filter[2])
          return {
            data: cliente,
            totalCount: 1,
          }
        }
        return {
          data: [],
          totalCount: 0,
        }
      }
      const paginInfo: PaginationInfo = {
        current: (loadOptions.skip ?? 0) / (loadOptions.take ?? 0) + 1,
        size: loadOptions.take ?? 10,
      }

      let pagedResult: PagedResult<Cliente>
      if (regex.test(loadOptions.searchValue)) {
        pagedResult = await consultarClientesPaged(
          { nombre: '', identificacion: loadOptions.searchValue.toString() },
          paginInfo,
        )
      } else {
        pagedResult = await consultarClientesPaged(
          { nombre: loadOptions.searchValue },
          paginInfo,
        )
      }
      return {
        data: pagedResult.result,
        totalCount: pagedResult.count,
      }
    },
  })
  const source = new DataSource({
    paginate: true,
    pageSize: 10,
    store: store,
    key: 'codigo',
    loadMode: 'processed',
  })
  return source
}

async function modificarConsumidorFinal(cliente): Promise<string> {
  const data = {
    email: cliente.email,
  }
  const apiresult = await RequestHelper.postUrlEncoded<any>(
    'clientes',
    'updateEmailConsumidorFinal',
    data,
  )
  return apiresult.clienteActualizado
}

async function modificarRemitenteMail(remitente): Promise<any> {
  const data = {
    infoRegistro: {
      tipoRemitente: remitente.tipoRemitente,
      codigo: remitente.codigo,
      email: remitente.email,
    },
  }
  const apiresult = await RequestHelper.post<any>(
    'generic',
    'updateMailRemitente',
    data,
  )
  return apiresult
}
