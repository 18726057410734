import { RequestHelper } from "../../../../helpers/requestHelper"



export const HorariosTrabajosServices = { loadHour, loadWHour }

async function loadWHour(descripcion: string) {
  try {
    const obj = {
      descripcion
    }

    const dataApi = await RequestHelper.getAll<any>('nomina/general', 'workTime/getHourly', '', obj)
    return dataApi
  } catch (error) {
    return error
  }
}

async function loadHour(codigo: number, lista: boolean = true) {
  try {
    const obj = {
      horarioTrabajo: codigo,
      lista
    }

    const dataApi = await RequestHelper.getAll<any>('nomina/general', 'schedules/getHourly', '', obj)
    return dataApi
  } catch (error) {
    return error
  }
}