/* eslint-disable no-unused-vars */
enum TipoExportacion {
  EXPORTACIONES_DENTRO_CENTROAMERICA = 'EDCA',
  EXPORTACIONES_FUERA_CENTROAMERICA = 'EFCA',
  EXPORTACIONES_SERVICIO = 'ES',
  VENTAS_ZONAS_FRANCAS = 'EZF',
  VENTAS_CUENTA_TERCEROS = 'CND',
}

export default TipoExportacion
