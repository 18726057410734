import React from 'react'
import { useDispatch } from 'react-redux'
import { setVisitorConfig } from '../../../store/reducers'
import { TVisitor } from '../../../store/types'
import { GeneralService } from '../../../services/general.service'
import { consoleService } from '../../../services/console.service'

interface IGeoProps extends React.PropsWithChildren { }

export type ICoordenate = {
  latitude: number | null
  longitude: number | null
}

type Country = {
  codigo: number
  nombre: string
}


type TLimitsCountry = {
  north: number
  south: number
  easth: number
  west: number
  pais: string
}

const Geo: React.FunctionComponent<IGeoProps> = (props) => {
  const dispatch = useDispatch()
  const [coor, setCoor] = React.useState<ICoordenate | null>(null)
  const [countries, setCountries] = React.useState<Array<Country>>([])

  const getProvider = React.useCallback(async () => {
    consoleService.log('getProvider')
    const validacionIdentificacion = await GeneralService.getCountries();
    if (validacionIdentificacion?.auto && validacionIdentificacion?.auto.length > 0) {
      setCountries(validacionIdentificacion?.auto)
    }
    return validacionIdentificacion?.auto ?? []
  }, []);

  const obtenerUbicacion = React.useCallback(async () => {
    consoleService.log('obtenerUbicacion')
    consoleService.log('navigator.geolocation', navigator.geolocation)
    const locationClient = localStorage.getItem('locationClient');
    if (locationClient !== null) {
      consoleService.log('locationClient in storage')
      const getSaved: TVisitor = JSON.parse(locationClient)
      dispatch(setVisitorConfig({
        country: getSaved?.country,
        countryPrefix: getSaved?.countryPrefix,
        countryCode: getSaved?.countryCode,
      }))
      return false;
    }
    consoleService.log('locationClient not in storage')

    const coordenates: ICoordenate = {
      latitude: null,
      longitude: null,
    }
    if (navigator.geolocation) {
      consoleService.log('navigator.geolocation', navigator.geolocation, 'warn')

      navigator.geolocation.getCurrentPosition((position) => {
        consoleService.log('position', position, 'warn')

        const latitud = position.coords.latitude;
        const longitud = position.coords.longitude;
        // Aquí puedes hacer lo que necesites con la latitud y longitud obtenidas
        consoleService.log(`Latitud: ${latitud}, Longitud: ${longitud}`);
        coordenates.latitude = latitud
        coordenates.longitude = longitud
        if (coordenates.latitude !== null && coordenates.longitude !== null) {
          setCoor(coordenates)
        }
      }, (error) => {
        console.error('Error al obtener la ubicación:', error);
      }
      );
    } else {
      console.error('Geolocalización no soportada por el navegador');
    }
    return coordenates;
  }, [dispatch]);

  const filtrarPorCoordenadas = React.useCallback(async (coord: ICoordenate): Promise<TLimitsCountry | null> => {
    const datos = [
      { north: 14.44, south: 13.16, easth: -87.69, west: -90.1, pais: 'El Salvador' },
      { north: 1.4381, south: -5.0113, easth: -75.1849, west: -81.0781, pais: 'Ecuador' },
      { north: 83.1167, south: 41.675, easth: -52.6156, west: -141.0029, pais: 'Canadá' },
      { north: 71.5388, south: 18.7763, easth: -66.9346, west: -179.1489, pais: 'Estados Unidos' },
      { north: 32.7186, south: 14.5323, easth: -86.7034, west: -117.1278, pais: 'México' },
      { north: 5.2719, south: -33.7506, easth: -34.7935, west: -73.9855, pais: 'Brasil' },
      { north: -21.7815, south: -55.0617, easth: -53.6415, west: -73.5886, pais: 'Argentina' },
      { north: -17.4987, south: -56.7251, easth: -66.9346, west: -75.6442, pais: 'Chile' },
      { north: 13.5141, south: -4.227, easth: -66.8695, west: -81.7279, pais: 'Colombia' }
    ];
    const buscar = datos.filter(function (i) {
      return (
        coord.latitude >= i.south &&
        coord.latitude <= i.north &&
        coord.longitude >= i.west &&
        coord.longitude <= i.easth
      )
    });
    let pais: TLimitsCountry = null
    if (buscar.length > 0) {
      pais = buscar[0];
    }
    return pais;
  }, []);

  const obtenerPais = React.useCallback(async (coord: ICoordenate): Promise<any> => {
    consoleService.log("obtenerPais")
    consoleService.log("coord", coord)
    const paisFinded = await filtrarPorCoordenadas(coord); // coord { latitude: 13.7089123, longitude: -89.729874 }
    consoleService.log('paisFinded', paisFinded)

    let paisesListado = countries
    if (paisesListado.length === 0) {
      const paisesListadoApi = await getProvider();
      paisesListado = paisesListadoApi?.auto ?? []
    }
    try {
      consoleService.log('paisesListado=====>', paisesListado)

      if (paisFinded !== null) {
        consoleService.log('pais=====>', paisFinded)
        const findCountry: any = paisesListado.filter((e: any) => e.nombre.toLocaleUpperCase() === paisFinded.pais.toLocaleUpperCase())
        consoleService.log('findCountry=====>', findCountry)
        if (findCountry.length > 0) {
          const save: TVisitor = {
            country: findCountry[0]?.nombre ?? '',
            countryPrefix: findCountry[0]?.nombre ?? '',
            countryCode: findCountry[0]?.codigo ?? 1,
          }
          localStorage.setItem('locationClient', JSON.stringify(save))
          dispatch(setVisitorConfig(save));
          consoleService.log('establesh country=====>', findCountry[0])
        }
        return paisFinded;
      } else {
        return 'No se pudo determinar el país';
      }
    } catch (error) {
      console.error('Error al obtener el país:', error);
      return 'No se pudo determinar el país';
    }
  }, [dispatch, countries, filtrarPorCoordenadas, getProvider]);

  const onLoad = React.useCallback(async (): Promise<any> => {
    return new Promise(function (resolve, reject) {
      if ('geolocation' in navigator) {
        getProvider()
        obtenerUbicacion()
        resolve(true)
      } else {
        reject(false)
      }
      // Aquí puedes realizar tus operaciones asíncronas
      // Puedes llamar a resolve() si la operación es exitosa y deseas retornar un valor
      // Puedes llamar a reject() si la operación falla y deseas retornar un error
    });

  }, [getProvider, obtenerUbicacion]);

  const verificarPermisoUbicacion = React.useCallback(async (): Promise<boolean> => {
    if ("geolocation" in navigator) {
      navigator.permissions.query({ name: "geolocation" }).then((result) => {
        if (result.state === "granted") {
          consoleService.log("El usuario ha activado la opción de compartir ubicación");
          // Aquí puedes realizar las operaciones que requieren la ubicación del usuario
          return true
        } else {
          consoleService.log("El usuario no ha activado la opción de compartir ubicación", '', 'error');
          // Aquí puedes mostrar un mensaje o realizar alguna acción alternativa
          return false
        }
      });
    } else {
      consoleService.log("Geolocalización no es soportada por este navegador.", '', 'error');
      // Aquí puedes mostrar un mensaje o realizar alguna acción alternativa
      return false
    }
  }, []);


  // // Ejemplo de uso
  // verificarPermisoUbicacion().then(permisoConcedido => {
  //   if (permisoConcedido) {
  //     consoleService.log('Se ha permitido compartir la ubicación actual.');
  //     // Aquí puedes realizar las operaciones que requieren la ubicación del usuario
  //   } else {
  //     consoleService.log('No se ha permitido compartir la ubicación actual.');
  //     // Aquí puedes mostrar un mensaje o realizar alguna acción alternativa
  //   }
  // });

  // React.useEffect(() => {
  //   verificarPermisoUbicacion()
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // React.useEffect(() => {
  //   obtenerUbicacion()
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // React.useEffect(() => {
  //   if (navigator.geolocation) {
  //     onLoad
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [navigator]);

  React.useEffect(() => {
    getProvider();
    const obtenerCoordenadas = () => {
      const locationClient = localStorage.getItem('locationClient');
      if (locationClient !== null) {
        consoleService.log('locationClient in storage')
        const getSaved: TVisitor = JSON.parse(locationClient)
        dispatch(setVisitorConfig({
          country: getSaved?.country,
          countryPrefix: getSaved?.countryPrefix,
          countryCode: getSaved?.countryCode,
        }))
        consoleService.log('establesh country=====>', getSaved)
        return false;
      } else {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position) => {
            const latitud = position.coords.latitude;
            const longitud = position.coords.longitude;
            setCoor({ latitude: latitud, longitude: longitud })
            consoleService.log("Latitud: " + latitud);
            consoleService.log("Longitud: " + longitud);
            // onLoad();
          });
        } else {
          consoleService.log("Geolocalización no es soportada por este navegador.");
        }
      }
    };
    obtenerCoordenadas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (coor !== null && (coor.latitude !== null && coor.longitude !== null)) {
      obtenerPais(coor)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coor]);

  return (
    <>
    </>
  )
}
export default React.memo(Geo)

export const defaultVisitor: TVisitor = {
  country: 'Ecuador',
  countryPrefix: 'EC',
  countryCode: 1,
}