import React, { useCallback, useEffect } from 'react'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput'
import { Button, CheckBox, DataGrid, DateBox, TextBox } from 'devextreme-react'
import {
  ItemTransferencia,
  Progreso,
  SaveTransfer,
  SeriesItem,
  TransferEditData,
} from '../../types/types'
import { CCol } from '@coreui/react-pro'
import { TabState } from '../../../../../../../store/genericTabTypes'
import LocalesLookUp from '../../../../../../componentes/localesLookUp'
import {
  Column,
  Editing,
  Export,
  Pager,
  Paging,
  Button as DatagridButton,
} from 'devextreme-react/data-grid'
import { getAllowedPageSizes } from '../../../../../../../helpers/Helper'
import ItemsCountIndicator from '../../../../../../componentes/indicadorNroRegistros'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import { DateUtils } from '../../../../../../../helpers/dateUtils'
import { LocalOption } from '../../../../../../componentes/localesLookUp/store/type'
import { useSetToast } from '../../../../../../../hooks/useGlobalHooks'
import { clearButtonClick, setButtons } from '../../store/tabsReducer'
import { ButtonTypes } from '../../../../../../../views/componentes/globalMenu/types'
import { TransfersServices } from '../../services/transferencias.services'
import { ToastTypes } from '../../../../../../../store/types'
import { onParseItems } from '../nuevo'
import {
  setDataShipment,
  setDestinationStore,
  setOriginStore,
} from '../../store/editDataReducer'
import { setCurrentFunction } from '../../store/generalReducer'
import Dialog from '../../../../../../../views/componentes/librerias/bootstrap-dialog'
import { TabTypes } from '../../../../../../../store/enums'
import { ModalIngresoSeries } from '../../../../../../componentes/modalIngresoSerie'
import { AccountingEntry } from '../../../../../../clientes/pages/docsCtasXCobrar/types/types'
import { DocCuentasCobrar } from '../../../../../../clientes/pages/docsCtasXCobrar/services/docCuentasCobrar.service'
import LoadingIndicator from '../../../../../../../views/componentes/loadingindicator/loadingindicator'
import BlockUi from '../../../../../../../views/componentes/librerias/block-ui'
import { isMobile } from 'react-device-detect'
import { useLoaderTransfers } from '../../customHooks'

interface INewProps extends React.PropsWithChildren {
  tab: TabState<TransferEditData>
  tabId: string
}

const EnvioRecepcion: React.FunctionComponent<INewProps> = (props) => {
  const { tabId } = props
  const setToastMessage = useSetToast()
  const { playLoader, stopLoader } = useLoaderTransfers()
  const dispatch = useDispatch()

  const transferToSend = useSelector((state: RootState) => {
    return state.inventarios.movimientos.transferencias.editData[tabId]
  })
  const currentAction = useSelector(
    (state: RootState) =>
      state.inventarios.movimientos.transferencias.general.currentFunction,
  )
  const tabs = useSelector((state: RootState) => {
    return state.inventarios.movimientos.transferencias.tabs
  })
  const tabType = useSelector((state: RootState) => {
    return state.inventarios.movimientos.transferencias.tabs.tabs[tabId].type
  })
  const loader = useSelector((state: RootState) => {
    return state.inventarios.movimientos.transferencias.editData[tabId].loader
  })

  const [confirmSave, setConfirmSave] = React.useState<boolean>(false)
  const [shipmentTranfer, setShipmentTranSfer] = React.useState<boolean>(false)
  const [showSeries, setShowSeries] = React.useState<boolean>(false)
  const [series, setSeries] = React.useState<any[]>([])

  const [rowIndex, setRowIndex] = React.useState<number>(-1)
  const dialogRef = React.useRef<any>(null)
  const dataGrid = React.useRef<any>()

  const updateDetails = useCallback(
    (event: any, rowIndex: number) => {
      const detailsTransfer = structuredClone(transferToSend.detalles)
      detailsTransfer.forEach((detail: ItemTransferencia, index) => {
        if (index === rowIndex) {
          detailsTransfer[index].enviado = event.value
          detailsTransfer[index].progreso =
            event.value === true
              ? tabType === TabTypes.envios
                ? Progreso.ENVIADO
                : Progreso.RECIBIDO
              : tabType === TabTypes.envios
              ? Progreso.NO_ENVIADO
              : Progreso.NO_RECIBIDO
        }
      })
      dispatch(
        setDataShipment({
          key: tabId,
          transferencia: {
            ...transferToSend,
            detalles: detailsTransfer,
          },
        }),
      )
    },
    [dispatch, tabId, tabType, transferToSend],
  )

  const renderCheckBox = React.useCallback(
    ({ data, rowIndex }) => {
      return (
        <CheckBox
          text=""
          value={data.enviado}
          onValueChanged={(event) => {
            if (event.event) updateDetails(event, rowIndex)
          }}
        />
      )
    },
    [updateDetails],
  )

  const onGetDetailsTransfer = useCallback(async () => {
    try {
      playLoader(tabId, 'Cargando transferencia . . .', ButtonTypes.new)
      const tarnsferDetails = await TransfersServices.getTransferDetailAll(
        transferToSend.codigo,
      )
      const seriesItem = await TransfersServices.getSeriesItem(
        transferToSend.codigo,
        'TRANSFERENCIAS',
      )
      stopLoader(tabId)
      let errorMessage: string = ''
      const series = []
      if (!seriesItem.error && seriesItem.auto) {
        seriesItem.auto.forEach((serie: SeriesItem) => {
          series.push(serie)
        })
        setToastMessage(
          'Cargar Series del Ítem',
          seriesItem.message,
          ToastTypes.Success,
        )
      } else
        errorMessage += seriesItem.message ?? 'Error al cargar series del ítem'
      let detailsTransfer = []
      if (!tarnsferDetails.error && tarnsferDetails.auto) {
        detailsTransfer = onParseItems(tarnsferDetails.auto)
        if (tabType === TabTypes.envios)
          detailsTransfer.forEach((detail: ItemTransferencia) => {
            detail.progreso = Progreso.NO_ENVIADO
          })
        else {
          const detailsToRecive = detailsTransfer.filter(
            (detail: ItemTransferencia) => {
              return (
                detail.progreso === Progreso.ENVIADO ||
                detail.progreso === Progreso.INGRESADO
              )
            },
          )
          detailsToRecive.forEach((detail: ItemTransferencia) => {
            detail.progreso = Progreso.NO_RECIBIDO
          })
          detailsTransfer = detailsToRecive
        }
        series.forEach((serie: SeriesItem) => {
          const indexDetail = detailsTransfer.findIndex(
            (detail: ItemTransferencia) => {
              return serie.itemCodigo === detail.codigo
            },
          )
          if (indexDetail > -1)
            detailsTransfer[indexDetail].series.push({
              codigo: serie.itemCodigo ?? 0,
              serie: serie.itemSerieSerie ?? '',
              min: serie.itemSerieMin ?? '',
              barras: serie.itemBarras ?? '',
              descripcion: serie.itemDescripcion ?? '',
            })
        })
        detailsTransfer.forEach((detail: ItemTransferencia) => {
          if (
            detail.numSerie === 1 &&
            detail.numSerie === 1 &&
            detail.series?.length === 0
          ) {
            let series = []
            series = Array(detail.cantidad).fill({
              codigo: detail.codigo ?? 0,
              serie: '',
              min: '',
              barras: detail.barras ?? '',
              descripcion: detail.descripcion ?? '',
            })
            detail.series = series
          }
        })
        setToastMessage(
          'Cargar Detalles de Transferencia',
          tarnsferDetails.message,
          ToastTypes.Success,
        )
      } else
        errorMessage += tarnsferDetails.message ?? 'Error al cargar detalles'
      if (errorMessage !== '')
        setToastMessage(
          'Cargar Detalles de Transferencia',
          errorMessage,
          ToastTypes.Warning,
        )
      dispatch(
        setDataShipment({
          key: tabId,
          transferencia: {
            ...transferToSend,
            detalles: detailsTransfer,
            series: series,
            loaded: true,
          },
        }),
      )
    } catch (error) {
      stopLoader(tabId)
      setToastMessage(
        'Cargar Detalles de Transferencia',
        error,
        ToastTypes.Danger,
      )
    }
  }, [
    dispatch,
    playLoader,
    setToastMessage,
    stopLoader,
    tabId,
    tabType,
    transferToSend,
  ])

  const onGenerateEntry = useCallback(
    async (transerCode: number, date: string) => {
      const entry: AccountingEntry = {
        infoRegistro: {
          movimiento: 'TRANSFERENCIA',
        },
        fecha: DateUtils.pickersDateToApiDate(date),
        descripcion: '',
        numeroDocumento: transerCode,
        diarioNumero: 0,
        localCodigo: 0,
        codigo: 0,
        formaPago: [],
      }
      const generateEntry = await DocCuentasCobrar.generateAccountingEntry(
        entry,
      )
      if (generateEntry.error)
        setToastMessage(
          'Generar Asiento Transferencia',
          generateEntry.message ?? 'Error al generar asiento',
          ToastTypes.Warning,
        )
    },
    [setToastMessage],
  )

  const onHandleShipment = useCallback(async () => {
    try {
      playLoader(
        tabId,
        `${
          tabType === TabTypes.envios ? 'Enviando' : 'Recibiendo'
        } Transferencia . . . `,
        ButtonTypes.shipments,
      )
      const transferToSave: SaveTransfer = {
        infoRegistro: {
          codigo: transferToSend.codigo,
          numero: transferToSend.numero?.toString(),
          descripcion: transferToSend.descripcion,
          responsable: transferToSend.responsable?.codigo ?? 1,
          fecha: DateUtils.pickersDateToApiDate(transferToSend.fecha),
          origen: transferToSend.origenLocal.codigo,
          destino: transferToSend.destinoLocal.codigo,
          progreso: transferToSend.progreso,
          movimientoCabeceraCodigo: transferToSend.movimiento ?? 0,
          detalle: transferToSend.detalles,
          series: transferToSend.series,
          lotes: [],
        },
      }
      const shipmentTransfer = await TransfersServices.saveTransfer(
        transferToSave,
      )
      stopLoader(tabId)
      if (
        !shipmentTransfer.error &&
        shipmentTransfer.auto &&
        tabType === TabTypes.recepcion
      )
        onGenerateEntry(transferToSend.codigo, transferToSend.fecha)
      setToastMessage(
        `${tabType === TabTypes.envios ? 'Enviar' : 'Recibir'} Transferencia`,
        shipmentTransfer.message,
        shipmentTransfer.error ? ToastTypes.Warning : ToastTypes.Success,
      )
    } catch (error) {
      stopLoader(tabId)
      setToastMessage(
        `${tabType === TabTypes.envios ? 'Enviar' : 'Recibir'} Transferencia`,
        error,
        ToastTypes.Danger,
      )
    }
  }, [
    onGenerateEntry,
    playLoader,
    setToastMessage,
    stopLoader,
    tabId,
    tabType,
    transferToSend,
  ])

  useEffect(() => {
    if (shipmentTranfer === true) {
      setShipmentTranSfer(false)
      onHandleShipment()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipmentTranfer])

  const onConfirmSave = useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: 'No tiene seleccionado todos los items a ser transferidos, Desea continuar?',
      actions: [
        Dialog.Action(
          <span>
            <u>A</u>ceptar
          </span>,
          (dialog) => {
            dialog.hide()
            setShipmentTranSfer(true)
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setShipmentTranSfer(false)
    setConfirmSave(false)
    return
  }, [])

  useEffect(() => {
    if (confirmSave) onConfirmSave()
  }, [confirmSave, onConfirmSave])

  const onValidateForm = useCallback(() => {
    if (transferToSend.detalles.length === 0) {
      setToastMessage(
        `${tabType === TabTypes.envios ? 'Enviar' : 'Recibir'} Transferencia`,
        'La transferencia no tiene detalles.',
        ToastTypes.Warning,
      )
      return false
    }
    const checkDeatils = transferToSend.detalles.some(
      (detail: ItemTransferencia) => detail.enviado === false,
    )
    if (checkDeatils) setConfirmSave(true)
    else onHandleShipment()
  }, [onHandleShipment, setToastMessage, tabType, transferToSend.detalles])

  const updateCellValue = useCallback(
    (newData, value, currentRowData, cellToUpdate: string) => {
      if (value && currentRowData) {
        if (value < 0 && typeof value === 'number') {
          setToastMessage(
            `Actualizar Valor ${cellToUpdate}`,
            `El valor de ${cellToUpdate} debe eser mayor ó igual a 0`,
            ToastTypes.Warning,
          )
          return false
        }
        newData = structuredClone(transferToSend.detalles)
        const indexItemToUpdate = newData.findIndex(
          (item: ItemTransferencia) => {
            return item.codigo === currentRowData.codigo
          },
        )
        currentRowData[cellToUpdate] = value
        newData[indexItemToUpdate] = currentRowData
        dispatch(
          setDataShipment({
            key: tabId,
            transferencia: {
              ...transferToSend,
              detalles: newData,
            },
          }),
        )
      }
    },
    [dispatch, setToastMessage, tabId, transferToSend],
  )

  const onRemoveItem = useCallback(
    (rowIndex: number) => {
      const currentDataGrid = structuredClone(transferToSend.detalles)
      const series = structuredClone(transferToSend.series)
      const updateSeries = series.filter((series: SeriesItem) => {
        return series.itemCodigo !== currentDataGrid[rowIndex].codigo
      })
      currentDataGrid.splice(rowIndex, 1)
      dispatch(
        setDataShipment({
          key: tabId,
          transferencia: {
            ...transferToSend,
            detalles: currentDataGrid,
            series: updateSeries,
          },
        }),
      )
      setRowIndex(-1)
    },
    [dispatch, tabId, transferToSend],
  )

  const onChangedSerieModal = useCallback(
    (series) => {
      setSeries([])
      const newDetails = structuredClone(transferToSend.detalles)
      newDetails[rowIndex].series = series
      const newSeries = transferToSend.series.filter((serie: SeriesItem) => {
        return serie.itemCodigo !== newDetails[rowIndex].codigo
      })
      series.forEach((serie) => {
        newSeries.push({
          itemCodigo: serie.codigo,
          itemBarras: serie.barras,
          itemDescripcion: serie.descripcion,
          itemSerieSerie: serie.serie,
          itemSerieMin: serie.min,
        })
      })
      dispatch(
        setDataShipment({
          key: tabId,
          transferencia: {
            ...transferToSend,
            detalles: newDetails,
            series: newSeries,
          },
        }),
      )
      setSeries(series)
    },
    [dispatch, rowIndex, tabId, transferToSend],
  )

  const onSelectedChanged = useCallback(
    (rowIndex: number, dataRow: ItemTransferencia) => {
      setSeries([])
      setRowIndex(rowIndex)
      dispatch(
        setButtons({
          tabKey: tabId,
          buttons: {
            Nuevo: true,
            Buscar: true,
            Guardar: true,
            Series: dataRow.numSerie === 1,
          },
        }),
      )
      setSeries(dataRow.series)
    },
    [dispatch, tabId],
  )

  const handleButtonClick = useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.save:
          if (tabs.current === tabId) onValidateForm()
          break
        case ButtonTypes.series:
          if (tabs.current === tabId) setShowSeries(true)
          break
        default:
          break
      }
      dispatch(setCurrentFunction(''))
      dispatch(clearButtonClick(tabId))
    },
    [dispatch, tabId, tabs, onValidateForm],
  )

  useEffect(() => {
    if (currentAction !== '') handleButtonClick(currentAction)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAction])

  useEffect(() => {
    if (transferToSend.loaded === false) onGetDetailsTransfer()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <BlockUi
      tag="div"
      loader={LoadingIndicator}
      blocking={loader.show && tabId === tabs.current}
      message={loader.mensaje}
    >
      <RowContainer className="m-2">
        {showSeries && (
          <ModalIngresoSeries
            onChanged={(series) => {
              onChangedSerieModal(series)
            }}
            onCancel={() => setShowSeries(false)}
            show={showSeries}
            codModulo={2}
            data={transferToSend.origenLocal?.codigo ?? -1}
            dataSeries={series}
            dataDetalle={[]}
          />
        )}
        <Dialog ref={dialogRef} />
        <RowContainer>
          <CustomCol xs="12" md="2">
            <Labeled label="TRANSFERENCIA NRO:">
              <h5>{transferToSend?.codigo ?? 'N/A'}</h5>
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="2">
            <Labeled label="Fecha:">
              <DateBox
                displayFormat="dd/MM/yyyy"
                value={transferToSend.fecha}
                onValueChanged={(evt: any) => {
                  if (evt.event && evt.value)
                    dispatch(
                      setDataShipment({
                        key: tabId,
                        transferencia: {
                          ...transferToSend,
                          fecha: evt.value,
                        },
                      }),
                    )
                }}
                readOnly={tabType === TabTypes.envios}
              />
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="8">
            <Labeled label="Observaciones:">
              <TextBox value={transferToSend.descripcion} readOnly={true} />
            </Labeled>
          </CustomCol>
        </RowContainer>
        <RowContainer>
          <CustomCol xs="12" md="4">
            <Labeled label="Local Origen:">
              <LocalesLookUp
                onChanged={(store: LocalOption) => {
                  dispatch(setOriginStore({ key: tabId, store: store }))
                }}
                onChangedOptions={() => {}}
                selected={transferToSend.origenLocal}
                disabled={true}
              />
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="4">
            <Labeled label="Local Destino:">
              <LocalesLookUp
                onChanged={(store: LocalOption) => {
                  dispatch(setDestinationStore({ key: tabId, store: store }))
                }}
                onChangedOptions={() => {}}
                selected={transferToSend.destinoLocal}
                disabled={true}
              />
            </Labeled>
          </CustomCol>
          <CCol
            xs="12"
            md="4"
            style={{
              display: 'flex',
              marginTop: 'auto',
              justifyContent: 'flex-end',
            }}
          >
            {tabType === TabTypes.recepcion && (
              <Button
                style={{ marginTop: '4px' }}
                id="btnAdd"
                className="me-1"
                icon="trash"
                stylingMode="contained"
                type="default"
                onClick={() => onRemoveItem(rowIndex)}
                hint="Eliminar Ítem"
                disabled={rowIndex === -1}
              />
            )}
            <CheckBox
              text="Todos"
              style={{ marginTop: '4px' }}
              value={transferToSend.selectedAll}
              onValueChanged={(evt: any) => {
                if (evt.event) {
                  const detailsTransfer = structuredClone(
                    transferToSend.detalles,
                  )
                  detailsTransfer.forEach((detail: ItemTransferencia) => {
                    detail.enviado = evt.value
                    detail.progreso =
                      evt.value === true
                        ? tabType === TabTypes.envios
                          ? Progreso.ENVIADO
                          : Progreso.RECIBIDO
                        : tabType === TabTypes.envios
                        ? Progreso.NO_ENVIADO
                        : Progreso.NO_RECIBIDO
                  })
                  dispatch(
                    setDataShipment({
                      key: tabId,
                      transferencia: {
                        ...transferToSend,
                        detalles: detailsTransfer,
                        selectedAll: evt.value,
                      },
                    }),
                  )
                }
              }}
            />
          </CCol>
        </RowContainer>
        <RowContainer>
          <ItemsCountIndicator items={transferToSend.detalles} />
          <DataGrid
            ref={dataGrid}
            selection={{ mode: 'single' }}
            hoverStateEnabled={true}
            dataSource={transferToSend.detalles}
            showBorders={true}
            allowColumnReordering={true}
            allowColumnResizing={true}
            columnAutoWidth={true}
            keyExpr="codigo"
            key="gridSendTransfer"
            repaintChangesOnly
            onRowClick={({ rowIndex, data }) =>
              onSelectedChanged(rowIndex, data)
            }
          >
            <Editing mode="cell" allowUpdating />
            <Export enabled={true} allowExportSelectedData={true} />
            <Paging defaultPageSize={20} />
            <Pager
              visible={transferToSend.detalles.length > 0 ? true : false}
              allowedPageSizes={getAllowedPageSizes(transferToSend.detalles)}
              displayMode={'full'}
              showPageSizeSelector={true}
              showInfo={true}
              showNavigationButtons={true}
            />
            {!isMobile && tabType === TabTypes.recepcion && (
              <Column type="buttons" width="40px">
                <DatagridButton
                  icon="trash"
                  hint="Eliminar"
                  onClick={async ({ row }) => onRemoveItem(row.dataIndex)}
                />
              </Column>
            )}
            <Column
              allowEditing={false}
              dataField="codigoUsuario"
              caption="Código"
              width={isMobile ? '120px' : '14%'}
            />
            <Column
              allowEditing={false}
              dataField="barras"
              caption="Barras"
              width={isMobile ? '120px' : '14%'}
            />
            <Column
              allowEditing={false}
              dataField="descripcion"
              caption="Descripción"
              width={isMobile ? '300px' : '34%'}
            />
            <Column
              allowEditing={false}
              dataField="unidadDescripcion"
              caption="Unidad"
              width={isMobile ? '80px' : '10%'}
            />
            <Column
              allowEditing={false}
              dataField="cantidad"
              caption="Cantidad"
              width={isMobile ? '90px' : '10%'}
              setCellValue={(newData, value, currentRowData) =>
                updateCellValue(newData, value, currentRowData, 'cantidad')
              }
            />
            <Column
              allowEditing={true}
              dataField="referencia"
              caption="Referencia"
              width={isMobile ? '100px' : '10%'}
              setCellValue={(newData, value, currentRowData) =>
                updateCellValue(newData, value, currentRowData, 'referencia')
              }
            />
            <Column
              allowEditing={false}
              dataField="enviado"
              caption={tabType === TabTypes.envios ? 'Enviado' : 'Recibido'}
              width={isMobile ? '100px' : '8%'}
              cellRender={renderCheckBox}
            />
          </DataGrid>
        </RowContainer>
      </RowContainer>
    </BlockUi>
  )
}

export default EnvioRecepcion
