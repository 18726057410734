import React from 'react';
import RowContainer from '../../../../../views/componentes/rowContainer/rowContainer';
import { VendedoresService } from '../../../../shared/components/buscarVendedorLookMemoUp/service/vendedor.service';
import {
  Column,
  DataGrid,
  FilterRow,
  HeaderFilter,
  Paging,
  LoadPanel,
  Pager, Button as DatagridButton
} from 'devextreme-react/data-grid'
import { Vendedor, VendedorBusqueda } from '../../../../shared/components/buscarVendedorLookMemoUp/type/types';
import { ButtonTypes } from '../../../../../views/componentes/globalMenu/types';
import { ToastTypes } from '../../../../../store/types';

interface IResultadoBusquedaProps extends React.PropsWithChildren {
  funcion: string,
  name: string,
  data: any,
  filaSeleccionada: Vendedor,
  pagination: infoPaginationType,
  dataSearch: any,
  resetFunction: () => void,
  setDataItems: (data) => void
  onChanged: (any: VendedorBusqueda) => void
  onEdit: (any: VendedorBusqueda) => void
  setToast: (texto: string, type: ToastTypes) => void

}

type infoPaginationType = {
  desde: number,
  hasta: number,
  paginaActual: number,
  totalPaginas: number,
  totalRegistros: number
}



type TFindVendedores = {
  local?: number
  nombres?: string
  identificacion?: string
}

const ResultadoBusqueda: React.FunctionComponent<IResultadoBusquedaProps> = (props) => {

  const { setToast, filaSeleccionada, funcion, dataSearch, resetFunction, setDataItems, onChanged, onEdit } = props;


  const tablaRef = React.useRef<any>();
  const tablaRefPager = React.useRef<any>();

  const [data, setData] = React.useState<any>([])
  const [pageSize,] = React.useState<number>(10)
  const [pageIndex, setPageIndex] = React.useState<number>(10)

  const onSearch = React.useCallback((dataSearch) => {
    const findVendedores: TFindVendedores = {}
    if (dataSearch.local && dataSearch.local.codigo > 0) {
      findVendedores.local = dataSearch.local.codigo;
    }
    if (dataSearch.nombres) {
      findVendedores.nombres = dataSearch.nombres;
    }
    if (dataSearch.identificacion) {
      findVendedores.identificacion = dataSearch.identificacion;
    }

    const resultado = VendedoresService.getItemsGridDatasource(findVendedores);

    resultado.load().then(data => {
      tablaRef.current.instance.repaint();

      const sellersOptions: any = []
      data.map(function (obj) {
        if (obj['vendedorCodigo'] > 0) {
          sellersOptions.push({ ...obj })
        }
      });
      setData(sellersOptions)
      setDataItems(sellersOptions);
    });



  }, [setDataItems]);


  const onDelete = React.useCallback(async () => {
    const resultado: any = await VendedoresService.deleteSeller(filaSeleccionada.vendedorCodigo);
    if (resultado !== undefined && resultado.error === false) {
      setToast(resultado.message, ToastTypes.Success);
      onSearch(dataSearch)
      onEdit(null)
    } else if (resultado !== undefined && resultado.error === true) {
      setToast(resultado.message, ToastTypes.Danger)
    }
  }, [filaSeleccionada, dataSearch, setToast, onSearch, onEdit]);



  const onResetTab = React.useCallback(() => {
    setPageIndex(0)
  }, []);

  const onRowDblClick = React.useCallback((selec) => {
    if (selec.data) {
      onChanged(selec['data'])
    }
  }, [onChanged]);

  const onSelectionChanged = React.useCallback((selec) => {
    if (selec['selectedRowsData'] && selec['selectedRowsData'].length > 0) {
      onEdit(selec['selectedRowsData'][0])
    }
  }, [onEdit]);

  React.useEffect(() => {
    if (funcion === ButtonTypes.undo) {
      setData([])
    } else if (funcion === ButtonTypes.find) {
      onResetTab();
      resetFunction()
      onSearch(dataSearch)
    } else if (funcion === ButtonTypes.delete) {
      onResetTab();
      resetFunction()
      onDelete()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [funcion]);

  React.useEffect(() => {
    setData(props.data);
  }, [props.data])

  return (
    <>
      <RowContainer className='mt-2'>
        <DataGrid
          ref={tablaRef}
          keyExpr="vendedorCodigo"
          dataSource={data ?? []}
          selection={{ mode: 'single' }}
          hoverStateEnabled={true}
          showBorders={true}
          showRowLines={true}
          allowColumnReordering={true}
          allowColumnResizing
          columnResizingMode='widget'
          columnAutoWidth={true}
          activeStateEnabled={true}
          // onOptionChanged={handleOptionChange}
          remoteOperations={true}
          onSelectionChanged={onSelectionChanged}
          onRowDblClick={(d) => { onRowDblClick(d) }}
          width={'100%'}
        >
          <Paging
            pageIndex={pageIndex}
            enabled={true}
            defaultPageSize={10}
            pageSize={pageSize}
          />
          <Pager
            ref={tablaRefPager}
            showPageSizeSelector={true}
            allowedPageSizes={[8, 12, 20]}
            showNavigationButtons={true}
            showInfo={true}
            infoText="Page #{0}. Total: {1} ({2} items)"
          />
          <LoadPanel enabled={true} />
          <FilterRow visible={true} />
          <HeaderFilter visible={true} />
          <Column type='buttons' width='7%' >
            <DatagridButton icon='arrowleft' visible={true} hint='Seleccionar'
              onClick={(e) => {
                onChanged(e.row.data)
              }} />
          </Column>
          <Column dataField='usuarioNombres' caption='Nombres' width='200px' allowEditing={false} />
          <Column dataField='usuarioApellidos' caption='Apellidos' width='200px' allowEditing={false} />
          <Column dataField='siglas' caption='Siglas' width='200x' allowEditing={false} />
          <Column dataField='usuarioIdentificacion' caption='Identificación' width='200x' allowEditing={false} />
        </DataGrid>
      </RowContainer>
    </>
  );
}
export default ResultadoBusqueda;

