import React, { useCallback, useEffect, useRef } from 'react'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput'
import { Button, DataGrid, NumberBox, RadioGroup, TextBox, ValidationGroup, ValidationSummary, Validator } from 'devextreme-react'
import { consoleService } from '../../../../../../../services/console.service'
import MesesLookUp, { MesesData } from '../../../../../../componentes/mesesLookUp'
import PaisesLookUp from '../../../../../../componentes/paisesLookUp'
import LocalesLookUp from '../../../../../../componentes/localesLookUp'
import { AsyncRule, Column, ColumnChooser, Editing, Export, FilterRow, HeaderFilter, Pager, Paging, RangeRule, RequiredRule } from 'devextreme-react/data-grid'
import ItemsCountIndicator from '../../../../../../componentes/indicadorNroRegistros'
import { GenerateData, GenrateRolsList, RolesPadoDatosEdicion } from '../../types/types'
import { TabState } from '../../../../../../../store/genericTabTypes'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import { setChangeData, setChangeLoader, setRolesGenList, setSeleccionarDato } from '../../store/generarReducer'
import { localidadesService } from '../../../../../../../services/localidades.service'
import { adminService } from '../../../../../../admin/cuenta/usuario/services/adminService'
import { addToast } from '../../../../../../../store/toasterReducer'
import { ToastTypes } from '../../../../../../../store/types'
import BlockUi from '../../../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../../../views/componentes/loadingindicator/loadingindicator'
import { ButtonTypes } from '../../../../../../../views/componentes/globalMenu/types'
import { changeCurrentTab, clearButtonClick, setCurrentExecutingAction } from '../../store/tabsReducer'
import { isMobile } from 'react-device-detect'
import VisualizaError from '../../../../../../ventas/pages/shared/visualizaError/visualizaError'
import ListErrosValidationForm from '../../../../../../componentes/listadoErroresValidacionForm'
import { MessagesKeys, lh } from '../../../../../../../helpers/localizationHelper'
import { RolsPaymentsServices } from '../../services/rolsPays.services'
import { displayMode } from 'devexpress-dashboard/model/index.metadata'
import { getAllowedPageSizes } from '../../../../../../../helpers/Helper'
import { getIndexProvider } from '../../../../../../shared/helpers/funciones'
import { utilidades } from '../../../../../../../helpers/utilidades'


export const gruposPersonal = [
  { value: 0, label: 'Todos' },
  { value: 1, label: 'Administrativo' },
  { value: 2, label: 'Obrero' },
]


interface IGenerateRolsProps extends React.PropsWithChildren {
  tab: TabState<RolesPadoDatosEdicion>
  tabId: string
}


const GenerarRoles: React.FunctionComponent<IGenerateRolsProps> = (props) => {
  const { tab, tabId } = props

  const dispatch = useDispatch()
  const generateState = useSelector((state: RootState) => state.nomina.rol.rolesPago.generate)
  const resultados = useSelector((state: RootState) => state.nomina.rol.rolesPago.generate.resultadosTodos)
  const seleccionado = useSelector((state: RootState) => state.nomina.rol.rolesPago.generate.seleccionado)
  const activePeriod = useSelector((state: RootState) => state.shared.providers.periodoContable)
  const tabs = useSelector((state: RootState) => state.nomina.rol.rolesPago.tabs)
  const loader = useSelector((state: RootState) => state.nomina.rol.rolesPago.generate.loader)
  const validationGenrate = useRef<any>()
  const mesMessage: string = 'Mes: elija un mes.'
  const [arrayErrorsGen, setArrayErrorsGen] = React.useState<Array<string>>([])
  const [showErrorPopupGen, setShowErrorPopupGen] = React.useState<boolean>(false)
  const [changes, setChanges] = React.useState<any>();
  const [editRowKey, setEditRowKey] = React.useState<any>();
  const onSetButtonAction = useCallback(
    (tipo: ButtonTypes | undefined) => {
      dispatch(
        setCurrentExecutingAction({
          tabKey: tabId,
          buttonType: tipo,
        }),
      )
    },
    [dispatch, tabId],
  )

  const onLoader = useCallback((showLoader: boolean, mensaje: string) => {
    dispatch(setChangeLoader({
      show: showLoader,
      mensaje: mensaje
    }))
  }, [dispatch])

  const setToast = useCallback(
    (mensaje: string, tipo: ToastTypes) => {
      dispatch(
        addToast({
          title: 'Nómina - Roles de Pago',
          content: mensaje,
          type: tipo,
        }),
      )
    },
    [dispatch],
  )



  const onInitial = useCallback(async () => {
    const temp = { ...generateState }
    if (temp.initial) {
      onLoader(true, 'Cargando...')
      try {
        const resPais = await localidadesService.getDpPaises("Elija una opción")
        const dataLocal = await adminService.obtenerLocalesUsuario("Elija un local", true)

        if (resPais.length > 0) {
          const provider = resPais.slice(0)
          temp.pais = {
            codigo: provider[0]?.codigo ?? 0,
            nombre: provider[0]?.nombre ?? '',
            uaf: provider[0]?.codigoUaf ?? ''
          }
        }
        if (dataLocal?.auto && dataLocal?.error === false) {
          temp.lugar = dataLocal?.auto[0]
        }
        temp.anio = activePeriod?.length > 0 ? activePeriod[0].anio : new Date().getFullYear()
        temp.tipoPersonal = gruposPersonal[0]
        temp.mes = MesesData[0]
        temp.initial = false
        dispatch(setChangeData({
          ...temp
        }))
        onLoader(false, '')
      } catch (error) {
        setToast(error?.message, ToastTypes.Danger)
      }
    }
  }, [generateState, dispatch, onLoader, setToast, activePeriod])

  const onValueChange = useCallback((data, key: string) => {
    dispatch(setChangeData({
      ...generateState,
      [key]: data
    }))
  }, [dispatch, generateState])

  const onValidate = useCallback(() => {
    const errors: Array<string> = []
    if (generateState?.mes === null || generateState?.mes === undefined || generateState?.mes?.value === "-1") {
      errors.push('Mes: elija un mes.')
    }
    return errors
  }, [generateState])

  const onGenerateRols = useCallback(async () => {
    let errors: Array<string> = []
    errors = onValidate()
    const result = validationGenrate.current.instance.validate()
    if (result.isValid && errors.length === 0) {
      onSetButtonAction(ButtonTypes.rols)
      onLoader(true, 'Genrando roles...')
      try {
        consoleService.log(generateState, 'state general')
        const gen_data: GenerateData = {
          nmes: generateState?.mes?.label ?? '',
          mes: generateState?.mes?.value ?? '',
          anio: generateState?.anio ?? 0,
          tipoPersonal: generateState?.tipoPersonal?.value ?? 0,
          pais: generateState?.pais?.codigo ?? 0,
          local: generateState?.lugar?.codigo ?? 0,
        }
        consoleService.log(gen_data, 'gen_data')
        const data = await RolsPaymentsServices.generateRols(gen_data)
        consoleService.log(data, 'data results')
        if (data?.auto && data?.error === false) {
          if (data?.auto?.length > 0) {
            setToast(data?.message, ToastTypes.Success)
            dispatch(setRolesGenList(data?.auto))
          } else {
            setToast(`Los roles del personal ${generateState?.tipoPersonal?.label} de ${generateState?.pais?.nombre} del mes de ${generateState?.mes?.label} ya fueron generados`, ToastTypes.Warning)
            dispatch(setRolesGenList([]))
          }
        } else {
          setToast(data?.message, ToastTypes.Danger)
          dispatch(setRolesGenList([]))
        }
      } catch (error) {
        setToast(error?.message, ToastTypes.Danger)
      }
      onLoader(false, '')
      onSetButtonAction(undefined)
    } else {
      setArrayErrorsGen(errors)
      setShowErrorPopupGen(true)
      setToast(lh.getMessage(MessagesKeys.GlobalErrorSave), ToastTypes.Warning)
    }
  }, [onValidate, generateState, setToast, dispatch, onLoader, onSetButtonAction])

  const onSaveRols = useCallback(async () => {
    onSetButtonAction(ButtonTypes.save)
    onLoader(true, 'Guardando todos...')
    try {
      consoleService.log(resultados, ' resultados')
      const data = await RolsPaymentsServices.saveAllList(resultados)
      consoleService.log(data, ' result save')
      if (data?.auto && data?.error === false) {
        setToast(data?.message, ToastTypes.Success)
        dispatch(changeCurrentTab('BUSQUEDA'))
      } else {
        setToast(data?.message, ToastTypes.Danger)
      }
    } catch (error) {
      setToast(error?.message, ToastTypes.Danger)
    }
    onLoader(false, '')
    onSetButtonAction(undefined)
  }, [resultados, setToast, onLoader, onSetButtonAction, dispatch])


  const onUndo = useCallback(async () => {
    const temp = { ...generateState }
    const resPais = await localidadesService.getDpPaises("Elija una opción")
    const dataLocal = await adminService.obtenerLocalesUsuario("Elija un local", true)

    if (resPais.length > 0) {
      const provider = resPais.slice(0)
      temp.pais = {
        codigo: provider[0]?.codigo ?? 0,
        nombre: provider[0]?.nombre ?? '',
        uaf: provider[0]?.codigoUaf ?? ''
      }
    }
    if (dataLocal?.auto && dataLocal?.error === false) {
      temp.lugar = dataLocal?.auto[0]
    }
    temp.anio = 2013
    temp.tipoPersonal = gruposPersonal[0]
    temp.mes = MesesData[0]
    temp.seleccionado = null
    dispatch(setChangeData({
      ...temp
    }))
  }, [dispatch, generateState])

  const onBroom = useCallback(() => {
    dispatch(setRolesGenList([]))
    dispatch(
      setSeleccionarDato(null),
    )
  }, [dispatch])

  const handleButtonClick = useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.save:
          if (tabs.current === tabId) onSaveRols()
          break
        case ButtonTypes.undo:
          if (tabs.current === tabId) onUndo()
          break
        case ButtonTypes.broom:
          if (tabs.current === tabId) onBroom()
          break
        default:
          break
      }
      dispatch(clearButtonClick(tabId))
    }, [dispatch, tabId, onSaveRols, tabs, onUndo, onBroom])


  useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab.globalButtonClick])

  useEffect(() => {
    onInitial()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const validateAsyncSelect = React.useCallback((datoSeleccion: any, message: string) => {
    if (datoSeleccion?.value?.value !== "-1") {
      return Promise.resolve()
    } else {
      return Promise.reject(message)
    }
  }, [])

  const validateAsyncSeleccionMes = React.useCallback((datoSeleccion) => {
    return validateAsyncSelect(datoSeleccion, mesMessage)
  }, [validateAsyncSelect])

  const onChangesChange = React.useCallback((changes) => {
    setChanges(changes);
  }, [])

  const onEditRowKeyChange = React.useCallback((editRowKey) => {
    setEditRowKey(editRowKey);
  }, [])

  const onSelectedChanged = useCallback(
    ({ selectedRowsData }) => {
      consoleService.log(selectedRowsData[0], 'seleccionado selectedRowsData[0]')
      if (selectedRowsData[0] && selectedRowsData[0]?.usuario) {
        dispatch(
          setSeleccionarDato({ ...selectedRowsData[0] }),
        )
      }
    },
    [dispatch],
  )

  const onRemoveItem = useCallback(async () => {
    const provider = resultados.slice(0)
    if (seleccionado !== null && seleccionado !== undefined && seleccionado?.usuario) {
      const index = await getIndexProvider(provider, 'usuario', seleccionado?.usuario)
      if (index > -1) {
        provider.splice(index, 1)
        dispatch(setRolesGenList(provider))
        dispatch(
          setSeleccionarDato(null),
        )
      }
    }

  }, [resultados, dispatch, seleccionado])

  const setCellValue = React.useCallback((rowData, value, currentRowData, key: string) => {
    let newValue: Array<GenrateRolsList> = []
    if (currentRowData !== null && currentRowData !== undefined && value) {
      const detalle = resultados.slice(0) ?? []
      newValue = detalle.map((det) => {
        if (currentRowData.usuario === det.usuario) {
          currentRowData[key] = value;
          return currentRowData
        }
        return det
      })
      dispatch(setRolesGenList(newValue))
    }
  }, [dispatch, resultados])

  return (
    <>
      {showErrorPopupGen && (
        <VisualizaError
          titulo="Error en Roles de Pago"
          mensaje={'Error al generar roles'}
          content={
            <ListErrosValidationForm
              mainMessage={'Revise los siguientes errores:'}
              errorsList={arrayErrorsGen}
              color={'danger'}
            />
          }
          onOk={() => setShowErrorPopupGen(false)}
        />
      )}
      <div id="PersonalContent">
        <BlockUi
          tag="div"
          loader={LoadingIndicator}
          blocking={loader.show}
          message={loader.mensaje}
        >
          <RowContainer>
            <ValidationGroup id={'formRolPago'} ref={validationGenrate}>
              <RowContainer>
                {!isMobile && (
                  <CustomCol lg="6" md="8" sm="12">
                    <ValidationSummary id="summary"></ValidationSummary>
                  </CustomCol>
                )}
              </RowContainer>
              <RowContainer>
                <CustomCol xs="12" md="3">
                  <Labeled label='Año:'>
                    <TextBox
                      value={String(generateState?.anio) ?? null}
                      onValueChange={(data) => onValueChange(parseInt(data), 'anio')}
                      maxLength={4}
                      onKeyDown={utilidades.filtraTeclasNoNumericas}
                    />
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="3">
                  <Labeled label='Mes:'>
                    <MesesLookUp
                      onChanged={(data) => {
                        consoleService.log('mes ', data)
                        onValueChange(data, 'mes')
                      }}
                      selected={generateState?.mes ?? null}
                    >
                      <Validator>
                        <RequiredRule message={mesMessage} />
                        <AsyncRule
                          message={mesMessage}
                          validationCallback={validateAsyncSeleccionMes}
                        />
                      </Validator>
                    </MesesLookUp>
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="3">
                  <Labeled label="Pais:">
                    <PaisesLookUp
                      onChanged={(data) => onValueChange(data, 'pais')}
                      selected={generateState?.pais ?? null}
                      onChangedOptions={() => { }}
                      defecto={false}
                    />
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="3">
                  <Labeled label="Lugar o CC:">
                    <div
                      style={{
                        display: 'flex',
                        gap: '2px',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >

                      <LocalesLookUp
                        onChanged={(data) => onValueChange(data, 'lugar')}
                        onChangedOptions={() => { }}
                        selected={generateState?.lugar ?? null}
                      />
                      <Button
                        id="btnAdd"
                        className="me-1"
                        stylingMode="contained"
                        type="default"
                        onClick={() => onGenerateRols()}
                      >{'Generar'}</Button>
                    </div>
                  </Labeled>
                </CustomCol>
              </RowContainer>
              <RowContainer>
                {isMobile && (
                  <CustomCol lg="6" md="8" sm="12">
                    <ValidationSummary id="summary"></ValidationSummary>
                  </CustomCol>
                )}
              </RowContainer>
            </ValidationGroup>
            <RowContainer>
              <CustomCol xs="12" md="6">
                <Labeled label='Tipo de Personal:'>
                  <RadioGroup
                    name="personal"
                    layout="horizontal"
                    displayExpr="label"
                    dataSource={gruposPersonal}
                    value={generateState?.tipoPersonal ?? null}
                    onValueChange={(data) => onValueChange(data, 'tipoPersonal')}
                  />
                </Labeled>
              </CustomCol>
              <CustomCol xs="12" md="6" className='d-flex justify-content-end'>
                <Labeled label=''>
                  <div
                    style={{
                      display: 'flex',
                      gap: '2px',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <Button
                      id="btnAdd"
                      className="me-1"
                      icon='minus'
                      disabled={seleccionado === null || seleccionado === undefined}
                      stylingMode="contained"
                      type="default"
                      onClick={() => onRemoveItem()}
                    />
                  </div>
                </Labeled>
              </CustomCol>
            </RowContainer>
            <ItemsCountIndicator numberOfItems={resultados?.length} />
            <RowContainer>
              <CustomCol xs="12" md="12">
                <DataGrid
                  id="tablaGeneraRoles"
                  dataSource={resultados ?? []}
                  selection={{ mode: 'single' }}
                  hoverStateEnabled={true}
                  showBorders={true}
                  showRowLines={true}
                  onSelectionChanged={onSelectedChanged}
                  repaintChangesOnly={true}
                  highlightChanges={true}
                  keyExpr="usuario"
                  key="usuario"
                  focusedRowKey="usuario"
                  showColumnLines={true}
                  // onExporting={onExportExcell}
                  className={'m-2 p-2'}
                  remoteOperations={true}
                  loadPanel={{
                    enabled: true,
                    height: 90,
                    indicatorSrc: '',
                    shading: false,
                    shadingColor: '',
                    showIndicator: true,
                    showPane: true,
                    text: 'Cargando...',
                    width: 200,
                  }}
                >
                  <Editing
                    mode="cell"
                    allowUpdating
                    changes={changes}
                    onChangesChange={onChangesChange}
                    editRowKey={editRowKey}
                    onEditRowKeyChange={onEditRowKeyChange}
                  />
                  <Export enabled={true} allowExportSelectedData={true} />
                  <ColumnChooser enabled={true} mode="select" />
                  <FilterRow visible={true} />
                  <HeaderFilter visible={true} />
                  <Paging defaultPageSize={20} />
                  <Pager
                    visible={resultados.length > 0 ? true : false}
                    allowedPageSizes={getAllowedPageSizes(resultados)}
                    displayMode={'full'}
                    showPageSizeSelector={true}
                    showInfo={true}
                    showNavigationButtons={true}
                  />
                  <Column allowEditing={false} dataType='date' dataField='fechaLabor' caption="Fecha" width="100px" />
                  <Column allowEditing={false} dataType='string' dataField='nombre' caption="Apellidos y Nombres" width="150px" />
                  <Column allowEditing={false} dataType='string' dataField='rolDescripcion' caption="Cargo" width="120px" />
                  <Column allowEditing={false} dataType='number' dataField='nominal' caption="Sueldo Basico" width="100px" />
                  <Column
                    allowEditing={true}
                    setCellValue={(rowData, value, currentRowData) => setCellValue(rowData, value, currentRowData, 'dias')}
                    dataType='number'
                    dataField='dias'
                    caption="Días"
                    width="60px"
                  >
                    <RangeRule max={30} min={0} />
                  </Column>
                  <Column
                    setCellValue={(rowData, value, currentRowData) => setCellValue(rowData, value, currentRowData, 'HL')}
                    allowEditing={true} dataType='number' dataField='HL' caption="HL" width="50px" >
                    <RangeRule max={7} min={0} />
                  </Column>
                  <Column
                    setCellValue={(rowData, value, currentRowData) => setCellValue(rowData, value, currentRowData, 'ML')}
                    allowEditing={true} dataType='number' dataField='ML' caption="ML" width="50px" >
                    <RangeRule max={59} min={0} />
                  </Column>
                  <Column
                    setCellValue={(rowData, value, currentRowData) => setCellValue(rowData, value, currentRowData, 'jornada')}
                    allowEditing={true} dataType='number' dataField='jornada' caption="HN" width="50px" >
                    <RangeRule max={200} min={0} />
                  </Column>
                  <Column
                    setCellValue={(rowData, value, currentRowData) => setCellValue(rowData, value, currentRowData, 'MN')}
                    allowEditing={true} dataType='number' dataField='MN' caption="MN" width="50px" >
                    <RangeRule max={59} min={0} />
                  </Column>
                  <Column
                    setCellValue={(rowData, value, currentRowData) => setCellValue(rowData, value, currentRowData, 'horasSuplementarias')}
                    allowEditing={true} dataType='number' dataField='horasSuplementarias' caption="HS" width="50px" >
                    <RangeRule max={200} min={0} />
                  </Column>
                  <Column
                    setCellValue={(rowData, value, currentRowData) => setCellValue(rowData, value, currentRowData, 'MS')}
                    allowEditing={true} dataType='number' dataField='MS' caption="MS" width="50px" >
                    <RangeRule max={59} min={0} />
                  </Column>
                  <Column
                    setCellValue={(rowData, value, currentRowData) => setCellValue(rowData, value, currentRowData, 'horasExtras')}
                    allowEditing={true} dataType='number' dataField='horasExtras' caption="HE" width="50px" >
                    <RangeRule max={200} min={0} />
                  </Column>
                  <Column
                    setCellValue={(rowData, value, currentRowData) => setCellValue(rowData, value, currentRowData, 'ME')}
                    allowEditing={true} dataType='number' dataField='ME' caption="ME" width="50px" >
                    <RangeRule max={59} min={0} />
                  </Column>
                  <Column
                    setCellValue={(rowData, value, currentRowData) => setCellValue(rowData, value, currentRowData, 'anticipos')}
                    allowEditing={true} dataType='number' dataField='anticipos' caption="Anticipos" width="50px" />
                  <Column
                    setCellValue={(rowData, value, currentRowData) => setCellValue(rowData, value, currentRowData, 'bonificacion')}
                    allowEditing={true} dataType='number' dataField='bonificacion' caption="Bonificacion" width="50px" />
                  <Column
                    setCellValue={(rowData, value, currentRowData) => setCellValue(rowData, value, currentRowData, 'prestamos')}
                    allowEditing={true} dataType='number' dataField='prestamos' caption="Prestamos" width="50px" />
                  <Column
                    setCellValue={(rowData, value, currentRowData) => setCellValue(rowData, value, currentRowData, 'renta')}
                    allowEditing={true} dataType='number' dataField='renta' caption="Imp. Renta" width="50px" />
                </DataGrid>
              </CustomCol>
            </RowContainer>
          </RowContainer>
        </BlockUi>
      </div>
    </>
  )
}

export default React.memo(GenerarRoles)