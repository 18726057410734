import React from 'react'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import { TextBox } from 'devextreme-react'
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput'
import MarcasWebLookUp from '../../../../../../componentes/marcasWebLookUp'
import { useDispatch, useSelector } from 'react-redux'
import { addToast } from '../../../../../../../store/toasterReducer'
import { RootState } from '../../../../../../../store/store'
import { ToastTypes } from '../../../../../../../store/types'
import { changeFilter, fetchMarcas, setCollapsed } from '../../store/serachReducer'
import { MarcaWeb } from '../../../../../../componentes/marcasWebLookUp/types/types'
import { CCardBody, CCardHeader, CCollapse, CLink } from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilChevronBottom, cilChevronTop } from '@coreui/icons'

const FiltrosBusqueda = () => {
  const dispatch = useDispatch()
  const searchFilter = useSelector((state: RootState) => state.inventarios.catalogos.marcas.search.filter)
  const collapsed = useSelector((state: RootState) => state.inventarios.catalogos.marcas.search.colapsado)
  const [marcasWeb, setMarcasWeb] = React.useState<Array<MarcaWeb>>([])
  const [marcaWeb, setMarcaWeb] = React.useState<MarcaWeb>(null)

  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(
      addToast({
        title: 'Inventario - Marcas',
        content: mensaje,
        type: tipo,
      }),
    )
  }, [dispatch])


  const handleSearch = React.useCallback(async () => {
    try {
      const request_search: any = {
        marcaWeb: searchFilter.marcaWeb,
        nombre: searchFilter.codigo
      }
      const toAny: any = fetchMarcas(request_search)
      const res = await dispatch(toAny)
      if (res !== null && res !== undefined && res['payload'].length >= 0) {
        setToast(`${res['payload'].length} registro(os) encontrados`, ToastTypes.Success)
      } else {
        setToast(`${res} registro(os) encontrados`, ToastTypes.Danger)
      }
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
  }, [searchFilter, dispatch, setToast])

  const onChangeData = React.useCallback((data, key: string) => {
    if (data.event !== null && data.event !== undefined) {
      dispatch(changeFilter({
        ...searchFilter,
        [key]: data.value
      }))
    }
  }, [dispatch, searchFilter])

  const onFilterMarcaWeb = React.useCallback((data) => {
    onChangeData(data, 'marcaWeb')
    if (data?.event !== null && data?.event !== undefined) {
      const marcaWeb: string = data?.value
      if (marcasWeb !== null && marcasWeb !== undefined && marcasWeb.length > 0) {
        let newArayy = []
        newArayy = marcasWeb.map(item => {
          if (item?.descripcion && item?.descripcion.indexOf(marcaWeb) > 0) {
            return item
          }
        })
        setMarcasWeb(newArayy)
      }
    }
  }, [marcasWeb, onChangeData])


  return (
    <>
      <CCardHeader
        onClick={() => dispatch(setCollapsed(!collapsed))}
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <strong> {collapsed ? '' : 'Filtro de Búsqueda'} </strong>
        <CLink className="card-header-action">
          <CIcon icon={collapsed ? cilChevronBottom : cilChevronTop} />
        </CLink>
      </CCardHeader>
      <CCollapse visible={collapsed}>
        <CCardBody>
          <RowContainer>
            <CustomCol xs="12" md="4">
              <Labeled label="Nombre:">
                <TextBox
                  value={searchFilter?.codigo ?? ''}
                  onValueChanged={(data) => onChangeData(data, 'codigo')}
                  onEnterKey={handleSearch}
                />
              </Labeled>
            </CustomCol>
            <CustomCol xs="12" md="3">
              <Labeled label="Marca web:">
                <TextBox
                  value={searchFilter?.marcaWeb ?? ''}
                  onValueChanged={(data) => onFilterMarcaWeb(data)}
                  onEnterKey={handleSearch}
                />
              </Labeled>
            </CustomCol>
            <CustomCol xs="12" md="3">
              <Labeled label="">
                <MarcasWebLookUp
                  selected={marcaWeb}
                  onChanged={(data) => setMarcaWeb(data)}
                  onChangedOptions={(data) => setMarcasWeb(data)}
                  provider={marcasWeb}
                />
              </Labeled>
            </CustomCol>
          </RowContainer>
        </CCardBody>
      </CCollapse>
    </>
  )
}

export default React.memo(FiltrosBusqueda)