/* eslint-disable no-unused-vars */
/**
 * Enumerador Codigo de Subtipos
 */
export enum EFormasPago {
  efectivo = 6,
  cheque = 7,
  otros = 28,
  credito = 8,
}
