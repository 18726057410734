import {
  FunctionComponent,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Position } from '../../types/types'
import SearchFilter from './seacrhFilter'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import { DataGrid, SpeedDialAction } from 'devextreme-react'
import {
  Column,
  Export,
  Pager,
  Paging,
  Button as DatagridButton,
} from 'devextreme-react/data-grid'
import { isMobile } from 'react-device-detect'
import { getAllowedPageSizes } from '../../../../../../helpers/Helper'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/store'
import ItemsCountIndicator from '../../../../../componentes/indicadorNroRegistros'
import BlockUi from '../../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../../views/componentes/loadingindicator/loadingindicator'
import { setIsSearch, setSelectedPosition } from '../../store/searchReducer'
import { buttonClick, setButtons } from '../../store/tabsReducer'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import { utilidades } from '../../../../../../helpers/utilidades'
import { ActionSheetCommandsMobile } from './actionSheetMobile'
import { SearchViews } from '../../../../../inventario/pages/items/components/buscar'
import { IGenericTabProps } from '../../../../store/types'
import { CCol } from '@coreui/react-pro'

const SearchPosition: FunctionComponent<IGenericTabProps<Position>> = (
  props,
) => {
  const { tabId } = props
  const dispatch = useDispatch()

  const resultsSearch = useSelector(
    (state: RootState) => state.nomina.cargos.search.positionsList,
  )
  const loader = useSelector(
    (state: RootState) => state.nomina.cargos.search.loader,
  )
  const currentTab = useSelector(
    (state: RootState) => state.nomina.cargos.tabs.current,
  )
  const isSearch = useSelector(
    (state: RootState) => state.nomina.cargos.search.isSearch,
  )

  const dataGrid = useRef<any>()
  const [currentView, setCurrentView] = useState<SearchViews>(
    SearchViews.Filter,
  )

  const onSelectedItemChanged = useCallback(
    (selectedCandidate: Position[]) => {
      if (selectedCandidate?.length > 0) {
        dispatch(setSelectedPosition(selectedCandidate[0]))
        dispatch(
          setButtons({
            tabKey: tabId,
            buttons: {
              Nuevo: true,
              Editar: true,
              Eliminar: true,
              Buscar: true,
              Imprimir: true,
              Deshacer: true,
              Limpiar: true,
              Permisos: true,
              Asignar_Roles_Organigrama: true,
            },
          }),
        )
      }
    },
    [dispatch, tabId],
  )

  const onHandleDoubleClick = useCallback(() => {
    dispatch(buttonClick({ tabKey: tabId, button: ButtonTypes.edit }))
  }, [dispatch, tabId])

  const onHandleActionClick = useCallback(
    async (candidateData: Position, button: ButtonTypes) => {
      dispatch(setSelectedPosition({ ...candidateData }))
      await utilidades.sleep(10)
      dispatch(buttonClick({ tabKey: tabId, button: button }))
    },
    [dispatch, tabId],
  )

  const actionSheetCommandsMobile = useCallback(
    ({ row: { data } }) => {
      return (
        <ActionSheetCommandsMobile
          key={crypto.randomUUID()}
          onEdit={() => onHandleActionClick(data, ButtonTypes.edit)}
          onDelete={() => onHandleActionClick(data, ButtonTypes.delete)}
          onPermises={() => onHandleActionClick(data, ButtonTypes.permissions)}
        />
      )
    },
    [onHandleActionClick],
  )

  useEffect(() => {
    if (isSearch) {
      setCurrentView(SearchViews.Search)
      dispatch(setIsSearch(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearch])

  return (
    <>
      {isMobile && currentTab === 'BUSQUEDA' && (
        <>
          <SpeedDialAction
            icon="filter"
            label="Filtros"
            visible={true}
            index={1}
            onClick={() => {
              setCurrentView(SearchViews.Filter)
            }}
          />
          <SpeedDialAction
            icon="search"
            label="Búsqueda"
            visible={true}
            index={2}
            onClick={() => {
              setCurrentView(SearchViews.Search)
            }}
          />
        </>
      )}
      <BlockUi
        tag="div"
        loader={LoadingIndicator}
        blocking={loader.show}
        message={loader.mensaje}
      >
        {(currentView === SearchViews.Filter || !isMobile) && <SearchFilter />}
        {(currentView === SearchViews.Search || !isMobile) && (
          <RowContainer className="mt-2">
            <CCol xs="12" md="7">
              <ItemsCountIndicator items={resultsSearch} />
              <DataGrid
                className="mb-2"
                ref={dataGrid}
                keyExpr="codigo"
                id="tabSearchPosition"
                selection={{ mode: 'single' }}
                dataSource={resultsSearch}
                showColumnLines={true}
                showRowLines={true}
                showBorders={true}
                columnResizingMode="widget"
                loadPanel={{ enabled: true }}
                allowColumnResizing={true}
                onCellDblClick={onHandleDoubleClick}
                onSelectionChanged={({ selectedRowsData }) =>
                  onSelectedItemChanged(selectedRowsData)
                }
                remoteOperations={true}
              >
                <Paging defaultPageSize={20} />
                <Pager
                  visible={resultsSearch.length > 0 ? true : false}
                  allowedPageSizes={getAllowedPageSizes(resultsSearch)}
                  displayMode={'full'}
                  showPageSizeSelector={true}
                  showInfo={true}
                  showNavigationButtons={true}
                />
                <Export enabled={true} allowExportSelectedData={true} />
                {!isMobile && (
                  <Column type="buttons" width="70px">
                    <DatagridButton
                      icon="edit"
                      hint="Editar"
                      onClick={({ row }) =>
                        onHandleActionClick(row.data, ButtonTypes.edit)
                      }
                    />
                    <DatagridButton
                      icon="trash"
                      hint="Eliminar"
                      onClick={({ row }) =>
                        onHandleActionClick(row.data, ButtonTypes.delete)
                      }
                    />
                    <DatagridButton
                      icon="key"
                      hint="Permisos"
                      onClick={({ row }) =>
                        onHandleActionClick(row.data, ButtonTypes.permissions)
                      }
                    />
                  </Column>
                )}
                {isMobile && (
                  <Column
                    caption="Tareas"
                    cellRender={actionSheetCommandsMobile}
                    width="50px"
                    allowFiltering={false}
                  />
                )}
                <Column
                  dataField="descripcion"
                  caption="Descripción"
                  width={'100%'}
                />
              </DataGrid>
            </CCol>
          </RowContainer>
        )}
      </BlockUi>
    </>
  )
}

export default memo(SearchPosition)
