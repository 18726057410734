import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AccionMenu, OptionGrupos } from "../../../../store/types";
import { SeleccionadoTableItem } from "../../../bancos/store/types";
import { ModalOpen } from "../../../contabilidad/general/store/types";
import { ModalSeleccionRetencionState, Registros } from "./types";

const initialState: ModalSeleccionRetencionState = {
  modulo: 'seleccionRetencion',
  currentAction: "Buscar",
  currentFunction: "",
  aplicacion: 'seleccionRetencion',
  acciones: [],
  registros: [],
  seleccionado: {
    index: null,
    row: [],
  },
  modal: {
    name: "",
    open: false,
    codModulo: 0,
    parametros: []
  },
  dpformaspago: [],
  dpimpuestos: []
}

const modalSeleccionRetencionSlice = createSlice({
  name: "seleccionRetencion",
  initialState: initialState,
  reducers: {
    setCurrentAction(state, action: PayloadAction<string>) {
      state.currentAction = action.payload
    },
    setCurrentFunction(state, action: PayloadAction<string>) {
      state.currentFunction = action.payload
    },
    obtenerAcciones(state, action: PayloadAction<Array<AccionMenu>>) {
      state.acciones = action.payload
    },
    setRegistros(state, action: PayloadAction<Array<Registros>>) {
      state.registros = action.payload
    },
    setSeleccionarDato(state, action: PayloadAction<SeleccionadoTableItem>) {
      const toAdd = {
        index: action.payload.index,
        row: action.payload.row,
      }
      state.seleccionado = toAdd
    },
    setResetSeleccion(state, action: PayloadAction<any>) {
      const toAdd = {
        index: null,
        row: []
      }
      state.seleccionado = toAdd
    },
    setModalSeleccionRetencion(state, action: PayloadAction<ModalOpen>) {
      const toAdd = {
        name: action.payload.name,
        open: action.payload.open,
        codModulo: action.payload.codModulo,
        parametros: action.payload.parametros
      }
      state.modal = toAdd
    },
    setFormasPago(state, action: PayloadAction<Array<OptionGrupos>>) {
      state.dpformaspago = action.payload
    },
    setImpuestos(state, action: PayloadAction<Array<OptionGrupos>>) {
      state.dpimpuestos = action.payload
    },
  }
})

export const { setImpuestos, setFormasPago, setCurrentFunction, setModalSeleccionRetencion, obtenerAcciones, setRegistros, setCurrentAction, setSeleccionarDato, setResetSeleccion } = modalSeleccionRetencionSlice.actions;
export const modalSeleccionRetencionReducer = modalSeleccionRetencionSlice.reducer;