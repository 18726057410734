import React from 'react'
import { CCol } from '@coreui/react-pro'
import { Button, DataGrid } from 'devextreme-react'
import { Column, FilterRow, HeaderFilter, Pager, Paging, Button as DatagridButton } from 'devextreme-react/data-grid'
import RowContainer from '../../../../../../../../views/componentes/rowContainer/rowContainer'
import { GrupoInventario } from '../../../../../../../inventario/store/types'
import { ModalAdminGrupos } from '../../../../../../../inventario/components/buscarGrupo/modalAdminGrupos/modaAdminGrupos'
import { DatosEdicionGrupos } from '../../../../types/types'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../../store/store'
import { ToastTypes } from '../../../../../../../../store/types'
import Dialog from '../../../../../../../../views/componentes/librerias/bootstrap-dialog'
import { MessagesKeys, lh } from '../../../../../../../../helpers/localizationHelper'
import { setTabGrupos } from '../../../../store/editDataReducer'

interface ILookUpGruposProps extends React.PropsWithChildren {
  tabId: string
  setToast: (sms: string, tipo: ToastTypes) => void
  refForm: any
}

const Grupos: React.FC<ILookUpGruposProps> = (props,) => {
  const { tabId, setToast } = props
  const dispatch = useDispatch()

  const gruposState = useSelector((state: RootState) => { return state.clientes.clientes.editData[tabId]?.tabs?.grupos })
  const lineasIdState = useSelector((state: RootState) => { return state.clientes.clientes.editData[tabId]?.tabs?.lineas.linea })
  const dialogRef = React.useRef<any>(null);
  const tablaRefPager = React.useRef<any>()
  const [showModal, setShowModal] = React.useState<boolean>(false)


  const deleteItemTable = React.useCallback(() => {
    const filtrado = gruposState.grupos.findIndex((item: any) => {
      return Number(item.codigo) === Number(gruposState.grupo["codigo"]);
    });
    const rowsBefore = gruposState.grupos.slice();
    if (filtrado > -1) {
      rowsBefore.splice(filtrado, 1);
      dispatch(setTabGrupos({
        key: tabId,
        data: {
          grupos: rowsBefore,
          grupo: null
        }
      }))
    }
  }, [gruposState, tabId, dispatch]);



  const selectionChanged = React.useCallback((selectionChanged) => {
    if (selectionChanged['selectedRowsData'].length > 0) {
      dispatch(setTabGrupos({
        key: tabId,
        data: {
          ...gruposState,
          grupo: selectionChanged['selectedRowsData'][0]
        }
      }))
    }
  }, [tabId, gruposState, dispatch]);



  const addGrupo = React.useCallback((e: GrupoInventario) => {
    if (e !== undefined) {
      const grupos = gruposState.grupos.slice();
      console.log(e)
      const existItem = grupos.find((obj: GrupoInventario) => obj.codigo === e.codigo)
      if (existItem === undefined) {
        grupos.push({
          ...e,
          perteneceCodigo: lineasIdState.codigo,
          perteneceNombre: lineasIdState.descripcion
        })

        dispatch(setTabGrupos({
          key: tabId,
          data: {
            ...gruposState,
            grupos: grupos
          }
        }))
        setShowModal(false)
      } else {
        setToast("No se puede volver a agregar este grupo", ToastTypes.Warning)
      }
    }
  }, [gruposState, lineasIdState, setToast, dispatch, tabId]);

  const onConfirmarUpdate = React.useCallback(() => {
    const sms = lh.getMessage(MessagesKeys.FormConfirmDeleteForm);
    dialogRef.current.show({
      title: <><strong>{"Ácatha"}</strong></>,
      body: <label style={{ fontSize: "14px", fontWeight: 600 }}>{sms}</label>,
      actions: [
        Dialog.Action(
          <span><u>{"A"}</u>{"ceptar"}</span>,
          (dialog) => {
            deleteItemTable()
            dialog.hide();
          },
          'btn-success',
          'n'
        ),
        Dialog.Action(
          <span><u>{"C"}</u>{"ancelar"}</span>,
          (dialog) => {
            dialog.hide();
          },
          'btn-danger',
          'c'
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide();
      }
    });
    return;
  }, [deleteItemTable]);

  const showPopupCondition = React.useCallback((): boolean => {
    if (gruposState.grupos.length === 0) {
      return true
    } else {
      return false
    }
  }, [gruposState])

  return (
    <>
      <div>
        <Dialog ref={dialogRef} />
      </div>
      <RowContainer>

        {showModal &&
          <ModalAdminGrupos
            onChanged={(data) => {
              addGrupo(data)
            }}
            lineas={[lineasIdState.codigo]}
            onCancel={() => setShowModal(!showModal)}
            show={showModal}
          />
        }

        <CCol style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '4px' }}>
          <Button
            id="btnAdd"
            icon="add"
            disabled={lineasIdState === null}
            stylingMode="contained"
            type="default"
            onClick={() => {
              setShowModal(!showModal)
            }}
          />
          <Button
            style={{ marginLeft: '6px' }}
            id="btnRemove"
            icon="trash"
            stylingMode="contained"
            type="danger"
            onClick={() => {
              onConfirmarUpdate()
            }}
            disabled={gruposState.grupo === null}

          />
        </CCol>
      </RowContainer>
      <RowContainer>
        {
          showPopupCondition() && (
            <div style={{ color: '#e22020' }}>
              <label>{'Es necesario seleccionar al menos una Linea para añadir un Grupo'}</label>
            </div>
          )
        }
        <CCol className="d-flex justify-content-end">{gruposState.grupos.length}</CCol>
        <DataGrid
          selection={{ mode: 'single' }}
          hoverStateEnabled={true}
          dataSource={gruposState.grupos}
          showBorders={true}
          showRowLines={true}
          width={'100%'}
          onSelectionChanged={selectionChanged}
        >
          <FilterRow visible={true} />
          <HeaderFilter visible={true} />
          <Paging
            enabled={true}
            defaultPageSize={10}
          />
          <Pager
            ref={tablaRefPager}
            showPageSizeSelector={true}
            allowedPageSizes={[8, 12, 20]}
            showNavigationButtons={true}
            showInfo={true}
            infoText="Page #{0}. Total: {1} ({2} items)"
          />
          <Column type='buttons' width='7%' >
            <DatagridButton icon='arrowleft' visible={true} hint='Seleccionar'
              onClick={(e) => { console.log(e) }} />
          </Column>
          <Column dataType="string" dataField="descripcion" caption="Grupos" width="100%" />
        </DataGrid>
      </RowContainer>
    </>
  )
}

export default Grupos

export const defaultDatosEdicionGrupos: DatosEdicionGrupos = {
  grupos: [],
  grupo: null
}
