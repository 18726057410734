import React, { useEffect } from 'react'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput'
import {
  TextBox,
  ValidationGroup,
  ValidationSummary,
  Validator,
} from 'devextreme-react'
import {
  AsyncRule,
  RequiredRule,
  StringLengthRule,
} from 'devextreme-react/data-grid'
import { DesempenoListado, SavePerformance } from '../../types/types'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import {
  clearButtonClick,
  openTab,
  setButtons,
  setNameTabe,
} from '../../store/tabsReducer'
import { useDispatch, useSelector } from 'react-redux'
import { performanceServices } from '../../services/desempeno.services'
import { RootState } from '../../../../../../store/store'
import TipoEstadoLookUp from '../../../../../componentes/tipoEstado'
import { ToastTypes } from '../../../../../../store/types'
import {
  changeLoaderPerformance,
  setCurrentFunction,
} from '../../store/generalReducer'
import { addToast } from '../../../../../../store/toasterReducer'
import { initDatosEdicion, setDatosEdicion } from '../../store/editDataReduce'
import { StatesEdition } from '../../../../../ventas/types/enums'
import Dialog from '../../../../../../views/componentes/librerias/bootstrap-dialog'
import { defaultDataPerformance } from '../..'
import { v4 as uuidv4 } from 'uuid'
import { TabState } from '../../../../../../store/genericTabTypes'

interface INewProps extends React.PropsWithChildren {
  tab: TabState<DesempenoListado>
  tabId: string
}

const Nuevo: React.FunctionComponent<INewProps> = (props) => {
  const { tab, tabId } = props
  const dispatch = useDispatch()
  const currentAction = useSelector(
    (state: RootState) => state.clientes.desempeno.general.currentFunction,
  )
  const performanceState = useSelector((state: RootState) => {
    return state.clientes.desempeno.editData[tabId]
  })
  const tabs = useSelector((state: RootState) => {
    return state.clientes.desempeno.tabs
  })

  const [confirmarNuevo, setconfirmarNuevo] = React.useState<boolean>(false)
  const validationGroupRef = React.useRef<any>()
  const dialogRef = React.useRef<any>(null)

  const playLoader = React.useCallback(
    (message = 'Cargando...') => {
      dispatch(changeLoaderPerformance({ show: true, mensaje: message }))
    },
    [dispatch],
  )

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoaderPerformance({ show: false, mensaje: '' }))
  }, [dispatch])

  const onChangedCode = React.useCallback(
    (code: number) => {
      dispatch(
        setDatosEdicion({
          key: tabId,
          performance: {
            ...performanceState,
            codigo: code,
            guardado: true,
          },
        }),
      )
    },
    [dispatch, performanceState, tabId],
  )

  const onChangedName = React.useCallback(
    (data) => {
      if (data.event) {
        dispatch(
          setDatosEdicion({
            key: tabId,
            performance: {
              ...performanceState,
              nombre: data.value,
            },
          }),
        )
      }
    },
    [dispatch, performanceState, tabId],
  )

  const onChangedState = React.useCallback(
    (data) => {
      if (data) {
        dispatch(
          setDatosEdicion({
            key: tabId,
            performance: {
              ...performanceState,
              estado: data,
            },
          }),
        )
      }
    },
    [dispatch, performanceState, tabId],
  )

  const onConfirmarNuevo = React.useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea crear un nuevo registro?`,
      actions: [
        Dialog.Action(
          <span>
            <u>P</u>estaña Actual
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-success',
          'p',
        ),
        Dialog.Action(
          <span>
            <u>N</u>ueva Pestaña
          </span>,
          (dialog) => {
            dialog.hide()
            const id = uuidv4()
            dispatch(
              initDatosEdicion({
                key: id,
                data: defaultDataPerformance,
              }),
            )
            dispatch(openTab({ key: id }))
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setconfirmarNuevo(false)
    return
  }, [dispatch])

  React.useEffect(() => {
    if (confirmarNuevo) {
      onConfirmarNuevo()
    }
  }, [confirmarNuevo, onConfirmarNuevo])

  const validateState = React.useCallback((selectedState: any) => {
    if (selectedState.value.codigo > -1) {
      return Promise.resolve()
    } else {
      return Promise.reject('Estado: Debe seleccionar una opción')
    }
  }, [])

  const onHandleSave = React.useCallback(async () => {
    const result = validationGroupRef.current.instance.validate()
    if (result.isValid === false) {
      dispatch(
        addToast({
          id: '',
          autoHide: true,
          content: 'Existen Errores en los datos, por favor verifiquelos.',
          fade: true,
          title: 'Guardar',
          type: ToastTypes.Warning,
        }),
      )
    } else {
      try {
        playLoader('Guardando . . .')
        const performance: SavePerformance = {
          nombre: performanceState.nombre,
          codigo: performanceState.codigo,
          estado: performanceState.estado.codigo,
        }
        const newPerformace = await performanceServices.setPerformance(
          performance,
        )
        if (!newPerformace.error) {
          onChangedCode(
            tab.editStatus === StatesEdition.save
              ? Number(tab.info.info.codigo)
              : Number(newPerformace.auto),
          )
          dispatch(
            setNameTabe({
              key: tabId,
              codigo:
                tab.editStatus === StatesEdition.save
                  ? tab.info.info.codigo
                  : newPerformace.auto,
              nombre: performanceState.nombre,
            }),
          )
          dispatch(
            setButtons({
              tabKey: tabId,
              buttons: {
                Nuevo: true,
                Guardar: false,
                Editar: false,
                Eliminar: false,
                Buscar: true,
                Imprimir: false,
                Deshacer: false,
              },
            }),
          )
          stopLoader()
          dispatch(
            addToast({
              id: '',
              autoHide: true,
              content: newPerformace.message ?? 'Registro Satisfactorio',
              fade: true,
              title: 'Guardar',
              type: ToastTypes.Success,
            }),
          )
        } else {
          stopLoader()
          dispatch(
            addToast({
              id: '',
              autoHide: true,
              content: newPerformace.message ?? 'Error al guardar.',
              fade: true,
              title: 'Guardar',
              type: ToastTypes.Danger,
            }),
          )
        }
      } catch (error) {
        stopLoader()
        dispatch(
          addToast({
            id: '',
            autoHide: true,
            content:
              'Error al guardar ' +
              (typeof error === 'string' ? error : JSON.stringify(error)),
            fade: true,
            title: 'Guardar',
            type: ToastTypes.Danger,
          }),
        )
      }
    }
  }, [
    dispatch,
    onChangedCode,
    performanceState,
    playLoader,
    stopLoader,
    tab,
    tabId,
  ])

  const onHandleUndo = React.useCallback(() => {
    if (tab.editStatus === StatesEdition.save) {
      dispatch(
        setDatosEdicion({
          key: tabId,
          performance: {
            ...performanceState,
            nombre: tab.info.info.nombre,
            estado: {
              codigo: tab.info.info.estado.codigo,
              descripcion: '',
            },
          },
        }),
      )
    } else {
      dispatch(
        setDatosEdicion({
          key: tabId,
          performance: {
            ...performanceState,
            nombre: defaultDataPerformance.nombre,
            estado: defaultDataPerformance.estado,
          },
        }),
      )
    }
  }, [dispatch, performanceState, tab, tabId])

  const handleButtonClick = React.useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.save:
          if (tabs.current === tabId) onHandleSave()
          break
        case ButtonTypes.new:
          if (tabs.current === tabId) setconfirmarNuevo(true)
          break
        case ButtonTypes.undo:
          if (tabs.current === tabId) onHandleUndo()
          break
        default:
          break
      }
      dispatch(setCurrentFunction(''))
      dispatch(clearButtonClick(tabId))
    },
    [dispatch, onHandleSave, onHandleUndo, tabId, tabs],
  )

  const onEnterKeyHandle = React.useCallback(() => {
    if (!performanceState.guardado) onHandleSave()
  }, [onHandleSave, performanceState])

  useEffect(() => {
    if (currentAction !== '') handleButtonClick(currentAction)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAction])

  return (
    <>
      <Dialog ref={dialogRef} />
      <ValidationGroup
        key={'valitadionPerformance'}
        id={'valitadionPerformance'}
        ref={validationGroupRef}
      >
        <RowContainer>
          <ValidationSummary
            id="summaryValidatePerformance"
            style={{ marginTop: '12px' }}
          />
          <CustomCol xs="12" md="4">
            <Labeled label="Nombre:">
              <TextBox
                value={performanceState.nombre}
                onValueChanged={onChangedName}
                onEnterKey={onEnterKeyHandle}
              >
                <Validator>
                  <RequiredRule
                    message={
                      'Nombre: Debe tener al menos 3 y máximo 50 caracteres.'
                    }
                  />
                  <StringLengthRule
                    min={3}
                    max={50}
                    message={
                      'Nombre: Debe tener al menos 3 y máximo 50 caracteres.'
                    }
                  />
                </Validator>
              </TextBox>
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="3">
            <Labeled label="Estado:">
              <TipoEstadoLookUp
                onChanged={onChangedState}
                selected={performanceState?.estado ?? null}
              >
                <Validator>
                  <RequiredRule
                    message={'Estado: Debe seleccionar una opción'}
                  />
                  <AsyncRule
                    message={'Estado: Debe seleccionar una opción'}
                    validationCallback={validateState}
                  />
                </Validator>
              </TipoEstadoLookUp>
            </Labeled>
          </CustomCol>
        </RowContainer>
      </ValidationGroup>
    </>
  )
}

export default Nuevo
