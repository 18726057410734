/* eslint-disable no-case-declarations */
import React, { useEffect } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow, CTooltip, CModal, CModalHeader, CModalTitle, CModalBody,
} from '@coreui/react-pro'
import LoadPanel from 'devextreme-react/load-panel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux'
import { anticiposService } from "./service/anticipos.services"
import TabAnticipos from "./componentes/tabAnticipos"
import TabAsignados from "./componentes/tabAsignados"
import {
  setCurrentFunction, setCurrentAccion, setAsignadosBackup, setAnticiposBackup, setAsignados, setAnticipos, setFormulario, setMostrarAlerta,
  setResetSeleccionAsignados, setResetSeleccionAnticipo
} from "./store/modalAnticiposReducer"
import { NumberBox } from "devextreme-react/number-box";
import { Validator, AsyncRule } from "devextreme-react/validator";
import ValidationSummary from "devextreme-react/validation-summary";
import { TIPOS_BOTONES } from "./store/types";
import "./modalAnticipos.scss";
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import Modalform from '../../../../../views/componentes/modalform';
import { BotonMenu } from '../../../../contabilidad/pages/planCuentas/modalCuentas/store/types';
import Labelinput from '../../../../../views/componentes/Labelinput'
import { RootState } from '../../../../../store/store';
import { menuService } from '../../../../../services/menu.service';
import { DateUtils, formatoFechasApi } from '../../../../../helpers/dateUtils';
import messagges from '../../../../../config/messagges';
import { ToastTypes } from '../../../../../store/types';
import { addToast } from '../../../../../store/toasterReducer';
import { changeLoader } from '../../../../../store/reducers';
import config from '../../../../../config/config';

type ModalAnticiposProps = {
  show: boolean,
  onClose: () => void,
  cargarCuentas?: (evt) => void,
  pathVerificador: string,
  cliente?: number,
  proveedor?: number,
  comprobante?: number,
  fecha?: Date,
  modulo: number,
}

const ModalAnticipos = (props: ModalAnticiposProps) => {

  const dispatch = useDispatch();

  const { comprobante } = props;
  const loader = useSelector((state: RootState) => state.global.loader)

  const modalAnticipos = useSelector((state: RootState) => state.shared.modalAnticipos.modal);
  const currentAction = useSelector((state: RootState) => state.shared.modalAnticipos.currentAction);
  const currentFunction = useSelector((state: RootState) => state.shared.modalAnticipos.currentFunction);
  const anticiposState = useSelector((state: RootState) => state.shared.modalAnticipos.anticipos);
  const asignadosState = useSelector((state: RootState) => state.shared.modalAnticipos.asignados);
  const motrarAlerta = useSelector((state: RootState) => state.shared.modalAnticipos.motrarAlerta);
  const formularioAnticipos = useSelector((state: RootState) => state.shared.modalAnticipos.formularioAnticipos);
  const rowsSelectedAnticipos = useSelector((state: RootState) => state.shared.modalAnticipos.rowsSelectedAnticipos);
  const rowsSelectedAsignados = useSelector((state: RootState) => state.shared.modalAnticipos.rowsSelectedAsignados);

  const [options, setOptions] = React.useState<any>([]);
  const [documento, setDocumento] = React.useState<any>("");
  const [COD_MODULO, setCOD_MODULO] = React.useState<any>(props.modulo);
  const [LOAD_MODULO, setLOAD_MODULO] = React.useState<boolean>(false);
  const [deleteConfirm, setDeleteConfirm] = React.useState<boolean>(false);
  const [confirmClose, setConfirmClose] = React.useState<boolean>(false);
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [valorsinretencion, setValorsinretencion] = React.useState<number>(0);
  const [total, setTotal] = React.useState<number>(0);
  const [descuadre, setDescuadre] = React.useState<number>(0);
  const [ANT_PERTENECE, setANT_PERTENECE] = React.useState<number>(0);
  const [ASO_CODIGO, setASO_CODIGO] = React.useState<number>(0);
  const [COMPROBANTE, setCOMPROBANTE] = React.useState<number>(0);
  const [ACV_TIPO, setACV_TIPO] = React.useState<string | "COMPRA" | "VENTA" | "ASIENTO" | "ABONO CLIENTE" | "ABONO PROVEEDOR" | "INGRESO" | "EGRESO" | "">("");
  const [FECHA, setFECHA] = React.useState<Date>(new Date());
  const [seleccionCobrarVs, setSeleccionCobrarVs] = React.useState<any>({});
  const [seleccionAbonosVs, setSeleccionAbonosVs] = React.useState<any>({});
  const [seleccionPagarVs, setSeleccionPagarVs] = React.useState<any>({});

  useEffect(() => {
    if (modalAnticipos.open === true) {
      setCOD_MODULO(modalAnticipos.codModulo)
    }
  }, [modalAnticipos]);

  useEffect(() => {
    if (COD_MODULO !== "") {
      iniciar(Number(COD_MODULO))
    }
  }, [COD_MODULO]);

  useEffect(() => {
    if (currentFunction === "Eliminar") {
      dispatch(setCurrentFunction(""))
      removerAsignacion()
    }
  }, [currentFunction]);

  const botones = config['menuDefecto'].map(acc => {
    const boton: BotonMenu = {
      id: acc.text as any,
      icon: menuService.getIcon(acc.icon),
      text: acc.text
    }
    return boton;
  });

  useEffect(() => {
    if (showModal === false) {
      setShowModal(props.show)
      dispatch(setAnticipos([]))
      dispatch(setAnticiposBackup([]))
      dispatch(setAsignados([]))
      dispatch(setAsignadosBackup([]))
      setOptions(botones)
    }
  }, [props.show]);

  // React.useEffect(() => {
  //   if (anticiposState.length > 0 || asignadosState.length > 0) {
  //     ResultadoBusquedaSel()
  //   } else {
  //     setTotal(0)
  //     setDescuadre(0)
  //     // setValorsinretencion(asiento['TRA_HABER'])
  //   }
  // }, [anticiposState, asignadosState]);

  React.useEffect(() => {
    if (asignadosState.length > 0) {
      ResultadoBusquedaSel()
    } else {
      setTotal(0)
      setDescuadre(0)
      // setValorsinretencion(asiento['TRA_HABER'])
    }
  }, [asignadosState]);


  const iniciar = async (modulo: number) => {
    let _ANT_PERTENECE: number = 0;
    let _ACV_TIPO: string = "";
    let _ASO_CODIGO: number = 0;
    let _COMPROBANTE: number = 0;
    let _FECHA: Date = new Date();
    // let _valorsinretencion: number = 0;

    switch (Number(modulo)) {
      case 9:
        _ANT_PERTENECE = 3;
        _ASO_CODIGO = modalAnticipos.parametros.cliente;
        _COMPROBANTE = modalAnticipos.parametros.comprobante; // VEN_CODIGO
        _ACV_TIPO = "VENTA";
        _FECHA = modalAnticipos.parametros.fecha;
        setANT_PERTENECE(_ANT_PERTENECE);
        setASO_CODIGO(_ASO_CODIGO)
        setCOMPROBANTE(_COMPROBANTE)
        setACV_TIPO(_ACV_TIPO)
        setFECHA(_FECHA)
        const tipopago: number = 30;
        devuelveTotalSinRetencion(props?.comprobante, tipopago)
        return false;
      case 11:
        _ANT_PERTENECE = 2;
        _ACV_TIPO = "COMPRA";
        setANT_PERTENECE(_ANT_PERTENECE);
        setASO_CODIGO(props?.proveedor ?? 0)
        setCOMPROBANTE(props?.comprobante ?? 0) // COM_CODIGO
        setACV_TIPO(_ACV_TIPO)
        setFECHA(props?.fecha ?? new Date())
        return false;
      case 16:
      case 17:
        //Es egreso por lo que busco ingresos de Anticipos
        _ANT_PERTENECE = 3;
        _ACV_TIPO = "ASIENTO";
        setASO_CODIGO(props?.proveedor ?? 0)
        setANT_PERTENECE(_ANT_PERTENECE);
        setCOMPROBANTE(props?.comprobante ?? 0) // COM_CODIGO
        setFECHA(props?.fecha ?? new Date())
        setACV_TIPO(_ACV_TIPO)

        const asiento = modalAnticipos.parametros;

        dispatch(setFormulario({
          ...formularioAnticipos,
          FECHA: asiento['fecha'],
          ACV_TIPO: 'ASIENTO',
          COMPROBANTE: asiento['numeroAsiento'],
          VALOR_SIN_RETENCION: asiento['haberTotal'],
          DOCUMENTO: documento
        }))

        setValorsinretencion(asiento['TRA_HABER'])
        // CargarCombosPuntoVenta("Todos")
        // CargarCombosLocales("General")

        const objetoFind: any = {
          fecha: asiento.fecha,
          aso_codigo: ASO_CODIGO,
          ant_pertenece: ANT_PERTENECE,
          documento: "",
          comprobante: asiento.numeroAsiento,
          tipo: 'ASIENTO',
        }

        getAnticipos(objetoFind)
        return false;
      case 24:
        _ANT_PERTENECE = 3;
        setANT_PERTENECE(_ANT_PERTENECE);
        setASO_CODIGO(props?.cliente ?? 0) // CLI_CODIGO
        setCOMPROBANTE(props?.comprobante ?? 0) // ING_CODIGO
        setACV_TIPO('ABONO CLIENTE')
        if (seleccionCobrarVs === seleccionAbonosVs) {
          setFECHA(props?.fecha ?? new Date()) // ABV_FECHA
        } else {
          setFECHA(props?.fecha ?? new Date()) // fechactual_ca
        }
        return false;
      case 25:
        _ANT_PERTENECE = 2;
        setANT_PERTENECE(_ANT_PERTENECE);
        setASO_CODIGO(props?.proveedor ?? 0) // PRV_CODIGO
        setCOMPROBANTE(props?.comprobante ?? 0) // EGR_CODIGO
        setACV_TIPO('ABONO PROVEEDOR')
        if (seleccionPagarVs === seleccionAbonosVs) {
          setFECHA(props?.fecha ?? new Date()) // ABC_FECHA
        } else {
          setFECHA(props?.fecha ?? new Date()) // fechactual_ca
        }
        return false;
      case 111:
        _ANT_PERTENECE = 2;
        _FECHA = modalAnticipos.parametros.fecha_ca;
        _COMPROBANTE = modalAnticipos.parametros.ingresos_ti;
        _ACV_TIPO = "INGRESO";
        setANT_PERTENECE(_ANT_PERTENECE);
        setFECHA(_FECHA) // fechactual_ca
        setCOMPROBANTE(_COMPROBANTE) // ING_CODIGO
        setACV_TIPO(_ACV_TIPO)
        await devuelveValor(_COMPROBANTE)
        await buscar(String(_FECHA), _ASO_CODIGO, _ANT_PERTENECE, documento, _COMPROBANTE, _ACV_TIPO);

        return false;
      case 106:
        _ANT_PERTENECE = 3;
        setANT_PERTENECE(_ANT_PERTENECE);
        setFECHA(props?.fecha ?? new Date()) // fechactual_ca
        setCOMPROBANTE(props?.comprobante ?? 0) // EGR_CODIGO
        setACV_TIPO('EGRESO')
        return false;
      case 142:
        _ANT_PERTENECE = 3;
        setANT_PERTENECE(_ANT_PERTENECE);
        setFECHA(props?.fecha ?? new Date()) // fechactual_ca
        return false;
      case 400:
        _ANT_PERTENECE = 3;
        setANT_PERTENECE(_ANT_PERTENECE);
        setFECHA(props?.fecha ?? new Date()) // fechactual_ca
        setASO_CODIGO(props?.cliente ?? 0) // CLI_CODIGO
        setCOMPROBANTE(props?.comprobante ?? 0) // VEN_CODIGO
        setACV_TIPO('VENTA')
        return false;
      case 361:
        _ANT_PERTENECE = 3;
        setANT_PERTENECE(_ANT_PERTENECE);
        setFECHA(props?.fecha ?? new Date()) // fechactual_ca
        setASO_CODIGO(props?.cliente ?? 0) // CLI_CODIGO
        setCOMPROBANTE(props?.comprobante ?? 0) // ORD_CODIGO
        setACV_TIPO('VENTA')
        return false;
      default:
        return true;


    }



  }

  useEffect(() => {
    if (LOAD_MODULO === true) {
      setLOAD_MODULO(false)
    }
  }, [LOAD_MODULO]);



  const devuelveValor = async (COMPROBANTE: number) => {
    playLoader()
    const objeto = {
      codigo: COMPROBANTE
    }
    try {
      const data = await anticiposService.devuelveValor(objeto);

      if (data['error'] === false) {
        if (data['auto']) {
          setValorsinretencion(Number(data['auto']))
        }
      } else {
        setToast(data['message'], "warning")
      }

      return await data;
    } catch (error) {
      stopLoader()
      setToast(JSON.stringify(error), "danger")
    }
  }

  const devuelveTotalSinRetencion = async (COMPROBANTE, tipopago) => {
    playLoader()
    const objeto = {
      ventaCodigo: COMPROBANTE,
      tipoPago: tipopago,
    }
    try {
      const data = await anticiposService.devuelveTotalSinRetencion(objeto);
      if (data['error'] === false) {
        setToast(data['message'], "success")

        stopLoader()
      } else {
        setToast(data['message'], "warning")
      }

      return await data;
    } catch (error) {
      stopLoader()
      setToast(JSON.stringify(error), "danger")
    }
  }

  const resultadoBusqueda = (objeto) => {
    let total: number = 0;
    if (objeto.length === 0) {
      setToast("La búsqueda no produjo resultados.", "info")
    } else {
      objeto.map(acc => {
        total = Number(acc['anticipoCompraVentaValor']) + Number(total);
      });
    }
    setTotal(total)
  };

  const getAnticipos = async (objetoFind) => {
    playLoader()
    dispatch(setAnticipos([]))
    dispatch(setAnticiposBackup([]))

    const objeto = {
      fechaHasta: objetoFind['fecha'],
      anticipoAsociado: objetoFind['aso_codigo'],
      anticipoPertenece: objetoFind['ant_pertenece'],
      anticipoDocumento: objetoFind['documento'],
      anticipoCompraVentaComprobante: objetoFind['comprobante'],
      anticipoCompraVentaTipo: objetoFind['tipo'],
      anticipoCodigo: 0,
    }
    console.log('objeto', objeto)

    const anticipos = await obtenerAnticipos(objeto);
    stopLoader()
    if (anticipos['error'] === false) {
      if (anticipos['auto']['anticipos'].length > 0) {
        const resultadosAnticipos: any = [];
        anticipos['auto']['anticipos'].map((anticipo) => {
          const toAdd = {
            ...anticipo,
            ACV_VALOR: 0
          }
          resultadosAnticipos.push(toAdd);
        });
        resultadosAnticipos.sort()
        dispatch(setAnticipos(resultadosAnticipos))
        dispatch(setAnticiposBackup(resultadosAnticipos))
      }
      if (anticipos['auto']['asignados'].length > 0) {
        const asignados = anticipos['auto']['asignados'].sort();
        dispatch(setAsignados(asignados))
        dispatch(setAsignadosBackup(asignados))
        resultadoBusqueda(asignados)
      }
    }
  }

  const obtenerAnticipos = async (objeto) => {
    try {
      const data = await anticiposService.obtenerAnticipos(objeto);
      if (data['error'] === false) {
        setToast(data['message'], "success")
      } else {
        setToast(data['message'], "warning")
      }
      return await data;
    } catch (error) {
      setToast(JSON.stringify(error), "danger")
    }
  }

  const ResultadoBusquedaSel = () => {
    let total: number = 0;
    let descuadre: number = 0;

    for (let i: number = 0; i < asignadosState.length; i++) {
      total = total + Number(asignadosState[i].anticipoCompraVentaValor);
    }
    descuadre = (total - valorsinretencion)
    setTotal(total)
    setDescuadre(descuadre)
    if (Number(descuadre) !== 0 && anticiposState.length > 0) {
      dispatch(setMostrarAlerta({
        mostrar: true,
        mensaje: 'Los valores registrados no concuerdan con el valor del comprobante corríjalo.'
      }))
    }
  }

  const renderBodyConfirm = () => {
    return (<> {motrarAlerta.mensaje} </>)
  }

  const buscar = async (FECHA: any, ASO_CODIGO: number, ANT_PERTENECE: number, documento: string, COMPROBANTE: number, ACV_TIPO: string) => {
    const objetoFind: any = {
      fecha: DateUtils.format(FECHA, formatoFechasApi),
      aso_codigo: ASO_CODIGO,
      ant_pertenece: ANT_PERTENECE,
      documento: documento,
      comprobante: COMPROBANTE,
      tipo: ACV_TIPO,
    }
    await getAnticipos(objetoFind)
  }

  const returnAction = (metodo: TIPOS_BOTONES) => {
    console.log('returnAction', metodo)
    switch (metodo) {
      case TIPOS_BOTONES.BOTON_BUSCAR:
        if (currentAction === "Buscar") {
          dispatch(setAnticipos([]))
          dispatch(setAnticiposBackup([]))

          buscar(FECHA, ASO_CODIGO, ANT_PERTENECE, documento, COMPROBANTE, ACV_TIPO);
        } else {
          dispatch(setCurrentAccion(metodo))
        }
        break;
      case TIPOS_BOTONES.BOTON_ELIMINAR:
        dispatch(setCurrentFunction(TIPOS_BOTONES.BOTON_ELIMINAR))
        break;
      case TIPOS_BOTONES.BOTON_LIMPIAR:
        dispatch(setAnticipos([]))
        dispatch(setAsignados([]))
        break;
      case TIPOS_BOTONES.BOTON_GUARDAR:
        if (asignadosState.length > 0) {
          insertarAnticipos()
        } else {
          setToast("No se encontraron asignaciones registradas para guardar.", "warning")
        }
        break;
      case TIPOS_BOTONES.BOTON_DESHACER:
        iniciar(Number(COD_MODULO))
        break;
      default:
        break;
    }
  }

  const agregarAsignacion = () => {

    playLoader();

    return new Promise((resolve) => {
      setTimeout(function () {
        if (rowsSelectedAnticipos['index'] === null) {
          stopLoader();
          setToast(messagges['pleaseSelectAnticipAsoc'], "warning");
        } else {

          let existe = false;
          let total = Number(0);

          for (let i = 0; i < asignadosState.length; i++) {
            total = Number(total) + Number(asignadosState[i].anticipoValor);
            if (Number(asignadosState[i].anticipoVenta) === Number(rowsSelectedAnticipos['row']['anticipoCodigo'])) {
              existe = true;
            }
          }

          if (Number(rowsSelectedAnticipos['row']['ACV_VALOR']) === 0) {
            dispatch(setMostrarAlerta({
              mostrar: true,
              mensaje: 'El anticipo que esta intentando adicionar no puede tener valor : 0.'
            }));
            return false;
          }


          if (existe === false) {
            if (Number(anticiposState[rowsSelectedAnticipos['index']].ACV_VALOR) <= Number(anticiposState[rowsSelectedAnticipos['index']].anticipoSaldoReal)) {
              const to_Add = {
                ...rowsSelectedAnticipos['row'],
                anticipoCompraVentaCodigo: 0,
                anticipoVenta: rowsSelectedAnticipos['row']['anticipoCodigo'],
                anticipoCodigo: rowsSelectedAnticipos['row']['anticipoCodigo'],
                anticipoNumero: rowsSelectedAnticipos['row']['anticipoNumero'],
                anticipoConcepto: rowsSelectedAnticipos['row']['anticipoConcepto'],
                anticipoCompraVentaTipo: formularioAnticipos.ACV_TIPO,
                anticipoCompraVentaAsociado: formularioAnticipos.COMPROBANTE,
                ACV_VALOR: Number(anticiposState[rowsSelectedAnticipos['index']].ACV_VALOR),
                anticipoCompraVentaValor: Number(anticiposState[rowsSelectedAnticipos['index']].ACV_VALOR),
                anticipoSaldo: rowsSelectedAnticipos['row']['anticipoSaldo'],
                anticipoSaldoReal: rowsSelectedAnticipos['row']['anticipoSaldoReal']
              }
              total = Number(anticiposState[rowsSelectedAnticipos['index']].ACV_VALOR) + Number(total);

              const antcp = asignadosState.slice(0);
              antcp.push(to_Add)
              dispatch(setAsignados(antcp))
              dispatch(setResetSeleccionAnticipo(null))
              dispatch(setResetSeleccionAsignados(null))
              stopLoader();
              setToast(messagges['anticipAsocSuccess'], "success");



            } else {
              stopLoader();
              dispatch(setMostrarAlerta({
                mostrar: true,
                mensaje: 'El anticipo que esta intentando adicionar es mayor al saldo.'
              }))
            }

          } else {
            stopLoader();
            dispatch(setMostrarAlerta({
              mostrar: true,
              mensaje: 'El anticipo que esta intentando adicionar ya fue seleccionado anteriormente.'
            }))
          }
          let descuadreClase = "";

          if (total <= 0) {
            descuadreClase = "textSuccess";
          } else {
            descuadreClase = "textDanger";
          }

          dispatch(setFormulario({
            ...formularioAnticipos,
            TOTAL: Number(total).toFixed(2),
            DESCUADRE: (Number(total) - Number(valorsinretencion)).toFixed(2),
            CLASE_DESCUADRE: descuadreClase
          }))

        }

        resolve(true);

      }, 1000);
    });
  }

  const removerAsignacion = () => {
    if (rowsSelectedAsignados['index'] === null) {
      stopLoader();
      setToast("Debe elegir un registro para deshabilitarlo.", "warning");
    } else {
      setDeleteConfirm(true)
      dispatch(setMostrarAlerta({
        mostrar: true,
        mensaje: 'Está seguro que desea eliminar el registro?'
      }))
    }
  }

  const removerAnticipo = async () => {
    playLoader()
    const objeto = {
      codigo: rowsSelectedAsignados['row']['anticipoCompraVentaCodigo'],
    }
    try {
      const data = await anticiposService.removerAnticipo(objeto);
      if (data['error'] === false) {
        setToast(data['message'], "success")

        stopLoader()
        removerFilaAsignadaTable()
      } else {
        setToast(data['message'], "warning")
      }

      return await data;
    } catch (error) {
      stopLoader()
      setToast(JSON.stringify(error), "danger")
    }
  }

  const actionDeleteAsignado = () => {
    removerAsignacion()
  }

  const insertarAnticipos = async () => {

    let item: any = [];
    let contarCeros: number = 0;
    item = asignadosState.map(function (key, i) {
      if (Number(key['anticipoCompraVentaValor']) <= 0) {
        contarCeros = contarCeros + 1;
      }
      return {
        ...key,
        anticipoCompraVentaCodigo: key['anticipoCompraVentaCodigo'],
        anticipoCodigo: key['anticipoCodigo'],
        anticipoCompraVentaTipo: key['anticipoCompraVentaTipo'],
        anticipoCompraVentaAsociado: key['anticipoCompraVentaAsociado'],
        anticipoCompraVentaValor: key['anticipoCompraVentaValor'],
      }
    })

    if (contarCeros > 0) {
      dispatch(setMostrarAlerta({
        mostrar: true,
        mensaje: 'Los anticipos que esta intentando guardar no pueden tener valores inferiores o igual a : 0.'
      }))
      return false;
    }

    playLoader()
    try {
      const data = await anticiposService.insertarAnticipo(item);
      stopLoader()
      if (data['error'] === false) {
        setToast(data['message'], "success")
      } else {
        setToast(data['message'], "warning")
      }
      return await data;
    } catch (error) {
      console.log('error', error);
      stopLoader()
      setToast(JSON.stringify(error), "danger")
    }
  }

  const handleOnKeyDown = (event) => {
    const asiento = modalAnticipos.parametros;

    if (event.key === "Enter" || event.keyCode === 13) {
      if (documento !== "") {
        const objetoFind: any = {
          fecha: asiento.fecha,
          aso_codigo: ASO_CODIGO,
          ant_pertenece: ANT_PERTENECE,
          documento: documento,
          comprobante: asiento.numeroAsiento,
          tipo: 'ASIENTO',
        }

        getAnticipos(objetoFind)
      }
    } else if (event.key === "Tab" || event.keyCode === 11) {
      if (documento !== "") {
        const objetoFind: any = {
          fecha: asiento.fecha,
          aso_codigo: ASO_CODIGO,
          ant_pertenece: ANT_PERTENECE,
          documento: documento,
          comprobante: asiento.numeroAsiento,
          tipo: 'ASIENTO',
        }

        getAnticipos(objetoFind)
      }
    }
  }

  const removerFilaAsignadaTable = () => {
    const antcp = asignadosState.slice(0);
    antcp.splice(rowsSelectedAsignados['index'], 1)
    dispatch(setAsignados(antcp))
    dispatch(setResetSeleccionAsignados(null))
  }

  const setToast = (texto, type) => {
    let tipo: ToastTypes = ToastTypes.Info;
    if (type === 'warning') {
      tipo = ToastTypes.Warning;
    } else if (type === 'success') {
      tipo = ToastTypes.Success;
    } if (type === 'info') {
      tipo = ToastTypes.Info;
    } if (type === 'danger') {
      tipo = ToastTypes.Danger;
    }
    dispatch(addToast({
      id: '',
      autoHide: 3500,
      title: 'Acatha',
      content: texto,
      fade: true,
      type: tipo
    }));
  }

  const playLoader = () => {
    dispatch(changeLoader({ show: true, mensaje: 'Cargando...' }))
  }

  const stopLoader = () => {
    dispatch(changeLoader({ show: false, mensaje: '' }))
  }

  const getButtonDisabled = React.useCallback((button: BotonMenu) => {
    switch (button.id) {
      default:
        return false;
    }
  }, []);

  const optionsForm = () => {
    if (options.length > 0) {
      return (
        <>

          <div key='menuItems' className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups" style={{ marginBottom: '5px', paddingTop: '-2rem' }}>
            <div key='menuItemsBotones' className="btn-group-lg btn-group btn-sm me-2" role="group" aria-label="First group" >
              {options.map(function (x, i) {
                if (x.id !== "Editar") {
                  const prefixButton = "buttonsCuentas_";
                  const nameButon = x.text;
                  const disabled = getButtonDisabled(x);
                  return (
                    <CTooltip
                      key={"tool-" + prefixButton + nameButon}
                      placement="top"
                      content={nameButon}
                    >
                      <CButton
                        key={prefixButton + nameButon}
                        name={prefixButton + nameButon}
                        title={x.text}
                        id={prefixButton + nameButon}
                        value={x.text}
                        className="btn btn-secondary sm"
                        variant="ghost"
                        onClick={(ev) => returnAction(ev = x.text)}
                        size='sm'
                        disabled={disabled}
                      >
                        <FontAwesomeIcon size='sm' icon={x.icon ?? faQuestionCircle} />
                      </CButton>
                    </CTooltip>
                  );
                }
              })}
            </div>
          </div>

        </>
      )
    } else {
      return (<> </>)
    }
  }

  const validateAsyncTotales = (datoSeleccion) => {
    return verificarValorEnTotales(datoSeleccion);
  };

  const verificarValorEnTotales = (value) => {
    const validador = valorsinretencion;
    return new Promise((resolve) => {
      setTimeout(function () {
        if (value["value"] !== undefined) {
          resolve(
            Number(value["value"]) === Number(validador) === true
              ? true
              : false
          );
        } else {
          resolve(true);
        }
      }, 1000);
    });
  };

  const renderBody = () => {

    return (
      <>
        <Modalform
          name='confirmarCambios'
          headerTitle={'Confirmar'}
          childrenBody={renderBodyConfirm()}
          childrenFooter={renderFooter()}
          closeOnBackdrop={false}
          show={motrarAlerta.mostrar}
          onClose={() => closeModalAlerta()}
          centered={true}
          size="sm"
          key={'confirmarCambios'}
        />

        <CCard key="cardCuentas" >
          <CCardHeader >
            <CRow>
              <CCol className="ms-0 d-flex align-items-start justify-content-start" >
                {optionsForm()}
              </CCol>
              <CCol className="ms-0 d-flex align-items-start justify-content-end">
                <CButton id="btnCloseAnticipo"
                  className="btn btn-secondary sm"
                  color="secondary"
                  variant="ghost"
                  size='sm' onClick={() => preClose()} >
                  <FontAwesomeIcon
                    size="lg"
                    icon={['fal', "times"]} style={{ fontWeight: 1 }}
                  />
                </CButton>
              </CCol>
            </CRow>
          </CCardHeader >

          <CCardBody >
            <CRow>
              <CCol lg="6" md="8" sm="12">
                <ValidationSummary id="summary"></ValidationSummary>
              </CCol>
            </CRow>

            <CRow>
              <CCol lg="12" >
                <CCard>
                  <CCardBody>
                    <CRow >
                      <CCol lg="10" >
                        <Labelinput
                          placeholder=""
                          value={documento}
                          label={"Documento:"}
                          name={'documento'}
                          readOnly={false}
                          autoComplete={"documento"}
                          onChange={(
                            ev: React.ChangeEvent<HTMLInputElement>,
                          ): void => {
                            setDocumento(ev.target.value)
                          }}
                        // onKeyPress={(event) => handleOnKeyDown(event)}

                        />
                      </CCol>
                      <CCol lg="2" className="d-flex justify-content-start"  >
                        <span>  <strong>{anticiposState.length}</strong> </span>
                      </CCol>
                    </CRow>

                    <TabAnticipos
                      agregarAsignacion={() => agregarAsignacion()}
                      removerAsignacion={() => removerAsignacion()}
                    />

                    <br></br>

                    <TabAsignados actionDeleteAsignado={() => actionDeleteAsignado()} />

                    <CRow>
                      <CCol lg="12" className="d-flex justify-content-end m-1" >
                        <div className="dx-field">
                          <div className="dx-field-label">{"Total :"}</div>
                          <div className="dx-field-value">
                            <NumberBox
                              width={"100%"}
                              value={Number(total)}
                              placeholder=""
                              min={1}
                              step={1}
                            />
                          </div>
                        </div>
                      </CCol>
                    </CRow>

                    <CRow>
                      <CCol lg="12" className="d-flex justify-content-end m-1" >
                        <div className="dx-field">
                          <div className="dx-field-label">{"Descuadre :"}</div>
                          <div className="dx-field-value">
                            <NumberBox
                              width={"100%"}
                              value={Number(descuadre)}
                              placeholder=""
                              className={"alignTextRight " + formularioAnticipos.CLASE_DESCUADRE}
                            >
                              <Validator>
                                <AsyncRule
                                  message={"Los valores registrados no concuerdan con el valor del comprobante corríjalo."}
                                  validationCallback={validateAsyncTotales}
                                />
                              </Validator>
                            </NumberBox>
                          </div>
                        </div>
                      </CCol>
                    </CRow>

                  </CCardBody>
                </CCard>
              </CCol>
            </CRow>

          </CCardBody >
        </CCard >
      </>
    )
  }

  const renderFooter = () => {

    if (currentAction === "Buscar") {
      return (
        <>
          <CButton color="primary" onClick={() => {
            dispatch(setMostrarAlerta({
              mostrar: false,
              mensaje: ""
            }))
            if (deleteConfirm === true) {
              setDeleteConfirm(false)
              removerAnticipo()
            } else if (confirmClose === true) {
              setConfirmClose(false)
              closeModal()
            }
          }}>
            {"Aceptar"}
          </CButton>

          <CButton color="secondary" onClick={() => {
            dispatch(setMostrarAlerta({
              mostrar: false,
              mensaje: ""
            }))
          }} >{"Cancelar"}</CButton>
        </>
      )
    }

    return (
      <>
      </>
    )
  }

  const obtenerDiferencia = () => {
    let sumar = 0;
    console.log('asignadosState', asignadosState)
    asignadosState.map(acc => {
      sumar = Number(acc['anticipoCompraVentaValor']) + Number(sumar);
    });
    console.log('sumar', sumar)
    console.log('Number(sumar) <= Number(valorsinretencion)', Number(sumar) <= Number(valorsinretencion))
    if (Number(sumar) <= Number(valorsinretencion)) {
      return true;
    } else {
      return false;
    }

  }

  const closeModalAlerta = () => {
    dispatch(setMostrarAlerta({
      mostrar: false,
      mensaje: ""
    }))
  }

  const closeModal = () => {
    setConfirmClose(false)
    props.onClose()
  }

  const preClose = () => {
    const res = obtenerDiferencia();
    if (res === true) {
      dispatch(setMostrarAlerta({
        mostrar: true,
        mensaje: 'PRECAUCION!!!. Esta intentando salir sin haber guardado la información necesaria. ¿Desea salir de esta ventana?'
      }))
      setConfirmClose(true)
    } else {
      closeModal()
    }
  }

  return (
    <>
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={'center'}
        visible={loader.show}
        showIndicator={true}
        shading={true}
        showPane={true}
        message='Buscando...'
        closeOnOutsideClick={false}
      />
      <CRow key="rowMain">
        <CCol key="colMain" >

          <CModal
            visible={showModal}
            onClose={() => console.log('close')}
            size={'xl'}
            backdrop={'static'}
            key={'modalAnticipos'}
            alignment='center'
          >
            <CModalHeader>
              <CModalTitle>{"Lista de Anticipos"}</CModalTitle>
            </CModalHeader>
            <CModalBody>

              {renderBody()}

            </CModalBody>
          </CModal>

        </CCol>
      </CRow>
    </>
  )
}

export default React.memo(ModalAnticipos);
