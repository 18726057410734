import React from 'react'
import Modalform from '../../../views/componentes/modalform'
import { CCard, CCardBody } from '@coreui/react-pro'
import { DataGrid, LoadPanel } from 'devextreme-react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import RowContainer from '../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../views/componentes/colContainer'
import { Column } from 'devextreme-react/data-grid'
import { DetailList } from './types/types'

interface IModalDetalleCambios extends React.PropsWithChildren {
  onCancel: () => void
  show: boolean
  data: Array<DetailList> | []
}

export const ModalDetalleCambios: React.FC<IModalDetalleCambios> = (props) => {
  const { show, onCancel, data } = props
  const loader = useSelector((state: RootState) => state.global.loader);


  const bodyModal = () => {

    return (
      <>
        <RowContainer>
          <CustomCol xs="12" md="12">
            <DataGrid
              id="tablaDetalleCambios"
              dataSource={data ?? []}
              selection={{ mode: 'single' }}
              hoverStateEnabled={true}
              showBorders={true}
              showRowLines={true}
              repaintChangesOnly={true}
              highlightChanges={true}
              keyExpr="codigo"
              key="codigo"
              focusedRowKey="codigo"
              showColumnLines={true}
              className={'m-2 p-2'}
              remoteOperations={true}
              loadPanel={{
                enabled: true,
                height: 90,
                indicatorSrc: '',
                shading: false,
                shadingColor: '',
                showIndicator: true,
                showPane: true,
                text: 'Cargando...',
                width: 200,
              }}
            >


              <Column dataField='fecha' caption="Fecha" width="100px" />
              <Column dataField='nominal' caption="Sueldo Nomina" width="100px" />
              <Column dataField='jornadaNombre' caption="Jornada" width="100px" />
              <Column dataField='horarioTrabajoDescripcion' caption="Horario" width="100px" />
              <Column dataField='rolDescripcion' caption="Cargo" width="100px" />
              <Column dataField='estadoDescripcion' caption="Estado" width="100px" />
            </DataGrid>
          </CustomCol>
        </RowContainer>
      </>
    )
  }

  const renderContent = () => {
    return (
      <CCard>
        <CCardBody>
          <LoadPanel
            shadingColor="rgba(0,0,0,0.4)"
            position={'center'}
            visible={loader.show}
            showIndicator={true}
            shading={true}
            showPane={true}
            message={loader.mensaje}
            closeOnOutsideClick={false}
          />
          {bodyModal()}
        </CCardBody>
      </CCard>
    )
  }

  return (
    <>
      <Modalform
        name={'detalleCambios'}
        key={'detalleCambios'}
        headerTitle={"Detalle de Cambios"}
        childrenBody={renderContent()}
        closeOnBackdrop={false}
        show={show}
        onClose={() => onCancel()}
        centered={true}
        size='lg'
      />
    </>
  )
}
