import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AccionMenu, LoaderInfo } from "../../../../../store/types";
import { PageState, Seleccionado, Registros, formularioBusqueda } from "./types";

const initialState: PageState = {
  modulo: 'contabilidad',
  currentAction: "Buscar",
  currentFuntion: "",
  aplicacion: 'tipoCuenta',
  currentExecute: null,
  acciones: [],
  registros: [],
  refrescaTabla: false,
  seleccionado: {
    index: null,
    row: null,
    selectedRow: null
  },
  loader: {
    show: false,
    mensaje: ''
  },
  formularioBusqueda: {
    codigo: null,
    descripcion: null,
    estado: 1,
  },
}

const tipoCuentaSlice = createSlice({
  name: "tipoCuenta",
  initialState: initialState,
  reducers: {
    setFormularioBusqueda(state, action: PayloadAction<formularioBusqueda>) {
      const toAdd = {
        codigo: action.payload.codigo,
        descripcion: action.payload.descripcion,
        estado: action.payload.estado,
      }
      state.formularioBusqueda = toAdd
    },
    obtenerAcciones(state, action: PayloadAction<Array<AccionMenu>>) {
      state.acciones = action.payload
    },
    setTipoCuentas(state, action: PayloadAction<Array<Registros>>) {
      state.registros = action.payload
    },
    setCurrentAccion(state, action: PayloadAction<any>) {
      state.currentAction = action.payload
    },
    setCurrentFunction(state, action: PayloadAction<any>) {
      state.currentFuntion = action.payload
    },
    setCurrentExecute(state, action: PayloadAction<any>) {
      state.currentExecute = action.payload
    },
    setSeleccionarTipoCuenta(state, action: PayloadAction<Seleccionado>) {
      const toAdd = {
        index: action.payload.index,
        row: action.payload.row,
        selectedRow: action.payload.selectedRow,
      }
      state.seleccionado = toAdd
    },
    setResetTipoCuenta(state) {
      const toAdd = {
        index: null,
        row: null,
        selectedRow: null,
      }
      state.seleccionado = toAdd
    },
    changeLoaderTipoCuentas(state, action: PayloadAction<LoaderInfo>) {
      state.loader = action.payload
    },
  }
})

export const { setCurrentExecute, setFormularioBusqueda, changeLoaderTipoCuentas, setCurrentFunction, obtenerAcciones, setTipoCuentas, setCurrentAccion, setSeleccionarTipoCuenta, setResetTipoCuenta } = tipoCuentaSlice.actions;
export const tipoCuentaReducer = tipoCuentaSlice.reducer;