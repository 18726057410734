import React, { useEffect } from 'react'
import {
  CBadge,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormInput,
} from '@coreui/react-pro'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput'
import { consoleService } from '../../../../../../services/console.service'
import { SmallLoadingIndicator } from '../../../../../../views/componentes/loadingindicator/loadingindicator'
import { tipoIdentificacionService } from '../../../../../componentes/tipoIdentificacion/service/tipoId.service'
import { TipoTransaccion } from '../../../../../componentes/tipoIdentificacion/store/types'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/store'
import { getIndexProvider } from '../../../../../shared/helpers/funciones'
import { estadosService } from '../../../../../componentes/estadosCivilLookUp/service/estados.service'
import { tipoSangreService } from '../../../../../componentes/tipoSangre/services/tipoSangre.service'
import { PersonalServices } from '../../services/personal.service'
import { generosGruposPersonal } from '../nuevo/components/personales'
import { GenerosCompletos } from '../../types/types'

const DetallePersonal: React.FunctionComponent<any> = (props) => {
  const { data } = props

  const localSession = useSelector((state: RootState) => state.global.session.local)


  const [loading, setLoading] = React.useState(false)
  const [loaded, setLoaded] = React.useState(false)
  const [personal, setPersonal] = React.useState<any>({})

  const onCargarPersona = React.useCallback(async (persona: any) => {
    setLoading(true)
    consoleService.log(persona, 'detalle persona')
    const detailData: any = {}
    const dataId = await tipoIdentificacionService.getTipoIdetificacion(TipoTransaccion.compra, localSession?.ciudad?.paisCodigo ?? 1)
    const dataEstadoCivil = await estadosService.getEstados("Elija un estado")
    const dataTipoSangre = await tipoSangreService.getTipoSangre('Elija tipo')
    const telefonosUsuario = await PersonalServices.getPhones(persona?.codigo ?? 0)
    consoleService.log(telefonosUsuario, '_______________telefonosUsuario')
    if (telefonosUsuario['auto'] && telefonosUsuario['error'] === false) {
      detailData.telefonos = telefonosUsuario['auto'][0]?.numero ?? ''
      detailData.telefonosDes = telefonosUsuario['auto'][0]?.descripcion ?? ''
    }
    if (dataId['auto'] && dataId['error'] === false) {
      const provider = dataId['auto'].slice(0)
      const index = await getIndexProvider(provider, 'codigo', persona?.ideCodigo ?? "-1")
      if (index > -1) {
        detailData.tipoId = provider[index]?.descripcion ?? ''
      } else {
        detailData.tipoId = provider[0]?.descripcion ?? ''
      }
    }


    if (dataEstadoCivil['auto'] && dataEstadoCivil['error'] === false) {
      const provider = dataEstadoCivil['auto'].slice(0)
      const index = await getIndexProvider(provider, 'codigo', persona?.ecivil ?? 0)
      if (index > -1) {
        detailData.estadoCivil = provider[index]?.descripcion ?? ''
      } else {
        detailData.estadoCivil = provider[0]?.descripcion ?? ''
      }
    }

    if (dataTipoSangre['auto'] && dataTipoSangre['error'] === false) {
      const provider = dataTipoSangre['auto'].slice(0)
      const index = await getIndexProvider(provider, 'descripcion', persona?.tipoSangre ?? '')
      if (index > -1) {
        detailData.tipoSangre = provider[index]?.descripcion ?? ''
      } else {
        detailData.tipoSangre = provider[0]
      }
    }

    if (generosGruposPersonal.length > 0) {
      let opcion = ''
      const provider = generosGruposPersonal.slice(0)
      const index = await getIndexProvider(provider, 'value', persona?.genero ?? 0)
      if (index > -1) {
        opcion = provider[index]?.label
      } else {
        opcion = provider[0]?.label
      }
      switch (opcion) {
        case 'M':
          detailData.genero = GenerosCompletos.m
          break;

        case 'F':
          detailData.genero = GenerosCompletos.f
          break;

        default:
          detailData.genero = GenerosCompletos.n
          break;
      }
    }


    detailData.codigo = persona?.codigo ?? ''
    detailData.codigoIess = persona?.codigoIess ?? ''
    detailData.cedula = persona?.cedula ?? ''
    detailData.nombres = persona?.nombres ?? ''
    detailData.apellidos = persona?.apellidos ?? ''
    detailData.ciuNombre = persona?.ciuNombre ?? ''
    detailData.telefono = persona?.telefono ?? ''
    detailData.direccion = persona?.direccion ?? ''
    detailData.email = persona?.email ?? ''
    consoleService.log(detailData, 'detalle data')
    setPersonal(detailData)
    setLoading(false)
    setLoaded(true)
  }, [localSession])

  useEffect(() => {
    onCargarPersona(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  if (loading) {
    return <SmallLoadingIndicator mensaje="Cargando información adicional del registro..." />
  }

  if (!loaded) {
    return <></>
  }


  return (
    <RowContainer>
      <CCol xs="12" md="10">
        <CCard
          className="border-top-primary border-top-3"
          id="busqueda-modVentas-resumen"
        >
          <CCardHeader>
            <span style={{ float: 'left' }}>
              <CBadge color="secondary" textColor="black">
                <strong>{`Código: ${personal?.codigo ?? ''}`}</strong>
              </CBadge>
            </span>
          </CCardHeader>
          <CCardBody>
            <RowContainer>
              <CustomCol sm="12" md="2">
                <Labeled label="Tipo Identificacion:">
                  <CFormInput
                    value={personal?.tipoId ?? ''}
                    readOnly={true}
                    size="sm"
                  />
                </Labeled>
              </CustomCol>
              <CustomCol sm="12" md="2">
                <Labeled label="Identificacion:">
                  <CFormInput
                    value={personal?.cedula ?? ''}
                    readOnly={true}
                    size="sm"
                  />
                </Labeled>
              </CustomCol>
              <CustomCol sm="12" md="2">
                <Labeled label="Nombres:">
                  <CFormInput value={personal?.nombres ?? ''} readOnly={true} size="sm" />
                </Labeled>
              </CustomCol>
              <CustomCol sm="12" md="2">
                <Labeled label="Apellidos:">
                  <CFormInput value={personal?.apellidos ?? ''} readOnly={true} size="sm" />
                </Labeled>
              </CustomCol>
              <CustomCol sm="12" md="2">
                <Labeled label="Codigo IESS:">
                  <CFormInput value={personal?.codigoIess ?? ''} readOnly={true} size="sm" />
                </Labeled>
              </CustomCol>

            </RowContainer>
            <RowContainer>
              <CustomCol sm="12" md="2">
                <Labeled label="Estado Civil:">
                  <CFormInput
                    value={personal?.estadoCivil ?? ''}
                    readOnly={true}
                    size="sm"
                  />
                </Labeled>
              </CustomCol>
              <CustomCol sm="12" md="2">
                <Labeled label="Tipo Sangre:">
                  <CFormInput
                    value={personal?.tipoSangre ?? ''}
                    readOnly={true}
                    size="sm"
                  />
                </Labeled>
              </CustomCol>
              <CustomCol sm="12" md="2">
                <Labeled label="Ciudad:">
                  <CFormInput
                    value={personal?.ciuNombre ?? ''}
                    readOnly={true}
                    size="sm"
                  />
                </Labeled>
              </CustomCol>
              <CustomCol sm="12" md="2">
                <Labeled label="Teléfono:">
                  <CFormInput value={personal?.telefono ?? ''} readOnly={true} size="sm" />
                </Labeled>
              </CustomCol>
              <CustomCol sm="12" md="3">
                <Labeled label="Dirección:">
                  <CFormInput
                    value={personal?.direccion ?? ''}
                    readOnly={true}
                    size="sm"
                  />
                </Labeled>
              </CustomCol>
            </RowContainer>
            <RowContainer>
              <CustomCol sm="12" md="4">
                <Labeled label="Email:">
                  <CFormInput value={personal?.email ?? ''} readOnly={true} size="sm" />
                </Labeled>
              </CustomCol>
              <CustomCol sm="12" md="4">
                <Labeled label="Teléfono:">
                  <CFormInput value={`${personal?.telefonos ?? ''} - ${personal?.telefonosDes ?? ''}`} readOnly={true} size="sm" />
                </Labeled>
              </CustomCol>
              <CustomCol sm="12" md="3">
                <Labeled label="Genero:">
                  <CFormInput value={personal?.genero ?? ''} readOnly={true} size="sm" />
                </Labeled>
              </CustomCol>
            </RowContainer>
          </CCardBody>
        </CCard>
      </CCol>
    </RowContainer>
  )
}

export default React.memo(DetallePersonal)
