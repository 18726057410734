import React from 'react'
import { Button, DateBox, NumberBox, TextBox } from 'devextreme-react'
import CustomCol from '../../../../../../../../views/componentes/colContainer'
import { CBadge, CCard, CCardBody, CCardHeader, CCol, CRow } from '@coreui/react-pro'
import Labeled from '../../../../../../../../views/componentes/labeledInput/labeledInput'
import RowContainer from '../../../../../../../../views/componentes/rowContainer/rowContainer'
import { DatosEdicionHistoricosCliente } from '../../../../types/types'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../../store/store'
import { DateUtils } from '../../../../../../../../helpers/dateUtils'
import { setTabHistorico } from '../../../../store/editDataReducer'

interface IHistoricosProps {
  tabId: string
  refForm: any
}

const Historicos: React.FunctionComponent<IHistoricosProps> = (props) => {
  const { tabId } = props
  const dispatch = useDispatch()

  const limiteCreditoFecha = useSelector((state: RootState) => {
    return state.clientes.clientes.editData[props.tabId]?.tabs?.historico?.limiteCreditoFecha
  })
  const ventasAnuales = useSelector((state: RootState) => {
    return state.clientes.clientes.editData[props.tabId]?.tabs?.historico?.ventasAnuales
  })
  const ultimoPago = useSelector((state: RootState) => {
    return state.clientes.clientes.editData[props.tabId]?.tabs?.historico?.ultimoPago
  })
  const ultimaVenta = useSelector((state: RootState) => {
    return state.clientes.clientes.editData[props.tabId]?.tabs?.historico?.ultimaVenta
  })

  const datosHistoricoState = useSelector((state: RootState) => {
    return state.clientes.clientes.editData[props.tabId]?.tabs?.historico
  })

  const onChangeLimiteCreditoFecha = React.useCallback((data) => {
    if (data.value !== null) {
      dispatch(setTabHistorico({
        key: tabId,
        data: {
          ...datosHistoricoState,
          limiteCreditoFecha: data.value ?? null,
        }
      }))
    }
  }, [dispatch, tabId, datosHistoricoState])

  const onChangeVentasAnuales = React.useCallback((data) => {
    if (data.value !== null) {
      dispatch(setTabHistorico({
        key: tabId,
        data: {
          ...datosHistoricoState,
          ventasAnuales: data.value ?? ''
        }
      }))
    }
  }, [dispatch, tabId, datosHistoricoState])

  const onChangeFechaUltimoPago = React.useCallback((data) => {
    if (data.value !== null) {
      dispatch(setTabHistorico({
        key: tabId,
        data: {
          ...datosHistoricoState,
          ultimoPago: {
            ...ultimoPago,
            fecha: data.value ?? null
          }
        }
      }))
    }
  }, [dispatch, tabId, datosHistoricoState, ultimoPago])

  const onChangeDocumentoUltimoPago = React.useCallback((data) => {
    if (data.value !== null) {
      dispatch(setTabHistorico({
        key: tabId,
        data: {
          ...datosHistoricoState,
          ultimoPago: {
            ...ultimoPago,
            documento: data.value
          }
        }
      }))
    }
  }, [dispatch, tabId, datosHistoricoState, ultimoPago])

  const onChangeMontoUltimoPago = React.useCallback((data) => {
    if (data.value !== null) {
      dispatch(setTabHistorico({
        key: tabId,
        data: {
          ...datosHistoricoState,
          ultimoPago: {
            ...ultimoPago,
            monto: data.value
          }
        }
      }))
    }
  }, [dispatch, tabId, datosHistoricoState, ultimoPago])


  const onChangeFechaUltimaVenta = React.useCallback((data) => {
    if (data.value !== null) {
      dispatch(setTabHistorico({
        key: tabId,
        data: {
          ...datosHistoricoState,
          ultimaVenta: {
            ...ultimaVenta,
            fecha: data.value ?? null
          }
        }
      }))
    }
  }, [dispatch, tabId, datosHistoricoState, ultimaVenta])

  const onChangeDocumentoUltimaVenta = React.useCallback((data) => {
    if (data.value !== null) {
      dispatch(setTabHistorico({
        key: tabId,
        data: {
          ...datosHistoricoState,
          ultimaVenta: {
            ...ultimaVenta,
            documento: data.value
          }
        }
      }))
    }
  }, [dispatch, tabId, datosHistoricoState, ultimaVenta])

  const onChangeMontoUltimaVenta = React.useCallback((data) => {
    if (data.value !== null) {
      dispatch(setTabHistorico({
        key: tabId,
        data: {
          ...datosHistoricoState,
          ultimaVenta: {
            ...ultimaVenta,
            monto: data.value
          }
        }
      }))
    }
  }, [dispatch, tabId, datosHistoricoState, ultimaVenta])

  return (
    <>
      <RowContainer>
        <CustomCol xs="12" md="6">
          <Labeled label="Límite de Crédito:">
            <CRow>
              <CCol lg="4" xs="3">
                {'Hasta: '}
              </CCol>
              <CCol lg="8" xs="3">
                <DateBox
                  displayFormat='dd/MM/yyyy'
                  value={limiteCreditoFecha ?? null}
                  onValueChanged={(data) => {
                    if (data.event !== undefined) {
                      onChangeLimiteCreditoFecha(data)
                    }
                  }}
                />
              </CCol>
            </CRow>
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="6">
          <Labeled label="Ventas Anuales:">
            <CRow>
              <CCol lg="10" xs="3">
                <NumberBox
                  name="ventasAnuales"
                  placeholder=""
                  value={ventasAnuales}
                  onValueChanged={(data) => {
                    if (data.event !== undefined) {
                      onChangeVentasAnuales(data)
                    }
                  }}
                  inputAttr={{
                    autocomplete: 'nope',
                  }}
                  className='textInputAlignRight'
                />
              </CCol>
              <CCol lg="2" xs="3">
                <Button id="btnAdd" icon="help" stylingMode="contained" type="default" />
              </CCol>
            </CRow>
          </Labeled>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="6">
          <CCard className="border-top-info border-top-3" style={{ marginTop: '4px' }}>
            <CCardHeader>
              <CBadge textColor="black">{"Último Pago"}</CBadge>
            </CCardHeader>
            <CCardBody>
              <RowContainer>
                <CustomCol xs="12" md="12" lg="12" className="mt-1 mb-1">
                  <CRow>
                    <CCol lg="6" xs="6">
                      {'Fecha: '}
                    </CCol>
                    <CCol lg="6" xs="6">
                      <DateBox
                        displayFormat='dd/MM/yyyy'
                        value={ultimoPago?.fecha ?? null}
                        onValueChanged={(data) => {
                          if (data.event !== undefined) {
                            onChangeFechaUltimoPago(data)
                          }
                        }}
                      />
                    </CCol>
                  </CRow>
                </CustomCol>
                <CustomCol xs="12" md="12" lg="12" className="mt-1 mb-1">
                  <CRow style={{ marginTop: '4px' }}>
                    <CCol lg="6" xs="6">
                      {'Documento: '}
                    </CCol>
                    <CCol lg="6" xs="6">
                      <TextBox
                        name="documentoUltimoPago"
                        placeholder=""
                        value={ultimoPago?.documento ?? ''}
                        onValueChanged={(data) => {
                          if (data.event !== undefined) {
                            onChangeDocumentoUltimoPago(data)
                          }
                        }}
                        inputAttr={{
                          autocomplete: 'nope',
                        }}
                      />
                    </CCol>
                  </CRow>
                </CustomCol>
                <CustomCol xs="12" md="12" lg="12" className="mt-1 mb-1">
                  <CRow>
                    <CCol lg="6" xs="6">
                      {'Monto: '}
                    </CCol>
                    <CCol lg="6" xs="6">
                      <NumberBox
                        name="montoUltimoPago"
                        placeholder=""
                        value={ultimoPago?.monto ?? 0}
                        onValueChanged={(data) => {
                          if (data.event !== undefined) {
                            onChangeMontoUltimoPago(data)
                          }
                        }}
                        inputAttr={{
                          autocomplete: 'nope',
                        }}
                        className='textInputAlignRight'
                      />
                    </CCol>
                  </CRow>
                </CustomCol>
              </RowContainer>
            </CCardBody>
          </CCard>
        </CustomCol>
        <CustomCol xs="12" md="6">
          <CCard className="border-top-info border-top-3" style={{ marginTop: '4px' }}>
            <CCardHeader>
              <CBadge textColor="black">{'Última Venta'}</CBadge>
            </CCardHeader>
            <CCardBody>
              <RowContainer>
                <CustomCol xs="12" md="12" lg="12" className="mt-1 mb-1">
                  <CRow>
                    <CCol lg="6" xs="6">
                      {'Fecha: '}
                    </CCol>
                    <CCol lg="6" xs="6">
                      <DateBox
                        displayFormat='dd/MM/yyyy'
                        value={ultimaVenta?.fecha ?? null}
                        onValueChanged={(data) => {
                          if (data.event !== undefined) {
                            onChangeFechaUltimaVenta(data)
                          }
                        }}
                      />
                    </CCol>
                  </CRow>
                </CustomCol>
                <CustomCol xs="12" md="12" lg="12" className="mt-1 mb-1">
                  <CRow>
                    <CCol lg="6" xs="6">
                      {'Documento: '}
                    </CCol>
                    <CCol lg="6" xs="6">
                      <TextBox
                        name="documentoUltimaVenta"
                        placeholder=""
                        value={ultimaVenta?.documento ?? ''}
                        onValueChanged={(data) => {
                          if (data.event !== undefined) {
                            onChangeDocumentoUltimaVenta(data)
                          }
                        }}
                        inputAttr={{
                          autocomplete: 'nope',
                        }}
                      />
                    </CCol>
                  </CRow>
                </CustomCol>
                <CustomCol xs="12" md="12" lg="12" className="mt-1 mb-1">
                  <CRow>
                    <CCol lg="6" xs="6">
                      {'Monto: '}
                    </CCol>
                    <CCol lg="6" xs="6">
                      <NumberBox
                        name="montoUltimaVenta"
                        placeholder=""
                        value={ultimaVenta?.monto ?? 0}
                        onValueChanged={(e) => {
                          if (e.event !== undefined) {
                            onChangeMontoUltimaVenta(e)
                          }
                        }}
                        inputAttr={{
                          autocomplete: 'nope',
                        }}
                        className='textInputAlignRight'
                      />
                    </CCol>
                  </CRow>
                </CustomCol>
              </RowContainer>
            </CCardBody>
          </CCard>
        </CustomCol>
      </RowContainer>
    </>
  )
}

export default Historicos

export const defaultDatosEdicionHistoricosCliente: DatosEdicionHistoricosCliente = {
  limiteCreditoFecha: DateUtils.getCurrentDateAsString(),
  ventasAnuales: 0,
  ultimoPago: { fecha: DateUtils.getCurrentDateAsString(), documento: '', monto: 0 },
  ultimaVenta: { fecha: DateUtils.getCurrentDateAsString(), documento: '', monto: 0 },
}