import React, { useEffect } from 'react'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import { GemasDatosEdicion, GuardarGema } from '../../types/types'
import { ButtonTypes } from '../../../../../../../views/componentes/globalMenu/types'
import { clearButtonClick, openTab } from '../../store/tabsReducer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import {
  changeLoaderGems,
  setCurrentFunction,
} from '../../store/generalReducer'
import { initDatosEdicion, setCurrentTab, setDatosEdicion } from '../../store/editDataReducer'
import Dialog from '../../../../../../../views/componentes/librerias/bootstrap-dialog'
import { v4 as uuidv4 } from 'uuid'
import { defaultDataAplications } from '../..'
import {
  CCol,
  CNav,
  CNavItem,
  CNavLink,
  CRow,
  CTabContent,
  CTabPane,
} from '@coreui/react-pro'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput'
import { TextBox, ValidationGroup, ValidationSummary, Validator } from 'devextreme-react'
import TipoEstadoLookUp from '../../../../../../componentes/tipoEstado'
import { TabState } from '../../../../../../../store/genericTabTypes'
import { AsyncRule, RequiredRule } from 'devextreme-react/validator'
import validations from '../../../../../../../config/validations'
import { addToast } from '../../../../../../../store/toasterReducer'
import { ToastTypes } from '../../../../../../../store/types'
import { MessagesKeys, lh } from '../../../../../../../helpers/localizationHelper'
import { GemsServices } from '../../services/gemas.services'

interface INewProps extends React.PropsWithChildren {
  tab: TabState<GemasDatosEdicion>
  tabId: string
}

const Nuevo: React.FunctionComponent<INewProps> = (props) => {
  const { tab, tabId } = props
  const dispatch = useDispatch()
  const currentAction = useSelector((state: RootState) => state.inventarios.catalogos.gemas.general.currentFunction)
  const gemsState = useSelector((state: RootState) => state.inventarios.catalogos.gemas.editData[tabId])
  const tabs = useSelector((state: RootState) => state.inventarios.catalogos.gemas.tabs)
  const currentTabState = useSelector((state: RootState) => state.inventarios.catalogos.gemas.editData[tabId])

  const [confirmNew, setonfirmNew] = React.useState<boolean>(false)
  const validationEngRef = React.useRef<any>()
  const validationCortRef = React.useRef<any>()
  const validationPosRef = React.useRef<any>()
  const dialogRef = React.useRef<any>(null)
  const sms = validations['es']
  const mensajeEstado: string = 'Estado: Debe seleccionar una opción'

  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(
      addToast({
        title: 'Inventario - Gemas',
        content: mensaje,
        type: tipo,
      }),
    )
  }, [dispatch])

  const playLoader = React.useCallback((message = 'Cargando...') => {
    dispatch(changeLoaderGems({ show: true, mensaje: message }))
  }, [dispatch])

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoaderGems({ show: false, mensaje: '' }))
  }, [dispatch])

  const onChangeValue = React.useCallback((data, key: string) => {
    if (data !== null && data !== undefined) {
      let value = null
      if (data?.value !== null && data?.value !== undefined) {
        value = data?.value
      } else {
        value = data
      }
      if (value !== null && value !== undefined) {
        dispatch(setDatosEdicion({
          key: tabId,
          gema: {
            ...gemsState,
            [key]: value
          }
        }))
      }
    }
  }, [dispatch, gemsState, tabId])

  const onConfirmNew = React.useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea crear un nuevo registro?`,
      actions: [
        Dialog.Action(
          <span>
            <u>P</u>estaña Actual
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-success',
          'p',
        ),
        Dialog.Action(
          <span>
            <u>N</u>ueva Pestaña
          </span>,
          (dialog) => {
            dialog.hide()
            const id = uuidv4()
            dispatch(
              initDatosEdicion({
                key: id,
                data: defaultDataAplications,
              }),
            )
            dispatch(openTab({ key: id }))
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setonfirmNew(false)
    return
  }, [dispatch])

  React.useEffect(() => {
    if (confirmNew) {
      onConfirmNew()
    }
  }, [confirmNew, onConfirmNew])

  const validateState = React.useCallback((selectedState: any, mensaje: string) => {
    if (selectedState.value.codigo > -1) {
      return Promise.resolve()
    } else {
      return Promise.reject(mensaje)
    }
  }, [])

  const validateAsyncSeleccionEstado = React.useCallback((datoSeleccion) => {
    return validateState(datoSeleccion, mensajeEstado)
  }, [validateState])

  const onHandleSave = React.useCallback(async () => {
    let saveData = false
    let tipo = 0
    if (gemsState?.currentTab === "Engaste Gemas") {
      const resultEng = validationEngRef.current.instance.validate()
      if (resultEng.isValid) {
        saveData = true
        tipo = 1
      } else {
        setToast(lh.getMessage(MessagesKeys.GlobalErrorSave), ToastTypes.Warning)
      }

    } else if (gemsState?.currentTab === "Corte Gemas") {
      const resultCort = validationCortRef.current.instance.validate()
      if (resultCort.isValid) {
        saveData = true
        tipo = 2
      } else {
        setToast(lh.getMessage(MessagesKeys.GlobalErrorSave), ToastTypes.Warning)
      }

    } else if (gemsState?.currentTab === "Posición Gemas") {
      const resultPos = validationPosRef.current.instance.validate()
      if (resultPos.isValid) {
        saveData = true
        tipo = 3
      } else {
        setToast(lh.getMessage(MessagesKeys.GlobalErrorSave), ToastTypes.Warning)
      }
    }

    if (saveData && tipo !== 0) {
      try {
        const save_data: GuardarGema = {
          infoRegistro: {
            codigo: gemsState?.codigo ?? 0,
            descripcion: gemsState?.descripcion ?? "",
            tamanio: "",
            estado: gemsState.estado?.codigo ?? 0,
            tipo,
          }
        }
        playLoader()
        const data = await GemsServices.setGem(save_data)
        stopLoader()
        if (data !== null && data !== undefined && data['error'] === false) {
          setToast(data['message'], ToastTypes.Success)
        } else {
          setToast(data['message'], ToastTypes.Success)
        }
      } catch (error) {
        stopLoader()
        setToast(error.message, ToastTypes.Success)
      }
    }

  }, [gemsState, setToast, playLoader, stopLoader])

  const onHandleBroom = React.useCallback(() => {
    dispatch(setDatosEdicion({
      key: tabId,
      gema: {
        ...gemsState,
        codigo: 0,
        descripcion: '',
        pertenece: null,
        estado: null,
        observaciones: '',
        engaste: { descripcion: '', estado: null },
        corte: { descripcion: '', estado: null },
        posicion: { descripcion: '', estado: null },
        tabs: ['Engaste Gemas', 'Corte Gemas', 'Posición Gemas'],
        guardado: false,
      }
    }))
  }, [dispatch, gemsState, tabId])

  const handleButtonClick = React.useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.save:
          if (tabs.current === tabId) onHandleSave()
          break
        case ButtonTypes.new:
          if (tabs.current === tabId) setonfirmNew(true)
          break
        case ButtonTypes.broom:
          if (tabs.current === tabId) onHandleBroom()
          break
        default:
          break
      }
      dispatch(setCurrentFunction(''))
      dispatch(clearButtonClick(tabId))
    },
    [dispatch, onHandleSave, onHandleBroom, tabId, tabs],
  )

  useEffect(() => {
    if (currentAction !== '') handleButtonClick(currentAction)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAction])

  return (
    <RowContainer className="m-2">
      <Dialog ref={dialogRef} />
      <CNav variant="tabs" role="tablist" className="mt-4">
        <CNav variant="tabs">
          {currentTabState.tabs.map((tab, index) => (
            <CNavItem key={index}>
              <CNavLink
                onClick={() =>
                  dispatch(
                    setCurrentTab({
                      key: tabId,
                      tab: currentTabState.tabs[index],
                    }),
                  )
                }
                active={currentTabState.currentTab === tab}
              >
                {tab}
              </CNavLink>
            </CNavItem>
          ))}
        </CNav>
        <CTabContent
          style={{
            overflowY: 'unset',
            width: '100%',
            marginBottom: '10px',
          }}
        >
          <CTabPane
            role="tabpanel"
            aria-labelledby="items-tab"
            visible={currentTabState.currentTab === currentTabState.tabs[0]}
          >
            <ValidationGroup id={'formEngaste'} ref={validationEngRef}>
              <RowContainer className="mt-2">
                <CustomCol xs="12" md="3">
                  <Labeled label="Descripción:">
                    <TextBox
                      value={gemsState?.descripcion ?? ''}
                      onValueChanged={(data) => onChangeValue(data, 'descripcion')}
                    >
                      <Validator>
                        <RequiredRule message={'Nombre: ' + sms['required']} />
                      </Validator>
                    </TextBox>
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="3">
                  <Labeled label="Estado:">
                    <TipoEstadoLookUp onChanged={(data) => onChangeValue(data, 'estado')} selected={gemsState.estado ?? null} >
                      <Validator>
                        <RequiredRule message={mensajeEstado} />
                        <AsyncRule
                          message={mensajeEstado}
                          validationCallback={validateAsyncSeleccionEstado}
                        />
                      </Validator>
                    </TipoEstadoLookUp>
                  </Labeled>
                </CustomCol>
              </RowContainer>
              <CRow>
                <CCol lg="6" md="8" sm="12">
                  <ValidationSummary id="summary"></ValidationSummary>
                </CCol>
              </CRow>
            </ValidationGroup>
          </CTabPane>
          <CTabPane
            role="tabpanel"
            aria-labelledby="todos-tab"
            visible={currentTabState.currentTab === currentTabState.tabs[1]}
          >
            <ValidationGroup id={'formEngaste'} ref={validationCortRef}>
              <RowContainer className="mt-2">
                <CustomCol xs="12" md="3">
                  <Labeled label="Descripción:">
                    <TextBox
                      value={gemsState?.descripcion ?? ''}
                      onValueChanged={(data) => onChangeValue(data, 'descripcion')}
                    />
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="3">
                  <Labeled label="Estado:">
                    <TipoEstadoLookUp onChanged={(data) => onChangeValue(data, 'estado')} selected={gemsState.estado ?? null} >
                      <Validator>
                        <RequiredRule message={mensajeEstado} />
                        <AsyncRule
                          message={mensajeEstado}
                          validationCallback={validateAsyncSeleccionEstado}
                        />
                      </Validator>
                    </TipoEstadoLookUp>
                  </Labeled>
                </CustomCol>
              </RowContainer>
            </ValidationGroup>
          </CTabPane>
          <CTabPane
            role="tabpanel"
            aria-labelledby="reservas-tab"
            visible={currentTabState.currentTab === currentTabState.tabs[2]}
          >
            <ValidationGroup id={'formEngaste'} ref={validationPosRef}>
              <RowContainer className="mt-2">
                <CustomCol xs="12" md="3">
                  <Labeled label="Descripción:">
                    <TextBox
                      value={gemsState?.descripcion ?? ''}
                      onValueChanged={(data) => onChangeValue(data, 'descripcion')}
                    />
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="3">
                  <Labeled label="Estado:">
                    <TipoEstadoLookUp onChanged={(data) => onChangeValue(data, 'estado')} selected={gemsState.estado ?? null} >
                      <Validator>
                        <RequiredRule message={mensajeEstado} />
                        <AsyncRule
                          message={mensajeEstado}
                          validationCallback={validateAsyncSeleccionEstado}
                        />
                      </Validator>
                    </TipoEstadoLookUp>
                  </Labeled>
                </CustomCol>
              </RowContainer>
            </ValidationGroup>
          </CTabPane>
        </CTabContent>
      </CNav>
    </RowContainer>
  )
}

export default Nuevo
