import React from 'react'
import {
  CBadge,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormInput,
  CRow,
} from '@coreui/react-pro'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput'
import { TextArea } from 'devextreme-react'
import { v4 as uuidv4 } from 'uuid'
import { typeNotification } from '../../types/types'

const DetailsNotification: React.FunctionComponent<any> = (props) => {
  const { data, typeNotification } = props

  const getTypeNotification = React.useCallback(
    (data: any): string => {
      const selectedType = typeNotification.find(
        (type: typeNotification) => data === type.value,
      )
      if (selectedType) return selectedType.label
      return 'N/A'
    },
    [typeNotification],
  )

  return (
    <RowContainer>
      <CCol xs="12" md="12">
        <CCard
          className="border-top-primary border-top-3"
          id="busqueda-modVentas-resumen"
        >
          <CCardHeader>
            <span style={{ float: 'left' }}>
              <CBadge color="secondary" textColor="black">
                <strong>{`Título: ${data.titulo}`}</strong>
              </CBadge>
            </span>
            <span style={{ float: 'right' }}>
              <CBadge color="secondary" textColor="black">
                <strong>{`Código: ${data.codigo}`}</strong>
              </CBadge>
            </span>
          </CCardHeader>
          <CCardBody>
            <RowContainer>
              <CustomCol sm="12" md="12">
                <Labeled label="Descripción:">
                  <TextArea minHeight={100} readOnly value={data.descripcion} />
                </Labeled>
              </CustomCol>
            </RowContainer>
            <RowContainer>
              <CustomCol sm="12" md="2">
                <Labeled label="Fecha Inicio:">
                  <CFormInput
                    value={`${data.fechaInicio} - 00:00:00`}
                    readOnly={true}
                    size="sm"
                  />
                </Labeled>
              </CustomCol>
              <CustomCol sm="12" md="2">
                <Labeled label="Fecha Fin:">
                  <CFormInput
                    value={`${data.fechaFin} - 23:59:59`}
                    readOnly={true}
                    size="sm"
                  />
                </Labeled>
              </CustomCol>
              <CustomCol sm="12" md="2">
                <Labeled label="Tipo de Notificación:">
                  <CFormInput
                    value={getTypeNotification(data.tipoId)}
                    readOnly={true}
                    size="sm"
                  />
                </Labeled>
              </CustomCol>
              <CustomCol sm="12" md="1">
                <Labeled label="Permanente:">
                  <CFormInput
                    value={data.permanente === 1 ? 'SI' : 'NO'}
                    readOnly={true}
                    size="sm"
                  />
                </Labeled>
              </CustomCol>
              <CustomCol sm="12" md="2">
                <Labeled label="Mostrar Popup:">
                  <CFormInput
                    value={data.mostratPopup === 1 ? 'SI' : 'NO'}
                    readOnly={true}
                    size="sm"
                  />
                </Labeled>
              </CustomCol>
              <CustomCol sm="12" md="3">
                <Labeled label="Nombe Imagen:">
                  <CFormInput value={data.imagen} readOnly={true} size="sm" />
                </Labeled>
              </CustomCol>
            </RowContainer>
            <CRow className="d-flex justify-content-end">
              {data.imagen && data.imagen.length > 0 && (
                <img
                  key={uuidv4()}
                  id={uuidv4()}
                  // eslint-disable-next-line no-undef
                  src={`${process.env['REACT_APP_PATH_FOTOS_NOTIFICACIONES']}${data.imagen}`}
                  className="avathar"
                  style={{
                    float: 'right',
                    marginTop: '12px',
                    maxWidth: '250px',
                    maxHeight: '250px',
                    objectFit: 'cover',
                  }}
                  alt="imagen_notificacion"
                />
              )}
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </RowContainer>
  )
}

export default DetailsNotification
