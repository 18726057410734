import React from 'react'
import { DateBox, TextArea, TextBox, Switch, Validator } from 'devextreme-react'
import CustomCol from '../../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../../views/componentes/labeledInput/labeledInput'
import RowContainer from '../../../../../../../../views/componentes/rowContainer/rowContainer'
import { AsyncRule, RequiredRule } from 'devextreme-react/validator'
import LookUpClasificacionAdicional from '../../../../../../../componentes/lookUpClasificacionAdicional'
import LookUpDesempenoAdicional from '../../../../../../../componentes/lookUpDesempenoAdicional'
import TiposEmpresasLookUp from '../../../../../../../componentes/tiposEmpresasLookUp'
import SituacionLaboralLookUp from '../../../../../../../componentes/situacionLaboralLookUp'
import NivelesEstudioLookUp from '../../../../../../../componentes/nivelesEstudioLookUp'
import EstadosCivilLookUp from '../../../../../../../componentes/estadosCivilLookUp'
import OrigenClienteLookUp from '../../../../../../components/origenClienteLookUp/origenClienteLookUp'
import { DatosEdicionAdicionales } from '../../../../types/types'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../../store/store'
import { DateUtils, formatoFechasDatePickers } from '../../../../../../../../helpers/dateUtils'
import { setTabAdicionales } from '../../../../store/editDataReducer'
import { EstadosCivilOption } from '../../../../../../../componentes/estadosCivilLookUp/store/type'
import { ClasificacionOption } from '../../../../../../../componentes/lookUpClasificacionAdicional/store/type'
import { NivelesEstudioOption } from '../../../../../../../componentes/nivelesEstudioLookUp/store/type'
import { SituacionLaboralOption } from '../../../../../../../componentes/situacionLaboralLookUp/store/type'
import { TiposEmpresasOption } from '../../../../../../../componentes/tiposEmpresasLookUp/store/type'
import { DesempenoOption } from '../../../../../../../componentes/lookUpDesempenoAdicional/store/type'
import { OpcionCliente } from '../../../../../../components/origenClienteLookUp/store/types'

interface IAdicionalessProps {
  tabId: string
  refForm: any
}

const Adicionales: React.FunctionComponent<IAdicionalessProps> = (props) => {
  const { tabId } = props
  const dispatch = useDispatch()

  const datosAdicionalesState = useSelector((state: RootState) => {
    return state.clientes.clientes.editData[tabId].tabs?.adicionales
  })
  const [observaciones, setObservaciones] = React.useState<string>('');
  const [fechaNacimiento, setFechaNacimiento] = React.useState<string>('');
  const [estadoCivil, setEstadoCivil] = React.useState<EstadosCivilOption | null>(null);
  const [estadosCivil, setEstadosCivil] = React.useState<Array<EstadosCivilOption> | []>([]);
  const [clasificacion, setClasificacion] = React.useState<ClasificacionOption | null>(null);
  const [clasificaciones, setClasificaciones] = React.useState<Array<ClasificacionOption> | []>([]);
  const [estudios, setEstudios] = React.useState<NivelesEstudioOption | null>(null);
  const [estudiosOptions, setEstudiosOptions] = React.useState<Array<NivelesEstudioOption> | []>([]);
  const [profesion, setProfesion] = React.useState<string>('');
  const [situacionLaboral, setSituacionLaboral] = React.useState<SituacionLaboralOption | null>(null);
  const [situacionLaboralDp, setSituacionLaboralDp] = React.useState<Array<SituacionLaboralOption> | []>([]);
  const [lugarTrabajo, setLugarTrabajo] = React.useState<string>('');
  const [tipoEmpresa, setTipoEmpresa] = React.useState<TiposEmpresasOption | null>(null);
  const [tipoEmpresaDp, setTipoEmpresaDp] = React.useState<Array<TiposEmpresasOption> | []>([]);
  const [sitioWeb, setSitioWeb] = React.useState<string>('');
  const [desempeno, setDesempeno] = React.useState<DesempenoOption | null>(null);
  const [desempenoDp, setDesempenoDp] = React.useState<Array<DesempenoOption> | []>([]);
  const [origen, setOrigen] = React.useState<OpcionCliente | null>(null);
  const [origenes, setOrigenes] = React.useState<Array<OpcionCliente> | []>([]);
  const [beca, setBeca] = React.useState<boolean>(false);


  const validateAsyncSelect = React.useCallback((datoSeleccion: any, message: string) => {
    if (datoSeleccion.value.codigo > -1) {
      return Promise.resolve()
    } else {
      return Promise.reject(message)
    }
  }, [])

  const validateAsyncSelectClasification = React.useCallback(
    (datoSeleccion: Event) => {
      return validateAsyncSelect(datoSeleccion, `- Clasificación: Debe seleccionar una opción.`)
    },
    [validateAsyncSelect],
  )

  const onDataFormulario = React.useCallback((dataToFormulario: DatosEdicionAdicionales) => {
    setObservaciones(dataToFormulario?.observaciones)
    setFechaNacimiento(dataToFormulario?.fechaNacimiento)
    setEstadoCivil(dataToFormulario?.estadoCivil)
    setEstadosCivil(dataToFormulario?.estadosCivil)
    setClasificacion(dataToFormulario?.clasificacion)
    setClasificaciones(dataToFormulario?.clasificaciones)
    setEstudios(dataToFormulario?.estudios)
    setEstudiosOptions(dataToFormulario?.estudiosOptions)
    setProfesion(dataToFormulario?.profesion)
    setSituacionLaboral(dataToFormulario?.situacionLaboral)
    setSituacionLaboralDp(dataToFormulario?.situacionLaboralDp)
    setLugarTrabajo(dataToFormulario?.lugarTrabajo)
    setTipoEmpresa(dataToFormulario?.tipoEmpresa)
    setTipoEmpresaDp(dataToFormulario?.tipoEmpresaDp)
    setSitioWeb(dataToFormulario?.sitioWeb)
    setDesempeno(dataToFormulario?.desempeno)
    setDesempenoDp(dataToFormulario?.desempenoDp)
    setOrigen(dataToFormulario?.origen)
    setOrigenes(dataToFormulario?.origenes)
    setBeca(dataToFormulario?.beca)
  }, []);

  const onDataGlobal = React.useCallback((obj: any) => {
    dispatch(setTabAdicionales({
      key: tabId,
      data: {
        ...datosAdicionalesState,
        ...obj
      }
    }))
  }, [dispatch, tabId, datosAdicionalesState]);

  React.useEffect(() => {
    onDataFormulario(datosAdicionalesState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datosAdicionalesState])

  React.useEffect(() => {
    onDataGlobal({
      observaciones, fechaNacimiento, estadoCivil,
      clasificacion, estudios, profesion,
      situacionLaboral, lugarTrabajo, tipoEmpresa,
      sitioWeb, desempeno, origen, beca
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    observaciones, fechaNacimiento, estadoCivil,
    clasificacion, estudios, profesion,
    situacionLaboral, lugarTrabajo, tipoEmpresa,
    sitioWeb, desempeno, origen, beca
  ])

  return (
    <>
      <RowContainer>
        <CustomCol xs="12" md="12">
          <Labeled label="Observaciónes:">
            <TextArea
              name="observaciones"
              value={observaciones}
              onValueChanged={(e) => {
                if (e?.event !== null || e?.event !== undefined) {
                  setObservaciones(e.value)
                }
              }}
              inputAttr={{
                autocomplete: 'nope',
              }}
            >
            </TextArea>
          </Labeled>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="3">
          <Labeled label="Fecha de Nacimiento:">
            <DateBox
              displayFormat='dd/MM/yyyy'
              value={DateUtils.strDateToDate(fechaNacimiento) ?? null} // datosAdicionalesState?.fechaNacimiento
              onValueChanged={(e) => {
                if (e?.event !== null || e?.event !== undefined) {
                  setFechaNacimiento(DateUtils.dateToString(e.value, formatoFechasDatePickers))
                }
              }}
            >
            </DateBox>
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="3">
          <Labeled label="Estado Civil:">
            <EstadosCivilLookUp
              selected={estadoCivil}
              onChanged={(data) => {
                if (data !== null || data !== undefined) {
                  setEstadoCivil(data)
                }
              }}
              provider={estadosCivil ?? []}
              id={'lookUpEstadosCivilA'}
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="3">
          <Labeled label="Clasificación:">
            <LookUpClasificacionAdicional
              selected={clasificacion}
              provider={clasificaciones ?? []}
              onChanged={(data) => {
                if (data !== null || data !== undefined) {
                  setClasificacion(data)
                }
              }}
              id={'lookUpdicionalesClaA'}
            >
              <Validator>
                <RequiredRule message={'Clasificación: Debe seleccionar una opción'} />
                <AsyncRule
                  message={'Clasificación: Debe seleccionar una opción'}
                  validationCallback={(e) => validateAsyncSelectClasification(e)}
                />
              </Validator>
            </LookUpClasificacionAdicional>
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="3">
          <Labeled label="Estudios:">
            <NivelesEstudioLookUp
              selected={estudios ?? null}
              provider={estudiosOptions ?? []}
              onChanged={(data) => {
                if (data !== null || data !== undefined) {
                  setEstudios(data)
                }
              }}
            />
          </Labeled>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="3">
          <Labeled label="Profesión:">
            <TextBox
              name="profesion"
              value={profesion ?? ''}
              onValueChanged={(e) => {
                if (e?.event !== null || e?.event !== undefined) {
                  setProfesion(e.value)
                }
              }}
              inputAttr={{
                autocomplete: 'nope',
              }}
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="3">
          <Labeled label="Situación Laboral:">
            <SituacionLaboralLookUp
              selected={situacionLaboral ?? null}
              provider={situacionLaboralDp ?? []}
              onChanged={(data) => {
                if (data !== null || data !== undefined) {
                  setSituacionLaboral(data)
                }
              }}
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="3">
          <Labeled label="Lugar de Trabajo:">
            <TextBox
              name="lugarTrabajo"
              value={lugarTrabajo ?? ''}
              onValueChanged={(e) => {
                if (e?.event !== null || e?.event !== undefined) {
                  setLugarTrabajo(e.value)
                }
              }}
              inputAttr={{
                autocomplete: 'nope',
              }}
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="3">
          <Labeled label="Tipos:">
            <TiposEmpresasLookUp
              selected={tipoEmpresa ?? null}
              onChanged={(data) => {
                if (data !== null || data !== undefined) {
                  setTipoEmpresa(data)
                }
              }}
              provider={tipoEmpresaDp ?? []}
            />
          </Labeled>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="4">
          <Labeled label="Sitio Web:">
            <TextBox
              name="sitioWeb"
              value={sitioWeb ?? ''}
              onValueChanged={(e) => {
                if (e?.event !== null || e?.event !== undefined) {
                  setSitioWeb(e.value)
                }
              }}
              inputAttr={{
                autocomplete: 'nope',
              }}
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="4">
          <Labeled label="Desempeño:">
            <LookUpDesempenoAdicional
              selected={desempeno ?? null}
              onChanged={(data) => {
                if (data !== null || data !== undefined) {
                  setDesempeno(data)
                }
              }}
              provider={desempenoDp}
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="4">
          <Labeled label="Origen:">
            <OrigenClienteLookUp
              onChanged={(data) => {
                if (data !== null || data !== undefined) {
                  setOrigen(data)
                }
              }}
              selected={origen ?? null}
              provider={origenes ?? []}

            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="3">
          <Labeled label="Beca">
            <Switch
              value={beca ?? false}
              onValueChanged={(e) => {
                if (e?.event !== null || e?.event !== undefined) {
                  setBeca(e.value)
                }
              }}
            />
          </Labeled>
        </CustomCol>
      </RowContainer>
    </>
  )
}

export default React.memo(Adicionales)

export const defaultDatosEdicionAdicionales: DatosEdicionAdicionales = {
  observaciones: '',
  fechaNacimiento: DateUtils.getCurrentDateAsString(),
  estadoCivil: null,
  estadosCivil: [],
  clasificacion: null,
  clasificaciones: [],
  estudios: null,
  estudiosOptions: [],
  profesion: '',
  situacionLaboral: null,
  situacionLaboralDp: [],
  lugarTrabajo: '',
  tipoEmpresa: null,
  tipoEmpresaDp: [],
  sitioWeb: '',
  desempeno: null,
  desempenoDp: [],
  origen: null,
  origenes: [],
  beca: false,
}