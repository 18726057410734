import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MenuState } from '../../../../../ventas/types/types'
import { AccionMenu } from '../../../../../../store/types'

const initialState: MenuState = {
  loading: true,
  accions: [],
}

const menuMovImportacionesSlice = createSlice({
  name: 'movimientosImportacionesMenu',
  initialState: initialState,
  reducers: {
    setMenuButtons(state, action: PayloadAction<Array<AccionMenu>>) {
      state.accions = action.payload
      state.loading = false
    },
  },
})

//export const selectMenuVentas = (state: RootState) => { return state.ventas.menuVentas };
export const { setMenuButtons } = menuMovImportacionesSlice.actions
export const menuReducer = menuMovImportacionesSlice.reducer
