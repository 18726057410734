import { CCol, CRow } from '@coreui/react-pro';
import React from 'react';
import { NumberBox } from 'devextreme-react/number-box'
import { RootState } from '../../../../../../../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import {
  update3VenInicialFinal,
  updateAnuladasCierre3_tiOnly, updateDpCierre3Only, updateDptotales2Only, updateDptotales3Only, updateDptotalesOnly,
  updateLoadDpCierre3Only,
  updateTotal3_tiOnly
} from '../../../store/editDataReducer';
import DataGrid, {
  Column, HeaderFilter, Paging, Pager, Export
} from 'devextreme-react/data-grid';
import RowContainer from '../../../../../../../../../views/componentes/rowContainer/rowContainer';
import CustomCol from '../../../../../../../../../views/componentes/colContainer';
import { utilidades } from '../../../../../../../../../helpers/utilidades';
import { useGridResultadosSize } from '../../../../../../../../../hooks/useGridResultadosSize';
import { getAllowedPageSizes } from '../../../../../../../../../helpers/Helper';
import { ccDetalle, ComprobantesCuadresCajaListado, formasPagoCuadre } from '../../../../../types/types';
import TabFormasPago from '../componentes/tabFormasPago/tabFormasPago';
import { GlobalLabel } from '../../../../../../../../../views/componentes/globalLabel/globalLabel';
import ButtonsInfo from '../shared/ButtonsInfo';
import { exportFormats } from '../facturas/facturas';

interface IRetencionesProps extends React.PropsWithChildren {
  setToast: (texto, type) => void,
  playLoader: () => void,
  stopLoader: () => void,
  onErrorConfirm: (sms, title) => void,
  onConfirmarRegistro: (sms, method) => void
  tabId: string,
  statusLoaded: (load) => void
}



const Retenciones: React.FunctionComponent<IRetencionesProps> = (props) => {
  const {
    tabId, setToast, playLoader, stopLoader,
    onErrorConfirm, onConfirmarRegistro, statusLoaded
  } = props;
  const dataGrid = React.useRef<any>();


  const dispatch = useDispatch();
  const [heigth] = useGridResultadosSize();

  const local = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[tabId].local });
  const puntoVenta = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[tabId].puntoVenta });
  const fechaCierre = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].fechaCierre });
  const venInicial3 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venInicial3 });
  const venFinal3 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venFinal3 });
  const generarCuadre = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].generarCuadre });
  const loaddpcierre3 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].loaddpcierre3 });

  const anuladascierre3_ti = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].anuladascierre3_ti });
  const total3_ti = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].total3_ti });
  const dptotales3 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dptotales3 });
  const dpfpagos = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dpfpagos });
  const dptotales = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dptotales });
  const dpcierre3 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dpcierre3 });

  const [displayMode,] = React.useState<string | 'full' | 'compact'>('full');
  const [showInfo,] = React.useState<boolean>(true);
  const [showNavButtons,] = React.useState<boolean>(true);
  const [showPageSizeSelector,] = React.useState<boolean>(true);

  const resultadoCierreCajaRet = React.useCallback(async (dpCierreFacturasVar: Array<ComprobantesCuadresCajaListado> | []) => {

    const detalle = {
      ccdCodigo: 0,
      ccaCodigo: "",
      subValor: "",
      ccdValor: 0,
      subCodigo: 0,
    };

    let valor: number = 0;
    const dptotales3Local: Array<formasPagoCuadre> = [];
    if (dpCierreFacturasVar.length === 0) {
      for (const fpg of dpfpagos) {
        dptotales3Local.push({
          subCodigo: Number(fpg.codigo) ?? -1,
          subValor: String(fpg.descripcion) ?? "",
          ccdValor: valor,
        });
      }

      dispatch(updateDptotales3Only({
        dptotales3: dptotales3Local,
        key: tabId
      }));

    } else {

      let anuladas = 0;
      let i = 0;

      for (let j = 0; j <= dpfpagos.length - 1; j++) {
        valor = 0;
        for (i = 0; i < dpCierreFacturasVar.length; i++) {
          if (dpCierreFacturasVar[i].ventaFormaPagoDescripcion === dpfpagos[j].descripcion) {
            valor = valor + Number(Number(dpCierreFacturasVar[i].valor).toFixed(2));
          }
        }

        dptotales3Local.push({
          subCodigo: Number(dpfpagos[j].codigo),
          subValor: String(dpfpagos[j].descripcion),
          ccdValor: Number(Number(valor).toFixed(2)),
        });

      }

      const VEN_FINAL3 = dpCierreFacturasVar[dpCierreFacturasVar.length - 1].ventaCodigo;
      const VEN_INICIAL3 = dpCierreFacturasVar[0].ventaCodigo;

      dispatch(update3VenInicialFinal({
        rango: {
          inicial: VEN_INICIAL3,
          final: VEN_FINAL3
        },
        key: tabId
      }));

      dispatch(updateDptotales3Only({
        dptotales3: dptotales3Local,
        key: tabId
      }));

      let k = 0;

      for (k = 0; k < dpCierreFacturasVar.length; k++) {
        if (dpCierreFacturasVar[k].ventaEstado === "*") {
          anuladas += Number(dpCierreFacturasVar[k].ventaTotal);
        }
      }

      dispatch(updateAnuladasCierre3_tiOnly({
        anuladascierre3_ti: anuladas,
        key: tabId
      }));

      let total: number = 0;
      for (k = 0; k < dptotales3Local.length; k++) {
        total = total + dptotales3Local[k].ccdValor;
      }
      total = Number(Number(total).toFixed(2));
      dispatch(updateTotal3_tiOnly({
        total3_ti: total,
        key: tabId
      }));

      const dptotalesLocal: any = dptotales.slice() ?? [];
      if (dptotales3Local.length > 0) {
        for (i = 0; i < dpfpagos.length; i++) {
          let x: ccDetalle | null = null;
          try {
            x = dptotales[i];
          } catch (ex) {
            x = null;
          }
          if (x == null) {
            const addDetallTotales: ccDetalle = {
              ...detalle,
              ccdValor: Number((Number(dptotales3Local[i].ccdValor) * -1).toFixed(2)),
              subCodigo: dpfpagos[i].codigo,
              subValor: dpfpagos[i].descripcion,
            }
            dptotalesLocal.push({ ...addDetallTotales });
          } else {
            const y: number = Number(x?.ccdValor ?? 0) - Number(dptotales3Local[i]?.ccdValor ?? 0);

            dptotalesLocal[i] = {
              ...x, ccdValor: y ?? 0
            }
          }

        }

        dispatch(updateDptotalesOnly({
          dptotales: dptotalesLocal,
          key: tabId
        }));

      }
    }


  }, [dispatch, tabId, dpfpagos, dptotales]);

  const getCierreCajaRet = React.useCallback(async () => {
    let dpCierreRet = [];
    if (dpcierre3) {
      dpCierreRet = dpcierre3
    }
    await resultadoCierreCajaRet(dpCierreRet);
    dispatch(updateLoadDpCierre3Only({
      loaddpcierre3: false,
      key: tabId
    }));
  }, [
    dispatch, tabId, dpcierre3, resultadoCierreCajaRet
  ]);


  const onLoadClose = React.useCallback(async () => {
    statusLoaded(true)
  }, [statusLoaded]);

  React.useEffect(() => {
    if (generarCuadre === true) {
      onLoadClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generarCuadre])

  React.useEffect(() => {
    if (loaddpcierre3 === true) {
      getCierreCajaRet();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaddpcierre3])

  return (
    <>
      <CRow className={'m-2'}>
        <CCol lg="12" xl="12" sm="12">
          <RowContainer >
            <CustomCol xs='12' md='12'   >
              <DataGrid
                selection={{ mode: 'single' }}
                focusedRowEnabled={true}
                keyExpr='ventaCodigo'
                dataSource={dpcierre3}
                showBorders={true}
                width={utilidades.getGridFullWidth()}
                allowColumnResizing
                columnResizingMode='widget'
                height={heigth}
                ref={dataGrid}
              >
                <Export enabled={true} formats={exportFormats} />

                <Paging defaultPageSize={20} />
                <Pager
                  visible={dpcierre3.length > 0 ? true : false}
                  allowedPageSizes={getAllowedPageSizes(dpcierre3)}
                  displayMode={displayMode}
                  showPageSizeSelector={showPageSizeSelector}
                  showInfo={showInfo}
                  showNavigationButtons={showNavButtons}
                />
                <HeaderFilter visible={true} />
                <Column dataField='ventaTipoDocumentoDescripcion' dataType='string' caption='Comprobante' width='15%' allowEditing={false} allowSearch={false} allowFiltering />
                <Column dataField='ventaNumero' caption='Número' width='20%' allowEditing={false} />
                <Column dataField='clienteNombre' caption='Cliente' width='20%' allowEditing={false} />
                <Column dataField='ventaTotal' caption='Total' width='15%' allowEditing={false} />
                <Column dataField='valor' caption='Abonado' allowEditing={false} width={'15%'} />
                <Column dataField='saldo' caption='Saldo' width='15%' allowEditing={false} />
                <Column dataField='ventaFormaPagoDescripcion' caption='F. Pago' width='15%' allowEditing={false} />
                <Column dataField='ventaEstado' caption='E' width='20%' allowEditing={false} />
              </DataGrid>
            </CustomCol>
          </RowContainer>
        </CCol>
      </CRow>
      <CRow className="m-2 row">
        <CCol lg="12" md="12" sm="12" className="d-flex justify-content-end  align-items-center">
          <CCol lg="3" md="3" sm="12" className="d-flex justify-content-end  align-items-center">
            <TabFormasPago
              tabId={tabId}
              dptotales={dptotales3}
            />
          </CCol>
        </CCol>
      </CRow>
      <CRow className="m-2 row">
        <CCol lg="3" md="3" sm="12" className="d-flex justify-content-start  align-items-center">
          <ButtonsInfo />
        </CCol>
        <CCol lg="9" md="9" sm="12" className="d-flex justify-content-end  align-items-center">
          <CCol lg='3' md="3" xs="12" className='me-1'>
            <GlobalLabel htmlFor="anuladas" className="col-form-label-md">
              <strong> {"Anuladas"} </strong>
            </GlobalLabel>
            <CRow>
              <CCol md="12">
                <NumberBox
                  readOnly
                  width="100%"
                  value={anuladascierre3_ti}
                  placeholder=""
                  format="#,##0.00"
                />
              </CCol>
            </CRow>
          </CCol>
          <CCol lg='3' md="3" xs="12">
            <GlobalLabel htmlFor="total" className="col-form-label-md">
              <strong> {"Total"} </strong>
            </GlobalLabel>
            <CRow>
              <CCol md="12">
                <NumberBox
                  readOnly
                  width="100%"
                  value={total3_ti}
                  placeholder=""
                  format="#,##0.00"
                />
              </CCol>
            </CRow>
          </CCol>
        </CCol>
      </CRow>
    </>
  )
}
export default Retenciones;
