import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EdicionPayload } from '../../../../store/types'
import { TransferEditData } from '../types/types'
import { CustomDictionary, LoaderInfo } from '../../../../../../store/types'
import { Project } from '../../../../../shared/types/types'
import { LocalOption } from '../../../../../componentes/localesLookUp/store/type'

const initialState: CustomDictionary<TransferEditData> = {}

const datosEditSlice = createSlice({
  name: 'transferenciasDatosEdicion',
  initialState: initialState,
  reducers: {
    deleteEditData(state, action: PayloadAction<string>) {
      delete state[action.payload]
    },
    setDatosEdicion(
      state,
      action: PayloadAction<{
        key: string
        transferencia: TransferEditData
      }>,
    ) {
      const { key, transferencia } = action.payload
      state[key].codigo = transferencia.codigo
      state[key].numero = transferencia.numero
      state[key].fecha = transferencia.fecha
      state[key].descripcion = transferencia.descripcion
      state[key].responsable = transferencia.responsable
      state[key].item = transferencia.item
      state[key].cantidad = transferencia.cantidad
      state[key].compra = transferencia.compra
      state[key].movimiento = transferencia.movimiento
      state[key].existencia = transferencia.existencia
      state[key].cantidad = transferencia.cantidad
      state[key].unidad = transferencia.unidad
      state[key].referencia = transferencia.referencia
      state[key].ordenAsociada = transferencia.ordenAsociada
      state[key].detalles = transferencia.detalles
      state[key].guardado = transferencia.guardado
      state[key].loaded = transferencia.loaded
      state[key].horaFin = transferencia.horaFin
      state[key].horaInicio = transferencia.horaInicio
      state[key].loaded = transferencia.loaded
    },
    setOriginProject(
      state,
      action: PayloadAction<{
        key: string
        origin: Project
      }>,
    ) {
      state[action.payload.key].origenProyecto = action.payload.origin
    },
    setOriginStore(
      state,
      action: PayloadAction<{
        key: string
        store: LocalOption
      }>,
    ) {
      state[action.payload.key].origenLocal = action.payload.store
    },
    setDestintionProject(
      state,
      action: PayloadAction<{
        key: string
        destination: Project
      }>,
    ) {
      state[action.payload.key].destinoProyecto = action.payload.destination
    },
    setDestinationStore(
      state,
      action: PayloadAction<{
        key: string
        store: LocalOption
      }>,
    ) {
      state[action.payload.key].destinoLocal = action.payload.store
    },
    initDatosEdicion(
      state,
      action: PayloadAction<EdicionPayload<TransferEditData>>,
    ) {
      if (!state[action.payload.key])
        state[action.payload.key] = action.payload.data
    },
    initDataShipment(state, action: PayloadAction<EdicionPayload<any>>) {
      if (!state[action.payload.key])
        state[action.payload.key] = action.payload.data
    },
    setDataShipment(
      state,
      action: PayloadAction<{
        key: string
        transferencia: any
      }>,
    ) {
      const { key, transferencia } = action.payload
      state[key].detalles = transferencia.detalles
      state[key].series = transferencia.series
      state[key].loaded = transferencia.loaded
      state[key].selectedAll = transferencia.selectedAll
      state[key].fecha = transferencia.fecha
    },
    setTabLoader(
      state,
      action: PayloadAction<{
        key: string
        loader: LoaderInfo
      }>,
    ) {
      state[action.payload.key].loader = action.payload.loader
    },
    setDataToEdit(
      state,
      action: PayloadAction<EdicionPayload<TransferEditData>>,
    ) {
      state[action.payload.key] = action.payload.data
    },
    setBackupTransfer(
      state,
      action: PayloadAction<EdicionPayload<TransferEditData>>,
    ) {
      state[action.payload.key].backup = action.payload.data
    },
  },
})

export const {
  setDatosEdicion,
  initDatosEdicion,
  deleteEditData,
  setOriginProject,
  setOriginStore,
  setDestintionProject,
  setDestinationStore,
  initDataShipment,
  setDataShipment,
  setTabLoader,
  setDataToEdit,
  setBackupTransfer,
} = datosEditSlice.actions
export const dataEditReducer = datosEditSlice.reducer
