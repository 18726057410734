import { DateUtils, formatoFechasApi } from '../../../../helpers/dateUtils'
import { RequestHelper } from '../../../../helpers/requestHelper'
import { utilidades } from '../../../../helpers/utilidades'
import { SesionService } from '../../../../services/sesion.service'
import { CapitalizeMode } from '../../../../store/types'
import { OptionTiposImpuestos } from '../types/types'

export const tiposImpuestodServices = { getTipos }

async function getTipos(): Promise<any> {
  try {
    const sesion = SesionService.getCurrentSesion()
    const jquery: any = {
      paisCodigo: sesion?.empresa.codigoPais ?? 1 // 1 -  Ecuador => por defecto
    }
    jquery.fecha = DateUtils.getCurrentDateAsString(formatoFechasApi)
    const cacheKey = 'impuestTypeOptionsAll' + JSON.stringify(jquery)
    const cached = sessionStorage.getItem(cacheKey)
    if (cached) {
      return { error: false, auto: JSON.parse(cached) }
    } else {

      const data = await RequestHelper.getAll<any>('iva/listar', '', '', jquery)

      const opt: Array<OptionTiposImpuestos> = []
      if (data?.auto && data?.auto.length > 0) {
        for (const x of data.auto) {
          opt.push({
            codigo: Number(x?.ivaTipo) ?? 0,
            descripcion: utilidades.capText(x?.ivaDescripcion, CapitalizeMode.upper) ?? '',
            valor: Number(x?.ivaValor) ?? 0,
          })
        }
      }
      data.auto = opt ?? []
      sessionStorage.setItem(cacheKey, JSON.stringify(opt))
      return data
    }
  } catch (error) {
    return error
  }
}
