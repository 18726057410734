import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ConfigIngEgr } from '../types/types'

const initialState: ConfigIngEgr = {
  aplicacion: 0,
}

const configuracionIngEgr = createSlice({
  name: 'configIngEgr',
  initialState: initialState,
  reducers: {
    setAplicacionModulo(state, acion: PayloadAction<number>) {
      state.aplicacion = acion.payload
    },
  },
})

export const configuracionesIngEgr = configuracionIngEgr.reducer
export const { setAplicacionModulo } = configuracionIngEgr.actions
