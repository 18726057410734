import { DateUtils, formatoFechasApi } from '../../../../helpers/dateUtils'
import { RequestHelper } from '../../../../helpers/requestHelper'
import { SesionService } from '../../../../services/sesion.service';
import { OrganicoRol } from '../types/types'

export const orgrollServices = {
  getorgrollsList,
}

async function getorgrollsList(codigo: number): Promise<any> {
  const sesion = SesionService.getCurrentSesion();
  try {
    const data: any = { codigo: codigo }
    const keysToCache = {
      ...data,
      dateKey: DateUtils.getCurrentDateAsString(formatoFechasApi),
      empresa: sesion?.empresa?.codigo
    }
    const cacheKey = 'positionOragnicLookup' + JSON.stringify(keysToCache)
    const cachedData = sessionStorage.getItem(cacheKey)
    if (cachedData) return { error: false, auto: JSON.parse(cachedData) }
    else {
      const positionsList = await RequestHelper.getAll<any>(
        'admin/roles',
        'organico/cboxLoad',
        '',
        data
      )
      if (!positionsList.error && positionsList.auto) {
        const positionsListData: OrganicoRol[] = positionsList.auto
        sessionStorage.setItem(cacheKey, JSON.stringify(positionsListData))
        return {
          error: false,
          auto: positionsListData,
          message: positionsList.message,
        }
      } else
        return {
          error: true,
          auto: positionsList.auto,
          message: positionsList.message,
        }
    }
  } catch (error) {
    return { error: true, auto: [], message: error }
  }
}
