import React, { useState, useEffect } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow, CTooltip
} from '@coreui/react-pro'
import Modalform from "../../../../../views/componentes/modalform"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cuentasService } from '../services/cuentas.service';
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../store/store'
import { changeLoader } from '../../../../../store/reducers';
import { addToast } from '../../../../../store/toasterReducer';
import { ToastTypes } from '../../../../../store/types';
import { BotonMenu, TIPOS_BOTONES } from './../store/types';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import { menuService } from '../../../../../services/menu.service';
import config from "./../../../../../config/config"
import { setDataBack, setSeleccionarDato, setCurrentFunction, setCurrentAccion, setResetSeleccion, setData } from "./store/modalCuentasReducer"
import Buscar from "./components/buscar";
import Nuevo from "./components/nuevo";
import { lh, MessagesKeys } from '../../../../../helpers/localizationHelper';

type ModalCuentasProps = {
  show: boolean,
  onClose: () => void,
  cargarCuentas: (evt) => void
}

const ModalCuentas = (props: ModalCuentasProps) => {

  const refBuscar = React.useRef();

  const dispatch = useDispatch()

  const seleccionado = useSelector((state: RootState) => state.contabilidad.modalCuentas.seleccionado);
  const modal = useSelector((state: RootState) => state.contabilidad.planCuentas.modal);
  const currentAction = useSelector((state: RootState) => state.contabilidad.modalCuentas.currentAction);
  const registros = useSelector((state: RootState) => state.contabilidad.modalCuentas.registros);

  const [acciones, setAcciones] = useState<any>([]);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const [confirmUpdate, setConfirmUpdate] = React.useState(false);
  const [messageConfirm, setMessageConfirm] = React.useState("Está seguro que desea realizar estos cambios?");
  const [displayDestino, setDisplayDestino] = React.useState<string>("");
  const [confirmChange, setConfirmChange] = React.useState<boolean>(false);
  const [codModulo, setCodModulo] = React.useState<number>(0);

  const botones = config['menuDefecto'].map(acc => {
    const boton: BotonMenu = {
      id: acc.text as any,
      icon: menuService.getIcon(acc.icon),
      text: acc.text
    }
    return boton;
  });


  const getButtonDisabled = React.useCallback((button: BotonMenu) => {
    switch (button.id) {
      case TIPOS_BOTONES.BOTON_BUSCAR:
        return false;
      case TIPOS_BOTONES.BOTON_IMPORTAR:
        return currentAction === "Importar";
      case TIPOS_BOTONES.BOTON_EXPORTAR:
      case TIPOS_BOTONES.BOTON_NUEVO: {
        // return currentAction !== "Buscar" || seleccionado.index !== null;
        return false;
      }
      case TIPOS_BOTONES.BOTON_ELIMINAR: {
        return (currentAction !== "Buscar" || seleccionado.index === null);
      }
      case TIPOS_BOTONES.BOTON_EDITAR: {
        //return (currentAction !== "Buscar" || seleccionado.index === null);
        return false;
      }
      case TIPOS_BOTONES.BOTON_DESHACER:
      case TIPOS_BOTONES.BOTON_GUARDAR: {
        return currentAction === "Buscar";
      }
      default:
        return true;
    }
  }, [currentAction, seleccionado]);

  const playLoader = React.useCallback(() => {
    dispatch(changeLoader({ show: true, mensaje: 'Cargando...' }))
  }, [dispatch]);

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoader({ show: false, mensaje: '' }))
  }, [dispatch]);

  const setToast = React.useCallback((texto, type) => {
    let tipo: ToastTypes = ToastTypes.Info;
    if (type === 'warning') {
      tipo = ToastTypes.Warning;
    } else if (type === 'success') {
      tipo = ToastTypes.Success;
    } if (type === 'info') {
      tipo = ToastTypes.Info;
    } if (type === 'danger') {
      tipo = ToastTypes.Danger;
    }
    dispatch(addToast({
      id: '',
      autoHide: 5000,
      title: 'Acatha',
      content: texto,
      fade: true,
      type: tipo
    }));
  }, [dispatch]);

  const renderBodyConfirm = () => {
    return (<> {messageConfirm} </>)
  }

  const clickDelete = React.useCallback(() => {
    setConfirmDelete(true);
    setShowConfirm(true)
    setMessageConfirm("Está seguro que desea eliminar este item?");
  }, []);

  const returnAction = React.useCallback((metodo) => {

    switch (metodo) {
      case TIPOS_BOTONES.BOTON_NUEVO:
        setDisplayDestino(TIPOS_BOTONES.BOTON_NUEVO)
        if (currentAction === TIPOS_BOTONES.BOTON_EDITAR) {
          setConfirmChange(true)
          setConfirmUpdate(true)
          setMessageConfirm(lh.getMessage(MessagesKeys.FormConfirmExitForm));
          setShowConfirm(true)
          return false;
        } else {
          dispatch(setCurrentAccion("Nuevo"))
        }
        break;
      case TIPOS_BOTONES.BOTON_BUSCAR:

        setDisplayDestino(TIPOS_BOTONES.BOTON_BUSCAR)
        if (currentAction === "Buscar") {
          dispatch(setCurrentFunction(TIPOS_BOTONES.BOTON_BUSCAR))
          return false;
        } else {

          if (currentAction === "Nuevo") {
            setConfirmChange(true)
            setConfirmUpdate(true)
            setMessageConfirm(lh.getMessage(MessagesKeys.FormConfirmExitForm));
            setShowConfirm(true)
            return false;
          } if (currentAction === TIPOS_BOTONES.BOTON_EDITAR) {
            setConfirmChange(true)
            setConfirmUpdate(true)
            const sms = lh.getMessage(MessagesKeys.FormConfirmExitForm);
            const sms_replace = sms.replace("@dinamic_var@", seleccionado['row']['descripcion'] ?? 's/n');
            setMessageConfirm(sms_replace);
            setShowConfirm(true)
            return false;
          } else {
            dispatch(setCurrentAccion(TIPOS_BOTONES.BOTON_BUSCAR))
          }

        }
        break;
      case TIPOS_BOTONES.BOTON_GUARDAR:
        if (currentAction === "Nuevo") {
          dispatch(setCurrentFunction("Guardar"))
          return false;
        } else if (currentAction === TIPOS_BOTONES.BOTON_EDITAR) {
          dispatch(setCurrentFunction("Guardar"))
          return false;
        } else if (currentAction === "Importar") {
          document?.getElementById('btnSubmitModalPlan')?.click()
          return false;
        } else {
          setToast("No se encuentra cambios que realizar.", "info")
        }
        break;
      case TIPOS_BOTONES.BOTON_EDITAR:
        setDisplayDestino(TIPOS_BOTONES.BOTON_EDITAR)

        if (currentAction === TIPOS_BOTONES.BOTON_EDITAR) {
          setConfirmChange(true)
          setConfirmUpdate(true)

          const sms = lh.getMessage(MessagesKeys.FormConfirmChangeForm);
          const sms_replace = sms.replace("@dinamic_var@", seleccionado['row']['descripcion'] ?? 's/n');
          setMessageConfirm(sms_replace)

          setShowConfirm(true)
          return false;
        } else {
          if (seleccionado['index'] === null) {
            setToast("No se encuentra un registro seleccionado, Por favor seleccione un registro para editarlo.", "info")
            return false;
          } else {
            setConfirmUpdate(true)
            setMessageConfirm("Está seguro que desea realizar estos cambios?");
            setShowConfirm(true)
          }
        }
        break;
      case TIPOS_BOTONES.BOTON_ELIMINAR:
        if (currentAction === "Buscar") {
          if (seleccionado['index'] === null) {
            setToast("No se encuentra un registro seleccionado, Por favor seleccione un registro para eliminarlo.", "info")
            return false;
          } else {
            clickDelete();
          }
        } else {
          setToast("No se puede eliminiar una cuenta desde esta pantalla.", "info")
        }
        break;
      case TIPOS_BOTONES.BOTON_DESHACER:
        if (currentAction === "Nuevo") {
          dispatch(setCurrentFunction(TIPOS_BOTONES.BOTON_DESHACER))
          return false;
        } else if (currentAction === TIPOS_BOTONES.BOTON_EDITAR) {
          dispatch(setCurrentFunction(TIPOS_BOTONES.BOTON_DESHACER))
          return false;
        }
        else if (currentAction === "Importar") {
          dispatch(setCurrentFunction(TIPOS_BOTONES.BOTON_DESHACER))
          return false;
        }
        else {
          dispatch(setResetSeleccion())
          dispatch(setCurrentAccion("Buscar"))
          //dispatch(setTipoCuentas([]))
          document?.getElementById("btnReset")?.click();
        }
        break;
      default:
        break;
    }
  }, [currentAction, dispatch, seleccionado, setToast, clickDelete]);

  const quitarRegistroTabla = React.useCallback((idCuenta) => {
    const filtrado = registros.findIndex((item: any) => {
      return Number(item.codigo) === Number(idCuenta['codigo']);
    });
    if (filtrado > -1) {
      const rowsBefore = registros.slice();
      rowsBefore.splice(filtrado, 1);
      dispatch(setData(rowsBefore));
    }
  }, [dispatch, registros]);

  const handleDelete = React.useCallback(async () => {
    playLoader()
    try {
      const data = await cuentasService.deleteRegistro(seleccionado['row']['codigo']);
      if (data['error'] === false) {
        stopLoader()
        setToast(data['message'], "success");
        quitarRegistroTabla(seleccionado['row'])
        dispatch(setResetSeleccion())
        dispatch(setCurrentAccion("Buscar"))
      } else {
        setToast(data['message'], "warning")
      }
      return await data;
    } catch (error) {
      console.error(error);
      stopLoader()
      setToast(JSON.stringify(error), "danger")
    }
  }, [dispatch, playLoader, quitarRegistroTabla, seleccionado, setToast, stopLoader]);

  const bodyModalForm = () => {
    if (currentAction === "Buscar") {
      return (
        <>
          <Buscar
            referencia={refBuscar}
            actionDelete={() => clickDelete()}
            cargarCuentas={(evt) => cargarCuentas(evt)}
            codModulo={codModulo}
            setToast={setToast}
            playLoader={playLoader}
            stopLoader={stopLoader}
          />
        </>
      )
    } else if (currentAction === "Nuevo") {
      return (
        <>
          <Nuevo />
        </>
      )
    } else if (currentAction === TIPOS_BOTONES.BOTON_EDITAR) {
      return (
        <>
          <Nuevo />
        </>
      )
    } else if (currentAction === "Importar") {
      return (
        <>
          {/*<Importar />*/}
        </>
      )
    } else {
      return (
        <>
        </>
      )
    }
  }

  const confirmAction = () => {
    return (
      <Modalform
        name='confirmarCambios'
        headerTitle={'Confirmar'}
        childrenBody={renderBodyConfirm()}
        childrenFooter={renderFooter()}
        closeOnBackdrop={false}
        show={showConfirm}
        onClose={() => setShowConfirm(!showConfirm)}
        centered={true}
        size="sm"
        key={'confirmarCambios'}
      />
    );
  };

  const renderBody = () => {

    return (
      <>

        {showConfirm === true && confirmAction()}

        <CCard key="cardCuentas" >
          <CCardHeader >
            <CRow>
              <CCol className="ms-0 d-flex align-items-start justify-content-start" >
                <div key='menuItems' className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups" style={{ marginBottom: '5px', paddingTop: '-2rem' }}>
                  <div key='menuItemsBotones' className="btn-group-lg btn-group btn-sm me-2" role="group" aria-label="First group" >
                    {acciones.map(function (x, i) {
                      const prefixButton = "buttonsCuentas_";
                      const nameButon = x.text;
                      const disabled = getButtonDisabled(x);
                      return (
                        <CTooltip
                          key={"tool-" + prefixButton + nameButon}
                          placement="top"
                          content={nameButon}
                        >
                          <CButton
                            key={prefixButton + nameButon}
                            name={prefixButton + nameButon}
                            title={x.text}
                            id={prefixButton + nameButon}
                            value={x.text}
                            className="btn btn-secondary sm"
                            variant="ghost"
                            onClick={(ev) => returnAction(ev = x.text)}
                            size='sm'
                            disabled={disabled}
                          >
                            <FontAwesomeIcon size='sm' icon={x.icon ?? faQuestionCircle} />
                          </CButton>
                        </CTooltip>
                      );
                    })}
                  </div>
                </div>
              </CCol>
            </CRow>
          </CCardHeader >

          <CCardBody >
            {bodyModalForm()}
          </CCardBody >

        </CCard>
      </>
    )
  }

  const renderFooter = () => {
    if (currentAction === "Buscar") {
      return (
        <>
          {confirmDelete === true && (
            <CButton color="primary" onClick={() => {
              setShowConfirm(false)
              setConfirmDelete(false)
              handleDelete()
            }}>
              {"Aceptar"}
            </CButton>
          )}
          {confirmUpdate === true && (
            <CButton color="primary" onClick={() => {
              setShowConfirm(false)
              setConfirmUpdate(false);
              dispatch(setCurrentAccion(TIPOS_BOTONES.BOTON_EDITAR))
            }}>
              {"Aceptar"}
            </CButton>
          )}
          <CButton color="secondary" onClick={() => {
            setShowConfirm(!showConfirm)
            setConfirmUpdate(false);
            setConfirmDelete(false)
          }} >{"Cancelar"}</CButton>
        </>
      )
    } else if (currentAction === "Nuevo" || currentAction === TIPOS_BOTONES.BOTON_EDITAR) {
      return (
        <>
          {confirmChange === true && (
            <CButton color="primary" onClick={() => {
              setShowConfirm(false)
              setConfirmUpdate(false);
              setConfirmChange(false)
              setMessageConfirm("")
              dispatch(setCurrentAccion(displayDestino))
            }}>
              {"Aceptar"}
            </CButton>
          )}
          <CButton color="secondary" onClick={() => {
            setShowConfirm(!showConfirm)
            setConfirmUpdate(false);
            setConfirmDelete(false)
          }} >{"Cancelar"}</CButton>
        </>
      )
    }
  }

  const closeModal = React.useCallback(() => {
    props.onClose()
  }, [props]);

  const cargarCuentas = React.useCallback((evt) => {
    props.cargarCuentas(evt)
  }, [props]);

  const initModal = React.useCallback(() => {
    setAcciones(botones)
    dispatch(setData([]));
    dispatch(setDataBack([]));
    dispatch(setCurrentAccion("Buscar"));

    if ((modal?.open === true) && (modal?.modalName === "modalPlan")) {
      dispatch(setSeleccionarDato(
        {
          index: null,
          row: null,
          selectedRow: null
        }
      ))
      setCodModulo(modal.codModulo);
    }
  }, [dispatch, botones, modal]);

  useEffect(() => {
    initModal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CRow key="rowMain">
      <CCol key="colMain" >
        <Modalform
          name='apis'
          key='modalPlan'
          headerTitle={"Plan de Cuentas"}
          childrenBody={renderBody()}
          childrenFooter={<></>}
          closeOnBackdrop={false}
          show={props.show}
          onClose={closeModal}
          size="lg"
        />
      </CCol>
    </CRow>
  )
}

export default React.memo(ModalCuentas);
