/* eslint-disable no-unused-vars */
export enum KeyItemSales {
  cod = 'codigo',
  codInt = 'codigoint',
  barrraCod = 'barras',
  descripcion = 'descripcion',
  existencia = 'existencia',
  pvdiva = 'pvdiva',
  pvpiva = 'pvpiva',
  pvd = 'pvd',
  pvp = 'pvp',
  codigoImpuesto = 'impuesto',
  tieneIce = 'ice',
  porcentajeIva = 'porcentajeIva',
  costo = 'costo',
  tipoCodigo = 'tipo',
  numeroSerie = 'numeroSerie',
  plantilla = 'plantilla',
  freeitem = 'freeitem',
  gradoAcoholico = 'gradoAlcoholico',
  capacidad = 'capacidadCc',
  factor = 'factor',
  jerarquia = 'jerarquia',
  descuentos = 'descuentos',
  titadicional = 'titadicional',
  valadicional = 'valadicional',
  codigoUnidadVenta = 'unidadv',
  tipoDescripcion = 'tipoDescripcion',
  codigoGrupo = 'codGrupo',
  reservas = 'reserva',
  stockMin = 'stockMin',
  stockMax = 'stockMax',
}

export enum KeyItemLocalSimple {
  cod = 'codigo',
  codInt = 'codigoint',
  barrraCod = 'barras',
  descripcion = 'descripcion',
  generico = 'generico',
  existencia = 'existencia',
  pvdiva = 'pvdiva',
  pvpiva = 'pvpiva',
  pvd = 'pvd',
  pvp = 'pvp',
  codigoImpuesto = 'impuesto',
  tieneIce = 'ice',
  porcentajeIva = 'porcentajeIva',
  costo = 'costo',
  tipoCodigo = 'tipo',
  numeroSerie = 'numserie',
  plantilla = 'plantilla',
  freeitem = 'freeitem',
  gradoAcoholico = 'galcoholico',
  capacidad = 'capacidad',
  factor = 'factor',
  jerarquia = 'jerarquia',
  descuentos = 'descuentos',
  titadicional = 'titadicional',
  valadicional = 'valadicional',
  codigoUnidadIce = 'codigoUnidadIce',
  lineaDescripcion = 'lineaDescripcion',
  marcaDescripcion = 'marcaDescripcion',
  grupoCodigo = 'grupoCodigo',
  grupoDescripcion = 'grupoDescripcion',
  unidadDescripcion = 'unidadDescripcion',
  imagen = 'imagen',
  lotes = 'imagen',
  procedenciaDescripcion = 'procedenciaDescripcion',
  impuesto = 'impuesto',
  impuestoValor = 'impuestoValor',
  pvo = 'pvo',
  costeo = 'costeo',
  costoIVA = 'costoIVA',
  aplicaciones = 'aplicaciones',
  peso = 'peso',
  largo = 'largo',
  ancho = 'ancho',
  alto = 'alto',
  nota = 'nota',
  tags = 'tags',
  gananciaPVD = 'gananciaPVD',
  gananciaPVP = 'gananciaPVP',
  localCodigo = 'localCodigo',
  loteNumero = 'loteNumero',
  loteCaducidad = 'loteCaducidad',
  localizacion = 'localizacion',
  proveedores = 'proveedores',
  padre = 'padre',
  controlador = 'controlador',
  tallaInicial = 'tallaInicial',
  tallaFinal = 'tallaFinal',
  valores = 'valores',
  propiedades = 'propiedades',
  desperdicio = 'desperdicio',
  subsidio = 'subsidio',
  saldoFecha = 'saldoFecha',
  unidadVenCod = 'unidadVenCod',
  unidadVenDes = 'unidadVenDes',
  tipoDescripcion = 'tipoDescripcion',
  saldoReservas = 'saldoReservas',
  existenciaMin = 'existenciaMin',
  existenciaMax = 'existenciaMax',
}
export enum KeyItemLocalSimpleVenta {
  cod = 'codigo',
  codInt = 'codigoint',
  barrraCod = 'barras',
  descripcion = 'descripcion',
  generico = 'generico',
  existencia = 'existencia',
  pvdiva = 'pvdiva',
  pvpiva = 'pvpiva',
  pvd = 'pvd',
  pvp = 'pvp',
  codigoImpuesto = 'impuesto',
  tieneIce = 'ice',
  porcentajeIva = 'porcentajeIva',
  costo = 'costo',
  tipoCodigo = 'tipo',
  numeroSerie = 'numserie',
  plantilla = 'plantilla',
  freeitem = 'freeitem',
  gradoAcoholico = 'galcoholico',
  capacidad = 'capacidad',
  factor = 'factor',
  jerarquia = 'jerarquia',
  descuentos = 'descuentos',
  titadicional = 'titadicional',
  valadicional = 'valadicional',
  itemGrupo = 'codgrupo',
  grupo = 'grupo',
  itemUnidadV = 'unidadv',
  subs = 'subsidio',
  tipoDescripcion = 'tipo_descripcion',
  grupoDescripcion = 'grupoDescripcion',
  unidadDescripcion = 'unidadDescripcion',
  imagenes = 'imagenes',
  stockMin = 'stockMin',
  stockMax = 'stockMax',
  reservas = 'reservas',
  saldo = 'saldoFecha',
}

export enum KeyItemLocal {
  cod = 'codigo',
  codInt = 'codigoint',
  barrraCod = 'barras',
  descripcion = 'descripcion',
  generico = 'generico',
  existencia = 'existencia',
  pvdiva = 'pvdiva',
  pvpiva = 'pvpiva',
  pvd = 'pvd',
  pvp = 'pvp',
  codigoImpuesto = 'impuesto',
  tieneIce = 'ice',
  porcentajeIva = 'porcentajeIva',
  costo = 'costo',
  tipoCodigo = 'tipo',
  numeroSerie = 'numserie',
  plantilla = 'plantilla',
  freeitem = 'freeitem',
  gradoAcoholico = 'galcoholico',
  capacidad = 'capacidad',
  factor = 'factor',
  jerarquia = 'jerarquia',
  descuentos = 'descuentos',
  titadicional = 'titadicional',
  valadicional = 'valadicional',
  codigoUnidadIce = 'codigoUnidadIce',
  lineaDescripcion = 'lineaDescripcion',
  marcaDescripcion = 'marcaDescripcion',
  grupoCodigo = 'codgrupo',
  grupo = 'grupo',
  grupoDescripcion = 'grupoDescripcion',
  unidadDescripcion = 'unidadDescripcion',
  imagen = 'imagen',
  lotes = 'imagen',
  procedenciaDescripcion = 'procedenciaDescripcion',
  impuesto = 'impuesto',
  impuestoValor = 'impuestoValor',
  pvo = 'pvo',
  costeo = 'costeo',
  costoIVA = 'costoIVA',
  aplicaciones = 'aplicaciones',
  peso = 'peso',
  largo = 'largo',
  ancho = 'ancho',
  alto = 'alto',
  nota = 'nota',
  tags = 'tags',
  gananciaPVD = 'gananciaPVD',
  gananciaPVP = 'gananciaPVP',
  localCodigo = 'localCodigo',
  loteNumero = 'loteNumero',
  loteCaducidad = 'loteCaducidad',
  localizacion = 'localizacion',
  proveedores = 'proveedores',
  padre = 'padre',
  controlador = 'controlador',
  tallaInicial = 'tallaInicial',
  tallaFinal = 'tallaFinal',
  valores = 'valores',
  propiedades = 'propiedades',
  desperdicio = 'desperdicio',
  subsidio = 'subsidio',
  saldoFecha = 'saldoFecha',
  unidadVenCod = 'unidadVenCod',
  unidadVenDes = 'unidadVenDes',
  tipoDescripcion = 'tipoDescripcion',
  saldoReservas = 'saldoReservas',
  existenciaMin = 'existenciaMin',
  existenciaMax = 'existenciaMax',
}

export enum KeyItemLocalSimpleCompra {
  cod = 'codigo',
  codInt = 'codigoint',
  barrraCod = 'barras',
  descripcion = 'descripcion',
  generico = 'generico',
  existencia = 'existencia',
  pvdiva = 'pvdiva',
  pvpiva = 'pvpiva',
  pvd = 'pvd',
  pvp = 'pvp',
  codigoImpuesto = 'impuesto',
  impuestoValor = 'impuestoValor',
  tieneIce = 'ice',
  porcentajeIva = 'porcentajeIva',
  costo = 'costo',
  tipoCodigo = 'tipo',
  numeroSerie = 'numserie',
  plantilla = 'plantilla',
  freeitem = 'freeitem',
  gradoAcoholico = 'galcoholico',
  capacidad = 'capacidad',
  factor = 'factor',
  jerarquia = 'jerarquia',
  descuentos = 'descuentos',
  titadicional = 'titadicional',
  valadicional = 'valadicional',
  itemGrupo = 'codgrupo',
  lotes = 'lotes',
  grupo = 'grupo',
  itemUnidadV = 'unidadv',
  subs = 'subsidio',
  fraccion = 'fraccion',
  tipoDescripcion = 'tipo_descripcion',
  grupoDescripcion = 'grupoDescripcion',
  unidadDescripcion = 'unidadDescripcion',
  imagenes = 'imagenes',
  stockMin = 'stockMin',
  stockMax = 'stockMax',
  reservas = 'reservas',
  saldo = 'saldoFecha',
}
