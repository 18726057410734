import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataGrid, { Column, Paging, Pager, ColumnChooser, MasterDetail, Button as DatagridButton, HeaderFilter, TotalItem, Summary } from 'devextreme-react/data-grid';
import { DatosAnuladas } from '../../../../types/types';

interface ITablaAnuladosProps extends React.PropsWithChildren {
  data: Array<DatosAnuladas> | []
}
const pageSizes = [10, 25, 50, 100, 200];

const TablaAnulados: React.FunctionComponent<ITablaAnuladosProps> = (props) => {
  const { data } = props;
  const dispatch = useDispatch();

  const dialogRef = React.useRef<any>(null);
  const dataGrid = React.useRef<any>();


  const clearFilter = React.useCallback(() => {
    dataGrid.current.instance.clearSorting();
    dataGrid.current.instance.clearFilter();
  }, []);

  return (
    <DataGrid
      ref={dataGrid}
      selection={{ mode: 'single' }}
      allowColumnResizing={true}
      columnResizingMode={'nextColumn'}
      focusedRowEnabled={true}
      keyExpr='nro'
      focusedRowKey='nro'
      dataSource={data}
      showBorders={true}

    >
      <ColumnChooser enabled={true} mode="select" />
      <HeaderFilter visible={true} />

      <Column dataField='nro' caption='Nro' width='80px' allowEditing={false} visible={true} allowSearch={false} allowFiltering={false} />
      <Column dataField='tipoComprobanteDescripcion' type={'string'} width='170px' caption='Tipo Comprobante' alignment='right' allowFiltering={false} />
      <Column dataField='serie' caption='Serie Comp' minWidth='110px' width='auto' />
      <Column dataField='fechaDesde' caption='Secuencial - desde' minWidth='110px' width='auto' />
      <Column dataField='fechaHasta' caption='Secuencial - hasta' minWidth='110px' width='auto' />
      <Column dataField='autorizacion' caption='Autorización' minWidth='220px' width='auto' />

      <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} showInfo />
      <Paging defaultPageSize={10} />


    </DataGrid>
  );
}
export default TablaAnulados;
