import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoaderInfo, OpenModal } from "../../../../../store/types";
import { PageState, BotonMenu, Seleccionado, Registros, OptionGrupos, formularioBusqueda } from "./types";

const initialState: PageState = {
  modulo: 'contabilidad',
  currentAction: "Buscar",
  currentFunction: "",
  currentExecute: null,
  aplicacion: 'planCuentas',
  acciones: [],
  registros: [],
  registrosBack: [],
  refrescaTabla: false,
  seleccionado: {
    index: null,
    row: null,
    selectedRow: null,
  },
  tipos: [],
  categorias: [],
  cuentasPertenecen: [],
  cuentasPertenecenBackup: [],
  cuentasFlujoOptions: [],
  modal: {
    modalName: "",
    open: false,
    codModulo: null
  },
  loader: {
    show: false,
    mensaje: ''
  },
  formularioBusqueda: {
    numero: null,
    descripcion: null,
    estado: 1,
  },
}

const cuentasSlice = createSlice({
  name: "planCuentas",
  initialState: initialState,
  reducers: {
    setFormularioBusqueda(state, action: PayloadAction<formularioBusqueda>) {
      const toAdd = {
        numero: action.payload.numero,
        descripcion: action.payload.descripcion,
        estado: action.payload.estado,
      }
      state.formularioBusqueda = toAdd
    },
    obtenerAcciones(state, action: PayloadAction<Array<BotonMenu>>) {
      state.acciones = action.payload
    },
    setData(state, action: PayloadAction<Array<Registros>>) {
      state.registros = action.payload
    },
    setDataBack(state, action: PayloadAction<Array<Registros>>) {
      state.registrosBack = action.payload
    },
    setCurrentAccion(state, action: PayloadAction<string>) {
      state.currentAction = action.payload
    },
    setCurrentFunction(state, action: PayloadAction<string>) {
      state.currentFunction = action.payload
    },
    setSeleccionarDato(state, action: PayloadAction<Seleccionado>) {
      const toAdd = {
        index: action.payload.index,
        row: action.payload.row,
        selectedRow: action.payload.selectedRow,
      }
      state.seleccionado = toAdd
    },
    setResetSeleccion(state) {
      const toAdd = {
        index: null,
        row: null,
        selectedRow: null,
      }
      state.seleccionado = toAdd
    },
    setTiposOptions(state, action: PayloadAction<Array<OptionGrupos>>) {
      state.tipos = action.payload
    },
    setCategoriasOptions(state, action: PayloadAction<Array<OptionGrupos>>) {
      state.categorias = action.payload
    },
    setCuentasOptions(state, action: PayloadAction<Array<OptionGrupos>>) {
      state.cuentasPertenecen = action.payload
    },
    setCuentasOptionsBackup(state, action: PayloadAction<Array<OptionGrupos>>) {
      state.cuentasPertenecenBackup = action.payload
    },
    setCuentasFlujoOptions(state, action: PayloadAction<Array<OptionGrupos>>) {
      state.cuentasFlujoOptions = action.payload
    },
    setModal(state, action: PayloadAction<OpenModal>) {
      const toAdd = {
        modalName: action.payload.modalName,
        open: action.payload.open,
        codModulo: action.payload.codModulo
      }
      state.modal = toAdd
    },
    changeLoaderPlanCuentas(state, action: PayloadAction<LoaderInfo>) {
      state.loader = action.payload
    },
    setCurrentExecute(state, action: PayloadAction<any>) {
      state.currentExecute = action.payload
    },
  }
})

export const { setCurrentExecute, setFormularioBusqueda, changeLoaderPlanCuentas, setModal, obtenerAcciones, setData, setCurrentAccion, setSeleccionarDato, setResetSeleccion, setTiposOptions, setCategoriasOptions, setCuentasOptions, setCuentasFlujoOptions, setCuentasOptionsBackup, setDataBack, setCurrentFunction } = cuentasSlice.actions;
export const planCuentasReducer = cuentasSlice.reducer;