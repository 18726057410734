import React from 'react';
import SelectBox from 'devextreme-react/select-box';
import { projetsService } from '../projets.services';
import { Local, Project } from '../../../../../modulos/shared/types/types';
import { KeyLoc, KeyPro } from '../enumKeys';
import { SesionService } from '../../../../../services/sesion.service';

interface TLocsLookUpProps extends React.PropsWithChildren {
  selected: Local | null,
  // eslint-disable-next-line no-unused-vars
  onChanged: (newValue: Local | null) => void,
  // eslint-disable-next-line no-unused-vars
  onChangedOptions: (newValue: Array<Local> | []) => void,
  disabled?: boolean,
  provider?: Array<Local> | []
}

const LocsLookUp: React.FC<TLocsLookUpProps> = (props) => {
  const { provider, selected, onChanged, disabled } = props;
  const sesion = SesionService.getCurrentSesion();
  const selectRef = React.useRef<any>();
  const [options, setOptions] = React.useState<Array<Local> | []>([]);

  const loadDatos = React.useCallback(async () => {
    if (provider !== undefined && provider.length > 0) {
      setOptions(provider)
      let marcarOption: Local = provider[0]
      if (selected !== null) {
        const selectedIndex = provider.findIndex((select: Local) => {
          return select.codigo === selected?.codigo
        })
        if (selectedIndex > -1) {
          marcarOption = provider[selectedIndex] ?? provider[0];
        }
      }
      onChanged(marcarOption)
    } else {
      const locs = await projetsService.getLocs(sesion.empresa.codigo, '');
      if (locs.auto && locs.auto.length > 0) {
        // const opt = ProjectsHelper.onParseLocs(locs.auto, KeyLoc);
        const opt = locs.auto;
        setOptions(opt ?? []);

        const selectedIndex = opt.findIndex((option) => {
          return option.codigo === selected?.codigo
        })
        if (selectedIndex > -1) {
          onChanged(opt[selectedIndex] ?? opt[0])
        } else {
          onChanged(opt[0])
        }
      }
    }

  }, [
    onChanged, selected, sesion, provider
  ]);

  const getItemDisplayExpr = React.useCallback((item: Project) => {
    return item ? `${item.nombre}` : '';
  }, []);

  const onLookUpValueChanged = React.useCallback((evt: any) => {
    onChanged(evt);
  }, [onChanged]);

  const onSelectBoxFocusIn = React.useCallback((e) => {
    e.element.querySelector("input.dx-texteditor-input").select();
  }, []);

  const refreshSelected = React.useCallback(async () => {
    if (options && options.length > 0) {
      const selectedIndex = await options.findIndex((option: Local) => {
        return Number(option.codigo) === Number(selected.codigo)
      })
      let marcarOption: Local = options[0]
      if (selectedIndex > -1) {
        marcarOption = options[selectedIndex] ?? options[0];
      }
      onChanged(marcarOption)
    }
    selectRef.current.instance.repaint()
  }, [onChanged, options, selected])

  React.useEffect(() => {
    loadDatos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (provider.length > 0) {
      loadDatos()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider])

  React.useEffect(() => {
    if (selected !== null) {
      refreshSelected()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  return (
    <SelectBox
      id='lcoalSelect'
      ref={selectRef}
      items={options ?? []}
      searchEnabled
      displayExpr={getItemDisplayExpr}
      searchExpr={['nombre']}
      searchTimeout={500}
      value={selected}
      onValueChange={onLookUpValueChanged}
      disabled={disabled}
      onFocusIn={onSelectBoxFocusIn}
    >
      {props.children}
    </SelectBox>
  )

}
export default React.memo(LocsLookUp);
