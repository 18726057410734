import React, { useEffect, useState } from 'react'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput'
import { DataGrid, TextBox } from 'devextreme-react'
import { Column, Export, Pager, Paging } from 'devextreme-react/data-grid'
import { ZonasListado, ZonaDetail } from '../../types/types'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import {
  clearButtonClick,
  openTab,
  setButtons,
  setCurrentExecutingAction,
} from '../../store/tabsReducer'
import { useDispatch, useSelector } from 'react-redux'
import { zonasServices } from '../../services/zonas.services'
import { RootState } from '../../../../../../store/store'
import { ToastTypes } from '../../../../../../store/types'
import { addToast } from '../../../../../../store/toasterReducer'
import {
  changeLoaderZonas,
  setCurrentFunction,
} from '../../store/generalReducer'
import { initDatosEdicion } from '../../store/editDataReduce'
import { defaultDataZona } from '../..'
import { v4 as uuidv4 } from 'uuid'
import {
  fetchZones,
  setCleanResult,
  setFilterZonas,
} from '../../store/serachReducer'
import { getAllowedPageSizes } from '../../../../../../helpers/Helper'
import { TabState } from '../../../../../../store/genericTabTypes'
import ItemsCountIndicator from '../../../../../componentes/indicadorNroRegistros'

interface ISearchProps extends React.PropsWithChildren {
  tab: TabState<ZonasListado>
  tabId: string
}

const Search: React.FunctionComponent<ISearchProps> = (props) => {
  const { tabId } = props

  const dispatch = useDispatch()
  const currentAction = useSelector(
    (state: RootState) => state.clientes.zonas.general.currentFunction,
  )
  const resultados = useSelector((state: RootState) => {
    return state.clientes.zonas.search.resultados
  })
  const filter = useSelector((state: RootState) => {
    return state.clientes.zonas.search.filter
  })

  const [selectedZona, setSelectedZona] = useState<ZonaDetail>(null)
  const dataGrid = React.useRef<any>()

  const playLoader = React.useCallback(
    (message = 'Cargando...') => {
      dispatch(changeLoaderZonas({ show: true, mensaje: message }))
    },
    [dispatch],
  )

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoaderZonas({ show: false, mensaje: '' }))
  }, [dispatch])

  const onChangedZona = React.useCallback(
    (data) => {
      if (data.event)
        dispatch(
          setFilterZonas({
            ...filter,
            zona: data.value,
          }),
        )
    },
    [dispatch, filter],
  )

  const onSelectedZonaChanged = React.useCallback(
    ({ selectedRowsData, currentDeselectedRowKeys }) => {
      if (selectedRowsData && resultados.length > 0) {
        setSelectedZona(selectedRowsData[0])
        if (currentDeselectedRowKeys.length === 0)
          dispatch(
            setButtons({
              tabKey: tabId,
              buttons: {
                Nuevo: true,
                Guardar: false,
                Editar: true,
                Eliminar: true,
                Buscar: true,
                Imprimir: false,
                Deshacer: true,
              },
            }),
          )
      }
    },
    [dispatch, resultados, tabId],
  )

  const onCleanResultFilter = React.useCallback(() => {
    dispatch(setCleanResult())
  }, [dispatch])

  const onHandledit = React.useCallback(() => {
    const id = uuidv4()
    dispatch(
      initDatosEdicion({
        key: id,
        data: defaultDataZona,
      }),
    )
    dispatch(
      openTab({
        key: id,
        cliente: {
          codigo: selectedZona.codigo,
          nombre: selectedZona.nombre,
          estado: selectedZona.estado,
          guardado: false,
        },
      }),
    )
  }, [dispatch, selectedZona])

  const onHandleRemove = React.useCallback(async () => {
    try {
      dispatch(
        setCurrentExecutingAction({
          tabKey: tabId,
          buttonType: ButtonTypes.delete,
        }),
      )
      playLoader('Eliminando . . .')
      const deletePerformance = await zonasServices.deleteZona(
        selectedZona.codigo,
      )
      if (!deletePerformance.error) {
        stopLoader()
        onCleanResultFilter()
        const toAny: any = fetchZones(filter)
        dispatch(toAny)
        dispatch(
          addToast({
            id: '',
            autoHide: true,
            content: deletePerformance.message ?? 'Registro eliminado.',
            fade: true,
            title: 'Eliminar',
            type: ToastTypes.Success,
          }),
        )
      } else {
        dispatch(
          setCurrentExecutingAction({
            tabKey: tabId,
            buttonType: undefined,
          }),
        )
        stopLoader()
        dispatch(
          addToast({
            id: '',
            autoHide: true,
            content: deletePerformance.message ?? 'Error al eliminar.',
            fade: true,
            title: 'Eliminar',
            type: ToastTypes.Warning,
          }),
        )
      }
    } catch (error) {
      dispatch(
        setCurrentExecutingAction({
          tabKey: tabId,
          buttonType: undefined,
        }),
      )
      stopLoader()
      dispatch(
        addToast({
          id: '',
          autoHide: true,
          content:
            'Error al eliminar ' +
            (typeof error === 'string' ? error : JSON.stringify(error)),
          fade: true,
          title: 'Busqueda',
          type: ToastTypes.Danger,
        }),
      )
    }
  }, [
    dispatch,
    filter,
    onCleanResultFilter,
    playLoader,
    selectedZona,
    stopLoader,
    tabId,
  ])

  const handleButtonClick = React.useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.delete:
          onHandleRemove()
          break
        case ButtonTypes.edit:
          onHandledit()
          break
        default:
          break
      }
      dispatch(setCurrentFunction(''))
      dispatch(clearButtonClick(tabId))
    },
    [dispatch, onHandleRemove, onHandledit, tabId],
  )

  const handleSearch = React.useCallback(() => {
    onCleanResultFilter()
    const toAny: any = fetchZones(filter)
    dispatch(toAny)
  }, [dispatch, filter, onCleanResultFilter])

  useEffect(() => {
    if (currentAction !== '') handleButtonClick(currentAction)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAction])

  return (
    <>
      <RowContainer>
        <CustomCol xs="12" md="3">
          <Labeled label="Zona:">
            <TextBox
              value={filter.zona}
              onValueChanged={onChangedZona}
              onEnterKey={handleSearch}
            />
          </Labeled>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="7">
          <ItemsCountIndicator items={resultados} />
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="7">
          <DataGrid
            ref={dataGrid}
            keyExpr="codigo"
            focusedRowKey="codigo"
            id="tabCertificados"
            selection={{ mode: 'single' }}
            dataSource={resultados}
            showColumnLines={true}
            showRowLines={true}
            showBorders={true}
            columnResizingMode="widget"
            rowAlternationEnabled={true}
            onSelectionChanged={onSelectedZonaChanged}
            loadPanel={{
              enabled: true,
              height: 90,
              indicatorSrc: '',
              shading: false,
              shadingColor: '',
              showIndicator: true,
              showPane: true,
              text: 'Cargando...',
              width: 200,
            }}
          >
            <Paging defaultPageSize={20} />
            <Pager
              visible={resultados.length > 0 ? true : false}
              allowedPageSizes={getAllowedPageSizes(resultados)}
              displayMode={'full'}
              showPageSizeSelector={true}
              showInfo={true}
              showNavigationButtons={true}
            />
            <Export enabled={true} allowExportSelectedData={true} />
            <Column dataField="codigo" caption="Codigo" width="30%" />
            <Column dataField="nombre" caption="Zona" width="70%" />
          </DataGrid>
        </CustomCol>
      </RowContainer>
    </>
  )
}

export default Search
