import { RequestHelper } from '../../../../../helpers/requestHelper'
import { ApiResponseAll } from '../../../../../store/types'
import { AssignPosition, SavePermission, SavePosition } from '../types/types'

export const PositionsServices = {
  getListPositions,
  savePosition,
  deletePosition,
  getListPositionsOrganizationChart,
  getPositionsToBeAssigned,
  getAssignedPositions,
  saveAssignation,
  deleteAssignation,
  getMenuOptions,
  getAvailableCommands,
  getAssignedCommands,
  savePermission,
  deletePermission,
}

async function getListPositions<T>(
  postionDescription: string,
): Promise<ApiResponseAll<T>> {
  try {
    return await RequestHelper.getAll<ApiResponseAll<T>>(
      'admin',
      'roles/getAll',
      '',
      { descripcion: postionDescription },
    )
  } catch (error) {
    return error
  }
}

async function savePosition<T>(
  position: SavePosition,
): Promise<ApiResponseAll<T>> {
  try {
    return await RequestHelper.postAll<ApiResponseAll<T>>(
      'admin',
      'roles/save',
      position,
    )
  } catch (error) {
    return error
  }
}

async function deletePosition<T>(
  codePosition: number,
): Promise<ApiResponseAll<T>> {
  try {
    return await RequestHelper.deleteRequestAll<ApiResponseAll<T>>(
      'admin',
      'roles/inactive',
      '',
      { codigo: codePosition },
    )
  } catch (error) {
    return error
  }
}

async function getListPositionsOrganizationChart<T>(
  parentCode: number,
): Promise<ApiResponseAll<T>> {
  try {
    return await RequestHelper.getAll<ApiResponseAll<T>>(
      'admin',
      'organico/getAllXML',
      '',
      { padre: parentCode },
    )
  } catch (error) {
    return error
  }
}

async function getPositionsToBeAssigned<T>(
  code: number,
): Promise<ApiResponseAll<T>> {
  try {
    return await RequestHelper.getAll<ApiResponseAll<T>>(
      'admin',
      'roles/getByAssign',
      '',
      { orgCodigo: code },
    )
  } catch (error) {
    return error
  }
}

async function getAssignedPositions<T>(
  code: number,
): Promise<ApiResponseAll<T>> {
  try {
    return await RequestHelper.getAll<ApiResponseAll<T>>(
      'admin/roles',
      'organico/getAssign',
      '',
      { organicoCodigo: code },
    )
  } catch (error) {
    return error
  }
}

async function saveAssignation<T>(
  toAssign: AssignPosition,
): Promise<ApiResponseAll<T>> {
  try {
    return await RequestHelper.postAll<ApiResponseAll<T>>(
      'admin/roles',
      'organico/save',
      toAssign,
    )
  } catch (error) {
    return error
  }
}

async function deleteAssignation<T>(code: number): Promise<ApiResponseAll<T>> {
  try {
    return await RequestHelper.deleteRequestAll<ApiResponseAll<T>>(
      'admin/roles',
      'organico/delete',
      '',
      { orgRolCodigo: code },
    )
  } catch (error) {
    return error
  }
}

async function getMenuOptions<T>(): Promise<ApiResponseAll<T>> {
  try {
    return await RequestHelper.getAll<ApiResponseAll<T>>(
      'admin/usuarios',
      'menu/loadNav',
      '',
      { padre: 0 },
    )
  } catch (error) {
    return error
  }
}

async function getAvailableCommands<T>(
  codeMenu: number,
): Promise<ApiResponseAll<T>> {
  try {
    return await RequestHelper.getAll<ApiResponseAll<T>>(
      'admin/usuarios',
      'permisos/getComandAvailable',
      '',
      { menuCodigo: codeMenu },
    )
  } catch (error) {
    return error
  }
}

async function getAssignedCommands<T>(
  positionCode: number,
): Promise<ApiResponseAll<T>> {
  try {
    return await RequestHelper.getAll<ApiResponseAll<T>>(
      'admin/usuarios',
      'permisos/getAll',
      '',
      { asignado: positionCode, tipo: 'ROL' },
    )
  } catch (error) {
    return error
  }
}

async function savePermission<T>(
  permission: SavePermission,
): Promise<ApiResponseAll<T>> {
  try {
    return await RequestHelper.postAll<ApiResponseAll<T>>(
      'admin/usuarios',
      'permisos/save',
      permission,
    )
  } catch (error) {
    return error
  }
}

async function deletePermission<T>(code: number): Promise<ApiResponseAll<T>> {
  try {
    return await RequestHelper.deleteRequestAll<ApiResponseAll<T>>(
      'admin/usuarios',
      'permisos/delete',
      '',
      { codigo: code },
    )
  } catch (error) {
    return error
  }
}
