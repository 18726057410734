import { useState, useCallback } from 'react'
import { AccionMenu } from '../store/types'
import { findOnObject } from '../helpers/Helper'
import { TabsState } from '../store/genericTabTypes'

const useButtonDisabled = (tabs: TabsState<any>) => {
  const getButtonDisabled = useCallback(
    (buttonAction: AccionMenu) => {
      const currentTab = tabs.tabs[tabs.current]
      if (!currentTab) return true
      return !currentTab.buttons[buttonAction.actionType] ?? true
    },
    [tabs],
  )
  return getButtonDisabled
}

const useSortByProperty = () => {
  const sortByProperty = useCallback((property: string) => {
    return function (a: number, b: number) {
      if (a[property] > b[property]) return 1
      else if (a[property] < b[property]) return -1
      return 0
    }
  }, [])
  return sortByProperty
}

const useMenuOptions = () => {
  const [codModulo, setCodModulo] = useState<number>(0)
  const [options, setOptions] = useState<Array<AccionMenu>>([])

  const sortByProperty = useSortByProperty()

  const getMenuOptions = useCallback(
    (menu: any, keyToFind: string, pathToFind: string) => {
      const menuFound = findOnObject(menu, keyToFind, pathToFind)
      if (menuFound.length > 0) {
        let menuOptions = []
        setCodModulo(Number(menuFound[0]['codigo']))
        menuFound[0]['acciones'].map((itemMenu: AccionMenu) => {
          if (Number(itemMenu['main']) === 1) {
            let typeButton = itemMenu['name'] ?? 'none'
            if (itemMenu['name'].includes(' ')) {
              typeButton = itemMenu['name'].replace(' ', '_') ?? 'none'
            }
            menuOptions.push({
              ...itemMenu,
              nombre: itemMenu['name'],
              actionType: typeButton,
            })
          }
        })
        menuOptions = menuOptions.sort(sortByProperty('codigo'))
        setOptions(menuOptions)
      }
    },
    [sortByProperty],
  )

  return { getMenuOptions, codModulo, options }
}

export { useButtonDisabled, useSortByProperty, useMenuOptions }
