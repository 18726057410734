import { combineReducers } from '@reduxjs/toolkit'
import { datosEdicionReducer } from './editDataReducer'
import { menuReducer } from './menuReducer'
import { searchNotasDebitoReducer } from './searchReducer'
import { tabsReducer } from './tabsReducer'
import { configuracionesReducer } from './configuracionesNDebitoReducer'
import { NotasDebitoState } from '../types/types'

export const notasDebitoReducer = combineReducers<NotasDebitoState>({
  menu: menuReducer,
  tabs: tabsReducer,
  search: searchNotasDebitoReducer,
  editData: datosEdicionReducer,
  configuraciones: configuracionesReducer,
})
