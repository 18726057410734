import React from 'react'
import RowContainer from '../../../../../../../../views/componentes/rowContainer/rowContainer'
import Labeled from '../../../../../../../../views/componentes/labeledInput/labeledInput'
import CustomCol from '../../../../../../../../views/componentes/colContainer'
import { Button, TextBox } from 'devextreme-react'
import { PersonalDatosEdicion } from '../../../../types/types'
import { DocumentInfo } from '../../../../../../../../store/types'
import { TabState } from '../../../../../../../../store/genericTabTypes'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../../store/store'
import { setDatosEdicionNominaRolesPagos } from '../../../../store/editDataReducer'
import ModalCuentas from '../../../../../../../contabilidad/pages/planCuentas/modalCuentas'
import { consoleService } from '../../../../../../../../services/console.service'

interface ITabsRolesPagoProps extends React.PropsWithChildren {
  info: DocumentInfo<PersonalDatosEdicion>
  tab: TabState<PersonalDatosEdicion>
  tabId: string
  refForm: any
}

const RolesPago: React.FunctionComponent<ITabsRolesPagoProps> = (props) => {

  const { tabId } = props
  const dispatch = useDispatch()
  const rolesState = useSelector((state: RootState) => state.nomina.personal.editData[tabId].nomina.rolesPago)
  const [showModalPlanCuentas, setShowModalPlanCuentas] =
    React.useState<boolean>(false)
  const [tipoDescuento, setTipoDescuento] = React.useState<string>(null)


  const onChangeValue = React.useCallback((data, key: string) => {

    dispatch(setDatosEdicionNominaRolesPagos({
      key: tabId,
      data: {
        ...rolesState,
        [key]: data
      }
    }))
  }, [dispatch, rolesState, tabId])

  const onChangeNull = React.useCallback((key: string) => {
    dispatch(setDatosEdicionNominaRolesPagos({
      key: tabId,
      data: {
        ...rolesState,
        [key]: ''
      }
    }))
  }, [dispatch, rolesState, tabId])

  return (
    <>
      {showModalPlanCuentas && <ModalCuentas
        show={showModalPlanCuentas}
        onClose={() => setShowModalPlanCuentas(false)}
        cargarCuentas={(account) => {
          consoleService.log(account)
          const descripcion = `${account?.numero ?? ''} - ${account?.descripcion ?? ''}`
          onChangeValue(descripcion, tipoDescuento)
          setShowModalPlanCuentas(false)
        }}
      />}
      <RowContainer>
        <CustomCol xs="12" md="6">

          <RowContainer>
            <Labeled label="Sueldos y Salarios:">
              <RowContainer>
                <CustomCol md="12">
                  <div
                    style={{
                      display: 'flex',
                      gap: '2px',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <TextBox
                      value={rolesState?.sueldo ?? ''}
                      readOnly={true}
                      showClearButton={true}
                      width="100%"
                      inputAttr={{ autocomplete: 'nope' }}
                    />
                    <Button
                      id="btnAdd"
                      className="me-1"
                      icon="add"
                      stylingMode="contained"
                      type="default"
                      onClick={() => {
                        setTipoDescuento('sueldo')
                        setShowModalPlanCuentas(true)
                      }}
                    />
                    <Button
                      id="btnRemove"
                      icon="trash"
                      stylingMode="contained"
                      type="danger"
                      onClick={() => onChangeNull(tipoDescuento)}
                    />
                  </div>
                </CustomCol>
              </RowContainer>
            </Labeled>
          </RowContainer>
          <RowContainer>
            <Labeled label="Ajustes de Sueldo:">
              <RowContainer>
                <CustomCol md="12">
                  <div
                    style={{
                      display: 'flex',
                      gap: '2px',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <TextBox
                      value={rolesState?.ajustes ?? ''}
                      readOnly={true}
                      showClearButton={true}
                      width="100%"
                      inputAttr={{ autocomplete: 'nope' }}
                    />
                    <Button
                      id="btnAdd"
                      className="me-1"
                      icon="add"
                      stylingMode="contained"
                      type="default"
                      onClick={() => {
                        setTipoDescuento('ajustes')
                        setShowModalPlanCuentas(true)
                      }}
                    />
                    <Button
                      id="btnRemove"
                      icon="trash"
                      stylingMode="contained"
                      type="danger"
                      onClick={() => onChangeNull(tipoDescuento)}
                    />
                  </div>
                </CustomCol>
              </RowContainer>
            </Labeled>
          </RowContainer>
          <RowContainer>
            <Labeled label="Recargo con Horario Nocturno:">
              <RowContainer>
                <CustomCol md="12">
                  <div
                    style={{
                      display: 'flex',
                      gap: '2px',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <TextBox
                      value={rolesState?.nocturno ?? ''}
                      readOnly={true}
                      showClearButton={true}
                      width="100%"
                      inputAttr={{ autocomplete: 'nope' }}
                    />
                    <Button
                      id="btnAdd"
                      className="me-1"
                      icon="add"
                      stylingMode="contained"
                      type="default"
                      onClick={() => {
                        setTipoDescuento('nocturno')
                        setShowModalPlanCuentas(true)
                      }}
                    />
                    <Button
                      id="btnRemove"
                      icon="trash"
                      stylingMode="contained"
                      type="danger"
                      onClick={() => onChangeNull(tipoDescuento)}
                    />
                  </div>
                </CustomCol>
              </RowContainer>
            </Labeled>
          </RowContainer>
          <RowContainer>
            <Labeled label="Recargo con Hora Suplementario:">
              <RowContainer>
                <CustomCol md="12">
                  <div
                    style={{
                      display: 'flex',
                      gap: '2px',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <TextBox
                      value={rolesState?.suple ?? ''}
                      readOnly={true}
                      showClearButton={true}
                      width="100%"
                      inputAttr={{ autocomplete: 'nope' }}
                    />
                    <Button
                      id="btnAdd"
                      className="me-1"
                      icon="add"
                      stylingMode="contained"
                      type="default"
                      onClick={() => {
                        setTipoDescuento('suple')
                        setShowModalPlanCuentas(true)
                      }}
                    />
                    <Button
                      id="btnRemove"
                      icon="trash"
                      stylingMode="contained"
                      type="danger"
                      onClick={() => onChangeNull(tipoDescuento)}
                    />
                  </div>
                </CustomCol>
              </RowContainer>
            </Labeled>
          </RowContainer>
          <RowContainer>
            <Labeled label="Comisiones Ganadas:">
              <RowContainer>
                <CustomCol md="12">
                  <div
                    style={{
                      display: 'flex',
                      gap: '2px',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <TextBox
                      value={rolesState?.comision ?? ''}
                      readOnly={true}
                      showClearButton={true}
                      width="100%"
                      inputAttr={{ autocomplete: 'nope' }}
                    />
                    <Button
                      id="btnAdd"
                      className="me-1"
                      icon="add"
                      stylingMode="contained"
                      type="default"
                      onClick={() => {
                        setTipoDescuento('comision')
                        setShowModalPlanCuentas(true)
                      }}
                    />
                    <Button
                      id="btnRemove"
                      icon="trash"
                      stylingMode="contained"
                      type="danger"
                      onClick={() => onChangeNull(tipoDescuento)}
                    />
                  </div>
                </CustomCol>
              </RowContainer>
            </Labeled>
          </RowContainer>
          <RowContainer>
            <Labeled label="Impuesto a la Renta pagado por la empresa:">
              <RowContainer>
                <CustomCol md="12">
                  <div
                    style={{
                      display: 'flex',
                      gap: '2px',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <TextBox
                      value={rolesState?.irenta ?? ''}
                      readOnly={true}
                      showClearButton={true}
                      width="100%"
                      inputAttr={{ autocomplete: 'nope' }}
                    />
                    <Button
                      id="btnAdd"
                      className="me-1"
                      icon="add"
                      stylingMode="contained"
                      type="default"
                      onClick={() => {
                        setTipoDescuento('irenta')
                        setShowModalPlanCuentas(true)
                      }}
                    />
                    <Button
                      id="btnRemove"
                      icon="trash"
                      stylingMode="contained"
                      type="danger"
                      onClick={() => onChangeNull(tipoDescuento)}
                    />
                  </div>
                </CustomCol>
              </RowContainer>
            </Labeled>
          </RowContainer>
          <RowContainer>
            <Labeled label="Recargo de Hora Extraordinaria:">
              <RowContainer>
                <CustomCol md="12">
                  <div
                    style={{
                      display: 'flex',
                      gap: '2px',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <TextBox
                      value={rolesState?.extra ?? ''}
                      readOnly={true}
                      showClearButton={true}
                      width="100%"
                      inputAttr={{ autocomplete: 'nope' }}
                    />
                    <Button
                      id="btnAdd"
                      className="me-1"
                      icon="add"
                      stylingMode="contained"
                      type="default"
                      onClick={() => {
                        setTipoDescuento('extra')
                        setShowModalPlanCuentas(true)
                      }}
                    />
                    <Button
                      id="btnRemove"
                      icon="trash"
                      stylingMode="contained"
                      type="danger"
                      onClick={() => onChangeNull(tipoDescuento)}
                    />
                  </div>
                </CustomCol>
              </RowContainer>
            </Labeled>
          </RowContainer>
          <RowContainer>
            <Labeled label="Provision D. Cuarto por Pagar:">
              <RowContainer>
                <CustomCol md="12">
                  <div
                    style={{
                      display: 'flex',
                      gap: '2px',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <TextBox
                      value={rolesState?.pcsueldoxp ?? ''}
                      readOnly={true}
                      showClearButton={true}
                      width="100%"
                      inputAttr={{ autocomplete: 'nope' }}
                    />
                    <Button
                      id="btnAdd"
                      className="me-1"
                      icon="add"
                      stylingMode="contained"
                      type="default"
                      onClick={() => {
                        setTipoDescuento('pcsueldoxp')
                        setShowModalPlanCuentas(true)
                      }}
                    />
                    <Button
                      id="btnRemove"
                      icon="trash"
                      stylingMode="contained"
                      type="danger"
                      onClick={() => onChangeNull(tipoDescuento)}
                    />
                  </div>
                </CustomCol>
              </RowContainer>
            </Labeled>
          </RowContainer>
          <RowContainer>
            <Labeled label="Aporte Patronal para el IESS:">
              <RowContainer>
                <CustomCol md="12">
                  <div
                    style={{
                      display: 'flex',
                      gap: '2px',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <TextBox
                      value={rolesState?.apersonalxp ?? ''}
                      readOnly={true}
                      showClearButton={true}
                      width="100%"
                      inputAttr={{ autocomplete: 'nope' }}
                    />
                    <Button
                      id="btnAdd"
                      className="me-1"
                      icon="add"
                      stylingMode="contained"
                      type="default"
                      onClick={() => {
                        setTipoDescuento('apersonalxp')
                        setShowModalPlanCuentas(true)
                      }}
                    />
                    <Button
                      id="btnRemove"
                      icon="trash"
                      stylingMode="contained"
                      type="danger"
                      onClick={() => onChangeNull(tipoDescuento)}
                    />
                  </div>
                </CustomCol>
              </RowContainer>
            </Labeled>
          </RowContainer>
          <RowContainer>
            <Labeled label="Aporte Patronal por Pagar:">
              <RowContainer>
                <CustomCol md="12">
                  <div
                    style={{
                      display: 'flex',
                      gap: '2px',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <TextBox
                      value={rolesState?.apatronalxp ?? ''}
                      readOnly={true}
                      showClearButton={true}
                      width="100%"
                      inputAttr={{ autocomplete: 'nope' }}
                    />
                    <Button
                      id="btnAdd"
                      className="me-1"
                      icon="add"
                      stylingMode="contained"
                      type="default"
                      onClick={() => {
                        setTipoDescuento('apatronalxp')
                        setShowModalPlanCuentas(true)
                      }}
                    />
                    <Button
                      id="btnRemove"
                      icon="trash"
                      stylingMode="contained"
                      type="danger"
                      onClick={() => onChangeNull(tipoDescuento)}
                    />
                  </div>
                </CustomCol>
              </RowContainer>
            </Labeled>
          </RowContainer>
          <RowContainer>
            <Labeled label="Vacaciones:">
              <RowContainer>
                <CustomCol md="12">
                  <div
                    style={{
                      display: 'flex',
                      gap: '2px',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <TextBox
                      value={rolesState?.vacacion ?? ''}
                      readOnly={true}
                      showClearButton={true}
                      width="100%"
                      inputAttr={{ autocomplete: 'nope' }}
                    />
                    <Button
                      id="btnAdd"
                      className="me-1"
                      icon="add"
                      stylingMode="contained"
                      type="default"
                      onClick={() => {
                        setTipoDescuento('vacacion')
                        setShowModalPlanCuentas(true)
                      }}
                    />
                    <Button
                      id="btnRemove"
                      icon="trash"
                      stylingMode="contained"
                      type="danger"
                      onClick={() => onChangeNull(tipoDescuento)}
                    />
                  </div>
                </CustomCol>
              </RowContainer>
            </Labeled>
          </RowContainer>
          <RowContainer>
            <Labeled label="Vacaciones por Pagar:">
              <RowContainer>
                <CustomCol md="12">
                  <div
                    style={{
                      display: 'flex',
                      gap: '2px',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <TextBox
                      value={rolesState?.vacacionxp ?? ''}
                      readOnly={true}
                      showClearButton={true}
                      width="100%"
                      inputAttr={{ autocomplete: 'nope' }}
                    />
                    <Button
                      id="btnAdd"
                      className="me-1"
                      icon="add"
                      stylingMode="contained"
                      type="default"
                      onClick={() => {
                        setTipoDescuento('vacacionxp')
                        setShowModalPlanCuentas(true)
                      }}
                    />
                    <Button
                      id="btnRemove"
                      icon="trash"
                      stylingMode="contained"
                      type="danger"
                      onClick={() => onChangeNull(tipoDescuento)}
                    />
                  </div>
                </CustomCol>
              </RowContainer>
            </Labeled>
          </RowContainer>
          <RowContainer>
            <Labeled label="Fondo de Reserva:">
              <RowContainer>
                <CustomCol md="12">
                  <div
                    style={{
                      display: 'flex',
                      gap: '2px',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <TextBox
                      value={rolesState?.freserva ?? ''}
                      readOnly={true}
                      showClearButton={true}
                      width="100%"
                      inputAttr={{ autocomplete: 'nope' }}
                    />
                    <Button
                      id="btnAdd"
                      className="me-1"
                      icon="add"
                      stylingMode="contained"
                      type="default"
                      onClick={() => {
                        setTipoDescuento('freserva')
                        setShowModalPlanCuentas(true)
                      }}
                    />
                    <Button
                      id="btnRemove"
                      icon="trash"
                      stylingMode="contained"
                      type="danger"
                      onClick={() => onChangeNull(tipoDescuento)}
                    />
                  </div>
                </CustomCol>
              </RowContainer>
            </Labeled>
          </RowContainer>
          <RowContainer>
            <Labeled label="Impuesto a la Renta por Pagar:">
              <RowContainer>
                <CustomCol md="12">
                  <div
                    style={{
                      display: 'flex',
                      gap: '2px',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <TextBox
                      value={rolesState?.irentaxp ?? ''}
                      readOnly={true}
                      showClearButton={true}
                      width="100%"
                      inputAttr={{ autocomplete: 'nope' }}
                    />
                    <Button
                      id="btnAdd"
                      className="me-1"
                      icon="add"
                      stylingMode="contained"
                      type="default"
                      onClick={() => {
                        setTipoDescuento('irentaxp')
                        setShowModalPlanCuentas(true)
                      }}
                    />
                    <Button
                      id="btnRemove"
                      icon="trash"
                      stylingMode="contained"
                      type="danger"
                      onClick={() => onChangeNull(tipoDescuento)}
                    />
                  </div>
                </CustomCol>
              </RowContainer>
            </Labeled>
          </RowContainer>
          <RowContainer>
            <Labeled label="Prestamos Quirografarios por Pagar:">
              <RowContainer>
                <CustomCol md="12">
                  <div
                    style={{
                      display: 'flex',
                      gap: '2px',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <TextBox
                      value={rolesState?.quirografariosxp ?? ''}
                      readOnly={true}
                      showClearButton={true}
                      width="100%"
                      inputAttr={{ autocomplete: 'nope' }}
                    />
                    <Button
                      id="btnAdd"
                      className="me-1"
                      icon="add"
                      stylingMode="contained"
                      type="default"
                      onClick={() => {
                        setTipoDescuento('quirografariosxp')
                        setShowModalPlanCuentas(true)
                      }}
                    />
                    <Button
                      id="btnRemove"
                      icon="trash"
                      stylingMode="contained"
                      type="danger"
                      onClick={() => onChangeNull(tipoDescuento)}
                    />
                  </div>
                </CustomCol>
              </RowContainer>
            </Labeled>
          </RowContainer>
        </CustomCol>
        <CustomCol xs="12" md="6">

          <RowContainer>
            <Labeled label="Aporte Personal por Pagar al IESS:">
              <RowContainer>
                <CustomCol md="12">
                  <div
                    style={{
                      display: 'flex',
                      gap: '2px',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <TextBox
                      value={rolesState?.apersonalxp ?? ''}
                      readOnly={true}
                      showClearButton={true}
                      width="100%"
                      inputAttr={{ autocomplete: 'nope' }}
                    />
                    <Button
                      id="btnAdd"
                      className="me-1"
                      icon="add"
                      stylingMode="contained"
                      type="default"
                      onClick={() => {
                        setTipoDescuento('apersonalxp')
                        setShowModalPlanCuentas(true)
                      }}
                    />
                    <Button
                      id="btnRemove"
                      icon="trash"
                      stylingMode="contained"
                      type="danger"
                      onClick={() => onChangeNull(tipoDescuento)}
                    />
                  </div>
                </CustomCol>
              </RowContainer>
            </Labeled>
          </RowContainer>
          <RowContainer>
            <Labeled label="Bonificaciones:">
              <RowContainer>
                <CustomCol md="12">
                  <div
                    style={{
                      display: 'flex',
                      gap: '2px',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <TextBox
                      value={rolesState?.bonificacion ?? ''}
                      readOnly={true}
                      showClearButton={true}
                      width="100%"
                      inputAttr={{ autocomplete: 'nope' }}
                    />
                    <Button
                      id="btnAdd"
                      className="me-1"
                      icon="add"
                      stylingMode="contained"
                      type="default"
                      onClick={() => {
                        setTipoDescuento('bonificacion')
                        setShowModalPlanCuentas(true)
                      }}
                    />
                    <Button
                      id="btnRemove"
                      icon="trash"
                      stylingMode="contained"
                      type="danger"
                      onClick={() => onChangeNull(tipoDescuento)}
                    />
                  </div>
                </CustomCol>
              </RowContainer>
            </Labeled>
          </RowContainer>
          <RowContainer>
            <Labeled label="Bonificacion Navideña/Aguinaldos:">
              <RowContainer>
                <CustomCol md="12">
                  <div
                    style={{
                      display: 'flex',
                      gap: '2px',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <TextBox
                      value={rolesState?.bonificacionna ?? ''}
                      readOnly={true}
                      showClearButton={true}
                      width="100%"
                      inputAttr={{ autocomplete: 'nope' }}
                    />
                    <Button
                      id="btnAdd"
                      className="me-1"
                      icon="add"
                      stylingMode="contained"
                      type="default"
                      onClick={() => {
                        setTipoDescuento('bonificacionna')
                        setShowModalPlanCuentas(true)
                      }}
                    />
                    <Button
                      id="btnRemove"
                      icon="trash"
                      stylingMode="contained"
                      type="danger"
                      onClick={() => onChangeNull(tipoDescuento)}
                    />
                  </div>
                </CustomCol>
              </RowContainer>
            </Labeled>
          </RowContainer>
          <RowContainer>
            <Labeled label="Aporte Personal pagada por la empresa:">
              <RowContainer>
                <CustomCol md="12">
                  <div
                    style={{
                      display: 'flex',
                      gap: '2px',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <TextBox
                      value={rolesState?.apersonal ?? ''}
                      readOnly={true}
                      showClearButton={true}
                      width="100%"
                      inputAttr={{ autocomplete: 'nope' }}
                    />
                    <Button
                      id="btnAdd"
                      className="me-1"
                      icon="add"
                      stylingMode="contained"
                      type="default"
                      onClick={() => {
                        setTipoDescuento('apersonal')
                        setShowModalPlanCuentas(true)
                      }}
                    />
                    <Button
                      id="btnRemove"
                      icon="trash"
                      stylingMode="contained"
                      type="danger"
                      onClick={() => onChangeNull(tipoDescuento)}
                    />
                  </div>
                </CustomCol>
              </RowContainer>
            </Labeled>
          </RowContainer>
          <RowContainer>
            <Labeled label="Fondo de Reserva por Pagar:">
              <RowContainer>
                <CustomCol md="12">
                  <div
                    style={{
                      display: 'flex',
                      gap: '2px',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <TextBox
                      value={rolesState?.freservaxp ?? ''}
                      readOnly={true}
                      showClearButton={true}
                      width="100%"
                      inputAttr={{ autocomplete: 'nope' }}
                    />
                    <Button
                      id="btnAdd"
                      className="me-1"
                      icon="add"
                      stylingMode="contained"
                      type="default"
                      onClick={() => {
                        setTipoDescuento('freservaxp')
                        setShowModalPlanCuentas(true)
                      }}
                    />
                    <Button
                      id="btnRemove"
                      icon="trash"
                      stylingMode="contained"
                      type="danger"
                      onClick={() => onChangeNull(tipoDescuento)}
                    />
                  </div>
                </CustomCol>
              </RowContainer>
            </Labeled>
          </RowContainer>
          <RowContainer>
            <Labeled label="Prestamos Hipotecarios por Pagar:">
              {/* TODO: REV */}
              <RowContainer>
                <CustomCol md="12">
                  <div
                    style={{
                      display: 'flex',
                      gap: '2px',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <TextBox
                      value={rolesState?.hipotecariosxp ?? ''}
                      readOnly={true}
                      showClearButton={true}
                      width="100%"
                      inputAttr={{ autocomplete: 'nope' }}
                    />
                    <Button
                      id="btnAdd"
                      className="me-1"
                      icon="add"
                      stylingMode="contained"
                      type="default"
                      onClick={() => {
                        setTipoDescuento('hipotecariosxp')
                        setShowModalPlanCuentas(true)
                      }}
                    />
                    <Button
                      id="btnRemove"
                      icon="trash"
                      stylingMode="contained"
                      type="danger"
                      onClick={() => onChangeNull(tipoDescuento)}
                    />
                  </div>
                </CustomCol>
              </RowContainer>
            </Labeled>
          </RowContainer>
          <RowContainer>
            <Labeled label="Anticipos:">
              <RowContainer>
                <CustomCol md="12">
                  <div
                    style={{
                      display: 'flex',
                      gap: '2px',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <TextBox
                      value={rolesState?.anticipos ?? ''}
                      readOnly={true}
                      showClearButton={true}
                      width="100%"
                      inputAttr={{ autocomplete: 'nope' }}
                    />
                    <Button
                      id="btnAdd"
                      className="me-1"
                      icon="add"
                      stylingMode="contained"
                      type="default"
                      onClick={() => {
                        setTipoDescuento('anticipos')
                        setShowModalPlanCuentas(true)
                      }}
                    />
                    <Button
                      id="btnRemove"
                      icon="trash"
                      stylingMode="contained"
                      type="danger"
                      onClick={() => onChangeNull(tipoDescuento)}
                    />
                  </div>
                </CustomCol>
              </RowContainer>
            </Labeled>
          </RowContainer>
          <RowContainer>
            <Labeled label="Descuentos:">
              <RowContainer>
                <CustomCol md="12">
                  <div
                    style={{
                      display: 'flex',
                      gap: '2px',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <TextBox
                      value={rolesState?.descuentos ?? ''}
                      readOnly={true}
                      showClearButton={true}
                      width="100%"
                      inputAttr={{ autocomplete: 'nope' }}
                    />
                    <Button
                      id="btnAdd"
                      className="me-1"
                      icon="add"
                      stylingMode="contained"
                      type="default"
                      onClick={() => {
                        setTipoDescuento('descuentos')
                        setShowModalPlanCuentas(true)
                      }}
                    />
                    <Button
                      id="btnRemove"
                      icon="trash"
                      stylingMode="contained"
                      type="danger"
                      onClick={() => onChangeNull(tipoDescuento)}
                    />
                  </div>
                </CustomCol>
              </RowContainer>
            </Labeled>
          </RowContainer>
          <RowContainer>
            <Labeled label="Multas:">
              <RowContainer>
                <CustomCol md="12">
                  <div
                    style={{
                      display: 'flex',
                      gap: '2px',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <TextBox
                      value={rolesState?.multas ?? ''}
                      readOnly={true}
                      showClearButton={true}
                      width="100%"
                      inputAttr={{ autocomplete: 'nope' }}
                    />
                    <Button
                      id="btnAdd"
                      className="me-1"
                      icon="add"
                      stylingMode="contained"
                      type="default"
                      onClick={() => {
                        setTipoDescuento('multas')
                        setShowModalPlanCuentas(true)
                      }}
                    />
                    <Button
                      id="btnRemove"
                      icon="trash"
                      stylingMode="contained"
                      type="danger"
                      onClick={() => onChangeNull(tipoDescuento)}
                    />
                  </div>
                </CustomCol>
              </RowContainer>
            </Labeled>
          </RowContainer>
          <RowContainer>
            <Labeled label="Préstamos:">
              <RowContainer>
                <CustomCol md="12">
                  <div
                    style={{
                      display: 'flex',
                      gap: '2px',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <TextBox
                      value={rolesState?.prestamos ?? ''}
                      readOnly={true}
                      showClearButton={true}
                      width="100%"
                      inputAttr={{ autocomplete: 'nope' }}
                    />
                    <Button
                      id="btnAdd"
                      className="me-1"
                      icon="add"
                      stylingMode="contained"
                      type="default"
                      onClick={() => {
                        setTipoDescuento('prestamos')
                        setShowModalPlanCuentas(true)
                      }}
                    />
                    <Button
                      id="btnRemove"
                      icon="trash"
                      stylingMode="contained"
                      type="danger"
                      onClick={() => onChangeNull(tipoDescuento)}
                    />
                  </div>
                </CustomCol>
              </RowContainer>
            </Labeled>
          </RowContainer>
          <RowContainer>
            <Labeled label="Nomina por Pagar:">
              <RowContainer>
                <CustomCol md="12">
                  <div
                    style={{
                      display: 'flex',
                      gap: '2px',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <TextBox
                      value={rolesState?.nominaxp ?? ''}
                      readOnly={true}
                      showClearButton={true}
                      width="100%"
                      inputAttr={{ autocomplete: 'nope' }}
                    />
                    <Button
                      id="btnAdd"
                      className="me-1"
                      icon="add"
                      stylingMode="contained"
                      type="default"
                      onClick={() => {
                        setTipoDescuento('nominaxp')
                        setShowModalPlanCuentas(true)
                      }}
                    />
                    <Button
                      id="btnRemove"
                      icon="trash"
                      stylingMode="contained"
                      type="danger"
                      onClick={() => onChangeNull(tipoDescuento)}
                    />
                  </div>
                </CustomCol>
              </RowContainer>
            </Labeled>
          </RowContainer>
          <RowContainer>
            <Labeled label="Decimo Tercer Sueldo:">
              <RowContainer>
                <CustomCol md="12">
                  <div
                    style={{
                      display: 'flex',
                      gap: '2px',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <TextBox
                      value={rolesState?.dtsueldo ?? ''}
                      readOnly={true}
                      showClearButton={true}
                      width="100%"
                      inputAttr={{ autocomplete: 'nope' }}
                    />
                    <Button
                      id="btnAdd"
                      className="me-1"
                      icon="add"
                      stylingMode="contained"
                      type="default"
                      onClick={() => {
                        setTipoDescuento('dtsueldo')
                        setShowModalPlanCuentas(true)
                      }}
                    />
                    <Button
                      id="btnRemove"
                      icon="trash"
                      stylingMode="contained"
                      type="danger"
                      onClick={() => onChangeNull(tipoDescuento)}
                    />
                  </div>
                </CustomCol>
              </RowContainer>
            </Labeled>
          </RowContainer>
          <RowContainer>
            <Labeled label="Provisión D. Tercero Por Pagar:">
              <RowContainer>
                <CustomCol md="12">
                  <div
                    style={{
                      display: 'flex',
                      gap: '2px',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <TextBox
                      value={rolesState?.pdtsueldoxp ?? ''}
                      readOnly={true}
                      showClearButton={true}
                      width="100%"
                      inputAttr={{ autocomplete: 'nope' }}
                    />
                    <Button
                      id="btnAdd"
                      className="me-1"
                      icon="add"
                      stylingMode="contained"
                      type="default"
                      onClick={() => {
                        setTipoDescuento('pdtsueldoxp')
                        setShowModalPlanCuentas(true)
                      }}
                    />
                    <Button
                      id="btnRemove"
                      icon="trash"
                      stylingMode="contained"
                      type="danger"
                      onClick={() => onChangeNull(tipoDescuento)}
                    />
                  </div>
                </CustomCol>
              </RowContainer>
            </Labeled>
          </RowContainer>
          <RowContainer>
            <Labeled label="Decimo Cuarto Sueldo:">
              <RowContainer>
                <CustomCol md="12">
                  <div
                    style={{
                      display: 'flex',
                      gap: '2px',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <TextBox
                      value={rolesState?.dcsueldo ?? ''}
                      readOnly={true}
                      showClearButton={true}
                      width="100%"
                      inputAttr={{ autocomplete: 'nope' }}
                    />
                    <Button
                      id="btnAdd"
                      className="me-1"
                      icon="add"
                      stylingMode="contained"
                      type="default"
                      onClick={() => {
                        setTipoDescuento('dcsueldo')
                        setShowModalPlanCuentas(true)
                      }}
                    />
                    <Button
                      id="btnRemove"
                      icon="trash"
                      stylingMode="contained"
                      type="danger"
                      onClick={() => onChangeNull(tipoDescuento)}
                    />
                  </div>
                </CustomCol>
              </RowContainer>
            </Labeled>
          </RowContainer>
        </CustomCol>
      </RowContainer>
    </>
  )
}

export default React.memo(RolesPago)