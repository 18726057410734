import React, { useState, useEffect } from 'react'
import {
  CCol,
  CRow
} from '@coreui/react-pro'
import Modalform from "../../../../../../views/componentes/modalform"
import config from '../../../../../../config/config';
import { menuService } from '../../../../../../services/menu.service';
import { addToast } from '../../../../../../store/toasterReducer';
import { AccionMenu, ToastTypes } from '../../../../../../store/types';
import { useDispatch, useSelector } from 'react-redux'
import { changeLoader } from '../../../../../../store/reducers';
import { RootState } from '../../../../../../store/store'
import CardContent from '../../../../components/cardContent'
import { findOnObject } from '../../../../../../helpers/Helper';
import { GlobalMenu } from '../../../../../../views/componentes/globalMenu/globalMenu';
import Buscar from "./buscar"
import { setCurrentAccion } from '../../store/modalProveedor';
import { BotonMenu, TIPOS_BOTONES } from '../../types/types';

type ModalProveedoresProps = {
  show: boolean,
  onClose: () => void,
  cargarCuentas: (evt) => void,
  origen: string, //// main, modal
}

const ModalProveedores = (props: ModalProveedoresProps) => {

  const app = "c.c._y_ahorros"
  const pathVerificador = '/' + app;

  const dispatch = useDispatch()
  const currentActionState = useSelector((state: RootState) => state.proveedores.proveedores.modalProveedores.currentAction);
  const registros = useSelector((state: RootState) => state.proveedores.proveedores.modalProveedores.registros);
  const menuState = useSelector((state: RootState) => state.global.menu);

  const [acciones, setAcciones] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [seleccionado, setSeleccionado] = useState<any>([]);
  const [busqueda_ti, setBusqueda_ti] = useState<any>("");
  const [showConfirm, setShowConfirm] = React.useState<boolean>(false);
  const [confirmDelete, setConfirmDelete] = React.useState<boolean>(false);
  const [confirmUpdate, setConfirmUpdate] = React.useState<boolean>(false);
  const [messageConfirm, setMessageConfirm] = React.useState<string>("Está seguro que desea realizar estos cambios?");
  const [titleConfirm, setTitleConfirm] = React.useState<string>("Confirmar");
  const [options, setOptions] = React.useState<Array<AccionMenu>>([]);

  const getObjects = (obj: any, key: any, val: any) => {
    const find = findOnObject(obj, key, val);
    if (find.length > 0) {
      const opt: any = [];
      find[0]['acciones'].map((item) => {
        opt.push({ ...item, nombre: item['name'] })
      })
      setOptions(opt)
    }
  }

  useEffect(() => {
    if (menuState.length > 0 && options.length === 0) {
      getObjects(menuState, 'to', pathVerificador)
    }
  }, [menuState]);

  const botones = config['menuDefectoSearch'].map(acc => {
    const boton: BotonMenu = {
      id: acc.text as any,
      icon: menuService.getIcon(acc.icon),
      text: acc.text
    }
    return boton;
  });

  React.useEffect(() => {
    if (props?.show === true) {
      setAcciones(botones)
    }
  }, [props?.show]);


  const setToast = (texto, type) => {
    let tipo: ToastTypes = ToastTypes.Info;
    if (type === 'warning') {
      tipo = ToastTypes.Warning;
    } else if (type === 'success') {
      tipo = ToastTypes.Success;
    } if (type === 'info') {
      tipo = ToastTypes.Info;
    } if (type === 'danger') {
      tipo = ToastTypes.Danger;
    }
    dispatch(addToast({
      id: '',
      autoHide: 3500,
      title: 'Acatha',
      content: texto,
      fade: true,
      type: tipo
    }));
  }

  const playLoader = () => {
    dispatch(changeLoader({ show: true, mensaje: 'Cargando...' }))
  }

  const stopLoader = () => {
    dispatch(changeLoader({ show: false, mensaje: '' }))
  }

  const capturarAccion = (metodo) => {
    switch (metodo['nombre']) {
      case TIPOS_BOTONES.BOTON_NUEVO:
        dispatch(setCurrentAccion("Nuevo"))
        break;
      case TIPOS_BOTONES.BOTON_EXPORTAR:
        exportarDatos()
        break;
      case TIPOS_BOTONES.BOTON_BUSCAR:
        if (currentActionState === "Buscar") {
          document?.getElementById('btnModalBuscar')?.click()
        } else {
          dispatch(setCurrentAccion("Buscar"))
        }
        break;
      case TIPOS_BOTONES.BOTON_DESHACER:
        dispatch(setCurrentAccion("Buscar"))
        break;
      case TIPOS_BOTONES.BOTON_GUARDAR:
        if (currentActionState == "Nuevo") {
          document?.getElementById('btnSubmit')?.click()
          return false;
        } else if (currentActionState == "Editar") {
          document?.getElementById('btnSubmit')?.click()
          return false;
        } else {
          setToast("No se encuentra cambios que realizar.", "info")
        }
        break;
      case TIPOS_BOTONES.BOTON_EDITAR:
        if (seleccionado['index'] === null) {
          setToast("No se encuentra un banco seleccionado, Por favor seleccione un banco para continuar.", "info")
          return false;
        } else {
          setTitleConfirm("Confirmar")
          setConfirmUpdate(true)
          setMessageConfirm("Está seguro que desea realizar estos cambios?");
          setShowConfirm(true)
        }
        break;
      case TIPOS_BOTONES.BOTON_ELIMINAR:
        clickDelete()
        break;
      default:
        break;
    }
  }

  const desSeleccionarCuenta = () => {
    setSeleccionado(
      {
        index: null,
        row: null,
        selectedRow: null
      }
    )
  }

  const pasarCuentaSeleccion = (cuenta) => {
    if (cuenta['row']['codigo'] !== "") {
      props.cargarCuentas(cuenta['row']);
      props.onClose()
    }
  }

  const pasarCuenta = (cuenta) => {
    if (cuenta['codigo'] !== "") {
      props.cargarCuentas(cuenta);
      props.onClose()
    }
  }

  const confirmAction = () => {
    return (
      <Modalform
        name='confirmar'
        headerTitle={titleConfirm}
        childrenBody={renderBody()}
        childrenFooter={renderFooter()}
        closeOnBackdrop={false}
        show={showConfirm}
        onClose={() => setShowConfirm(!showConfirm)}
        centered={true}
        size="sm"
      />
    )
  }

  const onButtonExportarClick = React.useCallback(async (filtro) => {
  }, [registros]);

  const exportarDatos = () => {
    if (registros.length > 0) {
      onButtonExportarClick(registros)
    } else {
      setToast("No se encuentran datos para exportar.", "info")
    }
  }

  const clickDelete = () => {
    setConfirmDelete(true);
    setShowConfirm(true);
    setTitleConfirm("Confirmar");
    setMessageConfirm("Está seguro que desea eliminar este item?");
  }

  const bodyForm = () => {
    if (currentActionState === "Buscar") {
      return (
        <>
          {showConfirm === true && confirmAction()}

          <Buscar
            actionDelete={() => console.log()}
            exportExcel={() => console.log()}
            origen={props.origen} // main, modal
            returnSeleccion={(data) => props.cargarCuentas(data)}
          />

        </>
      )
    } else if (currentActionState === "Nuevo") {
      return (
        <>
          nuevo
        </>
      )
    } else if (currentActionState === "Editar") {
      return (
        <>
          nuevo
        </>
      )
    } else {
      return (
        <>
        </>
      )
    }
  }

  const getButtonDisabled = React.useCallback((button: AccionMenu) => {
    switch (button.nombre) {
      case TIPOS_BOTONES.BOTON_BUSCAR:
        return currentActionState !== "Buscar";
      case TIPOS_BOTONES.BOTON_NUEVO: {
        return currentActionState !== "Buscar" || seleccionado.index !== null;
      }
      case TIPOS_BOTONES.BOTON_ELIMINAR:
      case TIPOS_BOTONES.BOTON_EDITAR: {
        return (currentActionState !== "Buscar" || seleccionado.index === null);
      }
      case TIPOS_BOTONES.BOTON_DESHACER:
      case TIPOS_BOTONES.BOTON_GUARDAR: {
        return currentActionState === "Buscar";
      }

      default:
        return true;
    }
  }, [currentActionState, seleccionado]);

  const optionsForm = () => {
    if (options.length > 0) {
      return (
        <GlobalMenu
          acciones={options}
          onClick={capturarAccion}
          getButtonDisabled={getButtonDisabled}
        />
      )
    } else {
      return (<> </>)
    }
  }

  const renderBody = () => {

    return (
      <CardContent
        childrenOptions={optionsForm()}
        childrenBody={bodyForm()}
        headerTitle={currentActionState}
        aplicacion={"CCAhorros"}
      />
    );
  }

  const renderFooter = () => {
    return <></>
  }

  return (
    <CRow key="rowMain">
      <CCol key="colMain" >
        <Modalform
          name='modalProveedores'
          headerTitle={"Proveedores"}
          childrenBody={renderBody()}
          childrenFooter={renderFooter()}
          closeOnBackdrop={false}
          show={props.show}
          onClose={() => props.onClose()}
          centered={true}
          size="lg"
        />
      </CCol>
    </CRow>
  )
}

export default ModalProveedores