import { useDispatch } from "react-redux";
import { RequestHelper } from "../../../../../helpers/requestHelper";
import { PagedResult, PaginationInfo } from "../../../../../store/types";
import { FiltroBusquedaPersonal, PersonalList, RentasSave, SavePermises } from "../types/types";
import CustomStore from "devextreme/data/custom_store";
import { useCallback } from "react";
import DataSource from "devextreme/data/data_source";
import { setNroRegistrosEncontradas, setSearch } from "../store/searchReducer";
import { consoleService } from "../../../../../services/console.service";



export const PersonalServices = {
  useSetDataSources,
  getPersonal,
  deletePersonal,
  getPersonalById,
  saveAssigmentNomina,
  getPhones,
  getAllAssigmentNomina,
  getRentaPersonal,
  saveRentaPersonal,
  getDeletePersonal,
  getGenerate107,
  getUsuariosLocal,
  getUsuariosSinLocal,
  deleteUserLocal,
  getXMLOrganico,
  getPermises,
  getMenu,
  getCommandsAvaiable,
  savePermises,
  deletePermise,
  saveGrid
}

async function getPersonal(filter: FiltroBusquedaPersonal, tipo: string) {
  try {
    const obj = {
      usuarioId: filter?.usuario ?? '',
      nombres: filter?.nombres ?? '',
      apellidos: filter?.apellidos ?? '',
      cedula: filter?.cedula ?? '',
      local: filter?.locales ? filter.locales?.codigo ?? -1 : -1,
      ciudadOrigen: filter?.pais ? filter.pais?.codigo ?? -1 : -1,
      estado: filter?.activos ? 1 : 2,
      ciudadCodigo: -1,
    }
    let apiData = null
    if (tipo === "SADM") {
      apiData = await RequestHelper.getAll<any>(
        'admin/usuarios',
        'getAll',
        '',
        obj,
      )
    } else {
      apiData = await RequestHelper.get<any>(
        'admin/usuarios',
        'getByCompany',
        '',
        obj,
      )
    }


    return apiData
  } catch (error) {
    return error
  }
}

async function getPersonalById(cedula: string) {
  try {
    const obj = {
      cedula
    }

    const apiData = await RequestHelper.getAll<any>(
      'usuarios',
      'loadByIdentification',
      '',
      obj,
    )

    return apiData
  } catch (error) {
    return error
  }
}

async function getPhones(usuarioCodigo: number) {
  try {
    const obj = {
      usuarioCodigo
    }

    const apiData = await RequestHelper.getAll<any>(
      'admin/usuarios',
      'getPhones',
      '',
      obj,
    )

    return apiData
  } catch (error) {
    return error
  }
}


async function getPersonalPaged(
  filter: FiltroBusquedaPersonal,
  pagination: PaginationInfo,
  tipo: string
): Promise<PagedResult<PersonalList>> {

  const queryData = {
    usuarioId: filter?.usuario ?? '',
    nombres: filter?.nombres ?? '',
    apellidos: filter?.apellidos ?? '',
    cedula: filter?.cedula ?? '',
    local: filter?.locales ? filter.locales?.codigo ?? -1 : -1,
    ciudadOrigen: filter?.pais ? filter.pais?.codigo ?? 0 : 0,
    estado: filter?.activos ? 1 : 2,
    ciudadCodigo: -1,
    isPaged: true,
    page: pagination.current,
    limit: pagination.size,
  }

  let apiDataPaged = null

  if (tipo === "SADM") {
    apiDataPaged = await RequestHelper.get<any>(
      'admin/usuarios',
      'getAll',
      '',
      queryData,
    )
  } else {
    apiDataPaged = await RequestHelper.get<any>(
      'admin/usuarios',
      'getByCompany',
      '',
      queryData,
    )

  }

  const apiData = apiDataPaged.auto as Array<any> //await RequestHelper.get<Array<any>>('inventario', 'classification/all', '', queryData);
  const lineas = apiData
  const pagedResult: PagedResult<PersonalList> = {
    currentPage: parseInt(apiDataPaged.paginaActual),
    from: parseInt(apiDataPaged.desde),
    to: parseInt(apiDataPaged.hasta),
    count: parseInt(apiDataPaged.totalRegistros),
    result: lineas,
  }
  return pagedResult
}

async function saveGrid(data: Array<any>) {
  try {
    const obj = {
      infoRegistro: {
        usuarios: data
      }
    }

    const apiData = await RequestHelper.postAll<any>(
      'admin/usuarios',
      'saveGrid',
      obj,
    )

    return apiData
  } catch (error) {
    return error
  }
}

function useSetDataSources() {
  const dispatch = useDispatch()
  const getPersonalDatasource = useCallback(
    (find: FiltroBusquedaPersonal, tipo: string) => {
      const store = new CustomStore<void, PersonalList>({
        useDefaultSearch: true,
        key: 'codigo',
        byKey: async (key) => {
          console.log(key)
        },
        load: async (loadOptions) => {
          const paginInfo: PaginationInfo = {
            current: (loadOptions.skip ?? 0) / (loadOptions.take ?? 0) + 1,
            size: loadOptions.take ?? 10,
          }

          let pagedResult: PagedResult<PersonalList> = {
            currentPage: 0,
            from: 0,
            to: 0,
            count: 0,
            result: [],
          }

          pagedResult = await getPersonalPaged(find, paginInfo, tipo)
          if (pagedResult.result.length === 0) {
            dispatch(setSearch(false))
          }
          return {
            data: pagedResult.result,
            totalCount: pagedResult.count,
          }
        },
        update: async (data) => {
          consoleService.log(data)
          return Promise.resolve()
          // 4342
        },
      })
      const dataSource = new DataSource({
        totalCount: store.totalCount,
        paginate: true,
        pageSize: 10,
        store: store,
        key: 'codigo',
        requireTotalCount: true,
        onChanged: () => {
          dispatch(setNroRegistrosEncontradas(dataSource.totalCount()))
        },
      })
      return dataSource
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return { getPersonalDatasource }
}

async function deletePersonal(codigo: number) {
  try {
    const obj = {
      codigo
    }

    const apiData = await RequestHelper.deleteRequestAll<any>(
      'admin/usuarios',
      'inactive',
      '',
      obj,
    )

    return apiData
  } catch (error) {
    return error
  }
}

async function saveAssigmentNomina(asignacion: Array<any>, descuento: Array<any>) {
  try {
    const obj = {
      infoRegistro: {
        asignacion,
        descuento
      }
    }

    const apiData = await RequestHelper.postAll<any>(
      'contabilidad',
      'assignment/payroll/save',
      obj,
    )

    return apiData
  } catch (error) {
    return error
  }
}


async function getAllAssigmentNomina(usuarioCodigo: number, tipo: string) {
  try {
    const obj = {
      item: usuarioCodigo,
      tipo
    }

    const apiData = await RequestHelper.getAll<any>(
      'contabilidad',
      'assignment/payroll/all',
      '',
      obj,
    )

    return apiData
  } catch (error) {
    return error
  }
}

async function saveRentaPersonal(data: RentasSave) {
  try {
    const obj = {
      ...data
    }

    const apiData = await RequestHelper.postAll<any>(
      'cuenta',
      'employeeRent/save',
      obj,
    )

    return apiData
  } catch (error) {
    return error
  }
}

async function getRentaPersonal(codigoUsuario: number) {
  try {
    const obj = {
      usuario: codigoUsuario
    }

    const apiData = await RequestHelper.getAll<any>(
      'cuenta',
      'employeeRent/loadEmployee',
      '',
      obj,
    )

    return apiData
  } catch (error) {
    return error
  }
}

async function getDeletePersonal(codigoRem: number) {
  try {
    const obj = {
      codigo: codigoRem
    }

    const apiData = await RequestHelper.deleteRequestAll<any>(
      'cuenta',
      'employeeRent/delete',
      '',
      obj,
    )

    return apiData
  } catch (error) {
    return error
  }
}

async function getGenerate107(codigoUsuario: number, anio: number) {
  try {
    const obj = {
      usuario: codigoUsuario,
      anio
    }

    const apiData = await RequestHelper.getAll<any>(
      'admin/usuarios',
      'generate107',
      '',
      obj,
    )

    return apiData
  } catch (error) {
    return error
  }
}

async function getUsuariosLocal(localCodigo: number) {
  try {
    const obj = {
      localCodigo
    }

    const apiData = await RequestHelper.getAll<any>(
      'admin/usuarios',
      'usersLocal',
      '',
      obj,
    )

    return apiData
  } catch (error) {
    return error
  }
}
async function getUsuariosSinLocal(localCodigo: number, tipoUsuario: string) {
  try {
    const obj = {
      localCodigo,
      usuarioTipo: tipoUsuario
    }

    const apiData = await RequestHelper.getAll<any>(
      'admin/usuarios',
      'usersWithoutLocal',
      '',
      obj,
    )

    return apiData
  } catch (error) {
    return error
  }
}


async function deleteUserLocal(local: number, codigoUsuario: number) {
  try {
    const obj = {
      local,
      usuario: codigoUsuario
    }

    const apiData = await RequestHelper.deleteRequestAll<any>(
      'admin/usuarios',
      'deleteUserLocal',
      '',
      obj,
    )

    return apiData
  } catch (error) {
    return error
  }
}


async function getXMLOrganico(padre: number) {
  try {
    const obj = {
      padre
    }

    const apiData = await RequestHelper.getAll<any>(
      'admin/organico',
      'getAllXML',
      '',
      obj,
    )

    return apiData
  } catch (error) {
    return error
  }
}


async function getPermises(usuarioCodigo: number, tipo: string) {
  try {
    const obj = {
      asignado: usuarioCodigo,
      tipo
    }

    const apiData = await RequestHelper.getAll<any>(
      'admin/usuarios',
      'permisos/getAll',
      '',
      obj,
    )

    return apiData
  } catch (error) {
    return error
  }
}

async function getMenu(padre: number, usuario: number, tipo: string) {
  try {
    const obj = {
      padre,
      usuario,
      tipo
    }

    const apiData = await RequestHelper.getAll<any>(
      'admin/usuarios',
      'menu/loadNav',
      '',
      obj,
    )

    return apiData
  } catch (error) {
    return error
  }
}

async function getCommandsAvaiable(usuario: number, menu: number, tipo: string) {
  try {
    const obj = {
      usuario,
      menuCodigo: menu,
      tipo
    }

    const apiData = await RequestHelper.getAll<any>(
      'admin/usuarios',
      'permisos/getComandAvailable',
      '',
      obj,
    )

    return apiData
  } catch (error) {
    return error
  }
}

async function savePermises(data: SavePermises) {
  try {
    const obj = {
      ...data
    }

    const apiData = await RequestHelper.postAll<any>(
      'admin/usuarios',
      'permisos/save',
      obj,
    )

    return apiData
  } catch (error) {
    return error
  }
}
async function deletePermise(codigoPermiso: number) {
  try {
    const obj = {
      codigo: codigoPermiso
    }

    const apiData = await RequestHelper.deleteRequestAll<any>(
      'admin/usuarios',
      'permisos/delete',
      '',
      obj,
    )

    return apiData
  } catch (error) {
    return error
  }
}
