import { CRow } from '@coreui/react-pro'
import React from 'react'

interface IRowContainerProps extends React.PropsWithChildren {
  className?: string
  gutters?: boolean
  children?: React.ReactNode

}

const RowContainer: React.FunctionComponent<IRowContainerProps> = (props) => {
  return (
    <CRow
      style={{ paddingTop: '5px' }}
      className={props.className ?? ''}
    // gutters={props.gutters ?? true}
    >
      {props.children}
    </CRow>
  )
}

export default RowContainer
