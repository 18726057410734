import React from 'react'
import RowContainer from '../../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../../views/componentes/colContainer'
import { DateBox, NumberBox, TextArea, TextBox } from 'devextreme-react'
import Labeled from '../../../../../../../../views/componentes/labeledInput/labeledInput'
import FIleInput from '../../../../../../../componentes/fileInput'
import { ItemObservations } from '../../../../types/types'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../../store/store'
import { setDatObservationsToEdit } from '../../../../store/editDataReduce'

const Observaciones = (props) => {
  const { tabId } = props

  const dispatch = useDispatch()

  const dataToEditObservations = useSelector(
    (state: RootState) => state.inventarios.items.editData[tabId].observaciones,
  )

  // eslint-disable-next-line no-unused-vars
  const [manual, setManual] = React.useState<File>(null) //manual pdf file save
  // eslint-disable-next-line no-unused-vars
  const [specifications, setSpecifications] = React.useState<File>(null) //specifications pdf file save

  const onChangedPdfFiles = React.useCallback(
    (file: any, fieldToUpdate: string) => {
      let namePdf: string = ''
      let pdfFile: File = null
      if (file?.value.length > 0) {
        namePdf = file.value[0].name
        pdfFile = file.value[0]
      }
      if (fieldToUpdate === 'manual') setManual(pdfFile)
      else setSpecifications(pdfFile)
      dispatch(
        setDatObservationsToEdit({
          key: tabId,
          observation: {
            ...dataToEditObservations,
            [fieldToUpdate]: namePdf,
          },
        }),
      )
    },
    [dispatch, tabId, dataToEditObservations],
  )

  const onChangedFromObservations = React.useCallback(
    (event: any, fieldToUpdate: string) => {
      if (event.event)
        dispatch(
          setDatObservationsToEdit({
            key: tabId,
            observation: {
              ...dataToEditObservations,
              [fieldToUpdate]: event.value,
            },
          }),
        )
    },
    [dispatch, tabId, dataToEditObservations],
  )

  return (
    <RowContainer className="m-2">
      <RowContainer>
        <CustomCol xs="12" md="9">
          {'Registre observaciones especiales del ítem'}
          <TextArea
            style={{ marginTop: '4px' }}
            value={dataToEditObservations.observaciones}
            onValueChanged={(evt) =>
              onChangedFromObservations(evt, 'observaciones')
            }
          />
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="2">
          <Labeled label="Costo Refencial:">
            <NumberBox
              value={dataToEditObservations.costoReferencial}
              onValueChanged={(evt) =>
                onChangedFromObservations(evt, 'costoReferencial')
              }
              showClearButton={true}
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="2">
          <Labeled label="Ultimo Costo Real:">
            <NumberBox
              value={dataToEditObservations.costoReal}
              onValueChanged={(evt) =>
                onChangedFromObservations(evt, 'costoReal')
              }
              showClearButton={true}
              readOnly={true}
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="2">
          <Labeled label="Modificado:">
            <DateBox
              value={dataToEditObservations.modificado}
              onValueChanged={(evt) =>
                onChangedFromObservations(evt, 'modificado')
              }
              readOnly={true}
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="3">
          <div
            style={{
              display: 'flex',
              gap: '4px',
              alignItems: 'center',
              width: '100%',
              marginTop: '4px',
            }}
          >
            {'Titulo Adicional:'}
            <TextBox
              value={dataToEditObservations.titulo}
              onValueChanged={(evt) => onChangedFromObservations(evt, 'titulo')}
              showClearButton={true}
              width={'50%'}
            />
          </div>
          <div
            style={{
              display: 'flex',
              gap: '4px',
              alignItems: 'center',
              width: '100%',
              marginTop: '4px',
            }}
          >
            {'Valor Adicional:'}
            <TextBox
              value={dataToEditObservations.valor}
              onValueChanged={(evt) => onChangedFromObservations(evt, 'valor')}
              showClearButton={true}
              width={'50%'}
            />
          </div>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="4">
          <Labeled label="Manual:">
            <FIleInput
              accept={'.pdf'}
              allowedFileExtensions={['.pdf']}
              onChanged={(file) => onChangedPdfFiles(file, 'manual')}
              showDownloadDemo={false}
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="4">
          <Labeled label="Especificaciones:">
            <FIleInput
              accept={'.pdf'}
              allowedFileExtensions={['.pdf']}
              onChanged={(file) => onChangedPdfFiles(file, 'especificaciones')}
              showDownloadDemo={false}
            />
          </Labeled>
        </CustomCol>
      </RowContainer>
    </RowContainer>
  )
}

export default Observaciones

export const defaultObservaciones: ItemObservations = {
  observaciones: '',
  costoReferencial: 0.0,
  costoReal: 0.0,
  modificado: '',
  titulo: '',
  valor: '',
  manual: '',
  especificaciones: '',
}
