import React from 'react';
import Button from 'devextreme-react/button';
import ActionSheet from 'devextreme-react/action-sheet';
import { MovimientoTipo } from '../../types/types';

interface IActionSheetCommandsMobileProps extends React.PropsWithChildren {
  ingresoEgreso: MovimientoTipo
  onDelete: (inresoEgreso: MovimientoTipo) => void,
  onVer: (inresoEgreso: MovimientoTipo) => void,
  onEdit: (inresoEgreso: MovimientoTipo) => void,
}

/*
{ text: 'Email', icon: 'email' },
  { text: 'Autorizar', icon: 'email' },
*/

export const ActionSheetCommandsMobile: React.FC<IActionSheetCommandsMobileProps> = (props) => {
  const { onDelete, ingresoEgreso, onVer, onEdit } = props;
  const [visible, setVisible] = React.useState<boolean>(false);
  const [commands, setCommands] = React.useState<Array<any>>([]);

  const onButtonClick = () => {
    setVisible(!visible)
  }

  const onItemClick = React.useCallback(async (e) => {
    const { itemData } = e;
    switch (itemData.text) {
      case "Eliminar":
        onDelete(ingresoEgreso)
        break;
      case "Ver":
        onVer(ingresoEgreso)
        break;
      case "Editar":
        onEdit(ingresoEgreso)
        break;

      default:
        break;
    }
  }, [onDelete, ingresoEgreso, onVer, onEdit])

  React.useEffect(() => {
    const cmds: Array<any> = [
      { text: 'Eliminar', icon: 'trash' },
      { text: 'Ver', icon: 'file' },
      { text: 'Editar', icon: 'edit' },
    ];
    setCommands(cmds)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const keyId = `filamovimiento${ingresoEgreso.documento ?? ingresoEgreso.codigo}`
  return (
    <>
      <Button id={keyId} icon='overflow' type='default' onClick={onButtonClick} />
      <ActionSheet
        title="Tareas"
        usePopover={true}
        visible={visible}
        target={'#' + keyId}
        items={commands}
        itemComponent={ActionSheetCommand}
        onItemClick={onItemClick}
        onVisibleChange={(value) => {
          if (value !== visible) setVisible(!visible)
        }}
      />
    </>
  );
};



const ActionSheetCommand: React.FC<any> = (props) => {

  return <Button stylingMode='contained' type='default' text={props.data.text} icon={props.data.icon} />
}