import React from 'react'
import { SelectBox } from 'devextreme-react'
import { TOptionGenericoString } from '../../clientes/pages/clientes/types/types'

interface CodificacionLookupProps extends React.PropsWithChildren {
  selected: TOptionGenericoString | null
  // eslint-disable-next-line no-unused-vars
  onChanged: (newValue: TOptionGenericoString | null) => void
  disabled?: boolean
  provider?: Array<TOptionGenericoString> | []
}

const CodificacionLookup: React.FC<CodificacionLookupProps> = (props) => {
  const { provider, selected, onChanged, disabled } = props
  const selectRef = React.useRef<any>()
  const [options, setOptions] = React.useState<
    Array<TOptionGenericoString> | []
  >([])

  const loadDatos = React.useCallback(async () => {
    if (provider !== undefined && provider.length > 0) {
      setOptions(provider)
      const selectedIndex = provider.findIndex(
        (select: TOptionGenericoString) => {
          return select.value === selected?.value
        },
      )
      onChanged(provider[selectedIndex] ?? provider[0])
      if (selectedIndex > -1) onChanged(provider[selectedIndex] ?? provider[0])
    } else {
      const opt = Codificacion
      setOptions(opt)
      const selectedIndex = opt.findIndex((option) => {
        return option.value === selected?.value
      })
      if (selectedIndex > -1) onChanged(opt[selectedIndex] ?? opt[0])
    }
  }, [onChanged, provider, selected])

  const onLookUpValueChanged = React.useCallback(
    (evt: any) => {
      if (evt.event !== undefined) {
        onChanged(evt.value)
      }
    },
    [onChanged],
  )

  const onSelectBoxFocusIn = React.useCallback((e) => {
    e.element.querySelector('input.dx-texteditor-input').select()
  }, [])

  const refreshSelected = React.useCallback(async () => {
    if (options && options.length > 0) {
      const selectedIndex = await options.findIndex(
        (option: TOptionGenericoString) => {
          return option.value === selected?.value
        },
      )
      onChanged(options[selectedIndex] ?? options[0])
    }
  }, [onChanged, options, selected])

  React.useEffect(() => {
    loadDatos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (selected) refreshSelected()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  return (
    <SelectBox
      id="codificacionBarCodeSelect"
      ref={selectRef}
      items={Codificacion ?? []}
      searchEnabled
      displayExpr={'label'}
      searchExpr={['label']}
      searchTimeout={500}
      onEnterKey={() => {}}
      value={selected}
      onValueChanged={onLookUpValueChanged}
      disabled={disabled}
      onFocusIn={onSelectBoxFocusIn}
    >
      {props.children}
    </SelectBox>
  )
}

export default React.memo(CodificacionLookup)

export const Codificacion: Array<TOptionGenericoString> = [
  { value: 'ANY', label: 'La más acorde' },
  { value: 'EAN', label: 'EAN 8 - 13' },
  { value: 'UPC', label: 'EAN 12 Dígitos' },
  { value: 'ISBN', label: 'ISBN Números' },
  { value: '39', label: 'Code 39' },
  { value: '128', label: 'Code 128 (a,b,c: autoselección)' },
  { value: '128C', label: 'Code 128 (Forma compacta para dígitos)' },
  { value: '128B', label: 'Code 128, Impresión completa ascii' },
  { value: 'I25', label: 'Interpaginado 2 de 5' },
  { value: '128RAW', label: 'Raw code 128' },
  { value: 'CBR', label: 'Codabar' },
  { value: 'MSI', label: 'MSI' },
  { value: 'PLS', label: 'Plessey ' },
]
