import { cilChevronBottom, cilChevronTop } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CCardBody, CCardHeader, CCollapse, CLink } from '@coreui/react-pro'
import React from 'react'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput'
import { RadioGroup, TextBox } from 'devextreme-react'
import { useDispatch, useSelector } from 'react-redux'
import { changeFilter, setCollapsed } from '../../store/serachReducer'
import { RootState } from '../../../../../../../store/store'

export const optionsActivos = [
  { value: 1, label: 'Activos' },
  { value: 2, label: 'Inactivos' },
  { value: 3, label: 'Todos' },
]

interface ISearchProps extends React.PropsWithChildren {
  onSearchEnter: () => void
}


const FiltrosBusqueda: React.FunctionComponent<ISearchProps> = (props) => {
  const { onSearchEnter } = props
  const dispatch = useDispatch()
  const searchFilter = useSelector((state: RootState) => state.inventarios.catalogos.unidades.search.filter)
  const collapsed = useSelector((state: RootState) => state.inventarios.catalogos.unidades.search.colapsado)

  const onValueChanged = React.useCallback((data, key: string) => {
    if (data?.event !== null && data?.event !== undefined) {
      dispatch(changeFilter({
        ...searchFilter,
        [key]: data.value
      }))
    }
  }, [dispatch, searchFilter])

  return (
    <>
      <CCardHeader
        onClick={() => dispatch(setCollapsed(!collapsed))}
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <strong> {collapsed ? '' : 'Filtro de Búsqueda'} </strong>
        <CLink className="card-header-action">
          <CIcon icon={collapsed ? cilChevronBottom : cilChevronTop} />
        </CLink>
      </CCardHeader>
      <CCollapse visible={collapsed}>
        <CCardBody>
          <RowContainer>
            <CustomCol xs="12" md="4">
              <Labeled label="Descripción:">
                <TextBox
                  value={searchFilter?.nombre ?? ''}
                  onValueChanged={(data) => onValueChanged(data, 'nombre')}
                  onEnterKey={onSearchEnter}
                />
              </Labeled>
            </CustomCol>
            <CustomCol xs="12" md="4">
              <Labeled label="Estado:">
                <RadioGroup
                  name="grupos"
                  layout="horizontal"
                  displayExpr="label"
                  items={optionsActivos}
                  value={searchFilter?.estado ?? null}
                  onValueChanged={(data) => onValueChanged(data, 'estado')}
                />
              </Labeled>
            </CustomCol>
          </RowContainer>
        </CCardBody>
      </CCollapse>
    </>
  )
}

export default React.memo(FiltrosBusqueda)