import React from 'react';
import SelectBox from 'devextreme-react/select-box';
import { tiposLocalesServices } from './service/tiposLocales.service';
import { OptionTiposLocales } from './types/types';
import { ESubtipo } from '../../../store/enum/subtypes';

interface TiposLocalesLookUpProps extends React.PropsWithChildren {
  selected: OptionTiposLocales | null,
  onChanged: (newValue: OptionTiposLocales | null) => void,
  onChangedOptions: (newValue: Array<OptionTiposLocales> | []) => void,
  disabled?: boolean,
}

const TiposLocalesLookUp: React.FC<TiposLocalesLookUpProps> = (props) => {
  const { selected, onChanged, disabled, onChangedOptions } = props;
  const selectRef = React.useRef<any>();
  const [options, setOptions] = React.useState<Array<OptionTiposLocales> | []>([]);

  const loadDatos = React.useCallback(async () => {
    const data = await tiposLocalesServices.getTipos(ESubtipo.Establecimientos, 'Elija un tipo')
    if (data.error === false) {
      let opt = []
      if (data.auto.length > 0) {
        opt = data.auto.map((x) => {
          return {
            value: Number(x?.codigo),
            label: String(x?.valor),
          }
        })
      }
      setOptions(opt)
      if (onChangedOptions) {
        onChangedOptions(opt)
      }
      const selectedIndex = opt.findIndex((option) => {
        return option.value === selected?.value
      })
      if (selectedIndex > -1) {
        onChanged(opt[selectedIndex] ?? opt[0])
      }
    }
  }, [onChanged, onChangedOptions, selected])

  const getItemDisplayExpr = React.useCallback((item: OptionTiposLocales) => {
    return item ? `${item.label}` : ''
  }, [])

  const onLookUpValueChanged = React.useCallback(
    (evt: any) => {
      if (evt.event !== undefined) {
        onChanged(evt.value)
      }
    },
    [onChanged],
  )

  const onSelectBoxFocusIn = React.useCallback((e) => {
    e.element.querySelector('input.dx-texteditor-input').select()
  }, [])

  const refreshSelected = React.useCallback(() => {
    if (options && options.length > 0) {
      const selectedIndex = options.findIndex((option: OptionTiposLocales) => {
        return option.value === selected?.value
      })
      onChanged(options[selectedIndex] ?? options[0])
    }
    selectRef.current.instance.repaint();
  }, [onChanged, options, selected])

  React.useEffect(() => {
    loadDatos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    refreshSelected()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  return (
    <SelectBox
      id='tiposLocalesSelect'
      ref={selectRef}
      items={options ?? []}
      searchEnabled
      displayExpr={getItemDisplayExpr}
      searchExpr={['label']}
      searchTimeout={500}
      onEnterKey={(e) => { console.log(e) }}
      value={selected}
      onValueChanged={onLookUpValueChanged}
      disabled={disabled}
      onFocusIn={onSelectBoxFocusIn}
    >
      {props.children}
    </SelectBox>
  )

}
export default React.memo(TiposLocalesLookUp);
