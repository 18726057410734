import { AutorizacionDocumentoVenta } from '../../../../ventas/types/types'
import { WindowVComprasState } from '../../../types/genercis'
import { RetencionDatosEdicion, RetencionInfo } from '../../../types/types'

export type DetalleRetencionRedux = {
 tipo: TiposRetencion
 anioFiscal: number
 baseImponible: number
 concepto: ConceptoRetencion | null
 conceptoCodigo: string
 porcentaje: number
 valor: number
 fecha: string
 id: string //Si la fila ya está guardada en el grid.
}

export enum TiposRetencion {
 Fuente = 'Fuente',
 IVA = 'IVA',
 NoDefinido = 'NA',
}

export enum TiposPorcentajeRetencion {
 Unico = 'Unico',
 Opciones = 'Opciones',
 Limite = 'Limite',
}

export type ConceptoRetencion = {
 codigo: string
 concepto: string
 porcentaje: number
 estado: boolean
 tipo: TiposRetencion
 tipoPorcentaje: TiposPorcentajeRetencion
 opciones?: Array<number>
 rango?: {
  inferior: number
  superior: number
 }
}

export type ConfiguracionesRetenciones = {
 autorizacionDocumento: AutorizacionDocumentoVenta | null
}

export type RetencionesState = WindowVComprasState<
 RetencionInfo,
 RetencionDatosEdicion
> & {
 configuraciones: ConfiguracionesRetenciones
}
