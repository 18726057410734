import React from "react";
import { CButton, CCol, CFormInput, CInputGroup, CFormFeedback } from '@coreui/react-pro';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-light-svg-icons";
import Labeled from "../../../../../views/componentes/labeledInput/labeledInput";
import { TABLECOL_CANTIDAD, TABLECOL_CODIGO, TABLECOL_COMANDOS, TABLECOL_DESCRIPCION, TABLECOL_FEEDBACK, TABLECOL_ISVALID, TABLECOL_IVA, TABLECOL_PRECIO, TABLECOL_SUBTOTAL, TABLECOL_TOTAL } from "../../../pages/ventas/types/types";
import RowContainer from "../../../../../views/componentes/rowContainer/rowContainer";

export function getColumnnasTablaDetalleVentas(
  onUpdateCell: (row: number, col: number, value: string) => void,
  onDeleteRow: (row) => void): Array<any> {
  const columnnasTablaDetalleVentas = [
    {
      name: "Commands",
      label: " ",
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <CButton color='danger' onClick={() => {
                onDeleteRow(tableMeta.rowIndex);
              }}>
                <FontAwesomeIcon icon={faTrash} />
              </CButton>
            </>

          );
        }
      }
    },
    {
      label: "Código",
      name: "codigoBarras",
      options: {
        filter: true,
        size: 'small'
      }
    },
    {
      label: "Descripción",
      name: "descripcion",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Labeled
              label='Descripción'
            >
              <CInputGroup>
                <CFormInput
                  size='sm'
                  type='text'
                  value={value}
                  invalid={false}
                  onChange={(e: React.FormEvent<HTMLInputElement>) => {
                    //updateValue(parseFloat(e.currentTarget.value))
                    onUpdateCell(tableMeta.rowIndex, tableMeta.columnIndex, e.currentTarget.value);
                  }}
                >

                </CFormInput>
                {false &&
                  <CFormFeedback invalid>{ }</CFormFeedback>
                }
              </CInputGroup>
            </Labeled>


          )
        }
      }
    },
    {
      label: "Cant.",
      name: "_cantidad",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <CInputGroup>
              <CFormInput
                type='number'
                size='sm'
                value={value}
                invalid={false}
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                  //updateValue(parseFloat(e.currentTarget.value))
                  onUpdateCell(tableMeta.rowIndex, tableMeta.columnIndex, e.currentTarget.value);
                }}
              >

              </CFormInput>
              {false &&
                <CFormFeedback invalid>{tableMeta.rowData[TABLECOL_FEEDBACK].cantidad}</CFormFeedback>
              }
            </CInputGroup>
          )
        }
      }
    },
    {
      label: "Prec.",
      name: "_precioUnitario",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <CInputGroup>
              <CFormInput
                type='number'
                size='sm'
                value={value}
                invalid={false}
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                  //updateValue(parseFloat(e.currentTarget.value))
                  onUpdateCell(tableMeta.rowIndex, tableMeta.columnIndex, e.currentTarget.value);
                }}
              >

              </CFormInput>
              {false &&
                <CFormFeedback invalid>{tableMeta.rowData[TABLECOL_FEEDBACK].precio}</CFormFeedback>
              }
            </CInputGroup>
          )
        }
      }
    },
    {
      label: "Subt.",
      name: "_subtotal",
    },
    {
      label: "i.V.A",
      name: "_iva",
    },
    {
      label: "Total",
      name: "_total",
    },
    {
      label: "puedeEditar",
      name: 'puedeEditar',
      options: {
        display: false
      }
    },
    {
      label: "valida",
      name: 'valida',
      options: {
        display: false
      }
    },
    {
      label: "feedback",
      name: 'feedback',
      options: {
        display: false
      }
    }
  ];
  return columnnasTablaDetalleVentas;
}


export const OptionsTablaDetalleVentas = {
  responsive: "vertical",
  selectableRows: 'none',
  padding: 'none',
  size: 'small',
  download: false,
  viewColumns: false,
  filter: false,
  pagination: true,
  print: false,
  search: false,
  elevation: 6,
  customRowRender: (data, dataIndex, rowIndex) => {
    return (
      <tr key={`$filaDetalle${rowIndex}`}>
        <th>
          <div className='container customMuiRowContainer'>
            <RowContainer>
              <CCol xs='8'>
                <Labeled
                  label='Código'
                >
                  <span>{data[TABLECOL_CODIGO]}</span>
                </Labeled>
              </CCol>
              <CCol xs='4'>
                {data[TABLECOL_COMANDOS]}
              </CCol>
            </RowContainer>
            <RowContainer>
              <CCol xs='12'>
                {data[TABLECOL_DESCRIPCION]}
              </CCol>
            </RowContainer>
            <RowContainer>
              <CCol xs='6'>
                <Labeled
                  label='Cantidad'
                >
                  {data[TABLECOL_CANTIDAD]}
                </Labeled>
              </CCol>
              <CCol xs='6'>
                <Labeled
                  label='Precio'
                >
                  {data[TABLECOL_PRECIO]}
                </Labeled>

              </CCol>
            </RowContainer>
            <RowContainer>
              <CCol xs='4'>
                <Labeled
                  label='Subtotal'
                >
                  <span>{data[TABLECOL_SUBTOTAL].toFixed(2)}</span>
                </Labeled>
              </CCol>
              <CCol xs='4'>
                <Labeled
                  label='I.V.A'
                >
                  <span>{data[TABLECOL_IVA].toFixed(2)}</span>
                </Labeled>
              </CCol>
              <CCol xs='4'>
                <Labeled
                  label='Total'
                >
                  <span>{data[TABLECOL_TOTAL].toFixed(2)}</span>
                </Labeled>
              </CCol>
            </RowContainer>
          </div>
          {/* <Divider /> */}
        </th>
      </tr>

    )
  },
  textLabels: {
    body: {
      noMatch: "",
    },
  }
};
