import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EdicionPayload } from '../../../../store/types'
import { IngresosEgresosDatosEdicion } from '../types/types'
import { CustomDictionary, LoaderInfo } from '../../../../../../store/types'
import { Movimiento } from '../../conceptosMovimientos/types/types'
import { ProveedorBusqueda } from '../../../../../proveedores/store/types'
import { Cliente } from '../../../../../ventas/types/types'
import { LocalOption } from '../../../../../componentes/localesLookUp/store/type'
import { Proyecto } from '../../../../../bancos/pages/movimientosBancos/ingresosNC/store/types'

const initialState: CustomDictionary<IngresosEgresosDatosEdicion> = {}

const datosEditSlice = createSlice({
  name: 'ingEgrEdicion',
  initialState: initialState,
  reducers: {
    deleteEditData(state, action: PayloadAction<string>) {
      delete state[action.payload]
    },
    setDatosEdicion(
      state,
      action: PayloadAction<EdicionPayload<IngresosEgresosDatosEdicion>>,
    ) {
      state[action.payload.key] = action.payload.data
    },
    initDatosEdicion(
      state,
      action: PayloadAction<EdicionPayload<IngresosEgresosDatosEdicion>>,
    ) {
      if (!state[action.payload.key]) {
        state[action.payload.key] = action.payload.data
      }
    },
    onChangeMovimientos(
      state,
      action: PayloadAction<{ key: number | string; concepto: Movimiento }>,
    ) {
      state[action.payload.key].concepto = action.payload.concepto
    },
    onChangeLocal(
      state,
      action: PayloadAction<{ key: number | string; local: LocalOption }>,
    ) {
      state[action.payload.key].local = action.payload.local
    },
    onChangeProyectos(
      state,
      action: PayloadAction<{
        key: number | string
        proyecto: Proyecto
      }>,
    ) {
      state[action.payload.key].proyectos = action.payload.proyecto
    },
    onChangeAsociado(
      state,
      action: PayloadAction<{
        key: number | string
        tipo: string
        asociado: ProveedorBusqueda | Cliente
      }>,
    ) {
      state[action.payload.key][action.payload.tipo] = action.payload.asociado
    },
    onChangeDocumento(
      state,
      action: PayloadAction<{ key: number | string; documento: string }>,
    ) {
      state[action.payload.key].documento = action.payload.documento
    },
    onChangeDetall(
      state,
      action: PayloadAction<{ key: number | string; detalle: Array<any> }>,
    ) {
      state[action.payload.key].dataGrid = action.payload.detalle
    },
    onChangeSeries(
      state,
      action: PayloadAction<{ key: number | string; series: Array<any> }>,
    ) {
      state[action.payload.key].dataSeries = action.payload.series
    },
    changeLoader(state, action: PayloadAction<{ key: number | string; loader: LoaderInfo }>) {
      state[action.payload.key].loader = action.payload.loader
    },
    onChangeTotales(
      state,
      action: PayloadAction<{
        key: number | string
        subtotalIva: number | null
        subtotalIva0: number | null
        iva: number | null
        total: number | null
        art: number | null
      }>,
    ) {
      state[action.payload.key].subtotalIva = action.payload.subtotalIva
      state[action.payload.key].subtotalIva0 = action.payload.subtotalIva0
      state[action.payload.key].iva = action.payload.iva
      state[action.payload.key].total = action.payload.total
      state[action.payload.key].numArt = action.payload.art
    },
    onChangeCantidadAdd(
      state,
      action: PayloadAction<{ key: number | string; cantidad: number | null }>,
    ) {
      state[action.payload.key].cantidad = action.payload.cantidad
    },
  },
})

export const {
  onChangeCantidadAdd,
  onChangeSeries,
  onChangeTotales,
  onChangeDetall,
  onChangeDocumento,
  onChangeAsociado,
  onChangeMovimientos,
  setDatosEdicion,
  initDatosEdicion,
  deleteEditData,
  onChangeLocal,
  onChangeProyectos,
  changeLoader,
} = datosEditSlice.actions
export const dataEditReducer = datosEditSlice.reducer
