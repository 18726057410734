import React, { useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput'
import { DataGrid, LoadPanel, RadioGroup, TextBox } from 'devextreme-react'
import { GemasDatosEdicion } from '../../types/types'
import { ButtonTypes } from '../../../../../../../views/componentes/globalMenu/types'
import { clearButtonClick, openTab } from '../../store/tabsReducer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import {
  changeLoaderGems,
  setCurrentFunction,
} from '../../store/generalReducer'
import { CCard, CCardFooter, CCardHeader, CCol, CCollapse, CLink } from '@coreui/react-pro'
import { Column, Pager, Paging } from 'devextreme-react/data-grid'
import { getAllowedPageSizes } from '../../../../../../../helpers/Helper'
import { TabState } from '../../../../../../../store/genericTabTypes'
import { changeFilter, fetcGemas, setSeleccionarDato } from '../../store/serachReducer'
import { FETCH_STATUS, ToastTypes } from '../../../../../../../store/types'
import ItemsCountIndicator from '../../../../../../componentes/indicadorNroRegistros'
import { addToast } from '../../../../../../../store/toasterReducer'
import { initDatosEdicion } from '../../store/editDataReducer'
import { tipoEstadoService } from '../../../../../../componentes/tipoEstado/service/tipoEstado.service'
import CIcon from '@coreui/icons-react'
import { cilChevronBottom, cilChevronTop } from '@coreui/icons'


export const optionsGems = [
  { value: 1, label: 'Engaste' },
  { value: 2, label: 'Corte' },
  { value: 3, label: 'Posición' },
]

interface ISearchProps extends React.PropsWithChildren {
  tab: TabState<GemasDatosEdicion>
  tabId: string
}

const Search: React.FunctionComponent<ISearchProps> = (props) => {
  const { tabId } = props

  const dispatch = useDispatch()
  const currentAction = useSelector((state: RootState) => state.inventarios.catalogos.gemas.general.currentFunction)
  const searchFilter = useSelector((state: RootState) => state.inventarios.catalogos.gemas.search.filter)
  const searchResults = useSelector((state: RootState) => state.inventarios.catalogos.gemas.search.resultados)
  const searchStatus = useSelector((state: RootState) => state.inventarios.catalogos.gemas.search.status)
  const selected = useSelector((state: RootState) => state.inventarios.catalogos.gemas.search.seleccionado)

  const dataGrid = React.useRef<any>()
  const [resultados, setResultados] = React.useState([])
  const [showLoader, setShowLoader] = React.useState<boolean>(false)
  const position = { of: '#gemasContent' }
  const [collapsed, setCollapsed] = React.useState<boolean>(true)

  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(
      addToast({
        title: 'Inventario - Gemas',
        content: mensaje,
        type: tipo,
      }),
    )
  }, [dispatch])

  const playLoader = React.useCallback(
    (message = 'Cargando...') => {
      dispatch(changeLoaderGems({ show: true, mensaje: message }))
    },
    [dispatch],
  )

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoaderGems({ show: false, mensaje: '' }))
  }, [dispatch])

  const onValueCahanged = React.useCallback((data, key: string) => {
    if (data?.value !== null && data?.value !== undefined) {
      dispatch(changeFilter({
        ...searchFilter,
        [key]: data?.value
      }))
    }
  }, [dispatch, searchFilter])

  const getEstado = React.useCallback(async (codigo: number) => {
    try {
      const data = await tipoEstadoService.getTipoEstado('Elija un estado')
      if (data !== null && data !== undefined && data['error'] === false) {
        const estado = data['auto'].find(item => item.codigo === codigo)
        return estado
      }
      return null
    } catch (error) {
      return null
    }
  }, [])

  const onHandleEdit = React.useCallback(async () => {
    playLoader()
    const estado = await getEstado(selected.row?.estado)
    const id = uuidv4()
    let curentTabState = ''
    if (searchFilter.estado?.value === 1) {
      curentTabState = 'Engaste Gemas'
    } else if (searchFilter.estado?.value === 2) {
      curentTabState = 'Corte Gemas'
    } else if (searchFilter.estado?.value === 3) {
      curentTabState = 'Posición Gemas'
    }
    stopLoader()
    const edit_data: GemasDatosEdicion = {
      codigo: selected.row?.codigo ?? 0,
      descripcion: selected.row?.descripcion ?? '',
      pertenece: null,
      estado,
      observaciones: '',
      engaste: { descripcion: '', estado: null },
      corte: { descripcion: '', estado: null },
      posicion: { descripcion: '', estado: null },
      tabs: ['Engaste Gemas', 'Corte Gemas', 'Posición Gemas'],
      currentTab: curentTabState,
      guardado: false,
    }
    dispatch(
      initDatosEdicion({
        key: id,
        data: edit_data,
      }),
    )
    dispatch(openTab({ key: id, gema: edit_data }))
  }, [selected, dispatch, searchFilter, playLoader, stopLoader, getEstado])

  const onHandleRemove = React.useCallback(async () => {
    console.log('implements handlle to DELETE ')
  }, [])

  const handleButtonClick = React.useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.delete:
          onHandleRemove()
          break
        case ButtonTypes.edit:
          onHandleEdit()
          break
        default:
          break
      }
      dispatch(setCurrentFunction(''))
      dispatch(clearButtonClick(tabId))
    },
    [dispatch, onHandleRemove, onHandleEdit, tabId],
  )

  const handleSearch = React.useCallback(async () => {
    try {

      const search_data = {
        tipo: searchFilter.estado?.value ?? 0,
        descripcion: searchFilter?.descripcion ?? '',
      }

      const toAny: any = fetcGemas(search_data)
      const res = await dispatch(toAny)
      if (res !== null && res !== undefined && res['payload']?.error === false) {
        setToast(res['payload']?.message, ToastTypes.Success)
      } else {
        setToast(res['payload']?.message, ToastTypes.Danger)
      }
    } catch (error) {
      setToast(error.message.message, ToastTypes.Danger)
    }
  }, [dispatch, searchFilter, setToast])

  const onGetData = React.useCallback(() => {
    let newArray = []
    if (searchResults !== null && searchResults !== undefined && searchResults['error'] === false) {
      newArray = searchResults['auto'].map(item => {
        return item
      })
      setResultados(newArray)
    }
  }, [searchResults])

  const onSelectedChanged = React.useCallback(({ selectedRowsData }) => {
    console.log('selected', selectedRowsData[0])
    dispatch(setSeleccionarDato({
      index: selectedRowsData[0]?.codigo,
      row: selectedRowsData[0],
      selectedRow: [selectedRowsData[0]?.codigo],
    }))
  }, [dispatch])

  useEffect(() => {
    if (currentAction !== '') handleButtonClick(currentAction)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAction])

  useEffect(() => {
    onGetData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResults])

  useEffect(() => {
    dispatch(changeFilter({
      ...searchFilter,
      estado: optionsGems[0]
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={position}
        visible={searchStatus === FETCH_STATUS.LOADING || showLoader}
        showIndicator={true}
        shading={true}
        showPane={true}
        closeOnOutsideClick={false}
      />
      <div id='gemasContent'>
        <CCard>
          <CCardHeader
            onClick={() => setCollapsed(!collapsed)}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <strong> {collapsed ? '' : 'Filtro de Búsqueda'} </strong>
            <CLink className="card-header-action">
              <CIcon icon={collapsed ? cilChevronBottom : cilChevronTop} />
            </CLink>
          </CCardHeader>
          <CCollapse visible={collapsed}>
            <RowContainer className='mb-1'>
              <CustomCol xs="12" md="12">
                <Labeled label="Descripción:">
                  <TextBox
                    value={searchFilter?.descripcion ?? ''}
                    onValueChanged={(data) => onValueCahanged(data, 'descripcion')}
                    onEnterKey={handleSearch}
                  />
                </Labeled>
              </CustomCol>
              <CustomCol xs="12" md="3" style={{ marginTop: 'auto' }}>
                <Labeled label=''>
                  <RadioGroup
                    name="grupos-gemas"
                    layout="horizontal"
                    displayExpr="label"
                    items={optionsGems}
                    value={searchFilter.estado ?? null}
                    onValueChanged={(data) => onValueCahanged(data, 'estado')}
                  />
                </Labeled>
              </CustomCol>
            </RowContainer>
          </CCollapse>
          <CCardFooter>
            <ItemsCountIndicator items={resultados} />
            <RowContainer>
              <CustomCol xs="12" md="12">
                <DataGrid
                  ref={dataGrid}
                  id="codigo"
                  focusedRowKey="codigo"
                  selection={{ mode: 'single' }}
                  dataSource={resultados}
                  showColumnLines={true}
                  showRowLines={true}
                  showBorders={true}
                  columnResizingMode="widget"
                  onSelectionChanged={onSelectedChanged}
                  loadPanel={{
                    enabled: true,
                    height: 90,
                    indicatorSrc: '',
                    shading: false,
                    shadingColor: '',
                    showIndicator: true,
                    showPane: true,
                    text: 'Cargando...',
                    width: 200,
                  }}
                >
                  <Paging defaultPageSize={10} />
                  <Pager
                    visible={searchResults.length > 0 ? true : false}
                    allowedPageSizes={getAllowedPageSizes(searchResults)}
                    displayMode={'full'}
                    showPageSizeSelector={true}
                    showInfo={true}
                    showNavigationButtons={true}
                  />
                  <Column dataField="codigo" caption="Código" width="25%" />
                  <Column dataField="descripcion" caption="Descripción" width="50%" />
                  <Column dataField="estado" caption="Estado" width="25%" />
                </DataGrid>
              </CustomCol>
            </RowContainer>
          </CCardFooter>
        </CCard>
      </div>
    </>
  )
}

export default Search
