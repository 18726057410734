import React from 'react'
import Modalform from '../../../views/componentes/modalform';
import { CButton, CCard, CCardBody, CCardHeader } from '@coreui/react-pro';
import { LoadPanel } from 'devextreme-react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import RowContainer from '../../../views/componentes/rowContainer/rowContainer';
import CustomCol from '../../../views/componentes/colContainer';
import { GlobalMenu } from '../../../views/componentes/globalMenu/globalMenu';
import { AccionMenu, ToastTypes } from '../../../store/types';
import { ButtonTypes } from '../../../views/componentes/globalMenu/types';
import { changeLoader } from '../../../store/reducers';
import { addToast } from '../../../store/toasterReducer';
import Buscar from './components/buscar';
import Nuevo from './components/nuevo';
import { DataHours } from './components/types/types';
import { RegisterDetail } from '../modalDetallesRegistro/types/types';
import { RegisterServices } from './components/services/register.services';
import { consoleService } from '../../../services/console.service';
import { THoursLoad } from '../horariosCombosLookUp/types/types';

interface IModalMarcacionProps extends React.PropsWithChildren {
  onCancel: () => void
  show: boolean
  data: DataHours
}



const menuDefecto = [
  {
    icon: 'fal fa-file',
    id: 'Nuevo',
    text: 'Nuevo',
    type: ButtonTypes.new,
  },
  {
    icon: 'fal fa-trash',
    id: 'Eliminar',
    text: 'Eliminar',
    type: ButtonTypes.delete,
  },
  {
    icon: 'fal fa-search',
    id: 'Buscar',
    text: 'Buscar',
    type: ButtonTypes.find,
  },
  {
    icon: 'fal fa-save',
    id: 'Guardar',
    text: 'Guardar',
    type: ButtonTypes.save,
  },
  {
    icon: 'fal fa-file',
    id: 'Asociar',
    text: 'Asociar',
    type: ButtonTypes.assign,
  },
]

export const ModalMarcacion: React.FC<IModalMarcacionProps> = (props) => {
  const { onCancel, show, data, } = props
  const dispatch = useDispatch()
  const loader = useSelector((state: RootState) => state.global.loader);
  const [messageConfirm, setMessageConfirm] = React.useState("Está seguro que desea realizar estos cambios?");
  const [currentTab, setCurrentTab] = React.useState<any>("Buscar");
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [confirmChange, setConfirmChange] = React.useState<boolean>(false);
  const [deleteItem, setDeleteItem] = React.useState<any>(null);
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const [seleccionado, setSeleccionado] = React.useState<RegisterDetail>(null);
  const [seleccionadoHora, setSeleccionadoHora] = React.useState<THoursLoad>(null);

  const [funcion, setFuncion] = React.useState<string>("");
  const playLoader = React.useCallback(async () => {
    dispatch(changeLoader({ show: true, mensaje: 'Cargando...' }))
  }, [dispatch]);

  const stopLoader = React.useCallback(async () => {
    dispatch(changeLoader({ show: false, mensaje: '' }))
  }, [dispatch]);


  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(addToast({
      content: mensaje,
      title: 'Marcaciones',
      type: tipo
    }));
  }, [dispatch])

  const botones = menuDefecto.map(acc => {
    const boton: AccionMenu = {
      nombre: acc.id,
      icon: acc.icon,//as any,
      actionType: acc.type,
      main: false,
      modal: false,
      shortcut: null
    }
    return boton;
  });

  const [acciones,] = React.useState<Array<AccionMenu>>(botones);

  const setActionDelete = (data: RegisterDetail) => {
    setShowConfirm(true)
    setConfirmDelete(true);
    setMessageConfirm(`Está seguro que desea eliminar el item: ${data.registro}?`)
  }

  const handleDelete = async () => {

    playLoader()
    try {
      const data = await RegisterServices.deleteRegister(seleccionado?.codigo ?? 0)
      consoleService.log(data, 'delete register')
      // setFuncion(ButtonTypes.delete)
      // setDeleteItem(seleccionado)
      stopLoader()
    } catch (error) {
      stopLoader();
      setToast(JSON.stringify(error), ToastTypes.Danger);
    }
  }

  const returnAction = (boton: AccionMenu) => {
    switch (boton.actionType) {
      case ButtonTypes.find:
        if (currentTab == "Nuevo") {
          // resetSearched()
          setConfirmChange(true)
          setShowConfirm(true)
          setMessageConfirm("¿Esta seguro que desea salir de este formulario?")
          return false;
        } else {
          setCurrentTab("Buscar")
          setFuncion(ButtonTypes.find)
        }
        break;
      case ButtonTypes.new:
        setCurrentTab("Nuevo")
        setFuncion(ButtonTypes.new)
        break;
      case ButtonTypes.save:
        if (currentTab == "Nuevo") {
          setFuncion(ButtonTypes.save)
          return false;
        } else {
          setToast("No se encuentra cambios que realizar.", ToastTypes.Info)
        }
        break;
      case ButtonTypes.delete:
        if (seleccionado !== null) {
          setActionDelete(seleccionado);
          // handleDelete();
          return false;
        } else {
          setToast("No se encuentra seleccionado un proveedor, por favor seleccione un proveedor para eliminarlo.", ToastTypes.Warning)
          return false;
        }
      case ButtonTypes.assign:
        if (seleccionado !== null && seleccionadoHora !== null) {
          setActionDelete(seleccionado);
          // handleDelete();
          return false;
        }
        break
      default:

        break;
    }
  }


  const bodyModal = () => {

    return (
      <>
        <RowContainer>
          <CustomCol xs='12' md='12'>
            {currentTab === "Buscar" && (
              <Buscar
                funcion={funcion}
                resetFunction={() => setFuncion('')}
                onChanged={() => { }}
                onSelection={(data) => setSeleccionado(data)}
                deletedItem={deleteItem}
                currentTab={currentTab}
                setCurrenTab={(currentTab) => setCurrentTab(currentTab)}
                resetSelected={() => { }}
                setToast={setToast}
                playLoader={playLoader}
                stopLoader={stopLoader}
                data={data}
                onSelectionHour={(data) => setSeleccionadoHora(data)}
              />
            )}
            {currentTab === "Nuevo" && (
              <Nuevo
                funcion={funcion}
                setCurrenTab={(tab) => setCurrentTab(tab)}
                currentTab={currentTab}
                resetFunction={() => setFuncion('')}
                onChanged={() => { }}
                setToast={(sms, type) => setToast(sms, type)}
                playLoader={playLoader}
                stopLoader={stopLoader}
                data={data}
              />
            )}

          </CustomCol>
        </RowContainer>
      </>
    );
  }


  const renderFooter = () => {
    if (currentTab === "Buscar") {
      return (
        <>
          {confirmDelete === true && (
            <CButton
              color="primary"
              onClick={() => {
                setShowConfirm(false);
                setConfirmDelete(false);
                handleDelete();
              }}
            >
              {"Aceptar"}
            </CButton>
          )}
          <CButton
            color="secondary"
            onClick={() => {
              setConfirmDelete(false);
              setShowConfirm(!showConfirm);
            }}
          >
            {"Cancelar"}
          </CButton>
        </>
      );
    } else if (currentTab === "Nuevo") {
      return (
        <>
          {confirmChange === true && (
            <CButton color="primary" onClick={() => {
              setShowConfirm(false)
              setConfirmChange(false)
              setMessageConfirm("¿Esta seguro que desea salir de este formulario?")
              setCurrentTab('Buscar')
              // setFuncion(ButtonTypes.save)
            }}>
              {"Aceptar"}
            </CButton>
          )}
          <CButton color="secondary" onClick={() => {
            setConfirmDelete(false)
            setShowConfirm(!showConfirm)
          }} >{"Cancelar"}</CButton>
        </>
      )
    }
  };

  const getButtonDisabled = React.useCallback((button: AccionMenu) => {
    switch (button.actionType) {
      case ButtonTypes.find:
        return false;
      case ButtonTypes.new: {
        return currentTab !== "Buscar"
      }
      case ButtonTypes.delete: {
        return seleccionado === null
      }
      case ButtonTypes.save: {
        return currentTab === "Buscar";
      }
      case ButtonTypes.assign: {
        return seleccionado === null || seleccionadoHora === null
      }
      default:
        return true;
    }
  }, [currentTab, seleccionado, seleccionadoHora]);

  //Body Modal
  const renderBodyConfirm = () => {
    return (
      <>
        {messageConfirm}
      </>
    )
  }

  const botonesModal = () => {
    return (
      <>
        <RowContainer>
          <CustomCol xs='12' md='4'>
            <GlobalMenu
              acciones={acciones}
              onClick={returnAction}
              getButtonDisabled={getButtonDisabled}
            />
          </CustomCol>
        </RowContainer>
      </>
    );
  }

  const confirmAction = () => {


    return (
      <Modalform
        name='confirmarEliminar'
        headerTitle={'Confirmar'}
        childrenBody={renderBodyConfirm()}
        childrenFooter={renderFooter()}
        closeOnBackdrop={false}
        show={showConfirm}
        onClose={() => setShowConfirm(!showConfirm)}
        centered={true}
        size="sm"
      />
    );
  };

  const renderContent = () => {
    return (
      <CCard>
        <CCardHeader>
          {botonesModal()}
        </CCardHeader>
        <CCardBody>

          <LoadPanel
            shadingColor="rgba(0,0,0,0.4)"
            position={'center'}
            visible={loader.show}
            showIndicator={true}
            shading={true}
            showPane={true}
            message={loader.mensaje}
            closeOnOutsideClick={false}
          />

          {bodyModal()}
        </CCardBody>
      </CCard>
    )
  }



  return (
    <>
      {showConfirm === true && confirmAction()}
      <Modalform
        name='detalleRegistro'
        headerTitle={'Detalle de Registros'}
        childrenBody={renderContent()}
        closeOnBackdrop={false}
        show={show}
        onClose={() => onCancel()}
        centered={true}
        size="lg"
      />
    </>

  )
}
