import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoaderInfo } from "../../../../../../store/types";
import { PageState, Registros, Seleccionado, formularioBusqueda } from "./types";

const initialState: PageState = {
    modulo: 'contabilidad',
    currentAction: "Buscar",
    currentFunction: "",
    aplicacion: 'balanceComprobacionCC',
    acciones: [],
    registros: [],
    registrosBackup: [],
    refrescaTabla: false,
    seleccionado: {
        index: null,
        row: null,
        selectedRow: null,
    },
    formularioBusqueda: {
        ASI_DIARIONRO: -1,
        INICIA_PERIODO: null,
        ASI_FECHA_D: new Date().toDateString(),
        ASI_FECHA_H: new Date().toDateString(),
        COD_PROYECTO: -1,
        COD_LOCALCC: -1,
        COD_TIPO_CTA: -1,
        CUENTA_COD: "",
        CUENTAS_ALL: false,
        IDIOMA_ALTERNO: false,
        VALOR: "",
        MAX_NIVEL: 1,
        desde: new Date().toDateString(),
        hasta: new Date().toDateString(),
    },
    localesSeleccinados: [],
    loader: {
        show: false,
        mensaje: ''
    },
}

const balanceComprobacionCCSlice = createSlice({
    name: "balanceComprobacionCC",
    initialState: initialState,
    reducers: {
        obtenerAcciones(state, action: PayloadAction<any>) {
            state.acciones = action.payload
        },
        setData(state, action: PayloadAction<Array<Registros>>) {
            state.registros = action.payload
        },
        setDataBackup(state, action: PayloadAction<Array<Registros>>) {
            state.registrosBackup = action.payload
        },
        setCurrentAccion(state, action: PayloadAction<string>) {
            state.currentAction = action.payload
        },
        setCurrentFunction(state, action: PayloadAction<string>) {
            state.currentFunction = action.payload
        },
        setSeleccionarDato(state, action: PayloadAction<Seleccionado>) {
            const toAdd = {
                index: action.payload.index,
                row: action.payload.row,
                selectedRow: action.payload.selectedRow,
            }
            state.seleccionado = toAdd
        },
        setResetSeleccion(state) {
            const toAdd = {
                index: null,
                row: null,
                selectedRow: null,
            }
            state.seleccionado = toAdd
        },
        setFormularioBusqueda(state, action: PayloadAction<formularioBusqueda>) {
            state.formularioBusqueda = action.payload
        },
        setLocalesSeleccionados(state, action: PayloadAction<any>) {
            state.localesSeleccinados = action.payload
        },
        changeLoaderBalance(state, action: PayloadAction<LoaderInfo>) {
            state.loader = action.payload
        },
    }
})

export const { changeLoaderBalance, setLocalesSeleccionados, setCurrentFunction, obtenerAcciones, setData, setDataBackup, setCurrentAccion, setSeleccionarDato, setResetSeleccion, setFormularioBusqueda } = balanceComprobacionCCSlice.actions;
export const balanceComprobacionCCReducer = balanceComprobacionCCSlice.reducer;