import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ButtonTypes } from '../../../../../views/componentes/globalMenu/types'
import { StatesEdition, TabTypes } from '../../../../ventas/types/enums'
import { ClientesListado } from '../types/types'
import { ModuleButtons } from '../components/documento'
import { ModuleButtonsImport } from '../components/importar'
import { TabsState, TabState } from '../../../../../store/genericTabTypes'
import { CustomButtons } from '../../../../../store/types'
import {
  tabExists,
  closeTab,
  tabIsVisible,
} from '../../../../../hooks/useNavigationTabControls'

const initialState: TabsState<ClientesListado> = {
  current: 'BUSQUEDA',
  tabs: {
    BUSQUEDA: {
      id: 0,
      canClose: false,
      editStatus: StatesEdition.doesNotApply,
      globalButtonClick: ButtonTypes.none,
      tittle: 'Búsqueda',
      type: TabTypes.busqueda,
      imprimir: false,
      tabKey: 'BUSQUEDA',
      buttons: {
        Buscar: true,
        Nuevo: true,
        Exportar: true,
        Importar: true,
      },
    },
  },
}

const tabsClientesSlice = createSlice({
  name: 'clientesTabs',
  initialState: initialState,
  reducers: {
    clientesCloseTab: closeTab,
    openTab(
      state,
      action: PayloadAction<{
        key: string
        cliente?: ClientesListado
        templateMode?: boolean
      }>,
    ) {
      if (action.payload.cliente === undefined || action.payload.templateMode) {
        const tab: TabState<ClientesListado> = {
          id: -1,
          canClose: true,
          editStatus: StatesEdition.new,
          globalButtonClick: ButtonTypes.none,
          tittle: 'Nueva',
          type: TabTypes.documento,
          imprimir: false,
          tabKey: action.payload.key,
          info: {
            codigo: 0,
          },
          buttons: ModuleButtons,
          templateMode: action.payload.templateMode,
        }
        state.tabs[action.payload.key] = tab
        state.current = action.payload.key
      } else {
        const tabKey = tabExists(state.tabs, action.payload.cliente.codigo)
        if (tabKey) {
          state.current = tabKey
        } else {
          console.log('cliente.codigo:', action.payload.cliente)
          const tab: TabState<ClientesListado> = {
            tabKey: action.payload.key,
            id: action.payload.cliente.codigo,
            tittle: `${action.payload.cliente.identificacion}-${action.payload.cliente.codigo}`,
            canClose: true,
            editStatus: StatesEdition.edit,
            type: TabTypes.documento,
            info: {
              codigo: action.payload.cliente.codigo,
              info: action.payload.cliente,
            },
            globalButtonClick: ButtonTypes.none,
            imprimir: false,
            buttons: ModuleButtons,
          }
          state.tabs[action.payload.key] = tab
          state.current = action.payload.key
        }
      }
    },
    openTabImport(
      state,
      action: PayloadAction<{
        key: string
        type: TabTypes
      }>,
    ) {
      console.log('action.payload.type', action.payload.type)
      if (action.payload.type === TabTypes.import) {
        const tabKey = tabIsVisible(state.tabs, action.payload.type)
        if (tabKey) {
          state.current = tabKey
        } else {
          const tab: TabState<ClientesListado> = {
            id: -1,
            tittle: 'Importar',
            type: TabTypes.import,
            info: {
              codigo: 0,
            },
            canClose: true,
            editStatus: StatesEdition.new,
            globalButtonClick: ButtonTypes.none,
            imprimir: false,
            tabKey: action.payload.key,
            buttons: ModuleButtonsImport,
          }
          state.tabs[action.payload.key] = tab
          state.current = action.payload.key
        }
      }
    },
    changeCurrentTab(state, action: PayloadAction<string>) {
      if (Object.prototype.hasOwnProperty.call(state.tabs, action.payload)) {
        state.current = action.payload
      }
    },
    setCurrentExecutingAction(
      state,
      action: PayloadAction<{
        tabKey: string
        buttonType: ButtonTypes | undefined
      }>,
    ) {
      const { buttonType, tabKey } = action.payload
      state.tabs[tabKey].currentExecutingAction = buttonType
    },
    setButtons(
      state,
      action: PayloadAction<{ tabKey: string; buttons: CustomButtons }>,
    ) {
      const { buttons, tabKey } = action.payload
      state.tabs[tabKey].buttons = buttons
      state.tabs[tabKey].currentExecutingAction = undefined
    },
    changeEditStatus(
      state,
      action: PayloadAction<{
        tabKey: string
        estado: StatesEdition
        info?: ClientesListado
        buttons: CustomButtons
        tittle?: string
      }>,
    ) {
      const { estado, tabKey, info, buttons, tittle } = action.payload
      state.tabs[tabKey].editStatus = estado
      state.tabs[tabKey].currentExecutingAction = undefined
      state.tabs[tabKey].buttons = buttons
      if (tittle) {
        state.tabs[tabKey].tittle = tittle
      }
      if (info) {
        state.tabs[tabKey].info = {
          codigo: action.payload.info.codigo,
          info: info ?? null,
        }
        state.tabs[tabKey].id = info.codigo
      }
    },
    buttonClick(
      state,
      action: PayloadAction<{ tabKey: string; button: ButtonTypes }>,
    ) {
      state.tabs[action.payload.tabKey].globalButtonClick =
        action.payload.button
    },
    clearButtonClick(state, action: PayloadAction<string>) {
      if (Object.prototype.hasOwnProperty.call(state.tabs, action.payload)) {
        state.tabs[action.payload].globalButtonClick = ButtonTypes.none
      }
    },
  },
})

export const {
  clientesCloseTab,
  openTab,
  changeCurrentTab,
  changeEditStatus,
  setCurrentExecutingAction,
  setButtons,
  buttonClick,
  openTabImport,
  clearButtonClick,
} = tabsClientesSlice.actions
export const tabsReducer = tabsClientesSlice.reducer
