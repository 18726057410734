import React from 'react';
import SelectBox from 'devextreme-react/select-box';
import { estudiosService } from './service/estudios.service';
import { NivelesEstudioOption } from './store/type';

interface NivelesEstudioLookUpProps extends React.PropsWithChildren {
  selected: NivelesEstudioOption | null,
  onChanged: (newValue: NivelesEstudioOption | null) => void,
  disabled?: boolean,
  provider: Array<NivelesEstudioOption> | []
}

const NivelesEstudioLookUp: React.FC<NivelesEstudioLookUpProps> = (props) => {
  const { provider, selected, onChanged, disabled } = props;
  const selectRef = React.useRef<any>();
  const [options, setOptions] = React.useState<Array<NivelesEstudioOption> | []>([]);

  const loadDatos = React.useCallback(async () => {
    if (provider !== undefined && provider.length > 0) {
      setOptions(provider)
      const selectedIndex = provider.findIndex((select: NivelesEstudioOption) => {
        return select.codigo === selected?.codigo
      })
      onChanged(provider[selectedIndex] ?? provider[0])
      if (selectedIndex > -1) {
        onChanged(provider[selectedIndex] ?? provider[0])
      }
    } else {
      const data = await estudiosService.getEstados("Elija una opción");
      if (data.error === false) {
        let opt = [];
        if (data.auto.length > 0) {
          opt = data.auto.map(x => {
            const item: NivelesEstudioOption = {
              codigo: parseInt(x?.codigo) ?? 0,
              descripcion: x?.descripcion ?? '',
            };
            return item;
          });
        }
        setOptions(opt)
        const selectedIndex = opt.findIndex((option) => {
          return option.codigo === selected?.codigo
        })
        if (selectedIndex > -1) {
          onChanged(opt[selectedIndex] ?? opt[0])
        }
      }
    }
  }, [onChanged, selected, provider]);

  const getItemDisplayExpr = React.useCallback((item: NivelesEstudioOption) => {
    return item ? `${item.descripcion}` : '';
  }, []);

  const onLookUpValueChanged = React.useCallback(async (evt: any) => {
    if (evt.event !== undefined) {
      await onChanged(evt.value);
    }
  }, [onChanged]);

  const onSelectBoxFocusIn = React.useCallback((e) => {
    e.element.querySelector("input.dx-texteditor-input").select();
  }, []);

  const refreshSelected = React.useCallback(async () => {
    if (options && options.length > 0) {
      const selectedIndex = await options.findIndex((option: NivelesEstudioOption) => {
        return option.codigo === selected?.codigo
      })
      onChanged(options[selectedIndex] ?? options[0])
    }
  }, [onChanged, options, selected])

  React.useEffect(() => {
    loadDatos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (selected !== null) {
      refreshSelected()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  return (
    <SelectBox
      id='NivelesEstudioSelect'
      ref={selectRef}
      items={options ?? []}
      searchEnabled
      displayExpr={getItemDisplayExpr}
      searchExpr={['descripcion']}
      searchTimeout={500}
      onEnterKey={(e) => { console.log(e) }}
      value={selected}
      onValueChanged={onLookUpValueChanged}
      disabled={disabled}
      onFocusIn={onSelectBoxFocusIn}
    >
      {props.children}
    </SelectBox>
  )

}
export default React.memo(NivelesEstudioLookUp);
