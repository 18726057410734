import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LoaderInfo, Seleccionado } from '../../../../../../store/types'
import { statusForms } from '../../store/types'
import {
  ProtestosProveedoresState,
  ProtestosProveedores,
  DetalleProtestosProveedores,
  DatosFormularioProtestosProveedores,
  formularioBusqueda
} from './types'

const initialState: ProtestosProveedoresState = {
  modulo: 'protestosProveedores',
  codModulo: 0,
  registros: [],
  detalleProtestoProveedores: [],
  dpTipoTransaccion: [],
  currentAction: 'Buscar',
  currentFunction: "",
  loader: {
    show: false,
    mensaje: ''
  },
  currentFormulario: null,
  seleccionado: {
    index: null,
    row: null,
    selectedRow: null,
  },
  formularioEdicion: {
    estadoFormulario: statusForms.pending,
    diarioNumero: 0,
    fechaIngreso: "",
    numeroDocumento: 0,
    numeroAsiento: 0,
    numeroTipo: 0,
    tipoDocumento: "",
    totalDebe: 0,
    totalHaber: 0,
    local: null,
    proyecto: null,
    codigoCompra: 0,
    codigoRetencion: 0,
    codigoIngreso: 0,
    descripcion: "",
    lote: 0,
    proveedorNombre: "",
    proveedorCodigo: 0,
    detalleAsiento: []
  },
  formularioBusqueda: {
    fechaDesde: "",
    fechaHasta: "",
    codigo: 0,
    numero: "",
    recibido: "",
    tipo: 0,
    concepto: "",
    planCuentasCodigo: 0,
    fecha: ""
  },
}

const protestosProveedoresSlice = createSlice({
  name: 'protestosProveedores',
  initialState: initialState,
  reducers: {
    changeLoaderProtestosProveedores(state, action: PayloadAction<LoaderInfo>) {
      state.loader = action.payload
    },
    setCodModulo(state, action: PayloadAction<number>) {
      state.codModulo = action.payload
    },
    setData(state, action: PayloadAction<Array<ProtestosProveedores>>) {
      state.registros = action.payload
    },
    setDetalleProtestoProveedores(state, action: PayloadAction<Array<DetalleProtestosProveedores>>) {
      state.detalleProtestoProveedores = action.payload
    },
    setDpTipoTransaccion(state, action: PayloadAction<Array<any>>) {
      state.dpTipoTransaccion = action.payload
    },
    setCurrentAccionProtestoProv(state, action: PayloadAction<any>) {
      state.currentAction = action.payload
    },
    setCurrentFunctionProtestoProv(state, action: PayloadAction<string>) {
      state.currentFunction = action.payload
    },
    setCurrentFormulario(state, action: PayloadAction<any>) {
      state.currentFormulario = action.payload
    },
    setSeleccionarDato(state, action: PayloadAction<Seleccionado>) {
      state.seleccionado = action.payload;
    },
    setResetSeleccionProtestosProv(state) {
      const toAdd = {
        index: null,
        row: null,
        selectedRow: null,
      }
      state.seleccionado = toAdd
    },
    setFormularioEdicionProtestoProveedores(state, action: PayloadAction<DatosFormularioProtestosProveedores>) {
      state.formularioEdicion = action.payload
    },
    setFormularioBusqueda(state, action: PayloadAction<formularioBusqueda>) {
      state.formularioBusqueda = action.payload
    },
  }
})
export const {
  setData, setDetalleProtestoProveedores, setDpTipoTransaccion,
  setCurrentAccionProtestoProv, setCurrentFunctionProtestoProv,
  setCodModulo, changeLoaderProtestosProveedores, setCurrentFormulario,
  setSeleccionarDato, setResetSeleccionProtestosProv, setFormularioEdicionProtestoProveedores,
  setFormularioBusqueda
} = protestosProveedoresSlice.actions
export const ProtestosProveedoresReducer = protestosProveedoresSlice.reducer
