import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataGrid, { Column, Paging, Pager, ColumnChooser, HeaderFilter, TotalItem, Summary } from 'devextreme-react/data-grid';
import { DatosVenta } from '../../../../types/types';


interface ITablaVentasProps extends React.PropsWithChildren {
  data: Array<DatosVenta> | []
}
const pageSizes = [10, 25, 50, 100, 200];

const TablaVentas: React.FunctionComponent<ITablaVentasProps> = (props) => {
  const { data } = props;

  const dispatch = useDispatch();

  const dataGrid = React.useRef<any>();


  const clearFilter = React.useCallback(() => {
    dataGrid.current.instance.clearSorting();
    dataGrid.current.instance.clearFilter();
  }, []);


  const calculateCustomSummary = React.useCallback((options) => {
    if (options.summaryProcess === 'start') {
      options.totalValue = 0;
    } else if (options.summaryProcess === 'calculate') {
      if (options.value.tipoDocumento === '04') {
        options.totalValue = options.totalValue - options.value[options.name]
      } else {
        options.totalValue = options.totalValue + options.value[options.name]
      }
    }
  }, [])

  const customizeSummaryText = React.useCallback((data) => {
    return data.value.toFixed(2);
  }, [])

  return (
    <DataGrid
      ref={dataGrid}
      selection={{ mode: 'single' }}
      allowColumnResizing={true}
      columnResizingMode={'nextColumn'}
      focusedRowEnabled={true}
      keyExpr='nro'
      focusedRowKey='nro'
      dataSource={data}
      showBorders={true}

    >
      <ColumnChooser enabled={true} mode="select" />
      <HeaderFilter visible={true} />

      <Column dataField='nro' caption='Nro' width='80px' allowEditing={false} visible={true} allowSearch={false} allowFiltering={false} />
      <Column dataField='identificacionCliente' caption='Id. Cliente' width='110px' allowEditing={false} visible={true} allowSearch={false} allowFiltering={true} ><HeaderFilter allowSearch={true} />
      </Column>
      <Column dataField='tipoComprobante' type={'string'} width='100px' caption='Tipo Comprobante' alignment='right' allowFiltering={false} />
      <Column dataField='numero' caption='No. Comp Emitidos' minWidth='200px' width='auto' />
      <Column dataField='subtotal0' caption='Base IVA 0%' minWidth='220px' width='auto' />
      <Column dataField='subtotal12' caption='Base IVA 12%' minWidth='220px' width='auto' />
      <Column dataField='subtotalNoIva' caption='Base No IVA' alignment='right' width='85px' allowFiltering={false} />
      <Column dataField='iva' caption='IVA' alignment='right' width='85px' allowFiltering={false} />
      <Column dataField='retencionIva' caption='Ret Iva' alignment='right' width='85px' allowFiltering={false} />
      <Column dataField='retencionRenta' caption='Ret Renta' alignment='right' width='85px' allowFiltering={false} />

      <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} showInfo />
      <Paging defaultPageSize={10} />

      <Summary calculateCustomSummary={calculateCustomSummary} >
        <TotalItem customizeText={customizeSummaryText} name='subtotal0' showInColumn='subtotal0' summaryType='custom' displayFormat="{0}" valueFormat='currency' alignment="right" />
        <TotalItem customizeText={customizeSummaryText} name='subtotal12' showInColumn='subtotal12' summaryType='custom' displayFormat="{0}" valueFormat='currency' alignment="right" />
        <TotalItem customizeText={customizeSummaryText} name='subtotalNoIva' showInColumn='subtotalNoIva' summaryType='custom' displayFormat="{0}" valueFormat='currency' alignment="right" />
        <TotalItem customizeText={customizeSummaryText} name='iva' showInColumn='iva' summaryType='custom' displayFormat="{0}" valueFormat='currency' alignment="right" />
      </Summary>

    </DataGrid>
  );
}
export default TablaVentas;
