import React, { useCallback, useEffect, useState, useRef } from 'react'
import { createPortal } from 'react-dom'
import {
  AccionMenu,
  CustomButtons,
  ToastTypes,
} from '../../../../../store/types'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../../../store/store'
import {
  ItemTransferencia,
  Progreso,
  SearchFilters,
  TransferEditData,
} from './types/types'
import {
  setCollpased,
  setCurrentFunction,
  setIsChecked,
} from './store/generalReducer'
import {
  changeCurrentTab,
  clearButtonClick,
  closeTabTransferencias,
  openTab,
  openTabArchived,
  openTabShipments,
  setButtons,
  setCurrentExecutingAction,
} from './store/tabsReducer'
import { ButtonTypes } from '../../../../../views/componentes/globalMenu/types'
import { v4 as uuidv4 } from 'uuid'
import {
  deleteEditData,
  initDataShipment,
  initDatosEdicion,
} from './store/editDataReducer'
import {
  setSearchResultDetails,
  setSearchResult,
  changeFilter,
  changeOrigin,
  changeDestination,
  setSearchLoader,
} from './store/searchReducer'
import Dialog from '../../../../../views/componentes/librerias/bootstrap-dialog'
import {
  useButtonDisabled,
  useMenuOptions,
} from '../../../../../hooks/useMenuButtonControls'
import TransferenciasMain from './components/shared/main'
import { useSetToast } from '../../../../../hooks/useGlobalHooks'
import { TransfersServices } from './services/transferencias.services'
import { DateUtils } from '../../../../../helpers/dateUtils'
import { StatesEdition, TabTypes } from '../../../../../store/enums'
import { LocalOption } from '../../../../componentes/localesLookUp/store/type'
import ModalAsiento from '../../../../contabilidad/pages/mayorGeneral/componentes/modalAsiento'
import { EModulosAcatha } from '../../../../../store/enumsAplication'
import { ReportWithLocalDataModal } from '../../../../../views/componentes/xtrareports/ReportWithLocalDataModal'
import { CButton, CCol, CRow } from '@coreui/react-pro'
import { useLoaderTransfers } from './customHooks'
import Modalform from '../../../../../views/componentes/modalform'

const pathVerificador = '/transferencias'

interface CatalogosGruposProps extends React.PropsWithChildren {}

const Transferencias: React.FunctionComponent<CatalogosGruposProps> = () => {
  const dispatch = useDispatch()
  const setToastMessage = useSetToast()

  const menuState = useSelector((state: RootState) => state.global.menu)
  const tabs = useSelector((state: RootState) => {
    return state.inventarios.movimientos.transferencias.tabs
  })
  const isChecked = useSelector(
    (state: RootState) =>
      state.inventarios.movimientos.transferencias.general.isChecked,
  )
  const searchFilter = useSelector((state: RootState) => {
    return state.inventarios.movimientos.transferencias.search.filter
  })
  const currentTab = useSelector((state: RootState) => {
    return state.inventarios.movimientos.transferencias.search.currentTab
  })
  const selectedTransfer = useSelector((state: RootState) => {
    return state.inventarios.movimientos.transferencias.search.selectedTransfer
  })
  const accountingPeriod = useSelector((state: RootState) => {
    return state.shared.providers.periodoContable
  })

  const [confirmDelete, setConfirmDelete] = useState<boolean>(false)
  const [confirmEdit, setConfirmEdit] = useState<boolean>(false)
  const [confirmNew, setConfirmNew] = useState<boolean>(false)
  const [confirmDuplicate, setConfirmDuplicate] = useState<boolean>(false)
  const [confirmArchived, setConfirmArchived] = useState<boolean>(false)
  const [newTransfer, setNewtransfer] = useState<boolean>(false)
  const [deleteTransfer, setDeleteTransfer] = useState<boolean>(false)
  const [duplicateTransfer, setDuplicatetransfer] = useState<boolean>(false)
  const [archiveTransfer, setArchiveTransfer] = useState<boolean>(false)
  const [clickArchiveTransfer, setClickArchiveTransfer] =
    useState<boolean>(false)
  const [shipmentTransfer, setShipmentTransfer] = useState<boolean>(false)
  const [optionsMenu, setOptionsMenu] = useState<Array<AccionMenu>>([])
  const [pdfReport, setPdfReport] = useState<'Viewer' | 'Designer'>(null)
  const [typeReport, setTypeReport] = useState<'Admin' | 'Vale'>(null)
  const [confirmPrint, setConfirmPrint] = useState<boolean>(false)
  const [dataToPrint, setDataToPrint] = useState<{}>({})
  const [showEntry, setShowEntry] = useState<boolean>(false)
  const dialogRef = useRef<any>(null)

  const { playLoaderSearch, stopLoaderSearch } = useLoaderTransfers()
  const getButtonDisabled = useButtonDisabled(tabs)
  const { getMenuOptions, options } = useMenuOptions()

  const onTabClosing = useCallback(
    async (index) => {
      const closedTab = tabs.tabs[index]
      dispatch(closeTabTransferencias(closedTab.tabKey ?? index))
      dispatch(deleteEditData(closedTab.tabKey ?? index))
    },
    [dispatch, tabs.tabs],
  )

  const onTabChanged = useCallback(
    (tabKey: string) => {
      dispatch(changeCurrentTab(tabKey))
    },
    [dispatch],
  )

  const onParseTransfertoPrint = useCallback(
    (items: any[]) => {
      const itemsToPrint = {
        status: selectedTransfer?.progresoDescripcion ?? 'N/A',
        number: selectedTransfer?.numero ?? 'N/A',
        code: selectedTransfer?.codigo ?? 0,
        description: selectedTransfer?.descripcion ?? 'N/A',
        date: selectedTransfer?.fecha ?? 'N/A',
        origin: selectedTransfer?.localOrigenNombre ?? 'N/A',
        destination: selectedTransfer?.localDestinoNombre ?? 'N/A',
        details: [],
      }
      items.forEach((item) => {
        itemsToPrint.details.push({
          userCode: item.itemCodigoUsuario ?? '',
          barcode: item.itemBarras ?? '',
          description: item.itemDescripcion ?? '',
          quantity: item.cantidad ?? 0,
          cost: item.costo ?? 0,
          total: item.cantidad * item.costo ?? 0,
        })
      })
      return itemsToPrint
    },
    [
      selectedTransfer?.progresoDescripcion,
      selectedTransfer?.codigo,
      selectedTransfer?.descripcion,
      selectedTransfer?.fecha,
      selectedTransfer?.localDestinoNombre,
      selectedTransfer?.localOrigenNombre,
      selectedTransfer?.numero,
    ],
  )

  const onHandlePrint = useCallback(
    async (format: 'Admin' | 'Vale') => {
      try {
        playLoaderSearch('Imprimiendo transferencia. . .', ButtonTypes.print)
        const deatilsTarnsfer = await TransfersServices.getTransferDetailAll(
          selectedTransfer?.codigo,
        )
        if (!deatilsTarnsfer.error && deatilsTarnsfer.auto) {
          setDataToPrint(onParseTransfertoPrint(deatilsTarnsfer.auto))
          setTypeReport(format)
          setPdfReport('Designer')
        } else
          setToastMessage(
            'Imprimir Transferencia',
            deatilsTarnsfer.message,
            ToastTypes.Warning,
          )
        stopLoaderSearch()
      } catch (error) {
        stopLoaderSearch()
        setToastMessage('Imprimir Transferencia', error, ToastTypes.Danger)
      }
    },
    [
      onParseTransfertoPrint,
      playLoaderSearch,
      selectedTransfer?.codigo,
      setToastMessage,
      stopLoaderSearch,
    ],
  )

  const resetButtons = useCallback(() => {
    dispatch(
      setButtons({
        tabKey: 'BUSQUEDA',
        buttons: {
          Nuevo: true,
          Buscar: true,
          Limpiar: true,
          Duplicar: false,
          Editar: false,
          Eliminar: false,
          Envios: false,
          Recepcion: false,
          Archivado: false,
          Ver_asiento: false,
          Imprimir: false,
          Deshacer: true,
        },
      }),
    )
  }, [dispatch])

  const onHandleArchived = useCallback(async () => {
    const id = crypto.randomUUID()
    dispatch(
      initDatosEdicion({
        key: id,
        data: {
          ...defaultDataTranfers,
          codigo: selectedTransfer?.codigo,
          fecha: DateUtils.apiDateToPickersDate(selectedTransfer?.fecha),
          descripcion: selectedTransfer?.descripcion,
          origenLocal: {
            ...newLocal,
            codigo: selectedTransfer?.localOrigen ?? -1,
          },
          destinoLocal: {
            ...newLocal,
            codigo: selectedTransfer?.localDestino ?? -1,
          },
          horaInicio: '00:00',
          horaFin: '00:00',
        },
      }),
    )
    dispatch(
      openTabArchived({
        key: id,
        codeTransfer: selectedTransfer?.codigo,
      }),
    )
  }, [
    dispatch,
    selectedTransfer?.codigo,
    selectedTransfer?.descripcion,
    selectedTransfer?.fecha,
    selectedTransfer?.localDestino,
    selectedTransfer?.localOrigen,
  ])

  const onHandleSearch = useCallback(async () => {
    try {
      resetButtons()
      dispatch(
        setSearchLoader({
          show: true,
          mensaje: 'Buscando transferencias . . .',
        }),
      )
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'BUSQUEDA',
          buttonType: ButtonTypes.find,
        }),
      )
      const filters: SearchFilters = {
        codigo: searchFilter.codigo ?? '',
        numero: searchFilter.numero ? searchFilter.numero.toString() : '',
        barras: searchFilter.item ?? '',
        descripcion: searchFilter.observaciones ?? '',
        fechaDesde:
          DateUtils.pickersDateToApiDate(searchFilter.fechaDesde) ?? '',
        fechaHasta:
          DateUtils.pickersDateToApiDate(searchFilter.fechaHasta) ?? '',
        origen: searchFilter.origen?.codigo ?? -1,
        destino: searchFilter.destino?.codigo ?? -1,
        progreso: searchFilter.progreso?.codigo ?? -1,
        estado: searchFilter.activos === true ? 1 : 2,
        archivado: searchFilter.archivados === true ? 1 : 0,
        movimientoCabeceraCodigo: searchFilter.movimiento ?? '',
      }
      let searchTransfers = null
      if (currentTab === 'Cabecera') {
        dispatch(setSearchResult([]))
        searchTransfers = await TransfersServices.getTransfersAll(filters)
      } else {
        dispatch(setSearchResultDetails([]))
        searchTransfers = await TransfersServices.getTransfersDetails(filters)
      }
      dispatch(setSearchLoader({ show: false, mensaje: '' }))
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'BUSQUEDA',
          buttonType: undefined,
        }),
      )
      if (!searchTransfers.error && searchTransfers.auto) {
        if (searchTransfers.auto.length > 5) dispatch(setCollpased(false))
        if (currentTab === 'Cabecera')
          dispatch(setSearchResult(searchTransfers.auto))
        else {
          searchTransfers.auto.forEach((tansferDetail) => {
            tansferDetail.uuid = crypto.randomUUID()
          })
          dispatch(setSearchResultDetails(searchTransfers.auto))
        }
      } else
        setToastMessage(
          'Buscar Transferencias',
          searchTransfers.message,
          searchTransfers.error ? ToastTypes.Warning : ToastTypes.Success,
        )
      dispatch(setIsChecked(true))
    } catch (error) {
      dispatch(setSearchLoader({ show: false, mensaje: '' }))
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'BUSQUEDA',
          buttonType: undefined,
        }),
      )
      setToastMessage('Buscar Transferencias', error, ToastTypes.Danger)
    }
  }, [resetButtons, dispatch, searchFilter, currentTab, setToastMessage])

  const onHandleEdit = useCallback(
    (tabStatus: StatesEdition) => {
      if (
        selectedTransfer.progreso !== Progreso.INGRESADO &&
        tabStatus === StatesEdition.save
      )
        setToastMessage(
          'Editar Transferencia',
          `La Transferencia elejida está en progreso ${selectedTransfer.progresoDescripcion} por lo cual no podrá editarla.`,
          ToastTypes.Warning,
        )
      else {
        const id = crypto.randomUUID()
        dispatch(
          initDatosEdicion({
            key: id,
            data: {
              ...defaultDataTranfers,
              origenLocal: {
                ...newLocal,
                codigo: selectedTransfer?.localOrigen ?? -1,
              },
              destinoLocal: {
                ...newLocal,
                codigo: selectedTransfer?.localDestino ?? -1,
              },
            },
          }),
        )
        dispatch(
          openTab({
            key: id,
            editStatus: tabStatus,
            transferencia: selectedTransfer,
          }),
        )
      }
    },
    [dispatch, selectedTransfer, setToastMessage],
  )

  useEffect(() => {
    if (duplicateTransfer === true) {
      setDuplicatetransfer(false)
      onHandleEdit(StatesEdition.edit)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duplicateTransfer])

  const onConfirmDuplicate = useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea duplicar la transferencia TRF - ${selectedTransfer?.codigo}?`,
      actions: [
        Dialog.Action(
          <span>
            <u>A</u>ceptar
          </span>,
          (dialog) => {
            dialog.hide()
            setDuplicatetransfer(true)
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setDuplicatetransfer(false)
    setConfirmDuplicate(false)
    return
  }, [selectedTransfer?.codigo])

  useEffect(() => {
    if (confirmDuplicate) onConfirmDuplicate()
  }, [confirmDuplicate, onConfirmDuplicate])

  const openNewTab = useCallback(async () => {
    const id = uuidv4()
    dispatch(
      initDatosEdicion({
        key: id,
        data: defaultDataTranfers,
      }),
    )
    dispatch(openTab({ key: id }))
  }, [dispatch])

  useEffect(() => {
    if (newTransfer === true) {
      setNewtransfer(false)
      openNewTab()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newTransfer])

  const onConfirmNew = useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea crear un nuevo registro?`,
      actions: [
        Dialog.Action(
          <span>
            <u>P</u>estaña Actual
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-success',
          'p',
        ),
        Dialog.Action(
          <span>
            <u>N</u>ueva Pestaña
          </span>,
          (dialog) => {
            dialog.hide()
            setNewtransfer(true)
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setNewtransfer(false)
    setConfirmNew(false)
    return
  }, [])

  useEffect(() => {
    if (confirmNew) {
      onConfirmNew()
    }
  }, [confirmNew, onConfirmNew])

  const onConfirmEdit = useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea Editar la transferencia TRF - ${selectedTransfer.codigo}?`,
      actions: [
        Dialog.Action(
          <span>
            <u>A</u>ceptar
          </span>,
          (dialog) => {
            dialog.hide()
            onHandleEdit(StatesEdition.save)
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setConfirmEdit(false)
    return
  }, [onHandleEdit, selectedTransfer?.codigo])

  useEffect(() => {
    if (confirmEdit) {
      onConfirmEdit()
    }
  }, [confirmEdit, onConfirmEdit])

  const onHandleDelete = useCallback(async () => {
    if (selectedTransfer.progreso !== Progreso.INGRESADO)
      setToastMessage(
        'Eliminar Transferencia',
        'Solo se puede eliminar Transferencias con su progreso en INGRESADO',
        ToastTypes.Warning,
      )
    else {
      try {
        playLoaderSearch('Eliminando transferencia. . .', ButtonTypes.find)
        const deleteTransfer = await TransfersServices.deleteTransfer(
          selectedTransfer.codigo,
          selectedTransfer.progreso,
        )
        stopLoaderSearch()
        if (!deleteTransfer.error && deleteTransfer.auto) onHandleSearch()
        setToastMessage(
          'Eliminar Transferencia',
          deleteTransfer.message,
          deleteTransfer.error ? ToastTypes.Warning : ToastTypes.Success,
        )
      } catch (error) {
        stopLoaderSearch()
        setToastMessage('Eliminar Transferencia', error, ToastTypes.Danger)
      }
    }
  }, [
    onHandleSearch,
    playLoaderSearch,
    selectedTransfer?.codigo,
    selectedTransfer?.progreso,
    setToastMessage,
    stopLoaderSearch,
  ])

  useEffect(() => {
    if (deleteTransfer === true) {
      setDeleteTransfer(false)
      onHandleDelete()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteTransfer])

  const onConfirmDelete = useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea eliminar la transferencia ${selectedTransfer?.codigo}?`,
      actions: [
        Dialog.Action(
          <span>
            <u>A</u>ceptar
          </span>,
          (dialog) => {
            dialog.hide()
            setDeleteTransfer(true)
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setDeleteTransfer(false)
    setConfirmDelete(false)
    return
  }, [selectedTransfer?.codigo])

  useEffect(() => {
    if (confirmDelete) {
      onConfirmDelete()
    }
  }, [confirmDelete, onConfirmDelete])

  useEffect(() => {
    if (archiveTransfer === true) {
      setArchiveTransfer(false)
      onHandleArchived()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [archiveTransfer])

  const onConfirmArchived = useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `Esta seguro que desea procesar como archivada la transferencia TRF -  ${selectedTransfer?.codigo}?`,
      actions: [
        Dialog.Action(
          <span>
            <u>A</u>ceptar
          </span>,
          (dialog) => {
            dialog.hide()
            setArchiveTransfer(true)
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setArchiveTransfer(false)
    setConfirmArchived(false)
    return
  }, [selectedTransfer?.codigo, setArchiveTransfer])

  useEffect(() => {
    if (confirmArchived) {
      onConfirmArchived()
    }
  }, [confirmArchived, onConfirmArchived])

  const onArchived = useCallback(() => {
    if (selectedTransfer.progreso === Progreso.RECIBIDO)
      setConfirmArchived(true)
    else
      setToastMessage(
        'Archivar Transferencia',
        'La transferencia no puede ser archivada, su estado debe ser <strong>RECIBIDA</strong>',
        ToastTypes.Warning,
      )
  }, [selectedTransfer?.progreso, setToastMessage])

  const onHandleUndo = useCallback(() => {
    dispatch(
      changeFilter({
        fechaDesde:
          DateUtils.apiDateToPickersDate(accountingPeriod[0]?.finicio) ?? '',
        fechaHasta:
          DateUtils.apiDateToPickersDate(accountingPeriod[0]?.fcierre) ?? '',
        codigo: '',
        numero: null,
        origen: null,
        destino: null,
        activos: true,
        archivados: false,
        item: '',
        movimiento: '',
        progreso: null,
        observaciones: '',
      }),
    )
    dispatch(changeOrigin(null))
    dispatch(changeDestination(null))
  }, [accountingPeriod, dispatch])

  const onHandleBroom = useCallback(() => {
    resetButtons()
    dispatch(setSearchResult([]))
    dispatch(setSearchResultDetails([]))
  }, [dispatch, resetButtons])

  const onHandleShipments = useCallback(
    (shipments: boolean = false) => {
      if (selectedTransfer?.progreso !== Progreso.INGRESADO && shipments)
        setToastMessage(
          'Enviar Transferencia',
          'No puede editar esta transferencia, su estado debe ser <strong>INGRESADO</strong>',
          ToastTypes.Warning,
        )
      else {
        const id = crypto.randomUUID()
        dispatch(
          initDataShipment({
            key: id,
            data: {
              codigo: selectedTransfer?.codigo ?? 0,
              fecha:
                DateUtils.apiDateToPickersDate(selectedTransfer?.fecha) ?? '',
              descripcion: selectedTransfer?.descripcion ?? '',
              numero: parseInt(selectedTransfer?.numero) ?? 0,
              origenLocal: { codigo: selectedTransfer?.localOrigen ?? -1 },
              destinoLocal: { codigo: selectedTransfer?.localDestino ?? -1 },
              responsable: {
                codigo: selectedTransfer?.responsable ?? 1,
                nombres: selectedTransfer?.responsableNombre ?? '',
                apellidos: '',
              },
              progreso: shipments ? Progreso.ENVIADO : Progreso.RECIBIDO,
              detalles: [],
              series: [],
              loaded: false,
              selectedAll: false,
              loader: { show: false, mensaje: '' },
            },
          }),
        )
        dispatch(
          openTabShipments({
            key: id,
            type: shipments ? TabTypes.envios : TabTypes.recepcion,
            codeTransfer: selectedTransfer?.codigo,
          }),
        )
      }
    },
    [
      selectedTransfer?.progreso,
      selectedTransfer?.codigo,
      selectedTransfer?.fecha,
      selectedTransfer?.descripcion,
      selectedTransfer?.numero,
      selectedTransfer?.localOrigen,
      selectedTransfer?.localDestino,
      selectedTransfer?.responsable,
      selectedTransfer?.responsableNombre,
      setToastMessage,
      dispatch,
    ],
  )

  useEffect(() => {
    if (clickArchiveTransfer) {
      onArchived()
      setClickArchiveTransfer(false)
    }
  }, [clickArchiveTransfer, onArchived])

  useEffect(() => {
    if (shipmentTransfer) {
      onHandleShipments(true)
      setShipmentTransfer(false)
    }
  }, [shipmentTransfer, onHandleShipments])

  const onMenuButtonClick = useCallback(
    async (action: AccionMenu | { actionType: string }) => {
      switch (action.actionType) {
        case ButtonTypes.find:
          if (tabs.current === 'BUSQUEDA') onHandleSearch()
          else dispatch(changeCurrentTab('BUSQUEDA'))
          break
        case ButtonTypes.new:
          if (tabs.current === 'BUSQUEDA') openNewTab()
          else setConfirmNew(true)
          break
        case ButtonTypes.save:
          if (tabs.current !== 'BUSQUEDA')
            dispatch(setCurrentFunction(ButtonTypes.save))
          break
        case ButtonTypes.edit:
          if (tabs.current === 'BUSQUEDA') setConfirmEdit(true)
          break
        case ButtonTypes.delete:
          if (tabs.current === 'BUSQUEDA') setConfirmDelete(true)
          break
        case ButtonTypes.clone:
          if (tabs.current === 'BUSQUEDA') setConfirmDuplicate(true)
          break
        case ButtonTypes.broom:
          if (tabs.current === 'BUSQUEDA') onHandleBroom()
          break
        case ButtonTypes.shipments:
          if (tabs.current === 'BUSQUEDA') setShipmentTransfer(true)
          break
        case ButtonTypes.reception:
          if (tabs.current === 'BUSQUEDA') onHandleShipments()
          break
        case ButtonTypes.series:
          if (tabs.current !== 'BUSQUEDA')
            dispatch(setCurrentFunction(ButtonTypes.series))
          break
        case ButtonTypes.lots:
          if (tabs.current !== 'BUSQUEDA')
            dispatch(setCurrentFunction(ButtonTypes.lots))
          break
        case ButtonTypes.archived:
          if (tabs.current === 'BUSQUEDA') setClickArchiveTransfer(true)
          break
        case ButtonTypes.verAsiento:
          if (tabs.current === 'BUSQUEDA') setShowEntry(true)
          break
        case ButtonTypes.print:
          if (tabs.current === 'BUSQUEDA') setConfirmPrint(true)
          break
        case ButtonTypes.undo:
          if (tabs.current === 'BUSQUEDA') onHandleUndo()
          else dispatch(setCurrentFunction(ButtonTypes.undo))
          break
        default:
          break
      }
      dispatch(clearButtonClick(tabs.current))
    },
    [
      dispatch,
      onHandleBroom,
      onHandleSearch,
      openNewTab,
      onHandleShipments,
      onHandleUndo,
      tabs,
    ],
  )

  const onParseButtons = useCallback(() => {
    const acctionButtons = structuredClone(options)
    const index = acctionButtons.findIndex(
      (action: AccionMenu) => action.nombre === 'Ver asiento',
    )
    if (index > -1) acctionButtons[index].actionType = ButtonTypes.verAsiento
    setOptionsMenu(acctionButtons)
  }, [options])

  useEffect(() => {
    if (tabs.tabs[tabs.current].globalButtonClick !== ButtonTypes.none)
      onMenuButtonClick({
        actionType: tabs.tabs[tabs.current].globalButtonClick,
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs.tabs[tabs.current].globalButtonClick])

  useEffect(() => {
    if (menuState.length > 0 && options.length === 0) {
      getMenuOptions(menuState, 'to', pathVerificador)
    }
  }, [menuState, getMenuOptions, options])

  useEffect(() => {
    if (options.length > 0 && optionsMenu.length === 0) onParseButtons()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options])

  useEffect(() => {
    if (tabs.tabs[tabs.current].globalButtonClick !== ButtonTypes.none)
      onMenuButtonClick({
        actionType: tabs.tabs[tabs.current].globalButtonClick,
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs.tabs[tabs.current].globalButtonClick])

  useEffect(() => {
    resetButtons()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onCheckTarnsfersStatus = useCallback(async () => {
    try {
      playLoaderSearch(
        'Verificando estado de transferencias . . .',
        ButtonTypes.new,
      )
      const tarnsfersStatus = await TransfersServices.verifyStatus()
      stopLoaderSearch()
      setToastMessage(
        'Verificar Estado de Transferencias',
        tarnsfersStatus.message,
        ToastTypes.Warning,
      )
      dispatch(setIsChecked(true))
    } catch (error) {
      stopLoaderSearch()
      setToastMessage(
        'Verificar Estado de Transferencias',
        error,
        ToastTypes.Danger,
      )
    }
  }, [dispatch, playLoaderSearch, setToastMessage, stopLoaderSearch])

  useEffect(() => {
    if (isChecked === false) onCheckTarnsfersStatus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const confirmActionImprimir = () => {
    return (
      <Modalform
        name="confirmar"
        headerTitle={'ácatha'}
        childrenBody={<>{'¿Que impresión desea realizar?'}</>}
        childrenFooter={
          <CRow>
            <CCol lg="12" sm="12" md="12">
              <CButton
                size="sm"
                color="secondary"
                className="me-2"
                onClick={() => {
                  setConfirmPrint(false)
                  onHandlePrint('Admin')
                }}
              >
                {'Admin'}
              </CButton>
              <CButton
                size="sm"
                color="secondary"
                className="me-2"
                onClick={() => {
                  setConfirmPrint(false)
                  onHandlePrint('Vale')
                }}
              >
                {'Vale'}
              </CButton>
              <CButton
                size="sm"
                color="secondary"
                className="me-2"
                onClick={() => {
                  setConfirmPrint(false)
                }}
              >
                {'Cancelar'}
              </CButton>
            </CCol>
          </CRow>
        }
        closeOnBackdrop={false}
        show={confirmPrint}
        onClose={() => setConfirmPrint(!confirmPrint)}
        centered={true}
        size="sm"
      />
    )
  }

  return (
    <>
      {confirmPrint && createPortal(confirmActionImprimir(), document.body)}
      {pdfReport !== null && (
        <ReportWithLocalDataModal
          show={pdfReport !== null}
          onClose={() => setPdfReport(null)}
          data={[dataToPrint]}
          fileName={
            typeReport === 'Admin'
              ? 'StaticTransferAdmin'
              : 'StaticTransferVale'
          }
          mode={pdfReport ?? 'Viewer'}
          parameters={{ Reporte_Filtro: '' }}
          template={
            typeReport === 'Admin'
              ? 'StaticTransferAdmin'
              : 'StaticTransferVale'
          }
          key="reportDesignerTransfers"
        />
      )}
      {showEntry && (
        <ModalAsiento
          show={showEntry}
          onClose={() => setShowEntry(false)}
          infoAsiento={selectedTransfer?.codigo ?? 0}
          origenModulo={parseInt(EModulosAcatha.Transferencias)}
        />
      )}
      <Dialog ref={dialogRef} />
      <TransferenciasMain
        accciones={optionsMenu}
        onMenuButtonClick={onMenuButtonClick}
        getButtonDisabled={getButtonDisabled}
        currentWorking={tabs.tabs[tabs.current].currentExecutingAction}
        tabs={tabs}
        onTabChanged={onTabChanged}
        onTabClosing={onTabClosing}
      />
    </>
  )
}

export default Transferencias

export const ModuleButtons: CustomButtons = {
  Nuevo: true,
  Guardar: true,
  Buscar: true,
  Deshacer: true,
}

export const emptyItem: ItemTransferencia = {
  codigoTrd: null,
  codigo: null,
  codigoUsuario: '',
  barras: '',
  descripcion: '',
  unidadDescripcion: '',
  cantidad: null,
  referencia: null,
  existencia: null,
  numSerie: null,
  lotes: null,
  pvd: null,
  pvp: null,
  fraccion: null,
  progreso: null,
}

export const defaultDataTranfers: TransferEditData = {
  codigo: 0,
  numero: 1,
  fecha: DateUtils.getCurrentDateAsString(),
  descripcion: '',
  progreso: Progreso.INGRESADO,
  destinoProyecto: { codigo: -1, locales: [] },
  destinoLocal: null,
  origenProyecto: { codigo: -1, locales: [] },
  origenLocal: null,
  responsable: null,
  item: null,
  compra: null,
  movimiento: null,
  existencia: null,
  cantidad: 0,
  unidad: '',
  referencia: null,
  ordenAsociada: 0,
  detalles: [emptyItem],
  series: [],
  lotes: [],
  guardado: false,
  loaded: false,
  loader: { show: false, mensaje: '' },
  backup: null,
}

export const newLocal: LocalOption = {
  codigo: -1,
  ciudad: -1,
  ciudadNombre: '',
  nombre: '',
  direccion: '',
  telefono: '',
  gerente: '',
  tipo: -1,
  tipoDescripcion: '',
  clase: -1,
  claseDescripcion: '',
  pertenece: -1,
  perteneceDescripcion: '',
  estado: -1,
  estadoDescripcion: '',
  kardex: -1,
  siglas: '',
  siglasLocal: '',
  web: -1,
  uaf: '',
  descuento: -1,
  contrato: -1,
  observaciones: '',
  usuarioDokan: '',
  passwordDokan: '',
  ulotipo: '',
}
