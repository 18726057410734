import { DateUtils, formatoFechasApi } from '../../../../helpers/dateUtils'
import { RequestHelper } from '../../../../helpers/requestHelper'
import { utilidades } from '../../../../helpers/utilidades'
import { SesionService } from '../../../../services/sesion.service'
import { Subsidio } from '../types/types'

export const SubsidiosServices = { getSubsidios }

async function getSubsidios(message: string): Promise<any> {
  try {
    const jquery: any = { mensaje: message }
    const sesion = SesionService.getCurrentSesion()
    const keysToCache: any = {
      ...jquery,
      dateKey: DateUtils.getCurrentDateAsString(formatoFechasApi),
      empresa: sesion?.empresa?.codigo,
    }
    const cacheKey = 'tipoSubsidios' + JSON.stringify(keysToCache)
    const cached = sessionStorage.getItem(cacheKey)
    if (cached) {
      return { error: false, auto: JSON.parse(cached) }
    } else {
      const data = await RequestHelper.getAll<any>(
        'inventario/items',
        'subsidios/getAll',
        '',
        jquery,
      )
      const opt: Array<Subsidio> = []

      if (data?.auto && data?.auto.length > 0) {
        for (const x of data.auto) {
          opt.push({
            codigo: parseInt(x.codigo) ?? -1,
            desde: x.desde ?? '',
            hasta: x.hasta ?? '',
            nombre: utilidades.capText(x.nombre) ?? '',
            valor: parseInt(x.valor) ?? 0,
          })
        }
      }
      data.auto = opt ?? []
      sessionStorage.setItem(cacheKey, JSON.stringify(opt))
      return data
    }
  } catch (error) {
    return error
  }
}
