import React, { useEffect } from 'react'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput'
import { DataGrid, LoadPanel, TextBox } from 'devextreme-react'
import { FichaTecnicaDatosEdicion, FichaTecnicaInventario, FiltrosFichaTecnicaState, TechnicalFieldReport } from '../../types/types'
import { ButtonTypes } from '../../../../../../../views/componentes/globalMenu/types'
import { clearButtonClick, openTab } from '../../store/tabsReducer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import {
  changeLoaderDataSheet,
  setCurrentFunction,
} from '../../store/generalReducer'
import { Column, Export, Pager, Paging } from 'devextreme-react/data-grid'
import { getAllowedPageSizes } from '../../../../../../../helpers/Helper'
import { BuscarGrupoLookUp } from '../../../../../components/buscarGrupo/buscarGrupo'
import { TabState } from '../../../../../../../store/genericTabTypes'
import { FETCH_STATUS, ToastTypes } from '../../../../../../../store/types'
import { changeFilter, fetchFichaTecnica, setResetSeleccion, setSeleccionarDato } from '../../store/serachReducer'
import ItemsCountIndicator from '../../../../../../componentes/indicadorNroRegistros'
import { dataSheetServices } from '../../services/unidades.services'
import { getIndexProvider } from '../../../../../../shared/helpers/funciones'
import { addToast } from '../../../../../../../store/toasterReducer'
import { initDatosEdicion } from '../../store/editDataReducer'
import { v4 as uuidv4 } from 'uuid'
import { DateUtils } from '../../../../../../../helpers/dateUtils'
import { Workbook } from 'exceljs'
import { exportDataGrid } from 'devextreme/excel_exporter';
import { saveAs } from 'file-saver';
import Dialog from '../../../../../../../views/componentes/librerias/bootstrap-dialog'
import { ReportWithLocalDataModal } from '../../../../../../../views/componentes/xtrareports/ReportWithLocalDataModal'
import { CCard, CCardFooter, CCardHeader, CCollapse, CLink } from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilChevronBottom, cilChevronTop } from '@coreui/icons'

interface ISearchProps extends React.PropsWithChildren {
  tab: TabState<FichaTecnicaDatosEdicion>
  tabId: string
}

const Search: React.FunctionComponent<ISearchProps> = (props) => {
  const { tabId } = props

  const dispatch = useDispatch()
  const currentAction = useSelector((state: RootState) => state.inventarios.catalogos.fechaTecnica.general.currentFunction)
  const searchResults = useSelector((state: RootState) => state.inventarios.catalogos.fechaTecnica.search.resultados)
  const searchFilter = useSelector((state: RootState) => state.inventarios.catalogos.fechaTecnica.search.filter)
  const searchStatus = useSelector((state: RootState) => state.inventarios.catalogos.fechaTecnica.search.status)
  const selected = useSelector((state: RootState) => state.inventarios.catalogos.fechaTecnica.search.seleccionado)
  const dialogRef = React.useRef<any>(null)

  const unitsDataGrid = React.useRef<any>()
  const position = { of: '#fichaTecnicaContent' }
  const [showLoader, setShowLoader] = React.useState<boolean>(false)
  const [resultados, setResultados] = React.useState([])
  const [fTecnica, setFTecnica] = React.useState<Array<FichaTecnicaInventario>>([])
  const [collapsed, setCollapsed] = React.useState<boolean>(true)

  const getFilterString = React.useCallback((filter: FiltrosFichaTecnicaState) => {
    const filterText = ''
    return filterText
  }, [])

  const [reporte, setReporte] = React.useState<null | 'Viewer' | 'Designer'>(null)

  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(
      addToast({
        title: 'Inventario - Ficha Tecnica',
        content: mensaje,
        type: tipo,
      }),
    )
  }, [dispatch])

  const playLoader = React.useCallback(
    (message = 'Cargando...') => {
      dispatch(changeLoaderDataSheet({ show: true, mensaje: message }))
    },
    [dispatch],
  )

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoaderDataSheet({ show: false, mensaje: '' }))
  }, [dispatch])

  const onValueChanged = React.useCallback((data, key: string) => {
    if (data !== null && data !== undefined) {
      let value = null
      if (data?.value !== null && data?.value !== undefined) {
        value = data.value
      } else if (key === "tipoProducto") {
        value = data
      }
      if (value !== null && value !== undefined) {
        dispatch(changeFilter({
          ...searchFilter,
          [key]: value
        }))
      }
    }
  }, [dispatch, searchFilter])

  const onGetDetalle = React.useCallback(async (codigo: number) => {
    try {
      const detalle = {
        codigo
      }
      const detalleData = await dataSheetServices.geDataSheetsKitCom(detalle)
      if (detalleData !== null && detalleData !== undefined && detalleData['error'] === false) {
        setToast('Detalle Ficha tecnica: ' + detalleData['message'], ToastTypes.Info)
        return detalleData['auto']
      } else {
        setToast(detalleData['message'], ToastTypes.Danger)
        return []
      }
    } catch (error) {
      return []
    }
  }, [setToast])

  const onHandleEdit = React.useCallback(async () => {
    playLoader()
    const detalle = await onGetDetalle(selected.row?.codigo)
    stopLoader()
    const id = uuidv4()

    const itemRef = {
      descripcion: selected.row?.codigoUsuItem ?? '',
      codigo: selected.row?.codigoItem ?? 0,
    }
    const hormaData = {
      descripcion: selected.row?.barraSh ?? '',
      codigo: selected.row?.horma ?? 0,
    }

    const edit_data: FichaTecnicaDatosEdicion = {
      codigo: selected.row?.codigo ?? 0,
      fecha: DateUtils.format(selected.row?.fecha, 'yyyy-MM-dd', 'dd/MM/yyyy'),
      itemReferencia: itemRef,
      serie: selected.row?.serie ?? '',
      nroBase: selected.row?.base ?? '',
      horma: hormaData,
      grupo: selected.row?.grupoItemdescripcion ?? '',
      propiedades: selected.row?.valores.toString() ?? '',
      kitPartes: null,
      materiales: null,
      color: '',
      descripcionKit: 'Editar',
      descripcionMaterial: '',
      codigoUsuario: '',
      cantidad: '',
      unidad: '',
      desperdicio: '',
      costo: '',
      observaciones: selected.row?.observaciones ?? '',
      imagen: selected.row?.imagenItem ?? '',
      datagrid: detalle,
      guardado: false,
    }

    console.log(edit_data)
    dispatch(
      initDatosEdicion({
        key: id,
        data: edit_data,
      }),
    )
    dispatch(openTab({ key: id, fichaTecnica: edit_data }))
  }, [selected.row, dispatch, playLoader, stopLoader, onGetDetalle])

  const onConfirmEdit = React.useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea Editar el registro?`,
      actions: [
        Dialog.Action(
          <span>
            <u>A</u>ceptar
          </span>,
          (dialog) => {
            dialog.hide()
            onHandleEdit()
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    return
  }, [onHandleEdit])

  const deleteItemTabla = React.useCallback(async () => {
    if (selected.row !== null && selected.row !== undefined) {
      if (resultados !== null && resultados !== undefined && resultados.length > 0) {
        const provider = resultados.slice(0);
        const index = await getIndexProvider(provider, 'codigo', selected.row.codigo)
        if (index > -1) {
          provider.splice(index, 1)
          setResultados(provider)
          dispatch(setResetSeleccion())
        }
      }
    }
  }, [selected.row, dispatch, resultados])

  const onHandleRemove = React.useCallback(async () => {
    try {
      const id = selected.row?.codigo ?? -1
      setShowLoader(true)
      const data = await dataSheetServices.deleteDataSheet(id)
      setShowLoader(false)
      if (data !== null && data !== undefined && data['error'] === false) {
        deleteItemTabla()
        setToast(data['message'], ToastTypes.Success)
      } else {
        setToast(data['message'], ToastTypes.Success)
      }
    } catch (error) {
      setShowLoader(false)
      setToast(error.message, ToastTypes.Success)
    }
  }, [selected.row, setToast, deleteItemTabla])

  const onGetApiData = React.useCallback(async (codigoFicha: number, tipo: string) => {
    try {
      setFTecnica([])
      const data_print = {
        codigo: codigoFicha
      }
      const res = await dataSheetServices.geDataSheetsTransaction(data_print)
      if (res !== null && res !== undefined && res['error'] === false) {
        setFTecnica(res['auto'])
        if (tipo === 'Viewer') {
          setReporte('Viewer')
        } else {
          setReporte('Designer')
        }
      }
    } catch (error) {
      console.log(error)
    }
  }, [])

  const handleButtonClick = React.useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.delete:
          onHandleRemove()
          break
        case ButtonTypes.edit:
          onHandleEdit()
          break
        case ButtonTypes.print:
          if (selected.row !== undefined && selected.row !== null && selected.row?.codigo) {
            if (fTecnica.length === 0) {
              onGetApiData(selected.row?.codigo ?? 0, 'Viewer')
            } else {
              setReporte('Viewer')
            }
          } else {
            setToast('Seleccione un item para imprimir', ToastTypes.Warning)
          }
          break
        case ButtonTypes.print_design:
          if (selected.row !== undefined && selected.row !== null && selected.row?.codigo) {
            if (fTecnica.length === 0) {
              onGetApiData(selected.row?.codigo ?? 0, 'Designer')
            } else {
              setReporte('Designer')
            }
          } else {
            setToast('Seleccione un item para Diseñar', ToastTypes.Warning)
          }
          break
        default:
          break
      }
      dispatch(setCurrentFunction(''))
      dispatch(clearButtonClick(tabId))
    },
    [dispatch, onHandleRemove, onHandleEdit, tabId, fTecnica, onGetApiData, selected.row, setToast],
  )

  const handleSearch = React.useCallback(async () => {
    try {
      const search_data = {
        base: searchFilter?.nroBase ?? '',
        grupoCodigo: searchFilter.tipoProducto?.codigo ?? -1,
      }
      const toAny: any = fetchFichaTecnica(search_data)
      const res = await dispatch(toAny)
      if (res !== null && res !== undefined && res['payload'].error === false) {
        setToast(res['payload'].message, ToastTypes.Success)
      } else {
        setToast(res['payload'].message, ToastTypes.Danger)
      }
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
  }, [dispatch, searchFilter, setToast])

  const onGetData = React.useCallback(() => {
    let newArray = []
    if (searchResults !== null && searchResults !== undefined && searchResults['error'] === false) {
      newArray = searchResults['auto'].map(item => {
        return item
      })
      setResultados(newArray)
    }
  }, [searchResults])

  const onSelectedChanged = React.useCallback(({ selectedRowsData }) => {
    console.log(selectedRowsData[0])
    dispatch(setSeleccionarDato({
      index: selectedRowsData[0]?.codigo ?? 0,
      row: selectedRowsData[0],
      selectedRow: selectedRowsData[0],
    }))
  }, [dispatch])

  const onExportExcell = React.useCallback((e) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('Main sheet')
    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Fucha_Tecnica_Inventario.xlsx')
      });
    });
  }, [])

  const onParseData = React.useCallback((fichaTecnica: any) => {
    console.log(fichaTecnica)
    const data = fichaTecnica.resultado
    const fechaPrint = data?.fecha ? DateUtils.strDateToDate(data?.fecha, 'dd/MM/yyyy') : new Date()
    const registros: Array<TechnicalFieldReport> = []
    const registro: TechnicalFieldReport = {
      Date: fechaPrint,
      Serie: data?.serie ?? '',
      Base: data?.base ?? '',
      ItemCodeUsuario: data?.codigoUsuItem ?? '',
      ItemDescriptionN: data?.itemDescripcion ?? '',
      ItemDescriptionNH: data?.itemDescripcionH ?? '',
      ItemProperties: '',
      ItemGroupDescription: data?.itemGrupoDes ?? '',
    }


    registros.push(registro)
    console.log(registros)
    return registros;
  }, []);

  useEffect(() => {
    if (currentAction !== '') handleButtonClick(currentAction)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAction])

  useEffect(() => {
    onGetData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResults])


  return (
    <>
      <ReportWithLocalDataModal
        show={reporte !== null}
        onClose={() => setReporte(null)}
        data={onParseData(fTecnica)}
        fileName="StaticFichaTecnicaListado"
        mode={reporte ?? 'Viewer'}
        parameters={{ Reporte_Filtro: getFilterString(searchFilter) }}
        template="StaticFichaTecnicaListado"
        key="reportDesigner"
      />
      <Dialog ref={dialogRef} />
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={position}
        visible={searchStatus === FETCH_STATUS.LOADING || showLoader}
        showIndicator={true}
        shading={true}
        showPane={true}
        closeOnOutsideClick={false}
      />
      <div id='fichaTecnicaContent'>
        <CCard>
          <CCardHeader
            onClick={() => setCollapsed(!collapsed)}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <strong> {collapsed ? '' : 'Filtro de Búsqueda'} </strong>
            <CLink className="card-header-action">
              <CIcon icon={collapsed ? cilChevronBottom : cilChevronTop} />
            </CLink>
          </CCardHeader>
          <CCollapse visible={collapsed}>
            <RowContainer className='mb-1'>
              <CustomCol xs="12" md="3">
                <Labeled label="Nro. Base:">
                  <TextBox
                    value={searchFilter?.nroBase ?? ''}
                    onValueChanged={(data) => onValueChanged(data, 'nroBase')}
                    onEnterKey={handleSearch}
                  />
                </Labeled>
              </CustomCol>
              <CustomCol xs="12" md="3">
                <Labeled label="Tipo de Producto:">
                  <BuscarGrupoLookUp
                    selected={searchFilter.tipoProducto ?? null}
                    onChanged={(data) => onValueChanged(data, 'tipoProducto')} />
                </Labeled>
              </CustomCol>
            </RowContainer>
          </CCollapse>
          <CCardFooter>
            <ItemsCountIndicator items={resultados} />
            <RowContainer>
              <DataGrid
                ref={unitsDataGrid}
                id="codigo"
                selection={{ mode: 'single' }}
                dataSource={resultados}
                showColumnLines={true}
                showRowLines={true}
                showBorders={true}
                columnResizingMode="widget"
                onExporting={onExportExcell}
                onSelectionChanged={onSelectedChanged}
                onRowDblClick={onConfirmEdit}
                loadPanel={{
                  enabled: true,
                  height: 90,
                  indicatorSrc: '',
                  shading: false,
                  shadingColor: '',
                  showIndicator: true,
                  showPane: true,
                  text: 'Cargando...',
                  width: 200,
                }}
              >
                <Paging defaultPageSize={20} />
                <Pager
                  visible={searchResults.length > 0 ? true : false}
                  allowedPageSizes={getAllowedPageSizes(searchResults)}
                  displayMode={'full'}
                  showPageSizeSelector={true}
                  showInfo={true}
                  showNavigationButtons={true}
                />
                <Export enabled={true} allowExportSelectedData={true} />
                <Column dataField="fecha" caption="Fecha" width="10%" />
                <Column dataField="serie" caption="Serie" width="7%" />
                <Column dataField="base" caption="Base" width="7%" />
                <Column dataField="codigoUsuItem" caption="Ref." width="7%" />
                <Column dataField="descripcionItem" caption="Item" width="18%" />
                <Column dataField="horma" caption="Horma" width="18%" />
                <Column dataField="valores" caption="Propiedades" width="26%" />
                <Column dataField="grupoItemdescripcion" caption="Grupo" width="7%" />
              </DataGrid>
            </RowContainer>
          </CCardFooter>
        </CCard>
      </div>
    </>
  )
}

export default React.memo(Search)
