import React from 'react';
import SelectBox from 'devextreme-react/select-box';
import { proyectosService } from './service/proyectos.service';
import { TProyectoOption } from './store/type';

interface TProyectokUpProps extends React.PropsWithChildren {
  selected: TProyectoOption | null,
  onChanged: (newValue: TProyectoOption | null) => void,
  onChangedOptions: (newValue: Array<TProyectoOption> | []) => void,
  disabled?: boolean,
}

const ProyectoLookUp: React.FC<TProyectokUpProps> = (props) => {
  const { selected, onChanged, disabled } = props;
  const selectRef = React.useRef<any>();
  const [options, setOptions] = React.useState<Array<TProyectoOption> | []>([]);

  const loadDatos = React.useCallback(async () => {
    const data = await proyectosService.getProyectos("Elija una opción");
    if (data.error === false) {
      let opt = [];
      if (data.auto.length > 0) {
        opt = data.auto.map(x => {
          const item: TProyectoOption = {
            codigo: parseInt(x.PRO_CODIGO) ?? -1,
            descripcion: x.PRO_NOMBRE ?? '',
          };
          return item;
        });
      }
      setOptions(opt)
      const selectedIndex = opt.findIndex((select) => {
        return select.codigo === selected?.codigo
      })
      onChanged(opt[selectedIndex] ?? opt[0])
    }

  }, [onChanged, selected]);

  const getItemDisplayExpr = React.useCallback((item: TProyectoOption) => {
    return item ? `${item.descripcion}` : '';
  }, []);

  const onLookUpValueChanged = React.useCallback((evt: any) => {
    if (evt.event !== undefined) {
      onChanged(evt.value);
    }
  }, [onChanged]);

  const onSelectBoxFocusIn = React.useCallback((e) => {
    e.element.querySelector("input.dx-texteditor-input").select();
  }, [])

  React.useEffect(() => {
    loadDatos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <SelectBox
      id="proyectoSelect"
      ref={selectRef}
      items={options ?? []}
      searchEnabled
      displayExpr={getItemDisplayExpr}
      searchExpr={['descripcion']}
      searchTimeout={500}
      onEnterKey={() => {}}
      value={selected}
      onValueChanged={onLookUpValueChanged}
      disabled={disabled}
      onFocusIn={onSelectBoxFocusIn}
    >
      {props.children}
    </SelectBox>
  )

}
export default React.memo(ProyectoLookUp);
