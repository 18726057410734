import { DateUtils, formatoFechasApi } from "../../../../helpers/dateUtils";
import { RequestHelper } from "../../../../helpers/requestHelper";
import { utilidades } from "../../../../helpers/utilidades";
import { SesionService } from "../../../../services/sesion.service";
import { CapitalizeMode } from "../../../../store/types";
import { TipoCliente } from "../../../ventas/types/types";

export const tipoClienteService = { getTipoCliente };

async function getTipoCliente(label: string): Promise<any> {
  const data: any = {
    label: label
  };
  const sesion = SesionService.getCurrentSesion()
  const keysToCache: any = {
    ...data,
    dateKey: DateUtils.getCurrentDateAsString(formatoFechasApi),
    empresa: sesion?.empresa?.codigo,
  }
  const cacheKey = 'tiposClientes' + JSON.stringify(keysToCache);
  const cached = sessionStorage.getItem(cacheKey);
  if (cached) {
    return { error: false, auto: JSON.parse(cached) };
  } else {
    const tipos: any = await RequestHelper.getAll('clientes', 'clientes/tipos', "", data);
    const opt: Array<TipoCliente> = [];
    if (tipos?.auto && tipos?.auto.length > 0) {
      for (const x of tipos.auto) {
        opt.push({
          codigo: parseInt(x.codigo) ?? -1,
          descripcion: utilidades.capText(x.descripcion, CapitalizeMode.upperFirst) ?? '',
          estado: x.codigoEstado ?? 0,
          estadoDescripcion: x.descripcionEstado ?? '',
          porcentajeDescuento: x.porcentajeDescuento ?? 0,
        })
      }
    }
    sessionStorage.setItem(cacheKey, JSON.stringify(opt));
    tipos.auto
    return tipos;
  }
}

