import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { changeLoaderKardex } from '../store/generalReducer'

function useLoaderKardex() {
  const dispatch = useDispatch()

  const playLoader = useCallback(
    (message: string = 'Cargando...') => {
      dispatch(changeLoaderKardex({ show: true, mensaje: message }))
    },
    [dispatch],
  )

  const stopLoader = useCallback(() => {
    dispatch(changeLoaderKardex({ show: false, mensaje: '' }))
  }, [dispatch])

  return { playLoader, stopLoader }
}

export { useLoaderKardex }
