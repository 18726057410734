import { AccionMenu } from "../../../../store/types"
import { SeleccionadoTableItem } from "../../../bancos/store/types"
import { ModalOpen } from "../../../contabilidad/general/store/types"
import { Alerta } from "../../../contabilidad/pages/diarioGeneral/components/modalAnticipos/store/types"

export enum TIPOS_BOTONES {
  BOTON_NUEVO = 'Nuevo',
  BOTON_GUARDAR = 'Guardar',
  BOTON_BUSCAR = 'Buscar',
  BOTON_ELIMINAR = 'Eliminar',
  BOTON_IMPRIMIR = 'Imprimir',
  BOTON_DESHACER = 'Deshacer',
}

export type Registros = {
  codigoTAV: number,//  TAV_CODIGO;
  codigoVenta: number,//  VEN_CODIGO;
  codigoCMO: number,//  CMO_CODIGO;
  nombreCMO: string | null,// CMO_NOMBRE;
  nombreTarjeta: string | null,// TAR_NOMBRE;
  valorTAV: number,// -> TAV_VALOR;
  loteTAV: string | null,// TAV_LOTE;
  recapTAV: string | null,// TAV_RECAP;
  autorizacionTAV: string | null,// TAV_AUTORIZACION;
  numeroVenta: string | null,// VEN_NUMERO;
  fechaVenta: string | null,// VEN_FECHA;
  codigoING: number,//  ING_CODIGO;
}

export type OptionGrupos = {
  id: number,
  orden: number,
  check: boolean,
  label: string,
  labelId: string,
  cod: string,
}

export type ModalTarjetaState = {
  modulo: string,
  currentAction: string,
  currentFunction: string,
  aplicacion: string,
  acciones: Array<AccionMenu>,
  registros: Array<Registros>,
  seleccionado: SeleccionadoTableItem,
  refrescaTabla: boolean,
  modulos: Array<OptionGrupos>,
  modal: ModalOpen,
  motrarAlerta: Alerta,
  dbtarjetas: Array<any>
}