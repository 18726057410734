import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../../store/store'
import { FETCH_STATUS } from '../../../../../store/types'
import { ClientesService } from '../services/clientes.service'
import {
  ClientesListado,
  FiltrosClientesState,
  SearchState,
} from '../types/types'

const initialState: SearchState<ClientesListado> = {
  filter: {
    identificacion: '',
    nombre: '',
    rcomercial: '',
    saldos: 0,
    tipoIdentificacion: null,
    ciudad: null,
    nombreEmpresa: '',
    origen: null,
    estado: true,
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  resultados: [],
}

export const fetchClientes = createAsyncThunk<
  Array<ClientesListado>,
  FiltrosClientesState
>('clientes/clientes/listar', async (filtro) => {
  try {
    const clientesApi = await ClientesService.getClientes(
      filtro.identificacion,
      filtro.nombre,
      filtro.rcomercial,
      filtro.tipoIdentificacion,
      filtro.nombreEmpresa,
      filtro.saldos,
      filtro.estado,
      filtro.ciudad,
      filtro.origen,
    )
    return clientesApi
  } catch (error) {
    return Promise.reject(error)
  }
})

const searchClientesSlice = createSlice({
  name: 'searchClientes',
  initialState: initialState,
  reducers: {
    setIdentificacion(state, action: PayloadAction<string>) {
      state.filter.identificacion = action.payload
    },
    setCleanResult(state, action) {
      state.filter = {
        identificacion: action.payload.filter.identificacion,
        nombre: action.payload.filter.nombre,
        rcomercial: action.payload.filter.rcomercial,
        saldos: action.payload.filter.saldos,
        tipoIdentificacion: action.payload.filter.tipoIdentificacion,
        ciudad: action.payload.filter.ciudad,
        nombreEmpresa: action.payload.filter.nombreEmpresa,
        origen: action.payload.filter.origen,
        estado: action.payload.filter.estado,
      }
    },
    changeFilter(state, action: PayloadAction<FiltrosClientesState>) {
      state.filter = action.payload
    },
    setResultFilter(state, action: PayloadAction<any>) {
      state.resultados = action.payload
    },
    cleanResult(state) {
      state.resultados = []
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchClientes.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchClientes.fulfilled, (state, { payload }) => {
      state.resultados = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchClientes.rejected, (state, { payload }) => {
      state.resultados = []
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
  },
})

export const selectFiltroBusquedaClientes = (state: RootState) => {
  return state.clientes.clientes.search.filter
}

export const selectResultadosBusquedaClientes = (state: RootState) => {
  return state.clientes.clientes.search.resultados
}

export const {
  setIdentificacion,
  changeFilter,
  setCleanResult,
  setResultFilter,
  cleanResult,
} = searchClientesSlice.actions

export const searchClientesReducer = searchClientesSlice.reducer
