import { RequestHelper } from "../../../../../helpers/requestHelper";
import {
  AccountingEntry,
  DocumentosCtasCobrarListado,
  SavePayment,
} from '../types/types'

export const DocCuentasCobrar = {
  getDocumentos,
  getDocumentoPendientes,
  getFacturasCobrar,
  getCuotasVenta,
  getDetalleVenta,
  savePayments,
  generateAccountingEntry,
  getFacturasCobrarEmpresas,
  loadEntry,
  getEntryDetail,
  getDataPrint,
}

async function getDocumentos(
  empresa: number,
  cliente: number,
  razonSocial: string,
  comprobante: string,
  puntoVenta: number,
  proyecto: number,
  lugar: number,
  consignacion: boolean,
  consolidar: boolean,
  fecha: string,
): Promise<Array<DocumentosCtasCobrarListado>> {
  const data: any = {
    empresa: empresa ?? '',
    cliente: cliente ?? '',
    razonSocial: razonSocial ?? '',
    comprobante: comprobante ?? '',
    puntoVenta: puntoVenta ?? '',
    proyecto: proyecto,
    lugar: lugar,
    consignacion: consignacion,
    consolidar: consolidar,
    fecha: fecha,
  }

  // if (estado) {
  //     data.estado = estado;
  // }

  const documentos = await RequestHelper.get<
    Array<DocumentosCtasCobrarListado>
  >('clientes', 'clientes/listar', '', data)
  return documentos
}

async function getDocumentoPendientes(object: any): Promise<Promise<any>> {
  const query = {
    fechaHoy: object['fechaHoy'],
    locCodigo: object['locCodigo'],
  }

  try {
    const dataPendientes = await RequestHelper.getAll<any>(
      'clientes',
      'general/customersReceivable',
      '',
      query,
    )
    return dataPendientes
  } catch (error) {
    console.log(error)
    return error
  }
}

async function getFacturasCobrar(codigo: number): Promise<Promise<any>> {
  const query = {
    clienteCodigo: codigo,
  }
  try {
    const dataPendientes = await RequestHelper.getAll<any>(
      'ventas',
      'invoicesReceivable',
      '',
      query,
    )
    return dataPendientes
  } catch (error) {
    return error
  }
}

async function getFacturasCobrarEmpresas(
  codigoEmpresa: number,
  fechaInicio: string,
  fechaFin: string
): Promise<Promise<any>> {
  const query = {
    empres: codigoEmpresa,
    fechaInicio,
    fechaFin,
  }
  try {
    const dataPendientes = await RequestHelper.getAll<any>(
      'ventas',
      'invoicesReceivableforCompanies',
      '',
      query,
    )
    return dataPendientes
  } catch (error) {
    return error
  }
}

async function getCuotasVenta(codigo: number): Promise<Promise<any>> {
  const query = {
    codigo: codigo,
  }
  try {
    const dataPendientes = await RequestHelper.getAll<any>(
      'ventas',
      'getSalesInstallments',
      '',
      query,
    )
    return dataPendientes
  } catch (error) {
    return error
  }
}

async function getDetalleVenta(codigo: number): Promise<Promise<any>> {
  const query = {
    codigo: codigo,
  }
  try {
    const dataPendientes = await RequestHelper.getAll<any>(
      'ventas',
      'getDetail',
      '',
      query,
    )
    return dataPendientes
  } catch (error) {
    return error
  }
}

async function savePayments(requestBody: SavePayment): Promise<any> {
  try {
    const data = await RequestHelper.postAll<any>(
      'clientes',
      'DocCuentasPorCobrar/paymentsSales/savePayment',
      requestBody,
    )
    return data
  } catch (error) {
    return error
  }
}

async function generateAccountingEntry(
  requestBody: AccountingEntry,
): Promise<any> {
  try {
    const data = await RequestHelper.postAll<any>(
      'contabilidad',
      'entries/generateEntry',
      requestBody,
    )
    return data
  } catch (error) {
    return error
  }
}


async function loadEntry(
  requestBody: any,
): Promise<any> {
  try {
    const data = await RequestHelper.getAll<any>(
      'contabilidad',
      'entries/getJournalTran',
      '',
      requestBody,
    )
    return data
  } catch (error) {
    return error
  }
}

async function getEntryDetail(
  numeroAsiento: number,
): Promise<any> {
  try {

    const obj = {
      numeroAsiento: numeroAsiento
    }
    const data = await RequestHelper.getAll<any>(
      'contabilidad',
      'entries/getDetail',
      '',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}

async function getDataPrint(
  tab: string,
  codigoAsociado: number = null,
  codigoIngreso: number = null,
  modeloImpresion: string = "",
  tipo: string = ""): Promise<any> {
  try {
    const obj = {
      tab,
      ingresoAsociado: codigoAsociado,
      ingresoCodigo: codigoIngreso,
      modeloImpresion,
      tipo,
    }
    const data = await RequestHelper.getAll<any>(
      'clientes',
      'DocCuentasPorCobrar/getTransactions',
      '',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}
