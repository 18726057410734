import { RequestHelper } from '../../../../../helpers/requestHelper'

export const cuentasService = {
  asientosEliminar,
  CargarCombosPuntoVenta,
  devuelveTotalSinRetencion,
  insertarAnticipo,
  removerAnticipo,
  obtenerAnticipos,
  asientosEnumerar,
  asientosCorrejir,
  asientosVerificar,
  asientoSecuencial,
  asientosRestaurar,
  crearRegistro,
  obtenerProyectos,
  obtenerPeriodosContables,
  obtenerRegistros,
  deleteRegistro,
  createImport,
  obtenerTipoAsientos,
  cargarAsiento,
  getAnticipos,
  saveAnticipo,
  deleteAnticipos,
}

async function obtenerPeriodosContables(): Promise<any> {
  try {
    const obj = {}
    const data = await RequestHelper.getAll<any>(
      'generales',
      'period/getAll',
      '',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}

async function obtenerTipoAsientos(message): Promise<any> {
  try {
    const obj = {
      mensaje: message,
    }
    const data = await RequestHelper.getAll<any>(
      'contabilidad',
      'asientos/tipos',
      '',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}

async function obtenerProyectos(): Promise<any> {
  try {
    const obj = {}
    const data = await RequestHelper.getAll<any>(
      'proyectos/listar',
      '',
      '',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}

async function obtenerRegistros(objeto: any): Promise<any> {
  try {
    const data = await RequestHelper.getAll<any>(
      'contabilidad',
      'entries/searchByDate',
      '',
      objeto,
    )
    return data
  } catch (error) {
    return error
  }
}

async function deleteRegistro(idCuenta: any): Promise<any> {
  try {
    const objRequest = {
      numero: idCuenta,
    }
    const data = await RequestHelper.deleteRequestAll<any>(
      'contabilidad',
      'entries/inactive',
      '',
      objRequest,
    )
    return data
  } catch (error) {
    return error
  }
}

// probar este import al cargar los asientos importandolos
async function createImport(archivo: any): Promise<any> {
  try {
    const obj = {
      archivo: archivo['file'],
      tipo: 'archivo',
      nombre: archivo['nombre'],
    }
    const data = await RequestHelper.postData<any>(
      'contabilidad',
      'asientos/importar',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}

async function cargarAsiento(asiento: any): Promise<any> {
  try {
    const obj = {
      numeroAsiento: asiento,
    }
    const data = await RequestHelper.getAll<any>(
      'contabilidad',
      'entries/getJournalEntry',
      '',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}

async function crearRegistro(objeto: any): Promise<any> {
  try {
    const obj = {
      infoRegistro: {
        numero: objeto['numero'],
        fecha: objeto['fecha'],
        descripcion: objeto['descripcion'],
        pla_descripcion: objeto['pla_descripcion'],
        tipo: objeto['tipo'],
        debe: objeto['debe'],
        haber: objeto['haber'],
        local: objeto['local'],
        transacciones: objeto['transacciones'],
      },
    }
    const data = await RequestHelper.postAll<any>(
      'contabilidad',
      'entries/save',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}

async function asientosRestaurar(numero: any): Promise<any> {
  try {
    const objRequest = {
      numero: numero,
    }
    const data = await RequestHelper.deleteRequestAll<any>(
      'contabilidad',
      'entries/restore',
      '',
      objRequest,
    )
    return data
  } catch (error) {
    return error
  }
}

async function asientoSecuencial(): Promise<any> {
  try {
    const obj = {}
    const data = await RequestHelper.getAll<any>(
      'contabilidad',
      'entries/getEntryNumber',
      '',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}

async function asientosVerificar(obj: any): Promise<any> {
  try {
    const objeto = {
      ...obj,
    }
    const data = await RequestHelper.getAll<any>(
      'contabilidad/diarioGeneral',
      'seats/verify',
      '',
      objeto,
    )
    return data
  } catch (error) {
    return error
  }
}

async function asientosCorrejir(obj: any): Promise<any> {
  try {
    const objeto = {
      ...obj,
    }
    const data = await RequestHelper.postAll<any>(
      'contabilidad/diarioGeneral',
      'seats/correction',
      objeto,
    )
    return data
  } catch (error) {
    return error
  }
}

async function asientosEliminar(obj: any): Promise<any> {
  try {
    const objeto = {
      ...obj,
    }
    const data = await RequestHelper.deleteRequestAll<any>(
      'contabilidad/diarioGeneral',
      'seats/delete',
      '',
      objeto,
    )
    return data
  } catch (error) {
    return error
  }
}

async function asientosEnumerar(obj: any): Promise<any> {
  try {
    const objeto = {
      ...obj,
    }
    const data = await RequestHelper.postUrlEncodedAll<any>(
      'contabilidad',
      'entries/generateNumberType',
      objeto,
    )
    return data
  } catch (error) {
    return error
  }
}

async function getAnticipos(
  pertenece: number,
  asociado: number,
  nrtipo: number,
  codigo: number,
  locCodigo: number,
  hasta: string,
): Promise<Promise<any>> {
  try {
    const query = {
      pertenece,
      asociado,
      nrtipo,
      codigo,
      locCodigo,
      hasta,
    }

    const data = await RequestHelper.getAll<any>(
      'contabilidad/diarioGeneral',
      'advances/getAll',
      '',
      query,
    )
    return data
  } catch (error) {
    return error
  }
}

async function saveAnticipo(
  antcodigo: number,
  antnumero: string,
  antpertence: number,
  antAsociado: number,
  antfpago: number,
  antconcepto: string,
  antregistro: string,
  documento: string,
  valor: number,
  antorigen: string,
  ptocodigo: number,
  locCodigo: number,
  pagos: Array<any>,
): Promise<Promise<any>> {
  try {
    const obj = {
      infoRegistro: {
        codigo: antcodigo,
        numero: antnumero,
        pertenece: antpertence,
        asociado: antAsociado,
        formaPago: antfpago,
        concepto: antconcepto,
        registro: antregistro,
        documento: documento,
        valor: valor,
        origen: antorigen,
        origenAsociado: 0,
        codigoPto: ptocodigo,
        codigoLocal: locCodigo,
        pagos,
      },
    }

    const data = await RequestHelper.postAll<any>(
      'contabilidad/diarioGeneral',
      'advances/save',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}

async function deleteAnticipos(
  codigo: number,
  transaccion: string,
): Promise<Promise<any>> {
  try {
    const query = {
      codigo,
      transaccion,
    }

    const data = await RequestHelper.deleteRequestAll<any>(
      'contabilidad/diarioGeneral',
      'advances/inactive',
      '',
      query,
    )
    return data
  } catch (error) {
    return error
  }
}

async function obtenerAnticipos(obj: any): Promise<any> {
  try {
    const objeto = {
      ...obj,
    }
    const data = await RequestHelper.getAll<any>(
      'contabilidad',
      'diarioGeneral/advances/getAllBalances',
      '',
      objeto,
    )
    return data
  } catch (error) {
    return error
  }
}

async function removerAnticipo(obj: any): Promise<any> {
  try {
    const objeto = {
      ...obj,
    }
    const data = await RequestHelper.deleteRequestAll<any>(
      'contabilidad',
      'anticiposcv/baja',
      '',
      objeto,
    )
    return data
  } catch (error) {
    return error
  }
}

async function insertarAnticipo(objetoAnticipos: any): Promise<any> {
  try {
    const obj = {
      infoRegistro: {
        ...objetoAnticipos,
      },
    }
    const data = await RequestHelper.postAll<any>(
      'contabilidad',
      'anticiposcv/insertar',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}

async function devuelveTotalSinRetencion(obj: any): Promise<any> {
  try {
    const objeto = {
      ...obj,
    }
    const data = await RequestHelper.getAll<any>(
      'ventas',
      'getTotalWithoutRetentionSales',
      '',
      objeto,
    )
    return data
  } catch (error) {
    return error
  }
}

async function CargarCombosPuntoVenta(obj: any): Promise<any> {
  try {
    const objeto = {
      ...obj,
    }
    const data = await RequestHelper.getAll<any>(
      'bancos',
      'pointSale/comboLoad',
      '',
      objeto,
    )
    return data
  } catch (error) {
    return error
  }
}
