import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { TabState } from '../../../../../../../store/genericTabTypes'
import { AllRolsList, PaymentsRolListDto, RolesPadoDatosEdicion, RolesPayList, RolsListDto, SaveDataList, SearchTabs } from '../../types/types'
import FiltrosBusqueda from './FiltrosBusqueda'
import ResultadosBusqueda from './ResultadosBusqueda'
import { CCard, CCardFooter, CNav, CNavItem, CNavLink, CTabContent, CTabPane } from '@coreui/react-pro'
import ResHistorialBusqueda from './ResHistorialBusqueda'
import { ButtonTypes } from '../../../../../../../views/componentes/globalMenu/types'
import { clearButtonClick, openTab, setButtons, setCurrentExecutingAction } from '../../store/tabsReducer'
import { useDispatch, useSelector } from 'react-redux'
import { changeFilter, setChangeLoader, setImport, setResetSeleccion, setSearch } from '../../store/searchReducer'
import { RootState } from '../../../../../../../store/store'
import { RolsPaymentsServices } from '../../services/rolsPays.services'
import DataSource from 'devextreme/data/data_source'
import { ConfigPopupMsg, ToastTypes } from '../../../../../../../store/types'
import { addToast } from '../../../../../../../store/toasterReducer'
import { isMobile } from 'react-device-detect'
import BlockUi from '../../../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../../../views/componentes/loadingindicator/loadingindicator'
import ConfirmarAccionMsj from '../../../../../../componentes/confirmarAccionMsj'
import { defaultPopupMsgConfig } from '../../../../../store/types'
import { consoleService } from '../../../../../../../services/console.service';
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import { Draggable, NumberBox, SpeedDialAction } from 'devextreme-react'
import { DateUtils } from '../../../../../../../helpers/dateUtils'
import { MessagesKeys, lh } from '../../../../../../../helpers/localizationHelper'
import VisualizaError from '../../../../../../ventas/pages/shared/visualizaError/visualizaError'
import ListErrosValidationForm from '../../../../../../componentes/listadoErroresValidacionForm'
import { initDatosEdicion } from '../../store/editDataReducer'
import { defaultRolesPagoEdicion } from '../nuevo/index';
import TableLoader from '../../../../../../ventas/components/ventas/busquedaVentas/TableLoader'
import { ReportWithLocalDataModal } from '../../../../../../../views/componentes/xtrareports/ReportWithLocalDataModal'
import Dialog from '../../../../../../../views/componentes/librerias/bootstrap-dialog'
import { MesesData } from '../../../../../../componentes/mesesLookUp'


interface ISearchProps extends React.PropsWithChildren {
  tab: TabState<RolesPadoDatosEdicion>
  tabId: string
}

enum VistasBusqueda {
  Filtros,
  ResultadosBusqueda,
  Resumen,
  Loading,
  Error,
}

const draggingGroupName = 'appointmentsGroup'

export const tabsNames = [SearchTabs.rol, SearchTabs.his]
const Buscar: React.FunctionComponent<ISearchProps> = (props) => {
  const { tabId, tab } = props
  const dispatch = useDispatch()
  const dialogRef = React.useRef<any>(null)

  const tabs = useSelector((state: RootState) => state.nomina.rol.rolesPago.tabs)
  const currentabs = useSelector((state: RootState) => state.nomina.rol.rolesPago.tabs.current)
  const filter = useSelector((state: RootState) => state.nomina.rol.rolesPago.search.filter)
  const filterBackup = useSelector((state: RootState) => state.nomina.rol.rolesPago.search.filterBackup)
  const empresa = useSelector((state: RootState) => state.global.empresasUsuario)
  const seleccionado = useSelector((state: RootState) => state.nomina.rol.rolesPago.search.seleccionado)
  const searchRols = useSelector((state: RootState) => state.nomina.rol.rolesPago.search.searchRols)
  const loader = useSelector((state: RootState) => state.nomina.rol.rolesPago.search.loader)

  const { getRolsPayDatasource } = RolsPaymentsServices.useSetDataSources()

  const DataSourceRolsPays = useMemo(
    () => getRolsPayDatasource(filter),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filter],
  )
  const [templateName, setTemplateName] = React.useState<string>('StaticPaymentsRols')
  const [templateType, setTemplateType] = React.useState<string>('1')

  const [resultadoImprimir, setResultadoImprimir] = useState<Array<RolesPayList> | []>([])
  const [arrayErrors, setArrayErrors] = React.useState<Array<string>>([])
  const [showErrorPopup, setShowErrorPopup] = React.useState<boolean>(false)
  const [currentTab, setCurrentTab] = React.useState<string>(SearchTabs.rol)
  const [dataSourceRols, setDataSourceRols] = useState<DataSource>(null)
  const [vistaActual, setVistaActual] = useState<VistasBusqueda>(
    VistasBusqueda.Filtros,
  )
  const [popupMsgConfig, setPopupMsgConfig] = useState<ConfigPopupMsg>(
    defaultPopupMsgConfig,
  )
  const [reporte, setReporte] = React.useState<null | "Viewer" | "Designer">(null);

  const [pageIndex, setPageIndex] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(10)
  const onResetPag = useCallback(async () => {
    setPageIndex(0)
    setPageSize(10)
  }, [])
  const onSetButtonAction = useCallback(
    (tipo: ButtonTypes | undefined) => {
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'BUSQUEDA',
          buttonType: tipo,
        }),
      )
    },
    [dispatch],
  )

  const setToast = useCallback(
    (mensaje: string, tipo: ToastTypes) => {
      dispatch(
        addToast({
          title: 'Nómina - Roles de Pago',
          content: mensaje,
          type: tipo,
        }),
      )
    },
    [dispatch],
  )

  const onLoader = useCallback((showLoader: boolean, mensaje: string) => {
    dispatch(setChangeLoader({
      show: showLoader,
      mensaje: mensaje
    }))
  }, [dispatch])


  const handleOptionChange = useCallback(async (e) => {
    if (e.fullName === 'paging.pageSize') {
      setPageSize(e.value)
    }
    if (e.fullName === 'paging.pageIndex') {
      setPageIndex(e.value)
    }
  }, [])


  const onFind = useCallback(async () => {
    onSetButtonAction(ButtonTypes.find)
    if (!isMobile) {
      onLoader(true, 'Buscando...')
    }
    try {
      if (isMobile) {
        setVistaActual(VistasBusqueda.Loading)
      }
      await DataSourceRolsPays.reload()
      setDataSourceRols(DataSourceRolsPays)
      dispatch(setSearch(true))
      if (isMobile) {
        setVistaActual(VistasBusqueda.ResultadosBusqueda)
      }
      setToast(`${DataSourceRolsPays.totalCount()} Registro(s) Encontrado(s)`, ToastTypes.Success)
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
      if (isMobile) {
        setVistaActual(VistasBusqueda.Error)
      }
    }
    if (!isMobile) {
      onLoader(false, '')
    }
    onSetButtonAction(undefined)
  }, [DataSourceRolsPays, dispatch, onLoader, onSetButtonAction, setToast])

  const onSaveList = useCallback(async () => {
    const provider: Array<RolesPayList> = dataSourceRols?.items() ?? []
    let vacio: boolean = true
    provider.forEach(x => {
      if (x?.codigo && x?.fechaCobro !== "//") {
        vacio = false
      }
    })
    const providerList = provider.map(x => {
      if (x?.codigo && x?.fechaCobro !== "" && x?.fechaCobro !== null) {
        const dataItem: RolesPayList = {
          ...x,
          fechaCobro: DateUtils.format(x?.fechaCobro, 'dd/MM/yyyy', 'yyyy-MM-dd')
        }
        return dataItem
      }
    })
    if (vacio) {
      onSetButtonAction(ButtonTypes.save)
      onLoader(true, 'Guardando lista...')
      try {

        const datalist_save: SaveDataList = {
          infoRegistro: providerList,
          fpaCodigo: filter?.opcionFormaPago?.codigo ?? 0,
          fecha: DateUtils.getCurrentDateAsString('dd/MM/yyyy'),
          cobrado: 0
        }
        const data = await RolsPaymentsServices.saveListaPay(datalist_save)
        consoleService.log(data, 'data save')
        if (data?.auto && data?.error === false) {
          setToast(data?.message, ToastTypes.Success)
        } else {
          setToast(data?.message, ToastTypes.Danger)
        }
      } catch (error) {
        setToast(error.message, ToastTypes.Danger)
      }
      onLoader(false, '')
      onSetButtonAction(undefined)
    } else {
      setToast('Solo puede modificar roles que no esten pagados', ToastTypes.Warning)
    }
  }, [dataSourceRols, setToast, filter, onLoader, onSetButtonAction])

  const onValidatePay = useCallback(() => {
    const errors: Array<string> = []
    if (filter?.opcionFecha === null || filter?.opcionFecha === undefined || filter?.opcionFecha === '') {
      errors.push('Ingrese una fecha de cobro.')
    }
    return errors
  }, [filter])

  const onPayment = useCallback(() => {
    consoleService.log(dataSourceRols?.items())
    const provider = dataSourceRols?.items() ?? []
    let vacio = true
    provider.forEach(x => {
      if (x?.fPago === 0) {
        vacio = false
      }
    })
    if (filter?.mes?.value !== "-1" && (vacio || filter?.opcionFormaPago?.codigo !== 0)) {
      let errors: Array<string> = []
      errors = onValidatePay()
      if (errors.length === 0) {
        setPopupMsgConfig({
          show: true,
          title: 'Ácatha',
          message: '¿Está seguro que desea realizar el pago de todos los empleados?',
          type: 'confirm',
          currentAction: ButtonTypes.payments,
        })
      } else {
        setToast(lh.getMessage(MessagesKeys.GlobalErrorSave), ToastTypes.Warning)
        setArrayErrors(errors)
        setShowErrorPopup(true)
      }
    } else {
      setToast('Existen roles sin forma de pago', ToastTypes.Warning)
    }
  }, [dataSourceRols, filter, setToast, onValidatePay])

  const onUndo = useCallback(() => {
    dispatch(changeFilter({
      ...filterBackup,
    }))
    dispatch(setResetSeleccion())
  }, [dispatch, filterBackup])

  const onBroom = useCallback(() => {
    setDataSourceRols(null)
  }, [])


  const onConfirmDelete = React.useCallback(() => {
    if (seleccionado !== null && seleccionado?.codigo) {
      setPopupMsgConfig({
        show: true,
        title: 'Ácatha',
        message: `¿Desea ELIMINAR el registro ${seleccionado?.nombresCompletos ?? '-'}?`,
        type: 'confirm',
        currentAction: ButtonTypes.delete,
      })
    }
  }, [seleccionado])

  const onConfirmEdit = React.useCallback(() => {
    if (seleccionado !== null && seleccionado?.codigo) {
      setPopupMsgConfig({
        show: true,
        title: 'Ácatha',
        message: `¿Desea EDITAR el registro ${seleccionado?.nombresCompletos ?? '-'}?`,
        type: 'confirm',
        currentAction: ButtonTypes.edit,
      })
    }
  }, [seleccionado])

  const onGetDataPrint = useCallback(async (mode: null | "Viewer" | "Designer") => {
    onSetButtonAction(ButtonTypes.print)
    onLoader(true, 'Generando Reporte...')
    try {
      const data = await RolsPaymentsServices.getRolsPaymentsAll(filter)
      consoleService.log(data, 'res reporte')
      if (data?.auto && data?.error === false) {
        setResultadoImprimir(data?.auto)
        setReporte(mode)
      } else {
        setToast(data?.message, ToastTypes.Danger)
        setResultadoImprimir([])
      }
    } catch (error) {
      setToast(error?.message, ToastTypes.Danger)
    }
    onLoader(false, '')
    onSetButtonAction(undefined)
  }, [filter, onLoader, onSetButtonAction, setToast])

  const onParseDataRolsAll = useCallback(() => {
    const registros: Array<AllRolsList> = []
    const rols: Array<PaymentsRolListDto> = []
    const provider: Array<RolesPayList> = dataSourceRols?.items() ?? []
    consoleService.log(provider, 'select reg')

    let tipoRol = ''
    const numroles = empresa[0]?.numeroRoles ?? 0
    switch (numroles) {
      case 1:
        tipoRol = 'MENSUAL'
        break;
      case 2:
        tipoRol = 'QUINCENA'
        break;
      case 4:
        tipoRol = 'SEMANA'
        break;

      default:
        break;
    }
    provider.forEach(x => {
      const flabor: string = x?.fechaLabor
      let mes = ''
      if (MesesData.length > 0) {
        MesesData.forEach(x => {
          if (x?.value === flabor.substring(3, 5)) {
            mes = x?.label
          }
        })
      }
      let tot5 = 0
      if (Number(x?.acumularFondosReserva) === 0) {
        tot5 = Number(x?.sueldo) + Number(x?.valorJornada) + Number(x?.valorHoraSuplementarias ?? '0') + Number(x?.valorHoraExtra ?? '0') + Number(x?.comisiones ?? '0') + Number(x?.ajusteSueldo ?? '0') + Number(x?.bonos) + Number(x?.bonificacion ?? '0')
          - (Number(x?.pagarIess ?? '0') + Number(x?.prestamos ?? '0') + Number(x?.prestamosHipotecarios ?? '0') + Number(x?.prestamosQuirografarios ?? '0') + Number(x?.anticipos ?? '0') + Number(x?.descuentos ?? '0') + Number(x?.renta ?? '0') + Number(x?.atrasos ?? '0') + Number(x?.multas ?? '0') + Number(x?.reemplazo ?? '0') + Number(x?.subsidioMaternidad ?? '0') + Number(x?.subsidioEnfermedad ?? '0')) +
          Number(x?.fondosReserva ?? '0') + Number(x?.vacaciones ?? '0') + Number(x?.decimoTercero ?? '0') + Number(x?.decimoCuarto ?? '0')
      } else {
        tot5 = Number(x?.sueldo) + Number(x?.valorJornada) + Number(x?.valorHoraSuplementarias ?? '0') + Number(x?.valorHoraExtra ?? '0') + Number(x?.comisiones ?? '0') + Number(x?.ajusteSueldo ?? '0') + Number(x?.bonos) + Number(x?.bonificacion ?? '0')
          - (Number(x?.pagarIess ?? '0') + Number(x?.prestamos ?? '0') + Number(x?.prestamosHipotecarios ?? '0') + Number(x?.prestamosQuirografarios ?? '0') + Number(x?.anticipos ?? '0') + Number(x?.descuentos ?? '0') + Number(x?.renta ?? '0') + Number(x?.atrasos ?? '0') + Number(x?.multas ?? '0') + Number(x?.reemplazo ?? '0') + Number(x?.subsidioMaternidad ?? '0') + Number(x?.subsidioEnfermedad ?? '0')) +
          Number(x?.vacaciones ?? '0') + Number(x?.decimoTercero ?? '0') + Number(x?.decimoCuarto ?? '0')
      }
      rols.push({
        Code: x?.codigo ?? 0,
        Type: tipoRol,
        Month: mes,
        Year: x.anio ?? 0,
        Employee: `${x?.nombresCompletos}(${x?.cedula})`,
        Charge: x?.descripcion ?? '',
        NominaSalary: Number(x?.nominal) ?? 0,
        WorkDaysD: String(x?.dias) ?? '',
        WorkDaysH: String(x?.HL) ?? '',
        WorkDaysM: String(x?.ML) ?? '',
        SalaryReceived: Number(x?.sueldo) ?? 0,
        NightH2: Number(x?.jornada ?? '0'),
        SupH3: Number(x?.horasSuplementarias ?? '0'),
        ExtraH4: Number(x?.horasExtras ?? '0'),
        VJournal: Number(x?.valorJornada) ?? 0,
        VSup: Number(x?.valorHoraSuplementarias ?? '0'),
        VExtra: Number(x?.valorHoraExtra ?? '0'),
        Commissions: Number(x?.comisiones ?? '0'),
        SalaryAdjustments: Number(x?.ajusteSueldo ?? '0'),
        Base: Number(x?.sueldo) + Number(x?.valorJornada) + Number(x?.valorHoraSuplementarias ?? '0') + Number(x?.valorHoraExtra ?? '0') + Number(x?.comisiones ?? '0') + Number(x?.ajusteSueldo ?? '0'),
        Bonuses: Number(x?.bonos) ?? 0,
        Bonification: Number(x?.bonificacion ?? '0'),
        Total1: Number(x?.sueldo) + Number(x?.valorJornada) + Number(x?.valorHoraSuplementarias ?? '0') + Number(x?.valorHoraExtra ?? '0') + Number(x?.comisiones ?? '0') + Number(x?.ajusteSueldo ?? '0') + Number(x?.bonos) + Number(x?.bonificacion ?? '0'),
        Booking: Number(x?.fondosReserva) ?? 0,
        Vacations: Number(x?.vacaciones) ?? 0,
        Thirtheen: Number(x?.decimoTercero) ?? 0,
        Fourtheen: Number(x?.decimoCuarto) ?? 0,
        Total4: Number(x?.acumularFondosReserva ?? '00') + Number(x?.decimoTercero) + Number(x?.decimoCuarto),
        Total5: Number(x?.sueldo) + Number(x?.valorJornada ?? '0') + Number(x?.valorHoraSuplementarias) + Number(x?.valorHoraExtra) + Number(x?.comisiones) + Number(x?.ajusteSueldo) + Number(x?.bonificacion) + Number(x?.bonos) + Number(x?.sueldo) + Number(x?.vacaciones) + Number(x?.acumularFondosReserva) + Number(x?.decimoTercero) + Number(x?.decimoCuarto),
        IESS: Number(x?.iess) ?? 0,
        Subsidy: Number(x?.subsidioEnfermedad) + Number(x?.subsidioMaternidad),
        Loans: Number(x?.prestamos) ?? 0,
        Advances: Number(x?.anticipos) ?? 0,
        UnsecuredLoans: Number(x?.prestamosQuirografarios) ?? 0,
        MortgageLoans: Number(x?.prestamosHipotecarios) ?? 0,
        Discounts: Number(x?.descuentos) ?? 0,
        Income: Number(x?.renta) ?? 0,
        Arrears: Number(x?.atrasos) ?? 0,
        Fines: Number(x?.multas),
        Others: Number(x?.reemplazo) ?? 0,
        Total2: Number(x?.iess) + Number(x?.prestamos) + Number(x?.prestamosQuirografarios) + Number(x?.prestamosHipotecarios) + Number(x?.anticipos) + Number(x?.descuentos) + Number(x?.renta) + Number(x?.multas) + Number(x?.atrasos) + Number(x?.reemplazo) + Number(x?.subsidioMaternidad) + Number(x?.subsidioEnfermedad),
        Total3: tot5,
        Observations: x?.observaciones ?? '',
      })
    })
    const temp: AllRolsList = {
      Rols: rols
    }
    registros.push(temp)
    consoleService.log(registros)
    return registros
  }, [dataSourceRols, empresa])


  const onParseDataRols = useCallback(() => {
    const registros: Array<PaymentsRolListDto> = []
    consoleService.log(seleccionado, 'select reg')
    let tipoRol = ''
    const numroles = empresa[0]?.numeroRoles ?? 0
    switch (numroles) {
      case 1:
        tipoRol = 'MENSUAL'
        break;
      case 2:
        tipoRol = 'QUINCENA'
        break;
      case 4:
        tipoRol = 'SEMANA'
        break;

      default:
        break;
    }
    const flabor: string = seleccionado?.fechaLabor ?? ""
    let mes = ''
    if (MesesData.length > 0) {
      MesesData.forEach(x => {
        if (x?.value === flabor.substring(3, 5)) {
          mes = x?.label
        }
      })
    }
    let tot5 = 0
    if (Number(seleccionado?.acumularFondosReserva) === 0) {
      tot5 = Number(seleccionado?.sueldo) + Number(seleccionado?.valorJornada) + Number(seleccionado?.valorHoraSuplementarias ?? '0') + Number(seleccionado?.valorHoraExtra ?? '0') + Number(seleccionado?.comisiones ?? '0') + Number(seleccionado?.ajusteSueldo ?? '0') + Number(seleccionado?.bonos) + Number(seleccionado?.bonificacion ?? '0')
        - (Number(seleccionado?.pagarIess ?? '0') + Number(seleccionado?.prestamos ?? '0') + Number(seleccionado?.prestamosHipotecarios ?? '0') + Number(seleccionado?.prestamosQuirografarios ?? '0') + Number(seleccionado?.anticipos ?? '0') + Number(seleccionado?.descuentos ?? '0') + Number(seleccionado?.renta ?? '0') + Number(seleccionado?.atrasos ?? '0') + Number(seleccionado?.multas ?? '0') + Number(seleccionado?.reemplazo ?? '0') + Number(seleccionado?.subsidioMaternidad ?? '0') + Number(seleccionado?.subsidioEnfermedad ?? '0')) +
        Number(seleccionado?.fondosReserva ?? '0') + Number(seleccionado?.vacaciones ?? '0') + Number(seleccionado?.decimoTercero ?? '0') + Number(seleccionado?.decimoCuarto ?? '0')
    } else {
      tot5 = Number(seleccionado?.sueldo) + Number(seleccionado?.valorJornada) + Number(seleccionado?.valorHoraSuplementarias ?? '0') + Number(seleccionado?.valorHoraExtra ?? '0') + Number(seleccionado?.comisiones ?? '0') + Number(seleccionado?.ajusteSueldo ?? '0') + Number(seleccionado?.bonos) + Number(seleccionado?.bonificacion ?? '0')
        - (Number(seleccionado?.pagarIess ?? '0') + Number(seleccionado?.prestamos ?? '0') + Number(seleccionado?.prestamosHipotecarios ?? '0') + Number(seleccionado?.prestamosQuirografarios ?? '0') + Number(seleccionado?.anticipos ?? '0') + Number(seleccionado?.descuentos ?? '0') + Number(seleccionado?.renta ?? '0') + Number(seleccionado?.atrasos ?? '0') + Number(seleccionado?.multas ?? '0') + Number(seleccionado?.reemplazo ?? '0') + Number(seleccionado?.subsidioMaternidad ?? '0') + Number(seleccionado?.subsidioEnfermedad ?? '0')) +
        Number(seleccionado?.vacaciones ?? '0') + Number(seleccionado?.decimoTercero ?? '0') + Number(seleccionado?.decimoCuarto ?? '0')
    }
    registros.push({
      Code: seleccionado?.codigo ?? 0,
      Type: tipoRol,
      Month: mes,
      Year: seleccionado.anio ?? 0,
      Employee: `${seleccionado?.nombresCompletos}(${seleccionado?.cedula})`,
      Charge: seleccionado?.descripcion ?? '',
      NominaSalary: Number(seleccionado?.nominal) ?? 0,
      WorkDaysD: String(seleccionado?.dias) ?? '',
      WorkDaysH: String(seleccionado?.HL) ?? '',
      WorkDaysM: String(seleccionado?.ML) ?? '',
      SalaryReceived: Number(seleccionado?.sueldo) ?? 0,
      NightH2: Number(seleccionado?.jornada ?? '0'),
      SupH3: Number(seleccionado?.horasSuplementarias ?? '0'),
      ExtraH4: Number(seleccionado?.horasExtras ?? '0'),
      VJournal: Number(seleccionado?.valorJornada) ?? 0,
      VSup: Number(seleccionado?.valorHoraSuplementarias ?? '0'),
      VExtra: Number(seleccionado?.valorHoraExtra ?? '0'),
      Commissions: Number(seleccionado?.comisiones ?? '0'),
      SalaryAdjustments: Number(seleccionado?.ajusteSueldo ?? '0'),
      Base: Number(seleccionado?.sueldo) + Number(seleccionado?.valorJornada) + Number(seleccionado?.valorHoraSuplementarias ?? '0') + Number(seleccionado?.valorHoraExtra ?? '0') + Number(seleccionado?.comisiones ?? '0') + Number(seleccionado?.ajusteSueldo ?? '0'),
      Bonuses: Number(seleccionado?.bonos) ?? 0,
      Bonification: Number(seleccionado?.bonificacion ?? '0'),
      Total1: Number(seleccionado?.sueldo) + Number(seleccionado?.valorJornada) + Number(seleccionado?.valorHoraSuplementarias ?? '0') + Number(seleccionado?.valorHoraExtra ?? '0') + Number(seleccionado?.comisiones ?? '0') + Number(seleccionado?.ajusteSueldo ?? '0') + Number(seleccionado?.bonos) + Number(seleccionado?.bonificacion ?? '0'),
      Booking: Number(seleccionado?.fondosReserva) ?? 0,
      Vacations: Number(seleccionado?.vacaciones) ?? 0,
      Thirtheen: Number(seleccionado?.decimoTercero) ?? 0,
      Fourtheen: Number(seleccionado?.decimoCuarto) ?? 0,
      Total4: Number(seleccionado?.acumularFondosReserva ?? '00') + Number(seleccionado?.decimoTercero) + Number(seleccionado?.decimoCuarto),
      Total5: Number(seleccionado?.sueldo) + Number(seleccionado?.valorJornada ?? '0') + Number(seleccionado?.valorHoraSuplementarias) + Number(seleccionado?.valorHoraExtra) + Number(seleccionado?.comisiones) + Number(seleccionado?.ajusteSueldo) + Number(seleccionado?.bonificacion) + Number(seleccionado?.bonos) + Number(seleccionado?.sueldo) + Number(seleccionado?.vacaciones) + Number(seleccionado?.acumularFondosReserva) + Number(seleccionado?.decimoTercero) + Number(seleccionado?.decimoCuarto),
      IESS: Number(seleccionado?.iess) ?? 0,
      Subsidy: Number(seleccionado?.subsidioEnfermedad) + Number(seleccionado?.subsidioMaternidad),
      Loans: Number(seleccionado?.prestamos) ?? 0,
      Advances: Number(seleccionado?.anticipos) ?? 0,
      UnsecuredLoans: Number(seleccionado?.prestamosQuirografarios) ?? 0,
      MortgageLoans: Number(seleccionado?.prestamosHipotecarios) ?? 0,
      Discounts: Number(seleccionado?.descuentos) ?? 0,
      Income: Number(seleccionado?.renta) ?? 0,
      Arrears: Number(seleccionado?.atrasos) ?? 0,
      Fines: Number(seleccionado?.multas),
      Others: Number(seleccionado?.reemplazo) ?? 0,
      Total2: Number(seleccionado?.iess) + Number(seleccionado?.prestamos) + Number(seleccionado?.prestamosQuirografarios) + Number(seleccionado?.prestamosHipotecarios) + Number(seleccionado?.anticipos) + Number(seleccionado?.descuentos) + Number(seleccionado?.renta) + Number(seleccionado?.multas) + Number(seleccionado?.atrasos) + Number(seleccionado?.reemplazo) + Number(seleccionado?.subsidioMaternidad) + Number(seleccionado?.subsidioEnfermedad),
      Total3: tot5,
      Observations: seleccionado?.observaciones ?? '',
    })
    consoleService.log(registros)
    return registros
  }, [seleccionado, empresa])

  const onConfirmPrint = React.useCallback((mode: null | "Viewer" | "Designer") => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `Seleccione el tipo de impresión:`,
      actions: [
        Dialog.Action(
          <span>
            <u>T</u>odos
          </span>,
          (dialog) => {
            dialog.hide()
            if (currentTab === SearchTabs.rol) {
              setTemplateName('StaticAllRolsList')
              setTemplateType('1')
              setReporte(mode)
            } else {
              setTemplateName('StaticRolsList')
              onGetDataPrint(mode)
            }
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>P</u>or Empleado
          </span>,
          (dialog) => {
            dialog.hide()
            if (currentTab === SearchTabs.rol) {
              if (seleccionado !== null && seleccionado?.codigo) {
                setTemplateName('StaticPaymentsRols')
                setTemplateType('2')
                setReporte(mode)
              }
            }
          },
          'btn-warning',
          'c',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    return
  }, [seleccionado, currentTab, onGetDataPrint])


  const handleButtonClick = useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.find:
          if (tabs.current === tabId) {
            onResetPag()
            onFind()
          }
          break
        case ButtonTypes.import:
          if (tabs.current === tabId) dispatch(setImport(true))
          break
        case ButtonTypes.delete:
          if (tabs.current === tabId) onConfirmDelete()
          break
        case ButtonTypes.edit:
          if (tabs.current === tabId) onConfirmEdit()
          break
        case ButtonTypes.save:
          if (tabs.current === tabId) onSaveList()
          break
        case ButtonTypes.undo:
          if (tabs.current === tabId) onUndo()
          break
        case ButtonTypes.broom:
          if (tabs.current === tabId) onBroom()
          break
        case ButtonTypes.payments:
          if (tabs.current === tabId) onPayment()
          break
        case ButtonTypes.print:
          if (tabs.current === tabId) onConfirmPrint('Viewer')
          break
        case ButtonTypes.print_design:
          if (tabs.current === tabId) onConfirmPrint('Designer')
          break
        default:
          break
      }
      dispatch(clearButtonClick(tabId))
    }, [dispatch, tabId, tabs, onFind, onResetPag, onConfirmDelete, onSaveList, onUndo, onBroom, onPayment, onConfirmEdit, onConfirmPrint])


  useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab.globalButtonClick])

  React.useEffect(() => {
    if (searchRols) setDataSourceRols(DataSourceRolsPays)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onTotal = useCallback(() => {
    let totales = 0
    const provider = dataSourceRols?.items() ?? []
    provider.forEach(x => {
      totales += x?.sueldoMes ?? 0
    })
    return totales
  }, [dataSourceRols])

  const openTabToEdit = useCallback((selected: any) => {
    const id = uuidv4()
    dispatch(
      initDatosEdicion({
        key: id,
        data: {
          ...defaultRolesPagoEdicion,
          loading: true,
        },
      }),
    )
    const data: any = {
      ...selected,
      empleado: selected?.nombresCompletos
    }
    dispatch(openTab({ key: id, store: data }))
  }, [dispatch])

  const onPay = useCallback(async () => {
    consoleService.log(dataSourceRols?.items())
    const provider = dataSourceRols?.items().slice(0) ?? []
    try {
      provider.forEach(x => {
        x.fechaCobro = filter?.opcionFecha
        if (x?.codigo !== 0) {
          x.fPago = filter?.opcionFormaPago?.codigo
        }
      })
      const data = await RolsPaymentsServices.savePayRes(provider)
      consoleService.log(data, 'data pay res')
      if (data?.auto && data?.error === false) {
        setToast(data?.message, ToastTypes.Success)
      } else {
        setToast(data?.message, ToastTypes.Danger)
      }
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
  }, [dataSourceRols, filter, setToast])

  const onHandleDelete = useCallback(async () => {
    onSetButtonAction(ButtonTypes.delete)
    onLoader(true, 'Eliminando...')
    try {
      const data = await RolsPaymentsServices.deleteRolPay(seleccionado?.codigo ?? 0, seleccionado?.mes ?? '', seleccionado?.anio ?? 0)
      consoleService.log(data, 'delete data')
      if (data?.auto && data?.error === false) {
        setToast(data?.message, ToastTypes.Success)
        dispatch(setResetSeleccion())
        DataSourceRolsPays.reload()
        setDataSourceRols(DataSourceRolsPays)
      } else {
        setToast(data?.message, ToastTypes.Danger)
      }
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
    onLoader(false, '')
    onSetButtonAction(undefined)
  }, [seleccionado, setToast, onLoader, onSetButtonAction, DataSourceRolsPays, dispatch])

  const onHandleConfirm = useCallback(() => {
    if (popupMsgConfig.type === 'confirm') {
      if (popupMsgConfig.currentAction === ButtonTypes.edit) openTabToEdit(seleccionado)
      else if (popupMsgConfig.currentAction === ButtonTypes.delete)
        onHandleDelete()
      else if (popupMsgConfig.currentAction === ButtonTypes.payments)
        onPay()
    }
    setPopupMsgConfig(defaultPopupMsgConfig)
  }, [onHandleDelete, openTabToEdit, popupMsgConfig, seleccionado, onPay])

  const onHandleCancel = useCallback(() => {
    setPopupMsgConfig(defaultPopupMsgConfig)
  }, [])

  React.useEffect(() => {
    dispatch(
      setButtons({
        tabKey: 'BUSQUEDA',
        buttons: {
          Nuevo: true,
          Guardar: dataSourceRols !== null,
          Editar: seleccionado !== null && seleccionado !== undefined,
          Eliminar: seleccionado !== null && seleccionado !== undefined,
          Buscar: true,
          Imprimir: dataSourceRols !== null,
          Cheque: false,
          Ver_asiento: false,
          Transferencias: true,
          Deshacer: true,
          Pagos: false,
          Sueldos: true,
          Todos: true,
          Roles: true,
          Limpiar: dataSourceRols !== null,
          Importar: true,
        },
      }),
    )
  }, [dispatch, seleccionado, dataSourceRols])

  const onParseData = useCallback((res: Array<RolesPayList>) => {
    const registros: Array<RolsListDto> = []

    consoleService.log(res, 'res print')

    if (res.length > 0) {
      res.forEach(x => {
        let mes = ''
        let tot3 = 0
        if (Number(x?.acumularFondosReserva) === 0) {
          tot3 = Number(x?.sueldo) + Number(x?.jornada) + Number(x?.valorHoraSuplementarias) + Number(x?.valorHoraExtra) + Number(x?.comisiones) + Number(x?.ajusteSueldo) + Number(x?.bonos) + Number(x?.fondosReserva) + Number(x?.bonificacion)
            - (Number(x?.iess) + Number(x?.prestamos) + Number(x?.prestamosQuirografarios) + Number(x?.prestamosHipotecarios) + Number(x?.anticipos) + Number(x?.descuentos) + Number(x?.renta) + Number(x?.atrasos) + Number(x?.multas) + Number(x?.reemplazo) + Number(x?.subsidioMaternidad) + Number(x?.subsidioEnfermedad))
        } else {
          tot3 = Number(x?.sueldo) + Number(x?.jornada) + Number(x?.valorHoraSuplementarias) + Number(x?.valorHoraExtra) + Number(x?.comisiones) + Number(x?.ajusteSueldo) + Number(x?.bonos) + Number(x?.bonificacion)
            - (Number(x?.iess) + Number(x?.prestamos) + Number(x?.prestamosQuirografarios) + Number(x?.prestamosHipotecarios) + Number(x?.anticipos) + Number(x?.descuentos) + Number(x?.renta) + Number(x?.atrasos) + Number(x?.multas) + Number(x?.reemplazo) + Number(x?.subsidioMaternidad) + Number(x?.subsidioEnfermedad))
        }
        MesesData.forEach(j => {
          if (j?.value === x?.mes) {
            mes = j.label
          }
        })
        registros.push({
          Month: mes,
          Employee: `${x?.nombresCompletos ?? ''}(${x?.cedula ?? ''})`,
          Nominal: Number(x?.nominal ?? '0'), //
          Salary2: Number(x?.sueldo ?? '0'), //
          Vjournal: Number(x?.jornada) ?? 0,
          VSuple: Number(x?.valorHoraSuplementarias) ?? 0,
          VExtra: Number(x?.valorHoraExtra) ?? 0,
          Commission: Number(x?.comisiones) ?? 0,
          SalaryAdjustment: Number(x?.ajusteSueldo) ?? 0,
          Faults: Number(x?.nominal ?? '0') - Number(x?.sueldo ?? '0'), //
          Base: Number(x?.sueldo) + Number(x?.jornada) + Number(x?.valorHoraSuplementarias) + Number(x?.valorHoraExtra) + Number(x?.comisiones) + Number(x?.ajusteSueldo),
          Bonuses: Number(x?.bonificacion) ?? 0,
          Booking: Number(x?.fondosReserva ?? '0'),
          Bonus: Number(x?.bonos) ?? 0,
          IESS: Number(x?.iess ?? '0'),//
          Loans: Number(x?.prestamos) ?? 0,
          Advances: Number(x?.anticipos) ?? 0,
          UnsecuredLoans: Number(x?.prestamosQuirografarios) ?? 0,
          MortgageLoans: Number(x?.prestamosHipotecarios) ?? 0,
          Discounts: Number(x?.descuentos) ?? 0,
          Income: Number(x?.renta) ?? 0,
          Fines: Number(x?.multas) ?? 0,
          Others: Number(x?.reemplazo) ?? 0,
          Total3: tot3,
        })
      })
    }

    consoleService.log(registros)
    return registros
  }, [])


  if (isMobile) {
    return (
      <>
        <Dialog ref={dialogRef} />
        <ReportWithLocalDataModal
          show={reporte !== null}
          onClose={() => setReporte(null)}
          data={currentTab === SearchTabs.rol ? templateType === "1" ? onParseDataRolsAll() : onParseDataRols() : onParseData(resultadoImprimir ?? [])}
          fileName={`${templateName}`}
          mode={reporte ?? 'Viewer'}
          parameters={{ 'Reporte_Filtro': '' }}
          template={`${templateName}`}
          key='reportDesigner'
        />
        <ConfirmarAccionMsj
          title={popupMsgConfig.title}
          isVisible={popupMsgConfig.show}
          handleConfirm={onHandleConfirm}
          handleCancel={onHandleCancel}
          message={popupMsgConfig.message}
          typeMessage={popupMsgConfig.type}
          typeInputInfo={null}
        />
        <CCard>
          {vistaActual === VistasBusqueda.Filtros && (
            <>
              <FiltrosBusqueda tab={tab} tabId={tabId} onSearchEnter={onFind} />
            </>
          )}
          {vistaActual === VistasBusqueda.ResultadosBusqueda && (
            <>
              <CNav variant="tabs" role="tablist" className="mt-4">
                <CNav variant="tabs">
                  {tabsNames.map((tab, index) => (
                    <CNavItem key={index}>
                      <CNavLink
                        onClick={() => {
                          setCurrentTab(tab)
                        }}
                        active={currentTab === tab}
                      >
                        {tab}
                      </CNavLink>
                    </CNavItem>
                  ))}
                </CNav>
                <CTabContent
                  style={{
                    overflowY: 'unset',
                    width: '100%',
                    marginBottom: '10px',
                  }}
                >
                  <CTabPane
                    role="tabpanel"
                    aria-labelledby="generales-tab"
                    visible={currentTab === tabsNames[0]}
                  >
                    <ResultadosBusqueda
                      onDBClick={onConfirmEdit}
                      data={dataSourceRols}
                      handleOptionChange={handleOptionChange}
                      pageIndex={pageIndex}
                      pageSize={pageSize}
                      onDelete={onConfirmDelete}
                      onPrint={() => {
                        if (seleccionado !== null && seleccionado?.codigo) {
                          setTemplateName('StaticPaymentsRols')
                          setTemplateType('2')
                          setReporte('Viewer')
                        }
                      }}
                    />
                    <RowContainer>
                      <CustomCol md="10" className="d-flex justify-content-end">
                        <strong> {'Totales:'} </strong>
                      </CustomCol>

                      <CustomCol md="2">
                        <NumberBox
                          readOnly
                          width="100%"
                          value={onTotal()}
                          placeholder=""
                          format="#,##0.00"
                        />
                      </CustomCol>
                    </RowContainer>
                  </CTabPane>
                  <CTabPane
                    role="tabpanel"
                    aria-labelledby="generales-tab"
                    visible={currentTab === tabsNames[1]}
                  >
                    <ResHistorialBusqueda
                      onDBClick={onConfirmEdit}
                      data={dataSourceRols}
                      handleOptionChange={handleOptionChange}
                      pageIndex={pageIndex}
                      pageSize={pageSize}
                      onDelete={onConfirmDelete}
                    />
                    <RowContainer>
                      <CustomCol md="10" className="d-flex justify-content-end">
                        <strong> {'Totales:'} </strong>
                      </CustomCol>

                      <CustomCol md="2">
                        <NumberBox
                          readOnly
                          width="100%"
                          value={onTotal()}
                          placeholder=""
                          format="#,##0.00"
                        />
                      </CustomCol>
                    </RowContainer>
                  </CTabPane>

                </CTabContent>
              </CNav>
            </>
          )}
          {vistaActual === VistasBusqueda.Error && <></>}
          {vistaActual === VistasBusqueda.Loading && (
            <CCardFooter>
              <TableLoader />
            </CCardFooter>
          )}
          {currentabs === tabId && (
            <Draggable id="list" data="dropArea" group={draggingGroupName}>
              <SpeedDialAction
                icon="filter"
                label="Filtros"
                visible={true}
                index={1}
                onClick={() => {
                  setVistaActual(VistasBusqueda.Filtros)
                }}
              />
              <SpeedDialAction
                icon="search"
                label="Busqueda"
                visible={true}
                index={2}
                onClick={() => {
                  setVistaActual(VistasBusqueda.ResultadosBusqueda)
                }}
              />
            </Draggable>
          )}
        </CCard>
      </>
    )
  }

  return (
    <>
      <Dialog ref={dialogRef} />
      <ReportWithLocalDataModal
        show={reporte !== null}
        onClose={() => setReporte(null)}
        data={currentTab === SearchTabs.rol ? templateType === "1" ? onParseDataRolsAll() : onParseDataRols() : onParseData(resultadoImprimir ?? [])}
        fileName={`${templateName}`}
        mode={reporte ?? 'Viewer'}
        parameters={{ 'Reporte_Filtro': '' }}
        template={`${templateName}`}
        key='reportDesigner'
      />
      <ConfirmarAccionMsj
        title={popupMsgConfig.title}
        isVisible={popupMsgConfig.show}
        handleConfirm={onHandleConfirm}
        handleCancel={onHandleCancel}
        message={popupMsgConfig.message}
        typeMessage={popupMsgConfig.type}
        typeInputInfo={null}
      />
      {showErrorPopup && (
        <VisualizaError
          titulo="Error en Roles de Pago"
          mensaje={'Error al pagar'}
          content={
            <ListErrosValidationForm
              mainMessage={'Revise los siguientes errores:'}
              errorsList={arrayErrors}
              color={'danger'}
            />
          }
          onOk={() => setShowErrorPopup(false)}
        />
      )}
      <div id="PersonalContent">
        <BlockUi
          tag="div"
          loader={LoadingIndicator}
          blocking={loader.show}
          message={loader.mensaje}
        >
          <CCard>
            <FiltrosBusqueda tab={tab} tabId={tabId} onSearchEnter={onFind} />
            <CNav variant="tabs" role="tablist" className="mt-4">
              <CNav variant="tabs">
                {tabsNames.map((tab, index) => (
                  <CNavItem key={index}>
                    <CNavLink
                      onClick={() => {
                        setCurrentTab(tab)
                      }}
                      active={currentTab === tab}
                    >
                      {tab}
                    </CNavLink>
                  </CNavItem>
                ))}
              </CNav>
              <CTabContent
                style={{
                  overflowY: 'unset',
                  width: '100%',
                  marginBottom: '10px',
                }}
              >
                <CTabPane
                  role="tabpanel"
                  aria-labelledby="generales-tab"
                  visible={currentTab === tabsNames[0]}
                >
                  <ResultadosBusqueda
                    onDBClick={onConfirmEdit}
                    data={dataSourceRols}
                    handleOptionChange={handleOptionChange}
                    pageIndex={pageIndex}
                    pageSize={pageSize}
                    onDelete={onConfirmDelete}
                    onPrint={() => {
                      if (seleccionado !== null && seleccionado?.codigo) {
                        setTemplateName('StaticPaymentsRols')
                        setTemplateType('2')
                        setReporte('Viewer')
                      }
                    }}
                  />
                  <RowContainer>
                    <CustomCol md="10" className="d-flex justify-content-end">
                      <strong> {'Totales:'} </strong>
                    </CustomCol>

                    <CustomCol md="2">
                      <NumberBox
                        readOnly
                        width="100%"
                        value={onTotal()}
                        placeholder=""
                        format="#,##0.00"
                      />
                    </CustomCol>
                  </RowContainer>
                </CTabPane>
                <CTabPane
                  role="tabpanel"
                  aria-labelledby="generales-tab"
                  visible={currentTab === tabsNames[1]}
                >
                  <ResHistorialBusqueda
                    onDBClick={onConfirmEdit}
                    data={dataSourceRols}
                    handleOptionChange={handleOptionChange}
                    pageIndex={pageIndex}
                    pageSize={pageSize}
                    onDelete={onConfirmDelete}
                  />
                  <RowContainer>
                    <CustomCol md="10" className="d-flex justify-content-end">
                      <strong> {'Totales:'} </strong>
                    </CustomCol>

                    <CustomCol md="2">
                      <NumberBox
                        readOnly
                        width="100%"
                        value={onTotal()}
                        placeholder=""
                        format="#,##0.00"
                      />
                    </CustomCol>
                  </RowContainer>
                </CTabPane>

              </CTabContent>
            </CNav>

          </CCard>
        </BlockUi>
      </div>
    </>
  )
}

export default React.memo(Buscar)