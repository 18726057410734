import { RequestHelper } from "../../../helpers/requestHelper";
import { AnexoTransacListado, FiltroBusquedaAnexoTransaccionalState, TAnexoIngresar } from "../pages/anexoTransaccional/types/types";

export const anexoTransaccionalService = {
  getAnexoTransac, getSustentos, ingresarAnexo, asignarSustento, obtenerXml
}

async function getAnexoTransac(filtro: FiltroBusquedaAnexoTransaccionalState) {
  const data: any = {
  };

  if (filtro.tipoPeriodo) {
    data.tipoAnexo = String(filtro.tipoPeriodo).toLowerCase();
  }

  if (filtro.anio) {
    data.anio = filtro.anio.value
  }

  if (filtro.mes) {
    data.mes = filtro.mes.codmes
  }

  if (filtro.periodo) {
    data.semestre = filtro.periodo.value
  }

  if (filtro.incluirElectronicos) {
    data.electronicos = filtro.incluirElectronicos.value
  }

  if (filtro.tipoValidacion) {
  }
  const anexos = await RequestHelper.get<Array<AnexoTransacListado>>('compras/compras', 'getDataRetention', "", data);
  return anexos;
}

async function getSustentos(tipoComprobante: string) {
  const data: any = {
    all: true
  };

  const sustentos = await RequestHelper.get<Array<any>>('generales', 'sustentosVouchers/getLivelihoods', "", data);
  return sustentos;
}

async function ingresarAnexo(anexo: TAnexoIngresar): Promise<Promise<any>> {
  try {
    const infoRegistro = {
      infoRegistro: anexo
    }
    const creada = await RequestHelper.postAll<any>("impuestos", "generaXmlAts", infoRegistro);
    return creada;
  } catch (error) {
    return error;
  }
}

async function asignarSustento(asignacion): Promise<Promise<any>> {
  try {
    const data: any = {
      ...asignacion
    };

    const sustentos = await RequestHelper.get<Array<any>>('impuestos', 'asignarSustento', "", data);
    return sustentos;
  } catch (error) {
    return error;
  }
}

async function obtenerXml(filtros): Promise<Blob> {
  try {
    const queryData = {
      ...filtros
    }
    const blob = await RequestHelper.getFile('impuestos', 'obtenerATS', '', queryData, { type: 'application/xml' });
    return blob;
  } catch (error) {
    throw error;
  }

}