import { DataGrid } from 'devextreme-react';
import { Column, FilterRow, HeaderFilter, Pager, Paging, Button as DatagridButton } from 'devextreme-react/data-grid';
import React from 'react';
import { ToastTypes } from '../../../../../../../store/types';
import CustomCol from '../../../../../../../views/componentes/colContainer';
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer';

interface IResultadoBusquedaProps extends React.PropsWithChildren {
	funcion: string,
	data: any,
	setToast: (sms: string, type: ToastTypes) => void,
	capturaSeleccion: (data) => void,
	onChanged: (data) => void;
}

const ResultadoBusqueda: React.FunctionComponent<IResultadoBusquedaProps> = (props) => {

	const { capturaSeleccion, data, onChanged } = props
	const [items, setItems] = React.useState<Array<any> | []>(data)
	const tablaRef = React.useRef<any>()
	const tablaRefPager = React.useRef<any>()

	const selectionChanged = React.useCallback((selectionChanged) => {

		if (items.length > 0) {
			if (selectionChanged['selectedRowsData'].length > 0) {

				const filt: number = items.findIndex((item: any) => {
					return Number(item.codigo) === Number(selectionChanged['selectedRowsData'][0]['codigo']);
				});

				capturaSeleccion(
					{
						index: filt,
						row: selectionChanged['selectedRowsData'][0],
						selectedRow: filt
					}
				)

			}
		}
	}, [items, capturaSeleccion]);



	React.useEffect(() => {
		setItems(data)
	}, [data])

	return (
		<>
			<RowContainer className='mt-2 '>
				<CustomCol>
					<DataGrid
						ref={tablaRef}
						selection={{ mode: 'single' }}
						className='border'
						dataSource={items}
						onSelectionChanged={selectionChanged}
						width={'100%'}
						keyExpr=''
					>
						<FilterRow visible={true} />
						<HeaderFilter visible={true} />
						<Paging
							enabled={true}
							defaultPageSize={10}
						/>
						<Pager
							ref={tablaRefPager}
							showPageSizeSelector={true}
							allowedPageSizes={[8, 12, 20]}
							showNavigationButtons={true}
							showInfo={true}
							infoText="Page #{0}. Total: {1} ({2} items)"
						/>
						<Column type='buttons' width='40px' >
							<DatagridButton icon='arrowleft' visible={true} hint='Seleccionar'
								onClick={(e) => {
									onChanged(e.row.data)
								}} />
						</Column>
						<Column dataField='particular' caption='CODIGO' width='120px' allowEditing={false} />
						<Column dataField='descripcion' caption='DESCRIPCION' width='120px' allowEditing={false} />
						<Column dataField='estadoNombre' caption='ESTADO' width='120px' allowEditing={false} />
					</DataGrid>
				</CustomCol>

			</RowContainer>
		</>
	);
}
export default ResultadoBusqueda;

