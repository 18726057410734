import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MenuState } from '../../../../../../ventas/types/types';
import { RootState } from '../../../../../../../store/store';
import { TabsState } from '../../../../../../../store/genericTabTypes';
import { addToast } from '../../../../../../../store/toasterReducer';
import { AccionMenu, ConfigPopupMsg, ToastTypes } from '../../../../../../../store/types';
import { StatesEdition } from '../../../../../../../store/enums';
import Dialog from '../../../../../../../views/componentes/librerias/bootstrap-dialog';
import { menuService } from '../../../../../../../services/menu.service';
import { ButtonTypes } from '../../../../../../../views/componentes/globalMenu/types';
import { changeLoader } from '../../../../../../../store/reducers';
import { UrlHelper } from '../../../../../../../helpers/urlHelper';
import { GlobalMenu } from '../../../../../../../views/componentes/globalMenu/globalMenu';
import { Aplicacion, defaultPopupMsgConfig } from '../../../../../store/types';
import TabHorariosControl from './tabs/tabs';
import ConfirmarAccionMsj from '../../../../../../componentes/confirmarAccionMsj';

// const pathVerificador = 'ingresos/egresos'

interface IRolesPagoMainProps extends React.PropsWithChildren {
  onActionsButtonsLoaded: (acciones: Array<AccionMenu>) => void;
  codigoModulo: number,
  opciones: Array<number>,
  menu: MenuState,
  tabs: TabsState<any>,
  onTabChanged: (tabKey: string) => void,
  onMenuButtonClick: (accion: AccionMenu, tab: string) => void,
  searching: boolean,
  ontabClosing: (tabKey: string) => void,
  hayResultadosBusqueda: boolean,
  verificarPuntoVenta: boolean,
  verificarAutorizacion: boolean,
  onExecutingAction?: (tabKey: string, type?: ButtonTypes) => void,
  tipoAplicacion: Aplicacion
}
const position = { of: '#mainContent' };

function RolesPagoMain(props: IRolesPagoMainProps) {

  const urls = UrlHelper.getUrls();

  const { codigoModulo, opciones, onActionsButtonsLoaded, menu,
    ontabClosing, onTabChanged, onMenuButtonClick, tabs,
    tipoAplicacion, verificarPuntoVenta, onExecutingAction
  } = props;

  const dispatch = useDispatch();
  const puntoVenta = useSelector((state: RootState) => { return state.global.puntoVenta });
  const localState = useSelector((state: RootState) => { return state.global.session?.local });
  const menuState = useSelector((state: RootState) => state.nomina.rol.rolesPago.menu)

  const [configurarPV, setConfigurarPv] = React.useState(false);
  const dialogRef = React.useRef<any>(null);
  const [habilitarDisenador, setHabilitarDisenador] = React.useState<boolean>(true);
  const [popupMsgConfig, setPopupMsgConfig] = React.useState<ConfigPopupMsg>(
    defaultPopupMsgConfig,
  )

  const onParseButtonsLoaded = useCallback((accionesMenu: Array<AccionMenu>) => {
    const opt: Array<AccionMenu> = []
    const titles = {
      'Transferencias': 'Transferencia Bancaria',
      'Cheque': 'Emitir Cheque',
      'Todos': 'Generar Roles de Pago',
      'Roles': 'Roles de Pago',
      'Adjuntar': 'Generar archivo plano',
      'Sueldos': 'Pagar',
      'Pagos': 'Otros'
    };
    accionesMenu?.forEach(item => {
      let titleButton: string = ''
      let typeButton = item['name'] ?? 'none'
      if (item['name'].includes(' ')) {
        typeButton = item['name'].replace(' ', '_') ?? 'none'
      }
      if (titles?.hasOwnProperty(item['name'])) {
        titleButton = titles[item['name']];
      }

      if (titleButton === '') {
        titleButton = typeButton
      }
      opt.push({
        actionType: typeButton,
        icon: item['icon'],
        main: Number(item['main']) === 1 ? true : false,
        modal: Number(item['modal']) === 1 ? true : false,
        nombre: titleButton ?? '',
        shortcut: item['shortcut'],
      })
    })
    onActionsButtonsLoaded(opt);
  }, [onActionsButtonsLoaded])

  const getVerificaPath = React.useCallback(async () => {
    if (urls.origin.includes("acatha.io")) {
      setHabilitarDisenador(false);
    } else {
      setHabilitarDisenador(true);
    }

  }, [urls]);

  const cargarAccionesMenu = React.useCallback(async () => {
    try {

      const acciones = await menuService.cargarAccionesUsuario(codigoModulo, opciones);
      console.log(acciones, acciones)
      onParseButtonsLoaded(acciones)
    } catch (error) {
      dispatch(addToast({
        content: error,
        title: 'Cargar opciones',
        type: ToastTypes.Danger
      }));
    }
  }, [dispatch, codigoModulo, opciones, onParseButtonsLoaded])



  const handleButtonClick = React.useCallback(async (accion: AccionMenu) => {
    if (accion.actionType === ButtonTypes.new) {
      try {
        if (onExecutingAction) {
          onExecutingAction("BUSQUEDA", ButtonTypes.new);
        }
        if (verificarPuntoVenta && puntoVenta == null) {
          setConfigurarPv(true);
          return;
        }

        const currentTab = tabs.tabs[tabs.current];
        if (currentTab.editStatus === StatesEdition.new || currentTab.editStatus === StatesEdition.save) {
          setPopupMsgConfig({
            show: true,
            title: 'Ácatha',
            message: 'Desea crear un nuevo registro?',
            type: 'newTab',
          })
          return;
        }
      } catch (error) {

      }
      finally {
        if (onExecutingAction) {
          onExecutingAction("BUSQUEDA");
        }
      }

    }
    onMenuButtonClick(accion, "");
  }, [puntoVenta, onMenuButtonClick, onExecutingAction, verificarPuntoVenta, tabs

  ]);



  const getButtonDisabled = React.useCallback((accion: AccionMenu) => {
    const currentTab = tabs.tabs[tabs.current];
    if (!currentTab) return true;
    return !currentTab.buttons[accion.actionType] ?? true;
  }, [tabs]);

  const onHandleConfirm = React.useCallback(() => {
    if (popupMsgConfig.type === 'newTab') {

      onMenuButtonClick({ actionType: ButtonTypes.new, icon: '', main: false, modal: false, nombre: 'Nuevo', shortcut: null }, tabs.current);
    }
    setPopupMsgConfig(defaultPopupMsgConfig)
  }, [onMenuButtonClick, popupMsgConfig, tabs])



  const onHandleCancel = React.useCallback(() => {
    onMenuButtonClick({ actionType: ButtonTypes.broom, icon: '', main: false, modal: false, nombre: 'Limpiar', shortcut: null }, tabs.current);
    setPopupMsgConfig(defaultPopupMsgConfig)
  }, [onMenuButtonClick, tabs])



  React.useEffect(() => {
    if (menu && menu.accions.length === 0) {
      cargarAccionesMenu();
    } else {
      onParseButtonsLoaded(menu?.accions)
    }
  }, [menu, cargarAccionesMenu, onParseButtonsLoaded]);

  React.useEffect(() => {
    if (menu.loading) {
      dispatch(changeLoader({ show: true, mensaje: 'Cargando opciones....' }))
    } else {
      dispatch(changeLoader({ show: false, mensaje: '' }))
    }
  }, [dispatch, menu]);

  React.useEffect(() => {
    getVerificaPath()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (props.menu.loading) {
    return null;
  }

  return (
    <div style={{ backgroundColor: '#fff' }}  >
      <ConfirmarAccionMsj
        title={popupMsgConfig.title}
        isVisible={popupMsgConfig.show}
        handleConfirm={onHandleConfirm}
        handleCancel={onHandleCancel}
        message={popupMsgConfig.message}
        typeMessage={popupMsgConfig.type}
        typeInputInfo={null}
      />
      <Dialog ref={dialogRef} />
      <GlobalMenu printButtonAsDropdown={habilitarDisenador} currentWorking={tabs.tabs[tabs.current].currentExecutingAction} showExportDesingOptions acciones={menuState.accions} onClick={handleButtonClick} getButtonDisabled={getButtonDisabled} />

      <TabHorariosControl
        tabsState={tabs}
        onTabChanged={onTabChanged}
        onTabClosing={ontabClosing}
        tipoAplicacion={tipoAplicacion}
      />
    </div>
  );
}
RolesPagoMain.whyDidYouRender = true
export default React.memo(RolesPagoMain);