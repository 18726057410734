import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ChangesIncomesExpenses, GlobalChanges } from './types'

const initialState: GlobalChanges = {
  incomesExpenses: [],
}

const globalChanges = createSlice({
  name: 'globalChangesInventory',
  initialState: initialState,
  reducers: {
    setChanges(state, action: PayloadAction<ChangesIncomesExpenses>) {
      state.incomesExpenses = action.payload
    },
    clearChanges(state) {
      state.incomesExpenses = []
    },
  },
})

export const { setChanges, clearChanges } = globalChanges.actions

export const globalChangesReducer = globalChanges.reducer
