import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../../store/store'
import { FETCH_STATUS } from '../../../../../store/types'
import {
  PerformaceDetail,
  FiltrosDesemepnoState,
  SearchState,
} from '../types/types'
import { performanceServices } from '../services/desempeno.services'

const initialState: SearchState<PerformaceDetail> = {
  filter: {
    nombre: '',
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  resultados: [],
}

export const fetchPerformances = createAsyncThunk<
  Array<PerformaceDetail>,
  FiltrosDesemepnoState
>('clientes/desempeno/listar', async (filtro) => {
  try {
    const performanceList = performanceServices.getPerformance(filtro.nombre)
    return performanceList
  } catch (error) {
    return Promise.reject(error)
  }
})

const searchSlice = createSlice({
  name: 'searchPerformance',
  initialState: initialState,
  reducers: {
    setNombre(state, action: PayloadAction<string>) {
      state.filter.nombre = action.payload
    },
    setCleanResult(state) {
      state.resultados = []
    },
    changeFilter(state, action: PayloadAction<FiltrosDesemepnoState>) {
      state.filter = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPerformances.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchPerformances.fulfilled, (state, { payload }) => {
      state.resultados = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchPerformances.rejected, (state, { payload }) => {
      state.resultados = []
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
  },
})

export const selectFiltroBusquedaClientes = (state: RootState) => {
  return state.clientes.desempeno.search.filter
}

export const selectResultadosBusquedaClientes = (state: RootState) => {
  return state.clientes.desempeno.search.resultados
}

export const { setNombre, changeFilter, setCleanResult } = searchSlice.actions

export const searchReducer = searchSlice.reducer
