import React from 'react'
import { isMobile } from 'react-device-detect'
import {
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
} from '@coreui/react-pro'
import { Aplicacion } from '../../../../../../store/types'
import { TabTypes } from '../../../../../../../../store/enums'
import TabNavTitle from '../../../../../../../componentes/tituloNavTab'
import { useActiveTabChange, useHanldeOnTabClosing } from '../../../../../../../../hooks/useNavigationTabControls'
import { TabsState } from '../../../../../../../../store/genericTabTypes'
import Search from '../../buscar'
import Nuevo from '../../nuevo'

interface ITabRegistrosProps extends React.PropsWithChildren {
  tabsState: TabsState<any>,
  onTabChanged: (tabKey: string) => void,
  onTabClosing: (tabKey: string) => void,
  tipoAplicacion: Aplicacion
}

const TabControlHorarios: React.FunctionComponent<ITabRegistrosProps> = (props) => {
  const { onTabChanged, onTabClosing, tipoAplicacion } = props;

  const tabs = props.tabsState.tabs
  const onActiveTabChange = useActiveTabChange(tabs, onTabChanged)
  const hanldeOnTabClosing = useHanldeOnTabClosing(tabs, onTabClosing)

  return (
    <div
      className="container-fluid"
      style={isMobile ? { paddingTop: '10px' } : undefined}
    >
      <CNav variant="tabs" role="tablist">
        {Object.keys(tabs).map((key, index) => {
          const c = tabs[key]
          return (
            <CNavItem key={`${c.type}${c.tabKey}`}>
              <CNavLink
                active={key === props.tabsState.current}
                onClick={() => onActiveTabChange(index)}
              >
                <TabNavTitle
                  tab={c}
                  tabIndex={index}
                  ontabClosing={hanldeOnTabClosing}
                  current={key === props.tabsState.current}
                />
              </CNavLink>
            </CNavItem>
          )
        })}
      </CNav>
      <CTabContent style={{ overflowY: 'unset' }}>
        {Object.keys(tabs).map((key) => {
          const c = tabs[key]
          return (
            <CTabPane
              key={`${c.type}${c.tabKey}content`}
              visible={key === props.tabsState.current}
            >
              {c.type === TabTypes.busqueda && (
                <div key="serarchComponent" id="mainContent">
                  {tipoAplicacion === Aplicacion.horarios && (
                    <Search tab={c} tabId={c.tabKey} />
                  )}
                </div>
              )}
              {c.type === TabTypes.documento && (
                <div key="newComponent" id="mainContent">
                  {tipoAplicacion === Aplicacion.horarios && (
                    <Nuevo
                      info={{
                        codigo: c?.info ? c.info.codigo : 0,
                        info: c.info.info
                      }}
                      tab={c} tabId={c.tabKey}
                    />
                  )}
                </div>
              )}
            </CTabPane>
          )
        })}
      </CTabContent>
    </div>
  )
}
export default React.memo(TabControlHorarios)
