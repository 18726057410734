import {combineReducers} from "redux";
import {TFormaPago} from "./types";
import {menuReducer} from "./menu.reducer";
import {tabsReducer} from "./tabs.reducer";
import {dataEditionReducer} from "./edit.reducer";
import {searchReducer} from "./search.reducer";

export const CatalogosFormaPagoReducer = combineReducers<TFormaPago>({
  menu: menuReducer,
  tabs: tabsReducer,
  editData: dataEditionReducer,
  search: searchReducer,
})
