import { DetallePedido, DetallePedidoRedux } from "./detallePedido";

export const PedidoHelper = {
  detallesToDetallesPedido
}


function detallesToDetallesPedido(detalles: Array<any>): Array<DetallePedidoRedux> {
  return detalles.map(det => {
    const detalle: DetallePedidoRedux = {
      codigo: det.codigo,
      codigoInterno: det.codigoInterno,
      codigoBarras: det.codigoBarras,
      descripcion: det.descripcion,
      imagenes: det.itemImagen,
      fecha: '',
      _subtotal: det.detallePrecio,
      _subtotal0: 0,
      _subtotalIva: det.detalleCantidad * det.detallePrecio,
      _total: 0,
      _cantidad: det.detalleCantidad,
      _precioUnitario: det.detallePrecio,
      _descuento: 0,
      _porcentajeDescuento: 0,
    }
    const _detalle: DetallePedido = new DetallePedido();
    _detalle.setDetalle(detalle);

    return _detalle.getDetalle();
  })
}