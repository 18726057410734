// Importación de polyfills y dependencias básicas
import 'react-app-polyfill/stable'
import 'core-js'
import './env' // Asegúrate de que el archivo exista y esté correctamente configurado.
import './styles/style.css'

import React from 'react'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { createRoot } from 'react-dom/client'

import store from './store/store'
import AppContainer from './AppContainer'
import { configAws } from './helpers/dist/awsConfig' // Si es una utilidad importante, exporta luego de su uso.
import { icons } from './assets/icons/index' // Evita múltiples importaciones redundantes de íconos.
import './assets/icons/iconsDuotone'
import './assets/icons/iconsLigth'
import './assets/icons/inconsRegular'
import './assets/icons/inconsSolid'

export { configAws, icons }

// Renderizado y declaración de fallback
const container = document.getElementById('root')
if (!container) throw new Error('No se pudo encontrar el contenedor raíz.')

const root = createRoot(container)

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

root.render(
  <React.Suspense fallback={loading}>
    <Provider store={store}>
      <BrowserRouter>
        <AppContainer
          mantenimiento={
            (process?.env?.REACT_APP_MODO_MANTENIMIENTO ?? 'false') === 'true'
          }
        />
      </BrowserRouter>
    </Provider>
  </React.Suspense>,
)

// Registro de métricas de rendimiento
reportWebVitals()
