import { combineReducers } from '@reduxjs/toolkit'
import { ClientesStateEdition } from '../types/types'
import { datosEdicionClientesReducer } from './editDataReducer'
import { menuReducer } from './menuReducer'
import { searchClientesReducer } from './searchReducer'
import { tabsReducer } from './tabsReducer'
import { configuracionesClienteReducer } from './configClientesReducer'

export const clientesReducer = combineReducers<ClientesStateEdition>({
  menu: menuReducer,
  tabs: tabsReducer,
  search: searchClientesReducer,
  editData: datosEdicionClientesReducer,
  configCliente: configuracionesClienteReducer,
})
