import { DateUtils, formatoFechasApi } from "../../../../helpers/dateUtils";
import { RequestHelper } from "../../../../helpers/requestHelper";
import { SesionService } from "../../../../services/sesion.service";
import { PadreOption } from "../types/types";



export const belongsService = { getUpperOptions }


async function getUpperOptions(label: string): Promise<any> {
  try {
    const data: any = {
      mensaje: label,
    };
    const sesion = SesionService.getCurrentSesion()
    const keysToCache: any = {
      ...data,
      dateKey: DateUtils.getCurrentDateAsString(formatoFechasApi),
      empresa: sesion?.empresa?.codigo,
    }
    const cacheKey = 'pertenecePadre' + JSON.stringify(keysToCache);
    const cached = sessionStorage.getItem(cacheKey);
    if (cached) {
      return { error: false, auto: JSON.parse(cached) };
    } else {
      const res = await RequestHelper.getAll<any>('admin/organico', 'cboxLoad', "", data);
      const opt: Array<PadreOption> = [];
      if (res?.auto && res?.auto.length > 0) {
        for (const x of res.auto) {
          opt.push({ ...x })
        }
        res.auto = opt
      }
      sessionStorage.setItem(cacheKey, JSON.stringify(opt));
      return res;
    }
  } catch (error) {
    return error;
  }
}
