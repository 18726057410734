import { GeneralesState } from '../types/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LoaderInfo } from '../../../../../../store/types'

const initialState: GeneralesState = {
  modulo: 'inventarios',
  currentAction: 'Buscar',
  currentFunction: '',
  aplicacion: 'catalogos/propiedades_item',
  loader: {
    show: false,
    mensaje: '',
  },
  loaderTab: {
    show: false,
    mensaje: '',
  },
}

const unitsSlice = createSlice({
  name: 'catalogosAtributos',
  initialState: initialState,
  reducers: {
    setCurrentAccion(state, action: PayloadAction<string>) {
      state.currentAction = action.payload
    },
    setCurrentFunction(state, action: PayloadAction<string>) {
      state.currentFunction = action.payload
    },
    changeLoaderDataSheet(state, action: PayloadAction<LoaderInfo>) {
      state.loader = action.payload
    },
    changeLoaderTab(state, action: PayloadAction<LoaderInfo>) {
      state.loaderTab = action.payload
    },
  },
})

export const { setCurrentAccion, setCurrentFunction, changeLoaderDataSheet, changeLoaderTab } =
  unitsSlice.actions

export const generalReducer = unitsSlice.reducer
