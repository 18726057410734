import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { TConfigUsuario, UsuarioDatosEdicion } from './types'

const initialState: TConfigUsuario = {}

const configuracionUsuario = createSlice({
  name: 'configUsuarios',
  initialState: initialState,
  reducers: {
    setDatosEdicionBackup(
      state,
      action: PayloadAction<{
        key: string
        formulario: UsuarioDatosEdicion
      }>,
    ) {
      state[action.payload.key] = action.payload.formulario
    },
    deleteEditDataBackup(state, action: PayloadAction<string>) {
      delete state[action.payload]
    },
  },
})

export const configuracionesUsuario = configuracionUsuario.reducer
export const { setDatosEdicionBackup, deleteEditDataBackup } =
  configuracionUsuario.actions
