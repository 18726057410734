import { CAlert, CButton, CCard, CCardBody, CCardHeader } from '@coreui/react-pro'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import RowContainer from '../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../views/componentes/colContainer'
import { GlobalMenu } from '../../../views/componentes/globalMenu/globalMenu'
import LoadPanel from 'devextreme-react/load-panel';
import { AccionMenu, ToastTypes } from '../../../store/types'
import { ButtonTypes } from '../../../views/componentes/globalMenu/types'
import { addToast } from '../../../store/toasterReducer'
import { RootState } from '../../../store/store'
import { changeLoader } from '../../../store/reducers'
import ReactDom from 'react-dom'
import Modalform from '../../../views/componentes/modalform'
import NumberBox from 'devextreme-react/number-box';
import TextBox from 'devextreme-react/text-box'
import Labeled from '../../../views/componentes/labeledInput/labeledInput'
import DateBox from 'devextreme-react/date-box'
import {
  Column,
  DataGrid,
  FilterRow,
  HeaderFilter,
  Paging,
  LoadPanel as LoadPanelTab,
  Pager, Editing
} from 'devextreme-react/data-grid'
import {
  Validator,
  RequiredRule,
  StringLengthRule
} from 'devextreme-react/validator';
import { MessagesKeys, lh } from '../../../helpers/localizationHelper'
import { Button } from 'devextreme-react';
import { Movimiento } from '../../inventario/pages/movimientos/conceptosMovimientos/types/types'
import Dialog from '../../../views/componentes/librerias/bootstrap-dialog'
import { isMobileOnly } from 'react-device-detect'
import ValidationSummary from 'devextreme-react/validation-summary'
import ValidationGroup from 'devextreme-react/validation-group';
import { consoleService } from '../../../services/console.service'
import { getIndexProvider } from '../../shared/helpers/funciones'
import { DateUtils, formatoFechasApi } from '../../../helpers/dateUtils'
import { lotesService } from './service/lotes.services'
import { Detalle } from '../../inventario/pages/movimientos/ingresosEgresos/types/types'

// import Buscar from './components/buscar'
// import Nuevo from './components/nuevo'

const menuIngresoLotes = [
  {
    icon: 'fal fa-save',
    id: 'Guardar',
    text: 'Guardar',
    type: ButtonTypes.save,
  },
  {
    icon: 'fal fa-print',
    id: 'Imprimir',
    text: 'Imprimir',
    type: ButtonTypes.print,
  },
  // {
  //   icon: 'fal fa-times',
  //   id: 'Salir',
  //   text: 'Salir',
  //   type: ButtonTypes.times,
  // }
];

export type TItemSeleccionado = {
  codigo: number
  impuesto: number
  codigoUsuario: string
  barras: string
  unidadDescripcion: string
  oCostos: number
  oCostosIVA: number
  descripcion: string
  cantidad: number
  lotes: number
  pvp: number
  pvd: number
  precio: number
  numeroSerie: number // REVISAR
  fracion: number // REVISAR
  valorDescripcion: number
  valor: number
  cantidadMovimiento: number //MDE_CANTIDAD
}

interface IModalIngresoLotesProps extends React.PropsWithChildren {
  onChanged: (dataLocal: any) => void
  onCancel: () => void
  show: boolean
  modulo: number
  lotes: Array<any>
  detalleItems: Array<TItemSeleccionado> | Array<Detalle>
  seleccionadoItems: TItemSeleccionado | null
  localCodigo: number
  movimiento?: Movimiento
}

export const ModalIngresoLotes: React.FC<IModalIngresoLotesProps> = (props) => {
  const {
    seleccionadoItems,
    show,
    onCancel,
    onChanged,
    modulo,
    detalleItems,
    lotes,
    localCodigo,
    movimiento,
  } = props

  const dispatch = useDispatch()
  const tablaRef = React.useRef<any>()
  const tablaRefPager = React.useRef<any>()
  const dialogRef = React.useRef<any>(null)
  const validationGroupRef = React.useRef<any>()
  const loteInout = React.useRef<any>()

  const loader = useSelector((state: RootState) => state.global.loader)

  const botones = menuIngresoLotes.map((acc) => {
    const boton: AccionMenu = {
      nombre: acc.id,
      icon: acc.icon, //as any,
      actionType: acc.type,
      main: false,
      modal: false,
      shortcut: null,
    }
    return boton
  })

  const [acciones] = React.useState<Array<AccionMenu>>(botones)
  const [currentTab, setCurrentTab] = React.useState<any>('Buscar')
  const [funcion, setFuncion] = React.useState<string>('')
  const [codigoItem, setCodigoItem] = React.useState<number>(0)
  const [codigo, setCodigo] = React.useState<string>('')
  const [descripcion, setDescripcion] = React.useState<string>('')
  const [cantidadMovimiento, setCantidadMovimiento] = React.useState<number>(0)
  const [descuadre, setDescuadre] = React.useState<number>(0)
  const [lote, setLote] = React.useState<number>(null)
  const [loteBarra, setLoteBarra] = React.useState<string>(null)
  const [cantidad, setCantidad] = React.useState<number>(0)
  const [caducidad, setCaducidad] = React.useState<Date>(new Date())
  const [ubicacion, setUbicacion] = React.useState<any>(null)
  const [pageIndex, setPageIndex] = React.useState<number>(10)
  const [pageSize] = React.useState<number>(10)
  const [dplotes, setDplotes] = React.useState<Array<any>>([])
  const [selectedLote, setSelectedLote] = React.useState<any>(null)
  const [changes, setChanges] = React.useState<any>()
  const [editRowKey, setEditRowKey] = React.useState<any>()
  const [sel, setsel] = React.useState<number>(0)

  const setToast = React.useCallback(
    (texto: string, type: ToastTypes) => {
      dispatch(
        addToast({
          id: '',
          autoHide: 3500,
          title: 'Acatha',
          content: texto,
          fade: true,
          type: type,
        }),
      )
    },
    [dispatch],
  )

  const playLoader = React.useCallback(async () => {
    dispatch(changeLoader({ show: true, mensaje: 'Cargando...' }))
  }, [dispatch])

  const stopLoader = React.useCallback(async () => {
    dispatch(changeLoader({ show: false, mensaje: '' }))
  }, [dispatch])

  const onValidarToatales = React.useCallback(() => {
    let saveLotes: Array<any> = []
    let total: number = 0
    consoleService.log(dplotes)
    if (movimiento?.tipo === 9) {
      consoleService.log('ingreso')
      dplotes.map((lote) => {
        total += lote?.cantidadLote
      })
      if (total !== cantidadMovimiento) {
        setToast(
          'La suma de cantidades por lotes es diferente a la cantidad del detalle del movimiento',
          ToastTypes.Warning,
        )
        return
      }
    } else {
      consoleService.log('egreso')
      dplotes.map((lote) => {
        total += parseInt(lote?.cantidad)
      })
      consoleService.log(total)
      consoleService.log(cantidadMovimiento)
      if (total !== cantidadMovimiento) {
        setToast(
          'La suma de cantidades por lotes es diferente a la cantidad del detalle del movimiento',
          ToastTypes.Warning,
        )
        return
      }
    }
    saveLotes = dplotes.map((item) => {
      if (movimiento?.tipo === 9) {
        return {
          ...item,
          caducidad: DateUtils.dateToString(item?.caducidad, 'dd/MM/yyyy'),
        }
      } else {
        return item
      }
    })
    consoleService.log(saveLotes)
    onChanged(saveLotes)
  }, [cantidadMovimiento, dplotes, onChanged, setToast, movimiento?.tipo])

  const returnAction = (boton: AccionMenu) => {
    switch (boton.actionType) {
      case ButtonTypes.save:
        onValidarToatales()
        break
      default:
        break
    }
  }

  const getButtonDisabled = React.useCallback((button: AccionMenu) => {
    switch (button.actionType) {
      case ButtonTypes.save:
        return false
      case ButtonTypes.print:
        return true
      default:
        return false
    }
  }, [])

  const onSelectionChanged = React.useCallback(({ selectedRowsData }) => {
    consoleService.log('detalle slected', selectedRowsData[0])
    setSelectedLote(selectedRowsData[0])
  }, [])

  const onRowDblClick = React.useCallback((selec) => {
    if (selec.data) {
      // onChanged(selec['data'])
    }
  }, [])

  const onErrorConfirm = React.useCallback((messages: any, tittle?: string) => {
    dialogRef.current.show({
      title: (
        <>
          <strong>{'Ácatha'}</strong>
        </>
      ),
      body: (
        <>
          <strong style={{ fontSize: '14px' }}>{tittle}</strong>
          <br />
          <CAlert color="danger">
            <ul className="errorList">
              {messages.map(function (item, id) {
                return <li key={id}>{item}</li>
              })}
            </ul>
          </CAlert>
        </>
      ),
      actions: [
        Dialog.Action(
          <span>
            <u>{'A'}</u>
            {'ceptar'}
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>{'C'}</u>
            {'ancelar'}
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    return
  }, [])

  const onAddLote = React.useCallback(() => {
    // setDplotes

    const result = validationGroupRef.current.instance.validate()
    if (result.isValid !== false) {
      const errors: any = []

      if (lote === null || lote === undefined) {
        errors.push('Numero: ingrese el numero de lote.')
      }
      let existe: boolean = false
      let total: number = 0
      if (dplotes.length > 0) {
        consoleService.log(dplotes, 'dplotes')
        consoleService.log(lote, 'dlote')
        consoleService.log(detalleItems[sel], 'dplotes')
        consoleService.log(detalleItems, 'dpdetalle')
        dplotes.map((item: any) => {
          total += item?.cantidadLote ?? 0
          if (
            item?.codigoItem !== detalleItems[sel]?.codigo &&
            item?.barras === codigo
          ) {
            existe = true
          }
        })
      }

      if (existe) {
        errors.push('Numero: existe un registro con un mismo numero de lote.')
      }

      if (cantidad < 0) {
        errors.push('Cantidad: Es necesario ingresar un valor.')
      }

      total += cantidad
      if (total > cantidadMovimiento) {
        errors.push(
          'Descuadre: las cantidades ingresadas superan la cantidad adquirida.',
        )
      }
      if (errors.length > 0) {
        onErrorConfirm(errors, 'Antes de continuar revise lo siguiente:')
        const desc = total - cantidadMovimiento
        setDescuadre(desc)
        return false
      }

      const dplotesadd = dplotes.slice()
      dplotesadd.push({
        codigo: 0,
        codigoItem: seleccionadoItems.codigo,
        numero: lote,
        barras: lote,
        cantidadLote: cantidad,
        caducidad: caducidad,
        ubicado: ubicacion,
        cantidad: cantidad,
      })

      // dplotes.addItem({LOT_CODIGO:0,ITE_CODIGO:dpdetalle[sel].ITE_CODIGO,LOT_NUMERO: barraslote_ti.text, LOT_BARRAS: barraslote_ti.text,
      //   LOT_CANTIDAD:cantidad_ti.text, LOT_CADUCIDAD:caducidad_ca.text, LOT_UBICADO:ubicado_ti.text, LMO_CANTIDAD:cantidad_ti.text});

      setDplotes(dplotesadd ?? [])
      setLote(null)
      setCaducidad(new Date())
      setCantidad(null)
      setUbicacion(null)
    }
  }, [
    lote,
    dplotes,
    cantidad,
    seleccionadoItems,
    caducidad,
    ubicacion,
    onErrorConfirm,
    detalleItems,
    sel,
    codigo,
    cantidadMovimiento,
  ])

  const onRemoveLote = React.useCallback(async () => {
    if (
      selectedLote !== null &&
      selectedLote !== undefined &&
      selectedLote?.codigo !== 0
    ) {
      if (dplotes.length > 0) {
        let dataArray = []
        const provider = dplotes.slice(0)
        const index = await getIndexProvider(
          provider,
          'codigo',
          selectedLote?.codigo,
        )
        dataArray = provider
        if (index > -1) {
          provider.splice(index, 1)
          dataArray = provider
          setDplotes(dataArray)
        }
      }
    } else {
      setToast(
        'Seleccione un item de la tabla para quitarlo',
        ToastTypes.Warning,
      )
    }
  }, [dplotes, selectedLote, setToast])

  const onChangesChange = React.useCallback((changes) => {
    setChanges(changes)
  }, [])

  const onEditRowKeyChange = React.useCallback((editRowKey) => {
    setEditRowKey(editRowKey)
  }, [])

  const onResultsSearh = React.useCallback((data = []) => {
    consoleService.log(data)
    const lotesTemp = []
    let temp = []
    let total: number = cantidadMovimiento

    temp = data
    temp.map(lote => {
      if (lote?.existencia >= total && total > 0) {
        lotesTemp.push({
          codigo: lote?.codigo,
          codigoItem: seleccionadoItems.codigo,
          numero: lote?.numero,
          barras: lote?.barras,
          existencia: lote?.existencia,
          caducidad: lote?.caducidad,
          ubicado: lote?.ubicado,
          cantidad: total
        })
        total = 0
      } else {
        if (lote?.existencia < total) {
          lotesTemp.push({
            codigo: lote?.codigo,
            codigoItem: seleccionadoItems.codigo,
            numero: lote?.numero,
            barras: lote?.barras,
            existencia: lote?.existencia,
            caducidad: lote?.caducidad,
            ubicado: lote?.ubicado,
            cantidad: lote?.existencia
          })
          total -= lote?.existencia
        }
      }
    })
    consoleService.log(lotesTemp)
    setDplotes(lotesTemp)
  }, [cantidadMovimiento, seleccionadoItems])

  const onResultsSearhBarras = React.useCallback(
    (data = []) => {
      consoleService.log(data)
      const lotesTemp = dplotes
      let temp = []
      let total: number = 0
      let subtotal: number = 0
      let existe: number = -1

      temp = data

      lotesTemp.map((lote, i) => {
        if (lote?.codigo === temp[0].codigo) {
          existe = i
          subtotal += lote?.cantidad ?? 0
        }

        if (lote?.codigoItem === codigoItem) {
          total += lote?.cantidad ?? 0
        }
      })
      if (subtotal > temp[0]?.cantidad) {
        setToast(
          'Lote no cuenta con la cantidad suficiente de items solicitados',
          ToastTypes.Danger,
        )
        return
      }
      if (total + 1 > cantidadMovimiento) {
        setToast(
          'La cantidad en el comprobante es menor a la cantidad de productos que desea ingresar por lotes',
          ToastTypes.Danger,
        )
        return
      }
      if (existe >= 0) {
        lotesTemp[existe] = temp[0]
      } else {
        lotesTemp.push(temp[0])
      }
      setDplotes(lotesTemp)
    },
    [cantidadMovimiento, codigoItem, dplotes, setToast],
  )

  const onSearchLote = React.useCallback(async () => {
    let lotesTemp = dplotes
    const temp = []
    const lote_search: any = {
      itemCodigo: codigoItem,
      localCodigo: localCodigo,
    }
    let data = null
    try {
      playLoader()
      if (loteBarra === null || loteBarra === '') {
        data = await lotesService.getStock(lote_search)
      } else {
        lote_search.barras = loteBarra
        data = await lotesService.getStockBarras(lote_search)
      }
      stopLoader()
      consoleService.log(data)
      if (data !== null && data !== undefined && data['error'] === false) {
        if (loteBarra === null || loteBarra === '') {
          lotesTemp.map((lote) => {
            if (lote?.codigoItem !== codigoItem && lote?.existencia > 0) {
              temp.push(lote)
            }
          })
          consoleService.log(temp)
          lotesTemp = []
          lotesTemp = temp.map((itemt) => {
            return itemt
          })
          lotesTemp = lotesTemp.filter((l) => l?.codigoItem === codigoItem)
          setDplotes(lotesTemp)
          onResultsSearh(data['auto'])
        } else {
          onResultsSearhBarras(data['auto'])
        }
        setToast(data['message'], ToastTypes.Success)
        loteInout.current.instance.focus()
      } else {
        setToast(data['message'], ToastTypes.Danger)
      }
    } catch (error) {
      stopLoader()
      setToast(error.message, ToastTypes.Danger)
    }
  }, [
    codigoItem,
    localCodigo,
    loteBarra,
    playLoader,
    setToast,
    stopLoader,
    onResultsSearh,
    dplotes,
    onResultsSearhBarras,
  ])

  const onInitialData = React.useCallback(async () => {
    if (seleccionadoItems !== null) {
      let getLotes: Array<any> = []
      const provider = detalleItems.slice(0)
      const index = await getIndexProvider(
        provider,
        'codigo',
        seleccionadoItems?.codigo ?? 0,
      )
      if (index > -1) {
        setsel(index)
        setCodigoItem(provider[index]?.codigo ?? 0)
        setDescripcion(provider[index]?.descripcion ?? '')
        setCodigo(provider[index]?.barras ?? '')
        setCantidadMovimiento(
          parseInt(provider[index]?.cantidad.toString()) ?? 0,
        )
        setDescuadre(null)
        setLote(null)
        setCantidad(null)
        setUbicacion(null)
        consoleService.log(lotes)
        consoleService.log(provider[index])
        if (lotes.length > 0) {
          getLotes = lotes.map((lote) => {
            if (lote?.codigoItem === provider[index].codigo) {
              consoleService.log(lote?.caducidad)
              return {
                ...lote,
                caducidad: DateUtils.strDateToDate(
                  lote?.caducidad,
                  'dd/MM/yyyy',
                ),
              }
            }
          })
        }
        consoleService.log(getLotes)
        setDplotes(getLotes)
      }
    } else {
      setToast('No se ha seleccionado un item', ToastTypes.Warning)
    }
  }, [seleccionadoItems, detalleItems, lotes, setToast])

  const setCellCantidad = React.useCallback(
    (rowData, value, currentRowData) => {
      let newCantidad: Array<any> = []
      if (currentRowData !== null && currentRowData !== undefined && value) {
        const detalle = dplotes ?? []
        newCantidad = detalle.map((det) => {
          if (currentRowData.codigo === det.codigo) {
            currentRowData.cantidadLote = value
            return currentRowData
          }
          return det
        })
        setDplotes(newCantidad)
      }
    },
    [dplotes],
  )

  const setCellCantidadEgr = React.useCallback(
    (rowData, value, currentRowData) => {
      let newCantidad: Array<any> = []
      let total: number = 0
      if (currentRowData !== null && currentRowData !== undefined && value) {
        const detalle = dplotes ?? []
        newCantidad = detalle.map((det) => {
          if (currentRowData.codigo === det.codigo) {
            if (currentRowData.codigoItem === codigoItem) {
              currentRowData.cantidad = value
              total += currentRowData.cantidad
            }
            if (currentRowData.existencia < currentRowData.cantidad) {
              setToast(
                'La cantidad del lote es mayor a la existencia.',
                ToastTypes.Danger,
              )
              currentRowData.cantidad = currentRowData.existencia
              return currentRowData
            } else {
              return currentRowData
            }
          }
          if (total > cantidadMovimiento) {
            setToast(
              'La cantidad en lotes es mayor al indicado en el detalle del item.',
              ToastTypes.Danger,
            )
            return det
          } else {
            return det
          }
        })
        setDplotes(newCantidad)
      }
    },
    [dplotes, cantidadMovimiento, codigoItem, setToast],
  )

  const setCellUbicado = React.useCallback(
    (rowData, value, currentRowData) => {
      let newCantidad: Array<any> = []
      if (currentRowData !== null && currentRowData !== undefined && value) {
        const detalle = dplotes ?? []
        newCantidad = detalle.map((det) => {
          if (currentRowData.codigo === det.codigo) {
            currentRowData.ubicado = value
            return currentRowData
          }
          return det
        })
        setDplotes(newCantidad)
      }
    },
    [dplotes],
  )

  const setFechaVencimientoValue = React.useCallback(
    (rowData, value, currentRowData) => {
      let newCantidad: Array<any> = []
      if (currentRowData !== null && currentRowData !== undefined && value) {
        const detalle = dplotes ?? []
        newCantidad = detalle.map((det) => {
          if (currentRowData.codigo === det.codigo) {
            currentRowData.caducidad = value
            return currentRowData
          }
          return det
        })
        setDplotes(newCantidad)
      }
    },
    [dplotes],
  )

  React.useEffect(() => {
    setCurrentTab('Buscar')
    setFuncion(ButtonTypes.find)
    onInitialData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const botonesModal = () => {
    return (
      <GlobalMenu
        acciones={acciones}
        onClick={returnAction}
        getButtonDisabled={getButtonDisabled}
      />
    )
  }

  const rendeDGlotes = () => {
    return (
      <>
        <ValidationGroup ref={validationGroupRef} className="m-0">
          {!isMobileOnly && (
            <RowContainer>
              <CustomCol lg="10">
                <ValidationSummary id="summary"></ValidationSummary>
              </CustomCol>
            </RowContainer>
          )}

          <RowContainer className="d-flex justify-content-start mt-1">
            <CustomCol xs="12" md="5">
              <Labeled label="Lote" position="left">
                <NumberBox
                  name="lote"
                  value={lote ?? 0}
                  width={'100%'}
                  onValueChanged={(data) => {
                    if (data?.event !== null || data?.event !== undefined) {
                      setLote(data?.value)
                    }
                  }}
                >
                  <Validator>
                    <RequiredRule
                      message={lh.getMessage(
                        MessagesKeys.GlobalCampoRequerido,
                        'Lote',
                      )}
                    />
                  </Validator>
                </NumberBox>
              </Labeled>
            </CustomCol>
          </RowContainer>

          <RowContainer className="d-flex justify-content-start mt-1">
            <CustomCol xs="12" lg="3" md="4">
              <Labeled label="Cantidad" position="left">
                <NumberBox
                  name="cantidad"
                  value={cantidad ?? null}
                  onEnterKey={onAddLote}
                  onValueChanged={(data) => {
                    if (data?.event !== null || data?.event !== undefined) {
                      setCantidad(data?.value)
                    }
                  }}
                >
                  <Validator>
                    <RequiredRule
                      message={lh.getMessage(
                        MessagesKeys.GlobalCampoRequerido,
                        'Cantidad',
                      )}
                    />
                    <StringLengthRule
                      max="200"
                      message={lh.getMessage(
                        MessagesKeys.GlobalInputMax,
                        'Cantidad',
                        '200',
                      )}
                    />
                  </Validator>
                </NumberBox>
              </Labeled>
            </CustomCol>
          </RowContainer>

          <RowContainer className="d-flex justify-content-start mt-1">
            <CustomCol xs="12" lg="3" md="5">
              <Labeled label="Caducidad" position="left">
                <DateBox
                  applyValueMode="useButtons"
                  displayFormat="dd/MM/yyyy"
                  value={caducidad}
                  onValueChanged={({ value }: any) => {
                    setCaducidad(value)
                  }}
                  width="100%"
                >
                  <Validator>
                    <RequiredRule
                      message={lh.getMessage(
                        MessagesKeys.GlobalCampoRequerido,
                        'Caducidad',
                      )}
                    />
                  </Validator>
                </DateBox>
              </Labeled>
            </CustomCol>
          </RowContainer>

          <RowContainer className="d-flex justify-content-start mt-1">
            <CustomCol xs="12" lg="6" md="6">
              <Labeled label="Ubicación" position="left">
                <TextBox
                  name="ubicacion"
                  value={ubicacion ?? ''}
                  width={'100%'}
                  onEnterKey={onAddLote}
                  onValueChanged={(data) => {
                    if (data?.event !== null || data?.event !== undefined) {
                      setUbicacion(data?.value)
                    }
                  }}
                />
                <Button
                  stylingMode="outlined"
                  type="normal"
                  icon="plus"
                  onClick={() => {
                    onAddLote()
                  }}
                  className="ms-1 me-1"
                />
                <Button
                  icon="minus"
                  stylingMode="outlined"
                  disabled={selectedLote === null || selectedLote === undefined}
                  type="normal"
                  onClick={() => {
                    onRemoveLote()
                  }}
                  className="ms-1 me-1"
                />
              </Labeled>
            </CustomCol>
          </RowContainer>

          {isMobileOnly && (
            <RowContainer className="mb-2 mt-2">
              <CustomCol lg="10">
                <ValidationSummary id="summary"></ValidationSummary>
              </CustomCol>
            </RowContainer>
          )}
        </ValidationGroup>

        <RowContainer className="d-flex justify-content-start mt-1">
          <CustomCol xs="12" lg="12" md="12">
            <DataGrid
              ref={tablaRef}
              keyExpr="codigo"
              dataSource={dplotes ?? []}
              selection={{ mode: 'single' }}
              hoverStateEnabled={true}
              showBorders={true}
              showRowLines={true}
              allowColumnReordering={true}
              allowColumnResizing
              columnResizingMode="widget"
              columnAutoWidth={true}
              activeStateEnabled={true}
              // onOptionChanged={handleOptionChange}
              remoteOperations={true}
              onSelectionChanged={onSelectionChanged}
              onRowDblClick={(d) => {
                onRowDblClick(d)
              }}
              width={'100%'}
            >
              <Paging
                pageIndex={pageIndex}
                enabled={true}
                defaultPageSize={10}
                pageSize={pageSize}
              />
              <Pager
                ref={tablaRefPager}
                visible={dplotes.length > 0 ? true : false}
                showPageSizeSelector={true}
                allowedPageSizes={[8, 12, 20]}
                showNavigationButtons={true}
                showInfo={true}
                infoText="Page #{0}. Total: {1} ({2} items)"
              />
              <Editing
                mode="cell"
                allowUpdating
                changes={changes}
                onChangesChange={onChangesChange}
                editRowKey={editRowKey}
                onEditRowKeyChange={onEditRowKeyChange}
              />
              <LoadPanelTab enabled={true} />
              <FilterRow visible={true} />
              <HeaderFilter visible={true} />
              <Column
                dataField="numero"
                caption="Lote"
                width="100px"
                allowEditing={false}
              />
              <Column
                dataField="cantidadLote"
                caption="Cantidad"
                setCellValue={setCellCantidad}
                dataType={'number'}
                width="100px"
                allowEditing={true}
              />
              <Column
                dataField="caducidad"
                caption="Caducidad"
                dataType="date"
                width="200px"
                allowEditing={true}
                setCellValue={setFechaVencimientoValue}
              />
              <Column
                dataField="ubicado"
                caption="Ubicado"
                dataType={'string'}
                setCellValue={setCellUbicado}
                width="100px"
                allowEditing={true}
              />
            </DataGrid>
          </CustomCol>
        </RowContainer>
      </>
    )
  }

  const rendeDGEgresados = () => {
    return (
      <>
        <RowContainer className="d-flex justify-content-start mt-1">
          <CustomCol xs="12" md="5">
            <Labeled label="Lote" position="left">
              <TextBox
                ref={loteInout}
                name="lote"
                value={loteBarra ?? ''}
                width={'100%'}
                onValueChanged={(data) => {
                  if (data?.event !== null || data?.event !== undefined) {
                    setLoteBarra(data?.value)
                  }
                }}
                onEnterKey={onSearchLote}
              />
            </Labeled>
          </CustomCol>
          <CustomCol
            xs="12"
            md="5"
            style={{ marginTop: 'auto', marginRight: 'auto' }}
          >
            <CButton
              id="btnConfirmar"
              color="secondary"
              className="m-1"
              size="sm"
              onClick={() => onSearchLote()}
            >
              {'Llenado Automático'}
            </CButton>
          </CustomCol>
        </RowContainer>
        <RowContainer className="d-flex justify-content-start mt-1">
          <CustomCol xs="12" lg="3" md="5">
            <Labeled label="Caducidad" position="left">
              <DateBox
                applyValueMode="useButtons"
                displayFormat="dd/MM/yyyy"
                value={caducidad}
                onValueChanged={({ value }: any) => {
                  setCaducidad(value)
                }}
                width="100%"
              />
            </Labeled>
          </CustomCol>
        </RowContainer>

        <RowContainer className="d-flex justify-content-start mt-1">
          <CustomCol xs="12" lg="12" md="12">
            <DataGrid
              ref={tablaRef}
              keyExpr="codigo"
              dataSource={dplotes ?? []}
              selection={{ mode: 'single' }}
              hoverStateEnabled={true}
              showBorders={true}
              showRowLines={true}
              allowColumnReordering={true}
              allowColumnResizing
              columnResizingMode="widget"
              columnAutoWidth={true}
              activeStateEnabled={true}
              // onOptionChanged={handleOptionChange}
              remoteOperations={true}
              onSelectionChanged={onSelectionChanged}
              onRowDblClick={(d) => {
                onRowDblClick(d)
              }}
              width={'100%'}
            >
              <Paging
                pageIndex={pageIndex}
                enabled={true}
                defaultPageSize={10}
                pageSize={pageSize}
              />
              <Pager
                ref={tablaRefPager}
                visible={dplotes.length > 0 ? true : false}
                showPageSizeSelector={true}
                allowedPageSizes={[8, 12, 20]}
                showNavigationButtons={true}
                showInfo={true}
                infoText="Page #{0}. Total: {1} ({2} items)"
              />
              <Editing
                mode="cell"
                allowUpdating
                changes={changes}
                onChangesChange={onChangesChange}
                editRowKey={editRowKey}
                onEditRowKeyChange={onEditRowKeyChange}
              />
              <LoadPanelTab enabled={true} />
              <FilterRow visible={true} />
              <HeaderFilter visible={true} />
              <Column
                dataField="numero"
                caption="Lote"
                width="100px"
                allowEditing={false}
              />
              <Column
                dataField="existencia"
                caption="Existencia"
                width="100px"
                allowEditing={false}
              />
              <Column
                dataField="caducidad"
                caption="Caducidad"
                width="200px"
                allowEditing={false}
              />
              <Column
                dataField="cantidad"
                caption="Egreso"
                dataType={'number'}
                setCellValue={setCellCantidadEgr}
                width="100px"
                allowEditing={true}
              />
            </DataGrid>
          </CustomCol>
        </RowContainer>
      </>
    )
  }

  const renderDynamicDG = () => {
    if (movimiento?.tipo && movimiento?.tipo === 9) {
      return rendeDGlotes()
    } else {
      return rendeDGEgresados()
    }
  }

  const bodyModal = () => {
    return (
      <>
        <div>
          <Dialog ref={dialogRef} />
        </div>
        <RowContainer className="d-flex justify-content-start mt-1">
          <CustomCol xs="12" lg="3" md="3">
            <Labeled label="Código" position="left">
              <TextBox name="codigo" value={codigo ?? null} readOnly />
            </Labeled>
          </CustomCol>
        </RowContainer>

        <RowContainer className="d-flex justify-content-start mt-1">
          <CustomCol xs="12" md="8">
            <Labeled label="Descripción" position="left">
              <TextBox
                name="descripcion"
                value={descripcion ?? ''}
                width={'100%'}
                onValueChanged={(evt) => {
                  if (evt?.event !== undefined) {
                    setDescripcion(String(evt?.value))
                  }
                }}
              />
            </Labeled>
          </CustomCol>
        </RowContainer>

        <RowContainer className="mt-1">
          <CustomCol xs="12" md="9" className="d-flex justify-content-start ">
            <Labeled label="Cantidad Movimiento" position="left">
              <NumberBox
                name="cantidadMovimiento"
                value={cantidadMovimiento ?? null}
              />
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="3" className="d-flex justify-content-end ">
            <Labeled label="Descuadre" position="left">
              <NumberBox name="descuadre" value={descuadre} width={'100%'} />
            </Labeled>
          </CustomCol>
        </RowContainer>

        <hr />
        {movimiento !== null && renderDynamicDG()}
      </>
    )
  }

  const renderContent = () => {
    return (
      <CCard>
        <CCardHeader>{botonesModal()}</CCardHeader>
        <CCardBody>
          <LoadPanel
            shadingColor="rgba(0,0,0,0.4)"
            position={'center'}
            visible={loader.show}
            showIndicator={true}
            shading={true}
            showPane={true}
            message={loader.mensaje}
            closeOnOutsideClick={false}
          />
          {bodyModal()}
        </CCardBody>
      </CCard>
    )
  }

  return (
    <>
      {ReactDom.createPortal(
        <Modalform
          name={'popupIngresoLotes'}
          key={'popupIngresoLotes'}
          headerTitle={'Alta de Pedimentos Aduanales y Lotes'}
          childrenBody={renderContent()}
          closeOnBackdrop={false}
          show={show}
          onClose={() => {
            onValidarToatales()
            onCancel()
          }}
          centered={true}
          size="lg"
        />,
        document.body,
      )}
    </>
  )
}

