import { RequestHelper } from "../../../../../../helpers/requestHelper"



export const MarcationsService = { loadMarctions, loadByUser, associateMark, getUserSimple, getUserRegister }


async function loadMarctions(usuarioCodigo: number, mes: string, anio: number, rolPagos: number = 0, horarioTrabajo: number = 0): Promise<any> {
  try {
    const obj = {
      usuario: usuarioCodigo,
      rolPagos,
      horarioTrabajo,
      mes,
      anio,
    }
    const apiData = await RequestHelper.getAll<any>('nomina/general', 'markings/get', '', obj)
    return apiData
  } catch (error) {
    return error
  }
}

async function loadByUser(usuarioCodigo: number, mes: string, anio: number): Promise<any> {
  try {
    const obj = {
      usuario: usuarioCodigo,
      mes,
      anio,
    }
    const apiData = await RequestHelper.getAll<any>('nomina/registro/marcacion', 'getHourlyByUser', '', obj)
    return apiData
  } catch (error) {
    return error
  }
}
async function associateMark(): Promise<any> {
  try {
    const obj = {}
    const apiData = await RequestHelper.getAll<any>('nomina/registro/marcacion', 'associateMarking', '', obj)
    return apiData
  } catch (error) {
    return error
  }
}

async function getUserSimple(cedula: string): Promise<any> {
  try {
    const obj = {
      cedula
    }
    const apiData = await RequestHelper.getAll<any>('nomina/registro/marcacion', 'searchUserSimple', '', obj)
    return apiData
  } catch (error) {
    return error
  }
}

async function getUserRegister(usuario: number, mes: string, anio: number): Promise<any> {
  try {
    const obj = {
      usuario,
      mes,
      anio
    }
    const apiData = await RequestHelper.getAll<any>('nomina/registro/marcacion', 'getRegisterByUser', '', obj)
    return apiData
  } catch (error) {
    return error
  }
}