import { IconDefinition } from "@fortawesome/pro-light-svg-icons";
import { LoaderInfo, OpenModal } from "../../../../../store/types";

export enum TIPOS_BOTONES {
  BOTON_NUEVO = 'Nuevo',
  BOTON_GUARDAR = 'Guardar',
  BOTON_EDITAR = 'Editar',
  BOTON_DESHACER = 'Deshacer',
  BOTON_BUSCAR = 'Buscar',
  BOTON_ELIMINAR = 'Eliminar',
  BOTON_LIMPIAR = 'Limpiar',
  BOTON_EXPORTAR = 'Exportar',
  BOTON_IMPORTAR = 'Importar',
  BOTON_IMPRIMIR = 'Imprimir',
  BOTON_ASIGNAR_JERARQUIA = 'Asignar jerarquia',
  BOTON_ASIGNAR_NIVELES = 'Asignar niveles',
  BOTON_PRINT_DESIGN = "Imprimir_Diseño",
}

export type BotonMenu = {
  id: TIPOS_BOTONES,
  text: string,
  icon: IconDefinition | null,
}

export type Registros = {
  PLA_CODIGO: number,
  PLA_NUMERO: string,
  PLA_DESCRIPCION: string,
  PLA_DESCIDIOMA: string,
  PLA_CATEGORIA: number,
  PLA_CATEGORIA_DES: string,
  PLA_SALDO_INICIAL: any,
  PLA_SALDO_FINAL: any,
  PLA_ESTADO: number,
  TIP_CODIGO: number,
  TIP_DESCRIPCION: string,
  PLA_PADRE: number,
  PLA_PADRENUM: any,
  PLA_ORDEN: any,
  PLA_OPERACION: number,
  PLA_SALDO: any,
  PLA_NIVEL: number,
  PLA_FISCAL: any,
  PLF_NUMERO: any,
  PLA_TRANSACCIONES: any,
}

export type Seleccionado = {
  index: any,
  row: any,
  selectedRow: any,
}

export type OptionGrupos = {
  value: number,
  label: string,
}

export type formularioBusqueda = {
  numero: number | null,
  descripcion: string | null,
  estado: number,
}

export type PageState = {
  modulo: string,
  currentAction: string,
  currentFunction: string,
  currentExecute: any,
  aplicacion: string,
  acciones: Array<BotonMenu>,
  registros: Array<Registros>,
  registrosBack: Array<Registros>,
  seleccionado: Seleccionado,
  refrescaTabla: boolean,
  tipos: Array<OptionGrupos>,
  categorias: Array<OptionGrupos>,
  cuentasPertenecen: Array<OptionGrupos>,
  cuentasFlujoOptions: Array<OptionGrupos>,
  cuentasPertenecenBackup: Array<OptionGrupos>,
  modal: OpenModal,
  loader: LoaderInfo,
  formularioBusqueda: formularioBusqueda
}