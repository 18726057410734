import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { PersonalDatosEdicion, configPersonal } from '../types/types'
import { EdicionPayload } from '../../../store/types'

const initialState: configPersonal = {
  aplicacion: 0,
}

const configuracionPersonal = createSlice({
  name: 'configPersonalNomina',
  initialState: initialState,
  reducers: {
    setAplicacionModulo(state, acion: PayloadAction<number>) {
      state.aplicacion = acion.payload
    },
    deleteEditDataBackup(state, action: PayloadAction<string>) {
      delete state[action.payload]
    },
    setDatosEdicionBackup(
      state,
      action: PayloadAction<EdicionPayload<PersonalDatosEdicion>>,
    ) {
      state[action.payload.key] = action.payload.data
    },
  },
})

export const configuracionesPersonalNomina = configuracionPersonal.reducer
export const { setAplicacionModulo, deleteEditDataBackup, setDatosEdicionBackup } = configuracionPersonal.actions
