import { combineReducers } from '@reduxjs/toolkit'
import { tabsReducer } from './tabsReducer'
import { TConUsuarioState } from './types'
import { menuReducer } from './menuReducer'
import { dataEditionReducer } from './editDataReducer'
import { searchReducer } from './searchReducer'
import { configuracionesUsuario } from './configReducer'

export const ConfigUsuariosReducer = combineReducers<TConUsuarioState>({
  menu: menuReducer,
  tabs: tabsReducer,
  editData: dataEditionReducer,
  search: searchReducer,
  configuraciones: configuracionesUsuario,
})
