import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AutorizacionDocumentoVenta } from '../../../../types/types'
import { ConfigurationsGuide } from '../types/types'

const initialState: ConfigurationsGuide = {
 autorizacionDocumento: null,
}

const configurationsGuideSlice = createSlice({
 name: 'configurationsGuide',
 initialState: initialState,
 reducers: {
  initialize(state, acion: PayloadAction<AutorizacionDocumentoVenta>) {
   state.autorizacionDocumento = acion.payload
  },
  setAuthorizationModuleGuide(
   state,
   acion: PayloadAction<AutorizacionDocumentoVenta>,
  ) {
   state.autorizacionDocumento = acion.payload
  },
 },
})

export const configurationsReducer = configurationsGuideSlice.reducer
export const { setAuthorizationModuleGuide } = configurationsGuideSlice.actions
