import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ConfiguracionesComprasSV } from '../types/types'
import { AutorizacionDocumentoVenta } from '../../../../../ventas/types/types'
import { CompraInfo } from '../../../../types/types'

const initialState: ConfiguracionesComprasSV = {
  autorizacionDocumento: null,
  usaDescuentoGlobal: false,
  compraSeleccionada: null,
  modulo: 0,
}

const configuracionesComprasSVSlice = createSlice({
  name: 'configuracionesComprasSVCompras',
  initialState: initialState,
  reducers: {
    initialize(state, acion: PayloadAction<AutorizacionDocumentoVenta>) {
      state.autorizacionDocumento = acion.payload
    },
    setAutorizacionModuloComprasSV(
      state,
      acion: PayloadAction<AutorizacionDocumentoVenta>,
    ) {
      state.autorizacionDocumento = acion.payload
    },
    seleccionarCompra(state, acion: PayloadAction<CompraInfo | null>) {
      state.compraSeleccionada = acion.payload
    },
    setCodigoModulo(state, acion: PayloadAction<number>) {
      state.modulo = acion.payload
    },
  },
})

export const configuracionesReducer = configuracionesComprasSVSlice.reducer
export const { setAutorizacionModuloComprasSV } =
  configuracionesComprasSVSlice.actions
