import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FETCH_STATUS, LoaderInfo, Seleccionado } from "../../../../../store/types";
import { CarteraState, FiltroCarteraState } from "../types/types";
import { CarteraService } from "../components/corteComprobantes/service/carta.service";
import { DateUtils } from "../../../../../helpers/dateUtils";
import { VerCarteraVentas } from "../../../../ventas/pages/cuadres/types/types";
import { RootState } from "../../../../../store/store";

const gruposTipos = [
  { value: "", label: 'Todos' },
  { value: "N", label: 'Nacional' },
  { value: "E", label: 'Extrangero' },
]

const initialState: CarteraState<VerCarteraVentas> = {
  filter: {
    tipoCliente: gruposTipos[0],
    cliente: null,
    vendedor: null,
    local: null,
    ciudad: null,
    empresa: null,
    desdec: null,
    corte: DateUtils.getCurrentDateAsString('dd/MM/yyyy'),
    habilitarDesdec: false,
    agruparPor: {
      clienteBox: true,
      rcomercialBox: false,
      empresaBox: false,
      ciudadBox: false,
      fechaBox: false,
      consolidadoBox: false,
      saldoMayorCeroBox: true,
      consignacion: false,
    },
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  loader: {
    show: false,
    mensaje: ''
  },
  seleccionado: {
    index: null,
    row: null,
    selectedRow: null,
  },
  resultados: []
}

export const fetchCartera = createAsyncThunk<
  Array<any>,
  FiltroCarteraState
>('ventas/duePortfolio', async (filtro) => {
  try {
    const carteraApi = await CarteraService.getCartera(
      filtro.cliente !== null ? filtro.cliente.codigo : 0,
      filtro.ciudad !== null ? filtro.ciudad.codigo : -1,
      filtro.vendedor !== null ? filtro.vendedor.vendedorCodigo : 0,
      filtro.local !== null ? filtro.local.codigo : -1,
      Number(filtro.agruparPor.consignacion),
      filtro.empresa !== null && filtro.empresa.codigo !== "Elija una opción" ? filtro.empresa.codigo : "",
      filtro.agruparPor.consolidadoBox,
      filtro.tipoCliente.value,
      filtro.corte,
      filtro.desdec === null ? "" : filtro.desdec,
    )
    return carteraApi
  } catch (error) {
    return Promise.reject(error)
  }
})

const carteraSlice = createSlice({
  name: 'carteraDocumentos',
  initialState: initialState,
  reducers: {
    setCleanResult(state) {
      state.resultados = []
    },
    setChangeFilter(state, action: PayloadAction<FiltroCarteraState>) {
      state.filter.tipoCliente = action.payload.tipoCliente
      state.filter.cliente = action.payload.cliente
      state.filter.vendedor = action.payload.vendedor
      state.filter.local = action.payload.local
      state.filter.ciudad = action.payload.ciudad
      state.filter.corte = action.payload.corte
      state.filter.empresa = action.payload.empresa
      state.filter.desdec = action.payload.desdec
      state.filter.habilitarDesdec = action.payload.habilitarDesdec
      state.filter.agruparPor = action.payload.agruparPor
    },
    cleanFilter(state) {
      state.filter.tipoCliente = gruposTipos[0]
      state.filter.cliente = null
      state.filter.vendedor = null
      state.filter.local = null
      state.filter.ciudad = null
      state.filter.empresa = null
      state.filter.desdec = null
      state.filter.corte = DateUtils.getCurrentDateAsString('dd/MM/yyyy')
      state.filter.habilitarDesdec = null
      state.filter.agruparPor = {
        clienteBox: true,
        rcomercialBox: false,
        empresaBox: false,
        ciudadBox: false,
        fechaBox: false,
        consolidadoBox: false,
        saldoMayorCeroBox: true,
        consignacion: false,
      }
    },
    setResetSeleccion(state) {
      const toAdd = {
        index: null,
        row: null,
        selectedRow: null,
      }
      state.seleccionado = toAdd
    },
    setSeleccionarDato(state, action: PayloadAction<Seleccionado>) {
      const toAdd = {
        index: action.payload.index,
        row: action.payload.row,
        selectedRow: action.payload.selectedRow,
      }
      state.seleccionado = toAdd
    },
    changeLoaderCartera(state, action: PayloadAction<LoaderInfo>) {
      state.loader = action.payload
    },
  }, extraReducers: (builder) => {
    builder.addCase(fetchCartera.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchCartera.fulfilled, (state, { payload }) => {
      state.resultados = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchCartera.rejected, (state, { payload }) => {
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
  }
})

export const {
  setCleanResult,
  setChangeFilter,
  setResetSeleccion,
  setSeleccionarDato,
  changeLoaderCartera,
  cleanFilter
} = carteraSlice.actions


export const carteraReducer = carteraSlice.reducer