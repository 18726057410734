import { TabsState } from "../../../../../store/genericTabTypes"
import { CustomDictionary, FETCH_STATUS, LoaderInfo, TEstado } from "../../../../../store/types"
import { TOptionGenerico } from "../../../../clientes/pages/clientes/types/types"
import { CargoDepartamento } from "../../../../componentes/cargosDepartamentosLookup/types/types"
import { DetailList } from "../../../../componentes/modalDetalleCambios/types/types"
import { OrganicoRol } from "../../../../componentes/organicoCargosLookup/types/types"
import { PaisOption } from "../../../../componentes/paisesLookUp/store/type"
import { OptionRelations } from "../../../../componentes/relacionesLookUp/types/types"
import { MenuState } from "../../../../ventas/types/types"


export enum NuevoTabs {
  con = 'Contratos',
  obs = 'Observaciones',
}

export type ContratosList = {
  codigo: number
  usuarioCodigo: number
  usuarioNombre: string
  usuarioNombres: string
  usuarioApellidos: string
  usuarioNA: string
  usuarioCedula: string
  nivelEstudiosCodigo: number
  nivelEstudiosDescripcion: string
  tituloDescripcion: string
  tipo: number
  tipoDescripcion: string
  fecha: string
  fechaIngreso: string
  fechaSalida: string
  archivo: string
  observacionesIngreso: string
  observacionesSalida: string
  calificado: number
  tipoPersonal: number
  funciones: string
  contrato: string
  horasTiempoParcial: number
  calificadoDescripcion: string
  estado: number
  estadoDescripcion: string
  proceso: string
  procesoDescripcion: string
  anios: string
  fechaProporcional: string
  discapacidad: string
  porcentajeDiscapacidad: number
  confianza: number
  nominal: number
  liquido: number
  jornada: number
  jornadaNombre: string
  horarioTrabajo: number
  horarioTrabajoDescripcion: string
  organicoRol: number
  organico: number
  rol: number
  rolDescripcion: string
  acumulaFondoReserva: number
  privacionesNocturno: number
  privacionesSuplementarios: number
  privacionesExtras: number
  privacionesDecimoTercero: number
  privacionesDecimoCuarto: number
  pagaImpuestoRenta: number
  pagarIESS: number
  acumulaDecimoTercero: number
  acumulaDecimoCuarto: number
  cuentaBanco: number
  cuentaBancoDescripcion: string
  pagaFondosReservaInicio: number
}


export type ChangeContratc = {
  contratoCodigo: number
  usuario: number,
  tipoPersonal: number
  fechaIngreso: string
  contrato: string
  organicoRol: number
  funciones: string
  nominal: number
  nominalB: number
  descripcion: string
  confianza: number
}

export type ContratoDatosEdicion = {
  proceso: string
  activaIngreso: boolean
  nroIngreso: number
  contrato: TOptionGenerico
  tipoPersonal: TOptionGenerico
  aumentoSueldo: boolean
  fechaIngreso: string
  contatoRt: string
  empleado: string
  codigoEmpleado: number
  tipoContrato: TEstado | null
  cargo: OrganicoRol | null
  jornada: TEstado | null
  departamento: CargoDepartamento | null
  numeroTipoParcial: number
  numeroTipoParcialShow: boolean
  funciones: string
  sueldoNomina: number
  sueldoNominaB: number
  fechaSalida: string
  horarioLabores: string
  codigoHorarioLabores: number
}


export type SaveContract = {
  infoRegistro: {
    fecha: string
    fechaIngreso: string
    fechaSalida: string
    codigo: number
    usuarioCodigo: number
    tipoPersonal: number
    archivo: string
    observacionesIngreso: string
    observacionesSalida: string
    funciones: string
    contrato: string
    cargo: number
    nominal: number
    discapacidad: string
    porcentajeDiscapacidad: number
    tipo: number
    horasTiempoParcial: number
    detalle: {
      codigo: number
      contrato: number
      nominal: number
      liquido: number
      jornada: number
      horarioTrabajo: number
      organicoRol: number
      confianza: number
      acumulaFondoReserva: number
      pagarIESS: number
      acumulaDecimoTercero: number
      acumulaDecimoCuarto: number
      pagaImpuestoRenta: number
      privacionesNocturno: number
      privacionesSuplementarios: number
      privacionesExtras: number
      privacionesDecimoTercero: number
      privacionesDecimoCuarto: number
      estado: number
      cuentaBanco: number
      pagaFondosReservaInicio: number
    }
  }
}

export type SearchUsesContract = {
  nombres: string
  apellidos: string
  fecha: string
  cedula: string
  estado: number
  ciudad: number
  local: number
}

export type PrivacionesTipos = {
  nocturno: boolean
  suplementarios: boolean
  extras: boolean
  decimoCuarto: boolean
  decimoTercero: boolean
}

export type OpcionesEmpresaTipos = {
  pagaimpuestorenta: boolean
  pagaseguridadsocial: boolean
  pagofondosinicio: boolean
  sistemasalarioneto: boolean
  acumuladecimotercero: boolean
  acumulafondoreserva: boolean
  acumuladecimocuarto: boolean
}

export type ObservacionesDatosEdicion = {
  fecha: string
  discapacidad: TEstado | null
  discapacidadPorcentaje: number
  privaciones: PrivacionesTipos
  opcionEmpres: OpcionesEmpresaTipos
  ctaBancaria: string
  archivo: string
  codigoCtaBancaria: number
  archivoNombre: string
  observacionesIngreso: string
  observacionesSalida: string
  relacion: OptionRelations | null
  detalleLista: DetailList[] | []
}


export type ContratosDatosEdicion = {
  codigo: number
  loader: LoaderInfo
  loading: boolean
  contratos: ContratoDatosEdicion
  observaciones: ObservacionesDatosEdicion
}

export type FiltroBusquedaContratos = {
  empleado: string
  tipoContrato: TEstado | null
  procesos: TEstado | null
  jornada: TEstado | null
  departamento: CargoDepartamento | null
  pais: PaisOption | null
  tipoPersonal: TOptionGenerico | null
  desde: string
  hasta: string
  initital: boolean
}

export type BusquedaState<ContratosList> = {
  filtro: FiltroBusquedaContratos
  filtroBackup: FiltroBusquedaContratos
  mensaje: string
  resultados: Array<ContratosList>
  loader: LoaderInfo
  seleccionado: ContratosList
  searchContratos: boolean
  colapsado: boolean
  import: boolean
  nroRegistrosEncontrados: number
  status: FETCH_STATUS
}


export type ContratosState = {
  menu: MenuState
  search: BusquedaState<ContratosList>
  config: configContratos
  editData: CustomDictionary<ContratosDatosEdicion>
  tabs: TabsState<ContratosDatosEdicion>
}

export type configContratos = {
  aplicacion: number
  editData?: CustomDictionary<ContratosDatosEdicion>

}

export type SaveListContracts = {
  infoRegistro: {
    fecha: string,
    detalle: ContratosList[]
  }
}

export type SaveGirdUser = {
  codigo: number
  cedula: string
  nombres: string
  apellidos: string
  direccion: string
  email: string
  telefono: string
  numero: string
  nivelCodigo: number
  ciuCodigo: number
}


export type ContractsListDto = {
  Identification: string
  Names: string
  DescriptionRole: string
  Title: string
  Studies: string
  Qualify: string
  WorkingDay: string
  BeginDate: string
  EndDate: string
  Observations: string
  Salary: number
}


export type ContractDto = {
  Code: number
  Identification: string
  Names: string
  BeginDate: string
  DescriptionRole: string
  EndDate: string
  TypePersonal: string
  Contract: string
  TypeContract: string
  WorkingDay: string
  Salary: number
  Functions: string
  Department: string
  Date: string
  Disability: string
  DisabilityPercent: number
}
