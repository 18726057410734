import React from 'react'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput'
import { CheckBox, TextBox } from 'devextreme-react'
import LocalesLookUp from '../../../../../componentes/localesLookUp'
import PaisesLookUp from '../../../../../componentes/paisesLookUp'
import { TabState } from '../../../../../../store/genericTabTypes'
import { PersonalDatosEdicion } from '../../types/types'
import Importar from '../../../../../clientes/pages/docsCtasXCobrar/components/impportarExcel/importar'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/store'
import { CCardBody, CCardHeader, CCollapse, CLink } from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilChevronBottom, cilChevronTop } from '@coreui/icons'
import { changeFilter, setCollapsed, setImport } from '../../store/searchReducer'
import { ToastTypes } from '../../../../../../store/types'
import { addToast } from '../../../../../../store/toasterReducer'
import { utilidades } from '../../../../../../helpers/utilidades'

interface IFiltrosBusquedaProps extends React.PropsWithChildren {
  tab: TabState<PersonalDatosEdicion>
  tabId: string
  onEnter: () => void
}

const FiltroBusqueda: React.FunctionComponent<IFiltrosBusquedaProps> = (props) => {

  const { tab, tabId, onEnter } = props
  const dispatch = useDispatch()
  const modulo = useSelector((state: RootState) => state.nomina.personal.config.aplicacion)
  const collapsed = useSelector((state: RootState) => state.nomina.personal.search.colapsado)
  const personalFilter = useSelector((state: RootState) => state.nomina.personal.search.filtro)
  const showImport = useSelector((state: RootState) => state.nomina.personal.search.import)

  const setToast = React.useCallback(
    (mensaje: string, tipo: ToastTypes) => {
      dispatch(
        addToast({
          title: 'Nómina - Personal',
          content: mensaje,
          type: tipo,
        }),
      )
    },
    [dispatch],
  )
  const onValueChange = React.useCallback((data, key: string) => {
    if (data !== null && data !== undefined) {
      dispatch(changeFilter({
        ...personalFilter,
        [key]: data
      }))
    }

  }, [dispatch, personalFilter])

  const onGetDatFile = React.useCallback((data: any) => {
    if (data['auto'] !== null && data !== undefined && data['error'] === true) {
      setToast(data['message'], ToastTypes.Danger)
    }
  }, [setToast])

  return (
    <>
      <CCardHeader
        onClick={() => dispatch(setCollapsed(!collapsed))}
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <strong> {collapsed ? '' : 'Filtro de Búsqueda'} </strong>
        <CLink className="card-header-action">
          <CIcon icon={collapsed ? cilChevronBottom : cilChevronTop} />
        </CLink>
      </CCardHeader>
      <CCollapse visible={collapsed}>
        <CCardBody>
          <RowContainer>
            <CustomCol xs="12" md={showImport ? '7' : '12'}>
              <RowContainer>
                <CustomCol xs="12" md="4">
                  <Labeled label='Usuario:'>
                    <TextBox
                      value={personalFilter?.usuario ?? ''}
                      onValueChange={(data) => onValueChange(data, 'usuario')}
                      onEnterKey={onEnter}
                    />
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="4">
                  <Labeled label='Cedula:'>
                    <TextBox
                      value={personalFilter?.cedula ?? ''}
                      onValueChange={(data) => onValueChange(data, 'cedula')}
                      onEnterKey={onEnter}
                      onKeyDown={utilidades.filtraTeclasNoNumericas}
                    />
                  </Labeled>
                </CustomCol>
              </RowContainer>
              <RowContainer>
                <CustomCol xs="12" md="4">
                  <Labeled label='Nombres:'>
                    <TextBox
                      value={personalFilter?.nombres ?? ''}
                      onValueChange={(data) => onValueChange(data, 'nombres')}
                      onEnterKey={onEnter}
                    />
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="4">
                  <Labeled label='Apellidos'>
                    <TextBox
                      value={personalFilter?.apellidos ?? ''}
                      onValueChange={(data) => onValueChange(data, 'apellidos')}
                      onEnterKey={onEnter}
                    />
                  </Labeled>
                </CustomCol>
              </RowContainer>
            </CustomCol>
            {showImport && (
              <CustomCol xs="12" md="5">
                <Labeled label=''>
                  <Importar
                    tab={tab}
                    tabId={tabId}
                    openImport={(open) => dispatch(setImport(false))}
                    moduleCode={modulo}
                    setData={(data) => onGetDatFile(data)}
                  />
                </Labeled>
              </CustomCol>
            )}
          </RowContainer>
          <RowContainer>
            <CustomCol xs="12" md="4">
              <Labeled label="Pais:">
                <PaisesLookUp
                  onChanged={(data) => onValueChange(data, 'pais')}
                  selected={personalFilter?.pais ?? null}
                  onChangedOptions={() => { }}
                  defecto={false}
                />
              </Labeled>
            </CustomCol>
            <CustomCol xs="12" md="4">
              <Labeled label="Locales:">
                <LocalesLookUp
                  onChanged={(data) => onValueChange(data, 'locales')}
                  onChangedOptions={() => { }}
                  selected={personalFilter?.locales ?? null}
                />
              </Labeled>
            </CustomCol>
            <CustomCol xs="12" md="2">
              <Labeled label="">
                <CheckBox
                  text="Activos"
                  className="m-3"
                  value={personalFilter?.activos ?? false}
                  onValueChange={(data) => onValueChange(data, 'activos')}
                />
              </Labeled>
            </CustomCol>
          </RowContainer>
        </CCardBody>
      </CCollapse>
    </>
  )
}

export default React.memo(FiltroBusqueda)