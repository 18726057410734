import { RequestHelper } from "../../../../../../../helpers/requestHelper"


export const BalancesServicesPagar = {
  loadOpeningBalances,
  loadNumLastBalances,
  saveBalances,
  deleteBalance
}

async function loadOpeningBalances(obj: any): Promise<any> {
  const object_request = {
    proveedorCodigo: obj['codigo'],
    fecha: obj['fecha'],
    numero: obj['numero'],
  }
  try {
    const data = await RequestHelper.getAll<any>(
      'proveedores/documentosPorPagar',
      'loadInitialBalance',
      '',
      object_request
    )
    return data
  } catch (error) {
    return error
  }
}


async function loadNumLastBalances(obj: any): Promise<any> {
  const object_request = {
    proveedorCodigo: obj['codigo'],
    numero: obj['numero'],
  }
  try {
    const data = await RequestHelper.getAll<any>(
      'proveedores/documentosPorPagar',
      'loadNumLastBalances',
      '',
      object_request
    )
    return data
  } catch (error) {
    return error
  }
}
async function saveBalances(obj: any): Promise<any> {
  const object_request = {
    infoRegistro: {
      compraCodigo: obj["comCodigo"],
      compraTipoDocumento: "99",
      compraNumero: obj["comNumero"],
      compraAutorizacion: obj["comAutorizacion"],
      compraEstablecimiento: obj["comEstablecimiento"],
      compraPuntoEmision: obj["comPuntoEmision"],
      compraFecha: obj["comFecha"],
      compraGuia: "",
      compraSubtotal12: 0,
      compraSubtotal0: 0,
      compraDescuento: 0,
      compraSubtotal: 0,
      compraSubtotalNoIVA: 0,
      compraSubtotalExcento: 0,
      compraICE: 0,
      compraIVA: 0,
      compraTotal: obj["saldoAnterior"],
      compraFormaPago: 8,
      proveedorCodigo: obj["prvCodigo"],
      empresaCodigo: obj["empCodigo"],
      localCodigo: obj["locCodigo"],
      compraTipo: 1,
      compraEnvio: 0,
      compraEmpaque: 0,
      movimientosInventarioCodigo: 0,
      compraObservaciones: obj["comObservaciones"],
      sustentoCodigo: "00",
      comprasComprobanteAfectado: 0,
      detalleItems: obj["items"],
      detalleSeries: obj["series"],
      detalleTipoPago: obj["tipoPago"],
    }
  }
  try {
    const data = await RequestHelper.postAll<any>(
      'compras/compras',
      'save',
      object_request
    )
    return data
  } catch (error) {
    return error
  }
}

async function deleteBalance(obj: any): Promise<any> {
  const object_request = {
    codigo: obj['codigo'],
    fecha: obj['fecha'],
  }
  try {
    const data = await RequestHelper.deleteRequestAll<any>(
      'proveedores/documentosPorPagar',
      'deleteIf',
      '',
      object_request
    )
    return data
  } catch (error) {
    return error
  }
}