import React from 'react';
import Button from 'devextreme-react/button';
import ActionSheet from 'devextreme-react/action-sheet';
import { GrupoInventario } from '../../../../../store/types';

interface IActionSheetCommandsMobileProps extends React.PropsWithChildren {
  grupo: GrupoInventario
  onDelete: (grupo: GrupoInventario) => void,
  onEdit: (grupo: GrupoInventario) => void,
  onSinc: (grupo: GrupoInventario) => void,
}

export const ActionSheetCommandsMobile: React.FC<IActionSheetCommandsMobileProps> = (props) => {
  const { onDelete, grupo, onEdit, onSinc } = props;
  const [visible, setVisible] = React.useState<boolean>(false);
  const [commands, setCommands] = React.useState<Array<any>>([]);

  const onButtonClick = () => {
    setVisible(!visible)
  }

  const onItemClick = React.useCallback(async (e) => {
    const { itemData } = e;
    switch (itemData.text) {
      case "Eliminar":
        onDelete(grupo)
        break;
      case "Editar":
        onEdit(grupo)
        break;
      case "Sincronizar Woo":
        onSinc(grupo)
        break;

      default:
        break;
    }
  }, [onDelete, grupo, onEdit, onSinc])

  React.useEffect(() => {
    const cmds: Array<any> = [
      { text: 'Eliminar', icon: 'trash' },
      { text: 'Editar', icon: 'edit' },
      { text: 'Sinc Woo', icon: 'globe' },
    ];
    setCommands(cmds)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const keyId = `filamovimiento${grupo.codigo ?? grupo.descripcion}`
  return (
    <>
      <Button id={keyId} icon='overflow' type='default' onClick={onButtonClick} />
      <ActionSheet
        title="Tareas"
        usePopover={true}
        visible={visible}
        target={'#' + keyId}
        items={commands}
        itemComponent={ActionSheetCommand}
        onItemClick={onItemClick}
        onVisibleChange={(value) => {
          if (value !== visible) setVisible(!visible)
        }}
      />
    </>
  );
};



const ActionSheetCommand: React.FC<any> = (props) => {

  return <Button stylingMode='contained' type='default' text={props.data.text} icon={props.data.icon} />
}