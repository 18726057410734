import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../../store/store";
import { FETCH_STATUS } from "../../../../../store/types";
import { anexoTransaccionalService } from "../../../services/anexoTransaccional.services";
import { PERIOD_TYPE } from "../../../types/types";
import { AnexoTransacListado, FiltroBusquedaAnexoTransaccionalState, GenericOption, MonthOption, SearchStateAnexoTransaccional } from "../types/types";


const initialState: SearchStateAnexoTransaccional<AnexoTransacListado> = {
  filter: {
    anio: null,
    tipoPeriodo: PERIOD_TYPE.MONTLY,
    periodo: null,
    mes: null,
    incluirElectronicos: null,
    tipoValidacion: null
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: "",
  resultados: [],
  finalizaBusqueda: false
};

export const fetchAnexoTransaccional = createAsyncThunk<
  Array<AnexoTransacListado>,
  FiltroBusquedaAnexoTransaccionalState
>("compras/compras/getDataRetention", async (filtro) => {
  try {
    const anexoData = await anexoTransaccionalService.getAnexoTransac(filtro);
    return anexoData;
  } catch (error) {
    return Promise.reject(error);
  }
});

const searchAnexoSlice = createSlice({
  name: "searchAnexos",
  initialState: initialState,
  reducers: {
    resetState(state) {
      state = {
        ...initialState,
        filter: {
          ...initialState.filter,
        },
      };
    },
    setMes(state, action: PayloadAction<MonthOption | null>) {
      state.filter.mes = action.payload;
    },
    setAnio(state, action: PayloadAction<GenericOption | null>) {
      state.filter.anio = action.payload;
    },
    setTipoPeriodo(state, action: PayloadAction<any>) {
      state.filter.tipoPeriodo = action.payload;
    },
    setPeriodo(state, action: PayloadAction<GenericOption>) {
      state.filter.periodo = action.payload;
    },
    setIncluidElectronicos(state, action: PayloadAction<GenericOption | null>) {
      state.filter.incluirElectronicos = action.payload;
    },
    setTipoValidacion(state, action: PayloadAction<GenericOption | null>) {
      state.filter.tipoValidacion = action.payload;
    },
    setCleanResult(state) {
      state.resultados = [];
      state.finalizaBusqueda = false;
    },
    setResultados(state, action: PayloadAction<Array<AnexoTransacListado> | []>) {
      state.resultados = action.payload;
      state.finalizaBusqueda = false;
    },
    setResetEndBusqueda(state) {
      state.finalizaBusqueda = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAnexoTransaccional.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING;
        state.mensaje = "";
      }
    });
    builder.addCase(fetchAnexoTransaccional.fulfilled, (state, { payload }) => {
      state.resultados = payload;
      state.finalizaBusqueda = true;
      state.status = FETCH_STATUS.SUCCESS;
    });
    builder.addCase(fetchAnexoTransaccional.rejected, (state, { payload }) => {
      state.resultados = [];
      state.finalizaBusqueda = false;
      state.status = FETCH_STATUS.FAILED;
      state.mensaje = payload as string;
    });
  },
});

export const selectFiltroBusquedaAnexos = (state: RootState) => {
  return state.impuestos.anexoTransaccional.search.filter;
};
export const selectResultadosBusquedaAnexos = (state: RootState) => {
  return state.impuestos.anexoTransaccional.search.resultados;
};
export const selectBusquedaAnexosEstado = (state: RootState) => {
  return state.impuestos.anexoTransaccional.search.status;
};
export const {
  setResetEndBusqueda,
  setResultados,
  setAnio,
  setMes,
  setTipoPeriodo,
  setPeriodo,
  setIncluidElectronicos,
  setTipoValidacion,
  resetState,
  setCleanResult
} = searchAnexoSlice.actions;
export const searchAnexoReducer = searchAnexoSlice.reducer;
