import { DateUtils, formatoFechasApi } from '../../../../helpers/dateUtils';
import { RequestHelper } from '../../../../helpers/requestHelper'
import { utilidades } from '../../../../helpers/utilidades';
import { SesionService } from '../../../../services/sesion.service';
import { TipoProveedor } from '../../../ventas/types/types';

export const tipoProveedorService = { getTiposProveedor }

async function getTiposProveedor(label: string): Promise<any> {
  try {
    const data: any = {
      mensaje: label,
    }
    const sesion = SesionService.getCurrentSesion()
    const keysToCache: any = {
      ...data,
      dateKey: DateUtils.getCurrentDateAsString(formatoFechasApi),
      empresa: sesion?.empresa?.codigo,
    }
    const cacheKey = 'tipoProveedor' + JSON.stringify(keysToCache);
    const cached = sessionStorage.getItem(cacheKey);
    if (cached) {
      return { error: false, auto: JSON.parse(cached) };
    } else {
      const resApi = await RequestHelper.getAll<Array<any>>(
        'proveedores/proveedores',
        'supplier/cboxLoad',
        '',
        data,
      );
      const opt: Array<TipoProveedor> = [];
      if (resApi?.auto && resApi?.auto.length > 0) {
        for (const x of resApi.auto) {
          opt.push({
            codigo: x?.codigo ?? '-1',
            descripcion: utilidades.capText(x?.descripcion) ?? '',
          })
        }
      }
      sessionStorage.setItem(cacheKey, JSON.stringify(opt));
      return resApi
    }
  } catch (error) {
    return error
  }
}
