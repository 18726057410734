import { combineReducers } from '@reduxjs/toolkit'
import { DocCuentasXCobrarState } from '../types/types'
import { searchDocumentosReducer } from './searchReducer'
import { datosEdicionDocumentosCtaCobrarReducer } from './editDataReducer'
import { menuReducer } from './menuReducer'
import { tabsReducer } from './tabsReducer'
import { abonosDocumentosCtasCobrarReducer } from './abonosReducer'
import { clientesxCobrarReducer } from './clientesReducer'
import { configuracionesDocsCtosXCobrarReducer } from './configuracionesDocsCtasXCobrar'
import { anticiposReducer } from './anticiposReducer'
import { verAbonosReducer } from './verAbonosReducer'
import { saldosReducer } from './saldosReducer'
import { carteraReducer } from './carteraReducer'

export const docCuentasXCobrarReducer = combineReducers<DocCuentasXCobrarState>({
  menu: menuReducer,
  tabs: tabsReducer,
  search: searchDocumentosReducer,
  editData: datosEdicionDocumentosCtaCobrarReducer,
  abonos: abonosDocumentosCtasCobrarReducer,
  clientes: clientesxCobrarReducer,
  configuraciones: configuracionesDocsCtosXCobrarReducer,
  anticipos: anticiposReducer,
  verAbonos: verAbonosReducer,
  saldos: saldosReducer,
  cartera: carteraReducer,
})
