import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FETCH_STATUS } from '../../../../../store/types'
import {
  SpecialtyDetail,
  FiltrosSpecialtyState,
  SearchState,
} from '../types/types'
import { specialityServices } from '../services/specialty.services'

const initialState: SearchState<SpecialtyDetail> = {
  filter: {
    nombre: '',
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  resultados: [],
}

export const fetchSpeciality = createAsyncThunk<
  Array<SpecialtyDetail>,
  FiltrosSpecialtyState
>('proveedores/especialidad/listar', async (filtro) => {
  try {
    const specialtyList = specialityServices.getSpeciality(filtro.nombre)
    return specialtyList
  } catch (error) {
    return Promise.reject(error)
  }
})

const searchSlice = createSlice({
  name: 'searchSpecialty',
  initialState: initialState,
  reducers: {
    setNombre(state, action: PayloadAction<string>) {
      state.filter.nombre = action.payload
    },
    setCleanResult(state) {
      state.resultados = []
    },
    changeFilter(state, action: PayloadAction<FiltrosSpecialtyState>) {
      state.filter = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSpeciality.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchSpeciality.fulfilled, (state, { payload }) => {
      state.resultados = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchSpeciality.rejected, (state, { payload }) => {
      state.resultados = []
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
  },
})

export const { setNombre, changeFilter, setCleanResult } = searchSlice.actions

export const searchReducer = searchSlice.reducer
