import React from 'react'
import { CCol } from '@coreui/react-pro'
import { Button, DataGrid } from 'devextreme-react'
import { Column, FilterRow, HeaderFilter, Pager, Paging, Button as DatagridButton } from 'devextreme-react/data-grid'
import RowContainer from '../../../../../../../../views/componentes/rowContainer/rowContainer'
import { MarcaInventario } from '../../../../../../../inventario/store/types'
import { ModalAdminMarcas } from '../../../../../../../inventario/components/buscarMarca/modalAdminMarcas/modalAdminMarcas'
import { DatosEdicionMarcas } from '../../../../types/types'
import { ToastTypes } from '../../../../../../../../store/types'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../../store/store'
import Dialog from '../../../../../../../../views/componentes/librerias/bootstrap-dialog'
import { MessagesKeys, lh } from '../../../../../../../../helpers/localizationHelper'
import { setTabMarcas } from '../../../../store/editDataReducer'

interface ILookUpMarcasProps extends React.PropsWithChildren {
  tabId: string
  setToast: (sms: string, tipo: ToastTypes) => void
  refForm: any
}

const Marcas: React.FC<ILookUpMarcasProps> = (props,) => {

  const { tabId, setToast } = props
  const dispatch = useDispatch()

  const gruposState = useSelector((state: RootState) => { return state.clientes.clientes.editData[tabId]?.tabs?.grupos })
  const marcasState = useSelector((state: RootState) => { return state.clientes.clientes.editData[tabId]?.tabs?.marcas })

  const tablaRefPager = React.useRef<any>()
  const dialogRef = React.useRef<any>(null);

  const [showModal, setShowModal] = React.useState<boolean>(false)


  const deleteItemTable = React.useCallback(() => {
    const filtrado = marcasState.marcas.findIndex((item: any) => {
      return Number(item.codigo) === Number(marcasState.marca["codigo"]);
    });
    const rowsBefore = marcasState.marcas.slice();
    if (filtrado > -1) {
      rowsBefore.splice(filtrado, 1);
      dispatch(setTabMarcas({
        key: tabId,
        data: {
          marcas: rowsBefore,
          marca: null
        }
      }))
    }
  }, [marcasState, tabId, dispatch]);





  const selectionChanged = React.useCallback((selectionChanged) => {
    if (selectionChanged['selectedRowsData'].length > 0) {
      dispatch(setTabMarcas({
        key: tabId,
        data: {
          ...marcasState,
          marca: selectionChanged['selectedRowsData'][0]
        }
      }))
    }
  }, [tabId, marcasState, dispatch]);



  const addMarca = React.useCallback((e: MarcaInventario) => {
    if (e !== undefined) {
      const marcas = marcasState.marcas.slice();
      console.log(e)
      const existItem = marcas.find((obj: MarcaInventario) => obj.codigo === e.codigo)
      if (existItem === undefined) {
        marcas.push({
          ...e,
          lineaCodigo: gruposState.grupo.perteneceCodigo,
          lineaDescripcion: gruposState.grupo.perteneceNombre,
          grupoCodigo: gruposState.grupo.codigo,
          grupoDescripcion: gruposState.grupo.descripcion
        })

        dispatch(setTabMarcas({
          key: tabId,
          data: {
            ...marcasState,
            marcas: marcas
          }
        }))
        setShowModal(false)
      } else {
        setToast("No se puede volver a agregar este grupo", ToastTypes.Warning)
      }
    }
  }, [marcasState, gruposState, setToast, dispatch, tabId]);

  const showPopupCondition = React.useCallback((): boolean => {
    if (marcasState.marcas.length === 0) {
      return true
    } else {
      return false
    }
  }, [marcasState])

  const onConfirmarUpdate = React.useCallback(() => {
    const sms = lh.getMessage(MessagesKeys.FormConfirmDeleteForm);
    dialogRef.current.show({
      title: <><strong>{"Ácatha"}</strong></>,
      body: <label style={{ fontSize: "14px", fontWeight: 600 }}>{sms}</label>,
      actions: [
        Dialog.Action(
          <span><u>{"A"}</u>{"ceptar"}</span>,
          (dialog) => {
            deleteItemTable()
            dialog.hide();
          },
          'btn-success',
          'n'
        ),
        Dialog.Action(
          <span><u>{"C"}</u>{"ancelar"}</span>,
          (dialog) => {
            dialog.hide();
          },
          'btn-danger',
          'c'
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide();
      }
    });
    return;
  }, [deleteItemTable]);


  return (
    <>
      <div>
        <Dialog ref={dialogRef} />
      </div>
      {showModal &&
        <ModalAdminMarcas
          onChanged={(data) => addMarca(data)}
          grupos={[gruposState.grupo.codigo]}
          lineas={[gruposState.grupo.perteneceCodigo]}
          onCancel={() => setShowModal(!showModal)}
          show={showModal}
        />}
      <RowContainer>
        <CCol style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '4px' }}>
          <Button
            id="btnAdd"
            icon="add"
            stylingMode="contained"
            type="default"
            onClick={() => {
              setShowModal(!showModal)
            }}
            disabled={gruposState.grupo === null}
          />
          <Button
            style={{ marginLeft: '6px' }}
            id="btnRemove"
            icon="trash"
            stylingMode="contained"
            type="danger"
            onClick={() => {
              onConfirmarUpdate()
            }}
            disabled={marcasState.marca === null}
          />
        </CCol>
      </RowContainer>
      <RowContainer>
        {
          showPopupCondition() && (
            <div style={{ color: '#e22020' }}>
              <label>{'Es necesario seleccionar al menos una Linea y Grupo para añadir una Marca'}</label>
            </div>
          )
        }
        <CCol className="d-flex justify-content-end">{marcasState.marcas.length}</CCol>
        <DataGrid
          selection={{ mode: 'single' }}
          hoverStateEnabled={true}
          dataSource={marcasState.marcas}
          showBorders={true}
          showRowLines={true}
          width={'100%'}
          onSelectionChanged={selectionChanged}
        >
          <FilterRow visible={true} />
          <HeaderFilter visible={true} />
          <Paging
            enabled={true}
            defaultPageSize={10}
          />
          <Pager
            ref={tablaRefPager}
            showPageSizeSelector={true}
            allowedPageSizes={[8, 12, 20]}
            showNavigationButtons={true}
            showInfo={true}
            infoText="Page #{0}. Total: {1} ({2} items)"
          />
          <Column type='buttons' width='7%' >
            <DatagridButton icon='arrowleft' visible={true} hint='Seleccionar'
              onClick={(e) => { console.log(e) }} />
          </Column>
          <Column dataType="string" dataField="nombre" caption="Marcas" width="100%" />
        </DataGrid>
      </RowContainer>
    </>
  )
}

export default Marcas

export const defaultDatosEdicionMarcas: DatosEdicionMarcas = {
  marcas: [],
  marca: null
}