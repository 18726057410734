import { CCol } from '@coreui/react-pro'
import React from 'react'

type columnProps =
  | boolean
  | number
  | string
  | {
    size?: boolean | number | string
    order?: string | number
    offset?: string | number
  }

interface ICustomColProps {
  xs?: columnProps
  sm?: columnProps
  md?: columnProps
  lg?: columnProps
  xl?: columnProps
  xxl?: columnProps
  children?: React.ReactNode
  style?: any
  className?: string
}

const CustomCol: React.FunctionComponent<ICustomColProps> = (props) => {
  return (
    <CCol
      xs={props.xs}
      sm={props.sm}
      md={props.md}
      lg={props.lg}
      xl={props.xl}
      xxl={props.xxl}
      className={props.className}
    >
      {props.children}
    </CCol>
  )
}

export default CustomCol
