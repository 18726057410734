import React, { useEffect } from 'react'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput'
import {
  Button,
  CheckBox,
  DataGrid,
  DateBox,
  NumberBox,
  TextArea,
  TextBox,
} from 'devextreme-react'
import { TranformacionesDatosEdicion } from '../../types/types'
import { ButtonTypes } from '../../../../../../../views/componentes/globalMenu/types'
import { clearButtonClick, openTab } from '../../store/tabsReducer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import {
  changeLoaderTransformaciones,
  setCurrentFunction,
} from '../../store/generalReducer'
import { initDatosEdicion } from '../../store/editDataReducer'
import Dialog from '../../../../../../../views/componentes/librerias/bootstrap-dialog'
import { v4 as uuidv4 } from 'uuid'
import { defaultDataTransformation } from '../..'
import { TabState } from '../../../../../../../store/genericTabTypes'
import { CCol, CRow } from '@coreui/react-pro'
import LocalesLookUp from '../../../../../../componentes/localesLookUp'
import { Column } from 'devextreme-react/data-grid'
import { footerStyles } from '../../../ingresosEgresos/components/nuevo'
import ItemsCountIndicator from '../../../../../../componentes/indicadorNroRegistros'
import TiposCombinacionesLookUp from '../../../../../../componentes/combinacionesLookUp'
import ProcesoTransformacionLookUp from '../../../../../../componentes/procesoTransformacionLookUp'

interface INewProps extends React.PropsWithChildren {
  tab: TabState<TranformacionesDatosEdicion>
  tabId: string
}

const Nuevo: React.FunctionComponent<INewProps> = (props) => {
  const { tab, tabId } = props
  const dispatch = useDispatch()
  const currentAction = useSelector(
    (state: RootState) =>
      state.inventarios.movimientos.transformaciones.general.currentFunction,
  )
  const unitsState = useSelector((state: RootState) => {
    return state.inventarios.movimientos.transformaciones.editData[tabId]
  })
  const tabs = useSelector((state: RootState) => {
    return state.inventarios.movimientos.transformaciones.tabs
  })

  const [confirmNew, setonfirmNew] = React.useState<boolean>(false)
  const validationGroupRef = React.useRef<any>()
  const dialogRef = React.useRef<any>(null)
  const inventoryDatagrid = React.useRef<any>()

  const playLoader = React.useCallback(
    (message = 'Cargando...') => {
      dispatch(changeLoaderTransformaciones({ show: true, mensaje: message }))
    },
    [dispatch],
  )

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoaderTransformaciones({ show: false, mensaje: '' }))
  }, [dispatch])

  const onConfirmNew = React.useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea crear un nuevo registro?`,
      actions: [
        Dialog.Action(
          <span>
            <u>P</u>estaña Actual
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-success',
          'p',
        ),
        Dialog.Action(
          <span>
            <u>N</u>ueva Pestaña
          </span>,
          (dialog) => {
            dialog.hide()
            const id = uuidv4()
            dispatch(
              initDatosEdicion({
                key: id,
                data: defaultDataTransformation,
              }),
            )
            dispatch(openTab({ key: id }))
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setonfirmNew(false)
    return
  }, [dispatch])

  React.useEffect(() => {
    if (confirmNew) {
      onConfirmNew()
    }
  }, [confirmNew, onConfirmNew])

  const validateState = React.useCallback((selectedState: any) => {
    if (selectedState.value.codigo > -1) {
      return Promise.resolve()
    } else {
      return Promise.reject('Estado: Debe seleccionar una opción')
    }
  }, [])

  const onHandleSave = React.useCallback(async () => {
    console.log('implements handlle to SAVE ')
  }, [])

  const onHandleUndo = React.useCallback(() => {
    console.log('implements handlle to UNDO ')
  }, [])

  const handleButtonClick = React.useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.save:
          if (tabs.current === tabId) onHandleSave()
          break
        case ButtonTypes.new:
          if (tabs.current === tabId) setonfirmNew(true)
          break
        case ButtonTypes.undo:
          if (tabs.current === tabId) onHandleUndo()
          break
        default:
          break
      }
      dispatch(setCurrentFunction(''))
      dispatch(clearButtonClick(tabId))
    },
    [dispatch, onHandleSave, onHandleUndo, tabId, tabs],
  )

  useEffect(() => {
    if (currentAction !== '') handleButtonClick(currentAction)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAction])

  return (
    <RowContainer className="m-2">
      <Dialog ref={dialogRef} />
      <RowContainer>
        <CustomCol xs="12" md="2">
          <Labeled label="Fecha:">
            <DateBox
              applyValueMode="useButtons"
              displayFormat="dd/MM/yyyy"
              value={null}
              onValueChanged={() => {}}
              width="100%"
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="3">
          <Labeled label="Local:">
            <LocalesLookUp
              onChanged={() => {}}
              onChangedOptions={() => {}}
              selected={null}
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="3">
          <Labeled label="Proceso:">
            <ProcesoTransformacionLookUp
              onChanged={() => {}}
              selected={null}
              provider={[]}
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="3">
          <Labeled label="Combinación:">
            <TiposCombinacionesLookUp
              onChanged={() => {}}
              onChangedOptions={() => {}}
              selected={null}
            />
          </Labeled>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="5">
          <Labeled label="Resultado:">
            <CRow>
              <CCol xs="12" md="3">
                <TextBox
                  value={''}
                  //onValueChanged={onChangedName}
                  showClearButton
                />
              </CCol>
              <CCol xs="12" md="6">
                <TextBox
                  value={''}
                  //onValueChanged={onChangedName}
                  showClearButton
                />
              </CCol>
              <CCol xs="12" md="3">
                <TextBox
                  value={''}
                  //onValueChanged={onChangedName}
                  showClearButton
                />
              </CCol>
            </CRow>
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="1">
          <Labeled label="Stock:">
            <NumberBox
              value={null}
              showClearButton
              //onValueChanged={onChangedName}
            />
          </Labeled>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="3">
          <Labeled label="Pureza:">
            <div style={{ display: 'flex', gap: '3px', alignItems: 'center' }}>
              De:
              <NumberBox
                value={null}
                showClearButton
                //onValueChanged={onChangedName}
              />
              ml A:
              <NumberBox
                value={null}
                showClearButton
                //onValueChanged={onChangedName}
              />
              ml
            </div>
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="1">
          <Labeled label="Cantidad:">
            <NumberBox
              value={null}
              showClearButton
              //onValueChanged={onChangedName}
            />
          </Labeled>
        </CustomCol>
        <CCol xs="12" md="2" style={{ marginTop: '42px', marginLeft: '-15px' }}>
          gr componente principal
        </CCol>
        <CustomCol xs="12" md="2">
          <Labeled label="Resultado Real:">
            <NumberBox
              value={null}
              showClearButton
              //onValueChanged={onChangedName}
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="1">
          <Labeled label="Merma:">
            <div style={{ display: 'flex', gap: '3px', alignItems: 'center' }}>
              <NumberBox
                value={null}
                showClearButton
                //onValueChanged={onChangedName}
              />
              %0
            </div>
          </Labeled>
        </CustomCol>
      </RowContainer>
      <ItemsCountIndicator items={[]} />
      <RowContainer>
        <DataGrid
          style={{ marginTop: '10px' }}
          ref={inventoryDatagrid}
          keyExpr="codigo"
          //focusedRowKey="codigo"
          id="tabNewInictialInventory"
          selection={{ mode: 'single' }}
          dataSource={[]}
          showColumnLines={true}
          showRowLines={true}
          showBorders={true}
          columnResizingMode="widget"
          //focusedRowEnabled={true}
          ///onSelectionChanged={onSelectedLineChanged}
          loadPanel={{
            enabled: true,
            height: 90,
            indicatorSrc: '',
            shading: false,
            shadingColor: '',
            showIndicator: true,
            showPane: true,
            text: 'Cargando...',
            width: 200,
          }}
        >
          <Column dataField="" caption="Interno" width="" />
          <Column dataField="" caption="Barras" width="" />
          <Column dataField="" caption="Item" width="" />
          <Column dataField="" caption="Stock" width="" />
          <Column dataField="" caption="Cantidad" width="" />
        </DataGrid>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="5">
          <Labeled label="Observaciones:">
            <TextArea
              value={''}
              showClearButton
              //onValueChanged={onChangedName}
            />
          </Labeled>
        </CustomCol>
      </RowContainer>
    </RowContainer>
  )
}

export default Nuevo
