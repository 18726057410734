import React, { useState, useEffect } from 'react';
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
} from '@coreui/react-pro'
//import { tarjetasService } from "../../service/modaltarjetas.services";
import { useDispatch, useSelector } from 'react-redux'
import { TextBox } from "devextreme-react/text-box";
import ValidationSummary from "devextreme-react/validation-summary";
import { Validator, RequiredRule, AsyncRule, StringLengthRule } from "devextreme-react/validator";
import ValidationGroup from 'devextreme-react/validation-group';
import { Lookup, DropDownOptions } from 'devextreme-react/lookup';
import { RootState } from '../../../../../store/store';
import { ToastTypes } from '../../../../../store/types';
import { addToast } from '../../../../../store/toasterReducer';
import { changeLoader } from '../../../../../store/reducers';
import { setResetSeleccion } from "../../store/modalTarjetasReducer"
import { tarjetasService } from "../../service/modaltarjetas.services"
import NumberBox from 'devextreme-react/number-box'
import { setCurrentAction, setCurrentFunction, setDpTarjetas, setRegistros } from "../../store/modalTarjetasReducer";

type NuevoProps = {
}

const Nuevo = (props: NuevoProps) => {

  const dispatch = useDispatch()
  const validationGroupRef = React.useRef<any>();

  const currentAction = useSelector((state: RootState) => state.bancos.modalTarjetas.currentAction)
  const currentFunction = useSelector((state: RootState) => state.bancos.modalTarjetas.currentFunction)
  const modalTarjetas = useSelector((state: RootState) => state.bancos.modalTarjetas.modal)
  const registros = useSelector((state: RootState) => state.bancos.modalTarjetas.registros)
  const dbtarjetasState = useSelector((state: RootState) => state.bancos.modalTarjetas.dbtarjetas)

  const [tarjetas_cb, setTarjetas_cb] = useState<any>('');
  const [dbtarjetas, setDbtarjetas] = useState<Array<any>>([]);
  const [comisiones_cb, setComisiones_cb] = useState<any>('');
  const [dpcomisiones, setDpcomisiones] = useState<Array<any>>([]);
  const [recap_ti, setRecap_ti] = useState<any>('');
  const [lote_ti, setLote_ti] = useState<any>('');
  const [autorizacion_ti, setAutorizacion_ti] = useState<any>('');
  const [valor_ti, setValor_ti] = React.useState<number>(0);
  const [total_ti, setTotal_ti] = useState<number>(0);
  const [totales, setTotales] = useState<number>(0);
  // cargar estos datos cuando seleccionae en el padre
  const [VEN_CODIGO, setVEN_CODIGO] = React.useState<number>(0);
  const [ING_CODIGO, setING_CODIGO] = React.useState<number>(0);
  const [TAV_CODIGO, setTAV_CODIGO] = React.useState<number>(0);


  useEffect(() => {
    if (dbtarjetasState.length === 0) {
      obtenerTarjetas();
    } else {
      setDbtarjetas(dbtarjetasState)
      setTarjetas_cb(dbtarjetasState[0])
    }
  }, []);

  useEffect(() => {
    if (registros.length > 0) {
      obtenerTotal(registros);
    } else {
      setTotal_ti(0)
    }
  }, [registros]);

  useEffect(() => {
    if (currentFunction === "Guardar") {
      dispatch(setCurrentFunction(""));
      handleSubmit();
    } else if (currentFunction === "Deshacer") {
      dispatch(setCurrentFunction(""))
      if (currentAction === "Nuevo") {
        handleReset();
      } else if (currentAction === "Editar") {
        resetDataTable();
      }
    }
  }, [currentFunction, currentAction]);

  useEffect(() => {
    // if (currentAction === "Editar") {
    //   copiarDatosToFormulario();
    // } else if (currentAction === "Nuevo") {
    //   dispatch(setResetSeleccion())
    //   handleReset()
    // }
  }, [currentAction]);


  const obtenerTarjetas = React.useCallback(async () => {
    const find = {
      mensaje: 'Elija una tarjeta',
      estado: 1
    };
    try {
      const data = await tarjetasService.cargarTarjetas(find);
      if (data["error"] === false) {
        if (data['auto'].length > 0) {
          const dataTipos = data['auto'];
          const tarjetas: any = [];
          Object.keys(dataTipos).map(function (key, i) {
            return tarjetas.push({
              value: Number(dataTipos[key]["codigo"]),
              label: dataTipos[key]["nombre"],
            });
          });
          setDbtarjetas(tarjetas)
          setTarjetas_cb(tarjetas[0])
          dispatch(setDpTarjetas(tarjetas))
        }
      }
      return await data;
    } catch (error) {
      console.log("error", error);
      setToast(JSON.stringify(error), "danger");
    }
  }, [])


  const resetDataTable = () => {
    dispatch(setRegistros([]))
  }

  const obtenerTotal = (data) => {
    const total: number = 0;
    let totales: number = 0;
    data.map(function (key, i) {
      totales = totales + key['TAV_VALOR'];
    });
    setTotal_ti(totales)
    setTotales(totales)
  }



  const setToast = (texto, type) => {
    let tipo: ToastTypes = ToastTypes.Info;
    if (type === 'warning') {
      tipo = ToastTypes.Warning;
    } else if (type === 'success') {
      tipo = ToastTypes.Success;
    } if (type === 'info') {
      tipo = ToastTypes.Info;
    } if (type === 'danger') {
      tipo = ToastTypes.Danger;
    }
    dispatch(addToast({
      id: '',
      autoHide: 3500,
      title: 'Acatha',
      content: texto,
      fade: true,
      type: tipo
    }));
  }

  const playLoader = () => {
    dispatch(changeLoader({ show: true, mensaje: 'Cargando...' }))
  }

  const stopLoader = () => {
    dispatch(changeLoader({ show: false, mensaje: '' }))
  }


  const handleReset = () => {
    obtenerTarjetas();
    setRecap_ti("")
    setLote_ti("")
    setAutorizacion_ti("")
    setValor_ti(0)
  }

  const validarBusqueda = React.useCallback(async () => {
    const errors: any = []

    if (comisiones_cb && comisiones_cb.value <= 0) {
      errors.push('- Comisión: debe elegir una.')
    }
    if (lote_ti === "") {
      errors.push('- Ingrese el # de lote.')
    }
    // let total_ti: Number = (totales = Number(valor_ti)).toFixed(2);
    // if (comisiones_cb && comisiones_cb.value <= 0) {
    //   errors.push('- Totales: el valor total suma una cantidad mayor al valor adeudado')
    // }
  }, []);

  const handleSubmit = async () => {
    const result = validationGroupRef.current.instance.validate();
    if (result.isValid) {

      const resValidarBusqueda = await validarBusqueda();

      playLoader()

      let data_request: any = {}

      data_request = {
        codigoTAV: currentAction === 'Nuevo' ? 0 : TAV_CODIGO,
        codigoING: '',
        codigoCMO: comisiones_cb['value'],
        valor: valor_ti,
        lote: lote_ti,
        recap: recap_ti,
        autorizacion: autorizacion_ti,
      }

      if (modalTarjetas.codModulo === 400) {
        data_request.codigoVenta = VEN_CODIGO;
      } else if (modalTarjetas.codModulo === 24) {
        data_request.codigoVenta = 0;
        data_request.codigoING = ING_CODIGO;
      } else {
        data_request.codigoVenta = VEN_CODIGO;
      }


      try {
        const data = await tarjetasService.registrarTarjetaV(data_request);
        stopLoader()
        if (data['error'] === true) {
          setToast(data['message'], "warning");
        } else {
          if (currentAction === "Editar") {
            setToast(data['message'], "success");
            //dispatch(setData([]));
          } else {
            setToast(JSON.stringify(data['message']), "success");
          }
          dispatch(setResetSeleccion(null))
          dispatch(setCurrentAction("Buscar"))
          dispatch(setCurrentFunction('Buscar'))
        }
        return await data;
      } catch (error) {
        console.log('error', error);
        stopLoader()
        setToast(JSON.stringify(error), "danger")
      }
    } else {
      setToast("Tiene errores por favor verifíquelos.", "info");
    }
  }

  const validarFormulario = () => {
    let mensaje: string = "";
    if (comisiones_cb['value'] <= 1) {
      mensaje = mensaje + "- Comisión: debe elegir una.\n";
    }
    if (lote_ti === "") {
      mensaje = mensaje + "- Ingrese el # de lote.\n";
    }
    // total_ti.text = (totales + Number(valor_ti)).toFixed(2);
    // if (Number(total_ti.text) > valorTarjeta) {
    //   mensaje = mensaje + "- Totales: el valor total suma una cantidad mayor al valor adeudado\n";
    // }
    return mensaje;
  }

  const validateAsyncSeleccion = (datoSeleccion) => {
    return verificarSeleccion(datoSeleccion);
  };

  const verificarSeleccion = (value) => {
    const validador = 0;
    return new Promise((resolve) => {
      setTimeout(function () {
        if (value["value"]["value"] !== undefined) {
          resolve(
            Number(value["value"]["value"]) > Number(validador) === true
              ? true
              : false
          );
        } else {
          resolve(true);
        }
      }, 1000);
    });
  };

  const cargarComisiones = async (tarjeta) => {
    playLoader()
    setDpcomisiones([])
    const find = {
      mensaje: 'Elija una comision',
      codigo: tarjeta['value']
    };
    try {
      const data = await tarjetasService.cargarComisiones(find);
      stopLoader()
      if (data["error"] === false) {
        if (data['auto'].length > 0) {
          const dataTipos = data['auto'];
          const comision: any = [];
          Object.keys(dataTipos).map(function (key, i) {
            return comision.push({
              value: Number(dataTipos[key]["codigoComision"]),
              label: dataTipos[key]["nombreComision"],
            });
          });
          setDpcomisiones(comision)
          setComisiones_cb(comision[0])
        }
      }
      return await data;
    } catch (error) {
      stopLoader()
      console.log("error", error);
      setToast(JSON.stringify(error), "danger");
    }
  }

  return (

    <CCard>
      <CCardHeader >
        <strong>{currentAction}</strong>
      </CCardHeader>
      <CCardBody>


        <ValidationGroup ref={validationGroupRef}>
          <CRow>
            <CCol lg="8" md="8" sm="12">
              <div className="dx-field">
                <div className="dx-field-label">{"Tarjetas de Crédito Aceptadas:"}</div>
                <div className="dx-field-value ">
                  <Lookup
                    width="100%"
                    dataSource={dbtarjetas}
                    displayExpr="label"
                    searchEnabled={true}
                    searchMode={"contains"}
                    searchExpr={"label"}
                    searchTimeout={200}
                    minSearchLength={3}
                    showDataBeforeSearch={true}
                    placeholder="Seleccionar"
                    showClearButton={true}
                    value={tarjetas_cb}
                    onValueChanged={(data) => {
                      if (data.value) {
                        setTarjetas_cb(data.value);
                        cargarComisiones(data.value)
                      } else {
                        setTarjetas_cb(dbtarjetas[0]);
                        setDpcomisiones([])
                      }
                    }}
                  >
                    <Validator>
                      <RequiredRule message={"Debe seleccionar la tarjeta de credito"} />
                      <AsyncRule
                        message={'Debe seleccionar la tarjeta de credito'}
                        validationCallback={validateAsyncSeleccion}
                      />
                    </Validator>
                  </Lookup>
                </div>
              </div>

              <div className="dx-field">
                <div className="dx-field-label">{"Comisión:"}</div>
                <div className="dx-field-value ">
                  <Lookup
                    width="100%"
                    dataSource={dpcomisiones}
                    displayExpr="label"
                    searchEnabled={true}
                    searchMode={"contains"}
                    searchExpr={"label"}
                    searchTimeout={200}
                    minSearchLength={3}
                    showDataBeforeSearch={true}
                    placeholder="Seleccionar"
                    showClearButton={true}
                    defaultValue={comisiones_cb}
                    value={comisiones_cb}
                    onValueChanged={(data) => {
                      if (data.value) {
                        setComisiones_cb(data.value);
                      } else {
                        setComisiones_cb(dpcomisiones[0]);
                      }
                    }}
                  >
                    <Validator>
                      <RequiredRule message={"- Comisión:debe elegir una. "} />
                      <AsyncRule
                        message={"- Comisión:debe elegir una. "}
                        validationCallback={validateAsyncSeleccion}
                      />
                    </Validator>
                  </Lookup>
                </div>
              </div>

              <div className="dx-field">
                <div className="dx-field-label">{"Recap:"}</div>
                <div className="dx-field-value">
                  <TextBox
                    width={"100%"}
                    value={recap_ti}
                    placeholder=""
                    onValueChanged={(data) => {
                      setRecap_ti(data.value);
                    }}
                  >
                    <Validator>
                      <StringLengthRule message={"Recap: No puede exceder los 50 caracteres."} max={50} />
                    </Validator>
                  </TextBox>
                </div>
              </div>

              <div className="dx-field">
                <div className="dx-field-label">{"Lote:"}</div>
                <div className="dx-field-value">
                  <TextBox
                    width={"100%"}
                    value={lote_ti}
                    placeholder=""
                    onValueChanged={(data) => {
                      setLote_ti(data.value);
                    }}
                  >
                    <Validator>
                      <RequiredRule message={"- Lote:  Ingrese el # de lote"} />
                      <StringLengthRule message={"- Lote: No puede exceder los 15 caracteres."} max={15} />
                    </Validator>
                  </TextBox>
                </div>
              </div>

              <div className="dx-field">
                <div className="dx-field-label">
                  {"Autorizacion:"}
                </div>
                <div className="dx-field-value">
                  <TextBox
                    width={"100%"}
                    value={autorizacion_ti}
                    placeholder=""
                    onValueChanged={(data) => {
                      setAutorizacion_ti(data.value);
                    }}
                  >
                    <Validator>
                      <StringLengthRule message={"Autorizacion: No puede exceder los 50 caracteres."} max={50} />
                    </Validator>
                  </TextBox>
                </div>
              </div>

              <div className="dx-field">
                <div className="dx-field-label">
                  {"Valor:"}
                </div>
                <div className="dx-field-value">
                  <NumberBox
                    width={"100%"}
                    value={valor_ti}
                    placeholder=""
                    onValueChanged={(data) => {
                      setValor_ti(Number(data.value));
                    }}
                  >
                    <Validator>
                      <StringLengthRule message={"Valor: requerida"} max={75} />
                    </Validator>
                  </NumberBox>
                </div>
              </div>

            </CCol>
          </CRow>
          <CRow>
            <CCol lg="6" md="8" sm="12">
              <ValidationSummary id="summary"></ValidationSummary>
            </CCol>
          </CRow>
        </ValidationGroup>


      </CCardBody>

    </CCard>


  );
}

export default Nuevo;