import React from 'react'
import { useSelector } from 'react-redux'
import DataGrid, {
  Column,
  Paging,
  Pager,
  ColumnChooser,
  HeaderFilter,
  Export,
} from 'devextreme-react/data-grid'
import { DatosAnuladas, DatosCompra, DatosVenta } from '../../../../types/types'
import { VentaHelperSV } from '../../../../../../../../ventas/helpers/ventaHelperSV'
import { RootState } from '../../../../../../../../../../../store/store'
import { getAllowedPageSizes } from '../../../../../../../../../../../helpers/Helper'

interface ITablaAnuladosProps extends React.PropsWithChildren {
  data: Array<DatosVenta | DatosCompra> | []
  // eslint-disable-next-line no-unused-vars
  onExport: (data) => void
}
const pageSizes = [10, 25, 50, 100, 200]

const TablaAnulados: React.FunctionComponent<ITablaAnuladosProps> = (props) => {
  const { data, onExport } = props

  const empresa = useSelector((state: RootState) => {
    return state.global.session?.empresa
  })

  const localSession = useSelector((state: RootState) => {
    return state.global.session?.local
  })

  const filtros = useSelector((state: RootState) => {
    return state.impuestosSV.anexoTransaccional.search.filter
  })

  const dataGrid = React.useRef<any>()
  const [showInfo] = React.useState<boolean>(true)
  const [showNavButtons] = React.useState<boolean>(true)
  const [showPageSizeSelector] = React.useState<boolean>(true)
  const [displayMode] = React.useState<string | 'full' | 'compact'>('full')

  const [parsedData, setParsedDate] = React.useState<Array<DatosAnuladas>>([])

  const onExporting = React.useCallback(
    (e) => {
      const rango = `${filtros?.fechaInicio}-${filtros?.fechaFinal}`
      const fileName = `anexo_anulados-${rango}`
      if (e.format === 'csv') {
        onExport({
          title: 'Anulados',
          format: e.format,
          component: e.component,
          filename: fileName,
        })
      }
    },
    [filtros, onExport],
  )

  const parseData = React.useCallback(() => {
    const dataParsed: Array<DatosAnuladas> = []

    data.map((item: DatosVenta) => {
      const numeroResolucion =
        'DTE-' +
        VentaHelperSV.generarCodigoControl(
          item.tipoDocumentoCodigo,
          item.establecimiento,
          item.puntoEmision,
          Number(item.numero),
          empresa?.comercial,
          localSession?.tipo,
        ).content

      const codigoGeneneracion =
        item?.autorizacionHacienda?.codigoGeneracion?.replaceAll('-', '') ?? ''

      dataParsed.push({
        numeroResolucion: numeroResolucion,
        claseDocumento: 4,
        desdePreImpreso: 0,
        hastaPreImpreso: 0,
        tipoDocumento: item.tipoDocumentoCodigo,
        tipoDetalle: 'D',
        serie: item?.autorizacionHacienda?.selloRecibido ?? 'N/A',
        desde: 0,
        hasta: 0,
        codigoGeneracion: codigoGeneneracion,
      })
    })

    setParsedDate(dataParsed)
  }, [data, empresa, localSession])

  React.useEffect(() => {
    parseData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <DataGrid
      ref={dataGrid}
      selection={{ mode: 'single' }}
      allowColumnResizing={true}
      columnResizingMode={'nextColumn'}
      focusedRowEnabled={true}
      keyExpr="numeroResolucion"
      focusedRowKey="numeroResolucion"
      dataSource={parsedData}
      showBorders={true}
      onExporting={onExporting}
      width="100%"
    >
      <Pager
        visible={parsedData.length > 0}
        allowedPageSizes={getAllowedPageSizes(data)}
        displayMode={displayMode}
        showPageSizeSelector={showPageSizeSelector}
        showInfo={showInfo}
        showNavigationButtons={showNavButtons}
      />
      <Export
        enabled={true}
        allowExportSelectedData={false}
        formats={['csv']}
      />
      <ColumnChooser enabled={true} mode="select" />
      <HeaderFilter visible={true} />
      <Column
        dataField="numeroResolucion"
        caption="NUMERO DE RESOLUCIÓN"
        minWidth="160px"
        width="10%"
      />
      <Column
        dataField="claseDocumento"
        caption="CLASE DE DOCUMENTO"
        minWidth="60px"
        width="10%"
      />
      <Column
        dataField="desdePreImpreso"
        caption="SECUENCIAL - DESDE"
        minWidth="60px"
        width="10%"
      />
      <Column
        dataField="hastaPreImpreso"
        caption="SECUENCIAL - HASTA"
        minWidth="60px"
        width="10%"
      />
      <Column
        dataField="tipoDocumento"
        caption="TIPO DE DOCUMENTO"
        minWidth="140px"
        width="10%"
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />
      <Column
        dataField="tipoDetalle"
        caption="TIPO DE DETALLE"
        minWidth="100px"
        width="10%"
        visible={true}
      />
      <Column dataField="serie" caption="SERIE" minWidth="100px" width="10%" />
      <Column
        dataField="desde"
        caption="DESDE"
        minWidth="60px"
        width="10%"
        visible={true}
      />
      <Column
        dataField="hasta"
        caption="HASTA"
        minWidth="60px"
        width="10%"
        visible={true}
      />
      <Column
        dataField="codigoGeneracion"
        caption="CÓDIGO DE GENERACIÓN"
        minWidth="150px"
        width="10%"
        alignment="right"
        allowFiltering={false}
      />
      <Pager
        allowedPageSizes={pageSizes}
        showPageSizeSelector={true}
        showInfo
      />
      <Paging defaultPageSize={10} />
    </DataGrid>
  )
}
export default TablaAnulados
