import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { IGenericTabProps } from '../../../../store/types'
import {
  AssigndedComand,
  MenuCommand,
  MenuItem,
  MenuItemTree,
  Position,
} from '../../types/types'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput'
import Arbol from '../../../../../contabilidad/pages/planCuentas/components/arbol'
import { useDispatch, useSelector } from 'react-redux'
import { useSetToast } from '../../../../../../hooks/useGlobalHooks'
import { useLoaderPositions } from '../../customHooks'
import { PositionsServices } from '../../services/positions.services'
import { ToastTypes } from '../../../../../../store/types'
import { setResultsMenu } from '../../store/searchReducer'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import { RootState } from '../../../../../../store/store'
import BlockUi from '../../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../../views/componentes/loadingindicator/loadingindicator'
import { DataGrid } from 'devextreme-react'
import { Column, Grouping } from 'devextreme-react/data-grid'
import { updatePermissions } from '../../store/permisosReducer'
import { clearButtonClick } from '../../store/tabsReducer'
import { CCol } from '@coreui/react-pro'
import { isMobile } from 'react-device-detect'

const Permissions: FunctionComponent<IGenericTabProps<Position>> = (props) => {
  const { tabId } = props
  const dispatch = useDispatch()
  const setToastMessage = useSetToast()
  const { showLoaderTabs } = useLoaderPositions()

  const searchState = useSelector(
    (state: RootState) => state.nomina.cargos.search,
  )
  const currentTab = useSelector(
    (state: RootState) => state.nomina.cargos.tabs.current,
  )
  const currentPosition = useSelector(
    (state: RootState) => state.nomina.cargos.tabs.tabs[tabId].info.info,
  )
  const loader = useSelector(
    (state: RootState) => state.nomina.cargos.permissions[tabId].loader,
  )
  const permission = useSelector(
    (state: RootState) => state.nomina.cargos.permissions[tabId],
  )
  const globalButtonClick = useSelector(
    (state: RootState) =>
      state.nomina.cargos.tabs.tabs[tabId].globalButtonClick,
  )

  const dataGridCommands = useRef<any>(null)
  const [selectedAvailable, setSelectedAvailable] = useState<MenuCommand>(null)
  const [indexAvailable, setIndexAvailable] = useState<number>(null)
  const [selectedAssigned, setSelectedAssigned] =
    useState<AssigndedComand>(null)

  const clearPositionsAndAssigns = useCallback(() => {
    setSelectedAvailable(null)
    setIndexAvailable(null)
    setSelectedAssigned(null)
    dispatch(
      updatePermissions({
        key: tabId,
        permission: {
          ...permission,
          assignedCommands: [],
          availableCommands: [],
        },
      }),
    )
  }, [dispatch, permission, tabId])

  const onHandleSucessfull = useCallback(
    (codePermission: number = -1, addPermission: boolean = false) => {
      const listAvailable: MenuCommand[] = structuredClone(
        permission.availableCommands,
      )
      const listAssigned: AssigndedComand[] = structuredClone(
        permission.assignedCommands,
      )
      if (addPermission) {
        listAvailable.splice(indexAvailable, 1)
        listAssigned.push({
          codigo: codePermission,
          menuBotCodigo: selectedAvailable.menuBotCodigo,
          menuCodigo: selectedAvailable.menuCodigo,
          menuNombre: selectedAvailable.menuNombre,
          indexNombre: '',
          botonCodigo: selectedAvailable.botonCodigo,
          botonDescripcion: selectedAvailable.botonDescripcion,
          asignado: currentPosition.codigo,
          tipo: 87,
          tipoDescripcion: 'ROL',
        })
        setSelectedAvailable(null)
        setIndexAvailable(null)
      } else {
        const indexAssigned = listAssigned.findIndex((assigned) => {
          return assigned.codigo === selectedAssigned.codigo
        })
        listAssigned.splice(indexAssigned, 1)
        if (
          listAvailable.length > 0 &&
          listAvailable[0].menuCodigo === selectedAssigned.menuCodigo
        )
          listAvailable.push({
            menuBotCodigo: selectedAssigned.menuBotCodigo,
            menuCodigo: selectedAssigned.menuCodigo,
            menuNombre: selectedAssigned.menuNombre,
            botonCodigo: selectedAssigned.botonCodigo,
            botonDescripcion: selectedAssigned.botonDescripcion,
            botonImagen: '',
          })
        setSelectedAssigned(null)
      }
      dispatch(
        updatePermissions({
          key: tabId,
          permission: {
            ...permission,
            assignedCommands: listAssigned,
            availableCommands: listAvailable,
          },
        }),
      )
    },
    [
      currentPosition,
      dispatch,
      indexAvailable,
      permission,
      selectedAssigned,
      selectedAvailable,
      tabId,
    ],
  )

  const deletePermission = useCallback(async () => {
    if (selectedAssigned) {
      try {
        showLoaderTabs(
          true,
          tabId,
          true,
          'Eliminando Permisos . . .',
          ButtonTypes.permissions,
        )

        const deletePermission =
          await PositionsServices.deletePermission<boolean>(
            selectedAssigned.codigo,
          )
        if (
          !deletePermission.error &&
          deletePermission.auto &&
          typeof deletePermission.auto === 'boolean' &&
          deletePermission.auto === true
        )
          onHandleSucessfull()

        setToastMessage(
          'Eliminar Permisos',
          deletePermission.message,
          deletePermission.error ? ToastTypes.Warning : ToastTypes.Success,
        )
        showLoaderTabs(false, tabId, true)
      } catch (error) {
        showLoaderTabs(false, tabId, true)
        setToastMessage('Eliminar Permisos', error, ToastTypes.Danger)
      }
    } else
      setToastMessage(
        'Eliminar Permisos',
        'Debe elegir un comando asignado para eliminarlo.',
        ToastTypes.Warning,
      )
  }, [
    onHandleSucessfull,
    selectedAssigned,
    setToastMessage,
    showLoaderTabs,
    tabId,
  ])

  const addPermission = useCallback(async () => {
    if (selectedAvailable) {
      try {
        showLoaderTabs(
          true,
          tabId,
          true,
          'Guardando Permisos . . .',
          ButtonTypes.permissions,
        )

        const savePermission = await PositionsServices.savePermission<number>({
          infoRegistro: {
            codigo: 0,
            menuBotCodigo: selectedAvailable.menuBotCodigo,
            asignado: currentPosition.codigo,
            tipo: 87,
          },
        })
        if (
          !savePermission.error &&
          savePermission.auto &&
          typeof savePermission.auto === 'number' &&
          !isNaN(savePermission.auto)
        )
          onHandleSucessfull(savePermission.auto, true)

        setToastMessage(
          'Guardar Permisos',
          savePermission.message,
          savePermission.error ? ToastTypes.Warning : ToastTypes.Success,
        )
        showLoaderTabs(false, tabId, true)
      } catch (error) {
        showLoaderTabs(false, tabId, true)
        setToastMessage('Guardar Permisos', error, ToastTypes.Danger)
      }
    } else
      setToastMessage(
        'Eliminar Permisos',
        'Antes de guardar la   información, seleccione el comando a asignar',
        ToastTypes.Warning,
      )
  }, [
    currentPosition,
    onHandleSucessfull,
    selectedAvailable,
    setToastMessage,
    showLoaderTabs,
    tabId,
  ])

  const getAvailableCommands = useCallback(
    async (data: any) => {
      if (Number(data?.id)) {
        try {
          showLoaderTabs(
            true,
            tabId,
            true,
            'Cargando Comandos Disponibles. . .',
            ButtonTypes.permissions,
          )
          const avaibleComands =
            await PositionsServices.getAvailableCommands<MenuCommand>(data.id)
          if (
            !avaibleComands.error &&
            avaibleComands.auto &&
            Array.isArray(avaibleComands.auto)
          )
            dispatch(
              updatePermissions({
                key: tabId,
                permission: {
                  ...permission,
                  availableCommands: avaibleComands.auto,
                },
              }),
            )
          setToastMessage(
            'Buscar Comandos Disponibles',
            avaibleComands.message,
            avaibleComands.error ? ToastTypes.Warning : ToastTypes.Success,
          )
          showLoaderTabs(false, tabId, true)
        } catch (error) {
          showLoaderTabs(false, tabId, true)
          setToastMessage(
            'Buscar Comandos Disponibles',
            error,
            ToastTypes.Danger,
          )
        }
      }
    },
    [dispatch, permission, setToastMessage, showLoaderTabs, tabId],
  )

  const onSelectedCommandChanged = useCallback(
    (menuOption: MenuCommand, rowIndex: number) => {
      if (menuOption) {
        setSelectedAvailable(menuOption)
        setIndexAvailable(rowIndex)
      }
    },
    [],
  )

  const onSelectedAssignedChanged = useCallback((position: AssigndedComand) => {
    if (position) {
      setSelectedAssigned(position)
    }
  }, [])

  const parseMenuItemsToTree = useCallback(
    (menuItems: MenuItem[]): MenuItemTree[] => {
      const menuItemsTree: MenuItemTree[] = []
      menuItems.forEach((item) => {
        menuItemsTree.push({
          codigo: item.codigo,
          descripcion: item.name,
          padre: item.padre,
          numero: '',
        })
      })
      return menuItemsTree
    },
    [],
  )

  const loadAssignedCommands = useCallback(async () => {
    try {
      showLoaderTabs(
        true,
        tabId,
        true,
        'Cargando Comandos Asignados . . .',
        ButtonTypes.permissions,
      )
      const assigndedCommands =
        await PositionsServices.getAssignedCommands<AssigndedComand>(
          currentPosition.codigo,
        )
      if (
        !assigndedCommands.error &&
        assigndedCommands.auto &&
        Array.isArray(assigndedCommands.auto)
      )
        dispatch(
          updatePermissions({
            key: tabId,
            permission: {
              ...permission,
              assignedCommands: assigndedCommands.auto,
              isLoadedCommands: true,
            },
          }),
        )
      setToastMessage(
        'Buscar Comandos Asignados',
        assigndedCommands.message,
        assigndedCommands.error ? ToastTypes.Warning : ToastTypes.Success,
      )
      showLoaderTabs(false, tabId, true)
    } catch (error) {
      showLoaderTabs(false, tabId, true)
      setToastMessage('Buscar Comandos Asignados', error, ToastTypes.Danger)
    }
    await PositionsServices.getAssignedCommands<AssigndedComand>(
      currentPosition.codigo,
    )
  }, [
    currentPosition,
    showLoaderTabs,
    tabId,
    dispatch,
    permission,
    setToastMessage,
  ])

  const loadMenuOPtions = useCallback(async () => {
    try {
      showLoaderTabs(
        true,
        tabId,
        true,
        'Cargando . . .',
        ButtonTypes.permissions,
      )
      const menuOptions = await PositionsServices.getMenuOptions<MenuItem>()
      if (
        !menuOptions.error &&
        menuOptions.auto &&
        Array.isArray(menuOptions.auto)
      ) {
        dispatch(
          setResultsMenu({
            menuOptions: parseMenuItemsToTree(menuOptions.auto),
            isMenuLoaded: true,
          }),
        )
      }
      setToastMessage(
        'Buscar Menú',
        menuOptions.message,
        menuOptions.error ? ToastTypes.Warning : ToastTypes.Success,
      )
      await loadAssignedCommands()
      showLoaderTabs(false, tabId, true)
    } catch (error) {
      showLoaderTabs(false, tabId, true)
      setToastMessage('Buscar Menú', error, ToastTypes.Danger)
    }
  }, [
    dispatch,
    setToastMessage,
    showLoaderTabs,
    parseMenuItemsToTree,
    tabId,
    loadAssignedCommands,
  ])

  const onMenuButtonClick = useCallback(
    (actionType: string) => {
      switch (actionType) {
        case ButtonTypes.save:
          if (currentTab === tabId) addPermission()
          break
        case ButtonTypes.delete:
          if (currentTab === tabId) deletePermission()
          break
        default:
          break
      }
      dispatch(clearButtonClick(tabId))
    },
    [addPermission, currentTab, deletePermission, dispatch, tabId],
  )

  useEffect(() => {
    if (globalButtonClick !== ButtonTypes.none)
      onMenuButtonClick(globalButtonClick)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalButtonClick])

  useEffect(() => {
    clearPositionsAndAssigns()
    if (!searchState.isMenuLoaded) loadMenuOPtions()
    else if (!permission.isLoadedCommands) loadAssignedCommands()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <BlockUi
      tag="div"
      loader={LoadingIndicator}
      blocking={loader.show && tabId === currentTab}
      message={loader.mensaje}
    >
      <RowContainer className="m-2">
        <span>{currentPosition.descripcion}</span>
        <CCol xs="12" md="4" style={{ marginBottom: isMobile ? '15px' : 0 }}>
          <Labeled label="Opciones de Menú:">
            <Arbol
              name="three-menu-options"
              data={searchState.menuOptions}
              jerarquiaName="Menú"
              activedense={false}
              sendData={getAvailableCommands}
            />
          </Labeled>
        </CCol>
        <CustomCol xs="12" md="4">
          <DataGrid
            className="mb-3"
            ref={dataGridCommands}
            keyExpr="menuBotCodigo"
            id={crypto.randomUUID()}
            selection={{ mode: 'single' }}
            dataSource={permission.availableCommands}
            showColumnLines={true}
            showRowLines={true}
            showBorders={true}
            columnResizingMode="widget"
            loadPanel={{ enabled: true }}
            allowColumnResizing={true}
            remoteOperations={true}
            onRowClick={({ data, rowIndex }) =>
              onSelectedCommandChanged(data, rowIndex)
            }
            onCellDblClick={() => addPermission()}
          >
            <Column
              caption="Comandos Disponibles"
              dataField="botonDescripcion"
              width={'100%'}
            />
          </DataGrid>
        </CustomCol>
        <CustomCol xs="12" md="4">
          <DataGrid
            className="mb-30"
            ref={dataGridCommands}
            keyExpr="menuBotCodigo"
            id={crypto.randomUUID()}
            selection={{ mode: 'single' }}
            dataSource={permission.assignedCommands}
            showColumnLines={true}
            showRowLines={true}
            showBorders={true}
            columnResizingMode="widget"
            loadPanel={{ enabled: true }}
            allowColumnResizing={true}
            remoteOperations={true}
            onRowClick={(data: any) => {
              if (!data?.data?.key) onSelectedAssignedChanged(data.data)
            }}
          >
            <Grouping autoExpandAll={false} />
            <Column groupIndex={0} dataField="menuNombre" caption="Menú" />
            <Column
              caption="Comandos Asignados"
              dataField="botonDescripcion"
              width={'100%'}
            />
          </DataGrid>
        </CustomCol>
      </RowContainer>
    </BlockUi>
  )
}

export default Permissions
