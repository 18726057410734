import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DateUtils } from '../../../../../../helpers/dateUtils'
import { RootState } from '../../../../../../store/store'
import { FiltroBusquedaState, SearchState } from '../../../../types/types'
import { ComprasSVListado, SincronizadosListado } from '../types/types'
import { FETCH_STATUS } from '../../../../../../store/types'
import { utilidades } from '../../../../../../helpers/utilidades'
import { ComprasSVService } from '../../../services/comprasSV.service'

const initialState: SearchState<SincronizadosListado> = {
  filter: {
    fechaInicio: DateUtils.getCurrentDateAsString(),
    fechaFin: DateUtils.getCurrentDateAsString(),
    puntoVenta: '',
    activas: false,
    documento: '',
    pendientesAbutorizar: false,
    proveedor: null,
    tipoDoc: null,
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  resultados: [],
}

export const fetchSync = createAsyncThunk<
  Array<SincronizadosListado>,
  FiltroBusquedaState
>('compras/extractElectronics', async (filtro) => {
  try {
    const syncRes = await ComprasSVService.buscarSincronizadosSV(
      {
        inicio: DateUtils.pickersDateToApiDate(filtro.fechaInicio),
        fin: DateUtils.pickersDateToApiDate(filtro.fechaFin),
      },
      filtro.documento,
      filtro?.proveedor ? filtro?.proveedor?.identificacion : undefined,
      filtro.tipoDoc,
      filtro?.formaPago ? filtro?.formaPago?.codigo.toString() : undefined,
      filtro.claveAcceso,
    )

    let data = syncRes

    if (syncRes.length > 0) {
      data = syncRes
    }
    return data
  } catch (error) {
    return Promise.reject(error)
  }
})

const searchSyncSlice = createSlice({
  name: 'searchSinc',
  initialState: initialState,
  reducers: {
    resetState(state) {
      state = {
        ...initialState,
        filter: {
          ...initialState.filter,
          fechaInicio: DateUtils.getCurrentDateAsString(),
          fechaFin: DateUtils.getCurrentDateAsString(),
        },
      }
    },
    changeFilter(state, action: PayloadAction<FiltroBusquedaState>) {
      state.filter = action.payload
    },
    setCleanResultSync(state) {
      state.resultados = []
    },
    setResultados(state, action: PayloadAction<any>) {
      state.resultados = action.payload.resultados
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSync.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchSync.fulfilled, (state, { payload }) => {
      state.resultados = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchSync.rejected, (state, { payload }) => {
      state.resultados = []
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
  },
})

export const selectFiltroBusquedaSincronizados = (state: RootState) => {
  return state.compras.comprasSV.compras.searchSync.filter
}
export const selectResultadosBusquedaSincronizados = (state: RootState) => {
  return state.compras.comprasSV.compras.searchSync.resultados
}
export const selectBusquedaSincronizadosEstado = (state: RootState) => {
  return state.compras.comprasSV.compras.searchSync.status
}
export const { setResultados, changeFilter, resetState, setCleanResultSync } =
  searchSyncSlice.actions
export const searchSincronizadosSVReducer = searchSyncSlice.reducer
