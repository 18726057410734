import { CCol, CRow } from '@coreui/react-pro';
import React from 'react';
import { NumberBox } from 'devextreme-react/number-box'
import { RootState } from '../../../../../../../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import {
  update10VenInicialFinal,
  updateDptotales10Only, updateDptotalesOnly,
  updateLoadDpCierre10Only,
  updateTotalTesoreria_tiOnly
} from '../../../store/editDataReducer';
import DataGrid, {
  Column, HeaderFilter, Paging, Pager,
  Grouping,
  GroupPanel, ColumnChooser, Export
} from 'devextreme-react/data-grid';
import RowContainer from '../../../../../../../../../views/componentes/rowContainer/rowContainer';
import CustomCol from '../../../../../../../../../views/componentes/colContainer';
import { utilidades } from '../../../../../../../../../helpers/utilidades';
import { useGridResultadosSize } from '../../../../../../../../../hooks/useGridResultadosSize';
import { getAllowedPageSizes } from '../../../../../../../../../helpers/Helper';
import { AbonoComprasCuadresCajaListado, ccDetalle, formasPagoCuadre } from '../../../../../types/types';
import TabFormasPago from '../componentes/tabFormasPago/tabFormasPago';
import { GlobalLabel } from '../../../../../../../../../views/componentes/globalLabel/globalLabel';
import { exportFormats } from '../facturas/facturas';

interface IPagosTesoreriaProps extends React.PropsWithChildren {
  setToast: (texto, type) => void,
  playLoader: () => void,
  stopLoader: () => void,
  onErrorConfirm: (sms, title) => void,
  onConfirmarRegistro: (sms, method) => void
  tabId: string,
  statusLoaded: (load) => void
}

const PagosTesoreria: React.FunctionComponent<IPagosTesoreriaProps> = (props) => {
  const {
    tabId, setToast, playLoader, stopLoader,
    onErrorConfirm, onConfirmarRegistro, statusLoaded
  } = props;
  const dataGrid = React.useRef<any>();

  const dispatch = useDispatch();
  const [heigth] = useGridResultadosSize();

  const local = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[tabId].local });
  const puntoVenta = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[tabId].puntoVenta });
  const fechaCierre = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].fechaCierre });
  const venInicial10 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venInicial10 });
  const venFinal10 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venFinal10 });
  const generarCuadre = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].generarCuadre });
  const loaddpcierre10 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].loaddpcierre10 });

  const totaltesoreria_ti = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].totaltesoreria_ti });
  const dptotales10 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dptotales10 });
  const dpfpagos = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dpfpagos });
  const dptotales = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dptotales });
  const dpcierre10 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dpcierre10 });

  const [displayMode,] = React.useState<string | 'full' | 'compact'>('full');
  const [showInfo,] = React.useState<boolean>(true);
  const [showNavButtons,] = React.useState<boolean>(true);
  const [showPageSizeSelector,] = React.useState<boolean>(true);

  const resultadoCuadresAbonos = React.useCallback(async (dpCierreFacturasVar: Array<AbonoComprasCuadresCajaListado> | []) => {

    const detalle = {
      ccdCodigo: 0,
      ccaCodigo: "",
      subValor: "",
      ccdValor: 0,
      subCodigo: 0,
    };

    let valor: number = 0;
    const dptotales10Local: Array<formasPagoCuadre> = [];
    if (dpCierreFacturasVar.length === 0) {
      for (const fpg of dpfpagos) {
        dptotales10Local.push({
          subCodigo: Number(fpg.codigo) ?? -1,
          subValor: String(fpg.descripcion) ?? "",
          ccdValor: valor,
        });
      }

      dispatch(updateDptotales10Only({
        dptotales10: dptotales10Local,
        key: tabId
      }));

    } else {

      console.log('else')
      console.log('dpCierreFacturasVar', dpCierreFacturasVar)

      let i = 0;

      for (let j = 0; j <= dpfpagos.length - 1; j++) {
        valor = 0;
        for (i = 0; i < dpCierreFacturasVar.length; i++) {
          if (dpCierreFacturasVar[i].compraTipoDocumentoCodigo === dpfpagos[j].codigo) {
            valor = valor + Number(Number(dpCierreFacturasVar[i].abonoCuotaValor).toFixed(2));
          }
        }

        dptotales10Local.push({
          subCodigo: Number(dpfpagos[j].codigo),
          subValor: String(dpfpagos[j].descripcion),
          ccdValor: Number(Number(valor).toFixed(2)),
        });

      }

      const VEN_INICIAL10 = dpCierreFacturasVar[0].compraCodigo;
      const VEN_FINAL10 = dpCierreFacturasVar[dpCierreFacturasVar.length - 1].compraCodigo;

      dispatch(update10VenInicialFinal({
        rango: {
          inicial: VEN_INICIAL10,
          final: VEN_FINAL10
        },
        key: tabId
      }));

      dispatch(updateDptotales10Only({
        dptotales10: dptotales10Local,
        key: tabId
      }));

      let k = 0;

      let total: number = 0;
      for (k = 0; k < dptotales10Local.length; k++) {
        total = total + dptotales10Local[k].ccdValor;
      }
      total = Number(Number(total).toFixed(2));
      dispatch(updateTotalTesoreria_tiOnly({
        totaltesoreria_ti: total,
        key: tabId
      }));

      const dptotalesLocal: any = dptotales.slice() ?? [];
      if (dptotales10Local.length > 0) {
        for (i = 0; i < dpfpagos.length; i++) {
          let x: ccDetalle | null = null;
          try {
            x = dptotales[i];
          } catch (ex) {
            x = null;
          }
          if (x == null) {
            const addDetallTotales: ccDetalle = {
              ...detalle,
              ccdValor: Number(Number(dptotales10Local[i].ccdValor * -1).toFixed(2)),
              subCodigo: dpfpagos[i].codigo,
              subValor: dpfpagos[i].descripcion,
            }
            dptotalesLocal.push({ ...addDetallTotales });
          } else {
            const y: number = Number(x?.ccdValor ?? 0) - Number(dptotales10Local[i]?.ccdValor ?? 0);

            dptotalesLocal[i] = {
              ...x, ccdValor: y ?? 0
            }
          }

        }

        dispatch(updateDptotalesOnly({
          dptotales: dptotalesLocal,
          key: tabId
        }));

      }
    }


  }, [dispatch, tabId, dpfpagos, dptotales]);

  const getPagosCuadres = React.useCallback(async () => {
    let dpCierreRet = [];
    if (dpcierre10) {
      dpCierreRet = dpcierre10
    }
    await resultadoCuadresAbonos(dpCierreRet)
    dispatch(updateLoadDpCierre10Only({
      loaddpcierre10: false,
      key: tabId
    }));
  }, [
    dispatch, tabId, dpcierre10, resultadoCuadresAbonos
  ]);


  const onLoadClose = React.useCallback(async () => {
    statusLoaded(true)
  }, [statusLoaded]);

  React.useEffect(() => {
    if (generarCuadre === true) {
      onLoadClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generarCuadre])

  React.useEffect(() => {
    if (loaddpcierre10 === true) {
      getPagosCuadres();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaddpcierre10])

  return (
    <>
      <CRow className={'m-2'}>
        <CCol lg="12" xl="12" sm="12">
          <RowContainer >
            <CustomCol xs='12' md='12'>
              <DataGrid
                selection={{ mode: 'single' }}
                focusedRowEnabled={true}
                keyExpr='compraCodigo'
                dataSource={dpcierre10}
                showBorders={true}
                width={utilidades.getGridFullWidth()}
                allowColumnResizing
                columnResizingMode='widget'
                height={heigth}
                ref={dataGrid}
              >
                <Export enabled={true} formats={exportFormats} />

                <GroupPanel visible={true} />
                <Grouping autoExpandAll={false} />
                <Paging defaultPageSize={20} />
                <Pager
                  visible={dpcierre10.length > 0 ? true : false}
                  allowedPageSizes={getAllowedPageSizes(dpcierre10)}
                  displayMode={displayMode}
                  showPageSizeSelector={showPageSizeSelector}
                  showInfo={showInfo}
                  showNavigationButtons={showNavButtons}
                />
                <HeaderFilter visible={true} />
                <ColumnChooser enabled={true} mode="select" />
                <Column dataField='egresoValor' caption='' width='20%' allowEditing={false} groupIndex={0} />
                <Column dataField='compraFecha' caption='Fecha de Registro' width='20%' allowEditing={false} />
                <Column dataField='proveedorNombre' caption='Proveedor' width='20%' allowEditing={false} />
                <Column dataField='vencimiento' caption='Vencimiento' width='15%' allowEditing={false} />
                <Column dataField='abonoFecha' caption='Fecha Abono' allowEditing={false} width={'15%'} />
                <Column dataField='compraTipoDocumentoDescripcion' caption='Comprobante' width='15%' allowEditing={false} visible={false} />
                <Column dataField='puntoVentaHost' caption='POS' width='15%' allowEditing={false} />
                <Column dataField='compraNumero' caption='Número' width='20%' allowEditing={false} visible={false} />
                <Column dataField='compraFormaPagoDescripcion' caption='Forma Pago' width='20%' allowEditing={false} visible={false} />
                <Column dataField='abonoCompraTipoPagoDocumentoDescripcion' caption='Doc.' width='20%' allowEditing={false} visible={false} />
                <Column dataField='compraCuota' caption='Cuota' width='20%' allowEditing={false} />
                <Column dataField='abonoCuotaValor' caption='Pago' width='20%' allowEditing={false} />
                <Column dataField='pagoCuotaSaldo' caption='Saldo' width='20%' allowEditing={false} />
              </DataGrid>
            </CustomCol>
          </RowContainer>
        </CCol>
      </CRow>
      <CRow className="m-2 row">
        <CCol lg="12" md="12" sm="12" className="d-flex justify-content-end  align-items-center">
          <CCol lg="3" md="3" sm="12" className="d-flex justify-content-end  align-items-center">
            <TabFormasPago
              tabId={tabId}
              dptotales={dptotales10}
            />
          </CCol>
        </CCol>
      </CRow>
      <CRow className="m-2 row">
        <CCol lg="12" md="12" sm="12" className="d-flex justify-content-end  align-items-center">
          <CCol lg='2' md="2" xs="12">
            <>
              <GlobalLabel htmlFor="total" className="col-form-label-md">
                <strong> {"Total"} </strong>
              </GlobalLabel>
              <CRow>
                <CCol md="12">
                  <NumberBox
                    readOnly
                    width="100%"
                    value={totaltesoreria_ti}
                    placeholder=""
                    format="#,##0.00"
                  />
                </CCol>
              </CRow>
            </>
          </CCol>
        </CCol>
      </CRow>
    </>
  )
}
export default PagosTesoreria;
