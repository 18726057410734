import React from 'react'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput'
import { Button, DateBox, NumberBox, SelectBox, TextBox } from 'devextreme-react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import { EAplicationsAcatha } from '../../../../../../../store/enumsAplication'
import { TFormaPagoOption } from '../../../../../../componentes/formasPagoLookUp/store/type'

const Nuevo = () => {

  const decimo = useSelector((state: RootState) => state.nomina.rol.decimos.config.appThree)



  const getItemDisplayExpr = React.useCallback((item: TFormaPagoOption) => {
    return item ? `${item.descripcion}` : '';
  }, []);

  return (
    <>
      <RowContainer>
        <RowContainer>
          <CustomCol xs="12" md="3">
            <Labeled label='Empleado:'>
              <div
                style={{
                  display: 'flex',
                  gap: '2px',
                  alignItems: 'center',
                  width: '100%',
                }}
              >

                <TextBox
                  value={''}
                  onValueChange={(data) => { }}
                />
                <Button
                  id="btnAdd"
                  className="me-1"
                  stylingMode="contained"
                  type="default"
                  onClick={() => { }}
                >{'+'}</Button>
              </div>
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="3">
            <Labeled label='Periodo:'>
              <div
                style={{
                  display: 'flex',
                  gap: '2px',
                  alignItems: 'center',
                  width: '100%',
                }}
              >

                <NumberBox
                  value={null}
                  onValueChange={(data) => { }}
                />
                <Button
                  id="btnAdd"
                  className="me-1"
                  stylingMode="contained"
                  type="default"
                  onClick={() => { }}
                >{'Calcular'}</Button>
              </div>
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="3">
            <Labeled label='Fecha de Emision:'>
              <DateBox />
            </Labeled>
          </CustomCol>
        </RowContainer>
        {decimo === parseInt(EAplicationsAcatha.DecimoTercero) && <RowContainer>
          <p>{'Formula = Sueldo + (H.Trabajo Nocturno+H. Suplementarias+H. Extraordinarias) + Comisiones+Bonificacion+Ajuste Sueldo'}</p>
        </RowContainer>}

        <RowContainer>
          <CustomCol xs="12" md="3">
            <Labeled label={`Decimo ${decimo === parseInt(EAplicationsAcatha.DecimoTercero) ? 'Tercero' : 'Cuarto'}:`}>
              <NumberBox value={null} />
            </Labeled>
          </CustomCol>
        </RowContainer>
        <RowContainer>
          <h6>{'Opción válida para pagos'}</h6>
          <CustomCol xs="12" md="3">
            <Labeled label='Fecha de Cobro:'>
              <DateBox
                value={null}
                onValueChange={(data) => { }} />
            </Labeled>
          </CustomCol>
        </RowContainer>
        <RowContainer>
          <CustomCol xs="12" md="3">
            <Labeled label="Forma de Pago:">
              <SelectBox
                id='TFormaPagoOptionSelect'
                items={[]}
                searchEnabled
                displayExpr={getItemDisplayExpr}
                searchExpr={['descripcion']}
                searchTimeout={500}
                value={null}
                onValueChange={(data) => { }}
              />
            </Labeled>
          </CustomCol>
        </RowContainer>
      </RowContainer>
    </>
  )
}

export default React.memo(Nuevo)