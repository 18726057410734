import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../../store/store";
import { Autorizacion } from "../../../../../store/types";
import { CompraInfo } from "../../../types/types";
import { ConfiguracionesCompras } from "../types/types";

const initialState: ConfiguracionesCompras = {
  usaDescuentoGlobal: false,
  autorizacion: null,
  compraSeleccionada: null,
  modulo: 0
}

const configuracionesComprasSlice = createSlice(
  {
    name: 'configuraciones',
    initialState: initialState,
    reducers: {
      initialize(state, acion: PayloadAction<Autorizacion>) {
        state.autorizacion = acion.payload;
      },
      toogleUsaDescuentoGlobal(state, acion: PayloadAction) {
        state.usaDescuentoGlobal = !state.usaDescuentoGlobal;
      },
      seleccionarCompra(state, acion: PayloadAction<CompraInfo | null>) {
        state.compraSeleccionada = acion.payload;
      },
      setCodigoModulo(state, acion: PayloadAction<number>) {
        state.modulo = acion.payload;
      },
    }
  }
)


export const selectUsaDescuentoGlobal = (state: RootState) => { return state.compras.compras.configuraciones.usaDescuentoGlobal };
export const configuracionesReducer = configuracionesComprasSlice.reducer;
export const { setCodigoModulo, seleccionarCompra, toogleUsaDescuentoGlobal } = configuracionesComprasSlice.actions;