import { GeneralState } from '../types/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LoaderInfo } from '../../../../../store/types'

const initialState: GeneralState = {
  modulo: 'inventario',
  currentAction: 'Buscar',
  currentFunction: '',
  aplicacion: 'lotes',
  loader: {
    show: false,
    mensaje: '',
  },
}

const desempenoSlice = createSlice({
  name: 'lotes',
  initialState: initialState,
  reducers: {
    setCurrentAccion(state, action: PayloadAction<string>) {
      state.currentAction = action.payload
    },
    setCurrentFunction(state, action: PayloadAction<string>) {
      state.currentFunction = action.payload
    },
    changeLoaderKardex(state, action: PayloadAction<LoaderInfo>) {
      state.loader = action.payload
    },
  },
})

export const { setCurrentAccion, setCurrentFunction, changeLoaderKardex } =
  desempenoSlice.actions

export const generalReducer = desempenoSlice.reducer
