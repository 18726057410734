import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../../store/store'
import { FETCH_STATUS } from '../../../../../store/types'
import {
  TRecintoFiscalListado,
  TFiltroBusquedaRecintoFiscalState
} from './types';
import { TConfigSearchState } from '../../store/types'
import {RecintoFiscalService} from "../services/recintoFiscal.services";
import {fetchAbonosPagar} from "../../../../proveedores/pages/docsCtasXPagar/store/seeSubscriptionsReducer";

const initialState: TConfigSearchState<TRecintoFiscalListado> = {
  filter: {
    codigo: 0,
    descripcion: '',
    codigoTributario: '',
    estado: 1,
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  resultados: [],
  selectedItem: null,
  error: false,
}

export const fetchRecintoFiscal = createAsyncThunk<
  Array<TRecintoFiscalListado>,
  TFiltroBusquedaRecintoFiscalState
>('catalogos/getAll/recintoFiscal', async (filtro) => {
  try {
    return await RecintoFiscalService.getCollection(filtro)
  } catch (error) {
    return Promise.reject(error)
  }
});

const searchConfSlice = createSlice({
  name: 'RecintoFiscalSearch',
  initialState: initialState,
  reducers: {
    resetState(state) {
      state = {
        ...initialState,
        filter: {
          ...initialState.filter,
        },
      }
    },
    changeFilter(state, action: PayloadAction<TFiltroBusquedaRecintoFiscalState>) {
      state.filter = action.payload
    },
    setCleanResult(state) {
      state.resultados = []
    },
    setResultados(state, action: PayloadAction<Array<TRecintoFiscalListado> | []>) {
      state.resultados = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRecintoFiscal.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
        state.error = false
      }
    })
    builder.addCase(fetchRecintoFiscal.fulfilled, (state, { payload }: {payload:any}) => {
      state.resultados = payload.auto
      state.status = FETCH_STATUS.SUCCESS
      state.error = payload.error
      state.mensaje = payload.message
    })
    builder.addCase(fetchAbonosPagar.rejected, (state, { payload }: {payload:any}) => {
      state.resultados = []
      state.status = FETCH_STATUS.FAILED
      state.error = payload.error
      state.mensaje = payload.message
    })
  }
});

export const selectFiltroBusquedaRecintoFiscal = (state: RootState) => {
  return state.administracion.catalagos.recintoFiscal.search.filter
}

export const selectResultadosBusquedaRecintoFiscal = (state: RootState) => {
  return state.administracion.catalagos.recintoFiscal.search.resultados
}

export const selectEstadoBusquedaRecintoFiscal = (state: RootState) => {
  return state.administracion.catalagos.recintoFiscal.search.status
}

export const { setResultados, resetState, setCleanResult, changeFilter } = searchConfSlice.actions
export const searchReducer = searchConfSlice.reducer
