import { DateUtils, formatoFechasApi } from '../../../../helpers/dateUtils'
import { RequestHelper } from '../../../../helpers/requestHelper'
import { SesionService } from '../../../../services/sesion.service'
import { CargoRol } from '../types/types'

export const payrollServices = {
  getPyarollsList,
}

async function getPyarollsList(): Promise<any> {
  try {
    const data: any = { mensaje: 'Elija una opción' }
    const sesion = SesionService.getCurrentSesion()
    const keysToCache: any = {
      ...data,
      dateKey: DateUtils.getCurrentDateAsString(formatoFechasApi),
      empresa: sesion?.empresa?.codigo,
    }
    const cacheKey = 'tipoPositionLookup' + JSON.stringify(keysToCache)
    const cachedData = sessionStorage.getItem(cacheKey)
    if (cachedData) return { error: false, auto: JSON.parse(cachedData) }
    else {
      const positionsList = await RequestHelper.getAll<any>(
        'admin/roles',
        'cboxLoad',
        '',
        data,
      )
      if (!positionsList.error && positionsList.auto) {
        const positionsListData: CargoRol[] = positionsList.auto
        sessionStorage.setItem(cacheKey, JSON.stringify(positionsListData))
        return {
          error: false,
          auto: positionsListData,
          message: positionsList.message,
        }
      } else
        return {
          error: true,
          auto: positionsList.auto,
          message: positionsList.message,
        }
    }
  } catch (error) {
    return { error: true, auto: [], message: error }
  }
}
