import React from 'react'
import Modalform from '../../../views/componentes/modalform'
import { CCard, CCardBody, CCardHeader } from '@coreui/react-pro'
import { DataGrid, LoadPanel, TextBox } from 'devextreme-react'
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/store'
import { AccionMenu, ToastTypes } from '../../../store/types'
import { ButtonTypes } from '../../../views/componentes/globalMenu/types'
import { GlobalMenu } from '../../../views/componentes/globalMenu/globalMenu'
import RowContainer from '../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../views/componentes/colContainer'
import Labeled from '../../../views/componentes/labeledInput/labeledInput'
import { Column } from 'devextreme-react/data-grid'
import { addToast } from '../../../store/toasterReducer';
import { HorariosTrabajosServices } from './services/horariosTrabajo.services';
import { consoleService } from '../../../services/console.service';
import { Hours, WorkingHour } from './types/type';
import { changeLoader } from '../../../store/reducers';

interface IModalHorarioTrabajo extends React.PropsWithChildren {
  onChanged: (data: WorkingHour) => void
  onCancel: () => void
  show: boolean
}

const menuDefecto = [
  {
    icon: 'fal fa-search',
    id: 'Buscar',
    text: 'Buscar',
    type: ButtonTypes.find,
  },
]

export const ModalHorariosTrabajo: React.FC<IModalHorarioTrabajo> = (props) => {
  const { onChanged, show, onCancel } = props
  const dispatch = useDispatch()
  const botones = menuDefecto.map(acc => {
    const boton: AccionMenu = {
      nombre: acc.id,
      icon: acc.icon,//as any,
      actionType: acc.type,
      main: false,
      modal: false,
      shortcut: null
    }
    return boton;
  });

  const loader = useSelector((state: RootState) => state.global.loader);
  const [acciones,] = React.useState<Array<AccionMenu>>(botones);
  const [descripcion, setDescripcion] = React.useState<string>('')
  const [hTrabajo, setHTrabajo] = React.useState<WorkingHour[] | []>([])
  const [horarios, setHorarios] = React.useState<Hours[] | []>([])


  const playLoader = React.useCallback(() => {
    dispatch(changeLoader({ show: true, mensaje: 'Cargando...' }))
  }, [dispatch])

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoader({ show: false, mensaje: '' }))
  }, [dispatch])

  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(
      addToast({
        title: 'Horarios',
        content: mensaje,
        type: tipo,
      }),
    )
  }, [dispatch])

  const onFind = React.useCallback(async () => {
    playLoader()
    setHTrabajo([])
    try {
      const data = await HorariosTrabajosServices.loadWHour(descripcion)
      consoleService.log(data, 'Horario Trabajo')
      if (data !== null && data !== undefined && data['error'] === false) {
        setHTrabajo(data['auto'])
        setToast(data.message, ToastTypes.Success)
      } else {
        setToast(data.message, ToastTypes.Danger)
      }
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
    stopLoader()
  }, [descripcion, setToast, playLoader, stopLoader])

  const returnAction = (boton: AccionMenu) => {

    switch (boton.actionType) {
      case ButtonTypes.find:
        onFind()
        break;
      default:
        break;
    }
  }

  const getButtonDisabled = React.useCallback((button: AccionMenu) => {
    switch (button.actionType) {
      default:
        return false;
    }
  }, [])

  const onSelectedChanged = React.useCallback(
    async ({ selectedRowsData }) => {
      consoleService.log(selectedRowsData[0], 'seleccionado selectedRowsData[0]')
      if (selectedRowsData[0]?.codigo) {
        playLoader()
        setHorarios([])
        try {
          const data = await HorariosTrabajosServices.loadHour(selectedRowsData[0]?.codigo ?? 0)
          consoleService.log(data, 'dat Horarios')
          if (data !== null && data !== undefined && data['error'] === false) {
            setHorarios(data['auto'])
            setToast(data.message, ToastTypes.Success)
          } else {
            setToast(data.message, ToastTypes.Danger)
          }
        } catch (error) {
          setToast(error.message, ToastTypes.Danger)
        }
        stopLoader()
      }
    },
    [setToast, playLoader, stopLoader],
  )

  const onDBClick = React.useCallback(
    ({ data }) => {
      consoleService.log(data, 'seleccionado dbClick')
      onChanged(data)
    },
    [onChanged],
  )

  React.useEffect(() => {
    setHTrabajo([])
    setHorarios([])
    onFind()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const botonesModal = () => {
    return (

      <GlobalMenu
        acciones={acciones}
        onClick={returnAction}
        getButtonDisabled={getButtonDisabled}
      />

    );
  }

  const bodyModal = () => {

    return (
      <>
        <RowContainer>
          <RowContainer>
            <CustomCol xs="12" md="6">
              <Labeled label='Descripción:' >
                <TextBox
                  value={descripcion}
                  onValueChange={(data) => setDescripcion(data)}
                  onEnterKey={onFind}
                />
              </Labeled>
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol xs="12" md="6">
              <Labeled label='Horario:'>
                <RowContainer>
                  <CustomCol xs="12" md="12">
                    <DataGrid
                      id="tablaHtrabajo"
                      dataSource={hTrabajo ?? []}
                      selection={{ mode: 'single' }}
                      hoverStateEnabled={true}
                      showBorders={true}
                      showRowLines={true}
                      onSelectionChanged={onSelectedChanged}
                      onRowDblClick={onDBClick}
                      repaintChangesOnly={true}
                      highlightChanges={true}
                      keyExpr="codigo"
                      key="codigo"
                      focusedRowKey="codigo"
                      showColumnLines={true}
                      className={'m-2 p-2'}
                    >
                      <Column dataField="descripcion" caption="Descripcion" width="100px" />
                    </DataGrid>
                  </CustomCol>
                </RowContainer>
              </Labeled>
            </CustomCol>
            <CustomCol xs="12" md="6">
              <Labeled label='Detalle del Horario:'>
                <RowContainer>
                  <CustomCol xs="12" md="12">
                    <DataGrid
                      id="tablaDetHtrabajo"
                      dataSource={horarios ?? []}
                      selection={{ mode: 'single' }}
                      hoverStateEnabled={true}
                      showBorders={true}
                      showRowLines={true}
                      repaintChangesOnly={true}
                      highlightChanges={true}
                      keyExpr="codigo"
                      key="codigo"
                      focusedRowKey="codigo"
                      showColumnLines={true}
                      className={'m-2 p-2'}
                    >
                      <Column dataField="descripcion" caption="Descripcion" width="100px" />
                    </DataGrid>
                  </CustomCol>
                </RowContainer>
              </Labeled>
            </CustomCol>
          </RowContainer>
        </RowContainer>
      </>
    )
  }

  const renderContent = () => {
    return (
      <CCard>
        <CCardHeader>
          {botonesModal()}
        </CCardHeader>
        <CCardBody>
          <LoadPanel
            shadingColor="rgba(0,0,0,0.4)"
            position={'center'}
            visible={loader.show}
            showIndicator={true}
            shading={true}
            showPane={true}
            message={loader.mensaje}
            closeOnOutsideClick={false}
          />
          {bodyModal()}
        </CCardBody>
      </CCard>
    )
  }

  return (
    <>
      <Modalform
        name={'seleccionarHorariosTrabajo'}
        key={'seleccionarHorariosTrabajo'}
        headerTitle={"Horarios de Trabajo"}
        childrenBody={renderContent()}
        closeOnBackdrop={false}
        show={show}
        onClose={() => onCancel()}
        centered={true}
        size='lg'
      />
    </>
  )
}
