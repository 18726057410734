import React, { useEffect } from 'react';
import ReactDom from 'react-dom';
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow, CTooltip
} from '@coreui/react-pro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux'
import Modalform from "../../../views/componentes/modalform"
import config from "./../../../config/config"
import { BotonMenu } from '../../contabilidad/pages/planCuentas/modalCuentas/store/types';
import { menuService } from '../../../services/menu.service';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import { RootState } from '../../../store/store';
import TablaTarjetas from "./componentes/tabla"
import { Registros, TIPOS_BOTONES } from "./store/types"
import { lh, MessagesKeys } from '../../../helpers/localizationHelper';
import { changeLoader } from '../../../store/reducers';
import { tarjetasService } from "./service/modaltarjetas.services";
import { ToastTypes } from '../../../store/types';
import { addToast } from '../../../store/toasterReducer';
import Nuevo from "./componentes/nuevo"
import { setCurrentAction, setCurrentFunction, setDpTarjetas, setRegistros as setRegistrosTarjetas, setResetSeleccion } from "./store/modalTarjetasReducer";
import { ingresarChequesService } from '../modalIngresoCheques/service/modalIngresarCheques.services';
import { EModulosAcatha } from '../../../store/enumsAplication';

type ModalTarjetasProps = {
  show: boolean,
  onClose: () => void,
  parametros: (evt) => void,
  pathVerificador: string,
  ejecutaAccion: () => void
  setTotales: (data: number) => void
  onSelected: (data) => void
}

type TVENTAS = {
  VEN_CODIGO?: number,
  TAV_LOTE?: string,
  ING_CODIGO?: number,
  COM_CODIGO?: number,
  EGR_CODIGO?: number,
}

const ModalTarjetas = (props: ModalTarjetasProps) => {

  const { onSelected } = props;

  const dispatch = useDispatch()
  const modalTarjetas = useSelector((state: RootState) => state.bancos.modalTarjetas.modal)

  const currentAction = useSelector((state: RootState) => state.bancos.modalTarjetas.currentAction)
  const currentFunction = useSelector((state: RootState) => state.bancos.modalTarjetas.currentFunction)
  const seleccionado = useSelector((state: RootState) => state.bancos.modalTarjetas.seleccionado)

  const [titleConfirm, setTitleConfirm] = React.useState<string>('Confirmar')
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const [confirmUpdate, setConfirmUpdate] = React.useState(false);
  const [messageConfirm, setMessageConfirm] = React.useState("Está seguro que desea realizar estos cambios?");
  const [displayDestino, setDisplayDestino] = React.useState<string>("");
  const [confirmChange, setConfirmChange] = React.useState<boolean>(false);
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [acciones, setAcciones] = React.useState<any>([]);
  const [registros, setRegistros] = React.useState<any>([]);
  const [codModulo, setCodModulo] = React.useState<number | null>(0);
  const [dbtarjetas, setDbtarjetas] = React.useState<Array<any>>([]);
  const [valor_ti, setValor_ti] = React.useState<number>(0);
  const [TAV_CODIGO, setTAV_CODIGO] = React.useState<number>(0);
  const [TAV_LOTE, setTAV_LOTE] = React.useState<string>("");
  const [valorTarjeta, setValorTarjeta] = React.useState<number>(0);

  React.useEffect(() => {
    setShowModal(props.show)
  }, [props])

  const botones = config['menuDefecto'].map(acc => {
    const boton: BotonMenu = {
      id: acc.text as any,
      icon: menuService.getIcon(acc.icon),
      text: acc.text
    }
    return boton;
  });

  useEffect(() => {
    props.setTotales(valor_ti)
  }, [valor_ti]);

  useEffect(() => {
    if (currentFunction === "Deshacer") {
      dispatch(setCurrentFunction(""))
      if (currentAction === "Buscar") {
        resetDataTable();
      }
    }
  }, [currentFunction, currentAction]);

  useEffect(() => {
    if ((modalTarjetas.open === true) && (modalTarjetas.name === "modalTarjetas")) {
      setAcciones(botones)
      setCodModulo(modalTarjetas.codModulo ?? null)
      // dispatch(setSeleccionarDato(
      //   {
      //     index: null,
      //     row: null,
      //     selectedRow: null
      //   }
      // ))
      // dispatch(setRegistros([]));
      // dispatch(setDataBack([]));
    }
  }, [modalTarjetas.open]);

  useEffect(() => {
    iniciar();
  }, []);

  useEffect(() => {
    if (currentFunction === "Buscar") {
      dispatch(setCurrentFunction(''))
      handleSubmit()
    }
  }, [currentFunction]);

  const resetDataTable = () => {
    dispatch(setRegistrosTarjetas([]))
  }

  const iniciar = React.useCallback(async () => {
    if (dbtarjetas.length === 0) {
      obtenerTarjetas();
    }
    const tventas: TVENTAS = {
      VEN_CODIGO: 0,
      TAV_LOTE: '',
      ING_CODIGO: 0,
      COM_CODIGO: 0,
      EGR_CODIGO: 0,
    }
    if (modalTarjetas.codModulo === 400) {
      const VEN_CODIGO_ = modalTarjetas.parametros.VEN_CODIGO;
      tventas.VEN_CODIGO = VEN_CODIGO_;
      await devuelveTotalSinRetencion(VEN_CODIGO_, 22)
    } else if (modalTarjetas.codModulo === 111) {
      const TAV_LOTE_ = modalTarjetas.parametros.lote_ti;
      tventas.TAV_LOTE = TAV_LOTE_;
      setTAV_LOTE(TAV_LOTE_)
      props.ejecutaAccion();
    } else if (modalTarjetas.codModulo === parseInt(EModulosAcatha.Clientes)) {
      const ING_CODIGO_ = modalTarjetas.parametros.ING_CODIGO;
      await devuelveTotalFormasPagoABV(ING_CODIGO_);
      tventas.VEN_CODIGO = 0;
      tventas.ING_CODIGO = ING_CODIGO_;
    } else if (modalTarjetas.codModulo === parseInt(EModulosAcatha.Proveedores)) {
      const EGR_CODIGO_ = modalTarjetas.parametros.EGR_CODIGO;
      await devuelveTotalFormasPagoABC(EGR_CODIGO_);
      tventas.COM_CODIGO = 0;
      tventas.EGR_CODIGO = EGR_CODIGO_;
    } else {
      const VEN_CODIGO_ = modalTarjetas.parametros.VEN_CODIGO;
      tventas.VEN_CODIGO = VEN_CODIGO_;
      await devuelveTotalSinRetencion(VEN_CODIGO_, 22)
    }
    console.log('tventas', tventas)
    await tarCargarTodosVenta(tventas)
  }, [modalTarjetas, dbtarjetas]);

  const obtenerTarjetas = React.useCallback(async () => {
    const find = {
      mensaje: 'Elija una tarjeta',
      estado: 1
    };
    try {
      const data = await tarjetasService.cargarTarjetas(find);
      if (data["error"] === false) {
        if (data['auto'].length > 0) {
          const dataTipos = data['auto'];
          const tarjetas: any = [];
          Object.keys(dataTipos).map(function (key, i) {
            return tarjetas.push({
              value: Number(dataTipos[key]["codigo"]),
              label: dataTipos[key]["nombre"],
            });
          });
          setDbtarjetas(tarjetas)
          dispatch(setDpTarjetas(tarjetas))
        }
      }
      return await data;
    } catch (error) {
      console.log("error", error);
      setToast(JSON.stringify(error), "danger");
    }
  }, [])

  const tarCargarTodosVenta = async (tventas: TVENTAS) => {
    console.log('tarCargarTodosVenta')

    const find = {
      codigo: tventas.VEN_CODIGO,
      codigoING: tventas.ING_CODIGO,
      lote: tventas.TAV_LOTE,
    }
    try {
      playLoader();
      const data = await tarjetasService.tarCargarTodosVenta(find);
      console.log('tarCargarTodosVenta data : ', data)

      if (data["error"] === false) {
        setToast(data['message'], "success");

        stopLoader();
        if (data['auto'].length && data['auto'].length > 0) {
          const tar_ventas = data['auto'];
          const tarjetas: Array<Registros> = [];
          Object.keys(tar_ventas).map(function (key, i) {
            return tarjetas.push({
              codigoTAV: tar_ventas[key]["codigoTAV"],
              codigoVenta: tar_ventas[key]["codigoVenta"],
              codigoCMO: tar_ventas[key]["codigoCMO"],
              nombreCMO: tar_ventas[key]["nombreCMO"],
              nombreTarjeta: tar_ventas[key]["nombreTarjeta"],
              valorTAV: tar_ventas[key]["valorTAV"],
              loteTAV: tar_ventas[key]["loteTAV"],
              recapTAV: tar_ventas[key]["recapTAV"],
              autorizacionTAV: tar_ventas[key]["autorizacionTAV"],
              numeroVenta: tar_ventas[key]["numeroVenta"],
              fechaVenta: tar_ventas[key]["fechaVenta"],
              codigoING: tar_ventas[key]["codigoING"],
            });
          });
          console.log("tarjetas", tarjetas)
          dispatch(setRegistrosTarjetas(tarjetas))
          resultadoCargarTodosVenta(tarjetas)
        }
      } else {
        stopLoader();
        setToast(data['message'], "warning");
      }
      return await data;
    } catch (error) {
      console.log("error", error);
      setToast(JSON.stringify(error), "danger");
      stopLoader();
    }
  }

  const devuelveTotalSinRetencion = async (codigoVenta: number, tipoPago: number) => {
    const find = {
      ventaCodigo: codigoVenta,
      tipoPago: tipoPago
    };
    try {
      const data = await tarjetasService.devuelveTotalSinRetencion(find);
      if (data["error"] === false) {
        setValor_ti(Number(data['auto'].toFixed(2)))
      } else {
        setToast(data['message'], "warning");
      }
      return await data;
    } catch (error) {
      console.log("error", error);
      setToast(JSON.stringify(error), "danger");
    }
  }

  const devuelveTotalFormasPagoABV = async (codigoIngreso: number) => {
    const find = {
      codigo: codigoIngreso
    };
    try {
      const data = await tarjetasService.devuelveTotalFormasPagoABV(find);
      if (data["error"] === false) {
        setValor_ti(Number(data['auto'].toFixed(2)))
      } else {
        setToast(data['message'], "warning");
      }
      return await data;
    } catch (error) {
      console.log("error", error);
      setToast(JSON.stringify(error), "danger");
    }
  }
  const devuelveTotalFormasPagoABC = async (codigoEgreso: number) => {
    const find = {
      codigo: codigoEgreso
    };
    try {
      const data = await ingresarChequesService.devuelveTotal(find, "PROVEEDORES", "ABONOS");
      if (data !== null && data !== undefined && data["error"] === false) {
        setValor_ti(Number(data['auto'].toFixed(2)))
      } else {
        setToast(data['message'], "warning");
      }
      return await data;
    } catch (error) {
      setToast(JSON.stringify(error), "danger");
    }
  }

  const resultadoCargarTodosVenta = (dptarjetasv: Array<Registros>) => {
    let totales: number = 0;

    for (let i: number = 0; i < dptarjetasv.length; i++) {
      totales = totales + Number(dptarjetasv[i].valorTAV);
    }
    setValor_ti(totales)

  }

  const handleSubmit = async () => {
    iniciar()
  }

  const handleDelete = async () => {
    playLoader()
    try {
      const codigoTAV: number = seleccionado['row']['codigoTAV']
      const obj = {
        codigo: codigoTAV
      }
      const data = await tarjetasService.deleteTarjeta(obj)
      stopLoader()
      if (data['error'] === false) {
        setToast(data['message'], 'success')
        dispatch(setResetSeleccion(null))
        quitarRegistroTabla(Number(codigoTAV))
      } else {
        setToast(data['message'], 'warning')
      }
      return await data
    } catch (error) {
      console.log('error', error)
      stopLoader()
      setToast(JSON.stringify(error), 'danger')
    }
  }

  const quitarRegistroTabla = idIngreso => {
    const filtrado = registros.findIndex((item: any) => {
      return Number(item.codigo) === Number(idIngreso)
    })
    const rowsBefore = registros.slice()
    rowsBefore.splice(filtrado, 1)
    dispatch(setRegistrosTarjetas(rowsBefore))
  }

  const getButtonDisabled = React.useCallback((button: BotonMenu) => {
    switch (button.id) {
      default:
        return false;
    }
  }, []);

  const returnAction = (metodo) => {
    console.log('returnAction', metodo)
    switch (metodo) {

      case TIPOS_BOTONES.BOTON_NUEVO:
        setDisplayDestino(TIPOS_BOTONES.BOTON_NUEVO)
        if (currentAction === "Editar") {
          setConfirmChange(true)
          setConfirmUpdate(true)
          setMessageConfirm(lh.getMessage(MessagesKeys.FormConfirmExitForm));
          setShowConfirm(true)
          return false;
        } else {
          dispatch(setCurrentAction("Nuevo"))
        }
        break;

      case TIPOS_BOTONES.BOTON_BUSCAR:
        setDisplayDestino(TIPOS_BOTONES.BOTON_BUSCAR)
        if (currentAction === "Buscar") {
          dispatch(setCurrentFunction("Buscar"))
          return false;
        } else {
          if (currentAction === "Nuevo" || currentAction === "Editar") {
            setConfirmChange(true)
            setConfirmUpdate(true)
            setMessageConfirm(lh.getMessage(MessagesKeys.FormConfirmExitForm));
            setShowConfirm(true)
            return false;
          } else {
            dispatch(setCurrentAction("Buscar"))
          }
        }
        break;

      case TIPOS_BOTONES.BOTON_GUARDAR:
        if (currentAction === "Nuevo") {
          dispatch(setCurrentFunction(TIPOS_BOTONES.BOTON_GUARDAR))
          return false;
        } else if (currentAction === "Editar") {
          dispatch(setCurrentFunction(TIPOS_BOTONES.BOTON_GUARDAR))
          return false;
        } else {
          setToast("No se encuentra cambios que realizar.", "info")
        }
        break;

      case TIPOS_BOTONES.BOTON_ELIMINAR:
        if (currentAction === "Buscar") {
          if (seleccionado['index'] === null) {
            setToast(lh.getMessage(MessagesKeys.NotFoundRegisterSelec), "info")
            return false;
          } else {
            clickDelete();
          }
        } else {
          setToast(lh.getMessage(MessagesKeys.NotFoundRegisterSelec), "info")
        }
        break;

      case TIPOS_BOTONES.BOTON_DESHACER:
        dispatch(setCurrentFunction(TIPOS_BOTONES.BOTON_DESHACER))
        break;

      default:
        break;
    }
  }

  const clickDelete = () => {
    setConfirmDelete(true);
    setShowConfirm(true);
    setTitleConfirm("Confirmar");
    setMessageConfirm("Está seguro que desea eliminar esta tarjeta?");
  }

  const playLoader = () => {
    dispatch(changeLoader({ show: true, mensaje: 'Cargando...' }))
  }

  const stopLoader = () => {
    dispatch(changeLoader({ show: false, mensaje: '' }))
  }

  const setToast = (texto, type) => {
    let tipo: ToastTypes = ToastTypes.Info;
    if (type === 'warning') {
      tipo = ToastTypes.Warning;
    } else if (type === 'success') {
      tipo = ToastTypes.Success;
    } if (type === 'info') {
      tipo = ToastTypes.Info;
    } if (type === 'danger') {
      tipo = ToastTypes.Danger;
    }
    dispatch(addToast({
      id: '',
      autoHide: 3500,
      title: 'Acatha',
      content: texto,
      fade: true,
      type: tipo
    }));
  }

  const bodyModalForm = () => {
    if (currentAction === "Buscar") {
      return (
        <>
          < TablaTarjetas
            name="tablaTarjetas"
            pasarSeleccionados={() => console.log('pasarSeleccionados')}
            seleccionadosDetalle={''}
            total_ti={valor_ti}
            onSelected={onSelected}
          />
        </>
      )
    } else if (currentAction === "Nuevo") {
      return (
        <>
          <Nuevo />
        </>
      )
    }

  }

  const renderBody = () => {
    return (
      <>

        <CCard key="cardCuentas" >
          <CCardHeader >
            <CRow>
              <CCol className="ms-0 d-flex align-items-start justify-content-start" >
                <div key='menuItems' className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups" style={{ marginBottom: '5px', paddingTop: '-2rem' }}>
                  <div key='menuItemsBotones' className="btn-group-lg btn-group btn-sm me-2" role="group" aria-label="First group" >
                    {acciones.map(function (x, i) {
                      if (x.id !== "Editar") {
                        const prefixButton = "buttonsCuentas_";
                        const nameButon = x.text;
                        const disabled = getButtonDisabled(x);
                        return (
                          <CTooltip
                            key={"tool-" + prefixButton + nameButon}
                            placement="top"
                            content={nameButon}
                          >
                            <CButton
                              key={prefixButton + nameButon}
                              name={prefixButton + nameButon}
                              title={x.text}
                              id={prefixButton + nameButon}
                              value={x.text}
                              className="btn btn-secondary sm"
                              variant="ghost"
                              onClick={(ev) => returnAction(ev = x.text)}
                              size='sm'
                              disabled={disabled}
                            >
                              <FontAwesomeIcon size='sm' icon={x.icon ?? faQuestionCircle} />
                            </CButton>
                          </CTooltip>
                        );
                      }
                    })}
                  </div>
                </div>
              </CCol>
            </CRow>
          </CCardHeader >

          <CCardBody >
            {bodyModalForm()}
          </CCardBody >


        </CCard>
      </>
    )
  }

  const renderFooter = () => {
    if (currentAction === "Buscar") {
      return (
        <>
          {confirmDelete === true && (
            <CButton color="primary" onClick={() => {
              setShowConfirm(false)
              setConfirmDelete(false)
              handleDelete()
            }}>
              {"Aceptar"}
            </CButton>
          )}
          {confirmUpdate === true && (
            <CButton color="primary" onClick={() => {
              setShowConfirm(false)
              setConfirmUpdate(false);
              dispatch(setCurrentAction("Editar"))
            }}>
              {"Aceptar"}
            </CButton>
          )}
          <CButton color="secondary" onClick={() => {
            setShowConfirm(!showConfirm)
            setConfirmUpdate(false);
            setConfirmDelete(false)
          }} >{"Cancelar"}</CButton>
        </>
      )
    } else if (currentAction === "Nuevo" || currentAction === "Editar") {
      return (
        <>
          {confirmChange === true && (
            <CButton color="primary" onClick={() => {
              setShowConfirm(false)
              setConfirmUpdate(false);
              setConfirmChange(false)
              setMessageConfirm("")
              dispatch(setCurrentAction(displayDestino))
            }}>
              {"Aceptar"}
            </CButton>
          )}
          <CButton color="secondary" onClick={() => {
            setShowConfirm(!showConfirm)
            setConfirmUpdate(false);
            setConfirmDelete(false)
          }} >{"Cancelar"}</CButton>
        </>
      )
    }
  }

  const renderBodyConfirm = () => {
    return (<> {messageConfirm} </>)
  }

  const confirmAction = () => {
    return (
      <Modalform
        name='confirmarCambios'
        headerTitle={'Confirmar'}
        childrenBody={renderBodyConfirm()}
        childrenFooter={renderFooter()}
        closeOnBackdrop={false}
        show={showConfirm}
        onClose={() => setShowConfirm(!showConfirm)}
        centered={true}
        size="sm"
        key={'confirmarCambios'}
      />
    );
  };

  return (
    <>
      {showConfirm === true && ReactDom.createPortal(confirmAction(), document.body)}
      <CRow key="rowMain">
        <CCol key="colMain" >
          <Modalform
            name='apis'
            key='modalAnticipos'
            headerTitle={"Tarjetas de Crédito"}
            childrenBody={renderBody()}
            childrenFooter={<></>}
            closeOnBackdrop={false}
            show={showModal}
            onClose={() => props.onClose()}
            size="lg"
          />
        </CCol>
      </CRow>
    </>
  )
}

export default ModalTarjetas
