import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DateUtils } from '../../../../../helpers/dateUtils'
import { RootState } from '../../../../../store/store'
import { FETCH_STATUS, TiposComprobantesSri } from '../../../../../store/types'
// import { VentasService } from '../../../services/ventas.service'
import { EmpresaListado, FiltroBusquedaEmpresasState } from './types'
import { TConfigSearchState } from '../../store/types'

const initialState: TConfigSearchState<EmpresaListado> = {
  filter: {
    fechaInicio: DateUtils.getCurrentDateAsString(),
    fechaFin: DateUtils.getCurrentDateAsString(),
    puntoVenta: '',
    cliente: null,
    activas: false,
    documento: '',
    formaPago: null,
    pendientesAbutorizar: false,
    establecimiento: null,
    estado: null,
    puntoEmision: null,
    tipoDoc: TiposComprobantesSri.Factura,
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  resultados: [],
}

export const fetchVentas = createAsyncThunk<
  Array<EmpresaListado>,
  FiltroBusquedaEmpresasState
>('ventas/searchVentas', async (filtro) => {
  try {
    // const ventas = await VentasService.getVentas(
    //   0,
    //   filtro.identificacion,
    //   filtro.nombre,
    // )
    return null
  } catch (error) {
    return Promise.reject(error)
  }
})

const searchConfEmpresaSlice = createSlice({
  name: 'searchEmpresas',
  initialState: initialState,
  reducers: {
    resetState(state) {
      state = {
        ...initialState,
        filter: {
          ...initialState.filter,
        },
      }
    },
    changeFilter(state, action: PayloadAction<FiltroBusquedaEmpresasState>) {
      state.filter = action.payload
    },
    setIdentificacion(state, action: PayloadAction<string>) {
      state.filter.identificacion = action.payload
    },
    setNombre(state, action: PayloadAction<string>) {
      state.filter.nombre = action.payload
    },
    setCleanResult(state) {
      state.resultados = []
    },
    setResultados(state, action: PayloadAction<Array<EmpresaListado> | []>) {
      state.resultados = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchVentas.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchVentas.fulfilled, (state, { payload }) => {
      state.resultados = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchVentas.rejected, (state, { payload }) => {
      state.resultados = []
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
  },
})

export const selectFiltroBusquedaVentas = (state: RootState) => {
  return state.ventas.ventas.search.filter
}
export const selectResultadosBusquedaVentas = (state: RootState) => {
  return state.ventas.ventas.search.resultados
}
export const selectBusquedaVentasEstado = (state: RootState) => {
  return state.ventas.ventas.search.status
}
export const { setResultados, resetState, setCleanResult, changeFilter } =
  searchConfEmpresaSlice.actions
export const searchReducer = searchConfEmpresaSlice.reducer
