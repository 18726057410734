import { DocumentosCtasXPagar } from '../pages/docsCtasXPagar/types/types'
import { SpecialtyState } from '../pages/especialidad/types/types'
import { SupplierssState } from '../pages/proveedores/types/types'

export enum Aplicacion {
  proveedor = 'proveedores',
  cuentasXPagar = 'cuentasXPagar',
  especialidad = 'especialidad',
}

export enum EgresoTipo {
  abono = 'ABONO PROVEEDOR',
  anticipo = 'ANTICIPO PROVEEDOR',
  compra = 'COMPRA',
  egreso = 'EGRESOS',
  rol = 'ROL DE PAGOS',
}

export type ModuloProveedoresState = {
  proveedores: SupplierssState
  documentosCuentasPorPagar: DocumentosCtasXPagar
  especialidad: SpecialtyState
}

export type ProveedorSearchGrid = {
  nombreComercial: string
  razonSocial: string
  identificacion: string
  activos: boolean
  transportista: boolean
}

export type ProveedorBusqueda = {
  codigo: number
  nombre: string
  razonComercial: string
  identificacion: string
  email: string
  direccion: string
  diaReposicion: string
  diasCredito: number
  intervalo: number
  limiteCredito: number
  personaAtencionCompras: string
  personaAtencionPagos: string
  telefono: string | null
  tipoIdDescripcion: string
  tipoIdentificacion: string
  placas?: string
  tipoActividad?: string
  nrc?: string
  ciudad?: string
  ciudadCodigo?: number
  tipoAgente?: number
}

export type Proveedor = {
  codigo?: number
  tipoIdentificacion?: string
  tipoIdentificacionDescripcion?: string
  identificacion?: string
  identificacionString?: string
  tributario?: string
  fechaRegitro?: string
  nombre?: string
  razonComercial?: string
  direccion?: string
  telefono?: string
  fax?: string
  celular?: string
  email?: string
  ubicacion?: number
  ciudadNombre?: string
  clasificacion?: number
  diasCredito?: number
  limiteCredito?: number
  descuento?: number
  personaAtencionCompras?: string
  personaAtencionPagos?: string
  saldo?: number
  comprasAnuales?: number
  fechaUltimoPago?: string
  documentoUltimoPago?: string
  montoPago?: number
  fechaUltimaCompra?: number
  documentoUltimaCompra?: string
  montoCompra?: number
  observaciones?: string
  web?: string
  tipoEmpresa?: number
  especialidad?: number
  tipoAgenteCodigo?: number
  seguros?: number
  artesanos?: number
  tipoProveedorCodigo?: string
  relacionado?: string
  estado?: string
  intervalo?: number
  diaReposicion?: string
  transportista?: string
  paisCodigo?: number
  paisCescripcion?: string
  provinciaCodigo?: number
  provinciaDescripcion?: string
  ciudadCodigo?: number
  ciudadDescripcion?: string
  placas?: string
  nit?: string
  dui?: string
  nrc?: string
  actividadCodigo?: number | null
}

export type DetalleProveedor = {
  codigo: number
  descripcion: string
  numero: string
  tipo: string
  tipoDescripcion: string
  estado: string
  asociadoCodigo: number
  asociadoTipo: string
  principal: number
}

export type EdicionPayload<T> = {
  key: number | string
  data: T
}
