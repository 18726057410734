import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LoaderInfo } from '../../../../../../store/types'
import { CustomDictionary } from '../../../../../ventas/types/generics'
import {
  Cliente,
  CuotaCreditoVenta,
  FormaPagoModuloVentas,
} from '../../../../types/types'
import { DetalleVentaRedux } from '../../types/detalleVenta'
import {
  FacturaDatosEdicion,
  FacturacionEdicionPayload,
  TTotalesFactura,
  TiposPagosVenta,
} from '../../types/types'
import { ventasClearDatosEdicion } from '../../../../store/reducers'
import { Ciudad } from '../../../../store/types'

const initialState: CustomDictionary<FacturaDatosEdicion> = {}
const datosEdicionVentasSlice = createSlice({
  name: 'ventasConFinalDatosEdicion',
  initialState: initialState,
  reducers: {
    ventasClearDatosEdicion,
    setDatosEdicion(
      state,
      action: PayloadAction<FacturacionEdicionPayload<FacturaDatosEdicion>>,
    ) {
      state[action.payload.key] = action.payload.data
    },
    updateTotalesDescuentos(
      state,
      action: PayloadAction<{
        key: number | string
        descuentoGlobal: number
        descuento: number
      }>,
    ) {
      state[action.payload.key].descuentoGlobal = action.payload.descuentoGlobal
      state[action.payload.key].totales.descuento = action.payload.descuento
      state[action.payload.key].totales.descuentoGloabal =
        action.payload.descuentoGlobal
    },
    updateTotales(
      state,
      action: PayloadAction<{ key: number | string; totales: TTotalesFactura }>,
    ) {
      state[action.payload.key].totales = action.payload.totales
      state[action.payload.key].descuentoGlobal =
        action.payload.totales.descuentoGloabal
    },
    updateDetalles(
      state,
      action: PayloadAction<{
        key: number | string
        detalles: Array<DetalleVentaRedux>
      }>,
    ) {
      state[action.payload.key].detalles = action.payload.detalles
    },
    addDetalle(
      state,
      action: PayloadAction<{
        key: number | string
        detalle: DetalleVentaRedux
      }>,
    ) {
      state[action.payload.key].detalles.push(action.payload.detalle)
    },
    updateCliente(
      state,
      action: PayloadAction<{
        key: number | string
        cliente: Cliente | null
        formasPago: Array<FormaPagoModuloVentas>
        formaPago: Array<FormaPagoModuloVentas>
        detalles?: Array<DetalleVentaRedux>
      }>,
    ) {
      state[action.payload.key].cliente = action.payload.cliente
      state[action.payload.key].formasPago = action.payload.formasPago
      state[action.payload.key].formaPago = action.payload.formaPago
      // if (state[action.payload.key].formaPago.length > 0) {
      //   const formaPago = action.payload.formasPago.find(x => x.codigo === state[action.payload.key].formaPago[0].codigo);
      //   state[action.payload.key].formaPago = formaPago ? [formaPago] : action.payload.formasPago.filter(x => x.descripcion === "EFECTIVO");
      // } else {
      //   state[action.payload.key].formaPago = action.payload.formasPago.filter(x => x.descripcion === "EFECTIVO");
      // }
      if (action.payload.detalles)
        state[action.payload.key].detalles = action.payload.detalles
    },
    updateClienteOnly(
      state,
      action: PayloadAction<{ key: number | string; cliente: Cliente }>,
    ) {
      state[action.payload.key].cliente = action.payload.cliente
    },
    updateFechaEmision(
      state,
      action: PayloadAction<{ key: string; fecha: string }>,
    ) {
      state[action.payload.key].fecha = action.payload.fecha
    },
    updateObservaciones(
      state,
      action: PayloadAction<{ key: number | string; observaciones: string }>,
    ) {
      state[action.payload.key].observaciones = action.payload.observaciones
    },
    //Establecer datos edicion para un tab nuevo
    initDatosEdicion(
      state,
      action: PayloadAction<FacturacionEdicionPayload<FacturaDatosEdicion>>,
    ) {
      if (!state[action.payload.key]) {
        state[action.payload.key] = action.payload.data
      }
    },
    setEditLoader(
      state,
      action: PayloadAction<{ key: number | string; info: LoaderInfo }>,
    ) {
      state[action.payload.key].loader = action.payload.info
    },
    setMuestraError(
      state,
      action: PayloadAction<{
        key: number | string
        tieneError: boolean
        mensajeError: string
      }>,
    ) {
      state[action.payload.key].tieneError = action.payload.tieneError
      state[action.payload.key].mensajeError = action.payload.mensajeError
      state[action.payload.key].advertencias = []
    },
    setImprimir(
      state,
      action: PayloadAction<{ key: number | string; imprimir: boolean }>,
    ) {
      state[action.payload.key].imprimir = action.payload.imprimir
    },
    setFormaPago(
      state,
      action: PayloadAction<{
        key: number | string
        formaPago?: FormaPagoModuloVentas
      }>,
    ) {
      const { formaPago, key } = action.payload
      state[key].formaPago = formaPago ? [formaPago] : []
      if (formaPago && formaPago.descripcion !== 'CREDITO') {
        //Limpiar cuotas
        state[key].cuotas = []
      }
    },
    setCuotasCredito(
      state,
      action: PayloadAction<{
        key: number | string
        cuotas: Array<CuotaCreditoVenta>
      }>,
    ) {
      const { key, cuotas } = action.payload
      state[key].cuotas = cuotas
    },
    setMetodosAgregados(
      state,
      action: PayloadAction<{
        key: number | string
        tiposPagos: Array<TiposPagosVenta>
      }>,
    ) {
      const { key, tiposPagos } = action.payload
      state[key].tiposPagos = tiposPagos
    },
    setMostrarBusquedaItems(
      state,
      action: PayloadAction<{ key: string; mostrarBusquedaItems: boolean }>,
    ) {
      const { key, mostrarBusquedaItems } = action.payload
      state[key].mostrarBusquedaItems = mostrarBusquedaItems
    },
    setFletes(
      state,
      action: PayloadAction<{ key: number | string; flete: number }>,
    ) {
      const { key, flete } = action.payload
      state[key].flete = flete
    },
    setSeguro(
      state,
      action: PayloadAction<{ key: number | string; seguro: number }>,
    ) {
      const { key, seguro } = action.payload
      state[key].seguro = seguro
    },
    updateLocalidadesReceptorOnly(
      state,
      action: PayloadAction<{
        key: number | string
        localidadReceptor: Ciudad | null
      }>,
    ) {
      state[action.payload.key].localidadReceptor =
        action.payload.localidadReceptor
    },

    updateRetenerRentaValorOnly(
      state,
      action: PayloadAction<{
        key: number | string
        retenerRentaValor: number
      }>,
    ) {
      state[action.payload.key].retenerRentaValor =
        action.payload.retenerRentaValor
    },
    updateIsRetenerRenta(
      state,
      action: PayloadAction<{
        key: number | string
        isRetenerRenta: boolean
      }>,
    ) {
      state[action.payload.key].isRetenerRenta = action.payload.isRetenerRenta
    },
    updateRetenerIvaValorOnly(
      state,
      action: PayloadAction<{
        key: number | string
        retenerIvaValor: number
      }>,
    ) {
      state[action.payload.key].retenerIvaValor = action.payload.retenerIvaValor
    },
    updateIsRetenerIva(
      state,
      action: PayloadAction<{
        key: number | string
        isRetenerIva: boolean
      }>,
    ) {
      state[action.payload.key].isRetenerIva = action.payload.isRetenerIva
    },
    updateDescuentoGlobal(
      state,
      action: PayloadAction<{
        key: number | string
        descuentoGlobal: number
      }>,
    ) {
      state[action.payload.key].descuentoGlobal = action.payload.descuentoGlobal
    },
    updateLoading(
      state,
      action: PayloadAction<{
        key: number | string
        loading: boolean
      }>,
    ) {
      state[action.payload.key].loading = action.payload.loading
    },
    setAdvertencias(
      state,
      action: PayloadAction<{
        key: number | string
        advertencias: string[] | []
      }>,
    ) {
      state[action.payload.key].advertencias = action.payload.advertencias
    },
    setReceptorNombre(
      state,
      action: PayloadAction<{
        key: number | string
        receptorNombre: string
      }>,
    ) {
      state[action.payload.key].receptorNombre = action.payload.receptorNombre
    },
  },
})

export const {
  updateTotalesDescuentos,
  setReceptorNombre,
  setAdvertencias,
  updateLoading,
  updateDescuentoGlobal,
  updateFechaEmision,
  initDatosEdicion,
  setDatosEdicion,
  updateTotales,
  updateDetalles,
  updateCliente,
  updateObservaciones,
  setEditLoader,
  ventasClearDatosEdicion: clearDatosEdicion,
  setMuestraError,
  addDetalle,
  setImprimir,
  setFormaPago,
  setCuotasCredito,
  setMostrarBusquedaItems,
  updateClienteOnly,
  setMetodosAgregados,
  setFletes,
  setSeguro,
  updateLocalidadesReceptorOnly,
  updateRetenerRentaValorOnly,
  updateIsRetenerRenta,
  updateRetenerIvaValorOnly,
  updateIsRetenerIva,
} = datosEdicionVentasSlice.actions
export const datosEdicionReducer = datosEdicionVentasSlice.reducer
