import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ExcellView } from "../types/types";
import { LoaderInfo } from '../../../../../../store/types';


const initialState: ExcellView = {
  archivo: '',
  tamanio: '',
  loader: {
    show: false,
    mensaje: '',
  },
  initial: true
}


const excellSlice = createSlice({
  name: 'rolPagosExcell',
  initialState: initialState,
  reducers: {
    setChangeLoader(state, action: PayloadAction<LoaderInfo>) {
      state.loader = action.payload
    },
    setFile(state, action: PayloadAction<ExcellView>) {
      state.tamanio = action.payload.tamanio
      state.archivo = action.payload.archivo
      state.initial = action.payload.initial
    },
  }
})

export const {
  setChangeLoader,
  setFile,
} = excellSlice.actions

export const excellReducer = excellSlice.reducer