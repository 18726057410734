import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { defaultEditionEmpresa } from '../containers/component/formEmpresa'
import { EdicionEmpresa } from '../containers/component/formEmpresa/types/types'
import { defaultEditionLocalEmpresa } from '../containers/component/formLocal'
import { EdicionLocalEmpresa } from '../containers/component/formLocal/types/types'
import { RootState } from './store'
import {
  ScreenLock,
  SessionInfo,
  GlobalState,
  LoaderInfo,
  SesionIniciadaPayload,
  ErrorInfo,
  Autorizacion,
  Puntoventa,
  Mensajes,
  ModalPerfil,
  Tour,
  Impresora,
  TipoComprobantes,
  Impresiones,
  AlertInfo,
  TipoAgente,
  TipoNegocio,
  ConfigConsumidorFinal,
  ClasificacionRimpe,
  Plan,
  TVisitor,
  TAutorizaDocumentos,
} from './types'
import { defaultVisitor } from '../views/componentes/geo/geo'

export const initialState: GlobalState = {
  session: null,
  usuario: null,
  empresasUsuario: null,
  sidebar: {
    sidebarShow: true,
    sidebarUnfoldable: false,
    asideShow: false,
    canCloseAside: false,
    darkMode: false,
    showNotificacion: false,
    modoImpresion: false,
    loadTab: '',
    formPuntoventa: {
      codigoPuntoVenta: 0,
      host: '',
      codigoLocal: 0,
      nombreLocal: '',
      especial: '',
      mac: '',
      uso: '',
      restaurante: 0,
      toOrden: 0,
      emula: 0,
      estado: 0,
      nombreDocumento: '',
    },
    formDataEmpresa: '',
    formAutorizaciones: [],
    formAutorizacion: {
      DOCUMENTO: '',
      AUT_CODIGO: 0,
      TIC_CODIGO: 0,
      AUT_AUTORIZACION: '',
      AUT_ESTABLECIMIENTO: null,
      AUT_PTOEMISION: null,
      AUT_DESDE: null,
      AUT_HASTA: null,
      AUT_CADUCIDAD: null,
      AUT_NUM_ACTUAL: null,
      AUT_PERTENECE: null,
      AUT_ASOCIADO: null,
      AUT_IMPRENTA: null,
      AUT_COMPARTIDA: null,
      EMP_MODFACTURACION: 1,
      COD_IMPRESION: 0,
      COD_IMPRESORA: 0,
      COD_TIPONEGOCIO: -1,
      PUNTO_VENTA: '',
    },
    impresoras: [],
    tiposAgentes: [],
    tiposNegocios: [],
    configConsumidorFinal: {
      email: '',
      codigo: 0,
      ruc: '',
    },
    configEmpresa: {
      ...defaultEditionEmpresa,
    },
    configLocalEmpresa: {
      ...defaultEditionLocalEmpresa,
    },
    dpClasificacionRimpe: [],
  },
  loader: {
    show: false,
    mensaje: '',
  },
  alert: {
    alertTitle: '',
    show: false,
    mensaje: '',
    detalles: [],
  },
  error: {
    show: false,
  },
  puntoVenta: null,
  peridoActivo: [],
  mensajes: {
    mensajes: [],
    mostrar: false,
    modo: 'item', // item,todos
    pos: 0,
  },
  modal: {
    show: false,
    titulo: 'Acatha',
    mensaje: '',
    idmodal: '',
    error: '',
  },
  tour: {
    show: false,
    usoElectronica: false,
  },
  screenLock: {
    show: false,
  },
  menu: [],
  puntosVenta: [],
  tipoComprobantes: [],
  impresiones: [],
  loadUser: false,
  loadSummaryDocs: true,
  procesandoPago: false,
  planes: [],
  visitor: defaultVisitor,
  autorizarDocumentos: {
    loadAutorizaDocumentos: true,
    autorizarTodos: false,
  },
}
const globalSlice = createSlice({
  name: 'global',
  initialState: initialState,
  reducers: {
    setProcesandoPago(state, action: PayloadAction<boolean>) {
      state.procesandoPago = action.payload
    },
    setLoadSummaryDocs(state, action: PayloadAction<boolean>) {
      state.loadSummaryDocs = action.payload
    },
    setConfigLocalEmpresa(state, action: PayloadAction<EdicionLocalEmpresa>) {
      state.sidebar.configLocalEmpresa = action.payload
    },
    setConfigEmpresa(state, action: PayloadAction<EdicionEmpresa>) {
      state.sidebar.configEmpresa = action.payload
    },
    setLoadUser(state, action: PayloadAction<boolean>) {
      state.loadUser = action.payload
    },
    onSesionIniciada(state, action: PayloadAction<SesionIniciadaPayload>) {
      if (action.payload && action.payload.usuario && action.payload.empresas) {
        state.usuario = action.payload.usuario
        state.empresasUsuario = action.payload.empresas
        if (action.payload.sesion) {
          state.session = action.payload.sesion
        }
      }
    },
    onCerrarSesion(state, action: PayloadAction) {
      state.session = initialState.session
      state.usuario = initialState.usuario
      state.empresasUsuario = initialState.empresasUsuario
      state.sidebar = initialState.sidebar
      state.loader = initialState.loader
      state.error = initialState.error
      state.mensajes = initialState.mensajes
      state.puntoVenta = initialState.puntoVenta
      state.modal = initialState.modal
      state.tour = initialState.tour
    },
    establecerSession(state, action: PayloadAction<SessionInfo>) {
      state.session = action.payload
    },
    setSidebarShow(state, action: PayloadAction<boolean>) {
      state.sidebar.sidebarShow = action.payload
    },
    setSidebarUnfoldable(state, action: PayloadAction<boolean>) {
      state.sidebar.sidebarUnfoldable = action.payload
    },
    setDarkMode(state, action: PayloadAction<boolean>) {
      state.sidebar.darkMode = action.payload
    },
    setAsideShow(state, action: PayloadAction<boolean>) {
      state.sidebar.asideShow = action.payload
    },
    setCanCloseAside(state, action: PayloadAction<boolean>) {
      state.sidebar.canCloseAside = action.payload
    },
    changeLoader(state, action: PayloadAction<LoaderInfo>) {
      state.loader = action.payload
    },
    setAlert(state, action: PayloadAction<AlertInfo>) {
      state.alert = action.payload
    },
    onError(state, action: PayloadAction<ErrorInfo>) {
      state.error = action.payload
    },
    setPuntoVenta(state, action: PayloadAction<string>) {
      state.puntoVenta = action.payload
    },
    setModoImpresion(state, action: PayloadAction<boolean>) {
      state.sidebar.modoImpresion = action.payload
    },
    setFormDataEmpresa(state, action: PayloadAction<any>) {
      state.sidebar.formDataEmpresa = action.payload
    },
    setAutorizacionState(state, action: PayloadAction<Autorizacion>) {
      state.sidebar.formAutorizacion = action.payload
    },
    setAutorizacionesState(state, action: PayloadAction<Array<Autorizacion>>) {
      state.sidebar.formAutorizaciones = action.payload
    },
    setPuntoventaState(state, action: PayloadAction<Puntoventa>) {
      state.sidebar.formPuntoventa = action.payload
    },
    setMensajesState(state, action: PayloadAction<Mensajes>) {
      state.mensajes = action.payload
    },
    setModalState(state, action: PayloadAction<ModalPerfil>) {
      state.modal = action.payload
    },
    setTourState(state, action: PayloadAction<Tour>) {
      state.tour = action.payload
    },
    setScreenLock(state, action: PayloadAction<ScreenLock>) {
      state.screenLock = action.payload
    },
    setMenu(state, action: PayloadAction<ScreenLock>) {
      state.menu = action.payload
    },
    setPuntosVenta(state, action: PayloadAction<Array<Puntoventa>>) {
      state.puntosVenta = action.payload
    },
    setImpresoras(state, action: PayloadAction<Array<Impresora>>) {
      state.sidebar.impresoras = action.payload
    },
    setTipoComprobantes(state, action: PayloadAction<Array<TipoComprobantes>>) {
      state.tipoComprobantes = action.payload
    },
    setImpresiones(state, action: PayloadAction<Array<Impresiones>>) {
      state.impresiones = action.payload
    },
    setTiposAgentes(state, action: PayloadAction<Array<TipoAgente>>) {
      state.sidebar.tiposAgentes = action.payload
    },
    setTiposNegocios(state, action: PayloadAction<Array<TipoNegocio>>) {
      state.sidebar.tiposNegocios = action.payload
    },
    setConfigConsumidorFinal(
      state,
      action: PayloadAction<ConfigConsumidorFinal>,
    ) {
      state.sidebar.configConsumidorFinal = action.payload
    },
    setLoadTab(state, action: PayloadAction<string>) {
      state.sidebar.loadTab = action.payload
    },
    setDpClaificacionRimpe(
      state,
      action: PayloadAction<Array<ClasificacionRimpe>>,
    ) {
      state.sidebar.dpClasificacionRimpe = action.payload
    },
    setAvailablePlans(state, action: PayloadAction<Array<Plan>>) {
      state.planes = action.payload
    },
    setVisitorConfig(state, action: PayloadAction<TVisitor>) {
      state.visitor = action.payload
    },
    setAutorizarDocumentos(state, action: PayloadAction<TAutorizaDocumentos>) {
      state.autorizarDocumentos = action.payload
    },
  },
})

export const {
  setAutorizarDocumentos,
  setVisitorConfig,
  setProcesandoPago,
  setDpClaificacionRimpe,
  setLoadSummaryDocs,
  setConfigLocalEmpresa,
  setConfigEmpresa,
  setLoadUser,
  setAutorizacionesState,
  setFormDataEmpresa,
  setConfigConsumidorFinal,
  setTiposNegocios,
  setTiposAgentes,
  setAlert,
  setTipoComprobantes,
  setImpresoras,
  setPuntosVenta,
  setMenu,
  setScreenLock,
  onCerrarSesion,
  establecerSession,
  setSidebarShow,
  setSidebarUnfoldable,
  setDarkMode,
  setAsideShow,
  changeLoader,
  onSesionIniciada,
  onError,
  setPuntoVenta,
  setModoImpresion,
  setAutorizacionState,
  setPuntoventaState,
  setMensajesState,
  setModalState,
  setTourState,
  setImpresiones,
  setLoadTab,
  setAvailablePlans,
} = globalSlice.actions

export const selectPuntoVentaActual = (state: RootState) => {
  return state.global.puntoVenta
}

export const seleccionarSesion = (state: RootState) => {
  return state.global.session
}

export default globalSlice.reducer
