import React from 'react'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import { DataGrid } from 'devextreme-react'
import { Column, ColumnChooser, Export, Pager, Paging, Summary, TotalItem } from 'devextreme-react/data-grid'
import { getAllowedPageSizes } from '../../../../../../../helpers/Helper'
import ItemsCountIndicator from '../../../../../../componentes/indicadorNroRegistros'
import { Workbook } from 'exceljs'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { saveAs } from 'file-saver';
import { MovimientosGridHelper } from '../helpers/movimientosGridHelper'

const Base = () => {
  const resultsSerach = useSelector((state: RootState) => state.inventarios.movimientos.ingresosEgresos.search.resultados)
  const currentTab = useSelector((state: RootState) => state.inventarios.movimientos.ingresosEgresos.search.currentTab)
  const [resultados, setResultados] = React.useState([])
  const dataGrid = React.useRef<any>()

  const onGetData = React.useCallback(() => {
    let newArray = []
    if (currentTab === "Base") {
      if (resultsSerach !== null && resultsSerach !== undefined && resultsSerach['error'] === false) {
        console.log(resultsSerach['auto'])
        newArray = resultsSerach['auto'].map(item => {
          return {
            ...item,
            itemCodigoUsuario: item?.itemCodigoUsuario.replace("'", ""),
            itemBarras: item?.itemBarras.replace("'", "")
          }
        })
        setResultados(newArray)
      } else {
        setResultados([])
      }
    } else {
      setResultados([])
    }
  }, [resultsSerach, currentTab])

  React.useEffect(() => {
    onGetData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultsSerach])

  const onExportExcell = React.useCallback((e) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('Main sheet')
    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Base_Inventario.xlsx')
      });
    });
  }, [])

  return (
    <RowContainer className="mt-2">
      <ItemsCountIndicator items={resultados} />
      <DataGrid
        ref={dataGrid}
        keyExpr="codigo"
        focusedRowKey="codigo"
        id="tabCertificados"
        selection={{ mode: 'single' }}
        dataSource={resultados}
        showColumnLines={true}
        showRowLines={true}
        showBorders={true}
        columnResizingMode="widget"
        focusedRowEnabled={true}
        onSelectionChanged={() => { }}
        loadPanel={{
          enabled: true,
          height: 90,
          indicatorSrc: '',
          shading: false,
          shadingColor: '',
          showIndicator: true,
          showPane: true,
          text: 'Cargando...',
          width: 200,
        }}
      >
        <Paging defaultPageSize={10} />
        <Pager
          visible={resultados.length > 0 ? true : false}
          allowedPageSizes={getAllowedPageSizes(resultados)}
          displayMode={'full'}
          showPageSizeSelector={true}
          showInfo={true}
          showNavigationButtons={true}
        />
        <ColumnChooser enabled={true} mode="select" />
        <Export enabled={true} allowExportSelectedData={true} />
        <Column dataField="codigo" caption="Cod. Movimiento" width="100px" />
        <Column dataField="documento" caption="Documento" width="100px" />
        <Column dataField="movimientoDescripcion" caption="Movimiento" width="100px" />
        <Column dataField="productoClienteDescripcion" caption="Nombre" width="100px" />
        <Column dataField="localCodigoDescripcion" caption="Local/CC" width="100px" />
        <Column dataField="planCuentasDescripcion" caption="CC Compra" width="100px" />
        <Column dataField="fecha" caption="Fecha" width="100px" />
        <Column dataField="itemCodigoUsuario" caption="Interno" width="100px" />
        <Column dataField="itemBarras" caption="Barras" width="100px" />
        <Column dataField="itemDescripcion" caption="Descripción" width="100px" />
        <Column dataField="itemUnidadDescripcion" caption="Unidad" width="100px" />
        <Column dataField="itemPVPIVA" caption="PVP" width="70px" />
        <Column dataField="itemPVP" caption="PVD" width="70px" />
        <Column dataField="itemImpuestoDescripcion" caption="Impuesto" width="100px" />
        <Column dataField="movimientoDetalleCantidad" caption="Cantidad" width="100px" />
        <Column dataField="movimientoDetallePrecio" caption="Costo" width="100px" />
        <Column dataField="movimientoDetalleValor" caption="Subtotal" width="100px" />
        <Summary
          calculateCustomSummary={MovimientosGridHelper.calculateCustomSummary}
        >
          <TotalItem
            customizeText={MovimientosGridHelper.customizeSummaryText}
            showInColumn="itemPVPIVA"
            name="itemPVPIVA"
            summaryType="custom"
            displayFormat="{0}"
            valueFormat="currency"
            alignment="right"
          />
          <TotalItem
            customizeText={MovimientosGridHelper.customizeSummaryText}
            showInColumn="itemPVP"
            name="itemPVP"
            summaryType="custom"
            displayFormat="{0}"
            valueFormat="currency"
            alignment="right"
          />
          <TotalItem
            customizeText={MovimientosGridHelper.customizeSummaryText}
            showInColumn="movimientoDetallePrecio"
            name="movimientoDetallePrecio"
            summaryType="custom"
            displayFormat="{0}"
            valueFormat="currency"
            alignment="right"
          />

        </Summary>
      </DataGrid>
    </RowContainer>
  )
}

export default React.memo(Base)
