import { combineReducers } from '@reduxjs/toolkit'
import { PositionsState } from '../types/types'
import { dataEditPositionReducer } from './editDataReducer'
import { searchPositionsReducer } from './searchReducer'
import { tabsPositionsReducer } from './tabsReducer'
import { permissionsReducer } from './permisosReducer'

export const cargosReducer = combineReducers<PositionsState>({
  tabs: tabsPositionsReducer,
  editData: dataEditPositionReducer,
  search: searchPositionsReducer,
  permissions: permissionsReducer,
})
