import { RequestHelper } from "../../../../../../../helpers/requestHelper"

export const anticiposCtasXCobrar = {
  getFormasPagoANL,
  getAnticiposCV,
}

async function getAnticipos(
  pertenece: number,
  asociado: number,
  nrtipo: number,
  codigo: number,
  locCodigo: number,
  hasta: string,
): Promise<Promise<any>> {

  try {
    const query = {
      pertenece,
      asociado,
      nrtipo,
      codigo,
      locCodigo,
      hasta,
    }

    const data = await RequestHelper.getAll<any>("contabilidad/diarioGeneral", "advances/getAll", "", query)
    return data
  } catch (error) {
    console.log(error)
    return error
  }
}

async function getAnticiposCV(
  codigo: number,
): Promise<Promise<any>> {

  try {
    const query = {
      codigo,
    }

    const data = await RequestHelper.getAll<any>("contabilidad/diarioGeneral", "advancescv/getAll", "", query)
    return data
  } catch (error) {
    console.log(error)
    return error
  }
}

async function getFormasPagoANL(
  codigo: number
): Promise<Promise<any>> {

  try {
    const query = {
      codigo
    }

    const data = await RequestHelper.getAll<any>("generales/formasPago", "anl/getAll", "", query)
    return data
  } catch (error) {
    console.log(error)
    return error
  }
}