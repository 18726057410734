/* eslint-disable no-useless-catch */
import axios, { AxiosRequestConfig, Method } from 'axios'
import axiosRetry from 'axios-retry'
import { UrlHelper } from './urlHelper'
import config from '../config/config'
import { Auth } from 'aws-amplify'
// import { isIOS13, isMacOs } from "react-device-detect";

export const RequestHelperDotNet = {
  get,
  post,
  getConfig,
  getConfigAsync,
  getRequestUrl,
  getTokenAsync,
  GetFileWithCors,
}

type ApiRequestEntityResult<T> = {
  isOk: boolean
  code: number
  message: string | null
  data: T
}

axiosRetry(axios, {
  retries: 3,
  retryCondition: (error) => {
    console.error(JSON.stringify(error))

    return error.message.includes('503') || error.message === 'Network Error'
  },
})

export const API_URL = `${UrlHelper.getUrls().apiDotNetPath}`

function getRequestUrl(
  modulo: string,
  controller: string,
  key: string | null,
  query: any | null,
): string {
  const url = `${API_URL}${modulo}${
    controller.length > 0 ? '/' : ''
  }${controller}${!key ? '' : '/' + key}`
  let params = '?'
  if (query !== null && query !== undefined) {
    const keys = Object.keys(query)
    for (let index = 0; index < keys.length; index++) {
      const value = query[keys[index]]
      params += `${keys[index]}=${value}&`
    }
  }
  params = params.slice(0, -1)
  return url + params
}

function getConfig(
  method: Method,
  contentype = 'application/json',
): AxiosRequestConfig {
  const tokenEmpresa = sessionStorage.getItem('tokenEmpresa')
  const sessionId = localStorage.getItem('sesionIdentificador') ?? ''
  let csrfToken = ''
  // if (isIOS13 === true || isMacOs === true) {
  //   csrfToken = sessionStorage.getItem('token') ?? "";
  // } else {
  csrfToken = localStorage.getItem('token') ?? ''
  // }
  return {
    method: method,
    headers: {
      'x-authorization': tokenEmpresa,
      'client-id': config.CLIENT_ID,
      'secret-key': config.SECRET_KEY,
      authorization: 'Bearer ' + csrfToken,
      'Content-Type': contentype,
      'Session-ID': sessionId,
    },
  }
}

async function getConfigAsync(
  method: Method,
  contentype = 'application/json',
): Promise<AxiosRequestConfig> {
  const tokenEmpresa = sessionStorage.getItem('tokenEmpresa')
  const currentSesion = await Auth.currentSession()
  const token = currentSesion.getIdToken()
  const sessionId = localStorage.getItem('sesionIdentificador') ?? ''
  return {
    method: method,
    headers: {
      'x-authorization': tokenEmpresa,
      'client-id': config.CLIENT_ID,
      'secret-key': config.SECRET_KEY,
      authorization: 'Bearer ' + token.getJwtToken(),
      'Content-Type': contentype,
      'Session-ID': sessionId,
    },
  }
}

async function getTokenAsync(): Promise<string> {
  const currentSesion = await Auth.currentSession()
  const token = currentSesion.getIdToken()
  return token.getJwtToken()
}

async function get<T>(
  modulo: string,
  controller: string,
  key: string,
  query: any | null | undefined,
): Promise<T> {
  try {
    const url = getRequestUrl(modulo, controller, key, query)
    const cnf = await getConfigAsync('GET')
    const data = await axios.get<ApiRequestEntityResult<T>>(url, cnf)
    if (data.status === 200) {
      if (data.data.isOk) {
        const apiData = data.data as ApiRequestEntityResult<T>
        return apiData.data
      } else {
        throw data.data.message
      }
    }
    throw data.statusText
  } catch (error) {
    throw error
  }
}

async function post<T>(
  modulo: string,
  controller: string,
  postData: any | null | undefined,
): Promise<T> {
  try {
    const url = getRequestUrl(modulo, controller, null, null)
    const cnf = await getConfigAsync('POST')
    const data = await axios.post<ApiRequestEntityResult<T>>(url, postData, cnf)
    if (data.status === 200) {
      if (data.data.isOk) {
        const apiData = data.data as ApiRequestEntityResult<T>
        return apiData.data
      } else {
        throw data.data.message
      }
    }
    throw data.statusText
  } catch (error) {
    throw error
  }
}

async function GetFileWithCors(path: string) {
  const url = getRequestUrl('Legacy', 'GetFileWithCors', null, { path })
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'client-id': config.CLIENT_ID ?? '',
      'secret-key': config.SECRET_KEY ?? '',
    },
  })
  return response
}
