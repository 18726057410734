import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DateUtils } from '../../../../../../helpers/dateUtils'
import { RootState } from '../../../../../../store/store'
import { FiltroBusquedaState, SearchState } from '../../../../types/types'
import { SujetoExcluidoListado } from '../types/types'
import {
  FETCH_STATUS,
  TiposComprobantesSLV,
} from '../../../../../../store/types'
import { SujetoExcentoService } from '../../../services/sujetoExcento.service'
import { utilidades } from '../../../../../../helpers/utilidades'

const initialState: SearchState<SujetoExcluidoListado> = {
  filter: {
    fechaInicio: DateUtils.getCurrentDateAsString(),
    fechaFin: DateUtils.getCurrentDateAsString(),
    puntoVenta: '',
    activas: false,
    documento: '',
    pendientesAbutorizar: false,
    proveedor: null,
    tipoDoc: TiposComprobantesSLV.FacturaExcluidoSujeto,
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  resultados: [],
  verJson: false,
  verJsonTab: '',
}

export const fetchSujetoExcluido = createAsyncThunk<
  Array<SujetoExcluidoListado>,
  FiltroBusquedaState
>('sujetoExcluido/searchSujetoExcluido', async (filtro) => {
  try {
    const sujetoExcluidoList =
      await SujetoExcentoService.buscarComprasSujetoExluido(
        {
          inicio: DateUtils.pickersDateToApiDate(filtro.fechaInicio),
          fin: DateUtils.pickersDateToApiDate(filtro.fechaFin),
        },
        filtro.documento,
        filtro?.proveedor ? filtro?.proveedor?.identificacion : undefined,
        filtro.tipoDoc,
        filtro?.formaPago ? filtro?.formaPago?.codigo.toString() : undefined,
        filtro.claveAcceso,
      )

    let data = sujetoExcluidoList

    if (sujetoExcluidoList.length > 0) {
      data = utilidades.sortJSON(sujetoExcluidoList, 'codigo', 'desc')
    }
    return data
  } catch (error) {
    return Promise.reject(error)
  }
})

const searchSujetoExcluidoSlice = createSlice({
  name: 'searchSujetoExcluido',
  initialState: initialState,
  reducers: {
    resetState(state) {
      state = {
        ...initialState,
        filter: {
          ...initialState.filter,
          fechaInicio: DateUtils.getCurrentDateAsString(),
          fechaFin: DateUtils.getCurrentDateAsString(),
        },
      }
    },
    changeFilter(state, action: PayloadAction<FiltroBusquedaState>) {
      state.filter = action.payload
    },
    setCleanResult(state) {
      state.resultados = []
    },
    setResultados(state, action: PayloadAction<any>) {
      state.resultados = action.payload.resultados
    },
    toggleVerJsonSujetoExcluido(state, action: PayloadAction<boolean>) {
      state.verJson = action.payload
    },
    setVerJsonTabSujetoExcluido(state, action: PayloadAction<string>) {
      state.verJsonTab = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSujetoExcluido.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchSujetoExcluido.fulfilled, (state, { payload }) => {
      state.resultados = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchSujetoExcluido.rejected, (state, { payload }) => {
      state.resultados = []
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
  },
})

export const selectFiltroBusquedaSujetoExcluido = (state: RootState) => {
  return state.compras.comprasSV.sujetoExcluido.search.filter
}
export const selectResultadosBusquedaSujetoExcluido = (state: RootState) => {
  return state.compras.comprasSV.sujetoExcluido.search.resultados
}
export const selectBusquedaSujetoExcluidoEstado = (state: RootState) => {
  return state.compras.comprasSV.sujetoExcluido.search.status
}
export const selectBusquedaVerJsonSujetoExcluido = (state: RootState) => {
  return state.compras.comprasSV.sujetoExcluido.search.verJson
}
export const selectBusquedaVerJsonTabSujetoExcluido = (state: RootState) => {
  return state.compras.comprasSV.sujetoExcluido.search.verJsonTab
}
export const {
  setResultados,
  changeFilter,
  resetState,
  setCleanResult,
  toggleVerJsonSujetoExcluido,
  setVerJsonTabSujetoExcluido,
} =
  searchSujetoExcluidoSlice.actions
export const searchSujetoExcluidoReducer = searchSujetoExcluidoSlice.reducer
