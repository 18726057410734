/* eslint-disable prefer-const */
import config from '../../../../../../../config/config'
import { RequestHelper } from '../../../../../../../helpers/requestHelper'
import { SesionService } from '../../../../../../../services/sesion.service'
import {
  DateRange,
  ETipoAsociado,
  PagedResult,
  PaginationInfo,
} from '../../../../../../../store/types'
import { Cliente } from '../../../../../types/types'
import { ItemDetallePedido, OrdenPedidoListado } from '../../../type/types'
import { ItemPedido, TOrdenPedidoIngresar } from '../type/types'
import CustomStore from 'devextreme/data/custom_store'
import DataSource from 'devextreme/data/data_source'
import {
  DateUtils,
  formatoFechasApi,
} from '../../../../../../../helpers/dateUtils'
import { Movimiento } from '../../../../../../inventario/pages/movimientos/conceptosMovimientos/types/types'
import { LocalOption } from '../../../../../../componentes/localesLookUp/store/type'
import { EModulosAcatha } from '../../../../../../../store/enumsAplication'
import { useCallback, useContext } from 'react'
import { LoaderContext } from '../components/busquedaPedidos/BusquedaItemsSelectBox/BuscarItemPedidoMemoLookUp'

export const OrdenPedidoService = {
  getOrdenes,
  getSecuencial,
  buscarItemPedido,
  getItemPorCodigoBarrasPedido,
  ingresarOrden,
  getOrden,
  getOrdenDetalle,
  getOrdenCompra,
  deletePedido,
  uploadFiles,
  getVendedorByCode,
  useGetItemsDatasource,
}

async function ingresarOrden(
  orden: TOrdenPedidoIngresar,
): Promise<Promise<any>> {
  try {
    const infoRegistro = {
      infoRegistro: orden,
    }
    const creada = await RequestHelper.postAll<any>(
      'ordenes',
      'save',
      infoRegistro,
    )
    return creada
  } catch (error) {
    return error
  }
}

async function getOrdenes(
  rangoFechas: DateRange,
  cod: string = '',
  filtro: any,
) {
  const queryData: any = {}
  if (rangoFechas.inicio) {
    queryData.fechaDesde = rangoFechas.inicio
  }
  if (rangoFechas.fin) {
    queryData.fechaHasta = rangoFechas.fin
  }
  if (filtro.item) {
    queryData.item = filtro.item.codigoBarras ?? 0
  }
  queryData.tipo = 'PROD'
  queryData.estado = ''
  queryData.salonCodigo = ''

  if (filtro.numero) {
    queryData.ordNumero = filtro.numero
  }

  if (filtro?.cliente && filtro?.cliente?.codigo) {
    queryData.idCliente = filtro?.cliente?.identificacion
  }

  if (filtro?.local && filtro?.local?.codigo) {
    queryData.localCodigo = filtro?.local?.codigo
  }

  const apiData = await RequestHelper.get<any>(
    'compras/documentos/general',
    'orders/getAll',
    '',
    queryData,
  )
  const orden: Array<OrdenPedidoListado> = []
  try {
    for (const ite of apiData) {
      orden.push({
        codigo: Number(ite?.codigo) ?? 0,
        numero: String(ite?.numero) ?? '',
        fechaEntrega: String(ite?.fechaEntrega) ?? '',
        horaEntrega: String(ite?.fechaEntrega) ?? '',
        clienteNombre: String(ite?.clienteNombre) ?? '',
        clienteIdentificacion: String(ite?.clienteIdentificacion) ?? '',
        clienteEmail: String(ite?.clienteEmail) ?? '',
        clienteTelefono: String(ite?.clienteTelefono) ?? '',
        garantia: parseFloat(ite?.garantia) ?? 0,
        abono: parseFloat(ite?.abono) ?? 0,
        saldo: parseFloat(ite?.saldo) ?? 0,
        total: parseFloat(ite?.total) ?? 0,
        porciones: parseFloat(ite?.porciones) ?? 0,
        observaciones: ite?.observaciones ?? '',
        imagenReferencia: ite?.imgAlternativa ?? '',
        imagenDecoracion: ite?.imgReferencia ?? '',
        vendedor: ite?.vendedorData ?? null,
        localCodigo: Number(ite?.localCodigo) ?? 0,
        localNombre: String(ite?.localNombre) ?? '',
        localColor: String(ite?.localColor) ?? '',
        ordenDetalle:
          ite.detalleOrden.map(function (key: ItemDetallePedido) {
            return {
              codigoInterno: String(key?.codigoInterno) ?? '',
              itemBarras: String(key?.itemBarras) ?? '',
              itemCodigo: Number(key?.itemCodigo) ?? 0,
              itemDescripcion: String(key?.itemDescripcion) ?? '',
              itemImagen: String(key?.itemImagen) ?? '',
              itemUnidadCompraDescripcion:
                String(key?.itemUnidadCompraDescripcion) ?? '',
              ordenCodigo: Number(key?.ordenCodigo) ?? 0,
              ordenDetalleCantidad: Number(key?.ordenDetalleCantidad) ?? 0,
              ordenDetalleCodigo: Number(key?.ordenDetalleCodigo) ?? 0,
              ordenDetalleDescuento: Number(key?.ordenDetalleDescuento) ?? 0,
              ordenDetalleImpuesto: Number(key?.ordenDetalleImpuesto) ?? 0,
              ordenDetallePorcentaje: Number(key?.ordenDetallePorcentaje) ?? 0,
              ordenDetallePrecio: Number(key?.ordenDetallePrecio) ?? 0,
              ordenDetallePrecioIVA: Number(key?.ordenDetallePrecioIVA) ?? 0,
              ordenDetalleValor: Number(key?.ordenDetalleValor) ?? 0,
            }
          }) ?? [],
      })
    }
  } catch (error) {
    console.error(error)
  }

  return orden
}

async function getSecuencial(tipo: string): Promise<any> {
  const data = {
    ordenTipo: tipo,
  }
  const apiDetalles = await RequestHelper.get<any>(
    'compras/documentos',
    'ordenes/getLastNumber',
    '',
    data,
  )
  return apiDetalles
}

async function getItemPorCodigoBarrasPedido(
  codigoBarras: string,
  identificacionCliente: string,
  fecha: string,
  tipoAsociado: ETipoAsociado,
  modulo: EModulosAcatha,
  movimiento: Movimiento,
  codigoPrvAsociado?: number,
  local?: LocalOption,
): Promise<Array<ItemPedido>> {
  const sesion = SesionService.getCurrentSesion()
  const query: any = {
    local: local !== undefined ? local?.codigo : sesion.local.codigo,
    identificacion: identificacionCliente,
    barras: codigoBarras,
    fecha: fecha,
    exacta: true,
  }
  let endPoint: string = 'searchItemsLocal'

  switch (modulo) {
    case EModulosAcatha.Kardex:
      endPoint = 'searchItemsLocal'
      break
    case EModulosAcatha.Pedidos:
      endPoint = 'searchItemsLocal'
      break
    case EModulosAcatha.Proformas:
      endPoint = 'searchItemsLocal'
      break
    case EModulosAcatha.MovimientosIngresosEgresos:
      endPoint = 'searchItemsLocal'
      if (movimiento?.asociado === 56) query.proveedor = codigoPrvAsociado
      break
    case EModulosAcatha.Transferencias:
      endPoint = 'searchItemsLocalCorte'
      break
    case EModulosAcatha.IngresoBloque:
      endPoint = 'searchItemsLocalSimple'
      break
    default:
      break
  }

  const itemsApi = await RequestHelper.get<Array<any>>(
    'inventario',
    'items/',
    endPoint,
    query,
  )

  let itemsParsed = []
  if (itemsApi) {
    if (endPoint === 'searchItemsLocalSimplePurchase') {
      itemsParsed = parseApiItemsLocalSimplePurchase(itemsApi)
    } else if (endPoint === 'searchItemsLocal') {
      itemsParsed = parseApiItemsLocal(itemsApi)
    } else if (endPoint === 'searchItemsLocalSimple') {
      itemsParsed = parseApiItemsLocalSimple(itemsApi)
    } else if (endPoint === 'searchItemsLocalCorte') {
      itemsParsed = parseApiItemsLocalSimple(itemsApi)
    }
  }
  return itemsParsed
}

async function buscarItemPedido(
  descripcion: string,
  codigoBarras: string,
  identificacionCliente: string,
  fecha: string,
): Promise<Array<ItemPedido>> {
  const sesion = SesionService.getCurrentSesion()
  const sesionAutorizacionModulo = SesionService.getCurrentModulosAutorizacion()
  const query: any = {
    local: sesion.local.codigo,
    identificacion: identificacionCliente,
    descripcion: descripcion,
    barras: codigoBarras,
    fecha: fecha,
  }
  if (sesionAutorizacionModulo && sesionAutorizacionModulo.establecimiento) {
    query.establecimiento = sesionAutorizacionModulo.establecimiento
  }
  const itemsApi = await RequestHelper.get<Array<any>>(
    'inventario',
    'items/searchItemsLocal',
    '',
    query,
  )
  const items = parseApiItemsLocal(itemsApi)
  return items
}

export function parseApiItemsLocal(apiresult: Array<any>): Array<ItemPedido> {
  const items = apiresult.map((x) => {
    const item: ItemPedido = {
      codigo: parseInt(x.codigo),
      codigoInterno: x.codigoint,
      codigoBarras: x.barras,
      descripcion: x.descripcion,
      existencia: parseFloat(x.existencia),
      pvdiva: parseFloat(x.pvdiva),
      pvpiva: parseFloat(x.pvpiva),
      pvd: parseFloat(x.pvd),
      pvp: parseFloat(x.pvp),
      codigoImpuesto: parseInt(x.impuesto),
      tieneIce: x.ice === '-1',
      porcentajeIva: parseFloat(x.porcentajeIva),
      costo: parseFloat(x.costo),
      tipoCodigo: parseInt(x.tipoCodigo),
      tipo: parseInt(x.tipo),
      numeroSerie: parseInt(x.numeroSerie),
      plantilla: parseInt(x.plantilla),
      freeitem: parseInt(x.freeitem),
      gradoAcoholico: parseInt(x.gradoAlcoholico),
      capacidad: parseFloat(x.capacidad),
      factor: parseFloat(x.factor),
      jerarquia: x.jerarquia,
      descuentos: parseFloat(x.descuentos),
      titadicional: x.titadicional,
      valadicional: x.valadicional,
      grupo: x.grupoDescripcion,
      codigoUnidadVenta: parseInt(x.codigoUnidadVenta),
      tipoDescripcion: x.tipoDescripcion,
      codigoGrupo: parseInt(x.codigoGrupo),
      existenciaMin: parseInt(x.existenciaMin),
      existenciaMax: parseInt(x.existenciaMax),
      saldoReservas: parseInt(x.saldoReservas),
      saldoFecha: parseInt(x.saldoFecha),
      lotes: x.lotes,
      fraccion: parseInt(x.fraccion),
      unidadIce: parseInt(x.codigoUnidadVenta),
      unidadDescripcion: x.unidadDescripcion,
      impuesto: parseInt(x.impuesto),
      impuestoValor: parseInt(x.impuestoValor),
    }
    return item
  })
  return items
}

export function parseApiItemsLocalSimplePurchase(
  apiresult: Array<any>,
): Array<ItemPedido> {
  const items = apiresult.map((x) => {
    const item: ItemPedido = {
      codigo: parseInt(x.codigo),
      codigoInterno: x.codigoint,
      codigoBarras: x.barras,
      descripcion: x.descripcion,
      existencia: parseFloat(x.existencia),
      pvdiva: parseFloat(x.pvdiva),
      pvpiva: parseFloat(x.pvpiva),
      pvd: parseFloat(x.pvd),
      pvp: parseFloat(x.pvp),
      codigoImpuesto: parseInt(x.impuesto),
      tieneIce: x.ice === '-1',
      porcentajeIva: parseFloat(x.porcentajeiva),
      costo: parseFloat(x.costo),
      tipoCodigo: parseInt(x.tipo),
      numeroSerie: parseInt(x.numeroSerie),
      plantilla: parseInt(x.plantilla),
      freeitem: parseInt(x.freeitem),
      gradoAcoholico: parseInt(x.gradoAlcoholico),
      capacidad: parseFloat(x.capacidad),
      factor: parseFloat(x.factor),
      jerarquia: x.jerarquia,
      descuentos: parseFloat(x.descuentos),
      titadicional: x.titadicional,
      valadicional: x.valadicional,
      grupo: x.grupo,
      codigoUnidadVenta: parseInt(x.unidadv),
      tipoDescripcion: x.tipo_descripcion,
      codigoGrupo: parseInt(x.codgrupo),
      existenciaMin: parseInt(x.existenciaMin),
      existenciaMax: parseInt(x.existenciaMax),
      saldoReservas: parseInt(x.saldoReservas),
      saldoFecha: parseInt(x.saldoFecha),
      lotes: x.lotes,
      fraccion: parseInt(x.fraccion),
      unidadIce: parseInt(x.codigoUnidadVenta),
      unidadDescripcion: x.unidadDescripcion,
      impuesto: parseInt(x.impuesto),
      impuestoValor: parseInt(x.impuestoValor),
    }
    return item
  })
  return items
}

export function parseApiItemsLocalSimple(
  apiresult: Array<any>,
): Array<ItemPedido> {
  const items = apiresult.map((x) => {
    const item: ItemPedido = {
      codigo: parseInt(x.codigo),
      codigoInterno: x.codigoint,
      codigoBarras: x.barras,
      descripcion: x.descripcion,
      existencia: parseFloat(x.existencia),
      pvdiva: parseFloat(x.pvdiva),
      pvpiva: parseFloat(x.pvpiva),
      pvd: parseFloat(x.pvd),
      pvp: parseFloat(x.pvp),
      codigoImpuesto: parseInt(x.impuesto),
      tieneIce: x.ice === '-1',
      porcentajeIva: parseFloat(x.porcentajeIva),
      costo: parseFloat(x.costo),
      tipoCodigo: parseInt(x.tipo),
      numeroSerie: parseInt(x.numeroSerie),
      plantilla: parseInt(x.plantilla),
      freeitem: parseInt(x.freeitem),
      gradoAcoholico: parseInt(x.galcoholico),
      capacidad: parseFloat(x.capacidad),
      factor: parseFloat(x.factor),
      jerarquia: x.itemJerarquia,
      descuentos: parseFloat(x.descuentos),
      titadicional: x.titadicional,
      valadicional: x.valadicional,
      grupo: x.grupo,
      codigoUnidadVenta: parseInt(x.unidadv),
      tipoDescripcion: x.itemTipoDescripcion,
      codigoGrupo: parseInt(x.grupoCodigo),
      existenciaMin: parseInt(x.existenciaMin),
      existenciaMax: parseInt(x.existenciaMax),
      saldoReservas: parseInt(x.saldoReservas),
      saldoFecha: parseInt(x.saldoFecha),
      lotes: x.lotes,
      fraccion: parseInt(x.fraccion),
      unidadIce: parseInt(x.codigoUnidadVenta),
      unidadDescripcion: x.unidadDescripcion,
      impuesto: parseInt(x.impuesto),
      impuestoValor: parseInt(x.impuestoValor),
    }
    return item
  })
  return items
}

async function getOrden(cod: number) {
  const queryData: any = {}
  queryData.ordenTipo = 'PROD'
  queryData.ordenCodigo = cod

  const orden = await RequestHelper.get<any>(
    'compras/documentos',
    'ordenes/get',
    '',
    queryData,
  )

  return orden
}

async function getOrdenDetalle(cod: number) {
  const queryData: any = {}
  queryData.orden = cod

  const orden = await RequestHelper.get<any>(
    'generales/orders',
    'getDetail',
    '',
    queryData,
  )

  return orden
}

async function getOrdenCompra(cod: string) {
  try {
    const queryData: any = {
      numero: cod,
    }

    const orden = await RequestHelper.getAll<any>(
      'generales/orders',
      'getBuy',
      '',
      queryData,
    )

    return orden
  } catch (error) {
    return error
  }
}

async function deletePedido(idPedido: any): Promise<any> {
  try {
    const objRequest = {
      ordenCodigo: idPedido,
    }
    const data = await RequestHelper.deleteRequestAll<any>(
      'compras/documentos',
      'ordenes/inactive',
      '',
      objRequest,
    )
    return data
  } catch (error) {
    return error
  }
}

async function uploadFiles(objeto: any): Promise<boolean> {
  try {
    const data = await RequestHelper.postData<boolean>(
      'compras/documentos',
      'ordenes/uploadFile',
      objeto,
    )
    return data
  } catch (error) {
    const smsErr: any = error
    return smsErr
  }
}

async function getVendedorByCode(cod: number) {
  const queryData: any = {}
  queryData.codigo = cod

  const vendedorApi = await RequestHelper.get<any>(
    'ventas/ventas',
    'sellers/getByIdOrCode',
    '',
    queryData,
  )

  return vendedorApi
}

async function buscarItemsPaged(
  descripcion: string,
  codigoBarras: string,
  identificacionCliente: string,
  fecha: string,
  pagination: PaginationInfo,
  prioridadBarras: boolean = false,
  tipoAsociado: ETipoAsociado,
  modulo: EModulosAcatha,
  movimiento: Movimiento,
  codigoPrvAsociado?: number,
  local?: LocalOption,
): Promise<PagedResult<ItemPedido>> {
  const sesion = SesionService.getCurrentSesion()
  const query: any = {
    local: local !== undefined ? local?.codigo : sesion.local.codigo,
    identificacion: identificacionCliente,
    descripcion: descripcion,
    barras: codigoBarras,
    fecha: fecha,
    isPaged: true,
    page: pagination.current,
    limit: pagination.size,
    establecimiento: '',
  }
  if (prioridadBarras) {
    query.descripcion = ''
    query.barras = descripcion === '' ? codigoBarras : descripcion
  }
  let endPoint: string = 'searchItemsLocal'

  switch (modulo) {
    case EModulosAcatha.Kardex:
      endPoint = 'searchItemsLocal'
      break
    case EModulosAcatha.Pedidos:
      endPoint = 'searchItemsLocal'
      break
    case EModulosAcatha.Proformas:
      endPoint = 'searchItemsLocal'
      break
    case EModulosAcatha.MovimientosInventarioInicial:
      endPoint = 'searchItemsLocalSimpleV2'
      break
    case EModulosAcatha.MovimientosIngresosEgresos:
    case EModulosAcatha.MovimientosImportaciones:
      if (movimiento?.asociado === 56) {
        query.codigoProveedor = codigoPrvAsociado
        endPoint = 'searchItemsLocalSimplePurchase'
      } else endPoint = 'searchItemsLocalSimpleV2'
      break
    case EModulosAcatha.Transferencias:
      endPoint = 'searchItemsLocalCorte'
      break
    case EModulosAcatha.IngresoBloque:
      endPoint = 'searchItemsLocalSimple'
      break
    default:
      break
  }
  const pagedApiResult = await RequestHelper.get<any>(
    'inventario',
    'items/',
    endPoint,
    query,
  )

  let itemsParsed = []
  if (pagedApiResult?.auto && pagedApiResult.auto.length) {
    if (endPoint === 'searchItemsLocalSimplePurchase')
      itemsParsed = parseApiItemsLocalSimplePurchase(pagedApiResult.auto)
    else if (endPoint === 'searchItemsLocal')
      itemsParsed = parseApiItemsLocal(pagedApiResult.auto)
    else if (
      endPoint === 'searchItemsLocalSimple' ||
      endPoint === 'searchItemsLocalSimpleV2'
    )
      itemsParsed = parseApiItemsLocalSimple(pagedApiResult.auto)
    else if (endPoint === 'searchItemsLocalCorte')
      itemsParsed = parseApiItemsLocalSimple(pagedApiResult.auto)
  }

  return {
    currentPage: parseInt(pagedApiResult.paginaActual),
    from: parseInt(pagedApiResult.desde),
    to: parseInt(pagedApiResult.hasta),
    count: parseInt(pagedApiResult.totalRegistros),
    result: itemsParsed,
  }
}

function useGetItemsDatasource() {
  const { setLoader, setNumberItemsFound } = useContext(LoaderContext)

  const getItemsDatasource = useCallback(
    (
      cliente: Cliente | null,
      fecha: string = DateUtils.getCurrentDateAsString(formatoFechasApi),
      prioridadBarras: boolean = false,
      tipoAsociado: ETipoAsociado = ETipoAsociado.Clientes,
      modulo: EModulosAcatha = EModulosAcatha.Clientes,
      movimiento: Movimiento,
      codigoPrvAsociado?: number,
      local?: LocalOption,
      autocomplete?: boolean,
    ) => {
      const regex = new RegExp('^[0-9]{3,13}$')

      const store = new CustomStore({
        key: 'codigoBarras',
        byKey: async (key) => {
          const item = await getItemPorCodigoBarrasPedido(
            key,
            cliente?.identificacion ?? config.rucConsumidorFinal,
            fecha,
            tipoAsociado,
            modulo,
            movimiento,
            codigoPrvAsociado,
            local,
          )
          return item.length > 0 ? item[0] : undefined
        },
        load: async (loadOptions) => {
          if (autocomplete) setLoader(true)
          const paginInfo: PaginationInfo = {
            current: (loadOptions.skip ?? 0) / (loadOptions.take ?? 0) + 1,
            size: loadOptions.take ?? 10,
          }
          let pagedResult: PagedResult<ItemPedido>
          if (regex.test(loadOptions.searchValue)) {
            pagedResult = await buscarItemsPaged(
              '',
              loadOptions.searchValue ?? '',
              cliente?.identificacion ?? config.rucConsumidorFinal,
              fecha,
              paginInfo,
              prioridadBarras,
              tipoAsociado,
              modulo,
              movimiento,
              codigoPrvAsociado,
              local,
            )
          } else {
            pagedResult = await buscarItemsPaged(
              loadOptions.searchValue ?? '',
              '',
              cliente?.identificacion ?? config.rucConsumidorFinal,
              fecha,
              paginInfo,
              prioridadBarras,
              tipoAsociado,
              modulo,
              movimiento,
              codigoPrvAsociado,
              local,
            )
          }
          if (autocomplete) {
            setLoader(false)
            setNumberItemsFound(pagedResult.count)
          }

          return {
            data: pagedResult.result,
            totalCount: pagedResult.count,
          }
        },
      })
      const source = new DataSource({
        paginate: true,
        pageSize: 10,
        store: store,
        key: 'codigoBarras',
      })
      return source
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return { getItemsDatasource }
}
