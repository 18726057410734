import { DateUtils, formatoFechasApi } from "../../../../helpers/dateUtils";
import { RequestHelper } from "../../../../helpers/requestHelper";
import { SesionService } from "../../../../services/sesion.service";
import { parseOptions } from "../helper/helper";
import { TTipoItem } from "../store/types";

export const tipoItemService = { getTipoItems };

async function getTipoItems(): Promise<any> {
  const sesion = SesionService.getCurrentSesion()
  const data: any = {
    mensaje: 'Elija una opción',
    paisCodigo: sesion?.empresa?.codigoPais ?? 1, // 1 -  Ecuador => por defecto
  };
  const keysToCache: any = {
    ...data,
    dateKey: DateUtils.getCurrentDateAsString(formatoFechasApi),
    empresa: sesion?.empresa?.codigo,
  }
  data.empresa = sesion.empresa.codigo ?? 0;
  const cacheKey = 'tipoItem' + JSON.stringify(keysToCache);
  const cached = sessionStorage.getItem(cacheKey);
  if (cached) {
    return { error: false, auto: JSON.parse(cached) };
  } else {
    const tipoItems: any = await RequestHelper.getAll('tipoItem', 'listar', "", data);
    let opt: Array<TTipoItem> = [];
    if (tipoItems?.auto && tipoItems?.auto.length > 0) {
      opt = parseOptions(tipoItems?.auto);
    }
    sessionStorage.setItem(cacheKey, JSON.stringify(opt));
    return tipoItems;
  }
}

