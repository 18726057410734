import React from 'react'
import { DatosEdicionCompra } from '../../../../types/types'
import { useDispatch, useSelector } from 'react-redux'
import RowContainer from '../../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../../views/componentes/labeledInput/labeledInput'
import {
  Button,
  DataGrid,
  NumberBox,
  SelectBox,
  TextBox,
} from 'devextreme-react'
import { CButton, CCard, CCol, CRow } from '@coreui/react-pro'
import { Column, Scrolling, Sorting } from 'devextreme-react/data-grid'
import { RootState } from '../../../../../../../../store/store'
import { setTabCompra } from '../../../../store/editDataReducer'
import { ToastTypes } from '../../../../../../../../store/types'
import SustentosTributariosLookUp from '../../../../../../../componentes/sustentosTributarioLookUp'
import { AdminAutorizacionesModal } from '../../../../../../../componentes/modalAutorizaciones'
import { setAutorizacion, setEstablecimiento, setPuntoEmision } from '../../../../../../../compras/pages/compras/store/editDataReducer'
import { ModalListaRetencionesIVA } from '../../../../../../../componentes/modalListaRetencionesIVA/modalListaRetencionesIVA'
import { TRetencionesIva } from '../../../../../../../clientes/pages/clientes/types/types'
import { TRetencionesOptionsIva } from '../../../../../../../componentes/modalListaRetencionesIVA/components/types/type'
import { v4 as uuidv4 } from 'uuid'
import { getIndexProvider } from '../../../../../../../shared/helpers/funciones'
import ModalCuentas from '../../../../../../../contabilidad/pages/planCuentas/modalCuentas'

interface IDataPruchasesesProps {
  tabId: string
  refForm: any
  setToast: (sms: string, tipo: ToastTypes) => void
}

const Compras: React.FunctionComponent<IDataPruchasesesProps> = (props) => {
  const { tabId, setToast } = props
  const dispatch = useDispatch()
  const [showlistaRet, setShowlistaRet] = React.useState<boolean>(false)

  const stateEditPurchases = useSelector((state: RootState) => {
    return state.proveedores.proveedores.editData[tabId]?.tabs?.compras
  });
  const stateEditTextpayer = useSelector((state: RootState) => {
    return state.proveedores.proveedores.editData[tabId]?.tabs?.contribuyente
  });
  const retenciones = useSelector((state: RootState) => {
    return state.proveedores.proveedores.editData[tabId]?.tabs.compras?.retenciones
  })
  const formasPago = useSelector((state: RootState) => {
    return state.proveedores.proveedores.editData[tabId]?.tabs.compras?.formasPago
  })
  const precision = useSelector((state: RootState) => { return state.global.session?.empresa?.precision });
  const modulo = useSelector((state: RootState) => { return state.proveedores.proveedores.configuraciones.modulo });
  const [openAutorizaciones, setOpenAutorizaciones] = React.useState(false);
  const [showModalCuentas, setShowModalCuentas] = React.useState(false);
  const [formasPagoSeleccionado, setFormasPagoSeleccionado] = React.useState(null);
  const [retencionSeleccionado, setRetencionSeleccionado] = React.useState(null);


  const onChangedCreditDays = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setTabCompra({
            key: tabId,
            data: {
              ...stateEditPurchases,
              diasCredito: event.value,
            },
          }),
        )
    },
    [dispatch, stateEditPurchases, tabId],
  )

  const onChangedCreditLimit = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setTabCompra({
            key: tabId,
            data: {
              ...stateEditPurchases,
              limiteCredito: Number(event.value.toFixed(precision)),
            },
          }),
        )
    },
    [dispatch, stateEditPurchases, tabId, precision])

  const onChangedDiscount = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setTabCompra({
            key: tabId,
            data: {
              ...stateEditPurchases,
              descuento: event.value,
            },
          }),
        )
    },
    [dispatch, stateEditPurchases, tabId],
  )

  const onChangedBalance = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setTabCompra({
            key: tabId,
            data: {
              ...stateEditPurchases,
              saldo: event.value,
            },
          }),
        )
    },
    [dispatch, stateEditPurchases, tabId],
  )

  const onChangedPurchasesSupport = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setTabCompra({
            key: tabId,
            data: {
              ...stateEditPurchases,
              atencionCompras: event.value,
            },
          }),
        )
    },
    [dispatch, stateEditPurchases, tabId],
  )

  const onChangedPaymentsSupport = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setTabCompra({
            key: tabId,
            data: {
              ...stateEditPurchases,
              atencionPagos: event.value,
            },
          }),
        )
    }, [dispatch, stateEditPurchases, tabId])

  const onChangedSustentoTributario = React.useCallback(
    (data: any) => {
      if (data)
        dispatch(
          setTabCompra({
            key: tabId,
            data: {
              ...stateEditPurchases,
              sustentoTri: data,
            },
          }),
        )
    }, [dispatch, stateEditPurchases, tabId])

  const onChangeRetencionIva = React.useCallback(
    (data: TRetencionesOptionsIva) => {
      const ret: TRetencionesIva = {
        id: uuidv4(),
        impuesto: 0,
        porcentaje: data.grupo ?? 0,
        retencion: 0,
        codigo: data.codigo,
        tipo: data.tipoCodigo,
        descripcion: data.valor,
      }
      const selectedIndex = retenciones.findIndex((retState: TRetencionesIva) => {
        return retState.codigo === ret.codigo
      })
      if (selectedIndex === -1) {
        dispatch(
          setTabCompra({
            key: tabId,
            data: {
              ...stateEditPurchases,
              retenciones: [...retenciones, ret],
            }
          })
        )
      }
    },
    [dispatch, retenciones, tabId, stateEditPurchases],)

  const deleteItemRetencion = React.useCallback(async () => {
    if (retencionSeleccionado !== null && retencionSeleccionado !== undefined && retencionSeleccionado.codigo) {
      if (retenciones !== null && retenciones !== undefined && retenciones.length > 0) {
        const provider = retenciones.slice(0);
        const index = await getIndexProvider(provider, 'codigo', retencionSeleccionado.codigo)
        if (index > -1) {
          provider.splice(index, 1)
          dispatch(
            setTabCompra({
              key: tabId,
              data: {
                ...stateEditPurchases,
                retenciones: provider,
              }
            })
          )
          setRetencionSeleccionado(null)
        }
      }
    }
  }, [dispatch, retencionSeleccionado, retenciones, stateEditPurchases, tabId])

  const onSelectFormasPago = React.useCallback(({ selectedRowsData }) => {
    setFormasPagoSeleccionado(selectedRowsData[0])
  }, [])
  const onSelectRetencion = React.useCallback(({ selectedRowsData }) => {
    setRetencionSeleccionado(selectedRowsData[0])
  }, [])

  const cargarCuentas = React.useCallback(async evt => {
    console.log(evt)
    const provider: any[] = formasPago.slice(0);
    const index = await getIndexProvider(provider, 'codigo', formasPagoSeleccionado.codigo)
    if (index > -1) {
      const editItem: any = {
        codigoFormaPago: provider[index].codigoFormaPago,
        descripcionFormaPago: provider[index].descripcionFormaPago,
        cuentaCodigo: evt?.codigo ?? 0,
        cuentaNumero: evt?.numero ?? "",
        cuentaDescripcion: evt?.descripcion ?? "",
        transaccion: provider[index]?.transaccion ?? "",
        enUso: provider[index]?.enUso ?? "",
      }

      provider[index] = editItem
    }
    dispatch(
      setTabCompra({
        key: tabId,
        data: {
          ...stateEditPurchases,
          formasPago: provider,
        }
      })
    )
  }, [dispatch, formasPago, formasPagoSeleccionado, stateEditPurchases, tabId])

  return (
    <>
      {openAutorizaciones &&
        <AdminAutorizacionesModal
          onChanged={(data) => {
            dispatch(setEstablecimiento({ key: tabId, establecimiento: data.establecimiento }))
            dispatch(setPuntoEmision({ key: tabId, puntoEmision: data.puntoEmision }))
            dispatch(setAutorizacion({ key: tabId, autorizacion: data.numeroAutorizacion }))
            setOpenAutorizaciones(false)
          }}
          onCancel={() => { setOpenAutorizaciones(false) }}
          show={openAutorizaciones}
          modulo={modulo}
          data={{
            codigoPertenece: 2,
            codigoAsociado: stateEditTextpayer.codigo ?? 0,
            nombreAsociado: stateEditTextpayer.nombre ?? "",
          }}
        />
      }
      {showlistaRet &&
        <ModalListaRetencionesIVA
          onChanged={(data) => {
            onChangeRetencionIva(data)
            setShowlistaRet(false)
          }}
          onCancel={() => {
            setShowlistaRet(false)
          }}
          show={showlistaRet}
        />
      }
      {
        showModalCuentas && <ModalCuentas
          show={showModalCuentas}
          onClose={() => setShowModalCuentas(false)}
          cargarCuentas={evt => cargarCuentas(evt)}
        />
      }
      <RowContainer>
        <CustomCol xs="12" md="2">
          <Labeled label="Días de Crédito:">
            <NumberBox
              name="diasCredito"
              placeholder="Número de días de crédito"
              value={stateEditPurchases?.diasCredito}
              onValueChanged={onChangedCreditDays}
              showSpinButtons
              min={0}
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="2">
          <Labeled label="Límite de Crédito:">
            <NumberBox
              name="limiteCredito"
              placeholder="Valor del limite de crédito"
              value={stateEditPurchases?.limiteCredito}
              onValueChanged={onChangedCreditLimit}
              className='textInputAlignRight'
              format={{ precision: precision }}
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="2">
          <Labeled label="Descuento (%):">
            <NumberBox
              name="porcentajeDescuento"
              placeholder="Valor del porcentaje de descuento"
              value={stateEditPurchases?.descuento}
              onValueChanged={onChangedDiscount}
              className='textInputAlignRight'
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="2">
          <strong>
            <Labeled label="Saldo:">
              <NumberBox
                name="saldo"
                value={stateEditPurchases?.saldo}
                onValueChanged={onChangedBalance}
              />
            </Labeled>
          </strong>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="4">
          <Labeled label="Atención Compras:">
            <TextBox
              name="atencionCompras"
              value={stateEditPurchases?.atencionCompras}
              onValueChanged={onChangedPurchasesSupport}
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="4">
          <Labeled label="Atención Pagos:">
            <TextBox
              name="atencionPagos"
              value={stateEditPurchases?.atencionPagos}
              onValueChanged={onChangedPaymentsSupport}
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="5">
          <Labeled label="Sustento Tributario:">
            <SustentosTributariosLookUp
              selected={stateEditPurchases?.sustentoTri}
              provider={stateEditPurchases?.sustentoTriOptions ?? []}
              onChanged={(e) => {
                if (e !== null || e !== undefined) {
                  onChangedSustentoTributario(e)
                }
              }}
              onChangedOptions={() => { }}
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="2">
          <Labeled label="Autorizaciones SRI :">
            <Button onClick={() => {
              if (stateEditTextpayer.codigo !== 0) {
                setOpenAutorizaciones(true)
              } else {
                setToast('Para ingresar autorizaciones al proveedor primero registre sus datos', ToastTypes.Warning)
              }
            }} icon="add" text="" />
          </Labeled>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="10" className="mt-2 mb-2">
          <CCard>
            <CRow className="d-flex justify-content-between">
              <CCol xs="12" md="6" className="m-2">
                <Labeled label="RETENCIONES EN LA FUENTE DEL I.V.A." />
              </CCol>
              <CCol xs="12" md="6" className="m-2">
                <Button onClick={() => setShowlistaRet(true)} icon="add" text="Agregar" />
                <Button onClick={deleteItemRetencion} disabled={retencionSeleccionado === null} icon="trash" text="Quitar" />
              </CCol>
            </CRow>
            <CRow>
              <CCol xs="12" md="12">
                <DataGrid
                  className="m-2"
                  selection={{ mode: 'single' }}
                  hoverStateEnabled={true}
                  dataSource={stateEditPurchases?.retenciones}
                  showBorders={true}
                  showRowLines={true}
                  onSelectionChanged={onSelectRetencion}
                  onRowDblClick={deleteItemRetencion}
                >
                  <Column
                    dataType="string"
                    dataField="descripcion"
                    caption="Impuesto"
                    width="70%"
                  />
                  <Column
                    dataType="string"
                    dataField="porcentaje"
                    caption="Porcentaje"
                    width="30%"
                  />
                </DataGrid>
              </CCol>
            </CRow>
          </CCard>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="10">
          <CRow>
            <CCol md="10" xs="8">
              <Labeled label="Formas de Pago:" />
            </CCol>
            <CCol md="2" xs="4" className="d-flex justify-content-end">
              <CButton
                style={{ marginTop: '4px' }}
                onClick={() => setShowModalCuentas(true)}
                size="sm"
                disabled={formasPagoSeleccionado === null || formasPagoSeleccionado === undefined}
                active
                color="secondary"
                aria-pressed="true"
              >
                {'Cuentas'}
              </CButton>
            </CCol>
          </CRow>
          <CRow>
            <CCol md="12" xs="12" className="mt-2">
              <DataGrid
                selection={{ mode: 'single' }}
                hoverStateEnabled={true}
                height={240}
                dataSource={stateEditPurchases?.formasPago}
                showBorders={true}
                showRowLines={true}
                onSelectionChanged={onSelectFormasPago}
              >
                <Sorting mode="none" />
                <Scrolling
                  mode="virtual" />
                <Column
                  dataType="string"
                  dataField="descripcionFormaPago"
                  caption="Formas de Pago"
                  width="20%"
                />
                <Column
                  dataType="string"
                  dataField="cuentaNumero"
                  caption="Nro. de Cuenta"
                  width="20%"
                />
                <Column
                  dataType="string"
                  dataField="cuentaDescripcion"
                  caption="Cuenta"
                  width="60%"
                />
              </DataGrid>
            </CCol>
          </CRow>
        </CustomCol>
      </RowContainer>
    </>
  )
}

export default Compras

export const defaultDatosCompra: DatosEdicionCompra = {
  diasCredito: 0,
  limiteCredito: 0,
  descuento: 0,
  saldo: 0,
  sustentoTri: null,
  sustentoTriOptions: [],
  atencionCompras: '',
  atencionPagos: '',
  retenciones: [],
  formasPago: [],
}
