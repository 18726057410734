import { RequestHelper } from "../../../../../../../helpers/requestHelper"


export const VerAbonosService = {
  seePayments,
  deletePayment,
}

async function seePayments(objeto: any): Promise<any> {
  const obj = {
    prvCodigo: objeto['proveedor']?.codigo ?? 0,
    comNumero: objeto['comprobante'],
    comEstablecimiento: objeto['fechaDesde'],
    comPtoEmision: objeto['fechaHasta'],
    comTipodocDes: objeto['egreso'],
    comFpagoDes: objeto['cheque'],
    comEstado: !objeto['anulados'],
  }

  try {
    const data = await RequestHelper.getAll<any>('generales', 'purchases/seePayment', '', obj)
    return data
  } catch (error) {
    return error
  }
}

async function deletePayment(objeto: any): Promise<any> {
  const obj = {
    egresoCodigo: objeto['egreso'],
    proveedorCodigo: objeto['proveedor'],
  }

  try {
    const data = await RequestHelper.deleteRequestAll<any>('proveedores/documentosPorPagar', 'deletePayment', '', obj)
    return data
  } catch (error) {
    return error
  }
}