import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ConfiguracionesRetenciones } from '../types/types'
import { AutorizacionDocumentoVenta } from '../../../../../ventas/types/types'

const initialState: ConfiguracionesRetenciones = {
  autorizacionDocumento: null,
}

const configuracionesRetencionesSlice = createSlice({
  name: 'configuracionesRetencionesComprasSV',
  initialState: initialState,
  reducers: {
    initialize(state, acion: PayloadAction<AutorizacionDocumentoVenta>) {
      state.autorizacionDocumento = acion.payload
    },
    setAutorizacionModuloRetenciones(
      state,
      acion: PayloadAction<AutorizacionDocumentoVenta>,
    ) {
      state.autorizacionDocumento = acion.payload
    },
  },
})

export const configuracionesReducer = configuracionesRetencionesSlice.reducer
export const { setAutorizacionModuloRetenciones } =
  configuracionesRetencionesSlice.actions
