import React from 'react'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput'
import { Button, NumberBox } from 'devextreme-react'
import { DocumentInfo, ToastTypes } from '../../../../../../../store/types'
import { PersonalDatosEdicion, RentasSave } from '../../../types/types'
import { TabState } from '../../../../../../../store/genericTabTypes'
import DataGrid, { Column } from 'devextreme-react/data-grid'
import { setChangeLoaderEdit, setDatosEdicionRentas, setResetRenta } from '../../../store/editDataReducer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import { addToast } from '../../../../../../../store/toasterReducer'
import { PersonalServices } from '../../../services/personal.service';
import { consoleService } from '../../../../../../../services/console.service'
import { DateUtils } from '../../../../../../../helpers/dateUtils'
import { getIndexProvider } from '../../../../../../shared/helpers/funciones'

interface INewProps extends React.PropsWithChildren {
  info: DocumentInfo<PersonalDatosEdicion>
  tab: TabState<PersonalDatosEdicion>
  tabId: string
  refForm: any
}

const Rentas: React.FunctionComponent<INewProps> = (props) => {

  const { tabId } = props
  const dispatch = useDispatch()
  const rentasState = useSelector((state: RootState) => state.nomina.personal.editData[tabId].rentas)
  const seleccionado = useSelector((state: RootState) => state.nomina.personal.editData[tabId].rentas.seleccionadoRenta)
  const personalState = useSelector((state: RootState) => state.nomina.personal.editData[tabId])
  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(
      addToast({
        title: 'Nómina - Personal',
        content: mensaje,
        type: tipo,
      }),
    )
  }, [dispatch])

  const onLoader = React.useCallback((showLoader: boolean, text: string) => {
    dispatch(setChangeLoaderEdit({
      key: tabId,
      data: {
        show: showLoader,
        mensaje: text
      }
    }))
  }, [dispatch, tabId])


  const onChangeValue = React.useCallback((data, key: string) => {

    dispatch(setDatosEdicionRentas({
      key: tabId,
      data: {
        ...rentasState,
        [key]: data
      }
    }))
  }, [dispatch, rentasState, tabId])


  const onSaveRenta = React.useCallback(async () => {
    const data_save: RentasSave = {
      infoRegistro: {
        codigoREM: rentasState?.codigoREM ?? 0,
        alimentacion: rentasState?.alimentacion ?? 0,
        anio: rentasState?.anio ?? 0,
        deducciones: rentasState?.dgpEmpleadores ?? 0,
        discapacitados: rentasState?.discapacitados ?? 0,
        educacion: rentasState?.educacion ?? 0,
        ingresos: rentasState?.iGEmpleadores ?? 0,
        rebajas: rentasState?.orcEmpleadores ?? 0,
        renta: rentasState?.iRporEpleado ?? 0,
        retencion: rentasState?.virEmpleadores ?? 0,
        retenciones: rentasState?.numeroRetenciones ?? 0,
        rotros: rentasState?.virEmpleadoresAnteriores ?? 0,
        salud: rentasState?.salud ?? 0,
        terceraEdad: rentasState?.terceraEdad ?? 0,
        vestimenta: rentasState?.vestimenta ?? 0,
        vivienda: rentasState?.vivienda ?? 0,
        usuario: personalState?.codigo ?? 0,
      }
    }
    onLoader(true, 'Guardando Renta...')
    try {
      const data = await PersonalServices.saveRentaPersonal(data_save)
      consoleService.log(data, '_____rentasSave')
      if (data['auto'] && data['error'] === false) {
        setToast(data['message'], ToastTypes.Success)
        const renta = await PersonalServices.getRentaPersonal(personalState?.codigo ?? 0)
        consoleService.log(renta, '_____dataRentas')
        if (renta['auto'] && renta['error'] === false) {
          dispatch(setDatosEdicionRentas({
            key: tabId,
            data: {
              ...rentasState,
              rentas: renta['auto'],
              codigoREM: 0,
              alimentacion: 0,
              anio: parseInt(DateUtils.getCurrentDateAsString('dd/MM/yyyy').substring(6.4)),
              dgpEmpleadores: 0,
              discapacitados: 0,
              educacion: 0,
              iGEmpleadores: 0,
              orcEmpleadores: 0,
              iRporEpleado: 0,
              virEmpleadores: 0,
              numeroRetenciones: 0,
              virEmpleadoresAnteriores: 0,
              salud: 0,
              terceraEdad: 0,
              vestimenta: 0,
              vivienda: 0,
            }
          }))
        }
      } else {
        setToast(data['message'], ToastTypes.Danger)
      }
    } catch (error) {
      setToast(error?.save, ToastTypes.Danger)
    }
    onLoader(false, '')
  }, [personalState, rentasState, setToast, onLoader, dispatch, tabId])

  const deleteRecordrenta = React.useCallback(async () => {
    if (seleccionado !== null && seleccionado?.codigo) {
      const temp = { ...rentasState }
      const provider = rentasState.rentas?.slice(0)
      const index = await getIndexProvider(provider, 'codigo', seleccionado?.codigo)
      if (index > -1) {
        provider.splice(index, 1)
        temp.rentas = provider
        temp.seleccionadoRenta = null
        temp.codigoREM = 0,
          temp.alimentacion = 0,
          temp.anio = parseInt(DateUtils.getCurrentDateAsString('dd/MM/yyyy').substring(6.4)),
          temp.dgpEmpleadores = 0,
          temp.discapacitados = 0,
          temp.educacion = 0,
          temp.iGEmpleadores = 0,
          temp.orcEmpleadores = 0,
          temp.iRporEpleado = 0,
          temp.virEmpleadores = 0,
          temp.numeroRetenciones = 0,
          temp.virEmpleadoresAnteriores = 0,
          temp.salud = 0,
          temp.terceraEdad = 0,
          temp.vestimenta = 0,
          temp.vivienda = 0,
          dispatch(setDatosEdicionRentas({
            key: tabId,
            data: { ...temp }
          }))
      }
    }
  }, [dispatch, tabId, seleccionado, rentasState])

  const onDeleteRenta = React.useCallback(async () => {
    if (seleccionado !== null && seleccionado?.codigo) {
      onLoader(true, 'Eliminar Renta...')
      consoleService.log(seleccionado, '____eliminar')
      try {
        const data = await PersonalServices.getDeletePersonal(seleccionado?.codigo ?? 0)
        consoleService.log(data, '_____rentasdelete')
        if (data['auto'] && data['error'] === false) {
          deleteRecordrenta()
          setToast(data['message'], ToastTypes.Success)
        }
      } catch (error) {
        setToast(error?.save, ToastTypes.Danger)
      }
      onLoader(false, '')
    }
  }, [seleccionado, setToast, onLoader, deleteRecordrenta])

  const onGenerate107 = React.useCallback(async () => {
    onLoader(true, 'Generando...')
    if (seleccionado !== null && seleccionado?.codigo) {
      try {
        const generate = await PersonalServices.getGenerate107(personalState?.codigo, seleccionado?.anio)
        consoleService.log(generate, '____generate107')
        if (generate['auto'] && generate['error'] === false) {
          setToast(generate['message'], ToastTypes.Success)
        } else {
          consoleService.log(generate, '____respuesta negativa')
          setToast('La busqueda no produjo resultados', ToastTypes.Danger)
        }
      } catch (error) {
        setToast(error.message, ToastTypes.Danger)
      }
    } else {
      setToast('Seleccione un año por favor', ToastTypes.Warning)
    }
    onLoader(false, '')
  }, [setToast, seleccionado, personalState, onLoader])

  const onSelectedChangedRenta = React.useCallback(
    ({ selectedRowsData }) => {
      consoleService.log(selectedRowsData[0], 'selectedRowsData[0] renta')
      if (selectedRowsData[0] && selectedRowsData[0]?.codigo) {
        dispatch(setDatosEdicionRentas({
          key: tabId,
          data: {
            ...rentasState,
            codigoREM: selectedRowsData[0]?.codigo ?? 0,
            alimentacion: selectedRowsData[0]?.alimentacion ?? 0,
            anio: selectedRowsData[0]?.anio ?? 0,
            dgpEmpleadores: selectedRowsData[0]?.deducciones ?? 0,
            discapacitados: selectedRowsData[0]?.discapacitados ?? 0,
            educacion: selectedRowsData[0]?.educacion ?? 0,
            iGEmpleadores: selectedRowsData[0]?.ingresos ?? 0,
            orcEmpleadores: selectedRowsData[0]?.rebajas ?? 0,
            iRporEpleado: selectedRowsData[0]?.renta ?? 0,
            virEmpleadores: selectedRowsData[0]?.retencion ?? 0,
            numeroRetenciones: selectedRowsData[0]?.retenciones ?? 0,
            virEmpleadoresAnteriores: selectedRowsData[0]?.rotros ?? 0,
            salud: selectedRowsData[0]?.salud ?? 0,
            terceraEdad: selectedRowsData[0]?.terceraedad ?? 0,
            vestimenta: selectedRowsData[0]?.vestimenta ?? 0,
            vivienda: selectedRowsData[0]?.vivienda ?? 0,
            seleccionadoRenta: { ...selectedRowsData[0] }
          }
        }))
      }
    },
    [dispatch, tabId, rentasState],
  )

  return (
    <>
      <RowContainer>
        <CustomCol xs="12" md="5">
          <RowContainer>
            <CustomCol xs="12" md="12">
              <Labeled label="Año:">
                <NumberBox
                  value={rentasState?.anio ?? 0}
                  onValueChange={(data) => onChangeValue(data, 'anio')}
                />
              </Labeled>
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol xs="12" md="12">
              <p>Deducibles:</p>

            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol xs="12" md="12">
              <Labeled label="Vivienda:">
                <NumberBox
                  value={rentasState?.vivienda ?? 0}
                  onValueChange={(data) => onChangeValue(data, 'vivienda')}
                />
              </Labeled>
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol xs="12" md="12">
              <Labeled label="Salud:">
                <NumberBox
                  value={rentasState?.salud ?? 0}
                  onValueChange={(data) => onChangeValue(data, 'salud')}
                />
              </Labeled>
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol xs="12" md="12">
              <Labeled label="Educación:">
                <NumberBox
                  value={rentasState?.educacion ?? 0}
                  onValueChange={(data) => onChangeValue(data, 'educacion')}
                />
              </Labeled>
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol xs="12" md="12">
              <Labeled label="Alimentación:">
                <NumberBox
                  value={rentasState?.alimentacion ?? 0}
                  onValueChange={(data) => onChangeValue(data, 'alimentacion')}
                />
              </Labeled>
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol xs="12" md="12">
              <Labeled label="Vestimenta:">
                <NumberBox
                  value={rentasState?.vestimenta ?? 0}
                  onValueChange={(data) => onChangeValue(data, 'vestimenta')}
                />
              </Labeled>
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol xs="12" md="12">
              <p>Rebajas Especiales:</p>
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol xs="12" md="12">
              <Labeled label="Discapacitados:">
                <NumberBox
                  value={rentasState?.discapacitados ?? 0}
                  onValueChange={(data) => onChangeValue(data, 'discapacitados')}
                />
              </Labeled>
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol xs="12" md="12">
              <Labeled label="Tercera edad:">
                <NumberBox
                  value={rentasState?.terceraEdad ?? 0}
                  onValueChange={(data) => onChangeValue(data, 'terceraEdad')}
                />
              </Labeled>
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol xs="12" md="12">
              <Labeled label="IR Asumido pro el Empleado:">
                <NumberBox
                  value={rentasState?.iRporEpleado ?? 0}
                  onValueChange={(data) => onChangeValue(data, 'iRporEpleado')}
                />
              </Labeled>
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol xs="12" md="12">
              <Labeled label="Ingresos Grabados generados con otros empleadores:">
                <NumberBox
                  value={rentasState?.iGEmpleadores ?? 0}
                  onValueChange={(data) => onChangeValue(data, 'iGEmpleadores')}
                />
              </Labeled>
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol xs="12" md="12">
              <Labeled label="Deducción de Gastos Personales consideradas por otros empleadores:">
                <NumberBox
                  value={rentasState?.dgpEmpleadores ?? 0}
                  onValueChange={(data) => onChangeValue(data, 'dgpEmpleadores')}
                />
              </Labeled>
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol xs="12" md="12">
              <Labeled label="Otras rebajas consideradas por otros empleadores:">
                <NumberBox
                  value={rentasState?.orcEmpleadores ?? 0}
                  onValueChange={(data) => onChangeValue(data, 'orcEmpleadores')}
                />
              </Labeled>
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol xs="12" md="12">
              <Labeled label="Valor del impuesto retenido por este empleador:">
                <NumberBox
                  value={rentasState?.virEmpleadores ?? 0}
                  onValueChange={(data) => onChangeValue(data, 'virEmpleadores')}
                />
              </Labeled>
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol xs="12" md="12">
              <Labeled label="Número de retenciones:">
                <NumberBox
                  value={rentasState?.numeroRetenciones ?? 0}
                  onValueChange={(data) => onChangeValue(data, 'numeroRetenciones')}
                />
              </Labeled>
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol xs="12" md="12">
              <Labeled label="Valor del impuesto retenido por empleadores anteriores:">
                <NumberBox
                  value={rentasState?.virEmpleadoresAnteriores ?? 0}
                  onValueChange={(data) => onChangeValue(data, 'virEmpleadoresAnteriores')}
                />
              </Labeled>
            </CustomCol>
          </RowContainer>
        </CustomCol>
        <CustomCol xs="12" md="7">
          <RowContainer>
            <CustomCol md="12">
              <DataGrid
                id="tablaSeguimientoRegistros"
                // ref={tablaRefPager}
                dataSource={rentasState?.rentas ?? []}
                selection={{ mode: 'single' }}
                hoverStateEnabled={true}
                showBorders={true}
                showRowLines={true}
                focusedRowEnabled={true}
                onSelectionChanged={onSelectedChangedRenta}
                // onRowDblClick={onDBClick}
                repaintChangesOnly={true}
                highlightChanges={true}
                keyExpr="codigo"
                key="codigo"
                focusedRowKey="codigo"
                showColumnLines={true}
                // onExporting={(e) => { }}
                className={'m-2 p-2'}
                // onOptionChanged={(e) => handleOptionChange(e)}
                remoteOperations={true}
                loadPanel={{
                  enabled: true,
                  height: 90,
                  indicatorSrc: '',
                  shading: false,
                  shadingColor: '',
                  showIndicator: true,
                  showPane: true,
                  text: 'Cargando...',
                  width: 200,
                }}
              >
                <Column dataField="anio" caption="Año" width="100px" />
              </DataGrid>
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol className='d-flex align-items-end justify-content-end'>
              <Button
                id="btnAdd"
                className="me-1"
                icon="add"
                stylingMode="contained"
                type="default"
                onClick={() => onSaveRenta()}
              />
              <Button
                id="btnRemove"
                className="me-1"
                icon="minus"
                stylingMode="contained"
                disabled={rentasState?.seleccionadoRenta === null}
                type="default"
                onClick={() => onDeleteRenta()}
              />
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol className='d-flex align-items-start justify-content-start'>
              <Button
                id="formulario107"
                className="me-1"
                stylingMode="contained"
                type="default"
                onClick={() => onGenerate107()}
              >
                {'Formulario 107'}
              </Button>
            </CustomCol>
          </RowContainer>
        </CustomCol>
      </RowContainer>

    </>
  )
}

export default React.memo(Rentas)