import { DateUtils, formatoFechasApi } from "../../../../helpers/dateUtils";
import { RequestHelper } from "../../../../helpers/requestHelper";
import { SesionService } from "../../../../services/sesion.service";
import { GetHour, THoursLoad } from "../types/types";

export const HoursLoadService = { getHoursLoad };

async function getHoursLoad(obj: GetHour, listado: boolean, label: string): Promise<any> {
  const sesion = SesionService.getCurrentSesion();
  try {
    const data: any = {
      ...obj,
      listado,
      mensaje: label,
    };
    const tipos: any = await RequestHelper.getAll('nomina/registro/calendarios', 'horario/getClockwise', "", data);

    const opt: Array<THoursLoad> = []

    if (tipos?.auto && tipos?.auto.length > 0) {
      for (const x of tipos.auto) {
        opt.push({
          codigo: x?.codigo ?? -1,
          dia: x?.dia ?? 0,
          descripcion: x?.descripcion ?? '',
          entrada: x?.entrada ?? '',
          salida: x?.salida ?? '',
          htrCodigo: x?.htrCodigo ?? 0,
        })
      }
    }
    // sessionStorage.setItem(cacheKey, JSON.stringify(opt));
    tipos.auto = opt ?? []
    return tipos;
  } catch (error) {
    return error;
  }
}