import React, { useState } from 'react'
import RowContainer from '../../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../../views/componentes/colContainer'
import {
  Button,
  DataGrid,
  DateBox,
  NumberBox,
  RadioGroup,
  TextBox,
  Validator,
} from 'devextreme-react'
import { AsyncRule, Column, RequiredRule } from 'devextreme-react/data-grid'
import { CRow } from '@coreui/react-pro'
import Labeled from '../../../../../../../../views/componentes/labeledInput/labeledInput'
import { Col } from 'react-bootstrap'
import CondicionActivoLookUp from '../../../../../../../componentes/condicionActivoLookUp'
import TiposDepreciacionLookUp from '../../../../../../../componentes/tiposDepreciacionLookUp'
import MetodosTiposDepreciacionesLookUp from '../../../../../../../componentes/metodoTipoDepreciacionLookUp'
import { ModalCaracteristicas } from '../../../modalCaracteristicas'
import {
  Characteristic,
  ItemActives,
  LedgerAccount,
} from '../../../../types/types'
import { useDispatch, useSelector } from 'react-redux'
import { useSetToast } from '../../../../../../../../hooks/useGlobalHooks'
import { RootState } from '../../../../../../../../store/store'
import {
  setConditionActive,
  setDataActivesToEdit,
  setDepreciationMethod,
  setDepreciationMethodInf,
  setDepreciationType,
} from '../../../../store/editDataReduce'
import { CondicionActivo } from '../../../../../../../componentes/condicionActivoLookUp/types/types'
import { TOptionGenericoString } from '../../../../../../../clientes/pages/clientes/types/types'
import { TipoDepreciacion } from '../../../../../../../componentes/tiposDepreciacionLookUp/types/types'
import { ToastTypes } from '../../../../../../../../store/types'
import ModalCuentas from '../../../../../../../contabilidad/pages/planCuentas/modalCuentas'
import { DateUtils } from '../../../../../../../../helpers/dateUtils'

export const optionsDepreciacion = [
  { value: 1, label: 'Depreciación' },
  { value: 2, label: 'Amortización' },
  { value: 3, label: 'Disponible a la venta' },
]

const Activos = (props) => {
  const { tabId } = props

  const dispatch = useDispatch()
  const setToastMessage = useSetToast()

  const activesDataToEdit = useSelector((state: RootState) => {
    return state.inventarios.items.editData[tabId].activos
  })
  const typeItem = useSelector((state: RootState) => {
    return state.inventarios.items.editData[tabId].generales.tipoItem
  })

  const [showModalCharac, setShowModalCharac] = useState<boolean>(false)
  const [showModalCtaDep, setShowModalCtaDep] = useState<boolean>(false)
  const [showModalCtaDepAcu, setShowModalCtaDepAcu] = useState<boolean>(false)
  const [rowIndex, setRowIndex] = React.useState<number>(-1)

  const onChangedFromActives = React.useCallback(
    (event: any, fieldToUpdate: string) => {
      if (event.event)
        dispatch(
          setDataActivesToEdit({
            key: tabId,
            actives: {
              ...activesDataToEdit,
              [fieldToUpdate]: event.value,
            },
          }),
        )
    },
    [dispatch, tabId, activesDataToEdit],
  )

  const onChangedCondition = React.useCallback(
    (condition: CondicionActivo) => {
      dispatch(
        setConditionActive({
          key: tabId,
          condition: condition,
        }),
      )
    },
    [dispatch, tabId],
  )

  const onChangedMethod = React.useCallback(
    (method: TOptionGenericoString) => {
      dispatch(
        setDepreciationMethod({
          key: tabId,
          method: method,
        }),
      )
    },
    [dispatch, tabId],
  )

  const onChangedMethodInf = React.useCallback(
    (methodInf: TOptionGenericoString) => {
      dispatch(
        setDepreciationMethodInf({
          key: tabId,
          methodInf: methodInf,
        }),
      )
    },
    [dispatch, tabId],
  )

  const onChangedType = React.useCallback(
    (type: TipoDepreciacion) => {
      dispatch(
        setDepreciationType({
          key: tabId,
          type: type,
        }),
      )
    },
    [dispatch, tabId],
  )

  const onAddCharactersitic = React.useCallback(() => {
    if (
      activesDataToEdit.valor > 0 &&
      activesDataToEdit.caracteristica?.codigo > 0
    ) {
      const indexCharacteristic = activesDataToEdit.dataGrid.findIndex(
        (characteristic: Characteristic) => {
          return (
            characteristic.codigo === activesDataToEdit.caracteristica.codigo
          )
        },
      )
      if (indexCharacteristic === -1) {
        const newItemsDataGrid: Array<Characteristic> = structuredClone(
          activesDataToEdit.dataGrid,
        )
        newItemsDataGrid.push({
          ...activesDataToEdit.caracteristica,
          valor: activesDataToEdit.valor,
        })
        dispatch(
          setDataActivesToEdit({
            key: tabId,
            actives: {
              ...activesDataToEdit,
              dataGrid: newItemsDataGrid,
              caracteristica: null,
              valor: 0,
            },
          }),
        )
      } else
        setToastMessage(
          'Agregar Característica',
          'La característicaya se encuentra registrada',
          ToastTypes.Warning,
        )
    } else {
      let errorMessage: string = 'El(los) campo(s): '
      if (!activesDataToEdit.caracteristica)
        errorMessage = errorMessage.concat('Característica, ')
      if (activesDataToEdit.valor <= 0)
        errorMessage = errorMessage.concat('Valor')
      errorMessage = errorMessage.concat(' es (son) olbigatorios')
      setToastMessage(
        'Agregar Característica',
        errorMessage,
        ToastTypes.Warning,
      )
    }
  }, [activesDataToEdit, setToastMessage, dispatch, tabId])

  const onRemoveCharactersitic = React.useCallback(() => {
    const newCharacteristic = structuredClone(activesDataToEdit.dataGrid)
    newCharacteristic.splice(rowIndex, 1)
    onChangedFromActives({ event: true, value: newCharacteristic }, 'dataGrid')
    setRowIndex(-1)
  }, [activesDataToEdit.dataGrid, onChangedFromActives, rowIndex])

  const closeModal = React.useCallback(() => {
    if (showModalCtaDep) setShowModalCtaDep(false)
    else setShowModalCtaDepAcu(false)
  }, [showModalCtaDep])

  const checkShowModal = () => {
    if (showModalCtaDep || showModalCtaDepAcu) return true
    return false
  }

  const setLedgerAccount = React.useCallback(
    (account: any) => {
      if (account) {
        let valueToUpdate: string = 'depreciacionAcumulada'
        const newAccount: LedgerAccount = {
          codigo: 0,
          tipo: 'ITEM',
          planCuentasCodigo: account.codigo ?? 0,
          planCuentasNumero: account.numero ?? '',
          planCuentasDescripcion: account.descripcion ?? '',
          transaccion:
            showModalCtaDep === true ? 'DEPRECIACION' : 'DEPACUMULADA',
        }
        if (showModalCtaDep) valueToUpdate = 'depreciacion'
        onChangedFromActives({ event: true, value: newAccount }, valueToUpdate)
      }
      closeModal()
    },
    [closeModal, onChangedFromActives, showModalCtaDep],
  )

  const onClearAccountingAccounts = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setDataActivesToEdit({
            key: tabId,
            actives: {
              ...activesDataToEdit,
              tipo: event.value,
              depreciacion: null,
              depreciacionAcumulada: null,
            },
          }),
        )
    },
    [dispatch, tabId, activesDataToEdit],
  )

  const validateActiveCondition = React.useCallback(
    (selected: any) => {
      if (selected?.value?.codigo == 0 && typeItem.value === 7)
        return Promise.reject('El campo Condición del activo es obligatorio')
      else return Promise.resolve()
    },
    [typeItem.value],
  )

  const validatedepretiationType = React.useCallback(
    (selected: any) => {
      if (
        selected?.value?.codigo == 0 &&
        activesDataToEdit.tipo.value === 1 &&
        typeItem.value === 7
      )
        return Promise.reject('El campo Tipo de Depreciación es obligatorio')
      else return Promise.resolve()
    },
    [activesDataToEdit.tipo.value, typeItem.value],
  )

  return (
    <>
      {checkShowModal() && (
        <ModalCuentas
          show={checkShowModal()}
          onClose={() => closeModal()}
          cargarCuentas={(account) => setLedgerAccount(account)}
        />
      )}
      {showModalCharac && (
        <ModalCaracteristicas
          onSelectCaracteristic={(characteristic: Characteristic) => {
            onChangedFromActives(
              {
                event: true,
                value: characteristic,
              },
              'caracteristica',
            )
            setShowModalCharac(false)
          }}
          onCancel={() => setShowModalCharac(false)}
          show={showModalCharac}
        />
      )}
      <RowContainer className="m-2">
        <CustomCol xs="12" md="6">
          <div
            style={{
              display: 'flex',
              gap: '2px',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <RadioGroup
              name="idTypesHierarchy"
              dataSource={optionsDepreciacion}
              value={activesDataToEdit.tipo}
              layout="horizontal"
              displayExpr="label"
              onValueChanged={(evt) => onClearAccountingAccounts(evt)}
              width="100%"
            />
            <DateBox
              displayFormat="dd/MM/yyyy"
              value={activesDataToEdit.fecha}
              onValueChanged={(evt) => onChangedFromActives(evt, 'fecha')}
            />
          </div>
          <Labeled label="Característica *:">
            <div
              style={{
                display: 'flex',
                gap: '2px',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <TextBox
                value={activesDataToEdit.caracteristica?.nombre ?? ''}
                readOnly={true}
                showClearButton={true}
                width="100%"
                inputAttr={{ autocomplete: 'nope' }}
              />
              <Button
                id="btnAdd"
                className="me-1"
                icon="more"
                stylingMode="contained"
                type="default"
                onClick={() => setShowModalCharac(true)}
              />
            </div>
          </Labeled>
          <Labeled label="Valor:">
            <div
              style={{
                display: 'flex',
                gap: '2px',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <NumberBox
                value={activesDataToEdit.valor}
                onValueChanged={(evt) => onChangedFromActives(evt, 'valor')}
                showClearButton={true}
                width="100%"
                inputAttr={{ autocomplete: 'nope' }}
                min={0}
              />
              <Button
                id="btnAdd"
                className="me-1"
                icon="add"
                stylingMode="contained"
                type="default"
                onClick={() => onAddCharactersitic()}
                hint="Agregar Característica"
              />
              <Button
                id="btnRemove"
                icon="trash"
                stylingMode="contained"
                type="danger"
                onClick={() => onRemoveCharactersitic()}
                disabled={rowIndex < 0}
                hint="Eliminar Característica"
              />
            </div>
          </Labeled>
          <DataGrid
            className="m-2"
            selection={{ mode: 'single' }}
            hoverStateEnabled={true}
            dataSource={activesDataToEdit.dataGrid ?? []}
            loadPanel={{ enabled: true }}
            showBorders={true}
            showRowLines={true}
            onRowClick={({ rowIndex }) => setRowIndex(rowIndex)}
          >
            <Column
              dataType="string"
              dataField="nombre"
              caption="Catacterística"
              width="50%"
            />
            <Column
              dataType="string"
              dataField="valor"
              caption="Valor"
              width="50%"
            />
          </DataGrid>
          <Labeled label="Depreciación:">
            <div
              style={{
                display: 'flex',
                gap: '2px',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <TextBox
                value={
                  activesDataToEdit.depreciacion?.planCuentasDescripcion ?? ''
                }
                readOnly={true}
                showClearButton={true}
                width="100%"
                inputAttr={{ autocomplete: 'nope' }}
              />
              <Button
                id="btnAdd"
                className="me-1"
                icon="more"
                stylingMode="contained"
                type="default"
                onClick={() => setShowModalCtaDep(true)}
              />
              <Button
                id="btnRemove"
                icon="trash"
                stylingMode="contained"
                type="danger"
                onClick={() =>
                  onChangedFromActives(
                    { event: true, value: null },
                    'depreciacion',
                  )
                }
              />
            </div>
          </Labeled>
          <Labeled label="Depreciación acumulada:">
            <div
              style={{
                display: 'flex',
                gap: '2px',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <TextBox
                value={
                  activesDataToEdit.depreciacionAcumulada
                    ?.planCuentasDescripcion ?? ''
                }
                readOnly={true}
                showClearButton={true}
                width="100%"
                inputAttr={{ autocomplete: 'nope' }}
              />
              <Button
                id="btnAdd"
                className="me-1"
                icon="more"
                stylingMode="contained"
                type="default"
                onClick={() => setShowModalCtaDepAcu(true)}
              />
              <Button
                id="btnRemove"
                icon="trash"
                stylingMode="contained"
                type="danger"
                onClick={() =>
                  onChangedFromActives(
                    { event: true, value: null },
                    'depreciacionAcumulada',
                  )
                }
              />
            </div>
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="3">
          <Labeled label="Condición del activo *:">
            <CondicionActivoLookUp
              selected={activesDataToEdit.condicion}
              onChanged={onChangedCondition}
              onChangedOptions={() => {}}
            >
              <Validator>
                <AsyncRule validationCallback={validateActiveCondition} />
                <RequiredRule
                  message={'El campo Condición del activo es obligatorio'}
                />
              </Validator>
            </CondicionActivoLookUp>
          </Labeled>
          {activesDataToEdit.tipo.value === 2 ? (
            <CRow>
              <Col>
                <Labeled label="Años:">
                  <NumberBox
                    value={activesDataToEdit.anios}
                    onValueChanged={(evt) => onChangedFromActives(evt, 'anios')}
                    showClearButton={true}
                    min={0}
                  />
                </Labeled>
              </Col>
              <Col>
                <Labeled label="Coef:">
                  <NumberBox
                    value={activesDataToEdit.coef}
                    onValueChanged={(evt) => onChangedFromActives(evt, 'coef')}
                    showClearButton={true}
                    min={0}
                  />
                </Labeled>
              </Col>
            </CRow>
          ) : (
            <>
              <Labeled label="Método Depreciación:">
                <MetodosTiposDepreciacionesLookUp
                  selected={activesDataToEdit.metodoDep}
                  onChanged={onChangedMethod}
                  onChangedOptions={() => {}}
                />
              </Labeled>
              <Labeled label="Método Depreciación INF:">
                <MetodosTiposDepreciacionesLookUp
                  selected={activesDataToEdit.metodoDepInf}
                  onChanged={onChangedMethodInf}
                  onChangedOptions={() => {}}
                />
              </Labeled>
              <Labeled label="Tipo de Depreciación *:">
                <TiposDepreciacionLookUp
                  selected={activesDataToEdit.tipoDep}
                  onChanged={onChangedType}
                  onChangedOptions={() => {}}
                >
                  <Validator>
                    <AsyncRule validationCallback={validatedepretiationType} />
                    <RequiredRule
                      message={'El campo Tipo de Depreciación es obligatorio'}
                    />
                  </Validator>
                </TiposDepreciacionLookUp>
              </Labeled>
              <CRow>
                <Col>
                  <Labeled label="Vida Útil:">
                    <NumberBox
                      value={activesDataToEdit.vidaUtil}
                      onValueChanged={(evt) =>
                        onChangedFromActives(evt, 'vidaUtil')
                      }
                      readOnly={true}
                    />
                  </Labeled>
                </Col>
                <Col>
                  <Labeled label="Vida Útil INF:">
                    <NumberBox
                      value={activesDataToEdit.vidaUtilInf}
                      onValueChanged={(evt) =>
                        onChangedFromActives(evt, 'vidaUtilInf')
                      }
                      readOnly={true}
                    />
                  </Labeled>
                </Col>
              </CRow>
              <CRow>
                <Col>
                  <Labeled label="Valor residual:">
                    <NumberBox
                      value={activesDataToEdit.valorResidual}
                      onValueChanged={(evt) =>
                        onChangedFromActives(evt, 'valorResidual')
                      }
                      showClearButton={true}
                      min={0}
                    />
                  </Labeled>
                </Col>
                <Col>
                  <Labeled label="Factor:">
                    <NumberBox
                      value={activesDataToEdit.factor}
                      onValueChanged={(evt) =>
                        onChangedFromActives(evt, 'factor')
                      }
                      showClearButton={true}
                      min={0}
                    />
                  </Labeled>
                </Col>
              </CRow>
            </>
          )}
        </CustomCol>
      </RowContainer>
    </>
  )
}

export default Activos

export const defaultActivos: ItemActives = {
  tipo: optionsDepreciacion[0],
  fecha: DateUtils.getCurrentDateAsString(),
  caracteristica: null,
  valor: 0,
  dataGrid: [],
  depreciacion: null,
  depreciacionAcumulada: null,
  condicion: null,
  anios: 0,
  coef: 0,
  metodoDep: null,
  metodoDepInf: null,
  tipoDep: null,
  vidaUtil: 0,
  vidaUtilInf: 0,
  valorResidual: 0,
  factor: 2,
}
