import { CButton, CCard, CCardBody, CCardHeader } from '@coreui/react-pro'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LoadPanel from 'devextreme-react/load-panel'
import Buscar from './components/buscar'
import { RootState } from '../../../../../../store/store'
import Modalform from '../../../../../../views/componentes/modalform'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../views/componentes/colContainer'
import { GlobalMenu } from '../../../../../../views/componentes/globalMenu/globalMenu'
import config from '../../../../../../config/config'
import { AccionMenu, ToastTypes } from '../../../../../../store/types'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import { addToast } from '../../../../../../store/toasterReducer'
import { changeLoader } from '../../../../../../store/reducers'
import Nuevo from './components/nuevo'
import { unitsServices } from './services/modalUnidades.services'
import { UnidadInventario } from '../../../../store/types'

interface IModalUnidadesProps extends React.PropsWithChildren {
  // eslint-disable-next-line no-unused-vars
  onSelectUnit: (selectedUnit: UnidadInventario) => void
  onCancel: () => void
  show: boolean
}

export const ModalUnidades: React.FC<IModalUnidadesProps> = (props) => {
  const dispatch = useDispatch()
  const { show, onCancel, onSelectUnit } = props
  const loader = useSelector((state: RootState) => state.global.loader)

  const botones = config['menuDefecto'].map((acc) => {
    const boton: AccionMenu = {
      nombre: acc.id,
      icon: acc.icon,
      actionType: acc.type,
      main: false,
      modal: false,
      shortcut: null,
    }
    return boton
  })

  const [actionsMenu] = React.useState<Array<AccionMenu>>(botones)
  const [selectedItem, setSelectedItem] = React.useState<any>({
    index: null,
    row: null,
    selectedRow: null,
  })
  const [messageConfirm, setMessageConfirm] = React.useState(
    'Está seguro que desea realizar estos cambios?',
  )
  const [currentAction, setCurrentAction] = React.useState<any>('Buscar')
  const [showConfirm, setShowConfirm] = React.useState(false)
  const [confirmUpdate, setConfirmUpdate] = React.useState(false)
  const [confirmChange, setConfirmChange] = React.useState<boolean>(false)
  const [deleteItem, setDeleteItem] = React.useState<any>(null)
  const [confirmDelete, setConfirmDelete] = React.useState(false)
  const [clickedButton, setclickedButton] = React.useState<string>('')

  const resetSearched = React.useCallback(() => {
    setSelectedItem({
      index: null,
      row: null,
      selectedRow: null,
    })
  }, [])

  const setToastMessage = React.useCallback(
    (message: string, type: ToastTypes) => {
      dispatch(
        addToast({
          id: '',
          autoHide: 3500,
          title: 'Unidades Activas',
          content: message,
          fade: true,
          type: type,
        }),
      )
    },
    [dispatch],
  )

  const setActionUpdate = ({ row }) => {
    setMessageConfirm(
      `Está seguro que desea actualizar el item: ${row.nombre} ?`,
    )
    setConfirmUpdate(true)
    setShowConfirm(true)
  }

  const setActionDelete = ({ row }) => {
    setShowConfirm(true)
    setConfirmDelete(true)
    setMessageConfirm(`Está seguro que desea eliminar el item: ${row.nombre} ?`)
  }

  const onHandleDelete = async () => {
    try {
      const {
        row: { codigo },
      } = selectedItem
      playLoader()
      const deletedUnit = await unitsServices.deleteUnit(codigo)
      stopLoader()
      if (!deletedUnit.error && deletedUnit.auto) {
        setclickedButton(ButtonTypes.delete)
        setDeleteItem(selectedItem['row'])
        setToastMessage(
          deletedUnit.message ?? 'Registro Eliminado',
          ToastTypes.Success,
        )
      } else
        setToastMessage(
          deletedUnit.message ?? 'Error al eliminar Unidad',
          ToastTypes.Warning,
        )
    } catch (error) {
      stopLoader()
      setToastMessage(error, ToastTypes.Danger)
    }
  }

  const playLoader = React.useCallback(async () => {
    dispatch(changeLoader({ show: true, mensaje: 'Cargando...' }))
  }, [dispatch])

  const stopLoader = React.useCallback(async () => {
    dispatch(changeLoader({ show: false, mensaje: '' }))
  }, [dispatch])

  const returnAction = React.useCallback(
    (boton: AccionMenu) => {
      switch (boton.actionType) {
        case ButtonTypes.find:
          if (currentAction == 'Nuevo') {
            resetSearched()
            setConfirmChange(true)
            setShowConfirm(true)
            setMessageConfirm(
              '¿Esta seguro que desea salir de este formulario?',
            )
            return false
          } else if (currentAction === 'Editar') {
            resetSearched()
            setConfirmChange(true)
            setShowConfirm(true)
            setMessageConfirm(
              '¿Esta seguro que desea salir de este formulario?',
            )
          } else {
            setCurrentAction('Buscar')
            setclickedButton(ButtonTypes.find)
          }
          break
        case ButtonTypes.new:
          setCurrentAction('Nuevo')
          setclickedButton(ButtonTypes.new)
          break
        case ButtonTypes.save:
          if (currentAction == 'Nuevo') {
            setclickedButton(ButtonTypes.save)
            return false
          } else if (currentAction == 'Editar') {
            setclickedButton(ButtonTypes.save)
            return false
          } else {
            setToastMessage(
              'No se encuentra cambios que realizar.',
              ToastTypes.Info,
            )
          }
          break
        case ButtonTypes.undo:
          if (currentAction == 'Nuevo') {
            setclickedButton(ButtonTypes.undo)
            return false
          } else if (currentAction == 'Editar') {
            setclickedButton(ButtonTypes.undo)
            return false
          } else {
            resetSearched()
          }
          break
        case ButtonTypes.edit:
          if (selectedItem['index'] === null) {
            setToastMessage(
              'No se encuentra un registro seleccionado, Por favor seleccione un Item para editarlo.',
              ToastTypes.Info,
            )
            return false
          } else {
            setActionUpdate(selectedItem)
            return false
          }
          break
        case ButtonTypes.delete:
          if (selectedItem.index !== null) {
            setActionDelete(selectedItem)
            return false
          } else {
            setToastMessage(
              'No se encuentra seleccionado un proveedor, por favor seleccione un proveedor para eliminarlo.',
              ToastTypes.Info,
            )
            return false
          }
          break
        default:
          break
      }
    },
    [currentAction, resetSearched, selectedItem, setToastMessage],
  )

  const getButtonDisabled = React.useCallback(
    (button: AccionMenu) => {
      switch (button.actionType) {
        case ButtonTypes.find:
        case ButtonTypes.undo:
          return false
        case ButtonTypes.new: {
          return currentAction !== 'Buscar' || selectedItem.index !== null
        }
        case ButtonTypes.delete:
        case ButtonTypes.edit: {
          return currentAction !== 'Buscar' || selectedItem.index === null
        }
        case ButtonTypes.save: {
          return currentAction === 'Buscar'
        }
        default:
          return true
      }
    },
    [currentAction, selectedItem],
  )

  const modalButtons = () => {
    return (
      <>
        <RowContainer>
          <CustomCol xs="12" md="4">
            <GlobalMenu
              acciones={actionsMenu}
              onClick={returnAction}
              getButtonDisabled={getButtonDisabled}
            />
          </CustomCol>
        </RowContainer>
      </>
    )
  }

  const renderBodyConfirm = () => {
    return <>{messageConfirm}</>
  }

  const renderFooter = () => {
    if (currentAction === 'Buscar') {
      return (
        <>
          {confirmDelete === true && (
            <CButton
              color="primary"
              onClick={() => {
                setShowConfirm(false)
                setConfirmDelete(false)
                onHandleDelete()
              }}
            >
              {'Aceptar'}
            </CButton>
          )}
          {confirmUpdate === true && (
            <CButton
              color="primary"
              onClick={() => {
                setConfirmUpdate(false)
                setShowConfirm(false)
                setclickedButton(ButtonTypes.edit)
                setCurrentAction('Editar')
              }}
            >
              {'Aceptar'}
            </CButton>
          )}
          <CButton
            color="secondary"
            onClick={() => {
              setConfirmUpdate(false)
              setConfirmDelete(false)
              setShowConfirm(!showConfirm)
            }}
          >
            {'Cancelar'}
          </CButton>
        </>
      )
    } else if (currentAction === 'Nuevo' || currentAction === 'Editar') {
      return (
        <>
          {confirmChange === true && (
            <CButton
              color="primary"
              onClick={() => {
                setConfirmUpdate(false)
                setShowConfirm(false)
                setConfirmChange(false)
                setMessageConfirm(
                  '¿Esta seguro que desea salir de este formulario?',
                )
                setCurrentAction('Buscar')
              }}
            >
              {'Aceptar'}
            </CButton>
          )}
          <CButton
            color="secondary"
            onClick={() => {
              setConfirmUpdate(false)
              setConfirmDelete(false)
              setShowConfirm(!showConfirm)
            }}
          >
            {'Cancelar'}
          </CButton>
        </>
      )
    }
  }

  const confirmAction = () => {
    return (
      <Modalform
        name="confirmarEliminarItem"
        headerTitle={'Confirmar'}
        childrenBody={renderBodyConfirm()}
        childrenFooter={renderFooter()}
        closeOnBackdrop={false}
        show={showConfirm}
        onClose={() => setShowConfirm(!showConfirm)}
        centered={true}
        size="sm"
      />
    )
  }

  const bodyModal = () => {
    return (
      <>
        {(currentAction === 'Nuevo' || currentAction === 'Editar') && (
          <Nuevo
            clickedButton={clickedButton}
            selectedItem={selectedItem}
            setCurrentAction={(action) => setCurrentAction(action)}
            currentTab={currentAction}
            resetAction={() => setclickedButton('')}
            resetSelected={() => resetSearched()}
            setToast={(sms, type) => setToastMessage(sms, type)}
            playLoader={playLoader}
            stopLoader={stopLoader}
          />
        )}
        {currentAction === 'Buscar' && (
          <Buscar
            clickedButton={clickedButton}
            resetAction={() => setclickedButton('')}
            onSelectItem={onSelectUnit}
            onSelectedItem={setSelectedItem}
            deletedItem={deleteItem}
            currentAction={currentAction}
            setCurrenAction={(action) => setCurrentAction(action)}
            resetSelected={() => resetSearched()}
            setToast={setToastMessage}
            playLoader={playLoader}
            stopLoader={stopLoader}
          />
        )}
      </>
    )
  }

  const renderContent = () => {
    return (
      <CCard>
        <CCardHeader>{modalButtons()}</CCardHeader>
        <CCardBody>
          <LoadPanel
            shadingColor="rgba(0,0,0,0.4)"
            position={'center'}
            visible={loader.show}
            showIndicator={true}
            shading={true}
            showPane={true}
            message={loader.mensaje}
          />
          {bodyModal()}
        </CCardBody>
      </CCard>
    )
  }

  return (
    <>
      {showConfirm === true && confirmAction()}
      <Modalform
        name="modalUnidadesActivas"
        headerTitle={'Lista de Unidades Activas'}
        childrenBody={renderContent()}
        closeOnBackdrop={false}
        show={show}
        onClose={() => onCancel()}
        centered={true}
        size="lg"
      />
    </>
  )
}
