import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DateUtils } from '../../../../../../../helpers/dateUtils'
import { RootState } from '../../../../../../../store/store'
import { DateRange, FETCH_STATUS } from '../../../../../../../store/types'
import { TLocal } from '../../../../../../shared/components/buscarLocalesLookMemoUp/type/types'
import { Cliente } from '../../../../../types/types'
import {
  FiltroBusquedaOrdenProformaState,
  OrdenProformaListado,
  SearchOrdenProformaState,
} from '../../../type/types'
import { ProformaService } from '../services/proforma.services'
import { ItemProforma } from '../type/types'

const initialState: SearchOrdenProformaState<OrdenProformaListado> = {
  filter: {
    fechaInicio: DateUtils.getCurrentDateAsString(),
    fechaFin: DateUtils.getCurrentDateAsString(),
    numero: '',
    cliente: null,
    local: null,
    item: null,
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  resultados: [],
}

export const fetchProformas = createAsyncThunk<
  Array<OrdenProformaListado>,
  FiltroBusquedaOrdenProformaState
>('compras/documentos/general/orders/getAll', async (filtro) => {
  try {
    const rangofechas: DateRange = {
      inicio: DateUtils.pickersDateToApiDate(filtro.fechaInicio),
      fin: DateUtils.pickersDateToApiDate(filtro.fechaFin),
    }
    const ords = await ProformaService.getOrdenes(
      rangofechas,
      filtro.numero ? filtro.numero : '',
      filtro,
    )
    return ords
  } catch (error) {
    return Promise.reject(error)
  }
})

const searchProformaSlice = createSlice({
  name: 'searchOrdenProforma',
  initialState: initialState,
  reducers: {
    resetState(state) {
      state = {
        ...initialState,
        filter: {
          ...initialState.filter,
          fechaInicio: DateUtils.getCurrentDateAsString(),
          fechaFin: DateUtils.getCurrentDateAsString(),
        },
      }
    },
    setCustomer(state, action: PayloadAction<Cliente | null>) {
      state.filter.cliente = action.payload
    },
    setLocal(state, action: PayloadAction<TLocal | null>) {
      state.filter.local = action.payload
    },
    changeFilter(
      state,
      action: PayloadAction<FiltroBusquedaOrdenProformaState>,
    ) {
      state.filter = action.payload
    },
    setSearchStatus(state, action: PayloadAction<FETCH_STATUS>) {
      state.status = action.payload
    },
    setCleanResult(state) {
      state.resultados = []
    },
    setDateStart(state, action: PayloadAction<string>) {
      state.filter.fechaInicio = action.payload
    },
    setDateEnd(state, action: PayloadAction<string>) {
      state.filter.fechaFin = action.payload
    },
    setItem(state, action: PayloadAction<ItemProforma | null>) {
      state.filter.item = action.payload
    },
    setResultFilter(state, action: PayloadAction<any>) {
      state.resultados = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProformas.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchProformas.fulfilled, (state, { payload }) => {
      state.resultados = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchProformas.rejected, (state, { payload }) => {
      state.resultados = []
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
  },
})

export const selectFiltroBusquedaOrdenProforma = (state: RootState) => {
  return state.ventas.proformas.ordenProforma.search.filter
}
export const selectResultadosBusquedaOrdenProforma = (state: RootState) => {
  return state.ventas.proformas.ordenProforma.search.resultados
}
export const selectBusquedaOrdenProformaEstado = (state: RootState) => {
  return state.ventas.proformas.ordenProforma.search.status
}
export const {
  setDateEnd,
  setDateStart,
  setCleanResult,
  changeFilter,
  setSearchStatus,
  resetState,
  setResultFilter,
  setCustomer,
  setLocal,
  setItem,
} = searchProformaSlice.actions
export const searchProformaReducer = searchProformaSlice.reducer
