import React from 'react'
import { DocumentosDatosEdicion, DocumentosListado } from '../../types/types'
import { TabState } from '../../../../../../store/genericTabTypes'
import { CustomButtons, DocumentInfo } from '../../../../../../store/types'

interface IDocumentosCuentasPorPagarProps {
  info: DocumentInfo<DocumentosListado>
  tabId: string
  tab: TabState<DocumentosListado>
  templateMode?: boolean
}

const DocumentosCuentasPorPagar: React.FunctionComponent<IDocumentosCuentasPorPagarProps> = (props) => {

  return (
    <>
      nuevo tab
    </>
  );
}

export const defaultDatosEdicionDocumentosCtasPorPagar: DocumentosDatosEdicion = {
  imprimir: false,
  loader: {
    mensaje: 'Cargando...',
    show: false,
  },
  tieneError: false,
  mensajeError: '',
  loading: true,
  desdePlantilla: false,
  codigo: null,
}

export const ModuleButtons: CustomButtons = {
  Nuevo: true,
  Guardar: true,
  Buscar: true,
  Deshacer: true,
  Editar: true,
  Imprimir: true,
  Importar: true,
  Abonar: true,
  Saldos: true,
  Anticipos: true,
  Cartera: true,
  Cartera_vencida: true
}

export default React.memo(DocumentosCuentasPorPagar)
