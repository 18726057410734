/* eslint-disable prefer-const */
import config from '../../../../../../../config/config'
import { RequestHelper } from '../../../../../../../helpers/requestHelper'
import { SesionService } from '../../../../../../../services/sesion.service'
import {
  DateRange,
  PagedResult,
  PaginationInfo,
} from '../../../../../../../store/types'
import { Cliente } from '../../../../../types/types'
import { ItemDetalleProforma, OrdenProformaListado } from '../../../type/types'
import { ItemProforma, TOrdenProformaIngresar } from '../type/types'
import CustomStore from 'devextreme/data/custom_store'
import DataSource from 'devextreme/data/data_source'
import {
  DateUtils,
  formatoFechasApi,
} from '../../../../../../../helpers/dateUtils'

export const ProformaService = {
  getOrdenes,
  getSecuencial,
  buscarItemPedido,
  getItemPorCodigoBarrasPedido,
  ingresarOrden,
  getOrden,
  getOrdenDetalle,
  deletePedido,
  uploadFiles,
  getVendedorByCode,
  getItemsDatasource,
}

async function ingresarOrden(
  orden: TOrdenProformaIngresar,
): Promise<Promise<any>> {
  try {
    const infoRegistro = {
      infoRegistro: orden,
    }
    const creada = await RequestHelper.postAll<any>(
      'ordenes',
      'save',
      infoRegistro,
    )
    return creada
  } catch (error) {
    return error
  }
}

async function getOrdenes(
  rangoFechas: DateRange,
  cod: string = '',
  filtro: any,
) {
  const queryData: any = {}
  if (rangoFechas.inicio) {
    queryData.fechaDesde = rangoFechas.inicio
  }
  if (rangoFechas.fin) {
    queryData.fechaHasta = rangoFechas.fin
  }
  if (filtro.item) {
    queryData.item = filtro.item.codigoBarras ?? 0
  }
  queryData.tipo = 'PRO'
  queryData.estado = ''
  queryData.salonCodigo = ''

  if (filtro.numero) {
    queryData.ordNumero = filtro.numero
  }

  if (filtro?.cliente && filtro?.cliente?.codigo) {
    queryData.idCliente = filtro?.cliente?.identificacion
  }

  if (filtro?.local && filtro?.local?.codigo) {
    queryData.localCodigo = filtro?.local?.codigo
  }

  const apiData = await RequestHelper.get<any>(
    'compras/documentos/general',
    'orders/getAll',
    '',
    queryData,
  )
  const orden: Array<OrdenProformaListado> = []
  try {
    for (const ite of apiData) {
      orden.push({
        codigo: Number(ite?.codigo) ?? 0,
        anio: String(ite?.anio) ?? '',
        numero: String(ite?.numero) ?? '',
        fechaEntrega: String(ite?.fechaEntrega) ?? '',
        horaEntrega: String(ite?.fechaEntrega) ?? '',
        clienteNombre: String(ite?.clienteNombre) ?? '',
        clienteIdentificacion: String(ite?.clienteIdentificacion) ?? '',
        clienteEmail: String(ite?.clienteEmail) ?? '',
        clienteTelefono:
          ite?.clienteTelefono !== null
            ? String(ite?.clienteTelefono) ?? ''
            : '',
        garantia: parseFloat(ite?.garantia) ?? 0,
        abono: parseFloat(ite?.abono) ?? 0,
        saldo: parseFloat(ite?.saldo) ?? 0,
        total: parseFloat(ite?.total) ?? 0,
        porciones: parseFloat(ite?.porciones) ?? 0,
        observaciones: ite?.observaciones ?? '',
        imagenReferencia: ite?.imgAlternativa ?? '',
        imagenDecoracion: ite?.imgReferencia ?? '',
        vendedor: ite?.vendedorData ?? null,
        localCodigo: Number(ite?.localCodigo) ?? 0,
        localNombre: String(ite?.localNombre) ?? '',
        localColor: String(ite?.localColor) ?? '',
        ordenDetalle:
          ite.detalleOrden.map(function (key: ItemDetalleProforma) {
            return {
              codigoInterno: String(key?.codigoInterno) ?? '',
              itemBarras: String(key?.itemBarras) ?? '',
              itemCodigo: Number(key?.itemCodigo) ?? 0,
              itemDescripcion: String(key?.itemDescripcion) ?? '',
              itemImagen: String(key?.itemImagen) ?? '',
              itemUnidadCompraDescripcion:
                String(key?.itemUnidadCompraDescripcion) ?? '',
              ordenCodigo: Number(key?.ordenCodigo) ?? 0,
              ordenDetalleCantidad: Number(key?.ordenDetalleCantidad) ?? 0,
              ordenDetalleCodigo: Number(key?.ordenDetalleCodigo) ?? 0,
              ordenDetalleDescuento: Number(key?.ordenDetalleDescuento) ?? 0,
              ordenDetalleImpuesto: Number(key?.ordenDetalleImpuesto) ?? 0,
              ordenDetallePorcentaje: Number(key?.ordenDetallePorcentaje) ?? 0,
              ordenDetallePrecio: Number(key?.ordenDetallePrecio) ?? 0,
              ordenDetallePrecioIVA: Number(key?.ordenDetallePrecioIVA) ?? 0,
              ordenDetalleValor: Number(key?.ordenDetalleValor) ?? 0,
            }
          }) ?? [],
        fecha: ite?.fecha,
        documentoGenerar: ite?.documentoGenerar,
      })
    }
  } catch (error) {
    console.error(error)
  }

  return orden
}

async function getSecuencial(tipo: string): Promise<any> {
  const data = {
    ordenTipo: tipo,
  }
  const apiDetalles = await RequestHelper.get<any>(
    'compras/documentos',
    'ordenes/getLastNumber',
    '',
    data,
  )
  return apiDetalles
}

async function getItemPorCodigoBarrasPedido(
  codigoBarras: string,
  identificacionCliente: string,
  fecha: string,
): Promise<Array<ItemProforma>> {
  const sesion = SesionService.getCurrentSesion()
  const query: any = {
    local: sesion.local.codigo,
    identificacion: identificacionCliente,
    barras: codigoBarras,
    fecha: fecha,
    exacta: true,
  }
  const itemsApi = await RequestHelper.get<Array<any>>(
    'inventario',
    'items/searchItemsLocal',
    '',
    query,
  )
  const items = parseApiItemsData(itemsApi)
  return items
}

async function buscarItemPedido(
  descripcion: string,
  codigoBarras: string,
  identificacionCliente: string,
  fecha: string,
): Promise<Array<ItemProforma>> {
  const sesion = SesionService.getCurrentSesion()
  const sesionAutorizacionModulo = SesionService.getCurrentModulosAutorizacion()
  const query: any = {
    local: sesion.local.codigo,
    identificacion: identificacionCliente,
    descripcion: descripcion,
    barras: codigoBarras,
    fecha: fecha,
  }
  if (sesionAutorizacionModulo && sesionAutorizacionModulo.establecimiento) {
    query.establecimiento = sesionAutorizacionModulo.establecimiento
  }
  const itemsApi = await RequestHelper.get<Array<any>>(
    'inventario',
    'items/searchItemsLocal',
    '',
    query,
  )
  const items = parseApiItemsData(itemsApi)
  return items
}

function parseApiItemsData(apiresult: Array<any>): Array<ItemProforma> {
  const items = apiresult.map((x) => {
    const item: ItemProforma = {
      codigo: parseInt(x.codigo),
      codigoInterno: x.codigoint,
      codigoBarras: x.barras,
      descripcion: x.descripcion,
      existencia: parseFloat(x.existencia),
      pvdiva: parseFloat(x.pvdiva),
      pvpiva: parseFloat(x.pvpiva),
      pvd: parseFloat(x.pvd),
      pvp: parseFloat(x.pvp),
      codigoImpuesto: parseInt(x.impuesto),
      tieneIce: x.ice === '-1',
      porcentajeIva: parseFloat(x.porcentajeIva),
      costo: parseFloat(x.costo),
      tipoCodigo: parseInt(x.tipo),
      numeroSerie: parseInt(x.numserie),
      plantilla: parseInt(x.plantilla),
      freeitem: parseInt(x.freeitem),
      gradoAcoholico: parseInt(x.galcoholico),
      capacidad: parseFloat(x.capacidad),
      factor: parseFloat(x.factor),
      jerarquia: x.jerarquia,
      descuentos: parseFloat(x.descuentos),
      titadicional: x.titadicional,
      valadicional: x.valadicional,
      codigoGrupo: parseInt(x.codgrupo),
      grupo: x.grupo,
      codigoUnidadVenta: parseInt(x.unidadv),
      tipoDescripcion: x.tipo_descripcion,
    }
    return item
  })
  return items
}

async function getOrden(cod: number) {
  const queryData: any = {}
  queryData.ordenTipo = 'PROD'
  queryData.ordenCodigo = cod

  const orden = await RequestHelper.get<any>(
    'compras/documentos',
    'ordenes/get',
    '',
    queryData,
  )

  return orden
}

async function getOrdenDetalle(cod: number) {
  const queryData: any = {}
  queryData.orden = cod

  const orden = await RequestHelper.get<any>(
    'generales/orders',
    'getDetail',
    '',
    queryData,
  )

  return orden
}

async function deletePedido(idPedido: any): Promise<any> {
  try {
    const objRequest = {
      ordenCodigo: idPedido,
    }
    const data = await RequestHelper.deleteRequestAll<any>(
      'compras/documentos',
      'ordenes/inactive',
      '',
      objRequest,
    )
    return data
  } catch (error) {
    return error
  }
}

async function uploadFiles(objeto: any): Promise<boolean> {
  try {
    const data = await RequestHelper.postData<boolean>(
      'compras/documentos',
      'ordenes/uploadFile',
      objeto,
    )
    return data
  } catch (error) {
    const smsErr: any = error
    return smsErr
  }
}

async function getVendedorByCode(findSeller: { cod?: number; id?: string }) {
  try {
    const queryData: any = {}
    if (findSeller.cod) {
      queryData.codigo = findSeller.cod
    }
    if (findSeller.id) {
      queryData.identificacion = findSeller.id
    }
    const vendedorApi = await RequestHelper.get<any>(
      'ventas/ventas',
      'sellers/getByIdOrCode',
      '',
      queryData,
    )

    return vendedorApi
  } catch (error) {
    return error
  }
}

async function buscarItemsPaged(
  descripcion: string,
  codigoBarras: string,
  identificacionCliente: string,
  fecha: string,
  pagination: PaginationInfo,
): Promise<PagedResult<ItemProforma>> {
  const sesion = SesionService.getCurrentSesion()
  const query: any = {
    local: sesion.local.codigo,
    identificacion: identificacionCliente,
    descripcion: descripcion,
    barras: codigoBarras,
    fecha: fecha,
    isPaged: true,
    page: pagination.current,
    limit: pagination.size,
    establecimiento: '',
  }
  const pagedApiResult = await RequestHelper.get<any>(
    'inventario',
    'items/searchItemsLocal',
    '',
    query,
  )
  const itemsParsed = parseApiItemsData(pagedApiResult.auto)
  return {
    currentPage: parseInt(pagedApiResult.paginaActual),
    from: parseInt(pagedApiResult.desde),
    to: parseInt(pagedApiResult.hasta),
    count: parseInt(pagedApiResult.totalRegistros),
    result: itemsParsed,
  }
}

function getItemsDatasource(
  cliente: Cliente | null,
  fecha: string = DateUtils.getCurrentDateAsString(formatoFechasApi),
) {
  const store = new CustomStore({
    key: 'codigoBarras',
    byKey: async (key) => {
      const item = await getItemPorCodigoBarrasPedido(
        key,
        cliente?.identificacion ?? config.rucConsumidorFinal,
        fecha,
      )

      return item.length > 0 ? item[0] : undefined
      //console.log(key);
    },
    load: async (loadOptions) => {
      const paginInfo: PaginationInfo = {
        current: (loadOptions.skip ?? 0) / (loadOptions.take ?? 0) + 1,
        size: loadOptions.take ?? 10,
      }
      let pagedResult: PagedResult<ItemProforma>
      pagedResult = await buscarItemsPaged(
        loadOptions.searchValue ?? '',
        '',
        cliente?.identificacion ?? config.rucConsumidorFinal,
        fecha,
        paginInfo,
      )

      return {
        data: pagedResult.result,
        totalCount: pagedResult.count,
      }
    },
  })
  const source = new DataSource({
    paginate: true,
    pageSize: 10,
    store: store,
    key: 'codigoBarras',
  })
  return source
}
