import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoaderInfo } from "../../../../../store/types";
import { CustomDictionary } from "../../../../ventas/types/generics";
import { ventasClearDatosEdicion } from "../../../../ventas/store/reducers";
import {
  CompraInfo,
  FormaPagoModuloCompras,
  ComprasDatosEdicion,
  SustentosTributarios,
  TiposCompras,
  TTotalesFacturaC,
} from "../../../types/types";
import { DetalleCompraRedux } from "../types/detalleCompra";
import { ProveedorBusqueda } from "../../../../proveedores/store/types";
import { Proyecto } from "../../../../bancos/pages/movimientosCajas/ingresosCaja/store/types";
import { LocalOption } from "../../../../ventas/pages/cuadres/types/types";
import { CuotaCreditoVenta } from "../../../../ventas/types/types";

const initialState: CustomDictionary<ComprasDatosEdicion> = {};

const datosEdicionCompraSlice = createSlice({
  name: "comprasDatosEdicion",
  initialState: initialState,
  reducers: {
    ventasClearDatosEdicion,
    setFechaEmicion(
      state,
      action: PayloadAction<{ key: number | string; fecha: string }>
    ) {
      state[action.payload.key].fechaEmision = action.payload.fecha;
    },
    setFechaPropuesta(
      state,
      action: PayloadAction<{ key: number | string; fecha: string }>
    ) {
      state[action.payload.key].fechaPropuesta = action.payload.fecha;
    },
    setComprobanteElectronico(
      state,
      action: PayloadAction<{ key: number | string; comprobanteElectronico: boolean }>
    ) {
      state[action.payload.key].comprobanteElectronico = action.payload.comprobanteElectronico;
    },
    setNumero(
      state,
      action: PayloadAction<{ key: number | string; numero: string }>
    ) {
      state[action.payload.key].numero = action.payload.numero;
    },
    setEstablecimiento(
      state,
      action: PayloadAction<{ key: number | string; establecimiento: string }>
    ) {
      state[action.payload.key].establecimiento = action.payload.establecimiento;
    },
    setPuntoEmision(
      state,
      action: PayloadAction<{ key: number | string; puntoEmision: string }>
    ) {
      state[action.payload.key].puntoEmision = action.payload.puntoEmision;
    },
    setAutorizacion(
      state,
      action: PayloadAction<{ key: number | string; autorizacion: string }>
    ) {
      state[action.payload.key].autorizacion = action.payload.autorizacion;
    },
    setFormaPago(state, action: PayloadAction<{ key: number | string, formaPago: FormaPagoModuloCompras }>) {
      const { formaPago, key } = action.payload;
      state[key].formaPago = formaPago;
    },
    setTipoCompra(state, action: PayloadAction<{ key: number | string, tipoCompra: TiposCompras }>) {
      const { tipoCompra, key } = action.payload;
      state[key].tipoCompra = tipoCompra;
    },
    setSustentosTributarios(state, action: PayloadAction<{ key: number | string, sustentosTributarios: Array<SustentosTributarios> }>) {
      const { sustentosTributarios, key } = action.payload;
      state[key].sustentosTributarios = sustentosTributarios;
    },
    setSustentoTributario(state, action: PayloadAction<{ key: number | string, sustentoTributario: SustentosTributarios }>) {
      const { sustentoTributario, key } = action.payload;
      state[key].sustentoTributario = sustentoTributario;
    },
    setProyectos(state, action: PayloadAction<{ key: number | string, proyectos: Array<Proyecto> }>) {
      const { proyectos, key } = action.payload;
      state[key].proyectos = proyectos;
    },
    setProyecto(state, action: PayloadAction<{ key: number | string, proyecto: Proyecto }>) {
      const { proyecto, key } = action.payload;
      state[key].proyecto = proyecto;
    },
    setLocales(state, action: PayloadAction<{ key: number | string, locales: Array<LocalOption> }>) {
      const { locales, key } = action.payload;
      state[key].locales = locales;
    },
    setLocal(state, action: PayloadAction<{ key: number | string, local: LocalOption }>) {
      const { local, key } = action.payload;
      state[key].local = local;
    },
    updateProveedor(
      state,
      action: PayloadAction<{
        key: number | string;
        proveedor: ProveedorBusqueda | null;
        formasPago: Array<FormaPagoModuloCompras>;
      }>
    ) {
      state[action.payload.key].proveedor = action.payload.proveedor;
      if (state[action.payload.key]) {
        const formaPago = action.payload.formasPago.find(
          (x) => x.codigo === state[action.payload.key].formaPago?.codigo
        );
        state[action.payload.key].formaPago = formaPago
          ? formaPago
          : action.payload.formasPago.filter((x) => x.nombre === "EFECTIVO")[0];
      } else {
        state[action.payload.key].formaPago = action.payload.formasPago.filter(
          (x) => x.nombre === "EFECTIVO"
        )[0];
      }
    },
    setDatosEdicion(
      state,
      action: PayloadAction<{
        key: number | string;
        data: ComprasDatosEdicion;
      }>
    ) {
      state[action.payload.key] = action.payload.data;
    },
    updateDetalles(state, action: PayloadAction<{ key: number | string, detalles: Array<DetalleCompraRedux> }>) {
      state[action.payload.key].detalles = action.payload.detalles;
    },

    updateTotales(state, action: PayloadAction<{ key: number | string, totales: TTotalesFacturaC }>) {
      state[action.payload.key].totales = action.payload.totales;
      state[action.payload.key].descuentoGlobal = action.payload.totales.descuentoGlobal;
    },
    setEditLoader(
      state,
      action: PayloadAction<{ key: number | string; info: LoaderInfo }>
    ) {
      state[action.payload.key].loader = action.payload.info;
    },
    setMuestraError(state, action: PayloadAction<{ key: number | string, tieneError: boolean, mensajeError: string }>) {
      state[action.payload.key].tieneError = action.payload.tieneError;
      state[action.payload.key].mensajeError = action.payload.mensajeError;
    },
    updateResultadoGuardarRetencion(
      state,
      action: PayloadAction<{ key: string | number; retencion: CompraInfo; imprimir: boolean }>
    ) {
      state[action.payload.key].autorizacion =
        action.payload.retencion.claveAcceso;
      state[action.payload.key].codigo = action.payload.retencion.codigo;
      state[action.payload.key].numero = action.payload.retencion.numero;
      state[action.payload.key].fechaAutorizacion =
        action.payload.retencion.fechaAutorizacion;
      state[action.payload.key].imprimir = action.payload.imprimir;
    },
    setImprimir(
      state,
      action: PayloadAction<{ key: number | string; imprimir: boolean }>
    ) {
      state[action.payload.key].imprimir = action.payload.imprimir;
    },
    setMostrarBusquedaItems(state, action: PayloadAction<{ key: string, mostrarBusquedaItems: boolean }>) {
      const { key, mostrarBusquedaItems } = action.payload;
      state[key].mostrarBusquedaItems = mostrarBusquedaItems;
    },
    updateObservaciones(state, action: PayloadAction<{ key: number | string, observaciones: string }>) {
      state[action.payload.key].observaciones = action.payload.observaciones;
    },
    addDetalle(state, action: PayloadAction<{ key: number | string, detalle: DetalleCompraRedux }>) {
      state[action.payload.key].detalles.push(action.payload.detalle);
    },
    setCuotasCredito(state, action: PayloadAction<{ key: number | string, cuotas: Array<CuotaCreditoVenta> }>) {
      const { key, cuotas } = action.payload;
      state[key].cuotas = cuotas;
    },
  },
});

export const {
  setCuotasCredito,
  addDetalle,
  updateObservaciones,
  updateTotales,
  setLocales,
  setLocal,
  setProyectos,
  setProyecto,
  setSustentosTributarios,
  setSustentoTributario,
  setTipoCompra,
  setAutorizacion,
  setComprobanteElectronico,
  setFechaEmicion,
  setFechaPropuesta,
  setPuntoEmision,
  setEstablecimiento,
  setFormaPago,
  setNumero,
  updateDetalles,
  setDatosEdicion,
  setEditLoader,
  setMuestraError,
  ventasClearDatosEdicion: clearDatosEdicion,
  updateResultadoGuardarRetencion,
  setImprimir,
  updateProveedor,
  setMostrarBusquedaItems
} = datosEdicionCompraSlice.actions;
export const datosEdicionReducer = datosEdicionCompraSlice.reducer;
