import { utilidades } from "../../../../helpers/utilidades";
import { CapitalizeMode } from "../../../../store/types";
import { localesService } from "../service/locales.service";
import { KeyLocLook } from "../store/enumKeys";
import { LocalOption } from "../store/type";

export async function getLocales(mensaje: string) {
  const data = await localesService.getLocales(mensaje);
  let opt: Array<LocalOption> = []
  if (data.auto.length > 0) {
    opt = data.auto;
  }
  return opt
}

export function parseLocalOptions(options: Array<any>) {

  let opt: Array<LocalOption> = []
  if (options.length > 0) {
    opt = options.map((x) => {
      const item: LocalOption = {
        codigo: parseInt(x[KeyLocLook.cod]) ?? 0,
        ciudad: parseInt(x[KeyLocLook.cityCod]) ?? 0,
        ciudadNombre: utilidades.capText(x?.[KeyLocLook.cityName]) ?? '',
        nombre: utilidades.capText(x?.[KeyLocLook.name], CapitalizeMode.upperFirst) ?? '',
        direccion: x?.[KeyLocLook.address] ?? '',
        telefono: x?.[KeyLocLook.numberPhone] ?? '',
        gerente: x?.[KeyLocLook.ger] ?? '',
        tipo: parseInt(x[KeyLocLook.typeCod]) ?? 0,
        tipoDescripcion: x?.[KeyLocLook.typeDesc] ?? '',
        clase: parseInt(x[KeyLocLook.class]) ?? 0,
        claseDescripcion: x?.[KeyLocLook.classDes] ?? '',
        pertenece: parseInt(x[KeyLocLook.owner]) ?? 0,
        perteneceDescripcion: x?.[KeyLocLook.ownerDes] ?? '',
        estado: parseInt(x[KeyLocLook.stateCod]) ?? 0,
        estadoDescripcion: x?.[KeyLocLook.stateDes] ?? '',
        kardex: parseInt(x[KeyLocLook.kardex]) ?? 0,
        siglas: x?.[KeyLocLook.acronym] ?? '',
        siglasLocal: x?.[KeyLocLook.acronymLoc] ?? '',
        web: parseInt(x[KeyLocLook.web]) ?? 0,
        uaf: x?.[KeyLocLook.uaf] ?? '',
        descuento: parseInt(x[KeyLocLook.descount]) ?? 0,
        contrato: parseInt(x[KeyLocLook.contract]) ?? 0,
        observaciones: x?.[KeyLocLook.observations] ?? '',
        usuarioDokan: x?.[KeyLocLook.userDokan] ?? '',
        passwordDokan: x?.[KeyLocLook.passDokan] ?? '',
        ulotipo: x?.[KeyLocLook.uloTipo] ?? '',
      }
      return item
    })
  }

  return opt
}