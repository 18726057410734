import React from 'react'
import SelectBox from 'devextreme-react/select-box'
import { TipoProveedor } from '../../ventas/types/types'
import { tipoProveedorService } from './service/tiposProveedor.service'
import { v4 as uuidv4 } from 'uuid'

interface ITipoProveedorLookUpProps extends React.PropsWithChildren {
  selected: TipoProveedor | null
  onChanged: (event: any | null) => void
  onChange?: (event: any | null) => void
  disabled?: boolean
  provider?: Array<TipoProveedor> | []
}

const TipoProveedorLookUp: React.FC<ITipoProveedorLookUpProps> = (props) => {
  const { provider, selected, onChanged, disabled, onChange } = props;
  const selectRef = React.useRef<any>(null);
  const [options, setOptions] = React.useState<TipoProveedor[]>([]);

  const loadDatos = React.useCallback(async () => {
    if (provider?.length) {
      setOptions(provider);
      return;
    }

    const { error, auto } = await tipoProveedorService.getTiposProveedor('Elige una opción');
    if (!error && auto.length) {
      const opt = auto.map(x => ({
        codigo: x.codigo ?? '-1',
        descripcion: x.descripcion ?? ''
      }));
      setOptions(opt);
    }
  }, [provider]);

  const onValueChanged = React.useCallback((event: any) => {
    if (!event.event) return;
    onChanged(event.value);
    onChange?.(event.value);
  }, [onChanged, onChange]);

  React.useEffect(() => {
    loadDatos();
  }, [loadDatos]);

  return (
    <SelectBox
      ref={selectRef}
      key={uuidv4()}
      items={options}
      searchEnabled
      displayExpr="descripcion"
      searchExpr={['descripcion']}
      searchTimeout={500}
      value={selected}
      onValueChanged={onValueChanged}
      disabled={disabled}
      onFocusIn={(e: any) => e.element.querySelector('input.dx-texteditor-input').select()}
    >
      {props.children}
    </SelectBox>
  );
};
export default React.memo(TipoProveedorLookUp)
