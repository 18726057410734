import { combineReducers } from '@reduxjs/toolkit'
import { menuReducer } from './menuReducer'
import { tabsReducer } from './tabsReducer'
import { searchProformaReducer } from './searchReducer'
import { datosEdicionProformaReducer } from './editDataReducer'
import { configuracionesProformaReducer } from './configuracionesOrdenProformaReducer'
import { OrdenProformaState } from '../type/types'

export const ordenProformaReducer = combineReducers<OrdenProformaState>({
  menu: menuReducer,
  tabs: tabsReducer,
  search: searchProformaReducer,
  editData: datosEdicionProformaReducer,
  configuraciones: configuracionesProformaReducer,
})
