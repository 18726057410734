import React, { useCallback, useEffect } from 'react'
import { DocumentInfo, ToastTypes } from '../../../../../../../store/types'
import { TabState } from '../../../../../../../store/genericTabTypes'
import { MarcacionDatosEdicion } from '../../types/types'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import { Button, DateBox, NumberBox, TextArea, TextBox } from 'devextreme-react'
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput'
import Importar from '../../../../../../clientes/pages/docsCtasXCobrar/components/impportarExcel/importar'
import { ButtonTypes } from '../../../../../../../views/componentes/globalMenu/types'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import { clearButtonClick, setCurrentExecutingAction } from '../../store/tabsReducer'
import { addToast } from '../../../../../../../store/toasterReducer'
import { setChangeLoader, setDatosEdicion } from '../../store/editDataReducer'
import ModalUsuarios from '../../../../../../componentes/modalUsuarios'
import { EAplicationsAcatha } from '../../../../../../../store/enumsAplication'
import { consoleService } from '../../../../../../../services/console.service'
import { StatesEdition } from '../../../../../../../store/enums'
import { setDatosEdicionBackup } from '../../store/configReducer'
import { RegisterServices } from '../../../../../../componentes/modalMarcaciones/components/services/register.services'
import { SaveRegisterData } from '../../../../../../componentes/modalMarcaciones/components/types/types'
import { DateUtils } from '../../../../../../../helpers/dateUtils'
import { MarcationsService } from '../../services/marcations.service'
import BlockUi from '../../../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../../../views/componentes/loadingindicator/loadingindicator'
import { CLink, CTooltip } from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilInfo } from '@coreui/icons'
import { ModalErrores } from '../../../../../../componentes/modalErrores'


interface INuevoProps extends React.PropsWithChildren {
  info: DocumentInfo<MarcacionDatosEdicion>
  tab: TabState<MarcacionDatosEdicion>
  tabId: string
}


const Nuevo: React.FunctionComponent<INuevoProps> = (props) => {
  const { tab, tabId } = props

  const dispatch = useDispatch()
  const tabs = useSelector((state: RootState) => state.nomina.registros.marcacion.tabs)
  const modulo = useSelector((state: RootState) => state.nomina.registros.marcacion.config.aplicacion)
  const marcacionState = useSelector((state: RootState) => state.nomina.registros.marcacion.editData[tabId])
  const marcacionStateBackup = useSelector((state: RootState) => state.nomina.registros.marcacion.config[tabId])
  const loading = useSelector((state: RootState) => state.nomina.registros.marcacion.editData[tabId].loading)
  const loader = useSelector((state: RootState) => state.nomina.registros.marcacion.editData[tabId].loader)
  const localSesion = useSelector((state: RootState) => state.global.session.local)
  const [showImportButton, setShowImportButton] = React.useState<boolean>(false)
  const [showUsers, setShowUsers] = React.useState<boolean>(false)
  const [showErrores, setShowErrores] = React.useState<any>(false)


  const onSetButtonAction = useCallback(
    (tipo: ButtonTypes | undefined) => {
      dispatch(
        setCurrentExecutingAction({
          tabKey: tabId,
          buttonType: tipo,
        }),
      )
    },
    [dispatch, tabId],
  )

  const setToast = useCallback(
    (mensaje: string, tipo: ToastTypes) => {
      dispatch(
        addToast({
          title: 'Nómina - Marcación',
          content: mensaje,
          type: tipo,
        }),
      )
    },
    [dispatch],
  )

  const onLoader = useCallback((showLoader: boolean, mensaje: string) => {
    dispatch(setChangeLoader({
      key: tabId,
      data: {
        show: showLoader,
        mensaje: mensaje
      }
    }))
  }, [dispatch, tabId])


  const modoNuevo = React.useCallback(async (template: number, limpiar: boolean = false) => {
    if (loading === false && !limpiar) {
      return
    }

    onSetButtonAction(ButtonTypes.new)
    onLoader(true, 'Cargando...')
    const datanueva = { ...defaultMarcacionData }
    datanueva.horaIngreso = 8
    datanueva.fechaIngreso = new Date()
    datanueva.deshabilitarManual = false
    datanueva.loading = false
    dispatch(setDatosEdicion({
      key: tabId,
      data: {
        ...datanueva
      }
    }))
    dispatch(setDatosEdicionBackup({
      key: tabId,
      data: {
        ...datanueva
      }
    }))
    onLoader(false, '')
    onSetButtonAction(undefined)
  }, [loading, onLoader, onSetButtonAction, dispatch, tabId])

  const onValueChange = useCallback((data, key: string) => {
    dispatch(setDatosEdicion({
      key: tabId,
      data: {
        ...marcacionState,
        [key]: data
      }
    }))
  }, [dispatch, marcacionState, tabId])

  const onGetUser = useCallback(async () => {
    onLoader(true, 'Buscando usuario...')
    try {
      const data = await MarcationsService.getUserSimple(marcacionState?.identificacion ?? '')
      consoleService.log(data, 'data user')
      if (data?.auto && data?.error === false) {
        dispatch(setDatosEdicion({
          key: tabId,
          data: {
            ...marcacionState,
            usuarioCodigo: data?.auto?.codigo ?? 0,
            usuario: data?.auto?.nombre ?? '',
            identificacion: data?.auto?.cedula ?? '',
          }
        }))
      }
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
    onLoader(false, '')
  }, [setToast, marcacionState, onLoader, dispatch, tabId])

  const onGetPass = useCallback(async () => {
    onLoader(true, 'Buscando...')
    try {
      const save_data: SaveRegisterData = {
        codigoUsuario: 0,
        fecha: '',
        dia: 0,
        hora: '',
        observaciones: marcacionState?.clave ?? '',
        local: -1,
        tipo: 'USU',
      }
      const data = await RegisterServices.saveRegister(save_data)
      consoleService.log(data, 'data reg')
      const temp = { ...marcacionState }
      if (data?.auto && data?.error === false) {
        temp.nombre = data?.auto[0]?.nombre
        temp.hora = data?.auto[0]?.hora
        temp.deshabilitarManual = true
      } else {
        setToast(data?.message, ToastTypes.Danger)
        const error: string = data?.message
        if (error.indexOf('Error') !== -1) {
          temp.nombre = error
        } else {
          temp.nombre = 'Error, intentelo nuevamente'
        }
      }
      temp.clave = ''
      dispatch(setDatosEdicion({
        key: tabId,
        data: {
          ...temp
        }
      }))
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
    onLoader(false, '')
  }, [setToast, marcacionState, onLoader, dispatch, tabId])

  const onSave = useCallback(async () => {
    if (marcacionState?.usuarioCodigo > 0) {
      onSetButtonAction(ButtonTypes.save)
      onLoader(true, 'Guardando...')
      try {
        let fecha = ''
        if (marcacionState?.fechaIngreso !== null) {
          fecha = DateUtils.dateToString(marcacionState?.fechaIngreso, 'dd/MM/yyyy')
        }
        const save_data: SaveRegisterData = {
          codigoUsuario: marcacionState?.usuarioCodigo ?? 0,
          fecha: fecha,
          dia: marcacionState?.fechaIngreso.getDay(),
          hora: `${marcacionState?.horaIngreso}:${marcacionState?.minIngreso}:00`,
          observaciones: marcacionState?.observaciones ?? '',
          local: localSesion?.codigo ?? 0,
          tipo: 'ADM',
        }
        const data = await RegisterServices.saveRegister(save_data)
        consoleService.log(data, 'save data')
        const temp = { ...marcacionState }
        if (data?.auto && data?.error === false) {
          temp.nombre = data?.auto[0]?.nombre
          temp.hora = data?.auto[0]?.hora
          temp.deshabilitarManual = true
        } else {
          setToast(data?.message, ToastTypes.Danger)
          const error: string = data?.message
          if (error.indexOf('Error') !== -1) {
            temp.nombre = error
          } else {
            temp.nombre = 'Error, intentelo nuevamente'
          }
        }
        temp.clave = ''
        dispatch(setDatosEdicion({
          key: tabId,
          data: {
            ...temp
          }
        }))
      } catch (error) {
        setToast(error.message, ToastTypes.Danger)
      }
      onLoader(false, '')
      onSetButtonAction(undefined)
    } else {
      setToast('Si desea guardar elija un usuario', ToastTypes.Warning)
    }
  }, [marcacionState, setToast, localSesion, onLoader, onSetButtonAction, dispatch, tabId])

  const onUndo = useCallback(() => {
    dispatch(setDatosEdicion({
      key: tabId,
      data: {
        ...marcacionStateBackup,
      }
    }))
  }, [marcacionStateBackup, dispatch, tabId])

  const handleButtonClick = useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.save:
          if (tabs.current === tabId) onSave()
          break
        case ButtonTypes.import:
          if (tabs.current === tabId) setShowImportButton(true)
          break
        case ButtonTypes.dialing:
        case ButtonTypes.undo:
          if (tabs.current === tabId) onUndo()
          break
        case ButtonTypes.broom:
          if (tabs.current === tabId) onUndo()
          break
        default:
          break
      }
      dispatch(clearButtonClick(tabId))
    }, [dispatch, tabId, tabs, onSave, onUndo])


  useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab.globalButtonClick])

  useEffect(() => {
    if (tab.editStatus === StatesEdition.new) {
      modoNuevo(tab?.info?.codigo)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onDatFile = useCallback((data: any) => {
    consoleService.log(data, 'file data')
    if (data?.error === false) {
      setToast(data?.message, ToastTypes.Success)
    } else {
      if (data?.auto && data?.auto?.length > 0) {
        dispatch(setDatosEdicion({
          key: tabId,
          data: {
            ...marcacionState,
            errores: data?.auto
          }
        }))
        setShowErrores(true)
      } else {
        setToast(data?.message, ToastTypes.Success)
      }
    }
  }, [dispatch, marcacionState, setToast, tabId])

  return (
    <>
      {
        showErrores && <ModalErrores
          onCancel={() => setShowErrores(false)}
          show={showErrores}
          errores={marcacionState?.errores ?? []}
        />
      }
      {showUsers && (
        <ModalUsuarios
          show={showUsers}
          onClose={() => setShowUsers(false)}
          modulo={EAplicationsAcatha.Marcacion}
          onSelectUser={(user) => {
            consoleService.log(user)
            dispatch(setDatosEdicion({
              key: tabId,
              data: {
                ...marcacionState,
                usuarioCodigo: user?.codigo,
                identificacion: user?.cedula,
                usuario: user?.nombre
              }
            }))
            setShowUsers(false)
          }}
        />
      )}
      <RowContainer >
        <RowContainer className='d-flex justify-content-center'>
          {showImportButton && (
            <CustomCol xs="12" md="5">
              <Importar
                tab={tab}
                tabId={tabId}
                openImport={(open) => setShowImportButton(open)}
                moduleCode={modulo}
                setData={(data) => onDatFile(data)}
              />
              <div
                style={{
                  display: 'flex',
                  gap: '2px',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <CTooltip
                  key={'tool-more-auth-side'}
                  placement="top"
                  content={
                    'Para editar un ítem haga doble clic sobre este en la lista de detalles'
                  }
                >
                  <div className="card-header-actions">
                    <CLink className="card-header-action m-0">
                      <CIcon icon={cilInfo} className="ms-1 me-1" />
                    </CLink>
                  </div>
                </CTooltip>
                <div>{'El tipo de archivos a importar es .xls'}</div>
              </div>
            </CustomCol>
          )}
          <div style={{ overflowX: 'hidden' }}>
            <BlockUi
              tag="div"
              loader={LoadingIndicator}
              blocking={loader.show}
              message={loader.mensaje}
            >
              <RowContainer className='d-flex justify-content-center'>
                <CustomCol xs="12" md="3">
                  <Labeled label=''>

                    <TextBox readOnly value={marcacionState?.nombre ?? ''} />
                  </Labeled>
                </CustomCol>
              </RowContainer>
              <RowContainer className='d-flex justify-content-center'>
                <CustomCol xs="12" md="3">
                  <Labeled label=''>
                    <TextBox readOnly value={marcacionState?.hora ?? ''} />
                  </Labeled>
                </CustomCol>
              </RowContainer>
              <RowContainer className='d-flex justify-content-center'>
                <CustomCol xs="12" md="3">
                  <Labeled label=''>
                    <TextBox
                      mode="password"
                      value={marcacionState?.clave ?? ''}
                      onValueChange={(data) => onValueChange(data, 'clave')}
                      onEnterKey={onGetPass}
                    />
                  </Labeled>
                </CustomCol>
              </RowContainer>
              <RowContainer className='d-flex justify-content-center'>
                <CustomCol xs="12" md="4">
                  <Labeled label='C.I.:' position='left'>
                    <div
                      style={{
                        display: 'flex',
                        gap: '2px',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >

                      <TextBox
                        disabled={marcacionState?.deshabilitarManual}
                        value={marcacionState?.identificacion ?? ''}
                        onValueChange={(data) => onValueChange(data, 'identificacion')}
                        onEnterKey={onGetUser}
                      />
                      <Button
                        disabled={marcacionState?.deshabilitarManual}
                        id="btnAdd"
                        className="me-1"
                        icon="plus"
                        stylingMode="contained"
                        type="default"
                        onClick={() => setShowUsers(true)}
                      />
                    </div>
                  </Labeled>
                </CustomCol>
              </RowContainer>
              <RowContainer className='d-flex justify-content-center'>
                <CustomCol xs="12" md="4">
                  <Labeled label=''>
                    <TextBox
                      disabled={marcacionState?.deshabilitarManual}
                      readOnly
                      value={marcacionState?.usuario ?? ''}
                      onValueChange={(data) => onValueChange(data, 'usuario')}
                    />
                  </Labeled>
                </CustomCol>
              </RowContainer>
              <RowContainer className='d-flex justify-content-center'>
                <CustomCol xs="12" md="2">
                  <Labeled label=''>
                    <DateBox
                      disabled={marcacionState?.deshabilitarManual}
                      value={marcacionState?.fechaIngreso ?? null}
                      onValueChange={(data) => onValueChange(data, 'fechaIngreso')}
                    />
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="2">
                  <Labeled label=''>
                    <NumberBox
                      disabled={marcacionState?.deshabilitarManual}
                      value={marcacionState?.horaIngreso ?? null}
                      onValueChange={(data) => onValueChange(data, 'horaIngreso')}
                    />
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="2">
                  <Labeled label=''>
                    <NumberBox
                      disabled={marcacionState?.deshabilitarManual}
                      value={marcacionState?.minIngreso ?? null}
                      onValueChange={(data) => onValueChange(data, 'minIngreso')}
                    />
                  </Labeled>
                </CustomCol>
              </RowContainer>
              <RowContainer className='d-flex justify-content-center'>
                <CustomCol xs="12" md="4">
                  <Labeled label=''>
                    <TextArea
                      disabled={marcacionState?.deshabilitarManual}
                      value={marcacionState?.observaciones ?? ''}
                      onValueChange={(data) => onValueChange(data, 'observaciones')}
                    />
                  </Labeled>
                </CustomCol>
              </RowContainer>
              <RowContainer className='d-flex justify-content-center'>
                <CustomCol xs="12" md="4">
                  <Labeled label=''>
                    <h3><strong>{'Pase su credencial por el lector'}</strong></h3>
                  </Labeled>
                </CustomCol>
              </RowContainer>
            </BlockUi>
          </div>
        </RowContainer>
      </RowContainer>
    </>
  )
}

export default React.memo(Nuevo)


export const defaultMarcacionData: MarcacionDatosEdicion = {
  nombre: '',
  hora: '',
  clave: '',
  identificacion: '',
  usuario: '',
  usuarioCodigo: 0,
  fechaIngreso: null,
  horaIngreso: 0,
  minIngreso: 0,
  observaciones: '',
  loader: {
    show: false,
    mensaje: ''
  },
  loading: false,
  deshabilitarManual: true,
  errores: []
}