import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LoaderInfo } from '../../../../../store/types'
import { CustomDictionary } from '../../../../ventas/types/generics'
import { LocalDatosEdicion } from './types'
import { TConfEdicionPayload } from '../../store/types'
import { ventasClearDatosEdicion } from '../../../../ventas/store/reducers'

const initialState: CustomDictionary<LocalDatosEdicion> = {}
const datosEdicionConfLocalSlice = createSlice({
  name: 'LocalDatosEdicion',
  initialState: initialState,
  reducers: {
    ventasClearDatosEdicion,
    setDatosEdicion(
      state,
      action: PayloadAction<TConfEdicionPayload<LocalDatosEdicion>>,
    ) {
      state[action.payload.key] = action.payload.data
    },
    //Establecer datos edicion para un tab nuevo
    initDatosEdicion(
      state,
      action: PayloadAction<TConfEdicionPayload<LocalDatosEdicion>>,
    ) {
      if (!state[action.payload.key]) {
        state[action.payload.key] = action.payload.data
      }
    },
    // setEditLoader(
    //   state,
    //   action: PayloadAction<{ key: number | string; info: LoaderInfo }>,
    // ) {
    //   state[action.payload.key].loader = action.payload.info
    // },
  },
})

export const {
  ventasClearDatosEdicion: clearDatosEdicion,
  initDatosEdicion,
  setDatosEdicion,
} = datosEdicionConfLocalSlice.actions
export const dataEditionReducer = datosEdicionConfLocalSlice.reducer
