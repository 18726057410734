import { DateUtils, formatoFechasApi } from '../../../../helpers/dateUtils'
import { RequestHelper } from '../../../../helpers/requestHelper'
import { Local, Project } from '../../../../modulos/shared/types/types'
import { SesionService } from '../../../../services/sesion.service'
import { KeyLoc, KeyLocNoCast, KeyPro } from './enumKeys'
import { ProjectsHelper } from './helper'

export const projetsService = {
  getProj,
  getLocs,
}

async function getProj(): Promise<any> {
  try {
    const obj: any = {}
    obj.dateKey = DateUtils.getCurrentDateAsString(formatoFechasApi)
    const sesion = SesionService.getCurrentSesion()
    obj.emp_loc = `${sesion.empresa.codigo}-${sesion.local.codigo}`
    const cacheKey = 'projectOptions' + JSON.stringify(obj)
    const cached = sessionStorage.getItem(cacheKey)
    if (cached) {
      return { error: false, auto: JSON.parse(cached) }
    } else {
      const data: any = await RequestHelper.getAll<any>(
        'proyectos',
        'listar',
        '',
        obj,
      )
      let opt: any = []
      if (data?.auto && data?.auto.length > 0) {
        const locs = await projetsService.getLocs(sesion.empresa.codigo, '')
        let locales: Array<Local> = []
        if (locs?.auto && locs?.auto.length > 0) {
          const aplicateEnum = ProjectsHelper.onDetectEnumLocal(locs?.auto[0])
          locales = ProjectsHelper.onParseLocs(locs.auto, aplicateEnum)
        }

        const _proj: Array<Project> = []
        Object.keys(data?.auto).map(function (key) {
          let addLocs: Array<Local> = []
          if (Number(data?.auto[key][KeyPro.cod]) === -1) {
            addLocs = locales ?? []
          } else {
            addLocs = ProjectsHelper.onParseLocs(
              data?.auto[key][KeyPro.locs],
              KeyLocNoCast,
            )
          }

          _proj.push({
            codigo: Number(data?.auto[key][KeyPro.cod]),
            nombre: data?.auto[key][KeyPro.name],
            locales: addLocs ?? [],
          })
        })
        opt = _proj ?? []
        data.auto = opt
      }
      sessionStorage.setItem(cacheKey, JSON.stringify(opt))
      return data
    }
  } catch (error) {
    return error
  }
}

async function getLocs(codEmp: number, mensaje: string): Promise<any> {
  try {
    const obj: any = {
      codigoempresa: codEmp,
      mensaje: mensaje,
    }
    obj.dateKey = DateUtils.getCurrentDateAsString(formatoFechasApi)
    const sesion = SesionService.getCurrentSesion()
    obj.emp_loc = `${sesion.empresa.codigo}-${sesion.local.codigo}`
    const cacheKey = 'locOptions' + JSON.stringify(obj)
    const cached = sessionStorage.getItem(cacheKey)
    if (cached) {
      return { error: false, auto: JSON.parse(cached) }
    } else {
      const data = await RequestHelper.getAll<any>('local', 'cboxLoad', '', obj)
      let opt: any = []
      if (data?.auto && data?.auto.length > 0) {
        opt = ProjectsHelper.onParseLocs(data?.auto, KeyLoc) ?? []
        data.auto = opt
      }
      sessionStorage.setItem(cacheKey, JSON.stringify(opt))
      return data
    }
  } catch (error) {
    return error
  }
}
