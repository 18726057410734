import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Draggable, SpeedDialAction } from 'devextreme-react'
import { AtributosDatosEdicion, AtributosInventario, AttributtesListDto } from '../../types/types'
import { ButtonTypes } from '../../../../../../../views/componentes/globalMenu/types'
import { clearButtonClick, openTab, setCurrentExecutingAction } from '../../store/tabsReducer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import {
  changeLoaderDataSheet,
  setCurrentFunction,
} from '../../store/generalReducer'
import { CCard, CCardFooter } from '@coreui/react-pro'
import { TabState } from '../../../../../../../store/genericTabTypes'
import { changeFilter, fetchAttributes, setCleanResult, setResetSeleccion } from '../../store/serachReducer'
import { ToastTypes } from '../../../../../../../store/types'
import { addToast } from '../../../../../../../store/toasterReducer'
import { attributesServices } from '../../services/unidades.services'
import { initDatosEdicion } from '../../store/editDataReducer'
import Dialog from '../../../../../../../views/componentes/librerias/bootstrap-dialog'
import ResultadosBusqueda from './ResultadosBusqueda'
import FiltroBusqueda from './filtroBusqueda'
import BlockUi from '../../../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../../../views/componentes/loadingindicator/loadingindicator'
import { isMobile } from 'react-device-detect'
import TableLoader from '../../../../../../ventas/components/ventas/busquedaVentas/TableLoader'
import { defaultDataSheet } from '../nuevo/index';
import { ReportWithLocalDataModal } from '../../../../../../../views/componentes/xtrareports/ReportWithLocalDataModal'
import { consoleService } from '../../../../../../../services/console.service'
import DataSource from 'devextreme/data/data_source'


enum VistasBusqueda {
  Filtros,
  ResultadosBusqueda,
  Resumen,
  Loading,
  Error
}


interface ISearchProps extends React.PropsWithChildren {
  tab: TabState<AtributosDatosEdicion>
  tabId: string
}

const draggingGroupName = 'appointmentsGroup';

const Search: React.FunctionComponent<ISearchProps> = (props) => {
  const { tabId, tab } = props
  const currentTab = useSelector((state: RootState) => {
    return state.inventarios.catalogos.atributos.tabs.current
  })
  const dispatch = useDispatch()
  const searchFilter = useSelector((state: RootState) => state.inventarios.catalogos.atributos.search.filter)
  const atributosLoader = useSelector((state: RootState) => {
    return state.inventarios.catalogos.atributos.general
  })
  const selected = useSelector((state: RootState) => state.inventarios.catalogos.atributos.search.seleccionado)
  const dialogRef = React.useRef<any>(null)
  const [confirmEdit, setConfirmEdit] = React.useState<boolean>(false)
  const [vistaActual, setVistaActual] = React.useState<VistasBusqueda>(VistasBusqueda.Filtros)
  const [confirmDelete, setConfirmDelete] = React.useState<boolean>(false)

  const [pageIndex, setPageIndex] = React.useState<number>(0)
  const [pageSize, setPageSize] = React.useState<number>(10)
  const { getDataAtributtesPaged } = attributesServices.useGeAtributosDataSource()
  const [dataAttributesPrint, setDataAttributesPrint] = React.useState<AtributosInventario[]>([])
  const [reporte, setReporte] = React.useState<null | 'Viewer' | 'Designer'>(null)
  const [dataSourceAtributtes, setDataSourceAtributtes] = React.useState<DataSource>(null)

  const DataSourceAtributtes = React.useMemo(
    () => getDataAtributtesPaged(searchFilter),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchFilter],)

  const onSetButtonAction = React.useCallback((tipo: ButtonTypes | undefined) => {
    dispatch(
      setCurrentExecutingAction({
        tabKey: 'BUSQUEDA',
        buttonType: tipo,
      }),
    )
  }, [dispatch])

  const getFilterString = React.useCallback(() => {
    const filterText = ''
    return filterText
  }, [])

  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(
      addToast({
        title: 'Inventario - Atributos',
        content: mensaje,
        type: tipo,
      }),
    )
  }, [dispatch])

  const playLoader = React.useCallback(
    (message = 'Cargando...') => {
      dispatch(changeLoaderDataSheet({ show: true, mensaje: message }))
    },
    [dispatch],
  )

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoaderDataSheet({ show: false, mensaje: '' }))
  }, [dispatch])

  const onInitFilter = React.useCallback(() => {
    const data = { ...searchFilter }
    if (data.initial) {
      data.propiedad = ''
      data.activo = true
      data.initial = false
      dispatch(changeFilter({
        ...data
      }))
    }
  }, [dispatch, searchFilter])

  const onResetPag = React.useCallback(async () => {
    setPageIndex(0)
    setPageSize(10)
  }, [])


  const onHandleEdit = React.useCallback(async (atributoData: any) => {
    const id = uuidv4()

    dispatch(
      initDatosEdicion({
        key: id,
        data: {
          ...defaultDataSheet,
          codigo: atributoData?.codigo,
          loading: true
        },
      }),
    )
    dispatch(openTab({ key: id, atributo: atributoData }))
  }, [dispatch])




  const onSearch = React.useCallback(async () => {
    onSetButtonAction(ButtonTypes.find)
    playLoader('Buscando...')
    try {
      if (isMobile) {
        setVistaActual(VistasBusqueda.Loading)
      }
      await DataSourceAtributtes.reload()
      setDataSourceAtributtes(DataSourceAtributtes)
      if (isMobile) {
        setVistaActual(VistasBusqueda.ResultadosBusqueda)
      }
    } catch (error) {
      if (isMobile) {
        setVistaActual(VistasBusqueda.Error)
      }
      setToast(error.message, ToastTypes.Danger)
    }
    stopLoader()
    onSetButtonAction(undefined)
  }, [setToast, DataSourceAtributtes, onSetButtonAction, stopLoader, playLoader])

  const onBusquedaPrint = React.useCallback(
    async (tipo: null | 'Viewer' | 'Designer') => {
      onSetButtonAction(ButtonTypes.print)
      playLoader('Generando Reporte...')
      try {
        const toAny: any = fetchAttributes(searchFilter)
        const res = await dispatch(toAny)
        if (res !== null && res !== undefined && res['payload']?.auto && res['payload'].auto?.length >= 0) {
          setDataAttributesPrint(res['payload']['auto'])
          setReporte(tipo)
        } else {
          setToast(res, ToastTypes.Danger)
        }
      } catch (error) {
        setToast(error, ToastTypes.Danger)
      }
      stopLoader()
      onSetButtonAction(undefined)
    },
    [
      dispatch,
      searchFilter,
      setToast,
      onSetButtonAction,
      playLoader,
      stopLoader,
    ],
  )

  const onHandleRemove = React.useCallback(async () => {
    try {
      onSetButtonAction(ButtonTypes.delete)
      playLoader('Eliminando registro...')
      if (isMobile) {
        setVistaActual(VistasBusqueda.Loading)
      }
      const data = await attributesServices.deletetAttribute(selected.row?.codigo)
      if (isMobile) {
        setVistaActual(VistasBusqueda.ResultadosBusqueda)
      }
      stopLoader()
      onSetButtonAction(undefined)
      if (data !== null && data !== undefined && data['error'] === false) {
        onSearch()
        dispatch(setResetSeleccion())
        setToast(data['message'], ToastTypes.Success)
      } else {
        setToast(data['message'], ToastTypes.Danger)
      }
    } catch (error) {
      if (isMobile) {
        setVistaActual(VistasBusqueda.Error)
      }
      stopLoader()
      onSetButtonAction(undefined)
      setToast(error.message, ToastTypes.Danger)
    }
  }, [selected, setToast, stopLoader, playLoader, onSetButtonAction, dispatch, onSearch])

  const onUndoFilter = React.useCallback(() => {
    const data = { ...searchFilter }
    data.propiedad = ''
    data.activo = true
    dispatch(changeFilter({
      ...data
    }))
    dispatch(setCleanResult())
    dispatch(setResetSeleccion())
  }, [dispatch, searchFilter])

  const onBroomResult = React.useCallback(() => {
    setDataSourceAtributtes(null)
  }, [])



  const onConfirmDelete = React.useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea Eliminar el Registro: ${selected.row?.descripcion ?? '-'}?`,
      actions: [
        Dialog.Action(
          <span>
            <u>A</u>ceptar
          </span>,
          (dialog) => {
            dialog.hide()
            onHandleRemove()
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setConfirmDelete(false)
    return
  }, [selected, onHandleRemove])

  const onConfirmEdit = React.useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea Editar el registro: ${selected.row?.descripcion ?? '-'}?`,
      actions: [
        Dialog.Action(
          <span>
            <u>A</u>ceptar
          </span>,
          (dialog) => {
            dialog.hide()
            onHandleEdit(selected.row)
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setConfirmEdit(false)
    return
  }, [onHandleEdit, selected])

  const handleButtonClick = React.useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.delete:
          if (currentTab === tabId) setConfirmDelete(true)
          break
        case ButtonTypes.find:
          if (currentTab === tabId) {
            onResetPag()
            onSearch()
          }
          break
        case ButtonTypes.edit:
          if (currentTab === tabId) setConfirmEdit(true)
          break
        case ButtonTypes.broom:
          if (currentTab === tabId) onBroomResult()
          break
        case ButtonTypes.undo:
          if (currentTab === tabId) onUndoFilter()
          break
        case ButtonTypes.print:
          if (currentTab === tabId) onBusquedaPrint('Viewer')
          break
        case ButtonTypes.print_design:
          if (currentTab === tabId) onBusquedaPrint('Designer')
          break
        default:
          break
      }
      dispatch(setCurrentFunction(''))
      dispatch(clearButtonClick(tabId))
    },
    [dispatch, tabId, onSearch, onResetPag, onUndoFilter, currentTab, onBusquedaPrint, onBroomResult],
  )



  React.useEffect(() => {
    if (confirmDelete) {
      onConfirmDelete()
    }
  }, [confirmDelete, onConfirmDelete])


  React.useEffect(() => {
    if (confirmEdit) {
      onConfirmEdit()
    }
  }, [confirmEdit, onConfirmEdit])


  const handleOptionChange = React.useCallback(async (e) => {
    if (e.fullName === "paging.pageSize") {
      setPageSize(e.value)
    }
    if (e.fullName === "paging.pageIndex") {
      setPageIndex(e.value)
    }
  }, []);

  const onDBClickRow = React.useCallback(() => {
    setConfirmEdit(true)
  }, [])

  React.useEffect(() => {
    if (confirmEdit) {
      onConfirmEdit()
    }
  }, [confirmEdit, onConfirmEdit])
  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab.globalButtonClick])

  React.useEffect(() => {
    onInitFilter()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onParseData = React.useCallback((attributtes: Array<any> = []) => {
    let registros: Array<AttributtesListDto> = []
    let registro: AttributtesListDto = null
    if (attributtes.length > 0) {
      registros = attributtes.map((x) => {
        registro = {
          Code: x?.codigo ?? 0,
          Description: x?.descripcion ?? '',
          Options: x?.opciones.toString() ?? '',
        }

        return registro
      })
    }
    consoleService.log(registros)
    return registros
  }, [])


  if (isMobile) {
    return (
      <>
        <CCard>

          <Dialog ref={dialogRef} />
          <ReportWithLocalDataModal
            show={reporte !== null}
            onClose={() => setReporte(null)}
            data={onParseData(dataAttributesPrint)}
            fileName="StaticAttributtesListado"
            mode={reporte ?? 'Viewer'}
            parameters={{ Reporte_Filtro: getFilterString() }}
            template="StaticAttributtesListado"
            key="reportDesigner"
          />
          {vistaActual === VistasBusqueda.Filtros &&

            (
              <>
                <FiltroBusqueda onSearchEnter={onSearch} />
              </>
            )
          }
          {vistaActual === VistasBusqueda.ResultadosBusqueda &&
            (<>
              <ResultadosBusqueda
                onDBClick={() => onDBClickRow()}
                handleOptionChange={handleOptionChange}
                data={dataSourceAtributtes}
                pageIndex={pageIndex}
                pageSize={pageSize}
                onDelete={() => setConfirmDelete(true)}
              />
            </>)
          }
          {vistaActual === VistasBusqueda.Error &&
            <></>
          }
          {vistaActual === VistasBusqueda.Loading &&
            <CCardFooter>
              <TableLoader />
            </CCardFooter>
          }
          {
            currentTab === tabId && (
              <Draggable
                id="list"
                data="dropArea"
                group={draggingGroupName}
              >
                <SpeedDialAction
                  icon="filter"
                  label='Filtros'
                  visible={true}
                  index={1}
                  onClick={() => {
                    setVistaActual(VistasBusqueda.Filtros);
                  }}
                />
                <SpeedDialAction
                  icon="search"
                  label='Busqueda'
                  visible={true}
                  index={2}
                  onClick={() => {
                    setVistaActual(VistasBusqueda.ResultadosBusqueda);
                  }}
                />
              </Draggable>
            )
          }
        </CCard>
      </>
    )
  }


  return (
    <>
      <Dialog ref={dialogRef} />
      <ReportWithLocalDataModal
        show={reporte !== null}
        onClose={() => setReporte(null)}
        data={onParseData(dataAttributesPrint)}
        fileName="StaticAttributtesListado"
        mode={reporte ?? 'Viewer'}
        parameters={{ Reporte_Filtro: getFilterString() }}
        template="StaticAttributtesListado"
        key="reportDesigner"
      />
      <div id='unidadesContent'>
        <BlockUi
          tag="div"
          loader={LoadingIndicator}
          blocking={atributosLoader.loader.show}
          message={atributosLoader.loader.mensaje}
        >
          <CCard>
            <FiltroBusqueda onSearchEnter={onSearch} />
            <ResultadosBusqueda
              onDBClick={() => onDBClickRow()}
              handleOptionChange={handleOptionChange}
              data={dataSourceAtributtes}
              pageIndex={pageIndex}
              pageSize={pageSize}
              onDelete={() => setConfirmDelete(true)}
            />
          </CCard>
        </BlockUi>
      </div>
    </>
  )
}

export default React.memo(Search)
