import React from 'react'
import { CBadge, CCard, CCardBody, CCardFooter, CCardHeader, CFormInput } from '@coreui/react-pro'
import DataGrid, { Column } from 'devextreme-react/data-grid'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import { consoleService } from '../../../../../../../services/console.service'
import { SmallLoadingIndicator } from '../../../../../../../views/componentes/loadingindicator/loadingindicator'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput'
import { utilidades } from '../../../../../../../helpers/utilidades'
import { OrdenPedidoService } from '../../../../../../ventas/pages/ordenes/pages/ordenPedido/services/ordenPedido.services'
import { getLocales } from '../../../../../../componentes/localesLookUp/helper/helper'
import { getIndexProvider } from '../../../../../../shared/helpers/funciones'
import { LocalOption } from '../../../../../../componentes/localesLookUp/store/type'
import { incomeExpensesServices } from '../../../ingresosEgresos/services/ingresosEgresos.services'
import { ProveedoresService } from '../../../../../../proveedores/services/proveedores.service'

const DetalleImportaciones: React.FunctionComponent<any> = (props) => {
  const empresa = useSelector((state: RootState) => state.global.session.empresa)

  const [loading, setLoading] = React.useState(false)
  const [loaded, setLoaded] = React.useState(false)
  const [detalles, setDetalles] = React.useState<Array<any>>([])
  const [almacen, setAlmacen] = React.useState<string>('')
  const [punto, setPunto] = React.useState<string>('')
  const [establecimiento, setEstablecimiento] = React.useState<string>('')
  const [documento, setDocumento] = React.useState<string>('')
  const onGetDeatilMove = React.useCallback(async (codigo: number) => {
    try {
      const res = await incomeExpensesServices.getIncomeExpensesDeatil(codigo)
      if (res !== null && res !== undefined && res['error'] === false) {
        return res['auto']
      }
      return []
    } catch (error) {
      return []
    }
  }, [])

  const cargarInformacionDetallada = React.useCallback(
    async (importaciones: any) => {

      setLoading(true)
      const resLocal = await getLocales('Elija un almacén')
      const detalle = await await onGetDeatilMove(importaciones?.codigo ?? 0)
      if (resLocal.length > 0) {
        let almacenView: LocalOption = null
        const provider = resLocal.slice(0)
        const index = await getIndexProvider(provider, 'codigo', importaciones?.localCodigo ?? -1)
        if (index > -1) {
          almacenView = provider[index]
        } else {
          almacenView = provider[0]
        }
        setAlmacen(almacenView?.nombre ?? '')
      }
      const edDoc = importaciones?.documento.split("-")
      if (edDoc.length >= 3) {
        setEstablecimiento(edDoc[0])
        setPunto(edDoc[1])
        setDocumento(edDoc[2])
      } else {
        setDocumento(importaciones?.documento)
      }
      setDetalles(detalle)
      setLoaded(true)
      setLoading(false)
      consoleService.log(importaciones)
    },
    [onGetDeatilMove],
  )

  React.useEffect(() => {
    cargarInformacionDetallada(props.data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) {
    return <SmallLoadingIndicator mensaje="Cargando información adicional del registro..." />
  }

  if (!loaded) {
    return <></>
  }
  const importaciones = props.data as any
  return (
    <div>
      <RowContainer>
        <CustomCol xs="12">
          <CCard className="border-top-primary border-top-3" id="busqueda-modVentas-resumen">
            <CCardHeader>
              <span style={{ float: 'left' }}>
                <CBadge color="secondary" textColor="black">
                  <strong>
                    {`${importaciones?.documento ?? ''}-${importaciones?.tipo ?? ''}`}
                  </strong>
                </CBadge>
              </span>
            </CCardHeader>
            <CCardBody>
              <div className="container">
                <RowContainer>
                  <CustomCol xs="12" md="3" xl="4">
                    <Labeled label="Concepto:">
                      <CFormInput size="sm" readOnly type="string" value={`${importaciones?.movimientoDescripcion ?? ''}`} />
                    </Labeled>
                  </CustomCol>
                  {importaciones?.fechaCompra && (
                    <CustomCol xs="6" md="3" xl="2">
                      <Labeled label="Fecha Compra:">
                        <CBadge color="info">{`${importaciones?.fechaCompra ?? ''}`}</CBadge>
                      </Labeled>
                    </CustomCol>
                  )}
                  <CustomCol xs="6" md="3" xl="4">
                    <Labeled label="Proveedor:">
                      <CFormInput size="sm" readOnly value={importaciones?.productoClienteDescripcion ?? ''} />
                    </Labeled>
                  </CustomCol>

                </RowContainer>
                <RowContainer>
                  {importaciones?.fecha && (
                    <CustomCol xs="6" md="3" xl="2">
                      <Labeled label="Fecha Imp:">
                        <CBadge color="info">{`${importaciones?.fecha ?? ''}`}</CBadge>
                      </Labeled>
                    </CustomCol>
                  )}
                  <CustomCol xs="4" md="6" xl="2">
                    <Labeled label="Observaciones:">
                      <CFormInput value={`${importaciones?.observaciones ?? ''}`} readOnly={true} size="sm" />
                    </Labeled>
                  </CustomCol>
                  <CustomCol xs="12" md="6">
                    <Labeled label="Documento:">
                      <RowContainer>
                        <CustomCol xs="3">
                          <CFormInput value={establecimiento} readOnly={true} size="sm" />
                        </CustomCol>
                        <CustomCol xs="3">
                          <CFormInput value={punto} readOnly={true} size="sm" />
                        </CustomCol>
                        <CustomCol>
                          <CFormInput value={documento} readOnly={true} size="sm" />
                        </CustomCol>
                      </RowContainer>
                    </Labeled>
                  </CustomCol>
                </RowContainer>
                <RowContainer>
                  <CustomCol xs="6" md="3" xl="2">
                    <Labeled label="Local:">
                      <CFormInput size="sm" readOnly value={almacen} />
                    </Labeled>
                  </CustomCol>
                </RowContainer>
                <RowContainer className="tabla-busqueda-modVentas-resumen">
                  <DataGrid
                    keyExpr={['codigo', 'cantidad']}
                    dataSource={detalles}
                    focusedRowEnabled
                    hoverStateEnabled
                    showBorders
                    width={utilidades.getGridFullWidth()}
                  >
                    <Column
                      dataField="codigoUsuario"
                      caption="Interno"
                      visible={false}
                      width="50px"
                    />
                    <Column
                      dataField="barras"
                      caption="Barras"
                      width="100px"
                    />
                    <Column
                      dataField="unidadDescripcion"
                      caption="Unidad"
                      width="100px"
                    />
                    <Column
                      dataField="descripcion"
                      caption="Item"
                      width="250px"
                    />
                    <Column
                      dataField="pvp"
                      caption="PVP IVA"
                      width="11px"
                      visible={false}
                    />
                    <Column
                      dataType='number'
                      dataField="cantidad"
                      caption="Cantidad"
                      width="100px"
                    />
                    <Column
                      dataType='number'
                      dataField="precio"
                      caption="Costo Unitario"
                      width="100px"
                    />
                    <Column
                      dataType='number'
                      dataField="valorDescripcion"
                      caption="Valor Original"
                      width="100px"
                    />
                    <Column
                      dataType='number'
                      dataField="valor"
                      caption="Total"
                      width="100px"
                    />
                  </DataGrid>
                </RowContainer>
              </div>
            </CCardBody>
            <CCardFooter></CCardFooter>
          </CCard>
        </CustomCol>
      </RowContainer>
    </div>
  )
}

export default React.memo(DetalleImportaciones)
