import { RequestHelper } from "../../../../helpers/requestHelper";

export const proyectosService = { getProyectos };

async function getProyectos(label: string): Promise<any> {
  try {
    const data: any = {
      mensaje: label,
    };
    const res = await RequestHelper.getAll<any>('proyectos', 'listar', "", data);
    return res;
  } catch (error) {
    return error;
  }
}

