import React from 'react'
import { CCol, CRow } from '@coreui/react-pro'
import { useDispatch, useSelector } from 'react-redux'
import {
  Column,
  DataGrid,
  FilterRow,
  HeaderFilter,
  Summary,
  TotalItem,
  Paging
} from 'devextreme-react/data-grid'
import { NumberBox } from 'devextreme-react/number-box'
import { RootState } from '../../../../../store/store'
import { utilidades } from '../../../../../helpers/utilidades'
import { setSeleccionarDato } from "../../store/modalTarjetasReducer"

interface TablaTarjetasProps extends React.PropsWithChildren {
  name: string
  pasarSeleccionados: (data) => void
  seleccionadosDetalle: any,
  total_ti: number
  onSelected: (data) => void
}

const TablaTarjetas = (props: TablaTarjetasProps) => {

  const { onSelected } = props;

  const dispatch = useDispatch()
  const registros = useSelector((state: RootState) => state.bancos.modalTarjetas.registros)

  const [data, setData] = React.useState<Array<any>>([])
  const [selectedItemKeys, setSelectedItemKeys] = React.useState<any>([])
  const [valor_ti, setValor_ti] = React.useState<number>(0)

  React.useEffect(() => {
    setSelectedItemKeys(props.seleccionadosDetalle)
  }, [props.seleccionadosDetalle])

  React.useEffect(() => {
    if (registros.length > 0) {
      llenarData(registros)
    } else {
      setData([])
    }
  }, [registros])

  React.useEffect(() => {
    setValor_ti(props.total_ti)
  }, [props.total_ti])

  const llenarData = (data) => {
    const item: any = []
    let contar: number = 0;
    try {
      data.map(function (key, i) {
        let itemAdd: any = {}
        itemAdd = {
          ...key,
          ORDEN: Number(i)
        }
        contar = contar + 1;
        item.push(itemAdd);
      })
      setData(item.map(x => utilidades.unfreeze(x)))
    } catch (error) {
      console.error("on llenarData", error)
    }
  }

  const selectionChanged = (selectionChanged) => {
    if (data.length > 0) {
      if (selectionChanged['selectedRowsData'].length > 0) {
        setSelectedItemKeys(selectionChanged.selectedRowKeys)
        dispatch(setSeleccionarDato(
          {
            index: selectionChanged['selectedRowKeys'],
            row: selectionChanged['selectedRowsData'][0]
          }
        ))
      }
    }
  }

  const onRowDoubleClick = React.useCallback((e) => {
    const { data } = e;
    onSelected(data);
  }, [onSelected]);


  return (
    <>
      <CRow>
        <CCol className='d-flex justify-content-end'>{data.length}</CCol>
      </CRow>
      <CRow>
        <CCol className='d-flex justify-content-center'>
          <DataGrid
            selection={{ mode: 'single' }}
            hoverStateEnabled={true}
            dataSource={data}
            showBorders={true}
            showRowLines={true}
            onSelectionChanged={selectionChanged}
            onRowDblClick={onRowDoubleClick}
            allowColumnReordering={true}
            allowColumnResizing={true}
            columnAutoWidth={true}
          >
            <FilterRow visible={true} />
            <HeaderFilter visible={true} />
            <Paging enabled={false} />
            <Column
              dataType='string'
              dataField='nombreTarjeta'
              caption='Tarjeta'
              width='25%'
            />
            <Column
              dataType='string'
              dataField='loteTAV'
              caption='Lote'
              width='15%'
            />
            <Column
              dataType='number'
              dataField='recapTAV'
              caption='Recap'
              width={'15%'}
            />
            <Column
              dataField='autorizacionTAV'
              caption='Autorizacion'
              width='15%'
            />
            <Column dataField='nombreCMO' caption='Comisión' width='15%' />
            <Column dataField='valorTAV' caption='Valor' width='20%' />
            <Column dataField='numeroVenta' caption='Venta' width='25%' />
            <Column dataField='fechaVenta' caption='Fecha' width='10%' />
            <Summary>
              <TotalItem column='valorTAV' summaryType='sum' valueFormat='currency' />
            </Summary>
          </DataGrid>
        </CCol>
      </CRow>
      <CRow className="mt-2">
        <CCol className='d-flex justify-content-end'>
          <div className="dx-field">
            <div className="dx-field-label">
              {"Total:"}
            </div>
            <div className="dx-field-value">
              <NumberBox
                width={"100%"}
                value={valor_ti}
                placeholder=""
              />
            </div>
          </div>
        </CCol>
      </CRow>
    </>
  )
}

export default TablaTarjetas
