import { DateUtils, formatoFechasApi } from '../../../../helpers/dateUtils'
import { utilidades } from '../../../../helpers/utilidades'
import { SesionService } from '../../../../services/sesion.service'
import { WebCategoryWoo } from '../../../inventario/pages/items/types/types'
import { InventarioService } from '../../../inventario/services/inventario.service'

export const webCategoriesServices = { getWebCategoriesWoo }

async function getWebCategoriesWoo(): Promise<any> {
  try {
    const sesion = SesionService.getCurrentSesion()
    const keysToCache: any = {
      dateKey: DateUtils.getCurrentDateAsString(formatoFechasApi),
      empresa: sesion?.empresa?.codigo,
    }
    const cacheKey = 'categoriasWeb' + JSON.stringify(keysToCache)
    const cached = sessionStorage.getItem(cacheKey)
    if (cached) {
      return { error: false, auto: JSON.parse(cached) }
    } else {
      const webCategoriesData = await InventarioService.getCategoriasWeb()
      const webCategories: Array<WebCategoryWoo> = []
      if (webCategoriesData?.length > 0) {
        for (const webCategory of webCategoriesData.auto) {
          webCategories.push({
            id: webCategory?.id ?? -1,
            name: utilidades.capText(webCategory?.name) ?? '',
          })
        }
        sessionStorage.setItem(cacheKey, JSON.stringify(webCategories))
        return { error: false, auto: webCategories }
      } else
        return {
          error: true,
          message: webCategoriesData.message
            ? webCategoriesData.message
            : 'Error al obtener los datos: Categorias Web',
        }
    }
  } catch (error) {
    return error
  }
}
