import React from 'react'
import { Lookup, DropDownOptions } from 'devextreme-react/lookup'
import { Proveedor, ProveedorBusqueda } from '../../store/types'
import { ProveedoresService } from '../../services/proveedores.service'
import RowContainer from '../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../views/componentes/colContainer'
import SelectBox from 'devextreme-react/select-box'
import ModalAdminProveedores from '../modalAdminProveedores/ModalAdminProveedores'
import Button from 'devextreme-react/button'

interface IBuscarProveedorLookUpProps extends React.PropsWithChildren {
  selected: ProveedorBusqueda | null
  onChanged: (newValue: ProveedorBusqueda | null) => void
  onChange?: (newValue: ProveedorBusqueda | null) => void
  allowAdd?: boolean
  allowClear?: boolean
  allowEdit?: boolean
  esTransportista?: boolean
  disabled?: boolean
  onEnterKey?: any
}

export const BuscarProveedorLookUp: React.FC<IBuscarProveedorLookUpProps> = (
  props,
) => {
  const {
    selected,
    onChanged,
    onChange,
    esTransportista,
    disabled,
    allowAdd,
    allowClear,
    allowEdit,
    onEnterKey,
  } = props
  const [mostrarEdicion, setMostrarEdicion] = React.useState(false)
  const [proveedorEditando, setProveedorEditando] =
    React.useState<ProveedorBusqueda | null>(null)

  const selectRef = React.useRef<any>()

  const datasource = React.useMemo(() => {
    return ProveedoresService.getProveedoresDatasource(true, esTransportista)
  }, [esTransportista])

  const onLookUpValueChanged = React.useCallback(
    ({ value }: any) => {
      if (typeof value == 'string') return
      onChanged(value)
    },
    [onChanged],
  )

  const getProveedorDisplayExpr = React.useCallback((proveedor: Proveedor) => {
    return proveedor ? `${proveedor.identificacion} - ${proveedor.nombre}` : ''
  }, [])

  const onSelectBoxFocusIn = React.useCallback((e) => {
    e.element.querySelector('input.dx-texteditor-input').select()
  }, [])

  const onParseProv = React.useCallback((infoProv) => {
    return {
      ...infoProv,
      codigo: infoProv.codigo,
      nombre: infoProv.nombre,
      razonComercial: infoProv.razonComercial,
      identificacion: infoProv.identificacion,
      email: infoProv.email,
      direccion: infoProv.direccion,
      diaReposicion: infoProv.diaReposicion,
      diasCredito: infoProv.diasCredito,
      intervalo: infoProv.intervalo,
      limiteCredito: infoProv.limiteCredito,
      personaAtencionCompras: infoProv.personaAtencionCompras,
      personaAtencionPagos: infoProv.personaAtencionPagos,
      telefono: infoProv.telefono,
      tipoIdDescripcion: infoProv.tipoIdentificacionDescripcion,
      tipoIdentificacion: infoProv.tipoIdentificacion,
      placas: infoProv.placas,
      tipoActividad: infoProv?.actividadCodigo ?? null,
    }
  }, [])

  const onModificarProveedorChanged = React.useCallback(
    async (identificacion) => {
      setMostrarEdicion(false)
      setProveedorEditando(null)

      if (selected) {
        await datasource.reload()
        if (selectRef) {
          selectRef.current.instance.repaint()
        }

        const loadPro = await ProveedoresService.getProveedor(identificacion)

        if (loadPro?.codigo) {
          const prov = await ProveedoresService.getProveedorByCode(
            loadPro?.codigo ?? 0,
          )

          const resProv = onParseProv(prov)

          onChanged(resProv)
        }
      } else {
        const nuevo = await ProveedoresService.getProveedor(identificacion)

        onChanged(nuevo)
      }
      //onChanged(nuevo)
    },
    [selected, onChanged, datasource, onParseProv],
  )

  const onModificarProveedorCancel = React.useCallback(() => {
    setProveedorEditando(null)
    setMostrarEdicion(false)
  }, [])

  if (allowAdd || allowEdit || allowClear) {
    return (
      <>
        {!esTransportista && (
          <ModalAdminProveedores
            codigoProveedor={
              proveedorEditando ? proveedorEditando.codigo : null
            }
            onChanged={onModificarProveedorChanged}
            onCancel={onModificarProveedorCancel}
            show={mostrarEdicion}
            esTransportista={esTransportista ?? false}
          />
        )}
        {/* ModalAdminTransportista */}
        {esTransportista && (
          <ModalAdminProveedores
            codigoProveedor={
              proveedorEditando ? proveedorEditando.codigo : null
            }
            onChanged={onModificarProveedorChanged}
            onCancel={onModificarProveedorCancel}
            show={mostrarEdicion}
            esTransportista={esTransportista ?? false}
          />
        )}
        <RowContainer>
          <CustomCol xs="9" md="9" className="pe-0">
            <SelectBox
              ref={selectRef}
              dataSource={datasource}
              searchEnabled
              displayExpr={getProveedorDisplayExpr}
              searchExpr={['nombre', 'identificacion']}
              searchTimeout={500}
              value={selected}
              onValueChanged={(e) => {
                if (e.event) {
                  onLookUpValueChanged(e)
                }
              }}
              onFocusIn={onSelectBoxFocusIn}
              disabled={disabled}
              onEnterKey={onEnterKey}
              width={'100%'}
            >
              {props.children}
            </SelectBox>
          </CustomCol>
          <CustomCol md="3" xs="3" className="m-0 p-0">
            <div className="d-flex flex-row align-items-start m-0 p-0">
              {allowEdit && selected && (
                <div style={{ marginLeft: '1px' }}>
                  <Button
                    stylingMode="contained"
                    type="default"
                    icon="edit"
                    onClick={() => {
                      setProveedorEditando(selected)
                      setMostrarEdicion(true)
                    }}
                  ></Button>
                </div>
              )}
              {allowAdd && !disabled && (
                <div style={{ marginLeft: '1px' }}>
                  <Button
                    stylingMode="contained"
                    type="default"
                    icon="add"
                    onClick={() => {
                      setProveedorEditando(null)
                      setMostrarEdicion(true)
                    }}
                  ></Button>
                </div>
              )}
              {!disabled && allowClear && selected && (
                <div style={{ marginLeft: '1px' }}>
                  <Button
                    stylingMode="contained"
                    type="danger"
                    icon="undo"
                    onClick={() => {
                      onChanged(null)
                    }}
                  ></Button>
                </div>
              )}
            </div>
          </CustomCol>
        </RowContainer>
      </>
    )
  }

  return (
    <Lookup
      dataSource={datasource}
      showDataBeforeSearch
      value={selected}
      minSearchLength={3}
      searchTimeout={500}
      displayExpr={getProveedorDisplayExpr}
      searchExpr={['nombre', 'identificacion']}
      pageLoadMode="scrollBottom"
      showClearButton={true}
      onValueChanged={({ value }: any) => {
        onChanged(value)
      }}
      onValueChange={(value) => {
        onChange(value)
      }}
      disabled={disabled}
      onEnterKey={onEnterKey}
    >
      <DropDownOptions
        tabIndex={3000}
        closeOnOutsideClick={true}
        showTitle={false}
      />
      {props.children}
    </Lookup>
  )
}
