import React, { useEffect } from 'react'
import {
  AccionMenu,
  CustomButtons,
  FETCH_STATUS,
  ToastTypes,
} from '../../../../store/types'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../../store/store'
import { changeLoaderKardex } from './store/generalReducer'
import { addToast } from '../../../../store/toasterReducer'
import { Aplicacion } from '../../store/types'
import {
  changeCurrentTab,
  setButtons,
  setCurrentExecutingAction,
} from './store/tabsReducer'
import { ButtonTypes } from '../../../../views/componentes/globalMenu/types'
import { fetchLots, fetchLotsTransactions, setCleanResult, changeCurrentTab as changeTabSearch, setResetSeleccion, changeFilter, setCollapsed } from './store/serachReducer'
import Dialog from '../../../../views/componentes/librerias/bootstrap-dialog'
import {
  useButtonDisabled,
  useMenuOptions,
} from '../../../../hooks/useMenuButtonControls'
import LotesMain from './components/shared/main'
import { setMenuButtons } from './store/menuReducer'
import { consoleService } from '../../../../services/console.service'
import { EModulosAcatha } from '../../../../store/enumsAplication'
import { setModulo } from './store/configReducer'


const pathVerificador = '/lotes'

interface LotesProps extends React.PropsWithChildren { }

const Lotes: React.FunctionComponent<LotesProps> = () => {
  const dispatch = useDispatch()
  const menuState = useSelector((state: RootState) => state.global.menu)
  const tabs = useSelector((state: RootState) => {
    return state.inventarios.lotes.tabs
  })

  const searchStatus = useSelector((state: RootState) => {
    return state.inventarios.lotes.search.status
  })

  const searchResults = useSelector((state: RootState) => {
    return state.inventarios.lotes.search.resultados
  })
  const searchFilter = useSelector((state: RootState) => {
    return state.inventarios.lotes.search.filter
  })

  const selected = useSelector((state: RootState) => {
    return state.inventarios.lotes.search.seleccionado
  })
  const dialogRef = React.useRef<any>(null)
  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(
      addToast({
        title: 'Inventario-Lotes',
        content: mensaje,
        type: tipo,
      }),
    )
  }, [dispatch])
  const getButtonDisabled = useButtonDisabled(tabs)
  const { getMenuOptions, options } = useMenuOptions()

  const playLoader = React.useCallback(
    (message = 'Cargando...') => {
      dispatch(changeLoaderKardex({ show: true, mensaje: message }))
    },
    [dispatch],
  )

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoaderKardex({ show: false, mensaje: '' }))
  }, [dispatch])

  const onTabClosing = React.useCallback(() => { }, [])

  const onTabChanged = React.useCallback(
    (tabKey: string) => {
      dispatch(changeCurrentTab(tabKey))
    },
    [dispatch],
  )

  const onCleanResultFilter = React.useCallback(() => {
    dispatch(setCleanResult())
    dispatch(setResetSeleccion())
    dispatch(changeFilter({
      ...searchFilter,
      items: null,
    }))
  }, [dispatch, searchFilter])

  const onSearch = React.useCallback(async () => {
    try {
      const toAny: any = fetchLots(searchFilter)
      const res = await dispatch(toAny)
      consoleService.log(res, '--res')
      if (res !== null && res !== undefined && res['payload']['error'] === false) {
        setToast(res['payload']['message'], ToastTypes.Success)
      } else {
        setToast(res['payload']['message'], ToastTypes.Danger)
      }
    } catch (error) {
      setToast(error, ToastTypes.Danger)
    }
  }, [dispatch, searchFilter, setToast])

  const onSearchTransactions = React.useCallback(async (codigoLote: number) => {
    try {
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'BUSQUEDA',
          buttonType: ButtonTypes.find,
        }),
      )
      playLoader()
      const toAny: any = fetchLotsTransactions(codigoLote)
      const res = await dispatch(toAny)
      stopLoader()
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'BUSQUEDA',
          buttonType: undefined,
        }),
      )
      consoleService.log(res, '--resTran')
      if (res !== null && res !== undefined && res['payload']['error'] === false) {
        setToast(res['payload']['message'], ToastTypes.Success)
        if (res['payload']?.auto && res['payload'].auto.length > 5) {
          dispatch(setCollapsed(false))
        }
      } else {
        setToast(res['payload']['message'], ToastTypes.Danger)
      }
      dispatch(changeTabSearch('Transacciones'))
    } catch (error) {
      stopLoader()
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'BUSQUEDA',
          buttonType: undefined,
        }),
      )
      setToast(error, ToastTypes.Danger)
    }
  }, [dispatch, setToast, playLoader, stopLoader])


  const onMenuButtonClick = React.useCallback(
    async (action: AccionMenu) => {
      switch (action.actionType) {
        case ButtonTypes.find:
          if (selected !== null && selected !== undefined && selected?.codigo) {
            await onSearchTransactions(selected?.codigo)
          } else {
            await onSearch()
          }
          break
        case ButtonTypes.broom:
          if (tabs.current === 'BUSQUEDA') onCleanResultFilter()
          break
        default:
          break
      }
    },
    [onCleanResultFilter, tabs, onSearch, onSearchTransactions, selected])

  const onGetNumberModulo = React.useCallback(() => {
    dispatch(setModulo(parseInt(EModulosAcatha.Lotes)))
  }, [dispatch])

  useEffect(() => {
    onGetNumberModulo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (menuState.length > 0 && options.length === 0) {
      getMenuOptions(menuState, 'to', pathVerificador)
    }
  }, [menuState, getMenuOptions, options])

  React.useEffect(() => {
    if (searchStatus === FETCH_STATUS.FAILED) {
      stopLoader()
      dispatch(
        addToast({
          title: 'Buscar Lotes',
          content: 'Error al realizar la búsqueda',
          type: ToastTypes.Danger,
        }),
      )
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'BUSQUEDA',
          buttonType: undefined,
        }),
      )
    }
    if (searchStatus === FETCH_STATUS.LOADING) {
      playLoader()
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'BUSQUEDA',
          buttonType: ButtonTypes.find,
        }),
      )
    }
    if (searchStatus === FETCH_STATUS.SUCCESS) {
      stopLoader()
      dispatch(
        setButtons({
          tabKey: 'BUSQUEDA',
          buttons: {
            Buscar: true,
            Limpiar: searchResults['auto'] && searchResults['auto'].length > 0,
          },
        }),
      )
    }
  }, [dispatch, searchStatus, playLoader, stopLoader, searchResults])

  const onActionsButtonsLoaded = React.useCallback((actions: Array<AccionMenu>) => {
    dispatch(setMenuButtons(actions));
  }, [dispatch]);

  const onSearchExecutingAction = React.useCallback((tabKey: string, type?: ButtonTypes) => {
    dispatch(setCurrentExecutingAction({
      tabKey: tabKey,
      buttonType: type
    }))
  }, [dispatch]);


  return (
    <>
      <div id={'inventarioLotes'}>
        <Dialog ref={dialogRef} />
      </div>
      <LotesMain
        menu={{ loading: false, accions: options }}
        tabs={tabs}
        onTabChanged={onTabChanged}
        codigoModulo={parseInt(EModulosAcatha.Lotes)}
        opciones={OpcionesModeulo}
        onActionsButtonsLoaded={onActionsButtonsLoaded}
        onMenuButtonClick={onMenuButtonClick}
        searching={searchStatus === FETCH_STATUS.LOADING}
        ontabClosing={onTabClosing}
        hayResultadosBusqueda={searchResults.length > 0}
        verificarPuntoVenta
        verificarAutorizacion
        onExecutingAction={onSearchExecutingAction}
        tipoAplicacion={Aplicacion.lotes}
      />
    </>
  )
}

export default React.memo(Lotes)

export const ModuleButtons: CustomButtons = {
  Buscar: true,
  Limpiar: false,
}

const OpcionesModeulo = [parseInt(EModulosAcatha.Lotes)];