import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ButtonTypes } from '../../../../../views/componentes/globalMenu/types'
import { StatesEdition, TabTypes } from '../../../../ventas/types/enums'
import { TabsState } from '../../../../../store/genericTabTypes'
import { CustomButtons } from '../../../../../store/types'

const initialState: TabsState<{}> = {
  current: 'BUSQUEDA',
  tabs: {
    BUSQUEDA: {
      id: 0,
      canClose: false,
      editStatus: StatesEdition.doesNotApply,
      globalButtonClick: ButtonTypes.none,
      tittle: 'Búsqueda',
      type: TabTypes.busqueda,
      imprimir: false,
      tabKey: 'BUSQUEDA',
      buttons: {
        Buscar: true,
        Limpiar: true,
      },
    },
  },
}

const verificadorPreciosSlice = createSlice({
  name: 'verificarPreciosTabs',
  initialState: initialState,
  reducers: {
    changeCurrentTab(state, action: PayloadAction<string>) {
      if (Object.prototype.hasOwnProperty.call(state.tabs, action.payload)) {
        state.current = action.payload
      }
    },
    setCurrentExecutingAction(
      state,
      action: PayloadAction<{
        tabKey: string
        buttonType: ButtonTypes | undefined
      }>,
    ) {
      const { buttonType, tabKey } = action.payload
      state.tabs[tabKey].currentExecutingAction = buttonType
    },
    setButtons(
      state,
      action: PayloadAction<{ tabKey: string; buttons: CustomButtons }>,
    ) {
      const { buttons, tabKey } = action.payload
      state.tabs[tabKey].buttons = buttons
      state.tabs[tabKey].currentExecutingAction = undefined
    },
    buttonClick(
      state,
      action: PayloadAction<{ tabKey: string; button: ButtonTypes }>,
    ) {
      state.tabs[action.payload.tabKey].globalButtonClick =
        action.payload.button
    },
    clearButtonClick(state, action: PayloadAction<string>) {
      if (Object.prototype.hasOwnProperty.call(state.tabs, action.payload)) {
        state.tabs[action.payload].globalButtonClick = ButtonTypes.none
      }
    },
  },
})

export const {
  changeCurrentTab,
  setCurrentExecutingAction,
  setButtons,
  buttonClick,
  clearButtonClick,
} = verificadorPreciosSlice.actions
export const tabsReducer = verificadorPreciosSlice.reducer
