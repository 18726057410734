import { CertificadoDetalle, CertificadoState } from './types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LoaderInfo } from '../../../../../store/types'

const initialState: CertificadoState = {
  modulo: 'backOffice',
  currentAction: 'Buscar',
  currentFunction: '',
  aplicacion: 'certificadosDigitales',
  detalleCertificado: [],
  loader: {
    show: false,
    mensaje: '',
  },
}

const certificadosDigitalesSlice = createSlice({
  name: 'certificadosDigitales',
  initialState: initialState,
  reducers: {
    setCurrentAccion(state, action: PayloadAction<string>) {
      state.currentAction = action.payload
    },
    setCurrentFunction(state, action: PayloadAction<string>) {
      state.currentFunction = action.payload
    },
    setDetalleCertificado(
      state,
      action: PayloadAction<Array<CertificadoDetalle>>,
    ) {
      state.detalleCertificado = action.payload
    },
    setResetDetalleCertificado(state) {
      state.detalleCertificado = []
    },
    changeLoaderCertificados(state, action: PayloadAction<LoaderInfo>) {
      state.loader = action.payload
    },
  },
})

export const {
  setCurrentAccion,
  setCurrentFunction,
  setDetalleCertificado,
  setResetDetalleCertificado,
  changeLoaderCertificados,
} = certificadosDigitalesSlice.actions

export const CertificadosReducer = certificadosDigitalesSlice.reducer
