import React from 'react'
import DataGrid, { Column, Editing, Button } from 'devextreme-react/data-grid';
import SelectBox from 'devextreme-react/select-box';
import { CButton } from '@coreui/react-pro';
import BlockUi from '../../../../../../views/componentes/librerias/block-ui';
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer';
import CustomCol from '../../../../../../views/componentes/colContainer';
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput';
import LoadingIndicator from '../../../../../../views/componentes/loadingindicator/loadingindicator';
import Modalform from '../../../../../../views/componentes/modalform';
import { atributosService } from '../../../../../../services/atributos.service';


export type TAtributos = {
  valor: string;
  atributo: string;
  idAtributoValor: number;
  nueva: boolean
};

export type TAtributosOptions = {
  id: number;
  des: string;
};

interface IAtributos {
  onChange: (data) => void,
  show: boolean,
  onClose: () => void,
  atributos: Array<TAtributos>
  setToast: (texto, type) => void
}

const AtributosLocal: React.FunctionComponent<IAtributos> = (props) => {

  const { show, onClose, atributos, setToast, onChange } = props;

  const dataGrid = React.useRef<any>();

  const [resultados, setResultados] = React.useState<any>([]);
  const [atributosOptions, setAtributosOptions] = React.useState<Array<TAtributosOptions>>([]);
  const [atributosSelect, setAtributosSelect] = React.useState<TAtributosOptions | null>(null);


  const [valorOptions, setValorOptions] = React.useState<Array<TAtributosOptions>>([]);
  const [valorSelect, setValorSelect] = React.useState<TAtributosOptions | null>(null);
  const [atributosEliminados, setAtributosEliminados] = React.useState<Array<TAtributos>>([]);
  const [loaderLocal, setLoaderLocal] = React.useState<{ show: boolean, message: string }>({ show: false, message: "" });
  const [atributosActualizar, setAtributosActualizar] = React.useState<{ eliminar: Array<TAtributos> | [], agregar: Array<TAtributos> | [] } | {}>({});

  const onRowDoubleClick = React.useCallback((e) => {
    const data = { ...e.data };
  }, []);

  const onSelectionDetalleChanged = React.useCallback(async (selectedRowsData) => {
    if (selectedRowsData['selectedRowsData'].length > 0) {

    }
  }, []);

  const onData = React.useCallback((data) => {
    const atr = [] as Array<TAtributos>;
    if (data && data.length > 0) {
      for (const item of data) {
        atr.push({
          ...item,
          nueva: false
        })
      }
    }
    setResultados(data)
  }, []);

  const llenarAtributos = React.useCallback(async () => {
    try {
      const atr = [] as Array<TAtributosOptions>;
      const data = await atributosService.getAtributos();
      if (data.auto && data.auto.length > 0) {
        for (const item of data.auto) {
          atr.push({
            id: item.codigo,
            des: item.descripcion,
          })
        }
      }
      setAtributosOptions(atr);
      return await atr;
    } catch (e) {
      console.error(e)
    }
  }, [])

  const llenarValoresAtributos = React.useCallback(async (atributo: TAtributosOptions) => {
    try {
      const atr = [] as Array<TAtributosOptions>;
      const data = await atributosService.getValoresAtributos(atributo.id);
      if (data.auto && data.auto.length > 0) {
        for (const item of data.auto) {
          atr.push({
            id: item.codigo,
            des: item.descripcion,
          })
        }
      }
      setValorOptions(atr);
      setValorSelect(null)
      return await atr;
    } catch (error) {
      console.error()
    }
  }, [])

  const onRowRemove = React.useCallback((editRowKey) => {

    const _atributosEliminados = atributosEliminados.slice(0);

    const _atributos = resultados.slice(0);
    if (editRowKey.row && editRowKey.row.rowIndex > -1) {
      _atributos.splice(editRowKey.row.rowIndex, 1);
      _atributosEliminados.push(resultados[editRowKey.row.rowIndex])
      setAtributosEliminados(_atributosEliminados);
      setResultados(_atributos)
    }

  }, [resultados, atributosEliminados])

  const addAtributos = React.useCallback(() => {

    if (atributosSelect === null) {
      setToast("Seleccionar el atributo que desea agregar", 'info')
      return false;
    }

    if (valorSelect === null) {
      setToast("Seleccionar el valor del atributo que desea agregar", 'info')
      return false;
    }

    if ((valorSelect && valorSelect.id !== undefined) && (atributosSelect && atributosSelect.id !== undefined)) {

      const filtrado = resultados.findIndex((item: any) => {
        return Number(item.idAtributo) === Number(atributosSelect.id);
      });

      if (filtrado > -1) {
        setToast("Ya existe un atributo del tipo " + atributosSelect.des + " agregado", 'info')
      } else {
        const _resultados = resultados.slice(0);
        _resultados.push({
          idAtributo: atributosSelect.id,
          atributo: atributosSelect.des,
          valor: valorSelect.des,
          idAtributoValor: valorSelect.id,
          nueva: true
        })
        setResultados(_resultados)
      }

    }

  }, [valorSelect, atributosSelect, resultados, setToast])

  const onSaveAtributos = React.useCallback(async () => {
    let contadorNuevos: number = 0;
    const nuevaAsignacion: any = [];
    for (const item of resultados) {
      if (item.nueva === true) {
        contadorNuevos += 1;
        nuevaAsignacion.push(item)
      }
    }

    if (atributosEliminados.length === 0 && contadorNuevos === 0) {
      setToast("No hay cambios para aplicar", "info");
      return false;
    } else {

      setLoaderLocal({ show: true, message: 'Cargando...' })

      try {
        // const data = await atributosService.atributosLocalInsertar(dataLocal);
        // setLoaderLocal({ show: false, message: '' })
        // if (data['error'] === true) {
        //   setToast(data['message'], "warning");
        // } else {
        //   onChange()
        //   setToast(data['message'], "success");
        // }
        const actualizarAtributos = { agregar: nuevaAsignacion, eliminar: atributosEliminados };
        setAtributosActualizar(actualizarAtributos)
        onChange(actualizarAtributos)
      } catch (error) {
        console.error(error);
        setLoaderLocal({ show: false, message: '' })
        setToast(error, "danger")
      }

    }

  }, [atributosEliminados, resultados, setToast, onChange])

  function Item(data) {
    return (
      <div className="custom-item" onClick={() => {
        setValorSelect(data)
      }} style={{ backgroundColor: String(atributosSelect?.des).toLocaleLowerCase().includes("color") ? String(data?.des) : "" }} >
        <div className="product-name">{data.des}  </div>
      </div>
    );
  }

  function ItemCell(data) {
    return (
      <div className="custom-item" style={{ backgroundColor: String(data.data.atributo).toLocaleLowerCase().includes("color") ? String(data.data.valor) : "" }}>
        <div className="product-name">{data.data.valor}</div>
      </div>
    );
  }

  const onChangeAtributos = React.useCallback((data) => {
    setValorSelect(data.value)
  }, []);

  React.useEffect(() => {
    onData(atributos)
    llenarAtributos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ContentBody = () => {
    return (
      <>
        <div id="configAtributos">
          <BlockUi tag="div" loader={LoadingIndicator} blocking={loaderLocal?.show ?? false} message={loaderLocal?.message ?? ""}  >
            <fieldset disabled={loaderLocal.show}>
              <RowContainer>
                <CustomCol xs='12'>

                  <RowContainer>
                    <CustomCol xs='12' md='12' lg='12'>
                      <Labeled
                        label='Atributo'
                      >
                        <SelectBox
                          width="100%"
                          dataSource={atributosOptions ?? []}
                          displayExpr="des"
                          searchEnabled={true}
                          searchMode={"contains"}
                          searchExpr={"des"}
                          searchTimeout={200}
                          minSearchLength={3}
                          showDataBeforeSearch={true}
                          placeholder="Seleccionar"
                          showClearButton={true}
                          value={atributosSelect}
                          onValueChanged={(data) => {
                            if (data.value) {
                              setAtributosSelect(data.value)
                              llenarValoresAtributos(data.value)
                            }
                          }}
                        />
                      </Labeled>
                    </CustomCol>
                  </RowContainer>


                  <RowContainer>
                    <CustomCol xs='12' md='12' lg='12'>
                      <Labeled
                        label='Valor'
                      >
                        <SelectBox
                          width="100%"
                          dataSource={valorOptions ?? []}
                          displayExpr="des"
                          searchEnabled={true}
                          searchMode={"contains"}
                          searchExpr={"des"}
                          searchTimeout={200}
                          minSearchLength={3}
                          showDataBeforeSearch={true}
                          placeholder="Seleccionar"
                          showClearButton={true}
                          value={valorSelect}
                          itemRender={Item}
                          onValueChanged={onChangeAtributos}
                        />
                      </Labeled>
                    </CustomCol>
                  </RowContainer>

                  <RowContainer>
                    <CustomCol xs='12' md='12' lg='12'>
                      <CButton
                        variant="outline"
                        size="sm"
                        color="primary"
                        onClick={() => {
                          addAtributos();
                        }}
                        className="m-1"
                      >
                        {"AGREGAR"}
                      </CButton>
                    </CustomCol>
                  </RowContainer>

                </CustomCol>
              </RowContainer>
              <RowContainer>
                <CustomCol xs='12'>
                  <div className='card'>
                    <div className='card-body'>
                      <DataGrid
                        ref={dataGrid}
                        selection={{ mode: 'single' }}
                        allowColumnResizing={true}
                        columnResizingMode={'nextColumn'}
                        focusedRowEnabled={true}
                        keyExpr='idAtributoValor'
                        focusedRowKey='idAtributoValor'
                        dataSource={resultados}
                        showBorders={true}
                        onRowDblClick={onRowDoubleClick}
                        onSelectionChanged={onSelectionDetalleChanged}
                      >
                        <Editing
                          mode="form"
                          allowDeleting={true}
                        />
                        <Column dataField='idAtributoValor' caption='Código' width='80px' allowEditing={false} visible={false} allowSearch={false} allowFiltering={false} />
                        <Column dataField='atributo' caption='Atributo' width='80px' allowEditing={false} visible={true} allowSearch={false} allowFiltering={false} />
                        <Column dataField='valor' caption='Valor' cellRender={ItemCell} width='80px' allowEditing={false} visible={true} allowSearch={false} allowFiltering={false} />
                        <Column type='buttons'>
                          <Button name='delete'
                            onClick={onRowRemove}
                            visible={true} />
                        </Column>
                      </DataGrid>

                    </div>
                  </div>
                </CustomCol>
              </RowContainer>
            </fieldset>
          </BlockUi >
        </div>
      </>
    )
  }

  const ContentFooter = () => {
    return (
      <>
        <RowContainer>
          <CustomCol xs='12' md='12' lg='12'>
            <CButton
              variant="outline"
              size="sm"
              color="primary"
              onClick={() => {
                onSaveAtributos();
              }}
            >
              {"GUARDAR"}
            </CButton>
          </CustomCol>
        </RowContainer>
      </>
    )
  }

  return (
    <Modalform
      name='modalAtributos'
      headerTitle={"Atributos"}
      childrenBody={ContentBody()}
      childrenFooter={ContentFooter()}
      closeOnBackdrop={false}
      show={show}
      onClose={() => onClose()}
      centered={true}
      size="sm"
      enableBackdroop={false}
    />
  );
}
export default AtributosLocal
