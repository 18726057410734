import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LoaderInfo } from '../../../../../../store/types'
import { CustomDictionary } from '../../../../../ventas/types/generics'
import { Cliente, FormaPagoModuloVentas } from '../../../../types/types'
import { DetalleVentaRedux } from '../../../ventas/types/detalleVenta'
import {
  DocumentoOrigenFacturacion,
  FacturacionEdicionPayload,
  FacturaDatosEdicion,
  TTotalesFactura,
} from '../../../ventas/types/types'
import { ventasClearDatosEdicion } from '../../../../store/reducers'

const initialState: CustomDictionary<FacturaDatosEdicion> = {}

const datosEdicionNotasDebitoSlice = createSlice({
  name: 'notasDebitoDatosEdicionSV',
  initialState: initialState,
  reducers: {
    ventasClearDatosEdicion,
    setDatosEdicion(
      state,
      action: PayloadAction<FacturacionEdicionPayload<FacturaDatosEdicion>>,
    ) {
      state[action.payload.key] = action.payload.data
    },
    updateTotales(
      state,
      action: PayloadAction<{ key: number | string; totales: TTotalesFactura }>,
    ) {
      state[action.payload.key].totales = action.payload.totales
    },
    updateDetalles(
      state,
      action: PayloadAction<{
        key: number | string
        detalles: Array<DetalleVentaRedux>
      }>,
    ) {
      state[action.payload.key].detalles = action.payload.detalles
    },
    updateCliente(
      state,
      action: PayloadAction<{ key: number | string; cliente: Cliente | null }>,
    ) {
      state[action.payload.key].cliente = action.payload.cliente
    },
    updateObservaciones(
      state,
      action: PayloadAction<{ key: number | string; observaciones: string }>,
    ) {
      state[action.payload.key].observaciones = action.payload.observaciones
    },
    updateMotivo(
      state,
      action: PayloadAction<{ key: number | string; motivo: string }>,
    ) {
      state[action.payload.key].motivoNotaCredito = action.payload.motivo
    },
    //Establecer datos edicion para un tab nuevo
    initDatosEdicion(
      state,
      action: PayloadAction<FacturacionEdicionPayload<FacturaDatosEdicion>>,
    ) {
      state[action.payload.key] = action.payload.data //prueba
    },
    setEditLoader(
      state,
      action: PayloadAction<{ key: number | string; info: LoaderInfo }>,
    ) {
      state[action.payload.key].loader = action.payload.info
    },
    updateDocumentoOrigen(
      state,
      action: PayloadAction<{
        key: number | string
        documento: DocumentoOrigenFacturacion
        detalles: Array<DetalleVentaRedux>
        cliente: Cliente
        formaPago?: FormaPagoModuloVentas
        formasPago?: Array<FormaPagoModuloVentas>
      }>,
    ) {
      state[action.payload.key].documentoOrigen = action.payload.documento
      state[action.payload.key].cliente = action.payload.cliente
      state[action.payload.key].detalles = action.payload.detalles
      state[action.payload.key].formaPago = action.payload.formaPago
        ? [action.payload.formaPago]
        : []
      state[action.payload.key].formasPago = action.payload.formasPago
        ? action.payload.formasPago
        : []
    },
    setMuestraError(
      state,
      action: PayloadAction<{
        key: number | string
        tieneError: boolean
        mensajeError: string
      }>,
    ) {
      state[action.payload.key].tieneError = action.payload.tieneError
      state[action.payload.key].mensajeError = action.payload.mensajeError
    },
    setClaveAccesoGuardada(
      state,
      action: PayloadAction<{
        key: string | number
        claveAcceso: string
        imprimir: boolean
      }>,
    ) {
      state[action.payload.key].claveAcceso = action.payload.claveAcceso
      state[action.payload.key].imprimir = action.payload.imprimir
    },
    addDetalle(
      state,
      action: PayloadAction<{
        key: number | string
        detalle: DetalleVentaRedux
      }>,
    ) {
      state[action.payload.key].detalles.push(action.payload.detalle)
    },
    setFormaPago(
      state,
      action: PayloadAction<{
        key: number | string
        formaPago: FormaPagoModuloVentas
      }>,
    ) {
      state[action.payload.key].formaPago = action.payload.formaPago
        ? [action.payload.formaPago]
        : []
    },
    setImprimir(
      state,
      action: PayloadAction<{ key: number | string; imprimir: boolean }>,
    ) {
      state[action.payload.key].imprimir = action.payload.imprimir
    },
    updateFechaEmision(
      state,
      action: PayloadAction<{ key: string | number; fechaEmision: string }>,
    ) {
      state[action.payload.key].fecha = action.payload.fechaEmision
    },

    updateRetenerRentaValorOnly(
      state,
      action: PayloadAction<{
        key: number | string
        retenerRentaValor: number
      }>,
    ) {
      state[action.payload.key].retenerRentaValor =
        action.payload.retenerRentaValor
    },
    updateIsRetenerRenta(
      state,
      action: PayloadAction<{
        key: number | string
        isRetenerRenta: boolean
      }>,
    ) {
      state[action.payload.key].isRetenerRenta = action.payload.isRetenerRenta
    },
    updateRetenerIvaValorOnly(
      state,
      action: PayloadAction<{
        key: number | string
        retenerIvaValor: number
      }>,
    ) {
      state[action.payload.key].retenerIvaValor = action.payload.retenerIvaValor
    },
    updateIsRetenerIva(
      state,
      action: PayloadAction<{
        key: number | string
        isRetenerIva: boolean
      }>,
    ) {
      state[action.payload.key].isRetenerIva = action.payload.isRetenerIva
    },
  },
})

export const {
  updateFechaEmision,
  initDatosEdicion,
  setDatosEdicion,
  updateTotales,
  updateDetalles,
  updateCliente,
  updateObservaciones,
  setEditLoader,
  ventasClearDatosEdicion: clearDatosEdicion,
  updateDocumentoOrigen,
  updateMotivo,
  setMuestraError,
  setClaveAccesoGuardada,
  addDetalle,
  setFormaPago,
  setImprimir,
  updateRetenerRentaValorOnly,
  updateIsRetenerRenta,
  updateRetenerIvaValorOnly,
  updateIsRetenerIva,
} = datosEdicionNotasDebitoSlice.actions
export const datosEdicionReducer = datosEdicionNotasDebitoSlice.reducer
