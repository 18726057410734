import { RequestHelper } from '../../../../helpers/requestHelper'

export const modalDetallesVenta = { getDetallesVenta, getLocal }

async function getDetallesVenta(dataObj: any): Promise<any> {
  try {
    const data: any = {
      codigo: dataObj.codigo,
      tipodoc: dataObj.tipoDoc,
    }
    const res = await RequestHelper.getAll<any>(
      'ventas',
      'ventas/getDetail',
      '',
      data,
    )
    return res
  } catch (error) {
    return error
  }
}

async function getLocal(codigo: number): Promise<any> {
  try {
    const data: any = {
      venta: codigo,
    }
    const res = await RequestHelper.getAll<any>(
      'ventas',
      'ventas/getLocal',
      '',
      data,
    )
    return res
  } catch (error) {
    return error
  }
}
