import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoaderInfo } from '../../../../../store/types';
import { CustomDictionary } from '../../../../ventas/types/generics'
import { AnexoEdicionPayload, AnexoTransacDatosEdicion, ComprasType, DatosAnuladas, DatosDetalleRetenciones, DatosRetenciones, DatosVenta, DatosVentaEstablecimiento, TipoATS } from "../types/types";
import { impuestosClearDatosEdicion } from "../store/reducer";

const initialState: CustomDictionary<AnexoTransacDatosEdicion> = {
}
const datosEdicionAnexoSlice = createSlice({
  name: 'anexoDatosEdicion',
  initialState: initialState,
  reducers: {
    impuestosClearDatosEdicion,
    setDatosEdicion(state, action: PayloadAction<AnexoEdicionPayload<AnexoTransacDatosEdicion>>) {
      state[action.payload.key] = action.payload.data;
    },
    updateRuc(state, action: PayloadAction<{ key: number | string, ruc: string }>) {
      state[action.payload.key].ruc = action.payload.ruc;
    },
    updateTypeAnnex(state, action: PayloadAction<{ key: number | string, typeAnnex: string }>) {
      state[action.payload.key].typeAnnex = action.payload.typeAnnex;
    },
    updateYear(state, action: PayloadAction<{ key: number | string, year: string }>) {
      state[action.payload.key].year = action.payload.year;
    },
    updateMonth(state, action: PayloadAction<{ key: number | string, month: string }>) {
      state[action.payload.key].month = action.payload.month;
    },
    updateSemester(state, action: PayloadAction<{ key: number | string, semester: string }>) {
      state[action.payload.key].semester = action.payload.semester;
    },
    updateTypeATS(state, action: PayloadAction<{ key: number | string, tipoAts: TipoATS | null }>) {
      state[action.payload.key].tipoAts = action.payload.tipoAts;
    },
    updateCompras(state, action: PayloadAction<{ key: number | string, compras: Array<ComprasType> | [] }>) {
      state[action.payload.key].compras = action.payload.compras;
    },
    updateRetenciones(state, action: PayloadAction<{ key: number | string, retenciones: Array<DatosRetenciones> | [] }>) {
      state[action.payload.key].retenciones = action.payload.retenciones;
    },
    updateDetalleRetenciones(state, action: PayloadAction<{ key: number | string, detalleRetenciones: Array<DatosDetalleRetenciones> | [] }>) {
      state[action.payload.key].detalleRetenciones = action.payload.detalleRetenciones;
    },
    updateVentas(state, action: PayloadAction<{ key: number | string, ventas: Array<DatosVenta> | [] }>) {
      state[action.payload.key].ventas = action.payload.ventas;
    },
    updateVentasEstablecimiento(state, action: PayloadAction<{ key: number | string, ventasEstablecimiento: Array<DatosVentaEstablecimiento> | [] }>) {
      state[action.payload.key].ventasEstablecimiento = action.payload.ventasEstablecimiento;
    },
    updateAnulados(state, action: PayloadAction<{ key: number | string, anulados: Array<DatosAnuladas> | [] }>) {
      state[action.payload.key].anulados = action.payload.anulados;
    },
    //Establecer datos edicion para un tab nuevo
    initDatosEdicion(state, action: PayloadAction<AnexoEdicionPayload<AnexoTransacDatosEdicion>>) {
      if (!state[action.payload.key]) {
        state[action.payload.key] = action.payload.data;
      }
    },
    setEditLoader(state, action: PayloadAction<{ key: number | string, info: LoaderInfo }>) {
      state[action.payload.key].loader = action.payload.info;
    },
    setMuestraError(state, action: PayloadAction<{ key: number | string, tieneError: boolean, mensajeError: string }>) {
      state[action.payload.key].tieneError = action.payload.tieneError;
      state[action.payload.key].mensajeError = action.payload.mensajeError;
    },
    setImprimir(state, action: PayloadAction<{ key: number | string, imprimir: boolean }>) {
      state[action.payload.key].imprimir = action.payload.imprimir;
    },
  },
})


export const {
  impuestosClearDatosEdicion: clearDatosEdicion,
  initDatosEdicion, setDatosEdicion,
  updateYear, updateMonth, updateSemester, updateTypeATS, updateAnulados,
  updateCompras, updateDetalleRetenciones, updateVentas, updateVentasEstablecimiento,
  setMuestraError, setImprimir
} = datosEdicionAnexoSlice.actions;
export const datosEdicionReducer = datosEdicionAnexoSlice.reducer;