export async function getIndexProvider(
  provider: Array<any>,
  key: string,
  value: string | number,
) {
  const filtrado: number = provider.findIndex((item: any) => {
    return item[key] === value
  })
  return filtrado
}
