import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  FiltrosConceptosMovState,
  BusquedaState,
  Movimiento,
  Maximos,
} from '../types/types'
import { FETCH_STATUS } from '../../../../../../store/types'
import { conceptsServices } from '../services/conceptos.services'

const initialState: BusquedaState<Movimiento> = {
  filter: {
    descripcion: '',
    tipo: null,
    initial: true
  },
  maxs: {
    maxIngresos: 0,
    maxEgresos: 0,
    maxTrans: 0,
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  resultados: [],
  colapsed: true,
  seleccionado: null,
}

export const fetchConcepts = createAsyncThunk<
  Array<Movimiento>,
  FiltrosConceptosMovState
>('inventarios/movimientos/conceptos/listar', async (filtro) => {
  try {
    const conceptsList = conceptsServices.getConcepts(filtro.tipo !== null ? filtro.tipo?.codigo : -1, filtro?.descripcion ?? '')
    return conceptsList
  } catch (error) {
    return Promise.reject(error)
  }
})

const searchSlice = createSlice({
  name: 'buscarConceptosMov',
  initialState: initialState,
  reducers: {
    setCleanResult(state) {
      state.resultados = []
    },
    changeFilter(state, action: PayloadAction<FiltrosConceptosMovState>) {
      state.filter.tipo = action.payload.tipo
      state.filter.descripcion = action.payload.descripcion
      state.filter.initial = action.payload.initial
    },
    changeMaxs(state, action: PayloadAction<Maximos>) {
      state.maxs.maxIngresos = action.payload.maxIngresos
      state.maxs.maxEgresos = action.payload.maxEgresos
      state.maxs.maxTrans = action.payload.maxTrans
    },
    setCollapsed(state, action: PayloadAction<boolean>) {
      state.colapsed = action.payload
    },
    setResetSeleccion(state) {
      state.seleccionado = null
    },
    setSeleccionarDato(state, action: PayloadAction<Movimiento>) {
      state.seleccionado = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchConcepts.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchConcepts.fulfilled, (state, { payload }) => {
      state.resultados = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchConcepts.rejected, (state, { payload }) => {
      state.resultados = []
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
  },
})

export const { changeFilter, setCleanResult, setCollapsed, setResetSeleccion, setSeleccionarDato, changeMaxs } = searchSlice.actions

export const searchReducer = searchSlice.reducer
