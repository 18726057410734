import { RequestHelper } from '../../../../../../helpers/requestHelper'
import { GuardarConceptoMov } from '../types/types'

export const conceptsServices = {
  getConcepts,
  setConcept,
  deleteCOncept,
}

async function getConcepts(tipoCodigo: number, descripcion: string): Promise<any> {
  try {
    const obj: any = {
      tipo: tipoCodigo,
      descripcion
    }

    const data = await RequestHelper.getAll<any>('inventario/movimientos', 'conceptos/getAll', '', obj)
    return data
  } catch (error) {
    return error
  }
}
async function setConcept(requestBody: GuardarConceptoMov): Promise<any> {
  try {
    const obj: any = {
      ...requestBody
    }

    const data = await RequestHelper.postAll<any>('inventario/movimientos', 'conceptos/save', obj)
    return data
  } catch (error) {
    return error
  }
}

async function deleteCOncept(performanceCode: number): Promise<any> {
  return Promise.resolve()
}
