import { CustomDictionary, TabsState, TabState } from '../types/generics'

export const ventasClearDatosEdicion = (state, action) => {
  delete state[action.payload]
}

const getNewTabKeyOnClose = (state: TabsState<any>, keyToClose: string) => {
  let newCurrent = ''
  for (const key in state.tabs) {
    if (newCurrent === '') {
      newCurrent = key
    }
    if (keyToClose === key) {
      return newCurrent
    }
  }
  return 'BUSQUEDA'
}

export const ventasCloseTab = (state, action) => {
  const newCurrent = getNewTabKeyOnClose(state, action.payload)
  state.current = newCurrent
  delete state.tabs[action.payload]
}

export const existeTab = (
  tabs: CustomDictionary<TabState<any>>,
  comprobante: number,
) => {
  for (const key in tabs) {
    const tab = tabs[key]
    if (tab.id === comprobante) return key
  }
}
