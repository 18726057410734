import { combineReducers } from '@reduxjs/toolkit'
import { editDataReducer } from './editDataReduce'
import { menuReducer } from './menuReducer'
import { searchGuideReducer } from './searchReducer'
import { tabsReducer } from './tabsReducer'
import { configurationsReducer } from './configurationsGuideReducer'
import { GuideState } from '../types/types'

export const guideRemitionSVReducer = combineReducers<GuideState>({
 menu: menuReducer,
 tabs: tabsReducer,
 search: searchGuideReducer,
 editData: editDataReducer,
 configurations: configurationsReducer,
})
