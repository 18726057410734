import { ENetworkMessages, ENetworkStates } from './enum/requestType'

export const resolveMessages = {
  errorNetwork,
}

function errorNetwork(input: any): { error: boolean; message: string } {
  if (input) {
    const mesagges: Error = new Error(input)

    if (mesagges?.message) {
      let isError = false
      let sms = ''

      let status = mesagges?.message
      let enumStatus: ENetworkStates = ENetworkStates.errorConexion
      status = status.replace(/Error\s*:/g, '')
      if (Object.values(ENetworkStates).includes(status as ENetworkStates)) {
        enumStatus = ENetworkStates[status as keyof typeof ENetworkStates]
      }

      switch (enumStatus) {
        case ENetworkStates.errorSolicitud:
          isError = true
          sms = ENetworkMessages.errorSolicitud
          break

        case ENetworkStates.errorConexion:
          isError = true
          sms = ENetworkMessages.errorConexion
          break

        case ENetworkStates.BadRequest: // 400
          isError = true
          sms = ENetworkMessages.error400
          break

        case ENetworkStates.Unauthorized: // 401
          isError = true
          sms = ENetworkMessages.error401
          break

        case ENetworkStates.Forbidden: // 403
          isError = true
          sms = ENetworkMessages.error403
          break

        case ENetworkStates.NotFound: // 404
          isError = true
          sms = ENetworkMessages.error404
          break

        case ENetworkStates.MethodNotAllowed: // 405
          isError = true
          sms = ENetworkMessages.error405
          break

        case ENetworkStates.InternalServerError: // 500
          isError = true
          sms = ENetworkMessages.error500
          break

        case ENetworkStates.BadGateway: // 502
          isError = true
          sms = ENetworkMessages.error502
          break

        default:
          isError = true
          sms = ENetworkMessages.errorDefault
      }

      return {
        error: isError,
        message: sms,
      }
    }
  }
  return { error: false, message: '' }
}
