import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FETCH_STATUS } from '../../../../../store/types'
import {
  DocumentosListado,
  FiltrosDocumentosCtasXPagar,
  SearchState,
} from '../types/types'

const initialState: SearchState<DocumentosListado> = {
  filter: {
    proveedor: null,
    nombre: '',
    puntoVenta: null,
    proyecto: null,
    local: null,
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  resultados: [],
}

const searchDocumentosSlice = createSlice({
  name: 'searchDocumentos',
  initialState: initialState,
  reducers: {
    setCleanResult(state, action) {
      state = {
        ...action.payload,
        resultados: [],
      }
      state.resultados = []
    },
    changeFilter(state, action: PayloadAction<FiltrosDocumentosCtasXPagar>) {
      state.filter.proveedor = action.payload.proveedor
      state.filter.nombre = action.payload.nombre
      state.filter.puntoVenta = action.payload.puntoVenta
      state.filter.proyecto = action.payload.proyecto
      state.filter.local = action.payload.local
    },
    cleanFilter(state) {
      state.filter.proveedor = null
      state.filter.nombre = '',
        state.filter.puntoVenta = null
      state.filter.proyecto = null
      state.filter.local = null
    },
  },
})

export const { changeFilter, setCleanResult, cleanFilter } = searchDocumentosSlice.actions

export const searchDocumentsToPayReducer = searchDocumentosSlice.reducer
