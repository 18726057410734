import React from 'react'
import { CustomButtons } from '../../../../../../store/types'

interface IImporatrProps extends React.PropsWithChildren {
  tabId: string
  tab: any
}

const Importar: React.FunctionComponent<IImporatrProps> = (props) => {

  return (
    <>IMportar</>
  );
}
export const ModuleButtonsImport: CustomButtons = {
  Nuevo: false,
  Guardar: true,
  Buscar: true,
  Deshacer: true,
  Editar: false,
  Imprimir: false,
  Importar: true,
}
export default React.memo(Importar)
