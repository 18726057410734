import React, { useState } from 'react'
import { CCard, CCardBody, CCardHeader, CCol, CRow, CCollapse, CLink, CButton, CTooltip } from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cuentasService } from '../../services/cuentas.service'
import { ToastTypes } from '../../../../../../store/types'
import { useDispatch, useSelector } from 'react-redux'
import Tabla from '../../components/tabla'
import {
  setCargarCuentaContable,
  setData,
  setResetSeleccion,
  setCurrentFunction,
  setFormularioBusqueda,
  setDataApi,
} from '../../store/diarioReducer'
import { setModal } from '../../../planCuentas/store/cuentasReducer'
import { RootState } from '../../../../../../store/store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ValidationGroup from 'devextreme-react/validation-group'
import { DateBox, Button as DateBoxButton } from 'devextreme-react/date-box'
import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box'
import { CheckBox } from 'devextreme-react/check-box'
import { DateUtils, formatoFechasApi } from '../../../../../../helpers/dateUtils'
import {
  setTiposOptions,
  setPeriodosContables
} from './../../../../general/store/generalReducers'
import { Lookup } from 'devextreme-react/lookup'
import { isMobileOnly } from 'react-device-detect'
import { cilChevronBottom, cilChevronTop } from '@coreui/icons'
import './buscar.scss'
import { Local, Project } from '../../../../../shared/types/types'
import ProjectsLookUp from '../../../../../../containers/component/sharedProviders/projets/projectsLookUp/projectsLookUp'

type BuscarProps = {
  actionDelete: () => void
  exportExcel: () => void
  closeReport?: () => void
  reporte?: any
  returnFormularioBusqueda: (dataSearch) => void
  returnAction: () => void
  setToast: (text: string, type: string | ToastTypes) => void
  playLoader: () => void
  stopLoader: () => void
}

const Buscar = (props: BuscarProps) => {
  const {
    returnFormularioBusqueda,
    returnAction,
    setToast,
    playLoader,
    stopLoader,
  } = props

  const dispatch = useDispatch()
  const validationGroupRef = React.useRef<any>()
  const localSession = useSelector((state: RootState) => {
    return state.global.session?.local
  })

  const cargarCuentaContable = useSelector(
    (state: RootState) => state.contabilidad.diarioGeneral.cargarCuentaContable,
  )
  const periodosContables = useSelector(
    (state: RootState) => state.contabilidad.general.periodosContables,
  )
  const tipoAsiento = useSelector(
    (state: RootState) => state.contabilidad.general.tipoAsiento,
  )

  const registros = useSelector(
    (state: RootState) => state.contabilidad.diarioGeneral.registros,
  )
  const [registrosOriginales, setRegistrosOriginales] = useState<Array<any>>([])
  const currentActionState = useSelector(
    (state: RootState) => state.contabilidad.diarioGeneral.currentAction,
  )
  const currentFunction = useSelector(
    (state: RootState) => state.contabilidad.diarioGeneral.currentFunction,
  )

  const [DESCRIPCION, setDESCRIPCION] = useState<any>('')
  const [REFERENCIA, setREFERENCIA] = useState<any>('')
  const [collapsed, setCollapsed] = React.useState(true)
  const [ASI_DIARIONRO, setASI_DIARIONRO] = React.useState<any>('')
  const [TIPO, setTIPO] = React.useState<any>('0')
  const [ASI_NASIENTO, setASI_NASIENTO] = React.useState<any>('')
  const [ASI_INTERNO, setASI_INTERNO] = React.useState<any>('')
  const [IMPRESION, setIMPRESION] = React.useState<any>(false)
  const [IDIOMA, setIDIOMA] = React.useState<any>(false)
  const [ELIMINADOS, setELIMINADOS] = React.useState<any>(false)
  const [IS_LOCALCC, setIS_LOCALCC] = React.useState<any>(false)
  const [COD_CUENTA, setCOD_CUENTA] = React.useState<any>('')
  const [NUM_CUENTA, setNUM_CUENTA] = React.useState<any>('')
  const [DES_CUENTA, setDES_CUENTA] = React.useState<any>('')
  const [COD_LOCALCC, setCOD_LOCALCC] = React.useState<Local | null>(null)
  const [contadorAsientos, setContadorAsientos] = React.useState<any>('0')
  const [ASI_FECHA_D, setASI_FECHA_D] = React.useState<Date>(new Date())
  const [ASI_FECHA_H, setASI_FECHA_H] = React.useState<Date>(new Date())
  const [ASI_FECHA_MAX, setASI_FECHA_MAX] = React.useState<any>(
    DateUtils.getCurrentDateAsString(),
  )
  const [dpPeriodos, setDpPeriodos] = React.useState<Array<any>>([])
  const [dpTiposAsientos, setDpTiposAsientos] = React.useState<Array<any>>([])
  const [dpLocales, setDpLocales] = React.useState<Array<any>>([])
  const [proyecto, setProyecto] = React.useState<Project | null>(null)

  const generarOptionsTipos = React.useCallback((data) => {
    const tiposObtenidos: any = []
    data.map(function (key) {
      tiposObtenidos.push({
        value: String(key['llave']),
        label: key['descripcion'],
      })
    })
    return { cant: tiposObtenidos.length, data: tiposObtenidos }
  }, [])

  const procesarResultado = React.useCallback(
    async (asientosDiario) => {
      const asientosDiario_object: any = []
      let contadorAsientosFunc = 0

      Object.keys(asientosDiario).map((key: any) => {
        let to_array_asientosDiario = []
        if (asientosDiario_object.length > 0) {
          if (
            Number(asientosDiario[key]['numeroAsiento']) ===
            Number(asientosDiario_object[key - 1]['COD'])
          ) {
            let cuentaShow = ''
            if (asientosDiario[key]['debeTransaccion'] > 0) {
              cuentaShow =
                IDIOMA === false
                  ? asientosDiario[key]['descripcionPlanCuentas']
                  : asientosDiario[key]['idiomaDescripcion']
            } else {
              cuentaShow =
                IDIOMA === false
                  ? asientosDiario[key]['descripcionPlanCuentas']
                  : asientosDiario[key]['idiomaDescripcion']
            }
            to_array_asientosDiario = {
              ...asientosDiario[key],
              sessionID: '',
              fechaAUD: '',
              numeroAsiento: '',
              fecha: '',
              tipo: '',
              tipoNumero: '',
              ORDEN: contadorAsientosFunc,
              COD: Number(asientosDiario[key]['numeroAsiento']),
              SHOW_CUENTA: cuentaShow,
            }
          } else {
            let cuentaShow = ''
            if (asientosDiario[key]['debeTransaccion'] > 0) {
              cuentaShow =
                IDIOMA === false
                  ? asientosDiario[key]['descripcionPlanCuentas']
                  : asientosDiario[key]['idiomaDescripcion']
            } else {
              cuentaShow =
                IDIOMA === false
                  ? asientosDiario[key]['descripcionPlanCuentas']
                  : asientosDiario[key]['idiomaDescripcion']
            }
            to_array_asientosDiario = {
              ...asientosDiario[key],
              ORDEN: contadorAsientosFunc,
              COD: Number(asientosDiario[key]['numeroAsiento']),
              SHOW_CUENTA: cuentaShow,
            }
            contadorAsientosFunc++
          }
        } else {
          let cuentaShow = ''
          if (asientosDiario[key]['debeTransaccion'] > 0) {
            cuentaShow =
              IDIOMA === false
                ? asientosDiario[key]['descripcionPlanCuentas']
                : asientosDiario[key]['idiomaDescripcion']
          } else {
            cuentaShow =
              IDIOMA === false
                ? asientosDiario[key]['descripcionPlanCuentas']
                : asientosDiario[key]['idiomaDescripcion']
          }
          to_array_asientosDiario = {
            ...asientosDiario[key],
            ORDEN: contadorAsientosFunc,
            COD: Number(asientosDiario[key]['numeroAsiento']),
            SHOW_CUENTA: cuentaShow,
          }
          contadorAsientosFunc++
        }
        asientosDiario_object.push(to_array_asientosDiario)
      })

      setContadorAsientos(contadorAsientosFunc)
      dispatch(setData(asientosDiario_object))
    },
    [IDIOMA, dispatch],
  )

  const obteneTipoAsientos = React.useCallback(async () => {
    try {
      const data = await cuentasService.obtenerTipoAsientos(
        'Elije tipo de asiento',
      )
      if (data['error'] === false) {
        if (data['auto'].length > 0) {
          const resTipos: any = await generarOptionsTipos(data['auto'])
          if (resTipos['cant'] > 0) {
            dispatch(setTiposOptions(resTipos['data']))
            setDpTiposAsientos(resTipos['data'])
            setTIPO(resTipos['data'][0])
          }
        }
      } else {
        setToast(data['message'], 'warning')
      }
      return await data
    } catch (error) {
      setToast(JSON.stringify(error), 'danger')
    }
  }, [dispatch, generarOptionsTipos, setToast])

  const llenarDataPeriodos = React.useCallback((data) => {
    const cuentas: any = []
    const periodos = data
    Object.keys(periodos).map(function (key) {
      return cuentas.push({
        value: Number(periodos[key]['diario']),
        label: Number(periodos[key]['anio']),
        detalle: periodos[key],
      })
    })
    return { cant: cuentas.length, data: cuentas }
  }, [])

  const obtenerRangoPeriodos = React.useCallback((detalle) => {
    let finicio: any = ''
    let fcierre: any = ''
    if (detalle['detalle']['fechaInicio'] !== null) {
      finicio = DateUtils.strDateToDate(
        detalle['detalle']['fechaInicio'],
        formatoFechasApi,
      )
    }
    if (detalle['detalle']['fechaCierre'] !== null) {
      fcierre = DateUtils.strDateToDate(
        detalle['detalle']['fechaCierre'],
        formatoFechasApi,
      )
    }
    setASI_FECHA_D(finicio)
    setASI_FECHA_H(fcierre)
    setASI_FECHA_MAX(fcierre)
  }, [])

  const llenarCombosTiposAsientos = React.useCallback((data) => {
    if (data.length > 0) {
      setDpTiposAsientos(data)
      setTIPO(data[0])
    }
  }, [])

  const obtenerPeriodosContables = React.useCallback(async () => {
    try {
      const data = await cuentasService.obtenerPeriodosContables()
      if (data['error'] === false) {
        if (data['auto'].length > 0) {
          const resPeriodos: any = await llenarDataPeriodos(data['auto'])
          if (resPeriodos['cant'] > 0) {
            dispatch(setPeriodosContables(resPeriodos['data']))
            setDpPeriodos(resPeriodos['data'])
            if (currentActionState === 'Buscar') {
              if (resPeriodos['data'].length > 0) {
                setASI_DIARIONRO(resPeriodos['data'][0])
                obtenerRangoPeriodos(resPeriodos['data'][0])
              }
            }
          }
        }
      } else {
        setToast(data['message'], 'warning')
      }
      return await data
    } catch (error) {
      setToast(JSON.stringify(error), 'danger')
    }
  }, [
    currentActionState,
    dispatch,
    llenarDataPeriodos,
    obtenerRangoPeriodos,
    setToast,
  ])

  const llenarCombosPeriodos = React.useCallback(
    async (data) => {
      if (currentActionState === 'Buscar') {
        setDpPeriodos(data)
        if (data.length > 0) {
          setASI_DIARIONRO(data[0])
          obtenerRangoPeriodos(data[0])
        }
      }
    },
    [currentActionState, obtenerRangoPeriodos],
  )

  const openModalCuentas = React.useCallback(() => {
    dispatch(
      setModal({
        modalName: 'modalPlan',
        open: true,
        codModulo: null,
      }),
    )
  }, [dispatch])

  const aplicarEnterFecha = React.useCallback(
    async (data) => {
      const { component } = data
      if (component._changedValue) {
        returnAction()
      }
    },
    [returnAction],
  )

  const onValueChangedDiario = React.useCallback(
    (evt: any) => {
      if (evt !== undefined) {
        const selectedValue = evt || periodosContables[0]
        setASI_DIARIONRO(selectedValue)
        obtenerRangoPeriodos(selectedValue)
      }
    },
    [obtenerRangoPeriodos, periodosContables],
  )

  const onValueChangedTipo = React.useCallback(
    (e: any) => {
      if (e !== undefined) {
        const selectedValue = e || dpTiposAsientos[0]
        setTIPO(selectedValue)
      }
    },
    [dpTiposAsientos],
  )

  const onValueChangedCC = React.useCallback(
    (e: any) => {
      const selectedValue = e || dpLocales[0]
      setCOD_LOCALCC(selectedValue)
    },
    [dpLocales],
  )

  const onValueChangedNumCuenta = React.useCallback((evt: any) => {
    if (evt !== undefined) {
      setNUM_CUENTA(evt)
    }
  }, [])

  const handleReset = React.useCallback(() => {
    setDESCRIPCION('')
    setREFERENCIA('')
    setIMPRESION(false)
    setIDIOMA(false)
    setELIMINADOS(false)
    setNUM_CUENTA('')
    setASI_NASIENTO('')
    setASI_INTERNO('')
    setIS_LOCALCC(false)
    setContadorAsientos(0)
    if (periodosContables.length <= 0) {
      obtenerPeriodosContables()
    } else {
      llenarCombosPeriodos(periodosContables)
    }
    if (tipoAsiento.length <= 0) {
      obteneTipoAsientos()
    } else {
      llenarCombosTiposAsientos(tipoAsiento)
    }
  }, [
    llenarCombosTiposAsientos,
    llenarCombosPeriodos,
    obteneTipoAsientos,
    obtenerPeriodosContables,
    periodosContables,
    tipoAsiento,
  ])

  const onSubmit = React.useCallback(async () => {
    if (dpPeriodos.length === 0) {
      setToast('Seleccione un periodo por favor.', 'info')
      return false
    }
    setRegistrosOriginales([])
    dispatch(setResetSeleccion())
    dispatch(setData([]))
    playLoader()
    const data_request = {
      periodo: Number(ASI_DIARIONRO?.value) ?? -1,
      desde: DateUtils.dateToString(ASI_FECHA_D, 'dd/MM/yyyy'),
      hasta: DateUtils.dateToString(ASI_FECHA_H, 'dd/MM/yyyy'),
      estado: ELIMINADOS === false ? 0 : 2,
      local: Number(COD_LOCALCC?.codigo) ?? -1,
      tipo: TIPO?.value ?? -1,
      numero: ASI_INTERNO,
      cuentaCodigo: COD_CUENTA,
      nrodocumento: IS_LOCALCC === true ? -1 : 0,
      descripcion: DESCRIPCION,
      fecha: REFERENCIA,
      habilitado: true,
      nrotipo: ASI_NASIENTO,
    }
    try {
      const data = await cuentasService.obtenerRegistros(data_request)
      stopLoader()

      if (data['error'] === true) {
        setToast(data['message'], 'warning')
      } else {
        setToast(data['message'], 'success')
        if (isMobileOnly) {
          setCollapsed(false)
        }
        if (data['auto'].length > 0) {
          const item: Array<any> = data['auto'].map(function (item: any) {
            return {
              ...item,
            }
          })
          setRegistrosOriginales(item)
          procesarResultado(item)
        } else {
          setContadorAsientos(0)
        }
      }
      return await data
    } catch (error) {
      stopLoader()
      setToast(JSON.stringify(error), 'danger')
    }
  }, [
    ASI_DIARIONRO,
    ASI_FECHA_D,
    ASI_FECHA_H,
    ASI_INTERNO,
    COD_CUENTA,
    COD_LOCALCC,
    DESCRIPCION,
    ELIMINADOS,
    IS_LOCALCC,
    REFERENCIA,
    TIPO,
    dispatch,
    dpPeriodos,
    playLoader,
    procesarResultado,
    setToast,
    stopLoader,
    ASI_NASIENTO,
  ])

  const onChangeProyectoLook = React.useCallback((v) => {
    setProyecto(v)
    if (v.locales) {
      setDpLocales(v.locales ?? [])
      setCOD_LOCALCC(v?.locales[0])
    }
  }, [])

  React.useEffect(() => {
    dispatch(setDataApi(registrosOriginales))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registrosOriginales])

  React.useEffect(() => {
    returnFormularioBusqueda({
      ASI_FECHA_D,
      ASI_FECHA_H,
      TIPO,
      proyecto,
      COD_LOCALCC,
      COD_CUENTA,
      NUM_CUENTA,
      DES_CUENTA,
      ASI_DIARIONRO,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    ASI_FECHA_D,
    ASI_FECHA_H,
    TIPO,
    proyecto,
    COD_LOCALCC,
    COD_CUENTA,
    NUM_CUENTA,
    DES_CUENTA,
    ASI_DIARIONRO,
  ])

  React.useEffect(() => {
    if (currentActionState === 'Buscar') {
      handleReset()
      if (periodosContables.length <= 0) {
        obtenerPeriodosContables()
      } else {
        llenarCombosPeriodos(periodosContables)
      }
      if (tipoAsiento.length <= 0) {
        obteneTipoAsientos()
      } else {
        llenarCombosTiposAsientos(tipoAsiento)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentActionState, periodosContables, tipoAsiento])

  React.useEffect(() => {
    if (currentFunction === 'Buscar') {
      dispatch(setCurrentFunction(''))
      onSubmit()
    } else if (currentFunction === 'Deshacer') {
      dispatch(setCurrentFunction(''))
      handleReset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFunction])

  React.useEffect(() => {
    dispatch(
      setFormularioBusqueda({
        ASI_FECHA_D: DateUtils.dateToString(ASI_FECHA_D, 'dd/MM/yyyy'),
        ASI_FECHA_H: DateUtils.dateToString(ASI_FECHA_H, 'dd/MM/yyyy'),
        ASI_DIARIONRO: ASI_DIARIONRO.value ?? 0,
        porhoja: IMPRESION,
        idioma: IDIOMA,
        numDiario: ASI_DIARIONRO.value ?? 0,
        descripcionAsiento: '',
        numeroAsiento: 0,
        fechaInicio: DateUtils.dateToString(ASI_FECHA_D, 'dd/MM/yyyy'),
        fechaCierre: DateUtils.dateToString(ASI_FECHA_H, 'dd/MM/yyyy'),
      }),
    )
  }, [ASI_FECHA_D, ASI_FECHA_H, ASI_DIARIONRO, IMPRESION, IDIOMA, dispatch])

  React.useEffect(() => {
    if (cargarCuentaContable.codigo !== 0) {
      setCOD_CUENTA(cargarCuentaContable['codigo'])
      setNUM_CUENTA(cargarCuentaContable['numero'])
      setDES_CUENTA(cargarCuentaContable['descripcion'])
      dispatch(
        setCargarCuentaContable({
          codigo: 0,
          numero: '',
          descripcion: '',
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cargarCuentaContable])

  React.useEffect(() => {
    if (currentActionState === 'Editar') {
      if (periodosContables.length > 0) {
        setASI_DIARIONRO(periodosContables[0]['value'])
      }
      if (tipoAsiento.length > 0) {
        setASI_DIARIONRO('0')
      }
    } else if (currentActionState === 'Nuevo') {
      dispatch(setResetSeleccion())
      handleReset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentActionState, tipoAsiento, periodosContables])

  return (
    <>
      <CCard>
        <CCardHeader onClick={() => setCollapsed(!collapsed)} style={{ display: 'flex', justifyContent: 'space-between' }}>
          <strong> {'Buscar'} </strong>
          <CLink className="card-header-action">
            <CIcon icon={collapsed ? cilChevronBottom : cilChevronTop} />
          </CLink>
        </CCardHeader>
        <CCollapse visible={collapsed}>
          <CCardBody>
            <ValidationGroup ref={validationGroupRef}>
              <CRow>
                <CCol lg="12" md="12" sm="12">
                  <div className="dx-field m-1">
                    <CRow>
                      <CCol lg="6" sm="12">
                        <CRow>
                          <CCol>
                            <div className="dx-field m-1">
                              <div className="dx-field-label">{'Diario Nro:'}</div>
                              <div className="dx-field-value ">
                                <Lookup
                                  width="100%"
                                  dataSource={dpPeriodos}
                                  displayExpr="label"
                                  searchEnabled={true}
                                  searchMode={'contains'}
                                  searchExpr={'label'}
                                  searchTimeout={200}
                                  minSearchLength={3}
                                  showDataBeforeSearch={true}
                                  placeholder="Seleccionar"
                                  showClearButton={true}
                                  defaultValue={ASI_DIARIONRO}
                                  value={ASI_DIARIONRO}
                                  onValueChange={onValueChangedDiario}
                                />
                              </div>
                            </div>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol lg="12" md="12" sm="12">
                            <div className="dx-field m-1">
                              <div className="dx-field-label">{'Rango:'}</div>
                              <div className="dx-field-value">
                                <CRow>
                                  <CCol lg="6" md="6" sm="12">
                                    <DateBox
                                      displayFormat="dd/MM/yyyy"
                                      value={ASI_FECHA_D}
                                      stylingMode="outlined"
                                      onValueChange={(data) => setASI_FECHA_D(data)}
                                      max={ASI_FECHA_MAX}
                                      onEnterKey={(data) => {
                                        aplicarEnterFecha(data)
                                      }}
                                    >
                                      <DateBoxButton
                                        name="prevDate"
                                        location="before"
                                        options={{
                                          icon: 'spinprev',
                                          stylingMode: 'text',
                                          onClick: () => {
                                            const x = DateUtils.add(ASI_FECHA_D, { days: -1 })
                                            setASI_FECHA_D(x)
                                          },
                                        }}
                                      />
                                      <DateBoxButton
                                        name="nextDate"
                                        location="after"
                                        options={{
                                          icon: 'spinnext',
                                          stylingMode: 'text',
                                          onClick: () => {
                                            const x = DateUtils.add(ASI_FECHA_D, { days: 1 })
                                            setASI_FECHA_D(x)
                                          },
                                        }}
                                      />
                                      <DateBoxButton name="dropDown" />
                                    </DateBox>
                                  </CCol>
                                  <CCol lg="6" md="6" sm="12">
                                    <DateBox
                                      displayFormat="dd/MM/yyyy"
                                      value={ASI_FECHA_H}
                                      stylingMode="outlined"
                                      onValueChange={(data) => setASI_FECHA_H(data)}
                                      max={ASI_FECHA_MAX}
                                      onEnterKey={(data) => {
                                        aplicarEnterFecha(data)
                                      }}
                                    >
                                      <DateBoxButton
                                        name="prevDate"
                                        location="before"
                                        options={{
                                          icon: 'spinprev',
                                          stylingMode: 'text',
                                          onClick: () => {
                                            const x = DateUtils.add(ASI_FECHA_H, { days: -1 })
                                            setASI_FECHA_H(x)
                                          },
                                        }}
                                      />
                                      <DateBoxButton
                                        name="nextDate"
                                        location="after"
                                        options={{
                                          icon: 'spinnext',
                                          stylingMode: 'text',
                                          onClick: () => {
                                            const x = DateUtils.add(ASI_FECHA_H, { days: 1 })
                                            setASI_FECHA_H(x)
                                          },
                                        }}
                                      />
                                      <DateBoxButton name="dropDown" />
                                    </DateBox>
                                  </CCol>
                                </CRow>
                              </div>
                            </div>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol>
                            <div className="dx-field m-1">
                              <div className="dx-field-label">{'Tipo:'}</div>
                              <div className="dx-field-value ">
                                <Lookup
                                  width="100%"
                                  dataSource={dpTiposAsientos}
                                  displayExpr="label"
                                  searchEnabled={true}
                                  searchMode={'contains'}
                                  searchExpr={'label'}
                                  searchTimeout={200}
                                  minSearchLength={3}
                                  showDataBeforeSearch={true}
                                  placeholder="Seleccionar"
                                  showClearButton={true}
                                  defaultValue={TIPO}
                                  value={TIPO}
                                  onValueChange={onValueChangedTipo}
                                  onEnterKey={(data) => {
                                    aplicarEnterFecha(data)
                                  }}
                                />
                              </div>
                            </div>
                          </CCol>
                        </CRow>
                      </CCol>
                    </CRow>

                    <CRow>
                      <CCol lg="6" sm="12">
                        <div className="dx-field m-1">
                          <div className="dx-field-label">{'Número:'}</div>
                          <div className="dx-field-value">
                            <TextBox
                              width="100%"
                              value={ASI_NASIENTO}
                              placeholder=""
                              onValueChange={(data) => {
                                setASI_NASIENTO(data)
                              }}
                              onEnterKey={onSubmit}
                            />
                          </div>
                        </div>
                      </CCol>
                      <CCol lg="6" sm="12">
                        <div className="dx-field m-1">
                          <div className="dx-field-label">{'Interno:'}</div>
                          <div className="dx-field-value">
                            <TextBox
                              width="100%"
                              value={ASI_INTERNO}
                              placeholder=""
                              onValueChange={(data) => {
                                setASI_INTERNO(data)
                              }}
                              onEnterKey={onSubmit}
                            />
                          </div>
                        </div>
                      </CCol>
                    </CRow>

                    <CRow>
                      <CCol lg="6" sm="12">
                        <div className="dx-field m-1">
                          <div className="dx-field-label">{'Descripción:'}</div>
                          <div className="dx-field-value">
                            <TextBox
                              width="100%"
                              value={DESCRIPCION}
                              placeholder=""
                              onValueChange={(data) => {
                                setDESCRIPCION(data)
                              }}
                              onEnterKey={onSubmit}
                            />
                          </div>
                        </div>
                      </CCol>
                    </CRow>

                    <CRow>
                      <CCol lg="6" sm="12">
                        <div className="dx-field m-1">
                          <div className="dx-field-label">{'Referencia:'}</div>
                          <div className="dx-field-value">
                            <TextBox
                              width="100%"
                              value={REFERENCIA}
                              placeholder=""
                              onValueChange={(data) => {
                                setREFERENCIA(data)
                              }}
                              onEnterKey={onSubmit}
                            />
                          </div>
                        </div>
                      </CCol>
                    </CRow>

                    <CRow>
                      <CCol lg="6" sm="12">
                        <div className="dx-field m-1">
                          <div className="dx-field-label">{'Cuenta:'}</div>
                          <div className="dx-field-value">
                            <TextBox
                              width="100%"
                              value={NUM_CUENTA + ' ' + DES_CUENTA}
                              placeholder=""
                              onValueChange={onValueChangedNumCuenta}
                            >
                              <TextBoxButton
                                name="agregarCuenta"
                                location="after"
                                options={{
                                  icon: 'plus',
                                  text: '',
                                  onClick: () => openModalCuentas(),
                                }}
                              />
                              <TextBoxButton
                                name="limpiarCuenta"
                                location="after"
                                options={{
                                  icon: 'trash',
                                  text: '',
                                  onClick: () => {
                                    setDES_CUENTA('')
                                    setNUM_CUENTA('')
                                    setCOD_CUENTA('')
                                  },
                                }}
                              />
                            </TextBox>
                          </div>
                        </div>
                      </CCol>
                      <CCol lg="6" sm="12">
                        <div className="dx-field-value d-flex align-items-start">
                          <CheckBox
                            width={100}
                            text="Impresión por hoja"
                            value={IMPRESION}
                            onValueChange={() => setIMPRESION(!IMPRESION)}
                          />{' '}
                          <CheckBox width={100} text="Idioma Alterno" value={IDIOMA} onValueChanged={() => setIDIOMA(!IDIOMA)} />{' '}
                          <CheckBox width={100} text="Eliminados" value={ELIMINADOS} onValueChanged={() => setELIMINADOS(!ELIMINADOS)} />
                        </div>
                      </CCol>
                    </CRow>

                    <CRow>
                      <CCol lg="6" md="6" sm="12">
                        <div className="dx-field m-1">
                          <div className="dx-field-label">{'Proyectos:'}</div>
                          <div className="dx-field-value ">
                            <ProjectsLookUp
                              addDefaultPosition={'General'}
                              onChanged={(data) => onChangeProyectoLook(data)}
                              selected={proyecto ?? null}
                            />
                          </div>
                        </div>
                      </CCol>
                      <CCol lg="6" md="6" sm="12"></CCol>
                    </CRow>

                    <CRow>
                      <CCol lg="6" md="6" sm="12">
                        <div className="dx-field m-1">
                          <div className="dx-field-label">{'Lugar o CC:'}</div>
                          <div className="dx-field-value ">
                            <Lookup
                              width="100%"
                              dataSource={dpLocales}
                              displayExpr="nombre"
                              searchEnabled={true}
                              searchMode={'contains'}
                              searchExpr={'nombre'}
                              searchTimeout={200}
                              minSearchLength={3}
                              showDataBeforeSearch={true}
                              placeholder="Seleccionar"
                              showClearButton={true}
                              defaultValue={COD_LOCALCC}
                              value={COD_LOCALCC}
                              onValueChange={onValueChangedCC}
                            />
                          </div>
                        </div>
                      </CCol>
                      <CCol lg="6" md="6" sm="12">
                        <div className="dx-field-value  d-flex align-items-start">
                          <CheckBox
                            width={100}
                            text="CC no es un Proyecto"
                            onValueChange={() => setIS_LOCALCC(!IS_LOCALCC)}
                            value={IS_LOCALCC}
                          />
                        </div>
                      </CCol>
                    </CRow>
                  </div>
                </CCol>
              </CRow>
            </ValidationGroup>
          </CCardBody>
        </CCollapse>
      </CCard>

      <CRow className="infoButton">
        <CCol md="1" xs="12" className="text-right">
          <CRow>
            <CCol lg="12">
              <span>
                <CTooltip placement={'top'} content={'Selecciona una fila para poder leer la observación del asiento '}>
                  <CButton id={'infoAsiento'} color="info" size="sm">
                    <FontAwesomeIcon size="lg" icon={['fal', 'info']} style={{ fontWeight: 1 }} />
                  </CButton>
                </CTooltip>
              </span>
            </CCol>
            <CCol className="float-right">
              <strong>{contadorAsientos}</strong>
            </CCol>
          </CRow>
        </CCol>
      </CRow>

      <CRow>
        <CCol>
          <Tabla
            name="TablaDiarioGeneral"
            data={registros}
            actionDelete={() => props.actionDelete()}
            exportExcel={() => props.exportExcel()}
          />
        </CCol>
      </CRow>
      {/* {props.reporte &&
        <CustomModalDevx
          show={props.reporte !== null}
          onClose={() => { if (props.closeReport) props.closeReport() }}
          title='Reporte'
          width={window.innerWidth - (window.innerWidth * 0.1)}
          height={window.innerHeight - (window.innerHeight * 0.1)}
        >
          <ReportWithLocalData
            data={ExportarExcelService.transformDataAsientosToDevexpressReport(registrosOriginales)}
            fileName='StaticDiarioGeneralListado'
            mode={props.reporte ?? 'Viewer'}
            parameters={{ 'Reporte_Filtro': "getFilterString()" }}
            template='StaticDiarioGeneralListado'
            key='reportDesigner'
          >
          </ReportWithLocalData>
        </CustomModalDevx>
      } */}
    </>
  )
}

export default React.memo(Buscar)
