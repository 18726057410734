import React, { useState, useEffect } from 'react';
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
} from '@coreui/react-pro'
import { cuentasService } from "../../../services/cuentas.service"
import { addToast } from '../../../../../../../store/toasterReducer';
import { ToastTypes } from '../../../../../../../store/types';
import { useDispatch, useSelector } from 'react-redux'
import { changeLoader } from '../../../../../../../store/reducers';
import { setCurrentFunction, setCurrentAccion, setResetSeleccion, setData }
  from "./../../store/modalCuentasReducer"
import { RootState } from '../../../../../../../store/store'
import { TextBox } from "devextreme-react/text-box";
import ValidationSummary from "devextreme-react/validation-summary";
import { Validator, RequiredRule, AsyncRule, StringLengthRule } from "devextreme-react/validator";
import RadioGroup from "devextreme-react/radio-group";
import ValidationGroup from 'devextreme-react/validation-group';
import validations from './../../../../../../../config/validations'
import { setTiposCuenta, setCuentasOptionsBackup, setCuentasOptions, setCategoriasOptions, setCuentasFlujoOptions } from "../../../../../general/store/generalReducers"
import { Lookup } from 'devextreme-react/lookup';

const sms = validations["es"];

type NuevoProps = {
}

const operacionesGrupos = [
  {
    value: -1,
    label: "Resta",
  },
  {
    value: 1,
    label: "Suma",
  },
];

const estadosGrupos = [
  {
    value: 1,
    label: "Activo",
  },
  {
    value: 2,
    label: "Inactivo",
  },
];

const Nuevo = (props: NuevoProps) => {

  const dispatch = useDispatch()
  const validationGroupRef = React.useRef<any>();

  //const loaderShow = useSelector((state: RootState) => state.global.loader.show);
  const tipoCuenta = useSelector((state: RootState) => state.contabilidad.general.tipoCuenta);
  const categorias = useSelector((state: RootState) => state.contabilidad.general.categorias);
  const cuentasFlujoOptions = useSelector((state: RootState) => state.contabilidad.general.cuentasFlujoOptions);
  const cuentasPertenecen = useSelector((state: RootState) => state.contabilidad.general.cuentasPertenecen);
  const cuentasPertenecenBackup = useSelector((state: RootState) => state.contabilidad.general.cuentasPertenecenBackup);

  const currentActionState = useSelector((state: RootState) => state.contabilidad.modalCuentas.currentAction);
  const currentFunction = useSelector((state: RootState) => state.contabilidad.modalCuentas.currentFunction);
  const seleccionado = useSelector((state: RootState) => state.contabilidad.modalCuentas.seleccionado);

  const [TIPO, setTIPO] = useState<any>("-1");
  const [TIPOAUX, setTIPOAUX] = useState<any>("-1");
  const [CATEGORIA, setCATEGORIA] = useState<any>("-1");
  const [CATEGORIAAUX, setCATEGORIAAUX] = useState<any>("-1");
  const [PERTENECE, setPERTENECE] = useState<any>("-1");
  const [PERTENECEAUX, setPERTENECEAUX] = useState<any>("-1");
  const [CODIGO, setCODIGO] = useState<number>(0);
  const [NUMERO, setNUMERO] = useState<any>("");
  const [CODIGOFISCAL, setCODIGOFISCAL] = useState<any>("");
  const [DESCRIPCION, setDESCRIPCION] = useState<any>("");
  const [DESCIDIOMA, setDESCIDIOMA] = useState<any>("");
  const [OPERACION, setOPERACION] = useState<any>(operacionesGrupos[1]);
  const [FLUJOEFECTIVO, setFLUJOEFECTIVO] = useState<any>("");
  const [FLUJOEFECTIVOAUX, setFLUJOEFECTIVOAUX] = useState<any>("");
  const [ESTADO, setESTADO] = useState<any>(estadosGrupos[0]);
  const [dbtipos, setDbTipos] = useState<Array<any>>([]);
  const [dbCategorias, setDbCategorias] = useState<Array<any>>([]);
  const [dbPertenecen, setDbPertenecen] = useState<Array<any>>([]);
  const [dbCuentasFlujo, setDbCuentasFlujo] = useState<Array<any>>([]);

  useEffect(() => {
    if (currentFunction === "Guardar") {
      dispatch(setCurrentFunction(""));
      handleSubmit();
    } else if (currentFunction === "Deshacer") {
      dispatch(setCurrentFunction(""))
      if (currentActionState === "Nuevo") {
        loadAllCombos();
        handleReset();
      } else if (currentActionState === "Editar") {
        copiarDatosToFormulario();
      }
    }
  }, [currentFunction, currentActionState]);

  useEffect(() => {
    loadAllCombos()
  }, []);

  useEffect(() => {
    if (currentActionState === "Editar") {
      copiarDatosToFormulario();
    } else if (currentActionState === "Nuevo") {
      dispatch(setResetSeleccion())
      handleReset()
    }
  }, [currentActionState, tipoCuenta]);

  const copiarDatosToFormulario = () => {
    setCODIGO(Number(seleccionado['row']['codigo']))
    setDESCRIPCION(seleccionado['row']['descripcion'])
    setDESCIDIOMA(seleccionado['row']['descripcionIdioma'])
    setESTADO(Number(seleccionado['row']['estado']))
    setCODIGOFISCAL(seleccionado['row']['fiscal'])
    setNUMERO(seleccionado['row']['numero'])

    setCATEGORIAAUX(seleccionado['row']['codigoCategoria'])
    setPERTENECEAUX(String(seleccionado['row']['padre']))
    setFLUJOEFECTIVOAUX(seleccionado['row']['numeroPLF'])
    setTIPOAUX(seleccionado['row']['codigoTipo'])

    const itemsOperacion = operacionesGrupos.filter((data) => {
      if (Number(data.value) === Number(seleccionado["row"]["operacion"])) {
        return data;
      }
    });

    if (itemsOperacion.length > 0) {
      setOPERACION(itemsOperacion[0])
    }

    const itemsEstado = estadosGrupos.filter((data) => {
      if (Number(data.value) === Number(seleccionado["row"]["estado"])) {
        return data;
      }
    });

    if (itemsEstado.length > 0) {
      setESTADO(itemsEstado[0])
    }
  }

  useEffect(() => {
    if (dbtipos.length > 0) {
      filtrarTipoFormulario(TIPOAUX, dbtipos);
    }
  }, [TIPOAUX, dbtipos]);

  useEffect(() => {
    if (dbCategorias.length > 0) {
      filtrarCategoriaFormulario(CATEGORIAAUX, dbCategorias);
    }
  }, [CATEGORIAAUX, dbCategorias]);

  useEffect(() => {
    if (dbPertenecen.length > 0) {
      filtrarPerteneceFormulario(PERTENECEAUX, dbPertenecen);
    }
  }, [PERTENECEAUX, dbPertenecen]);

  useEffect(() => {
    if (dbCuentasFlujo.length > 0) {
      filtrarCFlujoFormulario(FLUJOEFECTIVOAUX, dbCuentasFlujo);
    }
  }, [FLUJOEFECTIVOAUX, dbCuentasFlujo]);


  const setToast = (texto, type) => {
    let tipo: ToastTypes = ToastTypes.Info;
    if (type === 'warning') {
      tipo = ToastTypes.Warning;
    } else if (type === 'success') {
      tipo = ToastTypes.Success;
    } if (type === 'info') {
      tipo = ToastTypes.Info;
    } if (type === 'danger') {
      tipo = ToastTypes.Danger;
    }
    dispatch(addToast({
      id: '',
      autoHide: 3500,
      title: 'Acatha',
      content: texto,
      fade: true,
      type: tipo
    }));
  }

  const playLoader = () => {
    dispatch(changeLoader({ show: true, mensaje: 'Cargando...' }))
  }

  const stopLoader = () => {
    dispatch(changeLoader({ show: false, mensaje: '' }))
  }

  const loadAllCombos = () => {
    if (tipoCuenta.length === 0) {
      obtenerOptionsTipos();
    } else {
      cargarComboTipos()
    }
    if (categorias.length === 0) {
      obtenerOptionsCategorias();
    } else {
      cargarComboCategorias()
    }
    if (cuentasPertenecen.length === 0) {
      obtenerCuentasPertenecen();
    } else {
      cargarComboPertenecen()
    }
    if (cuentasFlujoOptions.length === 0) {
      obtenerCuentasFlujo();
    } else {
      cargarComboFuentasFlujo()
    }
  }

  const handleReset = () => {
    setCODIGO(0)
    setDESCRIPCION("")
    setNUMERO("")
    setCODIGOFISCAL("")
    setDESCIDIOMA("")
    setESTADO(estadosGrupos[0])
    setOPERACION(operacionesGrupos[1])
  }

  const filtrarTipoFormulario = async (filTipo, data) => {
    const itemsData = await data.findIndex((item: any) => {
      return Number(item.value) === Number(filTipo);
    });
    if (itemsData !== -1) {
      setTIPO(data[itemsData])
    } else {
      setTIPO(data[0])
    }
  }

  const filtrarCategoriaFormulario = (filcategoria, data) => {
    const itemsData = data.findIndex((item: any) => {
      return Number(item.value) === Number(filcategoria);
    });
    if (itemsData !== -1) {
      setCATEGORIA(data[itemsData])
    } else {
      setCATEGORIA(data[0])
    }
  }

  const filtrarPerteneceFormulario = (filPertenece, data) => {
    const itemsData = data.findIndex((item: any) => {
      return Number(item.value) === Number(filPertenece);
    });
    if (itemsData !== -1) {
      setPERTENECE(data[itemsData])
    } else {
      setPERTENECE(data[0])
    }
  }

  const filtrarCFlujoFormulario = (filPertenece, data) => {
    const itemsData = data.findIndex((item: any) => {
      return String(item.value) === String(filPertenece);
    });
    if (itemsData !== -1) {
      setFLUJOEFECTIVO(data[itemsData])
    } else {
      setFLUJOEFECTIVO(data[0])
    }
  }

  const handleSubmit = async () => {
    const result = validationGroupRef.current.instance.validate();
    if (result.isValid) {
      playLoader()
      const data_request = {
        pla_categoria: CATEGORIA['value'],
        pla_codigo: Number(CODIGO),
        pla_descidioma: DESCIDIOMA,
        pla_descripcion: DESCRIPCION,
        pla_estado: Number(ESTADO['value']),
        pla_fiscal: CODIGOFISCAL,
        pla_numero: NUMERO,
        pla_operacion: Number(OPERACION['value']),
        pla_padre: Number(PERTENECE['value']),
        plf_numero: FLUJOEFECTIVO['value'],
        tip_codigo: Number(TIPO['value']),
      }
      try {
        const data = await cuentasService.crearRegistro(data_request);
        stopLoader()
        if (data['error'] === true) {
          setToast(data['message'], "warning");
        } else {
          if (currentActionState === "Editar") {
            setToast(data['message'], "success");
            dispatch(setData([]));
          } else {
            setToast(data['message'], "success");
          }
          dispatch(setResetSeleccion())
          dispatch(setCurrentAccion("Buscar"))
        }
        return await data;
      } catch (error) {
        console.log('error', error);
        stopLoader()
        setToast(JSON.stringify(error), "danger");
      }
    } else {
      setToast("Tiene errores por favor verifíquelos.", "info");
    }
  }

  const obtenerCuentasFlujo = React.useCallback(async () => {
    try {
      const data = await cuentasService.obtenerCuentasFlujoOptions();
      if (data['error'] === false) {
        if (data['auto'].length > 0) {
          const cantCuentas: any = await llenarCuentasFlujo(data['auto']);
          if (cantCuentas['cant'] > 0) {
            if (currentActionState === "Nuevo") {
              setFLUJOEFECTIVO(cantCuentas['data'][0]);
            }
          }
        }
      }
      return await data;
    } catch (error) {
      console.log("error", error);
      setToast(JSON.stringify(error), "danger");
    }
  }, [])

  const llenarCuentasFlujo = React.useCallback(async (dataCuentasFlujo) => {
    if (dataCuentasFlujo.length > 0) {
      const cuentas: any = [];
      const cuentasFlujo = dataCuentasFlujo;
      Object.keys(cuentasFlujo).map(function (key, i) {
        return cuentas.push({
          value: String(cuentasFlujo[key]["numero"]),
          label: cuentasFlujo[key]["descripcion"],
        });
      });
      dispatch(setCuentasFlujoOptions(cuentas));
      setDbCuentasFlujo(cuentas);
      return { cant: cuentas.length, data: cuentas };
    }
  }, [])

  const obtenerCuentasPertenecen = React.useCallback(async () => {
    playLoader()
    const categoria: string = "Principales";
    try {
      const data = await cuentasService.obtenerCuentasOption(categoria);
      stopLoader()
      if (data["error"] === false) {
        if (data['auto'].length > 0) {
          const cantPertenecen: any = await obtenerOptionsPertenecen(data['auto'])
          if (cantPertenecen['cant'] > 0) {
            if (currentActionState === "Nuevo") {
              setPERTENECE(cantPertenecen['data'][0]);
            }
          }
        }
      }

      return await data;
    } catch (error) {
      stopLoader()
      console.log("error", error);
      setToast(JSON.stringify(error), "danger");
    }
  }, [])

  const obtenerOptionsPertenecen = React.useCallback(async (dataPertenecen) => {
    if (dataPertenecen.length > 0) {
      const cuentas_object: any = [];
      const cuentas = dataPertenecen;
      Object.keys(cuentas).map(function (key, i) {
        if (Number(cuentas[key]["codigo"]) === 0) {
          return cuentas_object.push({
            value: -1,
            label: cuentas[key]["descripcion"],
          });
        } else {
          return cuentas_object.push({
            value: Number(cuentas[key]["codigo"]),
            label:
              cuentas[key]["numero"] +
              "  " +
              cuentas[key]["descripcion"],
          });
        }

      });
      dispatch(setCuentasOptions(cuentas_object));
      dispatch(setCuentasOptionsBackup(cuentas));
      setDbPertenecen(cuentas_object);
      return { cant: cuentas_object.length, data: cuentas_object };
    }
  }, [])


  const obtenerOptionsCategorias = React.useCallback(async () => {
    const categoria: number = 60;
    try {
      const data = await cuentasService.obtenerCategorias(categoria);
      if (data['error'] === false) {
        if (data['auto'].length > 0) {
          const contCategorias: any = await llenarOptionsCategorias(data['auto'])
          if (contCategorias['cant'] > 0) {
            if (currentActionState === "Nuevo") {
              setCATEGORIA(contCategorias['data'][0]);
            }
          }
        }
      }
      return await data;
    } catch (error) {
      console.log("error", error);
      setToast(JSON.stringify(error), "danger");
    }
  }, [])

  const llenarOptionsCategorias = React.useCallback(async (dataCategorias) => {
    if (dataCategorias.length > 0) {
      const dpCategoria: any = [];
      const dataCategoria = dataCategorias;
      Object.keys(dataCategoria).map(function (key, i) {
        return dpCategoria.push({
          value: Number(dataCategoria[key]["SUB_CODIGO"]),
          label: dataCategoria[key]["SUB_VALOR"],
        });
      });
      dispatch(setCategoriasOptions(dpCategoria));
      setDbCategorias(dpCategoria);
      return { cant: dpCategoria.length, data: dpCategoria };
    }
  }, [])

  const obtenerOptionsTipos = React.useCallback(async () => {
    const categoria: number = 1;
    try {
      const data = await cuentasService.obtenerTipos(categoria);
      if (data["error"] === false) {
        if (data['auto'].length > 0) {
          const dataTipos = data['auto'];
          const cantTipos: any = await llenarTipos(dataTipos);
          if (cantTipos['cant'] > 0) {
            setTIPO(cantTipos['data'][0]);
          }
        }
      }
      return await data;
    } catch (error) {
      console.log("error", error);
      setToast(JSON.stringify(error), "danger");
    }
  }, [])

  const llenarTipos = React.useCallback(async (dataTipo) => {
    if (dataTipo.length > 0) {
      const tipoCuentas_object: any = [];
      const default_init = {
        value: Number(-1),
        label: "Tipo de cuenta",
      };
      tipoCuentas_object.push(default_init);
      const tipoCuentas = dataTipo;
      Object.keys(tipoCuentas).map(function (key, i) {
        return tipoCuentas_object.push({
          value: Number(tipoCuentas[key]["codigo"]),
          label: tipoCuentas[key]["descripcion"],
        });
      });
      dispatch(setTiposCuenta(tipoCuentas_object));
      setDbTipos(tipoCuentas_object);
      return { cant: tipoCuentas_object.length, data: tipoCuentas_object };
    }
  }, []);

  const cargarComboTipos = async () => {
    setDbTipos(tipoCuenta)
  }

  const cargarComboCategorias = async () => {
    setDbCategorias(categorias)
  }

  const cargarComboPertenecen = async () => {
    setDbPertenecen(cuentasPertenecen);
  }

  const cargarComboFuentasFlujo = async () => {
    setDbCuentasFlujo(cuentasFlujoOptions);
  }

  const filtrarTipos = async TIP_CODIGO => {
    playLoader();
    setNUMERO("");

    const default_init = {
      value: Number("-1"),
      label: "- Principales",
    };

    const perteneceA_filtro: any = [];
    if (Number(TIP_CODIGO) !== -1) {
      const items = cuentasPertenecenBackup.filter((data) => {
        if (Number(data.codigoTipo) === Number(TIP_CODIGO)) {
          return data;
        }
      });

      perteneceA_filtro.push(default_init);
      Object.keys(items).map(function (key, i) {
        if (Number(items[i]["codigo"]) === 0) {
          return perteneceA_filtro.push({
            value: -1,
            label:
              items[i]["descripcion"],
          });
        } else {
          return perteneceA_filtro.push({
            value: Number(items[i]["codigo"]),
            label:
              items[i]["numero"] + "  " + items[i]["descripcion"],
          });
        }
      });

      setPERTENECE(perteneceA_filtro[0]);
      setDbPertenecen(perteneceA_filtro);
      stopLoader();
    } else {
      const items = cuentasPertenecenBackup;

      perteneceA_filtro.push(default_init);
      Object.keys(items).map(function (key, i) {
        return perteneceA_filtro.push({
          value: Number(items[i]["codigo"]),
          label:
            items[i]["numero"] + "  " + items[i]["descripcion"],
        });
      });

      setPERTENECE(perteneceA_filtro[0]);
      setDbPertenecen(perteneceA_filtro);
      stopLoader();
    }
  }

  const obtenerNumeroCuenta = React.useCallback(async (CUENTA) => {
    playLoader();
    setNUMERO("");
    if (CUENTA !== null) {
      const account: number = CUENTA;
      try {
        const data = await cuentasService.obtenerCuentaNumero(account);
        stopLoader();
        if (data['error'] === false) {
          setNUMERO(String(data['auto']) ?? "");
        } else {
          setToast(data['message'], "warning");
        }
        return await data;
      } catch (error) {
        console.log("error", error);
        stopLoader();
        setToast(JSON.stringify(error), "danger");
      }
    } else {
      stopLoader();
    }
  }, [])

  const onChangePertenece = React.useCallback(async (ev) => {
    setNUMERO("");
    if (Number(ev.value) !== -1) {
      obtenerNumeroCuenta(ev.value);
    }
  }, [])

  const onChangeTipo = async (ev) => {
    let valSelected: any = "";
    if (ev) {
      valSelected = ev.value;
      filtrarTipos(valSelected);
    } else {
      valSelected = -1;
      filtrarTipos(valSelected);
    }
  }

  const validateAsyncSeleccion = (datoSeleccion) => {
    return verificarSeleccion(datoSeleccion);
  };

  const verificarSeleccion = (value) => {
    const validador = 0;
    return new Promise((resolve) => {
      setTimeout(function () {
        if (value["value"]["value"] !== undefined) {
          resolve(
            Number(value["value"]["value"]) > Number(validador) === true
              ? true
              : false
          );
        } else {
          resolve(true);
        }
      }, 1000);
    });
  };

  return (

    <CCard>
      <CCardHeader >
        <strong>{currentActionState}</strong>
      </CCardHeader>
      <CCardBody>


        <ValidationGroup ref={validationGroupRef}>
          <CRow>
            <CCol lg="8" md="8" sm="12">
              <div className="dx-field">
                <div className="dx-field-label">{"Tipo:"}</div>
                <div className="dx-field-value ">
                  <Lookup
                    width="100%"
                    dataSource={dbtipos}
                    displayExpr="label"
                    searchEnabled={true}
                    searchMode={"contains"}
                    searchExpr={"label"}
                    searchTimeout={200}
                    minSearchLength={3}
                    showDataBeforeSearch={true}
                    placeholder="Seleccionar"
                    showClearButton={true}
                    defaultValue={TIPO}
                    value={TIPO}
                    onValueChanged={(data) => {
                      if (data.value) {
                        setTIPO(data.value);
                        onChangeTipo(data.value)
                      } else {
                        setTIPO(dbtipos[0]);
                        onChangeTipo(data.value)
                      }
                    }}
                  >
                    <Validator>
                      <RequiredRule message={"Tipo " + sms["select"]} />
                      <AsyncRule
                        message={"Tipo " + sms["select"]}
                        validationCallback={validateAsyncSeleccion}
                      />
                    </Validator>
                  </Lookup>
                </div>
              </div>

              <div className="dx-field">
                <div className="dx-field-label">{"Categoría:"}</div>
                <div className="dx-field-value ">
                  <Lookup
                    width="100%"
                    dataSource={dbCategorias}
                    displayExpr="label"
                    searchEnabled={true}
                    searchMode={"contains"}
                    searchExpr={"label"}
                    searchTimeout={200}
                    minSearchLength={3}
                    showDataBeforeSearch={true}
                    placeholder="Seleccionar"
                    showClearButton={true}
                    defaultValue={CATEGORIA}
                    value={CATEGORIA}
                    onValueChanged={(data) => {
                      if (data.value) {
                        setCATEGORIA(data.value);
                      } else {
                        setCATEGORIA(dbCategorias[0]);
                      }
                    }}
                  >
                    <Validator>
                      <RequiredRule message={"Categoría " + sms["select"]} />
                      <AsyncRule
                        message={"Categoría " + sms["select"]}
                        validationCallback={validateAsyncSeleccion}
                      />
                    </Validator>
                  </Lookup>
                </div>
              </div>

              <div className="dx-field">
                <div className="dx-field-label">{"Pertenece a:"}</div>
                <div className="dx-field-value ">
                  <Lookup
                    width="100%"
                    dataSource={dbPertenecen}
                    displayExpr="label"
                    searchEnabled={true}
                    searchMode={"contains"}
                    searchExpr={"label"}
                    searchTimeout={200}
                    minSearchLength={3}
                    showDataBeforeSearch={true}
                    placeholder="Seleccionar"
                    showClearButton={true}
                    defaultValue={PERTENECE}
                    value={PERTENECE}
                    onValueChanged={(data) => {
                      if (data.value) {
                        setPERTENECE(data.value);
                        onChangePertenece(data.value)
                      } else {
                        setPERTENECE(dbPertenecen[0]);
                        //onChangePertenece(data.value)
                        setNUMERO("")
                      }
                    }}
                  >
                    <Validator>
                      <RequiredRule message={"Pertenece " + sms["select"]} />
                      <AsyncRule
                        message={"Pertenece " + sms["select"]}
                        validationCallback={validateAsyncSeleccion}
                      />
                    </Validator>
                  </Lookup>
                </div>
              </div>
              <div className="dx-field">
                <div className="dx-field-label">{"Número:"}</div>
                <div className="dx-field-value">
                  <TextBox
                    width={"100%"}
                    value={NUMERO}
                    placeholder=""
                    onValueChanged={(data) => {
                      setNUMERO(data.value);
                    }}
                  >
                    <Validator>
                      <RequiredRule message={"Número: " + sms["required"]} />
                      <StringLengthRule message={"Número: " + sms["max"].replace("%num_characters%", "30")} max={30} />
                      <StringLengthRule message={"Número: " + sms["min"].replace("%num_characters%", "1")} min={1} />
                    </Validator>
                  </TextBox>
                </div>
              </div>

              <div className="dx-field">
                <div className="dx-field-label">{"Descripción:"}</div>
                <div className="dx-field-value">
                  <TextBox
                    width={"100%"}
                    value={DESCRIPCION}
                    placeholder=""
                    onValueChanged={(data) => {
                      setDESCRIPCION(data.value);
                    }}
                  >
                    <Validator>
                      <RequiredRule message={"Descripción: " + sms["required"]} />
                      <StringLengthRule message={"Descripción: " + sms["max"].replace("%num_characters%", "75")} max={75} />
                      <StringLengthRule message={"Descripción: " + sms["min"].replace("%num_characters%", "3")} min={3} />
                    </Validator>
                  </TextBox>
                </div>
              </div>

              <div className="dx-field">
                <div className="dx-field-label">
                  {"Descripción Alterna:"}
                </div>
                <div className="dx-field-value">
                  <TextBox
                    width={"100%"}
                    value={DESCIDIOMA}
                    placeholder=""
                    onValueChanged={(data) => {
                      setDESCIDIOMA(data.value);
                    }}
                  >
                    <Validator>
                      <StringLengthRule message={"Descripción Alterna: " + sms["max"].replace("%num_characters%", "75")} max={75} />
                    </Validator>
                  </TextBox>
                </div>
              </div>

              <div className="dx-field">
                <div className="dx-field-label">
                  {"Operación"}
                </div>
                <div className="dx-field-value">
                  <RadioGroup
                    name="operacion"
                    dataSource={operacionesGrupos}
                    value={OPERACION}
                    layout="horizontal"
                    displayExpr="label"
                    onValueChanged={(e) => {
                      setOPERACION(e.value)
                    }}
                  />
                </div>
              </div>

              <div className="dx-field">
                <div className="dx-field-label">
                  {"Flujo de Efectivo:"}
                </div>
                <div className="dx-field-value ">
                  <Lookup
                    width="100%"
                    dataSource={dbCuentasFlujo}
                    displayExpr="label"
                    searchEnabled={true}
                    searchMode={"contains"}
                    searchExpr={"label"}
                    searchTimeout={200}
                    minSearchLength={3}
                    showDataBeforeSearch={true}
                    placeholder="Seleccionar"
                    showClearButton={true}
                    value={FLUJOEFECTIVO}
                    onValueChanged={(data) => {
                      if (data.value) {
                        setFLUJOEFECTIVO(data.value);
                      } else {
                        setFLUJOEFECTIVO(dbCuentasFlujo[0]);
                      }
                    }}
                  />
                </div>
              </div>

              <div className="dx-field">
                <div className="dx-field-label ">
                  {"Estado"}
                </div>
                <div className="dx-field-value ">
                  <RadioGroup
                    name="Estado"
                    dataSource={estadosGrupos}
                    value={ESTADO}
                    layout="horizontal"
                    displayExpr="label"
                    onValueChanged={(e) => {
                      setESTADO(e.value)
                    }}
                  />
                </div>
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol lg="6" md="8" sm="12">
              <ValidationSummary id="summary"></ValidationSummary>
            </CCol>
          </CRow>
        </ValidationGroup>


      </CCardBody>

    </CCard>


  );
}

export default React.memo(Nuevo);