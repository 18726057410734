import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LoaderInfo } from '../../../../../store/types'
import { CustomDictionary } from '../../../../ventas/types/generics'
import {
  TEstudioData,
  TLoginData,
  TPersonalesData,
  TUsuarioBasico,
  UsuarioDatosEdicion,
} from './types'
import { TConfEdicionPayload } from '../../store/types'
import { ventasClearDatosEdicion } from '../../../../ventas/store/reducers'

const initialState: CustomDictionary<UsuarioDatosEdicion> = {}
const datosEdicionConfUsuarioSlice = createSlice({
  name: 'UsuarioDatosEdicion',
  initialState: initialState,
  reducers: {
    ventasClearDatosEdicion,
    updateLoader(
      state,
      action: PayloadAction<{
        key: string
        loader: { mensaje: string; show: boolean }
      }>,
    ) {
      state[action.payload.key].loader.mensaje = action.payload.loader.mensaje
      state[action.payload.key].loader.show = action.payload.loader.show
    },
    setDatosEdicion(
      state,
      action: PayloadAction<TConfEdicionPayload<UsuarioDatosEdicion>>,
    ) {
      state[action.payload.key] = action.payload.data
    },
    //Establecer datos edicion para un tab nuevo
    initDatosEdicion(
      state,
      action: PayloadAction<TConfEdicionPayload<UsuarioDatosEdicion>>,
    ) {
      if (!state[action.payload.key]) {
        state[action.payload.key] = action.payload.data
      }
    },
    // setEditLoader(
    //   state,
    //   action: PayloadAction<{ key: number | string; info: LoaderInfo }>,
    // ) {
    //   state[action.payload.key].loader = action.payload.info
    // },
    setDatosEdicionLogin(
      state,
      action: PayloadAction<TConfEdicionPayload<TLoginData>>,
    ) {
      state[action.payload.key].login = action.payload.data
    },
    setDatosEdicionPersonal(
      state,
      action: PayloadAction<TConfEdicionPayload<TPersonalesData>>,
    ) {
      state[action.payload.key].personales = action.payload.data
    },
    setDatosEdicionEstudios(
      state,
      action: PayloadAction<TConfEdicionPayload<TEstudioData>>,
    ) {
      state[action.payload.key].estudios = action.payload.data
    },
    setChangeLoaderEdit(
      state,
      action: PayloadAction<TConfEdicionPayload<LoaderInfo>>,
    ) {
      state[action.payload.key].loader = action.payload.data
    },
    setUsuarioBasicoDatosEdicion(
      state,
      action: PayloadAction<TConfEdicionPayload<TUsuarioBasico>>,
    ) {
      state[action.payload.key].usuarioBasico = action.payload.data
    },
    setBEDatosEdicion(
      state,
      action: PayloadAction<{
        key: string
        identificacion: string
      }>,
    ) {
      state[action.payload.key].usuarioBasico.identificacion =
        action.payload.identificacion
    },
  },
})

export const {
  setBEDatosEdicion,
  setUsuarioBasicoDatosEdicion,
  ventasClearDatosEdicion: clearDatosEdicion,
  initDatosEdicion,
  setDatosEdicion,
  updateLoader,
  setDatosEdicionLogin,
  setChangeLoaderEdit,
  setDatosEdicionPersonal,
  setDatosEdicionEstudios,
} = datosEdicionConfUsuarioSlice.actions
export const dataEditionReducer = datosEdicionConfUsuarioSlice.reducer
