import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  FiltersPositions,
  OrganizationChart,
  Position,
  SearchState,
} from '../types/types'
import { LoaderInfo } from '../../../../../store/types'

export const initialState: SearchState<Position> = {
  filter: {
    cargo: '',
  },
  positionsList: [],
  collapsed: true,
  loader: { show: false, mensaje: '' },
  selected: null,
  isSearch: false,
  organizationChart: {
    loader: { show: false, mensaje: '' },
    isDataLoaded: false,
    list: [],
    listToBeAssigned: [],
    listAssigned: [],
  },
  menuOptions: [],
  isMenuLoaded: false,
}

const searchSlice = createSlice({
  name: 'searchPosition',
  initialState: initialState,
  reducers: {
    updateFilter(state, action: PayloadAction<FiltersPositions>) {
      state.filter.cargo = action.payload.cargo
    },
    setResults(state, action: PayloadAction<Position[] | []>) {
      state.positionsList = action.payload
    },
    setFilter(state, action: PayloadAction<FiltersPositions>) {
      state.filter = action.payload
    },
    setCollpased(state, action: PayloadAction<boolean>) {
      state.collapsed = action.payload
    },
    showLoaderSearch(state, action: PayloadAction<LoaderInfo>) {
      state.loader = action.payload
    },
    setSelectedPosition(state, action: PayloadAction<Position>) {
      state.selected = action.payload
    },
    setIsSearch(state, action: PayloadAction<boolean>) {
      state.isSearch = action.payload
    },
    setResultsOrganizationChart(
      state,
      action: PayloadAction<Pick<OrganizationChart, 'list' | 'isDataLoaded'>>,
    ) {
      state.organizationChart.list = action.payload.list
      state.organizationChart.isDataLoaded = action.payload.isDataLoaded
    },
    showLoaderOrganizationChart(state, action: PayloadAction<LoaderInfo>) {
      state.organizationChart.loader = action.payload
    },
    setResultsAssigned(
      state,
      action: PayloadAction<
        Pick<OrganizationChart, 'listToBeAssigned' | 'listAssigned'>
      >,
    ) {
      state.organizationChart.listAssigned = action.payload.listAssigned
      state.organizationChart.listToBeAssigned = action.payload.listToBeAssigned
    },
    setResultsMenu(
      state,
      action: PayloadAction<
        Pick<SearchState<null>, 'menuOptions' | 'isMenuLoaded'>
      >,
    ) {
      state.menuOptions = action.payload.menuOptions
      state.isMenuLoaded = action.payload.isMenuLoaded
    },
  },
})

export const {
  updateFilter,
  setFilter,
  setResults,
  setCollpased,
  showLoaderSearch,
  setSelectedPosition,
  setIsSearch,
  setResultsOrganizationChart,
  showLoaderOrganizationChart,
  setResultsAssigned,
  setResultsMenu,
} = searchSlice.actions

export const searchPositionsReducer = searchSlice.reducer
