import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DateUtils } from '../../../../../helpers/dateUtils'
import { RootState } from '../../../../../store/store'
import { FETCH_STATUS, TiposComprobantesSri } from '../../../../../store/types'
// import { VentasService } from '../../../services/ventas.service'
import { LocalListado, FiltroBusquedaLocalsState } from './types'
import { TConfigSearchState } from '../../store/types'
import { LocalesService } from '../services/locales.services'

const initialState: TConfigSearchState<LocalListado> = {
  filter: {
    nombre: '',
    codigo: '',
    estado: 1
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  resultados: [],
  selectedItem: null
}

export const fetchLocales = createAsyncThunk<
  Array<LocalListado>,
  FiltroBusquedaLocalsState
>('local/getAllBase', async (filtro) => {
  try {
    const locales = await LocalesService.getAllLocales(filtro)

    return locales
  } catch (error) {
    return Promise.reject(error)
  }
})


export const fetchEmpresaLocales = createAsyncThunk<
  Array<LocalListado>,
  FiltroBusquedaLocalsState
>('local/cboxLoad', async (filtro) => {
  try {
    const locales = await LocalesService.getEmpresaLocales(filtro)

    return locales
  } catch (error) {
    return Promise.reject(error)
  }
})





const searchConfLocalSlice = createSlice({
  name: 'searchLocals',
  initialState: initialState,
  reducers: {
    resetState(state) {
      state = {
        ...initialState,
        filter: {
          ...initialState.filter,
        },
      }
    },
    changeFilter(state, action: PayloadAction<FiltroBusquedaLocalsState>) {
      state.filter = action.payload
    },
    
    setCleanResult(state) {
      state.resultados = []
    },
    setResultados(state, action: PayloadAction<Array<LocalListado> | []>) {
      state.resultados = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLocales.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchEmpresaLocales.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchLocales.fulfilled, (state, { payload }) => {
      state.resultados = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchEmpresaLocales.fulfilled, (state, { payload }) => {
      state.resultados = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchLocales.rejected, (state, { payload }) => {
      state.resultados = []
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
  },
})

export const selectFiltroBusquedaLocales = (state: RootState) => {
  return state.administracion.configuraciones.locales.search.filter
}
export const selectResultadosBusquedaLocales = (state: RootState) => {
  return state.administracion.configuraciones.locales.search.resultados
}
export const selectBusquedaLocalesEstado = (state: RootState) => {
  return state.administracion.configuraciones.locales.search.status
}
export const { setResultados, resetState, setCleanResult, changeFilter } =
searchConfLocalSlice.actions
export const searchReducer = searchConfLocalSlice.reducer
