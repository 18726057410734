import { CNav, CNavItem, CNavLink, CTabContent, CTabPane } from '@coreui/react-pro'
import React, { useCallback, useEffect } from 'react'
import RolesPago from './rolesPago'
import Descuentos, { descuentosGroup } from './descuentos'
import { TabState } from '../../../../../../store/genericTabTypes'
import { DescuentosDatosEdicion, NominaDatosEdicion, RolesPagoDatosEdicion, SaveAssigments, SeleccionadoDescuento } from '../../types/types'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import { useDispatch, useSelector } from 'react-redux'
import { clearButtonClick, setCurrentExecutingAction } from '../../store/tabsReducer'
import { RootState } from '../../../../../../store/store'
import { ToastTypes } from '../../../../../../store/types'
import { addToast } from '../../../../../../store/toasterReducer'
import { setChangeLoader, setDatosEdicion } from '../../store/nominaReducer'
import { consoleService } from '../../../../../../services/console.service'
import BlockUi from '../../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../../views/componentes/loadingindicator/loadingindicator'
import { AsigmentTransaction, AsigmentType } from '../../../personal/types/types'
import { PersonalServices } from '../../../personal/services/personal.service'
import { asignacionesService } from '../../../../../contabilidad/pages/asignaciones/services/asignaciones.service'


interface ITabsNominaProps extends React.PropsWithChildren {
  tab: TabState<NominaDatosEdicion>
  tabId: string
}

export enum NormasTabs {
  roles = "Roles de pago",
  descuentos = "Descuentos"
}


export const tabsNames = [NormasTabs.roles, NormasTabs.descuentos]

const Nomina: React.FunctionComponent<ITabsNominaProps> = (props) => {
  const { tabId, tab } = props
  const dispatch = useDispatch()

  const loading = useSelector((state: RootState) => state.nomina.organico.nomina[tabId].loading)
  const loader = useSelector((state: RootState) => state.nomina.organico.nomina[tabId].loader)
  const nominaState = useSelector((state: RootState) => state.nomina.organico.nomina[tabId])
  const tabs = useSelector((state: RootState) => state.nomina.organico.tabs)
  const [showTab, setShowTab] = React.useState<string>(tabsNames[0])

  const onSetButtonAction = useCallback(
    (tipo: ButtonTypes | undefined) => {
      dispatch(
        setCurrentExecutingAction({
          tabKey: tabId,
          buttonType: tipo,
        }),
      )
    },
    [dispatch, tabId],
  )

  const setToast = useCallback(
    (mensaje: string, tipo: ToastTypes) => {
      dispatch(
        addToast({
          title: 'Nómina - Horarios',
          content: mensaje,
          type: tipo,
        }),
      )
    },
    [dispatch],
  )

  const onLoader = useCallback((showLoader: boolean, mensaje: string) => {
    dispatch(setChangeLoader({
      key: tabId,
      data: {
        show: showLoader,
        mensaje: mensaje
      }
    }))
  }, [dispatch, tabId])

  const onLoadData = useCallback(async (loader: boolean) => {
    if (loader === false) {
      return
    }

    onSetButtonAction(ButtonTypes.assign)
    onLoader(true, 'Cargando nomina...')
    consoleService.log(nominaState)
    const nominaData = { ...nominaState }
    const dataRolesPago: RolesPagoDatosEdicion = { ...nominaData.rolesPago }
    const dataDescuentos: DescuentosDatosEdicion = { ...nominaData.descuentos }
    try {
      const allNomina = await PersonalServices.getAllAssigmentNomina(nominaState?.codigoOrganico ?? 0, AsigmentType.organico)
      consoleService.log(allNomina, '_____allNomina')
      if (allNomina['auto'] && allNomina['error'] === false) {
        const asignacion = allNomina['auto'].slice(0)
        asignacion.forEach(asig => {
          if (asig?.tipo === AsigmentType.organico && asig?.item === nominaState?.codigoOrganico) {
            if (asig?.transaccion === 'DESCUENTO NOMINA') {
              dataDescuentos.numeroDescuento = asig?.planNumero ?? ''
              dataDescuentos.descripcionDescuento = asig?.planDescripcion ?? ''
              dataDescuentos.codigoDescuento = asig?.planCodigo ?? ''
            } else {
              const index = AsigmentTransaction.indexOf(asig?.transaccion.toLowerCase())
              if (index > -1) {
                dataRolesPago[AsigmentTransaction[index]] = `${asig?.planNumero ?? ''} ${asig?.planDescripcion ?? ''}`
              }
            }
          }
        })
        if (asignacion.length > 0) {
          if (asignacion[0].tipo === AsigmentType.organico) {
            dataRolesPago.rolesPago = asignacion
          }
        }
      }
      const objTypoDescuento: any = {
        estado: 1
      }
      const tipoDescuento = await asignacionesService.getTypeDescount(objTypoDescuento)
      consoleService.log(tipoDescuento, '_____tipoDescuento')
      if (tipoDescuento && tipoDescuento.length > 0) {

        const dpDescuentoNomina: Array<SeleccionadoDescuento> = []
        tipoDescuento.map(x => {
          dpDescuentoNomina.push({
            item: x?.codigo ?? 0,
            descripcion: x?.descripcion ?? '',
            transaccion: 'DES NOMINA O',
            tipo: nominaState?.codigoOrganico ?? 0,
          })
        })
        dataDescuentos.descuentoNomina = dpDescuentoNomina
        const dataAsignacion: any = {
          transaccion: "DES NOMINA O",
          tipo: nominaState?.codigoOrganico,
        }
        const datasig = await asignacionesService.obtenerAsignacionesDescuentoNomina(dataAsignacion)
        consoleService.log(datasig, '________________datasig')
        if (datasig && datasig.length > 0) {
          dpDescuentoNomina.forEach((x, i) => {
            datasig.forEach(j => {
              if (x?.item === j?.item) {
                dpDescuentoNomina[i] = {
                  item: x?.item ?? 0,
                  descripcion: x?.descripcion ?? '',
                  tipo: parseInt(j?.tipo) ?? 0,
                  codigo: j?.codigoCuentaContable ?? 0,
                  planNumero: j?.numeroPlanCuentas ?? '',
                  planDescripcion: j?.descripcion ?? '',
                  transaccion: x?.transaccion ?? '',
                  enUso: x?.enUso ?? 0,
                }
              }
            })
          })
          dataDescuentos.descuentoNomina = dpDescuentoNomina
        }
      }

      dataDescuentos.radioOption = descuentosGroup[0]

      nominaData.rolesPago = dataRolesPago
      nominaData.descuentos = dataDescuentos
      nominaData.initial = false
      dispatch(setDatosEdicion({
        key: tabId,
        data: { ...nominaData }
      }))
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
    onLoader(false, '')
    onSetButtonAction(undefined)
  }, [onLoader, onSetButtonAction, nominaState, setToast, dispatch, tabId])

  const onSaveAssigments = useCallback(async () => {
    onSetButtonAction(ButtonTypes.save)
    onLoader(true, 'Guardando asignaciones...')
    try {
      const assigments = []
      if (nominaState?.descuentos.codigoDescuento !== 0) {
        assigments.push({
          codigo: 0,
          vinculacion: nominaState?.codigoOrganico ?? 0,
          tipo: "NOMINA ORGANICO",
          planCuentasCodigo: nominaState?.descuentos.codigoDescuento ?? 0,
          transaccion: "DESCUENTO NOMINA",
        })
      }

      consoleService.log(assigments, 'cuenta general')
      const dataDesc = await asignacionesService.registrarAsignacion(assigments)
      consoleService.log(dataDesc, 'save desc')
      const dpnomina: Array<SaveAssigments> = []
      const dpdescnomina: Array<SaveAssigments> = []
      if (nominaState?.descuentos?.descuentoNomina.length > 0) {
        nominaState?.descuentos?.descuentoNomina.forEach(x => {
          dpdescnomina.push({
            item: x?.item ?? 0,
            tipo: String(x?.tipo) ?? '',
            plaCodigo: x?.codigo ?? 0,
            transaccion: x?.transaccion ?? '',
          })
        })
      }

      if (nominaState?.rolesPago?.rolesPago.length > 0) {
        nominaState?.rolesPago?.rolesPago.forEach(x => {
          dpnomina.push({
            item: x?.item ?? 0,
            tipo: x?.tipo ?? '',
            plaCodigo: x?.planCodigo ?? 0,
            transaccion: x?.transaccion ?? '',
          })
        })
      }
      consoleService.log(dpnomina)
      consoleService.log(dpdescnomina)
      const dataRoles = await PersonalServices.saveAssigmentNomina(dpnomina, dpdescnomina)
      consoleService.log(dataRoles, 'save roles')
      if (dataRoles?.auto && dataRoles?.error === false) {
        setToast(dataRoles?.message, ToastTypes.Success)
      } else {
        setToast(dataRoles?.message, ToastTypes.Danger)
      }
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
    onLoader(false, '')
    onSetButtonAction(undefined)
  }, [nominaState, onLoader, onSetButtonAction, setToast])


  const handleButtonClick = useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.save:
          if (tabs.current === tabId) onSaveAssigments()
          break
        case ButtonTypes.undo:
          break
        default:
          break
      }
      dispatch(clearButtonClick(tabId))
    }, [dispatch, tabId, onSaveAssigments, tabs])


  useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab.globalButtonClick])

  useEffect(() => {
    onLoadData(loading)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <>

      <div style={{ overflowX: 'hidden' }}>
        <BlockUi
          tag="div"
          loader={LoadingIndicator}
          blocking={loader.show}
          message={loader.mensaje}
        >
          <CNav variant="tabs" role="tablist" className="mt-4">
            <CNav variant="tabs">
              {tabsNames.map((tab, index) => (
                <CNavItem key={index}>
                  <CNavLink
                    onClick={() => {
                      setShowTab(tab)
                    }}
                    active={showTab === tab}
                  >
                    {tab}
                  </CNavLink>
                </CNavItem>
              ))}
            </CNav>
            <CTabContent
              style={{
                overflowY: 'unset',
                width: '100%',
                marginBottom: '10px',
              }}
            >
              <CTabPane
                role="tabpanel"
                aria-labelledby="generales-tab"
                visible={showTab === tabsNames[0]}
              >
                <RolesPago tab={tab} tabId={tabId} />
              </CTabPane>
              <CTabPane
                role="tabpanel"
                aria-labelledby="generales-tab"
                visible={showTab === tabsNames[1]}
              >
                <Descuentos tab={tab} tabId={tabId} />
              </CTabPane>


            </CTabContent>
          </CNav>
        </BlockUi>
      </div>
    </>
  )
}

export default React.memo(Nomina)

export const defaultNominaData: NominaDatosEdicion = {
  loader: {
    show: false,
    mensaje: ''
  },
  codigoOrganico: 0,
  loading: false,
  initial: false,
  rolesPago: {
    sueldo: '',
    ajustes: '',
    nocturno: '',
    suple: '',
    extra: '',
    comision: '',
    irenta: '',
    apersonal: '',
    bonificacion: '',
    bonificacionna: '',
    freserva: '',
    irentaxp: '',
    apersonalxp: '',
    freservaxp: '',
    quirografariosxp: '',
    hipotecariosxp: '',
    anticipos: '',
    descuentos: '',
    multas: '',
    prestamos: '',
    nominaxp: '',
    dtsueldo: '',
    pdtsueldoxp: '',
    dcsueldo: '',
    pcsueldoxp: '',
    apatronal: '',
    apatronalxp: '',
    vacacion: '',
    vacacionxp: '',
    descuentoNomina: '',
    rolesPago: [],
  },
  descuentos: {
    numeroDescuento: '',
    codigoDescuento: 0,
    descripcionDescuento: '',
    descuentoNomina: [],
    nomina: [],
    radioOption: null,
    seleccionadoDescuento: null
  },
}