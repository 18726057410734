/* eslint-disable no-unused-vars */
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DataGrid, {
  Column,
  Paging,
  Pager,
  ColumnChooser,
  HeaderFilter,
  Export,
} from 'devextreme-react/data-grid'
import { DatosCompra } from '../../../../types/types'
import { RootState } from '../../../../../../../../../../../store/store'
import { Workbook } from 'exceljs'
import { saveAs } from 'file-saver'
import { exportDataGrid } from 'devextreme/excel_exporter'
interface ITablaComprasProps extends React.PropsWithChildren {
  data: Array<DatosCompra> | []
  onExport: (data) => void
}
const pageSizes = [10, 25, 50, 100, 200]

const TablaCompras: React.FunctionComponent<ITablaComprasProps> = (props) => {
  const { data, onExport } = props
  const dispatch = useDispatch()
  const filtros = useSelector((state: RootState) => {
    return state.impuestosSV.anexoTransaccional.search.filter
  })
  const dialogRef = React.useRef<any>(null)
  const dataGrid = React.useRef<any>()

  const onExportExcell = React.useCallback(
    (e) => {
      const rango = `${filtros?.fechaInicio} - ${filtros?.fechaFinal}`
      const fileName = `compras-${rango}`
      if (e.format === 'xlsx') {
        const workbook = new Workbook()
        const worksheet = workbook.addWorksheet('Compras')
        e.component.exportDataFieldHeaders = false
        exportDataGrid({
          component: e.component,
          worksheet,
          autoFilterEnabled: true,
        }).then(() => {
          workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(
              new Blob([buffer], { type: 'application/octet-stream' }),
              fileName + '.xlsx',
            )
          })
        })
      } else if (e.format === 'pdf') {
      } else if (e.format === 'csv') {
        onExport({
          title: 'Compras',
          format: e.format,
          component: e.component,
          filename: fileName,
        })
      }
    },
    [filtros, onExport],
  )

  return (
    <DataGrid
      ref={dataGrid}
      selection={{ mode: 'single' }}
      allowColumnResizing={true}
      columnResizingMode={'nextColumn'}
      focusedRowEnabled={true}
      keyExpr="comprobante"
      focusedRowKey="comprobante"
      dataSource={data}
      showBorders={true}
      onExporting={onExportExcell}
      width={'100%'}
    >
      <ColumnChooser enabled={true} mode="select" />
      <HeaderFilter visible={true} />

      <Export
        enabled={true}
        allowExportSelectedData={false}
        formats={['csv', 'xlsx']}
      />
      <Column
        dataField="fechaNegociacion"
        caption="FECHA DE EMISIÓN" // A
        width="80px"
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />
      <Column
        dataField="claseDocumentoCodigo"
        caption="CLASE DE DOCUMENTO" // B
        width="110px"
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={true}
      />
      <Column
        type={'string'}
        width="100px"
        dataField="tipoDocumentoCodigo"
        caption="TIPO DE DOCUMENTO" // C
        alignment="right"
        allowSearch={true}
        allowFiltering={true}
      >
        <HeaderFilter allowSearch={true} />
      </Column>
      <Column
        dataField="numeroDocumento"
        caption="NÚMERO DE DOCUMENTO" // D
        minWidth="200px"
        width="auto"
      />
      <Column
        dataField="proveedorIdentificacion"
        caption="NIT O NRC DEL PROVEEDOR" // E
        minWidth="220px"
        width="auto"
        allowSearch={true}
        allowFiltering={true}
      >
        <HeaderFilter allowSearch={true} />
      </Column>
      <Column
        dataField="proveedorNombre"
        caption="NOMBRE DEL PROVEEDOR" // F
        minWidth="220px"
        width="auto"
      />
      <Column
        dataField="comprasInternasExentasNoSujetas"
        caption="COMPRAS INTERNAS EXENTAS Y/O NO SUJETAS"
        alignment="right"
        width="85px"
        allowFiltering={false}
      />
      <Column
        dataField="internacionesExentasNoSujetas"
        caption="INTERNACIONES EXENTAS Y/O NO SUJETAS"
        alignment="right"
        width="85px"
        allowFiltering={false}
      />
      <Column
        dataField="importacionesExentasNoSujetas"
        caption="IMPORTACIONES EXENTAS Y/O NO SUJETAS"
        alignment="right"
        width="85px"
        allowFiltering={false}
      />
      <Column
        dataField="comprasInternasGravadas"
        caption="COMPRAS INTERNAS GRAVADAS"
        alignment="right"
        width="85px"
        allowFiltering={false}
      />
      <Column
        dataField="internacionesGravadasBienes"
        caption="INTERNACIONES GRAVADAS DE BIENES"
        alignment="right"
        width="85px"
        allowFiltering={false}
      />
      <Column
        dataField="importacionesGravadasBienes"
        caption="IMPORTACIONES GRAVADAS DE BIENES"
        alignment="right"
        width="85px"
        allowFiltering={false}
      />
      <Column
        dataField="importacionesGravadasServicios"
        caption="IMPORTACIONES GRAVADAS DE SERVICIOS"
        alignment="right"
        width="85px"
        allowFiltering={false}
      />
      <Column
        dataField="creditoFiscal"
        caption="CREDITO FISCAL"
        alignment="right"
        width="85px"
        allowFiltering={false}
      />
      <Column
        dataField="totalCompras"
        caption="TOTAL DE COMPRAS"
        alignment="right"
        width="85px"
        allowFiltering={false}
      />
      <Column
        dataField="proveedorDui"
        caption="DUI DEL PROVEEDOR"
        alignment="right"
        width="85px"
        allowFiltering={false}
      />
      <Column
        dataField="tipoOperacion"
        caption="TIPO DE OPERACION"
        alignment="right"
        width="85px"
        allowFiltering={false}
      />
      <Column
        dataField="clasificacion"
        caption="CLASIFICACION"
        alignment="right"
        width="85px"
        allowFiltering={false}
      />
      <Column
        dataField="sector"
        caption="SECTOR"
        alignment="right"
        width="85px"
        allowFiltering={false}
      />
      <Column
        dataField="tipoCostoGasto"
        caption="TIPO DE COSTO / GASTO"
        alignment="right"
        width="85px"
        allowFiltering={false}
      />
      <Column
        dataField="numeroAnexo"
        caption="NÚMERO DE ANEXO"
        alignment="right"
        width="85px"
        allowFiltering={false}
      />

      <Pager
        allowedPageSizes={pageSizes}
        showPageSizeSelector={true}
        showInfo
      />
      <Paging defaultPageSize={10} />
    </DataGrid>
  )
}
export default TablaCompras
