import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  FiltrosFichaTecnicaState,
  BusquedaState,
  FichaTecnicaInventario,
} from '../types/types'
import { FETCH_STATUS, Seleccionado } from '../../../../../../store/types'
import { dataSheetServices } from '../services/unidades.services'

const initialState: BusquedaState<FichaTecnicaInventario> = {
  filter: {
    nroBase: '',
    tipoProducto: null,
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  seleccionado: {
    index: null,
    row: null,
    selectedRow: null,
  },
  resultados: [],
  resultadosTransaction: [],
}

export const fetchFichaTecnica = createAsyncThunk<
  Array<FichaTecnicaInventario>,
  any
>('inventarios/catalogos/fecha_tecnica/listar', async (filtro) => {
  try {
    const unitsList = await dataSheetServices.geDataSheets(filtro)
    return unitsList
  } catch (error) {
    return Promise.reject(error)
  }
})

export const fetchFichaTecnicaReporte = createAsyncThunk<
  Array<any>,
  any
>('inventarios/catalogos/fecha_tecnica/imprimir', async (filtro) => {
  try {
    const transacctionList = await dataSheetServices.geDataSheetsTransaction(filtro)
    return transacctionList
  } catch (error) {
    return Promise.reject(error)
  }
})

const searchSlice = createSlice({
  name: 'buscarFichaTecnica',
  initialState: initialState,
  reducers: {
    setCleanResult(state) {
      state.resultados = []
      state.filter.nroBase = ''
      state.filter.tipoProducto = null
    },
    changeFilter(state, action: PayloadAction<FiltrosFichaTecnicaState>) {
      state.filter.nroBase = action.payload.nroBase
      state.filter.tipoProducto = action.payload.tipoProducto
    },
    setResetSeleccion(state) {
      const toAdd = {
        index: null,
        row: null,
        selectedRow: null,
      }
      state.seleccionado = toAdd
    },
    setSeleccionarDato(state, action: PayloadAction<Seleccionado>) {
      const toAdd = {
        index: action.payload.index,
        row: action.payload.row,
        selectedRow: action.payload.selectedRow,
      }
      state.seleccionado = toAdd
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFichaTecnica.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchFichaTecnica.fulfilled, (state, { payload }) => {
      state.resultados = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchFichaTecnica.rejected, (state, { payload }) => {
      state.resultados = []
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
    builder.addCase(fetchFichaTecnicaReporte.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchFichaTecnicaReporte.fulfilled, (state, { payload }) => {
      state.resultadosTransaction = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchFichaTecnicaReporte.rejected, (state, { payload }) => {
      state.resultados = []
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
  },
})

export const { changeFilter, setCleanResult, setResetSeleccion, setSeleccionarDato } = searchSlice.actions

export const searchReducer = searchSlice.reducer
