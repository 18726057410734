import React from 'react'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import { FiltrosInventariosConsolidarState, InventarioMovDatosEdicion } from '../../types/types'
import { ButtonTypes } from '../../../../../../../views/componentes/globalMenu/types'
import { clearButtonClick, setButtons, setCurrentExecutingAction } from '../../store/tabsReducer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import {
  setCurrentFunction,
} from '../../store/generalReducer'
import Dialog from '../../../../../../../views/componentes/librerias/bootstrap-dialog'
import { TabState } from '../../../../../../../store/genericTabTypes'
import { changeFilterCon, fetchConsolidate, setChangeLoaderCon } from '../../store/serachReducer'
import { getLocales } from '../../../../../../componentes/localesLookUp/helper/helper'
import { DateUtils } from '../../../../../../../helpers/dateUtils'
import FiltroBusqueda from './FiltroBusqueda'
import ResultadosBusqueda from './ResultadosBusqueda'
import BlockUi from '../../../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../../../views/componentes/loadingindicator/loadingindicator'
import ListErrosValidationForm from '../../../../../../componentes/listadoErroresValidacionForm'
import VisualizaError from '../../../../../../ventas/pages/shared/visualizaError/visualizaError'
import { consoleService } from '../../../../../../../services/console.service'
import { conceptsServices } from '../../services/conceptos.services'
import { FETCH_STATUS, ToastTypes } from '../../../../../../../store/types'
import { addToast } from '../../../../../../../store/toasterReducer'
import { fetchIncomeExpenses } from '../../../ingresosEgresos/store/serachReducer'
import { isMobile } from 'react-device-detect'
import TableLoader from '../../../../../../ventas/components/ventas/busquedaVentas/TableLoader'
import { Draggable, SpeedDialAction } from 'devextreme-react'

interface IConsolidateProps extends React.PropsWithChildren {
  tab: TabState<InventarioMovDatosEdicion>
  tabId: string
}

enum VistasBusqueda {
  Filtros,
  ResultadosBusqueda,
  Resumen,
  Loading,
  Error
}
const draggingGroupName = 'appointmentsGroup';

const Consolidar: React.FunctionComponent<IConsolidateProps> = (props) => {
  const { tab, tabId } = props
  const dispatch = useDispatch()
  const tabs = useSelector((state: RootState) => state.inventarios.movimientos.inventarioInicial.tabs)
  const consolidateLoader = useSelector((state: RootState) => state.inventarios.movimientos.inventarioInicial.search.filterConsolidar)
  const currentTab = useSelector((state: RootState) => state.inventarios.movimientos.inventarioInicial.tabs.current)
  const filterConsolidate = useSelector((state: RootState) => state.inventarios.movimientos.inventarioInicial.search.filterConsolidar)
  const estadoBusqueda = useSelector((state: RootState) => state.inventarios.movimientos.inventarioInicial.search.statusConsolidate)
  const searchFilter = useSelector((state: RootState) => state.inventarios.movimientos.inventarioInicial.search.filter)
  const dataItems = useSelector((state: RootState) => state.inventarios.movimientos.inventarioInicial.search.filterConsolidar.dataItems)
  const periodoActivo = useSelector(
    (state: RootState) => state.shared.providers.periodoContable,
  )
  const fechaFormato: string = 'dd/MM/yyyy'
  const fechaFormatoInput: string = 'yyyy-MM-dd'
  const [showErrorPopup, setShowErrorPopup] = React.useState<boolean>(false)
  const [arrayErrors, setArrayErrors] = React.useState<Array<string>>([])
  const [vistaActual, setVistaActual] = React.useState<VistasBusqueda>(VistasBusqueda.Filtros)

  const dialogRef = React.useRef<any>(null)
  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(
      addToast({
        title: 'Inventario - Consolidar',
        content: mensaje,
        type: tipo,
      }),
    )
  }, [dispatch])

  const setButtonExecuttion = React.useCallback((tipo: ButtonTypes | undefined) => {
    dispatch(
      setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: tipo,
      }),
    )
  }, [dispatch, tabId])

  const playLoader = React.useCallback((message = 'Cargando...') => {
    dispatch(setChangeLoaderCon({ show: true, mensaje: message }))
  }, [dispatch])

  const stopLoader = React.useCallback(() => {
    dispatch(setChangeLoaderCon({ show: false, mensaje: '' }))
  }, [dispatch])

  const onInitSearch = React.useCallback(async () => {
    dispatch(
      setButtons({
        tabKey: tabId,
        buttons: {
          Nuevo: true,
          Guardar: true,
          Editar: false,
          Eliminar: false,
          Buscar: true,
          Imprimir: false,
          Consolidar: false,
          Limpiar: true,
        },
      }),
    )
    const data = { ...filterConsolidate }
    if (data.initial) {
      const resLocal = await getLocales('Elija una opcion')
      data.local = resLocal.length > 0 ? resLocal[0] : null
      data.fechaInv = periodoActivo.length > 0 ? DateUtils.format(periodoActivo[0].fcierre, fechaFormatoInput, fechaFormato) : DateUtils.getCurrentDateAsString(fechaFormatoInput)
      data.anio = null
      data.initial = false

      dispatch(changeFilterCon({
        ...data
      }))
    }
  }, [dispatch, periodoActivo, tabId, filterConsolidate])

  const onTotalesConsolidar = React.useCallback((data = []) => {
    consoleService.log('onTotalesCon')
    consoleService.log(data)
    const datCon = { ...filterConsolidate }
    dispatch(changeFilterCon({
      ...datCon,
      dataMov: []
    }))
    let subtotal12: number = 0
    let subtotal0: number = 0
    let ivatotal: number = 0
    let descuentototal: number = 0
    let descuento: number = 0
    let precio: number = 0
    let cantidad: number = 0

    data.forEach(item => {
      cantidad = item?.ordenDetalleCantidad ?? 0
      precio = item?.ordenDetallePrecio ?? 0
      descuento = item?.ordenDetalleDescuento ?? 0
      if (item?.porcentaje !== 0 && item?.ordenDetalleImpuesto !== null) {
        subtotal12 += item?.ordenDetalleValor ?? 0
      } else {
        subtotal0 += item?.ordenDetalleValor ?? 0
      }
      descuentototal += (precio * cantidad) * descuento / 100
    })
    datCon.subtotalIVA = subtotal12
    datCon.subtotal0 = subtotal0
    datCon.subtotal = subtotal12 + subtotal0
    ivatotal = datCon.subtotalIVA * datCon.IVA / 100
    datCon.iva = ivatotal
    datCon.total = datCon.subtotalIVA + subtotal0 + ivatotal
    dispatch(changeFilterCon({
      ...datCon
    }))
  }, [dispatch, filterConsolidate])

  const onValidate = React.useCallback(() => {
    const arrayErrores: Array<string> = []
    if (filterConsolidate?.anio === undefined || filterConsolidate?.anio === null) {
      arrayErrores.push('Año: debe escojer el año de registro inicial')
    }

    if (filterConsolidate?.fechaInv === undefined || filterConsolidate?.fechaInv === null || filterConsolidate?.fechaInv === '') {
      arrayErrores.push('Fecha: debe escojer la fecha de registro inicial')
    }

    if (filterConsolidate?.local === undefined || filterConsolidate?.local === null || filterConsolidate.local?.codigo <= 0) {
      arrayErrores.push('Local: debe escojer un local')
    }
    if (filterConsolidate?.dataItems === undefined || filterConsolidate?.dataItems === null || filterConsolidate.dataItems.length === 0) {
      arrayErrores.push('Detalle: debe consolidar los datos')
    }

    return arrayErrores
  }, [filterConsolidate])

  const onHandleSearch = React.useCallback(async () => {
    const fecha = filterConsolidate?.fechaInv ? DateUtils.format(filterConsolidate?.fechaInv, fechaFormato, fechaFormatoInput) : DateUtils.getCurrentDateAsString(fechaFormato)

    try {
      const data_search: any = {
        local: filterConsolidate.local?.codigo ?? -1,
        fecha,
      }
      playLoader()
      setButtonExecuttion(ButtonTypes.find)
      const toAny: any = fetchConsolidate(data_search)
      const res = await dispatch(toAny)
      setButtonExecuttion(undefined)
      stopLoader()
      consoleService.log(res)
      if (res !== null && res !== undefined && res['payload']['error'] === false) {
        setToast(res['payload']['message'], ToastTypes.Success)
      } else {
        setToast(res['payload']['message'], ToastTypes.Danger)
      }
    } catch (error) {
      setButtonExecuttion(undefined)
      stopLoader()
      setToast(error.message, ToastTypes.Danger)
    }
  }, [dispatch, filterConsolidate, playLoader, stopLoader, setButtonExecuttion, setToast])

  const onLoadInventary = React.useCallback(async () => {
    const dataCon = { ...filterConsolidate }
    const fecha = filterConsolidate?.fechaInv ? DateUtils.format(filterConsolidate?.fechaInv, fechaFormato, fechaFormatoInput) : DateUtils.getCurrentDateAsString(fechaFormato)

    const search_data: any = {
      codigo: 0,
      documento: '',
      codigoLocal: filterConsolidate.local?.codigo ?? -1,
      prodCliente: 0,
      tipo: 'INVENTARIO',
      movimiento: 1,
      observaciones: searchFilter?.observaciones ?? '',
      estado: searchFilter.item?.codigoBarras ?? '',
      fechaDesde: '01/01/' + filterConsolidate?.anio ?? 0,
      fechaHasta: fecha,
      currentTab: 'Cabecera',
    }
    try {
      setButtonExecuttion(ButtonTypes.save)
      playLoader('Cargnado Inventario...')
      const toAny: any = fetchIncomeExpenses(search_data)
      const res = await dispatch(toAny)
      stopLoader()
      setButtonExecuttion(undefined)
      consoleService.log(res)
      if (res !== null && res !== undefined && res['payload']?.error === false) {
        dispatch(changeFilterCon({
          ...dataCon,
          dataMov: res['payload']
        }))
      }
    } catch (error) {
      stopLoader()
      setButtonExecuttion(undefined)
      setToast(error.message, ToastTypes.Danger)
    }
  }, [dispatch, filterConsolidate, searchFilter, setToast, playLoader, stopLoader, setButtonExecuttion])

  const onHandleSave = React.useCallback(async () => {
    let arrayErrores: Array<string> = []
    arrayErrores = onValidate()
    if (arrayErrores.length > 0) {
      setToast('Tiene errores, por favor verifiquelos', ToastTypes.Warning)
      setArrayErrors(arrayErrores)
      setShowErrorPopup(true)
    } else {
      const fecha = filterConsolidate?.fechaInv ? DateUtils.format(filterConsolidate?.fechaInv, fechaFormato, fechaFormatoInput) : DateUtils.getCurrentDateAsString(fechaFormato)

      const consolidate_save = {
        local: filterConsolidate.local?.codigo ?? -1,
        fechac: '01/01/' + filterConsolidate?.anio ?? 0,
        fechacr: fecha,
      }

      consoleService.log(consolidate_save)

      try {
        setButtonExecuttion(ButtonTypes.save)
        playLoader('Consolidando...')
        const data = await conceptsServices.saveConsolidate(consolidate_save)
        stopLoader()
        setButtonExecuttion(undefined)
        consoleService.log(data)
        if (data !== null && data !== undefined && data['error'] === false) {
          setToast(data['message'], ToastTypes.Success)
          onLoadInventary()
        } else {
          setToast(data['message'], ToastTypes.Danger)
        }
      } catch (error) {
        stopLoader()
        setButtonExecuttion(undefined)
        setToast(error.message, ToastTypes.Danger)
      }

    }
  }, [onValidate, filterConsolidate, setToast, playLoader, stopLoader, setButtonExecuttion, onLoadInventary])

  const onHandleBroom = React.useCallback(() => {
    dispatch(changeFilterCon({
      ...filterConsolidate,
      dataMov: []
    }))
  }, [dispatch, filterConsolidate])

  const handleButtonClick = React.useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.save:
          if (tabs.current === tabId) onHandleSave()
          break
        case ButtonTypes.broom:
          if (tabs.current === tabId) onHandleBroom()
          break
        default:
          break
      }
      dispatch(setCurrentFunction(''))
      dispatch(clearButtonClick(tabId))
    },
    [dispatch, onHandleSave, onHandleBroom, tabId, tabs])

  const onCalcularTotalesCon = React.useCallback(() => {
    consoleService.log('onTotales')
    consoleService.log(dataItems)
    if (dataItems['auto'] && dataItems['error'] === false) {
      onTotalesConsolidar(dataItems['auto'])
    }
  }, [dataItems, onTotalesConsolidar])

  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab.globalButtonClick]);

  React.useEffect(() => {
    onInitSearch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    onCalcularTotalesCon()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataItems]);

  React.useEffect(() => {
    switch (estadoBusqueda) {
      case FETCH_STATUS.IDDLE:
        setVistaActual(VistasBusqueda.Filtros);
        break;
      case FETCH_STATUS.LOADING:
        setVistaActual(VistasBusqueda.Loading);
        break;
      case FETCH_STATUS.SUCCESS:
        setVistaActual(VistasBusqueda.ResultadosBusqueda);
        break;
      case FETCH_STATUS.FAILED:
        setVistaActual(VistasBusqueda.Error);
        break;
      default:
        break;
    }
  }, [estadoBusqueda])


  if (isMobile) {
    return (
      <>
        {vistaActual === VistasBusqueda.Filtros &&

          (
            <>
              <FiltroBusqueda onEnter={onHandleSearch} onloadInventario={onLoadInventary} />
            </>
          )
        }
        {vistaActual === VistasBusqueda.ResultadosBusqueda &&
          (<>
            <RowContainer className="m-2">
              <ResultadosBusqueda />
            </RowContainer>
          </>)
        }
        {vistaActual === VistasBusqueda.Error &&
          <></>
        }
        {vistaActual === VistasBusqueda.Loading &&
          <TableLoader />
        }
        {
          currentTab === tabId && (
            <Draggable
              id="list"
              data="dropArea"
              group={draggingGroupName}
            >
              <SpeedDialAction
                icon="filter"
                label='Filtros'
                visible={true}
                index={1}
                onClick={() => {
                  setVistaActual(VistasBusqueda.Filtros);
                }}
              />
              <SpeedDialAction
                icon="search"
                label='Busqueda'
                visible={true}
                index={2}
                onClick={() => {
                  setVistaActual(VistasBusqueda.ResultadosBusqueda);
                }}
              />
            </Draggable>
          )
        }
      </>
    )
  }

  return (
    <>
      {showErrorPopup && (
        <VisualizaError
          titulo="Error en Consolidar"
          mensaje={'Error al guardar'}
          content={
            <ListErrosValidationForm
              mainMessage={'Revise los siguientes errores:'}
              errorsList={arrayErrors}
              color={'danger'}
            />
          }
          onOk={() => setShowErrorPopup(false)}
        />
      )}
      <div id='ingEgresoContent'>
        <BlockUi
          tag="div"
          loader={LoadingIndicator}
          blocking={consolidateLoader.loader.show}
          message={consolidateLoader.loader.mensaje}
        >
          <Dialog ref={dialogRef} />
          <FiltroBusqueda onEnter={onHandleSearch} onloadInventario={onLoadInventary} />
          <RowContainer className="m-2">
            <ResultadosBusqueda />
          </RowContainer>
        </BlockUi>
      </div>
    </>

  )
}

export default React.memo(Consolidar)


export const initialConsolidate: FiltrosInventariosConsolidarState = {
  anio: 0,
  fechaInv: '',
  local: null,
  dataItems: [],
  dataMov: [],
  loader: {
    show: false,
    mensaje: ''
  },
  subtotal0: 0,
  subtotalIVA: 0,
  subtotal: 0,
  iva: 0,
  IVA: 0,
  total: 0,
  initial: true
}
