import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LoaderInfo, OpenModal, OptionGrupos } from '../../../../../store/types'
import { SeleccionadoTableItem } from '../../../store/types'
import { ChequeState, Cheque, formularioBusqueda, ChequeReporte } from './types';

const initialState: ChequeState = {
  modulo: 'cheques',
  currentAction: 'Buscar',
  currentFunction: "",
  registros: [],
  registrosModal: [],
  seleccionado: {
    index: null,
    row: []
  },
  seleccionTablaModal: {
    index: null,
    row: []
  },
  tipos: [],
  estados: [],
  formasPagoSRI: [],
  checkAnulados: false,
  modal: {
    modalName: "",
    open: false,
    codModulo: null
  },
  diplayModal: 'Nuevo',
  currentExecute: null,
  loader: {
    show: false,
    mensaje: ''
  },
  formularioBusqueda: {
    fechaDesdeRegistro: null,
    fechaHastaRegistro: null,
    fechaDesdeCobro: null,
    fechaHastaCobro: null,
    posFechado: null,
    pertenece: null,
    numero: null,
    estado: null,
    asociadoCodigo: null,
    cuentaBancoCodigo: null,
    anulados: null
  },
  chequeReporte: null,
}

const chequesSlice = createSlice({
  name: 'cheques',
  initialState: initialState,
  reducers: {
    setCurrentAccion(state, action: PayloadAction<string>) {
      state.currentAction = action.payload
    },
    setCurrentFunction(state, action: PayloadAction<string>) {
      state.currentFunction = action.payload
    },
    setCheckAnulados(state, action: PayloadAction<boolean>) {
      state.checkAnulados = action.payload
    },
    setData(state, action: PayloadAction<Array<Cheque>>) {
      state.registros = action.payload
    },
    setDataModal(state, action: PayloadAction<Array<Cheque>>) {
      state.registrosModal = action.payload
    },
    setSeleccionarDato(state, action: PayloadAction<SeleccionadoTableItem>) {
      const toAdd = {
        index: action.payload.index,
        row: action.payload.row
      }
      state.seleccionado = toAdd
    },
    setSeleccionarDatoModal(
      state,
      action: PayloadAction<SeleccionadoTableItem>
    ) {
      const toAdd = {
        index: action.payload.index,
        row: action.payload.row
      }
      state.seleccionTablaModal = toAdd
    },
    setResetSeleccion(state) {
      const toAdd = {
        index: null,
        row: []
      }
      state.seleccionado = toAdd
    },
    setResetSeleccionModal(state) {
      const toAdd = {
        index: null,
        row: []
      }
      state.seleccionTablaModal = toAdd
    },
    setTiposOptions(state, action: PayloadAction<Array<OptionGrupos>>) {
      state.tipos = action.payload
    },
    setOptionsEstados(state, action: PayloadAction<Array<OptionGrupos>>) {
      state.estados = action.payload
    },
    setFormasPagoSRI(state, action: PayloadAction<Array<OptionGrupos>>) {
      state.formasPagoSRI = action.payload
    },
    setModal(state, action: PayloadAction<OpenModal>) {
      state.modal = action.payload
    },
    setDiplayModal(state, action: PayloadAction<string>) {
      state.diplayModal = action.payload
    },
    setCurrentExecute(state, action: PayloadAction<any>) {
      state.currentExecute = action.payload
    },
    setChangeLoaderCheques(state, action: PayloadAction<LoaderInfo>) {
      state.loader = action.payload
    },
    setFormularioBusqueda(state, action: PayloadAction<formularioBusqueda>) {
      const toAdd = {
        fechaDesdeRegistro: action.payload.fechaDesdeRegistro,
        fechaHastaRegistro: action.payload.fechaHastaRegistro,
        fechaDesdeCobro: action.payload.fechaDesdeCobro,
        fechaHastaCobro: action.payload.fechaHastaCobro,
        posFechado: action.payload.posFechado,
        pertenece: action.payload.pertenece,
        numero: action.payload.numero,
        estado: action.payload.estado,
        asociadoCodigo: action.payload.asociadoCodigo,
        cuentaBancoCodigo: action.payload.cuentaBancoCodigo,
        anulados: action.payload.anulados
      }
      state.formularioBusqueda = toAdd
    },
    setChequeReporte(state, action: PayloadAction<ChequeReporte>) {
      const toAdd = {
        chequeCodigo: action.payload.chequeCodigo,
        chequeNumero: action.payload.chequeNumero,
        chequeFRegistro: action.payload.chequeFRegistro,
        chequeFCobro: action.payload.chequeFCobro,
        cbancoCodigo: action.payload.cbancoCodigo,
        cbancoNumero: action.payload.cbancoNumero,
        bancoNombre: action.payload.bancoNombre,
        chequeTransaccion: action.payload.chequeTransaccion,
        cbancoPertenceDesc: action.payload.cbancoPertenceDesc,
        chequeFPago: action.payload.chequeFPago,
        chequeAFavor: action.payload.chequeAFavor,
        formaPagoValor: action.payload.formaPagoValor,
        chequePagos: action.payload.chequePagos,
        chequeValor: action.payload.chequeValor,
        chequeValorLetras: action.payload.chequeValorLetras,
        fpsCodigo: action.payload.fpsCodigo,
        chequeEstado: action.payload.chequeEstado,
        chequeEstadoDes: action.payload.chequeEstadoDes,
        chequeDescripcion: action.payload.chequeDescripcion,
      }
      state.chequeReporte = toAdd
    },
  }
})
export const {
  setCurrentExecute,
  setChangeLoaderCheques,
  setFormularioBusqueda,
  setOptionsEstados,
  setData,
  setCurrentAccion,
  setSeleccionarDato,
  setResetSeleccion,
  setTiposOptions,
  setResetSeleccionModal,
  setSeleccionarDatoModal,
  setDataModal,
  setFormasPagoSRI,
  setCurrentFunction,
  setCheckAnulados,
  setModal,
  setDiplayModal,
  setChequeReporte
} = chequesSlice.actions
export const ChequesReducer = chequesSlice.reducer
