import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../../store/store'
import { Autorizacion } from '../../../../../store/types'
import { AutorizacionDocumentoVenta } from '../../../types/types'
import { ConfiguracionesVentas } from '../types/types'

const initialState: ConfiguracionesVentas = {
  usaDescuentoGlobal: false,
  autorizacion: null,
  autorizacionDocumento: null,
  autorizacionDocumentoCredFiscal: null,
  autorizacionDocumentoFactExportacion: null,
}

const configuracionesVentaSlice = createSlice({
  name: 'configuraciones',
  initialState: initialState,
  reducers: {
    initialize(state, acion: PayloadAction<Autorizacion>) {
      state.autorizacion = acion.payload
    },
    toogleUsaDescuentoGlobal(state, acion: PayloadAction) {
      state.usaDescuentoGlobal = !state.usaDescuentoGlobal
    },
    setAutorizacionModulo(
      state,
      acion: PayloadAction<AutorizacionDocumentoVenta>,
    ) {
      state.autorizacionDocumento = acion.payload
    },
    setAutorizacionModuloCreditoFiscal(
      state,
      acion: PayloadAction<AutorizacionDocumentoVenta>,
    ) {
      state.autorizacionDocumentoCredFiscal = acion.payload
    },
    setAutorizacionModuloFactExportacion(
      state,
      acion: PayloadAction<AutorizacionDocumentoVenta>,
    ) {
      state.autorizacionDocumentoFactExportacion = acion.payload
    },
  },
})

export const selectUsaDescuentoGlobal = (state: RootState) => {
  return state.ventas.ventas.configuraciones.usaDescuentoGlobal
}
export const configuracionesReducer = configuracionesVentaSlice.reducer
export const {
  setAutorizacionModuloFactExportacion,
  setAutorizacionModuloCreditoFiscal,
  setAutorizacionModulo,
  toogleUsaDescuentoGlobal,
} = configuracionesVentaSlice.actions
