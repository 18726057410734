import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { NewPosition, Position } from '../../types/types'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import { TextBox, ValidationGroup, Validator } from 'devextreme-react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/store'
import { ValueChangedEvent as EventTextBox } from 'devextreme/ui/text_box'
import { ValueChangedEvent as EventHtmlEditor } from 'devextreme/ui/html_editor'
import {
  setPosition,
  updatePosition,
  updateStatus,
} from '../../store/editDataReducer'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import {
  clearButtonClick,
  setButtons,
  setNameTab,
} from '../../store/tabsReducer'
import { useLoaderPositions } from '../../customHooks'
import { useSetToast } from '../../../../../../hooks/useGlobalHooks'
import { TEstado, ToastTypes } from '../../../../../../store/types'
import VisualizaError from '../../../../../ventas/pages/shared/visualizaError/visualizaError'
import ListErrosValidationForm from '../../../../../componentes/listadoErroresValidacionForm'
import BlockUi from '../../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../../views/componentes/loadingindicator/loadingindicator'
import { defaultPosition } from '../..'
import { StatesEdition } from '../../../../../ventas/types/enums'
import { IGenericTabProps } from '../../../../store/types'
import CustomCol from '../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput'
import TipoEstadoLookUp from '../../../../../componentes/tipoEstado'
import HtmlEditor, { Toolbar, Item } from 'devextreme-react/html-editor'
import {
  fontValues,
  headerValues,
  sizeValues,
} from '../../../../../admin/backOffice/notificaciones/components/nuevo'
import {
  AsyncRule,
  PatternRule,
  RequiredRule,
} from 'devextreme-react/data-grid'
import { PositionsServices } from '../../services/positions.services'

const NewCandidateComponent: FunctionComponent<IGenericTabProps<Position>> = (
  props,
) => {
  const { tabId, tab } = props
  const dispatch = useDispatch()
  const setToastMessage = useSetToast()
  const { showLoaderTabs } = useLoaderPositions()


  const newPosition = useSelector((state: RootState) => {
    return state.nomina.cargos.editData[tabId]
  })
  const globalButtonClick = useSelector((state: RootState) => {
    return state.nomina.cargos.tabs.tabs[tabId].globalButtonClick
  })
  const tabs = useSelector((state: RootState) => {
    return state.nomina.cargos.tabs
  })
  const loader = useSelector((state: RootState) => {
    return state.nomina.cargos.editData[tabId].loader
  })

  const validationFormPosition = useRef<any>()
  const htmlEditorRef = useRef<any>(null)
  const [validationFormErrors, setValidationFormErrors] = useState<string[]>([])
  const [showPopupError, setShowPopupError] = useState<boolean>(false)

  const updateFormField = useCallback(
    (evt: EventTextBox | EventHtmlEditor, valueToUpdate: string) => {
      if (evt.event)
        dispatch(
          updatePosition({
            key: tabId,
            position: {
              ...newPosition,
              [valueToUpdate]: evt.value,
            },
          }),
        )
    },
    [dispatch, newPosition, tabId],
  )

  const updateStatusField = useCallback(
    (status: TEstado) => {
      dispatch(
        updateStatus({
          key: tabId,
          estado: status,
        }),
      )
    },
    [dispatch, tabId],
  )

  const asyncValidation = useCallback((value: TEstado, field: string) => {
    if (value?.codigo === -1)
      return Promise.reject(
        `El campo ${field} no se encuentra seleccionado, por favor elija una opción.`,
      )
    return Promise.resolve()
  }, [])

  const onHandleUndo = useCallback(() => {
    if (htmlEditorRef.current)
      htmlEditorRef.current.instance.option(
        'value',
        tab.editStatus === StatesEdition.edit ? tab.info.info.contrato : '',
      )
    const positionData: NewPosition =
      tab.editStatus === StatesEdition.edit
        ? {
            codigo: tab.info.info.codigo,
            descripcion: tab.info.info.descripcion,
            contrato: tab.info.info.contrato,
            estado: { codigo: tab.info.info.estado, descripcion: '' },
            loader: { show: false, mensaje: '' },
          }
        : defaultPosition
    dispatch(
      setPosition({
        key: tabId,
        data: positionData,
      }),
    )
  }, [dispatch, tab, tabId])

  const onHandleSaveSucessfull = useCallback(
    (positionCode: number) => {
      dispatch(
        setNameTab({
          key: tabId,
          codigo: positionCode,
          nombre: newPosition.descripcion,
        }),
      )
      dispatch(
        setButtons({
          tabKey: tabId,
          buttons: {
            Nuevo: true,
            Guardar: false,
            Editar: false,
            Eliminar: false,
            Buscar: true,
            Imprimir: true,
            Deshacer: false,
            Limpiar: false,
            Permisos: false,
            Asignar_Roles_Organigrama: true,
          },
        }),
      )
    },
    [dispatch, newPosition, tabId],
  )

  const onHandleSave = useCallback(async () => {
    showLoaderTabs(
      true,
      tabId,
      false,
      'Guardando Cargo . . .',
      ButtonTypes.save,
    )
    let validationFormResult =
      validationFormPosition.current.instance.validate()
    if (validationFormResult.status === 'pending')
      validationFormResult = await validationFormResult.complete
    if (!validationFormResult.isValid) {
      const errorList: string[] = []
      validationFormResult.brokenRules.forEach((rule) => {
        errorList.push(rule.message)
      })
      setValidationFormErrors(errorList)
      setShowPopupError(true)
      setToastMessage(
        'Guardar Cargo',
        'Existen Errores en los datos del formulario, por favor verifiquelos.',
        ToastTypes.Warning,
      )
      showLoaderTabs(false, tabId)
    } else {
      try {
        const savePosition = await PositionsServices.savePosition<number>({
          infoRegistro: {
            codigo: newPosition.codigo,
            descripcion: newPosition.descripcion,
            contrato: newPosition.contrato,
            estado: newPosition.estado?.codigo ?? -1,
          },
        })
        if (!savePosition.error && savePosition.auto) {
          if (
            typeof savePosition.auto === 'number' &&
            !isNaN(savePosition.auto)
          )
            onHandleSaveSucessfull(savePosition.auto)
        }
        setToastMessage(
          'Guardar Cargo',
          savePosition.message,
          savePosition.error ? ToastTypes.Warning : ToastTypes.Success,
        )
        showLoaderTabs(false, tabId)
      } catch (error) {
        showLoaderTabs(false, tabId)
        setToastMessage('Guardar Cargo', error, ToastTypes.Danger)
      }
    }
  }, [
    showLoaderTabs,
    tabId,
    setToastMessage,
    newPosition,
    onHandleSaveSucessfull,
  ])

  const onMenuButtonClick = useCallback(
    (actionType: string) => {
      switch (actionType) {
        case ButtonTypes.save:
          if (tabs.current === tabId) onHandleSave()
          break
        case ButtonTypes.undo:
          if (tabs.current === tabId) onHandleUndo()
          break
        default:
          break
      }
      dispatch(clearButtonClick(tabId))
    },
    [dispatch, onHandleSave, onHandleUndo, tabId, tabs],
  )

  useEffect(() => {
    if (globalButtonClick !== ButtonTypes.none)
      onMenuButtonClick(globalButtonClick)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalButtonClick])

  return (
    <>
      {showPopupError && (
        <VisualizaError
          titulo="Error en Cargos"
          mensaje={'Error al guardar'}
          content={
            <ListErrosValidationForm
              mainMessage={
                'Antes de guardar la información revise lo siguiente:'
              }
              errorsList={validationFormErrors}
              color={'danger'}
            />
          }
          onOk={() => setShowPopupError(false)}
        />
      )}
      <BlockUi
        tag="div"
        loader={LoadingIndicator}
        blocking={loader.show && tabId === tabs.current}
        message={loader.mensaje}
      >
        <RowContainer className="m-2">
          <ValidationGroup
            key={'valitadationPosition'}
            id={'valitadationPosition'}
            ref={validationFormPosition}
          >
            <RowContainer>
              <CustomCol xs="12" md="4">
                <Labeled label="Cargo:">
                  <TextBox
                    inputAttr={{ autocomplete: 'nope' }}
                    value={newPosition.descripcion}
                    onValueChanged={(evt: EventTextBox) =>
                      updateFormField(evt, 'descripcion')
                    }
                    showClearButton={true}
                    maxLength={50}
                  >
                    <Validator>
                      <RequiredRule message="El campo Cargo es requerido." />
                      <PatternRule
                        pattern="^[a-zA-Z ]{4,}$"
                        message="El campo Cargo debe tener al menos 4 letras."
                      />
                    </Validator>
                  </TextBox>
                </Labeled>
              </CustomCol>
              <CustomCol xs="12" md="3">
                <Labeled label="Estado:">
                  <TipoEstadoLookUp
                    onChanged={(status: TEstado) => updateStatusField(status)}
                    selected={newPosition.estado}
                  >
                    <Validator>
                      <AsyncRule
                        validationCallback={({ value }) =>
                          asyncValidation(value, 'Estado')
                        }
                      />
                      <RequiredRule />
                    </Validator>
                  </TipoEstadoLookUp>
                </Labeled>
              </CustomCol>
            </RowContainer>
            <CustomCol xs="12" md="7">
              <Labeled label="Contrato:">
                <HtmlEditor
                  ref={htmlEditorRef}
                  height="400px"
                  defaultValue={
                    tab.editStatus === StatesEdition.edit
                      ? newPosition.contrato
                      : ''
                  }
                  valueType={'html'}
                  onValueChanged={(evt: EventHtmlEditor) =>
                    updateFormField(evt, 'contrato')
                  }
                >
                  <Toolbar multiline={true}>
                    <Item name="undo" />
                    <Item name="redo" />
                    <Item name="separator" />
                    <Item name="size" acceptedValues={sizeValues} />
                    <Item name="font" acceptedValues={fontValues} />
                    <Item name="separator" />
                    <Item name="bold" />
                    <Item name="italic" />
                    <Item name="strike" />
                    <Item name="underline" />
                    <Item name="separator" />
                    <Item name="alignLeft" />
                    <Item name="alignCenter" />
                    <Item name="alignRight" />
                    <Item name="alignJustify" />
                    <Item name="separator" />
                    <Item name="orderedList" />
                    <Item name="bulletList" />
                    <Item name="separator" />
                    <Item name="header" acceptedValues={headerValues} />
                    <Item name="separator" />
                    <Item name="color" />
                    <Item name="background" />
                    <Item name="separator" />
                    <Item name="link" />
                    <Item name="image" />
                    <Item name="separator" />
                    <Item name="clear" />
                    <Item name="blockquote" />
                    <Item name="separator" />
                  </Toolbar>
                  <Validator>
                    <RequiredRule
                      message={'Contrato: Este campo es requerido.'}
                    />
                  </Validator>
                </HtmlEditor>
              </Labeled>
            </CustomCol>
          </ValidationGroup>
        </RowContainer>
      </BlockUi>
    </>
  )
}

export default NewCandidateComponent
