import { OptionCiudad } from '../../../../../containers/component/formLocal/types/types'
import { TabsState } from '../../../../../store/genericTabTypes'
import { CustomDictionary, LoaderInfo } from '../../../../../store/types'
import { CargoDepartamento } from '../../../../componentes/cargosDepartamentosLookup/types/types'
import { CargoRol } from '../../../../componentes/cargosLookup/types/types'

export type CandidatesState = {
  tabs: TabsState<Candidate>
  editData: CustomDictionary<NewCandidate>
  search: SearchState<Candidate>
}

export type SearchState<Candidate> = {
  filter: FiltersCandidate
  candidatesList: Candidate[]
  collapsedFilter: boolean
  loader: LoaderInfo
  selected: Candidate
  isSearch: boolean
}

export type FiltersCandidate = {
  apellidos: string
  nombres: string
  identificacion: string
  cargo: CargoRol | null
  registroDesde: string
  registroHasta: string
  liderazgo: boolean
  actitud: boolean
  comunicacion: boolean
  flexibilidad: boolean
  colaboracion: boolean
  dedicacion: boolean
  confianza: boolean
  integridad: boolean
  iniciativa: boolean
  aprendizaje: boolean
}

export type FiltersCandidateApi = {
  rolCodigo: number
  identificacion: string
  apellidos: string
  nombres: string
  capLiderazgo: number
  actitudPositiva: number
  comunicacion: number
  flexibilidad: number
  trabajoEquipo: number
  dedicacion: number
  confianza: number
  honestidad: number
  iniciativa: number
  desaprender: number
  fechaDesde: string
  fechaHasta: string
}

export type Candidate = {
  codigo: number
  fecha: string
  ciudad: number
  ciudadNombre: string
  rol: number
  rolDescripcion: string
  estado: number
  nombres: string
  apellidos: string
  identificacion: string
  telefono: string
  email: string
  liderazgo: boolean
  actitud: boolean
  comunicacion: boolean
  flexibilidad: boolean
  colaboracion: boolean
  dedicacion: boolean
  confianza: boolean
  integridad: boolean
  iniciativa: boolean
  aprendizaje: boolean
  carpeta: string
}

export type NewCandidate = Omit<
  Candidate,
  'ciudad' | 'ciudadNombre' | 'rol' | 'rolDescripcion' | 'estado'
> & {
  departamento: CargoDepartamento | null
  aplica: CargoRol | null
  ciudad: OptionCiudad | null
  loader: LoaderInfo
}

export type SaveCandidate = Omit<
  NewCandidate,
  'ciudad' | 'departamento' | 'aplica' | 'loader' | 'carpeta'
> & {
  aplica: number
  ciudad: number
  curriculum: File | null
}

export const CheckBoxArrayLeft = [
  {
    name: 'liderazgo',
    title: 'Capacidad de Liderazgo',
  },
  {
    name: 'actitud',
    title: 'Actitud Positiva',
  },
  {
    name: 'comunicacion',
    title: 'Comunicación',
  },
  {
    name: 'flexibilidad',
    title: 'Flexibilidad',
  },
  {
    name: 'colaboracion',
    title: 'Trabajo en Equipo',
  },
]

export const CheckBoxArrayRigth = [
  {
    name: 'dedicacion',
    title: 'Dedicación',
  },
  {
    name: 'confianza',
    title: 'Confianza',
  },
  {
    name: 'integridad',
    title: 'Honestidad e Integridad',
  },
  {
    name: 'iniciativa',
    title: 'Iniciativa',
  },
  {
    name: 'aprendizaje',
    title: 'Deseo de Aprender',
  },
]
