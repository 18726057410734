const getLastZIndex = () => {
  const elem = document.getElementsByTagName('*')
  let zIndexMax = Number.MIN_SAFE_INTEGER

  for (let i: number = 0; i < elem.length; i++) {
    const zIndex = parseInt(window.getComputedStyle(elem[i]).zIndex)

    if (zIndex > zIndexMax) {
      zIndexMax = zIndex
    }
  }

  return zIndexMax ?? 9
}

const getIncrementZIndex = () => {
  return getLastZIndex() + 1
}

export { getLastZIndex, getIncrementZIndex }
