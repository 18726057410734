export type TipoIdentificacion = {
  codigo: string
  descripcion: string
  codTributario?: string
}

export type TipoIdentificacionSub = {
  codigo: number
  valor: string
  tipoCodigo: string
  grupo: string
  orden: number
  estado: string
}

export enum TipoTransaccion {
  compra = 'COMPRA',
  venta = 'VENTA',
}
