import { DateUtils, formatoFechasApi } from "../../../../helpers/dateUtils";
import { RequestHelper } from "../../../../helpers/requestHelper";
import { utilidades } from "../../../../helpers/utilidades";
import { SesionService } from "../../../../services/sesion.service";
import { ESubtipo } from "../../../../store/enum/subtypes";
import { ClasificacionOption } from "../store/type";

export const clasificacionesService = { getClasificacionesAdicionales };

async function getClasificacionesAdicionales(label: string): Promise<any> {
  try {
    const sesion = SesionService.getCurrentSesion()
    const data: any = {
      mensaje: label,
      tipoCodigo: ESubtipo.Clasificaciones,
    };
    const keysToCache: any = {
      ...data,
      dateKey: DateUtils.getCurrentDateAsString(formatoFechasApi),
      empresa: sesion?.empresa?.codigo,
    }
    const cacheKey = 'tipoClasificaciones' + JSON.stringify(keysToCache);
    const cached = sessionStorage.getItem(cacheKey);
    if (cached) {
      return { error: false, auto: JSON.parse(cached) };
    } else {
      const res = await RequestHelper.getAll<any>('subtypes', 'cboxLoad', "", data);
      const opt: Array<ClasificacionOption> = [];
      if (res?.auto && res?.auto.length > 0) {
        for (const x of res.auto) {
          opt.push({
            codigo: parseInt(x.codigo) ?? -1,
            descripcion: utilidades.capText(x?.valor) ?? '',
            tipoCodigo: x?.tipoCodigo ?? 0,
            grupo: x?.grupo ?? '',
            orden: parseInt(x?.orden) ?? 1,
            estado: parseInt(x?.estado) === 1 ? true : false,
          })
        }
        res.auto = opt
      }
      sessionStorage.setItem(cacheKey, JSON.stringify(opt));
      return res;
    }
  } catch (error) {
    return error;
  }
}

