import React from 'react'
import {
  CBadge,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormInput,
} from '@coreui/react-pro'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput'

const ItemDetails: React.FunctionComponent<any> = (props) => {
  const { data } = props
  return (
    <RowContainer>
      <CCol xs="12" md="10">
        <CCard
          className="border-top-primary border-top-3"
          id="busqueda-modVentas-resumen"
        >
          <CCardHeader>
            <span style={{ float: 'left' }}>
              <CBadge color="secondary" textColor="black">
                <strong>{`Código: ${data.itemCodigo}`}</strong>
              </CBadge>
            </span>
          </CCardHeader>
          <CCardBody>
            <RowContainer>
              <CustomCol sm="12" md="2">
                <Labeled label="Código de usuario:">
                  <CFormInput
                    value={data.itemCodigoUsuario}
                    readOnly={true}
                    size="sm"
                  />
                </Labeled>
              </CustomCol>
              <CustomCol sm="12" md="2">
                <Labeled label="Código de barras:">
                  <CFormInput
                    value={data.itemBarras}
                    readOnly={true}
                    size="sm"
                  />
                </Labeled>
              </CustomCol>
              <CustomCol sm="12" md="5">
                <Labeled label="Descripción:">
                  <CFormInput
                    value={data.itemDescripcion}
                    readOnly={true}
                    size="sm"
                  />
                </Labeled>
              </CustomCol>
              <CustomCol sm="12" md="1">
                <Labeled label="Tipo:">
                  <CFormInput
                    value={data.itemTipoDescripcion}
                    readOnly={true}
                    size="sm"
                  />
                </Labeled>
              </CustomCol>
              <CustomCol sm="12" md="2">
                <Labeled label="Stock Físico:">
                  <CFormInput
                    value={data.itemExistencia}
                    readOnly={true}
                    size="sm"
                  />
                </Labeled>
              </CustomCol>
            </RowContainer>
            <RowContainer>
              <CustomCol sm="12" md="2">
                <Labeled label="PVP:">
                  <CFormInput value={data.itemPVP} readOnly={true} size="sm" />
                </Labeled>
              </CustomCol>
              <CustomCol sm="12" md="2">
                <Labeled label="PVP IVA:">
                  <CFormInput
                    value={data.itemPVPIVA}
                    readOnly={true}
                    size="sm"
                  />
                </Labeled>
              </CustomCol>
              <CustomCol sm="12" md="2">
                <Labeled label="PVD:">
                  <CFormInput value={data.itemPVD} readOnly={true} size="sm" />
                </Labeled>
              </CustomCol>
              <CustomCol sm="12" md="2">
                <Labeled label="PVD IVA:">
                  <CFormInput
                    value={data.itemPVDIVA}
                    readOnly={true}
                    size="sm"
                  />
                </Labeled>
              </CustomCol>
              <CustomCol sm="12" md="2">
                <Labeled label="IVA (%):">
                  <CFormInput
                    value={data.itemImpuestoValor}
                    readOnly={true}
                    size="sm"
                  />
                </Labeled>
              </CustomCol>
              <CustomCol sm="12" md="2">
                <Labeled label="Costo:">
                  <CFormInput
                    value={data.itemCosto}
                    readOnly={true}
                    size="sm"
                  />
                </Labeled>
              </CustomCol>
            </RowContainer>
            <RowContainer>
              <CustomCol sm="12" md="4">
                <Labeled label="Línea:">
                  <CFormInput
                    value={data.itemLineaDescripcion}
                    readOnly={true}
                    size="sm"
                  />
                </Labeled>
              </CustomCol>
              <CustomCol sm="12" md="4">
                <Labeled label="Grupo:">
                  <CFormInput
                    value={data.itemGrupoDescripcion}
                    readOnly={true}
                    size="sm"
                  />
                </Labeled>
              </CustomCol>
              <CustomCol sm="12" md="4">
                <Labeled label="Marca:">
                  <CFormInput
                    value={data.itemMarcaDescripcion}
                    readOnly={true}
                    size="sm"
                  />
                </Labeled>
              </CustomCol>
            </RowContainer>
            <RowContainer>
              <CustomCol sm="12" md="2">
                <Labeled label="Procedencia:">
                  <CFormInput
                    value={data.itemProcedenciaDescripcion}
                    readOnly={true}
                    size="sm"
                  />
                </Labeled>
              </CustomCol>
              <CustomCol sm="12" md="2">
                <Labeled label="Serie:">
                  <CFormInput
                    value={data.itemNumeroSerieDescripcion}
                    readOnly={true}
                    size="sm"
                  />
                </Labeled>
              </CustomCol>
              <CustomCol sm="12" md="2">
                <Labeled label="Lote:">
                  <CFormInput
                    value={data.itemLoteDescripcion}
                    readOnly={true}
                    size="sm"
                  />
                </Labeled>
              </CustomCol>
              <CustomCol sm="12" md="2">
                <Labeled label="Unidad Venta:">
                  <CFormInput
                    value={data.itemUnidadVentaDescripcion}
                    readOnly={true}
                    size="sm"
                  />
                </Labeled>
              </CustomCol>
              <CustomCol sm="12" md="2">
                <Labeled label="Stock Reservas:">
                  <CFormInput
                    value={data.itemReservas}
                    readOnly={true}
                    size="sm"
                  />
                </Labeled>
              </CustomCol>
              <CustomCol sm="12" md="2">
                <Labeled label="Notas:">
                  <CFormInput value={data.itemNota} readOnly={true} size="sm" />
                </Labeled>
              </CustomCol>
            </RowContainer>
          </CCardBody>
        </CCard>
      </CCol>
    </RowContainer>
  )
}

export default ItemDetails
