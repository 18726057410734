import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  CCard,
  CCardHeader,
  CCardBody,
  CRow,
  CCol,
  CAlert,
} from '@coreui/react-pro'
import ScrollView from 'devextreme-react/scroll-view'
import LoadPanel from 'devextreme-react/load-panel'
import RowContainer from '../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../views/componentes/colContainer'
import { addToast } from '../../../store/toasterReducer'
import { changeLoader } from '../../../store/reducers'
import { RootState } from '../../../store/store'
import { AccionMenu, ToastTypes } from '../../../store/types'
import config from '../../../config/config'
import Modalform from '../../../views/componentes/modalform'
import { GlobalMenu } from '../../../views/componentes/globalMenu/globalMenu'
import { ButtonTypes } from '../../../views/componentes/globalMenu/types'
import Buscar from './componentes/buscar/buscar'
import {
  setCurrentAction,
  setCurrentFunction,
  setDatosEdicion,
  setInfoAdicional,
  setResetSeleccion,
} from './store/modalAutorizacion'
import { InfoAdicional } from './store/types'
import FormularioEdicion, {
  defaulDatosEdicionAtorizacion,
} from './componentes/formularioEdicion/formularioEdicion'
import { lh, MessagesKeys } from '../../../helpers/localizationHelper'
import Dialog from '../../../views/componentes/librerias/bootstrap-dialog'

interface IAdminAutorizacionesModalProps extends React.PropsWithChildren {
  onChanged: (dataAutorizacion: any) => void
  onCancel: () => void
  show: boolean
  modulo: number
  data?: InfoAdicional
}

export const AdminAutorizacionesModal: React.FC<
  IAdminAutorizacionesModalProps
> = (props) => {
  const dispatch = useDispatch()
  const loader = useSelector((state: RootState) => state.global.loader)
  const currentAction = useSelector(
    (state: RootState) => state.compras.compras.modalAutorizacion.currentAction,
  )
  const seleccionado = useSelector(
    (state: RootState) => state.compras.compras.modalAutorizacion.seleccionado,
  )

  const { show, onCancel, onChanged, modulo, data } = props

  const dialogRef = React.useRef<any>(null)

  const [acciones, setAcciones] = React.useState<Array<AccionMenu> | []>([])

  const generaBotones = React.useCallback(() => {
    const botonesLimpiar: any = []
    config['menuDefecto'].map((acc) => {
      if (acc.text !== 'Eliminar') {
        let boton: any = null
        boton = {
          nombre: acc.id,
          icon: acc.icon, //as any,
          actionType: acc.type,
          main: false,
          modal: false,
          shortcut: null,
        }
        botonesLimpiar.push(boton)
      }
    })
    const botones = botonesLimpiar.map((acc) => {
      const boton: AccionMenu = {
        nombre: acc.nombre,
        icon: acc.icon, //as any,
        actionType: acc.actionType,
        main: acc.main,
        modal: acc.modal,
        shortcut: acc.shortcut,
      }
      return boton
    })
    setAcciones(botones)
  }, [])

  const getButtonDisabled = React.useCallback(
    (button: AccionMenu) => {
      switch (button.actionType) {
        case ButtonTypes.find:
        case ButtonTypes.undo:
          return false
        case ButtonTypes.new: {
          return currentAction !== 'Buscar'
        }
        case ButtonTypes.delete:
        case ButtonTypes.edit: {
          if (currentAction !== 'Buscar') {
            return false
          } else {
            if (seleccionado && seleccionado.codigo) {
              return false
            } else {
              return true
            }
          }
        }
        case ButtonTypes.save: {
          return currentAction === 'Buscar'
        }
        default:
          return true
      }
    },
    [currentAction, seleccionado],
  )

  const returnAction = (boton: AccionMenu) => {
    switch (boton.actionType) {
      case ButtonTypes.find:
        if (currentAction === 'Buscar') {
          dispatch(setCurrentFunction('Buscar'))
          return false
        } else if (currentAction === 'Editar') {
          dispatch(setCurrentAction('Buscar'))
        } else {
          dispatch(setCurrentAction('Buscar'))
        }
        break
      case ButtonTypes.new:
        dispatch(
          setDatosEdicion({ ...defaulDatosEdicionAtorizacion, mode: 'new' }),
        )
        dispatch(setCurrentAction('Nuevo'))
        dispatch(setResetSeleccion())
        break
      case ButtonTypes.save:
        if (currentAction === 'Nuevo') {
          dispatch(setCurrentFunction(ButtonTypes.save))
          return false
        } else if (currentAction === 'Editar') {
          dispatch(setCurrentFunction(ButtonTypes.save))
          return false
        } else {
          setToast('No se encuentra cambios que realizar.', 'info')
        }
        break
      case ButtonTypes.undo:
        dispatch(setCurrentFunction(ButtonTypes.undo))
        break
      case ButtonTypes.edit:
        if (seleccionado && seleccionado.codigo) {
          onConfirmarUpdate()
          return false
        } else {
          setToast(
            'No se encuentra un registro seleccionado, Por favor seleccione un Item para editarlo.',
            'info',
          )
          return false
        }
      default:
        break
    }
  }

  const setToast = React.useCallback(
    (texto, type) => {
      let time: number = 3000
      if (texto.length < 50) {
        time = 5000
      } else if (texto.length > 50) {
        time = 9000
      }
      let tipo: ToastTypes = ToastTypes.Info
      if (type === 'warning') {
        tipo = ToastTypes.Warning
      } else if (type === 'success') {
        tipo = ToastTypes.Success
      }
      if (type === 'info') {
        tipo = ToastTypes.Info
      }
      if (type === 'danger') {
        tipo = ToastTypes.Danger
      }
      dispatch(
        addToast({
          id: '',
          autoHide: time,
          title: 'Acatha',
          content: texto,
          fade: true,
          type: tipo,
        }),
      )
    },
    [dispatch],
  )

  const botonesModal = () => {
    return (
      <>
        <RowContainer>
          <CustomCol xs="12" md="4">
            <GlobalMenu
              acciones={acciones}
              onClick={returnAction}
              getButtonDisabled={getButtonDisabled}
            />
          </CustomCol>
        </RowContainer>
      </>
    )
  }

  const playLoader = () => {
    dispatch(changeLoader({ show: true, mensaje: 'Cargando...' }))
  }

  const stopLoader = () => {
    dispatch(changeLoader({ show: false, mensaje: '' }))
  }

  const onConfirmarUpdate = React.useCallback(() => {
    const sms = lh.getMessage(MessagesKeys.FormConfirmChangeForm)
    const sms_replace = sms.replace(
      '@dinamic_var@',
      'Autorización : ' + (seleccionado?.numeroAutorizacion ?? 's/n'),
    )
    dialogRef.current.show({
      title: (
        <>
          <strong>{'Ácatha'}</strong>
        </>
      ),
      body: (
        <label style={{ fontSize: '14px', fontWeight: 600 }}>
          {sms_replace}
        </label>
      ),
      actions: [
        Dialog.Action(
          <span>
            <u>{'A'}</u>
            {'ceptar'}
          </span>,
          (dialog) => {
            dispatch(
              setDatosEdicion({
                ...defaulDatosEdicionAtorizacion,
                codigo: seleccionado?.codigo ?? 0,
                mode: 'save',
              }),
            )
            dispatch(setCurrentAction('Editar'))
            dialog.hide()
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>{'C'}</u>
            {'ancelar'}
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    return
  }, [seleccionado, dispatch])

  const onErrorConfirm = React.useCallback((messages: any, tittle?: string) => {
    dialogRef.current.show({
      title: (
        <>
          <strong>{'Ácatha'}</strong>
        </>
      ),
      body: (
        <>
          <strong style={{ fontSize: '14px' }}>{tittle}</strong>
          <br />
          <CAlert color="danger">
            <ul className="errorList">
              {messages.map(function (item, id) {
                return <li key={id}>{item}</li>
              })}
            </ul>
          </CAlert>
        </>
      ),
      actions: [
        Dialog.Action(
          <span>
            <u>{'A'}</u>
            {'ceptar'}
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>{'C'}</u>
            {'ancelar'}
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    return
  }, [])

  const bodyModal = () => {
    return (
      <>
        <RowContainer>
          <CustomCol xs="12" md="12">
            {(currentAction === 'Editar' || currentAction === 'Nuevo') && (
              <FormularioEdicion
                onChanged={(data) => console.log('onChanged', data)}
                currentTab={currentAction}
                playLoader={playLoader}
                stopLoader={stopLoader}
                setToast={setToast}
                onErrorConfirm={onErrorConfirm}
              />
            )}
            {currentAction === 'Buscar' && (
              <Buscar
                playLoader={playLoader}
                stopLoader={stopLoader}
                onChanged={onChanged}
              />
            )}
          </CustomCol>
        </RowContainer>
      </>
    )
  }
  // body form autorizacion
  const renderContent = () => {
    return (
      <>
        <CCard>
          <CCardHeader>{botonesModal()}</CCardHeader>
          <CCardBody>
            <ScrollView width="100%" height="100%">
              <div id="contain</div>er">
                <LoadPanel
                  shadingColor="rgba(0,0,0,0.4)"
                  position={'center'}
                  visible={loader.show}
                  showIndicator={true}
                  shading={true}
                  showPane={true}
                  message={loader.mensaje}
                  closeOnOutsideClick={false}
                />
                {bodyModal()}
              </div>
            </ScrollView>
          </CCardBody>
        </CCard>
      </>
    )
  }

  const iniciar = React.useCallback(() => {
    switch (modulo) {
      case 11:
        dispatch(
          setInfoAdicional({
            codigoTipoDocumento: data?.codigoTipoDocumento ?? '01',
            codigoAsociado: data?.codigoAsociado ?? 0,
            nombreAsociado: data?.nombreAsociado ?? '',
            codigoPertenece: data?.codigoPertenece ?? 0,
          }),
        )
        dispatch(setCurrentFunction('Buscar'))

        return false
      default:
        return true
    }
  }, [modulo, data, dispatch])

  React.useEffect(() => {
    if (show) {
      dispatch(setCurrentAction('Buscar'))
      iniciar()
    }
  }, [show])

  React.useEffect(() => {
    generaBotones()
  }, [])

  return (
    <>
      <div>
        <Dialog ref={dialogRef} />
      </div>
      <CRow key="rowMain">
        <CCol key="colMain">
          <Modalform
            name="apis"
            key="Autorizaciones"
            headerTitle={'Autorizaciones'}
            childrenBody={renderContent()}
            childrenFooter={<></>}
            closeOnBackdrop={false}
            show={props.show}
            onClose={onCancel}
            size="lg"
          />
        </CCol>
      </CRow>
    </>
  )
}
