import React from 'react'
import { CBadge, CCard, CCardBody, CCardFooter, CCardHeader, CFormInput } from '@coreui/react-pro'
import DataGrid, { Column } from 'devextreme-react/data-grid'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import { consoleService } from '../../../../../../../services/console.service'
import { SmallLoadingIndicator } from '../../../../../../../views/componentes/loadingindicator/loadingindicator'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput'
import { utilidades } from '../../../../../../../helpers/utilidades'
import { getIndexProvider } from '../../../../../../shared/helpers/funciones'
import { incomeExpensesServices } from '../../../ingresosEgresos/services/ingresosEgresos.services'
import { getTypesMovements } from '../../helper/helper'
import { ETypeMovimiento } from '../../types/types'
import { bancoService } from '../../../../../../bancos/service/bancos.service'
import { DateUtils } from '../../../../../../../helpers/dateUtils'
import { NumberBox } from 'devextreme-react'

const DetalleIngresoEgreso: React.FunctionComponent<any> = (props) => {
  const empresa = useSelector((state: RootState) => state.global.session.empresa)
  const proyectosProv = useSelector((state: RootState) => state.shared.providers.proyectos)

  const [loading, setLoading] = React.useState(false)
  const [loaded, setLoaded] = React.useState(false)
  const [detalles, setDetalles] = React.useState<Array<any>>([])
  const [almacen, setAlmacen] = React.useState<string>('')
  const [proyecto, setProyecto] = React.useState<string>('')
  const [punto, setPunto] = React.useState<string>('')
  const [establecimiento, setEstablecimiento] = React.useState<string>('')
  const [asociado, setAsociado] = React.useState<string>('')
  const [documento, setDocumento] = React.useState<string>('')
  const [showAsociado, setShowAsociado] = React.useState<boolean>(false)

  const [subTotalIva, setSubTotalIva] = React.useState<number>(0)
  const [subTotalIva0, setSubTotalIva0] = React.useState<number>(0)
  const [descuento, setDescuento] = React.useState<number>(0)
  const [descuento0, setDescuento0] = React.useState<number>(0)
  const [IVA, setIVA] = React.useState<number>(0)
  const [IVAEmp, setIVAEmp] = React.useState<number>(0)
  const [total, setTotal] = React.useState<number>(0)

  const onGetDeatilMove = React.useCallback(async (codigo: number) => {
    try {
      const res = await incomeExpensesServices.getIncomeExpensesDeatil(codigo)
      if (res !== null && res !== undefined && res['error'] === false) {
        return res['auto']
      }
      return []
    } catch (error) {
      return []
    }
  }, [])

  const onGetMovimientos = React.useCallback(async (codigo: number) => {
    try {
      const res = await getTypesMovements()
      if (res !== null && res !== undefined && res.length > 0) {
        const mov = res.find(item => item.codigo === codigo)
        return mov
      }
      return null
    } catch (error) {
      return null
    }
  }, [])

  const generarOptionsProyectos = React.useCallback((obtenidos) => {
    const objeto: any = []
    Object.keys(obtenidos).map(function (key, i) {
      objeto.push({
        codigo: Number(obtenidos[key]['codigo']),
        nombre: obtenidos[key]['nombre'],
        locales: obtenidos[key]['locales']?.map(det => {
          if (det !== false) {
            const local: any = {
              proyectoCodigo: Number(obtenidos[key]['codigo']),
              proyectoNombre: obtenidos[key]['nombre'],
              ...det
            }
            return local;
          }

        })
      })
    })
    return objeto
  }, []);

  const generarLocales = React.useCallback(async (obtenidos) => {

    const proyectos_object: any = []
    Object.keys(obtenidos).map((key, i) => {
      if (obtenidos[key]['locales'].length > 0) {
        proyectos_object.push(...obtenidos[key]['locales'])
      }
    })
    // buscar el local principal de la empresa y retornarlo en el lugar de general
    if (proyectos_object.length > 0) {
      const local_object: any = []
      proyectos_object.forEach(function (tipo_I_) {
        if (tipo_I_ !== 'false' && tipo_I_ !== false && tipo_I_ !== undefined) {
          const to_array_proy: any = {
            codigo: Number(tipo_I_['LOC_CODIGO']),
            nombre:
              tipo_I_['LOC_NOMBRE'] === 'Elija una opción' ? 'General' : tipo_I_['LOC_NOMBRE'],
            proyectoCodigo: Number(tipo_I_['proyectoCodigo']),
            proyectoNombre: String(tipo_I_['proyectoNombre'])

          }
          local_object.push(to_array_proy)
        }
      })
      return local_object
    }
  }, []);
  const generarOptionsLocales = React.useCallback((obtenidos) => {
    const objeto: any = []
    Object.keys(obtenidos).map(function (key, i) {
      objeto.push({
        codigo: Number(obtenidos[key]['localCodigo']),
        nombre: obtenidos[key]['localNombre'],
        proyectoCodigo: Number(obtenidos[key]['codigo']),
        proyectoNombre: obtenidos[key]['nombre']
      })
    })
    return objeto
  }, []);

  const onGetIVA = React.useCallback(async (fecha: string) => {
    try {
      const iva = await incomeExpensesServices.getIva(fecha, empresa?.codigoTipoNegocio ?? 0, empresa?.codigoPais ?? 0)
      if (iva !== null && iva !== undefined && iva['error'] === false) {
        return iva['auto']
      }
      return 0
    } catch (error) {
      return 0
    }
  }, [empresa])

  const onTotales = React.useCallback((data: any[], IVAEmp: number) => {
    // const precision = empresa !== null ? empresa.precision : 2
    setDescuento(0)
    setDescuento0(0)
    let subTotIva: number = 0
    let subTotl0: number = 0
    let iva: number = 0
    let tot: number = 0
    let numItems: number = 0
    let cantidadItems: number = 0
    data.map(item => {
      cantidadItems = item.cantidad
      if (item?.impuesto === 3) {
        subTotIva += Number(item?.valorDescripcion) ?? 0
      } else {
        subTotl0 += Number(item?.valorDescripcion) ?? 0
      }
      numItems += cantidadItems
    })
    iva = (subTotIva - descuento) * (IVAEmp / 100)
    tot = subTotIva + subTotl0 - descuento - descuento0 + iva
    setIVAEmp(IVAEmp)
    setSubTotalIva(subTotIva)
    setSubTotalIva0(subTotl0)
    setIVA(iva)
    setTotal(tot)
  }, [descuento, descuento0])


  const cargarInformacionDetallada = React.useCallback(
    async (ingresosEgresos: any) => {

      setLoading(true)
      let local = null
      let proyectos = null
      const movimiento = await onGetMovimientos(ingresosEgresos?.movimiento)
      const detalle = await await onGetDeatilMove(ingresosEgresos?.codigo ?? 0)
      const localesApi = await bancoService.getLocales(empresa.codigo, 'Elije una opción');
      const IVARes = await onGetIVA(ingresosEgresos?.fecha)
      if (movimiento !== null && movimiento !== undefined && movimiento?.asociado == ETypeMovimiento.compras) {
        setAsociado('Proveedor:')
        setShowAsociado(true)
      } else if (movimiento !== null && movimiento !== undefined && movimiento?.asociado == ETypeMovimiento.ventas) {
        setAsociado('Cliente:')
        setShowAsociado(true)
      }
      if (proyectosProv && proyectosProv.length > 0) {
        const proyectosData = generarOptionsProyectos(proyectosProv);
        if (proyectosData.length > 0) {
          const localesData = await generarLocales(proyectosData);
          if (localesData) {
            const index = await getIndexProvider(localesData, 'codigo', ingresosEgresos?.localCodigo ?? 0)
            if (index > -1) {
              local = localesData[index]
            } else {
              local = localesData[0]
            }

            const indexProyecto = await getIndexProvider(proyectosData, 'codigo', local['proyectoCodigo'] ?? 0)
            if (indexProyecto > -1) {
              proyectos = proyectosData[indexProyecto]
            } else {
              proyectos = proyectosData[0]
            }
          }
        }

      }

      if (localesApi && localesApi.auto && localesApi.auto?.length > 0) {
        const localesData = generarOptionsLocales(localesApi.auto);
        const index = await getIndexProvider(localesData, 'codigo', ingresosEgresos?.localCodigo ?? 0)
        if (index > -1) {
          local = localesData[index]
        } else {
          local = localesData[0]
        }
      }

      setAlmacen(local?.nombre ?? '')
      setProyecto(proyectos?.nombre ?? '')
      const edDoc = ingresosEgresos?.documento.split("-")
      if (edDoc.length >= 3) {
        setEstablecimiento(edDoc[0])
        setPunto(edDoc[1])
        setDocumento(edDoc[2])
      } else {
        setDocumento(ingresosEgresos?.documento)
      }
      setDetalles(detalle)
      onTotales(detalle, IVARes)

      setLoaded(true)
      setLoading(false)
      consoleService.log(ingresosEgresos)
    },
    [onGetDeatilMove, onGetMovimientos, empresa, generarLocales, generarOptionsLocales, generarOptionsProyectos, proyectosProv, onTotales, onGetIVA],
  )

  React.useEffect(() => {
    cargarInformacionDetallada(props.data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  if (loading) {
    return <SmallLoadingIndicator mensaje="Cargando información adicional del registro..." />
  }

  if (!loaded) {
    return <></>
  }
  const ingresosEgresos = props.data as any
  return (
    <div>
      <RowContainer>
        <CustomCol xs="12">
          <CCard className="border-top-primary border-top-3" id="busqueda-modVentas-resumen">
            <CCardHeader>
              <span style={{ float: 'left' }}>
                <CBadge color="secondary" textColor="black">
                  <strong>
                    {`${ingresosEgresos?.documento ?? ''}-${ingresosEgresos?.tipo ?? ''}`}
                  </strong>
                </CBadge>
              </span>
            </CCardHeader>
            <CCardBody>
              <div className="container">
                <RowContainer>
                  <CustomCol xs="12" md="3" xl="4">
                    <Labeled label="Concepto:">
                      <CFormInput size="sm" readOnly type="string" value={`${ingresosEgresos?.movimientoDescripcion ?? ''}`} />
                    </Labeled>
                  </CustomCol>
                  {showAsociado && (
                    <CustomCol xs="6" md="3" xl="4">
                      <Labeled label={asociado}>
                        <CFormInput size="sm" readOnly value={ingresosEgresos?.productoClienteDescripcion ?? ''} />
                      </Labeled>
                    </CustomCol>
                  )}
                </RowContainer>
                <RowContainer>
                  {ingresosEgresos?.fecha && (
                    <CustomCol xs="6" md="3" xl="2">
                      <Labeled label="Fecha:">
                        <CBadge color="info">{`${ingresosEgresos?.fecha ?? ''}`}</CBadge>
                      </Labeled>
                    </CustomCol>
                  )}
                  <CustomCol xs="4" md="6" xl="2">
                    <Labeled label="Observaciones:">
                      <CFormInput value={`${ingresosEgresos?.observaciones ?? ''}`} readOnly={true} size="sm" />
                    </Labeled>
                  </CustomCol>
                  <CustomCol xs="12" md="6">
                    <Labeled label="Documento:">
                      <RowContainer>
                        <CustomCol xs="3">
                          <CFormInput value={establecimiento} readOnly={true} size="sm" />
                        </CustomCol>
                        <CustomCol xs="3">
                          <CFormInput value={punto} readOnly={true} size="sm" />
                        </CustomCol>
                        <CustomCol>
                          <CFormInput value={documento} readOnly={true} size="sm" />
                        </CustomCol>
                      </RowContainer>
                    </Labeled>
                  </CustomCol>
                </RowContainer>
                <RowContainer>
                  <CustomCol xs="6" md="3" xl="2">
                    <Labeled label="Proyeto:">
                      <CFormInput size="sm" readOnly value={proyecto} />
                    </Labeled>
                  </CustomCol>
                  <CustomCol xs="6" md="3" xl="2">
                    <Labeled label="Local:">
                      <CFormInput size="sm" readOnly value={almacen} />
                    </Labeled>
                  </CustomCol>
                </RowContainer>
                <RowContainer className="tabla-busqueda-modVentas-resumen">
                  <DataGrid
                    keyExpr={['codigo', 'cantidad']}
                    dataSource={detalles}
                    focusedRowEnabled
                    hoverStateEnabled
                    showBorders
                    width={utilidades.getGridFullWidth()}
                  >
                    <Column
                      dataField="codigoUsuario"
                      caption="Interno"
                      visible={false}
                      width="50px"
                    />
                    <Column
                      dataField="barras"
                      caption="Barras"
                      width="100px"
                    />
                    <Column
                      dataField="unidadDescripcion"
                      caption="Unidad"
                      width="100px"
                    />
                    <Column
                      dataField="descripcion"
                      caption="Item"
                      width="250px"
                    />
                    <Column
                      dataField="pvp"
                      caption="PVP IVA"
                      width="11px"
                      visible={false}
                    />
                    <Column
                      dataType='number'
                      dataField="cantidad"
                      caption="Cantidad"
                      width="100px"
                    />
                    <Column
                      dataType='number'
                      dataField="precio"
                      caption="Costo Unitario"
                      width="100px"
                    />
                    <Column
                      dataType='number'
                      dataField="valorDescripcion"
                      caption="Valor Original"
                      width="100px"
                    />
                    <Column
                      dataType='number'
                      dataField="valor"
                      caption="Total"
                      width="100px"
                    />
                  </DataGrid>
                </RowContainer>
                <RowContainer>
                  <CustomCol xs="6" md="2" xl="2">
                    <Labeled label="Subtotal IVA:">
                      <CFormInput size="sm" readOnly value={subTotalIva} />
                    </Labeled>
                  </CustomCol>
                  <CustomCol xs="6" md="2" xl="2">
                    <Labeled label="Subtotal IVA 0%:">
                      <NumberBox width="115px" format={{ precision: empresa?.precision ?? 2 }} readOnly value={subTotalIva0} />
                    </Labeled>
                  </CustomCol>
                  <CustomCol xs="6" md="2" xl="2">
                    <Labeled label={`Descuento ${IVAEmp}:`} >
                      <NumberBox width="115px" format={{ precision: empresa?.precision ?? 2 }} readOnly value={descuento} />
                    </Labeled>
                  </CustomCol>
                  <CustomCol xs="6" md="2" xl="2">
                    <Labeled label="Descuento 0:">
                      <NumberBox width="115px" format={{ precision: empresa?.precision ?? 2 }} readOnly value={descuento0} />
                    </Labeled>
                  </CustomCol>
                  <CustomCol xs="6" md="2" xl="2">
                    <Labeled label="IVA:">
                      <NumberBox width="115px" format={{ precision: empresa?.precision ?? 2 }} readOnly value={IVA} />
                    </Labeled>
                  </CustomCol>
                  <CustomCol xs="6" md="2" xl="2">
                    <Labeled label="Total:">
                      <NumberBox width="115px" format={{ precision: empresa?.precision ?? 2 }} readOnly value={total} />
                    </Labeled>
                  </CustomCol>
                </RowContainer>
              </div>
            </CCardBody>
            <CCardFooter></CCardFooter>
          </CCard>
        </CustomCol>
      </RowContainer>
    </div>
  )
}

export default React.memo(DetalleIngresoEgreso)
