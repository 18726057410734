import { CModal, CModalBody } from '@coreui/react-pro';
import React from 'react';
import { SmallLoadingIndicator } from '../loadingindicator/loadingindicator';

interface IModalLoaderProps extends React.PropsWithChildren {
  show: boolean,
  message: string
}

const ModalLoader: React.FunctionComponent<IModalLoaderProps> = (props) => {
  return (
    <CModal
      visible={props.show}
      backdrop={'static'}
    >
      <CModalBody>
        <SmallLoadingIndicator mensaje={props.message} />
      </CModalBody>
    </CModal>
  );
};

export default ModalLoader;
