import { DateUtils, formatoFechasApi } from "../../../../helpers/dateUtils";
import { RequestHelper } from "../../../../helpers/requestHelper";
import { SesionService } from "../../../../services/sesion.service";
import { ESubtipo } from "../../../../store/enum/subtypes";

export const estadosService = { getEstados };

async function getEstados(label: string): Promise<any> {
  try {
    const sesion = SesionService.getCurrentSesion()
    const data: any = {
      mensaje: label,
      tipoCodigo: ESubtipo.Estados
    };
    const keysToCache: any = {
      ...data,
      dateKey: DateUtils.getCurrentDateAsString(formatoFechasApi),
      empresa: sesion?.empresa?.codigo,
    }
    const cacheKey = 'tipoEstados' + JSON.stringify(keysToCache)
    const cachedData = sessionStorage.getItem(cacheKey)
    if (cachedData) {
      return { error: false, auto: JSON.parse(cachedData) }
    } else {
      const res = await RequestHelper.getAll<any>('subtypes', 'cboxLoad', "", data);
      return res;
    }
  } catch (error) {
    return error;
  }
}

