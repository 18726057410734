import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CustomDictionary, LoaderInfo } from "../../../../../../store/types";
import { DecimosDatosEdicion } from "../types/types";
import { EdicionPayload } from '../../../../store/types';


const initialState: CustomDictionary<DecimosDatosEdicion> = {}



const datosEditSlice = createSlice({
  name: 'decimosEdicion',
  initialState: initialState,
  reducers: {
    deleteEditData(state, action: PayloadAction<string>) {
      delete state[action.payload]
    },
    initDatosEdicion(
      state,
      action: PayloadAction<EdicionPayload<DecimosDatosEdicion>>,
    ) {
      if (!state[action.payload.key]) {
        state[action.payload.key] = action.payload.data
      }
    },
    setDatosEdicion(
      state,
      action: PayloadAction<EdicionPayload<DecimosDatosEdicion>>,
    ) {
      state[action.payload.key] = action.payload.data
    },
    setChangeLoader(state, action: PayloadAction<EdicionPayload<LoaderInfo>>) {
      state[action.payload.key].loader = action.payload.data
    },
  }
})



export const {
  setDatosEdicion,
  initDatosEdicion,
  deleteEditData,
  setChangeLoader,
} = datosEditSlice.actions
export const dataEditReducer = datosEditSlice.reducer