import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FETCH_STATUS } from '../../../../../store/types'
import { FilterSeriesState, SearchState, StoreListResult } from '../types/types'
import { MultistoreServices } from '../services/multialmacen.services'

const initialState: SearchState<StoreListResult> = {
  filter: {
    serie: '',
    min: '',
  },
  status: FETCH_STATUS.IDDLE,
  message: '',
  resultList: [],
  tabs: ['Cabecera', 'Detalle'],
  currentTab: 'Cabecera',
}

export const fetchSeries = createAsyncThunk<
  Array<StoreListResult>,
  FilterSeriesState
>('inventario/proyectos/listar', async (searchFilter) => {
  try {
    const seriesList = MultistoreServices.getStores(searchFilter)
    return seriesList
  } catch (error) {
    return Promise.reject(error)
  }
})

const searchSlice = createSlice({
  name: 'searchProjectsCC',
  initialState: initialState,
  reducers: {
    setFilter(state, action: PayloadAction<FilterSeriesState>) {
      state.filter.serie = action.payload.serie
      state.filter.min = action.payload.min
    },
    setCleanResult(state) {
      state.resultList = []
    },
    changeFilter(state, action: PayloadAction<FilterSeriesState>) {
      state.filter = action.payload
    },
    changeCurrentSearchTab(state, action: PayloadAction<string>) {
      state.currentTab = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSeries.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.message = ''
      }
    })
    builder.addCase(fetchSeries.fulfilled, (state, { payload }) => {
      state.resultList = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchSeries.rejected, (state, { error }) => {
      state.resultList = []
      state.status = FETCH_STATUS.FAILED
      state.message = error.message as string
    })
  },
})

export const {
  setFilter,
  changeFilter,
  setCleanResult,
  changeCurrentSearchTab,
} = searchSlice.actions

export const searchReducer = searchSlice.reducer
