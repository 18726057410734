import {PaginationInfo} from "../../../../../store/types";
import {TFiltroBusquedaUnidadMedidaState, TUnidadMedidaListado} from "../store/types";
import {RequestHelper} from "../../../../../helpers/requestHelper";

export const UnidadMedidaService = {
  getAllUnidadMedida,
  getUnidadMedida,
  postUnidadMedida,
  deleteUnidadMedida,
}

async function getAllUnidadMedida(filtro: TFiltroBusquedaUnidadMedidaState): Promise<any> {
  try {
    const query = buildQuery(filtro);
    const pagedResponse = await RequestHelper.getAll<any>('inventario/unidades', 'listar', '', query);
    pagedResponse.auto = pagedResponse.auto? parseResponse(pagedResponse.auto) : [];
    return pagedResponse;
  } catch (error) {
    return error;
  }
}

async function getUnidadMedida(id: any): Promise<any> {
  try {
    // const query = {
    //   codigo: id,
    // };
    // return await RequestHelper.get<any>('province', 'getProvince', '', query);
    return new Promise((resolve, reject) => {});
  } catch (error) {
    return error;
  }
}

async function postUnidadMedida(formData: any): Promise<any> {
  try {
    const body = {
      infoRegistro: {
        ...formData,
      }
    };

    return await RequestHelper.postAll<any>('inventario/catalogos/unidades', 'save', body);
  } catch (error) {
    return error;
  }
}

async function deleteUnidadMedida(id: any): Promise<any> {
  try {
    const query = {
      codigo: id
    }

    return await RequestHelper.deleteRequestAll<any>('inventario/catalogos/unidades', 'delete', '', query);
  } catch (error) {
    return error;
  }
}

// Private
function buildQuery(
  filtro: TFiltroBusquedaUnidadMedidaState,
  pagination?: PaginationInfo,
): any {

  const query: any = {
    estado: filtro.estado,
    ...(filtro.nombre && { nombre: filtro.nombre }),
    ...(filtro.siglas && { siglas: filtro.siglas }),
    ...(filtro.codigoTributario && { codigoTributario: filtro.codigoTributario }),
  }

  if (pagination) {
    query.page = pagination.current
    query.limit = pagination.size
    query.isPaged = true
  }

  const filters = ['descripcion', 'codigoTributario'];

  filters.forEach((field) => {
    if (filtro[field] && filtro[field].length > 0) {
      query[field] = filtro[field]
    }
  })

  return query;
}

function parseResponse(dataApi: any[]): Array<TUnidadMedidaListado> {
  return dataApi.map((item: any) => ({
    codigo: item.codigo,
    nombre: item.nombre,
    siglas: item.siglas,
    fraccion: item.fraccion,
    grupoCodigo: item.grupoCodigo,
    grupoNombre: item.grupoNombre,
    estadoCodigo: item.estadoCodigo,
    estadoNombre: item.estadoNombre,
    codigoTributario: item.codigoTributario,
  }))
}
