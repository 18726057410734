import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FETCH_STATUS } from '../../../../../store/types'
import { FilterSeriesState, SearchState, StoreListResult, TitleTabSeries } from '../types/types'
import { SeriesServices } from '../services/series.services'
import { initialFilterState } from '../components/buscar';

const initialState: SearchState<StoreListResult> = {
  filter: { ...initialFilterState },
  status: FETCH_STATUS.IDDLE,
  statusCom: FETCH_STATUS.IDDLE,
  message: '',
  resultList: [],
  resultListCom: [],
  tabs: ['Kardex de la Serie', 'Series por Comprobante'],
  currentTab: TitleTabSeries.kardex,
  colapsado: true,
  colapsadoAvanzado: true,
}

export const fetchSeries = createAsyncThunk<
  Array<StoreListResult>,
  FilterSeriesState
>('inventario/series/listar', async (searchFilter) => {
  try {
    const seriesList = SeriesServices.getSeries(searchFilter)
    return seriesList
  } catch (error) {
    return Promise.reject(error)
  }
})
export const fetchSeriesCom = createAsyncThunk<
  Array<StoreListResult>,
  FilterSeriesState
>('inventario/series/listarComprobante', async (searchFilter) => {
  try {
    const seriesList = SeriesServices.getSeriesCom(searchFilter)
    return seriesList
  } catch (error) {
    return Promise.reject(error)
  }
})

const searchSlice = createSlice({
  name: 'searchMultistore',
  initialState: initialState,
  reducers: {
    setFilter(state, action: PayloadAction<FilterSeriesState>) {
      state.filter.serie = action.payload.serie
      state.filter.min = action.payload.min
    },
    setCleanResult(state) {
      state.resultList = []
    },
    changeFilter(state, action: PayloadAction<FilterSeriesState>) {
      state.filter = action.payload
    },
    changeCurrentSearchTab(state, action: PayloadAction<string>) {
      state.currentTab = action.payload
    },
    setCollapsed(state, action: PayloadAction<boolean>) {
      state.colapsado = action.payload
    },
    setCollapsedAvanzed(state, action: PayloadAction<boolean>) {
      state.colapsadoAvanzado = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSeries.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.message = ''
      }
    })
    builder.addCase(fetchSeries.fulfilled, (state, { payload }) => {
      state.resultList = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchSeries.rejected, (state, { error }) => {
      state.resultList = []
      state.status = FETCH_STATUS.FAILED
      state.message = error.message as string
    })
    builder.addCase(fetchSeriesCom.pending, (state) => {
      if (
        state.statusCom === FETCH_STATUS.IDDLE ||
        state.statusCom === FETCH_STATUS.SUCCESS
      ) {
        state.statusCom = FETCH_STATUS.LOADING
        state.message = ''
      }
    })
    builder.addCase(fetchSeriesCom.fulfilled, (state, { payload }) => {
      state.resultListCom = payload
      state.statusCom = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchSeriesCom.rejected, (state, { error }) => {
      state.resultListCom = []
      state.statusCom = FETCH_STATUS.FAILED
      state.message = error.message as string
    })
  },
})

export const {
  setFilter,
  changeFilter,
  setCleanResult,
  changeCurrentSearchTab,
  setCollapsed,
  setCollapsedAvanzed
} = searchSlice.actions

export const searchReducer = searchSlice.reducer
