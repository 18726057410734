/* eslint-disable no-unused-vars */
import { RequestHelper } from "../helpers/requestHelper";
import { utilidades } from "../helpers/utilidades";
import { SesionService } from "./sesion.service";
import { DateUtils, formatoFechasApi } from "../helpers/dateUtils";
import { RequestHelperDotNet } from "../helpers/requestHelperDotNet";

export const ExportarExcelService = {
  exportarComprobantesVentas,
  exportarExcel,
  exportarPdf
}

type ExportarComprobantesVentaVenta = {
  comprobante: number,
  descripcion: string,
  codTipoDoc: string,
  guia: string | null,
  fechaNegociacion: string,
  establecimiento: string,
  servicios: number,
  ptoemision: string,
  numero: string,
  autorizacion: string,
  fecha: string,
  subtotaliva: number,
  subtotal0: number,
  subtotalnoiva: number,
  subtotal: number,
  retTotal: number,
  descuentos: number,
  descuento_items: number,
  descuento_global: number,
  ice: number,
  iva: number,
  total: number,
  identificacion: string,
  nombre: string,
  razonComercial: string,
  asesorComercial: string,
  direccion: string,
  telefono: string,
  email: string | null,
  estado: string,
  clave_acceso: string,
  fecha_aut: string,
  saldo: number,
  asociados: number,
  fpago: string,
  local: string,
  puntoVenta: string,
  observaciones: string,
  pdf: string,
  xml: string,
  url: string,
  estado_electronico: string
}

type ExportarComprobantesVenta = {
  sheetName: string,
  exportedBy: string,
  titulos: Array<string>,
  data: Array<ExportarComprobantesVentaVenta>,
  emptyRowsAfterTitles: number,
  cols: Array<{ fieldName: string, fieldTitle: string, order: number, datatype: string, sumtype?: string }>
}

export enum FielTypes {
  Integer = "Integer",
  String = "String",
  Decimal = "Decimal"
}

export enum SumTypes {
  Sum = "Sum",
}

export type ColInfo = {
  fieldName: string,
  fieldTitle: string,
  order: number,
  datatype: FielTypes,
  sumtype?: SumTypes
}

export type ExportarExcelData = {
  sheetName: string,
  exportedBy: string,
  titulos: Array<string>,
  data: Array<any>,
  emptyRowsAfterTitles: number,
  cols: Array<ColInfo>,
  convertToExcelTable?: boolean,
  resumen?: ResumenDocumento
}


type ResumenDocumento = {
  generalTitle: string,
  colspan: number,
  cols: Array<ColInfo>,
  data: Array<any>
}

type TableStyle = {
  tableStyle: 'dense' | 'collapse',
}

type ExportarPDFData = ExportarExcelData & {
  id: string,
  ruc: string,
  titleDocument: string,
  style?: TableStyle
}

async function exportarExcel(exportarData: ExportarExcelData, corregirFn: undefined | ((colNames: Array<ColInfo>, data: Array<any>) => Array<any>) = undefined) {
  exportarData.data = corregirFn ? coregirColumnasVacias(exportarData.cols, exportarData.data) : coregirColumnasVacias(exportarData.cols, exportarData.data)
  // var response = await RequestHelperDotNet.post("ExcelExport", "ConvertDataToExcel",exportarData );
  // return response;
  const config = await RequestHelperDotNet.getConfigAsync("POST");

  const response = fetch(RequestHelperDotNet.getRequestUrl('ExcelExport', 'ConvertDataToExcel', null, null), {
    method: 'post',
    body: JSON.stringify(exportarData),
    headers: config.headers
  }
  )
  return response;
}

function exportarPdf(exportarData: ExportarPDFData, corregirFn: undefined | ((colNames: Array<ColInfo>, data: Array<any>) => Array<any>) = undefined) {
  const config = RequestHelper.getConfig("POST");
  exportarData.data = corregirFn ? coregirColumnasVacias(exportarData.cols, exportarData.data) : coregirColumnasVacias(exportarData.cols, exportarData.data)
  // const response = fetch(RequestHelper.getRequestUrl('pdf', 'generate', null, null), {
  //   method: 'post',
  //   body: JSON.stringify(exportarData),
  //   headers: config.headers
  // }
  // )
  // return response;
}

async function exportarComprobantesVentas(ventas) {
  const sesion = SesionService.getCurrentSesion();
  const datosExportar: ExportarComprobantesVenta = {
    sheetName: 'Ventas',
    data: [],
    emptyRowsAfterTitles: 0,
    exportedBy: `${sesion.usuario.nombres} ${sesion.usuario.apellidos}`,
    titulos: [sesion.empresa.comercial,
    `FECHA:${DateUtils.getCurrentDateAsString(formatoFechasApi)}`,
      "EXPRESADO EN USD"],
    cols: [
      {
        "fieldName": "comprobante",
        "fieldTitle": "Cod",
        "order": 1,
        "datatype": "Integer"
      },
      {
        "fieldName": "fecha",
        "fieldTitle": "Fecha",
        "order": 2,
        "datatype": "String"
      },
      {
        "fieldName": "descripcion",
        "fieldTitle": "Comprobante",
        "order": 3,
        "datatype": "String"
      },
      {
        "fieldName": "local",
        "fieldTitle": "Local/CC",
        "order": 4,
        "datatype": "String"
      },
      {
        "fieldName": "puntoVenta",
        "fieldTitle": "Caja",
        "order": 5,
        "datatype": "String"
      },
      {
        "fieldName": "establecimiento",
        "fieldTitle": "Establecimiento",
        "order": 6,
        "datatype": "String"
      },
      {
        "fieldName": "ptoemision",
        "fieldTitle": "Punto Emision",
        "order": 7,
        "datatype": "String"
      },
      {
        "fieldName": "numero",
        "fieldTitle": "Número Factura",
        "order": 8,
        "datatype": "String"
      },
      {
        "fieldName": "autorizacion",
        "fieldTitle": "Autorizacion SRI",
        "order": 9,
        "datatype": "String"
      },
      {
        "fieldName": "identificacion",
        "fieldTitle": "Ced/RUC",
        "order": 10,
        "datatype": "String"
      },
      {
        "fieldName": "nombre",
        "fieldTitle": "Cliente",
        "order": 11,
        "datatype": "String"
      },
      {
        "fieldName": "razonComercial",
        "fieldTitle": "Razon Comercial",
        "order": 12,
        "datatype": "String"
      },
      {
        "fieldName": "telefono",
        "fieldTitle": "Telef.",
        "order": 13,
        "datatype": "String"
      },
      {
        "fieldName": "subtotalnoiva",
        "fieldTitle": "Subt. NO I.V.A.",
        "order": 14,
        "datatype": "Decimal"
      },
      {
        "fieldName": "subtotal0",
        "fieldTitle": "Subt. 0",
        "order": 15,
        "datatype": "Decimal",
        "sumtype": "Sum"

      },
      {
        "fieldName": "subtotaliva",
        "fieldTitle": "Subt. I.V.A.",
        "order": 16,
        "datatype": "Decimal",
        "sumtype": "Sum"
      },
      {
        "fieldName": "descuento_items",
        "fieldTitle": "Desc por Item",
        "order": 17,
        "datatype": "Decimal"
      },
      {
        "fieldName": "descuento_global",
        "fieldTitle": "Desc Global",
        "order": 18,
        "datatype": "Decimal"
      },
      {
        "fieldName": "subtotal",
        "fieldTitle": "Subtotal",
        "order": 19,
        "datatype": "Decimal",
        "sumtype": "Sum"
      },
      {
        "fieldName": "ice",
        "fieldTitle": "I.C.E",
        "order": 20,
        "datatype": "Decimal",
        "sumtype": "Sum"
      },
      {
        "fieldName": "iva",
        "fieldTitle": "I.V.A.",
        "order": 21,
        "datatype": "Decimal",
        "sumtype": "Sum"
      },
      {
        "fieldName": "total",
        "fieldTitle": "Total",
        "order": 22,
        "datatype": "Decimal",
        "sumtype": "Sum"
      },
      {
        "fieldName": "retTotal",
        "fieldTitle": "Ret.",
        "order": 23,
        "datatype": "Decimal",
        "sumtype": "Sum"
      },
      {
        "fieldName": "estado",
        "fieldTitle": "Estado",
        "order": 24,
        "datatype": "String"
      },
      {
        "fieldName": "fpago",
        "fieldTitle": "Forma Pago",
        "order": 25,
        "datatype": "String"
      },
      {
        "fieldName": "observaciones",
        "fieldTitle": "Observaciones",
        "order": 26,
        "datatype": "String"
      }
    ],
  }
  datosExportar.data = ventas.map(venta => {
    const ventaExportar: ExportarComprobantesVentaVenta = {
      comprobante: venta.comprobante,
      descripcion: venta.descripcion,
      codTipoDoc: "01",
      guia: venta.guia,
      fechaNegociacion: venta.fechaNegociacion,
      establecimiento: venta.establecimiento,
      servicios: venta.valorServicios,
      ptoemision: venta.puntoEmision,
      numero: venta.numero,
      autorizacion: venta.autorizacion,
      fecha: venta.fecha,
      subtotaliva: venta.subtotaliva,
      subtotal0: venta.subtotal0,
      subtotalnoiva: venta.subtotalnoiva,
      subtotal: venta.subtotal,
      retTotal: venta.valorRetenido,
      descuentos: venta.descuentos,
      descuento_items: venta.descuento_items,
      descuento_global: venta.descuento_global,
      ice: venta.ice,
      iva: venta.iva,
      total: venta.total,
      identificacion: venta.clienteIdentificacion,
      nombre: venta.clienteNombre,
      razonComercial: venta.clienteRazonSocial,
      asesorComercial: venta.asesorComercial,
      direccion: venta.clienteDireccion,
      telefono: venta.clienteTelefono,
      email: venta.clienteEmail,
      estado: venta.estado,
      clave_acceso: venta.claveAcceso,
      fecha_aut: venta.fechaAutorizacion,
      saldo: venta.saldo,
      asociados: venta.asociados,
      fpago: venta.formaPago,
      local: venta.local,
      puntoVenta: venta.puntoVenta,
      observaciones: venta.observaciones,
      pdf: venta.pathPdf,
      xml: venta.pathXml,
      url: venta.pathUrlVisualizacion,
      estado_electronico: venta.estadoElectronico,
    }
    return ventaExportar;
  });
  const config = await RequestHelperDotNet.getConfigAsync("POST");

  // const response = fetch(RequestHelperDotNet.getRequestUrl('ExcelExport', 'ConvertDataToExcel', null, null), {
  //   method: 'post',
  //   body: JSON.stringify(datosExportar),
  //   headers: config.headers
  // }
  // )
  // return response;

  //var response = axios.post(RequestHelper.getRequestUrl('excel', 'generar', null, null), datosExportar, RequestHelper.getConfig("POST"));
  // const config = RequestHelper.getConfig("POST");
  // var response = fetch(RequestHelper.getRequestUrl('excel', 'generar', null, null), {
  //   method: 'post',
  //   body: JSON.stringify(datosExportar),
  //   headers: config.headers
  // }
  // )
  // return response;
  //await RequestHelper.post<any>('excel', 'generar', datosExportar);
}


function coregirColumnasVacias(colNames: Array<ColInfo>, data: Array<any>): Array<any> {
  const result: Array<any> = [];
  for (const dd of data) {
    const _d = utilidades.unfreeze(dd);
    for (let index = 0; index < colNames.length; index++) {
      const colName = colNames[index];
      if (_d[colName.fieldName] == undefined) {
        switch (colName.datatype) {
          case FielTypes.String:
            _d[colName.fieldName] = '';
            break;
          case FielTypes.Decimal:
            _d[colName.fieldName] = 0;
            break;
          case FielTypes.Integer:
            _d[colName.fieldName] = 0;
            break;

          default:
            _d[colName.fieldName] = '';
            break;
        }
      } else {
        switch (colName.datatype) {
          case FielTypes.Decimal:
            _d[colName.fieldName] = parseFloat(_d[colName.fieldName]);
            break;
          case FielTypes.Integer:
            _d[colName.fieldName] = parseInt(_d[colName.fieldName]);
            break;
        }
      }
    }
    result.push(_d)
  }
  return result;
}