import { combineReducers } from '@reduxjs/toolkit'
import { tabsReducer } from './tabsReducer'
import { generalReducer } from './generalReducer'
import { ItemsState } from '../types/types'
import { dataEditReducer } from './editDataReduce'
import { searchReducer } from './searchReducer'

export const ItemsReducer = combineReducers<ItemsState>({
  general: generalReducer,
  tabs: tabsReducer,
  editData: dataEditReducer,
  search: searchReducer,
})
