import React, { useEffect } from 'react'
import { CCardBody, CCardFooter, CCardHeader, CCol, CForm, CRow } from '@coreui/react-pro'
import TextBox from 'devextreme-react/text-box'
import { ValidationGroup } from 'devextreme-react/validation-group'
import { Validator } from 'devextreme-react'
import { RequiredRule, StringLengthRule } from 'devextreme-react/validator'
import ValidationSummary from 'devextreme-react/validation-summary'


import CustomCol from '../../../../../../../views/componentes/colContainer'
import { ToastTypes } from '../../../../../../../store/types'
import validations from '../../../../../../../config/validations'
import SelectBox from '../../../../../../../views/componentes/selectBox/selectBox'
import { InventarioService } from '../../../../../services/inventario.service'
import { isString } from 'react-bootstrap-typeahead/types/utils'

const sms = validations['es']


const estadoLinea = [
  {
    value: 1,
    label: 'Activo',
  },
  {
    value: 2,
    label: 'Inactivo',
  }
]

interface INuevoProps extends React.PropsWithChildren {
  onChanged: (data) => void
  selection?: any
  onCancel?: () => void
  dataMarcaWeb: any
  funcion: string
  currentTab: string
  setCurrenTab: (currentTab: string) => void
  resetFunction: () => void
  resetSelected?: () => void
  setToast: (sms: string, type: ToastTypes) => void
  playLoader?: () => void
  stopLoader?: () => void
}

const Nuevo = (props: INuevoProps) => {
  const { dataMarcaWeb, funcion, setToast, selection, playLoader, stopLoader, currentTab, resetFunction, resetSelected, setCurrenTab } = props
  const formSearchRef = React.useRef<any>()
  const [codigo, setCodigo] = React.useState<number>(0)
  const [nombre, setNombre] = React.useState<string>('')
  const [estado, setEstado] = React.useState<any | null>(estadoLinea[0])
  const [marcaWeb, setMarcaWeb] = React.useState<Array<any> | null>(dataMarcaWeb[0])


  const handleForm = ({ row }: any) => {
    const { codigo, nombre, estadoNombre, marcaWeb } = row
    setCodigo(codigo)
    setNombre(nombre)
    if (estadoNombre === "ACTIVO") {
      setEstado(estadoLinea[0])
    } else {
      setEstado(estadoLinea[1])
    }
    getMarcaWeb(marcaWeb)
  }

  const getMarcaWeb = (value) => {
    let id = 0
    if (typeof value === 'string') {
      id = parseInt(value)
    } else {
      id = value
    }

    if (id === 0) {
      id = -1
    }

    const categoria = dataMarcaWeb.filter(categoria => categoria.id === id)
    setMarcaWeb(categoria[0])
  }

  const handleReset = () => {
    if (codigo === 0) {
      setCodigo(0)
      setNombre('')
      setMarcaWeb(dataMarcaWeb[0])
      setEstado(estadoLinea[0])

    } else {
      handleForm(selection)
    }
  }

  const handleSubmit = React.useCallback(async () => {
    const result = formSearchRef.current.instance.validate()
    if (result.isValid) {
      playLoader()
      const object_request = {
        codigo: codigo,
        nombre: nombre,
        estado: estado.value,
        marcaWeb: marcaWeb['id'] === -1 ? 0 : marcaWeb['id']
      }

      try {
        const data = await InventarioService.createMarca(object_request)
        stopLoader()
        if (data['error'] === true) {
          setToast(data['message'], ToastTypes.Warning)
        } else {
          if (funcion === 'Editar') {
            setToast(data['message'], ToastTypes.Success)
          } else {
            setToast(data['message'], ToastTypes.Success)
          }
          setCurrenTab('Buscar')
          if (selection) {
            resetSelected()
          }
        }
        handleReset()
        return await data
      } catch (error) {
        console.log(error)
        stopLoader()
        setToast(JSON.stringify(error), ToastTypes.Danger)
      }
    }
  }, [nombre, marcaWeb, estado])

  useEffect(() => {
    if (currentTab === 'Nuevo' || currentTab === 'Editar') {
      if (funcion === 'Guardar') {
        handleSubmit()
      } else if (funcion === 'Editar') {
        handleForm(selection)
      } else {
        handleReset()
      }
      resetFunction()
    }
  }, [funcion])



  return (
    <>
      <fieldset>
        <ValidationGroup ref={formSearchRef}>


          <CCardBody>


            <CRow className="row">
              <div className="dx-field-label col-lg-2 col-md-3 col-sm-12">
                {'Nombre:'}
              </div>
              <CustomCol className="dx-field-value" xs="12" md="4">
                <CCol md="12">
                  <TextBox
                    name="descripcion"
                    value={nombre}
                    onValueChanged={({ value }: any) => setNombre(value)}
                    onEnterKey={handleSubmit}
                  >
                    <Validator>
                      <RequiredRule message={'Nombre: ' + sms['required']} />
                      <StringLengthRule message={'Nombre: ' + sms['max'].replace('%num_characters%', '75')} max={75} />
                      <StringLengthRule message={'Nombre: ' + sms['min'].replace('%num_characters%', '3')} min={3} />
                    </Validator>
                  </TextBox>
                </CCol>
              </CustomCol>
            </CRow>



            <CRow className="row mt-1">
              <div className="dx-field-label col-lg-2 col-md-3 col-sm-12">
                {'Marca Web:'}
              </div>
              <CustomCol className="dx-field-value" xs="12" md="4">
                <CCol md="12">
                  <SelectBox
                    id={'selectedMarcaaWeb'}
                    keyExpr={'id'}
                    displayExpr={'name'}
                    onChange={(evt) => {
                      if (evt) {
                        setMarcaWeb(evt);
                      } else {
                        setMarcaWeb(null);
                      }
                    }}
                    options={dataMarcaWeb}
                    placeholder={'Seleccionar'}
                    selected={marcaWeb}
                    elementAttr={{
                      autocomplete: "nope",
                      autoComplete: "nope",
                    }}
                  ></SelectBox>
                </CCol>
              </CustomCol>
            </CRow>


            <CRow className="row mt-1">
              <div className="dx-field-label col-lg-2 col-md-3 col-sm-12">
                {'Estado:'}
              </div>
              <CustomCol className="dx-field-value" xs="12" md="4">
                <CCol md="12">
                  <SelectBox
                    id={'selectedEstadoGrupo'}
                    keyExpr={'value'}
                    displayExpr={'label'}
                    onChange={(evt) => {
                      if (evt) {
                        setEstado(evt);
                      } else {
                        setEstado(null);
                      }
                    }}
                    options={estadoLinea}
                    placeholder={'Elija una opción'}
                    selected={estado}
                    elementAttr={{
                      autocomplete: "nope",
                      autoComplete: "nope",
                    }}
                  ></SelectBox>
                </CCol>
              </CustomCol>
            </CRow>
            <CRow>
              <CCol lg="6" md="8" sm="12">
                <ValidationSummary id="summary"></ValidationSummary>
              </CCol>
            </CRow>
          </CCardBody>
        </ValidationGroup>
      </fieldset>
    </>
  )
}

export default Nuevo