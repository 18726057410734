import CustomStore from 'devextreme/data/custom_store'
import DataSource from 'devextreme/data/data_source'
import { RequestHelper } from '../../../helpers/requestHelper'
import { utilidades } from '../../../helpers/utilidades'
import { PagedResult, PaginationInfo } from '../../../store/types'
import {
  TiposEstados,
  TipoClasificacion,
  TipoIdentificacion,
  TipoProveedor,
  TiposOperadoras,
} from '../../ventas/types/types'
import {
  DetalleProveedor,
  Proveedor,
  ProveedorBusqueda,
  ProveedorSearchGrid,
} from '../store/types'
import {
  FiltrosProveedoresState,
  SuppliersList,
} from '../pages/proveedores/types/types'
import { SesionService } from '../../../services/sesion.service'
export const ProveedoresService = {
  getProveedoresDatasource,
  consultarProveedoresPaged,
  consultarProveedores,
  getProveedor,
  getProveedorByCode,
  getTiposProveedor,
  getClasificaciones,
  getTipoIdentificacion,
  getOperadoras,
  getEstados,
  modificarProveedor,
  getDetalleProveedor,
  getProveedorGridDatasource,
  eliminarProveedor,
  getSuppliersBase,
}

async function eliminarProveedor(codigoProveedor: number): Promise<boolean> {
  try {
    const queryData = {
      codigo: codigoProveedor,
    }
    const deleteResult = await RequestHelper.deleteRequest<boolean>(
      'proveedores',
      'proveedores/inactive',
      '',
      queryData,
    )
    return deleteResult
  } catch (error) {
    return error
  }
}

function getProveedorGridDatasource(dataSearch: ProveedorSearchGrid) {
  const regex = new RegExp('^[0-9]{3,13}$')
  const store = new CustomStore({
    key: 'codigo',
    byKey: async (key) => {
      const item = await getProveedorByCode(key)
      return item
    },
    load: async (loadOptions) => {
      const paginInfo: PaginationInfo = {
        current: (loadOptions.skip ?? 0) / (loadOptions.take ?? 0) + 1,
        size: loadOptions.take ?? 10,
      }
      let pagedResult: PagedResult<ProveedorBusqueda>

      if (regex.test(loadOptions.searchValue)) {
        pagedResult = await consultarProveedoresPaged(
          '',
          loadOptions.searchValue.toString(),
          dataSearch['estado'],
          dataSearch['transportista'],
          paginInfo,
        )
      } else {
        pagedResult = await consultarProveedoresPaged(
          dataSearch['nombreComercial'],
          dataSearch['identificacion'],
          dataSearch['activos'],
          dataSearch['transportista'],
          paginInfo,
        )
      }
      return {
        data: pagedResult.result,
        totalCount: pagedResult.count,
      }
    },
  })
  const source = new DataSource({
    totalCount: store.totalCount,
    paginate: true,
    pageSize: 10,
    store: store,
    key: 'codigo',
  })
  return source
}

async function consultarProveedoresPaged(
  nombre: string,
  identificacion: string,
  estado: boolean,
  esTransportista: boolean,
  paginInfo: PaginationInfo,
): Promise<PagedResult<ProveedorBusqueda>> {
  if (utilidades.canUseNetApi()) {
    return consultarProveedoresPagedApiNet(nombre, identificacion, paginInfo)
  } else {
    return consultarProveedoresPagedApiPhp(
      nombre,
      identificacion,
      estado,
      esTransportista,
      paginInfo,
    )
  }
}

async function consultarProveedoresPagedApiPhp(
  nombre: string,
  identificacion: string,
  estado: boolean,
  esTransportista: boolean,
  pagination: PaginationInfo,
): Promise<PagedResult<ProveedorBusqueda>> {
  const query: any = {
    page: pagination.current,
    limit: pagination.size,
    isPaged: true,
    estado: estado ? 1 : 0,
  }

  if (identificacion && identificacion.length > 0) {
    query.identificacion = identificacion
  }
  if (nombre && nombre.length > 0) {
    query.nombre = nombre
  }
  if (esTransportista) {
    query.transportista = esTransportista
  }
  const pagedApiResult = await RequestHelper.get<any>(
    'proveedores',
    'all',
    '',
    query,
  )
  const proveedoresApi = pagedApiResult.auto as Array<ProveedorBusqueda>

  const pagedResult: PagedResult<ProveedorBusqueda> = {
    count: pagedApiResult.totalRegistros,
    currentPage: pagedApiResult.paginaActual,
    from: pagedApiResult.desde,
    result: proveedoresApi,
    to: pagedApiResult.hasta,
  }
  return pagedResult
}

async function consultarProveedoresPagedApiNet(
  nombre: string,
  identificacion: string,
  paginInfo: PaginationInfo,
): Promise<PagedResult<ProveedorBusqueda>> {
  const pagedResult: PagedResult<ProveedorBusqueda> = {
    count: 0,
    currentPage: 1,
    from: 0,
    result: [],
    to: 0,
  }
  return pagedResult
}

function getProveedoresDatasource(
  estado: boolean = true,
  esTransportista: boolean = false,
) {
  const regex = new RegExp('^[0-9]{3,13}$')
  const store = new CustomStore({
    key: 'codigo',
    byKey: async (key) => {
      const proveedor = await getProveedorByCode(key)
      return proveedor
    },
    load: async (loadOptions) => {
      const paginInfo: PaginationInfo = {
        current: (loadOptions.skip ?? 0) / (loadOptions.take ?? 0) + 1,
        size: loadOptions.take ?? 10,
      }
      let pagedResult: PagedResult<ProveedorBusqueda>
      if (regex.test(loadOptions.searchValue)) {
        pagedResult = await consultarProveedoresPaged(
          '',
          loadOptions.searchValue.toString(),
          estado,
          esTransportista,
          paginInfo,
        )
      } else {
        pagedResult = await consultarProveedoresPaged(
          loadOptions.searchValue,
          '',
          estado,
          esTransportista,
          paginInfo,
        )
      }
      return {
        data: pagedResult.result,
        totalCount: pagedResult.count,
      }
    },
  })
  const source = new DataSource({
    paginate: true,
    pageSize: 10,
    store: store,
    key: 'codigo',
  })
  return source
}

async function consultarProveedores(
  nombre: string,
  identificacion: string,
  esTransportista: boolean | undefined = undefined,
): Promise<Array<ProveedorBusqueda>> {
  const query: any = {
    estado: 1,
  }

  if (identificacion && identificacion.length > 0) {
    query.identificacion = identificacion
  }
  if (nombre && nombre.length > 0) {
    query.nombre = nombre
  }
  if (esTransportista) {
    query.transportista = esTransportista
  }
  const proveedoresApi = await RequestHelper.get<Array<ProveedorBusqueda>>(
    'proveedores',
    'all',
    '',
    query,
  )

  const proveedor = [] as Array<ProveedorBusqueda>
  proveedoresApi.forEach((i: any) => {
    const p: ProveedorBusqueda = {
      codigo: parseInt(i.codigo),
      nombre: i?.nombre ?? '',
      razonComercial: i?.razonComercial ?? '',
      identificacion: i?.identificacion ?? '',
      email: i?.email ?? '',
      direccion: i?.direccion ?? '',
      diaReposicion: i?.diaReposicion ?? '',
      diasCredito: parseInt(i.diasCredito),
      intervalo: parseInt(i.intervalo),
      limiteCredito: parseInt(i.limiteCredito),
      personaAtencionCompras: i?.personaAtencionCompras ?? '',
      personaAtencionPagos: i?.personaAtencionPagos ?? '',
      telefono: i?.telefono ?? null,
      tipoIdDescripcion: i?.tipoIdDescripcion ?? -1,
      tipoIdentificacion: i?.tipoIdentificacion ?? '',
      placas: i?.placas ?? '',
      tipoActividad: i?.tipoActividad ?? '',
      nrc: i?.nrc ?? null,
      ciudad: i.ciudad ?? null,
      ciudadCodigo: parseInt(i.ciudadCodigo) ?? -1,
    }
    proveedor.push(p)
  })

  return proveedor
}

async function getProveedor(
  identificacion: string,
  esTransportista: boolean | undefined = undefined,
): Promise<ProveedorBusqueda | null> {
  const proveedores = await consultarProveedores(
    '',
    identificacion,
    esTransportista,
  )
  const proveedor = proveedores.find((p) => p.identificacion === identificacion)
  return proveedor ?? null
}

async function getProveedorByCode(codigo: number): Promise<Proveedor> {
  const query: any = {
    codigo: codigo,
  }
  const proveedorApi = await RequestHelper.get<Proveedor>(
    'proveedores',
    'proveedores/getByCode',
    '',
    query,
  )
  let proveedor = null

  if (proveedorApi) {
    proveedor = parseProveedor(proveedorApi)
  }
  return proveedor
}

async function getTiposProveedor(): Promise<Array<TipoProveedor>> {
  const query: any = {}
  const cacheKey = 'tiposProveedor' + JSON.stringify(query)
  const cached = sessionStorage.getItem(cacheKey)
  if (cached) {
    return JSON.parse(cached)
  } else {
    const tiposClienteApi = await RequestHelper.get<Array<any>>(
      'proveedores/proveedores',
      'supplier/cboxLoad',
      '',
      query,
    )
    const tipos = [] as Array<TipoProveedor>
    tiposClienteApi
      .filter((x) => x.codigo !== -1)
      .forEach((tipoClienteApi) => {
        if (tipoClienteApi.codigo !== '-1') {
          const tipo: TipoProveedor = {
            codigo: String(tipoClienteApi.codigo),
            descripcion: tipoClienteApi.descripcion,
          }
          tipos.push(tipo)
        }
      })
    sessionStorage.setItem(cacheKey, JSON.stringify(tipos))
    return tipos
  }
}

async function getClasificaciones(
  mensaje: string,
): Promise<Array<TipoClasificacion>> {
  const query: any = {
    tipoCodigo: 90,
    mensaje: mensaje,
  }
  const cacheKey = 'tiposClasificaciones' + JSON.stringify(query)
  const cached = sessionStorage.getItem(cacheKey)
  if (cached) {
    return JSON.parse(cached)
  } else {
    const tiposClasificacionApi = await RequestHelper.get<Array<any>>(
      'subtypes',
      'cboxLoad',
      '',
      query,
    )
    const tipos = [] as Array<TipoClasificacion>
    tiposClasificacionApi
      .filter((x) => x.SUB_CODIGO !== -1)
      .forEach((tipoClienteApi) => {
        if (tipoClienteApi.SUB_CODIGO !== '-1') {
          const tipo: TipoClasificacion = {
            codigo: parseInt(tipoClienteApi.SUB_CODIGO),
            descripcion: tipoClienteApi.SUB_VALOR,
          }
          tipos.push(tipo)
        }
      })
    sessionStorage.setItem(cacheKey, JSON.stringify(tipos))
    return tipos
  }
}

async function getTipoIdentificacion(
  mensaje: string,
): Promise<Array<TipoIdentificacion>> {
  const query: any = {
    tipo: 'COMPRA',
    mensaje: mensaje,
    paisCodigo: 1,
  }
  const sesion = SesionService.getCurrentSesion()
  if (query.paisCodigo) {
    query.paisCodigo = sesion.empresa?.codigoPais ?? 1
  }
  const cacheKey = 'tiposIdentificacionCompra' + JSON.stringify(query)
  const cached = sessionStorage.getItem(cacheKey)
  if (cached) {
    return JSON.parse(cached)
  } else {
    const tiposIdentificacionApi = await RequestHelper.get<Array<any>>(
      'cuenta',
      'tipoId/listar',
      '',
      query,
    )
    const tipos = [] as Array<TipoIdentificacion>
    tiposIdentificacionApi
      .filter((x) => x.codigo !== -1)
      .forEach((tipoClienteApi) => {
        if (tipoClienteApi.codigo !== '-1') {
          const tipo: TipoIdentificacion = {
            codigo: tipoClienteApi.codigo,
            tipo: tipoClienteApi.descripcion,
            codTributario: tipoClienteApi.codTributario ?? '',
          }
          tipos.push(tipo)
        }
      })
    sessionStorage.setItem(cacheKey, JSON.stringify(tipos))
    return tipos
  }
}

async function getOperadoras(mensaje: string): Promise<Array<TiposOperadoras>> {
  const query: any = {
    tipoCodigo: 'COMPRA',
    mensaje: mensaje,
  }
  const cacheKey = 'tiposOperadoras' + JSON.stringify(query)
  const cached = sessionStorage.getItem(cacheKey)
  if (cached) {
    return JSON.parse(cached)
  } else {
    const tiposIdentificacionApi = await RequestHelper.get<Array<any>>(
      'generales',
      'operadoras/listar',
      '',
      query,
    )
    const tipos = [] as Array<TiposOperadoras>
    tiposIdentificacionApi
      .filter((x) => x.OPE_CODIGO !== -1)
      .forEach((tipoClienteApi) => {
        if (tipoClienteApi.OPE_CODIGO !== '-1') {
          const tipo: TiposOperadoras = {
            codigo: tipoClienteApi.OPE_CODIGO,
            tipo: tipoClienteApi.OPE_DESCRIPCION,
          }
          tipos.push(tipo)
        }
      })
    sessionStorage.setItem(cacheKey, JSON.stringify(tipos))
    return tipos
  }
}

async function getEstados(mensaje: string): Promise<Array<TiposEstados>> {
  const query: any = {
    tipoCodigo: '1',
    mensaje: mensaje,
  }
  const cacheKey = 'tiposEstados' + JSON.stringify(query)
  const cached = sessionStorage.getItem(cacheKey)
  if (cached) {
    return JSON.parse(cached)
  } else {
    const tiposIdentificacionApi = await RequestHelper.get<Array<any>>(
      'subtypes',
      'cboxLoad',
      '',
      query,
    )
    const tipos = [] as Array<TiposEstados>
    tiposIdentificacionApi
      .filter((x) => x.SUB_CODIGO !== -1)
      .forEach((tipoClienteApi) => {
        if (tipoClienteApi.SUB_CODIGO !== '-1') {
          const tipo: TiposEstados = {
            codigo: tipoClienteApi.SUB_CODIGO,
            tipo: tipoClienteApi.SUB_VALOR,
          }
          tipos.push(tipo)
        }
      })
    sessionStorage.setItem(cacheKey, JSON.stringify(tipos))
    return tipos
  }
}

async function modificarProveedor(
  proveedor: Proveedor,
  telefonos: any,
): Promise<string> {
  const data_request = JSON.stringify({
    infoRegistro: {
      ...proveedor,
      telefonos: telefonos,
    },
  })

  const apiresult = await RequestHelper.post<any>(
    'proveedores',
    'proveedores/save',
    data_request,
  )
  return apiresult
}

async function getDetalleProveedor(
  proveedorCodigo: number,
  asociadoTipo: string,
): Promise<Array<DetalleProveedor>> {
  const query: any = {
    codigo: proveedorCodigo,
    asociadoTipo: asociadoTipo,
  }

  const tiposClienteApi = await RequestHelper.get<Array<DetalleProveedor>>(
    'proveedores/proveedores',
    'getDetail',
    '',
    query,
  )
  const tipos = [] as Array<DetalleProveedor>
  tiposClienteApi
    .filter((x) => x.codigo !== -1)
    .forEach((itemDetalle) => {
      const tipo: DetalleProveedor = {
        codigo: Number(itemDetalle.codigo),
        descripcion: itemDetalle.descripcion,
        numero: itemDetalle.numero,
        tipo: itemDetalle.tipo,
        tipoDescripcion: itemDetalle.tipoDescripcion,
        estado: itemDetalle.estado,
        asociadoCodigo: Number(itemDetalle.asociadoCodigo),
        asociadoTipo: itemDetalle.asociadoTipo,
        principal: Number(itemDetalle.principal),
      }
      tipos.push(tipo)
    })
  return tipos
}

async function getSuppliersBase(
  filter: FiltrosProveedoresState,
): Promise<Array<SuppliersList>> {
  const data: any = {
    identificacion: filter?.identificacion ?? '',
    nombre: filter?.nombre ?? '',
    razonComercial: filter?.razonComercial ?? '',
    tipoIdentificacion: filter?.tipoIdentificacion?.codigo ?? '',
    estado: filter?.estado === true ? 1 : 0,
    ubicacion: filter?.ciudad?.codigo ?? 0,
    transportista: '',
  }
  const suppliers = await RequestHelper.get<Array<any>>(
    'proveedores',
    'proveedores/getAllBase',
    '',
    data,
  )
  return suppliers
}

function parseProveedor(data): Proveedor {
  const proveedor = {
    codigo: parseInt(data.codigo) ?? 0,
    tipoIdentificacion: data?.tipoIdentificacion ?? '',
    tipoIdentificacionDescripcion: data?.tipoIdentificacionDescripcion ?? '',
    identificacion: data?.identificacion ?? '',
    identificacionString: data?.identificacionString ?? '',
    tributario: data?.tributario ?? '',
    fechaRegitro: data?.fechaRegitro ?? '',
    nombre: data?.nombre ?? '',
    razonComercial: data?.razonComercial ?? '',
    direccion: data?.direccion ?? '',
    telefono: data?.telefono ?? '',
    fax: data?.fax ?? '',
    celular: data?.celular ?? '',
    email: data?.email ?? '',
    ubicacion: parseInt(data.ubicacion) ?? 0,
    ciudadNombre: data?.ciudadNombre ?? '',
    clasificacion: parseInt(data.clasificacion) ?? 0,
    diasCredito: parseInt(data.diasCredito) ?? 0,
    limiteCredito: parseInt(data.limiteCredito) ?? 0,
    descuento: parseInt(data.descuento) ?? 0,
    personaAtencionCompras: data?.personaAtencionCompras ?? '',
    personaAtencionPagos: data?.personaAtencionPagos ?? '',
    saldo: parseInt(data.saldo) ?? 0,
    comprasAnuales: parseInt(data.comprasAnuales) ?? 0,
    fechaUltimoPago: data?.fechaUltimoPago ?? '',
    documentoUltimoPago: data?.documentoUltimoPago ?? '',
    montoPago: parseInt(data.montoPago) ?? 0,
    fechaUltimaCompra: parseInt(data.fechaUltimaCompra) ?? 0,
    documentoUltimaCompra: data?.documentoUltimaCompra ?? '',
    montoCompra: parseInt(data.montoCompra) ?? 0,
    observaciones: data?.observaciones ?? '',
    web: data?.web ?? '',
    tipoEmpresa: parseInt(data.tipoEmpresa) ?? 0,
    especialidad: parseInt(data.especialidad) ?? 0,
    tipoAgenteCodigo: parseInt(data.tipoAgenteCodigo) ?? 0,
    seguros: parseInt(data.seguros) ?? 0,
    artesanos: parseInt(data.artesanos) ?? 0,
    tipoProveedorCodigo: data?.tipoProveedorCodigo ?? null,
    relacionado: data?.relacionado ?? '',
    estado: data?.estado ?? '',
    intervalo: parseInt(data.intervalo) ?? 0,
    diaReposicion: data?.diaReposicion ?? '',
    transportista: data?.transportista ?? '',
    paisCodigo: parseInt(data.paisCodigo) ?? 0,
    paisCescripcion: data?.paisCescripcion ?? '',
    provinciaCodigo: parseInt(data.provinciaCodigo) ?? 0,
    provinciaDescripcion: data?.provinciaDescripcion ?? '',
    ciudadCodigo: parseInt(data.ciudadCodigo) ?? 0,
    ciudadDescripcion: data?.ciudadDescripcion ?? '',
    placas: data?.placas ?? '',
    nit: data?.nit ?? '',
    dui: data?.dui ?? '',
    nrc: data?.nrc ?? '',
    actividadCodigo: parseInt(data.actividadCodigo) ?? 0,
  }
  return proveedor
}
