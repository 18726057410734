import { combineReducers } from '@reduxjs/toolkit'
import { tabsReducer } from './tabsReducer'
import { generalReducer } from './generalReducer'
import { GruposState } from '../types/types'
import { dataEditReducer } from './editDataReducer'
import { searchReducer } from './serachReducer'
import { configuracionesGrupos } from './configReducer'
import { menuReducer } from './menuReducer'

export const GruposReducer = combineReducers<GruposState>({
  general: generalReducer,
  tabs: tabsReducer,
  editData: dataEditReducer,
  search: searchReducer,
  config: configuracionesGrupos,
  menu: menuReducer
})
