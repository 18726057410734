import { RequestHelper } from "../../../../helpers/requestHelper"


export const ArchivosDigitalesSevice = {
  Cargar,
  Eliminar,
  Guardar,
}

async function Cargar(objeto: any): Promise<any> {
  try {

    const obj = {
      modulo: objeto['modulo'],
      asociado: objeto['asociado'],
      tipo: objeto['tipo'],
    }

    const data = await RequestHelper.getAll<any>("compras/compras", "archivos/get", "", obj)
    return data
  } catch (error) {
    return error
  }
}


async function Guardar(objeto: any): Promise<any> {
  try {

    const obj = {
      codigo: 0,
      modulo: objeto['modulo'],
      asociado: objeto['asociado'],
      nombre: objeto['nombre'],
      tipo: objeto['tipo'],
    }

    const data = await RequestHelper.postAll<any>("compras/compras", "archivos/save", obj)
    return data
  } catch (error) {
    return error
  }
}


async function Eliminar(objeto: any): Promise<any> {
  try {

    const obj = {
      codigo: objeto['codigo'],
      nombre: objeto['nombre'],
    }

    const data = await RequestHelper.deleteRequestAll<any>("compras/compras", "archivos/delete", "", obj)
    return data
  } catch (error) {
    return error
  }
}