import React from 'react'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput'
import { NumberBox, RadioGroup, TextBox, ValidationGroup, ValidationSummary, Validator } from 'devextreme-react'
import { ConceptiosMovEdicion, GuardarConceptoMov, MovTypes } from '../../types/types'
import { ButtonTypes } from '../../../../../../../views/componentes/globalMenu/types'
import { clearButtonClick, setCurrentExecutingAction, setNameTabe } from '../../store/tabsReducer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import {
  changeLoaderTab,
  setCurrentFunction,
} from '../../store/generalReducer'
import Dialog from '../../../../../../../views/componentes/librerias/bootstrap-dialog'
import { TabState } from '../../../../../../../store/genericTabTypes'
import TiposAsociadosLookUp from '../../../../../../componentes/tiposAsociadosMovLookUp'
import BlockUi from '../../../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../../../views/componentes/loadingindicator/loadingindicator'
import { setDatosEdicion } from '../../store/editDataReducer'
import { StatesEdition } from '../../../../../../ventas/types/enums'
import { typesAssociatesServices } from '../../../../../../componentes/tiposAsociadosMovLookUp/services/asociados.services'
import { MessagesKeys, lh } from '../../../../../../../helpers/localizationHelper'
import { DocumentInfo, ToastTypes } from '../../../../../../../store/types'
import { addToast } from '../../../../../../../store/toasterReducer'
import VisualizaError from '../../../../../../ventas/pages/shared/visualizaError/visualizaError'
import ListErrosValidationForm from '../../../../../../componentes/listadoErroresValidacionForm'
import { AsyncRule, RequiredRule } from 'devextreme-react/data-grid'
import validations from '../../../../../../../config/validations'
import { consoleService } from '../../../../../../../services/console.service'
import { conceptsServices } from '../../services/conceptos.services'
import { isMobile } from 'react-device-detect'
import { getIndexProvider } from '../../../../../../shared/helpers/funciones'

export const typeMovement = [
  { value: 9, label: 'Ingreso' },
  { value: 10, label: 'Egreso' },
  { value: 11, label: 'Transferencia' },
]

export const generateEntry = [
  { value: 1, label: 'Si' },
  { value: 0, label: 'No' },
]

interface INewProps extends React.PropsWithChildren {
  info: DocumentInfo<ConceptiosMovEdicion>
  tab: TabState<ConceptiosMovEdicion>
  tabId: string
}

const Nuevo: React.FunctionComponent<INewProps> = (props) => {
  const { tab, tabId, info } = props
  const dispatch = useDispatch()
  const validationFormRef = React.useRef<any>()
  const [arrayErrors, setArrayErrors] = React.useState<Array<string>>([])
  const [showErrorPopup, setShowErrorPopup] = React.useState<boolean>(false)

  const conceptsMovState = useSelector((state: RootState) => {
    return state.inventarios.movimientos.conceptos.editData[tabId]
  })
  const loading = useSelector((state: RootState) => {
    return state.inventarios.movimientos.conceptos.editData[tabId].loading
  })
  const tabs = useSelector((state: RootState) => {
    return state.inventarios.movimientos.conceptos.tabs
  })
  const loader = useSelector((state: RootState) => {
    return state.inventarios.movimientos.conceptos.general.loaderTab
  })
  const maximsTipe = useSelector((state: RootState) => {
    return state.inventarios.movimientos.conceptos.search.maxs
  })
  const sms = validations['es']

  const dialogRef = React.useRef<any>(null)

  const playLoader = React.useCallback(
    (message = 'Cargando...') => {
      dispatch(changeLoaderTab({ show: true, mensaje: message }))
    },
    [dispatch],
  )

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoaderTab({ show: false, mensaje: '' }))
  }, [dispatch])

  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(
      addToast({
        title: 'Inventario - Conceptos Movimientos',
        content: mensaje,
        type: tipo,
      }),
    )
  }, [dispatch])

  const setButtonExecuttion = React.useCallback((tipo: ButtonTypes | undefined) => {
    dispatch(
      setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: tipo,
      }),
    )
  }, [dispatch, tabId])

  const modoNuevo = React.useCallback(async (template: number, limpiar: boolean = false) => {
    if (loading === false && !limpiar) {
      return
    }
    setButtonExecuttion(ButtonTypes.new)
    playLoader()
    const aso = await typesAssociatesServices.getTypesAssociates()
    const data = { ...defaultDataConcepts }
    if (aso['auto'] && aso['error'] === false) {
      data.asociado = aso['auto'][0]
    }
    data.codigo = null
    data.descripcion = ''
    data.asiento = generateEntry[1]
    dispatch(setDatosEdicion({
      key: tabId,
      concepto: { ...data }
    }))
    stopLoader()
    setButtonExecuttion(undefined)
  }, [loading, playLoader, stopLoader, setButtonExecuttion, dispatch, tabId])

  const cargarConMov = React.useCallback(async (loder: boolean, conceptoData) => {
    if (loder === false) {
      return
    }
    setButtonExecuttion(ButtonTypes.edit)
    playLoader('Cargando concepto...')
    const aso = await typesAssociatesServices.getTypesAssociates()
    const data = { ...defaultDataConcepts }
    if (aso['auto'] && aso['error'] === false) {
      const provider = aso['auto']?.slice(0)
      const index = await getIndexProvider(provider, 'codigo', conceptoData?.asociado ?? 0)
      if (index > -1) {
        data.asociado = aso['auto'][index]
      } else {
        data.asociado = aso['auto'][0]
      }
    }
    const provider = typeMovement?.slice(0)
    const indexTypeMove = await getIndexProvider(provider, 'value', conceptoData?.tipo ?? 0)
    if (indexTypeMove > -1) {
      data.tipo = typeMovement[indexTypeMove]
    } else {
      data.tipo = null
    }
    data.codigo = conceptoData?.codigo
    data.codigoConcepto = conceptoData?.codigo
    data.descripcion = conceptoData?.descripcion
    data.asiento = conceptoData?.asiento === 0 ? generateEntry[1] : generateEntry[0]
    data.planCuenta = conceptoData?.cuenta
    dispatch(setDatosEdicion({
      key: tabId,
      concepto: { ...data }
    }))
    stopLoader()
    setButtonExecuttion(undefined)
  }, [playLoader, stopLoader, dispatch, setButtonExecuttion, tabId])

  const validateState = React.useCallback((selectedState: any) => {
    if (selectedState.value.codigo > -1) {
      return Promise.resolve()
    } else {
      return Promise.reject('Estado: Debe seleccionar una opción')
    }
  }, [])

  const validateAsyncSeleccionTipoId = React.useCallback((datoSeleccion) => {
    return validateState(datoSeleccion)
  }, [validateState])


  const onChangeTipos = React.useCallback((data: any) => {
    const newData = { ...conceptsMovState }
    newData.tipo = data
    if (data?.value === MovTypes.TipoIngresos) {
      newData.codigo = maximsTipe?.maxIngresos
    }
    if (data?.value === MovTypes.TipoEgresos) {
      newData.codigo = maximsTipe?.maxEgresos
    }
    if (data?.value === MovTypes.TipoTrans) {
      newData.codigo = maximsTipe?.maxTrans
    }

    dispatch(setDatosEdicion({
      key: tabId,
      concepto: {
        ...newData
      }
    }))
  }, [conceptsMovState, tabId, dispatch, maximsTipe])

  const onValidate = React.useCallback(() => {
    const arrayErrors: Array<string> = []
    if (conceptsMovState?.tipo === null || conceptsMovState?.tipo === undefined || conceptsMovState.tipo?.value <= 0) {
      arrayErrors.push('Tipo: Debe seleccionar un tipo.')
    }

    if (conceptsMovState?.codigo === null || conceptsMovState?.codigo === undefined || conceptsMovState.codigo <= 0) {
      arrayErrors.push('Codigo: Ingrese el codigo.')
    }
    if (conceptsMovState?.descripcion === null || conceptsMovState?.descripcion === undefined || conceptsMovState.descripcion === '') {
      arrayErrors.push('Descripción: Ingrese una descripcion.')
    }

    if (conceptsMovState?.asociado === null || conceptsMovState?.asociado === undefined || conceptsMovState.asociado?.codigo <= 0) {
      arrayErrors.push('Asociado: Elija a que debe estar asociado.')
    }

    return arrayErrors
  }, [conceptsMovState])

  const onHandleSave = React.useCallback(async () => {
    let errors: Array<string> = []
    const result = validationFormRef.current.instance.validate()
    errors = onValidate()
    if (result.isValid && errors.length === 0) {
      const data_save: GuardarConceptoMov = {
        infoRegistro: {
          codigo: conceptsMovState?.codigo ?? 0,
          codigoConcepto: conceptsMovState?.codigoConcepto ?? 0,
          tipo: conceptsMovState.tipo?.value ?? 0,
          descripcion: conceptsMovState?.descripcion ?? '',
          cuenta: conceptsMovState?.planCuenta ?? 0,
          asociado: conceptsMovState.asociado?.codigo ?? 0,
          asiento: conceptsMovState.asiento?.value ?? 0,
        }
      }

      consoleService.log(data_save)

      setButtonExecuttion(ButtonTypes.save)
      playLoader('Guardando...')

      try {
        const data = await conceptsServices.setConcept(data_save)
        if (data !== null && data !== undefined && data['error'] === false) {
          setToast(data['message'], ToastTypes.Success)
          dispatch(setNameTabe({
            key: tabId,
            codigo: conceptsMovState?.codigo,
            nombre: conceptsMovState?.descripcion
          }))
          dispatch(setDatosEdicion({
            key: tabId,
            concepto: {
              ...conceptsMovState,
              codigoConcepto: conceptsMovState?.codigo
            }
          }))
        }
      } catch (error) {
        setToast(error.message, ToastTypes.Danger)
      }

      stopLoader()
      setButtonExecuttion(undefined)
    } else {
      setToast(lh.getMessage(MessagesKeys.GlobalErrorSave), ToastTypes.Warning)
      setArrayErrors(errors)
      setShowErrorPopup(true)
    }
  }, [onValidate, setToast, conceptsMovState, setButtonExecuttion, playLoader, stopLoader, dispatch, tabId])

  const handleButtonClick = React.useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.save:
          if (tabs.current === tabId) onHandleSave()
          break
        case ButtonTypes.broom:
          if (tabs.current === tabId) modoNuevo(0, true)
          break
        default:
          break
      }
      dispatch(setCurrentFunction(''))
      dispatch(clearButtonClick(tabId))
    },
    [dispatch, onHandleSave, tabId, tabs, modoNuevo],
  )

  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab.globalButtonClick]);

  React.useEffect(() => {
    if (tab.editStatus === StatesEdition.new) {
      modoNuevo(tab?.info?.codigo)
    } else {
      cargarConMov(loading, info.info)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {showErrorPopup && (
        <VisualizaError
          titulo="Error en Conceptos Movimientos"
          mensaje={'Error al guardar'}
          content={
            <ListErrosValidationForm
              mainMessage={'Revise los siguientes errores:'}
              errorsList={arrayErrors}
              color={'danger'}
            />
          }
          onOk={() => setShowErrorPopup(false)}
        />
      )}
      <div style={{ overflowX: 'hidden' }}>
        <BlockUi
          tag="div"
          loader={LoadingIndicator}
          blocking={loader.show}
          message={loader.mensaje}
        >
          <RowContainer className='mb-5'>
            <Dialog ref={dialogRef} />
            <ValidationGroup id={'formIngEg'} ref={validationFormRef}>
              <RowContainer>
                {!isMobile && (
                  <CustomCol lg="6" md="8" sm="12">
                    <ValidationSummary id="summary"></ValidationSummary>
                  </CustomCol>
                )}
              </RowContainer>
              <RowContainer>
                <CustomCol xs="12" md="2">
                  <Labeled label="Código del Concepto:">
                    <NumberBox
                      value={conceptsMovState?.codigo ?? null}
                      onValueChanged={(data) => {
                        if (data.event !== null && data.event !== undefined) {
                          dispatch(setDatosEdicion({
                            key: tabId,
                            concepto: {
                              ...conceptsMovState,
                              codigo: data?.value
                            }
                          }))
                        }
                      }}
                    >
                      <Validator>
                        <RequiredRule
                          message={'Codido del Concepto: ' + sms['required']}
                        />

                      </Validator>
                    </NumberBox>
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="4" style={{ marginTop: 'auto' }}>
                  <Labeled label=''>

                    <RadioGroup
                      name="tipo-movimiento"
                      layout="horizontal"
                      displayExpr="label"
                      items={typeMovement}
                      value={conceptsMovState?.tipo ?? null}
                      onValueChanged={(data) => {
                        if (data.event !== null && data.event !== undefined) {
                          onChangeTipos(data?.value)
                        }
                      }}
                    />
                  </Labeled>
                </CustomCol>
              </RowContainer>
              <RowContainer>
                <CustomCol xs="12" md="3">
                  <Labeled label="Descripción:">
                    <TextBox
                      value={conceptsMovState?.descripcion ?? ''}
                      showClearButton
                      onValueChanged={(data) => {
                        if (data.event !== null && data.event !== undefined) {
                          dispatch(setDatosEdicion({
                            key: tabId,
                            concepto: {
                              ...conceptsMovState,
                              descripcion: data?.value
                            }
                          }))
                        }
                      }}
                    >
                      <Validator>
                        <RequiredRule
                          message={'Descripcion: ' + sms['required']}
                        />

                      </Validator>
                    </TextBox>
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="2">
                  <Labeled label="Asociado a:">
                    <TiposAsociadosLookUp
                      selected={conceptsMovState?.asociado ?? null}
                      onChanged={(data) => {
                        dispatch(setDatosEdicion({
                          key: tabId,
                          concepto: {
                            ...conceptsMovState,
                            asociado: data
                          }
                        }))
                      }}
                      onChangedOptions={() => { }}
                    >
                      <Validator>
                        <RequiredRule
                          message={'Asociado: Debe seleccionar un asociado'}
                        />
                        <AsyncRule
                          message={'Asociado: Debe seleccionar un asociado'}
                          validationCallback={validateAsyncSeleccionTipoId}
                        />
                      </Validator>
                    </TiposAsociadosLookUp>
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="2">
                  <Labeled label="Genera Asiento:">
                    <RadioGroup
                      name="tipo-movimiento"
                      layout="horizontal"
                      displayExpr="label"
                      items={generateEntry}
                      value={conceptsMovState?.asiento ?? null}
                      onValueChanged={(data) => {
                        if (data.event !== null && data.event !== undefined) {
                          dispatch(setDatosEdicion({
                            key: tabId,
                            concepto: {
                              ...conceptsMovState,
                              asiento: data?.value
                            }
                          }))
                        }
                      }}
                    />
                  </Labeled>
                </CustomCol>
              </RowContainer>
              <RowContainer>
                {isMobile && (
                  <CustomCol lg="6" md="8" sm="12">
                    <ValidationSummary id="summary"></ValidationSummary>
                  </CustomCol>
                )}
              </RowContainer>
            </ValidationGroup>
          </RowContainer>
        </BlockUi>
      </div>
    </>
  )
}

export default React.memo(Nuevo)


export const defaultDataConcepts: ConceptiosMovEdicion = {
  codigo: 0,
  codigoConcepto: 0,
  planCuenta: 0,
  tipo: null,
  descripcion: '',
  asociado: null,
  asiento: null,
  guardado: false,
  loading: false
}