import { RequestHelper } from "../../../../../helpers/requestHelper";
import { CompraFullInfo } from "../../../../compras/types/types";
import {
  TLiquidacionCompraIngresar,
} from "../types/types";

export const LiquidacionesComprasService = {
  guardarLiquidacion,
};

async function guardarLiquidacion(
  liquidacion: TLiquidacionCompraIngresar
): Promise<CompraFullInfo> {
  const result = await RequestHelper.post<CompraFullInfo>(
    "compras",
    "liquidaciones/ingresar",
    liquidacion
  );
  return result;
}
