import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../../store/store'
import { FETCH_STATUS } from '../../../../../store/types'
import {
  ProveedoresListado,
  FiltrosProveedoresState,
  SearchState,
} from '../types/types'
import { ProveedoresService } from '../../../services/proveedores.service'
import { Seleccionado } from '../../../../bancos/store/types'

const initialState: SearchState<ProveedoresListado> = {
  filter: {
    identificacion: '',
    nombre: '',
    razonComercial: '',
    tipoIdentificacion: null,
    ciudad: null,
    estado: true,
    importar: false,
  },
  seleccionado: {
    index: null,
    row: null,
    selectedRow: null,
  },
  eliminarItem: false,
  editarRegistro: false,
  verRegistro: false,
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  resultados: [],
}

export const fetchSuppliers = createAsyncThunk<
  Array<ProveedoresListado>,
  FiltrosProveedoresState
>('proveedores/proveedores/listar', async (searchFilter) => {
  try {
    const suppliers = await ProveedoresService.getSuppliersBase(searchFilter)
    return suppliers
  } catch (error) {
    return Promise.reject(error)
  }
})

const searchProveedoresSlice = createSlice({
  name: 'searchSuppliers',
  initialState: initialState,
  reducers: {
    setIdentificacion(state, action: PayloadAction<string>) {
      state.filter.identificacion = action.payload
    },
    setResultados(state, action: PayloadAction<Array<ProveedoresListado>>) {
      state.resultados = action.payload
    },
    setElimnarItem(state, action: PayloadAction<boolean>) {
      state.eliminarItem = action.payload
    },
    setElditarRegistro(state, action: PayloadAction<boolean>) {
      state.editarRegistro = action.payload
    },
    setVerRegistro(state, action: PayloadAction<boolean>) {
      state.verRegistro = action.payload
    },
    setCleanResult(state) {
      state.resultados = []
    },
    changeFilter(state, action: PayloadAction<FiltrosProveedoresState>) {
      state.filter = action.payload
    },
    setResetSeleccion(state) {
      const toAdd = {
        index: null,
        row: null,
        selectedRow: null,
      }
      state.seleccionado = toAdd
    },
    setSeleccionarDato(state, action: PayloadAction<Seleccionado>) {
      const toAdd = {
        index: action.payload.index,
        row: action.payload.row,
        selectedRow: action.payload.selectedRow,
      }
      state.seleccionado = toAdd
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSuppliers.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchSuppliers.fulfilled, (state, { payload }) => {
      state.resultados = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchSuppliers.rejected, (state, { payload }) => {
      state.resultados = []
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
  },
})

export const searchFilterSupplier = (state: RootState) => {
  return state.proveedores.proveedores.search.filter
}

export const searchResultsSuppliers = (state: RootState) => {
  return state.proveedores.proveedores.search.resultados
}

export const { setIdentificacion, changeFilter, setCleanResult, setSeleccionarDato, setResetSeleccion, setResultados, setElimnarItem, setElditarRegistro, setVerRegistro } =
  searchProveedoresSlice.actions

export const searchProveedoresReducer = searchProveedoresSlice.reducer
