/* eslint-disable no-unused-vars */
import { PropsWithChildren } from 'react'
import { ModalUsuarioState } from '../componentes/modalUsuarios/store/types'
import { CandidatesState } from '../pages/candidatos/types/types'
import { PositionsState } from '../pages/cargos/types/types'
import { PersonalState } from '../pages/personal/types/types'
import { TabState, TabsState } from '../../../store/genericTabTypes'
import { AccionMenu } from '../../../store/types'
import { ButtonTypes } from '../../../views/componentes/globalMenu/types'
import { ContratosState } from '../pages/contratos/types/types'
import { ModuloRegistrosState } from '../pages/registro/types/types'
import { OrganigramaState } from '../pages/organigrama/types/types'
import { MarcationState } from '../pages/registro/marcacion/types/types'
import { ModuloRolesState } from '../pages/roles/types/types'

export type ModuloNominaState = {
  modalUsuarios: ModalUsuarioState
  candidatos: CandidatesState
  personal: PersonalState
  cargos: PositionsState
  contratos: ContratosState
  registros: ModuloRegistrosState
  organico: OrganigramaState
  rol: ModuloRolesState
}

export enum Aplicacion {
  personal = 'Personal',
  candidatos = 'Candidatos',
  contratos = 'Contratos',
  cargos = 'Cargos',
  registro = 'Registro',
  organigrama = 'Organigrama',
  roles = 'Roles',
  parametros = 'Parámetros',
  horarios = 'RegistroHorarios',
  calendarios = 'RegistroCalendario',
  marcacion = 'RegistroMarcacion',
  rolesPago = 'RolRolesPagp',
  decimos = 'Decimos',
}

export type EdicionPayload<T> = {
  key: number | string
  data: T
}

export interface ITabControlProps<T> extends PropsWithChildren {
  tabsState: TabsState<T>
  // eslint-disable-next-line no-unused-vars
  onTabChanged: (tabKey: string) => void
  aplicationType: Aplicacion
  // eslint-disable-next-line no-unused-vars
  ontabClosing: (tabKey: string) => void
}

export interface IGenericTabProps<T> extends PropsWithChildren {
  tab: TabState<T>
  tabId: string
}

export interface IGnericMainTabProps<T> extends PropsWithChildren {
  actionsMenu: Array<AccionMenu>
  // eslint-disable-next-line no-unused-vars
  onMenuButtonClick: (action: AccionMenu) => void
  // eslint-disable-next-line no-unused-vars
  getButtonDisabled: (action: AccionMenu) => boolean
  currentWorking: ButtonTypes
  tabs: TabsState<T>
  // eslint-disable-next-line no-unused-vars
  onTabChanged: (tabKey: string) => void
  // eslint-disable-next-line no-unused-vars
  onTabClosing: (tabKey: string) => void
}

export const defaultPopupMsgConfig = {
  show: false,
  title: 'Ácatha',
  message: '',
  type: null,
  currentAction: null,
}
