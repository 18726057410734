import { CCard, CCardBody, CLink } from '@coreui/react-pro'
import React, { useEffect } from 'react'
import {
  AsyncRule,
  RequiredRule,
  StringLengthRule,
} from 'devextreme-react/data-grid'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../views/componentes/colContainer'
import InputTextDE, {
  InputDateDE,
} from '../../../../../../views/componentes/inputText-DE/inputText-DE'
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput'
import { Button, Validator } from 'devextreme-react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/store'
import { TLoginData } from '../../store/types'
import TiposPersonasLookUp from '../../../../../componentes/tiposPersonasLookUp'
import LocalesLookUp from '../../../../../componentes/localesLookUp'
import Barcode from '../../../../../../views/componentes/barcode/barcode'
import validations from '../../../../../../config/validations'
import {
  setChangeLoaderEdit,
  setDatosEdicionLogin,
} from '../../store/editDataReducer'
import { addToast } from '../../../../../../store/toasterReducer'
import { ToastTypes } from '../../../../../../store/types'
import { Button as TextBoxButton } from 'devextreme-react/text-box'
import { SelectBoxDE } from '../../../../../../views/componentes/selectBox-DE/selectBox-DE'
import { utilidades } from '../../../../../../helpers/utilidades'
import { PatternRule } from 'devextreme-react/validator'
import { emailRegex } from '../personales/personales'
import './login-section.scss'
import { LocalesService } from '../../../local/services/locales.services'
import { FiltroBusquedaLocalsState } from '../../../local/store/types'
interface ILoginProps extends React.PropsWithChildren {
  tabId: string
}
const politicaContrasena =
  'La contraseña no se ajusta a la política: la contraseña debe tener al menos un número, una letra minúscula, letra mayúscula, al menos un símbolo y de al menos 8 caracteres.'
const passwordStrong = /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W]).{8,64})/g

export default function TLogin(props: ILoginProps) {
  const { tabId } = props
  const dispatch = useDispatch()
  const lugarMessage: string = 'Login - Local/CC: debe eligir una opción'
  const messageConfirm =
    'Login - Clave confirmación: No es igual a la clave ingresada'
  const sms = validations['es']
  const passwordRequired = 'Login - Password: ' + sms['required']
  const userRequired = 'Login - Usuario: ' + sms['required']
  const userPattern =
    'Login - Usuario: Debe ingresar un correo electrónico válido.'
  const passwordLength =
    'Password: Este campo no puede tener mas de 15 caracteres'
  const passwordConfRequired = 'Login - Clave confirmación: ' + sms['required']
  const codPaseRequired = 'Login - Codigo de Pase: ' + sms['required']

  const loginState = useSelector(
    (state: RootState) =>
      state.administracion.configuraciones.usuarios.editData[tabId].login,
  )
  const usuarioState = useSelector(
    (state: RootState) =>
      state.administracion.configuraciones.usuarios.editData[tabId],
  )
  const empresaState = useSelector(
    (state: RootState) =>
      state.global.session.empresa
  )
  const [passwordMode, setPasswordMode] = React.useState<'text' | 'password'>(
    'password',
  )
  const [passwordModeCon, setPasswordModeCon] = React.useState<
    'text' | 'password'
  >('password')
  const [passwordModeOld, setPasswordModeOld] = React.useState<
    'text' | 'password'
  >('password')
  const [options, setOptions] = React.useState<any[]>([])
  const setToast = React.useCallback(
    (mensaje: string, tipo: ToastTypes) => {
      dispatch(
        addToast({
          title: 'Usuarios - Login',
          content: mensaje,
          type: tipo,
        }),
      )
    },
    [dispatch],
  )

  const onLoader = React.useCallback(
    (showLoader: boolean, text: string) => {
      dispatch(
        setChangeLoaderEdit({
          key: tabId,
          data: {
            show: showLoader,
            mensaje: text,
          },
        }),
      )
    },
    [dispatch, tabId],
  )

  const onChangeValue = React.useCallback(
    (data, key: string) => {
      dispatch(
        setDatosEdicionLogin({
          key: tabId,
          data: {
            ...loginState,
            [key]: data,
          },
        }),
      )
    },
    [dispatch, loginState, tabId],
  )

  const generarNewPassword = React.useCallback(async () => {
    onLoader(true, 'Generando Password...')
    try {
      const dataGenerated = await utilidades.generarClave()
      if (dataGenerated) {
        dispatch(
          setDatosEdicionLogin({
            key: tabId,
            data: {
              ...loginState,
              password: dataGenerated,
              confirmarPassword: dataGenerated,
            },
          }),
        )
      }
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
    onLoader(false, '')
  }, [setToast, onLoader, dispatch, loginState, tabId])

  const validateAsyncSelect = React.useCallback(
    (datoSeleccion: any, message: string) => {
      if (datoSeleccion.value.codigo > -1) {
        return Promise.resolve()
      } else {
        return Promise.reject(message)
      }
    },
    [],
  )

  const validateAsyncSeleccionLugar = React.useCallback(
    (datoSeleccion) => {
      return validateAsyncSelect(
        datoSeleccion,
        'Local/CC: debe eligir una opción',
      )
    },
    [validateAsyncSelect],
  )

  const validateAsyncSamePassword = React.useCallback(
    (value: any, message: string) => {
      if (value === loginState?.password) {
        return Promise.resolve()
      } else {
        return Promise.reject(message)
      }
    },
    [loginState],
  )

  const validateSamePassword = React.useCallback(
    (value) => {
      return validateAsyncSamePassword(value?.value, messageConfirm)
    },
    [validateAsyncSamePassword, messageConfirm],
  )

  const validarClave = async (value) => {
    const regex = new RegExp(passwordStrong)
    if (regex.test(value)) {
      return await true
    } else {
      return await false
    }
  }

  const asyncValidationPasswordStrong = (params) => {
    return validarClave(params.value)
  }


  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { 
    const fetchData = async () => {
      const filtro: FiltroBusquedaLocalsState = { nombre: '', codigo: 0, estado: true }
      const data = await LocalesService.getAllLocales(filtro)
      setOptions(data)
    }

    fetchData()
  }, [])
  return (
    <CCard style={{ border: 1 }} className="m-0">
      <CCardBody>
        <div style={{ padding: '10px', overflowX: 'hidden' }} className="mb-4">
          <RowContainer>
            <CustomCol xs="12" md="4">
              <Labeled label="Creado:">
                <InputDateDE
                  value={loginState?.creado ?? ''}
                  onValueChange={(data) => onChangeValue(data, 'creado')}
                />
              </Labeled>
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol xs="12" md="4">
              <Labeled label="Usuario:">
                <InputTextDE
                  value={loginState?.usuario ?? ''}
                  onValueChange={(data) => onChangeValue(data, 'usuario')}
                  disabled={!loginState?.maintenanceCredentials}
                >
                  <Validator>
                    <RequiredRule message={userRequired} />
                    <PatternRule message={userPattern} pattern={emailRegex} />
                  </Validator>
                </InputTextDE>
              </Labeled>
            </CustomCol>
          </RowContainer>
          {/* {usuarioState?.codigo > 0 && (
            <RowContainer>
              <CustomCol xs="12" md="10" className="mt-2 mb-0">
                <CLink
                  onClick={() => {
                    onChangeValue(
                      !loginState?.maintenanceCredentials,
                      'maintenanceCredentials',
                    )
                  }}
                >
                  {' '}
                  {'Actualizar credenciales'}{' '}
                </CLink>
              </CustomCol>
            </RowContainer>
          )} */}
          <RowContainer>
            <CustomCol xs="12" md="10">
              <fieldset
                className={
                  'fieldset-anular mt-2 mb-2' +
                  (!loginState?.maintenanceCredentials ? ' fieldset-block' : '')
                }
                disabled={!loginState?.maintenanceCredentials}
              >
                {usuarioState?.codigo > 0 && (
                  <RowContainer>
                    <CustomCol xs="12" md="6">
                      <Labeled label="Password Actual:">
                        <InputTextDE
                          value={loginState?.password_old ?? ''}
                          showClearButton={true}
                          mode={passwordModeOld}
                          width="50%"
                          inputAttr={{ autocomplete: 'nope' }}
                          onValueChange={(data) =>
                            onChangeValue(data, 'password_old')
                          }
                          disabled={!loginState?.maintenanceCredentials}
                        >
                          <TextBoxButton
                            name="btnEye"
                            location="after"
                            options={{
                              type: 'normal',
                              stylingMode: 'contained',
                              icon:
                                passwordModeOld === 'text'
                                  ? 'images/eye.png'
                                  : 'images/eye-slash.png',
                              onClick: () => {
                                setPasswordModeOld(
                                  passwordModeOld === 'text'
                                    ? 'password'
                                    : 'text',
                                )
                              },
                            }}
                          />
                          <Validator>
                            <RequiredRule message={passwordConfRequired} />
                          </Validator>
                        </InputTextDE>
                      </Labeled>
                    </CustomCol>
                  </RowContainer>
                )}
                <RowContainer>
                  <CustomCol xs="12" md="6">
                    <Labeled
                      label={
                        usuarioState?.codigo > 0
                          ? 'Nueva Password'
                          : 'Password:'
                      }
                    >
                      <div
                        style={{
                          display: 'flex',
                          gap: '2px',
                          alignItems: 'center',
                          width: '100%',
                        }}
                      >
                        <InputTextDE
                          value={loginState?.password ?? ''}
                          showClearButton={true}
                          mode={passwordMode}
                          width="50%"
                          inputAttr={{ autocomplete: 'nope' }}
                          onValueChange={(data) =>
                            onChangeValue(data, 'password')
                          }
                          disabled={!loginState?.maintenanceCredentials}
                        >
                          <TextBoxButton
                            name="btnEye"
                            location="after"
                            options={{
                              type: 'normal',
                              stylingMode: 'contained',
                              icon:
                                passwordMode === 'text'
                                  ? 'images/eye.png'
                                  : 'images/eye-slash.png',
                              onClick: () => {
                                setPasswordMode(
                                  passwordMode === 'text' ? 'password' : 'text',
                                )
                              },
                            }}
                          />
                          <Validator>
                            <RequiredRule message={passwordRequired} />
                            <AsyncRule
                              message={politicaContrasena}
                              validationCallback={asyncValidationPasswordStrong}
                            />
                          </Validator>
                        </InputTextDE>
                        <Button
                          id="btnGenrarPassword"
                          className="me-1"
                          stylingMode="contained"
                          type="default"
                          onClick={() => generarNewPassword()}
                        >
                          {'Generar Password'}
                        </Button>
                      </div>
                    </Labeled>
                  </CustomCol>
                </RowContainer>
                <RowContainer>
                  <CustomCol xs="12" md="6">
                    <Labeled label="Confirmar Password:">
                      <InputTextDE
                        value={loginState?.confirmarPassword ?? ''}
                        mode={passwordModeCon}
                        inputAttr={{ autocomplete: 'nope' }}
                        width="50%"
                        onValueChange={(data) => {
                          onChangeValue(data, 'confirmarPassword')
                        }}
                        disabled={!loginState?.maintenanceCredentials}
                      >
                        <TextBoxButton
                          name="btnEye"
                          location="after"
                          options={{
                            type: 'normal',
                            stylingMode: 'contained',
                            icon:
                              passwordModeCon === 'text'
                                ? 'images/eye.png'
                                : 'images/eye-slash.png',
                            onClick: () => {
                              setPasswordModeCon(
                                passwordModeCon === 'text'
                                  ? 'password'
                                  : 'text',
                              )
                            },
                          }}
                        />
                        <Validator>
                          <RequiredRule message={passwordConfRequired} />
                          <AsyncRule
                            message={messageConfirm}
                            validationCallback={validateSamePassword}
                          />
                        </Validator>
                      </InputTextDE>
                    </Labeled>
                  </CustomCol>
                </RowContainer>
              </fieldset>
            </CustomCol>
          </RowContainer>
          <RowContainer>
          <CustomCol xs="12" md="4">
  <Labeled label="Lugar o CC:">
    {/* Renderiza el componente LocalesLookUp solo si provider (options) tiene datos */}
    {options.length > 0 ? (
      <LocalesLookUp
        onChanged={(data) => onChangeValue(data, 'lugarCC')}
        onChangedOptions={() => {}}
        selected={loginState?.lugarCC ?? null}
        provider={options}
      >
        <Validator>
          <RequiredRule message={lugarMessage} />
          <AsyncRule
            message={lugarMessage}
            validationCallback={validateAsyncSeleccionLugar}
          />
        </Validator>
      </LocalesLookUp>
    ) : (
      <div>Loading...</div>  
    )}
  </Labeled>
</CustomCol>

            <CustomCol xs="12" md="4">
              <Labeled label="Tipo Usuario:">
                <SelectBoxDE
                  items={loginState?.tiposUsuario ?? []}
                  onValueChange={(data) => {
                    onChangeValue(data, 'tipoUsuario')
                  }}
                  value={loginState?.tipoUsuario ?? null}
                  searchExpr={['descripcion']}
                  displayExpr={'descripcion'}
                />
              </Labeled>
            </CustomCol>
          </RowContainer>
        </div>
      </CCardBody>
    </CCard>
  )
}

export const defaulDatosEdicionTabDatosLogin: TLoginData = {
  creado: '',
  usuario: '',
  password: '',
  password_old: '',
  confirmarPassword: '',
  codigoPase: '',
  codigoPaseImagen: '',
  lugarCC: null,
  tipoUsuario: null,
  tiposUsuario: null,
  maintenanceCredentials: true,
}
