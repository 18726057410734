import {
  CAlert,
  CCard,
  CCardBody,
  CCol,
  CRow,
} from '@coreui/react-pro'
import { DataGrid, DateBox, LoadPanel, TextArea, TextBox } from 'devextreme-react'
import { Column, FilterRow, HeaderFilter, Pager, Paging, Selection } from 'devextreme-react/data-grid'
import React from 'react'
import CustomCol from '../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import CabeceraFiltroBusqueda from '../../shared/cabeceraFiltroBusqueda'
import { TabState } from '../../../../../../store/genericTabTypes'
import { useDispatch, useSelector } from 'react-redux'
import {
  changeFilter as changeSaldos,
  deleteSaldos,
  fetchCuotasVentas,
  fetchSaldoSinRetencion,
  fetchSaldos,
  fetchSaldosNum,
  getResultadosSaldos,
  initData,
  saveCuotasVentas,
  saveSaldosNew,
  setCleanFields,
  setCleanResult,
  setCurrentAction,
  setSeleccionarDato,
} from '../../store/saldosReducer'
import { DateUtils } from '../../../../../../helpers/dateUtils'
import {
  CustomButtons,
  FETCH_STATUS,
  ToastTypes,
} from '../../../../../../store/types'
import { addToast } from '../../../../../../store/toasterReducer'
import { getAllowedPageSizes } from '../../../../../../helpers/Helper'
import { RootState } from '../../../../../../store/store'
import {
  FiltrosDocumentosState,
  FiltrosSaldosState,
  SaldosIniciales,
} from '../../types/types'
import { CuotaCreditoVenta } from '../../../../../ventas/types/types'
import { ModalCreaditosVenta } from '../../../../../componentes/modalCreditos/creditosVentas/creditosVentaModal'
import Importar from '../impportarExcel/importar'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import { clearButtonClick } from '../../store/tabsReducer'
import VisualizaError from '../../../../../ventas/pages/shared/visualizaError/visualizaError'
import { DocCuentasCobrar } from '../../services/docCuentasCobrar.service'
import { ReportWithLocalDataModal } from '../../../../../../views/componentes/xtrareports/ReportWithLocalDataModal'
import { getIndexProvider } from '../../../../../shared/helpers/funciones'

interface ISaldosProps {
  tabId: string
  tab: TabState<any>
}

const Saldos: React.FunctionComponent<ISaldosProps> = (props) => {
  const { tab, tabId } = props

  const dispatch = useDispatch()
  const filterSaldos = useSelector(
    (state: RootState) => state.clientes.docCuentasXCobrar.saldos.filter,
  )
  const resultadosSaldos = useSelector(getResultadosSaldos)
  const estadoBusqueda = useSelector(
    (state: RootState) => state.clientes.docCuentasXCobrar.saldos.status,
  )
  const seleccionado = useSelector(
    (state: RootState) => state.clientes.docCuentasXCobrar.saldos.seleccionado,
  )

  const position = { of: '#saldosContent' }

  const [displayMode] = React.useState<string | 'full' | 'compact'>('full')
  const [showPageSizeSelector] = React.useState<boolean>(true)
  const [showInfo] = React.useState<boolean>(true)
  const [showNavButtons] = React.useState<boolean>(true)
  const [showModal, setShowModal] = React.useState(false)
  const [showImportButton, setShowImportButton] = React.useState<boolean>(false)

  const [showErrorPopup, setShowErrorPopup] = React.useState<boolean>(false)
  const [arrayErrors, setArrayErrors] = React.useState<Array<string>>([])

  const [fechaSaldo, setFechaSaldo] = React.useState(
    new Date(new Date().getFullYear(), 0, 1),
  )
  const filtroGlobales = useSelector(
    (state: RootState) => state.clientes.docCuentasXCobrar.search.filter,
  )
  const [fecha, setFecha] = React.useState<string>(
    DateUtils.dateToString(
      new Date(new Date().getFullYear(), 0, 1),
      'dd/MM/yyyy',
    ),
  )
  const [establecimiento, setEstablecimiento] = React.useState<string>('001')
  const [ptoEmision, setPtoEmision] = React.useState<string>('001')
  const [numero, setNumero] = React.useState<string | null>(null)
  const [saldoAnterior, setSaldoAnterior] = React.useState<string | null>(null)
  const [observaciones, setObservaciones] = React.useState<string | null>(null)
  const [ventaCodigo, setVentaCodigo] = React.useState<number>(0)
  const [total, setTotal] = React.useState<number>(0)
  const [cuotasIngresadas, setCuotas] = React.useState<
    Array<CuotaCreditoVenta>
  >([])
  const [resultadoImprimir, setResultadoImprimir] = React.useState<
    Array<SaldosIniciales>
  >([])
  const [filtroGlobal, setfiltroGlobal] =
    React.useState<FiltrosDocumentosState>(filtroGlobales)
  const [reporte, setReporte] = React.useState<null | 'Viewer' | 'Designer'>(
    null,
  )
  const [showLoader, setShowLoader] = React.useState(false)
  const [resultados, setResultados] = React.useState<Array<any>>([])
  const [clean, setClean] = React.useState<boolean>(false)

  const getFilterString = React.useCallback((filter: FiltrosSaldosState) => {
    const filterText = ''

    return filterText
  }, [])

  const onChangeNumero = React.useCallback(
    (data) => {
      if (data.value !== null && data.value !== undefined) {
        console.log(data.value)
        setNumero(data.value)
        dispatch(
          changeSaldos({
            ...filterSaldos,
            numero: data.value,
          }),
        )
      }
    },
    [dispatch, filterSaldos],
  )

  const onChangeEstablecimiento = React.useCallback(
    (data) => {
      if (data.value !== null && data.value !== undefined) {
        console.log(data.value)
        setEstablecimiento(data.value)
        dispatch(
          changeSaldos({
            ...filterSaldos,
            esteblecimientoNum: data.value,
          }),
        )
      }
    },
    [dispatch, filterSaldos],
  )

  const onChangePtoEmison = React.useCallback(
    (data) => {
      if (data.value !== null && data.value !== undefined) {
        console.log(data.value)
        setPtoEmision(data.value)
        dispatch(
          changeSaldos({
            ...filterSaldos,
            ptoEmision: data.value,
          }),
        )
      }
    },
    [dispatch, filterSaldos],
  )

  const onChangeSaldoAnt = React.useCallback(
    (data) => {
      if (data.value !== null && data.value !== undefined) {
        console.log(data.value)
        setSaldoAnterior(data.value)
        dispatch(
          changeSaldos({
            ...filterSaldos,
            saldoAnterior: data.value,
          }),
        )
      }
    },
    [dispatch, filterSaldos],
  )

  const onChangeObservaciones = React.useCallback(
    (data) => {
      if (data.value !== null && data.value !== undefined) {
        console.log(data.value)
        setObservaciones(data.value)
        dispatch(
          changeSaldos({
            ...filterSaldos,
            observaciones: data.value,
          }),
        )
      }
    },
    [dispatch, filterSaldos],
  )

  const deleteItemTabla = React.useCallback(async () => {
    if (seleccionado.row !== null && seleccionado.row !== undefined) {
      if (
        resultados !== null &&
        resultados !== undefined &&
        resultados.length > 0
      ) {
        const provider = resultados.slice(0)
        const index = await getIndexProvider(
          provider,
          'ventaCodigo',
          seleccionado.row.ventaCodigo,
        )
        if (index > -1) {
          provider.splice(index, 1)
          setResultados(provider)
        }
      }
    }
  }, [resultados, seleccionado.row])

  const onOpenModalCuotas = React.useCallback(async () => {
    console.log(ventaCodigo)
    if (
      ventaCodigo !== 0 &&
      ventaCodigo !== undefined &&
      ventaCodigo !== null
    ) {
      const toAnyRetencion: any = fetchSaldoSinRetencion(ventaCodigo)
      const resRetencion = await dispatch(toAnyRetencion)
      const toAnyCuotas: any = fetchCuotasVentas(ventaCodigo)
      const resCuotas = await dispatch(toAnyCuotas)

      if (resRetencion !== null && resRetencion['error'] !== true) {
        setTotal(resRetencion.payload['auto'])
      }
      if (resCuotas !== null && resCuotas['error'] !== true) {
        const changeCuotas: Array<CuotaCreditoVenta> = resCuotas.payload[
          'auto'
        ].map((cuota) => {
          console.log(cuota)
          const newCuota: CuotaCreditoVenta = {
            codigo: cuota.FPV_CODIGO,
            codigoPVE: cuota.PVE_CODIGO,
            numero: 0,
            letra: cuota.PVE_LETRA,
            cuota: cuota.PVE_CUOTA,
            vencimiento: cuota.PVE_VENCIMIENTO,
            saldo: cuota.PVE_SALDO,
            saldoCap: cuota.PVE_SALDOCAP,
            interes: cuota.PVE_INTERES,
            estado: cuota.PVE_ESTADO,
          }

          return newCuota
        })

        setCuotas(changeCuotas)
      }
      setShowModal(true)
    } else {
      dispatch(
        addToast({
          title: 'Saldos',
          content: 'Seleccione una cuota para ver el credito',
          type: ToastTypes.Warning,
        }),
      )
    }
  }, [ventaCodigo, dispatch])

  const resultMessage = React.useCallback(
    (respuesta) => {
      let mensaje = ''
      if (respuesta === 'PERIODO') {
        mensaje +=
          'La fecha del comprobante esta fuera del periodo contable activo'
      } else if (respuesta === 'EXISTE') {
        mensaje += 'Existe un comprobante con estas caracteristicas, revíselo'
      } else if (respuesta === 'CHEQUES') {
        mensaje +=
          'Existen Cheques asociados al comprobante, para poder actualizar la transacción deberá eliminarlos.'
      } else if (respuesta === 'TARJETAS') {
        mensaje +=
          'Existen Vouchers asociados al comprobante, para poder actualizar la transacción deberá eliminarlos.'
      } else if (respuesta === 'ABONOS') {
        mensaje +=
          'Existen Abonos asociados al comprobante, para poder actualizar la transacción deberá eliminarlos.'
      } else if (respuesta === 'SALDO') {
        mensaje +=
          'El saldo del comprobante al que afecta es menor al total en crédito de la Nota de Crédito'
      } else if (String(respuesta).substring(0, 10) === 'EXISTENCIA') {
        mensaje += `No existe stock de ${String(respuesta).substring(
          0,
          10,
        )} en bodega.`
      } else if (String(respuesta).substring(0, 16) === 'VERIFICAR STOCK:') {
        mensaje += respuesta.toString()
      } else {
        mensaje += 'Registro existoso'
        if (!isNaN(parseInt(respuesta))) {
          console.log(respuesta)
          const ventaCodigoNumber = parseInt(respuesta)
          setVentaCodigo(ventaCodigoNumber)
          setTimeout(() => {
            onOpenModalCuotas()
          }, 1000)
        } else {
          console.log(respuesta, 'error save')
        }
      }
      dispatch(
        addToast({
          title: 'Saldos',
          content: mensaje,
          type: ToastTypes.Success,
        }),
      )
    },
    [dispatch, onOpenModalCuotas],
  )

  const resultToast = React.useCallback(
    (res, accion: string = '') => {
      if (res.payload['error'] !== true) {
        if (accion === 'Guardar') {
          console.log(res.payload['auto'])
          resultMessage(res.payload['auto'])
        } else {
          dispatch(
            addToast({
              title: 'Saldos',
              content: res.payload['message'],
              type: ToastTypes.Success,
            }),
          )
        }
      } else {
        console.log(res)
        dispatch(
          addToast({
            title: 'Saldos',
            content: res.payload['message'],
            type: ToastTypes.Danger,
          }),
        )
      }
    },
    [dispatch, resultMessage],
  )

  const onDelete = React.useCallback(async () => {
    const toAny: any = deleteSaldos(seleccionado)
    const res = await dispatch(toAny)
    if (res !== null && res.payload['error'] === false) {
      if (res.payload['auto'] === 'ABONOS') {
        dispatch(
          addToast({
            title: 'Saldos',
            content:
              'Existen Abonos asociados al comprobante, para poder actualizar la transacción deberá eliminarlos',
            type: ToastTypes.Warning,
          }),
        )
      } else {
        deleteItemTabla()
        dispatch(
          addToast({
            title: 'Saldos',
            content: 'El registro fue eliminado con éxito',
            type: ToastTypes.Success,
          }),
        )
      }
    } else {
      console.log(res)
      dispatch(
        addToast({
          title: 'Saldos',
          content: res.payload['message'] || res['message'],
          type: ToastTypes.Danger,
        }),
      )
    }
  }, [dispatch, seleccionado])

  const onChangeFecha = React.useCallback(
    (e) => {
      console.log(e)
      if (e !== null && e !== undefined) {
        setFechaSaldo(e)
        const fechaNueva = DateUtils.dateToString(e, 'dd/MM/yyyy')
        setFecha(fechaNueva)
        dispatch(
          changeSaldos({
            ...filterSaldos,
            fecha: fechaNueva,
          }),
        )
      } else {
        setFechaSaldo(new Date())
        const fechaTemp = DateUtils.dateToString(fechaSaldo, 'dd/MM/yyyy')
        setFecha(fechaTemp)
        dispatch(
          changeSaldos({
            ...filterSaldos,
            fecha: fechaTemp,
          }),
        )
        dispatch(
          addToast({
            content: 'Borro fecha seleccionada',
            title: 'Saldos',
            type: ToastTypes.Danger,
          }),
        )
      }
    },
    [dispatch, fechaSaldo, filterSaldos],
  )

  const isValidateForm = React.useCallback(() => {
    const errors: Array<string> = [
      'Antes de guardar la información revise lo siguiente:',
    ]
    if (
      establecimiento === null ||
      establecimiento === undefined ||
      establecimiento === '' ||
      isNaN(parseInt(establecimiento))
    ) {
      errors.push(
        'Establecimiento: revise el campo sea un numero o que no este vacio',
      )
    }
    if (
      ptoEmision === null ||
      ptoEmision === undefined ||
      ptoEmision === '' ||
      isNaN(parseInt(ptoEmision))
    ) {
      errors.push(
        'Punto de emisión: revise el campo sea un numero o que no este vacio',
      )
    }

    if (
      numero === null ||
      numero === undefined ||
      numero === '' ||
      isNaN(parseInt(numero))
    ) {
      errors.push('Numero: revise el campo sea un numero o que no este vacio')
    }

    if (
      saldoAnterior === null ||
      saldoAnterior === undefined ||
      saldoAnterior === '' ||
      isNaN(parseInt(saldoAnterior))
    ) {
      errors.push(
        'Saldo anterior: revise el campo sea un numero o que no este vacio',
      )
    }

    if (ventaCodigo === null || ventaCodigo === undefined) {
      errors.push('Venta codigo: codigo de la venta no existe')
    }

    return errors
  }, [establecimiento, ptoEmision, numero, saldoAnterior, ventaCodigo])

  const onFindSaldos = React.useCallback(async () => {
    const cliente = filterSaldos.cliente
    const local = filterSaldos.local
    const empresa = filterSaldos.empresa
    const data: FiltrosSaldosState = {
      cliente,
      local,
      empresa,
      fecha,
      esteblecimientoNum: establecimiento,
      ptoEmision,
      numero,
      observaciones,
      saldoAnterior,
      ventacodigo: ventaCodigo,
    }
    console.log(data, 'onFindSaldos')
    const toAny: any = fetchSaldos(data)
    const res = await dispatch(toAny)
    const toAnyNum: any = fetchSaldosNum(filterSaldos)
    dispatch(toAnyNum)
    resultToast(res)
  }, [
    dispatch,
    filterSaldos,
    resultToast,
    fecha,
    establecimiento,
    ptoEmision,
    numero,
    observaciones,
    saldoAnterior,
    ventaCodigo,
  ])

  const onSaveSaldos = React.useCallback(async () => {
    if (filterSaldos.cliente !== null && filterSaldos.cliente !== undefined) {
      const errors = isValidateForm()
      if (errors.length === 1) {
        // dispatch(setCleanResultSaldos())
        const cliente = filterSaldos.cliente
        const local = filterSaldos.local
        const empresa = filterSaldos.empresa

        const data: FiltrosSaldosState = {
          cliente,
          local,
          empresa,
          fecha,
          esteblecimientoNum: establecimiento,
          ptoEmision,
          numero,
          observaciones,
          saldoAnterior,
          ventacodigo: ventaCodigo,
        }

        console.log(data)
        const toAny: any = saveSaldosNew(data)
        const res = await dispatch(toAny)
        if (res !== null && res !== undefined) {
          resultToast(res, 'Guardar')
        } else {
          console.log(res)
        }
      } else {
        setArrayErrors(errors)
        setShowErrorPopup(true)
      }
    } else {
      dispatch(
        addToast({
          title: 'Saldos',
          content: 'Seleccione un cliente antes de guardar.',
          type: ToastTypes.Warning,
        }),
      )
    }
  }, [
    dispatch,
    filterSaldos,
    resultToast,
    isValidateForm,
    fecha,
    establecimiento,
    ptoEmision,
    numero,
    observaciones,
    saldoAnterior,
    ventaCodigo,
  ])

  const onBroomSaldos = React.useCallback(() => {
    setClean(true)
    setFecha(
      DateUtils.dateToString(
        new Date(new Date().getFullYear(), 0, 1),
        'dd/MM/yyyy',
      ),
    )
    setFechaSaldo(new Date())
    setNumero(null)
    setEstablecimiento('001')
    setPtoEmision('001')
    setObservaciones(null)
    setSaldoAnterior(null)
    setVentaCodigo(0)
    dispatch(setCleanFields())
    dispatch(setCleanResult())
  }, [dispatch])

  const onChangeFilter = React.useCallback(
    (accion: string) => {
      if (accion === 'Buscar') {
        onFindSaldos()
      } else if (accion === 'Guardar') {
        onSaveSaldos()
      }
    },
    [onFindSaldos, onSaveSaldos],
  )

  const onDBSelectedRow = React.useCallback(
    ({ data }) => {
      console.log(data)
      dispatch(setCurrentAction('Editar'))
    },
    [dispatch],
  )

  const onGetFields = React.useCallback(() => {
    if (seleccionado.row !== null && seleccionado.row !== undefined) {
      const fecha = DateUtils.strDateToDate(
        seleccionado.row.ventaFecha,
        'dd/MM/yyyy',
      )
      setFechaSaldo(fecha)
      setFecha(seleccionado.row.ventaFecha)
      setObservaciones(seleccionado.row.ventaObservaciones)
      setEstablecimiento(seleccionado.row.ventaEstablecimiento)
      setPtoEmision(seleccionado.row.ventaPuntoEmision)
      setNumero(seleccionado.row.ventaNumero)
      setSaldoAnterior(seleccionado.row.ventaTotal.toString())
      setVentaCodigo(seleccionado.row.ventaCodigo)
    }
  }, [seleccionado.row])

  const getDataResult = React.useCallback(async (accion: string) => {
    try {
      setShowLoader(true)
      const data = await DocCuentasCobrar.getDataPrint('SALDOS')
      setShowLoader(false)
      if (data !== null && data['auto'] && data['auto'].length > 0) {
        setResultadoImprimir(data['auto'])
        if (accion === 'Imprimir') {
          setReporte('Viewer')
        } else {
          setReporte('Designer')
        }
      } else {
        setResultadoImprimir([])
      }
    } catch (error) {
      console.log(error)
      setShowLoader(false)
      setResultadoImprimir([])
    }
  }, [])

  const handleButtonClick = React.useCallback(
    (button: ButtonTypes) => {
      switch (button) {
        case ButtonTypes.credit:
          onOpenModalCuotas()
          break
        case ButtonTypes.find:
          onChangeFilter(ButtonTypes.find)
          break
        case ButtonTypes.save:
          onChangeFilter(ButtonTypes.save)
          break
        case ButtonTypes.edit:
          onGetFields()
          break
        case ButtonTypes.undo:
          onGetFields()
          break
        case ButtonTypes.delete:
          onDelete()
          break
        case ButtonTypes.broom:
          onBroomSaldos()
          break
        case ButtonTypes.import:
          setShowImportButton(true)
          break
        case ButtonTypes.print:
          if (resultadoImprimir.length === 0) {
            getDataResult('Imprimir')
          } else {
            setReporte('Viewer')
          }
          break
        case ButtonTypes.print_design:
          if (resultadoImprimir.length === 0) {
            getDataResult('Diseñar')
          } else {
            setReporte('Designer')
          }
          break
        default:
          console.log(button)
          break
      }
      dispatch(clearButtonClick(tabId))
    },
    [
      dispatch,
      tabId,
      onBroomSaldos,
      onGetFields,
      onChangeFilter,
      onDelete,
      onOpenModalCuotas,
      getDataResult,
      resultadoImprimir.length,
    ],
  )

  const onSelectionChanged = React.useCallback(
    (selectionChanged) => {
      console.log('onSelectionChanged', selectionChanged['selectedRowsData'][0])
      dispatch(
        setSeleccionarDato({
          index: [selectionChanged['currentSelectedRowKeys'][0]],
          row: selectionChanged['selectedRowsData'][0],
          selectedRow: [selectionChanged['currentSelectedRowKeys'][0]],
        }),
      )
    },
    [dispatch],
  )

  React.useEffect(() => {
    dispatch(
      changeSaldos({
        ...filterSaldos,
        cliente: filtroGlobal.cliente,
        local: filtroGlobal.lugar,
        empresa: filtroGlobal.empresa,
      }),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtroGlobal])

  React.useEffect(() => {
    dispatch(setCleanResult())
    dispatch(initData())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    } else {
      console.log(tab.globalButtonClick)
    }
  }, [handleButtonClick, tab.globalButtonClick])

  // const resultados = resultadosSaldos['auto'] || []

  const getResultados = React.useCallback(() => {
    let newArray = []
    if (
      resultadosSaldos !== null &&
      resultadosSaldos !== undefined &&
      resultadosSaldos['auto'] &&
      resultadosSaldos['auto'].length > 0
    ) {
      newArray = resultadosSaldos['auto']
    }
    setResultados(newArray)
  }, [resultadosSaldos])

  const onCuotasCreditoChanged = React.useCallback(
    async (nuevasCuotas) => {
      const toAny: any = saveCuotasVentas({
        codigo: ventaCodigo,
        cuotas: nuevasCuotas,
      })
      const res = await dispatch(toAny)
      if (res !== null && res['error'] !== true) {
        setShowModal(false)
        dispatch(
          addToast({
            title: 'Saldos',
            content: res.payload['message'],
            type: ToastTypes.Success,
          }),
        )
      } else {
        console.log(res)
      }
    },
    [ventaCodigo, dispatch],
  )

  const generaDetalleErrores = React.useCallback((item: any) => {
    if (item.length > 0) {
      return (
        <CAlert color="danger">
          <p>{item[0]}</p>
          <ul className="errorList">
            {item.map(function (item, id) {
              if (id === 0) return null
              return <li key={id}>{item}</li>
            })}
          </ul>
        </CAlert>
      )
    } else {
      return <></>
    }
  }, [])

  const getParseData = React.useCallback((saldo: Array<any> = []) => {
    let registros: Array<SaldosIniciales> = []
    registros = saldo.map((x) => {
      return {
        fecha:
          DateUtils.strDateToDate(x?.ventaFecha, 'dd/MM/yyyy') ?? new Date(),
        numero: x?.ventaNumero ?? '',
        identificaion: x?.clienteIdentificacion ?? '',
        nombre: x?.clienteNombre ?? '',
        total: x?.ventaTotal ?? '',
      }
    })
    console.log(registros)
    return registros
  }, [])

  const renderModalCuotas = React.useCallback(() => {
    return (
      <ModalCreaditosVenta
        cuotas={cuotasIngresadas} // lista de cuotas
        fechaFactura={DateUtils.dateToString(fechaSaldo)}
        totalCobrar={total}
        onCancel={() => setShowModal(false)}
        onOk={onCuotasCreditoChanged}
      />
    )
  }, [fechaSaldo, onCuotasCreditoChanged, total, cuotasIngresadas])

  React.useEffect(() => {
    getResultados()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultadosSaldos])

  return (
    <>
      {showModal && renderModalCuotas()}
      <ReportWithLocalDataModal
        show={reporte !== null}
        onClose={() => setReporte(null)}
        data={getParseData(resultadoImprimir)}
        fileName="StaticClientesSaldosIniciales"
        mode={reporte ?? 'Viewer'}
        parameters={{ Reporte_Filtro: getFilterString(filterSaldos), Modulo: "Clientes" }}
        template="StaticClientesSaldosIniciales"
        key="reportDesigner"
      />
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={position}
        visible={estadoBusqueda === FETCH_STATUS.LOADING || showLoader}
        showIndicator={true}
        shading={true}
        showPane={true}
        closeOnOutsideClick={false}
      />
      {showErrorPopup && (
        <VisualizaError
          titulo="Error en Saldos"
          mensaje={'Error al guardar'}
          content={generaDetalleErrores(arrayErrors)}
          onOk={() => {
            setShowErrorPopup(false)
            // dispatch(setMuestraError({ key: props.tabId, tieneError: false, mensajeError: '' }))
          }}
        />
      )}
      <CCard id="saldosContent">
        <CCardBody>
          <CabeceraFiltroBusqueda
            filtroBusqueda={{
              empresa: null,
              cliente: null,
              puntoVenta: null,
              proyecto: null,
              lugar: null,
              nombre: ''
            }}
            clean={clean}
            setClean={setClean}
            getCabeceraFiltro={(data) => setfiltroGlobal(data)}
          />
          <RowContainer className="mt-1 mb-1">
            <CustomCol xs="12" md={showImportButton ? '7' : '12'}>
              <RowContainer>
                <CustomCol xs="12" md="3">
                  <Labeled label="Fecha:">
                    <DateBox value={fechaSaldo} onValueChange={onChangeFecha} />
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="5">
                  <Labeled label="Número:">
                    <CRow>
                      <CCol xs="12" md="3">
                        <TextBox
                          value={establecimiento}
                          onValueChanged={(data) =>
                            onChangeEstablecimiento(data)
                          }
                        />
                      </CCol>
                      <CCol xs="12" md="3">
                        <TextBox
                          value={ptoEmision}
                          onValueChanged={(data) => onChangePtoEmison(data)}
                        />
                      </CCol>
                      <CCol xs="12" md="6">
                        <TextBox
                          value={numero}
                          onValueChanged={(data) => onChangeNumero(data)}
                        />
                      </CCol>
                    </CRow>
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="4">
                  <Labeled label="Saldo Anterior:">
                    <TextBox
                      value={saldoAnterior}
                      onValueChanged={(data) => onChangeSaldoAnt(data)}
                    />
                  </Labeled>
                </CustomCol>
              </RowContainer>
              <RowContainer>
                <CustomCol xs="12" md="5">
                  <Labeled label="Observaciones:">
                    <TextArea
                      value={observaciones}
                      onValueChanged={(data) => onChangeObservaciones(data)}
                    />
                  </Labeled>
                </CustomCol>
              </RowContainer>
            </CustomCol>
            {showImportButton && (
              <CustomCol xs="12" md="5">
                <Importar
                  tab={tab}
                  tabId={tabId}
                  openImport={(open) => setShowImportButton(open)}
                />
              </CustomCol>
            )}
          </RowContainer>
          <RowContainer>
            <p style={{ marginTop: '4px' }}>
              Si desea obtener una lista de todos los saldos inicales
              registrados, haga click en el botón imprimir.
            </p>
          </RowContainer>
          <CustomCol xs="12" md="7">
            <DataGrid
              style={{ marginTop: '5px' }}
              selection={{ mode: 'single' }}
              hoverStateEnabled={true}
              dataSource={resultados}
              showBorders={true}
              showRowLines={true}
              onRowDblClick={onDBSelectedRow}
              keyExpr="ventaCodigo"
              onSelectionChanged={onSelectionChanged}
            >
              <FilterRow visible={true} />
              <HeaderFilter visible={true} />
              <Selection
                mode="single"
                selectAllMode={'onClick'}
                showCheckBoxesMode={[]}
              />

              <Paging defaultPageSize={10} />
              <Pager
                visible={resultados.length > 0 ? true : false}
                allowedPageSizes={getAllowedPageSizes(resultados)}
                displayMode={displayMode}
                showPageSizeSelector={showPageSizeSelector}
                showInfo={showInfo}
                showNavigationButtons={showNavButtons}
              />
              <Column
                dataField="ventaEstablecimiento"
                caption="Establecimiento"
                width="20%"
              />
              <Column
                dataField="ventaPuntoEmision"
                caption="Pto Emisión"
                width="20%"
              />
              <Column dataField="ventaNumero" caption="Número" width="20%" />
              <Column dataField="ventaFecha" caption="Fecha" width="20%" />
              <Column
                dataField="ventaObservaciones"
                caption="Observaciones"
                width="20%"
              />
              <Column dataField="ventaTotal" caption="Valor" width="20%" />
            </DataGrid>
          </CustomCol>
        </CCardBody>
      </CCard>
    </>
  )
}

export default React.memo(Saldos)

export const ModuleButtonsSaldos: CustomButtons = {
  Nuevo: true,
  Guardar: true,
  Buscar: true,
  Deshacer: true,
  Eliminar: true,
  Editar: true,
  Imprimir: true,
  Importar: true,
  Limpiar: true,
  Credito: true,
}
