export const licenceHelper = {
  isLicence,
}
// funcion que debe estar en false cuando se compla para servidores de empresas
// cuando esta en false, la app ya no controla contratos
function isLicence() {
  // eslint-disable-next-line no-undef
  return (
    (process.env['REACT_APP_ACATHA_LICENCE'] === 'true' ? true : false) ?? false
  )
}
