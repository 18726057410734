import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FETCH_STATUS } from '../../../../../store/types'
import {
  NotificationListResult,
  FilterNotificationState,
  SearchState,
  optionsSearch,
} from '../types/types'
import { notificationsServices } from '../services/notifications.services'

const initialState: SearchState<NotificationListResult> = {
  filter: {
    descripcion: optionsSearch[1],
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  resultados: [],
}

export const fetchNotifications = createAsyncThunk<
  Array<NotificationListResult>,
  FilterNotificationState
>('backOffice/notificacioneses/listar', async (filtro) => {
  try {
    const notificationsList = notificationsServices.getNotifications(
      filtro.descripcion.value,
    )
    return notificationsList
  } catch (error) {
    return Promise.reject(error)
  }
})

const searchSlice = createSlice({
  name: 'searchNotifications',
  initialState: initialState,
  reducers: {
    setFilter(state, action: PayloadAction<FilterNotificationState>) {
      state.filter.descripcion = action.payload.descripcion
    },
    setCleanResult(state) {
      state.resultados = []
    },
    changeFilter(state, action: PayloadAction<FilterNotificationState>) {
      state.filter = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNotifications.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchNotifications.fulfilled, (state, { payload }) => {
      state.resultados = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchNotifications.rejected, (state, { payload }) => {
      state.resultados = []
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
  },
})

export const { setFilter, changeFilter, setCleanResult } = searchSlice.actions

export const searchReducer = searchSlice.reducer
