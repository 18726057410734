import React from 'react'
import { CCol, CRow } from '@coreui/react-pro'
import { v4 as uuidv4 } from 'uuid'
import { RequestHelperDotNet } from '../../../../helpers/requestHelperDotNet'
import { consoleService } from '../../../../services/console.service'

type ImageEmpresaProps = {
  imagenBlob: string
}

const ImageEmpresa = (props: ImageEmpresaProps) => {
  consoleService.log("ImageEmpresa")
  const { imagenBlob } = props

  const [image, setImage] = React.useState<any>('')

  const aplicarImagen = React.useCallback(async (path) => {
    if (path.includes('data:image/')) {
      // verifica si es contenido blob
      setImage(path)
    } else {
      try {
        const res = await RequestHelperDotNet.GetFileWithCors(path)
        const b = await res.blob()
        const fileURL = URL.createObjectURL(b)
        setImage(fileURL)
      } catch (error) {
        consoleService.error(error)
        setImage('data:image/jpg;base64,')
      }
    }
  }, [])

  React.useEffect(() => {
    aplicarImagen(imagenBlob)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imagenBlob])

  return (
    <React.Fragment>
      <div
        className="selected-item d-flex align-items-center justify-content-center"
        key={uuidv4()}
        id={uuidv4()}
      >
        <CRow className="mt-2 mb-2">
          <CCol className=" d-flex justify-content-center">
            <img
              key={uuidv4()}
              id={uuidv4()}
              src={image}
              className="avathar"
              style={{ maxWidth: '150px' }}
              alt="LOGO"
              onError={(err) => {
                consoleService.error(err)
                // setImage('/images/notFoundImage.png')
              }}
            ></img>
          </CCol>
        </CRow>
      </div>
    </React.Fragment>
  )
}

export default React.memo(ImageEmpresa)
