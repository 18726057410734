import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DateUtils } from "../../../../../helpers/dateUtils";
import { RootState } from "../../../../../store/store";
import { DateRange, FETCH_STATUS, TiposComprobantesSri } from "../../../../../store/types";
import { CompraService } from "../../../services/compra.service";
import { FiltroBusquedaState, CompraInfo, SearchState } from "../../../types/types";


const initialState: SearchState<CompraInfo> = {
  filter: {
    fechaInicio: DateUtils.getCurrentDateAsString(),
    fechaFin: DateUtils.getCurrentDateAsString(),
    puntoVenta: '',
    activas: false,
    documento: '',
    pendientesAbutorizar: false,
    proveedor: null,

  },
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  resultados: []
}

export const fetchCompras = createAsyncThunk<Array<CompraInfo>, FiltroBusquedaState>(
  'compras/compras/getAll',
  async (filtro) => {
    try {
      const range: DateRange = {
        inicio: DateUtils.pickersDateToApiDate(filtro.fechaInicio),
        fin: DateUtils.pickersDateToApiDate(filtro.fechaFin),
      };
      const compras = CompraService.buscarCompras(
        range,
        filtro.documento ?? "",
        filtro.proveedor?.identificacion ?? "",
        TiposComprobantesSri.Factura,
        filtro.formaPago ? filtro.formaPago.codigo.toString() : "", "");
      return compras;
    } catch (error) {
      return Promise.reject(error);
    }
  }
)



const searchComprasSlice = createSlice({
  name: 'searchCompras',
  initialState: initialState,
  reducers: {
    resetState(state) {
      state = {
        ...initialState,
        filter: {
          ...initialState.filter,
          fechaInicio: DateUtils.getCurrentDateAsString(),
          fechaFin: DateUtils.getCurrentDateAsString(),
        },
      };
    },
    changeFilter(state, action: PayloadAction<FiltroBusquedaState>) {
      state.filter = action.payload;
    },
    setCleanResult(state) {
      state.resultados = [];
    },
    setResultados(state, action: PayloadAction<any>) {
      state.resultados = action.payload.resultados;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCompras.pending, (state) => {
      if (state.status === FETCH_STATUS.IDDLE || state.status === FETCH_STATUS.SUCCESS) {
        state.status = FETCH_STATUS.LOADING;
        state.mensaje = '';
      }
    })
    builder.addCase(fetchCompras.fulfilled, (state, { payload }) => {
      state.resultados = payload;
      state.status = FETCH_STATUS.SUCCESS;
    })
    builder.addCase(fetchCompras.rejected, (state, { payload }) => {
      state.resultados = [];
      state.status = FETCH_STATUS.FAILED;
      state.mensaje = payload as string;
    })
  }
})





export const selectFiltroBusquedaCompras = (state: RootState) => { return state.compras.compras.search.filter };
export const selectResultadosBusquedaCompras = (state: RootState) => { return state.compras.compras.search.resultados };
export const selectBusquedaComprasEstado = (state: RootState) => { return state.compras.compras.search.status };
export const { setResultados, changeFilter, resetState, setCleanResult } = searchComprasSlice.actions;
export const searchComprasReducer = searchComprasSlice.reducer;