import { RequestHelper } from '../../../../../../../helpers/requestHelper'

export const ClientesxCobrar = {
  getDocumentoPendientes,
  getDocumentoCobrados,
}

async function getDocumentoPendientes(
  identificacion: string,
  nombres: string,
  comprobante: string,
  puntoVenta: number,
  lugar: number,
  consignacion: boolean,
  consolidar: boolean,
  fecha: string,
): Promise<Promise<any>> {
  const query: any = {
    identificacion: identificacion,
    nombre: nombres,
    comprobante: comprobante,
    puntoVenta: puntoVenta,
    locCodigo: lugar,
    consignacion: consignacion,
    consolidar: consolidar,
    fechaHoy: fecha,
  }


  try {
    const dataPendientes = await RequestHelper.getAll<any>(
      'clientes',
      'general/customersReceivable',
      '',
      query,
    )
    return dataPendientes
  } catch (error) {
    console.log(error)
    return error
  }
}

async function getDocumentoCobrados(
  identificacion: string,
  nombres: string,
  fechaUltimaVenta: string,
  fechaUltimoPago: string,
): Promise<Promise<any>> {
  // const query = {
  //   fechaHoy: object['fechaHoy'],
  //   locCodigo: object['locCodigo'],
  // }

  const query: any = {
    identificacion: identificacion,
    nombre: nombres,
    fechaUltimaVenta: fechaUltimaVenta,
    fechaUltimoPago: fechaUltimoPago,
  }

  console.log(query)


  try {
    const dataPendientes = await RequestHelper.getAll<any>(
      'clientes',
      'general/clientsCollented',
      '',
      query,
    )
    return dataPendientes
  } catch (error) {
    console.log(error)
    return error
  }
}
