import React from 'react'
import RowContainer from '../../../../../../../../views/componentes/rowContainer/rowContainer'
import { Button, DataGrid } from 'devextreme-react'
import CustomCol from '../../../../../../../../views/componentes/colContainer'
import { Column } from 'devextreme-react/data-grid'
import MainModalItems from '../../../../../../../ventas/components/items/busquedaItemsModal/MainModalItems'
import { ItemVenta } from '../../../../../../../ventas/types/types'
import { useDispatch, useSelector } from 'react-redux'
import { useSetToast } from '../../../../../../../../hooks/useGlobalHooks'
import { RootState } from '../../../../../../../../store/store'
import { setDataPartsToEdit } from '../../../../store/editDataReduce'
import { ToastTypes } from '../../../../../../../../store/types'
import { Parts } from '../../../../types/types'
import { changeLoaderItems } from '../../../../store/generalReducer'
import { setCurrentExecutingAction } from '../../../../store/tabsReducer'
import { ButtonTypes } from '../../../../../../../../views/componentes/globalMenu/types'
import { ItemsServices } from '../../../../services/items.services'
import Dialog from '../../../../../../../../views/componentes/librerias/bootstrap-dialog'
import { EModulosAcatha } from '../../../../../../../../store/enumsAplication'

const Partes = (props) => {
  const { tabId } = props

  const dispatch = useDispatch()
  const setToastMessage = useSetToast()

  const dataToEditParts = useSelector(
    (state: RootState) => state.inventarios.items.editData[tabId].partes,
  )
  const codeItem = useSelector(
    (state: RootState) => state.inventarios.items.editData[tabId].codigoInterno,
  )
  const typeItem = useSelector(
    (state: RootState) =>
      state.inventarios.items.editData[tabId].generales.tipoItem,
  )

  const [showModalItems, setShowModalItems] = React.useState<boolean>(false)
  const [rowIndex, setRowIndex] = React.useState<number>(-1)
  const [confirmToDelete, setConfirmToDelete] = React.useState<boolean>(false)
  const dialogRef = React.useRef<any>(null)

  const playLoader = React.useCallback(
    (message = 'Cargando...') => {
      dispatch(changeLoaderItems({ show: true, mensaje: message }))
    },
    [dispatch],
  )

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoaderItems({ show: false, mensaje: '' }))
  }, [dispatch])

  const onAddItem = React.useCallback(
    (newItem: ItemVenta) => {
      const indexItemExists = dataToEditParts.findIndex((item: Parts) => {
        return item.itemSecundario === newItem.codigo
      })
      if (indexItemExists === -1) {
        const newItemsDataGrid = structuredClone(dataToEditParts)
        newItemsDataGrid.push({
          codigo: 0,
          itemPrincipal: codeItem,
          itemSecundario: newItem.codigo,
          itemBarras: newItem.codigoBarras,
          itemDescripcion: newItem.descripcion,
        })
        dispatch(
          setDataPartsToEdit({
            key: tabId,
            parts: newItemsDataGrid,
          }),
        )
        setShowModalItems(false)
      } else
        setToastMessage(
          'Agregar Item',
          'El Item ya se encuentra registrado',
          ToastTypes.Warning,
        )
    },
    [dataToEditParts, setToastMessage, codeItem, dispatch, tabId],
  )

  const onRemoveSelectedItem = React.useCallback(() => {
    const newItemsDataGrid = structuredClone(dataToEditParts)
    if (newItemsDataGrid[rowIndex].codigo > 0) setConfirmToDelete(true)
    else {
      newItemsDataGrid.splice(rowIndex, 1)
      dispatch(
        setDataPartsToEdit({
          key: tabId,
          parts: newItemsDataGrid,
        }),
      )
      setRowIndex(-1)
    }
  }, [dataToEditParts, dispatch, rowIndex, tabId])

  const onDeletePart = React.useCallback(
    async (partCode: number) => {
      try {
        playLoader('Eliminando relación . . .')
        dispatch(
          setCurrentExecutingAction({
            tabKey: tabId,
            buttonType: ButtonTypes.delete,
          }),
        )
        const deletePart = await ItemsServices.deletePart(partCode)
        stopLoader()
        dispatch(
          setCurrentExecutingAction({
            tabKey: tabId,
            buttonType: undefined,
          }),
        )
        if (!deletePart.error && deletePart.auto) {
          const parts = structuredClone(dataToEditParts)
          parts.splice(rowIndex, 1)
          dispatch(
            setDataPartsToEdit({
              key: tabId,
              parts: parts,
            }),
          )
          setToastMessage(
            'Eliminar Parte Relacionada',
            deletePart.message ?? 'Promoción eliminada correctamente',
            ToastTypes.Success,
          )
          setRowIndex(-1)
        } else
          setToastMessage(
            'Eliminar Parte Relacionada',
            deletePart.message ?? 'Error al eliminar promoción',
            ToastTypes.Warning,
          )
      } catch (error) {
        stopLoader()
        setToastMessage(
          'Eliminar Parte Relacionada',
          JSON.stringify(error),
          ToastTypes.Danger,
        )
        dispatch(
          setCurrentExecutingAction({
            tabKey: tabId,
            buttonType: undefined,
          }),
        )
      }
    },
    [
      dataToEditParts,
      dispatch,
      playLoader,
      rowIndex,
      setToastMessage,
      stopLoader,
      tabId,
    ],
  )

  const onConfirmToDelette = React.useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea eliminar la relación  ${dataToEditParts[rowIndex].itemBarras}?`,
      actions: [
        Dialog.Action(
          <span>
            <u>A</u>ceptar
          </span>,
          (dialog) => {
            dialog.hide()
            onDeletePart(dataToEditParts[rowIndex].codigo)
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setConfirmToDelete(false)
    return
  }, [dataToEditParts, onDeletePart, rowIndex])

  React.useEffect(() => {
    if (confirmToDelete) onConfirmToDelette()
  }, [confirmToDelete, onConfirmToDelette])

  return (
    <>
      <Dialog ref={dialogRef} />
      {showModalItems && (
        <MainModalItems
          transaccion="ventas"
          show={showModalItems}
          action="none"
          onCancel={() => setShowModalItems(false)}
          returnItem={(item) => onAddItem(item)}
          modulo={EModulosAcatha.Items}
          tipoItem={{ value: typeItem.value, label: typeItem.label }}
        />
      )}
      <RowContainer className="m-2">
        <RowContainer>
          <CustomCol xs="12" md="6">
            <div
              style={{
                display: 'flex',
                gap: '2px',
                alignItems: 'center',
                width: '100%',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                id="btnAdd"
                className="me-1"
                icon="add"
                stylingMode="contained"
                type="default"
                onClick={() => setShowModalItems(true)}
              />
              <Button
                id="btnRemove"
                icon="trash"
                stylingMode="contained"
                type="danger"
                onClick={() => onRemoveSelectedItem()}
                disabled={rowIndex < 0}
              />
            </div>
          </CustomCol>
        </RowContainer>
        <RowContainer>
          <CustomCol xs="12" md="6">
            <DataGrid
              className="m-2"
              selection={{ mode: 'single' }}
              hoverStateEnabled={true}
              dataSource={dataToEditParts ?? []}
              loadPanel={{ enabled: true }}
              onRowClick={({ rowIndex }) => setRowIndex(rowIndex)}
              showBorders={true}
              showRowLines={true}
            >
              <Column
                dataType="string"
                dataField="itemBarras"
                caption="Barras"
                width="25%"
              />
              <Column
                dataType="string"
                dataField="itemDescripcion"
                caption="Item"
                width="75%"
              />
            </DataGrid>
          </CustomCol>
        </RowContainer>
      </RowContainer>
    </>
  )
}

export default React.memo(Partes)

export const defaultPartes: Array<Parts> = []
