import React from 'react'
import { CCloseButton } from '@coreui/react-pro'
import { TabState } from '../../../store/genericTabTypes'

interface ITabNavTitleProps extends React.PropsWithChildren {
  tab: TabState<any>
  tabIndex: number
  // eslint-disable-next-line no-unused-vars
  ontabClosing: (index: number) => void
  current: boolean
}

const TabNavTitle: React.FunctionComponent<ITabNavTitleProps> = (props) => {
  return (
    <div>
      <span className="text-primary">{props.tab.tittle}</span>
      {props.tab.canClose && props.current && (
        <CCloseButton
          style={{
            color: 'red',
            fontWeight: 7000,
            marginLeft: '7px',
            height: '4px',
          }}
          className=" text-danger"
          onClick={() => {
            props.ontabClosing(props.tabIndex)
          }}
        />
      )}
    </div>
  )
}

export default TabNavTitle
