import React from 'react'
import SelectBox from 'devextreme-react/select-box'
import { TiposIce } from './types/types'
import { tiposIceServices } from './services/tiposIce.service'

interface TiposIceLookUpProps extends React.PropsWithChildren {
  selected: TiposIce | null | { codigo: number }
  // eslint-disable-next-line no-unused-vars
  onChanged: (newValue: TiposIce | null) => void
  // eslint-disable-next-line no-unused-vars
  onChangedOptions?: (newValue: Array<TiposIce> | []) => void
  disabled?: boolean
}

const TiposIceLookUp: React.FC<TiposIceLookUpProps> = (props) => {
  const { selected, onChanged, disabled, onChangedOptions } = props
  const selectRef = React.useRef<any>()
  const [options, setOptions] = React.useState<Array<TiposIce> | []>([])

  const loadDatos = React.useCallback(async () => {
    const data = await tiposIceServices.getTipos('Elija una opción')
    if (data.error === false) {
      let optionsLookup = []
      if (data.auto.length > 0) {
        optionsLookup = data.auto.map((option) => {
          return {
            codigo: option?.codigo ?? 0,
            nombre: option?.nombre ?? '',
            descripcion: option?.descripcion ?? '',
            valor: option?.valor ?? 0,
            vigencia: option?.vigencia ?? '',
            hasta: option?.hasta ?? '',
            estado: option?.estado ?? 0,
            estadoDescripcion: option?.estadoDescripcion ?? '',
            tipo: option?.tipo ?? '',
            tipoValor: option?.tipoValor ?? '',
            adValorEm: option?.adValorEm ?? 0,
            exFabrica: option?.exFabrica ?? 0,
            exAduana: option?.exAduana ?? 0,
            exhibir: option?.exhibir ?? 0,
          }
        })
      }
      setOptions(optionsLookup)
      if (onChangedOptions) {
        onChangedOptions(optionsLookup)
      }
      const selectedIndex = optionsLookup.findIndex((option) => {
        return option.codigo === selected?.codigo
      })
      onChanged(optionsLookup[selectedIndex] ?? optionsLookup[0])
    }
  }, [onChanged, onChangedOptions, selected])

  const getItemDisplayExpr = React.useCallback((item: any) => {
    return item ? `${item.nombre}` : ''
  }, [])

  const onLookUpValueChanged = React.useCallback(
    (evt: any) => {
      if (evt.event !== undefined) {
        onChanged(evt.value)
      }
    },
    [onChanged],
  )

  const onSelectBoxFocusIn = React.useCallback((e) => {
    e.element.querySelector('input.dx-texteditor-input').select()
  }, [])

  const refreshSelected = React.useCallback(() => {
    if (options?.length > 0) {
      const selectedIndex = options.findIndex((option: any) => {
        return option.codigo === selected?.codigo
      })
      onChanged(options[selectedIndex] ?? options[0])
    }
  }, [onChanged, options, selected])

  React.useEffect(() => {
    loadDatos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    refreshSelected()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  return (
    <SelectBox
      id="tiposImpuestosSelect"
      ref={selectRef}
      items={options ?? []}
      searchEnabled
      displayExpr={getItemDisplayExpr}
      searchExpr={['nombre']}
      searchTimeout={500}
      value={selected}
      onValueChanged={onLookUpValueChanged}
      disabled={disabled}
      onFocusIn={onSelectBoxFocusIn}
    >
      {props.children}
    </SelectBox>
  )
}
export default React.memo(TiposIceLookUp)
