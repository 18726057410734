import { useDispatch } from "react-redux"
import { RequestHelper } from "../../../../../../helpers/requestHelper"
import { PagedResult, PaginationInfo } from "../../../../../../store/types"
import { setNroRegistrosEncontradas, setSearch } from "../store/searchReducer"
import { FiltroBusquedaRolesPago, GenerateData, GenerateEntryNomina, GenrateRolsList, GetTransfers, RolesPayList, SaveDataList, SaveRolsPay, UpdatePay } from "../types/types"
import { useCallback } from "react"
import CustomStore from "devextreme/data/custom_store"
import DataSource from "devextreme/data/data_source"
import { DateUtils } from "../../../../../../helpers/dateUtils"



export const RolsPaymentsServices = {
  getRolsPayments,
  getRolsPaymentsAll,
  useSetDataSources,
  deleteRolPay,
  saveListaPay,
  savePayRes,
  loadActive,
  loadRolParameters,
  loadUserDiscount,
  saveRolsPay,
  generateRolEntry,
  updatedPay,
  generateRols,
  saveAllList,
  getTransfers,
}


async function getRolsPayments(codigo: number): Promise<any> {
  try {
    const obj = {
      rolPagos: codigo
    }
    const dataApi = await RequestHelper.getAll<any>('nomina/roles/paymentRoles', 'forms/getAll', '', obj)
    return dataApi
  } catch (error) {
    return error
  }
}

async function getRolsPaymentsAll(filter: FiltroBusquedaRolesPago): Promise<any> {
  try {
    const obj = {
      cedula: filter?.cedula ?? '',
      nombres: filter?.nombres ?? '',
      apellidos: filter?.apellidos ?? '',
      pais: filter?.pais?.codigo ?? 0,
      mes: filter?.mes?.value ?? '',
      anio: filter?.anio ?? 0,
      formaPago: filter?.formaPago?.codigo ?? 0,
      local: filter?.lugar?.codigo ?? 0,
      cobrado: 0,
      personal: filter?.todos?.value ?? 0,
    }
    const dataApi = await RequestHelper.getAll<any>('nomina/roles/paymentRoles', 'getAll', '', obj)
    return dataApi
  } catch (error) {
    return error
  }
}



async function getPaysRolsPaged(
  filter: FiltroBusquedaRolesPago,
  pagination: PaginationInfo,
): Promise<PagedResult<RolesPayList>> {

  const queryData = {
    cedula: filter?.cedula ?? '',
    nombres: filter?.nombres ?? '',
    apellidos: filter?.apellidos ?? '',
    pais: filter?.pais?.codigo ?? 0,
    mes: filter?.mes?.value ?? '',
    anio: filter?.anio ?? 0,
    formaPago: filter?.formaPago?.codigo ?? 0,
    local: filter?.lugar?.codigo ?? 0,
    cobrado: 0,
    personal: filter?.todos?.value ?? 0,
    isPaged: true,
    page: pagination.current,
    limit: pagination.size,
  }

  const apiDataPaged = await RequestHelper.get<any>(
    'nomina/roles/paymentRoles',
    'getAll',
    '',
    queryData,
  )
  const apiData = apiDataPaged.auto as Array<RolesPayList>
  const contrats = apiData
  const pagedResult: PagedResult<RolesPayList> = {
    currentPage: parseInt(apiDataPaged.paginaActual),
    from: parseInt(apiDataPaged.desde),
    to: parseInt(apiDataPaged.hasta),
    count: parseInt(apiDataPaged.totalRegistros),
    result: onParseData(contrats),
  }
  return pagedResult
}


function onParseData(contrats: Array<RolesPayList>): Array<RolesPayList> {
  const newArray = contrats.map(x => {
    const data: RolesPayList = {
      ...x,
      fechaCobro: (x?.fechaCobro !== null && x?.fechaCobro !== "") ? DateUtils.format(x?.fechaCobro, 'yyyy-MM-dd', 'dd/MM/yyyy') : ""
    }
    return data
  })

  return newArray
}


function useSetDataSources() {
  const dispatch = useDispatch()
  const getRolsPayDatasource = useCallback(
    (find: FiltroBusquedaRolesPago) => {
      const store = new CustomStore<void, RolesPayList>({
        useDefaultSearch: true,
        key: 'codigo',
        byKey: async (key) => {
          console.log(key)
        },
        load: async (loadOptions) => {
          const paginInfo: PaginationInfo = {
            current: (loadOptions.skip ?? 0) / (loadOptions.take ?? 0) + 1,
            size: loadOptions.take ?? 10,
          }

          let pagedResult: PagedResult<RolesPayList> = {
            currentPage: 0,
            from: 0,
            to: 0,
            count: 0,
            result: [],
          }

          pagedResult = await getPaysRolsPaged(find, paginInfo)
          if (pagedResult.result.length === 0) {
            dispatch(setSearch(false))
          }
          return {
            data: pagedResult.result,
            totalCount: pagedResult.count,
          }
        },
        update: async (data) => {
          return Promise.resolve()
        },
      })
      const dataSource = new DataSource({
        totalCount: store.totalCount,
        paginate: true,
        pageSize: 10,
        store: store,
        key: 'codigo',
        requireTotalCount: true,
        onChanged: () => {
          dispatch(setNroRegistrosEncontradas(dataSource.totalCount()))
        },
      })
      return dataSource
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return { getRolsPayDatasource }
}


async function deleteRolPay(codigo: number, mes: string, anio: number): Promise<any> {
  try {
    const obj = {
      orgRolCodigo: codigo,
      mes,
      anio
    }
    const dataApi = await RequestHelper.deleteRequestAll<any>('nomina/roles/paymentRoles', 'delete', '', obj)
    return dataApi
  } catch (error) {
    return error
  }
}

async function saveListaPay(data: SaveDataList): Promise<any> {
  try {
    const obj = {
      ...data
    }
    const dataApi = await RequestHelper.postAll<any>('nomina/roles/paymentRoles', 'updateAll', obj)
    return dataApi
  } catch (error) {
    return error
  }
}

async function savePayRes(infoRegistro: Array<RolesPayList>): Promise<any> {
  try {
    const obj = {
      infoRegistro
    }
    const dataApi = await RequestHelper.postAll<any>('nomina/roles/paymentRoles', 'payAll', obj)
    return dataApi
  } catch (error) {
    return error
  }
}

async function loadActive(usuario: number, fecha: string): Promise<any> {
  try {
    const obj = {
      usuario,
      fecha
    }
    const dataApi = await RequestHelper.getAll<any>('nomina/contratos', 'getActive', '', obj)
    return dataApi
  } catch (error) {
    return error
  }
}

async function loadRolParameters(codigo: number): Promise<any> {
  try {
    const obj = {
      codigo
    }
    const dataApi = await RequestHelper.getAll<any>('nomina/general', 'roleParameters/get', '', obj)
    return dataApi
  } catch (error) {
    return error
  }
}


async function loadUserDiscount(codigo: number, mes: string, anio: number): Promise<any> {
  try {
    const obj = {
      usuario: codigo,
      mes,
      anio
    }
    const dataApi = await RequestHelper.getAll<any>('nomina/roles/paymentRoles', 'discounts/getUserDiscount', '', obj)
    return dataApi
  } catch (error) {
    return error
  }
}


async function saveRolsPay(data: SaveRolsPay): Promise<any> {
  try {
    const obj = {
      ...data
    }
    const dataApi = await RequestHelper.postAll<any>('nomina/roles/paymentRoles', 'save', obj)
    return dataApi
  } catch (error) {
    return error
  }
}

async function generateRolEntry(data: GenerateEntryNomina): Promise<any> {
  try {
    const obj = {
      ...data
    }
    const dataApi = await RequestHelper.getAll<any>('nomina/roles/paymentRoles', 'generatePayrolEntry', '', obj)
    return dataApi
  } catch (error) {
    return error
  }
}


async function updatedPay(data: UpdatePay): Promise<any> {
  try {
    const obj = {
      ...data
    }
    const dataApi = await RequestHelper.postAll<any>('nomina/roles/paymentRoles', 'updatePays', obj)
    return dataApi
  } catch (error) {
    return error
  }
}


async function generateRols(data: GenerateData): Promise<any> {
  try {
    const obj = {
      ...data
    }
    const dataApi = await RequestHelper.getAll<any>('nomina/roles/paymentRoles', 'generateRoles', '', obj)
    return dataApi
  } catch (error) {
    return error
  }
}

async function saveAllList(infoRegistro: Array<GenrateRolsList>): Promise<any> {
  try {
    const obj = {
      infoRegistro
    }
    const dataApi = await RequestHelper.postAll<any>('nomina/roles/paymentRoles', 'saveAll', obj)
    return dataApi
  } catch (error) {
    return error
  }
}

async function getTransfers(data: GetTransfers): Promise<any> {
  try {
    const obj = {
      ...data
    }
    const dataApi = await RequestHelper.getAll<any>('nomina/roles/paymentRoles', 'getTransfer', '', obj)
    return dataApi
  } catch (error) {
    return error
  }
}