import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ConfiguracionesSujetoExcluido } from '../types/types'
import { AutorizacionDocumentoVenta } from '../../../../../ventas/types/types'
import { CompraInfo } from '../../../../types/types'

const initialState: ConfiguracionesSujetoExcluido = {
  autorizacionDocumento: null,
  usaDescuentoGlobal: false,
  compraSeleccionada: null,
  modulo: 0,
}

const configuracionesSujetoExcluidoSlice = createSlice({
  name: 'configuracionesSujetoExcluidoCompras',
  initialState: initialState,
  reducers: {
    initialize(state, acion: PayloadAction<AutorizacionDocumentoVenta>) {
      state.autorizacionDocumento = acion.payload
    },
    setAutorizacionModuloSujExcluido(
      state,
      acion: PayloadAction<AutorizacionDocumentoVenta>,
    ) {
      state.autorizacionDocumento = acion.payload
    },
    seleccionarCompra(state, acion: PayloadAction<CompraInfo | null>) {
      state.compraSeleccionada = acion.payload
    },
    setCodigoModulo(state, acion: PayloadAction<number>) {
      state.modulo = acion.payload
    },
  },
})

export const configuracionesReducer = configuracionesSujetoExcluidoSlice.reducer
export const { setAutorizacionModuloSujExcluido } =
  configuracionesSujetoExcluidoSlice.actions
