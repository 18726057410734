import { combineReducers } from '@reduxjs/toolkit'
import { editDataReducer } from './editDataReduce'
import { menuReducer } from './menuReducer'
import { searchGuiasReducer } from './searchReducer'
import { tabsReducer } from './tabsReducer'
import { configuracionesReducer } from './configuracionesGuiasReducer'
import { GuiasState } from '../types/types'

// const initialState: WindowVentasState<InfoVenta> = {
//   menu: {},
//   search: {},
//   tabs: {
//     current: "",
//     tabs: []
//   }
// }

export const guiasRemisionReducer = combineReducers<GuiasState>({
 menu: menuReducer,
 tabs: tabsReducer,
 search: searchGuiasReducer,
 editData: editDataReducer,
 configuraciones: configuracionesReducer,
})
