import React from 'react'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import './asyncSelectBox.scss'
interface ISelectBoxProps extends React.PropsWithChildren {
  id: string
  labelKey: string
  multiple?: boolean
  onChange?: (selected: any[]) => void
  placeholder: string
  selected: any[]
  disabled?: boolean
  clearButton?: boolean
  onSearch: any
  filterBy?: any
  renderMenuItemChildren?: any
  minSearchlength?: number
  autofocus?: boolean
  defaultOpen?: boolean
  onKeyDown?: any
}

const AsyncSelectBox: React.FunctionComponent<ISelectBoxProps> = (props) => {
  const [selected, setSelected] = React.useState<Array<any>>([])
  const [loading, setLoading] = React.useState(false)
  const [options, setOptions] = React.useState([])

  const handleSearch = React.useCallback(async (query, searchFn) => {
    try {
      setLoading(true)
      const opt = await searchFn(query)
      setOptions(opt)
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }, [])

  React.useEffect(() => {
    setSelected(props.selected)
  }, [props])

  return (
    <AsyncTypeahead
      id={props.id}
      labelKey={(option) => option[props.labelKey]}
      multiple={props.multiple}
      onChange={(sel) => {
        setSelected(sel)
        if (props.onChange) {
          props.onChange(sel)
        }
      }}
      options={options}
      placeholder={props.placeholder ?? 'Escoja una opción'}
      selected={selected}
      disabled={props.disabled}
      clearButton={false}
      flip={true}
      positionFixed={true}
      isLoading={loading}
      delay={700}
      onSearch={async (query) => await handleSearch(query, props.onSearch)}
      filterBy={props.filterBy}
      minLength={props.minSearchlength ?? 4}
      renderMenuItemChildren={props.renderMenuItemChildren}
      autoFocus={props.autofocus}
      defaultOpen={props.defaultOpen}
      onKeyDown={props.onKeyDown}
      useCache={false}
      promptText="Buscando..."
      searchText="Buscando..."
      emptyLabel="Sin resultados"
    />
  )
}

export default AsyncSelectBox
