import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../../store/store'
import { FETCH_STATUS, LoaderInfo } from '../../../../../store/types'
import {
  ClientesPendientes,
  ClientesxCobrarState,
  FiltrosClientesState,
} from '../types/types'
import { DateUtils } from '../../../../../helpers/dateUtils'
import { ClientesxCobrar } from '../components/clientes/service/clientesxcobrar.service'
import { TEmpresasOption } from '../../../../componentes/empresasLookUp/store/type'

const initialState: ClientesxCobrarState<ClientesPendientes> = {
  filter: {
    estado: true,
    empresa: null,
    cliente: null,
    comprobante: '',
    puntoVenta: null,
    proyecto: null,
    lugar: null,
    consignacion: false,
    consolidar: false,
    fecha: DateUtils.dateToString(new Date(), 'dd/MM/yyyy'),
    fechaUV: DateUtils.dateToString(new Date(), 'dd/MM/yyyy'),
    fechaUP: DateUtils.dateToString(new Date(), 'dd/MM/yyyy'),
  },
  status: FETCH_STATUS.IDDLE,
  tabs: ['Pendientes', 'Cobrados'],
  currentTab: 'Pendientes',
  keyTab: 'Pendientes',
  loader: {
    show: false,
    mensaje: '',
  },
  mensaje: '',
  resultados: [],
  resultadosCobrados: [],
}

export const fetchDocs = createAsyncThunk<
  Array<ClientesPendientes>,
  FiltrosClientesState
>('clientes/general/customersReceivable', async (filtro) => {
  try {
    const clientesApi = await ClientesxCobrar.getDocumentoPendientes(
      filtro.cliente !== null ? filtro.cliente.identificacion : '',
      filtro.cliente !== null ? filtro.cliente.nombres : '',
      filtro.comprobante ?? '',
      filtro.puntoVenta !== null ? filtro.puntoVenta.codigo : -1,
      filtro.lugar !== null ? filtro.lugar.codigo : -1,
      filtro.consignacion,
      filtro.consolidar,
      filtro.fecha,
    )
    return clientesApi
  } catch (error) {
    return Promise.reject(error)
  }
})

export const fetchDocsCobrados = createAsyncThunk<
  Array<ClientesPendientes>,
  FiltrosClientesState
>('clientes/general/clientsCollented', async (filtro) => {
  try {
    const clientesApi = await ClientesxCobrar.getDocumentoCobrados(
      filtro.cliente !== null ? filtro.cliente.identificacion : '',
      filtro.cliente !== null ? filtro.cliente.nombres : '',
      filtro.fechaUV,
      filtro.fechaUP,
    )
    return clientesApi
  } catch (error) {
    return Promise.reject(error)
  }
})

const clientesxCobrarSlice = createSlice({
  name: 'clientesDocumentos',
  initialState: initialState,
  reducers: {
    setEmpresa(state, action: PayloadAction<TEmpresasOption | null>) {
      state.filter.empresa = action.payload
    },
    setCleanResult(state) {
      state.resultados = []
      state.resultadosCobrados = []
    },
    setCleanFileds(state) {
      state.filter.estado = true
      state.filter.empresa = null
      state.filter.cliente = null
      state.filter.comprobante = ''
      state.filter.puntoVenta = null
      state.filter.proyecto = null
      state.filter.lugar = null
      state.filter.consignacion = false
      state.filter.consolidar = false
    },
    changeFilter(state, action: PayloadAction<FiltrosClientesState>) {
      state.filter = action.payload
      state.filter.estado = action.payload.estado
      state.filter.empresa = action.payload.empresa
      state.filter.cliente = action.payload.cliente
      state.filter.comprobante = action.payload.comprobante
      state.filter.puntoVenta = action.payload.puntoVenta
      state.filter.proyecto = action.payload.proyecto
      state.filter.lugar = action.payload.lugar
      state.filter.consignacion = action.payload.consignacion
      state.filter.consolidar = action.payload.consolidar
      state.filter.fecha = action.payload.fecha
    },
    changeCurrentTab(state, action: PayloadAction<string>) {
      state.currentTab = action.payload
      state.keyTab = action.payload
    },
    changeLoaderClientes(state, action: PayloadAction<LoaderInfo>) {
      state.loader = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDocs.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchDocs.fulfilled, (state, { payload }) => {
      state.resultados = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchDocs.rejected, (state, { payload }) => {
      state.resultados = []
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
    builder.addCase(fetchDocsCobrados.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchDocsCobrados.fulfilled, (state, { payload }) => {
      state.resultadosCobrados = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchDocsCobrados.rejected, (state, { payload }) => {
      state.resultados = []
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
  },
})

export const selectFiltroClientesPendientes = (state: RootState) => {
  return state.clientes.docCuentasXCobrar.clientes.filter
}

export const getClientesResultados = (state: RootState) => {
  return state.clientes.docCuentasXCobrar.clientes.resultados
}

export const getClientesCurrentTab = (state: RootState) => {
  return state.clientes.docCuentasXCobrar.clientes.currentTab
}

export const { setEmpresa, changeFilter, setCleanResult, changeCurrentTab, setCleanFileds, changeLoaderClientes } =
  clientesxCobrarSlice.actions

export const clientesxCobrarReducer = clientesxCobrarSlice.reducer
