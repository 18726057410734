import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AutorizacionDocumentoVenta } from '../../../types/types'
import { ConfiguracionesGuias } from '../types/types'

const initialState: ConfiguracionesGuias = {
 autorizacionDocumento: null,
}

const configuracionesGuiasSlice = createSlice({
 name: 'configuracionesGuias',
 initialState: initialState,
 reducers: {
  initialize(state, acion: PayloadAction<AutorizacionDocumentoVenta>) {
   state.autorizacionDocumento = acion.payload
  },
  setAutorizacionModuloGuias(
   state,
   acion: PayloadAction<AutorizacionDocumentoVenta>,
  ) {
   state.autorizacionDocumento = acion.payload
  },
 },
})

export const configuracionesReducer = configuracionesGuiasSlice.reducer
export const { setAutorizacionModuloGuias } = configuracionesGuiasSlice.actions
