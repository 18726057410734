import React, { useCallback, } from 'react'
import { CustomButtons } from '../../../../../../ventas/types/generics';
import { AnexoInfo, ComprasType, DatosDetalleRetenciones, DatosRetenciones, SustentosTributarios, TabStateAnexoTransaccional, TAnexoIngresar, TipoATS } from '../../../types/types';

import LoadingIndicator from '../../../../../../../views/componentes/loadingindicator/loadingindicator';
import { RootState } from '../../../../../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import ValidationGroup from 'devextreme-react/validation-group'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer';
import CustomCol from '../../../../../../../views/componentes/colContainer';
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput';
import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box'
import { StatesEdition } from '../../../../../../ventas/types/enums';
import { defaultDatosEdicionAnexo } from '../../../store/defaulData';
import { changeEditStatus, clearButtonClick, setButtons, setCurrentExecutingAction } from '../../../store/tabsReduxcers';
import { addToast } from '../../../../../../../store/toasterReducer';
import { ToastTypes } from '../../../../../../../store/types';
import { setDatosEdicion, updateTypeATS, updateCompras } from '../../../store/editDataReducer';
import RadioGroup from 'devextreme-react/radio-group'
import { ATS_TYPE } from '../../../../../types/types';
import { CAlert, CCard, CCol, CNav, CNavItem, CNavLink, CRow, CTabContent, CTabPane } from '@coreui/react-pro';
import TablaCompras from "./tablasTransacciones/tablaCompras";
import TablaDetalleRetenciones from "./tablasTransacciones/tablaDetalleRetenciones";
import TablaRetenciones from "./tablasTransacciones/tablaRetenciones";
import TablaVentas from "./tablasTransacciones/tablaVentas";
import TablaAnulados from "./tablasTransacciones/tablaAnulados";
import SelectBox from '../../../../../../../views/componentes/selectBox/selectBox';
import Button from 'devextreme-react/button'
import { anexoTransaccionalService } from '../../../../../services/anexoTransaccional.services';
import { ButtonTypes } from '../../../../../../../views/componentes/globalMenu/types';

import { VisorXml } from '../../../../../componentes/visorXml/visorXml';
import BlockUi from '../../../../../../../views/componentes/librerias/block-ui';
import Dialog from '../../../../../../../views/componentes/librerias/bootstrap-dialog';

interface ICAnexoProps extends React.PropsWithChildren {
  // info: DocumentInfo<PedidoInfo>,
  info: any,
  tabId: string,
  tab: TabStateAnexoTransaccional<AnexoInfo>
}

export enum TABLE_ID {
  COMPRAS = "compras",
  RETENCIONES = "retenciones",
  DETALLE_RETENCIONES = "detalleRetenciones",
}

const Anexo: React.FunctionComponent<ICAnexoProps> = (props) => {

  const { info, tabId, tab } = props;
  const validationGroupRef = React.useRef<any>();
  const dialogRef = React.useRef<any>(null);

  const dispatch = useDispatch();
  const usuario = useSelector((state: RootState) => { return state.global.session?.usuario });
  const empresa = useSelector((state: RootState) => { return state.global.session?.empresa });
  const localSession = useSelector((state: RootState) => { return state.global.session?.local });

  const loader = useSelector((state: RootState) => { return state.impuestos.anexoTransaccional.editData[tabId].loader });
  const loading = useSelector((state: RootState) => { return state.impuestos.anexoTransaccional.editData[tabId].loading });
  const datosEdicion = useSelector((state: RootState) => { return state.impuestos.anexoTransaccional.editData[tabId] });
  const compras = useSelector((state: RootState) => { return state.impuestos.anexoTransaccional.editData[tabId].compras });
  const retencionesRedux = useSelector((state: RootState) => { return state.impuestos.anexoTransaccional.editData[tabId].retenciones });
  const detalleRetencionesRedux = useSelector((state: RootState) => { return state.impuestos.anexoTransaccional.editData[tabId].detalleRetenciones });
  const ventas = useSelector((state: RootState) => { return state.impuestos.anexoTransaccional.editData[tabId].ventas });
  const ventasEstablecimiento = useSelector((state: RootState) => { return state.impuestos.anexoTransaccional.editData[tabId].ventasEstablecimiento });
  const anulados = useSelector((state: RootState) => { return state.impuestos.anexoTransaccional.editData[tabId].anulados });
  const typeAnnex = useSelector((state: RootState) => { return state.impuestos.anexoTransaccional.editData[tabId].typeAnnex });
  const year = useSelector((state: RootState) => { return state.impuestos.anexoTransaccional.editData[tabId].year });
  const month = useSelector((state: RootState) => { return state.impuestos.anexoTransaccional.editData[tabId].month });
  const semester = useSelector((state: RootState) => { return state.impuestos.anexoTransaccional.editData[tabId].semester });
  const tipoAts = useSelector((state: RootState) => { return state.impuestos.anexoTransaccional.editData[tabId].tipoAts });
  const ruc = useSelector((state: RootState) => { return state.impuestos.anexoTransaccional.editData[tabId].ruc });

  const sustentosTributariosRedux = useSelector((state: RootState) => { return state.impuestos.anexoTransaccional.editData[tabId].sustentosTributarios });

  const [atsOptions, setAtsOptions] = React.useState<Array<TipoATS> | []>([]);
  const [sustentosTributarios, setSustentosTributarios] = React.useState<Array<{ codigo: string, tipo: string }> | []>([]);
  const [sustentoTributario, setSustentoTributario] = React.useState<{ codigo: string, tipo: string } | null>(null);
  const [retenciones, setRetenciones] = React.useState<Array<DatosRetenciones> | []>([]);
  const [detalleRetenciones, setDetalleRetenciones] = React.useState<Array<DatosDetalleRetenciones> | []>([]);
  const [comprasSeleccionadaIndex, setComprasSeleccionadaIndex] = React.useState<number>(-1);
  const [retencionSeleccionadaIndex, setRetencionSeleccionadaIndex] = React.useState<number>(-1);
  const [paths, setPaths] = React.useState<{
    xml: Blob | null, urlBlobPdf: Blob | null
  }>();

  const [showVisor, setShowVisor] = React.useState<boolean>(false);
  const [filename, setFilename] = React.useState<string>("");
  // const [reporte, setReporte] = React.useState<null | "Viewer" | "Designer">(null);
  const [activeKey, setActiveKey] = React.useState<number>(1)


  const seleccion = React.useCallback(async (data, table: TABLE_ID) => {
    if (table === TABLE_ID.COMPRAS) {
      setComprasSeleccionadaIndex(data.index)
    } else if (table === TABLE_ID.RETENCIONES) {
      setRetencionSeleccionadaIndex(data.index)
    }
  }, []);

  const loadFilterRetenciones = React.useCallback(async (dataPrm: { data: ComprasType, index: number }) => {
    const { data } = dataPrm;
    setRetenciones([])
    const filtradoRetencion = retencionesRedux.find((item: DatosRetenciones) => {
      return (item.compraCodigo === data.compraCodigo);
    });

    if (filtradoRetencion) {
      setRetenciones([{ ...filtradoRetencion }])
    }
  }, [retencionesRedux]);

  const loadFilterDetallesRetenciones = React.useCallback(async (dataPrm: { data: ComprasType, index: number }) => {
    const { data } = dataPrm;
    setDetalleRetenciones([])
    const filtradoDetalleRetencion = detalleRetencionesRedux.find((item: DatosDetalleRetenciones) => {
      return ((item.compraCodigo === data.compraCodigo) && (item.recCodigo === 0));
    });

    if (filtradoDetalleRetencion) {
      setDetalleRetenciones([{ ...filtradoDetalleRetencion }])
    }
  }, [detalleRetencionesRedux]);


  const loadSustentos = React.useCallback(async (dataPrm: { data: ComprasType, index: number }) => {
    const { data } = dataPrm;
    if (compras.length <= 0) {
      setSustentosTributarios([])
      return false;
    }
    const dataProviderSustentos: number = sustentosTributariosRedux.findIndex((item: any) => {
      return String(item.codigo) === String(data.tipoComprobanteCodigo);
    });

    if (dataProviderSustentos > -1) {
      setSustentosTributarios(sustentosTributariosRedux[dataProviderSustentos].sustentos)
      if (data.sustentoCodigo !== "00") {
        const sustentoCompra: number = sustentosTributariosRedux[dataProviderSustentos].sustentos.findIndex((item: any) => {
          return String(item.codigo) === String(data.sustentoCodigo);
        });
        if (sustentoCompra > -1) {
          setSustentoTributario(sustentosTributariosRedux[dataProviderSustentos].sustentos[sustentoCompra]);
        } else {
          setSustentoTributario(sustentosTributariosRedux[dataProviderSustentos].sustentos[0]);
        }

      } else {
        setSustentoTributario(sustentosTributariosRedux[dataProviderSustentos].sustentos[0]);
      }
    }
  }, [compras, sustentosTributariosRedux]);


  const cargarDetallesRetenciones = React.useCallback(async (data: { data: any, index: number }, table: TABLE_ID) => {
    await seleccion(data, table);
    await loadSustentos(data)
    await loadFilterRetenciones(data);
    await loadFilterDetallesRetenciones(data)
  }, [
    seleccion, loadSustentos,
    loadFilterDetallesRetenciones, loadFilterRetenciones
  ]);



  const asignarSustento = React.useCallback(async () => {
    if (comprasSeleccionadaIndex < -1) {
      return false;
    }
    if (sustentoTributario === null) {
      return false;
    }
    try {

      const comprasAux = compras.slice(0);

      const updateAsigancion: ComprasType = {
        ...comprasAux[comprasSeleccionadaIndex],
        sustentoCodigo: sustentoTributario?.codigo ?? "",
        sustentoTipo: sustentoTributario?.tipo ?? ""
      }

      comprasAux[comprasSeleccionadaIndex] = {
        ...updateAsigancion
      };

      dispatch(updateCompras({
        compras: comprasAux,
        key: tabId
      }))

      const asignacion = await anexoTransaccionalService.asignarSustento({
        comCodigo: comprasAux[comprasSeleccionadaIndex].compraCodigo,
        susCodigo: sustentoTributario?.codigo ?? "00"
      });

      // mostrar alert asignado correctaente o no asifnado
    } catch (error) {
      console.error(error)
    }

  }, [
    comprasSeleccionadaIndex, tabId,
    dispatch, compras, sustentoTributario
  ]);

  const modoNuevo = React.useCallback(async (template: number, limpiar: boolean = false) => {
    if (loading === false && !limpiar) {
      return;
    }

    const data = { ...datosEdicion };
    dispatch(setButtons({
      tabKey: tabId,
      buttons: {
        ...AnexoButtons,
        Buscar: false,
        Editar: false,
        Imprimir: false,
        Exportar: false,
        Enviar: false,
        Guardar: true,
        Generar_xml: true
      }
    }))

    try {

      const sustentos = await anexoTransaccionalService.getSustentos("01");
      if (sustentos && sustentos.length > 0) {
        data.sustentosTributarios = sustentos;
      }

      setAtsOptions(data.tiposAts ?? [])
      if (data.tiposAts.length > 0) {
        data.tipoAts = data.tiposAts[0] ?? null
      }
      data.loading = false;
      data.loader = {
        mensaje: '',
        show: false
      }

    } catch (error) {
      data.loading = false;
      data.tieneError = true;
      data.mensajeError = typeof error == 'string' ? error : JSON.stringify(error);
      data.loader = {
        mensaje: '',
        show: false
      }
      dispatch(addToast({
        content: data.mensajeError,
        type: ToastTypes.Danger
      }))
    }
    dispatch(setDatosEdicion({
      data: data,
      key: tabId
    }));
  }, [loading, tabId, dispatch, datosEdicion]);

  const onChangegSustento = React.useCallback((selected) => {
    setSustentoTributario(selected);
  }, [])

  const onErrorConfirm = React.useCallback((messages: any, tittle?: string) => {
    dialogRef.current.show({
      title: <><strong>{"Ácatha"}</strong></>,
      body:
        <>
          <strong style={{ fontSize: "14px" }}>{tittle}</strong>
          <br />
          <CAlert color='danger'>
            <ul className="errorList"  >
              {messages.map(function (item, id) {
                return <li key={id} >{item?.sms ?? ""}</li>
              })}
            </ul>
          </CAlert>
        </>,
      actions: [
        Dialog.Action(
          <span><u>{"A"}</u>{"ceptar"}</span>,
          (dialog) => {
            dialog.hide();
          },
          'btn-success',
          'n'
        ),
        Dialog.Action(
          <span><u>{"C"}</u>{"ancelar"}</span>,
          (dialog) => {
            dialog.hide();
          },
          'btn-danger',
          'c'
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide();
      }
    });
    return;
  }, []);

  const validarFormulario = React.useCallback(async () => {

    const smsErrors: Array<any> = [];
    // if (datosEdicion?.cliente?.codigo === 0) {
    //   let sms: string = "Seleccione el Cliente por favor.";
    //   smsErrors.push({ sms });
    // }
    return smsErrors;
  }, [datosEdicion]);

  const setToast = React.useCallback((texto, type, tittle: string = "Acatha") => {
    let tipo: ToastTypes = ToastTypes.Info;
    if (type === 'warning') {
      tipo = ToastTypes.Warning;
    } else if (type === 'success') {
      tipo = ToastTypes.Success;
    } if (type === 'info') {
      tipo = ToastTypes.Info;
    } if (type === 'danger') {
      tipo = ToastTypes.Danger;
    }
    dispatch(addToast({
      id: '',
      autoHide: 5000,
      title: tittle,
      content: texto,
      fade: true,
      type: tipo
    }));
  }, [dispatch]);

  const cargarXmlLegacy = React.useCallback(async () => {
    try {
      const filtros: any = {
        anio: year,
        mes: month,
        semestre: semester,
        tipoAts: Number(tipoAts?.value) ?? 0,
        tipoAnexo: typeAnnex,
      }
      const blobApi = await anexoTransaccionalService.obtenerXml(filtros);

      setPaths({
        xml: blobApi,
        urlBlobPdf: blobApi
      });
      setFilename("AT-" + String(filtros.mes) + String(filtros.anio) + '.xml')
      setShowVisor(true)

    } catch (error) {
      // setError(JSON.stringify(error));
      console.error(error);
    }
    finally {
      // setLoading(false);
    }
  }, [month, semester, tipoAts, typeAnnex, year])

  const generar = React.useCallback(async () => {

    if (usuario == null || empresa == null || localSession == null) {
      return;
    }

    const resValidarFormulario = await validarFormulario();
    if (resValidarFormulario.length > 0) {
      onErrorConfirm(resValidarFormulario, "Antes de continuar revise lo siguiente:");
      return false
    }

    try {

      dispatch(setDatosEdicion({
        data: {
          ...datosEdicion,
          loader: {
            show: true,
            mensaje: 'Generando...'
          }
        },
        key: tabId
      }));

      dispatch(setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: ButtonTypes.save
      }));

      const generaAnexo: TAnexoIngresar = {
        anio: year,
        mes: month,
        semestre: semester,
        tipoAts: Number(tipoAts?.value) ?? 0,
        tipoAnexo: typeAnnex,
        compras: compras,
        retenciones: retencionesRedux,
        detalleRetenciones: detalleRetencionesRedux,
        ventas: ventas,
        anuladas: anulados,
        ventasEstablecimiento: ventasEstablecimiento
      }

      const resultado = await anexoTransaccionalService.ingresarAnexo(generaAnexo);

      let sms = "";
      sms = resultado.message;
      let typeToast = 'danger';

      if (resultado['error'] === true) {
        typeToast = 'warning';
        setToast(sms, typeToast, "Guardar");
        dispatch(setDatosEdicion({
          data: {
            ...datosEdicion,
            loader: {
              show: false,
              mensaje: ''
            }
            // imprimir: empresa.imprimeAutomaticamenteAlfacturar ?? false
          },
          key: tabId
        }));
        dispatch(setCurrentExecutingAction({
          tabKey: tabId,
          buttonType: undefined
        }))
        return false;
      } else {
        typeToast = 'success';
        setToast(sms, typeToast, "Guardar");
      }

      dispatch(setDatosEdicion({
        data: {
          ...datosEdicion,
          typeAnnex: typeAnnex,
          ruc: ruc,
          year: year,
          month: month,
          semester: semester,
          tipoAts: tipoAts,
          compras: compras,
          retenciones: retencionesRedux,
          detalleRetenciones: detalleRetencionesRedux,
          ventas: ventas,
          anulados: anulados,
          ventasEstablecimiento: ventasEstablecimiento,
          sustentosTributarios: sustentosTributariosRedux,
          tiposAts: atsOptions,
          loader: {
            show: false,
            mensaje: ''
          },
          imprimir: empresa.imprimeAutomaticamenteAlfacturar ?? false
        },
        key: tabId
      }));

      // dispatch(changeEditStatus({
      //   estado: StatesEdition.save,
      //   tabKey: tabId,
      //   info: {
      //     // ...resultado['auto'],
      //     codigo: 1,
      //     numero: 1,
      //     titleTab: "AT-" + String(generaAnexo.mes) + String(generaAnexo.anio)
      //   },
      //   buttons: {
      //     ...AnexoButtons,
      //     Buscar: true,
      //     Generar_xml: true,
      //   }
      // }));


      // if (empresa.imprimeAutomaticamenteAlfacturar === true) {
      await cargarXmlLegacy();
      // }

      dispatch(setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: undefined
      }))

    } catch (error) {
      console.error(error);
      const err: any = error;
      let message = "";
      if (err && err['error'] === true) {
        message = err['message'];
      } else {
        message = 'Error al obtener el anexo ' + (typeof err === 'string' ? err : JSON.stringify(err));
      }
      dispatch(addToast({
        id: '',
        autoHide: 5000,
        content: message,
        fade: true,
        title: 'Guardar',
        type: ToastTypes.Danger
      }));
      dispatch(setDatosEdicion({
        data: {
          ...datosEdicion,
          loader: {
            show: false,
            mensaje: ''
          }
        },
        key: tabId
      }));
      dispatch(setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: undefined
      }))
      return;
    }
  }, [
    anulados, compras, datosEdicion,
    detalleRetencionesRedux, dispatch, empresa,
    localSession, onErrorConfirm, retencionesRedux,
    tabId, usuario, validarFormulario,
    ventas, ventasEstablecimiento, typeAnnex,
    year, month, semester, tipoAts,
    setToast, atsOptions, sustentosTributariosRedux,
    ruc, cargarXmlLegacy
  ])


  const handleButtonClick = React.useCallback((button: ButtonTypes) => {
    switch (button) {
      case ButtonTypes.generar_xml.replaceAll("_", " "): {
        generar();
        break;
      }
      default:
        break;
    }
    dispatch(clearButtonClick(tabId))
  }, [dispatch, generar, tabId])

  React.useEffect(() => {
    if (tab.editStatus === StatesEdition.new) {
      modoNuevo(tab.info?.codigo ?? 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
  }, [handleButtonClick, tab.globalButtonClick]);

  // React.useEffect(() => {
  // if (reporte !== null) {
  //   cargarXmlLegacy();
  // }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [reporte]);


  return (
    <>
      <div style={{ padding: '10px', overflowX: 'hidden', paddingBottom: "150px" }} id="mantenimientoPedido"  >
        <Dialog ref={dialogRef} />
        {showVisor &&
          <VisorXml
            show={showVisor}
            fileName={`${filename ?? ""}`}
            urlXml={paths?.xml ?? null}
            urlBlobPdf={paths?.urlBlobPdf ?? null}
            onClose={() => {
              setShowVisor(false)
              setFilename("")
            }}
          />
        }

        <BlockUi loader={LoadingIndicator} tag="div" blocking={loader.show} message={loader.mensaje}  >
          <fieldset  >
            <ValidationGroup id={`valGroupOrden${props.tabId}`} ref={validationGroupRef}>
              <RowContainer>
                <CustomCol >
                  <strong>{"ANEXO TRANSACCIONAL"}</strong>
                </CustomCol>
                <CustomCol >
                  <RadioGroup
                    name='tiposAts'
                    dataSource={atsOptions}
                    value={datosEdicion.tipoAts}
                    layout='horizontal'
                    displayExpr='label'
                    onValueChanged={(data) => {
                      dispatch(updateTypeATS({
                        tipoAts: data.value,
                        key: tabId
                      }));
                    }}
                  />
                </CustomCol>
              </RowContainer>
              <div>
                <RowContainer>
                  <CustomCol >

                    <CNav variant="tabs" role="tablist">
                      <CNavItem>
                        <CNavLink
                          href="javascript:void(0);"
                          active={activeKey === 1}
                          onClick={() => setActiveKey(1)}
                        >
                          {"Compras"}
                        </CNavLink>
                      </CNavItem>
                      <CNavItem>
                        <CNavLink
                          href="javascript:void(0);"
                          active={activeKey === 2}
                          onClick={() => setActiveKey(2)}
                        >
                          {"Ventas"}
                        </CNavLink>
                      </CNavItem>
                      <CNavItem>
                        <CNavLink
                          href="javascript:void(0);"
                          active={activeKey === 3}
                          onClick={() => setActiveKey(3)}
                        >
                          {"Comprobantes Anulados"}
                        </CNavLink>
                      </CNavItem>
                    </CNav>
                    <CTabContent style={{ overflowY: 'unset' }}>
                      <CTabPane visible={activeKey === 1} >
                        <CCard className={'m-0 p-4'}>

                          <RowContainer>
                            <CustomCol >
                              <TablaCompras
                                data={compras}
                                cargar={(data) => {
                                  cargarDetallesRetenciones(data, TABLE_ID.COMPRAS)
                                }}
                              />
                            </CustomCol>
                          </RowContainer>

                          <RowContainer>
                            <CustomCol md="6" sm="12" >
                              <Labeled
                                label='Sustento'
                              >
                                <SelectBox
                                  id='selectSustento'
                                  displayExpr='tipo'
                                  keyExpr='codigo'
                                  options={sustentosTributarios}
                                  selected={sustentoTributario}
                                  placeholder='Sustento Tributario'
                                  multiple={false}
                                  onChange={onChangegSustento}
                                  clearButton={true}
                                  onEnterKey={(data) => { }}
                                />
                              </Labeled>
                            </CustomCol>
                            <CCol sm="2" className={"d-flex align-items-end justify-content-end"} >
                              <Button
                                style={buttonsProductoStyle}
                                text='ASIGNAR'
                                stylingMode='contained'
                                type='normal'
                                onClick={() => {
                                  asignarSustento()
                                }}
                                elementAttr={{
                                  id: 'btnAsignar'
                                }}
                              />
                            </CCol>
                          </RowContainer>

                          <CRow className={"mt-2"}>
                            <CCol md="5" sm="12"  >
                              <Labeled
                                label='Concepto AIR'
                              >
                                <TablaRetenciones data={retenciones}
                                  cargar={(data) => {
                                    seleccion(data, TABLE_ID.RETENCIONES);
                                  }}
                                />
                              </Labeled>
                            </CCol>
                            <CCol md="5" sm="12"   >
                              <Labeled
                                label=''
                              >
                                <TablaDetalleRetenciones data={detalleRetenciones} />
                              </Labeled>

                            </CCol>
                          </CRow>


                        </CCard>
                      </CTabPane>
                      <CTabPane visible={activeKey === 2}>
                        <CCard className={'m-0 p-4'}>
                          <RowContainer>
                            <CustomCol >

                              <TablaVentas
                                data={ventas}
                              />

                            </CustomCol>
                          </RowContainer>
                        </CCard>
                      </CTabPane>
                      <CTabPane visible={activeKey === 3}>
                        <CCard className={'m-0 p-4'}>
                          <RowContainer>
                            <CustomCol >

                              <TablaAnulados
                                data={anulados}
                              />

                            </CustomCol>
                          </RowContainer>
                        </CCard>
                      </CTabPane>
                    </CTabContent>

                  </CustomCol>
                </RowContainer>

              </div>
            </ValidationGroup>
          </fieldset>
        </BlockUi>
      </div>
    </>
  );
}

export const AnexoButtons: CustomButtons = {
  Nuevo: true,
  Guardar: true,
  Buscar: true,
  Deshacer: true,
  Editar: false,
  Imprimir: true,
  Extraer: false,
  Generar_xml: false
}

const buttonsProductoStyle = { marginLeft: '2px' };

export default React.memo(Anexo);