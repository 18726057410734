import { DateUtils, formatoFechasApi } from "../../../../helpers/dateUtils";
import { RequestHelper } from "../../../../helpers/requestHelper";
import { SesionService } from "../../../../services/sesion.service";
import { TitleOption } from "../store/type";

export const tittlesService = { getTittesAdicionales };

async function getTittesAdicionales(label: string): Promise<any> {
  try {
    const sesion = SesionService.getCurrentSesion()
    const data: any = {
      mensaje: label
    };
    const keysToCache: any = {
      ...data,
      dateKey: DateUtils.getCurrentDateAsString(formatoFechasApi),
      empresa: sesion?.empresa?.codigo,
    }
    const cacheKey = 'tipoTitulos' + JSON.stringify(keysToCache);
    const cached = sessionStorage.getItem(cacheKey);
    if (cached) {
      return { error: false, auto: JSON.parse(cached) };
    } else {
      const res = await RequestHelper.getAll<any>('nomina/contratos', 'tittle/cBoxLoad', "", data);
      const opt: Array<TitleOption> = [];
      if (res?.auto && res?.auto.length > 0) {
        for (const x of res.auto) {
          opt.push({
            codigo: parseInt(x.codigo) ?? -1,
            descripcion: x?.descripcion ?? '',
            estado: parseInt(x?.estado),
          })
        }
      }
      sessionStorage.setItem(cacheKey, JSON.stringify(opt));
      res.auto = opt ?? []
      return res;
    }
  } catch (error) {
    return error;
  }
}

