import React, { useEffect } from 'react'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput'
import {
  Button,
  NumberBox,
  TextBox,
  ValidationGroup,
  ValidationSummary,
  Validator,
} from 'devextreme-react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/store'
import Dialog from '../../../../../../views/componentes/librerias/bootstrap-dialog'
import { CCol, CRow } from '@coreui/react-pro'
import MainModalItems from '../../../../../ventas/components/items/busquedaItemsModal/MainModalItems'
import { ItemVenta } from '../../../../../ventas/types/types'
import { RequiredRule } from 'devextreme-react/validator'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import {
  changeLoaderItems,
  setCurrentFunction,
} from '../../store/generalReducer'
import {
  clearButtonClick,
  closeTabMultistore,
  setCurrentExecutingAction,
} from '../../store/tabsReducer'
import { useSetToast } from '../../../../../../hooks/useGlobalHooks'
import { ToastTypes } from '../../../../../../store/types'
import VisualizaError from '../../../../../ventas/pages/shared/visualizaError/visualizaError'
import ListErrosValidationForm from '../../../../../componentes/listadoErroresValidacionForm'
import { ItemsServices } from '../../services/items.services'
import { utilidades } from '../../../../../../helpers/utilidades'
import { deleteEditData } from '../../store/editDataReduce'
import { EModulosAcatha } from '../../../../../../store/enumsAplication'

const Nuevo = (props) => {
  const { tabId } = props
  const dispatch = useDispatch()
  const setToastMessage = useSetToast()

  const currentAction = useSelector(
    (state: RootState) => state.inventarios.items.general.currentFunction,
  )
  const tabs = useSelector((state: RootState) => {
    return state.inventarios.items.tabs
  })
  const selectedItem = useSelector(
    (state: RootState) => state.inventarios.items.search.selectedItem,
  )

  const [showModalItems, setShowModalItems] = React.useState<boolean>(true)
  const [addItem, setAddItem] = React.useState<ItemVenta>(null)
  const validationGroupRef = React.useRef<any>()
  const dialogRef = React.useRef<any>(null)
  const [validationFormErrors, setValidationFormErrors] = React.useState<
    Array<any>
  >([])
  const [showPopupError, setShowPopupError] = React.useState<boolean>(false)

  const playLoader = React.useCallback(
    (message = 'Cargando...') => {
      dispatch(changeLoaderItems({ show: true, mensaje: message }))
    },
    [dispatch],
  )

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoaderItems({ show: false, mensaje: '' }))
  }, [dispatch])

  const onHandleSave = React.useCallback(async () => {
    const validationFormResult = validationGroupRef.current.instance.validate()
    if (validationFormResult.isValid === false) {
      const errorList = []
      validationFormResult.brokenRules.map((rule) => {
        errorList.push(rule.message)
      })
      setValidationFormErrors(errorList)
      setShowPopupError(true)
      setToastMessage(
        'Guardar Unificar Item',
        'Existen Errores en los datos, por favor verifiquelos.',
        ToastTypes.Warning,
      )
    } else {
      try {
        dispatch(
          setCurrentExecutingAction({
            tabKey: tabId,
            buttonType: ButtonTypes.save,
          }),
        )
        playLoader('Guardando unificar ítem. . .')
        const unifyItem = await ItemsServices.unifyItem(
          selectedItem.itemCodigo,
          addItem?.codigo,
        )
        stopLoader()
        dispatch(
          setCurrentExecutingAction({
            tabKey: tabId,
            buttonType: undefined,
          }),
        )
        if (!unifyItem.error && unifyItem.auto) {
          setToastMessage(
            'Guardar Unificar Item',
            unifyItem.message ?? 'Unificar Item registrado correctamente',
            ToastTypes.Success,
          )
          setToastMessage(
            'Guardar Unificar Item',
            'La unificación fué un éxito, por favor no se olvide de reconstruir el kardex del ítem !!!',
            ToastTypes.Warning,
          )
          dispatch(closeTabMultistore(tabId))
          await utilidades.sleep(50)
          dispatch(deleteEditData(tabId))
        } else
          setToastMessage(
            'Código de barras',
            unifyItem.message ?? 'Error al unificar ítem',
            ToastTypes.Warning,
          )
      } catch (error) {
        dispatch(
          setCurrentExecutingAction({
            tabKey: tabId,
            buttonType: undefined,
          }),
        )
        stopLoader()
        setToastMessage(
          'Código de barras',
          JSON.stringify(error) ?? 'Error al unificar Ítem',
          ToastTypes.Danger,
        )
      }
    }
  }, [
    addItem,
    dispatch,
    playLoader,
    selectedItem,
    setToastMessage,
    stopLoader,
    tabId,
  ])

  const handleButtonClick = React.useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.save:
          if (tabs.current === tabId) onHandleSave()
          break
        default:
          break
      }
      dispatch(setCurrentFunction(''))
      dispatch(clearButtonClick(tabId))
    },
    [dispatch, onHandleSave, tabId, tabs],
  )

  useEffect(() => {
    if (currentAction !== '') handleButtonClick(currentAction)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAction])

  return (
    <RowContainer className="m-2">
      {showPopupError && (
        <VisualizaError
          titulo="Error en Unificar Item"
          mensaje={'Error al guardar'}
          content={
            <ListErrosValidationForm
              mainMessage={
                'Antes de guardar la información revise lo siguiente:'
              }
              errorsList={validationFormErrors}
              color={'danger'}
            />
          }
          onOk={() => setShowPopupError(false)}
        />
      )}
      {showModalItems && (
        <MainModalItems
          transaccion="ventas"
          show={showModalItems}
          onCancel={() => setShowModalItems(false)}
          returnItem={(item: ItemVenta) => {
            setAddItem(item)
            setShowModalItems(false)
          }}
          action="none"
          modulo={EModulosAcatha.Items}
          tipoItem={{
            value: selectedItem.itemTipo,
            label: selectedItem.itemTipoDescripcion,
          }}
        />
      )}
      <Dialog ref={dialogRef} />
      <ValidationGroup id={'formUnify'} ref={validationGroupRef}>
        <ValidationSummary id="summary" />
        <CRow>
          <CRow>
            <CCol xs="12" md="6">
              <Labeled label="ITEM ACTUAL:" />
            </CCol>
            <CCol xs="12" md="6">
              <Labeled label="UNIFICAR CON:" />
            </CCol>
          </CRow>
          <CCol xs="12" md="6">
            <CRow>
              <CustomCol xs="12" md="6">
                <Labeled label="Barras:">
                  <TextBox
                    value={selectedItem.itemBarras ?? ''}
                    readOnly={true}
                  />
                </Labeled>
              </CustomCol>
              <CustomCol xs="12" md="6">
                <Labeled label="Código">
                  <TextBox
                    value={selectedItem.itemCodigoUsuario ?? ''}
                    readOnly={true}
                  />
                </Labeled>
              </CustomCol>
            </CRow>
            <CRow>
              <CustomCol xs="12" md="8">
                <Labeled label="Nombre:">
                  <TextBox
                    value={selectedItem.itemDescripcion ?? ''}
                    showClearButton={true}
                    width="100%"
                    readOnly={true}
                  />
                </Labeled>
              </CustomCol>
              <CustomCol xs="12" md="4">
                <Labeled label="Código Interno:">
                  <NumberBox
                    value={selectedItem.itemCodigo ?? 0}
                    showClearButton={true}
                    width="100%"
                    readOnly={true}
                  />
                </Labeled>
              </CustomCol>
            </CRow>
          </CCol>
          <CCol xs="12" md="6">
            <CRow>
              <CustomCol xs="12" md="6">
                <Labeled label="Barras:">
                  <TextBox
                    value={addItem?.codigoBarras ?? ''}
                    readOnly={true}
                  ></TextBox>
                </Labeled>
              </CustomCol>
              <CustomCol xs="12" md="6">
                <Labeled label="Código">
                  <TextBox
                    value={addItem?.codigoInterno ?? ''}
                    readOnly={true}
                  />
                </Labeled>
              </CustomCol>
            </CRow>
            <CRow>
              <CustomCol xs="12" md="8">
                <Labeled label="Nombre:">
                  <TextBox
                    value={addItem?.descripcion ?? ''}
                    showClearButton={true}
                    width="100%"
                    readOnly={true}
                  />
                </Labeled>
              </CustomCol>
              <CustomCol xs="12" md="4">
                <Labeled label="Código Interno:">
                  <NumberBox
                    value={addItem?.codigo ?? null}
                    showClearButton={true}
                    width="100%"
                    readOnly={true}
                  >
                    <Validator>
                      <RequiredRule
                        message={'El campo Código de Ítem es obligatorio'}
                      />
                    </Validator>
                  </NumberBox>
                </Labeled>
              </CustomCol>
            </CRow>
          </CCol>
        </CRow>
        <CCol
          style={{ display: 'flex', justifyContent: 'end', marginTop: '8px' }}
        >
          <Button
            id="btnAdd"
            className="me-1"
            icon="add"
            stylingMode="contained"
            type="default"
            onClick={() => setShowModalItems(true)}
          />
        </CCol>
      </ValidationGroup>
    </RowContainer>
  )
}

export default Nuevo
