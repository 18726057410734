import React, { useEffect } from 'react'
import { GlobalMenu } from '../../../../views/componentes/globalMenu/globalMenu'
import {
  AccionMenu,
  CustomButtons,
  FETCH_STATUS,
  ToastTypes,
} from '../../../../store/types'
import CardContent from '../../../bancos/components/cardContent'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../../store/store'
import { ProyectosCCList } from './types/types'
import {
  changeLoaderProjectCC,
  setCurrentFunction,
} from './store/generalReducer'
import { addToast } from '../../../../store/toasterReducer'
import TabControl from './components/shared'
import { Aplicacion } from '../../store/types'
import { utilidades } from '../../../../helpers/utilidades'
import {
  changeCurrentTab,
  closeTabProjectCC,
  openTab,
  setButtons,
  setCurrentExecutingAction,
} from './store/tabsReducer'
import { ButtonTypes } from '../../../../views/componentes/globalMenu/types'
import { v4 as uuidv4 } from 'uuid'
import { deleteEditData, initDatosEdicion } from './store/editDataReduce'
import { setCleanResult } from './store/serachReducer'
import Dialog from '../../../../views/componentes/librerias/bootstrap-dialog'
import {
  useButtonDisabled,
  useMenuOptions,
} from '../../../../hooks/useMenuButtonControls'

const pathVerificador = '/proyectos'

interface MultistoreProps extends React.PropsWithChildren {}

const Proyectos: React.FunctionComponent<MultistoreProps> = () => {
  const dispatch = useDispatch()
  const menuState = useSelector((state: RootState) => state.global.menu)
  const tabs = useSelector((state: RootState) => {
    return state.inventarios.proyectos.tabs
  })
  const loader = useSelector((state: RootState) => {
    return state.inventarios.proyectos.general.loader
  })
  const searchFilter = useSelector((state: RootState) => {
    return state.inventarios.proyectos.search.filter
  })
  const statusSearch = useSelector((state: RootState) => {
    return state.inventarios.proyectos.search.status
  })
  const resultsSearch = useSelector((state: RootState) => {
    return state.inventarios.proyectos.search.resultList
  })

  const [confirmarBorrar, setConfirmarBorrar] = React.useState<boolean>(false)
  const [confirmarEditar, setConfirmarEditar] = React.useState<boolean>(false)
  const dialogRef = React.useRef<any>(null)

  const getButtonDisabled = useButtonDisabled(tabs)
  const { getMenuOptions, options } = useMenuOptions()

  const playLoader = React.useCallback(
    (message = 'Cargando...') => {
      dispatch(changeLoaderProjectCC({ show: true, mensaje: message }))
    },
    [dispatch],
  )

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoaderProjectCC({ show: false, mensaje: '' }))
  }, [dispatch])

  const onTabClosing = React.useCallback(
    async (index) => {
      const closedTab = tabs.tabs[index]
      dispatch(closeTabProjectCC(closedTab.tabKey ?? index))
      await utilidades.sleep(50)
      dispatch(deleteEditData(closedTab.tabKey ?? index))
    },
    [dispatch, tabs.tabs],
  )

  const onTabChanged = React.useCallback(
    (tabKey: string) => {
      dispatch(changeCurrentTab(tabKey))
    },
    [dispatch],
  )

  const onCleanResultFilter = React.useCallback(() => {
    dispatch(setCleanResult())
  }, [dispatch])

  const onConfirmarEditar = React.useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea Editar el registro?`,
      actions: [
        Dialog.Action(
          <span>
            <u>A</u>ceptar
          </span>,
          (dialog) => {
            dialog.hide()
            dispatch(setCurrentFunction(ButtonTypes.edit))
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setConfirmarEditar(false)
    return
  }, [dispatch])

  const onConfirmarBorrar = React.useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea Eliminar el Registro?`,
      actions: [
        Dialog.Action(
          <span>
            <u>A</u>ceptar
          </span>,
          (dialog) => {
            dialog.hide()
            dispatch(setCurrentFunction(ButtonTypes.delete))
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setConfirmarBorrar(false)
    return
  }, [dispatch])

  React.useEffect(() => {
    if (confirmarEditar) {
      onConfirmarEditar()
    }
  }, [confirmarEditar, onConfirmarEditar])

  React.useEffect(() => {
    if (confirmarBorrar) {
      onConfirmarBorrar()
    }
  }, [confirmarBorrar, onConfirmarBorrar])

  const onMenuButtonClick = React.useCallback(
    async (action: AccionMenu) => {
      const id = uuidv4()
      switch (action.actionType) {
        case ButtonTypes.find:
          if (tabs.current === 'BUSQUEDA') {
            console.log('add fetch PROYECTOS')
          } else {
            dispatch(changeCurrentTab('BUSQUEDA'))
          }
          break
        case ButtonTypes.new:
          if (tabs.current === 'BUSQUEDA') {
            dispatch(
              initDatosEdicion({
                key: id,
                data: { ...defaultDataStore },
              }),
            )
            dispatch(openTab({ key: id }))
          } else {
            dispatch(setCurrentFunction(ButtonTypes.new))
          }
          break
        case ButtonTypes.save:
          if (tabs.current !== 'BUSQUEDA')
            dispatch(setCurrentFunction(ButtonTypes.save))
          break
        case ButtonTypes.edit:
          if (tabs.current === 'BUSQUEDA') setConfirmarEditar(true)
          break
        case ButtonTypes.delete:
          if (tabs.current === 'BUSQUEDA') setConfirmarBorrar(true)
          break
        case ButtonTypes.undo:
          if (tabs.current === 'BUSQUEDA') onCleanResultFilter()
          else dispatch(setCurrentFunction(ButtonTypes.undo))
          break
        default:
          break
      }
    },
    [dispatch, onCleanResultFilter, tabs],
  )

  useEffect(() => {
    if (menuState.length > 0 && options.length === 0) {
      getMenuOptions(menuState, 'to', pathVerificador)
    }
  }, [menuState, getMenuOptions, options])

  React.useEffect(() => {
    if (statusSearch === FETCH_STATUS.FAILED) {
      stopLoader()
      dispatch(
        addToast({
          title: 'Buscar Locales',
          content: 'Error al realizar la búsqueda',
          type: ToastTypes.Danger,
        }),
      )
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'BUSQUEDA',
          buttonType: undefined,
        }),
      )
    }
    if (statusSearch === FETCH_STATUS.LOADING) {
      playLoader()
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'BUSQUEDA',
          buttonType: ButtonTypes.find,
        }),
      )
    }
    if (statusSearch === FETCH_STATUS.SUCCESS) {
      stopLoader()
      dispatch(
        setButtons({
          tabKey: 'BUSQUEDA',
          buttons: {
            Nuevo: true,
            Guardar: false,
            Editar: false,
            Eliminar: false,
            Buscar: true,
            Imprimir: false,
            Deshacer: resultsSearch.length > 0,
          },
        }),
      )
    }
  }, [dispatch, statusSearch, playLoader, stopLoader, resultsSearch])

  const optionsForm = () => {
    if (options.length > 0) {
      return (
        <>
          <GlobalMenu
            printButtonAsDropdown={false}
            showExportDesingOptions
            acciones={options}
            onClick={onMenuButtonClick}
            getButtonDisabled={getButtonDisabled}
            currentWorking={tabs.tabs[tabs.current].currentExecutingAction}
          />
        </>
      )
    } else {
      return <> </>
    }
  }

  const bodyForm = () => {
    return (
      <TabControl
        tabsState={tabs}
        onTabChanged={onTabChanged}
        ontabClosing={onTabClosing}
        tipoAplicacion={Aplicacion.proyectosCC}
      />
    )
  }

  return (
    <>
      <div id={'inventarioProyectos'}>
        <Dialog ref={dialogRef} />
        <CardContent
          childrenOptions={optionsForm()}
          childrenBody={bodyForm()}
          headerTitle={'Proyectos'}
          aplicacion={'Proyectos'}
          loader={loader}
        />
      </div>
    </>
  )
}

export default React.memo(Proyectos)

export const ModuleButtons: CustomButtons = {
  Nuevo: true,
  Guardar: true,
  Editar: false,
  Eliminar: false,
  Buscar: true,
  Imprimir: false,
  Deshacer: true,
}

export const defaultDataStore: ProyectosCCList = {
  nombre: '',
  estado: null,
  codigo: 0,
}
