import React from 'react'

interface IContainerProps extends React.PropsWithChildren {
  style?: React.CSSProperties
  fluid?: boolean
  children?: any
}

const Container: React.FunctionComponent<IContainerProps> = ({
  style = {},
  fluid = true,
  children,
}) => {
  return (
    <div className={`container${fluid ? '-fluid' : ''}`} style={style}>
      {children}
    </div>
  )
}

export default Container
