import React from 'react'
import RowContainer from '../../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../../views/componentes/labeledInput/labeledInput'
import {
  CheckBox,
  NumberBox,
  RadioGroup,
  TextArea,
  TextBox,
} from 'devextreme-react'
import { CCol, CRow } from '@coreui/react-pro'
import ImpresorasLookUp from '../../../../../../../componentes/impresorasLookUp'
import TiposDeducibleLookUp from '../../../../../../../componentes/tiposDeduciblesLookUp'
import TiposPlanesLookUp from '../../../../../../../componentes/tiposPlanesLookUp'
import TiposSubsidiosLookUp from '../../../../../../../componentes/tiposSubsidiosLookUp'
import { ItemExtras } from '../../../../types/types'
import { useDispatch, useSelector } from 'react-redux/es/exports'
import { RootState } from '../../../../../../../../store/store'
import {
  setDataExtrasToEdit,
  setDeductiblesTypes,
  setPlans,
  setPrinter,
  setSubsidy,
} from '../../../../store/editDataReduce'
import { Impresora } from '../../../../../../../componentes/impresorasLookUp/types/types'
import { TOptionGenerico } from '../../../../../../../clientes/pages/clientes/types/types'
import { PlanFacturacion } from '../../../../../../../componentes/tiposPlanesLookUp/types/types'
import { Subsidio } from '../../../../../../../componentes/tiposSubsidiosLookUp/types/types'

export const optionsVirtual = [
  { value: 1, label: 'Si' },
  { value: 0, label: 'No' },
]

export const optionsBajoPedido = [
  { value: 1, label: 'Si' },
  { value: 0, label: 'No' },
  { value: 2, label: 'Notificar' },
]

export const optionsLiqTransporte = [
  { value: 'C', label: 'Compras' },
  { value: 'V', label: 'Ventas' },
  { value: 'N', label: 'N/A' },
]
const Extras = (props) => {
  const { tabId } = props

  const dispatch = useDispatch()
  const dataToEditExtras = useSelector(
    (state: RootState) => state.inventarios.items.editData[tabId].extras,
  )

  const onChangedSubsidy = React.useCallback(
    (subsidy: Subsidio) => {
      dispatch(
        setSubsidy({
          key: tabId,
          subsidy: subsidy,
        }),
      )
    },
    [dispatch, tabId],
  )

  const onChangedPlans = React.useCallback(
    (plan: PlanFacturacion) => {
      dispatch(
        setPlans({
          key: tabId,
          plans: plan,
        }),
      )
    },
    [dispatch, tabId],
  )

  const onChangedDeductible = React.useCallback(
    (deductible: TOptionGenerico) => {
      dispatch(
        setDeductiblesTypes({
          key: tabId,
          deductiblesTypes: deductible,
        }),
      )
    },
    [dispatch, tabId],
  )

  const onChangedPrinter = React.useCallback(
    (printer: Impresora) => {
      dispatch(
        setPrinter({
          key: tabId,
          printer: printer,
        }),
      )
    },
    [dispatch, tabId],
  )

  const onChangedDataExtras = React.useCallback(
    (event: any, fieldToUpdate: string) => {
      if (event.event)
        dispatch(
          setDataExtrasToEdit({
            key: tabId,
            extras: {
              ...dataToEditExtras,
              [fieldToUpdate]: event.value,
            },
          }),
        )
    },
    [dispatch, tabId, dataToEditExtras],
  )

  return (
    <RowContainer className="m-2">
      <RowContainer>
        <CustomCol xs="12" md="6">
          <Labeled label="Woo">
            <CRow>
              <CCol xs="12" md="3">
                <Labeled label="Virtual">
                  <RadioGroup
                    name="idTypesItem"
                    dataSource={optionsVirtual}
                    value={dataToEditExtras.virtual}
                    layout="horizontal"
                    displayExpr="label"
                    onValueChanged={(event) =>
                      onChangedDataExtras(event, 'virtual')
                    }
                    width="100%"
                  />
                </Labeled>
              </CCol>
              <CCol xs="12" md="4">
                <Labeled label="Vender Individualmente">
                  <RadioGroup
                    name="idTypesItem"
                    dataSource={optionsVirtual}
                    value={dataToEditExtras.venderIndividual}
                    layout="horizontal"
                    displayExpr="label"
                    onValueChanged={(event) =>
                      onChangedDataExtras(event, 'venderIndividual')
                    }
                    width="100%"
                  />
                </Labeled>
              </CCol>
              <CCol xs="12" md="5">
                <Labeled label="Vender Bajo Pedido">
                  <RadioGroup
                    name="idTypesItem"
                    dataSource={optionsBajoPedido}
                    value={dataToEditExtras.venderBajoPedido}
                    layout="horizontal"
                    displayExpr="label"
                    onValueChanged={(event) =>
                      onChangedDataExtras(event, 'venderBajoPedido')
                    }
                    width="100%"
                  />
                </Labeled>
              </CCol>
            </CRow>
            <CRow>
              <CCol xs="12" md="3">
                <Labeled label="Peso">
                  <NumberBox
                    value={dataToEditExtras.peso}
                    onValueChanged={(event) =>
                      onChangedDataExtras(event, 'peso')
                    }
                    showClearButton={true}
                  />
                </Labeled>
              </CCol>
              <CCol xs="12" md="3">
                <Labeled label="Largo">
                  <NumberBox
                    value={dataToEditExtras.largo}
                    onValueChanged={(event) =>
                      onChangedDataExtras(event, 'largo')
                    }
                    showClearButton={true}
                  />
                </Labeled>
              </CCol>
              <CCol xs="12" md="3">
                <Labeled label="Ancho">
                  <NumberBox
                    value={dataToEditExtras.ancho}
                    onValueChanged={(event) =>
                      onChangedDataExtras(event, 'ancho')
                    }
                    showClearButton={true}
                  />
                </Labeled>
              </CCol>
              <CCol xs="12" md="3">
                <Labeled label="Alto">
                  <NumberBox
                    value={dataToEditExtras.alto}
                    onValueChanged={(event) =>
                      onChangedDataExtras(event, 'alto')
                    }
                    showClearButton={true}
                  />
                </Labeled>
              </CCol>
            </CRow>
            <CRow>
              <CCol xs="12" md="6">
                <Labeled label="Nota">
                  <TextBox
                    value={dataToEditExtras.nota}
                    onValueChanged={(event) =>
                      onChangedDataExtras(event, 'nota')
                    }
                    width="100%"
                    inputAttr={{ autocomplete: 'nope' }}
                  />
                </Labeled>
              </CCol>
              <CCol xs="12" md="6">
                <Labeled label="Tags">
                  <TextBox
                    value={dataToEditExtras.tags}
                    onValueChanged={(event) =>
                      onChangedDataExtras(event, 'tags')
                    }
                    width="100%"
                    inputAttr={{ autocomplete: 'nope' }}
                  />
                </Labeled>
              </CCol>
            </CRow>
            <CRow>
              <Labeled label="Especificaciones">
                <TextArea
                  style={{ marginTop: '4px' }}
                  value={dataToEditExtras.especificaciones}
                  onValueChanged={(event) =>
                    onChangedDataExtras(event, 'especificaciones')
                  }
                  height={'133px'}
                />
              </Labeled>
            </CRow>
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="3">
          <Labeled label="Impresora">
            <ImpresorasLookUp
              selected={dataToEditExtras.impresora}
              onChanged={onChangedPrinter}
              onChangedOptions={() => {}}
            />
          </Labeled>
          <Labeled label="Uafe">
            <CheckBox
              text=""
              value={dataToEditExtras.uafe}
              onValueChanged={(event) => onChangedDataExtras(event, 'uafe')}
            />
          </Labeled>
          <Labeled label="Electrónicos">
            <div
              style={{
                display: 'flex',
                gap: '4px',
                alignItems: 'center',
                width: '100%',
                marginTop: '4px',
              }}
            >
              {'Nivel MLS:'}
              <TextBox
                value={dataToEditExtras.nivelMls}
                onValueChanged={(event) =>
                  onChangedDataExtras(event, 'nivelMls')
                }
                showClearButton={true}
              />
            </div>
          </Labeled>
          <Labeled label="Deducible">
            <RadioGroup
              style={{ marginBottom: '6px' }}
              name="idTypesItem"
              dataSource={optionsVirtual}
              value={dataToEditExtras.deducible}
              layout="horizontal"
              displayExpr="label"
              onValueChanged={(event) =>
                onChangedDataExtras(event, 'deducible')
              }
              width="100%"
            />
            <TiposDeducibleLookUp
              selected={dataToEditExtras.tiposDeducible}
              onChanged={onChangedDeductible}
            />
          </Labeled>
          <Labeled label="Planes">
            <TiposPlanesLookUp
              selected={dataToEditExtras.planes}
              onChanged={onChangedPlans}
              onChangedOptions={() => {}}
            />
          </Labeled>
          <Labeled label="Subsidios">
            <TiposSubsidiosLookUp
              selected={dataToEditExtras.subsidio}
              onChanged={onChangedSubsidy}
              onChangedOptions={() => {}}
            />
          </Labeled>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="6">
          <Labeled label="Códigos ICE">
            <CRow>
              <CCol xs="12" md="3">
                <Labeled label="Código de Imp.">
                  <NumberBox
                    value={dataToEditExtras.codImpuesto}
                    onValueChanged={(event) =>
                      onChangedDataExtras(event, 'codImpuesto')
                    }
                    showClearButton={true}
                  />
                </Labeled>
              </CCol>
              <CCol xs="12" md="3">
                <Labeled label="Capacidad en cc">
                  <NumberBox
                    value={dataToEditExtras.capacidadCC}
                    onValueChanged={(event) =>
                      onChangedDataExtras(event, 'capacidadCC')
                    }
                    showClearButton={true}
                  />
                </Labeled>
              </CCol>
              <CCol xs="12" md="3">
                <Labeled label="Código de clasificaión">
                  <NumberBox
                    value={dataToEditExtras.codClasificacion}
                    onValueChanged={(event) =>
                      onChangedDataExtras(event, 'codClasificacion')
                    }
                    showClearButton={true}
                  />
                </Labeled>
              </CCol>
              <CCol xs="12" md="3">
                <Labeled label="Código unidad">
                  <NumberBox
                    value={dataToEditExtras.codUnidad}
                    onValueChanged={(event) =>
                      onChangedDataExtras(event, 'codUnidad')
                    }
                    showClearButton={true}
                  />
                </Labeled>
              </CCol>
            </CRow>
            <CRow>
              <CCol xs="12" md="3">
                <Labeled label="Código de Marca">
                  <NumberBox
                    value={dataToEditExtras.codMarca}
                    onValueChanged={(event) =>
                      onChangedDataExtras(event, 'codMarca')
                    }
                    showClearButton={true}
                  />
                </Labeled>
              </CCol>
              <CCol xs="12" md="3">
                <Labeled label="País de Origen">
                  <NumberBox
                    value={dataToEditExtras.paisOrigen}
                    onValueChanged={(event) =>
                      onChangedDataExtras(event, 'paisOrigen')
                    }
                    showClearButton={true}
                  />
                </Labeled>
              </CCol>
              <CCol xs="12" md="3">
                <Labeled label="Código presentación">
                  <NumberBox
                    value={dataToEditExtras.codPresentacion}
                    onValueChanged={(event) =>
                      onChangedDataExtras(event, 'codPresentacion')
                    }
                    showClearButton={true}
                  />
                </Labeled>
              </CCol>
              <CCol xs="12" md="3">
                <Labeled label="Grado Alcohólico">
                  <NumberBox
                    value={dataToEditExtras.gradoAlcohol}
                    onValueChanged={(event) =>
                      onChangedDataExtras(event, 'gradoAlcohol')
                    }
                    showClearButton={true}
                  />
                </Labeled>
              </CCol>
            </CRow>
            <CRow>
              <CCol xs="12" md="3">
                <Labeled label="Estado">
                  <NumberBox
                    value={dataToEditExtras.estado}
                    onValueChanged={(event) =>
                      onChangedDataExtras(event, 'estado')
                    }
                    width="100%"
                    inputAttr={{ autocomplete: 'nope' }}
                  />
                </Labeled>
              </CCol>
              <CCol xs="12" md="3">
                <Labeled label="Gramo de azúcar">
                  <NumberBox
                    value={dataToEditExtras.gramoAzucar}
                    onValueChanged={(event) =>
                      onChangedDataExtras(event, 'gramoAzucar')
                    }
                    width="100%"
                    inputAttr={{ autocomplete: 'nope' }}
                  />
                </Labeled>
              </CCol>
              <CCol xs="12" md="4">
                <Labeled label="Código grado alcohólico">
                  <NumberBox
                    value={dataToEditExtras.codGradoAlcohol}
                    onValueChanged={(event) =>
                      onChangedDataExtras(event, 'codGradoAlcohol')
                    }
                    width="100%"
                    inputAttr={{ autocomplete: 'nope' }}
                  />
                </Labeled>
              </CCol>
              <Labeled label="Liquidación Transporte">
                <RadioGroup
                  name="idLiqTransporte"
                  dataSource={optionsLiqTransporte}
                  value={dataToEditExtras.liqTansporte}
                  layout="horizontal"
                  displayExpr="label"
                  onValueChanged={(event) =>
                    onChangedDataExtras(event, 'liqTansporte')
                  }
                  width="100%"
                />
              </Labeled>
            </CRow>
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="2">
          <Labeled label="Producción">
            <Labeled label="Desperdicio %">
              <NumberBox
                value={dataToEditExtras.desperdicio}
                onValueChanged={(event) =>
                  onChangedDataExtras(event, 'desperdicio')
                }
                showClearButton={true}
              />
            </Labeled>
            <Labeled label="Costo en producción">
              <NumberBox
                value={dataToEditExtras.costoProduccion}
                onValueChanged={(event) =>
                  onChangedDataExtras(event, 'costoProduccion')
                }
                showClearButton={true}
              />
            </Labeled>
            <Labeled label="CMU Ancho">
              <NumberBox
                value={dataToEditExtras.cmuAncho}
                onValueChanged={(event) =>
                  onChangedDataExtras(event, 'cmuAncho')
                }
                showClearButton={true}
              />
            </Labeled>
            <Labeled label="CMU Largo">
              <NumberBox
                value={dataToEditExtras.cmuLargo}
                onValueChanged={(event) =>
                  onChangedDataExtras(event, 'cmuLargo')
                }
                showClearButton={true}
              />
            </Labeled>
          </Labeled>
        </CustomCol>
      </RowContainer>
    </RowContainer>
  )
}

export default React.memo(Extras)

export const defaultExtras: ItemExtras = {
  virtual: optionsVirtual[1],
  venderIndividual: optionsVirtual[1],
  venderBajoPedido: optionsBajoPedido[1],
  peso: 0.0,
  largo: 0.0,
  ancho: 0.0,
  alto: 0.0,
  nota: '',
  tags: '',
  especificaciones: '',
  codImpuesto: null,
  capacidadCC: 0.0,
  codClasificacion: null,
  codUnidad: null,
  codMarca: null,
  paisOrigen: 593,
  codPresentacion: null,
  gradoAlcohol: 0,
  codGradoAlcohol: 0,
  estado: 0,
  gramoAzucar: 0.0,
  impresora: null,
  uafe: false,
  nivelMls: '',
  deducible: optionsVirtual[1],
  tiposDeducible: null,
  planes: null,
  subsidio: null,
  desperdicio: 0,
  liqTansporte: optionsLiqTransporte[2],
  costoProduccion: 0.0,
  cmuAncho: 0,
  cmuLargo: 0,
}
