import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  FiltrosAtributosState,
  BusquedaState,
  AtributosInventario,
} from '../types/types'
import { FETCH_STATUS, Seleccionado } from '../../../../../../store/types'
import { attributesServices } from '../services/unidades.services'

const initialState: BusquedaState<AtributosInventario> = {
  filter: {
    propiedad: '',
    activo: true,
    initial: true,
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  seleccionado: {
    index: null,
    row: null,
    selectedRow: null,
  },
  resultados: [],
  colapsed: true,
  nrRegistrosAtributosLista: 0
}

export const fetchAttributes = createAsyncThunk<
  Array<AtributosInventario>,
  any
>('inventarios/catalogos/atributos/listar', async (filtro) => {
  try {
    const attributesList = await attributesServices.getAttributes(filtro)
    return attributesList
  } catch (error) {
    return Promise.reject(error)
  }
})

const searchSlice = createSlice({
  name: 'buscarAtributos',
  initialState: initialState,
  reducers: {
    setCleanResult(state) {
      state.resultados = []
      state.filter.propiedad = null
      state.filter.activo = true
    },
    changeFilter(state, action: PayloadAction<FiltrosAtributosState>) {
      state.filter.propiedad = action.payload.propiedad
      state.filter.activo = action.payload.activo
      state.filter.initial = action.payload.initial
    },
    setCollapsed(state, action: PayloadAction<boolean>) {
      state.colapsed = action.payload
    },
    setNrRegistrosAtributosLista(state, action: PayloadAction<number>) {
      state.nrRegistrosAtributosLista = action.payload
    },
    setResetSeleccion(state) {
      const toAdd = {
        index: null,
        row: null,
        selectedRow: null,
      }
      state.seleccionado = toAdd
    },
    setSeleccionarDato(state, action: PayloadAction<Seleccionado>) {
      const toAdd = {
        index: action.payload.index,
        row: action.payload.row,
        selectedRow: action.payload.selectedRow,
      }
      state.seleccionado = toAdd
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAttributes.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchAttributes.fulfilled, (state, { payload }) => {
      state.resultados = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchAttributes.rejected, (state, { payload }) => {
      state.resultados = []
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
  },
})

export const { changeFilter, setCleanResult, setSeleccionarDato, setResetSeleccion, setCollapsed, setNrRegistrosAtributosLista } = searchSlice.actions

export const searchReducer = searchSlice.reducer
