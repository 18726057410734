import { useDispatch } from "react-redux"
import { setNroRegistrosEncontradas, setSearch } from "../store/searchReducer"
import { useCallback } from "react"
import { ChangeContratc, ContratosList, FiltroBusquedaContratos, SaveContract, SaveListContracts, SearchUsesContract } from "../types/types"
import CustomStore from "devextreme/data/custom_store"
import { PagedResult, PaginationInfo } from "../../../../../store/types"
import DataSource from "devextreme/data/data_source"
import { RequestHelper } from "../../../../../helpers/requestHelper"
import { DateUtils } from "../../../../../helpers/dateUtils"


export const ContratsServices = { useSetDataSources, getContratsList, deleteContrat, getContrat, getChangeContrat, getDesEmployees, saveContract, getDetail, saveColumn }

async function getContratsList(filter: FiltroBusquedaContratos): Promise<any> {

  const queryData = {
    nombres: filter?.empleado ?? '',
    apellidos: filter?.empleado ?? '',
    cedula: filter?.empleado ?? '',
    tipo: filter?.tipoContrato?.codigo ?? 0,
    proceso: filter?.procesos?.codigo ?? -1,
    fechaIngreso: DateUtils.format(filter?.desde, 'dd/MM/yyyy', 'yyyy-MM-dd') ?? '',
    fechaSalida: DateUtils.format(filter?.hasta, 'dd/MM/yyyy', 'yyyy-MM-dd') ?? '',
    tipoPersonal: filter.tipoPersonal?.value ?? 0,
    organico: filter.departamento?.codigo ?? 0,
    estado: -1,
    pais: filter.pais?.codigo ?? 0,
    fecha: DateUtils.getCurrentDateAsString('dd/MM/yyyy'),
  }
  try {

    const apiData = await RequestHelper.getAll<any>(
      'nomina/contratos',
      'getAll',
      '',
      queryData,
    )
    return apiData
  } catch (error) {
    return error
  }

}

async function getContratsPaged(
  filter: FiltroBusquedaContratos,
  pagination: PaginationInfo,
): Promise<PagedResult<ContratosList>> {

  const queryData = {
    nombres: filter?.empleado ?? '',
    apellidos: filter?.empleado ?? '',
    cedula: filter?.empleado ?? '',
    tipo: filter?.tipoContrato?.codigo ?? 0,
    proceso: filter?.procesos?.codigo ?? -1,
    fechaIngreso: DateUtils.format(filter?.desde, 'dd/MM/yyyy', 'yyyy-MM-dd') ?? '',
    fechaSalida: DateUtils.format(filter?.hasta, 'dd/MM/yyyy', 'yyyy-MM-dd') ?? '',
    tipoPersonal: filter.tipoPersonal?.value ?? 0,
    organico: filter.departamento?.codigo ?? 0,
    estado: -1,
    pais: filter.pais?.codigo ?? 0,
    fecha: DateUtils.getCurrentDateAsString('dd/MM/yyyy'),
    isPaged: true,
    page: pagination.current,
    limit: pagination.size,
  }

  const apiDataPaged = await RequestHelper.get<any>(
    'nomina/contratos',
    'getAll',
    '',
    queryData,
  )
  const apiData = apiDataPaged.auto as Array<ContratosList>
  const contrats = apiData
  const pagedResult: PagedResult<ContratosList> = {
    currentPage: parseInt(apiDataPaged.paginaActual),
    from: parseInt(apiDataPaged.desde),
    to: parseInt(apiDataPaged.hasta),
    count: parseInt(apiDataPaged.totalRegistros),
    result: contrats,
  }
  return pagedResult
}



function useSetDataSources() {
  const dispatch = useDispatch()
  const getContratsDatasource = useCallback(
    (find: FiltroBusquedaContratos) => {
      const store = new CustomStore<void, ContratosList>({
        useDefaultSearch: true,
        key: 'codigo',
        byKey: async (key) => {
          console.log(key)
        },
        load: async (loadOptions) => {
          const paginInfo: PaginationInfo = {
            current: (loadOptions.skip ?? 0) / (loadOptions.take ?? 0) + 1,
            size: loadOptions.take ?? 10,
          }

          let pagedResult: PagedResult<ContratosList> = {
            currentPage: 0,
            from: 0,
            to: 0,
            count: 0,
            result: [],
          }

          pagedResult = await getContratsPaged(find, paginInfo)
          if (pagedResult.result.length === 0) {
            dispatch(setSearch(false))
          }
          return {
            data: pagedResult.result,
            totalCount: pagedResult.count,
          }
        },
        update: async (data) => {
          return Promise.resolve()
        },
      })
      const dataSource = new DataSource({
        totalCount: store.totalCount,
        paginate: true,
        pageSize: 10,
        store: store,
        key: 'codigo',
        requireTotalCount: true,
        onChanged: () => {
          dispatch(setNroRegistrosEncontradas(dataSource.totalCount()))
        },
      })
      return dataSource
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return { getContratsDatasource }
}

async function deleteContrat(codigo: number) {
  try {
    const obj = {
      contrato: codigo
    }
    const dataApi = await RequestHelper.deleteRequestAll<any>('nomina/contratos', 'pre-contracts/inactive', '', obj)
    return dataApi
  } catch (error) {
    return error
  }
}

async function getContrat(codigo: number) {
  try {
    const obj = {
      codigo
    }
    const dataApi = await RequestHelper.getAll<any>('nomina/contratos', 'precontracts/get', '', obj)
    return dataApi
  } catch (error) {
    return error
  }
}

async function getChangeContrat(data: ChangeContratc) {
  try {
    const obj = {
      ...data
    }
    const dataApi = await RequestHelper.getAll<any>('admin/roles', 'changeDocument', '', obj)
    return dataApi
  } catch (error) {
    return error
  }
}

async function getDesEmployees(data: SearchUsesContract) {
  try {
    const obj = {
      ...data
    }
    const dataApi = await RequestHelper.getAll<any>('nomina/contratos', 'getDesemployees', '', obj)
    return dataApi
  } catch (error) {
    return error
  }
}

async function saveContract(data: SaveContract) {
  try {
    const obj = {
      ...data
    }
    const dataApi = await RequestHelper.postAll<any>('nomina/contratos', 'save', obj)
    return dataApi
  } catch (error) {
    return error
  }
}

async function getDetail(codigo: number) {
  try {
    const obj = {
      codigo
    }
    const dataApi = await RequestHelper.getAll<any>('nomina/contratos', 'getDetall', '', obj)
    return dataApi
  } catch (error) {
    return error
  }
}


async function saveColumn(data: SaveListContracts) {
  try {
    const obj = {
      ...data
    }
    const dataApi = await RequestHelper.postAll<any>('nomina/contratos', 'saveColumn', obj)
    return dataApi
  } catch (error) {
    return error
  }
}