import { TabsState } from '../../../../../../store/genericTabTypes'
import {
  CustomDictionary,
  FETCH_STATUS,
  LoaderInfo,
  TEstado,
} from '../../../../../../store/types'
import { LocalOption } from '../../../../../componentes/generate/baseLookUp/store/type'
import { ItemPedido } from '../../../../../ventas/pages/ordenes/pages/ordenPedido/type/types'
import { MenuState } from '../../../../../ventas/types/types'

export type GeneralesState = {
  modulo: string
  currentAction: string
  currentFunction: string
  aplicacion: string
  loader: LoaderInfo
}

export enum EInvIniTipo {
  tipo = 'INV'
}

export type InventarioMovDatosEdicion = {
  codigo: number
  nroInventario: string
  realizadoPor: string
  inventarioDe: LocalOption | null
  loading: boolean
  fechaKardex: string
  observaciones: string
  dataGrid: Array<any>
  item: ItemPedido | null
  loader: LoaderInfo
  descripcion: string
  cantidad: number
  existencias: number
  iva: number
  pvp: number
  pvd: number
  subtotal0: number
  subtotalIva: number
  subtotal: number
  totalIva: number
  total: number
  mostratIva: boolean
  guardado: boolean
}

export type GuardarIventarioInicial = {
  infoRegistro: {
    codigo: number
    numero: string
    fecha: string
    vehiculoCodigo: number
    vehiculoKilometraje: number
    clienteCodigo: number
    mesero: number
    detalleCono: number
    detalleTrabajo: string
    detalleRecepcion: string
    detalleObservaciones: string
    detalleProforma: string
    detalleTipo: string
    valorDetalle: Array<any>
  }
}

export type InventarioInicialState = {
  config: ConfigInventarioInicial
  menu: MenuState
  general: GeneralesState
  tabs: TabsState<InventarioMovDatosEdicion>
  editData: CustomDictionary<InventarioMovDatosEdicion>
  search: BusquedaState<InventarioInicial>
}

export type BusquedaState<InventarioInicial> = {
  filter: FiltrosInventariosMovState
  filterConsolidar: FiltrosInventariosConsolidarState
  status: FETCH_STATUS
  statusConsolidate: FETCH_STATUS
  seleccionado: InventarioInicial | null
  mensaje: string
  verRegistro: boolean
  colapsado: boolean
  resultados: Array<InventarioInicial>
}

export type FiltrosInventariosMovState = {
  documento: string
  fechaDesde: string
  fechaHasta: string
  estado: TEstado
  item: ItemPedido
  observaciones: string
  initial: boolean
}


export type FiltrosInventariosConsolidarState = {
  anio: number
  fechaInv: string
  local: LocalOption | null
  dataItems: Array<any>
  dataMov: Array<any>
  subtotal0: number
  subtotalIVA: number
  subtotal: number
  iva: number
  IVA: number
  total: number
  loader: LoaderInfo
  initial: boolean
}


export type FiltrosSearch = {
  numero: string
  tipo: string
  cliente: string
  observaciones: string
  estado: number
  fechaDesde: string
  fechaHasta: string
}

export type ConfigInventarioInicial = {
  aplicacion: number
}

export interface InventarioInicial {
  codigo: number
  codigoVenta: string
  codVenta: string
  anio: number
  numero: string
  registro: string
  fecha: string
  codigoVeh: string
  kilometrajeVeh: number
  codigoCliente: number
  identificacionCliente: string
  nombreCliente: string
  direccionCliente: string
  emailCliente: string
  rcomercialCliente: string
  telefonoCliente: string
  codigoSuc: string
  usuarioCodigo: number
  usuarioNombre: string
  cono: number
  trabajo: string
  recepcion: string
  observaciones: string
  proforme: string
  estado: number
  estadoDescripcion: string
  tipo: string
  descripcion: string
  codigoVendedor: string
  fechaEntrega: string
  fechaHoraEntrega: string
  termino: string
  seguimiento: string
  atencion: string
  numitems: number
  total: number
  cobros: string
  saldo: number
  origen: string
  progreso: string
  codigoLocal: string
  colorLocal: string
  unitem: number
  descripcionTcl: string
  nombreLocal: string
  imagenReferencia: string
  imagenAlternativa: string
  garantia: number
  abono: number
  porciones: number
  direntrega: string
}

export type InventarioInicialDto = {
  Items: InventarioItem[]
  Totals: TotalItem
  Numero: string
  RealizadoPor: string
  Local: string
  Fecha: string
  Observaciones: string
}

export type InventarioItem = {
  BarCode: string // codigo barras
  Description: string
  Cant: number
  Price: number
  Value: number
}

export type TotalItem = {
  SubTotalCero: number
  SubTotalIva: number
  SubTotal: number
  Iva: number
  Totals: number
}

export type InventarioInitialDto = {
  Year: string
  Number: string
  Date: string
  Observation: string
  User: string
  State: string
}