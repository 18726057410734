import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  CustomDictionary,
  LoaderInfo,
  TEstado,
} from '../../../../../store/types'
import { EdicionPayload } from '../../../../inventario/store/types'
import { NewPosition } from '../types/types'

const initialState: CustomDictionary<NewPosition> = {}

const datosEditSlice = createSlice({
  name: 'positionDataEdit',
  initialState: initialState,
  reducers: {
    deletePosition(state, action: PayloadAction<string>) {
      delete state[action.payload]
    },
    updatePosition(
      state,
      action: PayloadAction<{
        key: string
        position: NewPosition
      }>,
    ) {
      const { key, position } = action.payload
      state[key].codigo = position.codigo
      state[key].descripcion = position.descripcion
      state[key].contrato = position.contrato
    },
    updateStatus(
      state,
      action: PayloadAction<{
        key: string
        estado: TEstado
      }>,
    ) {
      state[action.payload.key].estado = action.payload.estado
    },
    initPosition(state, action: PayloadAction<EdicionPayload<NewPosition>>) {
      if (!state[action.payload.key])
        state[action.payload.key] = action.payload.data
    },
    setPosition(state, action: PayloadAction<EdicionPayload<NewPosition>>) {
      state[action.payload.key] = action.payload.data
    },
    showTabLoader(
      state,
      action: PayloadAction<{
        key: string
        loader: LoaderInfo
      }>,
    ) {
      state[action.payload.key].loader = action.payload.loader
    },
  },
})

export const {
  updatePosition,
  initPosition,
  deletePosition,
  showTabLoader,
  setPosition,
  updateStatus,
} = datosEditSlice.actions
export const dataEditPositionReducer = datosEditSlice.reducer
