import React from 'react'
import SelectBox from 'devextreme-react/select-box'
import { TOptionGenerico } from '../../clientes/pages/clientes/types/types'

interface TiposPerformanceLookUpProps extends React.PropsWithChildren {
  selected: TOptionGenerico | null
  // eslint-disable-next-line no-unused-vars
  onChanged: (newValue: TOptionGenerico | null) => void
  // eslint-disable-next-line no-unused-vars
  onChangedOptions?: (newValue: Array<TOptionGenerico> | []) => void
  disabled?: boolean
}

const TiposPerformanceLookUp: React.FC<TiposPerformanceLookUpProps> = (
  props,
) => {
  const { selected, onChanged, disabled, onChangedOptions } = props
  const selectRef = React.useRef<any>()
  const [options, setOptions] = React.useState<Array<TOptionGenerico> | []>([])

  const loadDatos = React.useCallback(async () => {
    setOptions(optionsLookUp)
    onChangedOptions(optionsLookUp)
    const selectedIndex = optionsLookUp.findIndex((option) => {
      return option.value === selected?.value
    })
    onChanged(optionsLookUp[selectedIndex] ?? optionsLookUp[0])
  }, [onChanged, onChangedOptions, selected])

  const getItemDisplayExpr = React.useCallback((item: any) => {
    return item ? `${item.label}` : ''
  }, [])

  const onLookUpValueChanged = React.useCallback(
    (evt: any) => {
      if (evt.event !== undefined) {
        onChanged(evt.value)
      }
    },
    [onChanged],
  )

  const onSelectBoxFocusIn = React.useCallback((e) => {
    e.element.querySelector('input.dx-texteditor-input').select()
  }, [])

  const refreshSelected = React.useCallback(() => {
    if (options && options.length > 0) {
      const selectedIndex = options.findIndex((option: any) => {
        return option.value === selected?.value
      })
      onChanged(options[selectedIndex] ?? options[0])
    }
  }, [onChanged, options, selected])

  React.useEffect(() => {
    loadDatos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    refreshSelected()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  return (
    <SelectBox
      id="tiposProcedenciaSelect"
      ref={selectRef}
      items={options ?? []}
      searchEnabled
      displayExpr={getItemDisplayExpr}
      searchExpr={['label']}
      searchTimeout={500}
      value={selected}
      onValueChanged={onLookUpValueChanged}
      disabled={disabled}
      onFocusIn={onSelectBoxFocusIn}
    >
      {props.children}
    </SelectBox>
  )
}

export default React.memo(TiposPerformanceLookUp)

const optionsLookUp: Array<TOptionGenerico> = [
  { value: 0, label: 'Elija una opción' },
  { value: 1, label: 'HI' },
  { value: 2, label: 'MEDIUM' },
  { value: 3, label: 'LOW' },
]
