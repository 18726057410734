import { DateUtils, formatoFechasApi } from "../../../../helpers/dateUtils";
import { RequestHelper } from "../../../../helpers/requestHelper";
import { SesionService } from "../../../../services/sesion.service";
import { ESubtipo } from "../../../../store/enum/subtypes";
import { TEstado } from "../../../../store/types";

export const tipoContratoService = { getTipoContrato };


async function getTipoContrato(label: string): Promise<any> {
  const sesion = SesionService.getCurrentSesion();
  try {
    const data: any = {
      tipoCodigo: ESubtipo.Contratos,
      mensaje: label,
      codigo: sesion?.empresa?.codigo
    };
    const keysToCache: any = {
      ...data,
      dateKey: DateUtils.getCurrentDateAsString(formatoFechasApi),
      empresa: sesion?.empresa?.codigo,
    }
    const cacheKey = 'tipoContrato' + JSON.stringify(keysToCache);
    const cached = sessionStorage.getItem(cacheKey);
    if (cached) {
      return { error: false, auto: JSON.parse(cached) };
    } else {
      const tipos: any = await RequestHelper.getAll('subtypes', 'cboxLoad', "", data);
      const opt: Array<TEstado> = []
      if (tipos?.auto && tipos?.auto.length > 0) {
        for (const x of tipos.auto) {
          opt.push({
            codigo: x?.SUB_GRUPO ? parseInt(x.SUB_GRUPO) : x?.SUB_CODIGO ?? -1,
            descripcion: x?.SUB_VALOR ?? '',
          })
        }
      }
      sessionStorage.setItem(cacheKey, JSON.stringify(opt));
      tipos.auto = opt ?? []
      return tipos;
    }
  } catch (error) {
    return error;
  }
}