import { CAlert, CButton, CCard, CCardBody, CCol, CRow } from '@coreui/react-pro'
import {
  Button,
  DataGrid,
  DateBox,
  LoadPanel,
  NumberBox,
  RadioGroup,
  TextArea,
} from 'devextreme-react'
import { Column, Editing, Scrolling, Sorting } from 'devextreme-react/data-grid'
import React from 'react'
import CustomCol from '../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import { useDispatch, useSelector, Provider } from 'react-redux';
import { RootState } from '../../../../../../store/store'
import ModalDetallesVenta from '../../../../../componentes/modalDetallesVenta'
import FormasPagoLookUp from '../../../../../componentes/formasPagoLookUp'
import { ETipoTransaccion, TFormaPagoOption } from '../../../../../componentes/formasPagoLookUp/store/type'
import VariasFormasPago from '../../../../../ventas/components/ventas/variasFormasPago/variasFormasPago'
import ModalTarjetas from '../../../../../componentes/modalTarjetas'
import ModalAnticipos from '../../../../../shared/components/modales/modalAnticipos'
import ModalIngresoCheques from '../../../../../componentes/modalIngresoCheques'
import CabeceraFiltroBusqueda from '../shared/cabeceraFiltroBusqueda'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import { clearButtonClick, setCurrentExecutingAction } from '../../store/tabsReducer'
import { CustomButtons, FETCH_STATUS, ToastTypes } from '../../../../../../store/types';
import { TabState } from '../../../../../../store/genericTabTypes'
import { addToast } from '../../../../../../store/toasterReducer'
import { cleanData, fetchAbonar, fetchAbonarCuotasCompra, setDatosAbonos, setInitData, setResetSeleccion } from '../../store/payReducer'
import { AccountingEntry, CoutasPagar } from '../../../../../clientes/pages/docsCtasXCobrar/types/types'
import { DateUtils } from '../../../../../../helpers/dateUtils'
import { restaFechas } from '../../../../../../helpers/Helper'
import VisualizaError from '../../../../../ventas/pages/shared/visualizaError/visualizaError'
import { Abonar, Asientos, Cheques, Compra, Egreso, EgresosCajaDto, Factura, Pago, PagoEmpresa, PaySaveProvider, PayState, PaymentSaveProvider, SavePaymentProvider } from '../../types/types'
import { AbonarXPagar } from './service/abonarxPagar.service'
import { generateAcoountEntry } from '../../../../../clientes/pages/docsCtasXCobrar/store/abonosReducer'
import Importar from '../../../../../clientes/pages/docsCtasXCobrar/components/impportarExcel/importar'
import ModalAsiento from '../../../../../contabilidad/pages/mayorGeneral/componentes/modalAsiento'
import { MessagesKeys, lh } from '../../../../../../helpers/localizationHelper'
import { ModalArchivosDigitales } from '../../../../../componentes/modalArchivosDigitales'
import { ReportWithLocalDataModal } from '../../../../../../views/componentes/xtrareports/ReportWithLocalDataModal'
import { DocumentsToPayService } from '../../services/docsCtasXPagar.services'
import ListErrosValidationForm from '../../../../../componentes/listadoErroresValidacionForm'
import { EgresoTipo } from '../../../../store/types'
import { EFormasPago } from '../../../../../../store/enum/formasPago'
import { consoleService } from '../../../../../../services/console.service'

export const optionsAbono = [
  { value: 0, label: 'Por Proveedor' },
  { value: 1, label: 'Por Varios Proveedores' },
]

interface IAbonosProps {
  tabId: string
  tab: TabState<any>
}

const Abonos: React.FunctionComponent<IAbonosProps> = (props) => {
  const { tabId, tab } = props
  const dispatch = useDispatch()

  const filterGlobal = useSelector((state: RootState) => state.proveedores.documentosCuentasPorPagar.search.filter)
  const periodoActual = useSelector(
    (state: RootState) => state.shared.providers.periodoContable,
  )
  const codigoModulo = useSelector((state: RootState) => state.proveedores.documentosCuentasPorPagar.configuraciones.modulo)

  const abono = useSelector((state: RootState) => state.proveedores.documentosCuentasPorPagar.pay)
  const resultadosFactura = useSelector((state: RootState) => state.proveedores.documentosCuentasPorPagar.pay.dpFacura)
  const empresa = useSelector((state: RootState) => state.global.session?.empresa)

  const [showModalsiento, setShowModalsiento] = React.useState<boolean>(false)
  const [showLoader, setShowLoader] = React.useState(false);
  const [showDetallesVenta, setShowDetallesVenta] = React.useState<boolean>(false)
  const [showOthersPayMethod, setShowOthersMethod] = React.useState<boolean>(false)
  const [showCreditCard, setShowCreditCard] = React.useState<boolean>(false)
  const [showAdvances, setShowAdvances] = React.useState<boolean>(false)
  const [showBankChecks, setShowBankChecks] = React.useState<boolean>(false)
  const [showErrorPopup, setShowErrorPopup] = React.useState<boolean>(false)
  const [arrayErrors, setArrayErrors] = React.useState<Array<string>>([])
  const [showImportButton, setShowImportButton] = React.useState<boolean>(false)
  const [reporte, setReporte] = React.useState<null | "Viewer" | "Designer">(null)
  const [resultadoImprimir, setResultadoImprimir] = React.useState<any>({})

  const [changes, setChanges] = React.useState<any>();
  const [editRowKey, setEditRowKey] = React.useState<any>();
  const [paymentsMethods, setPaymentsMethods] = React.useState<number>(-1)
  const [othersPayMethod, setOthersPayMethod] = React.useState<Array<PaySaveProvider>>([])
  const [fechaCal, setFechaCal] = React.useState<Date>(new Date())
  const [formaPago, setFormaPago] = React.useState<TFormaPagoOption>(null)
  const [concepto, setConcepto] = React.useState<string>(null)
  const [radioGroup, setRadioGroup] = React.useState(optionsAbono[1])
  const [egresoCodigo, setEgresoCodigo] = React.useState<number>(null)
  const [totalPendientes, setTotalPendientes] = React.useState<number>(null)
  const [valorAbonos, setValorAbonos] = React.useState<number>(null)
  const [valorPagos, setValorPagos] = React.useState<number>(null)
  const [totalCuotas, setTotalCuotas] = React.useState<number>(null)
  const [dpFacturas, setDpFacturas] = React.useState<Array<Factura>>([])
  const [dpPagos, setDpPagos] = React.useState<Array<Pago>>([])
  const [dpPagosEmp, setDpPagosEmp] = React.useState<Array<any>>([])
  const [showModalDA, setShowModalDA] = React.useState(false);

  const position = { of: '#payrContent' }
  const payProveedor: string = "ABONO PROVEEDOR"
  const fechaFormato: string = 'dd/MM/yyyy'
  const fechaInput: string = 'yyyy-MM-dd'
  const abcOrigen: string = 'EGRESO'
  const textoAbonar: string = 'Cantidad a Abonar:'

  const onChangesChange = React.useCallback((changes) => {
    setChanges(changes);
  }, []);

  const getFilterString = React.useCallback((filter: PayState) => {
    const filterText = "";

    return filterText;
  }, [])

  const onEditRowKeyChange = React.useCallback((editRowKey) => {
    setEditRowKey(editRowKey);
  }, []);

  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(
      addToast({
        title: 'Abonar',
        content: mensaje,
        type: tipo,
      }),
    )
  }, [dispatch])

  const onChangeData = React.useCallback((data, key: string) => {
    if (data.value !== null && data.value !== undefined) {
      switch (key) {
        case 'FechaCal':
          setFechaCal(data.value)
          dispatch(setDatosAbonos({
            ...abono,
            fecha: DateUtils.dateToString(data.value, fechaFormato)
          }))
          break;

        case 'RadioGroup':
          setRadioGroup(data.value)
          dispatch(setDatosAbonos({
            ...abono,
            proveedorVarios: data.value
          }))
          break;

        case 'Concepto':
          setConcepto(data.value)
          break;

        default:
          break;
      }
    } else {
      if (data !== null && data !== undefined && key === "FormaPago") {
        setFormaPago(data)
        dispatch(setDatosAbonos({
          ...abono,
          formasPago: data
        }))
      }
    }
  }, [abono, dispatch])


  const onGetDataPrint = React.useCallback(async (codigo: number, accion: string) => {
    try {
      const request_search = {
        tab: 'ABONOS',
        codigoAsociado: null,
        egresoCodigo: codigo,
        tipo: "ABONO PROVEEDOR"
      }
      setShowLoader(true)
      const data = await DocumentsToPayService.getDataPrintPurchase(request_search)
      setShowLoader(false)
      if (data !== null && data !== undefined && data['error'] === false) {
        setResultadoImprimir(data['auto'])
        if (accion === 'Imprimir') {
          setReporte('Viewer')
        } else {
          setReporte('Designer')
        }
        setToast(data['message'], ToastTypes.Success)
      } else {
        setToast(data['message'], ToastTypes.Danger)
      }
    } catch (error) {
      setShowLoader(false)
      setToast(error.message, ToastTypes.Danger)
    }
  }, [setToast])

  const onBroom = React.useCallback(() => {
    // dispatch(setDatosAbonos({
    //   ...abono,
    //   formasPago: null,
    //   fecha: DateUtils.getCurrentDateAsString(),
    //   concepto: null,
    //   proveedorVarios: optionsAbono[1]
    // }))
    setFechaCal(new Date())
    setConcepto(null)
    setFormaPago(null)
    setRadioGroup(optionsAbono[1])
  }, [])

  const validatePayments = React.useCallback(() => {
    const errors: Array<string> = []
    let errorDate: boolean = false
    let errorPayment: boolean = false
    const dataPayments: any =
      abono.proveedorVarios.value === 1
        ? [...abono.dpPagosE]
        : [...abono.dpPagos]
    const currentDate = DateUtils.format(fechaCal, fechaFormato, fechaInput)
    for (const payment of dataPayments) {
      const dateError = Number(restaFechas(payment.fechaVenta, currentDate))
      if (dateError < 0) errorDate = true
      if (!(payment.abono > 0 && payment.abono <= payment.saldo))
        errorPayment = true
    }
    if (paymentsMethods === -1)
      errors.push('Forma de pago: debe elegir una forma de pago.')
    else if (paymentsMethods === 8)
      errors.push('Forma de pago: no puede realizar el cobro con Crédito')
    else if (paymentsMethods === EFormasPago.otros) {
      let errorsPaymensMethods: boolean = false
      let valuePayment: number = Number(abono.totalCuotas.toFixed(2))
      othersPayMethod.map((payment) => {
        valuePayment = Number((valuePayment - payment.fpoValor).toFixed(2))
        if (payment.subCodigo === 8) {
          errors.push('Forma de pago: no puede realizar el cobro con Crédito')
          errorsPaymensMethods = true
        }
      })
      if (valuePayment > 0 || othersPayMethod.length <= 1) {
        errors.push(
          'Forma de pago: establezca los valores de las distintas formas de pago',
        )
        errorsPaymensMethods = true
      }
      if (errorsPaymensMethods) setShowOthersMethod(true)
    }
    if (abono.dpPagosE.length === 0 || errorPayment)
      errors.push(
        'Abonos: existen errores en la columna abonos por favor revíselos.',
      )
    if (concepto === null || concepto === undefined || concepto === "") {
      errors.push(
        'Campo concepto: el campo concepto esta vacio, reviselo por favor.',
      )
    }
    if (errorDate)
      errors.push(
        'Comprobantes ventas: existen comprobantes con fechas mayores a la fecha del abono.',
      )
    if (abono.local === undefined || abono.local === null || abono.local?.codigo === -1) errors.push('Centro de Costos: elija una opción.')
    return errors
  }, [abono, othersPayMethod, paymentsMethods, concepto, fechaCal])

  const onFindPay = React.useCallback(async () => {
    if (abono.proveedor !== null && abono.proveedor !== undefined) {
      try {
        setDpFacturas([])
        // const toAny: any = fetchAbonar(13763)
        const toAny: any = fetchAbonar(abono.proveedor.codigo)
        const res = await dispatch(toAny)
        if (res !== undefined && res.payload['error'] === false) {
          setToast(res.payload['message'], ToastTypes.Success)
        } else {
          setDpFacturas([])
          setToast(res.payload['message'], ToastTypes.Danger)
        }
      } catch (error) {
        setToast(error.message, ToastTypes.Danger)
      }
    } else {
      setToast(lh.getMessage(MessagesKeys.GlobalProveedorSelect), ToastTypes.Warning)
    }
  }, [dispatch, abono.proveedor, setToast])


  const onGenerateAE = React.useCallback(async (incomeCode, payments) => {
    const accountingEntry: AccountingEntry = {
      infoRegistro: {
        movimiento: payProveedor,
      },
      fecha: DateUtils.dateToString(fechaCal, fechaFormato),
      descripcion: `Abono registrado por: ${incomeCode}`,
      numeroDocumento: incomeCode,
      diarioNumero: periodoActual.length > 0 ? periodoActual[0]?.diario : 25, //GET THE NUMBER OF ACCOUNT PERIOD
      localCodigo: abono.local !== null ? abono.local.codigo : -1,
      formaPago: payments,
    }

    try {
      setShowLoader(true)
      const toAny: any = generateAcoountEntry(accountingEntry)
      const res = await dispatch(toAny)
      setShowLoader(false)
      if (res !== null && res.payload['error'] !== true) {
        setEgresoCodigo(incomeCode)
        setToast(res.payload['message'], ToastTypes.Success)
        dispatch(cleanData())
        setDpFacturas([])
        setDpPagos([])
        setDpPagosEmp([])
        dispatch(setResetSeleccion())
        await onFindPay()
        onGetDataPrint(incomeCode, 'Imprimir')
      } else {
        setToast(res.payload['message'], ToastTypes.Danger)
      }
    } catch (error) {
      setShowLoader(false)
      setToast(error.message, ToastTypes.Danger)
    }
  }, [abono, fechaCal, setToast, dispatch, periodoActual, onFindPay, onGetDataPrint])


  const onEnterChecksCredtiCardAdvances = React.useCallback(
    (paymentsMethods) => {
      let openModalChekcs = false
      let openModalCreditCards = false
      let openModalAdvances = false
      paymentsMethods.map((payment) => {
        if (
          payment.subCodigo === 7 ||
          payment.subCodigo === 111 ||
          payment.subCodigo === 114 ||
          payment.subCodigo === 9
        )
          openModalChekcs = true
        else if (payment.subCodigo === 22) openModalCreditCards = true
        else if (payment.subCodigo === 30) openModalAdvances = true
      })
      if (openModalChekcs || openModalCreditCards || openModalAdvances)
        if (openModalChekcs) setShowBankChecks(true)
        else if (openModalCreditCards) setShowCreditCard(true)
        else if (openModalAdvances) setShowAdvances(true)
    },
    [],
  )

  const onGenerateAccountingEntry = React.useCallback(async (paymentsMethods, incomeCode) => {
    let generate = false
    const payments: Array<{ codigo: number; valor: number }> = []
    paymentsMethods.map((payment) => {
      payments.push({
        codigo: payment.subCodigo,
        valor: payment.fpoValor,
      })
      if (
        payment.subCodigo === 7 ||
        payment.subCodigo === 9 ||
        payment.subCodigo === 22 ||
        payment.subCodigo === 30 ||
        payment.subCodigo === 114
      )
        generate = true
    })
    if (!generate) {
      onGenerateAE(incomeCode, payments)
    }
  }, [onGenerateAE])

  const onSave = React.useCallback(async () => {
    const errors = validatePayments()
    if (errors.length > 0) {
      setArrayErrors(errors)
      setShowErrorPopup(true)
      return false
    } else {
      const paymentsToSave: Array<PaymentSaveProvider> = []
      const dataPayments: any =
        abono.proveedorVarios.value === 1
          ? [...abono.dpPagosE]
          : [...abono.dpPagos]
      dataPayments.map((payment) => {
        const pay: PaymentSaveProvider = {
          abcCodigo: 0,
          pcoCodigo: payment.codigo,
          abcValor: payment.abono,
          abcFecha: DateUtils.dateToString(fechaCal, fechaFormato),
          prvCodigo: abono.proveedor.codigo,
          abcTipoPago: paymentsMethods,
          abcOrigen,
        }
        paymentsToSave.push(pay)
      })
      let paymentsMethodsToSave: Array<PaySaveProvider> = []
      if (paymentsMethods !== EFormasPago.otros) {
        paymentsMethodsToSave = [
          {
            subCodigo: paymentsMethods,
            tipCodigo: 0, // TIP_CODIGO -> THERE ISN'T THIS FIELD IN API RESPONSE
            fpoValor: abono.totalCuotas,
          },
        ]
      } else if (paymentsMethods === EFormasPago.otros) {
        othersPayMethod.map((payment) => {
          paymentsMethodsToSave.push(payment)
        })
      }
      try {
        const paymentData: SavePaymentProvider = {
          infoRegistro: {
            egresoCodigo: 0,
            concepto: concepto,
            fecha: DateUtils.dateToString(fechaCal, fechaFormato),
            aceptado: abono?.proveedor ? abono.proveedor?.nombre : '',
            valor: abono.totalCuotas,
            localCodigo: abono?.local ? abono.local?.codigo : -1,
            ptoCodigo: abono?.puntoVenta ? abono.puntoVenta?.codigo : -1,
            prvCodigo: abono?.proveedor ? abono.proveedor?.codigo : 0,
            abonos: paymentsToSave,
            pagos: paymentsMethodsToSave,
          },
        }
        setShowLoader(true)
        const data = await AbonarXPagar.SavePayment(paymentData)
        setShowLoader(false)
        if (data !== undefined && data !== null && data['error'] === false) {
          if (!isNaN(data['auto'])) {
            dispatch(
              setDatosAbonos({
                ...abono,
                incomeCode: data['auto'],
              }),
            )
            onEnterChecksCredtiCardAdvances(paymentsMethodsToSave)
            await onGenerateAccountingEntry(paymentsMethodsToSave, data['auto'])
            setToast(data['message'], ToastTypes.Success)
            onBroom()
          } else {
            setToast(`El resultado: ${data['auto']} no es un número`, ToastTypes.Warning)
          }
        } else {
          setToast(data['message'], ToastTypes.Danger)
        }
      } catch (error) {
        setShowLoader(false)
        setToast(error.message, ToastTypes.Danger)
      }
    }
  }, [fechaCal, concepto, validatePayments, abono, othersPayMethod, paymentsMethods, setToast, onEnterChecksCredtiCardAdvances, onGenerateAccountingEntry, dispatch, onBroom])



  const newPay = React.useCallback(() => {
    dispatch(setInitData())
    setPaymentsMethods(-1)
    setOthersPayMethod([])
    setFechaCal(new Date())
    setFormaPago(null)
    setConcepto(null)
    setRadioGroup(optionsAbono[1])
    setEgresoCodigo(0)
    setTotalPendientes(null)
    setValorAbonos(null)
    setValorPagos(null)
    setTotalCuotas(null)
    setDpFacturas([])
    setDpPagos([])
    setDpPagosEmp([])
  }, [dispatch])



  const handleButtonClick = React.useCallback((button: ButtonTypes) => {
    switch (button) {
      case ButtonTypes.new:
        newPay()
        break
      case ButtonTypes.find:
        onFindPay()
        break
      case ButtonTypes.save:
        onSave()
        break
      case ButtonTypes.broom:
        newPay()
        break
      case ButtonTypes.digitize:
        if (egresoCodigo !== null && egresoCodigo !== undefined && egresoCodigo !== 0) {
          setShowModalDA(true)
        } else {
          setToast(lh.getMessage(MessagesKeys.NotFoundRegisterSelec), ToastTypes.Warning)
        }
        break
      case ButtonTypes.print:
        if (egresoCodigo !== null && egresoCodigo !== undefined && egresoCodigo !== 0) {
          onGetDataPrint(egresoCodigo, 'Imprimir')
        } else {
          setToast(lh.getMessage(MessagesKeys.NotFoundRegisterSelec), ToastTypes.Warning)
        }
        break
      case ButtonTypes.methodsPay:
        if (egresoCodigo !== null && egresoCodigo !== undefined && egresoCodigo !== 0) {
          if (paymentsMethods !== 1 && paymentsMethods !== undefined && (paymentsMethods === 8 || paymentsMethods === 30)) {
            setShowOthersMethod(true)
          } else {
            setToast("Seleccione Forma de Pago: Otros", ToastTypes.Warning)
          }
        } else {
          setToast(lh.getMessage(MessagesKeys.NotFoundRegisterSelec), ToastTypes.Warning)
        }
        break
      case ButtonTypes.print_design:
        if (egresoCodigo !== null && egresoCodigo !== undefined && egresoCodigo !== 0) {
          onGetDataPrint(egresoCodigo, 'Diseñar')
        } else {
          setToast(lh.getMessage(MessagesKeys.NotFoundRegisterSelec), ToastTypes.Warning)
        }
        break
      case ButtonTypes.import:
        setShowImportButton(true)
        break
      case ButtonTypes.verAsiento:
        if (!isNaN(egresoCodigo) && egresoCodigo !== 0 && egresoCodigo !== null && egresoCodigo !== undefined) {
          setShowModalsiento(true)
        } else {
          setToast(lh.getMessage(MessagesKeys.GlobalVerAsientoCreate), ToastTypes.Warning)
        }
        break
      default:
        break
    }
    dispatch(clearButtonClick(tabId))
  }, [dispatch, tabId, onFindPay, onSave, newPay, egresoCodigo, setToast, onGetDataPrint, paymentsMethods])

  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
  }, [handleButtonClick, tab.globalButtonClick])

  React.useEffect(() => {
    if (abono.proveedor !== null && abono.proveedor !== undefined && abono.proveedor?.codigo) {
      onFindPay()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [abono.proveedor])

  React.useEffect(() => {
    if (abono.formasPago) setPaymentsMethods(abono.formasPago.codigo)
  }, [abono.formasPago])

  React.useEffect(() => {
    dispatch(setDatosAbonos({
      ...abono,
      proveedor: filterGlobal.proveedor,
      local: filterGlobal.local,
      puntoVenta: filterGlobal.puntoVenta
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterGlobal])

  const onCloseCreditCardsModal = React.useCallback(() => {
    setShowCreditCard(false)
  }, [])

  const calcularTotalLote = React.useCallback(() => {
    console.log('XXXXX')
  }, [])

  const setLote_lb = React.useCallback((data) => {
    console.log('CC', data)
  }, [])

  const getResultadosFactura = React.useCallback(() => {
    const precision = empresa?.precision ?? 0
    let subtotal = 0
    let newArray = []
    if (resultadosFactura !== undefined && resultadosFactura !== undefined && resultadosFactura['error'] === false) {
      newArray = resultadosFactura['auto'].map((item) => {
        return item
      })
      newArray.map((item) => {
        subtotal += item.saldo
      })
      setTotalPendientes(parseInt(subtotal.toFixed(precision)))
      setDpFacturas(newArray)
    }
  }, [resultadosFactura, empresa])

  React.useEffect(() => {
    getResultadosFactura()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultadosFactura])

  const onSelectedFactChanged = React.useCallback(async ({ selectedRowsData }) => {
    if (selectedRowsData !== null && selectedRowsData !== undefined && selectedRowsData.length > 0) {
      try {
        setDpPagos([])
        const codigo = selectedRowsData[0]?.codigo ?? 0
        const toAny: any = fetchAbonarCuotasCompra(codigo)

        const res = await dispatch(toAny)
        if (res !== undefined && res !== null && res.payload.length > 0) {
          let newArray = []
          newArray = res.payload.map((item) => {
            if (item.saldo > 0) {
              return item
            }
          })
          setDpPagos(newArray)
          setRadioGroup(optionsAbono[1])
          dispatch(
            setDatosAbonos({
              ...abono,
              dpPagos: newArray,
              facturaActual: selectedRowsData[0],
              proveedorVarios: optionsAbono[1]
            }),
          )
          setToast(`${res.payload.length} registro(s) encontrados`, ToastTypes.Success)
        } else {
          setDpPagos([])
          setToast(`Hubo un error: ${res}`, ToastTypes.Danger)
        }
      } catch (error) {
        setToast(error.message, ToastTypes.Danger)
      }
    }
  }, [dispatch, setToast, abono])

  const onCalcularAbonosSelec = React.useCallback(() => {
    if (abono.totalCuotas < abono.valorPagos)
      setToast(
        lh.getMessage(MessagesKeys.GlobalAbonosInsert),
        ToastTypes.Info,
      )
    else {
      let cantidadAbonar = abono.valorPagos
      const cuotas = JSON.parse(JSON.stringify(abono.dpPagosE))
      for (const cuota of cuotas) {
        let valorAbono = 0
        if (cantidadAbonar > cuota.abono) {
          valorAbono = cuota.abono
          cantidadAbonar -= cuota.abono
        } else if (cantidadAbonar > 0) {
          valorAbono = cantidadAbonar
          cantidadAbonar = 0
        }
        cuota.abono = valorAbono.toFixed(2)
      }
      dispatch(
        setDatosAbonos({
          ...abono,
          dpPagosE: cuotas,
          totalCuotas: abono.valorPagos,
        }),
      )
    }
  }, [abono, dispatch, setToast])

  const onCargarAbonosSelec = React.useCallback(() => {
    if (abono.valorPagos > 0) onCalcularAbonosSelec()
    else
      setToast(
        lh.getMessage(MessagesKeys.GlobalAbonosLoad),
        ToastTypes.Info,
      )
  }, [abono.valorPagos, onCalcularAbonosSelec, setToast])

  const onSelectedCuotaChanged = React.useCallback(
    ({ currentSelectedRowKeys }) => {
      if (currentSelectedRowKeys) {
        dispatch(
          setDatosAbonos({
            ...abono,
            cuotaActual: currentSelectedRowKeys[0],
          }),
        )
      }
    },
    [abono, dispatch],
  )

  const onSelectedCuotaPagarChanged = React.useCallback(
    ({ currentSelectedRowKeys }) => {
      if (currentSelectedRowKeys) {
        dispatch(
          setDatosAbonos({
            ...abono,
            cuotaPagarActual: currentSelectedRowKeys[0],
          }),
        )
      }
    },
    [abono, dispatch],
  )

  const setCellAbono = React.useCallback((rowData, value, currentRowData) => {
    let newCuotasFactura: Array<any> = []
    if (currentRowData !== null && currentRowData !== undefined && value) {
      newCuotasFactura = dpPagos.map(item => {
        if (item === undefined || item === null) return
        if (item.numero === currentRowData.numero) {
          if (!isNaN(value)) {
            currentRowData.abono = parseInt(value);
            return currentRowData
          } else {
            return item
            setToast(`El valor digitad: ${value} no es un número, introduzaca un numero`, ToastTypes.Warning)
          }
        } else {
          return item
        }
      })
      setDpPagos(newCuotasFactura)
    }
  }, [dpPagos, setToast]);

  const onSumarCuotas = React.useCallback(
    (cuotasPagar: Array<CoutasPagar>): number => {
      return cuotasPagar.reduce((totalCuotas, cuota) => {
        return totalCuotas + cuota.abono
      }, 0)
    },
    [],
  )

  const addCuotaPagar = React.useCallback(() => {
    const selectedIndex = dpPagosEmp.findIndex((cuota: CoutasPagar) => {
      return cuota.codigo === abono.cuotaActual.codigo
    })
    if (selectedIndex === -1) {
      const abonosPagar = JSON.parse(JSON.stringify(dpPagosEmp))
      const cuotaPagar: any = {
        codigo: abono.cuotaActual.codigo,
        cuota: abono.cuotaActual.cuota,
        saldo: abono.cuotaActual.saldo,
        abono: abono.cuotaActual.abono !== 0 && abono.cuotaActual.abono <= abono.cuotaActual.saldo ? abono.cuotaActual.abono : abono.cuotaActual.saldo,
        numero: abono.facturaActual.numero,
        fecha: abono.cuotaActual.vencimiento,
        fpcValor: abono.cuotaActual.fpcValor,
      }
      abonosPagar.push(cuotaPagar)
      setDpPagosEmp(abonosPagar)
      setTotalCuotas(onSumarCuotas(abonosPagar))
      dispatch(
        setDatosAbonos({
          ...abono,
          dpPagosE: abonosPagar,
          totalCuotas: onSumarCuotas(abonosPagar),
        }),
      )
    } else setToast(lh.getMessage(MessagesKeys.GlobalAbonosAddCuota), ToastTypes.Info)
  }, [abono, dispatch, onSumarCuotas, setToast, dpPagosEmp])

  const onAddCuota = React.useCallback(() => {
    if (abono.cuotaActual) addCuotaPagar()
    else
      setToast(
        lh.getMessage(MessagesKeys.GlobalAbonosChose),
        ToastTypes.Warning,
      )
  }, [abono.cuotaActual, addCuotaPagar, setToast])

  const onRemoveCuota = React.useCallback(() => {
    if (abono.cuotaPagarActual) {
      const cuotasPagar = JSON.parse(JSON.stringify(dpPagosEmp))
      const deletedCuota = cuotasPagar.filter(
        (cuota) => cuota.codigo !== abono.cuotaPagarActual.codigo,
      )
      setDpPagosEmp(deletedCuota)
      setTotalCuotas(onSumarCuotas(deletedCuota))
      dispatch(
        setDatosAbonos({
          ...abono,
          dpPagosE: deletedCuota,
          totalCuotas: onSumarCuotas(deletedCuota),
        }),
      )
    } else setToast(lh.getMessage(MessagesKeys.GlobalAbonosCuotaRemove), ToastTypes.Warning)
  }, [abono, dispatch, onSumarCuotas, setToast, dpPagosEmp])

  const setCellAbonoEmp = React.useCallback((rowData, value, currentRowData) => {
    let newCuotasPagar: Array<any> = []
    if (currentRowData !== null && currentRowData !== undefined && value) {
      newCuotasPagar = abono.dpPagosE.map((cuota) => {
        if (currentRowData.codigo === cuota.codigo) {
          currentRowData.abono = value;
          return currentRowData
        }
        return cuota
      })
      setDpPagosEmp(newCuotasPagar)
      setTotalCuotas(onSumarCuotas(newCuotasPagar))
      dispatch(setDatosAbonos({
        ...abono,
        dpPagosE: newCuotasPagar,
        totalCuotas: onSumarCuotas(newCuotasPagar)
      }))
    }
  }, [abono, dispatch, onSumarCuotas]);

  const onVerDetalleVenta = React.useCallback(() => {
    if (abono.facturaActual) setShowDetallesVenta(true)
    else
      setToast(lh.getMessage(MessagesKeys.GlobalFacturaSeeDetail), ToastTypes.Info)
  }, [abono.facturaActual, setToast])


  React.useEffect(() => {
    if (abono.status === FETCH_STATUS.FAILED) {
      dispatch(
        addToast({
          title: 'Abonar',
          content: abono.mensaje ?? lh.getMessage(MessagesKeys.GlobalButtonSearchError),
          type: ToastTypes.Danger,
        }),
      )
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'ABONAR',
          buttonType: undefined,
        }),
      )
    }
    if (abono.status === FETCH_STATUS.LOADING) {
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'ABONAR',
          buttonType: ButtonTypes.find,
        }),
      )
    }
    if (abono.status === FETCH_STATUS.SUCCESS) {
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'ABONAR',
          buttonType: undefined,
        }),
      )
    }
  }, [dispatch, abono.status, abono.mensaje])

  const onCalcularAbonos = React.useCallback(() => {
    if (totalPendientes < abono.valorAbonos)
      setToast(
        lh.getMessage(MessagesKeys.GlobalAbonosInsert),
        ToastTypes.Info,
      )
    else {
      let cantidadAbonar = abono.valorAbonos
      const cuotas = JSON.parse(JSON.stringify(abono.dpPagos))
      for (const cuota of cuotas) {
        let valorAbono = 0
        if (cantidadAbonar > cuota.saldo) {
          valorAbono = cuota.saldo
          cantidadAbonar -= cuota.saldo
        } else if (cantidadAbonar > 0) {
          valorAbono = cantidadAbonar
          cantidadAbonar = 0
        }
        cuota.letra = ''
        cuota.abono = valorAbono.toFixed(2)
      }
      dispatch(
        setDatosAbonos({
          ...abono,
          dpPagos: cuotas,
        }),
      )
    }
  }, [abono, dispatch, setToast, totalPendientes])


  const onCargarAbonos = React.useCallback(() => {
    if (abono.valorAbonos > 0) onCalcularAbonos()
    else
      setToast(
        lh.getMessage(MessagesKeys.GlobalAbonosLoad),
        ToastTypes.Info,
      )
  }, [onCalcularAbonos, setToast, abono.valorAbonos])


  const getParseData = React.useCallback((data: any = {}) => {
    const { asientos, compra, egreso, abonos } = data
    const registros: Array<EgresosCajaDto> = []

    let abonosArray: Array<Abonar> = []
    let asientosArray: Array<Asientos> = []
    let chequesArray: Array<Cheques> = []
    let egresoObj: Egreso
    let compraObj: Compra
    if (egreso !== null && egreso !== undefined) {

      egresoObj = {
        codigo: parseInt(egreso?.egresoCodigo) ?? 0,
        numero: egreso?.egresoNumero ?? "",
        tipo: egreso?.egresoTipo ?? "",
        asociado: parseInt(egreso?.egresoAsociado) ?? 0,
        aceptado: "",
        valor: parseInt(egreso.egresoValor) ?? null,
        fecha: egreso?.egresoFecha ?? "",
        concepto: egreso.egresoConcepto ?? "",
        estadoCodigo: parseInt(egreso?.egresoEstado) ?? 0,
        estadoDescripcion: egreso?.egresoEstadoDes ?? "",
        asientoNumeroTipo: egreso?.asientoNroTipo ?? "",
        proveedorCodigo: parseInt(egreso?.egresoProveedorCodigo) ?? 0,
        localNombre: egreso?.egresoLocalNombre ?? "",
        localCodigo: parseInt(egreso?.egresoLocalCodigo) ?? 0,
        asientoNumero: egreso?.asientoNumero ?? "",
        puntoVentaCodigo: egreso?.egresoPuntoCodigo ?? "",
      }
    }

    if (compra !== null && compra !== undefined) {
      compraObj = {
        establecimiento: compra?.compraEstablecimiento ?? "",
        nombreUsuario: compra?.nombreUsuario ?? "",
        proveedorCodigo: compra?.proveedorCodigo ?? "",
        proveedorNombre: compra?.proveedorNombre ?? "",
        proveedorIdentificacion: compra?.proveedorIdentificacion ?? "",
      }
    }

    if (asientos !== null && asientos !== undefined && asientos.length > 0) {
      asientosArray = data.asientos.map((asiento: any) => {
        const asientoObj: Asientos = {
          asiNmero: 0,
          planCtaCodigo: 0,
          planCtaNumero: asiento?.planNumero ?? "",
          planCtaDescripcion: asiento?.planDescripcion ?? "",
          planCtaDescripcionIdioma: "",
          localCodigo: 0,
          localNombre: "",
          transaccionReferencia: asiento?.transaccionReferencia ?? "",
          transaccionDebe: asiento?.transaccionDebe !== "" ? parseInt(asiento?.transaccionDebe) : 0,
          transaccionHaber: asiento?.transaccionHaber !== "" ? parseInt(asiento?.transaccionHaber) : 0,
          siglasLocal: "",
        }

        return asientoObj
      })
    }

    if (abonos !== null && abonos !== undefined && abonos.length > 0) {
      abonosArray = abonos.map((abono: any) => {
        const abonoObj: Abonar = {
          cuota: abono?.CUOTA ?? "",
          abono: abono?.ABONO !== "" ? parseInt(abono?.ABONO) : 0,
          comprobante: abono?.COMPROBANTE ?? "",
          numero: abono?.NUMERO ?? "",
          proveedorNombre: compraObj.proveedorNombre,
        }

        return abonoObj
      })
    }

    if (data.cheques !== null && data.cheques !== undefined && data.cheques.length > 0) {
      chequesArray = data.cheques.map((cheque: any) => {
        const chequeObj: Cheques = {
          codigo: parseInt(cheque?.codigo) ?? 0,
          numero: cheque?.Numero,
          fechaRegistro: cheque?.registro ?? "",
          fechaCobro: cheque?.fcobro ?? "",
          cuentaBancoCodigo: parseInt(cheque?.cbaCodigo) ?? 0,
          cuentaBancoNombre: cheque?.banNombre ?? "",
          cuentaBancoNumero: cheque?.cbaNumero ?? "",
          bancoNombre: cheque?.banNombre ?? "",
          perteneceCod: "",
          perteneceDescripcion: cheque?.cbaPertenece ?? "",
          chequePago: cheque?.formaPago ?? "",
          chequeAfavor: cheque?.aFavor ?? "",
          chequeAValor: cheque?.valor ?? "",
          xomprobante: cheque?.comprobante ?? "",
          formaPagoValor: cheque?.fpaValor ?? "",
          chequePagos: cheque?.pagos ?? "",
          chequeValor: parseInt(cheque?.valor) ?? 0,
          fpsCodigo: cheque?.fpsCodigo ?? "",
          chequeEstadoCodigo: cheque?.estado ?? "",
          chequeEstadoDescripcion: cheque?.estadoDes ?? "",
          chequeTransaccion: cheque?.transaccion ?? "",
          chequeDescripcion: cheque?.descripcion ?? "",
        }

        return chequeObj
      })
    }

    const egresoCaja: EgresosCajaDto = {
      EgresoTipo: EgresoTipo.abono,
      egreso: egresoObj,
      compra: compraObj,
      abonos: abonosArray,
      cheques: chequesArray.length > 0 ? chequesArray : null,
      asientos: asientosArray,
      formaPago: data?.formaPago ?? "",
      usuario: compraObj?.nombreUsuario ?? "",
      fecha: egresoObj?.fecha ?? "",
      elaborado: data?.elaborado ?? "",
      modificado: data?.modificado ?? "",
      concepto: egresoObj?.concepto ?? "",
      descripcion: data?.asientoDescripcion ?? "",
      totalDebe: parseInt(data?.asientoTotalDebe) ?? 0,
      totalHaber: parseInt(data?.asientoTotalHaber) ?? 0,
    }
    registros.push(egresoCaja)
    return registros
  }, [])


  const openModalDigitalizarArchivos = React.useCallback(() => {
    return (
      <ModalArchivosDigitales
        onChanged={(data) => { }}
        onCancel={() => setShowModalDA(false)}
        show={showModalDA}
        modulo={tabId}
        codModulo={codigoModulo}
        chequeNumero={egresoCodigo ?? 0}
      />
    )
  }, [tabId, showModalDA, codigoModulo, egresoCodigo])

  return (
    <>
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={position}
        visible={abono.status === FETCH_STATUS.LOADING || showLoader}
        showIndicator={true}
        shading={true}
        showPane={true}
        closeOnOutsideClick={false}
      />
      <ReportWithLocalDataModal
        show={reporte !== null}
        onClose={() => setReporte(null)}
        data={getParseData(resultadoImprimir)}
        fileName='StaticEgresosCaja'
        mode={reporte ?? 'Viewer'}
        parameters={{ 'Reporte_Filtro': getFilterString(abono), Modulo: "Proveedores" }}
        template='StaticEgresosCaja'
        key='reportDesigner'
      />
      {showModalDA && openModalDigitalizarArchivos()}
      {showDetallesVenta && (
        <ModalDetallesVenta
          show={showDetallesVenta}
          codigoVenta={abono.facturaActual.codigo}
          codigoModulo={codigoModulo}
          onClose={() => setShowDetallesVenta(false)}
        />
      )}
      {showOthersPayMethod && (
        <VariasFormasPago
          numeroVenta={0}
          tiposPagos={[]}
          fechaFactura={abono.fecha}
          totalCobrar={abono.totalCuotas}
          onCancel={() => { }} //setShowOthersMethod(false)}
          onOk={() => { }} //onSetValuePaymentsMethods}
        />
      )}
      {showBankChecks && (
        <ModalIngresoCheques
          show={showBankChecks}
          configModal={{
            open: showBankChecks,
            name: 'Ingresar Cheque Abono Proveedor',
            parametros: {
              incomeCode: abono.incomeCode, // abono.entryCode
              incomeDate: DateUtils.format(abono.fecha, fechaFormato, fechaInput),
            },
            codModulo: codigoModulo,
            tab: 'ABONAR',
            tipoFP: abono.formasPago.codigo,
          }}
          onClose={() => {
            setShowBankChecks(false)
            const payments: Array<{ codigo: number; valor: number }> = []
            payments.push({
              codigo: abono.formasPago.codigo ?? 0,
              valor: abono.totalCuotas ?? 0
            })
            onGenerateAE(abono.incomeCode, payments)
          }}
          parametros={(evt) => console.log(evt)}
          pathVerificador={''}
          ejecutaAccion={() => console.log('ejecutaAccion')}
          onSelect={(evt) => console.log(evt)}
        />
      )}
      {
        showModalsiento && (

          <ModalAsiento
            show={showModalsiento}
            onClose={() => setShowModalsiento(false)}
            infoAsiento={egresoCodigo}
            origenModulo={Number(codigoModulo)}
          />

        )
      }
      {showCreditCard && (
        <ModalTarjetas
          show={showCreditCard}
          onClose={onCloseCreditCardsModal}
          parametros={() => { }}
          pathVerificador={''}
          ejecutaAccion={calcularTotalLote}
          setTotales={(data) => setLote_lb(data)}
          onSelected={(data) => {
            console.log('tarjetas onSelected', data)
          }}
        />
      )}
      {showAdvances && (
        <ModalAnticipos
          show={showAdvances}
          onClose={() => { }} //setShowAdvances(false)}
          pathVerificador={''}
          modulo={codigoModulo}
        />
      )}
      {
        showErrorPopup && (<VisualizaError
          titulo="Error en Abonar"
          mensaje={'Error al guardar'}
          content={
            <ListErrosValidationForm
              mainMessage={'Revise los siguientes errores:'}
              errorsList={arrayErrors}
              color={'danger'}
            />
          }
          onOk={() => setShowErrorPopup(false)}
        />)
      }
      <CCard id='payrContent'>
        <CCardBody>
          <CabeceraFiltroBusqueda />
          <RowContainer className='mt-1 mb-1'>
            <CustomCol xs="12" md={showImportButton ? "7" : "12"}>
              <RowContainer>
                <CustomCol xs="12" md="3">
                  <Labeled label="Fecha:">
                    <DateBox
                      value={fechaCal}
                      onValueChanged={(data) => onChangeData(data, 'FechaCal')}
                    />
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="3">
                  <Labeled label="Forma de Pago:">
                    <FormasPagoLookUp
                      selected={formaPago}
                      onChanged={(data) => onChangeData(data, 'FormaPago')} //{onChangedFormaPago}
                      transaccion={ETipoTransaccion.ventas}
                    />
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="4" className="d-flex align-items-center">
                  <Labeled label="">
                    <RadioGroup
                      name="factura-cuotas"
                      layout="horizontal"
                      displayExpr="label"
                      items={optionsAbono}
                      value={radioGroup}
                      onValueChanged={(data) => onChangeData(data, 'RadioGroup')}
                    />
                  </Labeled>
                </CustomCol>
              </RowContainer>
              <RowContainer>
                <CustomCol xs="12" md="6">
                  <Labeled label="Concepto:">
                    <TextArea
                      value={concepto}
                      onValueChanged={(data) => onChangeData(data, 'Concepto')}
                    />
                  </Labeled>
                </CustomCol>
              </RowContainer>
            </CustomCol>
            {showImportButton && (
              <CustomCol xs="12" md="4">
                <Importar
                  tab={tab}
                  tabId={tabId}
                  openImport={(open) => setShowImportButton(open)}
                  moduleCode={codigoModulo}
                />
              </CustomCol>
            )}
          </RowContainer>
          <RowContainer>
            <CustomCol xs="12" md="5">
              <CRow>
                <CCol lg="12" className="d-flex justify-content-end">
                  <strong>{dpFacturas.length}</strong>
                </CCol>
              </CRow>
              <DataGrid
                style={{ marginTop: '5px' }}
                selection={{ mode: 'single' }}
                hoverStateEnabled={true}
                height={240}
                dataSource={dpFacturas}
                showBorders={true}
                showRowLines={true}
                onSelectionChanged={onSelectedFactChanged}
                loadPanel={{
                  enabled: true,
                  height: 90,
                  indicatorSrc: '',
                  shading: false,
                  shadingColor: '',
                  showIndicator: true,
                  showPane: true,
                  text: 'Cargando...',
                  width: 200,
                }}
              >
                <Sorting mode="none" />
                <Scrolling
                  mode="virtual" />
                <Column
                  dataField="numero"
                  caption="Comprobante"
                  width="30%"
                />
                <Column dataField="fecha" caption="Fecha" width="20%" />
                <Column dataField="total" caption="Total" width="10%" />
                <Column dataField="fpcValor" caption="Crédito" width="10%" />
                <Column
                  dataField="saldo"
                  caption="Saldo a la fecha"
                  width="15%"
                />
              </DataGrid>
              <RowContainer>
                <CustomCol xs="12" md="4">
                  <CButton
                    id="btnConfirmar"
                    color="secondary"
                    className="m-1"
                    size="sm"
                    disabled={abono.facturaActual === null}
                    onClick={onVerDetalleVenta}
                  >
                    {'Ver Detalle'}
                  </CButton>
                </CustomCol>
                <CustomCol
                  xs="12"
                  md="8"
                  className="d-flex justify-content-end align-items-center"
                >
                  <CRow>
                    <CCol
                      md="6"
                      className="d-flex justify-content-end align-items-center"
                    >
                      <strong> {'Total:'} </strong>
                    </CCol>
                    <CCol md="6">
                      <NumberBox
                        width="100%"
                        value={totalPendientes}
                        placeholder=""
                        format="#,##0.00"
                      />
                    </CCol>
                  </CRow>
                </CustomCol>
              </RowContainer>
              <RowContainer>
                <CustomCol
                  xs="12"
                  md="4"
                  className="d-flex justify-content-end align-items-center"
                >
                  <p> {textoAbonar} </p>
                </CustomCol>

                <CustomCol
                  xs="12"
                  md="3"
                  className="d-flex justify-content-end align-items-center"
                >
                  <NumberBox
                    width="100%"
                    value={valorAbonos}
                    placeholder=""
                  //onValueChanged={onChangedValorAbonar}
                  />
                </CustomCol>
                <CustomCol
                  xs="12"
                  md="5"
                  className="d-flex justify-content-end align-items-center"
                >
                  <CButton
                    id="btnConfirmar"
                    color="secondary"
                    className="m-1"
                    size="sm"
                    onClick={onCargarAbonos}
                  >
                    {'Cargar Abonos'}
                  </CButton>
                </CustomCol>
              </RowContainer>
              <CRow>
                <CCol lg="12" className="d-flex justify-content-end">
                  <strong>{dpPagos.length}</strong>
                </CCol>
              </CRow>
              <DataGrid
                style={{ marginTop: '5px' }}
                selection={{ mode: 'single' }}
                hoverStateEnabled={true}
                dataSource={dpPagos}
                showBorders={true}
                showRowLines={true}
                onSelectionChanged={onSelectedCuotaChanged}
                onRowDblClick={onAddCuota}
                loadPanel={{
                  enabled: true,
                  height: 90,
                  indicatorSrc: '',
                  shading: false,
                  shadingColor: '',
                  showIndicator: true,
                  showPane: true,
                  text: 'Cargando...',
                  width: 200,
                }}
              >
                <Editing
                  mode="cell"
                  allowUpdating
                  changes={changes}
                  onChangesChange={onChangesChange}
                  editRowKey={editRowKey}
                  onEditRowKeyChange={onEditRowKeyChange}
                />
                <Column allowEditing={false} dataField="letra" caption="Letra" width="20%" />
                <Column allowEditing={false} dataField="cuota" caption="Cuota" width="20%" />
                <Column allowEditing={false} dataField="vencimiento" caption="Vencimiento" width="20%" />
                <Column allowEditing={false} dataField="saldo" caption="Saldo" width="20%" />
                <Column
                  dataField="abono"
                  caption="Abono a la fecha"
                  width="20%"
                  allowEditing={true}
                  setCellValue={setCellAbono}
                />
              </DataGrid>
            </CustomCol>
            <CustomCol xs="12" md="7">
              <RowContainer>
                <CustomCol xs="12" md="2">
                  <p> {'Buscar:'} </p>
                </CustomCol>
                <CustomCol xs="12" md="10">
                  <CRow>
                    <CCol
                      xs="12"
                      md="4"
                      className="d-flex justify-content-end align-items-center"
                    >
                      <p> {textoAbonar} </p>
                    </CCol>
                    <CCol
                      xs="12"
                      md="3"
                      className="d-flex justify-content-end align-items-center"
                    >
                      <NumberBox
                        width="100%"
                        value={valorPagos}
                        placeholder=""
                      //onValueChanged={onChangedValoPagar}
                      />
                    </CCol>
                    <CCol
                      xs="12"
                      md="5"
                      className="d-flex justify-content-end align-items-center"
                    >
                      <CButton
                        id="btnConfirmar"
                        color="secondary"
                        className="m-1"
                        size="sm"
                        onClick={onCargarAbonosSelec}
                      >
                        {'Cargar Abonos'}
                      </CButton>
                    </CCol>
                    <CCol
                      xs="12"
                      md="10"
                      className="d-flex justify-content-end align-items-center"
                    ></CCol>
                  </CRow>
                </CustomCol>
              </RowContainer>
              <RowContainer>
                <CRow>
                  <CCol lg="12" className="d-flex justify-content-end">
                    <strong>{dpPagosEmp.length}</strong>
                  </CCol>
                </CRow>
                <DataGrid
                  style={{ marginTop: '5px' }}
                  selection={{ mode: 'single' }}
                  hoverStateEnabled={true}
                  dataSource={dpPagosEmp}
                  showBorders={true}
                  showRowLines={true}
                  onSelectionChanged={onSelectedCuotaPagarChanged}
                  onRowDblClick={onRemoveCuota}
                  loadPanel={{
                    enabled: true,
                    height: 90,
                    indicatorSrc: '',
                    shading: false,
                    shadingColor: '',
                    showIndicator: true,
                    showPane: true,
                    text: 'Cargando...',
                    width: 200,
                  }}
                >
                  <Editing
                    mode="cell"
                    allowUpdating
                    changes={changes}
                    onChangesChange={onChangesChange}
                    editRowKey={editRowKey}
                    onEditRowKeyChange={onEditRowKeyChange}
                  />
                  <Column
                    dataField="numero"
                    allowEditing={false}
                    caption="Comprobante"
                    width="20%"
                  />
                  <Column allowEditing={false} dataField="cuota" caption="Cuota" width="20%" />
                  <Column allowEditing={false} dataField="fecha" caption="Vence" width="20%" />
                  <Column allowEditing={false} dataField="saldo" caption="Saldo" width="20%" />
                  <Column allowEditing={true} setCellValue={setCellAbonoEmp} dataField="abono" caption="Abono" width="20%" />
                </DataGrid>
                <CRow>
                  <CCol md="3" xs="6" style={{ marginTop: '4px' }}>
                    <Button
                      id="btnAdd"
                      className="me-1"
                      icon="add"
                      stylingMode="contained"
                      type="default"
                      onClick={onAddCuota}
                    />
                    <Button
                      id="btnRemove"
                      icon="trash"
                      stylingMode="contained"
                      type="danger"
                      onClick={onRemoveCuota}
                    />
                  </CCol>
                  <CCol md="9" xs="6" style={{ marginTop: '4px' }}>
                    <CRow>
                      <CCol
                        md="8"
                        xs="6"
                        className="d-flex justify-content-end align-items-center"
                      >
                        <strong> {'Total:'} </strong>
                      </CCol>
                      <CCol
                        md="4"
                        xs="6"
                        className="d-flex justify-content-end"
                      >
                        <NumberBox
                          width="100%"
                          value={totalCuotas}
                          placeholder=""
                          format="#,##0.00"
                        />
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
              </RowContainer>
            </CustomCol>
          </RowContainer>
        </CCardBody>
      </CCard>
    </>
  )
}

export default React.memo(Abonos)

export const ModuleButtonsAbonar: CustomButtons = {
  Nuevo: true,
  Guardar: true,
  Buscar: true,
  Deshacer: true,
  Editar: false,
  Imprimir: true,
  Importar: true,
  Exportar: false,
  Ver_asiento: true,
  Cheque: true,
  Digitalizar: true,
  Otros: true,
  Proveedores: true,
  Limpiar: true
}
