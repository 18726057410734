import React from 'react'
import RowContainer from '../../../../../../../../views/componentes/rowContainer/rowContainer'
import Labeled from '../../../../../../../../views/componentes/labeledInput/labeledInput'
import { CCol, CRow } from '@coreui/react-pro'
import { Button, TextBox } from 'devextreme-react'
import ModalCuentas from '../../../../../../../contabilidad/pages/planCuentas/modalCuentas'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../../store/store'
import { setDataLegerAccountToEdit } from '../../../../store/editDataReduce'
import { ItemLedgerAccount } from '../../../../types/types'
import { ModalConceptoRetencion } from '../../../../../../../componentes/modalConceptoRetenecion'
import { ItemsServices } from '../../../../services/items.services'
import { changeLoaderItems } from '../../../../store/generalReducer'
import { ToastTypes } from '../../../../../../../../store/types'
import { useSetToast } from '../../../../../../../../hooks/useGlobalHooks'
import { ButtonTypes } from '../../../../../../../../views/componentes/globalMenu/types'
import { setCurrentExecutingAction } from '../../../../store/tabsReducer'

const CuentasContables = (props) => {
  const { tabId } = props

  const dispatch = useDispatch()
  const setToastMessage = useSetToast()

  const dataCuentasContables = useSelector(
    (state: RootState) =>
      state.inventarios.items.editData[tabId].cuentasContables,
  )
  const codeItem = useSelector(
    (state: RootState) => state.inventarios.items.editData[tabId].codigoInterno,
  )

  const [showModalVenta, setShowModalVenta] = React.useState<boolean>(false)
  const [showModalCompra, setShowModalCompra] = React.useState<boolean>(false)
  const [showModalInventario, setShowModalIventario] =
    React.useState<boolean>(false)
  const [showModalCostoVenta, setShowModalCostoVenta] =
    React.useState<boolean>(false)
  const [showModalRetencion, setShowModalRetencion] =
    React.useState<boolean>(false)
  const [showModalCustodia, setShowModalCustodia] =
    React.useState<boolean>(false)

  const playLoader = React.useCallback(
    (message = 'Cargando...') => {
      dispatch(changeLoaderItems({ show: true, mensaje: message }))
    },
    [dispatch],
  )

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoaderItems({ show: false, mensaje: '' }))
  }, [dispatch])

  const checkShowModal = () => {
    if (
      showModalVenta ||
      showModalCompra ||
      showModalInventario ||
      showModalCostoVenta ||
      showModalCustodia
    )
      return true
    return false
  }

  const closeModal = React.useCallback(() => {
    if (showModalVenta) setShowModalVenta(false)
    else if (showModalCompra) setShowModalCompra(false)
    else if (showModalInventario) setShowModalIventario(false)
    else if (showModalCostoVenta) setShowModalCostoVenta(false)
    else if (showModalCustodia) setShowModalCustodia(false)
  }, [
    showModalVenta,
    setShowModalVenta,
    showModalCompra,
    setShowModalCompra,
    showModalInventario,
    setShowModalIventario,
    showModalCostoVenta,
    setShowModalCostoVenta,
    showModalCustodia,
    setShowModalCustodia,
  ])

  const setLedgerAccount = React.useCallback(
    (account: any) => {
      if (account) {
        const modalStates = [
          { state: showModalVenta, key: 'venta' },
          { state: showModalCompra, key: 'compra' },
          { state: showModalInventario, key: 'inventario' },
          { state: showModalCostoVenta, key: 'costoVentas' },
          { state: showModalCustodia, key: 'custodia' },
        ]

        const modalState = modalStates.find((modal) => modal.state)
        if (modalState) {
          dispatch(
            setDataLegerAccountToEdit({
              key: tabId,
              ledgerAccount: {
                ...dataCuentasContables,
                [modalState.key]: {
                  codigo: 0,
                  tipo: 'ITEM',
                  planCuentasCodigo: account.codigo ?? 0,
                  planCuentasNumero: account.numero ?? '',
                  planCuentasDescripcion: account.descripcion ?? '',
                  transaccion: modalState.key.toUpperCase(),
                },
              },
            }),
          )
        }
      }
      closeModal()
    },
    [
      closeModal,
      dataCuentasContables,
      dispatch,
      showModalCompra,
      showModalCostoVenta,
      showModalCustodia,
      showModalInventario,
      showModalVenta,
      tabId,
    ],
  )

  const removeLedgerAccount = React.useCallback(
    async (account: string) => {
      if (codeItem > 0 && account !== 'retencion') {
        let codigoAsg: number = 0
        if (account === 'venta') codigoAsg = dataCuentasContables.venta.codigo
        else if (account === 'compra')
          codigoAsg = dataCuentasContables.compra.codigo
        else if (account === 'inventario')
          codigoAsg = dataCuentasContables.inventario.codigo
        else if (account === 'costoVentas')
          codigoAsg = dataCuentasContables.costoVentas.codigo
        else if (account === 'custodia')
          codigoAsg = dataCuentasContables.custodia.codigo
        if (codigoAsg > 0) {
          try {
            playLoader('Eliminando asignación . . .')
            dispatch(
              setCurrentExecutingAction({
                tabKey: tabId,
                buttonType: ButtonTypes.delete,
              }),
            )
            const deleteAsg = await ItemsServices.deleteAssignment(codigoAsg)
            stopLoader()
            dispatch(
              setCurrentExecutingAction({
                tabKey: tabId,
                buttonType: undefined,
              }),
            )
            if (!deleteAsg.error && deleteAsg.auto)
              setToastMessage(
                'Eliminar Asignación de Cuenta Contable',
                deleteAsg.message ?? 'Asignación eliminada correctamente',
                ToastTypes.Success,
              )
            else {
              setToastMessage(
                'Eliminar Asignación de Cuenta Contable',
                deleteAsg.message ?? 'Error al eliminar asignación',
                ToastTypes.Warning,
              )
              return false
            }
          } catch (error) {
            stopLoader()
            setToastMessage(
              'Eliminar Asignación de Cuenta Contable',
              JSON.stringify(error),
              ToastTypes.Danger,
            )
            dispatch(
              setCurrentExecutingAction({
                tabKey: tabId,
                buttonType: undefined,
              }),
            )
            return false
          }
        }
      }
      const emptyAccount = {
        planCuentasCodigo: 0,
        planCuentasNumero: '',
        planCuentasDescripcion: '',
      }
      dispatch(
        setDataLegerAccountToEdit({
          key: tabId,
          ledgerAccount: {
            ...dataCuentasContables,
            [account]: emptyAccount,
          },
        }),
      )
    },
    [
      codeItem,
      dataCuentasContables,
      dispatch,
      playLoader,
      setToastMessage,
      stopLoader,
      tabId,
    ],
  )

  const addRetentionAccount = React.useCallback(
    (retention: any) => {
      dispatch(
        setDataLegerAccountToEdit({
          key: tabId,
          ledgerAccount: {
            ...dataCuentasContables,
            retencion: {
              planCuentasCodigo: retention.codigo ?? 0,
              planCuentasNumero: retention.numero ?? '',
              planCuentasDescripcion: retention.descripcion ?? '',
            },
          },
        }),
      )
    },
    [dataCuentasContables, dispatch, tabId],
  )

  return (
    <>
      <RowContainer className="m-2">
        {showModalRetencion && (
          <ModalConceptoRetencion
            onChanged={(data) => {
              addRetentionAccount(data)
              setShowModalRetencion(false)
            }}
            onCancel={() => setShowModalRetencion(false)}
            show={showModalRetencion}
            codModulo={1}
          />
        )}
        {checkShowModal() && (
          <ModalCuentas
            show={checkShowModal()}
            onClose={() => closeModal()}
            cargarCuentas={(account) => setLedgerAccount(account)}
          />
        )}
        <RowContainer>
          <Labeled label="Venta">
            <CRow>
              <CCol xs="12" md="6">
                <div
                  style={{
                    display: 'flex',
                    gap: '2px',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <TextBox
                    value={`${dataCuentasContables.venta.planCuentasNumero} - ${dataCuentasContables.venta.planCuentasDescripcion}`}
                    readOnly={true}
                    showClearButton={true}
                    width="100%"
                    inputAttr={{ autocomplete: 'nope' }}
                  />
                  <Button
                    id="btnAdd"
                    className="me-1"
                    icon="add"
                    stylingMode="contained"
                    type="default"
                    onClick={() => setShowModalVenta(true)}
                  />
                  <Button
                    id="btnRemove"
                    icon="trash"
                    stylingMode="contained"
                    type="danger"
                    onClick={() => removeLedgerAccount('venta')}
                  />
                </div>
              </CCol>
            </CRow>
          </Labeled>
        </RowContainer>
        <RowContainer>
          <Labeled label="Compra">
            <CRow>
              <CCol xs="12" md="6">
                <div
                  style={{
                    display: 'flex',
                    gap: '2px',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <TextBox
                    value={`${dataCuentasContables.compra.planCuentasNumero} - ${dataCuentasContables.compra.planCuentasDescripcion}`}
                    readOnly={true}
                    showClearButton={true}
                    width="100%"
                    inputAttr={{ autocomplete: 'nope' }}
                  />
                  <Button
                    id="btnAdd"
                    className="me-1"
                    icon="add"
                    stylingMode="contained"
                    type="default"
                    onClick={() => setShowModalCompra(true)}
                  />
                  <Button
                    id="btnRemove"
                    icon="trash"
                    stylingMode="contained"
                    type="danger"
                    onClick={() => removeLedgerAccount('compra')}
                  />
                </div>
              </CCol>
            </CRow>
          </Labeled>
        </RowContainer>
        <RowContainer>
          <Labeled label="Inventario">
            <CRow>
              <CCol xs="12" md="6">
                <div
                  style={{
                    display: 'flex',
                    gap: '2px',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <TextBox
                    value={`${dataCuentasContables.inventario.planCuentasNumero} - ${dataCuentasContables.inventario.planCuentasDescripcion}`}
                    readOnly={true}
                    showClearButton={true}
                    width="100%"
                    inputAttr={{ autocomplete: 'nope' }}
                  />
                  <Button
                    id="btnAdd"
                    className="me-1"
                    icon="add"
                    stylingMode="contained"
                    type="default"
                    onClick={() => setShowModalIventario(true)}
                  />
                  <Button
                    id="btnRemove"
                    icon="trash"
                    stylingMode="contained"
                    type="danger"
                    onClick={() => removeLedgerAccount('inventario')}
                  />
                </div>
              </CCol>
            </CRow>
          </Labeled>
        </RowContainer>
        <RowContainer>
          <Labeled label="Costo de Ventas">
            <CRow>
              <CCol xs="12" md="6">
                <div
                  style={{
                    display: 'flex',
                    gap: '2px',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <TextBox
                    value={`${dataCuentasContables.costoVentas.planCuentasNumero} - ${dataCuentasContables.costoVentas.planCuentasDescripcion}`}
                    readOnly={true}
                    showClearButton={true}
                    width="100%"
                    inputAttr={{ autocomplete: 'nope' }}
                  />
                  <Button
                    id="btnAdd"
                    className="me-1"
                    icon="add"
                    stylingMode="contained"
                    type="default"
                    onClick={() => setShowModalCostoVenta(true)}
                  />
                  <Button
                    id="btnRemove"
                    icon="trash"
                    stylingMode="contained"
                    type="danger"
                    onClick={() => removeLedgerAccount('costoVentas')}
                  />
                </div>
              </CCol>
            </CRow>
          </Labeled>
        </RowContainer>
        <RowContainer>
          <Labeled label="Retención">
            <CRow>
              <CCol xs="12" md="6">
                <div
                  style={{
                    display: 'flex',
                    gap: '2px',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <TextBox
                    value={
                      dataCuentasContables.retencion.planCuentasDescripcion
                    }
                    readOnly={true}
                    showClearButton={true}
                    width="100%"
                    inputAttr={{ autocomplete: 'nope' }}
                  />
                  <Button
                    id="btnAdd"
                    className="me-1"
                    icon="add"
                    stylingMode="contained"
                    type="default"
                    onClick={() => setShowModalRetencion(true)}
                  />
                  <Button
                    id="btnRemove"
                    icon="trash"
                    stylingMode="contained"
                    type="danger"
                    onClick={() => removeLedgerAccount('retencion')}
                  />
                </div>
              </CCol>
            </CRow>
          </Labeled>
        </RowContainer>
        <RowContainer>
          <Labeled label="Custodia">
            <CRow>
              <CCol xs="12" md="6">
                <div
                  style={{
                    display: 'flex',
                    gap: '2px',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <TextBox
                    value={`${dataCuentasContables.custodia.planCuentasNumero} - ${dataCuentasContables.custodia.planCuentasDescripcion}`}
                    readOnly={true}
                    showClearButton={true}
                    width="100%"
                    inputAttr={{ autocomplete: 'nope' }}
                  />
                  <Button
                    id="btnAdd"
                    className="me-1"
                    icon="add"
                    stylingMode="contained"
                    type="default"
                    onClick={() => setShowModalCustodia(true)}
                  />
                  <Button
                    id="btnRemove"
                    icon="trash"
                    stylingMode="contained"
                    type="danger"
                    onClick={() => removeLedgerAccount('custodia')}
                  />
                </div>
              </CCol>
            </CRow>
          </Labeled>
        </RowContainer>
      </RowContainer>
    </>
  )
}

export default CuentasContables

export const defaultCuentasContables: ItemLedgerAccount = {
  venta: {
    planCuentasCodigo: 0,
    planCuentasNumero: '',
    planCuentasDescripcion: '',
  },
  compra: {
    planCuentasCodigo: 0,
    planCuentasNumero: '',
    planCuentasDescripcion: '',
  },
  inventario: {
    planCuentasCodigo: 0,
    planCuentasNumero: '',
    planCuentasDescripcion: '',
  },
  costoVentas: {
    planCuentasCodigo: 0,
    planCuentasNumero: '',
    planCuentasDescripcion: '',
  },
  retencion: {
    planCuentasCodigo: 0,
    planCuentasNumero: '',
    planCuentasDescripcion: '',
  },
  custodia: {
    planCuentasCodigo: 0,
    planCuentasNumero: '',
    planCuentasDescripcion: '',
  },
}
