import React from 'react'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../views/componentes/colContainer'
import { CBadge, CCardFooter } from '@coreui/react-pro'
import { DataGrid, TextBox } from 'devextreme-react'
import { Column, ColumnChooser, Export, Pager, Paging } from 'devextreme-react/data-grid'
import { getAllowedPageSizes } from '../../../../../../helpers/Helper'
import ItemsCountIndicator from '../../../../../componentes/indicadorNroRegistros'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/store'
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput'

const Transacciones = () => {
  const searchResultsTran = useSelector((state: RootState) => state.inventarios.lotes.search.resultadosTransacciones)
  const selected = useSelector((state: RootState) => state.inventarios.lotes.search.seleccionado)

  const dataGrid = React.useRef<any>()

  return (
    <CCardFooter>
      <RowContainer>
        <div
          className="mt-1 mb-1"
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            width: '100%',
          }}
        >
          <Labeled label='Caducidad:' position='left'>
            <TextBox
              width='100%'
              value={selected?.caducidad ?? null}
              readOnly
            />
          </Labeled>
        </div>
        <ItemsCountIndicator items={searchResultsTran['auto'] ?? []} />
        <DataGrid
          ref={dataGrid}
          keyExpr="codigo"
          focusedRowKey="codigo"
          id="tabCertificados"
          selection={{ mode: 'single' }}
          dataSource={searchResultsTran['auto'] ?? []}
          showColumnLines={true}
          showRowLines={true}
          showBorders={true}
          columnResizingMode="widget"
          focusedRowEnabled={true}
          onSelectionChanged={() => { }}
          loadPanel={{
            enabled: true,
            height: 90,
            indicatorSrc: '',
            shading: false,
            shadingColor: '',
            showIndicator: true,
            showPane: true,
            text: 'Cargando...',
            width: 200,
          }}
        >
          <Paging defaultPageSize={20} />
          <Pager
            visible={(searchResultsTran['auto'] && searchResultsTran['auto'].length > 0) ? true : false}
            allowedPageSizes={getAllowedPageSizes(searchResultsTran['auto'] ?? [])}
            displayMode={'full'}
            showPageSizeSelector={true}
            showInfo={true}
            showNavigationButtons={true}
          />
          <Export enabled={true} allowExportSelectedData={true} />
          <ColumnChooser enabled={true} mode="select" />
          <Column dataField="codigo" caption="Código" width="80px" />
          <Column dataField="movimiento" caption="Movimiento" width="100px" />
          <Column dataField="numero" caption="Número" width="200px" />
          <Column dataField="lote" caption="Lote" width="100px" />
          <Column dataField="caducidad" dataType={'string'} caption="Caducidad" width="150px" />
          <Column dataField="fecha" caption="Fecha" dataType={'string'} width="150px" />
          <Column dataField="local" caption="Local" width="100px" />
          <Column dataField="cantidad" caption="Cantidad" width="100px" />
        </DataGrid>
      </RowContainer>
    </CCardFooter>
  )
}

export default React.memo(Transacciones)
