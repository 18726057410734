import React, { useEffect } from 'react'
import {
  AccionMenu,
  CustomButtons,
  FETCH_STATUS,
  ToastTypes,
} from '../../../../../store/types'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../../../store/store'
import { changeLoaderImports, setCurrentFunction } from './store/generalReducer'
import { addToast } from '../../../../../store/toasterReducer'
import { Aplicacion } from '../../../store/types'
import { utilidades } from '../../../../../helpers/utilidades'
import {
  buttonClick,
  changeCurrentTab,
  closeTabTransferencias,
  openTab,
  openTabResum,
  setButtons,
  setCurrentExecutingAction,
} from './store/tabsReducer'
import { ButtonTypes } from '../../../../../views/componentes/globalMenu/types'
import { v4 as uuidv4 } from 'uuid'
import { deleteEditData, initDatosEdicion } from './store/editDataReducer'
import { changeFilter, fetchImports, setCleanResult, setColapsado, setVerRegistro } from './store/searchReducer'
import Dialog from '../../../../../views/componentes/librerias/bootstrap-dialog'
import {
  useButtonDisabled,
  useMenuOptions,
} from '../../../../../hooks/useMenuButtonControls'
import { EAplicationsAcatha } from '../../../../../store/enumsAplication'
import { setMenuButtons } from './store/menuReducer'
import ImportacionesMain from './components/shared/main'
import { consoleService } from '../../../../../services/console.service'
import { DateUtils } from '../../../../../helpers/dateUtils'
import { TabTypes } from '../../../../ventas/types/enums'
import { setAplicacionModulo } from './store/configReducer'
import { defaultDataImports } from './components/nuevo';
import { initSearch, optionsItems, optionsTransito } from './components/buscar/filtroBusqueda';
import { getTypesMovements } from '../ingresosEgresos/helper/helper'
import { getLocales } from '../../../../componentes/localesLookUp/helper/helper'
import { getIndexProvider } from '../../../../shared/helpers/funciones'
import { ReportWithLocalDataModal } from '../../../../../views/componentes/xtrareports/ReportWithLocalDataModal'
import { FiltrosImportacionesState, ImportacionesDto } from './types/types'

const pathVerificador = '/importaciones'

interface CatalogosGruposProps extends React.PropsWithChildren { }

const Importaciones: React.FunctionComponent<CatalogosGruposProps> = () => {
  const dispatch = useDispatch()
  const menuState = useSelector((state: RootState) => state.global.menu)
  const tabs = useSelector((state: RootState) => {
    return state.inventarios.movimientos.importaciones.tabs
  })
  const periodoActivo = useSelector(
    (state: RootState) => state.shared.providers.periodoContable,
  )
  const localSession = useSelector((state: RootState) => state.global.session.local)

  const searchFilter = useSelector((state: RootState) => {
    return state.inventarios.movimientos.importaciones.search.filter
  })
  const currentTab = useSelector((state: RootState) => {
    return state.inventarios.movimientos.importaciones.search.currentTab
  })
  const searchStatus = useSelector((state: RootState) => {
    return state.inventarios.movimientos.importaciones.search.status
  })
  const searchResults = useSelector((state: RootState) => {
    return state.inventarios.movimientos.importaciones.search.resultados
  })
  const selected = useSelector((state: RootState) => {
    return state.inventarios.movimientos.importaciones.search.seleccionado
  })
  const [reporte, setReporte] = React.useState<null | "Viewer" | "Designer">(null);

  const [confirmDelete, setConfirmDelete] = React.useState<boolean>(false)
  const [confirmEdit, setConfirmEdit] = React.useState<boolean>(false)
  const dialogRef = React.useRef<any>(null)
  const getButtonDisabled = useButtonDisabled(tabs)
  const { getMenuOptions, options } = useMenuOptions()
  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(
      addToast({
        title: 'Inventario-Importaciones',
        content: mensaje,
        type: tipo,
      }),
    )
  }, [dispatch])
  const fechaFormato: string = 'dd/MM/yyyy'
  const fechaFormatoInput: string = 'yyyy-MM-dd'

  const playLoader = React.useCallback(
    (message = 'Cargando...') => {
      dispatch(changeLoaderImports({ show: true, mensaje: message }))
    },
    [dispatch],
  )

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoaderImports({ show: false, mensaje: '' }))
  }, [dispatch])
  const getFilterString = React.useCallback((filter: FiltrosImportacionesState) => {
    const filterText = ''
    return filterText
  }, [])

  const onTabClosing = React.useCallback(
    async (index) => {
      const closedTab = tabs.tabs[index]
      dispatch(closeTabTransferencias(closedTab.tabKey ?? index))
      await utilidades.sleep(50)
      dispatch(deleteEditData(closedTab.tabKey ?? index))
    },
    [dispatch, tabs.tabs],
  )

  const onTabChanged = React.useCallback(
    (tabKey: string) => {
      dispatch(changeCurrentTab(tabKey))
    },
    [dispatch],
  )

  const onFilterMovimientos = React.useCallback((data = []) => {
    return data.filter(item => item.codigo === -1 || item.codigo === 9)
  }, [])

  const onCleanResultFilter = React.useCallback(async () => {
    const data = { ...initSearch }
    let arrayMovimientos: Array<any> = []
    const resMovimientos = await getTypesMovements()
    const resLocal = await getLocales('Elija una opcion')
    if (resMovimientos.length > 0) {
      arrayMovimientos = onFilterMovimientos(resMovimientos)
    }
    data.itemRadioGroup = optionsItems[0]
    data.transito = optionsTransito[0]
    data.fechaDesde = periodoActivo.length > 0 ? DateUtils.format(periodoActivo[0].finicio, fechaFormatoInput, fechaFormato) : DateUtils.getCurrentDateAsString(fechaFormatoInput)
    data.fechaHasta = periodoActivo.length > 0 ? DateUtils.format(periodoActivo[0].fcierre, fechaFormatoInput, fechaFormato) : DateUtils.getCurrentDateAsString(fechaFormatoInput)
    data.movimiento = arrayMovimientos.length > 0 ? arrayMovimientos[1] : null
    data.movimientos = arrayMovimientos
    if (resLocal.length > 0) {
      const provider = resLocal.slice(0)
      const index = await getIndexProvider(provider, 'codigo', localSession?.codigo ?? 0)
      if (index > -1) {
        data.almacen = resLocal[index]
      } else {
        data.almacen = resLocal[0]
      }
    }
    data.esRapida = false
    data.item = null
    data.initial = false

    dispatch(changeFilter({
      ...data
    }))

    dispatch(setCleanResult())
  }, [dispatch, onFilterMovimientos, periodoActivo, localSession])

  const onSearch = React.useCallback(async () => {

    const cod = searchFilter?.codigo ? parseInt(searchFilter?.codigo) : 0

    const desde = searchFilter?.fechaDesde ? DateUtils.format(searchFilter?.fechaDesde, fechaFormato, fechaFormatoInput) : DateUtils.getCurrentDateAsString(fechaFormato)
    const hasta = searchFilter?.fechaHasta ? DateUtils.format(searchFilter?.fechaHasta, fechaFormato, fechaFormatoInput) : DateUtils.getCurrentDateAsString(fechaFormato)


    const search_data: any = {
      codigo: cod,
      documento: searchFilter?.documento ?? '',
      codigoLocal: searchFilter.almacen?.codigo ?? -1,
      prodCliente: searchFilter.proveedor?.codigo ?? 0,
      tipo: 'IMPORTACIONES',
      impreso: searchFilter.itemRadioGroup?.value ?? 0,
      movimiento: searchFilter.movimiento?.codigo ?? -1,
      observaciones: searchFilter?.observaciones ?? '',
      estado: searchFilter.item?.codigoBarras ?? '',
      transito: searchFilter.transito?.value ?? 0,
      fechaDesde: desde,
      fechaHasta: hasta,
      currentTab,
      consolidado: searchFilter?.consolidar ? true : false
    }
    try {
      const toAny: any = fetchImports(search_data)
      const res = await dispatch(toAny)
      consoleService.log(res)
      if (res !== null && res !== undefined && res['payload']?.error === false) {
        if (res['payload']?.auto && res['payload'].auto.length > 5) {
          dispatch(setColapsado(false))
        }
        setToast(res['payload']?.message, ToastTypes.Success)
      } else {
        setToast(res['payload']?.message, ToastTypes.Danger)
      }
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
  }, [searchFilter, currentTab, dispatch, setToast])

  const onConfirmEdit = React.useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea Editar el registro: ${selected?.documento ?? ''}?`,
      actions: [
        Dialog.Action(
          <span>
            <u>A</u>ceptar
          </span>,
          (dialog) => {
            dialog.hide()
            dispatch(setVerRegistro(false))
            dispatch(setCurrentFunction(ButtonTypes.edit))
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setConfirmEdit(false)
    return
  }, [dispatch, selected])

  const onConfirmDelete = React.useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea Eliminar el Registro: ${selected?.documento ?? ''}?`,
      actions: [
        Dialog.Action(
          <span>
            <u>A</u>ceptar
          </span>,
          (dialog) => {
            dialog.hide()
            dispatch(setCurrentFunction(ButtonTypes.disable))
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setConfirmDelete(false)
    return
  }, [dispatch, selected])

  React.useEffect(() => {
    if (confirmEdit) {
      onConfirmEdit()
    }
  }, [confirmEdit, onConfirmEdit])

  React.useEffect(() => {
    if (confirmDelete) {
      onConfirmDelete()
    }
  }, [confirmDelete, onConfirmDelete])

  const onMenuButtonClick = React.useCallback(
    async (action: AccionMenu, tab: string = "") => {
      const id = uuidv4()
      switch (action.actionType) {
        case ButtonTypes.find:
          if (tabs.current === 'BUSQUEDA') {
            onSearch()
          } else if (tabs.current === 'VERABONOS') {
            dispatch(buttonClick({
              tabKey: tab === "" ? tabs.current : tab,
              button: action.actionType
            }))
          } else dispatch(changeCurrentTab('BUSQUEDA'))
          break
        case ButtonTypes.new:
          dispatch(setVerRegistro(false))
          dispatch(
            initDatosEdicion({
              key: id,
              data: {
                ...defaultDataImports,
                loading: true
              },
            }),
          )
          dispatch(openTab({ key: id }))
          break
        case ButtonTypes.save:
          if (tabs.current !== 'BUSQUEDA') {
            dispatch(buttonClick({
              tabKey: tab === "" ? tabs.current : tab,
              button: action.actionType
            }))
          }
          break
        case ButtonTypes.edit:
          if (tabs.current === 'BUSQUEDA') setConfirmEdit(true)
          break
        case ButtonTypes.disable:
          if (tabs.current === 'BUSQUEDA') setConfirmDelete(true)
          break
        case ButtonTypes.broom:
          if (tabs.current === 'BUSQUEDA') onCleanResultFilter()
          else {
            dispatch(buttonClick({
              tabKey: tab === "" ? tabs.current : tab,
              button: action.actionType
            }))
          }
          break
        case ButtonTypes.resumen:
          dispatch(openTabResum({ key: 'VERABONOS', type: TabTypes.verAbonos }))
          break
        case ButtonTypes.movements:
          if (tabs.current !== 'BUSQUEDA') dispatch(changeCurrentTab('BUSQUEDA'))
          break
        case ButtonTypes.verAsiento:
          if (tabs.current !== 'BUSQUEDA') {
            dispatch(buttonClick({
              tabKey: tab === "" ? tabs.current : tab,
              button: action.actionType
            }))
          }
          break
        case ButtonTypes.print:
          if (tabs.current === 'BUSQUEDA') {
            setReporte('Viewer')
          } else {
            dispatch(buttonClick({
              tabKey: tab === "" ? tabs.current : tab,
              button: action.actionType
            }))
          }
          break
        case ButtonTypes.print_design:
          if (tabs.current === 'BUSQUEDA') {
            setReporte('Designer')
          } else {
            dispatch(buttonClick({
              tabKey: tab === "" ? tabs.current : tab,
              button: action.actionType
            }))
          }
          break
        default:
          dispatch(buttonClick({
            tabKey: tab === "" ? tabs.current : tab,
            button: action.actionType
          }))
          break
      }
    },
    [dispatch, onCleanResultFilter, tabs, onSearch],
  )

  const onGetCodeModule = React.useCallback(() => {
    dispatch(setAplicacionModulo(parseInt(EAplicationsAcatha.Importaciones)))
  }, [dispatch])

  useEffect(() => {
    if (menuState.length > 0 && options.length === 0) {
      getMenuOptions(menuState, 'to', pathVerificador)
    }
  }, [menuState, getMenuOptions, options])

  React.useEffect(() => {
    if (searchStatus === FETCH_STATUS.FAILED) {
      stopLoader()
      dispatch(
        addToast({
          title: 'Buscar clientes',
          content: 'Error al realizar la búsqueda',
          type: ToastTypes.Danger,
        }),
      )
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'BUSQUEDA',
          buttonType: undefined,
        }),
      )
    }
    if (searchStatus === FETCH_STATUS.LOADING) {
      playLoader()
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'BUSQUEDA',
          buttonType: ButtonTypes.find,
        }),
      )
    }
    if (searchStatus === FETCH_STATUS.SUCCESS) {
      stopLoader()
      dispatch(
        setButtons({
          tabKey: 'BUSQUEDA',
          buttons: {
            Nuevo: true,
            Guardar: false,
            Editar: selected !== null && selected !== undefined,
            Anular: selected !== null && selected !== undefined,
            Buscar: true,
            Limpiar: true,
            Imprimir: searchResults['auto'] ?? [],
            Resumen: true,
          },
        }),
      )
    }
  }, [dispatch, searchStatus, playLoader, searchResults, stopLoader, selected])

  const onActionsButtonsLoaded = React.useCallback((actions: Array<AccionMenu>) => {
    dispatch(setMenuButtons(actions));
  }, [dispatch]);

  const onSearchExecutingAction = React.useCallback((tabKey: string, type?: ButtonTypes) => {
    dispatch(setCurrentExecutingAction({
      tabKey: tabKey,
      buttonType: type
    }))
  }, [dispatch]);

  React.useEffect(() => {
    onGetCodeModule()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onParseData = React.useCallback((importaciones: Array<any>) => {
    let registros: Array<ImportacionesDto> = [];
    let registro: ImportacionesDto = null

    if (importaciones.length > 0) {
      registros = importaciones.map(item => {
        registro = {
          Code: item?.codigo ?? 0,
          Document: item?.documento ?? '',
          Movement: item?.movimientoDescripcion ?? '',
          Name: item?.productoClienteDescripcion ?? '',
          Store: item?.localCodigoDescripcion ?? '',
          Date: item?.fecha ?? DateUtils.getCurrentDateAsString(),
        }
        return registro
      })
      // registros.push(registro)
    }

    consoleService.log(registros)
    return registros

  }, []);


  return (
    <>
      <ReportWithLocalDataModal
        show={reporte !== null}
        onClose={() => setReporte(null)}
        data={onParseData(searchResults['auto'] ?? [])}
        fileName='StaticIngresosEgresos'
        mode={reporte ?? 'Viewer'}
        parameters={{ 'Reporte_Filtro': getFilterString(searchFilter) }}
        template='StaticIngresosEgresos'
        key='reportDesigner'
      />
      <div>
        <Dialog ref={dialogRef} />
      </div>
      <ImportacionesMain
        menu={{ loading: false, accions: options }}
        tabs={tabs}
        onTabChanged={onTabChanged}
        codigoModulo={parseInt(EAplicationsAcatha.Importaciones)}
        opciones={OpcionesModeulo}
        onActionsButtonsLoaded={onActionsButtonsLoaded}
        onMenuButtonClick={onMenuButtonClick}
        searching={searchStatus === FETCH_STATUS.LOADING}
        ontabClosing={onTabClosing}
        hayResultadosBusqueda={searchResults.length > 0}
        verificarPuntoVenta
        verificarAutorizacion
        onExecutingAction={onSearchExecutingAction}
        tipoAplicacion={Aplicacion.importaciones}
      />
    </>
  )
}

export default React.memo(Importaciones)

export const ModuleButtons: CustomButtons = {
  Nuevo: true,
  Guardar: true,
  Editar: false,
  Eliminar: false,
  Buscar: true,
  Imprimir: false,
  Importar: true,
  Lotes: true,
  Ver_asiento: true,
  Exportar: false,
  Auditoria: false,
  Series: true,
  Resumen: true,
  Limpiar: true,
  Movimientos: true,
}

const OpcionesModeulo = [parseInt(EAplicationsAcatha.Importaciones)];
