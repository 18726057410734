import React from 'react'
import {
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
} from '@coreui/react-pro'
import { ProveedoresListado } from '../../../../types/types'
import MainInfo from '../main'
import { ToastTypes } from '../../../../../../../../store/types'
import Generales from '../generales'
import Compras from '../compras'
import Historicos from '../historicos'
import Adicionales from '../adicionales'
import { TabState } from '../../../../../../../../store/genericTabTypes'

interface ITabsEditionsProps {
  tabId: string
  tab: TabState<ProveedoresListado>
  setToast: (texto: string, type: ToastTypes) => void
  refForm: any
}

export enum SupplierTabs {
  // eslint-disable-next-line no-unused-vars
  generales = 'Datos Generales',
  // eslint-disable-next-line no-unused-vars
  compras = 'Datos de Compras',
  // eslint-disable-next-line no-unused-vars
  historicos = 'Datos Históricos',
  // eslint-disable-next-line no-unused-vars
  adicionales = 'Datos Adicionales',
}

const tabsNames: Array<string> = [
  SupplierTabs.generales,
  SupplierTabs.compras,
  SupplierTabs.historicos,
  SupplierTabs.adicionales,
]

const TabsEdition: React.FunctionComponent<ITabsEditionsProps> = (props) => {
  const { refForm, tabId, setToast } = props

  const [showTab, setShowTab] = React.useState<string>(tabsNames[0])

  return (
    <>
      <MainInfo tabId={tabId} />

      <CNav variant="tabs" role="tablist" className="mt-4">
        <CNav variant="tabs">
          {tabsNames.map((supplierTab, index) => {
            return (
              <CNavItem key={index}>
                <CNavLink
                  onClick={() => {
                    setShowTab(supplierTab)
                  }}
                  active={showTab === supplierTab}
                >
                  {supplierTab}
                </CNavLink>
              </CNavItem>
            )
          })}
        </CNav>
        <CTabContent
          style={{
            overflowY: 'unset',
            width: '100%',
            marginBottom: '10px',
          }}
        >
          <CTabPane
            role="tabpanel"
            aria-labelledby="generales-tab"
            visible={showTab === tabsNames[0]}
          >
            <Generales tabId={tabId} refForm={refForm} />
          </CTabPane>
          <CTabPane
            role="tabpanel"
            aria-labelledby="compras-tab"
            visible={showTab === tabsNames[1]}
          >
            <Compras tabId={tabId} refForm={refForm} setToast={setToast} />
          </CTabPane>
          <CTabPane
            role="tabpanel"
            aria-labelledby="historicos-tab"
            visible={showTab === tabsNames[2]}
          >
            <Historicos tabId={tabId} refForm={refForm} />
          </CTabPane>
          <CTabPane
            role="tabpanel"
            aria-labelledby="adicionales-tab"
            visible={showTab === tabsNames[3]}
          >
            <Adicionales tabId={tabId} refForm={refForm} />
          </CTabPane>
        </CTabContent>
      </CNav>
    </>
  )
}

export default React.memo(TabsEdition)
