import React from 'react'
import { CButton, CCard, CCardBody, CCol, CRow } from '@coreui/react-pro'
import {
  DataGrid,
  DateBox,
  LoadPanel,
  NumberBox,
  TextBox,
} from 'devextreme-react'
import {
  Column,
  FilterRow,
  HeaderFilter,
  Pager,
  Paging,
  Selection,
} from 'devextreme-react/data-grid'

import CustomCol from '../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import FormasPagoLookUp from '../../../../../componentes/formasPagoLookUp'
import { ETipoTransaccion, TFormaPagoOption } from '../../../../../componentes/formasPagoLookUp/store/type'
import { useDispatch, useSelector } from 'react-redux'

import CabeceraFiltroBusqueda from '../shared/cabeceraFiltroBusqueda'
import { TabState } from '../../../../../../store/genericTabTypes'
import { CustomButtons, FETCH_STATUS, ToastTypes } from '../../../../../../store/types'
import { RootState } from '../../../../../../store/store'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import { clearButtonClick, setCurrentExecutingAction } from '../../store/tabsReducer'
import { MessagesKeys, lh } from '../../../../../../helpers/localizationHelper'
import { addToast } from '../../../../../../store/toasterReducer'
import { changeFilter, fetchAdvances, initData, setCleanFields, setResetSeleccion, setSeleccionarDato } from '../../store/advancesReducer'
import { DateUtils } from '../../../../../../helpers/dateUtils'
import { ModalListarAnticipos } from '../../../../../componentes/modalListarAnticipos/modalListarAnticipos'
import VariasFormasPago from '../../../../../ventas/components/ventas/variasFormasPago/variasFormasPago'
import VisualizaError from '../../../../../ventas/pages/shared/visualizaError/visualizaError'
import { cuentasService } from '../../../../../contabilidad/pages/diarioGeneral/services/cuentas.service'
import { AccountingEntry } from '../../../../../clientes/pages/docsCtasXCobrar/types/types'
import { generateAcoountEntry } from '../../../../../clientes/pages/docsCtasXCobrar/store/abonosReducer'
import { formasPagoService } from '../../../../../componentes/formasPagoLookUp/service/formasPago.service'
import { getIndexProvider } from '../../../../../shared/helpers/funciones'
import ModalAsiento from '../../../../../contabilidad/pages/mayorGeneral/componentes/modalAsiento'
import ModalIngresoCheques from '../../../../../componentes/modalIngresoCheques'
import Importar from '../../../../../clientes/pages/docsCtasXCobrar/components/impportarExcel/importar'
import ModalTarjetas from '../../../../../componentes/modalTarjetas'
import ModalAnticipos from '../../../../../shared/components/modales/modalAnticipos'
import { ReportWithLocalDataModal } from '../../../../../../views/componentes/xtrareports/ReportWithLocalDataModal'
import { Abonar, Asientos, Cheques, Compra, Egreso, EgresosCajaDto, FiltrosAdvancesState } from '../../types/types'
import { DocumentsToPayService } from '../../services/docsCtasXPagar.services'
import { ModalArchivosDigitales } from '../../../../../componentes/modalArchivosDigitales'
import ListErrosValidationForm from '../../../../../componentes/listadoErroresValidacionForm'
import { consoleService } from '../../../../../../services/console.service'
import { EgresoTipo } from '../../../../store/types'
import { EFormasPago } from '../../../../../../store/enum/formasPago'

interface IAnticiposProps {
  tabId: string
  tab: TabState<any>
}
const Anticipos: React.FunctionComponent<IAnticiposProps> = (props) => {
  const { tab, tabId } = props

  const dispatch = useDispatch()
  const position = { of: '#advancesContent' }
  const filterGlobal = useSelector((state: RootState) => state.proveedores.documentosCuentasPorPagar.search.filter)
  const periodoActual = useSelector(
    (state: RootState) => state.shared.providers.periodoContable,
  )
  const empresa = useSelector((state: RootState) => { return state.global.session?.empresa })
  const modulo = useSelector((state: RootState) => state.proveedores.documentosCuentasPorPagar.configuraciones.modulo)
  const filtros = useSelector((state: RootState) => state.proveedores.documentosCuentasPorPagar.advances.filter)
  const seleccionado = useSelector((state: RootState) => state.proveedores.documentosCuentasPorPagar.advances.seleccionado)
  const anticipos = useSelector((state: RootState) => state.proveedores.documentosCuentasPorPagar.advances)


  const [showModalAsiento, setShowModalAsiento] = React.useState<boolean>(false)
  const [showImportButton, setShowImportButton] = React.useState<boolean>(false)
  const [reporte, setReporte] = React.useState<null | "Viewer" | "Designer">(null)

  const [displayMode] = React.useState<string | 'full' | 'compact'>('full')
  const [showPageSizeSelector] = React.useState<boolean>(true)
  const [showInfo] = React.useState<boolean>(true)
  const [showNavButtons] = React.useState<boolean>(true)
  const [showLoader, setShowLoader] = React.useState<boolean>(false)
  const [showModal, setShowModal] = React.useState<boolean>(false)
  const [showOthersPayMethod, setShowOthersMethod] = React.useState<boolean>(false)
  const [paymentsMethods, setPaymentsMethods] = React.useState<number>(-1)
  const [otherPaymentMethods, setOtherPaymentMethods] = React.useState<TFormaPagoOption[]>([])
  const [showErrorPopup, setShowErrorPopup] = React.useState<boolean>(false)
  const [arrayErrors, setArrayErrors] = React.useState<Array<string>>([])
  const [resultadoImprimir, setResultadoImprimir] = React.useState<any>({})
  const [showModalDA, setShowModalDA] = React.useState(false);

  const [showCreditCard, setShowCreditCard] = React.useState<boolean>(false)
  const [showAdvances, setShowAdvances] = React.useState<boolean>(false)
  const [showBankChecks, setShowBankChecks] = React.useState<boolean>(false)

  const [fechaFin, setFechaFin] = React.useState<Date | null>(null)
  const [fecha, setFecha] = React.useState<Date>(new Date())
  const [numero, setNumero] = React.useState<number>(null)
  const [codigo, setCodigo] = React.useState<number>(null)
  const [concepto, setConcepto] = React.useState<string>(null)
  const [valor, setValor] = React.useState<string>(null)
  const [documento, seDocumento] = React.useState<string>(null)
  const [formasPago, setFormaPago] = React.useState<TFormaPagoOption>(null)

  const [resultados, setResultados] = React.useState<Array<any>>([])
  const [total, setTotal] = React.useState<number>(null)
  const [devengado, setDevengado] = React.useState<number>(null)
  const [saldo, setSaldo] = React.useState<number>(null)
  const [antCodigo, setAntCodigo] = React.useState<number>(0)

  const pertenece = 2
  const antOrigen = 'EGRESO'
  const fechaFormato = 'dd/MM/yyyy'
  const antTransaccion = 'ANTICIPO PROVEEDOR'

  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(
      addToast({
        title: 'Anticipos',
        content: mensaje,
        type: tipo,
      }),
    )
  }, [dispatch])

  const getFilterString = React.useCallback((filter: FiltrosAdvancesState) => {
    const filterText = "";

    return filterText;
  }, [])

  const onChangeData = React.useCallback((data, key) => {
    if (data.value !== null && data.value !== undefined) {
      let makeDispatch: boolean = true
      switch (key) {
        case 'fecha':
          setFecha(data.value)
          break;
        case 'fechaFin':
          setFechaFin(data.value)
          break;
        case 'numero':
          setNumero(data.value)
          break;
        case 'codigo':
          setCodigo(data.value)
          break;
        case 'concepto':
          setConcepto(data.value)
          break;
        case 'valor':
          setValor(data.value)
          break;
        case 'documento':
          seDocumento(data.value)
          break;
        default:
          makeDispatch = false
          break;
      }
      if (makeDispatch) {
        let value = null
        if (key === 'fecha' || key === 'fechaFin') {
          value = DateUtils.dateToString(data.value, fechaFormato)
        } else {
          value = data.value
        }
        dispatch(changeFilter({
          ...filtros,
          [key]: value
        }))
      }
    } else if (data !== null && data !== undefined && key === 'formasPago') {
      setFormaPago(data)
      dispatch(changeFilter({
        ...filtros,
        [key]: data
      }))
    }
  }, [dispatch, filtros])

  const getApiData = React.useCallback(async (codigo: number, accion: string) => {
    try {
      const request_search = {
        tab: 'ANTICIPOS',
        codigoAsociado: codigo,
        egresoCodigo: null,
        tipo: "ANTICIPO PROVEEDOR"
      }
      setShowLoader(true)
      const data = await DocumentsToPayService.getDataPrintPurchase(request_search)
      setShowLoader(false)
      if (data !== null && data !== undefined && data['error'] === false) {
        setResultadoImprimir(data['auto'])
        if (accion === 'Imprimir') {
          setReporte('Viewer')
        } else {
          setReporte('Designer')
        }
      } else {
        setToast(data['message'], ToastTypes.Danger)
        setResultadoImprimir({})
      }
    } catch (error) {
      setShowLoader(false)
      setResultadoImprimir({})
      setToast(error.message, ToastTypes.Danger)
    }
  }, [setToast])
  const getFormaPago = React.useCallback(async () => {
    const codigoTipoPago = seleccionado.row.formaPago
    const transaccion = ETipoTransaccion.compras
    const data = await formasPagoService.getFormasPago(transaccion, "Elija una opción");
    if (data !== null && data !== undefined && data['auto'] && data['auto'].length > 0) {
      const fp = data['auto'].find(p => p.codigo == codigoTipoPago)
      setFormaPago(fp)
    }
  }, [seleccionado])

  const onGetDataEdit = React.useCallback((data) => {
    const fecha = DateUtils.strDateToDate(data.registro, fechaFormato)
    setFecha(fecha)
    setAntCodigo(data.codigo)
    setNumero(data.numeroTipoAsiento)
    setConcepto(data.concepto)
    setValor(data.valor.toString())
    seDocumento(data.documento)
    getFormaPago()
    dispatch(changeFilter({
      ...filtros,
      numero: data.numeroTipoAsiento,
      concepto: data.concepto,
      documento: data.documento,
      valor: data.valor.toString(),
      codigoAnticipo: data.codigo,
      codigoAsociado: data.codigoAso,
      fecha: data.registro
    }),)
  }, [dispatch, filtros, getFormaPago])

  const getTotales = React.useCallback((lista: Array<any>) => {
    let totales = 0
    let devengado = 0
    let saldos = 0

    if (lista.length > 0) {
      lista.forEach(item => {
        totales += item.valor
        devengado += item.devengado
        saldos += item.saldo
      })

      setTotal(totales)
      setDevengado(devengado)
      setSaldo(saldos)
    }
  }, [])


  const onFind = React.useCallback(async () => {
    const finfechcal = fechaFin !== null ? DateUtils.dateToString(fechaFin, fechaFormato) : DateUtils.getCurrentDateAsString(fechaFormato)
    const object_find = {
      pertence: pertenece,
      asociado: filtros?.proveedor ? filtros.proveedor?.codigo : 0,
      nrtipo: numero,
      codigo: codigo,
      local: filtros?.local ? filtros.local?.codigo : -1,
      hasta: finfechcal
    }

    try {
      setResultados([])
      const toAny: any = fetchAdvances(object_find)
      const res = await dispatch(toAny)
      if (res !== null && res !== undefined && res.payload['error'] === false) {
        setResultados(res.payload['auto'])
        getTotales(res.payload['auto'])
        setToast(res.payload['message'], ToastTypes.Success)
      } else {
        setToast(res.payload['message'], ToastTypes.Danger)
      }
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
  }, [filtros, setToast, codigo, fechaFin, numero, dispatch, getTotales])

  const onEnterChecksCredtiCardAdvances = React.useCallback(
    (paymentsMethods) => {
      let openModalChekcs = false
      let openModalCreditCards = false
      let openModalAdvances = false
      paymentsMethods.map((payment) => {
        if (
          payment.codigoSub === 7 ||
          payment.codigoSub === 114 ||
          payment.codigoSub === 9
        )
          openModalChekcs = true
        else if (payment.codigoSub === 22) openModalCreditCards = true
        else if (payment.codigoSub === 30) openModalAdvances = true
      })
      if (openModalChekcs || openModalCreditCards || openModalAdvances)
        if (openModalChekcs) setShowBankChecks(true)
        else if (openModalCreditCards) setShowCreditCard(true)
        else if (openModalAdvances) setShowAdvances(true)
    },
    [],
  )

  const generateAE = React.useCallback(async (data, pagos) => {
    try {
      const accountingEntry: AccountingEntry = {
        infoRegistro: {
          movimiento: antTransaccion,
        },
        fecha: data?.ANT_REGISTRO ?? filtros.fecha,
        descripcion: `Abono registrado por: ${data.ANT_ASOCIADO_DES}`,
        numeroDocumento: data.ASO_CODIGO, // ASO_CODIGO
        codigo: data.ANT_CODIGO, // ASO_CODIGO
        diarioNumero: periodoActual.length > 0 ? periodoActual[0]?.diario : 25, //GET THE NUMBER OF ACCOUNT PERIOD
        localCodigo: filtros.local !== null ? filtros.local.codigo : -1,
        formaPago: pagos,
      }
      setShowLoader(true)
      const toAny: any = generateAcoountEntry(accountingEntry)
      const res = await dispatch(toAny)
      setShowLoader(false)
      if (res !== null && res !== undefined && res.payload['error'] === false) {
        getApiData(data?.ANT_CODIGO, 'Imprimir')
        setToast(res.payload['message'], ToastTypes.Success)
      } else {
        setToast(res.payload['message'], ToastTypes.Danger)
      }
    } catch (error) {
      setShowLoader(false)
      setToast(error.message, ToastTypes.Danger)
    }
  }, [dispatch, filtros, setToast, periodoActual, getApiData])

  const generateEntry = React.useCallback(async (data, tipoPagonant: Array<any>) => {
    await onFind()
    let generar = false
    const pagos: Array<{ codigo: number; valor: number }> = []
    tipoPagonant.map(pago => {
      pagos.push({
        codigo: pago.codigoSub,
        valor: pago.valorFormaPago
      })

      if (
        pago.codigoSub === 7 ||
        pago.codigoSub === 9 ||
        pago.codigoSub === 22 ||
        pago.codigoSub === 30 ||
        pago.codigoSub === 114
      ) {
        generar = true
      }
    })

    if (!generar) {
      await generateAE(data, pagos)
    }


  }, [onFind, generateAE])

  const isValidateForm = React.useCallback(() => {
    let errorsPaymensMethods: boolean = false
    const errors: Array<string> = []
    if (filtros.proveedor === null || filtros.proveedor === undefined || filtros.proveedor.codigo === 0) {
      errors.push('Proveedor: elija un proveedor.')
    }
    if (numero === null || numero === undefined || numero === 0) {
      errors.push('Numero: este campo esta vacío o es incorrecto.')
    }
    if (concepto === null || concepto === undefined || concepto === "") {
      errors.push('Concepto: este campo esta vacío o es incorrecto.')
    }

    if (valor === null || valor === undefined || valor === "" || isNaN(parseInt(valor))) {
      errors.push('Valor: este campo esta vacío o tiene un valor incorrecto.')
    }

    if (documento === null || documento === undefined || documento === "") {
      errors.push('Documento: este campo esta vacío.')
    }

    if (formasPago === null || formasPago === undefined || formasPago.codigo === -1) {
      errors.push('Forma de Pago: seleccione una Forma de Pago')
    } else if (formasPago === null || formasPago === undefined || formasPago.codigo === 8) {
      errors.push('Forma de pago: no puede realizar el cobro con Crédito')
    } else if (formasPago === null || formasPago === undefined || formasPago.codigo === 30) {
      errors.push(
        'Forma de pago: no puede realizar el pago con Anticipos',
      )
    } else if (formasPago === null || formasPago === undefined || formasPago.codigo === EFormasPago.otros) {
      let valuePago: number = Number(parseInt(valor).toFixed(2))
      otherPaymentMethods.map(pago => {
        valuePago = valuePago - Number(parseInt(valor).toFixed(2))
        if (pago.codigo === 8) {
          errors.push('Forma de pago: no puede realizar el pago con Crédito')
          errorsPaymensMethods = true
        }
        if (pago.codigo === 30) {
          errors.push('Forma de pago: no puede realizar el pago con Anticipos')
          errorsPaymensMethods = true
        }
      })
      if (valuePago > 0 || otherPaymentMethods.length === 1) {
        errors.push('Forma de pago: establezca los valores de las distintas formas de pago')
        if (errorsPaymensMethods) setShowOthersMethod(true)
      }
    }

    if (filtros.local === null || filtros.local === undefined || filtros.local.codigo === -1) {
      errors.push('Centro de Costos: elija una opción')
    }

    return errors
  }, [numero, valor, concepto, documento, formasPago, filtros, otherPaymentMethods])

  const onBroom = React.useCallback(() => {
    dispatch(setCleanFields())
    dispatch(setResetSeleccion())
    setFechaFin(null)
    setFecha(new Date())
    setNumero(null)
    setCodigo(null)
    setConcepto(null)
    setValor(null)
    seDocumento(null)
    setFormaPago(null)
  }, [dispatch])

  const deleteItemTabla = React.useCallback(async () => {
    if (seleccionado.row !== null && seleccionado.row !== undefined) {
      if (resultados !== null && resultados !== undefined && resultados.length > 0) {
        const provider = resultados.slice(0);
        const index = await getIndexProvider(provider, 'codigo', seleccionado.row.codigo)
        if (index > -1) {
          provider.splice(index, 1)
          setResultados(provider)
        }
      }
    }
  }, [resultados, seleccionado.row])

  const onNew = React.useCallback(() => {
    dispatch(initData())
    dispatch(setResetSeleccion())
    setFechaFin(null)
    setFecha(new Date())
    setNumero(null)
    setCodigo(null)
    setConcepto(null)
    setValor(null)
    seDocumento(null)
    setFormaPago(null)
    setOtherPaymentMethods([])
    setPaymentsMethods(-1)
  }, [dispatch])

  const onDelete = React.useCallback(async () => {
    try {
      const delete_request = {
        codigo: seleccionado.row.codigo,
        transaccion: antTransaccion,
      }
      setShowLoader(true)
      const data = await cuentasService.deleteAnticipos(
        delete_request.codigo,
        delete_request.transaccion,
      )
      setShowLoader(false)
      if (data !== null && data !== undefined && data['error'] === false) {
        deleteItemTabla()
        setToast(data['message'], ToastTypes.Success)
      } else {
        setToast(data['message'], ToastTypes.Danger)
      }
    } catch (error) {
      setShowLoader(false)
      setToast(error.message, ToastTypes.Danger)
    }
  }, [seleccionado.row, setToast, deleteItemTabla])

  const onSave = React.useCallback(async () => {
    const errors = isValidateForm()
    if (errors.length > 0) {
      setArrayErrors(errors)
      setShowErrorPopup(true)
      return false
    } else {
      let resultadoPagoAnt: any = []
      const precision = empresa?.precision ?? 2
      if (filtros.formasPago.codigo !== EFormasPago.otros) {
        resultadoPagoAnt = []
      }
      if (filtros.formasPago.codigo !== EFormasPago.otros && parseInt(parseInt(valor).toFixed(precision)) > 0) {
        resultadoPagoAnt.push({ codigoSub: filtros.formasPago.codigo, valorFormaPago: valor })
      }
      const fechacal = fecha !== null ? DateUtils.dateToString(fecha, fechaFormato) : DateUtils.getCurrentDateAsString(fechaFormato)
      const save_request = {
        antcodigo: antCodigo,
        antnumero: numero.toString(),
        antpertence: pertenece,
        antAsociado: filtros.proveedor !== null ? filtros.proveedor?.codigo : 0,
        antfpago: filtros.formasPago !== null ? filtros.formasPago.codigo : -1,
        antconcepto: concepto,
        antregistro: fechacal,
        documento: documento,
        valor: parseInt(valor),
        antorigen: antOrigen,
        ptocodigo: filtros.puntoVenta !== null ? filtros.puntoVenta.codigo : -1,
        locCodigo: filtros.local !== null ? filtros.local.codigo : -1,
        pagos: resultadoPagoAnt,
      }

      try {
        setShowLoader(true)
        const data = await cuentasService.saveAnticipo(
          save_request.antcodigo,
          save_request.antnumero,
          save_request.antpertence,
          save_request.antAsociado,
          save_request.antfpago,
          save_request.antconcepto,
          save_request.antregistro,
          save_request.documento,
          save_request.valor,
          save_request.antorigen,
          save_request.ptocodigo,
          save_request.locCodigo,
          save_request.pagos,
        )

        setShowLoader(false)
        if (data !== null && data !== undefined && data['error'] === false) {
          dispatch(changeFilter({
            ...filtros,
            codigoAsociado: data['auto'].ANT_CODIGO,
            codigoAnticipo: data['auto'].ASO_CODIGO,
            numero: data['auto'].ASI_NROTIPO,
          }))
          setToast(data['message'], ToastTypes.Success)
          onEnterChecksCredtiCardAdvances(resultadoPagoAnt)
          await generateEntry(data['auto'], resultadoPagoAnt)
        } else {
          setToast(data['message'], ToastTypes.Danger)
        }
      } catch (error) {
        setShowLoader(false)
        setToast(error.message, ToastTypes.Danger)
      }
    }
  }, [antCodigo, concepto, documento, empresa, fecha, filtros, numero, valor, setToast, isValidateForm, generateEntry, onEnterChecksCredtiCardAdvances, dispatch])


  const handleButtonClick = React.useCallback((button: ButtonTypes) => {
    switch (button) {
      case ButtonTypes.new:
        onNew()
        break
      case ButtonTypes.broom:
        onBroom()
        break
      case ButtonTypes.find:
        onFind()
        break
      case ButtonTypes.delete:
        onDelete()
        break
      case ButtonTypes.edit:
        if (seleccionado.row !== null && seleccionado.row !== undefined) {
          onGetDataEdit(seleccionado.row)
        } else {
          setToast(lh.getMessage(MessagesKeys.NotFoundRegisterSelec), ToastTypes.Warning)
        }
        break
      case ButtonTypes.undo:
        console.log('undo----- anticipos x pagar')
        if (seleccionado.row !== null && seleccionado.row !== undefined) {
          onGetDataEdit(seleccionado.row)
        } else {
          setToast(lh.getMessage(MessagesKeys.NotFoundRegisterSelec), ToastTypes.Warning)
        }
        break
      case ButtonTypes.save:
        onSave()
        break
      case ButtonTypes.check:
        if (seleccionado.row !== null && seleccionado.row !== undefined) {
          if (filtros.formasPago !== null && filtros.formasPago !== undefined && filtros.formasPago.codigo === 7) {
            setShowBankChecks(true)
          } else {
            setToast("Seleccione Forma de Pago: Cheque", ToastTypes.Warning)
          }
        } else {
          setToast(lh.getMessage(MessagesKeys.NotFoundRegisterSelec), ToastTypes.Warning)
        }
        break
      case ButtonTypes.verAsiento:
        if (seleccionado.row !== null && seleccionado.row !== undefined) {
          setShowModalAsiento(true)
        } else {
          setToast(lh.getMessage(MessagesKeys.NotFoundRegisterSelec), ToastTypes.Warning)
        }
        break
      case ButtonTypes.checks_pf:
        if (seleccionado.row !== null && seleccionado.row !== undefined) {
          if (filtros.formasPago !== null && filtros.formasPago !== undefined && filtros.formasPago.codigo === 9) {
            setShowBankChecks(true)
          } else {
            setToast("Seleccione Forma de Pago: Cheque Post-Fechado", ToastTypes.Warning)
          }
        } else {
          setToast(lh.getMessage(MessagesKeys.NotFoundRegisterSelec), ToastTypes.Warning)
        }
        break
      case ButtonTypes.methodsPay:
        setShowOthersMethod(true)
        break
      case ButtonTypes.transfers:
        if (seleccionado.row !== null && seleccionado.row !== undefined) {
          if (filtros.formasPago !== null && filtros.formasPago !== undefined && filtros.formasPago.codigo === 114) {
            setShowBankChecks(true)
          } else {
            setToast("Seleccione Forma de Pago: Transferencias Bancarias", ToastTypes.Warning)
          }
        } else {
          setToast(lh.getMessage(MessagesKeys.NotFoundRegisterSelec), ToastTypes.Warning)
        }
        break
      case ButtonTypes.import:
        setShowImportButton(true)
        break
      case ButtonTypes.print:
        if (seleccionado.row !== null && seleccionado.row !== undefined) {
          getApiData(seleccionado.row.codigo, 'Imprimir')
        } else {
          setToast("Seleccione un anticipo para poder imprimirlo.", ToastTypes.Danger)
        }
        break
      case ButtonTypes.print_design:
        if (seleccionado.row !== null && seleccionado.row !== undefined) {
          getApiData(seleccionado.row.codigo, 'Diseñar')
        } else {
          setToast("Seleccione un anticipo para poder diseñarlo.", ToastTypes.Danger)
        }
        break
      case ButtonTypes.digitize:
        if (seleccionado.row !== null && seleccionado.row !== undefined && seleccionado.row.codigo !== 0) {
          setShowModalDA(true)
        } else {
          setToast(lh.getMessage(MessagesKeys.NotFoundRegisterSelec), ToastTypes.Warning)
        }
        break
      default:
        break
    }
    dispatch(clearButtonClick(tabId))
  }, [dispatch, setToast, seleccionado.row, tabId, onFind, onSave, onGetDataEdit, onBroom, onDelete, onNew, filtros, getApiData])

  React.useEffect(() => {
    dispatch(changeFilter({
      ...filtros,
      proveedor: filterGlobal.proveedor,
      local: filterGlobal.local,
      puntoVenta: filterGlobal.puntoVenta
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterGlobal])

  React.useEffect(() => {
    if (filtros.formasPago) setPaymentsMethods(filtros.formasPago.codigo)
  }, [filtros.formasPago])

  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
  }, [handleButtonClick, tab.globalButtonClick])

  React.useEffect(() => {
    if (anticipos.status === FETCH_STATUS.FAILED) {
      dispatch(
        addToast({
          title: 'Anticipos',
          content: anticipos.mensaje ?? lh.getMessage(MessagesKeys.GlobalButtonSearchError),
          type: ToastTypes.Danger,
        }),
      )
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'ANTICIPOS',
          buttonType: undefined,
        }),
      )
    }
    if (anticipos.status === FETCH_STATUS.LOADING) {
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'ANTICIPOS',
          buttonType: ButtonTypes.find,
        }),
      )
    }
    if (anticipos.status === FETCH_STATUS.SUCCESS) {
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'ANTICIPOS',
          buttonType: undefined,
        }),
      )
    }
  }, [dispatch, anticipos.status, anticipos.mensaje])

  const onSelectionChanged = React.useCallback((selectionChanged) => {
    console.log("onSelectionChanged", selectionChanged['selectedRowsData'][0])
    dispatch(setSeleccionarDato(
      {
        index: [selectionChanged['currentSelectedRowKeys'][0]],
        row: selectionChanged['selectedRowsData'][0],
        selectedRow: [selectionChanged['currentSelectedRowKeys'][0]]
      }
    ))
  }, [dispatch]);

  const getParseData = React.useCallback((anticipo: any = {}) => {
    const { asientos, egreso, compra } = anticipo
    consoleService.log(anticipo)
    const registros: Array<EgresosCajaDto> = []

    const abonosArray: Array<Abonar> = []
    let asientosArray: Array<Asientos> = []
    const chequesArray: Array<Cheques> = []
    let egresoObj: Egreso
    let compraObj: Compra

    if (egreso !== null && egreso !== undefined) {

      egresoObj = {
        codigo: parseInt(egreso?.egresoCodigo) ?? 0,
        numero: egreso?.egresoNumero ?? "",
        tipo: egreso?.egresoTipo ?? "",
        asociado: parseInt(egreso?.egresoAsociado) ?? 0,
        aceptado: "",
        valor: parseInt(egreso.egresoValor) ?? null,
        fecha: egreso?.egresoFecha ?? "",
        concepto: egreso.egresoConcepto ?? "",
        estadoCodigo: parseInt(egreso?.egresoEstado) ?? 0,
        estadoDescripcion: egreso?.egresoEstadoDes ?? "",
        asientoNumeroTipo: egreso?.asientoNroTipo ?? "",
        proveedorCodigo: parseInt(egreso?.egresoProveedorCodigo) ?? 0,
        localNombre: egreso?.egresoLocalNombre ?? "",
        localCodigo: parseInt(egreso?.egresoLocalCodigo) ?? 0,
        asientoNumero: egreso?.asientoNumero ?? "",
        puntoVentaCodigo: egreso?.egresoPuntoCodigo ?? "",
      }
    }

    if (compra !== null && compra !== undefined) {
      compraObj = {
        establecimiento: egreso?.egresoTipo ?? "",
        nombreUsuario: compra?.nombreUsuario ?? "",
        proveedorCodigo: compra?.proveedorCodigo ?? "",
        proveedorNombre: compra?.proveedorNombre ?? "",
        proveedorIdentificacion: compra?.proveedorIdentificacion ?? "",
      }
    }

    if (asientos !== null && asientos !== undefined && asientos.length > 0) {
      asientosArray = asientos.map((x) => {
        const debe = x?.transaccionDebe === '' ? 0 : parseInt(x?.transaccionDebe)
        const haber = x?.transaccionHaber === '' ? 0 : parseInt(x?.transaccionHaber)

        const asientoObj: Asientos = {
          asiNmero: 0,
          planCtaCodigo: x?.transaccionCodigo ?? 0,
          planCtaNumero: x?.planNumero ?? "",
          planCtaDescripcion: x?.planDescripcion ?? "",
          planCtaDescripcionIdioma: "",
          localCodigo: 0,
          localNombre: "",
          transaccionReferencia: x?.transaccionReferencia ?? "",
          transaccionDebe: debe,
          transaccionHaber: haber,
          siglasLocal: "",
        }

        return asientoObj
      })
    }

    const debe = !isNaN(anticipo?.asientoTotalDebe ?? 0) ? parseInt(anticipo?.asientoTotalDebe) : 0
    const haber = !isNaN(anticipo?.asientoTotalHaber ?? 0) ? parseInt(anticipo?.asientoTotalHaber) : 0

    const egresoCaja: EgresosCajaDto = {
      EgresoTipo: EgresoTipo.anticipo,
      egreso: egresoObj,
      compra: compraObj,
      abonos: abonosArray.length > 0 ? abonosArray : null,
      cheques: chequesArray.length > 0 ? chequesArray : null,
      asientos: asientosArray,
      formaPago: anticipo?.formapago ?? '',
      usuario: compraObj?.nombreUsuario ?? '',
      fecha: egresoObj?.fecha ?? '',
      elaborado: anticipo?.elaborado ?? '',
      modificado: '',
      concepto: egresoObj?.concepto ?? '',
      descripcion: '',
      totalDebe: debe,
      totalHaber: haber,
    }

    registros.push(egresoCaja)
    consoleService.log(registros)
    return registros
  }, [])



  const onDBSelectedRow = React.useCallback(({ data }) => {
    if (data != null && data !== undefined) {
      onGetDataEdit(data)
    }
  }, [onGetDataEdit])

  const onSetValuePaymentsMethods = React.useCallback((paymentMetohds) => {
    const othersPaymentsMethods: Array<TFormaPagoOption> = []
    for (const payment of paymentMetohds) {
      const pay: TFormaPagoOption = {
        codigo: payment.codigoFormaPago,
        descripcion: payment.descripcionFormaPago,
        asignadoSri: payment.asignado,
        grupo: payment.valor.toString(),
      }
      othersPaymentsMethods.push(pay)
    }
    dispatch(changeFilter({
      ...filtros,
      formasPago: othersPaymentsMethods[0],
    }),)
    setShowOthersMethod(false)
  }, [dispatch, filtros])

  const modalAnticiposComponent = () => {
    return (
      <ModalListarAnticipos
        onChanged={(data) => console.log("onChange", data)}
        onCancel={() => setShowModal(false)}
        show={showModal}
        codigoAnticipo={seleccionado.row.codigo}
      />
    )
  }

  const setLote_lb = React.useCallback((data) => {
    console.log('CC', data)
  }, [])

  const calcularTotalLote = React.useCallback(() => {
    console.log('XXXXX')
  }, [])


  const modalAsiento = (

    <ModalAsiento
      show={showModalAsiento}
      onClose={() => setShowModalAsiento(false)}
      infoAsiento={seleccionado}
      origenModulo={Number(modulo)}
    />

  )

  const openModalDigitalizarArchivos = React.useCallback(() => {
    return (
      <ModalArchivosDigitales
        onChanged={(data) => { }}
        onCancel={() => setShowModalDA(false)}
        show={showModalDA}
        modulo={tabId}
        codModulo={modulo}
        chequeNumero={seleccionado.row.codigo ?? 0}
      />
    )
  }, [tabId, showModalDA, modulo, seleccionado.row])

  const getTotal = React.useCallback(() => {
    let total = 0
    if (seleccionado.row !== null && seleccionado.row !== undefined && seleccionado.row.valor) {
      total = seleccionado.row.valor
    }
    return total
  }, [seleccionado.row])

  const getDataFormat = React.useCallback(() => {
    let fechaOtrasFP = DateUtils.dateToString(fecha)
    if (seleccionado.row !== null && seleccionado.row !== undefined && seleccionado.row.registro) {
      const fechaToTranform = new Date(seleccionado.row.registro)
      fechaOtrasFP = DateUtils.dateToString(fechaToTranform)
    }
    return fechaOtrasFP
  }, [fecha, seleccionado.row])

  return (
    <>
      <ReportWithLocalDataModal
        show={reporte !== null}
        onClose={() => setReporte(null)}
        data={getParseData(resultadoImprimir)}
        fileName='StaticEgresosCaja'
        mode={reporte ?? 'Viewer'}
        parameters={{ 'Reporte_Filtro': getFilterString(filtros), Modulo: "Proveedores" }}
        template='StaticEgresosCaja'
        key='reportDesigner'
      />
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={position}
        visible={anticipos.status === FETCH_STATUS.LOADING || showLoader}
        showIndicator={true}
        shading={true}
        showPane={true}
        closeOnOutsideClick={false}
      />
      {showModalDA && openModalDigitalizarArchivos()}
      {showCreditCard && (
        <ModalTarjetas
          show={showCreditCard}
          onClose={() => setShowCreditCard(false)}
          parametros={() => { }}
          pathVerificador={''}
          ejecutaAccion={calcularTotalLote}
          setTotales={(data) => setLote_lb(data)}
          onSelected={(data) => {
            console.log('tarjetas onSelected', data)
          }}
        />
      )}
      {showBankChecks && (
        <ModalIngresoCheques
          show={showBankChecks}
          configModal={{
            open: showBankChecks,
            name: 'Ingresar Cheque Anticipo Compra',
            parametros: {
              incomeCode: seleccionado.row.codigoAso, // anticipo.asociadoCodigo
              incomeDate: seleccionado.row.registro,
            },
            codModulo: Number(modulo),
            tab: 'ANTICIPOS',
            tipoFP: filtros.formasPago.codigo,
          }}
          onClose={async () => {
            setShowBankChecks(false)
            const tiposPago: Array<any> = []
            tiposPago.push({ codigo: filtros.formasPago.codigo, valor: seleccionado.row?.valor })
            const data: any = {
              ANT_ASOCIADO_DES: seleccionado.row?.asociadoDes ?? "",
              ANT_REGISTRO: seleccionado.row?.registro ?? DateUtils.getCurrentDateAsString,
              ASO_CODIGO: seleccionado.row?.codigoAso ?? 0,
              ANT_CODIGO: filtros.formasPago.codigo ?? 0,
            }
            await generateAE(data, tiposPago)
          }}
          parametros={(evt) => console.log(evt)}
          pathVerificador={''}
          ejecutaAccion={() => console.log('ejecutaAccion')}
          onSelect={(evt) => console.log(evt)}
        />
      )}
      <CCard id="advancesContent">
        {showModal === true && modalAnticiposComponent()}
        {showModalAsiento === true && modalAsiento}
        {showOthersPayMethod && (
          <VariasFormasPago
            numeroVenta={filtros.codigoAnticipo}
            tiposPagos={[]}
            fechaFactura={getDataFormat()}
            totalCobrar={getTotal()}
            onCancel={() => setShowOthersMethod(false)}
            onOk={onSetValuePaymentsMethods}
          />
        )}
        {showAdvances && (
          <ModalAnticipos
            show={showAdvances}
            onClose={() => setShowAdvances(false)}
            pathVerificador={''}
            modulo={Number(modulo)}
          />
        )}
        {
          showErrorPopup && (<VisualizaError
            titulo="Error en Anticipos"
            mensaje={'Error al guardar'}
            content={
              <ListErrosValidationForm
                mainMessage={'Revise los siguientes errores:'}
                errorsList={arrayErrors}
                color={'danger'}
              />
            }
            onOk={() => setShowErrorPopup(false)}
          />)
        }
        <CCardBody>
          <CabeceraFiltroBusqueda
            seleccionado={seleccionado} />
          <RowContainer>
            <CustomCol xs="12" md="3">
              <Labeled label="Fecha:">
                <DateBox
                  value={fecha}
                  onValueChanged={(data) => onChangeData(data, 'fecha')}
                //onEnterKey={(evt) => onEnterKey(evt, 'Fecha')}
                />
              </Labeled>
            </CustomCol>
            <CustomCol xs="12" md="3">
              <Labeled label="Fecha Fin:">
                <DateBox
                  value={fechaFin}
                  onValueChanged={(data) => onChangeData(data, 'fechaFin')}
                //onEnterKey={(evt) => onEnterKey(evt, 'Fecha')}
                />
              </Labeled>
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol xs="12" md={showImportButton ? "7" : "12"}>
              <RowContainer>
                <CustomCol xs="12" md="4">
                  <Labeled label="Número:">
                    <NumberBox
                      value={numero}
                      onValueChanged={(data) => onChangeData(data, 'numero')}
                      //onEnterKey={(evt) => onEnterKey(evt, 'Numero')}
                      showClearButton
                    />
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="4">
                  <Labeled label="Código:">
                    <NumberBox
                      value={codigo}
                      onValueChanged={(data) => onChangeData(data, 'codigo')}
                      //onEnterKey={(evt) => onEnterKey(evt, 'Codigo')}
                      showClearButton
                    />
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="4">
                  <Labeled label="Concepto:">
                    <TextBox
                      value={concepto}
                      onValueChanged={(data) => onChangeData(data, 'concepto')}
                      //onEnterKey={(evt) => onEnterKey(evt, 'Concepto')}
                      showClearButton
                    />
                  </Labeled>
                </CustomCol>
              </RowContainer>
              <RowContainer>
                <CustomCol xs="12" md="4">
                  <Labeled label="Valor:">
                    <TextBox
                      value={valor}
                      onValueChanged={(data) => onChangeData(data, 'valor')}
                      //onEnterKey={(evt) => onEnterKey(evt, 'Valor')}
                      showClearButton
                    />
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="4">
                  <Labeled label="Documento:">
                    <TextBox
                      value={documento}
                      onValueChanged={(data) => onChangeData(data, 'documento')}
                      //onEnterKey={(evt) => onEnterKey(evt, 'Documento')}
                      showClearButton
                    />
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="4">
                  <Labeled label="Formas de Pago:">
                    <FormasPagoLookUp
                      onChanged={(data) => onChangeData(data, 'formasPago')}
                      onChangedOptions={(data) => { }}
                      selected={formasPago}
                      transaccion={ETipoTransaccion.compras}
                    />
                  </Labeled>
                </CustomCol>
              </RowContainer>
            </CustomCol>
            {
              showImportButton && (
                <CustomCol xs="12" md="5">
                  <Importar
                    tab={tab}
                    tabId={tabId}
                    openImport={(open) => setShowImportButton(open)}
                    moduleCode={modulo}
                  />
                </CustomCol>
              )
            }
          </RowContainer>
          <CustomCol xs="12" md="12">
            <CCol className="d-flex justify-content-end">
              {resultados.length}
            </CCol>
            <DataGrid
              selection={{ mode: 'single' }}
              hoverStateEnabled={true}
              dataSource={resultados}
              showBorders={true}
              showRowLines={true}
              onSelectionChanged={onSelectionChanged}
              onRowDblClick={onDBSelectedRow}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              keyExpr="codigo"
              key="gridAnticipos"
            >
              <FilterRow visible={true} />
              <HeaderFilter visible={true} />
              <Selection
                mode="single"
                selectAllMode={'onClick'}
                showCheckBoxesMode={[]}
              />

              <Paging defaultPageSize={10} />
              <Pager
                displayMode={displayMode}
                showPageSizeSelector={showPageSizeSelector}
                showInfo={showInfo}
                showNavigationButtons={showNavButtons}
              />
              <Column
                dataType="string"
                dataField="registro"
                caption="Fecha"
                width="7%"
              />
              <Column
                dataType="string"
                dataField="numeroTipoAsiento"
                caption="Diario"
                width="5%"
              />
              <Column
                dataType="string"
                dataField="nombreLocal"
                caption="Local/CC"
                width="9%"
              />
              <Column
                dataType="string"
                dataField="asociadoId"
                caption="Ced/Ruc"
                width="9%"
              />
              <Column dataField="asociadoDes" caption="Cliente" width="15%" />
              <Column
                dataType="string"
                dataField="concepto"
                caption="Concepto"
                width="9%"
              />
              <Column
                dataType="string"
                dataField="documento"
                caption="Documento"
                width="9%"
              />
              <Column dataField="valor" caption="Valor" width="9%" />
              <Column
                dataType="string"
                dataField="devengado"
                caption="Deverngado"
                width="9%"
              />
              <Column
                dataType="string"
                dataField="saldo"
                caption="Saldo"
                width="9%"
              />
            </DataGrid>
          </CustomCol>
          <RowContainer>
            <CustomCol xs="12" md="2">
              <CButton
                id="btnConfirmar"
                color="secondary"
                disabled={seleccionado.row === null || seleccionado.row === undefined}
                className="m-1"
                size="sm"
                onClick={() => setShowModal(true)}
              >
                {'Ver Detalle'}
              </CButton>
            </CustomCol>
            <CustomCol
              xs="12"
              md="10"
              className="d-flex justify-content-end align-items-center"
            >
              <CRow>
                <CCol md="6" className="d-flex justify-content-end">
                  <strong> {'Totales:'} </strong>
                </CCol>
                <CCol md="2">
                  <NumberBox
                    readOnly
                    width="100%"
                    value={total}
                    placeholder=""
                    format="#,##0.00"
                  />
                </CCol>
                <CCol md="2">
                  <NumberBox
                    readOnly
                    width="100%"
                    value={devengado}
                    placeholder=""
                    format="#,##0.00"
                  />
                </CCol>
                <CCol md="2">
                  <NumberBox
                    readOnly
                    width="100%"
                    value={saldo}
                    placeholder=""
                    format="#,##0.00"
                  />
                </CCol>
              </CRow>
            </CustomCol>
          </RowContainer>
        </CCardBody>
      </CCard>
    </>
  )
}

export default React.memo(Anticipos)

export const ModuleButtonsAnticipos: CustomButtons = {
  Nuevo: true,
  Guardar: true,
  Buscar: true,
  Deshacer: true,
  Eliminar: true,
  Editar: true,
  Imprimir: true,
  Importar: true,
  Limpiar: true,
  Ver_asiento: true,
  Cheque: true,
  Cheques_pf: true,
  Transferencias: true,
  Digitalizar: true,
  Otros: true,
  Proveedores: true,
}
