import React, { useCallback, useEffect } from 'react'
import { DocumentInfo, ToastTypes } from '../../../../../../store/types'
import { TabState } from '../../../../../../store/genericTabTypes'
import { AsigmentTransaction, AsigmentType, Asignacion, Cellphons, Contactos, DescuentosDatosEdicion, EstudioDatosEdicion, ExtrasDatosEdicion, GenerosCompletos, LoginDatosEdicion, PersonalDatosEdicion, PersonalDto, PersonalesDatosEdicion, RentasDatosEdicion, RolesPagoDatosEdicion, SavePersonal, SeleccionadoDescuento } from '../../types/types'
import TabsEdition from './components/tabsEdition'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/store'
import { StatesEdition } from '../../../../../../store/enums'
import { clearButtonClick, setButtons, setCurrentExecutingAction, setNameTab } from '../../store/tabsReducer'
import { setChangeLoaderEdit, setDatosEdicion } from '../../store/editDataReducer'
import { consoleService } from '../../../../../../services/console.service'
import { tipoIdentificacionService } from '../../../../../componentes/tipoIdentificacion/service/tipoId.service'
import { TipoTransaccion } from '../../../../../componentes/tipoIdentificacion/store/types'
import { DateUtils } from '../../../../../../helpers/dateUtils'
import BlockUi from '../../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../../views/componentes/loadingindicator/loadingindicator'
import { addToast } from '../../../../../../store/toasterReducer'
import { estadosService } from '../../../../../componentes/estadosCivilLookUp/service/estados.service'
import { tipoSangreService } from '../../../../../componentes/tipoSangre/services/tipoSangre.service'
import { tipoEstadoService } from '../../../../../componentes/tipoEstado/service/tipoEstado.service'
import { estudiosService } from '../../../../../componentes/nivelesEstudioLookUp/service/estudios.service'
import { descuentosGroup } from './components/normas/descuentos'
import { localesService } from '../../../../../componentes/localesLookUp/service/locales.service'
import { ValidationGroup, ValidationSummary } from 'devextreme-react'
import { isMobile } from 'react-device-detect'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../views/componentes/colContainer'
import { getIndexProvider } from '../../../../../shared/helpers/funciones'
import { MessagesKeys, lh } from '../../../../../../helpers/localizationHelper'
import VisualizaError from '../../../../../ventas/pages/shared/visualizaError/visualizaError'
import ListErrosValidationForm from '../../../../../componentes/listadoErroresValidacionForm'
import { cuentaService } from '../../../../../../services/cuenta.service'
import { asignacionesService } from '../../../../../contabilidad/pages/asignaciones/services/asignaciones.service'
import { PersonalServices } from '../../services/personal.service'
import { ModuleButtons } from '../..'
import { localidadesService } from '../../../../../../services/localidades.service'
import { OptionCiudad } from '../../../../../../containers/component/formLocal/types/types'
import { generosGruposPersonal } from './components/personales'
import { setDatosEdicionBackup } from '../../store/configReducer'
import { ReportWithLocalDataModal } from '../../../../../../views/componentes/xtrareports/ReportWithLocalDataModal'


interface INewProps extends React.PropsWithChildren {
  info: DocumentInfo<PersonalDatosEdicion>
  tab: TabState<PersonalDatosEdicion>
  tabId: string
}

const Nuevo: React.FunctionComponent<INewProps> = (props) => {
  const { info, tab, tabId } = props
  const dispatch = useDispatch()
  const validationPersonal = React.useRef<any>()
  const tabs = useSelector((state: RootState) => state.nomina.personal.tabs)
  const localSession = useSelector((state: RootState) => state.global.session.local)
  const loader = useSelector((state: RootState) => state.nomina.personal.editData[tabId].loader)
  const personalState = useSelector((state: RootState) => state.nomina.personal.editData[tabId])
  const personalStateBackup = useSelector((state: RootState) => state.nomina.personal.config[tabId])

  const [arrayErrors, setArrayErrors] = React.useState<Array<string>>([])
  const [showErrorPopup, setShowErrorPopup] = React.useState<boolean>(false)
  const [reporte, setReporte] = React.useState<null | "Viewer" | "Designer">(null);

  const loading = useSelector(
    (state: RootState) =>
      state.nomina.personal.editData[tabId].loading
  )

  const onLoader = React.useCallback((showLoader: boolean, text: string) => {
    dispatch(setChangeLoaderEdit({
      key: tabId,
      data: {
        show: showLoader,
        mensaje: text
      }
    }))
  }, [dispatch, tabId])

  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(
      addToast({
        title: 'Nómina - Personal',
        content: mensaje,
        type: tipo,
      }),
    )
  }, [dispatch])

  const onSetButtonAction = useCallback(
    (tipo: ButtonTypes | undefined) => {
      dispatch(
        setCurrentExecutingAction({
          tabKey: tabId,
          buttonType: tipo,
        }),
      )
    },
    [dispatch, tabId],
  )



  const modoNuevo = React.useCallback(async (template: number, limpiar: boolean = false) => {
    if (loading === false && !limpiar) {
      return
    }
    onSetButtonAction(ButtonTypes.new)
    onLoader(true, 'Cargando...')

    try {
      const data = { ...defaultPersonalData }


      const dataPersonales: PersonalesDatosEdicion = { ...data.personales }
      const dataEstudios: EstudioDatosEdicion = { ...data.estudios }
      const dataExtras: ExtrasDatosEdicion = { ...data.extras }
      const dataLogin: LoginDatosEdicion = { ...data.login }
      const dataRentas: RentasDatosEdicion = { ...data.rentas }
      const dataRolesPago: RolesPagoDatosEdicion = { ...data.nomina.rolesPago }
      const dataDescuentos: DescuentosDatosEdicion = { ...data.nomina.descuentos }


      const dataId = await tipoIdentificacionService.getTipoIdetificacion(TipoTransaccion.compra, localSession?.ciudad?.paisCodigo ?? 1)
      const dataEstadoCivil = await estadosService.getEstados("Elija un estado")
      const dataTipoSangre = await tipoSangreService.getTipoSangre('Elija tipo')
      const dataEstado = await tipoEstadoService.getTipoEstado('Elija un estado')
      const dataNivelEstudios = await estudiosService.getEstados("Elija una opción")
      const dataLocales = await localesService.getLocales('Elija una opción')

      if (dataId['auto'] && dataId['error'] === false) {
        dataPersonales.tipoId = dataId['auto'][0]
      }

      if (dataEstadoCivil['auto'] && dataEstadoCivil['error'] === false) {
        dataPersonales.estadoCivil = dataEstadoCivil['auto'][0]
      }

      if (dataTipoSangre['auto'] && dataTipoSangre['error'] === false) {
        dataPersonales.tipoSangre = dataTipoSangre['auto'][0]
      }

      if (dataEstado['auto'] && dataEstado['error'] === false) {
        dataPersonales.estado = dataEstado['auto'][1]
      }

      dataPersonales.fechaNacimiento = DateUtils.getCurrentDateAsString()
      dataPersonales.genero = generosGruposPersonal[0]

      dataLogin.creado = DateUtils.getCurrentDateAsString()

      dataRentas.anio = parseInt(DateUtils.getCurrentDateAsString('dd/MM/yyyy').substring(6.4))
      if (dataNivelEstudios['auto'] && dataNivelEstudios['error'] === false) {
        dataEstudios.estudios = dataNivelEstudios['auto'][0]
      }

      dataDescuentos.radioOption = descuentosGroup[0]

      if (dataLocales['auto'] && dataLocales['error'] === false) {
        const provider = dataLocales['auto'].slice(0)
        const index = await getIndexProvider(provider, 'codigo', localSession?.codigo ?? -1)
        if (index > -1) {
          dataLogin.lugarCC = provider[index]
        } else {
          dataLogin.lugarCC = provider[0]
        }
      }

      data.personales = dataPersonales
      data.estudios = dataEstudios
      data.extras = dataExtras
      data.login = dataLogin
      data.rentas = dataRentas
      data.nomina = {
        rolesPago: dataRolesPago,
        descuentos: dataDescuentos
      }
      data.loading = false
      dispatch(setDatosEdicion({
        key: tabId,
        data: { ...data }
      }))
      dispatch(setDatosEdicionBackup({
        key: tabId,
        data: { ...data }
      }))
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }

    onLoader(false, '')
    onSetButtonAction(undefined)
  }, [loading, onLoader, onSetButtonAction, dispatch, tabId, setToast, localSession])



  const cargarPersonal = React.useCallback(async (loader: boolean, personalData) => {
    if (loader === false) {
      return
    }

    dispatch(
      setButtons({
        tabKey: tabId,
        buttons: {
          ...ModuleButtons,
          Imprimir: true,
        }
      }),
    )

    onSetButtonAction(ButtonTypes.edit)
    onLoader(true, 'Cargando Persona...')
    try {
      const dataEdit = { ...personalState }

      const dataPersona = personalData?.personales
      consoleService.log(dataPersona, '_______dataPersona')

      const dataPersonales: PersonalesDatosEdicion = { ...dataEdit.personales }
      const dataEstudios: EstudioDatosEdicion = { ...dataEdit.estudios }
      const dataExtras: ExtrasDatosEdicion = { ...dataEdit.extras }
      const dataLogin: LoginDatosEdicion = { ...dataEdit.login }
      const dataRentas: RentasDatosEdicion = { ...dataEdit.rentas }
      const dataRolesPago: RolesPagoDatosEdicion = { ...dataEdit.nomina.rolesPago }
      const dataDescuentos: DescuentosDatosEdicion = { ...dataEdit.nomina.descuentos }

      const dataId = await tipoIdentificacionService.getTipoIdetificacion(TipoTransaccion.compra, localSession?.ciudad?.paisCodigo ?? 1)
      const dataEstadoCivil = await estadosService.getEstados("Elija un estado")
      const dataTipoSangre = await tipoSangreService.getTipoSangre('Elija tipo')
      const dataEstado = await tipoEstadoService.getTipoEstado('Elija un estado')
      const dataNivelEstudios = await estudiosService.getEstados("Elija una opción")
      const dataLocales = await localesService.getLocales('Elija una opción')
      const dataCiudades = await localidadesService.getDpCiudades(localSession?.ciudad?.provinciaCodigo ?? 1, "Elija una opción");
      dataEdit.codigo = dataPersona?.codigo ?? 0
      // Tab Personales

      if (dataId['auto'] && dataId['error'] === false) {
        const provider = dataId['auto'].slice(0)
        const index = await getIndexProvider(provider, 'codigo', dataPersona?.ideCodigo ?? "-1")
        if (index > -1) {
          dataPersonales.tipoId = provider[index]
        } else {
          dataPersonales.tipoId = provider[0]
        }
      }

      if (dataEstadoCivil['auto'] && dataEstadoCivil['error'] === false) {
        const provider = dataEstadoCivil['auto'].slice(0)
        const index = await getIndexProvider(provider, 'codigo', dataPersona?.ecivil ?? 0)
        if (index > -1) {
          dataPersonales.estadoCivil = provider[index]
        } else {
          dataPersonales.estadoCivil = provider[0]
        }
      }

      if (dataTipoSangre['auto'] && dataTipoSangre['error'] === false) {
        const provider = dataTipoSangre['auto'].slice(0)
        const index = await getIndexProvider(provider, 'descripcion', dataPersona?.tipoSangre ?? '')
        if (index > -1) {
          dataPersonales.tipoSangre = provider[index]
        } else {
          dataPersonales.tipoSangre = provider[0]
        }
      }

      if (dataEstado['auto'] && dataEstado['error'] === false) {
        const provider = dataEstado['auto'].slice(0)
        const index = await getIndexProvider(provider, 'codigo', dataPersona?.estado ?? 0)
        if (index > -1) {
          dataPersonales.estado = provider[index]
        } else {
          dataPersonales.estado = provider[0]
        }
      }

      if (generosGruposPersonal.length > 0) {
        const provider = generosGruposPersonal.slice(0)
        const index = await getIndexProvider(provider, 'value', dataPersona?.genero ?? 0)
        if (index > -1) {
          dataPersonales.genero = provider[index]
        } else {
          dataPersonales.genero = provider[0]
        }
      }


      const ciudades = [] as Array<OptionCiudad>;
      if (dataCiudades && dataCiudades.length > 0) {
        for (const item of dataCiudades) {
          ciudades.push({
            codigoPais: Number(item?.paisCodigo) ?? 0,
            nombrePais: String(item?.paisNombre) ?? "",
            codigoProvincia: Number(item?.provinciaCodigo) ?? 0,
            nombreProvincia: String(item?.provinciaNombre) ?? "",
            codigo: Number(item?.codigo) ?? 0,
            nombre: String(item?.nombre) ?? "",
          })
        }
      }

      if (ciudades.length > 0) {
        const provider = ciudades.slice(0)
        const index = await getIndexProvider(provider, 'codigo', dataPersona?.ciuCodigo ?? 0)
        if (index > -1) {
          dataPersonales.ciudadReferencia = provider[index]
        } else {
          dataPersonales.ciudadReferencia = provider[0]
        }
      }

      if (ciudades.length > 0) {
        const provider = ciudades.slice(0)
        const index = await getIndexProvider(provider, 'codigo', dataPersona?.ciuOrigen ?? 0)
        if (index > -1) {
          dataPersonales.ciudadOrigen = provider[index]
        } else {
          dataPersonales.ciudadOrigen = provider[0]
        }
      }


      dataPersonales.cedula = dataPersona?.cedula ?? ''
      dataPersonales.nombres = dataPersona?.nombres ?? ''
      dataPersonales.apellidos = dataPersona?.apellidos ?? ''
      dataPersonales.codigoIESS = dataPersona?.codigoIess ?? ''
      dataPersonales.direccion = dataPersona?.direccion ?? ''
      dataPersonales.numero = dataPersona?.numero ?? ''
      dataPersonales.fechaNacimiento = DateUtils.format(dataPersona?.fnacimiento, 'yyyy-MM-dd', 'dd/MM/yyyy')
      dataPersonales.email = dataPersona?.email ?? ''

      const telefonosUsuario = await PersonalServices.getPhones(dataPersona?.codigo ?? 0)
      consoleService.log(telefonosUsuario, '_______________telefonosUsuario')
      if (telefonosUsuario['auto'] && telefonosUsuario['error'] === false) {
        dataPersonales.telefonos = telefonosUsuario['auto']
      }

      // Tab rentas
      dataRentas.anio = parseInt(DateUtils.getCurrentDateAsString('dd/MM/yyyy').substring(6.4))

      // Tab estudios
      if (dataNivelEstudios['auto'] && dataNivelEstudios['error'] === false) {
        const provider = dataNivelEstudios['auto'].slice(0)
        const index = await getIndexProvider(provider, 'codigo', dataPersona?.nivelCodigo ?? 0)
        if (index > -1) {
          dataEstudios.estudios = provider[index]
        } else {
          dataEstudios.estudios = provider[0]
        }
      }
      dataEstudios.titulos = dataPersona?.titulos ?? ''
      dataEstudios.cursos = dataPersona?.cursos ?? ''
      // Tab extras
      dataExtras.firma = dataPersona?.firma ?? ''
      dataExtras.fotos = dataPersona?.foto ?? ''
      // Tab login
      if (dataLocales['auto'] && dataLocales['error'] === false) {
        const provider = dataLocales['auto'].slice(0)
        const index = await getIndexProvider(provider, 'codigo', localSession?.codigo ?? -1)
        if (index > -1) {
          dataLogin.lugarCC = provider[index]
        } else {
          dataLogin.lugarCC = provider[0]
        }
      }

      dataLogin.creado = DateUtils.format(dataPersona?.fcreacion, 'yyyy-MM-dd', 'dd/MM/yyyy')
      dataLogin.usuario = dataPersona?.idsesion ?? ''
      dataLogin.codigoPase = dataPersona?.pase ?? ''
      dataLogin.password = dataPersona?.password ?? ''
      dataLogin.confirmarPassword = dataPersona?.password ?? ''

      // Cargando asignaciones si las tiene
      dataDescuentos.radioOption = descuentosGroup[0]
      const allNomina = await PersonalServices.getAllAssigmentNomina(dataPersona?.codigo ?? 0, AsigmentType.empleado)
      consoleService.log(allNomina, '_____allNomina')
      if (allNomina['auto'] && allNomina['error'] === false) {
        const asignacion = allNomina['auto'].slice(0)
        asignacion.forEach(asig => {
          if (asig?.tipo === AsigmentType.empleado && asig?.item === dataPersona?.codigo) {
            const index = AsigmentTransaction.indexOf(asig?.transaccion.toLowerCase())
            if (index > -1) {
              dataRolesPago[AsigmentTransaction[index]] = `${asig?.planNumero ?? ''} ${asig?.planDescripcion ?? ''}`
            }
          }
        })

      }

      const objTypoDescuento: any = {
        estado: 1
      }
      const tipoDescuento = await asignacionesService.getTypeDescount(objTypoDescuento)
      consoleService.log(tipoDescuento, '_____tipoDescuento')
      if (tipoDescuento && tipoDescuento.length > 0) {

        const dpDescuentoNomina: Array<SeleccionadoDescuento> = []
        tipoDescuento.map(x => {
          dpDescuentoNomina.push({
            item: x?.codigo ?? 0,
            descripcion: x?.descripcion ?? '',
            transaccion: 'DES NOMINA E',
            tipo: dataPersona?.codigo ?? 0,
          })
        })
        dataDescuentos.descuentoNomina = dpDescuentoNomina
        const dataAsignacion: any = {
          transaccion: "DES NOMINA E",
          tipo: dataPersona?.codigo,
        }
        const datasig = await asignacionesService.obtenerAsignacionesDescuentoNomina(dataAsignacion)
        consoleService.log(datasig, '________________datasig')
        if (datasig && datasig.length > 0) {
          dpDescuentoNomina.forEach((x, i) => {
            datasig.forEach(j => {
              if (x?.item === j?.item) {
                dpDescuentoNomina[i] = {
                  item: x?.item ?? 0,
                  descripcion: x?.descripcion ?? '',
                  tipo: parseInt(j?.tipo) ?? 0,
                  codigo: j?.codigoCuentaContable ?? 0,
                  planNumero: j?.numeroPlanCuentas ?? '',
                  planDescripcion: j?.descripcion ?? '',
                  transaccion: x?.transaccion ?? '',
                  enUso: x?.enUso ?? 0,
                }
              }
            })
          })
          dataDescuentos.descuentoNomina = dpDescuentoNomina
        }
      }

      const renta = await PersonalServices.getRentaPersonal(dataPersona?.codigo ?? 0)
      consoleService.log(renta, '_____dataRentas')
      if (renta['auto'] && renta['error'] === false) {
        dataRentas.rentas = renta['auto']
      }

      dataEdit.personales = dataPersonales
      dataEdit.estudios = dataEstudios
      dataEdit.extras = dataExtras
      dataEdit.login = dataLogin
      dataEdit.rentas = dataRentas
      dataEdit.nomina = {
        rolesPago: dataRolesPago,
        descuentos: dataDescuentos
      }
      dataEdit.loading = false
      dispatch(setDatosEdicion({
        key: tabId,
        data: { ...dataEdit }
      }))
      dispatch(setDatosEdicionBackup({
        key: tabId,
        data: { ...dataEdit }
      }))
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }

    onLoader(false, '')
    onSetButtonAction(undefined)
  }, [onSetButtonAction, onLoader, setToast, personalState, dispatch, tabId, localSession])

  const onValidate = React.useCallback(() => {
    const errors: Array<string> = []
    if (personalState.personales?.tipoId === null || personalState.personales?.tipoId === undefined || parseInt(personalState.personales?.tipoId.codigo) <= 0) {
      errors.push('Personales - Tipo de Identificacion: elija un tipo.')
    }
    if (personalState.personales?.cedula === null || personalState.personales?.cedula === undefined || personalState.personales?.cedula === "") {
      errors.push('Personales - Cédula: ingrese la identificación.')
    }
    if (personalState.personales?.nombres === null || personalState.personales?.nombres === undefined || personalState.personales?.nombres === "") {
      errors.push('Personales - Nombres: ingrese la información.')
    }
    if (personalState.personales?.apellidos === null || personalState.personales?.apellidos === undefined || personalState.personales?.apellidos === "") {
      errors.push('Personales - Apellidos: ingrese la información.')
    }
    if (personalState.personales?.telefonos === null || personalState.personales?.telefonos === undefined || personalState.personales?.telefonos.length === 0) {
      errors.push('Personales - Teléfono: ingrese al menos un teléfono.')
    }
    if (personalState.personales?.fechaNacimiento === null || personalState.personales?.fechaNacimiento === undefined || personalState.personales?.fechaNacimiento === '') {
      errors.push('Personales - Fecha de Nacimiento: ingrese la fecha de nacimiento del usuario.')
    }
    if (personalState.personales?.estadoCivil === null || personalState.personales?.estadoCivil === undefined || personalState.personales?.estadoCivil.codigo <= 0) {
      errors.push('Personales - Estado civil: elija un estado.')
    }
    if (personalState.estudios?.estudios === null || personalState.estudios?.estudios === undefined || personalState.estudios?.estudios.codigo <= 0) {
      errors.push('Estudios - Estudios: elija un nivel.')
    }
    if (personalState.login?.usuario === null || personalState.login?.usuario === undefined || personalState.login?.usuario === '') {
      errors.push('Login - Usuario: ingrese la clave unica de identificación.')
    }
    if (personalState.login?.password === null || personalState.login?.password === undefined || personalState.login?.password === '') {
      errors.push('Login - Password: ingrese la password que se utilizará para su verificación.')
    }
    if (personalState.login?.confirmarPassword === null || personalState.login?.confirmarPassword === undefined || personalState.login?.confirmarPassword === '') {
      errors.push('Login - Confirmación: ingrese la password de confirmación que se utilizará para su verificación.')
    } else if (personalState.login?.confirmarPassword !== personalState.login?.password) {
      errors.push('Login - Confirmación: el password escrito no es igual a la confirmación.')
    }

    if (personalState.login?.codigoPase === null || personalState.login?.codigoPase === undefined || personalState.login?.codigoPase === '') {
      errors.push('Login - Codigo de Pase: ingrese un codigo de pase.')
    }
    if (personalState.personales?.tipoSangre === null || personalState.personales?.tipoSangre === undefined || personalState.personales?.tipoSangre.codigo <= 0) {
      errors.push('Personales - Tipo de Sangre: ingrese el tipo de sangre.')
    }
    if (personalState.personales?.ciudadReferencia === null || personalState.personales?.ciudadReferencia === undefined || personalState.personales?.ciudadReferencia.codigo < 0) {
      errors.push('Personales - Lugar de Residencia: Elija un Lugar de Residencia.')
    }
    if (personalState.login?.lugarCC === null || personalState.login?.lugarCC === undefined || personalState.login?.lugarCC.codigo <= 0) {
      errors.push('Login - Local/CC: debe eligir una opción.')
    }

    if (personalState?.codigo === 0) {
      if (personalState.login?.tipoUsuario?.codigo <= 0) {
        errors.push('Login - Tipo de Usuario: Elija un tipo de usuario ya sea ADM | USU.')
      }
    }
    return errors
  }, [personalState])


  const onHandleSave = React.useCallback(async () => {
    let errors: Array<string> = []
    const result = validationPersonal.current.instance.validate()
    errors = onValidate()
    consoleService.log(errors)
    if (result.isValid && errors.length === 0) {
      consoleService.log(personalState)
      let telefonos: Array<Contactos> = []
      if (personalState.personales?.telefonos?.length > 0) {
        telefonos = personalState.personales?.telefonos.map(x => {
          const telefono: Contactos = {
            numero: x?.numero ?? '',
            descripcion: x?.descripcion ?? '',
            tipo: x?.tipo ?? 0,
          }
          return telefono
        })
      }

      const fechaNa = DateUtils.format(personalState.personales?.fechaNacimiento, 'dd/MM/yyyy', 'yyyy-MM-dd')

      const save_object: SavePersonal = {
        infoRegistro: {
          codigo: personalState?.codigo ?? 0,
          nombres: personalState.personales?.nombres ?? '',
          apellidos: personalState.personales?.apellidos ?? '',
          idsesion: personalState.login?.usuario ?? '',
          pase: personalState.login?.codigoPase ?? '',
          password: personalState.login?.password ?? '',
          cedula: personalState.personales?.cedula ?? '',
          codiess: personalState.personales?.codigoIESS ?? '',
          direccion: personalState.personales?.direccion ?? '',
          numero: personalState.personales?.numero ?? '',
          fnacimiento: fechaNa ?? '',
          email: personalState.personales?.email ?? '',
          firma: personalState.extras?.firma ?? '',
          ciudadCodigo: personalState.personales?.ciudadReferencia?.codigo ?? 0,
          ideCodigo: personalState.personales?.tipoId?.codigo ?? '',
          sexo: personalState.personales?.genero?.value ?? 0,
          ecivil: personalState.personales?.estadoCivil?.codigo ?? 0,
          cargas: personalState.personales.cargasFamiliares ?? 0,
          foto: personalState.extras?.fotos ?? '',
          nivelCodigo: personalState.estudios?.estudios?.codigo ?? 0,
          estado: personalState.personales?.estado?.codigo ?? 0,
          tiposangre: personalState.personales?.tipoSangre?.descripcion ?? '',
          titulos: personalState.estudios?.titulos ?? '',
          cursos: personalState.estudios?.cursos ?? '',
          ciudadOrigen: personalState.personales?.ciudadOrigen?.codigo ?? 0,
          contactos: telefonos
        },
        infoLocal: {
          codigo: personalState.login?.lugarCC?.codigo ?? 0
        }
      }

      consoleService.log(save_object)

      onLoader(true, 'Guardando...')
      onSetButtonAction(ButtonTypes.save)
      try {
        const dataSave = await cuentaService.usuarioActualizar(save_object)
        consoleService.log(dataSave)
        if (dataSave !== null && dataSave !== undefined && dataSave['error'] === false) {
          setToast(dataSave['message'], ToastTypes.Success)
          const asignaciones: Array<Asignacion> = []
          if (personalState.nomina?.descuentos.codigoDescuento !== 0) {
            asignaciones.push({
              codigo: 0,
              vinculacion: dataSave['auto'],
              tipo: "NOMINA EMPLEADO",
              planCuentasCodigo: personalState.nomina?.descuentos?.codigoDescuento ?? 0,
              transaccion: String("DESCUENTO NOMINA")
            })
          }

          // const asigData = await asignacionesService.registrarAsignacion(asignaciones)
          const asigNomina = await PersonalServices.saveAssigmentNomina(personalState.nomina?.descuentos.nomina, personalState.nomina?.descuentos.descuentoNomina)
          consoleService.log(asigNomina)
          dispatch(setNameTab({
            key: tabId,
            codigo: dataSave['auto'] ?? 0,
            nombre: personalState?.personales?.nombres ?? ''
          }))
          dispatch(
            setButtons({
              tabKey: tabId,
              buttons: {
                ...ModuleButtons,
                Guardar: false,
                Imprimir: true
              }
            }),
          )

        } else {
          setToast(dataSave['message'], ToastTypes.Danger)
        }
      } catch (error) {
        setToast(error.message, ToastTypes.Danger)
      }
      onSetButtonAction(undefined)
      onLoader(false, '')
    } else {
      setToast(lh.getMessage(MessagesKeys.GlobalErrorSave), ToastTypes.Warning)
      setArrayErrors(errors)
      setShowErrorPopup(true)
    }
  }, [onValidate, setToast, personalState, onSetButtonAction, onLoader, dispatch, tabId])

  const onUndo = React.useCallback(() => {
    dispatch(setDatosEdicion({
      key: tabId,
      data: { ...personalStateBackup }
    }))
  }, [personalStateBackup, dispatch, tabId])

  const handleButtonClick = useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.undo:
          if (tabs.current === tabId) onUndo()
          break
        case ButtonTypes.save:
          if (tabs.current === tabId) onHandleSave()
          break
        case ButtonTypes.print:
          if (tabs.current === tabId) setReporte('Viewer')
          break
        case ButtonTypes.print_design:
          if (tabs.current === tabId) setReporte('Designer')
          break
        default:
          break
      }
      dispatch(clearButtonClick(tabId))
    }, [dispatch, tabId, tabs, onHandleSave, onUndo])

  useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab.globalButtonClick])

  useEffect(() => {
    if (tab.editStatus === StatesEdition.new) {
      modoNuevo(tab?.info?.codigo)
    } else {
      cargarPersonal(loading, info.info)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onParseData = React.useCallback(() => {
    consoleService.log(personalState)
    const registros: Array<PersonalDto> = []
    let telefonos: Array<Cellphons> = []

    let telefono: Cellphons
    if (personalState?.personales?.telefonos.length > 0) {
      telefonos = personalState?.personales?.telefonos.map(x => {
        telefono = {
          Number: x?.numero ?? '',
          Description: x?.descripcion ?? '',
        }
        return telefono
      })
    }

    let genero = ''

    switch (personalState?.personales?.genero?.label) {
      case 'M':
        genero = GenerosCompletos.m
        break;

      case 'F':
        genero = GenerosCompletos.f
        break;

      default:
        genero = GenerosCompletos.n
        break;
    }
    const registro: PersonalDto = {
      Code: personalState?.codigo ?? 0,
      TipoId: personalState?.personales?.tipoId?.descripcion ?? '',
      Identification: personalState?.personales?.cedula ?? '',
      IESSCode: personalState?.personales?.codigoIESS ?? '',
      Firstnames: personalState?.personales?.nombres ?? '',
      Lastnames: personalState?.personales?.apellidos ?? '',
      Gender: genero,
      CivilStatus: personalState?.personales?.estadoCivil?.descripcion ?? '',
      FamilyResponsabilities: personalState?.personales?.cargasFamiliares ?? 0,
      BloodType: personalState?.personales?.tipoSangre?.descripcion ?? '',
      Status: personalState?.personales?.estado?.descripcion ?? '',
      PlaceResidence: personalState?.personales?.ciudadReferencia?.nombre ?? '',
      PlaceOrigin: personalState?.personales?.ciudadOrigen?.codigo > 0 ? personalState?.personales?.ciudadOrigen?.nombre : '',
      Address: personalState?.personales?.direccion ?? '',
      Email: personalState?.personales?.email ?? '',
      EducationLevel: personalState?.estudios?.estudios?.descripcion ?? '',
      DateCreation: personalState?.login?.creado ?? '',
      User: personalState?.login?.usuario ?? '',
      Cellphones: telefonos,
    }

    registros.push(registro)
    consoleService.log(registros)
    return registros
  }, [personalState])



  return (
    <>
      <ReportWithLocalDataModal
        show={reporte !== null}
        onClose={() => setReporte(null)}
        data={onParseData()}
        fileName='StaticPersonal'
        mode={reporte ?? 'Viewer'}
        parameters={{ 'Reporte_Filtro': "" }}
        template='StaticPersonal'
        key='reportDesigner'
      />
      {showErrorPopup && (
        <VisualizaError
          titulo="Error en Personal"
          mensaje={'Error al guardar'}
          content={
            <ListErrosValidationForm
              mainMessage={'Revise los siguientes errores:'}
              errorsList={arrayErrors}
              color={'danger'}
            />
          }
          onOk={() => setShowErrorPopup(false)}
        />
      )}
      <div style={{ overflowX: 'hidden' }}>
        <BlockUi
          tag="div"
          loader={LoadingIndicator}
          blocking={loader.show}
          message={loader.mensaje}
        >
          <ValidationGroup id={'formPersonal'} ref={validationPersonal}>
            {
              !isMobile && (
                <RowContainer>
                  <CustomCol lg="6" md="8" sm="12">
                    <ValidationSummary id="summary"></ValidationSummary>
                  </CustomCol>
                </RowContainer>
              )
            }
            <TabsEdition info={info} tab={tab} tabId={tabId} refForm={{}} />
            {
              isMobile && (
                <RowContainer>
                  <CustomCol lg="6" md="8" sm="12">
                    <ValidationSummary id="summary"></ValidationSummary>
                  </CustomCol>
                </RowContainer>
              )
            }
          </ValidationGroup>
        </BlockUi>
      </div>
    </>
  )
}

export default React.memo(Nuevo)


export const defaultPersonalData: PersonalDatosEdicion = {
  personales: {
    tipoId: null,
    cedula: '',
    codigoIESS: '',
    nombres: '',
    apellidos: '',
    fechaNacimiento: '',
    genero: null,
    estadoCivil: null,
    cargasFamiliares: 0,
    tipoSangre: null,
    estado: null,
    ciudadReferencia: null,
    ciudadOrigen: null,
    direccion: '',
    numero: '',
    email: '',
    telefonos: [],
  },
  estudios: {
    estudios: null,
    titulos: '',
    cursos: '',
  },
  extras: {
    fotos: '',
    fotosName: '',
    firma: '',
    firmaName: '',
    fotosFile: [],
    firmaFile: [],
  },
  login: {
    creado: '',
    usuario: '',
    password: '',
    confirmarPassword: '',
    codigoPase: '',
    codigoPaseImagen: '',
    lugarCC: null,
    tipoUsuario: null,
  },
  rentas: {
    anio: 0,
    codigoREM: 0,
    vivienda: 0,
    salud: 0,
    educacion: 0,
    alimentacion: 0,
    vestimenta: 0,
    discapacitados: 0,
    terceraEdad: 0,
    iRporEpleado: 0,
    iGEmpleadores: 0,
    dgpEmpleadores: 0,
    orcEmpleadores: 0,
    virEmpleadores: 0,
    numeroRetenciones: 0,
    virEmpleadoresAnteriores: 0,
    rentas: [],
    seleccionadoRenta: null
  },
  nomina: {
    rolesPago: {
      sueldo: '',
      ajustes: '',
      nocturno: '',
      suple: '',
      extra: '',
      comision: '',
      irenta: '',
      apersonal: '',
      bonificacion: '',
      bonificacionna: '',
      freserva: '',
      irentaxp: '',
      apersonalxp: '',
      freservaxp: '',
      quirografariosxp: '',
      hipotecariosxp: '',
      anticipos: '',
      descuentos: '',
      multas: '',
      prestamos: '',
      nominaxp: '',
      dtsueldo: '',
      pdtsueldoxp: '',
      dcsueldo: '',
      pcsueldoxp: '',
      apatronal: '',
      apatronalxp: '',
      vacacion: '',
      vacacionxp: '',
      descuentoNomina: '',
      rolesPago: [],
    },
    descuentos: {
      numeroDescuento: '',
      codigoDescuento: 0,
      descripcionDescuento: '',
      descuentoNomina: [],
      nomina: [],
      radioOption: null,
      seleccionadoDescuento: null
    },
  },
  codigo: 0,
  idSesion: '',
  ciudadCodigo: 0,
  cargas: 0,
  loader: {
    show: false,
    mensaje: ''
  },
  loading: false,
}