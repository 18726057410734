import React, { memo } from 'react'
import { ValidationGroup } from 'devextreme-react/validation-group'
import CustomCol from '../../../../../views/componentes/colContainer'
import RowContainer from '../../../../../views/componentes/rowContainer/rowContainer'
import Labeled from '../../../../../views/componentes/labeledInput/labeledInput'
import TextBox from 'devextreme-react/text-box'
import SelectBox from '../../../../../views/componentes/selectBox/selectBox'
import { TipoIdentificacion } from '../../../../ventas/types/types'
import ResultadoBusqueda from './resultadoBusqueda'
import { CButton, CCard, CCardBody, CCardHeader, CCol, CCollapse, CLink, CRow, CTooltip } from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilChevronBottom, cilChevronTop } from '@coreui/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons'
import LocalesLookUp from '../../../localesLookUp'
import { ToastTypes, local } from '../../../../../store/types'
import { LocalOption } from '../../../localesLookUp/store/type'
import { setData } from '../../../modalAnticipos/store/modalAnticiposReducer'
import { VendedorBusqueda } from '../../../../shared/components/buscarVendedorLookMemoUp/type/types'
import { TIPOS_BOTONES } from '../../store/types'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../store/store'
import { getLocales } from '../../../localesLookUp/helper/helper'

interface TBuscarItemsProps extends React.PropsWithChildren {
  onChanged: (data) => void;
  onEdit: (data) => void;
  onCancel?: () => void;
  funcion: string,
  resetFunction: () => void,
  ejecutarBusqueda: () => void
  seleccion: VendedorBusqueda
  currentTab: string
  setToast: (texto: string, type: ToastTypes) => void
}

type infoPaginationType = {
  desde: number,
  hasta: number,
  paginaActual: number,
  totalPaginas: number,
  totalRegistros: number
}

type TFindVendedores = {
  local: LocalOption | null,
  nombres: string,
  identificacion: string
}

const infoPaginationInit = {
  desde: 1,
  hasta: 10,
  paginaActual: 0,
  totalPaginas: 0,
  totalRegistros: 0
}

const initDataFind = {
  local: null,
  nombres: '',
  identificacion: ''
}

const Buscar = (props: TBuscarItemsProps) => {

  const { setToast, ejecutarBusqueda, currentTab, funcion, seleccion, resetFunction, onChanged, onEdit } = props;

  const formSearchRef = React.useRef<any>()
  const localState = useSelector((state: RootState) => { return state.global.session?.local });

  const [collapsed, setCollapsed] = React.useState(true)
  const [dataSearch, setDataSearch] = React.useState<TFindVendedores>(initDataFind);
  const [infoPagination, setInfoPagination] = React.useState<infoPaginationType>(infoPaginationInit);
  const [items, setItems] = React.useState<any>([]);

  const onLoad = React.useCallback(async () => {
    const data: TFindVendedores = dataSearch;
    const optLocales = await getLocales('Elija una opción');
    if (optLocales.length > 0) {
      let locaSelect = optLocales[0] ?? null;
      const locaFind = optLocales.find((opt) => {
        return opt.codigo === Number(localState.codigo)
      })
      if (locaFind !== undefined) {
        locaSelect = locaFind
      }
      data.local = (locaSelect ?? null)
    }
  }, [dataSearch, localState]);

  React.useEffect(() => {
    if (currentTab === TIPOS_BOTONES.BOTON_BUSCAR) {
      onLoad()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <fieldset>
        <ValidationGroup ref={formSearchRef}>
          <CCard>
            <CCardHeader onClick={() => { }} className='d-flex justify-content-between'>
              <strong> {'Buscar'} </strong>
              <div className='card-header-actions'>
                <CLink className='card-header-action'>
                  <CIcon icon={collapsed ? cilChevronBottom : cilChevronTop} />
                </CLink>
              </div>
            </CCardHeader>

            <CCardBody>

              <RowContainer>
                <CustomCol xs="12" md="4">
                  <Labeled label="Locales">
                    <LocalesLookUp
                      onChanged={(data) => {
                        setDataSearch({
                          ...dataSearch,
                          local: data
                        })
                      }}
                      selected={dataSearch.local ?? null}
                    />
                  </Labeled>
                </CustomCol>
              </RowContainer>

              <RowContainer>
                <CustomCol xs="12" md="5">
                  <Labeled label="Nombres">

                    <TextBox
                      name="nombres"
                      value={dataSearch.nombres ?? ''}
                      onValueChanged={({ value }: any) =>
                        setDataSearch({
                          ...dataSearch,
                          nombres: value
                        })
                      }
                      onEnterKey={() => ejecutarBusqueda()}
                    />

                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="6">
                  <Labeled label="Identificación">
                    <TextBox
                      name="identificacion"
                      value={dataSearch.identificacion ?? ''}
                      onValueChanged={({ value }: any) => {
                        setDataSearch({
                          ...dataSearch,
                          identificacion: value
                        })
                      }}
                      onEnterKey={() => ejecutarBusqueda()}
                    />
                  </Labeled>
                </CustomCol>
              </RowContainer>
            </CCardBody>

          </CCard>

          <RowContainer>
            <CustomCol>
              <ResultadoBusqueda
                funcion={funcion}
                name="tableItems"
                data={items}
                filaSeleccionada={seleccion}
                pagination={infoPagination}
                dataSearch={dataSearch}
                resetFunction={() => {
                  resetFunction()
                }}
                setDataItems={(data: any) => { setItems(data) }}
                onChanged={onChanged}
                onEdit={onEdit}
                setToast={setToast}
              />
            </CustomCol>
          </RowContainer>

        </ValidationGroup>
      </fieldset>
    </>
  )
}
export default memo(Buscar)
