import React from 'react'
import FileUploader from 'devextreme-react/file-uploader'
import {CLink} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilVerticalAlignBottom, cilX } from '@coreui/icons'
import config from '../../../config/config'

export interface InputfileProps extends React.PropsWithChildren {
  showFileList?: boolean
  multiple?: boolean
  accept: string
  onChanged: any
  uploadMode?: any
  maxFileSize?: number
  allowedFileExtensions?: Array<string>
  downDemo?: () => void
  showDownloadDemo?: boolean
  resetFile?: boolean
}

const FIleInput = (props: InputfileProps) => {
  const [fileName, setFileName] = React.useState<string>('')
  const [showFileList, setshowFileList] = React.useState<boolean>(
    props.showFileList ? props.showFileList : true,
  )
  const fileToUpload = React.useRef<any>()

  const onValueChanged = React.useCallback(
    (evt) => {
      let fileName = ''
      if (evt !== null && evt.value.length > 0) {
        setshowFileList(true)
        fileName = evt.value[0].name
      }
      setFileName(fileName)
      props.onChanged(evt)
    },
    [props],
  )

  const onResetHandle = React.useCallback(() => {
    fileToUpload.current.instance.reset()
    setshowFileList(false)
    onValueChanged(null)
  }, [onValueChanged])

  React.useEffect(() => {
    if (props.resetFile) onResetHandle()
  }, [props.resetFile, onResetHandle])

  return (
    <div className="fileuploader-container">
      <FileUploader
        showFileList={showFileList}
        multiple={props.multiple ? props.multiple : false}
        accept={props.accept}
        onValueChanged={onValueChanged}
        uploadMode={props.uploadMode ? props.uploadMode : 'useForm'}
        maxFileSize={
          props.maxFileSize
            ? props.maxFileSize
            : config['uploadTemplates'].maxFileSize
        }
        allowedFileExtensions={
          props.allowedFileExtensions
            ? props.allowedFileExtensions
            : config['uploadTemplates'].allowedFileExtensions
        }
        ref={fileToUpload}
      />
      {fileName === '' ? (
        <b style={{ cursor: 'default' }}>Ningún archivo seleccionado.</b>
      ) : (
        <>
          <CIcon
            icon={cilX}
            className="ms-1 me-1"
            onClick={() => onResetHandle()}
            style={{ cursor: 'pointer' }}
          />
          <CLink
            className="float-right text-right c-subheader-nav-link"
            onClick={() => onResetHandle()}
            style={{ marginRight: '2px', cursor: 'pointer', width: 'auto' }}
          >
            {'Reset Archivo'}
          </CLink>
        </>
      )}
      {props.showDownloadDemo === false ? (
        <></>
      ) : (
        <div style={{ display: 'flex', marginTop: '4px' }}>
          <CIcon
            icon={cilVerticalAlignBottom}
            className="ms-1 me-1"
            onClick={() => props.downDemo()}
          />
          <CLink
            className="float-right text-right c-subheader-nav-link"
            onClick={() => props.downDemo()}
            style={{ marginRight: '2px', cursor: 'pointer', width: 'auto' }}
          >
            {'ejemplo.xls'}
          </CLink>
        </div>
      )}
    </div>
  )
}
export default FIleInput
