/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CAlert, CCard, CCardBody, CCol, CRow } from '@coreui/react-pro'
import { ValidationGroup, ValidationSummary, Validator } from 'devextreme-react'
import { RequiredRule, StringLengthRule } from 'devextreme-react/data-grid'

import Dialog from '../../../../../views/componentes/librerias/bootstrap-dialog'
import BlockUi from '../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../views/componentes/loadingindicator/loadingindicator'
import { TabState } from '../../../../../store/genericTabTypes'
import {
  CustomButtons,
  DocumentInfo,
  ToastTypes,
} from '../../../../../store/types'
import { RootState } from '../../../../../store/store'
import { CiudadesDatosEdicion, TCiudadesData } from '../store/types'
import { ButtonTypes } from '../../../../../views/componentes/globalMenu/types'
import { addToast } from '../../../../../store/toasterReducer'
import { PopupContent } from '../../../../../views/componentes/popupContent'
import {
  clearButtonClick,
  setButtons,
  setCurrentExecutingAction,
  setNameTab,
} from '../store/tabs.reducer'
import { StatesEdition } from '../../../../../store/enums'
import { setDatosEdicion } from '../store/edit.reducer'
import InputTextDE, {InputNumberDE} from '../../../../../views/componentes/inputText-DE/inputText-DE'
import { CiudadesService } from '../services/ciudades.services'
import SelectBox from "devextreme-react/select-box";
import {OptionPais, OptionProvincia} from "../../../../../containers/component/formLocal/types/types";
import {localidadesService} from "../../../../../services/localidades.service";

interface IMCiudadesProps extends React.PropsWithChildren {
  info: DocumentInfo<TCiudadesData>
  tabId: string
  tab: TabState<TCiudadesData>
}

export type TAtributos = {
  valor: string
  atributo: string
  idAtributo: number
  idAtributoValor: number
  nueva: boolean
}

const MantenimientoCiudades: React.FunctionComponent<IMCiudadesProps> = (
  props,
) => {
  const { info, tabId, tab } = props

  const dialogRef = React.useRef<any>(null)
  const validationGroupRef = React.useRef<any>()

  const tabs = useSelector(
    (state: RootState) => state.administracion.catalagos.ciudades.tabs,
  )

  const empresaState = useSelector(
    (state: RootState) => state.global.session?.empresa,
  )

  const usuarioState = useSelector(
    (state: RootState) => state.global.session?.usuario,
  )

  const ingresoState = useSelector(
    (state: RootState) => state.global.session?.ingreso,
  )

  const dataEdicion = useSelector(
    (state: RootState) =>
      state.administracion.catalagos.ciudades.editData[tabId],
  )

  const loading = useSelector(
    (state: RootState) =>
      state.administracion.catalagos.ciudades.editData[tabId].loading,
  )

  const localState = useSelector(
    (state: RootState) => state.global.session?.local,
  )

  const sessionStatus = useSelector((state: RootState) => state.global.session)

  const dispatch = useDispatch()

  const [showErrorPopup, setShowErrorPopup] = React.useState<boolean>(false)
  const [showErrorMessages, setShowErrorMessages] = React.useState<Array<any>>(
    [],
  )
  const [loaderCiudades, setLoaderCiudades] = React.useState<{
    show: boolean
    message: string
  }>({ show: false, message: '' })
  const [datosEdicion2, setDatosEdicion2] = React.useState<any>([])

  const [dpPais, setDpPais] = React.useState<Array<OptionPais> | []>([])
  const [pais, setPais] = React.useState<OptionPais | null>(null)
  const [dpProvincia, setDpProvincia] = React.useState<Array<OptionProvincia> | []>([])
  const [provincia, setProvincia] = React.useState<OptionProvincia | null>(null)

  const setToast = React.useCallback(
    (texto, type: ToastTypes) => {
      let time: number = 3000
      if (texto.length < 50) {
        time = 5000
      } else if (texto.length > 50) {
        time = 9000
      }
      dispatch(
        addToast({
          id: '',
          autoHide: time,
          title: 'Acatha',
          content: texto,
          fade: true,
          type: type,
        }),
      )
    },
    [dispatch],
  )

  const parseApiCiudadData = React.useCallback(
    async (response: any): Promise<CiudadesDatosEdicion> => {
      return {
        edition: false,
        codigo: response?.codigo ?? 0,
        origen: response?.origen ?? 0,
        nombre: response?.nombre ?? '',
        provinciaCodigo: response?.provinciaCodigo ?? '',
        dep: response?.dep ?? '',
        inec: response?.inec ?? '',
        uaf: response?.uaf ?? '',
        codigoOrigen: response?.codigoOrigen ?? '',
        loader: {
          show: false,
          mensaje: '',
        },
        loading: false,
        tieneError: false,
        mensajeError: '',
      }
    },
    [],
  )

  const modoNuevo = React.useCallback(
    async (template: number, limpiar: boolean = false) => {
      if (loading === false && !limpiar) {
        return
      }
      await initDatos();
      const data = { ...defaultEditionCiudades }
      dispatch(
        setDatosEdicion({
          key: tabId,
          data: { ...data },
        }),
      )
    },
    [dispatch, tabId],
  )

  const modoEdicion = React.useCallback(
    async (reload: boolean = false, dataEdicion: TCiudadesData) => {
      setLoaderCiudades({ show: true, message: 'Espere, por favor...' })

      const paises: any = await initDatos();
      const provinces: OptionProvincia[] = paises.flatMap((pais: OptionPais) => pais.provincias);
      const province: OptionProvincia = provinces.find((item: OptionProvincia) => item.codigo === parseInt(dataEdicion.provinciaCodigo));
      const pais: OptionPais = paises.find((item: OptionPais) => item.codigo === province.codigoPais);

      console.log("withEdition", province);

      if (pais) {
        console.log("withEdition, pais", pais);
        await onChangePais(pais);
      }

      if (province) {
        console.log("withEdition, province", province);
        onChangeProvincia(province);
      }

      const dataProvinciasEdition = dataEdicion

      setDatosEdicion2(dataProvinciasEdition)

      try {
        let dataApi: any = []
        let infoParse: CiudadesDatosEdicion = {
          edition: false,
          codigo: 0,
          origen: 0,
          nombre: '',
          provinciaCodigo: '',
          dep: '',
          inec: '',
          uaf: '',
          codigoOrigen: '',
          loader: {
            show: false,
            mensaje: '',
          },
          loading: false,
          tieneError: false,
          mensajeError: '',
        }

        infoParse.edition = false

        dataApi = dataEdicion

        infoParse = await parseApiCiudadData(dataEdicion)

        if (reload) {
          dataApi = await CiudadesService.getCiudad(
            dataProvinciasEdition?.codigo ?? 0,
          )

          infoParse = await parseApiCiudadData(dataApi)
        }

        dispatch(
          setDatosEdicion({
            key: tabId,
            data: { ...infoParse },
          }),
        )

        setLoaderCiudades({ show: false, message: '' })
      } catch (error) {
        setLoaderCiudades({ show: false, message: JSON.stringify(error) })
      }
    },
    [dispatch, parseApiCiudadData],
  )

  const playLoader = React.useCallback(async () => {
    setLoaderCiudades({ show: true, message: 'Espere, por favor...' })
  }, [])

  const stopLoader = React.useCallback(async () => {
    setLoaderCiudades({ show: false, message: '' })
  }, [])

  const onSetButtonAction = useCallback(
    (tipo: ButtonTypes | undefined) => {
      dispatch(
        setCurrentExecutingAction({
          tabKey: tabId,
          buttonType: tipo,
        }),
      )
    },
    [dispatch, tabId],
  )

  const onErrorConfirm = React.useCallback((messages: any, tittle?: string) => {
    dialogRef.current.show({
      title: (
        <>
          <strong>{'Ácatha'}</strong>
        </>
      ),
      body: (
        <>
          <strong style={{ fontSize: '14px', margin: '0px', padding: '0px' }}>
            {tittle}
          </strong>
          <br />
          <CAlert color="danger">
            <ul className="errorList">
              {messages.map(function (item, id) {
                return <li key={id}>{item}</li>
              })}
            </ul>
          </CAlert>
        </>
      ),
      actions: [
        Dialog.Action(
          <span>
            <u>{'A'}</u>
            {'ceptar'}
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>{'C'}</u>
            {'ancelar'}
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    return
  }, [])

  const onChangeValue = React.useCallback(
    (data, key: string) => {
      dispatch(
        setDatosEdicion({
          key: tabId,
          data: {
            ...dataEdicion,
            [key]: data,
          },
        }),
      )
    },
    [dispatch, dataEdicion, tabId],
  )

  const handleSubmit = React.useCallback(async () => {
    const result = validationGroupRef.current.instance.validate()

    playLoader()

    if (result.isValid === false) {
      setToast('Tiene errores por favor verifíquelos.', ToastTypes.Info)
      setShowErrorPopup(true)
      setShowErrorMessages(result.brokenRules)
      stopLoader()
      onSetButtonAction(undefined)
      return
    }

    setShowErrorPopup(false)
    setShowErrorMessages([])
    onSetButtonAction(ButtonTypes.save)

    const dataCiudad: any = {
      codigo: dataEdicion?.codigo ?? 0,
      nombre: dataEdicion.nombre,
      codigoProvincia: parseInt(dataEdicion.provinciaCodigo),
      codigoRDep: dataEdicion.dep,
      codigoInec: dataEdicion.inec,
      codigoUaf: dataEdicion.uaf,
      codigoOrigen: String(dataEdicion?.codigoOrigen),
    }

    try {
      const data = await CiudadesService.saveCiudad(dataCiudad)

      stopLoader()

      if (data['error'] === true) {
        setToast(data['message'], ToastTypes.Warning)
        stopLoader()
        onSetButtonAction(undefined)
        dispatch(
          setButtons({
            tabKey: tabId,
            buttons: {
              ...ECiudadesButtons,
              Guardar: true,
              Imprimir: true,
            },
          }),
        )
        return
      }

      if (data['error'] === false) {
        setToast(data['message'], ToastTypes.Success)
        stopLoader()
        onSetButtonAction(undefined)
      }

      setDatosEdicion2(dataCiudad)
      if (dataCiudad.codigo === 0) {
        dispatch(
          setButtons({
            tabKey: tabId,
            buttons: {
              ...ECiudadesButtons,
              Deshacer: false,
              Guardar: false,
              Imprimir: true,
            },
          }),
        )
      }

      if (dataCiudad.codigo !== 0) {
        dispatch(
          setButtons({
            tabKey: tabId,
            buttons: {
              ...ECiudadesButtons,
              Deshacer: false,
              Guardar: false,
              Imprimir: true,
            },
          }),
        )
      }

      dispatch(
        setNameTab({
          key: tabId,
          nombre: dataCiudad?.nombre ?? '',
          codigo: data?.auto ?? 0,
        }),
      )
    } catch (error) {
      onSetButtonAction(undefined)
      dispatch(
        setButtons({
          tabKey: tabId,
          buttons: {
            ...ECiudadesButtons,
            Guardar: true,
            Imprimir: true,
          },
        }),
      )
      stopLoader()
      setToast(error, ToastTypes.Danger)
    }
  }, [
    dataEdicion,
    playLoader,
    setToast,
    stopLoader,
    dispatch,
    onErrorConfirm,
    modoEdicion,
    empresaState,
    ingresoState,
  ])

  const handleButtonClick = React.useCallback(
    async (buttonAction: ButtonTypes) => {
      //let ciudaddselect=null;
      switch (buttonAction) {
        case ButtonTypes.undo:
          // eslint-disable-next-line no-case-declarations
          dispatch(
            setDatosEdicion({
              key: tabId,
              data: { ...datosEdicion2 },
            }),
          )
          break
        case ButtonTypes.save:
          dispatch(
            setCurrentExecutingAction({
              tabKey: tabId,
              buttonType: ButtonTypes.save,
            }),
          )
          if (tabs.current === tabId) handleSubmit()
          break
        default:
          break
      }
      dispatch(clearButtonClick(tabId))
    },
    [tabId, tabs, info, modoNuevo, dispatch, modoEdicion, onSetButtonAction],
  )

  const llenarProvincias = React.useCallback(async (paisSeleccionado) => {
    const provincias = [] as Array<OptionProvincia>
    if (paisSeleccionado.provincias && paisSeleccionado.provincias.length > 0) {
      await paisSeleccionado.provincias.forEach((item: any) => {
        provincias.push({
          codigo: Number(item.codigo) ?? 0,
          codigoPais: Number(item.codigoPais) ?? 0,
          nombre: String(item.nombre) ?? '',
        })
      })
    }
    return provincias
  }, [])

  const cargarProvincias = React.useCallback(
    async (paisData: OptionPais) => {
      console.log("cargarProvincias", paisData);
      let dpProvincias = [] as Array<OptionProvincia>;
      setDpProvincia([])
      setProvincia(null)

      if (paisData.provincias && paisData.provincias.length > 0) {
        dpProvincias = await llenarProvincias(paisData)
      } else {
        dpProvincias = []
      }

      setDpProvincia(dpProvincias);
      console.log("cargarProvincias, seteado", dpProvincias);
    },
    [llenarProvincias],
  )

  const onChangeProvincia = React.useCallback(
    (item: OptionProvincia) => {
      console.log("onChangeProvincia", item);
      if (!item) {
        setProvincia(dpProvincia[0]);
        return
      }

      setProvincia(item);
      console.log("onChangeProvincia,seteado", item);
    },
    [dpProvincia],
  )

  const getPaises = React.useCallback(async () => {
    const paises = [] as Array<OptionPais>
    const data = await localidadesService.getDpPaises('Elija una opción')

    if (data && data.length > 0) {
      for (const item of data) {
        const provincias = [] as Array<OptionProvincia>
        if (item['provincias'] && item['provincias']?.length > 0) {
          for (const provItem of item.provincias) {
            provincias.push({
              codigoPais: Number(provItem.codigoPais) ?? 0,
              codigo: Number(provItem.codigo) ?? 0,
              nombre: String(provItem.nombre) ?? '',
            })
          }
        }
        paises.push({
          ...item,
          codigo: Number(item.codigo) ?? 0,
          descripcion: String(item.nombre) ?? '',
          nombre: String(item.nombre) ?? '',
          codigoUaf: String(item.codigoUaf) ?? '',
          provincias: provincias ?? [],
        })
      }
    }
    return paises
  }, [])

  const onChangePais = React.useCallback(async (item: OptionPais) => {
      if (!item) {
        setPais(dpPais[0]);
        await cargarProvincias(dpPais[0]);
        return;
      }

      setPais(item);
      await cargarProvincias(item);
    },
    [cargarProvincias, dpPais],
  )

  const initDatos = React.useCallback(
    async (reload: boolean = false) => {
      console.log("initDatos");
      if (localState?.codigo === 0) {
        return false
      }

      try {
        let paisesApi: Array<OptionPais> | [] = []
        paisesApi = await getPaises();
        setDpPais(paisesApi ?? []);
        return paisesApi;
      } catch (error) {
        return null
      }
    },
    [getPaises, localState],
  )

  const withEdicion = React.useCallback(async (provinciaCodigo: string) => {
    const provinces: OptionProvincia[] = dpPais.flatMap((pais: OptionPais) => pais.provincias);
    const province: OptionProvincia = provinces.find((item: OptionProvincia) => item.codigo === parseInt(provinciaCodigo));
    const pais: OptionPais = dpPais.find((item: OptionPais) => item.codigo === province.codigoPais);

    console.log("withEdition", province);

    if (pais) {
      console.log("withEdition, pais", pais);
      await onChangePais(pais);
    }

    if (province) {
      console.log("withEdition, province", province);
      onChangeProvincia(province);
    }
  }, [dpPais, setPais, cargarProvincias, setProvincia]);

  React.useEffect(() => {
    if (tab.editStatus === StatesEdition.new) {
      modoNuevo(tab.info?.codigo ?? 0)
    } else {
      modoEdicion(false, info.info)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
  }, [handleButtonClick, tab.globalButtonClick])

  // React.useEffect(() => {
  //   if (dataEdicion.codigo !== 0 && dataEdicion.provinciaCodigo !== '' && provincia === null) {
  //     withEdicion(dataEdicion.provinciaCodigo);
  //   }
  // }, [dataEdicion])

  return (
    <div style={{ padding: '10px', overflowX: 'hidden' }}>
      <div>
        <div>
          <Dialog ref={dialogRef} />
        </div>
        {showErrorPopup && (
          <PopupContent
            show={showErrorPopup}
            title={'Acatha'}
            subTitle={'Por favor revise las siguientes advertencias:'}
            onClose={() => {
              setShowErrorPopup(false)
              setShowErrorMessages([])
            }}
            size={'sm'}
            canClose={true}
            height={'auto'}
          >
            <CAlert color="danger">
              <ul style={{ listStyle: 'none', margin: '0px', padding: '0px' }}>
                {showErrorMessages.map(function (item, id) {
                  return <li key={id}>{item['message']}</li>
                })}
              </ul>
            </CAlert>
          </PopupContent>
        )}

        <div id="configLocal">
          <BlockUi
            tag="div"
            loader={LoadingIndicator}
            blocking={loaderCiudades?.show ?? false}
            message={loaderCiudades?.message ?? ''}
          >
            <fieldset disabled={loaderCiudades.show}>
              <ValidationGroup ref={validationGroupRef} className="">
                <CCard style={{ border: 1 }} className="m-0">
                  <CCardBody>
                    <ValidationSummary className="mb-2" />
                    <CRow>
                      <CCol className=" d-flex " md="12">
                        <div className="dx-field" style={{ width: '100%' }}>
                          <CRow>
                            <CCol lg="12" md="12" sm="12">
                              <div
                                className="dx-field-label"
                                style={{ width: '100%' }}
                              >
                                {'Nombre(*):'}
                              </div>
                            </CCol>
                          </CRow>
                          <CRow>
                            <CCol lg="12" md="12" sm="12">
                              <InputTextDE
                                value={dataEdicion?.nombre ?? ''}
                                placeholder=""
                                onValueChange={(e) => {
                                  onChangeValue(e, 'nombre')
                                }}
                                showClearButton={true}
                                width="100%"
                              >
                                <Validator>
                                  <RequiredRule message="- Nombre: Este campo es requerido" />
                                  <StringLengthRule
                                    min="5"
                                    message="- Nombre: Este campo debe tener al menos 5 caracteres"
                                  />
                                  <StringLengthRule
                                    max="50"
                                    message="- Nombre: Este campo no puede tener mas de 50 caracteres"
                                  />
                                </Validator>
                              </InputTextDE>
                            </CCol>
                          </CRow>
                        </div>
                      </CCol>
                    </CRow>

                    <CRow>
                      <CCol className=" d-flex " md="12">
                        <div className="dx-field" style={{ width: '100%' }}>
                          <CRow>
                            <CCol lg="12" md="12" sm="12">
                              <div
                                className="dx-field-label"
                                style={{ width: '100%' }}
                              >
                                {'INEC(*):'}
                              </div>
                            </CCol>
                          </CRow>
                          <CRow>
                            <CCol lg="12" md="12" sm="12">
                              <InputTextDE
                                value={dataEdicion?.inec ?? ''}
                                placeholder=""
                                onValueChange={(e) => {
                                  onChangeValue(e, 'inec')
                                }}
                                showClearButton={true}
                                width="100%"
                              >
                                <Validator>
                                  <RequiredRule message="- INEC: Este campo es requerido" />
                                  <StringLengthRule
                                    min="1"
                                    message="- INEC: Este campo debe tener al menos 1 caracteres"
                                  />
                                  <StringLengthRule
                                    max="12"
                                    message="- INEC: Este campo no puede tener mas de 12 caracteres"
                                  />
                                </Validator>
                              </InputTextDE>
                            </CCol>
                          </CRow>
                        </div>
                      </CCol>
                    </CRow>

                    <CRow>
                      <CCol className=" d-flex " md="12">
                        <div className="dx-field" style={{ width: '100%' }}>
                          <CRow>
                            <CCol lg="12" md="12" sm="12">
                              <div
                                className="dx-field-label"
                                style={{ width: '100%' }}
                              >
                                {'Código UAF:'}
                              </div>
                            </CCol>
                          </CRow>
                          <CRow>
                            <CCol lg="12" md="12" sm="12">
                              <InputTextDE
                                value={dataEdicion?.uaf ?? ''}
                                placeholder=""
                                onValueChange={(e) => {
                                  onChangeValue(e, 'uaf')
                                }}
                                showClearButton={true}
                                width="100%"
                              >
                                <Validator>
                                  <StringLengthRule
                                    max="12"
                                    message="- Código UAF: Este campo no puede tener mas de 12 caracteres"
                                  />
                                </Validator>
                              </InputTextDE>
                            </CCol>
                          </CRow>
                        </div>
                      </CCol>
                    </CRow>

                    <CRow>
                      <CCol className=" d-flex " md="12">
                        <div className="dx-field" style={{ width: '100%' }}>
                          <CRow>
                            <CCol lg="12" md="12" sm="12">
                              <div
                                className="dx-field-label"
                                style={{ width: '100%' }}
                              >
                                {'Código DEP:'}
                              </div>
                            </CCol>
                          </CRow>
                          <CRow>
                            <CCol lg="12" md="12" sm="12">
                              <InputTextDE
                                value={dataEdicion?.dep ?? ''}
                                placeholder=""
                                onValueChange={(e) => {
                                  onChangeValue(e, 'dep')
                                }}
                                showClearButton={true}
                                width="100%"
                              >
                                <Validator>
                                  <StringLengthRule
                                    max="12"
                                    message="- Código DEP: Este campo no puede tener mas de 12 caracteres"
                                  />
                                </Validator>
                              </InputTextDE>
                            </CCol>
                          </CRow>
                        </div>
                      </CCol>
                    </CRow>

                    <CRow>
                      <CCol className=" d-flex " md="12">
                        <div className="dx-field" style={{ width: '100%' }}>
                          <CRow>
                            <CCol lg="12" md="12" sm="12">
                              <div
                                className="dx-field-label"
                                style={{ width: '100%' }}
                              >
                                {'Código Origen(*):'}
                              </div>
                            </CCol>
                          </CRow>
                          <CRow>
                            <CCol lg="12" md="12" sm="12">
                              <InputTextDE
                                value={dataEdicion?.codigoOrigen ?? ''}
                                placeholder=""
                                onValueChange={(e) => {
                                  onChangeValue(e, 'codigoOrigen')
                                }}
                                showClearButton={true}
                                width="100%"
                              >
                                <Validator>
                                  <RequiredRule message="- Código Origen: Este campo es requerido" />
                                  <StringLengthRule
                                    min="1"
                                    message="- Código Origen: Este campo debe tener al menos 1 caracteres"
                                  />
                                  <StringLengthRule
                                    max="12"
                                    message="- Código Origen: Este campo no puede tener mas de 12 caracteres"
                                  />
                                </Validator>
                              </InputTextDE>
                            </CCol>
                          </CRow>
                        </div>
                      </CCol>
                    </CRow>

                    <CRow>
                      <CCol className=" d-flex " md="12">
                        <div className="dx-field" style={{ width: '100%' }}>
                          <CRow>
                            <CCol lg="12" md="12" sm="12">
                              <div
                                className="dx-field-label"
                                style={{ width: '100%' }}
                              >
                                {'Pais(*):'}
                              </div>
                            </CCol>
                          </CRow>
                          <CRow>
                            <CCol lg="12" md="12" sm="12">
                              <SelectBox
                                id="selectPaises"
                                width="100%"
                                dataSource={dpPais ?? []}
                                displayExpr="descripcion"
                                searchEnabled={true}
                                searchMode={'contains'}
                                searchExpr={'descripcion'}
                                searchTimeout={200}
                                placeholder="Seleccionar"
                                value={pais ?? null}
                                onValueChange={(e) => {
                                  onChangePais(e)
                                }}
                              >
                              </SelectBox>
                            </CCol>
                          </CRow>
                        </div>
                      </CCol>
                    </CRow>

                    <CRow>
                      <CCol className=" d-flex " md="12">
                        <div className="dx-field" style={{ width: '100%' }}>
                          <CRow>
                            <CCol lg="12" md="12" sm="12">
                              <div
                                className="dx-field-label"
                                style={{ width: '100%' }}
                              >
                                {'Provincia(*):'}
                              </div>
                            </CCol>
                          </CRow>
                          <CRow>
                            <CCol lg="12" md="12" sm="12">
                              <SelectBox
                                id="selectProvincias"
                                width="100%"
                                dataSource={dpProvincia ?? []}
                                displayExpr="nombre"
                                searchEnabled={true}
                                searchMode={'contains'}
                                searchExpr={'nombre'}
                                searchTimeout={200}
                                placeholder="Seleccionar"
                                value={provincia ?? null}
                                onValueChange={(e) => {
                                  console.log("selectProvincia", e);
                                  onChangeValue(e.codigo, 'provinciaCodigo')
                                  onChangeProvincia(e)
                                }}
                              ></SelectBox>
                            </CCol>
                          </CRow>
                        </div>
                      </CCol>
                    </CRow>

                  </CCardBody>
                </CCard>
              </ValidationGroup>
            </fieldset>
          </BlockUi>
        </div>
      </div>
    </div>
  )
}

export default React.memo(MantenimientoCiudades)

export const ECiudadesButtons: CustomButtons = {
  Nuevo: true,
  Guardar: true,
  Buscar: true,
  Deshacer: true,
  Editar: false,
  Imprimir: false,
  Descuento: true,
  Credito: true,
  Extraer: false,
  Eliminar: false,
  'Desactivar varios': false,
}

export const defaultEditionCiudades: CiudadesDatosEdicion = {
  codigo: 0,
  nombre: '',
  origen: 0,
  provinciaCodigo: '',
  uaf: '',
  dep: '',
  inec: '',
  codigoOrigen: '',
  edition: false,
  loader: null,
  loading: false,
  tieneError: false,
  mensajeError: '',
}
