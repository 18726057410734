/* eslint-disable prefer-const */
import React from 'react';
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol, CForm, CFormCheck, CRow
} from '@coreui/react-pro';
import { addToast } from '../../../../../../store/toasterReducer';
import { OptionGrupos, ToastTypes } from '../../../../../../store/types';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../store/store';
import { ccahorrosService } from '../../services/CCAhorrosService';
import { setCurrentAccion, setResetSeleccion, setCurrentFunction, changeLoaderCuentaAhorros } from './../../store/CCAhorrosReducers';
import ModalBancos from "../../../bancos/componentes/modalBancos"
import ModalUsuarios from "../../../../../nomina/componentes/modalUsuarios"
import {
  Validator,
  RequiredRule,
  PatternRule,
  AsyncRule
} from 'devextreme-react/validator';
import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box';
import { DateBox, Button as DateBoxButton } from 'devextreme-react/date-box';
import ValidationGroup from 'devextreme-react/validation-group';
import ValidationSummary from "devextreme-react/validation-summary";
import { DateUtils, formatoFechasApi } from '../../../../../../helpers/dateUtils';
import { Lookup, DropDownOptions } from 'devextreme-react/lookup';
import { AdminProveedoresModal } from '../../../../../proveedores/components/adminProveedoresModal';
import { ModalClientes } from '../../../../../clientes/components/modalClientes';
import { GlobalLabel } from '../../../../../../views/componentes/globalLabel/globalLabel';

type NuevoProps = {
  accion: string,
  origen: string,
  codModulo: number
}

const defaultTipoCuenta = [{ value: -1, label: "Elija un Tipo" }];
const defaultEstados = [{ value: -1, label: "Elija un Estado" }];

const dpasociado: Array<OptionGrupos> = [
  { value: 1, label: "EMPRESA" },
  { value: 2, label: "PROVEEDOR" },
  { value: 3, label: "CLIENTE" },
  { value: 4, label: "EMPLEADO" }];

const tipoSecuencial = [
  { label: "Si", value: 1 },
  { label: "No", value: 0 },
]

const Nuevo = (props: NuevoProps) => {

  const { codModulo } = props;

  const dispatch = useDispatch()
  const validationGroupRef = React.useRef<any>();
  const seleccionado = useSelector((state: RootState) => state.bancos.ccahorros.seleccionado);
  const currentAction = useSelector((state: RootState) => state.bancos.ccahorros.currentAction);
  const currentFunction = useSelector((state: RootState) => state.bancos.ccahorros.currentFunction);

  const [tiposCuenta, setTiposCuenta] = React.useState<OptionGrupos | []>([]);
  const [dpTiposCuentas, setDpTiposCuentas] = React.useState<Array<OptionGrupos>>([]);
  const [codcuenta_ti, setCodcuenta_ti] = React.useState<number>(0);
  const [numero_ti, setNumero_ti] = React.useState<string>("");
  const [banco_ti, setBanco_ti] = React.useState<string>("");
  const [codbanco_ti, setCodbanco_ti] = React.useState<number | null>(null);
  const [corte_ca, setCorte_ca] = React.useState<Date>(new Date());
  const [cheque_ti, setCheque_ti] = React.useState<string>("");
  const [asociado_cb, setAsociado_c] = React.useState<any>(dpasociado[0]);
  const [codAsociado_cb, setCodAsociado_c] = React.useState<any>(0);
  const [identificacion_ti, setIdentificacion_ti] = React.useState<string>("");
  const [nombreAsociado_ti, setNombreAsociado_ti] = React.useState<string>("");

  const [saldo_ti, setSaldo_ti] = React.useState<string>('');
  const [oficial_ti, setOficial_ti] = React.useState<string>("");
  const [secuencialTipo, setSecuencialTipo] = React.useState<number>(0);
  const [showBancos, setShowBancos] = React.useState<boolean>(false);
  const [readonlyDate, setReadonlyDate] = React.useState<boolean>(true);
  const [readonlyCheque, setReadonlyCheque] = React.useState<boolean>(true);
  const [readonlySaldo, setReadonlySaldo] = React.useState<boolean>(true);
  const [readonlyOficial, setReadonlyOficial] = React.useState<boolean>(true);
  const [readonlyAsociado, setReadonlyAsociado] = React.useState<boolean>(true);

  //utilidades.apiDateToPickersDate(datosEdicion.fecha)
  const [estado, setEstado] = React.useState<OptionGrupos | []>([]);
  const [dpEstados, setDpEstados] = React.useState<Array<OptionGrupos>>([]);
  const [showUsuarios, setShowUsuarios] = React.useState<boolean>(false);
  const [showProveedor, setShowProveedor] = React.useState<boolean>(false);
  const [showCliente, setShowCliente] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (currentFunction === "Guardar") {
      dispatch(setCurrentFunction(""));
      handleSubmit()
    } else if (currentFunction === "Deshacer") {
      dispatch(setCurrentFunction(""));
      if (currentAction === "Nuevo") {
        handleReset()
      } else if (currentAction === "Editar") {
        cargarBancoToFormulario()
      }
    } else if (currentFunction === "Nuevo") {
      dispatch(setCurrentFunction(""));
      handleReset()
    }
  }, [currentFunction]);

  React.useEffect(() => {
    if (props.accion === 'Nuevo') {
      obtenerTiposCuentas()
      obtenerEstados()
      setAsociado_c(dpasociado[0]) // marca la seleccion del combo box pertenece
      //setTiposBanco(defaultTipoBanco[0])
      //setDpEstados(defaultEstados)
      //setEstado(defaultEstados[0])
    }
  }, []);

  React.useEffect(() => {
    formInitialState()
    if ((props.accion === 'Editar') && (seleccionado['index'] !== null)) {
      cargarBancoToFormulario()
    }
  }, [props.accion]);

  const setToast = (texto, type) => {
    let tipo: ToastTypes = ToastTypes.Info;
    if (type === 'warning') {
      tipo = ToastTypes.Warning;
    } else if (type === 'success') {
      tipo = ToastTypes.Success;
    } if (type === 'info') {
      tipo = ToastTypes.Info;
    } if (type === 'danger') {
      tipo = ToastTypes.Danger;
    }
    dispatch(addToast({
      id: '',
      autoHide: 3500,
      title: 'Acatha',
      content: texto,
      fade: true,
      type: tipo
    }));
  }

  const playLoader = () => {
    dispatch(changeLoaderCuentaAhorros({ show: true, mensaje: 'Cargando...' }))
  }

  const stopLoader = () => {
    dispatch(changeLoaderCuentaAhorros({ show: false, mensaje: '' }))
  }

  const handleReset = () => {
    setNumero_ti("")
    setBanco_ti("")
    setCodbanco_ti(null)
    setCorte_ca(new Date())
    setCheque_ti("")
    setAsociado_c(dpasociado[0])
    setSaldo_ti("")
    setOficial_ti("")
    setSecuencialTipo(0)
    setCodAsociado_c(null)
    setIdentificacion_ti('')
    setNombreAsociado_ti('')
    setCodcuenta_ti(0)
    setTiposCuenta(dpTiposCuentas[0])
    setEstado(dpEstados[0]);
    setReadonlyAsociado(true)
  }
  /*  inicio carga el combo box estados de cuentas */

  const obtenerEstados = async () => {
    try {
      const data = await obtenerEstadosService();
      if (data.length > 0) {
        let itemEstado: Array<OptionGrupos>;
        itemEstado = data.map(function (key) {
          return {
            value: Number(key?.SUB_CODIGO),
            label: String(key?.SUB_VALOR),
          };
        });
        setDpEstados(itemEstado)
        setEstado(itemEstado[1])
      } else {
        setDpEstados(defaultEstados)
        setEstado(defaultEstados[0])
      }
      return await data;
    } catch (error) {
      console.log('error', error);
    }
  }

  const obtenerEstadosService = async () => {
    try {
      const data = await ccahorrosService.obtenerCombos(1, "Elija un Estado");
      return await data;
    } catch (error) {
      console.log('error', error);
    }
  }

  /*  final carga el combo box estados de cuentas */


  /*  inicio carga el combo box tipos de cuentas */
  const obtenerTiposCuentas = React.useCallback(async () => {
    const tipos = await obtenerTiposCuentasService();

    if (tipos.length > 0) {
      let tipoBanco: Array<OptionGrupos>;
      tipoBanco = tipos.map(function (key) {
        return {
          value: Number(key?.SUB_CODIGO),
          label: String(key?.SUB_VALOR),
        };
      });
      setDpTiposCuentas(tipoBanco)
      setTiposCuenta(tipoBanco[0])
    } else {
      setDpTiposCuentas(defaultTipoCuenta)
      setTiposCuenta(defaultTipoCuenta[0])
    }

  }, []);

  const obtenerTiposCuentasService = async () => {
    try {
      const tipos = await ccahorrosService.obtenerCombos(130, "Elija un tipo");
      return await tipos;
    } catch (error) {
      console.log('error', error);
    }
  }
  /*  fin carga el combo box tipos de cuentas */

  const filtrarOptionsValue = (option, filtrar) => {
    const filtrado = option.filter((item: any) => {
      return item.value === filtrar;
    });
    return filtrado;
  }

  const handleSubmit = async () => {
    const result = validationGroupRef.current.instance.validate();
    console.log('result', result)
    if (result.isValid) {
      if (Number(tiposCuenta['value']) <= 0 || Number(estado['value']) <= 0 || Number(asociado_cb['value']) <= 0) {
        setToast("Tiene errores por favor verifíquelos.", "info");
        return false;
      }
      playLoader()
      const data_request = {
        codigo: props.accion === "Nuevo" ? 0 : codcuenta_ti,
        tipo: tiposCuenta['value'],
        numero: numero_ti,
        codigoBanco: codbanco_ti,
        fechaCorte: DateUtils.dateToString(corte_ca, formatoFechasApi),
        numeroCheque: cheque_ti,
        pertenece: String(asociado_cb['value']),
        asociado: codAsociado_cb,
        saldo: Number(saldo_ti),
        oficial: oficial_ti,
        estado: estado['value'],
        contador: secuencialTipo,
      }
      try {

        const data = await ccahorrosService.crearCuentaCAhorros(data_request);
        stopLoader()

        if (data['error'] === true) {
          setToast(data['message'], "warning");
        } else {

          setToast(data['message'], "success");
          if (props.accion === "Editar") {
            dispatch(setResetSeleccion())
          }
          dispatch(setCurrentAccion("Buscar"))
        }

        return await data;
      } catch (error) {
        console.log('error', error);
        stopLoader()
        setToast(JSON.stringify(error), "danger")
      }
    } else {
      setToast("Tiene errores por favor verifíquelos.", "info");
    }
  }

  const cargarBancoToFormulario = React.useCallback(async () => {
    setCodcuenta_ti(seleccionado['row']['cba_codigo'])
    setNumero_ti(seleccionado['row']['cba_numero'])
    setBanco_ti(seleccionado['row']['ban_nombre'])
    setCodbanco_ti(seleccionado['row']['ban_codigo'])
    setCheque_ti(seleccionado['row']['num_cheque'])
    setSaldo_ti(seleccionado['row']['cba_saldo'])
    setOficial_ti(seleccionado['row']['cba_oficial'])
    setCorte_ca(DateUtils.strDateToDate(seleccionado['row']['fecha_corte'], formatoFechasApi))
    // setIdAsociado(seleccionado['row']['asociado_id'])
    // setNomAsociado(seleccionado['row']['asociado_nombre'])
    // setCodAsociado(seleccionado['row']['asociado'])
    setSecuencialTipo(seleccionado['row']['cba_contador'])
    cargarTipoCuenta(seleccionado['row']['cba_tipo'])
    cargarTipoPertenece(seleccionado['row']['cba_pertenece'])
    cargarEstado(seleccionado['row']['cba_estado']);
    setIdentificacion_ti(seleccionado['row']['asociado_id'])
    setNombreAsociado_ti(seleccionado['row']['asociado_nombre'])
    setCodAsociado_c(seleccionado['row']['asociado'])
    if (seleccionado['row']['asociado_id'] > 0) {
      setReadonlyAsociado(false)
    }
  }, []);
  /* carga el valor combo box tipo cuentas y su data provider */
  const cargarTipoCuenta = React.useCallback(async (tipoBancoCargar) => {
    if (dpTiposCuentas.length > 0) {
      const resTipoFiltrado = filtrarOptionsValue(dpTiposCuentas, Number(tipoBancoCargar))
      if (resTipoFiltrado.length > 0) {
        setTiposCuenta(resTipoFiltrado[0])
      }
    } else {
      const tipos = await obtenerTiposCuentasService();
      let tipoBanco: Array<OptionGrupos>;
      tipoBanco = tipos.map(function (key) {
        return {
          value: Number(key?.SUB_CODIGO),
          label: String(key?.SUB_VALOR),
        };
      });
      setDpTiposCuentas(tipoBanco)
      const resTipoFiltrado = filtrarOptionsValue(tipoBanco, Number(tipoBancoCargar))
      if (resTipoFiltrado.length > 0) {
        setTiposCuenta(resTipoFiltrado[0])
      }
    }
  }, []);

  const cargarTipoPertenece = React.useCallback(async (tipoAsociadoCargar) => {
    const resTipoFiltrado = filtrarOptionsValue(dpasociado, Number(tipoAsociadoCargar))
    if (resTipoFiltrado.length > 0) {
      setAsociado_c(resTipoFiltrado[0])
    }
  }, []);

  const cargarEstado = React.useCallback(async (estadoBancoCargar) => {
    if (dpEstados.length > 0) {
      const resEstadoFiltrado = filtrarOptionsValue(dpEstados, Number(estadoBancoCargar))
      if (resEstadoFiltrado.length > 0) {
        setEstado(resEstadoFiltrado[0])
      }
    } else {
      const tipos = await obtenerEstados();
      const resEstadoFiltrado = filtrarOptionsValue(tipos, Number(estadoBancoCargar))
      if (resEstadoFiltrado.length > 0) {
        setEstado(resEstadoFiltrado[0])
      }
    }
  }, []);

  const formInitialState = React.useCallback(async () => {
    setNumero_ti("");
    setBanco_ti("");
    setCodbanco_ti(null);
  }, []);

  const radioItems = tipoSecuencial.map(function (item, i) {
    const nameButon = item.label.replace(/ /gi, "");
    return (
      <>
        <CForm key={i + nameButon + "_formgroup"}>
        <CFormCheck type='radio' id={item.label} className="form-check-input input-sm" name="opt" value={item.label} key={i + nameButon + "_input"}
          checked={secuencialTipo === item.value ? true : false}
          onChange={() => setSecuencialTipo(item.value)} />
        <GlobalLabel variant="custom-checkbox" htmlFor={item.label} key={i + nameButon + "_label"} >{item.label}</GlobalLabel>
        </CForm> 
      </>
    );
  });

  const modalBancos = () => {
    return (
      <ModalBancos
        show={showBancos}
        onClose={() => setShowBancos(!showBancos)}
        cargarCuentas={(data) => {
          setBanco_ti(data['nombre']);
          setCodbanco_ti(data['codigo']);
        }}
      />
    )
  }


  const modalUsuarios = () => {
    return (
      <ModalUsuarios
        show={showUsuarios}
        onClose={() => setShowUsuarios(false)}
        cargarCuentas={(data) => {
          //setBanco_ti(data['nombre']);
          //setCodbanco_ti(data['codigo']);
        }}
        modulo={codModulo}
      />
    )
  }

  const readonlyVerify = (asociado) => {
    if (asociado['value'] !== null) {

      switch (Number(asociado['value'])) {
        case 1:
          setReadonlyDate(false);
          setReadonlyCheque(false);
          setReadonlySaldo(false);
          setReadonlyOficial(false);
          break;
        case 2:
          setReadonlyDate(true);
          setReadonlyCheque(false);
          setReadonlySaldo(true);
          setReadonlyOficial(true);
          break;
        case 3:
          setReadonlyDate(true);
          setReadonlyCheque(false);
          setReadonlySaldo(true);
          setReadonlyOficial(true);
          break;
        case 4:
          setReadonlyDate(true);
          setReadonlyCheque(true);
          setReadonlySaldo(true);
          setReadonlyOficial(true);
          setReadonlyAsociado(true);
          break;
        default:
          break;
      }

    } else {
      setReadonlyDate(true);
      setReadonlyDate(false);
      setReadonlyCheque(false);
      setReadonlySaldo(false);
      setReadonlyOficial(false);
      setReadonlyAsociado(false);
    }
    setSaldo_ti('');
    setOficial_ti("");
  }

  const onDateChanged = (data) => {
    setCorte_ca(data.value)
  }

  const validateAsyncSeleccion = (datoSeleccion) => {
    return verificarSeleccion(datoSeleccion);
  }

  const verificarSeleccion = (value) => {
    const validador = 0;
    return new Promise((resolve) => {
      setTimeout(function () {
        if (value['value']['value'] !== undefined) {
          resolve((Number(value['value']['value']) > Number(validador)) === true ? true : false);
        } else {
          resolve(true);
        }
      }, 1000);
    });
  }


  const openAsociados = React.useCallback(async () => {
    //
    console.log("openAsociados :", asociado_cb)
    // EMPRESA = 1
    // PROVEEDOR = 2
    // CLIENTE = 3
    // EMPLEADO = 4

    switch (asociado_cb.value) {
      case 1:
        setShowProveedor(true)
        break;
      case 2:
        setShowProveedor(true)
        break;
      case 3:
        setShowCliente(true);
        break;
      case 4:
        setShowUsuarios(true)
        break;
      default:
        return true
    }
  }, [asociado_cb]);


  return (
    <>
      {showBancos === true && modalBancos()}
      {showUsuarios === true && modalUsuarios()}

      <AdminProveedoresModal
        onChanged={(data) => {
          console.log("onChange", data);
          setIdentificacion_ti(data.identificacion);
          setShowProveedor(false);
        }}
        onCancel={() => setShowProveedor(false)}
        show={showProveedor}
      />

      <ModalClientes
        show={showCliente}
        onClose={() => {
          setShowCliente(false)
        }}
        returnCliente={(data) => {
          console.log("info cliente", data)
          setNombreAsociado_ti(data.nombres ?? "")
          setIdentificacion_ti(data.identificacion ?? "");
          setShowCliente(false)
        }}
      />

      <CCard>
        <CCardHeader>
          <strong> {props.accion} </strong>
        </CCardHeader>
        <CCardBody>


          <ValidationGroup ref={validationGroupRef}>
            <CRow>
              <CCol lg="8" sm='12' >

                <div className="dx-field">
                  <div className="dx-field-label" >{"Tipo:"}</div>
                  <div className="dx-field-value">
                    <Lookup
                      dataSource={dpTiposCuentas}
                      displayExpr="label"
                      searchEnabled={true}
                      searchMode={'contains'}
                      searchExpr={'label'}
                      searchTimeout={200}
                      minSearchLength={3}
                      showDataBeforeSearch={true}
                      placeholder="Seleccionar"
                      showClearButton={true}
                      defaultValue={tiposCuenta}
                      value={tiposCuenta}
                      onValueChanged={(data) => {
                        if (data.value) {
                          setTiposCuenta(data.value)
                        } else {
                          setTiposCuenta(dpTiposCuentas[0])
                        }
                      }}
                    >
                      <Validator>
                        <RequiredRule message="Seleccione el tipo de banco" />
                        <AsyncRule
                          message="Seleccione el tipo de banco"
                          validationCallback={validateAsyncSeleccion} />
                      </Validator>
                    </Lookup>
                  </div>
                </div>

                <div className="dx-field">
                  <div className="dx-field-label">{"Número:"}</div>
                  <div className="dx-field-value">
                    <TextBox
                      value={numero_ti}
                      placeholder=""
                      onValueChanged={(data) => { setNumero_ti(data.value) }}
                    >
                      <Validator>
                        <RequiredRule message="Ingrese el Número de cuenta" />
                      </Validator>
                    </TextBox>
                  </div>
                </div>

                <div className="dx-field">
                  <div className="dx-field-label">{"Banco:"}</div>
                  <div className="dx-field-value">
                    <TextBox
                      name="banco_ti"
                      id="banco_ti"
                      value={banco_ti}
                      showClearButton={true}
                      onEnterKey={() => setShowBancos(true)}
                    >
                      <TextBoxButton
                        name="agregarCuenta"
                        location="after"
                        options={
                          {
                            icon: 'plus',
                            text: '',
                            onClick: () => setShowBancos(true)
                          }
                        }
                      />
                      {
                        codbanco_ti !== null &&
                        <TextBoxButton
                          name="limpiarCuenta"
                          location="after"
                          options={
                            {
                              icon: 'trash',
                              text: '',
                              onClick: () => {
                                setBanco_ti("");
                                setCodbanco_ti(null);
                              }
                            }
                          }
                        />
                      }
                      <Validator>
                        <RequiredRule message="Ingrese el banco" />
                      </Validator>
                    </TextBox>
                  </div>
                </div>

                <div className="dx-field">
                  <div className="dx-field-label">{"Fecha de corte:"}</div>
                  <div className="dx-field-value">
                    <DateBox
                      readOnly={readonlyDate}
                      displayFormat="dd/MM/yyyy"
                      value={corte_ca}
                      stylingMode="outlined"
                      onValueChanged={onDateChanged}
                    >
                      <DateBoxButton
                        name="today"
                        location="before"
                        options={{
                          text: 'HOY',
                          onClick: () => {
                            setCorte_ca(new Date())
                          }
                        }}
                      />
                      <DateBoxButton
                        name="prevDate"
                        location="before"
                        options={{
                          icon: 'spinprev',
                          stylingMode: 'text',
                          onClick: () => {
                            const x = DateUtils.add(corte_ca, { days: -1 })
                            setCorte_ca(x)
                          }
                        }}
                      />
                      <DateBoxButton
                        name="nextDate"
                        location="after"
                        options={{
                          icon: 'spinnext',
                          stylingMode: 'text',
                          onClick: () => {
                            const x = DateUtils.add(corte_ca, { days: 1 })
                            setCorte_ca(x)
                          }
                        }}
                      />
                      <DateBoxButton name="dropDown" />
                      <Validator>
                        {readonlyDate === false &&
                          <>
                            <RequiredRule message="La Fecha de corte es requerida" />
                          </>
                        }
                      </Validator>
                    </DateBox>
                  </div>
                </div>

                <div className="dx-field">
                  <div className="dx-field-label">{"Cheque:"}</div>
                  <div className="dx-field-value">
                    <TextBox
                      name="cheque_ti"
                      id="cheque_ti"
                      value={cheque_ti}
                      onValueChanged={(data) => setCheque_ti(data.value)}
                      showClearButton={true}
                      readOnly={readonlyCheque}
                    >
                      <Validator>
                        {readonlyCheque === false &&
                          <>
                            <RequiredRule message="Ingrese el numero de cheque actual" />
                          </>
                        }
                      </Validator>
                    </TextBox>
                  </div>
                </div>

                <div className="dx-field">
                  <div className="dx-field-label">{"Pertenece a:"}</div>
                  <div className="dx-field-value">
                    <Lookup
                      dataSource={dpasociado}
                      displayExpr="label"
                      searchEnabled={true}
                      searchMode={'contains'}
                      searchExpr={'label'}
                      searchTimeout={200}
                      minSearchLength={0}
                      showDataBeforeSearch={false}
                      placeholder="Seleccionar"
                      defaultValue={asociado_cb}
                      value={asociado_cb}
                      onValueChanged={(data) => {
                        if (data.value) {
                          setAsociado_c(data.value);
                          setIdentificacion_ti("");
                          setNombreAsociado_ti("")
                          readonlyVerify(data.value)
                        } else {
                          setAsociado_c(dpasociado[0]);
                          setNombreAsociado_ti("")
                          setIdentificacion_ti("");
                        }
                      }}
                    >
                      <Validator>
                        <RequiredRule message="Debe seleccionar a quien pertenece el banco" />
                        <AsyncRule
                          message="Debe seleccionar a quien pertenece el banco"
                          validationCallback={validateAsyncSeleccion} />
                      </Validator>
                    </Lookup>
                  </div>
                </div>

                <div className="dx-field">
                  <div className="dx-field-label">{"Asociado:"}</div>
                  <div className="dx-field-value">
                    <CRow>
                      <CCol>
                        <TextBox
                          readOnly={readonlyAsociado}
                          name="identificacion_ti"
                          id="identificacion_ti"
                          value={identificacion_ti}
                          onValueChanged={(data) => setIdentificacion_ti(data.value)}
                          showClearButton={true}
                          onEnterKey={() => setShowUsuarios(true)}
                        >
                          <TextBoxButton
                            name="agregarCuenta"
                            location="after"
                            options={
                              {
                                icon: 'plus',
                                text: '',
                                onClick: () => {
                                  openAsociados()
                                }
                              }
                            }
                          />
                          {
                            identificacion_ti !== "" &&
                            <TextBoxButton
                              name="limpiarCuenta"
                              location="after"
                              options={
                                {
                                  icon: 'trash',
                                  text: '',
                                  onClick: () => {
                                    setIdentificacion_ti("");
                                  }
                                }
                              }
                            />
                          }
                        </TextBox>
                      </CCol>
                      <CCol>
                        <GlobalLabel>{nombreAsociado_ti}</GlobalLabel>
                      </CCol>
                    </CRow>
                  </div>
                </div>

                <div className="dx-field">
                  <div className="dx-field-label">{"Saldo:"}</div>
                  <div className="dx-field-value">
                    <TextBox
                      name="saldo_ti"
                      value={saldo_ti}
                      readOnly={readonlySaldo}
                      onValueChanged={(data) => setSaldo_ti(data.value)}
                      showClearButton={true}
                      useMaskedValue={true}
                    >
                      <Validator>
                        {readonlySaldo === false &&
                          <>
                            <RequiredRule message="Ingrese el saldo de la cuenta" />
                            <PatternRule message={"EL Saldo debe ser un valor numérico."} pattern='^(0|[1-9]\d*)?(\.\d+)?(?<=\d)$' />
                          </>
                        }
                      </Validator>
                    </TextBox>
                  </div>
                </div>

                <div className="dx-field">
                  <div className="dx-field-label">{"Oficial:"}</div>
                  <div className="dx-field-value">
                    <TextBox
                      name="oficial_ti"
                      id="oficial_ti"
                      readOnly={readonlyOficial}
                      value={oficial_ti}
                      onValueChanged={(data) => setOficial_ti(data.value)}
                      showClearButton={true}
                    >
                      <Validator>
                        {readonlyOficial === false &&
                          <>
                            <RequiredRule message="Ingrese el nombre del oficial de cuenta" />
                          </>
                        }
                      </Validator>
                    </TextBox>
                  </div>
                </div>

                <div className="dx-field">
                  <div className="dx-field-label">{"Estado:"}</div>
                  <div className="dx-field-value">
                    <Lookup dataSource={dpEstados}
                      displayExpr="label"
                      searchEnabled={true}
                      searchMode={'contains'}
                      searchExpr={'label'}
                      searchTimeout={200}
                      minSearchLength={0}
                      showDataBeforeSearch={false}
                      placeholder="Seleccionar"
                      showClearButton={true}
                      defaultValue={estado}
                      value={estado}
                      onValueChanged={(data) => {
                        if (data.value) {
                          setEstado(data.value)
                        } else {
                          setEstado(dpEstados[0])
                        }
                      }}
                    >
                      <Validator>
                        <RequiredRule message="Seleccione el estado del banco" />
                        <AsyncRule
                          message="Seleccione el estado del banco"
                          validationCallback={validateAsyncSeleccion} />
                      </Validator>
                    </Lookup>
                  </div>
                </div>

                <div className="dx-field">
                  <div className="dx-field-label">{"Secuencial:"}</div>
                  <div className="d-flex dx-field-value">
                    {radioItems}
                  </div>
                </div>

              </CCol>
            </CRow>

            <CRow>
              <CCol lg="6" md="8" sm="12" className="mt-2">
                <ValidationSummary id="summary"></ValidationSummary>
              </CCol>
            </CRow>

          </ValidationGroup>

        </CCardBody>

      </CCard>

    </>
  );
}

export default Nuevo;