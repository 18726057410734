import { memo, useCallback, useEffect, useState } from 'react'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../views/componentes/colContainer'
import ModalWoocommerce from '../modalWoocomerce'
import { CheckBox, NumberBox, RadioGroup, TextBox } from 'devextreme-react'
import {
  optionsGroups,
  optionsImages,
  optionsMovilWeb,
  optionsSearch,
  optionsStatus,
  optionsTypes,
} from '../../types/types'
import {
  CButton,
  CCard,
  CCardHeader,
  CCol,
  CCollapse,
  CLink,
  CRow,
} from '@coreui/react-pro'
import LocalesLookUp from '../../../../../componentes/localesLookUp'
import TiposImpuestosLookup from '../../../../../componentes/tiposImpuestosLookup'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/store'
import {
  setFilter,
  setStoreFilter,
  setTaxFilter,
} from '../../store/searchReducer'
import { OptionTiposImpuestos } from '../../../../../componentes/tiposImpuestosLookup/types/types'
import { LocalOption } from '../../../../../componentes/localesLookUp/store/type'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import {
  buttonClick,
  clearButtonClick,
  setCurrentExecutingAction,
} from '../../store/tabsReducer'
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput'
import { addToast } from '../../../../../../store/toasterReducer'
import { ToastTypes } from '../../../../../../store/types'
import ImageViewer from '../../../../../componentes/imagen'
import FIleInput from '../../../../../componentes/fileInput'
import {
  changeLoaderItems,
  setCollpasedAdv,
  setCurrentFunction,
  setCollpased,
} from '../../store/generalReducer'
import { useSetToast } from '../../../../../../hooks/useGlobalHooks'
import { ExportarExcelService } from '../../../../../contabilidad/pages/planCuentas/services/exportarExcel.service'
import { ItemsServices } from '../../services/items.services'
import CIcon from '@coreui/icons-react'
import { cilChevronBottom, cilChevronTop } from '@coreui/icons'

const FiltroBusqueda = () => {
  const dispatch = useDispatch()
  const setToastMessage = useSetToast()

  const searchFilter = useSelector((state: RootState) => {
    return state.inventarios.items.search.filter
  })
  const selectedItem = useSelector((state: RootState) => {
    return state.inventarios.items.search.selectedItem
  })
  const ipWebCompany = useSelector(
    (state: RootState) => state.global.session?.empresa.ipWeb,
  )
  const currentAction = useSelector(
    (state: RootState) => state.inventarios.items.general.currentFunction,
  )
  const tabs = useSelector((state: RootState) => {
    return state.inventarios.items.tabs
  })
  const collapsedAdv = useSelector(
    (state: RootState) => state.inventarios.items.general.collapseAdvance,
  )
  const collapsed = useSelector(
    (state: RootState) => state.inventarios.items.general.collapse,
  )

  const [showModalWoocommerce, setShowModalWoocommerce] = useState(false)
  const [fileExcel, setFileExcel] = useState<any>(null)

  const playLoader = useCallback(
    (message = 'Cargando...') => {
      dispatch(changeLoaderItems({ show: true, mensaje: message }))
    },
    [dispatch],
  )

  const stopLoader = useCallback(() => {
    dispatch(changeLoaderItems({ show: false, mensaje: '' }))
  }, [dispatch])

  const onChangedNoSync = useCallback(
    (data: any) => {
      if (data.event)
        dispatch(
          setFilter({
            ...searchFilter,
            noSincronizados: data.value,
          }),
        )
    },
    [dispatch, searchFilter],
  )

  const onChangedGrouper = useCallback(
    (data: any) => {
      if (data.event)
        dispatch(
          setFilter({
            ...searchFilter,
            agrupador: data.value,
          }),
        )
    },
    [dispatch, searchFilter],
  )

  const onChangedAplications = useCallback(
    (data: any) => {
      if (data.event)
        dispatch(
          setFilter({
            ...searchFilter,
            aplicaciones: data.value,
          }),
        )
    },
    [dispatch, searchFilter],
  )

  const onChangedLotes = useCallback(
    (data: any) => {
      if (data.event)
        dispatch(
          setFilter({
            ...searchFilter,
            lotes: data.value,
          }),
        )
    },
    [dispatch, searchFilter],
  )

  const onChangedExistence = useCallback(
    (data: any) => {
      if (data.event)
        dispatch(
          setFilter({
            ...searchFilter,
            existencia: data.value,
          }),
        )
    },
    [dispatch, searchFilter],
  )

  const onChangedStatus = useCallback(
    (data: any) => {
      if (data.event)
        dispatch(
          setFilter({
            ...searchFilter,
            estadoItem: data.value,
          }),
        )
    },
    [dispatch, searchFilter],
  )

  const onChangedType = useCallback(
    (data: any) => {
      if (data.event)
        dispatch(
          setFilter({
            ...searchFilter,
            tipoItem: data.value,
          }),
        )
    },
    [dispatch, searchFilter],
  )

  const onChangedStore = useCallback(
    (store: LocalOption) => {
      dispatch(
        setStoreFilter({
          store: store,
        }),
      )
    },
    [dispatch],
  )

  const onChangedModel = useCallback(
    (data: any) => {
      if (data.event)
        dispatch(
          setFilter({
            ...searchFilter,
            modelo: data.value,
          }),
        )
    },
    [dispatch, searchFilter],
  )

  const onChangedPriceTo = useCallback(
    (data: any) => {
      if (data.event)
        dispatch(
          setFilter({
            ...searchFilter,
            precioHasta: data.value,
          }),
        )
    },
    [dispatch, searchFilter],
  )

  const onChangedPriceFrom = useCallback(
    (data: any) => {
      if (data.event)
        dispatch(
          setFilter({
            ...searchFilter,
            precioDesde: data.value,
          }),
        )
    },
    [dispatch, searchFilter],
  )

  const onChangedTax = useCallback(
    (tax: OptionTiposImpuestos) => {
      dispatch(
        setTaxFilter({
          tax: tax,
        }),
      )
    },
    [dispatch],
  )

  const onChangedProvCode = useCallback(
    (data: any) => {
      if (data.event)
        dispatch(
          setFilter({
            ...searchFilter,
            codProv: data.value,
          }),
        )
    },
    [dispatch, searchFilter],
  )

  const onChangedAplication = useCallback(
    (data: any) => {
      if (data.event)
        dispatch(
          setFilter({
            ...searchFilter,
            aplicacion: data.value,
          }),
        )
    },
    [dispatch, searchFilter],
  )

  const onChangedGeneric = useCallback(
    (data: any) => {
      if (data.event)
        dispatch(
          setFilter({
            ...searchFilter,
            generico: data.value,
          }),
        )
    },
    [dispatch, searchFilter],
  )

  const onChangedBrand = useCallback(
    (data: any) => {
      if (data.event)
        dispatch(
          setFilter({
            ...searchFilter,
            marca: data.value,
          }),
        )
    },
    [dispatch, searchFilter],
  )

  const onChangedGroup = useCallback(
    (data: any) => {
      if (data.event)
        dispatch(
          setFilter({
            ...searchFilter,
            grupo: data.value,
          }),
        )
    },
    [dispatch, searchFilter],
  )

  const onChangedName = useCallback(
    (data: any) => {
      if (data.event)
        dispatch(
          setFilter({
            ...searchFilter,
            nombre: data.value,
          }),
        )
    },
    [dispatch, searchFilter],
  )

  const onChangedLine = useCallback(
    (data: any) => {
      if (data.event)
        dispatch(
          setFilter({
            ...searchFilter,
            linea: data.value,
          }),
        )
    },
    [dispatch, searchFilter],
  )

  const onChangedBarcode = useCallback(
    (data: any) => {
      if (data.event)
        dispatch(
          setFilter({
            ...searchFilter,
            barras: data.value,
          }),
        )
    },
    [dispatch, searchFilter],
  )

  const onChangedCode = useCallback(
    (data: any) => {
      if (data.event)
        dispatch(
          setFilter({
            ...searchFilter,
            codigo: data.value,
          }),
        )
    },
    [dispatch, searchFilter],
  )

  const onChangedImage = useCallback(
    (data: any) => {
      if (data.event)
        dispatch(
          setFilter({
            ...searchFilter,
            imagen: data.value,
          }),
        )
    },
    [dispatch, searchFilter],
  )

  const onChangedMovilWeb = useCallback(
    (data: any) => {
      if (data.event)
        dispatch(
          setFilter({
            ...searchFilter,
            movilweb: data.value,
          }),
        )
    },
    [dispatch, searchFilter],
  )

  const onChangedIncrementalSearch = useCallback(
    (data: any) => {
      if (data.event)
        dispatch(
          setFilter({
            ...searchFilter,
            busquedaIncremental: data.value,
          }),
        )
    },
    [dispatch, searchFilter],
  )

  const onChangedStartEnd = useCallback(
    (data: any) => {
      if (data.event)
        dispatch(
          setFilter({
            ...searchFilter,
            inicioFinOpt: data.value,
          }),
        )
    },
    [dispatch, searchFilter],
  )

  const onHandleSearch = useCallback(() => {
    dispatch(buttonClick({ tabKey: 'BUSQUEDA', button: ButtonTypes.find }))
  }, [dispatch])

  const showWoocommerce = useCallback(() => {
    if (selectedItem !== null) setShowModalWoocommerce(true)
    else
      dispatch(
        addToast({
          title: 'Woocommerce.',
          content:
            'No se encuentra un registro seleccionado, Por favor seleccione un registro para continuar.',
          type: ToastTypes.Info,
        }),
      )
  }, [dispatch, selectedItem])

  const downLoadTemplate = async () => {
    playLoader('Descargando plantilla de excel . . .')
    setCurrentExecutingAction({
      tabKey: 'BUSQUEDA',
      buttonType: ButtonTypes.import,
    })
    try {
      const filename = 'items'
      const response = await ExportarExcelService.exportarDemo(filename)
      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)
      const linkToDownload = document.createElement('a')
      linkToDownload.href = url
      linkToDownload.download = filename + '.xls'
      linkToDownload.click()
    } catch (error) {
      setToastMessage(
        'Descargar Plantilla Excel',
        JSON.stringify(error),
        ToastTypes.Danger,
      )
    }
    stopLoader()
    dispatch(
      setCurrentExecutingAction({
        tabKey: 'BUSQUEDA',
        buttonType: undefined,
      }),
    )
  }

  const onFileChanged = useCallback((file: any) => {
    if (file === null || file.value.length === 0) setFileExcel(null)
    else if (file.value.length > 0) setFileExcel(file.value[0])
  }, [])

  const onHandleImport = useCallback(async () => {
    if (fileExcel === null)
      setToastMessage(
        'Importar Plantilla Excel',
        'No se encuentra una plantilla para importar',
        ToastTypes.Warning,
      )
    else {
      try {
        playLoader('Importando plantilla . . .')
        setCurrentExecutingAction({
          tabKey: 'BUSQUEDA',
          buttonType: ButtonTypes.import,
        })
        const fileImport = await ItemsServices.importTemplate(
          fileExcel,
          searchFilter.local?.codigo ?? -1,
        )
        stopLoader()
        dispatch(
          setCurrentExecutingAction({
            tabKey: 'BUSQUEDA',
            buttonType: undefined,
          }),
        )
        if (!fileImport.error && fileImport.auto)
          setToastMessage(
            'Importar Plantilla Excel',
            fileImport.message ?? 'Plantila importada correctamente',
            ToastTypes.Success,
          )
        else
          setToastMessage(
            'Importar Plantilla Excel',
            fileImport.message ?? 'Error al importar plantilla',
            ToastTypes.Warning,
          )
      } catch (error) {
        setToastMessage(
          'Importar Plantilla Excel',
          JSON.stringify(error),
          ToastTypes.Danger,
        )
        stopLoader()
        dispatch(
          setCurrentExecutingAction({
            tabKey: 'BUSQUEDA',
            buttonType: undefined,
          }),
        )
      }
    }
  }, [
    dispatch,
    fileExcel,
    playLoader,
    searchFilter,
    setToastMessage,
    stopLoader,
  ])

  const handleButtonClick = useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.import:
          if (tabs.current === 'BUSQUEDA') onHandleImport()
          break
        default:
          break
      }
      dispatch(setCurrentFunction(''))
      dispatch(clearButtonClick('BUSQUEDA'))
    },
    [dispatch, onHandleImport, tabs],
  )

  useEffect(() => {
    if (currentAction !== '') handleButtonClick(currentAction)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAction])

  return (
    <>
      {showModalWoocommerce && (
        <ModalWoocommerce
          show={showModalWoocommerce}
          onClose={() => setShowModalWoocommerce(false)}
        />
      )}
      <CCard>
        <CCardHeader
          onClick={() => dispatch(setCollpased(!collapsed))}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <strong> {!collapsed ? 'Filtro de Búsqueda' : ''} </strong>
          <CLink className="card-header-action">
            <CIcon icon={collapsed ? cilChevronBottom : cilChevronTop} />
          </CLink>
        </CCardHeader>
        <CCollapse visible={collapsed}>
          <RowContainer className="m-2">
            <CustomCol xs="12" md="3">
              <Labeled label="Barras:">
                <TextBox
                  value={searchFilter.barras}
                  placeholder=""
                  onValueChanged={onChangedBarcode}
                  showClearButton={true}
                  width="100%"
                  inputAttr={{ autocomplete: 'nope' }}
                  onEnterKey={onHandleSearch}
                />
              </Labeled>
            </CustomCol>
            <CustomCol xs="12" md="3">
              <Labeled label="Nombre:">
                <TextBox
                  value={searchFilter.nombre}
                  placeholder=""
                  onValueChanged={onChangedName}
                  showClearButton={true}
                  width="100%"
                  inputAttr={{ autocomplete: 'nope' }}
                  onEnterKey={onHandleSearch}
                />
              </Labeled>
            </CustomCol>
            <CustomCol xs="12" md="3">
              <Labeled label="Línea:">
                <TextBox
                  value={searchFilter.linea}
                  placeholder=""
                  onValueChanged={onChangedLine}
                  showClearButton={true}
                  width="100%"
                  inputAttr={{ autocomplete: 'nope' }}
                />
              </Labeled>
            </CustomCol>
            <CustomCol xs="12" md="3">
              <Labeled label="Grupo:">
                <TextBox
                  value={searchFilter.grupo}
                  placeholder=""
                  onValueChanged={onChangedGroup}
                  showClearButton={true}
                  width="100%"
                  inputAttr={{ autocomplete: 'nope' }}
                />
              </Labeled>
            </CustomCol>
          </RowContainer>
          <CCardHeader
            onClick={() => dispatch(setCollpasedAdv(!collapsedAdv))}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <strong> {!collapsedAdv ? 'Búsqueda Avanzada' : ''} </strong>
            <CLink className="card-header-action">
              <CIcon icon={collapsedAdv ? cilChevronBottom : cilChevronTop} />
            </CLink>
          </CCardHeader>
          <CCollapse visible={collapsedAdv}>
            <CRow className="m-2">
              <CCol xs="12" md="9">
                <RowContainer>
                  <CustomCol xs="12" md="4">
                    <Labeled label="Busqueda Incremental:">
                      <div style={{ display: 'flex' }}>
                        <CheckBox
                          style={{ marginRight: '22px' }}
                          text="Modo"
                          value={searchFilter.busquedaIncremental}
                          onValueChanged={onChangedIncrementalSearch}
                        />
                        <RadioGroup
                          name="idSearchItems"
                          dataSource={optionsSearch}
                          value={searchFilter.inicioFinOpt}
                          layout="horizontal"
                          displayExpr="label"
                          onValueChanged={onChangedStartEnd}
                          width="100%"
                          disabled={!searchFilter.busquedaIncremental}
                        />
                      </div>
                    </Labeled>
                  </CustomCol>
                  <CustomCol xs="12" md="3">
                    <Labeled label="Móviles y Web:">
                      <RadioGroup
                        name="idMovilesWeb"
                        dataSource={optionsMovilWeb}
                        value={searchFilter.movilweb}
                        layout="horizontal"
                        displayExpr="label"
                        onValueChanged={onChangedMovilWeb}
                        width="100%"
                      />
                    </Labeled>
                  </CustomCol>
                  <CustomCol xs="12" md="5">
                    <Labeled label="Imagen:">
                      <RadioGroup
                        name="idImagenes"
                        dataSource={optionsImages}
                        value={searchFilter.imagen}
                        layout="horizontal"
                        displayExpr="label"
                        onValueChanged={onChangedImage}
                        width="100%"
                      />
                    </Labeled>
                  </CustomCol>
                </RowContainer>
                <RowContainer>
                  <CustomCol xs="12" md="4">
                    <Labeled label="Código:">
                      <TextBox
                        value={searchFilter.codigo}
                        placeholder=""
                        onValueChanged={onChangedCode}
                        showClearButton={true}
                        width="100%"
                        inputAttr={{ autocomplete: 'nope' }}
                        onEnterKey={onHandleSearch}
                      />
                    </Labeled>
                  </CustomCol>
                  <CustomCol xs="12" md="4">
                    <Labeled label="Marca:">
                      <TextBox
                        value={searchFilter.marca}
                        placeholder=""
                        onValueChanged={onChangedBrand}
                        showClearButton={true}
                        width="100%"
                        inputAttr={{ autocomplete: 'nope' }}
                      />
                    </Labeled>
                  </CustomCol>
                  <CustomCol xs="12" md="4">
                    <Labeled label="Local:">
                      <LocalesLookUp
                        selected={searchFilter.local}
                        onChanged={onChangedStore}
                        onChangedOptions={() => {}}
                      />
                    </Labeled>
                  </CustomCol>
                </RowContainer>
                <RowContainer>
                  <CustomCol xs="12" md="4">
                    <Labeled label="Genérico:">
                      <TextBox
                        value={searchFilter.generico}
                        placeholder=""
                        onValueChanged={onChangedGeneric}
                        showClearButton={true}
                        width="100%"
                        inputAttr={{ autocomplete: 'nope' }}
                      />
                    </Labeled>
                  </CustomCol>
                  <CustomCol xs="12" md="4">
                    <Labeled label="Aplicación:">
                      <TextBox
                        value={searchFilter.aplicacion}
                        placeholder=""
                        onValueChanged={onChangedAplication}
                        showClearButton={true}
                        width="100%"
                        inputAttr={{ autocomplete: 'nope' }}
                      />
                    </Labeled>
                  </CustomCol>
                  <CustomCol xs="12" md="4">
                    <Labeled label="Cod Prov.:">
                      <TextBox
                        value={searchFilter.codProv}
                        placeholder=""
                        onValueChanged={onChangedProvCode}
                        showClearButton={true}
                        width="100%"
                        inputAttr={{ autocomplete: 'nope' }}
                      />
                    </Labeled>
                  </CustomCol>
                </RowContainer>
                <RowContainer>
                  <CustomCol xs="12" md="4">
                    <Labeled label="Impuesto:">
                      <TiposImpuestosLookup
                        selected={searchFilter.tipoImpuesto}
                        onChanged={onChangedTax}
                        onChangedOptions={() => {}}
                      />
                    </Labeled>
                  </CustomCol>
                  <CustomCol xs="12" md="4">
                    <Labeled label="Rango de Precios:">
                      <CRow>
                        <CCol>
                          <NumberBox
                            value={searchFilter.precioDesde}
                            placeholder=""
                            onValueChanged={onChangedPriceFrom}
                            showClearButton={true}
                            width="100%"
                            inputAttr={{ autocomplete: 'nope' }}
                          />
                        </CCol>
                        <CCol>
                          <NumberBox
                            value={searchFilter.precioHasta}
                            placeholder=""
                            onValueChanged={onChangedPriceTo}
                            showClearButton={true}
                            width="100%"
                            inputAttr={{ autocomplete: 'nope' }}
                          />
                        </CCol>
                      </CRow>
                    </Labeled>
                  </CustomCol>
                  <CustomCol xs="12" md="4">
                    <Labeled label="Modelo:">
                      <TextBox
                        value={searchFilter.modelo}
                        placeholder=""
                        onValueChanged={onChangedModel}
                        showClearButton={true}
                        width="100%"
                        inputAttr={{ autocomplete: 'nope' }}
                      />
                    </Labeled>
                  </CustomCol>
                </RowContainer>
                <RowContainer>
                  <CCol xs="12" md="3">
                    <Labeled label="Estado:">
                      <RadioGroup
                        name="idStatusItem"
                        dataSource={optionsStatus}
                        value={searchFilter.estadoItem}
                        layout="horizontal"
                        displayExpr="label"
                        onValueChanged={onChangedStatus}
                        width="100%"
                      />
                    </Labeled>
                  </CCol>
                  <CCol xs="6" md="2" style={{ marginTop: 'auto' }}>
                    <CheckBox
                      text="Existencia"
                      value={searchFilter.existencia}
                      onValueChanged={onChangedExistence}
                      width="100%"
                    />
                  </CCol>
                  <CCol xs="6" md="2" style={{ marginTop: 'auto' }}>
                    <CheckBox
                      text="Aplicaciones"
                      value={searchFilter.aplicaciones}
                      onValueChanged={onChangedAplications}
                      width="100%"
                    />
                  </CCol>
                  <CCol xs="12" md="5" style={{ marginTop: 'auto' }}>
                    <RadioGroup
                      name="idGroupsItem"
                      dataSource={optionsGroups}
                      value={searchFilter.agrupador}
                      layout="horizontal"
                      displayExpr="label"
                      onValueChanged={onChangedGrouper}
                      width="100%"
                    />
                  </CCol>
                </RowContainer>
                <CRow style={{ marginTop: '8px' }}>
                  <CCol xs="12" md="8" />
                  <CCol xs="4" md="2" style={{ marginTop: '8px' }}>
                    <CheckBox
                      text="Lotes"
                      value={searchFilter.lotes}
                      onValueChanged={onChangedLotes}
                      width="100%"
                    />
                  </CCol>
                  <CCol xs="8" md="2" style={{ marginTop: '8px' }}>
                    <CheckBox
                      text="No Sincronizados"
                      value={searchFilter.noSincronizados}
                      onValueChanged={onChangedNoSync}
                      width="100%"
                    />
                  </CCol>
                </CRow>
              </CCol>
              <CCol
                xs="12"
                md="3"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                }}
              >
                <CRow>
                  <FIleInput
                    accept={'application/vnd.ms-excel'}
                    onChanged={onFileChanged}
                    downDemo={downLoadTemplate}
                  />
                </CRow>
                <CRow>
                  <ImageViewer
                    show={selectedItem?.itemImagen?.length > 0}
                    source={
                      selectedItem?.itemImagen?.length > 0
                        ? `${ipWebCompany}/SolucionEmpresarial/SigNum/fotos/${selectedItem.itemImagen}`
                        : `${ipWebCompany}/SolucionEmpresarial/SigNum/fotos/vacio.jpg`
                    }
                    alt={'imagenItem'}
                  />
                </CRow>
                <CRow>
                  <CButton
                    id="btnWoocommerce"
                    color="secondary"
                    className="m-1"
                    size="sm"
                    onClick={() => showWoocommerce()}
                  >
                    {'Actualizar Woocommerce'}
                  </CButton>
                </CRow>
              </CCol>
              <CRow>
                <CustomCol xs="12" md="12">
                  <Labeled label="Tipo:">
                    <RadioGroup
                      name="idTypesItem"
                      dataSource={optionsTypes}
                      value={searchFilter.tipoItem}
                      layout="horizontal"
                      displayExpr="label"
                      onValueChanged={onChangedType}
                      width="100%"
                    />
                  </Labeled>
                </CustomCol>
              </CRow>
            </CRow>
          </CCollapse>
        </CCollapse>
      </CCard>
    </>
  )
}

export default memo(FiltroBusqueda)
