import { RequestHelper } from "../../../../../helpers/requestHelper"

export const cuentasService = {
  cargarTodos, obtenerCuentaPorNumero, obtenerRegistros, obtenerTipos, obtenerCategorias, crearRegistro, deleteRegistro, createImport, obtenerCuentasOption, obtenerCuentaNumero, obtenerCuentasFlujoOptions, asignarJerarquia, asignarNiveles
}

async function cargarTodos(objeto: any): Promise<any> {
  try {
    const obj = {
      ...objeto
    }
    const data = await RequestHelper.getAll<any>('contabilidad', 'accountsPlan/getAll', "", obj);
    return data;
  } catch (error) {
    return error;
  }
}

async function obtenerRegistros(objeto: any): Promise<any> {
  try {
    const obj = {
      numero: objeto['numero'],
      descripcion: objeto['descripcion'],
      estado: objeto['estado'],
    }
    const data = await RequestHelper.postUrlEncodedAll<any>('contabilidad', 'accountsPlan/getAccountsPlan', obj);
    return data;
  } catch (error) {
    return error;
  }
}

async function obtenerCuentasOption(categoria: any): Promise<any> {
  try {
    const obj = {
      mensaje: categoria,
    }
    const data = await RequestHelper.getAll<any>('contabilidad', 'accountsPlan/getGroups', "", obj);
    return data;
  } catch (error) {
    return error;
  }
}

async function obtenerCuentasFlujoOptions(): Promise<any> {
  try {
    const obj = {
    }
    const data = await RequestHelper.getAll<any>('contabilidad', 'cashFlow/cboxLoad', "", obj);
    return data;
  } catch (error) {
    return error;
  }
}

async function obtenerCuentaPorNumero(objeto: any): Promise<any> {
  try {
    const obj = {
      numero: objeto['numero'],
    }
    const data = await RequestHelper.getAll<any>('generales', 'accountPlan/getAccount', "", obj);
    return data;
  } catch (error) {
    return error;
  }
}

async function obtenerCuentaNumero(categoria: any): Promise<any> {
  try {
    const obj = {
      padre: categoria,
    }
    const data = await RequestHelper.getAll<any>('contabilidad', 'accountsPlan/getLastChild', "", obj);
    return data;
  } catch (error) {
    return error;
  }
}

async function obtenerTipos(categoria: any): Promise<any> {
  try {
    const obj = {
      estado: categoria,
    }
    const data = await RequestHelper.getAll<any>('contabilidad', 'typeAccount/getAll', "", obj);
    return data;
  } catch (error) {
    return error;
  }
}

async function obtenerCategorias(categoria: any): Promise<any> {
  try {
    const obj = {
      tipoCodigo: categoria,
    }
    const data = await RequestHelper.getAll<any>('subtypes', 'cboxLoad', "", obj);
    return data;
  } catch (error) {
    return error;
  }
}

async function crearRegistro(objeto: any): Promise<any> {
  try {

    const obj = {
      infoRegistro: {
        pla_categoria: objeto['pla_categoria'],
        pla_codigo: objeto['pla_codigo'],
        pla_descidioma: objeto['pla_descidioma'],
        pla_descripcion: objeto['pla_descripcion'],
        pla_estado: objeto['pla_estado'],
        pla_fiscal: objeto['pla_fiscal'],
        pla_numero: objeto['pla_numero'],
        pla_operacion: objeto['pla_operacion'],
        pla_padre: objeto['pla_padre'],
        plf_numero: objeto['plf_numero'],
        tip_codigo: objeto['tip_codigo'],
      }
    }
    const data = await RequestHelper.postAll<any>('contabilidad', 'accountsPlan/save', obj);
    return data;
  } catch (error) {
    return error;
  }
}

async function createImport(archivo: any): Promise<any> {
  try {
    const obj = {
      archivo: archivo['file'],
    }
    const data = await RequestHelper.postData<any>('contabilidad', 'plandecuentas/importar', obj);
    return data;
  } catch (error) {
    return error;
  }
}

async function deleteRegistro(idCuenta: any): Promise<any> {
  try {
    const objRequest = {
      codigo: idCuenta
    }
    const data = await RequestHelper.deleteRequestAll<any>('contabilidad', 'accountsPlan/delete', "", objRequest);
    return data;
  } catch (error) {
    return error;
  }
}

async function asignarJerarquia(): Promise<any> {
  try {
    const objRequest = {
    }
    const data = await RequestHelper.putUrlEncodedAll<any>('contabilidad', 'accountsPlan/assignParents', objRequest);
    return data;
  } catch (error) {
    return error;
  }
}

async function asignarNiveles(): Promise<any> {
  try {
    const objRequest = {
    }
    const data = await RequestHelper.putUrlEncodedAll<any>('contabilidad', 'accountsPlan/asignNivels', objRequest);
    return data;
  } catch (error) {
    return error;
  }
}
