import { RequestHelper } from "../../../../helpers/requestHelper";

export const puntosVentaService = { getPuntosVenta };

async function getPuntosVenta(label: string): Promise<any> {
  try {
    const data: any = {
      mensaje: label
    };
    const res = await RequestHelper.getAll<any>('generales/pointSale', 'comboLoad', "", data);
    return res;
  } catch (error) {
    return error;
  }
}

