import { DateUtils, formatoFechasApi } from '../../../../helpers/dateUtils'
import { RequestHelper } from '../../../../helpers/requestHelper'
import { utilidades } from '../../../../helpers/utilidades'
import { SesionService } from '../../../../services/sesion.service'
import { ESubtipo } from '../../../../store/enum/subtypes'
import { Subtipo } from '../../gruposUnidadesLookUp/types/types'

export const typesConceptsMovementsServices = { getTypesMovements }

async function getTypesMovements(): Promise<any> {
  try {
    const data: any = {
      mensaje: 'Todos',
      tipoCodigo: ESubtipo.ConceptosMovimientos,
    }
    const sesion = SesionService.getCurrentSesion()
    const keysToCache: any = {
      ...data,
      dateKey: DateUtils.getCurrentDateAsString(formatoFechasApi),
      empresa: sesion?.empresa?.codigo,
    }
    const cacheKey = 'tiposConceptosMovimientos' + JSON.stringify(keysToCache)
    const cached = sessionStorage.getItem(cacheKey)
    if (cached) {
      return { error: false, auto: JSON.parse(cached) }
    } else {
      const typesConceptsMovementsData = await RequestHelper.getAll<any>(
        'subtypes',
        'cboxLoad',
        '',
        data,
      )
      const typesConceptsMovements: Array<Subtipo> = []
      if (typesConceptsMovementsData?.auto?.length > 0) {
        for (const typeConceptMovement of typesConceptsMovementsData.auto) {
          typesConceptsMovements.push({
            codigo: typeConceptMovement?.codigo ?? -1,
            valor: utilidades.capText(typeConceptMovement?.valor) ?? '',
            tipoCodigo: typeConceptMovement?.tipoCodigo ?? '',
            grupo: typeConceptMovement?.grupo ?? '',
            orden: typeConceptMovement?.orden ?? -1,
            estado: typeConceptMovement?.estado ?? '',
          })
        }
        sessionStorage.setItem(cacheKey, JSON.stringify(typesConceptsMovements))
        return { error: false, auto: typesConceptsMovements }
      } else
        return {
          error: true,
          message: typesConceptsMovementsData.message
            ? typesConceptsMovementsData.message
            : 'Error al obtrner los datos',
        }
    }
  } catch (error) {
    return error
  }
}
