import React, { useEffect } from 'react'
import { GlobalMenu } from '../../../../../views/componentes/globalMenu/globalMenu'
import {
  AccionMenu,
  CustomButtons,
  FETCH_STATUS,
  ToastTypes,
} from '../../../../../store/types'
import CardContent from '../../../../bancos/components/cardContent'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../../../store/store'
import { GemasDatosEdicion } from './types/types'
import { changeLoaderGems, setCurrentFunction } from './store/generalReducer'
import { addToast } from '../../../../../store/toasterReducer'
import TabControl from './components/shared'
import { Aplicacion } from '../../../store/types'
import { utilidades } from '../../../../../helpers/utilidades'
import {
  changeCurrentTab,
  closeTabAtributos,
  openTab,
  setButtons,
  setCurrentExecutingAction,
} from './store/tabsReducer'
import { ButtonTypes } from '../../../../../views/componentes/globalMenu/types'
import { v4 as uuidv4 } from 'uuid'
import { deleteEditData, initDatosEdicion } from './store/editDataReducer'
import { fetcGemas, setCleanResult } from './store/serachReducer'
import Dialog from '../../../../../views/componentes/librerias/bootstrap-dialog'
import {
  useButtonDisabled,
  useMenuOptions,
} from '../../../../../hooks/useMenuButtonControls'
import { setCodigoModulo } from './store/configReducer'
import { EAplicationsAcatha } from '../../../../../store/enumsAplication'

const pathVerificador = '/caracteristicas_gemas'

interface CaracteristicasGemasProps extends React.PropsWithChildren {
  onSendData?: (data: any) => void
  modal?: boolean
}

const CaracteristicasGemas: React.FunctionComponent<
  CaracteristicasGemasProps
> = (props) => {
  const { onSendData, modal } = props

  const dispatch = useDispatch()
  const menuState = useSelector((state: RootState) => state.global.menu)
  const tabs = useSelector((state: RootState) => {
    return state.inventarios.catalogos.gemas.tabs
  })
  const loader = useSelector(
    (state: RootState) => state.inventarios.catalogos.gemas.general.loader,
  )
  const searchFilter = useSelector((state: RootState) => state.inventarios.catalogos.gemas.search.filter)
  const searchStatus = useSelector((state: RootState) => state.inventarios.catalogos.gemas.search.status)
  const searchResults = useSelector((state: RootState) => state.inventarios.catalogos.gemas.search.resultados)
  const selected = useSelector((state: RootState) => state.inventarios.catalogos.gemas.search.seleccionado)

  const [confirmDelete, setConfirmDelete] = React.useState<boolean>(false)
  const [confirmEdit, setConfirmEdit] = React.useState<boolean>(false)
  const dialogRef = React.useRef<any>(null)
  const getButtonDisabled = useButtonDisabled(tabs)
  const { getMenuOptions, options } = useMenuOptions()
  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(
      addToast({
        title: 'Inventario - Gemas',
        content: mensaje,
        type: tipo,
      }),
    )
  }, [dispatch])
  const playLoader = React.useCallback(
    (message = 'Cargando...') => {
      dispatch(changeLoaderGems({ show: true, mensaje: message }))
    },
    [dispatch],
  )

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoaderGems({ show: false, mensaje: '' }))
  }, [dispatch])

  const onTabClosing = React.useCallback(
    async (index) => {
      const closedTab = tabs.tabs[index]
      dispatch(closeTabAtributos(closedTab.tabKey ?? index))
      await utilidades.sleep(50)
      dispatch(deleteEditData(closedTab.tabKey ?? index))
    },
    [dispatch, tabs.tabs],
  )

  const onTabChanged = React.useCallback(
    (tabKey: string) => {
      dispatch(changeCurrentTab(tabKey))
    },
    [dispatch],
  )

  const onCleanResultFilter = React.useCallback(() => {
    dispatch(setCleanResult())
  }, [dispatch])

  const onConfirmEdit = React.useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea Editar el registro?`,
      actions: [
        Dialog.Action(
          <span>
            <u>A</u>ceptar
          </span>,
          (dialog) => {
            dialog.hide()
            dispatch(setCurrentFunction(ButtonTypes.edit))
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setConfirmEdit(false)
    return
  }, [dispatch])

  const onConfirmDelete = React.useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea Eliminar el Registro?`,
      actions: [
        Dialog.Action(
          <span>
            <u>A</u>ceptar
          </span>,
          (dialog) => {
            dialog.hide()
            dispatch(setCurrentFunction(ButtonTypes.delete))
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setConfirmDelete(false)
    return
  }, [dispatch])

  React.useEffect(() => {
    if (confirmEdit) {
      onConfirmEdit()
    }
  }, [confirmEdit, onConfirmEdit])

  React.useEffect(() => {
    if (confirmDelete) {
      onConfirmDelete()
    }
  }, [confirmDelete, onConfirmDelete])

  const onSearch = React.useCallback(async () => {
    try {
      const search_data = {
        tipo: searchFilter.estado?.value ?? 0,
        descripcion: searchFilter?.descripcion ?? '',
      }

      const toAny: any = fetcGemas(search_data)
      const res = await dispatch(toAny)
      if (res !== null && res !== undefined && res['payload']?.error === false) {
        setToast(res['payload']?.message, ToastTypes.Success)
      } else {
        setToast(res['payload']?.message, ToastTypes.Danger)
      }
    } catch (error) {
      setToast(error.message.message, ToastTypes.Danger)
    }
  }, [searchFilter, dispatch, setToast])

  const onMenuButtonClick = React.useCallback(
    async (action: AccionMenu) => {
      const id = uuidv4()
      switch (action.actionType) {
        case ButtonTypes.find:
          if (tabs.current === 'BUSQUEDA') {
            onSearch()
          } else dispatch(changeCurrentTab('BUSQUEDA'))
          break
        case ButtonTypes.new:
          if (tabs.current === 'BUSQUEDA') {
            dispatch(
              initDatosEdicion({
                key: id,
                data: defaultDataAplications,
              }),
            )
            dispatch(openTab({ key: id }))
          } else {
            dispatch(setCurrentFunction(ButtonTypes.new))
          }
          break
        case ButtonTypes.save:
          if (tabs.current !== 'BUSQUEDA')
            dispatch(setCurrentFunction(ButtonTypes.save))
          break
        case ButtonTypes.edit:
          if (tabs.current === 'BUSQUEDA') setConfirmEdit(true)
          break
        case ButtonTypes.delete:
          if (tabs.current === 'BUSQUEDA') setConfirmDelete(true)
          break
        case ButtonTypes.broom:
          if (tabs.current === 'BUSQUEDA') onCleanResultFilter()
          else dispatch(setCurrentFunction(ButtonTypes.broom))
          break
        default:
          break
      }
    },
    [dispatch, onCleanResultFilter, tabs, onSearch],
  )

  const onGetPupUpData = React.useCallback(() => {
    if (selected.row !== null && selected.row !== undefined) {
      const data = selected.row
      onSendData(data)
    }
  }, [selected, onSendData])

  useEffect(() => {
    if (menuState.length > 0 && options.length === 0) {
      getMenuOptions(menuState, 'to', pathVerificador)
    }
  }, [menuState, getMenuOptions, options])


  useEffect(() => {
    if (modal !== null && modal !== undefined && modal) {
      onGetPupUpData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected.row, modal])

  React.useEffect(() => {
    if (searchStatus === FETCH_STATUS.FAILED) {
      dispatch(
        addToast({
          title: 'Buscar clientes',
          content: 'Error al realizar la búsqueda',
          type: ToastTypes.Danger,
        }),
      )
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'BUSQUEDA',
          buttonType: undefined,
        }),
      )
    }
    if (searchStatus === FETCH_STATUS.LOADING) {
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'BUSQUEDA',
          buttonType: ButtonTypes.find,
        }),
      )
    }
    if (searchStatus === FETCH_STATUS.SUCCESS) {
      dispatch(
        setButtons({
          tabKey: 'BUSQUEDA',
          buttons: {
            Nuevo: true,
            Guardar: false,
            Editar: selected.row !== null && selected.row !== undefined,
            Buscar: true,
            Limpiar: true,
          },
        }),
      )
    }
  }, [dispatch, searchStatus, playLoader, searchResults, stopLoader, selected.row])

  const optionsForm = () => {
    dispatch(setCodigoModulo(parseInt(EAplicationsAcatha.CaracteristicasGemas)))
    if (options.length > 0) {
      return (
        <>
          <GlobalMenu
            printButtonAsDropdown={false}
            showExportDesingOptions
            acciones={options}
            onClick={onMenuButtonClick}
            getButtonDisabled={getButtonDisabled}
            currentWorking={tabs.tabs[tabs.current].currentExecutingAction}
          />
        </>
      )
    } else {
      return <> </>
    }
  }

  const bodyForm = () => {
    return (
      <TabControl
        tabsState={tabs}
        onTabChanged={onTabChanged}
        ontabClosing={onTabClosing}
        tipoAplicacion={Aplicacion.catalogosGemas}
      />
    )
  }

  return (
    <>
      <div id={'catalogosGemas'}>
        <Dialog ref={dialogRef} />
        <CardContent
          childrenOptions={optionsForm()}
          childrenBody={bodyForm()}
          headerTitle={'Características Gemas'}
          aplicacion={'Características Gemas'}
          loader={loader}
        />
      </div>
    </>
  )
}

export default CaracteristicasGemas

export const ModuleButtons: CustomButtons = {
  Nuevo: true,
  Guardar: true,
  Editar: false,
  Buscar: false,
  Limpiar: true,
}

export const defaultDataAplications: GemasDatosEdicion = {
  codigo: 0,
  descripcion: '',
  pertenece: null,
  estado: null,
  observaciones: '',
  engaste: { descripcion: '', estado: null },
  corte: { descripcion: '', estado: null },
  posicion: { descripcion: '', estado: null },
  tabs: ['Engaste Gemas', 'Corte Gemas', 'Posición Gemas'],
  currentTab: 'Engaste Gemas',
  guardado: false,
}
