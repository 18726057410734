import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EdicionPayload } from '../../../../store/types'
import { ConceptiosMovEdicion } from '../types/types'
import { CustomDictionary } from '../../../../../../store/types'

const initialState: CustomDictionary<ConceptiosMovEdicion> = {}

const datosEditSlice = createSlice({
  name: 'conceptosDatosEdicion',
  initialState: initialState,
  reducers: {
    deleteEditData(state, action: PayloadAction<string>) {
      delete state[action.payload]
    },
    setDatosEdicion(
      state,
      action: PayloadAction<{
        key: string
        concepto: ConceptiosMovEdicion
      }>,
    ) {
      state[action.payload.key] = action.payload.concepto
    },
    initDatosEdicion(
      state,
      action: PayloadAction<EdicionPayload<ConceptiosMovEdicion>>,
    ) {
      if (!state[action.payload.key]) {
        state[action.payload.key] = action.payload.data
      }
    },
  },
})

export const { setDatosEdicion, initDatosEdicion, deleteEditData } =
  datosEditSlice.actions
export const dataEditReducer = datosEditSlice.reducer
