import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { StatesEdition, TabTypes } from "../../../../../../store/enums";
import { TabState, TabsState } from "../../../../../../store/genericTabTypes";
import { ButtonTypes } from "../../../../../../views/componentes/globalMenu/types";
import { DecimosDatosEdicion } from "../types/types";
import { closeTab, tabExists, tabIsVisible } from "../../../../../../hooks/useNavigationTabControls";
import { ModuleButtons } from "../../rolesPago";
import { CustomButtons } from "../../../../../../store/types";
import { ModuleButtonsAll } from "../decimo";

const initialState: TabsState<DecimosDatosEdicion> = {
  current: 'BUSQUEDA',
  tabs: {
    BUSQUEDA: {
      id: 0,
      canClose: false,
      editStatus: StatesEdition.doesNotApply,
      globalButtonClick: ButtonTypes.none,
      tittle: 'Búsqueda',
      type: TabTypes.busqueda,
      imprimir: false,
      tabKey: 'BUSQUEDA',
      buttons: {
        Nuevo: true,
        Buscar: true,
        Editar: false,
        Eliminar: false,
        Guardar: false,
        Imprimir: false,
        Cheque: false,
        Ver_asiento: false,
        Transferencias: true,
        Pagos: false,
        Sueldos: true,
        Importar: true,
        Roles: false,
        Todos: true,
        Deshacer: true,
        Limpiar: false,
        Adjuntar: false,
      },
    },
  },
}

const decimosTabsSlice = createSlice({
  name: 'decimosTabs',
  initialState: initialState,
  reducers: {
    decimosCloseTab: closeTab,
    openTab(
      state,
      action: PayloadAction<{
        key: string
        store?: DecimosDatosEdicion
        templateMode?: boolean
      }>,
    ) {
      const { key, store, templateMode } = action.payload
      if (action.payload.store === undefined || action.payload.templateMode) {
        const tab: TabState<DecimosDatosEdicion> = {
          id: -1,
          canClose: true,
          editStatus: StatesEdition.new,
          globalButtonClick: ButtonTypes.none,
          tittle: 'Nueva',
          type: TabTypes.documento,
          imprimir: false,
          tabKey: key,
          info: {
            codigo: 0,
          },
          buttons: ModuleButtons,
          templateMode: templateMode,
        }
        state.tabs[key] = tab
        state.current = key
      } else {
        const tabKey = tabExists(state.tabs, store.codigo)
        if (tabKey) {
          state.current = tabKey
        } else {
          const tab: TabState<DecimosDatosEdicion> = {
            tabKey: action.payload.key,
            id: store.codigo,
            tittle: `${action.payload.store.codigo}-${action.payload.store.empleado}`,
            canClose: true,
            editStatus: StatesEdition.save,
            type: TabTypes.documento,
            info: {
              codigo: store.codigo,
              info: store,
            },
            globalButtonClick: ButtonTypes.none,
            imprimir: false,
            buttons: ModuleButtons,
          }
          state.tabs[key] = tab
          state.current = key
        }
      }
    },
    openTabTransfer(
      state,
      action: PayloadAction<{
        key: string
        type: TabTypes
      }>,
    ) {
      const tabKey = tabIsVisible(state.tabs, action.payload.type)
      if (tabKey) state.current = tabKey
      else {
        const tab: TabState<any> = {
          id: -1,
          tittle: 'Transferencias',
          type: action.payload.type,
          info: {
            codigo: 0,
          },
          canClose: true,
          editStatus: StatesEdition.doesNotApply,
          globalButtonClick: ButtonTypes.none,
          imprimir: false,
          tabKey: action.payload.key,
          buttons: ModuleButtons,
        }
        state.tabs[action.payload.key] = tab
        state.current = action.payload.key
      }
    },
    openTabTodos(
      state,
      action: PayloadAction<{
        key: string
        type: TabTypes
      }>,
    ) {
      const tabKey = tabIsVisible(state.tabs, action.payload.type)
      if (tabKey) state.current = tabKey
      else {
        const tab: TabState<any> = {
          id: -1,
          tittle: 'Todos',
          type: action.payload.type,
          info: {
            codigo: 0,
          },
          canClose: true,
          editStatus: StatesEdition.doesNotApply,
          globalButtonClick: ButtonTypes.none,
          imprimir: false,
          tabKey: action.payload.key,
          buttons: ModuleButtonsAll,
        }
        state.tabs[action.payload.key] = tab
        state.current = action.payload.key
      }
    },
    setNameTab(
      state,
      action: PayloadAction<{ key: string; codigo: number; nombre: string }>,
    ) {
      state.tabs[
        action.payload.key
      ].tittle = `${action.payload.codigo}-${action.payload.nombre}`
      state.tabs[action.payload.key].info.codigo = action.payload.codigo
    },
    setCurrentExecutingAction(
      state,
      action: PayloadAction<{
        tabKey: string
        buttonType: ButtonTypes | undefined
      }>,
    ) {
      const { buttonType, tabKey } = action.payload
      state.tabs[tabKey].currentExecutingAction = buttonType
    },
    setButtons(
      state,
      action: PayloadAction<{ tabKey: string; buttons: CustomButtons }>,
    ) {
      const { buttons, tabKey } = action.payload
      state.tabs[tabKey].buttons = buttons
      state.tabs[tabKey].currentExecutingAction = undefined
    },
    buttonClick(
      state,
      action: PayloadAction<{ tabKey: string; button: ButtonTypes }>,
    ) {
      state.tabs[action.payload.tabKey].globalButtonClick =
        action.payload.button
    },
    clearButtonClick(state, action: PayloadAction<string>) {
      if (Object.prototype.hasOwnProperty.call(state.tabs, action.payload)) {
        state.tabs[action.payload].globalButtonClick = ButtonTypes.none
      }
    },
    changeCurrentTab(state, action: PayloadAction<string>) {
      if (Object.prototype.hasOwnProperty.call(state.tabs, action.payload)) {
        state.current = action.payload
      }
    },
  }
})


export const {
  decimosCloseTab,
  openTab,
  changeCurrentTab,
  setCurrentExecutingAction,
  setButtons,
  buttonClick,
  clearButtonClick,
  setNameTab,
  openTabTransfer,
  openTabTodos,
} = decimosTabsSlice.actions
export const tabsReducer = decimosTabsSlice.reducer