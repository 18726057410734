import React from 'react'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput'
import TiposConceptosMovLookUp from '../../../../../../componentes/tiposConceptosMovLookUp'
import { TextBox } from 'devextreme-react'
import { CCardBody, CCardHeader, CCollapse, CLink } from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilChevronBottom, cilChevronTop } from '@coreui/icons'
import { useDispatch, useSelector } from 'react-redux'
import { changeFilter, setCollapsed } from '../../store/serachReducer'
import { RootState } from '../../../../../../../store/store'


interface IFiltrosBusquedaProps extends React.PropsWithChildren {
  onHandleSearch: () => void
}

const FiltrosBusqueda: React.FunctionComponent<IFiltrosBusquedaProps> = (props) => {
  const { onHandleSearch } = props
  const dispatch = useDispatch()
  const collapsed = useSelector(
    (state: RootState) =>
      state.inventarios.movimientos.conceptos.search.colapsed,
  )
  const searchFilter = useSelector(
    (state: RootState) =>
      state.inventarios.movimientos.conceptos.search.filter,
  )

  return (
    <>
      <CCardHeader
        onClick={() => dispatch(setCollapsed(!collapsed))}
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <strong> {collapsed ? '' : 'Filtro de Búsqueda'} </strong>
        <CLink className="card-header-action">
          <CIcon icon={collapsed ? cilChevronBottom : cilChevronTop} />
        </CLink>
      </CCardHeader>
      <CCollapse visible={collapsed}>
        <CCardBody>
          <RowContainer>
            <CustomCol xs="12" md="3">
              <Labeled label="Tipos:">
                <TiposConceptosMovLookUp
                  selected={searchFilter?.tipo ?? null}
                  onChanged={(data) => {
                    dispatch(changeFilter({
                      ...searchFilter,
                      tipo: data
                    }))
                  }}
                  onChangedOptions={() => { }}
                />
              </Labeled>
            </CustomCol>
            <CustomCol xs="12" md="4">
              <Labeled label="Descripción:">
                <TextBox
                  value={searchFilter?.descripcion ?? ''}
                  onValueChange={(data) => {
                    dispatch(changeFilter({
                      ...searchFilter,
                      descripcion: data
                    }))
                  }}
                  onEnterKey={onHandleSearch}
                />
              </Labeled>
            </CustomCol>
          </RowContainer>
        </CCardBody>
      </CCollapse>
    </>
  )
}

export default React.memo(FiltrosBusqueda)