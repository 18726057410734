import { useState, useEffect } from 'react'

const useValidarCuotasVencidas = (cuotasVencidas: any[]) => {
 const [valorSaldoVencidas, setValorSaldoVencidas] = useState(0)
 const [inmediato, setInmediato] = useState(0)

 useEffect(() => {
  let totalSaldoVencidas = 0
  let totalInmediato = 0

  if (cuotasVencidas.length > 0) {
   cuotasVencidas.forEach((key: any) => {
    if (key.pagoInmediato === true) {
     totalInmediato += Number(key.vencido)
    }
    totalSaldoVencidas += key.saldo
   })

   if (totalSaldoVencidas > 0) {
    totalSaldoVencidas = Number(parseFloat(totalSaldoVencidas.toFixed(2))) + 0
   }
  }

  setInmediato(totalInmediato)
  setValorSaldoVencidas(totalSaldoVencidas)
 }, [cuotasVencidas])

 return { valorSaldoVencidas, inmediato }
}

export default useValidarCuotasVencidas
