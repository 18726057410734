const messagges = {
  successUpdated: 'Cambios realizados correctamente.',
  successUploaded: 'El archivo se subió  correctamente.',
  sinCambiosEnFormulario:
    'No se encontró ningún formulario para aplicar los cambios.',
  dontConect: 'Lo sentimos, se produjo un error al acceder a la consulta.',
  userNoSigned: 'Lo sentimos, el usuario no ha iniciado sesión en este equipo.',
  logoutSignedOtherdevice: 'La cuenta está siendo utilizada en otro lugar.',
  confirmCloseModule: '¿Desea salir sin aplicar los cambios?',
  confirmUpdate: 'Está seguro que desea editar la información',
  confirmUpdateComprobante: '¿Está seguro que desea editar el comprobante?',
  confirmDelete: 'Está seguro que desea eliminar este registro',
  confirmCambio: '¿Está seguro que desea realizar este cambio?',
  confirmDeleteTransaccs: '¿Está seguro que desea eliminar %num%?',
  confirmDesactive: '¿Está seguro que desea desactivar este registro?',
  closeModulo: '¿Está seguro que desea salir de este modulo?',
  sinRegistros: 'No hemos podido obtener ningún registro, intente más tarde',
  noAuth:
    'No pudimos iniciar su sesión. Compruebe su nombre de usuario y/o contraseña.',
  cardSingUp: {
    Titulo: 'No tienes cuenta',
    Descripcion:
      'Si aun no puedes disfrutar de los servicios de ácatha junto con nuestro centro de formación acathemia y nuestro mall virtual pideuna.com te invitamos a registrarte con nosotros',
    Boton: 'Registrate ya!',
    Enlace: 'https://acatha.com/#pricing',
  },
  titleConfirmAlert: 'ácatha',
  sesionExpired:
    'Tu sesión ha expirado. Por favor, identifícate de nuevo para continuar donde lo dejaste',
  titlePopups: 'Acatha',
  revisarCorreo: 'Revisa tu correo por favor.',
  revisarCorreoPorFavor: 'Por favor revisa tu correo electrónico.',
  mailNoEnviado:
    'No se pudo enviar el código de verificación a tu correo electrónico.',
  ingresarCodigoCompleto: 'Ingresar el código completo por favor.',
  codigoCaducado: 'El Código de verificación ha caducado.',
  caracterNoPermitido: 'Caracter no permitido.',
  enhorabuena: 'En hora buena',
  codigoIncorrecto: 'Código incorrecto',
  selectEmpresa: 'Seleccione una empresa favor',
  selectLocal: 'Seleccione un local por favor',
  bienvenido: 'Bienvenido administrador',
  enlaceRoto: 'El enlace no es correcto.',
  validaciones: {
    password: {
      minLen: 'La clave deve tener al menos 8 caracteres.',
      mayusculas: 'Incluir letras mayusculas en su clave.',
      minusculas: 'Incluir letras minusculas en su clave.',
      numeros: 'Incluir letras minusculas en su clave.',
      caracteres: 'Incluir caracteres especiales en su clave.',
    },
    menyMails: {
      errFormat:
        "El formato de E-Mail es incorrecto (Puede separar diferentes correos con ';').",
      requiredMail: 'Debe de ingresar el correo para el cliente.',
    },
  },
  ingresarUsusario: 'Ingresar el usuario por favor.',
  selectInfoTable: 'Por favor seleccionar una opción en la tabla para editar.',
  selectInfoDelete:
    'Por favor seleccionar una opción en la tabla para eliminarla.',
  selectInfoEnable:
    'Por favor seleccionar una opción en la tabla para desactivarla.',
  selectInfTable: 'Por favor seleccionar una opción en la tabla.',
  errorsFound:
    'Se encontraron algunos errores, por favor corregirlos para poder continuar.',
  dataNotFound:
    'No se encontró información para registrarla, por favor revisela para poder continuar.',
  accNoPermitidDesdeAqui: 'Esta acción no es permitida desde este formulario.',
  confirmeCambioFormulario: '¿Está seguro que desea salir de este formulario?',
  pleaseCorrection: 'Por favor corregir los errores encontrados.',
  resultSearch: 'Resultados en la búsqueda.',
  dataNotFoudFiltered: 'No se encontraron registros en la tabla.',
  dataNotFoudExport: 'No se encontraron registros para exportar.',
  dataNotFoundSelected:
    'No se encontró ningún registro seleccionado, por favor seleccione un registro e intente nuevamente.',
  transacNotFoundForDelete:
    'No se encontraron transacciones seleccionadas para elimininarlas.',
  dataCantNotExport: 'Imposible exportar archivo',
  renumerarConfirm: '¿Está seguro que desea renumerar los asientos?',
  restaureConfirm: '¿Está seguro que desea restaurar el registro?',
  actionNotAllow: 'Acción  no permitida desde este formulario.',
  pleaseSelectAnticipAsoc: 'Por favor seleccionar un anticipo para asociarlo.',
  pleaseSelectAnticipDesAsoc: 'Debe elegir un registro para deshabilitarlo.',
  valueAnticipMax: 'El valor del anticipo no puede ser mayor al saldo.',
  anticipAsocSuccess: 'Anticipo asociado correctamente.',
  desAsocAnticipo: 'Está seguro que desea eliminar el registro?',
  anticipAsocRemoved: 'Anticipo removido correctamente.',
  confirmDuplicado: 'Está seguro que desea duplicar el comprobante?',
  reestaurarAsiento:
    'No encontramos el asiento relacionado, se intentará generar en este momento.',
  reestaurarAsientoConfirm: 'Está seguro que desea regenerar este asiento.',
  formatoExcel: {
    header: {
      titulo: 'AUSTROSOFT CIA LTDA.',
      subtitulo: 'EXPRESADO EN USD',
      fecha: '',
    },
    footer: {
      textFooter: 'Exportado por: ',
    },
  },
  labelsTables: {
    es: {
      body: {
        noMatch: 'No hemos podido obtener ningún registro, intente más tarde',
        toolTip: 'Ordenar',
      },
      pagination: {
        next: 'Página siguiente',
        previous: 'Página anterior',
        rowsPerPage: 'Filas por página:',
        displayRows: 'de',
      },
      toolbar: {
        search: 'Buscar',
        downloadCsv: 'Descargar',
        print: 'Imprimir',
        viewColumns: 'Ver columnas',
        filterTable: 'Tabla de filtros',
      },
      filter: {
        all: 'TODOS',
        title: 'FILTROS',
        reset: 'RESET',
      },
      viewColumns: {
        title: 'Mostrar columnas',
        titleAria: 'Mostrar / Ocultar columnas de tabla',
      },
      selectedRows: {
        text: 'fila (s) seleccionada (s)',
        delete: 'Eliminar',
        deleteAria: 'Eliminar filas seleccionadas',
      },
    },
  },
  ventas: {
    notFoundPuntoVenta:
      'Punto de Venta(POS) no registrado, desea registrarlo en este momento?',
  },
}

export default messagges
