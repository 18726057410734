/* eslint-disable */
import React from 'react';
import ReactDataSheet from 'react-datasheet';


/**Clase genérica para el datasheet */
// type generic && <Text, React.PropsWithChildren>

type ComponentProps<T> = T & React.PropsWithChildren;

export default class GenericDatasheet<ComponentProps> extends ReactDataSheet<ComponentProps> {

}
