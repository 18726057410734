import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ButtonTypes } from '../../../../../views/componentes/globalMenu/types'
import { StatesEdition, TabTypes } from '../../../../ventas/types/enums'
import { DocumentosListado } from '../types/types'
import { ModuleButtonsImport } from '../components/importar'
import { ModuleButtons } from '../components/documento'
import { ModuleButtonsAbonar } from '../components/abonar'
import { ModuleButtonsAnticipos } from '../components/anticipos'
import { TabsState, TabState } from '../../../../../store/genericTabTypes'
import {
  closeTab,
  tabExists,
  tabIsVisible,
} from '../../../../../hooks/useNavigationTabControls'
import { CustomButtons } from '../../../../../store/types'
import { ModuleButtonsVerPagos } from '../components/verPagos/filtroBusqueda'
import { ModuleButtonsSaldos } from '../components/saldos'
import { ModuleButtonsCartera } from '../components/corteComprobantes'

const initialState: TabsState<DocumentosListado> = {
  current: 'BUSQUEDA',
  tabs: {
    BUSQUEDA: {
      id: 0,
      canClose: false,
      editStatus: StatesEdition.doesNotApply,
      globalButtonClick: ButtonTypes.none,
      tittle: 'Búsqueda',
      type: TabTypes.busqueda,
      imprimir: false,
      tabKey: 'BUSQUEDA',
      buttons: {
        Buscar: true,
        Nuevo: false,
        Imprimir: true,
        Imprimir_Diseño: true,
        Exportar: false,
        Importar: false,
        Abonar: true,
        Anticipos: true,
        Saldos: true,
        Cartera: true,
        Ver_abonos: true,
        Limpiar: true,
      },
    },
  },
}

const tabsDocumentosSlice = createSlice({
  name: 'documentosTabs',
  initialState: initialState,
  reducers: {
    ctasPagarCloseTab: closeTab,
    openTab(
      state,
      action: PayloadAction<{
        key: string
        cliente?: DocumentosListado
        templateMode?: boolean
      }>,
    ) {
      if (action.payload.cliente === undefined || action.payload.templateMode) {
        const tab: TabState<DocumentosListado> = {
          id: -1,
          canClose: true,
          editStatus: StatesEdition.new,
          globalButtonClick: ButtonTypes.none,
          tittle: 'Nueva',
          type: TabTypes.documento,
          imprimir: false,
          tabKey: action.payload.key,
          info: {
            codigo: 0,
          },
          buttons: ModuleButtons,
          templateMode: action.payload.templateMode,
        }
        state.tabs[action.payload.key] = tab
        state.current = action.payload.key
      } else {
        const tabKey = tabExists(state.tabs, action.payload.cliente.codigo)
        if (tabKey) {
          state.current = tabKey
        } else {
          const tab: TabState<DocumentosListado> = {
            tabKey: action.payload.key,
            id: action.payload.cliente.codigo,
            tittle: `${action.payload.cliente.numero}-${action.payload.cliente.numero}-${action.payload.cliente.codigo}`,
            canClose: true,
            editStatus: StatesEdition.save,
            type: TabTypes.documento,
            info: {
              codigo: action.payload.cliente.codigo,
              info: action.payload.cliente,
            },
            globalButtonClick: ButtonTypes.none,
            imprimir: false,
            buttons: ModuleButtons,
          }
          state.tabs[action.payload.key] = tab
          state.current = action.payload.key
        }
      }
    },
    openTabDtosPagar(
      state,
      action: PayloadAction<{
        key: string
        type: TabTypes
      }>,
    ) {
      let title: string = ''
      let buttonsTab: CustomButtons = null
      if (action.payload.type === TabTypes.import) {
        title = 'Importar'
        buttonsTab = ModuleButtonsImport
      } else if (action.payload.type === TabTypes.abonar) {
        title = 'Abonar'
        buttonsTab = ModuleButtonsAbonar
      } else if (action.payload.type === TabTypes.anticipos) {
        title = 'Anticipos'
        buttonsTab = ModuleButtonsAnticipos
      } else if (action.payload.type === TabTypes.saldos) {
        title = 'Saldos'
        buttonsTab = ModuleButtonsSaldos
      } else if (action.payload.type === TabTypes.cartera) {
        title = 'Cartera'
        buttonsTab = ModuleButtonsCartera
      } else if (action.payload.type === TabTypes.verAbonos) {
        title = 'Ver Abonos'
        buttonsTab = ModuleButtonsVerPagos
      }
      const tabKey = tabIsVisible(state.tabs, action.payload.type)
      if (tabKey) state.current = tabKey
      else {
        const tab: TabState<DocumentosListado> = {
          id: -1,
          tittle: title,
          type: action.payload.type,
          info: {
            codigo: 0,
          },
          canClose: true,
          editStatus: StatesEdition.new,
          globalButtonClick: ButtonTypes.none,
          imprimir: false,
          tabKey: action.payload.key,
          buttons: buttonsTab,
        }
        state.tabs[action.payload.key] = tab
        state.current = action.payload.key
      }
    },
    changeCurrentTab(state, action: PayloadAction<string>) {
      if (Object.prototype.hasOwnProperty.call(state.tabs, action.payload)) {
        state.current = action.payload
      }
    },
    setCurrentExecutingAction(
      state,
      action: PayloadAction<{
        tabKey: string
        buttonType: ButtonTypes | undefined
      }>,
    ) {
      const { buttonType, tabKey } = action.payload
      state.tabs[tabKey].currentExecutingAction = buttonType
    },
    setButtons(
      state,
      action: PayloadAction<{ tabKey: string; buttons: CustomButtons }>,
    ) {
      const { buttons, tabKey } = action.payload
      state.tabs[tabKey].buttons = buttons
      state.tabs[tabKey].currentExecutingAction = undefined
    },
    buttonClick(
      state,
      action: PayloadAction<{ tabKey: string; button: ButtonTypes }>,
    ) {
      state.tabs[action.payload.tabKey].globalButtonClick =
        action.payload.button
    },
    clearButtonClick(state, action: PayloadAction<string>) {
      if (Object.prototype.hasOwnProperty.call(state.tabs, action.payload)) {
        state.tabs[action.payload].globalButtonClick = ButtonTypes.none
      }
    },
  },
})

export const {
  ctasPagarCloseTab,
  openTab,
  changeCurrentTab,
  setCurrentExecutingAction,
  setButtons,
  buttonClick,
  openTabDtosPagar,
  clearButtonClick,
} = tabsDocumentosSlice.actions
export const tabsReducer = tabsDocumentosSlice.reducer
