import React, { useCallback } from 'react'
import Modalform from '../../../views/componentes/modalform'
import { CCard, CCardBody } from '@coreui/react-pro'
import { DataGrid } from 'devextreme-react'
import RowContainer from '../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../views/componentes/colContainer'
import { Column, ColumnChooser, Editing, Export, Pager, Paging } from 'devextreme-react/data-grid'
import { getAllowedPageSizes } from '../../../helpers/Helper'
import { Workbook } from 'exceljs'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { saveAs } from 'file-saver';

interface IModalErrores extends React.PropsWithChildren {
  onCancel: () => void
  show: boolean
  errores: Array<any> //TODO: añadir el tipo
}

export const ModalErrores: React.FC<IModalErrores> = (props) => {

  const { onCancel, show, errores } = props
  const [changes, setChanges] = React.useState<any>();
  const [editRowKey, setEditRowKey] = React.useState<any>();
  const onChangesChange = React.useCallback((changes) => {
    setChanges(changes);
  }, [])

  const onEditRowKeyChange = React.useCallback((editRowKey) => {
    setEditRowKey(editRowKey);
  }, [])

  const onExportExcell = useCallback((e) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('Main sheet')
    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'Errores.xlsx',
        )
      })
    })
  }, [])

  const bodyModal = () => {

    return (
      <>
        <RowContainer>
          <CustomCol xs="12" md="12">
            <DataGrid
              id="tablaErrores"
              dataSource={errores ?? []}
              selection={{ mode: 'single' }}
              hoverStateEnabled={true}
              showBorders={true}
              showRowLines={true}
              repaintChangesOnly={true}
              highlightChanges={true}
              // keyExpr="uuid"
              // key="uuid"
              // focusedRowKey="uuid"
              onExporting={onExportExcell}
              showColumnLines={true}
              className={'m-2 p-2'}
              loadPanel={{
                enabled: true,
                height: 90,
                indicatorSrc: '',
                shading: false,
                shadingColor: '',
                showIndicator: true,
                showPane: true,
                text: 'Cargando...',
                width: 200,
              }}
            >

              <Editing
                mode="cell"
                allowUpdating
                refreshMode={'repaint'}
                changes={changes}
                onChangesChange={onChangesChange}
                editRowKey={editRowKey}
                onEditRowKeyChange={onEditRowKeyChange}
              />
              <Paging defaultPageSize={10} />
              <Pager
                visible={errores.length > 0 ? true : false}
                allowedPageSizes={getAllowedPageSizes(errores)}
                displayMode={'full'}
                showPageSizeSelector={true}
                showInfo={true}
                showNavigationButtons={true}
              />
              <ColumnChooser enabled={true} mode="select" />

              <Export enabled={true} allowExportSelectedData={true} />
              <Column dataField="FECHA" caption="Fecha" width="100px" />
              <Column dataField="CEDULA" caption="Cedula" width="100px" />
              <Column dataField="NOMBRE" caption="Nombres" width="100px" />
              <Column dataField="APELLIDO" caption="Apellido" width="100px" />
              <Column dataField="SUELDO" caption="Sueldo" width="10px" />
              <Column dataField="CARGO" caption="OroCodigo" width="10px" />
              <Column dataField="CIU_CODIGO" caption="CiuCodigo" width="10px" />
              <Column dataField="CIUDAD" caption="Ciudad" width="10px" />
              <Column dataField="TITULO" caption="Titulo" width="10px" />
              <Column dataField="ESTUDIO" caption="Estudio" width="10px" />
              <Column dataField="CALIFICADO" caption="Calificados" width="10px" />
              <Column dataField='TEXTO' caption={`${errores?.length} errores detectados`} width="100px" />
            </DataGrid>
          </CustomCol>
        </RowContainer>
      </>
    )
  }

  const renderContent = () => {
    return (
      <CCard>
        <CCardBody>
          {bodyModal()}
        </CCardBody>
      </CCard>
    )
  }

  return (
    <>
      <Modalform
        name={'mostrarMensaje'}
        key={'mostrarMensaje'}
        headerTitle={"Mensaje"}
        childrenBody={renderContent()}
        closeOnBackdrop={false}
        show={show}
        onClose={() => onCancel()}
        centered={true}
        size='lg'
      />
    </>
  )
}
