import {
  FunctionComponent,
  PropsWithChildren,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { DataGrid } from 'devextreme-react'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import {
  Column,
  Editing,
  Pager,
  Paging,
  Button as DatagridButton,
  ColumnChooser,
  MasterDetail,
} from 'devextreme-react/data-grid'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/store'
import {
  buttonClick,
  clearButtonClick,
  setButtons,
  setCurrentExecutingAction,
} from '../../store/tabsReducer'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import {
  setPaging,
  setProperties,
  setSelectedItem,
} from '../../store/searchReducer'
import {
  allowedPageSizes,
  getAllowedPageSizes,
} from '../../../../../../helpers/Helper'
import ItemsCountIndicator from '../../../../../componentes/indicadorNroRegistros'
import {
  changeLoaderItems,
  setCurrentFunction,
} from '../../store/generalReducer'
import { TiposPropiedades } from '../../../../../componentes/propiedadesLookUp/services/propiedaddes.service'
import { addToast } from '../../../../../../store/toasterReducer'
import { ToastTypes } from '../../../../../../store/types'
import { TipoPropiedad } from '../../../../../componentes/propiedadesLookUp/types/types'
import { isMobile } from 'react-device-detect'
import { utilidades } from '../../../../../../helpers/utilidades'
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCardTitle,
  CCol,
  CTooltip,
} from '@coreui/react-pro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons'
import { TIPOS_BOTONES } from '../../../../../proveedores/pages/proveedores/types/types'
import { useSetToast } from '../../../../../../hooks/useGlobalHooks'
import { ItemsServices } from '../../services/items.services'
import ItemDetails from './detallesItem'
import { ActionSheetCommandsMobile } from './actionSheetMobile'
import { ItemsListResult } from '../../types/types'

const buttons = [
  {
    icon: 'fal fa-save',
    id: 'Guardar',
    text: 'Guardar',
    type: ButtonTypes.save,
  },
  {
    icon: 'fal fa-trash',
    id: 'Eliminar',
    text: 'Eliminar',
    type: ButtonTypes.delete,
  },
  {
    icon: 'fal fa-times',
    id: 'Salir',
    text: 'Salir',
    type: ButtonTypes.times,
  },
]

interface ILista extends PropsWithChildren {
  data: any
  tabId: string
}

const Lista: FunctionComponent<ILista> = (props) => {
  const { data, tabId } = props

  const dispatch = useDispatch()
  const setToastMessage = useSetToast()

  const pageSize = useSelector(
    (state: RootState) => state.inventarios.items.search.pageSize,
  )
  const pageIndex = useSelector(
    (state: RootState) => state.inventarios.items.search.pageIndex,
  )
  const currentSearchTab = useSelector((state: RootState) => {
    return state.inventarios.items.search.currentTab
  })
  const totalCountItems = useSelector((state: RootState) => {
    return state.inventarios.items.search.totalCount
  })
  const propertiesItems = useSelector((state: RootState) => {
    return state.inventarios.items.search.properties
  })
  const currentAction = useSelector(
    (state: RootState) => state.inventarios.items.general.currentFunction,
  )
  const template = useSelector(
    (state: RootState) => state.global.session.empresa.plantillaBarras,
  )

  const dataGridList = useRef<any>()
  const tablaRefPager = useRef<any>()
  const tablaRefInactivar = useRef<any>()
  const tablaRefPagerInactivar = useRef<any>()
  const [showInactivateItems, setShowInactivateItems] = useState<boolean>(false)
  const [itemsInactivate, setItemsInactivate] = useState<Array<any>>([])
  const [acciones] = useState<any>(buttons)
  const [rowIndex, setRowIndex] = useState<number>(-1)

  const playLoader = useCallback(
    (message = 'Cargando...') => {
      dispatch(changeLoaderItems({ show: true, mensaje: message }))
    },
    [dispatch],
  )

  const stopLoader = useCallback(() => {
    dispatch(changeLoaderItems({ show: false, mensaje: '' }))
  }, [dispatch])

  const onHandleDoubleClick = useCallback(
    ({ data }) => {
      if (!showInactivateItems)
        dispatch(buttonClick({ tabKey: tabId, button: ButtonTypes.edit }))
      else {
        const itemsToInactivate = structuredClone(itemsInactivate)
        const indexToAdd = itemsToInactivate.findIndex((item) => {
          return item.codigo === data.itemCodigo
        })
        if (indexToAdd === -1) {
          const newItem = {
            codigo: data.itemCodigo ?? 0,
            barras: data.itemBarras ?? '',
            descripcion: data.itemDescripcion ?? '',
          }
          itemsToInactivate.push(newItem)
          setItemsInactivate(itemsToInactivate)
        } else
          setToastMessage(
            'Agregar Ítem a Inactivar',
            'El Ítem ya se encuentra agregado',
            ToastTypes.Warning,
          )
      }
    },
    [showInactivateItems, dispatch, tabId, itemsInactivate, setToastMessage],
  )

  const onSelectedItemChanged = useCallback(
    ({ selectedRowsData, currentDeselectedRowKeys }) => {
      if (selectedRowsData && totalCountItems > 0) {
        dispatch(setSelectedItem(selectedRowsData[0]))
        if (currentDeselectedRowKeys.length === 0)
          dispatch(
            setButtons({
              tabKey: tabId,
              buttons: {
                Nuevo: true,
                Editar: true,
                Eliminar: true,
                Buscar: true,
                Duplicar: true,
                Unificar: true,
                Woocommerce: true,
                Auditoria: true,
                Imprimir: true,
                Codigo_Barras: template >= 0,
                Editar_Precio: true,
                Desactivar_Item: true,
                Armar_Kit: true,
                Importar: true,
                Exportar: true,
                Limpiar: true,
              },
            }),
          )
      }
    },
    [totalCountItems, dispatch, tabId, template],
  )

  const handleOptionChange = useCallback(
    async (e) => {
      if (e.fullName === 'paging.pageSize')
        dispatch(
          setPaging({
            pageIndex: pageIndex,
            pageSize: e.value,
          }),
        )

      if (e.fullName === 'paging.pageIndex')
        dispatch(
          setPaging({
            pageIndex: e.value,
            pageSize: pageSize,
          }),
        )
    },
    [dispatch, pageIndex, pageSize],
  )

  const getProperties = useCallback(async () => {
    try {
      playLoader('Cargando . . .')
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'BUSQUEDA',
          buttonType: ButtonTypes.new,
        }),
      )
      const poperties = await TiposPropiedades.getTypes('Elige una opción')
      stopLoader()
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'BUSQUEDA',
          buttonType: undefined,
        }),
      )
      if (!poperties.error) dispatch(setProperties(poperties.auto))
      else
        setToastMessage(
          'Buscar Propiedades Ítems',
          poperties.message,
          ToastTypes.Danger,
        )
    } catch (error) {
      stopLoader()
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'BUSQUEDA',
          buttonType: undefined,
        }),
      )
      dispatch(
        addToast({
          id: '',
          autoHide: true,
          content:
            'Error al obtener propiedaddes ' +
            (typeof error === 'string' ? error : JSON.stringify(error)),
          fade: true,
          title: 'Cargar Propiedades',
          type: ToastTypes.Warning,
        }),
      )
    }
  }, [dispatch, playLoader, setToastMessage, stopLoader])

  useEffect(() => {
    if (propertiesItems === null) getProperties()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleButtonClick = useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.deactivateItem:
          setShowInactivateItems(true)
          break
        default:
          break
      }
      dispatch(setCurrentFunction(''))
      dispatch(clearButtonClick(tabId))
    },
    [dispatch, tabId, setShowInactivateItems],
  )

  useEffect(() => {
    if (currentAction !== '') handleButtonClick(currentAction)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAction])

  const onDeleteItemToInactivate = useCallback(() => {
    if (rowIndex >= 0) {
      const newItemsDataGrid = structuredClone(itemsInactivate)
      newItemsDataGrid.splice(rowIndex, 1)
      setItemsInactivate([...newItemsDataGrid])
      setRowIndex(-1)
    } else
      setToastMessage(
        'Eliminar Ítem a Inactivar',
        'Debe elejir un ítem para eliminarlo',
        ToastTypes.Warning,
      )
  }, [itemsInactivate, rowIndex, setToastMessage])

  const onInactivateItems = useCallback(async () => {
    if (itemsInactivate.length > 0) {
      try {
        playLoader('Inactivando Ítems . . . ')
        dispatch(
          setCurrentExecutingAction({
            tabKey: 'BUSQUEDA',
            buttonType: ButtonTypes.editPrice,
          }),
        )
        const inactivateItems = await ItemsServices.inactivateItems(
          itemsInactivate,
        )
        stopLoader()
        dispatch(
          setCurrentExecutingAction({
            tabKey: 'BUSQUEDA',
            buttonType: undefined,
          }),
        )
        if (!inactivateItems.error && inactivateItems.auto) {
          setShowInactivateItems(false)
          dispatch(
            buttonClick({ tabKey: 'BUSQUEDA', button: ButtonTypes.find }),
          )
        }
        setToastMessage(
          'Inactivar Ítems',
          inactivateItems.message,
          inactivateItems.message ? ToastTypes.Warning : ToastTypes.Success,
        )
      } catch (error) {
        stopLoader()
        dispatch(
          setCurrentExecutingAction({
            tabKey: 'BUSQUEDA',
            buttonType: undefined,
          }),
        )
        setToastMessage(
          'Inactivar Ítems',
          error ?? 'Error al inactivar ítems',
          ToastTypes.Danger,
        )
      }
    } else
      setToastMessage(
        'Inactivar Ítems',
        'Debe elejir por lo menos un ítem para inactivarlos',
        ToastTypes.Warning,
      )
  }, [dispatch, itemsInactivate, playLoader, setToastMessage, stopLoader])

  const onHandleColumnButtons = useCallback(
    async (data: ItemsListResult, button: ButtonTypes) => {
      if (data) {
        dispatch(setSelectedItem(data))
        await utilidades.sleep(10)
        dispatch(buttonClick({ tabKey: tabId, button: button }))
      }
    },
    [dispatch, tabId],
  )

  const actionSheetCommandsMobile = useCallback(
    ({ row: { data } }) => {
      return (
        <ActionSheetCommandsMobile
          key={crypto.randomUUID()}
          onEdit={() => onHandleColumnButtons(data, ButtonTypes.edit)}
          onDelete={() => onHandleColumnButtons(data, ButtonTypes.delete)}
          onDuplicate={() => onHandleColumnButtons(data, ButtonTypes.clone)}
          onUnify={() => onHandleColumnButtons(data, ButtonTypes.unify)}
        />
      )
    },
    [onHandleColumnButtons],
  )

  const returnAction = useCallback(
    (metodo: string) => {
      switch (metodo) {
        case TIPOS_BOTONES.BOTON_GUARDAR:
          onInactivateItems()
          break
        case TIPOS_BOTONES.BOTON_ELIMINAR:
          onDeleteItemToInactivate()
          break
        case TIPOS_BOTONES.BOTON_SALIR:
          setShowInactivateItems(false)
          break
        default:
          break
      }
    },
    [onDeleteItemToInactivate, onInactivateItems],
  )

  const renderButtons = () => {
    return (
      <>
        <CCol className="ms-0 d-flex align-items-start justify-content-start">
          <div
            key="menuItems"
            className="btn-toolbar"
            role="toolbar"
            aria-label="Toolbar with button groups"
            style={{ marginBottom: '5px', paddingTop: '-2rem' }}
          >
            <div
              key="menuItemsBotones"
              className="btn-group-lg btn-group btn-sm me-2"
              role="group"
              aria-label="First group"
            >
              {acciones.map(function (action: any) {
                const prefixButton = 'buttonsEditPrices_'
                const nameButon = action.text
                const disabled = false
                return (
                  <CTooltip
                    key={'tool-' + prefixButton + nameButon}
                    placement="top"
                    content={nameButon}
                  >
                    <CButton
                      key={prefixButton + nameButon}
                      name={prefixButton + nameButon}
                      title={action.text}
                      id={prefixButton + nameButon}
                      value={action.text}
                      className="btn btn-secondary sm"
                      variant="ghost"
                      // eslint-disable-next-line no-unused-vars
                      onClick={(ev) => returnAction((ev = action.text))}
                      size="sm"
                      disabled={disabled}
                    >
                      <FontAwesomeIcon
                        size="sm"
                        icon={action.icon ?? faQuestionCircle}
                      />
                    </CButton>
                  </CTooltip>
                )
              })}
            </div>
          </div>
        </CCol>
      </>
    )
  }

  const renderInactivateItems = () => {
    return (
      <>
        <CCol xs="12" md="5" style={{ marginTop: '12px' }}>
          <CCard key="cardInactivateItems">
            <CCardTitle>{'Ítems a Inactivar'}</CCardTitle>
            <CCardHeader>{renderButtons()}</CCardHeader>
            <CCardBody>
              <DataGrid
                ref={tablaRefInactivar}
                selection={{ mode: 'single' }}
                className="border"
                dataSource={itemsInactivate}
                onRowClick={({ rowIndex }) => setRowIndex(rowIndex)}
                width={'100%'}
                keyExpr="codigo"
                loadPanel={{ enabled: true }}
                showBorders={true}
                showRowLines={true}
              >
                <Paging enabled={true} defaultPageSize={5} />
                <Pager
                  ref={tablaRefPagerInactivar}
                  showPageSizeSelector={true}
                  allowedPageSizes={getAllowedPageSizes(itemsInactivate)}
                  showNavigationButtons={true}
                  showInfo={true}
                />
                <Column dataField="barras" caption="Cód. Barras" width="30%" />
                <Column
                  dataField="descripcion"
                  caption="Descripción"
                  width="70%"
                />
              </DataGrid>
            </CCardBody>
          </CCard>
        </CCol>
      </>
    )
  }

  return (
    <>
      {showInactivateItems && renderInactivateItems()}
      <RowContainer className="mt-2">
        <ItemsCountIndicator numberOfItems={totalCountItems} />
        <DataGrid
          ref={dataGridList}
          selection={{ mode: 'single' }}
          hoverStateEnabled={true}
          dataSource={data ?? []}
          showBorders={true}
          showRowLines={true}
          allowColumnResizing={true}
          repaintChangesOnly={true}
          onSelectionChanged={onSelectedItemChanged}
          onCellDblClick={onHandleDoubleClick}
          onOptionChanged={(e) => handleOptionChange(e)}
          remoteOperations={true}
          key="gridListItems"
          loadPanel={{ enabled: true }}
        >
          <ColumnChooser enabled={true} mode="select" />
          <Editing mode="cell" allowUpdating={true} />
          <Paging pageIndex={pageIndex} pageSize={pageSize} enabled={true} />
          <Pager
            ref={tablaRefPager}
            visible={true}
            allowedPageSizes={allowedPageSizes}
            displayMode={'full'}
            showPageSizeSelector={true}
            showInfo={true}
            showNavigationButtons={true}
            infoText="Page #{0}. Total: {1} ({2} items)"
          />
          {!isMobile && (
            <MasterDetail
              enabled={true}
              render={(params) => (
                <>
                  <ItemDetails {...params} />{' '}
                </>
              )}
            />
          )}
          {!isMobile && (
            <Column type="buttons" width="80px">
              <DatagridButton
                icon="edit"
                hint="Editar Ítem"
                onClick={({ row: { data } }) =>
                  onHandleColumnButtons(data, ButtonTypes.edit)
                }
              />
              <DatagridButton
                icon="trash"
                hint="Eliminar Ítem"
                onClick={({ row: { data } }) =>
                  onHandleColumnButtons(data, ButtonTypes.delete)
                }
              />
              <DatagridButton
                icon="selectall"
                hint="Clonar Ítem"
                onClick={({ row: { data } }) =>
                  onHandleColumnButtons(data, ButtonTypes.clone)
                }
              />
              <DatagridButton
                icon="isblank"
                hint="Unificar Ítem"
                onClick={({ row: { data } }) =>
                  onHandleColumnButtons(data, ButtonTypes.unify)
                }
              />
            </Column>
          )}
          {isMobile && (
            <Column
              caption="Tareas"
              cellRender={actionSheetCommandsMobile}
              width="50px"
              allowFiltering={false}
            />
          )}
          <Column
            visible={false}
            allowEditing={false}
            dataType="string"
            dataField="itemCodigoUsuario"
            caption="Código"
            width="120px"
          />
          <Column
            allowEditing={false}
            dataField="itemBarras"
            caption="Barras"
            width="120px"
          />
          <Column
            allowEditing={false}
            dataField="itemDescripcion"
            caption="Descripción"
            width="220px"
          />
          <Column
            allowEditing={false}
            dataField="itemLineaDescripcion"
            caption="Línea"
            width="85px"
          />
          <Column
            allowEditing={false}
            dataField="itemGrupoDescripcion"
            caption="Grupo"
            width="85px"
          />
          <Column
            allowEditing={false}
            dataField="itemMarcaDescripcion"
            caption="Marca"
            width="85px"
          />
          <Column
            allowEditing={false}
            dataField="itemImpuestoDescripcion"
            caption="Impuesto"
            width="90px"
          />
          <Column
            allowEditing={false}
            dataField="itemCosto"
            caption="Costo"
            width="80px"
          />
          <Column
            allowEditing={false}
            dataField="itemGananciaPVP"
            caption="% Utilidad"
            width="80px"
            visible={false}
          />
          <Column
            allowEditing={false}
            dataField="itemPVPIVA"
            caption="PVP IVA"
            width="80px"
          />
          <Column
            allowEditing={false}
            dataField="itemPVDIVA"
            caption="PVD IVA"
            width="80px"
          />
          <Column
            dataType="number"
            allowEditing={false}
            dataField="itemReservas"
            caption="Stock Reservas"
            width="90px"
            visible={false}
          />
          <Column
            allowEditing={false}
            dataType="number"
            dataField="itemExistencia"
            caption="Stock Físico"
            width="90px"
          />
          <Column
            allowEditing={false}
            dataField="itemUnidadVentaDescripcion"
            caption="Unidad"
            width="80px"
            visible={false}
          />
          <Column
            allowEditing={false}
            dataField="itemTipoDescripcion"
            caption="Tipo"
            width="80px"
            visible={false}
          />
          <Column
            allowEditing={false}
            dataField="itemLoteDescripcion"
            caption="Lote"
            width="60px"
            visible={false}
          />
          <Column
            allowEditing={false}
            dataField="itemNumeroSerieDescripcion"
            caption="Serie"
            width="50px"
            visible={false}
          />
          <Column
            allowEditing={false}
            dataField="itemProcedenciaDescripcion"
            caption="Procedencia"
            width="80px"
            visible={false}
          />
          <Column
            allowEditing={false}
            dataField="itemSemana"
            caption="Semana"
            width="60px"
            visible={false}
          />

          <Column
            allowEditing={false}
            dataField="itemPeso"
            caption="Peso"
            width="60px"
            visible={false}
          />
          <Column
            allowEditing={false}
            dataField="itemAncho"
            caption="Ancho"
            width="60px"
            visible={false}
          />
          <Column
            allowEditing={false}
            dataField="itemLargo"
            caption="Largo"
            width="60px"
            visible={false}
          />
          <Column
            allowEditing={false}
            dataField="itemAlto"
            caption="Alto"
            width="60px"
            visible={false}
          />
          <Column
            allowEditing={false}
            dataField="itemNota"
            caption="Nota"
            width="50px"
            visible={false}
          />
          <Column
            allowEditing={false}
            dataField="itemTags"
            caption="Tags"
            width="50px"
            visible={false}
          />

          <Column
            allowEditing={false}
            dataField=""
            caption="Lote"
            width="80px"
            visible={false}
          />
          <Column
            allowEditing={false}
            dataField=""
            caption="Caducidad"
            width="80px"
            visible={false}
          />
          <Column
            allowEditing={false}
            dataField="itemUltimoProveedor"
            caption="Ult. Proveedor"
            width="80px"
            visible={currentSearchTab === 'Lista Extendida'}
          />
          <Column
            allowEditing={false}
            dataField="" //THERE ISN'T THIS KEY IN RESPONSE OF API
            caption="Costo Ult. Proveedor"
            width="80px"
            visible={currentSearchTab === 'Lista Extendida'}
          />
          <Column
            allowEditing={false}
            dataField="itemJerarquia" //CHECK THIS KEY
            caption="Jerarquía"
            width="80px"
            visible={currentSearchTab === 'Lista Extendida'}
          />
          {currentSearchTab === 'Lista Extendida' &&
            propertiesItems.map((property: TipoPropiedad) => {
              if (property.codigo > 0)
                return (
                  <Column
                    key={property.codigo}
                    dataField=""
                    caption={property.descripcion}
                    width="60px"
                    visible={false}
                  />
                )
            })}
        </DataGrid>
      </RowContainer>
    </>
  )
}

export default memo(Lista)
