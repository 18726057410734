import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../../store/store'
import { FETCH_STATUS } from '../../../../../store/types'
import {
  TIncotermsListado,
  TFiltroBusquedaIncotermsState
} from './types';
import { TConfigSearchState } from '../../store/types'
import {IncotermsService} from "../services/incoterms.services";

const initialState: TConfigSearchState<TIncotermsListado> = {
  filter: {
    codigo: 0,
    descripcion: '',
    codigoTributario: '',
    estado: 1,
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  resultados: [],
  selectedItem: null,
  error: false,
}

export const fetchIncoterms = createAsyncThunk<
  Array<TIncotermsListado>,
  TFiltroBusquedaIncotermsState
>('catalogos/getAll/incoterms', async (filtro) => {
  try {
    return await IncotermsService.getCollection(filtro)
  } catch (error) {
    return Promise.reject(error)
  }
});

const searchConfSlice = createSlice({
  name: 'IncotermsSearch',
  initialState: initialState,
  reducers: {
    resetState(state) {
      state = {
        ...initialState,
        filter: {
          ...initialState.filter,
        },
      }
    },
    changeFilter(state, action: PayloadAction<TFiltroBusquedaIncotermsState>) {
      state.filter = action.payload
    },
    setCleanResult(state) {
      state.resultados = []
    },
    setResultados(state, action: PayloadAction<Array<TIncotermsListado> | []>) {
      state.resultados = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchIncoterms.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
        state.error = false
      }
    })
    builder.addCase(fetchIncoterms.fulfilled, (state, { payload }: {payload:any}) => {
      state.resultados = payload.auto
      state.status = FETCH_STATUS.SUCCESS
      state.error = payload.error
      state.mensaje = payload.message
    })
    builder.addCase(fetchIncoterms.rejected, (state, { payload }: {payload:any}) => {
      state.resultados = []
      state.status = FETCH_STATUS.FAILED
      state.error = payload.error
      state.mensaje = payload.message
    })
  }
});

export const selectFiltroBusquedaIncoterms = (state: RootState) => {
  return state.administracion.catalagos.incoterms.search.filter
}

export const selectResultadosBusquedaIncoterms = (state: RootState) => {
  return state.administracion.catalagos.incoterms.search.resultados
}

export const selectEstadoBusquedaIncoterms = (state: RootState) => {
  return state.administracion.catalagos.incoterms.search.status
}

export const { setResultados, resetState, setCleanResult, changeFilter } = searchConfSlice.actions
export const searchReducer = searchConfSlice.reducer
