import React from 'react'
import RowContainer from '../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../views/componentes/colContainer'
import Labeled from '../../../../../views/componentes/labeledInput/labeledInput'
import { NumberBox, RadioGroup, TextBox, ValidationGroup, ValidationSummary, Validator } from 'devextreme-react'
import { RequiredRule } from 'devextreme-react/validator'
import validations from '../../../../../config/validations'
import { useDispatch } from 'react-redux'
import { addToast } from '../../../../../store/toasterReducer'
import { ToastTypes } from '../../../../../store/types'
import { MessagesKeys, lh } from '../../../../../helpers/localizationHelper'
import { etapasProduccionService } from '../../../etapasProduccionLookUp/service/etapaProduccion.service'


interface INewProps extends React.PropsWithChildren {
  currentTab: string
  currentFunction: string
  setFunction: (data: string) => void
  setShowLoader: (data: boolean) => void
  data: any
}

export const estados = [
  { value: 1, label: 'Activo' },
  { value: 2, label: 'Inactivo' },
]

const Nuevo: React.FunctionComponent<INewProps> = (props) => {

  const { currentTab, currentFunction, setFunction, setShowLoader, data } = props

  const dispatch = useDispatch()
  const [codigo, setCodigo] = React.useState<number>(null)
  const [orden, setOrden] = React.useState<number>(1)
  const validationEtapasProdRef = React.useRef<any>()

  const [descripcion, setDescripcion] = React.useState<string>(null)
  const [estado, setEstado] = React.useState<any>(estados[0])


  const sms = validations['es']

  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(
      addToast({
        title: 'Kits Partes',
        content: mensaje,
        type: tipo,
      }),
    )
  }, [dispatch])

  const onValueChanged = React.useCallback((data, key: string) => {
    if (data !== null && data !== undefined) {
      let value = null
      if (data?.value !== null && data?.value !== undefined) {
        value = data.value
      }
      switch (key) {
        case 'descripcion':
          setDescripcion(value)
          break;
        case 'estado':
          setEstado(value)
          break;
        case 'orden':
          setOrden(value)
          break;

        default:
          break;
      }
    }
  }, [])

  const onEdit = React.useCallback(async () => {
    if (data !== null && data !== undefined && data?.codigo > 0) {
      setShowLoader(true)
      setCodigo(data.codigo)
      setDescripcion(data.descripcion)
      setOrden(data.orden)
      const estado = data.estado === estados[0].value ? estados[0] : estados[1]
      setEstado(estado)
      setShowLoader(false)
    }
  }, [data, setShowLoader])

  const onSave = React.useCallback(async () => {
    const result = validationEtapasProdRef.current.instance.validate()
    if (result.isValid) {
      const save_data = {
        infoRegistro: {
          codigo: codigo !== null ? codigo : 0,
          descripcion: descripcion !== null ? descripcion : '',
          orden: orden !== null ? orden : 0,
          estado: estado ? 1 : 2,
        }
      }

      try {
        setShowLoader(true)
        const data = await etapasProduccionService.saveEtapa(save_data)
        setShowLoader(false)
        if (data !== null && data !== undefined && data['error'] === false) {
          setCodigo(data['auto'])
          setToast(data['message'], ToastTypes.Success)
        } else {
          setToast(data['message'], ToastTypes.Danger)
        }
      } catch (error) {
        setShowLoader(false)
        setToast(error.message, ToastTypes.Danger)
      }
    } else {
      setToast(lh.getMessage(MessagesKeys.GlobalErrorSave), ToastTypes.Warning)
    }
  }, [setToast, codigo, descripcion, estado, orden, setShowLoader])


  React.useEffect(() => {
    if (currentTab === 'Nuevo') {
      if (currentFunction === 'Guardar') {
        onSave()
      } else if (currentFunction === 'Editar') {
        onEdit()
      }
    }
    setFunction('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFunction])

  return (
    <>
      <ValidationGroup id={'formKitsPartes'} ref={validationEtapasProdRef}>
        <RowContainer className="m-2">
          <RowContainer>
            <CustomCol xs="12" md="8">
              <Labeled label="Codigo:">
                <NumberBox
                  value={codigo}
                  readOnly
                  width="100%"
                  inputAttr={{ autocomplete: 'nope' }}
                />
              </Labeled>
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol xs="12" md="8">
              <Labeled label="Descripcion:">
                <TextBox
                  value={descripcion}
                  width="100%"
                  inputAttr={{ autocomplete: 'nope' }}
                  onValueChanged={(data) => onValueChanged(data, 'descripcion')}
                >
                  <Validator>
                    <RequiredRule message={'Descripción: ' + sms['required']} />
                  </Validator>
                </TextBox>
              </Labeled>
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol xs="12" md="8">
              <Labeled label="Orden:">
                <NumberBox
                  value={orden}
                  width="100%"
                  inputAttr={{ autocomplete: 'nope' }}
                  onValueChanged={(data) => onValueChanged(data, 'orden')}
                />
              </Labeled>
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol xs="12" md="8">
              <Labeled label="Estado:">
                <RadioGroup
                  name="grupos-estado"
                  layout="horizontal"
                  displayExpr="label"
                  items={estados}
                  value={estado}
                  onValueChanged={(data) => onValueChanged(data, 'estado')}
                />
              </Labeled>
            </CustomCol>
          </RowContainer>
        </RowContainer>
        <RowContainer>
          <CustomCol lg="6" md="8" sm="12">
            <ValidationSummary id="summary"></ValidationSummary>
          </CustomCol>
        </RowContainer>
      </ValidationGroup>
    </>
  )
}

export default React.memo(Nuevo)