import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  FiltrosTransformacionesState,
  BusquedaState,
  Transformacion,
} from '../types/types'
import { FETCH_STATUS } from '../../../../../../store/types'
import { conceptsServices } from '../services/combinaciones.services'

const initialState: BusquedaState<Transformacion> = {
  filter: {},
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  resultados: [],
}

export const fetchTransformations = createAsyncThunk<
  Array<Transformacion>,
  FiltrosTransformacionesState
>('inventarios/movimientos/transformaciones/listar', async (filtro) => {
  try {
    const transformationsList = conceptsServices.getConcepts(filtro)
    return transformationsList
  } catch (error) {
    return Promise.reject(error)
  }
})

const searchSlice = createSlice({
  name: 'buscarTransformacionesMov',
  initialState: initialState,
  reducers: {
    setCleanResult(state) {
      state.resultados = []
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTransformations.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchTransformations.fulfilled, (state, { payload }) => {
      state.resultados = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchTransformations.rejected, (state, { payload }) => {
      state.resultados = []
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
  },
})

export const { setCleanResult } = searchSlice.actions

export const searchReducer = searchSlice.reducer
