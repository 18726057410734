import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ButtonTypes } from '../../../../../views/componentes/globalMenu/types'
import { StatesEdition } from '../../../../ventas/types/enums'
import { TabsState, TabState } from '../../../../../store/genericTabTypes'
import {
  closeTab,
  tabExists,
} from '../../../../../hooks/useNavigationTabControls'
import { CustomButtons } from '../../../../../store/types'
import { Position } from '../types/types'
import { NewTabButtons } from '..'
import { TabTypes } from '../../../../../store/enums'

const initialState: TabsState<Position> = {
  current: 'BUSQUEDA',
  tabs: {
    BUSQUEDA: {
      id: 0,
      canClose: false,
      editStatus: StatesEdition.doesNotApply,
      globalButtonClick: ButtonTypes.none,
      tittle: 'Búsqueda',
      type: TabTypes.busqueda,
      imprimir: false,
      tabKey: 'BUSQUEDA',
      buttons: {
        Nuevo: true,
        Buscar: true,
        Limpiar: true,
        Deshacer: true,
      },
    },
  },
}

const tabsPositionsSlice = createSlice({
  name: 'positionsTabs',
  initialState: initialState,
  reducers: {
    closeTabPosition: closeTab,
    openTab(
      state,
      action: PayloadAction<{
        key: string
        position?: Position
        templateMode?: boolean
      }>,
    ) {
      if (
        action.payload.position === undefined ||
        action.payload.templateMode
      ) {
        const tab: TabState<Position> = {
          id: -1,
          canClose: true,
          editStatus: StatesEdition.new,
          globalButtonClick: ButtonTypes.none,
          tittle: 'Nueva',
          type: TabTypes.documento,
          imprimir: false,
          tabKey: action.payload.key,
          info: { codigo: 0 },
          buttons: NewTabButtons,
          templateMode: action.payload.templateMode,
        }
        state.tabs[action.payload.key] = tab
        state.current = action.payload.key
      } else {
        const tabKey = tabExists(
          state.tabs,
          action.payload.position.codigo,
          TabTypes.documento,
        )
        if (tabKey) state.current = tabKey
        else {
          const { key, position: position } = action.payload
          const tab: TabState<Position> = {
            tabKey: key,
            id: position.codigo,
            tittle: `${position.codigo} - ${position.descripcion}`,
            canClose: true,
            editStatus: StatesEdition.edit,
            type: TabTypes.documento,
            info: {
              codigo: position.codigo,
              info: position,
            },
            globalButtonClick: ButtonTypes.none,
            imprimir: false,
            buttons: { ...NewTabButtons, Imprimir: true },
          }
          state.tabs[key] = tab
          state.current = key
        }
      }
    },
    openTabOrganizationChart(state) {
      const tab: TabState<any> = {
        id: 666,
        tittle: 'Asignación de Roles al Organigrama',
        type: TabTypes.asignarRolesOrganigrama,
        info: { codigo: 666 },
        canClose: true,
        editStatus: StatesEdition.new,
        globalButtonClick: ButtonTypes.none,
        imprimir: false,
        tabKey: 'ASIGNACION_ROL_ORGANIGRAMA',
        buttons: {
          Buscar: true,
        },
      }
      state.tabs['ASIGNACION_ROL_ORGANIGRAMA'] = tab
      state.current = 'ASIGNACION_ROL_ORGANIGRAMA'
    },
    openTabPermisions(
      state,
      action: PayloadAction<{
        key: string
        position: Position
      }>,
    ) {
      const tabKey = tabExists(
        state.tabs,
        action.payload.position.codigo,
        TabTypes.permisos,
      )
      if (tabKey) state.current = tabKey
      else {
        const { key, position: position } = action.payload
        const tab: TabState<Position> = {
          tabKey: key,
          id: position.codigo,
          tittle: `${position.codigo} - ${position.descripcion} - Permisos`,
          canClose: true,
          editStatus: StatesEdition.new,
          type: TabTypes.permisos,
          info: {
            codigo: position.codigo,
            info: position,
          },
          globalButtonClick: ButtonTypes.none,
          imprimir: false,
          buttons: {
            Guardar: true,
            Buscar: true,
            Eliminar: true,
            Imprimir: true,
          },
        }
        state.tabs[key] = tab
        state.current = key
      }
    },
    changeCurrentTab(state, action: PayloadAction<string>) {
      if (Object.prototype.hasOwnProperty.call(state.tabs, action.payload)) {
        state.current = action.payload
      }
    },
    setCurrentExecutingAction(
      state,
      action: PayloadAction<{
        tabKey: string
        buttonType: ButtonTypes | undefined
      }>,
    ) {
      const { buttonType, tabKey } = action.payload
      state.tabs[tabKey].currentExecutingAction = buttonType
    },
    setButtons(
      state,
      action: PayloadAction<{ tabKey: string; buttons: CustomButtons }>,
    ) {
      const { buttons, tabKey } = action.payload
      state.tabs[tabKey].buttons = buttons
      state.tabs[tabKey].currentExecutingAction = undefined
    },
    buttonClick(
      state,
      action: PayloadAction<{ tabKey: string; button: ButtonTypes }>,
    ) {
      state.tabs[action.payload.tabKey].globalButtonClick =
        action.payload.button
    },
    clearButtonClick(state, action: PayloadAction<string>) {
      if (Object.prototype.hasOwnProperty.call(state.tabs, action.payload))
        state.tabs[action.payload].globalButtonClick = ButtonTypes.none
    },
    setNameTab(
      state,
      action: PayloadAction<{
        key: string
        codigo: number
        nombre: string
      }>,
    ) {
      const { key, codigo, nombre } = action.payload
      state.tabs[key].tittle = `${codigo} - ${nombre}`
      state.tabs[key].info.codigo = codigo
    },
  },
})

export const {
  closeTabPosition,
  openTab,
  changeCurrentTab,
  setCurrentExecutingAction,
  setButtons,
  buttonClick,
  clearButtonClick,
  setNameTab,
  openTabOrganizationChart,
  openTabPermisions,
} = tabsPositionsSlice.actions
export const tabsPositionsReducer = tabsPositionsSlice.reducer
