import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AccionMenu } from "../../../../store/types";
import { SeleccionadoTableItem } from "../../../bancos/store/types";
import { ModalOpen } from "../../../contabilidad/general/store/types";
import { Alerta } from "../../../contabilidad/pages/diarioGeneral/components/modalAnticipos/store/types";
import { ModalTarjetaState, Registros, OptionGrupos } from "./types";

const initialState: ModalTarjetaState = {
  modulo: 'tarjetas',
  currentAction: "Buscar",
  currentFunction: "",
  aplicacion: 'modalTarjetas',
  acciones: [],
  registros: [],
  seleccionado: {
    index: null,
    row: [],
  },
  refrescaTabla: false,
  modulos: [],
  modal: {
    name: "",
    open: false,
    codModulo: 0,
    parametros: []
  },
  motrarAlerta: {
    mostrar: false,
    mensaje: ""
  },
  dbtarjetas: []
}

const modalTarjetasSlice = createSlice({
  name: "modalTarjetas",
  initialState: initialState,
  reducers: {
    setModulosOptions(state, action: PayloadAction<Array<OptionGrupos>>) {
      state.modulos = action.payload
    },
    setCurrentAction(state, action: PayloadAction<string>) {
      state.currentAction = action.payload
    },
    setCurrentFunction(state, action: PayloadAction<string>) {
      state.currentFunction = action.payload
    },
    obtenerAcciones(state, action: PayloadAction<Array<AccionMenu>>) {
      state.acciones = action.payload
    },
    setRegistros(state, action: PayloadAction<Array<Registros>>) {
      state.registros = action.payload
    },
    setSeleccionarDato(state, action: PayloadAction<SeleccionadoTableItem>) {
      const toAdd = {
        index: action.payload.index,
        row: action.payload.row,
      }
      state.seleccionado = toAdd
    },
    setResetSeleccion(state, action: PayloadAction<any>) {
      const toAdd = {
        index: null,
        row: []
      }
      state.seleccionado = toAdd
    },
    setModalTarjetas(state, action: PayloadAction<ModalOpen>) {
      const toAdd = {
        name: action.payload.name,
        open: action.payload.open,
        codModulo: action.payload.codModulo,
        parametros: action.payload.parametros
      }
      state.modal = toAdd
    },
    setMostrarAlerta(state, action: PayloadAction<Alerta>) {
      const toAdd = {
        mostrar: action.payload.mostrar,
        mensaje: action.payload.mensaje,
      }
      state.motrarAlerta = toAdd
    },
    setDpTarjetas(state, action: PayloadAction<Array<any>>) {
      state.dbtarjetas = action.payload
    }
  }
})

export const { setCurrentFunction, setMostrarAlerta, setModalTarjetas, obtenerAcciones, setRegistros, setCurrentAction, setSeleccionarDato, setResetSeleccion, setDpTarjetas } = modalTarjetasSlice.actions;
export const modalTarjetasReducer = modalTarjetasSlice.reducer;