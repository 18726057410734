import React, { memo } from 'react'
import TextBox from 'devextreme-react/text-box'
import ResultadoBusqueda from './resultadoBusqueda'
import { CCol } from '@coreui/react-pro'
import CustomCol from '../../../../../../../../views/componentes/colContainer'
import { ToastTypes } from '../../../../../../../../store/types'
import RowContainer from '../../../../../../../../views/componentes/rowContainer/rowContainer'
import { CheckBox } from 'devextreme-react'
import Labeled from '../../../../../../../../views/componentes/labeledInput/labeledInput'
import { AplicacionInventario } from '../../../../../catalogos/aplicaciones/types/types'
import { aplicationsModalServices } from '../../services/modalAplicaciones.services'

interface IBuscarProps extends React.PropsWithChildren {
  // eslint-disable-next-line no-unused-vars
  onSelectItem: (item) => void
  // eslint-disable-next-line no-unused-vars
  onSelectedItem: (item) => void
  deletedItem: any
  currentAction: string
  // eslint-disable-next-line no-unused-vars
  setCurrenAction?: (currentAction: string) => void
  clickedButton: string
  resetSelected?: () => void
  resetAction: () => void
  // eslint-disable-next-line no-unused-vars
  setToast: (sms: string, type: ToastTypes) => void
  playLoader: () => void
  stopLoader: () => void
}

const Buscar = (props: IBuscarProps) => {
  const {
    clickedButton,
    setCurrenAction,
    onSelectItem,
    resetAction,
    setToast,
    stopLoader,
    playLoader,
    onSelectedItem,
    deletedItem,
    currentAction,
    resetSelected,
  } = props

  const [aplications, setAplications] = React.useState<
    Array<AplicacionInventario>
  >([])
  const [name, setName] = React.useState<string>('')
  const [status, setStatus] = React.useState<boolean>(true)

  React.useEffect(() => {
    if (currentAction === 'Buscar') {
      if (clickedButton === 'Buscar') {
        onHandleSearch()
      } else if (clickedButton === 'Eliminar') {
        deleteItemTable(deletedItem)
      }
      resetAction()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickedButton])

  const deleteItemTable = (idCuenta) => {
    const filtrado = aplications.findIndex((item: any) => {
      return Number(item.codigo) === Number(idCuenta['codigo'])
    })
    const rowsBefore = aplications.slice()
    if (filtrado > -1) {
      rowsBefore.splice(filtrado, 1)
      setAplications(rowsBefore)
      resetSelected()
      setCurrenAction('Buscar')
      resetAction()
    }
  }

  const onHandleSearch = React.useCallback(async () => {
    try {
      setAplications([])
      playLoader()
      const aplicationsData = await aplicationsModalServices.getAplications(
        name,
      )
      stopLoader()
      if (!aplicationsData.error && aplicationsData.auto)
        setAplications(aplicationsData.auto)
      else
        setToast(
          aplicationsData.message ?? 'Error al buscar Aplicaciones',
          ToastTypes.Warning,
        )
    } catch (error) {
      stopLoader()
      setToast(error, ToastTypes.Danger)
    }
  }, [name, playLoader, setToast, stopLoader])

  const onHandleChange = React.useCallback(
    (setState: any) => (event: any) => {
      if (event.event) setState(event.value)
    },
    [],
  )

  return (
    <>
      <RowContainer>
        <CustomCol xs="12" md="5">
          <Labeled label="Nombre:">
            <TextBox
              value={name}
              onValueChanged={onHandleChange(setName)}
              onEnterKey={onHandleSearch}
            />
          </Labeled>
        </CustomCol>
        <CCol style={{ marginTop: 'auto' }}>
          <CheckBox
            text="Activos"
            value={status}
            onValueChanged={onHandleChange(setStatus)}
          />
        </CCol>
      </RowContainer>
      <ResultadoBusqueda
        dataSource={aplications ?? []}
        setToast={setToast}
        onSelectedItem={(item) => onSelectedItem(item)}
        onSelectItem={onSelectItem}
      />
    </>
  )
}
export default memo(Buscar)
