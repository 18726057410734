import React from 'react'
import { LoadPanel, TextBox } from 'devextreme-react'
import { CCard, CCardBody, CCardHeader } from '@coreui/react-pro'
import { useDispatch, useSelector } from 'react-redux'

import Modalform from '../../../views/componentes/modalform'
import { RootState } from '../../../store/store'
import { GlobalMenu } from '../../../views/componentes/globalMenu/globalMenu'
import { AccionMenu, ToastTypes } from '../../../store/types';
import { ButtonTypes } from '../../../views/componentes/globalMenu/types'
import FIleInput from '../fileInput'
import { ArchivosDigitalesSevice } from './service/archivosDigitales.service'
import { addToast } from '../../../store/toasterReducer'
import RowContainer from '../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../views/componentes/colContainer'
import { sidebarService } from '../../../services/sidebar.service'
import { changeLoader } from '../../../store/reducers'
import { ImportExcellService } from '../../clientes/pages/docsCtasXCobrar/components/impportarExcel/service/importExcell.service'
import { EModulosAcatha } from '../../../store/enumsAplication'

interface IModalArchivsoDigitales extends React.PropsWithChildren {
  onChanged: (dataLocal: any) => void
  onCancel: () => void
  show: boolean
  modulo: string
  codModulo: number
  chequeNumero: number
}


const menuDefecto = [
  {
    icon: 'fal fa-edit',
    id: 'Editar',
    text: 'Editar',
    type: ButtonTypes.edit,
  },
  {
    icon: 'fal fa-trash',
    id: 'Eliminar',
    text: 'Eliminar',
    type: ButtonTypes.delete,
  },
]

export const ModalArchivosDigitales: React.FC<IModalArchivsoDigitales> = (props) => {

  const { onCancel, show, modulo, codModulo, chequeNumero } = props

  const empresaState = useSelector((state: RootState) => state.global.session?.empresa);

  const dispatch = useDispatch()

  const botones = menuDefecto.map(acc => {
    const boton: AccionMenu = {
      nombre: acc.id,
      icon: acc.icon,//as any,
      actionType: acc.type,
      main: false,
      modal: false,
      shortcut: null
    }
    return boton;
  });

  const loader = useSelector((state: RootState) => state.global.loader);
  const [acciones,] = React.useState<Array<AccionMenu>>(botones);

  const docaccept = ['.jpg', '.jpeg', '.gif', '.png', '.pdf', '.doc', '.xls']

  const [file, setFile] = React.useState<any>([]);
  const [codigo, setCodigo] = React.useState<any>(0);
  const [tipo, setTipo] = React.useState<string>("");
  const [iGeneralTi, setIGeneralTi] = React.useState<string>(null);
  const [resultados, setResultados] = React.useState<Array<any>>([]);

  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(addToast({
      content: mensaje,
      title: 'Archivos Digitales',
      type: tipo
    }));
  }, [dispatch])

  const playLoader = React.useCallback(() => {
    dispatch(changeLoader({ show: true, mensaje: 'Cargando...' }))
  }, [dispatch])

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoader({ show: false, mensaje: '' }))
  }, [dispatch])




  const onClean = React.useCallback(() => {
    setFile([])
  }, [])

  const findData = React.useCallback(async (modulo: number, asociado: number, tipo: string) => {
    try {
      const objeto = {
        modulo,
        asociado,
        tipo,
      }
      playLoader()
      const data = await ArchivosDigitalesSevice.Cargar(objeto)
      stopLoader()
      if (data !== null && data !== undefined && data['error'] === false) {
        let newArray = []
        newArray = data['auto'].map(item => {
          return item
        })
        setResultados(newArray)
        setIGeneralTi(data['auto'][0].nombre)
        setToast(data['message'], ToastTypes.Success)
      } else {
        setToast(data['message'], ToastTypes.Danger)
      }
    } catch (error) {
      stopLoader()
      setToast(error.message, ToastTypes.Danger)
    }
  }, [setToast, playLoader, stopLoader])


  const saveData = React.useCallback(async (modulo: number, tipo: string, codigo: number, nombreCompleto: string) => {
    const obj = {
      modulo,
      asociado: codigo,
      nombre: nombreCompleto,
      tipo,
    }
    try {
      playLoader()
      const data = await ArchivosDigitalesSevice.Guardar(obj)
      stopLoader()
      if (data !== null && data !== undefined && data['error'] === false) {
        setToast(data['message'], ToastTypes.Success)
        findData(modulo, codigo, tipo)
      } else {
        setToast(data['message'], ToastTypes.Danger)
      }
    } catch (error) {
      stopLoader()
      setToast(error.message, ToastTypes.Danger)
    }
  }, [setToast, findData, playLoader, stopLoader])

  const deleteData = React.useCallback(async () => {
    try {
      const obj = {
        codigo: resultados[0]?.codigo ?? 0,
        nombre: resultados[0]?.nombre ?? "",
      }
      playLoader()
      const data = await ArchivosDigitalesSevice.Eliminar(obj)
      stopLoader()
      if (data !== null && data !== undefined && data['error'] === false) {
        setToast(data['message'], ToastTypes.Success)
        setIGeneralTi(null)
        findData(codModulo, codigo, tipo)
      } else {
        setToast(data['message'], ToastTypes.Danger)
      }
    } catch (error) {
      stopLoader()
      setToast(error.message, ToastTypes.Danger)
    }
  }, [resultados, codModulo, codigo, tipo, findData, setToast, playLoader, stopLoader])

  const onFileChanged = React.useCallback(async (file: any) => {
    if (resultados.length === 0) {
      if (file === null || file.value.length === 0) {
        onClean()
      } else if (file.value.length > 0) {
        const archivo = file.value[0].name
        const formato = archivo.split('.').pop() ?? ""
        const nombreCompleto: string = `${codigo}_${codModulo}_${archivo}`
        setIGeneralTi(nombreCompleto)
        const objeto = {
          tipo: "digitalizados",
          formato,
          archivo: file.value[0],
          // usuario: "",
          ruc: empresaState?.ruc,
          nombre: nombreCompleto,
          password: "",
        }
        try {
          playLoader()
          const data = await sidebarService.docEmpresaUplod(objeto)
          stopLoader()
          if (data !== null && data?.error === false) {
            setToast(data['message'], ToastTypes.Success)
            saveData(codModulo, tipo, codigo, nombreCompleto)
          } else {
            setToast(data['message'], ToastTypes.Danger)
          }
        } catch (error) {
          stopLoader()
          setToast(error.message, ToastTypes.Danger)
        }
      }

    } else {
      setToast('Antes de cargar un nuevo archivo elimine el existente', ToastTypes.Warning)
    }
  }, [onClean, saveData, resultados, setToast, codigo, codModulo, tipo, empresaState, playLoader, stopLoader])

  const editData = React.useCallback(async () => {
    try {
      const response = await ImportExcellService.exportarDemo(iGeneralTi, "digitalizado");
      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement("a")
      a.href = url
      a.download = iGeneralTi
      a.click();
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
  }, [iGeneralTi, setToast])

  React.useEffect(() => {
    let codigo = 0
    let tipo = ""
    if (codModulo === parseInt(EModulosAcatha.Clientes)) {
      codigo = chequeNumero
      if (modulo === "ANTICIPOS") {
        tipo = "CHEQUES ANTICIPO CLIENTE"
      } else {
        tipo = "CHEQUES ABONO CLIENTE"
      }
    } else if (codModulo === parseInt(EModulosAcatha.Proveedores)) {
      codigo = chequeNumero
      if (modulo === "ANTICIPOS") {
        tipo = "CHEQUES ANTICIPO PROVEEDOR"
      } else {
        tipo = "CHEQUES ABONO PROVEEDOR"
      }
    }
    setCodigo(codigo)
    setTipo(tipo)
    findData(codModulo, codigo, tipo)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chequeNumero, codModulo, modulo])



  const returnAction = (boton: AccionMenu) => {

    switch (boton.actionType) {
      case ButtonTypes.edit:
        if (iGeneralTi !== null && iGeneralTi !== undefined && iGeneralTi !== "") {
          editData()
        } else {
          setToast('No hay un archivo subido, suba un archivo antes de descargarlo', ToastTypes.Warning)
        }
        break;
      case ButtonTypes.delete:
        deleteData()
        break;
      default:
        break;
    }
  }

  const getButtonDisabled = React.useCallback((button: AccionMenu) => {
    switch (button.actionType) {
      default:
        return false;
    }
  }, [])

  const botonesModal = () => {
    return (

      <GlobalMenu
        acciones={acciones}
        onClick={returnAction}
        getButtonDisabled={getButtonDisabled}
      />

    );
  }

  const bodyModal = () => {

    return (
      <>
        <p>{'Para la digitalización de archivos, por favor proceda a escanear el documento.'}</p>
        <p>{'Recuerde: * El nombre de archivo no debe contener espacios.'}</p>
        <p>{'Modulo: ' + modulo}</p>
        <RowContainer>
          <CustomCol xs="12" md="6">
            <TextBox
              value={iGeneralTi}
            />
          </CustomCol>
          <CustomCol xs="12" md="6">
            <FIleInput
              accept={''}
              allowedFileExtensions={docaccept}
              onChanged={onFileChanged}
              showDownloadDemo={false}
            />
          </CustomCol>
        </RowContainer>
      </>
    )
  }

  const renderContent = () => {
    return (
      <CCard>
        <CCardHeader>
          {botonesModal()}
        </CCardHeader>
        <CCardBody>
          <LoadPanel
            shadingColor="rgba(0,0,0,0.4)"
            position={'center'}
            visible={loader.show}
            showIndicator={true}
            shading={true}
            showPane={true}
            message={loader.mensaje}
            closeOnOutsideClick={false}
          />
          {bodyModal()}
        </CCardBody>
      </CCard>
    )
  }
  return (
    <>
      <Modalform
        name={'seleccionarArchivosDigitales'}
        key={'seleccionarArchivosDigitales'}
        headerTitle={"Digitalización de archivos"}
        childrenBody={renderContent()}
        closeOnBackdrop={false}
        show={show}
        onClose={() => onCancel()}
        centered={true}
        size='lg'
      />
    </>
  )
}
