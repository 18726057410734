import React from 'react'
import RowContainer from '../../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../../views/componentes/labeledInput/labeledInput'
import { DateBox, NumberBox } from 'devextreme-react'
import { CCol } from '@coreui/react-pro'
import { ItemHistorical } from '../../../../types/types'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../../store/store'
import { setDataHistoricalToEdit } from '../../../../store/editDataReduce'

const Historicos = (props) => {
  const { tabId } = props

  const dispatch = useDispatch()

  const dataToEditHistorical = useSelector(
    (state: RootState) => state.inventarios.items.editData[tabId].historicos,
  )
  const decimalPrecision = useSelector((state: RootState) => {
    return state.global.session.empresa.precision
  })

  const onChangedDataHistorical = React.useCallback(
    (event: any, fieldToUpdate: string) => {
      if (event.event && event.value) {
        let valueToUpdate: number | string = null
        if (fieldToUpdate !== 'ultimaCompra' && fieldToUpdate !== 'ultimaVenta')
          valueToUpdate = parseFloat(event.value.toFixed(decimalPrecision))
        else valueToUpdate = event.value
        dispatch(
          setDataHistoricalToEdit({
            key: tabId,
            historical: {
              ...dataToEditHistorical,
              [fieldToUpdate]: valueToUpdate,
            },
          }),
        )
      }
    },
    [dispatch, tabId, dataToEditHistorical, decimalPrecision],
  )

  return (
    <RowContainer className="m-2">
      <RowContainer>
        <CustomCol xs="12" md="3">
          <Labeled label="Cantidades Pendientes:">
            <CCol>
              <div
                style={{
                  display: 'flex',
                  gap: '4px',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                {'De surtir:'}
                <NumberBox
                  style={{ marginLeft: '5px' }}
                  value={dataToEditHistorical.deSurtir}
                  onValueChanged={(event) =>
                    onChangedDataHistorical(event, 'deSurtir')
                  }
                  showClearButton={true}
                />
              </div>
            </CCol>
            <CCol>
              <div
                style={{
                  display: 'flex',
                  gap: '4px',
                  alignItems: 'center',
                  width: '100%',
                  marginTop: '4px',
                }}
              >
                {'De recibir:'}
                <NumberBox
                  value={dataToEditHistorical.deRecibir}
                  onValueChanged={(event) =>
                    onChangedDataHistorical(event, 'deRecibir')
                  }
                  showClearButton={true}
                />
              </div>
            </CCol>
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="3">
          <Labeled label="Costos:">
            <CCol>
              <div
                style={{
                  display: 'flex',
                  gap: '4px',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                {'Promedio:'}
                <NumberBox
                  style={{ marginLeft: '5px' }}
                  value={dataToEditHistorical.costoPromedio}
                  onValueChanged={(event) =>
                    onChangedDataHistorical(event, 'costoPromedio')
                  }
                  showClearButton={true}
                />
              </div>
            </CCol>
            <CCol>
              <div
                style={{
                  display: 'flex',
                  gap: '4px',
                  alignItems: 'center',
                  width: '100%',
                  marginTop: '4px',
                }}
              >
                {'Ultimo:'}
                <NumberBox
                  style={{ marginLeft: '5px' }}
                  value={dataToEditHistorical.ultimoCosto}
                  onValueChanged={(event) =>
                    onChangedDataHistorical(event, 'ultimoCosto')
                  }
                  showClearButton={true}
                />
              </div>
            </CCol>
          </Labeled>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="3">
          <Labeled label="Acumulados Anuales (Ventas):">
            <CCol>
              <div
                style={{
                  display: 'flex',
                  gap: '4px',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                {'Cantidad:'}
                <NumberBox
                  style={{ marginLeft: '5px' }}
                  value={dataToEditHistorical.cantidadVentas}
                  onValueChanged={(event) =>
                    onChangedDataHistorical(event, 'cantidadVentas')
                  }
                  showClearButton={true}
                />
              </div>
            </CCol>
            <CCol>
              <div
                style={{
                  display: 'flex',
                  gap: '4px',
                  alignItems: 'center',
                  width: '100%',
                  marginTop: '4px',
                }}
              >
                {'Monto:'}
                <NumberBox
                  style={{ marginLeft: '16px' }}
                  value={dataToEditHistorical.montoVenta}
                  onValueChanged={(event) =>
                    onChangedDataHistorical(event, 'montoVenta')
                  }
                  showClearButton={true}
                />
              </div>
            </CCol>
            <CCol>
              <div
                style={{
                  display: 'flex',
                  gap: '4px',
                  alignItems: 'center',
                  marginTop: '4px',
                }}
              >
                {'Última Venta:'}
                <DateBox
                  style={{ marginLeft: '10px' }}
                  displayFormat="dd/MM/yyyy"
                  width={'145px'}
                  value={dataToEditHistorical.ultimaVenta}
                  onValueChanged={(event) =>
                    onChangedDataHistorical(event, 'ultimaVenta')
                  }
                />
              </div>
            </CCol>
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="3">
          <Labeled label="Acumulados Anuales (Compras):">
            <CCol>
              <div
                style={{
                  display: 'flex',
                  gap: '4px',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                {'Cantidad:'}
                <NumberBox
                  style={{ marginLeft: '5px' }}
                  value={dataToEditHistorical.cantidadCompras}
                  onValueChanged={(event) =>
                    onChangedDataHistorical(event, 'cantidadCompras')
                  }
                  showClearButton={true}
                />
              </div>
            </CCol>
            <CCol>
              <div
                style={{
                  display: 'flex',
                  gap: '4px',
                  alignItems: 'center',
                  width: '100%',
                  marginTop: '4px',
                }}
              >
                {'Monto:'}
                <NumberBox
                  style={{ marginLeft: '15px' }}
                  value={dataToEditHistorical.montoCompra}
                  onValueChanged={(event) =>
                    onChangedDataHistorical(event, 'montoCompra')
                  }
                  showClearButton={true}
                />
              </div>
            </CCol>
            <CCol>
              <div
                style={{
                  display: 'flex',
                  gap: '4px',
                  alignItems: 'center',
                  width: '100%',
                  marginTop: '4px',
                }}
              >
                {'Última Compra:'}
                <DateBox
                  displayFormat="dd/MM/yyyy"
                  width={'145px'}
                  value={dataToEditHistorical.ultimaCompra}
                  onValueChanged={(event) =>
                    onChangedDataHistorical(event, 'ultimaCompra')
                  }
                />
              </div>
            </CCol>
          </Labeled>
        </CustomCol>
      </RowContainer>
    </RowContainer>
  )
}

export default React.memo(Historicos)

export const defaultHistoricos: ItemHistorical = {
  deSurtir: 0,
  deRecibir: 0,
  costoPromedio: 0,
  ultimoCosto: 0,
  cantidadVentas: 0,
  montoVenta: 0,
  ultimaVenta: null,
  cantidadCompras: 0,
  montoCompra: 0,
  ultimaCompra: null,
}
