import React, { memo } from 'react'
import CustomCol from '../../../../../views/componentes/colContainer'
import RowContainer from '../../../../../views/componentes/rowContainer/rowContainer'
import Labeled from '../../../../../views/componentes/labeledInput/labeledInput'
import TextBox from 'devextreme-react/text-box'
import LocalesLookUp from '../../../localesLookUp'
import { LocalOption } from '../../../localesLookUp/store/type'
import {
  Validator,
  RequiredRule,
  PatternRule, AsyncRule, StringLengthRule
} from 'devextreme-react/validator';
import { ValidationGroup } from 'devextreme-react/validation-group';
import ValidationSummary from 'devextreme-react/validation-summary';
import { MessagesKeys, lh } from '../../../../../helpers/localizationHelper'
import { TEstado, ToastTypes } from '../../../../../store/types'
import { TIPOS_BOTONES, TVendedorIngresar } from '../../store/types'
import { VendedorBusqueda } from '../../../../shared/components/buscarVendedorLookMemoUp/type/types'
import NumberBox from 'devextreme-react/number-box';
import LookUpTittles from '../../../lookUpTittles'
import { TitleOption } from '../../../lookUpTittles/store/type'
import { getTittles } from '../../../lookUpTittles/helper/helper'
import { getLocales } from '../../../localesLookUp/helper/helper'
import { RootState } from '../../../../../store/store'
import { useDispatch, useSelector } from 'react-redux'
import TipoEstadoLookUp from '../../../tipoEstado'
import VisualizaError from '../../../../ventas/pages/shared/visualizaError/visualizaError'
import { CAlert } from '@coreui/react-pro'
import { getEstados } from '../../../../clientes/pages/clientes/helper/helper'
import { isMobile, isMobileOnly } from 'react-device-detect'
import { VendedoresService } from '../../service/vendedores'

export const emailPatern = /^([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]{1,}[\\;]?)+){1,}$/

type NuevoProps = {
  onChanged: (data) => void
  selection?: VendedorBusqueda
  onCancel?: () => void
  funcion: string
  currentTab: string
  setCurrenTab: (currentTab: string) => void
  resetFunction: () => void
  resetSelected?: () => void
  setToast: (sms: string, type: ToastTypes) => void
  playLoader?: () => void
  stopLoader?: () => void
}

const Nuevo = (props: NuevoProps) => {
  const { funcion, setToast, selection, playLoader, stopLoader, currentTab, resetFunction, resetSelected, setCurrenTab } = props

  const formRef = React.useRef<any>()
  const localState = useSelector((state: RootState) => { return state.global.session?.local });

  const [codigo, setCodigo] = React.useState<number>(0)
  const [codigoUsuario, setCodigoUsuario] = React.useState<number>(0)
  const [email, setEmail] = React.useState<string>('')
  const [nombres, setNombres] = React.useState<string>('')
  const [apellidos, setApellidos] = React.useState<string>('')
  const [identificacion, setIdentificacion] = React.useState<string>('')
  const [telefono, setTelefono] = React.useState<string>('')
  const [local, setLocal] = React.useState<LocalOption | null>(null)
  const [locales, setLocales] = React.useState<Array<LocalOption> | []>([])
  const [titles, setTitles] = React.useState<Array<TitleOption> | []>([])
  const [title, setTitle] = React.useState<TitleOption | null>(null)
  const [comision, setComision] = React.useState<number>(0)
  const [zona, setZona] = React.useState<string>('')
  const [siglas, setSiglas] = React.useState<string>('')
  const [estado, setEstado] = React.useState<TEstado | null>(null)
  const [estados, setEstados] = React.useState<TEstado[] | []>([])
  const [muestraErrores, setMuestraErrores] = React.useState<{ tieneError: boolean, mensajeError: string, mensajesErrors: string[] }>({ tieneError: false, mensajeError: '', mensajesErrors: [] })

  function capturarEnter(): void {
    throw new Error('Function not implemented.')
  }

  const onHandleSubmit = React.useCallback(async () => {
    // resetFunction()
    console.log("onHandleSubmit")
    const result = formRef.current.instance.validate()
    if (result.isValid === false) {
      let sms: Array<string> = [];
      if (result.brokenRules) {
        sms = result.brokenRules.map((x) => {
          return x.message
        })
        setMuestraErrores({ tieneError: true, mensajeError: lh.getMessage(MessagesKeys.GlobalErrorSave), mensajesErrors: sms })
      }
    }
    try {
      playLoader()

      const vendedorRegistrar: TVendedorIngresar = {
        codigo: codigo,
        usuarioCodigo: codigoUsuario,
        comision: comision,
        usuarioNombres: nombres ?? '',
        usuarioApellidos: apellidos ?? '',
        siglas: siglas ?? '',
        estadoCodigo: estado?.codigo ?? 0,
        localCodigo: local?.codigo ?? 0,
        usuarioEmail: email ?? '',
        usuarioIdentificacion: identificacion ?? '',
        usuarioTelefono: telefono ?? '',
        tituloCodigo: title?.codigo ?? 0,
        zona: zona ?? ''
      }
      const resultado = await VendedoresService.ingresarVendedor(vendedorRegistrar);
      stopLoader()
      if (resultado['error'] === false) {
        setCodigo(Number(resultado?.auto?.vendedorCodigo) ?? 0)
        setToast(resultado?.message, ToastTypes.Success);
        setCurrenTab("Buscar")
      } else {
        setToast(resultado?.message ?? "Se produjo un error", ToastTypes.Warning);
      }
    } catch (e) {
      stopLoader()
      setToast(JSON.stringify(e), ToastTypes.Danger);
    }
  }, [
    codigo, comision, siglas, zona, title,
    local, nombres, apellidos, estado, email,
    identificacion, telefono, codigoUsuario,
    setToast, playLoader, stopLoader, setCurrenTab
  ]);


  const onLoad = React.useCallback(async () => {
    formRef.current.instance.reset();
    let sellerData: VendedorBusqueda = { ...dafaultVendedor };
    if (selection !== null && selection?.vendedorCodigo > 0) {
      sellerData = { ...selection }
    }
    console.log("sellerData:", sellerData)
    setEmail(sellerData?.usuarioEmail ?? '')
    setNombres(sellerData?.usuarioNombres ?? '')
    setApellidos(sellerData?.usuarioApellidos ?? '')
    setIdentificacion(sellerData?.usuarioIdentificacion ?? '')
    setCodigoUsuario(sellerData?.usuarioCodigo ?? 0)
    setTelefono(sellerData?.usuarioTelefono ?? '')
    setCodigo(sellerData?.vendedorCodigo ?? 0)

    const optLocales = await getLocales('Elija una opción');

    if (optLocales.length > 0) {
      let locaSelect = optLocales[0] ?? null;
      const locaFind = optLocales.find((opt) => {
        return opt.codigo === Number(localState.codigo)
      })

      if (locaFind !== undefined) {
        locaSelect = locaFind
      }
      setLocales(optLocales ?? []);
      setLocal(locaSelect ?? null)
    }

    const optTittles = await getTittles('Elija una opción');
    if (optTittles.length > 0) {
      setTitles(optTittles ?? []);
      setTitle(optTittles[0] ?? null)
    }
    setComision(sellerData?.comision ?? 0)
    setZona(sellerData?.vendedorZona ?? '')
    setSiglas(sellerData?.siglas ?? '')

    const optEstados = await getEstados();
    if (optEstados.length > 0) {
      let activeStatus = optEstados[0] ?? null;
      const statusFind = optEstados.find((opt) => {
        return opt.codigo === 1
      })
      if (statusFind !== undefined) {
        activeStatus = statusFind
      }
      setEstados(optEstados ?? []);
      setEstado(activeStatus ?? null)
    }

  }, [selection, localState]);


  const validateAsync = React.useCallback((datoSeleccion) => {
    if (datoSeleccion?.value?.codigo > -1) {
      return Promise.resolve()
    } else {
      return Promise.reject(`Tipo de Identificación: Debe seleccionar una opción.`)
    }
  }, []);

  const generaDetalleErrores = React.useCallback((item: any) => {
    if (item.length > 0) {
      return <CAlert color='danger' id={"alert-"}>
        <ul className="errorList" id={"errorList"}>
          {item.map(function (item, id) {
            return <li key={id + "-"}>{item}</li>
          })}
        </ul></CAlert>
    } else {
      return <></>
    }
  }, []);

  React.useEffect(() => {
    // if (currentTab === TIPOS_BOTONES.BOTON_NUEVO || currentTab === TIPOS_BOTONES.BOTON_EDITAR) {
    //   if (funcion === TIPOS_BOTONES.BOTON_GUARDAR) {
    //     onHandleSubmit()
    //   } else if (funcion === TIPOS_BOTONES.BOTON_EDITAR) {
    //     onHandleForm(selection)
    //   } else {
    //     onLoad()
    //   }
    // }
    if (funcion === TIPOS_BOTONES.BOTON_GUARDAR) {
      onHandleSubmit()
    } else if (funcion === TIPOS_BOTONES.BOTON_EDITAR) {
      // onLoad()
    } else if (funcion === TIPOS_BOTONES.BOTON_DESHACER) {
      onLoad()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [funcion, selection]);

  React.useEffect(() => {
    if (currentTab === TIPOS_BOTONES.BOTON_NUEVO || currentTab === TIPOS_BOTONES.BOTON_EDITAR) {
      onLoad()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);


  return (
    <>
      {muestraErrores.tieneError &&
        <VisualizaError
          titulo=''
          mensaje={muestraErrores?.mensajeError ?? ''}
          content={generaDetalleErrores(muestraErrores.mensajesErrors)}
          onOk={() => {
            setMuestraErrores({ tieneError: false, mensajesErrors: [], mensajeError: '' })
          }}
        />}

      <fieldset>
        <ValidationGroup ref={formRef}>
          {!isMobileOnly &&
            <RowContainer>
              <CustomCol>
                <ValidationSummary />
              </CustomCol>
            </RowContainer>
          }
          <RowContainer className='d-flex justify-content-end'>
            <CustomCol xs="12" md="2" >
              <NumberBox
                name="codigo"
                value={codigo ?? null}
                readOnly
              />
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol xs="12" md="6">
              <RowContainer>
                <CustomCol xs="12" md="12">
                  <Labeled label="Email *">
                    <TextBox
                      name="email"
                      value={email ?? ''}
                      onValueChanged={(evt) => {
                        if (evt?.event !== undefined) {
                          setEmail(String(evt?.value).trim())
                        }
                      }}
                      onEnterKey={() => capturarEnter()}
                    >
                      <Validator>
                        <RequiredRule message={lh.getMessage(MessagesKeys.GlobalCampoRequerido, "Email")} />
                        <PatternRule pattern={emailPatern} message={lh.getMessage(MessagesKeys.GlobalFormatoCampoIncorrecto, "Email")} />
                        <StringLengthRule max='70' message={lh.getMessage(MessagesKeys.GlobalInputMax, "Email", '70')} />
                        <StringLengthRule min='3' message={lh.getMessage(MessagesKeys.GlobalInputMin, "Email", '3')} />
                      </Validator>
                    </TextBox>
                  </Labeled>
                </CustomCol>
              </RowContainer>

              <RowContainer>
                <CustomCol xs="12" md="12">
                  <Labeled label="Nombres *">
                    <TextBox
                      name="nombres"
                      value={nombres ?? ''}
                      onValueChanged={(evt) => {
                        if (evt?.event !== undefined) {
                          setNombres(evt?.value ?? "")
                        }
                      }}
                    >
                      <Validator>
                        <RequiredRule trim message={lh.getMessage(MessagesKeys.GlobalCampoRequerido, "Nombres")} />
                        <StringLengthRule max='70' message={lh.getMessage(MessagesKeys.GlobalInputMax, "Nombres", '70')} />
                        <StringLengthRule min='3' message={lh.getMessage(MessagesKeys.GlobalInputMin, "Nombres", '3')} />
                      </Validator>
                    </TextBox>
                  </Labeled>
                </CustomCol>
              </RowContainer>

              <RowContainer>
                <CustomCol xs="12" md="12">
                  <Labeled label="Apellidos *">
                    <TextBox
                      name="apellidos"
                      value={apellidos ?? ''}
                      onValueChanged={(evt) => {
                        if (evt?.event !== undefined) {
                          setApellidos(evt?.value ?? "")
                        }
                      }}
                      onEnterKey={() => setApellidos}
                    >
                      <Validator>
                        <RequiredRule trim message={lh.getMessage(MessagesKeys.GlobalCampoRequerido, "Apellidos")} />
                        <StringLengthRule max='70' message={lh.getMessage(MessagesKeys.GlobalInputMax, "Apellidos", '70')} />
                        <StringLengthRule min='3' message={lh.getMessage(MessagesKeys.GlobalInputMin, "Apellidos", '3')} />
                      </Validator>
                    </TextBox>
                  </Labeled>
                </CustomCol>
              </RowContainer>

              <RowContainer>
                <CustomCol xs="12" md="6">
                  <Labeled label="Identificación *">
                    <TextBox
                      name="identificacion"
                      value={identificacion ?? ''}
                      onValueChanged={(evt) => {
                        if (evt?.event !== undefined) {
                          setIdentificacion(evt?.value ?? "")
                        }
                      }}
                      onEnterKey={() => setIdentificacion}
                    >
                      <Validator>
                        <RequiredRule trim message={lh.getMessage(MessagesKeys.GlobalCampoRequerido, "Identificación")} />
                        <StringLengthRule max='45' message={lh.getMessage(MessagesKeys.GlobalInputMax, "Identificación", '45')} />
                        <StringLengthRule min='6' message={lh.getMessage(MessagesKeys.GlobalInputMin, "Identificación", '6')} />
                      </Validator>
                    </TextBox>
                  </Labeled>
                </CustomCol>

                <CustomCol xs="12" md="6">
                  <Labeled label="Teléfono *">
                    <TextBox
                      name="telefono"
                      value={telefono ?? ''}
                      onValueChanged={(evt) => {
                        if (evt?.event !== undefined) {
                          setTelefono(evt?.value ?? "")
                        }
                      }}
                    >
                      <Validator>
                        <RequiredRule trim message={lh.getMessage(MessagesKeys.GlobalCampoRequerido, "Teléfono")} />
                        <StringLengthRule max='15' message={lh.getMessage(MessagesKeys.GlobalInputMax, "Teléfono", '15')} />
                        <StringLengthRule min='5' message={lh.getMessage(MessagesKeys.GlobalInputMin, "Teléfono", '5')} />
                      </Validator>
                    </TextBox>
                  </Labeled>
                </CustomCol>
              </RowContainer>

            </CustomCol>
            <CustomCol xs="12" md="6">

              <RowContainer>
                <CustomCol xs="12" md="8">
                  <Labeled label="Locales">
                    <LocalesLookUp
                      onChanged={(data) => {
                        console.log('localidad ', data)
                        if (data !== null || data !== undefined) {
                          setLocal(data)
                        }
                      }}
                      selected={local}
                      provider={locales ?? []}
                    />
                  </Labeled>
                </CustomCol>
              </RowContainer>

              <RowContainer>
                <CustomCol xs="12" md="9">
                  <Labeled label="Titulos">
                    <LookUpTittles
                      provider={titles ?? []}
                      selected={title ?? null}
                      onChanged={(data) => {
                        if (data !== null || data !== undefined) {
                          setTitle(data)
                        }
                      }}
                    >
                      <Validator>
                        <RequiredRule
                          message={lh.getMessage(MessagesKeys.GlobalCampoRequerido, "Titulos")} />
                        <AsyncRule
                          message={'Titulos: Debe seleccionar una opción'}
                          validationCallback={validateAsync}
                        />
                      </Validator>
                    </LookUpTittles>
                  </Labeled>
                </CustomCol>
              </RowContainer>

              <RowContainer>
                <CustomCol xs="12" md="6">
                  <Labeled label="% de Comision">
                    <NumberBox
                      name="comision"
                      value={comision}
                      onValueChanged={(e) => {
                        if (e.event !== undefined || e.event !== null) {
                          setComision(e.value)
                        }
                      }}
                      elementAttr={{
                        autoFocus: true,
                      }}
                    />
                  </Labeled>
                </CustomCol>

                <CustomCol xs="12" md="6">
                  <Labeled label="Zona">
                    <TextBox
                      name="zona"
                      value={zona}
                      onValueChanged={({ value }: any) => setZona(value)}
                      elementAttr={{
                        autoFocus: true,
                      }}
                    />
                  </Labeled>
                </CustomCol>
              </RowContainer>

              <RowContainer>
                <CustomCol xs="12" md="3">
                  <Labeled label="Siglas">
                    <TextBox
                      name="Siglas"
                      value={siglas}
                      onValueChanged={({ value }: any) => setSiglas(value)}
                      elementAttr={{
                        autoFocus: true,
                      }}
                      maxLength={6}
                    >
                      <StringLengthRule min='3' message={lh.getMessage(MessagesKeys.GlobalInputMin, "Siglas", '3')} />
                    </TextBox>
                  </Labeled>
                </CustomCol>

                <CustomCol xs="12" md="4">
                  <Labeled label="Estado">
                    <TipoEstadoLookUp
                      selected={estado ?? null}
                      onChanged={(d) => {
                        if (d !== null || d !== undefined) {
                          setEstado(d)
                        }
                      }}
                      provider={estados ?? []}
                    >
                      <Validator>
                        <RequiredRule message={lh.getMessage(MessagesKeys.GlobalCampoRequerido, "Estado")} />
                      </Validator>
                    </TipoEstadoLookUp>
                  </Labeled>
                </CustomCol>
              </RowContainer>
            </CustomCol>
          </RowContainer>

          {isMobileOnly &&
            <RowContainer>
              <CustomCol>
                <ValidationSummary />
              </CustomCol>
            </RowContainer>
          }

        </ValidationGroup >
      </fieldset >
    </>
  )
}
export default Nuevo


const dafaultVendedor = {
  vendedorCodigo: 0,
  usuarioCodigo: 0,
  comision: 0,
  vendedorZona: '',
  usuarioNombres: '',
  usuarioApellidos: '',
  completo: '',
  siglas: '',
  estado: '',
  localCodigo: '',
  usuarioEmail: '',
  usuarioIdentificacion: '',
  usuarioTelefono: '',
}