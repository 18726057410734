import React, {
  FC,
  PropsWithChildren,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import SelectBox from 'devextreme-react/select-box'
import { EstadoProgresoServices } from './services/estadoProgreso.services'
import { ProgressStatus } from './types/types'
import { ESubtipo } from '../../../store/enum/subtypes'

interface EstadoProgresoLookUpProps extends PropsWithChildren {
  selected: ProgressStatus | null
  // eslint-disable-next-line no-unused-vars
  onChanged: (newValue: ProgressStatus | null) => void
  // eslint-disable-next-line no-unused-vars
  onChangedOptions: (newValue: Array<ProgressStatus> | []) => void
  disabled?: boolean
}

const EstadoProgresoLookUp: FC<EstadoProgresoLookUpProps> = (props) => {
  const { selected, onChanged, disabled, onChangedOptions } = props
  const selectRef = useRef<any>()
  const [options, setOptions] = useState<Array<ProgressStatus> | []>([])

  const loadDatos = useCallback(async () => {
    const data = await EstadoProgresoServices.getProgressStatus(
      'Seleccione un tipo',
      ESubtipo.Progreso,
    )
    if (data.error === false) {
      const optionsLookup = []
      const filteredOptions = data.auto.filter(
        (progress) => progress.codigo <= 52,
      )
      if (filteredOptions.length > 0) {
        filteredOptions.forEach((option) => {
          optionsLookup.push({
            codigo: option?.codigo ?? -1,
            valor: option?.valor ?? '',
            tipoCodigo: option?.tipoCodigo ?? '',
            grupo: option?.grupo ?? '',
            orden: option?.orden ?? -1,
            estado: option?.estado ?? '',
          })
        })
      }
      setOptions(optionsLookup)
      onChangedOptions(optionsLookup)
      const selectedIndex = optionsLookup.findIndex((option) => {
        return option.codigo === selected?.codigo
      })
      onChanged(optionsLookup[selectedIndex] ?? optionsLookup[0])
    }
  }, [onChanged, onChangedOptions, selected])

  const getItemDisplayExpr = useCallback((item: ProgressStatus) => {
    return item ? `${item.valor}` : ''
  }, [])

  const onLookUpValueChanged = useCallback(
    (evt: any) => {
      if (evt.event !== undefined) onChanged(evt.value)
    },
    [onChanged],
  )

  const onSelectBoxFocusIn = useCallback((e) => {
    e.element.querySelector('input.dx-texteditor-input').select()
  }, [])

  const refreshSelected = useCallback(() => {
    if (options && options.length > 0) {
      const selectedIndex = options.findIndex((option: ProgressStatus) => {
        return option.codigo === selected?.codigo
      })
      onChanged(options[selectedIndex] || options[0])
    }
  }, [onChanged, options, selected])

  useEffect(() => {
    loadDatos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    refreshSelected()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  return (
    <SelectBox
      id="preogressStatusSelect"
      ref={selectRef}
      items={options ?? []}
      searchEnabled
      displayExpr={getItemDisplayExpr}
      searchExpr={['valor']}
      searchTimeout={500}
      value={selected}
      onValueChanged={onLookUpValueChanged}
      disabled={disabled}
      onFocusIn={onSelectBoxFocusIn}
    >
      {props.children}
    </SelectBox>
  )
}
export default memo(EstadoProgresoLookUp)
