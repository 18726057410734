import { combineReducers } from "redux";
import { ContratosState } from "../types/types";
import { menuReducer } from "./menuReducer";
import { searchReducer } from "./searchReducer";
import { tabsReducer } from "./tabsReducer";
import { dataEditReducer } from "./editDataReducer";
import { configuracionesContratosNomina } from "./configReducer";



export const ContratosReducer = combineReducers<ContratosState>({
  menu: menuReducer,
  search: searchReducer,
  tabs: tabsReducer,
  editData: dataEditReducer,
  config: configuracionesContratosNomina
})