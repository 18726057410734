import { DateUtils, formatoFechasApi } from '../../../../helpers/dateUtils'
import { RequestHelper } from '../../../../helpers/requestHelper'
import { SesionService } from '../../../../services/sesion.service'
import { ESubtipo } from '../../../../store/enum/subtypes'
import { TEstado } from '../../../../store/types'

export const tipoClasificacionesSujExc = { getTipoClasificacionesSujExc }

async function getTipoClasificacionesSujExc(
  label: string,
  paisCodigo: number,
): Promise<any> {
  try {
    const data: any = {
      tipoCodigo: ESubtipo.ClasificacionesSujEx,
      mensaje: label,
      paisCodigo: paisCodigo,
    }
    const sesion = SesionService.getCurrentSesion()
    const keysToCache: any = {
      ...data,
      dateKey: DateUtils.getCurrentDateAsString(formatoFechasApi),
      empresa: sesion?.empresa?.codigo,
    }
    const cacheKey = 'tipoClasificacionesSujExc' + JSON.stringify(keysToCache)
    const cached = sessionStorage.getItem(cacheKey)
    if (cached) {
      return { error: false, auto: JSON.parse(cached) }
    } else {
      const tipos: any = await RequestHelper.getAll(
        'subtypes',
        'cboxLoad',
        '',
        data,
      )
      const opt: Array<TEstado> = []
      if (tipos?.auto && tipos?.auto.length > 0) {
        for (const x of tipos.auto) {
          opt.push({
            codigo: parseInt(x.codigo) ?? -1,
            descripcion: x.valor ?? '',
            codigoTributario: x?.grupo ?? '',
          })
        }
      }
      sessionStorage.setItem(cacheKey, JSON.stringify(opt))
      tipos.auto = opt ?? []
      return tipos
    }
  } catch (error) {
    return error
  }
}
