import { combineReducers, } from "@reduxjs/toolkit";
import { OrdenPedidoState } from "../type/types";
import { menuReducer } from "./menuReducer";
import { tabsReducer } from "./tabsReducer";
import { searchOrdenPedidoReducer } from "./searchReducer";
import { datosEdicionReducer } from "./editDataReducer";
import { configuracionesOrdenPedidoReducer } from "./configuracionesOrdenPedidoReducer";

export const ordenPedidoReducer = combineReducers<OrdenPedidoState>({
    menu: menuReducer,
    tabs: tabsReducer,
    search: searchOrdenPedidoReducer,
    editData: datosEdicionReducer,
    configuraciones: configuracionesOrdenPedidoReducer
});