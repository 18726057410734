import { combineReducers } from "redux";
import { comprasReducer } from "../pages/compras/store/comprasReducer";
import { retencionesReducer } from "../pages/retenciones/store/retencionesReducer";
import { ModuloComprasState } from "../types/types";
import { moduloComprasSVReducer } from "../sv/store/moduloComprasSVReducer";

export const moduloComprasReducer = combineReducers<ModuloComprasState>({
  retenciones: retencionesReducer,
  compras: comprasReducer,
  comprasSV: moduloComprasSVReducer,
})
