const prefijos = [
  { name: 'Prefijo', value: '0', mask: '' },
  { name: '+1', value: '+1', mask: '' },
  { name: '+7', value: '+7', mask: '' },
  { name: '+20', value: '+20', mask: '' },
  { name: '+27', value: '+27', mask: '' },
  { name: '+30', value: '+30', mask: '' },
  { name: '+31', value: '+31', mask: '' },
  { name: '+32', value: '+32', mask: '' },
  { name: '+33', value: '+33', mask: '' },
  { name: '+34', value: '+34', mask: '' },
  { name: '+36', value: '+36', mask: '' },
  { name: '+39', value: '+39', mask: '' },
  { name: '+40', value: '+40', mask: '' },
  { name: '+41', value: '+41', mask: '' },
  { name: '+43', value: '+43', mask: '' },
  { name: '+44', value: '+44', mask: '' },
  { name: '+45', value: '+45', mask: '' },
  { name: '+46', value: '+46', mask: '' },
  { name: '+47', value: '+47', mask: '' },
  { name: '+48', value: '+48', mask: '' },
  { name: '+49', value: '+49', mask: '' },
  { name: '+51', value: '+51', mask: '' },
  { name: '+52', value: '+52', mask: '' },
  { name: '+53', value: '+53', mask: '' },
  { name: '+54', value: '+54', mask: '' },
  { name: '+55', value: '+55', mask: '' },
  { name: '+56', value: '+56', mask: '' },
  { name: '+57', value: '+57', mask: '' },
  { name: '+58', value: '+58', mask: '' },
  { name: '+60', value: '+60', mask: '' },
  { name: '+61', value: '+61', mask: '' },
  { name: '+62', value: '+62', mask: '' },
  { name: '+63', value: '+63', mask: '' },
  { name: '+64', value: '+64', mask: '' },
  { name: '+65', value: '+65', mask: '' },
  { name: '+66', value: '+66', mask: '' },
  { name: '+81', value: '+81', mask: '' },
  { name: '+82', value: '+82', mask: '' },
  { name: '+84', value: '+84', mask: '' },
  { name: '+86', value: '+86', mask: '' },
  { name: '+90', value: '+90', mask: '' },
  { name: '+91', value: '+91', mask: '' },
  { name: '+92', value: '+92', mask: '' },
  { name: '+93', value: '+93', mask: '' },
  { name: '+94', value: '+94', mask: '' },
  { name: '+95', value: '+95', mask: '' },
  { name: '+98', value: '+98', mask: '' },
  { name: '+212', value: '+212', mask: '' },
  { name: '+213', value: '+213', mask: '' },
  { name: '+216', value: '+216', mask: '' },
  { name: '+218', value: '+218', mask: '' },
  { name: '+220', value: '+220', mask: '' },
  { name: '+221', value: '+221', mask: '' },
  { name: '+222', value: '+222', mask: '' },
  { name: '+223', value: '+223', mask: '' },
  { name: '+224', value: '+224', mask: '' },
  { name: '+225', value: '+225', mask: '' },
  { name: '+226', value: '+226', mask: '' },
  { name: '+227', value: '+227', mask: '' },
  { name: '+228', value: '+228', mask: '' },
  { name: '+229', value: '+229', mask: '' },
  { name: '+230', value: '+230', mask: '' },
  { name: '+231', value: '+231', mask: '' },
  { name: '+232', value: '+232', mask: '' },
  { name: '+233', value: '+233', mask: '' },
  { name: '+234', value: '+234', mask: '' },
  { name: '+235', value: '+235', mask: '' },
  { name: '+236', value: '+236', mask: '' },
  { name: '+237', value: '+237', mask: '' },
  { name: '+238', value: '+238', mask: '' },
  { name: '+239', value: '+239', mask: '' },
  { name: '+240', value: '+240', mask: '' },
  { name: '+241', value: '+241', mask: '' },
  { name: '+242', value: '+242', mask: '' },
  { name: '+243', value: '+243', mask: '' },
  { name: '+244', value: '+244', mask: '' },
  { name: '+245', value: '+245', mask: '' },
  { name: '+246', value: '+246', mask: '' },
  { name: '+248', value: '+248', mask: '' },
  { name: '+249', value: '+249', mask: '' },
  { name: '+250', value: '+250', mask: '' },
  { name: '+251', value: '+251', mask: '' },
  { name: '+252', value: '+252', mask: '' },
  { name: '+253', value: '+253', mask: '' },
  { name: '+254', value: '+254', mask: '' },
  { name: '+255', value: '+255', mask: '' },
  { name: '+256', value: '+256', mask: '' },
  { name: '+257', value: '+257', mask: '' },
  { name: '+258', value: '+258', mask: '' },
  { name: '+260', value: '+260', mask: '' },
  { name: '+261', value: '+261', mask: '' },
  { name: '+262', value: '+262', mask: '' },
  { name: '+263', value: '+263', mask: '' },
  { name: '+264', value: '+264', mask: '' },
  { name: '+265', value: '+265', mask: '' },
  { name: '+266', value: '+266', mask: '' },
  { name: '+267', value: '+267', mask: '' },
  { name: '+268', value: '+268', mask: '' },
  { name: '+269', value: '+269', mask: '' },
  { name: '+290', value: '+290', mask: '' },
  { name: '+291', value: '+291', mask: '' },
  { name: '+297', value: '+297', mask: '' },
  { name: '+298', value: '+298', mask: '' },
  { name: '+299', value: '+299', mask: '' },
  { name: '+345', value: '+345', mask: '' },
  { name: '+350', value: '+350', mask: '' },
  { name: '+351', value: '+351', mask: '' },
  { name: '+352', value: '+352', mask: '' },
  { name: '+353', value: '+353', mask: '' },
  { name: '+354', value: '+354', mask: '' },
  { name: '+355', value: '+355', mask: '' },
  { name: '+356', value: '+356', mask: '' },
  { name: '+357', value: '+357', mask: '' },
  { name: '+358', value: '+358', mask: '' },
  { name: '+359', value: '+359', mask: '' },
  { name: '+370', value: '+370', mask: '' },
  { name: '+371', value: '+371', mask: '' },
  { name: '+372', value: '+372', mask: '' },
  { name: '+373', value: '+373', mask: '' },
  { name: '+374', value: '+374', mask: '' },
  { name: '+375', value: '+375', mask: '' },
  { name: '+376', value: '+376', mask: '' },
  { name: '+377', value: '+377', mask: '' },
  { name: '+378', value: '+378', mask: '' },
  { name: '+379', value: '+379', mask: '' },
  { name: '+380', value: '+380', mask: '' },
  { name: '+381', value: '+381', mask: '' },
  { name: '+382', value: '+382', mask: '' },
  { name: '+385', value: '+385', mask: '' },
  { name: '+386', value: '+386', mask: '' },
  { name: '+387', value: '+387', mask: '' },
  { name: '+389', value: '+389', mask: '' },
  { name: '+420', value: '+420', mask: '' },
  { name: '+421', value: '+421', mask: '' },
  { name: '+423', value: '+423', mask: '' },
  { name: '+500', value: '+500', mask: '' },
  { name: '+501', value: '+501', mask: '' },
  { name: '+502', value: '+502', mask: '' },
  { name: '+503', value: '+503', mask: '0000-0000' },
  { name: '+504', value: '+504', mask: '' },
  { name: '+505', value: '+505', mask: '' },
  { name: '+506', value: '+506', mask: '' },
  { name: '+507', value: '+507', mask: '' },
  { name: '+508', value: '+508', mask: '' },
  { name: '+509', value: '+509', mask: '' },
  { name: '+537', value: '+537', mask: '' },
  { name: '+590', value: '+590', mask: '' },
  { name: '+591', value: '+591', mask: '' },
  { name: '+593', value: '+593', mask: '(00) 000-0000' },
  { name: '+594', value: '+594', mask: '' },
  { name: '+595', value: '+595', mask: '' },
  { name: '+596', value: '+596', mask: '' },
  { name: '+597', value: '+597', mask: '' },
  { name: '+598', value: '+598', mask: '' },
  { name: '+599', value: '+599', mask: '' },
  { name: '+670', value: '+670', mask: '' },
  { name: '+672', value: '+672', mask: '' },
  { name: '+673', value: '+673', mask: '' },
  { name: '+674', value: '+674', mask: '' },
  { name: '+675', value: '+675', mask: '' },
  { name: '+676', value: '+676', mask: '' },
  { name: '+677', value: '+677', mask: '' },
  { name: '+678', value: '+678', mask: '' },
  { name: '+679', value: '+679', mask: '' },
  { name: '+680', value: '+680', mask: '' },
  { name: '+681', value: '+681', mask: '' },
  { name: '+682', value: '+682', mask: '' },
  { name: '+683', value: '+683', mask: '' },
  { name: '+685', value: '+685', mask: '' },
  { name: '+686', value: '+686', mask: '' },
  { name: '+687', value: '+687', mask: '' },
  { name: '+688', value: '+688', mask: '' },
  { name: '+689', value: '+689', mask: '' },
  { name: '+690', value: '+690', mask: '' },
  { name: '+691', value: '+691', mask: '' },
  { name: '+692', value: '+692', mask: '' },
  { name: '+850', value: '+850', mask: '' },
  { name: '+852', value: '+852', mask: '' },
  { name: '+853', value: '+853', mask: '' },
  { name: '+855', value: '+855', mask: '' },
  { name: '+856', value: '+856', mask: '' },
  { name: '+872', value: '+872', mask: '' },
  { name: '+880', value: '+880', mask: '' },
  { name: '+886', value: '+886', mask: '' },
  { name: '+960', value: '+960', mask: '' },
  { name: '+961', value: '+961', mask: '' },
  { name: '+962', value: '+962', mask: '' },
  { name: '+963', value: '+963', mask: '' },
  { name: '+964', value: '+964', mask: '' },
  { name: '+965', value: '+965', mask: '' },
  { name: '+966', value: '+966', mask: '' },
  { name: '+967', value: '+967', mask: '' },
  { name: '+968', value: '+968', mask: '' },
  { name: '+970', value: '+970', mask: '' },
  { name: '+971', value: '+971', mask: '' },
  { name: '+972', value: '+972', mask: '' },
  { name: '+973', value: '+973', mask: '' },
  { name: '+974', value: '+974', mask: '' },
  { name: '+975', value: '+975', mask: '' },
  { name: '+976', value: '+976', mask: '' },
  { name: '+977', value: '+977', mask: '' },
  { name: '+992', value: '+992', mask: '' },
  { name: '+993', value: '+993', mask: '' },
  { name: '+994', value: '+994', mask: '' },
  { name: '+995', value: '+995', mask: '' },
  { name: '+996', value: '+996', mask: '' },
  { name: '+998', value: '+998', mask: '' },
]

export default prefijos
