import React, { useEffect, useState } from 'react'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import { TransferEditData } from '../../types/types'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import { changeCurrentTab } from '../../store/searchReducer'
import {
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
} from '@coreui/react-pro'
import Cabecera from './cabecera'
import Detalle from './detalle'
import { TabState } from '../../../../../../../store/genericTabTypes'
import FiltroBusqueda from './filtroBusqueda'
import BlockUi from '../../../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../../../views/componentes/loadingindicator/loadingindicator'
import { isMobile } from 'react-device-detect'
import { SpeedDialAction } from 'devextreme-react'
import { SearchViews } from '../../../../items/components/buscar'
import { ButtonTypes } from '../../../../../../../views/componentes/globalMenu/types'

export const optionsActivos = [
  { value: 1, label: 'Activos' },
  { value: 2, label: 'Inactivos' },
]

interface ISearchProps extends React.PropsWithChildren {
  tab: TabState<TransferEditData>
  tabId: string
}

const Search: React.FunctionComponent<ISearchProps> = (props) => {
  const dispatch = useDispatch()

  const currentTab = useSelector((state: RootState) => {
    return state.inventarios.movimientos.transferencias.search
  })
  const loader = useSelector((state: RootState) => {
    return state.inventarios.movimientos.transferencias.search.loader
  })
  const tabs = useSelector((state: RootState) => {
    return state.inventarios.movimientos.transferencias.tabs
  })

  const [currentView, setCurrentView] = useState<SearchViews>(
    SearchViews.Filter,
  )

  useEffect(() => {
    if (tabs.tabs[props.tabId].currentExecutingAction === ButtonTypes.find)
      setCurrentView(SearchViews.Search)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs.tabs[props.tabId].currentExecutingAction])

  return (
    <>
      {isMobile && tabs.current === 'BUSQUEDA' && (
        <>
          <SpeedDialAction
            icon="filter"
            label="Filtros"
            visible={true}
            index={1}
            onClick={() => {
              setCurrentView(SearchViews.Filter)
            }}
          />
          <SpeedDialAction
            icon="search"
            label="Búsqueda"
            visible={true}
            index={2}
            onClick={() => {
              setCurrentView(SearchViews.Search)
            }}
          />
        </>
      )}
      <BlockUi
        tag="div"
        loader={LoadingIndicator}
        blocking={loader.show}
        message={loader.mensaje}
      >
        {(currentView === SearchViews.Filter || !isMobile) && (
          <FiltroBusqueda />
        )}
        {(currentView === SearchViews.Search || !isMobile) && (
          <RowContainer className="m-2">
            <CNav variant="tabs" role="tablist" className="mt-4">
              <CNav variant="tabs">
                {currentTab.tabs.map((tab, index) => (
                  <CNavItem key={index}>
                    <CNavLink
                      onClick={() =>
                        dispatch(changeCurrentTab(currentTab.tabs[index]))
                      }
                      active={currentTab.currentTab === tab}
                    >
                      {tab}
                    </CNavLink>
                  </CNavItem>
                ))}
              </CNav>
              <CTabContent
                style={{
                  overflowY: 'unset',
                  width: '100%',
                  marginBottom: '10px',
                }}
              >
                <CTabPane
                  role="tabpanel"
                  aria-labelledby="cabecera-tab"
                  visible={currentTab.currentTab === currentTab.tabs[0]}
                >
                  <Cabecera {...props} />
                </CTabPane>
                <CTabPane
                  role="tabpanel"
                  aria-labelledby="detalle-tab"
                  visible={currentTab.currentTab === currentTab.tabs[1]}
                >
                  <Detalle />
                </CTabPane>
              </CTabContent>
            </CNav>
          </RowContainer>
        )}
      </BlockUi>
    </>
  )
}

export default Search
