import { combineReducers } from '@reduxjs/toolkit'
import { menuReducer } from './menuReducer'
import { DocumentosCtasXPagar } from '../types/types'
import { searchDocumentsToPayReducer } from './searchReducer'
import { datosEdicionDocumentosCuentasPorPagarReducer } from './editDataReducer'
import { tabsReducer } from './tabsReducer'
import { supplierstoPayReducer } from './suppliersToPay'
import { payReducer } from './payReducer'
import { configuracionesDocsCtosXPagarReducer } from './configuracionesDtosCtasXPagar'
import { seeSubscriptionsReducer } from './seeSubscriptionsReducer'
import { balancesReducer } from './balancesReducer'
import { advancesReducer } from './advancesReducer'
import { walletReducer } from './walletReducer'

export const documentosCuentasPorPagarReducer =
  combineReducers<DocumentosCtasXPagar>({
    menu: menuReducer,
    tabs: tabsReducer,
    search: searchDocumentsToPayReducer,
    editData: datosEdicionDocumentosCuentasPorPagarReducer,
    suppliersToPay: supplierstoPayReducer,
    pay: payReducer,
    configuraciones: configuracionesDocsCtosXPagarReducer,
    seeSubscriptions: seeSubscriptionsReducer,
    balances: balancesReducer,
    advances: advancesReducer,
    wallet: walletReducer,
  })
