import React, { useCallback, useEffect } from 'react'
import Modalform from '../../../views/componentes/modalform'
import { CCard, CCardBody } from '@coreui/react-pro'
import { DataGrid, LoadPanel } from 'devextreme-react'
import RowContainer from '../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../views/componentes/colContainer'
import { Column, Editing, Pager, Paging } from 'devextreme-react/data-grid'
import { getAllowedPageSizes } from '../../../helpers/Helper'
import { useDispatch, useSelector } from 'react-redux'
import { changeLoader } from '../../../store/reducers'
import { RootState } from '../../../store/store'
import { DetailReister } from './services/detalleRegistro.services'
import { consoleService } from '../../../services/console.service'
import { addToast } from '../../../store/toasterReducer'
import { ToastTypes } from '../../../store/types'
import { RegisterDetail } from './types/types'

interface IModalDetalleRegistro extends React.PropsWithChildren {
  onCancel: () => void
  show: boolean
  usuarioCodigo: number
  fecha: string
}

export const ModalDetalleRegistro: React.FC<IModalDetalleRegistro> = (props) => {

  const { onCancel, show, usuarioCodigo, fecha } = props
  const dispatch = useDispatch()
  const loader = useSelector((state: RootState) => state.global.loader);

  const [detalle, setDetalle] = React.useState<Array<RegisterDetail> | []>([]);
  const [changes, setChanges] = React.useState<any>();
  const [editRowKey, setEditRowKey] = React.useState<any>();

  const playLoader = React.useCallback(() => {
    dispatch(changeLoader({ show: true, mensaje: 'Cargando...' }))
  }, [dispatch])

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoader({ show: false, mensaje: '' }))
  }, [dispatch])


  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(addToast({
      content: mensaje,
      title: 'Detalle de Registro',
      type: tipo
    }));
  }, [dispatch])

  const onFind = useCallback(async () => {
    playLoader()
    try {
      const data = await DetailReister.getDetail(usuarioCodigo, fecha)
      consoleService.log(data, 'get datail')
      if (data?.auto && data?.error === false) {
        setDetalle(data?.auto)
        setToast(data?.message, ToastTypes.Success)
      } else {
        setToast(data?.message, ToastTypes.Danger)
        setDetalle([])
      }
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
    stopLoader()
  }, [usuarioCodigo, fecha, setToast, playLoader, stopLoader])


  useEffect(() => {
    onFind()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onChangesChange = React.useCallback((changes) => {
    setChanges(changes);
  }, [])

  const onEditRowKeyChange = React.useCallback((editRowKey) => {
    setEditRowKey(editRowKey);
  }, [])

  const bodyModal = () => {

    return (
      <>
        <RowContainer>
          <CustomCol xs="12" md="12">
            <DataGrid
              id="tablaErrores"
              dataSource={detalle ?? []}
              selection={{ mode: 'single' }}
              hoverStateEnabled={true}
              showBorders={true}
              showRowLines={true}
              repaintChangesOnly={true}
              highlightChanges={true}
              keyExpr="codigo"
              key="codigo"
              focusedRowKey="codigo"
              showColumnLines={true}
              className={'m-2 p-2'}
              loadPanel={{
                enabled: true,
                height: 90,
                indicatorSrc: '',
                shading: false,
                shadingColor: '',
                showIndicator: true,
                showPane: true,
                text: 'Cargando...',
                width: 200,
              }}
            >

              <Editing
                mode="cell"
                allowUpdating
                changes={changes}
                onChangesChange={onChangesChange}
                editRowKey={editRowKey}
                onEditRowKeyChange={onEditRowKeyChange}
              />
              <Paging defaultPageSize={10} />
              <Pager
                visible={detalle.length > 0 ? true : false}
                allowedPageSizes={getAllowedPageSizes(detalle)}
                displayMode={'full'}
                showPageSizeSelector={true}
                showInfo={true}
                showNavigationButtons={true}
              />

              <Column dataField='fecha' caption="Fecha" width="100px" />
              <Column dataField='registro' caption="Registro" width="100px" />
              <Column dataField='hora' caption="Hora de Registro" width="100px" />
              <Column dataField='horaDebida' caption="Hora Debida" width="100px" />
            </DataGrid>
          </CustomCol>
        </RowContainer>
      </>
    )
  }



  const renderContent = () => {
    return (
      <CCard>
        <CCardBody>
          <LoadPanel
            shadingColor="rgba(0,0,0,0.4)"
            position={'center'}
            visible={loader.show}
            showIndicator={true}
            shading={true}
            showPane={true}
            message={loader.mensaje}
            closeOnOutsideClick={false}
          />
          {bodyModal()}
        </CCardBody>
      </CCard>
    )
  }

  return (
    <>
      <Modalform
        name={'modtrarDetalleRegistro'}
        key={'modtrarDetalleRegistro'}
        headerTitle={"Detalle de Registro"}
        childrenBody={renderContent()}
        closeOnBackdrop={false}
        show={show}
        onClose={() => onCancel()}
        centered={true}
        size='lg'
      />
    </>
  )
}
