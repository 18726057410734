import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../../../store/store'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import { StatesEdition, TabTypes } from '../../../../types/enums'
import { CustomButtons, TabsState, TabState } from '../../../../types/generics'
import { GuiaInfo } from '../../../../types/types'
import { ventasCloseTab, existeTab } from '../../../../store/reducers'
import { RemisionGuideButtons } from '../../../../components/guiasRemision/guiaRemision/guiaRemision'
import { TiposComprobantesSLV } from '../../../../../../store/types'
import { consoleService } from '../../../../../../services/console.service'

const initialState: TabsState<GuiaInfo> = {
 current: 'BUSQUEDA',
 tabs: {
  BUSQUEDA: {
   id: 0,
   canClose: false,
   editStatus: StatesEdition.doesNotApply,
   globalButtonClick: ButtonTypes.none,
   tittle: 'Búsqueda',
   type: TabTypes.busqueda,
   imprimir: false,
   tabKey: 'BUSQUEDA',
   buttons: {
    Nuevo: true,
    Buscar: true,
    Exportar: false,
   },
  },
 },
}

const tabsGuiasSlice = createSlice({
 name: 'guideTabs',
 initialState: initialState,
 reducers: {
  ventasCloseTab,
  openTab(state, action: PayloadAction<{ key: string; guia?: GuiaInfo }>) {
   const { guia, key } = action.payload
   if (guia === undefined) {
    const tab = {
     id: -1,
     canClose: true,
     editStatus: StatesEdition.new,
     globalButtonClick: ButtonTypes.none,
     tittle: 'Nueva',
     type: TabTypes.documento,
     imprimir: false,
     tabKey: action.payload.key,
     info: {
      claveAcceso: '',
      numero: 0,
      tipoComprobante: TiposComprobantesSLV.NotaRemision,
     },
     buttons: RemisionGuideButtons,
    }
    state.tabs[key] = tab
    state.current = action.payload.key
   } else {
    const tabKey = existeTab(state.tabs, guia.codigo)
    if (tabKey) {
     state.current = tabKey
    } else {
     const tab: TabState<GuiaInfo> = {
      tabKey: action.payload.key,
      id: guia.codigo,
      tittle: `${guia.establecimiento}-${guia.puntoEmision}-${guia.numero}`,
      canClose: true,
      editStatus: StatesEdition.save,
      type: TabTypes.documento,
      info: {
       claveAcceso: guia.claveAcceso,
       numero: guia.codigo,
       tipoComprobante: TiposComprobantesSLV.NotaRemision,
       info: action.payload.guia,
      },
      globalButtonClick: ButtonTypes.none,
      imprimir: false,
      buttons: RemisionGuideButtons,
     }
     state.tabs[key] = tab
     state.current = key
    }
   }
  },
  changeEditStatus(
   state,
   action: PayloadAction<{
    tabKey: string
    estado: StatesEdition
    info?: GuiaInfo
    buttons: CustomButtons
   }>,
  ) {
   const { estado, tabKey, info, buttons } = action.payload
   state.tabs[tabKey].editStatus = estado
   state.tabs[tabKey].currentExecutingAction = undefined
   state.tabs[tabKey].buttons = buttons
   if (info) {
    consoleService.log('redux info', info)
    state.tabs[tabKey].info = {
     claveAcceso: info.claveAcceso,
     numero: info.codigo,
     tipoComprobante: TiposComprobantesSLV.NotaRemision,
     info: info ?? null,
    }
    state.tabs[tabKey].id = info.codigo
    state.tabs[
     action.payload.tabKey
    ].tittle = `${info.establecimiento}-${info.puntoEmision}-${info.numero}`
   }
  },
  buttonClick(
   state,
   action: PayloadAction<{ tabKey: string; button: ButtonTypes }>,
  ) {
   state.tabs[action.payload.tabKey].globalButtonClick = action.payload.button
  },
  clearButtonClick(state, action: PayloadAction<string>) {
   if (Object.prototype.hasOwnProperty.call(state.tabs, action.payload)) {
    state.tabs[action.payload].globalButtonClick = ButtonTypes.none
   }
  },
  changeCurrentTab(state, action: PayloadAction<string>) {
   if (Object.prototype.hasOwnProperty.call(state.tabs, action.payload)) {
    state.current = action.payload
   }
  },
  setTabInfoAsInactive(state, action: PayloadAction<string>) {
   const info = state.tabs[action.payload].info
   if (info !== undefined) {
    if (info.info) {
     info.info.estadoDescripcion = 'ANULADO'
     info.info.estadoElectronico = 'ANULADO'
    }
    state.tabs[action.payload].info = info
   }
  },
  setButtons(
   state,
   action: PayloadAction<{ tabKey: string; buttons: CustomButtons }>,
  ) {
   const { buttons, tabKey } = action.payload
   state.tabs[tabKey].buttons = buttons
   state.tabs[tabKey].currentExecutingAction = undefined
  },
  setCurrentExecutingAction(
   state,
   action: PayloadAction<{
    tabKey: string
    buttonType: ButtonTypes | undefined
   }>,
  ) {
   const { buttonType, tabKey } = action.payload
   state.tabs[tabKey].currentExecutingAction = buttonType
  },
 },
})

export const selectTabsGuias = (state: RootState) => {
 return state.ventas.ventasSV.guideRemition.tabs
}
export const {
 openTab,
 changeCurrentTab,
 buttonClick,
 changeEditStatus,
 clearButtonClick,
 ventasCloseTab: closeTab,
 setButtons,
 setCurrentExecutingAction,
 setTabInfoAsInactive,
} = tabsGuiasSlice.actions
export const tabsReducer = tabsGuiasSlice.reducer
