/* eslint-disable no-unused-vars */
import { CatalogosState } from '../pages/catalogos/types/types'
import { ItemsState } from '../pages/items/types/types'
import { KardexState } from '../pages/kardex/types/types'
import { LotesState } from '../pages/lotes/types/types'
import { Detalle } from '../pages/movimientos/ingresosEgresos/types/types'
import { MovimientosState } from '../pages/movimientos/types/types'
import { MultistoreState } from '../pages/multialmacen/types/types'
import { ProjectCCState } from '../pages/proyectos/types/types'
import { SeriesState } from '../pages/series/types/types'
import { VerificadorPreciosState } from '../pages/verificarPrecios/types/types'

export enum Aplicacion {
  multialmacen = 'multialmacen',
  items = 'items',
  kardex = 'kardex',
  series = 'series',
  lotes = 'lotes',
  proyectosCC = 'proyectosCC',
  verificadorPrecios = 'verificadorPrecios',
  catalogosLineas = 'catalogos_lineas',
  catalogosMarcas = 'catalogos_marcas',
  catalogosGrupos = 'catalogos_grupos',
  catalogosUnidades = 'catalogos_unidades',
  catalogosAplicaciones = 'catalogos_aplicaciones',
  catalogosGemas = 'catalogos_gemas',
  catalogosFichaTecnica = 'catalogos_fichaTecnica',
  catalogosAtributos = 'catalogos_atributos',
  conceptosMovimientos = 'conceptos_movimientos',
  ingresosEgresos = 'ingresos_egresos',
  transferencias = 'transferencias',
  importaciones = 'importaciones',
  inventarioInicial = 'inventario_inicial',
  combinaciones = 'combinaciones',
  transformaciones = 'transformaciones',
  ingresoBloque = 'ingreso_bloque',
}

export type ModuloInventariosState = {
  global: GlobalChanges
  multialmacen: MultistoreState
  items: ItemsState
  kardex: KardexState
  series: SeriesState
  lotes: LotesState
  proyectos: ProjectCCState
  verificadorPrecios: VerificadorPreciosState
  catalogos: CatalogosState
  movimientos: MovimientosState
}

export type GlobalChanges = {
  incomesExpenses: ChangesIncomesExpenses | []
}

export type ChangesIncomesExpenses = Pick<
  Detalle,
  'codigo' | 'barras' | 'cantidad'
>[]

export type EdicionPayload<T> = {
  key: number | string
  data: T
}

export type LineaInventario = {
  codigo: number //"LIN_CODIGO": "605",
  particular?: string // "LIN_PARTICULAR": "AGENYUS",
  descripcion: string //"LIN_DESCRIPCION": "41 PASAJEROS",
  estado?: boolean // "LIN_ESTADO": "1",
  estadoNombre?: string //"LIN_ESTADO_DES": "ACTIVO",
  imagen?: string //"LIN_IMAGEN": "vacio.jpg",
  conCodigo?: number
  conConcepto?: null
  susCodigo?: string
  utilidad?: number //"LIN_UTILIDAD": "8.00",
}

export type GrupoInventario = {
  codigo: number
  descripcion: string
  perteneceCodigo?: number
  perteneceNombre?: string
  estadoCodigo?: number
  estadoNombre?: string
  categoriaWeb?: number
  items?: number
}

export type MarcaInventario = {
  codigo: number //"MAR_CODIGO": "299",
  nombre: string
  estado?: number //"MAR_ESTADO": "1",
  estadoNombre?: string //"MAR_ESTADO_DES": "ACTIVO",
  marcaWeb?: number //"MAR_ESTADO_DES": "ACTIVO",
  lineaCodigo?: number
  lineaDescripcion?: string
  grupoCodigo?: number
  grupoDescripcion?: string
}

export type UnidadInventario = {
  codigo: number
  nombre: string
  siglas: string
  grupoCodigo: number
  grupoNombre: string
  estadoCodigo: number
  estadoNombre: string
  fraccion: number
}

export type IceInventario = {
  codigo: number
  nombre: string
  descripcion?: string
  valor?: number
  vigencia?: string
  hasta?: string
  estado?: number
  estadoDescripcion?: string
  tipo?: string
  tipoValor?: string
  adValorEm?: number
  exFabrica?: number
  exAduana?: number
  exhibir?: number
}

export type ItemInventario = {
  itemCodigo: number
  itemCodigoUsuario: string
  itemBarras: string
  itemDescripcion: string
  itemTipo: number
  itemTipoDescripcion: string
  itemLineaCodigo: number
  itemLineaDescripcion: string
  itemMarcaCodigo: number
  itemMarcaDescripcion: string
  itemGrupoCodigo: number
  itemGrupoDescripcion: string
  itemUnidadCompraCodigo: number
  itemUnidadCompraDescripcion: string
  itemUnidadVentaCodigo: number
  itemUnidadVentaDescripcion: string
  itemImpuestoCodigo: number
  itemImpuestoDescripcion: string
  itemImpuestoValor: number
  itemIce: number
  itemImagen: string
  itemLoteDescripcion: string
  itemNumeroSerieDescripcion: string
  itemProcedenciaDescripcion: string
  itemPVO: number
  itemCosteo: number
  itemCosto: number
  itemCostoIVA: number
  itemAplicaciones: string
  itemPeso: number
  itemLargo: number
  itemAncho: number
  itemAlto: number
  itemNota: string
  itemTags: string
  itemGananciaPVD: number
  itemGananciaPVP: number
  localCodigo: number
  loteNumero?: any
  loteCaducidad: string
  itemLocalizacion?: any
  itemProveedores?: any
  itemPadre?: any
  itemControlador?: any
  itemJerarquia?: any
  itemLotes?: any
  itemStockMax?: any
  itemSemana: string
  itemUltimoProveedor?: any
  impresoraCodigoTecnologia?: any
  itemModeloBom?: any
  impresoraTipo?: any
  proveedorNombreFabricante?: any
  impresoraCodigoTecnologiaFabricante?: any
  itemDesperdicio: number
  itemDescuentos: string
  itemNivelMSL: string
  costoTotal: number
  itemLimites: number
  itemProveedor?: any
  itemReservas: string
  itemExistencia: number
  itemPVD: number
  itemPVDIVA: number
  itemPVP: number
  itemPVPIVA: number
  iceCodigoGradoAlcoholico: string
  iceGradoAlcoholico: string
  iceCapacidaCm3: string
  iceCodigoImpuesto: string
  iceCodigoClasificacion: string
  iceCodigoMarca: string
  iceEstado: string
  iceCodigoPresentacion: string
  iceCodigoUnidad: string
  icePaisOrigen: string
  iceGramosAzucar: string
}
