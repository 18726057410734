import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CustomDictionary, LoaderInfo } from '../../../../../store/types'
import { EdicionPayload } from '../../../store/types'
import { DescuentosDatosEdicion, EstudioDatosEdicion, ExtrasDatosEdicion, LoginDatosEdicion, NominaDatosEdicion, PersonalDatosEdicion, PersonalesDatosEdicion, RentasDatosEdicion, RentasList, RolesPagoDatosEdicion, SeleccionadoDescuento } from '../types/types'

const initialState: CustomDictionary<PersonalDatosEdicion> = {}

const datosEditSlice = createSlice({
  name: 'personalEdicion',
  initialState: initialState,
  reducers: {
    deleteEditData(state, action: PayloadAction<string>) {
      delete state[action.payload]
    },
    setDatosEdicion(
      state,
      action: PayloadAction<EdicionPayload<PersonalDatosEdicion>>,
    ) {
      state[action.payload.key] = action.payload.data
    },
    setDatosEdicionPersonales(
      state,
      action: PayloadAction<EdicionPayload<PersonalesDatosEdicion>>,
    ) {
      state[action.payload.key].personales = action.payload.data
    },
    setDatosEdicionEstudios(
      state,
      action: PayloadAction<EdicionPayload<EstudioDatosEdicion>>,
    ) {
      state[action.payload.key].estudios = action.payload.data
    },
    setDatosEdicionExtras(
      state,
      action: PayloadAction<EdicionPayload<ExtrasDatosEdicion>>,
    ) {
      state[action.payload.key].extras = action.payload.data
    },
    setDatosEdicionLogin(
      state,
      action: PayloadAction<EdicionPayload<LoginDatosEdicion>>,
    ) {
      state[action.payload.key].login = action.payload.data
    },
    setDatosEdicionRentas(
      state,
      action: PayloadAction<EdicionPayload<RentasDatosEdicion>>,
    ) {
      state[action.payload.key].rentas = action.payload.data
    },
    setSeleccionadoRenta(
      state,
      action: PayloadAction<EdicionPayload<RentasList>>,
    ) {
      state[action.payload.key].rentas.seleccionadoRenta = action.payload.data
    },
    setResetRenta(
      state,
      action: PayloadAction<EdicionPayload<null>>,
    ) {
      state[action.payload.key].rentas.seleccionadoRenta = action.payload.data
    },
    setDatosEdicionNomina(
      state,
      action: PayloadAction<EdicionPayload<NominaDatosEdicion>>,
    ) {
      state[action.payload.key].nomina = action.payload.data
    },
    setDatosEdicionNominaRolesPagos(
      state,
      action: PayloadAction<EdicionPayload<RolesPagoDatosEdicion>>,
    ) {
      state[action.payload.key].nomina.rolesPago = action.payload.data
    },
    setDatosEdicionNominaDescuento(
      state,
      action: PayloadAction<EdicionPayload<DescuentosDatosEdicion>>,
    ) {
      state[action.payload.key].nomina.descuentos = action.payload.data
    },
    setChangeLoaderEdit(state, action: PayloadAction<EdicionPayload<LoaderInfo>>) {
      state[action.payload.key].loader = action.payload.data
    },
    initDatosEdicion(
      state,
      action: PayloadAction<EdicionPayload<PersonalDatosEdicion>>,
    ) {
      if (!state[action.payload.key]) {
        state[action.payload.key] = action.payload.data
      }
    },
    setSeleccionadoDescuento(
      state,
      action: PayloadAction<EdicionPayload<SeleccionadoDescuento>>,
    ) {
      state[action.payload.key].nomina.descuentos.seleccionadoDescuento = action.payload.data
    },
  },
})

export const {
  setDatosEdicion,
  initDatosEdicion,
  deleteEditData,
  setDatosEdicionPersonales,
  setDatosEdicionEstudios,
  setDatosEdicionExtras,
  setDatosEdicionLogin,
  setDatosEdicionRentas,
  setDatosEdicionNomina,
  setDatosEdicionNominaRolesPagos,
  setDatosEdicionNominaDescuento,
  setChangeLoaderEdit,
  setSeleccionadoRenta,
  setResetRenta,
  setSeleccionadoDescuento,
} = datosEditSlice.actions
export const dataEditReducer = datosEditSlice.reducer
