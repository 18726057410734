import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import DropDownButton from 'devextreme-react/drop-down-button';
import Button from 'devextreme-react/button';
import { v4 as uuidv4 } from 'uuid'
import './sheetCommandViewer.scss'
import { consoleService } from '../../../../services/console.service';

export default function CustomSheetCommandViewer(props) {
  const { cell, row } = props

  if (!cell.commands) {
    return <div style={{ width: '100%', height: '100%' }} className='' ></div>
  }
  const element = document.querySelector("#modal");
  if (element === null) {
    console.error('No se encuentra #modal, debe declarar este elemento al mismo nivel que #root para que el menú se muestre por encima de los demás elementos')
  }
  if (cell.commands.length === 1) {
    const cmd = cell.commands[0];
    return (
      <div>
        <Button
          onClick={() => {
            cmd.onExecute(row)
          }}
          variant='ghost'
          color={cmd.color}
        >
          <FontAwesomeIcon size='1x' icon={cmd.icon} />
        </Button>
      </div>
    )
  }

  const onItemClick = (e) => {
    consoleService.log('onItemClick : ', e)
    if (e?.itemData?.id !== undefined)
      cell.commands.map((cmd: any) => {
        if (cmd.id === e.itemData.id)
          cmd.onExecute(row)
      })
  }

  const onButtonClick = (e) => {
    consoleService.log('onButtonClick : ', e)
  }

  return (
    <div className='' style={{ width: '100%', height: '100%' }}>
      {cell?.commands.length > 0 &&
        <DropDownButton
          splitButton={false}
          useSelectMode={false}
          text=""
          icon="plus"
          dropDownOptions={buttonDropDownOptions}
          // items={profileSettings}
          items={cell?.commands.length > 0 ? cell.commands : []}
          // onItemClick={onItemClick}
          id={"commandsDetall" + uuidv4()}
          displayExpr="text"
          className='ms-1 me-1'
          onButtonClick={onButtonClick}
          onItemClick={onItemClick}
        />
      }
    </div>
  )
}
const buttonDropDownOptions = {
  width: 100,
  color: '#39f',
  backgroudColor: 'white',
  fontSize: 14
};
const profileSettings = [
  { id: 'Eliminar', text: 'Eliminar', icon: 'trash' },
  {
    id: 4, text: 'Messages', icon: 'email', badge: '5',
  },
  { id: 2, text: 'Friends', icon: 'group' },
  { id: 3, text: 'Exit', icon: 'runner' },
];