import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoaderInfo, OptionGrupos, Seleccionado } from "../../../../../store/types";
import { BancoState, Banco, formularioBusqueda } from "./types";

const initialState: BancoState = {
  modulo: 'bancos',
  currentAction: "Buscar",
  currentFunction: "",
  registros: [],
  seleccionado: {
    index: null,
    row: null,
    selectedRow: null,
  },
  tipos: [],
  estados: [],
  loader: {
    show: false,
    mensaje: ''
  },
  formularioBusqueda: {
    banco: null,
  },
  currentExecute: null,
}

const bancosSlice = createSlice({
  name: "bancos",
  initialState: initialState,
  reducers: {
    setCurrentAccion(state, action: PayloadAction<string>) {
      state.currentAction = action.payload
    },
    setCurrentFunction(state, action: PayloadAction<string>) {
      state.currentFunction = action.payload
    },
    setData(state, action: PayloadAction<Array<Banco>>) {
      state.registros = action.payload
    },
    setSeleccionarDato(state, action: PayloadAction<Seleccionado>) {
      const toAdd = {
        index: action.payload.index,
        row: action.payload.row,
        selectedRow: action.payload.selectedRow,
      }
      state.seleccionado = toAdd
    },
    setResetSeleccion(state) {
      const toAdd = {
        index: null,
        row: null,
        selectedRow: null,
      }
      state.seleccionado = toAdd
    },
    setTiposOptions(state, action: PayloadAction<Array<OptionGrupos>>) {
      state.tipos = action.payload
    },
    setOptionsEstados(state, action: PayloadAction<Array<OptionGrupos>>) {
      state.estados = action.payload
    },
    setChangeLoaderBancos(state, action: PayloadAction<LoaderInfo>) {
      state.loader = action.payload
    },
    setFormularioBusqueda(state, action: PayloadAction<formularioBusqueda>) {
      const toAdd = {
        banco: action.payload.banco,
      }
      state.formularioBusqueda = toAdd
    },
    setCurrentExecute(state, action: PayloadAction<any>) {
      state.currentExecute = action.payload
    },
  }
})
export const {
  setOptionsEstados,
  setData,
  setCurrentAccion,
  setSeleccionarDato,
  setResetSeleccion,
  setTiposOptions,
  setCurrentFunction,
  setChangeLoaderBancos,
  setFormularioBusqueda,
  setCurrentExecute
} = bancosSlice.actions;
export const BancosReducer = bancosSlice.reducer;

