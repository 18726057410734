import { DateUtils, formatoFechasApi } from '../../../../helpers/dateUtils'
import { RequestHelper } from '../../../../helpers/requestHelper'
import { utilidades } from '../../../../helpers/utilidades'
import { SesionService } from '../../../../services/sesion.service'
import { ESubtipo } from '../../../../store/enum/subtypes'
import { Subtipo } from '../types/types'

export const unitGroupsServices = { getUnitsGroups }
async function getUnitsGroups(): Promise<any> {
  try {
    const sesion = SesionService.getCurrentSesion()
    const data: any = {
      mensaje: 'Selecciona el grupo',
      codigoTic: ESubtipo.Grupo,
    }
    const keysToCache: any = {
      ...data,
      dateKey: DateUtils.getCurrentDateAsString(formatoFechasApi),
      empresa: sesion?.empresa?.codigo,
    }
    const cacheKey = 'tipoGruposUnidades' + JSON.stringify(keysToCache)
    const cached = sessionStorage.getItem(cacheKey)
    if (cached) {
      return { error: false, auto: JSON.parse(cached) }
    } else {
      const unitGruopsData = await RequestHelper.getAll<any>(
        'subtypes',
        'cboxLoad',
        '',
        data,
      )
      const groupsUnits: Array<Subtipo> = []
      if (unitGruopsData?.auto && unitGruopsData?.auto.length > 0) {
        for (const unitGroup of unitGruopsData.auto) {
          groupsUnits.push({
            codigo: unitGroup?.codigo ?? -1,
            valor: utilidades.capText(unitGroup?.valor) ?? '',
            tipoCodigo: unitGroup?.tipoCodigo ?? '',
            grupo: unitGroup?.grupo ?? '',
            orden: unitGroup?.orden ?? -1,
            estado: unitGroup?.estado ?? '',
          })
        }
        sessionStorage.setItem(cacheKey, JSON.stringify(groupsUnits))
        return { error: false, auto: groupsUnits }
      } else
        return {
          error: true,
          message: unitGruopsData.message
            ? unitGruopsData.message
            : 'Error al obtrner los datos',
        }
    }
  } catch (error) {
    return error
  }
}
