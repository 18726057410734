import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Calculate, CalculateList, Todos } from "../types/types";
import { LoaderInfo } from "../../../../../../store/types";

const initialState: Todos = {
  calculate: {
    periodo: 0,
    fechaEmision: '',
    initial: true,
  },
  loader: {
    show: false,
    mensaje: ''
  },
  nroRegistrosEncontrados: 0,
  resultados: []
}

const todoSlice = createSlice({
  name: 'todoTransfer',
  initialState: initialState,
  reducers: {
    setResetResults(state) {
      state.resultados = []
    },
    setResults(state, action: PayloadAction<CalculateList[]>) {
      state.resultados = action.payload
    },
    changeData(state, action: PayloadAction<Calculate>) {
      state.calculate.periodo = action.payload.periodo
      state.calculate.fechaEmision = action.payload.fechaEmision
      state.calculate.initial = action.payload.initial
    },
    setChangeLoader(state, action: PayloadAction<LoaderInfo>) {
      state.loader = action.payload
    },
  }
})

export const {
  setResetResults,
  setResults,
  changeData,
  setChangeLoader,
} = todoSlice.actions
export const todoReducer = todoSlice.reducer