import React, { useCallback, useEffect, useRef } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { OrganicSave, OrganicoDatosEdicion } from '../../types/types'
import { DocumentInfo, ToastTypes } from '../../../../../../store/types'
import { TabState } from '../../../../../../store/genericTabTypes'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput'
import { TextBox, ValidationGroup, ValidationSummary, Validator } from 'devextreme-react'
import { consoleService } from '../../../../../../services/console.service'
import PerteneceLookUp from '../../../../../componentes/perteneceLookUp'
import TipoEstadoLookUp from '../../../../../componentes/tipoEstado/'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import { useDispatch, useSelector } from 'react-redux'
import { clearButtonClick, openTabNomina, setButtons, setCurrentExecutingAction, setNameTab } from '../../store/tabsReducer'
import { StatesEdition, TabTypes } from '../../../../../../store/enums'
import { RootState } from '../../../../../../store/store'
import { addToast } from '../../../../../../store/toasterReducer'
import { setChangeLoader, setDatosEdicion } from '../../store/editDataReducer'
import { setDatosEdicionBackup } from '../../store/configReducer'
import { belongsService } from '../../../../../componentes/perteneceLookUp/services/pertenece.services'
import { tipoEstadoService } from '../../../../../componentes/tipoEstado/service/tipoEstado.service'
import BlockUi from '../../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../../views/componentes/loadingindicator/loadingindicator'
import { isMobile } from 'react-device-detect'
import { AsyncRule, RequiredRule } from 'devextreme-react/data-grid'
import validations from '../../../../../../config/validations'
import VisualizaError from '../../../../../ventas/pages/shared/visualizaError/visualizaError'
import ListErrosValidationForm from '../../../../../componentes/listadoErroresValidacionForm'
import { MessagesKeys, lh } from '../../../../../../helpers/localizationHelper'
import { OrganicServices } from '../../services/organico.services'
import { ModuleButtons } from '../../index'
import { getIndexProvider } from '../../../../../shared/helpers/funciones'
import { initDatosEdicion } from '../../store/nominaReducer'
import { defaultNominaData } from '../nomina'

interface INuevoProps extends React.PropsWithChildren {
  info: DocumentInfo<OrganicoDatosEdicion>
  tab: TabState<OrganicoDatosEdicion>
  tabId: string
}

const Nuevo: React.FunctionComponent<INuevoProps> = (props) => {
  const { tab, tabId, info } = props


  const dispatch = useDispatch()
  const validationOrganic = useRef<any>()

  const loading = useSelector((state: RootState) => state.nomina.organico.editData[tabId].loading)
  const loader = useSelector((state: RootState) => state.nomina.organico.editData[tabId].loader)
  const organicState = useSelector((state: RootState) => state.nomina.organico.editData[tabId])
  const tabs = useSelector((state: RootState) => state.nomina.organico.tabs)
  const sms = validations['es']
  const estadoMessage: string = 'Estado: Elija el estado del registro.'


  const [arrayErrors, setArrayErrors] = React.useState<Array<string>>([])
  const [showErrorPopup, setShowErrorPopup] = React.useState<boolean>(false)

  const onSetButtonAction = useCallback(
    (tipo: ButtonTypes | undefined) => {
      dispatch(
        setCurrentExecutingAction({
          tabKey: tabId,
          buttonType: tipo,
        }),
      )
    },
    [dispatch, tabId],
  )

  const setToast = useCallback(
    (mensaje: string, tipo: ToastTypes) => {
      dispatch(
        addToast({
          title: 'Nómina - Horarios',
          content: mensaje,
          type: tipo,
        }),
      )
    },
    [dispatch],
  )

  const onLoader = useCallback((showLoader: boolean, mensaje: string) => {
    dispatch(setChangeLoader({
      key: tabId,
      data: {
        show: showLoader,
        mensaje: mensaje
      }
    }))
  }, [dispatch, tabId])

  const modoNuevo = React.useCallback(async (template: number, limpiar: boolean = false) => {
    if (loading === false && !limpiar) {
      return
    }

    onSetButtonAction(ButtonTypes.new)
    onLoader(true, 'Cargando...')
    try {
      const data = { ...defaultOrganicEdition }

      const dataPertenece = await belongsService.getUpperOptions("Inicio")
      const dataEstado = await tipoEstadoService.getTipoEstado('Elija un estado')

      if (dataEstado?.auto && dataEstado?.error === false) {
        data.estado = dataEstado?.auto[1]
      }
      if (dataPertenece?.auto && dataPertenece?.error === false) {
        data.padre = dataPertenece?.auto[0]
      }
      data.descripcion = ''
      data.siglas = ''
      data.loading = false
      dispatch(setDatosEdicion({
        key: tabId,
        data: {
          ...data
        }
      }))
      dispatch(setDatosEdicionBackup({
        key: tabId,
        data: {
          ...data
        }
      }))
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
    onLoader(false, '')
    onSetButtonAction(undefined)

  }, [loading, onLoader, onSetButtonAction, setToast, dispatch, tabId])

  const cargarOrganico = React.useCallback(async (loader: boolean, organicoData) => {
    if (loader === false) {
      return
    }
    dispatch(
      setButtons({
        tabKey: tabId,
        buttons: {
          ...ModuleButtons,
          Asignar: true,
        }
      }),
    )
    onSetButtonAction(ButtonTypes.edit)
    onLoader(true, 'Cargando Registro...')
    try {
      const dataPertenece = await belongsService.getUpperOptions("Inicio")
      const dataEstado = await tipoEstadoService.getTipoEstado('Elija un estado')
      consoleService.log(organicoData, 'edit data')
      const dataEdit = { ...organicState }
      dataEdit.codigo = organicoData?.organico
      if (dataEstado?.auto && dataEstado?.error === false) {
        const provider = dataEstado?.auto?.slice(0)
        const index = await getIndexProvider(provider, 'codigo', organicoData?.estado ?? 0)
        if (index > -1) {
          dataEdit.estado = provider[index]
        } else {
          dataEdit.estado = provider[0]
        }
      }

      if (dataPertenece?.auto && dataPertenece?.error === false) {
        const provider = dataPertenece?.auto.slice(0)
        const index = await getIndexProvider(provider, 'codigo', organicoData?.padre ?? 0)
        if (index > -1) {
          dataEdit.padre = provider[index]
        } else {
          dataEdit.padre = provider[0]
        }
      }

      dataEdit.descripcion = organicoData?.descripcion
      dataEdit.siglas = organicoData?.siglas
      dataEdit.loading = false
      dispatch(setDatosEdicion({
        key: tabId,
        data: {
          ...dataEdit
        }
      }))
      dispatch(setDatosEdicionBackup({
        key: tabId,
        data: {
          ...dataEdit
        }
      }))
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
    onLoader(false, '')
    onSetButtonAction(undefined)
  }, [onLoader, onSetButtonAction, dispatch, tabId, organicState, setToast])


  const onValueChange = useCallback((data, key: string) => {
    dispatch(setDatosEdicion({
      key: tabId,
      data: {
        ...organicState,
        [key]: data
      }
    }))
  }, [dispatch, organicState, tabId])

  const onValidate = useCallback(() => {
    const errors: Array<string> = []
    if (organicState?.descripcion === "") {
      errors.push('Descripcion: La descripcion es requerida.')
    }
    if (organicState?.estado === null || organicState?.estado === undefined || organicState?.estado?.codigo <= 0) {
      errors.push('Estado: Elija el estado del registro.')
    }
    return errors
  }, [organicState])

  const onSave = useCallback(async () => {
    let errors: Array<string> = []
    const result = validationOrganic.current.instance.validate()
    errors = onValidate()
    consoleService.log(errors)
    if (result.isValid && errors.length === 0) {
      onSetButtonAction(ButtonTypes.save)
      onLoader(true, 'Guardando...')
      try {
        const data_save: OrganicSave = {
          infoRegistro: {
            codigo: organicState?.codigo ?? 0,
            padre: organicState?.padre?.codigo ?? 0,
            descripcion: organicState?.descripcion ?? '',
            siglas: organicState?.siglas ?? '',
            estado: organicState?.estado?.codigo ?? 0,
          }
        }
        const data = await OrganicServices.saveOrganic(data_save)
        consoleService.log(data, 'data save')
        if (data?.auto && data?.error === false) {
          setToast(data?.message, ToastTypes.Success)
          dispatch(setNameTab({
            key: tabId,
            codigo: data?.auto,
            nombre: organicState?.descripcion
          }))
          dispatch(
            setButtons({
              tabKey: tabId,
              buttons: {
                ...ModuleButtons,
                Guardar: false,
              }
            }),
          )
        } else {
          setToast(data?.message, ToastTypes.Danger)
        }
      } catch (error) {
        setToast(error.message, ToastTypes.Danger)
      }
      onLoader(false, '')
      onSetButtonAction(undefined)
    } else {
      setToast(lh.getMessage(MessagesKeys.GlobalErrorSave), ToastTypes.Warning)
      setArrayErrors(errors)
      setShowErrorPopup(true)
    }
  }, [onValidate, setToast, organicState, onLoader, onSetButtonAction, dispatch, tabId])

  const openNomina = useCallback(() => {
    const id = uuidv4()
    dispatch(
      initDatosEdicion({
        key: id,
        data: {
          ...defaultNominaData,
          codigoOrganico: organicState?.codigo,
          loading: true
        },
      }),
    )
    dispatch(openTabNomina({ key: id, type: TabTypes.nomina }))
  }, [organicState, dispatch])

  const handleButtonClick = useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.save:
          if (tabs.current === tabId) onSave()
          break
        case ButtonTypes.assign:
          if (tabs.current === tabId) openNomina()
          break
        case ButtonTypes.undo:
          break
        case ButtonTypes.broom:
          if (tabs.current === tabId) modoNuevo(0, true)
          break
        default:
          break
      }
      dispatch(clearButtonClick(tabId))
    }, [dispatch, tabId, onSave, tabs, openNomina, modoNuevo])


  useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab.globalButtonClick])

  useEffect(() => {
    if (tab.editStatus === StatesEdition.new) {
      modoNuevo(tab?.info?.codigo)
    } else {
      cargarOrganico(loading, info.info)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const validateAsyncSelect = React.useCallback((datoSeleccion: any, message: string) => {
    if (datoSeleccion.value.codigo > -1) {
      return Promise.resolve()
    } else {
      return Promise.reject(message)
    }
  }, [])

  const validateAsyncSeleccionEstado = React.useCallback((datoSeleccion) => {
    return validateAsyncSelect(datoSeleccion, estadoMessage)
  }, [validateAsyncSelect])

  return (
    <>
      {showErrorPopup && (
        <VisualizaError
          titulo="Error en Organigrama"
          mensaje={'Error al guardar'}
          content={
            <ListErrosValidationForm
              mainMessage={'Revise los siguientes errores:'}
              errorsList={arrayErrors}
              color={'danger'}
            />
          }
          onOk={() => setShowErrorPopup(false)}
        />
      )}
      <div style={{ overflowX: 'hidden' }}>
        <BlockUi
          tag="div"
          loader={LoadingIndicator}
          blocking={loader.show}
          message={loader.mensaje}
        >
          <RowContainer>
            <ValidationGroup id={'formOrganic'} ref={validationOrganic}>
              {
                !isMobile && (
                  <RowContainer>
                    <CustomCol lg="6" md="8" sm="12">
                      <ValidationSummary id="summary"></ValidationSummary>
                    </CustomCol>
                  </RowContainer>
                )
              }
              <RowContainer>
                <CustomCol xs="12" md="4">
                  <Labeled label='Descripción:' >
                    <TextBox
                      value={organicState?.descripcion ?? ''}
                      showClearButton
                      onValueChange={(data) => onValueChange(data, 'descripcion')}
                    >
                      <Validator>
                        <RequiredRule message={'Descripción: ' + sms['required']} />
                      </Validator>
                    </TextBox>
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="4">
                  <Labeled label='Pertenece a:' >
                    <PerteneceLookUp
                      onChanged={(data) => onValueChange(data, 'padre')}
                      selected={organicState?.padre ?? null}
                      provider={[]}
                    />
                  </Labeled>
                </CustomCol>
              </RowContainer>
              <RowContainer>
                <CustomCol xs="12" md="4">
                  <Labeled label='Siglas:' >
                    <TextBox
                      value={organicState?.siglas ?? ''}
                      showClearButton
                      onValueChange={(data) => onValueChange(data, 'siglas')}
                    />
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="4">
                  <Labeled label="Estado:">
                    <TipoEstadoLookUp
                      onChanged={(e) => onValueChange(e, 'estado')}
                      selected={organicState?.estado ?? null}
                      provider={[]}
                    >
                      <Validator>
                        <RequiredRule message={estadoMessage} />
                        <AsyncRule
                          message={estadoMessage}
                          validationCallback={validateAsyncSeleccionEstado}
                        />
                      </Validator>
                    </TipoEstadoLookUp>

                  </Labeled>
                </CustomCol>
              </RowContainer>
              {
                isMobile && (
                  <RowContainer>
                    <CustomCol lg="6" md="8" sm="12">
                      <ValidationSummary id="summary"></ValidationSummary>
                    </CustomCol>
                  </RowContainer>
                )
              }
            </ValidationGroup>
          </RowContainer>
        </BlockUi>
      </div>
    </>
  )
}

export default React.memo(Nuevo)

export const defaultOrganicEdition: OrganicoDatosEdicion = {
  codigo: 0,
  descripcion: '',
  siglas: '',
  padre: null,
  estado: null,
  loading: false,
  loader: {
    show: false,
    mensaje: ''
  },
}