import React from 'react'
import { CBadge } from '@coreui/react-pro'

interface ItemsCountIndicatorProps extends React.PropsWithChildren {
  items?: Array<any>
  numberOfItems?: number
}

const ItemsCountIndicator: React.FunctionComponent<ItemsCountIndicatorProps> = (
  props,
) => {
  const { numberOfItems, items } = props
  return (
    <div
      className="mt-1 mb-1"
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
      }}
    >
      {(items?.length > 0 || numberOfItems > 0) && (
        <CBadge color="info">{`${
          items ? items.length : numberOfItems
        } REGISTROS ENCONTRADOS`}</CBadge>
      )}
    </div>
  )
}

export default React.memo(ItemsCountIndicator)
