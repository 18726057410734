import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EdicionPayload } from '../../../../store/types'
import { FichaTecnicaDatosEdicion } from '../types/types'
import { CustomDictionary } from '../../../../../../store/types'

const initialState: CustomDictionary<FichaTecnicaDatosEdicion> = {}

const datosEditSlice = createSlice({
  name: 'fichaTecnicaDatosEdicion',
  initialState: initialState,
  reducers: {
    deleteEditData(state, action: PayloadAction<string>) {
      delete state[action.payload]
    },
    setDatosEdicion(
      state,
      action: PayloadAction<{
        key: string
        ficha: FichaTecnicaDatosEdicion
      }>,
    ) {
      const { key, ficha } = action.payload
      state[key].codigo = ficha.codigo
      state[key].fecha = ficha.fecha
      state[key].itemReferencia = ficha.itemReferencia
      state[key].serie = ficha.serie
      state[key].nroBase = ficha.nroBase
      state[key].horma = ficha.horma
      state[key].grupo = ficha.grupo
      state[key].propiedades = ficha.propiedades
      state[key].kitPartes = ficha.kitPartes
      state[key].materiales = ficha.materiales
      state[key].color = ficha.color
      state[key].descripcionKit = ficha.descripcionKit
      state[key].descripcionMaterial = ficha.descripcionMaterial
      state[key].codigoUsuario = ficha.codigoUsuario
      state[key].cantidad = ficha.cantidad
      state[key].unidad = ficha.unidad
      state[key].desperdicio = ficha.desperdicio
      state[key].costo = ficha.costo
      state[key].datagrid = ficha.datagrid
      state[key].guardado = ficha.guardado
    },
    initDatosEdicion(
      state,
      action: PayloadAction<EdicionPayload<FichaTecnicaDatosEdicion>>,
    ) {
      if (!state[action.payload.key]) {
        state[action.payload.key] = action.payload.data
      }
    },
  },
})

export const { setDatosEdicion, initDatosEdicion, deleteEditData } =
  datosEditSlice.actions
export const dataEditReducer = datosEditSlice.reducer
