import { PagedResult, PaginationInfo } from "../../../../../../store/types"
import { AddHour, FiltroBusquedaHorarios } from "../types/types"
import { WorkingHour } from '../../../../../componentes/modalHorariosTrabajo/types/type';
import { RequestHelper } from "../../../../../../helpers/requestHelper";
import { useDispatch } from "react-redux";
import { useCallback } from "react";
import CustomStore from "devextreme/data/custom_store";
import { setNroRegistrosEncontradas, setSearch } from "../store/searchReducer";
import DataSource from "devextreme/data/data_source";


export const HoursServices = { useSetDataSources, deleteHour, saveHour, addHour, removetHour, getHours }

async function getHours(
  filter: FiltroBusquedaHorarios,
): Promise<any> {

  try {
    const queryData = {
      estado: filter?.estado?.value ?? 0,
      descripcion: filter?.descripcion ?? '',
    }

    const apiData = await RequestHelper.getAll<any>(
      'nomina/general',
      'workTime/getHourly',
      '',
      queryData,
    )
    return apiData
  } catch (error) {
    return error
  }
}

async function getHoursPaged(
  filter: FiltroBusquedaHorarios,
  pagination: PaginationInfo,
): Promise<PagedResult<WorkingHour>> {

  const queryData = {
    estado: filter?.estado?.value ?? 0,
    descripcion: filter?.descripcion ?? '',
    isPaged: true,
    page: pagination.current,
    limit: pagination.size,
  }

  const apiDataPaged = await RequestHelper.get<any>(
    'nomina/general',
    'workTime/getHourly',
    '',
    queryData,
  )
  const apiData = apiDataPaged.auto as Array<WorkingHour>
  const contrats = apiData
  const pagedResult: PagedResult<WorkingHour> = {
    currentPage: parseInt(apiDataPaged.paginaActual),
    from: parseInt(apiDataPaged.desde),
    to: parseInt(apiDataPaged.hasta),
    count: parseInt(apiDataPaged.totalRegistros),
    result: contrats,
  }
  return pagedResult
}



function useSetDataSources() {
  const dispatch = useDispatch()
  const getHoursDatasource = useCallback(
    (find: FiltroBusquedaHorarios) => {
      const store = new CustomStore<void, WorkingHour>({
        useDefaultSearch: true,
        key: 'codigo',
        byKey: async (key) => {
          console.log(key)
        },
        load: async (loadOptions) => {
          const paginInfo: PaginationInfo = {
            current: (loadOptions.skip ?? 0) / (loadOptions.take ?? 0) + 1,
            size: loadOptions.take ?? 10,
          }

          let pagedResult: PagedResult<WorkingHour> = {
            currentPage: 0,
            from: 0,
            to: 0,
            count: 0,
            result: [],
          }

          pagedResult = await getHoursPaged(find, paginInfo)
          if (pagedResult.result.length === 0) {
            dispatch(setSearch(false))
          }
          return {
            data: pagedResult.result,
            totalCount: pagedResult.count,
          }
        },
      })
      const dataSource = new DataSource({
        totalCount: store.totalCount,
        paginate: true,
        pageSize: 10,
        store: store,
        key: 'codigo',
        requireTotalCount: true,
        onChanged: () => {
          dispatch(setNroRegistrosEncontradas(dataSource.totalCount()))
        },
      })
      return dataSource
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return { getHoursDatasource }
}


async function deleteHour(codigo: number): Promise<any> {
  try {
    const obj = {
      codigo
    }

    const apiData = await RequestHelper.deleteRequestAll<any>('nomina/registro/horarios', 'work/inactive', '', obj)
    return apiData

  } catch (error) {
    return error
  }
}

async function saveHour(codigo: number, descripcion: string, estado: number): Promise<any> {
  try {
    const obj = {
      infoRegistro: {
        codigo,
        descripcion,
        estado
      }
    }

    const apiData = await RequestHelper.postAll<any>('nomina/registro/horarios', 'work/save', obj)
    return apiData

  } catch (error) {
    return error
  }
}

async function addHour(data: AddHour): Promise<any> {
  try {
    const obj = {
      ...data
    }

    const apiData = await RequestHelper.postAll<any>('nomina/registro/horarios', 'addHourly', obj)
    return apiData

  } catch (error) {
    return error
  }
}

async function removetHour(codigo: number): Promise<any> {
  try {
    const obj = {
      codigo
    }

    const apiData = await RequestHelper.deleteRequestAll<any>('nomina/registro/horarios', 'removeHourly', '', obj)
    return apiData

  } catch (error) {
    return error
  }
}