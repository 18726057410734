import React from 'react'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import {
  Button,
  CheckBox,
  DataGrid,
  RadioGroup,
  TextBox,
} from 'devextreme-react'
import { Column, ColumnChooser, Export, Pager, Paging } from 'devextreme-react/data-grid'
import CustomCol from '../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput'
import LocalesLookUp from '../../../../../componentes/localesLookUp'
import ItemsCountIndicator from '../../../../../componentes/indicadorNroRegistros'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/store'
import BuscarItemPedidoMemoLookUp from '../../../../../ventas/pages/ordenes/pages/ordenPedido/components/busquedaPedidos/BusquedaItemsSelectBox/BuscarItemPedidoMemoLookUp'
import { changeFilter, setCollapsedAvanzed } from '../../store/serachReducer'
import { DateUtils, formatoFechasApi } from '../../../../../../helpers/dateUtils'
import { consoleService } from '../../../../../../services/console.service'
import { EModulosAcatha } from '../../../../../../store/enumsAplication'
import { Workbook } from 'exceljs'
import { exportDataGrid } from 'devextreme/excel_exporter';
import { saveAs } from 'file-saver';
import DocumentoVentaAfectar from '../../../../../ventas/components/busquedaDocumentoVentaAfectar/documentoVentaAfectar'
import { TiposComprobantesSri } from '../../../../../../store/types'
import { CCardBody, CCardFooter, CCardHeader, CCollapse, CLink } from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilChevronBottom, cilChevronTop } from '@coreui/icons'
import { ReportWithLocalDataModal } from '../../../../../../views/componentes/xtrareports/ReportWithLocalDataModal'
import { SeriesVoucherDto } from '../../types/types'

export const optionsPrioridad = [
  { value: false, label: 'Descripcion' },
  { value: true, label: 'Barras' },
]

export const optionsSeries = [
  { value: '', label: 'Todos' },
  { value: 'COMPRAS', label: 'Compras' },
  { value: 'MOVIMIENTOS', label: 'Ingresos/Egresos' },
  { value: 'TRANSFERENCIAS', label: 'Transferencias' },
  { value: 'VENTAS', label: 'Ventas' },
]

interface ISearchProps extends React.PropsWithChildren {
  reporte: null | 'Viewer' | 'Designer'
  setReporte: (data: null | 'Viewer' | 'Designer') => void
}

const SeriesComprobante: React.FunctionComponent<ISearchProps> = (props) => {
  const { reporte, setReporte } = props

  const dataGridSeriesComprobantes = React.useRef<any>()
  const dispatch = useDispatch()
  const [prioridadBarras, setPrioridadBarras] = React.useState<{ value: boolean, label: string }>(optionsPrioridad[1])
  const [resultados, setResultados] = React.useState([])
  const [showPoupComprobantesVentas, setShowPoupComprobantesVentas] = React.useState<boolean>(false)
  const [comprobante, setComprobante] = React.useState<string>('')

  const searchFilter = useSelector((state: RootState) => {
    return state.inventarios.series.search.filter
  })

  const collapsed = useSelector((state: RootState) => {
    return state.inventarios.series.search.colapsadoAvanzado
  })

  const searchResults = useSelector((state: RootState) => {
    return state.inventarios.series.search.resultListCom
  })

  const modulo = useSelector((state: RootState) => {
    return state.inventarios.series.config.modulo
  })

  const onGetData = React.useCallback(() => {
    if (searchResults !== null && searchResults !== null && searchResults['auto'] && searchResults['auto'].length > 0) {
      let newArray = []
      newArray = searchResults['auto'].map(x => {
        if (x?.itemCodigo) {
          return {
            ...x,
            serie: x?.serie.replace("'", ""),
            min: x?.min.replace("'", ""),
          }
        }
      })
      if (searchFilter.local && searchFilter.local?.codigo > 0) {
        newArray = newArray.filter(x => x?.localCodigo === searchFilter.local?.codigo)
      }
      consoleService.log(newArray)
      setResultados(newArray)
    }
  }, [searchResults, searchFilter?.local])

  const onGetNumeroDoc = React.useCallback((tipo: string, numero: string, codigoDoc: number) => {
    if (tipo === 'venta') {
      dispatch(changeFilter({
        ...searchFilter,
        ventaCodigo: codigoDoc,
        ventaDescripcion: numero,
      }))
    } else {
      dispatch(changeFilter({
        ...searchFilter,
        compraCodigo: codigoDoc,
        compraDescripcion: numero,
      }))

    }
  }, [dispatch, searchFilter])

  const getFilterString = React.useCallback(() => {
    const filterText = ''
    return filterText
  }, [])

  const onExportExcell = React.useCallback((e) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('Main sheet')
    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Serie_Comprobante.xlsx')
      });
    });
  }, [])

  React.useEffect(() => {
    onGetData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResults, searchFilter?.local])

  const onParseData = React.useCallback(
    (seriesComprobante: Array<any> = []) => {
      let registros: Array<SeriesVoucherDto> = []
      let registro: SeriesVoucherDto = null
      if (seriesComprobante.length > 0) {
        registros = seriesComprobante.map((x) => {
          registro = {
            Code: x?.barras ?? '',
            Description: x?.descripcion ?? '',
            Serie: x?.serie ?? '',
            Min: x?.min ?? '',
            Type: x?.tipo ?? '',
            Date: x?.fecha ?? '',
            Transaction: x?.codigo ?? '',
            Local: x?.local ?? '',
          }
          return registro
        })
      }
      consoleService.log(registros)
      return registros
    },
    [],
  )

  return (
    <>
      <ReportWithLocalDataModal
        show={reporte !== null}
        onClose={() => setReporte(null)}
        data={onParseData(resultados)}
        fileName="StaticSerieVourcher"
        mode={reporte ?? 'Viewer'}
        parameters={{ Reporte_Filtro: getFilterString() }}
        template="StaticSerieVourcher"
        key="reportDesigner"
      />
      {showPoupComprobantesVentas && (
        <DocumentoVentaAfectar
          onCancel={() => {
            setShowPoupComprobantesVentas(false)
          }}
          onOk={(data) => {
            consoleService.log(data)
            onGetNumeroDoc(comprobante, data?.numero, data?.codigo)
            setShowPoupComprobantesVentas(false)
          }}
          tipo={TiposComprobantesSri.NotaCredito}
          tipoComprobante={comprobante}
          modulo={modulo}
        />
      )}
      <CCardHeader
        onClick={() => dispatch(setCollapsedAvanzed(!collapsed))}
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <strong> {collapsed ? '' : 'Filtro de Búsqueda por Comprobante'} </strong>
        <CLink className="card-header-action">
          <CIcon icon={collapsed ? cilChevronBottom : cilChevronTop} />
        </CLink>
      </CCardHeader>
      <CCollapse visible={collapsed}>
        <CCardBody>
          <RowContainer>
            <CustomCol xs="12" md="3">
              <Labeled label="Ingreso/Egreso:">
                <TextBox
                  value={searchFilter?.ingresosEgreso ?? null}
                  onValueChanged={(data) => {
                    if (data.event !== null && data.event !== undefined) {
                      dispatch(changeFilter({
                        ...searchFilter,
                        ingresosEgreso: data?.value
                      }))
                    }
                  }}
                  showClearButton={true}
                />
              </Labeled>
            </CustomCol>
            <CustomCol xs="12" md="3">
              <Labeled label="Compra:">
                <div style={{ display: 'flex', gap: '10px' }}>
                  <TextBox
                    value={searchFilter?.compraDescripcion ?? null}
                    width="100%"
                    inputAttr={{ autocomplete: 'nope' }}
                    readOnly={true}
                  />
                  <Button
                    id="btnAdd"
                    className="me-1"
                    icon="add"
                    stylingMode="contained"
                    type="default"
                    onClick={() => {
                      setComprobante('compra')
                      setShowPoupComprobantesVentas(true)
                    }}
                  />
                </div>
              </Labeled>
            </CustomCol>
            <CustomCol xs="12" md="3">
              <Labeled label="Transferencia:">
                <TextBox
                  value={searchFilter?.transferencia ?? null}
                  onValueChanged={(data) => {
                    if (data.event !== null && data.event !== undefined) {
                      dispatch(changeFilter({
                        ...searchFilter,
                        transferencia: data?.value
                      }))
                    }
                  }}
                  showClearButton={true}
                />
              </Labeled>
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol xs="12" md="3">
              <Labeled label="Venta:">
                <div style={{ display: 'flex', gap: '10px' }}>
                  <TextBox
                    value={searchFilter?.ventaDescripcion ?? null}
                    width="100%"
                    inputAttr={{ autocomplete: 'nope' }}
                    readOnly={true}
                  />
                  <Button
                    id="btnAdd"
                    className="me-1"
                    icon="add"
                    stylingMode="contained"
                    type="default"
                    onClick={() => {
                      setComprobante('venta')
                      setShowPoupComprobantesVentas(true)
                    }}
                  />
                </div>
              </Labeled>
            </CustomCol>
            <CustomCol xs="12" md="3">
              <Labeled label="Locales:">
                <LocalesLookUp
                  selected={searchFilter?.local ?? null}
                  onChanged={(data) => {
                    dispatch(changeFilter({
                      ...searchFilter,
                      local: data
                    }))
                  }}
                  onChangedOptions={() => { }}
                />
              </Labeled>
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol xs="12" md="10">
              <Labeled label="Items:">
                <RowContainer>
                  <CustomCol md={searchFilter.item === null ? "6" : "8"}>
                    <BuscarItemPedidoMemoLookUp
                      selected={searchFilter.item ?? null}
                      onChanged={(data) => {
                        dispatch(changeFilter({
                          ...searchFilter,
                          item: data
                        }))
                      }}
                      cliente={null}
                      fecha={DateUtils.getCurrentDateAsString(formatoFechasApi)}
                      prioridadBarras={prioridadBarras?.value ?? true}
                      modulo={EModulosAcatha.Series}
                      tipoAsociado={null}
                      local={searchFilter?.local !== null && searchFilter?.local !== undefined ? searchFilter?.local : undefined}
                      movimiento={null}
                      allowMoreOption={true}
                      allowClear={true}
                      allowEdit={true}
                      allowAdd={true}
                      codigoPrvAsociado={-1}
                    />
                  </CustomCol>
                  <CustomCol md="4">
                    <RadioGroup
                      name="iOptionsMov"
                      dataSource={optionsPrioridad}
                      value={prioridadBarras}
                      layout="horizontal"
                      displayExpr="label"
                      onValueChange={(data) => {
                        setPrioridadBarras(data)
                      }}
                    />
                  </CustomCol>
                </RowContainer>
              </Labeled>
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
              <RadioGroup
                name="idTypesHierarchy"
                dataSource={optionsSeries}
                value={searchFilter?.tipo ?? null}
                layout="horizontal"
                displayExpr="label"
                onValueChanged={(data) => {
                  if (data.event !== null && data.event !== undefined) {
                    dispatch(changeFilter({
                      ...searchFilter,
                      tipo: data?.value
                    }))
                  }
                }}
              />
              <CheckBox text="En Stock" value={searchFilter?.enStock ?? false} onValueChanged={(data) => {
                if (data.event !== null && data.event !== undefined) {
                  dispatch(changeFilter({
                    ...searchFilter,
                    enStock: data?.value
                  }))
                }
              }} />
            </div>
          </RowContainer>
        </CCardBody>
      </CCollapse>
      <ItemsCountIndicator items={resultados} />
      <DataGrid
        ref={dataGridSeriesComprobantes}
        selection={{ mode: 'single' }}
        hoverStateEnabled={true}
        dataSource={resultados}
        showBorders={true}
        allowColumnReordering={true}
        allowColumnResizing={true}
        columnAutoWidth={true}
        onExporting={onExportExcell}
        width={'100%'}
        keyExpr="codigo"
        key="gridListItems"
      >
        <Paging defaultPageSize={20} />
        <Pager
          //visible={data.length > 0 ? true : false}
          //allowedPageSizes={getAllowedPageSizes(data)}
          displayMode={'full'}
          showPageSizeSelector={true}
          showInfo={true}
          showNavigationButtons={true}
        />
        <ColumnChooser enabled={true} mode="select" />
        <Export enabled={true} allowExportSelectedData={true} />
        <Column visible={false} dataField="itemCodigo" width={0} />
        <Column
          allowEditing={false}
          dataField="barras"
          caption="Código"
          width="100px"
        />
        <Column
          allowEditing={false}
          dataField="descripcion"
          caption="Descripción"
          width="100px"
        />
        <Column allowEditing={false} dataField="serie" caption="Serie" width="100px" />
        <Column allowEditing={false} dataField="min" caption="Min" width="100px" />
        <Column allowEditing={false} dataField="tipo" caption="Tipo" width="100px" />
        <Column allowEditing={false} dataField="fecha" caption="Fecha" width="100px" />
        <Column
          allowEditing={false}
          dataField="codigo"
          caption="Transacción"
          width="150px"
        />
        <Column allowEditing={false} dataField="local" caption="Local" width="50px" />
      </DataGrid>
    </>
  )
}

export default SeriesComprobante
