import { library } from '@fortawesome/fontawesome-svg-core'
// import {
//   faBell,
//   faCodeCommit,
//   faExclamationTriangle,
//   faReceipt,
//   faSave,
//   faTags,
//   faTriangle,
//   faPassport,
//   faMoneyCheckAlt,
//   faMoneyCheckEditAlt,
//   faCogs,
//   faWallet,
//   faSuitcase,
//   faUsers,
//   faShip,
//   faUtensils,
//   faWifi,
//   faTruck,
//   faSackDollar,
//   faArrowCircleLeft,
//   faArrowCircleRight,
//   faQuestionCircle,
//   faEnvelope,
//   faInbox,
//   faChairOffice
// } from '@fortawesome/pro-regular-svg-icons'

import { faBell } from '@fortawesome/pro-regular-svg-icons/faBell'
import { faCodeCommit } from '@fortawesome/pro-regular-svg-icons/faCodeCommit'
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons/faExclamationTriangle'
import { faReceipt } from '@fortawesome/pro-regular-svg-icons/faReceipt'
import { faSave } from '@fortawesome/pro-regular-svg-icons/faSave'
import { faTags } from '@fortawesome/pro-regular-svg-icons/faTags'
import { faTriangle } from '@fortawesome/pro-regular-svg-icons/faTriangle'
import { faPassport } from '@fortawesome/pro-regular-svg-icons/faPassport'
import { faMoneyCheckAlt } from '@fortawesome/pro-regular-svg-icons/faMoneyCheckAlt'
import { faMoneyCheckEditAlt } from '@fortawesome/pro-regular-svg-icons/faMoneyCheckEditAlt'
import { faCogs } from '@fortawesome/pro-regular-svg-icons/faCogs'
import { faWallet } from '@fortawesome/pro-regular-svg-icons/faWallet'
import { faSuitcase } from '@fortawesome/pro-regular-svg-icons/faSuitcase'
import { faUsers } from '@fortawesome/pro-regular-svg-icons/faUsers'
import { faShip } from '@fortawesome/pro-regular-svg-icons/faShip'
import { faUtensils } from '@fortawesome/pro-regular-svg-icons/faUtensils'
import { faWifi } from '@fortawesome/pro-regular-svg-icons/faWifi'
import { faTruck } from '@fortawesome/pro-regular-svg-icons/faTruck'
import { faSackDollar } from '@fortawesome/pro-regular-svg-icons/faSackDollar'
import { faArrowCircleLeft } from '@fortawesome/pro-regular-svg-icons/faArrowCircleLeft'
import { faArrowCircleRight } from '@fortawesome/pro-regular-svg-icons/faArrowCircleRight'
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons/faQuestionCircle'
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons/faEnvelope'
import { faInbox } from '@fortawesome/pro-regular-svg-icons/faInbox'
import { faChairOffice } from '@fortawesome/pro-regular-svg-icons/faChairOffice'
import { faUserHeadset } from '@fortawesome/pro-regular-svg-icons/faUserHeadset'
import { faEnvelopesBulk } from '@fortawesome/pro-regular-svg-icons/faEnvelopesBulk'
import { faFiles } from '@fortawesome/pro-regular-svg-icons/faFiles'
import { faClipboardCheck } from '@fortawesome/pro-regular-svg-icons/faClipboardCheck'
import { faBadgeCheck } from '@fortawesome/pro-regular-svg-icons/faBadgeCheck'
import { faArrowProgress } from '@fortawesome/pro-regular-svg-icons/faArrowProgress'

library.add(
  faEnvelopesBulk,
  faFiles,
  faUserHeadset,
  faBell,
  faExclamationTriangle,
  faReceipt,
  faSave,
  faTags,
  faTriangle,
  faCodeCommit,
  faPassport,
  faMoneyCheckAlt,
  faMoneyCheckEditAlt,
  faCogs,
  faWallet,
  faSuitcase,
  faUsers,
  faShip,
  faUtensils,
  faWifi,
  faTruck,
  faSackDollar,
  faArrowCircleLeft,
  faArrowCircleRight,
  faQuestionCircle,
  faEnvelope,
  faInbox,
  faChairOffice,
  faClipboardCheck,
  faBadgeCheck,
  faArrowProgress,
)
