import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CCard, CCardBody, CCol, CRow } from '@coreui/react-pro'
import { CheckBox, DataGrid, DateBox, LoadPanel, NumberBox, RadioGroup } from 'devextreme-react'
import { Column, Grouping, Scrolling, Sorting } from 'devextreme-react/data-grid'

import CustomCol from '../../../../../../views/componentes/colContainer'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput'
import LocalidadesLookUp from '../../../../../componentes/localidadesLookUp/localidadesLookUp'
import CabeceraFiltroBusqueda from '../../shared/cabeceraFiltroBusqueda'
import { RootState } from '../../../../../../store/store'
import {
  CarteraClientesPorCobrar,
  FiltroCarteraState,
  FiltrosDocumentosState,
} from '../../types/types'
import {
  cleanFilter,
  fetchCartera,
  setChangeFilter,
  setCleanResult,
} from '../../store/carteraReducer'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import { clearButtonClick, setCurrentExecutingAction } from '../../store/tabsReducer'
import { TOptionGenericoString } from '../../../clientes/types/types'
import { OptionCiudad } from '../../../../../../containers/component/formLocal/types/types'
import { Vendedor } from '../../../../../shared/components/buscarVendedorLookMemoUp/type/types'
import { BuscarVendedorLookUp } from '../../../../../shared/components/buscarVendedorLookMemoUp/buscarVendedorLookUp'
import { DateUtils } from '../../../../../../helpers/dateUtils'
import { addToast } from '../../../../../../store/toasterReducer'
import {
  CustomButtons,
  FETCH_STATUS,
  ToastTypes,
} from '../../../../../../store/types'
import { VerCarteraVentas } from '../../../../../ventas/pages/cuadres/types/types'
import { ReportWithLocalDataModal } from '../../../../../../views/componentes/xtrareports/ReportWithLocalDataModal'
import { TabState } from '../../../../../../store/genericTabTypes'
import LoadingIndicator from '../../../../../../views/componentes/loadingindicator/loadingindicator'
import BlockUi from '../../../../../../views/componentes/librerias/block-ui'
import { consoleService } from '../../../../../../services/console.service'

const gruposTipos = [
  { value: "", label: 'Todos' },
  { value: "N", label: 'Nacional' },
  { value: "E", label: 'Extrangero' },
]

interface ICorteComprobantesProps {
  tabId: string
  tab: TabState<any>
}

const CorteComprobantes: React.FunctionComponent<ICorteComprobantesProps> = (props) => {

  const { tabId, tab } = props

  const dispatch = useDispatch()
  const filtroGlobales = useSelector((state: RootState) => state.clientes.docCuentasXCobrar.search.filter)
  const filterBusqueda = useSelector((state: RootState) => state.clientes.docCuentasXCobrar.cartera.filter)
  const resultadosBusqueda = useSelector((state: RootState) => state.clientes.docCuentasXCobrar.cartera.resultados)
  const estadoBusqueda = useSelector((state: RootState) => state.clientes.docCuentasXCobrar.cartera.status)

  const [filtroGlobal, setfiltroGlobal] = React.useState<FiltrosDocumentosState>(filtroGlobales)
  const [resultados, setResultados] = React.useState<Array<VerCarteraVentas>>([])
  const [grupos, setGrupos] = React.useState<Array<string>>(['clienteBox'])
  const [showLoader, setShowLoader] = React.useState(false)
  const [clean, setClean] = React.useState<boolean>(false)

  const [reporte, setReporte] = React.useState<null | "Viewer" | "Designer">(null)
  const [grupoTipo, setGrupoTipo] = React.useState<TOptionGenericoString>(gruposTipos[0])
  const [ciudad, setCiudad] = React.useState<OptionCiudad>(null)
  const [corte, setCorte] = React.useState(new Date())
  const [desdec, setDesdec] = React.useState(false)
  const [desde, setDesde] = React.useState<Date | null>(null)
  const [vendedor, setVendedor] = React.useState<Vendedor>(null)
  const [agruparPor, setAgruparPor] = React.useState({
    clienteBox: true,
    rcomercialBox: false,
    empresaBox: false,
    ciudadBox: false,
    fechaBox: false,
    consolidadoBox: false,
    saldoMayorCeroBox: true,
    consignacion: false,
  })

  const getFilterString = React.useCallback((filter: FiltroCarteraState) => {
    const filterText = "";

    return filterText;
  }, [])
  const gruposCheckBox = [
    {
      title: 'Cliente',
      value: 'clienteBox'
    },
    {
      title: 'R. Comercial',
      value: 'rcomercialBox'
    },
    {
      title: 'Empresa',
      value: 'empresaBox'
    },
    {
      title: 'Ciudad',
      value: 'ciudadBox'
    },
    {
      title: 'Fecha',
      value: 'fechaBox'
    },
    {
      title: 'Consolidado',
      value: 'consolidadoBox'
    },
    {
      title: 'Saldo Mayor a Cero',
      value: 'saldoMayorCeroBox'
    },
    {
      title: 'Consignacion',
      value: 'consignacion'
    },
  ]

  const position = { of: '#carteraContent' };

  const setToast = React.useCallback((mensaje: string, button: ToastTypes) => {
    dispatch(addToast({
      title: 'Cartera',
      content: mensaje,
      type: button
    }));
  }, [dispatch])

  const onButtonPrint = React.useCallback(async (filtro: null | "Viewer" | "Designer") => {
    setReporte(filtro);
  }, []);

  const getResultados = React.useCallback(() => {
    let newArray = []
    if (agruparPor.saldoMayorCeroBox && resultadosBusqueda['auto']) {
      newArray = resultadosBusqueda['auto'].filter((item: VerCarteraVentas) => item.saldo > 0)
    } else if (resultadosBusqueda['auto']) {
      newArray = resultadosBusqueda['auto']
    }
    setResultados(newArray)
  }, [agruparPor.saldoMayorCeroBox, resultadosBusqueda])


  const onChangeState = React.useCallback((value, key) => {
    switch (key) {
      case 'tipoCliente':
        setGrupoTipo(value)
        break
      case 'ciudad':
        setCiudad(value)
        break
      case 'corte':
        setCorte(value)
        break
      case 'habilitarDesdec':
        setDesdec(value)
        break
      case 'desde':
        setDesde(value)
        break
      case 'vendedor':
        setVendedor(value)
        break
      default:
        break
    }
  }, [])

  const onChangedValue = React.useCallback((data, key) => {
    if (data?.event !== null && data?.event !== undefined) {
      if (key === "corte" || key === "desde") {
        onChangeState(data.value, key)
        const newFecha = DateUtils.dateToString(data.value, "dd/MM/yyyy")
        dispatch(setChangeFilter({
          ...filterBusqueda,
          [key]: newFecha
        }))
      } else {
        onChangeState(data.value, key)
        dispatch(setChangeFilter({
          ...filterBusqueda,
          [key]: data.value
        }))
      }
    } else if (key === "ciudad" || key === "vendedor") {
      onChangeState(data, key)
      dispatch(setChangeFilter({
        ...filterBusqueda,
        [key]: data
      }))
    }
  }, [dispatch, filterBusqueda, onChangeState])

  const onChangedAP = React.useCallback((data, key) => {
    setResultados([])
    setAgruparPor({
      ...agruparPor,
      [key]: data.value
    })
    dispatch(setChangeFilter({
      ...filterBusqueda,
      agruparPor: {
        ...filterBusqueda.agruparPor,
        [key]: data.value
      }
    }))

    if (data.value && !grupos.includes(key)) {
      const saveArray = [...grupos, key]
      setGrupos(saveArray)
    } else {
      if (grupos.includes(key)) {
        const newGrupo: Array<string> = grupos.filter(g => g !== key)
        setGrupos(newGrupo)
      }
    }

    setShowLoader(true)
    getResultados()
  }, [dispatch, filterBusqueda, agruparPor, grupos, getResultados])

  const getTotal = React.useCallback((carteraResultado): number => {
    let total = 0
    if (carteraResultado.length > 0) {
      carteraResultado.map(item => {
        total += item.saldo
      })
    }
    return total
  }, [])

  const onFind = React.useCallback(async () => {
    try {
      const toAny: any = fetchCartera(filterBusqueda)
      const res = await dispatch(toAny)
      if (res !== null && res.payload['error'] !== true) {
        if (!agruparPor.saldoMayorCeroBox) {
          setToast(res.payload['message'], ToastTypes.Success)
        } else {
          setToast('Registros encontrados satisfactoriamente', ToastTypes.Success)
        }
      } else {
        if (res !== null && res.payload['error'] === true) {
          setToast(res.payload['message'], ToastTypes.Warning)
        } else {
          setToast('Error - Consola', ToastTypes.Warning)
        }
      }
    } catch (error) {
      setToast(error, ToastTypes.Danger)
    }
  }, [dispatch, filterBusqueda, setToast, agruparPor.saldoMayorCeroBox])

  const onUndo = React.useCallback(() => {
    dispatch(cleanFilter())
    setClean(true)
    setGrupoTipo(gruposTipos[0])
    setCiudad(null)
    setCorte(new Date())
    setDesdec(false)
    setDesde(null)
    setVendedor(null)
    setAgruparPor(
      {
        clienteBox: true,
        rcomercialBox: false,
        empresaBox: false,
        ciudadBox: false,
        fechaBox: false,
        consolidadoBox: false,
        saldoMayorCeroBox: true,
        consignacion: false,
      }
    )
  }, [dispatch])

  const onBroom = React.useCallback(() => {
    setResultados([])
  }, [])

  const handleButtonClick = React.useCallback((button: ButtonTypes) => {
    switch (button) {
      case ButtonTypes.find:
        onFind()
        break
      case ButtonTypes.broom:
        onBroom()
        break
      case ButtonTypes.undo:
        onUndo()
        break
      case ButtonTypes.print:
        if (resultados.length > 0) {
          onButtonPrint('Viewer')
        } else {
          setToast('Primero debe realizar la busqueda de información', ToastTypes.Warning)
        }
        break
      case ButtonTypes.print_design:
        if (resultados.length > 0) {
          onButtonPrint('Designer')
        } else {
          setToast('Primero debe realizar la busqueda de información', ToastTypes.Warning)
        }
        break
      default:
        break
    }
    dispatch(clearButtonClick(tabId))
  }, [dispatch, tabId, onFind, resultados, setToast, onButtonPrint, onBroom, onUndo])


  React.useEffect(() => {
    dispatch(setChangeFilter({
      ...filterBusqueda,
      cliente: filtroGlobal.cliente,
      local: filtroGlobal.lugar,
      empresa: filtroGlobal.empresa,
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtroGlobal])

  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    } else {
      console.log(tab.globalButtonClick)
    }
  }, [handleButtonClick, tab.globalButtonClick])

  React.useEffect(() => {
    getResultados()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultadosBusqueda, agruparPor.saldoMayorCeroBox])

  React.useEffect(() => {
    dispatch(setCleanResult())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onParseData = React.useCallback((cartera: Array<any> = []) => {
    let registros: Array<CarteraClientesPorCobrar> = [];
    if (cartera.length > 0) {
      registros = cartera.map((x) => {
        return {
          cedula: x?.clienteIdentificacion ?? '',
          nombre: x?.clienteNombre ?? '',
          fecha: x?.ventaFecha ?? '',
          doc: x?.comprobante ?? 0,
          numero: x?.numero ?? 0,
          credito: parseInt(x?.credito) ?? 0,
          cobros: x?.cobros ?? 0,
          nc: x?.notasCredito ?? 0,
          ret: x?.retencion ?? 0,
          saldo: x?.saldo ?? 0,
          fechaCorte: DateUtils.dateToString(corte, 'dd/MM/yyyy') ?? '',
        }
      })
    }
    const subtotales: Array<CarteraClientesPorCobrar> = []
    if (registros.length > 0) {
      let cedula = ''
      registros.forEach(x => {
        let cont = 0
        cartera.forEach(j => {
          if (j?.clienteIdentificacion === x?.cedula && cedula !== x?.cedula) {
            cont += j?.saldo ?? 0
          }
        })
        if (cedula !== x?.cedula) {
          cedula = x?.cedula
          subtotales.push(
            {
              cedula: x?.cedula,
              nombre: x?.nombre ?? '',
              fecha: '',
              fechaCorte: '',
              doc: "",
              numero: "",
              credito: null,
              cobros: null,
              nc: null,
              ret: 'SUBTOTAL:',
              saldo: cont,
            }
          )
        }
      })
    }

    if (subtotales.length > 0) {
      subtotales.forEach(s => {
        registros.push(s)
      })
    }

    consoleService.log(subtotales)
    consoleService.log(registros)

    return registros;
  }, []);

  const columnCustom = React.useCallback((column) => {
    if (column !== null && column.length > 0) {
      column[1].groupIndex = grupos.includes('clienteBox') ? grupos.indexOf('clienteBox') : undefined
      column[2].groupIndex = grupos.includes('rcomercialBox') ? grupos.indexOf('rcomercialBox') : undefined
      column[3].groupIndex = grupos.includes('ciudadBox') ? grupos.indexOf('ciudadBox') : undefined
      column[5].groupIndex = grupos.includes('empresaBox') ? grupos.indexOf('empresaBox') : undefined
      column[10].groupIndex = grupos.includes('fechaBox') ? grupos.indexOf('fechaBox') : undefined
    }
    setTimeout(() => {
      setShowLoader(false)
    }, 1000);
  }, [grupos])

  React.useEffect(() => {
    if (estadoBusqueda === FETCH_STATUS.FAILED) {
      dispatch(addToast({
        title: 'Buscar Cartera',
        content: 'Error al realizar la búsqueda',
        type: ToastTypes.Danger
      }))
      dispatch(setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: undefined
      }))
    }
    if (estadoBusqueda === FETCH_STATUS.LOADING) {
      dispatch(setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: ButtonTypes.find
      }))
    }
    if (estadoBusqueda === FETCH_STATUS.SUCCESS) {
      dispatch(setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: undefined
      }))
    }
  }, [dispatch, estadoBusqueda, tabId, showLoader])



  return (
    <>
      <ReportWithLocalDataModal
        show={reporte !== null}
        onClose={() => setReporte(null)}
        data={onParseData(resultados)}
        fileName='StaticCarteraPendientes'
        mode={reporte ?? 'Viewer'}
        parameters={{ 'Reporte_Filtro': getFilterString(filterBusqueda), Modulo: "Clientes" }}
        template='StaticCarteraPendientes'
        key='reportDesigner'
      />
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={position}
        visible={estadoBusqueda === FETCH_STATUS.LOADING || showLoader}
        showIndicator={true}
        shading={true}
        showPane={true}
        closeOnOutsideClick={false}
      />
      <CCard id='carteraContent'>
        <CCardBody>
          <CabeceraFiltroBusqueda
            filtroBusqueda={{
              empresa: null,
              cliente: null,
              puntoVenta: null,
              proyecto: null,
              lugar: null,
              nombre: ''
            }}
            clean={clean}
            setClean={setClean}
            getCabeceraFiltro={(data) => setfiltroGlobal(data)}
          />
          <br />
          <RowContainer>
            <CustomCol xs="12" md="4">
              <RadioGroup
                name="tiposNacionalidad"
                layout="horizontal"
                displayExpr="label"
                value={grupoTipo}
                dataSource={gruposTipos}
                onValueChanged={(data) => onChangedValue(data, 'tipoCliente')}
              />
            </CustomCol>
            <CustomCol xs="12" md="8">
              {
                'Los datos de este reporte se basan en la fecha de registro de la venta y la fecha de cobro del crédito otorgado.'
              }
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol xs="12" md="3">
              <Labeled label="Ciudad:">
                <LocalidadesLookUp
                  onChanged={(data) => onChangedValue(data, 'ciudad')}
                  onChangedOptions={() => { }}
                  selected={ciudad}
                  allowEdit
                  allowClear
                />
              </Labeled>
            </CustomCol>
            <CustomCol xs="12" md="5">
              <Labeled label="Agrupar por:">
                {
                  gruposCheckBox.map((grupo, i) => (
                    <CheckBox
                      key={i}
                      className='m-1'
                      text={grupo.title}
                      value={agruparPor[grupo.value]}
                      onValueChanged={(data) => onChangedAP(data, grupo.value)}
                    />

                  ))
                }
              </Labeled>
            </CustomCol>
            <RowContainer>
              <CustomCol xs="12" md="4">
                <Labeled label="Corte:">
                  <CRow>
                    <CCol xs="4" md="5">
                      <DateBox
                        value={corte}
                        onValueChanged={(data) => onChangedValue(data, 'corte')}
                      // onEnterKey={onEnterKey}
                      />
                    </CCol>
                    <CCol xs="8" md="7">
                      <CRow>
                        <CCol xs="4" md="4">
                          <CheckBox
                            text="Desde"
                            value={desdec}
                            onValueChanged={(data) => onChangedValue(data, 'habilitarDesdec')}
                          />
                        </CCol>
                        <CCol xs="8" md="8">
                          <DateBox
                            value={desde}
                            onValueChanged={(data) => onChangedValue(data, 'desdec')}
                            // onEnterKey={onEnterKey}
                            disabled={desdec ? false : true}
                          />
                        </CCol>
                      </CRow>
                    </CCol>
                  </CRow>
                </Labeled>
              </CustomCol>
              <CustomCol xs="12" md="3">
                <Labeled label="Vendedor:">
                  <BuscarVendedorLookUp
                    selected={vendedor}
                    onChanged={(data) => onChangedValue(data, 'vendedor')}
                    allowAdd
                    allowClear
                    allowEdit
                  />
                </Labeled>
              </CustomCol>
            </RowContainer>
          </RowContainer>
          <CustomCol xs="12" md="12">
            <CCol className="d-flex justify-content-end">{resultados.length}</CCol>
            <DataGrid
              style={{ marginTop: '5px' }}
              selection={{ mode: 'single' }}
              keyExpr="ventaCodigo"
              repaintChangesOnly={true}
              customizeColumns={columnCustom}
              height={340}
              width='100%'
              hoverStateEnabled={true}
              dataSource={resultados}
              showBorders={true}
              showRowLines={true}
            >
              {/* <RemoteOperations groupPaging={true} /> */}
              <Scrolling mode="virtual" />
              <Grouping autoExpandAll={true} />
              <Sorting mode="none" />
              {/* <GroupPanel visible={true} /> */}
              <Column
                dataType="string"
                dataField="clienteIdentificacion"
                caption="Ced/Ruc"
                width="20%"
              />
              <Column
                dataField="clienteNombre"
                caption="Cliente"
                width="5%"
              />
              <Column
                dataField="clienteRazonComercial"
                caption="R Comercial"
                width="5%"
              />
              <Column
                dataType="string"
                dataField="ciudadDescripcion"
                caption="Ciudad"
                width="5%"
              />
              <Column
                dataType="string"
                dataField="localNombre"
                caption="Local"
                width="5%"
              />
              <Column
                dataField="empresa"
                caption="Empresa"
                width="5%" />
              <Column
                dataType="string"
                dataField="clienteDireccion"
                caption="DIrección"
                width="5%"
              />
              <Column
                dataType="string"
                dataField="clienteTelefono"
                caption="Teléfono"
                width="5%"
              />
              <Column
                dataField="comprobante"
                caption="Comprobante"
                width="5%"
              />
              <Column
                dataType="string"
                dataField="numero"
                caption="Número"
                width="5%"
              />
              <Column
                dataField="ventaFecha"
                caption="Fecha"
                width="5%" />
              <Column
                dataField="ventaTotal"
                alignment="left"
                format="currency"
                caption="Total"
                width="5%"
              />
              <Column
                dataField="credito"
                caption="Crédito"
                alignment="left"
                format="currency"
                width="5%"
              />
              <Column
                dataField="cobros"
                alignment="left"
                format="currency"
                caption="Cobros"
                width="5%"
              />
              <Column
                dataField="notasCredito"
                alignment="left"
                format="currency"
                caption="NC"
                width="5%"
              />
              <Column
                dataField="retencion"
                caption="Retención"
                alignment="left"
                format="currency"
                width="5%"
              />
              <Column
                dataField="saldo"
                alignment="left"
                format="currency"
                caption="Saldo"
                width="5%"
              />
            </DataGrid>
          </CustomCol>
          <RowContainer>

            <CCol className="mt-1 d-flex justify-content-end">
              <div className="dx-field">
                <div className="dx-field-label">{'Total:'}</div>
                <div className="dx-field-value">
                  <NumberBox
                    width="100%"
                    value={getTotal(resultados)}
                    placeholder=""
                    format="#,##0.00"
                  />
                </div>
              </div>
            </CCol>
          </RowContainer>
        </CCardBody>
      </CCard>
    </>
  )
}

export default React.memo(CorteComprobantes)

export const ModuleButtonsCartera: CustomButtons = {
  Nuevo: false,
  Buscar: true,
  Exportar: true,
  Imprimir: true,
  Limpiar: true,
  Deshacer: true,
}
