import { RequestHelper } from "../../../helpers/requestHelper"
import { SesionService } from '../../../services/sesion.service'

export const bancoService = {
  getPeriodos,
  getProyectos,
  puntosVentaListar,
  getTipoTransacciones,
  getLocales,
}

async function getTipoTransacciones(findTipos): Promise<any> {
  try {
    const obj = {
      ...findTipos
    }
    const data = await RequestHelper.getAll<any>('bancos', 'typeTransaction/cboxLoad', "", obj);
    return data;
  } catch (error) {
    return error;
  }
}

async function getPeriodos(): Promise<any> {
  try {
    const obj = {}
    const data = await RequestHelper.getAll<any>(
      'generales',
      'period/getAll',
      '',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}

async function getProyectos(): Promise<any> {
  try {
    const obj = {}
    const data = await RequestHelper.getAll<any>(
      'proyectos/listar',
      '',
      '',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}

async function getLocales(
  codigoempresa: number,
  mensaje: string,
): Promise<any> {
  try {
    const obj = {
      codigoempresa: codigoempresa,
      mensaje: mensaje,
    }
    const data = await RequestHelper.getAll<any>('local/cboxLoad', '', '', obj)
    return data
  } catch (error) {
    return error
  }
}

async function puntosVentaListar(sms: string): Promise<any> {
  try {
    const sesion = SesionService.getCurrentSesion()
    const obj = {
      codigoLocal: sesion.local.codigo,
      mensaje: sms,
    }
    const data = await RequestHelper.get<any>(
      'sidebar',
      'pointSales/getPointsSales',
      '',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}
