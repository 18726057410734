import {
  CCard,
  CCardBody,
  CCardFooter,
  CCol,
  CContainer,
  CRow,
} from '@coreui/react-pro'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  DateUtils,
  formatoFechasApi,
  formatoFechasDatePickers,
} from '../../../../../helpers/dateUtils'
import { RootState } from '../../../../../store/store'
import CustomCol from '../../../../../views/componentes/colContainer'
import Labeled from '../../../../../views/componentes/labeledInput/labeledInput'
import RowContainer from '../../../../../views/componentes/rowContainer/rowContainer'
import DataGrid, { Column, Editing } from 'devextreme-react/data-grid'
import { CuotaCreditoVenta } from '../../../types/types'
import CustomModalDevx from '../../../../../views/componentes/modal/Modal'
import {
  NumberBox,
  Button as NumberBoxButton,
} from 'devextreme-react/number-box'
import DateBox from 'devextreme-react/date-box'
import { useModalConvertSize } from '../../../../../hooks/useModalSize'
import Container from '../../../../../views/componentes/container'
import applyChanges from 'devextreme/data/apply_changes'
import ValidationGroup from 'devextreme-react/validation-group'
import { utilidades } from '../../../../../helpers/utilidades'
import Validator, { AsyncRule } from 'devextreme-react/validator'
import ValidationSummary from 'devextreme-react/validation-summary'
import { addToast } from '../../../../../store/toasterReducer'
import { ToastTypes } from '../../../../../store/types'
import Switch from 'devextreme-react/switch'

export interface ICreditosVentaProps extends React.PropsWithChildren {
  totalCobrar: number
  fechaFactura: string
  cuotas: Array<CuotaCreditoVenta>
  onOk: (cuotas: Array<CuotaCreditoVenta>) => void
  onCancel: () => void
}

export const CreditosVenta: React.FC<ICreditosVentaProps> = (props) => {
  const { totalCobrar, fechaFactura, cuotas, onOk, onCancel } = props

  const dispatch = useDispatch()

  const empresa = useSelector((state: RootState) => {
    return state.global.session?.empresa
  })
  const validationGroupRef = React.useRef<any>()

  const [modalSize] = useModalConvertSize('md')

  const [periodo, setPeriodo] = React.useState('Meses')
  const [numeroCuotas, setNumeroCuotas] = React.useState(1)
  const [primeraCuotaCOnFechaCmp, setprimeraCuotaCOnFechaCmp] =
    React.useState(false)
  const [cuotasIngresadas, setCuotas] = React.useState<
    Array<CuotaCreditoVenta>
  >(props.cuotas)
  const [descuadre, setDescuandre] = React.useState(0)
  const [fechaCalculo, setFechaCalculo] = React.useState<string>(fechaFactura)
  const [numeroPeriodosCredito, setNumeroPeriodosCredito] = React.useState(1)
  const [changes, setChanges] = React.useState<any>()
  const [show, setShow] = React.useState<boolean>(true)
  const [editRowKey, setEditRowKey] = React.useState<any>()

  const validateRules = React.useCallback(async () => {
    let resolved = false
    let status = false
    const p = new Promise(async (resolve) => {
      while (!resolved) {
        await utilidades.sleep(10)
      }
      resolve(resolved)
    })
    const validationResult = validationGroupRef.current.instance.validate()
    if (!validationResult.isValid && validationResult.status !== 'pending') {
      //setTieneErroresValidacion(true);
      return false
    }
    if (validationResult.status === 'pending') {
      validationResult.complete.then(async (resolve) => {
        await resolve
        status = resolve.isValid
        resolved = true
      })
      await p
      return status
    }
    return true
  }, [])

  const ordenarCuotas = React.useCallback(
    (cuotas: Array<CuotaCreditoVenta>) => {
      const cuotasOrdenadas = cuotas.sort((a, b) => {
        const fechaA = DateUtils.strDateToDate(a.vencimiento, formatoFechasApi)
        const fechaB = DateUtils.strDateToDate(b.vencimiento, formatoFechasApi)
        return fechaA.getTime() - fechaB.getTime()
      })
      const numeroCuotas = cuotasOrdenadas.length
      for (let index = 0; index < numeroCuotas; index++) {
        cuotasOrdenadas[index].numero = index + 1
        cuotasOrdenadas[index].letra = `LETRA ${index + 1}\\${numeroCuotas}`
      }
      return cuotasOrdenadas
    },
    [],
  )

  const validateCuotas = React.useCallback(
    (cuotas: Array<CuotaCreditoVenta>): boolean => {
      const cuotasOrdenadas = ordenarCuotas(cuotas)
      if (cuotasOrdenadas.length === 1) return true
      let cuotaA = cuotasOrdenadas[0]
      let cuotaB: CuotaCreditoVenta
      let index = 1
      while (index < cuotasOrdenadas.length) {
        cuotaB = cuotasOrdenadas[index]
        const fechaA = DateUtils.strDateToDate(
          cuotaA.vencimiento,
          formatoFechasApi,
        )
        const fechaB = DateUtils.strDateToDate(
          cuotaB.vencimiento,
          formatoFechasApi,
        )
        if (fechaA.getTime() === fechaB.getTime()) {
          dispatch(
            addToast({
              content: `La cuota ${index} tiene igual fecha de vencimiento que la cuota ${
                index + 1
              }`,
              type: ToastTypes.Danger,
              autoHide: 5000,
            }),
          )
          return false
        }
        cuotaA = cuotasOrdenadas[index]
        index++
      }
      return true
    },
    [ordenarCuotas, dispatch],
  )

  const calcularCuotas = React.useCallback(
    (
      numeroCuotas: number,
      fechaCalculo: string,
      primeraCuotaConFecha: boolean,
      periodo: string,
      valorPeriodo: number,
    ) => {
      const cuotas: Array<CuotaCreditoVenta> = []
      const startIndex = 0
      let cuotaNumero = 1
      let fechaPrimeraCuota = DateUtils.strDateToDate(
        primeraCuotaConFecha ? fechaFactura : fechaCalculo,
        formatoFechasDatePickers,
      )
      if (!primeraCuotaConFecha) {
        if (periodo === 'Días') {
          fechaPrimeraCuota = DateUtils.add(fechaPrimeraCuota, {
            days: valorPeriodo,
          })
        } else {
          fechaPrimeraCuota = DateUtils.add(fechaPrimeraCuota, {
            months: valorPeriodo,
          })
        }
        //fechaPrimeraCuota = DateUtils.add(fechaPrimeraCuota, { months: 1 });
      }

      // if (entrada > 0) {
      //   const cuota: CuotaCreditoVenta = {
      //     cuota: entrada,
      //     estado: 'NO COBRADO',
      //     letra: 'ENTRADA',
      //     numero: cuotaNumero,
      //     saldo: entrada,
      //     vencimiento: DateUtils.format(fechaPrimeraCuota, formatoFechasApi)
      //   }
      //   fechaPrimeraCuota = DateUtils.add(fechaPrimeraCuota, { months: 1 }); // fechaPrimeraCuota.add(30, 'days');
      //   cuotas.push(cuota);
      //   //cuotaNumero++;
      // }
      const valorCuotas = totalCobrar
      const valorCuota = parseFloat((valorCuotas / numeroCuotas).toFixed(2))
      let sumanCuotas = 0
      for (let index = startIndex; index < numeroCuotas; index++) {
        const cuota: CuotaCreditoVenta = {
          cuota: valorCuota,
          estado: 'NO COBRADO',
          letra: `LETRA ${cuotaNumero}\\${numeroCuotas}`,
          numero: cuotaNumero,
          saldo: valorCuota,
          vencimiento: DateUtils.format(fechaPrimeraCuota, formatoFechasApi),
        }
        if (periodo === 'Días') {
          fechaPrimeraCuota = DateUtils.add(fechaPrimeraCuota, {
            days: valorPeriodo,
          })
        } else {
          fechaPrimeraCuota = DateUtils.add(fechaPrimeraCuota, {
            months: valorPeriodo,
          })
        }

        cuotas.push(cuota)
        sumanCuotas += cuota.cuota
        cuotaNumero++
      }
      setNumeroCuotas(numeroCuotas)
      if (sumanCuotas < valorCuotas) {
        const desfase = valorCuotas - sumanCuotas
        cuotas[0].cuota = parseFloat(
          (cuotas[cuotas.length - 1].cuota + desfase).toFixed(2),
        )
        cuotas[0].saldo = parseFloat(
          (cuotas[cuotas.length - 1].cuota + desfase).toFixed(2),
        )
      } else if (sumanCuotas > valorCuotas) {
        const desfase = valorCuotas - sumanCuotas
        cuotas[0].cuota = parseFloat(
          (cuotas[cuotas.length - 1].cuota + desfase).toFixed(2),
        )
        cuotas[0].saldo = parseFloat(
          (cuotas[cuotas.length - 1].cuota + desfase).toFixed(2),
        )
      }

      setCuotas(cuotas)
      return cuotas
    },
    [fechaFactura, totalCobrar],
  )

  const handleOnOk = React.useCallback(async () => {
    if ((await validateRules()) && validateCuotas(cuotasIngresadas)) {
      onOk(ordenarCuotas(cuotasIngresadas))
      return
    }
    dispatch(
      addToast({
        content: 'Verifique las cuotas!!!',
        type: ToastTypes.Danger,
      }),
    )
  }, [
    cuotasIngresadas,
    validateRules,
    validateCuotas,
    onOk,
    ordenarCuotas,
    dispatch,
  ])

  const onSaving = React.useCallback(
    (e) => {
      e.cancel = true
      const newData = applyChanges(cuotasIngresadas, e.changes, {
        keyExpr: 'numero',
      })
      setChanges([])
      setEditRowKey(null)
      setCuotas(newData)
      //e.promise = saveChange(dispatch, e.changes[0]);
    },
    [cuotasIngresadas],
  )

  const onChangesChange = React.useCallback((changes) => {
    setChanges(changes)
  }, [])

  const onEditRowKeyChange = React.useCallback((editRowKey) => {
    setEditRowKey(editRowKey)
  }, [])

  const validateRetencion = React.useCallback((e) => {
    return e.value === 0
      ? Promise.resolve()
      : Promise.reject('La suma de cuotas no es igual al valor de la factura.')
  }, [])

  const convertirFechaVencimiento = React.useCallback(
    (data: CuotaCreditoVenta) => {
      if (data) {
        return DateUtils.strDateToDate(data.vencimiento, formatoFechasApi)
      }
    },
    [],
  )

  const setFechaVencimientoValue = React.useCallback((rowData, value) => {
    if (value) {
      rowData.vencimiento = DateUtils.dateToString(value, formatoFechasApi)
    }
  }, [])

  const setCuotaValue = React.useCallback((rowData, value) => {
    if (value) {
      rowData.cuota = value
      rowData.saldo = value
    }
  }, [])

  const validateVencimiento = React.useCallback(
    (params) => {
      const { data } = params
      const vencimiento = DateUtils.strDateToDate(
        data.vencimiento,
        formatoFechasApi,
      )
      const fechaFacturaDate = DateUtils.strDateToDate(
        fechaFactura,
        formatoFechasDatePickers,
      )
      if (vencimiento.getTime() < fechaFacturaDate.getTime()) {
        return Promise.reject(
          'La fecha de vencimiento no puede ser anterior a la factura!!!',
        )
      }
      return Promise.resolve()
    },
    [fechaFactura],
  )

  const isDisabledDate = React.useCallback(
    ({ date }) => {
      const fechaFacturaDate = DateUtils.strDateToDate(
        fechaFactura,
        formatoFechasDatePickers,
      )
      return fechaFacturaDate.getTime() >= date.getTime()
    },
    [fechaFactura],
  )

  React.useEffect(() => {
    if (empresa && empresa.cuotasVenta > 0 && cuotasIngresadas.length === 0) {
      setNumeroCuotas(empresa.cuotasVenta)
      calcularCuotas(empresa.cuotasVenta, fechaFactura, false, 'Meses', 1)
    }
  }, [empresa, fechaFactura, cuotasIngresadas, calcularCuotas])

  React.useEffect(() => {
    const sumanCuotas = parseFloat(
      cuotasIngresadas.reduce((prev, next) => prev + next.cuota, 0).toFixed(2),
    )
    const diferencia = parseFloat((totalCobrar - sumanCuotas).toFixed(2))
    if (diferencia > 0.01 || diferencia < 0) {
      setDescuandre(diferencia)
    } else {
      setDescuandre(0)
    }
  }, [cuotasIngresadas, totalCobrar])

  return (
    <CustomModalDevx
      size={modalSize}
      id="sectionTutModalCuotas"
      title="Cuotas de crédito"
      onClose={onCancel}
      show={show}
      toolbarItems={[
        {
          toolbar: 'bottom',
          widget: 'dxButton',
          location: 'after',
          options: {
            text: 'Guardar',
            type: 'default',
            onClick: handleOnOk,
          },
        },
        {
          toolbar: 'bottom',
          widget: 'dxButton',
          location: 'after',
          options: {
            text: 'Cancelar',
            type: 'danger',
            onClick: onCancel,
          },
        },
      ]}
    >
      <ValidationGroup id={`valGroupCuotasCredito`} ref={validationGroupRef}>
        <CCard className={`mb-3 border-top-secondary border-top-3`}>
          <CCardBody>
            <RowContainer>
              {!primeraCuotaCOnFechaCmp && (
                <CustomCol xs="12" md="6" lg="3" xxl="2">
                  <Labeled label="Fecha Calculo">
                    <DateBox
                      disabledDates={isDisabledDate}
                      value={fechaCalculo}
                      onValueChanged={(evt) => {
                        if (evt?.event !== undefined) {
                          if (typeof evt?.value === 'string') {
                            if (
                              !isDisabledDate({
                                date: DateUtils.strDateToDate(
                                  evt?.value,
                                  formatoFechasDatePickers,
                                ),
                              })
                            ) {
                              setFechaCalculo(evt?.value)
                              calcularCuotas(
                                numeroCuotas,
                                evt?.value,
                                primeraCuotaCOnFechaCmp,
                                periodo,
                                numeroPeriodosCredito,
                              )
                            }
                            /*
                            else {
                              dispatch(addToast({
                                content: 'Fecha no válida',
                                type: ToastTypes.Warning
                              }))
                            }
                            */
                          } else {
                            setFechaCalculo(
                              DateUtils.dateToString(
                                evt?.value ?? new Date(),
                                formatoFechasDatePickers,
                              ),
                            )
                          }
                        }
                      }}
                    />
                  </Labeled>
                </CustomCol>
              )}
              <CustomCol xs="12" md="6" lg="2" xxl="1">
                <Labeled label="Periodo">
                  <Switch
                    width={60}
                    switchedOnText="Días"
                    switchedOffText="Meses"
                    value={periodo === 'Días'}
                    onValueChanged={(e) => {
                      if (e.event !== undefined) {
                        const periodo = e.value === true ? 'Días' : 'Meses'
                        setPeriodo(periodo)
                        setNumeroPeriodosCredito(e.value === true ? 30 : 1)
                        calcularCuotas(
                          numeroCuotas,
                          fechaCalculo,
                          primeraCuotaCOnFechaCmp,
                          periodo,
                          periodo === 'Días' ? 30 : 1,
                        )
                      }
                    }}
                  />
                </Labeled>
              </CustomCol>
              <CustomCol xs="12" md="6" lg="3" xxl="2">
                <Labeled
                  label={periodo === 'Días' ? '# Días Cuota' : '# Meses cuota'}
                >
                  <NumberBox
                    value={numeroPeriodosCredito}
                    onValueChanged={(evt) => {
                      if (evt?.event !== undefined) {
                        setNumeroPeriodosCredito(evt?.value)
                        calcularCuotas(
                          numeroCuotas,
                          fechaCalculo,
                          primeraCuotaCOnFechaCmp,
                          periodo,
                          evt?.value,
                        )
                      }
                    }}
                    min={1}
                    step={periodo === 'Días' ? 15 : 1}
                    showSpinButtons
                    max={periodo === 'Días' ? 1000 : 600}
                  />
                </Labeled>
              </CustomCol>
              <CustomCol xs="12" lg="6" xxl="3">
                <Labeled label="Primera cuota con fecha comprobante">
                  <Switch
                    value={primeraCuotaCOnFechaCmp}
                    onValueChanged={(e) => {
                      if (e.event !== undefined) {
                        setprimeraCuotaCOnFechaCmp(e.value)
                        calcularCuotas(
                          numeroCuotas,
                          fechaCalculo,
                          e.value,
                          periodo,
                          numeroPeriodosCredito,
                        )
                      }
                    }}
                  />
                </Labeled>
              </CustomCol>
            </RowContainer>

            <RowContainer>
              <CustomCol xs="6" lg="4" xxl="2">
                <Labeled label="Total">
                  <NumberBox
                    readOnly={true}
                    format={{ precision: 2 }}
                    value={totalCobrar}
                  />
                </Labeled>
              </CustomCol>
              <CustomCol xs="6" xl="2">
                <Labeled label="Descuadre">
                  <NumberBox
                    readOnly={true}
                    format={{ precision: 2 }}
                    value={descuadre}
                  >
                    <Validator>
                      <AsyncRule
                        reevaluate
                        validationCallback={validateRetencion}
                      >
                        {' '}
                      </AsyncRule>
                    </Validator>
                  </NumberBox>
                </Labeled>
              </CustomCol>
            </RowContainer>
            <RowContainer>
              <CustomCol xs="3" sm="8" md="6" lg="4" xxl="3">
                <Labeled label="Numero Cuotas">
                  <NumberBox
                    min={empresa?.cuotasVenta ?? 0}
                    showSpinButtons
                    value={numeroCuotas}
                    onValueChanged={(e) => {
                      setNumeroCuotas(e.value)
                      calcularCuotas(
                        e.value,
                        fechaCalculo,
                        primeraCuotaCOnFechaCmp,
                        periodo,
                        numeroPeriodosCredito,
                      )
                    }}
                    width={'100%'}
                  >
                    <NumberBoxButton name="spins" />
                    <NumberBoxButton
                      name="currency"
                      location="after"
                      options={{
                        text: 'Calcular',
                        onClick: () =>
                          calcularCuotas(
                            numeroCuotas,
                            fechaCalculo,
                            primeraCuotaCOnFechaCmp,
                            periodo,
                            numeroPeriodosCredito,
                          ),
                        stylingMode: 'contained',
                        type: 'default',
                        width: 80,
                      }}
                    />
                  </NumberBox>
                </Labeled>
              </CustomCol>
            </RowContainer>
            <RowContainer>
              <CustomCol xs="12" className="mt-2">
                <DataGrid
                  keyExpr="numero"
                  focusedRowEnabled={true}
                  dataSource={cuotasIngresadas}
                  showBorders
                  repaintChangesOnly
                  onSaving={onSaving}
                >
                  <Editing
                    mode="cell"
                    allowUpdating
                    changes={changes}
                    onChangesChange={onChangesChange}
                    editRowKey={editRowKey}
                    onEditRowKeyChange={onEditRowKeyChange}
                  />
                  <Column
                    dataField="numero"
                    width="30px"
                    caption="#"
                    allowEditing={false}
                    allowSearch={false}
                    allowFiltering
                  />
                  <Column
                    dataField="vencimiento"
                    dataType="date"
                    setCellValue={setFechaVencimientoValue}
                    calculateCellValue={convertirFechaVencimiento}
                    width="100px"
                    caption="Vencimiento"
                    allowEditing={true}
                    allowSearch={false}
                    allowFiltering
                  >
                    <AsyncRule validationCallback={validateVencimiento} />
                  </Column>
                  <Column
                    dataField="letra"
                    width="*"
                    minWidth="100px"
                    caption="Letra"
                    allowEditing={false}
                    allowSearch={false}
                    allowFiltering
                  />
                  <Column
                    dataField="cuota"
                    width="80px"
                    allowEditing={true}
                    setCellValue={setCuotaValue}
                  />
                  <Column dataField="saldo" width="80px" allowEditing={false} />
                  <Column
                    dataField="estado"
                    width="100px"
                    allowEditing={false}
                  />
                </DataGrid>
              </CustomCol>
            </RowContainer>
          </CCardBody>
          {descuadre !== 0 && (
            <CCardFooter>
              <RowContainer>
                <CustomCol>
                  <ValidationSummary validationGroup="valGroupCuotasCredito" />
                </CustomCol>
              </RowContainer>
            </CCardFooter>
          )}
        </CCard>
      </ValidationGroup>
    </CustomModalDevx>
  )
}
