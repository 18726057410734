import { RequestHelper } from '../../../../../helpers/requestHelper'

export const tiposClientesServices = {
  getTypesCustomers,
  setTypeCustomer,
  deleteTypeCustomer,
}

async function getTypesCustomers(description: any): Promise<any> {
  try {
    const data: any = {
      descripcion: description,
    }
    const res = await RequestHelper.get<any>(
      'clientes',
      'clientType/getAll',
      '',
      data,
    )
    return res
  } catch (error) {
    return error
  }
}
async function setTypeCustomer(objeto: any): Promise<any> {
  try {
    const obj = {
      codigo: objeto['codigo'],
      descripcion: objeto['descripcion'],
      porcentaje: objeto['porcentaje'],
      estado: objeto['estado'],
    }
    const data = await RequestHelper.postUrlEncodedAll<any>(
      'clientes',
      'clientType/save',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}

async function deleteTypeCustomer(typeCustomerCode: number): Promise<any> {
  try {
    const obj = {
      codigo: typeCustomerCode,
    }
    const data = await RequestHelper.deleteRequestAll<any>(
      'clientes',
      'clientType/inactive',
      '',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}
