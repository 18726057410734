import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { DateUtils } from "../../../../../helpers/dateUtils"
import { FETCH_STATUS } from "../../../../../store/types"
import { Aplicacion } from "../../../store/types"
import { PayState } from "../types/types"
import { Seleccionado } from "../../../../bancos/store/types"
import { AbonarXPagar } from "../components/abonar/service/abonarxPagar.service"
import { cuotasCreditosService } from "../../../../componentes/modalCreditos/creditosCompras/services/cuotasCreditosServices"

const optionsAbono = [
  { value: 0, label: 'Por Proveedor' },
  { value: 1, label: 'Por varios Proveedores' },
]

const initialState: PayState = {
  modulo: 'Proveedores',
  currentAction: '',
  currentFunction: '',
  currentExecute: null,
  proveedor: null,
  local: null,
  puntoVenta: null,
  aplicacion: Aplicacion.cuentasXPagar,
  loader: {
    show: false,
    mensaje: '',
  },
  mensaje: '',
  incomeCode: 0,
  fecha: DateUtils.getCurrentDateAsString(),
  proveedorVarios: optionsAbono[1],
  formasPago: null,
  concepto: '',
  dpPagosE: [],
  pagosMetodosGuardar: [],
  dpFacura: [],
  dpPagos: [],
  facturaActual: null,
  totalPendientes: 0,
  cuotaActual: null,
  valorAbonos: 0,
  totalCuotas: 0,
  cuotaPagarActual: null,
  valorPagos: 0,
  status: FETCH_STATUS.IDDLE,
  seleccionado: {
    index: null,
    row: null,
    selectedRow: null,
  }
}

export const fetchAbonar = createAsyncThunk<
  Array<any>,
  number
>('proveedores/documentosPorPagar/invoiceByPay', async (codigo) => {
  try {
    const abonarApi = await AbonarXPagar.BillsPayable(codigo)
    return abonarApi
  } catch (error) {
    return Promise.reject(error)
  }
})
export const fetchAbonarCuotasCompra = createAsyncThunk<
  Array<any>,
  number
>('proveedores/general/paymentsPurchases/getBuyPayment', async (codigo) => {
  try {
    const abonarCCApi = await cuotasCreditosService.cargarCuotasCompra(codigo)
    return abonarCCApi
  } catch (error) {
    return Promise.reject(error)
  }
})


const pay = createSlice({
  name: 'documentosCtasPagarAbonos',
  initialState: initialState,
  reducers: {
    setDatosAbonos(state, action: PayloadAction<PayState>) {
      state.modulo = action.payload.modulo
      state.currentAction = action.payload.currentAction
      state.currentFunction = action.payload.currentFunction
      state.proveedor = action.payload.proveedor
      state.local = action.payload.local
      state.aplicacion = action.payload.aplicacion
      state.loader = action.payload.loader
      state.pagosMetodosGuardar = action.payload.pagosMetodosGuardar
      state.incomeCode = action.payload.incomeCode
      state.fecha = action.payload.fecha
      state.proveedorVarios = action.payload.proveedorVarios
      state.formasPago = action.payload.formasPago
      state.concepto = action.payload.concepto
      state.dpPagosE = action.payload.dpPagosE
      state.dpFacura = action.payload.dpFacura
      state.dpPagos = action.payload.dpPagos
      state.facturaActual = action.payload.facturaActual
      state.totalPendientes = action.payload.totalPendientes
      state.cuotaActual = action.payload.cuotaActual
      state.puntoVenta = action.payload.puntoVenta
      state.valorAbonos = action.payload.valorAbonos
      state.totalCuotas = action.payload.totalCuotas
      state.cuotaPagarActual = action.payload.cuotaPagarActual
      state.valorPagos = action.payload.valorPagos
    },
    setInitData(state) {
      state.currentAction = '',
        state.currentFunction = '',
        state.currentExecute = null,
        state.proveedor = null,
        state.local = null,
        state.puntoVenta = null,
        state.incomeCode = 0,
        state.fecha = DateUtils.getCurrentDateAsString(),
        state.proveedorVarios = optionsAbono[1],
        state.formasPago = null,
        state.concepto = '',
        state.dpPagosE = [],
        state.pagosMetodosGuardar = [],
        state.dpFacura = [],
        state.dpPagos = [],
        state.facturaActual = null,
        state.totalPendientes = 0,
        state.cuotaActual = null,
        state.valorAbonos = 0,
        state.totalCuotas = 0,
        state.cuotaPagarActual = null,
        state.valorPagos = 0
    },
    cleanData(state) {
      state.dpPagosE = [],
        state.dpFacura = [],
        state.dpPagos = []
    },
    setResetSeleccion(state) {
      const toAdd = {
        index: null,
        row: null,
        selectedRow: null,
      }
      state.seleccionado = toAdd
    },
    setSeleccionarDato(state, action: PayloadAction<Seleccionado>) {
      const toAdd = {
        index: action.payload.index,
        row: action.payload.row,
        selectedRow: action.payload.selectedRow,
      }
      state.seleccionado = toAdd
    },
    setCurrentAction(state, action: PayloadAction<string>) {
      state.currentAction = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAbonar.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchAbonar.fulfilled, (state, { payload }) => {
      state.dpFacura = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchAbonar.rejected, (state, { payload }) => {
      state.dpFacura = []
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
    builder.addCase(fetchAbonarCuotasCompra.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchAbonarCuotasCompra.fulfilled, (state, { payload }) => {
      state.dpPagos = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchAbonarCuotasCompra.rejected, (state, { payload }) => {
      state.dpPagos = []
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
  }
})

export const { setDatosAbonos, setCurrentAction, setInitData, cleanData, setSeleccionarDato, setResetSeleccion } = pay.actions
export const payReducer = pay.reducer