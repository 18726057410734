import { RequestHelper } from '../../../../../helpers/requestHelper'
import { SesionService } from '../../../../../services/sesion.service'
import { FormaPagoModuloVentas } from '../../../../ventas/types/types'
import { AsignacionesFormasClientes, ClientesListado } from '../types/types'

export const ClientesService = {
  getClientes,
  exportarDemo,
  plantillaImportar,
  getAsignacionesClientes,
  getFormasPago,
  saveCustomer,
  getDetailsCustomer,
  getRetenciones,
  getCustomer,
  getSucursales,
  getLineasCliente,
  getGruposCliente,
  getMarcasCliente,
  deleteCliente
}

async function getClientes(
  identificacion: string,
  nombre: string,
  rcomercial: string,
  tipoId: any,
  empresa: string,
  saldos: number,
  estado: boolean,
  ciudad: any,
  origen: any,
): Promise<Array<ClientesListado>> {
  const data: any = {
    codigo: '',
    identificacion: identificacion ?? '',
    nombre: nombre ?? '',
    rcomercial: rcomercial ?? '',
    empresa: empresa ?? '',
    saldos: saldos ?? '',
    estado: estado ?? false,
    ciudad: ciudad?.codigo ?? -1,
    origen: origen ? origen?.opcion : '',
    tipoId: tipoId?.codigo ?? '-1',
  }

  const clientes = await RequestHelper.get<Array<ClientesListado>>('clientes', 'clientes/listar', '', data)

  return clientes
}

function exportarDemo(fileName) {
  const config = RequestHelper.getConfig('GET')
  const sesion = SesionService.getCurrentSesion()
  const datosSearch = {
    archivo: fileName,
    paisCodigo: sesion?.empresa?.codigoPais ?? 1,
  }
  const response = fetch(RequestHelper.getRequestUrl('generales', 'downloadFile', null, datosSearch), {
    method: 'get',
    headers: config.headers,
  })
  return response
}

async function plantillaImportar(objeto: any): Promise<any> {
  const sesion = SesionService.getCurrentSesion()
  const fileUpload: any = {
    local: sesion.local.codigo,
    archivo: objeto.file,
    tipo: 'archivo',
    paisCodigo: sesion?.empresa?.codigoPais ?? 1,
  }
  const data = await RequestHelper.postData<any>('clientes', 'importar', fileUpload)
  return data
}

async function getAsignacionesClientes(codigo: number): Promise<Array<AsignacionesFormasClientes>> {
  const data: any = {
    codigo: codigo,
  }

  const asignaciones = await RequestHelper.get<Array<AsignacionesFormasClientes>>(
    'contabilidad/assignments',
    'getClientAssignments',
    '',
    data,
  )

  return asignaciones
}

async function getFormasPago(grupo: string): Promise<Array<FormaPagoModuloVentas>> {
  const data: any = {
    grupo: grupo,
  }

  const apiData = await RequestHelper.get<Array<any>>('generales/subtypes', 'cboxLoadFP', '', data)

  const formasPago: Array<FormaPagoModuloVentas> = []
  for (const formaPagoApi of apiData) {
    formasPago.push({
      codigo: parseInt(formaPagoApi.codigo),
      descripcion: formaPagoApi.descripcion,
    })
  }

  return formasPago
}

async function saveCustomer(requestBody: any): Promise<any> {
  try {
    const infoRegistro: any = {
      infoRegistro: {
        ...requestBody,
      },
    }
    const data = await RequestHelper.postAll<any>('clientes', 'save', infoRegistro)
    return data
  } catch (error) {
    return error
  }
}

async function getCustomer(code: any): Promise<any> {
  try {
    const queryParams: any = {
      ...code,
    }
    const res = await RequestHelper.get<any>('clientes', 'getCode', '', queryParams)
    return res
  } catch (error) {
    return error
  }
}

async function getDetailsCustomer(id: number): Promise<any> {
  try {
    const queryParams: any = {
      codigo: id,
      tipo: 'CLIENTE'
    }
    const res = await RequestHelper.get<any>('clientes', 'getDetall', '', queryParams)
    return res
  } catch (error) {
    return error
  }
}

async function getRetenciones(id: number, tipo: number, impuesto: number): Promise<any> {
  try {
    const queryParams: any = {
      id: id,
      tipo: tipo,
      impuesto: impuesto,
    }
    const res = await RequestHelper.getAll<any>('retProCli', 'getAll', '', queryParams)
    return res
  } catch (error) {
    return error
  }
}

async function getSucursales(id: number): Promise<any> {
  try {
    const queryParams: any = {
      codigo: id,
    }
    const res = await RequestHelper.get<any>('clientes/branches', 'getAll', '', queryParams)
    return res
  } catch (error) {
    return error
  }
}

async function getLineasCliente(id: number): Promise<any> {
  try {
    const queryParams: any = {
      codigoCliente: id,
    }
    const res = await RequestHelper.get<any>('clientes', 'clienteLinea/getAll', '', queryParams)
    return res
  } catch (error) {
    return error
  }
}

async function getGruposCliente(id: number): Promise<any> {
  try {
    const queryParams: any = {
      codigoCliente: id,
    }
    const res = await RequestHelper.get<any>('clientes', 'clientGroup/getAll', '', queryParams)
    return res
  } catch (error) {
    return error
  }
}

async function getMarcasCliente(id: number): Promise<any> {
  try {
    const queryParams: any = {
      codigoCliente: id,
    }
    const res = await RequestHelper.get<any>('clientes', 'clientBrand/getAll', '', queryParams)
    return res
  } catch (error) {
    return error
  }
}

async function deleteCliente(codigoCliente: number) {
  try {
    const queryData: any = {
      codigo: codigoCliente,
    }
    const apiData = await RequestHelper.deleteRequestAll<any>(
      'clientes',
      'inactive',
      '',
      queryData,
    )
    return apiData
  } catch (error) {
    return error
  }
}