import React from 'react'
import ReactDom from 'react-dom';
import CustomCol from '../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/store'
import CabeceraFiltroBusqueda from '../shared/cabeceraFiltroBusqueda'
import { CheckBox, DateBox, TextBox } from 'devextreme-react'
import { CCard, CCardBody } from '@coreui/react-pro'
import { CustomButtons, FETCH_STATUS, ToastTypes } from '../../../../../../store/types'
import { clearButtonClick, setCurrentExecutingAction } from '../../store/tabsReducer'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import { addToast } from '../../../../../../store/toasterReducer'
import { changeFilter, fetchAbonosPagar, setDeleteItem } from '../../store/seeSubscriptionsReducer'
import { VerAbonosService } from './services/verAbonos.service'
import { DateUtils } from '../../../../../../helpers/dateUtils'
import ModalAsiento from '../../../../../contabilidad/pages/mayorGeneral/componentes/modalAsiento'
import { AccountingEntry } from '../../../../../clientes/pages/docsCtasXCobrar/types/types'
import { generateAcoountEntry } from '../../../../../clientes/pages/docsCtasXCobrar/store/abonosReducer'
import { MessagesKeys, lh } from '../../../../../../helpers/localizationHelper'
import { ReportWithLocalDataModal } from '../../../../../../views/componentes/xtrareports/ReportWithLocalDataModal'
import { Abonar, Asientos, Cheques, Compra, Egreso, EgresosCajaDto, FilterSubscriptionssState } from '../../types/types'
import { DocumentsToPayService } from '../../services/docsCtasXPagar.services'
import { ModalArchivosDigitales } from '../../../../../componentes/modalArchivosDigitales'
import ModalTarjetas from '../../../../../componentes/modalTarjetas';
import { EgresoTipo } from '../../../../store/types';
import { cleanFilter } from '../../store/searchReducer';

interface IFiltroBusquedaProps extends React.PropsWithChildren {
  tab
  tabId
  setShowLoader: (data: boolean) => void
}

const FiltroBusqueda: React.FunctionComponent<IFiltroBusquedaProps> = (
  props,
) => {
  const { tab, tabId } = props
  const dispatch = useDispatch()

  const filterGlobal = useSelector((state: RootState) => state.proveedores.documentosCuentasPorPagar.search.filter)
  const modulo = useSelector((state: RootState) => state.proveedores.documentosCuentasPorPagar.configuraciones.modulo)
  const filter = useSelector((state: RootState) => state.proveedores.documentosCuentasPorPagar.seeSubscriptions.filter)
  const periodoActual = useSelector(
    (state: RootState) => state.shared.providers.periodoContable,
  )
  const verAbono = useSelector((state: RootState) => state.proveedores.documentosCuentasPorPagar.seeSubscriptions)
  const seleccionado = useSelector((state: RootState) => state.proveedores.documentosCuentasPorPagar.seeSubscriptions.seleccionado)

  const [showImportButton, setShowImportButton] = React.useState<boolean>(false)
  const [showCreditCard, setShowCreditCard] = React.useState<boolean>(false)
  const [showModalsiento, setShowModalsiento] = React.useState<boolean>(false)
  const [resultadoImprimir, setResultadoImprimir] = React.useState<any>({})
  const [comprobante, setComprobante] = React.useState<string>(null)
  const [egreso, setEgreso] = React.useState<string>(null)
  const [cheque, setCheque] = React.useState<string>(null)
  const [fechaDesde, setFechaDesde] = React.useState<Date>(new Date(new Date().getFullYear(), 0, 1))
  const [fechaHasta, setFechaHasta] = React.useState<Date>(new Date(new Date().getFullYear() + 1, 11, 31))
  const [anulados, setAnulados] = React.useState<boolean>(false)
  const [impTodos, setImpTodos] = React.useState<boolean>(false)
  const [reporte, setReporte] = React.useState<null | "Viewer" | "Designer">(null)
  const [showModalDA, setShowModalDA] = React.useState(false);

  const formatoFecha: string = 'dd/MM/yyyy';

  const getFilterString = React.useCallback((filter: FilterSubscriptionssState) => {
    const filterText = "";

    return filterText;
  }, [])

  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(
      addToast({
        title: 'Ver Abonos',
        content: mensaje,
        type: tipo,
      }),
    )
  }, [dispatch])

  const onChangeData = React.useCallback((data, key) => {
    if (data.value !== null && data.value !== undefined) {
      let makeDispatch: boolean = true
      switch (key) {
        case 'comprobante':
          setComprobante(data.value)
          break;
        case 'egreso':
          setEgreso(data.value)
          break;
        case 'cheque':
          setCheque(data.value)
          break;
        case 'fechaDesde':
          setFechaDesde(data.value)
          break;
        case 'fechaHasta':
          setFechaHasta(data.value)
          break;
        case 'anulados':
          setAnulados(data.value)
          break;
        case 'impTodos':
          setImpTodos(data.value)
          break;

        default:
          makeDispatch = false
          break;
      }
      if (makeDispatch) {
        let value = null
        if (key === 'fechaDesde' || key === 'fechaHasta') {
          value = DateUtils.dateToString(data.value, formatoFecha)
        } else {
          value = data.value
        }
        dispatch(changeFilter({
          ...filter,
          [key]: value
        }))
      }
    }
  }, [dispatch, filter])


  const onBroom = React.useCallback(() => {
    dispatch(cleanFilter())
    setResultadoImprimir({})
    setComprobante(null)
    setEgreso(null)
    setCheque(null)
    setFechaDesde(periodoActual.length > 0 ? DateUtils.strDateToDate(periodoActual[0].finicio, "dd/MM/yyyy") : new Date())
    setFechaHasta(periodoActual.length > 0 ? DateUtils.strDateToDate(periodoActual[0].fcierre, "dd/MM/yyyy") : new Date())
    setAnulados(false)
    setImpTodos(false)
  }, [periodoActual, dispatch])

  const onGenerateEntry = React.useCallback(async (generate: boolean) => {
    if (generate && seleccionado.row !== null && seleccionado.row !== undefined) {
      const data = seleccionado.row
      const payments: Array<{ codigo: number; valor: number }> = [
        { codigo: -1, valor: 0 }
      ]
      const accountingEntry: AccountingEntry = {
        infoRegistro: {
          movimiento: 'ABONO PROVEEDOR',
        },
        fecha: data.abonoVentaFecha,
        descripcion: `Abono registrado por: ${data.proveedorNombre}`,
        numeroDocumento: data.egresoCodigo,
        diarioNumero: periodoActual.length > 0 ? periodoActual[0].diario : 0, //GET THE NUMBER OF ACCOUNT PERIOD
        localCodigo: data.localCodigo ?? -1,
        formaPago: payments,
      }

      try {
        const toAny: any = generateAcoountEntry(accountingEntry)
        const res = await dispatch(toAny)
        if (res !== null && res.payload['error'] !== true) {
          setToast(res.payload['message'], ToastTypes.Success)
        } else {
          setToast(res.payload['message'], ToastTypes.Danger)
        }
      } catch (error) {
        setToast(error.message, ToastTypes.Danger)
      }
    }

  }, [setToast, dispatch, periodoActual, seleccionado])

  const deleteRegistroTabla = React.useCallback(() => {
    if (seleccionado.row !== null && seleccionado.row !== undefined) {
      dispatch(setDeleteItem(true))
    }
  }, [seleccionado.row, dispatch])

  const onFindSubs = React.useCallback(async () => {
    try {
      const toAny: any = fetchAbonosPagar(filter)
      const res = await dispatch(toAny)
      if (res !== null && res !== undefined && res.payload && res.payload['error'] === false) {
        setToast(res.payload['message'], ToastTypes.Success)
      } else {
        setToast(res.payload['message'], ToastTypes.Danger)
      }
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
  }, [filter, dispatch, setToast])

  const ooDeleteSubs = React.useCallback(async () => {
    if (seleccionado.row !== null && seleccionado.row !== undefined) {
      try {
        const obj = {
          proveedor: seleccionado.row?.proveedorCodigo ?? 0,
          egreso: seleccionado.row?.egresoCodigo ?? 0,
        }
        props.setShowLoader(true)
        const data = await VerAbonosService.deletePayment(obj)
        props.setShowLoader(false)
        if (data !== null && data['error'] === false) {
          deleteRegistroTabla()
          setToast(data['message'], ToastTypes.Success)
        } else {
          setToast(data['message'], ToastTypes.Danger)
        }
      } catch (error) {
        props.setShowLoader(false)
        setToast(error.message, ToastTypes.Danger)
      }
    } else {
      setToast('Seleccione un abono para anularlo', ToastTypes.Warning)
    }
  }, [seleccionado.row, setToast, deleteRegistroTabla, props])

  React.useEffect(() => {
    dispatch(changeFilter({
      ...filter,
      proveedor: filterGlobal.proveedor
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterGlobal])

  const onGetDataPrint = React.useCallback(async (codigo: number, accion: string) => {
    const valor: string = seleccionado.row.egresoValor.toString()
    if (seleccionado.row.egresoCodigo === 0 &&
      (valor.substring(0, 7) === "0 - NCr" || valor.substring(0, 7) === "0 - Ret")) {
      setToast('El abono corresponde a ua Retención o Nota de Crédito, no puede ser impreso', ToastTypes.Warning)
    } else {
      try {
        const request_search = {
          tab: impTodos ? 'ABONOS TODOS' : 'ABONOS',
          codigoAsociado: null,
          egresoCodigo: codigo,
          desde: DateUtils.dateToString(fechaDesde, formatoFecha),
          hasta: DateUtils.dateToString(fechaHasta, formatoFecha),
          tipo: "ABONO PROVEEDOR"
        }
        props.setShowLoader(true)
        const data = await DocumentsToPayService.getDataPrintPurchase(request_search)
        props.setShowLoader(false)
        if (data !== null && data !== undefined && data['error'] === false) {
          setResultadoImprimir(data['auto'])
          if (accion === 'Imprimir') {
            setReporte('Viewer')
          } else {
            setReporte('Designer')
          }
        } else {
          setToast(data['message'], ToastTypes.Danger)
        }
      } catch (error) {
        props.setShowLoader(false)
        setToast(error.message, ToastTypes.Danger)
      }
    }
  }, [props, setToast, fechaDesde, fechaHasta, seleccionado, impTodos])

  const handleButtonClick = React.useCallback((button: ButtonTypes) => {
    switch (button) {
      case ButtonTypes.find:
        onFindSubs()
        break
      case ButtonTypes.delete:
        ooDeleteSubs()
        break
      case ButtonTypes.broom:
        onBroom()
        break
      case ButtonTypes.print:
        if (seleccionado.row !== null && seleccionado.row !== undefined && seleccionado.row.egresoCodigo !== 0) {
          onGetDataPrint(seleccionado.row.egresoCodigo, 'Imprimir')
        } else {
          setToast(lh.getMessage(MessagesKeys.NotFoundRegisterSelec), ToastTypes.Warning)
        }
        break
      case ButtonTypes.print_design:
        if (seleccionado.row !== null && seleccionado.row !== undefined && seleccionado.row.egresoCodigo !== 0) {
          onGetDataPrint(seleccionado.row.egresoCodigo, 'Diseñar')
        } else {
          setToast(lh.getMessage(MessagesKeys.NotFoundRegisterSelec), ToastTypes.Warning)
        }
        break
      case ButtonTypes.digitize:
        if (seleccionado.row !== null && seleccionado.row !== undefined && seleccionado.row.egresoCodigo !== 0) {
          setShowModalDA(true)
        } else {
          setToast(lh.getMessage(MessagesKeys.NotFoundRegisterSelec), ToastTypes.Warning)
        }
        break
      case ButtonTypes.import:
        setShowImportButton(true)
        break
      case ButtonTypes.verAsiento:
        if (seleccionado.row !== null && seleccionado.row !== undefined && !isNaN(seleccionado.row.egresoCodigo) && seleccionado.row.egresoCodigo !== 0) {
          setShowModalsiento(true)
        } else {
          setToast(lh.getMessage(MessagesKeys.NotFoundRegisterSelec), ToastTypes.Warning)
        }
        break
      default:
        break
    }
    dispatch(clearButtonClick(tabId))
  }, [dispatch, tabId, seleccionado.row, setToast, onFindSubs, ooDeleteSubs, onGetDataPrint, onBroom])

  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
  }, [handleButtonClick, tab.globalButtonClick])

  React.useEffect(() => {
    setFechaDesde(periodoActual.length > 0 ? DateUtils.strDateToDate(periodoActual[0].finicio, "dd/MM/yyyy") : new Date())
    setFechaHasta(periodoActual.length > 0 ? DateUtils.strDateToDate(periodoActual[0].fcierre, "dd/MM/yyyy") : new Date())
  }, [periodoActual])

  const getParseData = React.useCallback((data: any = {}) => {
    const { asientos, compra, egreso, abonos } = data
    const registros: Array<EgresosCajaDto> = []

    let abonosArray: Array<Abonar> = []
    let asientosArray: Array<Asientos> = []
    let chequesArray: Array<Cheques> = []
    let egresoObj: Egreso
    let compraObj: Compra
    if (egreso !== null && egreso !== undefined) {

      egresoObj = {
        codigo: parseInt(egreso?.egresoCodigo) ?? 0,
        numero: egreso?.egresoNumero ?? "",
        tipo: egreso?.egresoTipo ?? "",
        asociado: parseInt(egreso?.egresoAsociado) ?? 0,
        aceptado: "",
        valor: parseInt(egreso.egresoValor) ?? null,
        fecha: egreso?.egresoFecha ?? "",
        concepto: egreso.egresoConcepto ?? "",
        estadoCodigo: parseInt(egreso?.egresoEstado) ?? 0,
        estadoDescripcion: egreso?.egresoEstadoDes ?? "",
        asientoNumeroTipo: egreso?.asientoNroTipo ?? "",
        proveedorCodigo: parseInt(egreso?.egresoProveedorCodigo) ?? 0,
        localNombre: egreso?.egresoLocalNombre ?? "",
        localCodigo: parseInt(egreso?.egresoLocalCodigo) ?? 0,
        asientoNumero: egreso?.asientoNumero ?? "",
        puntoVentaCodigo: egreso?.egresoPuntoCodigo ?? "",
      }
    }

    if (compra !== null && compra !== undefined) {
      compraObj = {
        establecimiento: compra?.compraEstablecimiento ?? "",
        nombreUsuario: compra?.nombreUsuario ?? "",
        proveedorCodigo: compra?.proveedorCodigo ?? "",
        proveedorNombre: compra?.proveedorNombre ?? "",
        proveedorIdentificacion: compra?.proveedorIdentificacion ?? "",
      }
    }

    if (asientos !== null && asientos !== undefined && asientos.length > 0) {
      asientosArray = data.asientos.map((asiento: any) => {
        const asientoObj: Asientos = {
          asiNmero: 0,
          planCtaCodigo: 0,
          planCtaNumero: asiento?.planNumero ?? "",
          planCtaDescripcion: asiento?.planDescripcion ?? "",
          planCtaDescripcionIdioma: "",
          localCodigo: 0,
          localNombre: "",
          transaccionReferencia: asiento?.transaccionReferencia ?? "",
          transaccionDebe: asiento?.transaccionDebe !== "" ? parseInt(asiento?.transaccionDebe) : 0,
          transaccionHaber: asiento?.transaccionHaber !== "" ? parseInt(asiento?.transaccionHaber) : 0,
          siglasLocal: "",
        }

        return asientoObj
      })
    }

    if (abonos !== null && abonos !== undefined && abonos.length > 0) {
      abonosArray = abonos.map((abono: any) => {
        const abonoObj: Abonar = {
          cuota: abono?.CUOTA ?? "",
          abono: abono?.ABONO !== "" ? parseInt(abono?.ABONO) : 0,
          comprobante: abono?.COMPROBANTE ?? "",
          numero: abono?.NUMERO ?? "",
          proveedorNombre: compraObj.proveedorNombre,
        }

        return abonoObj
      })
    }

    if (data.cheques !== null && data.cheques !== undefined && data.cheques.length > 0) {
      chequesArray = data.cheques.map((cheque: any) => {
        const chequeObj: Cheques = {
          codigo: parseInt(cheque?.codigo) ?? 0,
          numero: cheque?.Numero,
          fechaRegistro: cheque?.registro ?? "",
          fechaCobro: cheque?.fcobro ?? "",
          cuentaBancoCodigo: parseInt(cheque?.cbaCodigo) ?? 0,
          cuentaBancoNombre: cheque?.banNombre ?? "",
          cuentaBancoNumero: cheque?.cbaNumero ?? "",
          bancoNombre: cheque?.banNombre ?? "",
          perteneceCod: "",
          perteneceDescripcion: cheque?.cbaPertenece ?? "",
          chequePago: cheque?.formaPago ?? "",
          chequeAfavor: cheque?.aFavor ?? "",
          chequeAValor: cheque?.valor ?? "",
          xomprobante: cheque?.comprobante ?? "",
          formaPagoValor: cheque?.fpaValor ?? "",
          chequePagos: cheque?.pagos ?? "",
          chequeValor: parseInt(cheque?.valor) ?? 0,
          fpsCodigo: cheque?.fpsCodigo ?? "",
          chequeEstadoCodigo: cheque?.estado ?? "",
          chequeEstadoDescripcion: cheque?.estadoDes ?? "",
          chequeTransaccion: cheque?.transaccion ?? "",
          chequeDescripcion: cheque?.descripcion ?? "",
        }

        return chequeObj
      })
    }

    const egresoCaja: EgresosCajaDto = {
      EgresoTipo: EgresoTipo.abono,
      egreso: egresoObj,
      compra: compraObj,
      abonos: abonosArray,
      cheques: chequesArray.length > 0 ? chequesArray : null,
      asientos: asientosArray,
      formaPago: data?.formaPago ?? '',
      usuario: compraObj?.nombreUsuario ?? '',
      fecha: egresoObj?.fecha ?? '',
      elaborado: data?.elaborado ?? '',
      modificado: data?.modificado ?? '',
      concepto: egresoObj?.concepto ?? '',
      descripcion: data?.asientoDescripcion ?? '',
      totalDebe: parseInt(data?.asientoTotalDebe) ?? 0,
      totalHaber: parseInt(data?.asientoTotalHaber) ?? 0,
    }
    registros.push(egresoCaja)
    return registros
  }, [])

  const onCloseCreditCardsModal = React.useCallback(() => {
    setShowCreditCard(false)
  }, [])

  const calcularTotalLote = React.useCallback(() => {
    console.log('XXXXX')
  }, [])

  const setLote_lb = React.useCallback((data) => {
    console.log('CC', data)
  }, [])

  React.useEffect(() => {
    if (verAbono.status === FETCH_STATUS.FAILED) {
      dispatch(
        addToast({
          title: 'Ver Abono',
          content: verAbono.mensaje ?? lh.getMessage(MessagesKeys.GlobalButtonSearchError),
          type: ToastTypes.Danger,
        }),
      )
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'VERABONOS',
          buttonType: undefined,
        }),
      )
    }
    if (verAbono.status === FETCH_STATUS.LOADING) {
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'VERABONOS',
          buttonType: ButtonTypes.find,
        }),
      )
    }
    if (verAbono.status === FETCH_STATUS.SUCCESS) {
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'VERABONOS',
          buttonType: undefined,
        }),
      )
    }
  }, [dispatch, verAbono.status, verAbono.mensaje])

  const openModalDigitalizarArchivos = React.useCallback(() => {
    return (
      <ModalArchivosDigitales
        onChanged={(data) => { }}
        onCancel={() => setShowModalDA(false)}
        show={showModalDA}
        modulo={tabId}
        codModulo={modulo}
        chequeNumero={seleccionado.row.egresoCodigo ?? 0}
      />
    )
  }, [tabId, showModalDA, modulo, seleccionado.row])

  return (
    <>
      {showModalDA && openModalDigitalizarArchivos()}
      <ReportWithLocalDataModal
        show={reporte !== null}
        onClose={() => setReporte(null)}
        data={getParseData(resultadoImprimir)}
        fileName='StaticEgresosCaja'
        mode={reporte ?? 'Viewer'}
        parameters={{ 'Reporte_Filtro': getFilterString(filter) }}
        template='StaticEgresosCaja'
        key='reportDesigner'
      />

      {
        showModalsiento && (

          <ModalAsiento
            show={showModalsiento}
            onClose={() => setShowModalsiento(false)}
            infoAsiento={seleccionado.row.egresoCodigo}
            origenModulo={Number(modulo)}
            onGenerateEntry={(generate) => onGenerateEntry(generate)}
          />

        )
      }
      {showCreditCard && (
        <ModalTarjetas
          show={showCreditCard}
          onClose={onCloseCreditCardsModal}
          parametros={() => { }}
          pathVerificador={''}
          ejecutaAccion={calcularTotalLote}
          setTotales={(data) => setLote_lb(data)}
          onSelected={(data) => {
            console.log('tarjetas onSelected', data)
          }}
        />
      )}
      <div id="busquedaVerAbonos">
        <CCard>
          <CCardBody>
            <CabeceraFiltroBusqueda seleccionado={seleccionado} />
            <RowContainer>
              <CustomCol xs="12" md="4">
                <Labeled label="Comprobante #:">
                  <TextBox
                    onValueChanged={(data) => onChangeData(data, 'comprobante')}
                    value={comprobante}
                    showClearButton
                  />
                </Labeled>
              </CustomCol>
              <CustomCol xs="12" md="4">
                <Labeled label="Egreso #:">
                  <TextBox
                    value={egreso}
                    onValueChanged={(data) => onChangeData(data, 'egreso')}
                    showClearButton
                  />
                </Labeled>
              </CustomCol>
              <CustomCol xs="12" md="4" className="d-flex align-items-center">
                <Labeled label="Cheque #:">
                  <TextBox
                    value={cheque}
                    onValueChanged={(data) => onChangeData(data, 'cheque')}
                    showClearButton
                  />
                </Labeled>
              </CustomCol>
            </RowContainer>
            <RowContainer>
              <CustomCol xs="12" md="3">
                <Labeled label="Desde:">
                  <DateBox
                    type="date"
                    value={fechaDesde}
                    onValueChanged={(data) => onChangeData(data, 'fechaDesde')}
                  />
                </Labeled>
              </CustomCol>
              <CustomCol xs="12" md="3">
                <Labeled label="Hasta:">
                  <DateBox
                    type="date"
                    value={fechaHasta}
                    onValueChanged={(data) => onChangeData(data, 'fechaHasta')}
                  />
                </Labeled>
              </CustomCol>
              <CustomCol xs="12" md="2" className="d-flex align-items-center">
                <CheckBox
                  text="Anulados"
                  onValueChanged={(data) => onChangeData(data, 'anulados')}
                  value={anulados}
                />
              </CustomCol>
              <CustomCol xs="12" md="2" className="d-flex align-items-center">
                <CheckBox
                  text="Imprimir Todos"
                  onValueChanged={(e) => onChangeData(e, 'impTodos')}
                  value={impTodos}
                />
              </CustomCol>
            </RowContainer>
          </CCardBody>

          <div>
            <p>
              <small>
                {lh.getMessage(MessagesKeys.GlobalVerAbonosInfo)}
              </small>
            </p>
          </div>
        </CCard>
      </div>
    </>
  )
}
export default React.memo(FiltroBusqueda)

export const ModuleButtonsVerPagos: CustomButtons = {
  Nuevo: false,
  Guardar: false,
  Buscar: true,
  Cheque: true,
  Eliminar: true,
  Editar: false,
  Imprimir: true,
  Ver_asiento: true,
  Digitalizar: true,
  Proveedores: true,
  Limpiar: true,
}
