import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../../../../store/store";
import { ButtonTypes } from "../../../../../../../views/componentes/globalMenu/types";
import { StatesEdition, TabTypes } from "../../../../../types/enums";
import { CustomButtons, CustomDictionary } from "../../../../../types/generics";
import { cuadresCloseTab } from "../../../store/reducers";
import { TabStateOrdenPedido, TabsStateOrdenPedido, OrdenPedidoListado } from "../../../type/types";
import { OrdenPedidoButtons } from "../components/pedido/pedido";

const initialState: TabsStateOrdenPedido<OrdenPedidoListado> = {
  current: "BUSQUEDA",
  tabs: {
    "BUSQUEDA":
    {
      id: 0,
      canClose: false,
      editStatus: StatesEdition.doesNotApply,
      globalButtonClick: ButtonTypes.none,
      tittle: "Búsqueda",
      type: TabTypes.busqueda,
      imprimir: false,
      tabKey: "BUSQUEDA",
      buttons: {}
    },
  },
};



const existeTab = (
  tabs: CustomDictionary<TabStateOrdenPedido<any>>,
  id: number
) => {
  for (const key in tabs) {
    const tab = tabs[key];
    if (tab.id === id) return key;
  }
};

const tabsOrdenPedidoSlice = createSlice({
  name: "ordenPedidoTabs",
  initialState: initialState,
  reducers: {
    cuadresCloseTab,
    closeTab(state, action: PayloadAction<string | number>) {
      // if (typeof action.payload == "number") {
      //   if (state.current === action.payload) {
      //     //current tab closed
      //     state.current = action.payload - 1;
      //   }
      //   state.tabs.splice(action.payload, 1);
      //   state.current = state.current + 0;
      // } else {
      //   const indexTabToClose = state.tabs.findIndex(
      //     (x) => x.tabKey === action.payload
      //   );
      //   if (state.current === indexTabToClose) {
      //     //current tab closed
      //     state.current = indexTabToClose - 1;
      //   }
      //   state.tabs.splice(indexTabToClose, 1);
      //   state.current = state.current + 0;
      // }
    },
    openTab(
      state,
      action: PayloadAction<{ key: string; cuadre?: OrdenPedidoListado, templateMode?: boolean }>
    ) {

      if (action.payload.cuadre === undefined || action.payload.templateMode) {

        const tab: TabStateOrdenPedido<OrdenPedidoListado> = {
          id: -1,
          canClose: true,
          editStatus: StatesEdition.new,
          globalButtonClick: ButtonTypes.none,
          tittle: "Nueva",
          type: TabTypes.documento,
          imprimir: false,
          tabKey: action.payload.key,
          info: {
            codigo: action.payload.templateMode && action.payload.cuadre ? action.payload.cuadre.codigo : 0,
          },
          buttons: OrdenPedidoButtons,
          templateMode: action.payload.templateMode
        };
        state.tabs[action.payload.key] = tab;
        state.current = action.payload.key;
      } else {
        const tabKey = existeTab(state.tabs, action.payload.cuadre.codigo);
        if (tabKey) {
          state.current = tabKey;
        } else {
          const tab: TabStateOrdenPedido<OrdenPedidoListado> = {
            tabKey: action.payload.key,
            id: action.payload.cuadre.codigo,
            tittle: `(${action.payload.cuadre.numero})- ${action.payload.cuadre.fechaEntrega} ${action.payload.cuadre.horaEntrega}-${String(action.payload.cuadre.numero).toUpperCase()}`,
            canClose: true,
            editStatus: StatesEdition.save,
            type: TabTypes.documento,
            info: {
              codigo: action.payload.cuadre.codigo,
              info: action.payload.cuadre,
            },
            globalButtonClick: ButtonTypes.none,
            imprimir: false,
            buttons: OrdenPedidoButtons,
          };
          state.tabs[action.payload.key] = tab;
          state.current = action.payload.key;
        }
      }
    },
    changeEditStatus(
      state,
      action: PayloadAction<{
        tabKey: string;
        estado: StatesEdition;
        info?: OrdenPedidoListado;
        buttons: CustomButtons
      }>
    ) {
      // state.tabs[action.payload.tabId].editStatus = action.payload.estado;
      // if (action.payload.info) {
      //   state.tabs[action.payload.tabId].info = {
      //     claveAcceso: action.payload.info.autorizacion,
      //     numero: action.payload.info.codigo,
      //     tipoComprobante: TiposComprobantesSri.Factura,
      //     info: action.payload.info ?? null,
      //     canSendMail: false,
      //     canSendAutorizacion: false,
      //     canInactive: action.payload.info.estadoCodigo !== 2
      //   };
      //   state.tabs[action.payload.tabId].id = action.payload.info.codigo;
      //   state.tabs[
      //     action.payload.tabId
      //   ].tittle = `${action.payload.info.establecimiento}-${action.payload.info.puntoEmision}-${action.payload.info.numero}`;
      // }
    },
    buttonClick(
      state,
      action: PayloadAction<{ tabKey: string; button: ButtonTypes }>
    ) {
      state.tabs[action.payload.tabKey].globalButtonClick =
        action.payload.button;
    },
    clearButtonClick(state, action: PayloadAction<string>) {
      if (Object.prototype.hasOwnProperty.call(state.tabs, action.payload)) {
        state.tabs[action.payload].globalButtonClick = ButtonTypes.none;
      }
    },
    changeCurrentTab(state, action: PayloadAction<string>) {
      if (Object.prototype.hasOwnProperty.call(state.tabs, action.payload)) {
        state.current = action.payload;
      }
    },
    setButtons(state, action: PayloadAction<{ tabKey: string, buttons: CustomButtons }>) {
      const { buttons, tabKey } = action.payload;
      state.tabs[tabKey].buttons = buttons;
      state.tabs[tabKey].currentExecutingAction = undefined;
    },

    setCurrentExecutingAction(state, action: PayloadAction<{ tabKey: string, buttonType: ButtonTypes | undefined }>) {
      const { buttonType, tabKey } = action.payload;
      state.tabs[tabKey].currentExecutingAction = buttonType;
    }
  },
});

export const selectTabsventas = (state: RootState) => {
  return state.ventas.ventas.tabs;
};
export const {
  cuadresCloseTab: closeTab,
  openTab,
  changeCurrentTab,
  changeEditStatus,
  clearButtonClick,
  buttonClick,
  setButtons,
  setCurrentExecutingAction
} = tabsOrdenPedidoSlice.actions;
export const tabsReducer = tabsOrdenPedidoSlice.reducer;
