import React, { useMemo } from 'react'
import { Draggable, SpeedDialAction } from 'devextreme-react'
import {
  GroupReport,
  GruposDatosEdicion,
} from '../../types/types'
import { ButtonTypes } from '../../../../../../../views/componentes/globalMenu/types'
import {
  clearButtonClick,
  openTab,
  setCurrentExecutingAction,
} from '../../store/tabsReducer'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { RootState } from '../../../../../../../store/store'
import {
  changeLoaderGroups,
  setCurrentFunction,
} from '../../store/generalReducer'
import {
  changeFilter,
  fetchGrupos,
  setCleanResult,
  setResetSeleccion,
} from '../../store/serachReducer'
import {
  CCard,
  CCardFooter,
} from '@coreui/react-pro'
import Lista from './lista'
import { TabState } from '../../../../../../../store/genericTabTypes'
import { addToast } from '../../../../../../../store/toasterReducer'
import { FETCH_STATUS, ToastTypes } from '../../../../../../../store/types'
import { InventarioService } from '../../../../../services/inventario.service'
import { initDatosEdicion } from '../../store/editDataReducer'
import { GrupoInventario } from '../../../../../store/types'
import { ReportWithLocalDataModal } from '../../../../../../../views/componentes/xtrareports/ReportWithLocalDataModal'
import FiltroBusqueda, { optionsActivos } from './filtroBusqueda'
import BlockUi from '../../../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../../../views/componentes/loadingindicator/loadingindicator'
import { isMobile } from 'react-device-detect'
import TableLoader from '../../../../../../ventas/components/ventas/busquedaVentas/TableLoader'
import Dialog from '../../../../../../../views/componentes/librerias/bootstrap-dialog'
import { defaultDataGroups } from '../nuevo/index'
import { ItemsServices } from '../../../../items/services/items.services'
import { SyncWoo } from '../../../../items/types/types'
import DataSource from 'devextreme/data/data_source'


interface ISearchProps extends React.PropsWithChildren {
  tab: TabState<GruposDatosEdicion>
  tabId: string
}

enum VistasBusqueda {
  Filtros,
  ResultadosBusqueda,
  Resumen,
  Loading,
  Error,
}

const draggingGroupName = 'appointmentsGroup'

const Search: React.FunctionComponent<ISearchProps> = (props) => {
  const { tabId, tab } = props
  const dialogRef = React.useRef<any>(null)

  const dispatch = useDispatch()
  const [vistaActual, setVistaActual] = React.useState<VistasBusqueda>(
    VistasBusqueda.Filtros,
  )
  const [confirmDelete, setConfirmDelete] = React.useState<boolean>(false)
  const [confirmEdit, setConfirmEdit] = React.useState<boolean>(false)
  const [dataSourceGroups, setDataSourceGroups] = React.useState<DataSource>(null)

  const gruposLoader = useSelector((state: RootState) => {
    return state.inventarios.catalogos.grupos.general
  })
  const currentTab = useSelector((state: RootState) => {
    return state.inventarios.catalogos.grupos.tabs.current
  })
  const estadoBusqueda = useSelector(
    (state: RootState) => state.inventarios.catalogos.grupos.search.status,
  )

  const searchFilter = useSelector(
    (state: RootState) => state.inventarios.catalogos.grupos.search.filter,
  )
  const selected = useSelector(
    (state: RootState) =>
      state.inventarios.catalogos.grupos.search.seleccionado,
  )

  const { getGroupsDatasource } = InventarioService.useSetDataSources()

  const DataSourceGroups = useMemo(
    () => getGroupsDatasource(searchFilter),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchFilter],
  )

  const [dataGruposPrint, setDataGruposPrint] = React.useState<
    Array<GrupoInventario>
  >([])
  const [pageIndex, setPageIndex] = React.useState<number>(0)
  const [pageSize, setPageSize] = React.useState<number>(10)

  const getFilterString = React.useCallback(() => {
    const filterText = ''
    return filterText
  }, [])
  const onSetButtonAction = React.useCallback(
    (tipo: ButtonTypes | undefined) => {
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'BUSQUEDA',
          buttonType: tipo,
        }),
      )
    },
    [dispatch],
  )
  const [reporte, setReporte] = React.useState<null | 'Viewer' | 'Designer'>(
    null,
  )

  const playLoader = React.useCallback(
    (message = 'Cargando...') => {
      dispatch(changeLoaderGroups({ show: true, mensaje: message }))
    },
    [dispatch],
  )

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoaderGroups({ show: false, mensaje: '' }))
  }, [dispatch])

  const setToast = React.useCallback(
    (mensaje: string, tipo: ToastTypes) => {
      dispatch(
        addToast({
          title: 'Inventario - Grupos',
          content: mensaje,
          type: tipo,
        }),
      )
    },
    [dispatch],
  )

  const onInitSearch = React.useCallback(() => {
    const data = { ...searchFilter }
    if (data.initial) {
      data.descripcion = ''
      data.categoriaWeb = ''
      data.estado = optionsActivos[0]
      data.initial = false
      dispatch(
        changeFilter({
          ...data,
        }),
      )
    }
  }, [dispatch, searchFilter])

  const handleOptionChange = React.useCallback(async (e) => {
    if (e.fullName === 'paging.pageSize') {
      setPageSize(e.value)
    }
    if (e.fullName === 'paging.pageIndex') {
      setPageIndex(e.value)
    }
  }, [])

  const onHandleEdit = React.useCallback(
    async (grupoData: any) => {
      const id = uuidv4()
      dispatch(
        initDatosEdicion({
          key: id,
          data: {
            ...defaultDataGroups,
            codigo: grupoData?.codigo,
            loader: true,
          },
        }),
      )
      dispatch(openTab({ key: id, grupo: grupoData }))
    },
    [dispatch],
  )

  const onSearch = React.useCallback(async () => {
    onSetButtonAction(ButtonTypes.find)
    playLoader('Buscando...')
    try {
      if (isMobile) {
        setVistaActual(VistasBusqueda.Loading)
      }
      await DataSourceGroups.reload()
      setDataSourceGroups(DataSourceGroups)
      if (isMobile) {
        setVistaActual(VistasBusqueda.ResultadosBusqueda)
      }
    } catch (error) {
      if (isMobile) {
        setVistaActual(VistasBusqueda.Error)
      }
      setToast(error.message, ToastTypes.Danger)
    }
    stopLoader()
    onSetButtonAction(undefined)
  }, [DataSourceGroups, setToast, stopLoader, onSetButtonAction, playLoader])

  const onSearchPrint = React.useCallback(
    async (tipo: null | 'Viewer' | 'Designer') => {
      try {
        playLoader('Genrando Reporte...')
        const toAny: any = fetchGrupos(searchFilter)
        const res = await dispatch(toAny)
        stopLoader()
        if (res !== null && res !== undefined && res['payload'].length >= 0) {
          setDataGruposPrint(res['payload'])
          setReporte(tipo)
        } else {
          setToast(res, ToastTypes.Danger)
        }
      } catch (error) {
        stopLoader()
        setToast(error.message, ToastTypes.Danger)
      }
    },
    [dispatch, searchFilter, setToast, playLoader, stopLoader],
  )

  const onSinc = React.useCallback(async () => {
    const obj_request: SyncWoo = {
      infoRegistro: {
        codigoUsuario: '0',
        barras: 'null',
        generico: 'null',
        linea: 'null',
        grupo: 'null',
        marca: 'null',
        aplicaciones: 'null',
        tipo: 0,
        impuesto: 0,
        nota: 'null',
        peso: 0,
        tags: 'null',
        descripcion: selected.row?.descripcion ?? 'null',
        estado: selected.row?.estadoCodigo ?? 1,
        pvdIva: 0,
        pvpIva: 0,
        incremental: 0,
        mobile: 0,
        codigoTecnologia: 'null',
        modeloBom: 'null',
        busqueda: 0,
        jerarquia: 0,
        idwoo: 0,
        imagen: 'CON',
        local: -1,
      },
    }
    try {
      onSetButtonAction(ButtonTypes.woocommerce)
      playLoader('Sincronizando woocommerce...')
      const res = await ItemsServices.syncItemWoo(obj_request)
      stopLoader()
      onSetButtonAction(undefined)
      if (res !== null && res !== undefined && res['error'] === false) {
        setToast(res['message'], ToastTypes.Success)
      } else {
        const resError: string = res['message']
        if (resError.substring(0, 5) === 'Local') {
          setToast(resError, ToastTypes.Danger)
        } else if (resError.substring(0, 10) === 'La empresa') {
          setToast(
            'Lo sentimos, existe un error con la sincronización',
            ToastTypes.Danger,
          )
        } else {
          setToast(resError, ToastTypes.Danger)
        }
      }
    } catch (error) {
      stopLoader()
      onSetButtonAction(undefined)
      setToast(error.message, ToastTypes.Danger)
    }
  }, [selected.row, setToast, playLoader, stopLoader, onSetButtonAction])

  const onHandleRemove = React.useCallback(async () => {
    onSetButtonAction(ButtonTypes.delete)
    playLoader('Eliminando Registro...')
    try {
      const codigo = selected.row?.codigo ?? 0
      if (isMobile) {
        setVistaActual(VistasBusqueda.Loading)
      }
      const data = await InventarioService.deleteGrupo(codigo)
      if (isMobile) {
        setVistaActual(VistasBusqueda.ResultadosBusqueda)
      }
      if (data !== null && data !== undefined && data['error'] === false) {
        onSearch()
        dispatch(setResetSeleccion())
        setToast(data['message'], ToastTypes.Success)
      } else {
        setToast(data['message'], ToastTypes.Danger)
      }
    } catch (error) {
      if (isMobile) {
        setVistaActual(VistasBusqueda.Error)
      }
      setToast(error.message, ToastTypes.Danger)
    }
    stopLoader()
    onSetButtonAction(undefined)
  }, [
    selected,
    setToast,
    onSearch,
    onSetButtonAction,
    playLoader,
    stopLoader,
    dispatch,
  ])

  const onUndoFilter = React.useCallback(() => {
    const data = { ...searchFilter }
    data.descripcion = ''
    data.categoriaWeb = ''
    data.estado = optionsActivos[0]
    dispatch(changeFilter({ ...data }))
    dispatch(setCleanResult())
    dispatch(setResetSeleccion())
    setDataGruposPrint([])
  }, [dispatch, searchFilter])

  const onCleanResult = React.useCallback(() => {
    setDataSourceGroups(null)
  }, [])

  const onConfirmDelete = React.useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea Eliminar el Registro: ${selected.row?.descripcion ?? '-'}?`,
      actions: [
        Dialog.Action(
          <span>
            <u>A</u>ceptar
          </span>,
          (dialog) => {
            dialog.hide()
            onHandleRemove()
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setConfirmDelete(false)
    return
  }, [onHandleRemove, selected])

  const onConfirmEdit = React.useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea Editar el registro: ${selected.row?.descripcion ?? '-'}?`,
      actions: [
        Dialog.Action(
          <span>
            <u>A</u>ceptar
          </span>,
          (dialog) => {
            dialog.hide()
            onHandleEdit(selected.row)
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setConfirmEdit(false)
    return
  }, [onHandleEdit, selected])

  const handleButtonClick = React.useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.delete:
          setConfirmDelete(true)
          break
        case ButtonTypes.find:
          onSearch()
          break
        case ButtonTypes.refresh:
          onSearch()
          break
        case ButtonTypes.edit:
          setConfirmEdit(true)
          break
        case ButtonTypes.broom:
          onCleanResult()
          break
        case ButtonTypes.undo:
          onUndoFilter()
          break
        case ButtonTypes.print:
          if (dataGruposPrint.length > 0) {
            setReporte('Viewer')
          } else {
            onSearchPrint('Viewer')
          }
          break
        case ButtonTypes.print_design:
          if (dataGruposPrint.length > 0) {
            setReporte('Designer')
          } else {
            onSearchPrint('Designer')
          }
          break
        case ButtonTypes.woocommerce:
          if (selected.row !== null && selected.row !== undefined) {
            onSinc()
          }
          break
        default:
          break
      }
      dispatch(setCurrentFunction(''))
      dispatch(clearButtonClick(tabId))
    },
    [
      dispatch,
      tabId,
      onSearch,
      onSearchPrint,
      dataGruposPrint,
      onUndoFilter,
      onCleanResult,
      onSinc,
      selected,
    ],
  )

  const onParseData = React.useCallback(
    (grupos: Array<GrupoInventario> = []) => {
      let registros: Array<GroupReport> = []
      if (grupos.length > 0) {
        registros = grupos.map((x) => {
          return {
            Code: x?.codigo ?? 0,
            Description: x?.descripcion ?? '',
            State: x?.estadoCodigo ?? 0,
            Category: x.perteneceNombre ?? '',
          }
        })
      }
      return registros
    },
    [],
  )

  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab.globalButtonClick])

  React.useEffect(() => {
    onInitSearch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (confirmDelete) {
      onConfirmDelete()
    }
  }, [confirmDelete, onConfirmDelete])

  React.useEffect(() => {
    if (confirmEdit) {
      onConfirmEdit()
    }
  }, [confirmEdit, onConfirmEdit])

  React.useEffect(() => {
    switch (estadoBusqueda) {
      case FETCH_STATUS.IDDLE:
        setVistaActual(VistasBusqueda.Filtros)
        break
      case FETCH_STATUS.LOADING:
        setVistaActual(VistasBusqueda.Loading)
        break
      case FETCH_STATUS.SUCCESS:
        setVistaActual(VistasBusqueda.ResultadosBusqueda)
        break
      case FETCH_STATUS.FAILED:
        setVistaActual(VistasBusqueda.Error)
        break
      default:
        break
    }
  }, [estadoBusqueda])

  if (isMobile) {
    return (
      <>
        <ReportWithLocalDataModal
          show={reporte !== null}
          onClose={() => setReporte(null)}
          data={onParseData(dataGruposPrint)}
          fileName="StaticGruposListado"
          mode={reporte ?? 'Viewer'}
          parameters={{ Reporte_Filtro: getFilterString() }}
          template="StaticGruposListado"
          key="reportDesigner"
        />
        <CCard>
          <Dialog ref={dialogRef} />
          {vistaActual === VistasBusqueda.Filtros && (
            <>
              <FiltroBusqueda onSearchEnter={onSearch} />
            </>
          )}
          {vistaActual === VistasBusqueda.ResultadosBusqueda && (
            <>
              <Lista
                data={dataSourceGroups}
                onDBClick={() => setConfirmEdit(true)}
                handleOptionChange={handleOptionChange}
                pageIndex={pageIndex}
                pageSize={pageSize}
                onSinc={onSinc}
                onDelete={() => setConfirmDelete(true)}
              />
            </>
          )}
          {vistaActual === VistasBusqueda.Error && <></>}
          {vistaActual === VistasBusqueda.Loading && (
            <CCardFooter>
              <TableLoader />
            </CCardFooter>
          )}
          {currentTab === tabId && (
            <Draggable id="list" data="dropArea" group={draggingGroupName}>
              <SpeedDialAction
                icon="filter"
                label="Filtros"
                visible={true}
                index={1}
                onClick={() => {
                  setVistaActual(VistasBusqueda.Filtros)
                }}
              />
              <SpeedDialAction
                icon="search"
                label="Busqueda"
                visible={true}
                index={2}
                onClick={() => {
                  setVistaActual(VistasBusqueda.ResultadosBusqueda)
                }}
              />
            </Draggable>
          )}
        </CCard>
      </>
    )
  }

  return (
    <>
      <Dialog ref={dialogRef} />
      <ReportWithLocalDataModal
        show={reporte !== null}
        onClose={() => setReporte(null)}
        data={onParseData(dataGruposPrint)}
        fileName="StaticGruposListado"
        mode={reporte ?? 'Viewer'}
        parameters={{ Reporte_Filtro: getFilterString() }}
        template="StaticGruposListado"
        key="reportDesigner"
      />
      <div id="lineasContent">
        <BlockUi
          tag="div"
          loader={LoadingIndicator}
          blocking={gruposLoader.loader.show}
          message={gruposLoader.loader.mensaje}
        >
          <CCard>
            <FiltroBusqueda onSearchEnter={onSearch} />
            <Lista
              data={dataSourceGroups}
              onDBClick={() => setConfirmEdit(true)}
              handleOptionChange={handleOptionChange}
              pageIndex={pageIndex}
              pageSize={pageSize}
              onSinc={onSinc}
              onDelete={() => setConfirmDelete(true)}
            />
          </CCard>
        </BlockUi>
      </div>
    </>
  )
}

export default React.memo(Search)
