import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ConfiguracionesProveedor } from "../types/types";


const initialState: ConfiguracionesProveedor = {
  modulo: 0
}

const configuracionesProveedor = createSlice(
  {
    name: 'configuracionesDocsCtasXCobrar',
    initialState: initialState,
    reducers: {
      setCodigoModulo(state, acion: PayloadAction<number>) {
        state.modulo = acion.payload;
      },
    }
  }
)


export const configuracionesProveedorReducer = configuracionesProveedor.reducer;
export const { setCodigoModulo } = configuracionesProveedor.actions;