import { RequestHelper } from "../../../../../helpers/requestHelper";
import { CuotaCreditoVenta } from "../../../../ventas/types/types";

export const cuotasCreditosService = {
  cargarCuotasCompra,
  guardarCuotasCompras
}

async function cargarCuotasCompra(find): Promise<any> {
  try {
    const obj = {
      codigo: find
    }
    const data = await RequestHelper.get<any>('proveedores/general', 'paymentsPurchases/getBuyPayment', "", obj);
    const itemsParsed = parseApiItemsData(data);
    return itemsParsed;
  } catch (error) {
    return error;
  }
}

async function guardarCuotasCompras(objeto): Promise<any> {
  const obj = {
    infoRegistro: {
      compraCodigo: objeto['codigo'],
      cuotas: objeto['cuotas'],
    },
  }
  try {
    const data = await RequestHelper.postAll('proveedores/general', 'purchases/saveInstalment', obj)
    return data
  } catch (error) {
    return error
  }
}

// parseInt(x.codigo)
function parseApiItemsData(apiresult: Array<any>): Array<CuotaCreditoVenta> {
  const items = apiresult.map((x, i = 0) => {
    const item: CuotaCreditoVenta = {
      numero: i + 1,
      letra: x.letra,
      cuota: x.cuota,
      vencimiento: x.vencimiento,
      saldo: parseFloat(x.saldo),
      estado: String(x.estadoDes),
      codigo: x?.codigo ?? 0,
    };
    return item;
  });
  return items;
}
