import React from 'react'
import {
  DatosEdicionAdicionales
} from '../../../../types/types'
import { useDispatch, useSelector } from 'react-redux'
import RowContainer from '../../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../../views/componentes/labeledInput/labeledInput'
import { NumberBox, SelectBox, TextArea, TextBox } from 'devextreme-react'
import EspecialidadPrvLookUp from '../../../../../../../componentes/especialidadProveedor'
import { RootState } from '../../../../../../../../store/store'
import { setTabAdicionales } from '../../../../store/editDataReducer'
import { EspecialidadPrv } from '../../../../../../../componentes/especialidadProveedor/store/types'
import TiposEmpresasLookUp from '../../../../../../../componentes/tiposEmpresasLookUp'

interface IAditionalsProps {
  tabId: string
  refForm: any
}

const Adicionales: React.FunctionComponent<IAditionalsProps> = (props) => {
  const { tabId } = props
  const dispatch = useDispatch()

  const stateEditAditionals = useSelector((state: RootState) => {
    return state.proveedores.proveedores.editData[tabId]?.tabs?.adicionales
  })

  const onChangedSpecialty = React.useCallback(
    (value: EspecialidadPrv) => {
      if (value)
        dispatch(
          setTabAdicionales({
            key: tabId,
            data: {
              ...stateEditAditionals,
              especialidad: value,
            },
          }),
        )
    },
    [dispatch, stateEditAditionals, tabId],
  )

  const onChangedWebsite = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setTabAdicionales({
            key: tabId,
            data: {
              ...stateEditAditionals,
              sitioweb: event.value,
            },
          }),
        )
    },
    [dispatch, stateEditAditionals, tabId],
  )

  const onChangedObservations = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setTabAdicionales({
            key: tabId,
            data: {
              ...stateEditAditionals,
              observaciones: event.value,
            },
          }),
        )
    },
    [dispatch, stateEditAditionals, tabId],
  )


  const onLookUpValueChanged = React.useCallback(
    (event: any) => {
      if (event)
        dispatch(
          setTabAdicionales({
            key: tabId,
            data: {
              ...stateEditAditionals,
              tipoEmpresa: event,
            },
          }),
        )
    },
    [dispatch, stateEditAditionals, tabId],
  )

  return (
    <>
      <RowContainer>
        <CustomCol xs="12" md="8">
          <Labeled label="Observaciónes:">
            <TextArea
              name="observaciones"
              value={stateEditAditionals.observaciones ?? ''}
              onValueChanged={onChangedObservations}
              inputAttr={{
                autocomplete: 'nope',
              }}
            />
          </Labeled>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="3">
          <Labeled label="Sitio Web:">
            <TextBox
              name="sitioWeb"
              value={stateEditAditionals.sitioweb ?? ''}
              onValueChanged={onChangedWebsite}
              inputAttr={{
                autocomplete: 'nope',
              }}
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="3">
          <Labeled label="Tipo:">
            <TiposEmpresasLookUp
              selected={stateEditAditionals?.tipoEmpresa ?? null}
              onChanged={(e) => {
                if (e !== null || e !== undefined) {
                  onLookUpValueChanged(e)
                }
              }}
              provider={stateEditAditionals?.tiposEmpresaDp ?? []}
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="3">
          <Labeled label="Especialidad:">
            <EspecialidadPrvLookUp
              selected={stateEditAditionals.especialidad ?? null}
              onChanged={(e) => {
                if (e !== null || e !== undefined) {
                  onChangedSpecialty(e)
                }
              }}
              provider={stateEditAditionals?.especialidadOptions ?? []}
            />
          </Labeled>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="2">
          {/* 1-30 -> Range -> int */}
          <Labeled label="Intervalo de reposicion:">
            <NumberBox value={stateEditAditionals?.reposicion ?? 0} />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="2">
          {/* string */}
          <Labeled label="Dia de visita:">
            <SelectBox
              id="diaVisitaPrv"
              // ref={selectRef}
              items={diasVisista ?? []}
              // searchEnabled
              displayExpr={'label'}
              searchExpr={['label']}
              // searchTimeout={500}
              value={stateEditAditionals.diaVisita ?? diasVisista[0]}
            // onValueChanged={onLookUpValueChanged}
            // disabled={disabled}
            // onFocusIn={onSelectBoxFocusIn}
            ></SelectBox>
          </Labeled>
        </CustomCol>
      </RowContainer>
    </>
  )
}

export default Adicionales

export const defaultDatosAditionales: DatosEdicionAdicionales = {
  observaciones: '',
  sitioweb: '',
  tipoEmpresa: null,
  tiposEmpresaDp: [],
  especialidad: null,
  especialidadOptions: [],
  reposicion: 0,
  diaVisita: null,
}



export const diasVisista: Array<{ value: number; label: string }> = [
  { value: -1, label: 'Elija una opción' },
  { value: 1, label: 'Lunes' },
  { value: 2, label: 'Martes' },
  { value: 3, label: 'Miércoles' },
  { value: 4, label: 'Jueves' },
  { value: 5, label: 'Viernes' },
  { value: 6, label: 'Sábado' },
  { value: 7, label: 'Domingo' },
]
