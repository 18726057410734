import React from 'react'
import { TabState, TabsState } from '../store/genericTabTypes'
import { CustomDictionary } from '../store/types'
import { TabTypes } from '../store/enums'
import { PayloadAction } from '@reduxjs/toolkit'

const useActiveTabChange = (tabs: any, onTabChanged: any) => {
  const onActiveTabChange = React.useCallback(
    (index: number) => {
      if (index <= 0) {
        onTabChanged('BUSQUEDA')
        return
      }
      let i = 0
      for (const key in tabs) {
        if (i === index) {
          onTabChanged(key)
          return
        }
        i++
      }
    },
    [tabs, onTabChanged],
  )
  return onActiveTabChange
}

const useHanldeOnTabClosing = (tabs: any, ontabClosing: any) => {
  const hanldeOnTabClosing = React.useCallback(
    (index: number) => {
      if (index <= 0) {
        return
      }
      let i = 0
      for (const key in tabs) {
        if (i === index) {
          ontabClosing(key)
          return
        }
        i++
      }
    },
    [tabs, ontabClosing],
  )
  return hanldeOnTabClosing
}

const getNewTabKeyOnClose = (state: TabsState<any>, keyToClose: string) => {
  let newCurrent = ''
  for (const key in state.tabs) {
    if (newCurrent === '') {
      newCurrent = key
    }
    if (keyToClose === key) {
      return newCurrent
    }
  }
  return 'BUSQUEDA'
}

const clearDataEdition = (
  state: CustomDictionary<any>,
  { payload }: PayloadAction<string>,
) => {
  delete state[payload]
}

const closeTab = (
  state: TabsState<any>,
  { payload }: PayloadAction<string>,
) => {
  const newCurrent = getNewTabKeyOnClose(state, payload)
  state.current = newCurrent
  delete state.tabs[payload]
}

const tabExists = (
  tabs: CustomDictionary<TabState<any>>,
  comprobante: number,
  type?: TabTypes,
) => {
  for (const key in tabs) {
    const tab = tabs[key]
    if (type) {
      if (tab.id === comprobante && tab.type === type) return key
    } else {
      if (tab.id === comprobante) return key
    }
  }
}

const tabIsVisible = (
  tabs: CustomDictionary<TabState<any>>,
  type: TabTypes,
) => {
  for (const key in tabs) {
    const tab = tabs[key]
    if (tab.type === type) return key
  }
}

export {
  useActiveTabChange,
  useHanldeOnTabClosing,
  clearDataEdition,
  closeTab,
  tabExists,
  tabIsVisible,
}
