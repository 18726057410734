import React, { useEffect } from 'react'
import {
  AccionMenu,
  CustomButtons,
  FETCH_STATUS,
  ToastTypes,
} from '../../../../../store/types'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../../../store/store'
import { EInvIniTipo, FiltrosInventariosMovState, FiltrosSearch, InventarioInitialDto } from './types/types'
import {
  changeLoaderInventory,
} from './store/generalReducer'
import { addToast } from '../../../../../store/toasterReducer'
import { Aplicacion } from '../../../store/types'
import { utilidades } from '../../../../../helpers/utilidades'
import {
  buttonClick,
  changeCurrentTab,
  closeTabConceptos,
  openTab,
  openTabConsolidate,
  setButtons,
  setCurrentExecutingAction,
} from './store/tabsReducer'
import { ButtonTypes } from '../../../../../views/componentes/globalMenu/types'
import { v4 as uuidv4 } from 'uuid'
import { deleteEditData, initDatosEdicion } from './store/editDataReducer'
import { changeFilter, fetchConsolidate, fetchInventorys, setChangeLoaderCon, setCleanResult, setColapsado, setResetSeleccion, setVerRegistro } from './store/serachReducer'
import Dialog from '../../../../../views/componentes/librerias/bootstrap-dialog'
import {
  useButtonDisabled,
  useMenuOptions,
} from '../../../../../hooks/useMenuButtonControls'
import InventarioInicialMain from './components/shared/main'
import { EAplicationsAcatha } from '../../../../../store/enumsAplication'
import { setMenuButtons } from './store/menuReducer'
import { setAplicacionModulo } from './store/configReducer'
import { DateUtils } from '../../../../../helpers/dateUtils'
import { consoleService } from '../../../../../services/console.service'
import { TabTypes } from '../../../../ventas/types/enums'
import { defaultDataInventory } from './components/nuevo'
import { inititalState } from './components/buscar/index';
import { tipoEstadoService } from '../../../../componentes/tipoEstado/service/tipoEstado.service'
import { ReportWithLocalDataModal } from '../../../../../views/componentes/xtrareports/ReportWithLocalDataModal'

const pathVerificador = '/inventario_inicial'

interface InventarioInicialProps extends React.PropsWithChildren { }

const InventarioInicial: React.FunctionComponent<
  InventarioInicialProps
> = () => {
  const dispatch = useDispatch()
  const menuState = useSelector((state: RootState) => state.global.menu)
  const tabs = useSelector((state: RootState) => {
    return state.inventarios.movimientos.inventarioInicial.tabs
  })
  const periodoActivo = useSelector(
    (state: RootState) => state.shared.providers.periodoContable,
  )

  const searchFilter = useSelector((state: RootState) => {
    return state.inventarios.movimientos.inventarioInicial.search.filter
  })
  const searchFilterCon = useSelector((state: RootState) => {
    return state.inventarios.movimientos.inventarioInicial.search.filterConsolidar
  })
  const searchStatus = useSelector((state: RootState) => {
    return state.inventarios.movimientos.inventarioInicial.search.status
  })
  const searchResults = useSelector((state: RootState) => {
    return state.inventarios.movimientos.inventarioInicial.search.resultados
  })

  const selected = useSelector((state: RootState) => {
    return state.inventarios.movimientos.inventarioInicial.search.seleccionado
  })


  const dialogRef = React.useRef<any>(null)
  const getButtonDisabled = useButtonDisabled(tabs)
  const { getMenuOptions, options } = useMenuOptions()
  const [reporte, setReporte] = React.useState<null | "Viewer" | "Designer">(null);


  const fechaFormato: string = 'dd/MM/yyyy'
  const fechaFormatoInput: string = 'yyyy-MM-dd'
  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(
      addToast({
        title: 'Inventario - Inventario Inicial',
        content: mensaje,
        type: tipo,
      }),
    )
  }, [dispatch])
  const getFilterString = React.useCallback((filter: FiltrosInventariosMovState) => {
    const filterText = ''
    return filterText
  }, [])
  const playLoader = React.useCallback(
    (message = 'Cargando...') => {
      dispatch(changeLoaderInventory({ show: true, mensaje: message }))
    },
    [dispatch],
  )

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoaderInventory({ show: false, mensaje: '' }))
  }, [dispatch])

  const playLoaderCon = React.useCallback((message = 'Cargando...') => {
    dispatch(setChangeLoaderCon({ show: true, mensaje: message }))
  }, [dispatch])

  const stopLoaderCon = React.useCallback(() => {
    dispatch(setChangeLoaderCon({ show: false, mensaje: '' }))
  }, [dispatch])

  const onTabClosing = React.useCallback(
    async (index) => {
      const closedTab = tabs.tabs[index]
      dispatch(closeTabConceptos(closedTab.tabKey ?? index))
      await utilidades.sleep(50)
      dispatch(deleteEditData(closedTab.tabKey ?? index))
    },
    [dispatch, tabs.tabs],
  )

  const onTabChanged = React.useCallback(
    (tabKey: string) => {
      dispatch(changeCurrentTab(tabKey))
    },
    [dispatch],
  )

  const onCleanResultFilter = React.useCallback(async () => {
    const data = { ...inititalState }
    const estado = await tipoEstadoService.getTipoEstado('Elija un estado')
    if (estado['auto'] && estado['error'] === false) {
      data.estado = estado['auto'][0]
    }
    data.documento = ''
    data.observaciones = ''
    data.fechaDesde = periodoActivo.length > 0 ? DateUtils.format(periodoActivo[0].finicio, fechaFormatoInput, fechaFormato) : DateUtils.getCurrentDateAsString(fechaFormatoInput)
    data.fechaHasta = periodoActivo.length > 0 ? DateUtils.format(periodoActivo[0].fcierre, fechaFormatoInput, fechaFormato) : DateUtils.getCurrentDateAsString(fechaFormatoInput)
    data.initial = false
    dispatch(changeFilter({
      ...data
    }))
    dispatch(setResetSeleccion())
    dispatch(setCleanResult())
  }, [dispatch, periodoActivo])

  const onSearchFilter = React.useCallback(async () => {
    const desde = searchFilter?.fechaDesde ? DateUtils.format(searchFilter?.fechaDesde, fechaFormato, fechaFormatoInput) : DateUtils.getCurrentDateAsString(fechaFormato)
    const hasta = searchFilter?.fechaHasta ? DateUtils.format(searchFilter?.fechaHasta, fechaFormato, fechaFormatoInput) : DateUtils.getCurrentDateAsString(fechaFormato)

    try {
      const data_search: FiltrosSearch = {
        numero: searchFilter?.documento ?? '',
        tipo: EInvIniTipo.tipo,
        cliente: searchFilter.item?.codigoBarras ?? '',
        observaciones: searchFilter?.observaciones ?? '',
        estado: searchFilter.estado?.codigo ?? -1,
        fechaDesde: desde,
        fechaHasta: hasta,
      }
      const toAny: any = fetchInventorys(data_search)
      const res = await dispatch(toAny)
      consoleService.log(res)
      if (res !== null && res !== undefined && res['payload']['error'] === false) {
        if (res['payload']?.auto && res['payload'].auto.length > 5) {
          dispatch(setColapsado(false))
        }
        setToast(res['payload']['message'], ToastTypes.Success)
      } else {
        setToast(res['payload']['message'], ToastTypes.Danger)
      }
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
  }, [dispatch, searchFilter, setToast])

  const onSearchFilterCon = React.useCallback(async () => {
    const fecha = searchFilterCon?.fechaInv ? DateUtils.format(searchFilterCon?.fechaInv, fechaFormato, fechaFormatoInput) : DateUtils.getCurrentDateAsString(fechaFormato)

    try {
      const data_search: any = {
        local: searchFilterCon.local?.codigo ?? -1,
        fecha,
      }
      playLoaderCon()
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'VERABONOS',
          buttonType: ButtonTypes.find,
        }),
      )
      const toAny: any = fetchConsolidate(data_search)
      const res = await dispatch(toAny)
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'VERABONOS',
          buttonType: undefined,
        }),
      )
      stopLoaderCon()
      consoleService.log(res)
      if (res !== null && res !== undefined && res['payload']['error'] === false) {
        setToast(res['payload']['message'], ToastTypes.Success)
      } else {
        setToast(res['payload']['message'], ToastTypes.Danger)
      }
    } catch (error) {
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'VERABONOS',
          buttonType: undefined,
        }),
      )
      stopLoaderCon()
      setToast(error.message, ToastTypes.Danger)
    }
  }, [dispatch, searchFilterCon, setToast, playLoaderCon, stopLoaderCon])


  const onMenuButtonClick = React.useCallback(
    async (action: AccionMenu, tab: string = "") => {
      const id = uuidv4()
      switch (action.actionType) {
        case ButtonTypes.find:
          if (tabs.current === 'BUSQUEDA') {
            onSearchFilter()
          } else if (tabs.current === 'VERABONOS') {
            onSearchFilterCon()
          } else {
            dispatch(changeCurrentTab('BUSQUEDA'))
          }
          break
        case ButtonTypes.new:
          dispatch(setVerRegistro(false))
          dispatch(
            initDatosEdicion({
              key: id,
              data: {
                ...defaultDataInventory,
                loading: true
              },
            }),
          )
          dispatch(openTab({ key: id }))
          break
        case ButtonTypes.save:
          if (tabs.current !== 'BUSQUEDA') {
            dispatch(buttonClick({
              tabKey: tab === "" ? tabs.current : tab,
              button: action.actionType
            }))
          }
          break
        case ButtonTypes.edit:
          if (tabs.current === 'BUSQUEDA') {
            dispatch(buttonClick({
              tabKey: tab === "" ? tabs.current : tab,
              button: action.actionType
            }))
          }
          break
        case ButtonTypes.delete:
          if (tabs.current === 'BUSQUEDA') {
            dispatch(buttonClick({
              tabKey: tab === "" ? tabs.current : tab,
              button: action.actionType
            }))
          }
          break
        case ButtonTypes.broom:
          if (tabs.current === 'BUSQUEDA') {
            onCleanResultFilter()
          } else {
            dispatch(buttonClick({
              tabKey: tab === "" ? tabs.current : tab,
              button: action.actionType
            }))
          }
          break
        case ButtonTypes.print:
          if (tabs.current === 'BUSQUEDA') {
            setReporte('Viewer')
          } else {
            dispatch(buttonClick({
              tabKey: tab === "" ? tabs.current : tab,
              button: action.actionType
            }))

          }
          break
        case ButtonTypes.undo:
          if (tabs.current !== 'BUSQUEDA') {
            dispatch(buttonClick({
              tabKey: tab === "" ? tabs.current : tab,
              button: action.actionType
            }))
          }
          break
        case ButtonTypes.print_design:
          if (tabs.current === 'BUSQUEDA') {
            setReporte('Designer')
          } else {
            dispatch(buttonClick({
              tabKey: tab === "" ? tabs.current : tab,
              button: action.actionType
            }))

          }
          break
        case ButtonTypes.consolidate:
          dispatch(openTabConsolidate({ key: 'VERABONOS', type: TabTypes.verAbonos }))
          break
        default:
          break
      }
    },
    [dispatch, onCleanResultFilter, tabs, onSearchFilter, onSearchFilterCon],
  )

  const onGetCode = React.useCallback(() => {
    dispatch(setAplicacionModulo(parseInt(EAplicationsAcatha.InventarioInicial)))
  }, [dispatch])

  useEffect(() => {
    onGetCode()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (menuState.length > 0 && options.length === 0) {
      getMenuOptions(menuState, 'to', pathVerificador)
    }
  }, [menuState, getMenuOptions, options])

  React.useEffect(() => {
    if (searchStatus === FETCH_STATUS.FAILED) {
      stopLoader()
      dispatch(
        addToast({
          title: 'Buscar clientes',
          content: 'Error al realizar la búsqueda',
          type: ToastTypes.Danger,
        }),
      )
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'BUSQUEDA',
          buttonType: undefined,
        }),
      )
    }
    if (searchStatus === FETCH_STATUS.LOADING) {
      playLoader()
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'BUSQUEDA',
          buttonType: ButtonTypes.find,
        }),
      )
    }
    if (searchStatus === FETCH_STATUS.SUCCESS) {
      stopLoader()
      dispatch(
        setButtons({
          tabKey: 'BUSQUEDA',
          buttons: {
            Nuevo: true,
            Guardar: false,
            Editar: selected !== null && selected !== undefined,
            Eliminar: selected !== null && selected !== undefined,
            Buscar: true,
            Imprimir: searchResults['auto'] ?? [],
            Consolidar: true,
            Deshacer: false,
            Limpiar: searchResults['auto'] && searchResults['auto'].length > 0,
          },
        }),
      )
    }
  }, [dispatch, searchStatus, playLoader, searchResults, stopLoader, selected])


  const onActionsButtonsLoaded = React.useCallback((actions: Array<AccionMenu>) => {
    dispatch(setMenuButtons(actions));
  }, [dispatch]);

  const onSearchExecutingAction = React.useCallback((tabKey: string, type?: ButtonTypes) => {
    dispatch(setCurrentExecutingAction({
      tabKey: tabKey,
      buttonType: type
    }))
  }, [dispatch]);

  const onParseData = React.useCallback((importaciones: Array<any>) => {
    let registros: Array<InventarioInitialDto> = [];
    let registro: InventarioInitialDto = null

    if (importaciones.length > 0) {
      registros = importaciones.map(item => {
        registro = {
          Year: item?.anio.toString() ?? '',
          Number: item?.numero ?? '',
          Date: item?.fecha ?? DateUtils.getCurrentDateAsString(),
          Observation: item?.observaciones ?? '',
          User: item?.usuarioNombre ?? '',
          State: item?.estadoDescripcion ?? '',
        }
        return registro
      })
      // registros.push(registro)
    }

    consoleService.log(registros)
    return registros

  }, []);


  return (
    <>
      <ReportWithLocalDataModal
        show={reporte !== null}
        onClose={() => setReporte(null)}
        data={onParseData(searchResults['auto'] ?? [])}
        fileName='StaticInventoryInitial'
        mode={reporte ?? 'Viewer'}
        parameters={{ 'Reporte_Filtro': getFilterString(searchFilter) }}
        template='StaticInventoryInitial'
        key='reportDesigner'
      />
      <div>
        <Dialog ref={dialogRef} />
      </div>
      <InventarioInicialMain
        menu={{ loading: false, accions: options }}
        tabs={tabs}
        onTabChanged={onTabChanged}
        codigoModulo={parseInt(EAplicationsAcatha.InventarioInicial)}
        opciones={OpcionesModeulo}
        onActionsButtonsLoaded={onActionsButtonsLoaded}
        onMenuButtonClick={onMenuButtonClick}
        searching={searchStatus === FETCH_STATUS.LOADING}
        ontabClosing={onTabClosing}
        hayResultadosBusqueda={searchResults.length > 0}
        verificarPuntoVenta
        verificarAutorizacion
        onExecutingAction={onSearchExecutingAction}
        tipoAplicacion={Aplicacion.inventarioInicial}
      />
    </>
  )
}

export default React.memo(InventarioInicial)

export const ModuleButtons: CustomButtons = {
  Nuevo: true,
  Guardar: true,
  Editar: false,
  Eliminar: false,
  Buscar: false,
  Imprimir: false,
  Consolidar: true,
  Limpiar: true,
  Deshacer: true
}

const OpcionesModeulo = [parseInt(EAplicationsAcatha.InventarioInicial)];
