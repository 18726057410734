import { RequestHelper } from '../../../../../helpers/requestHelper'
import { RebuildKdx, SearchItem, SearchKardex } from '../types/types'

export const KardexServices = {
  getKardexByStore,
  getKardex,
  getKardexReserves,
  getKardexAll,
  getDetailsItem,
  getPadanuales,
  getLotes,
  getSupplierByItem,
  rebuildKardex,
  getProvenance,
}

async function getKardexByStore(itemKardex: SearchKardex): Promise<any> {
  try {
    const res = await RequestHelper.getAll<any>(
      'inventario/kardex',
      'getAllxLocal',
      '',
      itemKardex,
    )
    return res
  } catch (error) {
    return error
  }
}

async function getKardex(itemKardex: SearchKardex): Promise<any> {
  try {
    const res = await RequestHelper.getAll<any>(
      'inventario/kardex',
      'getAll',
      '',
      itemKardex,
    )
    return res
  } catch (error) {
    return error
  }
}

async function getKardexReserves(itemKardex: SearchKardex): Promise<any> {
  try {
    const res = await RequestHelper.getAll<any>(
      'inventario/kardex',
      'getAllReserve',
      '',
      itemKardex,
    )
    return res
  } catch (error) {
    return error
  }
}

async function getKardexAll(itemKardex: SearchKardex): Promise<any> {
  try {
    const res = await RequestHelper.postAll<any>(
      'inventario/kardex',
      'getKardexItems',
      itemKardex,
    )
    return res
  } catch (error) {
    return error
  }
}

async function getDetailsItem(itemKardex: SearchItem): Promise<any> {
  try {
    const res = await RequestHelper.getAll<any>(
      'inventario/items',
      'searchItemsLocalSimple',
      '',
      itemKardex,
    )
    return res
  } catch (error) {
    return error
  }
}
async function getPadanuales(
  iteCodigo: number,
  pedimento: string,
): Promise<any> {
  try {
    const res = await RequestHelper.getAll<any>(
      'inventario/kardex',
      'padAnuales/getAll',
      '',
      { pedimento: pedimento, iteCodigo: iteCodigo },
    )
    return res
  } catch (error) {
    return error
  }
}

async function getLotes(itemCodigo: number, localCodigo: number = -1): Promise<any> {
  try {
    const res = await RequestHelper.getAll<any>(
      'inventario/kardex',
      'lots/getAll',
      '',
      { itemCodigo: itemCodigo, localCodigo: localCodigo },
    )
    return res
  } catch (error) {
    return error
  }
}

async function getSupplierByItem(itemCodigo: number): Promise<any> {
  try {
    const res = await RequestHelper.getAll<any>(
      'inventario/kardex',
      'proveedores/getByItems',
      '',
      { codigo: itemCodigo },
    )
    return res
  } catch (error) {
    return error
  }
}

async function rebuildKardex(rebuild: RebuildKdx): Promise<any> {
  try {
    const res = await RequestHelper.getAll<any>(
      'inventario/kardex',
      'rebuildKardex2',
      '',
      rebuild,
    )
    return res
  } catch (error) {
    return error
  }
}

async function getProvenance(codekardex: number): Promise<any> {
  try {
    const res = await RequestHelper.getAll<any>(
      'inventario/kardex',
      'getProvenance',
      '',
      { codigoKardex: codekardex },
    )
    return res
  } catch (error) {
    return error
  }
}
