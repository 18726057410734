import React, { useEffect } from 'react'
import ReactDom from 'react-dom'
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow, CModal, CModalHeader, CModalTitle, CModalBody, CTooltip,
} from '@coreui/react-pro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux'
import Modalform from "../../../views/componentes/modalform"
import { RootState } from '../../../store/store'
import { changeLoader } from '../../../store/reducers';
import { AccionMenu, ToastTypes } from '../../../store/types';
import { addToast } from '../../../store/toasterReducer';
import { TIPOS_BOTONES } from './store/types';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
// import Nuevo from "./componentes/nuevo"
import { setCurrentAction, setRegistros } from "./store/modalListadoComprobantesReducer";
import { ButtonTypes } from '../../../views/componentes/globalMenu/types';
import { BotonMenu } from '../../contabilidad/pages/planCuentas/store/types';
import { menuService } from '../../../services/menu.service';
import TablaListadoComprobantes from './componentes/tabla';
import { listadoComprobantesService } from './service/modalListadoComprobantes.services';
import TablaListadoComprobantesPrv from './componentes/tablaPrv'

type ModalListadoComprobantesProps = {
  show: boolean
  onClose: () => void
  cargarComprobantes?: (evt) => void
  tipo: any
  modulo: number
  puntoVenta: any
}

const menuDefectoSearch = [
  {
    icon: 'fal fa-search',
    id: 'Buscar',
    text: 'Buscar',
    type: ButtonTypes.find,
  },
  {
    icon: 'fal fa-broom',
    id: 'Limpiar',
    text: 'Limpiar',
    type: ButtonTypes.broom,
  },
  {
    icon: 'fal fa-print',
    id: 'Imprimir',
    text: 'Imprimir',
    type: ButtonTypes.print,
  },
]

const ModalListadoComprobantes = (props: ModalListadoComprobantesProps) => {
  const { show, onClose, tipo, modulo, puntoVenta } = props
  const dispatch = useDispatch()

  const currentAction = useSelector(
    (state: RootState) => state.bancos.modalNovedades.currentAction,
  )
  const motrarAlerta = useSelector(
    (state: RootState) => state.bancos.modalNovedades.motrarAlerta,
  )

  const [options, setOptions] = React.useState<Array<any>>([])
  const [showModal, setShowModal] = React.useState<boolean>(false)

  const [showConfirm, setShowConfirm] = React.useState(false)
  const [confirmDelete, setConfirmDelete] = React.useState(false)
  const [confirmUpdate, setConfirmUpdate] = React.useState(false)
  const [messageConfirm, setMessageConfirm] = React.useState(
    'Está seguro que desea realizar estos cambios?',
  )
  const [displayDestino, setDisplayDestino] = React.useState<string>('')
  const [confirmChange, setConfirmChange] = React.useState<boolean>(false)

  const setToast = (texto, type) => {
    let tipo: ToastTypes = ToastTypes.Info
    if (type === 'warning') {
      tipo = ToastTypes.Warning
    } else if (type === 'success') {
      tipo = ToastTypes.Success
    }
    if (type === 'info') {
      tipo = ToastTypes.Info
    }
    if (type === 'danger') {
      tipo = ToastTypes.Danger
    }
    dispatch(
      addToast({
        id: '',
        autoHide: 3500,
        title: 'Acatha',
        content: texto,
        fade: true,
        type: tipo,
      }),
    )
  }

  const playLoader = React.useCallback(() => {
    dispatch(changeLoader({ show: true, mensaje: 'Cargando...' }))
  }, [dispatch])

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoader({ show: false, mensaje: '' }))
  }, [dispatch])

  const getDetalle = React.useCallback(
    (data: Array<any>) => {
      const listado: Array<any> = []
      data.forEach(function (det) {
        listado.push({ ...det })
      })

      dispatch(setRegistros(listado))
    },
    [dispatch],
  )

  const onSearch = React.useCallback(async () => {
    playLoader()
    let resapi: any = null
    if (modulo === 24) {
      switch (tipo) {
        case 'cuotas':
          resapi = await listadoComprobantesService.verificarSinCuotas('d')
          if (resapi.auto && resapi.auto.length > 0) {
            getDetalle(resapi.auto)
          }
          break
        case 'cheques':
          resapi = await listadoComprobantesService.verificarSinCheques('d')
          if (resapi.auto && resapi.auto.length > 0) {
            getDetalle(resapi.auto)
          }
          break
        case 'tarjetas':
          resapi = await listadoComprobantesService.verificarSinTarjetas('d')
          if (resapi.auto && resapi.auto.length > 0) {
            getDetalle(resapi.auto)
          }
          break
      }
    } else if (modulo === 25) {
      switch (tipo) {
        case 'cuotas':
          resapi = await listadoComprobantesService.verificarSinCuotasPrv('d')
          if (resapi.auto && resapi.auto.length > 0) {
            getDetalle(resapi.auto)
          }
          break
        case 'cheques':
          resapi = await listadoComprobantesService.verificarSinChequesPrv('d')
          if (resapi.auto && resapi.auto.length > 0) {
            getDetalle(resapi.auto)
          }
          break
        case 'tarjetas':
          resapi = await listadoComprobantesService.verificarSinTarjetasPrv('d')
          if (resapi.auto && resapi.auto.length > 0) {
            getDetalle(resapi.auto)
          }
          break
      }
    } else {
      switch (tipo) {
        case 'cuotas':
          resapi = await listadoComprobantesService.verificarSinCuotasPunto(
            'd',
            puntoVenta?.codigoPuntoVenta ?? -1,
          )
          if (resapi.auto && resapi.auto.length > 0) {
            getDetalle(resapi.auto)
          }
          break
        case 'cheques':
          resapi = await listadoComprobantesService.verificarSinChequesPunto(
            'd',
            puntoVenta?.codigoPuntoVenta ?? -1,
          )
          if (resapi.auto && resapi.auto.length > 0) {
            getDetalle(resapi.auto)
          }
          break
        case 'tarjetas':
          resapi = await listadoComprobantesService.verificarSinTarjetasPunto(
            'd',
            puntoVenta?.codigoPuntoVenta ?? -1,
          )
          if (resapi.auto && resapi.auto.length > 0) {
            getDetalle(resapi.auto)
          }
          break
      }
    }
    stopLoader()
  }, [modulo, tipo, puntoVenta, playLoader, stopLoader, getDetalle])

  const returnAction = (metodo) => {
    switch (metodo) {
      case TIPOS_BOTONES.BOTON_BUSCAR:
        onSearch()
        break

      case TIPOS_BOTONES.BOTON_LIMPIAR:
        dispatch(setRegistros([]))

        break

      default:
        break
    }
  }

  const getButtonDisabled = React.useCallback(
    (button: AccionMenu) => {
      switch (button.nombre) {
        case TIPOS_BOTONES.BOTON_IMPRIMIR:
          return false
        case TIPOS_BOTONES.BOTON_BUSCAR:
          return false
        case TIPOS_BOTONES.BOTON_DESHACER:
          return false
        case TIPOS_BOTONES.BOTON_LIMPIAR: {
          return currentAction !== 'Buscar'
        }
        default:
          return false
      }
    },
    [currentAction],
  )

  const getTitleModal = () => {
    if (tipo === 'cuota' || tipo === 'cuotas') {
      return <>{'Lista de Comprobantes sin cuotas'}</>
    }
    if (tipo === 'cheques') {
      return <>{'Lista de Comprobantes sin cheques'}</>
    }
    if (tipo === 'tarjetas') {
      return <>{'Lista de Comprobantes sin tarjetass'}</>
    } else {
      return <> </>
    }
  }

  const optionsForm = () => {
    if (options.length > 0) {
      return (
        <>
          <CRow>
            <CCol className="ms-0 d-flex align-items-start justify-content-start">
              <div
                key="menuItems"
                className="btn-toolbar"
                role="toolbar"
                aria-label="Toolbar with button groups"
                style={{ marginBottom: '5px', paddingTop: '-2rem' }}
              >
                <div
                  key="menuItemsBotones"
                  className="btn-group-lg btn-group btn-sm me-2"
                  role="group"
                  aria-label="First group"
                >
                  {options.map(function (x, i) {
                    const prefixButton = 'buttonsCuentas_'
                    const nameButon = x.text
                    const disabled = getButtonDisabled(x)
                    return (
                      <CTooltip
                        key={'tool-' + prefixButton + nameButon}
                        placement="top"
                        content={nameButon}
                      >
                        <CButton
                          key={prefixButton + nameButon}
                          name={prefixButton + nameButon}
                          title={x.text}
                          id={prefixButton + nameButon}
                          value={x.text}
                          className="btn btn-secondary sm"
                          variant="ghost"
                          onClick={(ev) => returnAction((ev = x.text))}
                          size="sm"
                          disabled={disabled}
                        >
                          <FontAwesomeIcon
                            size="sm"
                            icon={x.icon ?? faQuestionCircle}
                          />
                        </CButton>
                      </CTooltip>
                    )
                  })}
                </div>
              </div>
            </CCol>
          </CRow>
        </>
      )
    } else {
      return <> </>
    }
  }

  const bodyModalForm = () => {
    if (currentAction === 'Buscar') {
      if (modulo === 25)
        return (
          <>
            <TablaListadoComprobantesPrv onSelected={() => {}} tipo={tipo} />
          </>
        )
      return (
        <>
          <TablaListadoComprobantes onSelected={() => {}} tipo={tipo} />
        </>
      )
    } else if (currentAction === 'Nuevo' || currentAction === 'Editar') {
      return <>{/* <Nuevo /> */}</>
    }
  }

  const renderBody = () => {
    return (
      <>
        <Modalform
          name="confirmarCambios"
          headerTitle={'Confirmar'}
          // childrenBody={renderBodyConfirm()}
          childrenFooter={renderFooter()}
          closeOnBackdrop={false}
          show={motrarAlerta.mostrar}
          onClose={() => closeModalAlerta()}
          centered={true}
          size="sm"
          key={'confirmarCambios'}
        />

        <CCard key="cardCuentas">
          <CCardHeader>
            <CRow>
              <CCol className="ms-0 d-flex align-items-start justify-content-start">
                {optionsForm()}
              </CCol>
              <CCol className="ms-0 d-flex align-items-start justify-content-end">
                <CButton
                  id="btnCloseAnticipo"
                  className="btn btn-secondary sm"
                  color="secondary"
                  variant="ghost"
                  size="sm"
                  onClick={() => closeModal()}
                >
                  <FontAwesomeIcon
                    size="lg"
                    icon={['fal', 'times']}
                    style={{ fontWeight: 1 }}
                  />
                </CButton>
              </CCol>
            </CRow>
          </CCardHeader>

          <CCardBody>{bodyModalForm()}</CCardBody>
        </CCard>
      </>
    )
  }

  const renderFooter = () => {
    if (currentAction === 'Buscar') {
      return (
        <>
          {confirmUpdate === true && (
            <CButton
              color="primary"
              onClick={() => {
                setShowConfirm(false)
                setConfirmUpdate(false)
                dispatch(setCurrentAction('Editar'))
              }}
            >
              {'Aceptar'}
            </CButton>
          )}
          ;
          <CButton
            color="secondary"
            onClick={() => {
              setShowConfirm(!showConfirm)
              setConfirmUpdate(false)
              setConfirmDelete(false)
            }}
          >
            {'Cancelar'}
          </CButton>
        </>
      )
    } else if (currentAction === 'Nuevo' || currentAction === 'Editar') {
      return (
        <>
          {confirmChange === true && (
            <CButton
              color="primary"
              onClick={() => {
                setShowConfirm(false)
                setConfirmUpdate(false)
                setConfirmChange(false)
                setMessageConfirm('')
                dispatch(setCurrentAction(displayDestino))
              }}
            >
              {'Aceptar'}
            </CButton>
          )}
          ;
          <CButton
            color="secondary"
            onClick={() => {
              setShowConfirm(!showConfirm)
              setConfirmUpdate(false)
              setConfirmDelete(false)
            }}
          >
            {'Cancelar'}
          </CButton>
        </>
      )
    }
  }

  const closeModalAlerta = () => {}

  const closeModal = () => {
    props.onClose()
  }

  const renderBodyConfirm = () => {
    return <> {messageConfirm} </>
  }

  const confirmAction = () => {
    return (
      <Modalform
        name="confirmarCambios"
        headerTitle={'Confirmar'}
        childrenBody={renderBodyConfirm()}
        childrenFooter={renderFooter()}
        closeOnBackdrop={false}
        show={showConfirm}
        onClose={() => setShowConfirm(!showConfirm)}
        centered={true}
        size="sm"
        key={'confirmarCambios'}
      />
    )
  }

  useEffect(() => {
    if (show === true) {
      dispatch(setRegistros([]))
      setShowModal(show)
      onSearch()
    }
  }, [show])

  useEffect(() => {
    const botones = menuDefectoSearch.map((acc) => {
      const boton: BotonMenu = {
        id: acc.text as any,
        icon: menuService.getIcon(acc.icon),
        text: acc.text,
      }
      return boton
    })
    setOptions(botones)
  }, [])

  return (
    <>
      {showConfirm === true &&
        ReactDom.createPortal(confirmAction(), document.body)}

      <CRow key="rowMain">
        <CCol key="colMain">
          <CModal
            visible={showModal}
            onClose={() => onClose()}
            size={'lg'}
            backdrop={'static'}
            // closeOnBackdrop={false}
            // name={'modalListadoComprobantes'}
            key={'modalListadoComprobantes'}
            alignment="center"
          >
            <CModalHeader>
              <CModalTitle>{getTitleModal()}</CModalTitle>
            </CModalHeader>
            <CModalBody>{renderBody()}</CModalBody>
          </CModal>
        </CCol>
      </CRow>
    </>
  )
}

export default React.memo(ModalListadoComprobantes)
