import { combineReducers } from '@reduxjs/toolkit'
import { SupplierssState } from '../types/types'
import { menuReducer } from './menuReducer'
import { searchProveedoresReducer } from './searchReducer'
import { tabsReducer } from './tabsReducer'
import { datosEdicionProveedoresReducer } from './editDataReducer'
import { modalProveedoresReducer } from './modalProveedor'
import { configuracionesProveedorReducer } from './configuracionProveedorReducer'

export const proveedoresReducer = combineReducers<SupplierssState>({
  menu: menuReducer,
  tabs: tabsReducer,
  search: searchProveedoresReducer,
  editData: datosEdicionProveedoresReducer,
  modalProveedores: modalProveedoresReducer,
  configuraciones: configuracionesProveedorReducer,
})
