import React from 'react'
import { SmallLoadingIndicator } from '../../../../../../../views/componentes/loadingindicator/loadingindicator'
import { consoleService } from '../../../../../../../services/console.service'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import { CBadge, CCard, CCardBody, CCardHeader, CFormInput } from '@coreui/react-pro'
import { formasPagoService } from '../../../../../../componentes/formasPagoLookUp/service/formasPago.service'
import { localesService } from '../../../../../../componentes/localesLookUp/service/locales.service'
import { ETipoTransaccion } from '../../../../../../componentes/formasPagoLookUp/store/type'
import { getIndexProvider } from '../../../../../../shared/helpers/funciones'
import { EFormasPago } from '../../../../../../../store/enum/formasPago'
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput'
import { MesesData } from '../../../../../../componentes/mesesLookUp'


const DetalleRolPago: React.FunctionComponent<any> = (props) => {
  const { data } = props

  const [loading, setLoading] = React.useState(false)
  const [loaded, setLoaded] = React.useState(false)
  const [rolPay, setRolPay] = React.useState<any>({})

  const onLoadDetail = React.useCallback(async (rolPayData) => {
    setLoading(true)
    consoleService.log(rolPayData, 'detail rol')
    const detailData: any = {}
    try {
      const dataFP = await formasPagoService.getFormasPago(ETipoTransaccion.ventas, "Elija una opción");
      const dataLocal = await localesService.getLocales("Elija un local")
      const flabor: string = rolPayData?.fechaLabor
      if (MesesData.length > 0) {
        const provider = MesesData.slice(0)
        const index = await getIndexProvider(provider, 'value', flabor.substring(3, 5))
        if (index > -1) {
          detailData.mes = MesesData[index]
        } else {
          detailData.mes = MesesData[0]
        }
      }
      if (dataFP?.auto && dataFP?.error === false) {
        const newFormPago = []
        dataFP?.auto.forEach(x => {
          if (x?.codigo === 7 || x?.codigo === -1 || x?.codigo === 6 || x?.codigo === 114 || x?.codigo === EFormasPago.otros) {
            newFormPago.push(x)
          }
        })
        const index = await getIndexProvider(newFormPago, 'codigo', rolPayData?.fPago)
        if (index > -1) {
          detailData.formaPago = newFormPago[index]
        } else {
          detailData.formaPago = newFormPago[0]
        }
      }
      if (dataLocal?.auto && dataLocal?.error === false) {
        const provider = dataLocal?.auto.slice(0)
        const index = await getIndexProvider(provider, 'codigo', rolPayData?.local)
        if (index > -1) {
          detailData.lugar = provider[index]
        } else {
          detailData.lugar = provider[0]
        }
      }
      detailData.codigo = rolPayData?.codigo
      detailData.dias = rolPayData?.dias
      detailData.d_horas = String(rolPayData?.HL)
      detailData.d_min = String(rolPayData?.ML)
      detailData.horarioNocturno = rolPayData?.valorJornada
      detailData.horarioSuple = Number(rolPayData?.valorHoraSuplementarias)
      detailData.horarioExtra = Number(rolPayData?.valorHoraExtra)
      detailData.comisiones = Number(rolPayData?.comisiones)
      detailData.bonoAportable = rolPayData?.ajusteSueldo
      detailData.iessPersonal = rolPayData?.iess
      detailData.iessPatronal = rolPayData?.pagoIess
      detailData.secap = rolPayData?.secap
      detailData.iece = rolPayData?.iece
      detailData.prestamos = rolPayData?.prestamos
      detailData.bonos = rolPayData?.bonos
      detailData.prestamosQuirografarios = rolPayData?.prestamosQuirografarios
      detailData.prestamosHipotecarios = rolPayData?.prestamosHipotecarios
      detailData.anticipos = rolPayData?.anticipos
      detailData.descuentos = rolPayData?.descuentos
      detailData.subsidioMaternidad = rolPayData?.subsidioMaternidad
      detailData.subsidioEnfermedad = rolPayData?.subsidioEnfermedad
      detailData.renta = rolPayData?.renta
      detailData.fondosReserva = rolPayData?.fondosReserva
      detailData.observaciones = rolPayData?.observaciones
    } catch (error) {
      consoleService.log(error.message)
    }
    setRolPay(detailData)
    setLoading(false)
    setLoaded(true)
  }, [])

  React.useEffect(() => {
    onLoadDetail(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  if (loading) {
    return <SmallLoadingIndicator mensaje="Cargando información adicional del registro..." />
  }

  if (!loaded) {
    return <></>
  }
  return (
    <>
      <RowContainer>
        <CustomCol xs="12" md="10">
          <CCard
            className="border-top-primary border-top-3"
            id="busqueda-modVentas-resumen"
          >
            <CCardHeader>
              <span style={{ float: 'left' }}>
                <CBadge color="secondary" textColor="black">
                  <strong>{`Código: ${rolPay?.codigo ?? 0}`}</strong>
                </CBadge>
              </span>
            </CCardHeader>
            <CCardBody>
              <RowContainer>
                <CustomCol sm="12" md="2">
                  <Labeled label="Mes:">
                    <CFormInput
                      value={rolPay?.mes?.label ?? ''}
                      readOnly
                      size="sm"
                    />
                  </Labeled>
                </CustomCol>
                <CustomCol sm="12" md="2">
                  <Labeled label="Dias:">
                    <CFormInput
                      value={`${rolPay?.dias ?? ''} ${rolPay?.d_horas ?? ''}:${rolPay?.d_min ?? ''}`}
                      readOnly
                      size="sm"
                    />
                  </Labeled>
                </CustomCol>
                <CustomCol sm="12" md="2">
                  <Labeled label="Horario Nocturno:">
                    <CFormInput
                      value={rolPay?.horarioNocturno ?? ''}
                      readOnly
                      size="sm"
                    />
                  </Labeled>
                </CustomCol>
                <CustomCol sm="12" md="2">
                  <Labeled label="Horario Suplementario:">
                    <CFormInput
                      value={rolPay?.horarioSuple ?? ''}
                      readOnly
                      size="sm"
                    />
                  </Labeled>
                </CustomCol>
                <CustomCol sm="12" md="2">
                  <Labeled label="Horario Extra:">
                    <CFormInput
                      value={rolPay?.horarioExtra ?? ''}
                      readOnly
                      size="sm"
                    />
                  </Labeled>
                </CustomCol>
              </RowContainer>

              <RowContainer>
                <CustomCol sm="12" md="2">
                  <Labeled label="Comisiones:">
                    <CFormInput
                      value={rolPay?.comisiones ?? ''}
                      readOnly
                      size="sm"
                    />
                  </Labeled>
                </CustomCol>
                <CustomCol sm="12" md="2">
                  <Labeled label="Bono Aportable:">
                    <CFormInput
                      value={rolPay?.bonoAportable ?? ''}
                      readOnly
                      size="sm"
                    />
                  </Labeled>
                </CustomCol>
                <CustomCol sm="12" md="2">
                  <Labeled label="IESS Personal:">
                    <CFormInput
                      value={rolPay?.iessPersonal ?? ''}
                      readOnly
                      size="sm"
                    />
                  </Labeled>
                </CustomCol>
                <CustomCol sm="12" md="2">
                  <Labeled label="IESS Patronal:">
                    <CFormInput
                      value={rolPay?.iessPatronal ?? ''}
                      readOnly
                      size="sm"
                    />
                  </Labeled>
                </CustomCol>
                <CustomCol sm="12" md="2">
                  <Labeled label="Secap:">
                    <CFormInput
                      value={rolPay?.secap ?? ''}
                      readOnly
                      size="sm"
                    />
                  </Labeled>
                </CustomCol>
              </RowContainer>

              <RowContainer>
                <CustomCol sm="12" md="2">
                  <Labeled label="IECE:">
                    <CFormInput
                      value={rolPay?.iece ?? ''}
                      readOnly
                      size="sm"
                    />
                  </Labeled>
                </CustomCol>
                <CustomCol sm="12" md="2">
                  <Labeled label="Bono Prestamos:">
                    <CFormInput
                      value={rolPay?.prestamos ?? ''}
                      readOnly
                      size="sm"
                    />
                  </Labeled>
                </CustomCol>
                <CustomCol sm="12" md="2">
                  <Labeled label="Bonos:">
                    <CFormInput
                      value={rolPay?.bonos ?? ''}
                      readOnly
                      size="sm"
                    />
                  </Labeled>
                </CustomCol>
                <CustomCol sm="12" md="2">
                  <Labeled label="Préstamos Quirografarios:">
                    <CFormInput
                      value={rolPay?.prestamosQuirografarios ?? ''}
                      readOnly
                      size="sm"
                    />
                  </Labeled>
                </CustomCol>
                <CustomCol sm="12" md="2">
                  <Labeled label="Préstamos Hipotecarios:">
                    <CFormInput
                      value={rolPay?.prestamosHipotecarios ?? ''}
                      readOnly
                      size="sm"
                    />
                  </Labeled>
                </CustomCol>
              </RowContainer>

              <RowContainer>
                <CustomCol sm="12" md="2">
                  <Labeled label="Anticipos:">
                    <CFormInput
                      value={rolPay?.anticipos ?? ''}
                      readOnly
                      size="sm"
                    />
                  </Labeled>
                </CustomCol>
                <CustomCol sm="12" md="2">
                  <Labeled label="Descuentos:">
                    <CFormInput
                      value={rolPay?.descuentos ?? ''}
                      readOnly
                      size="sm"
                    />
                  </Labeled>
                </CustomCol>
                <CustomCol sm="12" md="2">
                  <Labeled label="Subsidio Maternidad:">
                    <CFormInput
                      value={rolPay?.subsidioMaternidad ?? ''}
                      readOnly
                      size="sm"
                    />
                  </Labeled>
                </CustomCol>
                <CustomCol sm="12" md="2">
                  <Labeled label="Subsidio Enfermedad:">
                    <CFormInput
                      value={rolPay?.subsidioEnfermedad ?? ''}
                      readOnly
                      size="sm"
                    />
                  </Labeled>
                </CustomCol>
                <CustomCol sm="12" md="2">
                  <Labeled label="Renta:">
                    <CFormInput
                      value={rolPay?.renta ?? ''}
                      readOnly
                      size="sm"
                    />
                  </Labeled>
                </CustomCol>
              </RowContainer>

              <RowContainer>
                <CustomCol sm="12" md="2">
                  <Labeled label="Fondos de Reserva:">
                    <CFormInput
                      value={rolPay?.fondosReserva ?? ''}
                      readOnly
                      form=''
                      size="sm"
                    />
                  </Labeled>
                </CustomCol>
                <CustomCol sm="12" md="5">
                  <Labeled label="Observaciones:">
                    <CFormInput
                      value={rolPay?.observaciones ?? ''}
                      readOnly
                      size="sm"
                    />
                  </Labeled>
                </CustomCol>
              </RowContainer>
            </CCardBody>
          </CCard>
        </CustomCol>
      </RowContainer>
    </>
  )
}

export default React.memo(DetalleRolPago)