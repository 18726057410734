import React, { useCallback, useEffect, useState } from 'react'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput'
import { DataGrid, DateBox, TextBox } from 'devextreme-react'
import { TransferEditData } from '../../types/types'
import { TabState } from '../../../../../../../store/genericTabTypes'
import LocalesLookUp from '../../../../../../componentes/localesLookUp'
import { Column, Export, Pager, Paging } from 'devextreme-react/data-grid'
import { getAllowedPageSizes } from '../../../../../../../helpers/Helper'
import ItemsCountIndicator from '../../../../../../componentes/indicadorNroRegistros'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import { LocalOption } from '../../../../../../componentes/localesLookUp/store/type'
import {
  setDatosEdicion,
  setDestinationStore,
  setOriginStore,
} from '../../store/editDataReducer'
import { useSetToast } from '../../../../../../../hooks/useGlobalHooks'
import { ButtonTypes } from '../../../../../../../views/componentes/globalMenu/types'
import { setCurrentFunction } from '../../store/generalReducer'
import {
  clearButtonClick,
  setCurrentExecutingAction,
} from '../../store/tabsReducer'
import { TransfersServices } from '../../services/transferencias.services'
import { ToastTypes } from '../../../../../../../store/types'
import { onParseItems } from '../nuevo'
import VisualizaError from '../../../../../../ventas/pages/shared/visualizaError/visualizaError'
import ListErrosValidationForm from '../../../../../../componentes/listadoErroresValidacionForm'
import BlockUi from '../../../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../../../views/componentes/loadingindicator/loadingindicator'
import { isMobile } from 'react-device-detect'
import { useLoaderTransfers } from '../../customHooks'

interface INewProps extends React.PropsWithChildren {
  tab: TabState<TransferEditData>
  tabId: string
}

const Archivo: React.FunctionComponent<INewProps> = (props) => {
  const { tabId } = props

  const setToastMessage = useSetToast()
  const { playLoader, stopLoader } = useLoaderTransfers()
  const dispatch = useDispatch()

  const transferToSend = useSelector((state: RootState) => {
    return state.inventarios.movimientos.transferencias.editData[tabId]
  })
  const currentAction = useSelector(
    (state: RootState) =>
      state.inventarios.movimientos.transferencias.general.currentFunction,
  )
  const tabs = useSelector((state: RootState) => {
    return state.inventarios.movimientos.transferencias.tabs
  })
  const loader = useSelector((state: RootState) => {
    return state.inventarios.movimientos.transferencias.editData[tabId].loader
  })

  const [validationFormErrors, setValidationFormErrors] = useState<string[]>([])
  const [showPopupError, setShowPopupError] = React.useState<boolean>(false)
  const dataGrid = React.useRef<any>()

  const validateHours = useCallback(() => {
    const errors: Array<string> = []
    if (transferToSend.horaInicio === '00:00')
      errors.push('Ingrese la hora de inicio.')
    if (transferToSend.horaFin === '00:00')
      errors.push('Ingrese la hora de fin.')
    return { isValid: errors.length === 0, errors: errors }
  }, [transferToSend.horaFin, transferToSend.horaInicio])

  const onHandleSave = useCallback(async () => {
    dispatch(
      setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: ButtonTypes.archived,
      }),
    )
    const validateForm = validateHours()
    if (!validateForm.isValid) {
      setValidationFormErrors(validateForm.errors)
      setShowPopupError(true)
      setToastMessage(
        'Archivar Transferencia',
        'Existen Errores en los datos, por favor verifiquelos.',
        ToastTypes.Warning,
      )
      dispatch(
        setCurrentExecutingAction({
          tabKey: tabId,
          buttonType: undefined,
        }),
      )
    } else {
      try {
        playLoader(
          tabId,
          'Archivando transferencia . . .',
          ButtonTypes.archived,
        )
        const tarnsferDetails = await TransfersServices.setArchivedTranfer(
          transferToSend.codigo,
          transferToSend.horaInicio,
          transferToSend.horaFin,
        )
        stopLoader(tabId)
        setToastMessage(
          'Archivar Transferencia',
          tarnsferDetails.message,
          tarnsferDetails.error ? ToastTypes.Warning : ToastTypes.Success,
        )
      } catch (error) {
        stopLoader(tabId)
        setToastMessage('Archivar Transferencia', error, ToastTypes.Danger)
      }
    }
  }, [
    dispatch,
    playLoader,
    setToastMessage,
    stopLoader,
    tabId,
    transferToSend,
    validateHours,
  ])

  const onGetDetailsTransfer = useCallback(async () => {
    try {
      playLoader(tabId, 'Cargando transferencia . . .', ButtonTypes.archived)
      const tarnsferDetails = await TransfersServices.getTransferDetailAll(
        transferToSend.codigo,
      )
      stopLoader(tabId)
      if (!tarnsferDetails.error && tarnsferDetails.auto)
        dispatch(
          setDatosEdicion({
            key: tabId,
            transferencia: {
              ...transferToSend,
              detalles: onParseItems(tarnsferDetails.auto),
              loaded: true,
            },
          }),
        )
      setToastMessage(
        'Cargar Detalles de Transferencia',
        tarnsferDetails.message,
        tarnsferDetails.error ? ToastTypes.Warning : ToastTypes.Success,
      )
    } catch (error) {
      stopLoader(tabId)
      setToastMessage(
        'Cargar Detalles de Transferencia',
        error,
        ToastTypes.Danger,
      )
    }
  }, [dispatch, playLoader, setToastMessage, stopLoader, tabId, transferToSend])

  const handleButtonClick = useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.save:
          if (tabs.current === tabId) onHandleSave()
          break
        default:
          break
      }
      dispatch(setCurrentFunction(''))
      dispatch(clearButtonClick(tabId))
    },
    [dispatch, tabId, tabs, onHandleSave],
  )

  const onChangedHour = useCallback(
    (evt, valueToUpdate: string) => {
      if (evt.event && evt.value) {
        dispatch(
          setDatosEdicion({
            key: tabId,
            transferencia: {
              ...transferToSend,
              [valueToUpdate]: evt.value,
            },
          }),
        )
      }
    },
    [dispatch, tabId, transferToSend],
  )

  useEffect(() => {
    if (currentAction !== '') handleButtonClick(currentAction)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAction])

  useEffect(() => {
    if (transferToSend.loaded === false) onGetDetailsTransfer()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <BlockUi
      tag="div"
      loader={LoadingIndicator}
      blocking={loader.show && tabId === tabs.current}
      message={loader.mensaje}
    >
      {showPopupError && (
        <VisualizaError
          titulo="Error en Transferencias"
          mensaje={'Error al archivar'}
          content={
            <ListErrosValidationForm
              mainMessage={
                'Antes de guardar la información revise lo siguiente:'
              }
              errorsList={validationFormErrors}
              color={'danger'}
            />
          }
          onOk={() => setShowPopupError(false)}
        />
      )}
      <RowContainer className="m-2">
        <RowContainer>
          <CustomCol xs="12" md="2">
            <Labeled label="TRANSFERENCIA NRO:">
              <h5>{transferToSend?.codigo ?? 'N/A'}</h5>
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="2">
            <Labeled label="Fecha:">
              <DateBox
                displayFormat="dd/MM/yyyy"
                value={transferToSend.fecha}
                readOnly={true}
              />
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="7">
            <Labeled label="Observaciones:">
              <TextBox value={transferToSend.descripcion} readOnly={true} />
            </Labeled>
          </CustomCol>
        </RowContainer>
        <RowContainer>
          <CustomCol xs="12" md="4">
            <Labeled label="Local Origen:">
              <LocalesLookUp
                onChanged={(store: LocalOption) => {
                  dispatch(setOriginStore({ key: tabId, store: store }))
                }}
                onChangedOptions={() => {}}
                selected={transferToSend.origenLocal}
                disabled={true}
              />
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="4">
            <Labeled label="Local Destino:">
              <LocalesLookUp
                onChanged={(store: LocalOption) => {
                  dispatch(setDestinationStore({ key: tabId, store: store }))
                }}
                onChangedOptions={() => {}}
                selected={transferToSend.destinoLocal}
                disabled={true}
              />
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="2">
            <Labeled label="Hora Inicio:">
              <DateBox
                type="time"
                value={transferToSend.horaInicio}
                onValueChanged={(evt: unknown) =>
                  onChangedHour(evt, 'horaInicio')
                }
              />
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="2">
            <Labeled label="Hora Fin:">
              <DateBox
                type="time"
                value={transferToSend.horaFin}
                onValueChanged={(evt: unknown) => {
                  onChangedHour(evt, 'horaFin')
                }}
              />
            </Labeled>
          </CustomCol>
        </RowContainer>
        <RowContainer>
          <ItemsCountIndicator items={transferToSend.detalles} />
          <DataGrid
            ref={dataGrid}
            selection={{ mode: 'single' }}
            hoverStateEnabled={true}
            dataSource={transferToSend.detalles}
            showBorders={true}
            allowColumnReordering={true}
            allowColumnResizing={true}
            columnAutoWidth={true}
            keyExpr="itemCodigo"
            key="gridListItems"
          >
            <Export enabled={true} allowExportSelectedData={true} />
            <Paging defaultPageSize={20} />
            <Pager
              visible={transferToSend.detalles.length > 0 ? true : false}
              allowedPageSizes={getAllowedPageSizes(transferToSend.detalles)}
              displayMode={'full'}
              showPageSizeSelector={true}
              showInfo={true}
              showNavigationButtons={true}
            />
            <Column
              dataField="codigoUsuario"
              caption="Código"
              width={isMobile ? '120px' : '10%'}
            />
            <Column
              dataField="barras"
              caption="Barras"
              width={isMobile ? '120px' : '10%'}
            />
            <Column
              dataField="descripcion"
              caption="Descripción"
              width={isMobile ? '300px' : '32%'}
            />
            <Column
              dataField="unidadDescripcion"
              caption="Unidad"
              width={isMobile ? '80px' : '8%'}
            />
            <Column
              dataField="cantidad"
              caption="Cantidad"
              width={isMobile ? '80px' : '8%'}
            />
            <Column
              dataField="referencia"
              caption="Referencia"
              width={isMobile ? '80px' : '12%'}
            />
            <Column
              dataField="pvp"
              caption="PVP"
              width={isMobile ? '80px' : '10%'}
            />
            <Column
              dataField="pvd"
              caption="PVD"
              width={isMobile ? '80px' : '10%'}
            />
          </DataGrid>
        </RowContainer>
      </RowContainer>
    </BlockUi>
  )
}

export default Archivo
