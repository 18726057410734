import React from 'react'
import { GlobalMenu } from '../../../../../../../views/componentes/globalMenu/globalMenu'
import TabControlTransferencias from './index'
import { AccionMenu } from '../../../../../../../store/types'
import { ButtonTypes } from '../../../../../../../views/componentes/globalMenu/types'
import { TabsState } from '../../../../../../../store/genericTabTypes'
import { Aplicacion } from '../../../../../store/types'

interface ITranferenciasMainProps extends React.PropsWithChildren {
  accciones: Array<AccionMenu>
  // eslint-disable-next-line no-unused-vars
  onMenuButtonClick: (action: AccionMenu) => void
  // eslint-disable-next-line no-unused-vars
  getButtonDisabled: (action: AccionMenu) => boolean
  currentWorking: ButtonTypes
  tabs: TabsState<any>
  // eslint-disable-next-line no-unused-vars
  onTabChanged: (tabKey: string) => void
  // eslint-disable-next-line no-unused-vars
  onTabClosing: (tabKey: string) => void
}

const TransferenciasMain = (props: ITranferenciasMainProps) => {
  const {
    accciones,
    onMenuButtonClick,
    getButtonDisabled,
    currentWorking,
    tabs,
    onTabChanged,
    onTabClosing,
  } = props

  return (
    <div style={{ backgroundColor: '#fff' }}>
      <GlobalMenu
        printButtonAsDropdown={false}
        showExportDesingOptions
        acciones={accciones}
        onClick={onMenuButtonClick}
        getButtonDisabled={getButtonDisabled}
        currentWorking={currentWorking}
      />
      <TabControlTransferencias
        tabsState={tabs}
        onTabChanged={onTabChanged}
        ontabClosing={onTabClosing}
        tipoAplicacion={Aplicacion.transferencias}
      />
    </div>
  )
}

export default TransferenciasMain
