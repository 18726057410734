export enum AllowDteRetention {
  comprobanteCreditoFiscal = 'comprobanteCreditoFiscal',
  consumidorFinal = 'consumidorFinal',
  notaCredito = 'notaCredito',
  notaDebito = 'notaDebito',
}

export enum AllowDteCompras {
  consumidorFinal = '01',
  creditoFiscal = '03',
}
