import { CAlert, CLink } from '@coreui/react-pro';
import Button from 'devextreme-react/button';
import React from 'react';
import { useDispatch } from 'react-redux';
import { setAsideShow } from '../../../../store/reducers';

interface ISinPuntoventa {
  mensaje: string,
  onClick: () => void
}

export function SinPuntoDeVenta(props: ISinPuntoventa) {
  const dispatch = useDispatch();
  return (
    <CAlert
      color='danger'
      visible={true}
      dismissible={false}
    >
      <div>
        <span style={{ paddingRight: '5px' }} >{props.mensaje}</span>
        <div style={{ display: 'inline', }} onClick={() => {
          props.onClick();
          dispatch(setAsideShow(true));
        }}>
          <Button stylingMode='text' type='default' icon='preferences' onClick={() => {
            props.onClick();
            dispatch(setAsideShow(true));
          }} >Configuraciones</Button>
        </div>

      </div>
    </CAlert>
  );
}
