import { AccionMenu } from "../../../../../../store/types"
import { ModalOpen } from "../../../../../contabilidad/general/store/types"

export enum TIPOS_BOTONES {
  BOTON_NUEVO = 'Nuevo',
  BOTON_GUARDAR = 'Guardar',
  BOTON_EDITAR = 'Editar',
  BOTON_DESHACER = 'Deshacer',
  BOTON_BUSCAR = 'Buscar',
  BOTON_ELIMINAR = 'Eliminar',
  BOTON_EXPORTAR = 'Exportar',
  BOTON_IMPORTAR = 'Importar',
  BOTON_LIMPIAR = 'Limpiar',
}

export type Registros = {
  TIPO: any,
  COMPROBANTE: string,
  PROBLEMA: string,
}

export type Seleccionado = {
  index: any,
  row: any,
  selectedRow: any,
}

export type OptionGrupos = {
  id: number,
  orden: number,
  check: boolean,
  label: string,
  labelId: string,
  cod: string,
}

export type Anticipo = {
  ANT_CODIGO: number,
  ANT_NUMERO: string,
  ANT_PERTENECE: number,
  ANT_TIPO: string,
  ANT_ASOCIADO: number,
  ANT_ASOCIADO_ID: string,
  ANT_ASOCIADO_DES: string,
  ANT_FPAGO: number,
  ANT_FPAGO_DES: string,
  ANT_CONCEPTO: string,
  ANT_REGISTRO: Date,
  ANT_DOCUMENTO: string,
  ANT_VALOR: string,
  ANT_DEVENGADO: string,
  ANT_SALDO: string,
  ANT_SALDOREAL: string,
  ASO_CODIGO: number,
  ASI_NUMERO: number,
  ASI_NROTIPO: number,
  ANT_ORIGEN: string,
  ANT_OASOCIADO: string,
  PTO_CODIGO: number,
  LOC_NOMBRE: string,
  PTO_HOST: string,
  ANT_ESTADO: number,
  ACV_VALOR: number,
  anticipoSaldoReal?: any
}

export type Asignado = {
  ACV_CODIGO: number,
  ACV_TIPO: string,
  ACV_ASOCIADO: number,
  ASOCIADO: number,
  FECHA: Date,
  ANT_CODIGO: number,
  ANT_NUMERO: string,
  ANT_PERTENECE: string,
  ANT_TIPO: string,
  ANT_ASOCIADO: string,
  ANT_ASOCIADO_ID: string,
  ANT_ASOCIADO_DES: string,
  ANT_FPAGO: number,
  ANT_FPAGO_DES: string,
  ANT_CONCEPTO: string,
  ANT_REGISTRO: string,
  ANT_DOCUMENTO: string,
  ANT_VALOR: string,
  ANT_DEVENGADO: string,
  ANT_SALDO: string,
  ANT_SALDOREAL: string,
  ASO_CODIGO: string,
  ASI_NUMERO: number,
  ASI_NROTIPO: number,
  ANT_ORIGEN: string,
  ANT_OASOCIADO: string,
  PTO_CODIGO: number,
  LOC_NOMBRE: string,
  PTO_HOST: string,
  ACV_VALOR: string,
  anticipoSaldoReal?: any,
  anticipoCompraVentaValor?: any,
  anticipoValor?: any,
  anticipoVenta?: any
}

export type FormularioAnticipos = {
  TOTAL: number,
  DESCUADRE: number,
  CLASE_DESCUADRE: string,
  VALOR_SIN_RETENCION: number,
  FECHA: Date | null,
  ACV_TIPO: string,
  COMPROBANTE: string,
  DOCUMENTO: string,
}

export type Alerta = {
  mostrar: boolean,
  mensaje: string
}

export type ModalAnticiposState = {
  modulo: string,
  currentAction: string,
  currentFunction: string,
  aplicacion: string,
  acciones: Array<AccionMenu>,
  registros: Array<Registros>,
  seleccionado: Seleccionado,
  refrescaTabla: boolean,
  modulos: Array<OptionGrupos>,
  modal: ModalOpen,
  anticipos: Array<Anticipo>,
  anticiposBackup: Array<Anticipo>,
  asignados: Array<Asignado>,
  asignadosBackup: Array<Asignado>,
  rowsSelectedAnticipos: Seleccionado,
  rowsSelectedAsignados: Seleccionado,
  formularioAnticipos: FormularioAnticipos
  motrarAlerta: Alerta,
}