import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  EModeloFacturacionSLV,
  ETipoTransmisionSLV,
  LoaderInfo,
  TEstado,
  TipoComprobante,
  TiposComprobantesSLV,
} from '../../../../../../store/types'
import { CustomDictionary } from '../../../../../ventas/types/generics'
import {
  ComprasSVDatosEdicion,
  THDetalleEdition,
  TTotalesFacturaSE,
} from '../types/types'
import { ProveedorBusqueda } from '../../../../../proveedores/store/types'
import {
  FacturacionEdicionPayload,
  TiposPagosVenta,
} from '../../../../../ventas/pages/ventas/types/types'
import {
  CuotaCreditoVenta,
  FormaPagoModuloVentas,
} from '../../../../../ventas/types/types'
import { ventasClearDatosEdicion } from '../../../../../ventas/store/reducers'
import { DetalleDteComprasSVRedux } from '../types/detalleDteComprasSV-03'
import { Ciudad } from '../../../../../ventas/store/types'
import { SustentosTributarios, TiposCompras } from '../../../../types/types'
import { Proyecto } from '../../../../../bancos/pages/movimientosBancos/ingresosNC/store/types'
import { LocalOption } from '../../../../../ventas/pages/cuadres/types/types'
import { TFormaPagoOption } from '../../../../../componentes/formasPagoLookUp/store/type'
import { Local, Project } from '../../../../../shared/types/types'
import { TTipoCompraOption } from '../../../../../componentes/lookUpTipoCompra/store/type'
import { SutentosTributarios } from '../../../../../componentes/sustentosTributarioLookUp/types/types'
import { TModeloEmisionSv } from '../../../../../componentes/lookUpModoEmision/store/type'
import { TModeloTipoTrasmision } from '../../../../../componentes/lookUpTipoTrasmision/store/type'

const initialState: CustomDictionary<ComprasSVDatosEdicion> = {}
const datosEdicionComprasSlice = createSlice({
  name: 'comprasComprasSVDatosEdicion',
  initialState: initialState,
  reducers: {
    ventasClearDatosEdicion,
    setDatosEdicion(
      state,
      action: PayloadAction<FacturacionEdicionPayload<ComprasSVDatosEdicion>>,
    ) {
      state[action.payload.key] = action.payload.data
    },
    updateTotales(
      state,
      action: PayloadAction<{
        key: number | string
        totales: TTotalesFacturaSE
      }>,
    ) {
      state[action.payload.key].totales = action.payload.totales
      state[action.payload.key].descuentoGlobal =
        action.payload.totales.descuentoGlobal
    },
    updateDetalles(
      state,
      action: PayloadAction<{
        key: number | string
        detalles: Array<DetalleDteComprasSVRedux>
      }>,
    ) {
      state[action.payload.key].detalles = action.payload.detalles
    },
    addDetalle(
      state,
      action: PayloadAction<{
        key: number | string
        detalle: DetalleDteComprasSVRedux
      }>,
    ) {
      state[action.payload.key].detalles.push(action.payload.detalle)
    },
    updateProveedor(
      state,
      action: PayloadAction<{
        key: number | string
        proveedor: ProveedorBusqueda | null
        formasPago: Array<FormaPagoModuloVentas>
        formaPago: Array<FormaPagoModuloVentas>
        detalles?: Array<DetalleDteComprasSVRedux>
      }>,
    ) {
      state[action.payload.key].proveedor = action.payload.proveedor
      state[action.payload.key].formasPago = action.payload.formasPago
      state[action.payload.key].formaPago = action.payload.formaPago

      if (action.payload.detalles)
        state[action.payload.key].detalles = action.payload.detalles
    },
    updateProveedorOnly(
      state,
      action: PayloadAction<{
        key: number | string
        proveedor: ProveedorBusqueda
      }>,
    ) {
      state[action.payload.key].proveedor = action.payload.proveedor
    },
    setFechaEmision(
      state,
      action: PayloadAction<{ key: string; fecha: string }>,
    ) {
      state[action.payload.key].fechaEmision = action.payload.fecha
    },
    setFechaAutorizacion(
      state,
      action: PayloadAction<{ key: string; fechaAutorizacion: string }>,
    ) {
      state[action.payload.key].fechaAutorizacion =
        action.payload.fechaAutorizacion
    },
    updateObservaciones(
      state,
      action: PayloadAction<{ key: number | string; observaciones: string }>,
    ) {
      state[action.payload.key].observaciones = action.payload.observaciones
    },
    initDatosEdicion(
      state,
      action: PayloadAction<FacturacionEdicionPayload<ComprasSVDatosEdicion>>,
    ) {
      if (!state[action.payload.key]) {
        state[action.payload.key] = action.payload.data
      }
    },
    setEditLoader(
      state,
      action: PayloadAction<{ key: number | string; info: LoaderInfo }>,
    ) {
      state[action.payload.key].loader = action.payload.info
    },
    setMuestraError(
      state,
      action: PayloadAction<{
        key: number | string
        tieneError: boolean
        mensajeError: string
      }>,
    ) {
      state[action.payload.key].tieneError = action.payload.tieneError
      state[action.payload.key].mensajeError = action.payload.mensajeError
    },
    setImprimir(
      state,
      action: PayloadAction<{ key: number | string; imprimir: boolean }>,
    ) {
      state[action.payload.key].imprimir = action.payload.imprimir
    },
    setFormaPago(
      state,
      action: PayloadAction<{
        key: number | string
        formaPago?: FormaPagoModuloVentas
      }>,
    ) {
      const { formaPago, key } = action.payload
      state[key].formaPago = formaPago ? [formaPago] : []
      if (formaPago && formaPago.descripcion !== 'CREDITO') {
        //Limpiar cuotas
        state[key].cuotas = []
      }
    },
    setCuotasCredito(
      state,
      action: PayloadAction<{
        key: number | string
        cuotas: Array<CuotaCreditoVenta>
      }>,
    ) {
      const { key, cuotas } = action.payload
      state[key].cuotas = cuotas
    },
    setMetodosAgregados(
      state,
      action: PayloadAction<{
        key: number | string
        tiposPagos: Array<TiposPagosVenta>
      }>,
    ) {
      const { key, tiposPagos } = action.payload
      state[key].tiposPagos = tiposPagos
    },
    setMostrarBusquedaItems(
      state,
      action: PayloadAction<{ key: string; mostrarBusquedaItems: boolean }>,
    ) {
      const { key, mostrarBusquedaItems } = action.payload
      state[key].mostrarBusquedaItems = mostrarBusquedaItems
    },
    setTipoClasificacion(
      state,
      action: PayloadAction<{
        key: number | string
        tipoClasificacion?: TEstado | null
      }>,
    ) {
      const { tipoClasificacion, key } = action.payload
      state[key].tipoClasificacion = tipoClasificacion
    },
    setTipoSector(
      state,
      action: PayloadAction<{
        key: number | string
        tipoSector?: TEstado | null
      }>,
    ) {
      const { tipoSector, key } = action.payload
      state[key].tipoSector = tipoSector
    },
    setTipoCosto(
      state,
      action: PayloadAction<{
        key: number | string
        tipoCosto?: TEstado | null
      }>,
    ) {
      const { tipoCosto, key } = action.payload
      state[key].tipoCosto = tipoCosto
    },
    setTipoOperacion(
      state,
      action: PayloadAction<{
        key: number | string
        tipoOperacion?: TEstado | null
      }>,
    ) {
      const { tipoOperacion, key } = action.payload
      state[key].tipoOperacion = tipoOperacion
    },
    updateLocalidadesReceptorOnly(
      state,
      action: PayloadAction<{
        key: number | string
        localidadReceptor: Ciudad | null
      }>,
    ) {
      state[action.payload.key].localidadReceptor =
        action.payload.localidadReceptor
    },
    setFechaPropuesta(
      state,
      action: PayloadAction<{
        key: number | string
        fechaPropuesta: string
      }>,
    ) {
      state[action.payload.key].fechaPropuesta = action.payload.fechaPropuesta
    },
    setComprobanteElectronico(
      state,
      action: PayloadAction<{
        key: number | string
        comprobanteElectronico: boolean
      }>,
    ) {
      state[action.payload.key].comprobanteElectronico =
        action.payload.comprobanteElectronico
    },
    setEstablecimiento(
      state,
      action: PayloadAction<{
        key: number | string
        establecimiento: string
      }>,
    ) {
      state[action.payload.key].establecimiento = action.payload.establecimiento
    },
    setPuntoEmision(
      state,
      action: PayloadAction<{
        key: number | string
        puntoEmision: string
      }>,
    ) {
      state[action.payload.key].puntoEmision = action.payload.puntoEmision
    },
    setNumero(
      state,
      action: PayloadAction<{
        key: number | string
        numero: string
      }>,
    ) {
      state[action.payload.key].numero = action.payload.numero
    },
    setAutorizacion(
      state,
      action: PayloadAction<{
        key: number | string
        autorizacion: string
      }>,
    ) {
      state[action.payload.key].autorizacion = action.payload.autorizacion
    },
    setTipoCompra(
      state,
      action: PayloadAction<{ key: number | string; tipoCompra: TiposCompras }>,
    ) {
      const { tipoCompra, key } = action.payload
      state[key].tipoCompra = tipoCompra
    },
    setSustentosTributarios(
      state,
      action: PayloadAction<{
        key: number | string
        sustentosTributarios: Array<SustentosTributarios>
      }>,
    ) {
      const { sustentosTributarios, key } = action.payload
      state[key].sustentosTributarios = sustentosTributarios
    },
    setLocal(
      state,
      action: PayloadAction<{ key: number | string; local: LocalOption }>,
    ) {
      const { local, key } = action.payload
      state[key].local = local
    },
    setSustentoTributario(
      state,
      action: PayloadAction<{
        key: number | string
        sustentoTributario: SustentosTributarios
      }>,
    ) {
      const { sustentoTributario, key } = action.payload
      state[key].sustentoTributario = sustentoTributario
    },
    setProyecto(
      state,
      action: PayloadAction<{ key: number | string; proyecto: Proyecto }>,
    ) {
      const { proyecto, key } = action.payload
      state[key].proyecto = proyecto
    },
    setLocales(
      state,
      action: PayloadAction<{
        key: number | string
        locales: Array<LocalOption>
      }>,
    ) {
      const { locales, key } = action.payload
      state[key].locales = locales
    },
    setCodigoGeneracion(
      state,
      action: PayloadAction<{
        key: string
        codigoGeneracion: string
      }>,
    ) {
      state[action.payload.key].codigoGeneracion =
        action.payload.codigoGeneracion
    },
    setSelloRecibido(
      state,
      action: PayloadAction<{
        key: string
        selloRecibido: string
      }>,
    ) {
      state[action.payload.key].selloRecibido = action.payload.selloRecibido
    },
    setNumeroControl(
      state,
      action: PayloadAction<{
        key: string
        numeroControl: string
      }>,
    ) {
      state[action.payload.key].numeroControl = action.payload.numeroControl
    },
    setNumeroDte(
      state,
      action: PayloadAction<{
        key: string
        establecimiento: string
        puntoEmision: string
        numero: string
      }>,
    ) {
      state[action.payload.key].establecimiento = action.payload.establecimiento
      state[action.payload.key].puntoEmision = action.payload.puntoEmision
      state[action.payload.key].numero = action.payload.numero
    },

    updateRetenerRentaValorOnly(
      state,
      action: PayloadAction<{
        key: number | string
        retenerRentaValor: number
      }>,
    ) {
      state[action.payload.key].retenerRentaValor =
        action.payload.retenerRentaValor
    },
    updateIsRetenerRenta(
      state,
      action: PayloadAction<{
        key: number | string
        isRetenerRenta: boolean
      }>,
    ) {
      state[action.payload.key].isRetenerRenta = action.payload.isRetenerRenta
    },
    updateRetenerIvaValorOnly(
      state,
      action: PayloadAction<{
        key: number | string
        retenerIvaValor: number
      }>,
    ) {
      state[action.payload.key].retenerIvaValor = action.payload.retenerIvaValor
    },
    updateIsRetenerIva(
      state,
      action: PayloadAction<{
        key: number | string
        isRetenerIva: boolean
      }>,
    ) {
      state[action.payload.key].isRetenerIva = action.payload.isRetenerIva
    },
    setTipoDte(
      state,
      action: PayloadAction<{
        key: string
        tipoDte: TiposComprobantesSLV
      }>,
    ) {
      state[action.payload.key].tipoDte = action.payload.tipoDte
    },

    setHFormasPago(
      state,
      action: PayloadAction<{
        key: string
        formaPago: TFormaPagoOption | null
      }>,
    ) {
      state[action.payload.key].homologar.formaPago = action.payload.formaPago
    },

    setHProyecto(
      state,
      action: PayloadAction<{
        key: string
        proyecto: Project | null
      }>,
    ) {
      state[action.payload.key].homologar.proyecto = action.payload.proyecto
    },

    setHLugar(
      state,
      action: PayloadAction<{
        key: string
        lugar: Local | null
      }>,
    ) {
      state[action.payload.key].homologar.lugar = action.payload.lugar
    },

    setHTipoCompra(
      state,
      action: PayloadAction<{
        key: string
        tipoCompra: TTipoCompraOption | null
      }>,
    ) {
      state[action.payload.key].homologar.tipoCompra = action.payload.tipoCompra
    },

    setHSustentoTributario(
      state,
      action: PayloadAction<{
        key: string
        sutentoTributario: SutentosTributarios | null
      }>,
    ) {
      state[action.payload.key].homologar.sutentoTributario =
        action.payload.sutentoTributario
    },

    setHDetalleHomologado(
      state,
      action: PayloadAction<{
        key: string
        itemsHomologados: THDetalleEdition[] | []
      }>,
    ) {
      state[action.payload.key].homologar.itemsHomologados =
        action.payload.itemsHomologados
    },
    setModoEmision(
      state,
      action: PayloadAction<{
        key: string
        modeloFacturacion: TModeloEmisionSv | null
      }>,
    ) {
      state[action.payload.key].modeloFacturacion =
        action.payload.modeloFacturacion
    },
    setTipoTrasmision(
      state,
      action: PayloadAction<{
        key: string
        tipoTrasmision: TModeloTipoTrasmision | null
      }>,
    ) {
      state[action.payload.key].tipoTrasmision = action.payload.tipoTrasmision
    },
    setTipoComprobante(
      state,
      action: PayloadAction<{
        key: string
        tipoComprobante: TipoComprobante | null
      }>,
    ) {
      state[action.payload.key].tipoComprobante = action.payload.tipoComprobante
    },
    setNumeroSerie(
      state,
      action: PayloadAction<{
        key: number | string
        numeroSerie: string
      }>,
    ) {
      state[action.payload.key].numeroSerie = action.payload.numeroSerie
    },
  },
})

export const {
  setNumeroSerie,
  setTipoComprobante,
  setModoEmision,
  setTipoTrasmision,
  setFechaAutorizacion,
  setHDetalleHomologado,
  setHFormasPago,
  setHProyecto,
  setHLugar,
  setHTipoCompra,
  setHSustentoTributario,
  setTipoDte,
  updateIsRetenerIva,
  updateRetenerIvaValorOnly,
  updateIsRetenerRenta,
  updateRetenerRentaValorOnly,
  setNumeroDte,
  setNumeroControl,
  setCodigoGeneracion,
  setSelloRecibido,
  setFechaEmision,
  initDatosEdicion,
  setDatosEdicion,
  updateTotales,
  updateDetalles,
  updateProveedor,
  updateObservaciones,
  setEditLoader,
  ventasClearDatosEdicion: clearDatosEdicion,
  setMuestraError,
  addDetalle,
  setImprimir,
  setFormaPago,
  setCuotasCredito,
  setMostrarBusquedaItems,
  updateProveedorOnly,
  setMetodosAgregados,
  setTipoClasificacion,
  setTipoSector,
  setTipoCosto,
  setTipoOperacion,
  updateLocalidadesReceptorOnly,
  setFechaPropuesta,
  setComprobanteElectronico,
  setEstablecimiento,
  setPuntoEmision,
  setNumero,
  setAutorizacion,
  setTipoCompra,
  setSustentosTributarios,
  setLocal,
  setProyecto,
  setSustentoTributario,
  setLocales,
} = datosEdicionComprasSlice.actions
export const datosEdicionReducer = datosEdicionComprasSlice.reducer
