import { DateUtils } from '../../../helpers/dateUtils'
import { RequestHelper } from '../../../helpers/requestHelper'
import { SesionService } from '../../../services/sesion.service'
import {
  RetencionCompraIngresar,
  RetencionDetalleInfo,
  RetencionFullInfo,
  RetencionInfo,
} from '../types/types'

export const RetencionCompraService = {
  buscarRetenciones,
  getRetencionByCode,
  guardar,
  getDetalles,
  anularRetencion,
  obtieneTotalSinRetencion,
}

async function buscarRetenciones(
  fechaInicio: string,
  fechaFin: string,
  filtros,
): Promise<RetencionInfo[]> {
  const queryData = {
    desde: DateUtils.pickersDateToApiDate(fechaInicio),
    hasta: DateUtils.pickersDateToApiDate(fechaFin),
    retencionCompraNumero: '',
    proveedorNombre: '',
    retencionCompraFormaPago: '',
    compraNumeroCompleto: '',
    claveClave: filtros.claveAcceso ?? '',
    proveedorIdentificacion: '',
  }
  const apiData = await RequestHelper.get<Array<RetencionInfo>>(
    'compras',
    'retenciones/getAll',
    '',
    queryData,
  )
  return apiData
}

async function getRetencionByCode(code: number): Promise<RetencionFullInfo> {
  const queryData = {
    codigo: code,
  }
  const apiData = await RequestHelper.get<Array<RetencionFullInfo>>(
    'compras',
    'retenciones/get',
    '',
    queryData,
  )
  if (apiData.length === 0) {
    // eslint-disable-next-line no-throw-literal
    throw 'No se encuentra una retención con código ' + code
  }
  return apiData[0]
}

async function getDetalles(code: number): Promise<Array<RetencionDetalleInfo>> {
  const queryData = {
    codigo: code,
  }
  const apiData = await RequestHelper.get<Array<RetencionDetalleInfo>>(
    'compras',
    'retenciones/getDetails',
    '',
    queryData,
  )
  return apiData
}

async function guardar(
  retencion: RetencionCompraIngresar,
): Promise<RetencionInfo> {
  const sesion = SesionService.getCurrentSesion()
  if (retencion?.paisCodigo) {
    retencion.paisCodigo = sesion.empresa?.codigoPais ?? 1
  }
  const result = await RequestHelper.post<RetencionInfo>(
    'compras',
    'retenciones/ingresar',
    retencion,
  )
  return result
  //http://dev.acatha.com/amfphp/services/SIGNUM/API/v4/compras/retenciones/ingresar'
}

async function anularRetencion(info: RetencionInfo) {
  const postData = {
    infoComprobante: {
      retencionCodigo: info.codigo,
    },
  }
  const result = await RequestHelper.deleteRequest<RetencionInfo>(
    'compras',
    'retenciones/cancel',
    '',
    undefined,
    postData,
  )
  return result
}

async function obtieneTotalSinRetencion(objeto): Promise<any> {
  const obj = {
    compra: objeto['codigo'],
    tipoPago: objeto['tipoPago'],
  }
  const result = await RequestHelper.getAll<any>(
    'compras/retenciones',
    'getTotalWithoutRetention',
    '',
    obj,
  )
  return result
}
