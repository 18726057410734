import React from 'react'
import './labeled.scss'

interface ILabeledInputProps extends React.PropsWithChildren {
  label: string
  position?: 'left' | 'top'
  children?: React.ReactNode
  styleCustom?: string
  underlineLabel?: boolean
}

const Labeled: React.FunctionComponent<ILabeledInputProps> = (props) => {
  const { underlineLabel = true } = props
  const position = props.position ?? 'top'

  function getStyle() {
    const st = {
      marginRight: '0.8rem',
      width: 'auto',
      whiteSpace: 'nowrap',
    }
    return st
  }

  if (position === 'top') {
    return (
      <div className="labeledContainer ">
        <label
          className={
            (underlineLabel ? 'labeledLabel' : '') + ' form-label-sm mt-2 mb-2'
          }
        >
          {props.label}
        </label>
        <div className="labeledElements">{props.children}</div>
      </div>
    )
  }

  return (
    <div className="labeledContainer d-flex justify-content-start align-items-center">
      <label
        className={
          (underlineLabel ? 'labeledLabel' : '') + ' form-label-sm mt-2 mb-2'
        }
        style={getStyle()}
      >
        {props.label}
      </label>
      {props.children}
    </div>
  )
}

export default Labeled
