import { DateUtils, formatoFechasApi } from '../../../../helpers/dateUtils';
import { RequestHelper } from '../../../../helpers/requestHelper'
import { utilidades } from '../../../../helpers/utilidades';
import { SesionService } from '../../../../services/sesion.service';
import { CapitalizeMode } from '../../../../store/types';
import { SutentosTributarios } from '../types/types';

export const sustentoTributarioServices = { getTipos }

async function getTipos(): Promise<any> {
  try {
    const data: any = {};
    const sesion = SesionService.getCurrentSesion()
    const keysToCache: any = {
      ...data,
      dateKey: DateUtils.getCurrentDateAsString(formatoFechasApi),
      empresa: sesion?.empresa?.codigo,
    }
    const cacheKey = 'sustentosTributariosOptionsAll' + JSON.stringify(keysToCache);
    const cached = sessionStorage.getItem(cacheKey);
    if (cached) {
      return { error: false, auto: JSON.parse(cached) };
    } else {
      const resApi = await RequestHelper.getAll<any>(
        'generales/sustentos',
        'getAll',
        '',
        data
      )
      const opt: Array<SutentosTributarios> = [];
      if (resApi?.auto && resApi?.auto.length > 0) {
        for (const x of resApi.auto) {
          opt.push({
            codigo: x?.codigo ?? '',
            tipo: utilidades.capText(x?.tipo, CapitalizeMode.upperFirst) ?? '', //to show
            estado: parseInt(x?.estado) ?? 0,
            descripcionEstado: x?.descripcionEstado ?? '',
          })
        }
      }
      sessionStorage.setItem(cacheKey, JSON.stringify(opt));
      return resApi
    }
  } catch (error) {
    return error
  }
}
