import { CButton, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react-pro'
import React from 'react'
import { isMobile, isMobileOnly } from 'react-device-detect'
import { AccionMenu } from '../../../store/types'
import { AppBar, Menu, Toolbar, MenuItem, Fade } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisV, faQuestionCircle } from '@fortawesome/pro-light-svg-icons'
import { menuService } from '../../../services/menu.service'
import ContextMenu from 'devextreme-react/context-menu'
import { ButtonTypes } from './types'
import { MenuSpinner } from './customSpinner'
import './globalMenu.scss'
interface IGlobalMenuProps extends React.PropsWithChildren {
  onClick: (accion: AccionMenu) => void
  getButtonDisabled: (accion: AccionMenu) => boolean
  acciones: AccionMenu[]
  showExportDesingOptions?: boolean
  hideInactiveButtons?: boolean
  currentWorking?: ButtonTypes
  printButtonAsDropdown?: boolean
}

export function GlobalMenu(props: IGlobalMenuProps) {
  const { hideInactiveButtons, acciones, currentWorking, printButtonAsDropdown } = props
  const [open, setOpen] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState<any>(null)

  const toogleMenu = (status) => {
    setOpen(!status)
  }

  if (isMobile) {
    let maxItems = 5
    if (window.innerWidth < 400) {
      maxItems = 5
    } else if (window.innerWidth < 500) {
      maxItems = 6
    } else if (window.innerWidth < 800) {
      maxItems = 10
    } else {
      maxItems = 14
    }
    return (
      <AppBar key="menuVentasAppBar" position="fixed" color="inherit" className="appBar">
        <Toolbar>
          {props.acciones
            .filter((x) => !props.getButtonDisabled(x))
            .map((m, index) => {
              const disabled = currentWorking !== undefined || props.getButtonDisabled(m)
              if (index < maxItems) {
                return (
                  <CButton key={m.nombre} color="primary" variant="ghost" disabled={disabled} onClick={() => props.onClick(m)}>
                    <FontAwesomeIcon
                      icon={menuService.getIcon(m.icon) ?? faQuestionCircle}
                      size={isMobile && !isMobileOnly ? '3x' : '2x'}
                    />
                  </CButton>
                )
              }
            })}
          {props.acciones.filter((x) => !props.getButtonDisabled(x)).length > maxItems && (
            <>
              <CButton
                key={'buttonPopUpMenu'}
                color="primary"
                variant="ghost"
                onClick={(event) => {
                  toogleMenu(open)
                  if (event && event.currentTarget) {
                    setAnchorEl(event.currentTarget)
                  }
                }}
              >
                <FontAwesomeIcon icon={faEllipsisV} size={isMobile && !isMobileOnly ? '3x' : '2x'} />
              </CButton>
              <Menu
                id="menuButtonsMenu"
                keepMounted
                open={open}
                anchorEl={anchorEl}
                onClose={() => {
                  toogleMenu(open)
                  setAnchorEl(null)
                }}
                TransitionComponent={Fade}
              >
                {props.acciones
                  .filter((x) => !props.getButtonDisabled(x))
                  .map((m, index) => {
                    if (index >= maxItems) {
                      return (
                        <MenuItem key={m.nombre} onClick={() => props.onClick(m)}>
                          <FontAwesomeIcon
                            icon={menuService.getIcon(m.icon) ?? faQuestionCircle}
                            size={isMobile && !isMobileOnly ? '2x' : '1x'}
                            className="dropDownMenuGlobalMenu"
                          />
                        </MenuItem>
                      )
                    }
                  })}
              </Menu>
            </>
          )}
        </Toolbar>
      </AppBar>
    )
  }

  const botones = hideInactiveButtons ? acciones.filter((x) => !props.getButtonDisabled(x)) : acciones
  return (
    <div
      key="menuVentasBarra"
      className="btn-toolbar p-2 sm"
      role="toolbar"
      aria-label="Toolbar with button groups"
    // style={{ marginBottom: '5px', paddingTop: '-2rem !important' }}
    >
      <div key="menuVentasBarraBotones" className="btn-group-lg btn-group sm btn-sm me-2" role="group" aria-label="First group">
        {botones.map((m) => {
          const disabled = currentWorking !== undefined || props.getButtonDisabled(m)

          //if (m.actionType === (currentWorking ?? '')) disabled = true;
          const iconStyle: any = {
            '--fa-secondary-opacity': disabled ? '0.10' : '0.8',
            '--fa-primary-color': '#321fdb',
            '--fa-secondary-color': '#4638c2',
          }
          const idBtn = m.nombre //'x' + uuidv4();
          return (
            <React.Fragment key={idBtn}>
              {m.nombre === 'Imprimir' && printButtonAsDropdown && (
                <CDropdown>
                  <CDropdownToggle disabled={disabled} style={{ borderRadius: '0px', height: '42px' }} className="btn btn-secondary sm">
                    {m.actionType !== (currentWorking ?? '') && (
                      <FontAwesomeIcon icon={menuService.getIcon(m.icon) ?? faQuestionCircle} size="sm" style={iconStyle} />
                    )}
                    {m.actionType === (currentWorking ?? '') && (
                      <MenuSpinner animation="border">
                        <FontAwesomeIcon icon={menuService.getIcon(m.icon) ?? faQuestionCircle} size="sm" style={iconStyle} />
                      </MenuSpinner>
                    )}
                  </CDropdownToggle>
                  <CDropdownMenu>
                    <CDropdownItem onClick={() => props.onClick({ ...m, actionType: ButtonTypes.print })} href="#">
                      Imprimir
                    </CDropdownItem>
                    <CDropdownItem
                      onClick={() =>
                        props.onClick({
                          ...m,
                          actionType: ButtonTypes.print_design,
                        })
                      }
                      href="#"
                    >
                      Diseñar
                    </CDropdownItem>
                  </CDropdownMenu>
                </CDropdown>
              )}
              {(m.nombre !== 'Imprimir' || !printButtonAsDropdown) && (
                <CButton
                  id={idBtn}
                  size="sm"
                  className="btn btn-secondary sm"
                  disabled={disabled}
                  onClick={() => props.onClick(m)}
                  title={m.nombre}
                >
                  {m.actionType !== (currentWorking ?? '') && (
                    <FontAwesomeIcon icon={menuService.getIcon(m.icon) ?? faQuestionCircle} size="sm" style={iconStyle} />
                  )}
                  {m.actionType === (currentWorking ?? '') && (
                    <MenuSpinner animation="border">
                      <FontAwesomeIcon icon={menuService.getIcon(m.icon) ?? faQuestionCircle} size="sm" style={iconStyle} />
                    </MenuSpinner>
                  )}
                </CButton>
              )}
              {props.showExportDesingOptions && m.nombre === 'Exportar' && !disabled && (
                <ContextMenu
                  dataSource={[
                    { text: 'Visualizar', icon: 'dx-icon-download' },
                    { text: 'Diseñar', icon: 'dx-icon-add' },
                  ]}
                  width={200}
                  target={'#' + idBtn}
                  onItemClick={(e) =>
                    props.onClick({
                      ...m,
                      actionType: e.itemData.text === 'Visualizar' ? ButtonTypes.export : ButtonTypes.export_design,
                    })
                  }
                />
              )}
            </React.Fragment>
          )
        })}
      </div>
    </div>
  )
}
