import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EdicionPayload } from '../../../../clientes/store/types'
import { DocumentosDatosEdicion } from '../types/types'
import { CustomDictionary } from '../../../../../store/types'

const initialState: CustomDictionary<DocumentosDatosEdicion> = {}

const datosEdicionDocumentosCuentasPorPagarSlice = createSlice({
  name: 'documentosCuentasPorPagarDatosEdicion',
  initialState: initialState,
  reducers: {
    setDatosEdicion(
      state,
      action: PayloadAction<EdicionPayload<DocumentosDatosEdicion>>,
    ) {
      state[action.payload.key] = action.payload.data
    },
    initDatosEdicion(
      state,
      action: PayloadAction<EdicionPayload<DocumentosDatosEdicion>>,
    ) {
      if (!state[action.payload.key]) {
        state[action.payload.key] = action.payload.data
      }
    },
  },
})

export const { setDatosEdicion, initDatosEdicion } =
  datosEdicionDocumentosCuentasPorPagarSlice.actions
export const datosEdicionDocumentosCuentasPorPagarReducer =
  datosEdicionDocumentosCuentasPorPagarSlice.reducer
