import React from 'react'
import RowContainer from '../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../views/componentes/colContainer'
import Labeled from '../../../../../views/componentes/labeledInput/labeledInput'
import { Button, NumberBox, RadioGroup, TextBox, ValidationGroup, ValidationSummary, Validator } from 'devextreme-react'
import EtapasProduccionLookUp from '../../../etapasProduccionLookUp'
import { TEtapaProduccionOption } from '../../../etapasProduccionLookUp/store/type'
import { AsyncRule, RequiredRule } from 'devextreme-react/validator'
import validations from '../../../../../config/validations'
import KitsPartesLookUp from '../../../kitsPartesLookUp'
import { TKitsPartesOption } from '../../../kitsPartesLookUp/store/type'
import { useDispatch } from 'react-redux'
import { addToast } from '../../../../../store/toasterReducer'
import { ToastTypes } from '../../../../../store/types'
import { MessagesKeys, lh } from '../../../../../helpers/localizationHelper'
import { kitsPartsServices } from '../../services/kitspartes.service'
import { etapasProduccionService } from '../../../etapasProduccionLookUp/service/etapaProduccion.service'
import { kitsPartesService } from '../../../kitsPartesLookUp/service/kitsPartes.service'
import { ModalKitPartes } from '../..'
import { ModalEtapasProduccion } from '../../../modalEtapasProduccion'


interface INewProps extends React.PropsWithChildren {
  currentTab: string
  currentFunction: string
  setFunction: (data: string) => void
  setShowLoader: (data: boolean) => void
  data: any
}

export const kitsTipos = [
  { value: '88', label: 'Agrupador' },
  { value: '89', label: 'Detalle' },
]

const Nuevo: React.FunctionComponent<INewProps> = (props) => {

  const { currentTab, currentFunction, setFunction, setShowLoader, data } = props

  const dispatch = useDispatch()
  const [codigo, setCodigo] = React.useState<number>(null)
  const [orden, setOrden] = React.useState<number>(1)
  const [codigoPadre, setCodigoPadre] = React.useState<TKitsPartesOption>(null)
  const [etapa, setEtapa] = React.useState<TEtapaProduccionOption>(null)
  const validationGroupRef = React.useRef<any>()
  const sms = validations['es']
  const mensajeEtapas: string = 'Etapa: Debe seleccionar una opción'
  const mensajePadreKits: string = 'Codigo Padre: Seleccione el agrupador para este detalle'

  const [descripcion, setDescripcion] = React.useState<string>(null)
  const [tipo, setTipo] = React.useState<any>(null)
  const [showEtapas, setShowEtapas] = React.useState<boolean>(false)

  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(
      addToast({
        title: 'Kits Partes',
        content: mensaje,
        type: tipo,
      }),
    )
  }, [dispatch])

  const onValueChanged = React.useCallback((data, key: string) => {
    if (data !== null && data !== undefined) {
      let value = null
      if (data?.value !== null && data?.value !== undefined) {
        value = data.value
      }
      switch (key) {
        case 'descripcion':
          setDescripcion(value)
          break;
        case 'tipo':
          setTipo(value)
          break;

        default:
          break;
      }
    }
  }, [])

  const onGetData = React.useCallback(async (id: number) => {
    const search_data = {
      agrupar: true,
      descripcion: '',
      codigo: 0,
    }
    try {

      const res = await kitsPartsServices.getAllKitsParts(search_data)
      if (res !== null && res !== undefined && res['error'] === false) {
        const data = res['auto'].find(item => item.codigo === id)
        return data
      }
      return null
    } catch (error) {
      return null
    }
  }, [])

  const onGetEtapa = React.useCallback(async (etapaCodigo: number) => {
    try {
      const res = await etapasProduccionService.getEtapas('Elija una opción')
      if (res !== null && res !== undefined && res['error'] === false) {
        const data = res['auto'].find(item => item.codigo === etapaCodigo)
        if (data !== null && data !== undefined) {
          return data
        }
      }
      return null
    } catch (error) {
      return null
    }
  }, [])

  const onGetKitPadre = React.useCallback(async (padreCodigo: number) => {
    try {
      const data = await kitsPartesService.getKitsPartes('Elija una opción')
      if (data !== null && data !== undefined) {
        const padre = data['auto'].find(item => item.codigo === padreCodigo)
        if (padre !== null && padre !== undefined) {
          return padre
        }
      }
      return null
    } catch (error) {
      return null
    }
  }, [])


  const onEdit = React.useCallback(async () => {
    if (data !== null && data !== undefined) {
      setShowLoader(true)
      const dataEdit = await onGetData(data.id)
      const kitPadre = await onGetKitPadre(dataEdit.padre)
      const etapaEdit = await onGetEtapa(dataEdit.etapaCodigo)
      setCodigo(dataEdit.codigo)
      setEtapa(etapaEdit)
      setCodigoPadre(kitPadre)
      setOrden(dataEdit.orden)
      setDescripcion(dataEdit.descripcion)
      const editTipo = dataEdit.tipo === kitsTipos[0]?.value ? kitsTipos[0] : kitsTipos[1]
      setTipo(editTipo)
      setShowLoader(false)
    }
  }, [data, onGetData, setShowLoader, onGetEtapa, onGetKitPadre])

  const onSave = React.useCallback(async () => {
    const result = validationGroupRef.current.instance.validate()
    if (result.isValid) {
      const save_data = {
        infoRegistro: {
          codigo: codigo !== null ? codigo : 0,
          descripcion: descripcion ?? '',
          orden,
          estado: 1,
          etapaCodigo: etapa !== null ? etapa.codigo : 1,
          tipo: tipo !== null ? parseInt(tipo.value) : 0,
          kitPadre: codigoPadre !== null ? codigoPadre.codigo : -1
        }
      }
      try {
        setShowLoader(true)
        const data = await kitsPartsServices.saveKitsParts(save_data)
        setShowLoader(false)
        if (data !== null && data !== undefined && data['error'] === false) {
          setCodigo(data['auto'])
          setToast(data['message'], ToastTypes.Success)
        } else {
          setToast(data['message'], ToastTypes.Danger)
        }
      } catch (error) {
        setShowLoader(false)
        setToast(error.message, ToastTypes.Danger)
      }
    } else {
      setToast(lh.getMessage(MessagesKeys.GlobalErrorSave), ToastTypes.Warning)
    }
  }, [codigo, orden, codigoPadre, etapa, descripcion, setToast, tipo, setShowLoader])

  const onEtapasValueChanged = React.useCallback((data) => {
    setEtapa(data)
  }, [])


  const validateAsyncSelect = React.useCallback((datoSeleccion: any, mensaje: string) => {
    if (tipo === null) {
      return Promise.reject('Tipo: Seleccione un tipo para armar el Kit')
    }
    if (tipo !== null && tipo.value === '89') {
      if (datoSeleccion.value.codigo > -1) {
        return Promise.resolve()
      } else {
        return Promise.reject(mensaje)
      }
    }

    if (tipo !== null && tipo.value === '88') {
      if (datoSeleccion.value.codigo > -1) {
        return Promise.resolve()
      } else {
        return Promise.reject(mensaje)
      }
    }
  }, [tipo])

  const validateAsyncSeleccionEtapaId = React.useCallback((datoSeleccion) => {
    return validateAsyncSelect(datoSeleccion, mensajeEtapas)
  }, [validateAsyncSelect])
  const validateAsyncSeleccionKitsPadreId = React.useCallback((datoSeleccion) => {
    return validateAsyncSelect(datoSeleccion, mensajePadreKits)
  }, [validateAsyncSelect])

  React.useEffect(() => {
    if (currentTab === 'Nuevo') {
      if (currentFunction === 'Guardar') {
        onSave()
      } else if (currentFunction === 'Editar') {
        onEdit()
      }
    }
    setFunction('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFunction])

  return (
    <>
      {
        showEtapas && (<ModalEtapasProduccion
          show={showEtapas}
          onCancel={() => setShowEtapas(false)}
          onChanged={(data) => {
            onEtapasValueChanged(data)
            setShowEtapas(false)
          }}
        />)
      }
      <ValidationGroup id={'formKitsPartes'} ref={validationGroupRef}>
        <RowContainer className="m-2">
          <RowContainer>
            <CustomCol xs="12" md="8">
              <Labeled label="Codigo:">
                <NumberBox
                  value={codigo}
                  readOnly
                  width="100%"
                  inputAttr={{ autocomplete: 'nope' }}
                />
              </Labeled>
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol xs="12" md="8">
              <Labeled label="Tipo:">
                <RadioGroup
                  name="grupos-kitsPartes"
                  layout="horizontal"
                  displayExpr="label"
                  items={kitsTipos}
                  value={tipo}
                  onValueChanged={(data) => onValueChanged(data, 'tipo')}
                />
              </Labeled>
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol xs="12" md="8">
              <Labeled label="Codigo Padre:">
                <KitsPartesLookUp
                  selected={codigoPadre}
                  disabled={tipo !== null && tipo?.value === '88'}
                  onChanged={(data) => setCodigoPadre(data)}
                >
                  <Validator>
                    <RequiredRule message={mensajePadreKits} />
                    <AsyncRule
                      message={mensajePadreKits}
                      validationCallback={validateAsyncSeleccionKitsPadreId}
                    />
                  </Validator>
                </KitsPartesLookUp>
              </Labeled>
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol xs="12" md="8">
              <Labeled label="Etapa:">
                <EtapasProduccionLookUp
                  selected={etapa}
                  disabled={tipo !== null && tipo?.value === '89'}
                  onChanged={(data) => setEtapa(data)}
                >
                  <Validator>
                    <RequiredRule message={mensajeEtapas} />
                    <AsyncRule
                      message={mensajeEtapas}
                      validationCallback={validateAsyncSeleccionEtapaId}
                    />
                  </Validator>
                </EtapasProduccionLookUp>
              </Labeled>
            </CustomCol>
            <CustomCol xs="3" md="3">
              <Labeled label="">
                <Button
                  id="btnAdd"
                  className="me-1"
                  icon="add"
                  stylingMode="contained"
                  type="default"
                  onClick={() => setShowEtapas(true)}
                />
              </Labeled>
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol xs="12" md="8">
              <Labeled label="Descripcion:">
                <TextBox
                  value={descripcion}
                  width="100%"
                  inputAttr={{ autocomplete: 'nope' }}
                  onValueChanged={(data) => onValueChanged(data, 'descripcion')}
                >
                  <Validator>
                    <RequiredRule message={'Descripción: ' + sms['required']} />
                  </Validator>
                </TextBox>
              </Labeled>
            </CustomCol>
          </RowContainer>
        </RowContainer>
        <RowContainer>
          <CustomCol lg="6" md="8" sm="12">
            <ValidationSummary id="summary"></ValidationSummary>
          </CustomCol>
        </RowContainer>
      </ValidationGroup>
    </>
  )
}

export default React.memo(Nuevo)