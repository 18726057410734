import { combineReducers } from '@reduxjs/toolkit'
import { tabsReducer } from './tabsReducer'
import { generalReducer } from './generalReducer'
import { ImportacionesState } from '../types/types'
import { dataEditReducer } from './editDataReducer'
import { searchReducer } from './searchReducer'
import { menuReducer } from './menuReducer'
import { configuracionesImports } from './configReducer'

export const ImportacionesReducer = combineReducers<ImportacionesState>({
  config: configuracionesImports,
  menu: menuReducer,
  general: generalReducer,
  tabs: tabsReducer,
  editData: dataEditReducer,
  search: searchReducer,
})
