import React from 'react'
import { CBadge, CCol, CRow } from '@coreui/react-pro'
import {
  AsyncRule,
  Column,
  EmailRule,
  RequiredRule,
  StringLengthRule,
} from 'devextreme-react/data-grid'
import {
  Button,
  DataGrid,
  TextBox,
  Validator,
  ValidationGroup,
  ValidationSummary,
} from 'devextreme-react'
import CustomCol from '../../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../../views/componentes/labeledInput/labeledInput'
import RowContainer from '../../../../../../../../views/componentes/rowContainer/rowContainer'
import { DatosEdicionSucursal, DatosSucursales } from '../../../../types/types'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../../../../../../store/store'
import TextArea from 'devextreme-react/text-area'
import LocalidadesLookUp from '../../../../../../../componentes/localidadesLookUp/localidadesLookUp'
import ZonasLookUp from '../../../../../../../componentes/zonasLookUp'
import { ToastTypes } from '../../../../../../../../store/types'
import { v4 as uuidv4 } from 'uuid'
import ContactosLookUp from './contactosLookUp'
import { setTabSucursales } from '../../../../store/editDataReducer'

interface ISucursalesProps {
  tabId: string
  setToast: (sms: string, tipo: ToastTypes) => void
  refForm: any
}

const Sucursales: React.FunctionComponent<ISucursalesProps> = (props) => {
  const { tabId, setToast } = props
  const dispatch = useDispatch()

  const sucursalesState = useSelector((state: RootState) => { return state.clientes.clientes.editData[props.tabId]?.tabs?.sucursales })
  const datosEditionClienteCodigoState = useSelector((state: RootState) => { return state.clientes.clientes.editData[props.tabId]?.codigo })


  const [seleccionarDato, setSeleccionarDato] = React.useState<any | null>(null)
  const validationGroupRef = React.useRef<any>()


  const onChangedDireccion = React.useCallback((data) => {
    if (data.event !== undefined) {
      dispatch(
        setTabSucursales({
          key: tabId,
          data: {
            ...sucursalesState,
            direccion: data.value
          }
        }),
      )
    }
  }, [dispatch, tabId, sucursalesState])

  const onChangedReference = React.useCallback(
    (data) => {
      if (data.event !== undefined) {
        dispatch(
          setTabSucursales({
            key: tabId,
            data: {
              ...sucursalesState,
              referencia: data.value
            }
          }),
        )
      }
    }, [dispatch, tabId, sucursalesState])

  const onChangedEmail = React.useCallback(
    (data) => {
      if (data.event !== undefined) {
        dispatch(
          setTabSucursales({
            key: tabId,
            data: {
              ...sucursalesState,
              email: data.value
            }
          }),
        )
      }
    }, [dispatch, tabId, sucursalesState])

  const onChangedCiudad = React.useCallback(
    (data) => {
      dispatch(
        setTabSucursales({
          key: tabId,
          data: {
            ...sucursalesState,
            ciudad: data
          }
        }),
      )
    }, [dispatch, tabId, sucursalesState])

  const onChangeZonas = React.useCallback(
    (data) => {
      if (data !== null) {
        dispatch(
          setTabSucursales({
            key: tabId,
            data: {
              ...sucursalesState,
              zona: data
            }
          })
        )
      }
    }, [dispatch, tabId, sucursalesState])

  const onChangedContacto = React.useCallback(
    (data) => {
      if (data !== null) {
        dispatch(
          setTabSucursales({
            key: tabId,
            data: {
              ...sucursalesState,
              telefono: data
            }
          }),
        )
      }
    },
    [dispatch, tabId, sucursalesState],
  )

  const onResetFormSucursal = React.useCallback(() => {
    // dispatch(resetFormSucursales({
    //   key: tabId
    // }))
  }, [tabId, dispatch])

  const onAddSucursal = React.useCallback(() => {
    const result = validationGroupRef.current.instance.validate()
    if (result.isValid === false) {
      setToast("No se pudo registrar la sucursal, por favor revise las advertencias.", ToastTypes.Danger);
      return false;
    } else {
      let zonaCodigo = null
      let contactoCodigo = null
      let ciudadCodigo = null
      if (sucursalesState.telefono == null || sucursalesState.telefono == undefined) {
        setToast("Es necesario tener seleccionado un contacto telefónico.", ToastTypes.Danger);
        return false;
      } else {
        zonaCodigo = sucursalesState.telefono.codigo
      }
      if (sucursalesState.zona == null || sucursalesState.zona == undefined) {
        setToast("Es necesario tener seleccionado una zona.", ToastTypes.Danger);
        return false;
      } else {
        contactoCodigo = sucursalesState.zona.codigo
      }
      if (sucursalesState.ciudad == null || sucursalesState.ciudad == undefined) {
        setToast("Es necesario tener seleccionado una ciudad.", ToastTypes.Danger);
        return false;
      } else {
        ciudadCodigo = sucursalesState.ciudad.codigo
      }
      const rows = sucursalesState.sucursales.slice()
      const newSucursal: DatosSucursales = {
        codigo: 0,
        clienteCodigo: 0,
        direccion: sucursalesState.direccion,
        referencia: sucursalesState.referencia,
        email: sucursalesState.email,
        contacto: contactoCodigo ?? null,
        codContacto: contactoCodigo ?? null,
        uuid: uuidv4(),
        ciudadCodigo: ciudadCodigo ?? null,
        ciudadDescripcion: '',
        zonaCodigo: zonaCodigo ?? null,
        zonaDescripcion: '',
      }
      if (sucursalesState.zona) {
        newSucursal.zonaCodigo = sucursalesState.zona.codigo
        newSucursal.zonaDescripcion = sucursalesState.zona.descripcion
      }
      if (sucursalesState.ciudad) {
        newSucursal.ciudadCodigo = sucursalesState.ciudad.codigo
        newSucursal.ciudadDescripcion = sucursalesState.ciudad.nombre
      }
      rows.push(newSucursal)
      // dispatch(
      //   updateSucursalDataSucursales({
      //     sucursales: rows,
      //     key: tabId,
      //   }),
      // )
      onResetFormSucursal()
      validationGroupRef.current.instance.reset();
    }
  }, [
    onResetFormSucursal,
    sucursalesState,
    setToast
  ])

  const handleClickRemove = () => {
    if (seleccionarDato === null) {
      setToast("No se encuentra seleccionado un registro para eliminarlo.", ToastTypes.Info);
      return false;
    } else {
      const filtrado = sucursalesState.sucursales.findIndex((item: any) => {
        return item.key === seleccionarDato['row']['key']
      })
      if (filtrado > -1) {
        const rowsBefore = sucursalesState.sucursales.slice()
        rowsBefore.splice(filtrado, 1)
        // dispatch(
        //   updateSucursalDataSucursales({
        //     sucursales: rowsBefore,
        //     key: tabId,
        //   }),
        // )
        setSeleccionarDato(null)
        setToast("Removido correctamente.", ToastTypes.Success);
      }
    }
  }

  const onSelectionChanged = React.useCallback((evt) => {
    if (evt['selectedRowsData'].length > 0) {
      const data = evt['selectedRowsData'][0]
      setSeleccionarDato({
        index: 1,
        row: data,
      })
    }
  }, [])

  const validateAsyncSelect = React.useCallback(
    (datoSeleccion: any, message: string) => {
      if (datoSeleccion.value.codigo > -1) {
        return Promise.resolve()
      } else {
        return Promise.reject(message)
      }
    }, [])

  const validateAsyncSeleccionCiudad = React.useCallback(
    (datoSeleccion) => {
      return validateAsyncSelect(
        datoSeleccion,
        `Ciudad: Debe seleccionar una opción.`,
      )
    }, [validateAsyncSelect])

  const validateAsyncSeleccionContacto = React.useCallback(
    (datoSeleccion) => {
      return validateAsyncSelect(
        datoSeleccion,
        `Contacto: Debe seleccionar una opción.`,
      )
    }, [validateAsyncSelect])

  const validateAsyncSeleccionZona = React.useCallback(
    (datoSeleccion) => {
      return validateAsyncSelect(
        datoSeleccion,
        `Zona: Debe seleccionar una opción.`,
      )
    }, [validateAsyncSelect])

  return (
    <>
      <ValidationGroup
        key={'valitadionCliSucursales'}
        id={'valitadionCliSucursales'}
        ref={validationGroupRef}
      >
        <CRow>
          <CCol xs="12" md="12" className="mt-2 mb-2">
            <ValidationSummary id="summary" />
          </CCol>
        </CRow>
        <RowContainer>
          <CustomCol xs="12" md="3">
            <Labeled label="Dirección:">
              <TextBox
                value={sucursalesState?.direccion ?? ""}
                onValueChanged={(data) => {
                  if (data?.event !== undefined) {
                    onChangedDireccion(data)
                  }
                }}
              >
                <Validator>
                  <RequiredRule
                    message={'Dirección: Debe tener al menos 5 caracteres.'}
                  />
                  <StringLengthRule min={5} />
                </Validator>
              </TextBox>
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="4">
            <Labeled label="Referencia:">
              <TextArea
                value={sucursalesState?.referencia ?? ""}
                onValueChanged={(data) => {
                  if (data?.event !== undefined) {
                    onChangedReference(data)
                  }
                }}
              >
                <Validator>
                  <RequiredRule
                    message={'Referencia: Debe tener al menos 5 caracteres.'}
                  />
                  <StringLengthRule
                    min={5}
                    message={'Referencia: Debe tener al menos 5 caracteres.'}
                  />
                </Validator>
              </TextArea>
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="3">
            <Labeled label="Email:">
              <TextBox
                value={sucursalesState?.email ?? ""}
                onValueChanged={(data) => {
                  if (data?.event !== undefined) {
                    onChangedEmail(data)
                  }
                }}
              >
                <Validator>
                  <RequiredRule
                    message={'Email: Formato de email incorrecto.'}
                  />
                  <EmailRule
                    message={'Email: Formato de email incorrecto.'}
                  />
                </Validator>
              </TextBox>
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="2">
            <Labeled label="Teléfono:">
              <ContactosLookUp
                onChanged={(data) => {
                  if (data !== null || data !== undefined) {
                    onChangedContacto(data)
                  }
                }}
                disabled={false}
                selected={sucursalesState?.telefono ?? null}
                provider={sucursalesState?.telefonos ?? []}
                codigoEntidad={datosEditionClienteCodigoState ?? 0}
              >
                <Validator>
                  <RequiredRule message={'Teléfono: Debe seleccionar una opción'} />
                  <AsyncRule
                    message={'Teléfono: Debe seleccionar una opción'}
                    validationCallback={(e) => validateAsyncSeleccionContacto(e)}
                  />
                </Validator>
              </ContactosLookUp>
            </Labeled>
          </CustomCol>
        </RowContainer>
        <RowContainer>
          <CustomCol xs="12" md="3">
            <Labeled label="Ciudad:">
              <LocalidadesLookUp
                onChanged={(data) => {
                  if (data !== null || data !== undefined) {
                    onChangedCiudad(data)
                  }
                }}
                onChangedOptions={() => { }}
                selected={sucursalesState?.ciudad ?? null}
                allowEdit
                allowClear
              >
                <Validator>
                  <RequiredRule message={'Ciudad: Debe seleccionar una opción'} />
                  <AsyncRule message={'Ciudad: Debe seleccionar una opción'} validationCallback={(e) => validateAsyncSeleccionCiudad(e)} />
                </Validator>
              </LocalidadesLookUp>
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="9">
            <Labeled label="Ruta:">
              <CRow>
                <CCol md="6" xs="8">
                  <ZonasLookUp
                    onChanged={(data) => {
                      if (data !== null || data !== undefined) {
                        onChangeZonas(data)
                      }
                    }}
                    selected={sucursalesState?.zona ?? null}
                    provider={sucursalesState?.zonas ?? []}
                    id={'lookUpZonasS'}
                  >
                    <Validator>
                      <RequiredRule message={'Zona: Debe seleccionar una opción'} />
                      <AsyncRule message={'Zona: Debe seleccionar una opción'} validationCallback={(e) => validateAsyncSeleccionZona(e)} />
                    </Validator>
                  </ZonasLookUp>
                </CCol>
                <CCol md="4" xs="6" className="me-3">
                  <Button
                    id="btnAdd"
                    className="me-1"
                    icon="add"
                    stylingMode="contained"
                    type="default"
                    onClick={() => onAddSucursal()}
                  />
                  <Button
                    id="btnRemove"
                    icon="trash"
                    stylingMode="contained"
                    type="danger"
                    onClick={() => handleClickRemove()}
                  />
                </CCol>
              </CRow>
            </Labeled>
          </CustomCol>
        </RowContainer>
      </ValidationGroup >

      <RowContainer>
        <CustomCol xs="12" className="mt-2 mb-2">
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
            }}
          >
            <div>
              <CBadge color="info">{`${sucursalesState?.sucursales && sucursalesState?.sucursales.length} REGISTROS`}</CBadge>
            </div>
          </div>
        </CustomCol>
      </RowContainer>
      <RowContainer className="mt-2">
        <CustomCol xs="12" md="12">
          <DataGrid
            keyExpr="uuid"
            repaintChangesOnly
            selection={{ mode: 'single' }}
            hoverStateEnabled={true}
            dataSource={sucursalesState?.sucursales}
            showBorders={true}
            showRowLines={true}
            onSelectionChanged={(e) => onSelectionChanged(e)}
          >
            <Column
              dataType="string"
              dataField="ciudadDescripcion"
              caption="Ciudad"
              width="30%"
            />
            <Column
              dataType="string"
              dataField="direccion"
              caption="Dirección"
              width="60%"
            />
            <Column
              dataType="string"
              dataField="email"
              caption="Email"
              width="35%"
            />
            <Column dataField="zonaDescripcion" caption="Ruta" width="60%" />
            <Column dataField="contacto" caption="Telefono" width="30%" />
          </DataGrid>
        </CustomCol>
      </RowContainer>
    </>
  )
}

export default React.memo(Sucursales)

export const defaultDatosEdicionSucursalCliente: DatosEdicionSucursal = {
  direccion: "",
  referencia: "",
  telefono: null,
  telefonos: [],
  email: "",
  ciudad: null,
  zonas: [],
  zona: null,
  sucursales: [],
}
