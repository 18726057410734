import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  FiltroBusquedaState,
  RetencionInfo,
  SearchState,
} from '../../../../types/types'
import { DateUtils } from '../../../../../../helpers/dateUtils'
import { FETCH_STATUS } from '../../../../../../store/types'
import { RetencionCompraService } from '../../../../services/retencionCompra.service'
import { RootState } from '../../../../../../store/store'
import { utilidades } from '../../../../../../helpers/utilidades'

const initialState: SearchState<RetencionInfo> = {
  filter: {
    fechaInicio: DateUtils.getCurrentDateAsString(),
    fechaFin: DateUtils.getCurrentDateAsString(),
    puntoVenta: '',
    activas: false,
    documento: '',
    pendientesAbutorizar: false,
    proveedor: null,
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  resultados: [],
  verJson: false,
}

export const fetchRetenciones = createAsyncThunk<
  Array<RetencionInfo>,
  FiltroBusquedaState
>('retenciones/searchRetencionesSV', async (filtro) => {
  try {
    const retenciones = await RetencionCompraService.buscarRetenciones(
      filtro.fechaInicio,
      filtro.fechaFin,
      filtro,
    )

    let data = retenciones
    if (retenciones.length > 0) {
      data = utilidades.sortJSON(retenciones, 'codigo', 'desc')
    }
    return data
  } catch (error) {
    return Promise.reject(error)
  }
})

const searchRetencionesSlice = createSlice({
  name: 'searchRetenciones',
  initialState: initialState,
  reducers: {
    resetState(state) {
      state = {
        ...initialState,
        filter: {
          ...initialState.filter,
          fechaInicio: DateUtils.getCurrentDateAsString(),
          fechaFin: DateUtils.getCurrentDateAsString(),
        },
      }
    },
    setResults(state, action: PayloadAction<Array<RetencionInfo>>) {
      state.resultados = action.payload
    },
    changeFilter(state, action: PayloadAction<FiltroBusquedaState>) {
      state.filter = action.payload
    },
    setCleanResult(state) {
      state.resultados = []
    },
    toggleVerJsonComprobanteRetencion(state, action: PayloadAction<boolean>) {
      state.verJson = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRetenciones.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchRetenciones.fulfilled, (state, { payload }) => {
      state.resultados = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchRetenciones.rejected, (state, { payload }) => {
      state.resultados = []
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
  },
})

export const selectFiltroBusquedaRetenciones = (state: RootState) => {
  return state.compras.comprasSV.retenciones.search.filter
}
export const selectResultadosBusquedaRetenciones = (state: RootState) => {
  return state.compras.comprasSV.retenciones.search.resultados
}
export const selectBusquedaRetencionesEstado = (state: RootState) => {
  return state.compras.comprasSV.retenciones.search.status
}
export const selectBusquedaVerJsonComprobanteRetencion = (state: RootState) => {
  return state.compras.comprasSV.retenciones.search.verJson
}
export const { changeFilter, resetState, setResults, setCleanResult, toggleVerJsonComprobanteRetencion } =
  searchRetencionesSlice.actions
export const searchRetencionesReducer = searchRetencionesSlice.reducer
