import { CCol } from '@coreui/react-pro'
import { Switch } from 'devextreme-react'
import React from 'react'
import CustomCol from '../../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../../views/componentes/labeledInput/labeledInput'
import RowContainer from '../../../../../../../../views/componentes/rowContainer/rowContainer'
import ZonasGeograficasLookup from '../../../../../../../componentes/lookupZonasGeograficas'
import ActividadEcoLookup from '../../../../../../../componentes/lookUpActividadEconomica'
import PersonasPoliticaExpLookup from '../../../../../../../componentes/lookUpPersonasPoliticamenteExpuestas'
import ProfesionesLookup from '../../../../../../../componentes/lookUpProfesiones'
import TiposClientesLookup from '../../../../../../../componentes/lookUpTipoCliente'
import ConcentracionAccionariaLookup from '../../../../../../../componentes/lookUpConcentracionAccionaria'
import { DatosEdicionUafe } from '../../../../types/types'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../../store/store'
import { setTabUaf } from '../../../../store/editDataReducer'

interface IUafeProps {
  tabId: string
  refForm: any
}

const Uafe: React.FunctionComponent<IUafeProps> = (props) => {
  const { tabId } = props
  const dispatch = useDispatch()

  const uafState = useSelector((state: RootState) => {
    return state.clientes.clientes.editData[tabId]?.tabs?.uafe
  })

  const onChangeZonaGeografica = React.useCallback(
    (data) => {
      dispatch(setTabUaf({
        key: tabId,
        data: {
          ...uafState,
          zonaGeografica: data
        }
      }))
    }, [dispatch, tabId, uafState])

  const onChangeActividadEconomica = React.useCallback(
    (data) => {
      dispatch(setTabUaf({
        key: tabId,
        data: {
          ...uafState,
          actividadEconomica: data
        }
      }))
    }, [dispatch, tabId, uafState])

  const onChangePersonaPolitica = React.useCallback(
    (data) => {
      dispatch(setTabUaf({
        key: tabId,
        data: {
          ...uafState,
          personaPolitica: data
        }
      }))
    }, [dispatch, tabId, uafState])

  const onChangeProfesiones = React.useCallback(
    (data) => {
      dispatch(setTabUaf({
        key: tabId,
        data: {
          ...uafState,
          profesiones: data
        }
      }))
    }, [dispatch, tabId, uafState])

  const onChangeTipoCliente = React.useCallback(
    (data) => {
      dispatch(setTabUaf({
        key: tabId,
        data: {
          ...uafState,
          tipoCliente: data
        }
      }))
    }, [dispatch, tabId, uafState])

  const onChangeConAccionaria = React.useCallback(
    (data) => {
      dispatch(setTabUaf({
        key: tabId,
        data: {
          ...uafState,
          concentracionAccionaria: data
        }
      }))
    }, [dispatch, tabId, uafState])

  const onChangeExtranjero = React.useCallback(
    (data) => {
      dispatch(setTabUaf({
        key: tabId,
        data: {
          ...uafState,
          extranjero: data.value
        }
      }))
    }, [dispatch, tabId, uafState])

  return (
    <>
      <RowContainer>
        <CustomCol xs="12" md="4">
          <Labeled label="Zona Geográfica (40pts):">
            <ZonasGeograficasLookup
              onChanged={(data) => {
                if (data !== null || data !== undefined) {
                  onChangeZonaGeografica(data)
                }
              }}
              selected={uafState?.zonaGeografica ?? null}
              provider={uafState?.zonaGeograficaDp ?? []}
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="4">
          <Labeled label="Actividad Económica (N 25pts J 30pts):">
            <ActividadEcoLookup
              onChanged={(data) => {
                if (data !== null || data !== undefined) {
                  onChangeActividadEconomica(data)
                }
              }}
              selected={uafState?.actividadEconomica ?? null}
              provider={uafState?.actividadEconomicaDp ?? []}
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="4">
          <Labeled label="Persona Políticamente Expuestos (25 pts)">
            <PersonasPoliticaExpLookup
              onChanged={(data) => {
                if (data !== null || data !== undefined) {
                  onChangePersonaPolitica(data)
                }
              }}
              selected={uafState?.personaPolitica ?? null}
              provider={uafState?.personaPoliticaDp ?? []}
            />
          </Labeled>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="4">
          <Labeled label="Profesiones (10 pts aplica solo al punto anterior):">
            <ProfesionesLookup
              onChanged={(data) => {
                if (data !== null || data !== undefined) {
                  onChangeProfesiones(data)
                }
              }}
              selected={uafState?.profesiones ?? null}
              provider={uafState?.profesionesDp ?? []}
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="4">
          <Labeled label="Tipo de Cliente (10pts 50% N 100% J):">
            <TiposClientesLookup
              onChanged={(data) => {
                if (data !== null || data !== undefined) {
                  onChangeTipoCliente(data)
                }
              }}
              selected={uafState?.tipoCliente ?? null}
              provider={uafState?.tipoClienteDp ?? []}
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="4">
          <Labeled label="Concentracion Accionaria (15 pts solo J):">
            <ConcentracionAccionariaLookup
              onChanged={(data) => {
                if (data !== null || data !== undefined) {
                  onChangeConAccionaria(data)
                }
              }}
              selected={uafState?.concentracionAccionaria ?? null}
              provider={uafState?.concentracionAccionariaDp ?? []}
            />
          </Labeled>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="4">
          <Labeled label="Estructura de Propirdad Extranjera (5 pts solo J):">
            <Switch
              value={uafState?.extranjero ?? false}
              onValueChanged={(e) => {
                if (e.event !== null || e.event !== undefined) {
                  onChangeExtranjero(e)
                }
              }}
            />
          </Labeled>
        </CustomCol>
        <CCol
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '38px',
          }}
        >
          <strong>{'N = Persona Natural  J = Persona Jurídica'}</strong>
        </CCol>
      </RowContainer>
    </>
  )
}

export default React.memo(Uafe)

export const defaultDatosEdicionUafe: DatosEdicionUafe = {
  zonaGeografica: null,
  zonaGeograficaDp: [],
  actividadEconomica: null,
  actividadEconomicaDp: [],
  personaPolitica: null,
  personaPoliticaDp: [],
  profesiones: null,
  profesionesDp: [],
  tipoCliente: null,
  tipoClienteDp: [],
  concentracionAccionaria: null,
  concentracionAccionariaDp: [],
  extranjero: false,
}
