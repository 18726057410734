import {
  Column,
  DataGrid,
  FilterRow,
  HeaderFilter,
  Paging,
  Pager, Button as DatagridButton
} from 'devextreme-react/data-grid';
import React from 'react';
import CustomCol from '../../../../../views/componentes/colContainer';
import RowContainer from '../../../../../views/componentes/rowContainer/rowContainer';
import { ToastTypes } from '../../../../../store/types';
import { TListadoAnticipos } from '../../store/types';


interface IResultadoBusquedaProps extends React.PropsWithChildren {
  funcion: string,
  data: any,
  setToast: (sms: string, type: ToastTypes) => void,
  capturaSeleccion: (data: TListadoAnticipos) => void,
  onChanged: (data: TListadoAnticipos) => void;
}

const ResultadoBusqueda: React.FunctionComponent<IResultadoBusquedaProps> = (props) => {

  const { capturaSeleccion, data, onChanged } = props

  const tablaRef = React.useRef<any>();
  const tablaRefPager = React.useRef<any>();

  const [items, setItems] = React.useState<Array<any> | []>(data)

  const selectionChanged = React.useCallback((selectionChanged) => {
    if (items.length > 0) {
      if (selectionChanged['selectedRowsData'].length > 0) {
        capturaSeleccion(selectionChanged['selectedRowsData'][0])
      }
    }
  }, [items, capturaSeleccion]);

  const onData = React.useCallback(
    (datos) => {
      const f: Array<TListadoAnticipos> = []
      for (const item of datos) {
        if (item.codigo !== -1) {
          const i: any = {
            ...item
          }
          f.push(i)
        }
      }
      console.log('retenciones lis', f)

      setItems(f ?? [])
    }, []);

  const onRowDblClick = React.useCallback((rowDblClick) => {
    console.log("onRowDblClick", rowDblClick)
    onChanged(rowDblClick['data'])
  }, [onChanged]);

  React.useEffect(() => {
    onData(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <RowContainer className='mt-2'>
      <CustomCol xs='12' md='14' >
        <DataGrid
          ref={tablaRef}
          selection={{ mode: 'single' }}
          className='border'
          dataSource={items}
          onSelectionChanged={selectionChanged}
          onRowDblClick={onRowDblClick}
          width={'100%'}
          keyExpr="antCodigo"
        >
          <FilterRow visible={true} />
          <HeaderFilter visible={true} />
          <Paging
            enabled={true}
            defaultPageSize={10}
          />
          <Pager
            ref={tablaRefPager}
            showPageSizeSelector={true}
            allowedPageSizes={[8, 12, 20]}
            showNavigationButtons={true}
            showInfo={true}
            infoText="Page #{0}. Total: {1} ({2} items)"
          />
          <Column type='buttons' width='7%' >
            <DatagridButton icon='arrowleft' visible={true} hint='Seleccionar'
              onClick={(e) => {
                onChanged(e.row.data)
              }} />
          </Column>
          <Column dataField='tipo' caption='Tipo' width='60%' allowEditing={false} />
          <Column dataField='documento' caption='Comprobante' width='60%' allowEditing={false} />
          <Column dataField='origen' caption='fecha' width='60%' allowEditing={false} />
          <Column dataField='acvValor' caption='Valor' width='20%' allowEditing={false} />

        </DataGrid>
      </CustomCol>
    </RowContainer>

  );
}
export default ResultadoBusqueda;

