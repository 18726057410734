import React from 'react';
import SelectBox from 'devextreme-react/select-box';
import { tiposPersonasServices } from './service/tiposPersonas.service';
import { OptionTiposPersonas } from './types/types';

interface TiposPersonasLookUpProps extends React.PropsWithChildren {
  provider?: Array<OptionTiposPersonas> | []
  selected: OptionTiposPersonas | null,
  onChanged: (newValue: OptionTiposPersonas | null) => void,
  onChangedOptions: (newValue: Array<OptionTiposPersonas> | []) => void,
  disabled?: boolean,
}

const TiposPersonasLookUp: React.FC<TiposPersonasLookUpProps> = (props) => {
  const { provider, selected, onChanged, disabled } = props;

  const selectRef = React.useRef<any>();
  const [options, setOptions] = React.useState<Array<OptionTiposPersonas> | []>([]);

  const loadDatos = React.useCallback(async () => {
    if (provider !== undefined && provider.length > 0) {
      setOptions(provider)
      const selectedIndex = provider.findIndex((select: OptionTiposPersonas) => {
        return select.codigo === selected?.codigo
      })
      onChanged(provider[selectedIndex] ?? provider[0])
      if (selectedIndex > -1) {
        onChanged(provider[selectedIndex] ?? provider[0])
      }
    } else {
      const data = await tiposPersonasServices.getTipos(179, 'Elija tipo')
      if (data.error === false) {
        let opt = []
        if (data.auto.length > 0) {
          opt = data.auto.map((x, i) => {
            const item: OptionTiposPersonas = {
              codigo: i,
              descripcion: x?.descripcion ?? '',
            }
            return item
          })
        }
        setOptions(opt)
        const selectedIndex = opt.findIndex((option) => {
          return option.codigo === selected?.codigo
        })
        onChanged(opt[selectedIndex] ?? opt[0])
      }
    }
  }, [onChanged, selected, provider])

  const getItemDisplayExpr = React.useCallback((item: OptionTiposPersonas) => {
    return item ? `${item.descripcion}` : ''
  }, [])

  const onLookUpValueChanged = React.useCallback((evt: any) => {
    onChanged(evt)
  }, [onChanged])

  const onSelectBoxFocusIn = React.useCallback((e) => {
    e.element.querySelector('input.dx-texteditor-input').select()
  }, [])

  const refreshSelected = React.useCallback(() => {
    if (options && options.length > 0) {
      const selectedIndex = options.findIndex((option: OptionTiposPersonas) => {
        return option.codigo === selected?.codigo
      })
      onChanged(options[selectedIndex] ?? options[0])
    }
  }, [onChanged, options, selected])

  React.useEffect(() => {
    loadDatos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    refreshSelected()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  return (
    <SelectBox
      id='tiposPersonasSelect'
      ref={selectRef}
      items={options ?? []}
      searchEnabled
      displayExpr={getItemDisplayExpr}
      searchExpr={['descripcion']}
      searchTimeout={500}
      value={selected}
      onValueChange={onLookUpValueChanged}
      disabled={disabled}
      onFocusIn={onSelectBoxFocusIn}
    >
      {props.children}
    </SelectBox>
  )

}
export default React.memo(TiposPersonasLookUp);
