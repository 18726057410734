import { combineReducers } from '@reduxjs/toolkit'
import { tabsReducer } from './tabsReducer'
import { generalReducer } from './generalReducer'
import { UnidadesState } from '../types/types'
import { dataEditReducer } from './editDataReducer'
import { searchReducer } from './serachReducer'
import { configuracioneUnidades } from './configReducer'
import { menuReducer } from './menuReducer'

export const UnidadesReducer = combineReducers<UnidadesState>({
  general: generalReducer,
  tabs: tabsReducer,
  editData: dataEditReducer,
  search: searchReducer,
  config: configuracioneUnidades,
  menu: menuReducer
})
