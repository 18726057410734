import React from 'react'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import { DataGrid } from 'devextreme-react'
import { Column, ColumnChooser, Export, Pager, Paging, Button as DatagridButton, MasterDetail } from 'devextreme-react/data-grid'
import { getAllowedPageSizes } from '../../../../../../../helpers/Helper'
import ItemsCountIndicator from '../../../../../../componentes/indicadorNroRegistros'
import { Workbook } from 'exceljs'
import { exportDataGrid } from 'devextreme/excel_exporter';
import { saveAs } from 'file-saver';
import { consoleService } from '../../../../../../../services/console.service'
import { setSeleccionarDato, setVerRegistro } from '../../store/searchReducer'
import Dialog from '../../../../../../../views/componentes/librerias/bootstrap-dialog'
import { setCurrentFunction } from '../../store/generalReducer'
import { ButtonTypes } from '../../../../../../../views/componentes/globalMenu/types'
import { ActionSheetCommandsMobile } from './ActionSheetCommandsMobile'
import { isMobile } from 'react-device-detect'
import DetalleImportaciones from './detalleImportaciones'

const Cabecera = () => {

  const dispatch = useDispatch()
  const [confirmEdit, setConfirmEdit] = React.useState<boolean>(false)
  const [confirmDelete, setConfirmDelete] = React.useState<boolean>(false)


  const resultsSerach = useSelector((state: RootState) => {
    return state.inventarios.movimientos.importaciones.search.resultados
  })
  const selected = useSelector((state: RootState) => {
    return state.inventarios.movimientos.importaciones.search.seleccionado
  })
  const dialogRef = React.useRef<any>(null)
  const dataGrid = React.useRef<any>()


  const onConfirmEdit = React.useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea Editar el registro: ${selected?.documento ?? ''}?`,
      actions: [
        Dialog.Action(
          <span>
            <u>A</u>ceptar
          </span>,
          (dialog) => {
            dialog.hide()
            dispatch(setVerRegistro(false))
            dispatch(setCurrentFunction(ButtonTypes.edit))
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setConfirmEdit(false)
    return
  }, [dispatch, selected])

  const onConfirmDelete = React.useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea Eliminar el Registro: ${selected?.documento ?? ''}?`,
      actions: [
        Dialog.Action(
          <span>
            <u>A</u>ceptar
          </span>,
          (dialog) => {
            dialog.hide()
            dispatch(setCurrentFunction(ButtonTypes.disable))
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setConfirmDelete(false)
    return
  }, [dispatch, selected])

  const onSelectedIngEgChanged = React.useCallback(({ selectedRowsData }) => {
    consoleService.log('selected ', selectedRowsData[0])
    dispatch(setSeleccionarDato({ ...selectedRowsData[0] }))
  }, [dispatch])


  const onDBClickEdit = React.useCallback(() => {
    setConfirmEdit(true)
  }, [])

  React.useEffect(() => {
    if (confirmEdit) {
      onConfirmEdit()
    }
  }, [confirmEdit, onConfirmEdit])

  React.useEffect(() => {
    if (confirmDelete) {
      onConfirmDelete()
    }
  }, [confirmDelete, onConfirmDelete])

  const onExportExcell = React.useCallback((e) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('Main sheet')
    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'IngreososEgresos_Inventario.xlsx')
      });
    });
  }, [])


  const actionSheetCommandsMobile = React.useCallback(
    (cellData) => {
      const data = cellData.data as any
      return (
        <ActionSheetCommandsMobile
          importacion={data}
          key={data.codigo}
          onDelete={(data) => {
            dispatch(setSeleccionarDato({ ...data }))
            setConfirmDelete(true)
          }}
          onVer={(data) => {
            dispatch(setSeleccionarDato({ ...data }))
            dispatch(setVerRegistro(true))
            dispatch(setCurrentFunction(ButtonTypes.edit))
          }}
          onEdit={(data) => {
            dispatch(setSeleccionarDato({ ...data }))
            setConfirmEdit(true)
          }}
        />
      )
    },
    [dispatch],
  )

  return (
    <>
      <Dialog ref={dialogRef} />
      <RowContainer className="mt-2">
        <ItemsCountIndicator items={resultsSerach['auto'] ?? []} />
        <DataGrid
          ref={dataGrid}
          keyExpr="codigo"
          focusedRowKey="codigo"
          id="tabCertificados"
          selection={{ mode: 'single' }}
          dataSource={resultsSerach['auto'] ?? []}
          showColumnLines={true}
          showRowLines={true}
          showBorders={true}
          columnResizingMode="widget"
          focusedRowEnabled={true}
          onSelectionChanged={onSelectedIngEgChanged}
          onRowDblClick={onDBClickEdit}
          onExporting={onExportExcell}
          loadPanel={{
            enabled: true,
            height: 90,
            indicatorSrc: '',
            shading: false,
            shadingColor: '',
            showIndicator: true,
            showPane: true,
            text: 'Cargando...',
            width: 200,
          }}
        >
          <Paging defaultPageSize={20} />
          <Pager
            visible={(resultsSerach['auto'] && resultsSerach['auto'].length > 0) ? true : false}
            allowedPageSizes={getAllowedPageSizes(resultsSerach['auto'] ?? [])}
            displayMode={'full'}
            showPageSizeSelector={true}
            showInfo={true}
            showNavigationButtons={true}
          />
          <ColumnChooser enabled={true} mode="select" />
          <Export enabled={true} allowExportSelectedData={true} />
          {
            !isMobile && (
              <Column type="buttons" width="100px">
                <DatagridButton
                  icon="trash"
                  hint="Anular"
                  onClick={async (e) => {
                    consoleService.log('e', e)
                    const data: any = e.row.data
                    dispatch(setSeleccionarDato({ ...data }))
                    setConfirmDelete(true)
                  }}
                />
                <DatagridButton
                  // icon="arrowLeft"
                  icon="file"
                  hint="Ver"
                  onClick={async (e) => {
                    consoleService.log('e', e)
                    const data: any = e.row.data
                    dispatch(setSeleccionarDato({ ...data }))
                    dispatch(setVerRegistro(true))
                    dispatch(setCurrentFunction(ButtonTypes.edit))
                  }}
                />
                <DatagridButton
                  icon="edit"
                  hint="Editar"
                  onClick={async (e) => {
                    consoleService.log('e', e)
                    const data: any = e.row.data
                    dispatch(setSeleccionarDato({ ...data }))
                    setConfirmEdit(true)
                  }}
                />
              </Column>
            )
          }
          {!isMobile && (
            <MasterDetail
              enabled={true}
              render={(params) => (
                <>
                  <DetalleImportaciones {...params} />{' '}
                </>
              )}
            />
          )}
          {isMobile && (
            <Column
              caption="Tareas"
              dataField="codigo"
              cellRender={actionSheetCommandsMobile}
              width="50px"
              allowFiltering={false}
            ></Column>
          )}
          <Column dataField="codigo" caption="Cod. Movimiento" width="100px" />
          <Column dataField="documento" caption="Documento" width="150px" />
          <Column dataField="movimientoDescripcion" caption="Movimiento" width="150px" />
          <Column dataField="productoClienteDescripcion" caption="Nombre" width="200px" />
          <Column dataField="localCodigoDescripcion" caption="Alamcén" width="150px" />
          <Column dataField="fecha" caption="Fecha" width="150px" />
        </DataGrid>
      </RowContainer>
    </>
  )
}

export default React.memo(Cabecera)
