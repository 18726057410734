import { combineReducers } from '@reduxjs/toolkit'
import { tabsReducer } from './tabsReducer'
import { TConLocalState } from './types'
import { menuReducer } from './menuReducer'
import { dataEditionReducer } from './editDataReducer'
import { searchReducer } from './searchReducer'

export const ConfigLocalReducer = combineReducers<TConLocalState>({
  menu: menuReducer,
  tabs: tabsReducer,
  editData: dataEditionReducer,
  search: searchReducer,
})
