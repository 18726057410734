import { DateUtils, formatoFechasApi } from '../../../helpers/dateUtils'
import { RequestHelper } from '../../../helpers/requestHelper'
import { SesionService } from '../../../services/sesion.service'
import { TipoComprobante } from '../../../store/types'

export const TiposComprobantesService = {
  getAll,
}

async function getAll(): Promise<Array<TipoComprobante>> {
  const sesion = SesionService.getCurrentSesion()
  let punto_venta: string = 'vacio'

  punto_venta =
    localStorage.getItem(
      sesion.empresa?.codigo +
        '_' +
        sesion.usuario?.codigo +
        '_' +
        'punto_venta',
    ) ?? 'vacio'

  const data: any = {
    host: punto_venta,
  }
  data.dateKey = DateUtils.getCurrentDateAsString(formatoFechasApi)
  const cacheKey = 'tiposComprobantes' + JSON.stringify(data)
  const cached = sessionStorage.getItem(cacheKey)
  if (cached) {
    return JSON.parse(cached)
  } else {
    const apiData = await RequestHelper.get<Array<TipoComprobante>>(
      'generales',
      'voucherType/getAll',
      '',
      data,
    )
    sessionStorage.setItem(cacheKey, JSON.stringify(apiData))

    return apiData
  }
}
