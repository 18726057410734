import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ConfiguracionesNotaCredito } from '../types/types'
import { AutorizacionDocumentoVenta } from '../../../../types/types'

const initialState: ConfiguracionesNotaCredito = {
  autorizacionDocumento: null,
}

const configuracionesNotaCreditoSlice = createSlice({
  name: 'configuracionesNCreditoSV',
  initialState: initialState,
  reducers: {
    initialize(state, acion: PayloadAction<AutorizacionDocumentoVenta>) {
      state.autorizacionDocumento = acion.payload
    },
    setAutorizacionModuloNCredito(
      state,
      acion: PayloadAction<AutorizacionDocumentoVenta>,
    ) {
      state.autorizacionDocumento = acion.payload
    },
  },
})

export const configuracionesReducer = configuracionesNotaCreditoSlice.reducer
export const { setAutorizacionModuloNCredito } =
  configuracionesNotaCreditoSlice.actions
