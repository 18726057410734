import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DateUtils } from "../../../../../helpers/dateUtils";
import { FETCH_STATUS } from "../../../../../store/types";
import { BalancesState, FilterBalancesState } from "../types/types";
import { Seleccionado } from "../../../../bancos/store/types";
import { BalancesServicesPagar } from "../components/saldos/services/saldos.service";
import { RetencionCompraService } from "../../../../compras/services/retencionCompra.service";


const initialState: BalancesState<any> = {
  filter: {
    fecha: DateUtils.dateToString(new Date(new Date().getFullYear(), 0, 1), 'dd/MM/yyyy'),
    proveedor: null,
    local: null,
    numero: null,
    esteblecimientoNum: '001',
    ptoEmision: '001',
    saldoAnterior: null,
    observaciones: null,
    compraCodigo: 0,
  },
  seleccionado: {
    index: null,
    row: null,
    selectedRow: null,
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  resultados: [],
  currentExecute: null,
  currentUltimoNum: null,
  currentActiontate: ""
}

export const fetchBalances = createAsyncThunk<
  Array<any>,
  { codigo: number, fecha: string, numero: string }
>('proveedores/documentosPorPagar/loadInitialBalance', async (object) => {
  try {
    const saldosApi = await BalancesServicesPagar.loadOpeningBalances(object)
    return saldosApi
  } catch (error) {
    return Promise.reject(error)
  }
})

export const fetchBalancesNum = createAsyncThunk<
  Array<any>,
  { codigo: number, numero: string }
>(
  'proveedores/documentosPorPagar/loadNumLastBalances',
  async (object) => {
    try {
      const saldosApi = await BalancesServicesPagar.loadNumLastBalances(object)
      return saldosApi
    } catch (error) {
      return Promise.reject(error)
    }
  },
)

export const getToatalWithoutRetentionBuy = createAsyncThunk<
  Array<any>,
  { codigo: number, tipoPago: number }
>(
  'compras/retenciones/getTotalWithoutRetention',
  async (object) => {
    try {
      const data = await RetencionCompraService.obtieneTotalSinRetencion(object)
      return data
    } catch (error) {
      return Promise.reject(error)
    }
  },
)

const balancesSlice = createSlice({
  name: 'saldosPagar',
  initialState: initialState,
  reducers: {
    setCleanResult(state) {
      state.resultados = []
    },
    setCleanCurrentExecute(state) {
      state.currentExecute = null
    },
    setCurrentAction(state, action: PayloadAction<string>) {
      state.currentActiontate = action.payload
    },
    setCleanFields(state) {
      state.currentExecute = null
      state.filter.fecha = DateUtils.dateToString(new Date(new Date().getFullYear(), 0, 1), 'dd/MM/yyyy')
      state.filter.proveedor = null
      state.filter.numero = null
      state.filter.esteblecimientoNum = '001'
      state.filter.ptoEmision = '001'
      state.filter.observaciones = null
      state.filter.saldoAnterior = null
      state.filter.local = null
      state.filter.compraCodigo = 0
    },
    initData(state) {
      state.filter.fecha = DateUtils.dateToString(new Date(new Date().getFullYear(), 0, 1), 'dd/MM/yyyy')
      state.filter.numero =
        state.currentUltimoNum !== null
          ? state.currentUltimoNum.auto.toString()
          : '0'
      state.filter.esteblecimientoNum = '001'
      state.filter.ptoEmision = '001'
      state.filter.observaciones = null
      state.filter.saldoAnterior = null
      state.filter.local = null
      state.filter.compraCodigo = 0
    },
    changeFilter(state, action: PayloadAction<FilterBalancesState>) {
      state.filter.fecha = action.payload.fecha
      state.filter.proveedor = action.payload.proveedor
      state.filter.local = action.payload.local
      state.filter.numero = action.payload.numero
      state.filter.esteblecimientoNum = action.payload.esteblecimientoNum
      state.filter.ptoEmision = action.payload.ptoEmision
      state.filter.observaciones = action.payload.observaciones
      state.filter.saldoAnterior = action.payload.saldoAnterior
      state.filter.compraCodigo = action.payload.compraCodigo
    },
    setResetSeleccion(state) {
      const toAdd = {
        index: null,
        row: null,
        selectedRow: null,
      }
      state.seleccionado = toAdd
    },
    setSeleccionarDato(state, action: PayloadAction<Seleccionado>) {
      const toAdd = {
        index: action.payload.index,
        row: action.payload.row,
        selectedRow: action.payload.selectedRow,
      }
      state.seleccionado = toAdd
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBalances.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchBalances.fulfilled, (state, { payload }) => {
      state.resultados = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchBalances.rejected, (state, { payload }) => {
      state.resultados = []
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
    builder.addCase(fetchBalancesNum.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchBalancesNum.fulfilled, (state, { payload }) => {
      state.currentUltimoNum = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchBalancesNum.rejected, (state, { payload }) => {
      state.currentUltimoNum = null
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
    builder.addCase(getToatalWithoutRetentionBuy.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(getToatalWithoutRetentionBuy.fulfilled, (state, { payload }) => {
      state.currentExecute = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(getToatalWithoutRetentionBuy.rejected, (state, { payload }) => {
      state.currentExecute = null
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
  },
})

export const {
  changeFilter,
  setCleanResult,
  setResetSeleccion,
  setSeleccionarDato,
  setCleanFields,
  initData,
  setCleanCurrentExecute,
  setCurrentAction
} = balancesSlice.actions


export const balancesReducer = balancesSlice.reducer