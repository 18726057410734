import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../../store/store'
import { FETCH_STATUS } from '../../../../../store/types'
import {
  FiltroBusquedaProvinciasState, ProvinciasListado,
} from './types';
import { TConfigSearchState } from '../../store/types'
import {ProvinciasService} from "../services/provincias.services";

const initialState: TConfigSearchState<ProvinciasListado> = {
  filter: {
    codigo: 0,
    nombre: '',
    paisCodigo: '',
    codigoDep: '',
    codigoOrigen: '',
    inec: '',
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  resultados: [],
  selectedItem: null,
  error: false,
}

export const fetchProvincias = createAsyncThunk<
  Array<ProvinciasListado>,
  FiltroBusquedaProvinciasState
>('provinces/getAll', async (filtro) => {
  try {
    return await ProvinciasService.getAllProvincias(filtro)
  } catch (error) {
    return Promise.reject(error)
  }
});

const searchConfProvinciasSlice = createSlice({
  name: 'searchProvincias',
  initialState: initialState,
  reducers: {
    resetState(state) {
      state = {
        ...initialState,
        filter: {
          ...initialState.filter,
        },
      }
    },
    changeFilter(state, action: PayloadAction<FiltroBusquedaProvinciasState>) {
      state.filter = action.payload
    },
    setCleanResult(state) {
      state.resultados = []
    },
    setResultados(state, action: PayloadAction<Array<ProvinciasListado> | []>) {
      state.resultados = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProvincias.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchProvincias.fulfilled, (state, { payload }: {payload:any}) => {
      state.resultados = payload.auto
      state.status = FETCH_STATUS.SUCCESS
      state.error = payload.error
      state.mensaje = payload.message
    })
    builder.addCase(fetchProvincias.rejected, (state, { payload }: {payload:any}) => {
      state.resultados = []
      state.status = FETCH_STATUS.FAILED
      state.error = payload.error
      state.mensaje = payload.message
    })
  }
});

export const selectFiltroBusquedaProvincias = (state: RootState) => {
  return state.administracion.catalagos.provincias.search.filter
}

export const selectResultadosBusquedaProvincias = (state: RootState) => {
  return state.administracion.catalagos.provincias.search.resultados
}

export const selectBusquedaProvinciasEstado = (state: RootState) => {
  return state.administracion.catalagos.provincias.search.status
}

export const { setResultados, resetState, setCleanResult, changeFilter } = searchConfProvinciasSlice.actions
export const searchReducer = searchConfProvinciasSlice.reducer
