import { CCard, CCardBody } from '@coreui/react-pro'
import React from 'react'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput'
import NivelesEstudioLookUp from '../../../../../componentes/nivelesEstudioLookUp'
import { TextArea, Validator } from 'devextreme-react'
import { AsyncRule, RequiredRule } from 'devextreme-react/data-grid'
import { TEstudioData } from '../../store/types'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/store'
import { setDatosEdicionEstudios } from '../../store/editDataReducer'
import { SelectBoxDE } from '../../../../../../views/componentes/selectBox-DE/selectBox-DE'

interface IEstudiosProps extends React.PropsWithChildren {
  tabId: string
}

export default function TEstudios(props: IEstudiosProps) {
  const { tabId } = props
  const dispatch = useDispatch()
  const estudiosdMessage: string = 'Estudios: elija un nivel'

  const datosEstudioState = useSelector((state: RootState) => {
    return state.administracion.configuraciones.usuarios.editData[tabId]
      ?.estudios
  })

  const onChangeValue = React.useCallback(
    (data, key: string) => {
      dispatch(
        setDatosEdicionEstudios({
          key: tabId,
          data: {
            ...datosEstudioState,
            [key]: data,
          },
        }),
      )
    },
    [dispatch, datosEstudioState, tabId],
  )

  return (
    <CCard style={{ border: 1 }} className="m-0">
      <CCardBody>
        <div style={{ padding: '10px', overflowX: 'hidden' }} className="mb-4">
          <RowContainer>
            <CustomCol xs="12" md="12">
              <Labeled label="Nivel de Estudio:">
                <SelectBoxDE
                  onValueChange={(data) => {
                    onChangeValue(data, 'estudios')
                  }}
                  items={datosEstudioState?.estudios ?? []}
                  value={datosEstudioState?.estudio ?? null}
                  displayExpr={'descripcion'}
                  searchExpr={['descripcion']}
                >
                  <Validator>
                    <RequiredRule message={'Estudios: elija un nivel'} />
                    {/* <AsyncRule
                  message={estudiosdMessage}
                  validationCallback={validateAsyncSeleccionEstudios}
                /> */}
                  </Validator>
                </SelectBoxDE>
              </Labeled>
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol xs="12" md="6">
              <Labeled label="Titulos Obtenidos:">
                <TextArea
                  value={datosEstudioState?.titulos ?? ''}
                  onValueChange={(data) => onChangeValue(data, 'titulos')}
                ></TextArea>
              </Labeled>
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol xs="12" md="6">
              <Labeled label="Cursos Realizados:">
                <TextArea
                  value={datosEstudioState?.cursos ?? ''}
                  onValueChange={(data) => onChangeValue(data, 'cursos')}
                ></TextArea>
              </Labeled>
            </CustomCol>
          </RowContainer>
        </div>
      </CCardBody>
    </CCard>
  )
}

export const defaultEstudiosUsuarioData: TEstudioData = {
  estudios: [],
  estudio: null,
  titulos: '',
  cursos: '',
}
