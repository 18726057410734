import { combineReducers,} from "@reduxjs/toolkit";
import { WindowVentasState } from "../../../types/generics";
import {  RetencionDatosEdicion, RetencionventaListado } from "../types/types";
import { datosEdicionReducer } from "./editDataReducer";
import { menuReducer } from "./menuReducer";
import { searchRetencionesVentasReducer } from "./searchReducer";
import { tabsReducer } from "./tabsReducer";

// const initialState: WindowVentasState<InfoVenta> = {
//   menu: {},
//   search: {},
//   tabs: {
//     current: "",
//     tabs: []
//   }
// }

export const retencionesVentasReducer = combineReducers<WindowVentasState<RetencionventaListado, RetencionDatosEdicion>>({
  menu: menuReducer,
  tabs: tabsReducer,
  search: searchRetencionesVentasReducer,
  editData: datosEdicionReducer
});