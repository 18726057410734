import React from 'react'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput'
import { Button, CheckBox, DateBox, TextBox } from 'devextreme-react'
import LocalesLookUp from '../../../../../componentes/localesLookUp'
import EstadoProgresoLookUp from '../../../../../componentes/progresoEstadoLookUp'
import { CCol, CRow } from '@coreui/react-pro'

const FiltroBusqueda = () => {
  return (
    <>
      <RowContainer>
        <CustomCol xs="12" md="3">
          <Labeled label="Transferencia Código:">
            <TextBox
              value={null}
              onValueChanged={() => {}}
              showClearButton={true}
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="3">
          <Labeled label="Transferencia Número:">
            <TextBox
              value={null}
              onValueChanged={() => {}}
              showClearButton={true}
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="5">
          <Labeled label="Origen:">
            <CRow>
              <CCol xs="10" md="10">
                <LocalesLookUp
                  onChanged={() => {}}
                  onChangedOptions={() => {}}
                  selected={null}
                />
              </CCol>
              <CCol xs="2" md="2">
                <Button
                  style={{ marginLeft: '-20px' }}
                  id="btnAdd"
                  className="me-1"
                  icon="add"
                  stylingMode="contained"
                  type="default"
                  //onClick={() => setShowModalVenta(true)}
                />
              </CCol>
            </CRow>
          </Labeled>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="6">
          <Labeled label="Observaciones:">
            <TextBox
              value={null}
              onValueChanged={() => {}}
              showClearButton={true}
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="5">
          <Labeled label="Destino:">
            <CRow>
              <CCol xs="10" md="10">
                <LocalesLookUp
                  onChanged={() => {}}
                  onChangedOptions={() => {}}
                  selected={null}
                />
              </CCol>
              <CCol xs="2" md="2">
                <Button
                  style={{ marginLeft: '-20px' }}
                  id="btnAdd"
                  className="me-1"
                  icon="add"
                  stylingMode="contained"
                  type="default"
                  //onClick={() => setShowModalVenta(true)}
                />
              </CCol>
            </CRow>
          </Labeled>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="3">
          <Labeled label="Item:">
            <TextBox
              value={null}
              onValueChanged={() => {}}
              showClearButton={true}
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="3">
          <Labeled label="Rango de Fechas:">
            <div
              style={{
                display: 'flex',
                gap: '2px',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <DateBox
                displayFormat="dd/MM/yyyy"
                //value={fechaDeposito}
                onValueChanged={() => {}}
              />
              <DateBox
                displayFormat="dd/MM/yyyy"
                //value={fechaDeposito}
                onValueChanged={() => {}}
              />
            </div>
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="3">
          <Labeled label="Progreso:">
            <EstadoProgresoLookUp
              selected={null}
              onChanged={() => {}}
              onChangedOptions={() => {}}
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="1" className="mt-4">
          <CheckBox text="Archivados" value={false} onValueChanged={() => {}} />
          <CheckBox text="Activos" value={false} onValueChanged={() => {}} />
        </CustomCol>
      </RowContainer>
    </>
  )
}

export default FiltroBusqueda
