import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DateUtils } from "../../../../../../../helpers/dateUtils";
import { RootState } from "../../../../../../../store/store";
import { DateRange, FETCH_STATUS } from "../../../../../../../store/types";
import { TLocal } from "../../../../../../shared/components/buscarLocalesLookMemoUp/type/types";
import { Cliente } from "../../../../../types/types";
import { FiltroBusquedaOrdenPedidoState, OrdenPedidoListado, SearchOrdenPedidoState } from "../../../type/types";
import { ItemPedido } from "../../../../ordenes/pages/ordenPedido/type/types";
import { OrdenPedidoService } from "../services/ordenPedido.services"

const initialState: SearchOrdenPedidoState<OrdenPedidoListado> = {
  filter: {
    fechaInicio: DateUtils.getCurrentDateAsString(),
    fechaFin: DateUtils.getCurrentDateAsString(),
    numero: '',
    cliente: null,
    local: null,
    item: null
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  resultados: []
}

export const fetchOrdenPedido = createAsyncThunk<Array<OrdenPedidoListado>, FiltroBusquedaOrdenPedidoState>(
  'compras/documentos/general/orders/getAll',
  async (filtro) => {
    try {
      const rangofechas: DateRange = {
        inicio: DateUtils.pickersDateToApiDate(filtro.fechaInicio),
        fin: DateUtils.pickersDateToApiDate(filtro.fechaFin)
      }
      const ords = await OrdenPedidoService.getOrdenes(rangofechas,
        filtro.numero ? filtro.numero : "", filtro
      );
      return ords;
    } catch (error) {
      return Promise.reject(error);
    }
  }
)



const searchOrdenPedidoSlice = createSlice({
  name: 'searchOrdenPedido',
  initialState: initialState,
  reducers: {
    resetState(state) {
      state = {
        ...initialState,
        filter: {
          ...initialState.filter,
          fechaInicio: DateUtils.getCurrentDateAsString(),
          fechaFin: DateUtils.getCurrentDateAsString(),
        },
      };
    },
    setCustomer(state, action: PayloadAction<Cliente | null>) {
      state.filter.cliente = action.payload;
    },
    setLocal(state, action: PayloadAction<TLocal | null>) {
      state.filter.local = action.payload;
    },
    changeFilter(state, action: PayloadAction<FiltroBusquedaOrdenPedidoState>) {
      state.filter = action.payload;
    },
    setSearchStatus(state, action: PayloadAction<FETCH_STATUS>) {
      state.status = action.payload;
    },
    setCleanResult(state) {
      state.resultados = [];
    },
    setDateStart(state, action: PayloadAction<string>) {
      state.filter.fechaInicio = action.payload;
    },
    setDateEnd(state, action: PayloadAction<string>) {
      state.filter.fechaFin = action.payload;
    },
    setItem(state, action: PayloadAction<ItemPedido | null>) {
      state.filter.item = action.payload;
    },
    setResultFilter(state, action: PayloadAction<any>) {
      state.resultados = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOrdenPedido.pending, (state) => {
      if (state.status === FETCH_STATUS.IDDLE || state.status === FETCH_STATUS.SUCCESS) {
        state.status = FETCH_STATUS.LOADING;
        state.mensaje = '';
      }
    })
    builder.addCase(fetchOrdenPedido.fulfilled, (state, { payload }) => {
      state.resultados = payload;
      state.status = FETCH_STATUS.SUCCESS;
    })
    builder.addCase(fetchOrdenPedido.rejected, (state, { payload }) => {
      state.resultados = [];
      state.status = FETCH_STATUS.FAILED;
      state.mensaje = payload as string;
    })
  }
})


export const selectFiltroBusquedaOrdenPedido = (state: RootState) => { return state.ventas.ordenes.ordenPedido.search.filter };
export const selectResultadosBusquedaOrdenPedido = (state: RootState) => { return state.ventas.ordenes.ordenPedido.search.resultados };
export const selectBusquedaOrdenPedidoEstado = (state: RootState) => { return state.ventas.ordenes.ordenPedido.search.status };
export const {
  setDateEnd, setDateStart, setCleanResult,
  changeFilter, setSearchStatus, resetState,
  setResultFilter, setCustomer, setLocal,
  setItem
} = searchOrdenPedidoSlice.actions;
export const searchOrdenPedidoReducer = searchOrdenPedidoSlice.reducer;