import React, { memo } from 'react';
import { CCol, CRow, CTooltip, CLink } from '@coreui/react-pro'
import {
  Column,
  DataGrid,
  FilterRow,
  HeaderFilter,
  Paging,
  LoadPanel,
  Pager, Button as DatagridButton,
  Export
} from 'devextreme-react/data-grid'
import CIcon from '@coreui/icons-react';
import { VentasService } from '../../../../../services/ventas.service';
import { CompraService } from '../../../../../../compras/services/compra.service';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { consoleService } from '../../../../../../../services/console.service';
import { DateUtils } from '../../../../../../../helpers/dateUtils';
import { ETypeItems } from '../../../../../../../store/types';
import { TOptionGenerico } from '../../../../../../clientes/pages/clientes/types/types';
import { ConfigFiltros } from '../../../../../pages/ordenes/pages/ordenPedido/components/busquedaPedidos/BusquedaItemsSelectBox/BuscarItemPedidoMemoLookUp';
import { Movimiento } from '../../../../../../inventario/pages/movimientos/conceptosMovimientos/types/types'
import { LocalOption } from '../../../../../../componentes/localesLookUp/store/type';
import { EAplicationsAcatha, EModulosAcatha } from '../../../../../../../store/enumsAplication';

type infoPaginationType = {
  desde: number
  hasta: number
  paginaActual: number
  totalPaginas: number
  totalRegistros: number
}

type findItems = {
  descripcion: string
  codigo: string
}

interface TablaItemsProps extends React.PropsWithChildren {
  transaccion: string
  name: string
  data: any
  capturaSeleccion: any
  filaSeleccionada: any
  actionDelete: any
  funcion: string
  returnItem: (item) => void
  resetFunction: () => void
  pagination: infoPaginationType
  cliente?: any
  proveedor?: any
  fecha: any
  dataSearch: any
  setDataItems: (data) => void
  modulo?: EModulosAcatha | EAplicationsAcatha
  tipoItem?: TOptionGenerico
  configFiltros?: ConfigFiltros
  requestType?: ETypeItems
  movimiento?: Movimiento
  codigoPrvAsociado?: number
  local?: LocalOption
}

const infoPaginationInit = {
  desde: 1,
  hasta: 10,
  paginaActual: 0,
  totalPaginas: 0,
  totalRegistros: 0,
}

const infoFind = {
  descripcion: '',
  codigo: '',
}

const TablaItems = (props: TablaItemsProps) => {
  const tablaRef = React.useRef<any>()
  const tablaRefPager = React.useRef<any>()

  const {
    requestType,
    configFiltros,
    tipoItem,
    modulo,
    funcion,
    returnItem,
    pagination,
    cliente,
    proveedor,
    fecha,
    dataSearch,
    resetFunction,
    setDataItems,
    transaccion,
    codigoPrvAsociado,
    movimiento,
    local
  } = props

  const [data, setData] = React.useState<any>([])
  const [infoPagination, setInfoPagination] =
    React.useState<infoPaginationType>(infoPaginationInit)
  const [pageSize, setPageSize] = React.useState<number>(10)
  const [pageIndex, setPageIndex] = React.useState<number>(10)
  const [dataSeacrh, setDataSeacrh] = React.useState<findItems>(infoFind)

  const selectionChanged = (selectionChanged) => {
    if (data.length > 0) {
      if (selectionChanged['selectedRowsData'].length > 0) {
        const filtroImpresora: number = data.findIndex((item: any) => {
          return (
            Number(item.codigo) ===
            Number(selectionChanged['selectedRowsData'][0]['codigo'])
          )
        })

        props.capturaSeleccion({
          index: filtroImpresora,
          row: selectionChanged['selectedRowsData'][0],
          selectedRow: filtroImpresora,
        })
      }
    }
  }

  const onRowDoubleClick = React.useCallback(
    (e) => {
      const { data } = e
      const item = data
      returnItem(item)
    },
    [returnItem],
  )

  const handleOptionChange = React.useCallback(
    (e) => {
      if (e.fullName === 'paging.pageSize') {
        setPageSize(e.value)
      }
      if (e.fullName === 'paging.pageIndex') {
        setPageIndex(e.value)
      }
      if (e.fullName === 'selectedRowKeys') {
        if (data._items && data._items.length > 0) {
          if (e['value'].length > 0) {
            const filtrado: number = data._items.findIndex((item: any) => {
              return String(item.codigoBarras) === String(e['value'])
            })
            if (filtrado > -1) {
              props.capturaSeleccion({
                index: filtrado,
                row: data._items[filtrado],
                selectedRow: filtrado,
              })
            }
          }
        }
      }
    },
    [data, props],
  )

  const onSearch = React.useCallback(
    async (dataSeacrh) => {
      if (transaccion === 'ventas') {
        const resFind = await VentasService.getItemsGridDatasource(
          cliente,
          fecha,
          dataSeacrh,
          modulo,
          tipoItem,
          configFiltros,
          movimiento,
          codigoPrvAsociado,
          local
        )
        setData(resFind)
        setDataItems(resFind)
      } else if (transaccion === 'compras') {
        const resFind = await CompraService.getItemsGridDatasource(
          proveedor,
          dataSeacrh,
        )
        setData(resFind)
        setDataItems(resFind)
      }
    },
    [
      transaccion,
      cliente,
      tipoItem,
      modulo,
      proveedor,
      fecha,
      setDataItems,
      configFiltros,
      movimiento,
      codigoPrvAsociado,
      local
    ],
  )

  const onExporting = React.useCallback(async (e) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('Items')

    const isSelection = e.component.getController('export')._selectionOnly

    let keys = []
    if (!isSelection) {
      //export only current page
      keys = e.component.getSelectedRowKeys()
      const newKeys: any = e.component
        .getVisibleRows()
        .filter((r) => r.rowType === 'data')
        .map((i) => i.key)
      e.component.option('selectedRowKeys', newKeys)
    }

    let oldDS = null
    let items = null

    oldDS = e.component.getDataSource()
    items = oldDS.items()

    consoleService.log('items:', items)

    setTimeout(() => {
      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        selectedRowsOnly: !isSelection,
      })
        .then(function () {
          workbook.xlsx.writeBuffer().then(function (buffer) {
            saveAs(
              new Blob([buffer], { type: 'application/octet-stream' }),
              'ListadoItems-' +
              DateUtils.dateToString(new Date(), 'dd-MM-yyyy') +
              '.xlsx',
            )
          })
        })
        .then(function () {
          // e.component.option("dataSource", oldDS);
        })
    }, 3000) // adjust the time depending on how long it takes for DataGrid to swap the dataSource
    e.cancel = true
  }, [])

  const onResetTab = React.useCallback(() => {
    setPageIndex(0)
  }, [])

  React.useEffect(() => {
    setDataSeacrh(dataSearch)
  }, [dataSearch])

  React.useEffect(() => {
    setInfoPagination(pagination)
  }, [pagination])

  React.useEffect(() => {
    if (funcion === 'Deshacer') {
      setData([])
    } else if (funcion === 'Buscar') {
      onResetTab()
      resetFunction()
      setInfoPagination(infoPaginationInit)
      onSearch(dataSearch)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [funcion])

  React.useEffect(() => {
    setData(props.data)
  }, [props.data])

  function getPages() {
    const pages = [8, 12, 20, 50, 100]
    if (tablaRef?.current !== undefined) {
      const cont = tablaRef.current.instance.getDataSource().totalCount()
      if (cont > 100) {
        if (cont > 100 && cont <= 1000) {
          pages.push(500)
          pages.push(750)
        } else if (cont > 1000 && cont <= 5000) {
          pages.push(1000)
          pages.push(2500)
        } else if (cont > 5000) {
          pages.push(5000)
        } else {
          pages.push(500)
        }
      }
    }
    return pages
  }

  return (
    <>
      <CRow>
        <CCol lg="6" className="d-flex justify-content-start">
          {data.length > 0 && (
            <>
              <CTooltip
                key={'tool-more'}
                placement="top"
                content={
                  'Hacer doble click sobre el item para copiar el item a la transacción.'
                }
              >
                <div className="card-header-actions">
                  <CLink className="card-header-action">
                    <CIcon icon="cil-info" />
                  </CLink>
                </div>
              </CTooltip>
            </>
          )}
        </CCol>
        <CCol lg="6" className="d-flex justify-content-end">
          {/* <div>
            <CBadge color='info'>
              {`${data.length} REGISTROS ENCONTRADOS`}
            </CBadge>
          </div> */}
        </CCol>
      </CRow>
      <CRow>
        <CCol
          className="d-flex justify-content-center mt-2"
          md="12"
          lg="12"
          xs="12"
        >
          <DataGrid
            ref={tablaRef}
            dataSource={data ?? []}
            selection={{ mode: 'single' }}
            hoverStateEnabled={true}
            showBorders={true}
            showRowLines={true}
            onSelectionChanged={selectionChanged}
            onRowDblClick={onRowDoubleClick}
            allowColumnReordering={true}
            allowColumnResizing
            columnResizingMode="widget"
            columnAutoWidth={true}
            activeStateEnabled={true}
            onOptionChanged={handleOptionChange}
            remoteOperations={true}
            onExporting={onExporting}
          >
            {/* <Column type='buttons'>
        <Button icon='add'
          onClick={onAddButtonClick}
          visible={isAddButtonVisible}
        />
        <Button name='delete' />
        <Button name='save' />
        <Button name='cancel' />
      </Column> */}
            <Export enabled={true} allowExportSelectedData={true} />
            <Paging
              pageIndex={pageIndex}
              enabled={true}
              defaultPageSize={10}
              pageSize={pageSize}
            />
            <Pager
              ref={tablaRefPager}
              showPageSizeSelector={true}
              allowedPageSizes={getPages()}
              showNavigationButtons={true}
              showInfo={true}
              infoText="Page #{0}. Total: {1} ({2} items)"
            />
            <LoadPanel enabled={true} />
            <FilterRow visible={true} />
            <HeaderFilter visible={true} />
            <Column type="buttons" width="7%">
              <DatagridButton
                icon="arrowleft"
                visible={true}
                hint="Seleccionar"
                onClick={(e) => {
                  returnItem(e.row.data)
                }}
              />
            </Column>
            <Column
              dataType="string"
              dataField="codigoBarras"
              caption="Barras"
              width="15%"
            />
            <Column
              dataType="string"
              dataField="codigoInterno"
              caption="Cod. Interno"
              width="15%"
            />
            <Column
              dataType="string"
              dataField="descripcion"
              caption="Descripción"
              minWidth={'60%'}
              width={'60%'}
            />
            <Column
              dataType="number"
              dataField="existencia"
              caption="Stock"
              width="10%"
            />
            <Column
              dataType="number"
              dataField="pvpiva"
              caption="PVP"
              width="15%"
            />
          </DataGrid>
        </CCol>
      </CRow>
    </>
  )
}

export default memo(TablaItems)
