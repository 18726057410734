/* eslint-disable no-unused-vars */
/**
 * Enumerador Codigo de Subtipos
 */
export enum ESubtipo {
  Contratos = 181,
  EstadosCivil = 174,
  Desempeno = 90,
  Establecimientos = 6,
  Estados = 1,
  Grupo = 100,
  Clasificaciones = 90,
  Estudios = 175,
  Progreso = 13,
  SituacionLaboral = 176,
  Discapcidad = 182,
  IdetificacionSub = 121,
  TipoSangre = 178,
  AsociadosMovimientos = 20,
  ConceptosMovimientos = 4,
  TipoEmpresa = 177,
  ProcesosTrans = 183,
  Depreciacion = 184,
  TipoOperaciones = 122, // sv
  ClasificacionesSujEx = 123, // sv
  TipoCostos = 124, // sv
  TipoSector = 125, // sv
}
