import React, { useCallback, useEffect } from 'react'
import { GlobalMenu } from '../../../../views/componentes/globalMenu/globalMenu'
import { AccionMenu, CustomButtons, ToastTypes } from '../../../../store/types'
import CardContent from '../../../bancos/components/cardContent'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../../store/store'
import { setCollpased, setCurrentFunction } from './store/generalReducer'
import TabControl from './components/shared'
import { Aplicacion } from '../../store/types'
import { changeCurrentTab, setButtons } from './store/tabsReducer'
import { ButtonTypes } from '../../../../views/componentes/globalMenu/types'
import { setCleanResult, setCodeSelectedKardex } from './store/serachReducer'
import Dialog from '../../../../views/componentes/librerias/bootstrap-dialog'
import {
  useButtonDisabled,
  useMenuOptions,
} from '../../../../hooks/useMenuButtonControls'
import ModalAduanaLotes from './components/modalAdunasLotes/'
import ModalProveedoresKdx from '../items/components/modalProveedores'
import { useSetToast } from '../../../../hooks/useGlobalHooks'
import { KardexServices } from './services/kardex.services'
import { DateUtils } from '../../../../helpers/dateUtils'
import { ReportWithLocalDataModal } from '../../../../views/componentes/xtrareports/ReportWithLocalDataModal'
import { KardexListResult } from './types/types'
import ModalProcedencia from './components/modalProcedencia'
import { useLoaderKardex } from './customHooks'

const pathVerificador = '/kardex'

interface KardexProps extends React.PropsWithChildren {}

const Kardex: React.FunctionComponent<KardexProps> = () => {
  const dispatch = useDispatch()
  const menuState = useSelector((state: RootState) => state.global.menu)
  const tabs = useSelector((state: RootState) => {
    return state.inventarios.kardex.tabs
  })
  const loader = useSelector((state: RootState) => {
    return state.inventarios.kardex.general.loader
  })
  const searchFilter = useSelector((state: RootState) => {
    return state.inventarios.kardex.search.filter
  })
  const currentTab = useSelector((state: RootState) => {
    return state.inventarios.kardex.search.currentTab
  })
  const resultsSearch = useSelector((state: RootState) => {
    return state.inventarios.kardex.search.resultItem
  })
  const codeSelectedKardex = useSelector((state: RootState) => {
    return state.inventarios.kardex.search.codeSelectedKardex
  })

  const [confirmRebuild, setConfirmRebuild] = React.useState<boolean>(false)
  const [confirmRebuildAll, setConfirmRebuildAll] =
    React.useState<boolean>(false)
  const [showModalProvenance, setShowModalProvenance] =
    React.useState<boolean>(false)
  const [showModalLotes, setShowModalLotes] = React.useState<boolean>(false)
  const [showModalPrv, setShowModalPrv] = React.useState<boolean>(false)
  const [optionsMenu, setOptionsMenu] = React.useState<Array<AccionMenu>>([])
  const [pdfReport, setPdfReport] = React.useState<
    null | 'Viewer' | 'Designer'
  >(null)
  const dialogRef = React.useRef<any>(null)

  const setToastMessage = useSetToast()
  const getButtonDisabled = useButtonDisabled(tabs)
  const { getMenuOptions, options } = useMenuOptions()
  const { playLoader, stopLoader } = useLoaderKardex()

  const onTabClosing = React.useCallback(() => {}, [])

  const onTabChanged = React.useCallback(
    (tabKey: string) => {
      dispatch(changeCurrentTab(tabKey))
    },
    [dispatch],
  )

  const onRebuild = useCallback(async () => {
    try {
      playLoader('Reconstruyendo kardex . . .')
      const rebuildKardex = await KardexServices.rebuildKardex({
        itemCodigo: searchFilter.codigo,
        fechaDesde:
          DateUtils.pickersDateToApiDate(searchFilter.fechaDesde) ?? '',
      })
      stopLoader()
      if (!rebuildKardex.error && rebuildKardex.auto)
        setToastMessage(
          'Reconstuir Kardex',
          rebuildKardex.message ?? 'Kardex reconstruido con éxito',
          ToastTypes.Success,
        )
      else
        setToastMessage(
          'Reconstuir Kardex',
          rebuildKardex.message ?? 'Error al reconstruir kardex',
          ToastTypes.Warning,
        )
    } catch (error) {
      stopLoader()
      setToastMessage('Reconstuir Kardex', error, ToastTypes.Danger)
    }
  }, [
    playLoader,
    searchFilter.codigo,
    searchFilter.fechaDesde,
    setToastMessage,
    stopLoader,
  ])

  const onConfirmRebuildAll = React.useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: 'Esta seguro de que desea reconstruir el kardex de todos los items?',
      actions: [
        Dialog.Action(
          <span>
            <u>A</u>ceptar
          </span>,
          (dialog) => {
            dialog.hide()
            onRebuild()
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setConfirmRebuildAll(false)
    return
  }, [onRebuild])

  const onConfirmRebuild = React.useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `Esta a punto de eliminar el kardex, recuerde que si elimina el kardex se eliminarán los asientos contables relacionados.
      Desea continuar?`,
      actions: [
        Dialog.Action(
          <span>
            <u>A</u>ceptar
          </span>,
          (dialog) => {
            dialog.hide()
            if (searchFilter.codigo > 0) onRebuild()
            else setConfirmRebuildAll(true)
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setConfirmRebuild(false)
    return
  }, [onRebuild, searchFilter.codigo])

  React.useEffect(() => {
    if (confirmRebuildAll) {
      onConfirmRebuildAll()
    }
  }, [confirmRebuildAll, onConfirmRebuildAll])

  React.useEffect(() => {
    if (confirmRebuild) {
      onConfirmRebuild()
    }
  }, [confirmRebuild, onConfirmRebuild])

  const onParseData = React.useCallback(
    (kardexItem: Array<KardexListResult>) => {
      let kardexList: Array<any> = []
      kardexList = kardexItem.map((itemKardex: KardexListResult) => {
        return {
          fecha: itemKardex?.registro ?? '',
          interno: itemKardex?.itemCodigoUsuario ?? '',
          movimiento: itemKardex?.kardexDescripcion ?? '',
          linea: itemKardex?.lineaDescripcion ?? '',
          unidad: itemKardex?.unidadNombre ?? '',
          cantidadIn: itemKardex?.cantIng ?? 0,
          punitarioIn: itemKardex?.precioUniIng ?? 0,
          valorIn: itemKardex?.valorIng ?? 0,
          cantidadEg: itemKardex?.cantEgr ?? 0,
          punitarioEg: itemKardex?.precioUniEgr ?? 0,
          valorEg: itemKardex?.valorEgr ?? 0,
          cantidadSt: itemKardex?.saldo ?? 0,
          punitarioSt: itemKardex?.costo ?? 0,
          valorSt: itemKardex?.totalX ?? 0,
        }
      })
      return kardexList
    },
    [],
  )

  const onMenuButtonClick = React.useCallback(
    async (action: AccionMenu) => {
      switch (action.actionType) {
        case ButtonTypes.find:
          dispatch(setCurrentFunction(ButtonTypes.find))
          break
        case ButtonTypes.broom:
          dispatch(setCleanResult())
          dispatch(setCollpased(true))
          break
        case ButtonTypes.seeRequestLots:
          setShowModalLotes(true)
          break
        case ButtonTypes.providers:
          setShowModalPrv(true)
          break
        case ButtonTypes.rebuildKardex:
          setConfirmRebuild(true)
          break
        case ButtonTypes.print:
          setPdfReport('Viewer')
          break
        default:
          break
      }
    },
    [dispatch],
  )

  useEffect(() => {
    if (options.length > 0) {
      const acctionButtons = structuredClone(options)
      acctionButtons.map((action: AccionMenu) => {
        if (action.nombre === 'Ver pedimentos y lotes')
          action.actionType = ButtonTypes.seeRequestLots
      })
      setOptionsMenu(acctionButtons)
    }
  }, [options])

  useEffect(() => {
    if (menuState.length > 0 && options.length === 0) {
      getMenuOptions(menuState, 'to', pathVerificador)
    }
  }, [menuState, getMenuOptions, options])

  useEffect(() => {
    dispatch(
      setButtons({
        tabKey: 'BUSQUEDA',
        buttons: {
          Buscar: true,
          Limpiar: true,
          Pedimentos_Lotes: searchFilter.codigo > 0,
          Proveedores: searchFilter.codigo > 0,
          Reconstruir: true,
          Imprimir:
            searchFilter.codigo > 0 &&
            currentTab === 'Item' &&
            resultsSearch.length > 0,
        },
      }),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFilter.codigo, resultsSearch, currentTab])

  useEffect(() => {
    if (codeSelectedKardex > 0) setShowModalProvenance(true)
  }, [codeSelectedKardex, dispatch])

  const optionsForm = () => {
    if (optionsMenu.length > 0) {
      return (
        <>
          <GlobalMenu
            printButtonAsDropdown={false}
            showExportDesingOptions
            acciones={optionsMenu}
            onClick={onMenuButtonClick}
            getButtonDisabled={getButtonDisabled}
            currentWorking={tabs.tabs[tabs.current].currentExecutingAction}
          />
        </>
      )
    } else {
      return <> </>
    }
  }

  const bodyForm = () => {
    return (
      <TabControl
        tabsState={tabs}
        onTabChanged={onTabChanged}
        ontabClosing={onTabClosing}
        tipoAplicacion={Aplicacion.kardex}
      />
    )
  }

  return (
    <>
      {showModalProvenance && (
        <ModalProcedencia
          show={showModalProvenance}
          onClose={() => {
            setShowModalProvenance(false)
            dispatch(setCodeSelectedKardex(0))
          }}
          codeKardex={codeSelectedKardex}
        />
      )}
      {pdfReport !== null && (
        <ReportWithLocalDataModal
          show={pdfReport !== null}
          onClose={() => setPdfReport(null)}
          data={onParseData(resultsSearch) ?? []}
          fileName="StaticKardexList"
          mode={pdfReport ?? 'Viewer'}
          parameters={{
            Reporte_Filtro: `${DateUtils.pickersDateToApiDate(
              searchFilter.fechaDesde,
            )} - ${DateUtils.pickersDateToApiDate(searchFilter.fechaHasta)}`,
          }}
          template="StaticKardexList"
          key="reportDesignerKardex"
        />
      )}
      {showModalPrv && (
        <ModalProveedoresKdx
          show={showModalPrv}
          code={searchFilter.codigo}
          onClose={() => setShowModalPrv(false)}
        />
      )}
      {showModalLotes && (
        <ModalAduanaLotes
          show={showModalLotes}
          code={searchFilter.codigo}
          barras={searchFilter.barras}
          description={searchFilter.descripcion}
          onClose={() => setShowModalLotes(false)}
        />
      )}
      <div id={'kardex'}>
        <Dialog ref={dialogRef} />
        <CardContent
          childrenOptions={optionsForm()}
          childrenBody={bodyForm()}
          headerTitle={'Kardex'}
          aplicacion={'Kardex'}
          loader={loader}
        />
      </div>
    </>
  )
}

export default Kardex

export const ModuleButtons: CustomButtons = {
  Nuevo: true,
  Guardar: true,
  Editar: false,
  Eliminar: false,
  Buscar: true,
  Imprimir: false,
  Deshacer: true,
}
