import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ContratosDatosEdicion, configContratos } from '../types/types'
import { EdicionPayload } from '../../../store/types'

const initialState: configContratos = {
  aplicacion: 0,
}

const configuracionContratos = createSlice({
  name: 'configContratosNomina',
  initialState: initialState,
  reducers: {
    setAplicacionModulo(state, acion: PayloadAction<number>) {
      state.aplicacion = acion.payload
    },
    deleteEditDataBackup(state, action: PayloadAction<string>) {
      delete state[action.payload]
    },
    setDatosEdicionBackup(
      state,
      action: PayloadAction<EdicionPayload<ContratosDatosEdicion>>,
    ) {
      state[action.payload.key] = action.payload.data
    },
  },
})

export const configuracionesContratosNomina = configuracionContratos.reducer
export const { setAplicacionModulo, deleteEditDataBackup, setDatosEdicionBackup } = configuracionContratos.actions
