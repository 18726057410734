import React from 'react'
import RowContainer from '../../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../../views/componentes/colContainer'
import {
  Button,
  DataGrid,
  DateBox,
  NumberBox,
  TextArea,
} from 'devextreme-react'
import { Column } from 'devextreme-react/data-grid'
import Labeled from '../../../../../../../../views/componentes/labeledInput/labeledInput'
import { ItemPromotions, Promotion } from '../../../../types/types'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../../store/store'
import { setDataPromotionsToEdit } from '../../../../store/editDataReduce'
import { useSetToast } from '../../../../../../../../hooks/useGlobalHooks'
import { ToastTypes } from '../../../../../../../../store/types'
import Dialog from '../../../../../../../../views/componentes/librerias/bootstrap-dialog'
import { changeLoaderItems } from '../../../../store/generalReducer'
import { setCurrentExecutingAction } from '../../../../store/tabsReducer'
import { ButtonTypes } from '../../../../../../../../views/componentes/globalMenu/types'
import { ItemsServices } from '../../../../services/items.services'

const Promociones = (props) => {
  const { tabId } = props

  const dispatch = useDispatch()
  const setToastMessage = useSetToast()

  const dataToEditPromotions = useSelector(
    (state: RootState) => state.inventarios.items.editData[tabId].promociones,
  )
  const codeItem = useSelector(
    (state: RootState) => state.inventarios.items.editData[tabId].codigoInterno,
  )

  const [rowIndex, setRowIndex] = React.useState<number>(-1)
  const [confirmToDelete, setConfirmToDelete] = React.useState<boolean>(false)
  const dialogRef = React.useRef<any>(null)

  const playLoader = React.useCallback(
    (message = 'Cargando...') => {
      dispatch(changeLoaderItems({ show: true, mensaje: message }))
    },
    [dispatch],
  )

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoaderItems({ show: false, mensaje: '' }))
  }, [dispatch])

  const onChangedFromPromotions = React.useCallback(
    (event: any, fieldToUpdate: string) => {
      if (event.event)
        dispatch(
          setDataPromotionsToEdit({
            key: tabId,
            promotion: {
              ...dataToEditPromotions,
              [fieldToUpdate]: event.value,
            },
          }),
        )
    },
    [dispatch, tabId, dataToEditPromotions],
  )

  const onRemovePromotion = React.useCallback(() => {
    const promotions = structuredClone(dataToEditPromotions.dataGrid)
    if (promotions[rowIndex].codigo > 0) setConfirmToDelete(true)
    else {
      promotions.splice(rowIndex, 1)
      dispatch(
        setDataPromotionsToEdit({
          key: tabId,
          promotion: {
            ...dataToEditPromotions,
            dataGrid: promotions,
          },
        }),
      )
      setRowIndex(-1)
    }
  }, [dataToEditPromotions, dispatch, rowIndex, tabId])

  const validateForm = React.useCallback(() => {
    let isValid: boolean = true
    if (
      dataToEditPromotions.descripcion === '' ||
      dataToEditPromotions.desde === '' ||
      dataToEditPromotions.hasta === '' ||
      dataToEditPromotions.descuento === 0
    )
      isValid = false
    return isValid
  }, [dataToEditPromotions])

  const onAddPromotion = React.useCallback(() => {
    if (validateForm()) {
      const newPromotion: Promotion = {
        uuid: crypto.randomUUID(),
        codigo: 0,
        itemCodigo: codeItem,
        desde: dataToEditPromotions.desde,
        hasta: dataToEditPromotions.hasta,
        descuento: dataToEditPromotions.descuento,
        descripcion: dataToEditPromotions.descripcion,
      }
      const refreshDataTablePromotion: Array<any> = structuredClone(
        dataToEditPromotions.dataGrid,
      )
      refreshDataTablePromotion.push(newPromotion)
      dispatch(
        setDataPromotionsToEdit({
          key: tabId,
          promotion: {
            ...dataToEditPromotions,
            dataGrid: refreshDataTablePromotion,
            descripcion: '',
            descuento: 0,
          },
        }),
      )
    } else
      setToastMessage(
        'Agregar Promoción',
        'Complete todo el formulario para agregar una promoción',
        ToastTypes.Warning,
      )
  }, [
    validateForm,
    setToastMessage,
    codeItem,
    dataToEditPromotions,
    dispatch,
    tabId,
  ])

  const onDeletePromotion = React.useCallback(
    async (promotionCode: number) => {
      try {
        playLoader('Eliminando promoción . . .')
        dispatch(
          setCurrentExecutingAction({
            tabKey: tabId,
            buttonType: ButtonTypes.delete,
          }),
        )
        const deletePromo = await ItemsServices.deletePromotion(promotionCode)
        stopLoader()
        dispatch(
          setCurrentExecutingAction({
            tabKey: tabId,
            buttonType: undefined,
          }),
        )
        if (!deletePromo.error && deletePromo.auto) {
          const promotions = structuredClone(dataToEditPromotions.dataGrid)
          promotions.splice(rowIndex, 1)
          dispatch(
            setDataPromotionsToEdit({
              key: tabId,
              promotion: {
                ...dataToEditPromotions,
                dataGrid: promotions,
              },
            }),
          )
          setToastMessage(
            'Eliminar Promoción',
            deletePromo.message ?? 'Promoción eliminada correctamente',
            ToastTypes.Success,
          )
          setRowIndex(-1)
        } else
          setToastMessage(
            'Eliminar Promoción',
            deletePromo.message ?? 'Error al eliminar promoción',
            ToastTypes.Warning,
          )
      } catch (error) {
        stopLoader()
        setToastMessage(
          'Eliminar Promoción',
          JSON.stringify(error),
          ToastTypes.Danger,
        )
        dispatch(
          setCurrentExecutingAction({
            tabKey: tabId,
            buttonType: undefined,
          }),
        )
      }
    },
    [
      dataToEditPromotions,
      dispatch,
      playLoader,
      rowIndex,
      setToastMessage,
      stopLoader,
      tabId,
    ],
  )

  const onConfirmToDelette = React.useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea eliminar la promoción  ${dataToEditPromotions.dataGrid[rowIndex].descripcion}?`,
      actions: [
        Dialog.Action(
          <span>
            <u>A</u>ceptar
          </span>,
          (dialog) => {
            dialog.hide()
            onDeletePromotion(dataToEditPromotions.dataGrid[rowIndex].codigo)
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setConfirmToDelete(false)
    return
  }, [dataToEditPromotions.dataGrid, onDeletePromotion, rowIndex])

  React.useEffect(() => {
    if (confirmToDelete) onConfirmToDelette()
  }, [confirmToDelete, onConfirmToDelette])

  return (
    <>
      <Dialog ref={dialogRef} />
      <RowContainer className="m-2">
        <RowContainer>
          <CustomCol xs="12" md="6">
            <Labeled label="Descripción:">
              <TextArea
                value={dataToEditPromotions.descripcion}
                onValueChanged={(evt) =>
                  onChangedFromPromotions(evt, 'descripcion')
                }
              />
            </Labeled>
          </CustomCol>
        </RowContainer>
        <RowContainer>
          <CustomCol xs="12" md="2">
            <Labeled label="F. Desde:">
              <DateBox
                displayFormat="dd/MM/yyyy"
                value={dataToEditPromotions.desde}
                onValueChanged={(evt) => onChangedFromPromotions(evt, 'desde')}
              />
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="2">
            <Labeled label="F. Hasta:">
              <DateBox
                displayFormat="dd/MM/yyyy"
                value={dataToEditPromotions.hasta}
                onValueChanged={(evt) => onChangedFromPromotions(evt, 'hasta')}
              />
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="2">
            <Labeled label="% Descuento:">
              <NumberBox
                value={dataToEditPromotions.descuento}
                onValueChanged={(evt) =>
                  onChangedFromPromotions(evt, 'descuento')
                }
                min={0}
                max={100}
              />
            </Labeled>
          </CustomCol>
        </RowContainer>
        <RowContainer>
          <CustomCol xs="12" md="7" style={{ marginTop: '4px' }}>
            <div
              style={{
                display: 'flex',
                gap: '2px',
                alignItems: 'center',
                width: '100%',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                id="btnAdd"
                className="me-1"
                icon="add"
                stylingMode="contained"
                type="default"
                onClick={() => onAddPromotion()}
              />
              <Button
                id="btnRemove"
                icon="trash"
                stylingMode="contained"
                type="danger"
                onClick={() => onRemovePromotion()}
                disabled={
                  dataToEditPromotions.dataGrid.length === 0 || rowIndex < 0
                }
              />
            </div>
          </CustomCol>
        </RowContainer>
        <RowContainer>
          <CustomCol xs="12" md="7">
            <DataGrid
              className="m-2"
              selection={{ mode: 'single' }}
              hoverStateEnabled={true}
              dataSource={dataToEditPromotions.dataGrid ?? []}
              loadPanel={{ enabled: true }}
              onRowClick={({ rowIndex }) => setRowIndex(rowIndex)}
              showBorders={true}
              showRowLines={true}
            >
              <Column
                dataType="string"
                dataField="descripcion"
                caption="Descripción"
                width="55%"
              />
              <Column
                dataType="date"
                dataField="desde"
                caption="Desde"
                width="15%"
              />
              <Column
                dataType="date"
                dataField="hasta"
                caption="Hasta"
                width="15%"
              />
              <Column
                dataType="number"
                dataField="descuento"
                caption="Descuento"
                width="15%"
              />
            </DataGrid>
          </CustomCol>
        </RowContainer>
      </RowContainer>
    </>
  )
}

export default Promociones

export const defaultPromociones: ItemPromotions = {
  descripcion: '',
  desde: '',
  hasta: '',
  descuento: 0,
  dataGrid: [],
}
