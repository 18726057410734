import { RequestHelper } from '../../../../../helpers/requestHelper'
import { SesionService } from '../../../../../services/sesion.service'
import { PagedResult, PaginationInfo } from '../../../../../store/types'
import { FiltroBusquedaLocalsState, LocalListado } from '../store/types'

export const LocalesService = {
  getAllLocales,
  getAllLocalesPaged,
  countLocales,
  getEmpresaLocales,
  deleteStore
}

async function getAllLocalesPaged(
  filtro: FiltroBusquedaLocalsState,
  pagination: PaginationInfo,
): Promise<PagedResult<LocalListado>> {
  const query = buildQuery(filtro, pagination)

  const pagedApiResult = await RequestHelper.getAll<any>('local', 'getAllBase', '', query);

  const usuarios = parseLocales(pagedApiResult.auto)

  return {
    count: pagedApiResult.totalRegistros,
    currentPage: pagedApiResult.paginaActual,
    from: pagedApiResult.desde,
    result: usuarios,
    to: pagedApiResult.hasta,
  }
}

async function getAllLocales(
  filtro: FiltroBusquedaLocalsState,
): Promise<Array<LocalListado>> {
  const query = buildQuery(filtro)

  const pagedApiResult = await RequestHelper.getAll<any>('local', 'getAllBase', '', query);
  return pagedApiResult.auto ? parseLocales(pagedApiResult.auto) : []
}

async function countLocales(): Promise<number> {
  const count = await RequestHelper.getAll<any>('generales', 'count/premises', '', null);
  return count.auto
}

async function getEmpresaLocales(
  filtro: FiltroBusquedaLocalsState,
): Promise<Array<LocalListado>> {
  const sesion = SesionService.getCurrentSesion()
  const query: any = {
  }  
  query.codigoempresa = sesion?.empresa?.codigo
  query.mensaje=filtro.nombre
  const pagedApiResult = await RequestHelper.getAll<any>(
    'local',
    'cboxLoad',
    '',
    query,
  )

  return pagedApiResult.auto ? parseLocales(pagedApiResult.auto) : []
}

async function deleteStore(id: any): Promise<any> {
  try {
    const objRequest = {
      codigo: id
    }
    const data = await RequestHelper.deleteRequest<any>(
      'inventario/multialmacen',
      'local/delete',
      '',
      objRequest
    )
    return data
  } catch (error) {
    return error
  }
}


function buildQuery(
  filtro: FiltroBusquedaLocalsState,
  pagination?: PaginationInfo,
): any {

  const query: any = {
    estado: filtro?.estado ? 1 : 0,
    nombre: filtro.nombre? filtro.nombre:'',
    codigo: filtro.codigo.codigo?filtro.codigo.codigo:0
  }

  if (pagination) { 
    query.page = pagination.current
    query.limit = pagination.size
    query.isPaged = true
  }

  const filters = ['nombre', 'codigo']

  filters.forEach((field) => {
    if (filtro[field] && filtro[field].length > 0) {
      query[field] = filtro[field]
    }
  })


  return query
}



function parseLocales(dataApi: any[]): Array<LocalListado> {
  return dataApi.map((i: any) => ({
    codigo: i.localCodigo,
    ciudad: i.localCiudad,
    ciudadNombre:  i.localCiudadNombre,
    nombre: i.localNombre,
    direccion:  i.localDireccion,
    telefono: i.localTelefono,
    gerente: i.localGerente,
    tipo: i.localTipo,
    tipoDescripcion: i.localTipoDescripcion,
    clase: i.localClase,
    claseDescripcion: i.localClaseDescripcion,
    pertenece: i.localPertenece,
    perteneceDescripcion: i.localPerteneceDescripcion,
    estado: i.localEstado,
    estadoDescripcion: i.localEstadoDescripcion,
    kardex: i.localKardex,
    siglas: i.localSiglas,
    siglasLocal: i.localSiglasLocal,
    web: i.localWEB,
    uaf: i.localUAF,
    descuento: i.localDescuento,
    contrato: i.localContrato,
    observaciones: i.localObservaciones,
    usuarioDokan: i.localUsuarioDokan,
    passwordDokan: i.localPasswordDokan,
    ulotipo: i.ULOTipo,
    establecimiento:i.localEstablecimiento
  }))
}

