import React, { useEffect } from 'react'
import { GlobalMenu } from '../../../../../views/componentes/globalMenu/globalMenu'
import {
  AccionMenu,
  CustomButtons,
  FETCH_STATUS,
  ToastTypes,
} from '../../../../../store/types'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../../../store/store'
import { ConceptiosMovEdicion, ConceptsMovDto, FiltrosConceptosMovState, Movimiento } from './types/types'
import { changeLoaderGroups, setCurrentFunction } from './store/generalReducer'
import { addToast } from '../../../../../store/toasterReducer'
import { Aplicacion } from '../../../store/types'
import { utilidades } from '../../../../../helpers/utilidades'
import {
  buttonClick,
  changeCurrentTab,
  closeTabConceptos,
  openTab,
  setButtons,
  setCurrentExecutingAction,
} from './store/tabsReducer'
import { ButtonTypes } from '../../../../../views/componentes/globalMenu/types'
import { v4 as uuidv4 } from 'uuid'
import { deleteEditData, initDatosEdicion } from './store/editDataReducer'
import { setCleanResult } from './store/serachReducer'
import Dialog from '../../../../../views/componentes/librerias/bootstrap-dialog'
import {
  useButtonDisabled,
  useMenuOptions,
} from '../../../../../hooks/useMenuButtonControls'
import { EAplicationsAcatha } from '../../../../../store/enumsAplication'
import ConceptosMovMain from './components/shared/main'
import { setMenuButtons } from './store/menuReducer'
import { setAplicacionModulo } from './store/configReducer'
import { defaultDataConcepts } from './components/nuevo'
import { ReportWithLocalDataModal } from '../../../../../views/componentes/xtrareports/ReportWithLocalDataModal'
import { consoleService } from '../../../../../services/console.service'

const pathVerificador = '/conceptos_movimientos'

interface ConceptopsMovProps extends React.PropsWithChildren { }

const ConceptosMovimientos: React.FunctionComponent<
  ConceptopsMovProps
> = () => {
  const dispatch = useDispatch()
  const menuState = useSelector((state: RootState) => state.global.menu)
  const tabs = useSelector((state: RootState) => {
    return state.inventarios.movimientos.conceptos.tabs
  })

  const searchStatus = useSelector((state: RootState) => {
    return state.inventarios.movimientos.conceptos.search.status
  })
  const searchResults = useSelector((state: RootState) => {
    return state.inventarios.movimientos.conceptos.search.resultados
  })
  const searchFilter = useSelector((state: RootState) => {
    return state.inventarios.movimientos.conceptos.search.filter
  })
  const selected = useSelector((state: RootState) => {
    return state.inventarios.movimientos.conceptos.search.seleccionado
  })

  const tabsMovimientos = useSelector((state: RootState) => { return state.inventarios.movimientos.conceptos.tabs });

  const [reporte, setReporte] = React.useState<null | "Viewer" | "Designer">(null);

  const dialogRef = React.useRef<any>(null)
  const getButtonDisabled = useButtonDisabled(tabs)
  const { getMenuOptions, options } = useMenuOptions()

  const getFilterString = React.useCallback((filter: FiltrosConceptosMovState) => {
    const filterText = ''
    return filterText
  }, [])

  const playLoader = React.useCallback(
    (message = 'Cargando...') => {
      dispatch(changeLoaderGroups({ show: true, mensaje: message }))
    },
    [dispatch],
  )

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoaderGroups({ show: false, mensaje: '' }))
  }, [dispatch])

  const onTabClosing = React.useCallback(
    async (index) => {
      const closedTab = tabs.tabs[index]
      dispatch(closeTabConceptos(closedTab.tabKey ?? index))
      await utilidades.sleep(50)
      dispatch(deleteEditData(closedTab.tabKey ?? index))
    },
    [dispatch, tabs.tabs],
  )

  const onTabChanged = React.useCallback(
    (tabKey: string) => {
      dispatch(changeCurrentTab(tabKey))
    },
    [dispatch],
  )

  const onMenuButtonClick = React.useCallback(
    async (action: AccionMenu, tab: string = "") => {
      const id = uuidv4()
      switch (action.actionType) {
        case ButtonTypes.find:
          if (tabs.current === 'BUSQUEDA') {
            dispatch(buttonClick({
              tabKey: tab === "" ? tabs.current : tab,
              button: action.actionType
            }))
          } else dispatch(changeCurrentTab('BUSQUEDA'))
          break
        case ButtonTypes.new:
          dispatch(
            initDatosEdicion({
              key: id,
              data: {
                ...defaultDataConcepts,
                loading: true
              },
            }),
          )
          dispatch(openTab({ key: id }))
          break
        case ButtonTypes.save:
          if (tabs.current !== 'BUSQUEDA') {
            dispatch(buttonClick({
              tabKey: tab === "" ? tabs.current : tab,
              button: action.actionType
            }))
          }
          break
        case ButtonTypes.edit:
          if (tabs.current === 'BUSQUEDA') {
            dispatch(buttonClick({
              tabKey: tab === "" ? tabs.current : tab,
              button: action.actionType
            }))
          }
          break
        case ButtonTypes.print:
          if (tabs.current === 'BUSQUEDA') {
            setReporte('Viewer')
          }
          break
        case ButtonTypes.print_design:
          if (tabs.current === 'BUSQUEDA') {
            setReporte('Designer')
          }
          break
        case ButtonTypes.broom:
          dispatch(buttonClick({
            tabKey: tab === "" ? tabs.current : tab,
            button: action.actionType
          }))
          break
        default:
          break
      }
    },
    [dispatch, tabs],
  )

  useEffect(() => {
    if (menuState.length > 0 && options.length === 0) {
      getMenuOptions(menuState, 'to', pathVerificador)
    }
  }, [menuState, getMenuOptions, options])

  React.useEffect(() => {
    if (searchStatus === FETCH_STATUS.FAILED) {
      stopLoader()
      dispatch(
        addToast({
          title: 'Buscar Conceptos Movimientos',
          content: 'Error al realizar la búsqueda',
          type: ToastTypes.Danger,
        }),
      )
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'BUSQUEDA',
          buttonType: undefined,
        }),
      )
    }
    if (searchStatus === FETCH_STATUS.LOADING) {
      playLoader('Buscando')
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'BUSQUEDA',
          buttonType: ButtonTypes.find,
        }),
      )
    }
    if (searchStatus === FETCH_STATUS.SUCCESS) {
      stopLoader()
      dispatch(
        setButtons({
          tabKey: 'BUSQUEDA',
          buttons: {
            Nuevo: true,
            Guardar: false,
            Editar: selected !== null && selected?.codigo > 0,
            Buscar: true,
            Imprimir: searchResults['auto'] && searchResults['auto'].length > 0,
            Limpiar: true
          },
        }),
      )
    }
  }, [dispatch, searchStatus, playLoader, searchResults, stopLoader, selected])

  const onGetCodeModule = React.useCallback(() => {
    dispatch(setAplicacionModulo(parseInt(EAplicationsAcatha.ConceptoMovimientos)))
  }, [dispatch])


  React.useEffect(() => {
    onGetCodeModule()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSearchExecutingAction = React.useCallback((tabKey: string, type?: ButtonTypes) => {
    dispatch(setCurrentExecutingAction({
      tabKey: tabKey,
      buttonType: type
    }))
  }, [dispatch]);

  const onActionsButtonsLoaded = React.useCallback((actions: Array<AccionMenu>) => {
    dispatch(setMenuButtons(actions));
  }, [dispatch]);

  const onParseData = React.useCallback((conceptos: Array<Movimiento>) => {
    let registros: Array<ConceptsMovDto> = [];
    let registro: ConceptsMovDto = null
    if (conceptos.length > 0) {
      registros = conceptos.map(item => {
        registro = {
          Code: item?.codigo ?? 0,
          Description: item?.descripcion ?? '',
          AsociatedTo: item?.asociadoDescripcion ?? '',
          AccountDEscripcion: item?.cuentaDescripcion ?? '',
        }
        return registro
      })
      // registros.push(registro)
    }

    consoleService.log(registros)
    return registros

  }, []);

  return (
    <>
      <ReportWithLocalDataModal
        show={reporte !== null}
        onClose={() => setReporte(null)}
        data={onParseData(searchResults['auto'] ?? [])}
        fileName='StaticConceptsMovement'
        mode={reporte ?? 'Viewer'}
        parameters={{ 'Reporte_Filtro': getFilterString(searchFilter) }}
        template='StaticConceptsMovement'
        key='reportDesigner'
      />
      <div>
        <Dialog ref={dialogRef} />
      </div>
      <ConceptosMovMain
        menu={{ loading: false, accions: options }}
        tabs={tabsMovimientos}
        onTabChanged={onTabChanged}
        codigoModulo={parseInt(EAplicationsAcatha.ConceptoMovimientos)}
        opciones={OpcionesModeulo}
        onActionsButtonsLoaded={onActionsButtonsLoaded}
        onMenuButtonClick={onMenuButtonClick}
        searching={searchStatus === FETCH_STATUS.LOADING}
        ontabClosing={onTabClosing}
        hayResultadosBusqueda={searchResults.length > 0}
        verificarPuntoVenta
        verificarAutorizacion
        onExecutingAction={onSearchExecutingAction}
        tipoAplicacion={Aplicacion.conceptosMovimientos}
      />
    </>
  )
}

export default React.memo(ConceptosMovimientos)
const OpcionesModeulo = [parseInt(EAplicationsAcatha.ConceptoMovimientos)];

export const ModuleButtons: CustomButtons = {
  Nuevo: true,
  Guardar: true,
  Editar: false,
  Buscar: false,
  Imprimir: false,
  Limpiar: true,
}


