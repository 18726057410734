import { CCard, CCardBody, CCardHeader } from '@coreui/react-pro'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import RowContainer from '../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../views/componentes/colContainer'
import { GlobalMenu } from '../../../views/componentes/globalMenu/globalMenu'
import LoadPanel from 'devextreme-react/load-panel';
import config from '../../../config/config'
import { AccionMenu, ToastTypes } from '../../../store/types'
import { ButtonTypes } from '../../../views/componentes/globalMenu/types'
import { addToast } from '../../../store/toasterReducer'
import { RootState } from '../../../store/store'
import Buscar from './components/buscar'
import { changeLoader } from '../../../store/reducers'
import Nuevo from './components/nuevo'
import ReactDom from 'react-dom'
import Modalform from '../../../views/componentes/modalform'

interface IAdminModalLocalesProps extends React.PropsWithChildren {
  onChanged: (dataLocal: any) => void
  onCancel: () => void
  show: boolean
  modulo: number
}

export const AdminModalLocales: React.FC<IAdminModalLocalesProps> = (
  props
) => {
  const dispatch = useDispatch()
  const { show, onCancel, onChanged, modulo } = props
  const loader = useSelector((state: RootState) => state.global.loader);

  const botones = config['menuDefecto'].map(acc => {
    const boton: AccionMenu = {
      nombre: acc.id,
      icon: acc.icon,//as any,
      actionType: acc.type,
      main: false,
      modal: false,
      shortcut: null
    }
    return boton;
  });

  const [acciones,] = React.useState<Array<AccionMenu>>(botones);
  const [currentTab, setCurrentTab] = React.useState<any>("Buscar");
  const [funcion, setFuncion] = React.useState<string>("");

  const setToast = React.useCallback((texto: string, type: ToastTypes) => {
    dispatch(addToast({
      id: '',
      autoHide: 3500,
      title: 'Acatha',
      content: texto,
      fade: true,
      type: type
    }));
  }, [dispatch]);

  const playLoader = React.useCallback(async () => {
    dispatch(changeLoader({ show: true, mensaje: 'Cargando...' }))
  }, [dispatch]);

  const stopLoader = React.useCallback(async () => {
    dispatch(changeLoader({ show: false, mensaje: '' }))
  }, [dispatch]);

  const returnAction = (boton: AccionMenu) => {

    console.log("returnAction", boton.actionType, "currentTab", currentTab)
    switch (boton.actionType) {
      case ButtonTypes.find:
        if (currentTab == "Buscar") {
          setFuncion(ButtonTypes.find)
          return false;
        } else if (currentTab === "Editar") {
          setCurrentTab("Buscar")
        } else {
          setCurrentTab("Buscar")
          setFuncion(ButtonTypes.undo)
        }
        break;
      case ButtonTypes.new:
        setCurrentTab("Nuevo")
        setFuncion("")
        break;
      case ButtonTypes.save:
        if (currentTab == "Nuevo") {
          setFuncion(ButtonTypes.save)
          return false;
        } else if (currentTab == "Editar") {
          setFuncion(ButtonTypes.save)
          return false;
        } else {
          setToast('error', 2)
        }
        break;
      case ButtonTypes.undo:
        if (currentTab == "Nuevo") {
          setFuncion(ButtonTypes.undo)
          return false;
        } else if (currentTab == "Editar") {
          setFuncion(ButtonTypes.undo)
          return false;
        }
        break;
      default:
        break;
    }
  }

  const getButtonDisabled = React.useCallback((button: AccionMenu) => {
    switch (button.actionType) {
      case ButtonTypes.save:
        return currentTab === "Buscar"
      case ButtonTypes.delete:
        return (currentTab === "Nuevo" || currentTab === "Editar")
      case ButtonTypes.broom:
      case ButtonTypes.close:
        return false;
      case ButtonTypes.new:
      case ButtonTypes.edit:
        return currentTab === "Imprimir"
      default:
        return false;
    }
  }, [currentTab]);

  React.useEffect(() => {
    setCurrentTab("Buscar")
    setFuncion(ButtonTypes.find)
  }, []);

  const botonesModal = () => {
    return (
      <GlobalMenu
        acciones={acciones}
        onClick={returnAction}
        getButtonDisabled={getButtonDisabled}
      />
    );
  }

  const bodyModal = () => {
    return (
      <RowContainer>
        <CustomCol xs='12' md='12'>
          {currentTab === "Nuevo" && (
            <Nuevo
              funcion={funcion}
              setCurrenTab={() => setCurrentTab('Nuevo')}
              currentTab={currentTab}
              resetFunction={() => setFuncion('')}
              onChanged={onChanged}
              setToast={setToast}
              playLoader={playLoader}
              stopLoader={stopLoader}
              modulo={modulo}
            />
          )}
          {currentTab === "Editar" && (
            <Nuevo
              funcion={funcion}
              currentTab={currentTab}
              setCurrenTab={() => setCurrentTab('Editar')}
              resetFunction={() => setFuncion('')}
              onChanged={onChanged}
              setToast={setToast}
              playLoader={playLoader}
              stopLoader={stopLoader}
              modulo={modulo}
            />
          )}
          {currentTab === "Buscar" && (
            <Buscar
              funcion={funcion}
              resetFunction={() => setFuncion('')}
              onChanged={onChanged}
              ejecutarBusqueda={() => setFuncion("Burcar")}
              setToast={setToast}
              playLoader={playLoader}
              stopLoader={stopLoader}
            />
          )}
        </CustomCol>
      </RowContainer>
    );
  }

  const renderContent = () => {
    return (
      <CCard>
        <CCardHeader>
          {botonesModal()}
        </CCardHeader>
        <CCardBody>
          <LoadPanel
            shadingColor="rgba(0,0,0,0.4)"
            position={'center'}
            visible={loader.show}
            showIndicator={true}
            shading={true}
            showPane={true}
            message={loader.mensaje}
            closeOnOutsideClick={false}
          />
          {bodyModal()}
        </CCardBody>
      </CCard>
    )
  }

  return (
    <>
      {
        ReactDom.createPortal(<Modalform
          name={'popupLocales'}
          key={'popupLocales'}
          headerTitle={"Local"}
          childrenBody={renderContent()}
          closeOnBackdrop={false}
          show={show}
          onClose={() => onCancel()}
          centered={true}
          size='lg'
        />, document.body)
      }
    </>
  )
}

