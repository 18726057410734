import React, { useState, useEffect } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow, CTooltip
} from '@coreui/react-pro'
import Modalform from "../../../../views/componentes/modalform"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import config from '../../../../config/config';
import { BotonMenu } from '../../../contabilidad/pages/planCuentas/store/types';
import { menuService } from '../../../../services/menu.service';
import { addToast } from '../../../../store/toasterReducer';
import { ToastTypes } from '../../../../store/types';
import { useDispatch, useSelector } from 'react-redux'
import { changeLoader } from '../../../../store/reducers';
import { RootState } from '../../../../store/store'
import Buscar from "./components/buscar"

type ModalUsuariosProps = {
  show: boolean,
  onClose: () => void,
  cargarCuentas: (evt) => void,
  modulo: number
}

const ModalUsuarios = (props: ModalUsuariosProps) => {
  const { onClose, show, cargarCuentas, modulo } = props;
  const dispatch = useDispatch()

  const currentAction = useSelector((state: RootState) => state.nomina.modalUsuarios.currentAction)

  const [acciones, setAcciones] = useState<any>([]);


  const botones = config['menuDefectoSearch'].map(acc => {
    const boton: BotonMenu = {
      id: acc.text as any,
      icon: menuService.getIcon(acc.icon),
      text: acc.text
    }
    return boton;
  });






  const setToast = React.useCallback((texto, type) => {
    let time: number = 3000;
    if (texto.length < 50) {
      time = 5000;
    } else if (texto.length > 50) {
      time = 9000;
    }
    let tipo: ToastTypes = ToastTypes.Info;
    if (type === 'warning') {
      tipo = ToastTypes.Warning;
    } else if (type === 'success') {
      tipo = ToastTypes.Success;
    } if (type === 'info') {
      tipo = ToastTypes.Info;
    } if (type === 'danger') {
      tipo = ToastTypes.Danger;
    }
    dispatch(addToast({
      id: '',
      autoHide: time,
      title: 'Acatha',
      content: texto,
      fade: true,
      type: tipo
    }));
  }, [dispatch]);


  const playLoader = React.useCallback(async () => {
    dispatch(changeLoader({ show: true, mensaje: 'Cargando...' }))
  }, [dispatch]);

  const stopLoader = React.useCallback(async () => {
    dispatch(changeLoader({ show: false, mensaje: '' }))
  }, [dispatch]);

  const handleSubmit = async () => {
    /*
            dispatch(setData([]));
            playLoader()
            let buscar = {
                nombre: busqueda_ti
            };
            try {
                const data = await bancosService.obtenerBancos(buscar);
                stopLoader()
                if (data['error'] === false) {
                    if (data['auto'].length > 0) {
                        setToast("(" + data['auto'].length + ")" + " Registros encontrados.", "success");
                        setCollapsed(false)
                        let itemBanco: Array<Banco>;
                        itemBanco = data['auto'].map(function (item: Banco) {
                            return {
                                codigo: Number(item["BAN_CODIGO"]),
                                nombre: String(item["BAN_NOMBRE"]),
                                ciudad: Number(item["BAN_CIUDAD"]),
                                direccion: String(item["BAN_DIRECCION"]),
                                telefono: String(item["BAN_TELEFONO"]),
                                tipo: Number(item["BAN_TIPO"]),
                                tipo_nombre: String(item["BAN_TIPO_NOMBRE"]),
                                ruc: String(item["BAN_RUC"]),
                                especial: String(item["BAN_ESPECIAL"]),
                                estado: Number(item["BAN_ESTADO"]),
                            };
                        });
                        dispatch(setData(itemBanco));
                    } else {

                        setToast("(" + data['auto'].length + ")" + " Registros encontrados.", "success");

                    }
                } else {
                    setToast(data.message, "warning");
                }
                return await data;
            } catch (error) {
                console.log('error', error);
                stopLoader()
                setToast(JSON.stringify(error), "danger")
            }
    */
  }

  const returnAction = React.useCallback((metodo) => {

    switch (metodo) {
      case "Salir":
        onClose();
        break;
      default:
        break;
    }

  }, [onClose]);



  const pasarCuentaSeleccion = (cuenta) => {
    if (cuenta['row']['codigo'] !== "") {
      cargarCuentas(cuenta['row']);
      onClose()
    }
  }

  const pasarCuenta = (cuenta) => {
    console.log("pasarCuenta", cuenta)
    if (cuenta['codigo'] !== "") {
      cargarCuentas(cuenta);
      onClose()
    } else {
      setToast("No se encuentra datos de la cuenta seleccionada.", "warning");
    }
  }



  const bodyModalForm = () => {
    if (currentAction === "Buscar") {
      return (
        <>
          <Buscar
            cargarCuentas={(evt) => pasarCuenta(evt)}
            setToast={setToast}
            playLoader={playLoader}
            stopLoader={stopLoader}
            codModulo={modulo}
          />
        </>
      )
    } else {
      return <></>
    }
  }

  React.useEffect(() => {
    if (show === true) {
      setAcciones(botones)
    }
  }, [show]);

  const renderBody = () => {

    return (
      <CCard key="cardUsuarios" >
        <CCardHeader >
          <CRow>
            <CCol className="ms-0 d-flex align-items-start justify-content-start" >
              <div key='menuItems' className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups" style={{ marginBottom: '5px', paddingTop: '-2rem' }}>
                <div key='menuItemsBotones' className="btn-group-lg btn-group btn-sm me-2" role="group" aria-label="First group" >
                  {acciones.map(function (x, i) {
                    const prefixButton = "buttonsCuentas_";
                    const nameButon = x.text;
                    const disabled = false;
                    return (
                      <CTooltip
                        key={"tool-" + prefixButton + nameButon}
                        placement="top"
                        content={nameButon}
                      >
                        <CButton
                          key={prefixButton + nameButon}
                          name={prefixButton + nameButon}
                          title={x.text}
                          id={prefixButton + nameButon}
                          value={x.text}
                          className="btn btn-secondary sm"
                          variant="ghost"
                          onClick={(ev) => returnAction(ev = x.text)}
                          size='sm'
                          disabled={disabled}
                        >
                          <FontAwesomeIcon size='sm' icon={x.icon ?? faQuestionCircle} />
                        </CButton>
                      </CTooltip>
                    );
                  })}
                </div>
              </div>
            </CCol>
          </CRow>
        </CCardHeader >

        <CCardBody >
          {bodyModalForm()}
        </CCardBody >
      </CCard>
    )
  }

  const renderFooter = () => {
    return <></>
  }

  return (
    <CRow key="rowMain">
      <CCol key="colMain" >
        <Modalform
          name='modalListarUsuarios'
          headerTitle={"Usuarios"}
          childrenBody={renderBody()}
          childrenFooter={renderFooter()}
          closeOnBackdrop={false}
          show={show}
          onClose={() => onClose()}
          centered={true}
          size="lg"
        />
      </CCol>
    </CRow>
  )
}

export default ModalUsuarios;