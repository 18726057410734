import React, { useCallback } from 'react'
import { AnexoInfo, TabStateAnexoTransaccional } from '../../../types/types'
import { useDispatch, useSelector } from 'react-redux'
import ValidationGroup from 'devextreme-react/validation-group'
import {
  clearButtonClick,
  closeTab,
  setButtons,
  setCurrentExecutingAction,
} from '../../../store/tabsReduxcers'
import {
  clearDatosEdicion,
  setDatosEdicion,
} from '../../../store/editDataReducer'
import {
  CCard,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
} from '@coreui/react-pro'
import TablaVentas from './tablasTransacciones/tablaVentas'
import { anexoTransaccionalSVService } from '../../../../../services/anexoTransaccionalSV.services'
import { RootState } from '../../../../../../../../../../store/store'
import { addToast } from '../../../../../../../../../../store/toasterReducer'
import {
  CustomButtons,
  ETiposEstablecimientosMH,
  TEstado,
  TiposComprobantesSLV,
  ToastTypes,
} from '../../../../../../../../../../store/types'
import Dialog from '../../../../../../../../../../views/componentes/librerias/bootstrap-dialog'
import { ButtonTypes } from '../../../../../../../../../../views/componentes/globalMenu/types'
import { StatesEdition } from '../../../../../../../../types/enums'
import { LoadingIndicator } from 'devextreme-react/bar-gauge'
import BlockUi from '../../../../../../../../../../views/componentes/librerias/block-ui/BlockUi'
import RowContainer from '../../../../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../../../../views/componentes/colContainer'
import { dteSalvador } from '../../../../../data/dte'
import TablaCCF from './tablasTransacciones/tablaCCF'
import { VentaHelperSV } from '../../../../../../../ventas/helpers/ventaHelperSV'
import { VentasService } from '../../../../../../../../services/ventas.service'
import {
  DateUtils,
  formatoFechasApi,
} from '../../../../../../../../../../helpers/dateUtils'
import { utilidades } from '../../../../../../../../../../helpers/utilidades'
import TablaSuj from './tablasTransacciones/tablaSuj'
import TablaRetenciones from './tablasTransacciones/tablaRetenciones'
import { tipoIdentificacionService } from '../../../../../../../../../componentes/tipoIdentificacion/service/tipoId.service'
import {
  TipoIdentificacion,
  TipoTransaccion,
} from '../../../../../../../../../componentes/tipoIdentificacion/store/types'
import { ExportarExcelService } from '../../../../../services/exportarExcel.service'
import { CsvWriteOptions, Workbook } from 'exceljs'
import { saveAs } from 'file-saver'
import { exportDataGrid } from 'devextreme/excel_exporter'
import TablaNotasCredito from './tablasTransacciones/tablaNotasCredito'
import TablaAnulados from './tablasTransacciones/tablaAnulados'
import { consoleService } from '../../../../../../../../../../services/console.service'
import TipoExportacion from '../../../../../../../../../admin/catalogos/paises/enum/TipoExportacion'
import { selectFiltroBusquedaAnexos } from '../../../store/searchReducer'
import TablaCompras from './tablasTransacciones/tablaCompras'
import { tipoOperacionesService } from '../../../../../../../../../componentes/tipoOperacionesLookUp/services/tipoOperaciones.service'
import { tipoClasificacionesSujExc } from '../../../../../../../../../componentes/clasificacionSujExcLookUp/services/tipoClasificacionesSujExc.service'
import { tipoCostosSujExc } from '../../../../../../../../../componentes/tipoCostosLookUp/services/tipoCostosSujExc.service'
import { tipoSectorService } from '../../../../../../../../../componentes/tipoSectorLookUp/services/tipoSector.service'

interface ICAnexoProps extends React.PropsWithChildren {
  info: any
  tabId: string
  tab: TabStateAnexoTransaccional<AnexoInfo>
}

export type TabConfigAnexo = {
  visible: boolean
  title: string
  code: TiposComprobantesSLV | string
}

export type ConfigDocCSV = {
  title: string
  format: string
  component: any
  filename: string
}

const Anexo: React.FunctionComponent<ICAnexoProps> = (props) => {
  const { tabId, tab } = props
  const validationGroupRef = React.useRef<any>()
  const dialogRef = React.useRef<any>(null)

  const dispatch = useDispatch()
  const filtroBusqueda = useSelector(selectFiltroBusquedaAnexos)

  const empresa = useSelector((state: RootState) => {
    return state.global.session?.empresa
  })
  const localSession = useSelector((state: RootState) => {
    return state.global.session?.local
  })
  const loader = useSelector((state: RootState) => {
    return state.impuestosSV.anexoTransaccional.editData[tabId].loader
  })
  const loading = useSelector((state: RootState) => {
    return state.impuestosSV.anexoTransaccional.editData[tabId].loading
  })
  const datosEdicion = useSelector((state: RootState) => {
    return state.impuestosSV.anexoTransaccional.editData[tabId]
  })
  const ventas = useSelector((state: RootState) => {
    return state.impuestosSV.anexoTransaccional.editData[tabId].consumidorFinal
  })
  const creditoFiscal = useSelector((state: RootState) => {
    return state.impuestosSV.anexoTransaccional.editData[tabId].creditoFiscal
  })
  const exportacion = useSelector((state: RootState) => {
    return state.impuestosSV.anexoTransaccional.editData[tabId].exportacion
  })
  const sujetoExcluido = useSelector((state: RootState) => {
    return state.impuestosSV.anexoTransaccional.editData[tabId].sujetoExcluido
  })
  const notasCredito = useSelector((state: RootState) => {
    return state.impuestosSV.anexoTransaccional.editData[tabId].notasCredito
  })
  const notasDebito = useSelector((state: RootState) => {
    return state.impuestosSV.anexoTransaccional.editData[tabId].notasDebito
  })
  const notasRemision = useSelector((state: RootState) => {
    return state.impuestosSV.anexoTransaccional.editData[tabId].notasRemision
  })
  const liquidaciones = useSelector((state: RootState) => {
    return state.impuestosSV.anexoTransaccional.editData[tabId].liquidaciones
  })
  const liquidacionesContables = useSelector((state: RootState) => {
    return state.impuestosSV.anexoTransaccional.editData[tabId]
      .liquidacionesContables
  })
  const donaciones = useSelector((state: RootState) => {
    return state.impuestosSV.anexoTransaccional.editData[tabId].donaciones
  })
  const retenciones = useSelector((state: RootState) => {
    return state.impuestosSV.anexoTransaccional.editData[tabId].retenciones
  })
  const anulados = useSelector((state: RootState) => {
    return state.impuestosSV.anexoTransaccional.editData[tabId].anulados
  })
  const compras = useSelector((state: RootState) => {
    return state.impuestosSV.anexoTransaccional.editData[tabId].compras
  })

  const [activeKey, setActiveKey] = React.useState<TiposComprobantesSLV | null>(
    null,
  )
  const [ventasCCF, setVentasCCF] = React.useState<Array<any> | []>([])
  const [ventasAnexos, setVentasAnexos] = React.useState<Array<any> | []>([])
  const [ventasNotasCredito, setVentasNotasCredito] = React.useState<
    Array<any> | []
  >([])
  const [ventasSujetoExcluido, setVentasSujetoExcluido] = React.useState<
    Array<any> | []
  >([])
  const [ventasNotasDebito, setVentasNotasDebito] = React.useState<
    Array<any> | []
  >([])
  const [ventasExportacion, setVentasExportacion] = React.useState<
    Array<any> | []
  >([])
  const [ventasRetenciones, setVentasRetenciones] = React.useState<
    Array<any> | []
  >([])
  const [configTabs, setConfigTabs] = React.useState<
    Array<TabConfigAnexo> | []
  >([])
  const [comprasAnexo, setComprasAnexo] = React.useState<Array<any> | []>([])

  const agruparPorFechaNegociacion = useCallback((datos) => {
    const agrupados = {}

    datos.forEach((dato) => {
      if (dato?.fechaNegociacion) {
        if (!agrupados[dato.fechaNegociacion]) {
          agrupados[dato.fechaNegociacion] = []
        }
        agrupados[dato.fechaNegociacion].push(dato)
      }
    })
    try {
      // Crear el array resultante con los datos agrupados
      const resultado = Object.keys(agrupados).map((fecha) => {
        const grupo = agrupados[fecha]

        // Ordenar el grupo por 'numDocumentoDel' (asumiendo que es una cadena que puede ser ordenada lexicográficamente)
        grupo.sort((a, b) => a.numDocumentoDel.localeCompare(b.numDocumentoDel))

        const subtotal0 = grupo.reduce((t, item) => {
          t += Number(item?.subtotal0) ?? 0
          return t
        }, 0)
        const ventasProporcionalidad = grupo.reduce((t, item) => {
          t += Number(item?.ventasProporcionalidad) ?? 0
          return t
        }, 0)
        const ventasNoSujetas = grupo.reduce((t, item) => {
          t += Number(item?.ventasNoSujetas) ?? 0
          return t
        }, 0)
        const subtotalIva = grupo.reduce((t, item) => {
          t += Number(item?.subtotalIva) ?? 0
          return t
        }, 0)
        const expoDentroCentroAmerica = grupo.reduce((t, item) => {
          t += Number(item?.expoDentroCentroAmerica) ?? 0
          return t
        }, 0)
        const expoAfueraCentroAmerica = grupo.reduce((t, item) => {
          t += Number(item?.expoAfueraCentroAmerica) ?? 0
          return t
        }, 0)
        const exportacionesServicios = grupo.reduce((t, item) => {
          t += Number(item?.exportacionesServicios) ?? 0
          return t
        }, 0)
        const ventasZonasFrancasDPA = grupo.reduce((t, item) => {
          t += Number(item?.ventasZonasFrancasDPA) ?? 0
          return t
        }, 0)
        const ventasZonasFrancas = grupo.reduce((t, item) => {
          t += Number(item?.ventasZonasFrancas) ?? 0
          return t
        }, 0)
        const total = grupo.reduce((t, item) => {
          t += Number(item?.total) ?? 0
          return t
        }, 0)

        const itemGrupo = {
          fechaNegociacion: fecha,
          codigo: grupo[0].codigo,
          claseDocumento: grupo[0].claseDocumento,
          numeroResolucion: grupo[0].numeroResolucion,
          serieDocumento: grupo[0].serieDocumento,
          tipoDocumentoCodigo: grupo[0].tipoDocumentoCodigo,
          numeroAnexo: grupo[0].numeroAnexo,
          numDocumentoDel: grupo[0].numDocumentoDel,
          numDocumentoAl: grupo[grupo.length - 1].numDocumentoAl,
          numControlInternoDel: grupo[0].numControlInternoDel,
          numControlInternoAL: grupo[grupo.length - 1].numControlInternoAL,
          subtotal0: formatNumber(subtotal0),
          ventasProporcionalidad: formatNumber(ventasProporcionalidad),
          ventasNoSujetas: formatNumber(ventasNoSujetas),
          subtotalIva: formatNumber(subtotalIva),
          expoDentroCentroAmerica: formatNumber(expoDentroCentroAmerica),
          expoAfueraCentroAmerica: formatNumber(expoAfueraCentroAmerica),
          exportacionesServicios: formatNumber(exportacionesServicios),
          ventasZonasFrancasDPA: formatNumber(ventasZonasFrancasDPA),
          ventasZonasFrancas: formatNumber(ventasZonasFrancas),
          total: formatNumber(total),
        }

        return itemGrupo
      })
      return resultado
    } catch (error) {
      console.error(error)
      return []
    }
  }, [])

  const parserNoContribuyente = useCallback(
    (data) => {
      const d = data.map((venta, i) => {
        if (venta.estadoElectronico === 'AUTORIZADO') {
          const descuentoGlobal = venta.descuentoGlobal ?? 0
          const itemsSinImpuesto = venta.itemsSinImpuesto ?? 0
          const itemsConImpuesto = venta.itemsConImpuesto ?? 0
          const totalProductos = itemsSinImpuesto + itemsConImpuesto
          const descPorItem = descuentoGlobal / totalProductos
          const totalDescuExento = itemsSinImpuesto * descPorItem
          const totalExenta = utilidades.formatDecimal(
            Number(venta?.totalExenta ?? 0) - totalDescuExento,
            2,
          )

          const numeroControl =
            'DTE-' +
            VentaHelperSV.generarCodigoControl(
              venta.tipoDocumentoCodigo,
              venta.establecimiento,
              venta.puntoEmision,
              Number(venta.numero),
              empresa?.comercial,
              localSession?.tipo,
            ).content

          const subtotalIva = Number(venta?.subtotalIva) + Number(venta?.iva)
          const date = venta?.fechaNegociacion.toString()
          const codigoGen = venta?.autorizacionHacienda?.codigoGeneracion ?? ''

          let ventasNoSujetas = 0
          if (venta?.totalNoSujeto) {
            ventasNoSujetas = Number(venta?.totalNoSujeto ?? 0)
          }
          return {
            ...venta,
            codigo: i,
            fechaNegociacion: date,
            numeroResolucion: numeroControl,
            serieDocumento: 'N/A',
            numDocumentoDel: codigoGen,
            numDocumentoAl: codigoGen,
            numControlInternoDel: 'N/A',
            numControlInternoAL: 'N/A',
            ventasNoSujetas: ventasNoSujetas,
            ventasProporcionalidad: 0,
            numeroAnexo: '2',
            numeroMaquinaRegistradora: '',
            expoDentroCentroAmerica: 0,
            expoAfueraCentroAmerica: 0,
            exportacionesServicios: 0,
            ventasZonasFrancas: 0,
            ventasZonasFrancasDPA: 0,
            subtotal0: totalExenta,
            subtotalIva: subtotalIva,
            claseDocumento: 4,
            claseDocumentoDescripcion:
              '4. DOCUMENTO TRIBUTARIO ELECTRÓNICO (DTE)',
            autorizacionHacienda: {
              ...venta.autorizacionHacienda,
              codigoGeneracion:
                venta?.autorizacionHacienda?.codigoGeneracion?.replaceAll(
                  '-',
                  '',
                ) ?? '',
            },
          }
        }
      })
      const agrupados = agruparPorFechaNegociacion(d)
      return agrupados
    },
    [localSession, empresa, agruparPorFechaNegociacion],
  )

  const parserExportacion = useCallback(
    (data) => {
      // clasificacion region

      //   EXPORTACIONES_DENTRO_CENTROAMERICA = 'EDCA',
      //   EXPORTACIONES_FUERA_CENTROAMERICA = 'EFCA',
      //   EXPORTACIONES_SERVICIO = 'ES',
      //   VENTAS_ZONAS_FRANCAS = 'EZF',
      //   VENTAS_CUENTA_TERCEROS = 'CND',

      return data.map((venta) => {
        let expoDentroCentroAmerica = 0
        let expoAfueraCentroAmerica = 0
        let exportacionesServicios = 0
        let ventasZonasFrancas = 0
        let ventasZonasFrancasDPA = 0
        if (venta.estadoElectronico === 'AUTORIZADO') {
          switch (venta.paisCodigoRegion) {
            case TipoExportacion.EXPORTACIONES_DENTRO_CENTROAMERICA:
              expoDentroCentroAmerica = expoDentroCentroAmerica + venta?.total
              break
            case TipoExportacion.EXPORTACIONES_FUERA_CENTROAMERICA:
              expoAfueraCentroAmerica = expoAfueraCentroAmerica + venta?.total
              break
            case TipoExportacion.EXPORTACIONES_SERVICIO:
              exportacionesServicios = exportacionesServicios + venta?.total
              break
            case TipoExportacion.VENTAS_ZONAS_FRANCAS:
              ventasZonasFrancas = ventasZonasFrancas + venta?.total
              break
            case TipoExportacion.VENTAS_CUENTA_TERCEROS:
              ventasZonasFrancasDPA = ventasZonasFrancasDPA + venta?.total
              break
          }
          const descuentoGlobal = venta.descuentoGlobal ?? 0
          const totalGravada = utilidades.formatDecimal(
            venta.totalGravada - descuentoGlobal,
            2,
          )
          const codigoGen =
            venta?.autorizacionHacienda?.codigoGeneracion?.replaceAll(
              '-',
              '',
            ) ?? ''

          return {
            ...venta,
            codigo: venta?.comprobante,
            numeroControl:
              'DTE-' +
              VentaHelperSV.generarCodigoControl(
                venta.tipoDocumentoCodigo,
                venta.establecimiento,
                venta.puntoEmision,
                Number(venta.numero),
                empresa?.comercial,
                localSession?.tipo,
              ).content,
            subtotalIva: totalGravada,
            numeroAnexo: '2',
            subtotal0: 0,
            numControldocumentoDel: codigoGen,
            numDocumentoDel: codigoGen,
            numControldocumentoAL: '0',
            ventasNoSujetas: 0,
            numeroMaquinaRegistradora: '0',
            ventasProporcionalidad: 0,
            expoDentroCentroAmerica: expoDentroCentroAmerica,
            expoAfueraCentroAmerica: expoAfueraCentroAmerica,
            ventasZonasFrancas: ventasZonasFrancas,
            exportacionesServicios: exportacionesServicios,
            ventasZonasFrancasDPA: ventasZonasFrancasDPA,
            claseDocumento: '4. DOCUMENTO TRIBUTARIO ELECTRÓNICO (DTE)',
            autorizacionHacienda: {
              ...venta.autorizacionHacienda,
              codigoGeneracion:
                venta?.autorizacionHacienda?.codigoGeneracion?.replaceAll(
                  '-',
                  '',
                ) ?? '',
            },
          }
        }
      })
    },
    [empresa, localSession],
  )

  const parserCompras = useCallback(
    async (data) => {
      console.log('parserCompras', data)
      const tiposIdApi = await tipoIdentificacionService.getTipoIdetificacion(
        TipoTransaccion.compra,
        empresa.codigoPais,
      )
      const tipoOperacionesApi =
        await tipoOperacionesService.getTipoOperaciones(
          'Elija una opción',
          empresa?.codigoPais,
        )
      let tipoOperaciones: Array<TEstado> = []
      if (tipoOperacionesApi?.auto && tipoOperacionesApi?.auto.length > 0) {
        tipoOperaciones = tipoOperacionesApi?.auto
      }

      const tipoClasificacionesApi =
        await tipoClasificacionesSujExc.getTipoClasificacionesSujExc(
          'Elija una opción',
          empresa?.codigoPais,
        )
      let tipoClasificacion: Array<TEstado> = []
      if (
        tipoClasificacionesApi?.auto &&
        tipoClasificacionesApi?.auto.length > 0
      ) {
        tipoClasificacion = tipoClasificacionesApi?.auto
      }

      const tipoCostosApi = await tipoCostosSujExc.getTipoCostosSujExc(
        'Elija una opción',
        empresa?.codigoPais,
      )
      let tipoCostos: Array<TEstado> = []
      if (tipoCostosApi?.auto && tipoCostosApi?.auto.length > 0) {
        tipoCostos = tipoCostosApi?.auto
      }

      const tipoSectorApi = await tipoSectorService.getTipoSector(
        'Elija una opción',
        empresa?.codigoPais,
      )
      let tipoSector: Array<TEstado> = []
      if (tipoSectorApi?.auto && tipoSectorApi?.auto.length > 0) {
        tipoSector = tipoSectorApi?.auto
      }

      const d = data.map((compra, i) => {
        if (
          compra?.tipoDocumento === '03' ||
          compra?.tipoDocumento === '05' ||
          compra?.tipoDocumento === '06' ||
          compra?.tipoDocumento === '11' ||
          compra?.tipoDocumento === '12' ||
          compra?.tipoDocumento === '13'
        ) {
          let tipoId: TipoIdentificacion | null = null

          if (tiposIdApi.auto && tiposIdApi.auto.length > 0) {
            const selectedIndex = tiposIdApi.auto.findIndex(
              (select: TipoIdentificacion) => {
                return select.codigo == compra?.proveedorTipoIdentificacion
              },
            )
            if (selectedIndex > -1) {
              tipoId = tiposIdApi.auto[selectedIndex]
            }
          }

          let codigoGen = compra?.identificador?.replaceAll('-', '') ?? ''

          if (codigoGen === '') {
            codigoGen =
              compra?.establecimiento +
              '-' +
              compra?.puntoEmision +
              '-' +
              compra?.numero
            codigoGen = codigoGen.replaceAll('-', '')
          }

          let comprasInternasExentasNoSujetas = 0
          comprasInternasExentasNoSujetas =
            compra?.subtotal0 + compra?.subtotalNoIVA

          const internacionesExentasNoSujetas = 0
          const importacionesExentasNoSujetas = 0
          const comprasInternasGravadas = compra?.subtotal12
          const internacionesGravadasBienes = 0
          const importacionesGravadasBienes = 0
          const importacionesGravadasServicios = 0
          const creditoFiscal =
            comprasInternasExentasNoSujetas + importacionesGravadasServicios
          const totalCompras = compra?.total ?? 0

          let tipoOperacion: TEstado = null
          let clasificacion: TEstado = null
          let sector: TEstado = null
          let tipoCosto: TEstado = null

          if (compra?.clasificacion && tipoClasificacion.length > 0) {
            clasificacion = tipoClasificacion.find((select: TEstado) => {
              return String(select.codigo) == String(compra?.clasificacion)
            })
          }

          if (compra?.operacion && tipoOperaciones.length > 0) {
            tipoOperacion = tipoOperaciones.find((select: TEstado) => {
              return String(select.codigo) == String(compra?.operacion)
            })
          }

          if (compra?.sector && tipoSector.length > 0) {
            sector = tipoSector.find((select: TEstado) => {
              return String(select.codigo) == String(compra?.sector)
            })
          }

          if (compra?.sector && tipoCostos.length > 0) {
            tipoCosto = tipoCostos.find((select: TEstado) => {
              return String(select.codigo) == String(compra?.tipoCosto)
            })
          }

          return {
            ...compra,
            comprobante: i,
            numeroControl:
              'DTE-' +
              VentaHelperSV.generarCodigoControl(
                compra?.tipoDocumento,
                compra?.establecimiento,
                compra?.puntoEmision,
                Number(compra?.numero),
                empresa?.comercial,
                localSession?.tipo,
              ).content,
            fechaNegociacion: compra?.fecha,
            tipoDocumentoCodigo: compra?.tipoDocumento,
            claseDocumentoCodigo: compra?.tipoDocumento,
            numeroDocumento: codigoGen,
            proveedorIdentificacion: compra?.proveedorIdentificacion,
            proveedorDui:
              compra?.proveedorIdentificacion === ''
                ? compra?.proveedorDui.replaceAll('-', '')
                : '',
            proveedorNombre: compra?.proveedorNombre,
            comprasInternasExentasNoSujetas: Number(
              comprasInternasExentasNoSujetas.toFixed(2),
            ),
            internacionesExentasNoSujetas: Number(
              internacionesExentasNoSujetas.toFixed(2),
            ),
            importacionesExentasNoSujetas: Number(
              importacionesExentasNoSujetas.toFixed(2),
            ),
            comprasInternasGravadas: Number(comprasInternasGravadas.toFixed(2)),
            internacionesGravadasBienes: Number(
              internacionesGravadasBienes.toFixed(2),
            ),
            importacionesGravadasBienes: Number(
              importacionesGravadasBienes.toFixed(2),
            ),
            importacionesGravadasServicios: Number(
              importacionesGravadasServicios.toFixed(2),
            ),
            creditoFiscal: Number(creditoFiscal.toFixed(2)),
            totalCompras: Number(totalCompras.toFixed(2)),
            tipoOperacion:
              tipoOperacion !== null
                ? (tipoOperacion?.codigoTributario === ''
                    ? 0
                    : tipoOperacion?.codigoTributario) ?? 0
                : 0,
            clasificacion:
              clasificacion !== null
                ? (clasificacion?.codigoTributario === ''
                    ? 0
                    : clasificacion?.codigoTributario) ?? 0
                : 0,
            sector:
              sector !== null
                ? (sector?.codigoTributario === ''
                    ? 0
                    : sector?.codigoTributario) ?? 0
                : 0,
            tipoCostoGasto:
              tipoCosto !== null
                ? (tipoCosto?.codigoTributario === ''
                    ? 0
                    : tipoCosto?.codigoTributario) ?? 0
                : 0,
            numeroAnexo: '3',

            tipoDocProveedor: tipoId?.descripcion ?? '',
          }
        }
      })
      console.log('parserCompras end', d)

      return d
    },
    [empresa, localSession],
  )
  const parserSujetoExcluido = useCallback(
    async (data) => {
      const tiposIdApi = await tipoIdentificacionService.getTipoIdetificacion(
        TipoTransaccion.compra,
        empresa.codigoPais,
      )
      const d = data.map((venta, i) => {
        if (venta.estadoElectronico === 'AUTORIZADO') {
          let tipoId: TipoIdentificacion | null = null

          if (tiposIdApi.auto && tiposIdApi.auto.length > 0) {
            const selectedIndex = tiposIdApi.auto.findIndex(
              (select: TipoIdentificacion) => {
                return select.codigo == venta.proveedorTipoIdentificacion
              },
            )
            if (selectedIndex > -1) {
              tipoId = tiposIdApi.auto[selectedIndex]
            }
          }

          const descuentoGlobal = venta.descuentoGlobal ?? 0
          const itemsSinImpuesto = venta.itemsSinImpuesto ?? 0
          const itemsConImpuesto = venta.itemsConImpuesto ?? 0
          const totalProductos = itemsSinImpuesto + itemsConImpuesto
          const descPorItem = descuentoGlobal / totalProductos
          const totalDescuGravado = itemsConImpuesto * descPorItem
          const totalDescuExento = itemsSinImpuesto * descPorItem
          const totalExenta = utilidades.formatDecimal(
            venta.totalExenta - totalDescuExento,
            2,
          )
          const totalGravada = utilidades.formatDecimal(
            venta.totalGravada - totalDescuGravado,
            2,
          )

          const codigoGen =
            venta?.autorizacionHacienda?.codigoGeneracion?.replaceAll(
              '-',
              '',
            ) ?? ''

          return {
            ...venta,
            comprobante: i,
            numeroControl:
              'DTE-' +
              VentaHelperSV.generarCodigoControl(
                venta.tipoDocumento,
                venta.establecimiento,
                venta.puntoEmision,
                Number(venta.numero),
                empresa?.comercial,
                localSession?.tipo,
              ).content,
            subtotalIva: totalGravada,
            tipoDocProveedor: tipoId?.descripcion ?? '',
            numeroAnexo: '5',
            numControldocumentoDel: codigoGen,
            numDocumentoDel: codigoGen,
            clasificacionDescripcion:
              venta?.clasificacionDescripcion != false
                ? venta?.clasificacionDescripcion
                : '',
            sectorDescripcion:
              venta?.sectorDescripcion != false ? venta?.sectorDescripcion : '',
            operacionDescripcion:
              venta.operacionDescripcion != false
                ? venta?.operacionDescripcion
                : '',
            tipoCostoDescripcion:
              venta?.tipoCostoDescripcion != false
                ? venta?.tipoCostoDescripcion
                : '',
            tipoIdentificacion: tipoId?.descripcion ?? '',
            dui:
              tipoId?.codTributario == '13' ? venta.clienteIdentificacion : '',
            nit:
              tipoId?.codTributario == '36' ? venta.clienteIdentificacion : '',
            numControldocumentoAL: '0',
            ventasNoSujetas: 0,
            ventasProporcionalidad: 0,
            expoDentroCentroAmerica: 0,
            expoAfueraCentroAmerica: 0,
            exportacionesServicios: 0,
            numeroMaquinaRegistradora: '0',
            ventasZonasFrancas: 0,
            ventasZonasFrancasDPA: 0,
            retener: venta.retener,
            tipoDocumentoCodigo: venta.tipoDocumento,
            retenidoIva: utilidades.formatDecimal(venta.retenidoIva, 2),
            retenidoRenta: utilidades.formatDecimal(venta.retenidoRenta, 2),
            subtotal0: totalExenta,
            claseDocumento: '4. DOCUMENTO TRIBUTARIO ELECTRÓNICO (DTE)',
            autorizacionHacienda: {
              ...venta.autorizacionHacienda,
              codigoGeneracion:
                venta?.autorizacionHacienda?.codigoGeneracion?.replaceAll(
                  '-',
                  '',
                ) ?? '',
            },
          }
        }
      })
      return d
    },
    [empresa, localSession],
  )

  const parserContribuyente = useCallback(
    (data, iva) => {
      const d = data.map((venta, i) => {
        if (venta.estadoElectronico === 'AUTORIZADO') {
          const descGlobal = venta.descuentoGlobal ?? 0
          const itemsSinImpuesto = venta.itemsSinImpuesto ?? 0
          const itemsConImpuesto = venta.itemsConImpuesto ?? 0
          const totalProductos = itemsSinImpuesto + itemsConImpuesto
          const descPorItem = descGlobal / totalProductos
          const totalDescuGravado = itemsConImpuesto * descPorItem
          const totalDescuExento = itemsSinImpuesto * descPorItem
          const totalExenta = utilidades.formatDecimal(
            Number(venta?.totalExenta ?? 0) - totalDescuExento,
            2,
          )
          const totalGravada = utilidades.formatDecimal(
            Number(venta?.totalGravada ?? 0) - totalDescuGravado,
            2,
          )
          const totalIva = utilidades.formatDecimal(totalGravada * iva, 2)
          const subtotalIva = Number(venta?.subtotalIva)
          const date = venta?.fechaNegociacion.toString()
          const codigoGen =
            venta?.autorizacionHacienda?.codigoGeneracion?.replaceAll('-', '')
          let ventasNoSujetas = 0
          if (venta?.totalNoSujeto) {
            ventasNoSujetas = Number(venta?.totalNoSujeto ?? 0)
          }
          const debitoFiscal = Number(venta?.iva)

          const numeroControlDte =
            'DTE-' +
            VentaHelperSV.generarCodigoControl(
              venta.tipoDocumentoCodigo,
              venta.establecimiento,
              venta.puntoEmision,
              Number(venta.numero),
              empresa?.comercial,
              localSession?.tipo,
            ).content

          let duiCliente = ''
          let nitCliente = ''
          if (venta?.clienteTipoIdentificacion === '13') {
            // dui
            duiCliente = venta?.clienteIdentificacion
          } else if (venta?.clienteTipoIdentificacion === '12') {
            // nit
            nitCliente = venta?.clienteIdentificacion
          }

          return {
            ...venta,
            codigo: i,
            fechaNegociacion: date,
            numeroResolucion: numeroControlDte,
            serieDocumento: venta?.autorizacionHacienda?.selloRecibido,

            numControldocumentoDel: codigoGen,
            numControldocumentoAL: '0',
            numControlInternoAL: 'N/A',
            numControlInternoDel: 'N/A',
            ventasNoSujetas: formatNumber(ventasNoSujetas),
            ventasProporcionalidad: formatNumber(0),
            numeroAnexo: '1',

            tipoDocumentoCodigo: venta?.tipoDocumentoCodigo,
            numControlInterno: venta?.comprobante,
            numDocumento: codigoGen,
            numDocumentoAl: codigoGen,
            numDocumentoDel: codigoGen,
            numeroMaquinaRegistradora: '0',
            expoDentroCentroAmerica: formatNumber(0),
            expoAfueraCentroAmerica: formatNumber(0),
            exportacionesServicios: formatNumber(0),
            ventasZonasFrancas: formatNumber(0),
            ventasZonasFrancasDPA: formatNumber(0),
            subtotal0: formatNumber(totalExenta),
            subtotalIva: formatNumber(subtotalIva),
            claseDocumento: 4,
            claseDocumentoDescripcion:
              '4. DOCUMENTO TRIBUTARIO ELECTRÓNICO (DTE)',
            autorizacionHacienda: {
              ...venta.autorizacionHacienda,
              codigoGeneracion:
                venta?.autorizacionHacienda?.codigoGeneracion?.replaceAll(
                  '-',
                  '',
                ) ?? '',
            },

            clienteIdentificacion: nitCliente,
            clienteNombre: venta?.clienteNombre,
            numeroControl: numeroControlDte,
            debitoFiscal: formatNumber(debitoFiscal),
            iva: formatNumber(totalIva),
            total: formatNumber(venta?.total),
            duiCliente: duiCliente,
          }
        }
      })
      return d
    },
    [empresa, localSession],
  )

  const parserRetencion = useCallback(
    async (data) => {
      const tiposIdApi = await tipoIdentificacionService.getTipoIdetificacion(
        TipoTransaccion.compra,
        empresa.codigoPais,
      )

      return data.map((venta) => {
        if (venta.estadoElectronico === 'AUTORIZADO') {
          let tipoId: TipoIdentificacion | null = null

          if (tiposIdApi.auto && tiposIdApi.auto.length > 0) {
            const selectedIndex = tiposIdApi.auto.findIndex(
              (select: TipoIdentificacion) => {
                return select.codigo === venta.tipoIdentificacion
              },
            )
            if (selectedIndex > -1) {
              tipoId = tiposIdApi.auto[selectedIndex]
            }
          }
          const codigoGen =
            venta?.autorizacionHacienda?.codigoGeneracion?.replaceAll(
              '-',
              '',
            ) ?? ''

          return {
            ...venta,
            numeroControl:
              'DTE-' +
              VentaHelperSV.generarCodigoControl(
                venta.tipoDocumentoCodigo,
                venta.establecimiento,
                venta.puntoEmision,
                Number(venta.numero),
                empresa?.comercial,
                localSession?.tipo,
              ).content,
            numControldocumentoDel: codigoGen,
            numDocumentoDel: codigoGen,
            numControldocumentoAL: '0',
            ventasNoSujetas: 0,
            ventasProporcionalidad: 0,
            expoDentroCentroAmerica: 0,
            expoAfueraCentroAmerica: 0,
            exportacionesServicios: 0,
            numeroMaquinaRegistradora: '0',
            ventasZonasFrancas: 0,
            ventasZonasFrancasDPA: 0,
            tipoIdentificacion: tipoId?.descripcion ?? '',
            dui:
              tipoId?.codTributario == '13' ? venta.clienteIdentificacion : '',
            nit:
              tipoId?.codTributario == '36' ? venta.clienteIdentificacion : '',
            numeroAnexo: '7',
            claseDocumento: '07 COMPROBANTE DE RETENCIÓN',
            autorizacionHacienda: {
              ...venta.autorizacionHacienda,
              codigoGeneracion:
                venta?.autorizacionHacienda?.codigoGeneracion?.replaceAll(
                  '-',
                  '',
                ) ?? '',
            },
          }
        }
      })
    },
    [empresa, localSession],
  )

  function formatNumber(num: number): string {
    return num.toFixed(2)
  }

  const onExportRetencion = React.useCallback(async (data) => {
    const resultado = await anexoTransaccionalSVService.generarCsvRete(
      data as any,
    )
    // Download the CSV file
    const csvData = resultado
    const blob = new Blob([csvData], { type: 'text/csv' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'data.csv')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(url)
  }, [])

  const onExportContribuyente = React.useCallback(
    async (data) => {
      let dataParser: any = ventas.concat(exportacion)

      //add missing fields
      dataParser = data.map((element) => ({
        ...element,
        montoRetencencion: 0,
        tipoOperacion: '-',
        clasificacion: '-',
        sector: '-',
        tipoCosto: 0,
        tipoDocSv: 0,
      }))

      const resultado =
        await anexoTransaccionalSVService.generarCsvContribuyente(dataParser)

      // Download the CSV file
      const csvData = resultado
      const blob = new Blob([csvData], { type: 'text/csv' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'data.csv')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(url)
    },
    [exportacion, ventas],
  )

  const onExportCSV = React.useCallback(async (data: ConfigDocCSV) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet(data?.title ?? '')
    exportDataGrid({
      component: data.component,
      worksheet: worksheet,
    }).then(function () {
      worksheet.spliceRows(1, 1) // elimina los header
      // Escribir el contenido en el buffer CSV
      // Configuración para el delimitador de columnas ;
      const csvOptions = {
        formatterOptions: {
          delimiter: ';',
        },
      } as Partial<CsvWriteOptions>
      workbook.csv
        .writeBuffer(csvOptions)
        .then(function (buffer) {
          saveAs(
            new Blob([buffer], { type: 'text/csv;charset=utf-8;' }),
            data.filename + '.csv',
          )
        })
        .catch((error) => {
          consoleService.error(error)
        })
    })
  }, [])

  const onMerge = React.useCallback(async () => {
    dispatch(
      setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: ButtonTypes.export,
      }),
    )

    dispatch(
      setDatosEdicion({
        data: {
          ...datosEdicion,
          loader: {
            show: true,
            mensaje: 'Por favor espere...',
          },
        },
        key: tabId,
      }),
    )

    //validar que si hay datos seleccionados pueda combinar solo factura y exportacion si mostrar un mensaje que diga que no se puede combinar
    if (
      filtroBusqueda?.dte?.['01'] &&
      filtroBusqueda?.dte?.['01'] === true &&
      filtroBusqueda?.dte?.['11'] &&
      filtroBusqueda?.dte?.['11'] === true
    ) {
      //combinar los datos
      // let data = ventas.concat(exportacion)
      const data = ventas.concat(exportacion).map((det) => {
        return {
          ...det,
          localTipo:
            Number(det?.localTipo) === ETiposEstablecimientosMH.Matriz
              ? 'M'
              : 'S',
        }
      })

      const anexo: any = []
      data.map(function (d) {
        if (d?.estadoElectronico === 'AUTORIZADO') {
          anexo.push(d)
        }
      })

      const resultado = await anexoTransaccionalSVService.generarCsvCF(
        anexo as any,
      )
      dispatch(
        setDatosEdicion({
          data: {
            ...datosEdicion,
            loader: {
              show: false,
              mensaje: '',
            },
          },
          key: tabId,
        }),
      )

      const csvData = resultado
      const blob = new Blob([csvData], { type: 'text/csv' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'ventasnocontribuyentes.csv')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(url)
    } else if (
      filtroBusqueda?.dte?.['05'] &&
      filtroBusqueda?.dte?.['05'] === true &&
      filtroBusqueda?.dte?.['03'] &&
      filtroBusqueda?.dte?.['03'] === true &&
      filtroBusqueda?.dte?.['06'] &&
      filtroBusqueda?.dte?.['06'] === true
    ) {
      //combinar los datos
      let data = notasCredito.concat(creditoFiscal).concat(notasDebito)

      //add missing fields
      data = data.map((element) => ({
        ...element,
        montoRetencencion: 0,
        tipoOperacion: '-',
        clasificacion: '-',
        sector: '-',
        tipoCosto: 0,
        tipoDocSv: 0,
      }))

      const resultado =
        await anexoTransaccionalSVService.generarCsvContribuyente(data as any)

      dispatch(
        setDatosEdicion({
          data: {
            ...datosEdicion,
            loader: {
              show: false,
              mensaje: '',
            },
          },
          key: tabId,
        }),
      )

      const csvData = resultado
      const blob = new Blob([csvData], { type: 'text/csv' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'ventascontribuyentes.csv')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(url)
    } else {
      //mensaje que diga que no se puede combinar con toast
      dispatch(
        setDatosEdicion({
          data: {
            ...datosEdicion,
            loader: {
              show: false,
              mensaje: '',
            },
          },
          key: tabId,
        }),
      )

      dispatch(
        addToast({
          content:
            'No se puede combinar los datos seleccionados, solo puede combinar Facturas y Facturas de Exportación o Notas de Crédito, Notas de Débito y Crédito Fiscal',
          type: ToastTypes.Danger,
        }),
      )
    }

    dispatch(
      setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: undefined,
      }),
    )
  }, [
    creditoFiscal,
    datosEdicion,
    dispatch,
    exportacion,
    notasCredito,
    notasDebito,
    tabId,
    ventas,
    filtroBusqueda,
  ])

  const modoNuevo = React.useCallback(
    async (template: number, limpiar: boolean = false) => {
      if (loading === false && !limpiar) {
        return
      }

      const data = { ...datosEdicion }
      dispatch(
        setButtons({
          tabKey: tabId,
          buttons: {
            ...AnexoButtons,
            Buscar: false,
            Editar: false,
            Imprimir: false,
            Exportar: true,
            Enviar: false,
            Guardar: true,
            Generar_xml: false,
            Zip: true,
          },
        }),
      )

      let tabs: TabConfigAnexo[] = []

      tabs = dteSalvador.map(function (d) {
        let codeTipoDte = null
        if (d?.codigo === 'ANULADOS') {
          codeTipoDte = 'ANULADOS'
        } else {
          codeTipoDte = d.codigo as TiposComprobantesSLV
        }
        return {
          visible: false,
          title: d.valor,
          code: codeTipoDte,
        }
      })

      if (ventas.length > 0) {
        const findCF = tabs.findIndex(
          (f) => f.code === TiposComprobantesSLV.Factura,
        )
        if (findCF > -1) {
          tabs[findCF].visible = true
        }
      }

      if (creditoFiscal.length > 0) {
        const findCCF = tabs.findIndex(
          (f) => f.code === TiposComprobantesSLV.CreditoFiscal,
        )
        if (findCCF > -1) {
          tabs[findCCF].visible = true
        }
      }

      if (creditoFiscal.length > 0) {
        const findCCF = tabs.findIndex(
          (f) => f.code === TiposComprobantesSLV.CreditoFiscal,
        )
        if (findCCF > -1) {
          tabs[findCCF].visible = true
        }
      }

      if (exportacion.length > 0) {
        const findCCF = tabs.findIndex(
          (f) => f.code === TiposComprobantesSLV.FacturaExportacion,
        )
        if (findCCF > -1) {
          tabs[findCCF].visible = true
        }
      }

      if (sujetoExcluido.length > 0) {
        const findCCF = tabs.findIndex(
          (f) => f.code === TiposComprobantesSLV.FacturaExcluidoSujeto,
        )
        if (findCCF > -1) {
          tabs[findCCF].visible = true
        }
      }

      if (notasCredito.length > 0) {
        const findCCF = tabs.findIndex(
          (f) => f.code === TiposComprobantesSLV.NotaCredito,
        )
        if (findCCF > -1) {
          tabs[findCCF].visible = true
        }
      }

      if (notasDebito.length > 0) {
        const findCCF = tabs.findIndex(
          (f) => f.code === TiposComprobantesSLV.NotaDebito,
        )
        if (findCCF > -1) {
          tabs[findCCF].visible = true
        }
      }

      if (notasRemision.length > 0) {
        const findCCF = tabs.findIndex(
          (f) => f.code === TiposComprobantesSLV.NotaRemision,
        )
        if (findCCF > -1) {
          tabs[findCCF].visible = true
        }
      }

      if (liquidaciones.length > 0) {
        const findCCF = tabs.findIndex(
          (f) => f.code === TiposComprobantesSLV.Liquidacion,
        )
        if (findCCF > -1) {
          tabs[findCCF].visible = true
        }
      }

      if (liquidacionesContables.length > 0) {
        const findCCF = tabs.findIndex(
          (f) => f.code === TiposComprobantesSLV.LiquidacionContable,
        )
        if (findCCF > -1) {
          tabs[findCCF].visible = true
        }
      }

      if (donaciones.length > 0) {
        const findCCF = tabs.findIndex(
          (f) => f.code === TiposComprobantesSLV.Donacion,
        )
        if (findCCF > -1) {
          tabs[findCCF].visible = true
        }
      }

      if (retenciones.length > 0) {
        const findCCF = tabs.findIndex(
          (f) => f.code === TiposComprobantesSLV.Retencion,
        )
        if (findCCF > -1) {
          tabs[findCCF].visible = true
        }
      }

      if (anulados.length > 0) {
        const indexAnulados = tabs.findIndex((f) => f.code === 'ANULADOS')
        if (indexAnulados > -1) {
          tabs[indexAnulados].visible = true
        }
      }

      if (compras.length > 0) {
        const indexAnulados = tabs.findIndex((f) => f.code === 'COMPRAS')
        if (indexAnulados > -1) {
          tabs[indexAnulados].visible = true
        }
      }

      setConfigTabs(tabs)

      if (tabs.length > 0) {
        const i = tabs.findIndex((objeto) => objeto.visible === true)
        if (i > -1) {
          setActiveKey(tabs[i].code as TiposComprobantesSLV)
        }
      }

      try {
        data.loading = false
        data.loader = {
          mensaje: '',
          show: false,
        }
      } catch (error) {
        data.loading = false
        data.tieneError = true
        data.mensajeError =
          typeof error == 'string' ? error : JSON.stringify(error)
        data.loader = {
          mensaje: '',
          show: false,
        }
        dispatch(
          addToast({
            content: data.mensajeError,
            type: ToastTypes.Danger,
          }),
        )
      }
      dispatch(
        setDatosEdicion({
          data: data,
          key: tabId,
        }),
      )
    },
    [
      loading,
      datosEdicion,
      dispatch,
      tabId,
      ventas,
      creditoFiscal,
      exportacion,
      sujetoExcluido,
      notasCredito,
      notasDebito,
      notasRemision,
      liquidaciones,
      liquidacionesContables,
      donaciones,
      retenciones,
      anulados,
      compras,
    ],
  )

  const generarCsv = React.useCallback(async () => {
    onMerge()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datosEdicion, onMerge])

  const generateBooks = React.useCallback(async () => {
    const dataParser: any = []
    const fac_ = ventas.map((e) => ({
      ...e,
      facturasEmitidas: 0,
      fechaEmision: e?.fecha ?? '',
      numerofactura: e?.numero ?? '',
      tipoComprobante: e?.tipoDocumentoDescripcion ?? '',
      clienteNombre: e?.clienteNombre ?? '',
      descripcionOperacion: e?.observaciones ?? '',
      baseImponible: e?.subtotal ?? 0,
      tipoIva: e?.iva > 0 ? 13 : 'Exento',
      importeIva: e?.iva ?? 0,
      totalFacturado: e?.total ?? 0,
    }))

    dataParser.push(...fac_)

    const exp_ = exportacion.map((e) => ({
      ...e,
      facturasEmitidas: 0,
      fechaEmision: e?.fecha ?? '',
      numerofactura: e?.numero ?? '',
      tipoComprobante: e?.tipoDocumentoDescripcion ?? '',
      clienteNombre: e?.clienteNombre ?? '',
      descripcionOperacion: e?.observaciones ?? '',
      baseImponible: e?.subtotal ?? 0,
      tipoIva: e?.iva > 0 ? 13 : 'Exento',
      importeIva: e?.iva ?? 0,
      totalFacturado: e?.total ?? 0,
    }))

    dataParser.push(...exp_)

    const ccf_ = creditoFiscal.map((e) => ({
      ...e,
      facturasEmitidas: 0,
      fechaEmision: e?.fecha ?? '',
      numerofactura: e?.numero ?? '',
      tipoComprobante: e?.tipoDocumentoDescripcion ?? '',
      clienteNombre: e?.clienteNombre ?? '',
      descripcionOperacion: e?.observaciones ?? '',
      baseImponible: e?.subtotal ?? 0,
      tipoIva: e?.iva > 0 ? 13 : 'Exento',
      importeIva: e?.iva ?? 0,
      totalFacturado: e?.total ?? 0,
    }))

    dataParser.push(...ccf_)

    const nc_ = notasCredito.map((e) => ({
      ...e,
      facturasEmitidas: 0,
      fechaEmision: e?.fecha ?? '',
      numerofactura: e?.numero ?? '',
      tipoComprobante: e?.tipoDocumentoDescripcion ?? '',
      clienteNombre: e?.clienteNombre ?? '',
      descripcionOperacion: e?.observaciones ?? '',
      baseImponible: e?.subtotal ?? 0,
      tipoIva: e?.iva > 0 ? 13 : 'Exento',
      importeIva: e?.iva ?? 0,
      totalFacturado: e?.total ?? 0,
    }))

    dataParser.push(...nc_)

    try {
      const response = await ExportarExcelService.exportarArchivo(dataParser)
      const filename = 'libroVentas.xlsx'
      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = filename
      a.click()
    } catch (error) {
      dispatch(
        addToast({
          title: 'Exportar Excel',
          content: typeof error == 'string' ? error : JSON.stringify(error),
          type: ToastTypes.Danger,
          autoHide: 3000,
        }),
      )
    }
  }, [dispatch, ventas, creditoFiscal, exportacion, notasCredito])

  const initProviders = React.useCallback(async () => {
    if (
      filtroBusqueda?.dte?.['COMPRAS'] &&
      filtroBusqueda?.dte['COMPRAS'] === true
    ) {
      if (!empresa?.codigoPais) {
        // eslint-disable-next-line no-console
        console.warn('El código de país no está disponible.')
        return
      }

      try {
        await Promise.all([
          tipoOperacionesService.getTipoOperaciones(
            'Elija una opción',
            empresa.codigoPais,
          ),
          tipoClasificacionesSujExc.getTipoClasificacionesSujExc(
            'Elija una opción',
            empresa.codigoPais,
          ),
          tipoCostosSujExc.getTipoCostosSujExc(
            'Elija una opción',
            empresa.codigoPais,
          ),
          tipoSectorService.getTipoSector(
            'Elija una opción',
            empresa.codigoPais,
          ),
        ])
        // eslint-disable-next-line no-console
        console.log('Todos los servicios han sido inicializados correctamente.')
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error al inicializar los servicios:', error)
      }
    }
  }, [empresa, filtroBusqueda])

  const handleButtonClick = React.useCallback(
    (button: ButtonTypes) => {
      switch (button) {
        case ButtonTypes.export:
          generarCsv()
          break
        case ButtonTypes.generateBooks:
          generateBooks()
          break
        case ButtonTypes.new:
          dispatch(closeTab(tabId))
          setTimeout(() => {
            const idTab: any = tabId
            dispatch(clearDatosEdicion(idTab))
          }, 100)
          break
        default:
          break
      }
      dispatch(clearButtonClick(tabId))
    },
    [dispatch, generarCsv, generateBooks, tabId],
  )

  React.useEffect(() => {
    initProviders()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (tab.editStatus === StatesEdition.new) {
      modoNuevo(tab.info?.codigo ?? 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
  }, [handleButtonClick, tab.globalButtonClick])

  React.useEffect(() => {
    const configVentas = async () => {
      const iva = await VentasService.getIva(
        DateUtils.dateToString(new Date(), formatoFechasApi),
        null,
      )
      // creditoFiscal.concat(notasCredito)
      const ccf = parserContribuyente(creditoFiscal, iva / 100)
      setVentasCCF(ccf) // .concat(notasCredito)
      setVentasAnexos(parserNoContribuyente(ventas))
      setVentasNotasCredito(parserContribuyente(notasCredito, iva / 100))
      setVentasNotasDebito(parserContribuyente(notasDebito, iva / 100))
      setVentasExportacion(parserExportacion(exportacion))
      setVentasRetenciones(await parserRetencion(retenciones))
      setVentasSujetoExcluido(await parserSujetoExcluido(sujetoExcluido))
      setComprasAnexo(await parserCompras(compras))
    }
    configVentas()
  }, [
    compras,
    creditoFiscal,
    sujetoExcluido,
    exportacion,
    notasCredito,
    notasDebito,
    parserCompras,
    parserContribuyente,
    parserExportacion,
    parserNoContribuyente,
    ventas,
    parserSujetoExcluido,
    retenciones,
    parserRetencion,
    empresa?.codigoPais,
  ])

  return (
    <>
      <div
        style={{ padding: '10px', overflowX: 'hidden', paddingBottom: '150px' }}
        id="mantenimientoPedido"
      >
        <Dialog ref={dialogRef} />

        <BlockUi
          loader={LoadingIndicator}
          tag="div"
          blocking={loader.show}
          message={loader.mensaje}
        >
          <fieldset>
            <ValidationGroup
              id={`valGroupOrden${props.tabId}`}
              ref={validationGroupRef}
            >
              <div>
                <RowContainer>
                  <CustomCol>
                    <CNav variant="tabs" role="tablist">
                      {configTabs.map(
                        (d, i) =>
                          d.visible && (
                            <CNavItem key={i}>
                              <CNavLink
                                active={activeKey === d.code}
                                onClick={() => setActiveKey(d.code)}
                              >
                                {d.title}
                              </CNavLink>
                            </CNavItem>
                          ),
                      )}
                    </CNav>

                    <CTabContent style={{ overflowY: 'unset' }}>
                      {configTabs.map((d, i) => (
                        <CTabPane key={i} visible={activeKey === d.code}>
                          <CCard className={'m-0 p-4'}>
                            <RowContainer>
                              <CustomCol>
                                <strong>{d?.title ?? ''}</strong>
                              </CustomCol>
                              <CustomCol></CustomCol>
                            </RowContainer>
                            <RowContainer>
                              <CustomCol>
                                {d.code === TiposComprobantesSLV.Factura && (
                                  <TablaVentas
                                    data={ventasAnexos}
                                    onExport={onExportCSV}
                                  />
                                )}
                                {d.code === TiposComprobantesSLV.Retencion && (
                                  <TablaRetenciones
                                    data={ventasRetenciones}
                                    onExport={onExportRetencion}
                                  />
                                )}
                                {d.code ===
                                  TiposComprobantesSLV.CreditoFiscal && (
                                  <TablaCCF
                                    data={ventasCCF}
                                    onExport={onExportCSV}
                                  />
                                )}
                                {d.code ===
                                  TiposComprobantesSLV.FacturaExportacion && (
                                  <TablaVentas
                                    data={ventasExportacion}
                                    onExport={onExportCSV}
                                  />
                                )}
                                {d.code ===
                                  TiposComprobantesSLV.FacturaExcluidoSujeto && (
                                  <TablaSuj
                                    data={ventasSujetoExcluido}
                                    onExport={onExportCSV}
                                  />
                                )}
                                {d.code ===
                                  TiposComprobantesSLV.NotaCredito && (
                                  <TablaNotasCredito
                                    data={ventasNotasCredito}
                                    onExport={onExportCSV}
                                  />
                                )}
                                {d.code === TiposComprobantesSLV.NotaDebito && (
                                  <TablaCCF
                                    data={ventasNotasDebito}
                                    onExport={onExportContribuyente}
                                  />
                                )}
                                {d.code ===
                                  TiposComprobantesSLV.NotaRemision && (
                                  <TablaCCF
                                    data={notasRemision}
                                    onExport={onExportContribuyente}
                                  />
                                )}
                                {d.code ===
                                  TiposComprobantesSLV.Liquidacion && (
                                  <TablaCCF
                                    data={liquidaciones}
                                    onExport={onExportContribuyente}
                                  />
                                )}
                                {d.code ===
                                  TiposComprobantesSLV.LiquidacionContable && (
                                  <TablaCCF
                                    data={liquidacionesContables}
                                    onExport={onExportContribuyente}
                                  />
                                )}
                                {d.code === TiposComprobantesSLV.Donacion && (
                                  <TablaCCF
                                    data={donaciones}
                                    onExport={onExportContribuyente}
                                  />
                                )}
                                {d.code === 'ANULADOS' && (
                                  <TablaAnulados
                                    data={anulados}
                                    onExport={onExportCSV}
                                  />
                                )}
                                {d.code === 'COMPRAS' && (
                                  <TablaCompras
                                    data={comprasAnexo}
                                    onExport={onExportCSV}
                                  />
                                )}
                              </CustomCol>
                            </RowContainer>
                          </CCard>
                        </CTabPane>
                      ))}
                    </CTabContent>
                  </CustomCol>
                </RowContainer>
              </div>
            </ValidationGroup>
          </fieldset>
        </BlockUi>
      </div>
    </>
  )
}

export const AnexoButtons: CustomButtons = {
  Nuevo: true,
  Guardar: true,
  Buscar: true,
  Deshacer: true,
  Editar: false,
  Imprimir: true,
  Extraer: false,
  Generar_xml: false,
  Zip: false,
}

export default React.memo(Anexo)
