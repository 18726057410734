import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
 FiltrosaplicacionesState,
 BusquedaState,
 AplicacionInventario,
} from '../types/types'
import { FETCH_STATUS } from '../../../../../../store/types'
import { aplicationsModalServices } from '../../../items/components/modalAplicaciones/services/modalAplicaciones.services'
import { TOptionGenerico } from '../../../../../clientes/pages/clientes/types/types'

const initialState: BusquedaState<AplicacionInventario> = {
 filter: {
  descripcion: '',
  pertence: null,
  estado: null,
  initial: true,
 },
 dataEdit: null,
 status: FETCH_STATUS.IDDLE,
 mensaje: '',
 resultados: [],
 colapsado: true,
}

export const fetchUnidades = createAsyncThunk<
 Array<AplicacionInventario>,
 FiltrosaplicacionesState
>('inventarios/catalogos/aplicaciones/listar', async (filtro) => {
 try {
  const aplicationsList = await aplicationsModalServices.getAplications(
   filtro?.descripcion,
   filtro.pertence?.codigo ?? 0,
  )
  return aplicationsList
 } catch (error) {
  return Promise.reject(error)
 }
})

const searchSlice = createSlice({
 name: 'buscarAplicaciones',
 initialState: initialState,
 reducers: {
  setCleanResult(state) {
   state.resultados = []
  },
  setDatEdit(state, action: PayloadAction<any>) {
   state.dataEdit = action.payload
  },
  setCollapsed(state, action: PayloadAction<boolean>) {
   state.colapsado = action.payload
  },
  setCleanDatEdit(state) {
   state.dataEdit = null
  },
  changeFilter(state, action: PayloadAction<FiltrosaplicacionesState>) {
   state.filter.descripcion = action.payload.descripcion
   state.filter.pertence = action.payload.pertence
   state.filter.estado = action.payload.estado
   state.filter.initial = action.payload.initial
  },
 },
 extraReducers: (builder) => {
  builder.addCase(fetchUnidades.pending, (state) => {
   if (
    state.status === FETCH_STATUS.IDDLE ||
    state.status === FETCH_STATUS.SUCCESS
   ) {
    state.status = FETCH_STATUS.LOADING
    state.mensaje = ''
   }
  })
  builder.addCase(fetchUnidades.fulfilled, (state, { payload }) => {
   state.resultados = payload
   state.status = FETCH_STATUS.SUCCESS
  })
  builder.addCase(fetchUnidades.rejected, (state, { payload }) => {
   state.resultados = []
   state.status = FETCH_STATUS.FAILED
   state.mensaje = payload as string
  })
 },
})

export const {
 changeFilter,
 setCleanResult,
 setDatEdit,
 setCleanDatEdit,
 setCollapsed,
} = searchSlice.actions

export const searchReducer = searchSlice.reducer
