import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ConfigSeries } from '../types/types'

const initialState: ConfigSeries = {
  modulo: 0,
}

const configuracionSeries = createSlice({
  name: 'configSeries',
  initialState: initialState,
  reducers: {
    setModulo(state, acion: PayloadAction<number>) {
      state.modulo = acion.payload
    },
  },
})

export const configuracionesSeries = configuracionSeries.reducer
export const { setModulo } = configuracionSeries.actions
