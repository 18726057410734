import {PaginationInfo} from "../../../../../store/types";
import {TFiltroBusquedaFormaPagoState, TFormaPagoListado} from "../store/types";
import {RequestHelper} from "../../../../../helpers/requestHelper";

export const FormaPagoService = {
  getCollection,
  getResource,
  postResource,
  deleteResource,
}

async function getCollection(filtro: TFiltroBusquedaFormaPagoState): Promise<any> {
  try {
    const query = buildQuery(filtro);
    const pagedResponse = await RequestHelper.getAll<any>('generales/paymentFormsMain', 'getAll', '', query);
    pagedResponse.auto = pagedResponse.auto? parseResponse(pagedResponse.auto) : [];
    return pagedResponse;
  } catch (error) {
    return error;
  }
}

async function getResource(id: any): Promise<any> {
  try {
    // const query = {
    //   codigo: id,
    // };
    // return await RequestHelper.get<any>('province', 'getProvince', '', query);
    return new Promise((resolve, reject) => {});
  } catch (error) {
    return error;
  }
}

async function postResource(formData: any): Promise<any> {
  try {
    const body = {
      infoRegistro: {
        ...formData,
      }
    };

    return await RequestHelper.postAll<any>('generales/paymentFormsMain', 'save', body);
  } catch (error) {
    return error;
  }
}

async function deleteResource(id: any): Promise<any> {
  try {
    const query = {
      codigo: id
    }

    return await RequestHelper.deleteRequestAll<any>('generales/paymentFormsMain', 'inactive', '', query);
  } catch (error) {
    return error;
  }
}

// Private
function buildQuery(
  filtro: TFiltroBusquedaFormaPagoState,
  pagination?: PaginationInfo,
): any {

  const query: any = {
    ...(filtro.descripcion && { descripcion: filtro.descripcion }),
    estado: filtro.estado,
  }

  if (pagination) {
    query.page = pagination.current
    query.limit = pagination.size
    query.isPaged = true
  }

  const filters = ['descripcion', 'estado'];

  filters.forEach((field) => {
    if (filtro[field] && filtro[field].length > 0) {
      query[field] = filtro[field]
    }
  })

  return query;
}

function parseResponse(dataApi: any[]): Array<TFormaPagoListado> {
  return dataApi.map((item: any) => ({
    codigo: item.codigo,
    descripcion: item.descripcion,
    codigoTributario: item.codigoTributario,
    fechaInicio: item.fechaInicio,
    fechaFinal: item.fechaFinal,
    estado: item.estado,
  }))
}
