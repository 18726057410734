import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SearchStateCustomFilter } from '../../../../../ventas/types/generics'
import { CompraInfo } from '../../../../types/types'
import { FiltroBusquedaLiquidacionCompraState } from '../../../../../ventas/types/types'
import { DateUtils } from '../../../../../../helpers/dateUtils'
import {
  DateRange,
  FETCH_STATUS,
  TiposComprobantesSLV,
} from '../../../../../../store/types'
import { CompraService } from '../../../../services/compra.service'
import { RootState } from '../../../../../../store/store'

const initialState: SearchStateCustomFilter<
  CompraInfo,
  FiltroBusquedaLiquidacionCompraState
> = {
  filter: {
    fechaInicio: DateUtils.getCurrentDateAsString(),
    fechaFin: DateUtils.getCurrentDateAsString(),
    puntoVenta: '',
    proveedor: null,
    activas: false,
    documento: '',
    formaPago: null,
    pendientesAbutorizar: false,
    establecimiento: null,
    estado: null,
    puntoEmision: null,
    tipoDoc: TiposComprobantesSLV.Liquidacion,
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  resultados: [],
}

export const fetchComprasLiquidacionesSV = createAsyncThunk<
  Array<CompraInfo>,
  FiltroBusquedaLiquidacionCompraState
>('ventas/liquidaciones/searchLiquidaciones', async (filtro) => {
  try {
    const range: DateRange = {
      inicio: DateUtils.pickersDateToApiDate(filtro.fechaInicio),
      fin: DateUtils.pickersDateToApiDate(filtro.fechaFin),
    }
    const compras = await CompraService.buscarCompras(
      range,
      filtro.documento ?? '',
      filtro.proveedor?.identificacion ?? '',
      filtro.tipoDoc,
      filtro.formaPago ? filtro.formaPago.codigo.toString() : '',
      filtro.claveAcceso ?? '',
    )
    return compras
  } catch (error) {
    return Promise.reject(error)
  }
})

const searchComprasLiquidacionesSVSlice = createSlice({
  name: 'searchComprasLiquidacionesSV',
  initialState: initialState,
  reducers: {
    resetState(state) {
      state = {
        ...initialState,
        filter: {
          ...initialState.filter,
          fechaInicio: DateUtils.getCurrentDateAsString(),
          fechaFin: DateUtils.getCurrentDateAsString(),
        },
      }
    },
    changeFilter(
      state,
      action: PayloadAction<FiltroBusquedaLiquidacionCompraState>,
    ) {
      state.filter = action.payload
    },
    setCleanResult(state) {
      state.resultados = []
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchComprasLiquidacionesSV.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(
      fetchComprasLiquidacionesSV.fulfilled,
      (state, { payload }) => {
        state.resultados = payload
        state.status = FETCH_STATUS.SUCCESS
      },
    )
    builder.addCase(
      fetchComprasLiquidacionesSV.rejected,
      (state, { payload }) => {
        state.resultados = []
        state.status = FETCH_STATUS.FAILED
        state.mensaje = payload as string
      },
    )
  },
})

export const selectFiltroBusqueda = (state: RootState) => {
  return state.compras.comprasSV.liquidacionesCompras.search.filter
}
export const selectResultadosBusqueda = (state: RootState) => {
  return state.compras.comprasSV.liquidacionesCompras.search.resultados
}
export const selectBusquedaEstado = (state: RootState) => {
  return state.compras.comprasSV.liquidacionesCompras.search.status
}
export const { changeFilter, resetState, setCleanResult } =
  searchComprasLiquidacionesSVSlice.actions
export const searchReducer = searchComprasLiquidacionesSVSlice.reducer
