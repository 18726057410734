import React, { useCallback, useEffect } from 'react'
import { ValidationGroup } from 'devextreme-react/validation-group'
import ValidationSummary from 'devextreme-react/validation-summary'
import TextBox from 'devextreme-react/text-box'
import CustomCol from '../../../../../../../../views/componentes/colContainer'
import { TEstado, ToastTypes } from '../../../../../../../../store/types'
import { Button, TextArea, Validator } from 'devextreme-react'
import { RequiredRule } from 'devextreme-react/validator'
import RowContainer from '../../../../../../../../views/componentes/rowContainer/rowContainer'
import Labeled from '../../../../../../../../views/componentes/labeledInput/labeledInput'
import TipoEstadoLookUp from '../../../../../../../componentes/tipoEstado'
import VisualizaError from '../../../../../../../ventas/pages/shared/visualizaError/visualizaError'
import ListErrosValidationForm from '../../../../../../../componentes/listadoErroresValidacionForm'
import { SaveAplication } from '../../../../types/types'
import { aplicationsModalServices } from '../../services/modalAplicaciones.services'
import { CCol, CTooltip } from '@coreui/react-pro'

interface INuevoProps extends React.PropsWithChildren {
  selectedItem?: any
  clickedButton: string
  currentTab: string
  // eslint-disable-next-line no-unused-vars
  setCurrentAction: (currentTab: string) => void
  resetAction: () => void
  resetSelected?: () => void
  // eslint-disable-next-line no-unused-vars
  setToast: (sms: string, type: ToastTypes) => void
  playLoader: () => void
  stopLoader: () => void
}

const Nuevo = (props: INuevoProps) => {
  const {
    clickedButton,
    setToast,
    selectedItem,
    playLoader,
    stopLoader,
    currentTab,
    resetAction,
    resetSelected,
    setCurrentAction,
  } = props

  const formSaveUnit = React.useRef<any>()
  const [code, setCode] = React.useState<number>(0)
  const [description, setDescription] = React.useState<string>('')
  const [observations, setObservations] = React.useState<string>('')
  const [status, setStatus] = React.useState<TEstado>({
    codigo: 1,
    descripcion: 'ACTIVO',
  })
  const [showPopupError, setShowPopupError] = React.useState<boolean>(false)
  const [validationFormErrors, setValidationFormErrors] = React.useState<
    Array<any>
  >([])

  useEffect(() => {
    if (currentTab === 'Nuevo' || currentTab === 'Editar') {
      if (clickedButton === 'Guardar') {
        onHandleSave()
      } else if (clickedButton === 'Editar') {
        setDataAplication(selectedItem)
      }
      resetAction()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickedButton])

  const onHandleReset = useCallback(() => {
    if (code === 0) {
      setDescription('')
      setObservations('')
      setStatus({ codigo: 1, descripcion: 'ACTIVO' })
    } else setDataAplication(selectedItem)
  }, [code, selectedItem])

  const setDataAplication = ({ row }) => {
    setCode(row.codigo)
    setDescription(row.descripcion)
    setObservations(row.observaciones)
    setStatus({
      codigo: row.estado,
      descripcion: row.estado === 1 ? 'ACTIVO' : 'INACTIVO',
    })
  }

  const onHandleSave = React.useCallback(async () => {
    const validationFormResult = formSaveUnit.current.instance.validate()
    if (validationFormResult.isValid === false) {
      const errorList = []
      validationFormResult.brokenRules.map((rule) => {
        errorList.push(rule.message)
      })
      setValidationFormErrors(errorList)
      setShowPopupError(true)
    } else {
      try {
        const aplication: SaveAplication = {
          infoRegistro: {
            codigo: code,
            descripcion: description,
            observaciones: observations,
            pertenece: 0,
            estado: status.codigo,
          },
        }
        playLoader()
        const saveAplication = await aplicationsModalServices.saveAplication(
          aplication,
        )
        stopLoader()
        if (!saveAplication.error && saveAplication.auto) {
          setToast(
            saveAplication.message ?? 'Aplicación Registrada',
            ToastTypes.Success,
          )
          setCurrentAction('Buscar')
          if (selectedItem) resetSelected()
          onHandleReset()
        } else {
          setToast(
            saveAplication.message ?? 'Error al Guardar Aplicacion',
            ToastTypes.Warning,
          )
        }
      } catch (error) {
        stopLoader()
        setToast(error, ToastTypes.Danger)
      }
    }
  }, [
    code,
    description,
    observations,
    status,
    playLoader,
    stopLoader,
    setToast,
    setCurrentAction,
    selectedItem,
    resetSelected,
    onHandleReset,
  ])

  const onHandleChange = React.useCallback(
    (setState: any) => (event: any) => {
      if (event.event) setState(event.value)
    },
    [],
  )

  return (
    <>
      {showPopupError && (
        <VisualizaError
          titulo="Error en Aplicaciones"
          mensaje={'Error al guardar'}
          content={
            <ListErrosValidationForm
              mainMessage={
                'Antes de guardar la información revise lo siguiente:'
              }
              errorsList={validationFormErrors}
              color={'danger'}
            />
          }
          onOk={() => setShowPopupError(false)}
        />
      )}
      <ValidationGroup ref={formSaveUnit}>
        <ValidationSummary id="summary" />
        <RowContainer>
          <CustomCol xs="12" md="4">
            <Labeled label="Descripción:">
              <TextBox
                value={description}
                onValueChanged={onHandleChange(setDescription)}
                showClearButton={true}
              >
                <Validator>
                  <RequiredRule
                    message={'El campo Descripción es obligatorio'}
                  />
                </Validator>
              </TextBox>
            </Labeled>
          </CustomCol>
          <CCol xs="12" md="1" style={{ marginTop: '34px' }}>
            <CTooltip
              content="Si deja el campo vacío el sistema interpretará que pertenece al registro raíz."
              placement="right"
            >
              <Button
                id="btnInfo"
                type="normal"
                icon="info"
                stylingMode="contained"
              />
            </CTooltip>
          </CCol>
          <CustomCol xs="12" md="5">
            <Labeled label="Observaciones:">
              <TextArea
                value={observations}
                onValueChanged={onHandleChange(setObservations)}
                showClearButton={true}
              />
            </Labeled>
          </CustomCol>
        </RowContainer>
        <RowContainer>
          <CustomCol xs="12" md="4">
            <Labeled label="Estado:">
              <TipoEstadoLookUp
                onChanged={(status: TEstado) => setStatus(status)}
                selected={status}
              />
            </Labeled>
          </CustomCol>
        </RowContainer>
      </ValidationGroup>
    </>
  )
}
export default Nuevo
