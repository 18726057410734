import { combineReducers } from "@reduxjs/toolkit";
import { AnexoTransaccionalState, WindowAnexoTransaccionalState } from "../types/types";
import { datosEdicionReducer } from "./editDataReducer";
import { menuReducer } from "./menuReducer";
import { searchAnexoReducer } from "./searchReducer";
import { tabsReducer } from "./tabsReduxcers";

export const anexoTransaccionalReducer = combineReducers<AnexoTransaccionalState>({
  menu: menuReducer,
  tabs: tabsReducer,
  search: searchAnexoReducer,
  editData: datosEdicionReducer,
  // configuraciones: configuracionesReducer
});
