import { CCardBody, CCardHeader, CCollapse, CLink } from '@coreui/react-pro'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { changeFilter, changeFilterBackup, setCollapsed } from '../../store/searchReducer';
import { cilChevronBottom, cilChevronTop } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { RootState } from '../../../../../../../store/store';
import { Button, NumberBox, RadioGroup, TextBox } from 'devextreme-react';
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput';
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer';
import CustomCol from '../../../../../../../views/componentes/colContainer';
import { consoleService } from '../../../../../../../services/console.service';
import MesesLookUp, { MesesData } from '../../../../../../componentes/mesesLookUp';
import ModalUsuarios from '../../../../../../componentes/modalUsuarios';
import { EAplicationsAcatha } from '../../../../../../../store/enumsAplication';
import { FiltrosBusquedaMarcacion } from '../../types/types';

interface ISearchProps extends React.PropsWithChildren {
  onSearchEnter: () => void
}

export const optionsReport = [
  { value: 0, label: 'Por Marcación' },
  { value: 1, label: 'Por Registro' },
]

const FiltroBusqueda: React.FunctionComponent<ISearchProps> = (props) => {
  const { onSearchEnter } = props
  const dispatch = useDispatch()
  const [showUsers, setShowUsers] = React.useState<boolean>(false)

  const collapsed = useSelector((state: RootState) => state.nomina.registros.marcacion.search.colapsado)
  const filter = useSelector((state: RootState) => state.nomina.registros.marcacion.search.filtro)

  const onInitialData = useCallback(() => {
    const temp = { ...filter }
    if (temp.initital) {
      temp.anio = new Date().getFullYear()
      temp.mes = MesesData[0]
      temp.tipoImpresion = optionsReport[0]
      temp.initital = false
      dispatch(changeFilter({
        ...temp,
      }))
      dispatch(changeFilterBackup({
        ...temp,
      }))
    }
  }, [dispatch, filter])

  const onValueChange = useCallback((data, key: string) => {
    dispatch(changeFilter({
      ...filter,
      [key]: data
    }))
  }, [dispatch, filter])

  useEffect(() => {
    onInitialData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {showUsers && (
        <ModalUsuarios
          show={showUsers}
          onClose={() => setShowUsers(false)}
          modulo={EAplicationsAcatha.Marcacion}
          onSelectUser={(user) => {
            consoleService.log(user)
            dispatch(changeFilter({
              ...filter,
              identificacion: user?.cedula,
              empleado: user?.nombre,
              empleadoCodigo: user?.codigo,
            }))
            setShowUsers(false)
          }}
        />
      )}
      <CCardHeader
        onClick={() => dispatch(setCollapsed(!collapsed))}
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <strong> {collapsed ? '' : 'Filtro de Búsqueda'} </strong>
        <CLink className="card-header-action">
          <CIcon icon={collapsed ? cilChevronBottom : cilChevronTop} />
        </CLink>
      </CCardHeader>
      <CCollapse visible={collapsed}>
        <CCardBody>
          <RowContainer>
            <CustomCol xs="12" md="3">
              <Labeled label='C.I.:'>
                <div
                  style={{
                    display: 'flex',
                    gap: '2px',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >

                  <TextBox
                    value={filter?.identificacion ?? ''}
                    onValueChange={(data) => onValueChange(data, 'identificacion')}
                    onEnterKey={onSearchEnter}
                  />
                  <Button
                    id="btnAdd"
                    className="me-1"
                    icon="plus"
                    stylingMode="contained"
                    type="default"
                    onClick={() => setShowUsers(true)}
                  />
                </div>
              </Labeled>
            </CustomCol>
            <CustomCol xs="12" md="2">
              <Labeled label='Año:'>
                <NumberBox value={filter?.anio ?? null} onValueChange={(data) => onValueChange(data, 'anio')} />
              </Labeled>
            </CustomCol>
            <CustomCol xs="12" md="2">
              <Labeled label='Mes:'>
                <MesesLookUp
                  onChanged={(data) => {
                    consoleService.log('meses ', data)
                    onValueChange(data, 'mes')
                  }}
                  selected={filter?.mes ?? null}
                />
              </Labeled>
            </CustomCol>
            <CustomCol xs="12" md="3">
              <Labeled label=''>
                <RadioGroup
                  name="grupos-impresion"
                  layout="horizontal"
                  displayExpr="label"
                  items={optionsReport}
                  value={filter?.tipoImpresion ?? null}
                  onValueChange={(data) => onValueChange(data, 'tipoImpresion')}
                />
              </Labeled>
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol xs="12" md="4">
              <Labeled label='Empleado:'>
                <TextBox readOnly value={filter?.empleado ?? ''} />
              </Labeled>
            </CustomCol>
          </RowContainer>
        </CCardBody>
      </CCollapse>
      <RowContainer>
        <CustomCol xs="12" md="3">
          <Labeled label='# Atrasos:'>
            <NumberBox readOnly value={filter?.atrasos ?? null} />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="3">
          <Labeled label='Tiempo Atrasos:'>
            <NumberBox readOnly value={filter?.tiempoAtrasos ?? null} />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="3">
          <Labeled label='Hora Extra 50%:'>
            <TextBox readOnly value={filter?.hoEx50 ?? null} />
          </Labeled>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="3">
          <Labeled label='# Salidas:'>
            <NumberBox readOnly value={filter?.salidas ?? null} />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="3">
          <Labeled label='Tiempo Sal Anticipos:'>
            <NumberBox readOnly value={filter?.tiempoSalAnt ?? null} />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="3">
          <Labeled label='Hora Extra 100%:'>
            <TextBox readOnly value={filter?.hoEx100 ?? null} />
          </Labeled>
        </CustomCol>
      </RowContainer>
    </>
  )
}

export default React.memo(FiltroBusqueda)

export const defaultFilter: FiltrosBusquedaMarcacion = {
  identificacion: '',
  empleado: '',
  empleadoCodigo: 0,
  anio: 0,
  htrCodigo: 0,
  mes: null,
  tipoImpresion: null,
  initital: true,
  atrasos: null,
  salidas: null,
  tiempoAtrasos: null,
  tiempoSalAnt: null,
  hoEx50: null,
  hoEx100: null,
}