import { EgresosState } from "../egresosND/store/types";
import { IngresosNCState } from "../ingresosNC/store/types";
import { ProtestosClientesState } from "../protestosClientes/store/types";
import { ProtestosProveedoresState } from "../protestosProveedores/store/types";

export enum statusForms {
  pending = 'pendiente',
  saved = 'guardado',
}

export type MovimientosBancosState = {
  ingresosNC: IngresosNCState,
  egresosND: EgresosState,
  protestoClientes: ProtestosClientesState,
  protestosProveedores: ProtestosProveedoresState
}