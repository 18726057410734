import React, { memo } from 'react'
import { ValidationGroup } from 'devextreme-react/validation-group'
import TextBox from 'devextreme-react/text-box'
import ResultadoBusqueda from './resultadoBusqueda'
import { CCol, CRow } from '@coreui/react-pro'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import { LineaInventario } from '../../../../../store/types'
import { InventarioService } from '../../../../../services/inventario.service'
import { ToastTypes } from '../../../../../../../store/types'

interface IBuscarProps extends React.PropsWithChildren {
  onChanged: (data) => void
  onSelection: (data) => void
  deletedItem: any,
  currentTab: string
  setCurrenTab?: (currentTab: string) => void
  onCancel?: () => void
  funcion: string
  resetSelected?: () => void
  resetFunction: () => void
  setToast: (sms: string, type: ToastTypes) => void
  playLoader: () => void
  stopLoader: () => void
}

const Buscar = (props: IBuscarProps) => {

  const { funcion, setCurrenTab, onChanged, resetFunction, setToast, stopLoader, playLoader, onSelection, deletedItem, currentTab, resetSelected } = props

  const formSearchRef = React.useRef<any>()
  const [codigo, setCodigo] = React.useState<string>('')
  const [descripcion, setDescripcion] = React.useState<string>('')
  const [data, setData] = React.useState<Array<LineaInventario>>([])
  const [items, setItems] = React.useState<Array<LineaInventario>>([])

  React.useEffect(() => {
    if (currentTab === 'Buscar') {
      if (funcion === 'Buscar') {
        handleSubmit()
      } else if (funcion === 'Eliminar') {
        deleteItemTable(deletedItem)
      }
      resetFunction()
    }

  }, [funcion])

  const deleteItemTable = (idCuenta) => {
    const filtrado = items.findIndex((item: any) => {
      return Number(item.codigo) === Number(idCuenta["codigo"]);
    });
    const rowsBefore = items.slice();
    if (filtrado > -1) {
      rowsBefore.splice(filtrado, 1);
      setItems(rowsBefore);
      resetSelected()
      setCurrenTab('Buscar')
      resetFunction()
    }
  }



  const handleSubmit = React.useCallback(async () => {
    setItems([])
    playLoader()
    try {
      const data_request = {
        particular: codigo,
        descripcion: descripcion
      }
      const lineas = await InventarioService.getLineas(data_request)
      stopLoader()
      if (lineas && lineas.length > 0) {
        // setToast('(' + data.length + ')' + ' Registros encontrados.', 'success')
        let item: Array<LineaInventario> = []
        item = lineas.map(function (item: any) {
          return {
            ...item,
            particular: String(item['particular'] ?? ''),
            descripcion: String(item['descripcion'] ?? ''),
            estadoNombre: String(item['estadoNombre'] ?? ''),
          }
        })
        setItems(item)
      }

      return await data
    } catch (error) {
      console.log(error)
      stopLoader()
      setToast(JSON.stringify(error), ToastTypes.Danger)
    }
  }, [codigo, descripcion])

  return (
    <>
      <fieldset>
        <ValidationGroup ref={formSearchRef}>

          <CRow className='row'>
            <div className="dx-field-label col-lg-2 col-md-3 col-sm-12">
              {'Código:'}
            </div>
            <CustomCol className='dx-field-value' xs="12" md="4">
              <CCol md="8" >
                <TextBox
                  name="codigo"
                  value={codigo ?? ''}
                  onValueChanged={({ value }: any) => setCodigo(value)}
                  onEnterKey={handleSubmit}
                >
                </TextBox>
              </CCol>
            </CustomCol>
          </CRow>
          <CRow className='row mt-2'>
            <div className="dx-field-label col-lg-2 col-md-3 col-sm-12">
              {'Descripción:'}
            </div>
            <CustomCol className='dx-field-value' xs="12" md="4">
              <CCol md="8" >
                <TextBox
                  name="descripcion"
                  value={descripcion ?? ''}
                  onValueChanged={({ value }: any) => setDescripcion(value)}
                  onEnterKey={handleSubmit}
                >
                </TextBox>
              </CCol>
            </CustomCol>
          </CRow>

          <ResultadoBusqueda
            funcion={''}
            data={items ?? []}
            setToast={setToast}
            capturaSeleccion={(data) => onSelection(data)}
            onChanged={onChanged}
          />

        </ValidationGroup>
      </fieldset>
    </>
  )
}
export default memo(Buscar)
