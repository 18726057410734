import React, { memo } from 'react'
import { ValidationGroup } from 'devextreme-react/validation-group'
import CustomCol from '../../../../../views/componentes/colContainer'
import TextBox from 'devextreme-react/text-box'
import ResultadoBusqueda from './resultadoBusqueda'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CCollapse,
  CForm,
  CLink,
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilChevronBottom, cilChevronTop } from '@coreui/icons'
import { CheckBox, RadioGroup } from 'devextreme-react'
import { adminLocales } from '../../service/locales.service'
import { TModalAdminLocales } from '../../store/types'
import { ToastTypes } from '../../../../../store/types'

const estadosGrupos = [
  {
    value: 0,
    label: 'Almacen',
  },
  {
    value: 1,
    label: 'Bodega general',
  },
  {
    value: 2,
    label: 'Bodega sucursales',
  },
]

interface IBuscarProps extends React.PropsWithChildren {
  onChanged: (data) => void
  onCancel?: () => void
  funcion: string
  resetFunction: () => void
  ejecutarBusqueda: () => void
  setToast: (sms: string, type: ToastTypes) => void
  playLoader: () => void
  stopLoader: () => void
}

const Buscar = (props: IBuscarProps) => {

  const formSearchRef = React.useRef<any>()
  const [nombre, setNombre] = React.useState<string>('')
  const [estados_rg, setEstados_rg] = React.useState<any>(estadosGrupos[0])
  const { onChanged, funcion, setToast, resetFunction, playLoader, stopLoader } = props
  const [items, setItems] = React.useState<Array<any>>([])
  const [seleccion, setSeleccion] = React.useState<any>({
    index: null,
    row: null,
    seleccionRow: null,
  })

  const onValueChangeEstados = React.useCallback((data) => {
    setEstados_rg(data.value)
  }, [])

  function capturarEnter(): void {
    throw new Error('Function not implemented.')
  }

  const handleSubmit = React.useCallback(async () => {
    console.log('handleSubmit boton click')
    // playLoader()

    try {
      const find = {
        estado: 1,
        mensaje: ''
      }
      const data = await adminLocales.getAdminLocales(find)
      console.log('data', data)
      // stopLoader()

      if (data.auto && data.auto.length > 0) {
        // setToast('(' + data.length + ')' + ' Registros encontrados.', 'success')
        let item: Array<TModalAdminLocales> = []
        item = data.auto.map(function (item: any) {
          return {
            codigo: parseInt(item?.localCodigo ?? 0),
            ciudadCodigo: String(item?.localCiudad ?? ''),
            ciudadNombre: String(item?.localCiudadNombre ?? ''),
            nombre: String(item?.localNombre ?? ''),
            direccion: String(item?.localDireccion ?? ''),
            telefono: String(item?.localTelefono ?? ''),
            gerente: String(item?.localGerente ?? ''),
            tipoCodigo: parseInt(item?.localTipo ?? 0),
            tipoDescripcion: String(item?.localTipoDescripcion ?? ''),
            claseCodigo: parseInt(item?.localClase ?? 0),
            claseDescripcion: String(item?.localClaseDescripcion ?? ''),
            perteneceCodigo: parseInt(item?.localPertenece ?? 0),
            perteneceDescripcion: String(item?.localPerteneceDescripcion ?? ''),
            estadoCodigo: parseInt(item?.localEstado ?? 0),
            estadoDescripcion: String(item?.localEstadoDescripcion ?? ''),
            kardex: parseInt(item?.localKardex ?? 0),
            siglas: String(item?.localSiglas ?? ''),
            siglasLocal: String(item?.localSiglasLocal ?? ''),
            web: parseInt(item?.localWEB ?? 0),
            uaf: String(item?.localUAF ?? ''),
            descuento: String(item?.localDescuento ?? ''),
            contrato: String(item?.localContrato ?? ''),
            observaciones: String(item?.localObservaciones ?? ''),
            usuarioDokan: String(item?.localUsuarioDokan ?? ''),
            passwordDokan: String(item?.localPasswordDokan ?? ''),
            ULOTipo: String(item?.ULOTipo ?? ''),
            logo: String(item?.localLogo ?? ''),
            listaPrecios: parseInt(item?.localListaPrecios ?? 0),
            establecimiento: String(item?.localEstablecimiento ?? '')
          }
        })
        console.log('item', item)
        setItems(item)
      }

      return await data
    } catch (error) {
      console.log(error)
      stopLoader()
      setToast(JSON.stringify(error), ToastTypes.Danger)
    }
  }, [resetFunction, playLoader, setToast, stopLoader])

  const onReset = React.useCallback(() => {
    resetFunction()
    setItems([])
  }, [resetFunction]);

  React.useEffect(() => {
    if (funcion === 'Buscar') {
      handleSubmit()
    } else if (funcion === 'Limpiar') {
      onReset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [funcion])

  return (
    <>
      <fieldset>
        <ValidationGroup ref={formSearchRef}>
          <CCard>
            <CCardHeader
              onClick={() => { }}
              className="d-flex justify-content-between"
            >
              <strong> {'Buscar'} </strong>
              <div className="card-header-actions">
                <CLink className="card-header-action">
                  <CIcon icon={CCollapse ? cilChevronBottom : cilChevronTop} />
                </CLink>
              </div>
            </CCardHeader>

            <CCardBody>
              <CForm className="row">
                <div className="dx-field-label col-lg-2 col-md-3 col-sm-12">
                  {'Clase:'}
                </div>
                <CustomCol className="dx-field-value mt-1">
                  <CCol lg="9" md="6" sm="12">
                    <RadioGroup
                      name="estado"
                      dataSource={estadosGrupos}
                      defaultValue={estadosGrupos[0]}
                      value={estados_rg}
                      layout="horizontal"
                      displayExpr="label"
                      onValueChanged={onValueChangeEstados}
                    />
                  </CCol>
                </CustomCol>
              </CForm>

              <CForm className="row">
                <div className="dx-field-label col-lg-2 col-md-3 col-sm-12">
                  {'Nombre:'}
                </div>
                <CustomCol className="dx-field-value" xs="12" md="4">
                  <CCol md="6">
                    <TextBox
                      name="nombre"
                      value={nombre ?? ''}
                      onValueChanged={({ value }: any) => setNombre(value)}
                      onEnterKey={() => capturarEnter()}
                    ></TextBox>
                  </CCol>
                </CustomCol>
              </CForm>
              <CForm className="row">
                <div className="dx-field-label col-lg-2 col-md-3 col-sm-12">
                  {'Estado:'}
                </div>
                <CustomCol className="dx-field-value mt-1">
                  <CCol lg="9" md="6" sm="12">
                    <CheckBox
                      text="Activos"
                      className='me-2'
                    // value={anulados_cx}
                    // onValueChanged={() => setAnulados_cx(!anulados_cx)}
                    />
                    <CheckBox
                      text="Sin Asignar"
                    // value={anulados_cx}
                    // onValueChanged={() => setAnulados_cx(!anulados_cx)}
                    />
                  </CCol>
                </CustomCol>
              </CForm>

              <ResultadoBusqueda
                funcion={''}
                data={items ?? []}
                setToast={setToast}
                capturaSeleccion={(data) => setSeleccion(data)}
                onChanged={onChanged}
              />
            </CCardBody>
          </CCard>
        </ValidationGroup>
      </fieldset>
    </>
  )
}
export default memo(Buscar)
