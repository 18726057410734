import React from 'react';
import { CCol, CRow } from '@coreui/react-pro'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import { Column, DataGrid, FilterRow, HeaderFilter } from 'devextreme-react/data-grid';
import { setSeleccionarDato } from '../../../store/modalProveedor';

interface TablaProps extends React.PropsWithChildren {
  name: string,
  data: any,
  actionDelete: () => void,
  exportExcel: () => void,
  origen: string,
  returnSeleccion: (data) => void
}

const Tabla = (props: TablaProps) => {

  const seleccionado = useSelector((state: RootState) => state.proveedores.proveedores.modalProveedores.seleccionado);

  const dispatch = useDispatch()

  const data = props.data;

  const desSeleccionarRegistro = () => {
    dispatch(setSeleccionarDato(
      {
        index: null,
        row: null,
        selectedRow: null
      }
    ))
  }

  const onSelectionChanged = (selectedRowsData) => {
    const data = selectedRowsData['selectedRowsData'][0];
    dispatch(setSeleccionarDato(
      {
        index: 0,
        row: data,
        selectedRow: 0
      }
    ))
  }

  const onRowDblClick = (onRowDblClick) => {
    if (props.origen === 'modal') {
      const dataRow = onRowDblClick['key'];
      props.returnSeleccion(dataRow)
    }
  }

  return (
    <>
      <CRow>
        <CCol className="d-flex justify-content-end">
          {data.length}
        </CCol>
      </CRow>
      <DataGrid
        selection={{ mode: 'single' }}
        hoverStateEnabled={true}
        dataSource={data}
        showBorders={true}
        showRowLines={true}
        onSelectionChanged={onSelectionChanged}
        onRowDblClick={onRowDblClick}
      >
        <FilterRow visible={true}
        />
        <HeaderFilter visible={true} />
        <Column dataType="string" dataField='identificacion' caption='Identificación' width='20%' />
        <Column dataType="string" dataField='nombre' caption='Nombre' width='20%' />
        <Column dataField='razonComercial' caption='R.Comercial' width='20%' />
        <Column dataField='telefono' caption='Telefono' width='20%' />
      </DataGrid>
    </>
  );
}

export default Tabla;