import { FETCH_STATUS, LoaderInfo } from "../../../../../../../../../../store/types"
import { ButtonTypes } from "../../../../../../../../../../views/componentes/globalMenu/types"
import { CustomDictionaryImpuestos } from "../../../../../../../../../impuestos/types/types"
import { StatesEdition, TabTypes } from "../../../../../../../../types/enums"
import { CustomButtons } from "../../../../../../../../types/generics"
import { MenuState } from "../../../../../../../../types/types"
import { ComprasType, ComprobanteDte, DatosAnuladas, DatosDetalleRetenciones, DatosRetenciones, DatosVenta, DatosVentaEstablecimiento, DteOption, DteType } from "../../../types/types"
import { TabConfigAnexo } from "../../component/anexo/anexo"

export type WindowLibrosTransaccionalState<T, K> = {
  menu: MenuState
  tabs: TabsStateLibroTransaccional<T>
  search: SearchStateLibroTransaccional
}

export type LibroTransaccionalState = WindowLibrosTransaccionalState<DatosVenta, AnexoTransacDatosEdicion> & {}

export type TabsStateLibroTransaccional<T> = {
  tabs: CustomDictionaryImpuestos<TabStateLibroTransaccional<T>>
  current: string
}

export type DocumentInfoOrdenTransaccional<T> = {
  codigo: number
  info?: T
}

export type TabStateLibroTransaccional<T> = {
  id: number
  tittle: string
  type: TabTypes
  info?: DocumentInfoOrdenTransaccional<T>
  canClose: boolean
  editStatus: StatesEdition
  /**Controla los botones presionados fuera del tab (Un menu por ejemplo) y se guarda aquí el ultimo botone presionado,
   * una vez controlada la accion se deberia dejar como ButtonTypes.none
   */
  globalButtonClick: ButtonTypes
  imprimir: boolean
  /** Identificador del tab, debe ser unico e identifica el tab entre los demás */
  tabKey: string
  /** Botones que se van a controlar y su estado.
   * Los botones que no aparezcan aquí se marcan como desabilitados / no usados y podrian
   * no aparecer en ciertas pantallas.
   */
  buttons: CustomButtons
  currentExecutingAction?: ButtonTypes
  templateMode?: boolean
}

export type SearchStateLibroTransaccional = {
  filter: FiltroBusquedaLibroTransaccionalState
  status: FETCH_STATUS
  mensaje: string
  resultados: ComprobanteDte[] | []
  finalizaBusqueda: boolean
}

export type FiltroBusquedaLibroTransaccionalState = {
  fechaInicio: string
  fechaFinal: string
  dte: DteType
  dteOptions: DteOption[]
  loader: LoaderInfo
  tabActive: TiposLibrosSLV
  tabs: TabConfigLibro[]
  consumidorFinal: []
  contribuyentes: []
  resumen: []
  exportaciones: []
}

export type TabConfigLibro = {
  visible: boolean
  title: string
  code: TiposLibrosSLV
}

export enum TiposLibrosSLV {
  ConsumidorFinal = 'ConsumidorFinal',
  Contribuyentes = 'Contribuyentes',
  Resumen = 'Resumen',
}

export type AnexoTransacDatosEdicion = {
  codigo: number
  ruc: string
  loader: LoaderInfo
  imprimir: boolean
  loading: boolean
  tieneError: boolean
  mensajeError: string
  compras: ComprasType[]
  consumidorFinal: DatosVenta[]
  creditoFiscal: DatosVenta[]
  exportacion: DatosVenta[]
  sujetoExcluido: DatosVenta[]
  notasCredito: DatosVenta[]
  notasDebito: DatosVenta[]
  notasRemision: DatosVenta[]
  liquidaciones: DatosVenta[]
  liquidacionesContables: DatosVenta[]
  donaciones: DatosVenta[]
  retenciones: DatosRetenciones[]
  detalleRetenciones: DatosDetalleRetenciones[]
  ventasEstablecimiento: DatosVentaEstablecimiento[]
  anulados: DatosAnuladas[]
}