/* eslint-disable no-undef */

import { isMobile } from 'react-device-detect'
import { RequestHelper } from './requestHelper'
import { CapitalizeMode } from '../store/types'
import { ECountry } from '../store/enum/countries'
import { v4 as uuidv4 } from 'uuid'

/**
 * Función para ordenar un arreglo de objetos JSON por una clave específica.
 * @param data - Arreglo de objetos JSON.
 * @param key - Clave por la cual se desea ordenar los objetos.
 * @param orden - Orden de clasificación ('asc' para ascendente, 'desc' para descendente).
 * @returns El arreglo de objetos JSON ordenado.
 */
function sortJSON(data, key, orden: 'asc' | 'desc') {
  return data.sort(function (a: number, b: number) {
    const x = a[key],
      y = b[key]

    if (orden === 'asc') {
      return x < y ? -1 : x > y ? 1 : 0
    }

    if (orden === 'desc') {
      return x > y ? -1 : x < y ? 1 : 0
    }
  })
}

/**
 * Función asincrónica que pausa la ejecución durante un tiempo especificado en milisegundos.
 * @param ms El tiempo en milisegundos durante el cual se pausará la ejecución.
 */
async function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

/**
 * Obtiene la fecha actual del servidor como una cadena de texto.
 * @returns La fecha actual del servidor.
 * @throws Error si hay algún error al obtener la fecha.
 */

async function getServerCurrentDateAsString() {
  try {
    const obj = {}
    const data = await RequestHelper.get<any>('getDate', '', '', obj)
    return data
  } catch (error) {
    return error
  }
}
/**
 * Función que devuelve una copia no congelada de un objeto genérico.
 * Si el objeto es un array, se crea una copia de cada elemento.
 * Si es un string, se crea una nueva instancia del string.
 * Si es un objeto, se crea una copia de cada propiedad.
 */
function unfreeze<T>(o: T): T {
  let oo: any = undefined
  if (o instanceof Array) {
    oo = []
    const clone = function (v) {
      oo.push(v)
    }
    o.forEach(clone)
  } else if (o instanceof String) {
    // eslint-disable-next-line no-new-wrappers
    oo = new String(o).toString()
  } else if (typeof o == 'object') {
    oo = {}
    for (const property in o) {
      oo[property] = o[property]
    }
  }
  return oo as T
}
/**
 * Función que verifica si se puede utilizar la API de red.
 *
 * Comprueba el valor de la variable de entorno REACT_APP_USE_APINET.
 * Si el valor es "false", devuelve false; de lo contrario, devuelve true.
 *
 * @returns {boolean} Indica si se puede utilizar la API de red.
 */
function canUseNetApi() {
  const canUse =
    (process.env['REACT_APP_USE_APINET'] ?? 'false') === 'false' ? false : true
  return canUse
}
/**
 * Filtra las teclas no numéricas.
 * Evita que se ingresen caracteres no numéricos en un campo de entrada.
 * @param e El evento de teclado.
 */
function filtraTeclasNoNumericas(e) {
  if (
    !(
      (e.event.keyCode >= 96 && e.event.keyCode <= 107) ||
      (e.event.keyCode >= 48 && e.event.keyCode <= 57)
    )
  ) {
    if (
      e.event.keyCode !== 8 &&
      e.event.keyCode !== 9 &&
      e.event.keyCode !== 46 &&
      e.event.keyCode !== 37 &&
      e.event.keyCode !== 39 &&
      e.event.keyCode !== 17 &&
      e.event.keyCode !== 86 &&
      e.event.keyCode !== 13 // Habilita presionar el boton enter
    ) {
      e.event?.preventDefault()
    }
  }
}
/**
 * Función que filtra las teclas permitidas en base a un arreglo de códigos de tecla.
 * Si la tecla presionada no se encuentra en el arreglo de teclas permitidas, se previene su comportamiento por defecto.
 *
 * @param e El evento de teclado.
 * @param teclas Arreglo de códigos de tecla permitidos.
 */
function filtrarTeclas(e, teclas: Array<number>) {
  if (teclas.findIndex((x) => x === e.event.keyCode) !== -1) {
    e.event?.preventDefault()
  }
}
/**
 * Filtra las teclas dentro de un rango específico y previene su comportamiento predeterminado.
 * @param e - Evento del teclado.
 * @param rangos - Array de objetos que contienen los rangos mínimo y máximo de las teclas a filtrar.
 */
function filtrarTeclasRangos(e, rangos: Array<{ min: number; max: number }>) {
  const keyCode = e.event.keyCode
  for (const rango of rangos) {
    if (keyCode >= rango.min && keyCode <= rango.max) {
      e.event?.preventDefault()
      return
    }
  }
}
/**
 * Retorna el ancho completo de la cuadrícula.
 * Si es un dispositivo móvil, devuelve el 80% del ancho de la ventana.
 * De lo contrario, devuelve el valor '100%'.
 */
function getGridFullWidth() {
  if (isMobile) {
    return window.innerWidth * 0.8
  } else {
    return '100%'
  }
}
/**
 * Filtra las teclas no numéricas y la tecla "D" en un evento de teclado.
 * @param e El evento de teclado.
 */
function filtraTeclasNoNumericasAndKeyD(e) {
  if (
    !(
      (e.event.keyCode >= 96 && e.event.keyCode <= 107) ||
      (e.event.keyCode >= 48 && e.event.keyCode <= 57)
    )
  ) {
    if (
      e.event.keyCode !== 8 &&
      e.event.keyCode !== 9 &&
      e.event.keyCode !== 46 &&
      e.event.keyCode !== 37 &&
      e.event.keyCode !== 39 &&
      e.event.keyCode !== 68 &&
      e.event.keyCode !== 13 &&
      e.event.keyCode !== 190 &&
      e.event.keyCode !== 188 &&
      e.event.keyCode !== 110
    ) {
      e.event?.preventDefault()
    }
  }
}

// complete zero at left|right
/**
 * Función para rellenar un número con ceros a la izquierda o a la derecha.
 *
 * @param number El número que se desea rellenar con ceros.
 * @param width La cantidad de caracteres que se desea tener en total.
 * @param pos La posición en la que se desea rellenar los ceros ('left' o 'right').
 * @returns El número rellenado con ceros.
 */
function zeroFill(number, width, pos: 'left' | 'right' = 'left') {
  try {
    width -= number.toString().length
    if (width > 0) {
      if (pos === 'left') {
        return new Array(width + (/\./.test(number) ? 2 : 1)).join('0') + number
      } else if (pos === 'right') {
        const data = new Array(width + (/\./.test(number) ? 2 : 1)).join('0')
        return number + data
      }
    }
    return number + '' // siempre devuelve tipo cadena
  } catch {
    return number
  }
}
/**
 * Esta función toma una ruta de archivo como entrada y devuelve la extensión del archivo.
 * Si la ruta no contiene una extensión, la función devuelve una cadena vacía.
 */
function getExtension(path) {
  const basename: any = path.split(/[\\/]/).pop(), // extract file name from full path ...
    // (supports `\\` and `/` separators)
    pos = basename.lastIndexOf('.') // get last position of `.`
  if (basename === '' || pos < 1)
    // if file name is empty or ...
    return '' //  `.` not found (-1) or comes first (0)
  return basename.slice(pos + 1) // extract extension ignoring `.`
}
/**
 * Verifica si el dispositivo se encuentra en El Salvador utilizando la geolocalización.
 * @returns Una promesa que se resuelve en verdadero si el dispositivo se encuentra en El Salvador, de lo contrario se resuelve en falso.
 */
function isInElSalvador(): Promise<boolean> {
  return new Promise<boolean>((resolve) => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          const latitude = position.coords.latitude
          const longitude = position.coords.longitude
          const limitNorth = 14.44
          const southLimit = 13.16
          const limitEast = -87.69
          const westLimit = -90.1

          const isInside =
            latitude >= southLimit &&
            latitude <= limitNorth &&
            longitude >= westLimit &&
            longitude <= limitEast

          resolve(isInside)
        },
        function () {
          resolve(false) // En caso de error al obtener la ubicación
        },
      )
    } else {
      resolve(false) // Si la geolocalización no está disponible
    }
  })
}

function isPathLocationSV(): Promise<boolean> {
  return Promise.resolve(
    Number(process.env['REACT_APP_COUNTRY']) === Number(ECountry.ElSalvador),
  )
}

/**
 * Convierte un número en su representación en letras en español.
 * @param num El número a convertir.
 * @param currency Opciones para especificar la moneda.
 * @returns La representación en letras del número.
 */

const numeroALetras = (function () {
  // Código basado en el comentario de @sapienman
  // Código basado en https://gist.github.com/alfchee/e563340276f89b22042a
  function Unidades(num) {
    switch (num) {
      case 1:
        return 'UN'
      case 2:
        return 'DOS'
      case 3:
        return 'TRES'
      case 4:
        return 'CUATRO'
      case 5:
        return 'CINCO'
      case 6:
        return 'SEIS'
      case 7:
        return 'SIETE'
      case 8:
        return 'OCHO'
      case 9:
        return 'NUEVE'
    }

    return ''
  } //Unidades()

  function Decenas(num) {
    const decena = Math.floor(num / 10)
    const unidad = num - decena * 10

    switch (decena) {
      case 1:
        switch (unidad) {
          case 0:
            return 'DIEZ'
          case 1:
            return 'ONCE'
          case 2:
            return 'DOCE'
          case 3:
            return 'TRECE'
          case 4:
            return 'CATORCE'
          case 5:
            return 'QUINCE'
          default:
            return 'DIECI' + Unidades(unidad)
        }
      case 2:
        switch (unidad) {
          case 0:
            return 'VEINTE'
          default:
            return 'VEINTI' + Unidades(unidad)
        }
      case 3:
        return DecenasY('TREINTA', unidad)
      case 4:
        return DecenasY('CUARENTA', unidad)
      case 5:
        return DecenasY('CINCUENTA', unidad)
      case 6:
        return DecenasY('SESENTA', unidad)
      case 7:
        return DecenasY('SETENTA', unidad)
      case 8:
        return DecenasY('OCHENTA', unidad)
      case 9:
        return DecenasY('NOVENTA', unidad)
      case 0:
        return Unidades(unidad)
    }
  } //Unidades()

  function DecenasY(strSin, numUnidades) {
    if (numUnidades > 0) return strSin + ' Y ' + Unidades(numUnidades)

    return strSin
  } //DecenasY()

  function Centenas(num) {
    const centenas = Math.floor(num / 100)
    const decenas = num - centenas * 100

    switch (centenas) {
      case 1:
        if (decenas > 0) return 'CIENTO ' + Decenas(decenas)
        return 'CIEN'
      case 2:
        return 'DOSCIENTOS ' + Decenas(decenas)
      case 3:
        return 'TRESCIENTOS ' + Decenas(decenas)
      case 4:
        return 'CUATROCIENTOS ' + Decenas(decenas)
      case 5:
        return 'QUINIENTOS ' + Decenas(decenas)
      case 6:
        return 'SEISCIENTOS ' + Decenas(decenas)
      case 7:
        return 'SETECIENTOS ' + Decenas(decenas)
      case 8:
        return 'OCHOCIENTOS ' + Decenas(decenas)
      case 9:
        return 'NOVECIENTOS ' + Decenas(decenas)
    }

    return Decenas(decenas)
  } //Centenas()

  function Seccion(num, divisor, strSingular, strPlural) {
    const cientos = Math.floor(num / divisor)
    const resto = num - cientos * divisor

    let letras = ''

    if (cientos > 0)
      if (cientos > 1) letras = Centenas(cientos) + ' ' + strPlural
      else letras = strSingular

    if (resto > 0) letras += ''

    return letras
  } //Seccion()

  function Miles(num) {
    const divisor = 1000
    const cientos = Math.floor(num / divisor)
    const resto = num - cientos * divisor

    const strMiles = Seccion(num, divisor, 'UN MIL', 'MIL')
    const strCentenas = Centenas(resto)

    if (strMiles == '') return strCentenas

    return strMiles + ' ' + strCentenas
  } //Miles()

  function Millones(num) {
    const divisor = 1000000
    const cientos = Math.floor(num / divisor)
    const resto = num - cientos * divisor

    const strMillones = Seccion(num, divisor, 'UN MILLON DE', 'MILLONES DE')
    const strMiles = Miles(resto)

    if (strMillones == '') return strMiles

    return strMillones + ' ' + strMiles
  } //Millones()

  return function NumeroALetras(num, currency) {
    currency = currency || {}
    const data = {
      numero: num,
      enteros: Math.floor(num),
      centavos: Math.round(num * 100) - Math.floor(num) * 100,
      letrasCentavos: '',
      letrasMonedaPlural: currency.plural || 'PESOS CHILENOS', //'PESOS', 'Dólares', 'Bolívares', 'etcs'
      letrasMonedaSingular: currency.singular || 'PESO CHILENO', //'PESO', 'Dólar', 'Bolivar', 'etc'
      letrasMonedaCentavoPlural: currency.centPlural || 'CHIQUI PESOS CHILENOS',
      letrasMonedaCentavoSingular:
        currency.centSingular || 'CHIQUI PESO CHILENO',
    }

    if (data.centavos > 0) {
      data.letrasCentavos =
        'CON ' +
        (function () {
          if (data.centavos == 1)
            return (
              Millones(data.centavos) + ' ' + data.letrasMonedaCentavoSingular
            )
          else
            return (
              Millones(data.centavos) + ' ' + data.letrasMonedaCentavoPlural
            )
        })()
    }

    if (data.enteros == 0)
      return 'CERO ' + data.letrasMonedaPlural + ' ' + data.letrasCentavos
    if (data.enteros == 1)
      return (
        Millones(data.enteros) +
        ' ' +
        data.letrasMonedaSingular +
        ' ' +
        data.letrasCentavos
      )
    else
      return (
        Millones(data.enteros) +
        ' ' +
        data.letrasMonedaPlural +
        ' ' +
        data.letrasCentavos
      )
  }
})()
/**
 * Formatea un número decimal con la cantidad de decimales especificada.
 * @param value El número a formatear.
 * @param decimals La cantidad de decimales a mostrar.
 * @returns El número formateado.
 */
const formatDecimal = (value: number, decimals: number) => {
  return Number(Math.round(Number(value + 'e' + decimals)) + 'e-' + decimals)
}
/**
 * Este código convierte todas las palabras en una cadena de texto en mayúsculas,
 * manteniendo la letra inicial de cada palabra en mayúscula y el resto de las letras en minúscula.
 * Luego, une todas las palabras en una sola cadena y la devuelve.
 */
function upperAllInit(text: string) {
  const words = text.split(' ')
  const capitalizedWords = words.map((word) => {
    const firstLetter = word.charAt(0).toUpperCase()
    const restOfWord = word.slice(1).toLowerCase()
    return firstLetter + restOfWord
  })
  return capitalizedWords.join(' ')
}
/**
 * Esta función reemplaza las variables dinámicas en un mensaje con los valores proporcionados.
 * @param message El mensaje que contiene las variables a reemplazar.
 * @param replace Un objeto que contiene los valores de reemplazo para las variables.
 * @returns El mensaje actualizado con las variables reemplazadas.
 */
function getMessageValidation(message, replace) {
  let sms: string = message
  if (sms.includes('@dinamic_var@') && replace['label'] !== undefined) {
    sms = sms.replaceAll('@dinamic_var@', replace['label'] ?? '')
  }
  if (sms.includes('@minChar@') && replace['minChar'] !== undefined) {
    sms = sms.replaceAll('@minChar@', replace['minChar'] ?? 1)
  }
  if (sms.includes('@maxChar@') && replace['maxChar'] !== undefined) {
    sms = sms.replaceAll('@maxChar@', replace['maxChar'] ?? 250)
  }
  return sms
}
/**
 * Función para capitalizar un texto según el modo especificado.
 * @param text El texto a capitalizar.
 * @param modo El modo de capitalización (por defecto: CapitalizeMode.upperFirst).
 * @returns El texto capitalizado.
 */
function capText(
  text: string,
  modo: CapitalizeMode = CapitalizeMode.upperFirst,
): string {
  if (text && text.length > 0) {
    let t: string = ''
    switch (modo) {
      case CapitalizeMode.upper:
        t = text.toUpperCase()
        break
      case CapitalizeMode.lower:
        t = text.toLowerCase()
        break
      case CapitalizeMode.upperAllInit:
        t = upperAllInit(text)
        break
      case CapitalizeMode.upperFirst:
        t = text.toLowerCase()
        t = t.charAt(0).toUpperCase() + t.slice(1)
        break
      default:
        break
    }

    return t
  } else {
    return ''
  }
}
/**
 * Convierte una cadena de texto en un número.
 * Reemplaza la coma por un punto y utiliza parseFloat para realizar la conversión.
 * @param cadena La cadena de texto que se desea convertir.
 * @returns El número resultante.
 */
function stringToNumber(cadena: string): number {
  const cadenaSinComa = cadena.replace(',', '.')
  const numero = parseFloat(cadenaSinComa)
  return numero
}
/**
 * Esta función devuelve la parte de texto después del último punto en una cadena de texto.
 *
 * @param text La cadena de texto de la cual se desea obtener la parte después del último punto.
 * @returns La parte de texto después del último punto.
 */
function getTextAfterLastDot(text: string): string {
  const lastDot = text.lastIndexOf('.')
  return text.substring(lastDot + 1)
}

//crea una funcion que devuelva la ultima parte de un texto despues de  ]
/**
*Esta funcion devuelve la parte de texto despues del ultimo corchete en una cadena de texto.

#@param text La cadena de texto de la cual se desea obtener la parte despues del ultimo corchete.
#@returns La parte de texto despues del ultimo corchete.
*/
function getTextAfterLastBracket(text: string): string {
  const lastBracket = text.lastIndexOf(']')
  return text.substring(lastBracket + 1)
}
/**
 * Función que devuelve el inicio y fin de un mes dado un mes y año.
 * @param mes Número del mes (1-12).
 * @param anio Año.
 * @returns Objeto con la fecha de inicio y fin del mes.
 */
function obtenerInicioFinMes(
  mes: number,
  anio: number,
): { inicio: Date; fin: Date } {
  const fechaInicio = new Date(anio, mes - 1, 1)
  const fechaFin = new Date(anio, mes, 0)
  return { inicio: fechaInicio, fin: fechaFin }
}

function hanTranscurrido24Horas(desde: string): boolean {
  const fechaInicial = new Date(desde) // Convertir la fecha inicial a un objeto Date
  const fechaActual = new Date() // Obtener la fecha actual

  // Calcular la diferencia en milisegundos entre la fecha actual y la fecha inicial
  const diferencia = fechaActual.getTime() - fechaInicial.getTime()

  // Convertir la diferencia a horas y verificar si es mayor a 24 horas
  return diferencia > 24 * 60 * 60 * 1000 // 24 horas en milisegundos
}

function hanTranscurrido90Dias(desde: string): boolean {
  const fechaInicial = new Date(desde) // Convertir la fecha inicial a un objeto Date
  const fechaActual = new Date() // Obtener la fecha actual

  // Calcular la diferencia en milisegundos entre la fecha actual y la fecha inicial
  const diferencia = fechaActual.getTime() - fechaInicial.getTime()

  // Convertir la diferencia a días y verificar si es mayor a 90 días
  const diasTranscurridos = diferencia / (1000 * 60 * 60 * 24) // 1 día en milisegundos
  return diasTranscurridos > 90
}

function eliminarGuiones(cadena: string): string {
  // Verificar si la cadena contiene el carácter "-"
  if (cadena.includes('-')) {
    // Utilizar una expresión regular para eliminar todos los guiones
    cadena = cadena.replace(/-/g, '')
  }
  return cadena
}

function contarLetraN(texto: string): boolean {
  // Convertimos el texto a mayúsculas usando el método toUpperCase()
  const textoEnMayusculas = texto.toUpperCase()
  // Verificamos si el carácter 'N' está presente en el texto en mayúsculas
  const contieneN = textoEnMayusculas.includes('N')
  return contieneN
}

type AnyObject = { [key: string]: any }

function containsKeyVerificate(
  obj: AnyObject,
  searchString: string = 'codActividad',
): boolean {
  if (typeof obj !== 'object' || obj === null) {
    return false
  }

  for (const key in obj) {
    if (typeof obj[key] === 'string' && obj[key].includes(searchString)) {
      return true
    }
    if (
      typeof obj[key] === 'object' &&
      containsKeyVerificate(obj[key], searchString)
    ) {
      return true
    }
  }

  return false
}

function getTextAfterLastSlash(path: string): string {
  // Usa el método split para dividir la cadena en partes por cada "/"
  const parts = path.split('/')
  // Devuelve la última parte del array resultante
  return parts[parts.length - 1]
}

function cleanUrl(url: string): string {
  // Utiliza una expresión regular para reemplazar // adicionales con /
  return url.replace(/([^:]\/)\/+/g, '$1')
}

function getUUID(): string {
  return uuidv4()
}

function generarClave(): string {
  const caracteresMinusculas = 'abcdefghijklmnopqrstuvwxyz'
  const caracteresMayusculas = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  const numeros = '0123456789'
  const simbolos = '!@#$%^&*()_+[]{}|;:,.<>?'

  // Función para obtener un carácter aleatorio de una cadena
  const obtenerCaracterAleatorio = (cadena: string): string => {
    return cadena[Math.floor(Math.random() * cadena.length)]
  }

  // Crear una clave asegurando que cumpla con todos los requisitos
  let clave = ''

  // Asegurar que contiene al menos un dígito, una minúscula, una mayúscula y un símbolo
  clave += obtenerCaracterAleatorio(caracteresMinusculas)
  clave += obtenerCaracterAleatorio(caracteresMayusculas)
  clave += obtenerCaracterAleatorio(numeros)
  clave += obtenerCaracterAleatorio(simbolos)

  // Completar el resto de la contraseña hasta alcanzar al menos 8 caracteres
  const todosCaracteres =
    caracteresMinusculas + caracteresMayusculas + numeros + simbolos
  const longitudClave = 8 // Entre 8  caracteres

  for (let i = clave.length; i < longitudClave; i++) {
    clave += obtenerCaracterAleatorio(todosCaracteres)
  }

  // Mezclar los caracteres para evitar que siempre comiencen en un orden predecible
  clave = clave
    .split('')
    .sort(() => 0.5 - Math.random())
    .join('')

  return clave
}

export const utilidades = {
  getUUID,
  sleep,
  getTextAfterLastBracket,
  unfreeze,
  getServerCurrentDateAsString,
  canUseNetApi,
  filtraTeclasNoNumericas,
  getGridFullWidth,
  filtrarTeclas,
  filtrarTeclasRangos,
  filtraTeclasNoNumericasAndKeyD,
  sortJSON,
  zeroFill,
  getExtension,
  getMessageValidation,
  capText,
  stringToNumber,
  formatDecimal,
  numeroALetras,
  isInElSalvador,
  isPathLocationSV,
  getTextAfterLastDot,
  obtenerInicioFinMes,
  hanTranscurrido24Horas,
  eliminarGuiones,
  contarLetraN,
  containsKeyVerificate,
  getTextAfterLastSlash,
  cleanUrl,
  hanTranscurrido90Dias,
  generarClave,
}
