import { TabsState } from '../../../../../store/genericTabTypes'
import { LoaderInfo } from '../../../../../store/types'
import {
  TOptionGenerico,
  TOptionGenericoString,
} from '../../../../clientes/pages/clientes/types/types'
import { LocalOption } from '../../../../componentes/localesLookUp/store/type'
import { ItemPedido } from '../../../../ventas/pages/ordenes/pages/ordenPedido/type/types'
import { GrupoInventario, MarcaInventario } from '../../../store/types'

export type GeneralState = {
  modulo: string
  currentAction: string
  currentFunction: string
  aplicacion: string
  loader: LoaderInfo
  collapse: boolean
}

export type KardexList = {
  codigo: number
  descripcion: string
  fechaInicio: string
  fechaFin: string
  titulo: string
  imagen: string
  permanente: number
  tipoId: TOptionGenerico | null
  mostrarPopup: number
  imagenCodificada: string
}

export type KardexListResult = {
  codigo: number
  tipo: number
  movimientoCodigo: number
  documento: number
  generado: string
  registro: string
  itemCodigo: number
  itemCodigoUsuario: string
  barras: string
  descripcion: string
  loteCodigo: number
  local: number
  localNombre: string
  kardexDescripcion: string
  transferenciaCantidad: number
  transferenciaPrecioUnitario: number
  cantidad: number
  precioUnitario: number
  saldo: number
  costo: number
  totalX: number
  totalIE: number
  procedencia: string
  fechaCompra: string
  ccompra: string
  ccosto: string
  lineaDescripcion: string
  unidadSiglas: string
  unidadNombre: string
  cantIng?: number
  precioUniIng?: number
  valorIng?: number
  cantEgr?: number
  precioUniEgr?: number
  valorEgr?: number
}

export type KardexListReserve = {
  codigo: number
  registro: string
  descripcion: string
  cantidadI: number
  cantidadE: number
  saldo: number
  cantidadX: number
  procedencia: string
  unidadNombre: string
}

export type KardexState = {
  general: GeneralState
  tabs: TabsState<KardexList>
  search: SearchState<any>
}

export type SearchState<T> = {
  filter: SearchFilter
  resultItem: Array<T>
  resultAll: Array<T>
  resultReserve: Array<T>
  tabs: Array<string>
  currentTab: string
  existenciaMin: number
  existenciaMax: number
  costo: number
  saldoReservas: number
  saldoFecha: number
  codeSelectedKardex: number
}

export type SearchFilter = {
  codigo: number
  barras: string
  descripcion: string
  local: LocalOption
  fechaDesde: string
  fechaHasta: string
  visualizarBodega: boolean
  stock: TOptionGenericoString
  grupo: GrupoInventario
  marca: MarcaInventario
  locales: Array<{ codigo: number; nombre: string }>
  itemLookup: ItemPedido
}

export type SearchKardex = {
  itemCodigo: number
  localCodigo: number
  fechaDesde: string
  fechaHasta: string
  stock: string
  marcaCodigo?: number
  grupoCodigo?: number
  locales?: Array<{ codigo: number; nombre: string }>
}

export type PAdanuales = {
  codigo: number
  itemCodigo: number
  itemBarras: string
  descripcion: string
  cantidad: number
  registro: string
  pedimento: string
  ingreso: string
  aduana: number
  aduanaNombre: string
  estado: number
  estadoDescripcion: string
}

export type LotesKardex = {
  codigo: number
  itemCodigo: number
  numero: string
  barras: string
  fecha: string
  cantidad: number
  caducidad: string
  ubicado: string
  existencia: number
}

export type SearchItem = {
  local: number
  barras: string
  fecha: string
}
export type ProveedorKdx = {
  codigo: number
  tipoIdentificacion: string
  identificacion: string
  idString: string
  tributario: string
  nombre: string
  razonComercial: string
  direccion: string
  telefono: string
  fax: string
  celular: string
  email: string
  ubicacion: number
  ciudadNombre: string
  clasificacion: number
  diasCredito: number
  limiteCredito: number
  descuento: number
  atencionCompras: string
  atencionPagos: string
  saldo: number
  comprasAnuales: number
  fechaUltimoPago: string
  documentoUltimoPago: string
  montoPago: number
  fechaUltimaCompra: string
  documentoUltimaCompra: string
  montoCompra: number
  observaciones: string
  web: string
  tipoEmpresa: number
  especialidad: number
  tipoAgenteCodigo: number
  seguros: number
  artesanos: number
  tipoCodigo: string
  relacionado: string
  estado: number
  placa: string
  intervalo: number
  diaReposicion: string
  transportista: number
  fechaRegistro: string
}

export type RebuildKdx = {
  itemCodigo: number
  fechaDesde: string
}


export const optionsStock = [
  { value: '', label: 'Todos' },
  { value: 'SI', label: 'Con Stock' },
  { value: 'NO', label: 'Sin Stock' },
]
