import {combineReducers} from "redux";
import {TTransporte} from "./types";
import {menuReducer} from "./menu.reducer";
import {tabsReducer} from "./tabs.reducer";
import {dataEditionReducer} from "./edit.reducer";
import {searchReducer} from "./search.reducer";

export const CatalogosTransporteReducer = combineReducers<TTransporte>({
  menu: menuReducer,
  tabs: tabsReducer,
  editData: dataEditionReducer,
  search: searchReducer,
})
