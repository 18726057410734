import React from 'react';
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow, CButton, CTooltip
} from '@coreui/react-pro'
import Labelgroup from '../../../../../../views/componentes/Labelgroup'
import { cuentasService } from "../../services/cuentas.service"
import { cuentasService as planDeCuentas } from "../../../planCuentas/services/cuentas.service"
import { ToastTypes } from '../../../../../../store/types';
import { useDispatch, useSelector } from 'react-redux'
import { setCargarCuentaContable, setCurrentFunction, setAsiento, setAsientoApi, setCurrentAccion, setResetSeleccion, setDetalleAsiento, setNumeroAsientoActual, setResetDetalleAsiento, changeLoaderDiario } from "../../store/diarioReducer"
import { setData as setCuentasPlan, setDataBack as setCuentasPlanBack } from "./../../../planCuentas/modalCuentas/store/modalCuentasReducer"
import { RootState } from '../../../../../../store/store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setModal } from "../../../planCuentas/store/cuentasReducer"
import Detalle from "../tabladetalle"
import Modalform from "../../../../../../views/componentes/modalform"
import { Asiento, AsientoApi, AsientoPeriodo, AsientoProyecto, DetalleAsiento } from '../../../../store/types';
import ValidationGroup from 'devextreme-react/validation-group';
import { DateBox, Button as DateBoxButton } from 'devextreme-react/date-box';
import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box';
import { NumberBox } from 'devextreme-react/number-box';
import { CheckBox } from 'devextreme-react/check-box';
import { DateUtils, formatoFechasApi } from '../../../../../../helpers/dateUtils';
import RadioGroup from "devextreme-react/radio-group";
import { Validator, RequiredRule, AsyncRule } from "devextreme-react/validator";
import { TextArea } from 'devextreme-react/text-area';
import ValidationSummary from "devextreme-react/validation-summary";
import { setPeriodosContables } from "./../../../../general/store/generalReducers"
import { Lookup } from 'devextreme-react/lookup';
import { TIPOS_BOTONES } from '../../store/types';
import { v4 as uuidv4 } from 'uuid';
import { GlobalLabel } from '../../../../../../views/componentes/globalLabel/globalLabel';
import ProjectsLookUp from '../../../../../../containers/component/sharedProviders/projets/projectsLookUp/projectsLookUp';
import { Local, Project } from '../../../../../shared/types/types';
import { consoleService } from '../../../../../../services/console.service';
import { atFocus } from '../../../../../../helpers/Helper';

type EditarProps = {
  currentAction?: string
  origenModulo: number
  setToast: (text: string, type: string | ToastTypes) => void
  playLoader: () => void
  stopLoader: () => void
}

const operacionesGrupos = [
  {
    value: 0,
    label: "Debe",
  },
  {
    value: 1,
    label: "Haber",
  },
];

const Editar = (props: EditarProps) => {
  const { currentAction, setToast, playLoader, stopLoader } = props

  const dispatch = useDispatch()

  const inputValor = React.useRef<any>()
  const inputRefDesc = React.useRef<any>()
  const inputNumCuenta = React.useRef<any>()
  const validationGroupRef = React.useRef<any>();
  const localSession = useSelector((state: RootState) => { return state.global.session?.local });

  const cargarCuentaContable = useSelector((state: RootState) => state.contabilidad.diarioGeneral.cargarCuentaContable);
  const periodosContables = useSelector((state: RootState) => state.contabilidad.general.periodosContables);
  const locales = useSelector((state: RootState) => state.shared.providers.locales);
  const proyectos = useSelector((state: RootState) => state.shared.providers.proyectos);

  const currentActionState = useSelector((state: RootState) => state.contabilidad.diarioGeneral.currentAction);
  const currentFunction = useSelector((state: RootState) => state.contabilidad.diarioGeneral.currentFunction);
  const seleccionado = useSelector((state: RootState) => state.contabilidad.diarioGeneral.seleccionado);
  const asientosDetalle = useSelector((state: RootState) => state.contabilidad.diarioGeneral.detalleAsiento);
  const asiento = useSelector((state: RootState) => state.contabilidad.diarioGeneral.asiento);
  const numeroAsientoActual = useSelector((state: RootState) => state.contabilidad.diarioGeneral.numeroAsientoActual);

  const [ASI_CODIGO, setASI_CODIGO] = React.useState<any>(0)
  const [ASI_DIARIONRO, setASI_DIARIONRO] = React.useState<any>("")
  const [NRO, setNRO] = React.useState<any>(0)
  const [AS_TIPO, setAS_TIPO] = React.useState<any>("MANUAL")
  const [AS_NROTIPO, setAS_NROTIPO] = React.useState<any>(0)
  const [ASI_C_FECHA_AS, setASI_C_FECHA_AS] = React.useState<Date>(new Date())
  const [ASI_FECHA_MIN, setASI_FECHA_MIN] = React.useState<Date>(new Date())
  const [ASI_FECHA_MAX, setASI_FECHA_MAX] = React.useState<Date>(new Date())
  const [AS_C_INICIAL, setAS_C_INICIAL] = React.useState<any>(false)
  const [AS_C_AJUSTE, setAS_C_AJUSTE] = React.useState<any>(false)
  const [ELIMINADOS, setELIMINADOS] = React.useState<any>(false)
  const [COD_LOCALCC, setCOD_LOCALCC] = React.useState<Local | null>(null)
  const [TIPO_TRANSACCION, setTIPO_TRANSACCION] = React.useState<any>(operacionesGrupos[0])
  const [COD_CUENTA, setCOD_CUENTA] = React.useState<any>("")
  const [NUM_CUENTA, setNUM_CUENTA] = React.useState<any>("")
  const [DESC_CUENTA, setDESC_CUENTA] = React.useState<any>("")
  const [VALOR, setVALOR] = React.useState<any>("")
  const [REF, setREF] = React.useState<any>("")
  const [AS_C_OBSERVACION, setAS_C_OBSERVACION] = React.useState<any>("")
  const [AS_C_COP_REFERENC, setAS_C_COP_REFERENC] = React.useState<any>("")
  const [AS_C_TOT_DEBE, setAS_C_TOT_DEBE] = React.useState<any>(0)
  const [AS_C_TOT_HABER, setAS_C_TOT_HABER] = React.useState<any>(0)
  const [AS_C_TOT_DESCUADRE, setAS_C_TOT_DESCUADRE] = React.useState<any>(0)
  const [messageConfirm, setMessageConfirm] = React.useState<string>("");
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [cleanDetall, setCleanDetall] = React.useState(false);
  const [colorDescuadre, setcolorDescuadre] = React.useState("#0b333c");
  const [filaSeleccionada, setFilaSeleccionada] = React.useState<any>('');
  const [resErrores, setResErrores] = React.useState<any>([]);
  const [dpLocales, setDpLocales] = React.useState<Array<any>>([])
  const [asientoCargado, setAsientoCargado] = React.useState<boolean>(false)
  const [proyecto, setProyecto] = React.useState<Project | null>(null)

  const llenarPeriodos = React.useCallback(async (data) => {
    const cuentas: any = [];
    const periodos = data;
    Object.keys(periodos).map(function (key) {
      return cuentas.push({
        value: Number(periodos[key]["PER_DIARIO"]),
        label: periodos[key]["PER_ANIO"],
        detalle: periodos[key]
      });
    });
    return { cant: cuentas.length, data: cuentas }
  }, []);

  const llenarCombosPeriodos = async (data) => {
    if (data.length > 0) {
      // setASI_DIARIONRO(data[0]['value'])
      // let fnac: any = DateUtils.apiDateToPickersDate(data[0]['detalle']['PER_FINICIO']);
      // let fnacMax: any = DateUtils.apiDateToPickersDate(data[0]['detalle']['PER_FCIERRE']);
      // setASI_C_FECHA_AS(DateUtils.getCurrentDateAsString())
      // setASI_FECHA_MIN(fnac)
      // setASI_FECHA_MAX(fnacMax)
    }
  }

  const onBlurCuentas = (numCuenta) => {
    if (numCuenta !== "") {
      findCuentaPlan('NUM_CUENTA', numCuenta);
    }
  }

  const handleReset = React.useCallback(() => {
    setASI_CODIGO(0)
    setASI_DIARIONRO('')
    setNRO(0)
    setAS_TIPO("MANUAL")
    setAS_NROTIPO(0)
    setASI_C_FECHA_AS(new Date())
    setASI_FECHA_MIN(new Date())
    setASI_FECHA_MAX(new Date())
    setAS_C_INICIAL(false)
    setAS_C_AJUSTE(false)
    setELIMINADOS(false)
    setCOD_LOCALCC(null)
    setTIPO_TRANSACCION(operacionesGrupos[0])
    setCOD_CUENTA("")
    setNUM_CUENTA("")
    setDESC_CUENTA("")
    setVALOR("")
    setREF("")
    setAS_C_OBSERVACION("")
    setAS_C_COP_REFERENC("")
    setAS_C_TOT_DEBE(0)
    setAS_C_TOT_HABER(0)
    setAS_C_TOT_DESCUADRE(0)
    setcolorDescuadre("#0b333c")
    setFilaSeleccionada('')
    dispatch(setResetDetalleAsiento())
  }, [dispatch]);


  const obtenerPeriodosContables = React.useCallback(async () => {
    playLoader()
    try {
      const data = await cuentasService.obtenerPeriodosContables();
      stopLoader()
      if (data['error'] === false) {
        if (data['auto'].length > 0) {
          const resPeriodos: any = await llenarPeriodos(data['auto']);
          if (resPeriodos['cant'] > 0) {
            dispatch(setPeriodosContables(resPeriodos['data']))
            // setASI_DIARIONRO(resPeriodos['data'][0]['value'])
            // let fnac: any = DateUtils.apiDateToPickersDate(resPeriodos['data'][0]['detalle']['PER_FINICIO']);
            // let fnacMax: any = DateUtils.apiDateToPickersDate(resPeriodos['data'][0]['detalle']['PER_FCIERRE']);
            // setASI_C_FECHA_AS(DateUtils.getCurrentDateAsString())
            // setASI_FECHA_MIN(fnac)
            // setASI_FECHA_MAX(fnacMax)
          }
        }
      } else {
        setToast(data['message'], ToastTypes.Warning)
      }
      return await data;
    } catch (error) {
      consoleService.error(error);
      stopLoader()
      setToast(JSON.stringify(error), ToastTypes.Danger)
    }
  }, [dispatch, playLoader, setToast, stopLoader, llenarPeriodos])

  const verificarValidaciones = React.useCallback(() => {
    const messagesErrorAsiento: any = [];

    if (asientosDetalle.length <= 0) {
      messagesErrorAsiento.push("-    Registros: no existen registros para este asiento contable.");
    }

    if (AS_C_OBSERVACION === "") {
      messagesErrorAsiento.push("-    Descripción del asiento.");
    }

    if (Number(AS_C_TOT_DESCUADRE) !== 0) {
      messagesErrorAsiento.push("-    Descuadre: el asiento contable tiene problemas revíselo.");
    }

    return messagesErrorAsiento;
  }, [AS_C_OBSERVACION, AS_C_TOT_DESCUADRE, asientosDetalle]);

  const handleSubmit = React.useCallback(async () => {

    playLoader()

    const transaccion: any = []
    let fila: any = 0;
    let contarCuentasSinCodigo: number = 0;

    asientosDetalle.map((e) => {
      let detallAdd: any = {}
      if (e['PLA_CODIGO'] !== "") {
        detallAdd = {
          FILA: fila + 1,
          ASI_NUMERO: e['asientoNumero'],
          PLA_CODIGO: e['PLA_CODIGO'],
          PLA_NUMERO: e['PLA_NUMERO'],
          PLA_DESCRIPCION: e['PLA_DESCRIPCION'],
          PLA_DESCIDIOMA: e['PLA_DESCIDIOMA'],
          LOC_CODIGO: COD_LOCALCC?.codigo ?? -1,
          TRA_CODIGO: e['TRA_CODIGO'],
          TRA_REFERENCIA: e['TRA_REFERENCIA'],
          TRA_DEBE: e['TRA_DEBE'],
          TRA_HABER: e['TRA_HABER']
        };
      }

      transaccion.push(detallAdd)
      if (e['PLA_CODIGO'] === '' || e['PLA_CODIGO'] === null || e['PLA_CODIGO'] === undefined) {
        consoleService.error("PLA_CODIGO not found")
        contarCuentasSinCodigo = contarCuentasSinCodigo + 1;
      }
      fila++;
    });

    if (contarCuentasSinCodigo > 0) {
      stopLoader()
      setToast("Una de las transacciones en el detalle no tiene cuenta contable, por favor reviselo.", ToastTypes.Warning);
      return false;
    }

    const data_request = {
      numero: ASI_CODIGO,
      fecha: DateUtils.dateToString(ASI_C_FECHA_AS, 'dd/MM/yyyy'),
      descripcion: AS_C_OBSERVACION,
      pla_descripcion: "",
      tipo: AS_TIPO, // MANUAL
      debe: Number(AS_C_TOT_DEBE) ?? 0,
      haber: Number(AS_C_TOT_HABER) ?? 0,
      local: COD_LOCALCC?.codigo ?? -1,
      transacciones: transaccion,
    }
    try {
      const data = await cuentasService.crearRegistro(data_request);
      consoleService.log("save asiento : ", data)
      stopLoader()
      if (data['error'] === true) {
        setToast(data['message'], ToastTypes.Warning);
      } else {
        if (currentAction === TIPOS_BOTONES.BOTON_EDITAR) {
          setToast(data['message'], ToastTypes.Success);
          // dispatch(setData([]));
        } else {
          setToast(data['message'], ToastTypes.Success);
        }
        if (Number(props.origenModulo) === 16) {
          dispatch(setResetSeleccion())
          dispatch(setCurrentAccion("Buscar"))
        }
      }
      return await data;
    } catch (e) {
      consoleService.error(e);
      stopLoader()
      setToast(JSON.stringify(e), ToastTypes.Danger)
    }
  }, [ASI_CODIGO, ASI_C_FECHA_AS, AS_C_OBSERVACION, AS_C_TOT_DEBE, AS_C_TOT_HABER, AS_TIPO, COD_LOCALCC, asientosDetalle, currentAction, dispatch, playLoader, setToast, stopLoader, props]);

  const openModalCuentas = React.useCallback(() => {
    dispatch(setModal({
      modalName: "modalPlan",
      open: true,
      codModulo: null
    }))
  }, [dispatch]);

  const validarTransaccion = React.useCallback(() => {
    let mensaje: any = "";
    if (NUM_CUENTA === "") {
      mensaje = mensaje + "-  Datos de cuenta.\n";
    }
    return mensaje;
  }, [NUM_CUENTA]);

  const handleClickDeleteConfirm = React.useCallback((index) => {
    const rowsBefore = asientosDetalle.slice();
    rowsBefore.splice(index, 1);
    dispatch(setDetalleAsiento(rowsBefore));
    setFilaSeleccionada(-1)
    calcularTotales(rowsBefore)
  }, [asientosDetalle, dispatch]);

  const handleClickRemove = React.useCallback(() => {

    if (asientosDetalle.length > 0) {
      if (filaSeleccionada < 0) {
        setToast("No se encuentra ninguna fila seleccionada.", ToastTypes.Info)
      } else {

        const rowsBefore = asientosDetalle.slice();
        rowsBefore.splice(filaSeleccionada, 1);
        dispatch(setDetalleAsiento(rowsBefore));
        setFilaSeleccionada(-1)
        calcularTotales(rowsBefore)
      }
    } else {
      setToast("No hay registros ingresados en la tabla.", ToastTypes.Info)
    }
  }, [asientosDetalle, dispatch, filaSeleccionada, setToast]);

  const handleClickRemoveDetall = React.useCallback(() => {
    if (asientosDetalle.length > 0) {
      //dispatch(setResetDetalleAsiento());
      const sms = "¿Desea eliminar todo el detalle del asiento?\n";
      setMessageConfirm(sms)
      setShowConfirm(true)
      setCleanDetall(true)
      calcularTotales([])
    } else {
      setToast("No hay detalle ingresados en la tabla.", ToastTypes.Info)
    }
  }, [asientosDetalle, setToast]);

  const getName = React.useCallback((value) => {
    let name = "";
    locales.map((e) => {
      if (value === e.value) {
        name = e.label;
      }
    });
    return name;
  }, [locales]);


  const parseDataAsiento = React.useCallback(async (infoAsiento) => {
    consoleService.error(infoAsiento)
    const getDetall: Array<DetalleAsiento> = infoAsiento['asientoDetalle'].map(itemKey => {
      const asientoInfo: DetalleAsiento = {
        asientoNumero: Number(itemKey.asientoNumero) ?? 0,
        codigoLocal: Number(itemKey.codigoLocal) ?? 0,
        localNombre: itemKey.localNombre ?? "",
        planCuentasCodigo: Number(itemKey.planCuentasCodigo) ?? 0,
        planCuentasDescIdioma: itemKey.planCuentasDescIdioma ?? "",
        planCuentasDescripcion: itemKey.planCuentasDescripcion ?? "",
        planCuentasNumero: itemKey.planCuentasNumero ?? "",
        siglasLocal: itemKey.siglasLocal ?? "",
        transaccionCodigo: Number(itemKey.transaccionCodigo) ?? 0,
        transaccionDebe: Number(itemKey.transaccionDebe) ?? 0,
        transaccionHaber: Number(itemKey.transaccionHaber) ?? 0,
        transaccionReferencia: itemKey.transaccionReferencia ?? ""
      }
      return asientoInfo;
    });

    const getPeriodo: AsientoPeriodo = infoAsiento['periodoDetalle'].map(itemKey => {
      const _periodo: AsientoPeriodo = {
        periodoDiario: Number(itemKey.periodoDiario) ?? 0,
        periodoAnio: Number(itemKey.periodoAnio) ?? 0,
        periodoCierre: itemKey.periodoCierre ?? "",
        periodoInicio: itemKey.periodoInicio ?? "",
        periodoMes: Number(itemKey.periodoMes) ?? 0,
        periodoMesDescripcion: itemKey.periodoMesDescripcion ?? "",
        periodoUso: Number(itemKey.periodoUso) ?? 0,
        subvalor: itemKey.subvalor ?? "",
      }
      return _periodo;
    });

    const getProyecto: AsientoProyecto = {
      proyectoCodigo: Number(infoAsiento['proyectoDetalle'].proyectoCodigo) ?? 0,
      proyectoNombre: infoAsiento['proyectoDetalle'].proyectoNombre ?? ""
    };

    const asientoApi: AsientoApi = {
      descripcionAsiento: infoAsiento.asientoDescripcion,
      numeroDiario: infoAsiento.asientoDiarioNum,
      asientoEstado: infoAsiento.asientoEstado,
      numeroDocumento: infoAsiento.asientoNroDoc,
      tipoNumero: infoAsiento.asientoNroTipo,
      numeroAsiento: infoAsiento.asientoNumero,
      tipo: infoAsiento.asientoTipo,
      debe: Number(infoAsiento.asientoTotalDebe) ?? 0,
      haber: Number(infoAsiento.asientoTotalHaber) ?? 0,
      fecha: infoAsiento.asientoFecha ?? new Date(),
      fechaAuditoria: infoAsiento.asientoFechaAuditoria ?? new Date(),
      local: {
        localCodigo: infoAsiento.localCodigo,
        localNombre: infoAsiento.localNombre,
      },
      periodoDetalle: { ...getPeriodo[0] },
      proyecto: { ...getProyecto },
      detalle: getDetall,
      usuario: infoAsiento.usuIdSesion ?? ""
    }
    dispatch(setAsientoApi(asientoApi))
  }, [dispatch]);

  const cargarAsiento = React.useCallback(async (numero) => {
    consoleService.log("cargarAsiento:", numero)
    setAsientoCargado(true)

    playLoader()
    try {
      //let numero = seleccion['row']['COD']
      const data = await cuentasService.cargarAsiento(numero);
      consoleService.error(data)
      stopLoader()
      if (data['error'] === false) {
        if (data['auto'] !== null) {
          if (data['auto']['asientoDetalle'] === false || data['auto']['periodoDetalle'] === false) {
            setToast("No se encuentra el detalle del asiento", ToastTypes.Warning)
            return false;
          }
          if (data['auto']['asientoFecha'] === null || data['auto']['asientoFecha'] === "") {
            setToast("No se puede mostrar este asiento", ToastTypes.Warning)
            handleReset()
            return false;
          }
          handleReset();
          const asientoAuto = data['auto'];
          const asientoObj: Asiento = {
            ASI_CODIGO: asientoAuto['asientoNumero'],
            ASI_DIARIONRO: asientoAuto['asientoDiarioNum'],
            NRO: asientoAuto['asientoNumero'],
            AS_TIPO: asientoAuto['asientoTipo'],
            AS_NROTIPO: asientoAuto['asientoNroTipo'],
            ASI_C_FECHA_AS: DateUtils.apiDateToPickersDate(asientoAuto['asientoFecha']),
            ASI_FECHA_MIN: "",
            ASI_FECHA_MAX: "",
            ASI_NRODOC: asientoAuto['asientoNroDoc'],
            AS_C_INICIAL: false,
            AS_C_AJUSTE: false,
            ELIMINADOS: false,
            COD_PROYECTO: "-1",
            COD_LOCALCC: {
              value: asientoAuto['localCodigo'],
              label: getName(asientoAuto['localCodigo']),
            },
            TIPO_TRANSACCION: 0,
            COD_CUENTA: "",
            NUM_CUENTA: "",
            DESC_CUENTA: "",
            VALOR: "",
            REF: "",
            AS_C_OBSERVACION: asientoAuto['asientoDescripcion'],
            AS_C_COP_REFERENC: "",
            AS_C_TOT_DEBE: asientoAuto['asientoTotalDebe'],
            AS_C_TOT_HABER: asientoAuto['asientoTotalHaber'],
            AS_C_TOT_DESCUADRE: 0
          }
          parseDataAsiento(asientoAuto)
          dispatch(setAsiento(asientoObj))
          dispatch(setNumeroAsientoActual(asientoAuto['asientoNumero']))
          //setAsientoCont(asientoObj)
          setASI_CODIGO(asientoAuto['asientoNumero'])
          setASI_DIARIONRO(asientoAuto['asientoDiarioNum'])
          setNRO(asientoAuto['asientoNumero'])
          setAS_TIPO(asientoAuto['asientoTipo'])
          setAS_NROTIPO(asientoAuto['asientoNroTipo'])
          setASI_C_FECHA_AS(DateUtils.strDateToDate(asientoAuto['asientoFecha'], formatoFechasApi))
          if (asientoAuto['periodoDetalle']) {
            setASI_FECHA_MIN(DateUtils.strDateToDate(asientoAuto['periodoDetalle'][0]['periodoInicio'], formatoFechasApi))
            setASI_FECHA_MAX(DateUtils.strDateToDate(asientoAuto['periodoDetalle'][0]['periodoCierre'], formatoFechasApi))
          }
          setAS_C_INICIAL(false)
          setAS_C_AJUSTE(false)
          setELIMINADOS(false)

          if (proyectos.length > 0) {
            const find = proyectos.findIndex((option: Project) => {
              return option.codigo === asientoAuto['proyectoCodigo']
            });
            let marcProj = proyectos[0]
            if (find > -1) {
              marcProj = proyectos[find] ?? proyectos[0]
            }
            setProyecto(marcProj);
          }

          if (locales.length > 0) {
            const findLoc = locales.findIndex((option: Project) => {
              return option.codigo === asientoAuto['localCodigo']
            });
            let marcLoc = locales[0]
            if (findLoc > -1) {
              marcLoc = locales[findLoc] ?? locales[0]
            }
            setCOD_LOCALCC(marcLoc)
          }

          setTIPO_TRANSACCION(operacionesGrupos[0])
          setCOD_CUENTA("")
          setNUM_CUENTA("")
          setDESC_CUENTA("")
          setVALOR("")
          setREF("")
          setAS_C_OBSERVACION(asientoAuto['asientoDescripcion'])
          setAS_C_COP_REFERENC("")
          setAS_C_TOT_DEBE(asientoAuto['asientoTotalDebe'])
          setAS_C_TOT_HABER(asientoAuto['asientoTotalHaber'])
          setAS_C_TOT_DESCUADRE(0)
          setcolorDescuadre("#0b333c")
          setFilaSeleccionada('')

          let detallAdd: any = {}
          let fila: any = 0;
          //let detalle: any = asientosDetalle.slice(0);
          const detalle: any = [];
          if (asientoAuto['asientoDetalle'].length > 0) {

            asientoAuto['asientoDetalle'].map((e) => {
              let marcLocDet = null
              if (locales.length > 0) {
                const findLocDet = locales.findIndex((option: Project) => {
                  return option.codigo === e['codigoLocal']
                });
                marcLocDet = locales[0]
                if (findLocDet > -1) {
                  marcLocDet = locales[findLocDet] ?? locales[0]
                }
              }

              detallAdd = {
                id: uuidv4(),
                FILA: fila + 1,
                ASI_NUMERO: e['asientoNumero'],
                PLA_CODIGO: e['planCuentasCodigo'],
                PLA_NUMERO: e['planCuentasNumero'],
                PLA_DESCRIPCION: e['planCuentasDescripcion'],
                PLA_DESCIDIOMA: e['planCuentasDescIdioma'],
                LOCAL: {
                  value: marcLocDet?.codigo ?? -1,
                  label: marcLocDet?.nombre ?? '',
                },
                LOC_CODIGO: marcLocDet?.codigo ?? -1,
                LOC_NOMBRE: e['localNombre'],
                LOC_SIGLAS: e['localSiglas'],
                TRA_CODIGO: e['transaccionCodigo'],
                TRA_REFERENCIA: e['transaccionReferencia'],
                TRA_DEBE: e['transaccionDebe'],
                TRA_HABER: e['transaccionHaber'],
                TIPO_TRANSACCION: Number(e['transaccionDebe']) > 0 ? 0 : 1
              };
              detalle.push(detallAdd)
              fila++;
            });

            // if (Number(props.origenModulo) === 16) {
            //   let infoDataAsiento = parseDataAsiento(asientoAuto);

            //   dispatch(setSeleccionarDatoDiario(
            //     {
            //       index: [0],
            //       row: { ...infoDataAsiento },
            //       selectedRow: [0]
            //     }
            //   ))
            // } else if (Number(props.origenModulo) === 17) {
            //   dispatch(setSeleccionarDatoMayor(
            //     {
            //       index: [0],
            //       row: { ...asientoObj },
            //       selectedRow: [0]
            //     }
            //   ))
            // }


            dispatch(setDetalleAsiento(detalle))

          } else {
            dispatch(setResetDetalleAsiento())
            setToast("No se puede encontrar el detalle de este asiento.", ToastTypes.Warning)
            return false;
          }
        }
      } else {
        setToast(data['message'], ToastTypes.Warning)
      }
      return await data;
    } catch (error) {
      consoleService.error(error);
      stopLoader()
      setToast(JSON.stringify(error), ToastTypes.Danger)
    }
  }, [
    parseDataAsiento, dispatch, getName,
    handleReset, playLoader, setToast, stopLoader,
    locales, proyectos])


  const handleClickAddClone = React.useCallback(() => {

    if (asientosDetalle.length > 0) {
      if (filaSeleccionada === "") {
        setToast("No se encuentra ninguna fila seleccionada.", ToastTypes.Info)
      } else {
        try {

          let transaccionAgregar: any = {}
          let transaccionSeleccionada: any = {}
          // let fila: number = asientosDetalle.length;
          // let filaIncrementada: number = asientosDetalle.length + 1;
          let detalle: any = asientosDetalle.slice(0);
          const clonarFila = detalle[filaSeleccionada];
          detalle.splice(filaSeleccionada, 1);
          // let valor_debe: number = 0;
          // let valor_haber: number = 0;

          // if (clonarFila['TIPO_TRANSACCION']['value'] === 0) {
          //   valor_debe = VALOR;
          //   valor_haber = 0;
          // } else if (clonarFila['TIPO_TRANSACCION']['value'] === 1) {
          //   valor_debe = 0;
          //   valor_haber = VALOR;
          // }

          consoleService.error(clonarFila)

          if (Number(clonarFila['FILA']) > 1) {

            transaccionAgregar = {
              id: uuidv4(),
              FILA: Number(clonarFila['FILA']),
              ASI_NUMERO: "",
              PLA_CODIGO: "",
              PLA_NUMERO: "",
              PLA_DESCRIPCION: "",
              PLA_DESCIDIOMA: "",
              LOCAL: {
                value: clonarFila?.LOC_CODIGO?.value ?? -1,
                label: getName(clonarFila['LOC_CODIGO']['value']),
              },
              LOC_CODIGO: clonarFila?.LOC_CODIGO?.value ?? -1,

              LOC_NOMBRE: getName(clonarFila['LOC_CODIGO']['value']),
              TRA_CODIGO: "",
              TRA_REFERENCIA: "",
              TIPO_TRANSACCION: clonarFila['TIPO_TRANSACCION'],
              TRA_DEBE: 0,
              TRA_HABER: 0
            };
            detalle.push(transaccionAgregar)
            transaccionSeleccionada = {
              ...clonarFila,
              FILA: Number(clonarFila['FILA'] + 1)
            }
            detalle.push(transaccionSeleccionada)
            consoleService.error(detalle)



          } else {
            let contador: number = 1;
            transaccionSeleccionada = {
              ...clonarFila,
              FILA: Number(contador) + 1
            }
            detalle.push(transaccionSeleccionada)

            transaccionAgregar = {
              FILA: contador,
              ASI_NUMERO: "",
              PLA_CODIGO: "",
              PLA_NUMERO: "",
              PLA_DESCRIPCION: "",
              PLA_DESCIDIOMA: "",
              LOC_CODIGO: {
                value: clonarFila['LOC_CODIGO']['value'],
                label: getName(clonarFila['LOC_CODIGO']['value']),
              },
              LOC_NOMBRE: getName(clonarFila['LOC_CODIGO']['value']),
              TRA_CODIGO: "",
              TRA_REFERENCIA: "",
              TIPO_TRANSACCION: clonarFila['TIPO_TRANSACCION'],
              TRA_DEBE: 0,
              TRA_HABER: 0
            };
            detalle.push(transaccionAgregar)
            const item: any = []
            detalle.map(function (key) {
              const obj: any = {
                ...key,
                FILA: contador
              }
              item.push(obj)
              contador = contador + 1;
            })
            detalle = item;
          }

          dispatch(setDetalleAsiento(detalle))

        } catch (error) {
          consoleService.error(error)
          setToast("No se puede agregar el registro.", ToastTypes.Info)
        }
      }
    } else {
      setToast("No hay registros ingresados en la tabla.", ToastTypes.Info)
    }
  }, [asientosDetalle, dispatch, filaSeleccionada, getName, setToast]);


  const resetCntrlDetallAsiento = React.useCallback(() => {
    dispatch(setAsiento({ ...asiento, NUM_CUENTA: "", DESC_CUENTA: "", VALOR: "", REF: "" }))
    atFocus(inputNumCuenta)
  }, [asiento, dispatch]);

  const handleClickAdd = React.useCallback(() => {

    let sms: string = "Adventencias";
    if (Number(AS_C_TOT_DEBE) <= 0 && (TIPO_TRANSACCION['value'] === 0 ? 0 : 1) === 1) {
      sms = "-    Para ingresar una transacción en el haber, debe ingresar al menos una en el debe.\n";
      setMessageConfirm(sms)
      setShowConfirm(true)
    } else {
      const mensaje = validarTransaccion();
      if (mensaje === "") {
        let detallAdd: any = {}
        const fila: any = asientosDetalle.length;
        const detalle: any = asientosDetalle.slice(0);
        let valor_debe: number = 0;
        let valor_haber: number = 0;

        if (TIPO_TRANSACCION['value'] === 0) {
          valor_debe = VALOR;
          valor_haber = 0;
        } else if (TIPO_TRANSACCION['value'] === 1) {
          valor_debe = 0;
          valor_haber = VALOR;
        }

        detallAdd = {
          FILA: fila + 1,
          ASI_NUMERO: ASI_CODIGO,
          PLA_CODIGO: COD_CUENTA,
          PLA_NUMERO: NUM_CUENTA,
          PLA_DESCRIPCION: DESC_CUENTA,
          PLA_DESCIDIOMA: "",
          LOC_CODIGO: {
            value: COD_LOCALCC['codigo'],
            label: COD_LOCALCC['nombre'],
          },
          LOC_NOMBRE: COD_LOCALCC['nombre'],
          TRA_CODIGO: "",
          TRA_REFERENCIA: REF,
          TRA_DEBE: valor_debe,
          TRA_HABER: valor_haber,
          TIPO_TRANSACCION: TIPO_TRANSACCION['value']
        };
        detalle.push(detallAdd)
        dispatch(setDetalleAsiento(detalle))
        calcularTotales(detalle)
        resetCntrlDetallAsiento()
      } else {
        sms = "    Para ingresar una transacción, debe ingresar los siguientes campos:\n";
        setMessageConfirm(sms + " " + mensaje)
        setShowConfirm(true)
      }
    }
  }, [ASI_CODIGO, AS_C_TOT_DEBE, COD_CUENTA, COD_LOCALCC, DESC_CUENTA, NUM_CUENTA, REF, TIPO_TRANSACCION, VALOR, asientosDetalle, dispatch, resetCntrlDetallAsiento, validarTransaccion]);

  const handleOnKeyPressRef = React.useCallback((event) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      handleClickAdd()
    }
  }, [handleClickAdd]);

  const handleOnKeyPressValor = React.useCallback((event) => {
    if (event.event.key === "d" || event.event.keyCode === 68) {
      let VALOR_: any = "";
      if (Number(AS_C_TOT_DESCUADRE) < 0) {
        VALOR_ = Number(AS_C_TOT_DESCUADRE) * -1;
      } else {
        VALOR_ = Number(AS_C_TOT_DESCUADRE) + 0;
      }
      setVALOR(VALOR.split("d").join(""))
      new Promise((resolve: any) => {
        setTimeout(() => {
          resolve();
          setVALOR(VALOR_)
        }, 100);
      });
      atFocus(inputRefDesc)
    } else if (event.event.key === "Enter" || event.event.keyCode === 13) {
      handleClickAdd()
    }
  }, [AS_C_TOT_DESCUADRE, VALOR, handleClickAdd]);

  const calcularTotales = (asientosDetalle_) => {
    const dptransaccion = asientosDetalle_;
    let totalDebe = 0;
    let totalHaber = 0;
    let debe = "";
    let haber = "";

    if (dptransaccion.length > 0) {

      for (let i = 0; i < dptransaccion.length; i++) {
        const tra_de = asientosDetalle_[i]['TRA_DEBE'];
        debe = tra_de;

        const tra_ha = asientosDetalle_[i]['TRA_HABER'];
        haber = tra_ha;

        totalDebe = totalDebe + Number(debe);
        totalHaber = totalHaber + Number(haber);
      }

    } else {
      totalDebe = 0;
      totalHaber = 0;
    }

    const totaldebe_ti = Number(totalDebe);
    const totalhaber_ti = Number(totalHaber);
    const descuadre_ti = totaldebe_ti - totalhaber_ti;

    if (descuadre_ti != 0) {
      setcolorDescuadre("#ff0000")
    } else {
      setcolorDescuadre("#0b333c")
    }

    setAS_C_TOT_DEBE(Number(totaldebe_ti).toFixed(2))
    setAS_C_TOT_HABER(Number(totalhaber_ti).toFixed(2))
    setAS_C_TOT_DESCUADRE(Number(descuadre_ti).toFixed(2))
  }

  const errores = () => {
    return (
      <>
        {
          Object.keys(resErrores).map(function (key) {
            return (
              <>
                <GlobalLabel style={{
                  marginTop: "0rem",
                  fontSize: "96%",
                  listStyleType: "none",
                }} key={key} >
                  {resErrores[key]}
                </GlobalLabel>
                <br></br>
              </>);
          })
        }
      </>
    )
  }

  const renderBody = () => {
    return (
      <>
        {messageConfirm}
        <br></br>
        {resErrores.length > 0 && errores()}
      </>
    )
  }

  const renderFooter = () => {
    return (
      <>
        {((showConfirm === true) && (cleanDetall !== true)) && (
          <CButton color="primary" onClick={() => {
            setShowConfirm(false)
          }}>
            {"Aceptar"}
          </CButton>
        )}

        {((showConfirm === true) && (cleanDetall === true)) && (
          <CButton color="primary" onClick={() => {
            dispatch(setResetDetalleAsiento());
            setShowConfirm(false)
            setCleanDetall(false)
          }}>
            {"Aceptar"}
          </CButton>
        )}

        <CButton color="secondary" onClick={() => {
          setShowConfirm(!showConfirm)
        }} >{"Cancelar"}</CButton>
      </>
    )
  }

  const confirmAction = () => {
    return (
      <Modalform
        name='confirmar'
        headerTitle={'Confirmar'}
        childrenBody={renderBody()}
        childrenFooter={renderFooter()}
        closeOnBackdrop={false}
        show={showConfirm}
        onClose={() => setShowConfirm(!showConfirm)}
        centered={true}
        size="sm"
      />
    )
  }

  const validateAsyncDescuadre = (datoSeleccion) => {
    return verificarDescuadre(datoSeleccion);
  };

  const verificarDescuadre = (value) => {
    if (value["value"] > 0) {
      return Promise.resolve();
    } else {
      return Promise.reject('Existe un descuadre en el asiento contable.');
    }
  };

  const validateAsyncTotales = (datoSeleccion) => {
    return verificarValorEnTotales(datoSeleccion);
  };

  const verificarValorEnTotales = (value) => {
    const validador = 0;
    return new Promise((resolve) => {
      setTimeout(function () {
        if (value["value"] !== undefined) {
          resolve(
            Number(value["value"]) > Number(validador) === true
              ? true
              : false
          );
        } else {
          resolve(true);
        }
      }, 1000);
    });
  };

  const findCuentaPlan = React.useCallback(async (TIPO, BUSCAR) => {

    playLoader()
    try {
      let obj: any = {}

      if (TIPO === "NUM_CUENTA") {
        obj = {
          numero: BUSCAR,
          descripcion: "",
          estado: 1,
        }
      } else if (TIPO === "DESC_CUENTA") {
        obj = {
          numero: "",
          descripcion: BUSCAR,
          estado: 1,
        }
      }
      const data = await planDeCuentas.obtenerRegistros(obj);
      stopLoader()
      if (data['auto'].length > 1) {
        atFocus(inputValor)
        openModalCuentas()
        dispatch(setCuentasPlan(data['auto']))
        dispatch(setCuentasPlanBack(data['auto']))
      } else if (data['auto'].length === 1) {

        dispatch(setAsiento({
          ...asiento,
          COD_CUENTA: data['auto'][0]['PLA_CODIGO'],
          NUM_CUENTA: data['auto'][0]['PLA_NUMERO'],
          DESC_CUENTA: data['auto'][0]['PLA_DESCRIPCION'],
        }))

        atFocus(inputValor);
      } else {
        atFocus(inputNumCuenta);
        setToast("No hay resultados en la busqueda.", ToastTypes.Warning)
      }
      return await data;
    } catch (error) {
      atFocus(inputNumCuenta)
      consoleService.error(error);
      stopLoader()
      setToast(JSON.stringify(error), ToastTypes.Danger)
    }
  }, [asiento, dispatch, openModalCuentas, playLoader, setToast, stopLoader]);

  const handleOnKeyPressCuenta = React.useCallback((event) => {
    if (event.event.key === "Enter" || event.event.keyCode === 13) {
      if (NUM_CUENTA.length == 0) {
        openModalCuentas()
      }
      if (NUM_CUENTA !== "") {
        findCuentaPlan("NUM_CUENTA", NUM_CUENTA);
      }
    } else if (event.event.key === "Tab" || event.event.keyCode === 11) {
      if (NUM_CUENTA !== "") {
        findCuentaPlan("NUM_CUENTA", NUM_CUENTA);
      }
    }
  }, [NUM_CUENTA, findCuentaPlan, openModalCuentas]);

  const handleOnKeyPressDescCuenta = React.useCallback((event) => {
    if (event.event.key === "Enter" || event.event.keyCode === 13) {
      if (DESC_CUENTA !== "") {
        findCuentaPlan("DESC_CUENTA", DESC_CUENTA);
      }
    } else if (event.event.key === "Tab" || event.event.keyCode === 11) {
      if (DESC_CUENTA !== "") {
        findCuentaPlan("DESC_CUENTA", DESC_CUENTA);
      }
    }
  }, [DESC_CUENTA, findCuentaPlan]);

  const onChangeProyectoLook = React.useCallback((v) => {
    setProyecto(v)
    if (v.locales) {
      setDpLocales(v.locales ?? [])
      setCOD_LOCALCC(v?.locales[0])
    }
  }, []);

  const onValueChangedCC = React.useCallback((e: any) => {
    const selectedValue = e || dpLocales[0];
    setCOD_LOCALCC(selectedValue)
  }, [dpLocales])

  const onSubmit = React.useCallback(() => {
    setResErrores([])
    const resValidacion = verificarValidaciones();
    if (resValidacion.length > 0) {
      setResErrores(resValidacion)
      const sms = "No pudimos guardar la información, revise lo siguiente: \n";
      setMessageConfirm(sms)
      setShowConfirm(true)
      return false;
    }
    handleSubmit()
  }, [handleSubmit, verificarValidaciones]);

  const onLoadAccount = React.useCallback((account) => {
    if (account.codigo !== 0) {
      setCOD_CUENTA(account['codigo'])
      setNUM_CUENTA(account['numero'])
      setDESC_CUENTA(account['descripcion'])
      atFocus(inputValor);
      dispatch(setCargarCuentaContable({
        codigo: 0,
        numero: "",
        descripcion: ""
      }))
    }
  }, [dispatch])

  React.useEffect(() => {
    if (currentFunction === "Guardar") {
      dispatch(setCurrentFunction(""));
      onSubmit()
    } else if (currentFunction === "Deshacer") {
      dispatch(setCurrentFunction(""));
      handleReset()
      cargarAsiento(seleccionado['row']['COD'])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFunction, seleccionado]);

  React.useEffect(() => {
    if (currentFunction === TIPOS_BOTONES.BOTON_DUPLICAR) {
      dispatch(setCurrentFunction(""));
      setASI_CODIGO(0)
      setNRO(0)
      // modificar el estado asiento reducer para encerar el asi_codigo y el nro para poder duplicarlo y desabilitar el boton duplicar del menu
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFunction]);




  // React.useEffect(() => {
  //   dispatch(setAsiento({
  //     ...asiento,
  //     TRA_HABER: Number(AS_C_TOT_HABER)
  //   }))
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [AS_C_TOT_HABER, asiento]);

  React.useEffect(() => {
    handleReset() // resetea el formulario para inicializar
    if (periodosContables.length <= 0) {
      obtenerPeriodosContables();
    } else {
      llenarCombosPeriodos(periodosContables)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodosContables]);

  React.useEffect(() => {
    if (cargarCuentaContable.codigo !== 0) {
      onLoadAccount(cargarCuentaContable)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cargarCuentaContable]);

  React.useEffect(() => {
    if (currentAction === TIPOS_BOTONES.BOTON_ANTERIOR) {
      dispatch(setCurrentAccion(TIPOS_BOTONES.BOTON_EDITAR));
      handleReset()
      cargarAsiento(numeroAsientoActual)
    } else if (currentAction === TIPOS_BOTONES.BOTON_SIGUIENTE) {
      dispatch(setCurrentAccion(TIPOS_BOTONES.BOTON_EDITAR))
      handleReset()
      cargarAsiento(numeroAsientoActual)
    } else if (currentAction === TIPOS_BOTONES.BOTON_EDITAR) {
      if (numeroAsientoActual !== 0 && asientoCargado === false) {
        cargarAsiento(numeroAsientoActual);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAction, numeroAsientoActual, asientoCargado]);

  // React.useEffect(() => {
  //   setCurrentAction(currentActionState)
  // }, [currentActionState]);
  // ddd
  // utilido cuando se carga desde pantalla modal que ejecuta el Mayor general
  React.useEffect(() => {
    if (seleccionado['row'] !== null) {
      dispatch(setAsiento({
        ...asiento,
        ASI_C_FECHA_AS: seleccionado['row']['asientoFecha'],
        NRO: Number(seleccionado['row']['asientoNumero']),
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seleccionado]);

  React.useEffect(() => {
    if (asientosDetalle.length > 0) {
      if (currentAction === TIPOS_BOTONES.BOTON_EDITAR) {
        calcularTotales(asientosDetalle)
      } else if (currentAction === TIPOS_BOTONES.BOTON_SIGUIENTE) {
        calcularTotales(asientosDetalle)
      } else if (currentAction === TIPOS_BOTONES.BOTON_ANTERIOR) {
        calcularTotales(asientosDetalle)
      }
    }
  }, [asientosDetalle, currentAction]);

  return (
    <>
      {showConfirm === true && confirmAction()}

      <CCard>
        <CCardHeader>
          <strong>{currentAction}</strong>
        </CCardHeader>
        <CCardBody>
          <ValidationGroup ref={validationGroupRef}>
            <CRow>
              <CCol lg="6" md="8" sm="12">
                <ValidationSummary id="summary"></ValidationSummary>
              </CCol>
            </CRow>

            <CRow>
              <CCol lg="4" sm="12">
                <div className="dx-field m-1">
                  <div className="dx-field-label">{'Diario:'}</div>
                  <div className="dx-field-value">
                    <NumberBox
                      readOnly
                      width="100%"
                      value={Number(ASI_DIARIONRO)}
                      placeholder=""
                    >
                      <Validator>
                        <RequiredRule message={'El Diario es requerido.'} />
                      </Validator>
                    </NumberBox>
                  </div>
                </div>

                <div className="dx-field m-1">
                  <div className="dx-field-label">{'Fecha:'}</div>
                  <div className="dx-field-value">
                    <DateBox
                      width="100%"
                      displayFormat="dd/MM/yyyy"
                      value={ASI_C_FECHA_AS}
                      stylingMode="outlined"
                      onValueChanged={(data) => {
                        //dispatch(setAsiento({ ...asiento, ASI_C_FECHA_AS: data.value }))
                        setASI_C_FECHA_AS(data.value)
                      }}
                      min={ASI_FECHA_MIN}
                      max={ASI_FECHA_MAX}
                    >
                      <DateBoxButton
                        name="prevDate"
                        location="before"
                        options={{
                          icon: 'spinprev',
                          stylingMode: 'text',
                          onClick: () => {
                            const x = DateUtils.add(ASI_C_FECHA_AS, {
                              days: -1,
                            })
                            setASI_C_FECHA_AS(x)
                          },
                        }}
                      />
                      <DateBoxButton
                        name="nextDate"
                        location="after"
                        options={{
                          icon: 'spinnext',
                          stylingMode: 'text',
                          onClick: () => {
                            const x = DateUtils.add(ASI_C_FECHA_AS, { days: 1 })
                            setASI_C_FECHA_AS(x)
                          },
                        }}
                      />
                      <DateBoxButton name="dropDown" />
                    </DateBox>
                  </div>
                </div>

                <div className="dx-field m-1">
                  <div className="dx-field-label">{'Proyectos:'}</div>
                  <div className="dx-field-value ">
                    <ProjectsLookUp
                      onChanged={(data) => onChangeProyectoLook(data)}
                      selected={proyecto ?? null}
                      addDefaultPosition={'General'}
                    />
                  </div>
                </div>

                <div className="dx-field m-1">
                  <div className="dx-field-label">{'Lugar o CC:'}</div>
                  <div className="dx-field-value ">
                    <Lookup
                      width="100%"
                      dataSource={dpLocales}
                      displayExpr="nombre"
                      searchEnabled={true}
                      searchMode={'contains'}
                      searchExpr={'nombre'}
                      searchTimeout={200}
                      minSearchLength={3}
                      showDataBeforeSearch={true}
                      placeholder="Seleccionar"
                      showClearButton={true}
                      defaultValue={COD_LOCALCC}
                      value={COD_LOCALCC}
                      onValueChange={onValueChangedCC}
                    ></Lookup>
                  </div>
                </div>
              </CCol>
              <CCol lg="4" sm="12">
                <div className="dx-field m-1">
                  <div className="dx-field-label">{'Nro.:'}</div>
                  <div className="dx-field-value">
                    <NumberBox
                      readOnly
                      width="70%"
                      value={Number(NRO)}
                      placeholder=""
                      onValueChanged={(data) => {
                        setNRO(data.value)
                      }}
                    ></NumberBox>
                  </div>
                </div>
                <div className="dx-field m-1">
                  <div className="dx-field-value d-flex align-items-start">
                    <CheckBox
                      width={100}
                      text="Inicial"
                      value={AS_C_INICIAL}
                      onValueChanged={() => {
                        setAS_C_INICIAL(!AS_C_INICIAL)
                      }}
                    />{' '}
                    <CheckBox
                      width={100}
                      text="Ajuste"
                      value={AS_C_AJUSTE}
                      onValueChanged={() => {
                        setAS_C_AJUSTE(!AS_C_AJUSTE)
                      }}
                    />{' '}
                    <CheckBox
                      width={100}
                      text="Eliminados"
                      value={ELIMINADOS}
                      onValueChanged={() => {
                        setELIMINADOS(!ELIMINADOS)
                      }}
                    />
                  </div>
                </div>
              </CCol>
              <CCol lg="4" sm="12">
                <Labelgroup
                  value={AS_TIPO + '-' + AS_NROTIPO}
                  name={''}
                  label={''}
                  labelmd="1"
                  inputxs="12"
                  inputmd="11"
                />
              </CCol>
            </CRow>

            <CRow>
              <CCol className="float-right" lg="12">
                <strong>
                  <hr></hr>
                </strong>
              </CCol>
            </CRow>

            <CRow>
              <CCol sm="12" md="4" lg="4">
                <div className="dx-field m-1">
                  <div className="dx-field-label">{'Cuenta:'}</div>
                  <div className="dx-field-value">
                    <TextBox
                      ref={inputNumCuenta}
                      width="70%"
                      value={NUM_CUENTA}
                      placeholder=""
                      onValueChanged={(data) => {
                        setNUM_CUENTA(data.value)
                        onBlurCuentas(data.value)
                      }}
                      //onKeyPress={(evt) => handleOnKeyPressCuenta(evt)}
                      onEnterKey={(evt) => handleOnKeyPressCuenta(evt)}
                    />
                  </div>
                </div>
              </CCol>
              <CCol sm="12" md="5" lg="5">
                <div className="dx-field m-1 d-flex align-items-start">
                  <div className="dx-field-value">
                    <TextBox
                      width="100%"
                      value={DESC_CUENTA}
                      placeholder=""
                      onValueChanged={(data) => {
                        setDESC_CUENTA(data.value)
                      }}
                      //onKeyPress={(evt) => handleOnKeyPressDescCuenta(evt)}
                      onEnterKey={(evt) => handleOnKeyPressDescCuenta(evt)}
                    >
                      <TextBoxButton
                        name="agregarCuenta"
                        location="after"
                        options={{
                          icon: 'plus',
                          text: '',
                          onClick: () => openModalCuentas(),
                        }}
                      />
                    </TextBox>
                  </div>
                </div>
              </CCol>
              <CCol sm="12" md="4" lg="4">
                <CRow>
                  <CCol></CCol>
                </CRow>
              </CCol>
            </CRow>

            <CRow>
              <CCol sm={12} md={4} lg={4}>
                <div className="dx-field m-1">
                  <div className="dx-field-label">{'Valor:'}</div>
                  <div className="dx-field-value">
                    <NumberBox
                      ref={inputValor}
                      width="70%"
                      value={VALOR}
                      placeholder=""
                      onValueChanged={(data) => {
                        setVALOR(data.value)
                      }}
                      //onKeyPress={(evt) => { handleOnKeyPressValor(evt) }}
                      onEnterKey={(evt) => handleOnKeyPressValor(evt)}
                    />
                  </div>
                </div>
              </CCol>
              <CCol sm={12} md={4} lg={4}>
                <div className="dx-field m-1">
                  <div className="dx-field-label">{'Ref.:'}</div>
                  <div className="dx-field-value">
                    <TextBox
                      ref={inputRefDesc}
                      width="100%"
                      value={REF}
                      placeholder=""
                      onValueChanged={(data) => {
                        setREF(data.value)
                      }}
                      //onKeyPress={(evt) => handleOnKeyPressRef(evt)}
                      onEnterKey={(evt) => handleOnKeyPressRef(evt)}
                    />
                  </div>
                </div>
              </CCol>
              <CCol sm={12} md={2} lg={2}>
                <div className="dx-field">
                  <div className="dx-field-value">
                    <RadioGroup
                      name="operacion"
                      dataSource={operacionesGrupos}
                      value={TIPO_TRANSACCION}
                      layout="horizontal"
                      displayExpr="label"
                      onValueChanged={(e) => {
                        setTIPO_TRANSACCION(e.value)
                      }}
                    />
                  </div>
                </div>
              </CCol>
              <CCol sm={12} md={2} lg={2}>
                <CTooltip
                  key={'tool-plus-account'}
                  placement="top"
                  content={'Agregar'}
                >
                  <CButton
                    id="btnBuscarCuentas"
                    color="secondary"
                    className="ms-1"
                    size="sm"
                    onClick={() => handleClickAdd()}
                  >
                    <FontAwesomeIcon
                      size="lg"
                      icon={['fal', 'plus']}
                      style={{ fontWeight: 1 }}
                    />
                  </CButton>
                </CTooltip>

                <CTooltip
                  key={'tool-minus-account'}
                  placement="top"
                  content={'Quitar'}
                >
                  <CButton
                    id="btnBuscarCuentas"
                    color="secondary"
                    className="ms-1"
                    size="sm"
                    onClick={() => handleClickRemove()}
                  >
                    <FontAwesomeIcon
                      size="lg"
                      icon={['fal', 'minus']}
                      style={{ fontWeight: 1 }}
                    />
                  </CButton>
                </CTooltip>
              </CCol>
            </CRow>

            <CRow>
              <CCol sm={12} md={12} lg={12} className="d-flex align-items-end">
                <CTooltip
                  key={'tool-minus-account'}
                  placement="top"
                  content={'Vaciar Detalle'}
                >
                  <CButton
                    id="btnVaciarDetalle"
                    color="secondary"
                    className="ms-1"
                    size="sm"
                    onClick={() => handleClickRemoveDetall()}
                  >
                    <FontAwesomeIcon
                      size="lg"
                      icon={['fal', 'trash']}
                      style={{ fontWeight: 1 }}
                    />
                  </CButton>
                </CTooltip>

                <CTooltip key={'tool-plus'} placement="top" content={'Agregar'}>
                  <CButton
                    id="btnBuscarCuentas"
                    color="secondary"
                    className="ms-1"
                    size="sm"
                    onClick={() => handleClickAddClone()}
                  >
                    <FontAwesomeIcon
                      size="lg"
                      icon={['fal', 'chevron-left']}
                      style={{ fontWeight: 1 }}
                    />
                    <FontAwesomeIcon
                      size="lg"
                      icon={['fal', 'plus']}
                      style={{ fontWeight: 1 }}
                    />
                    <FontAwesomeIcon
                      size="lg"
                      icon={['fal', 'chevron-right']}
                      style={{ fontWeight: 1 }}
                    />
                  </CButton>
                </CTooltip>

                <CTooltip key={'tool-minus'} placement="top" content={'Quitar'}>
                  <CButton
                    id="btnBuscarCuentas"
                    color="secondary"
                    className="ms-1"
                    size="sm"
                    onClick={() => handleClickRemove()}
                  >
                    <FontAwesomeIcon
                      size="lg"
                      icon={['fal', 'chevron-left']}
                      style={{ fontWeight: 1 }}
                    />
                    <FontAwesomeIcon
                      size="lg"
                      icon={['fal', 'minus']}
                      style={{ fontWeight: 1 }}
                    />
                    <FontAwesomeIcon
                      size="lg"
                      icon={['fal', 'chevron-right']}
                      style={{ fontWeight: 1 }}
                    />
                  </CButton>
                </CTooltip>
              </CCol>
            </CRow>
            <br></br>

            <CRow>
              <CCol sm={12} md={12} lg={12}>
                <Detalle
                  asientosDetalle={asientosDetalle}
                  retornarIndex={(row) => setFilaSeleccionada(row)}
                  handleClickRemove={(index) => handleClickDeleteConfirm(index)}
                  detalleAsiento={asientosDetalle}
                  returnDetalle={(detalle) => {
                    dispatch(setDetalleAsiento(detalle))
                  }}
                  setToast={setToast}
                  playLoader={playLoader}
                  stopLoader={stopLoader}
                />
              </CCol>
            </CRow>

            <CRow className="m-1">
              <CCol sm={12} md={8} lg={8}>
                <CRow>
                  <CCol md={2}>
                    <GlobalLabel
                      htmlFor={'Valor'}
                      className="col-form-label-md"
                    >
                      {'Descripción:'}
                    </GlobalLabel>
                  </CCol>
                  <CCol xs={12} md={8}>
                    <TextArea
                      placeholder="..."
                      value={AS_C_OBSERVACION}
                      onValueChanged={(data) => setAS_C_OBSERVACION(data.value)}
                    >
                      <Validator>
                        <RequiredRule message={'Descripción requerida'} />
                      </Validator>
                    </TextArea>
                  </CCol>
                </CRow>

                <CRow>
                  <CCol md="9">
                    <CheckBox
                      width={100}
                      text="Copiar con referencia"
                      value={AS_C_COP_REFERENC}
                      onValueChanged={() =>
                        setAS_C_COP_REFERENC(!AS_C_COP_REFERENC)
                      }
                    />
                  </CCol>
                </CRow>
              </CCol>

              <CCol sm={12} md={4} lg={4}>
                <CRow>
                  <CCol sm={12} md={6} lg={6}>
                    <CRow>
                      <CCol xs={12} md={9}>
                        <NumberBox
                          readOnly
                          width="100%"
                          value={AS_C_TOT_DEBE}
                          placeholder=""
                        >
                          <Validator>
                            <AsyncRule
                              message={'El total del debe no es válido.'}
                              validationCallback={validateAsyncTotales}
                            />
                          </Validator>
                        </NumberBox>
                      </CCol>
                    </CRow>
                  </CCol>
                  <CCol sm={12} md={6} lg={6}>
                    <CRow>
                      <CCol xs={12} md={9}>
                        <NumberBox
                          readOnly
                          width="100%"
                          value={AS_C_TOT_HABER}
                          placeholder=""
                        >
                          <Validator>
                            <AsyncRule
                              message={'El total del haber no es válido.'}
                              validationCallback={validateAsyncTotales}
                            />
                          </Validator>
                        </NumberBox>
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm={12} md={12} lg={12}>
                    <div className="dx-field m-1">
                      <div className="dx-field-label">{'Descuadre:'}</div>
                      <div className="dx-field-value">
                        <NumberBox
                          width="100%"
                          value={AS_C_TOT_DESCUADRE}
                          placeholder=""
                          style={{ borderColor: colorDescuadre }}
                        >
                          <Validator>
                            <AsyncRule
                              message={'Existe un descuadre en la transacción'}
                              validationCallback={validateAsyncDescuadre}
                            />
                          </Validator>
                        </NumberBox>
                      </div>
                    </div>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </ValidationGroup>
        </CCardBody>
      </CCard>
    </>
  )
}

export default React.memo(Editar);
