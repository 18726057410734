import React, { memo } from 'react';
import { ValidationGroup } from 'devextreme-react/validation-group';
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer';
import CustomCol from '../../../../../../views/componentes/colContainer';
import ValidationSummary from 'devextreme-react/validation-summary';
import { CAlert, CCard, CCardBody } from '@coreui/react-pro';
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput';
import TextBox from 'devextreme-react/text-box';
import {
  Validator,
  RequiredRule,
  PatternRule,
  AsyncRule,
} from 'devextreme-react/validator';
import TextArea from 'devextreme-react/text-area';
import CheckBox from 'devextreme-react/check-box'
import NumberBox from 'devextreme-react/number-box';
import { lh, MessagesKeys } from '../../../../../../helpers/localizationHelper';
import SelectBox from '../../../../../../views/componentes/selectBox/selectBox';
import { TipoClasificacion, TipoIdentificacion, TipoProveedor, TiposEstados, TiposOperadoras } from '../../../../../ventas/types/types';
import { isMobileOnly, isMobile } from 'react-device-detect';
import { GeneralService } from '../../../../../../services/general.service';
import { Pais, Provincia, ToastTypes } from '../../../../../../store/types';
import { Ciudad } from '../../../../../ventas/store/types';
import { localidadesService } from '../../../../../../services/localidades.service';
import { PopupContent } from '../../../../../../views/componentes/popupContent';
import { ProveedoresService } from '../../../../services/proveedores.service';
import TabContactos, { ETipoAsociado } from '../../../modalAdminProveedores/tabContactos/tabContactos';
import { addToast } from '../../../../../../store/toasterReducer';
import { Proveedor } from '../../../../store/types';
import { changeLoader } from '../../../../../../store/reducers';
import { RootState } from '../../../../../../store/store';
import { useDispatch, useSelector } from 'react-redux'
import { findOnObject } from '../../../../../../helpers/Helper';

interface IFormularioEdicionProps extends React.PropsWithChildren {
  onChanged: (codigoProveedor: string) => void,
  onResetFuncion: () => void,
  codigoProveedor: number | null,
  currentTab: string,
  funcion: string
}

type ErrorInfo = {
  tieneError: boolean,
  mensaje?: string
}

type TipoVisitas = {
  codigo: number,
  valor: string
}

const diasVisita: Array<TipoVisitas> = [
  { codigo: 0, valor: "" },
  { codigo: 1, valor: "LUNES" },
  { codigo: 2, valor: "MARTES" },
  { codigo: 3, valor: "MIERCOLES" },
  { codigo: 4, valor: "JUEVES" },
  { codigo: 5, valor: "VIERNES" },
  { codigo: 6, valor: "SABADO" },
  { codigo: 7, valor: "DOMINGO" }];

type DatosModificarProveedor = {
  razonSocial: string,
  nombreComercial: string | null,
  tipoIdentificacion: TipoIdentificacion | null,
  ruc: string | null,
  direccion: string | null,
  proveedorSeguros: boolean,
  artesanoCalificacion: boolean,
  transportista: boolean,
  email: string | null,
  clasificacion: TipoClasificacion | null,
  tipoProveedor: TipoProveedor | null,
  tipoEstado: TiposEstados | null,
  reposicion: number,
  diaVisita: TipoVisitas | null,
  ciudad: Ciudad | null,
  relacionada: boolean,
  contactos: Array<any>
}

export const emailPatern = /^([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]{1,}[\\;]?)+){1,}$/

const FormularioEdicion = (props: IFormularioEdicionProps) => {

  const dispatch = useDispatch();

  const { codigoProveedor, onChanged, currentTab, funcion, onResetFuncion } = props;
  const validationGroupRef = React.useRef<any>();
  const menuSession = useSelector((state: RootState) => state.global.menu);
  const empresaSession = useSelector((state: RootState) => state.global.session?.empresa);
  const contratoEmpresaState = useSelector((state: RootState) => state.pagosSuscripcion.contratoEmpresa);

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [errorInfo, seterrorInfo,] = React.useState<ErrorInfo>({ tieneError: false });
  const [razonSocial, setRazonSocial] = React.useState<string>("");
  const [tiposIdentificacion, setTiposIdentificacion] = React.useState<Array<TipoIdentificacion>>([]);
  const [tipoIdentificacion, setTipoIdentificacion] = React.useState<TipoIdentificacion | null>(null);
  const [ruc, setRuc] = React.useState<string>('');
  const [esNuevo, setEsNuevo] = React.useState(false);
  const [relacionada, setRelacionada] = React.useState(false);
  const [codigo, setCodigo] = React.useState<number>(0);
  const [nombreComercial, setNombreComercial] = React.useState<string>('');
  const [tipoProveedor, setTipoProveedor] = React.useState<TipoProveedor | null>(null);
  const [email, setEmail] = React.useState<string>("");
  const [direccion, setDireccion] = React.useState('');
  const [provincias, setProvincias] = React.useState<Array<Provincia>>([]);
  const [provincia, setProvincia] = React.useState<Provincia | null>(null);
  const [paises, setPaises] = React.useState<Array<Pais>>([]);
  const [pais, setPais] = React.useState<Pais | null>(null);
  const [ciudades, setCiudades] = React.useState<Array<Ciudad>>([]);
  const [ciudad, setCiudad] = React.useState<Ciudad | null>(null);
  const [tiposProveedores, setTiposProveedores] = React.useState<Array<TipoProveedor>>([]);
  const [proveedorSeguros, setProveedorSeguros] = React.useState<boolean>(false);
  const [artesanoCalificacion, setArtesanoCalificacion] = React.useState<boolean>(false);
  const [transportista, setTransportista] = React.useState<boolean>(false);
  const [clasificaciones, setClasificaciones] = React.useState<Array<TipoClasificacion>>([]);
  const [clasificacion, setClasificacion] = React.useState<TipoClasificacion | null>(null);
  const [reposicion, setReposicion] = React.useState<number>(1);
  const [diasVisitas,] = React.useState<Array<TipoVisitas>>(diasVisita);
  const [diaVisita, setDiaVisita] = React.useState<TipoVisitas | null>(null);
  const [tiposEstados, setTiposEstados] = React.useState<Array<TiposEstados>>([]);
  const [estado, setEstado] = React.useState<TiposEstados | null>(null);
  const [tiposOperadora, setTiposOperadoras] = React.useState<Array<TiposOperadoras>>([]);
  const [contactos, setContactos] = React.useState<Array<any>>([]);
  const [showErrorPopup, setShowErrorPopup] = React.useState<boolean>(false);
  const [showErrorMessages, setShowErrorMessages] = React.useState<Array<any>>([]);
  const [dataProveedor, setDataProveedor] = React.useState<DatosModificarProveedor | null>(null);
  const [availableInventario, setAvailableInventario] = React.useState<boolean>(false);

  const verificarModulosActivos = React.useCallback(() => {
    const find = findOnObject(menuSession, 'id', "44");
    if (find.length > 0) {
      setAvailableInventario(true)
    } else {
      setAvailableInventario(false)
    }
  }, [menuSession]);

  React.useEffect(() => {
    if (codigoProveedor !== null) {
      cargarProveedor(codigoProveedor);
    }
  }, [codigoProveedor]);

  React.useEffect(() => {
    if (currentTab === 'Nuevo') {
      reiniciarFormulario()
    }
    iniciarFormulario()
  }, [currentTab]);

  React.useEffect(() => {
    setDataProveedor({
      razonSocial: razonSocial,
      nombreComercial: nombreComercial,
      tipoIdentificacion: tipoIdentificacion,
      ruc: ruc,
      direccion: direccion,
      proveedorSeguros: proveedorSeguros,
      artesanoCalificacion: artesanoCalificacion,
      transportista: transportista,
      email: email,
      clasificacion: clasificacion,
      tipoProveedor: tipoProveedor,
      tipoEstado: estado,
      reposicion: reposicion,
      diaVisita: diaVisita,
      ciudad: ciudad,
      relacionada: relacionada,
      contactos: contactos
    })
  }, [razonSocial, nombreComercial, tipoIdentificacion, ruc, direccion, proveedorSeguros,
    artesanoCalificacion, transportista, email, clasificacion, tipoProveedor, estado,
    reposicion, diaVisita, ciudad, relacionada, contactos
  ]);

  React.useEffect(() => {
    if (currentTab === "Nuevo" && funcion === 'Guardar') {
      if (dataProveedor !== null) {
        onResetFuncion()
        handleSubmit(dataProveedor, false)
      }
    } if (currentTab === "Nuevo" && funcion === 'Deshacer') {
      onResetFuncion()
      reiniciarFormulario()
    } else if (currentTab === "Editar" && funcion === 'Guardar') {
      if (dataProveedor !== null) {
        onResetFuncion()
        handleSubmit(dataProveedor, false)
      }
    } else if (currentTab === "Editar" && funcion === 'Deshacer') {
      onResetFuncion()
      reiniciarFormulario()
    }
  }, [funcion]);

  const cargarProveedor = React.useCallback(async (codigoProveedor) => {
    validationGroupRef.current.instance.reset()
    try {
      playLoader()
      setContactos([])
      //await utilidades.sleep(25000);
      const localidades = await localidadesService.getDpPaises("Elija una opción");
      const tipoIdentificacionesApi = await ProveedoresService.getTipoIdentificacion("Identificación");
      const tipos = await ProveedoresService.getTiposProveedor();
      const tipoEstados = await ProveedoresService.getEstados("Estado");
      const tipoOperadoras = await ProveedoresService.getOperadoras("Elija una Operadora");
      const clasificacion = await ProveedoresService.getClasificaciones("Clasificación");
      const busqueda = codigoProveedor === null ? null : await ProveedoresService.getProveedorByCode(Number(codigoProveedor));

      setTiposProveedores(tipos);
      setClasificaciones(clasificacion)
      setTiposEstados(tipoEstados)
      setTiposOperadoras(tipoOperadoras)
      setTiposIdentificacion(tipoIdentificacionesApi)
      setPaises(localidades)
      setProvincias(localidades?.[2]?.['provincias'] ?? [])

      if (!busqueda) {
        setEsNuevo(true);
        setCodigo(0);
        setNombreComercial('');
        setTipoProveedor(tipos[0]);
        setTipoIdentificacion(tipoIdentificacionesApi[0]);
        setClasificacion(clasificacion[0])
        setEmail('');
        setDireccion('');
        setEstado(tipoEstados[0])
        setTipoProveedor(tipos[0])
        setPais(localidades[2])
        setProvincia(localidades?.[2]?.['provincias']?.[0] ?? null)
        setProveedorSeguros(false)
        setArtesanoCalificacion(false)
        setTransportista(false)
        setReposicion(1)

        const ciudades = await localidadesService.getDpCiudades(-1, "Elija una opción");
        setCiudades(ciudades)
        setCiudad(ciudades[0])

      } else {

        playLoader()
        const detalleProveedor = await ProveedoresService.getDetalleProveedor(busqueda?.codigo ?? 0, "PROVEEDOR");

        const ciudades = await localidadesService.getDpCiudades(busqueda.provinciaCodigo ?? -1, "Elija una opción");
        setCiudades(ciudades)

        const contacts: any = detalleProveedor.map(function (key) {
          return {
            ...key,
            principal: Number(key.principal) === 1 ? true : false
          }
        })
        setEsNuevo(false);
        setCodigo(busqueda.codigo ?? 0);
        setContactos(contacts)
        setNombreComercial(busqueda.nombre ?? "");
        setRazonSocial(busqueda.razonComercial ?? "")
        setRuc(busqueda.identificacion ?? "")
        setDireccion(busqueda.direccion ?? "")
        setEmail(busqueda.email ?? "")
        setProveedorSeguros(Number(busqueda.seguros) === 1 ? true : false)
        setArtesanoCalificacion(Number(busqueda.artesanos) === 1 ? true : false)
        setTransportista(Number(busqueda.transportista) === 1 ? true : false)
        setReposicion(Number(busqueda.intervalo) ?? 1)

        setTipoIdentificacion(tipoIdentificacionesApi.find(x => String(x.codigo) === String(busqueda.tipoIdentificacion)) ?? null);
        setTipoProveedor(tipos.find(x => String(x.codigo) === String(busqueda.tipoProveedorCodigo)) ?? null);
        setClasificacion(clasificacion.find(x => String(x.codigo) === String(busqueda.clasificacion)) ?? null);
        setEstado(tipoEstados.find(x => String(x.codigo) === String(busqueda.estado)) ?? null);
        setDiaVisita(diasVisitas.find(x => String(x.codigo) === String(busqueda.diaReposicion)) ?? null);
        setPais(paises.find(x => Number(x.codigo) === Number(busqueda.paisCodigo)) ?? null);
        setProvincia(provincias.find(x => Number(x.codigo) === Number(busqueda.provinciaCodigo)) ?? null);
        setCiudad(ciudades.find(x => Number(x.codigo) === Number(busqueda.ubicacion)) ?? null);
        stopLoader()

      }
      stopLoader()
    } catch (error) {
      stopLoader()
      dispatch(addToast({
        id: '',
        autoHide: true,
        content: 'Error al cargar el proveedor. Error:\n' + error,
        fade: true,
        title: 'Proveedor',
        type: ToastTypes.Danger
      }))

    }

  }, [dispatch, diasVisitas]);

  const playLoader = () => {
    dispatch(changeLoader({ show: true, mensaje: 'Cargando...' }))
  }

  const stopLoader = () => {
    dispatch(changeLoader({ show: false, mensaje: '' }))
  }

  const handleSubmit = React.useCallback(async (values: DatosModificarProveedor, verificarNuevo: boolean) => {
    console.log('handleSubmit', values)
    try {
      playLoader()
      const validationResult = await validationGroupRef.current.instance.validate();
      if (validationResult.isValid === false) {
        stopLoader()
        setShowErrorPopup(true)
        setShowErrorMessages(validationResult.brokenRules)
      }
      let contactos: any = [];
      if (values.contactos && values.contactos.length === 0) {
        stopLoader()
        dispatch(addToast({
          title: 'Crear proveedor',
          content: `Debe ingresar al menos un numero de contacto.`,
          type: ToastTypes.Warning,
          autoHide: 5000,
        }))
        return false;
      } else {
        contactos = values.contactos;
      }

      validationResult.complete.then(async resolve => {
        await resolve;
        if (!resolve.isValid) {
          setShowErrorPopup(true)
          setIsSubmitting(false);
          return;
        }

        if (verificarNuevo === true) {
          const proveedor = await ProveedoresService.consultarProveedores('', values.ruc ?? "", values.transportista);
          setIsSubmitting(false);
          stopLoader()
          if (proveedor && proveedor.length > 0) {
            dispatch(addToast({
              title: 'Crear proveedor',
              content: `'El proveedor con identificación ${(values.ruc ?? '')} ya se encuentra registrado, si desea modificar los datos debe dar clic en la opción de edición.`,
              type: ToastTypes.Danger,
              autoHide: 5000,
            }))
            return;
          }
        }

        const proveedor: Proveedor = {
          codigo: verificarNuevo ? 0 : codigo,
          tipoIdentificacion: String(values.tipoIdentificacion?.codigo) ?? "01",
          identificacion: values.ruc ?? "",
          identificacionString: values.ruc ?? "",
          // tributario: values.tributario,
          // fechaRegitro: values.fechaRegitro,
          nombre: values.razonSocial,
          razonComercial: values.nombreComercial ?? "",
          direccion: values.direccion ?? "",
          // telefono: values.telefono,
          // fax: values.fax,
          // celular: values.celular,
          email: values.email ?? "",
          ubicacion: values.ciudad?.codigo ?? 0,
          // ciudadNombre: values.ciudadNombre,
          clasificacion: values.clasificacion?.codigo ?? 0,
          diasCredito: 0,
          limiteCredito: 0,
          descuento: 0,
          // personaAtencionCompras: values.personaAtencionCompras,
          // personaAtencionPagos: values.personaAtencionPagos,
          saldo: 0,
          comprasAnuales: 0,
          // fechaUltimoPago: values.fechaUltimoPago,
          // documentoUltimoPago: values.documentoUltimoPago,
          montoPago: 0,
          // fechaUltimaCompra: values.fechaUltimaCompra,
          // documentoUltimaCompra: values.documentoUltimaCompra,
          montoCompra: 0,
          // observaciones: values.observaciones,
          // web: values.web,
          // tipoEmpresa: values.tipoEmpresa,
          // especialidad: values.especialidad,
          tipoAgenteCodigo: Number(values.tipoProveedor?.codigo) ?? 0,
          seguros: values.proveedorSeguros === true ? 1 : 0,
          artesanos: values.artesanoCalificacion === true ? 1 : 0,
          tipoProveedorCodigo: String(values.tipoProveedor?.codigo) ?? "01",
          relacionado: values.tipoIdentificacion?.codigo === "03" ? (values.relacionada === true ? "SI" : 'NO') : "NO",
          estado: String(values.tipoEstado?.codigo) ?? "1",
          intervalo: values.reposicion,
          diaReposicion: String(values.diaVisita?.codigo) ?? "1",
          transportista: values.transportista === true ? "1" : "0",
          tributario: ''
        }
        const respuesta = await ProveedoresService.modificarProveedor(proveedor,
          contactos);

        dispatch(addToast({
          title: 'Proveedor',
          content: `Registro Satisfactorio.`,
          type: ToastTypes.Success,
          autoHide: 5000,
        }))
        stopLoader();
        setIsSubmitting(false);
        onChanged(values.ruc ?? '');
      }, reject => {
        stopLoader();
        setShowErrorPopup(true)
        setIsSubmitting(false);
      })

    } catch (error) {
      stopLoader();
      seterrorInfo({ tieneError: true, mensaje: 'Error al guardar el proveedor, \n' + error });
      setIsSubmitting(false);
    }
  }, [onChanged, codigo]);

  const reiniciarFormulario = React.useCallback(() => {
    validationGroupRef.current.instance.reset()
    if (codigoProveedor === null || codigoProveedor === 0) {
      setRazonSocial("")
      setNombreComercial("")
      setTipoIdentificacion(null)
      setRuc("")
      setDireccion("")
      setProveedorSeguros(false)
      setArtesanoCalificacion(false)
      setTransportista(false)
      setEmail("")
      setPais(null)
      setProvincia(null)
      setCiudad(null)
      setClasificacion(null)
      setTipoProveedor(null)
      setEstado(null)
      setReposicion(1)
      setDiaVisita(null)
    } else {
      cargarProveedor(codigoProveedor)
    }
  }, []);

  const iniciarFormulario = React.useCallback(async () => {
    const localidades = await localidadesService.getDpPaises("Elija una opción");
    const tipoIdentificacionesApi = await ProveedoresService.getTipoIdentificacion("Identificación");
    const tipos = await ProveedoresService.getTiposProveedor();
    const tipoEstados = await ProveedoresService.getEstados("Estado");
    const tipoOperadoras = await ProveedoresService.getOperadoras("Elija una Operadora");
    const clasificacion = await ProveedoresService.getClasificaciones("Clasificación");
    setTiposProveedores(tipos);
    setClasificaciones(clasificacion)
    setTiposEstados(tipoEstados)
    setTiposOperadoras(tipoOperadoras)
    setTiposIdentificacion(tipoIdentificacionesApi)
    setPaises(localidades)
    setProvincias(localidades?.[2]?.['provincias'] ?? [])
  }, []);

  const validateId = React.useCallback(async (params: any, esNuevo: boolean) => {
    const validacionIdentificacion = await GeneralService.validarIndentificacion(params.value ?? '');
    if (validacionIdentificacion.isValid) {
      // if (esNuevo) {
      //   const proveedor = await ProveedoresService.getProveedorByCode({ nombre: '', identificacion: params.value ?? '' });
      //   if (proveedor.length > 0) return Promise.reject(`El proveedor con identificacion ${(params.value ?? '')} ya se encuentra registrado, si desea modificar los datos debe dar clic en la opción de edición.`)
      // }
      return Promise.resolve();
    } else {
      return Promise.reject(validacionIdentificacion.motivo ?? 'Error al validar identificación');
    }
  }, []);

  const cargarProvincias = React.useCallback(async (pais) => {
    if (pais === null) {
      setProvincias(paises[0]['provincias'] ?? [])
    } else {
      setProvincias(pais['provincias'])
      setProvincia(pais['provincias'][0])
    }
  }, [provincia, paises]);

  const cargarCiudades = React.useCallback(async (provincia) => {
    let find: number = -1;
    if (provincia === null) {
      find = -1;
    } else {
      find = provincia.codigo;
    }
    const ciudades = await localidadesService.getDpCiudades(find, "Elija una opción");
    setCiudades(ciudades)
    setCiudad(ciudades[0])
  }, [provincia]);

  React.useEffect(() => {
    verificarModulosActivos();
  }, []);

  return (
    <>
      <fieldset>
        <ValidationGroup id={`valGroupModificarProveedor}${codigoProveedor}`} ref={validationGroupRef}>
          {!isMobileOnly &&
            <RowContainer>
              <CustomCol>
                <ValidationSummary />
              </CustomCol>
            </RowContainer>
          }
          {errorInfo.tieneError &&
            <CAlert
              key='modificaProveedorErrorAlert'
              color='danger'
              visible={true}
              dismissible
              onChange={() => { seterrorInfo({ tieneError: false }) }}
            >
              {errorInfo.mensaje}
            </CAlert>
          }
          <RowContainer>
            <CustomCol xs='12' md='4'>
              <Labeled
                label='Razón Social'
              >
                <TextBox
                  name='razonSocial'
                  placeholder='Razón Social'
                  value={razonSocial}
                  onValueChanged={(data) => {
                    if (data.event !== undefined) {
                      setRazonSocial(data.value)
                    }
                  }}
                  elementAttr={{
                    autoFocus: true
                  }}
                >
                  <Validator>
                    <RequiredRule trim message={lh.getMessage(MessagesKeys.GlobalCampoRequerido, "Razón Social")} />
                  </Validator>
                </TextBox>
              </Labeled>
            </CustomCol>
            <CustomCol xs='12' md='4'>
              <Labeled
                label='Nombre Comercial'
              >
                <TextBox
                  name='nombreComercial'
                  placeholder='Nombre Comercial'
                  value={nombreComercial ?? ""}
                  onValueChanged={({ value }: any) => setNombreComercial(value)}
                >
                  <Validator>
                    <RequiredRule trim message={lh.getMessage(MessagesKeys.GlobalCampoRequerido, "Nombre Comercial")} />
                  </Validator>
                </TextBox>
              </Labeled>
            </CustomCol>
            <CustomCol xs='12' md='4'>
              <Labeled
                label='Tipo identificación'
              >
                <SelectBox
                  id='selectTiposIdentificacion'
                  options={tiposIdentificacion}
                  placeholder='Tipo de indetificación'
                  onChange={(data) => {
                    if (data !== null) {
                      setTipoIdentificacion(data);
                    } else {
                      setTipoIdentificacion(null);
                    }
                  }}
                  selected={tipoIdentificacion}
                  displayExpr='tipo'
                  keyExpr='codigo'
                  elementAttr={{
                    autocomplete: "nope",
                    autoComplete: "nope",
                  }}
                >
                  <Validator>
                    <RequiredRule message={lh.getMessage(MessagesKeys.ClienteTipoIdentificacionRequerida)} />
                  </Validator>
                </SelectBox>
              </Labeled>
            </CustomCol>
          </RowContainer>
          <RowContainer >
            <CustomCol xs='12' md='4'>
              <Labeled
                label='Identificación'
              >
                <TextBox
                  name='ruc'
                  placeholder='R.U.C / Identificacion'
                  value={ruc}
                  onValueChanged={({ value }: any) => setRuc(value)}
                  elementAttr={{
                    autocomplete: "nope",
                    autoComplete: "nope",
                  }}
                >
                  <Validator>
                    <RequiredRule trim message={lh.getMessage(MessagesKeys.ClienteNumeroIdentificacionRequerido)} />
                    <PatternRule message={lh.getMessage(MessagesKeys.ClienteNumeroIdentificacionIncorrecto, "")} pattern='^[0-9]{5,}|^[0-9]{10}$|^[0-9]{13}$' />
                    <AsyncRule message={lh.getMessage(MessagesKeys.ClienteNumeroIdentificacionIncorrecto, "")} reevaluate={true} ignoreEmptyValue validationCallback={(params) => validateId(params, esNuevo)} />
                  </Validator>
                </TextBox>
              </Labeled>
            </CustomCol>
            <CustomCol xs='12' md="8">
              <Labeled
                label='Dirección'
              >
                {isMobile &&
                  <TextArea
                    value={direccion}
                    onValueChanged={({ value }: any) => setDireccion(value)}
                    height={90}
                  >
                    <Validator>
                      <RequiredRule message={lh.getMessage(MessagesKeys.GlobalCampoRequerido, "Dirección")} />
                    </Validator>
                  </TextArea>
                }
                {!isMobile &&
                  <TextBox
                    value={direccion}
                    onValueChanged={({ value }: any) => setDireccion(value)}
                  >
                    <Validator>
                      <RequiredRule message={lh.getMessage(MessagesKeys.GlobalCampoRequerido, "Dirección")} />
                    </Validator>
                  </TextBox>
                }

              </Labeled>
            </CustomCol>
          </RowContainer>

          <RowContainer className="mt-2" >
            <CustomCol xs='12' md='12' >
              <CCard >
                <CCardBody>
                  <TabContactos
                    tipoAsociado={ETipoAsociado.cliente}
                    data={contactos}
                    actionSelect={(data) => console.log("data", data)}
                    tiposOperadoras={tiposOperadora}
                    returnContactos={(contactos) => setContactos(contactos)}
                  />
                </CCardBody>
              </CCard>
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol xs='12' md='4'>
              <Labeled
                label='Proveedor de Seguros'
              >
                <CheckBox
                  value={proveedorSeguros}
                  onValueChanged={() =>
                    setProveedorSeguros(!proveedorSeguros)
                  }
                />
              </Labeled>
            </CustomCol>
            <CustomCol xs='12' md='4'>
              <Labeled
                label='Artesano con calificación'
              >
                <CheckBox
                  value={artesanoCalificacion}
                  onValueChanged={() =>
                    setArtesanoCalificacion(!artesanoCalificacion)
                  }
                />
              </Labeled>
            </CustomCol>
            <CustomCol xs='12' md='4'>
              <Labeled
                label='Transportista'
              >
                <CheckBox
                  value={transportista}
                  onValueChanged={() =>
                    setTransportista(!transportista)
                  }
                />
              </Labeled>
            </CustomCol>
          </RowContainer>

          <RowContainer>
            <CustomCol xs='12' md='4'>
              <Labeled
                label='Email'
              >
                <TextBox
                  value={email}
                  onValueChanged={({ value }: any) => setEmail(String(value).trim())}
                >
                  <Validator>
                    <RequiredRule message={lh.getMessage(MessagesKeys.GlobalCampoRequerido, "Email")} />
                    <PatternRule pattern={emailPatern} message={lh.getMessage(MessagesKeys.GlobalFormatoCampoIncorrecto, "Email")} />
                  </Validator>
                </TextBox>
              </Labeled>
            </CustomCol>
          </RowContainer>

          <RowContainer>

            <CustomCol xs='12' md='4'>
              <Labeled label='País' >
                <SelectBox
                  id='selectPais'
                  options={paises}
                  placeholder='País'
                  onChange={(value) => {
                    if (value) {
                      setPais(value);
                      cargarProvincias(value)
                    } else {
                      setPais(null);
                      cargarProvincias(null)
                    }
                  }}
                  selected={pais}
                  displayExpr='nombre'
                  keyExpr='codigo'
                  elementAttr={{
                    autocomplete: "nope",
                    autoComplete: "nope",
                  }}
                >
                </SelectBox>
              </Labeled>
            </CustomCol>
            <CustomCol xs='12' md='4'>
              <Labeled label='Provincia' >
                <SelectBox
                  id='selectProvincia'
                  options={provincias}
                  placeholder='Provincia'
                  onChange={(value) => {
                    if (value) {
                      setProvincia(value);
                      cargarCiudades(value)
                    } else {
                      setProvincia(null);
                      cargarCiudades(null)
                    }
                  }}
                  selected={provincia}
                  displayExpr='nombre'
                  keyExpr='codigo'
                  elementAttr={{
                    autocomplete: "nope",
                    autoComplete: "nope",
                  }}
                >
                </SelectBox>
              </Labeled>
            </CustomCol>
            <CustomCol xs='12' md='4'>
              <Labeled label='Ciudad' >
                <SelectBox
                  id='selectCiudades'
                  options={ciudades}
                  placeholder='Ciudad'
                  onChange={(value) => {
                    if (value) {
                      setCiudad(value);
                    } else {
                      setCiudad(null);
                    }
                  }}
                  selected={ciudad}
                  displayExpr='nombre'
                  keyExpr='codigo'
                  elementAttr={{
                    autocomplete: "nope",
                    autoComplete: "nope",
                  }}
                >
                  <Validator>
                    <RequiredRule message={lh.getMessage(MessagesKeys.ProveedorUbicacionRequerida)} />
                  </Validator>
                </SelectBox>
              </Labeled>
            </CustomCol>

          </RowContainer>

          <RowContainer>
            <CustomCol xs='12' md='4'>
              <Labeled
                label='Clasificación'
              >
                <SelectBox
                  id='selectClasificacion'
                  options={clasificaciones}
                  placeholder='Clasificación'
                  onChange={(value) => {
                    if (value) {
                      setClasificacion(value);
                    } else {
                      setClasificacion(null);
                    }
                  }}
                  selected={clasificacion}
                  displayExpr='descripcion'
                  keyExpr='codigo'
                  elementAttr={{
                    autocomplete: "nope",
                    autoComplete: "nope",
                  }}
                >
                  <Validator>
                    <RequiredRule message={lh.getMessage(MessagesKeys.ProveedorClasificacionRequerida)} />
                  </Validator>
                </SelectBox>
              </Labeled>
            </CustomCol>

            <CustomCol xs='12' md='4'>
              <Labeled
                label='Tipo de proveedor'
              >
                <SelectBox
                  id='selectTipoProveedor'
                  options={tiposProveedores}
                  placeholder='Tipo de proveedor'
                  onChange={(value) => {
                    if (value) {
                      setTipoProveedor(value);
                    } else {
                      setTipoProveedor(null);
                    }
                  }}
                  selected={tipoProveedor}
                  displayExpr='descripcion'
                  keyExpr='codigo'
                  elementAttr={{
                    autocomplete: "nope",
                    autoComplete: "nope",
                  }}
                >
                  <Validator>
                    <RequiredRule message={lh.getMessage(MessagesKeys.ProveedorTipoRequerida)} />
                  </Validator>
                </SelectBox>
              </Labeled>
            </CustomCol>

            <CustomCol xs='12' md='4'>
              <Labeled
                label='Estado'
              >
                <SelectBox
                  id='selectEstados'
                  options={tiposEstados}
                  placeholder='Estado'
                  onChange={(value) => {
                    if (value !== undefined) {
                      setEstado(value);
                    } else {
                      setEstado(null);
                    }
                  }}
                  selected={estado}
                  displayExpr='tipo'
                  keyExpr='codigo'
                  elementAttr={{
                    autocomplete: "nope",
                    autoComplete: "nope",
                  }}
                >
                  <Validator>
                    <RequiredRule message={lh.getMessage(MessagesKeys.EstadoRequerido)} />
                  </Validator>
                </SelectBox>
              </Labeled>
            </CustomCol>
            {((contratoEmpresaState !== undefined) && (contratoEmpresaState.codigoPlanVigente) && availableInventario === true) &&

              <>
                <CustomCol xs='12' md='4'>
                  <Labeled
                    label='Intervalo de reposición'
                  >
                    <NumberBox
                      name="reposicion"
                      id="reposicion"
                      value={reposicion}
                      onValueChanged={(data) => {
                        if (data.event !== undefined) {
                          setReposicion(Number(data.value))
                        }
                      }}
                      showClearButton={true}
                    />
                  </Labeled>
                </CustomCol>
                <CustomCol xs='12' md='4'>
                  <Labeled
                    label='Día de visíta'
                  >
                    <SelectBox
                      id='selectDiasVisita'
                      options={diasVisitas}
                      placeholder=''
                      onChange={(value) => {
                        if (value !== undefined) {
                          setDiaVisita(value);
                        } else {
                          setDiaVisita(null);
                        }
                      }}
                      selected={diaVisita}
                      displayExpr='valor'
                      keyExpr='codigo'
                      elementAttr={{
                        autocomplete: "nope",
                        autoComplete: "nope",
                      }}
                    >
                      <Validator>
                      </Validator>
                    </SelectBox>
                  </Labeled>
                </CustomCol>
              </>
            }
          </RowContainer>

          {isMobileOnly &&
            (
              <>
                <RowContainer className="ms-2">
                  <CustomCol>
                    <ValidationSummary />
                  </CustomCol>
                </RowContainer>
                {showErrorPopup && (
                  < PopupContent
                    show={showErrorPopup}
                    title={"Acatha"}
                    subTitle={"Por favor revise las siguientes advertencias:"}
                    onClose={() => {
                      setShowErrorPopup(false);
                      setShowErrorMessages([]);
                    }}
                    size={"sm"}
                    canClose={true}
                  >
                    <CAlert color='danger'>
                      <ul>
                        {showErrorMessages.map(function (item, id) {
                          return <li key={id}>{item['message']}</li>
                        })}
                      </ul>
                    </CAlert>
                  </PopupContent>
                )}
              </>
            )
          }



        </ValidationGroup>
      </fieldset >

      <RowContainer>
        <CustomCol xs='12' md='4'>
        </CustomCol>
      </RowContainer>
    </>
  );
}
export default memo(FormularioEdicion);
