/* eslint-disable no-undef */

import { configureStore, createReducer, createSlice } from '@reduxjs/toolkit'
import { combineReducers, applyMiddleware } from 'redux'
import reducer from './reducers'
import {
  DashboardsState,
  GlobalState,
  TAlertNotification,
  TToaster,
} from './types'
import logger from 'redux-logger'
import { getDefaultMiddleware } from '@reduxjs/toolkit'
import { saveState } from './storageRedux'
import { throttle } from 'lodash'
import { contabilidadReducer } from '../modulos/contabilidad/store/contabilidadReducers'
import { ContabilidadState } from '../modulos/contabilidad/store/types'
import { moduloBancosReducer } from '../modulos/bancos/store/moduloBancosReducers'
import { toasterReducer } from './toasterReducer'
import { dashboardsReducer } from './dashboardsReducer'
import { WizardState } from '../containers/component/manualConfig/store/types'
import { WizardReducer } from '../containers/component/manualConfig/store/WizardRecucers'
import { moduloVentasReducer } from '../modulos/ventas/store/moduloVentasReducer'
import { ModuloVentasState } from '../modulos/ventas/types/types'
import { ModuloBancosState } from '../modulos/bancos/store/types'
import { ModuloComprasState } from '../modulos/compras/types/types'
import { moduloComprasReducer } from '../modulos/compras/store/moduloComprasReducer'
import { moduloProveedoresReducer } from '../modulos/proveedores/store/moduloProveedoreseducers'
import { ModuloProveedoresState } from '../modulos/proveedores/store/types'
import { ModuloNominaState } from '../modulos/nomina/store/types'
import { ModuloNominaReducer } from '../modulos/nomina/store/moduloUsuariosReducers'
import { ModuloAdminState } from '../modulos/admin/store/types'
import { moduloAdministracionReducer } from '../modulos/admin/store/moduloAdminReducers'
// import { ModuloRestauranteState } from '../modulos/restaurante/types/types';
// import { restauranteReducer } from '../modulos/restaurante/store/restauranteReducer';
import { sharedReducer } from '../modulos/shared/store/sharedReducer'
import { SharedState } from '../modulos/shared/types/types'
import { ModuloClientesState } from '../modulos/clientes/store/types'
import { moduloClientesReducer } from '../modulos/clientes/store/moduloClientesReducers'
import { moduloImpuestosReducer } from '../modulos/impuestos/store/moduloImpuestosReducers'
import { ModuloImpuestosState } from '../modulos/impuestos/types/types'
import { PagosSuscripcion } from '../containers/component/registraPagos/type/type'
import { pagosSuscripcionReducer } from '../containers/component/registraPagos/store/pagosSuscripcionReducer'
import { UpgrateState } from '../modulos/cuenta/upgrate/type/type'
import { suscripcionUpgrateReducer } from '../modulos/cuenta/upgrate/store/upgrateReducer'
import { pastDuePaymentsReducer } from '../modulos/cuenta/deudas/store/pastduePaymentsReducer'
import { DeuPyDuePayments } from '../modulos/cuenta/deudas/type/type'
import { notificationsReducer } from './notificationsReducer'
import { ModuloInventariosState } from '../modulos/inventario/store/types'
import { moduloInventarioReducer } from '../modulos/inventario/store/moduloInventarioReducers'
import { ModuloImpuestosSVState } from '../modulos/ventas/pages/sv/impuestos/types/types'
import { moduloImpuestosSVReducer } from '../modulos/ventas/pages/sv/impuestos/store/moduloImpuestosSVReducers'

export interface RootState {
  global: GlobalState
  toaster: TToaster
  notificaciones: TAlertNotification
  dashboards: DashboardsState
  wizard: WizardState
  ventas: ModuloVentasState
  contabilidad: ContabilidadState
  bancos: ModuloBancosState
  compras: ModuloComprasState
  proveedores: ModuloProveedoresState
  nomina: ModuloNominaState
  administracion: ModuloAdminState
  // restaurante: ModuloRestauranteState,
  shared: SharedState
  clientes: ModuloClientesState
  impuestos: ModuloImpuestosState
  impuestosSV: ModuloImpuestosSVState
  pagosSuscripcion: PagosSuscripcion
  suscripcionUpgrate: UpgrateState
  deuPyDuePayments: DeuPyDuePayments
  inventarios: ModuloInventariosState
}

const appReducer = combineReducers<RootState>({
  global: reducer,
  toaster: toasterReducer,
  notificaciones: notificationsReducer,
  dashboards: dashboardsReducer,
  wizard: WizardReducer,
  ventas: moduloVentasReducer,
  contabilidad: contabilidadReducer,
  bancos: moduloBancosReducer,
  compras: moduloComprasReducer,
  proveedores: moduloProveedoresReducer,
  nomina: ModuloNominaReducer,
  administracion: moduloAdministracionReducer,
  //   restaurante: restauranteReducer,
  shared: sharedReducer,
  clientes: moduloClientesReducer,
  impuestos: moduloImpuestosReducer,
  impuestosSV: moduloImpuestosSVReducer,
  pagosSuscripcion: pagosSuscripcionReducer,
  suscripcionUpgrate: suscripcionUpgrateReducer,
  deuPyDuePayments: pastDuePaymentsReducer,
  inventarios: moduloInventarioReducer,
})

//const rootInitialState: { appState: RootState }

// const rootSlice = createSlice({
//   name: 'appState',
//   initialState: (undefined as unknown) as RootState,
//   reducers: {
//     resetRedux(state, action) {

//     }
//   }
// })

const rootReducer = (state, action) => {
  return appReducer(state, action)
}

const middlewares = getDefaultMiddleware()

if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger)
}

const store = configureStore({
  reducer: rootReducer,
  middleware: middlewares,
})

store.subscribe(
  throttle(() => {
    saveState(store.getState().global.session)
  }, 1000),
)

export default store
