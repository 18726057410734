import { utilidades } from '../utilidades'
import { AllowDteRetention } from './allowDte'

export const JsonDteElectronicHelper = {
  openFile,
  readJsonFile,
  extractNumber,
}

async function openFile(file: any) {
  let text: any = ''
  const reader = new FileReader()
  const p = new Promise(async (resolve) => {
    while (true) {
      await utilidades.sleep(50)
      if (text) break
    }
    resolve(text)
  })
  const onload = function (event) {
    text = reader.result
    //parseFile(text);
  }
  reader.onload = onload
  reader.readAsText(file)
  await p
  if (typeof text == 'string') {
    return text
  }
  return undefined
}

async function readJsonFile(datosJSON: any) {
  if (datosJSON == '') {
    return ''
  }
  if (typeof datosJSON == 'string') {
    return ''
  }

  const items = Object.values(AllowDteRetention)
  const keys = Object.keys(datosJSON)
  let dteAvailable = ''
  for (const item of items) {
    if (keys.includes(item)) {
      dteAvailable = item
      break
    }
  }
  if (dteAvailable !== '') {
    const infoDteIdentification = datosJSON[dteAvailable].dteJson.identificacion
    const infoDteSummary = datosJSON[dteAvailable].dteJson.resumen
    const infoDteEmisor = datosJSON[dteAvailable].dteJson.emisor
    const infoDteCuerpoDocumento =
      datosJSON[dteAvailable].dteJson.cuerpoDocumento

    const formasPago = infoDteSummary?.pagos ?? []
    let ivaUtilizado = 0
    if (infoDteSummary.totalGravada > 0) {
      ivaUtilizado =
        infoDteSummary?.tributos?.[0].valor / infoDteSummary.totalGravada / 100
    }

    // const numeroDte = obtenerUltimosOnceDigitos(
    //   infoDteIdentification.numeroControl,
    // )

    return {
      numeroAutorizacion: infoDteIdentification.codigoGeneracion,
      tipoDte: infoDteIdentification.tipoDte,
      fecEmi: infoDteIdentification.fecEmi,
      numeroControl: infoDteIdentification.numeroControl,
      subtotalSinIva: infoDteSummary.totalExenta,
      subtotalIva: infoDteSummary.totalGravada,
      iva: ivaUtilizado ?? 0,
      receptorIdentificacion: infoDteEmisor.nit,
      formasPago: formasPago,
      cuerpoDocumento: infoDteCuerpoDocumento,
    }
  }
  return false
}

function extractNumber(cadena: string): string {
  // Verificar que la cadena tenga al menos 11 caracteres
  if (cadena.length < 24) {
    throw new Error('La cadena debe tener al menos 24 caracteres.')
  }
  let eliminaLetras = cadena.substring(4)
  eliminaLetras = eliminaLetras.replace(/-/g, '')

  const part1 = eliminaLetras.slice(0, 4)
  const part2 = eliminaLetras.slice(4, 8)
  const part3 = eliminaLetras.slice(8, 19)

  return `${part1}-${part2}-${part3}`
}
