import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CustomDictionary, LoaderInfo } from "../../../../../store/types";
import { DescuentosDatosEdicion, NominaDatosEdicion, RolesPagoDatosEdicion, SeleccionadoDescuento } from "../types/types";
import { EdicionPayload } from '../../../store/types';


const initialState: CustomDictionary<NominaDatosEdicion> = {}


const datosNominaEditSlice = createSlice({
  name: 'organicoNominaEdicion',
  initialState: initialState,
  reducers: {
    deleteEditDataNomina(state, action: PayloadAction<string>) {
      delete state[action.payload]
    },
    initDatosEdicion(
      state,
      action: PayloadAction<EdicionPayload<NominaDatosEdicion>>,
    ) {
      if (!state[action.payload.key]) {
        state[action.payload.key] = action.payload.data
      }
    },
    setDatosEdicion(
      state,
      action: PayloadAction<EdicionPayload<NominaDatosEdicion>>,
    ) {
      state[action.payload.key] = action.payload.data
    },
    setDatosEdicionRoles(
      state,
      action: PayloadAction<EdicionPayload<RolesPagoDatosEdicion>>,
    ) {
      state[action.payload.key].rolesPago = action.payload.data
    },
    setDatosEdicionDescuento(
      state,
      action: PayloadAction<EdicionPayload<DescuentosDatosEdicion>>,
    ) {
      state[action.payload.key].descuentos = action.payload.data
    },
    setChangeLoader(state, action: PayloadAction<EdicionPayload<LoaderInfo>>) {
      state[action.payload.key].loader = action.payload.data
    },
    setSeleccionadoDescuento(
      state,
      action: PayloadAction<EdicionPayload<SeleccionadoDescuento>>,
    ) {
      state[action.payload.key].descuentos.seleccionadoDescuento = action.payload.data
    },
    setResetSeleccionadoDescuento(
      state,
      action: PayloadAction<EdicionPayload<null>>,
    ) {
      state[action.payload.key].descuentos.seleccionadoDescuento = action.payload.data
    },
  }
})


export const {
  setDatosEdicion,
  initDatosEdicion,
  setDatosEdicionDescuento,
  setDatosEdicionRoles,
  deleteEditDataNomina,
  setChangeLoader,
  setSeleccionadoDescuento,
  setResetSeleccionadoDescuento,
} = datosNominaEditSlice.actions
export const dataNominaEditReducer = datosNominaEditSlice.reducer