import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StatesEdition, TabTypes } from "../../../../../../store/enums";
import { TabsState } from "../../../../../../store/genericTabTypes";
import { ButtonTypes } from "../../../../../../views/componentes/globalMenu/types";
import { CustomButtons } from "../../../../../../store/types";


const initialState: TabsState<any> = {
  current: 'BUSQUEDA',
  tabs: {
    BUSQUEDA: {
      id: 0,
      canClose: false,
      editStatus: StatesEdition.doesNotApply,
      globalButtonClick: ButtonTypes.none,
      tittle: 'Calendario',
      type: TabTypes.busqueda,
      imprimir: false,
      tabKey: 'BUSQUEDA',
      buttons: {
        Limpiar: false,
        Deshacer: true,
        Imprimir: false,
      },
    },
  },
}

const calendarTabsSlice = createSlice({
  name: 'calendarioTabs',
  initialState: initialState,
  reducers: {
    setButtons(
      state,
      action: PayloadAction<{ tabKey: string; buttons: CustomButtons }>,
    ) {
      const { buttons, tabKey } = action.payload
      state.tabs[tabKey].buttons = buttons
      state.tabs[tabKey].currentExecutingAction = undefined
    },
    buttonClick(
      state,
      action: PayloadAction<{ tabKey: string; button: ButtonTypes }>,
    ) {
      state.tabs[action.payload.tabKey].globalButtonClick =
        action.payload.button
    },
    clearButtonClick(state, action: PayloadAction<string>) {
      if (Object.prototype.hasOwnProperty.call(state.tabs, action.payload)) {
        state.tabs[action.payload].globalButtonClick = ButtonTypes.none
      }
    },
    setCurrentExecutingAction(
      state,
      action: PayloadAction<{
        tabKey: string
        buttonType: ButtonTypes | undefined
      }>,
    ) {
      const { buttonType, tabKey } = action.payload
      state.tabs[tabKey].currentExecutingAction = buttonType
    },
  }
})

export const {
  setButtons,
  buttonClick,
  clearButtonClick,
  setCurrentExecutingAction,
} = calendarTabsSlice.actions
export const tabsReducer = calendarTabsSlice.reducer