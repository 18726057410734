import React from 'react'
import { TabState } from '../../../../../../../store/genericTabTypes'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import { DataGrid } from 'devextreme-react'
import { Column, ColumnChooser, Export, Pager, Paging } from 'devextreme-react/data-grid'
import { getAllowedPageSizes } from '../../../../../../../helpers/Helper'
import { Workbook } from 'exceljs'
import { exportDataGrid } from 'devextreme/excel_exporter';
import { saveAs } from 'file-saver';
import ItemsCountIndicator from '../../../../../../componentes/indicadorNroRegistros'
import { FiltrosImportacionesState, ImportacionesDatosEdicion, MovimientosResumenDto } from '../../types/types'
import { CCardFooter } from '@coreui/react-pro'
import { ReportWithLocalDataModal } from '../../../../../../../views/componentes/xtrareports/ReportWithLocalDataModal'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import { consoleService } from '../../../../../../../services/console.service'

interface ResulatdosBusquedaProps extends React.PropsWithChildren {
  tab: TabState<ImportacionesDatosEdicion>
  tabId: string,
  data: Array<any>
  setReporte: (data: null | "Viewer" | "Designer") => void
  reporte: null | "Viewer" | "Designer"
}


const ResultadosBusqueda: React.FunctionComponent<ResulatdosBusquedaProps> = (props) => {
  const { data, setReporte, reporte } = props
  const dataGrid = React.useRef<any>()
  const searchFilter = useSelector((state: RootState) => state.inventarios.movimientos.importaciones.search.filterResumen)

  const onExportExcell = React.useCallback((e) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('Main sheet')
    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Resumen_Productos_Inventario.xlsx')
      });
    });
  }, [])

  const getFilterString = React.useCallback((filter: FiltrosImportacionesState) => {
    const filterText = ''
    return filterText
  }, [])


  const onParseData = React.useCallback((ingresosEgresos: Array<any>) => {
    let registros: Array<MovimientosResumenDto> = [];
    let registro: MovimientosResumenDto = null

    if (ingresosEgresos.length > 0) {
      registros = ingresosEgresos.map(item => {
        registro = {
          Code: item?.barras ?? '',
          Description: item?.descripcion ?? '',
          Group: item?.grupoDescripcion ?? '',
          Line: item?.lineaDescripcion ?? '',
          Brand: item?.marcaDescripcion ?? '',
          Stock: item?.existencia ?? 0,
          Cost: item?.costo ?? 0,
          Total: item?.costoTotal ?? 0,
        }
        return registro
      })
      registros.push(registro)
    }

    consoleService.log(registros)
    return registros

  }, []);

  return (
    <>
      <ReportWithLocalDataModal
        show={reporte !== null}
        onClose={() => setReporte(null)}
        data={onParseData(data)}
        fileName='StaticIngresosEgresosSummary'
        mode={reporte ?? 'Viewer'}
        parameters={{ 'Reporte_Filtro': getFilterString(searchFilter) }}
        template='StaticIngresosEgresosSummary'
        key='reportDesigner'
      />
      <ItemsCountIndicator items={data ?? []} />
      <DataGrid
        style={{ marginTop: '10px' }}
        ref={dataGrid}
        keyExpr="codigo"
        focusedRowKey="codigo"
        id="tabCertificados"
        selection={{ mode: 'single' }}
        dataSource={data ?? []}
        showColumnLines={true}
        showRowLines={true}
        showBorders={true}
        columnResizingMode="widget"
        focusedRowEnabled={true}
        onSelectionChanged={() => { }}
        onExporting={onExportExcell}
        loadPanel={{
          enabled: true,
          height: 90,
          indicatorSrc: '',
          shading: false,
          shadingColor: '',
          showIndicator: true,
          showPane: true,
          text: 'Cargando...',
          width: 200,
        }}
      >
        <Paging defaultPageSize={10} />
        <Pager
          visible={data.length > 0 ? true : false}
          allowedPageSizes={getAllowedPageSizes(data)}
          displayMode={'full'}
          showPageSizeSelector={true}
          showInfo={true}
          showNavigationButtons={true}
        />
        <ColumnChooser enabled={true} mode="select" />
        <Export enabled={true} allowExportSelectedData={true} />
        <Column dataField="barras" caption="Código" width="100px" />
        <Column dataField="descripcion" caption="Descripción" width="350px" />
        <Column dataField="grupoDescripcion" caption="Grupo" width="150px" />
        <Column dataField="lineaDescripcion" caption="Línea" width="150px" />
        <Column dataField="marcaDescripcion" caption="Marca" width="150px" />
        <Column dataField="existencia" caption="Stock" width="100px" />
        <Column dataField="costo" caption="Costo" width="100px" />
        <Column dataField="costoTotal" caption="Total" width="100px" />
      </DataGrid>
    </>
  )
}

export default React.memo(ResultadosBusqueda)