import React from 'react'
import { TabState } from '../../../../../../store/genericTabTypes'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput'
import { List } from 'devextreme-react'
import Arbol from '../../../../../contabilidad/pages/planCuentas/components/arbol'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/store'
import { PersonalServices } from '../../services/personal.service'
import { consoleService } from '../../../../../../services/console.service'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import { setCurrentExecutingAction } from '../../store/tabsReducer'
import { ToastTypes } from '../../../../../../store/types'
import { addToast } from '../../../../../../store/toasterReducer'
import { setChangeLoader, setInitital, setResetSeleccion, setResetSeleccionRol, setResults, setResultsRol, setResultsUsers, setResultsUsersRol, setSeleccionarDato, setSeleccionarDatoRol, setSeleccionarDatoRoles } from '../../store/organigramaReducer'
import LoadingIndicator from '../../../../../../views/componentes/loadingindicator/loadingindicator'
import BlockUi from '../../../../../../views/componentes/librerias/block-ui'
import { rolesUsuariosService } from '../../../../../admin/rolesUsuario/services/rolesUsuariosService'
import { CButton } from '@coreui/react-pro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SaveRollUsuario } from '../../types/types'

interface IAsignarProps extends React.PropsWithChildren {
  tab: TabState<any>
  tabId: string
}

const Asignar: React.FunctionComponent<IAsignarProps> = (props) => {

  const { tabId } = props

  const dispatch = useDispatch()

  const modulo = useSelector((state: RootState) => state.nomina.personal.config.aplicacion)
  const loader = useSelector((state: RootState) => state.nomina.personal.organigrama.loader)
  const inicial = useSelector((state: RootState) => state.nomina.personal.organigrama.initial)
  const resultados = useSelector((state: RootState) => state.nomina.personal.organigrama.resultadosOrganigrama)
  const resultadosRol = useSelector((state: RootState) => state.nomina.personal.organigrama.resultadosRoles)
  const resultadosUsuarios = useSelector((state: RootState) => state.nomina.personal.organigrama.resultadosUsuarios)
  const resultadosUsuariosRol = useSelector((state: RootState) => state.nomina.personal.organigrama.resultadosUsuariosRol)
  const seleccionadoRoles = useSelector((state: RootState) => state.nomina.personal.organigrama.seleccionadoRoles)
  const seleccionadoUsuarios = useSelector((state: RootState) => state.nomina.personal.organigrama.seleccionadoUsuarios)
  const seleccionadoUsuariosRol = useSelector((state: RootState) => state.nomina.personal.organigrama.seleccionadoUsuariosRol)

  const onLoader = React.useCallback((showLoader: boolean, mensaje: string) => {
    dispatch(setChangeLoader({
      show: showLoader,
      mensaje: mensaje
    }))
  }, [dispatch])

  const onSetButtonAction = React.useCallback(
    (tipo: ButtonTypes | undefined) => {
      dispatch(
        setCurrentExecutingAction({
          tabKey: tabId,
          buttonType: tipo,
        }),
      )
    },
    [dispatch, tabId],
  )

  const setToast = React.useCallback(
    (mensaje: string, tipo: ToastTypes) => {
      dispatch(
        addToast({
          title: 'Nómina - Personal/Organigrama',
          content: mensaje,
          type: tipo,
        }),
      )
    },
    [dispatch],
  )


  const onGetDataInit = React.useCallback(async () => {
    onSetButtonAction(ButtonTypes.assign)
    onLoader(true, 'Cargando...')
    try {
      let newAray = []
      const data = await PersonalServices.getXMLOrganico(0)
      consoleService.log(data, 'dataOrg')
      if (data['auto'] && data['error'] === false) {
        setToast(data['message'], ToastTypes.Success)
        newAray = data['auto'].map(x => {
          return {
            codigo: x?.codigo ?? 0,
            numero: '',
            descripcion: x?.descripcion ?? '',
            padre: x?.padre ?? 0,
            estado: x?.estado ?? 0,
            observaciones: x?.siglas ?? '',
          }
        })
        dispatch(setResults(newAray))
      } else {
        setToast(data['message'], ToastTypes.Danger)
        dispatch(setResults(newAray))
      }
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
    onLoader(false, '')
    onSetButtonAction(undefined)
  }, [setToast, onLoader, onSetButtonAction, dispatch])


  React.useEffect(() => {
    if (inicial) {
      onGetDataInit()
      dispatch(setInitital(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSearchUsuarios = React.useCallback(async (seleccionado) => {
    try {
      const data = await rolesUsuariosService.verificarUsuariosAsignar(seleccionado?.organicoRolCodigo ?? 0)
      const dataAsign = await rolesUsuariosService.verificarUsuariosAsignados(seleccionado?.organicoRolCodigo ?? 0)
      consoleService.log(data, '___asignar')
      consoleService.log(dataAsign, '___asignado')
      if (data['auto'] && data['error'] === false) {
        dispatch(setResultsUsers(data['auto']))
        dispatch(setSeleccionarDatoRoles({ ...seleccionado }))
      }
      if (dataAsign['auto'] && dataAsign['error'] === false) {
        dispatch(setResultsUsersRol(dataAsign['auto']))
      }
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
  }, [dispatch, setToast])

  const onSelectionChangeRol = React.useCallback(async ({ addedItems }) => {
    consoleService.log(addedItems[0], 'Search rol')
    onSetButtonAction(ButtonTypes.assign)
    onLoader(true, 'Cargando...')
    await onSearchUsuarios(addedItems[0])
    onLoader(false, '')
    onSetButtonAction(undefined)
  }, [onLoader, onSetButtonAction, onSearchUsuarios])

  const onGetDataEdit = React.useCallback(async (data) => {
    onSetButtonAction(ButtonTypes.assign)
    onLoader(true, 'Cargando...')
    try {
      const dataAsing = await rolesUsuariosService.verificarRoles(data?.id ?? 0);
      consoleService.log(dataAsing, '____dataAsing')
      if (dataAsing['auto'] && dataAsing['error'] === false) {
        setToast(dataAsing['message'], ToastTypes.Success)
        dispatch(setResultsRol(dataAsing['auto']))
      } else {
        setToast(dataAsing['message'], ToastTypes.Danger)
      }
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
    onLoader(false, '')
    onSetButtonAction(undefined)
  }, [setToast, onLoader, onSetButtonAction, dispatch])

  const onSelectionChangeUser = React.useCallback(({ addedItems }) => {
    consoleService.log(addedItems[0], 'Usurios select')
    if (addedItems[0]?.codigoUsuario) {
      dispatch(setSeleccionarDato({ ...addedItems[0] }))
    }
  }, [dispatch])


  const onSelectionChangeUserRol = React.useCallback(({ addedItems }) => {
    consoleService.log(addedItems[0], 'Usurios Rol select')
    if (addedItems[0]?.codigoUsuario) {
      dispatch(setSeleccionarDatoRol({ ...addedItems[0] }))
    }
  }, [dispatch])


  const onAsignRolUser = React.useCallback(async () => {
    consoleService.log(seleccionadoRoles, 'seleccionadoRoles')
    consoleService.log(seleccionadoUsuarios, 'seleccionadoUsuarios')
    if (seleccionadoRoles?.organicoRolCodigo && seleccionadoUsuarios?.codigoUsuario) {
      const save_data: SaveRollUsuario = {
        organicoRolCodigo: seleccionadoRoles?.organicoRolCodigo ?? 0,
        usuarioCodigo: seleccionadoUsuarios?.codigoUsuario ?? 0,
      }
      onSetButtonAction(ButtonTypes.assign)
      onLoader(true, 'Guardando...')
      try {

        const data = await rolesUsuariosService.crearAsignacion([save_data])
        if (data['auto'] && data['error'] === false) {
          setToast(data['message'], ToastTypes.Success)
          await onSearchUsuarios(seleccionadoRoles)
          dispatch(setResetSeleccion())
        } else {
          setToast(data['message'], ToastTypes.Danger)
        }
      } catch (error) {
        setToast(error.message, ToastTypes.Danger)
      }
      onLoader(false, '')
      onSetButtonAction(undefined)
    }
  }, [seleccionadoRoles, seleccionadoUsuarios, onLoader, onSetButtonAction, setToast, onSearchUsuarios, dispatch])


  const onDeleteRolUser = React.useCallback(async () => {
    consoleService.log(seleccionadoUsuariosRol, 'seleccionadoUsuariosRol')
    if (seleccionadoUsuariosRol?.codigoUsuario) {

      onSetButtonAction(ButtonTypes.assign)
      onLoader(true, 'Eliminado...')
      try {
        const data = await rolesUsuariosService.removerAsignacion([{ organicoRolUsuCodigo: seleccionadoUsuariosRol?.organicoRolUsuario ?? 0 }])
        consoleService.log(data, 'data delete')
        if (data['auto'] && data['error'] === false) {
          setToast(data['message'], ToastTypes.Success)
          await onSearchUsuarios(seleccionadoRoles)
          dispatch(setResetSeleccionRol())
        } else {
          setToast(data['message'], ToastTypes.Danger)
        }
      } catch (error) {
        setToast(error.message, ToastTypes.Danger)
      }
      onLoader(false, '')
      onSetButtonAction(undefined)
    }
  }, [seleccionadoUsuariosRol, onLoader, onSetButtonAction, seleccionadoRoles, setToast, onSearchUsuarios, dispatch])

  return (
    <>
      <div id="PersonalContent">
        <BlockUi
          tag="div"
          loader={LoadingIndicator}
          blocking={loader.show}
          message={loader.mensaje}
        >
          <RowContainer>
            <CustomCol xs="12" md="3">
              <Labeled label='Organigrama:'>
                <Arbol
                  name="ArbolAsignar"
                  data={resultados ?? []}
                  jerarquiaName='Organigrama'
                  activedense={false}
                  sendData={(data) => {
                    if (data?.filename !== "Organigrama") {
                      onGetDataEdit(data)
                    }
                  }}
                  modulo={modulo} />
              </Labeled>
            </CustomCol>
            <CustomCol xs="12" md="2">
              <Labeled label='Roles:'>
                <List
                  dataSource={resultadosRol ?? []}
                  selectionMode='single'
                  keyExpr="organicoRolCodigo"
                  displayExpr='rolDescripcion'
                  onSelectionChanged={onSelectionChangeRol}
                  height={500}
                />
              </Labeled>
            </CustomCol>
            <CustomCol xs="12" md="3">
              <Labeled label='Usuarios:'>
                <List
                  dataSource={resultadosUsuarios ?? []}
                  keyExpr="codigoUsuario"
                  displayExpr='nombresUsuario'
                  height={500}
                  onSelectionChanged={onSelectionChangeUser}
                  selectionMode='single'
                />
              </Labeled>
            </CustomCol>
            <CustomCol xs="12" md="1">
              <div
                style={{
                  display: 'grid',
                  gap: '2px',
                  alignContent: 'center',
                  justifyContent: 'center',
                  height: '100%',
                  width: '100%',
                }}
              >

                <CButton
                  id="btnBuscarCuentas"
                  color="secondary"
                  className="ms-1"
                  disabled={seleccionadoUsuarios === null}
                  size="sm"
                  onClick={() => onAsignRolUser()}
                >
                  <FontAwesomeIcon
                    size="lg"
                    icon={['fal', 'chevron-right']}
                    style={{ fontWeight: 1 }}
                  />
                  <FontAwesomeIcon
                    size="lg"
                    icon={['fal', 'chevron-right']}
                    style={{ fontWeight: 1 }}
                  />

                </CButton>
                <CButton
                  id="btnBuscarCuentas"
                  color="secondary"
                  className="ms-1"
                  disabled={seleccionadoUsuariosRol === null}
                  size="sm"
                  onClick={() => onDeleteRolUser()}
                >
                  <FontAwesomeIcon
                    size="lg"
                    icon={['fal', 'chevron-left']}
                    style={{ fontWeight: 1 }}
                  />
                  <FontAwesomeIcon
                    size="lg"
                    icon={['fal', 'chevron-left']}
                    style={{ fontWeight: 1 }}
                  />
                </CButton>
              </div>
            </CustomCol>
            <CustomCol xs="12" md="3">
              <Labeled label='Asignados:'>
                <List
                  dataSource={resultadosUsuariosRol ?? []}
                  onSelectionChanged={onSelectionChangeUserRol}
                  selectionMode='single'
                  keyExpr="organicoRolUsuario"
                  displayExpr='nombresUsuario'
                  height={500}
                />
              </Labeled>
            </CustomCol>
          </RowContainer>
        </BlockUi>
      </div>
    </>
  )
}

export default React.memo(Asignar)