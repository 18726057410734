import CustomStore from "devextreme/data/custom_store"
import { RequestHelper } from "../../../../../../helpers/requestHelper"
import { PagedResult, PaginationInfo } from "../../../../../../store/types"
import { CalculateTenth, DecimoTipo, FiltroBudquedaDecios, SaveAllData, SaveList, TenthList } from "../types/types"
import { useCallback } from "react"
import { useDispatch } from "react-redux"
import DataSource from "devextreme/data/data_source"
import { setNroRegistrosEncontradasF, setNroRegistrosEncontradasT, setSearchC, setSearchT } from "../store/searchReducers"
import { EAplicationsAcatha } from "../../../../../../store/enumsAplication"


export const TheenthServices = {
  useSetDataSources,
  deletTenth,
  saveListTenth,
  payListTenth,
  calculateTenth,
  SaveAll,
}



async function getTenthPaged(
  filter: FiltroBudquedaDecios,
  pagination: PaginationInfo,
  currentApp: number
): Promise<PagedResult<TenthList>> {

  const queryData = {
    nombre: filter?.nombre ?? '',
    identificacion: filter?.identificacion ?? '',
    diario: filter?.periodo?.diario ?? 0,
    tipo: (currentApp === parseInt(EAplicationsAcatha.DecimoTercero)) ? DecimoTipo.tercero : DecimoTipo.cuarto,
    estado: filter?.estado?.value ?? 0,
    isPaged: true,
    page: pagination.current,
    limit: pagination.size,
  }

  const apiDataPaged = await RequestHelper.get<any>(
    'nomina/roles/decimos',
    'getAll',
    '',
    queryData,
  )
  const apiData = apiDataPaged.auto as Array<TenthList>
  const decimos = apiData
  const pagedResult: PagedResult<TenthList> = {
    currentPage: parseInt(apiDataPaged.paginaActual),
    from: parseInt(apiDataPaged.desde),
    to: parseInt(apiDataPaged.hasta),
    count: parseInt(apiDataPaged.totalRegistros),
    result: decimos,
  }
  return pagedResult
}



function useSetDataSources() {
  const dispatch = useDispatch()
  const geTenthDatasource = useCallback(
    (find: FiltroBudquedaDecios, currentApp: number) => {
      const store = new CustomStore<void, TenthList>({
        useDefaultSearch: true,
        key: 'codigo',
        byKey: async (key) => {
          console.log(key)
        },
        load: async (loadOptions) => {
          const paginInfo: PaginationInfo = {
            current: (loadOptions.skip ?? 0) / (loadOptions.take ?? 0) + 1,
            size: loadOptions.take ?? 10,
          }

          let pagedResult: PagedResult<TenthList> = {
            currentPage: 0,
            from: 0,
            to: 0,
            count: 0,
            result: [],
          }

          pagedResult = await getTenthPaged(find, paginInfo, currentApp)
          if (pagedResult.result.length === 0 && currentApp === parseInt(EAplicationsAcatha.DecimoTercero)) {
            dispatch(setSearchT(false))
          } else {
            dispatch(setSearchC(false))
          }
          return {
            data: pagedResult.result,
            totalCount: pagedResult.count,
          }
        },
        update: async (data) => {
          return Promise.resolve()
        },
      })
      const dataSource = new DataSource({
        totalCount: store.totalCount,
        paginate: true,
        pageSize: 10,
        store: store,
        key: 'codigo',
        requireTotalCount: true,
        onChanged: () => {
          if (currentApp === parseInt(EAplicationsAcatha.DecimoTercero)) {
            dispatch(setNroRegistrosEncontradasT(dataSource.totalCount()))
          } else {
            dispatch(setNroRegistrosEncontradasF(dataSource.totalCount()))
          }
        },
      })
      return dataSource
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return { geTenthDatasource }
}

async function deletTenth(codigo: number) {
  try {
    const obj = {
      decimo: codigo
    }
    const dataApi = await RequestHelper.deleteRequestAll<any>('nomina/roles/decimos', 'delete', '', obj)
    return dataApi
  } catch (error) {
    return error
  }
}

async function saveListTenth(data: SaveList) {
  try {
    const obj = {
      ...data
    }
    const dataApi = await RequestHelper.postAll<any>('nomina/roles/decimos', 'updateAll', obj)
    return dataApi
  } catch (error) {
    return error
  }
}

async function payListTenth(decimos: Array<TenthList>) {
  try {
    const obj = {
      decimos
    }
    const dataApi = await RequestHelper.postAll<any>('nomina/roles/decimos', 'payAll', obj)
    return dataApi
  } catch (error) {
    return error
  }
}

async function calculateTenth(data: CalculateTenth) {
  try {
    const obj = {
      ...data
    }
    const dataApi = await RequestHelper.getAll<any>('nomina/roles/decimos', 'calculateAll', '', obj)
    return dataApi
  } catch (error) {
    return error
  }
}

async function SaveAll(data: SaveAllData) {
  try {
    const obj = {
      ...data
    }
    const dataApi = await RequestHelper.postAll<any>('nomina/roles/decimos', 'saveAll', obj)
    return dataApi
  } catch (error) {
    return error
  }
}