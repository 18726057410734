import React from 'react'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput'
import { Button, CheckBox, DateBox, NumberBox, RadioGroup, TextArea, TextBox, Validator } from 'devextreme-react'
import CargosDepartamentosLookup from '../../../../../../componentes/cargosDepartamentosLookup'
import { CargoDepartamento } from '../../../../../../componentes/cargosDepartamentosLookup/types/types'
import OrganicosCargosRolLookUp from '../../../../../../componentes/organicoCargosLookup'
import { consoleService } from '../../../../../../../services/console.service'
import TipoContratoLookup from '../../../../../../componentes/tipoContratoLookup'
import JornadaLookUp from '../../../../../../componentes/jornadaLookUp'
import ModalUsuarios from '../../../../../../componentes/modalUsuarios'
import { EAplicationsAcatha } from '../../../../../../../store/enumsAplication'
import { Usuario } from '../../../../../../inventario/pages/movimientos/transferencias/types/types'
import { ModalHorariosTrabajo } from '../../../../../../componentes/modalHorariosTrabajo'
import { DocumentInfo, ToastTypes } from '../../../../../../../store/types'
import { ChangeContratc, ContratoDatosEdicion } from '../../../types/types'
import { TabState } from '../../../../../../../store/genericTabTypes'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import { setChangeLoader, setDatosEdicionContratos } from '../../../store/editDataReducer'
import { DateUtils } from '../../../../../../../helpers/dateUtils'
import { addToast } from '../../../../../../../store/toasterReducer'
import { ContratsServices } from '../../../services/contratos.services'
import { AsyncRule, RequiredRule } from 'devextreme-react/data-grid'
import validations from '../../../../../../../config/validations'

interface IContractProps extends React.PropsWithChildren {
  info: DocumentInfo<ContratoDatosEdicion>
  tab: TabState<ContratoDatosEdicion>
  tabId: string
}


export const gruposContratos = [
  { value: 0, label: 'Contratos en General' },
  { value: 1, label: 'De Confianza' },
]

export const gruposPersonal = [
  { value: 1, label: 'Administrativo' },
  { value: 2, label: 'Obrero' },
  { value: 3, label: 'Gerente' },
]

const Contrato: React.FunctionComponent<IContractProps> = (props) => {

  const { tabId } = props
  const dispatch = useDispatch()
  const [showUsers, setShowUsers] = React.useState<boolean>(false)
  const [showHorarioTrabajo, setShowHorarioTrabajo] = React.useState<any>(false)
  const contractsFilter = useSelector((state: RootState) => state.nomina.contratos.editData[tabId].contratos)
  const contractFilter = useSelector((state: RootState) => state.nomina.contratos.editData[tabId])
  const dateInput = 'dd/MM/yyyy'
  const dateOutput = 'yyyy-MM-dd'
  const typeContractdMessage: string = 'Tipo de Contrato: elija el tipo de contrato.'
  const workingDayMessage: string = 'Jornada: elija una jornada.'
  const chargesdMessage: string = 'Cargo: seleccione un cargo.'
  const sms = validations['es']

  const onLoader = React.useCallback((showLoader: boolean, text: string) => {
    dispatch(setChangeLoader({
      key: tabId,
      data: {
        show: showLoader,
        mensaje: text
      }
    }))
  }, [dispatch, tabId])

  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(
      addToast({
        title: 'Nómina - Contratos',
        content: mensaje,
        type: tipo,
      }),
    )
  }, [dispatch])

  const onValueChange = React.useCallback((data, key: string) => {
    dispatch(setDatosEdicionContratos({
      key: tabId,
      data: {
        ...contractsFilter,
        [key]: data
      }
    }))
  }, [contractsFilter, dispatch, tabId])

  const onChangeContract = React.useCallback(async () => {
    onLoader(true, 'Cargando contrato')
    const fechaI = contractsFilter?.fechaIngreso === '' ? DateUtils.getCurrentDateAsString(dateInput) : contractsFilter?.fechaIngreso
    try {
      const obj_contract: ChangeContratc = {
        contratoCodigo: contractFilter?.codigo ?? 0,
        usuario: contractsFilter?.codigoEmpleado ?? 0,
        tipoPersonal: contractsFilter?.tipoPersonal?.value ?? 0,
        fechaIngreso: DateUtils.format(fechaI, dateInput, dateOutput),
        contrato: contractsFilter?.contatoRt ?? '',
        organicoRol: contractsFilter?.cargo?.organicoCodigo > 0 ? contractsFilter?.cargo?.organicoCodigo : 0,
        funciones: contractsFilter?.funciones ?? '',
        nominal: contractsFilter?.sueldoNomina ?? 0,
        nominalB: contractsFilter?.sueldoNominaB ?? 0,
        descripcion: contractsFilter?.horarioLabores ?? '',
        confianza: contractsFilter?.tipoContrato?.codigo ?? 0,
      }
      const data = await ContratsServices.getChangeContrat(obj_contract)
      consoleService.log(data, 'changeContract')
      if (data['auto'] && data['error'] === false) {
        if (data['auto'][0]?.contrato) {
          dispatch(setDatosEdicionContratos({
            key: tabId,
            data: {
              ...contractsFilter,
              contatoRt: data['auto'][0]?.contrato ?? '',
            }
          }))
        }
      }
    } catch (error) {
      consoleService.log(error, 'error contratc')
    }
    onLoader(false, '')
  }, [onLoader, contractsFilter, contractFilter, dispatch, tabId])

  const onClear = React.useCallback(() => {
    const temp = { ...contractsFilter }
    temp.empleado = ''
    temp.codigoEmpleado = 0
    temp.codigoHorarioLabores = 0
    temp.horarioLabores = ''
    temp.fechaIngreso = DateUtils.getCurrentDateAsString()
    temp.cargo = null
    dispatch(setDatosEdicionContratos({
      key: tabId,
      data: {
        ...temp
      }
    }))
  }, [contractsFilter, dispatch, tabId])

  const onSearchPreContrat = React.useCallback((data: any) => {
    const temp = { ...contractsFilter }
    temp.fechaIngreso = DateUtils.format(data?.fecha ?? DateUtils.getCurrentDateAsString('dd/MM/yyyy'), 'dd/MM/yyyy', 'yyyy-MM-dd')
    dispatch(setDatosEdicionContratos({
      key: tabId,
      data: {
        ...temp
      }
    }))
    onChangeContract()
  }, [contractsFilter, dispatch, tabId, onChangeContract])

  const loadContract = React.useCallback(async (codigo: number) => {
    onValueChange(codigo, 'nroIngreso')
    onLoader(true, 'Cargando contrato')
    try {
      const data = await ContratsServices.getContrat(codigo)
      consoleService.log(data, 'loadContract')
      if (data['auto'] && data['error'] === false) {
        onSearchPreContrat(data['auto'])
      } else {
        setToast('El número de Ingreso no existe', ToastTypes.Danger)
      }
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
    onLoader(false, '')
  }, [onValueChange, setToast, onLoader, onSearchPreContrat])


  const validateAsyncSelect = React.useCallback((datoSeleccion: any, message: string) => {
    if (datoSeleccion.value.codigo > -1) {
      return Promise.resolve()
    } else {
      return Promise.reject(message)
    }
  }, [])

  const validateAsyncSelectCharges = React.useCallback((datoSeleccion: any, message: string) => {
    if (datoSeleccion.value.organicoRolCodigo > -1) {
      return Promise.resolve()
    } else {
      return Promise.reject(message)
    }
  }, [])

  const validateAsyncSeleccionTipoContrato = React.useCallback((datoSeleccion) => {
    return validateAsyncSelect(datoSeleccion, typeContractdMessage)
  }, [validateAsyncSelect])

  const validateAsyncSeleccionJornada = React.useCallback((datoSeleccion) => {
    return validateAsyncSelect(datoSeleccion, workingDayMessage)
  }, [validateAsyncSelect])

  const validateAsyncSeleccionCargo = React.useCallback((datoSeleccion) => {
    return validateAsyncSelectCharges(datoSeleccion, chargesdMessage)
  }, [validateAsyncSelectCharges])

  return (
    <>
      {showHorarioTrabajo && <ModalHorariosTrabajo
        onCancel={() => setShowHorarioTrabajo(false)}
        show={showHorarioTrabajo}
        onChanged={(data) => {
          setShowHorarioTrabajo(false)
          consoleService.log(data, 'horario trabajo')
          dispatch(setDatosEdicionContratos({
            key: tabId,
            data: {
              ...contractsFilter,
              horarioLabores: data?.descripcion ?? '',
              codigoHorarioLabores: data?.codigo ?? 0,
            }
          }))
        }}
      />}
      {showUsers && (
        <ModalUsuarios
          show={showUsers}
          onClose={() => setShowUsers(false)}
          modulo={EAplicationsAcatha.Contratos}
          onSelectUser={(user: Usuario) => {
            consoleService.log(user, 'empleaod selected')
            dispatch(setDatosEdicionContratos({
              key: tabId,
              data: {
                ...contractsFilter,
                empleado: `${user?.nombres ?? ''}(${user?.cedula ?? ''})`,
                codigoEmpleado: user?.codigo ?? 0,
              }
            }))
            setShowUsers(false)
          }}
        />
      )}
      <RowContainer>
        <RowContainer>
          <CustomCol xs="12" md="6">
            <Labeled label='Proceso:' position='left'>
              <TextBox readOnly value={contractsFilter?.proceso ?? ''} />
            </Labeled>
          </CustomCol>
        </RowContainer>
        <RowContainer>
          <CustomCol xs="12" md="2">
            <Labeled label='Nro. Ingreso:'>
              <div
                style={{
                  display: 'flex',
                  gap: '2px',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <CheckBox
                  value={contractsFilter?.activaIngreso ?? null}
                  onValueChange={(data) => onValueChange(data, 'activaIngreso')}
                />
                <NumberBox
                  onFocusOut={onClear}
                  value={contractsFilter?.nroIngreso ?? null}
                  disabled={!contractsFilter?.activaIngreso}
                  onValueChange={(data) => {
                    if (data !== null && data !== undefined) {
                      loadContract(data)
                    }
                  }} />
              </div>
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="4">
            <Labeled label='Contrato:'>
              <RadioGroup
                name="contratos"
                layout="horizontal"
                displayExpr="label"
                dataSource={gruposContratos}
                value={contractsFilter?.contrato ?? null}
                onValueChange={(data) => onValueChange(data, 'contrato')}
              />
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="2">
            <Labeled label=''>
              <CheckBox
                text='Aumento de sueldo'
                value={contractsFilter?.aumentoSueldo ?? null}
                onValueChange={(data) => onValueChange(data, 'aumentoSueldo')}
              />
            </Labeled>
          </CustomCol>
        </RowContainer>
        <RowContainer>
          <CustomCol xs="12" md="4">
            <Labeled label='Tipo de Personal:'>
              <RadioGroup
                name="personal"
                layout="horizontal"
                displayExpr="label"
                dataSource={gruposPersonal}
                value={contractsFilter?.tipoPersonal ?? null}
                onValueChange={(data) => onValueChange(data, 'tipoPersonal')}
              />
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="2">
            <Labeled label='Fecha Ingreso:'>
              <DateBox
                onFocusOut={onChangeContract}
                value={contractsFilter?.fechaIngreso ?? null}
                onValueChange={(data) => onValueChange(data, 'fechaIngreso')}
              >
                <Validator>
                  <RequiredRule message={'Fecha Ingreso: ' + sms['required']} />
                </Validator>
              </DateBox>
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="4">
            <Labeled label='Empleado:'>
              <div
                style={{
                  display: 'flex',
                  gap: '2px',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <TextBox
                  readOnly
                  value={contractsFilter?.empleado ?? ''}
                >
                  <Validator>
                    <RequiredRule message={'Empleado: ' + sms['required']} />
                  </Validator>
                </TextBox>
                <Button
                  id="btnAdd"
                  className="me-1"
                  icon="plus"
                  stylingMode="contained"
                  type="default"
                  onClick={() => setShowUsers(true)}
                />
              </div>
            </Labeled>
          </CustomCol>
        </RowContainer>
        <RowContainer>
          {!contractsFilter?.activaIngreso && (
            <CustomCol xs="12" md="4">
              <Labeled label='Departamento:'>
                <CargosDepartamentosLookup
                  selected={contractsFilter?.departamento ?? null}
                  onChanged={(departament: CargoDepartamento) => onValueChange(departament, 'departamento')}
                />
              </Labeled>
            </CustomCol>
          )}
          <CustomCol xs="12" md="3">
            <Labeled label=''>
              <TextArea
                readOnly
                value={contractsFilter?.contatoRt ?? ''}
              ></TextArea>
            </Labeled>
          </CustomCol>
        </RowContainer>
        <hr />
        <RowContainer>
          <CustomCol xs="12" md="3">
            <Labeled label='Cargo:'>
              <OrganicosCargosRolLookUp
                onChanged={(data) => {
                  onValueChange(data, 'cargo')
                  onChangeContract()
                }}
                selected={contractsFilter?.cargo ?? null}
              >
                <Validator>
                  <RequiredRule message={chargesdMessage} />
                  <AsyncRule
                    message={chargesdMessage}
                    validationCallback={validateAsyncSeleccionCargo}
                  />
                </Validator>
              </OrganicosCargosRolLookUp>
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="3">
            <Labeled label='Tipo de contrato:'>
              <TipoContratoLookup
                onChanged={(data) => {
                  onValueChange(data, 'tipoContrato')
                }}
                selected={contractsFilter?.tipoContrato ?? null}
              >
                <Validator>
                  <RequiredRule message={typeContractdMessage} />
                  <AsyncRule
                    message={typeContractdMessage}
                    validationCallback={validateAsyncSeleccionTipoContrato}
                  />
                </Validator>
              </TipoContratoLookup>
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="1">
            <Labeled label=''>
              <NumberBox
                value={contractsFilter?.numeroTipoParcial ?? null}
                max={8}
                min={1}
                visible={contractsFilter?.numeroTipoParcialShow}
                onValueChange={(data) => onValueChange(data, 'numeroTipoParcial')}
              />
            </Labeled>
          </CustomCol>
        </RowContainer>
        <RowContainer>
          <CustomCol xs="12" md="3">
            <Labeled label='Jornada:'>
              <JornadaLookUp
                onChanged={(data) => {
                  onValueChange(data, 'jornada')
                }}
                selected={contractsFilter?.jornada ?? null}
              >
                <Validator>
                  <RequiredRule message={workingDayMessage} />
                  <AsyncRule
                    message={workingDayMessage}
                    validationCallback={validateAsyncSeleccionJornada}
                  />
                </Validator>
              </JornadaLookUp>
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="3">
            <Labeled label='Funciones:'>
              <TextArea
                onFocusOut={onChangeContract}
                value={contractsFilter?.funciones ?? ''}
                onValueChange={(data) => onValueChange(data, 'funciones')}
              ></TextArea>
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="3">
            <Labeled label='Sueldo nominal:'>
              <NumberBox
                onFocusOut={onChangeContract}
                onEnterKey={onChangeContract}
                value={contractsFilter?.sueldoNomina ?? null}
                onValueChange={(data) => onValueChange(data, 'sueldoNomina')}
              />
            </Labeled>
          </CustomCol>
          {contractsFilter?.aumentoSueldo && (
            <CustomCol xs="12" md="3">
              <Labeled label='a:'>
                <NumberBox
                  onFocusOut={onChangeContract}
                  onEnterKey={onChangeContract}
                  value={contractsFilter?.sueldoNominaB ?? null}
                  onValueChange={(data) => onValueChange(data, 'sueldoNominaB')}
                />
              </Labeled>
            </CustomCol>
          )}
        </RowContainer>
        <RowContainer>
          <CustomCol xs="12" md="3">
            <Labeled label='Fecha Salida:'>
              <DateBox
                value={contractsFilter?.fechaSalida ?? null}
                onValueChange={(data) => {
                  if (data !== null && data !== "" && data !== undefined) {
                    const fsalida = DateUtils.dateToString(data)
                    onValueChange(fsalida, 'fechaSalida')
                  }
                }}
              />
            </Labeled>
          </CustomCol>
          {!contractsFilter?.activaIngreso && (
            <CustomCol xs="12" md="5">
              <Labeled label='Horario de labores:'>
                <div
                  style={{
                    display: 'flex',
                    gap: '2px',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <TextBox
                    value={contractsFilter?.horarioLabores ?? ''}
                    readOnly
                  >
                    <Validator>
                      <RequiredRule message={'Horarios: ' + sms['required']} />
                    </Validator>
                  </TextBox>
                  <Button
                    id="btnAdd"
                    className="me-1"
                    icon="plus"
                    stylingMode="contained"
                    type="default"
                    onClick={() => setShowHorarioTrabajo(true)}
                  />
                </div>
              </Labeled>
            </CustomCol>
          )}
        </RowContainer>
      </RowContainer>
    </>
  )
}

export default React.memo(Contrato)