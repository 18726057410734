import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CustomDictionary } from '../../../../ventas/types/generics'
import { TUnidadMedidaDatosEdicion } from './types'
import { TConfEdicionPayload } from '../../store/types'
import { ventasClearDatosEdicion } from '../../../../ventas/store/reducers'

const initialState: CustomDictionary<TUnidadMedidaDatosEdicion> = {}

const datosEdicionConfSlice = createSlice({
  name: 'UnidadMedidaDatosEdicion',
  initialState: initialState,
  reducers: {
    ventasClearDatosEdicion,
    setDatosEdicion(
      state,
      action: PayloadAction<TConfEdicionPayload<TUnidadMedidaDatosEdicion>>,
    ) {
      state[action.payload.key] = action.payload.data
    },
    //Establecer datos edicion para un tab nuevo
    initDatosEdicion(
      state,
      action: PayloadAction<TConfEdicionPayload<TUnidadMedidaDatosEdicion>>,
    ) {
      if (!state[action.payload.key]) {
        state[action.payload.key] = action.payload.data
      }
    },
  },
})

export const {
  ventasClearDatosEdicion: clearDatosEdicion,
  initDatosEdicion,
  setDatosEdicion,
} = datosEdicionConfSlice.actions
export const dataEditionReducer = datosEdicionConfSlice.reducer
