import React from 'react'
import Modalform from '../../../views/componentes/modalform'
import { CCard, CCardBody, CCardHeader } from '@coreui/react-pro'
import { LoadPanel } from 'devextreme-react'
import RowContainer from '../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../views/componentes/colContainer'
import { GlobalMenu } from '../../../views/componentes/globalMenu/globalMenu'
import { AccionMenu, ToastTypes } from '../../../store/types'
import { ButtonTypes } from '../../../views/componentes/globalMenu/types'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import Arbol from '../../contabilidad/pages/planCuentas/components/arbol'
import { kitsPartsServices } from './services/kitspartes.service'
import { addToast } from '../../../store/toasterReducer'
import Nuevo from './componentes/nuevo'
import Dialog from '../../../views/componentes/librerias/bootstrap-dialog'


interface IAdminModalKitsPartes extends React.PropsWithChildren {
  onChanged: (data: any) => void
  onCancel: () => void
  show: boolean
}


const menuDefecto = [
  {
    icon: 'fal fa-file',
    id: 'Nuevo',
    text: 'Nuevo',
    type: ButtonTypes.new,
  },
  {
    icon: 'fal fa-save',
    id: 'Guardar',
    text: 'Guardar',
    type: ButtonTypes.save,
  },
  {
    icon: 'fal fa-edit',
    id: 'Editar',
    text: 'Editar',
    type: ButtonTypes.edit,
  },
  {
    icon: 'fal fa-search',
    id: 'Buscar',
    text: 'Buscar',
    type: ButtonTypes.find,
  },
]

export const ModalKitPartes: React.FC<IAdminModalKitsPartes> = (props) => {

  const { onCancel, show, onChanged } = props
  const dispatch = useDispatch()
  const [resultados, setResultados] = React.useState([])
  const [currentTab, setCurrentTab] = React.useState<any>("Buscar")
  const [currentFunction, setCurrentFunction] = React.useState<any>("")
  const [showLoader, setShowLoader] = React.useState<boolean>(false)
  const [selected, setSelected] = React.useState<any>(null)
  const dialogRef = React.useRef<any>(null)

  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(
      addToast({
        title: 'Kits Partes',
        content: mensaje,
        type: tipo,
      }),
    )
  }, [dispatch])

  const botones = menuDefecto.map(acc => {
    const boton: AccionMenu = {
      nombre: acc.id,
      icon: acc.icon,//as any,
      actionType: acc.type,
      main: false,
      modal: false,
      shortcut: null
    }
    return boton;
  });
  const [acciones,] = React.useState<Array<AccionMenu>>(botones)

  const onGetDataEdit = React.useCallback(async (data) => {
    onChanged(data)
    setSelected(data)
  }, [onChanged])

  const onSearch = React.useCallback(async () => {
    try {
      const search_data = {
        agrupar: true,
        descripcion: '',
        codigo: 0,
      }
      setShowLoader(true)
      const res = await kitsPartsServices.getAllKitsParts(search_data)
      setShowLoader(false)
      if (res !== null && res !== undefined && res['error'] === false) {
        console.log(res)
        let newAray = []
        newAray = res['auto'].map(item => {
          return {
            codigo: item?.codigo ?? 0,
            numero: '',
            descripcion: item?.descripcion ?? '',
            padre: item?.padre ?? 0,
            estado: item?.estado ?? 0,
            observaciones: '',
          }
        })
        setResultados(newAray)
        setToast(res['message'], ToastTypes.Success)
      } else {
        setToast(res['message'], ToastTypes.Danger)
      }
    } catch (error) {
      setShowLoader(false)
      setToast(error.message, ToastTypes.Danger)
    }
  }, [setToast])

  const onConfirmEdit = React.useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea Editar el registro: ${selected.filename}?`,
      actions: [
        Dialog.Action(
          <span>
            <u>A</u>ceptar
          </span>,
          (dialog) => {
            dialog.hide()
            setCurrentTab(ButtonTypes.new)
            setCurrentFunction(ButtonTypes.edit)
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    return
  }, [selected])


  const onConfirmNew = React.useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea Crear un nuevo registro?`,
      actions: [
        Dialog.Action(
          <span>
            <u>A</u>ceptar
          </span>,
          (dialog) => {
            dialog.hide()
            setCurrentTab(ButtonTypes.new)
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    return
  }, [])


  const returnAction = (boton: AccionMenu) => {
    switch (boton.actionType) {
      case ButtonTypes.find:
        if (currentTab === 'Nuevo') {
          setCurrentTab(ButtonTypes.find)
        } else {
          onSearch()
        }
        break;
      case ButtonTypes.new:
        if (currentTab === 'Buscar') {
          onConfirmNew()
        } else {
          setCurrentFunction(ButtonTypes.save)
        }
        break;
      case ButtonTypes.save:
        if (currentTab === 'Nuevo') {
          setCurrentFunction(ButtonTypes.save)
        } else {
          setCurrentTab(ButtonTypes.new)
        }
        break

      case ButtonTypes.edit:
        if (currentTab === 'Buscar') {
          onConfirmEdit()
        } else {
          setCurrentTab(ButtonTypes.find)
        }
        break;
      default:
        break;
    }
  }

  React.useEffect(() => {
    if (currentTab === 'Buscar') {
      setSelected(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab])

  const getButtonDisabled = React.useCallback((button: AccionMenu) => {
    switch (button.actionType) {
      case ButtonTypes.save:
        return currentTab !== 'Nuevo'
      case ButtonTypes.edit:
        return selected === null || selected === undefined || currentTab !== "Buscar"
      case ButtonTypes.find:
        return false
      case ButtonTypes.new:
        return currentTab !== 'Buscar'
      default:
        return true;
    }
  }, [currentTab, selected])

  const bodyModal = () => {
    return (
      <RowContainer>
        <CustomCol xs='12' md='12'>
          {(currentTab === "Buscar") && (
            <Arbol name="ArbolKitsPartes" data={resultados} activedense={true} sendData={(data) => onGetDataEdit(data)} modulo={1} />
          )}
          {(currentTab === "Nuevo") && (
            <Nuevo currentTab={currentTab} data={selected} currentFunction={currentFunction} setFunction={setCurrentFunction} setShowLoader={setShowLoader} />
          )}
          {(currentTab === "Eidtar") && (
            <Nuevo currentTab={currentTab} data={selected} currentFunction={currentFunction} setFunction={setCurrentFunction} setShowLoader={setShowLoader} />
          )}
        </CustomCol>
      </RowContainer>
    );
  }

  const botonesModal = () => {
    return (
      <RowContainer>
        <CustomCol xs='12' md='4'>
          <GlobalMenu
            acciones={acciones}
            onClick={returnAction}
            getButtonDisabled={getButtonDisabled}
          />
        </CustomCol>
      </RowContainer>
    );
  }

  const renderContent = () => {
    return (
      <CCard>
        <CCardHeader>
          {botonesModal()}
        </CCardHeader>
        <CCardBody>
          <LoadPanel
            shadingColor="rgba(0,0,0,0.4)"
            position={'center'}
            visible={showLoader}
            showIndicator={true}
            shading={true}
            showPane={true}
            message={'Cargando...'}
          />
          {bodyModal()}
        </CCardBody>
      </CCard>
    )
  }

  return (
    <>
      <Dialog ref={dialogRef} />
      <Modalform
        name='popupKitsPartes'
        headerTitle={'Kits Partes'}
        childrenBody={renderContent()}
        closeOnBackdrop={false}
        show={show}
        onClose={onCancel}
        centered={true}
        size='lg'
      />
    </>
  )
}
