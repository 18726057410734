import { RequestHelper } from '../../../../../../helpers/requestHelper'
import { EAplicationsAcatha } from '../../../../../../store/enumsAplication'
import { ETittleTab, GuardarMovimiento } from '../types/types'

export const incomeExpensesServices = {
  getIncomeExpenses,
  postIncomeExpensesReport,
  setIncomeExpense,
  deleteIncomeExpense,
  getIva,
  getIncomeExpensesDeatil,
  getIncomeExpensesItemsLocal,
  existData,
  getIncomeExpensesOrdersDetail,
  getSeries,
  getLots,
  updateItemPrice,
}

async function getIncomeExpenses(data: any, modulo = 66): Promise<any> {

  try {
    const obj: any = {
      codigo: data['codigo'],
      documento: data['documento'],
      localCodigo: data['codigoLocal'],
      productoCliente: data['prodCliente'],
      tipo: data['tipo'],
      impreso: data['impreso'],
      movimiento: data['movimiento'],
      observaciones: data['observaciones'],
      estadoDescripcion: data['estado'],
      fechaDesde: data['fechaDesde'],
      fechaHasta: data['fechaHasta'],
    }

    if (modulo !== parseInt(EAplicationsAcatha.IngresosEgresos)) {
      obj.transito = data['transito']
    }
    let dataApi = null
    if (data['currentTab'] === ETittleTab.cab) {
      dataApi = await RequestHelper.getAll<any>('inventario/movimientos/ingresosEgresos', 'headMovements/getAll', '', obj)
    } else {
      obj.consolidado = data['consolidado']
      dataApi = await RequestHelper.getAll<any>('inventario/movimientos/ingresosEgresos', 'headMovements/getAllCabDet', '', obj)
    }
    return dataApi
  } catch (error) {
    return error
  }
}

async function postIncomeExpensesReport(data: any): Promise<any> {

  try {
    const obj: any = {
      infoFiltro: {
        codigo: data['codigo'],
        documento: data['documento'],
        movimiento: data['movimiento'],
        productoCliente: data['prodCliente'],
        tipo: data['tipo'],
        observaciones: data['observaciones'],
        estadoDescripcion: data['estado'],
        fechaDesde: data['fechaDesde'],
        fechaHasta: data['fechaHasta'],
      }
    }
    const dataApi = await RequestHelper.postAll<any>('inventario/movimientos/ingresosEgresos', 'headMovements/getAllReport', obj)
    return dataApi
  } catch (error) {
    return error
  }
}

async function existData(data: any): Promise<any> {

  try {
    const obj: any = {
      codigo: data['codigo'],
      documento: data['documento'],
      productoCliente: data['procli'],
      tipo: data['tipo'],
      movimiento: data['movimiento'],
      localCodigo: data['locCodigo'],
    }
    const dataApi = await RequestHelper.getAll<any>('inventario/movimientos/ingresosEgresos', 'headMovements/verifyNumberExistent', '', obj)
    return dataApi
  } catch (error) {
    return error
  }
}
async function getIncomeExpensesDeatil(codigo: number): Promise<any> {

  try {
    const obj: any = {
      codigo,

    }
    const dataApi = await RequestHelper.getAll<any>('inventario/movimientos/ingresosEgresos', 'headMovements/getAllDet', '', obj)
    return dataApi
  } catch (error) {
    return error
  }
}

async function getIncomeExpensesOrdersDetail(data: any): Promise<any> {

  try {
    const obj: any = {
      codigo: data['codigo'],
      tipo: data['tipo'],

    }
    const dataApi = await RequestHelper.getAll<any>('inventario/movimientos/ingresosEgresos', 'orders/getDetailMov', '', obj)
    return dataApi
  } catch (error) {
    return error
  }
}

async function getIncomeExpensesItemsLocal(codigo: number): Promise<any> {

  try {
    const obj: any = {
      codigo,

    }
    const dataApi = await RequestHelper.getAll<any>('inventario/movimientos/ingresosEgresos', 'items/searchItemsLocalAll', '', obj)
    return dataApi
  } catch (error) {
    return error
  }
}
async function setIncomeExpense(requestBody: GuardarMovimiento): Promise<any> {
  const obj = {
    ...requestBody
  }
  try {
    const dataApi = await RequestHelper.postAll<any>('inventario/movimientos/ingresosEgresos', 'headMovements/save', obj)
    return dataApi
  } catch (error) {
    return error
  }
}

async function deleteIncomeExpense(data: any): Promise<any> {
  try {
    const obj = {
      codigo: data['codigo'],
      fecha: data['fecha'],
    }
    const dataApi = await RequestHelper.deleteRequestAll<any>('inventario/movimientos/ingresosEgresos', 'headMovements/delete', '', obj)
    return dataApi
  } catch (error) {
    return error
  }

}

async function getIva(fecha: string, idTipoNegocio: number, paisCodigo: number): Promise<any> {
  try {
    const obj = {
      fecha,
      idTipoDeNegocio: idTipoNegocio,
      paisCodigo
    }
    const dataApi = await RequestHelper.getAll<any>('iva', '', '', obj)
    return dataApi
  } catch (error) {
    return error
  }
}


async function getSeries(data: any): Promise<any> {
  try {
    const obj = {
      itemSerieTipo: data['tipo'],
      itemSerieAsociado: data['asociado'],
    }
    const apiResult = await RequestHelper.getAll<any>(
      'inventario/items',
      'getSeries',
      '',
      obj,
    )
    return apiResult
  } catch (error) {
    return error
  }
}

async function getLots(data: any): Promise<any> {
  try {
    const obj = {
      tipo: data['tipo'],
      ventaCodigo: data['codigo'],
    }
    const apiResult = await RequestHelper.getAll<any>(
      'ventas/ventas',
      'lots/getSelected',
      '',
      obj,
    )
    return apiResult
  } catch (error) {
    return error
  }
}


async function updateItemPrice(data: any): Promise<any> {
  try {
    const obj = {
      infoRegistro: {
        codigo: data['codigo'],
        utilidadPVD: data['utilidadPVD'],
        pvd: data['pvd'],
        utilidadPVP: data['utilidadPVP'],
        pvp: data['pvp'],
        costo: data['costo'],
      }
    }
    const apiResult = await RequestHelper.postAll<any>(
      'inventario/items',
      'updatePriceItems',
      obj,
    )
    return apiResult
  } catch (error) {
    return error
  }
}