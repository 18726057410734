import React from 'react'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput'
import {
  Button,
  DataGrid,
  NumberBox,
  RadioGroup,
  TextBox,
  ValidationSummary,
  Validator,
} from 'devextreme-react'
import { AtributosDatosEdicion, GuardarAtributo } from '../../types/types'
import { ButtonTypes } from '../../../../../../../views/componentes/globalMenu/types'
import { clearButtonClick, setCurrentExecutingAction, setNameTabe } from '../../store/tabsReducer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import {
  changeLoaderTab,
  setCurrentFunction,
} from '../../store/generalReducer'
import { onChangeAtributoWeb, setDatosEdicion } from '../../store/editDataReducer'
import Dialog from '../../../../../../../views/componentes/librerias/bootstrap-dialog'
import { Column } from 'devextreme-react/data-grid'
import { TabState } from '../../../../../../../store/genericTabTypes'
import { DocumentInfo, ToastTypes } from '../../../../../../../store/types'
import { addToast } from '../../../../../../../store/toasterReducer'
import { getIndexProvider } from '../../../../../../shared/helpers/funciones'
import ValidationGroup from 'devextreme-react/validation-group';
import { MessagesKeys, lh } from '../../../../../../../helpers/localizationHelper'
import { RequiredRule } from 'devextreme-react/validator'
import validations from '../../../../../../../config/validations'
import { attributesServices } from '../../services/unidades.services'
import { StatesEdition } from '../../../../../../ventas/types/enums'
import BlockUi from '../../../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../../../views/componentes/loadingindicator/loadingindicator'
import VisualizaError from '../../../../../../ventas/pages/shared/visualizaError/visualizaError'
import ListErrosValidationForm from '../../../../../../componentes/listadoErroresValidacionForm'
import { isMobile } from 'react-device-detect'
import { consoleService } from '../../../../../../../services/console.service'
import AtributosWebLookUp from '../../../../../../componentes/atributosWebLookUp'
import { InventarioService } from '../../../../../services/inventario.service'
import { setDatosEdicionBackup } from '../../store/configReducer'

export const optionsActivos = [
  { codigo: 1, descripcion: 'Activos' },
  { codigo: 2, descripcion: 'Inactivos' },
]

interface INewProps extends React.PropsWithChildren {
  info: DocumentInfo<AtributosDatosEdicion>
  tab: TabState<AtributosDatosEdicion>
  tabId: string
}

const Nuevo: React.FunctionComponent<INewProps> = (props) => {
  const { tab, tabId, info } = props
  const dispatch = useDispatch()
  const atributesState = useSelector((state: RootState) => state.inventarios.catalogos.atributos.editData[tabId])
  const atributesStateBackup = useSelector((state: RootState) => state.inventarios.catalogos.atributos.config[tabId])
  const loading = useSelector((state: RootState) => state.inventarios.catalogos.atributos.editData[tabId].loading)
  const loader = useSelector((state: RootState) => state.inventarios.catalogos.atributos.general.loaderTab)
  const tabs = useSelector((state: RootState) => state.inventarios.catalogos.atributos.tabs)
  const sms = validations['es']

  const [opciones, setOpciones] = React.useState<Array<any>>([])
  const [opcion, setOpcion] = React.useState<string>(null)
  const [opcionSelected, setOpcionSelected] = React.useState(null)
  const validationAtributosRef = React.useRef<any>()
  const dialogRef = React.useRef<any>(null)
  const dataGrid = React.useRef<any>()
  const [arrayErrors, setArrayErrors] = React.useState<Array<string>>([])
  const [showErrorPopup, setShowErrorPopup] = React.useState<boolean>(false)
  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(
      addToast({
        title: 'Inventario - Atributos',
        content: mensaje,
        type: tipo,
      }),
    )
  }, [dispatch])

  const setButtonExecuttion = React.useCallback((tipo: ButtonTypes | undefined) => {
    dispatch(
      setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: tipo,
      }),
    )
  }, [dispatch, tabId])

  const playLoader = React.useCallback((message = 'Cargando...') => {
    dispatch(changeLoaderTab({ show: true, mensaje: message }))
  }, [dispatch])

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoaderTab({ show: false, mensaje: '' }))
  }, [dispatch])

  const modoNuevo = React.useCallback(async (template: number, limpiar: boolean = false) => {
    if (loading === false && !limpiar) {
      return
    }
    setButtonExecuttion(ButtonTypes.new)
    playLoader()

    const data = { ...defaultDataSheet }
    const atributos = await InventarioService.getAtributosWeb()
    if (atributos['auto'] && atributos['error'] === false) {
      data.atributoWeb = atributos['auto'][0]
    }
    data.descripcion = ''
    data.dataGridoOpcion = []
    data.estado = optionsActivos[0]
    data.orden = 1
    data.loading = false
    dispatch(setDatosEdicion({
      key: tabId,
      atributo: data
    }))
    dispatch(setDatosEdicionBackup({
      key: tabId,
      atributtes: { ...data }
    }))
    stopLoader()
    setButtonExecuttion(undefined)
  }, [dispatch, loading, playLoader, stopLoader, setButtonExecuttion, tabId])


  const cargarAtributo = React.useCallback(async (loder: boolean, unidadData) => {
    if (loder === false) {
      return
    }

    setButtonExecuttion(ButtonTypes.edit)
    playLoader('Cargando atributo...')
    let opcionesGrid = []
    const data = { ...atributesState }
    consoleService.log(unidadData)
    const atributos = await InventarioService.getAtributosWeb()
    if (atributos['auto'] && atributos['error'] === false) {
      const provider = atributos['auto']?.slice(0)
      const index = await getIndexProvider(provider, 'codigo', unidadData?.idwoo ?? -1)
      if (index > -1) {
        data.atributoWeb = provider[index]
      } else {
        data.atributoWeb = provider[0]
      }
    }
    if (unidadData?.opciones && unidadData.opciones.length > 0) {
      // const arryOpcionesStr = unidadData?.opciones.split(',')
      opcionesGrid = unidadData?.opciones.map(item => {
        return {
          option: item
        }
      })
    }
    setOpciones(opcionesGrid)
    data.descripcion = unidadData?.descripcion ?? ''
    data.opciones = '',
      data.orden = unidadData?.orden ?? 1,
      data.estado = unidadData?.estado === 1 ? optionsActivos[0] : optionsActivos[1],
      data.dataGridoOpcion = opcionesGrid,
      data.loading = false,
      dispatch(setDatosEdicion({
        key: tabId,
        atributo: data
      }))
    dispatch(setDatosEdicionBackup({
      key: tabId,
      atributtes: data
    }))
    stopLoader()
    setButtonExecuttion(undefined)

  }, [atributesState, dispatch, playLoader, setButtonExecuttion, stopLoader, tabId])


  const onChangedValue = React.useCallback((data, key: string) => {
    if (data?.event !== null && data?.event !== undefined) {
      if (key === "opciones") {
        setOpcion(data.value)
      }
      dispatch(setDatosEdicion({
        key: tabId,
        atributo: {
          ...atributesState,
          [key]: data.value,
        }
      }))
    }
  }, [atributesState, dispatch, tabId])

  const onValidate = React.useCallback(() => {
    const arrayErrores: Array<string> = []
    if (atributesState?.descripcion === null || atributesState?.descripcion === undefined || atributesState?.descripcion === '') {
      arrayErrores.push('Descripción: Ingrese una descripción de la Propiedad.')
    }
    if (atributesState?.dataGridoOpcion === null || atributesState?.dataGridoOpcion === undefined || atributesState?.dataGridoOpcion.length <= 0) {
      arrayErrores.push('Opciones: Ingrese las opciones de la Propiedad.')
    }
    return arrayErrores
  }, [atributesState])


  const onHandleSave = React.useCallback(async () => {
    let errores: Array<string> = []
    errores = onValidate()
    const result = validationAtributosRef.current.instance.validate()
    if (result.isValid && errores.length === 0) {
      if (atributesState.dataGridoOpcion !== null && atributesState.dataGridoOpcion !== undefined && atributesState.dataGridoOpcion?.length > 0) {
        const optionsArray: Array<string> = []
        atributesState?.dataGridoOpcion.map(item => {
          optionsArray.push(item.option)
        })
        const save_date: GuardarAtributo = {
          infoRegistro: {
            codigo: atributesState?.codigo ?? 0,
            descripcion: atributesState?.descripcion ?? '',
            estado: atributesState.estado?.codigo ?? 0,
            orden: atributesState.orden ?? 0,
            atribtuosweb: atributesState.atributoWeb !== null ? atributesState.atributoWeb.codigo.toString() : '-1',
            opciones: optionsArray,
          }

        }
        try {
          setButtonExecuttion(ButtonTypes.save)
          playLoader('Guardando...')
          const data = await attributesServices.setAttribute(save_date)
          stopLoader()
          setButtonExecuttion(undefined)
          if (data !== null && data !== undefined && data['error'] === false) {
            dispatch(setDatosEdicion({
              key: tabId,
              atributo: {
                ...atributesState,
                codigo: parseInt(data['auto']),
              }
            }))
            dispatch(setNameTabe({
              key: tabId,
              codigo: parseInt(data['auto']),
              nombre: atributesState?.descripcion
            }))
            setToast(data['message'], ToastTypes.Success)
          } else {
            setToast(data['message'], ToastTypes.Danger)
          }
        } catch (error) {
          stopLoader()
          setButtonExecuttion(undefined)
          setToast(error.message, ToastTypes.Danger)
        }
      } else {
        setToast('Ingrese almenos una opcion', ToastTypes.Warning)
      }
    } else {
      setArrayErrors(errores)
      setShowErrorPopup(true)
      setToast(lh.getMessage(MessagesKeys.GlobalErrorSave), ToastTypes.Warning)
    }
  }, [atributesState, setToast, playLoader, stopLoader, dispatch, tabId, setButtonExecuttion, onValidate])

  const onUndo = React.useCallback(() => {
    dispatch(setDatosEdicion({
      key: tabId,
      atributo: { ...atributesStateBackup }
    }))
  }, [atributesStateBackup, tabId, dispatch])

  const onBroom = React.useCallback(() => {
    dispatch(setDatosEdicion({
      key: tabId,
      atributo: { ...defaultDataSheet }
    }))
  }, [dispatch, tabId])


  const handleButtonClick = React.useCallback((buttonAction: string) => {
    switch (buttonAction) {
      case ButtonTypes.save:
        if (tabs.current === tabId) onHandleSave()
        break
      case ButtonTypes.broom:
        if (tabs.current === tabId) {
          onBroom()
        }
        break
      case ButtonTypes.undo:
        if (tabs.current === tabId) {
          onUndo()
        }
        break
      default:
        break
    }
    dispatch(setCurrentFunction(''))
    dispatch(clearButtonClick(tabId))
  }, [dispatch, onHandleSave, tabId, tabs, onUndo, onBroom])

  const onAddOption = React.useCallback(async () => {
    let arrayOpciones: Array<any> = []
    if (opcion !== null && opcion !== undefined && opcion !== '') {
      arrayOpciones = opciones
      const provider = arrayOpciones.slice(0)
      const index = await getIndexProvider(provider, 'option', opcion)
      if (index > -1) {
        setToast('Opcion ya añadida, ingrese otra por favor', ToastTypes.Warning)
      } else {
        const obj_option: any = {}
        obj_option.option = opcion
        Object.preventExtensions(obj_option);
        arrayOpciones = [...arrayOpciones, obj_option]
        consoleService.log(opcion)
        consoleService.log(arrayOpciones)
        setOpciones(arrayOpciones)
        dispatch(setDatosEdicion({
          key: tabId,
          atributo: {
            ...atributesState,
            dataGridoOpcion: arrayOpciones,
          }
        }))
        setOpcion(null)
      }
    } else {
      setToast('Digite una opcion por favor', ToastTypes.Warning)
    }
  }, [atributesState, dispatch, opcion, opciones, setToast, tabId])

  const onRemoveOption = React.useCallback(async () => {
    let arrayOpciones = []
    if (opcionSelected !== null && opcionSelected !== undefined && opcionSelected !== '') {
      arrayOpciones = opciones
      const provider = arrayOpciones.slice(0)
      const index = await getIndexProvider(provider, 'option', opcionSelected.option)
      if (index > -1) {
        provider.splice(index, 1);
        setOpciones(provider)
        dispatch(setDatosEdicion({
          key: tabId,
          atributo: {
            ...atributesState,
            dataGridoOpcion: provider,
          }
        }))
      } else {
        setToast('La opcion no existe', ToastTypes.Warning)
      }

    } else {
      setToast('Seleccione una opción para eliminarla', ToastTypes.Warning)
    }
  }, [atributesState, dispatch, opcionSelected, opciones, setToast, tabId])

  const onSelectedChanged = React.useCallback(({ selectedRowsData }) => {
    consoleService.log('selected option', selectedRowsData[0])
    setOpcionSelected(selectedRowsData[0])
  }, [])

  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab.globalButtonClick])

  React.useEffect(() => {
    if (tab.editStatus === StatesEdition.new) {
      modoNuevo(tab?.info?.codigo)
    } else {
      cargarAtributo(loading, info.info)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {showErrorPopup && (
        <VisualizaError
          titulo="Error en Atributos"
          mensaje={'Error al guardar'}
          content={
            <ListErrosValidationForm
              mainMessage={'Revise los siguientes errores:'}
              errorsList={arrayErrors}
              color={'danger'}
            />
          }
          onOk={() => setShowErrorPopup(false)}
        />
      )}
      <div style={{ overflowX: 'hidden' }}>
        <BlockUi
          tag="div"
          loader={LoadingIndicator}
          blocking={loader.show}
          message={loader.mensaje}
        >
          <RowContainer>
            <Dialog ref={dialogRef} />
            <ValidationGroup id={'formAtributos'} ref={validationAtributosRef}>
              {
                !isMobile && (
                  <RowContainer>
                    <CustomCol lg="6" md="8" sm="12">
                      <ValidationSummary id="summary"></ValidationSummary>
                    </CustomCol>
                  </RowContainer>
                )
              }
              <RowContainer>
                <CustomCol xs="12" md="3">
                  <Labeled label="Código:">
                    <NumberBox
                      value={atributesState?.codigo ?? null}
                      width="100%"
                      inputAttr={{ autocomplete: 'nope' }}
                      onValueChanged={(data) => onChangedValue(data, 'codigo')}
                      readOnly
                    />
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="4">
                  <Labeled label="Descirpción:">
                    <TextBox
                      value={atributesState?.descripcion ?? ''}
                      width="100%"
                      inputAttr={{ autocomplete: 'nope' }}
                      onValueChanged={(data) => onChangedValue(data, 'descripcion')}
                    >
                      <Validator>
                        <RequiredRule message={'Descripción: ' + sms['required']} />
                      </Validator>
                    </TextBox>
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="3">
                  <Labeled label="Opciones:">
                    <div style={{ display: 'flex', gap: '5px' }}>
                      <TextBox
                        value={opcion ?? ''}
                        width="100%"
                        inputAttr={{ autocomplete: 'nope' }}
                        onValueChanged={(data) => onChangedValue(data, 'opciones')}
                        onEnterKey={onAddOption}
                      />
                      <Button
                        id="btnAdd"
                        className="me-1"
                        icon="add"
                        stylingMode="contained"
                        type="default"
                        onClick={() => onAddOption()}
                      />
                      <Button
                        id="btnAdd"
                        className="me-1"
                        icon="minus"
                        disabled={opcionSelected === null || opcionSelected === undefined}
                        stylingMode="contained"
                        type="default"
                        onClick={() => onRemoveOption()}
                      />
                    </div>
                  </Labeled>
                </CustomCol>
              </RowContainer>
              <RowContainer>
                <CustomCol xs="12" md="1">
                  <Labeled label="Orden:">
                    <NumberBox
                      value={atributesState?.orden ?? 1}
                      width="100%"
                      inputAttr={{ autocomplete: 'nope' }}
                      onValueChanged={(data) => onChangedValue(data, 'orden')}
                    />
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="3">
                  <Labeled label="Atributo Web:">
                    <AtributosWebLookUp
                      onChanged={(data) => {
                        consoleService.log("data ", data)
                        dispatch(onChangeAtributoWeb({
                          key: tabId,
                          atributo: data
                        }))
                      }}
                      onChangedOptions={(data) => {
                        consoleService.log("data ", data)
                      }}
                      selected={atributesState?.atributoWeb}
                    />
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="3">
                  <Labeled label="Estado:">
                    <RadioGroup
                      name="grupos-activos"
                      layout="horizontal"
                      displayExpr="descripcion"
                      items={optionsActivos}
                      value={atributesState?.estado}
                      onValueChanged={(data) => onChangedValue(data, 'estado')}
                    />
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="3">
                  <RowContainer className='mb-2'>
                    <DataGrid
                      ref={dataGrid}
                      id="codigo"
                      selection={{ mode: 'single' }}
                      dataSource={atributesState?.dataGridoOpcion}
                      showColumnLines={true}
                      showRowLines={true}
                      showBorders={true}
                      columnResizingMode="widget"
                      onSelectionChanged={onSelectedChanged}
                      onRowDblClick={onRemoveOption}
                    >
                      <Column dataField="option" caption="Opción" width="23%" />
                    </DataGrid>
                  </RowContainer>
                </CustomCol>
              </RowContainer>
              {
                isMobile && (
                  <RowContainer>
                    <CustomCol lg="6" md="8" sm="12">
                      <ValidationSummary id="summary"></ValidationSummary>
                    </CustomCol>
                  </RowContainer>
                )
              }
            </ValidationGroup>
          </RowContainer>
        </BlockUi>
      </div>
    </>
  )
}

export default React.memo(Nuevo)



export const defaultDataSheet: AtributosDatosEdicion = {
  codigo: 0,
  descripcion: '',
  opciones: [],
  orden: 1,
  atributoWeb: null,
  estado: null,
  dataGridoOpcion: [],
  guardado: false,
  loading: false,
}
