import { combineReducers, } from "@reduxjs/toolkit";
import { ComprasState } from "../../../types/genercis";
import { CompraInfo, ComprasDatosEdicion } from "../../../types/types";
import { menuReducer } from "./menuReducer";
import { searchComprasReducer } from "./searchReducer";
import { datosEdicionReducer } from "./editDataReducer";
import { tabsReducer } from "./tabsReducer";
import { configuracionesReducer } from "./configuracionesComprasReducer";
import { modalAutorizacionReducer } from "../../../../componentes/modalAutorizaciones/store/modalAutorizacion";
import { importarReducer } from "./importarReducer";

export const comprasReducer = combineReducers<ComprasState<CompraInfo, ComprasDatosEdicion>>({
  menu: menuReducer,
  tabs: tabsReducer,
  search: searchComprasReducer,
  editData: datosEdicionReducer,
  configuraciones: configuracionesReducer,
  modalAutorizacion: modalAutorizacionReducer,
  importar: importarReducer
});

