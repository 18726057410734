import { combineReducers } from "redux";
import { PayRolsState } from "../types/types";
import { menuReducer } from "./menuReducer";
import { tabsReducer } from "./tabsReducer";
import { dataEditReducer } from "./editDataReducer";
import { searchReducer } from "./searchReducer";
import { configuracionesRolNomina } from "./configReducer";
import { excellReducer } from "./excellReducer";
import { transferReducer } from "./transferReducer";
import { generateReducer } from "./generarReducer";



export const RolsPayReducer = combineReducers<PayRolsState>({
  menu: menuReducer,
  tabs: tabsReducer,
  editData: dataEditReducer,
  search: searchReducer,
  condfig: configuracionesRolNomina,
  excell: excellReducer,
  transfer: transferReducer,
  generate: generateReducer,
})