import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './folderTreeCSS.css';

class FilePane extends Component {
	static propTypes = {
		addNewFile: PropTypes.func.isRequired,
		toggleAddingNewFile: PropTypes.func.isRequired,
	};

	handleNewFile = () => {
		this.props.addNewFile(this.textInput.value);
		this.textInput.value = "";
	}

	render() {
		return (
			<div className={"filePane"}>
				<span>
					<input type="text" defaultValue={""} ref={input => { this.textInput = input; }} />
					<FontAwesomeIcon
						size="lg"
						icon={['fal', "check-circle"]} style={{ marginLeft: '10px' }} onClick={this.handleNewFile}
					/>
					<FontAwesomeIcon
						size="lg"
						icon={['fal', "times-circle"]} style={{ marginLeft: '10px' }} onClick={this.props.toggleAddingNewFile}
					/>
				</span>
			</div>
		);
	}

}

export default FilePane;
