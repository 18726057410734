import React from 'react';
import SelectBox from 'devextreme-react/select-box';
import { TOptionGenericoString } from '../../clientes/pages/clientes/types/types';

interface MesesLookUpProps extends React.PropsWithChildren {
  selected: TOptionGenericoString | null,
  onChanged: (newValue: TOptionGenericoString | null) => void,
  disabled?: boolean,
  provider?: Array<TOptionGenericoString> | []
}

const MesesLookUp: React.FC<MesesLookUpProps> = (props) => {
  const { provider, selected, onChanged, disabled } = props;
  const selectRef = React.useRef<any>();
  const [options, setOptions] = React.useState<Array<TOptionGenericoString> | []>([]);

  const loadDatos = React.useCallback(async () => {
    if (provider !== undefined && provider.length > 0) {
      setOptions(provider)
      const selectedIndex = provider.findIndex((select: TOptionGenericoString) => {
        return select.value === selected?.value
      })
      onChanged(provider[selectedIndex] ?? provider[0])
      if (selectedIndex > -1) {
        onChanged(provider[selectedIndex] ?? provider[0])
      }
    } else {
      const data = MesesData
      let opt = [];
      if (data.length > 0) {
        opt = data
      }
      setOptions(opt)
      const selectedIndex = opt.findIndex((option) => {
        return option.value === selected?.value
      })
      if (selectedIndex > -1) {
        onChanged(opt[selectedIndex] ?? opt[0])
      }
    }
  }, [onChanged, selected, provider]);

  const getItemDisplayExpr = React.useCallback((item: TOptionGenericoString) => {
    return item ? `${item.label}` : '';
  }, []);

  const onLookUpValueChanged = React.useCallback(async (evt: any) => {
    if (evt !== undefined) {
      await onChanged(evt);
    }
  }, [onChanged]);

  const refreshSelected = React.useCallback(async () => {
    if (options && options.length > 0) {
      const selectedIndex = await options.findIndex((option: TOptionGenericoString) => {
        return option.value === selected?.value
      })
      onChanged(options[selectedIndex] ?? options[0])
    }
    selectRef.current.instance.repaint();
  }, [onChanged, options, selected])

  const onSelectBoxFocusIn = React.useCallback((e) => {
    e.element.querySelector("input.dx-texteditor-input").select();
  }, [])

  React.useEffect(() => {
    loadDatos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    refreshSelected()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  return (
    <SelectBox
      id='mesesSelect'
      ref={selectRef}
      items={options ?? []}
      searchEnabled
      displayExpr={getItemDisplayExpr}
      searchExpr={['label']}
      searchTimeout={500}
      value={selected}
      onValueChange={onLookUpValueChanged}
      disabled={disabled}
      onFocusIn={onSelectBoxFocusIn}
    >
      {props.children}
    </SelectBox>
  )

}
export default React.memo(MesesLookUp);


export const MesesData: Array<TOptionGenericoString> = [
  {
    value: "-1",
    label: "Todos",
  },
  {
    value: "01",
    label: "ENERO",
  },
  {
    value: "02",
    label: "FEBRERO",
  },
  {
    value: "03",
    label: "MARZO",
  },
  {
    value: "04",
    label: "ABRIL",
  },
  {
    value: "05",
    label: "MAYO",
  },
  {
    value: "06",
    label: "JUNIO",
  },
  {
    value: "07",
    label: "JULIO",
  },
  {
    value: "08",
    label: "AGOSTO",
  },
  {
    value: "09",
    label: "SEPTIEMBRE",
  },
  {
    value: "10",
    label: "OCTUBRE",
  },
  {
    value: "11",
    label: "NOVIEMBRE",
  },
  {
    value: "12",
    label: "DICIEMBRE",
  },
]
