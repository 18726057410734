import { RequestHelper } from '../../../helpers/requestHelper'
import { SesionService } from '../../../services/sesion.service'
import { DateRange } from '../../../store/types'
import {
  ConceptoRetencion,
  RetencionventaListado,
  TiposPorcentajeRetencion,
  TiposRetencion,
} from '../pages/retenciones/types/types'
import { InfoInsertarRetencion } from '../types/types'

export const RetencionesService = {
  getRetenciones,
  getConceptosRetencion,
  guardarRetencion,
  getRetencionByCode,
}

async function getRetencionByCode(
  code: number,
): Promise<RetencionventaListado> {
  const sesion = SesionService.getCurrentSesion()
  const query = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
    codigo: code,
  }
  const apiData = await RequestHelper.get<Array<RetencionventaListado>>(
    'ventas',
    'retentionsale/byCode',
    '',
    query,
  )

  if (apiData.length === 0) {
    throw new Error('No se encontró la retención')
  }
  return apiData[0]
}

async function getRetenciones(
  rangoFechas: DateRange,
  formaPago: number = -1,
  esFinanciera: boolean = false,
  idCliente: string = '',
  numeroRetencion: string = '',
) {
  const sesion = SesionService.getCurrentSesion()
  const queryData: any = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
    fechaDesde: rangoFechas.inicio,
    fechaHasta: rangoFechas.fin,
  }
  if (formaPago > 0) queryData.retencionFPago = formaPago
  if (esFinanciera) queryData.esFinanciera = esFinanciera
  if (idCliente) queryData.idCliente = idCliente
  if (numeroRetencion) queryData.numeroRetencion = numeroRetencion
  const apiData = await RequestHelper.get<Array<any>>(
    'ventas',
    'retencionven/listar',
    '',
    queryData,
  )
  const retenciones: Array<RetencionventaListado> = []
  for (const retApi of apiData) {
    retenciones.push({
      codigo: retApi.REV_CODIGO,
      autorizacion: retApi.REV_AUTORIZACION,
      establecimiento: retApi.REV_ESTABLECIMIENTO,
      puntoEmision: retApi.REV_PTOEMISION,
      numero: retApi.REV_NUMERO,
      numeroCompleto: retApi.REV_NUMEROCOMPLETO,
      clienteCodigo: retApi.CLI_CODIGO,
      clienteNombre: retApi.CLI_NOMBRE,
      fecha: retApi.REV_FECHA,
      total: retApi.REV_TOTAL,
      ventaCodigo: parseInt(retApi.VEN_CODIGO),
      usuarioCodigo: parseInt(retApi.USU_CODIGO),
      formaPagoCodigo: parseInt(retApi.REV_FPAGO),
      formaPago: retApi.REV_FPAGODES,
      estadoCodigo: parseInt(retApi.REV_ESTADO),
      estado: retApi.REV_ESTADO_DES,
      loteTC: retApi.REV_LOTETAR,
      ventaNumero: retApi.VEN_NUMEROCOMPLETO,
      local: retApi.LOC_NOMBRE,
    })
  }
  return retenciones
}

async function getConceptosRetencion(
  tipo: TiposRetencion,
  fecha: string,
): Promise<Array<ConceptoRetencion>> {
  let _tipo = 'todos'
  switch (tipo) {
    case TiposRetencion.IVA:
      _tipo = 'iva'
      break
    case TiposRetencion.Fuente:
      _tipo = 'fuente'
      break
    case TiposRetencion.NoDefinido:
      _tipo = 'fuenteIva'
      break
    default:
      break
  }
  const sesion = SesionService.getCurrentSesion()
  const queryData: any = {
    fecha: fecha,
    tipo: _tipo,
    paisCodigo: sesion?.empresa?.codigoPais,
  }
  const sesionKey = `${tipo}${fecha}`
  const cachedData = sessionStorage.getItem(sesionKey)
  if (cachedData) {
    return JSON.parse(cachedData)
  } else {
    const apiData = await RequestHelper.get<Array<any>>(
      'conceptosret',
      'listar',
      '',
      queryData,
    )
    if (_tipo === 'fuenteIva') {
      const conceptos: Array<ConceptoRetencion> = []
      for (const conceptoApi of apiData) {
        const concepto: ConceptoRetencion = {
          codigo: conceptoApi.codigo,
          concepto: conceptoApi.concepto,
          porcentaje: parseFloat(conceptoApi.porcentaje),
          estado: conceptoApi.estado === '1' ? true : false,
          tipo:
            conceptoApi.tipo === 'fuente'
              ? TiposRetencion.Fuente
              : TiposRetencion.IVA,
          tipoPorcentaje: conceptoApi.tipoPorcentaje,
        }
        if (concepto.tipoPorcentaje === TiposPorcentajeRetencion.Limite) {
          if (conceptoApi.inferior !== null) {
            concepto.rango = {
              inferior: parseFloat(conceptoApi.inferior),
              superior: parseFloat(conceptoApi.superior),
            }
          } else {
            concepto.rango = {
              inferior: 0,
              superior: 0,
            }
          }
        } else if (
          concepto.tipoPorcentaje === TiposPorcentajeRetencion.Opciones
        ) {
          if (conceptoApi.opciones) {
            concepto.opciones = conceptoApi.opciones.map((x) => parseFloat(x))
          } else {
            concepto.opciones = []
          }
        }
        conceptos.push(concepto)
      }
      return conceptos
    } else {
      const conceptos: Array<ConceptoRetencion> = []
      for (const conceptoApi of apiData) {
        conceptos.push({
          codigo: conceptoApi.codigo,
          concepto: conceptoApi.concepto,
          porcentaje: parseFloat(conceptoApi.porcentaje),
          estado: conceptoApi.estado === '1' ? true : false,
          tipo: _tipo === 'iva' ? TiposRetencion.IVA : TiposRetencion.Fuente,
          tipoPorcentaje: conceptoApi.tipoPorcentaje,
        })
      }
      sessionStorage.setItem(sesionKey, JSON.stringify(conceptos))
      return conceptos
    }
  }
}

async function guardarRetencion(
  infoRetencion: InfoInsertarRetencion,
): Promise<RetencionventaListado> {
  const sesion = SesionService.getCurrentSesion()
  infoRetencion.infoEmpresa.ruc = sesion.empresa.ruc
  infoRetencion.infoRegistro.codigoEmpresa = sesion.empresa.codigo
  infoRetencion.infoRegistro.codigoUsuario = sesion.usuario.codigo
  infoRetencion.infoRegistro.codigoLocal = sesion.local.codigo
  infoRetencion.infoRegistro.infoUsuario.usuario = sesion.usuario.idsesion
  const apiRespuesta = await RequestHelper.post<RetencionventaListado>(
    'ventas',
    'retentionsale/insert',
    infoRetencion,
  )
  return apiRespuesta
}
