import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StatesEdition, TabTypes } from "../../../../../../store/enums";
import { TabState, TabsState } from "../../../../../../store/genericTabTypes";
import { ButtonTypes } from "../../../../../../views/componentes/globalMenu/types";
import { closeTab } from "../../../../../../hooks/useNavigationTabControls";
import { CustomButtons } from '../../../../../../store/types';
import { MarcacionDatosEdicion } from '../types/types';
import { ModuleButtons } from '..';


const initialState: TabsState<any> = {
  current: 'BUSQUEDA',
  tabs: {
    BUSQUEDA: {
      id: 0,
      canClose: false,
      editStatus: StatesEdition.doesNotApply,
      globalButtonClick: ButtonTypes.none,
      tittle: 'Búsqueda',
      type: TabTypes.busqueda,
      imprimir: false,
      tabKey: 'BUSQUEDA',
      buttons: {
        Nuevo: true,
        Buscar: true,
        Guardar: false,
        Limpiar: false,
        Deshacer: true,
        Importar: false,
        Imprimir: false,
        Marcacion: true,
        Asociar: true,
        Check: false
      },
    },
  },
}

const contratosTabsSlice = createSlice({
  name: 'marcacionTabs',
  initialState: initialState,
  reducers: {
    marcacionCloseTab: closeTab,
    openTab(
      state,
      action: PayloadAction<{
        key: string
        store?: MarcacionDatosEdicion
        templateMode?: boolean
      }>,
    ) {
      const { key, store, templateMode } = action.payload
      if (store === undefined || templateMode) {
        const tab: TabState<MarcacionDatosEdicion> = {
          id: -1,
          canClose: true,
          editStatus: StatesEdition.new,
          globalButtonClick: ButtonTypes.none,
          tittle: 'Nueva',
          type: TabTypes.documento,
          imprimir: false,
          tabKey: key,
          info: {
            codigo: 0,
          },
          buttons: ModuleButtons,
          templateMode: templateMode,
        }
        state.tabs[key] = tab
        state.current = key
      }
    },
    setNameTab(
      state,
      action: PayloadAction<{ key: string; codigo: number; nombre: string }>,
    ) {
      state.tabs[
        action.payload.key
      ].tittle = `${action.payload.codigo}-${action.payload.nombre}`
      state.tabs[action.payload.key].info.codigo = action.payload.codigo
    },
    changeCurrentTab(state, action: PayloadAction<string>) {
      if (Object.prototype.hasOwnProperty.call(state.tabs, action.payload)) {
        state.current = action.payload
      }
    },
    setCurrentExecutingAction(
      state,
      action: PayloadAction<{
        tabKey: string
        buttonType: ButtonTypes | undefined
      }>,
    ) {
      const { buttonType, tabKey } = action.payload
      state.tabs[tabKey].currentExecutingAction = buttonType
    },
    setButtons(
      state,
      action: PayloadAction<{ tabKey: string; buttons: CustomButtons }>,
    ) {
      const { buttons, tabKey } = action.payload
      state.tabs[tabKey].buttons = buttons
      state.tabs[tabKey].currentExecutingAction = undefined
    },
    buttonClick(
      state,
      action: PayloadAction<{ tabKey: string; button: ButtonTypes }>,
    ) {
      state.tabs[action.payload.tabKey].globalButtonClick =
        action.payload.button
    },
    clearButtonClick(state, action: PayloadAction<string>) {
      if (Object.prototype.hasOwnProperty.call(state.tabs, action.payload)) {
        state.tabs[action.payload].globalButtonClick = ButtonTypes.none
      }
    },
  }
})


export const {
  marcacionCloseTab,
  openTab,
  changeCurrentTab,
  setCurrentExecutingAction,
  setButtons,
  buttonClick,
  clearButtonClick,
  setNameTab,
} = contratosTabsSlice.actions
export const tabsReducer = contratosTabsSlice.reducer