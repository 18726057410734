import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FETCH_STATUS, LoaderInfo } from "../../../../../store/types";
import { BusquedaState, ContratosList, FiltroBusquedaContratos } from "../types/types";
import { initialFilter } from '../components/busqueda/FiltroBusqueda'
import { ContratsServices } from '../services/contratos.services';


const initialState: BusquedaState<ContratosList> = {
  filtro: {
    ...initialFilter
  },
  filtroBackup: {
    ...initialFilter
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  resultados: [],
  seleccionado: null,
  colapsado: true,
  import: false,
  searchContratos: false,
  loader: {
    show: false,
    mensaje: '',
  },
  nroRegistrosEncontrados: 0
}

export const fetchContractList = createAsyncThunk<
  Array<ContratosList>,
  FiltroBusquedaContratos
>('nomina/contrato/listar', async (filtro) => {
  try {
    const linesList = ContratsServices.getContratsList(filtro)
    return linesList
  } catch (error) {
    return Promise.reject(error)
  }
})


const searchSlice = createSlice({
  name: 'buscarContratos',
  initialState: initialState,
  reducers: {
    setResetResults(state) {
      state.resultados = []
    },
    setResults(state, action: PayloadAction<ContratosList[]>) {
      state.resultados = action.payload
    },
    changeFilter(state, action: PayloadAction<FiltroBusquedaContratos>) {
      state.filtro.empleado = action.payload.empleado
      state.filtro.tipoContrato = action.payload.tipoContrato
      state.filtro.procesos = action.payload.procesos
      state.filtro.jornada = action.payload.jornada
      state.filtro.departamento = action.payload.departamento
      state.filtro.pais = action.payload.pais
      state.filtro.tipoPersonal = action.payload.tipoPersonal
      state.filtro.desde = action.payload.desde
      state.filtro.hasta = action.payload.hasta
      state.filtro.initital = action.payload.initital
    },
    changeFilterBackup(state, action: PayloadAction<FiltroBusquedaContratos>) {
      state.filtroBackup.empleado = action.payload.empleado
      state.filtroBackup.tipoContrato = action.payload.tipoContrato
      state.filtroBackup.procesos = action.payload.procesos
      state.filtroBackup.jornada = action.payload.jornada
      state.filtroBackup.departamento = action.payload.departamento
      state.filtroBackup.pais = action.payload.pais
      state.filtroBackup.tipoPersonal = action.payload.tipoPersonal
      state.filtroBackup.desde = action.payload.desde
      state.filtroBackup.hasta = action.payload.hasta
      state.filtroBackup.initital = action.payload.initital
    },
    setResetSeleccion(state) {
      state.seleccionado = null
    },
    setSeleccionarDato(state, action: PayloadAction<ContratosList>) {
      state.seleccionado = action.payload
    },
    setCollapsed(state, action: PayloadAction<boolean>) {
      state.colapsado = action.payload
    },
    setImport(state, action: PayloadAction<boolean>) {
      state.import = action.payload
    },
    setNroRegistrosEncontradas(state, action: PayloadAction<number>) {
      state.nroRegistrosEncontrados = action.payload
    },
    setChangeLoader(state, action: PayloadAction<LoaderInfo>) {
      state.loader = action.payload
    },
    setSearch(state, action: PayloadAction<boolean>) {
      state.searchContratos = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchContractList.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchContractList.fulfilled, (state, { payload }) => {
      state.resultados = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchContractList.rejected, (state, { payload }) => {
      state.resultados = []
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
  },
})

export const {
  setResetResults,
  setResults,
  changeFilter,
  changeFilterBackup,
  setResetSeleccion,
  setSeleccionarDato,
  setCollapsed,
  setImport,
  setNroRegistrosEncontradas,
  setChangeLoader,
  setSearch,
} = searchSlice.actions
export const searchReducer = searchSlice.reducer