import { RequestHelper } from '../../../../../../helpers/requestHelper'
import { GuardarFichaTecnica, GuardarFichaTecnicaDetalle } from '../types/types'

export const dataSheetServices = {
  geDataSheets,
  setDataSheets,
  deleteDataSheet,
  geDataSheetsKitCom,
  setDataSheetsClone,
  setDataSheetsDetail,
  deleteDataSheetsDetail,
  geDataSheetsTransaction
}

async function geDataSheets(data: any): Promise<any> {
  try {
    const obj = {
      base: data['base'],
      grupo: data['grupoCodigo'],
    }

    const dataApi = await RequestHelper.getAll('inventario/catalogos', 'fichaTecnica/getAll', '', obj)
    return dataApi
  } catch (error) {
    return error
  }
}
async function geDataSheetsKitCom(data: any): Promise<any> {
  try {
    const obj = {
      codigo: data['codigo'],
    }

    const dataApi = await RequestHelper.getAll('inventario/catalogos', 'fichaTecnica/kitcompuestos/getAll', '', obj)
    return dataApi
  } catch (error) {
    return error
  }
}

async function geDataSheetsTransaction(data: any): Promise<any> {
  try {
    const obj = {
      codigoFicha: data['codigo'],
    }

    const dataApi = await RequestHelper.getAll('inventario/catalogos', 'fichaTecnica/getTransacction', '', obj)
    return dataApi
  } catch (error) {
    return error
  }
}

async function setDataSheets(requestBody: GuardarFichaTecnica): Promise<any> {
  try {
    const obj = {
      ...requestBody
    }
    const saveApi = await RequestHelper.postAll('inventario/catalogos', 'fichaTecnica/save', obj)
    return saveApi
  } catch (error) {
    return error
  }
}

async function setDataSheetsClone(requestBody: GuardarFichaTecnica): Promise<any> {
  try {
    const obj = {
      ...requestBody
    }
    const saveApi = await RequestHelper.postAll('inventario/catalogos', 'fichaTecnica/clone', obj)
    return saveApi
  } catch (error) {
    return error
  }
}

async function setDataSheetsDetail(requestBody: GuardarFichaTecnicaDetalle): Promise<any> {
  try {
    const obj = {
      ...requestBody
    }
    const saveApi = await RequestHelper.postAll('inventario/catalogos', 'fichaTecnica/kitcompuestos/save', obj)
    return saveApi
  } catch (error) {
    return error
  }
}


async function deleteDataSheetsDetail(data: any): Promise<any> {
  try {
    const obj = {
      codigo: data['codigo'],
      codigoFicha: data['ficha'],
    }
    const saveApi = await RequestHelper.deleteRequestAll('inventario/catalogos', 'fichaTecnica/kitcompuestos/delete', '', obj)
    return saveApi
  } catch (error) {
    return error
  }
}

async function deleteDataSheet(performanceCode: number): Promise<any> {
  try {
    const obj = {
      codigo: performanceCode
    }
    const saveApi = await RequestHelper.deleteRequestAll('inventario/catalogos', 'fichaTecnica/delete', '', obj)
    return saveApi
  } catch (error) {
    return error
  }
}
