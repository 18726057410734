import { RequestHelper } from '../../../../../helpers/requestHelper'
import { utilidades } from '../../../../../helpers/utilidades'
import { SesionService } from '../../../../../services/sesion.service'
import {
  CapitalizeMode,
  PagedResult,
  PaginationInfo,
} from '../../../../../store/types'
import { LocalOption } from '../../../../componentes/localesLookUp/store/type'
import { FiltroBusquedaUsuarioState, UsuarioListado } from '../store/types'

export const UsuariosService = {
  getEmpresaUsuarios,
  getAllUsuarios,
  getAllUsuariosPaged,
  getMaxUsers,
  getLocalesUsuarios,
  deleteUser,
}

async function getAllUsuariosPaged(
  filtro: FiltroBusquedaUsuarioState,
  pagination: PaginationInfo,
): Promise<PagedResult<UsuarioListado>> {
  const query = buildQuery(filtro, pagination)

  const pagedApiResult = await RequestHelper.get<any>(
    'admin',
    'usuarios/getAll',
    '',
    query,
  )
  const usuarios = parseUsuarios(pagedApiResult.auto)

  return {
    count: pagedApiResult.totalRegistros,
    currentPage: pagedApiResult.paginaActual,
    from: pagedApiResult.desde,
    result: usuarios,
    to: pagedApiResult.hasta,
  }
}

async function getAllUsuarios(
  filtro: FiltroBusquedaUsuarioState,
): Promise<Array<UsuarioListado>> {
  const query = buildQuery(filtro)
  const pagedApiResult = await RequestHelper.getAll<any>(
    'admin',
    'usuarios/getAll',
    '',
    query,
  )
  return pagedApiResult.auto ? parseUsuarios(pagedApiResult.auto) : []
}

async function getEmpresaUsuarios(
  filtro: FiltroBusquedaUsuarioState,
): Promise<Array<UsuarioListado>> {
  const sesion = SesionService.getCurrentSesion()
  const query = buildQuery(filtro)
  query.codigoEmpresa = sesion?.empresa?.codigo
  query.local = sesion?.local?.codigo
  const pagedApiResult = await RequestHelper.getAll<any>(
    'admin',
    'usuarios/getByCompany',
    '',
    query,
  )
  return pagedApiResult.auto ? parseUsuarios(pagedApiResult.auto) : []
}

function buildQuery(
  filtro: FiltroBusquedaUsuarioState,
  pagination?: PaginationInfo,
): any {
  const query: any = {
    local: -1,
    estado: filtro?.estado ? 1 : 0,
  }

  if (pagination) {
    query.page = pagination.current
    query.limit = pagination.size
    query.isPaged = true
  }

  const filters = ['usuario', 'identificacion', 'nombres', 'apellidos']
  filters.forEach((field) => {
    if (filtro[field] && filtro[field].length > 0) {
      query[field] = filtro[field]
    }
  })

  if (filtro?.usuario) {
    query.usuarioId = filtro.usuario
  }
  if (filtro?.identificacion) {
    query.cedula = filtro.identificacion
  }
  if (filtro?.ubicacion?.codigo > 0) {
    query.ciudadCodigo = filtro.ubicacion.codigo
  }
  if (filtro?.local?.codigo > 0) {
    query.local = filtro.local.codigo
  }

  return query
}

function parseUsuarios(dataApi: any[]): Array<UsuarioListado> {
  let opt: Array<UsuarioListado> = []
  opt = dataApi.map((i: any) => {
    const it = {
      codigo: parseInteger(i?.codigo),
      nombres: i?.nombres ?? '',
      apellidos: i?.apellidos ?? '',
      nombre: i?.nombre ?? '',
      usuario: i?.idsesion ?? '',
      identificacion: i?.cedula ?? '',
      fcreacion: i?.fcreacion ?? '',
      fnacimiento: i?.fnacimiento ?? '',
      direccion: i?.direccion ?? '',
      numero: i?.numero ?? '',
      telefono: i?.telefono ?? '',
      celular1: i?.celular1 ?? '',
      celular2: i?.celular2 ?? '',
      email: i?.email ?? '',
      tipo: i?.tipo ?? '',
      titulos: i?.titulos ?? '',
      ciudadCodigo: parseInteger(i?.ciuCodigo),
      ciudadDescripcion: i?.ciuNombre ?? '',
      ciuDepCodigo: parseInteger(i?.ciuDepCodigo),
      proCodigo: parseInteger(i?.proCodigo),
      proNombre: i?.proNombre ?? '',
      proRDepCodigo: parseInteger(i?.proRDepCodigo),
      ideCodigo: i?.ideCodigo ?? '',
      pase: i?.pase ?? '',
      password: i?.password ?? '',
      genero: parseInteger(i?.genero),
      ecivil: parseInteger(i?.ecivil),
      foto: i?.foto ?? '',
      firma: i?.firma ?? '',
      estado: parseInteger(i?.estado),
      tipoSangre: i?.tipoSangre ?? '',
      codigoIess: i?.codigoIess ?? '',
      ciuOrigen: parseInteger(i?.ciuOrigen),
      nivelCodigo: parseInteger(i?.nivelCodigo),
      paiCodigo: parseInteger(i?.paiCodigo),
      paiNombre: i?.paiNombre ?? '',
      cursos: i?.cursos ?? '',
      host: i?.host ?? '',
      titulo: '',
      nivelEstudio: '',
      cargasFamiliares: parseInteger(i?.cargasFamiliares),
    }
    return it
  })
  return opt
}

function parseInteger(value: any): number {
  return isNaN(parseInt(value)) ? 0 : parseInt(value)
}

async function getMaxUsers(): Promise<any> {
  const pagedApiResult = await RequestHelper.getAll<any>(
    'generales',
    'count/users',
    '',
    {},
  )
  return pagedApiResult?.auto
}

async function getLocalesUsuarios(usuario: number): Promise<any> {
  const query = {
    usuario,
  }
  const res = await RequestHelper.getAll<any>(
    'inventario/multialmacen',
    'local/usuario',
    '',
    query,
  )
  let opt: Array<LocalOption> = []
  if (res?.auto && res?.auto.length > 0) {
    opt = res?.auto.map((x) => {
      const item: LocalOption = {
        codigo: parseInt(x?.codigo) ?? 0,
        ciudad: parseInt(x?.ciucodigo) ?? 0,
        ciudadNombre: utilidades.capText(x?.ciudad) ?? '',
        nombre: utilidades.capText(x?.nombre, CapitalizeMode.upperFirst) ?? '',
        direccion: x?.direccion ?? '',
        telefono: x?.telefono ?? '',
        gerente: '',
        tipo: parseInt(x?.tipo) ?? 0,
        tipoDescripcion: '',
        clase: parseInt(x?.clase) ?? 0,
        claseDescripcion: x?.clase ?? '',
        pertenece: -1,
        perteneceDescripcion: '',
        estado: parseInt(x?.estado) ?? 0,
        estadoDescripcion: '',
        kardex: parseInt(x?.kardex) ?? 0,
        siglas: x?.siglas ?? '',
        siglasLocal: '',
        web: 0,
        uaf: x?.uafe ?? '',
        descuento: parseInt(x?.descuento) ?? 0,
        contrato: 0,
        observaciones: '',
        usuarioDokan: '',
        passwordDokan: '',
        ulotipo: x?.tipoRol ?? '',
      }
      return item
    })
  }
  res.auto = opt ?? []

  return res
}

async function deleteUser(codigo: number): Promise<any> {
  try {
    const d = await RequestHelper.deleteRequestAll(
      'admin/usuarios',
      'inactive',
      '',
      { codigo },
    )
    return d
  } catch (e) {
    return e
  }
}
