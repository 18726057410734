import React, { PureComponent } from 'react';

class SheetTextEditor extends PureComponent {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.inputRef = React.createRef();
    //this.handleChange = this.handleChange.bind(this);
    //this.handleKeyDown = this.handleKeyDown.bind(this);
    //this.state = { value: this.props.value };
  }

  componentDidMount() {
    this.inputRef.current.focus();
  }

  handleChange(e) {
    this.props.onChange(e.target.value)
  }

  // handleKeyDown(e) {
  //   const { onCommit, onRevert } = this.props
  //   if (e.which === ESCAPE_KEY) {
  //     return onRevert()
  //   }
  //   if (e.which === ENTER_KEY) {
  //     onCommit(this.state.value, null);
  //   }
  // }

  render() {
    const { value, onKeyDown } = this.props
    return (
      <input
        ref={this.inputRef}
        type='text'
        value={value}
        onChange={this.handleChange}
        onKeyDown={onKeyDown}
        size='sm'
        style={{ width: '100%', height: '100%' }}
        className='form-control form-control-sm'
      />
    )
  }
}
export { SheetTextEditor };