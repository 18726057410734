import { RequestHelper } from '../../../../../helpers/requestHelper'
import {
  EditPrices,
  FilterSearchToApi,
  PaginationSearch,
  SaveItem,
  SaveWooItem,
  SyncWoo,
} from '../types/types'

export const ItemsServices = {
  getItmesByBase,
  getItmesBySupplier,
  saveItemWoo,
  generateBarCode,
  getPricesItem,
  getItemExistenceStore,
  getItemExistenceKitStore,
  getAccoutingAccounts,
  getAplications,
  getCharacteristics,
  getParts,
  getPromotions,
  getSuppliers,
  getItemDetails,
  saveItem,
  deleteItem,
  unifyItem,
  syncItemWoo,
  importTemplate,
  editPriceItems,
  assemblyKitItem,
  inactivateItems,
  deleteAssignment,
  deletePromotion,
  deletePart,
}

async function getItmesByBase(
  filter: FilterSearchToApi,
  pagination: PaginationSearch = {
    isPaged: false,
    page: 0,
    limit: 0,
  },
) {
  const queryData: any = { ...filter, ...pagination }
  const itemsApiResult: any = await RequestHelper.get<any>(
    'inventario/items',
    'searchItemsBase',
    '',
    queryData,
  )

  if (itemsApiResult['auto'] === undefined || null) return itemsApiResult

  return {
    currentPage: parseInt(itemsApiResult.paginaActual),
    from: parseInt(itemsApiResult.desde),
    to: parseInt(itemsApiResult.hasta),
    count: parseInt(itemsApiResult.totalRegistros),
    result: itemsApiResult.auto,
  }
}

async function getItmesBySupplier(filter: FilterSearchToApi): Promise<any> {
  try {
    const res = await RequestHelper.getAll<any>(
      'inventario/items',
      'searchItemBaseSupplier',
      '',
      filter,
    )
    return res
  } catch (error) {
    return error
  }
}

async function saveItemWoo(item: SaveWooItem): Promise<any> {
  try {
    const apiResult = await RequestHelper.postAll<any>(
      'inventario/items',
      'updateWooItem',
      { ...item },
    )
    return apiResult
  } catch (error) {
    return error
  }
}

async function generateBarCode(dataToGenerate: any): Promise<any> {
  try {
    const apiResult = await RequestHelper.getAll<any>(
      'inventario/items',
      'generateCodigo',
      '',
      { ...dataToGenerate },
    )
    return apiResult
  } catch (error) {
    return error
  }
}

async function getPricesItem(codeItem: number): Promise<any> {
  try {
    const apiResult = await RequestHelper.getAll<any>(
      'inventario/items',
      'itemPrices/getAll',
      '',
      { codigo: codeItem },
    )
    return apiResult
  } catch (error) {
    return error
  }
}

async function getItemExistenceStore(
  codeItem: number,
  loteItem: number,
): Promise<any> {
  try {
    const apiResult = await RequestHelper.getAll<any>(
      'inventario/items',
      'getStocksByLocal',
      '',
      { codigo: codeItem, lotes: loteItem },
    )
    return apiResult
  } catch (error) {
    return error
  }
}

async function getItemExistenceKitStore(
  codeItem: number,
  loteItem: number,
): Promise<any> {
  try {
    const apiResult = await RequestHelper.getAll<any>(
      'inventario/items',
      'getStocksKitsByLocal',
      '',
      { codigo: codeItem, lotes: loteItem },
    )
    return apiResult
  } catch (error) {
    return error
  }
}

async function getAccoutingAccounts(
  codeItem: number,
  type: string,
): Promise<any> {
  try {
    const apiResult = await RequestHelper.getAll<any>(
      'inventario/items',
      'assignments/getAll',
      '',
      { tipo: type, item: codeItem },
    )
    return apiResult
  } catch (error) {
    return error
  }
}

async function getAplications(codeItem: number): Promise<any> {
  try {
    const apiResult = await RequestHelper.getAll<any>(
      'inventario/items',
      'itemAPL/getAll',
      '',
      { codigo: codeItem },
    )
    return apiResult
  } catch (error) {
    return error
  }
}

async function getCharacteristics(codeItem: number): Promise<any> {
  try {
    const apiResult = await RequestHelper.getAll<any>(
      'inventario/items',
      'caracteristicas/getAll',
      '',
      { codigo: codeItem },
    )
    return apiResult
  } catch (error) {
    return error
  }
}

async function getParts(codeItem: number): Promise<any> {
  try {
    const apiResult = await RequestHelper.getAll<any>(
      'inventario/items',
      'relations/getAll',
      '',
      { codigo: codeItem },
    )
    return apiResult
  } catch (error) {
    return error
  }
}

async function getPromotions(codeItem: number): Promise<any> {
  try {
    const apiResult = await RequestHelper.getAll<any>(
      'inventario/items',
      'promotions/getAll',
      '',
      { codigo: codeItem },
    )
    return apiResult
  } catch (error) {
    return error
  }
}

async function getSuppliers(codeItem: number): Promise<any> {
  try {
    const apiResult = await RequestHelper.getAll<any>(
      'inventario/items',
      'itemPro/getAll',
      '',
      { codigo: codeItem },
    )
    return apiResult
  } catch (error) {
    return error
  }
}

async function getItemDetails(codeItem: number): Promise<any> {
  try {
    const apiResult = await RequestHelper.get<any>(
      'inventario/items',
      'get',
      '',
      { codigo: codeItem },
    )
    return apiResult
  } catch (error) {
    return error
  }
}

async function saveItem(newItem: SaveItem): Promise<any> {
  try {
    const apiResult = await RequestHelper.postAll<any>(
      'inventario/items',
      'saveAll',
      { ...newItem },
    )
    return apiResult
  } catch (error) {
    return error
  }
}
async function deleteItem(codeItem: number): Promise<any> {
  try {
    const apiResult = await RequestHelper.deleteRequestAll<any>(
      'inventario/items',
      'inactive',
      '',
      { codigo: codeItem },
    )
    return apiResult
  } catch (error) {
    return error
  }
}

async function unifyItem(
  codeItem: number,
  codeItemToUnify: number,
): Promise<any> {
  try {
    const apiResult = await RequestHelper.getAll<any>(
      'inventario/items',
      'unificateItem',
      '',
      { codigo: codeItem, codigoU: codeItemToUnify },
    )
    return apiResult
  } catch (error) {
    return error
  }
}

async function syncItemWoo(item: SyncWoo): Promise<any> {
  try {
    const apiResult = await RequestHelper.postAll<any>(
      'inventario/items',
      'syncWoo',
      { ...item },
    )
    return apiResult
  } catch (error) {
    return error
  }
}

async function importTemplate(archivo: any, storeCode: number): Promise<any> {
  try {
    const apiResult = await RequestHelper.postData<any>(
      'inventario/items',
      'importar',
      { archivo: archivo, local: storeCode },
    )
    return apiResult
  } catch (error) {
    return error
  }
}

async function editPriceItems(items: EditPrices): Promise<any> {
  try {
    const apiResult = await RequestHelper.postAll<any>(
      'inventario/items',
      'editPrices',
      items,
    )
    return apiResult
  } catch (error) {
    return error
  }
}

async function assemblyKitItem(assemblyKit: any): Promise<any> {
  try {
    const apiResult = await RequestHelper.postAll<any>(
      'inventario/catalogos/fichaTecnica',
      'kits/saveAll',
      assemblyKit,
    )
    return apiResult
  } catch (error) {
    return error
  }
}

async function inactivateItems(itemsToInactivate: Array<any>): Promise<any> {
  try {
    const apiResult = await RequestHelper.postAll<any>(
      'inventario/items',
      'inactivateItems',
      { items: itemsToInactivate },
    )
    return apiResult
  } catch (error) {
    return error
  }
}

async function deleteAssignment(codeAssignment: number): Promise<any> {
  try {
    const apiResult = await RequestHelper.deleteRequestAll<any>(
      'contabilidad',
      'assignment/delete',
      '',
      { codigo: codeAssignment },
    )
    return apiResult
  } catch (error) {
    return error
  }
}

async function deletePromotion(codePromotion: number): Promise<any> {
  try {
    const apiResult = await RequestHelper.deleteRequestAll<any>(
      'inventario/items',
      'promotions/delete',
      '',
      { codigo: codePromotion },
    )
    return apiResult
  } catch (error) {
    return error
  }
}

async function deletePart(codePart: number): Promise<any> {
  try {
    const apiResult = await RequestHelper.deleteRequestAll<any>(
      'inventario/items',
      'relations/delete',
      '',
      { codigo: codePart },
    )
    return apiResult
  } catch (error) {
    return error
  }
}

