import React from 'react'
import { DataGrid } from 'devextreme-react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import ItemsCountIndicator from '../../../../../../componentes/indicadorNroRegistros'
import { Column, ColumnChooser, Export, Pager, Paging, Button as DatagridButton, MasterDetail } from 'devextreme-react/data-grid'
import { getAllowedPageSizes } from '../../../../../../../helpers/Helper'
import { InventarioInicial, InventarioMovDatosEdicion } from '../../types/types'
import { TabState } from '../../../../../../../store/genericTabTypes'
import { Workbook } from 'exceljs'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { saveAs } from 'file-saver';
import { consoleService } from '../../../../../../../services/console.service'
import { setSeleccionarDato, setVerRegistro } from '../../store/serachReducer'
import { ActionSheetCommandsMobile } from './ActionSheetCommandsMobile'
import { isMobile } from 'react-device-detect'
import DetalleInventarioIinicial from './detalleInventarioInicial'


interface IResultadosBusquedaProps extends React.PropsWithChildren {
  tab: TabState<InventarioMovDatosEdicion>
  tabId: string
  onDBEdit: () => void
  onDelete: () => void
  onVer: (importacion: InventarioInicial) => void
}

const ResultadosBusqueda: React.FunctionComponent<IResultadosBusquedaProps> = (props) => {
  const { onDBEdit, onDelete, onVer } = props
  const dispatch = useDispatch()
  const searchResults = useSelector((state: RootState) => state.inventarios.movimientos.inventarioInicial.search.resultados)
  const unitsDataGrid = React.useRef<any>()

  const onSelectedInvIniChanged = React.useCallback(({ selectedRowsData }) => {
    consoleService.log('selected ', selectedRowsData[0])
    if (selectedRowsData[0]?.codigo) {
      dispatch(setSeleccionarDato({ ...selectedRowsData[0] }))
    }
  }, [dispatch])

  const onDBClick = React.useCallback(() => {
    onDBEdit()
  }, [onDBEdit])

  const onExportExcell = React.useCallback((e) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('Main sheet')
    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Inventario_Inicial.xlsx')
      });
    });
  }, [])

  const actionSheetCommandsMobile = React.useCallback(
    (cellData) => {
      const data = cellData.data as InventarioInicial
      return (
        <ActionSheetCommandsMobile
          importacion={data}
          key={data.codigo}
          onDelete={(data) => {
            dispatch(setSeleccionarDato({ ...data }))
            onDelete()
          }}
          onVer={(data) => {
            dispatch(setSeleccionarDato({ ...data }))
            dispatch(setVerRegistro(true))
            onVer(data)
          }}
          onEdit={(data) => {
            dispatch(setSeleccionarDato({ ...data }))
            onDBEdit()
          }}
        />
      )
    },
    [dispatch, onDelete, onVer, onDBEdit],
  )

  return (
    <>
      <ItemsCountIndicator items={searchResults['auto'] ?? []} />
      <DataGrid
        ref={unitsDataGrid}
        keyExpr="codigo"
        //focusedRowKey="codigo"
        id="tabConceptosMovimiento"
        selection={{ mode: 'single' }}
        dataSource={searchResults['auto'] ?? []}
        showColumnLines={true}
        showRowLines={true}
        showBorders={true}
        columnResizingMode="widget"
        focusedRowEnabled={true}
        onSelectionChanged={onSelectedInvIniChanged}
        onRowDblClick={onDBClick}
        onExporting={onExportExcell}
        loadPanel={{
          enabled: true,
          height: 90,
          indicatorSrc: '',
          shading: false,
          shadingColor: '',
          showIndicator: true,
          showPane: true,
          text: 'Cargando...',
          width: 200,
        }}
      >
        <Paging defaultPageSize={20} />
        <Pager
          visible={(searchResults['auto'] && searchResults['auto'].length > 0) ? true : false}
          allowedPageSizes={getAllowedPageSizes(searchResults['auto'] ?? [])}
          displayMode={'full'}
          showPageSizeSelector={true}
          showInfo={true}
          showNavigationButtons={true}
        />
        <ColumnChooser enabled={true} mode="select" />
        <Export enabled={true} allowExportSelectedData={true} />
        {
          !isMobile && (
            <Column type="buttons" width="100px">
              <DatagridButton
                icon="trash"
                hint="Anular"
                onClick={async (e) => {
                  consoleService.log('e', e)
                  const data: InventarioInicial = e.row.data
                  dispatch(setSeleccionarDato({ ...data }))
                  onDelete()
                }}
              />
              <DatagridButton
                icon="file"
                hint="Ver"
                onClick={async (e) => {
                  consoleService.log('e', e)
                  const data: InventarioInicial = e.row.data
                  dispatch(setSeleccionarDato({ ...data }))
                  dispatch(setVerRegistro(true))
                  onVer(data)
                }}
              />
              <DatagridButton
                icon="edit"
                hint="Editar"
                onClick={async (e) => {
                  consoleService.log('e', e)
                  const data: InventarioInicial = e.row.data
                  dispatch(setSeleccionarDato({ ...data }))
                  onDBEdit()
                }}
              />
            </Column>
          )
        }
        {!isMobile && (
          <MasterDetail
            enabled={true}
            render={(params) => (
              <>
                <DetalleInventarioIinicial {...params} />{' '}
              </>
            )}
          />
        )}
        {isMobile && (
          <Column
            caption="Tareas"
            dataField="codigo"
            cellRender={actionSheetCommandsMobile}
            width="50px"
            allowFiltering={false}
          ></Column>
        )}
        <Column dataField="anio" caption="Año" width="100px" />
        <Column dataField="numero" caption="#" width="100px" />
        <Column dataField="fecha" caption="Fecha" width="100px" />
        <Column dataField="observaciones" caption="Observaciones" width="300px" />
        <Column dataField="usuarioNombre" caption="Usuario" width="200px" />
        <Column dataField="estadoDescripcion" caption="Estado" width="100px" />
      </DataGrid>
    </>
  )
}

export default React.memo(ResultadosBusqueda)