import React from 'react'
import { isMobile } from 'react-device-detect'
import {
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
} from '@coreui/react-pro'
import Buscar from '../buscar'
import { TabTypes } from '../../../../../ventas/types/enums'
import Nuevo from '../nuevo'
import { Aplicacion } from '../../../../store/types'
import {
  useActiveTabChange,
  useHanldeOnTabClosing,
} from '../../../../../../hooks/useNavigationTabControls'
import { TabsState } from '../../../../../../store/genericTabTypes'
import TabNavTitle from '../../../../../componentes/tituloNavTab'

interface ITabComprasProps extends React.PropsWithChildren {
  tabsState: TabsState<any>
  // eslint-disable-next-line no-unused-vars
  onTabChanged: (tabKey: string) => void
  tipoAplicacion: Aplicacion
  // eslint-disable-next-line no-unused-vars
  ontabClosing: (tabKey: string) => void
}

const TabControl: React.FunctionComponent<ITabComprasProps> = (props) => {
  const { onTabChanged, ontabClosing, tipoAplicacion } = props
  const tabs = props.tabsState.tabs
  const onActiveTabChange = useActiveTabChange(tabs, onTabChanged)
  const hanldeOnTabClosing = useHanldeOnTabClosing(tabs, ontabClosing)

  return (
    <div
      className="container-fluid"
      style={isMobile ? { paddingTop: '10px' } : undefined}
    >
      <CNav variant="tabs" role="tablist">
        {Object.keys(tabs).map((key, index) => {
          const c = tabs[key]
          return (
            <CNavItem key={`${c.type}${c.tabKey}`}>
              <CNavLink
                active={key === props.tabsState.current}
                onClick={() => onActiveTabChange(index)}
              >
                <TabNavTitle
                  tab={c}
                  tabIndex={index}
                  ontabClosing={hanldeOnTabClosing}
                  current={key === props.tabsState.current}
                />
              </CNavLink>
            </CNavItem>
          )
        })}
      </CNav>
      <CTabContent style={{ overflowY: 'unset' }}>
        {Object.keys(tabs).map((key) => {
          const c = tabs[key]
          return (
            <CTabPane
              key={`${c.type}${c.tabKey}content`}
              visible={key === props.tabsState.current}
            >
              {c.type === TabTypes.busqueda && (
                <div key="serarchComponent" id="mainContent">
                  {tipoAplicacion === Aplicacion.proyectosCC && (
                    <Buscar tab={c} tabId={c.tabKey} />
                  )}
                </div>
              )}
              {c.type === TabTypes.documento && (
                <div key="newComponent" id="mainContent">
                  {tipoAplicacion === Aplicacion.proyectosCC && (
                    <Nuevo tab={c} tabId={c.tabKey} />
                  )}
                </div>
              )}
            </CTabPane>
          )
        })}
      </CTabContent>
    </div>
  )
}
export default React.memo(TabControl)
