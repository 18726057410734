import { RequestHelper } from '../../../../../helpers/requestHelper'

export const zonasServices = {
  getZones,
  setZona,
  deleteZona,
}

async function getZones(zona: any): Promise<any> {
  try {
    const data: any = {
      codigo: zona.codigo,
      zona: zona.zona,
    }
    const res = await RequestHelper.get<any>(
      'clientes',
      'zonas/getAll',
      '',
      data,
    )
    return res
  } catch (error) {
    return error
  }
}
async function setZona(objeto: any): Promise<any> {
  try {
    const obj = {
      nombre: objeto['nombre'],
      codigo: objeto['codigo'],
    }
    const data = await RequestHelper.postUrlEncodedAll<any>(
      'clientes',
      'zonas/save',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}

async function deleteZona(performanceCode: number): Promise<any> {
  try {
    const obj = {
      codigo: performanceCode,
    }
    const data = await RequestHelper.deleteRequestAll<any>(
      'clientes',
      'zonas/delete',
      '',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}
