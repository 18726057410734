import React, { useEffect } from 'react'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput'
import { ProyectosCCList } from '../../types/types'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import { clearButtonClick, openTab } from '../../store/tabsReducer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/store'
import {
  changeLoaderProjectCC,
  setCurrentFunction,
} from '../../store/generalReducer'
import { initDatosEdicion } from '../../store/editDataReduce'
import Dialog from '../../../../../../views/componentes/librerias/bootstrap-dialog'
import { defaultDataStore } from '../..'
import { v4 as uuidv4 } from 'uuid'
import { Button, DataGrid, DateBox, TextBox } from 'devextreme-react'
import { CButton, CCol, CRow } from '@coreui/react-pro'
import LocalesLookUp from '../../../../../componentes/localesLookUp'
import { Column, Export, Pager, Paging } from 'devextreme-react/data-grid'
import { getAllowedPageSizes } from '../../../../../../helpers/Helper'
import { TabState } from '../../../../../../store/genericTabTypes'

interface INewProps extends React.PropsWithChildren {
  tab: TabState<ProyectosCCList>
  tabId: string
}

const Nuevo: React.FunctionComponent<INewProps> = (props) => {
  const data = []
  const dataGridKardexSerie = React.useRef<any>()
  const { tab, tabId } = props
  const dispatch = useDispatch()

  const currentAction = useSelector(
    (state: RootState) => state.inventarios.proyectos.general.currentFunction,
  )
  const tabs = useSelector((state: RootState) => {
    return state.inventarios.proyectos.tabs
  })

  const [confirmarNuevo, setconfirmarNuevo] = React.useState<boolean>(false)
  const dialogRef = React.useRef<any>(null)

  const playLoader = React.useCallback(
    (message = 'Cargando...') => {
      dispatch(changeLoaderProjectCC({ show: true, mensaje: message }))
    },
    [dispatch],
  )

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoaderProjectCC({ show: false, mensaje: '' }))
  }, [dispatch])

  const onConfirmarNuevo = React.useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea crear un nuevo registro?`,
      actions: [
        Dialog.Action(
          <span>
            <u>P</u>estaña Actual
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-success',
          'p',
        ),
        Dialog.Action(
          <span>
            <u>N</u>ueva Pestaña
          </span>,
          (dialog) => {
            dialog.hide()
            const id = uuidv4()
            const dataInitialDefault = {
              ...defaultDataStore,
            }
            dispatch(
              initDatosEdicion({
                key: id,
                data: dataInitialDefault,
              }),
            )
            dispatch(openTab({ key: id }))
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setconfirmarNuevo(false)
    return
  }, [dispatch])

  React.useEffect(() => {
    if (confirmarNuevo) {
      onConfirmarNuevo()
    }
  }, [confirmarNuevo, onConfirmarNuevo])

  const onHandleSave = React.useCallback(async () => {}, [])

  const onHandleUndo = React.useCallback(() => {}, [])

  const handleButtonClick = React.useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.save:
          if (tabs.current === tabId) onHandleSave()
          break
        case ButtonTypes.new:
          if (tabs.current === tabId) setconfirmarNuevo(true)
          break
        case ButtonTypes.undo:
          if (tabs.current === tabId) onHandleUndo()
          break
        default:
          break
      }
      dispatch(setCurrentFunction(''))
      dispatch(clearButtonClick(tabId))
    },
    [dispatch, onHandleSave, onHandleUndo, tabId, tabs],
  )

  useEffect(() => {
    if (currentAction !== '') handleButtonClick(currentAction)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAction])

  return (
    <>
      <Dialog ref={dialogRef} />
      <RowContainer>
        <CustomCol xs="12" md="2">
          <Labeled label="TRANSFERENCIA NRO:">
            <TextBox
              value={null}
              onValueChanged={() => []}
              //onEnterKey={onEnterKeyHandle}
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="2">
          <Labeled label="Fecha:">
            <DateBox
              displayFormat="dd/MM/yyyy"
              //value={fechaDeposito}
              onValueChanged={() => {}}
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="7">
          <Labeled label="Descripción:">
            <TextBox value={''} onValueChanged={() => {}} />
          </Labeled>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="4">
          <Labeled label="Origen:">
            <CRow>
              <CCol xs="10" md="10">
                <LocalesLookUp
                  onChanged={() => {}}
                  onChangedOptions={() => {}}
                  selected={null}
                />
              </CCol>
              <CCol xs="2" md="2">
                <Button
                  style={{ marginLeft: '-20px' }}
                  id="btnAdd"
                  className="me-1"
                  icon="add"
                  stylingMode="contained"
                  type="default"
                  //onClick={() => setShowModalVenta(true)}
                />
              </CCol>
            </CRow>
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="4">
          <Labeled label="Destino:">
            <CRow>
              <CCol xs="10" md="10">
                <LocalesLookUp
                  onChanged={() => {}}
                  onChangedOptions={() => {}}
                  selected={null}
                />
              </CCol>
              <CCol xs="2" md="2">
                <Button
                  style={{ marginLeft: '-20px' }}
                  id="btnAdd"
                  className="me-1"
                  icon="add"
                  stylingMode="contained"
                  type="default"
                  //onClick={() => setShowModalVenta(true)}
                />
              </CCol>
            </CRow>
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="4">
          <Labeled label="Responsable:">
            <CRow>
              <CCol xs="10" md="10">
                <TextBox
                  value={null}
                  onValueChanged={() => []}
                  //onEnterKey={onEnterKeyHandle}
                />
              </CCol>
              <CCol xs="2" md="2">
                <Button
                  style={{ marginLeft: '-20px' }}
                  id="btnAdd"
                  className="me-1"
                  icon="add"
                  stylingMode="contained"
                  type="default"
                  //onClick={() => setShowModalVenta(true)}
                />
              </CCol>
            </CRow>
          </Labeled>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="3">
          <Labeled label="Código:">
            <div
              style={{
                display: 'flex',
                gap: '10px',
                alignItems: 'center',
              }}
            >
              <TextBox
                width={'100%'}
                value={null}
                onValueChanged={() => []}
                //onEnterKey={onEnterKeyHandle}
              />
              <Button
                id="btnAdd"
                className="me-1"
                icon="add"
                stylingMode="contained"
                type="default"
                //onClick={() => setShowModalVenta(true)}
              />
            </div>
          </Labeled>
        </CustomCol>
        <CCol xs="12" md="5" style={{ marginTop: 'auto' }}>
          <TextBox
            value={null}
            onValueChanged={() => []}
            //onEnterKey={onEnterKeyHandle}
          />
        </CCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="1">
          <Labeled label="Existencia:">
            <TextBox
              value={null}
              onValueChanged={() => []}
              //onEnterKey={onEnterKeyHandle}
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="1">
          <Labeled label="Cantidad:">
            <TextBox
              value={null}
              onValueChanged={() => []}
              //onEnterKey={onEnterKeyHandle}
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="1">
          <Labeled label="Unidad:">
            <TextBox
              value={null}
              onValueChanged={() => []}
              //onEnterKey={onEnterKeyHandle}
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="1">
          <Labeled label="Referencia:">
            <TextBox
              value={null}
              onValueChanged={() => []}
              //onEnterKey={onEnterKeyHandle}
            />
          </Labeled>
        </CustomCol>
        <CCol xs="12" md="2" style={{ marginTop: 'auto' }}>
          <Button
            id="btnAdd"
            className="me-1"
            icon="add"
            stylingMode="contained"
            type="default"
            //onClick={() => setShowModalVenta(true)}
          />
          <Button
            id="btnAdd"
            className="me-1"
            icon="minus"
            stylingMode="contained"
            type="default"
            //onClick={() => setShowModalVenta(true)}
          />
        </CCol>
        <CCol xs="12" md="4" style={{ marginTop: 'auto' }}>
          <div>
            <CButton
              id="btnConfirmar"
              color="secondary"
              className="m-1"
              size="sm"
              onClick={() => {}}
            >
              {'Transferir Todo'}
            </CButton>
            <CButton
              id="btnConfirmar"
              color="secondary"
              className="m-1"
              size="sm"
              onClick={() => {}}
            >
              {'Cargar Origen Destino'}
            </CButton>
          </div>
        </CCol>
      </RowContainer>
      <RowContainer>
        <DataGrid
          ref={dataGridKardexSerie}
          selection={{ mode: 'single' }}
          hoverStateEnabled={true}
          dataSource={data}
          showBorders={true}
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          //onSelectionChanged={onSelectedItemChanged}
          //onCellDblClick={onOpenItemToEdit}
          keyExpr="itemCodigo"
          key="gridListItems"
        >
          <Export enabled={true} allowExportSelectedData={true} />
          <Paging defaultPageSize={20} />
          <Pager
            visible={data.length > 0 ? true : false}
            allowedPageSizes={getAllowedPageSizes(data)}
            displayMode={'full'}
            showPageSizeSelector={true}
            showInfo={true}
            showNavigationButtons={true}
          />
          <Column dataField="" caption="Barras" width="20%" />
          <Column dataField="" caption="Descripción" width="20%" />
          <Column dataField="" caption="Unidad" width="20%" />
          <Column dataField="" caption="Cantidad" width="20%" />
          <Column dataField="" caption="Referencia" width="20%" />
          <Column dataField="" caption="PVP" width="10%" />
          <Column dataField="" caption="PVD" width="10%" />
        </DataGrid>
      </RowContainer>
    </>
  )
}

export default React.memo(Nuevo)
