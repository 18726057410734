/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CTooltip,
} from '@coreui/react-pro'
import { useSelector } from 'react-redux'
import Labeled from '../../../views/componentes/labeledInput/labeledInput'
import Modalform from '../../../views/componentes/modalform'
import LocalesLookUp from '../localesLookUp'
import CodificacionLookup, { Codificacion } from '../lookUpCodificacion'
import PlantillaBarrasLookup from '../lookupPlantilasBarras'
import {
  TOptionGenerico,
  TOptionGenericoString,
} from '../../clientes/pages/clientes/types/types'
import { LocalOption } from '../localesLookUp/store/type'
import Barcode from '../../../views/componentes/barcode/barcode'
import { RootState } from '../../../store/store'
import { ReportWithLocalDataModal } from '../../../views/componentes/xtrareports/ReportWithLocalDataModal'

export enum Templates {
  P2X2inchX1 = 'StaticBarCode2X2inchX1',
  P4X15inchX2 = 'StaticBarCode4X15inchX2',
  P4X1inchX3 = 'StaticBarCode4X1inchX3',
  P2X4inchX1 = 'StaticBarCode2X4inchX1',
  PA4_45X5inchX1 = 'StaticBarCodeA445X5inchX1',
  P44X33inchX1 = 'StaticBarCode44X33inchX1',
  P58X12cmX1 = 'StaticBarCode58X12cmX1',
  P55X28cmX1 = 'StaticBarCode55X28cmX1',
  P58X28cmX1 = 'StaticBarCode58X28cmX1',
  P45X1cmX1 = 'StaticBarCode45X1cmX1',
  P51X25cmX1 = 'StaticBarCode51X25cmX1',
  P15X1cmX1 = 'StaticBarCode15X1cmX1',
  P5X25cmX1 = 'StaticBarCode5X25cmX1',
  P5X25cm2PreciosX1 = 'StaticBarCode5X25cm2PX1',
  P5X25cmC = 'StaticBarCode5X25cmC',
  P5X25cmPromosX1 = 'StaticBarCode5X25cmPromosX1',
  P76X15cmJoyas = 'StaticBarCode76X15cmJoyas',
}

export const templatesBarcode: Templates[] = [
  Templates.P2X2inchX1,
  Templates.P4X15inchX2,
  Templates.P4X1inchX3,
  Templates.P2X4inchX1,
  Templates.PA4_45X5inchX1,
  Templates.P44X33inchX1,
  Templates.P58X12cmX1,
  Templates.P55X28cmX1,
  Templates.P58X28cmX1,
  Templates.P45X1cmX1,
  Templates.P51X25cmX1,
  Templates.P15X1cmX1,
  Templates.P5X25cmX1,
  Templates.P5X25cm2PreciosX1,
  Templates.P5X25cmC,
  Templates.P5X25cmPromosX1,
  Templates.P76X15cmJoyas,
]

type ModalEditPriceProps = {
  show: boolean
  onClose: () => void
  storeCode: number
  barCode: string
  pvpIva: number
  pvdIva: number
  descripcion: string
}

const ModalCodigoBarras = (props: ModalEditPriceProps) => {
  const numTemplate = useSelector(
    (state: RootState) => state.global.session.empresa.plantillaBarras,
  )
  const [codification, setCodification] = useState<TOptionGenericoString>(
    Codificacion[0],
  )
  const [templateBarcode, setTemplateBarcode] = useState<TOptionGenerico>({
    label: '',
    value: numTemplate,
  })
  const [store, setStore] = useState<LocalOption | { codigo: number }>({
    codigo: props.storeCode,
  })
  const [printBarcode, serPrintBarcode] = useState<boolean>(false)
  const [templatePrint] = useState<string>(templatesBarcode[numTemplate])

  const closeModal = React.useCallback(() => {
    props.onClose()
  }, [props])

  useEffect(() => {
    const toPrintDiv = document.getElementById('barcode-to-print')
    toPrintDiv.addEventListener('click', function () {
      serPrintBarcode(true)
    })
  })

  const renderBody = () => {
    return (
      <>
        <CCard key="cardCodigoBarras">
          <CCardHeader>
            <Labeled label="Codificación:">
              <CodificacionLookup
                selected={codification}
                onChanged={(codification) => setCodification(codification)}
                disabled={true}
              />
            </Labeled>
            <Labeled label="Plantilla:">
              <PlantillaBarrasLookup
                selected={templateBarcode}
                onChanged={(template) => setTemplateBarcode(template)}
                disabled={true}
              />
            </Labeled>
            <Labeled label="Local:">
              <LocalesLookUp
                selected={store}
                onChanged={(store) => setStore(store)}
                onChangedOptions={() => {}}
                message="Todos"
              />
            </Labeled>
          </CCardHeader>
          <CCardBody>
            <CTooltip
              key={'tool-more-auth-side'}
              placement="right"
              content={'Imprimir código de barras.'}
            >
              <div id="barcode-to-print" style={{ cursor: 'pointer' }}>
                <Barcode text={props.barCode} />
              </div>
            </CTooltip>
          </CCardBody>
        </CCard>
      </>
    )
  }

  return (
    <>
      {printBarcode && (
        <ReportWithLocalDataModal
          show={printBarcode}
          onClose={() => serPrintBarcode(false)}
          data={[
            {
              barras: props.barCode,
              precio: props.pvpIva,
              precioDis: props.pvdIva,
              descripcion: props.descripcion,
            },
          ]}
          fileName={templatePrint}
          mode={'Viewer'}
          parameters={{ Reporte_Filtro: '' }}
          template={templatePrint}
          key="reportDesignerBarcode"
        />
      )}
      <CRow key="rowMain">
        <CCol key="colMain">
          <Modalform
            name="modalUpdatePrice"
            key="modalUpdatePrice"
            headerTitle={'Código de Barras'}
            childrenBody={renderBody()}
            childrenFooter={<></>}
            closeOnBackdrop={false}
            show={props.show}
            onClose={closeModal}
            size="sm"
          />
        </CCol>
      </CRow>
    </>
  )
}

export default React.memo(ModalCodigoBarras)
