import { DateUtils, formatoFechasApi } from "../helpers/dateUtils";
import { RequestHelper } from "../helpers/requestHelper";
import { consoleService } from "./console.service";
import { parseApiItemVenta, parseApiItemsLocalSimple } from "./helper/itemsHelper";
import { SesionService } from "./sesion.service";
import { FiltroItemGeneric } from "./types/types";

export const ItemsService = { getItemBaseInfo }

async function getItemBaseInfo(filtro: FiltroItemGeneric): Promise<undefined> {
  const sesion = SesionService.getCurrentSesion();
  const query: any = { local: sesion.local.codigo }
  if (filtro?.codigo) { query.codigo = filtro.codigo }
  if (filtro?.barra) { query.barras = filtro.barra }
  let item = null;
  let resApi: any = null;
  if (filtro?.codigo) {
    resApi = await RequestHelper.get<any>('inventario', 'items', '/get', query);
    if (resApi) {
      item = parseApiItemVenta(resApi);
    }
  } else if (filtro?.barra) {
    query.fecha = DateUtils.getCurrentDateAsString(formatoFechasApi);
    resApi = await RequestHelper.get<any>('inventario', 'items', '/searchItemsLocalSimple', query);
    if (resApi) {
      item = parseApiItemsLocalSimple(resApi);
    }
  }
  consoleService.log("item end: ", item)
  return item
}

