/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Dialog from '../../../../../views/componentes/librerias/bootstrap-dialog'
import BlockUi from '../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../views/componentes/loadingindicator/loadingindicator'
import { TabState } from '../../../../../store/genericTabTypes'
import {
  CustomButtons,
  DocumentInfo,
  ToastTypes,
} from '../../../../../store/types'
import { RootState } from '../../../../../store/store'
import {
  ActividadEconomica,
  ActividadesEconomicasDatosEdicion,
  TActividadesEconomicasData,
} from '../store/types'
import { ButtonTypes } from '../../../../../views/componentes/globalMenu/types'
import { addToast } from '../../../../../store/toasterReducer'
import { sidebarService } from '../../../../../services/sidebar.service'
import { CAlert, CCard, CCardBody, CCol, CRow } from '@coreui/react-pro'
import { PopupContent } from '../../../../../views/componentes/popupContent'
import { ValidationGroup, ValidationSummary, Validator } from 'devextreme-react'
import { RequiredRule, StringLengthRule } from 'devextreme-react/data-grid'
import {
  clearButtonClick,
  setButtons,
  setCurrentExecutingAction,
  setNameTab,
} from '../store/tabsReducer'
import { StatesEdition } from '../../../../../store/enums'
import { setDatosEdicion } from '../store/editDataReducer'
import InputTextDE from '../../../../../views/componentes/inputText-DE/inputText-DE'
import { ELocalButtons } from '../../../configuracion/local/mantenimiento/mantenimiento'
import PaisesLookUp from '../../../../componentes/paisesLookUp'
import { ActividadesEconomicasService } from '../services/actividadesEconomicas.services'
import { PaisOption } from '../../../../componentes/paisesLookUp/store/type'
import { ECiudadesButtons } from '../../ciudades/mantenimiento/mantenimiento'

interface IMActividadesEconomicasProps extends React.PropsWithChildren {
  info: DocumentInfo<TActividadesEconomicasData>
  tabId: string
  tab: TabState<TActividadesEconomicasData>
}

const allowExtention = ['.jpg', '.jpeg']

export type TAtributos = {
  valor: string
  atributo: string
  idAtributo: number
  idAtributoValor: number
  nueva: boolean
}

const MActividadEconomica: React.FunctionComponent<
  IMActividadesEconomicasProps
> = (props) => {
  const { info, tabId, tab } = props

  const dialogRef = React.useRef<any>(null)
  const validationGroupRef = React.useRef<any>()

  const tabs = useSelector(
    (state: RootState) =>
      state.administracion.catalagos.actividadesEconomicas.tabs,
  )

  const empresaState = useSelector(
    (state: RootState) => state.global.session?.empresa,
  )

  const usuarioState = useSelector(
    (state: RootState) => state.global.session?.usuario,
  )

  const ingresoState = useSelector(
    (state: RootState) => state.global.session?.ingreso,
  )

  const dpPaisRedux = useSelector(
    (state: RootState) => state.global.sidebar.configLocalEmpresa.dpPais,
  )

  const dataEdicion = useSelector(
    (state: RootState) =>
      state.administracion.catalagos.actividadesEconomicas.editData[tabId],
  )

  const loading = useSelector((state: RootState) => {
    return state.administracion.catalagos.actividadesEconomicas.editData[tabId]
      .loading
  })

  const empresaPais = useSelector(
    (state: RootState) => state.global.session.empresa.codigoPais,
  )

  const dispatch = useDispatch()
  const sessionStatus = useSelector((state: RootState) => state.global.session)

  const [showErrorPopup, setShowErrorPopup] = React.useState<boolean>(false)
  const [showErrorMessages, setShowErrorMessages] = React.useState<Array<any>>(
    [],
  )
  const [loaderActividadEconomica, setLoaderActividadEconomica] =
    React.useState<{
      show: boolean
      message: string
    }>({ show: false, message: '' })
  const [datosEdicion2, setDatosEdicion2] = React.useState<any>([])

  const setToast = React.useCallback(
    (texto, type: ToastTypes) => {
      let time: number = 3000
      if (texto.length < 50) {
        time = 5000
      } else if (texto.length > 50) {
        time = 9000
      }
      dispatch(
        addToast({
          id: '',
          autoHide: time,
          title: 'Acatha',
          content: texto,
          fade: true,
          type: type,
        }),
      )
    },
    [dispatch],
  )

  const parseSesionActividadEconomica = React.useCallback(
    (apiResult: any): ActividadEconomica => {
      return {
        codigo: apiResult?.codigo ?? 0,
        descripcion: apiResult?.descripcion ?? '',
      }
    },
    [],
  )

  const parseApiActividadEconomicaData = React.useCallback(
    async (response: any): Promise<ActividadesEconomicasDatosEdicion> => {
      return {
        edition: false,
        codigo: response.codigo ?? 0,
        descripcion: response.descripcion ?? '',
        codigoTributario: response.codigoTributario ?? '',
        codigoPais: response.codigoPais ?? '',
        siglas: response.siglas ?? '',
        loader: {
          show: false,
          mensaje: '',
        },
        loading: false,
        tieneError: false,
        mensajeError: '',
      }
    },
    [],
  )

  const modoNuevo = React.useCallback(
    async (template: number, limpiar: boolean = false) => {
      if (loading === false && !limpiar) {
        return
      }
      const data = { ...defaultEditionActividadesEconomicas }
      dispatch(
        setDatosEdicion({
          key: tabId,
          data: { ...data },
        }),
      )
    },
    [dispatch, tabId],
  )

  const modoEdicion = React.useCallback(
    async (
      reload: boolean = false,
      dataEdicion: TActividadesEconomicasData,
    ) => {
      setLoaderActividadEconomica({ show: true, message: 'Cargando...' })

      const dataActividadEconomicaEdition = dataEdicion

      setDatosEdicion2(dataActividadEconomicaEdition)

      try {
        let dataApi: any = []
        let infoParse: ActividadesEconomicasDatosEdicion = {
          edition: false,
          codigo: 0,
          descripcion: '',
          codigoTributario: '',
          codigoPais: {
            codigo: 0,
            nombre: '',
            uaf: '',
          },
          siglas: '',
          loader: {
            show: false,
            mensaje: '',
          },
          loading: false,
          tieneError: false,
          mensajeError: '',
        }

        infoParse.edition = false

        dataApi = dataEdicion

        infoParse = await parseApiActividadEconomicaData(dataEdicion)

        if (reload) {
          dataApi = await ActividadesEconomicasService.getActividadEconomica(
            dataActividadEconomicaEdition?.codigo ?? 0,
          )

          infoParse = await parseApiActividadEconomicaData(dataApi)
        }

        dispatch(
          setDatosEdicion({
            key: tabId,
            data: { ...infoParse },
          }),
        )

        setLoaderActividadEconomica({ show: false, message: '' })
      } catch (error) {
        console.log(error)
        setLoaderActividadEconomica({
          show: false,
          message: JSON.stringify(error),
        })
      }
    },
    [dispatch, parseApiActividadEconomicaData],
  )

  const playLoader = React.useCallback(async () => {
    setLoaderActividadEconomica({ show: true, message: 'Cargando...' })
  }, [])

  const stopLoader = React.useCallback(async () => {
    setLoaderActividadEconomica({ show: false, message: '' })
  }, [])

  const onSetButtonAction = useCallback(
    (tipo: ButtonTypes | undefined) => {
      dispatch(
        setCurrentExecutingAction({
          tabKey: tabId,
          buttonType: tipo,
        }),
      )
    },
    [dispatch, tabId],
  )

  const onErrorConfirm = React.useCallback((messages: any, tittle?: string) => {
    dialogRef.current.show({
      title: (
        <>
          <strong>{'Ácatha'}</strong>
        </>
      ),
      body: (
        <>
          <strong style={{ fontSize: '14px', margin: '0px', padding: '0px' }}>
            {tittle}
          </strong>
          <br />
          <CAlert color="danger">
            <ul className="errorList">
              {messages.map(function (item, id) {
                return <li key={id}>{item}</li>
              })}
            </ul>
          </CAlert>
        </>
      ),
      actions: [
        Dialog.Action(
          <span>
            <u>{'A'}</u>
            {'ceptar'}
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>{'C'}</u>
            {'ancelar'}
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    return
  }, [])

  const onChangeValue = React.useCallback(
    (data, key: string) => {
      dispatch(
        setDatosEdicion({
          key: tabId,
          data: {
            ...dataEdicion,
            [key]: data,
          },
        }),
      )
    },
    [dispatch, dataEdicion, tabId],
  )

  const handleSubmit = React.useCallback(async () => {
    const result = validationGroupRef.current.instance.validate()

    playLoader()

    if (result.isValid === false) {
      setToast('Tiene errores por favor verifíquelos.', ToastTypes.Info)
      setShowErrorPopup(true)
      setShowErrorMessages(result.brokenRules)
      stopLoader()
      onSetButtonAction(undefined)
      return
    }

    setShowErrorPopup(false)
    setShowErrorMessages([])
    onSetButtonAction(ButtonTypes.save)

    const dataActividadEconomica: any = {
      codigo: dataEdicion?.codigo ?? 0,
      estado: 1,
      codigoTributario: dataEdicion.codigoTributario,
      siglas: dataEdicion.siglas,
      codigoPais: String(dataEdicion.codigoPais.codigo),
      descripcion: dataEdicion.descripcion,
    }

    try {
      const data = await ActividadesEconomicasService.saveActividadesEconomicas(dataActividadEconomica)

      stopLoader()

      if (data['error'] === true) {
        setToast(data['message'], ToastTypes.Warning)
        stopLoader()
        onSetButtonAction(undefined)
        dispatch(
          setButtons({
            tabKey: tabId,
            buttons: {
              ...ELocalButtons,
              Guardar: true,
              Imprimir: true,
            },
          }),
        )
        return
      }

      if (data['error'] === false) {
        setToast(data['message'], ToastTypes.Success)
        stopLoader()
        onSetButtonAction(undefined)
      }

      setDatosEdicion2(dataActividadEconomica)
      // Si la actividad es nueva
      if (dataActividadEconomica.codigo === 0) {
        dispatch(
          setButtons({
            tabKey: tabId,
            buttons: {
              ...EActividadEconomicaButtons,
              Deshacer: false,
              Guardar: false,
              Imprimir: true,
            },
          }),
        )
      }

      // Si la actividad se esta editando
      if (dataActividadEconomica.codigo !== 0) {
        dispatch(
          setButtons({
            tabKey: tabId,
            buttons: {
              ...EActividadEconomicaButtons,
              Deshacer: false,
              Guardar: false,
              Imprimir: true,
            },
          }),
        )
      }

      dispatch(
        setNameTab({
          key: tabId,
          nombre: dataActividadEconomica?.descripcion ?? '',
          codigo: data?.auto ?? 0,
        }),
      )
    } catch (error) {
      onSetButtonAction(undefined)
      dispatch(
        setButtons({
          tabKey: tabId,
          buttons: {
            ...ELocalButtons,
            Guardar: true,
            Imprimir: true,
          },
        }),
      )
      stopLoader()
      setToast(error, ToastTypes.Danger)
    }
  }, [
    dataEdicion,
    playLoader,
    setToast,
    stopLoader,
    dispatch,
    onErrorConfirm,
    modoEdicion,
    empresaState,
    ingresoState,
  ])

  const handleButtonClick = React.useCallback(
    async (buttonAction: ButtonTypes) => {
      //let ciudaddselect=null;
      switch (buttonAction) {
        case ButtonTypes.undo:
          // eslint-disable-next-line no-case-declarations
          dispatch(
            setDatosEdicion({
              key: tabId,
              data: { ...datosEdicion2 },
            }),
          )
          break
        case ButtonTypes.save:
          dispatch(
            setCurrentExecutingAction({
              tabKey: tabId,
              buttonType: ButtonTypes.save,
            }),
          )
          if (tabs.current === tabId) handleSubmit()
          break
        default:
          break
      }
      dispatch(clearButtonClick(tabId))
    },
    [tabId, tabs, info, modoNuevo, dispatch, modoEdicion, onSetButtonAction],
  )

  React.useEffect(() => {
    if (tab.editStatus === StatesEdition.new) {
      modoNuevo(tab.info?.codigo ?? 0)
    } else {
      modoEdicion(false, info.info)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
  }, [handleButtonClick, tab.globalButtonClick])

  return (
    <div style={{ padding: '10px', overflowX: 'hidden' }}>
      <div>
        <div>
          <Dialog ref={dialogRef} />
        </div>
        {showErrorPopup && (
          <PopupContent
            show={showErrorPopup}
            title={'Acatha'}
            subTitle={'Por favor revise las siguientes advertencias:'}
            onClose={() => {
              setShowErrorPopup(false)
              setShowErrorMessages([])
            }}
            size={'sm'}
            canClose={true}
            height={'auto'}
          >
            <CAlert color="danger">
              <ul style={{ listStyle: 'none', margin: '0px', padding: '0px' }}>
                {showErrorMessages.map(function (item, id) {
                  return <li key={id}>{item['message']}</li>
                })}
              </ul>
            </CAlert>
          </PopupContent>
        )}

        <div id="configLocal">
          <BlockUi
            tag="div"
            loader={LoadingIndicator}
            blocking={loaderActividadEconomica?.show ?? false}
            message={loaderActividadEconomica?.message ?? ''}
          >
            <fieldset disabled={loaderActividadEconomica.show}>
              <ValidationGroup ref={validationGroupRef} className="">
                <CCard style={{ border: 1 }} className="m-0">
                  <CCardBody>
                    <ValidationSummary className="mb-2" />
                    <CRow>
                      <CCol className=" d-flex " md="12">
                        <div className="dx-field" style={{ width: '100%' }}>
                          <CRow>
                            <CCol lg="12" md="12" sm="12">
                              <div
                                className="dx-field-label"
                                style={{ width: '100%' }}
                              >
                                {'Descripción(*):'}
                              </div>
                            </CCol>
                          </CRow>
                          <CRow>
                            <CCol lg="12" md="12" sm="12">
                              <InputTextDE
                                value={dataEdicion?.descripcion ?? ''}
                                placeholder=""
                                onValueChange={(e) => {
                                  onChangeValue(e, 'descripcion')
                                }}
                                showClearButton={true}
                                width="100%"
                              >
                                <Validator>
                                  <RequiredRule message="- Descripción: Este campo es requerido" />
                                  <StringLengthRule
                                    min="5"
                                    message="- Descripción: Este campo debe tener al menos 5 caracteres"
                                  />
                                  <StringLengthRule
                                    max="100"
                                    message="- Descripción: Este campo no puede tener mas de 100 caracteres"
                                  />
                                </Validator>
                              </InputTextDE>
                            </CCol>
                          </CRow>
                        </div>
                      </CCol>
                    </CRow>

                    <CRow>
                      <CCol className=" d-flex " md="12">
                        <div className="dx-field" style={{ width: '100%' }}>
                          <CRow>
                            <CCol lg="12" md="12" sm="12">
                              <div
                                className="dx-field-label"
                                style={{ width: '100%' }}
                              >
                                {'Código Tributario(*):'}
                              </div>
                            </CCol>
                          </CRow>
                          <CRow>
                            <CCol lg="12" md="12" sm="12">
                              <InputTextDE
                                value={dataEdicion?.codigoTributario ?? ''}
                                placeholder=""
                                onValueChange={(e) => {
                                  onChangeValue(e, 'codigoTributario')
                                }}
                                showClearButton={true}
                                width="100%"
                              >
                                <Validator>
                                  <RequiredRule message="- Código Tributario: Este campo es requerido" />
                                  <StringLengthRule
                                    min="2"
                                    message="- Código Tributario: Este campo debe tener al menos 2 caracteres"
                                  />
                                  <StringLengthRule
                                    max="12"
                                    message="- Código Tributario: Este campo no puede tener mas de 12 caracteres"
                                  />
                                </Validator>
                              </InputTextDE>
                            </CCol>
                          </CRow>
                        </div>
                      </CCol>
                    </CRow>

                    <CRow>
                      <CCol className=" d-flex " md="12">
                        <div className="dx-field" style={{ width: '100%' }}>
                          <CRow>
                            <CCol lg="12" md="12" sm="12">
                              <div
                                className="dx-field-label"
                                style={{ width: '100%' }}
                              >
                                {'Código Pais(*):'}
                              </div>
                            </CCol>
                          </CRow>
                          <CRow>
                            <CCol lg="12" md="12" sm="12">
                              <PaisesLookUp
                                onChanged={(e) => {
                                  onChangeValue(e, 'codigoPais')
                                }}
                                onChangedOptions={() => {}}
                                selected={
                                  dataEdicion?.codigoPais
                                    ? dataEdicion?.codigoPais
                                    : { codigo: empresaPais }
                                }
                              >
                                <Validator>
                                  <RequiredRule
                                    message={
                                      'Código Pais: Este campo es requerido'
                                    }
                                  />
                                </Validator>
                              </PaisesLookUp>
                            </CCol>
                          </CRow>
                        </div>
                      </CCol>
                    </CRow>

                    <CRow>
                      <CCol className=" d-flex " md="12">
                        <div className="dx-field" style={{ width: '100%' }}>
                          <CRow>
                            <CCol lg="12" md="12" sm="12">
                              <div
                                className="dx-field-label"
                                style={{ width: '100%' }}
                              >
                                {'Siglas:'}
                              </div>
                            </CCol>
                          </CRow>
                          <CRow>
                            <CCol lg="12" md="12" sm="12">
                              <InputTextDE
                                value={dataEdicion?.siglas ?? ''}
                                placeholder=""
                                onValueChange={(e) => {
                                  onChangeValue(e, 'siglas')
                                }}
                                showClearButton={true}
                                width="100%"
                              >
                                <Validator>
                                  <StringLengthRule
                                    max="6"
                                    message="- Siglas: Este campo no puede tener mas de 6 caracteres"
                                  />
                                </Validator>
                              </InputTextDE>
                            </CCol>
                          </CRow>
                        </div>
                      </CCol>
                    </CRow>
                  </CCardBody>
                </CCard>
              </ValidationGroup>
            </fieldset>
          </BlockUi>
        </div>
      </div>
    </div>
  )
}

export default React.memo(MActividadEconomica)

export const EActividadEconomicaButtons: CustomButtons = {
  Nuevo: true,
  Guardar: true,
  Buscar: true,
  Deshacer: true,
  Editar: false,
  Imprimir: false,
  Descuento: true,
  Credito: true,
  Extraer: false,
  Eliminar: false,
}

export const defaultEditionActividadesEconomicas: ActividadesEconomicasDatosEdicion =
  {
    edition: false,
    codigo: 0,
    loader: null,
    loading: false,
    descripcion: '',
    tieneError: false,
    mensajeError: '',
  }
