import React, { FunctionComponent, PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react'
import ItemsCountIndicator from '../../../../../../componentes/indicadorNroRegistros'
import DataGrid, { Column, ColumnChooser, Editing, Export, Lookup, Pager, Paging } from 'devextreme-react/data-grid'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import { allowedPageSizes } from '../../../../../../../helpers/Helper'
import { EAplicationsAcatha } from '../../../../../../../store/enumsAplication'
import DataSource from 'devextreme/data/data_source'
import { Workbook } from 'exceljs'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { saveAs } from 'file-saver';
import { consoleService } from '../../../../../../../services/console.service'
import { setCollapsed, setSeleccionarDato } from '../../store/searchReducers'
import { TEstado } from '../../../../../../../store/types'
import { NumberBox } from 'devextreme-react'

interface IResultsProps extends PropsWithChildren {
  onDBClick: () => void
  // eslint-disable-next-line no-unused-vars
  handleOptionChange: (e: any) => void
  data: DataSource
  pageIndex: number
  pageSize: number
  onDelete: () => void
}


const ResultadosBusqueda: FunctionComponent<IResultsProps> = (props) => {

  const { onDBClick, data, handleOptionChange, pageIndex, pageSize, onDelete } =
    props

  const tablaRefPager = useRef<any>()
  const dispatch = useDispatch()
  const totalCountRolsT = useSelector((state: RootState) => state.nomina.rol.decimos.search.nroRegistrosEncontradosT)
  const totalCountRolsF = useSelector((state: RootState) => state.nomina.rol.decimos.search.nroRegistrosEncontradosF)
  const currentApp = useSelector((state: RootState) => state.nomina.rol.decimos.config.currentApp)
  const [isPagin] = useState<boolean>(true)
  const [changes, setChanges] = React.useState<any>()
  const [editRowKey, setEditRowKey] = React.useState<any>()
  const [optPayForm,] = useState<TEstado[] | null>(
    [
      { codigo: 0, descripcion: 'Sin pagar' },
      { codigo: 6, descripcion: 'Efectivo' },
      { codigo: 7, descripcion: 'Cheque' },
      { codigo: 114, descripcion: 'Transferencia Banco Guayaquil' },
      { codigo: 143, descripcion: 'Transferencia CITI BANK' },
    ]
  )
  const onCollapsed = useCallback(async () => {
    if (totalCountRolsT > 5 && currentApp === parseInt(EAplicationsAcatha.DecimoTercero)) {
      dispatch(setCollapsed(false))
    }

    if (totalCountRolsF > 5 && currentApp === parseInt(EAplicationsAcatha.DecimoCuarto)) {
      dispatch(setCollapsed(false))
    }
  }, [dispatch, totalCountRolsT, currentApp, totalCountRolsF])

  const onChangesChange = React.useCallback((changes) => {
    setChanges(changes)
  }, [])

  const onEditRowKeyChange = React.useCallback((editRowKey) => {
    setEditRowKey(editRowKey)
  }, [])

  useEffect(() => {
    onCollapsed()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCountRolsT, totalCountRolsF])

  const onSelectedChanged = useCallback(
    ({ selectedRowsData }) => {
      consoleService.log(selectedRowsData[0], 'seleccionado selectedRowsData[0]')
      if (selectedRowsData[0] && selectedRowsData[0]?.codigo) {
        dispatch(
          setSeleccionarDato({ ...selectedRowsData[0] }),
        )
      }
    },
    [dispatch],
  )

  const onExportExcell = useCallback((e) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('Main sheet')
    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          `Decimo_${currentApp === parseInt(EAplicationsAcatha.DecimoTercero) ? 'Tercero' : 'Cuarto'}.xlsx`,
        )
      })
    })
  }, [currentApp])


  const onTotal = useCallback(() => {
    let totales = 0
    const provider = data?.items() ?? []
    provider.forEach(x => {
      totales += x?.valor ?? 0
    })
    return totales
  }, [data])

  return (
    <>
      <ItemsCountIndicator numberOfItems={data ? currentApp === parseInt(EAplicationsAcatha.DecimoTercero) ? totalCountRolsT : totalCountRolsF : 0} />
      <RowContainer>
        <CustomCol xs="12" md="12">
          <DataGrid
            id="tablaDecimos"
            ref={tablaRefPager}
            dataSource={data ?? []}
            selection={{ mode: 'single' }}
            hoverStateEnabled={true}
            showBorders={true}
            showRowLines={true}
            onSelectionChanged={onSelectedChanged}
            onRowDblClick={onDBClick}
            repaintChangesOnly={true}
            highlightChanges={true}
            keyExpr="uuid"
            key="uuid"
            focusedRowKey="uuid"
            showColumnLines={true}
            onExporting={onExportExcell}
            className={'m-2 p-2'}
            onOptionChanged={handleOptionChange}
            remoteOperations={true}
            loadPanel={{
              enabled: true,
              height: 90,
              indicatorSrc: '',
              shading: false,
              shadingColor: '',
              showIndicator: true,
              showPane: true,
              text: 'Cargando...',
              width: 200,
            }}
          >
            <Editing
              mode="cell"
              refreshMode={'repaint'}
              allowUpdating
              changes={changes}
              onChangesChange={onChangesChange}
              editRowKey={editRowKey}
              onEditRowKeyChange={onEditRowKeyChange}
            />
            <Paging
              pageIndex={pageIndex}
              pageSize={pageSize}
              enabled={isPagin}
            />
            <Pager
              ref={tablaRefPager}
              showPageSizeSelector={true}
              allowedPageSizes={allowedPageSizes}
              showNavigationButtons={true}
              showInfo={true}
              infoText="Page #{0}. Total: {1} ({2} items)"
            />
            <Export enabled={true} allowExportSelectedData={true} />
            <ColumnChooser enabled={true} mode="select" />
            <Column dataField='perDiario' allowEditing={false} caption="Periodo" width="100%" />
            {currentApp === parseInt(EAplicationsAcatha.DecimoTercero) && <Column allowEditing={false} dataField='identificacion' caption="Cedula" width="100%" />}
            <Column allowEditing={false} dataField='nombre' caption="Nombre" width="100%" />
            <Column allowEditing={false} dataField='fecha' caption="Fecha Emision" width="100%" />
            <Column allowEditing={false} dataField='fechaCobro' caption="Fecha Cobro" width="100%" />
            <Column dataField='formaPago' caption="Forma Pago" width="100%" >
              <Lookup dataSource={optPayForm} valueExpr='codigo' displayExpr='descripcion' />
            </Column>
            <Column allowEditing={false} dataField='valor' caption="Valor" width="100%" />
          </DataGrid>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol md="10" className="d-flex justify-content-end">
          <strong> {'Totales:'} </strong>
        </CustomCol>

        <CustomCol md="2">
          <NumberBox
            readOnly
            width="100%"
            value={onTotal()}
            placeholder=""
            className='mb-1'
            format="#,##0.00"
          />
        </CustomCol>
      </RowContainer>
    </>
  )
}

export default React.memo(ResultadosBusqueda)