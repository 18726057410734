import React from 'react';

interface IImageViewProps extends React.PropsWithChildren {
  source: string | null | "";
}

const ImageView: React.FunctionComponent<IImageViewProps> = (props) => {
  const { source } = props;

  const [image, setImage] = React.useState<string | null | ''>(null);

  const imageShow = React.useCallback((contentFile: string | null | "") => {
    setImage(contentFile)
  }, []);

  React.useEffect(() => {
    imageShow(source);
  }, [source, imageShow]);

  return (
    <div style={{ padding: "5px" }} >
      <img
        id="dropzone-image"
        className='m-2'
        style={{
          maxWidth: "175px",
          display: "block",
          height: "auto"
        }}
        src={image ?? ""}
        alt=""
        onError={() => {
          setImage("/images/notFoundImage.png")
        }}
      />
    </div>
  );
}

export default ImageView;
