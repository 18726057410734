import { combineReducers, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EstadosResultadosState } from "./types";
import { balanceComprobacionReducer } from '../balanceComprobacion/store/balanceResultadosReducer';
import { balanceComprobacionCCReducer } from '../balanceComprobacionCC/store/balanceResultadosReducerCC';
import { balanceGeneralReducer } from '../balanceGeneral/store/balanceGeneralReducer';
import { estadosResultadosReducer } from '../estadoResultados/store/estadosResultadosReducer';
import { estadoFlujosEfectivoReducer } from '../estadoFlujoEfectivo/store/estadosResultadosReducer';

export const estadosFinancierosReducer = combineReducers<EstadosResultadosState>({
    balanceComprobacion: balanceComprobacionReducer,
    balanceComprobacionCC: balanceComprobacionCCReducer,
    balanceGeneral: balanceGeneralReducer,
    estadosResultados: estadosResultadosReducer,
    estadoFlujosEfectivo: estadoFlujosEfectivoReducer
})
