import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TAlertNotification, TNotification } from './types'
import { v4 as uuidv4 } from 'uuid'
import { RootState } from './store'

const initialState: TAlertNotification = {
  notifications: [],
}

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: initialState,
  reducers: {
    addNotification(state, action: PayloadAction<TNotification>) {
      const nts = state.notifications.slice()
      const not = action.payload
      const filtrado = nts.findIndex((item: any) => {
        return item.content === action.payload.content
      })
      if (filtrado > -1) {
        nts.splice(filtrado, 1)
        not.id = uuidv4()
        nts.push(not)
      } else {
        not.id = uuidv4()
        nts.push(not)
      }

      state.notifications = nts
    },
    removeNotification(state, action: PayloadAction<string>) {
      const nts = state.notifications.slice()
      const filtrado = nts.findIndex((item: any) => {
        return item.id === action.payload
      })
      if (filtrado > -1) {
        nts.splice(filtrado, 1)
      }

      state.notifications = nts
    },
    addNotifications(state, action: PayloadAction<Array<TNotification>>) {
      const nots = action.payload
      nots.forEach((not) => {
        state.notifications.push(not)
      })
    },
    clearAllNotifications(state) {
      state.notifications = []
    },
  },
})

export const notificationsReducer = notificationsSlice.reducer

export const {
  addNotification,
  clearAllNotifications,
  addNotifications,
  removeNotification,
} = notificationsSlice.actions

export const selectNotifications = (state: RootState) => {
  return state.notificaciones.notifications
}
