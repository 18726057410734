import React, { useCallback, useEffect, useState } from 'react'
import Modalform from '../../../views/componentes/modalform'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import { ButtonTypes } from '../../../views/componentes/globalMenu/types'
import { CCard, CCardBody, CCardHeader } from '@coreui/react-pro'
import { DataGrid, LoadPanel, NumberBox } from 'devextreme-react'
import { GlobalMenu } from '../../../views/componentes/globalMenu/globalMenu'
import { AccionMenu, ToastTypes } from '../../../store/types'
import RowContainer from '../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../views/componentes/colContainer'
import Labeled from '../../../views/componentes/labeledInput/labeledInput'
import { Column, Editing, Pager, Paging } from 'devextreme-react/data-grid'
import { getAllowedPageSizes } from '../../../helpers/Helper'
import ItemsCountIndicator from '../indicadorNroRegistros'
import { asignacionesService } from '../../contabilidad/pages/asignaciones/services/asignaciones.service'
import { addToast } from '../../../store/toasterReducer'
import { changeLoader } from '../../../store/reducers'
import { formasPago } from '../../ventas/pages/cuadres/types/types'
import { RolsPaymentsServices } from '../../nomina/pages/roles/rolesPago/services/rolsPays.services'
import { consoleService } from '../../../services/console.service'

interface IModalVerMarcaciones extends React.PropsWithChildren {
  onCancel: () => void
  show: boolean
  codigoRolPago: number
  fpagooRolPago: number
  totalPagar: number
  onSendFormasPago: (data: Array<formasPago>) => void
}

const menuDefecto = [
  {
    icon: 'fal fa-save',
    id: 'Guardar',
    text: 'Guardar',
    type: ButtonTypes.save,
  },
]

export const ModalFormasPago: React.FC<IModalVerMarcaciones> = (props) => {
  const {
    onCancel,
    show,
    codigoRolPago,
    totalPagar,
    fpagooRolPago,
    onSendFormasPago,
  } = props
  const dispatch = useDispatch()
  const loader = useSelector((state: RootState) => state.global.loader)
  const [resultados, setResultados] = useState<Array<formasPago>>([])
  const [formasPagos, setFormasPagos] = useState<Array<formasPago>>([])
  const [rolPagoCodigo, setRolPagoCodigo] = useState<number>(codigoRolPago)
  const [descuadre, setDescuadre] = useState<number>(0)
  const [total, setTotal] = useState<number>(totalPagar)
  const [changes, setChanges] = React.useState<any>()
  const [editRowKey, setEditRowKey] = React.useState<any>()

  const setToast = React.useCallback(
    (mensaje: string, tipo: ToastTypes) => {
      dispatch(
        addToast({
          content: mensaje,
          title: 'Formas de Pago',
          type: tipo,
        }),
      )
    },
    [dispatch],
  )

  const botones = menuDefecto.map((acc) => {
    const boton: AccionMenu = {
      nombre: acc.id,
      icon: acc.icon, //as any,
      actionType: acc.type,
      main: false,
      modal: false,
      shortcut: null,
    }
    return boton
  })
  const [acciones] = React.useState<Array<AccionMenu>>(botones)

  const playLoader = React.useCallback(() => {
    dispatch(changeLoader({ show: true, mensaje: 'Cargando...' }))
  }, [dispatch])

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoader({ show: false, mensaje: '' }))
  }, [dispatch])

  const onSave = useCallback(() => {
    if (descuadre === 0) {
      const temp: Array<formasPago> = []
      resultados.forEach((x) => {
        if (x?.valor !== 0) {
          temp.push(x)
        }
      })
      onSendFormasPago(temp)
    }
  }, [descuadre, onSendFormasPago, resultados])

  const calculateSalary = useCallback(
    (resDataList: Array<formasPago>) => {
      let saldo = total
      resDataList.forEach((x) => {
        saldo = saldo - x?.valor
      })
      setDescuadre(saldo)
      if (saldo !== 0) {
        setToast('Descuedre: el valor debe ser 0', ToastTypes.Warning)
      }
    },
    [total, setToast],
  )

  const onChangesChange = React.useCallback((changes) => {
    setChanges(changes)
  }, [])

  const onEditRowKeyChange = React.useCallback((editRowKey) => {
    setEditRowKey(editRowKey)
  }, [])

  const loadFormsPay = useCallback(
    async (resDataList: Array<formasPago>) => {
      try {
        const data = await RolsPaymentsServices.getRolsPayments(
          rolPagoCodigo ?? 0,
        )
        consoleService.log(data, 'data forms pay')
        if (data?.auto && data?.error === false) {
          const temp = resDataList.slice(0)
          const provider = data?.auto.slice(0)
          temp.forEach((x, i) => {
            provider.forEach((j) => {
              if (parseInt(j?.subcodigo) === x?.codigo) {
                temp[i].codigo = parseInt(j?.subcodigo)
                temp[i].descripcion = j?.subvalor
                temp[i].valor = j?.valor
              }
            })
          })
          setResultados(temp)
          calculateSalary(temp)
        }
      } catch (error) {
        setToast(error.message, ToastTypes.Danger)
      }
    },
    [setToast, rolPagoCodigo, calculateSalary],
  )

  const onLoadData = useCallback(async () => {
    playLoader()
    try {
      const data = await asignacionesService.obtenerFormasPagoCompra()
      consoleService.log(data, 'data f pago')
      const formPagoArray: Array<formasPago> = []
      if (data !== null && data !== undefined && data.length > 0) {
        data.forEach((x) => {
          if (parseInt(x?.codigo) < 8 || parseInt(x?.codigo) === 114) {
            formPagoArray.push({
              codigo: parseInt(x?.codigo),
              descripcion: x?.descripcion,
              grupo: '',
              valor: 0,
            })
          }
        })
        setResultados(formPagoArray)
        loadFormsPay(formPagoArray)
      }
      if (fpagooRolPago !== 0) {
        setTotal(totalPagar)
        loadFormsPay(formPagoArray)
      } else {
        setTotal(totalPagar)
        setDescuadre(totalPagar)
        calculateSalary(formPagoArray)
      }
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
    stopLoader()
  }, [
    setToast,
    playLoader,
    stopLoader,
    fpagooRolPago,
    loadFormsPay,
    totalPagar,
    calculateSalary,
  ])

  useEffect(() => {
    onLoadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const returnAction = (boton: AccionMenu) => {
    switch (boton.actionType) {
      case ButtonTypes.save:
        onSave()
        break
      default:
        break
    }
  }

  const getButtonDisabled = React.useCallback(
    (button: AccionMenu) => {
      switch (button.actionType) {
        case ButtonTypes.save:
          return descuadre !== 0
          break
        default:
          return false
      }
    },
    [descuadre],
  )

  const setCellValue = useCallback(
    (rowData, value, currentRowData) => {
      if (currentRowData !== null && currentRowData !== undefined && value) {
        const provider = resultados.slice(0)
        const formasPagos = provider.map((x) => {
          if (currentRowData.codigo === x?.codigo) {
            currentRowData.valor = value
            return currentRowData
          } else {
            return x
          }
        })
        calculateSalary(formasPagos)
        setResultados(formasPagos)
      }
    },
    [resultados, calculateSalary],
  )

  const botonesModal = () => {
    return (
      <GlobalMenu
        acciones={acciones}
        onClick={returnAction}
        getButtonDisabled={getButtonDisabled}
      />
    )
  }

  const bodyModal = () => {
    return (
      <>
        <RowContainer>
          <CustomCol xs="12" md="6">
            <Labeled label="Total a Pagar:">
              <NumberBox readOnly value={total ?? null} />
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="6">
            <Labeled label="Descuadre:">
              <NumberBox readOnly value={descuadre ?? null} />
            </Labeled>
          </CustomCol>
        </RowContainer>
        <RowContainer className="mt-2">
          <CustomCol xs="12" md="12">
            <ItemsCountIndicator items={resultados} />
            <DataGrid
              keyExpr="codigo"
              focusedRowKey="codigo"
              id="tabCertificados"
              selection={{ mode: 'single' }}
              dataSource={resultados ?? []}
              showColumnLines={true}
              hoverStateEnabled={true}
              showRowLines={true}
              showBorders={true}
              columnResizingMode="widget"
              focusedRowEnabled={true}
              loadPanel={{
                enabled: true,
                height: 90,
                indicatorSrc: '',
                shading: false,
                shadingColor: '',
                showIndicator: true,
                showPane: true,
                text: 'Cargando...',
                width: 200,
              }}
            >
              <Editing
                mode="cell"
                allowUpdating
                changes={changes}
                onChangesChange={onChangesChange}
                editRowKey={editRowKey}
                onEditRowKeyChange={onEditRowKeyChange}
              />
              <Paging defaultPageSize={10} />
              <Pager
                visible={resultados.length > 0 ? true : false}
                allowedPageSizes={getAllowedPageSizes(resultados)}
                displayMode={'full'}
                showPageSizeSelector={true}
                showInfo={true}
                showNavigationButtons={true}
              />

              <Column
                allowEditing={false}
                dataField="descripcion"
                caption="Forma Pago"
                width="100%"
              />
              <Column
                allowEditing={true}
                setCellValue={setCellValue}
                dataField="valor"
                caption="Valor"
                width="100%"
              />
            </DataGrid>
          </CustomCol>
        </RowContainer>
      </>
    )
  }

  const renderContent = () => {
    return (
      <CCard>
        <CCardHeader>{botonesModal()}</CCardHeader>
        <CCardBody>
          <LoadPanel
            shadingColor="rgba(0,0,0,0.4)"
            position={'center'}
            visible={loader.show}
            showIndicator={true}
            shading={true}
            showPane={true}
            message={loader.mensaje}
            closeOnOutsideClick={false}
          />
          {bodyModal()}
        </CCardBody>
      </CCard>
    )
  }

  return (
    <>
      <Modalform
        name={'formasPago'}
        key={'formasPago'}
        headerTitle={'Formas de Pago'}
        childrenBody={renderContent()}
        closeOnBackdrop={false}
        show={show}
        onClose={() => {
          if (descuadre !== 0) {
            setToast('Existe un descuadre', ToastTypes.Warning)
          } else {
            onCancel()
          }
        }}
        centered={true}
        size="lg"
      />
    </>
  )
}
