import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ConfigLotes } from '../types/types'

const initialState: ConfigLotes = {
  modulo: 0,
}

const configuracionLotes = createSlice({
  name: 'configLotes',
  initialState: initialState,
  reducers: {
    setModulo(state, acion: PayloadAction<number>) {
      state.modulo = acion.payload
    },
  },
})

export const configuracionesLotes = configuracionLotes.reducer
export const { setModulo } = configuracionLotes.actions
