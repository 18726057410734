import { RequestHelper } from '../../../../../helpers/requestHelper'

export const performanceServices = {
  getPerformance,
  setPerformance,
  deletePerformance,
}

async function getPerformance(name: string): Promise<any> {
  try {
    const data: any = {
      nombre: name,
    }
    const res = await RequestHelper.get<any>(
      'clientes',
      'performance/getAll',
      '',
      data,
    )
    return res
  } catch (error) {
    return error
  }
}
async function setPerformance(objeto: any): Promise<any> {
  try {
    const obj = {
      nombre: objeto['nombre'],
      codigo: objeto['codigo'],
      estado: objeto['estado'],
    }
    const data = await RequestHelper.postUrlEncodedAll<any>(
      'clientes',
      'performance/save',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}

async function deletePerformance(performanceCode: number): Promise<any> {
  try {
    const obj = {
      codigo: performanceCode,
    }
    const data = await RequestHelper.deleteRequestAll<any>(
      'clientes',
      'performance/inactive',
      '',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}
