import { RequestHelper } from '../../../../../helpers/requestHelper'
import { ApiResponseAll } from '../../../../../store/types'
import { FiltersCandidateApi, SaveCandidate } from '../types/types'

export const CandidatesServices = {
  getListCandidates,
  saveCandidate,
  deleteCandidate,
}

async function getListCandidates<T>(
  filter: FiltersCandidateApi,
): Promise<ApiResponseAll<T>> {
  try {
    return await RequestHelper.getAll<ApiResponseAll<T>>(
      'nomina/contratos',
      'precontratos/getAll',
      '',
      filter,
    )
  } catch (error) {
    return error
  }
}

async function saveCandidate<T>(
  candidate: SaveCandidate,
): Promise<ApiResponseAll<T>> {
  try {
    return await RequestHelper.postData<ApiResponseAll<T>>(
      'nomina/contratos',
      'precontratos/save',
      candidate,
    )
  } catch (error) {
    return error
  }
}

async function deleteCandidate<T>(
  codeCandidate: number,
): Promise<ApiResponseAll<T>> {
  try {
    return await RequestHelper.deleteRequestAll<ApiResponseAll<T>>(
      'nomina/contratos',
      'precontratos/inactive',
      '',
      { precontrato: codeCandidate },
    )
  } catch (error) {
    return error
  }
}
