import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LoaderInfo, Plan } from '../../../../store/types'
import {
  PersonalizaRecurrenteData,
  RecurrenteData,
} from '../../infoEmergente/contadorDocumentos/type/type'
import { ActionWizard, IMethodPay, PagosSuscripcion } from '../type/type'
import { CuotasPendientes } from '../../../../store/global/payTypes'

export const initialState: PagosSuscripcion = {
  action: ActionWizard.none,
  display: 0,
  metodoPago: IMethodPay.deposito,
  openPopupTerminos: false,
  loader: {
    show: false,
    mensaje: '',
  },
  planes: [],
  planSeleccionado: null,
  codPlanVigente: 0,
  valorPagar: 0,
  codigoContrato: 0,
  contratoEmpresa: null,
  planVigente: null,
  showFacturas: false,
  showCuentasBancarias: false,
  vencidas: [],
  cuentasDepositos: [],
  cuentasMain: '',
  contratoEmpresaPersonaliza: null,
}

const pagosSuscripcionSlice = createSlice({
  name: 'global',
  initialState: initialState,
  reducers: {
    setActionWizard(state, action: PayloadAction<ActionWizard>) {
      state.action = action.payload
    },
    setDisplay(state, action: PayloadAction<number>) {
      state.display = action.payload
    },
    setMethodPay(state, action: PayloadAction<IMethodPay>) {
      state.metodoPago = action.payload
    },
    setOpenPopupTerminos(state, action: PayloadAction<boolean>) {
      state.openPopupTerminos = action.payload
    },
    setLoaderPopup(state, action: PayloadAction<LoaderInfo>) {
      state.loader = action.payload
    },
    setDpPlanes(state, action: PayloadAction<Array<Plan>>) {
      state.planes = action.payload
    },
    setPlanSelec(state, action: PayloadAction<Plan | null>) {
      state.planSeleccionado = action.payload
    },
    setCodPlanVigente(state, action: PayloadAction<number>) {
      state.codPlanVigente = action.payload
    },
    setValorPagar(state, action: PayloadAction<number | string>) {
      state.valorPagar = action.payload
    },
    setCodigoContrato(state, action: PayloadAction<number>) {
      state.codigoContrato = action.payload
    },
    setContratoEmpresa(state, action: PayloadAction<RecurrenteData | null>) {
      state.contratoEmpresa = action.payload
    },
    setPlanVigente(state, action: PayloadAction<Plan | null>) {
      state.planVigente = action.payload
    },
    setShowFacturas(state, action: PayloadAction<boolean>) {
      state.showFacturas = action.payload
    },
    setShowCuentasBancarias(state, action: PayloadAction<boolean>) {
      state.showCuentasBancarias = action.payload
    },
    setVencidas(state, action: PayloadAction<Array<CuotasPendientes> | []>) {
      state.vencidas = action.payload
    },
    setCuentasDepositos(state, action: PayloadAction<Array<any> | []>) {
      state.cuentasDepositos = action.payload
    },
    setCuentasMain(state, action: PayloadAction<string>) {
      state.cuentasMain = action.payload
    },
    setContratoEmpresaPersonaliza(
      state,
      action: PayloadAction<PersonalizaRecurrenteData | null>,
    ) {
      state.contratoEmpresaPersonaliza = action.payload
    },
  },
})
export const {
  setCuentasMain,
  setCuentasDepositos,
  setVencidas,
  setShowFacturas,
  setShowCuentasBancarias,
  setPlanVigente,
  setContratoEmpresa,
  setCodigoContrato,
  setValorPagar,
  setCodPlanVigente,
  setDpPlanes,
  setPlanSelec,
  setLoaderPopup,
  setActionWizard,
  setMethodPay,
  setOpenPopupTerminos,
  setDisplay,
  setContratoEmpresaPersonaliza,
} = pagosSuscripcionSlice.actions
export default pagosSuscripcionSlice.reducer
export const pagosSuscripcionReducer = pagosSuscripcionSlice.reducer
