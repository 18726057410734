import React from "react"
import { PersonalDatosEdicion } from "../../../../types/types"
import { DocumentInfo } from "../../../../../../../../store/types"
import { TabState } from "../../../../../../../../store/genericTabTypes"
import { CNav, CNavItem, CNavLink, CTabContent, CTabPane } from "@coreui/react-pro"
import RolesPago from "./rolesPago"
import Descuentos from "./descuentos"


interface ITabsNormaProps extends React.PropsWithChildren {
  info: DocumentInfo<PersonalDatosEdicion>
  tab: TabState<PersonalDatosEdicion>
  tabId: string
  refForm: any
}

export enum NormasTabs {
  roles = "Roles de pago",
  descuentos = "Descuentos"
}

export const tabsNames = [NormasTabs.roles, NormasTabs.descuentos]

const TabsEditionNormas: React.FunctionComponent<ITabsNormaProps> = (props) => {
  const { tabId, tab, info, refForm } = props


  const [showTab, setShowTab] = React.useState<string>(tabsNames[1])

  return (
    <>
      <CNav variant="tabs" role="tablist" className="mt-4">
        <CNav variant="tabs">
          {tabsNames.map((tab, index) => (
            <CNavItem key={index}>
              <CNavLink
                onClick={() => {
                  setShowTab(tab)
                }}
                active={showTab === tab}
              >
                {tab}
              </CNavLink>
            </CNavItem>
          ))}
        </CNav>
        <CTabContent
          style={{
            overflowY: 'unset',
            width: '100%',
            marginBottom: '10px',
          }}
        >
          <CTabPane
            role="tabpanel"
            aria-labelledby="generales-tab"
            visible={showTab === tabsNames[0]}
          >
            <RolesPago info={info} tab={tab} tabId={tabId} refForm={refForm} />
          </CTabPane>
          <CTabPane
            role="tabpanel"
            aria-labelledby="generales-tab"
            visible={showTab === tabsNames[1]}
          >
            <Descuentos info={info} tab={tab} tabId={tabId} refForm={refForm} />
          </CTabPane>


        </CTabContent>
      </CNav>
    </>
  )
}

export default React.memo(TabsEditionNormas)