import SelectBox from 'devextreme-react/select-box'
import { CargoDepartamento } from './types/types'
import {
  FC,
  PropsWithChildren,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { payrollDepartamentServices } from './services/cargosDepartamentos.services'

interface CargosDepartamentosProps extends PropsWithChildren {
  selected: CargoDepartamento | null
  // eslint-disable-next-line no-unused-vars
  onChanged: (newValue: CargoDepartamento | null) => void
  disabled?: boolean
}

const CargosDepartamentosLookUp: FC<CargosDepartamentosProps> = (props) => {
  const { selected, onChanged, disabled } = props
  const selectRef = useRef<any>()
  const [options, setOptions] = useState<CargoDepartamento[] | []>([])

  const loadDatos = useCallback(async () => {
    const data = await payrollDepartamentServices.getPyarollsList()
    if (!data.error && data.auto) {
      const optionsLookup: CargoDepartamento[] = data.auto
      setOptions(optionsLookup)
      const selectedIndex = optionsLookup.findIndex((option) => {
        return option.codigo === selected?.codigo
      })
      onChanged(optionsLookup[selectedIndex] ?? optionsLookup[0])
    }
  }, [onChanged, selected])

  const getItemDisplayExpr = useCallback((item: any) => {
    return item ? `${item.descripcion}` : ''
  }, [])

  const onLookUpValueChanged = useCallback(
    (evt: any) => {
      if (evt !== undefined) onChanged(evt)
    },
    [onChanged],
  )

  const onSelectBoxFocusIn = useCallback((e) => {
    e.element.querySelector('input.dx-texteditor-input').select()
  }, [])

  const refreshSelected = useCallback(() => {
    if (options && options.length > 0) {
      const selectedIndex = options.findIndex((option: any) => {
        return option.codigo === selected?.codigo
      })
      onChanged(options[selectedIndex] ?? options[0])
    }
  }, [onChanged, options, selected])

  useEffect(() => {
    loadDatos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    refreshSelected()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  return (
    <SelectBox
      id="payrollsDepartamentSelect"
      ref={selectRef}
      items={options ?? []}
      searchEnabled
      displayExpr={getItemDisplayExpr}
      searchExpr={['descripcion']}
      searchTimeout={500}
      value={selected}
      onValueChange={onLookUpValueChanged}
      disabled={disabled}
      onFocusIn={onSelectBoxFocusIn}
    >
      {props.children}
    </SelectBox>
  )
}
export default memo(CargosDepartamentosLookUp)
