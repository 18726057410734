import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ConfigImports } from '../types/types'

const initialState: ConfigImports = {
  aplicacion: 0,
}

const configuracionImports = createSlice({
  name: 'configImports',
  initialState: initialState,
  reducers: {
    setAplicacionModulo(state, acion: PayloadAction<number>) {
      state.aplicacion = acion.payload
    },
  },
})

export const configuracionesImports = configuracionImports.reducer
export const { setAplicacionModulo } = configuracionImports.actions
