import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  TabsStateLibroTransaccional,
  TabStateLibroTransaccional,
} from '../types/types'
import { DatosVenta } from '../../../types/types'
import { StatesEdition, TabTypes } from '../../../../../../../../types/enums'
import { ButtonTypes } from '../../../../../../../../../../views/componentes/globalMenu/types'
import { RootState } from '../../../../../../../../../../store/store'
import { CustomButtons } from '../../../../../../../../types/generics'

const initialState: TabsStateLibroTransaccional<DatosVenta> = {
  current: 'BUSQUEDA',
  tabs: {
    BUSQUEDA: {
      id: 0,
      canClose: false,
      editStatus: StatesEdition.doesNotApply,
      globalButtonClick: ButtonTypes.none,
      tittle: 'Búsqueda',
      type: TabTypes.busqueda,
      imprimir: false,
      tabKey: 'BUSQUEDA',
      buttons: {
        Buscar: true,
        'Generar libros': true,
      },
    },
  },
}

const getNewTabKeyOnClose = (
  state: TabsStateLibroTransaccional<any>,
  keyToClose: string,
) => {
  let newCurrent = ''
  for (const key in state.tabs) {
    if (newCurrent === '') {
      newCurrent = key
    }
    if (keyToClose === key) {
      return newCurrent
    }
  }
  return 'BUSQUEDA'
}

const tabsVentasSlice = createSlice({
  name: 'librosTabs',
  initialState: initialState,
  reducers: {
    closeTab(state, action: PayloadAction<string>) {
      const newCurrent = getNewTabKeyOnClose(state, action.payload)
      state.current = newCurrent
      delete state.tabs[action.payload]
    },

    changeEditStatus(
      state,
      action: PayloadAction<{
        tabKey: string
        estado: StatesEdition
        info?: DatosVenta
        buttons: CustomButtons
      }>,
    ) {
      const { estado, tabKey, info, buttons } = action.payload
      state.tabs[tabKey].editStatus = estado
      state.tabs[tabKey].currentExecutingAction = undefined
      state.tabs[tabKey].buttons = buttons
      if (info) {
        state.tabs[tabKey].info = {
          codigo: 0,
          info: info ?? null,
        }
        ;(state.tabs[tabKey].id = 0),
          (state.tabs[action.payload.tabKey].tittle = `${'Nuevo'}`)
      }
    },
    buttonClick(
      state,
      action: PayloadAction<{ tabKey: string; button: ButtonTypes }>,
    ) {
      state.tabs[action.payload.tabKey].globalButtonClick =
        action.payload.button
    },
    clearButtonClick(state, action: PayloadAction<string>) {
      if (Object.prototype.hasOwnProperty.call(state.tabs, action.payload)) {
        state.tabs[action.payload].globalButtonClick = ButtonTypes.none
      }
    },
    changeCurrentTab(state, action: PayloadAction<string>) {
      if (Object.prototype.hasOwnProperty.call(state.tabs, action.payload)) {
        state.current = action.payload
      }
    },
    setButtons(
      state,
      action: PayloadAction<{ tabKey: string; buttons: CustomButtons }>,
    ) {
      const { buttons, tabKey } = action.payload
      state.tabs[tabKey].buttons = buttons
      state.tabs[tabKey].currentExecutingAction = undefined
    },
    setCurrentExecutingAction(
      state,
      action: PayloadAction<{
        tabKey: string
        buttonType: ButtonTypes | undefined
      }>,
    ) {
      const { buttonType, tabKey } = action.payload
      state.tabs[tabKey].currentExecutingAction = buttonType
    },
  },
})

export const selectTabsAnexoTransac = (state: RootState) => {
  return state.impuestosSV.libroTransaccional.tabs
}
export const {
  closeTab,
  changeCurrentTab,
  changeEditStatus,
  clearButtonClick,
  buttonClick,
  setButtons,
  setCurrentExecutingAction,
} = tabsVentasSlice.actions
export const tabsReducer = tabsVentasSlice.reducer
