import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../../store/store'
import { FETCH_STATUS } from '../../../../../store/types'
import {
  TFormaPagoListado,
  TFiltroBusquedaFormaPagoState
} from './types';
import { TConfigSearchState } from '../../store/types'
import {FormaPagoService} from "../services/formaPago.services";

const initialState: TConfigSearchState<TFormaPagoListado> = {
  filter: {
    codigo: 0,
    descripcion: '',
    estado: 1,
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  resultados: [],
  selectedItem: null,
  error: false,
}

export const fetchFormaPago = createAsyncThunk<
  Array<TFormaPagoListado>,
  TFiltroBusquedaFormaPagoState
>('', async (filtro) => {
  try {
    return await FormaPagoService.getCollection(filtro)
  } catch (error) {
    return Promise.reject(error)
  }
});

const searchConfSlice = createSlice({
  name: 'FormaPagoSearch',
  initialState: initialState,
  reducers: {
    resetState(state) {
      state = {
        ...initialState,
        filter: {
          ...initialState.filter,
        },
      }
    },
    changeFilter(state, action: PayloadAction<TFiltroBusquedaFormaPagoState>) {
      state.filter = action.payload
    },
    setCleanResult(state) {
      state.resultados = []
    },
    setResultados(state, action: PayloadAction<Array<TFormaPagoListado> | []>) {
      state.resultados = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFormaPago.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
        state.error = false
      }
    })
    builder.addCase(fetchFormaPago.fulfilled, (state, { payload }: {payload:any}) => {
      state.resultados = payload.auto
      state.status = FETCH_STATUS.SUCCESS
      state.error = payload.error
      state.mensaje = payload.message
    })
    builder.addCase(fetchFormaPago.rejected, (state, { payload }: {payload:any}) => {
      state.resultados = []
      state.status = FETCH_STATUS.FAILED
      state.error = payload.error
      state.mensaje = payload.message
    })
  }
});

export const selectFiltroBusquedaFormaPago = (state: RootState) => {
  return state.administracion.catalagos.formaPago.search.filter
}

export const selectResultadosBusquedaFormaPago = (state: RootState) => {
  return state.administracion.catalagos.formaPago.search.resultados
}

export const selectEstadoBusquedaFormaPago = (state: RootState) => {
  return state.administracion.catalagos.formaPago.search.status
}

export const { setResultados, resetState, setCleanResult, changeFilter } = searchConfSlice.actions
export const searchReducer = searchConfSlice.reducer
