import { DataGrid } from 'devextreme-react'
import {
  Column,
  Pager,
  Paging,
  Button as DatagridButton,
} from 'devextreme-react/data-grid'
import React from 'react'
import { ToastTypes } from '../../../../../../../../store/types'
import RowContainer from '../../../../../../../../views/componentes/rowContainer/rowContainer'
import ItemsCountIndicator from '../../../../../../../componentes/indicadorNroRegistros'
import { getAllowedPageSizes } from '../../../../../../../../helpers/Helper'
import { AplicacionInventario } from '../../../../../catalogos/aplicaciones/types/types'

interface IResultadoBusquedaProps extends React.PropsWithChildren {
  dataSource: Array<AplicacionInventario>
  // eslint-disable-next-line no-unused-vars
  setToast: (sms: string, type: ToastTypes) => void
  // eslint-disable-next-line no-unused-vars
  onSelectedItem: (item) => void
  // eslint-disable-next-line no-unused-vars
  onSelectItem: (item) => void
}

const ResultadoBusqueda: React.FunctionComponent<IResultadoBusquedaProps> = (
  props,
) => {
  const { onSelectedItem, dataSource, onSelectItem } = props
  const [items, setItems] = React.useState<Array<AplicacionInventario> | []>(
    dataSource,
  )
  const tablaRef = React.useRef<any>()
  const tablaRefPager = React.useRef<any>()

  const selectionChanged = React.useCallback(
    (selectionChanged) => {
      if (items.length > 0) {
        if (selectionChanged['selectedRowsData'].length > 0) {
          const filt: number = items.findIndex((item: any) => {
            return (
              Number(item.codigo) ===
              Number(selectionChanged['selectedRowsData'][0]['codigo'])
            )
          })
          onSelectedItem({
            index: filt,
            row: selectionChanged['selectedRowsData'][0],
            selectedRow: filt,
          })
        }
      }
    },
    [items, onSelectedItem],
  )

  React.useEffect(() => {
    setItems(dataSource)
  }, [dataSource])

  return (
    <>
      <ItemsCountIndicator items={items} />
      <RowContainer className="mt-2 ">
        <DataGrid
          ref={tablaRef}
          selection={{ mode: 'single' }}
          className="border"
          dataSource={items}
          onSelectionChanged={selectionChanged}
          width={'100%'}
          keyExpr=""
        >
          <Paging enabled={true} defaultPageSize={10} />
          <Pager
            ref={tablaRefPager}
            showPageSizeSelector={true}
            allowedPageSizes={getAllowedPageSizes(items)}
            showNavigationButtons={true}
            showInfo={true}
            infoText="Page #{0}. Total: {1} ({2} items)"
          />
          <Column type="buttons" width="7%">
            <DatagridButton
              icon="arrowleft"
              visible={true}
              hint="Seleccionar"
              onClick={(e) => onSelectItem(e.row.data)}
            />
          </Column>
          <Column
            dataField="descripcion"
            caption="Descripción"
            width="50%"
            allowEditing={false}
          />
          <Column
            dataField="perteneceDescripcion"
            caption="Pertenece"
            width="50%"
            allowEditing={false}
          />
        </DataGrid>
      </RowContainer>
    </>
  )
}
export default ResultadoBusqueda
