/* eslint-disable no-unused-vars */
export enum EAplicationsAcatha {
  // CATALOGOS
  Lineas = '38',
  Marcas = '61',
  Grupos = '62',
  Unidades = '60',
  Aplicaciones = '92',
  CaracteristicasGemas = '293',
  FichaTecnica = '355',
  Atributos = '360',

  // MOVIMIENTOS
  ConceptoMovimientos = '74',
  IngresosEgresos = '66',
  Transferencias = '26',
  Importaciones = '138',
  InventarioInicial = '150',
  Combinaciones = '339',
  Transformaciones = '340',
  IngresoBloques = '382',

  // CONTABILIDAD
  MayorGeneral = '17',

  Proveedor = '7',
  Clientes = '24',
  Proveedores = '25',
  Kardex = '3',
  MovimientosIngresosEgresos = '66',
  MovimientosImportaciones = '138',
  Pedidos = '4344',
  Proformas = '4346',
  Items = '2',
  Ventas = '1301',
  Lotes = '379',
  Series = '265',
  // Nómina
  Contratos = '113',
  Horarios = '116',
  Calendarios = '117',
  Organigrama = '52',
  Marcacion = '36',
  RolesPago = '114',
  DecimoTercero = '124',
  DecimoCuarto = '126',
}

export enum EModulosAcatha {
  Proveedor = '7',
  Clientes = '24',
  Proveedores = '25',
  Kardex = '3',
  MovimientosIngresosEgresos = '66',
  MovimientosImportaciones = '138',
  MovimientosInventarioInicial = '150',
  Pedidos = '4344',
  Proformas = '4346',
  Items = '2',
  Ventas = '1301',
  Lotes = '379',
  Series = '265',
  Transferencias = '26',
  IngresoBloque = '382',
  Personal = '50',
}
