import { DateUtils, formatoFechasApi } from '../../../../helpers/dateUtils'
import { RequestHelper } from '../../../../helpers/requestHelper'
import { RequestHelperCountries } from '../../../../helpers/requestHelperCountries'
import { resolveMessages } from '../../../../helpers/resolveMessages'
import { SesionService } from '../../../../services/sesion.service'
import {
  DateRange,
  TiposComprobantesSLV,
  TiposComprobantesSri,
} from '../../../../store/types'
import { TFacturaIngresar } from '../../../ventas/pages/ventas/types/types'
import { getIdentificationClient } from '../../../ventas/services/elSalvador/slv-ventas.service'
import { TS_InvoiceConsumidorFinal } from '../../../ventas/types/facturacion/elSalvador/types'
import { TAutorizacionDocumentoSV } from '../../../ventas/types/types'
import { CompraInfo, FormaPagoModuloCompras } from '../../types/types'
import { SujetoExcluidoListado } from '../pages/sujetoExcluido/types/types'

export const SujetoExcentoService = {
  getFormasPago,
  buscarComprasSujetoExluido,
  ingresarCompra,
  registerDocSujetoExcluido,
  anularDocumento,
  updateCodeGeneration
}
async function getFormasPago() {
  const sesion = SesionService.getCurrentSesion()
  const data: any = {
    movimiento: 'C',
  }
  data.paisCodigo = sesion.empresa?.codigoPais ?? 1
  data.dateKey = DateUtils.getCurrentDateAsString(formatoFechasApi)
  const cacheKey = 'tiposFormasPagoC' + JSON.stringify(data)
  const cached = sessionStorage.getItem(cacheKey)
  if (cached) {
    return JSON.parse(cached)
  } else {
    const apiData = await RequestHelper.get<Array<FormaPagoModuloCompras>>(
      'generales',
      'paymentForms/getCboxFP',
      '',
      data,
    )
    sessionStorage.setItem(cacheKey, JSON.stringify(apiData))

    return apiData
  }
}

async function buscarComprasSujetoExluido(
  range: DateRange | null,
  numero: string,
  identificacion: string,
  tipoDoc: TiposComprobantesSLV | TiposComprobantesSri,
  fpago: string,
  claveAcceso: string,
): Promise<Array<SujetoExcluidoListado>> {
  const queryData: any = {}

  if (range) {
    queryData.desde = range.inicio
    queryData.hasta = range.fin
  }

  if (numero && numero.length > 0) {
    queryData.compraNumero = numero
  }

  if (identificacion && identificacion.length > 0) {
    queryData.proveedorIdentificacion = identificacion
  }

  if (tipoDoc && tipoDoc.length > 0) {
    queryData.compraTipoDocumento = tipoDoc
  }

  if (fpago && fpago.length > 0) {
    queryData.compraFormaPago = fpago
  }

  if (claveAcceso) {
    queryData.claveAcceso = claveAcceso
  }

  let res = await RequestHelper.get<Array<SujetoExcluidoListado>>(
    'compras',
    'compras/getAll',
    '',
    queryData,
  )

  if (res.length > 0) {
    res = parseDataSujeto(res)
  }
  return res
}

async function registerDocSujetoExcluido(
  doc: TS_InvoiceConsumidorFinal,
): Promise<Promise<any>> {
  try {
    const dte: any = {
      ...getIdentificationClient(doc),
    }
    const creada = await RequestHelperCountries.postAll<any>(
      'facturacion-electronica',
      'sujeto-excluido',
      dte,
    )
    return creada
  } catch (err) {
    const detectError = resolveMessages.errorNetwork(err)
    if (detectError?.error === true) {
      return { error: detectError?.error, message: detectError?.message }
    }
    return err
  }
}

async function ingresarCompra(venta: TFacturaIngresar): Promise<Promise<any>> {
  try {
    const creada = await RequestHelper.postAll<any>('compras', 'insert', venta)
    return creada
  } catch (error) {
    return error
  }
}

function parseDataSujeto(apiData: Array<any>): Array<SujetoExcluidoListado> {
  const items = apiData.map((x) => {
    let autorizacionHacienda: TAutorizacionDocumentoSV = null
    if (x?.autorizacionHacienda) {
      autorizacionHacienda = {
        estado: x?.autorizacionHacienda?.estado ?? '',
        version: x?.autorizacionHacienda?.version ?? '',
        ambiente: x?.autorizacionHacienda?.ambiente ?? '',
        codigoMensaje: x?.autorizacionHacienda?.codigoMensaje ?? '',
        versionApp: x?.autorizacionHacienda?.versionApp ?? '',
        clasificacionMensaje:
          x?.autorizacionHacienda?.clasificacionMensaje ?? '',
        selloRecibido: x?.autorizacionHacienda?.selloRecibido ?? '',
        descripcionMensaje: x?.autorizacionHacienda?.descripcionMensaje ?? '',
        fechaProcesamiento: x?.autorizacionHacienda?.fechaProcesamiento ?? '',
        tipoComprobante: x?.autorizacionHacienda?.tipoComprobante ?? '',
        codigoGeneracion: x?.autorizacionHacienda?.codigoGeneracion ?? '',
        claveAcceso: x?.autorizacionHacienda?.claveAcceso ?? '',
        codigo: x?.autorizacionHacienda?.codigo ?? '',
      }
    }

    const item: SujetoExcluidoListado = {
      ice: parseFloat(x.ICE) ?? 0,
      iva: parseFloat(x.IVA) ?? 0,
      identificador: x?.identificador ?? '',
      asientoNumero: x?.asientoNumero !== null ? String(x.asientoNumero) : '',
      autorizacion: String(x?.autorizacion) ?? '',
      autorizacionHacienda: autorizacionHacienda,
      claveAcceso: String(x?.claveAcceso) ?? '',
      relacionado: x?.relacionado,
      claveFecha: String(x?.claveFecha) ?? '',
      retener: Number(x?.retener) ?? 0,
      codigo: parseInt(x?.codigo) ?? 0,
      descuento: parseFloat(x.descuento) ?? 0,
      establecimiento: String(x?.establecimiento) ?? '',
      estadoCodigo: parseInt(x?.estado) ?? 0,
      estadoDescripcion: String(x?.estadoDescripcion) ?? '',
      fecha: String(x?.fecha) ?? '',
      fechaAutorizacion: String(x?.fechaAutorizacion) ?? '',
      fechaHoraEmision: String(x?.fechaHoraEmision) ?? '',
      formaPagoCodigo: parseInt(x?.formaPagoCodigo) ?? 0,
      formaPagoDescripcion: String(x?.formaPagoDescripcion) ?? '',
      formaPagoCodigoTributario: String(x?.formaPagoCodigoTributario) ?? '',
      puntoEmision: String(x?.puntoEmision) ?? '',
      localCodigo: parseInt(x?.localCodigo) ?? 0,
      localNombre: String(x?.localNombre) ?? '',
      numero: String(x?.numero) ?? '',
      observaciones: String(x?.observaciones) ?? '',
      proveedorCodigo: parseInt(x?.proveedorCodigo) ?? 0,
      proveedorDireccion: String(x?.proveedorDireccion) ?? '',
      proveedorEmail: String(x?.proveedorEmail) ?? '',
      proveedorIdentificacion: String(x?.proveedorIdentificacion) ?? '',
      proveedorTipoIdentificacionCodigo:
        parseInt(x?.proveedorTipoIdentificacion) ?? 0,
      proveedorTipoIdentificacionCodigoTributario:
        String(x?.proveedorTipoIdentificacionCodigoTributario) ?? '',
      proveedorNombre: String(x?.proveedorNombre) ?? '',
      proveedorRazonComercial: String(x?.proveedorRazonComercial) ?? '',
      proveedorTelefono: String(x?.proveedorTelefono) ?? '',
      puntoVenta: String(x?.puntoVenta) ?? '',
      puntoVentaCodigo: x?.puntoVentaCodigo ?? -1,
      subtotalIva: parseFloat(x?.subtotal12) ?? 0,
      subtotal0: parseFloat(x?.subtotal0) ?? 0,
      subtotalNoIva: parseFloat(x?.subtotalNoIVA) ?? 0,
      subtotal: parseFloat(x?.subtotal) ?? 0,
      totalExcenta: parseFloat(x?.totalExcenta) ?? 0,
      totalGravada: parseFloat(x?.totalGravada) ?? 0,
      tipoDocumentoDescripcion: String(x?.tipoDocumentoDescripcion) ?? '',
      tipoDocumentoCodigo: String(x?.tipoDocumento) as TiposComprobantesSLV,
      total: parseFloat(x?.total) ?? 0,
      estadoElectronico: String(x?.estadoElectronico) ?? '',
      retenidoIva: parseFloat(x?.retenidoIva) ?? 0,
      retenidoRenta: parseFloat(x?.retenidoRenta) ?? 0,
    }
    return item
  })
  return items
}

async function anularDocumento(info: {
  codigo: number
  tipoDocumento?: string
  relacionado?: number
}) {
  const postData: any = {
    codigo: info.codigo,
    tipoDocumento: info.tipoDocumento,
  }
  if (info?.relacionado) {
    postData.relacionado = info.relacionado
  }
  const result = await RequestHelper.deleteRequest<CompraInfo>(
    'compras',
    'compras/cancel',
    '',
    postData,
  )
  return result
}

async function updateCodeGeneration(auth: {
  reference: number
  codeGeneration: string
}): Promise<Promise<any>> {
  try {
    const res = await RequestHelper.postAll<any>('compras', 'codeGeneration', {
      ...auth,
    })
    return res
  } catch (error) {
    return error
  }
}

// function parseDataCompraToSujeto(apiData: CompraInfo):  SujetoExcluidoListado {
//   const itemsParsear = apiData
//     let autorizacionHacienda: TAutorizacionDocumentoSV = null
//     if (itemsParsear?.autorizacionHacienda) {
//       autorizacionHacienda = {
//         estado: itemsParsear?.autorizacionHacienda?.estado ?? '',
//         version: itemsParsear?.autorizacionHacienda?.version ?? '',
//         ambiente: itemsParsear?.autorizacionHacienda?.ambiente ?? '',
//         codigoMensaje: itemsParsear?.autorizacionHacienda?.codigoMensaje ?? '',
//         versionApp: itemsParsear?.autorizacionHacienda?.versionApp ?? '',
//         clasificacionMensaje:
//           itemsParsear?.autorizacionHacienda?.clasificacionMensaje ?? '',
//         selloRecibido: itemsParsear?.autorizacionHacienda?.selloRecibido ?? '',
//         descripcionMensaje: itemsParsear?.autorizacionHacienda?.descripcionMensaje ?? '',
//         fechaProcesamiento: itemsParsear?.autorizacionHacienda?.fechaProcesamiento ?? '',
//         tipoComprobante: itemsParsear?.autorizacionHacienda?.tipoComprobante ?? '',
//         codigoGeneracion: itemsParsear?.autorizacionHacienda?.codigoGeneracion ?? '',
//         claveAcceso: itemsParsear?.autorizacionHacienda?.claveAcceso ?? '',
//         codigo: itemsParsear?.autorizacionHacienda?.codigo ?? '',
//       }
//     }

//     const item: SujetoExcluidoListado = {
//       ice: parseFloat(itemsParsear?.ICE) ?? 0,
//       iva: parseFloat(itemsParsear?.IVA) ?? 0,
//       identificador: itemsParsear?.identificador ?? '',
//       asientoNumero: itemsParsear?.asientoNumero !== null ? String(itemsParsear.asientoNumero) : '',
//       autorizacion: String(itemsParsear?.autorizacion) ?? '',
//       autorizacionHacienda: autorizacionHacienda,
//       claveAcceso: String(itemsParsear?.claveAcceso) ?? '',
//       claveFecha: String(itemsParsear?.claveFecha) ?? '',
//       retener: Number(itemsParsear?.retener) ?? 0,
//       codigo: parseInt(itemsParsear?.codigo) ?? 0,
//       descuento: parseFloat(itemsParsear.descuento) ?? 0,
//       establecimiento: String(itemsParsear?.establecimiento) ?? '',
//       estadoCodigo: parseInt(itemsParsear?.estado) ?? 0,
//       estadoDescripcion: String(itemsParsear?.estadoDescripcion) ?? '',
//       fecha: String(itemsParsear?.fecha) ?? '',
//       fechaAutorizacion: String(itemsParsear?.fechaAutorizacion) ?? '',
//       fechaHoraEmision: String(itemsParsear?.fechaHoraEmision) ?? '',
//       formaPagoCodigo: parseInt(itemsParsear?.formaPagoCodigo) ?? 0,
//       formaPagoDescripcion: String(itemsParsear?.formaPagoDescripcion) ?? '',
//       formaPagoCodigoTributario: String(itemsParsear?.formaPagoCodigoTributario) ?? '',
//       puntoEmision: String(itemsParsear?.puntoEmision) ?? '',
//       localCodigo: parseInt(itemsParsear?.localCodigo) ?? 0,
//       localNombre: String(itemsParsear?.localNombre) ?? '',
//       numero: String(itemsParsear?.numero) ?? '',
//       observaciones: String(itemsParsear?.observaciones) ?? '',
//       proveedorCodigo: parseInt(itemsParsear?.proveedorCodigo) ?? 0,
//       proveedorDireccion: String(itemsParsear?.proveedorDireccion) ?? '',
//       proveedorEmail: String(itemsParsear?.proveedorEmail) ?? '',
//       proveedorIdentificacion: String(itemsParsear?.proveedorIdentificacion) ?? '',
//       proveedorTipoIdentificacionCodigo:
//         parseInt(itemsParsear?.proveedorTipoIdentificacion) ?? 0,
//       proveedorTipoIdentificacionCodigoTributario:
//         String(itemsParsear?.proveedorTipoIdentificacionCodigoTributario) ?? '',
//       proveedorNombre: String(itemsParsear?.proveedorNombre) ?? '',
//       proveedorRazonComercial: String(itemsParsear?.proveedorRazonComercial) ?? '',
//       proveedorTelefono: String(itemsParsear?.proveedorTelefono) ?? '',
//       puntoVenta: String(itemsParsear?.puntoVenta) ?? '',
//       puntoVentaCodigo: itemsParsear?.puntoVentaCodigo ?? -1,
//       subtotalIva: parseFloat(itemsParsear?.subtotal12) ?? 0,
//       subtotal0: parseFloat(itemsParsear?.subtotal0) ?? 0,
//       subtotalNoIva: parseFloat(itemsParsear?.subtotalNoIVA) ?? 0,
//       subtotal: parseFloat(itemsParsear?.subtotal) ?? 0,
//       totalExcenta: parseFloat(itemsParsear?.totalExcenta) ?? 0,
//       totalGravada: parseFloat(itemsParsear?.totalGravada) ?? 0,
//       tipoDocumentoDescripcion: String(itemsParsear?.tipoDocumentoDescripcion) ?? '',
//       tipoDocumentoCodigo: String(itemsParsear?.tipoDocumento) as TiposComprobantesSLV,
//       total: parseFloat(itemsParsear?.total) ?? 0,
//       estadoElectronico: String(itemsParsear?.estadoElectronico) ?? '',
//       retenidoIva: parseFloat(itemsParsear?.retenidoIva) ?? 0,
//       retenidoRenta: parseFloat(itemsParsear?.retenidoRenta) ?? 0,
//     }
//     return item

// }
