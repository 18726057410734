import React, { Fragment } from 'react';
import {
  CCol,
  CInputGroup,

} from '@coreui/react-pro'
import { GlobalLabel } from '../globalLabel/globalLabel';


type Labelgroup = {
  value?: string,
  label: string,
  name: string,
  labelmd?: string,
  inputxs?: string,
  inputmd?: string,
}

const defaultProps = {
  labelmd: "3",
  inputxs: "12",
  inputmd: "9"
};

const Labelgroup: React.FC<Labelgroup> = ({ labelmd, inputxs, inputmd, label, value, name }) => {
  return (
    <Fragment>
      <  CInputGroup>
        <CCol md={labelmd}>
          <GlobalLabel htmlFor={name} className="col-form-label-md" >{label}</GlobalLabel>
        </CCol>
        <CCol xs={inputxs} md={inputmd} >
          <GlobalLabel className="col-form-label-md" >{value}</GlobalLabel>
        </CCol>
      </CInputGroup>
    </Fragment>
  );
};

Labelgroup.defaultProps = defaultProps
export default Labelgroup;