import React from 'react';
import Button from 'devextreme-react/button';
import ActionSheet from 'devextreme-react/action-sheet';
import { MarcationList } from '../../types/types';

interface IActionSheetCommandsMobileProps extends React.PropsWithChildren {
  marcation: MarcationList
  shoDetail: (marcation: MarcationList) => void,
}

/*
{ text: 'Email', icon: 'email' },
  { text: 'Autorizar', icon: 'email' },
*/

export const ActionSheetCommandsMobile: React.FC<IActionSheetCommandsMobileProps> = (props) => {
  const { shoDetail, marcation } = props;
  const [visible, setVisible] = React.useState<boolean>(false);
  const [commands, setCommands] = React.useState<Array<any>>([]);

  const onButtonClick = () => {
    setVisible(!visible)
  }

  const onItemClick = React.useCallback(async (e) => {
    const { itemData } = e;
    switch (itemData.text) {
      case "Detalle":
        shoDetail(marcation)
        break;

      default:
        break;
    }
  }, [marcation, shoDetail])

  React.useEffect(() => {
    const cmds: Array<any> = [
      { text: 'Detalle', icon: 'file' },
    ];
    setCommands(cmds)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const keyId = `filamarcation${new Date().getMilliseconds()}`
  return (
    <>
      <Button id={keyId} icon='overflow' type='default' onClick={onButtonClick} />
      <ActionSheet
        title="Tareas"
        usePopover={true}
        visible={visible}
        target={'#' + keyId}
        items={commands}
        itemComponent={ActionSheetCommand}
        onItemClick={onItemClick}
        onVisibleChange={(value) => {
          if (value !== visible) setVisible(!visible)
        }}
      />
    </>
  );
};



const ActionSheetCommand: React.FC<any> = (props) => {

  return <Button stylingMode='contained' type='default' text={props.data.text} icon={props.data.icon} />
}