import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EdicionPayload } from '../../../../store/types'
import { GemasDatosEdicion } from '../types/types'
import { CustomDictionary } from '../../../../../../store/types'

const initialState: CustomDictionary<GemasDatosEdicion> = {}

const datosEditSlice = createSlice({
  name: 'gemasDatosEdicion',
  initialState: initialState,
  reducers: {
    deleteEditData(state, action: PayloadAction<string>) {
      delete state[action.payload]
    },
    setDatosEdicion(
      state,
      action: PayloadAction<{
        key: string
        gema: GemasDatosEdicion
      }>,
    ) {
      const { key, gema } = action.payload
      state[key].codigo = gema.codigo
      state[key].descripcion = gema.descripcion
      state[key].pertenece = gema.pertenece
      state[key].estado = gema.estado
      state[key].observaciones = gema.observaciones
      state[key].engaste = gema.engaste
      state[key].corte = gema.corte
      state[key].posicion = gema.posicion
      state[key].guardado = gema.guardado
    },
    initDatosEdicion(
      state,
      action: PayloadAction<EdicionPayload<GemasDatosEdicion>>,
    ) {
      if (!state[action.payload.key]) {
        state[action.payload.key] = action.payload.data
      }
    },
    setCurrentTab(
      state,
      action: PayloadAction<{
        key: string
        tab: string
      }>,
    ) {
      const { key, tab } = action.payload
      state[key].currentTab = tab
    },
  },
})

export const {
  setDatosEdicion,
  initDatosEdicion,
  deleteEditData,
  setCurrentTab,
} = datosEditSlice.actions
export const dataEditReducer = datosEditSlice.reducer
