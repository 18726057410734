import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { IGenericTabProps } from '../../../../store/types'
import { Position, PositionAssigned, PositionXml } from '../../types/types'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput'
import Arbol from '../../../../../contabilidad/pages/planCuentas/components/arbol'
import { useDispatch, useSelector } from 'react-redux'
import { useSetToast } from '../../../../../../hooks/useGlobalHooks'
import { useLoaderPositions } from '../../customHooks'
import { PositionsServices } from '../../services/positions.services'
import { ToastTypes } from '../../../../../../store/types'
import {
  setResultsAssigned,
  setResultsOrganizationChart,
} from '../../store/searchReducer'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import { RootState } from '../../../../../../store/store'
import BlockUi from '../../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../../views/componentes/loadingindicator/loadingindicator'
import { Button, DataGrid } from 'devextreme-react'
import { Column } from 'devextreme-react/data-grid'
import { CCol } from '@coreui/react-pro'
import { isMobile } from 'react-device-detect'

const OrganizationChart: FunctionComponent<IGenericTabProps<Position>> = (
  props,
) => {
  const { tabId } = props
  const dispatch = useDispatch()
  const setToastMessage = useSetToast()
  const { showLoaderTabs } = useLoaderPositions()

  const searchState = useSelector(
    (state: RootState) => state.nomina.cargos.search.organizationChart,
  )
  const tabs = useSelector((state: RootState) => state.nomina.cargos.tabs)
  const loader = useSelector(
    (state: RootState) => state.nomina.cargos.search.organizationChart.loader,
  )

  const dataGridPositions = useRef<any>(null)
  const dataGridAssigned = useRef<any>(null)
  const [selectedOrganizationChart, setSelectedOrganizationChart] =
    useState<number>(null)
  const [selectedPosition, setSelectedPosition] = useState<Position>(null)
  const [indexSelectedPosition, setIndexSelectedPosition] =
    useState<number>(null)
  const [selectedAssigned, setSelectedAssigned] =
    useState<PositionAssigned>(null)
  const [indexSelectedAssigned, setIndexSelectedAssigned] =
    useState<number>(null)

  const clearPositionsAndAssigns = useCallback(() => {
    setSelectedPosition(null)
    setSelectedAssigned(null)
    setIndexSelectedPosition(null)
    setIndexSelectedAssigned(null)
    dispatch(
      setResultsAssigned({
        listAssigned: [],
        listToBeAssigned: [],
      }),
    )
  }, [dispatch])

  const verifyAssignedPositions = useCallback(
    async (data) => {
      if (Number(data?.id)) {
        try {
          setSelectedOrganizationChart(data?.id)
          clearPositionsAndAssigns()
          showLoaderTabs(
            true,
            'ASIGNACION_ROL_ORGANIGRAMA',
            false,
            'Cargando Roles Asignados . . .',
            ButtonTypes.assignPositionOrganizationChart,
          )
          const assignedPositions =
            await PositionsServices.getAssignedPositions<PositionAssigned>(
              data?.id,
            )
          const assigned =
            !assignedPositions.error &&
            assignedPositions.auto &&
            Array.isArray(assignedPositions.auto)
              ? assignedPositions.auto
              : []
          if (assignedPositions.error)
            setToastMessage(
              'Buscar Roles Asignados',
              assignedPositions.message,
              ToastTypes.Warning,
            )

          const positionsToBeAssigned =
            await PositionsServices.getPositionsToBeAssigned<Position>(data?.id)
          const toAssign =
            !positionsToBeAssigned.error &&
            positionsToBeAssigned.auto &&
            Array.isArray(positionsToBeAssigned.auto)
              ? positionsToBeAssigned.auto
              : []
          if (positionsToBeAssigned.error)
            setToastMessage(
              'Buscar Roles Por Asignar',
              positionsToBeAssigned.message,
              ToastTypes.Warning,
            )

          dispatch(
            setResultsAssigned({
              listAssigned: assigned,
              listToBeAssigned: toAssign,
            }),
          )
          showLoaderTabs(false, 'ASIGNACION_ROL_ORGANIGRAMA')
        } catch (error) {
          showLoaderTabs(false, 'ASIGNACION_ROL_ORGANIGRAMA')
          setToastMessage('Buscar Roles Asignados', error, ToastTypes.Danger)
        }
      }
    },
    [clearPositionsAndAssigns, dispatch, setToastMessage, showLoaderTabs],
  )

  const loadOrganizationChart = useCallback(async () => {
    try {
      showLoaderTabs(
        true,
        'ASIGNACION_ROL_ORGANIGRAMA',
        false,
        'Cargando . . .',
        ButtonTypes.assignPositionOrganizationChart,
      )
      const positionsList =
        await PositionsServices.getListPositionsOrganizationChart<PositionXml>(
          0,
        )
      showLoaderTabs(false, 'ASIGNACION_ROL_ORGANIGRAMA')
      if (
        !positionsList.error &&
        positionsList.auto &&
        Array.isArray(positionsList.auto)
      ) {
        dispatch(
          setResultsOrganizationChart({
            list: positionsList.auto,
            isDataLoaded: true,
          }),
        )
      }
      setToastMessage(
        'Buscar Roles Organigrama',
        positionsList.message,
        positionsList.error ? ToastTypes.Warning : ToastTypes.Success,
      )
    } catch (error) {
      showLoaderTabs(false, 'ASIGNACION_ROL_ORGANIGRAMA')
      setToastMessage('Buscar Roles Organigrama', error, ToastTypes.Danger)
    }
  }, [dispatch, setToastMessage, showLoaderTabs])

  const onSelectedPositionChanged = useCallback(
    (position: Position, rowIndex: number) => {
      if (position) {
        setSelectedPosition(position)
        setIndexSelectedPosition(rowIndex)
      }
    },
    [],
  )

  const onSelectedAssignedChanged = useCallback(
    (assigned: PositionAssigned, rowIndex: number) => {
      if (assigned) {
        setSelectedAssigned(assigned)
        setIndexSelectedAssigned(rowIndex)
      }
    },
    [],
  )

  const onHandleSucessfull = useCallback(
    (addAssign: boolean = true) => {
      const listToBeAssigned: Position[] = structuredClone(
        searchState.listToBeAssigned,
      )
      const listAssigned: PositionAssigned[] = structuredClone(
        searchState.listAssigned,
      )

      if (addAssign) {
        listToBeAssigned.splice(indexSelectedPosition, 1)
        listAssigned.push({
          organicoRolCodigo: -1,
          organicoCodigo: selectedOrganizationChart,
          organicoDescripcion: '',
          rolCodigo: selectedPosition.codigo,
          rolDescripcion: selectedPosition.descripcion,
        })
        setSelectedPosition(null)
        setIndexSelectedPosition(null)
      } else {
        listAssigned.splice(indexSelectedAssigned, 1)
        listToBeAssigned.push({
          codigo: selectedAssigned.rolCodigo,
          descripcion: selectedAssigned.rolDescripcion,
          contrato: '',
          estado: 1,
        })
        setSelectedAssigned(null)
        setIndexSelectedAssigned(null)
      }

      dispatch(
        setResultsAssigned({
          listAssigned: listAssigned,
          listToBeAssigned: listToBeAssigned,
        }),
      )
    },
    [
      dispatch,
      indexSelectedAssigned,
      indexSelectedPosition,
      searchState,
      selectedAssigned,
      selectedOrganizationChart,
      selectedPosition,
    ],
  )

  const unAssigPosition = useCallback(async () => {
    try {
      showLoaderTabs(
        true,
        'ASIGNACION_ROL_ORGANIGRAMA',
        false,
        'Eliminando Asignación . . .',
        ButtonTypes.assignPositionOrganizationChart,
      )

      const deleteAssignation =
        await PositionsServices.deleteAssignation<boolean>(
          selectedAssigned.organicoRolCodigo,
        )
      if (
        !deleteAssignation.error &&
        deleteAssignation.auto &&
        typeof deleteAssignation.auto === 'boolean'
      )
        onHandleSucessfull(false)
      setToastMessage(
        'Eliminar Asignación Organigrama',
        deleteAssignation.message,
        deleteAssignation.error ? ToastTypes.Warning : ToastTypes.Success,
      )
      showLoaderTabs(false, 'ASIGNACION_ROL_ORGANIGRAMA')
    } catch (error) {
      showLoaderTabs(false, 'ASIGNACION_ROL_ORGANIGRAMA')
      setToastMessage(
        'Eliminar Asignación Organigrama',
        error,
        ToastTypes.Danger,
      )
    }
  }, [onHandleSucessfull, selectedAssigned, setToastMessage, showLoaderTabs])

  const assigPosition = useCallback(async () => {
    try {
      showLoaderTabs(
        true,
        'ASIGNACION_ROL_ORGANIGRAMA',
        false,
        'Guardando Asignación . . .',
        ButtonTypes.assignPositionOrganizationChart,
      )

      const saveAssignation = await PositionsServices.saveAssignation<number>({
        infoRegistro: {
          organicoCodigo: selectedOrganizationChart,
          rolCodigo: selectedPosition.codigo,
        },
      })
      if (
        !saveAssignation.error &&
        saveAssignation.auto &&
        typeof saveAssignation.auto === 'number' &&
        !isNaN(saveAssignation.auto)
      )
        onHandleSucessfull()

      setToastMessage(
        'Guardar Asignación Organigrama',
        saveAssignation.message,
        saveAssignation.error ? ToastTypes.Warning : ToastTypes.Success,
      )
      showLoaderTabs(false, 'ASIGNACION_ROL_ORGANIGRAMA')
    } catch (error) {
      showLoaderTabs(false, 'ASIGNACION_ROL_ORGANIGRAMA')
      setToastMessage(
        'Guardar Asignación Organigrama',
        error,
        ToastTypes.Danger,
      )
    }
  }, [
    onHandleSucessfull,
    selectedOrganizationChart,
    selectedPosition,
    setToastMessage,
    showLoaderTabs,
  ])

  useEffect(() => {
    clearPositionsAndAssigns()
    if (!searchState.isDataLoaded) loadOrganizationChart()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <BlockUi
      tag="div"
      loader={LoadingIndicator}
      blocking={loader.show && tabId === tabs.current}
      message={loader.mensaje}
    >
      <RowContainer className="m-2">
        <CCol xs="12" md="4" style={{ marginBottom: isMobile ? '15px' : 0 }}>
          <Labeled label="Organigrama:">
            <Arbol
              name="three-positions"
              data={searchState.list}
              jerarquiaName="Organigrama Cargos"
              activedense={false}
              sendData={verifyAssignedPositions}
            />
          </Labeled>
        </CCol>
        <CustomCol xs="12" md="3">
          <DataGrid
            ref={dataGridPositions}
            keyExpr="codigo"
            id={crypto.randomUUID()}
            selection={{ mode: 'single' }}
            dataSource={searchState.listToBeAssigned}
            showColumnLines={true}
            showRowLines={true}
            showBorders={true}
            columnResizingMode="widget"
            loadPanel={{ enabled: true }}
            allowColumnResizing={true}
            remoteOperations={true}
            onRowClick={({ data, rowIndex }) =>
              onSelectedPositionChanged(data, rowIndex)
            }
            onCellDblClick={() => assigPosition()}
          >
            <Column caption="Roles" dataField="descripcion" width={'100%'} />
          </DataGrid>
        </CustomCol>
        <CCol
          xs="12"
          md="1"
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: isMobile ? '8px' : '40px',
            marginBottom: isMobile ? '8px' : 0,
          }}
        >
          <Button
            id="btnRemovePosition"
            className="me-1"
            icon="arrowright"
            stylingMode="contained"
            type="default"
            hint="Asignar rol seleccionado"
            disabled={selectedPosition === null}
            onClick={assigPosition}
            width={isMobile ? '25%' : 'auto'}
          />
          <Button
            style={{ marginTop: '5px' }}
            id="btnAddPosition"
            className="me-1"
            icon="arrowleft"
            stylingMode="contained"
            type="default"
            hint="Eliminar rol seleccionado"
            disabled={selectedAssigned === null}
            onClick={unAssigPosition}
            width={isMobile ? '25%' : 'auto'}
          />
        </CCol>
        <CustomCol xs="12" md="4">
          <DataGrid
            ref={dataGridAssigned}
            keyExpr="organicoRolCodigo"
            id={crypto.randomUUID()}
            selection={{ mode: 'single' }}
            dataSource={searchState.listAssigned}
            showColumnLines={true}
            showRowLines={true}
            showBorders={true}
            columnResizingMode="widget"
            loadPanel={{ enabled: true }}
            allowColumnResizing={true}
            remoteOperations={true}
            onRowClick={({ data, rowIndex }) =>
              onSelectedAssignedChanged(data, rowIndex)
            }
            onCellDblClick={() => unAssigPosition()}
          >
            <Column
              caption="Asignados"
              dataField="rolDescripcion"
              width={'100%'}
            />
          </DataGrid>
        </CustomCol>
      </RowContainer>
    </BlockUi>
  )
}

export default OrganizationChart
