import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MenuState } from '../../../../../../ventas/types/types';
import { RootState } from '../../../../../../../store/store';
import { TabsState } from '../../../../../../../store/genericTabTypes';
import { addToast } from '../../../../../../../store/toasterReducer';
import { AccionMenu, ToastTypes } from '../../../../../../../store/types';
import { StatesEdition } from '../../../../../../../store/enums';
import Dialog from '../../../../../../../views/componentes/librerias/bootstrap-dialog';
import { menuService } from '../../../../../../../services/menu.service';
import { ButtonTypes } from '../../../../../../../views/componentes/globalMenu/types';
import { changeLoader } from '../../../../../../../store/reducers';
import { UrlHelper } from '../../../../../../../helpers/urlHelper';
import { GlobalMenu } from '../../../../../../../views/componentes/globalMenu/globalMenu';
import { Aplicacion } from '../../../../../store/types';
import TabLineasControl from './tabs/tabs';

// const pathVerificador = 'ingresos/egresos'

interface ILineasMainProps extends React.PropsWithChildren {
  onActionsButtonsLoaded: (acciones: Array<AccionMenu>) => void;
  codigoModulo: number,
  opciones: Array<number>,
  menu: MenuState,
  tabs: TabsState<any>,
  onTabChanged: (tabKey: string) => void,
  onMenuButtonClick: (accion: AccionMenu, tab: string) => void,
  searching: boolean,
  ontabClosing: (tabKey: string) => void,
  hayResultadosBusqueda: boolean,
  verificarPuntoVenta: boolean,
  verificarAutorizacion: boolean,
  onExecutingAction?: (tabKey: string, type?: ButtonTypes) => void,
  tipoAplicacion: Aplicacion
}
const position = { of: '#mainContent' };

function LineasMain(props: ILineasMainProps) {

  const urls = UrlHelper.getUrls();

  const { codigoModulo, opciones, onActionsButtonsLoaded, menu,
    ontabClosing, onTabChanged, onMenuButtonClick, tabs,
    tipoAplicacion, verificarPuntoVenta, onExecutingAction
  } = props;

  const dispatch = useDispatch();
  const puntoVenta = useSelector((state: RootState) => { return state.global.puntoVenta });
  const localState = useSelector((state: RootState) => { return state.global.session?.local });
  const menuState = useSelector((state: RootState) => state.global.menu)

  const [configurarPV, setConfigurarPv] = React.useState(false);
  const dialogRef = React.useRef<any>(null);
  const [confirmarNuevo, setconfirmarNuevo] = React.useState<boolean>(false);
  const [habilitarDisenador, setHabilitarDisenador] = React.useState<boolean>(true);
  const [cambiarEstablecimiento, setCambiarEstablecimiento] = React.useState<boolean>(false);


  const getVerificaPath = React.useCallback(async () => {
    if (urls.origin.includes("acatha.io")) {
      setHabilitarDisenador(false);
    } else {
      setHabilitarDisenador(true);
    }

  }, [urls]);

  const cargarAccionesMenu = React.useCallback(async () => {
    try {

      const acciones = await menuService.cargarAccionesUsuario(codigoModulo, opciones);

      onActionsButtonsLoaded(acciones);
    } catch (error) {
      dispatch(addToast({
        content: error,
        title: 'Cargar opciones',
        type: ToastTypes.Danger
      }));
    }
  }, [dispatch, codigoModulo, opciones, onActionsButtonsLoaded])

  const continueOpenTab = React.useCallback(() => {
    const currentTab = tabs.tabs[tabs.current];
    if (currentTab.editStatus === StatesEdition.new) {
      setconfirmarNuevo(true);
      return;
    } else {
      onMenuButtonClick({ actionType: ButtonTypes.new, icon: '', main: false, modal: false, nombre: 'Nuevo', shortcut: null }, tabs.current);
    }
  }, [tabs, onMenuButtonClick]);

  const onConfirmarContinuarConEstablecimiento = React.useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿El establecimiento del local: ` + (localState?.nombre + `-` + localState?.establecimiento ?? `S/N`) + `, no corresponde al establecimiento de la autorización para este documento, desea continuar?`,
      actions: [
        Dialog.Action(
          <span><u>S</u>i</span>,
          (dialog) => {
            dialog.hide();
            continueOpenTab();
            return;
          },
          'btn-success',
          'n'
        ),
        Dialog.Action(
          <span><u>C</u>ambiar</span>,
          (dialog) => {
            dialog.hide();
            // dispatch(setAsideShow(true));
          },
          'btn-danger',
          'c'
        )
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      }
    });
    setconfirmarNuevo(false);
    setCambiarEstablecimiento(false);
    return;
  }, [localState, continueOpenTab])



  const handleButtonClick = React.useCallback(async (accion: AccionMenu) => {
    if (accion.actionType === ButtonTypes.new) {
      try {
        if (onExecutingAction) {
          onExecutingAction("BUSQUEDA", ButtonTypes.new);
        }
        if (verificarPuntoVenta && puntoVenta == null) {
          setConfigurarPv(true);
          return;
        }

        const currentTab = tabs.tabs[tabs.current];
        if (currentTab.editStatus === StatesEdition.new || currentTab.editStatus === StatesEdition.save) {
          setconfirmarNuevo(true);
          return;
        }
      } catch (error) {

      }
      finally {
        if (onExecutingAction) {
          onExecutingAction("BUSQUEDA");
        }
      }

    }
    onMenuButtonClick(accion, "");
  }, [puntoVenta, onMenuButtonClick, onExecutingAction, verificarPuntoVenta, tabs

  ]);



  const getButtonDisabled = React.useCallback((accion: AccionMenu) => {
    const currentTab = tabs.tabs[tabs.current];
    if (!currentTab) return true;
    return !currentTab.buttons[accion.actionType] ?? true;
  }, [tabs]);

  const onConfirmarNuevo = React.useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea crear un nuevo registro?`,
      actions: [
        Dialog.Action(
          <span><u>P</u>estaña Actual</span>,
          (dialog) => {
            dialog.hide();
            onMenuButtonClick({ actionType: ButtonTypes.broom, icon: '', main: false, modal: false, nombre: 'Limpiar', shortcut: null }, tabs.current);
          },
          'btn-success',
          'p'
        ),
        Dialog.Action(
          <span><u>N</u>ueva Pestaña</span>,
          (dialog) => {
            dialog.hide();
            onMenuButtonClick({ actionType: ButtonTypes.new, icon: '', main: false, modal: false, nombre: 'Nuevo', shortcut: null }, tabs.current);
          },
          'btn-success',
          'n'
        ),
        Dialog.Action(
          <span><u>C</u>ancelar</span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c'
        )
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      }
    });
    setconfirmarNuevo(false);
    return;
  }, [tabs, onMenuButtonClick])


  React.useEffect(() => {
    if (confirmarNuevo) {
      onConfirmarNuevo();
    }
  }, [confirmarNuevo, onConfirmarNuevo]);

  React.useEffect(() => {
    if (cambiarEstablecimiento) {
      onConfirmarContinuarConEstablecimiento();
    }
  }, [cambiarEstablecimiento, onConfirmarContinuarConEstablecimiento]);


  React.useEffect(() => {
    if (menu && menu.accions.length === 0) {
      cargarAccionesMenu();
    }
  }, [menu, cargarAccionesMenu]);

  React.useEffect(() => {
    if (menu.loading) {
      dispatch(changeLoader({ show: true, mensaje: 'Cargando opciones....' }))
    } else {
      dispatch(changeLoader({ show: false, mensaje: '' }))
    }
  }, [dispatch, menu]);

  React.useEffect(() => {
    getVerificaPath()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (props.menu.loading) {
    return null;
  }

  return (
    <div style={{ backgroundColor: '#fff' }}  >
      <Dialog ref={dialogRef} />
      <GlobalMenu printButtonAsDropdown={habilitarDisenador} currentWorking={tabs.tabs[tabs.current].currentExecutingAction} showExportDesingOptions acciones={menu.accions} onClick={handleButtonClick} getButtonDisabled={getButtonDisabled} />

      <TabLineasControl
        tabsState={tabs}
        onTabChanged={onTabChanged}
        ontabClosing={ontabClosing}
        tipoAplicacion={tipoAplicacion}
      />
    </div>
  );
}
LineasMain.whyDidYouRender = true
export default React.memo(LineasMain);