import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Candidate, FiltersCandidate, SearchState } from '../types/types'
import { CargoRol } from '../../../../componentes/cargosLookup/types/types'
import { DateUtils } from '../../../../../helpers/dateUtils'
import { LoaderInfo } from '../../../../../store/types'

export const initialState: SearchState<Candidate> = {
  filter: {
    apellidos: '',
    nombres: '',
    identificacion: '',
    cargo: null,
    registroDesde: DateUtils.getCurrentDateAsString(),
    registroHasta: DateUtils.getCurrentDateAsString(),
    liderazgo: false,
    actitud: false,
    comunicacion: false,
    flexibilidad: false,
    colaboracion: false,
    dedicacion: false,
    confianza: false,
    integridad: false,
    iniciativa: false,
    aprendizaje: false,
  },
  candidatesList: [],
  collapsedFilter: true,
  loader: { show: false, mensaje: '' },
  selected: null,
  isSearch: false,
}

const searchSlice = createSlice({
  name: 'searchCandidate',
  initialState: initialState,
  reducers: {
    setFilterCandidates(state, action: PayloadAction<FiltersCandidate>) {
      state.filter.apellidos = action.payload.apellidos
      state.filter.nombres = action.payload.nombres
      state.filter.identificacion = action.payload.identificacion
      state.filter.registroDesde = action.payload.registroDesde
      state.filter.registroHasta = action.payload.registroHasta
      state.filter.liderazgo = action.payload.liderazgo
      state.filter.actitud = action.payload.actitud
      state.filter.comunicacion = action.payload.comunicacion
      state.filter.flexibilidad = action.payload.flexibilidad
      state.filter.colaboracion = action.payload.colaboracion
      state.filter.dedicacion = action.payload.dedicacion
      state.filter.confianza = action.payload.confianza
      state.filter.integridad = action.payload.integridad
      state.filter.iniciativa = action.payload.iniciativa
      state.filter.aprendizaje = action.payload.aprendizaje
    },
    setFilterPayroll(state, action: PayloadAction<CargoRol>) {
      state.filter.cargo = action.payload
    },
    setResultsCandidate(state, action: PayloadAction<Candidate[] | []>) {
      state.candidatesList = action.payload
    },
    setFilter(state, action: PayloadAction<FiltersCandidate>) {
      state.filter = action.payload
    },
    setCollpasedFilter(state, action: PayloadAction<boolean>) {
      state.collapsedFilter = action.payload
    },
    setSearchLoader(state, action: PayloadAction<LoaderInfo>) {
      state.loader = action.payload
    },
    setSelectedCandidate(state, action: PayloadAction<Candidate>) {
      state.selected = action.payload
    },
    setIsSearch(state, action: PayloadAction<boolean>) {
      state.isSearch = action.payload
    },
  },
})

export const {
  setFilterCandidates,
  setFilter,
  setResultsCandidate,
  setCollpasedFilter,
  setFilterPayroll,
  setSearchLoader,
  setSelectedCandidate,
  setIsSearch,
} = searchSlice.actions

export const searchCandidatesReducer = searchSlice.reducer
