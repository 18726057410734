import { CFormLabel } from '@coreui/react-pro'

export const GlobalLabel = (props) => {
  return (
    <CFormLabel style={{ width: "100%" }} className={props.className} >
      {/* htmlFor={props.htmlFor} */}
      {props.children}
    </CFormLabel>
  )
}
