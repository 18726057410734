import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoaderInfo } from "../../../../../../store/types";
import { PageState, Registros, Seleccionado, formularioBusqueda } from "./types";
import { TOptionGenericoString } from '../../../../../clientes/pages/clientes/types/types'

const initialState: PageState = {
  modulo: 'contabilidad',
  currentAction: 'Buscar',
  currentFunction: '',
  aplicacion: 'estadoFlujosEfectivo',
  acciones: [],
  registros: [],
  registrosBackup: [],
  refrescaTabla: false,
  seleccionado: {
    index: null,
    row: null,
    selectedRow: null,
  },
  formularioBusqueda: {
    ASI_DIARIONRO: -1,
    TIPO_FLUJO_EFECTIVO: null,
  },
  loader: {
    show: false,
    mensaje: '',
  },
}

const estadoFlujosEfectivoSlice = createSlice({
  name: 'estadoFlujosEfectivo',
  initialState: initialState,
  reducers: {
    obtenerAcciones(state, action: PayloadAction<any>) {
      state.acciones = action.payload
    },
    setData(state, action: PayloadAction<Array<Registros>>) {
      state.registros = action.payload
    },
    setDataBackup(state, action: PayloadAction<Array<Registros>>) {
      state.registrosBackup = action.payload
    },
    setCurrentAccion(state, action: PayloadAction<any>) {
      state.currentAction = action.payload
    },
    setCurrentFunction(state, action: PayloadAction<string>) {
      state.currentFunction = action.payload
    },
    setSeleccionarDato(state, action: PayloadAction<Seleccionado>) {
      const toAdd = {
        index: action.payload.index,
        row: action.payload.row,
        selectedRow: action.payload.selectedRow,
      }
      state.seleccionado = toAdd
    },
    setResetSeleccion(state) {
      const toAdd = {
        index: null,
        row: null,
        selectedRow: null,
      }
      state.seleccionado = toAdd
    },
    setFormularioBusqueda(state, action: PayloadAction<formularioBusqueda>) {
      const toAdd = {
        ...state.formularioBusqueda,
        ASI_DIARIONRO: action.payload.ASI_DIARIONRO,
      }
      state.formularioBusqueda = toAdd
    },
    setTiposFlujoEfectivo(state, action: PayloadAction<TOptionGenericoString>) {
      state.formularioBusqueda.TIPO_FLUJO_EFECTIVO = action.payload
    },
    changeLoaderFlujo(state, action: PayloadAction<LoaderInfo>) {
      state.loader = action.payload
    },
  },
})

export const {
  changeLoaderFlujo,
  setCurrentFunction,
  obtenerAcciones,
  setData,
  setDataBackup,
  setCurrentAccion,
  setSeleccionarDato,
  setResetSeleccion,
  setFormularioBusqueda,
  setTiposFlujoEfectivo,
} = estadoFlujosEfectivoSlice.actions
export const estadoFlujosEfectivoReducer = estadoFlujosEfectivoSlice.reducer
