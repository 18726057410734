import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../../store/store";
import { ButtonTypes } from "../../../../../views/componentes/globalMenu/types";
import { StatesEdition, TabTypes } from "../../../../ventas/types/enums";
import { CustomButtons } from "../../../../ventas/types/generics";
import { AnexoButtons } from "../pages/component/anexo/anexo";
import { AnexoTransacListado, TabsStateAnexoTransaccional, TabStateAnexoTransaccional } from "../types/types";

const initialState: TabsStateAnexoTransaccional<AnexoTransacListado> = {
  current: "BUSQUEDA",
  tabs: {
    BUSQUEDA: {
      id: 0,
      canClose: false,
      editStatus: StatesEdition.doesNotApply,
      globalButtonClick: ButtonTypes.none,
      tittle: "Búsqueda",
      type: TabTypes.busqueda,
      imprimir: false,
      tabKey: "BUSQUEDA",
      buttons: {
        Buscar: true,
      },
    },
  },
};

const getNewTabKeyOnClose = (
  state: TabsStateAnexoTransaccional<any>,
  keyToClose: string
) => {
  let newCurrent = "";
  for (const key in state.tabs) {
    if (newCurrent === "") {
      newCurrent = key;
    }
    if (keyToClose === key) {
      return newCurrent;
    }
  }
  return "BUSQUEDA";
};

const tabsVentasSlice = createSlice({
  name: "anexosTabs",
  initialState: initialState,
  reducers: {
    closeTab(state, action: PayloadAction<string>) {
      const newCurrent = getNewTabKeyOnClose(state, action.payload);
      state.current = newCurrent;
      delete state.tabs[action.payload];
    },
    openTab(
      state,
      action: PayloadAction<{ key: string; anexo?: AnexoTransacListado, templateMode?: boolean }>
    ) {
      if (action.payload.anexo === undefined || action.payload.templateMode) {
        const tab: TabStateAnexoTransaccional<AnexoTransacListado> = {
          id: -1,
          canClose: true,
          editStatus: StatesEdition.new,
          globalButtonClick: ButtonTypes.none,
          tittle: "Nueva",
          type: TabTypes.documento,
          imprimir: false,
          tabKey: action.payload.key,
          info: {
            codigo: 0,
          },
          buttons: AnexoButtons,
          templateMode: action.payload.templateMode
        };
        state.tabs[action.payload.key] = tab;
        state.current = action.payload.key;
        // } else {
        //   const tabKey = existeTab(state.tabs, action.payload.venta.comprobante);
        //   if (tabKey) {
        //     state.current = tabKey;
        //   } else {
        //     const tab: TabState<AnexoTransacListado> = {
        //       tabKey: action.payload.key,
        //       id: action.payload.venta.comprobante,
        //       tittle: `${action.payload.venta.establecimiento}-${action.payload.venta.puntoEmision}-${action.payload.venta.numero}`,
        //       canClose: true,
        //       editStatus: StatesEdition.save,
        //       type: TabTypes.documento,
        //       info: {
        //         claveAcceso: action.payload.venta.claveAcceso,
        //         numero: action.payload.venta.comprobante,
        //         tipoComprobante: TiposComprobantesSri.Factura,
        //         info: action.payload.venta,
        //       },
        //       globalButtonClick: ButtonTypes.none,
        //       imprimir: false,
        //       buttons: InvoiceButtons,
        //     };
        //     state.tabs[action.payload.key] = tab;
        //     state.current = action.payload.key;
        //   }
      }
    },
    changeEditStatus(
      state,
      action: PayloadAction<{
        tabKey: string;
        estado: StatesEdition;
        info?: AnexoTransacListado;
        buttons: CustomButtons
      }>
    ) {
      const { estado, tabKey, info, buttons } = action.payload;
      state.tabs[tabKey].editStatus = estado;
      state.tabs[tabKey].currentExecutingAction = undefined;
      state.tabs[tabKey].buttons = buttons;
      if (info) {
        state.tabs[tabKey].info = {
          codigo: info.codigo,
          info: info ?? null,
        };
        state.tabs[tabKey].id = info.numero;
        state.tabs[
          action.payload.tabKey
        ].tittle = `${info.titleTab}`;
      }
    },
    buttonClick(
      state,
      action: PayloadAction<{ tabKey: string; button: ButtonTypes }>
    ) {
      state.tabs[action.payload.tabKey].globalButtonClick =
        action.payload.button;
    },
    clearButtonClick(state, action: PayloadAction<string>) {
      if (Object.prototype.hasOwnProperty.call(state.tabs, action.payload)) {
        state.tabs[action.payload].globalButtonClick = ButtonTypes.none;
      }

    },
    changeCurrentTab(state, action: PayloadAction<string>) {
      if (Object.prototype.hasOwnProperty.call(state.tabs, action.payload)) {
        state.current = action.payload;
      }
    },
    setButtons(state, action: PayloadAction<{ tabKey: string, buttons: CustomButtons }>) {
      const { buttons, tabKey } = action.payload;
      state.tabs[tabKey].buttons = buttons;
      state.tabs[tabKey].currentExecutingAction = undefined;
    },
    setCurrentExecutingAction(state, action: PayloadAction<{ tabKey: string, buttonType: ButtonTypes | undefined }>) {
      const { buttonType, tabKey } = action.payload;
      state.tabs[tabKey].currentExecutingAction = buttonType;
    }
  },
});

export const selectTabsAnexoTransac = (state: RootState) => {
  return state.ventas.ventas.tabs;
};
export const {
  closeTab,
  openTab,
  changeCurrentTab,
  changeEditStatus,
  clearButtonClick,
  buttonClick,
  setButtons,
  setCurrentExecutingAction
} = tabsVentasSlice.actions;
export const tabsReducer = tabsVentasSlice.reducer;
