import { utilidades } from "../../../../helpers/utilidades";
import { tittlesService } from "../service/tittles.service";
import { TitleOption } from "../store/type";

export async function getTittles(mensaje: string) {

  const data = await tittlesService.getTittesAdicionales(mensaje);

  let opt: Array<TitleOption> = []
  if (data.auto.length > 0) {
    opt = data.auto.map((x) => {
      const item: TitleOption = {
        codigo: parseInt(x?.codigo) ?? -1,
        descripcion: utilidades.capText(x?.descripcion) ?? '',
        estado: parseInt(x?.estado),
      }
      return item
    })
  }

  return opt
}