import { RequestHelper } from "../helpers/requestHelper";
import { SesionService } from "./sesion.service";

export const atributosService = { getAtributos, getValoresAtributos, atributosLocalInsertar };

async function getAtributos(): Promise<any> {
  const sesion = SesionService.getCurrentSesion();
  try {
    const query: any = {
      local: sesion.local.codigo
    };
    const data = await RequestHelper.getAll<any>(
      "generales",
      "atributos/get",
      "",
      query
    );
    return data;
  } catch (error) {
    return error;
  }
}

async function getValoresAtributos(atributoId: number): Promise<any> {
  try {
    const query: any = {
      atributo: atributoId
    };
    const data = await RequestHelper.getAll<any>(
      "generales",
      "atributos/getValues",
      "",
      query
    );
    return data;
  } catch (error) {
    return error;
  }
}

async function atributosLocalInsertar(objeto: any): Promise<any> {
  const sesion = SesionService.getCurrentSesion()
  try {
    const obj = {
      infoRegistro: {
        codLocal: sesion.local.codigo,
        ...objeto
      }
    }
    const data = await RequestHelper.postAll<any>(
      "sidebar",
      "local/updateAtributos",
      obj
    );
    return data;
  } catch (error) {
    return error;
  }
}